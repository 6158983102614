export const RECEIVE_COMPANY_NGRS = 'RECEIVE_COMPANY_NGRS';
export const ADD_COMPANY_NGR = 'ADD_COMPANY_NGR';
export const EDIT_COMPANY_NGR = 'EDIT_COMPANY_NGR';
export const CANCEL_EDIT_COMPANY_NGR = 'CANCEL_EDIT_COMPANY_NGR';
export const CLICK_ADD_COMPANY_NGR_BUTTON = 'CLICK_ADD_COMPANY_NGR_BUTTON';
export const CLICK_EDIT_COMPANY_NGR_ICON = 'CLICK_EDIT_COMPANY_NGR_ICON';
export const CLICK_EDIT_COMPANY_NGR = 'CLICK_EDIT_COMPANY_NGR';
export const ADD_COMPANY_NGR_ERRORS = 'ADD_COMPANY_NGR_ERRORS';
export const EDIT_COMPANY_NGR_ERRORS = 'EDIT_COMPANY_NGR_ERRORS';
export const CURRENT_USER_REPRESENTING_ANY_PARTY = 'CURRENT_USER_REPRESENTING_ANY_PARTY';

export const receiveCompanyNgrs = items => ({
  type: RECEIVE_COMPANY_NGRS,
  items,
});

export const addCompanyNgr = item => ({
  type: ADD_COMPANY_NGR,
  item,
});

export const addCompanyNgrErrors = errors => ({
  type: ADD_COMPANY_NGR_ERRORS,
  errors,
});

export const editCompanyNgr = item => ({
  type: EDIT_COMPANY_NGR,
  item,
});

export const editCompanyNgrErrors = errors => ({
  type: EDIT_COMPANY_NGR_ERRORS,
  errors,
});

export const cancelEditCompanyNgr = () => ({
  type: CANCEL_EDIT_COMPANY_NGR,
});

export const clickAddCompanyNgrButton = () => ({
  type: CLICK_ADD_COMPANY_NGR_BUTTON,
});

export const clickEditCompanyNgrIcon = id => ({
  type: CLICK_EDIT_COMPANY_NGR_ICON,
  id,
});

export const clickEditCompanyNgr = id => ({
  type: CLICK_EDIT_COMPANY_NGR,
  id,
});

export const isCurrentUserRepresentingAnyParty = item => ({
  type: CURRENT_USER_REPRESENTING_ANY_PARTY,
  item,
});
import React from 'react';

import { connect } from 'react-redux';
import { RadioGroup, FormControlLabel, Radio, Autocomplete, TextField } from '@mui/material';
import APIService from '../../../services/APIService';
import map from 'lodash/map';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import CommonButton from '../../common/CommonButton';
import CommonTextField from '../../common/CommonTextField';
import { required } from '../../../common/validators';
import {get, set, find}  from 'lodash';
import { isLoading, forceStopLoader } from "../../../actions/main";
import CommonSelect from '../../common/select/CommonSelect';
import alertifyjs from 'alertifyjs';
import { ASSIGN_CONTRACT_PO_SUCCESS } from './Constants';

class AllocatePackMovements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companies: [],
      customer: [],
      newContacts: [],
      listAssignFmOptions: {
        order: { label: "Pack Order", items: [] },
      },
      selectedContact:{
        value: [],
        validators: [required()]
      },
      baseEntity: {
        value: [],
        validators: [required()],
        errors: []
      },
      buttons: 'order',
    };
    this.setEntity = this.setEntity.bind(this);
    this.isLoading = this.props.isLoading;
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.onSave = this.onSave.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
  }

  componentDidMount() {
    this.isLoading();
    this.fetchOrderData();
  }

  getContacts = id => {
    APIService.contracts()
      .companies(id)
      .contacts()
      .get()
      .then(contacts => {
        this.setState({
          newContacts: contacts
        });
      });
  }

  fetchOrderData = async () => {
    let data = await APIService.freights().contracts(this.props.movement.id).appendToUrl('orders/pack/').get(this.props.token, {}, null);
    const filteredOrders = filter(data, 'canAssignFreightOrder');
    let orders = map(filteredOrders, data => merge(data, { referenceNumber: data.identifier, entity: 'order' }));
    const newState = { ...this.state };
    set(newState, 'listAssignFmOptions.order.items', orders);
    this.setState(newState, () => this.props.forceStopLoader());
  };

  OnSubmit = (event) => {
    event.preventDefault();
    this.setFieldErrors('selectedContact');
    if (this.state.listAssignFmOptions[this.state.buttons].label === 'Independent') {
      const newState = { ...this.state };
      set(newState, 'baseEntity.value', {});
      this.setState(newState);
    }
    let isFormInvalid = this.state.baseEntity.errors.length > 0 || this.state.selectedContact.errors.length > 0;
    if(!isFormInvalid)
      alertifyjs.confirm(
        'Warning',
        "Please verify the updated Customer and its NGR as it will be updated in the Pack Movement. Do you want to proceed or go back?",
        () => { },
        this.onSave,
      ).set({ labels: { cancel: 'Proceed', ok: 'Go Back' } });
  };

  onSave() {
    const { allocateMovement } = this.props;
    allocateMovement(
      { baseEntity: this.state.baseEntity.value,
        customer: {
          "companyId": this.state.customer.companyId,
          "ngrId": this.state.customer.ngrId,
          "contactId": this.state.selectedContact.value,
        }
      }, this.props.movement.id, ASSIGN_CONTRACT_PO_SUCCESS, true);
  }

  setEntity = id => {
    const newState = { ...this.state };
    if (id) {
      let selectedEntity = (find(get(this.state.listAssignFmOptions, `${[this.state.buttons]}.items`, []), { id: id }));
      let customer = null;
      if (get(selectedEntity, 'customer'))
        customer = selectedEntity.customer;
      set(newState, 'baseEntity.value', selectedEntity);
      set(newState, 'customer', customer);
      set(newState, 'selectedContact.value', customer.contactId);
      set(newState, 'baseEntity.errors', '');
      this.setState(newState, () => {
        this.getContacts(customer.companyId)
      });
    }
    else {
      set(newState, 'baseEntity.value', '');
      set(newState, 'customer', []);
      set(newState, 'newContacts', []);
      set(newState, 'selectedContact.value', '');
      const value = get(newState, `baseEntity.value`);
      const validators = get(this.state, `baseEntity.validators`, []);
      validators.forEach((validator) => {
        if (validator.isInvalid(value)) {
          set(newState, 'baseEntity.errors', validator.message);
        }
      });
      this.setState(newState);
    }
  }

  setFieldErrors(key) {
    const newState = { ...this.state };
    set(newState, `${key}.errors`, this.getFieldErrors(key));
    this.setState(newState);
  };

  getFieldErrors(key) {
    let errors = [];
    const value = get(this.state, `${key}.value`);
    const validators = get(this.state, `${key}.validators`) || [];
    validators.forEach(validator => {
        if (validator.isInvalid(value)) {
            errors.push(validator.message);
        }
    });

    return errors;
  }

  fetchNgrs(value, id, callback) {
    if(get(this.state, `{id}Ngrs.0.companyId`) !== value) {
      APIService.companies(value).ngrs().appendToUrl('minimal/')
        .get()
        .then(ngrs => {
          if(callback)
            callback(ngrs)
          else {
            const newState = { ...this.state };
            set(newState, `${id}Ngrs`, ngrs);
            this.setState(newState);
          }
        });
    }
  }

  handleSelectFieldChange(value, id) {
    const newState = { ...this.state };
    set(newState, `${id}.value`, value);
    this.setState(newState);
  }

  render() {
    return (<div>
      <form onSubmit={this.OnSubmit} noValidate>
        <div className="cardForm cardForm--drawer" id='assign-to-contract-or-freight-order'>
          <div className="cardForm-content row">
            <RadioGroup onChange={e => this.setState({ buttons: e.target.value } )} value={this.state.buttons} row className="col-sm-12 form-wrap-70">
              <FormControlLabel key={1} value="order" control={<Radio color="primary" />} label={this.state.listAssignFmOptions.order.label} />
            </RadioGroup>

            {this.state.listAssignFmOptions[this.state.buttons].label !== 'Independent' &&
             <div className="col-sm-12 form-wrap-70">
               <Autocomplete
                 fullWidth
                 options={this.state.listAssignFmOptions[this.state.buttons].items}
                 value={find(this.state.listAssignFmOptions[this.state.buttons].items, {id: get(this.state.baseEntity, 'value.id')})}
                 id="FmOptionsId"
                 style={{float: 'left'}}
                 getOptionLabel={option => get(option, 'displayName')}
                 renderInput={params => (
                   <TextField
                     {...params}
                     label={this.state.listAssignFmOptions[this.state.buttons].label}
                     errorText={!isEmpty(get(this.state.baseEntity, 'errors', ''))}
                     helperText={get(this.state.baseEntity, 'errors', '')}
                     fullWidth
                   />
                 )}
                 onChange={(event, value) => this.setEntity(value ? value.id : null, 'FmOptionsId', value)}
               />
              </div>
            }

            <div className='col-sm-12 form-wrap padding-reset'>
              <div className='col-sm-6 form-wrap'>
                <CommonTextField
                  id="customerName"
                  label="Old Customer Name"
                  value={get(this.props.movement, 'customer.company.displayName', get(this.state.customer, 'companyName'))}
                  disabled 
                />
              </div>
              <div className='col-sm-6 form-wrap'>
                <CommonTextField
                  id="customerName"
                  label="New Customer Name"
                  value={get(this.state.customer, 'companyName')}
                  disabled 
                />
              </div>
            </div>
            <div className='col-sm-12 form-wrap padding-reset'>
              <div className='col-sm-6 form-wrap'>
                <CommonTextField
                  id="customerName"
                  label="Old NGR"
                  value={get(this.props.movement, 'customer.ngr.ngrNumber', get(this.state.customer, 'companyName'))}
                  disabled 
                />
              </div>
              <div className='col-sm-6 form-wrap'>
                <CommonTextField
                  id="customerName"
                  label="New NGR"
                  value={get(this.state.customer, 'ngrNumber')}
                  disabled 
                />
              </div>
            </div>
            <div className='col-sm-12 form-wrap padding-reset'>
              <div className='col-sm-6 form-wrap'>
                <CommonTextField
                  id="customerName"
                  label="Old Contact"
                  value={get(this.props.movement, 'customer.contact.name', get(this.state.customer, 'companyName'))}
                  disabled 
                />
              </div>
              <div className='col-sm-6 form-wrap'>
                <CommonSelect
                  items={this.state.newContacts}
                  id="selectedContact"
                  floatingLabelText="New Contact"
                  value={get(this.state.selectedContact.value)}
                  onChange={this.handleSelectFieldChange}
                  errorText={get(this.state.selectedContact, 'errors[0]')}
                />
              </div>
            </div>
          </div>

          <div className="col-sm-12 cardForm-action top15 padding-reset">
            <CommonButton
              type="button"
              variant="outlined"
              label="Cancel"
              default
              onClick={this.props.handleCancel}
            />

            <CommonButton
              primary={true}
              variant="contained"
              label={"Submit"}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>);
  }
};

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  };
};

const mapDispatchToProps = dispatch => ({
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader())
});

export default connect(mapStateToProps, mapDispatchToProps)(AllocatePackMovements);

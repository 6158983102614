import React from 'react';
import { connect } from 'react-redux';
import { Table, TableHead, TableRow, TableCell, TableBody, Accordion, AccordionSummary, AccordionDetails  } from '@mui/material'
import { isLoading, forceStopLoader } from '../../actions/main';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { get, map, reject, orderBy, find, compact, uniq, isEmpty, sumBy } from 'lodash';
import APIService from '../../services/APIService';
import { setHeaderText } from '../../actions/main';
import GenericTable from '../GenericTable';
import { getCountryLabel, getCountryCurrency } from '../../common/utils';
import { getLoadsWithBalance } from '../stocks/utils';
import { STOCK_SWAP_ITEM_TYPE, WAREHOUSE_STORAGE_ITEM_TYPE } from './Constants';
import { REGRADE_RESEASON_ITEM_TYPE } from './Constants';
import includes from 'lodash/includes';
import { CUSTOM_ITEM_TYPE } from './Constants';

class WarehouseInvoiceLoads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
      invoice: null,
      value: '#' + this.props.location.pathname,
      invoices: [],
      warehouseStorageItems: {},
      customItems: {},
      stockSwapItems: {},
      regradeReseasonItems: {},
      currency: getCountryCurrency()
    };
    this.setValueBasedOnPath = this.setValueBasedOnPath.bind(this);
  }

  getInvoiceId() {
    return this.props.invoiceId || this.props.match.params.invoice_id;
  }

  componentDidMount() {
    this.fetchLoads()
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    const { headerText } = this.props;
    this.props.dispatch(setHeaderText(headerText));
  }

  componentDidUpdate(prevProps) {
    setTimeout(this.setValueBasedOnPath, 500);
    if(prevProps.invoiceId !== this.props.invoiceId)
      this.setHeaderAndBreadcrumbs();
  }

  setValueBasedOnPath() {
    const currentUrl = `#${this.props.location.pathname}`;
    if (this.state.value !== currentUrl) {
      this.setState({ value: currentUrl });
    }
  }

  fetchLoads() {
    this.props.dispatch(isLoading('warehouse-invoice-details'))
    const invoiceId = this.getInvoiceId()
    APIService.invoices(invoiceId).appendToUrl('warehouse-loads/').get().then(
      response => {
        const orderedInvoices = compact([...reject(response, {id: parseInt(invoiceId)}), find(response, {id: parseInt(invoiceId)})]);
        this.setState({invoices: orderedInvoices}, () => {
          this.setWarehouseStorageAndCustomItems();
          this.setWarehouseStockSwapAndRegradeReseasonItems();
          this.props.dispatch(forceStopLoader())
        });
      })
  }

  getHeader(invoice) {
    if(get(invoice, 'id', '').toString() === this.getInvoiceId().toString() && invoice?.status === 'draft')
      return <b>Not Invoiced - {this.state.currency} {parseFloat(this.props.invoice.systemAmount - this.props.invoice.invoicedAmount).toFixed(2)} (Inc. {getCountryLabel('gst')})</b>

    return (
      <b>
        Invoiced - <a href={`#/invoices/${invoice.id}/details`} target='_blank' rel='noopener noreferrer'>{invoice.identifier}</a> - {this.state.currency} {invoice.total} (Inc. {getCountryLabel('gst')})
      </b>
    )
  }

  handleDefaultCellClick = load =>  {
    if(load.freightMovementId)
      window.open(`#/freights/movements/${load.freightMovementId}/details`);
  };

  setWarehouseStockSwapAndRegradeReseasonItems() {
    let regradeReseasonData = {};
    let stockSwapData = {}
    this.state.invoices.map(invoice => {
      stockSwapData[invoice.id] = invoice.items.filter(item => item.itemType === STOCK_SWAP_ITEM_TYPE);
      regradeReseasonData[invoice.id] = invoice.items.filter(item => item.itemType === REGRADE_RESEASON_ITEM_TYPE);
    });
    this.setState({stockSwapItems: stockSwapData, regradeReseasonItems: regradeReseasonData});
  }

  setWarehouseStorageAndCustomItems() {
    let data = {};
    let customItemData = {}
    this.state.invoices.map(invoice => {
      const storageItems = invoice.items.filter(item => item.itemType === 'warehousestorageitem').map(item => {
        const grades = uniq(map(item.loads, 'gradeName'));
        let gradeName = '-';
        if(grades.length > 1)
          gradeName = 'Multiple';
        if (grades.length === 1)
          gradeName = grades[0];
        item.gradeName = gradeName;
        return item;
      });
      data[invoice.id] = storageItems;
      const customItems = invoice.items.filter(item => item.itemType === 'customitem');
      customItemData[invoice.id] = customItems
    });
    this.setState({warehouseStorageItems: data, customItems: customItemData});
  }

  render() {
    const endOfMonth = this.props.invoice.tenure.includes('-') ? this.props.invoice.tenure.split(' - ')[1] : this.props.invoice.tenure;
    const { invoices, currency } = this.state
    const LOADS_COLUMNS = [
      {header: 'Date & Time', key: 'dateTime'},
      {header: 'Type', key: 'invoiceType'},
      {header: 'Identifier', key: 'invoiceIdentifier', className: 'medium'},
      {header: 'Contract', key: 'contractNumber'},
      {header: 'Freight Provider', key: 'freightProvider', className: 'small'},
      {header: getCountryLabel('rego'), key: 'rego'},
      {header: 'Season', key: 'season', className: 'xsmall'},
      {header: 'NGR', key: 'ngr', className: 'medium'},
      {header: 'Variety', key: 'varietyName'},
      {header: 'Grade', key: 'gradeName', className: 'xsmall'},
      {header: 'Tonnage', key: 'unshrunkTonnage', className: 'small'},
      {header: 'Shrunk Tonnage', className: 'medium', default: item => item.type === 'inload' ? item.tonnageWithShrinkage : item.tonnage },
      {header: 'Rate(Per MT)', key: 'rate', className: 'small'},
      {header: `Price (Ex ${getCountryLabel('gst')})`, key: 'subTotal', className: 'small'},
    ];
    const STORAGE_COLUMNS = [
      {header: 'Location', key: 'location'},
      {header: 'Stock Owner', key: 'loads.0.stockOwnerCompanyName'},
      {header: 'NGR', key: 'loads.0.ngr'},
      {header: 'Commodity', key: 'loads.0.commodityName'},
      {header: 'Grade', key: 'gradeName'},
      {header: 'Season', key: 'loads.0.season'},
      {header: 'Quantity', key: 'shrunkTonnage'},
      {header: 'Rate(Per MT)', key: 'rate'},
      {header: `Price (Ex ${getCountryLabel('gst')})`, key: 'subTotal'},
    ]
    const CUSTOM_ITEM_COLUMNS = [
      {header: 'Description', key: 'description'},
      {header: 'Tonnage', key: 'tonnage'},
      {header: 'Shrunk Tonnage', key: 'shrunkTonnage'},
      {header: 'Rate(Per MT)', key: 'rate'},
      {header: `Price (Ex ${getCountryLabel('gst')})`, key: 'subTotal'},
      {header: `${getCountryLabel('gst')}`, key: 'gst'},
      {header: `Price (Inc ${getCountryLabel('gst')})`, key: 'total'},
    ]
    const STOCK_SWAP_REGRADE_RESEASON_ITEM_COLUMNS = [
      {header: 'Description', key: 'description', className: 'large'},
      {header: 'Tonnage', key: 'tonnage', className: 'small'},
      {header: 'Rate(Per MT)', key: 'rate', className: 'small'},
      {header: `Price (Ex ${getCountryLabel('gst')})`, key: 'subTotal', className: 'small'},
      {header: `${getCountryLabel('gst')}`, key: 'gst', className: 'small'},
      {header: `Price (Inc ${getCountryLabel('gst')})`, key: 'total', className: 'small'},
    ]
    return (
      <div className='col-xs-12 padding-reset' style={{marginBottom: '20px'}}>
        {
          map(reject(invoices, invoice => isEmpty(invoice.items)), invoice => {
            let warehouseStorageItems = get(this.state.warehouseStorageItems, invoice.id);
            let stockSwapItems = get(this.state.stockSwapItems, invoice.id);
            let regradeReseasonItems = get(this.state.regradeReseasonItems, invoice.id);
            let customItems = get(this.state.customItems, invoice.id);
            return (
              <Accordion defaultExpanded key={invoice.id} className='col-xs-12 padding-reset' sx={{'&.Mui-expanded': {margin: '8px 0'}}}>
                <AccordionSummary className='col-xs-12' style={{fontSize: '1.2rem'}} expandIcon={<ExpandMoreIcon />}>
                  {this.getHeader(invoice)}
                </AccordionSummary>
                <AccordionDetails style={{padding: 0}}>
                  {
                    !isEmpty(warehouseStorageItems) &&
                    <Table>
                      <TableHead>
                        <TableRow colSpan={STORAGE_COLUMNS.length}>
                          <TableCell style={{background: 'rgba(0, 0, 0, 0.05)', border: '2px solid rgba(0, 0, 0, 0.05)', borderLeft: 'none', borderRight: 'none'}}>
                            <span style={{paddingLeft: '11px', fontSize: '1rem', fontWeight: 'normal'}}>
                              Storage Fees - Stocks as on {endOfMonth} | {`${getCountryLabel('tonnage')} ${sumBy(warehouseStorageItems, 'shrunkTonnage').toFixed(2)}`} | {`${currency} ${sumBy(warehouseStorageItems, 'total').toFixed(2)} (inc. ${getCountryLabel('gst')})`}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <GenericTable
                          columns={STORAGE_COLUMNS}
                          items={warehouseStorageItems}
                          handleDefaultCellClick={this.handleDefaultCellClick}
                          orderBy="dateTime"
                          order="desc"
                          mainContainerStyle={{margin: '10px'}}
                        />
                      </TableBody>
                    </Table>
                  }
                  {
                    !isEmpty(stockSwapItems) &&
                    <Table>
                      <TableHead>
                        <TableRow colSpan={STOCK_SWAP_REGRADE_RESEASON_ITEM_COLUMNS.length}>
                          <TableCell style={{background: 'rgba(0, 0, 0, 0.05)', border: '2px solid rgba(0, 0, 0, 0.05)', borderLeft: 'none', borderRight: 'none'}}>
                            <span style={{paddingLeft: '11px', fontSize: '1rem', fontWeight: 'normal'}}>
                              Stock Swaps as on {endOfMonth}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <GenericTable
                          columns={STOCK_SWAP_REGRADE_RESEASON_ITEM_COLUMNS}
                          items={stockSwapItems}
                          mainContainerStyle={{margin: '10px'}}
                        />
                      </TableBody>
                    </Table>
                  }
                  {
                    !isEmpty(regradeReseasonItems) &&
                    <Table>
                      <TableHead>
                        <TableRow colSpan={STOCK_SWAP_REGRADE_RESEASON_ITEM_COLUMNS.length}>
                          <TableCell style={{background: 'rgba(0, 0, 0, 0.05)', border: '2px solid rgba(0, 0, 0, 0.05)', borderLeft: 'none', borderRight: 'none'}}>
                            <span style={{paddingLeft: '11px', fontSize: '1rem', fontWeight: 'normal'}}>
                              Regrade Reseason as on {endOfMonth}
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <GenericTable
                          columns={STOCK_SWAP_REGRADE_RESEASON_ITEM_COLUMNS}
                          items={regradeReseasonItems}
                          mainContainerStyle={{margin: '10px'}}
                        />
                      </TableBody>
                    </Table>
                  }
                  {
                    !isEmpty(customItems) &&
                    <Table>
                      <TableHead>
                        <TableRow colSpan={CUSTOM_ITEM_COLUMNS.length}>
                          <TableCell style={{background: 'rgba(0, 0, 0, 0.05)', border: '2px solid rgba(0, 0, 0, 0.05)', borderLeft: 'none', borderRight: 'none'}}>
                            <span style={{paddingLeft: '11px', fontSize: '1rem', fontWeight: 'normal'}}>
                              Custom Items
                            </span>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <GenericTable
                          columns={CUSTOM_ITEM_COLUMNS}
                          items={customItems}
                          mainContainerStyle={{margin: '10px'}}
                        />
                      </TableBody>
                    </Table>
                  }
                  {
                    map(orderBy(invoice.items, 'itemType'), item => {
                      let description = `${item.description} | ${getCountryLabel('tonnage')} ${item?.itemType?.includes('outload') ? item.shrunkTonnage : item.tonnage} | ${currency} ${item.total} (inc. ${getCountryLabel('gst')})`
                      const columns = LOADS_COLUMNS
                      let items = []
                      if (!(item.loads)){
                        item['type'] = 'inload'
                        item['unshrunkTonnage'] = item['tonnage']
                        item['dateTime'] = ''
                        items = [item]
                      }
                      else{
                        items = getLoadsWithBalance(item.loads)
                      }
                      items = map(items, _item => {
                        _item.rate = item.rate
                        _item.subTotal = _item.rate * ((item.itemType === 'warehouseinloaditem' || item.itemType === 'warehousethroughputinloaditem') ? (_item.unshrunkTonnage || _item.tonnage) : (_item.shrunkTonnage || _item.tonnage))
                        return _item
                      })
                      return (
                        <React.Fragment>
                          {
                            !includes([CUSTOM_ITEM_TYPE, STOCK_SWAP_ITEM_TYPE, REGRADE_RESEASON_ITEM_TYPE, WAREHOUSE_STORAGE_ITEM_TYPE], item.itemType) && !isEmpty(items) &&
                            <Table key={item.id}>
                              <TableHead>
                                <TableRow colSpan={columns.length}>
                                  <TableCell style={{background: 'rgba(0, 0, 0, 0.05)', border: '2px solid rgba(0, 0, 0, 0.05)', borderLeft: 'none', borderRight: 'none'}}>
                                    <span style={{paddingLeft: '11px', fontSize: '1rem', fontWeight: 'normal'}}>
                                      {description}
                                    </span>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <GenericTable
                                  columns={columns}
                                  items={items}
                                  handleDefaultCellClick={this.handleDefaultCellClick}
                                  orderBy="dateTime"
                                  order="desc"
                                  mainContainerStyle={{margin: '10px'}}
                                />
                              </TableBody>
                            </Table>
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </AccordionDetails>
              </Accordion>
            )
          })
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
  };
};

export default connect(mapStateToProps)(WarehouseInvoiceLoads);

import React from "react";
import alertifyjs from 'alertifyjs'
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Autocomplete } from '@mui/material';
import { some, get, find, forEach, includes } from 'lodash';
import APIService from '../../services/APIService';
import CommonTextField from '../common/CommonTextField';
import { required } from '../../common/validators';
import CommonAutoSelect from "../common/autocomplete/CommonAutoSelect";
import { DIRECT_TO_BUYER_ALLOCATION, DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME, THROUGH_WAREHOUSE_ALLOCATION } from "../../common/constants";
import { currentUserCompany } from "../../common/utils";

const ALLOCATION_TYPE_DESCRIPTION = {
  'through_warehouse': 'In this type of allocation the commodity is sent to a pack house under the purchase contract. Here, once packed the commodity is sent to the final delivery site under the sale contract. There can be one or more purchase contracts fulfilling one sale contract.',
  'direct_to_buyer': 'In this type of allocation many or one purchase contract complete(s) the sale contract. Use this option if you wish to link two contracts such that tonnage delivered against purchase contract is automatically delivered against the sale contract.',
}

class ContractAllocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.isThroughWarehouseAllocationEnabled = includes(currentUserCompany().contractAllocations, THROUGH_WAREHOUSE_ALLOCATION);
    this.isDirectToBuyerAllocationEnabled = includes(currentUserCompany().contractAllocations, DIRECT_TO_BUYER_ALLOCATION);
    this.state = {
      isLoadingContracts: false,
      saleContracts: [],
      purchaseContracts: [],
      purchaseContract: null,
      saleContract: null,
      fields: {
        purchaseContractId: {
          value: '',
          validators: [required()],
          errors: []
        },
        saleContractId: {
          value: '',
          validators: [required()],
          errors: []
        },
        tonnage: {
          value: '',
          validators: [required()],
          errors: []
        },
        allocationType: {
          value: '',
          validators: [required()],
          errors: []
        },
      }
    }
  }

  componentDidMount() {
    this.setContracts()
  }

  isAssigningSaleContract = () => this.props.subjectContract?.id === this.state.purchaseContract?.id

  setContracts() {
    const { subjectContract, objectContract, allocatedTonnage } = this.props;
    let purchaseContract = null
    let saleContract = null
    if(subjectContract.isSeller) {
      saleContract = subjectContract
      purchaseContract = objectContract
    }
    else if(objectContract?.isSeller) {
      saleContract = objectContract
      purchaseContract = subjectContract
    }
    else if(subjectContract.isBuyer) {
      purchaseContract = subjectContract
      saleContract = objectContract
    }
    else if(objectContract?.isBuyer) {
      purchaseContract = objectContract
      saleContract = subjectContract
    }

    const newState = {...this.state}
    newState.purchaseContract = purchaseContract
    newState.saleContract = saleContract
    newState.purchaseContracts = [purchaseContract]
    newState.saleContracts = [saleContract]
    newState.fields.purchaseContractId.value = purchaseContract?.id || ''
    newState.fields.saleContractId.value = saleContract?.id || ''
    newState.fields.tonnage.value = allocatedTonnage || '';
    if (this.props.edit && objectContract)
      newState.fields.allocationType.value = objectContract.allocationType == DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME ? DIRECT_TO_BUYER_ALLOCATION : THROUGH_WAREHOUSE_ALLOCATION;
    this.setState(newState, () => {
      if(!this.props.edit)
        this.fetchAllocationCandidates()
    })
  }

  __getService = contractId => APIService.contracts(contractId)

  fetchAllocationCandidates = () => {
    const { isLoadingContracts, purchaseContract, saleContract } = this.state
    if(!isLoadingContracts) {
      this.setState({isLoadingContracts: true}, () => {
        let contract;
        let path;
        let contractsKey;
        let query_params = '';
        let joiner = '?';
        if (!this.isThroughWarehouseAllocationEnabled) {
          query_params = '?exclude_through_warehouse_allocation_contracts=true';
          joiner = '&';
        }
        if (!this.isDirectToBuyerAllocationEnabled)
          query_params = `${joiner}exclude_direct_to_buyer_allocation_contracts=true`;
        if(this.isAssigningSaleContract()) {
          contract = purchaseContract
          path = 'sale-allocations-candidates/'
          contractsKey = 'saleContracts'
        } else {
          contract = saleContract
          path = 'purchase-allocations-candidates/'
          contractsKey = 'purchaseContracts'
        }
        if (query_params)
          path += query_params;
        this.__getService(contract.id).appendToUrl(path).get().then(candidates => this.setState({[contractsKey]: candidates, isLoadingContracts: false}))
      })
    }
  }

  onContractChange = (id, selectedContract) => {
    if(this.state.fields[id].value !== selectedContract?.id) {
      const newState = {...this.state}
      if(this.isAssigningSaleContract())
        newState.saleContract = selectedContract
      else
        newState.purchaseContract = selectedContract
      newState.fields[id].value = selectedContract?.id
      newState.fields[id].errors = selectedContract?.id ? [] : newState.fields[id].errors
      newState.fields.tonnage.value = newState.saleContract?.id ? newState.saleContract.unallocatedTonnage : ''
      newState.fields.tonnage.errors = selectedContract?.id ? [] : newState.fields.tonnage.errors
      if (selectedContract.allocationCompatibleType === DIRECT_TO_BUYER_ALLOCATION)
        newState.fields.allocationType.value = DIRECT_TO_BUYER_ALLOCATION
      else if (selectedContract.allocationCompatibleType === THROUGH_WAREHOUSE_ALLOCATION)
        newState.fields.allocationType.value = THROUGH_WAREHOUSE_ALLOCATION
      else if (this.isThroughWarehouseAllocationEnabled && !this.isDirectToBuyerAllocationEnabled)
        newState.fields.allocationType.value = THROUGH_WAREHOUSE_ALLOCATION
      else if (this.isDirectToBuyerAllocationEnabled && !this.isThroughWarehouseAllocationEnabled)
        newState.fields.allocationType.value = DIRECT_TO_BUYER_ALLOCATION
      this.setState(newState)
    }
  }

  onSubmit = event => {
    event.preventDefault()
    this.setAllFieldsErrors(() => {
      const { fields } = this.state
      const isFormInvalid = some(fields, field => field.errors.length > 0);
      if(!isFormInvalid) {
        if (this.state.fields.allocationType.value === DIRECT_TO_BUYER_ALLOCATION) {
          this.props.onClose();
          const data = [{id: fields.saleContractId.value, tonnage: fields.tonnage.value}];
          const service = this.__getService(fields.purchaseContractId.value).appendToUrl('sale-allocations/')
          service.put(data).then(() => {
            alertifyjs.success('Successfully updated direct to buyer allocations', 1, () => {
              window.location.reload()
            })
          })
        }
        else if (this.state.fields.allocationType.value === THROUGH_WAREHOUSE_ALLOCATION) {
          this.props.onClose();
          let data = [{id: fields.saleContractId.value, tonnage: fields.tonnage.value}];
          let path = 'through-warehouse-allocations/';
          if (this.props.edit) {
            path = `through-warehouse-allocations/${fields.saleContractId.value}/`
            data = {tonnage: fields.tonnage.value}
          }
          const service = this.__getService(fields.purchaseContractId.value).appendToUrl(path)
          service.put(data).then(() => {
            alertifyjs.success(`Successfully updated through warehouse allocation`, 1, () => {
              window.location.reload()
            })
          })
        }
      }

    })
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => newState.fields[key].errors = this.getFieldErrors(key));
    this.setState(newState, callback);
  }

  getUnAllocatedTonnageFromContract = contract => contract.unallocatedTonnage || contract.unallocatedTonnageForUser || 0
  getAllocatedTonnageFromContract = contract => contract.allocatedTonnage || contract.allocatedTonnageForUser || 0

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    if(key === 'tonnage' && value) {
      if (this.state.fields.allocationType.value === DIRECT_TO_BUYER_ALLOCATION) {
        const objectContractAllocatedTonnage = get(this.props.objectContract, 'allocatedTonnage', 0);
        const allowedPurchaseContractTonnage = this.getUnAllocatedTonnageFromContract(this.state.purchaseContract) + (this.props.edit ? objectContractAllocatedTonnage : 0)
        const allowedSaleContractTonnage = this.getUnAllocatedTonnageFromContract(this.state.saleContract) + (this.props.edit ? objectContractAllocatedTonnage : 0)
        if (value > allowedPurchaseContractTonnage)
          errors.push(`Tonnage should be lesser than or equal to Purchase Contract unallocated tonnage (${allowedPurchaseContractTonnage}MT)`)
        else if (value > allowedSaleContractTonnage)
          errors.push(`Tonnage should be lesser than or equal to Sale Contract unallocated tonnage (${allowedSaleContractTonnage}MT)`)
      }
      else if (this.state.fields.allocationType.value === THROUGH_WAREHOUSE_ALLOCATION) {
        let allowedTonnage = this.getUnAllocatedTonnageFromContract(this.state.saleContract)
        if (this.props.edit)
          allowedTonnage += this.props.allocatedTonnage;
        if (value > allowedTonnage)
          errors.push(`Tonnage should be lesser than or equal to sale contract unallocated tonnage ${allowedTonnage} ${get(this.props.subjectContract, 'commodity.priceUnit')}.`);
        else if (value > get(this.state.purchaseContract, 'unaccountedTonnage'))
          errors.push(`Tonnage cannot be greater than purchase contract unaccounted tonnage ${get(this.state.purchaseContract, 'unaccountedTonnage')} ${get(this.props.subjectContract, 'commodity.priceUnit')}.`)
      }
    }

    return errors;
  }

  handleTonnageChange = event => this.setState({fields: {...this.state.fields, tonnage: {...this.state.fields.tonnage, value: event.target.value}}})

  handleAllocationTypeChange = value => {
    const newState = {...this.state};
    newState.fields.allocationType.value = value;
    if (value)
      newState.fields.allocationType.errors = [];
    this.setState(newState)
  }

  render() {
    const { open, onClose, edit } = this.props
    const { purchaseContracts, saleContracts, fields } = this.state
    const isAssigningSaleContract = this.isAssigningSaleContract()
    const isBothAllocationsEnabled = this.isThroughWarehouseAllocationEnabled && this.isDirectToBuyerAllocationEnabled;
    let ALLOCATION_TYPES = []
    if (this.isThroughWarehouseAllocationEnabled)
      ALLOCATION_TYPES.push({id: 'through_warehouse', name: 'Through Warehouse'});
    if (this.isDirectToBuyerAllocationEnabled)
      ALLOCATION_TYPES.push({id: 'direct_to_buyer', name: 'Direct To Buyer'})

    return (
      <React.Fragment>
        <Dialog fullWidth open={open} onClose={onClose}>
          <DialogTitle>
            Create Contract Allocation
          </DialogTitle>
          <DialogContent>
            <div className='col-xs-12 padding-reset' style={{marginBottom: '15px'}}>
              <Autocomplete
                id='purchaseContractId'
                options={purchaseContracts || []}
                value={find(purchaseContracts, {id: fields.purchaseContractId.value})}
                getOptionLabel={option => get(option, 'displayNameWithCounterParty') || get(option, 'displayName') || ''}
                onChange={(event, option) => this.onContractChange('purchaseContractId', option)}
                renderInput={params => (
                  <CommonTextField
                    {...params}
                    variant="standard"
                    autoComplete='off'
                    fullWidth
                    label="Purchase Contract"
                    helperText={get(fields.purchaseContractId.errors, '0')}
                    error={Boolean(get(fields.purchaseContractId.errors, '0'))}
                    disabled={edit || isAssigningSaleContract}
                    value={edit || isAssigningSaleContract || undefined} // to show lock
                  />
                )}
                disabled={edit || isAssigningSaleContract}
              />
            </div>
            <div className='col-xs-12 padding-reset' style={{marginBottom: '15px'}}>
                <Autocomplete
                  id='saleContractId'
                  options={saleContracts || []}
                  value={find(saleContracts, {id: fields.saleContractId.value})}
                  getOptionLabel={option => get(option, 'displayNameWithCounterParty') || get(option, 'displayName') || ''}
                  onChange={(event, option) => this.onContractChange('saleContractId', option)}
                  renderInput={params => (
                    <CommonTextField
                      {...params}
                      variant="standard"
                      autoComplete='off'
                      fullWidth
                      label="Sale Contract"
                      helperText={get(fields.saleContractId.errors, '0')}
                      error={Boolean(get(fields.saleContractId.errors, '0'))}
                      disabled={edit || !isAssigningSaleContract}
                      value={edit || !isAssigningSaleContract || undefined} // to show lock
                    />
                  )}
                  disabled={edit || !isAssigningSaleContract}
                />
            </div>
            <div className='col-xs-12 padding-reset' style={{marginBottom: '15px'}}>
              <CommonTextField
                label='Allocated Tonnage'
                value={fields.tonnage.value}
                helperText={get(fields.tonnage.errors, '0')}
                error={Boolean(get(fields.tonnage.errors, '0'))}
                type='number'
                onChange={this.handleTonnageChange}
              />
            </div>
            {isBothAllocationsEnabled &&
            <>
              <div className='col-xs-12 padding-reset' style={{marginBottom: '15px'}}>
                <CommonAutoSelect
                  id="allocationType"
                  label='Allocation Type'
                  value={fields.allocationType.value}
                  onChange={this.handleAllocationTypeChange}
                  errorText={get(fields.allocationType.errors, '0')}
                  items={ALLOCATION_TYPES}
                  disabled={get(this.state.saleContract, 'allocationCompatibleType') || get(this.state.purchaseContract, 'allocationCompatibleType') || edit}
                />
              </div>
              {this.state.fields.allocationType.value &&
                <div className='col-xs-12 padding-reset' style={{marginBottom: '15px'}}>
                  <span><b>Description: </b>{get(ALLOCATION_TYPE_DESCRIPTION, this.state.fields.allocationType.value)}</span>
                </div>
              }
            </>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button onClick={this.onSubmit} color='primary'>Save</Button>
          </DialogActions>
        </Dialog>

      </React.Fragment>
    )
  }
}

export default ContractAllocationForm;

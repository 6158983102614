import React from 'react';

import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import AddButton from '../common/AddButton';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';

import withStyles from '@mui/styles/withStyles';
import { createNote, getNotes, receiveNotes, getAuditHistory, receiveHistory} from '../../actions/companies/notes';
import { isLoading, forceStopLoader, setHeaderText, setSubHeaderText, setBreadcrumbs } from '../../actions/main';
import { initials, getContractSubHeaderText, getOrderSubHeaderText, getOrderHeaderText } from '../../common/utils';
import FileUpload from '../common/FileUpload';
import moment from 'moment';
import Chip from '@mui/material/Chip';
import './Notes.scss';
import Button from "@mui/material/Button/Button";
import {CALL_ON_GRAIN_TYPE_ID, ORDER_TYPE_ROUTE_MAPPING, PACK_ORDER_TYPE_ID, PRIMARY_COLOR_GREEN} from "../../common/constants";
import { omit, startCase, forEach, has, orderBy, isEmpty, isEqual, get, filter, keys } from "lodash";
import ContactMail from '@mui/icons-material/ContactMail';
import Tooltip from '@mui/material/Tooltip';
import alertifyjs from 'alertifyjs';
import {getHeaderTitle} from '../invoices/invoiceDetails/InvoiceDetails';
import map from 'lodash/map';

const styles = () => ({
  root: {
    width: '100%',
    maxWidth: 600,
    backgroundColor: '#fff',
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: '8px',
    marginRight: '8px',
  },
});

class Notes extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      requestCounterMap: {},
      description: "",
      attachments: [],
      companyId: this.props.userCompanyId,
      objectId: this.props.objectId,
      objectType: this.props.objectType,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.handleAmendOpen = this.handleAmendOpen.bind(this);
    this.loaderInterval = null;
  }

  handleSubmit(){
    this.props.isLoading();
    let submitData = this.getSubmitData();
    this.props.addNote(submitData);
    const newState = {...this.state};
    newState.description = "";
    this.setState(newState);
  }

  getSubmitData() {
    return omit(this.state, ['requestCounterMap']);
  }

  componentDidUpdate(prevProps) {
    if(this.props.objectType != 'cash_prices' && !isEqual(this.props.notes,prevProps.notes)){
      document.getElementById('attachment-image-preview').innerHTML = '';
      const newState = {...this.state};
      let requestCounter = 1;
      const notes = [...this.props.notes].reverse();
      forEach(notes, (note) => {
        let acceptanceRequestId = get(note, 'args.acceptanceRequestId', get(note,'args.contractAcceptanceRequestId'));
        if(
          acceptanceRequestId &&
          !has(newState.requestCounterMap, acceptanceRequestId) &&
          !isEmpty(get(note, 'amendedDetails.amended'))
        ) {
          newState.requestCounterMap[acceptanceRequestId] = requestCounter;
          requestCounter += 1;
          this.setState(newState);
        }
      });
      newState.attachments = [];
      this.setState(newState, this.props.forceStopLoader);
    }
  }


  manageLoader = () => {
    const func = () => {
      if((this.props._history === false || this.props._notes === false) && !this.props.isLoaderApplied){
        this.props.isLoading('auditHistoryNotesLoader')
      }
      else if (this.props._history !== false && this.props._notes !== false && this.props.isLoaderApplied){
        this.props.isLoading('')
        this.props.forceStopLoader()
        clearInterval(this.loaderInterval)
      }
    }
    if (this.props.objectType != 'cash_prices' )
      this.loaderInterval = setInterval(func, 200)
  }

  componentWillUnmount() {
    clearInterval(this.loaderInterval)
  }

  handleCommentChange(data){
    const newState = {...this.state};
    newState.description = data.target.value;
    this.setState(newState);
  }

  handleAmendOpen = (note) => {
    this.props.onAmendOpen(note, this.state.requestCounterMap);
  };

  handleUpload(fileState){
    const newState = {...this.state};
    newState.attachments.push(fileState.file);
    this.setState(newState);
  }

  handleFileRemove(base64) {
    const newState = {...this.state};
    newState.attachments = filter(newState.attachments, (a) => {return a.base64 !== base64;});
    this.setState(newState);
  }

  componentDidMount(){
    this.props.isLoading('auditHistoryNotesLoader')
    this.props.clearAuditHistory()
    this.props.clearNotes()
    this.manageLoader()
    this.props.objectType != 'cash_prices' ? this.props.getNotes(this.props.companyId, this.props.objectId, this.props.objectType, receiveNotes) : null;
    this.props.getAuditHistory(this.props.objectId, this.props.objectType);
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    let breadcrumbs = [
      {text: 'Audit History'},
    ];
    let headerText = 'Audit History';
    if(this.props.objectId){
      if (this.props.objectType === 'contract') {
        this.props.setSubHeaderText(getContractSubHeaderText(this.props.contract));
        headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
        breadcrumbs = [
          {text: 'Contracts', route: '/Contracts'},
          {text: get(this.props.contract, 'referenceNumber', ''), route: '/Contracts/' + this.props.objectId + '/contract'},
          {text: 'Audit History'},
        ];
      } else if (this.props.objectType === 'freightorder') {
        this.props.setSubHeaderText(getOrderSubHeaderText(this.props.order));
        headerText = getOrderHeaderText(this.props.order)

        const orderTypeId = get(this.props.order, 'typeId')
        const orderType = get(ORDER_TYPE_ROUTE_MAPPING, orderTypeId, 'freights')

        const orderRoute = isPackOrder ? '/pack/orders/' : '/freights/orders/';
        const isPackOrder = get(this.props.order, 'typeId') === PACK_ORDER_TYPE_ID;
        breadcrumbs = [
          {text: 'Orders', route: `/orders/${orderType}`},
          {text: get(this.props.order, 'identifier', ''), route: orderRoute + this.props.objectId + '/order' },
          {text: 'Audit History'}
        ];
      } else if (this.props.objectType === 'freightcontract') {
        this.props.setSubHeaderText(getOrderSubHeaderText(this.props.movement));
        headerText = 'Freight Movement ' + get(this.props.movement, 'identifier', '');
        breadcrumbs = [
          {text: 'Movements', route: '/movements/freights'},
          {text: get(this.props.movement, 'identifier', ''), route: '/freights/movements/' + this.props.objectId + '/details' },
          {text: 'Audit History'}
        ];
      } else if (this.props.objectType === 'invoice') {
        headerText = getHeaderTitle(this.props.invoiceDetails);
        breadcrumbs = [
          {text: 'Invoices', route: '/invoices'},
          {text: get(this.props.invoiceDetails, 'identifier'), route: '/invoices/' + this.props.objectId + '/details' },
          {text: 'Audit History'}
        ];
      }
    }
    this.props.setHeaderText(headerText);

    if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }

  }

  isCallOnGrainOrder() {
    return get(this.props.order, 'typeId') === CALL_ON_GRAIN_TYPE_ID
  }

  onMailIconClick(note) {
    const subjectHTML = `<div style="font-style: italic;">${get(note, 'communication.subject')}</div>`;
    let recipientsHTML = '';
    forEach(get(note, 'communication.recipients', []), (recipients, party) => {
      if (party === 'provider') party = 'freight provider';
      if (party === 'consignor') party = 'Pickup Site';
      if (party === 'consignee') party = 'Delivery Site';
      recipientsHTML += `<div style="margin-bottom: 5px;"><span style="font-weight: 500;">${startCase(party)}: </span>${recipients.join(', ')}</div>`;
    });
    alertifyjs.alert(
      'Mail Info',
      `<div><div style="font-weight: 500; margin-bottom: 5px;">SUBJECT</div>${subjectHTML}</div><div><div style="font-weight: 500; margin-bottom: 5px; margin-top: 10px;">RECIPIENTS</div>${recipientsHTML}</div>`,
      () => {},
    );
  }


  render() {
    const disabled = this.state.description.trim() ? false : true;
    const style = this.props.objectType != 'cash_prices' ? {border: '1px solid black', borderRadius: '5px', minHeight: '300px' } : null ;
    return (
      <Paper className="paper-table col-md-12">
        <div className="col-md-12">
          { this.props.objectType != 'cash_prices' && <h3>Notes</h3> }

          <div>
            <div className={this.props.objectType != 'cash_prices' ? "cardForm-content col-md-6" : "cardForm-content col-md-12"} style={style}>
              {map(this.props.notes, note => (
                <div key={get(note, 'id')} className="col-md-12" style={{padding: '10px'}}>
                  <div className="col-md-1 col-sm-1 user-info" style={{marginLeft: '-30px'}}>
                      { get(note, 'actor') === "Admin Support (AgriChain)" && <div className="content-box--header admin-support-logo">
                          <img src="images/agrichain-logo-icon.png" alt="Agri Chain Logo"/></div> }
                      { get(note, 'actor') !== "Admin Support (AgriChain)" && <IconButton
                        touch="true"
                        className={'user-info-icon'}
                        style={{marginBottom: '25px'}}
                        size="large">
                      {initials(note.createdBy)}
                    </IconButton> }
                  </div>
                  <div className="col-md-11 col-sm-11" style={{width: '95%'}}>
                    <div className="col-md-12" style={{fontSize: '12px', fontWeight: '500'}}>
                      {get(note, 'actor') || get(note, 'createdBy.name')} {get(note, 'entity') === 'note' ? "(Internal Only)" : ""}
                    </div>
                    <div className="col-md-12" style={{fontSize: '12px', color: '#808080'}}>
                      {moment(get(note, 'args.createdAt')|| note.createdAt).format('LLLL')}
                    </div>
                    <div className="col-md-12" style={{whiteSpace: 'pre-wrap', textAlign: 'justify', fontSize: '14px', padding: '5px 0px 10px 15px'}}>
                      {
                        get(note, 'actor') ?
                        <i dangerouslySetInnerHTML={{__html: get(note, 'description')}}></i> :
                        note.description
                      }
                      {
                        get(note, 'communication.recipients') &&
                        <Tooltip title='Mail Info'>
                          <IconButton
                            onClick={() => this.onMailIconClick(note)}
                            style={{marginTop: '-4px'}}
                            size="large">
                            <ContactMail color='primary' />
                          </IconButton>
                        </Tooltip>
                      }
                    </div>
                    <div className="col-md-12" style={{whiteSpace: 'pre-wrap', textAlign: 'justify', fontSize: '14px', padding: '5px 0px 10px 15px'}}>
                      {
                        !isEmpty(get(note, 'amendedDetails.amended')) && <Button onClick={() => this.handleAmendOpen(note)} style={{color: PRIMARY_COLOR_GREEN, fontSize: '0.75rem', alignRight: true, padding: '0px'}}>
                       Review Amendment {get(this.state.requestCounterMap, (get(note,'args.acceptanceRequestId', get(note,'args.contractAcceptanceRequestId')))) ? keys(this.state.requestCounterMap).length === 1 ? null : get(this.state.requestCounterMap, (get(note,'args.acceptanceRequestId', get(note,'args.contractAcceptanceRequestId')))) : null}
                        </Button>
                      }
                    </div>
                    <div className="col-md-12">
                      {get(note, 'attachments') ? note.attachments.map((file, index) => (
                        <div style={{marginTop: '2px'}}key={index}>
                          <a target="_blank" rel="noopener noreferrer" href={file.url}>
                            <Chip style={{cursor: 'pointer'}} label={file.name} variant="outlined" />
                          </a>
                        </div>
                      )) : ''}
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="cardForm-content col-md-6">
              { this.props.objectType != 'cash_prices' &&
              <div>
                  <TextField
                    className="text-area"
                    id="note"
                    value={this.state.description}
                    fullWidth
                    multiline={true}
                    placeholder=" Please click here to add internal notes."
                    onChange={this.handleCommentChange}
                    rows={6}
                    style={{border: '1px solid black', borderRadius: '5px'}}
                    variant="standard" />
                  <AddButton
                    style={{float: '', margin: '10px 0px', maxHeight: '38px'}}
                    label="Add Note"
                    type="submit" app="notes"
                    disabled={disabled}
                    onClick={this.handleSubmit}/>
                  <FileUpload
                    className="col-md-4 col-xs-4"
                    id="attachment"
                    floatingLabelText="Attach File"
                    fullWidth={true}
                    textFieldstyle={{float: 'left', color: 'black'}}
                    mainStyle={{marginTop: '10px'}}
                    buttonStyle={{border: '1px solid'}}
                    onChange={this.handleUpload}
                    buttonText="Attach File"
                    allowedExtensions="image/*,application/pdf"
                    previewStyle={{marginTop: '10px', width: '150%'}}
                    onRemove={this.handleFileRemove}
                  />
                  </div>
                  }
            </div>
          </div>
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
  let items = state.companies.notes.items ? state.companies.notes.items : []
  let notes = state.companies.notes.history ? items.concat(state.companies.notes.history) : state.companies.notes.items || [];

  if (!isEmpty(notes)){
    notes = orderBy(notes, ['createdAt'], 'desc');
  }

  return {
    userCompanyId: state.main.user.user.companyId,
    breadcrumbs: state.main.breadcrumbs,
    notes: notes,
    _notes: state.companies.notes.items,
    _history: state.companies.notes.history,
    isLoaderApplied: state.main.isLoading
  };
};

const mapDispatchToProps = dispatch => ({
  clearAuditHistory: () => dispatch(receiveHistory(false)),
  clearNotes: () => dispatch(receiveNotes(false)),
  addNote:(data) => dispatch(createNote(data)),
  getNotes: (companyId, objectId, objectType) => dispatch(getNotes(companyId, objectId, objectType, receiveNotes)),
  getAuditHistory: (objectId, objectType) => dispatch(getAuditHistory(objectId, objectType)),
  isLoading: text => dispatch(isLoading(text)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  setHeaderText: headerText => dispatch(setHeaderText(headerText)),
  setSubHeaderText: headerText => dispatch(setSubHeaderText(headerText)),
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Notes));

import React from 'react';
import  { useSelector, useDispatch }  from 'react-redux';
import { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import { get } from 'lodash';
import CustomEmailDialog from './CustomEmailDialog';
import find from 'lodash/find';
import { isCurrentUserBroker } from '../../common/utils';
import { isLoading, forceStopLoader } from '../../actions/main';


let BrokerageCustomEmail = props => {

    const [isLoaded, setLoaded ] = useState(false);
    const [allContactsList, setAllContactsList] = useState({});
    const [partyEmails, setPartyEmails] = useState();
    const dispatch = useDispatch();

    const token = useSelector(state => {
        return state.main.user.token;
    });
    const user = useSelector(state => {
        return state.main.user.user;
    });

    let getSelectedParties = () => {
        if (isCurrentUserBroker()){
            return ["billToParty"];
        }
        return ["broker"];
    };

    let getCompanyEmployees = async (companyId) => {

        let employees = companyId ?  await APIService.companies(companyId)
                                      .employees()
                                      .get(props.token): "";
        return employees;
    };

    let getBrokerCompanies = async() => {
        return await APIService.companies().get(token, undefined, { type_id: 2 });
    };

    let BrokerRepresentedCompanies = async(companyId, query) => {
            return await APIService.companies(companyId).companies().appendToUrl('minimal/').get(token, null, query);
    };

    let setBrokerAndBillToContacts = async invoiceDetails => {
        dispatch(isLoading('CustomEmailDialog'));
        let broker = [];
        let billToContact = [];
        let brokerEmail = null;
        let billtoContactEmail = null;
        if(isCurrentUserBroker()){
            broker = await getCompanyEmployees(user.companyId);
            brokerEmail = user.email;
            let billToCompanies = await BrokerRepresentedCompanies(user.companyId, { include_parent_company: true,
                                    user_properties: 'brokerages_for_user' });
            let billToCompanyId = get(find(billToCompanies, {'name': get(invoiceDetails, 'payerDisplayName')}), 'id');
            billToContact = await getCompanyEmployees(billToCompanyId);
            billtoContactEmail = get(find(billToContact, {'name': get(invoiceDetails, 'payer.contactName')}), 'email');
        }
        else {
            let brokerCompanies = await getBrokerCompanies();
            let brokerCompanyId = get(find(brokerCompanies, {'name': get(invoiceDetails,
                                    'payeeDisplayName')}), 'id');
            billtoContactEmail = user.email;
            broker = await getCompanyEmployees(brokerCompanyId);
            billToContact = get(invoiceDetails, 'sentBy.email');
        }
        setAllContactsList({
            'broker':broker,
            'billToParty': billToContact
        });
        setPartyEmails({
            'broker': brokerEmail,
            'billToParty': billtoContactEmail
        });
        setLoaded(true);
        dispatch(forceStopLoader());
    };

    useEffect(() => {
        if (props.invoiceDetails && get(props, 'invoiceDetails.type') == 'Brokerage' && props.showCustomEmailDialog){
            setLoaded(false);
            setBrokerAndBillToContacts(props.invoiceDetails);
        }
    },[props.invoiceDetails, props.showCustomEmailDialog]);

    let getPartyContacts = () =>{
        return allContactsList;
    };

    let getPartyEmails = () =>{
        return partyEmails;
    };

    let getEmailSubject = () =>{
        if (props.subject){
            return props.subject;
        }
        let companyName = user.company.name;
        return `[Void] ${companyName} Brokerage Invoice #${get(props, 'invoiceDetails.identifier')}`;
    };

    return (<span>
        {
            props.invoiceDetails && props.showCustomEmailDialog && isLoaded &&
                <CustomEmailDialog
                    parties={['billToParty', 'broker']}
                    selectedParties={getSelectedParties()}
                    title='Void Invoice'
                    partyEmails={getPartyEmails()}
                    partyContacts={getPartyContacts()}
                    subject={getEmailSubject()}
                    noBody={true}
                    open={props.showCustomEmailDialog}
                    onClose={props.closeCustomEmailDialog}
                    disableAcceptanceRequired={false}
                />
        }
        </span>);

};

export default BrokerageCustomEmail;
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import {
  addCompanySiteErrors,
  editCompanySiteErrors,
  receiveFreightSlots,
  receiveFreightSlot,
  receiveFreightSlotUpdated,
  deleteFreightSlot,
  deleteMultipleFreightSlots,
  receiveSMSettings,
  receiveSlotCommentUpdated,
  receiveSlotComment,
  receiveSlotComments,
} from '../../actions/companies/company-sites';

const MESSAGES = {
  CREATE_SUCCESS: 'Site was successfully added.',
  UPDATE_SUCCESS: 'Site details were successfully updated.',
};
import { setLoadingText, forceStopLoader } from '../../actions/main';
import {isArray, isEmpty, isObject, has, get, uniqBy} from 'lodash';

export const getCompanySites = (companyId, actionCreator, callback, query, isActive) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    const service = APIService.companies(companyId).company_sites();
    if(query)
      service.appendToUrl(query);
    if (isActive !== undefined)
      query ? service.appendToUrl(`&is_active=${isActive}`) : service.appendToUrl(`is_active=${isActive}`);
    service.get(token)
      .then(items => {
        dispatch(actionCreator(items));
        if (callback) callback(items);
      });
  }
};

export const getAllSlots = (companyId, start, end, siteId, stopLoader) => (dispatch, getState) => {
  setTimeout(() => {
    dispatch(setLoadingText('Loading slots...'));
  }, 2000);
  const { token } = getState().main.user;
  APIService.company_sites()
    .slots()
    .get(token, {'original-unit': true}, { start: start, end: end, company_id: companyId, site_id: siteId })
    .then(items => {
      dispatch(receiveFreightSlots(items));
      if (stopLoader) dispatch(forceStopLoader());
    });
};

export const getOpenOrSelfSlots = (companyId, siteCompanyId, start, end, siteId, stopLoader) => (dispatch, getState) => {
  setTimeout(() => {
    dispatch(setLoadingText('Loading slots...'));
  }, 2000);
  const { token } = getState().main.user;
  APIService.company_sites()
    .slots()
    .appendToUrl('open-or-self/')
    .get(
      token,
      {'original-unit': true},
      { start: start, end: end, company_id: companyId, site_company_id: siteCompanyId, site_id: siteId }
    )
    .then(items => {
      dispatch(receiveFreightSlots(items));
      if (stopLoader) dispatch(forceStopLoader());
    });
};

export const createSlot = (data, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  const service = APIService.company_sites(data.siteId);
  service
    .slots()
    .post(data, token, {'original-unit': true})
    .then(item => {
      dispatch(receiveFreightSlot(item));
      if (callback) {
        callback();
      }
    });
};

export const createSlots = (siteId, data, callback) => (dispatch, getState) => {
  dispatch(setLoadingText('Creating slot(s)...'));
  if (data) {
    const { token } = getState().main.user;
    const service = APIService.company_sites(siteId);

    service
      .slots()
      .post(data, token, {'original-unit': true})
      .then(response => {
        if(!response.persisted) {
          alertifyjs.error('An Error Occurred!');
          dispatch(forceStopLoader());
        } else {
          if(callback) callback(response);
        }
      });
  }
};

export const updateSlot = (slotId, data, callback, newSlotId, newCounterSlotId='') => (dispatch, getState) => {
  const { token } = getState().main.user;
  let slotService = APIService.company_sites(data.siteId).slots(slotId)
  if(newSlotId)
    slotService = slotService.appendToUrl('?new_slot_id=' + newSlotId)
  if(newCounterSlotId)
    slotService = slotService.appendToUrl('&new_counter_slot_id=' + newCounterSlotId)

  slotService
    .put(data, token, {'original-unit': true})
    .then(item => {
      if(get(item, 'errors.version')) {
        dispatch(forceStopLoader());
        alertifyjs.error(item.errors.version, 0);
      }
      else if(get(item, 'errors.truck.0')) {
        dispatch(forceStopLoader());
        alertifyjs.error(get(item.errors, 'truck.0'), 0);
      } else if (isArray(get(item, 'reasons')) && !isEmpty(get(item, 'reasons'))) {
        dispatch(forceStopLoader());
        const reasons = '<li>' + item.reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div><p>You cannot cancel this Slot because:</p><ul>${reasons}</ul></div>`,
          () => {}
        );
      }
      else if (isArray(get(item, 'errors')) && !isEmpty(get(item, 'errors'))) {
        dispatch(forceStopLoader());
        const errors = '<li>' + item.errors.join('</li><li>');
        alertifyjs.alert(
          'Error:',
          `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul></div>`,
          () => {},
        );
      }
      else {
        dispatch(receiveFreightSlotUpdated(item));
        if (callback) callback();
      }
    });
};

export const bulkUpdateSlot = (siteId, data, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites(siteId)
    .slots()
    .put(data, token, {'original-unit': true})
    .then(response => {
      if(callback)
        callback(response)
    });
};

export const deleteSlot = (siteId, slotId, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites(siteId)
    .slots(slotId)
    .delete(token)
    .then(response => {
      if(get(response, 'alert'))
        alertifyjs.error(response.alert, 0)
      else {
        dispatch(deleteFreightSlot(slotId));
        if (callback)
          callback();
      }
    });
};

export const deleteForwardSiblingsSlots = (siteId, slotId, callback, failureCallback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites(siteId)
    .slots(slotId)
    .appendToUrl('forward/')
    .delete(token)
    .then(res => {
      if (res && res?.error) {
        dispatch(forceStopLoader());
        alertifyjs.error(res.error);
        if (failureCallback) failureCallback();
      }
      else if (res && res?.ids) {
        dispatch(deleteMultipleFreightSlots(res.ids));
        if (callback) callback();
      }
    });
};

export const deleteAllSiblingsSlots = (siteId, slotId, callback, failureCallback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites(siteId)
    .slots(slotId)
    .appendToUrl('all/')
    .delete(token)
    .then(res => {
      if (res && res?.error) {
        dispatch(forceStopLoader());
        alertifyjs.error(res?.error);
        if (failureCallback) failureCallback();
      }
      else if (res && res?.ids) {
        dispatch(deleteMultipleFreightSlots(res.ids));
        if (callback) callback();
      }
    });
};

export const getSMSettings = (companyId, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  const { user } = getState().main.user;
  APIService.companies(companyId || user.companyId)
    .appendToUrl('site-management/settings/')
    .get(token)
    .then(res => {
      if (res) dispatch(receiveSMSettings(res));
      if (callback) callback();
    });
};

export const updateSMSettings = (data, companyId, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  const { user } = getState().main.user;
  APIService.companies(companyId || user.companyId)
    .appendToUrl('site-management/settings/')
    .put(data, token)
    .then(res => {
      if (res) {
        dispatch(receiveSMSettings(res));
        alertifyjs.success('SM Settings updated successfully');
      }
      if (callback) callback();
    });
};

export const getCompanyBHCSites = (companyId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    const api = APIService.companies(companyId);
    api.appendToUrl('bhc/locations/');
    api.get(token).then(items => {
      dispatch(actionCreator(uniqBy(items, 'name')));
    });
  }
};

export const createCompanySite = (companyId, data, actionCreator, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .company_sites()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addCompanySiteErrors(has(item, 'errors') ? item.errors : item));
      } else {
        dispatch(actionCreator(item));
        callback ? callback(item) : "";
        if (isObject(item)) {
          alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        }
      }
    });
};

export const updateCompanySite = (companyId, companySiteId, data, actionCreator, callback, skipSuccessMessage = false) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .company_sites(companySiteId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editCompanySiteErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        if (isObject(item)) {
          if(!skipSuccessMessage)
            alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
        }
        callback ? callback(item) : "";
        dispatch(forceStopLoader());
      }
    });
};

export const createSlotComment = (siteId, slotId, data, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites(siteId)
    .slots(slotId)
    .appendToUrl('comments/')
    .post(data, token)
    .then(comment => {
      dispatch(receiveSlotComment(comment));
      if (callback) {
        callback();
      }
    });
};

export const updateSlotComment = (commentId, data, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites()
    .comments(commentId)
    .put(data, token)
    .then(comment => {
      dispatch(receiveSlotCommentUpdated(comment));
      if (callback) {
        callback();
      }
    });
};

export const getSlotComments = (siteId, slotId, callback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.company_sites(siteId)
    .slots(slotId)
    .appendToUrl('comments/')
    .get(token)
    .then(comments => {
      dispatch(receiveSlotComments(comments));
      if (callback) {
        callback();
      }
    });
};

import React from "react";
import SideDrawer from "../common/SideDrawer";
import { get, isEmpty, map } from "lodash";
import CommonTextField from "../common/CommonTextField";
import { Button } from "@mui/material";

class InvoiceGrainLevies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      levies: [],
      drawerOpen: true,
    }
  }

  componentDidMount() {
    this.setState({levies:  get(this.props.invoiceItem, 'levyItems')});
  }

  onChange(event, levy) {
    let value = event.target.value;
    value = parseFloat(value);
    if (isNaN(value))
      value = 0;
    if (value > 0)
      value = value * -1;
    const updatedLevies = this.state.levies.map(levyObj => levyObj.id === levy.id ? { ...levyObj, total: value } : levyObj);
    this.setState({levies: updatedLevies})
  }

  handleSubmit() {
    this.props.updateData(this.props.invoiceItem, this.state.levies);
    this.props.onClose()
  }

  render() {
    return (
      <SideDrawer
        isOpen
        title="Edit Levies"
        onClose={() => this.props.onClose()}>
        <div>
          {!isEmpty(this.state.levies) &&
          <>
          {
            map(this.state.levies, (levy, index) => (
              <div key={index} style={{marginTop: '20px'}}>
              <CommonTextField
                id={levy.levyType}
                label={levy.levyTypeDisplay}
                placeholder={levy.levyTypeDisplay}
                onChange={(event) => this.onChange(event, levy)}
                value={levy.total}
              />
              </div>
          ))
          }
          <div style={{float: 'right', marginTop: '20px'}}>
            <Button type='button' onClick={() => this.handleSubmit()} color='primary' variant='outlined'>Save</Button>
          </div>
          </>
          }
          </div>
      </SideDrawer>
    )
  }
}

export default InvoiceGrainLevies;

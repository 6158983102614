import React, { Component } from "react";
import { includes, flatten, values, map, get } from "lodash";
import { connect } from 'react-redux';

import APIService from '../../../services/APIService';
import Hits from "./Hits";
import Overlay from "./Overlay";
import SearchBar from "./SearchBar";
import SpotlightContext from "./SpotlightContext";
import './spotlight.scss';

const DEFAULT_STATE = {
  hits: {},
  flatHits: [],
  isOpen: false,
  selectedResultIndex: 0
};

class Spotlight extends Component {
  state = {
    ...DEFAULT_STATE,
    toggle: () => {
      this.setState({ isOpen: !this.state.isOpen }, () => {
        if(this.state.isOpen && localStorage.getItem('spotlightSearchTerm'))
          document.getElementById('searchTerm').value = localStorage.getItem('spotlightSearchTerm');
      });
    },
    clearSearch: (close = false) => {
      this.setState({ ...DEFAULT_STATE, isOpen: !close });
    },
    selectHit: selectedResultIndex => {
      if (selectedResultIndex === this.state.selectedResultIndex) {
        return;
      }

      this.setState({ selectedResultIndex });
    },
    selectUp: () => {
      const { flatHits, selectedResultIndex } = this.state;

      if (selectedResultIndex > 0) {
        this.setState({ selectedResultIndex: selectedResultIndex - 1 });
        return;
      }

      this.setState({ selectedResultIndex: flatHits.length - 1 });
    },
    selectDown: () => {
      const { flatHits, selectedResultIndex } = this.state;

      if (selectedResultIndex < flatHits.length - 1) {
        this.setState({ selectedResultIndex: selectedResultIndex + 1 });
        return;
      }

      this.setState({ selectedResultIndex: 0 });
    },
    handleKeyUp: input => {
      const { clearSearch, performSearch } = this.state;

      if (!input) {
        return;
      }

      if (!input) {
        clearSearch();
      } else {
        performSearch(input);
      }
    },
    handleKeyDown: event => {
      const { selectUp, selectDown, clearSearch, toggle } = this.state;

      // verificamos a tecla ↑ ↓ tab shift+tab ctrl+j ctrl+k
      switch (event.key) {
        case "ArrowUp":
          selectUp();
          event.preventDefault();
          break;
        case "ArrowDown":
          selectDown();
          event.preventDefault();
          break;
        case "Tab":
          if (event.shiftKey) {
            selectUp();
          } else {
            selectDown();
          }
          event.preventDefault();
          break;
        case "Escape":
          toggle();
          event.preventDefault();
          break;
      }
    },
    performSearch: async value => {
      if(!value || value.length < 2)
        return

      const searchStr = value.trim()
      const entity = document.getElementById('searchTerm').value;
      localStorage.setItem('spotlightSearchTerm', entity);

      if(searchStr && entity) {
        let json = []
        let APIServiceForEntity = this.getAPIService(entity, searchStr);
        if (APIServiceForEntity)
          json = await APIServiceForEntity.get(this.props.token);
        const flatHits = flatten(values(json));
        this.setState({
          flatHits: flatHits,
          hits: json,
          copyableData: map(flatHits, data => {return values(data).join(',')}).join('\n')
        });
      }

    }
  };

  getAPIService = (entity, searchStr) => {
    const __map = {
      'ngr': APIService.ngrs().appendToUrl(`global-search/${searchStr}/`),
      'company': APIService.companies().appendToUrl(`global-search/${searchStr}/`),
      'farm': APIService.farms().appendToUrl(`global-search/${searchStr}/?is_active=true`),
      'contract': APIService.contracts().appendToUrl(`global-search/${searchStr}/`),
      'order': APIService.freights().appendToUrl(`orders/global-search/${searchStr}/`),
      'movement': APIService.freights().appendToUrl(`contracts/global-search/${searchStr}/`),
      'invoice': APIService.invoices().appendToUrl(`global-search/${searchStr}/`),
      'employee': APIService.profiles().appendToUrl(`global-search/${searchStr}/`),
      'truck': APIService.trucks().appendToUrl(`global-search/${searchStr}/?is_active=true`),
      'vendor_dec': APIService.vendor_decs().appendToUrl(`global-search/${searchStr}/`),
    };

    return get(__map, entity)
  };

  _listenKey = event => {
    const isCtrlSpace = event.keyCode === 32 && event.ctrlKey;
    if (!isCtrlSpace) {
      return;
    }

    this.state.toggle();
  };

  componentWillUnmount() {
    document.body.removeEventListener("keydown", this._listenKey);
  }

  componentDidMount() {
    document.body.addEventListener("keydown", this._listenKey);
  }

  render() {
    if (!this.state.isOpen) {
      return null;
    }

    return (
      <SpotlightContext.Provider value={this.state}>
        <Overlay>
          <SearchBar />
          <Hits />
        </Overlay>
      </SpotlightContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  }
}

export default connect(mapStateToProps)(Spotlight);

import React from 'react';


import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class OrderConditionsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  render() {
    const { order } = this.props;
    const { expanded } = this.state;
    return (
      (order.generalConditions || order.specialConditions) &&
      <Paper className="order-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Conditions
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div>
            <h4 className="section-title">General Conditions</h4>
            <div className={"condition-section"}>{order.generalConditions}</div>
          </div>
        )}
        {expanded && (
          <div>
            <h4 className="section-title">Special Conditions</h4>
            <div className={"condition-section"}>{order.specialConditions}</div>
          </div>
        )}
      </Paper>
    );
  }
}

export default OrderConditionsSection;

import React from 'react';

import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import startsWith from 'lodash/startsWith';
import { get } from 'lodash'
import { MAX_DATE, getCountryFormats, toDateFormat } from '../../common/utils';
import { WARNING_ORANGE_CLASSES } from '../../common/constants';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDateTimePicker as DateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import dayjs from 'dayjs';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ...WARNING_ORANGE_CLASSES,
});

class CommonDatePicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate: props.minDate ? moment(props.minDate).format('YYYY-MM-DD') : null,
      maxDate: props.maxDate ? moment(props.maxDate).format('YYYY-MM-DD') : MAX_DATE,
      localValue: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentDidMount() {
    this.setState({localValue: this.state.value ? dayjs(this.state.value) : ''})
  }

  componentDidUpdate(prevProps) {
    const minDate = this.props.minDate ? moment(this.props.minDate).format('YYYY-MM-DD') : null;
    const maxDate = this.props.maxDate ? moment(this.props.maxDate).format('YYYY-MM-DD') : null;
    if (this.props.minDate !== prevProps.minDate && this.state.minDate !== minDate) {
      this.setState(state => ({
        ...state,
        minDate: this.props.minDate ? moment(this.props.minDate).format('YYYY-MM-DD') : null,
      }));
    }
    if (this.props.maxDate !== prevProps.maxDate && this.state.maxDate !== maxDate) {
      this.setState(state => ({
        ...state,
        maxDate: this.props.maxDate ? moment(this.props.maxDate).format('YYYY-MM-DD') : MAX_DATE,
      }));
    }
    if(this.props.value && (!this.state.localValue || (moment(this.state.localValue.toDate()).format('YYYY-MM-DD') !== moment(this.props.value).format('YYYY-MM-DD'))))
      this.setState({localValue: dayjs(this.props.value)})
    if(!this.props.value && this.state.localValue)
      this.setState({localValue: ''})
    this.resetDOM()
  }

  resetDOM = () => {
    if(this.props.id && !this.props.localValue && !this.props.value){
      document.getElementById(this.props.id).value = this.getFormat()
    }
  }

  handleChange(value) {
    if(!moment(get(value, '$d')).isValid())
      return
    this.setState({localValue: value}, () => {
      let _value = (this.state.localValue && moment(this.state.localValue.toDate()).isValid()) ? this.state.localValue.toDate() : this.state.localValue
      const newValue = _value ?
            (this.props.type == 'month' ? moment(_value).format('YYYY-MM') : moment(_value).format('YYYY-MM-DD')) :
            ''
      if(newValue !== this.props.value)
        this.props.onChange(newValue, this.props.id);
    })
  }

  handleOnBlur(event) {
    if(event.target.value){
      const selectedDate = this.props.type=='month' ? moment(event.target.value, 'YYYY-MM') : moment(event.target.value, getCountryFormats().date);
      if((this.state.minDate && selectedDate.isBefore(this.state.minDate)) || (this.state.maxDate && selectedDate.isAfter(this.state.maxDate))){

        this.setState({localValue: ''}, () => {
          this.props.onChange('', this.props.id);
        })
      }
    }
    if(this.props.onBlur){
      this.props.onBlur(event);
    }
  }


  getFormat = () => {
    const formats = getCountryFormats()
    let format = formats.date
    if(this.props.type === 'month')
      format = 'MM/YYYY'
    else if(this.props.type === 'datetime')
      format = formats.datetime
    return format
  }

  render() {
    const { classes } = this.props;
    let _props = {format: this.getFormat(), views: ['day']}
    if(this.props.type === 'month') {
      _props.views = ['month', 'year']
    }

    return (
      <div className="relative-pos text-black">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateTimePicker
            {..._props}
            clearable
            sx={{width: '100%'}}
            id={this.props.id}
            label={this.props.shouldRemoveLabel ? '' : this.props.floatingLabelText || 'Date'}
            value={this.state.localValue}
            disabled={this.props.disabled}
            onChange={this.handleChange}
            minDate={this.state.minDate ? dayjs(this.state.minDate) : undefined}
            maxDate={this.state.maxDate ? dayjs(this.state.maxDate) : undefined}
            slotProps={{
              textField: {
                id: this.props.id,
                error: Boolean(this.props.errorText),
                variant: this.props.variant || 'standard',
                helperText: this.props.errorText || '',
                inputProps: {
                  min: this.state.minDate ? toDateFormat(this.state.minDate) : undefined,
                  max: this.state.maxDate ? toDateFormat(this.state.maxDate) : undefined
                },
                InputProps: {
                  classes: {
                    underline: startsWith(this.props.errorText, 'Warning') ? classes.warningUnderline : null,
                  }
                },
                InputLabelProps: {
                  shrink: true,
                  className: startsWith(this.props.errorText, 'Warning') ? classes.warningColor : null,
                },
                FormHelperTextProps: {
                  classes: {
                    error: startsWith(this.props.errorText, 'Warning') ? classes.warningColor : null,
                  }
                },
                onBlur: this.handleOnBlur
              },
            }}
          />
        </LocalizationProvider>
      </div>
    );
  }
}

export default withStyles(styles)(CommonDatePicker);

/* eslint-disable */
import React, { Component } from 'react';
import './termsAndConditions.scss'
class MobileTermsAndConditions extends Component{

  render () {
    return <div className="full-screen-container term-and-conditions">
        <div className="content-wrapper">
            <div className="content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
            </div>
            <div className="content-body">
                <div id="page">
                    <p className="p0 ft0">AGRICHAIN MOBILE APP TERMS AND CONDITIONS OF USE</p>
                    <p className="p1 ft0"><span className="ft0">1.</span><span className="ft1">About the Application</span>
                    </p>
                    <p className="p2 ft5"><span className="ft2">1.1.</span><span className="ft3">Welcome to AgriChain (the
                        '</span><span className="ft4">Application</span>'). The
                        Application provides online contract management, stock management, and supply chain management
                        services (the '<span className="ft4">Services</span>’).</p>
                    <p className="p3 ft5"><span className="ft2">1.2.</span><span className="ft3">The Application is operated
                        by AgriChain PTY. LTD. (ABN 59 606 114 989). Access to and use of the Application, or any of its
                        associated Products or Services, is provided by AgriChain. Please read these terms and
                        conditions (the '</span><span className="ft6">Terms</span>') carefully. By using, browsing
                        and/or reading the Application, this
                        signifies that you have read, understood, and agree to be bound by the Terms. If you do not agree
                        with the Terms, you must cease usage of the Application, or any of its Services, immediately.</p>
                    <p className="p4 ft5"><span className="ft2">1.3.</span><span className="ft3">AgriChain reserves the
                        right to review and change any of the Terms by updating this page at its sole discretion. When
                        AgriChain updates the Terms, it will use reasonable endeavours to provide you with notice of
                        updates to the Terms. Any changes to the Terms take immediate effect from the date of their
                        publication. Before you continue, we recommend you keep a copy of the Terms for your
                        records.</span>
                    </p>
                    <p className="p5 ft0"><span className="ft0">2.</span><span className="ft1">Acceptance of the
                        Terms</span></p>
                    <p className="p6 ft5">You accept the Terms by using or browsing the Application. You may also accept the
                        Terms by clicking to accept or agree to the Terms where this option is made available to you by
                        AgriChain in the user interface.</p>
                    <p className="p1 ft0"><span className="ft0">3.</span><span className="ft1">Subscription to use the
                        Services</span>
                    </p>
                    <p className="p7 ft5"><span className="ft2">3.1.</span><span className="ft3">In order to access the
                        Services, you must first purchase a subscription through the Application (the '</span><span
                        className="ft6">Subscription</span>') and pay the applicable fee for the selected Subscription
                        (the
                        '<span className="ft6">Subscription Fee</span>').</p>
                    <p className="p8 ft5"><span className="ft2">3.2.</span><span className="ft3">In purchasing the
                        Subscription, you acknowledge and agree that it is your responsibility to ensure that the
                        Subscription you elect to purchase is suitable for your use.</span>
                    </p>
                    <p className="p9 ft2"><span className="ft2">3.3.</span><span className="ft7">Once you have purchased the
                        Subscription, you will then be required to register </span>for an account through the
                        Application before you can access the Services (the
                        '<span className="ft6">Account</span>').
                    </p>
                    <p className="p12 ft5"><span className="ft2">3.4.</span><span className="ft3">As part of the
                        registration process, or as part of your continued use of the Services, you may be required to
                        provide personal information about yourself (such as identification or contact details),
                        including:</span>
                    </p>
                    <p className="p13 ft2"><span className="ft2">a.</span><span className="ft10">Email address</span></p>
                    <p className="p14 ft2"><span className="ft2">b.</span><span className="ft10">Preferred username</span>
                    </p>
                    <p className="p14 ft2"><span className="ft11">c.</span><span className="ft12">Mailing address</span></p>
                    <p className="p14 ft2"><span className="ft2">d.</span><span className="ft10">Telephone number</span></p>
                    <p className="p14 ft2"><span className="ft2">e.</span><span className="ft10">Password</span></p>
                    <p className="p14 ft2"><span className="ft2">f.</span><span className="ft13">Business Name</span></p>
                    <p className="p14 ft2"><span className="ft2">g.</span><span className="ft10">ABN</span></p>
                    <p className="p15 ft5"><span className="ft2">3.5.</span><span className="ft3">You warrant that any
                        information you give to AgriChain in the course of completing the registration process will
                        always be accurate, correct and up to date.</span>
                    </p>
                    <p className="p16 ft5"><span className="ft2">3.6.</span><span className="ft3">Once you have completed
                        the registration process, you will be a registered member of the Application (</span><span
                        className="ft6">'Member’</span>) and agree to be bound by the Terms. As a Member you will be
                        granted
                        immediate access to the Services from the time you have completed the registration process until the
                        subscription period expires (the 'Subscription Period Subscription Period').</p>
                    <p className="p17 ft2"><span className="ft2">3.7.</span><span className="ft7">You may not use the
                        Services and may not accept the Terms if:</span>
                    </p>
                    <p className="p14 ft2"><span className="ft2">a.</span><span className="ft10">you are not of legal age to
                        form a binding contract with AgriChain; or</span>
                    </p>
                    <p className="p18 ft5"><span className="ft2">b.</span><span className="ft14">you are a person barred
                        from receiving the Services under the laws of Australia or other countries including the country
                        in which you are resident or from which you use the Services.</span>
                    </p>
                    <p className="p1 ft0"><span className="ft0">4.</span><span className="ft1">Your obligations as a
                        Member</span></p>
                    <p className="p19 ft2"><span className="ft2">4.1.</span><span className="ft7">As a Member, you agree to
                        comply with the following:</span>
                    </p>
                    <p className="p14 ft2"><span className="ft2">a.</span><span className="ft10">you will use the Services
                        only for purposes that are permitted by:</span>
                    </p>
                    <p className="p20 ft2"><span className="ft2">(i)</span><span className="ft15">the Terms; and</span></p>
                    <p className="p20 ft2"><span className="ft2">(ii)</span><span className="ft15">any applicable law,
                        regulation or generally accepted practices</span>or guidelines in the relevant jurisdictions;
                    </p>
                    <p className="p23 ft5"><span className="ft2">b.</span><span className="ft14">you have the sole
                        responsibility for protecting the confidentiality of your password and/or email address. Use of
                        your password by any other person may result in the immediate cancellation of the
                        Services;</span>
                    </p>
                    <p className="p24 ft5"><span className="ft11">c.</span><span className="ft16">any use of your
                        registration information by any other person, or third parties, is strictly prohibited. You
                        agree to immediately notify AgriChain of any unauthorised use of your password or email address
                        or any breach of security of which you have become aware;</span>
                    </p>
                    <p className="p25 ft5"><span className="ft2">d.</span><span className="ft14">access and use of the
                        Application is limited, </span>
                        <nobr>non-transferable,</nobr>
                        and allows for the sole use of the Application by you for the purposes of AgriChain providing the
                        Services;
                    </p>
                    <p className="p26 ft5"><span className="ft2">e.</span><span className="ft14">you will not use the
                        Services or the Application in connection with any commercial endeavours except those that are
                        specifically endorsed or approved by the management of AgriChain;</span>
                    </p>
                    <p className="p27 ft5"><span className="ft2">f.</span><span className="ft17">you will not use the
                        Services or Application for any illegal and/or unauthorised use which includes collecting email
                        addresses of Members by electronic or other means for the purpose of sending unsolicited email
                        or unauthorised framing of or linking to the Application;</span>
                    </p>
                    <p className="p28 ft5"><span className="ft2">g.</span><span className="ft14">you agree that commercial
                        advertisements, affiliate links, and other forms of solicitation may be removed from the
                        Application without notice and may result in termination of the Services. Appropriate legal
                        action will be taken by AgriChain for any illegal or unauthorised use of the Application;
                        and</span>
                    </p>
                    <p className="p29 ft9"><span className="ft2">h.</span><span className="ft18">you acknowledge and agree
                        that any automated use of the Application or its Services is prohibited.</span>
                    </p>
                    <p className="p30 ft0"><span className="ft0">5.</span><span className="ft1">Payment</span></p>
                    <p className="p31 ft9"><span className="ft2">5.1.</span><span className="ft19">Where the option is given
                        to you, you may make payment of the Subscription Fee by way of:</span>
                    </p>
                    <p className="p13 ft2"><span className="ft2">a.</span><span className="ft10">Electronic funds transfer
                        ('</span><span className="ft0">EFT</span>’) into our nominated bank account</p>
                    <p className="p32 ft2"><span className="ft2">b.</span><span className="ft10">Credit Card Payment
                        ('</span><span className="ft0">Credit Card</span>')</p>
                    <p className="p32 ft2"><span className="ft11">c.</span><span className="ft12">Cryptocurrency
                        ('</span><span className="ft0">Cryptocurrency</span>')</p>
                    <p className="p34 ft5"><span className="ft2">5.2.</span><span className="ft3">All payments made in the
                        course of your use of the Services are made using online payment services. In using the
                        Application, the Services or when making any payment in relation to your use of the Services,
                        you warrant that you have read, understood, and agree to be bound by the online payment services
                        terms and conditions which are available on their Application.</span>
                    </p>
                    <p className="p35 ft5"><span className="ft2">5.3.</span><span className="ft3">You acknowledge and agree
                        that where a request for the payment of the Subscription Fee is returned or denied, for whatever
                        reason, by your financial institution or is unpaid by you for any other reason, then you are
                        liable for any costs, including banking fees and charges, associated with the Subscription Fee
                        .</span>
                    </p>
                    <p className="p36 ft5"><span className="ft2">5.4.</span><span className="ft3">You agree and acknowledge
                        that AgriChain can vary the Subscription Fee at any time and that the varied Subscription Fee
                        will come into effect following the conclusion of the existing Subscription Period.</span>
                    </p>
                    <p className="p1 ft0"><span className="ft0">6.</span><span className="ft1">Refund Policy</span></p>
                    <p className="p37 ft5">AgriChain will only provide you with a refund of the Subscription Fee in the
                        event they are unable to continue to provide the Services or if the manager of AgriChain makes a
                        decision, at its absolute discretion, that it is reasonable to do so under the circumstances. Where
                        this occurs, the refund will be in the proportional amount of the Subscription Fee that remains
                        unused by the Member (the '<span className="ft6">Refund</span>').</p>
                    <p className="p38 ft0"><span className="ft0">7.</span><span className="ft1">Copyright and Intellectual
                        Property Copyright and Intellectual Property</span>
                    </p>
                    <p className="p39 ft5"><span className="ft2">7.1.</span><span className="ft3">The Application, the
                        Services, and all of the related products of AgriChain are subject to copyright. The material on
                        the Application is protected by copyright under the laws of Australia and through international
                        treaties. Unless otherwise indicated, all rights (including copyright) in the Services and
                        compilation of the Application (including but not limited to text, graphics, logos, button
                        icons, video images, audio clips, Application, code, scripts, design elements, and interactive
                        features) or the Services are owned or controlled for these purposes, and are reserved by
                        AgriChain or its contributors.</span>
                    </p>
                    <p className="p40 ft9"><span className="ft2">7.2.</span><span className="ft19">All trademarks, service
                        marks, and trade names are owned, registered and/or licensed by AgriChain, who grants to you a
                        worldwide, </span>
                        <nobr>non-exclusive,</nobr>
                        royalty-free, revocable license whilst you are a Member to:
                    </p>

                    <p className="p14 ft2"><span className="ft2">a.</span><span className="ft10">use the Application
                        pursuant to the Terms;</span>
                    </p>
                    <p className="p43 ft9"><span className="ft2">b.</span><span className="ft18">copy and store the
                        Application and the material contained in the Application in your device's cache memory;
                        and</span>
                    </p>
                    <p className="p44 ft9"><span className="ft11">c.</span><span className="ft20">print pages from the
                        Application for your own personal and </span>
                        <nobr>non-commercial</nobr>
                        use.
                    </p>
                    <p className="p45 ft9">AgriChain does not grant you any other rights whatsoever in relation to the
                        Application or the Services. All other rights are expressly reserved by AgriChain.</p>
                    <p className="p46 ft5"><span className="ft2">7.3.</span><span className="ft3">AgriChain retains all
                        rights, title, and interest in and to the Application and all related Services. Nothing you do
                        on or in relation to the Application will transfer any:</span>
                    </p>
                    <p className="p47 ft9"><span className="ft2">a.</span><span className="ft18">business name, trading
                        name, domain name, trade mark, industrial design, patent, registered design or copyright,
                        or</span>
                    </p>
                    <p className="p48 ft9"><span className="ft2">b.</span><span className="ft18">a right to use or exploit a
                        business name, trading name, domain name, trade mark or industrial design, or</span>
                    </p>
                    <p className="p49 ft5"><span className="ft11">c.</span><span className="ft16">a thing, system or process
                        that is the subject of a patent, registered design or copyright (or an adaptation or
                        modification of such a thing, system or process), to you.</span>
                    </p>
                    <p className="p50 ft5"><span className="ft2">7.4.</span><span className="ft3">You may not, without the
                        prior written permission of AgriChain and the permission of any other relevant rights owners:
                        broadcast, republish, </span>
                        <nobr>up-load</nobr>
                        to a third party, transmit, post, distribute, show or play in public, adapt or change in any way the
                        Services or third party Services for any purpose, unless otherwise provided by these Terms. This
                        prohibition does not extend to materials on the Application, which are freely available
                        for <nobr>re-use</nobr> or are in the public domain.
                    </p>
                    <p className="p51 ft0"><span className="ft0">8.</span><span className="ft1">Privacy</span></p>
                    <p className="p52 ft5">AgriChain takes your privacy seriously and any information provided through your
                        use of the Application and/or Services are subject to AgriChain's Privacy Policy, which is available
                        at <nobr>www.iubenda.com/privacy-policy/13161846.</nobr>
                    </p>
                    <p className="p54 ft0"><span className="ft0">9.</span><span className="ft1">General Disclaimer</span>
                    </p>
                    <p className="p55 ft5"><span className="ft2">9.1.</span><span className="ft3">Nothing in the Terms
                        limits or excludes any guarantees, warranties, representations or conditions implied or imposed
                        by law, including the Australian Consumer Law (or any liability under them) which by law may not
                        be limited or excluded.</span>
                    </p>
                    <p className="p56 ft2"><span className="ft2">9.2.</span><span className="ft7">Subject to this clause,
                        and to the extent permitted by law:</span>
                    </p>
                    <p className="p57 ft9"><span className="ft2">a.</span><span className="ft18">all terms, guarantees,
                        warranties, representations or conditions which are not expressly stated in the Terms are
                        excluded; and</span>
                    </p>
                    <p className="p24 ft5"><span className="ft2">b.</span><span className="ft14">AgriChain will not be
                        liable for any special, indirect or consequential loss or damage (unless such loss or damage is
                        reasonably foreseeable resulting from our failure to meet an applicable Consumer Guarantee),
                        loss of profit or opportunity, or damage to goodwill arising out of or in connection with the
                        Services or these Terms (including as a result of not being able to use the Services or the late
                        supply of the Services), whether at common law, under contract, tort (including negligence), in
                        equity, pursuant to statute or otherwise.</span>
                    </p>
                    <p className="p35 ft5"><span className="ft2">9.3.</span><span className="ft3">Use of the Application and
                        the Services is at your own risk. Everything on the Application and the Services is provided to
                        you "as is" and "as available" without warranty or condition of any kind. None of the
                        affiliates, directors, officers, employees, agents, contributors, and licensors of AgriChain
                        make any express or implied representation or warranty about any Products or Services (including
                        the products or Services of AgriChain) referred to on the Application. This includes (but is not
                        restricted to) loss or damage you might suffer as a result of any of the following:</span>
                    </p>
                    <p className="p58 ft5"><span className="ft2">a.</span><span className="ft14">failure of performance,
                        error, omission, interruption, deletion, defect, failure to correct defects, delay in operation
                        or transmission, computer virus or other harmful component, loss of data, communication line
                        failure, unlawful third party conduct, or theft, destruction, alteration or unauthorised access
                        to records;</span>
                    </p>
                    <p className="p59 ft9"><span className="ft2">b.</span><span className="ft18">the accuracy, suitability
                        or currency of any information on the Application, the Services, or any of its Services related
                        products (including third party</span>materials and advertisements on the Application);
                    </p>
                    <p className="p63 ft9"><span className="ft11">c.</span><span className="ft20">costs incurred as a result
                        of you using the Application, the Services or any of the products of AgriChain; and</span>
                    </p>
                    <p className="p64 ft9"><span className="ft2">d.</span><span className="ft18">the Services or operation
                        in respect to links which are provided for your convenience.</span>
                    </p>
                    <p className="p30 ft0"><span className="ft0">10.</span><span className="ft1">Competitors</span></p>
                    <p className="p65 ft5">If you are in the business of providing similar Services for the purpose of
                        providing them to users for a commercial gain, whether business users or domestic users, then you
                        are a competitor of AgriChain. Competitors are not permitted to use or access any information or
                        content on our Application. If you breach this provision, AgriChain will hold you fully responsible
                        for any loss that we may sustain and hold you accountable for all profits that you might make from
                        such a breach.</p>
                    <p className="p5 ft0"><span className="ft0">11.</span><span className="ft1">Limitation of
                        liability</span></p>
                    <p className="p66 ft5"><span className="ft2">11.1.</span><span className="ft3">AgriChain's total
                        liability arising out of or in connection with the Services or these Terms, however arising,
                        including under contract, tort (including negligence), in equity, under statute or otherwise,
                        will not exceed the resupply of the Services to you.</span>
                    </p>
                    <p className="p67 ft5"><span className="ft2">11.2.</span><span className="ft3">You expressly understand
                        and agree that AgriChain, its affiliates, employees, agents, contributors, and licensors shall
                        not be liable to you for any direct, indirect, incidental, special consequential or exemplary
                        damages which may be incurred by you, however caused, and under any theory of liability. This
                        shall include, but is not limited to, any loss of profit (whether incurred directly or
                        indirectly), any loss of goodwill or business reputation, and any other intangible loss.</span>
                    </p>
                    <p className="p68 ft0"><span className="ft0">12.</span><span className="ft1">Termination of
                        Contract</span></p>
                    <p className="p69 ft9"><span className="ft2">12.1.</span><span className="ft19">The Terms will continue
                        to apply until terminated by either you or by AgriChain as set out below.</span>
                    </p>
                    <p className="p70 ft2"><span className="ft2">12.2.</span><span className="ft7">If you want to terminate
                        the Terms, you may do so by:</span>
                    </p>
                    <p className="p72 ft2"><span className="ft2">a.</span><span className="ft10">not renewing the
                        Subscription prior to the end of the Subscription Period;</span>
                    </p>
                    <p className="p73 ft9"><span className="ft2">b.</span><span className="ft18">providing AgriChain with 30
                        days' notice of your intention to terminate; and</span>
                    </p>
                    <p className="p74 ft9"><span className="ft11">c.</span><span className="ft20">closing your accounts for
                        all of the services which you use, where AgriChain has made this option available to you.</span>
                    </p>
                    <p className="p75 ft9">Your notice should be sent, in writing, to AgriChain via the 'Contact Us' link on
                        our homepage.</p>
                    <p className="p76 ft2"><span className="ft2">12.3.</span><span className="ft7">AgriChain may at any
                        time, terminate the Terms with you if:</span>
                    </p>
                    <p className="p77 ft2"><span className="ft2">a.</span><span className="ft10">you do not renew the
                        Subscription at the end of the Subscription Period;</span>
                    </p>
                    <p className="p78 ft9"><span className="ft2">b.</span><span className="ft18">you have breached any
                        provision of the Terms or intend to breach any provision;</span>
                    </p>
                    <p className="p79 ft2"><span className="ft11">c.</span><span className="ft12">AgriChain is required to
                        do so by law;</span>
                    </p>
                    <p className="p80 ft9"><span className="ft2">d.</span><span className="ft18">the provision of the
                        Services to you by AgriChain is, in the opinion of AgriChain, no longer commercially
                        viable.</span>
                    </p>
                    <p className="p81 ft5"><span className="ft2">12.4.</span><span className="ft3">Subject to local
                        applicable laws, AgriChain reserves the right to discontinue or cancel your membership at any
                        time and may suspend or deny, in its sole discretion, your access to all or any portion of the
                        Application or the Services without notice if you breach any provision of the Terms or any
                        applicable law or if your conduct impacts AgriChain's name or reputation or violates the rights
                        of those of another party.</span>
                    </p>
                    <p className="p38 ft0"><span className="ft0">13.</span><span className="ft1">Indemnity</span></p>
                    <p className="p82 ft9"><span className="ft2">13.1.</span><span className="ft19">You agree to indemnify
                        AgriChain, its affiliates, employees, agents, contributors, third party content providers, and
                        licensors from and against:</span>
                    </p>
                    <p className="p83 ft5"><span className="ft2">a.</span><span className="ft14">all actions, suits, claims,
                        demands, liabilities, costs, expenses, loss, and damage (including legal fees on a full
                        indemnity basis) incurred, suffered or arising out of or in connection with Your Content;</span>
                    </p>
                    <p className="p84 ft9"><span className="ft2">b.</span><span className="ft18">any direct or indirect
                        consequences of you accessing, using or transacting on the Application or attempts to do so;
                        and/or</span>any breach of the Terms.
                    </p>
                    <p className="p1 ft0"><span className="ft0">14.</span><span className="ft1">Dispute Resolution</span>
                    </p>
                    <p className="p86 ft2"><span className="ft2">14.1.</span><span className="ft7">Compulsory:</span></p>
                    <p className="p87 ft5">If a dispute arises out of or relates to the Terms, either party may not commence
                        any Tribunal or Court proceedings in relation to the dispute, unless the following clauses have been
                        complied with (except where urgent interlocutory relief is sought).</p>
                    <p className="p88 ft2"><span className="ft2">14.2.</span><span className="ft7">Notice:</span></p>
                    <p className="p89 ft5">A party to the Terms claiming a dispute ('<span className="ft4">Dispute</span>')
                        has arisen under the Terms, must give written notice to the other party detailing the nature of the
                        dispute, the desired outcome, and the action required to settle the Dispute.</p>
                    <p className="p90 ft2"><span className="ft2">14.3.</span><span className="ft7">Resolution:</span></p>
                    <p className="p91 ft9">On receipt of that notice ('<span className="ft6">Notice</span>’) by that other
                        party, the parties to the Terms ('<span className="ft6">Parties</span>’) must:</p>
                    <p className="p92 ft5"><span className="ft2">a.</span><span className="ft14">Within 90 days of the
                        Notice endeavour in good faith to resolve the Dispute expeditiously by negotiation or such other
                        means upon which they may mutually agree;</span>
                    </p>
                    <p className="p93 ft5"><span className="ft2">b.</span><span className="ft14">If for any reason
                        whatsoever, 30 days days after the date of the Notice, the Dispute has not been resolved, the
                        Parties must either agree upon selection of a mediator or request that an appropriate mediator
                        be appointed by the President of the Australian Mediation Association or his or her
                        nominee;</span>
                    </p>
                    <p className="p94 ft5"><span className="ft11">c.</span><span className="ft16">The Parties are equally
                        liable for the fees and reasonable expenses of a mediator and the cost of the venue of the
                        mediation and without limiting the foregoing undertake to pay any amounts requested by the
                        mediator as a </span>
                        <nobr>pre-condition</nobr>
                        to the mediation commencing. The Parties must each pay their own costs associated with the
                        mediation;
                    </p>
                    <p className="p95 ft2"><span className="ft2">d.</span><span className="ft10">The mediation will be held
                        in Brisbane, Queensland, Australia.</span>
                    </p>
                    <p className="p86 ft2"><span className="ft2">14.4.</span><span className="ft7">Confidential</span></p>
                    <p className="p96 ft5">All communications concerning negotiations made by the Parties arising out of and
                        in connection with this dispute resolution clause are confidential and to the extent possible, must
                        be treated as "without prejudice" negotiations for the purpose of applicable laws of evidence.</p>

                    <p className="p99 ft2"><span className="ft2">14.5.</span><span className="ft7">Termination of
                        Mediation:</span></p>
                    <p className="p100 ft5">If 60 days have elapsed after the start of a mediation of the Dispute and the
                        Dispute has not been resolved, either Party may ask the mediator to terminate the mediation and the
                        mediator must do so.</p>
                    <p className="p1 ft0"><span className="ft0">15.</span><span className="ft1">Venue and
                        Jurisdiction</span></p>
                    <p className="p101 ft5">The Services offered by AgriChain is intended to be viewed by residents of
                        Australia. In the event of any dispute arising out of or in relation to the Application, you agree
                        that the exclusive venue for resolving any dispute shall be in the courts of Queensland,
                        Australia.</p>
                    <p className="p1 ft0"><span className="ft0">16.</span><span className="ft1">Governing Law</span></p>
                    <p className="p102 ft5">The Terms are governed by the laws of Queensland, Australia. Any dispute,
                        controversy, proceeding or claim of whatever nature arising out of or in any way relating to the
                        Terms and the rights created hereby shall be governed, interpreted, and construed by, under and
                        pursuant to the laws of Queensland, Australia, without reference to conflict of law principles,
                        notwithstanding mandatory rules. The validity of this governing law clause is not contested. The
                        Terms shall be binding to the benefit of the parties hereto and their successors and assigns.</p>
                    <p className="p68 ft0"><span className="ft0">17.</span><span className="ft1">Independent Legal
                        Advice</span></p>
                    <p className="p103 ft5">Both parties confirm and declare that the provisions of the Terms are fair and
                        reasonable and both parties have taken the opportunity to obtain independent legal advice and
                        declare the Terms are not against public policy on the grounds of inequality or bargaining power or
                        general grounds of restraint of trade.</p>
                    <p className="p104 ft0"><span className="ft0">18.</span><span className="ft1">Severance</span></p>
                    <p className="p105 ft9">If any part of these Terms is found to be void or unenforceable by a Court of
                        competent jurisdiction, that part shall be severed and the rest of the Terms shall remain in
                        force.</p>
                </div>
            </div>
        </div>
    </div>
  }
}

export default MobileTermsAndConditions;

import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import alertifyjs from 'alertifyjs';
import { getSelectedOrderForMenu, receiveAllocation, shouldFetchFreightOrder, closeOutFreightOrder } from '../../actions/companies/orders';
import { setClickedOption } from '../../actions/companies/orders';
import { RejectionReasonDialog } from '../rejections/RejectionReasonDialog';
import SideDrawer from '../common/SideDrawer';
import EditOrderReview from './order-details/EditOrderReview';
import { raiseVoidOrderRequest, raiseVendorDecRequest } from '../../actions/companies/freights';
import { isLoading } from '../../actions/main';
import FreightCustomEmail from '../common/FreightCustomEmail';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import { vendorDecBlockPermissionPopup, requestVendorDecBlockPermissionPopup } from '../../common/utils';
import { CALL_ON_GRAIN_TYPE_ID, ORDER_TYPE_NAMES } from '../../common/constants';

let FreightAllocationActions = props =>{
    const [options, setOptions] = useState({
        isVoidDialogOpen: false,
        isCloseOutDialogOpen: false,
        amendDialogOpen: false,
        showCustomEmailDialog: false,
        showVendorDecPopUp: false,
        disableOption:false
      });

    const [requestReason, setRequestReason] = useState("");
    const [closeOutReason, setCloseOutReason] = useState("");
    const [subject, setSubject] = useState("");
    const [vendorDecDetails, setVendorDecDetails] = useState(false);
    const [orderTypeName, setOrderTypeName] = useState("");

    let dispatch = useDispatch();

    let setOptionKey = (key, value) =>{
        setOptions({
            ...options,
            [key]: value
        });
        dispatch(setClickedOption(null));
    };

  let closeEmailDialog = (communicationData, justClose) => {
    setSubject("");
    setRequestReason(false);
    setCloseOutReason(false);
    setOptions({
      ...options,
      disableOption: false,
      showCustomEmailDialog: false,
      isVoidDialogOpen: false,
      isCloseOutDialogOpen: false
    });
    setVendorDecDetails(false);
    if (!justClose) {
      let data = {};
      if (communicationData) {
        data['communication'] = communicationData;
      }
      dispatch(isLoading('genericTableWithData'));
      if (vendorDecDetails) {
        const requestData = { entity: 'freightorder', ...data };
        dispatch(raiseVendorDecRequest(props.selectedAllocationId, requestData));
      }
      else {
        const voidData = { requestReason: requestReason, ...data };
        dispatch(raiseVoidOrderRequest(props.selectedAllocationId, voidData, true));
      }
    }
  };

    let checkForOptions = async () => {
        const { selectedAllocationId, clickedOption } = props;

        if (get(props, 'canRaiseVoidRequestForOrder') && get(clickedOption, 'key') === 'void' && selectedAllocationId ) {
            setOptionKey("isVoidDialogOpen", true);
        }
        else if (get(clickedOption, 'key') === 'review_amendment'){
            setOptionKey("amendDialogOpen", true);
            setOrderTypeName(find(ORDER_TYPE_NAMES, {id: get(props, 'selectedAllocation.typeId')}).name);
        }
        else if (get(clickedOption, 'key') === 'vendor_dec_add_fo' &&
              selectedAllocationId && props.selectedAllocation &&
              get(props.selectedAllocation, 'typeId') != CALL_ON_GRAIN_TYPE_ID //this needs to be fixed better by separating out reducers for FO/GO
          ){
          dispatch(setClickedOption(null));
          if (isEmpty(props.selectedAllocation.cannotCreateVendorDecReasons)) {
            document.location=`#/vendor-decs/grain/new?entity=freightorder&entityId=${selectedAllocationId}`;
          }
          else{
            vendorDecBlockPermissionPopup(props.selectedAllocation.cannotCreateVendorDecReasons, 'Order');
          }
        }
        else if (get(clickedOption, 'key') === 'vendor_dec_add_cog' && selectedAllocationId && props.selectedAllocation){
          dispatch(setClickedOption(null));
          if (isEmpty(props.selectedAllocation.cannotCreateVendorDecReasons)) {
            document.location=`#/vendor-decs/grain/new?entity=freightorder&entityId=${selectedAllocationId}`;
          }
          else{
            vendorDecBlockPermissionPopup(props.selectedAllocation.cannotCreateVendorDecReasons, 'Order');
          }
        }
        else if (get(clickedOption, 'key') === 'vendor_dec_request_fo' &&
          selectedAllocationId && props.selectedAllocation &&
          get(props.selectedAllocation, 'typeId') != CALL_ON_GRAIN_TYPE_ID //this needs to be fixed better by separating out reducers for FO/GO
        ){
          dispatch(setClickedOption(null));
          if (isEmpty(props.selectedAllocation.cannotRequestVendorDecReasons)) {
            const identifier = get(props.selectedAllocation, 'identifier', '').toUpperCase();
            const entity = get(props.selectedAllocation, 'typeId') === CALL_ON_GRAIN_TYPE_ID ? "Grain" : "Freight";
            setVendorDecDetails(true);
            setSubject(`[Request] Vendor Declaration for ${entity} Order #${identifier}`);
            setOptions({...options, disableOption: true, showCustomEmailDialog:true});
          }
          else{
            requestVendorDecBlockPermissionPopup(props.selectedAllocation.cannotRequestVendorDecReasons, 'Order');
          }
        }
        else if (get(clickedOption, 'key') === 'vendor_dec_request_cog' && selectedAllocationId && props.selectedAllocation){
          dispatch(setClickedOption(null));
          if (isEmpty(props.selectedAllocation.cannotRequestVendorDecReasons)) {
            const identifier = get(props.selectedAllocation, 'identifier', '').toUpperCase();
            const entity = get(props.selectedAllocation, 'typeId') === CALL_ON_GRAIN_TYPE_ID ? "Grain" : "Freight";
            setVendorDecDetails(true);
            setSubject(`[Request] Vendor Declaration for ${entity} Order #${identifier}`);
            setOptions({...options, disableOption: true, showCustomEmailDialog:true});
          }
          else{
            requestVendorDecBlockPermissionPopup(props.selectedAllocation.cannotRequestVendorDecReasons, 'Order');
          }
        }
        else if (get(clickedOption, 'key') === 'vendor_dec_view_fo'
            && selectedAllocationId
            && get(props.selectedAllocation, 'typeId') != CALL_ON_GRAIN_TYPE_ID // this needs to be fixed better by separating out reducers for FO/GO
          ){
          dispatch(setClickedOption(null));
          document.location=`#/freights/orders/${selectedAllocationId}/vendor-decs`;
        }
        else if (get(clickedOption, 'key') === 'vendor_dec_view_cog' && selectedAllocationId){
          dispatch(setClickedOption(null));
          document.location=`#/freights/orders/${selectedAllocationId}/vendor-decs`;
        }
        else if (get(clickedOption, 'key') === 'close_out' && selectedAllocationId && get(props, 'canCloseOutForOrder')){
          alertifyjs.confirm(
            'Warning',
            'To Close Out the Order, system would reduce the order tonnage to the tonnage that has been delivered. Do you wish to proceed?',
            () => {
              setOptionKey("isCloseOutDialogOpen", true);
            },
            () => { },);
        }
    };

    let closeVoidDialog = () =>{
        setOptionKey("isVoidDialogOpen", false);
        setRequestReason("");
    };

    let closeCloseOutDialog = () =>{
      setOptionKey("isCloseOutDialogOpen", false);
      setCloseOutReason("");
    };

    let getIdentifier = () =>{
      return get(props.selectedAllocation, 'identifier') || get(find(props.items, {id: props.selectedAllocationId}), "identifier");
    };

    useEffect(()=> {
        if(props.clickedOption){
            checkForOptions();
        }
        if (props.shouldFetchOrder && props.selectedAllocationId && props.selectedAllocationId !== get(props, 'selectedAllocation.id')) {
          dispatch(isLoading('orderDetail'));
          dispatch(
            getSelectedOrderForMenu(props.selectedAllocationId, receiveAllocation, false, false, false, false, true)
          );
          dispatch(shouldFetchFreightOrder(false));
        }
    }, [props.shouldFetchOrder, props.selectedAllocationId, props.canCloseOutForOrder,
       props.canRaiseVoidRequestForOrder, props.clickedOption, props.selectedAllocation]);

    return (<span>

        <FreightCustomEmail order={props.selectedAllocation}
          showCustomEmailDialog={options.showCustomEmailDialog}
          closeCustomEmailDialog={closeEmailDialog} disable={options.disableOption}
          subject={subject} vendorDecDetails={vendorDecDetails}/>

        {
          options.isVoidDialogOpen && getIdentifier() &&
            <RejectionReasonDialog
                open={options.isVoidDialogOpen}
                onClose={closeVoidDialog}
                title={`Void Order: ${getIdentifier()}`}
                value={requestReason}
                onChange={(event) => setRequestReason(event.target.value)}
                onCancel={closeVoidDialog}
                onReject={() => {
                  setVendorDecDetails(false);
                  setOptionKey("showCustomEmailDialog", true);
                  dispatch(shouldFetchFreightOrder(true));
                }}
                placeholder="Enter you reason for void request"
                submitText="Submit"
                required
            />
        }
        {
          options.isCloseOutDialogOpen && getIdentifier() &&
            <RejectionReasonDialog
                open={options.isCloseOutDialogOpen}
                onClose={closeCloseOutDialog}
                title={`Close Out Order: ${getIdentifier()}`}
                value={closeOutReason}
                onChange={(event) => setCloseOutReason(event.target.value)}
                onCancel={closeCloseOutDialog}
                onReject={() => {
                  const data = { closeOutReason: closeOutReason }
                  dispatch(closeOutFreightOrder(props.selectedOrderId, data));
                  dispatch(setClickedOption(null));
                }}
                placeholder="Enter your reason for close out"
                submitText="Submit"
                required = {false}
            />
        }

        {
          props.selectedAllocation && options.amendDialogOpen &&
           <SideDrawer
            isOpen={options.amendDialogOpen}
            onClose={() => setOptionKey("amendDialogOpen", false)}
            title={`${orderTypeName} Order Amend Request (${get(props.selectedAllocation, 'identifier')})`}
            classes={{'paper' : 'left-text-align'}}
            size="big"
          >
            <EditOrderReview order={props.selectedAllocation} closeSidebar={() => setOptionKey("amendDialogOpen", false)}/>
          </SideDrawer>
        }

    </span>);
};

export default FreightAllocationActions;

import React from 'react';
import {Table, TableBody, TableHead, TableRow, TableCell} from '@mui/material/';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { map } from 'lodash';
import { TOMATO_RED } from '../../../common/constants';
import './chemicalTable.scss';

const COLUMNS = ['Chemical Applied', 'Rate of Application', 'Application Date', 'WHP/ ESI/ EAFI'];
const EMPTY_VALUE = '-';

class ChemicalTable extends React.Component {

  getValue(value) {
    return value || EMPTY_VALUE;
  }

  getDateValue(value) {
    if(!value)
      return EMPTY_VALUE;

    return value.split('-').reverse().join('/');
  }

  render() {
    const { chemicals } = this.props;
    return (
      <Table className='chemicals-table'>
        <TableHead>
          <TableRow>
            {
              map(COLUMNS, column => (<TableCell key={column}>{column}</TableCell>))
            }
            <TableCell align='center'>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            map(chemicals, chemical => (
              <TableRow
                key={chemical.id}
                onClick={() => this.props.onRowClick(chemical)}
                style={{cursor: 'pointer'}}>
                <TableCell>{this.getValue(chemical.name)}</TableCell>
                <TableCell>{this.getValue(chemical.rate)} {this.getValue(chemical.measurement)}</TableCell>
                <TableCell>{this.getDateValue(chemical.date)}</TableCell>
                <TableCell>{this.getValue(chemical.periodType)}</TableCell>
                <TableCell align='center'>
                  <DeleteIcon
                    onClick={(event) => this.props.onRowDelete(event, chemical)}
                    style={{color: TOMATO_RED}}
                  />
                </TableCell>
              </TableRow>
            ))
          }
        </TableBody>
      </Table>
    );
  }
}

export default ChemicalTable;

import React from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import merge from 'lodash/merge';
import { canCreateOrUpdate } from '../../common/utils';
import { AppContext } from '../main/LayoutContext';

class AddButton extends React.Component {
  static contextType = AppContext

  render() {
    const {
      app, user, id, variant, onClick, color, disabled, style, label, containerStyle,
      tooltipTitle, tooltipPlacement, href
    } = this.props;
    const { isMobileDevice } = this.context

    let canCreate = true;
    if(app && user)
      canCreate = canCreateOrUpdate(user, app);

    const button = (
      <span>
        <Button
          id={id}
          variant={variant || "contained"}
          type="button"
          onClick={onClick}
          href={href}
          color={color || "primary"}
          disabled={disabled}
          className='common-add-button'
          style={merge({float: 'right', marginTop: '0px', marginBottom: '0px'}, style || {})} >
          <AddCircleIcon style={{paddingRight: '5px'}} />
          { label }
        </Button>
      </span>
    );

    return (canCreate && !isMobileDevice) ? (
        <span style={containerStyle || {}}>
          {
            tooltipTitle ?
            <Tooltip title={tooltipTitle} placement={tooltipPlacement || "left"}>
              {button}
            </Tooltip> :
            button
          }
        </span>
      ) : null;
  }
}

const mapStateToProps = state => ({
  user: state.main.user.user,
});

export default connect(mapStateToProps)(AddButton);

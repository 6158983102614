import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class SiteBooking extends Component {
  render() {
    return <Tooltip title="Site Bookings" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 1h24v24H0z"/>
        <path fill={this.props.fill} fillRule="nonzero" d="M18 19v-2h4V3H2v14h4v2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h20a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4z"/>
        <path fill={this.props.fill} d="M2 6h20v2H2z"/>
        <rect width="4" height="2" x="4" y="1" fill={this.props.fill} rx="1" transform="rotate(90 6 2)"/>
        <rect width="4" height="2" x="10" y="1" fill={this.props.fill} rx="1" transform="rotate(90 12 2)"/>
        <rect width="4" height="2" x="16" y="1" fill={this.props.fill} rx="1" transform="rotate(90 18 2)"/>
        <circle cx="12" cy="17" r="6" stroke={this.props.fill} strokeWidth="2"/>
        <path fill={this.props.fill} d="M14.536 18.121a1 1 0 0 1-1.415 1.415L11 17.414V14a1 1 0 0 1 2 0v2.586l1.536 1.535z"/>
      </g>
      </svg>
    </Tooltip>;
  }
}

export default SiteBooking;

import React from 'react';
import SideDrawer from './SideDrawer';
import CommonButton from './CommonButton';
import Hierarchy from './Hierarchy';

const HierarchySideDrawer = ({
  isOpen, title, contractId, orderId, data, statusColors, legendColors, checkbox, selected,
  onSelectChange, onRefresh, onClose, showUpdateTonnage, openUpdateTonnagePopup, style
}) => (
  <div style={{textAlign: 'center', ...style}}>
    <SideDrawer isOpen={isOpen} title={title} onClose={onClose} size="big">
      <Hierarchy
        contractId={contractId}
        orderId={orderId}
        data={data}
        statusColors={statusColors}
        legendColors={legendColors}
        checkbox={checkbox}
        selected={selected}
        onSelectChange={onSelectChange}
        onRefresh={onRefresh}
      />
      <div style={{float: 'right'}}>
        <CommonButton type='button' label='Close' default onClick={onClose} variant="contained" />
        {showUpdateTonnage &&
          <CommonButton type='button' label='Update Tonnage' default onClick={openUpdateTonnagePopup} variant="contained" />
        }
      </div>
    </SideDrawer>
  </div>
);

export default HierarchySideDrawer;

import React from 'react';
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import queryString from 'query-string';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  get, set, compact, cloneDeep, omit, isEmpty, forEach, mapValues, some,
  find, isNumber, includes, map, merge, isEqual, isObject, isString, uniq,
  findIndex, has, orderBy, filter, isNull, upperFirst, min
} from 'lodash';
import { InputAdornment, FormControlLabel, Checkbox, TextField, createFilterOptions, Dialog, DialogContent, DialogActions, Button } from '@mui/material/';
import APIService from '../../services/APIService';
import { getCommodities } from '../../actions/api/commodities';
import { required, selected, valueBetween, valueAbove, valueBelow, truckRegoRegex } from '../../common/validators';
import {
  FIELD,
  REQUIRED_FIELD,
  DATE_DB_FORMAT,
  TIME_DB_FORMAT,
  COMMODITIES,
  INSPECTION_ORIGIN_ID,
  WEIGHT_ORIGIN_ID,
  DEFAULT_WEIGHT_ID,
  NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE,
  TRUCK_CODES,
  FREIGHT_CONTRACT_TYPE,
  PACK_ORDER_TYPE_ID,
  REGRADE_RESEASON_OPTION_TYPE,
  OPTION_TYPE_WEB_SPLIT_LOADS,
  TRUCK_SEARCH_OPTIONS,
  SEARCH_BY_FREIGHT_PROVIDER_REGOS,
  SEARCH_BY_ALL_REGOS,
  SEARCH_BY_TRUCK_OWNER_REGOS,
  SYSTEM_COMPANY_IDS,
  NOTICE_NUMBERS,
  MAX_UPLOAD_DOCKETS,
  STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES,
  UNIT_ABBREVIATIONS,
  LOAD_CREATE_OR_EDIT_ERROR_MESSAGE
} from '../../common/constants';
import {
  getSiteName, getDateTimeInUTC, getDateTimeFromUTC, isSystemCompany, getLoadWarningTableData,
  generateIdentifier, getCountryLabel, getCountryConfig, isCompanyGrower, getCountrySystemCompanyId, isVisibleInCountry, getDefaultTruckTrailerUnit, getCountryDisplayUnit
} from '../../common/utils';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonTimePicker from '../common/CommonTimePicker';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import SeasonSelect from '../common/select/SeasonSelect';
import CommonSelect from '../common/select/CommonSelect';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import SpecParametersValue from '../common/SpecParametersValue';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonSelectInput from '../common/CommonSelectInput';
import FileUpload from '../common/FileUpload';
import { validateRego, isLoading, forceStopLoader } from '../../actions/main';
import { createTruck } from '../../actions/api/trucks';
import { addTruck, emptyCreatedTruck } from '../../actions/companies/trucks';
import { getStoredCommodityDetails } from '../../actions/api/storages';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';
import AdditionalMassLimitFields from '../common/AdditionalMassLimitFields';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import IconButton from '@mui/material/IconButton';
import { Cancel as RemoveIcon } from '@mui/icons-material'
import AddButton from '../common/AddButton';
import NumberField from '../common/NumberField';

const autocompleteFilters = createFilterOptions();

class OutloadForm extends React.Component {
  constructor(props) {
    super(props);

    const queryParams = queryString.parse(this.props.location.search);
    const load = this.props.outload;
    this.generatedIdentifier = generateIdentifier('load');
    const storageType = get(props.movement, 'freightPickup.consignor.sites[0].location.storageType');
    const isConsignorSiteBHC = get(props.movement, 'freightPickup.consignor.sites[0].isBhc');
    const isPackMovement = get(props.movement, 'typeId') === PACK_ORDER_TYPE_ID;
    this.farmId = get(this.props.match, 'params.farm_id');
    if (this.farmId)
      this.farmId = parseInt(this.farmId);
    this.storageId = get(this.props.match, 'params.storage_id');
    if (this.storageId)
      this.storageId = parseInt(this.storageId);
    const season = (queryString.season && queryString.season.toLowerCase() !== 'multiple') ? queryString.season : '';
    this.isRegradedLoad = false;
    this.countryTonnageLabel = getCountryLabel('tonnage');
    this.state = {
      unit: getDefaultTruckTrailerUnit(),
      availableTonnageCache: {},
      isLoadingAvailableTonnage: false,
      submitting: false,
      existingTruckConfig: {},
      isWarehouseInvoiced: false,
      categories: [],
      providerTrucks: [],
      permits: [],
      outload:load,
      selectedStockOwner: null,
      stockOwner: {
        value: '',
        errors: [],
        validators: [required()]
      },
      isCopyFromInload: false,
      selectedTrucks:[],
      companyNgrs: {},
      selectedNgr: undefined,
      sellerAvailableTonnage: undefined,
      sellerAvailableTonnageMessage: undefined,
      isVarietyMandatoryLabel: false,
      isDirectLoad: false,
      isStockUpdateLoad: false,
      showAmendTonnagePopup: false,
      isSubmit: false,
      massLimitPermit: {
        value: '',
        validators: [required()],
        errors: [],
      },
      ghms: {
        value: false,
        validators: [],
        errors: [],
      },
      code: {
        value: '',
        validators: [],
        errors: [],
      },
      lockStockOwner: false,
      searchOption: (this.props.movement && this.props.outload) ? (get(this.props, 'movement.providerId') == get(this.props, 'outload.truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS) : this.props.movement ? (get(this.props, 'movement.providerId') == get(this.props.movement, 'plannedTruck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS) : SEARCH_BY_FREIGHT_PROVIDER_REGOS,
      chemicalApplicationRates: [],
      models: {
        outload: {
          comment: cloneDeep(FIELD),
          date: set(cloneDeep(REQUIRED_FIELD), 'value', moment().format(DATE_DB_FORMAT)),
          time: set(cloneDeep(REQUIRED_FIELD), 'value', moment().format(TIME_DB_FORMAT)),
          freightProviderId: cloneDeep(isPackMovement ? FIELD : REQUIRED_FIELD),
          truckId: cloneDeep(isPackMovement ? FIELD : REQUIRED_FIELD),
          truckCompany: cloneDeep(FIELD),
          truckCompanyId: cloneDeep(FIELD),
          commodityId: set(cloneDeep(REQUIRED_FIELD), 'value', parseInt(queryParams.commodityId) || ''),
          varietyId: set(cloneDeep(FIELD), 'validators', [selected()]),
          season: set(cloneDeep(REQUIRED_FIELD), 'value', season),
          ngrId: {
            value: queryParams.ngrId || '',
            validators: [required()],
            errors: [],
          },
          tareWeight: cloneDeep(FIELD),
          grossWeight: cloneDeep(FIELD),
          docketNumber: set(cloneDeep(FIELD), 'validators', []),
          bookingNumber: set(cloneDeep(FIELD), 'validators', []),
          docketImage: {
            value: [],
            validators: [],
            errors: [],
          },
          farmFieldId: cloneDeep(FIELD),
          gradeId: {
            ...FIELD,
            value: parseInt(queryParams.gradeId) || '',
            validators: [required(), selected()]
          },
          storageId: set(props.movement && !load && storageType !== 'system' && !isConsignorSiteBHC ? cloneDeep(REQUIRED_FIELD) : cloneDeep(FIELD), 'value', undefined),
          farmId: cloneDeep(FIELD),
          estimatedNetWeight: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          orderNumber: set(cloneDeep(FIELD), 'validators', []),
          consignor: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
          },
          quantity: {
            value: undefined,
            validators: [],
            errors: [],
          },
          categoryId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          steer_point_5: {
            value: false,
            validators: [],
            errors: [],
          },
          steer_1_point_1: {
            value: false,
            validators: [],
            errors: [],
          },
          noticeNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          accreditationNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          restricted: {
            value: undefined,
            validators: [],
            errors: [],
          },
          declaredMassLimit: {
            value: undefined,
            validators: [],
            errors: [],
          },
          permitNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          containerNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          loadIdentifier: cloneDeep(FIELD),
          containerTare: cloneDeep(FIELD),
        },
        specs: {},
      },
      trucks: [],
      commoditySpecs: [],
      gradeSpecs: [],
      ngrs: [],
      sites: [],
      queryParams,
      storage: null,
      selectedSite: null,
      gradeFloatingLabelText: 'Grade',
      docketNumberFloatingLabelText: `${getCountryLabel('docket')} No (Optional)`,
      ngrFloatingLabelText: 'NGR',
      seasonFloatingLabelText: 'Season',
      isNetWeightGreaterThanCurrentTonnage: false,
      isNetWeightValid: true,
      isFetchingBHC: false,
      isBHCFetched: false,
      initialStorageId: '',
      siteName:
        (props.movement && load) || (props.movement && storageType === 'system') || (props.movement && isConsignorSiteBHC)
          ? getSiteName(get(props.movement, 'freightPickup.consignor'), load)
          : null,
      showEstimatedNetWeightField: true,
      selectedConsignor: null,
      docketImageFileCount: get(props.movement, 'freightPickup.docketImageUrl', []).length,
      docketImagePath: uniq(get(props.movement, 'freightPickup.docketImagePath', [])),
      docketImageUrl: uniq(get(props.movement, 'freightPickup.docketImageUrl', [])),
      initialEstimatedNetWeight: 0,
      loadSharing: true,
      accreditationNumberChecked: false,
      permitChecked: false,
      restrictedChecked: false,
      truckErrors: {},
      showAdditionalMassLimitFields: false,
      amendParentTonnage: false,
      chemicalApplications: [],
    };
    this.onDocketImageUpload = this.onDocketImageUpload.bind(this);
    this.onDocketImageRemove = this.onDocketImageRemove.bind(this);
    this.handleTareWeightChange = this.handleTareWeightChange.bind(this);
    this.handleGrossWeightChange = this.handleGrossWeightChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleVarietyChange = this.handleVarietyChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleSpecsChange = this.handleSpecsChange.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.getTonnageFieldsStates = this.getTonnageFieldsStates.bind(this);
    this.getSites = this.getSites.bind(this);
    this.checkNetWeightAgainstCurrentTonnage = this.checkNetWeightAgainstCurrentTonnage.bind(this);
    this.setFieldWarnings = this.setFieldWarnings.bind(this);
    this.getFieldWarnings = this.getFieldWarnings.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.getTrucks = this.getTrucks.bind(this);
    this.getNgrs = this.getNgrs.bind(this);
    this.getStorage = this.getStorage.bind(this);
    this.updateValidatorsBasedOnStorage = this.updateValidatorsBasedOnStorage.bind(this);
    this.isNetWeightMoreThanAllowed = this.isNetWeightMoreThanAllowed.bind(this);
    this.getTonnage = this.getTonnage.bind(this);
    this.voidLoad = this.voidLoad.bind(this);
    this.setOwnerAndNgrForCustomerOnlyMovements = this.setOwnerAndNgrForCustomerOnlyMovements.bind(this);
    this.handleTruckCodeChange = this.handleTruckCodeChange.bind(this);
    this.setInvalidIdentifierError = this.setInvalidIdentifierError.bind(this);
    this.checkDirectLoad = this.checkDirectLoad.bind(this);
    this.checkStockUpdateLoad = this.checkStockUpdateLoad.bind(this)
    this.handleContainerTareChange = this.handleContainerTareChange.bind(this);
    this.handleTruckDetailsSelectValueChange = this.handleTruckDetailsSelectValueChange.bind(this);
    this.getTonnageFieldsStates();
  }

  fetchPermits() {
    APIService.trucks().appendToUrl(`categories/permits/?for_date=${this.state.models.outload.date.value}`).get().then(permits => this.setState({ permits: permits }));
  }

  fetchCategories() {
    APIService.trucks().appendToUrl(`categories/?for_date=${this.state.models.outload.date.value}`).get().then(categories => this.setState({ categories: categories }, () => this.setUpCategoryFromLoadOrTruck()));
  }

  fetchInvoiced() {
    const loadId = get(this.props, 'outload.id')
    if(loadId)
      APIService.loads(loadId).appendToUrl('invoiced/').get().then(response => {
        this.setState({isWarehouseInvoiced: get(response, 'warehouse') || false})
      })
  }

  fetchTruckConfig = () => {
    const callback = response => {
      if(response.categoryId) {
        const newState = {...this.state}
        newState.existingTruckConfig = response
        newState.models.outload.categoryId.value = response.categoryId
        newState.models.outload.steer_1_point_1.value = response.steer1Point1
        newState.models.outload.steer_point_5.value = response.steerPoint5
        newState.models.outload.permitNumber.value = get(response, 'permitNumber')
        newState.models.outload.accreditationNumber.value = get(response, 'accreditationNumber')
        newState.models.outload.restricted.value = get(response, 'restricted')
        newState.models.outload.declaredMassLimit.value = get(response, 'declaredMassLimit')
        newState.models.outload.noticeNumber.value = get(response, 'noticeNumber')
        newState.permitChecked = Boolean(get(response, 'permitNumber'));
        newState.accreditationNumberChecked = Boolean(get(response, 'accreditationNumber'));
        newState.restrictedChecked = Boolean(get(response, 'restricted'));
        newState.loadSharing = get(response, 'loadSharing');
        this.setState(newState, this.setUpCategoryFromLoadOrTruck)
      }
    }

    if(this.props.outload?.id)
      APIService.loads(this.props.outload.id).appendToUrl('truck-config/').get().then(callback)
    else if(this.props.movement?.id)
      APIService.freights().contracts(this.props.movement.id).appendToUrl('truck-config/outload/').get().then(callback)
  }

  componentDidMount() {
    this.fetchTruckConfig();
    this.fetchInvoiced()
    this.setStockOwnerFromLoadOrQueryParams()

    if (this.props.movement && this.hasQuantityBasedCommodity(this.props.movement.commodityId)) {
      const newState = { ...this.state };
      newState.models.outload.quantity.validators = [required()];
      this.setState(newState);
    }
    if (get(this.props.movement, 'status') === 'manual_contract_complete_balanced') {
      const newState = { ...this.state };
      newState.models.outload.truckId.validators = [];
      newState.models.outload.freightProviderId.validators = [];
      this.setState(newState);
    }
    if (!this.props.movement && !get(this.props.outload, 'truckId')) {
      const newState = { ...this.state };
      newState.models.outload.truckId.validators = [];
      newState.models.outload.freightProviderId.validators = [];
      newState.models.outload.loadIdentifier.value = this.generatedIdentifier;
      newState.models.outload.loadIdentifier.validators = [required()];
      this.setState(newState);
    }
    if (isEmpty(this.props.commodities)) {
      this.props.getCommodities();
    }

    const commodityId = get(this.state, 'models.outload.commodityId.value');
    if (commodityId) {
      this.handleCommodityChange(commodityId, 'outload.commodityId');
    }
    const useConversions = getCountryConfig()?.showConversions
    if (this.props.outload) {
      const { outload } = this.props;
      const newState = { ...this.state };
      this.props.isLoading();
      this.getTrucks(get(outload, 'truck.companyId'), () => newState.models.outload.truckId.value = get(outload, 'truckId'));
      this.isRegradedLoad = isEqual(get(outload, 'optionType'), REGRADE_RESEASON_OPTION_TYPE);
      Object.keys(this.state.models.outload).forEach(key => {
        newState.models.outload[key].value = outload[key];
      });
      if(!outload?.freightProviderId && outload?.truck?.companyId)
        newState.models.outload.freightProviderId.value = outload.truck.companyId
      if (outload.dateTime) {
        const localDateTime = getDateTimeFromUTC(outload.dateTime);
        newState.models.outload.date.value = localDateTime.date;
        newState.models.outload.time.value = localDateTime.time;
      }
      if(outload.ngrId) {
        newState.models.outload.ngrId.value = get(outload, 'ngrId')
        newState.stockOwner.value = get(outload, 'ngr.companyId') || outload.ngrCompanyId
      }
      newState.models.outload.loadIdentifier.value = get(outload, 'identifier') || get(outload, 'extras.identifier') || get(outload, 'shrinkageParent');
      newState.models.outload.estimatedNetWeight.value = (useConversions ? outload.netWeightLb || outload?.weightsInLB?.netWeightLb : outload.tonnage || outload.estimatedNetWeight) || outload.tonnage;
      newState.searchOption = get(this.props.movement, 'providerId') == get(outload, 'truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
      newState.models.outload.truckCompanyId.value = get(outload, 'truck.companyId');
      if(get(outload, 'truck.companyId')) {
        let systemCompanyId = getCountrySystemCompanyId();
        if(get(outload, 'truck.companyId') == systemCompanyId)
          newState.models.outload.truckCompany.value = {id: systemCompanyId, name: 'Unknown', transactionParticipation: true}
        else
          APIService.companies(get(outload, 'truck.companyId')).get().then(data => newState.models.outload.truckCompany.value = data);
      }
      if (useConversions) {
        const tareWeight = outload.tareWeightLb || outload?.weightsInLB?.tareWeightLb
        const grossWeight = outload.grossWeightLb || outload?.weightsInLB?.grossWeightLb
        newState.models.outload.tareWeight.value = tareWeight ? parseFloat(tareWeight).toFixed(2) : null;
        newState.models.outload.grossWeight.value = grossWeight ? parseFloat(grossWeight).toFixed(2) : null;
      }
      if (!newState.models.outload.estimatedNetWeight.value)
        newState.models.outload.estimatedNetWeight.value = this.getNetWeight(newState);
      newState.initialEstimatedNetWeight = cloneDeep(newState.models.outload.estimatedNetWeight.value);
      this.setState(newState, () => {
        this.checkNetWeightAgainstCurrentTonnage();
        this.fetchChemicalApplications();
      });
    } else if (this.props.movement) {
      const { movement, flipLoad } = this.props;
      let truck = get(movement, 'plannedTruck');
      this.getTrucks(get(movement, 'freightPickup.load[0].truck.companyId') || get(truck, 'companyId') || get(this.props, 'movement.providerId',''));
      const newState = { ...this.state };
      if (this.isNGRFieldDisabled())
        newState.models.outload.ngrId.value = get(movement, 'commodityContract.seller.ngrId');
      newState.models.outload.orderNumber.value = get(movement, 'freightPickup.orderNumber');
      newState.models.outload.freightProviderId.value = movement.providerId;
      newState.models.outload.commodityId.value = movement.commodityId;
      newState.models.outload.varietyId.value = movement.varietyId;
      newState.models.outload.season.value = movement.season;
      if (!has(flipLoad, 'truckId') && get(movement, 'plannedTruckId')) {
        let truckId = get(movement, 'plannedTruckId');
        newState.models.outload.truckId.value = truckId;
      }
      newState.models.outload.truckCompanyId.value = get(truck, 'companyId') || movement.providerId;
      if (get(truck, 'companyId') === movement.providerId)
        newState.models.outload.truckCompany.value = movement.provider;
      else
        newState.models.outload.truckCompany.value = {id: get(truck, 'companyId'), name: get(truck, 'company.name')}
      newState.models.outload.gradeId.value = get(movement, 'plannedGradeId') || get(movement, 'commodityContract.gradeId');
      if (has(flipLoad, 'truckId')) newState.models.outload.truckId.value = get(flipLoad, 'truckId');
      if (has(flipLoad, 'tareWeight')) newState.models.outload.tareWeight.value = useConversions ? get(flipLoad, 'tareWeightLb') || flipLoad?.weightsInLB?.tareWeightLb : get(flipLoad, 'tareWeight');
      if (has(flipLoad, 'grossWeight')) newState.models.outload.grossWeight.value = useConversions ? get(flipLoad, 'grossWeightLb') || flipLoad?.weightsInLB?.grossWeightLb : get(flipLoad, 'grossWeight');
      if (has(flipLoad, 'netWeight')) newState.models.outload.estimatedNetWeight.value = useConversions ? get(flipLoad, 'netWeightLb') || flipLoad?.weightsInLB?.netWeightLb : get(flipLoad, 'netWeight');
      if (!newState.models.outload.estimatedNetWeight.value)
        newState.models.outload.estimatedNetWeight.value = this.getNetWeight(newState);
      newState.initialEstimatedNetWeight = cloneDeep(newState.models.outload.estimatedNetWeight.value);
      this.setState(newState, () => {
        if (get(this.props.movement, 'isBlended'))
          this.onChemicalApplicationAdd();
        this.setGradeSpecs(this.state.models.outload.gradeId.value);
      });
    }
    if (this.props.outload && this.props.movement) {
      if (get(this.props.outload, 'dateTime')) {
        const newState = { ...this.state };
        const localDateTime = getDateTimeFromUTC(this.props.outload.dateTime);
        newState.models.outload.date.value = localDateTime.date;
        newState.models.outload.time.value = localDateTime.time;
        this.setState(newState);
      }
    }

    if (get(this.props, 'movement.typeId') === PACK_ORDER_TYPE_ID) {
      const { order } = this.props.movement;
      const newState = {...this.state};
      const siteId = get(order, 'freightContainer.consignor.handlerId');
      const storageId = get(order, 'freightContainer.consignor.sites.0.locationId')
      newState.models.outload.consignor.handlerId.value = siteId;
      newState.models.outload.storageId.value = storageId;
      newState.selectedConsignor = get(order, 'freightContainer.consignor.handler');
      newState.selectedSite = get(order, 'freightContainer.consignor.sites.0.location');
      this.getConsignorSites(get(newState.selectedConsignor, 'id'));
      this.setState(newState);
    }

    if(has(this.state.outload, 'netWeight')) {
      const newState = {...this.state};
      newState.models.outload.estimatedNetWeight.value = useConversions ? this.state.outload.netWeightLb : this.state.outload.netWeight;
      this.setState(newState);
    }
    if (this.isContainerMovement()) {
      const newState = {...this.state};
      newState.models.outload.estimatedNetWeight.value = get(this.props, 'outload.estimatedNetWeight') || get(this.props, 'movement.plannedTonnage');
      newState.models.outload.containerTare.value = get(this.props, 'outload') ? get(this.props, 'outload.splitWeights.containerTare') : get(this.props, 'movement.packMovement.containerTare');
      newState.models.outload.tareWeight.value = get(this.props, 'outload.splitWeights.truckTare');
      newState.models.outload.ngrId.value = get(this.props, 'movement.customer.ngrId');
      this.setState(newState);
    }

    if(this.props.movement){
      const newState = { ...this.state };
      const plannedFreightPickupDate = get(this.props.movement, 'freightPickup.dateTime') || get(this.props.movement, 'freightDelivery.dateTime')
      if(plannedFreightPickupDate && new Date(plannedFreightPickupDate) <= new Date()){
        const localDateTime = getDateTimeFromUTC(plannedFreightPickupDate);
        newState.models.outload.date.value = localDateTime.date;
        newState.models.outload.time.value = localDateTime.time;
      }
      this.setState(newState);
    }
    this.getStorage();
    this.getNgrs();
    this.getSites(true);
    this.setHandler();
    this.setGradeSpecs(this.state.models.outload.gradeId.value);
    this.checkDirectLoad();
    this.checkStockUpdateLoad();
    this.fetchAndSetStockOwner();
    if (this.state.models.outload.date.value) {
      this.fetchCategories();
      this.fetchPermits()
    }
    if (get(this.props.movement, 'isBlended'))
      this.fetchChemicalApplicationRates();
  }

  fetchChemicalApplicationRates() {
    const companyId = get(this.props.movement, 'commodityContract.seller.companyId');
    if (companyId) {
      APIService.companies(companyId)
        .appendToUrl('application-rates/')
        .get()
        .then(resp => this.setState({chemicalApplicationRates: resp}))
    }
  }

  fetchChemicalApplications() {
    if (get(this.props.movement, 'isBlended')) {
      APIService.loads(this.props.outload.id)
        .appendToUrl('chemical-applications/')
        .get()
        .then(response => {
          if (response && !isEmpty(response)) {
            const newState = {...this.state};
            const movementChemicalApplications = (isEmpty(get(this.props.movement, 'chemicalApplications')) ? get(this.props.movement, 'orderChemicalApplications') : get(this.props.movement, 'chemicalApplications')) || [];
            forEach(response, (chemicalApplication, index) => {
              newState.chemicalApplications.push({
                id: index + 1,
                commodityId: chemicalApplication.commodityId,
                storageId: chemicalApplication.storageId,
                applicationRate: chemicalApplication.applicationFee,
                commodityIdOptions: movementChemicalApplications.map(_chemicalApplication => _chemicalApplication.commodityId),
                commodity: find(this.props.commodities, {id: chemicalApplication.commodityId}),
                chemicalLoadId: chemicalApplication.chemicalLoadId,
                errors: []
              })
            })
            this.setState(newState);
          }
        })
    } 
  }

  isContainerMovement() {
    let { movement } = this.props;
    if (movement) {
      return (get(movement, 'typeId') !== PACK_ORDER_TYPE_ID && !includes([null, undefined], get(movement, 'containerNumber')));
    }
  }

  fetchAndSetStockOwner() {
    if(this.props.movement) {
      APIService.freights().contracts().appendToUrl('outloads/stock-owners/').post({movement_id: this.props.movement.id}).then(response => {
        let lockStockOwner = Boolean(response?.outload && this.props.movement.commodityContractId)
        this.setState({...this.state, stockOwner: {...this.state.stockOwner, value: response.outload}, lockStockOwner: lockStockOwner})
      })
    }
  }

  checkShrinkageLoad() {
    return get(this.props, 'outload') && get(this.props.outload, 'shrinkageParent')
  }

  checkDirectLoad() {
    if(!this.props.movement && !(get(this.props.outload, 'checkpointId')) && !(get(this.props.outload, 'titleTransferNumber')) && !(get(this.props.outload, 'optionType')) && !get(this.props.outload, 'shrinkageParent')) {
      const newState = { ...this.state };
      newState.models.outload.loadIdentifier.value =  get(this.props.outload, 'extras.identifier') || this.generatedIdentifier;
      newState.models.outload.loadIdentifier.validators = [required()];
      newState.isDirectLoad = true;
      this.setState(newState);
    }
  }

  checkStockUpdateLoad() {
    if (includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, get(this.props.outload, 'optionType')))
      this.setState({ isStockUpdateLoad: true });
  }

  setOwnerAndNgrForCustomerOnlyMovements() {
    if (this.props.movement && includes([FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY, PACK_ORDER_TYPE_ID], this.props.movement.typeId) && this.props.isCreate
      && !get(this.state, 'models.outload.ngrId.value') && get(this.state, 'stockOwner.value') == get(this.props.movement, 'customer.companyId', get(this.props, 'flipLoad.ngr.companyId'))) {
      const { movement, flipLoad } = this.props;
      const newState = { ...this.state };
      set(newState.models, `outload.ngrId.value`, get(movement, 'customer.ngrId', get(flipLoad, 'ngrId')));
      set(newState, 'selectedNgr', get(movement, 'customer.ngr'));
      this.setState(newState);
    }
  }

  setStockOwnerFromLoadOrQueryParams() {
    if (!this.isNGRFieldDisabled()) {
      const newState = { ...this.state };
      const { outload } = this.props;
      if (outload) {
        newState.models.outload.ngrId.value = outload.ngrId;
        const isSharedNgr = get(outload, 'ngr.ngrType') === 'shared';
        newState.stockOwner.value = get(this.state.outload, 'stockOwnerId') || (isSharedNgr ? newState.stockOwner.value : get(outload, 'ngr.companyId')) || get(outload, 'ownerId') || newState.stockOwner.value;
      } else if (this.state.queryParams.ngrId) {
        newState.models.outload.ngrId.value = get(this.state, 'selectedNgr.id');
        newState.stockOwner.value = get(this.state, 'selectedNgr.companyId');
      }
      this.setState(newState);
    }
  }

  onCompanyChange = item => {
    const value = item?.id
    const { outload } = this.props;
    const newState = { ...this.state };
    newState.selectedStockOwner = item
    newState.stockOwner.value = value;
    const isSharedNgr = get(outload, 'ngr.ngrType') === 'shared';
    const ownerId = get(outload, 'stockOwnerId') || get(outload, 'ownerId') || (isSharedNgr ? this.state.stockOwner.value : get(outload, 'ngr.companyId'));
    if (ownerId && ownerId === value)
      newState.models.outload.ngrId.value = outload.ngrId;
    else if (value !== get(this.state, 'selectedNgr.companyId'))
      newState.models.outload.ngrId.value = '';

    this.updateDependentValidatorsByStorage(newState);
    const cachedNgrs = get(this.state.companyNgrs, value);
    newState.ngrs = cachedNgrs?.length ? cachedNgrs : []
    this.setState(newState, () => {
      if (value) {
        if (isEmpty(cachedNgrs))
          APIService.companies(value).ngrs().appendToUrl('minimal/')
          .get()
          .then(ngrs => this.setState({
            companyNgrs: { ...this.state.companyNgrs, [value]: ngrs },
            ngrs: ngrs
          }, () => {
            this.setVarietyMandatory()
            this.setOwnerAndNgrForCustomerOnlyMovements()
            this.setStockOwnerMandatoryError()
          }));
      } else {
        this.setVarietyMandatory()
        this.setStockOwnerMandatoryError()
      }
    });
  };

  isVarietyMandatory = () => {
    const { selectedConsignor } = this.state
    let result = false
    if(selectedConsignor?.isVarietyMandatory) {
      const isMandatoryForGrowers = selectedConsignor.userTypeForVarietyMandatory.includes('growers')
      const isMandatoryForNonGrowers = selectedConsignor.userTypeForVarietyMandatory.includes('non_growers')
      let isVarietyMandatoryForLoad = ['inload_and_outload'].some(type => selectedConsignor.loadTypeForVarietyMandatory.includes(type))
      result = isMandatoryForGrowers && isMandatoryForNonGrowers && isVarietyMandatoryForLoad
      if(!result && isVarietyMandatoryForLoad && this.state.stockOwner.value) {
        const company = this.state.selectedStockOwner
        const isGrower = isCompanyGrower(company)
        result = isMandatoryForGrowers ? isGrower : !isGrower
      }
    }

    return result
  }

  setVarietyMandatory = () => {
    const isVarietyMandatory = this.isVarietyMandatory()
    if(isVarietyMandatory && !this.state.isVarietyMandatoryLabel) {
      const newState = {...this.state}
      newState.models.outload.varietyId.validators = [required(), selected()];
      newState.isVarietyMandatoryLabel = true;
      setTimeout( () => this.setState(newState), 100)
    } else if(!isVarietyMandatory && this.state.isVarietyMandatoryLabel) {
      const newState = {...this.state}
      newState.models.outload.varietyId.validators = [selected()];
      newState.models.outload.varietyId.errors = [];
      newState.isVarietyMandatoryLabel = false;
      setTimeout( () => this.setState(newState), 100)
    }
  }

  setHandler() {
    const self = this;
    const handler = get(self.props.outload, 'handler') || get(self.props.movement, 'freightPickup.consignor.handler');
    if (handler) {
      const newState = { ...self.state };
      newState.selectedConsignor = { id: handler.id, name: handler.displayName, ...handler };
      newState.models.outload.consignor.handlerId.value = handler.id;
      newState.models.outload.consignor.handlerId.errors = this.isRestrictedToEditForSite(handler) ? [LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created')] : [];
      self.setState(newState, () => {
        if(this.state.selectedConsignor?.id)
          this.getConsignorSites(this.state.selectedConsignor.id)
        this.setShowAdditionalMassLimitFields()
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.models.outload.ngrId.value && this.isNGRFieldDisabled()) {
      const ngrId = this.getNGRId();
      if (ngrId) {
        const newState = { ...this.state };
        newState.models.outload.ngrId.value = ngrId;
        this.setState(newState);
      }
    }
    if (this.isContainerMovement() && !this.state.selectedNgr) {
      this.setState({selectedNgr: get(this.props, 'outload') ? get(this.props, 'outload.ngr') : get(this.props, 'movement.customer.ngr')})
    }
    if ((this.props.outload || this.props.movement) && !isEmpty(this.props.commodities) && isEmpty(this.state.commoditySpecs))
      this.handleCommodityChange(this.state.models.outload.commodityId.value, 'outload.commodityId');
    let inload = this.getCounterPartLoad()
    if( inload?.truckId && this.state.isCopyFromInload && isNull(this.state.models.outload.truckId.value)) {
      const newState = {...this.state};
      newState.models.outload.truckId.value = inload?.truckId;
      this.setState(newState);
    }
    if(this.props.createdTruck && !find(this.state.trucks, {id: this.props.createdTruck?.id})) {
      const newState = {...this.state};
      newState.trucks = [...this.state.trucks, this.props.createdTruck]
      if(this.state.searchOption != SEARCH_BY_ALL_REGOS)
        this.handleSelectFieldChange(this.props.createdTruck?.id, 'outload.truckId', this.props.createdTruck);
      this.props.emptyCreatedTruck();
      this.setState(newState);
    }
    if(this.props.outload && prevState.searchOption != this.state.searchOption) {
      if (this.state.searchOption == SEARCH_BY_FREIGHT_PROVIDER_REGOS)
        this.getTrucks(get(this.props, "movement.providerId"));
      else
        this.setState({trucks: []})
    }
    if(this.props.isLoading && this.props.outload && !isEmpty(this.state.trucks)) {
      if (!this.state.models.outload.truckCompany.value && get(this.props.outload, 'truck.companyId') == getCountrySystemCompanyId() && this.state.searchOption == SEARCH_BY_TRUCK_OWNER_REGOS) {
        const newState = {...this.state}
        newState.models.outload.truckCompany.value = {id: getCountrySystemCompanyId(), name: 'Unknown', transactionParticipation: true}
        newState.models.outload.truckId.value = get(this.props.outload, 'truckId')
        this.setState(newState)
      }
      if (!this.state.isSubmit)
        this.props.forceStopLoader();
    }
    if(prevProps !== this.props) {
      this.checkDirectLoad();
      this.checkStockUpdateLoad();
      this.setGradeSpecs(this.state.models.outload.gradeId.value);
    }
  }

  getTonnageFieldsStates() {
    const newState = { ...this.state };
    if (this.props.movement) {
      newState.showEstimatedNetWeightField = false;
      newState.models.outload.estimatedNetWeight.errors = [];
      if (this.props.movement.isAdHoc && !get(this.props.movement, 'freightPickup.consignor')) {
        newState.models.outload.consignor.handlerId.validators = [required()];
      }
    } else {
      newState.showEstimatedNetWeightField = true;
      newState.models.outload.estimatedNetWeight.errors = [];
    }
    this.changeFreightPickupTareWeightGrossWeightValidators(newState);
    this.changeFreightPickupGradeValidators(newState);
    this.setState(newState);
  }

  changeFreightPickupTareWeightGrossWeightValidators = newState => {
    const tareWeight = parseFloat(newState.models.outload.tareWeight.value);
    const grossWeight = parseFloat(newState.models.outload.grossWeight.value);
    if (this.props.movement) {
      if (
        !newState.models.outload.tareWeight.value &&
        !newState.models.outload.grossWeight.value &&
        get(newState, 'selectedSite.entity') === 'farm_field' &&
        (get(this.props.movement, 'commodityContract.weightId') !== WEIGHT_ORIGIN_ID ||
          !get(this.props.movement, 'commodityContractId') || !get(this.props.movement, 'order.commodityContractId'))
      ) {
        newState.models.outload.tareWeight.validators = [valueAbove(0)];
        newState.models.outload.grossWeight.validators = [valueAbove(0)];
      }
    }
    newState.models.outload.tareWeight.validators = [valueAbove(0), valueBelow(grossWeight)];
    newState.models.outload.grossWeight.validators = [isNaN(tareWeight) ? valueAbove(0) : valueAbove(tareWeight)];
    newState.models.outload.tareWeight.errors = [];
    newState.models.outload.grossWeight.errors = [];
  };

  changeFreightPickupGradeValidators = newState => {
    if (this.props.movement) {
      if (
        get(newState, 'selectedSite.entity') === 'farm_field' &&
        (get(this.props.movement, 'commodityContract.inspection.id') !== INSPECTION_ORIGIN_ID ||
          !get(this.props.movement, 'commodityContractId') || !get(this.props.movement, 'order.commodityContractId'))
      ) {
        newState.models.outload.gradeId.errors = [];
      }
    } else {
      newState.models.outload.gradeId.errors = [];
    }
  };

  confirmSubmit = async submitData => {
    const successCallback = () => {
      this.props.closeDrawer();
      if(!this.props.isCreate)
        localStorage.setItem('stocks', JSON.stringify({})); // stocks cache burst
      window.location.reload();
    };
    if(this.state.isDirectLoad || this.state.isStockUpdateLoad) {
      let isValidIdentifier = await this.checkIdentifier();
      if(!isValidIdentifier && !isEqual(get(this.props.outload, 'extras.identifier'), this.state.models.outload.loadIdentifier.value)) {
        this.setInvalidIdentifierError()
        this.unlockSubmit()
        return false
      } else {
        submitData.loadIdentifier = submitData.loadIdentifier.toUpperCase();
      }
    } else {
      delete submitData.loadIdentifier;
    }

    const storageId = this.state.models.outload.storageId.value || this.props.storageId || this.storageId;
    let datetimeString = this.props.outload ? this.props.outload.dateTime.replace("T", ' ').replace('Z', '') : '';
    datetimeString = datetimeString ? datetimeString.substring(0, 17) + '00' + datetimeString.substring(19) : '';
    if (this.props.outload && datetimeString != submitData.dateTime && get(this.props.outload, 'deviceSource.mobile') && get(this.props.outload, 'completedAt')) {
      setTimeout(() => {
        alertifyjs.confirm(
          'Warning',
          'The load date and time details will be replaced with the data entered here and the date and time data saved from the mobile app will be lost. Please confirm this change.',
          () => {
            submitData['completedAt'] = null;
            if (this.props.outload) {
              this.setState({ isSubmit: true})
              this.props.submit(this.props.outload.id, submitData, successCallback);
              this.unlockSubmit(null, 2000)
            } else if (storageId) {
              this.setState({ isSubmit: true})
              this.props.submit(storageId, submitData, successCallback);
              this.unlockSubmit(null, 2000)
            } else if (this.props.movement) {
              this.setState({ isSubmit: true})
              this.props.submit({ ...submitData, freightMovementId: this.props.movement.id }, successCallback);
              this.unlockSubmit(null, 2000)
            }
          },
          this.unlockSubmit,
        );
      }, 500)
    }
    else {
      if (this.props.outload) {
        this.setState({ isSubmit: true})
        this.props.submit(this.props.outload.id, submitData, successCallback);
        this.unlockSubmit(null, 2000)
      } else if (storageId) {
        this.setState({ isSubmit: true})
        this.props.submit(storageId, submitData, successCallback);
        this.unlockSubmit(null, 2000)
      } else if (this.props.movement) {
        this.setState({ isSubmit: true})
        this.props.submit({ ...submitData, freightMovementId: this.props.movement.id }, successCallback);
        this.unlockSubmit(null, 2000)
      }
    }
  };

  async checkIdentifier() {
    const identifierLoad = await APIService.loads().appendToUrl(`identifier/${this.state.models.outload.loadIdentifier.value.toUpperCase()}/`).get()
    return !identifierLoad;
  }

  setInvalidIdentifierError() {
    const newState = { ...this.state };
    newState.models.outload.loadIdentifier.errors.push('Identifier already exists');
    this.setState(newState);
  }

  setStockOwnerMandatoryError() {
    let hasErrors = false;
    if (!this.isNGRFieldDisabled() && !isSystemCompany()) {
      let errors = [];
      if (!this.state.stockOwner.value) {
        errors = ['This field can not be blank'];
        hasErrors = true;
      }
      const newState = { ...this.state };
      newState.stockOwner.errors = errors;
      this.setState(newState);
    }
    return hasErrors;
  }

  getInitialEstimatedNetWeight() {
    if (this.props.outload && this.state.initialStorageId == this.state.models.outload.storageId.value)
      return get(this.props.outload, 'netWeight', 0);

    if (this.props.movement && this.state.initialStorageId == this.state.models.outload.storageId.value)
      return get(this.props.movement, 'freight_pickup.loads.0.netWeight', 0);

    return 0;
  }

  isRestrictedToEditForSite = site => get(site, 'stocksManagement') && this.props.userCompanyId != get(site, 'companyId') && !isSystemCompany()

  isMassLimitFieldsValid() {
    let isValid = true;
    const ERROR_MESSAGE = "This field is required";
    if (this.state.showAdditionalMassLimitFields && this.state.models.outload.truckId.value) {
      const newState = {...this.state};
      if (this.state.permitChecked) {
        if (!this.state.models.outload.permitNumber.value) {
          isValid = false;
          newState.truckErrors.permitNumber = ERROR_MESSAGE;
        }
        if (!this.state.models.outload.declaredMassLimit.value) {
          isValid = false;
          newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      if (this.state.accreditationNumberChecked && !this.state.models.outload.accreditationNumber.value) {
        isValid = false;
        newState.truckErrors.accreditationNumber = ERROR_MESSAGE;
      }
      if (this.state.massLimitPermit.value === 'Notice' && !this.state.models.outload.noticeNumber.value) {
        isValid = false;
        newState.truckErrors.noticeNumber = ERROR_MESSAGE;
      }
      if (this.state.restrictedChecked) {
        if (!this.state.models.outload.restricted.value) {
          isValid = false;
          newState.truckErrors.restricted = ERROR_MESSAGE;
        }
        if (!this.state.models.outload.declaredMassLimit.value) {
          isValid = false;
          newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
        }
      }
      this.setState(newState);
    }
    const validator = get(this.state.massLimitPermit.validators, '0')
    if (validator && validator.isInvalid(this.state.massLimitPermit.value) && this.state.code.value) {
      const newState = {...this.state};
      isValid = false;
      newState.massLimitPermit.errors = ['This field is required.']
      this.setState(newState);
    }
    return isValid;
  }

  unlockSubmit = (errorMsg, timeout) => {
    setTimeout(() => {
      this.setState({submitting: false}, () => {
        if(errorMsg)
          alertifyjs.error(errorMsg, 5);
      })
    }, timeout ? timeout : 10)
  }

  getInProgressAndDeliveredTonnage() {
    return get(this.props, 'movement.parentTotalDeliveredTonnage', 0) + get(this.props, 'movement.parentTotalProgressTonnage', 0);
  }

  getMaxAllowedTonnage(tonnageProperty) {
    const parentTonnage = min([get(this.props, 'movement.maxAllowedTonnageOnOrder'), get(this.props, 'movement.parentTotalTonnageWithTolerance')]);
    if (isNumber(parentTonnage) && isNumber(get(this.props, 'movement.parentTotalDeliveredTonnage'))) {
      let maxAllowedTonnage = parentTonnage - get(this.props, 'movement.parentTotalDeliveredTonnage', 0);
      if ((get(this.props, 'movement.status') === 'confirmed') || (get(this.props, 'movement.status') === 'in_progress'))
         maxAllowedTonnage = maxAllowedTonnage - get(this.props, 'movement.parentTotalProgressTonnage', 0)
      const weightId = get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID);
      //  for split load, only consider that particular load's weight instead of movement weight,
      //  for non split movement weight is equal to outload weight when contract's inspection weight is origin
      //  split of inload when inspection weight is not origin is not handled
      const useConversions = getCountryConfig()?.showConversions;
      if (!tonnageProperty)
        tonnageProperty = useConversions ? 'netWeightLb' : 'netWeight';
      const counterLoad = this.getCounterPartLoad()
      if (this.props.outload || (get(this.props, 'movement.status') === 'delivered'))
        if (weightId === WEIGHT_ORIGIN_ID)
            maxAllowedTonnage = maxAllowedTonnage + Math.max(get(this.props.outload, tonnageProperty), this.props.movement.inferredTonnage);
        else
            maxAllowedTonnage = maxAllowedTonnage + Math.max(this.props.movement.inferredTonnage, get(this.props.outload, tonnageProperty), get(counterLoad, tonnageProperty));
      return parseFloat(maxAllowedTonnage.toFixed(2))
    }
    return 0;
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation()
    if (this.state.selectedConsignor && get(this.state.selectedConsignor, 'externallySyncSource')) {
      alertifyjs.error('Cannot perform this operation as site is externally managed', 5);
      return;
    }
    this.setState({submitting: true}, () => {
      if (!this.isMassLimitFieldsValid())
        this.unlockSubmit()
      else {
        let check = true;
        const farmId = this.props.outload?.farmId || this.props.outload?.farmField?.farmId
        if(farmId) {
          let existingSite = this.state.selectedConsignor
          if(existingSite) {
            if(this.isRestrictedToEditForSite(existingSite)) {
              this.unlockSubmit()
              return false
            }
          }
        }
        if (
          this.props.optionType == OPTION_TYPE_WEB_SPLIT_LOADS &&
          this.state.models.outload.storageId.value &&
          get(this.props, 'movement.freightPickup.load')
        ){
          const loads = this.props.movement.freightPickup.load
          const isExistingStorage = some(loads, load => get(load, 'storageId') == this.state.models.outload.storageId.value)
          if(isExistingStorage) {
            this.unlockSubmit()
            return false
          }
        }
        let pickupSite = this.state.selectedConsignor
        const counterSlot = this.getCounterPartLoad();
        if (this.isRestrictedToEditForSite(pickupSite)) {
          this.unlockSubmit()
          return false
        }
        else if (this.isContainerMovement() && counterSlot && get(counterSlot, 'handler.id') == this.state.selectedConsignor?.id) {
          this.unlockSubmit('Pickup and Delivery sites cannot be the same in container movement')
          return false
        }
        if (this.props.movement?.typeId === PACK_ORDER_TYPE_ID && !isEqual(this.state.initialStorageId, get(this.state.models.outload, 'storageId.value'))
            && get(this.state, 'selectedSite.type') === 'container') {
          check = false;
          alertifyjs.confirm(
            'Warning',
            `<div>
          <p>The storage selected for outload is a container. This action will remove stock from the container and add it to the current container</p>
          <p>Please confirm if this is the intended result</p>
        </div>`,
            () => {
              check = true;
              this.submitData(check);
            },
            this.unlockSubmit
          ).set('reverseButtons', true).set('labels', {ok:'Confirm'});
        }
        const submitCallback = () => {
          if (check) {
            if(!this.getIsFormValid()) {
              this.unlockSubmit()
              return false
            } else {
              let pickupId = get(find(this.state.sites, {id: get(this.state.models.outload, 'storageId.value')}), 'entity') != "farm_field";
              if (pickupId)
                getStoredCommodityDetails(this.state.models.outload.storageId.value, (data) => {
                  let available_tonnage = this.props.isCreate ? get(find(data, ['id', get(this.state.models.outload, 'commodityId.value')]), 'tonnage') : get(find(data, ['id', get(this.state.models.outload, 'commodityId.value')]), 'tonnage') + this.getInitialEstimatedNetWeight();
                  if ((!some(data, ['id', this.state.models.outload.commodityId.value]) || some(data, ['id', this.state.models.outload.commodityId.value]) && available_tonnage < get(this.state, 'models.outload.estimatedNetWeight.value', 0)) && !isEmpty(data)){
                    alertifyjs.confirm(
                      'Warning',
                      `<div className="">
                  <p>Please verify the storage information before outloading:</p>
                  <ul style="list-style: none;"><li>Storage Name: ${get(this.state, 'selectedSite.name')}</li>
                  <li>
                  <table>
                    <tr>
                      <th>Commodity</th>
                      <th>Grade</th>
                      <th>Season</th>
                      <th>Available ${getCountryLabel('tonnage')} (${this.priceUnit()})</th>
                      <th>Space Remaining</th>
                    </tr>
                    ${getLoadWarningTableData(data, available_tonnage)}
                  </table>
                  </li>
                  </ul>
                </div>`,
                      () => {
                        this.submitData(check);
                      },
                      this.unlockSubmit
                    );
                  }
                  else this.submitData(check);
                });
              else this.submitData(check);
            }
          }
        }
        this.setAllFieldsErrors(submitCallback)
      }
    })
  }

  async submitData(check) {
    if (!this.getIsFormValid()) {
      this.unlockSubmit();
      return false;
    }
    if (isEmpty(this.state.stockOwner.errors) && this.getIsFormValid() && check) {
      const maxAllowedTonnage = this.getMaxAllowedTonnage()
      if (get(this.props, 'movement') && maxAllowedTonnage && maxAllowedTonnage < parseFloat(get(this.state.models, 'outload.estimatedNetWeight.value', 0)))
        this.setState({showAmendTonnagePopup: true});
      else
        this.confirmSubmit(this.getSubmitData());
    }
    else
      this.unlockSubmit()
  }

  handleFieldChange(event) {
    this.setFieldValue(`models.${event.target.id}`, event.target.value);
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      this.handleFieldChange(event);
    }
  }

  handleContainerTareChange(event) {
    let value = event.target.value;
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue('models.outload.containerTare', value, true, state => {
      const newState = {...state};
      newState.models.outload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState);
    })
  }

  handleTareWeightChange(event) {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue(`models.${event.target.id}`, value, true, state => {
      const newState = { ...state };
      this.changeFreightPickupTareWeightGrossWeightValidators(newState);
      newState.models.outload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState, () => {
        this.checkNetWeightAgainstCurrentTonnage();
        if (get(this.state, 'models.outload.grossWeight.value')) {
          this.setFieldErrors('models.outload.grossWeight');
        }
      });
    });
  }

  handleQuantityFieldChange(event) {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')

    this.setFieldValue(`models.${event.target.id}`, value, true, state => {
      const newState = { ...state };
      this.setState(newState, () => {
        if (get(this.state, 'models.outload.quantity.value')) {
          this.setFieldErrors('models.outload.quantity');
        }
      });
    });
  }

  handleGrossWeightChange(event) {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue(`models.${event.target.id}`, value, true, state => {
      const newState = { ...state };
      this.changeFreightPickupTareWeightGrossWeightValidators(newState);
      newState.models.outload.estimatedNetWeight.value = this.getNetWeight(newState);
      this.setState(newState, () => {
        this.checkNetWeightAgainstCurrentTonnage();
        if (get(this.state, 'models.outload.tareWeight.value')) {
          this.setFieldErrors('models.outload.tareWeight');
        }
      });
    });
  }

  getNetWeight = (newState) => {
    let estimatedNetWeight = parseFloat(newState.models.outload.grossWeight.value) - parseFloat(newState.models.outload.tareWeight.value);
    if (this.isContainerMovement()) {
      estimatedNetWeight = parseFloat(newState.models.outload.grossWeight.value) - parseFloat(newState.models.outload.containerTare.value || 0) - parseFloat(newState.models.outload.tareWeight.value || 0);
    }
    if (isNaN(estimatedNetWeight))
      estimatedNetWeight = parseFloat(newState.models.outload.estimatedNetWeight.value);
    return isNaN(estimatedNetWeight) ? 0.0 : parseFloat(estimatedNetWeight).toFixed(2);
  };

  checkAnyWeightExists = site =>
    some([
      get(this.state, `models.${site}.tareWeight.value`),
      get(this.state, `models.${site}.grossWeight.value`),
      get(this.state, `models.${site}.estimatedNetWeight.value`),
    ]);

  validateWeightFields = site =>
    parseFloat(get(this.state, `models.${site}.estimatedNetWeight.value`)).toFixed(2) ===
    (parseFloat(get(this.state, `models.${site}.grossWeight.value`)) - parseFloat(get(this.state, `models.${site}.tareWeight.value`))).toFixed(2);

  handleNetWeightChange = (event) => {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue(`models.${event.target.id}`, value, true, (newState) => {
      if (parseFloat(newState.models.outload.estimatedNetWeight.value) > 0 && !this.validateWeightFields('outload')) {
        newState.models.outload.tareWeight.value = undefined;
        newState.models.outload.grossWeight.value = undefined;
        this.setState(newState);
      }
    });
  };

  setSellerAvailableTonnage() {
    const commodityId = get(this.state, 'models.outload.commodityId.value');
    const seasonId = get(this.state, 'models.outload.season.value');
    const gradeId = get(this.state, 'models.outload.gradeId.value');
    const handlerId = get(this.state, 'models.outload.consignor.handlerId.value')
    const ngrId = get(this.state, 'models.outload.ngrId.value')
    if (!this.state.isLoadingAvailableTonnage && commodityId &&  gradeId && handlerId && seasonId && ngrId && get(this.state.selectedSite, 'entity') != 'farm_field') {
      this.setState({isLoadingAvailableTonnage: true}, () => {
        const service = APIService.ngrs(ngrId).appendToUrl('available-stock/').appendToUrl(`?commodityId=${commodityId}&gradeId=${gradeId}&siteId=${handlerId}&season=${seasonId}`)

        const callback = res => {
          const newState = {...this.state}
          newState.availableTonnageCache[service.URL] = res
          newState.sellerAvailableTonnage = res.totalTonnage;
          let availableTonnage = res.totalTonnage;
          if (this.props.outload && this.props.movement) {
            if (commodityId === get(this.props.outload, 'commodityId') && seasonId === get(this.props.outload, 'season') && gradeId === get(this.props.outload, 'gradeId')
                && handlerId === get(this.props.outload, 'handler.id') && ngrId == get(this.props.outload, 'ngrId'))
              availableTonnage = (Number(res.totalTonnage) + Number(this.state.models.outload.estimatedNetWeight.value));
          }
          newState.sellerAvailableTonnageMessage = `Warning: Available ${getCountryLabel('tonnage')}: ${availableTonnage} ${getCountryConfig()?.truck?.unit}`
          this.setState(newState);
        }

        if(has(this.state.availableTonnageCache, service.URL))
          callback(this.state.availableTonnageCache[service.URL])
        else
          service.get(null, {'REFERER-UNIT': this.state.unit, 'REFERER-UNIT-FOR-REQUEST': true}).then(callback)
      })
    } else if(this.state.sellerAvailableTonnageMessage)
      this.setState({ sellerAvailableTonnage: undefined, sellerAvailableTonnageMessage: '' });
  }

  checkNetWeightAgainstCurrentTonnage() {
    const { outload } = this.props;
    const grossWeight = parseFloat(this.state.models.outload.grossWeight.value);
    const tareWeight = parseFloat(this.state.models.outload.tareWeight.value);
    var estimatedNetWeight = parseFloat(this.state.models.outload.estimatedNetWeight.value);
    const relevantStocks = find(get(this.state.storage, 'stocks'), stocksItem => {
      const stockNgrId = get(stocksItem, 'ngr.id');
      return !get(this.state.storage, 'isOwner')
        ? get(stocksItem, 'commodity.id') === this.state.models.outload.commodityId.value &&
        (stockNgrId ? stockNgrId === this.state.models.outload.ngrId.value : true) &&
        get(stocksItem, 'season') === this.state.models.outload.season.value &&
        get(stocksItem, 'grade.id') === this.state.models.outload.gradeId.value
        : stocksItem.commodity.id === this.state.models.outload.commodityId.value;
    });
    const currentTonnage = get(relevantStocks, 'currentTonnage', 0);
    const existingNetWeight = outload ? outload.netWeight : 0;
    if (grossWeight && tareWeight) {
      estimatedNetWeight = grossWeight - tareWeight;
    }
    const isNetWeightGreaterThanCurrentTonnage = estimatedNetWeight && this.state.storage ? estimatedNetWeight > existingNetWeight + currentTonnage : false;
    this.setState(state => ({ ...state, isNetWeightGreaterThanCurrentTonnage }), () => {
      this.setSellerAvailableTonnage();
    });
  }

  regoCallback = data => {
    if(data?.isAvailable) {
      const newState = {...this.state};
      newState.models.outload.truckId.errors = [];
      this.setState(newState)
      let config = getCountryConfig();
      let payload = {
        rego: data?.rego.toUpperCase(),
        tareWeight: get(config, 'truck.tareWeight'),
        grossWeight: get(config, 'truck.grossWeight')
      }
      let companyId = this.state.searchOption == SEARCH_BY_ALL_REGOS ? config?.systemCompanyId : this.state.models.outload.truckCompany.value?.id || config?.systemCompanyId;
      this.props.createTruck(companyId, payload, addTruck);
    }
  }

  handleSelectFieldChange(value, elementId, item) {
    if(value === 'Invalid date')
      value = ''
    let element = elementId == 'massLimitPermit' ? 'massLimitPermit' : `models.${elementId}`;
    let validateAfterSet = elementId != 'outload.truckId'
    this.setFieldValue(element, value, validateAfterSet, () => {
      if (includes(['outload.season', 'outload.ngrId', 'outload.date', 'outload.time'], elementId)) {
        this.checkNetWeightAgainstCurrentTonnage();
      } else if (elementId === 'outload.truckId' && !this.isContainerMovement()) {
        if (item && item?.inputValue) {
          if(item?.inputValue.length < 4 || item?.inputValue.length > 10) {
            const newState = {...this.state};
            newState.models.outload.truckId.errors.push(truckRegoRegex().message);
            this.setState(newState);
            return
          } else
            this.props.validateRego(elementId, item?.inputValue, this.regoCallback);
        }
        this.setUpDefaultValueWeightAndOwnerCompany(value, item);
        this.setUpCategoryFromTruck(value);
      } else if (includes(['massLimitPermit', 'outload.code'], elementId)) {
        this.setCategory();
      }
      if (elementId === 'massLimitPermit') {
        if (value === 'Notice') {
          const newState = {...this.state};
          newState.restrictedChecked = false;
          newState.ghms.value = false;
          newState.permitChecked = false;
          this.setState(newState)
        }
        else if(value && value.includes('PBS - ')) {
          const newState = {...this.state};
          newState.ghms.value = false;
          newState.accreditationNumberChecked = false;
          this.setState(newState);
        }
      }
      if (elementId === 'outload.date' && value) {
        let shouldFetchCategories = true;
        if (!isEmpty(this.state.categories)) {
          let category = this.state.categories[0];
          let date = new Date(value);
          if (date >= new Date(get(category, 'fromDate').substring(0, 10)) && date <= new Date(get(category, 'endDate').substring(0, 10)))
            shouldFetchCategories = false;
        }
        if (shouldFetchCategories) {
          this.fetchCategories();
          this.fetchPermits();
        }
      }
    });
  }

  setCategory = () => {
    const category = this.findCategory();
    if (get(category, 'id') !== this.state.models.outload.categoryId.value)
      this.setFieldValue('models.outload.categoryId', get(category, 'id'));
  };

  findCategory = () => {
    const { massLimitPermit, ghms, code } = this.state;
    if (massLimitPermit.value && code.value && !isEmpty(this.state.categories)) {
      const truck_code = code.value.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      const categories = filter(this.state.categories, category => {
        let truckCode = category.truckCode;
        if (isString(category.truckCode)) {
          truckCode = category.truckCode.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
          return truckCode === truck_code;
        }
      });
      return find(categories, {
        massLimitPermit: massLimitPermit.value, ghms: ghms.value,
      });
    }
    return undefined;
  };

  setUpCategoryFromLoadOrTruck = () => {
    let categoryId = this.state.models.outload.categoryId.value;
    if (!categoryId && this.props.isCreate)
      categoryId = get(find(this.state.trucks, { id: this.state.models.outload.truckId.value }), 'categoryId');
    if (categoryId)
      this.setCategoryAndFields(categoryId);
  };

  setUpCategoryFromTruck = truckId => {
    let _truckId = truckId || this.state.models.outload.truckId.value;
    if (!this.props.isCreate && truckId == get(this.props.outload, 'truckId'))
      return;
    if (isEmpty(this.state.categories))
      return;

    const truck = find(this.state.trucks, { id: _truckId });
    if (get(truck, 'categoryId')) {
      const newState = {...this.state};
      newState.models.outload.permitNumber.value = get(truck, 'permitNumber')
      newState.models.outload.accreditationNumber.value = get(truck, 'accreditationNumber')
      newState.models.outload.restricted.value = get(truck, 'restricted')
      newState.models.outload.declaredMassLimit.value = get(truck, 'declaredMassLimit')
      newState.models.outload.noticeNumber.value = get(truck, 'noticeNumber')
      newState.permitChecked = Boolean(get(truck, 'permitNumber'));
      newState.accreditationNumberChecked = Boolean(get(truck, 'accreditationNumber'));
      newState.restrictedChecked = Boolean(get(truck, 'restricted'));
      this.setState(newState, () => this.setCategoryAndFields(truck.categoryId));
    }
    else {
      const newState = { ...this.state };
      newState.models.outload.categoryId.value = null;
      newState.massLimitPermit.value = null;
      newState.code.value = get(truck, 'code');
      newState.ghms.value = null;
      newState.models.outload.steer_1_point_1.value = null;
      newState.models.outload.steer_point_5.value = null;
      this.setState(newState);
    }
  };

  setCategoryAndFields(categoryId) {
    const category = find(this.state.categories, { id: categoryId });
    const truck = find(this.state.trucks, { id: this.state.models.outload.truckId.value });
    if (category) {
      const newState = { ...this.state };
      newState.models.outload.categoryId.value = categoryId;
      newState.massLimitPermit.value = category.massLimitPermit;
      newState.code.value = category.truckCode;
      newState.ghms.value = category.ghms;
      if (this.props.movement && !this.props.outload) {
        newState.loadSharing = true;
        const stateCode = get(this.props.movement, 'consignorMarketZoneStateCode');
        newState.loadSharing = get(category, `loadSharing.${stateCode}`) === 0;
      }
      if(this.state.models.outload.truckId.value == get(this.props.outload, 'truckId') && !this.props.isCreate){
        //pass
      } else if(truck?.categoryId === categoryId){
        newState.models.outload.steer_1_point_1.value = get(truck, 'steer1Point1');
        newState.models.outload.steer_point_5.value = get(truck, 'steerPoint5');
      }
      this.setState(newState);
    }
  }

  setUpDefaultValueWeightAndOwnerCompany = (truckId, item) => {
    const truck = find(this.state.trucks, { id: truckId }) || item;
    if (truck) {
      const newState = { ...this.state };
      if(!this.checkAnyWeightExists('outload')) {
        if (this.state.selectedConsignor?.populateDefaultWeights) {
          newState.models.outload.tareWeight.value = get(truck, 'totalWeights.tareWeight');
          newState.models.outload.grossWeight.value = get(truck, 'totalWeights.grossWeight');
          newState.models.outload.estimatedNetWeight.value = get(truck, 'totalWeights.netWeight');
        }
      }
      newState.models.outload.estimatedNetWeight.errors = [];
      newState.models.outload.truckCompanyId.value = get(truck, 'companyId');
      newState.models.outload.truckId.value = truckId;
      if(get(truck, 'companyId')) {
        let systemCompanyId = getCountrySystemCompanyId();
        if(get(truck, 'companyId') == systemCompanyId)
          newState.models.outload.truckCompany.value = {id: systemCompanyId, name: 'Unknown', transactionParticipation: true}
        else
          newState.models.outload.truckCompany.value = {id: get(item, 'companyId'), name: get(item, 'companyName'), transactionParticipation: get(item, 'transactionParticipation')};
      }
      if(!find(this.state.trucks, { id: item.id }))
        newState.trucks.push(item);
      this.setState(newState);
    }
  };

  handleCommodityChange(value, elementId) {
    const commoditySpecs = get(find(this.props.commodities, { id: value }), 'specs', []);
    const models = set(this.state.models, 'specs', this.getModelSpecsByCommoditySpecs(commoditySpecs));
    if (!isEqual(this.state.models, models) || !isEqual(commoditySpecs, this.state.commoditySpecs)) {
      this.setState(
        state => ({
          ...state,
          models: set(state.models, 'specs', this.getModelSpecsByCommoditySpecs(commoditySpecs)),
          commoditySpecs,
        }),
        () => {
          this.syncCoilAndImpu();
          this.setFieldValue(`models.${elementId}`, value, true, () => {
            this.checkNetWeightAgainstCurrentTonnage();
            this.getModelSpecsByStorageSpecs(commoditySpecs);
            const newState = { ...this.state };
            if (this.hasQuantityBasedCommodity()) newState.models.outload.quantity.validators = [required()];
            else if(!isEmpty(this.props.commodities)) {
              newState.models.outload.quantity.validators = [];
              newState.models.outload.quantity.errors = [];
              newState.models.outload.quantity.value = undefined;
            }
          });
        },
      );
    }
  }

  setGradeSpecs = gradeId => {
    const value = gradeId || this.state.models.outload.gradeId.value
    const commodityGrades = get(
      find(this.props.commodities, { id: this.state.models.outload.commodityId.value }),
      'grades',
      []
    );
    const gradeSpecs = get(
      find(commodityGrades, { id: value }),
      'specs',
      []
    );
    const gtaCode = get(
      find(commodityGrades, { id: value }),
      'gtaCode',
      []
    );
    if (value) {
      const updatedSpecs = gtaCode ? gradeSpecs : [];
      this.setState(
        state => ({
          ...state,
          gradeSpecs: updatedSpecs
        }), 
        () => {
          forEach(gradeSpecs, (specCode) => 
            this.setFieldWarnings(`models.specs.${specCode['code']}`, specCode['code'])
          );
        }
      );
    }
  }

  getCounterPartLoad() {
    const { movement, flipLoad } = this.props;
    return flipLoad || get(movement, 'freightDelivery.load.0');
  }

  hasCOILOrIMPU(load) {
    if (load)
      return get(load, 'specs.coil') || get(load, 'specs.impu');
  }

  syncCoilAndImpu() {
    const { outload } = this.props;
    const counterPart = this.getCounterPartLoad();
    if (this.hasCOILOrIMPU(counterPart) && !this.hasCOILOrIMPU(outload) && !isEmpty(this.state.models.specs)) {
      const newState = { ...this.state };

      newState.models.specs['COIL'].value = counterPart.specs['coil'];
      newState.models.specs['IMPU'].value = counterPart.specs['impu'];
      this.setState(newState);
    }
  }

  handleVarietyChange(value) {
    if (this.state.models.outload.varietyId.value !== value) {
      this.setFieldValue(`models.outload.varietyId`, value);
    }
  }

  handleGradeChange(object) {
    if (this.state.models.outload.gradeId.value !== object.id) {
      this.setFieldValue('models.outload.gradeId', isNumber(object.id) ? object.id : undefined, true, () => {
        this.checkNetWeightAgainstCurrentTonnage();
        this.setGradeSpecs(object.id);
      }
      );
    }
    if (!isEmpty(this.state.chemicalApplications))
      this.setState({chemicalApplications: []}, () => this.onChemicalApplicationAdd())
  }

  handleSpecsChange(specCode, specValue) {
    this.setFieldValue(`models.specs.${specCode}`, specValue);
    this.setFieldWarnings(`models.specs.${specCode}`, specCode);
  }

  setFieldValue(path, value, validateAfterSet = true, callback) {
    this.setState(
      state => set(state, `${path}.value`, value),
      () => {
        if (callback) callback(this.state);
        if (validateAfterSet) this.setFieldErrors(path);
      },
    );
  }

  setFieldWarnings(path, specCode) {
    this.setState(state => set(state, `${path}.warnings`, this.getFieldWarnings(path, specCode)));
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  canRaiseMovementParentAmendRequest() {
    return get(this.props, 'movement.canRaiseParentAmendRequest');
  }

  getFieldWarnings(path, specCode) {
    const { gradeSpecs } = this.state;
    const warnings = [];
    const value = get(this.state, `${path}.value`);
    
    gradeSpecs.forEach(spec => {
      if (spec['code'] === specCode) {
        const min = get(spec, 'min', null)
        const max = get(spec, 'max', null)
        if (!(isNull(min) || isNull(max) || isNull(value))){
          if (!(value >= min && value <= max))
            warnings.push(`GTA Suggested Range: ${min} - ${max}`);
        }
      }}
    )
    return warnings;
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);

    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
      if (path == 'models.outload.estimatedNetWeight' && this.props.movement) {
        const maxAllowedTonnage = this.getMaxAllowedTonnage();
        if (maxAllowedTonnage && parseFloat(value) > maxAllowedTonnage)
          errors.push(`${this.canRaiseMovementParentAmendRequest() ? 'Warning: ': ''}Maximum allowed tonnage is ${maxAllowedTonnage} ${this.state.unit}`);
      }
      if (
        path == 'models.outload.storageId' &&
        this.state.models.outload.storageId.value &&
        get(this.props, 'movement.freightPickup.load')
      ){
        const loads = this.props.movement.freightPickup.load
        const isExistingStorage = some(loads, load => get(load, 'storageId') == this.state.models.outload.storageId.value)
        if(isExistingStorage)
          errors.push('Load exists for this storage. Please select another storage.')
      }
    });

    return errors;
  }

  setAllFieldsErrors(callback) {
    this.updateValidatorsBasedOnStorage(() => {
      var newState = cloneDeep(this.state);
      forEach(newState.models, (model, modelKey) => {
        forEach(model, (field, fieldKey) => {
          const path = `models.${modelKey}.${fieldKey}`;
          newState = set(newState, `${path}.errors`, this.getFieldErrors(path));
        });
      });
      newState.chemicalApplications = this.state.chemicalApplications.map(application => {
        let errors = [];
        if (!application.commodityId || !application.storageId || !application.applicationRate)
          errors.push('This field is required');
        return { ...application, errors };
      });
      this.setState(newState, () => {
        this.setStockOwnerMandatoryError();
        if (callback)
          callback()
      });
    });
  }

  getIsFormValid() {
    if (!isEmpty(this.state.models.outload.estimatedNetWeight.errors) && (!isSystemCompany() || this.state.models.outload.estimatedNetWeight.value) && !this.canRaiseMovementParentAmendRequest())
      return false;
    if (!isEmpty(this.state.chemicalApplications) && this.state.chemicalApplications.some(chemicalApplication => !isEmpty(chemicalApplication.errors) || !isEmpty(chemicalApplication.commodityErrors)))
      return false;
    return !some(this.state.models, (model, modelName) => {
      let fieldsToOmit = modelName === 'outload' && !this.props.movement ? ['freightProviderId', 'truckId', 'farmFieldId'] : [];
      if (isSystemCompany())
        fieldsToOmit.push('storageId', 'estimatedNetWeight', 'ngrId', 'consignor', 'netWeight', 'season', 'freightProviderId', 'truckId', 'farmFieldId', 'gradeId')
      if (this.isContainerMovement())
        fieldsToOmit.push('storageId');
      return some(omit(model, fieldsToOmit), field => {
        return some(field.validators, validator => {
          return validator.isInvalid(field.value);
        });
      });
    });
  }

  getSubmitData() {
    const { movement } = this.props;
    let data = {};
    const isEditing= this.props.outload

    const avoidableFields = ['storageId', 'netWeight'];
    forEach(this.state.models, (model, modelKey) => {
      forEach(model, (field, fieldKey) => {
        const path = `models.${modelKey}.${fieldKey}`;
        if (!includes(avoidableFields, fieldKey)) {
          data = set(data, `${modelKey}.${fieldKey}`, get(this.state, `${path}.value`, null));
        }
      });
    });
    if(isSystemCompany() && !data.outload.estimatedNetWeight)
      data.outload.estimatedNetWeight = null;
    const site = get(this.props.movement, 'freightPickup.consignor.sites[0].location');
    if (get(site, 'entity') === 'storage' && !isSystemCompany()) {
      data.outload.storageId = site.id;
    }
    if (this.state.selectedSite && this.state.selectedSite.entity === 'storage') {
      data.outload.storageId = this.state.selectedSite.id;
      data.outload.farmFieldId = null;
    }

    if (this.state.selectedSite && this.state.selectedSite.entity === 'farm_field') {
      data.outload.storageId = null;
      data.outload.farmFieldId = this.state.selectedSite.id;
    }

    if (this.props.outload && this.props.storageId) {
      data.outload.storageId = this.props.storageId;
    }

    if (isNaN(parseFloat(data.outload.estimatedNetWeight))) {
      data.outload.estimatedNetWeight = null;
    }

    if (isNaN(parseFloat(data.outload.tareWeight))) {
      data.outload.tareWeight = null;
    }

    if (isNaN(parseFloat(data.outload.grossWeight))) {
      data.outload.grossWeight = null;
    }

    if (this.props.movement) {
      data.outload.consignor = {
        handlerId: this.state.models.outload.consignor.handlerId.value,
      };
    }

    const utcDateTime = getDateTimeInUTC(data.outload.date, data.outload.time);
    data.outload.dateTime = utcDateTime.dateTime;
    data = omit(data, ['outload.date', 'outload.time']);
    if (movement) {
      if (this.props.outload) {
        data.outload.docketImagePath = this.state.docketImagePath;
      }
      data.outload.freightMovementId = movement.id;
      if (!isEditing)
        data.outload.identifier = this.generatedIdentifier;
    }
    data.outload.quantity = this.state.models.outload.quantity.value;
    if (data.outload.containerNumber == null) {
      delete data.outload.containerNumber;
    }
    if (this.isContainerMovement()) {
      let containerTare = parseFloat(data.outload.containerTare || 0);
      let truckTare = parseFloat(data.outload.tareWeight || 0);
      data.outload['splitWeights'] = {'container_tare': containerTare, 'truck_tare': truckTare};
      data.outload.tareWeight = truckTare + containerTare;
      if (data.outload.tareWeight === 0)
        data.outload.tareWeight = null;
    }
    data.outload['loadSharing'] = this.state.loadSharing;
    delete data.outload.containerTare;

    delete data.outload.bhcSite;
    delete data.outload.truckCompanyId;
    delete data.outload.searchOption;
    delete data.outload.truckCompany;
    if (this.state.chemicalApplications.length > 0) {
      data.outload.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
        let chemicalApplicationData = {
          commodityId: chemicalApplication.commodityId,
          storageId: chemicalApplication.storageId,
          applicationFee: chemicalApplication.applicationRate
        }
        if (get(chemicalApplication, 'chemicalLoadId'))
          chemicalApplicationData.chemicalLoadId = get(chemicalApplication, 'chemicalLoadId')
        return chemicalApplicationData;
      });
    }
    if (this.state.amendParentTonnage) {
      const parentTonnage = min([get(this.props, 'movement.maxAllowedTonnageOnOrder'), get(this.props, 'movement.parentTotalTonnageWithTolerance')]);
      data.outload.amendParentToTonnage = (parentTonnage + parseFloat(get(this.state.models, 'outload.estimatedNetWeight.value', 0)) - this.getMaxAllowedTonnage());
    }
    return {
      ...data.outload,
      specs: data.specs,
      optionType: get(this.props, 'optionType'),
      feature: 'Outload Form',
    };
  }

  async getModelSpecsByCommoditySpecs(commoditySpecs) {
    const modelSpecs = {};
    if (!isEmpty(commoditySpecs)) {
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        const validators = [valueBetween(spec.min, spec.max, true)];
        modelSpecs[spec.code] = {
          ...FIELD,
          value: get(this.props.outload, `specs.${spec.code.toLowerCase()}`, null),
          validators,
        };
      });
    }
    return modelSpecs;
  }

  async getModelSpecsByStorageSpecs(commoditySpecs) {
    const modelSpecs = {};
    let response = {};
    let commodityId = this.state.models.outload.commodityId.value;
    let isCommodityCanola = false;
    if (commodityId === COMMODITIES.CANOLA) {
      isCommodityCanola = true;
    }
    if (isEmpty(commoditySpecs))
      commoditySpecs = get(find(this.props.commodities, { id: commodityId }), 'specs', []);
    if (!isEmpty(commoditySpecs)) {
      let pickupSite = this.state.selectedConsignor
      if (this.props.isCreate &&
        this.isSelectedSiteNotFarmField() &&
        !get(this.state.selectedSite, 'isGate') &&
        get(this.state.models.outload, 'storageId.value', '') &&
        get(this.state.models.outload, 'commodityId.value', '') &&
        get(pickupSite, 'stocksManagement')) {
        response = await APIService.storages(this.state.models.outload.storageId.value).appendToUrl(`estimate_specs_grade?commodity_id=${this.state.models.outload.commodityId.value}`).get();
      }
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let specValue = get(response, `specs.${spec.code.toLowerCase()}`, false) ? parseFloat(get(response, `specs.${spec.code.toLowerCase()}`, 0)).toFixed(2) : null;
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (isCommodityCanola && includes(['COIL', 'IMPU'], spec.code)) {
          validators.push(required());
        }
        modelSpecs[spec.code] = {
          ...FIELD,
          value: get(this.props.outload, `specs.${spec.code.toLowerCase()}`, specValue),
          validators,
        };
      });
    }
    this.setState(
      state => ({
        ...state,
        models: set(state.models, 'specs', modelSpecs),
        commoditySpecs,
      })
    );
  }

  async getModelSpecsFromInloadSpecs(commoditySpecs, specValues) {
    if (specValues) {
      const modelSpecs = {};
      if (isEmpty(commoditySpecs))
        commoditySpecs = get(find(this.props.commodities, { id: this.state.models.outload.commodityId.value }), 'specs', []);
      if (!isEmpty(commoditySpecs)) {
        forEach(orderBy(commoditySpecs, 'order'), (spec) => {
          let specValue = get(specValues, `${spec.code.toLowerCase()}`, false) ? parseFloat(get(specValues, `${spec.code.toLowerCase()}`, 0)).toFixed(2) : null;
          const validators = [valueBetween(spec.min, spec.max, true)];
          modelSpecs[spec.code] = {
            ...FIELD,
            value: specValue,
            validators,
          };
        });
      }
      this.setState(
        state => ({
          ...state,
          models: set(state.models, 'specs', modelSpecs),
          commoditySpecs,
        })
      );
    }
  }


  async getTrucks(companyId, callback) {
    const providerId = companyId || get(this.props, 'outload.freightProviderId') || get(this.props, 'movement.freightDelivery.load.0.freightProviderId') || get(this.props, 'movement.subFreightProviderId') || get(this.props, 'movement.providerId');
    if (this.props.movement && providerId) {
      let url = APIService.companies(providerId).trucks()
      let truckId = get(this.props, 'outload.truckId') || get(this.props, 'movement.plannedTruckId');
      if (truckId)
        url.appendToUrl(`?include_truck_id=${truckId}`)
      await url.get(this.props.token)
      .then(trucks => {
          this.setState({ trucks: trucks });
          if (callback) callback();
        });
    }
  }

  getNgrs() {
    const { companyId, companyIds, farmId, farmIds, ngrId, movement, token } = this.props;
    let _companyIds = companyIds || (companyId ? [companyId] : []);
    let _farmIds = farmIds || (farmId ? [farmId] : []);
    if (isEmpty(_farmIds) && this.farmId)
      _farmIds = [this.farmId];
    if (this.props.outload) {
      let stockOwner = get(this.props.outload, 'ngr.companyId')
      _companyIds = stockOwner ? [stockOwner] : [];
      _farmIds = []
    }
    else {
    if (movement && !get(this.props, 'movement.commodityContract')) {
      _companyIds = uniq(compact([
        ..._companyIds,
        get(movement, 'customer.companyId'),
        get(movement, 'commodityContract.seller.companyId'),
        get(movement, 'commodityContract.buyer.companyId')
      ]));
      _farmIds = uniq(compact([
        ..._farmIds,
        get(movement, 'freightPickup.consignor.handlerId'),
        get(movement, 'freightDelivery.consignee.handlerId')
      ]));
    }
    else if (get(this.props, 'movement.commodityContract')) {
      _companyIds = [get(this.props, 'movement.commodityContract.seller.companyId')];
      _farmIds = [];
    }
  }
    const companyQueryString = map(_companyIds, id => { return `company_ids=${id}`; }).join('&');
    const farmQueryString = map(_farmIds, id => { return `farm_ids=${id}`; }).join('&');

    if ((companyQueryString || farmQueryString) && !get(this.state, 'queryParams.ngrId')) {
      const apiPath = APIService.ngrs().appendToUrl(`?${companyQueryString}&${farmQueryString}`);
      apiPath.get(token).then(ngrs => {
        this.setState(
          { ngrs: ngrs },
          () => {
            if(!this.props.outload) {
            if (movement && ngrId) {
              const newState = { ...this.state };
              newState.models.outload.ngrId.value = ngrId;
              this.setState(newState);
            }
            else if (ngrs.length == 1) {
              const newState = { ...this.state };
              newState.models.outload.ngrId.value = ngrs[0].id;
              this.setState(newState);
            }
          }
          },
        );
      });
    } else this.getNgr();
  }

  getNgr() {
    const { outload, token } = this.props;
    const ngrId = get(this.state, 'queryParams.ngrId');
    if (ngrId && !outload) {
      APIService.ngrs(ngrId).get(token).then(ngr => {
        const newState = {...this.state}
        if(ngr) {
          newState.models.outload.ngrId.value = ngr.id
        }
        newState.selectedNgr = ngr
        this.setState(newState);
      });
    }
  }

  isNGRFieldDisabled() {
    return Boolean(
      get(this.props, 'movement.commodityContract.seller.ngrId')
    ) && false;
  }

  getSelectedNGRForForm() {
    const ngrFromOutload = get(this.props, 'outload.ngr');
    return (ngrFromOutload && isObject(ngrFromOutload) ? ngrFromOutload : null) ||
      get(this.state, 'selectedNgr') ||
      get(this.props, 'movement.commodityContract.seller.ngr') ||
      find(this.state.ngrs, { id: get(this.props, 'outload.ngrId') });
  }

  getNGRNumber() {
    const ngrFromOutload = get(this.props, 'outload.ngr');
    return get(this.getSelectedNGRForForm(), 'ngrNumber') ||
      (isString(ngrFromOutload) ? ngrFromOutload : undefined);
  }

  getNGRId() {
    return get(this.getSelectedNGRForForm(), 'id') || get(this.props, 'outload.ngrId');
  }

  getStorage() {
    if (this.props.storageId) {
      APIService.storages(this.props.storageId)
        .appendToUrl('?no_stocks&amend_archived')
        .get(this.props.token)
        .then(storage => {
          this.setState(
            state => ({ ...state, storage }),
            () => this.updateValidatorsBasedOnStorage(),
          );
        });
    } else if (this.props.movement) {
      const site = get(this.props.outload, 'storage') || get(this.props.movement, 'freightPickup.consignor.sites[0].location');
      if (site && site.entity === 'storage') {
        this.setState(
          state => ({ ...state, storage: site }),
          () => this.updateValidatorsBasedOnStorage(),
        );
      }
    }
  }

  handleConsignorChange = (item, id) => {
    const newState = { ...this.state };
    const value = item?.id
    newState.models.outload.consignor.handlerId.value = value;
    newState.models.outload.consignor.handlerId.errors = this.isRestrictedToEditForSite(item) ? [LOAD_CREATE_OR_EDIT_ERROR_MESSAGE.replace('$action', 'created')] : [];
    if (item) {
      newState.selectedConsignor = item;
      newState.models.outload.storageId.validators = [selected(), required()];
    } else {
      newState.selectedConsignor = undefined;
      newState.sites = [];
      newState.models.outload.storageId.validators = [];
    }
    this.setState(newState, () => {
      if(this.state.selectedConsignor?.id)
        this.getConsignorSites(this.state.selectedConsignor.id);
      this.setFieldErrors(id, value);
      this.setFieldErrors('models.outload.storageId');
      this.setVarietyMandatory();
      this.setSellerAvailableTonnage();
      this.setShowAdditionalMassLimitFields();
    });
  };

  setShowAdditionalMassLimitFields() {
    if (this.state.selectedConsignor) {
      const companyId = this.state.selectedConsignor.companyId;
      if (includes(SYSTEM_COMPANY_IDS, companyId))
        this.setState({showAdditionalMassLimitFields: false})
      else if(companyId) {
        APIService.companies(companyId)
          .get()
          .then(response => this.setState({showAdditionalMassLimitFields: get(response, 'additionalMassLimitCodes')}))
      }
    }
  }

  getConsignorSites = async (handlerId) => {
    let sites = [];
    let queryString = 'storages/home/?no_stocks&no_aggregations&no_relations';
    if (get(this.props, 'outload.storageId') || this.storageId)
      queryString = queryString + `&storageId=${get(this.props, 'outload.storageId', this.storageId)}`;
    if (this.props.movement)
      queryString = queryString + '&with_containers=true';
    let homeStorages = await APIService
      .farms(handlerId)
      .appendToUrl(queryString)
      .get(this.props.token);

    homeStorages = map(homeStorages, storage => {
      return merge(storage, {
        id: storage.id,
        entity: 'storage',
        storageType: 'home',
      });
    });

    if (this.props.movement) {
      let farmFields = await APIService
        .farms(handlerId)
        .farm_fields()
        .get(this.props.token);

      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farm_field' });
      });

      sites = farmFields.concat(homeStorages);
    }
    else
      sites = homeStorages;
    if (this.props.movement && get(this.props.outload, 'farmFieldId') && !get(this.props.outload, 'farmField.isActive')) {
      let farmField = find(sites, {id: get(this.props.outload, 'farmFieldId'), entity: 'farm_field'})
      if (!farmField) {
        farmField = get(this.props.outload, 'farmField');
        if(farmField) {
          farmField['entity'] = 'farm_field';
          sites.push(farmField);
        }
      }
    }
    let selectedStorage = find(sites, { id: get(this.props.outload, 'siteId') }) || this.state.selectedSite;
    if(get(this.props.outload, 'farmFieldId'))
      selectedStorage = find(sites, {id: this.props.outload.farmFieldId, entity: 'farm_field'});
    if (this.props.storageId && !selectedStorage)
      selectedStorage = find(sites, { id: this.props.storageId });
    if (this.storageId && !selectedStorage)
      selectedStorage = find(sites, { id: this.storageId });
    if (!selectedStorage) {
      let storageId = get(this.state, 'models.outload.storageId.value');
      if (storageId) {
        selectedStorage = find(sites, { id: storageId });
      }
    }

    this.setState({
      sites: sites, selectedSite: selectedStorage,
      models: {
        ...this.state.models,
        outload: {
          ...this.state.models.outload,
          storageId: {
            ...this.state.models.outload.storageId,
            value: get(selectedStorage, 'id')
          }
        }
      }
    });
  };

  async getSites(isMounting = false) {
    let consignor = null;
    let storageId = this.props.outload?.storageId || this.storageId;
    let farmFieldId = this.props.outload?.farmFieldId;
    let handlerId = this.props.outload?.farmId
    let storage = this.props.outload?.storage
    if (this.props.movement) {
      consignor = this.props.movement?.freightPickup?.consignor
      handlerId = handlerId || consignor?.handlerId
    }
    let sites = [];
    if (handlerId) {
      let queryString = 'storages/home/?no_stocks';
      if (storageId)
        queryString = queryString + `&storageId=${storageId}`;
      if (this.props.movement)
        queryString = queryString + '&with_containers=true';
      let homeStorages = await APIService
        .farms(handlerId)
        .appendToUrl(queryString)
        .get(this.props.token);
      let farmFields = await APIService
        .farms(handlerId)
        .farm_fields()
        .get(this.props.token);
      homeStorages = map(homeStorages, storage => {
        return merge(storage, {
          id: storage.id,
          entity: 'storage',
          storageType: 'home',
        });
      });

      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farm_field' });
      });
      sites = farmFields.concat(homeStorages);
    }
    if (!isEqual(this.state.sites, sites)) {
      this.setState({ sites: sites }, () => {
        const newState = { ...this.state };
        var site = null;
        if (storageId) {
          site = find(this.state.sites, {
            id: storageId,
            entity: storage?.entity || 'storage',
            storageType: storage?.storageType || 'home',
          });
          newState.models.outload.storageId.value = this.props.outload.bhcSite ? this.props.outload.bhcSite.id : storageId;
        } else if (farmFieldId) {
          newState.models.outload.storageId.value = farmFieldId;
          newState.models.outload.farmFieldId.value = farmFieldId;
          site = find(this.state.sites, {
            id: farmFieldId,
            entity: 'farm_field',
          });
        } else {
          if(this.props.optionType !== OPTION_TYPE_WEB_SPLIT_LOADS || !isMounting)
            newState.models.outload.storageId.value = storageId || storage?.id || get(consignor, 'sites[0].locationId');
          site = find(this.state.sites, {
            id: newState.models.outload.storageId.value,
            entity: get(consignor, 'sites[0].locationContentType'),
          });
        }
        newState.initialStorageId = newState.models.outload.storageId.value;
        newState.selectedSite = site;
        newState.storage = site;

        this.setState(newState, this.updateValidatorsBasedOnStorage);
      });
    }
  }

  updateValidatorsBasedOnStorage(callback) {
    const newState = { ...this.state };
    this.changeFreightPickupTareWeightGrossWeightValidators(newState);
    this.changeFreightPickupGradeValidators(newState);
    this.updateDependentValidatorsByStorage(newState);
    this.setState(newState, () => {
      if (callback) {
        callback();
      }
    });
  }

  isSelectedSiteNotFarmField = () => {
    return get(this.state, 'selectedSite.entity') !== 'farm_field';
  };

  handleSiteChange(value, id, item) {
    const newState = { ...this.state };
    newState.models.outload.ngrId.validators = queryString.parse(this.props.location.search).ngrId ? [] : [required()];
    if (item) {
      if (item.entity === 'farm_field') {
        newState.models.outload.ngrId.validators = [];
        newState.models.outload.ngrId.errors = [];
      }
      newState.selectedSite = item;
      newState.storage = item;
      newState.models.outload.storageId.value = item.id;
    } else {
      newState.selectedSite = undefined;
      newState.storage = undefined;
      newState.models.outload.storageId.value = value;
    }
    this.setState(newState, () => {
      this.getTonnageFieldsStates();
      this.updateValidatorsBasedOnStorage();
      this.getModelSpecsByStorageSpecs({});
      this.setFieldErrors('models.outload.storageId');
      this.setSellerAvailableTonnage();
    });
  }


  updateDependentValidatorsByStorage(newState) {
    if (this.isSelectedSiteNotFarmField()) {
      newState.models.outload.season.validators = [required()];
      newState.models.outload.gradeId.validators = [required()];
    } else {
      newState.models.outload.season.validators = [];
      newState.models.outload.gradeId.validators = [];
      newState.models.outload.season.errors = [];
      newState.models.outload.gradeId.errors = [];
    }
  }

  handleBlur = event => {
    this.setFieldErrors(event.target.id, event.target.value);
  };

  handleEstimatedNetWeightFieldChange = event => {
    let value = event.target.value
    if(isString(value))
      value = value.replaceAll(',', '').replaceAll(' ', '')
    this.setFieldValue(`models.${event.target.id}`, value, true, () => {
      this.checkNetWeightAgainstCurrentTonnage();
    });
  };

  onDocketImageRemove = fileSrc => {
    if (fileSrc) {
      const newState = { ...this.state };
      newState.models.outload.docketImage.value = newState.models.outload.docketImage.value || [];
      var index = findIndex(newState.models.outload.docketImage.value, file => file.base64 === fileSrc);
      if (index === -1) {
        index = findIndex(newState.docketImageUrl, file => file === fileSrc);
      }
      newState.models.outload.docketImage.value.splice(index, 1);
      newState.docketImagePath.splice(index, 1);
      newState.docketImageUrl.splice(index, 1);
      newState.docketImageFileCount = get(newState, 'docketImageUrl') ? get(newState, 'docketImageUrl').length + newState.models.outload.docketImage.value.length : newState.models.outload.docketImage.value.length;
      this.setState(newState);
    }
  };

  onDocketImageUpload = fileState => {
    const newState = { ...this.state };
    if (isEmpty(fileState.errors)) {
      var docketImages = newState.models.outload.docketImage.value || [];
      docketImages.push(fileState.file);
      newState.models.outload.docketImage.value = docketImages;
      newState.docketImageFileCount = get(newState, 'docketImageUrl') ? get(newState, 'docketImageUrl').length + docketImages.length : docketImages.length;
    } else {
      newState.models.outload.docketImage.errors = fileState.errors;
    }
    this.setState(newState);
  };

  isNetWeightMoreThanAllowed() {
    if (!get(this.props, 'movement.commodityContract') || !get(this.props, 'movement.order')) {
      return false;
    }
    const weightId = get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID);
    let maxTonnageAllowed = this.getMaxAllowedTonnage('netWeight')
    if (weightId === WEIGHT_ORIGIN_ID) {
      return this.getTonnage() > maxTonnageAllowed;
    } else {
      return false;
    }
  }

  isQuantityMoreThanAllowed() {
    if (!get(this.props, 'movement.commodityContract') || !get(this.props, 'movement.order')) {
      return false;
    }
    const weightId = get(this.props, 'movement.commodityContract.weightId', DEFAULT_WEIGHT_ID);
    let maxQuantityAllowed = this.getMaxAllowedTonnage('quantity')
    if (weightId === WEIGHT_ORIGIN_ID) {
      return this.getQuantity() > maxQuantityAllowed;
    } else {
      return false;
    }
  }

  getTonnage() {
    const grossWeight = this.state.models.outload.grossWeight.value;
    const tareWeight = this.state.models.outload.tareWeight.value;
    const estimatedNetWeight = this.state.models.outload.estimatedNetWeight.value;
    const tonnage = grossWeight && tareWeight ?
      parseFloat(grossWeight) - parseFloat(tareWeight) :
      estimatedNetWeight ? parseFloat(estimatedNetWeight) : null;

    return tonnage ? parseFloat(parseFloat(tonnage).toFixed(2)) : tonnage;
  }

  getQuantity() {
    return this.state.models.outload.quantity.value;
  }

  getSelectedCommodity = commodityId => {
    const id = commodityId || this.state.models.outload.commodityId.value
    return id ? find(this.props.commodities, {id: id}) : null
  }

  hasQuantityBasedCommodity = commodityId => Boolean(this.getSelectedCommodity(commodityId || this.state.models.outload.commodityId.value)?.isQuantityBased)

  isMeterCubeCommodityCommodity() {
    return get(this.getSelectedCommodity(), 'isStrictQuantityBased')
  }

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }

  handleSteerChange = event => {
    const newState = { ...this.state };
    const checked = event.target.checked;
    newState.models.outload[event.target.id].value = checked;

    if (checked) {
      newState.permitChecked = false;
      if (event.target.id === 'steer_point_5')
        newState.models.outload.steer_1_point_1.value = false;
      else
        newState.models.outload.steer_point_5.value = false;
    }
    this.setState(newState);
  };

  shouldEnableSteerPoint5 = () => get(find(this.state.categories, { id: this.state.models.outload.categoryId.value }), 'steerPoint5');

  shouldEnableSteer1Point1 = () => get(find(this.state.categories, { id: this.state.models.outload.categoryId.value }), 'steer1Point1');


  handleCheckboxChange = event => {
    this.setFieldValue(event.target.id, event.target.checked, false, () => {
      const newState = {...this.state}
      newState.permitChecked = false;
      newState.accreditationNumberChecked = false;
      newState.restrictedChecked = false;
      this.setState(newState, () => this.setCategory())
    });
  };

  voidLoad() {
    const { outload } = this.props;
    if(this.isRestrictedToEditForSite(this.state.selectedConsignor)){
      alertifyjs.error("Inturns into and Outturns from this site can only be created/edited by the company employees. Please contact the site manager for creating this load.", 5);
    } else {
    alertifyjs.confirm(
      'Warning',
      'Load will be marked Void. Do you want to proceed?',
      () => {
        APIService
          .loads(outload.id)
          .appendToUrl('void/')
          .put().then(() => {
            alertifyjs.success('Load Deleted');
            window.location.reload();
            this.props.closeDrawer;
          });
      },
      () => { },
    );
    }
  }

  handleTruckCodeChange = (value, id) => {
    const newState = { ...this.state };
    set(newState, id + '.value', value);
    this.setState(newState, () => this.setCategory());
  };

  handleCopyLoadDetails(copyToType) {
    const loadFields = ['truckId', 'truckCompanyId', 'truckCompany', 'searchOption','steer_point_5', 'steer_1_point_1', 'season', 'tareWeight', 'grossWeight', 'varietyId'];
    const newState = { ...this.state };
    newState.isCopyFromInload = true;
    let counterPartLoad = this.getCounterPartLoad();
    this.getTrucks(get(counterPartLoad, 'truck.companyId'))
    let localDate = get(counterPartLoad, 'dateTime') ? getDateTimeFromUTC(counterPartLoad.dateTime).date : '';

    set(newState.models, `${copyToType}.date.value`, localDate);
    if (this.props.movement.typeId == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
      set(newState.models, `${copyToType}.ngrId.value`, get(counterPartLoad, 'ngrId'));
      set(newState, 'stockOwner.value', get(counterPartLoad, 'ngr.companyId'));
    }
    forEach(loadFields, field => {
      if (field == 'searchOption')
        newState.searchOption = get(this.props, 'movement.providerId') == get(counterPartLoad, 'truck.companyId') ? SEARCH_BY_FREIGHT_PROVIDER_REGOS : SEARCH_BY_TRUCK_OWNER_REGOS;
      else if(field == 'truckCompanyId')
        set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, 'truck.companyId'));
      else {
        if(field == 'truckCompany' && get(counterPartLoad, 'truck.companyId')) {
          let systemCompanyId = getCountrySystemCompanyId();
          if(get(counterPartLoad, 'truck.companyId') == systemCompanyId)
            newState.models.outload.truckCompany.value = {id: systemCompanyId, name: 'Unknown'}
          else
            APIService.companies(get(counterPartLoad, 'truck.companyId')).get().then(item => newState.models.outload.truckCompany.value = item)
        } else if (includes(['tareWeight', 'grossWeight', 'estimatedNetWeight'], field))
          set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, `_entered${upperFirst(field)}`) || get(counterPartLoad, field));
 else {
          let setField = field == 'steer_point_5' ? 'steerPoint5' : field;
          setField = field == 'steer_1_point_1' ? 'steer1Point1' : setField;
          set(newState.models, `${copyToType}.${field}.value`, get(counterPartLoad, `${setField}`));
        }
      }
    });

    let allLoads = get(this.props, 'movement.freightDelivery.load');
    if (allLoads) {
      if (allLoads.length > 1) {
        newState.models.outload.tareWeight.value = undefined;
        newState.models.outload.grossWeight.value = undefined;
      }
      let totalNetWeight = allLoads.reduce((acc, curr) => acc + (curr._enteredEstimatedNetWeight || curr.estimatedNetWeight) || 0, 0);
      newState.models.outload.estimatedNetWeight.value = totalNetWeight;
    }
    else {
      if (has(counterPartLoad, 'netWeight')) newState.models.outload.estimatedNetWeight.value = get(counterPartLoad, 'netWeight');
    }
    if (!newState.models.outload.estimatedNetWeight.value)
      newState.models.outload.estimatedNetWeight.value = this.getNetWeight(newState);
    newState.initialEstimatedNetWeight = cloneDeep(newState.models.outload.estimatedNetWeight.value);
    this.setState(newState, () => {
      this.setCategoryAndFields(get(counterPartLoad, 'categoryId'));
      this.getModelSpecsFromInloadSpecs({}, get(counterPartLoad, 'specs'));
      setTimeout(() => this.handleGradeChange({id: get(counterPartLoad, 'gradeId')}), 1000);
    });
  }

  priceUnit() {
    return this.props.movement ? this.getTruckUnit() : getCountryDisplayUnit();
  }

  applicationCommodityUnit(commodity, commodityId) {
    if (!commodity && commodityId && !isEmpty(this.props.commodities))
      commodity = find(this.props.commodities, {id: commodityId});
    return get(UNIT_ABBREVIATIONS, commodity?.unit);
  }

  getTruckUnit() {
    return get(find(this.state.trucks, {id: this.state.models.outload.truckId.value}), 'unit') || this.state.unit;
  }

  handleTruckSearchOptionChange = value => {
    const newState = { ...this.state };
    newState.searchOption = value;
    if (value == SEARCH_BY_FREIGHT_PROVIDER_REGOS) {
      newState.models.outload.truckCompanyId.value = get(this.props, 'movement.providerId');
      newState.models.outload.truckId.value = get(this.props, 'outload.truckId') || get(this.props, 'movement.plannedTruckId');
      newState.models.outload.truckCompany.value = get(this.props, 'movement.provider');
    }
    else {
      let truck = get(this.props.movement, 'plannedTruck');
      if (get(truck, 'companyId') === get(this.props.movement, 'providerId') || newState.models.outload.truckCompanyId.value !== get(truck, 'companyId')) {
        newState.models.outload.truckCompanyId.value = null;
        newState.models.outload.truckCompany.value = null;
        newState.models.outload.truckId.value = null;
      }
    }
    this.setState(newState, () => {
      if (this.state.searchOption === SEARCH_BY_FREIGHT_PROVIDER_REGOS)
        this.getTrucks(get(this.props, 'movement.providerId'));
    });
    this.setState(newState);
  }

  setSelectedProviderTruck = truck => {
    const newState = { ...this.state };
    newState.selectedTrucks.push(truck);
    if (get(truck, 'id')) {
      let category = find(this.state.categories, { id: get(truck, 'categoryId') });
      newState.code.value = get(category, 'truckCode');
      newState.models.outload.categoryId.value = get(truck, 'categoryId');
      newState.massLimitPermit.value = get(category, 'massLimitPermit');
      newState.ghms.value = get(category, 'ghms');
      newState.models.outload.steer_1_point_1.value = get(truck, 'steer1Point1');
      newState.models.outload.steer_point_5.value = get(truck, 'steerPoint5');
      newState.models.outload.truckCompanyId.value = get(truck, 'companyId');
      newState.models.outload.truckCompany.value = {id:get(truck, 'companyId'), name: get(truck, 'companyName')};
      newState.models.outload.truckId.value = get(truck, 'id')
    } else {
      newState.code.value = '';
      newState.models.outload.categoryId.value = '';
      newState.massLimitPermit.value = '';
      newState.ghms.value = false;
      newState.models.outload.steer_1_point_1.value = false;
      newState.models.outload.steer_point_5.value = false;
      newState.models.outload.truckCompanyId.value = null;
      newState.models.outload.truckCompany.value = null;
    }
    this.setState(newState);
  };

  handleTruckOwnerChange = (value, id) => { // eslint-disable-line no-unused-vars
    const newState = { ...this.state };
    newState.models.outload.truckCompanyId.value = value?.id;
    newState.models.outload.truckCompany.value = value;
    if(isNull(value)) {
      newState.models.outload.truckId.value= null;
      newState.trucks = [];
    }
    this.setState(newState, () => {
      if(value && this.state.searchOption != SEARCH_BY_ALL_REGOS)
        this.getTrucks(value?.id);
    });
  }

  handlePermitClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'permitChecked', isChecked);
    newState.massLimitPermit.validators = [required()];
    if (isChecked) {
      newState.accreditationNumberChecked = false;
      newState.ghms.value = false;
      newState.models.outload.steer_point_5.value = false;
      newState.models.outload.steer_1_point_1.value = false;
      newState.massLimitPermit.validators = [];
    }
    this.setState(newState);
  }

  handleAccreditationNumberClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'accreditationNumberChecked', isChecked);
    if (isChecked) {
      newState.permitChecked = false;
      newState.ghms.value = false;
    }
    this.setState(newState);
  }

  handleLoadSharingClick = event => event.target.checked ? this.setState({loadSharing: true}) : this.setState({loadSharing: false});

  handleRestrictedClick = event => {
    const isChecked = event.target.checked;
    const id = event.target.id;
    const newState = {...this.state};
    set(newState, id, isChecked);
    if (isChecked)
      newState.ghms.value = false;
    this.setState(newState);
  }

  handleTruckDetailsChange = event => {
    const newState = {...this.state}
    let id = event.target.id;
    set(newState, `${id}.value`, id === 'models.outload.restricted' ? event.target.checked : event.target.value);
    if (id !== 'models.outload.restricted' && event.target.value) {
      const parts = id.split(".")
      const errorField = `truckErrors.${parts[1]}`;
      set(newState, errorField, '')
    }
    this.setState(newState);
  }

  handleTruckDetailsSelectValueChange(value, id) {
    const newState = { ...this.state };
    set(newState, `${id}.value`, value);
    const parts = id.split(".")
    const errorField = `truckErrors.${parts[1]}`;
    set(newState, errorField, '')
    this.setState(newState);
  }

  getEntityUrl() {
    const reason = get(this.props, 'movement.reason')
    const canAmendRelatedEntity = get(this.props, 'movement.canAmendRelatedEntity');
    if (reason) {
      let url = ''
      let identifier = ''
      if (reason === 'contract') {
        identifier = get(this.props, 'movement.order.commodityContract.contractNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/contracts/${get(this.props, 'movement.order.commodityContract.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'pickup order') {
        identifier = get(this.props, 'movement.order.freightPickup.orderNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.props, 'movement.order.pickupOrderId')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'delivery order') {
        identifier = get(this.props, 'movement.order.freightDelivery.orderNumber')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.props, 'movement.order.deliveryOrderId')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      else if (reason === 'parent order') {
        identifier = get(this.props, 'movement.order.parentOrder.identifier')
        url = canAmendRelatedEntity ? <a rel="noopener noreferrer" target='_blank' href={`#/freights/orders/${get(this.props, 'movement.order.parentOrder.id')}/edit`} onClick={event => window.open(event.target.href, '_blank')}>{identifier}</a> : identifier;
      }
      return url
    }
  }

  onChemicalApplicationCommodityChange(obj, value) {
    let commodity = undefined
    if (value)
      commodity = find(this.props.commodities, {id: value});
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id) {
          let commodityErrors = [];
          if (prevState.chemicalApplications.some(_chemicalApplication => _chemicalApplication.commodityId === value && _chemicalApplication.id !== obj.id)) {
            commodityErrors.push('This application is already defined.')
          }
          return {...chemicalApplication, commodityId: value, commodity: commodity};
        }
        return {...chemicalApplication}
      })
    }));
  }

  handleApplicationRateChange(obj, value) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, applicationRate: value};
        return {...chemicalApplication}
      })
    }));
  }

  handleChemicalApplicationStorageChange(value, item, obj) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, storageId: value};
        return {...chemicalApplication}
      })
    }));
  }

  onChemicalApplicationDelete(obj) {
    const newState = {...this.state};
    let chemicalApplications = [...newState.chemicalApplications.filter(chemicalApplication => chemicalApplication.id !== obj.id)];
    newState.chemicalApplications = chemicalApplications.map((chemicalApplication, index) => ({...chemicalApplication, id: index + 1}));
    this.setState(newState)
  }

  onChemicalApplicationAdd() {
    const newState = {...this.state};
    const chemicalApplications = (isEmpty(get(this.props.movement, 'chemicalApplications')) ? get(this.props.movement, 'orderChemicalApplications') : get(this.props.movement, 'chemicalApplications')) || [];
    const obj = find(chemicalApplications, {gradeId: this.state.models.outload.gradeId.value})
    let commodityErrors = [];
    const commodityId = get(obj, 'commodityId');
    if (commodityId && this.state.chemicalApplications.some(chemicalApplication => chemicalApplication.commodityId === commodityId))
      commodityErrors = ['This application is already defined.']
    const newObj = {
      id: newState.chemicalApplications.length + 1,
      commodityIdOptions: chemicalApplications.map(chemicalApplication => chemicalApplication.commodityId),
      commodityId: commodityId,
      commodity: find(this.props.commodities, {id: get(obj, 'commodityId')}),
      applicationRate: get(obj, 'applicationFee'),
      storageId: undefined,
      errors: [],
      commodityErrors: commodityErrors
    }
    newState.chemicalApplications = [...newState.chemicalApplications, newObj];
    this.setState(newState)
  }

  getEligibleApplicationRates(commodityId) {
    let eligibleApplicationRates = [];
    if (commodityId && !isEmpty(this.state.chemicalApplicationRates)) {
      let _eligibleApplicationRates = this.state.chemicalApplicationRates.filter(chemicalApplicationRate => chemicalApplicationRate.commodityId === commodityId);
      if (!isEmpty(_eligibleApplicationRates)) {
        _eligibleApplicationRates.forEach(_eligibleApplicationRate => {
          eligibleApplicationRates.push({'name': _eligibleApplicationRate.rate, 'id': _eligibleApplicationRate.rate})
        })
      }
    }
    return eligibleApplicationRates;
  }

  render() {
    const { movement } = this.props;
    const { outload, specs } = this.state.models;
    const isPackMovement = get(movement, 'typeId') === PACK_ORDER_TYPE_ID;
    const isContainerMovement = this.isContainerMovement();
    const isShrinkageLoad = this.checkShrinkageLoad();
    const {
      selectedConsignor, trucks,
      seasonFloatingLabelText, ngrFloatingLabelText, ngrs, storage,
      selectedSite, docketNumberFloatingLabelText, commoditySpecs,
      gradeFloatingLabelText, docketImageFileCount, docketImageUrl, sites,
      stockOwner, selectedNgr, lockStockOwner, isVarietyMandatoryLabel,
      selectedStockOwner
    } = this.state;
    const estimatedNetWeightError = get(outload, 'estimatedNetWeight.errors[0]', '');
    const ngrFieldError =
      get(outload, 'ngrId.errors[0]', '') ||
      (movement && !outload.ngrId.value ? NGR_REQUIRED_FOR_STOCKS_WARNING_MESSAGE : '');

    const isSelectedSiteFFAndWeighingAtDestination = get(selectedSite, 'entity') === 'farm_field' && get(movement, 'commodityContract.weightId') !== WEIGHT_ORIGIN_ID;
    const completedMovement = includes(get(movement, 'status'), 'completed') && !isSystemCompany();
    const archivedStorage = find(sites, { 'storageId': get(this.props, 'outload.storageId', this.storageId), 'isActive': false }) || false;;
    const isInvoiced = this.state.isWarehouseInvoiced;
    const isDirectLoad = !(get(this.props.outload, 'checkpointId')) && !(get(this.props.outload, 'titleTransferNumber')) && !(get(this.props.outload, 'optionType')) && !get(this.props.outload, 'shrinkageParent');
    const isStockUpdateLoad = includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, get(this.props.outload, 'optionType'));
    const styleAdornment = (completedMovement || archivedStorage || isShrinkageLoad || isStockUpdateLoad) ? { color: 'rgb(162,162,162)', paddingRight: '32px' } : { color: 'rgb(162,162,162)' };
    const unit = this.priceUnit();
    const disableBasedOnTruckSearchOption = this.state.searchOption != SEARCH_BY_TRUCK_OWNER_REGOS;
    const isSplitLoadForm = get(this.props, 'optionType') === OPTION_TYPE_WEB_SPLIT_LOADS;
    const isDateTimeDisabled = completedMovement || archivedStorage || isInvoiced || isShrinkageLoad || isStockUpdateLoad;
    let disableLoadSharing = true;
    if (this.state.models.outload.categoryId.value && this.state.categories && this.props.movement) {
      const category = find(this.state.categories, {id: this.state.models.outload.categoryId.value});
      const stateCode = get(this.props.movement, 'consignorMarketZoneStateCode');
      disableLoadSharing = get(category, `loadSharing.${stateCode}`) === 0;
    }
    const isTruckMassLimitsVisible = isVisibleInCountry('truckMassLimits');
    const isNoticePermit = this.state.massLimitPermit.value === 'Notice';
    const parentTonnage = min([get(this.props, 'movement.maxAllowedTonnageOnOrder'), get(this.props, 'movement.parentTotalTonnageWithTolerance')]);
    const maxParentTonnage = get(this.props, 'movement.maxAllowedTonnageOnOrder') || get(this.props, 'movement.parentTotalTonnageWithTolerance');
    const updateToTonnage = (parentTonnage + parseFloat(get(this.state.models, 'outload.estimatedNetWeight.value', 0)) - this.getMaxAllowedTonnage());
    const entityName = get(this.props, 'movement.orderId') ? 'order' : 'contract';
    const isParentOrderIndependent = get(this.props, 'movement.isParentOrderIndependent');
    const isDirectContractMovement = !get(this.props, 'movement.orderId') && get(this.props, 'movement.commodityContractId');
    let showUpdateToTonnagePopup = this.state.showAmendTonnagePopup && !isParentOrderIndependent;
    if (showUpdateToTonnagePopup && isDirectContractMovement && get(this.props, 'movement.canAmendRelatedEntity'))
      showUpdateToTonnagePopup = true;
    else if (showUpdateToTonnagePopup && parseFloat(updateToTonnage) < get(this.props, 'movement.maxAllowedTonnageOnOrder'))
      showUpdateToTonnagePopup = true
    else
      showUpdateToTonnagePopup = false
    const parentEntityUrl = this.getEntityUrl();
    const amendMessage = get(this.props, 'movement.canAmendRelatedEntity') ? `Please amend ${get(this.props, 'movement.reason')} first.` : `Please contact the relevant party to amend ${get(this.props, 'movement.reason')}.`;
    const isSiteRestricted = this.props?.outload && this.isRestrictedToEditForSite(this.state.selectedConsignor)
    return (
      <div className='col-xs-12 padding-reset' style={{marginTop: '20px'}}>
        {
          isInvoiced &&
            <span style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)'}}>
              <i>(this load is already invoiced, editing is restricted.)</i>
            </span>
        }
        <form onSubmit={this.handleSubmit} noValidate>
          <div className='cardForm cardForm--drawer col-xs-12 padding-reset'>
            <div className='cardForm-content row trucks col-xs-12 padding-reset'>
              <div className='col-xs-12' style={{ display: 'inline-block' }}>
                {
                  this.getCounterPartLoad() && !completedMovement && !isInvoiced &&
                  <CommonButton
                    label='Copy from inload'
                    default={true}
                    variant='outlined'
                    style={{ height: '20px', minWidth: '61px' }}
                    onClick={() => this.handleCopyLoadDetails('outload')}
                  />
                }
              </div>
              <div className='col-xs-8 form-wrap' style={{ display: 'inline-block', marginTop: '16px' }}>
                <SiteAsyncAutocomplete
                  farmId={this.farmId || this.props.farmId}
                  variant='standard'
                  id='outload.consignor.handlerId'
                  label='Pickup Site'
                  onChange={this.handleConsignorChange}
                  selected={selectedConsignor?.id ? {...selectedConsignor, name: selectedConsignor.displayName || selectedConsignor.name} : null}
                  minLength={3}
                  errorText={outload.consignor.handlerId.errors[0]}
                  style={{ float: 'right' }}
                  disabled={ isSiteRestricted || completedMovement || archivedStorage || this.isRegradedLoad || isPackMovement || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isContainerMovement || movement?.outloadSlotId}
                  activeSitesOnly
                  includeSelectedSiteId={get(movement, 'freightPickup.consignor.handlerId')}
                />
              </div>
              {isContainerMovement ?
              <div className="col-xs-4 form-wrap" style={{ display: 'inline-block' }}>
                <CommonTextField
                  id='containerNumber'
                  label='Container Name'
                  placeholder='Container Name'
                  value={get(this.props, 'movement.containerNumber')}
                  disabled
                />
              </div> :
              <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                <CommonAutoSelect
                  items={sites}
                  id='outload.storageId'
                  label='Pickup Storage'
                  value={get(outload, 'storageId.value', '')}
                  errorText={get(outload, 'storageId.errors[0]', '')}
                  onChange={this.handleSiteChange}
                  selectedItem={selectedSite}
                  dontAutoselectSingleItem
                  disabled={archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isContainerMovement}
                  storageFieldMix
                  />
              </div>
              }
              {
                (isDirectLoad || isStockUpdateLoad || isShrinkageLoad) && !movement &&
                <div className='col-xs-4 form-wrap'>
                  {
                    (isStockUpdateLoad || isShrinkageLoad) ?
                    <CommonTextField
                      id='outload.loadIdentifier'
                      label='Identifier'
                      placeholder='Identifier'
                      disabled
                      maxLength='255'
                      value={outload.loadIdentifier.value}
                      helperText={get(outload, 'loadIdentifier.errors[0]', '')}
                    /> :
                    <TextField
                      id='outload.loadIdentifier'
                      label='Identifier'
                      placeholder='Identifier'
                      onChange={this.handleIdentifierChange}
                      value={outload.loadIdentifier.value}
                      error={!isEmpty(outload.loadIdentifier.errors)}
                      helperText={get(outload, 'loadIdentifier.errors[0]', '')}
                      inputProps={{
                        maxLength: 14,
                      }}
                      fullWidth
                      variant='standard'
                    />
                  }
                </div>
              }
              {!isSplitLoadForm &&
                <React.Fragment>
                  <div className={movement ? 'col-xs-6 form-wrap' : 'col-xs-4 form-wrap'}>
                    <CommonDatePicker
                      id='outload.date'
                      onChange={this.handleSelectFieldChange}
                      value={outload.date.value}
                      errorText={get(outload, 'date.errors[0]', '')}
                      maxDate={
                        get(movement, 'freightDelivery.inload') ?
                          new Date(movement.freightDelivery.inload.date) :
                          new Date()
                      }
                      disabled={isDateTimeDisabled}
                    />
                  </div>
                  <div className={movement ? 'col-xs-6 form-wrap' : 'col-xs-4 form-wrap'}>
                    <CommonTimePicker
                      id='outload.time'
                      onChange={this.handleSelectFieldChange}
                      value={outload.time.value}
                      errorText={get(outload, 'time.errors[0]', '')}
                      disabled={isDateTimeDisabled}
                    />
                  </div>
                </React.Fragment>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonAutoSelect
                    id='searchOptions'
                    label='Show Trucks Of'
                    items={TRUCK_SEARCH_OPTIONS}
                    dataSourceConfig={{ text: 'name', value: 'id' }}
                    value={this.state.searchOption}
                    onChange={this.handleTruckSearchOptionChange}
                    maxLength="255"
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CompanyAutocomplete
                    id='truckOwner'
                    selected={this.state.models.outload.truckCompany.value}
                    onChange={this.handleTruckOwnerChange}
                    minLength={3}
                    getRegisteredCompanies={false}
                    variant="standard"
                    disabled={disableBasedOnTruckSearchOption}
                    label='Truck Owner'
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                  (this.state.searchOption == SEARCH_BY_ALL_REGOS ?
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonSelectInput
                    search
                    allowEmptyOptions
                    allowText={false}
                    endpoint="trucks/search/"
                    queryParams={{is_active: true}}
                    options={[]}
                    optionMap={{ id: 'id', name: 'rego', companyId: 'companyId', companyName: 'companyName', totalWeights: 'totalWeights', categoryId: 'categoryId', code: 'code', steerPoint5: 'steerPoint5', steer1Point1: 'steer1Point1' }}
                    inputText={get(find(this.state.trucks, { id: outload.truckId.value }), 'rego')}
                    id='outload.truckId'
                    label={getCountryLabel('rego')}
                    getSelectedOption={this.setSelectedProviderTruck}
                    value={outload.truckId.value}
                    disabled={this.disableForm}
                    create={this.props.createTruck}
                    actionCreator={addTruck}
                    validate={this.props.validateRego}
                    errorText={get(outload, 'truckId.errors[0]', '')}
                    onChange={this.handleSelectFieldChange}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 10).replace(/[^0-9a-z]/gi, '');
                      if(e.target.value == ''){
                        const newState = {...this.state};
                        outload.truckCompanyId.value = '';
                        this.setState(newState);
                      }
                    }}
                />
                </div> :
                <div className='col-xs-4 form-wrap' style={{ display: 'inline-block' }}>
                  <CommonAutoSelect
                    id='outload.truckId'
                    label={getCountryLabel('rego')}
                    items={orderBy(trucks, 'rego')}
                    disabled={completedMovement || this.isRegradedLoad || (movement && !get(outload, 'truckId.errors[0]', '') &&this.state.searchOption == SEARCH_BY_TRUCK_OWNER_REGOS && this.state.models.outload.truckCompanyId.value == null)}
                    dataSourceConfig={{ text: 'rego', value: 'id' }}
                    value={outload.truckId.value}
                    onChange={this.handleSelectFieldChange}
                    errorText={get(outload, 'truckId.errors[0]', '')}
                    maxLength="255"
                    dontAutoselectSingleItem
                    filterOptions={(items, params) => {
                      let filtered = autocompleteFilters(items, params);
                      filtered = filtered.filter(item => !get(item, 'inputValue'))
                      if(!get(this.state.models.outload.truckCompany.value, 'transactionParticipation')) {
                        if (params.inputValue !== '' && params.inputValue.length >= 4 && params.inputValue.length <= 10 && params.inputValue.toLowerCase() !== 'fleet') {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}" as new ${getCountryLabel('rego')}`,
                          });
                        }
                        return filtered;
                      }
                      return filtered;
                    }}
                  />
                </div>)
              }
              <div className='col-xs-4 form-wrap'>
                <CommodityAutoComplete
                  id='outload.commodityId'
                  commodityId={outload.commodityId.value}
                  onChange={this.handleCommodityChange}
                  errorText={get(outload, 'commodityId.errors[0]', '')}
                  disabled={!!movement || completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              <div className='col-xs-4 form-wrap'>
                <SeasonSelect
                  id='outload.season'
                  floatingLabelText={this.isSelectedSiteNotFarmField() ? seasonFloatingLabelText : 'Season (Optional)'}
                  season={outload.season.value}
                  onChange={this.handleSelectFieldChange}
                  errorText={get(outload, 'season.errors[0]', '')}
                  includeEmptyOption={!this.isSelectedSiteNotFarmField()}
                  disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isPackMovement || isContainerMovement}
                />
              </div>
              <div className='col-xs-4 form-wrap'>
                <GradeAutoComplete
                  id='outload.gradeId'
                  floatingLabelText={this.isSelectedSiteNotFarmField() ? gradeFloatingLabelText : 'Grade (Optional)'}
                  commodityId={outload.commodityId.value}
                  season={outload.season.value}
                  selectedGradeId={outload.gradeId.value}
                  selectedVarietyId={outload.varietyId.value}
                  onChange={this.handleGradeChange}
                  errorText={get(outload, 'gradeId.errors[0]', '')}
                  disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isPackMovement || isContainerMovement}
                  specs={mapValues(specs, 'value')}
                  dependsOnSeason
                />
              </div>
              <div className='col-xs-4 form-wrap'>
                <VarietyAutoComplete
                  id='outload.varietyId'
                  commodityId={outload.commodityId.value}
                  dependsOnCommodity
                  onChange={this.handleVarietyChange}
                  errorText={get(outload, 'varietyId.errors[0]', '')}
                  label={isVarietyMandatoryLabel ? 'Variety' : 'Variety (Optional)'}
                  varietyId={outload.varietyId.value}
                  disabled={this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              {
                this.isNGRFieldDisabled() ?
                  <div className='col-xs-4 form-wrap'>
                    <CommonTextField
                      id='outload.ngrId'
                      label='NGR'
                      value={this.getNGRNumber()}
                      disabled
                    />
                  </div> :
                  <div className='col-xs-8 form-wrap padding-reset'>
                    <div className='col-xs-6 form-wrap'>
                      <CompanyAutocomplete
                        variant='standard'
                        id='company'
                        label='Stock Owner'
                        companyId={stockOwner.value || get(selectedNgr, 'companyId')}
                        selected={selectedStockOwner}
                        onChange={this.onCompanyChange}
                        urlPath='directory/names/'
                        queryParams={{include_self: true, excludeGroups: true}}
                        minLength={3}
                        style={{ float: 'right' }}
                        errorText={stockOwner.errors[0]}
                        disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isPackMovement || lockStockOwner || isContainerMovement}
                        filterFunc={companies => filter(companies, company => {
                          if (get(this.props, 'outload.ngr.companyId') && get(this.props.movement, 'commodityContract.buyer.companyId') == get(this.props, 'outload.ngr.companyId'))
                            return true
                          else
                            return company.id != get(this.props.movement, 'commodityContract.buyer.companyId');
                        })}
                        />
                    </div>
                    <div className='col-xs-6 form-wrap'>
                      <CommonSelect
                        id='outload.ngrId'
                        floatingLabelText={stockOwner.value ? ngrFloatingLabelText : 'NGR (Select Stock Owner)'}
                        items={stockOwner.value ? ngrs : []}
                        selectConfig={{ value: 'id', text: 'ngrNumber' }}
                        onChange={this.handleSelectFieldChange}
                        errorText={ngrFieldError}
                        value={outload.ngrId.value || get(selectedNgr, 'id')}
                        disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad || isPackMovement || isContainerMovement}
                      />
                    </div>
                  </div>
              }
              {
                !movement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='outload.estimatedNetWeight'
                    label={getCountryLabel('tonnage')}
                    placeholder={getCountryLabel('tonnage')}
                    value={outload.estimatedNetWeight.value}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced || isShrinkageLoad || isStockUpdateLoad}
                    helperText={estimatedNetWeightError}
                    maxValue={999999999.99}
                    onChange={this.handleEstimatedNetWeightFieldChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                this.hasQuantityBasedCommodity() &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='outload.quantity'
                    label={this.quantityLabel()}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced}
                    placeholder='Please enter'
                    value={outload.quantity.value}
                    onChange={this.handleQuantityFieldChange}
                    maxValue={999999999.99}
                    helperText={get(outload, 'quantity.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                          {this.quantityUnit()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement && isContainerMovement &&
                <div className='col-xs-4 form-wrap'>
                  <CommonTextField
                    id='outload.containerTare'
                    label='Container Tare'
                    placeholder='Please enter'
                    value={outload.containerTare.value}
                    onChange={this.handleContainerTareChange}
                    onKeyDown={event => positiveDecimalFilter(event, 2, 999999999.99)}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced}
                    helperText={get(outload, 'containerTare.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='outload.tareWeight'
                    label={
                      isSelectedSiteFFAndWeighingAtDestination &&
                        !outload.grossWeight.value
                        ? 'Truck Tare Weight (Optional)'
                        : 'Truck Tare Weight'
                    }
                    placeholder='Please enter'
                    value={outload.tareWeight.value}
                    onChange={this.handleTareWeightChange}
                    maxValue={999999999.99}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced}
                    helperText={get(outload, 'tareWeight.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {this.getTruckUnit()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='outload.grossWeight'
                    label={
                      isSelectedSiteFFAndWeighingAtDestination &&
                        !outload.tareWeight.value
                        ? 'Truck Gross Weight (Optional)'
                        : 'Truck Gross Weight'
                    }
                    placeholder='Please enter'
                    value={outload.grossWeight.value}
                    onChange={this.handleGrossWeightChange}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced}
                    maxValue={999999999.99}
                    helperText={get(outload, 'grossWeight.errors[0]', '')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {this.getTruckUnit()}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement &&
                <div className='col-xs-4 form-wrap'>
                  <NumberField
                    id='outload.estimatedNetWeight'
                    label='Net Weight'
                    placeholder='Please enter'
                    value={outload.estimatedNetWeight.value ? outload.estimatedNetWeight.value : ''}
                    onChange={() => { }}
                    onBlur={this.handleNetWeightChange}
                    maxValue={999999999.99}
                    helperText={estimatedNetWeightError || get(this.state, 'sellerAvailableTonnageMessage')}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad || isInvoiced}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={styleAdornment}>
                          {unit}
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
              }
              {
                movement && !isPackMovement &&
                <div className='col-xs-4 form-wrap'>
                  <CommonTextField
                    id='outload.docketNumber'
                    placeholder='Please enter'
                    label={docketNumberFloatingLabelText}
                    value={outload.docketNumber.value}
                    onChange={this.handleFieldChange}
                    disabled={completedMovement || archivedStorage || this.isRegradedLoad}
                    helperText={get(outload, 'docketNumber.errors[0]', '')}
                  />
                </div>
              }
              <div className='col-xs-4 form-wrap'>
                <CommonTextField
                  id='outload.orderNumber'
                  label='Order No (Optional)'
                  placeholder='Please enter'
                  value={outload.orderNumber.value}
                  onChange={this.handleFieldChange}
                  helperText={get(outload, 'orderNumber.errors[0]', '')}
                  maxLength='255'
                  disabled={this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              {
                movement && !isPackMovement &&
                  <div className='col-xs-4 form-wrap'>
                    <CommonTextField
                      id='outload.bookingNumber'
                      placeholder='Please enter'
                      label='Booking Number (Optional)'
                      value={outload.bookingNumber.value}
                      onChange={this.handleFieldChange}
                      helperText={get(outload, 'bookingNumber.errors[0]', '')}
                    />
                  </div>
              }
              <SpecParametersValue
                commoditySpecs={commoditySpecs}
                fieldsSpecs={specs}
                onChange={this.handleSpecsChange}
                disabled={completedMovement || archivedStorage || this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                errorTexts={mapValues(specs, spec => get(spec, 'errors[0]', ''))}
                warningTexts={mapValues(specs, spec => get(spec, 'warnings[0]', ''))}
                clearIndex={get(storage, 'isOwner') ? [0] : [2]}
              />
              {get(movement, 'isBlended') &&
              <React.Fragment>
                <div className="col-xs-12" style={{paddingRight: '0px', marginBottom: '30px'}}>
                  <h4 style={{marginBottom: '0px'}}>Applications</h4>
                {
                  map(this.state.chemicalApplications, (obj, index) => (
                    <div key={index}>
                      <div className="col-xs-12" style={{padding: '0px 0px 0px 0px', marginTop: '20px'}}>
                        <div className="col-xs-4" style={{paddingLeft: '0px'}}>
                          <CommodityAutoComplete
                            id='commodity'
                            commodityId={obj.commodityId}
                            selected={obj.commodity}
                            onChange={(value) => this.onChemicalApplicationCommodityChange(obj, value)}
                            label='Application Commodity'
                            errorText={get(obj, 'commodityErrors.0') || (obj.commodityId ? '' : get(obj, 'errors.0'))}
                            itemFilterFunc={commodities => filter(commodities, {type: 'chemical'})}
                            disabled={completedMovement}
                          />
                        </div>
                        <div className="col-xs-3" style={{paddingLeft: '0px', paddingRight: '0px'}}>
                          <CommonAutoSelect
                            id='applicationRate'
                            disabled={!obj.commodityId || completedMovement}
                            onChange={value => this.handleApplicationRateChange(obj, value)}
                            label={`Application Rate (${this.applicationCommodityUnit(obj.commodity, obj.commodityId)}/${this.priceUnit()})`}
                            dataSourceConfig={{ text: 'name', value: 'id' }}
                            value={obj.applicationRate}
                            items={this.getEligibleApplicationRates(obj.commodityId)}
                            errorText={obj.applicationRate ? '' : get(obj, 'errors.0')}
                          />
                        </div>
                        <div className="col-xs-4" style={{paddingRight: '0px'}}>
                          <CommonAutoSelect
                            items={sites}
                            id='storageId'
                            label='Storage'
                            value={obj.storageId}
                            errorText={obj.storageId ? '' : get(obj, 'errors.0', '')}
                            onChange={(value, id, item) => this.handleChemicalApplicationStorageChange(value, item, obj)}
                            dontAutoselectSingleItem
                            disabled={completedMovement}
                          />
                        </div>
                        {!completedMovement &&
                        <div className='col-md-1' style={{paddingRight: '0px'}}>
                          <IconButton onClick={() => this.onChemicalApplicationDelete(obj)} color='error'>
                            <RemoveIcon fontSize='inherit' />
                          </IconButton>
                        </div>
                        }
                      </div>
                    </div>
                  ))
                }
                <div className='col-xs-12' style={{marginTop: '30px', paddingLeft: '0px'}}>
                  <AddButton label='Application' onClick={() => this.onChemicalApplicationAdd()} style={{float: 'left'}} />
                </div>
                </div>
              </React.Fragment>
              }
              {
                movement && !isPackMovement && isTruckMassLimitsVisible &&
                <React.Fragment>
                  <div className="col-xs-12 form-wrap">
                    <h4>Truck Mass Limits (optional)</h4>
                  </div>
                  <div className="col-xs-12 form-wrap">
                    <CommonAutoSelect
                      id="code"
                      label='Code'
                      placeholder="Select Code..."
                      items={map(TRUCK_CODES, code => ({ id: code, name: code }))}
                      value={this.state.code.value || ''}
                      onChange={this.handleTruckCodeChange}
                      disabled={this.isRegradedLoad}
                    />
                  </div>
                  <div className="col-xs-4 form-wrap">
                    <CommonAutoSelect
                      id="massLimitPermit"
                      label='Mass Limit Permit'
                      placeholder="Select Mass Limit Permit..."
                      items={map(this.state.permits, permit => ({ id: permit, name: permit }))}
                      value={this.state.massLimitPermit.value || ''}
                      onChange={this.handleSelectFieldChange}
                      dataSourceConfig={{ id: 'id', text: 'name' }}
                      disabled={this.isRegradedLoad}
                      errorText={get(this.state.massLimitPermit.errors, '0') || ''}
                    />
                  </div>
                  <div className="col-xs-3 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='steer_point_5'
                          color='primary'
                          checked={this.state.models.outload.steer_point_5.value}
                          onChange={this.handleSteerChange}
                          disabled={!this.shouldEnableSteerPoint5() || this.isRegradedLoad}
                        />
                      }
                      label='0.5 T Steer Axle Allowance'
                    />
                  </div>
                  <div className="col-xs-3 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='steer_1_point_1'
                          color='primary'
                          checked={this.state.models.outload.steer_1_point_1.value}
                          onChange={this.handleSteerChange}
                          disabled={!this.shouldEnableSteer1Point1() || this.isRegradedLoad}
                        />
                      }
                      label='1.1 T Allowance'
                    />
                  </div>
                  <div className="col-xs-2 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='ghms'
                          color='primary'
                          checked={this.state.ghms.value}
                          onChange={this.handleCheckboxChange}
                          disabled={this.isRegradedLoad || isNoticePermit || (this.state.massLimitPermit.value || '').includes('PBS - ')}
                        />
                      }
                      label='GHMS'
                    />
                  </div>
                  { isNoticePermit &&
                      <div className="col-sm-4" style={{marginBottom: '30px'}}>
                        <CommonAutoSelect
                          id="models.outload.noticeNumber"
                          label='Notice Number'
                          placeholder="Select Notice Number..."
                          items={NOTICE_NUMBERS}
                          value={this.state.models.outload.noticeNumber.value}
                          onChange={this.handleTruckDetailsSelectValueChange}
                          dataSourceConfig={{id: 'id', text: 'name'}}
                          errorText={this.state.truckErrors.noticeNumber}
                        />
                      </div>
                    }
                  {
                    this.state.showAdditionalMassLimitFields &&
                    <AdditionalMassLimitFields
                      permitChecked={Boolean(this.state.permitChecked)}
                      handlePermitClick={this.handlePermitClick}
                      accreditationNumberChecked={Boolean(this.state.accreditationNumberChecked)}
                      handleAccreditationNumberClick={this.handleAccreditationNumberClick}
                      loadSharing={Boolean(this.state.loadSharing)}
                      handleLoadSharingClick={this.handleLoadSharingClick}
                      disableLoadSharing={disableLoadSharing}
                      isPBSMassLimitPermit={(this.state.massLimitPermit.value || '').includes('PBS - ')}
                      notice={this.state.massLimitPermit.value === 'Notice'}
                      handleTruckDetailsChange={this.handleTruckDetailsChange}
                      restrictedChecked={this.state.restrictedChecked}
                      handleRestrictedClick={this.handleRestrictedClick}
                      handleTruckDetailsSelectValueChange={this.handleTruckDetailsSelectValueChange}
                      accreditationNumberFieldId="models.outload.accreditationNumber"
                      accreditationNumber={this.state.models.outload.accreditationNumber.value}
                      restrictedFieldId="models.outload.restricted"
                      restricted={this.state.models.outload.restricted.value}
                      declaredMassLimitFieldId="models.outload.declaredMassLimit"
                      declaredMassLimit={this.state.models.outload.declaredMassLimit.value}
                      permitNumberFieldId="models.outload.permitNumber"
                      permitNumber={this.state.models.outload.permitNumber.value}
                      categoryId={this.state.models.outload.categoryId.value}
                      truckErrors={this.state.truckErrors}
                      truckUnit={this.getTruckUnit()}
                    />
                  }
                </React.Fragment>
              }
              {
                movement && !isPackMovement &&
                <FileUpload
                  id='outload.docketImage'
                  buttonText={`Upload ${getCountryLabel('docket')} Image`}
                  fullWidth={true}
                  fileCount={docketImageFileCount}
                  errorText={outload.docketImage.errors[0]}
                  textFieldstyle={{ float: 'left', color: 'black' }}
                  onChange={this.onDocketImageUpload}
                  onRemove={this.onDocketImageRemove}
                  showImagePreviewFromURL={docketImageUrl}
                  disabled={completedMovement || archivedStorage || this.isRegradedLoad}
                  maxUploadFileCount={MAX_UPLOAD_DOCKETS}
                />
              }
              <div className='col-xs-12 cardForm-action'>
                <CommonTextField
                  id='outload.comment'
                  label='Comment'
                  value={outload.comment.value}
                  onChange={this.handleFieldChange}
                  multiline
                  rows={3}
                  variant='outlined'
                  disabled={this.isRegradedLoad || isShrinkageLoad || isStockUpdateLoad}
                />
              </div>
              <div className='col-xs-12 cardForm-action padding-reset'>
                {!get(this.props, 'outload.titleTransferNumber') && !get(this.props, 'outload.checkpointId') &&
                  get(this.props, 'outload.id') && !isShrinkageLoad &&
                  <CommonButton
                    label='Delete'
                    default={true}
                    variant='outlined'
                    onClick={this.voidLoad}
                    disabled={isInvoiced}
                  />}
                <CommonButton
                  label='Cancel'
                  default={true}
                  variant='outlined'
                  onClick={this.props.closeDrawer}
                />
                {
                  !isShrinkageLoad && !isStockUpdateLoad &&
                  <CommonButton
                    type='submit'
                    label='Save'
                    primary
                    variant="contained"
                    disabled={this.state.submitting}
                  />
                }
              </div>
            </div>
          </div>
          {(showUpdateToTonnagePopup || (this.state.showAmendTonnagePopup && isParentOrderIndependent)) &&
          <Dialog open onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>
            <DialogTitleWithCloseIcon onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
              This {entityName} can take up to only {parentTonnage} {this.state.unit} (Inc tolerance).
              Saving this load will take the {entityName}&apos;s {this.countryTonnageLabel.toLowerCase()} to {updateToTonnage.toFixed(2)} {this.state.unit}. Do you want to automatically
              update the {entityName} {this.countryTonnageLabel.toLowerCase()} to {updateToTonnage.toFixed(2)} {this.state.unit} on saving this load?
            </DialogContent>
            <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}
              variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={() => this.setState({amendParentTonnage: true, showAmendTonnagePopup: false}, () => this.confirmSubmit(this.getSubmitData()))} color='primary' variant='outlined'>
              Yes, Proceed
            </Button>
            </DialogActions>
          </Dialog>
          }
          {this.state.showAmendTonnagePopup && !isParentOrderIndependent && !showUpdateToTonnagePopup  &&
          <Dialog open onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>
            <DialogTitleWithCloseIcon onClose={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
              This {entityName} can take up to only {parentTonnage} {this.state.unit} (Inc tolerance)
              {isDirectContractMovement ? '' : `and can be amended up to ${maxParentTonnage} ${this.state.unit} due to ${get(this.props, 'movement.reason')}`} {parentEntityUrl}. {amendMessage}
            </DialogContent>
            <DialogActions>
            <Button
              type='button'
              onClick={() => this.setState({showAmendTonnagePopup: false}, () => this.unlockSubmit())}
              variant='outlined'>
              Cancel
            </Button>
            </DialogActions>
          </Dialog>
          }
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    userCompanyId: state.main.user.user.companyId,
    user: state.main.user.user,
    commodities: state.master.commodities.items,
    isRegoAvailable: state.main.isRegoAvailable,
    createdTruck: state.companies.companies.company.trucks.createdTruck,
    isLoading: state.main.isLoading,
  };
};

const mapDispatchToProps = dispatch => ({
  getCommodities: () => dispatch(getCommodities()),
  validateRego: (key, value, callback) => dispatch(validateRego(key, value, callback)),
  createTruck: (companyId, data, addTruck) => dispatch(createTruck(companyId, data, addTruck)),
  emptyCreatedTruck: () => dispatch(emptyCreatedTruck()),
  isLoading: () => dispatch(isLoading()),
  forceStopLoader: () => dispatch(forceStopLoader())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutloadForm));

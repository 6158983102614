import React, { Component } from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import { Autocomplete, TextField, Button, InputAdornment, FormControlLabel, Checkbox } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { forEach, some, mapValues, find } from 'lodash';
import APIService from '../../services/APIService';
import { required } from '../../common/validators';

class ExternalPortalForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selected: props.selected,
      consent: false,
      showPassword: false,
      bhcCompanies: [],
      fields: {
        portal: {
          value: '',
          validators: [required()],
          errors: [],
        },
        url: {
          value: '',
          validators: [],
          errors: [],
        },
        username: {
          value: '',
          validators: [required()],
          errors: [],
        },
        password: {
          value: '',
          validators: [required()],
          errors: [],
        },
      }
    }
  }

  componentDidMount() {
    this.fetchBHCCompanies()
  }

  fetchBHCCompanies() {
    APIService.companies().appendToUrl('BHCs/').get().then(response => this.setState({bhcCompanies: response}, this.setUp))
  }

  setUp() {
    if(this.state.selected) {
      this.setForEdit()
    }
  }

  setAllFieldErrors = () => {
    const newState = {...this.state}
    forEach(newState.fields, (state, field) => {
      newState.fields[field].errors = []
      state.validators.forEach(validator => {
        if(validator.isInvalid(state.value))
          newState.fields[field].errors.push(validator.message)
      })
    })
    this.setState(newState)
  }

  getPayload = () => ({...mapValues(this.state.fields, 'value')})

  onSuccess = () => alertifyjs.success('Success!', 1, this.props.onSuccess)

  onSubmit = () => {
    this.setAllFieldErrors()
    const isFormInvalid = some(this.state.fields, field => field.errors.length > 0);


    if(!this.state.consent)
      alertifyjs.error('Please provide consent', 5)
    else if(!isFormInvalid){
      const service = APIService.companies(this.props.company.id).appendToUrl('external/portals/')
      const payload = this.getPayload()
      if(this.state.selected)
        service.appendToUrl(`${this.state.selected.id}/`).put(payload).then(this.onSuccess)
      else
        service.post(payload).then(this.onSuccess)
    }
  }

  setForEdit = () => {
    const newState = {...this.state};
    ['url', 'username', 'password', 'portal'].forEach(field => newState.fields[field].value = newState.selected[field] || '');
    this.setState(newState)
  }

  onFieldChange = (id, value) => {
    const newState = {...this.state}
    newState.fields[id].value = value || ''
    this.setState(newState)
  }

  handleClickShowPassword = () => this.setState({showPassword: !this.state.showPassword})

  render() {
    const { fields, bhcCompanies, selected, showPassword } = this.state
    const { onClose } = this.props
    const selectedHandler = find(bhcCompanies, {name: fields.portal.value})
    return (
      <div className='cardForm cardForm--drawer' style={{marginTop: '20px'}}>
        <div className='col-sm-12 form-wrap-70'>
          <Autocomplete
            blurOnSelect
            id='portal'
            required
            options={bhcCompanies}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField required label='Bulk Handler' {...params} variant='outlined' fullWidth error={Boolean(fields.portal.errors[0])} helperText={fields.portal.errors[0]} />}
            value={selectedHandler || null}
            onChange={(event, value) => this.onFieldChange('portal', value.name)}
          />
        </div>
        <div className='col-sm-12 form-wrap-70' style={{marginTop: '15px'}}>
          <TextField
            id='username'
            label='Username'
            value={fields.username.value}
            onChange={event => this.onFieldChange('username', event.target.value)}
            required
            fullWidth
            error={Boolean(fields.username.errors[0])}
            helperText={fields.username.errors[0]}
          />
        </div>
        <div className='col-sm-12 form-wrap-70' style={{marginTop: '15px'}}>
          <TextField
            label="Password"
            placeholder="Password"
            fullWidth
            onChange={event => this.onFieldChange('password', event.target.value)}
            name="password"
            type={showPassword ? 'text' : 'password'}
            value={fields.password.value}
            required
            error={Boolean(fields.password.errors[0])}
            helperText={fields.password.errors[0]}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleClickShowPassword}
                    size="large">
                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
        <div className='col-sm-12 form-wrap-70' style={{marginTop: '15px'}}>
          <TextField
            label="URL (Optional)"
            placeholder="URL"
            fullWidth
            onChange={event => this.onFieldChange('url', event.target.value)}
            name="URL"
            type='url'
            value={fields.url.value}
          />
        </div>
        <div className='col-sm-12 form-wrap-70' style={{marginTop: '15px'}}>
          <FormControlLabel
            control={<Checkbox />}
            onClick={event => this.setState({consent: event.target.checked})}
            label={
              <span style={{color: 'rgba(0, 0, 0, 0.75)'}}>
                I, hereby agree to share these credential with AgriChain team for login to the mentioned BHC portal for the purpose of stock management.
              </span>
            }
          />
        </div>
        <div className='col-sm-12 form-wrap-70' style={{marginTop: '30px'}}>
          <Button variant='outlined' color='default' style={{marginRight: '20px'}} onClick={onClose}>
            Cancel
          </Button>
          {
            selected &&
              <Button variant='contained' color='error' style={{marginRight: '20px'}} onClick={this.props.onDelete}>
                Delete
              </Button>
          }
          <Button variant='contained' color='primary' onClick={this.onSubmit}>
            {
              selected?.id ? 'Update' : 'Create'
            }
          </Button>
        </div>
      </div>
    )
  }
}

export default connect()(ExternalPortalForm)

import { connect } from 'react-redux';
import CompanySiteForm from '../components/company-sites/CompanySiteForm';
import { updateCompanySite } from '../actions/api/company-sites';
import { editCompanySite, cancelEditCompanySite } from '../actions/companies/company-sites';
import find from 'lodash/find';

function submit(companyId, data) {
  return (dispatch, getState) => {
    const companySiteId = getState().companies.companySites.selectedCompanySiteId;
    dispatch(updateCompanySite(companyId, companySiteId, data, editCompanySite));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedCompanySite: find(
      state.companies.companySites.items,
      { id: state.companies.companySites.selectedCompanySiteId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (companyId, data) => dispatch(submit(companyId, data)),
    cancelEdit: () => dispatch(cancelEditCompanySite()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySiteForm);

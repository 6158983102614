import React from 'react';
import  { useSelector }  from 'react-redux';
import { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import { get, without, isEmpty, keys, values, forEach, filter, map, uniq, compact, pick, includes } from 'lodash';
import CustomEmailDialog from './CustomEmailDialog';
import { CALL_ON_GRAIN_TYPE_ID, FREIGHT_CONTRACT_TYPE, PACK_ORDER_TYPE_ID } from '../../common/constants';
import find from 'lodash/find';
import { isCurrentUserBroker } from '../../common/utils';


let FreightCustomEmail = (props) =>{

    const token = useSelector(state => {
        return state.main.user.token;
    });
    const [isLoaded, setLoaded ] = useState(false);
    const [isIndependent, setIsIndependent ] = useState(false);
    const [emailParties, setEmailParties] = useState([]);
    const user = useSelector(state => {
        return state.main.user.user;
    });
    const [allContactsList, setAllContactsList] = useState({});

    let getContacts = async (company, representedById) => {
        let companyId = typeof company === 'object' ? get(company, 'id') : company
        const queryParams = representedById ? {representing_company_id: representedById} : {};
        return companyId ? await APIService.contracts().companies(companyId).contacts().get(token, {}, queryParams) : [];
    };

    let getEmailPopupParties = selectedOrder => {
      if (isEmpty(selectedOrder.cannotRequestVendorDecReasons) && props.vendorDecDetails)
        return ['seller', 'buyer', 'broker'];

        if (selectedOrder.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY)
          return ['customer', 'provider', 'consignor', 'consignee'];
        if (selectedOrder.typeId === PACK_ORDER_TYPE_ID)
          return ['customer', 'packProvider'];

        const buyerCompanyId = get(selectedOrder, 'commodityContract.buyer.companyId', get(selectedOrder, 'buyer.companyId', '')) || get(selectedOrder, 'buyerCompanyId');
        const sellerCompanyId = get(selectedOrder, 'commodityContract.seller.companyId', get(selectedOrder, 'seller.companyId', '')) || get(selectedOrder, 'sellerCompanyId');
        const customerCompanyId = get(selectedOrder, 'customerCompanyId', '');
        const buyer = buyerCompanyId === customerCompanyId ? {label: 'Buyer (Customer)', value: 'customer'} : 'buyer';
        const seller = sellerCompanyId === customerCompanyId ? {label: 'Seller (Customer)', value: 'customer'} : 'seller';
        if (get(selectedOrder, 'commodityContract.administration.brokeredById'))
          return [buyer, seller, 'broker', 'provider', 'consignor', 'consignee'];

        return [buyer, seller, 'provider', 'consignor', 'consignee'];
    };

    let fetchContacts = async selectedOrder => {
        let parties = null;
        let contacts = null;
        if(get(selectedOrder, 'typeId') === CALL_ON_GRAIN_TYPE_ID){
          parties = ['buyer', 'seller', 'consignor', 'consignee'];
          if (get(selectedOrder, 'commodityContract.administration.brokeredById')) {
            parties = ['buyer', 'seller', 'broker', 'consignor', 'consignee'];
          }
          let sellerContacts = await getContacts(get(selectedOrder, 'commodityContract.seller.company', get(selectedOrder, 'seller.company', false)), get(selectedOrder, 'commodityContract.seller.representedById',  get(selectedOrder, 'seller.representedById')));
          let buyerContacts = await getContacts(get(selectedOrder, 'commodityContract.buyer.company', get(selectedOrder, 'buyer.company', false)), get(selectedOrder, 'commodityContract.buyer.representedById', get(selectedOrder, 'buyer.representedById')));
          contacts = await fetchPartyContacts(parties, {
            buyerContacts: buyerContacts,
            sellerContacts: sellerContacts,
          });
          setAllContactsList(contacts);
          setEmailParties(parties);
          setLoaded(true);
          return;
        }
          parties = getEmailPopupParties(selectedOrder);
          let sellerContacts = await getContacts(get(selectedOrder, 'commodityContract.seller.company', get(selectedOrder, 'seller.company', false)) || get(selectedOrder, 'sellerCompanyId'), get(selectedOrder, 'commodityContract.seller.representedById', get(selectedOrder, 'seller.representedById')));
          let buyerContacts = await getContacts(get(selectedOrder, 'commodityContract.buyer.company', get(selectedOrder, 'buyer.company', false)) || get(selectedOrder, 'buyerCompanyId'), get(selectedOrder, 'commodityContract.buyer.representedById', get(selectedOrder, 'buyer.representedById')));
          let customerContacts = await getContacts(get(selectedOrder, 'customer.company') || get(selectedOrder, 'customerCompanyId'), get(selectedOrder, 'customer.representedById'));
          let providerEmployees = get(selectedOrder, 'providerId') ? await APIService.companies(get(selectedOrder, 'providerId')).employees().get(token) : [];
          contacts = await fetchPartyContacts(parties, {
            customerContacts: customerContacts,
            providerEmployees: providerEmployees,
            buyerContacts: buyerContacts,
            sellerContacts: sellerContacts,
          });
          setAllContactsList(contacts);
          setEmailParties(parties);
          setLoaded(true);

    };

    let getPartyEmails = () => {
        let selectedOrder = props.order;
        let parties = {
          broker: get(selectedOrder, 'commodityContract.administration.brokerContact.email', ''),
          buyer: get(selectedOrder, 'commodityContract.buyer.contact.email', get(selectedOrder, 'buyer.contact.email', '')) || get(selectedOrder, 'buyerContactEmail'),
          seller: get(selectedOrder, 'commodityContract.seller.contact.email', get(selectedOrder, 'seller.contact.email', '')) || get(selectedOrder, 'sellerContactEmail'),
          customer: get(selectedOrder, 'customer.contact.email') || get(selectedOrder, 'customerContactEmail'),
          provider: get(find(allContactsList.provider, {id: get(selectedOrder, 'assignToId')}), 'email'),
          packProvider: get(find(allContactsList.provider, {id: get(selectedOrder, 'assignToId')}), 'email'),
          consignor: '',
          consignee: '',
        };

        if(get(selectedOrder, 'typeId')===CALL_ON_GRAIN_TYPE_ID)
          delete parties.provider;

        return parties;
    };

    let getEmailPopupPartiesCompanyIds = (parties) => {
        let selectedOrder =  props.order;
        const ids = {};
        forEach(parties, party => {
          if(party === 'broker')
            ids.broker = get(selectedOrder, 'commodityContract.administration.brokerContact.companyId');
          if(party === 'buyer')
            ids.buyer = get(selectedOrder, 'commodityContract.buyer.companyId', get(selectedOrder, 'buyer.companyId')) || get(selectedOrder, 'buyerCompanyId');
          if(party === 'seller')
            ids.seller = get(selectedOrder, 'commodityContract.seller.companyId', get(selectedOrder, 'seller.companyId')) || get(selectedOrder, 'sellerCompanyId');
          if(party === 'consignor')
            ids.consignor = get(selectedOrder, 'freightPickup.consignor.handler.companyId') || get(selectedOrder, 'consignorCompanyId');
          if(party === 'consignee')
            ids.consignee = get(selectedOrder, 'freightDelivery.consignee.handler.companyId') || get(selectedOrder, 'consigneeCompanyId');
          if(party === 'customer' || get(party, 'label'))
            ids.customer = get(selectedOrder, 'customer.company.id') || get(selectedOrder, 'customerCompanyId');
          if(party === 'provider')
            ids.provider = get(selectedOrder, 'providerId');
          if(party === 'packProvider')
            ids.packProvider = get(selectedOrder, 'providerId');
        });

        return ids;
    };

    let fetchPartyContacts= async (emailPopupParties, contactsList) => {
        const parties = getEmailPopupPartiesCompanyIds(emailPopupParties);
        let excludeParties = get(props.order, 'typeId') === CALL_ON_GRAIN_TYPE_ID ? ('buyer', 'seller') :('customer', 'provider');
        const partiesWithoutContacts = without(keys(parties), excludeParties);
        const contacts = {};
        forEach(parties, (id, party) => {
          contacts[party] = [];
          if(party === 'customer')
              contacts[party] = get(contactsList, 'customerContacts', []);
          if(party === 'provider' || party === 'packProvider')
              contacts[party] = get(contactsList, 'providerEmployees', []);
          if(party === 'buyer')
              contacts[party] = get(contactsList, 'buyerContacts', []);
          if(party === 'seller')
              contacts[party] = get(contactsList, 'sellerContacts', []);
        });
        if(!isEmpty(partiesWithoutContacts)) {
          const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
          if(isEmpty(companyIds))
            return contacts;
          const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
          const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(token);
          forEach(partiesWithoutContacts, party => {
            contacts[party] = filter(employees, {companyId: parties[party]});
          });
        }
        return contacts;
    };

    let getEmailSubject = () => {
        if (props.subject){
          return props.subject;
        }
        const companyName = get(user,'company.name',"");
        const identifier = get(props.order, 'identifier', '').toUpperCase();
        let typeId = get(props.order, 'typeId');
        const entity = typeId === CALL_ON_GRAIN_TYPE_ID ? "Grain" : typeId === PACK_ORDER_TYPE_ID ? "Pack" : "Freight";
        if (isCurrentUserBroker()){
          let representingCompany = null;
          if (entity === 'Freight'){
            representingCompany = isRepresentingCustomer() ? get(props.order, 'customer.company.name') :
            "";
          }
          else{
            representingCompany = user.companyId === get(props.order, 'commodityContract.buyer.companyId',  get(props.order, 'buyer.companyId')) ? get(props.order, 'commodityContract.buyer.company.name', get(props.order, 'buyer.company.name')):
            get(props.order, 'commodityContract.seller.company.name', get(props.order, 'seller.company.name'));
          }
          if (representingCompany)
            return `[Void] ${companyName} a/c ${representingCompany} ${entity} Order #${identifier}`;
        }
        return `[Void] ${companyName} ${entity} Order #${identifier}`;
    };

  let getSelectedParties = () => {
    let selectedOrder = props.order;
    if (props.vendorDecDetails) {
      if (get(selectedOrder, 'isCustomer') && !get(selectedOrder, 'isBuyer') && !get(selectedOrder, 'commodityContract')) return ['seller', 'broker'];
      else if (get(selectedOrder, 'isBuyer') && get(selectedOrder, 'commodityContract.seller.companyId', get(selectedOrder, 'seller.companyId'))) return ['seller', 'broker'];
      else return ['seller'];
    }
    else {
      if (get(selectedOrder, 'typeId') === CALL_ON_GRAIN_TYPE_ID) {
        let buyerCompanyId = get(selectedOrder, 'commodityContract.seller.companyId', get(selectedOrder, 'buyer.companyId')) || get(selectedOrder, 'sellerCompanyId');
        let sellerCompanyId = get(selectedOrder, 'commodityContract.seller.companyId', get(selectedOrder, 'seller.companyId')) || get(selectedOrder, 'buyerCompanyId');
        if (get(selectedOrder, 'isBuyer') && get(selectedOrder, 'isSeller')) return ['buyer', 'seller'];
        else if (get(selectedOrder, 'isBuyer') && user.companyId === buyerCompanyId) return ['seller'];
        else if (get(selectedOrder, 'isSeller') && user.companyId === sellerCompanyId) return ['buyer'];
        return ['buyer', 'seller'];
      }
      else {
        if (get(selectedOrder, 'isFreightProvider') && get(selectedOrder, 'isCustomer')) return ['customer', 'provider'];
        else if (get(selectedOrder, 'isFreightProvider')) return ['customer'];
        else if (get(selectedOrder, 'isCustomer')) return ['provider'];
      }
    }
  };

    let getPartyContacts = () =>{
        return allContactsList;
    };

    useEffect(() => {
        if (props.order ){
            setLoaded(false);
            fetchContacts(props.order);
        }
        if (props.vendorDecDetails && get(props.order, 'isCustomer') && !get(props.order, 'isBuyer') && (!get(props.order, 'commodityContract') || !get(props.order, 'seller'))) setIsIndependent(true);
    },[props.order, props.vendorDecDetails]);

    let isFreightProviderCreator = () => {
        return get(props.order, 'providerId') == user.companyId;
    };

    let isRepresentingCustomer = () => {
        return get(props.order, 'customer.representedById') === get(user, 'companyId');
    };

    let isThirdParty = () => {
        return get(props.order, 'customer.representedById') !== get(user, 'companyId') &&
            get(props.order, 'customer.companyId') !== get(user, 'companyId') && !isFreightProviderCreator();
    };

  const isAcceptanceRequired = () => {
    const { order } = props;
    return get(order, 'croSitePermission') === 'grain_owner_and_site_acceptance_from_site' && get(order, 'isCustomer');
  };


  let isAcceptanceDisabled = () => {
      if(props.vendorDecDetails || props.order.croSitePermission || get(props, 'order.typeId') == PACK_ORDER_TYPE_ID){
        return true;
      }

    if(props.order && props.order.id && !props.order.isFreightProvider && !props.order.isCustomer)
      return true;

    const isEditingBeforeConfirmation = includes(['draft', 'planned'], get(props.order, 'status'));

    return get(props.order, 'isSelf', false) || isEditingBeforeConfirmation || (isRepresentingCustomer() && isFreightProviderCreator()) || (get(props, 'order.typeId') === CALL_ON_GRAIN_TYPE_ID && !isThirdParty());
    };

    let partiesForVendorDecRequest = () => {
        if (props.order.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          return['seller', 'broker'];
        }
        else {
          if (get(props.order, 'commodityContract.seller.representedById', get(props.order, 'seller.representedById', false))) {
            return[ 'seller', 'broker'];
          }
          else {
            return ['seller'];
          }
        }
    };

  return (<span>
    {
      props.order && props.showCustomEmailDialog && isLoaded &&
      <CustomEmailDialog
        parties={props.vendorDecDetails ? partiesForVendorDecRequest() : emailParties}
        selectedParties={getSelectedParties()}
        title={props.title || (props.vendorDecDetails) ? 'Request Vendor Declaration' : 'Void Order' }
        partyEmails={getPartyEmails()}
        partyContacts={getPartyContacts()}
        subject={getEmailSubject()}
        noBody={true}
        open={props.showCustomEmailDialog}
        onClose={props.closeCustomEmailDialog}
        disableAcceptanceRequired={isAcceptanceDisabled()}
        isAcceptanceRequired={isAcceptanceRequired()}
        forceSetAcceptanceRequired={isAcceptanceRequired()}
        disableLater={props.disable}
        isIndependent={isIndependent}
      />
    }
  </span>);

};

export default FreightCustomEmail;

import React from 'react';

import connect from "react-redux/es/connect/connect";
import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Cancel from '@mui/icons-material/Close';
import APIService from '../../services/APIService';
import isEmpty from 'lodash/isEmpty';
import alertifyjs from 'alertifyjs';
import { RejectionReasonDialog } from "../../components/rejections/RejectionReasonDialog";
import { required } from '../../common/validators';
import get from 'lodash/get';
import { voidAcceptInvoice, voidRejectInvoice } from "../../actions/companies/invoices";

class InvoiceAcceptRejectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectionTitle: "Reject Invoice",
      rejectionReason: {
        value: '',
        validators: [required()],
        errors: []
      },
      rejectDialogOpen: false,
      errors: [],
    };

    this.handleAccept = this.handleAccept.bind(this);
    this.handleRejectClick = this.handleRejectClick.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
    this.handleRejectClose = this.handleRejectClose.bind(this);
  }

  handleAccept() {
    const { voidable } = this.props.invoiceDetails;
    const { dispatch } = this.props;
    if (voidable)
      dispatch(voidAcceptInvoice(this.props.invoiceDetails.id));
    else {
      APIService.invoices(this.props.invoiceDetails.id)['confirm']().put({}, this.props.token)
        .then((json) => {
          if (!isEmpty(json.errors)) {
            this.setState({ errors: json.errors[0] });
          } else {
            alertifyjs.success('Invoice is accepted', 2, () => {
              window.location.reload();
            });
          }
        });
    }
  }

  async handleRejectSubmit() {
    this.setReasonErrors();
    const { voidable } = this.props.invoiceDetails;
    const { dispatch } = this.props;
    const data = { rejectionReason: this.state.rejectionReason.value };
    if (this.state.rejectionReason.errors.length === 0) {
      if (voidable) {
        dispatch(voidRejectInvoice(this.props.invoiceDetails.id, data));
      }
      else {
        await APIService.invoices(this.props.invoiceDetails.id)['reject']().put(data, this.props.token)
          .then((json) => {
            if (!isEmpty(json.errors)) {
              this.setState({ errors: json.errors[0] });
            } else {
              alertifyjs.success('Invoice is rejected', 2, () => {
                window.location.reload();
              });
            }
          });
      }
    }
  }

  handleRejectClick() {
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    this.setState(newState);
  }

  handleRejectClose() {
    const newState = { ...this.state };
    newState.rejectDialogOpen = false;
    this.setState(newState);
  }

  handleReasonChange = (event) => {
    const value = event.target.value;
    const newState = { ...this.state };
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  };

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  render() {
    const { canConfirmOrReject, voidable } = this.props.invoiceDetails;
    return (
      <div>
        {
          (canConfirmOrReject || voidable) &&
          <div>
            <h4 className="invoice-confirm-form-header">{voidable ? 'Invoice Void Request' : 'Invoice Confirm Request'}</h4>
            <div className='accept-reject-align'>
              <Button
                style={{ marginRight: '10px' }}
                variant="contained"
                className='btn-red'
                onClick={this.handleRejectClick}>
                <Cancel />
                  REJECT
                </Button>
              <Button
                variant="contained"
                style={{ marginRight: '10px' }}
                className='btn-green'
                onClick={this.handleAccept}>
                <Check />
                  ACCEPT
                </Button>
            </div>
          </div>
        }
        <RejectionReasonDialog
          open={this.state.rejectDialogOpen}
          onClose={this.handleRejectClose}
          title={voidable ? 'Reject Void' :this.state.rejectionTitle}
          value={this.state.rejectionReason.value}
          helperText={get(this.state, 'rejectionReason.errors[0]', '')}
          onChange={this.handleReasonChange}
          onCancel={this.handleRejectClose}
          onReject={this.handleRejectSubmit} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(InvoiceAcceptRejectForm);

import { pick, get, compact, includes } from 'lodash';
import { isSystemCompany, currentUser } from '../../common/utils';
import { INVOICE_TABLE_OPTIONS_ITEMS, PAYMENT_RUN_TABLE_OPTIONS_ITEMS, WAREHOUSE_DASHBOARD_TABLE_OPTIONS_ITEMS } from '../../common/constants';

export const getActionMenuOptions = (invoice, fromDetails) => {
  const options = [...INVOICE_TABLE_OPTIONS_ITEMS];
  const data = pick(invoice, ['id', 'entity'])
  const isSystem = isSystemCompany()

  options.forEach(option => {
    if (option.key === 'download_pdf') {
      option.component = true
      if(includes('Commodity Contract', invoice.type) && (isSystem || compact([invoice?.payee?.companyId, invoice?.payeeCompanyId]).includes(currentUser().companyId))) {
        option.subItems = [
          {
            component: true,
            data: {...data, party: 'seller'},
            key: "download_seller_pdf",
            text: "Seller's PDF"
          },
          {
            component: true,
            data: {...data, party: 'buyer'},
            key: "download_buyer_pdf",
            text: "Buyer's PDF"
          }
        ]
        option.hasFunc = false
      }
      else {
        option.data = data
        option.subItems = undefined
        option.hasFunc = true
      }
    }
  });
  if (isSystem) options.push({ key: 'regenerate_pdf', text: 'Regenerate PDF' });

  if(invoice.xeroId && fromDetails)
    options.push({key: 'sync_xero_payments', text: 'Sync Xero Payments'})

  if(invoice?.isPartOfValidPaymentRuns)
    options.push({key: 'remove_from_payment_run', text: 'Remove from Payment Run'})

  return options;
};

export const getWarehouseDashboardMenuOptions = invoice => {
  let options =   [...WAREHOUSE_DASHBOARD_TABLE_OPTIONS_ITEMS]

  if(invoice.systemAmount - invoice.invoicedAmount)
    return options

  return [options[3], options[4], options[5]]
};

export const getPaymentRunMenuOptions = paymentRun => {
  let options =   [...PAYMENT_RUN_TABLE_OPTIONS_ITEMS];
  let paymentRunStatus = get(paymentRun, 'statusDisplay');
  if (paymentRunStatus !== 'Void') {
    options.push({ key: 'download_aba', text: 'Download ABA'});
  }
  if (paymentRunStatus !== 'Paid' && paymentRunStatus !== 'Void') {
    options.push({ key: 'mark_paid', text: 'Mark Paid'});
    options.push({ key: 'update_bank_processing_date', text: 'Update Bank Processing Date'});
    options.push({ key: 'void', text: 'Void'});
  }
  if (paymentRunStatus === 'Paid') {
    options.push({ key: 'un_paid', text: 'Mark Unpaid'});
  }
  if(!get(paymentRun, 'payerNgr.bankDetails[0].name'))
    options.push({key: 'assign_payer_ngr', text: 'Assign Buyer NGR/Bank'})

  return options;
};

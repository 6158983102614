import React from 'react';
import { canActOnStorage } from '../../common/utils';
import ActiveStorages from './ActiveStorages';
import ArchivedStorages from './ArchivedStorages';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
class Storages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: 'active' ,
      canActOnStorage: false,
    };
  }

  componentDidMount() {
    if(this.props.farm) {
      this.setState({ canActOnStorage: canActOnStorage(this.props.farm) });
    }
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(get(prevProps, 'farm', ''), get(this.props, 'farm'))) {
      this.setState({ canActOnStorage: canActOnStorage(this.props.farm) });
    }
  }

  tabChanges = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value='active' label="Active Storages" className={value !== 'active' ? 'unselected-subtab' : ''} />
          {
            this.state.canActOnStorage &&
              <Tab value='archived'  label="Archived Storages" className={value !== 'archived' ? 'unselected-subtab' : ''} />
          }
        </Tabs>
        <div className="subTab-container">
        {
          value === 'active' && <ActiveStorages canActOnStorage={this.state.canActOnStorage} farmId={this.props.farmId} farmName={this.props.farmName} canActOnFarm={this.props.canActOnFarm} />
        }
        {
          value === 'archived' && <ArchivedStorages {...this.props} />
        }
        </div>
      </div>
    );
  }
}

export default Storages;

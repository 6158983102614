import React from 'react';
import { connect } from 'react-redux';
import '../common/subTab.scss';

import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import { addCompanyGroup } from '../../actions/api/companies';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';

const types = [{id: 'fees', name: 'Fees'}]

class RenameCompanyGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: null,
      groupName: null,
      type: null,
      renamedGroup: false,
      businessTypes: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
  }

  componentDidMount() {
    APIService.companies().types().get().then(types => this.setState({businessTypes: types}));
  }
  onTypeChange = (value) => {
    this.setState({type: value})
  }
  onNameChange = (event) => {
    this.setState({groupName: event.target.value})
  }
  onGroupChange(value){
    let group = this.props.companyGroups.find(group => group.id == value)
    this.setState({selectedGroup: group})
  }

  handleSubmit() {
    if (this.props.companyGroups.find(group => group.name == this.state.groupName))
        alertifyjs.error("Group Name already exists")
    else {
      if (this.state.groupName && this.state.type && this.state.selectedGroup) {
        if (!this.state.renamedGroup) {
          let payload = { 
              'groupName': this.state.groupName,
              'groupType': this.state.type,
              'groupId': this.state.selectedGroup?.id,
          }
          this.props.dispatch(addCompanyGroup(this.props.userCompanyId, payload))
          this.setState({renamedGroup: true})
          this.props.closeDrawer()
          window.location.reload()
        }
      } else {
        alertifyjs.error("All fields are mandatory")
      }
    }
    
  }


  render() {
    const businessType = find(this.state.businessTypes, {id: this.state.selectedGroup?.businessTypeId})
    return (
      <div style={{ marginTop: '5px' }}>
        <form onSubmit={this.handleSubmit} noValidate>
        <CommonAutoSelect
            id='groups'
            label='Groups'
            style={{ wordWrap: "break-word", marginBottom: "5px" }}
            items={this.props.companyGroups}
            selectConfig={{ text: 'name', value: 'name' }}
            value={this.state.selectedGroup}
            onChange={this.onGroupChange}
            notClearable
            autoSelectSingleItem
          />
        <CommonTextField id='group_name' label='New Group Name' value={this.state.groupName} onChange={this.onNameChange} />
        
        <CommonAutoSelect
            id='type'
            label='Type'
            placeholder='Type'
            items={types}
            fullWidth
            onBlur={this.onFieldBlur}
            onChange={this.onTypeChange}
            value={this.state.type}
            style={{marginTop: '10px'}}
        />

        <CommonTextField id='businessType' label='Business Type' value={businessType?.displayName} disabled style={{ marginTop: '8px' }}/>

        <div style={{ float: 'right', top: '20px', marginTop: '10px', marginRight: "-10px"}}>
            <CommonButton type='button' variant='outlined' label='Cancel' default onClick={this.props.closeDrawer} />
            <CommonButton primary={true} variant="contained" label='Submit' type='submit' />
        </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    userCompanyId: state.main.user.user.companyId,
    companies: state.companies.companies.items,
    companyParties: state.companies.companies.companyParties,
    companyGroups: state.companies.companies.companyGroups
  });

export default connect(mapStateToProps)(RenameCompanyGroup);
import React from 'react';


class LoaderInline extends React.Component {
  render() {
    const {containerClassName} = this.props;
    return (
      <div
        style={this.props.style}
        className={containerClassName ? containerClassName + " loader-inline" : "loader-inline"}>
        <img style={ this.props.imageStyle } src='images/loader.gif' />
        {
          this.props.text &&
            <div style={{color: 'gray', fontSize: '14px'}}>{this.props.text}</div>
        }
      </div>
    );
  }
}

export default LoaderInline;

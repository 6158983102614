import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Paper, TextField, Tooltip, Autocomplete, CircularProgress, Table, TableHead, TableBody, TableCell, TableRow } from '@mui/material';
import Grow from '@mui/material/Grow';
import { values, sum, debounce, isEmpty, map, reject, orderBy } from 'lodash';
import { PRIMARY_COLOR_GREEN, WHITE, BLACK } from '../../common/constants';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import APIService from '../../services/APIService';
import TimeButtonGroup from '../common/TimeButtonGroup';

const FONT_SIZE = '12px'


const Bar = ({width, bgColor, title, count, color, onClick}) => (
  <Tooltip arrow title={`${title} - (${count})`}>
    <div onClick={onClick} className='padding-reset' style={{background: bgColor, height: '100px', width: width, display: 'inline-flex', alignItems: 'center', justifyContent: 'center', fontSize: FONT_SIZE, color: color || BLACK, cursor: onClick ? 'pointer' : 'auto'}}>
      {title}<br/>{count}
    </div>
  </Tooltip>
)

class Dockets extends React.Component {
  constructor(props) {
    super(props);
    this.defaultHours = 6
    this.defaultSelected = `${this.defaultHours}h`
    const now = moment()
    const sixHoursFromNow = moment().subtract(this.defaultHours, 'hours')
    this.state = {
      selectedTimeKey: this.defaultSelected,
      dateFrom: sixHoursFromNow.toDate().toISOString(),
      dateTo: now.toDate().toISOString(),
      companyIds: undefined,
      stats: {},
      statsPendingByCustomer: [],
      statsFinishedByCustomer: [],
      isLoading: true,
      isLoadingPendingByCustomer: false,
      isLoadingFinishedByCustomer: false,
      isLoadingCompanies: false,
      companies: [],
      companyInputText: '',
      selectedCompanies: [],
      anchorEl: null,
      isCustomApplied: false,
    }
  }

  componentDidMount() {
    this.fetchStats()
    this.props.dispatch(setHeaderText('Dockets'));
    this.props.dispatch(setBreadcrumbs([{text: 'Dockets'}]));
  }

  getQueryParams = () => {
    const { dateFrom, dateTo, selectedCompanies } = this.state;
    return {date_from: dateFrom, date_to: dateTo, company_ids: map(selectedCompanies, 'id').join(',')}
  }

  fetchStats = () => {
    this.setState({isLoading: true, stats: {}, statsPendingByCustomer: [], statsFinishedByCustomer: []}, () => {
      APIService.freights().contracts().appendToUrl('dockets/stats/').get(null, null, this.getQueryParams()).then(response => this.setState({stats: {...response, total: sum(values(response))}, isLoading: false}))
    })
  }

  fetchStatsPendingByCustomer = () => {
    this.setState({isLoadingPendingByCustomer: true, statsPendingByCustomer: []}, () => {
      APIService.freights().contracts().appendToUrl('dockets/stats/pending-by-customer/').get(null, null, this.getQueryParams()).then(response => this.setState({statsPendingByCustomer: response.data, isLoadingPendingByCustomer: false}))
    })
  }

  fetchStatsFinishedByCustomer = () => {
    this.setState({isLoadingFinishedByCustomer: true, statsFinishedByCustomer: []}, () => {
      APIService.freights().contracts().appendToUrl('dockets/stats/finished-by-customer/').get(null, null, this.getQueryParams()).then(response => {
        const outloads = map(response.outloads, data => ({...data, type: 'outload'}))
        const inloads = map(response.inloads, data => ({...data, type: 'inload'}))
        this.setState({statsFinishedByCustomer: [...inloads, ...outloads], isLoadingFinishedByCustomer: false})
      })
    })
  }

  getWeight = stat => {
    const { stats } = this.state
    const total = stats.total || 0
    if(total && stat)
      return (stat * 100)/total

    return 0
  }

  onTimeChange = (event, key) => {
    let hours = 1
    let anchorEl = null
    let isCustomApplied = false
    if(key.endsWith('h'))
      hours = parseInt(key.replace('h'))
    else if(key.endsWith('d'))
      hours = 24 * parseInt(key.replace('d'))
    else if (key === 'custom') {
      isCustomApplied = this.state.isCustomApplied
      anchorEl = event.currentTarget
      hours = false
    }

    this.setState({
      selectedTimeKey: key,
      dateFrom: hours ? moment().subtract(hours, 'hours').toDate().toISOString() : this.state.dateFrom,
      dateTo: hours ? moment().toDate().toISOString() : this.state.dateTo,
      anchorEl: anchorEl,
      isCustomApplied: isCustomApplied
    }, () => !anchorEl && this.fetchStats())
  }

  onClear = () => {
    const now = moment()
    const sixHoursFromNow = moment().subtract(this.defaultHours, 'hours')
    this.setState({selectedTimeKey: this.defaultSelected, dateFrom: sixHoursFromNow.toDate().toISOString(), dateTo: now.toDate().toISOString(), anchorEl: null, isCustomApplied: false}, this.fetchStats)
  }

  onApply = () => this.setState({anchorEl: null, isCustomApplied: true}, this.fetchStats)

  onTimeFieldChange = event => this.setState({[event.target.id]: moment(event.target.value).toDate().toISOString()})

  handleCompanyChange = (event, items) => this.setState({selectedCompanies: isEmpty(items) ? [] : items}, this.fetchStats);

  handleCompanyInputChange = debounce((event, value, reason) => {
    this.setState({companyInputText: value}, () => {
      if(reason !== 'reset' && value && value.length > 2)
        this.setState({isLoadingCompanies: true}, () => this.fetchCompanies(value))
      else
        this.setState({isLoadingCompanies: false})
    })
  }, 300);

  fetchCompanies = searchStr => APIService
    .companies()
    .appendToUrl('minimal/registered/')
    .get(this.props.token, null, searchStr ? {search: searchStr} : {})
    .then(items => this.setState({companies: items, isLoadingCompanies: false}));

  render() {
    const {
      selectedTimeKey, stats, isLoading, companies, isLoadingCompanies, companyInputText,
      selectedCompanies, statsPendingByCustomer, statsFinishedByCustomer, anchorEl, dateFrom, dateTo,
      isCustomApplied
    } = this.state
    const total = stats.total
    const isSearchableInput = companyInputText?.length > 2
    return (
      <Paper className='col-xs-12' style={{marginBottom: '5px', padding: '20px', minHeight: '300px'}}>
        <div className='col-xs-6 padding-reset' style={{textAlign: 'left'}}>
          <Autocomplete
            multiple
            size='small'
            id="company"
            blurOnSelect
            options={companies || []}
            getOptionLabel={option => option.name}
            loading={isLoadingCompanies}
            loadingText={isSearchableInput ? 'Loading...' : 'Type at least 3 characters to search'}
            noOptionsText={companyInputText ? "No results" : 'Start typing...'}
            renderInput={params => (
              <TextField
                {...params}
                value={companyInputText}
                variant="outlined"
                placeholder="Company (Type at least 3 characters to search)"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                fullWidth
              />
            )}
            value={selectedCompanies}
            onChange={this.handleCompanyChange}
            onInputChange={this.handleCompanyInputChange}
          />
        </div>
        <div className='col-xs-6 padding-reset' style={{textAlign: 'right'}}>
          <TimeButtonGroup selected={selectedTimeKey} onClick={this.onTimeChange} onReload={this.fetchStats} isCustomApplied={isCustomApplied} dateFrom={dateFrom} dateTo={dateTo} anchorEl={anchorEl} onClear={this.onClear} onApply={this.onApply} onTimeFieldChange={this.onTimeFieldChange} />
        </div>
        <div className='col-xs-12 padding-reset' style={{textAlign: 'center', padding: '40px 0'}}>
          <Grow
            in={!isLoading}
            style={{ transformOrigin: '0 0 0' }}
            {...(!isLoading ? { timeout: 1000 } : {})}
          >
            <div className='col-xs-12 padding-reset'>
              {
                Boolean(stats.finished) &&
                  <Bar bgColor={PRIMARY_COLOR_GREEN} width={`${this.getWeight(stats.finished)}%`} title="Finished" count={stats.finished} color={WHITE} onClick={this.fetchStatsFinishedByCustomer} />
              }
              {
                Boolean(stats.pending) &&
                  <Bar bgColor='rgba(0, 0, 0, 0.1)' width={`${this.getWeight(stats.pending)}%`} title="Pending" count={stats.pending} onClick={this.fetchStatsPendingByCustomer} />
              }
              <div className='col-xs-12 padding-reset' style={{textAlign: 'center', fontSize: FONT_SIZE, margin: '10px 0'}}>
                {`Total ${total}`}
              </div>
            </div>
          </Grow>
        </div>
        <div className='col-xs-6'>
          {
            !isEmpty(statsFinishedByCustomer) &&
              <Table aria-label="simple table" size="small" sx={{ maxWidth: 650, float: 'right' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Customer</TableCell>
                    <TableCell align="left">Finished</TableCell>
                    <TableCell align="left">Finished By</TableCell>
                    <TableCell align="left">Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    map(orderBy(reject(statsFinishedByCustomer, {count: 0}), 'count', 'desc'), (info, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">{info.customer_company_businessName}</TableCell>
                          <TableCell align="center">{info.count}</TableCell>
                          <TableCell align="left">{info.meta_inloadDocketEntryFinishedBy_alias || info.meta_inloadDocketEntryFinishedBy_username || info.meta_outloadDocketEntryFinishedBy_alias || info.meta_outloadDocketEntryFinishedBy_username}</TableCell>
                          <TableCell align="left">{info.type}</TableCell>
                        </TableRow>
                      )

                    })
                  }
                </TableBody>
              </Table>
          }
        </div>
        <div className='col-xs-6'>
          {
            !isEmpty(statsPendingByCustomer) &&
              <Table aria-label="simple table" size="small" sx={{ maxWidth: 650, float: 'right' }}>
                <TableHead>
                  <TableRow>
                    <TableCell align='left'>Customer</TableCell>
                    <TableCell align="left">Pending</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    map(orderBy(reject(statsPendingByCustomer, {count: 0}), 'count', 'desc'), (info, index) => {
                      return (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell align="left">{info.customer_company_businessName}</TableCell>
                          <TableCell align="center">{info.count}</TableCell>
                        </TableRow>
                      )

                    })
                  }
                </TableBody>
              </Table>
          }
        </div>
      </Paper>
    )
  }
}

export default connect()(Dockets);

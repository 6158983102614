import { connect } from 'react-redux';
import EmployeeForm from '../components/employees/EmployeeForm';
import {createAndGetContact, createEmployee} from '../actions/api/employees';
import { addEmployee } from '../actions/company-settings/employees';

function submit(companyId, data, establishmentDetails, getContacts, callback) {
  return (dispatch) => {
    if (establishmentDetails && getContacts) {
      dispatch(createAndGetContact(companyId, data, getContacts, establishmentDetails, callback));
    } else {
      dispatch(createEmployee(companyId, data, addEmployee, callback));
    }
  };
}

export default connect(null, { submit })(EmployeeForm);

import React, { useEffect } from 'react';
import alertifyjs from "alertifyjs";
import { Dialog, DialogContent, Button, DialogActions, TextField } from '@mui/material';
import CommonDatePicker from '../common/CommonDatePicker';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { useState } from 'react';
import CommonSelect from '../common/select/CommonSelect';
import { get, map, isEmpty, filter, forEach, find, some, forOwn } from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch } from 'react-redux';
import { getDateTimeInUTC, getDateTimeFromUTC, generateIdentifier, getSeasons, getCountryLabel, getCountryDisplayUnit } from "../../common/utils";
import { transferStocksStorageToStorage, transferStocksStorageToStorageUpdate } from '../../actions/companies/storages';
import moment from 'moment';
import CommonTimePicker from '../common/CommonTimePicker';
import CommonTextField from '../common/CommonTextField';
import SpecParametersValue from '../common/SpecParametersValue';
import { forceStopLoader, isLoading } from '../../actions/main';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import { MESSAGES, valueBetween } from '../../common/validators';
import mapValues from 'lodash/mapValues';
import { FIELD } from '../../common/constants';
import set from 'lodash/set';

let StorageToStorageTransferDialog = props => {
  const displayUnit = getCountryDisplayUnit()
  let [combinedStates, setCombinedStates] = useState({
    'farm_id': get(props.item, 'farmId', get(props.item, 'stocks.farmId')),
    'date': '',
    'time': '',
    'tonnage': '',
    'comment': '',
    'identifier': '',
    'quantity': '',
  });

  let [outload, setOutload] = useState({
    'storage_id': '',
    'commodity_id': '',
    'grade_id': '',
    'variety_id': '',
    'season': '',
    'specs': {},
    'id': '',
  });

  let [inload, setInload] = useState({
    'storage_id': '',
    'commodity_id': '',
    'grade_id': '',
    'variety_id': '',
    'season': '',
    'specs': {},
    'id': '',
  });

  let [allStorages, setAllStorages] = useState({});
  let [farmStorages, setFarmStorages] = useState([]);
  let [allCommodities, setAllCommodities] = useState({});
  let [inloadGrades, setInloadGrades] = useState([]);
  let [outloadGrades, setOutloadGrades] = useState([]);
  let [allSeason, setSeason] = useState([]);
  let [outloadVarieties, setOutloadVarieties] = useState([]);
  let [inloadVarieties, setInloadVarieties] = useState([]);
  let [errors, setErrors] = useState({
    'tonnage': '',
    'identifier': '',
    'inload_storage_id': '',
    'outload_storage_id': '',
    'inload_commodity_id': '',
    'outload_commodity_id': '',
    'inload_grade_id': '',
    'outload_grade_id': '',
    'inload_season': '',
    'outload_season': '',
    'date': '',
    'time': '',
    'quantity': '',
  });
  let [isCallInProgress, setIsCallInProgress] = useState(false);
  let [submitDisabled, setSubmitDisabled] = useState(false);
  let [totalTonnageOfStorageLoadedFrom, setTotalTonnageOfStorageLoadedFrom] = useState(0);
  let [estimatedNetWeight, setEstimatedNetWeight] = useState(0);

  let initialSetup = async () => {
    if (props.isEdit) {
      dispatch(isLoading());
        let date_time = getDateTimeFromUTC(get(props.item, 'dateTime'));
      getAllStorages();
      let commodityData = await APIService.commodities(get(props.item, 'commodityId')).appendToUrl('interchangeable-commodities/').get(props.token, null);
      commodityData.map(val => {
        val.grades.map(val2 => {
          return { name: val2.name, parent: val.id, id: val2.id };
        });
      });
      let outloadGrades = [];
      let outloadVarieties = [];
      let outloadCommodity = filter(commodityData, obj => obj.id == get(props.item, 'commodityId'));
      outloadCommodity.forEach(val => {
        outloadGrades = [...val.grades];
        outloadVarieties = [...val.varieties];
      });
      setAllCommodities(commodityData);
      if (get(props.item, 'type') == 'outload') {
        setOutloadGrades(outloadGrades);
        setOutloadVarieties(outloadVarieties);
      }
      else {
        setInloadGrades(outloadGrades);
        setInloadVarieties(outloadVarieties);
      }
      let counter_load = await APIService.loads(get(props.item, 'id')).appendToUrl('dependent-load/').get();
      let inloadCommodityData = {};
      setTotalTonnageOfStorageLoadedFrom(counter_load.totalTonnage);
      setEstimatedNetWeight(counter_load.estimatedNetWeight);

      if(counter_load.commodityId == props.item.commodityId ) {
        inloadCommodityData = outloadCommodity;
      } else if (counter_load?.commodityId){
        inloadCommodityData = await APIService.commodities(counter_load.commodityId).appendToUrl('interchangeable-commodities/').get(props.token, null);
      }
      inloadCommodityData.map(val => {
        val.grades.map(val2 => {
          return { name: val2.name, parent: val.id, id: val2.id };
        });
      });
      let grades = [];
      let varieties = [];
      let commodity = filter(inloadCommodityData, obj => obj.id == get(counter_load, 'commodityId'));
      commodity.forEach(val => {
        grades = [...val.grades];
        varieties = [...val.varieties];
      });
      if (get(props.item, 'type') == 'inload') {
        setInloadGrades(outloadGrades);
        setInloadVarieties(outloadVarieties);
        setOutloadGrades(grades);
        setOutloadVarieties(varieties);
      } else {
        setInloadGrades(grades);
        setInloadVarieties(varieties);
        setOutloadGrades(outloadGrades);
        setOutloadVarieties(outloadVarieties);
      }

      const specs = get(commodity[0], 'specs', [])
      let commoditySpecValues = get(counter_load, 'specs')
      let outloadModelsSpecs = {};
      let inloadModelsSpecs = {};
      forEach(specs, spec => {
        const validators = [valueBetween(spec.min, spec.max, true)];
        outloadModelsSpecs[spec.code] = {
          ...FIELD,
          value: get(commoditySpecValues, spec.code.toLowerCase()),
          validators: validators
        };
        inloadModelsSpecs[spec.code] = {
          ...FIELD,
          value: get(get(props.item, 'specs'), spec.code.toLowerCase()),
          validators: validators
        }
      });

      if (get(props.item, 'type') == 'inload') {
        setOutload({
          storage_id: get(counter_load, 'storageId'), commodity_id: get(counter_load, 'commodityId'),
          grade_id: get(counter_load, 'gradeId'), variety_id: get(counter_load, 'varietyId'),
          season: get(counter_load, 'season'), specs: outloadModelsSpecs,
          id: get(counter_load, 'id'),
        });
        setInload({
          storage_id: get(props.item, 'storageId'), commodity_id: get(props.item, 'commodityId'),
          grade_id: get(props.item, 'gradeId'), variety_id: get(props.item, 'varietyId'),
          season: get(props.item, 'season'), specs: inloadModelsSpecs,
          id: get(props.item, 'id'),
        });
      } else {
        setOutload({
          storage_id: get(props.item, 'storageId'), commodity_id: get(props.item, 'commodityId'),
          grade_id: get(props.item, 'gradeId'), variety_id: get(props.item, 'varietyId'),
          season: get(props.item, 'season'), specs: inloadModelsSpecs,
          id: get(props.item, 'id'),
        });
        setInload({
          storage_id: get(counter_load, 'storageId'), commodity_id: get(counter_load, 'commodityId'),
          grade_id: get(counter_load, 'gradeId'), variety_id: get(counter_load, 'varietyId'),
          season: get(counter_load, 'season'), specs: outloadModelsSpecs,
          id: get(counter_load, 'id'),
        });
      }
      setCombinedStates({
        identifier: get(props.item, 'extras.identifier'),
        comment: get(props.item, 'extras.comment'),
        farm_id: get(props.item, 'farmId'),
        date: date_time.date,
        time: date_time.time,
        tonnage: get(props.item, 'tonnage', get(props.item, 'netWeight')),
        quantity: get(counter_load, 'quantity'),
      });
    }
    else {
      dispatch(isLoading());
      const commodityId = props.item?.stocks?.commodityId || props.item?.commodityId
      const gradeId = props.item?.stocks?.gradeId || props.item?.gradeId
      const season = props.item?.stocks?.season || props.item?.season
      const storageId = props.item?.id || props.item?.storageId
      const varietyId = props.item?.varietyId
      let commodityData = commodityId ? await APIService.commodities(commodityId).appendToUrl('interchangeable-commodities/').get(props.token, null) : [];
      commodityData.map(val => {
        val.grades.map(val2 => {
          return { name: val2.name, parent: val.id, id: val2.id };
        });
      });
      let storageSpecs = await APIService.storages(storageId).appendToUrl(`estimate_specs_grade?commodity_id=${commodityId}`).get();
      setAllCommodities(commodityData);
      if (commodityId) {
        let grades = [];
        let varieties = [];
        let commodity = filter(commodityData, {id: commodityId});
        commodity.forEach(val => {
          grades = [...val.grades];
          varieties = [...val.varieties];
        });
        setOutloadGrades(grades);
        setOutloadVarieties(varieties);
        setInloadGrades(grades);
        setInloadVarieties(varieties);
        let specs = get(commodity, '0.specs');
        let modelsSpecs = {};
        forEach(specs, spec => {
          let specValue = get(storageSpecs, `specs.${spec.code.toLowerCase()}`, false) ? parseFloat(get(storageSpecs, `specs.${spec.code.toLowerCase()}`, 0)).toFixed(2) : null;
          const validators = [valueBetween(spec.min, spec.max, true)];
          modelsSpecs[spec.code] = {
            ...FIELD,
            value: specValue,
            validators: validators
          };
        });
        setOutload({
          storage_id: storageId, commodity_id: commodityId,
          grade_id: gradeId, variety_id: varietyId,
          season: season, specs: modelsSpecs
        });
        setInload({
          storage_id: null, commodity_id: commodityId,
          grade_id: gradeId, variety_id: varietyId,
          season: season, specs: modelsSpecs
        });
      };
      const startDateTime = getDateTimeFromUTC(Date().toLocaleString());
      setCombinedStates({
        identifier: generateIdentifier('storageToStorage'),
        date: startDateTime.date,
        time: startDateTime.time,
      });
    }
    dispatch(forceStopLoader());
  };

  let getStorageIdName = storage => {
    return {
      id: storage.id,
      name: storage.name
    };
  };

  let getAllStorages = () => {
    const { farm_id } = combinedStates;
    if (!isEmpty(get(allStorages, farm_id))) {
      setFarmStorages(get(allStorages, farm_id));
    }
    else {
      APIService
        .farms(farm_id)
        .storages()
        .appendToUrl('home/?no_stocks&no_aggregations&no_relations')
        .get(props.token)
        .then(storages => {
          storages = storages.filter(storage => storage.type !== 'container')
          let data = map(storages, getStorageIdName);
          setAllStorages({ [farm_id]: data });
          setFarmStorages(data);
        });
    }
  };

  useEffect(() => {
    if (combinedStates.farm_id)
      getAllStorages();
  }, [combinedStates.farm_id]);

  useEffect(() => {
    if(! isCallInProgress) {
      initialSetup();
      getSeason();
      setIsCallInProgress(true);
    }
  }, []);

  let onCommodityChange = async (val, id, item) => {
    if (val !== get(inload, 'commodity_id')) {
      if (isEmpty(val) && !val)
        setErrors({ ...errors, inload_commodity_id: MESSAGES.REQUIRED });
      else
        setErrors({ ...errors, inload_commodity_id: '' });
      let specs = get(item, 'specs', []);
      let modelsSpecs = {};
      forEach(specs, spec => {
        const validators = [valueBetween(spec.min, spec.max, true)];
        modelsSpecs[spec.code] = {
          ...FIELD,
          value: null,
          validators: validators
        };
      });
      setInload({ ...inload, 'commodity_id': val, 'specs': modelsSpecs });
      let grades = [...item.grades];
      let varieties = [...item.varieties];
      setInloadGrades(grades);
      setInloadVarieties(varieties);
    }
  };

  let handleInloadFieldChange = async (val, id) => {
    if (val !== get(inload, id)) {
      setInload({ ...inload, [id]: val });
      if (isEmpty(val) && !val)
        setErrors({ ...errors, ['inload_' + id]: MESSAGES.REQUIRED });
      else
        setErrors({ ...errors, ['inload_' + id]: '' });
    }
  };

  let handleOutloadFieldChange = async (val, id) => {
    if (val !== get(outload, id)) {
      setOutload({ ...outload, [id]: val });
      if (isEmpty(val) && !val)
        setErrors({ ...errors, ['outload_' + id]: MESSAGES.REQUIRED });
      else
        setErrors({ ...errors, ['outload_' + id]: '' });
    }
  };

  let getSeason = () => setSeason(map(getSeasons(), season => ({id: season, name: season})));

  let onCombinedStateChange = async (val, id) => {
    setCombinedStates({ ...combinedStates, [id]: val });
    if (isEmpty(val) && !val)
      setErrors({ ...errors, [id]: MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, [id]: '' });
  };

  let onTonnageChange = event => {
    setCombinedStates({ ...combinedStates, 'tonnage': event.target.value });
    if (isEmpty(event.target.value) && !event.target.value)
      setErrors({ ...errors, tonnage: MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, tonnage: '' });
  }

  let setQuantityErrors = quantity => {
    if (hasQuantityBasedCommodity(inload.commodity_id)) {
      if (Number(quantity) > get(props.item, 'quantity'))
        setErrors({ ...errors, 'quantity': 'Cannot be greater than existing quantity'});
      else
        setErrors({ ...errors, 'quantity': '' });
    }
  }

  let onQuantityChange = event => {
    let quantity = event.target.value;
    setCombinedStates({ ...combinedStates, 'quantity': quantity });
    setQuantityErrors(quantity);
  }

  let handleOutloadSpecsChange = (specCode, specValue) => {
    if (get(outload, `specs.${specCode}`) !== specValue) {
      let validators = get(outload, `specs.${specCode}.validators`, [])
      let errors = [];
      validators.forEach((validator) => {
        if (validator.isInvalid(specValue)) {
          errors.push(validator.message);
        }
      });
      setOutload({ ...outload, specs: {
        ...outload.specs,
          [specCode]: {
            ...outload.specs[specCode],
            value: specValue,
            errors: errors
          }
        }
      });
    }
  };

  let handleInloadSpecsChange = (specCode, specValue) => {
    if (get(inload, `specs.${specCode}`) !== specValue) {
      let validators = get(inload, `specs.${specCode}.validators`, [])
      let errors = [];
      validators.forEach((validator) => {
        if (validator.isInvalid(specValue)) {
          errors.push(validator.message);
        }
      });
      setInload({ ...inload, specs: {
        ...inload.specs,
          [specCode]: {
            ...inload.specs[specCode],
            value: specValue,
            errors: errors
          }
        }
      });
    }
  };

  const dispatch = useDispatch();

  let tonnageOfPrevStorage= (totalTonnageOfStorageLoadedFrom + estimatedNetWeight)
  let availableTonnage =  props.isEdit ? tonnageOfPrevStorage : (props.item?.tonnage || get(props.item, 'stocks.totalTonnage'))

  let handleSubmit = async () => {
    setSubmitDisabled(true);
    let inloadCopy = Object.assign({}, inload);
    let outloadCopy = Object.assign({}, outload);
    let combinedStateCopy = Object.assign({}, combinedStates);
    let errors = {
      'tonnage_error': '',
      'inload_storage_id': '',
      'outload_storage_id': '',
      'inload_commodity_id': '',
      'outload_commodity_id': '',
      'inload_grade_id': '',
      'outload_grade_id': '',
      'inload_season': '',
      'outload_season': '',
      'date': '',
      'time': '',
      'identifier_error': ''
    };
    if (combinedStateCopy.identifier && get(props.item, 'extras.identifier', '').toUpperCase() != combinedStateCopy.identifier.toUpperCase())
      await APIService.loads().appendToUrl(`identifier/${combinedStateCopy.identifier}/`).get().then(
        res => { if (res) errors.identifier_error = 'Identifier already exists' }
      )
    if (!combinedStateCopy.identifier)
      errors.identifier_error = MESSAGES.REQUIRED
    if (!combinedStateCopy.tonnage)
      errors.tonnage_error = 'This field cannot be blank';
    else if (combinedStateCopy.tonnage == 0)
      errors.tonnage_error = `${getCountryLabel('tonnage')} must be greater than 0`;
    else if (availableTonnage.toFixed(2) < Number(combinedStateCopy.tonnage))
      errors.tonnage_error = `Cannot be greater than existing ${getCountryLabel('tonnage')}`;
    if (!inloadCopy.storage_id)
      errors.inload_storage_id = "This Field cannot be blank";
    if (!inloadCopy.commodity_id)
      errors.inload_commodity_id = "This Field cannot be blank";
    if (!inloadCopy.grade_id || inloadCopy.grade_id === 'Multiple')
      errors.inload_grade_id = "This Field cannot be blank";
    if (!inloadCopy.season)
      errors.inload_season = "This Field cannot be blank";
    if (!outloadCopy.storage_id)
      errors.outload_storage_id = "This Field cannot be blank";
    if (!outloadCopy.commodity_id)
      errors.outload_commodity_id = "This Field cannot be blank";
    if (!outloadCopy.grade_id || outloadCopy.grade_id === 'Multiple')
      errors.outload_grade_id = "This Field cannot be blank";
    if (!outloadCopy.season)
      errors.outload_season = "This Field cannot be blank";
    if (!combinedStateCopy.time || combinedStateCopy.time === 'Invalid date')
      errors.time = "This Field cannot be blank";
    if (!combinedStateCopy.date)
      errors.date = "This Field cannot be blank";

    errors.specErrors = []
    let inloadSpecs = get(inloadCopy, 'specs')
    forOwn(inloadSpecs, item => errors.specErrors = [...errors.specErrors, ...item.errors] )

    let outloadSpecs = get(outloadCopy, 'specs')
    forOwn(outloadSpecs, item => errors.specErrors = [...errors.specErrors, ...item.errors] )

    if(isEmpty(errors.specErrors)) {
      let updatedOutloadSpecs = {}
      forOwn(outloadSpecs, (item, key) => updatedOutloadSpecs[key] = item.value )
      set(outloadCopy, 'specs', updatedOutloadSpecs);

      let updatedInloadSpecs = {}
      forOwn(inloadSpecs, (item, key) => updatedInloadSpecs[key] = item.value )
      set(inloadCopy, 'specs', updatedInloadSpecs);
    }

    setErrors(() => {
      let newErrors = {
        ...errors, tonnage: errors.tonnage_error, inload_storage_id: errors.inload_storage_id,
        inload_commodity_id: errors.inload_commodity_id, inload_grade_id: errors.inload_grade_id,
        inload_season: errors.inload_season, outload_storage_id: errors.outload_storage_id,
        outload_commodity_id: errors.outload_commodity_id, outload_grade_id: errors.outload_grade_id,
        outload_season: errors.outload_season, time: errors.time, date: errors.date,
        identifier: errors.identifier_error, specErrors: errors.specErrors
      }
      let isInvalid = some(newErrors, error => !isEmpty(error));
      if (!isInvalid) {
        const utcDateTime = getDateTimeInUTC(combinedStateCopy.date, combinedStateCopy.time);
        inloadCopy.date_time = utcDateTime.dateTime;
        outloadCopy.date_time = utcDateTime.dateTime;
        inloadCopy.estimated_net_weight = combinedStateCopy.tonnage;
        inloadCopy.quantity = combinedStateCopy.quantity;
        outloadCopy.quantity = combinedStateCopy.quantity;
        outloadCopy.estimated_net_weight = combinedStateCopy.tonnage;
        inloadCopy.comment = combinedStateCopy.comment;
        outloadCopy.comment = combinedStateCopy.comment;
        inloadCopy.identifier = combinedStateCopy.identifier.toUpperCase();
        outloadCopy.identifier = combinedStateCopy.identifier.toUpperCase();
        if (inload.storage_id == outload.storage_id && inload.commodity_id == outload.commodity_id
          && inload.grade_id == outload.grade_id) {
          alertifyjs.error('Storage and Commodity cannot be same for inload and outload');
        }
        else {
          if (props.isEdit)
            alertifyjs.confirm(
              'Warning',
              `<div className=""><ul>Are you sure you want to update?</ul></div>`,
              () => {
                dispatch(isLoading());
                dispatch(transferStocksStorageToStorageUpdate({ 'inload': inloadCopy, 'outload': outloadCopy }));
                props.toggleDialog();
              },
              () => setSubmitDisabled(false),
            );
          else {
            delete inloadCopy.id;
            delete outloadCopy.id;
            dispatch(isLoading());
            dispatch(transferStocksStorageToStorage({ 'inload': inloadCopy, 'outload': outloadCopy }));
          }
        }
      }
      else {
        setSubmitDisabled(false);
      }
      return newErrors;
    })
  };

  let handleDelete = () => {
    alertifyjs.confirm(
      'Warning',
      `<div className=""><ul>Are you sure you want to delete?</ul></div>`,
      () => {
        APIService.storages().appendToUrl(`silo-to-silo-transfer/?load_ids=${outload.id}&load_ids=${inload.id}`).delete().then(
          alertifyjs.success('Loads Deleted', 1, () => {
            window.location.reload();
            localStorage.setItem('stocks', JSON.stringify({}));
            props.toggleDialog();
          })
        );
      },
      () => { }
    );
  };

  let handleIdentifierChange = event => {
    if (isEmpty(event.target.value))
      setErrors({ ...errors, 'identifier': MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, 'identifier': '' });
    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      setCombinedStates({ ...combinedStates, 'identifier': event.target.value })
    }
  }

  let hasQuantityBasedCommodity = commodityId => Boolean(commodityId && find(allCommodities, {id: commodityId})?.isQuantityBased)

  const siteName = props.item?.farmName || props.item?.siteName || props.siteName

  return (
    <div>
      <Dialog open={props.isOpen} onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
        <DialogTitleWithCloseIcon onClose={props.toggleDialog} closeButtonStyle={{ marginTop: '0px' }} id='form-dialog-title'>
          Storage To Storage Transfer
        </DialogTitleWithCloseIcon>

        <DialogContent style={{ marginTop: '15px' }}>
          <div className="col-sm-12 form-wrap" style={{ marginTop: '20px' }}>
            <TextField
              id='identifier'
              label='Identifier'
              value={combinedStates.identifier}
              onChange={handleIdentifierChange}
              error={!isEmpty(errors.identifier)}
              helperText={errors.identifier}
              variant='standard'
              inputProps={{
                maxLength: 14,
              }}
              fullWidth
            />
          </div>
          <div className="col-sm-12 form-wrap" style={{ marginTop: '20px' }}>
            <CommonTextField id='farm' label='Farm/Site' value={ siteName } disabled />
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
            {props.isEdit && get(props.item, 'type') == 'inload' ?
             <div className="col-sm-6 form-wrap">
               <CommonAutoSelect
                 id='storage_id'
                 label='Outload Storage'
                 style={{ wordWrap: "break-word" }}
                 items={farmStorages}
                 selectConfig={{ text: 'name', value: 'id' }}
                 value={outload.storage_id}
                 onChange={handleOutloadFieldChange}
                 errorText={errors.outload_storage_id}
                 notClearable
                 disabled
               />
             </div> :
             <div className="col-sm-6 form-wrap">
               <CommonTextField id='outload_storage_id' label='Outload Storage' value={ props.isSiteLoadsView ? get(props.item, 'storage') : props.item?.storageName || props.item?.siteName} disabled />
             </div>
            }
            <div className="col-sm-6 form-wrap">
              <CommonAutoSelect
                id='storage_id'
                label='Inload Storage'
                style={{ wordWrap: "break-word" }}
                items={farmStorages}
                selectConfig={{ text: 'name', value: 'id' }}
                value={inload.storage_id}
                onChange={handleInloadFieldChange}
                errorText={errors.inload_storage_id}
                notClearable
              />
            </div>
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
            {!props.isEdit ?
             <div className="col-sm-6 form-wrap">
               <CommonTextField id='outload_commodity_id' label='Outload Commodity' value={find(allCommodities, {id: outload.commodity_id})?.displayName} disabled />
             </div> :
             <div className="col-sm-6 form-wrap">
               <CommonSelect
                 id="commodity_id"
                 items={allCommodities}
                 value={outload.commodity_id}
                 selectConfig={{ text: 'displayName', value: 'id' }}
                 floatingLabelText="Outload Commodity"
                 errorText={errors.outload_commodity_id}
                 disabled
               />
             </div>
            }
            <div className="col-sm-6 form-wrap">
              <CommonSelect
                id="commodity_id"
                items={allCommodities}
                value={inload.commodity_id}
                selectConfig={{ text: 'displayName', value: 'id' }}
                onChange={onCommodityChange}
                floatingLabelText="Inload Commodity"
                errorText={errors.inload_commodity_id}
              />
            </div>
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
            <div className="col-sm-6 form-wrap">
              <CommonAutoSelect
                id='grade_id'
                label='Outload Grade'
                style={{ wordWrap: "break-word" }}
                items={outloadGrades}
                selectConfig={{ text: 'name', value: 'id' }}
                value={outload.grade_id}
                onChange={handleOutloadFieldChange}
                errorText={errors.outload_grade_id}
                notClearable
              />
            </div>
            <div className="col-sm-6 form-wrap" >
              <CommonAutoSelect
                id='grade_id'
                label='Inload Grade'
                style={{ wordWrap: "break-word" }}
                items={inloadGrades}
                selectConfig={{ text: 'name', value: 'id' }}
                value={inload.grade_id}
                onChange={handleInloadFieldChange}
                errorText={errors.inload_grade_id}
                notClearable
              />
            </div>
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
            <div className="col-sm-6 form-wrap">
              <CommonAutoSelect
                id='variety_id'
                label='Outload Variety'
                style={{ wordWrap: "break-word" }}
                items={outloadVarieties}
                selectConfig={{ text: 'name', value: 'id' }}
                value={outload.variety_id}
                onChange={handleOutloadFieldChange}
                errorText={errors.outload_variety_id}
                notClearable
              />
            </div>
            <div className="col-sm-6 form-wrap">
              <CommonAutoSelect
                id='variety_id'
                label='Inload Variety'
                style={{ wordWrap: "break-word" }}
                items={inloadVarieties}
                selectConfig={{ text: 'name', value: 'id' }}
                value={inload.variety_id}
                onChange={handleInloadFieldChange}
                errorText={errors.inload_variety_id}
                notClearable
              />
            </div>
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
            <div className="col-sm-6 form-wrap">
              <CommonSelect
                id="season"
                items={allSeason}
                value={outload.season}
                onChange={handleOutloadFieldChange}
                errorText={errors.outload_season}
                floatingLabelText="Outload Season"
              />
            </div>
            <div className="col-sm-6 form-wrap">
              <CommonSelect
                id="season"
                items={allSeason}
                value={inload.season}
                onChange={handleInloadFieldChange}
                errorText={errors.inload_season}
                floatingLabelText="Inload Season"
              />
            </div>
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
            <div className='col-md-6 form-wrap'>
              <CommonDatePicker
                id="date"
                floatingLabelText='Transfer Date'
                onChange={onCombinedStateChange}
                value={combinedStates.date}
                minDate={moment().subtract(5, 'years').format('YYYY/MM/DD')}
                maxDate={moment().format('YYYY/MM/DD')}
                errorText={errors.date}/>
            </div>
            <div className='col-md-6 form-wrap'>
              <CommonTimePicker
                id='time'
                floatingLabelText='Transfer Time'
                onChange={onCombinedStateChange}
                value={combinedStates.time}
                errorText={errors.time}
              />
            </div>
          </div>
          <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
          <div className='col-md-6 form-wrap'>
              <CommonTextField
                id='availableTonnage'
                label={`Available ${getCountryLabel('tonnage')} (${displayUnit})`}
                value={availableTonnage.toFixed(2)}
                disabled
              />
            </div>
            <div className='col-md-6 form-wrap'>
              <CommonTextField
                id='tonnage'
                label={`Transfer ${getCountryLabel('tonnage')} (${displayUnit})`}
                onChange={onTonnageChange}
                value={combinedStates.tonnage}
                type='number'
                min="0.01"
                error={!isEmpty(errors.tonnage)}
                helperText={get(errors, 'tonnage')}
              />
            </div>
          </div>
          {
            hasQuantityBasedCommodity(inload.commodity_id) &&
            <div className='col-md-12 padding-reset' style={{ marginTop: '20px' }}>
              <div className='col-md-6 form-wrap'>
                <CommonTextField
                  id='quantity'
                  label={`Transfer Quantity (${find(allCommodities, {id: inload.commodity_id})?.unit})`}
                  onChange={onQuantityChange}
                  value={combinedStates.quantity}
                  type='number'
                  min="0"
                  error={!isEmpty(errors.quantity)}
                  helperText={get(errors, 'quantity')}
                />
              </div>
            </div>
          }
          <div className='col-md-6 form-wrap' style={{ marginTop: '20px' }}>{'Outload Specs'}</div>
          <div className='col-md-6 form-wrap' style={{ marginTop: '20px' }}>{'Inload Specs'}</div>
          <div className='col-md-6 form-wrap'>
            <SpecParametersValue
              commoditySpecs={get(find(allCommodities, { id: outload.commodity_id }), 'specs')}
              fieldsSpecs={outload.specs}
              className={"col-md-12"}
              onChange={handleOutloadSpecsChange}
              style={{ marginTop: '10px' }}
              errorTexts={mapValues(outload.specs, spec => get(spec, 'errors[0]', ''))}
            />
          </div>
          <div className='col-md-6 form-wrap'>
            <SpecParametersValue
              commoditySpecs={get(find(allCommodities, { id: inload.commodity_id }), 'specs')}
              fieldsSpecs={inload.specs}
              className={"col-md-12"}
              onChange={handleInloadSpecsChange}
              style={{ marginTop: '10px' }}
              errorTexts={mapValues(inload.specs, spec => get(spec, 'errors[0]', ''))}
            />
          </div>
          <div className="col-md-12 form-wrap top-30">
            <CommonTextField
              id="comment"
              label="Comment (Optional)"
              value={combinedStates.comment}
              onChange={(event) => setCombinedStates({ ...combinedStates, 'comment': event.target.value })}
              multiline={true}
              rows={2}
              rowsMax={2}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={props.toggleDialog} variant='outlined'>
            Cancel
          </Button>
          {props.isEdit &&
           <Button type='button' onClick={handleDelete} color='primary' variant='outlined'>
             Delete
           </Button>
          }
          <Button id="submit-btn" type='button' onClick={handleSubmit} color='primary' variant='outlined' disabled={submitDisabled}>
            {props.isEdit ? 'Update' : 'Confirm'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default StorageToStorageTransferDialog;

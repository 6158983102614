import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import './acceptRejectInvoice.scss';
import APIService from '../../services/APIService';

class UnregisteredUserConfirmInvoice extends Component{
  constructor(props) {
    super(props);
    this.state = {
      canConfirm: false,
      isConfirmed: false,
      invoice: undefined,
      loaded: false,
    };
    this.acceptInvoice = this.acceptInvoice.bind(this);
  }

  componentDidMount(){
    if(this.props.match && this.props.match.params.invoice_id) {
      APIService.invoices(this.props.match.params.invoice_id).get(this.props.match.params.salt)
        .then((json) => {
          if(json && json.canConfirmOrReject){
            this.acceptInvoice(json);
          }
        });
      const newState = {...this.state};
      newState.loaded = true;
      this.setState(newState);
    }
  }

  acceptInvoice(invoice){
    const salt = this.props.match ? this.props.match.params.salt : null;
    APIService.invoices(invoice.id)['confirm']().put({}, salt)
      .then((json) => {
        if(!isEmpty(json.errors)){
          this.setState({ errors: json.errors[0] });
        } else {
          this.setState({ isConfirmed: true });
        }
      });
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
              {this.state.isConfirmed ? (
                <span>Invoice accepted successfully.</span>
              ) : this.state.loaded && (
                <span>You do not have permission to access the information.</span>
              )}
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>;
  }
}

export default connect()(UnregisteredUserConfirmInvoice);

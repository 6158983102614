import React, { Component } from 'react';
import { connect } from 'react-redux';
import ApprovedBuyersTable from '../../containers/ApprovedBuyersTable';
import Paper from '@mui/material/Paper';
import SideDrawer from '../common/SideDrawer';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ListboxComponent } from '../common/ListboxComponent';
import { forceStopLoader, isLoading } from '../../actions/main';
import APIService from '../../services/APIService';
import { get, filter, includes } from 'lodash';
import AddButton from '../common/AddButton';
import { getApprovedBuyers } from '../../actions/company-settings/approved-buyers';
import CommonButton from '../common/CommonButton';
import { getCompanyIdFromCurrentRoute, isSiteEmployee } from '../../common/utils';

class ApprovedBuyers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applyFilters: false,
            openSideDrawer: false,
            approved_buyers: [],
            selectedBuyers: [],
        };
        this.handleFilters = this.handleFilters.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

  componentDidMount() {
    const companyId = getCompanyIdFromCurrentRoute() || this.props.user.companyId
        this.props.getApprovedBuyers();
      APIService.companies(companyId)
            .appendToUrl('approved-buyers/')
            .get()
            .then(res => {
              APIService
                .companies()
                .appendToUrl('directory/names/?excludeGroups=true')
                    .get().then(companyData => {
                        this.setState({
                            approved_buyers: get(res, 'companyIds', []),
                            companies: companyData
                        });
                    });
            });
    }

    handleFilters = bool => {
        this.setState({
            openSideDrawer: bool,
        });
    };

    handleChange = (key, value) => {
        let selectedBuyers = [];
        value.forEach(val => {
            selectedBuyers.push(val.id);
        });
        this.setState({ selectedBuyers: selectedBuyers });
    };

    handleSave() {
        if (this.state.selectedBuyers) {
          const companyId = getCompanyIdFromCurrentRoute() || this.props.user.companyId
            APIService.companies(companyId)
                .appendToUrl('approved-buyers/')
                .post({ company_ids: this.state.selectedBuyers }, this.props.token)
                .then(() => {
                    APIService.companies(companyId)
                        .appendToUrl('approved-buyers/')
                        .get()
                        .then(res => {
                            this.setState({
                                approved_buyers: get(res, 'companyIds', []),
                                openSideDrawer: false,
                            });
                        });
                  this.props.isLoading();
                  this.props.getApprovedBuyers();
                });
        };
    };

    render() {
        return (
            <Paper className='paper-table-paginated'>
              <div style={{ position: 'relative' }}>
                {
                  !isSiteEmployee() &&
                  <AddButton
                    variant="contained"
                    type='button'
                    label='Buyer'
                    onClick={() => this.handleFilters(true)}
                    color='primary'
                    className='add-button'
                  />
                }
                    <SideDrawer
                        isOpen={this.state.openSideDrawer}
                        onClose={this.handleFilters}
                        title='Buyers' size='big'>
                        <div style={{ paddingTop: '30px' }}>
                            <Autocomplete
                                multiple
                                disableCloseOnSelect
                                ListboxComponent={ListboxComponent}
                                id={'buyer'}
                                options={filter(this.state.companies, val => { return !includes(this.state.approved_buyers, val.id); }) || []}
                                getOptionLabel={option => option.name}
                                renderInput={params => <TextField {...params} variant='outlined' fullWidth />}
                                onChange={this.handleChange}
                            />
                            <div style={{ float: 'right', top: '10px' }}>
                                <CommonButton type='button' label='CLOSE' variant="contained" onClick={() => this.handleFilters(false)} />
                                <CommonButton type='submit' label='SAVE' primary={true} variant="contained" onClick={this.handleSave} />
                            </div>
                        </div>
                    </SideDrawer>
                    <ApprovedBuyersTable />
                </div>


            </Paper>
        );
    }
};

const mapStateToProps = state => {
    return {
        user: state.main.user.user,
    };
};

const mapDispatchToProps = dispatch => ({
  getApprovedBuyers: () => dispatch(getApprovedBuyers()),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApprovedBuyers);

import { connect } from 'react-redux';
import SystemStorageForm from '../components/storages/SystemStorageForm';
import { createSystemStorage } from '../actions/api/storages';
import { addSystemStorage } from '../actions/companies/storages';

function submit(farmId, data, callback) {
  return (dispatch) => {
    dispatch(createSystemStorage(farmId, data, callback, addSystemStorage));
  };
}

export default connect(null, { submit })(SystemStorageForm);

import React from 'react';
import { GoogleMap, StandaloneSearchBox, Marker, useJsApiLoader } from '@react-google-maps/api';

const LIBRARIES = ['places']
const GoogleMapSearchBox = ({
  searchBoxText, onSearchBoxChange, center, onMarkerDragEnd, onPlacesChanged
}) => {
  useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyD7fXBq6bEoFpMvKq7mRsRu_ewTmtBY42I",
    libraries: LIBRARIES
  })

  const [searchBox, setSearchBox] = React.useState(null);
  return (
    <div className='map'>
      <div className='map-container'>

        <GoogleMap
          id='map-search-box'
          zoom={15}
          center={center}
          defaultZoom={15}
          defaultOptions={{
            draggable: true,
            zoomControl: true,
            mapTypeId: 'hybrid',
          }}
        >
          <StandaloneSearchBox onLoad={ref => setSearchBox(ref)} onPlacesChanged={() => onPlacesChanged(searchBox)}>
            <input
              type='text'
              placeholder='Search...'
              style={{
                boxSizing: `border-box`,
                border: `1px solid transparent`,
                width: `300px`,
                height: `32px`,
                marginTop: `12px`,
                marginLeft: `12px`,
                padding: `0 12px`,
                borderRadius: `3px`,
                boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                fontSize: `14px`,
                outline: `none`,
                position: 'absolute',
                left: '200px',
                textOverflow: `ellipses`,
              }}
              value={searchBoxText}
              onChange={onSearchBoxChange}
            />
          </StandaloneSearchBox>
          <Marker
            draggable={true}
            position={center}
            onDragEnd={event => onMarkerDragEnd(event)}
          />
        </GoogleMap>
      </div>
    </div>
  );
};

export default GoogleMapSearchBox;

import {
  START_FETCHING_VARIETIES,
  RESET_IS_FETCHING_VARIETIES,
  RECEIVE_VARIETIES,
} from '../../actions/master/varieties';
import {
  RECEIVE_COMMODITIES_WITH_VARIETIES,
  RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES,
} from '../../actions/master/commodities';
import flatten from 'lodash/flatten';

const initialState = {
  isFetchingVarieties: false,
  items: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING_VARIETIES:
      return { ...state, isFetchingVarieties: true };
    case RESET_IS_FETCHING_VARIETIES:
      return { ...state, isFetchingVarieties: false };
    case RECEIVE_VARIETIES:
      return { ...state, items: action.items, isFetchingVarieties: false };
    case RECEIVE_COMMODITIES_WITH_VARIETIES:
    case RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES:
      return { ...state, items: state.items.length ? state.items : flatten(action.items.map(item => item.varieties)) };
    default:
      return state;
  }
};

export default reducer;

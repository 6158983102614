import React from 'react';
import {
  Dialog, DialogContent, List,
} from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { map, capitalize, isEmpty } from 'lodash';
import './slotHistory.scss';
import { toDateTimeFormat } from '../../common/utils'

let SlotVendorDec = ({ parentLink, onClose, show, vendorDecs }) => {
  return (
    <div>
      <Dialog
        open={show}
        keepMounted
        className='slot-comments-dialog'
        onClose={onClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitleWithCloseIcon onClose={onClose} style={{ height: '55px' }}>
          <div style={{ marginTop: '-4px' }}>Vendor Declarations</div>
        </DialogTitleWithCloseIcon>
        <DialogContent style={{ padding: '0px', marginLeft: 'inherit' }}>
          <List>
            {
              !isEmpty(vendorDecs) &&
              <div>
                {
                  map(vendorDecs, (item, index) => (
                    <span key={index} style={{ display: 'grid', borderBottom: '1px solid lightgray', paddingTop: '15px', paddingBottom: '15px' }}>
                      <span>
                        {item.contactName} ({item.contactCompanyName})
                      </span>
                      <span style={{ fontSize: '0.875rem', color: 'grey', letterSpacing: '0.01071em' }}>
                        {toDateTimeFormat(item.createdAt)}
                      </span>
                      <br />
                      <span style={{ display: 'flex' }}>
                        <span style={{ color: '#00192b', whiteSpace: 'pre-wrap', fontWeight: 'bold' }}>
                          {item.type}:
                        </span>
                        <a target='_blank' rel='noopener noreferrer' href={item.webHref}>
                          {item.identifier}
                        </a>
                      </span>
                      <span style={{ display: 'flex' }}>
                        <span style={{ color: '#00192b', fontWeight: 'bold', whiteSpace: 'pre-wrap' }}>
                          Against {capitalize(item.componentName)}:
                        </span>
                        {
                          parentLink ?
                          <a target='_blank' rel='noopener noreferrer' href={item.parentWebUrl}>
                            {item.parentIdentifier}
                          </a> :
                          <React.Fragment>{item.parentIdentifier}</React.Fragment>
                        }
                      </span>
                    </span>
                  ))
                }
              </div>
            }
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default SlotVendorDec;

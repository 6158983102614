import React from 'react';
import { connect } from 'react-redux';
import { Slide } from '@mui/material';
import GenericTable from '../components/GenericTable';
import {
  setSelectedCashPriceInactive, canCreateTitleTransferCashPrice, getCashPrices,
  setSelectedCashPrice, getTitleTransfers, editSelectedCashPrice, viewSelectedCashPrice,
  cashPricesResponse, deleteCashPrice, getCashPricesUnregisteredUser, setCurrentCashPriceCommodities,
  setCurrentCommodityId, getCashPriceCommodities, getCompanyCashPrices, getArchivedCashPrices,
  getSiteArchivedCashPrices,
  companyCashPricesResponse,
  archivedCashPricesResponse,
  siteArchivedCashPricesResponse,
  sitesActiveCashPricesResponse,
  getSitesActiveCashPrices, showAuditHistory
} from '../actions/companies/cash-board';
import SideDrawer from '../components/common/SideDrawer'
import { getCashPriceGlobalListingTableHeaders } from "../common/constants";
import { TAB } from "../components/cash-boards/constants";
import alertifyjs from 'alertifyjs';
import APIService from '../services/APIService';
import Notes from '../components/notes/Notes';
import { includes, get, isEmpty, isEqual, isElement, isArray, map } from 'lodash';
import { currentUserCompany } from '../common/utils';


class CashPricesTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalCleared: true,
      commodityIdsSet: false,
      slide: true,
    }
  }

  componentDidMount() {
    const isPresentation = get(this.props, 'isPresentation');
    const { dispatch } = this.props;
    if (isArray(get(this.props, 'items')) && !isPresentation) {
      let commodityIds = map(this.props.items, item => item.commodityId);
      dispatch(setCurrentCashPriceCommodities(commodityIds));
    }
    let windowUrl = window.location.hash.split('?')[0]
    if (includes(['#/cash-board/cash-prices', '#/cash-board'], windowUrl) && isPresentation) {
      if (localStorage.getItem('token')) {
        this.props.dispatch(getCashPriceCommodities(false))
        this.commodityInterval = setInterval(() => this.props.dispatch(getCashPriceCommodities(false)), 30000);
      }
      else {
        this.props.dispatch(getCashPriceCommodities())
        this.commodityInterval = setInterval(() => this.props.dispatch(getCashPriceCommodities()), 30000);
      }
      this.setState({intervalCleared: false});
      this.props.navigateTo(null);
      this.interval = setInterval(this.fetchNew, 15000);
    }
    document.addEventListener('mousedown', (event) => this.handleClickOutside(event));
  }

  componentDidUpdate(prevProps) {
    const isPresentation = get(this.props, 'isPresentation');
    const { dispatch } = this.props;
    const items = get(this.props, 'items');
    const prevItems = get(prevProps, 'items');
    let windowUrl = window.location.hash.split('?')[0];
    if ((items && !isEmpty(items) && !isPresentation && !this.state.commodityIdsSet) || (!isEqual(prevItems, items) && !isPresentation)) {
      let commodityIds = map(this.props.items, item => item.commodityId);
      commodityIds = commodityIds.filter((commodityId, index) => commodityIds.indexOf(commodityId) === index);
      dispatch(setCurrentCashPriceCommodities(commodityIds));
      this.setState({commodityIdsSet: true});
    }
    if (!this.state.intervalCleared && ((!includes(['#/cash-board/cash-prices', '#/cash-board'], windowUrl) && get(this, 'interval')) || (get(this, 'interval') && !get(this.props, 'isPresentation')))) {
      this.setState({intervalCleared: true});
      clearInterval(this.interval);
      this.interval = null;
      clearInterval(this.commodityInterval);
      this.commodityInterval = null;
      dispatch(setCurrentCommodityId(null));
      this.props.navigateTo(null);
    }
    if (includes(['#/cash-board/cash-prices', '#/cash-board'], windowUrl) && !get(this, 'interval') && get(this.props, 'isPresentation') && !this.props.intervalTime) {
      if (localStorage.getItem('token')) {
        this.props.dispatch(getCashPriceCommodities(false))
        this.commodityInterval = setInterval(() => this.props.dispatch(getCashPriceCommodities(false)), 30000);
      }
      else {
        this.props.dispatch(getCashPriceCommodities())
        this.commodityInterval = setInterval(() => this.props.dispatch(getCashPriceCommodities()), 30000);
      }
      this.setState({intervalCleared: false});
      this.props.navigateTo(null);
      this.interval = setInterval(this.fetchNew, 15000);
    }
    if (get(this.props, 'isPresentation') && this.interval && this.props.intervalTime && prevProps.intervalTime !== this.props.intervalTime) {
      clearInterval(this.interval);
      this.interval = setInterval(this.fetchNew, this.props.intervalTime);
    }
  }

  fetchNew = () => {
    this.setState({slide: false}, () => {
      this.props.navigateTo(null)
      setTimeout(() => this.setState({slide: true}), 300)
    })
  }

  componentWillUnmount() {
    if (get(this, 'interval')) {
      clearInterval(this.interval);
    }
    document.removeEventListener('mousedown', (event) => this.handleClickOutside(event));
  }

  handleClickOutside(event) {
    const el = document.getElementById('history');
    if(isElement(el) && !el.contains(event.target))
      this.props.dispatch(showAuditHistory(false))
  }

  _render = () => {
    const { isPresentation } = this.props;
    return (
      <div>
        <GenericTable
          {...this.props}
          showHeader={isPresentation}
          applyMargins={isPresentation}
        />
      </div>
    );
  }

  render() {
    const { isPresentation } = this.props;
    const commodityName = isPresentation ? get(this.props.items, '0.commodityName') : null
    return (
      <div>
        {
          isPresentation ?
            <React.Fragment>
              <h1 style={{textAlign: 'center', margin: '15px 0'}}>
                {commodityName}
              </h1>
              <div style={{position: 'absolute', left: '30px', top: '22px'}}>
                <img style={{height: '40px'}} src='images/agrichain-logo-black.png' alt='Agri Chain Logo' />
              </div>
              <Slide direction='left' in={this.state.slide} mountOnEnter unmountOnExit>
                {this._render()}
              </Slide>
            </React.Fragment> :
          this._render()
        }
        {
          this.props.showAuditHistoryDialog &&
          <div id="history">
          <SideDrawer
              isOpen={this.props.showAuditHistoryDialog}
              title="Audit History"
              onClose={() => this.props.dispatch(showAuditHistory(false))}
              size='big'>
              <Notes {...this.props} objectId={this.props.selectedCashPrice?.id} objectType='cash_prices' companyId={this.props.companyId} />
          </SideDrawer>
          </div>
        }
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let optionItems = [];
  if (includes([TAB.ALL_CASH_PRICE, TAB.UNREGISTERED_CASH_PRICE], ownProps.isAllCashPriceTab)) {
    optionItems.push({ key: 'cashOut', text: 'Cash Out' });
    optionItems.push({ key: 'viewTitleTransfer', text: 'Title Transfers'});
  }

  if (ownProps.isAllCashPriceTab == TAB.MY_CASH_PRICE) {
    optionItems.push({ key: 'view', text: 'View' });
    optionItems.push({ key: 'edit', text: 'Repost' });
    optionItems.push({ key: 'inactive', text: 'Inactive' });
    optionItems.push({ key: 'viewTitleTransfer', text: 'Title Transfers' });
    optionItems.push({ key: 'auditHistory', text: 'Audit History'});
  }

  if (ownProps.isAllCashPriceTab == TAB.CLOSED_CASH_PRICE) {
    optionItems.push({ key: 'view', text: 'View' });
    optionItems.push({ key: 'edit', text: 'Repost' });
    optionItems.push({ key: 'viewTitleTransfer', text: 'Title Transfers' });
    optionItems.push({ key: 'delete', text: 'Delete' });
    optionItems.push({ key: 'auditHistory', text: 'Audit History'});
  }

  if (ownProps.isAllCashPriceTab == TAB.SITE_ARCHIVED_CASH_PRICE) {
    optionItems.push({ key: 'view', text: 'View' });
    optionItems.push({ key: 'viewTitleTransfer', text: 'Title Transfers' });
    optionItems.push({ key: 'auditHistory', text: 'Audit History'});
  }

  if (ownProps.isAllCashPriceTab == TAB.SITE_ACTIVE_CASH_PRICE) {
    optionItems.push({ key: 'view', text: 'View' });
    optionItems.push({ key: 'cashOut', text: 'Cash Out'});
    optionItems.push({ key: 'inactive', text: 'Inactive' });
    optionItems.push({ key: 'viewTitleTransfer', text: 'Title Transfers' });
    optionItems.push({ key: 'auditHistory', text: 'Audit History'});
  }

  let columns = [...getCashPriceGlobalListingTableHeaders(ownProps.isAllCashPriceTab, ownProps?.isPresentation)];
  if (get(ownProps, 'showSelectAll')) {
    columns = [{header: 'All', checkbox: true, className: 'xxsmall', onChange: ownProps.updateSelectedItems, func: ownProps.isSelected, selectAll: true, checked: ownProps.allSelected, onSelectAll: ownProps.onSelectAllToggle }, ...columns];
  }

  let cashPrices = state.companies.cashBoard.cashPrices;
  let paginatedCashPrices = state.companies.cashBoard.cashPricesPaginatedData;
  let clearSearchResponseMethod = cashPricesResponse;
  if (ownProps.isAllCashPriceTab === TAB.MY_CASH_PRICE) {
    cashPrices = state.companies.cashBoard.companyCashPrices;
    paginatedCashPrices = state.companies.cashBoard.companyCashPricesPaginatedData;
    clearSearchResponseMethod = companyCashPricesResponse;
  }
  else if (ownProps.isAllCashPriceTab === TAB.CLOSED_CASH_PRICE) {
    cashPrices = state.companies.cashBoard.archivedCashPrices;
    paginatedCashPrices = state.companies.cashBoard.archivedCashPricesPaginatedData;
    clearSearchResponseMethod = archivedCashPricesResponse;
  }
  else if (ownProps.isAllCashPriceTab === TAB.SITE_ARCHIVED_CASH_PRICE) {
    cashPrices = state.companies.cashBoard.siteArchivedCashPrices;
    paginatedCashPrices = state.companies.cashBoard.siteArchivedCashPricesPaginatedData;
    clearSearchResponseMethod = siteArchivedCashPricesResponse;
  }
  else if (ownProps.isAllCashPriceTab === TAB.SITE_ACTIVE_CASH_PRICE) {
    cashPrices = state.companies.cashBoard.siteActiveCashPrices;
    paginatedCashPrices = state.companies.cashBoard.siteActiveCashPricesPaginatedData;
    clearSearchResponseMethod = sitesActiveCashPricesResponse;
  }

  return {
    columns: [...columns, ...(ownProps.customColumns || [])],
    items: cashPrices,
    scrollToTopOnUpdate: false,
    handleDefaultCellClick: false,
    clearSearch: clearSearchResponseMethod,
    paginationData: paginatedCashPrices,
    globalSearch: true,
    noHighlight: ownProps?.isPresentation,
    optionsItems: (ownProps?.isPresentation || ownProps?.loggedOut) ? [] : optionItems,
    showAuditHistoryDialog: state.companies.cashBoard.showAuditHistoryDialog,
    selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
    intervalTime: state.companies.cashBoard.intervalTime,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleOptionClick: (index, key, id, item) => {
      if (key === 'cashOut') {
        if (window.location.hash == '#/cash-board')
          window.history.back();
        dispatch(canCreateTitleTransferCashPrice(item));
      }
      if (key === 'viewTitleTransfer') {
        if (includes([get(item, 'siteCompanyId'), get(item, 'buyer.companyId')], get(currentUserCompany(), 'id'))) {
          dispatch(setSelectedCashPrice(item));
          dispatch(getTitleTransfers(item.id));
          document.location.hash = '/cash-price/' + item.id + '/title-transfers';
        }
        else
          alertifyjs.alert('Alert', 'Only Buyer and Site employees can see the CashOuts done against this cash price');
      }
      if (key === 'edit') {
        dispatch(setSelectedCashPrice(item));
        dispatch(editSelectedCashPrice(true));
      }
      if (key == 'view') {
        dispatch(setSelectedCashPrice(item));
        dispatch(viewSelectedCashPrice(true));
      }
      if (key == 'inactive') {
        alertifyjs.confirm('Are you sure?', 'Are you sure that you want to mark this cash price Inactive?',
                           () => { dispatch(setSelectedCashPriceInactive(item.id, ownProps.isAllCashPriceTab)); },
                           () => { });
      }
      if (key == 'delete') {
        if (item.limit === item.limitRemaining)
          dispatch(deleteCashPrice(item.id));
        else
          alertifyjs.error('Cash Out has already been made for this cash price');
      }
      if (key == 'auditHistory') {
        dispatch(setSelectedCashPrice(item));
        dispatch(showAuditHistory(true));
      }
    },
    handleDefaultCellClick: (item) => {
      dispatch(setSelectedCashPrice(item));
      dispatch(viewSelectedCashPrice(true));
    },

    navigateTo: url => {
      let isPresentation = get(ownProps, 'isPresentation')
      if (!(isPresentation && url)) {
        if (localStorage.getItem('token')) {
          let tab = ownProps.isAllCashPriceTab;
          if (tab === TAB.ALL_CASH_PRICE)
            dispatch(getCashPrices('', true, url, null, isPresentation));
          else if (tab === TAB.MY_CASH_PRICE)
            dispatch(getCompanyCashPrices('', true, url));
          else if (tab === TAB.CLOSED_CASH_PRICE)
            dispatch(getArchivedCashPrices('', true, url));
          else if (tab === TAB.SITE_ARCHIVED_CASH_PRICE)
            dispatch(getSiteArchivedCashPrices('', true, url));
          else if (tab === TAB.SITE_ACTIVE_CASH_PRICE)
            dispatch(getSitesActiveCashPrices('', true, url));
        }
        else
          dispatch(getCashPricesUnregisteredUser(null, url, isPresentation));
      }
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      if (localStorage.getItem('token')) {
        let tab = ownProps.isAllCashPriceTab;
        if (tab === TAB.ALL_CASH_PRICE)
          dispatch(getCashPrices('', true, url));
        else if (tab === TAB.MY_CASH_PRICE)
          dispatch(getCompanyCashPrices('', true, url));
        else if (tab === TAB.CLOSED_CASH_PRICE)
          dispatch(getArchivedCashPrices('', true, url));
        else if (tab === TAB.SITE_ARCHIVED_CASH_PRICE)
          dispatch(getSiteArchivedCashPrices('', true, url));
        else if (tab === TAB.SITE_ACTIVE_CASH_PRICE)
          dispatch(getSitesActiveCashPrices('', true, url));
      }
      else
        dispatch(getCashPricesUnregisteredUser(null, url));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const cashPriceService = APIService.cash_board();
      cashPriceService.appendToUrl(`?page_size=${pageSize}`);
      if (page) {
        cashPriceService.appendToUrl(`&page=${page}`);
      }
      if (searchText) {
        cashPriceService.appendToUrl(`&search=${searchText}`);
      }
      if (orderBy) {
        cashPriceService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return cashPriceService.URL;
    },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CashPricesTable);

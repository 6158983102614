import React from 'react';

import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import {EMPTY_VALUE} from '../../../common/constants';
import Tooltip from '../../../common/Tooltip';
import connect from "react-redux/es/connect/connect";
import get from "lodash/get";
import Chip from "@mui/material/Chip";

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class OrderNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  getSectionAsPerGrid = (dataLists) => {
    return (
        <div>
            <ul className="field-label-list">
                 {Object.keys(dataLists).map(function(key, index) {
                    return (
                        <li key={index}>

                            <Tooltip
                                tooltipText={dataLists[key] || EMPTY_VALUE}
                                textContent={dataLists[key] || EMPTY_VALUE}
                            />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
   };

  imageTemplate = (attachment) =>{
    return (
      attachment.map(attachment => {
        return (
          <span key={attachment.url} className="field-value"   style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <a href={attachment.url} rel="noopener noreferrer" target="_blank">
              <Chip style={{cursor: 'pointer'}} label={attachment.name} variant="outlined" />
            </a>
          </span>
        );
      })
    );
  };

  render() {
    const note = get(this.props.entity, 'note');
    const { expanded } = this.state;
    let notePayload = [
      get(note, 'description'),
    ];

    return (
      <Paper className="order-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Notes
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div className="section-details-container">
            {this.getSectionAsPerGrid(notePayload)}
            {
              note &&
              <Tooltip
                className="field-label list"
                textContent={this.imageTemplate(get(note, 'attachmentUrls'))}
              />
            }
          </div>
        )}

      </Paper>
    );
  }
}

const mapStateToProps = (state) => {
    return {
      currentUser: state.main.user.user,
      userToken: state.main.user.token
    };
};

export default connect(mapStateToProps)(OrderNotes);

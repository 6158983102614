import React, { Component } from 'react';
import CommonButton from '../common/CommonButton';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';

class RemoveInvoiceFromPaymentRuns extends Component {

    constructor(props) {
        super(props);
    }
    render() {
        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="form-dialog-title"
                style={{paddingRight: '100px'}}
                fullWidth
            >
            <DialogTitleWithCloseIcon onClose={this.props.onClose}>Warning</DialogTitleWithCloseIcon>
            <DialogContent>
            <p>
            {this.props.removeFromPaymentRunDialog &&
                <p>
                Voiding this {this.props.itemType} will remove the Invoice from the Payment Run
                <a href={`/#/invoices/payment-runs/${this.props.selectedInvoice?.paymentRunId}`} target='_blank' rel="noopener noreferrer">
                    {` ${this.props.selectedInvoice?.paymentRunIdentifier}`}
                </a>.
                </p>
            }
            Are you sure you want to proceed?
            </p>
            </DialogContent>
            <DialogActions>
            <CommonButton
                key="no"
                label='Cancel'
                onClick={this.props.onClose}
                variant="flat"
            />
            <CommonButton
                key="yes"
                label='Yes, Proceed'
                primary={true}
                onClick={this.props.handleRemoveFromPaymentRun}
                variant="flat"
            />
            </DialogActions>
            </Dialog>
        );
    }
}
export default RemoveInvoiceFromPaymentRuns;
import React from 'react';
import CommonSelect from '../../common/select/CommonSelect';
import { get, map, set, mapValues, cloneDeep, some, forEach, isEqual } from 'lodash';
import { REQUIRED_FIELD } from '../../../common/constants';

class ProductIntegrity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        commoditySource: cloneDeep(REQUIRED_FIELD),
        gmo: cloneDeep(REQUIRED_FIELD),
      }
    };
    this.propagateChanges = this.propagateChanges.bind(this);
  }

  toSelectOptions(dataList) {
    return map(dataList, item => ({id: item, name: item}));
  }

  onChange = (value, id) => {
    const newState = { ...this.state };
    set(newState.fields, `${id}.value`, value);
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  };

  componentDidUpdate(prevProps) {
    const { validate } = this.props;

    if(!prevProps.validate && validate)
      this.setAllFieldErrors();

    if(this.shouldSetDataFromTemplate(prevProps))
      this.setDataFromSelectedTemplate();
  }

  shouldSetDataFromTemplate(prevProps) {
    const { selectedTemplate } = this.props;
    return selectedTemplate && !isEqual(prevProps.selectedTemplate, selectedTemplate);
  }

  setDataFromSelectedTemplate() {
    const { selectedTemplate } = this.props;
    const newState = { ...this.state };
    newState.fields.commoditySource.value = selectedTemplate.commoditySource;
    newState.fields.gmo.value = selectedTemplate.gmo;
    this.setState(newState, this.propagateChanges);
  }

  setFieldError(field, callback) {
    const newState = {...this.state};
    const fieldState = get(newState.fields, field);
    fieldState.errors = this.getFieldErrors(field);
    this.setState(newState, () => {
      if(callback)
        callback();
    });
  }

  getFieldErrors(field) {
    let errors = [];
    const fieldState = get(this.state.fields, field);
    if(!fieldState)
      return errors;
    fieldState.validators.forEach(validator => {
      if(validator.isInvalid(fieldState.value))
        errors.push(validator.message);
      else
        errors = [];
    });

    return errors;
  }

  setAllFieldErrors() {
    const newState = {...this.state};
    forEach(newState.fields, (state, field) => {
      state.errors = this.getFieldErrors(field);
    });
    this.setState(newState, this.propagateChanges);
  }

  propagateChanges() {
    this.props.onChange(mapValues(this.state.fields, 'value'), {productIntegrity: this.hasErrors()});
  }

  hasErrors() {
    const { fields } = this.state;
    return some(fields, field => field.errors.length > 0);
  }

  render() {
    const { constants, fieldRef } = this.props;
    const commoditySources = this.toSelectOptions(get(constants, 'commoditySources', []));
    const gmoOptions = this.toSelectOptions(get(constants, 'gmoOptions', []));
    return (
      <div>
        <h4 className='cardForm-title'>Product Integrity</h4>
        <div className='cardForm-content'>
          <div className='col-md-6'>
            <CommonSelect
              id='commoditySource'
              floatingLabelText='Commodity Source'
              items={commoditySources}
              setRef={fieldRef["data.commoditySource"]}
              selectConfig={{text: 'name', value: 'id'}}
              onChange={this.onChange}
              value={this.state.fields.commoditySource.value}
              errorText={this.state.fields.commoditySource.errors[0]}
            />
          </div>
          <div className='col-md-6'>
            <CommonSelect
              id='gmo'
              floatingLabelText='With respect to GMOs, this commodity:'
              items={gmoOptions}
              setRef={fieldRef["data.gmo"]}
              selectConfig={{text: 'name', value: 'id'}}
              onChange={this.onChange}
              value={this.state.fields.gmo.value}
              errorText={this.state.fields.gmo.errors[0]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProductIntegrity;

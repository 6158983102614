import { connect } from 'react-redux';
import FarmForm from '../components/farms/FarmForm';
import {
  updateFarm,
  getSelectedFarm,
  acceptFarm,
  brokerRejectFarm,
  growerRejectFarm,
} from '../actions/api/farms';
import { editFarm, receiveFarm } from '../actions/companies/farms';
import get from 'lodash/get';

function submit(companyId, data) {
  return (dispatch, getState) => {
    const farmId = getState().companies.farms.selectedFarmId;
    dispatch(updateFarm(companyId, farmId, data, editFarm));
  };
}

function getFarm(farmId) {
  return (dispatch) => {
    dispatch(getSelectedFarm(farmId, receiveFarm));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedFarm: get(state, 'companies.farms.selectedFarm', null)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (companyId, data) => dispatch(submit(companyId, data)),
    acceptFarm: (farmId, data) => dispatch(acceptFarm(farmId, data)),
    brokerRejectFarm: (farmId, data) => dispatch(brokerRejectFarm(farmId, data)),
    growerRejectFarm: (farmId) => dispatch(growerRejectFarm(farmId)),
    getFarm: (farmId) => dispatch(getFarm(farmId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FarmForm);

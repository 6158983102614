import React from 'react';
import { connect } from 'react-redux';
import Stepper from "@mui/material/Stepper/Stepper";
import Step from "@mui/material/Step/Step";
import StepLabel from "@mui/material/StepLabel/StepLabel";
import StepConnector from "@mui/material/StepConnector/StepConnector";
import Divider from "@mui/material/Divider/Divider";
import map from "lodash/map";
import keys from "lodash/keys";
import omit from "lodash/omit";
import startCase from "lodash/startCase";
import alertifyjs from "alertifyjs";
import './signup.scss';
import EmployeeSignUpForm from "../employees/EmployeeSignUpForm";
import CompanySignUpForm from "../companies/CompanySignUpForm";
import APIService from "../../services/APIService";
import {LOGISTIC, WARM_GREY} from "../../common/constants";
import {forceStopLoader, isLoading, loaded, login} from "../../actions/main";
import SignUpSuccess from "./SignUpSuccess";
import { addEmployeeErrors } from '../../actions/company-settings/employees';

class LogisticSignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpSetupDone: false,
      signUpCompleted: [],
      signUpActiveStep: 0,
      signUpData: {company: {}},
      employeeExists: false,
      existingCompanyId: undefined,
      existingEmployeeId: undefined,
      signUpSuccess: false,
      successHeader: '',
      successContent: '',
      selectedEmployee: null,
      companyResponse: null,
    };
  }

  componentDidMount() {
    if (!this.state.signUpSetupDone){
      this.setupSignUp();
    }
  }

  setupSignUp() {
    const newState = {...this.state};
    newState.signUpCompleted = [false, false];
    newState.signUpSetupDone = true;
    this.setState(newState);
  }

  getSignUpSteps = () => {
    return keys(this.getSignUpStepsContent());
  };

  getSignUpDisplaySteps = () => {
    return map(keys(this.getSignUpStepsContent()), startCase);
  };

  getSignUpStepsContent = () => {
    return {
      'companyDetails': <CompanySignUpForm submit={this.getFieldsValue} previousStep={this.previousSignUpStep} nextStep={this.nextSignUpStep} values={this.state.signUpData} isLiteSignUp employeeExists={this.state.employeeExists} getExistingCompany={this.getExistingCompany} setCompanyResponse={this.setCompanyResponse} onEmployeeSelect={this.onEmployeeSelect} />,
      'personalDetails': <EmployeeSignUpForm submit={this.getFieldsValue} previousStep={this.previousSignUpStep} nextStep={this.finishSignUpSetup} values={this.state.signUpData} getRegisteredDetails={this.getRegisteredDetails} showBack={true} isLiteSignUp completeSignUp={this.completeSignUp} companyResponse={this.state.companyResponse} selectedEmployee={this.state.selectedEmployee} />,
    };
  };

  onEmployeeSelect = (employee, callback) => {
    this.setState({selectedEmployee: employee}, () => {
      if(callback)
        callback();
    });
  };

  setCompanyResponse = response => {
    this.setState({companyResponse: response});
  };

  getStepperContent = () => {
    const steps = [];
    const displaySteps = this.getSignUpDisplaySteps();

    displaySteps.map((label, index) => {
      const props = {};
      props.completed = this.state.signUpCompleted[index];
      const labelProps = {};
      steps.push((
        <Step key={label} {...props}>
          <StepLabel {...labelProps} classes={{completed: 'step-label-completed', active: 'step-label-active'}}>
            {label}
          </StepLabel>
        </Step>
      ));
    });
    return steps;
  };

  getStepContent = () => {
    const activeStep = this.state.signUpActiveStep;
    const steps = this.getSignUpSteps();
    const currentStepTab = steps[activeStep];
    return this.getSignUpStepsContent()[currentStepTab];
  };

  previousSignUpStep = () => {
    if (this.state.signUpActiveStep === 0) {
      alertifyjs.confirm('Are you sure?', 'All data will be lost. Are you sure?', () => window.location = '#', () => {});
    } else {
      const newState = {...this.state};
      newState.signUpActiveStep -= 1;
      this.setState(newState);
    }
  };

  registerEmployee = async (data) => {
    const { dispatch } = this.props;
    const profileAPI = APIService.profiles();
    profileAPI.appendToUrl('register');

    dispatch(isLoading('alertify'));
    profileAPI.post(data).then(async response => {
      dispatch(loaded());
      if (response.id) {
        alertifyjs.success("Successfully Registered");
        const data = {username: this.state.signUpData.username, password: this.state.signUpData.password};
        login(data, null, null, this.props.dispatch, `#/companies/${response.company.id}/employees?employees`);
      } else {
        alertifyjs.error("Something went wrong!");
      }
    });
  };

  updateEmployee = async (data) => {
    const { dispatch } = this.props;
    const companiesAPI = APIService.companies();
    companiesAPI.appendToUrl(`unregistered/${this.state.existingCompanyId}/employees/${this.state.existingEmployeeId}/`);

    dispatch(isLoading('alertify'));
    companiesAPI.put(data).then(async response => {
      dispatch(loaded());
      if (response.id) {
        alertifyjs.success("Successfully Registered");
        const data = {username: this.state.signUpData.username, password: this.state.signUpData.password};
        login(data, null, null, this.props.dispatch);
      } else {
        alertifyjs.error("Something went wrong!");
      }
    });
  };

  completeSignUp = (header, content) => {
    this.setState({signUpSuccess: true, successHeader: header, successContent: content});
  };

  addEmployee = async (data) => {
    const { dispatch } = this.props;
    const companiesAPI = APIService.companies();
    companiesAPI.appendToUrl(`unregistered/${this.state.existingCompanyId}/employees/`);

    dispatch(isLoading('alertify'));
    companiesAPI.post(data).then(async response => {
      dispatch(loaded());
      if (response.id == null || response.errors){
        dispatch(forceStopLoader());
        dispatch(addEmployeeErrors(response.errors));
      }
      else if (response.id) {
        alertifyjs.success("Successfully Registered");
        const data = {username: this.state.signUpData.username, password: this.state.signUpData.password};
        login(data, null, null, this.props.dispatch);
      } else {
        alertifyjs.error("Something went wrong!");
      }
    });
  };

  finishSignUpSetup = () => {
    if(this.state.selectedEmployee) {
      const data = {username: this.state.signUpData.username, password: this.state.signUpData.password};
      login(data, null, null, this.props.dispatch);
    } else {
      if (this.state.employeeExists){
        this.updateEmployee(this.getEmployeeData());
      } else if (!this.state.employeeExists && this.state.existingCompanyId && !this.state.selectedEmployee){
        this.addEmployee(this.getEmployeeData());
      } else if(!this.state.selectedEmployee) {
        this.registerEmployee(this.getSubmitData());
      }
    }
  };

  getEmployeeData = () => {
    const data = {...this.state.signUpData};
    return omit(data, ['company', 'confirmPassword', 'isChecked']);
  };

  getSubmitData = () => {
    const data = {...this.state.signUpData};
    data['company']['typeId'] = LOGISTIC;
    data['company']['platformFeatures'] = ['site_bookings', 'companies', 'movements', 'orders'];
    data['company']['planType'] = 'logistics_lite';
    data['company']['assets'] = {
      'details': true,
      'employees': false,
      'trucks': false,
    };

    return omit(data, ['confirmPassword', 'isChecked']);
  };

  nextSignUpStep = () => {
    const newState = {...this.state};
    newState.signUpCompleted[newState.signUpActiveStep] = true;
    newState.signUpActiveStep += 1;
    this.setState(newState);
  };

  getRegisteredDetails = (abn, existingEmployeeId) => {
    const newState = {...this.state};
    if (abn){
      newState.signUpData['company']['abn'] = abn;
      newState.employeeExists = true;
    } else {
      newState.employeeExists = false;
    }
    newState.existingEmployeeId = existingEmployeeId;
    this.setState(newState);
  };

  getExistingCompany = (existingCompanyId) => {
    this.setState({existingCompanyId});
  };

  getFieldsValue = (details, callback) => {
    const newState = this.state;
    newState.signUpData = {...newState.signUpData, ...details};
    this.setState(newState, () => {
      if(callback)
        callback();
    });
  };

  navigateToLogin = () => {
    if(this.state.signUpSuccess){
      window.location = '#';
    }
  };

  render() {
    return (
      <div className="signup-wrapper">
        <div className="signup-container">

          {
            !this.state.signUpSuccess ?
            <div className="signup-content-box">
              <div className="signup-content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
              </div>

              <div className='col-sm-12' style={{margin: '5px', marginLeft: '25px'}}>
                <span style={{fontSize: '26px', color: 'rgb(106, 174, 32)'}}>Sign Up</span>
              </div>

              <div>
                <Divider className='col-sm-12 padding-reset'/>
              </div>

              <Stepper
                connector={<StepConnector className='step-connector'/>}
                activeStep={this.state.signUpActiveStep}
                style={{height: '48px', maxHeight: '48px', paddingLeft: '16px', paddingRight: '16px', width: '100%'}}
              >
                {this.getStepperContent()}
              </Stepper>

              <div>
                <Divider className='col-sm-12 padding-reset'/>
              </div>

              <div className="signup-content-box--content">
                {this.getStepContent()}
              </div>

            </div>
              :
            <div className="signup-content-box" style={{ width: '500px', marginLeft: '175px', marginTop: '80px'}}>
              <div className="signup-content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
              </div>

              <div className="signup-content-box--content">
                <div className="cardForm cardForm--drawer" id='registerSuccess'>
                  <SignUpSuccess onClick={this.navigateToLogin} contentText={this.state.successContent} headerText={this.state.successHeader} isMiniature={true} contentColor={WARM_GREY}/>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}

export default connect()(LogisticSignUp);

import React from 'react';
import { Dialog, DialogContent, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { DialogTitleWithCloseIcon } from "../common/DialogTitleWithCloseIcon";
import { isEmpty } from "lodash";

let StockDifferencesDialog = props => {

  return (
      <Dialog
        open={props.isOpen}
        onClose={props.toggleDialog}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        fullWidth
      >
        <DialogTitleWithCloseIcon
          onClose={props.toggleDialog}
          id="order-accept-dialog"
        >
          Stock Differences
        </DialogTitleWithCloseIcon>
        <DialogContent style={{ marginTop: '10px' }}>

          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center" className="xsmall">Commodity</TableCell>
                <TableCell align="center" className="xsmall">Storage View</TableCell>
                <TableCell align="center" className="xsmall">My Stocks</TableCell>
                <TableCell align="center" className="xsmall">Third Party Stocks</TableCell>
                <TableCell align="center" className="xsmall">Differences</TableCell>
                <TableCell align="center" className="xsmall">Identifier</TableCell>
                <TableCell align="center" className="xsmall">Storage</TableCell>
                <TableCell align="center" className="xsmall">NGR</TableCell>
                <TableCell align="center" className="xsmall">Quantity</TableCell>
                <TableCell align="center" className="xsmall">Type</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isEmpty(props.stockDifferences) ?
                props.stockDifferences.map((item, index) => (
                  item.reasons.map((reason, reasonIndex) => (
                    <TableRow key={`${index}-${reasonIndex}`}>
                      {reasonIndex === 0 && (
                        <>
                          <TableCell align="center" className="xsmall" rowSpan={item.reasons.length}>{item.commodityName}</TableCell>
                          <TableCell align="center" className="xsmall" rowSpan={item.reasons.length}>{item.storageViewStock}</TableCell>
                          <TableCell align="center" className="xsmall" rowSpan={item.reasons.length}>{item.myStocks}</TableCell>
                          <TableCell align="center" className="xsmall" rowSpan={item.reasons.length}>{item.thirdPartyStocks}</TableCell>
                          <TableCell align="center" className="xsmall" rowSpan={item.reasons.length}>{item.differences}</TableCell>
                        </>
                      )}
                      <TableCell align="center" className="xsmall">{reason.identifier}</TableCell>
                      <TableCell align="center" className="xsmall">{reason.storage}</TableCell>
                      <TableCell align="center" className="xsmall">{reason.ngr}</TableCell>
                      <TableCell align="center" className="xsmall">{reason.quantity}</TableCell>
                      <TableCell align="center" className="xsmall">{reason.optionType}</TableCell>
                    </TableRow>
                  ))
                )) :
                <TableRow>
                  <TableCell colSpan="100" className='no-record-column'>
                    No records found
                  </TableCell>
                </TableRow>
              }
            </TableBody>
          </Table>

        </DialogContent>
      </Dialog>);
};

export default StockDifferencesDialog;
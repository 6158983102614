import React, { Component } from 'react';
import APIService from '../../../services/APIService';

import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CommonButton from '../../common/CommonButton';
import { required } from '../../../common/validators';
import { isLoading } from '../../../actions/main';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import includes from 'lodash/includes';
import find from 'lodash/find';
import { RejectionReasonDialog } from '../../rejections/RejectionReasonDialog';
import Tooltip from '../../../common/Tooltip';
import {
  confirmOrderAmend,
  rejectOrderAmend,
} from '../../../actions/companies/freights';
import {
  convertEpochToDateFormat,
} from '../../../common/momentUtilities';
import { compareObjectValues, currentUser } from '../../../common/utils';


const styles = () => ({
  subTitle: {
    fontSize: 15,
    fontWeight: 600,
    color: '#112c42',
  },
  columnTitle:{
    border: '1px solid #000',
    textAlign: 'center',
    width : '35%',
    fontSize: 15,
    fontWeight: 600,
  },
  listItem: {
    display: 'block',
    height: 85,
    padding: 0,
  },
  secondaryListItem: {
    padding: 0,
  },
  primaryListItem: {
    padding: 0,
  },
});

class EditOrderReview extends Component {
  constructor(props) {
    super(props);

    this.classes = this.props.classes;

    this.orderId = this.props.order.id;
    this.isAmendRequestPending = this.props.order.isAmendRequestPending;
    this.amendable = this.props.order.amendable;
    this.amendedDetails = this.props.order.amendDetails || this.props.amendDetails;

    this.state = {
      rejectDialogOpen: {
        value: false,
        validators: [],
        errors: [],
      },
      rejectionReason: {
        value: '',
        validators: [],
        errors: [],
      },
      companies: [],
    };

    this.confirmAmendRequestWrapper = this.confirmAmendRequestWrapper.bind(this);
    this.rejectAmendRequestWrapper = this.rejectAmendRequestWrapper.bind(this);
  }

  handleRejectClickOpen = () => {
    const newState = { ...this.state };
    newState.rejectDialogOpen.value = true;
    newState.rejectionReason.validators = [required()];
    if (!newState.rejectionReason.value) {
      newState.rejectionReason.errors = [];
    }
    this.setState(newState);
  };

  componentDidMount() {
    const token =this.props.main.user?.token
    const userCompany = currentUser().company
    APIService.companies()
      .appendToUrl('directory/names/?excludeGroups=true')
      .get(token).then(companyData => this.setState({companies: [...companyData, {id: userCompany.id, name:userCompany.name}]}) )
  }

  handleRejectClose = () => {
    this.setState({
      rejectDialogOpen: { errors: [], value: false, validators: [] },
    });
  };

  handleRejectSubmit = event => {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    if (this.state.rejectionReason.errors.length === 0) {
      this.rejectAmendRequestWrapper(data);
    }
    event.preventDefault();
  };

  handleReasonChange = event => {
    const value = event.target.value;
    const newState = { ...this.state };
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  };

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  confirmAmendRequestWrapper(){
    this.props.closeSidebar();
    this.props.confirmAmendRequest(this.orderId, null, this.props.shouldReload);
  }

  rejectAmendRequestWrapper(data){
    this.props.closeSidebar();
    this.props.rejectAmendRequest(this.orderId, data, null, this.props.shouldReload);
  }

  getValidField = (field, amendedField) => {
    const currentField = get(this.props.order, amendedField)
    const resultObj = compareObjectValues({
        key: field || '',
        currentValue: typeof currentField == 'object' ? (currentField?.name || '') : currentField,
        value1: get(this.amendedDetails, amendedField),
      });
    if (isEmpty(resultObj) || resultObj.value1 == null || resultObj.value1 == resultObj.currentValue)
      return null;

    return resultObj;
  };

  getValidSiteField = field => {
    let currentField = '-';
    let amendedValue = '-';
    if (includes(['Consignor', 'Consignee'], field)) {
      currentField = field == 'Consignee' ? get(find(this.state.companies, {id: get(this.props.order, 'freightDelivery.consignee.handler.companyId')}), 'name') : get(find(this.state.companies, {id: get(this.props.order, 'freightPickup.consignor.handler.companyId')}), 'name')
      amendedValue = field == 'Consignee' ? get(find(this.state.companies, {id: get(this.amendedDetails, 'freightDelivery.consignee.handler.handler.companyId')}), 'name') : get(find(this.state.companies, {id: get(this.amendedDetails, 'freightPickup.consignor.handler.handler.companyId')}), 'name')
    } else {
      currentField = field == 'Delivery Site' ? get(this.props.order, 'freightDelivery.consignee.handler.displayName') : get(this.props.order, 'freightPickup.consignor.handler.displayName')
      amendedValue = field == 'Delivery Site' ? get(this.amendedDetails, 'consigneeName') : get(this.amendedDetails, 'consignorName')
    }
    const resultObj = compareObjectValues({
        key: field || '',
        currentValue: currentField,
        value1: amendedValue,
      });
    if (isEmpty(resultObj) || resultObj.value1 == null || resultObj.value1 == resultObj.currentValue)
      return null;

    return resultObj;
  };

  getNgrRow = (field, amendedField) => {
    let currentField = get(this.props.order, amendedField);
    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: currentField ? currentField?.ngrNumber : '-',
      value1: get(this.amendedDetails, amendedField),
    });
    if (isEmpty(resultObj) || resultObj.value1 == null || resultObj.value1 == resultObj.currentValue) {
      return null;
    }
    return resultObj;
  };

  getHeading = heading => {
    return (
      <TableRow>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '30%' }}>
          <Typography variant="heading" className={this.classes.subTitle} style={{textAlign: 'center'}}>
            {heading}
          </Typography>
        </TableCell>
        <TableCell style={{ border: '1px solid #000', width : '35%' }}></TableCell>
        <TableCell style={{ border: '1px solid #000', width : '35%' }}></TableCell>
      </TableRow>
    );
  };

  getRow = (resultObj, index) => {
    if (isEmpty(resultObj)) {
      return;
    }
    return (
      <TableRow key={index}>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '30%' }}>
          <Tooltip
              className="ellipse amend-field-value"
              tooltipText={resultObj.key}
              textContent={resultObj.key}
            />
        </TableCell>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '35%' }}>
          <Tooltip
            className="ellipses amend-field-value"
            tooltipText={toString(resultObj.currentValue)}
            textContent={toString(resultObj.currentValue)}
          />
        </TableCell>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '35%' }}>
          <Tooltip
            className="ellipses amend-field-value"
            tooltipText={toString(resultObj.value1)}
            textContent={toString(resultObj.value1)}
          />
        </TableCell>
      </TableRow>
    );
  };

  getDateRow = (field, amendedField) => {
    let amendedDate = get(this.amendedDetails, amendedField);
    let currentDate = get(this.props.order, amendedField)

    if (amendedDate)
      amendedDate = convertEpochToDateFormat(amendedDate);

    if (currentDate)
      currentDate = convertEpochToDateFormat(currentDate);

    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: currentDate,
      value1: amendedDate,
    });

    if (isEmpty(resultObj)) {
      return null;
    }
    return resultObj;
  };

  getConditions = (field, amendedField) => {
    const _check = this.getValidField(field, amendedField);
    if (isEmpty(_check)) {
      return;
    }
    return (
      <React.Fragment>
        <TableRow>
          <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '30%' }}>
            <Typography variant="heading" className={this.classes.subTitle} style={{margin:"15px 0"}}>
              {field}
            </Typography>
          </TableCell>
          <TableCell style={{ border: '1px solid #000', width : '35%' }}>
            <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
              {get(this.props.order, amendedField)}
            </div>
          </TableCell>
          <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '35%' }}>
            <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
              {get(this.amendedDetails, amendedField)}
            </div>
          </TableCell>
      </TableRow>
      </React.Fragment>
    );
  };

  quantityLabel() {
    const quantityLabel = get(this.props, 'order.commodity.quantityLabel')
    const unit = get(this.props, 'order.commodity.unit')
    if(quantityLabel)
      return `${quantityLabel} (${unit})`
  }

  tonnageLabel() {
    return get(this.props.order, 'tonnageWithUnitLabel')
  }

  render() {
    const {
      isCustomer,
      isFreightProvider,
      isCreator,
      canEditInvoicingSection,
      isCustomerRegistered,
    } = this.props.order;

    const FreightProvider = filter([
      this.getValidField('Contact', 'assignTo.name', 'assignTo'),
    ]);
    const Customer = filter([
      this.getValidField(
        'Contact',
        'customer.contact',
      ),
      this.getNgrRow('NGR', 'customer.ngr'),
    ]);
    const GeneralDetail = filter([
      this.getValidField(this.quantityLabel(), 'quantity'),
      this.getValidField(this.tonnageLabel(), 'plannedTonnage'),
      this.getValidField('Variety', 'variety'),
      this.getValidField('Grade','plannedGrade'),
    ]);

    const PickUpDetail = filter([
      this.getDateRow(
        'Pickup Date',
        'freightPickup.dateTime',

      ),
      this.getValidSiteField(
        'Consignor'
      ),
      this.getValidSiteField(
        'Pickup Site'
      ),
      this.getValidField(
        'Pickup Order Number',
        'freightPickup.orderNumber',
      ),
    ]);

    const DeliveryDetail = filter([
      this.getDateRow(
        'Delivery Date',
        'freightDelivery.dateTime',
      ),
      this.getValidSiteField(
        'Consignee'
      ),
      this.getValidSiteField(
        'Delivery Site'
      ),
      this.getValidField(
        'Delivery Order Number',
        'freightDelivery.orderNumber',
      ),
    ]);

    const Invoicing = filter([
      this.getValidField('Invoicing', 'invoicing'),
      this.getValidField('Payment Term', 'paymentTerm'),
    ]);

    return (
      <Grid container spacing={8} style={{display: 'inline'}}>
        {this.props.order && (
          <div className="col-md-12 padding-reset" id='edit-order-review'>
            <Table style={{ marginTop: '20px' }}>
              <TableHead>
                <TableRow key='column-names'>
                  <TableCell style={{ borderBottom: '1px solid #000', width : '30%' }}></TableCell>
                  <TableCell className={this.classes.columnTitle}>Current</TableCell>
                  <TableCell className={this.classes.columnTitle}>Amended</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>

                {!isEmpty(FreightProvider) && this.getHeading('Freight Provider')}
                {!isEmpty(FreightProvider) && map(FreightProvider, (element, index) => this.getRow(element, index))}

                {!isEmpty(Customer) && this.getHeading('Customer')}
                {!isEmpty(Customer) && map(Customer, (element, index) => this.getRow(element, index))}

                {!isEmpty(GeneralDetail) && this.getHeading('General Details')}
                {!isEmpty(GeneralDetail) && map(GeneralDetail, (element, index)=> this.getRow(element, index))}

                {!isEmpty(PickUpDetail) && this.getHeading('Pickup Details')}
                {!isEmpty(PickUpDetail) && map(PickUpDetail, (element, index) => this.getRow(element, index))}

                {!isEmpty(DeliveryDetail) && this.getHeading('Delivery Details')}
                {!isEmpty(DeliveryDetail) && map(DeliveryDetail, (element, index) => this.getRow(element, index))}

                {canEditInvoicingSection &&
                !isEmpty(Invoicing) &&
                this.getHeading('Invoicing Details')}
                {canEditInvoicingSection &&
                  !isEmpty(Invoicing) &&
                  map(DeliveryDetail, (element, index) => this.getRow(element, index))}

                {(isCustomer || (isCreator && !isCustomerRegistered)) &&
                  this.getRow(
                    this.getValidField(
                      'Freight Rate In',
                      'rateFreightIn',
                    ),
                  )}
                {(isCustomer || (isCreator && !isCustomerRegistered)) &&
                  this.getRow(
                    this.getValidField(
                      'Freight Rate Out',
                      'rateFreightOut',
                    ),
                  )}

                {isFreightProvider &&
                  !isCustomer &&
                  !isCreator &&
                  this.getRow(
                    this.getValidField(
                      'Freight Rate',
                      'rateFreightOut',
                    ),
                  )}

                {canEditInvoicingSection &&
                  this.getConditions(
                    'General Conditions',
                    'generalConditions',
                  )}
                {canEditInvoicingSection &&
                  this.getConditions(
                    'Special Conditions',
                    'specialConditions',
                  )}
              </TableBody>
             </Table>
          </div>
        )}

        <div
          className="button-container col-md-12"
          style={{ marginTop: '10px', textAlign: 'right' }}
        >
          {this.amendable && this.isAmendRequestPending
            ? [
                <CommonButton
                  key="reject"
                  label="Reject"
                  secondary
                  variant="outlined"
                  onClick={() => this.handleRejectClickOpen()}
                />,
                <CommonButton
                  key="accept"
                  label="Accept"
                  primary
                  variant="contained"
                  onClick={this.confirmAmendRequestWrapper}
                />,
              ]
            : [
                <CommonButton
                  key="close"
                  label="Close"
                  secondary
                  variant="outlined"
                  onClick={this.props.closeSidebar}
                />,
              ]}
        </div>

        {this.amendable && (
          <div className="status-actions-wrap">
            <RejectionReasonDialog
              open={this.state.rejectDialogOpen.value}
              onClose={this.handleRejectClose}
              title="Reject Amend Request"
              value={this.state.rejectionReason.value}
              onChange={this.handleReasonChange}
              helperText={get(this.state, 'rejectionReason.errors[0]', '')}
              onCancel={this.handleRejectClose}
              onReject={this.handleRejectSubmit}
            />
          </div>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => state;

const mapDispatchToProps = dispatch => ({
  confirmAmendRequest: orderId => dispatch(confirmOrderAmend(orderId)),
  rejectAmendRequest: (orderId, data) =>
    dispatch(rejectOrderAmend(orderId, data)),
  isLoading: component => dispatch(isLoading(component)),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditOrderReview),
);

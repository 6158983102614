import { connect } from 'react-redux';
import GeneralConditionForm from '../components/conditions/GeneralConditionForm';
import { createGeneralConditions } from '../actions/companies/conditions';

function submit(data, onSuccess) {
    return (dispatch) => {
        dispatch(createGeneralConditions(data, onSuccess));
    };
}

export default connect(null, { submit })(GeneralConditionForm);

import React from 'react';

import { connect } from 'react-redux';
import {Paper, Autocomplete, Checkbox} from '@mui/material';
import { get, has, debounce } from 'lodash';
import APIService from '../../services/APIService';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import CommonButton from '../common/CommonButton';
import alertifyjs from 'alertifyjs';

class CompanyToCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingCompanies: false,
      selectedCompany: null,
      companyResults: [],
      companyInputText: '',
      includeParties: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.siteId = null;
    this.companyId = null;
    this.hasSetURLBasedAttrs = false;
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  handleSubmit(event) {
    const { selectedAsset } = this.props;

    const submitData = {
      asset: get(selectedAsset, 'entity'),
      asset_id: get(selectedAsset, 'id'),
      include_parties: this.state.includeParties
    };
    if (this.state.selectedCompany) {
      this.props.submit(this.state.selectedCompany.id, submitData);
      this.props.cancel();
    }
    if (!this.state.selectedCompany) {
      alertifyjs.error('Please select company');
    }
    event.preventDefault();

  }

  handleCompanyChange(event, item) {
    const { selectedCompany } = this.state;
    const text = get(item, 'name', '');
    if (!item || (item.id !== get(selectedCompany, 'id')))
      this.setState({
        selectedCompany: item, companyInputText: text, isLoadingCompanies: item ? this.state.isLoadingCompanies : false
      });
  }

  handleCompanyInputChange = (event, value) => {
    this.setState({ companyInputText: value, isLoadingCompanies: Boolean(value) }, () => {
      if (value && value.length > 2)
        this.fetchCompanies(value);
    });
  };

  fetchCompanies = debounce(searchStr => {
    const queryString = searchStr ? { search: searchStr } : {};
    APIService
      .companies()
      .appendToUrl('minimal/all/')
      .get(this.props.token, null, queryString)
      .then(items => {
        this.setState({ companyResults: items, isLoadingCompanies: false });
      });
  });

  isSearchableInput() {
    return get(this.state.companyInputText, 'length') > 2;
  }

  render() {
    const { isLoadingCompanies, companyResults, includeParties } = this.state;
    const isSearchableInput = this.isSearchableInput();
    if (this.props.selectedAsset.entity == 'ngr' && this.props.selectedAsset.ngrNumber.includes('UNKNOWN_'))
      return (<div style={{ paddingTop: "20px" }}>
        not transferable
      </div>);
    else
      return (
        <div style={{ paddingTop: "20px" }}>
          <Paper style={{ boxShadow: 'none' }}>
            <Autocomplete
              id="company"
              blurOnSelect
              options={companyResults || []}
              getOptionLabel={option => option.name}
              loading={isLoadingCompanies}
              loadingText={isSearchableInput ? 'Loading...' : 'Type at least 3 characters to search'}
              noOptionsText={this.state.companyInputText ? "No results" : 'Start typing...'}
              renderInput={params => (
                <TextField
                  {...params}
                  value={this.state.companyInputText}
                  variant="outlined"
                  placeholder="Company (Type at least 3 characters to search)"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {isLoadingCompanies ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  fullWidth
                />
              )}
              value={get(this.state, 'selectedCompany')}
              onChange={this.handleCompanyChange}
              onInputChange={this.handleCompanyInputChange}
            />
          </Paper>
          {
            has(this.props, 'includeParties') &&
            <div style={{marginLeft: '0%', paddingTop: "10px"}}>
              <Checkbox checked={includeParties} onChange={() =>this.setState({includeParties: !includeParties})} /> Include Parties
            </div>
          }
          {
            <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
              <CommonButton
                type="button"
                label="Cancel"
                default
                onClick={this.props.cancel}
                variant="contained"
              />
              <CommonButton
                type="submit"
                label="Save"
                primary
                onClick={this.handleSubmit}
                variant="contained"
              />
            </div>
          }
        </div>

      );
  }

}
const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    selectedCompany: state.main.user.user.company,
    isLoading: state.main.isLoading,
  };
};

export default connect(mapStateToProps)(CompanyToCompany);

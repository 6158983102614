import { connect } from 'react-redux';
import TruckForm from '../components/trucks/TruckForm';
import { createTruck } from '../actions/api/trucks';
import { addTruck } from '../actions/companies/trucks';

function submit(companyId, data, syncTrucks) {
  return (dispatch) => {
    dispatch(createTruck(companyId, data, addTruck, syncTrucks));
  };
}

export default connect(null, { submit })(TruckForm);

import APIService from '../../services/APIService';
import axios from 'axios';
import { get } from 'lodash';
import { forceStopLoader, apiURL } from '../main';
import { isAtGlobalVendorDecs, getPageCache, addPageCacheQueryParamsToUrl } from '../../common/utils';
import has from 'lodash/has';
import includes from 'lodash/includes';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version

export const GET_PAGINATED_VENDOR_DECS_SUCCESS = 'GET_PAGINATED_VENDOR_DECS_SUCCESS';
export const STORE_VENDOR_DEC_MOVEMENT_ENTITY = 'STORE_VENDOR_DEC_MOVEMENT_ENTITY';

export const getPaginatedVendorDecsResponse = result => {
  return { type: GET_PAGINATED_VENDOR_DECS_SUCCESS, result };
};

export const storeVendorDecMovementEntity = movementId => {
  return { type: STORE_VENDOR_DEC_MOVEMENT_ENTITY, movementId };
}

export const getVendorDecs = (url, _forceStopLoader, queryString) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    const movementId = get(getState().companies, 'vendorDecs.movementId');
    let URL = url ? url : `${apiURL}/vendor_decs/web/`;
    let cache = getPageCache();
    if (queryString)
      URL += queryString;

    let joiner = '?';
    if(URL.indexOf('?') > -1)
      joiner = '&';

    if(isAtGlobalVendorDecs()) {
      URL = addPageCacheQueryParamsToUrl(URL)
    } else if(has(cache, 'pageSize')) {
      const pageSize = get(cache, 'pageSize');
      let joiner = '?';
      if(URL.indexOf('?') > -1)
        joiner = '&';
      URL += `${joiner}page_size=${pageSize}`;
    }

    if(!isAtGlobalVendorDecs() && movementId) {
      if(!includes(URL, 'movement_id'))
        URL += `${joiner}movement_id=${movementId}`;
    }

    axios
      .get(URL, config)
      .then(response => {
        dispatch(getPaginatedVendorDecsResponse(response.data));
        if (_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch(error => {
        dispatch(getPaginatedVendorDecsResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const downloadVendorDecPDFs = (itemId, type) => {
  APIService.vendor_decs(itemId).appendToUrl(`attachments/?type=${type}`).get().then(url => {
    if(url)
      location.href = url;
  });
}

import findIndex from 'lodash/findIndex';
import {
  RECEIVE_EMPLOYEES,
  ADD_EMPLOYEE,
  EDIT_EMPLOYEE,
  CANCEL_EDIT_EMPLOYEE,
  CLICK_ADD_EMPLOYEE_BUTTON,
  CLICK_EDIT_EMPLOYEE_ICON,
  ADD_EMPLOYEE_ERRORS,
  EDIT_EMPLOYEE_ERRORS,
  DELETE_EMPLOYEE,
  ARCHIVED_EMPLOYEE,
  ACTIVATED_EMPLOYEE,
  GET_PAGINATED_TEMPLATES_SUCCESS,
  GET_TEMPLATES_SUCCESS,
  SET_RESPONSE_RECEIVED
} from '../../actions/company-settings/employees';
import filter from 'lodash/filter';

const initialState = {
  items: [],
  archivedItems: [],
  selectedEmployeeId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: []
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_EMPLOYEES:
      return { 
        ...state, 
        items: action.items,
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: []
       };
    case ADD_EMPLOYEE:
      return {
        ...state,
        items: [...state.items, action.item],
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_EMPLOYEE_ERRORS:
      return {
        ...state,
        selectedEmployeeId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_EMPLOYEE: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    }
    case EDIT_EMPLOYEE_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_EMPLOYEE:
      return {
        ...state,
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case CLICK_ADD_EMPLOYEE_BUTTON:
      return {
        ...state,
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: true,
        isUpdateFormDisplayed: false,
        serverErrors: [],
        responseReceived: false,
      };
    case CLICK_EDIT_EMPLOYEE_ICON:
      return {
        ...state,
        selectedEmployeeId: action.index.id,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: true,
        serverErrors: [],
      };
    case DELETE_EMPLOYEE: {
      const updatedItems = filter(state.items, i => {return i.id != action.item.id;});
        return {
          ...state,
          items: [...updatedItems],
          selectedEmployeeId: undefined,
          isCreateFormDisplayed: false,
          isUpdateFormDisplayed: false,
          serverErrors: [],
        };
    }
    case ARCHIVED_EMPLOYEE:
      return {
        ...state,
        archivedItems: action.items,
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: []
      };
    case ACTIVATED_EMPLOYEE:{
      const updatedItems = filter(state.archivedItems, i => {return i.id != action.item.id;});
      return {
        ...state,
        archivedItems: updatedItems,
        selectedEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: []
      };
    }
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
      };
    case GET_PAGINATED_TEMPLATES_SUCCESS:
        return {
          ...state,
          templates: action.paginatedTemplates.results,
          paginationData: action.paginatedTemplates,
        };
    case SET_RESPONSE_RECEIVED:
      return{
        ...state,
        responseReceived: action.responseReceived
      }
    default:
      return state;
  }
};

export default reducer;

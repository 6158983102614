import React from 'react';
import { maxLength, minLength, minValue, numRegex, required } from '../../common/validators';
import CommonSelect from '../common/select/CommonSelect';
import SideDrawer from '../common/SideDrawer';
import { get, map, set, includes, forEach, some, mapValues, filter, omit, isEqual, cloneDeep, compact } from 'lodash';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import CommonButton from '../common/CommonButton';
import isEmpty from 'lodash/isEmpty';
import APIService from '../../services/APIService';
import alertify from 'alertifyjs';
import alertifyjs from 'alertifyjs';
import { Autocomplete, TextField, Button, FormControlLabel, Checkbox } from '@mui/material';
import {
  ALERT_NAMES, MASS_LIMIT_BREACH_ALERT_CODE, SPEC_AVG_REPORT_ALERT_CODE, DELIVERY_CONFIRMATION_SMS_ALERT_CODE, FREQUENCY_DAILY,
  FREQUENCY_WEEKLY, FREQUENCY_MONTHLY, ALERT_ALREADY_CONFIGURED_ERRORS, CLASS_1_MATERIAL_ALERT_CODE, MASS_LIMIT_ALERT_DESCRIPTION, RECEIVAL_REPORT_DESCRIPTION, DELIVERY_CONFIRMATION_SMS_ALERT_DESCRIPTION, CLASS_1_MATERIAL_ALERT_DESCRIPTION, PICKUP_CONFIRMATION_ALERT_CODE, PICKUP_CONFIRMATION_ALERT_DESCRIPTION, DELIVERY_CONFIRMATION_ALERT_CODE, DELIVERY_CONFIRMATION_ALERT_DESCRIPTION, EMAIL_ALERTS, SMS_ALERTS, OWN_COMPANY_RECIPIENT_ALERTS, CUSTOMER_RECIPIENT_ALERTS, FREIGHT_PROVIDER_RECIPIENT_ALERTS, STOCK_OWNER_RECIPIENT_ALERTS, SUB_FREIGHT_PROVIDER_RECIPIENT_ALERTS,
  OUTLOAD_REPORT_ALERT_CODE, OUTLOAD_REPORT_ALERT_DESCRIPTION, SELLER_RECIPIENT_ALERTS, SLOT_BOOKING_UPDATE_ALERT_CODE, SLOT_BOOKING_UPDATE_ALERT_DESCRIPTION, BUYER_RECIPIENT_ALERTS, TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE, TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_DESCRIPTION, ALERTS_WITH_HOURS_BEFORE_TRIGGER, PICKUP_CONFIRMATION_SMS_ALERT_CODE, PICKUP_CONFIRMATION_SMS_ALERT_DESCRIPTION, TRUCK_OWNER_RECIPIENT_ALERTS, MISSING_DOCKET_ALERT_CODE, MISSING_DOCKET_ALERT_DESCRIPTION, DOCKET_CLARIFICATION_ALERT_CODE, DOCKET_CLARIFICATION_ALERT_DESCRIPTION,
  FREQUENCEY_DAILY_AND_WEEKLY, SITE_WISE_ALERTS, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_DESCRIPTION,
  STOCK_OPERATIONS_UPDATE_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE, ACQUISITION_REPORT_ALERT_DESCRIPTION
} from './constants';
import CommonTextField from '../common/CommonTextField';
import AddButton from '../common/AddButton';
import ContentRemove from '@mui/icons-material/Remove';
import uniqBy from 'lodash/uniqBy';

const alertDescriptions = {
  [MASS_LIMIT_BREACH_ALERT_CODE]: MASS_LIMIT_ALERT_DESCRIPTION,
  [SPEC_AVG_REPORT_ALERT_CODE]: RECEIVAL_REPORT_DESCRIPTION,
  [DELIVERY_CONFIRMATION_SMS_ALERT_CODE]: DELIVERY_CONFIRMATION_SMS_ALERT_DESCRIPTION,
  [PICKUP_CONFIRMATION_SMS_ALERT_CODE]: PICKUP_CONFIRMATION_SMS_ALERT_DESCRIPTION,
  [CLASS_1_MATERIAL_ALERT_CODE]: CLASS_1_MATERIAL_ALERT_DESCRIPTION,
  [PICKUP_CONFIRMATION_ALERT_CODE]: PICKUP_CONFIRMATION_ALERT_DESCRIPTION,
  [DELIVERY_CONFIRMATION_ALERT_CODE]: DELIVERY_CONFIRMATION_ALERT_DESCRIPTION,
  [OUTLOAD_REPORT_ALERT_CODE]: OUTLOAD_REPORT_ALERT_DESCRIPTION,
  [SLOT_BOOKING_UPDATE_ALERT_CODE]: SLOT_BOOKING_UPDATE_ALERT_DESCRIPTION,
  [MISSING_DOCKET_ALERT_CODE]: MISSING_DOCKET_ALERT_DESCRIPTION,
  [MISSING_DOCKET_PICKUP_SITE_ALERT_CODE]: MISSING_DOCKET_PICKUP_SITE_ALERT_DESCRIPTION,
  [TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_CODE]: TRUCK_CLEANLINESS_DECLARATION_NOT_FILLED_ALERT_DESCRIPTION,
  [DOCKET_CLARIFICATION_ALERT_CODE]: DOCKET_CLARIFICATION_ALERT_DESCRIPTION,
  [STOCK_OPERATIONS_UPDATE_ALERT_CODE]: DOCKET_CLARIFICATION_ALERT_DESCRIPTION,
  [ACQUISITION_REPORT_ALERT_CODE]: ACQUISITION_REPORT_ALERT_DESCRIPTION
};

class AlertForm extends React.Component {
  constructor(props) {
    super(props);
    this.handlerFields = {
      handlerId: null,
      handler: null,
      employeeRoles: {
        value: [],
        errors: [],
        validators: []
      },
      employeeIds: {
        value: [],
        errors: [],
        validators: []
      },
      recipientIds: {
        value: [],
        errors: [],
        validators: []
      },
      position: 1,
    },
    this.state = {
      open: false,
      description: undefined,
      employees: [],
      alertNames: ALERT_NAMES,
      sites: [],
      ownCompanyRecipientsAllSitesSelected: true,
      ownCompanySiteRecipients: [],
      selectedSiteIds: [],
      includeLoadsAtMySite: true,
      fields: {
        name: {
          value: undefined,
          errors: [],
          validators: [required()]
        },
        channel: {
          value: [],
          validators: [required()],
          errors: [],
        },
        hoursBeforeTrigger: {
          value:undefined,
          validators: [numRegex(), minLength(1), maxLength(2)],
          errors: [],
        },
        customerRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        deliverySite: {
          value: [],
          validators: [],
          errors: [],
        },
        pickupSite: {
          value: [],
          validators: [],
          errors: [],
        },
        freightProviderRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        subFreightProviderRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        truckOwnerRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        stockOwnerRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        sellerRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        buyerRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        ownCompanyEmployeeRolesRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        ownCompanyEmployeeRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        ownCompanyRecipients: {
          value: [],
          validators: [],
          errors: [],
        },
        frequency: {
          value: 'daily',
          validators: [],
          errors: [],
        },
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { open, alert } = this.props;
    const newState = {...this.state};
    APIService.companies(this.props.companyId).appendToUrl('company_sites/minimal/').get().then(response => this.setState({sites: filter(response, site => site?.isActive)}));
    if (alert) {
      this.fetchEmployees();
      let alertName = get(alert, 'name')
      newState.fields.name.value = alertName;
      newState.fields.channel.value = get(alert, 'channel');
      newState.description = alertDescriptions[alertName];
      newState.fields.hoursBeforeTrigger.value = get(alert, 'hoursBeforeTrigger');
      newState.includeLoadsAtMySite = get(alert, 'includeOwnSiteLoadsInAcquisitionReport', false);
      if (includes(ALERTS_WITH_HOURS_BEFORE_TRIGGER, alertName)) {
        newState.fields.hoursBeforeTrigger.validators.push(minValue(1));
        newState.fields.hoursBeforeTrigger.validators.push(required());
      }
      let recipients = get(alert, 'recipients');
      let siteRecipients = get(alert, 'siteRecipients');
      newState.ownCompanyRecipientsAllSitesSelected = isEmpty(siteRecipients);
      if (recipients) {
        forEach(recipients, recipient => {
          let party = get(recipient, 'party');
          let employeeRoles = get(recipient, 'employeeRoles') || []
          if (party === 'customer')
            newState.fields.customerRecipients.value = employeeRoles;
          else if (party === 'delivery_site')
            newState.fields.deliverySite.value = employeeRoles;
          else if (party === 'pickup_site')
            newState.fields.pickupSite.value = employeeRoles;
          else if (party === 'freight_provider')
            newState.fields.freightProviderRecipients.value = employeeRoles;
          else if (party === 'stock_owner')
            newState.fields.stockOwnerRecipients.value = employeeRoles;
          else if (party === 'sub_freight_provider')
            newState.fields.subFreightProviderRecipients.value = employeeRoles;
          else if (party === 'seller')
            newState.fields.sellerRecipients.value = employeeRoles;
          else if (party === 'buyer')
            newState.fields.buyerRecipients.value = employeeRoles;
          else if (party === 'truck_owner')
            newState.fields.truckOwnerRecipients.value = employeeRoles;
          else if (party === 'own_company') {
            let employees = get(recipient, 'employees') || [];
            newState.fields.ownCompanyEmployeeRolesRecipients.value = employeeRoles;
            newState.fields.ownCompanyEmployeeRecipients.value = employees;
            newState.fields.ownCompanyRecipients.value = [...employeeRoles, ...employees];
          }
        });
      }

      if(siteRecipients) {
        forEach(siteRecipients, (siteRecipient, index) => {
          newState.ownCompanySiteRecipients.push(cloneDeep(this.handlerFields));
          let employeeRoles = get(siteRecipient, 'recipient.employeeRoles') || [];
          let employees = get(siteRecipient, 'recipient.employees') || [];
          newState.ownCompanySiteRecipients[index].handlerId = get(siteRecipient, 'site.id');
          newState.ownCompanySiteRecipients[index].handler = get(siteRecipient, 'site');
          newState.ownCompanySiteRecipients[index].employeeRoles.value = employeeRoles;
          newState.ownCompanySiteRecipients[index].employeeIds.value = employees;
          newState.ownCompanySiteRecipients[index].recipientIds.value = compact([...employeeRoles, ...employees]);
          newState.ownCompanySiteRecipients[index].position = index + 1;
          newState.selectedSiteIds = uniqBy([...newState.selectedSiteIds, get(siteRecipient, 'site.id')]);
          newState.ownCompanySiteRecipients[index].recipientIds.validators = [required()];
        });
      }
      newState.fields.frequency.value = get(alert, 'frequency');
    }
    if (window.location.hash.includes('stocks') && !alert) {
      newState.fields.name.value = ACQUISITION_REPORT_ALERT_CODE;
      newState.description = alertDescriptions[ACQUISITION_REPORT_ALERT_CODE];
      newState.fields.channel.value = 'Email';
      this.fetchEmployees();
    }
    newState.open = open;
    this.setState(newState, () => {
      if (includes([SPEC_AVG_REPORT_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, MISSING_DOCKET_ALERT_CODE, DOCKET_CLARIFICATION_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE], this.state.fields.name.value)){
        const alertFrequency = this.getAlertSchedule();
        this.setFieldValue(`fields.frequency`, get(alertFrequency, '0.id'));
      }
    });
  }

  onClose = () => this.setState({open: false}, () => this.props.onClose());

  setFieldValue(path, value, validateAfterSet = true, callback) {
    this.setState(
      state => set(state, `${path}.value`, value),
      () => {
        if (validateAfterSet) this.setFieldErrors(path);
        if (callback) callback(this.state);
      }
    );
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  fetchEmployees() {
    APIService.contracts()
      .appendToUrl(`companies/${this.props.companyId}/contacts/`)
      .get()
      .then(res => this.setState({employees: res}));
  }

  handleNameChange = (value, id) => {
    this.setFieldValue(`fields.${id}`, value, true, () => {
      if (includes([SPEC_AVG_REPORT_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, MISSING_DOCKET_ALERT_CODE, DOCKET_CLARIFICATION_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE], value)){
        const alertFrequency = this.getAlertSchedule();
        this.setFieldValue(`fields.frequency`, get(alertFrequency, '0.id'));
      }
    });
    if (id === 'name') {
      if (value) {
        if (includes(OWN_COMPANY_RECIPIENT_ALERTS, value) && isEmpty(this.state.employees)) {
          this.fetchEmployees();
        }
        APIService.alerts()
          .appendToUrl(`can-configure-alert/${value}/?company_id=${this.props.companyId}`)
          .get()
          .then(res => {
            const newState = {...this.state};
            if (get(res, 'canConfigureAlert')) {
              newState.fields.name.errors = [];
              newState.description = alertDescriptions[value];
              if (includes(EMAIL_ALERTS, value))
                newState.fields.channel.value = "email";
              else if (includes(SMS_ALERTS, value))
                newState.fields.channel.value = "sms";
            }
            else {
              newState.fields.name.errors = [ALERT_ALREADY_CONFIGURED_ERRORS]
            }
            if (includes(ALERTS_WITH_HOURS_BEFORE_TRIGGER, value)) {
              newState.fields.hoursBeforeTrigger.validators.push(minValue(1))
              newState.fields.hoursBeforeTrigger.validators.push(required())
            }
            else
              newState.fields.hoursBeforeTrigger.validators = []
            this.setState(newState);
          });
      }
    }
  };

  handleSelectChange = (value, id) => this.setFieldValue(`fields.${id}`, value, true);

  onMultiSelectChange = (id, selectedItems) => {
    const newState = {...this.state};
    newState.fields[id].value = map(selectedItems, 'id');
    this.setState(newState, () => this.setFieldErrors(`fields.${id}`));
  };

  handleHoursBeforeTriggerChange = event => this.setFieldValue(`fields.${event.target.id}`, event.target.value);

  getPartyRecipients(party) {
    let recipients = [
      {name:'Site Admin', id: 'site_admin'},
      {name: 'Company Admin', id: 'company_admin'},
      {name: 'Key Contact', id: 'key_contact'},
      {name: 'Office Admin', id: 'office_admin'},
      {name: 'Office Employee', id:'office_employee'},
      {name: 'Site/Farm Employee', id:'site_employee'}
    ];
    if (party === 'ownCompany') {
      recipients = recipients.filter(recipient => recipient.id !== 'key_contact').map(recipient => {
        return {
          category: 'Employee Roles',
          ...recipient,
        }
      });
      if (!isEmpty(this.state.employees))
        this.state.employees.map(employee => recipients.push({id: employee.id, name: employee.name, email: employee.email, category: 'All Users'}));
    }
    if (includes(['freightProvider', 'truckOwner'], party))
      recipients.push({name: 'Driver', id: 'driver'})
    return recipients;
  }

  getAlertSchedule() {
    const alertName = this.state.fields.name.value;
    if (includes([MISSING_DOCKET_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE], alertName))
      return [{name:'Weekly', id: FREQUENCY_WEEKLY}]
    if (includes([SPEC_AVG_REPORT_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE], alertName))
      return [{name:'Daily', id: FREQUENCY_DAILY}];
    if (isEqual(DOCKET_CLARIFICATION_ALERT_CODE,  alertName))
      return [{name:'Daily and Weekly', id: FREQUENCEY_DAILY_AND_WEEKLY}];
  }

  getIsFormInvalid() {
    let isFieldInvalid = some(this.state.fields, field => this.getIsFieldInvalid(field))
    let isRecipientInvalid = this.state.ownCompanyRecipientsAllSitesSelected ? false : some(this.state.ownCompanySiteRecipients, siteRecipient => this.getIsFieldInvalid(siteRecipient.recipientIds))

    return isFieldInvalid || isRecipientInvalid;
  }

  getIsFieldInvalid(field) {
    return some(field.validators, (validator) => {
      return validator.isInvalid(field.value);
    });
  }

  setAllFieldsErrors(fieldsToOmit) {
    forEach(omit(this.state.fields, fieldsToOmit), (value, key) => {
        this.setFieldErrors(`fields.${key}`);
    });
    if(!this.state.ownCompanyRecipientsAllSitesSelected)
      forEach(this.state.ownCompanySiteRecipients, (_, index) => this.setFieldErrors(`ownCompanySiteRecipients[${index}].recipientIds`))
  }

  isTruckRecipientsChanged() {
    let isChanged = false
    let truckRecipients = this.state.fields.truckOwnerRecipients.value
    if(this.props.alert) {
      forEach(this.props.alert.recipients, recipient => {
        if(recipient.party == 'truck_owner') {
          if (recipient.employeeRoles.length == truckRecipients.length) {
            map(truckRecipients, role => {
              if(!includes(recipient.employeeRoles, role))
                isChanged = true
            })
          } else
            isChanged = true
        }
      })
    }
    return isChanged
  }

  checkIfTruckOwnerRecipientsHasOtherRolesSelected() {
    let recipients = this.state.fields.truckOwnerRecipients.value
    let isTruckRecipientsChanged = get(this.props, 'alert') ? this.isTruckRecipientsChanged() : true
    return isTruckRecipientsChanged && (recipients.length > 1 || (recipients.length == 1 && recipients[0] != 'driver'))
  }

  getOwnCompanyRecipientsSiteWise = () => {
    let ownCompanyRecipients = [];
    forEach(this.state.ownCompanySiteRecipients, site => {
      let employeeRoles = site.employeeRoles.value;
      let employeeIds = site.employeeIds.value;
      if (!isEmpty(employeeRoles) || !isEmpty(employeeIds))
        ownCompanyRecipients.push({
          'siteId': site?.handlerId,
          'recipient': {
            'employeeRoles': employeeRoles,
            'employees': employeeIds,
            'party': 'own_company',
          }
        });
    });
    return ownCompanyRecipients;
  }

  getSubmitData() {
    let data = mapValues(this.state.fields, 'value');
    let recipientsData = []
    let customerRecipients = get(data, 'customerRecipients');
    let deliverySite = get(data, 'deliverySite');
    let pickupSite = get(data, 'pickupSite');
    let freightProviderRecipients = get(data, 'freightProviderRecipients');
    let subFreightProviderRecipients = get(data, 'subFreightProviderRecipients');
    let ownCompanyRecipients = this.state.ownCompanyRecipientsAllSitesSelected ? get(data, 'ownCompanyRecipients') : this.getOwnCompanyRecipientsSiteWise();
    let ownCompanyEmployeeRoleRecipients = get(data, 'ownCompanyEmployeeRolesRecipients');
    let ownCompanyEmployeeRecipients = get(data, 'ownCompanyEmployeeRecipients')
    let stockOwnerRecipients = get(data, 'stockOwnerRecipients');
    let sellerRecipients = get(data, 'sellerRecipients');
    let buyerRecipients = get(data, 'buyerRecipients');
    let truckOwnerRecipients = get(data, 'truckOwnerRecipients');
    if (customerRecipients && !isEmpty(customerRecipients)) {
      recipientsData.push({'employeeRoles': customerRecipients, 'party': 'customer'});
    }
    if (deliverySite && !isEmpty(deliverySite)) {
      recipientsData.push({'employeeRoles': deliverySite, 'party': 'delivery_site'});
    }
    if (pickupSite && !isEmpty(pickupSite)) {
      recipientsData.push({'employeeRoles': pickupSite, 'party': 'pickup_site'});
    }
    if (freightProviderRecipients && !isEmpty(freightProviderRecipients)) {
      recipientsData.push({'employeeRoles': freightProviderRecipients, 'party': 'freight_provider'});
    }
    if (subFreightProviderRecipients && !isEmpty(subFreightProviderRecipients)) {
      recipientsData.push({'employeeRoles': subFreightProviderRecipients, 'party': 'sub_freight_provider'});
    }
    if (sellerRecipients && !isEmpty(sellerRecipients)) {
      recipientsData.push({'employeeRoles': sellerRecipients, 'party': 'seller'});
    }
    if (buyerRecipients && !isEmpty(buyerRecipients)) {
      recipientsData.push({'employeeRoles': buyerRecipients, 'party': 'buyer'});
    }
    if (ownCompanyRecipients && !isEmpty(ownCompanyRecipients) && this.state.ownCompanyRecipientsAllSitesSelected) {
      let ownCompanyRecipientsData = {
        party: 'own_company'
      };
      if (ownCompanyEmployeeRoleRecipients && !isEmpty(ownCompanyEmployeeRoleRecipients)) {
        ownCompanyRecipientsData['employeeRoles'] = ownCompanyEmployeeRoleRecipients;
      }
      if (ownCompanyEmployeeRecipients && !isEmpty(ownCompanyEmployeeRecipients)) {
        ownCompanyRecipientsData['employees'] = ownCompanyEmployeeRecipients;
      }
      recipientsData.push(ownCompanyRecipientsData);
    }
    if (stockOwnerRecipients && !isEmpty(stockOwnerRecipients)) {
      recipientsData.push({'employeeRoles': stockOwnerRecipients, 'party': 'stock_owner'});
    }
    if (truckOwnerRecipients && !isEmpty(truckOwnerRecipients)) {
      recipientsData.push({'employeeRoles': truckOwnerRecipients, 'party': 'truck_owner'});
    }
    delete data['customerRecipients'];
    delete data['deliverySite'];
    delete data['pickupSite'];
    delete data['freightProviderRecipients'];
    delete data['subFreightProviderRecipients'];
    delete data['ownCompanyRecipients'];
    delete data['ownCompanyEmployeeRolesRecipients'];
    delete data['ownCompanyEmployeeRecipients'];
    delete data['stockOwnerRecipients'];
    delete data['sellerRecipients'];
    delete data['buyerRecipients'];
    delete data['truckOwnerRecipients']
    if (includes([STOCK_OPERATIONS_UPDATE_ALERT_CODE], this.state.fields.name.value) || !includes([FREQUENCY_DAILY, FREQUENCY_WEEKLY, FREQUENCY_MONTHLY, FREQUENCEY_DAILY_AND_WEEKLY], data.frequency))
      delete data['frequency'];
    data['recipients'] = recipientsData;
    if (!this.state.ownCompanyRecipientsAllSitesSelected && includes(SITE_WISE_ALERTS, this.state.fields.name.value))
      data['siteRecipients'] = ownCompanyRecipients;
    data['companyId'] = this.props.companyId;
    if (this.state.fields.name.value == ACQUISITION_REPORT_ALERT_CODE)
      data['includeOwnSiteLoadsInAcquisitionReport'] = this.state.includeLoadsAtMySite;
    return data;
  }

  submit(data) {
    const { alert } = this.props;

    const handleResponse = () => {
    this.onClose();
    window.location.reload();
    };

    if (alert)
      APIService.alerts(alert.id).put(data).then(handleResponse);
    else
      APIService.alerts().post(data).then(handleResponse);
  }

  handleSubmit = event => {
    event.preventDefault();
    let fieldsToOmit = get(this.state.fields.name.errors, '0') === ALERT_ALREADY_CONFIGURED_ERRORS ? ['name'] : [];
    this.setAllFieldsErrors(fieldsToOmit);
    if (!this.getIsFormInvalid()) {
      let data = this.getSubmitData();
      if (isEmpty(data['recipients']) && isEmpty(data['siteRecipients'])) {
        alertify.error('No recipient selected');
      }
      else {
        if (includes(TRUCK_OWNER_RECIPIENT_ALERTS, this.state.fields.name.value) && this.checkIfTruckOwnerRecipientsHasOtherRolesSelected()) {
          let recipients = [
            { name: 'Site Admin', id: 'site_admin' },
            { name: 'Company Admin', id: 'company_admin' },
            { name: 'Key Contact', id: 'key_contact' },
            { name: 'Office Admin', id: 'office_admin' },
            { name: 'Office Employee', id: 'office_employee' },
            { name: 'Site/Farm Employee', id: 'site_employee' }
          ];
          let truckRecipients = this.state.fields.truckOwnerRecipients.value.filter(id => id != 'driver')
          let truckOwnerRecipientNames = map(truckRecipients, id => {
            let recipient = recipients.find(recipient => recipient.id === id);
            return recipient ? recipient.name : null;
          });
          alertifyjs.confirm(
            'Warning',
            `All the ${truckOwnerRecipientNames.join(', ')} within the company will be sent separate SMS and these are chargeable based on number of sms's sent. Would you like to proceed?`,
            () => {this.submit(data)},
            () => {},
          ).set({'reverseButtons': true})
        }
        else
          this.submit(data)
      }
    }
  };

  getChannel() {
    let alertName = this.state.fields.name.value;
    if (includes(EMAIL_ALERTS, alertName)) {
      return [{name: 'Email', id: 'email'}];
    }
    if (includes(SMS_ALERTS, alertName)) {
      return [{name: 'SMS', id: 'sms'}];
    }
  }

  handleOwnCompanyRecipientsChange = (event, selectedItems, index) => {
    const newState = {...this.state};
    if (this.state.ownCompanyRecipientsAllSitesSelected) {
    newState.fields.ownCompanyRecipients.value = map(selectedItems, 'id');
    newState.fields.ownCompanyEmployeeRolesRecipients.value = map(filter(selectedItems, item => item.category === 'Employee Roles'), 'id');
    newState.fields.ownCompanyEmployeeRecipients.value = map(filter(selectedItems, item => item.category === 'All Users'), 'id');
    } else {
      newState.ownCompanySiteRecipients[index].recipientIds.value = map(selectedItems, 'id');
      newState.ownCompanySiteRecipients[index].employeeRoles.value = map(filter(selectedItems, item => item.category === 'Employee Roles'), 'id');
      newState.ownCompanySiteRecipients[index].employeeIds.value = map(filter(selectedItems, item => item.category === 'All Users'), 'id');
    }
    let path = this.state.ownCompanyRecipientsAllSitesSelected ? 'fields.ownCompanyRecipients' : `ownCompanySiteRecipients[${index}].recipientIds`;
    this.setState(newState, () => this.setFieldErrors(path));
  }

  handleAddSite = () => {
    const newState = { ...this.state };
    newState.ownCompanySiteRecipients.push(cloneDeep(this.handlerFields));
    this.setState(newState);
  };

  handleForAllSite = () => this.setState({ownCompanyRecipientsAllSitesSelected: true})

  handleSiteWise = () => {
    const newState = { ...this.state };
    newState.ownCompanyRecipientsAllSitesSelected = false
    if (newState.ownCompanySiteRecipients.length == 0)
      newState.ownCompanySiteRecipients.push(cloneDeep(this.handlerFields))
    this.setState(newState);
  }

  handleSiteChange = (value, index) => {
    const newState = { ...this.state };
    newState.ownCompanySiteRecipients[index].handler = value;
    newState.ownCompanySiteRecipients[index].handlerId = value?.id;
    newState.ownCompanySiteRecipients[index].position = parseInt(index) + 1;
    newState.selectedSiteIds = map(newState.ownCompanySiteRecipients, 'handlerId');
    newState.ownCompanySiteRecipients[index].recipientIds.validators = [required()];
    this.setState(newState);
  }

  handleRemoveSiteRecipients = index => {
    const newState = { ...this.state };
    newState.ownCompanySiteRecipients.splice(index, 1);
    newState.selectedSiteIds = map(newState.ownCompanySiteRecipients, 'handlerId');
    if (newState.ownCompanySiteRecipients.length == 0)
      newState.ownCompanyRecipientsAllSitesSelected = true
    this.setState(newState);
  };

  handleCheckboxClick = () => this.setState({includeLoadsAtMySite: !this.state.includeLoadsAtMySite});

  render() {
    const ERROR_STYLE = { textAlign: 'left' };
    const { fields } = this.state;
    const ownCompanyOptions = this.getPartyRecipients('ownCompany');
    return (
      <SideDrawer isOpen={this.state.open} title='New Alert' onClose={this.onClose} size='medium'>
        <form noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap" style={{marginTop: '15px', marginBottom: "-25px"}}>
                <CommonSelect
                  id="name"
                  floatingLabelText="Name"
                  value={fields.name.value}
                  selectedItemId={fields.name.value}
                  errorText= {get(fields.name, 'errors[0]', '')}
                  selectConfig={{text: 'name', value: 'id'}}
                  onChange={this.handleNameChange}
                  items={this.state.alertNames}
                  errorStyle={ERROR_STYLE}
                  varient={null}
                  disabled={this.props.alert || (window.location.hash.includes('stocks') && !this.props.alert)}
                />
              </div>
              {this.state.description &&
              <div className="col-sm-12" style={{fontSize: '13px', fontWeight: 350}}>
                <span>Description: {this.state.description}</span>
              </div>
              }
              {
                includes(ACQUISITION_REPORT_ALERT_CODE, fields.name.value) &&
                <div className='col-sm-12 form-wrap' style={{marginBottom: '-30px'}}>
                <FormControlLabel
                  control={
                      <Checkbox
                          color="primary"
                          id="includeLoadsAtMySite"
                          checked={this.state.includeLoadsAtMySite}
                          onChange={this.handleCheckboxClick}
                      />
                  }
                  label="Include loads at My Sites"
                />
                </div>
              }
              <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                <CommonSelect
                  id="channel"
                  items={this.getChannel()}
                  value={fields.channel.value}
                  selectedItemId={fields.channel.value}
                  onChange={this.handleSelectChange}
                  floatingLabelText='Alert Type'
                  errorText={get(fields.channel, 'errors[0]', '')}
                  variant='standard'
                  selectConfig={{text: 'name', value: 'id'}}
                />
              </div>
              {includes(ALERTS_WITH_HOURS_BEFORE_TRIGGER, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonTextField
                    id="hoursBeforeTrigger"
                    label="Hours Before Trigger"
                    type="number"
                    placeholder="Hours Before Trigger"
                    onChange={this.handleHoursBeforeTriggerChange}
                    value={fields.hoursBeforeTrigger.value}
                    onInput={(e) => {
                      e.target.value = e.target.value.toString().slice(0, 2);
                    }}
                    helperText={fields.hoursBeforeTrigger.errors[0]}
                  />
                </div>
              }
              {includes(CUSTOMER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="customerRecipients"
                    items={this.getPartyRecipients('customer')}
                    selectedItems={fields.customerRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Customer Recipients'
                    error={get(fields.customerRecipients, 'errors[0]', '')}
                    helperText={get(fields.customerRecipients, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(FREIGHT_PROVIDER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="freightProviderRecipients"
                    items={this.getPartyRecipients('freightProvider')}
                    selectedItems={fields.freightProviderRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Freight Provider Recipients'
                    error={get(fields.freightProviderRecipients, 'errors[0]', '')}
                    helperText={get(fields.freightProviderRecipients, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(PICKUP_CONFIRMATION_ALERT_CODE, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="deliverySite"
                    items={this.getPartyRecipients('deliverySite')}
                    selectedItems={fields.deliverySite.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Delivery Site Recipients'
                    error={get(fields.deliverySite, 'errors[0]', '')}
                    helperText={get(fields.deliverySite, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(DELIVERY_CONFIRMATION_ALERT_CODE, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="pickupSite"
                    items={this.getPartyRecipients('pickupSite')}
                    selectedItems={fields.pickupSite.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Pickup Site Recipients'
                    error={get(fields.pickupSite, 'errors[0]', '')}
                    helperText={get(fields.pickupSite, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(SUB_FREIGHT_PROVIDER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="subFreightProviderRecipients"
                    items={this.getPartyRecipients('freightProvider')}
                    selectedItems={fields.subFreightProviderRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Sub Freight Provider Recipients'
                    error={get(fields.subFreightProviderRecipients, 'errors[0]', '')}
                    helperText={get(fields.subFreightProviderRecipients, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(TRUCK_OWNER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="truckOwnerRecipients"
                    items={this.getPartyRecipients('truckOwner')}
                    selectedItems={fields.truckOwnerRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Truck Owner Recipients'
                    error={fields.truckOwnerRecipients.errors[0]}
                    helperText={fields.truckOwnerRecipients.errors[0]}
                    variant='standard'
                  />
                </div>
              }
              {includes(STOCK_OWNER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="stockOwnerRecipients"
                    items={this.getPartyRecipients('stockOwner')}
                    selectedItems={fields.stockOwnerRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Stock Owner Recipients'
                    error={get(fields.stockOwnerRecipients, 'errors[0]', '')}
                    helperText={get(fields.stockOwnerRecipients, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(SELLER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="sellerRecipients"
                    items={this.getPartyRecipients('seller')}
                    selectedItems={fields.sellerRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Seller Recipients'
                    error={get(fields.sellerRecipients, 'errors[0]', '')}
                    helperText={get(fields.sellerRecipients, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(BUYER_RECIPIENT_ALERTS, fields.name.value) &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                  <CommonMultiSelect
                    id="buyerRecipients"
                    items={this.getPartyRecipients('buyer')}
                    selectedItems={fields.buyerRecipients.value}
                    displayField='name'
                    onChange={this.onMultiSelectChange}
                    label='Buyer Recipients'
                    error={get(fields.buyerRecipients, 'errors[0]', '')}
                    helperText={get(fields.buyerRecipients, 'errors[0]', '')}
                    variant='standard'
                  />
                </div>
              }
              {includes(OWN_COMPANY_RECIPIENT_ALERTS, fields.name.value) && this.state.ownCompanyRecipientsAllSitesSelected &&
                <div className="col-sm-12 form-wrap" style={{marginTop: '30px'}}>
                  <Autocomplete
                    multiple
                    disableCloseOnSelect
                    id="ownCompanyRecipients"
                    options={ownCompanyOptions}
                    groupBy={item => item.category}
                    getOptionLabel={item => `${item.name} ${isEqual(item.email, '') ? '- Email Not Found' : ''}`}
                    renderInput={(params) => (
                      <TextField
                       label="Own Company Recipients"
                       helperText={get(fields.ownCompanyRecipients, 'errors[0]', '')}
                       error={get(fields.ownCompanyRecipients, 'errors[0]', '')}
                       {...params}
                       variant='standard'
                      />
                    )}
                    value={ filter(ownCompanyOptions, item => includes(fields.ownCompanyRecipients.value, get(item, 'id'))) }
                    onChange={this.handleOwnCompanyRecipientsChange}
                    getOptionDisabled={(option) => isEqual(option.email, '')} // Disable options with no email
                  />
                </div>
              }
              {
                includes(SITE_WISE_ALERTS, fields.name.value) && !this.state.ownCompanyRecipientsAllSitesSelected &&
                <div className='col-md-12 form-wrap' style={{marginTop: '5px'}}>
                <h6 style={{font: 'inherit', color: 'rgba(0, 0, 0, 0.6)', marginBottom: '12px'}}>Own Company Recipients</h6>
                {
                map(this.state.ownCompanySiteRecipients, (recipient, index) => {
                return (
                  <div key={index} style={{ display: 'flex', marginBottom: '10px' }}>
                    <div className='col-sm-5' style={{ paddingRight: '1px', paddingLeft: '0px', flex: '0 0 30%'}}>
                      <Autocomplete
                        disableCloseOnSelect
                        disableClearable
                        id={`site_${index}`}
                        options={filter(this.state.sites, site => recipient.handlerId == site?.id || !includes(this.state.selectedSiteIds, site.id))}
                        getOptionLabel={item => item.name}
                        renderInput={(params) => (
                          <TextField
                            label="Site"
                            {...params}
                            variant='outlined'
                          />
                        )}
                        value={recipient.handler}
                        onChange={(event, item) => this.handleSiteChange(item, index)}
                      />
                    </div>
                    <div className='col-sm-8' style={{flex: '1'}}>
                      <Autocomplete
                        multiple
                        limitTags={2}
                        disableCloseOnSelect
                        id={`ownCompanyRecipientsForSite_${index}`}
                        options={ownCompanyOptions}
                        groupBy={item => item.category}
                        getOptionLabel={item => `${item.name} ${isEqual(item.email, '') ? '- Email Not Found' : ''}`}
                        renderInput={(params) => (
                          <TextField
                            label="Recipients"
                            helperText={get(recipient.recipientIds, 'errors[0]', '')}
                            error={get(recipient.recipientIds, 'errors[0]', '')}
                            {...params}
                            variant='outlined'
                            InputProps={{
                              ...params.InputProps,
                              style: { paddingRight: '30px' },
                            }}
                          />
                        )}
                        value={filter(ownCompanyOptions, item => includes(recipient.recipientIds.value, get(item, 'id'))) }
                        onChange={(event, items) => this.handleOwnCompanyRecipientsChange(event, items, index)}
                      />
                    </div>
                    <div style={{ lineHeight: 'inherit', alignItems: 'center', display: 'flex', justifyContent: 'center' }}>
                      <Button
                        mini
                        id={`site_${index}RemoveButton`}
                        variant='fab'
                        secondary={true}
                        style={{ marginLeft: '-10px', padding: '0px', minWidth: '30px' }}
                        onClick={() => this.handleRemoveSiteRecipients(index)}
                      >
                        <ContentRemove />
                      </Button>
                    </div>
                  </div>
                );
                })
              }
              </div>
              }
              { includes(SITE_WISE_ALERTS, fields.name.value) &&
                <div className='col-sm-12 form-wrap' style={{ marginLeft: 'auto', float: 'right', marginTop: '10px', marginBottom: '-45px' }}>
                  {
                  !this.state.ownCompanyRecipientsAllSitesSelected ?
                    <React.Fragment>
                      <AddButton
                        label='Add Site'
                        id='addSites'
                        onClick={this.handleAddSite}
                        variant='outlined'
                      />
                      <CommonButton
                        id='allSites'
                        variant="outlined"
                        onClick={this.handleForAllSite}
                        label='All sites'
                        style={{ float: 'right', marginBottom: '10px', marginRight: '10px' }}
                      />
                    </React.Fragment>
                    : <CommonButton
                        id='addSiteWise'
                        variant="outlined"
                        onClick={this.handleSiteWise}
                        label='Add Site Wise'
                        style={{ float: 'right'}}
                      />
                  }
                </div>
              }
              { includes([SPEC_AVG_REPORT_ALERT_CODE, OUTLOAD_REPORT_ALERT_CODE, MISSING_DOCKET_ALERT_CODE, DOCKET_CLARIFICATION_ALERT_CODE, MISSING_DOCKET_PICKUP_SITE_ALERT_CODE, ACQUISITION_REPORT_ALERT_CODE], fields.name.value) &&
              <div className="col-sm-12 form-wrap" style={{marginTop: '15px'}}>
                <CommonSelect
                  id="frequency"
                  floatingLabelText="Alert Schedule"
                  value={fields.frequency.value}
                  selectedItemId={fields.frequency.value}
                  errorText= {get(fields.frequency, 'errors[0]', '')}
                  selectConfig={{text: 'name', value: 'id'}}
                  onChange={this.handleSelectChange}
                  items={this.getAlertSchedule()}
                  errorStyle={ERROR_STYLE}
                />
              </div>
              }
              <div style={{marginTop: '20px', marginLeft: 'auto', float: 'right' }}>
                <CommonButton
                  variant="contained"
                  onClick={this.onClose}
                  label='Cancel'
                />
                <CommonButton
                  variant="contained"
                  type='submit'
                  primary
                  onClick={this.handleSubmit}
                  label='Submit'
                  disabled={get(this.state.fields.name.errors, '0')}
                />
              </div>
            </div>
          </div>
        </form>
      </SideDrawer>
    )
  }
}

export default AlertForm;

import { connect } from 'react-redux';
import TruckForm from '../components/trucks/TruckForm';
import { updateTruck } from '../actions/api/trucks';
import { editTruck, cancelEditTruck } from '../actions/companies/trucks';
import find from 'lodash/find';

function submit(companyId, data) {
  return (dispatch, getState) => {
    const truckId = getState().companies.companies.company.trucks.selectedTruckId;
    dispatch(updateTruck(truckId, data, editTruck, companyId));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedTruck: find(
      state.companies.companies.company.trucks.items,
      { id: state.companies.companies.company.trucks.selectedTruckId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (companyId, data) => dispatch(submit(companyId, data)),
    cancelEdit: () => dispatch(cancelEditTruck()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TruckForm);

import React from 'react';

import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import Paper from '@mui/material/Paper';
import Slide from '@mui/material/Slide';
import Cancel from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import { canCreateOrUpdate } from '../../common/utils';
import { BLACK } from '../../common/constants';
import merge from 'lodash/merge';

const styles = () => ({
  paper: {
    zIndex: 2,
    position: 'fixed',
    left: 0,
    top: '88px',
    height: 'calc(100vh - 88px)',
    padding: '24px',
    overflowY: 'auto',
    borderRadius: 0,
  },
  small: {
    width: '400px',
  },
  big: {
    width: '720px',
  },
  cancel: {
    position: 'absolute',
    right: 25,
    top: 25,
    cursor: 'pointer',
  },
  title: {
    fontWeight: 500,
    color: '#112c42',
    fontSize: 20,
  },
});

class SideDrawer extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { classes, isOpen, children, title, onClose, size } = this.props;

    let canCreate = true;
    if(!this.props.canCreate && this.props.app && this.props.user){
      canCreate = canCreateOrUpdate(this.props.user, this.props.app);
    }
    if(canCreate) {
      return(
        <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
          <Paper elevation={4} style={merge({overflowX: 'hidden', color: BLACK}, this.props.paperStyle || {})} className={`${classes.paper} ${size === 'big' ? classes.big : classes.small}`}>
            <Cancel onClick={() => onClose()} className={classes.cancel} />
            <Typography variant="title" className={classes.title}>
              {title}
            </Typography>
            {children}
          </Paper>
        </Slide>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  user: state.main.user.user,
});

export default withStyles(styles)(connect(mapStateToProps)(SideDrawer));

import React from 'react';
import SideDrawer from '../common/SideDrawer';
import CommonSelect from '../common/select/CommonSelect';
import { get, includes, isEmpty, map } from 'lodash';
import CommonTextField from '../common/CommonTextField';
import APIService from '../../services/APIService';
import { forceStopLoader, isLoading } from '../../actions/main';
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import alertifyjs from 'alertifyjs';
import { getTitleTransfers } from '../../actions/companies/contracts';
import { PRICE_POINTS, PRIMARY_COLOR_GREEN } from '../../common/constants';
import { CheckCircle as YesIcon, Cancel as NoIcon } from '@mui/icons-material';

const ASSIGN_TO_OPTIONS = [
  {'id': 'contract', 'name': 'Contract'},
  {'id': 'independent', 'name': 'Independent'},
]

class AssignTitleTransferToContract extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      assignTo: {
        value: null,
        errors: [],
      },
      contractNumber: {
        value: null,
        errors: [],
      },
      externalReferenceNumber: {
        value: null,
        errors: [],
      },
      contract: undefined,
      isValidContract: false,
      unmatchedFields: [],
      tableData: undefined,
    }
    this.handleAssignToChange = this.handleAssignToChange.bind(this);
    this.handleContractNumberChange = this.handleContractNumberChange.bind(this);
  }

  handleAssignToChange(value) {
    const newState = {...this.state};
    newState.assignTo.value = value;
    if (value === 'independent' && !get(this.props.titleTransfer, 'commodityContractId'))
      newState.assignTo.errors = ['Title transfer is already independent']
    else
      newState.assignTo.errors = []
    this.setState(newState);
  }

  checkContractEligibility() {
    const { contract } = this.state;
    const { titleTransfer } = this.props;
    const newState = {...this.state};
    if (includes(['void', 'confirmation_pending'], contract.status))
      newState.contractNumber.errors = ['Contract status should be confirmed.']
    else {
      let unmatchedFields = [];
      if (contract.commodityId !== titleTransfer.commodityId)
        unmatchedFields.push('Commodity');
      if (!includes(contract.gradeIds, titleTransfer.gradeId))
        unmatchedFields.push('Grade');
      if (contract.season !== titleTransfer.season)
        unmatchedFields.push('Season');
      if (get(contract, 'pricePointId') !== PRICE_POINTS.TRACK && !includes(contract.mandatorySiteIds, titleTransfer.site.id))
        unmatchedFields.push('Site');
      if (contract.seller.companyId !== titleTransfer.seller.companyId)
        unmatchedFields.push('Seller');
      if (contract.buyer.companyId !== titleTransfer.buyer.companyId)
        unmatchedFields.push('Buyer');
      if (!isEmpty(unmatchedFields))
        newState.contractNumber.errors = [`${unmatchedFields.join(', ')} does not match with the contract.`]
      else if (contract.unaccountedTonnage < titleTransfer.tonnage)
        newState.contractNumber.errors = ["Not enough tonnage remaining on contract."]
      newState.unmatchedFields = unmatchedFields;
    }
    newState.isValidContract = isEmpty(newState.contractNumber.errors);
    this.setState(newState, () => {
      this.setTableDetails();
      this.props.dispatch(forceStopLoader())
    });
  }

  handleContractNumberChange(event) {
    const newState = { ...this.state };
    let value = event.target.value;
    if (value && value.length > 4) {
      this.props.dispatch(isLoading());
      APIService.contracts()
        .appendToUrl(`global-search/${value}/?search_in_assigning_title_transfers=true`)
        .get()
        .then(response => {
          if (get(response, 'id')) {
            const newState = {...this.state};
            newState.contract = response;
            newState.contractNumber.errors = [];
            this.setState(newState, () => this.checkContractEligibility());
          }
          else {
            this.props.dispatch(forceStopLoader());
            const newState = {...this.state};
            newState.contract = undefined;
            newState.contractNumber.errors = ['Contract does not exists.'];
            newState.isValidContract = false;
            this.setState(newState, () => this.setTableDetails());
          }
        })
    }
    else {
      newState.contractNumber.errors = ['This field is required.'];
      newState.isValidContract = false;
    }
    this.setState(newState);
  }

  handleSubmit() {
    if (this.state.assignTo.value === 'contract' && this.state.isValidContract) {
      this.props.dispatch(isLoading());
      APIService.contracts()
        .appendToUrl(`title-transfers/${this.props.titleTransfer.id}/contracts/`)
        .put({'contract_id': this.state.contract.id})
        .then(() => {
          this.props.dispatch(forceStopLoader());
          this.props.onClose();
          alertifyjs.success("Successfully assigned to contract.", 2, () => this.props.dispatch(getTitleTransfers(this.props.contractId)))
        })
    }
    else if (this.state.assignTo.value === 'independent') {
      let contractNumberMandatory = get(this.props.titleTransfer, 'site.contractNumberMandatory');
      if (((contractNumberMandatory && this.state.externalReferenceNumber.value) || !contractNumberMandatory)) {
        this.props.dispatch(isLoading());
        APIService.contracts()
          .appendToUrl(`title-transfers/${this.props.titleTransfer.id}/contracts/`)
          .put({'external_reference_number': this.state.externalReferenceNumber.value})
          .then(() => {
            this.props.dispatch(forceStopLoader());
            this.props.onClose();
            alertifyjs.success("Successfully converted to independent transfer.", 2, () => this.props.dispatch(getTitleTransfers(this.props.contractId)))
          })
      }
      else {
        if (get(this.props.titleTransfer, 'site.contractNumberMandatory')) {
          const newState = {...this.state};
          newState.externalReferenceNumber.errors = ['This field is required.'];
          this.setState(newState);
        }
      }
    }
  }

  setTableDetails() {
    if (this.state.assignTo.value === 'contract' && this.state.contract) {
      let data = [];
      const { titleTransfer } = this.props;
      const { contract, unmatchedFields } = this.state
      data.push({'column': 'Seller', 'tt_value': titleTransfer.seller.companyName, 'contract_value': contract.seller.companyName, 'matched': !includes(unmatchedFields, 'Seller')});
      data.push({'column': 'Buyer', 'tt_value': titleTransfer.buyer.companyName, 'contract_value': contract.buyer.companyName, 'matched': !includes(unmatchedFields, 'Buyer')});
      data.push({'column': 'Commodity', 'tt_value': titleTransfer.commodityName, 'contract_value': contract.commodity, 'matched': !includes(unmatchedFields, 'Commodity')});
      data.push({'column': 'Season', 'tt_value': titleTransfer.season, 'contract_value': contract.season, 'matched': !includes(unmatchedFields, 'Season')});
      data.push({'column': 'Grade Spreads', 'tt_value': titleTransfer.gradeName, 'contract_value': contract.spreadGrades?.join(', '), 'matched': !includes(unmatchedFields, 'Grade')});
      data.push({'column': 'Tonnage', 'tt_value': titleTransfer.tonnageDisplayValue, 'contract_value': contract.unaccountedTonnageDisplayValue, 'matched': contract.unaccountedTonnage >= titleTransfer.tonnage});
      data.push({'column': 'Price Point', 'tt_value': '', 'contract_value': contract.pricePoint, 'matched': true});
      if (!isEmpty(contract.pickupSites))
        data.push({'column': 'Pickup Sites', 'tt_value': titleTransfer.siteDisplayName, 'contract_value': contract.pickupSites.map(site => site.displayName).join(", "), 'matched': !includes(unmatchedFields, 'Site')});
      if (!isEmpty(contract.deliverySites))
        data.push({'column': 'Delivery Sites', 'tt_value': titleTransfer.siteDisplayName, 'contract_value': contract.deliverySites.map(site => site.displayName).join(", "), 'matched': !includes(unmatchedFields, 'Site')});
      this.setState({tableData: data})
    }
    else {
      this.setState({tableData: undefined});
    }
  }

  handleExternalReferenceNumberChange = event => {
    const newState = {...this.state};
    let value = event.target.value;
    newState.externalReferenceNumber.value = event.target.value;
    if (value)
      newState.externalReferenceNumber.errors = [];
    else if (get(this.props.titleTransfer, 'site.contractNumberMandatory'))
      newState.externalReferenceNumber.errors = ['This field is required.'];
    this.setState(newState);
  }

  render() {
    const { assignTo, contractNumber, isValidContract, tableData, externalReferenceNumber } = this.state;
    const contractNumberMandatory = get(this.props.titleTransfer, 'site.contractNumberMandatory');
    return (
      <div>
        <SideDrawer 
          isOpen
          title={`Assign Title Transfer: ${this.props.titleTransfer.identifier}`}
          onClose={() => this.props.onClose()}
          size="xlarge"
        >
          <div className="col-sm-12" style={{'paddingLeft': '0px', 'paddingTop': '15px'}}>
            <CommonSelect
              id="assignTo"
              floatingLabelText="Assign To"
              value={assignTo.value}
              errorText= {get(assignTo.errors, '0')}
              selectConfig={{text: 'name', value: 'id'}}
              onChange={this.handleAssignToChange}
              items={ASSIGN_TO_OPTIONS}
            />
          </div>
          {assignTo.value === 'contract' &&
            <div>
              <div className="col-sm-12" style={{paddingLeft: '0px', paddingTop: '15px', marginBottom: '40px'}}>
                <CommonTextField
                  id='contract'
                  label={'Contract Number'}
                  value={contractNumber.value}
                  helperText={get(contractNumber.errors, '0')}
                  onBlur={this.handleContractNumberChange}
                />
              </div>
              {tableData &&
              <div style={{marginTop: '10px'}}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="small">Fields</TableCell>
                      <TableCell className="medium">Title Transfer Details</TableCell>
                      <TableCell className="large">Contract Details</TableCell>
                      <TableCell className="xsmall">Match</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      map(tableData, row => {
                        return (
                          <TableRow>
                            <TableCell className="small">{row.column}</TableCell>
                            <TableCell className="medium">{row.tt_value}</TableCell>
                            <TableCell className="large">{row.contract_value}</TableCell>
                            <TableCell className="xsmall">{row.matched ? <YesIcon style={{color: PRIMARY_COLOR_GREEN}} /> : <NoIcon color='warning'/>}</TableCell>
                          </TableRow>
                        )
                      })
                    }
                  </TableBody>
                </Table>
              </div>
              }
            </div>
          }
          {assignTo.value === 'independent' && get(this.props.titleTransfer, 'commodityContractId') &&
            <div>
              <div className="col-sm-12" style={{paddingLeft: '0px', paddingTop: '15px', marginBottom: '40px'}}>
                <CommonTextField
                  id="externalReferenceNumber"
                  label={contractNumberMandatory ? 'External Contract Number' : 'External Contract Number (Optional)'}
                  value={externalReferenceNumber.value}
                  onChange={this.handleExternalReferenceNumberChange}
                  helperText={get(externalReferenceNumber.errors, '0')}
                />
              </div>
            </div>
          }
          {assignTo.value && 
            <div style={{float: 'right', marginTop: '10px'}}>
              <Button
                variant="outlined"
                size="small"
                onClick={() => this.props.onClose()}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                size="small"
                style={{marginLeft: '8px'}}
                onClick={() => this.handleSubmit()}
                disabled={assignTo.value === 'contract' ? !isValidContract: (contractNumberMandatory && !externalReferenceNumber.value)}>
                Save
              </Button>
            </div>
          }
        </SideDrawer>
      </div>
    )
  }
}

export default AssignTitleTransferToContract;

import React from 'react';

import { connect } from 'react-redux';
import AddButton from '../common/AddButton';
import { showHideTitleTransferSideDrawer } from '../../actions/companies/contracts';
import Paper from '@mui/material/Paper';
import {setHeaderText, setBreadcrumbs} from '../../actions/main';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import SideDrawer from '../common/SideDrawer';
import {getHeaderTitle} from "./invoiceDetails/InvoiceDetails";
import InvoicePaymentsTable from "../../containers/InvoicePaymentsTable";
import {getInvoicePayments} from "../../actions/companies/invoices";
import AddPaymentForm from "./AddPaymentForm";
import InvoicePaymentRejectForm from "./InvoicePaymentRejectForm";

class InvoicePayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
      invoice: null,
    };
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
  }

  getInvoiceId() {
    return this.props.invoiceId || this.props.match.params.invoice_id;
  }

  componentDidMount() {
    this.props.dispatch(getInvoicePayments(this.getInvoiceId()));
    this.setHeaderAndBreadcrumbs();
  }

  componentDidUpdate() {
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    const invoiceId = this.getInvoiceId();
    let breadcrumbs = [
      {text: 'Payments'},
    ];
    let headerText = 'Payments';
    if(invoiceId){
      breadcrumbs = [
        {text: 'Invoices', route: '/invoices'},
        {text: get(this.props.invoice, 'identifier', ''), route: '/invoices/' + invoiceId + '/details'},
        {text: 'Payments'},
      ];
      headerText = getHeaderTitle(this.props.invoice);
    }
    this.props.dispatch(setHeaderText(headerText));

    if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }

  }

  handleAddButtonClick() {
    this.openSideDraw();
  }

  openSideDraw(){
    this.props.dispatch(showHideTitleTransferSideDrawer(true));
    this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
    this.setState({ openSideDrawer: false, });
  }

  render() {
    const { invoice, canAddPayment } = this.props;
    return (
      <Paper className="paper-table">
        {
          canAddPayment && <AddButton label="Payment" onClick={this.handleAddButtonClick} />
        }
        <SideDrawer
          isOpen={this.state.openSideDrawer}
          title="Add Payment"
          onClose={this.closeSideDraw}
        >
          <AddPaymentForm
            invoice={invoice}
            dispatchInvoicePayments={true}
            dispatchInvoiceDetails={true}
            onClose={this.closeSideDraw}
          />
        </SideDrawer>
        <InvoicePaymentsTable />
        <InvoicePaymentRejectForm invoicePayment={this.props.invoicePayment} />
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    invoice: state.companies.invoices.selectedInvoice,
    canAddPayment: state.companies.invoices.canAddPayment,
    invoicePayment: state.companies.invoices.selectedInvoicePayment,
  };
};

export default connect(mapStateToProps)(InvoicePayments);

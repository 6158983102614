import React from 'react';

import { connect } from "react-redux";
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { getReleaseNotes } from '../../actions/companies/release-notes';
import moment from 'moment';

class ReleaseNotesView extends React.Component {
  componentDidMount() {
    this.props.dispatch(getReleaseNotes());
  }

  render() {
    const { releaseNotes } = this.props;
    return (
      <div>
        {
          releaseNotes.map(note => {
            const createdAt = moment(note.createdAt, 'DD/MM/YYYY').format('LL');
            return (
              <React.Fragment key={note.id}>
                <Card style={{padding: '10px', boxShadow: 'none'}}>
                  <Typography color="textSecondary" gutterBottom style={{fontSize: '10px'}}>
                    {createdAt}
                  </Typography>
                  <Typography component="p" dangerouslySetInnerHTML={{__html: note.changelog}} />
                </Card>
                <Divider sx={{marginBottom: '10px'}} />
              </React.Fragment>
            );
          })
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  releaseNotes: state.companies.releaseNotes.items
});

export default connect(mapStateToProps)(ReleaseNotesView);

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class SiteManagement extends Component {
  render() {
    return <Tooltip title="Site Management" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={this.props.style || {}}>
      <g fill="none" fillRule="nonzero">
        <path d="M0 0h24v24H0z"/>
        <path stroke={this.props.fill} strokeWidth="2" d="M20 7v13H4V7"/>
        <path stroke={this.props.fill} strokeWidth="2" d="M8 11h8v9H8z"/>
        <path stroke={this.props.fill} strokeLinecap="round" strokeWidth="2" d="M23 8L12 3 1 8"/>
        <path fill={this.props.fill} d="M9 13h6v2H9zM9 16h6v2H9z"/>
      </g>
      </svg>
    </Tooltip>;
  }
}

export default SiteManagement;

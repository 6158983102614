import React from 'react';

import Tooltip from '@mui/material/Tooltip';

const Stocks = ({ fill, title }) => (
  <Tooltip title={title || "Stocks"} placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={{marginLeft: '-2px', marginRight: '17px'}}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path stroke={ fill } strokeWidth="2" d="M3 21h9V5H3v16zM12 21h9v-7h-9v7zM12 5c0-2.485-2.015-4-4.5-4S3 2.515 3 5h9zM21 14c0-2.485-2.015-4-4.5-4S12 11.515 12 14h9z"/>
        <rect width="2" height="4" x="8" y="7" fill={fill} rx="1"/>
        <rect width="2" height="1" x="17" y="16" fill={fill} rx=".5"/>
        <rect width="2" height="1" x="17" y="18" fill={fill} rx=".5"/>
        <rect width="2" height="3" x="20" y="21" fill={fill} rx="1"/>
        <rect width="2" height="4" x="11" y="20" fill={fill} rx="1"/>
        <rect width="2" height="3" x="2" y="21" fill={fill} rx="1"/>
        <rect width="2" height="7" x="8" y="12" fill={fill} rx="1"/>
      </g>
      </svg>
  </Tooltip>
)

export default Stocks;

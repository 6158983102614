import React, { Component } from 'react';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { capitalize, get } from 'lodash';
import APIService from '../../../services/APIService'
import GenericTable from '../../GenericTable';
import LoaderInline from '../../LoaderInline';
import { Button } from '@mui/material';

const renderBasedOnExpandedFlag = expanded => expanded ? (<KeyboardArrowUp style={{ fill: '#112c42', height: '20px', width: '20px' }} />) : (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );

class DocketClarificationTable extends Component {
  constructor(props) {
    super(props)
    this.state = {
      clarifications: [],
      isLoading: false,
      expanded: true,
    }
  }

  componentDidMount() {
    this.fetchClarification()
  }

  fetchClarification = () => {
    this.setState(
        {isLoading: true},
        () => APIService.freights().contracts().appendToUrl(`${this.props.movement.id}/seek-clarification/`).get()
        .then(response => {
            const inloadData = get(response, 'inload');
            const outloadData = get(response, 'outload');
            const clarifications = [];
            if (inloadData){
              inloadData['identifier'] = this.props.movement.identifier
              clarifications.push(inloadData)
            }
            if(outloadData){
              outloadData['identifier'] = this.props.movement.identifier
              clarifications.push(outloadData)
            }
            this.setState({isLoading: false, clarifications: clarifications, expanded: clarifications.length > 0})
        })
      )
  }

  toggleExpanded = () => this.setState({expanded: !this.state.expanded});

  render() {
    const { expanded, isLoading, clarifications } = this.state
    const docketCategoryMapping = {
      'key_info_missing': "Key Info Missing",
      'docket_issue': "Docket Issue",
      'value_missing_in_ac': "Value Missing in AgriChain",
      'tonnage_exhausted': "Tonnage Finished",
    }
    const docketSubCategoryMapping = {
      "site": "Site",
      "grade": "Grade",
      "stock_owner": "Stock Owner",
      "net_weight": "Net Weight",
      "truck": "Truck",
      "season": "Season",
      "canola_spec": "Canola Spec",
      "irrelevant_image": "Irrelevant Image",
      "wrong_docket": "Wrong Docket",
      "multiple_dockets": "Multiple Dockets",
      "docket_not_readable": "Docket Not Readable",
      "contract_tonnage_exhausted": "Contract Tonnage Finished",
      "order_tonnage_exhausted": "Order Tonnage Finished",
    }
    const clarificationsFormatted = clarifications.map(clarification => {
      const formattedClarification = {};
      for (const [key, value] of Object.entries(clarification)) {
          if (key === 'category') {
              formattedClarification[key] = docketCategoryMapping[value];
          } else if (key === 'subCategory') {
              formattedClarification[key] = value.map(subCat => docketSubCategoryMapping[subCat]).join(', ');;
          } else if (key === 'loadType') {
              formattedClarification[key] = capitalize(value);
          } else {
              formattedClarification[key] = value;
          }
      }
      return formattedClarification;
  });
    const COLUMNS = [
        {header: 'Date', key: 'dateTime', type: 'datetime'},
        {header: 'Identifier', key: 'identifier'},
        {header: 'Load Type', key: 'loadType'},
        {header: 'Category', key: 'category'},
        {header: 'Sub Category', key: 'subCategory'},
        {header: 'Comments', key: 'comment'},
    ]
    const count = clarifications.length
    const getResolveActions = (item) => {
      return <div className="actions">
        {get(item, 'loadType') === 'Outload' && <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{marginRight: '8px'}}
          onClick={() => this.props.markResolveDocket('outload')}>
          Resolve
        </Button>}
        {get(item, 'loadType') === 'Inload' && <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{marginRight: '8px'}}
          onClick={() => this.props.markResolveDocket('inload')}>
          Resolve
        </Button>}
      </div>;
    };
    let heading = "Pending Docket Clarification"
    if(!isLoading)
      heading += ` (${count})`

    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={count ? this.toggleExpanded : () => {}}>
          {heading}
          {
            Boolean(count) &&
              <span className="expand-icon">
                {renderBasedOnExpandedFlag(expanded)}
              </span>
          }
        </h2>
        {
          expanded &&
            <div style={{marginTop: '15px'}}>
              {
                isLoading ?
                  <LoaderInline containerClassName="inline-loader-container" /> :
                  <GenericTable
                    columns={COLUMNS}
                    items={clarificationsFormatted}
                    customColumnGenerator={getResolveActions}
                  />
              }
            </div>
        }
      </Paper>
    )
  }
}

export default DocketClarificationTable

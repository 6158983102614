import alertifyjs from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import APIService from '../../services/APIService';
import { addFarmEmployee, addFarmEmployeeErrors, editFarmEmployeeErrors, receiveFarmEmployees } from '../../actions/companies/farm-employees';
import { myProfileUpdated } from '../../actions/main';

const MESSAGES = {
  CREATE_FARM_EMPLOYEE_SUCCESS: 'Employee was successfully added.',
  UPDATE_FARM_EMPLOYEE_SUCCESS: 'Employee details were successfully updated.',
};

export const getFarmEmployees = (farmId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (farmId) {
    APIService.farms(farmId)
      .employees()
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createFarmEmployee = (farmId, data, actionCreator, establishmentDetails) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .employees()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addFarmEmployeeErrors(item.errors));
      } else {
        if (establishmentDetails) {
          dispatch(addFarmEmployee(item));
          establishmentDetails.establishment
            ? actionCreator(establishmentDetails.establishment, establishmentDetails.value, item.id)
            : actionCreator(establishmentDetails, item.id);
        } else {
          dispatch(actionCreator(item));
          dispatch(getFarmEmployees(farmId, receiveFarmEmployees));
        }

        alertifyjs.success(MESSAGES.CREATE_FARM_EMPLOYEE_SUCCESS);
      }
    });
};

export const updateFarmEmployee = (farmId, farmEmployeeId, data, actionCreator) => (dispatch, getState) => {
  const { token, user } = getState().main.user;
  APIService.farms(farmId)
    .employees(farmEmployeeId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editFarmEmployeeErrors(item.errors));
      } else {
        if (user.id === item.id) {
          const myProfileFields = pick(item, ['title', 'firstName', 'lastName', 'type', 'mobile', 'email', 'farm']);
          dispatch(myProfileUpdated(myProfileFields));
        }
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_FARM_EMPLOYEE_SUCCESS);
      }
    });
};

import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { isArray, get, isEmpty, findIndex, isNull, includes} from 'lodash';
import {confirmOrder, rejectOrder} from '../../../actions/companies/freights';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {RejectionReasonDialog} from "../../rejections/RejectionReasonDialog";
import {required} from "../../../common/validators";
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../../common/DialogTitleWithCloseIcon';
import { forceStopLoader, isLoading } from '../../../actions/main';
import { isCurrentUserBelongsToCompany, openURLInNewTab } from '../../../common/utils';
import { SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS } from '../../freights/Constants';

class OrderVoidRequestsPendingGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectDialogOpen: false,
      isFetching: true,
      orders: [],
      selectedOrder: null,
      acceptDialogOpen: false,
      dialogContent: undefined,
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },

    };
      this.setReasonErrors = this.setReasonErrors.bind(this);
      this.getReasonErrors = this.getReasonErrors.bind(this);
      this.handleRejectClickOpen = this.handleRejectClickOpen.bind(this);
      this.handleReasonChange = this.handleReasonChange.bind(this);
      this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
      this.handleRejectClose = this.handleRejectClose.bind(this);

  }

  acceptOrder(event, order) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({acceptDialogOpen: false, dialogContent: undefined});
    this.props.dispatch(confirmOrder(get(order, 'id'), null, false));
  }


  componentDidMount() {
    const { token } = this.props;
    if(token)
      APIService.freights().appendToUrl('orders/acceptance/pending/').get(token).then(orders => {
        const isOk = isArray(orders);
        this.setState({isFetching: !isOk, orders: isOk ? orders : []}, () => {
          this.props.onDataLoad(this.state.orders.length);
        });
      });
  }

  componentDidUpdate() {
    this.removeSuccessfulOrderFromList();
  }

  getTitle() {
    let suffix = this.state.isFetching ? 'Loading...' : this.state.orders.length;
    return `Pending Acceptance Request (${suffix})`;
  }


  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  handleRejectClickOpen = (event, order) => {
    event.stopPropagation();
    event.preventDefault();
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    newState.selectedOrder = order;
    if(!newState.rejectionReason.value){
      newState.rejectionReason.errors = [];
    }
    this.setState(newState);
  };

  handleRejectClose = () => {
    this.setState({rejectDialogOpen: false});
  };

  handleReasonChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  }

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
      const errors = [];
      const value = get(this.state, `rejectionReason.value`);
      const validators = get(this.state, `rejectionReason.validators`, []);

      validators.forEach((validator) => {
          if (validator.isInvalid(value)) {
              errors.push(validator.message);
          }
      });

      return errors;
  }


  handleRejectSubmit() {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    const order = this.state.selectedOrder;
    if (this.state.rejectionReason.errors.length === 0) {
      const {dispatch} = this.props;
      dispatch(rejectOrder(order.id, data, false));
      this.setState({
          rejectionReason: {
            value: undefined,
            validators: [required()],
            errors: []
          },
        }
      );
    }
  }

  removeSuccessfulOrderFromList() {
    const { responseOrder } = this.props;
    const { selectedOrder, orders } = this.state;
    if(
      selectedOrder &&
      get(responseOrder, 'id') === selectedOrder.id &&
      !isEmpty(orders)
    ) {
      const index = findIndex(orders, {id: responseOrder.id});
      this.setState({
        orders: [...orders.slice(0, index), ...orders.slice(index + 1)],
        selectedOrder: null,
           rejectDialogOpen: false,
      }, () => {
        this.props.onDataLoad(this.state.orders.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/freights/orders/${id}/order`);
  }

  async handleSubmit(event, order) {
    event.stopPropagation();
    event.preventDefault();
    const {token, dispatch} = this.props;
    dispatch(isLoading('acceptOrderReview'));

    let availableTonnage = parseFloat(get(order, 'availableTonnage'));
    let plannedTonnage = parseFloat(get(order, 'plannedTonnage'));
    const orderDetails = await APIService.freights().appendToUrl(`orders/${order.id}/`).get(token);
    this.setState({selectedOrder: order}, () => dispatch(forceStopLoader()));
    const isCurrentUserCustomer = isCurrentUserBelongsToCompany(get(orderDetails, 'customer.companyId'))
    const isCurrentUserBelongsToPickupSite = isCurrentUserBelongsToCompany(get(orderDetails, 'freightPickup.consignor.handler.companyId'))
    const isStocksManagementOnForConsignor = get(orderDetails, 'freightPickup.consignor.handler.stocksManagement')
    if (isStocksManagementOnForConsignor && get(order, 'ngr') && includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, get(orderDetails, 'typeId')) && (isCurrentUserCustomer || isCurrentUserBelongsToPickupSite) && !isNull(availableTonnage) && availableTonnage < plannedTonnage) {
      let remainingTonnage = (availableTonnage - plannedTonnage).toFixed(2);
      let dialogContent = `The tonnage on this order exceeds the stored tonnage for ${get(order, 'customer')} against NGR ${get(order, 'ngr')}.
                           This will leave ${get(order, 'customer')} with ${remainingTonnage} MT.`
      this.setState({acceptDialogOpen: true, dialogContent: dialogContent});
    }
    else {
      this.acceptOrder(event, order);
    }
  }



  render() {
    return (
      <div className="action-centre-group">
        <Accordion defaultExpanded style={{margin: '0px'}}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Order No.</TableCell>
                      <TableCell align='center' className="xsmall">Customer</TableCell>
                      <TableCell align='center' className="xsmall">Freight Provider</TableCell>
                      <TableCell align='center' className="medium">Commodity Description</TableCell>
                      <TableCell align='center' className="xsmall">Pickup</TableCell>
                      <TableCell align='center' className="xsmall">Delivery</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.orders) ?
                      this.state.orders.map(order => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={order.referenceNumber}
                          onClick={(event) => this.onRowClick(event, order.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(order.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.customer)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.freightProvider)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(order.commodityDescription)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.pickupDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.deliveryDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-red-outlined"
                              onClick={(event) => this.handleRejectClickOpen(event, order)}>
                              Reject
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{marginLeft: '8px'}}
                              onClick={(event) => this.handleSubmit(event, order)}>
                              Accept
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <RejectionReasonDialog
          open={this.state.rejectDialogOpen}
          onClose={this.handleRejectClose}
          title='Reject Order'
          value={this.state.rejectionReason.value}
          onChange={this.handleReasonChange}
          helperText={get(this.state, 'rejectionReason.errors[0]', '')}
          onCancel={this.handleRejectClose}
          onReject={this.handleRejectSubmit}
        />
        <Dialog
          open={this.state.acceptDialogOpen}
          onClose={() => this.setState({acceptDialogOpen:false, dialogContent: undefined})}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({acceptDialogOpen:false, dialogContent: undefined})}
            id="order-accept-dialog"
          >
            Accept Order
          </DialogTitleWithCloseIcon>
          <DialogContent style={{marginTop: '10px'}}>
            <span>{this.state.dialogContent}</span>
          </DialogContent>
          <DialogActions>
            <Button type='button' onClick={() => this.setState({acceptDialogOpen:false, dialogContent: undefined})} variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={(event) => this.acceptOrder(event, this.state.selectedOrder)} color='primary' variant='contained'>
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseOrder: state.companies.orders.confirmedOrRejectedOrder,
});

export default connect(mapStateToProps)(OrderVoidRequestsPendingGroup);

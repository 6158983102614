export const ADD_RELEASE_NOTE = 'ADD_RELEASE_NOTE';
export const EDIT_RELEASE_NOTE = 'EDIT_RELEASE_NOTE';
export const CANCEL_EDIT_RELEASE_NOTE = 'CANCEL_EDIT_RELEASE_NOTE';
export const CLICK_ADD_RELEASE_NOTE_BUTTON = 'CLICK_ADD_RELEASE_NOTE_BUTTON';
export const CLICK_EDIT_RELEASE_NOTE_ICON = 'CLICK_EDIT_RELEASE_NOTE_ICON';
export const ADD_RELEASE_NOTE_ERRORS = 'ADD_RELEASE_NOTE_ERRORS';
export const EDIT_RELEASE_NOTE_ERRORS = 'EDIT_RELEASE_NOTE_ERRORS';
export const RECEIVE_RELEASE_NOTES = 'RECEIVE_RELEASE_NOTES';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import has from 'lodash/has';
import { toDateFormat } from '../../common/utils'

const MESSAGES = {
  CREATE_SUCCESS: 'Release Note was successfully added.',
  UPDATE_SUCCESS: 'Release Note details were successfully updated.',
};

export const receiveReleaseNotes = items => ({
  type: RECEIVE_RELEASE_NOTES,
  items,
});

export const addReleaseNote = item => ({
  type: ADD_RELEASE_NOTE,
  item,
});

export const addReleaseNoteErrors = errors => ({
  type: ADD_RELEASE_NOTE_ERRORS,
  errors,
});

export const editReleaseNote = item => ({
  type: EDIT_RELEASE_NOTE,
  item,
});

export const editReleaseNoteErrors = errors => ({
  type: EDIT_RELEASE_NOTE_ERRORS,
  errors,
});

export const cancelEditReleaseNote = () => ({
  type: CANCEL_EDIT_RELEASE_NOTE,
});

export const clickAddReleaseNoteButton = () => ({
  type: CLICK_ADD_RELEASE_NOTE_BUTTON,
});

export const clickEditReleaseNoteIcon = index => ({
  type: CLICK_EDIT_RELEASE_NOTE_ICON,
  index,
});
export const getReleaseNotes = () => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.release_notes().get(token).then(items => {
    items.forEach(item => {
      item.createdAt = toDateFormat(item.createdAt)
    });
    dispatch(receiveReleaseNotes(items));
  });
};
export const createReleaseNote = (data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.release_notes().post(data, token)
    .then((item) => {
      if (item.id == null) {
        if(item.errors) {
          alertifyjs.error(Object.values(item.errors)[0][0]);
        }
        dispatch(addReleaseNoteErrors(has(item, 'errors') ? item.errors : item));
      } else {
        dispatch(actionCreator(item));
        if(isObject(item)) {
          alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        }
      }
    });
};

export const updateReleaseNote = (releaseNoteId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.release_notes(releaseNoteId).put(data, token)
    .then((item) => {
      if (!isEmpty(item.errors)) {
        dispatch(editReleaseNoteErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        if(isObject(item)) {
          alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
        }
      }
    });
};

import React from 'react';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs } from '../../../actions/main';
import { getCountryLabel } from '../../../common/utils'
import '../actionCentre.scss';
import isNumber from 'lodash/isNumber';
import TruckVendorDecRequestPending from './TruckVendorDecRequestPending';
import GrainVendorDecRequestPending from './GrainVendorDecRequestPending';
import get from "lodash/get";

class VendorDecActionCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceItems1: 0,
      invoiceItems2: 0,
      pendingPaymentAcceptanceGroupUpdatedInvoice: undefined,

    };

    this.onVendorDecDataLoad1 = this.onVendorDecDataLoad1.bind(this);
    this.onVendorDecDataLoad2 = this.onVendorDecDataLoad2.bind(this);

  }

  componentDidMount() {
    this.props.onMount();
    this.props.dispatch(setHeaderText(getCountryLabel('actionCentre')));
    this.props.dispatch(setBreadcrumbs([{text: getCountryLabel('actionCentre')}]));
  }

  componentDidUpdate() {
    this.props.onParentDataLoad(this.getInvoiceItemSum());
  }


  onVendorDecDataLoad1(count) {
    this.setState({invoiceItems1: isNumber(count) ? count : this.props.existingCount});
  }

  onVendorDecDataLoad2(count) {
    this.setState({invoiceItems2: isNumber(count) ? count : this.props.existingCount});
  }

  getInvoiceItemSum() {
    const sum = this.state.invoiceItems1 + this.state.invoiceItems2;
    return (sum || (sum === 0 && this.props.existingCount === 1)) ? sum : this.props.existingCount;
  }

  shuffleInvoice = (invoice, group, operation) => {
    const groupMethod = get(this, 'shuffle' + group);
    if (groupMethod)
      groupMethod(invoice, operation);
  };

  shuffleInvoiceInPendingPaymentAcceptanceGroup = (invoice, operation) => {
    if (operation === 'push')
      this.setState({pendingPaymentAcceptanceGroupUpdatedInvoice: invoice});
  };

  render() {
    return (
      <div className='action-centre-group-container'>
          {
            <div>
              <GrainVendorDecRequestPending onDataLoad={this.onVendorDecDataLoad1} />
              <TruckVendorDecRequestPending onDataLoad={this.onVendorDecDataLoad2} />
            </div>
          }
      </div>
    );
  }
}

export default connect()(VendorDecActionCentre);

import { connect } from 'react-redux';
import React from 'react';
import { startCase } from 'lodash';
import APIService from '../services/APIService';
import alertifyjs from 'alertifyjs';
import GenericTable from '../components/GenericTable';
import { currentUserCompany, isCurrentUserCompanyAdmin, isSystemCompany } from '../common/utils';
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../actions/companies';
import { includes } from 'lodash';


const mapStateToProps = (state, ownProps) => {
  const config={
    columns: [
      {
        key: 'name',
        header: 'Site Name',
        className: 'medium',
        default: farm => (
          <div>
            <div>{farm.name}</div>
            {
              farm.externallySyncSource &&
                <div style={{color: 'gray', fontSize: '12px'}}>
                  <i>{`(Synced from ${startCase(farm.externallySyncSource)})`}</i>
                </div>
            }
          </div>
        )
      },
      { key: 'marketzone.name', secondaryKey: 'marketZone.name', header: 'Market Zone' , className: 'medium'},
      { key: 'region.name', header: 'Region' , className: 'medium'},
      { key: 'address.address', header: 'Address' , className: 'medium'},
      { key: 'location', header: 'Location', map: {
        name: 'address.address',
        lat: 'address.latitude',
        lng: 'address.longitude'
      },
        className: "small"
      },
      { key: 'mobile', header: 'Mobile', className: 'medium'},
    ],
    items: state.companies.companySites.items,
    scrollToTopOnUpdate: false,
    orderBy: 'name',
    order: 'asc',
  };
  config.optionsItems = []
  if (isSystemCompany() && !ownProps.isArchived )
  config.optionsItems =  [{ key: 'transfer', text: 'Transfer' }];

  if (isSystemCompany() && !ownProps.isArchived )
    config.optionsItems.push({ key : 'merge', text: 'Merge' });

  if (isSystemCompany() || (ownProps?.siteCompanyId == currentUserCompany()?.id && isCurrentUserCompanyAdmin())) {
    var option = ownProps.isArchived ? { key : 'unarchive', text: 'Unarchive' } : { key : 'archive', text: 'Archive' };
    config.optionsItems = [...config.optionsItems, option]
  }
  return config;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleDefaultCellClick: (item) => {
      if (ownProps.isArchived) {
        var message = isCurrentUserCompanyAdmin() || isSystemCompany() ? 'Please unarchive this site to view details.' : 'To view details please contact the company admin to unarchive this site.';
        alertifyjs.alert(
          'Warning',
          message,
          () => {}
          );
      } else
        document.location.hash = `#/farms/${item.id}/settings/details`;
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        dispatch(showSideDrawerForTransfer(item, true));
      }
      if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
      if (includes(['archive', 'unarchive'], key)) {
        APIService.farms(item.id).put({'isActive': Boolean(ownProps.isArchived)}).then(response=> {
          if(response?.errors)
            alertifyjs.alert('Warning',response.errors);
          else {
          let keyWord = ownProps.isArchived ? 'Unarchived' : 'Archived';
          alertifyjs.success(`Site ${keyWord}`, 3, () => {
            window.location.reload()
          })
          }
        })
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import {
  setHeaderText, setSubHeaderText, setBreadcrumbs, loaded, isLoading,
} from '../../../actions/main';
import './invoiceDetails.scss';
import '../../action-centre/actionCentre.scss';
import { getCountryConfig } from '../../../common/utils'

import InvoicePartiesSection from "./InvoicePartiesSection";
import InvoiceHowToPaySection from "./InvoiceHowToPaySection";
import InvoiceItemsSection from "./InvoiceItemsSection";
import OrderNotes from "../../freights/order-details/OrderNotes";
import InvoiceDetailsConditionSection from './InvoiceDetailsConditionSection';

class InvoiceDetails extends Component {

  constructor(props) {
    super(props);
    this.props.dispatch(isLoading());
    this.state = {
      requestReason: undefined,
      countryConfig: getCountryConfig()
    };
  }

  componentDidMount() {
    this.setLayout();
    setTimeout(() => {
      this.setState({}, () => this.props.dispatch(loaded()));
    }, 2000);
  }

  setLayout() {
    const { invoiceDetails } = this.props;
    const header = getHeaderTitle(invoiceDetails);

    if(header)
      this.props.dispatch(setHeaderText(header));

    const breadcrumbs = [
      {text: 'Invoices', route: '/invoices'},
      {text: get(invoiceDetails, 'identifier', '')}
    ];

    if(!isEqual(this.props.breadcrumbs, breadcrumbs))
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
  }

  componentDidUpdate() {
    this.setLayout();
  }

  static defaultProps = {
    invoiceDetails: {}
  };

  componentWillUnmount() {
    this.props.dispatch(setHeaderText(null));
    this.props.dispatch(setSubHeaderText(null));
  }

  render() {
    const note  = get(this.props, 'invoiceDetails.note');
    return (
      <div className="invoice-details-container">
        {
          this.props.invoiceDetails &&
          <div className='invoice-details-subcontainer'>
            <InvoicePartiesSection {...this.props} />
            <InvoiceItemsSection {...this.props} />
            {
              !this.state.countryConfig?.invoicing?.hideHowToPay &&
                <InvoiceHowToPaySection {...this.props}/>
            }
            <InvoiceDetailsConditionSection {...this.props} />
            {note && <OrderNotes {...this.props} entity={get(this.props, 'invoiceDetails')}/>}
          </div>
        }
      </div>
    );
  }
}

export const getHeaderTitle = invoice => {
  const { isFreightInvoice, isCommodityContractInvoice, isBrokerageInvoice, isRcti , isWarehouseInvoice} = invoice;
  let header;
  if(isFreightInvoice) header = 'Freight';
  if(isBrokerageInvoice) header = 'Brokerage Invoice ';
  if(isCommodityContractInvoice) header = 'Contract';
  if(isWarehouseInvoice) header = 'Warehouse Invoice ';

  if(!isBrokerageInvoice && !isWarehouseInvoice) {
    if(isRcti) header += ' RCTI ';
    else header+=' Tax Invoice ';
  }

  return header + get(invoice, 'identifier', '');
};

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
  };
};

export default connect(mapStateToProps)(InvoiceDetails);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { required } from '../../common/validators';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CommonTextField from '../common/CommonTextField';
import CommonButton from '../common/CommonButton';
import './acceptRejectInvoice.scss';
import APIService from '../../services/APIService';
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import CrossCircle from "@mui/icons-material/Cancel";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";

class UnregisteredUserRejectInvoice extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
      canReject: false,
      isRejected: false,
      invoice: undefined,
      loaded: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount(){
    if(this.props.match && this.props.match.params.invoice_id) {
      APIService.invoices(this.props.match.params.invoice_id).get(this.props.match.params.salt)
      .then((invoice) => {
        if(invoice && invoice.id){
          const newState = {...this.state};
          newState.canReject = invoice.canConfirmOrReject;
          newState.isRejected = invoice.status === 'rejected';
          newState.invoice = invoice;
          newState.loaded = true;
          this.setState(newState);
        }
      });
    }
  }

  handleSubmit(event) {
    this.setErrors();
    if (this.getIsFormValid()) {
      const data = { rejectionReason: this.state.rejectionReason.value };
      const salt = this.props.match ? this.props.match.params.salt : null;
      APIService.invoices(this.state.invoice.id)['reject']().put(data, salt)
        .then((json) => {
          if(!isEmpty(json.errors)){
            if(!isEmpty(json.errors['rejectionReason'])){
              this.setErrors(json.errors['rejectionReason']);
            } else {
              this.setErrors(json.errors);
            }
          } else {
            if(json.status === 'rejected'){
              this.setState({ isRejected: true });
            }
          }
        });
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return this.state.rejectionReason.errors.length == 0;
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            <form onSubmit={this.handleSubmit} noValidate>
              { this.state.invoice && this.state.canReject && !this.state.isRejected ? (
                <div className="status-actions-wrap">
                  <Dialog style={{'zIndex': 9999}}
                  open={true}
                  onClose={false}
                  aria-labelledby="form-dialog-title"
                  fullWidth>
                  <DialogTitle id="form-dialog-title">
                    <CrossCircle style={{ fill: '#FF0000', height: '35px', width: '35px', verticalAlign: 'middle' }} /> Reject Invoice
                  </DialogTitle>
                  <DialogContent>
                    <CommonTextField
                      id="rejectionReason"
                      label="Reason"
                      placeholder="Please enter your reason for rejection"
                      value={this.state.rejectionReason.value}
                      onChange={this.handleChange}
                      helperText={get(this.state, 'rejectionReason.errors[0]', '')}
                      errorStyle={{textAlign: "left"}}
                      fullWidth
                      maxLength="255"
                      multiline
                      rows={7}
                    />
                  </DialogContent>
                  <DialogActions>
                    <DialogActions>
                      <CommonButton onClick={this.handleSubmit} key='reject' label='Reject' primary variant="contained" />
                    </DialogActions>
                  </DialogActions>
                  </Dialog>
                </div>
              ) : (
                  this.state.invoice && this.state.isRejected ? (
                    <span>Invoice is Rejected</span>
                  ) : this.state.loaded && (
                    <span>You do not have permission to access the information.</span>
                  )
              )}
            </form>
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    invoice: state.companies.invoices.selectedInvoice,
  };
};

export default connect(mapStateToProps)(UnregisteredUserRejectInvoice);

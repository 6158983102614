import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import find from 'lodash/find';

const CommonTabs = ({variant, value, tabs, floating, className, actionRightEl, tabStyle}) => {
  const activeTabIndex = tabs.findIndex(tab => tab.url === value);
  const fixedPosStyle =  {position: "fixed", top:"88px", zIndex: '2', backgroundColor: '#fff', borderBottom: '1px solid rgb(224,224,224)'};
  const klassName = floating ? ( className ? className : null ) : ( className ? ( className + " tabs fixed open") : "tabs fixed open" );
  return (
    <React.Fragment>
      <Tabs
        variant={variant || "fullWidth"}
        value={activeTabIndex}
        indicatorColor="primary"
        className={klassName}
        style={floating ? null : fixedPosStyle}
      >
        {
          tabs.map((tab, index) => {
            let originalUrl = tab.url;
            let url = tab.url;

            if(isArray(tab.urls)) {
              originalUrl = find(tab.urls, u => u === window.location.hash.split('#')[1].split('?')[0]);
              if(originalUrl)
                url = originalUrl;
            }
            if(has(tab, 'queryParams') && !isEmpty(tab.queryParams))
              url += tab.queryParams;
            const key = `${originalUrl}-${index}`
            return (
              <Tab
                id={key}
                aria-label={key}
                aria-labelledby={key}
                className="settings-tab"
                key={key}
                label={tab.label}
                value={index}
                href={`#${url}`}
                style={tabStyle || {}}
              >
              </Tab>
            );
          })
        }
      </Tabs>
      {actionRightEl}
      {
        floating ?
        null :
        <div style={{marginBottom: '60px'}}>
        </div>
      }
    </React.Fragment>
  );
};

export default CommonTabs;

export const RECEIVE_INLOADS = 'RECEIVE_INLOADS';
export const ADD_INLOAD = 'ADD_INLOAD';
export const EDIT_INLOAD = 'EDIT_INLOAD';

export const receiveInloads = items => ({
  type: RECEIVE_INLOADS,
  items,
});

export const addInload = item => ({
  type: ADD_INLOAD,
  item,
});

export const editInload = item => ({
  type: EDIT_INLOAD,
  item,
});

import React from 'react';
import ReactDOM from 'react-dom';
import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';
import { Route, HashRouter, Switch } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider, StyledEngineProvider, createTheme, alpha } from '@mui/material/styles';
import './index.scss';
import App from './components/app/App';
import reducer from './reducers';
import './components/app/App.scss';
import alertifyjs from 'alertifyjs';
import { grey } from "@mui/material/colors";
import { PRIMARY_COLOR_GREEN, ACCENT_COLOR_BLUE } from './common/constants';
import Main from './components/main/Main';
import LayoutContext from './components/main/LayoutContext';
import ErrorPage from './components/error-pages/ErrorPage';
import NetworkService from './services/network-service';

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(reducer, composeEnhancers(applyMiddleware(...middleware)));

NetworkService.setupInterceptors(store);

alertifyjs.defaults = {
  ...alertifyjs.defaults,
  notifier: {
    ...alertifyjs.defaults.notifier,
    delay: 3,
    position: 'top-right',
  },
};

const theme = createTheme();
const agrichainTheme = createTheme(theme, {
  ...theme,
  palette: {
    ...theme.palette,
    primary: {
      main: PRIMARY_COLOR_GREEN,
      dark: PRIMARY_COLOR_GREEN,
      light: PRIMARY_COLOR_GREEN,
      contrastText: '#fff',
    },
    secondary: {
      main: ACCENT_COLOR_BLUE,
      dark: ACCENT_COLOR_BLUE,
      light: ACCENT_COLOR_BLUE,
      contrastText: '#fff',
    },
    "default": {
      main: grey[600],
      dark: grey[600]
    },
    grey: {
      main: grey[600],
      dark: grey[600]
    },
    black: {
      main: '#000',
      dark: '#000'
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        arrow: true,
      }
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300])
          }
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: theme.palette.text.primary,
            borderColor:
                    theme.palette.mode === "light"
                    ? "rgba(0, 0, 0, 0.23)"
                    : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`
            },
            "&:hover": {
              borderColor:
                      theme.palette.mode === "light"
                      ? "rgba(0, 0, 0, 0.23)"
                      : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        }
      ]
    }
  }
});

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={agrichainTheme}>
          <Switch>
            {['403', '404'].map(code => (
              <Route key={code} exact path={`/${code}`} component={ErrorPage} />
            ))}
            <Route exact path='/' component={App} />
            <LayoutContext subPages ={(<Main />)} />
          </Switch>
        </ThemeProvider>
      </StyledEngineProvider>
    </HashRouter>
  </Provider>,
  document.getElementById('root')
);

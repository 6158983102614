import React, {Component} from 'react';

import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { get, isEmpty } from 'lodash';
import { EMPTY_VALUE } from '../../common/constants';
import Tooltip from '../../common/Tooltip';
import './movementCard.scss';
import { FREIGHT_CONTRACT_TYPE } from '../../common/constants';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};
const contractStyle = {
  "margin": "0px 0px 20px"
};

class CommodityContractParties extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
    this.toggleExpanded = this.toggleExpanded.bind(this);
  }

  toggleExpanded() {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  }

  getCounterPartiesSectionAsPerGrid = (payload, sectionName) => {
    return (
      <div>
        <h4 className="section-title">{sectionName}</h4>
        <ul>
          {Object.keys(payload).map(function(key, index) {
            return (
              <li key={index}>
               <Tooltip
                  className="field-label ellipses"
                  tooltipText={key}
                  textContent={key}
                />
                {<Tooltip
                  className="field-value ellipses"
                  tooltipText={payload[key] || EMPTY_VALUE}
                  textContent={payload[key] || EMPTY_VALUE}
                />}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  getCompanyPayload(party, type){
    return {
      Name: get(party, 'company.displayName') || get(party, 'companyName'),
      Entity: get(party, 'company.entityName') || get(party, 'entityName'),
      NGR: get(party, 'ngr.ngrNumber') || get(party, 'ngrNumber'),
      Contact: (
        get(party, 'contactName') ||
        (
          type === 'seller' ?
          get(this.props, 'sellerContact.name') :
          get(this.props, 'buyerContact.name')
        )
      ),
    };
  }

  _getClassNames(){
    const { typeId, formTypeId} = this.props;

    if(formTypeId == 0 || (formTypeId==1 && typeId==FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY))
      return "section-details-container-2";

    else
      return "section-details-container" ; }

  render() {
    const { seller, buyer, provider, customer, typeId, formTypeId, title} = this.props;
    const { expanded } = this.state;
    return (
        <Paper className="contract-details-section-container" style={contractStyle}>
          <h2 onClick={this.toggleExpanded}>
            {title ? title : 'Commodity Contract Details'}
            <span className="expand-icon">{renderBasedOnExpandedFlag(expanded)}</span>
          </h2>
          {expanded && <div className={this._getClassNames()}>
            {!isEmpty(seller) && this.getCounterPartiesSectionAsPerGrid(this.getCompanyPayload(seller, 'seller'), 'Seller')}
            {!isEmpty(buyer) && this.getCounterPartiesSectionAsPerGrid(this.getCompanyPayload(buyer, 'buyer'), 'Buyer')}
            {formTypeId==1 && provider && this.getCounterPartiesSectionAsPerGrid(this.getCompanyPayload(provider, 'provider'), 'Freight Provider')}
            {formTypeId==1 && typeId==FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY && customer && this.getCounterPartiesSectionAsPerGrid(this.getCompanyPayload(customer, 'customer'), 'Customer')}
          </div>}
        </Paper>
    );
  }
}

export default CommodityContractParties;

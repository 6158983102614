import React from 'react';

import { connect } from 'react-redux';
import moment from 'moment';
import { Tooltip, Button, Dialog, DialogActions, DialogContent, FormControlLabel, Checkbox, Chip } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { isEmpty, map, isEqual, join, includes, filter } from 'lodash';
import { CloudDownload } from '@mui/icons-material';
import APIService from '../../services/APIService';
import {
  attachCSVEventListener,
} from '../../common/utils';
import { setDownloadBar } from '../../actions/main';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import CommonSelect from '../common/select/CommonSelect';
import { CSVLink } from 'react-csv';
import alertifyjs from 'alertifyjs';

const REPORT_TYPES = [
  {id: 'slots_csv', name: 'Slots'},
  {id: 'booking_cancelled_slots_csv', name: 'Cancelled Slot Report'},
  {id: 'site_comparison', name: 'Site Comparison Report'},
  {id: 'carrier_comparison', name: 'Carrier Comparison Report'},
  {id: 'order_delivery', name: 'Order Delivery Report'},
];

class SlotsCSV extends React.Component {
  constructor(props) {
    super(props);
    this.siteComparisonCSVLink = React.createRef();
    this.carrierComparisonCSVLink = React.createRef();
    this.state = {
      siteComparisonCSVData: [],
      carrierComparisonCSVData: [],
      isOpen: false,
      startDate: undefined,
      endDate: undefined,
      siteIds: [],
      providerIds: [],
      sellerIds: [],
      reportType: props.siteBooking ? 'site_comparison' : 'slots_csv',
      partialDateRange: false,
    };
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
  }

  setSelectedSiteIds() {
    const { siteIds } = this.props;
    if(this.state.isOpen)
      return;

    if(!isEmpty(siteIds) && !isEqual(siteIds, this.state.siteIds))
      this.setState({siteIds: siteIds});
  }

  setDatesFromProps() {
    if(this.state.isOpen)
      return;
    const { startDate, endDate } = this.props;
    if(!startDate || !endDate)
      return;

    const start = this.toFormattedDate(startDate.toDate());
    const end = this.toFormattedDate(endDate.toDate());

    if(start !== this.state.startDate || end !== this.state.endDate)
      this.setState({startDate: start, endDate: end});
  }

  toFormattedDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  componentDidMount() {
    attachCSVEventListener('slots-csv-ready', 'Slots', this.onDownloadResponse);
  }

  componentDidUpdate() {
    this.setDatesFromProps();
    this.setSelectedSiteIds();
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  toggleDialog = () => {
    this.setState({isOpen: !this.state.isOpen});
  };


  getStartEndDateTimeForCSV = () => {
    const { startDate, endDate } = this.state;
    let startTime = `${startDate} 00:00:00`;
    startTime = moment.tz(startDate, moment.tz.guess()).utc().format('YYYY-MM-DDTHH:mm:ss');
    let endTime = `${endDate} 23:59:59`;
    endTime = moment.tz(endDate, moment.tz.guess()).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ss');

    return {startTime, endTime}

  }

  fetchCSVData = () => {
    const { siteIds, reportType } = this.state;
    const { setDownloadBar } = this.props;
    setDownloadBar('Your CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);

    let queryString = '?';
    siteIds.forEach(id => { queryString += `site_id=${id}&`;});
    const { startTime, endTime } = this.getStartEndDateTimeForCSV()
    queryString += `&start=${startTime}&end=${endTime}`;
    let pathName = reportType === 'booking_cancelled_slots_csv' ?
                   'slots/booking-cancelled/csv/' :
                   'slots/csv/';
    pathName += queryString;
    APIService.company_sites().appendToUrl(pathName).get(
      this.props.token,
      {
        'Content-Type': 'text/csv',
        'Accept': 'text/csv',
      },
    ).then(() => {});
  }

  onDateFieldChange = (value, id) => {
    this.setState({[id]: value});
  };

  onSiteChange = (id, selectedItems) => {
    this.setState({siteIds: map(selectedItems, 'id')});
  };

  onProviderChange = (id, selectedItems) => {
    this.setState({providerIds: map(selectedItems, 'id')});
  };

  onSellerChange = (id, selectedItems) => {
    this.setState({sellerIds: map(selectedItems, 'id')});
  };

  onReportTypeChange = value => {
    this.setState({reportType: value});
  };

  isValid() {
    const { siteIds, startDate, endDate } = this.state;
    return Boolean(!isEmpty(siteIds) && startDate && endDate);
  }

  downloadReport = () => {
    const { reportType, siteIds, providerIds, sellerIds, partialDateRange } = this.state;
    if(this.isValid()) {
      if(includes(['site_comparison', 'carrier_comparison', 'order_delivery'], reportType)) {
        let queryString = join(map(siteIds, id => `site_ids=${id}`), '&');
        const { startTime, endTime } = this.getStartEndDateTimeForCSV()
        queryString += `&start=${startTime}&end=${endTime}`;
        this.props.setDownloadBar('Your CSV Report is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
        if(reportType === 'order_delivery') {
          if(!isEmpty(sellerIds)) {
            let sellerQueryString = join(map(sellerIds, id => `seller_ids=${id}`), '&');
            queryString += `&${sellerQueryString}`;
          }
          queryString += `&partial_date_range=${partialDateRange}`;
        } else {
          if(!isEmpty(providerIds)) {
            let providerQueryString = join(map(providerIds, id => `provider_ids=${id}`), '&');
            queryString += `&${providerQueryString}`;
          }
          if(this.props.siteBooking){
            queryString += `&provider_ids=${this.props.currentUserCompanyId}`;
          }
        }
        APIService.company_sites().appendToUrl(`reports/${reportType}/?${queryString}`)
                  .get(null, {'Content-Type': 'text/csv', Accept: 'text/csv'})
                  .then(() => {});
      } else if(includes(['slots_csv', 'booking_cancelled_slots_csv'], reportType)) {
        this.fetchCSVData();
        this.toggleDialog();
      }
    } else {
      if(isEmpty(siteIds))
        alertifyjs.error('Please select atleast one site');
    }
  };

  getReportFileName() {
    return `${this.state.reportType}_${this.state.startDate}-${this.state.endDate}.csv`;
  }

  getReportOptions() {
    return this.props.siteBooking ? filter(REPORT_TYPES, {id: 'site_comparison'}) : REPORT_TYPES;
  }

  onPartialDateRangeChange = event => this.setState({partialDateRange: event.target.checked});

  render() {
    const { isOpen, startDate, endDate, siteIds, providerIds, reportType, sellerIds, partialDateRange } = this.state;
    const { sites, directoryCompanies, siteBooking } = this.props;
    const TZ = moment().tz(moment.tz.guess()).format('z');
    return (
      <span>
        <Tooltip title="Export CSV" arrow>
          <span>
            <Chip
              label='CSV'
              icon={<CloudDownload />}
              variant='contained'
              color='secondary'
              onClick={this.toggleDialog}
              style={{marginRight: '2px'}}
              disabled={isEmpty(siteIds)}
            />
          </span>
        </Tooltip>
        <Dialog open={isOpen} onClose={this.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
          <DialogTitleWithCloseIcon
            onClose={this.toggleDialog}
            closeButtonStyle={{marginTop: '0px'}}
            id='form-dialog-title'>
            Download Reports
          </DialogTitleWithCloseIcon>
          <DialogContent style={{marginTop: '15px'}}>
            <div className='col-sm-6 no-left-padding'>
              <CommonDatePicker
                id='startDate'
                floatingLabelText={`Start Date (${TZ})`}
                value={startDate}
                onChange={this.onDateFieldChange}
              />
            </div>
            <div className='col-sm-6 no-right-padding'>
              <CommonDatePicker
                id='endDate'
                floatingLabelText={`End Date (${TZ})`}
                value={endDate}
                onChange={this.onDateFieldChange}
              />
            </div>
            {
              reportType === 'order_delivery' &&
              <div className="col-sm-12 padding-reset" style={{marginTop: '20px'}}>
                <FormControlLabel
                  control={
                    <Checkbox checked={partialDateRange} onChange={this.onPartialDateRangeChange} />
                  }
                  label="Partial Date Range"
                />
              </div>
            }
            <div className="col-sm-12 padding-reset" style={{marginTop: '20px'}}>
              <CommonSelect
                id="reportType"
                items={this.getReportOptions()}
                value={reportType}
                onChange={this.onReportTypeChange}
                floatingLabelText="Report Type"
                selectConfig={{ text: 'name', value: 'id' }}
              />
            </div>
            <div className="col-sm-12 padding-reset">
              <CommonMultiSelect
                id="siteSelector"
                items={sites}
                selectedItems={siteIds}
                displayField="name"
                onChange={this.onSiteChange}
                placeholder="Select Site(s)..."
                label="Site(s)"
                selectAll
                clearAll
              />
            </div>
            {
              !siteBooking && reportType !== 'order_delivery' &&
              <div className="col-sm-12 padding-reset" style={{marginTop: '20px'}}>
                <CommonMultiSelect
                  id="providerSelector"
                  items={directoryCompanies}
                  selectedItems={providerIds}
                  displayField="name"
                  onChange={this.onProviderChange}
                  placeholder='Select Carrier(s) (Optional)...'
                  label="Carrier(s)"
                  selectAll
                  clearAll
                />
              </div>
            }
            {
              !siteBooking && reportType === 'order_delivery' &&
              <div className="col-sm-12 padding-reset" style={{marginTop: '20px'}}>
                <CommonMultiSelect
                  id="providerSelector"
                  items={directoryCompanies}
                  selectedItems={sellerIds}
                  displayField="name"
                  onChange={this.onSellerChange}
                  placeholder='Select Seller(s) (Optional)...'
                  label="Seller(s)"
                  selectAll
                  clearAll
                />
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button type='button' onClick={this.toggleDialog} variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={this.downloadReport} color='primary' variant='outlined'>
              Download
            </Button>
          </DialogActions>
        </Dialog>
        <CSVLink data={this.state.siteComparisonCSVData} filename={this.getReportFileName()} className='hidden' ref={this.siteComparisonCSVLink} target='_blank' />
        <CSVLink data={this.state.carrierComparisonCSVData} filename={this.getReportFileName()} className='hidden' ref={this.carrierComparisonCSVLink} target='_blank' />
      </span>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
});

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    currentUserCompanyId: state.main.user.user.companyId,
    directoryCompanies: state.companies.companies.items,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SlotsCSV);

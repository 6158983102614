import React from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import '../../index.scss';
import NonProductionEnvironmentHeader from '../NonProductionEnvironmentHeader';
import CompaniesTab from '../companies/CompaniesTab';
import MergeCompaniesForm from '../companies/MergeCompaniesForm';
import ActionCentre from '../action-centre/ActionCentre';
import Contracts from '../contracts/Contracts';
import FreightMovements from '../freights/FreightMovements';
import InvoiceListingHome from '../invoices/InvoiceListingHome';
import LeftPanel from '../panels/LeftPanel';
import Header from '../header/Header';
import Footer from '../Footer';
import MyProfile from '../profiles/MyProfile';
import Dashboard from '../dashboard/Dashboard';
import '../app/App.scss';
import Farms from '../farms/Farms';
import CompanySettings from '../companies/CompanySettings';
import Integrations from '../integrations/Integrations';
import Farm from '../farms/Farm';
import CompanyStocks from '../stocks/CompanyStocks';
import ForgotPassword from '../ForgotPassword';
import LoginFormWithSetPassword from '../login/LoginFormWithSetPassword';
import UserSetPassword from '../login/UserSetPassword';
import CreateCompany from '../../containers/CreateCompany';
import ContractForm from '../contracts/ContractForm';
import FreightMovementForm from '../freights/FreightMovementForm';
import FreightOrderForm from '../freights/FreightOrderForm';
import CreateInvoice from '../../containers/CreateCommodityContractInvoice';
import CreateFreightInvoice from '../../containers/CreateFreightInvoice';
import CreateBrokerageInvoice from '../../containers/CreateBrokerageInvoice';
import ConfirmContractForm from '../contracts/ConfirmContractForm';
import RejectContractForm from '../contracts/RejectContractForm';
import MovementHome from '../freights/MovementDetails/MovementHome';
import ContractHome from '../contracts/ContractHome';
import UnregisteredUserConfirmContract from '../contracts/UnregisteredUserConfirmContract';
import UnregisteredUserRejectContract from '../contracts/UnregisteredUserRejectContract';
import Loader from '../Loader';
import {
  getRootForCurrentUser,
  isEnabledForCurrentUser,
  isSystemCompany,
  closeSpotLight,
  currentUser,
  pendoInitialize,
  clarityInitialize,
  isLoggedOutCashBoardView,
} from '../../common/utils';
import ErrorPage from '../error-pages/ErrorPage';
import MobileTermsAndConditions from "../terms-and-conditions/MobileTermsAndConditions";
import OrderHome from "../freights/OrderHome";
import { receiveToggles, fetchVersion } from '../../actions/main';
import UnregisteredUserConfirmOrder from "../freights/UnregisteredUserConfirmOrder";
import UnregisteredUserRejectOrder from "../freights/UnregisteredUserRejectOrder";
import UnregisteredUserConfirmMovement from "../freights/UnregisteredUserConfirmMovement";
import UnregisteredUserRejectMovement from "../freights/UnregisteredUserRejectMovement";
import APIService from "../../services/APIService";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import has from "lodash/has";
import get from "lodash/get";
import isElement from "lodash/isElement";
import isArray from "lodash/isArray";
import SiteManagement from '../company-sites/SiteManagement';
import SiteBooking from '../company-sites/SiteBooking';
import CompanySignUp from "../profiles/CompanySignUp";
import LogisticSignUp from "../profiles/LogisticSignUp";
import UnregisteredUserConfirmInvoice from '../invoices/UnregisteredUserConfirmInvoice';
import UnregisteredUserRejectInvoice from '../invoices/UnregisteredUserRejectInvoice';
import ReleaseNotes from '../release-notes/ReleaseNotes';
import Toggles from '../common/Toggles';
import ConfirmAmendedContractForUnregisteredUser from "../contracts/ConfirmAmendedContractForUnregisteredUser";
import RejectAmendedContractForUnregisteredUser from "../contracts/RejectAmendedContractForUnregisteredUser";
import WebTermsAndConditions from "../terms-and-conditions/WebTermsAndConditions";
import MetaTags from 'react-meta-tags';
import FreightInvoices from "../invoices/FreightInvoices";
import ConfirmAmendedOrderForUnregisteredUser from "../freights/ConfirmAmendedOrderForUnregisteredUser";
import RejectAmendedOrderForUnregisteredUser from "../freights/RejectAmendedOrderForUnregisteredUser";
import ConfirmAmendedFreightContractForUnregisteredUser
from "../freights/ConfirmAmendedFreightContractForUnregisteredUser";
import RejectAmendedFreightContractForUnregisteredUser
from "../freights/RejectAmendedFreightContractForUnregisteredUser";
import InvoiceHome from "../invoices/InvoiceHome";
import WarehouseInvoiceHome from "../invoices/WarehouseInvoiceHome";
import PaymentRunDetails from "../invoices/PaymentRunDetails";
import Orders from "../freights/Orders";
import SpotLight from '../external/react-spotlight/SpotLight';
import Downloads from '../profiles/Downloads';
import Snackbar from '../common/Snackbar';
import VendorDecs from '../vendor-decs/VendorDecs';
import VendorDecForm from '../vendor-decs/form-components/VendorDecForm';
import TruckVendorDecForm from '../vendor-decs/form-components/TruckVendorDecForm';
import RequestOrderForm from '../freights/RequestOrderForm';
import PackOrderForm from '../freights/PackOrderForm';
import UnregisteredVoidAccept from '../common/UnregisteredVoidAccept';
import UnregisteredVoidReject from '../common/UnregisteredVoidReject';
import VendorDecDetails from '../vendor-decs/details/VendorDecDetails';
import MovementDetailsContainer from '../freights/MovementDetails/MovementDetailsContainer';
import RequestSubmitSuccess from "../profiles/RequestSubmitSuccess";
import StorageLoads from '../stocks/StorageLoads';
import OwnershipLoads from '../stocks/OwnershipLoads';
import WarehouseInvoiceForm from '../invoices/WarehouseInvoiceForm';
import TitleTransfers from '../title-transfers/TitleTransfers';
import CashBoard from '../cash-boards/CashBoard';
import ContractBids from '../contract-bids/ContractBids';
import CashPriceDetails from '../cash-boards/CashPriceDetails';
import WithoutLoginCashBoard from '../cash-boards/WithoutLoginCashBoard';
import FreightScheduler from '../freights/scheduler/FreightScheduler';
import MobileMessages from '../mobile-messages/MobileMessages';
import Dockets from '../dockets/Dockets';
import AzureLogin from '../login/AzureLogin';
import XeroCallback from '../integrations/XeroCallback';
import Templates from '../profiles/Templates';
import Movements from "../freights/Movements";
import { AppContext } from './LayoutContext';

/*eslint react/prop-types: 0*/
const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
           localStorage.getItem('token')
             ? <Component {...props} />
           : <Redirect to={getRootForCurrentUser()} />
         )} />
);

const SuperAdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
           isSystemCompany()
             ? <Component {...props} />
           : <Redirect to='/404' />
         )} />
);

const PrivateEnabledRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
           (localStorage.getItem('token') && isEnabledForCurrentUser(rest.feature))
             ? <Component {...props} />
           : <Redirect to={getRootForCurrentUser(false)} />
         )} />
);

const PrivateNotEnabledSuperAdminRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
           (localStorage.getItem('token') && isEnabledForCurrentUser(rest.feature))
             ? <Component {...props} />
           : <Redirect to={getRootForCurrentUser(false)} />
         )} />
);


const UnRegisteredUserRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => (
           (localStorage.getItem('token') || (props.match.params.salt && props.match.params.pepper))
             ? <Component {...props} />
           : <Redirect to={getRootForCurrentUser()} />
         )} />
);



class Main extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    const isRenderedWithinApp = Boolean(new URLSearchParams(window.location.hash.split('?')[1]).get('hybridRendered')) || Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0) <= 850
    this.state = {
      toggleNav: true,
      isRenderedWithinApp: isRenderedWithinApp,
      togglesFetched: false,
      countriesFetched: false,
    };

    this.toggleNavHandler = this.toggleNavHandler.bind(this);
  }

  addLogoutListenerForAllTabs() {
    window.addEventListener("storage", function (event) {
      if (event.key === 'token' && !event.newValue) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window.location = '/';
      }
    });
  }

  spotLightCloseHandler() {
    const mainContainer = get(document.getElementsByClassName('main-container'), '0');
    const header = get(document.getElementsByTagName('header'), '0');
    const nonProdHeader = document.getElementById('non-prod-header');
    [mainContainer, header, nonProdHeader].forEach(el => {
      if (isElement(el))
        el.onclick = closeSpotLight;
    });
  }

  async componentDidMount() {
    await this.fetchCountries();
    await this.fetchToggles();
    await this.setupLayout();
    this.props.dispatch(fetchVersion());
    setTimeout(this.spotLightCloseHandler, 3000);
    this.addLogoutListenerForAllTabs();
    if (typeof (Storage) !== "undefined" && !localStorage.toggleNav) {
      localStorage.toggleNav = 'true';
    } else if (typeof (Storage) !== "undefined") {
      this.setState({ toggleNav: localStorage.toggleNav === 'true' });
    }
    setInterval(this.setCommonTabWidth, 300);
    pendoInitialize()
    clarityInitialize()
  }

  async fetchToggles() {
    return new Promise(resolve => {
      APIService.toggles().get().then((json) => {
        if (!isEmpty(json)) {
          forOwn(json, (value, key) => {
            if (!has(window, key) || window[key] !== value) {
              window[key] = value;
            }
          });
        }
        resolve();
        this.setState({togglesFetched: true}, () => this.props.dispatch(receiveToggles(json)))
      });
    });
  }

  async fetchCountries() {
    return new Promise(resolve => {
      APIService.countries().get().then(countries => {
        isArray(countries) && localStorage.setItem('countries', JSON.stringify(countries))
        if(!localStorage.current_country) {
          localStorage.setItem('current_country', currentUser()?.countryCode || 'au')
        }
        this.setState({countriesFetched: true})
      });
      resolve();
    });
  }

  setupLayout = async () => {
    const { setDevice } = this.context
    setDevice(this.state.isRenderedWithinApp ? 'mobile' : 'web')
  }

  setCommonTabWidth() {
    Array.prototype.slice.call(document.getElementsByClassName('tabs')).forEach((el) => {
      if (document.getElementsByClassName('main-page nav-collapsed').length === 0) {
        el.classList.remove('open');
        el.classList.add('collapsed');
      } else {
        el.classList.add('open');
        el.classList.remove('collapsed');
      }
      setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
    });
  }

  toggleNavHandler() {
    this.setState({ toggleNav: !this.state.toggleNav });
    if (typeof (Storage) !== "undefined") {
      localStorage.toggleNav = !this.state.toggleNav;
    }
    setTimeout(() => window.dispatchEvent(new Event('resize')), 100);
  }


  render() {
    const userId = get(this.props.user, 'id');
    const isLoggedOutCashBoard = isLoggedOutCashBoardView()
    return (
      <div className={this.state.toggleNav ? "main-page" : "main-page nav-collapsed"}>
        <MetaTags>
          <meta name="vapid-key" content="BNIa1J1ndFhwJ0SBiCWjjz9kwV14Opm7czpLKhvHqMiYrlcizhHMKEOu9_h6NrEoKMQJcLyj3MwDsX3MC2A6-wg" />
          <meta property="user-id" content={userId} />
        </MetaTags>
        {
          this.state.togglesFetched &&
            this.state.countriesFetched &&
            <ErrorPage>
              {
                !this.state.isRenderedWithinApp &&
                  <React.Fragment>
                    <NonProductionEnvironmentHeader />
                    <Header />
                </React.Fragment>
              }
              <SpotLight />
              <div className={(this.state.isRenderedWithinApp ? 'inside-app ' : '') + (isLoggedOutCashBoard ? "main-container logged-out" : 'main-container')}>
                <Loader />
                {
                  !isLoggedOutCashBoard && !this.state.isRenderedWithinApp &&
                  <LeftPanel toggleNavHandler={this.toggleNavHandler} toggleNav={this.state.toggleNav} />
                }
                <Snackbar />
                <div className="content content-wrap-right">
                  <Switch>
                    <Route exact path="/companies/:company_id([0-9]*)/sso/azure/login" component={AzureLogin} />
                    <PrivateRoute path="/xero/callback" component={XeroCallback} />
                    <PrivateRoute exact path="/companies/:company_id([0-9]+)/merge" component={MergeCompaniesForm} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/contracts/:contract_id([0-9]*)/confirm" component={UnregisteredUserConfirmContract} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/contracts/:contract_id([0-9]*)/reject" component={UnregisteredUserRejectContract} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/contracts/:contract_id([0-9]*)/void/confirm" component={UnregisteredVoidAccept} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/contracts/:contract_id([0-9]*)/void/reject" component={UnregisteredVoidReject} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/orders/:order_id([0-9]*)/amend/confirm" component={ConfirmAmendedOrderForUnregisteredUser} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/orders/:order_id([0-9]*)/amend/reject" component={RejectAmendedOrderForUnregisteredUser} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/orders/:order_id([0-9]*)/void/confirm" component={UnregisteredVoidAccept} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/orders/:order_id([0-9]*)/void/reject" component={UnregisteredVoidReject} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/contracts/:contract_id([0-9]*)/amend/confirm" component={ConfirmAmendedContractForUnregisteredUser} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/contracts/:contract_id([0-9]*)/amend/reject" component={RejectAmendedContractForUnregisteredUser} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/orders/:order_id([0-9]*)/confirm" component={UnregisteredUserConfirmOrder} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/orders/:order_id([0-9]*)/reject" component={UnregisteredUserRejectOrder} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/movements/:movement_id([0-9]*)/confirm" component={UnregisteredUserConfirmMovement} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/movements/:movement_id([0-9]*)/void/confirm" component={UnregisteredVoidAccept} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/movements/:movement_id([0-9]*)/void/reject" component={UnregisteredVoidReject} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/movements/:movement_id([0-9]*)/reject" component={UnregisteredUserRejectMovement} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/movements/:movement_id([0-9]*)/amend/confirm" component={ConfirmAmendedFreightContractForUnregisteredUser} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/freights/movements/:movement_id([0-9]*)/amend/reject" component={RejectAmendedFreightContractForUnregisteredUser} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/invoices/:invoice_id([0-9]*)/confirm" component={UnregisteredUserConfirmInvoice} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/invoices/:invoice_id([0-9]*)/void/confirm" component={UnregisteredVoidAccept} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/invoices/:invoice_id([0-9]*)/void/reject" component={UnregisteredVoidReject} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/invoices/:invoice_id([0-9]*)/reject" component={UnregisteredUserRejectInvoice} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/vendor-decs/grain/new" component={VendorDecForm} />
                    <UnRegisteredUserRoute exact path="/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})/vendor-decs/truck/new" component={TruckVendorDecForm} />
                    <Route exact path="/submit/success" component={RequestSubmitSuccess} />
                    <PrivateRoute exact path="/contracts/:contract_id([0-9]*)/confirm" component={ConfirmContractForm} />
                    <PrivateRoute exact path="/contracts/:contract_id([0-9]*)/reject" component={RejectContractForm} />
                    <PrivateRoute path="/profiles/templates/" component={Templates} />
                    <Route path="/profiles/user-set-password/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})" component={UserSetPassword} />
                    <PrivateRoute path="/profiles" component={MyProfile} />
                    <SuperAdminRoute exact path="/dashboard" component={Dashboard} />
                    <PrivateEnabledRoute exact feature="companies" path="/companies" component={CompaniesTab} />
                    <PrivateEnabledRoute exact feature="companies" path="/companies/groups" component={CompaniesTab} />
                    <PrivateEnabledRoute exact feature="contracts" path="/contracts" component={Contracts} />
                    <PrivateEnabledRoute exact feature="invoices" path="/freights/invoices" component={FreightInvoices} />
                    <PrivateEnabledRoute exact feature="movements" path="/freights/movements" component={FreightMovements} />
                    <PrivateEnabledRoute exact feature="freightScheduler" path="/freights/scheduler" component={FreightScheduler} />
                    <PrivateEnabledRoute exact feature="orders" path="/orders/requests/new" component={RequestOrderForm} />
                    <PrivateEnabledRoute exact feature="orders" path="/orders/requests/:order_id([0-9]*)/edit" component={RequestOrderForm} />
                    <PrivateEnabledRoute exact feature="orders" path="/orders/pack/new" component={PackOrderForm} />
                    <PrivateEnabledRoute exact feature="orders" path="/pack/orders/:order_id([0-9]*)/edit" component={PackOrderForm} />
                    <PrivateRoute feature="orders" path="/orders" component={Orders} />
                    <PrivateEnabledRoute feature="movements" path="/movements" component={Movements} />
                    <PrivateEnabledRoute exact feature="vendorDecs" path="/vendor-decs" component={VendorDecs} />
                    <PrivateRoute exact path="/site-management" component={SiteManagement} />
                    <PrivateNotEnabledSuperAdminRoute feature="actionCentre" path="/action-centre" component={ActionCentre} />
                    <PrivateEnabledRoute exact feature="siteBookings" path="/site-bookings" component={SiteBooking} />
                    <PrivateRoute name="companies" path="/companies/:company_id/integrations" component={Integrations} />
                    <PrivateRoute name="companies" feature="companies" path="/companies/:company_id" component={CompanySettings} />
                    <PrivateEnabledRoute exact feature="companies" path="/add-company" component={CreateCompany} />
                    <PrivateEnabledRoute exact feature="contracts" path="/contracts/new" component={ContractForm} />
                    <PrivateEnabledRoute exact feature="vendorDecs" path="/vendor-decs/grain/new" component={VendorDecForm} />
                    <PrivateEnabledRoute exact feature="vendorDecs" path="/vendor-decs/truck/new" component={TruckVendorDecForm} />
                    <PrivateEnabledRoute exact feature="movements" path="/freights/movements/new" component={FreightMovementForm} />
                    <PrivateEnabledRoute exact feature="orders" path="/freights/orders/new" component={FreightOrderForm} />
                    <PrivateEnabledRoute exact feature="orders" path="/freights/grain/new" component={FreightOrderForm} />
                    <PrivateEnabledRoute exact feature="orders" path="/freights/orders/:order_id([0-9]*)/edit" component={FreightOrderForm} />
                    <PrivateEnabledRoute exact feature="movements" path="/freights/movements/:movement_id([0-9]*)/edit" component={FreightMovementForm} />
                    <PrivateEnabledRoute exact feature="invoices" path="/invoices/new" component={CreateInvoice} />
                    <PrivateEnabledRoute exact feature="contracts" path="/invoices/:invoice_id([0-9]*)/edit" component={CreateInvoice} />
                    <PrivateEnabledRoute exact feature="invoices" path="/invoices/brokerage/new" component={CreateBrokerageInvoice} />
                    <PrivateEnabledRoute exact feature="invoices" path="/freights/invoices/new" component={CreateFreightInvoice} />
                    <PrivateEnabledRoute exact feature="invoices" path="/invoices/warehouse/new" component={WarehouseInvoiceForm} />
                    <PrivateEnabledRoute exact feature="contracts" path="/contracts/:contract_id([0-9]*)/edit" component={ContractForm} />
                    <PrivateEnabledRoute name="contracts" feature="contracts" path="/contracts/:contract_id([0-9]*)" component={ContractHome} />
                    <PrivateEnabledRoute name="invoices" feature="invoices" path="/invoices/:invoice_id([0-9]*)/details" component={InvoiceHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/payment-runs/:payment_run_id([0-9]*)" component={PaymentRunDetails} />
                    <PrivateEnabledRoute exact feature="invoices" path="/invoices/warehouse/:tenure/payees/:payee_company_id([0-9]*)/payers/:payer_company_id([0-9]*)/edit" component={WarehouseInvoiceForm} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/warehouse/:tenure/payees/:payee_company_id([0-9]*)/payers/:payer_company_id([0-9]*)" component={WarehouseInvoiceHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices" component={InvoiceListingHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/payable" component={InvoiceListingHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/receivable" component={InvoiceListingHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/payment-runs" component={InvoiceListingHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/freight-receivable" component={InvoiceListingHome} />
                    <PrivateEnabledRoute feature="invoices" path="/invoices/warehouse/dashboard" component={InvoiceListingHome} />
                    <PrivateEnabledRoute name="orders" feature="orders" path="/freights/orders/:order_id([0-9]*)" component={OrderHome} />
                    <PrivateEnabledRoute name="orders" feature="orders" path="/pack/orders/:order_id([0-9]*)" component={OrderHome} />
                    <PrivateEnabledRoute name="movements" feature="movements" path="/freights/movements/:movement_id([0-9]*)" component={MovementHome} />
                    <PrivateRoute exact feature="titleTransfers" path="/title-transfers" component={TitleTransfers} />
                    <PrivateEnabledRoute exact feature="movements" path="/freights/movements/:movement_id([0-9]*)/details" component={MovementDetailsContainer} />
                    <PrivateEnabledRoute exact feature="vendorDecs" path="/vendor-decs/:vendor_dec_id([0-9]*)/details" component={VendorDecDetails} />
                    <PrivateEnabledRoute exact feature="farms" path="/farms" component={Farms} />
                    <PrivateEnabledRoute feature="stocks" path="/stocks" component={CompanyStocks} />
                    <PrivateRoute feature="farms" path="/farms/:farm_id([0-9]+)/storages/:storage_id([0-9]+)/loads" component={StorageLoads} />
                    <PrivateRoute feature="farms" path="/farms/:farm_id([0-9]+)/ngrs/:ngr_id([0-9]+)/ownership-loads" component={OwnershipLoads} />
                    <PrivateRoute feature="farms" path="/farms/:farm_id([0-9]+)/ownership-loads" component={OwnershipLoads} />
                    <PrivateRoute feature="farms" path="/farms/:farm_id([0-9]+)" component={Farm} />
                    <PrivateEnabledRoute feature="cashBoard" path="/cash-board/cash-prices" component={CashBoard} />
                    <PrivateEnabledRoute feature="cashBoard" path="/cash-price/:cash_price_id([0-9]*)" component={CashPriceDetails} />
                    <PrivateEnabledRoute exact feature="cashBoard" path="/contract-bids" component={CashBoard} />
                    <PrivateEnabledRoute exact feature="cashBoard" path="/contract-bids/:contract_bid_id([0-9]*)" component={ContractBids} />
                    <SuperAdminRoute exact path="/release-notes" component={ReleaseNotes} />
                    <SuperAdminRoute exact path="/mobile-messages" component={MobileMessages} />
                    <SuperAdminRoute exact path="/dockets" component={Dockets} />
                    <SuperAdminRoute exact path="/toggles" component={Toggles} />
                    <Route exact path="/sign-up" component={CompanySignUp} />
                    <Route exact path="/simplesignup" component={LogisticSignUp} />
                    <Route exact path="/forgot-password" component={ForgotPassword} />
                    <Route exact path="/profiles/:profile_id([0-9]+)/set-password/:salt([a-zA-Z0-9]{40})/:pepper([a-zA-Z0-9]{32})" component={LoginFormWithSetPassword} />
                    <Route exact path="/terms-and-conditions" component={MobileTermsAndConditions} />
                    <Route exact path="/web/terms-and-conditions" component={WebTermsAndConditions} />
                    <Route exact path="/downloads" component={Downloads} />
                    <Route path='/cash-board' component={WithoutLoginCashBoard} />
                    <Route exact path="/sso/azure/callback/" component={AzureLogin} />
                    <Redirect to="/404" />
                  </Switch>
                </div>
              </div>
            </ErrorPage>
        }
        {
        !this.state.isRenderedWithinApp &&
        <Footer />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
  };
};

export default connect(mapStateToProps)(Main);

import { connect } from 'react-redux';
import StorageForm from '../components/storages/StorageForm';
import { createStorage } from '../actions/api/storages';
import { addStorage } from '../actions/companies/storages';

function submit(farmId, data, callback) {
  return (dispatch) => {
    dispatch(createStorage(farmId, data, addStorage, callback));
  };
}

export default connect(null, { submit })(StorageForm);

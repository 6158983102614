import React from "react";
import styled from "styled-components";
import FileDownloadIcon from '@mui/icons-material/FileDownload';

import media from "./media";
import SpotlightContext from "./SpotlightContext";

const DownloadButton = styled.div`
  width: 30px;
  height: 22px;
  margin: 16.5px;
  position: absolute;
  background-size: cover;
  color: rgb(166,166,166);
  cursor: pointer;

  ${media.pc`
float: right;
position: static;
`};

  svg {
    color: #a6a6a6;
  }
`;


const download = data => {
  var D = document, a = D.createElement("a");

  a.href = "data:text/csvcharset=utf-8," + escape(data);

  if ('download' in a) {
    a.setAttribute("download", 'data.csv');
    a.innerHTML = "downloading...";
    D.body.appendChild(a);
    setTimeout(function() {
      var event = D.createEvent("MouseEvents");
      event.initMouseEvent(
        "click", true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null
      );
      a.dispatchEvent(event);
      D.body.removeChild(a);
    }, 66);
    return true;
  };
}

const processDownload = (data) => {
  download(data)
}

export default () => (
  <SpotlightContext.Consumer>
    {({ copyableData }) => {
       return (
         <DownloadButton onClick={() => { processDownload(copyableData) }}>
           <FileDownloadIcon />
         </DownloadButton>
       )
    }}
  </SpotlightContext.Consumer>
);

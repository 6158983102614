import { connect } from 'react-redux';
import FarmFieldForm from '../components/farm-fields/FarmFieldForm';
import { createFarmField } from '../actions/api/farm-fields';
import { addFarmField } from '../actions/companies/farm-fields';

function submit(farmId, data) {
  return (dispatch) => {
    dispatch(createFarmField(farmId, data, addFarmField));
  };
}

export default connect(null, { submit })(FarmFieldForm);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import {
  confirm,
  receiveContract,
  getSelectedContract,
} from '../../actions/companies/contracts';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import {required} from '../../common/validators';

class ConfirmContractForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contractNumber: {
        value: this.props.contract ? this.props.contract.contractNumber : undefined,
        validators: [],
        errors: []
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    if(this.props.match && this.props.match.params.contract_id) {
      const {dispatch} = this.props;
      if(isEmpty(this.props.contract)) {
        dispatch(getSelectedContract(this.props.match.params.contract_id, receiveContract, false, this.props.match.params.salt));
      }
    }
  }

  componentDidUpdate() {
    if(this.state.contractNumber.value === undefined && this.props.contract) {
      const newState = {...this.state};
      newState.contractNumber.value = this.props.contract.contractNumber;

      this.setState(newState);
    }

    if(get(this.props, 'contract.isContractNumberMandatoryForConfirmation') && isEmpty(this.state.contractNumber.validators))
      this.setState({contractNumber: {...this.state.contractNumber, validators: [required()]}});
  }

  handleSubmit(event) {
    this.setErrors();

    if (this.getIsFormValid()) {
      const data = { contractNumber: this.state.contractNumber.value };
      const salt = this.props.match ? this.props.match.params.salt : null;
      const { dispatch } = this.props;
      dispatch(confirm(this.props.contract.id, data, salt));
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.contractNumber.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.contractNumber.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `contractNumber.value`);
    const validators = get(this.state, `contractNumber.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return this.state.contractNumber.errors.length == 0;
  }

  canConfirm() {
    if(this.props.contract && this.props.contract.confirmable) {
      if(isEmpty(this.props.confirmedContract)) {
        return this.props.contract.id && this.props.contract.status === 'planned';
      } else {
        return this.props.confirmedContract.status !== 'confirmed';
      }
    }
  }

  isConfirmed() {
    const isConfirmed = this.props.confirmedContract && this.props.confirmedContract.status === 'confirmed';
    if(isConfirmed) {
      const salt = this.props.match ? this.props.match.params.salt : null;
      if(!salt) {
        const url = '/contracts/' + (this.props.contract ? this.props.contract.id : '');
        document.location.hash = url;
      }
    }
    return isConfirmed;
  }

  isContractNumberFieldDisabled() {
    return this.props.contract && this.props.contract.party === 'seller' && this.props.contract.documentType.name === 'contract';
  }

  render() {
    return (
      <div>
        { this.props.contract ?
          <form onSubmit={this.handleSubmit} noValidate>
            <Card style={{ padding: 20, margin: '10% auto', width: '50%'}}>
              { this.canConfirm() ?
                (
                  <div>
                    <CardHeader
                      title="Confirm Contract"
                    />
                    <CardContent>
                      <div className="row">
                        <div className="col-xs-12">
                          <CommonTextField
                            id="contractNumber"
                            floatingLabelText="Contract Number"
                            hintText="Please enter"
                            value={this.state.contractNumber.value}
                            onChange={this.handleChange}
                            errorText={get(this.state, 'contractNumber.errors[0]', '')}
                            errorStyle={{textAlign: "left"}}
                            fullWidth
                            maxLength="255"
                            disabled={this.isContractNumberFieldDisabled()}
                          />
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                      <div className="row">
                        <div className="col-xs-12">
                          <CommonButton
                            type="submit"
                            label="Submit"
                            primary={true}
                            variant="contained"
                          />
                        </div>
                      </div>
                    </CardActions>
                  </div>
                )
                : ( this.isConfirmed() ?
                    <div>
                      <CardHeader
                        subtitle="Contract is Confirmed"
                      />
                    </div>
                :
                    <div>
                      <CardHeader
                        subtitle="You do not have permission to access the information."
                      />
                    </div>
                )
              }
            </Card>
          </form>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.companies.contracts.selectedContract,
    confirmedContract: state.companies.contracts.confirmedOrRejectedContract
  };
};

export default connect(mapStateToProps)(ConfirmContractForm);

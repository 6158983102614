import React from "react";
import { connect } from 'react-redux';
import CommonAutoSelect from "../../common/autocomplete/CommonAutoSelect";
import CommonSelect from "../../common/select/CommonSelect";
import {required} from "../../../common/validators";
import {INVOICINGS} from "../fixtures";
import CommonTextField from "../../common/CommonTextField";
import {positiveDecimalFilter} from "../../../common/input-filters";
import InputAdornment from "@mui/material/InputAdornment/InputAdornment";
import CommonButton from "../../common/CommonButton";

import {getCompanyCompanies} from "../../../actions/api/companies";
import APIService from "../../../services/APIService";
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import get from 'lodash/get';
import {getFixtures} from "../../../actions/companies/freights";
import isNumber from "lodash/isNumber";
import {
  BHC_TYPE_ID,
  DELIVERY_MANDATORY_PRICE_POINTS,
  PICKUP_MANDATORY_PRICE_POINTS,
  PRIMARY_COLOR_GREEN
} from "../../../common/constants";
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import {
  getGeneralConditions,
  getSpecialConditions,
  receiveGeneralConditions, receiveSpecialConditions
} from "../../../actions/companies/conditions";
import {getParties} from "../../../actions/companies/contracts";
import {getBHCLocations, isLoading, loaded} from "../../../actions/main";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import merge from "lodash/merge";
import uniqBy from "lodash/uniqBy";
import filter from "lodash/filter";
import isEqual from "lodash/isEqual";
import find from "lodash/find";
import has from "lodash/has";
import mapValues from "lodash/mapValues";
import includes from "lodash/includes";
import some from "lodash/some";

class OrderProviderDetailsForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isDefaultGeneralConditionApplied: false,
      isDefaultSpecialConditionApplied: false,
      fields: {
        providerId: {
          value: undefined,
          errors: [],
          validators: [required()]
        },
        assignToId: {
          value: undefined,
          errors: [],
          validators: [required()]
        },
        invoicing: {
          value: undefined,
          errors: [],
          validators: [required()]
        },
        paymentTermId: {
          value: this.props.customerPaymentTermId,
          errors: [],
          validators: [required()]
        },
        rateFreightIn: {
          value: undefined,
          errors: [],
          validators: []
        },
        rateFreightOut: {
          value: undefined,
          errors: [],
          validators: [required()]
        },
        isSelf: {
          value: false,
          errors: [],
          validators: []
        },
        generalConditions: {
          value: '',
          errors: [],
          validators: []
        },
        specialConditions: {
          value: '',
          errors: [],
          validators: []
        },
        freightPickup: {
          consignor: {
            handlerId: {
              value: get(this.props.order,'freightPickup.consignor.handlerId'),
              validators: [],
              errors: []
            },
            site: {
              locationType: {
                value: get(this.props.order,'freightPickup.consignor.sites.0.locationContentType'),
                validators: [],
                errors: []
              },
              locationId: {
                value: get(this.props.order,'freightPickup.consignor.sites.0.locationId'),
                validators: [],
                errors: []
              },
            },
          },
        },
        freightDelivery: {
          consignee: {
            handlerId: {
              value: get(this.props.order,'freightDelivery.consignee.handlerId'),
              validators: [],
              errors: []
            },
            site: {
              locationType: {
                value: get(this.props.order,'freightDelivery.consignee.sites.0.locationContentType'),
                validators: [],
                errors: []
              },
              locationId: {
                value: get(this.props.order,'freightDelivery.consignee.sites.0.locationId'),
                validators: [],
                errors: []
              }
            },
          },
        },
      },
      selectedSpecialConditionText: undefined,
      selectedGeneralConditionText: undefined,
      providers: this.props.providers,
      paymentTerms: this.props.paymentTerms,
      providerEmployees: this.props.providerEmployees,
      consignors: [],
      consignees: [],
      consignorSites: [],
      consigneeSites: [],
      selectedConsignor: undefined,
      selectedConsignee: undefined,
      selectedConsignorSite: undefined,
      selectedConsigneeSite: undefined,
      isConsignorDisabled: false,
      isConsigneeDisabled: false,
    };

    const { dispatch } = this.props;
    Promise.all([
      dispatch(getParties()),
      dispatch(getBHCLocations()),
      dispatch(getFixtures()),
      dispatch(getCompanyCompanies(this.props.userCompanyId, {
        include_parent_company: true,
      })),
      dispatch(getGeneralConditions('freight')),
      dispatch(getSpecialConditions('freight')),
    ]);
    this.getCompanyEmployees = this.getCompanyEmployees.bind(this);
    this.setDefaultPickupSite = true;
    this.setDefaultDeliverySite = true;
    this.removeLoader = false;
  }

  componentDidMount(){
    this.props.showLoader();
    this.enableOrDisableConsignorConsignee();
    setTimeout(() => {
      this.removeLoader = true;
      this.props.stopLoader();
    },5000);
  }

  componentWillUnmount() {
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(receiveSpecialConditions([]));
  }

  componentDidUpdate(prevProps) {
    const newState = {...this.state};
    if (!isEqual(this.props['consignmentParties'], prevProps['consignmentParties']) || isEmpty(this.state.consignors)) {
      if (!isEqual(this.state.consignors, this.props.consignmentParties)) {
        newState.consignors = this.props.consignmentParties;
      }
    }
    newState.consignees = this.getConsignees(newState);
    if (!(isEqual(this.state.consignees, newState.consignees) || isEqual(this.state.consignors, newState.consignors))) {
      this.setState(newState,() => {
        const selectedConsignor = find(this.state.consignors,{
          id: get(this.props.order,'freightPickup.consignor.handlerId'),
        });
        if(selectedConsignor){
          this.setState({selectedConsignor});
        }

        const selectedConsignee = find(this.state.consignees,{
          id: get(this.props.order,'freightDelivery.consignee.handlerId'),
        });
        if(selectedConsignee){
          this.setState({selectedConsignee});
        }
      });
    }

    if(!isEmpty(this.state.consignorSites) && this.setDefaultPickupSite){
      this.setDefaultPickupSite = false;
      this.removeLoader = true;
      this.setDefaultConsignorSite();
      this.props.stopLoader();
    }

    if(!isEmpty(this.state.consigneeSites) && this.setDefaultDeliverySite){
      this.setDefaultDeliverySite = false;
      this.removeLoader = true;
      this.setDefaultConsigneeSite();
      this.props.stopLoader();
    }
    if(!isEmpty(this.props.specialConditionsAll) && !this.selectedContract && !this.state.isDefaultSpecialConditionApplied) {
      newState.fields.specialConditions.value = map(filter(this.props.specialConditionsAll, {default: true}), 'details').join('\n');
      newState.isDefaultSpecialConditionApplied = true;
    }

    if(!isEmpty(this.props.generalConditionsAll) && !this.selectedContract && !this.state.isDefaultGeneralConditionApplied) {
      newState.fields.generalConditions.value = map(filter(this.props.generalConditionsAll, {default: true}), 'details').join('\n');
      newState.isDefaultGeneralConditionApplied = true;
    }

  }

  setDefaultConsignorSite = () => {
    const locationId = get(this.props.order,'freightPickup.consignor.sites.0.locationId');
    const locationContentType = get(this.props.order,'freightPickup.consignor.sites.0.locationContentType');
    if(locationId && locationContentType){
      const newState = {...this.state};
      newState.fields.freightPickup.consignor.site.locationId.value = locationId;
      newState.fields.freightPickup.consignor.site.locationType.value = locationContentType;
      newState.selectedConsignorSite = find(newState.consignorSites, {
        id: locationId,
        entity: locationContentType
      });
      this.setState(newState);
    }
  };

  setDefaultConsigneeSite = () => {
    const locationId = get(this.props.order,'freightDelivery.consignee.sites.0.locationId');
    const locationContentType = get(this.props.order,'freightDelivery.consignee.sites.0.locationContentType');
    if(locationId && locationContentType){
      const newState = {...this.state};
      newState.fields.freightDelivery.consignee.site.locationId.value = locationId;
      newState.fields.freightDelivery.consignee.site.locationType.value = locationContentType;
      newState.selectedConsigneeSite = find(newState.consigneeSites, {
        id: locationId,
        entity: locationContentType
      });
      this.setState(newState);
    }
  };

  async getCompanyEmployees(companyId){
    const employees = await APIService.companies(companyId).employees().get(this.props.currentUser.token);
    const providerKeyContactId = this.getKeyContactId(employees, companyId, 'company');
    this.setState({
      'providerEmployees': employees
    }, () => {
      if(providerKeyContactId) {
        this.handleSelectFieldChange(providerKeyContactId, 'assignToId');
      }
    });
  }

  getKeyContactId(contacts, entityId, partyType) {
    const keyContacts = filter(contacts, {keyContact: true});
    let keyContactId = null;
    if(keyContacts && keyContacts.length === 1) {
      keyContactId = keyContacts[0].id;
    } else if(partyType === 'farm') {
      const farmKeyContact = find(keyContacts, {farmId: entityId});
      if(farmKeyContact) {
        keyContactId = farmKeyContact.id;
      }
    } else {
      const companyKeyContact = find(keyContacts, {companyId: entityId});
      if(companyKeyContact) {
        keyContactId = companyKeyContact.id;
      }
    }
    return keyContactId;
  }

  handleProviderChange = (value, id, item) => {
    const newState = {...this.state};
    if (item) {
      newState.fields.providerId.value = value;
      this.getCompanyEmployees(value);
      newState.fields.isSelf.value = false;
      if (this.props.customerCompanyId === value) {
        newState.fields.isSelf.value = true;
      }
      this.changeValidatorsForSelf(newState);
    } else {
      newState.fields.providerId.value = undefined;
    }
    this.setConsignorConsigneeValidators(newState);
    this.setState(newState);
    this.setFieldErrors(id, value);
  };

  changeValidatorsForSelf = (newState) => {
    if (newState.fields.isSelf.value){
      newState.fields.paymentTermId.validators = [];
      newState.fields.invoicing.validators = [];
    } else {
      newState.fields.paymentTermId.validators = [required()];
      newState.fields.invoicing.validators = [required()];
    }
  };

  handleSelectFieldChange = (value, id) => {
    const newState = {...this.state};
    if(value){
      newState.fields[id].value = value;
    } else {
      newState.fields[id].value = undefined;
    }
    this.setState(newState);
    this.setFieldErrors(id, value);
  };

  handleFreightRateChange = (event) => {
    this.setFieldValue(event.target.value, event.target.id);
  };

  handleConditionTextChange = (event) => {
    this.setFieldValue(event.target.value, event.target.id);
  };

  handleConditionSelector = (value, id, chosenItem) => {
    var key = null;
    if (id === 'generalConditionsSelector') {
      key = 'selectedGeneralConditionText';
    } else if (id === 'specialConditionsSelector'){
      key = 'selectedSpecialConditionText';
    }
    const newState = { ...this.state };
    if(chosenItem){
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }
    this.setState(newState);
  };

  setFieldValue = (value, id) => {
    const newState = {...this.state};
    set(newState.fields, `${id}.value`, value);
    this.setState(newState);
    this.setFieldErrors(id, value);
  };

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key] ? this.state.fields[key].value : '';
    const validators = this.state.fields[key] ? this.state.fields[key].validators : [];
    if (validators) {
      validators.forEach((validator) => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }
    return errors;
  }

  setFieldErrors = (id, value) => {
    const newState = {...this.state};
    const field = get(newState.fields, id);
    field.errors = [];
    forEach(field.validators, validator => {
      if(validator.isInvalid(value || field.value)){
        field.errors.push(validator.message);
      }
    });
    this.setState(newState);
  };

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  handleSelectBlur = (event) => {
    this.setFieldErrors(event.target.id);
  };

  handleBlur = (event) => {
    this.setFieldErrors(event.target.id, event.target.value);
  };

  appendGeneralConditions = () => {
    const newState = {...this.state};
    newState.fields.generalConditions.value = newState.fields.generalConditions.value + "\n" + newState.selectedGeneralConditionText;
    this.setState(newState);
  };
  appendSpecialConditions = () => {
    const newState = {...this.state};
    newState.fields.specialConditions.value = newState.fields.specialConditions.value + "\n" + newState.selectedSpecialConditionText;
    this.setState(newState);
  };

  isFormValid = () => {
    return this.valid(this.state.fields)
        && this.valid(this.state.fields.freightPickup.consignor)
        && this.valid(this.state.fields.freightDelivery.consignee)
        && this.valid(this.state.fields.freightPickup.consignor.site)
        && this.valid(this.state.fields.freightDelivery.consignee.site);
  };

  valid(fields) {
    let isValid = true;
    forEach(fields, field => {
      forEach(field.validators, validator => {
        if (validator.isInvalid(field.value)) {
          isValid = false;
        }
      });
    });
    return isValid;
  }

  getSubmitData = () => {
    return {
      providerId: this.state.fields.providerId.value,
      assignToId: this.state.fields.assignToId.value,
      isSelf: this.state.fields.isSelf.value,
      rateFreightOut: this.state.fields.rateFreightOut.value,
      paymentTermId: this.state.fields.isSelf.value ? undefined : this.state.fields.paymentTermId.value,
      invoicing: this.state.fields.isSelf.value ? undefined : this.state.fields.invoicing.value,
      rateFreightIn: this.state.fields.isSelf.value ? undefined : this.state.fields.rateFreightIn.value,
      generalConditions: this.state.fields.isSelf.value ? undefined : this.state.fields.generalConditions.value,
      specialConditions: this.state.fields.isSelf.value ? undefined : this.state.fields.specialConditions.value
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setAllFieldsErrors(() => {
      const isFormInvalid = some(this.state.fields, (field) => {
        return field.errors.length > 0;
      });
      const submitData = this.getSubmitData();
      const isSeller = this.props.order.isSeller;
      const isBuyer = this.props.order.isBuyer;

      if(isSeller){
        submitData.freightPickup = mapValues(this.state.fields.freightPickup, 'value');
        if(this.state.fields.freightPickup.consignor.handlerId.value){
          submitData.freightPickup.consignor = {
            handlerId: this.state.fields.freightPickup.consignor.handlerId.value,
          };
        }
        if (this.state.fields.freightPickup.consignor.site.locationId.value && this.state.fields.freightPickup.consignor.site.locationType.value) {
          submitData.freightPickup.consignor.sites = [{
            locationId: this.state.fields.freightPickup.consignor.site.locationId.value,
            locationType: this.state.fields.freightPickup.consignor.site.locationType.value,
          }];
        }
      }

      if(isBuyer){
        submitData.freightDelivery = mapValues(this.state.fields.freightDelivery, 'value');
        if(this.state.fields.freightDelivery.consignee.handlerId.value){
          submitData.freightDelivery.consignee = {
            handlerId: this.state.fields.freightDelivery.consignee.handlerId.value,
          };
        }

        if (this.state.fields.freightDelivery.consignee.site.locationId.value && this.state.fields.freightDelivery.consignee.site.locationType.value) {
          submitData.freightDelivery.consignee.sites = [{
            locationId: this.state.fields.freightDelivery.consignee.site.locationId.value,
            locationType: this.state.fields.freightDelivery.consignee.site.locationType.value,
          }];
        }
      }

      if (!isNumber(get(submitData, 'freightPickup.consignor.handlerId')) && has(submitData,'freightPickup.consignor')) {
        delete submitData.freightPickup.consignor;
      } else {
        forEach(get(submitData, 'freightPickup.consignor.sites'), (site) => {
          if (!isNumber(site.locationId))
            delete submitData.freightPickup.consignor['sites'];
        });
      }

      if (!isNumber(get(submitData, 'freightDelivery.consignee.handlerId')) && has(submitData,'freightDelivery.consignee')) {
        delete submitData.freightDelivery.consignee;
      } else {
        forEach(get(submitData, 'freightDelivery.consignee.sites'), (site) => {
          if (!isNumber(site.locationId))
            delete submitData.freightDelivery.consignee['sites'];
        });
      }

      if (!isFormInvalid){
        this.props.handleAccept(submitData);
      }
    });
  };

  getConsignees = (newState) => {
    var consignees = this.props.consignmentParties;
    const selectedTrack = get(newState.selectedBaseEntity, 'trackName');
    if (!isEmpty(selectedTrack)) {
      const locationsForTrack = filter(this.props.operators, (operator) => {
        return includes(operator.tracks, selectedTrack);
      });
      let bhcOperators = uniqBy(map(locationsForTrack, (loc) => {
        return {
          id: loc.companyId,
          name: loc.company.businessName,
          isRegistered: loc.company.isRegistered,
          entity: 'company',
          typeId: loc.company.typeId,
          rel: 'companies',
          address: loc.address,
        };
      }), 'id');
      consignees = filter(this.props.consignmentParties, (party) => {
        return party.typeId !== BHC_TYPE_ID;
      }).concat(bhcOperators);
    }
    return consignees;
  };

  handleConsignorChange = (value, id, item) => {
    const newState = {...this.state};
    newState.fields.freightPickup.consignor.site.locationId.value = null;
    newState.fields.freightPickup.consignor.site.locationType.value = null;
    newState.fields.freightPickup.consignor.handlerId.value = value;
    if (item) {
      newState.selectedConsignor = item;
      this.getSites(value, item.entity, 'consignor');
    } else {
      newState.selectedConsignor = undefined;
      newState.consignorSites = [];
    }
    this.setState(newState, () => this.setFieldErrors(id, value));
  };

  handleConsigneeChange = (value, id, item) => {
    const newState = {...this.state};
    newState.fields.freightDelivery.consignee.site.locationId.value = null;
    newState.fields.freightDelivery.consignee.site.locationType.value = null;
    newState.fields.freightDelivery.consignee.handlerId.value = value;
    if (item) {
      newState.selectedConsignee = item;
      this.getSites(value, item.entity, 'consignee');
    } else {
      newState.selectedConsignee = undefined;
      newState.consigneeSites = [];
    }
    this.setState(newState, () => this.setFieldErrors(id, value));
  };

  async getSites(handlerId, partyType, getSitesFor) {
    let sites = [];
    if (partyType == 'farm') {
      let homeStorages = await APIService.farms(handlerId)['storages/home']().get(this.props.userToken);
      let farmFields = await APIService.farms(handlerId).farm_fields().get(this.props.userToken);

      homeStorages = map(homeStorages, (storage) => {
        return merge(storage, {
          id: storage.id, entity: 'storage', storageType: 'home',
        });
      });
      sites = farmFields.concat(homeStorages);
    } else {
      let companySites = await APIService.companies(handlerId).company_sites().get(this.props.userToken);
      sites = map(companySites, (site) => {
        return merge(site, {entity: 'companysite'});
      });
      const selectedTrack = get(this.props.order, 'commodityContract.track');
      if(selectedTrack && getSitesFor == 'consignee'){
        sites = filter(sites, (site) => {
          if (site.isBhc) {
            return includes(site.tracks, selectedTrack);
          } else {
            return site;
          }
        });
      }
    }
    if (getSitesFor == 'consignor') {
      this.setState({ consignorSites: sites});
    } else if (getSitesFor == 'consignee') {
      this.setState({ consigneeSites: sites});
    }
  }

  handlePickupSiteChange = (value, id, item) => {
    const newState = {...this.state};
    if(item){
      newState.selectedConsignorSite = item;
      newState.fields.freightPickup.consignor.site.locationId.value = item.id;
      newState.fields.freightPickup.consignor.site.locationType.value = item.entity;
    } else {
      newState.selectedConsignorSite = undefined;
      newState.fields.freightPickup.consignor.site.locationId.value = undefined;
      newState.fields.freightPickup.consignor.site.locationType.value = '';
    }
    this.setState(newState, () => this.setFieldErrors(id, value));
  };

  handleDeliverySiteChange = (value, id, item) => {
    const newState = {...this.state};
    if(item){
      newState.selectedConsigneeSite = item;
      newState.fields.freightDelivery.consignee.site.locationId.value = item.id;
      newState.fields.freightDelivery.consignee.site.locationType.value = item.entity;
    } else {
      newState.selectedConsigneeSite = undefined;
      newState.fields.freightDelivery.consignee.site.locationId.value = undefined;
      newState.fields.freightDelivery.consignee.site.locationType.value = '';
    }

    this.setState(newState, () => this.setFieldErrors(id, value));
  };

  setConsignorConsigneeValidators = (newState) => {
    const isSeller = this.props.order && this.props.order.isSeller;
    const isBuyer = this.props.order && this.props.order.isBuyer;
    this.setConsignorValidator(newState,[]);
    this.setConsigneeValidator(newState,[]);
    this.setConsignorSiteValidator(newState,[]);
    this.setConsigneeSiteValidator(newState,[]);
    if(!newState.fields.isSelf.value){
      if(isSeller){
        this.setConsignorValidator(newState,[required()]);
        this.setConsignorSiteValidator(newState,[required()]);
      }

      if (isBuyer) {
        this.setConsigneeValidator(newState,[required()]);
        this.setConsigneeSiteValidator(newState,[required()]);
      }
    }
  };

  setConsignorValidator(newState, validators){
    newState.fields.freightPickup.consignor.handlerId.errors = [];
    newState.fields.freightPickup.consignor.handlerId.validators = validators;
  }

  setConsignorSiteValidator(newState, validators){
    newState.fields.freightPickup.consignor.site.locationType.validators = validators;
    newState.fields.freightPickup.consignor.site.locationId.validators = validators;
    newState.fields.freightPickup.consignor.site.locationType.errors = [];
    newState.fields.freightPickup.consignor.site.locationId.errors = [];
  }

  setConsigneeValidator(newState, validators){
    newState.fields.freightDelivery.consignee.handlerId.errors = [];
    newState.fields.freightDelivery.consignee.handlerId.validators = validators;
  }

  setConsigneeSiteValidator(newState, validators){
    newState.fields.freightDelivery.consignee.site.locationType.errors = [];
    newState.fields.freightDelivery.consignee.site.locationId.errors = [];
    newState.fields.freightDelivery.consignee.site.locationType.validators = validators;
    newState.fields.freightDelivery.consignee.site.locationId.validators = validators;
  }

  enableOrDisableConsignorConsignee = () => {
    const pricePointId = get(this.props.order,'commodityContract.pricePointId');
    const newState = {...this.state};
    if(includes(PICKUP_MANDATORY_PRICE_POINTS, pricePointId)){
      newState.isConsignorDisabled = true;
    } else {
      newState.isConsignorDisabled = false;
    }
    if(includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId)){
      newState.isConsigneeDisabled = true;
    } else {
      newState.isConsigneeDisabled = false;
    }
    this.setState(newState);
  };

  render(){
    const showPickupDetails = this.props.order && this.props.order.isSeller;
    const showDeliveryDetails = this.props.order && this.props.order.isBuyer;
    return (
      <div className='cardForm cardForm--drawer'>
        <div className="cardForm-content row">
          {
            this.removeLoader && <div className="dom-for-order-accept-reject-loader hide"></div>
          }
          {showPickupDetails && <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              items={this.state.consignors}
              id="freightPickup.consignor.handlerId"
              label="Pickup Site"
              value={this.state.fields.freightPickup.consignor.handlerId.value}
              errorText={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
              onChange={this.handleConsignorChange}
              disabled={this.state.isConsignorDisabled}
              selectedItem={this.state.selectedConsignor}
            />
          </div>}
          {showPickupDetails && <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              items={this.state.consignorSites}
              id="freightPickup.consignor.site.locationId"
              label="Pickup Storage"
              value={this.state.fields.freightPickup.consignor.site.locationId.value}
              errorText={this.state.fields.freightPickup.consignor.site.locationId.errors[0]}
              style={{float: 'right'}}
              onChange={this.handlePickupSiteChange}
              selectedItem={this.state.selectedConsignorSite}
              dontAutoselectSingleItem
            />
          </div>}
          {showDeliveryDetails && <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              items={this.state.consignees}
              id="freightDelivery.consignee.handlerId"
              label="Delivery Site"
              value={this.state.fields.freightDelivery.consignee.handlerId.value}
              errorText={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
              onChange={this.handleConsigneeChange}
              disabled={this.state.isConsigneeDisabled}
              selectedItem={this.state.selectedConsignee}
            />
          </div>}
          {showDeliveryDetails && <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              items={this.state.consigneeSites}
              id="freightDelivery.consignee.site.locationId"
              label="Delivery Storage"
              value={this.state.fields.freightDelivery.consignee.site.locationId.value}
              errorText={this.state.fields.freightDelivery.consignee.site.locationId.errors[0]}
              onChange={this.handleDeliverySiteChange}
              selectedItem={this.state.selectedConsigneeSite}
              dontAutoselectSingleItem
            />
          </div>}
          <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              items={this.props.providers}
              id="providerId"
              label="Freight Provider"
              value={this.state.fields.providerId.value}
              errorText={this.state.fields.providerId.errors[0]}
              onChange={this.handleProviderChange}
              onBlur={this.handleSelectBlur}
            />
          </div>
          <div className="col-sm-12 form-wrap-70">
            <CommonSelect
              items={this.state.providerEmployees}
              id="assignToId"
              value={this.state.fields.assignToId.value}
              floatingLabelText="Freight Provider Contact"
              errorText={this.state.fields.assignToId.errors[0]}
              onChange={this.handleSelectFieldChange}
              onBlur={this.handleSelectBlur}
            />
          </div>
          {!this.state.fields.isSelf.value && <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              id="invoicing"
              onChange={this.handleSelectFieldChange}
              label="Invoicing"
              errorText={ this.state.fields.invoicing.errors[0] }
              items={filter(INVOICINGS, invoicing => {return invoicing.id !== 'Freight Provider to Invoice Broker';})}
              style={{float: 'left'}}
              value={this.state.fields.invoicing.value}
              onBlur={this.handleSelectBlur}
            />
          </div>}
          {!this.state.fields.isSelf.value && <div className="col-sm-12 form-wrap-70">
            <CommonAutoSelect
              id="paymentTermId"
              onChange={this.handleSelectFieldChange}
              label="Payment Term"
              dataSourceConfig={{text: 'name', value: 'id'}}
              value={this.state.fields.paymentTermId.value}
              errorText={this.state.fields.paymentTermId.errors[0]}
              items={this.props.paymentTerms}
              onBlur={this.handleSelectBlur}
            />
          </div>}
          <div className="col-sm-12 form-wrap-70">
            <CommonTextField
              id="rateFreightOut"
              label='Freight Rate'
              onKeyDown={(event)=>positiveDecimalFilter(event, 2, 10000)}
              helperText={ this.state.fields.rateFreightOut.errors[0] }
              value={this.state.fields.rateFreightOut.value}
              onChange={this.handleFreightRateChange}
              onBlur={this.handleBlur}
              InputProps={{
                startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>$</InputAdornment>
              }}
            />
          </div>
          {!this.state.fields.isSelf.value && <div className="col-sm-4 form-wrap-70">
            <CommonAutoSelect
              items={this.props.generalConditionsAll}
              style={{float: 'left'}}
              id="generalConditionsSelector"
              value={this.state.generalConditionsSelector}
              label="General"
              onChange={this.handleConditionSelector}
              dontAutoselectSingleItem
            />
            <CommonTextField
              id="generalConditionViewer"
              value={this.state.selectedGeneralConditionText}
              multiline={true}
              rows={2}
              disabled={true}
              rowsMax={2}
            />
          </div>}
          {!this.state.fields.isSelf.value && <div className="col-sm-1 form-wrap-70">
            <IconButton
              disabled={!isNumber(this.state.generalConditionsSelector)}
              onClick={ this.appendGeneralConditions }
              size="large">
              <ArrowForward color={ PRIMARY_COLOR_GREEN } />
            </IconButton>
          </div>}
          {!this.state.fields.isSelf.value && <div className="col-sm-7 form-wrap-70">
            <CommonTextField
              id="generalConditions"
              className="text-area"
              label=""
              value={this.state.fields.generalConditions.value}
              multiline={true}
              onChange={this.handleConditionTextChange}
              rows={6}
              rowsMax={6}
              onBlur={this.handleBlur}
              placeholder="Conditions (Optional)"
            />
          </div>}
          {!this.state.fields.isSelf.value && <div className="col-sm-4 form-wrap-70">
            <CommonAutoSelect
              items={this.props.specialConditionsAll}
              id="specialConditionsSelector"
              value={this.state.specialConditionsSelector}
              label="Special"
              onChange={this.handleConditionSelector}
              dontAutoselectSingleItem
            />
            <CommonTextField
              id="specialConditionViewer"
              value={this.state.selectedSpecialConditionText}
              multiline={true}
              rows={2}
              disabled={true}
              rowsMax={2}
              onBlur={this.handleBlur}
            />
          </div>}
          {!this.state.fields.isSelf.value && <div className="col-sm-1 form-wrap-70">
            <IconButton
              disabled={!isNumber(this.state.specialConditionsSelector)}
              onClick={ this.appendSpecialConditions }
              size="large">
              <ArrowForward color={ PRIMARY_COLOR_GREEN } />
            </IconButton>
          </div>}
          {!this.state.fields.isSelf.value && <div className="col-sm-7 form-wrap-70">
            <CommonTextField
              id="specialConditions"
              className="text-area"
              label=""
              value={this.state.fields.specialConditions.value}
              multiline={true}
              onChange={this.handleConditionTextChange}
              rows={6}
              rowsMax={6}
              onBlur={this.handleBlur}
              placeholder="Conditions (Optional)"
            />
          </div>}
        </div>
        <div className='col-sm-12 cardForm-action top15 padding-reset'>
          <CommonButton type="button" variant="outlined" label="Cancel" default onClick={this.props.handleCancel}/>
          <CommonButton primary={true} variant="contained" label="Save" type="submit" onClick={this.handleSubmit}/>
        </div>
      </div>
    );
  }
}

      const mapStateToProps = state => {
        var farmParties = [],companyParties = [];
        if (!isEmpty(state.companies.contracts.parties.farms)) {
          farmParties = map(state.companies.contracts.parties.farms, (farm) => {
            return merge(farm, {name: farm.displayName, rel: 'farms', entity: 'farm'});
          });
        }
        if (!isEmpty(state.companies.contracts.parties.companies)) {
          companyParties = map(state.companies.contracts.parties.companies, (company) => {
            return merge(company, {rel: 'companies', entity: 'company'});
          });
        }
        var parties = farmParties.concat(companyParties);
        const locations = state.master.bhcLocations.items;
        let bhcOperators = uniqBy(map(locations, (loc) => {
          return {
            id: loc.companyId,
            name: loc.company.businessName,
            isRegistered: loc.company.isRegistered,
            entity: 'company',
            typeId: loc.company.typeId,
            rel: 'companies',
            address: loc.address,
          };
        }), 'id');
        let consignmentParties = [];
        if (!isEmpty(bhcOperators)) {
          let partiesWithoutBHCOperators = filter(parties, (party) => {
            return party.typeId !== BHC_TYPE_ID;
          });
          consignmentParties = partiesWithoutBHCOperators.concat(bhcOperators);
        }
        const bhcSites = map(locations, function (item) {
          return {
            id: item.id,
            name: item.name,
            companyId: item.companyId,
            tracks: item.tracks,
            entity: 'companysite',
            address: item.address,
          };
        });
        return {
          paymentTerms: state.companies.freights.paymentTerms,
          providers: state.companies.companies.companyParties,
          providerEmployees: state.companySettings.employees.items,
          userCompanyId: state.main.user.user.companyId,
          currentUser: state.main.user,
          generalConditionsAll: state.companies.conditions.generalConditions,
          specialConditionsAll: state.companies.conditions.specialConditions,
          consignmentParties,
          operators: locations,
          userToken: state.main.user.token,
          bhcSites,
          isLoading: state.main.isLoading
        };
      };

      const mapDispatchToProps = dispatch => ({
        showLoader: () => dispatch(isLoading('acceptRejectFromOrderDetails')),
        stopLoader: () => dispatch(loaded()),
      });

      export default connect(mapStateToProps, mapDispatchToProps)(OrderProviderDetailsForm);

import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

import alertifyjs from 'alertifyjs';
import { applyValidatorsOn, required, selected } from '../../common/validators';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import CustomEmailDialog from '../common/CustomEmailDialog';
import get from 'lodash/get';
import set from 'lodash/set';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import find from 'lodash/find';
import some from 'lodash/some';
import isArray from 'lodash/isArray';
import mapValues from 'lodash/mapValues';
import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';
import {
  canAccessAny,
  generateIdentifier,
  getAutoSelectFocusField,
  getDaysToAdd,
  getLabelForEmployeeForm,
  getWeekDays,
  isTransactionParticipated,
  nextEndDayFrom,
  getCountryFormats,
  getCountryConfig, getCountryLabel, getCountryCurrency, formatPrice
} from '../../common/utils';
import APIService from '../../services/APIService';
import moment from 'moment';
import AddButton from '../common/AddButton';
import { COMMODITIES, NGR_MISSING_DETAILS_ERROR, PAYMENT_SCALES, PRE_DELIVERY, POST_DELIVERY, END_OF_DELIVERY, PRICE_POINTS } from '../../common/constants';
import SideDrawer from '../common/SideDrawer';
import InvoiceCustomItemForm from '../invoices/InvoiceCustomItemForm';
import InvoiceTable from './InvoiceTable';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import IconButton from '@mui/material/IconButton';
import isNumber from 'lodash/isNumber';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Tooltip from '@mui/material/Tooltip';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import TextField from '@mui/material/TextField/TextField';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Dialog from '@mui/material/Dialog/Dialog';
import InvoicePreview from './InvoicePreview';
import './invoice.scss';
import { fetchBanks, forceStopLoader, isLoading, setBreadcrumbs, setHeaderText } from '../../actions/main';
import { clickAddGeneralConditionButton, getGeneralConditions, receiveGeneralConditions } from '../../actions/companies/conditions';
import { getContractsResponse, getPaymentTerms, getSelectedContract, receiveContract, getTitleTransfers } from '../../actions/companies/contracts';
import { getDetailedContracts, isFreightContractsFetched } from '../../actions/companies/freights';
import queryString from 'query-string';
import { has, every, uniqBy, cloneDeep, merge, includes, reduce, isEmpty, remove, omit, map, filter} from 'lodash';
import CommonSelect from '../common/select/CommonSelect';
import { getZendeskURL } from '../../common/utils';
import AutoComplete from '../common/autocomplete/AutoComplete';
import Button from "@mui/material/Button/Button";
import UpdateCompanyNgr from "../../containers/UpdateCompanyNgr";
import {
  cancelEditCompanyNgr,
  clickEditCompanyNgr,
  receiveCompanyNgrs
} from "../../actions/companies/company-ngrs";
import { cancelEditNgr } from "../../actions/companies/ngrs";
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import CreateEmployee from "../../containers/CreateEmployee";
import CreateGeneralCondition from "../../containers/CreateGeneralCondition";
import {AddCircleIconWithTooltip} from "../common/icons/AddCircleIconWithTooltip";
import {canCreateEmployee} from "../../actions/api/employees";
import {clickAddEmployeeButton} from "../../actions/company-settings/employees";
import {canCreateNgr} from "../../actions/api/ngrs";
import { getCompanyCompaniesMinimal } from "../../actions/api/companies";
import Notes from "../common/Notes";
import Autocomplete from '@mui/material/Autocomplete';
import { apiURL } from '../../actions/main';

const DEFAULT_SIDE_DRAWERS_STATE = {
  openSideDrawer: false,
  ngrSideDrawerIsOpen: false,
  brokerSideDrawerIsOpen: false,
  billToSideDrawerIsOpen: false,
  invoiceConditionSideDrawerIsOpen: false,
};

class BrokerageInvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.invoicePartyFields = {
      companyId: {
        value: null,
        validators: [],
        errors: [],
      },
      contactId: {
        value: null,
        validators: [],
        errors: [],
      },
      ngrId: {
        value: null,
        validators: [],
        errors: [],
      },
      bankName: {
        value: null,
        id: '',
        validators: [required(), selected()],
        errors: [],
      },
      accountName: {
        value: null,
        validators: [required()],
        errors: [],
      },
      bsbNumber: {
        value: null,
        validators: [required()],
        errors: [],
      },
      accountNumber: {
        value: null,
        validators: [required()],
        errors: [],
      },
    };
    this.payerFields = cloneDeep(this.invoicePartyFields);
    this.payeeFields = cloneDeep(this.invoicePartyFields);
    this.setUpdatedNgrToBrokerNgrField = false;
    this.countryConfig = getCountryConfig();
    this.state = {
      countryFormats: getCountryFormats(),
      contractsCache: {},
      allSelectContracts: [],
      showCustomEmailDialog: false,
      isDefaultGeneralConditionApplied: false,
      brokerNgrs: [],
      billedToNgrs: [],
      billToParties: [],
      selectedBilledToParty: undefined,
      selectedBilledToContact: undefined,
      selectedBilledToNgr: undefined,
      entityForNgrUpdate: '',
      customItems: [],
      selectedItems: [],
      titleTransfers: [],
      freightMovements: [],
      selectedContracts: [],
      billedToEmployees: [],
      brokerEmployees: [],
      contracts: [],
      subscriptions: [],
      subscriptionItems: [],
      subTotal: undefined,
      gst: undefined,
      total: undefined,
      isConfirmDialogOpen: false,
      customItemId: 1,
      clearSelectedContracts: false,
      preview: false,
      generalConditionsSelector: '',
      selectedGeneralConditionText: '',
      displayBrokerBankForm: false,
      displayBilledToBankForm: false,
      fetchingBrokerNgrs: false,
      fetchingBilledToNgrs: false,
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      brokerSideDrawerIsOpen: false,
      billToSideDrawerIsOpen: false,
      invoiceConditionSideDrawerIsOpen: false,
      gstApplicable: this.countryConfig?.invoicing?.gst,
      gstRate: this.countryConfig?.invoicing?.gstRate,
      fields: {
        note: {
          description: '',
          attachments: [],
          companyId: this.props.user.companyId,
        },
        paymentDueDate: {
          value: '',
          validators: [required()],
          errors: [],
        },
        payableBy: {
          value: '',
          validators: [required()],
          errors: [],
        },
        identifier: {
          value: generateIdentifier('invoice'),
          validators: [required()],
          errors: [],
        },
        payeeNgrId: {
          value: '',
          validators: [],
          errors: [],
        },
        invoiceConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        brokerContact: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        brokerNgr: {
          value: '',
          validators: [required()],
          errors: [],
        },
        billedToContact: {
          value: '',
          validators: [required()],
          errors: [],
        },
        commodityContractIds: {
          value: [],
          validators: [],
          errors: [],
        },
        payee: cloneDeep(this.payeeFields),
        payer: cloneDeep(this.payerFields),
      },
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePayableByChange = this.handlePayableByChange.bind(this);
    this.setBilledToParties = this.setBilledToParties.bind(this);
    this.handlePaymentDueDateChange = this.handlePaymentDueDateChange.bind(this);
    this.handleBrokerContactChange = this.handleBrokerContactChange.bind(this);
    this.handleBilledToContactChange = this.handleBilledToContactChange.bind(this);
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.appendInvoiceConditions = this.appendInvoiceConditions.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.handleBrokerNgrChange = this.handleBrokerNgrChange.bind(this);
    this.handleBilledToNgrChange = this.handleBilledToNgrChange.bind(this);
    this.handleConfirmDialogOpen = this.handleConfirmDialogOpen.bind(this);
    this.handleConfirmDialogOk = this.handleConfirmDialogOk.bind(this);
    this.handleConfirmDialogCancel = this.handleConfirmDialogCancel.bind(this);
    this.handlePreviewClose = this.handlePreviewClose.bind(this);
    this.handlePreviewSubmit = this.handlePreviewSubmit.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.getSubscriptionList = this.getSubscriptionList.bind(this);
    this.getBrokerNgrs = this.getBrokerNgrs.bind(this);
    this.getBilledToNgrs = this.getBilledToNgrs.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.checkDueDate = this.checkDueDate.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handlePayeeBankChange = this.handlePayeeBankChange.bind(this);
    this.handlePayerBankChange = this.handlePayerBankChange.bind(this);
    this.handleAccountChangeForEntity = this.handleAccountChangeForEntity.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.openInvoiceConditionSideDrawer = this.openInvoiceConditionSideDrawer.bind(this);
    this.closeInvoiceConditionSideDrawer = this.closeInvoiceConditionSideDrawer.bind(this);
    this.openBrokerSideDrawer = this.openBrokerSideDrawer.bind(this);
    this.closeBrokerContactSideDrawer = this.closeBrokerContactSideDrawer.bind(this);
    this.openBillToContactSideDrawer = this.openBillToContactSideDrawer.bind(this);
    this.closeBillToContactSideDrawer = this.closeBillToContactSideDrawer.bind(this);
    this.addContract = this.addContract.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.applyBrokeragePreDelivery = this.applyBrokeragePreDelivery.bind(this);
    this.applyBrokeragePostDelivery = this.applyBrokeragePostDelivery.bind(this);
    this.getBrokerageTonnageFromFreightMovements = this.getBrokerageTonnageFromFreightMovements.bind(this);
    this.getBrokerageTonnageFromTitleTransfers = this.getBrokerageTonnageFromTitleTransfers.bind(this);
    this.updateSelectedItem = this.updateSelectedItem.bind(this);
    this.openAddNgrSideDrawer = this.openAddNgrSideDrawer.bind(this);
    this.closeAddNgrSideDrawer = this.closeAddNgrSideDrawer.bind(this);
    this.getAddBillToPartyContactButton = this.getAddBillToPartyContactButton.bind(this);
    this.getBrokerCompanyDetails = this.getBrokerCompanyDetails.bind(this);
    this.setEmployeesAndContactForParty = this.setEmployeesAndContactForParty.bind(this);
    this.getCompanyEmployees = this.getCompanyEmployees.bind(this);
    this.getBilledToPartyCompanyDetails = this.getBilledToPartyCompanyDetails.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.commonOpenSideDrawer = this.commonOpenSideDrawer.bind(this);
    this.billToContactSideDrawer = this.billToContactSideDrawer.bind(this);
    this.closeCustomEmailDialog = this.closeCustomEmailDialog.bind(this);
    this.tonnageValue = this.tonnageValue.bind(this);
    this.fetchDetailsOnClose = this.fetchDetailsOnClose.bind(this);
    this.handleBilledToNgrCreation = this.handleBilledToNgrCreation.bind(this);

    this.fieldsOrder = [
      'payableBy',
      'paymentDueDate',
      'identifier',
      'billedToContact',
      'billedToNgr',
      'brokerContact',
      'brokerNgr',
      'payeeBankName',
      'payee.accountName',
      'payee.accountNumber',
      'payee.bsbNumber',
    ];

    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
  }

  componentWillUnmount() {
    this.props.dispatch(getContractsResponse([]));
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(isFreightContractsFetched(false));
    this.closeNgrSideDrawer();
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(isLoading('nonExistingComponent'));
    const breadcrumbs = [{ text: 'Invoices', route: '/invoices' }, { text: 'New' }];
    dispatch(setBreadcrumbs(breadcrumbs));
    dispatch(setHeaderText('Invoices'));
    if (get(this.props, 'user.company')) {
      this.getCompanyEmployees(get(this.props, 'user.company'), 'broker', get(this.props, 'user.id'));
    }
    const queryParams = queryString.parse(this.props.location.search);
    const contractId = parseInt(get(queryParams, 'commodityContractId'));

    if (includes(this.props.location.search, 'commodityContractId=')) {
      this.fetchEntities(contractId);
      this.getCompaniesMinimal();
    }
    else {
      this.getCompaniesMinimal(true);
    }
  }

  fetchEntities(contractId){
    const { dispatch } = this.props;
    let movementsQueryString = contractId ? '&' : '?';
    movementsQueryString += 'status=manual_contract_complete_balanced,completed'
    dispatch(getSelectedContract(contractId, receiveContract, false, true, null, true, true, ' '));
    dispatch(getTitleTransfers(contractId));
    dispatch(getDetailedContracts('', contractId, movementsQueryString, true));
  }

  getCompaniesMinimal(stopLoader){
    const { dispatch } = this.props;
    dispatch(getPaymentTerms());
    dispatch(getGeneralConditions('invoice'));
    dispatch(getCompanyCompaniesMinimal(this.props.user.companyId, { include_parent_company: true, user_properties: 'brokerages_for_user' }, null, stopLoader));
    dispatch(fetchBanks());

  }

  selectedContract() {
    return this.state.selectedContracts.length === 1 ? this.state.selectedContracts[0] : '';
  }

  componentDidUpdate(prevProps) {
    const newState = { ...this.state };
    if (isEmpty(this.state.brokerNgrs) && !this.state.fetchingBrokerNgrs) {
      newState.fetchingBrokerNgrs = true;
      this.getBrokerNgrs(this.props.user.companyId);
    }
    this.updateNgrChanges(prevProps, newState);
    if (this.props.contract) {
      if (isEmpty(this.state.billToParties)) {
        if (this.props.contract.brokeragesForUser.length === 1) {
          this.setBrokerageFields(this.props.contract.brokeragesForUser[0].type, this.props.contract, newState);
        } else if (this.props.contract.brokeragesForUser.length === 2) {
          let seller = find(this.props.parties, {id: this.props.contract.seller.companyId});
          let buyer = find(this.props.parties, {id: this.props.contract.buyer.companyId});
          if (this.props.contract.sellerBrokerageInvoiced) {
            this.addBuyerInBillToParties(buyer, newState);
          } else if (this.props.contract.buyerBrokerageInvoiced) {
            this.addSellerInBillToParties(seller, newState);
          } else {
            this.addSellerInBillToParties(seller, newState);
            this.addBuyerInBillToParties(buyer, newState);
          }
        }
        if (!isEmpty(newState.billToParties)) this.setState(newState);
      }
    } else if (!includes(this.props.location.search, 'commodityContractId=')) {
      if (isEmpty(this.state.billToParties)) {
        newState.billToParties = newState.billToParties.concat(this.props.parties);
      }
    }

    let subTotal = reduce(
      this.state.selectedItems.concat(this.state.contracts),
      function(sum, item) {
        return sum + parseFloat(item.subTotal);
      },
      0,
    );
    let gst = reduce(
      this.state.selectedItems.concat(this.state.contracts),
      function(sum, item) {
        return sum + (item.gst === 'N/A' ? 0 : parseFloat(item.gst));
      },
      0,
    );
    newState.subTotal = subTotal.toFixed(2);
    newState.gst = gst.toFixed(2);
    let totalFromItems = reduce(
      this.state.selectedItems.concat(this.state.contracts),
      function(sum, item) {
        return sum + parseFloat(item.total);
      },
      0,
    );
    newState.total = totalFromItems.toFixed(2);
    if (!isEqual(this.props.serverErrors, prevProps.serverErrors)) {
      forEach(this.props.serverErrors, (value, key) => {
        if (has(newState.fields, key)) {
          newState.fields[key].errors = value;
        }
      });
      if (!isEmpty(this.props.serverErrors)) {
        newState.preview = false;
      }
    }
    if (!isEqual(this.state, newState)) {
      this.setState(newState);
    }

    if (!isEmpty(this.props.generalConditionsAll) && !this.state.isDefaultGeneralConditionApplied) {
      newState.fields.invoiceConditions.value = map(filter(this.props.generalConditionsAll, { default: true, module: 'invoice' }), 'details').join('\n');
      newState.isDefaultGeneralConditionApplied = true;
    }

    if (this.props.isFreightContractsFetched && this.props.isFreightContractsFetched !== prevProps.isFreightContractsFetched) {
      this.props.dispatch(forceStopLoader());
    }
  }

  addSellerInBillToParties(seller, newState) {
    if (seller) {
      seller['displayName'] = get(this.props.contract, 'seller.company.displayName');
      newState.billToParties.push(seller);
    }
  }

  addBuyerInBillToParties(buyer, newState) {
    if (buyer) {
      buyer['displayName'] = get(this.props.contract, 'buyer.company.displayName');
      newState.billToParties.push(buyer);
    }
  }

  updateNgrChanges(prevProps, newState) {
    if (this.props.updatedNgr && prevProps.updatedNgr !== this.props.updatedNgr) {
      this.updateBrokerNgrs(newState, this.props.updatedNgr);
    }

    if (this.props.updatedBillToNgr && prevProps.updatedBillToNgr !== this.props.updatedBillToNgr) {
      this.updateBillToNgrs(newState, this.props.updatedBillToNgr);
    }
  }

  updateBrokerNgrs(newState) {
    if (this.props.updatedNgr.id === this.state.fields.brokerNgr.value.id && !this.setUpdatedNgrToBrokerNgrField) {
      this.setUpdatedNgrToBrokerNgrField = true;
      this.getBrokerNgrs(this.props.user.companyId);
      newState.fields.brokerNgr.value = this.props.updatedNgr;
      this.getBrokerNgrErrors(newState);
    }
  }

  updateBillToNgrs(newState, updatedNgr) {
    if (updatedNgr.id === get(this.state.selectedBilledToNgr, 'id') && !newState.fetchingBilledToNgrs) {
      newState.fetchingBilledToNgrs = true;
      this.getBilledToNgrs(this.state.selectedBilledToParty.id);
      newState.selectedBilledToNgr = updatedNgr;
    }
  }

  hasContracts(partyCompanyId) {
    const { contractsCache } = this.state;
    if(!partyCompanyId)
      return;

    return has(contractsCache, partyCompanyId);
  }

  async fetchContracts(partyCompanyId, callback) {
    if(!partyCompanyId)
      return;

    this.props.dispatch(isLoading('brokerageInvoiceContractsList'));
    await APIService.contracts()
                    .appendToUrl('brokerage-invoices/pending/minimal/')
      .get(this.props.token, {'original-unit': true}, {party_company_id: partyCompanyId})
                    .then(contracts => {
                      const newState = {...this.state};
                      newState.contractsCache[partyCompanyId] = contracts;
                      this.setState(newState, () => {
                        this.props.dispatch(forceStopLoader());
                        if(callback)
                          callback();
                      });
                    });
  }

  handlePayeeBankChange = () => value => {
    const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
    const newState = { ...this.state };
    newState.fields.payee.bankName.value = selectedBank.name;
    newState.fields.payee.bankName.id = selectedBank.id;
    this.setState(newState, () => this.setPartyFieldErrors('payee.bankName'));
  };

  handlePayerBankChange = () => value => {
    const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
    const newState = { ...this.state };
    newState.fields.payer.bankName.value = selectedBank.name;
    newState.fields.payer.bankName.id = selectedBank.id;
    this.setState(newState);
  };

  getSubscriptionList = billTo => {
    this.props.dispatch(isLoading());
    APIService.invoices()
              .brokerages(billTo.brokeragesForUser[0].id)
              .appendToUrl('periodic-items/')
              .get(this.props.token, {}, null)
              .then(subscriptions => {
                this.setState({ subscriptionItems: subscriptions });
                let updatedSubscriptions = this.getSubscriptions(billTo);
                this.setState({ subscriptions: updatedSubscriptions }, () => {});
                this.props.dispatch(forceStopLoader());
              });
  };

  handleConfirmDialogCancel() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false,
    });
  }

  handleConfirmDialogOk() {
    this.setState({isConfirmDialogOpen: false}, () => { window.location.hash = '/invoices'; });
  }

  handleConfirmDialogOpen() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: true,
    });
  }

  openNgrSideDrawer(selectedEntity, ngrs, ngrId) {
    const { dispatch } = this.props;
    this.commonOpenSideDrawer();
    this.setUpdatedNgrToBrokerNgrField = false;
    dispatch(receiveCompanyNgrs(ngrs));
    dispatch(clickEditCompanyNgr(ngrId));
    this.setState({
      entityForNgrUpdate: (selectedEntity.company || selectedEntity)
    });
  }

  closeNgrSideDrawer() {
    const { dispatch } = this.props;
    dispatch(cancelEditNgr());
    dispatch(cancelEditCompanyNgr());
  }

  openCustomEmailDialog() {
    this.setState({ showCustomEmailDialog: true });
  }

  closeCustomEmailDialog(data, justClose) {
    if(justClose)
      this.setState({showCustomEmailDialog: false});
    else if (this.state.showCustomEmailDialog) {
      const communicationData = {};
      if (data) {
        delete data.body;
        communicationData['communication'] = data;
      }
      this.setState({ showCustomEmailDialog: false }, () => {
        let contract = this.props.contract || this.selectedContract();
        let contractId = contract ? contract.id : '';
        this.props.generateInvoice(this.props.invoiceId, contractId, communicationData);
      });
    }
  }

  handlePreviewSubmit() {
    this.openCustomEmailDialog();
  }

  handlePreviewClick() {
    this.setState({
      ...this.state,
      preview: true,
    });
  }

  handlePreviewClose() {
    this.setState({
      ...this.state,
      preview: false,
    });
    this.props.deleteInvoice(this.props.invoiceId);
  }

  appendInvoiceConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.invoiceConditions.value)){
      newState.fields.invoiceConditions.value = newState.selectedGeneralConditionText
    }
    else {
      newState.fields.invoiceConditions.value = newState.fields.invoiceConditions.value + '\n' + newState.selectedGeneralConditionText;
    }
    this.setState(newState);
  }

  setBilledToParties(brokerage, contract, newState) {
    if (brokerage) {
      newState.fields.payableBy.value = brokerage.type;
      let selectedBilledToParty = find(this.props.parties,
                                       {id: contract[newState.fields.payableBy.value.toLowerCase()].company.id});
      if (selectedBilledToParty) {
        selectedBilledToParty['displayName'] = get(contract[newState.fields.payableBy.value.toLowerCase()], 'company.displayName');
        if (this.state.billToParties && isEmpty(this.state.billToParties)) {
          newState.billToParties.push(selectedBilledToParty);
        }
      }
    }
  }

  getCounterPartyNameForBillTo = (selectedParty, contract) => {
    const selectedPartyCompanyId = get(selectedParty, 'companyId');
    const buyerBusinessName = get(contract, 'buyer.company.businessName') || get(contract, 'buyer.companyName');
    const sellerBusinessname = get(contract, 'seller.company.businessName') || get(contract, 'seller.companyName');
    const defaultCounterPartyName = contract.counterPartyName ? (' | ' + contract.counterPartyName) : '';
    if (selectedPartyCompanyId && selectedPartyCompanyId === get(contract, 'buyer.companyId'))
      return sellerBusinessname ? (' | ' + sellerBusinessname) : defaultCounterPartyName;
    else if (selectedPartyCompanyId && selectedPartyCompanyId === get(contract, 'seller.companyId'))
      return buyerBusinessName ? (' | ' + buyerBusinessName) : defaultCounterPartyName;
    else
      return defaultCounterPartyName;
  };

  applyBrokeragePreDelivery(brokerage, contract, selectedParty, newState) {
    let description = '';
    let subTotal = 0;
    let commodityName = get(contract, 'commodity.displayName') || get(contract, 'commodityName');
    let gradeName = get(contract, 'grade.name') || get(contract, 'gradeName');
    const counterPartyName = this.getCounterPartyNameForBillTo(selectedParty, contract);
    if (brokerage.feeType === '% of Sale') {
      const totalSale = this.contractValue(contract, contract.inferredTonnage);
      subTotal = (brokerage.rate / 100) * totalSale;
      description =
        'Brokerage Fee (% of Sale Value) (' +
        contract.referenceNumber +
        counterPartyName +
        ' | ' +
        commodityName +
        ' | ' +
        gradeName +
        ' | ' +
        contract.season +
        ' | ' +
        contract.inferredTonnage +
        ' MT | Sale Value = ' +
        `${formatPrice(totalSale, 'N/A', get(contract, 'currency'))}` +
        ')';
    } else {
      subTotal = brokerage.feeType === 'Fee Per MT' ? brokerage.rate * contract.inferredTonnage : brokerage.totalFee;
      description =
        'Brokerage ' +
        brokerage.feeType +
        ' (' +
        contract.referenceNumber +
        counterPartyName +
        ' | ' +
        commodityName +
        ' | ' +
        gradeName +
        ' | ' +
        contract.season +
        ' | ' +
        contract.inferredTonnage +
        ' MT )';
    }

    description = description.replace(/MT/g, contract.inferredTonnageUnit);
    let contractItem = {
      description: description,
      tonnage: contract.inferredTonnage,
      currency: contract?.currency,
      rate: brokerage.rate,
      subTotal: parseFloat(subTotal.toFixed(2)),
      gst: this.state.gstApplicable ? parseFloat((subTotal * this.state.gstRate).toFixed(2)) : 0,
      total: parseFloat((subTotal * (this.state.gstApplicable ? (1 + this.state.gstRate) : 1)).toFixed(2)),
      itemType: 'contract',
      invoice: true,
      date: moment().format(this.state.countryFormats.date),
      itemId: contract.id,
      id: contract.id,
      unit: contract.inferredTonnageUnit,
    };
    newState.contracts.push(contractItem);
  }

  applyBrokeragePostDelivery(brokerage, contract, selectedParty, fms, tts, newState) {
    let tonnage = 0;
    let description = '';
    let subTotal = 0;
    let tonnagePriceArray = [];
    let commodityName = get(contract, 'commodity.displayName') || get(contract, 'commodityName');
    let gradeName = get(contract, 'grade.name') || get(contract, 'gradeName');
    const counterPartyName = this.getCounterPartyNameForBillTo(selectedParty, contract);
    if (brokerage.feeType === 'Flat Fee') {
      tonnage = contract.inferredTonnage;
    } else {
      tonnagePriceArray.push(...this.getBrokerageTonnageFromFreightMovements(fms, selectedParty, brokerage, contract, newState));
      tonnagePriceArray.push(...this.getBrokerageTonnageFromTitleTransfers(tts, selectedParty, brokerage, contract, newState));
      tonnage = tonnagePriceArray.reduce((acc, curr) => acc + curr.tonnage, 0);
    }
    if (tonnage !== 0) {
      if (brokerage.feeType === '% of Sale') {
        let saleValue = parseFloat(tonnagePriceArray.map(i => i.tonnage * i.price).reduce((acc, curr) => acc + curr));
        subTotal = (brokerage.rate / 100) * saleValue;
        description =
          'Brokerage Fee (% of Sale Value) (' +
          contract.referenceNumber +
          counterPartyName +
          ' | ' +
          commodityName +
          ' | ' +
          gradeName +
          ' | ' +
          contract.season +
          ' | ' +
          contract.inferredTonnage +
          ' MT | Sale Value = ' +
          `${formatPrice(saleValue.toFixed(2), 'N/A', get(contract, 'currency'))}` +
          ')';
      } else {
        subTotal = brokerage.feeType === 'Fee Per MT' ? brokerage.rate * tonnage : brokerage.totalFee;
        description =
          'Brokerage ' +
          brokerage.feeType +
          ' (' +
          contract.referenceNumber +
          counterPartyName +
          ' | ' +
          commodityName +
          ' | ' +
          gradeName +
          ' | ' +
          contract.season +
          ' | ' +
          contract.inferredTonnage +
          ' MT )';
      }

      description = description.replace(/MT/g, contract.inferredTonnageUnit);
      let gst = Math.round(subTotal * this.state.gstRate * 100) / 100;
      let contractItem = {
        description: description,
        tonnage: parseFloat(tonnage.toFixed(2)),
        currency: contract?.currency,
        rate: brokerage.rate,
        subTotal: parseFloat(subTotal.toFixed(2)),
        gst: gst,
        total: parseFloat((subTotal + gst).toFixed(2)),
        itemType: 'contract',
        invoice: true,
        id: contract.id,
        date: moment().format(this.state.countryFormats.date),
        itemId: contract.id,
      };
      newState.contracts.push(contractItem);
    }
  }

  getLDFromContract = (contract, consigneeHandlerId, consignorHandlerId=0) => {
    const consignees = get(this.props, 'contract.consigneesWithSites', get(contract, 'consigneesWithSites', [])) || [get(this.props, 'contract.consignees', get(contract, 'consignees'))];
    const consigneeHandler = find(consignees, { handlerId: consigneeHandlerId });
    const consignors = get(this.props, 'contract.consignorsWithSites', get(contract, 'consignorsWithSites', [])) || [get(this.props, 'contract.consignees', get(contract, 'consignors', ''))];
    const consignorHandler = find(consignors, { handlerId: consignorHandlerId });
    const consigneeLd = get(consigneeHandler, 'ld', undefined);
    const consignorLd = get(consignorHandler, 'ld', undefined);
    if (!isNumber(consigneeLd))
      return consignorLd;
    if (!isNumber(consignorLd))
      return consigneeLd;
    return consigneeLd + consignorLd;
  };

  getDefaultLD = (freight, tracks_parent) => {
    let ld;
    if (PRICE_POINTS.TRACK === get(this.props, 'contract.pricePointId')) {
      ld = get(freight, `${tracks_parent}.tracks.${this.props.contract.track}`, get(freight, `${tracks_parent}.ld`));
    }
    if (isNumber(ld)) ld = -1 * ld;
    return ld;
  };

  getBrokerageTonnageFromFreightMovements(freightMovements, selectedParty, brokerage, contract, newState) {
    let tonnagePriceArray = [];
    filter(
      freightMovements,
      fm => {
        return includes(['completed', 'manual_contract_complete_balanced'], fm.status);
      }
    ).forEach((fm) => {
      let fmInvoiced = find(fm.relatedInvoicedItems, (i) => { return i.invoice.payer.companyId === selectedParty.id;});
      if (!fmInvoiced) {
        let locationDifferential = this.getLDFromContract(contract, get(fm, 'freightDelivery.load.0.handler.id'), get(fm, 'freightPickup.load.0.handler.id'));
        if (!isNumber(locationDifferential)) locationDifferential = this.getDefaultLD(fm, 'freightDelivery.load.0.handler') || 0;
        let freightData = get(contract, 'inspection.name') === 'Origin' ? fm.freightPickup : fm.freightDelivery;
        let pricePerMT = parseFloat(this.priceForOthers(get(freightData, 'load.0.gradeId'), locationDifferential, contract)).toFixed(2);
        if (this.props.contract && this.props.contract.commodityId === COMMODITIES.CANOLA) {
          pricePerMT = this.priceForCanola(pricePerMT, get(freightData.load[0], 'specs.impu'), get(freightData.load[0], 'specs.coil'), contract);
          if (isNumber(pricePerMT)) pricePerMT = pricePerMT.toFixed(2);
        }

        newState.freightMovements.push({
          tonnage: fm.inferredTonnage,
          subTotal: 0,
          rate: parseFloat(pricePerMT),
          gst: 0,
          total: 0,
          itemType: 'freightcontract',
          itemId: fm.id,
          description: fm.description,
          contractId: contract.id,
        });
        tonnagePriceArray.push({ tonnage: fm.inferredTonnage, price: parseFloat(pricePerMT) });
      }
    });
    return tonnagePriceArray;
  }

  getBrokerageTonnageFromTitleTransfers(titleTransfers, selectedParty, brokerage, contract, newState) {
    let tonnagePriceArray = [];
    filter(titleTransfers, function(t) {return includes(['completed', 'invoiced'], t.status);}).forEach(tt => {
      let ttInvoiced = find(tt.relatedInvoicedItems, (i) => { return i.invoice.payer.companyId === selectedParty.id;});
      if (!ttInvoiced) {
        let locationDifferential = this.getLDFromContract(contract, get(tt, 'storage.farmId'));
        if (!isNumber(locationDifferential)) locationDifferential = this.getDefaultLD(tt, 'storage.farm') || 0;
        let pricePerMT = parseFloat(this.priceForOthers(tt.grade.id, locationDifferential, contract)).toFixed(2);
        if (contract && contract.commodityId === COMMODITIES.CANOLA) {
          pricePerMT = this.priceForCanola(pricePerMT, tt.impu, tt.coil, contract);
          if (isNumber(pricePerMT)) pricePerMT = pricePerMT.toFixed(2);
        }
        newState.titleTransfers.push({
          tonnage: tt.inferredTonnage,
          subTotal: 0,
          rate: parseFloat(pricePerMT),
          gst: 0,
          total: 0,
          itemType: 'titletransfer',
          itemId: tt.id,
          description: tt.description,
          contractId: contract.id,
        });
        tonnagePriceArray.push({ tonnage: tt.inferredTonnage, price: parseFloat(pricePerMT) });
      }
    });
    return tonnagePriceArray;
  }

  priceForCanola(basePrice, impu, coil, contract) {
    let paymentScale = contract.paymentScaleId;
    if (paymentScale === PAYMENT_SCALES.AOF_BASIC) {
      return this.calculatePrice(basePrice, impu, coil);
    }
    if (paymentScale === PAYMENT_SCALES.AOF_BASIC_CAP) {
      return coil >= 46 ? this.calculatePrice(basePrice, impu, 46) : this.calculatePrice(basePrice, impu, coil);
    }
    return basePrice;
  }

  calculatePrice(basePrice, impu, coil) {
    let postAdmixAdjustedPrice = (basePrice * (100 - impu)) / 100;
    return postAdmixAdjustedPrice * (1 + (coil - 42) * 0.015);
  }

  priceForOthers(gradeId, locationDifferential, contract) {
    let priceDiff = 0;
    if (contract && contract.spread) {
      let grade = find(contract.spread.details, { id: gradeId });
      if (grade) {
        priceDiff = grade.priceVariation === '+' ? grade.value : -1 * grade.value;
      }
    }
    priceDiff += locationDifferential;
    return contract ? contract.price + priceDiff : priceDiff;
  }

  contractValue(contract, tonnage) {
    let _val = parseFloat(tonnage) * parseFloat(contract.price);
    return isNaN(_val) ? null : parseFloat(_val).toFixed(2);
  }

  getBrokerageTypeForContract(commodityContract) {
    let brokerageType = '';
    if (this.state.selectedBilledToParty && (commodityContract.buyer.companyId === this.state.selectedBilledToParty.companyId ||
                                             commodityContract.buyer.companyId === this.state.selectedBilledToParty.id)) {
      brokerageType = 'Buyer';
    } else {
      brokerageType = 'Seller';
    }
    return brokerageType;
  }

  getSubscriptions = selectedItem => {
    let qty = 0;
    let period = '';
    let subscriptions = [];
    let billToBrokerages = selectedItem.brokeragesForUser;
    if (isEmpty(billToBrokerages)) {
      return subscriptions;
    }
    let brokerage = billToBrokerages[0];
    let brokerageFrequency = brokerage.frequency;
    const dateParams = this.getSubscriptionDateParams(brokerageFrequency, qty, period);
    qty = dateParams.qty;
    period = dateParams.period;
    let subscriptionDates = this.getSubscriptionDates(qty, period, brokerage);
    let finalDates = this.getApplicableSubscriptionDates(subscriptionDates, qty, period);
    let id = 1;
    forEach(finalDates, value => {
      let subscription = this.getSubscription(brokerageFrequency, value, qty, period, brokerage, id);
      subscriptions.push(subscription);
      id += 1;
    });
    return subscriptions;
  };

  getSubscription(brokerageFrequency, value, qty, period, brokerage, id) {
    let description =
      'Brokerage Fee charged ' +
      brokerageFrequency +
      ' for ' +
      moment(value, 'MM/DD/YYYY').format(this.state.countryFormats.dateDisplay) +
      ' to ' +
      moment(value, 'MM/DD/YYYY')
                                 .add(qty, period)
                                 .subtract(1, 'day')
                                 .format(this.state.countryFormats.dateDisplay);
    let subTotal = brokerage.totalFee;
    let gst = this.state.gstRate * subTotal;
    let date =
      brokerage.chargedAt === 'End of the Period'
      ? moment(value)
        .add(qty, period)
        .subtract(1, 'day')
      : moment(value);
    return {
      description: description,
      subTotal: parseFloat(subTotal.toFixed(2)),
      itemId: id,
      invoice: false,
      gst: gst,
      total: parseFloat((subTotal + gst).toFixed(2)),
      itemType: 'subscriptionitem',
      date: date.format(this.state.countryFormats.date),
    };
  }

  getApplicableSubscriptionDates(subscriptionDates, qty, period) {
    let finalDates = [];
    if (this.state.subscriptionItems) {
      subscriptionDates = mapValues(subscriptionDates, field => {
        return moment(field, 'MM/DD/YYYY').format('MM/DD/YYYY');
      });
      const invoicedDates = mapValues(this.state.subscriptionItems.subscriptionDates, field => {
        return moment(field, 'YYYY-MM-DD').format('MM/DD/YYYY');
      });
      forEach(subscriptionDates, date => {
        if (
          !(
            includes(invoicedDates, date) ||
            includes(
              invoicedDates,
              moment(
                moment(date)
                  .add(qty, period)
                  .subtract(1, 'day'),
              ).format('MM/DD/YYYY'),
            )
          )
        ) {
          finalDates.push(date);
        }
      });
    }
    return finalDates;
  }

  getSubscriptionDateParams(brokerageFrequency, qty, period) {
    if (brokerageFrequency === 'Weekly') {
      qty = 7;
      period = 'days';
    } else if (brokerageFrequency === 'Fortnightly') {
      qty = 15;
      period = 'days';
    } else if (brokerageFrequency === 'Monthly') {
      qty = 1;
      period = 'months';
    } else if (brokerageFrequency === 'Quarterly') {
      qty = 3;
      period = 'months';
    } else if (brokerageFrequency === 'Semi-Annually') {
      qty = 6;
      period = 'months';
    } else if (brokerageFrequency === 'Annually') {
      qty = 1;
      period = 'years';
    }
    return { qty, period };
  }

  getSubscriptionDates(qty, period, brokerage) {
    let chargedAt = brokerage.chargedAt;
    let startDate = moment(brokerage.subscriptionStartDate, 'YYYY-MM-DD').format('MM/DD/YYYY');
    let period_dates = [];
    if (moment(startDate).isSame(new Date(), 'day') && chargedAt === 'Start of the Period') {
      period_dates = [startDate];
    } else {
      let endDate = startDate;
      if (chargedAt === 'End of the Period') {
        endDate = this.getEndDate(startDate, qty, period);
      }
      if (moment(endDate).isBefore(new Date(), 'day')) {
        period_dates.push(startDate);
      }
      while (
        moment(endDate)
          .add(qty, period)
          .isBefore(new Date(), 'day')
      ) {
        startDate = moment(startDate)
          .add(qty, period)
          .format('MM/DD/YYYY');
        endDate = chargedAt === 'End of the Period' ? this.getEndDate(startDate, qty, period) : moment(startDate);
        period_dates.push(startDate);
      }
    }
    return period_dates;
  }

  getEndDate(startDate, qty, period) {
    return moment(startDate)
      .add(qty, period)
      .subtract(1, 'day');
  }

  getBrokerNgrs(partyId, newlyCreatedNgr) {
    const api = APIService.companies(partyId);
    api
      .ngrs()
      .get(this.props.token)
      .then(items => {
        const newState = { ...this.state };
        newState.brokerNgrs = items;
        if (newlyCreatedNgr) {
          newState.fields.brokerNgr.value = newlyCreatedNgr;
        }
        this.setState(newState);
      });
  }

  estimatedPaymentDueDate(format = 'MM/DD/YYYY') {
    const today = moment();
    let val = null;
    const selectedBilledToParty = this.state.selectedBilledToParty;
    if (selectedBilledToParty) {
      let paymentTermId = get(this.state.selectedBilledToParty, 'paymentTermId') || get(this.state.selectedBilledToParty, 'company.paymentTermId');
      const selectedPaymentTerm = find(this.props.paymentTerms, {id: paymentTermId});
      let startOfWeek = get(selectedBilledToParty, 'startOfWeek');
      let endOfWeek = get(selectedBilledToParty, 'endOfWeek');

      const periodType = get(selectedPaymentTerm, 'periodType');
      if (periodType == 'weeks') {
        const nextFridayFromDate = nextEndDayFrom(today, endOfWeek);
        val =
          Object.prototype.toString.call(nextFridayFromDate) == '[object Object]' &&
          nextFridayFromDate.add(selectedPaymentTerm.period, 'days').format(format);
      } else if (periodType == 'months') {
        val = today
          .endOf('month')
          .add(selectedPaymentTerm.period, 'days')
          .format(format);
      } else if (periodType == 'days') {
        if (selectedPaymentTerm.name.includes('Business Days')) {
          const startDay = today.weekday();
          const weekdays = getWeekDays(startOfWeek, endOfWeek);
          if (startDay || startDay == 0) {
            const daysToAdd = getDaysToAdd(startDay, selectedPaymentTerm.period, weekdays);
            val = today.add(daysToAdd, 'days').format(format);
          }
        } else {
          val = today.add(selectedPaymentTerm.period, 'days').format(format);
        }
      } else {
        val = selectedPaymentTerm.name;
      }
      return val === 'Invalid date' ? moment().format(format) : val;
    } else {
      return null;
    }
  }

  handleAddItem(item) {
    const newState = { ...this.state };
    if (item.modifier && item.modifier === 'minus') {
      item.subTotal = -1 * item.subTotal;
      if (item.gst) {
        item.gst = -1 * item.gst;
        item.total = -1 * item.total;
      }
    }
    if (item.gst === '') {
      item.gst = 'N/A';
      item.total = item.subTotal;
    }
    item.itemType = 'customitem';
    item.itemId = newState.customItemId;
    item.id = newState.customItemId;
    item.invoice = true;
    item.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
    newState.customItems.push(item);
    newState.selectedItems.push(item);
    newState.openSideDrawer = false;
    newState.customItemId += 1;
    this.setState(newState);
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setAllFieldsErrors(() => {
      let isPayeeValid = true;
      const isFormInvalid = some(this.state.fields, field => {
        return field.errors.length > 0;
      });
      if (this.state.displayBrokerBankForm) {
        isPayeeValid = every(this.state.fields.payee, key => {
          return isEmpty(get(key, 'errors'));
        });
      }
      if (this.state.selectedItems.length === 0 && this.state.contracts.length === 0) {
        alertifyjs.error('Please select at least one item to be invoiced');
        return;
      }
      if (this.state.contracts.length > 0 && Array.from(new Set(map(this.state.contracts, contract => contract?.currency))).length > 1) {
        alertifyjs.alert(
          "Warning",
          "Invoice has amounts in multiple currencies, please ensure all the amounts are in same currency")
        return;
      }
      if (!isFormInvalid && isPayeeValid) {
        const data = mapValues(this.state.fields, field => {
          return field.value;
        });
        this.props.dispatch(isLoading('invoiceDetail'));
        let submittableData = this.getSubmitData(data);
        if (isArray(get(submittableData, 'invoiceItems'))) {
          submittableData['invoiceItems'].forEach(item => delete item.unit);
        }
        submittableData.note = omit(this.state.fields.note, ['errors']);
        this.props.submit(submittableData);
        this.handlePreviewClick();
      }
    });
    this.focusOnFirstErrorField();
  }

  focusOnFirstErrorField() {
    const autoCompleteFields = ['payableBy', 'billedToContact', 'billedToNgr', 'brokerContact'];
    const nestedFields = ['payee.accountName', 'payee.accountNumber', 'payee.bsbNumber', 'payee.accountNumber'];

    for (let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];

      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (this.fieldsOrder[i] === 'payee.bankName' && this.state.fields.payee.bankName.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
        if (
          (this.fieldsOrder[i] === 'payee.accountName' && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.accountNumber' && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.bsbNumber' && this.state.fields.payee.accountName.errors.length > 0)
        ) {
          formField.current.focus();
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (formField.current.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField.current.focus();
          break;
        }
      }
    }
  }

  getSubmitData(data) {
    data.raisedForType = null;
    data.raisedForId = null;
    if (!isEmpty(this.state.selectedBilledToParty.brokeragesForUser)) {
      data.raisedForId = this.state.selectedBilledToParty.brokeragesForUser[0].id;
      data.raisedForType = 'brokerage';
    }
    this.setPayerData(data);
    this.setPayeeData(data);

    data.invoiceItems = cloneDeep(this.state.contracts)
      .concat(cloneDeep(this.state.selectedItems))
      .concat(
        cloneDeep(
          map(uniqBy(this.state.titleTransfers, 'itemId'), t => {
            return omit(t, ['contractId']);
          }),
        ),
      )
      .concat(
        cloneDeep(
          map(uniqBy(this.state.freightMovements, 'itemId'), f => {
            return omit(f, ['contractId']);
          }),
        ),
      );
    data.invoiceItems.forEach(item => {
      if (item.itemType !== 'customitem' && item.itemType !== 'subscriptionitem' && item.itemType !== 'contract') {
        delete item['description'];
        delete item['date'];
      }
      if (item.itemType === 'customitem' || item.itemType === 'subscriptionitem') {
        delete item['itemId'];
        delete item['id'];
        if (item['gst'] === 'N/A') {
          item['gst'] = 0;
        }
      }
      if (item.itemType === 'contract') {
        delete item['id'];
        delete item['date'];
        delete item['currency'];
      }
      delete item['invoice'];
    });
    data.subTotal = this.state.subTotal;
    data.gst = this.state.gst;
    data.total = this.state.total;
    data.type = 'Brokerage';
    data.tonnage = reduce(
      this.state.contracts,
      function(sum, item) {
        return sum + parseFloat(item.tonnage);
      },
      0,
    );
    delete data.payableBy;
    delete data.brokerContact;
    delete data.commodityContractIds;
    delete data.billedToContact;
    delete data.brokerNgr;
    delete data.payeeNgrId;
    return data;
  }

  setPayeeData(data) {
    data['payee'] = {};
    data['payee']['companyId'] = this.props.user.companyId;
    data['payee']['contactId'] = get(data, 'brokerContact');
    data['payee']['ngrId'] = get(this.state.fields.brokerNgr.value, 'id');
    this.setEntityBankDetails(data, 'payee');
  }

  setEntityBankDetails(data, key) {
    data[key]['bankAccount'] = {};
    data[key]['bankAccount']['bank_id'] = get(find(this.props.banks, {name: this.state.fields[key].bankName.value}), 'id', '');
    data[key]['bankAccount']['accountName'] = this.state.fields[key].accountName.value;
    data[key]['bankAccount']['bsbNumber'] = this.state.fields[key].bsbNumber.value;
    data[key]['bankAccount']['accountNumber'] = this.state.fields[key].accountNumber.value;
  }

  setPayerData(data) {
    data['payer'] = {};
    data['payer']['companyId'] = this.state.selectedBilledToParty.id;
    data['payer']['contactId'] = this.state.fields.billedToContact.value ? this.state.fields.billedToContact.value.id :
                                 this.state.selectedBilledToContact.contactId;
    data['payer']['ngrId'] = get(this.state.selectedBilledToNgr, 'id');
    this.setEntityBankDetails(data, 'payer');
  }

  async handlePayableByChange(value, targetId, chosenItem) {
    const isSameAsSelected = get(this.state.selectedBilledToParty, 'id') === get(chosenItem, 'id')
    if (!this.state.selectedBilledToParty && !isSameAsSelected) {
      if(chosenItem && !this.hasContracts(chosenItem.id)) {
        await this.fetchContracts(chosenItem.id);
      }
      this.populateFields(chosenItem, value);
    } else if (!isSameAsSelected) {
      alertifyjs
        .confirm(
          'Warning',
          'Already filled values in the Invoice will be reset. Do you wish to continue?',
          () => {
            this.addContract(null, null, true);
            if(chosenItem && !this.hasContracts(chosenItem.id)) {
              this.fetchContracts(chosenItem.id, () => {
                this.populateFields(chosenItem, value, targetId);
              });
            } else {
              this.populateFields(chosenItem, value, targetId);
            }
          },
          () => {},
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    }

  }

  populateFields(chosenItem, value) {
    const newState = { ...this.state };
    // adding brokerages for user from props to handle edge case because of which ci test was failing
    if (chosenItem && isEmpty(chosenItem.brokeragesForUser)) {
      chosenItem.brokeragesForUser = get(find(this.props.companyParties, {id: chosenItem.id}), 'brokeragesForUser', []);
    }
    if (chosenItem && !isEmpty(chosenItem.brokeragesForUser)) {
      this.getSubscriptionList(chosenItem);
    } else {
      newState.subscriptions = [];
      newState.subscriptionItems = [];
    }
    let brokerageType = 'Buyer';
    newState.contracts = [];
    newState.selectedBilledToParty = chosenItem;
    let contract = this.props.contract;
    if (contract) {
      if (contract.buyer.companyId === value) {
        newState.selectedBilledToContact = contract.buyer.contact;
        brokerageType = 'Buyer';
      } else {
        newState.selectedBilledToContact = contract.seller.contact;
        brokerageType = 'Seller';
      }
      let brokerage = find(contract.brokeragesForUser, { type: brokerageType });
      if (includes([PRE_DELIVERY, END_OF_DELIVERY], get(brokerage, 'chargedOn'))) {
        this.applyBrokeragePreDelivery(brokerage, contract, chosenItem, newState);
      } else if (brokerage && brokerage.chargedOn === POST_DELIVERY) {
        this.applyBrokeragePostDelivery(brokerage, contract, chosenItem, this.props.freightMovements, this.props.titleTransfers, newState);
      }
    } else {
      newState.selectedContracts = [];
      newState.selectedItems = [];
      newState.customItems = [];
      newState.fields.commodityContractIds.value = [];
      newState.fields.paymentDueDate.value = '';
      newState.clearSelectedContracts = true;
      this.setFieldValue('commodityContractIds', []);
    }
    newState.fields.payableBy.value = brokerageType;
    newState.fields.paymentDueDate.errors = [];
    if (chosenItem) {
      this.getCompanyEmployees(chosenItem, 'billedTo');
      newState.selectedBilledToNgr = '';
      newState.fetchingBilledToNgrs = true;
      this.getBilledToNgrs(chosenItem.id);
    } else {
      newState.selectedBilledToContact = '';
      newState.selectedBilledToNgr = '';
      newState.billedToEmployees = [];
      newState.billedToNgrs = [];
      newState.selectedBilledToParty = '';
    }
    newState.displayBilledToBankForm = false;
    newState.ngrSideDrawerIsOpen = false;
    this.setState(newState, () => {
      this.setFieldErrors('payableBy');
      const newState = { ...this.state };
      newState.fields.paymentDueDate.value = this.estimatedPaymentDueDate('YYYY-MM-DD');
      this.setState(newState);
    });
  }

  getCompanyEmployees(party, type='', contactId='', representingCompanyId) {
    const queryParams = representingCompanyId ? {representing_company_id: representingCompanyId} : {};
    type = this.getPartyType(type, party);
    APIService.contracts().companies(party.id).contacts().get(this.props.token, {}, queryParams)
              .then(employees => {
                this.setEmployeesAndContactForParty(type, employees, contactId);
              });
  }

  setEmployeesAndContactForParty(type, employees, contactId) {
    if (type) {
      this.setState({ [type + 'Employees']: employees }, () => {
        setTimeout(() => {
          this.updateSelectedContactForParty(contactId, type);
        }, 500);
      });
    }
  }

  getPartyType(type, party) {
    if (!type) {
      let selectedBilledToParty = get(this.state.selectedBilledToParty, 'company') || this.state.selectedBilledToParty;
      type = isEqual(party, selectedBilledToParty) ? 'billedTo' : 'broker';
    }
    return type;
  }

  updateSelectedContactForParty(contactId, type) {
    if (contactId) {
      if (type === 'billedTo') {
        this.handleBilledToContactChange(contactId);
      } else if (type === 'broker') {
        this.handleBrokerContactChange(contactId);
      }
    }
  }

  getBilledToNgrs(partyId, newlyCreatedNgr) {
    APIService.companies(partyId).ngrs().appendToUrl('minimal/').get(this.props.token)
              .then(items => {
                const newState = { ...this.state };
                newState.billedToNgrs = items;
                newState.fetchingBilledToNgrs = false;
                if (newlyCreatedNgr) {
                  newState.selectedBilledToNgr = newlyCreatedNgr;
                }
                this.setState(newState);
              });
  }

  setBrokerageFields(brokerageType, contract, newState) {
    let brokerage = find(contract.brokeragesForUser, { type: brokerageType });
    this.setBilledToParties(brokerage, contract, newState);
  }

  async getCommodityContract(value) {
    this.props.dispatch(isLoading());
    var params = new URLSearchParams();
    value.forEach(element => {
      params.append("contract_ids", element.id);
    });
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${this.props.token}`,
      },
    };

    var url = `${apiURL}/contracts/brokerageinvoice/?${params}`;

    await axios
      .get(url, config)
      .then(response => {
        this.setState({ selectedContracts: response.data });
      });
  }

  addContract(event, value, removeValue) {
    let removedContracts = [];
    removedContracts = differenceBy(this.state.allSelectContracts, value, 'id');
    if (removedContracts.length > 0 || (removeValue && removedContracts.length > 0)) {
      this.handleDelete(removedContracts);
    }
    else this.setState({ allSelectContracts: value });

  }

  async fetchDetailsOnClose() {
    const { dispatch } = this.props;
    const newState = { ...this.state };
    let addedContracts = [];
    let newContracts = [];

    addedContracts = differenceBy(this.state.allSelectContracts, this.state.contracts, 'id');
    if (addedContracts.length > 0) {
      await this.getCommodityContract(this.state.allSelectContracts);
      newContracts = intersectionBy(this.state.selectedContracts, addedContracts, 'id');
      for (const value of newContracts){
        let brokerageType = this.getBrokerageTypeForContract(value);
        let brokerage = find(value.brokeragesForUser, { type: brokerageType });
        if (includes([PRE_DELIVERY, END_OF_DELIVERY], get(brokerage, 'chargedOn'))) {
          this.applyBrokeragePreDelivery(brokerage, value, this.state.selectedBilledToParty, newState);
          this.setState(newState);
        } else if (brokerage && brokerage.chargedOn === POST_DELIVERY) {
          let selectedParty = this.state.selectedBilledToParty;
          if (selectedParty) {
            dispatch(isLoading('invoiceFormFromContract'));
            const contractHome = APIService.contracts().appendToUrl(
              `title-transfers/?contract_id=${value.id}&invoicing`,
            );
            await contractHome.get(this.props.token).then(async tts => {
              const fmUrl = APIService.freights()
                                      .contracts()
                                      .appendToUrl('?commodity_contract_id=' + value.id);
              await fmUrl.get(this.props.token).then(fms => {
                this.applyBrokeragePostDelivery(brokerage, value, selectedParty, fms, tts, newState);
              });
              this.setState(newState);
            });
          }
        }
      };
      this.props.dispatch(forceStopLoader('b'));
    }
  }

  handleDelete(contracts) {
    const newState = { ...this.state };
    contracts.forEach(contract => {
      remove(newState.selectedContracts, c => {
        return c.id === contract.id;
      });
      remove(newState.contracts, c => {
        return c.itemId === contract.id;
      });
      remove(newState.titleTransfers, t => {
        return t.contractId === contract.id;
      });
      remove(newState.freightMovements, f => {
        return f.contractId === contract.id;
      });
      remove(newState.allSelectContracts, c => {
        return c.id === contract.id;
      });
    });
    this.setState(newState);
  };

  clearContractRelatedFields(newState) {
    newState.selectedBilledToContact = '';
    newState.selectedContracts = [];
    newState.contracts = [];
    newState.subscriptions = [];
  }

  handleBrokerContactChange(value, id = 'brokerContact') {
    this.setFieldValue(id, value);
  }

  handleBilledToContactChange(value, id = 'billedToContact') {
    this.setFieldValue(id, find(this.state.billedToEmployees, { id: value }));
  }

  handleBrokerNgrChange(id) {
    const newState = { ...this.state };
    this.closeNgrSideDrawer();
    newState.fields.brokerNgr.value = find(newState.brokerNgrs, { id: id });
    if (id) {
      newState.fields.brokerNgr.validators = [required()];
      newState.displayBrokerBankForm = false;
    } else {
      newState.fields.brokerNgr.validators = [];
      newState.displayBrokerBankForm = true;
    }
    this.setState(newState, () => this.setBrokerNgrErrors());
  }

  setBrokerNgrErrors = () => {
    const newState = { ...this.state };
    this.getBrokerNgrErrors(newState);
    this.setState(newState);
  };

  getBrokerNgrErrors(newState) {
    newState.fields['brokerNgr'].errors = [];
    if (newState.fields.brokerNgr.value) {
      if (!isEmpty(newState.fields.brokerNgr.value.bankAccounts)) {
        newState.fields.brokerNgr.value.bankAccounts.forEach(account => {
          if (!(account.name && account.accountName && account.accountNumber && account.bsbNumber)) {
            newState.fields['brokerNgr'].errors = [NGR_MISSING_DETAILS_ERROR];
          }
        });
      } else {
        newState.fields['brokerNgr'].errors = [NGR_MISSING_DETAILS_ERROR];
      }
    }
  }

  handleBilledToNgrChange(id) {
    this.closeNgrSideDrawer();
    if (id) {
      const newState = { ...this.state };
      newState.selectedBilledToNgr = find(this.state.billedToNgrs, { id: id });
      newState.displayBilledToBankForm = false;
      this.setState(newState);
    } else {
      this.setState({
        ...this.state,
        selectedBilledToNgr: '',
        displayBilledToBankForm: true,
      });
    }
  }

  handleAccountChangeForEntity(event, key) {
    const newState = { ...this.state };
    const id = event.target.id;
    const value = event.target.value;
    newState.fields[key][id].value = value
    if(id == 'bsbNumber' && value && isEqual(value.length, get(this.countryConfig, 'bank.bsbLength'))) {
      let bsbBank = find(this.props.banks, {bsbNumber: newState.fields[key][id].value});
      if(bsbBank) {
        newState.fields[key]['bankName'].id = bsbBank.id;
        newState.fields[key]['bankName'].value = bsbBank.name;
        newState.fields[key]['bankName'].errors = [];
      }
    }
    this.setState(newState, () => {
      if (key === 'payee') {
        this.setPartyFieldErrors(`${key}.${id}`);
      }
    });
  }

  handlePaymentDueDateChange(value, id) {
    this.setFieldValue(id, value);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  setPartyFieldErrors = id => {
    const newState = { ...this.state };
    let errors = [];
    const validators = get(newState, `fields.${id}.validators`);
    const value = get(newState, `fields.${id}.value`);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    set(newState, `fields.${id}.errors`, errors);
    this.setState(newState);
  };

  onPartyFieldBlur = event => {
    this.setPartyFieldErrors(event.target.id);
  };

  setFieldValue(key, value) {
    const newState = { ...this.state };
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    let value = '';
    let validators = [];
    if (this.state.fields[key]) {
      value = this.state.fields[key].value;
      validators = this.state.fields[key].validators;
    }
    if (validators) {
      validators.forEach(validator => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key),
        },
      },
    }));
  }

  openBrokerSideDrawer() {
    this.props.handleAddEmployeeButtonClick();
    this.commonOpenSideDrawer('brokerSideDrawerIsOpen');
  }

  commonOpenSideDrawer(type) {
    if (type !== 'ngrSideDrawerIsOpen') {
      this.closeNgrSideDrawer();
    }
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer);
  }

  alertPermissionError() {
    alertifyjs.alert(
      'Permission Denied',
      `<div className=""><p>You do not have permission to create employee for the party because:</p><ul><li>The selected party is registered on the system.</li></ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
      () => {},
    );
  }

  closeBrokerContactSideDrawer() {
    this.setState({ brokerSideDrawerIsOpen: false });
  }

  openBillToContactSideDrawer(party){
    const selectedParty = get(party, 'company', party);
    const companyId = get(selectedParty, 'id');
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.commonOpenSideDrawer('billToSideDrawerIsOpen');
      }));
    }
  }

  closeBillToContactSideDrawer() {
    this.setState({ billToSideDrawerIsOpen: false });
  }

  openInvoiceConditionSideDrawer() {
    this.props.dispatch(clickAddGeneralConditionButton());
    this.commonOpenSideDrawer('invoiceConditionSideDrawerIsOpen');
  }

  closeInvoiceConditionSideDrawer() {
    this.setState({ invoiceConditionSideDrawerIsOpen: false });
  }

  setDefaultGeneralCondition(condition) {
    this.closeInvoiceConditionSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = 'selectedGeneralConditionText';
    const newState = { ...this.state };

    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }

    this.setState(newState);
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    if (this.state.displayBrokerBankForm) {
      applyValidatorsOn(newState.fields.payee);
    }
    this.setState(newState, callback);
    if (isEmpty(newState.fields['brokerNgr'].errors)) {
      this.setBrokerNgrErrors();
    }
  }

  openSideDraw() {
    this.commonOpenSideDrawer('openSideDrawer');
  }

  closeSideDraw() {
    const newState = { ...this.state };
    newState.openSideDrawer = false;
    this.setState(newState);
  }

  handleIdentifierChange(event) {
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    this.setFieldValue(event.target.id, value);
  }

  handleItemClick(item, checked) {
    var selectedItems = cloneDeep(this.state.selectedItems);
    var customItems = cloneDeep(this.state.customItems);
    this.updateSelectedItem(customItems, item, checked, 'customitem');
    if (checked) {
      selectedItems.push(item);
    } else {
      remove(selectedItems, function(selectedItem) {
        return selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType;
      });
    }
    this.setState({
      selectedItems: selectedItems,
      customItems: customItems,
    });
  }

  updateSelectedItem(items, itemToBeUpdated, selected, itemType) {
    let item = items.find(i => i.itemId === itemToBeUpdated.itemId && itemToBeUpdated.itemType === itemType);
    if (item) item.invoice = selected;
  }

  openAddNgrSideDrawer(ngrEntity, type) {
    ngrEntity.type = type;
    const companyId = get(ngrEntity, 'id');
    if(companyId){
      const { dispatch } = this.props;
      const selectedEntity = find(this.props.companyParties, {id: companyId});
      dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
        this.props.handleAddCompanyNgrButtonClick();
        this.props.handleAddNgrButtonClick();
        this.setState({ngrEntity: ngrEntity}, () => this.commonOpenSideDrawer('ngrSideDrawerIsOpen'));
      }));
    }
  }

  closeAddNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }

  checkDueDate() {
    const newState = { ...this.state };
    const paymentDueDate = this.state.fields.paymentDueDate.value;
    const estimatedDate = this.estimatedPaymentDueDate();
    if (moment(estimatedDate).isAfter(moment(paymentDueDate))) {
      newState.fields.paymentDueDate.errors = [
        'Warning: Minimum Payment Due Date as per payment terms of the Bill To party is ' + moment(estimatedDate).format(this.state.countryFormats.date),
      ];
      this.setState(newState);
    }
  }

  getAddBillToPartyContactButton() {
    const party = get(this.state.selectedBilledToParty, 'company') || this.state.selectedBilledToParty;
    return party && <AddCircleIconWithTooltip
                      id="addBillToContact"
                      onClick={() => this.openBillToContactSideDrawer(party)}
                      title="Add Bill To Contact"
    />;
  }

  getBrokerCompanyDetails() {
    return {
      'company': get(this.props, 'user.company'),
      'value': 'broker',
    };
  }

  getBilledToPartyCompanyDetails() {
    return {
      'company': this.state.selectedBilledToParty,
      'value': 'billedTo',
    };
  }

  billToContactSideDrawer() {
    const billToParty = this.getBilledToPartyCompanyDetails();
    const party = get(billToParty, 'company');
    const companyId = get(party, 'id');
    return this.props.isEmployeeSideDrawerOpened &&
           party &&
           <SideDrawer
             isOpen={this.state.billToSideDrawerIsOpen}
             title={getLabelForEmployeeForm(party)}
             onClose={this.closeBillToContactSideDrawer}
             app="officeEmployee"
             canCreate={true}
           >
             <CreateEmployee
               closeDrawer={this.closeBillToContactSideDrawer}
               canAccessAny={true}
               companyId={companyId}
               selectedCompany={party}
               establishmentDetails={billToParty}
               getContacts={this.getCompanyEmployees}
             />
           </SideDrawer>;
  }

  getPartyEmails() {
    return {
      billToParty: get(find(this.state.billedToEmployees, { id: get(this.state.fields, 'billedToContact.value.id') }), 'email'),
      broker: get(find(this.state.brokerEmployees, { id: get(this.state.fields, 'brokerContact.value.id') }), 'email'),
    };
  }

  getPartyContacts() {
    return {
      billToParty: this.state.billedToEmployees,
      broker: this.state.brokerEmployees
    };
  }

  tonnageValue(item) {
    const isMeterCubeCommodity = get(this.props, 'contract.isStrictQuantityBasedCommodity');
    let tonnage = get(item, 'tonnage') || get(item, 'inferredTonnage');
    if (tonnage) return parseFloat(tonnage).toFixed(2) + ' ' + get(item, 'unit', isMeterCubeCommodity ? this.props.contract?.quantityUnit : 'MT');
  }

  noteHandler = val => {
    this.setState({fields: {...this.state.fields, note: val}});
  };

  // eslint-disable-next-line no-unused-vars
  handleBilledToNgrCreation = (companyId, submitData) => {
    this.setState({ billedToNgrs: [...this.state.billedToNgrs, submitData], selectedBilledToNgr: submitData })
  }

  getNoContractsOptionsText() {
    if(this.state.selectedBilledToParty && isEmpty(this.state.contractsCache[this.state.selectedBilledToParty]))
      return 'No eligible contracts for selected Bill To Party';

    if(!this.state.selectedBilledToParty)
      return 'Select Bill To Party to find relevant contracts';

    return 'Contract(s) not found';
  }

  render() {
    const COLUMNS = [
      { key: 'invoice', header: 'Select Item', checkbox: true, onChange: 'handleItemClick' },
      { key: 'description', header: 'Description' },
      { key: 'date', header: 'Date' },
      { valueFunction: this.tonnageValue, header: 'Quantity' },
      { key: 'rate', header: 'Rate' },
      { key: 'subTotal', header: `Total (Ex ${getCountryLabel('gst')})` },
      { key: 'gst', header: `${getCountryLabel('gst')}` },
      { key: 'total', header: `Total (Inc ${getCountryLabel('gst')})` },
    ];
    let brokerAccountNumber = this.state.fields.payee.accountNumber.value || get(this.state.fields, 'brokerNgr.value.bankAccounts[0].accountNumber');
    const bankItems = this.props.banks && uniqBy(this.props.banks.map(e => ({label: e.name, value: e.id})), 'label');
    const editBrokerNgrButton = (this.state.fields.brokerNgr.value) ?
                                <Button variant="outlined" onClick={() => this.openNgrSideDrawer(this.props.user.company, this.state.brokerNgrs, get(this.state.fields.brokerNgr.value, 'id'))} className="edit-ngr-button">
                                  Update NGR Details
                                </Button>
                              : null;
    let selectedBilledToParty = get(this.state.selectedBilledToParty, 'company') || this.state.selectedBilledToParty;
    const editBilledToNgrButton = get(this.state.selectedBilledToNgr, 'id') ?
                                  <Button variant="outlined" onClick={() => this.openNgrSideDrawer(this.state.selectedBilledToParty, this.state.billedToNgrs, get(this.state.selectedBilledToNgr, 'id'))} className="edit-ngr-button">
                                    {!isTransactionParticipated(selectedBilledToParty) ? 'Update NGR Details' : 'View NGR Details'}
                                  </Button>
                                : null;
    const addBrokerNgrButton = <Tooltip title="Add Broker NGR" placement="top">
      <AddCircleIcon id="addBrokerNgr" onClick={() => this.openAddNgrSideDrawer(this.props.user.company, "Broker")}
                     className="material-icons add-new-right"
                     style={{fontSize: '30px'}}/>
    </Tooltip>;
    const addBilledToNgrButton = selectedBilledToParty &&
                                 <Tooltip title="Add Bill To NGR" placement="top">
                                   <AddCircleIcon id="addBillToNgr" onClick={() => this.openAddNgrSideDrawer(selectedBilledToParty, "BilledTo")}
                                                  className="material-icons add-new-right"
                                                  style={{fontSize: '30px'}}/>
                                 </Tooltip>;
    const currency = get(this.props.contract, 'currency') || (this.state.contracts && get(this.state.contracts[0], 'currency')) || getCountryCurrency()
    return (
      <div ref={this.formRef}>
        <form className='invoice-form' onSubmit={this.handleSubmit} noValidate>
          <div className='cardForm'>
            <div className='dom-for-allocation-loader hide'></div>
            <h4 className='cardForm-title'>General</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
                <div className='col-md-12 padding-reset form-wrap'>
                  <TextField
                    id='identifier'
                    error={!isEmpty(this.state.fields.identifier.errors[0])}
                    placeholder='Please enter 14 digit unique number'
                    label='Invoice No'
                    inputRef={this.fieldRef['identifier']}
                    value={this.state.fields.identifier.value}
                    helperText={this.state.fields.identifier.errors[0]}
                    onChange={this.handleIdentifierChange}
                    onBlur={this.onFieldBlur}
                    fullWidth
                    inputProps={{
                      maxLength: 14,
                    }}
                    variant='standard'
                  />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonAutoSelect
                    id='payableBy'
                    setRef={this.fieldRef['payableBy']}
                    label='Bill To'
                    dontAutoselectSingleItem={true}
                    items={this.state.billToParties}
                    value={this.state.selectedBilledToParty}
                    dataSourceConfig={{ text: 'displayName', value: 'id' }}
                    onBlur={this.onFieldBlur}
                    onChange={this.handlePayableByChange}
                    errorText={!this.state.selectedBilledToParty && (this.state.fields.payableBy.errors[0] || get(this.state.serverErrors, 'payableBy.0'))}
                    selectedItem={this.state.selectedBilledToParty}
                  />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonDatePicker
                    id='paymentDueDate'
                    setRef={this.fieldRef['paymentDueDate']}
                    floatingLabelText='Payment Due Date'
                    errorText={this.state.fields.paymentDueDate.errors[0]}
                    value={this.state.fields.paymentDueDate.value}
                    onChange={this.handlePaymentDueDateChange}
                    onBlur={this.checkDueDate}
                    disabled={!this.state.selectedBilledToParty}
                  />
                </div>
              </div>
              <div className='col-md-5 col-md-offset-1'>
                <div className='col-md-12 padding-reset form-wrap'>
                  {this.props.contract ? (
                     <CommonTextField
                       id='contractNumber'
                       label='Contract No'
                       value={this.props.contract.contractNumber || this.props.contract.identifier}
                       disabled
                     />
                  ) :
                   (
                     <div>
                       <h4 style={{ margin: '0px 0px', fontWeight: 'normal', fontSize: '12px' }}>Contract No (Optional)</h4>
                       <Autocomplete
                         multiple
                         disableCloseOnSelect
                         limitTags={4}
                         id='contractNumber'
                         options={get(this.state.contractsCache, `${get(this.state.selectedBilledToParty, 'id')}`, [])}
                         getOptionLabel={option => option.brokerageInvoiceDisplayName}
                         renderInput={params => <TextField {...params} variant='standard' fullWidth />}
                         noOptionsText={this.getNoContractsOptionsText()}
                         onChange={this.addContract}
                         onClose={this.fetchDetailsOnClose}
                       />
                     </div>
                   )}
                </div>
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Invoicing Parties</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonTextField id='billTo' label='Bill To' value={get(this.state.selectedBilledToParty, 'displayName')} disabled />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonAutoSelect
                    id='billedToContact'
                    setRef={this.fieldRef['billedToContact']}
                    label='Contact'
                    items={uniqBy(this.state.billedToEmployees, 'id')}
                    value={this.state.fields.billedToContact.value}
                    onBlur={this.onFieldBlur}
                    onChange={this.handleBilledToContactChange}
                    errorText={this.state.fields.billedToContact.errors[0]}
                    selectedItem={this.state.fields.billedToContact.value || this.state.selectedBilledToContact}
                  />
                  {this.getAddBillToPartyContactButton()}
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonSelect
                    id='billedToNgr'
                    label={this.state.displayBilledToBankForm ? 'Enter Bank Details' : 'NGR (Optional)'}
                    floatingLabelText={this.state.displayBilledToBankForm ? 'Enter Bank Details' : 'NGR (Optional)'}
                    value={get(this.state.selectedBilledToNgr, 'id')}
                    items={this.state.billedToNgrs}
                    selectConfig={{ text: 'ngrNumber', value: 'id' }}
                    fullWidth
                    onBlur={this.onFieldBlur}
                    onChange={this.handleBilledToNgrChange}
                    includeEmptyOption={true}
                    emptyOptionText='Enter Bank Details'
                    dontAutoselectSingleItem
                  />
                  {addBilledToNgrButton}
                  {editBilledToNgrButton}
                </div>
                {this.state.displayBilledToBankForm ? (
                   <div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <CommonTextField
                         id='bsbNumber'
                         label={`${getCountryLabel('bsb')} Number (Optional)`}
                         value={this.state.fields.payer.bsbNumber.value}
                         onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                         placeholder={`Please enter ${get(this.countryConfig, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                         type='number'
                         onInput={e => {
                             e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.bsbLength'));
                         }}
                       />
                     </div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <AutoComplete
                         id='name'
                         label='Bank Name (Optional)'
                         placeholder='Bank Name (Optional)'
                         options={bankItems}
                         fullWidth
                         errorText={this.state.fields.payer.bankName.errors[0]}
                         onBlur={this.onFieldBlur}
                         value={this.state.fields.payer.bankName.value}
                         onChange={this.handlePayerBankChange()}
                       />
                     </div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <CommonTextField
                         id='accountName'
                         label='Account Name (Optional)'
                         value={this.state.fields.payer.accountName.value}
                         onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                       />
                     </div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <CommonTextField
                         id='accountNumber'
                         label='Account Number (Optional)'
                         value={this.state.fields.payer.accountNumber.value}
                         type='number'
                         onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                         placeholder={`Please enter ${get(this.countryConfig, 'bank.accountNumberMinimumLength')}-${get(this.countryConfig, 'bank.accountNumberMaximumLength')} digits Account number`}
                         onInput={e => {
                             e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.accountNumberMaximumLength'));
                         }}
                       />
                     </div>
                   </div>
                ) : null}
              </div>
              <div className='col-md-5 col-md-offset-1'>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonTextField id='broker' label='Broker' value={this.props.user.company.name} disabled />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonAutoSelect
                    id='brokerContact'
                    setRef={this.fieldRef['brokerContact']}
                    label='Contact'
                    items={this.state.brokerEmployees}
                    value={this.state.fields.brokerContact.value}
                    onBlur={this.onFieldBlur}
                    onChange={this.handleBrokerContactChange}
                    errorText={this.state.fields.brokerContact.errors[0]}
                  />
                  <AddCircleIconWithTooltip id='addBrokerContact' onClick={() => this.openBrokerSideDrawer()} title='Add Broker Contact' />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonSelect
                    id='brokerNgr'
                    floatingLabelText={this.state.displayBrokerBankForm ? 'Enter Bank Details' : 'NGR'}
                    items={this.state.brokerNgrs}
                    selectConfig={{ text: 'ngrNumber', value: 'id' }}
                    onBlur={this.onFieldBlur}
                    fullWidth
                    setRef={this.fieldRef['brokerNgr']}
                    onChange={this.handleBrokerNgrChange}
                    errorText={this.state.fields.brokerNgr.errors[0]}
                    value={get(this.state.fields.brokerNgr.value, 'id')}
                    includeEmptyOption={true}
                    emptyOptionText='Enter Bank Details'
                  />
                  {addBrokerNgrButton}
                  {editBrokerNgrButton}
                </div>
                {this.state.displayBrokerBankForm ? (
                   <div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <CommonTextField
                         id='bsbNumber'
                         label={`${getCountryLabel('bsb')} Number`}
                         value={this.state.fields.payee.bsbNumber.value}
                         onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                         placeholder={`Please enter ${get(this.countryConfig, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                         setRef={this.fieldRef['payee.bsbNumber']}
                         type='number'
                         helperText={this.state.fields.payee.bsbNumber.errors[0]}
                         onInput={e => {
                             e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.bsbLength'));
                         }}
                       />
                     </div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <AutoComplete
                         id='payee.bankName'
                         label='Bank Name'
                         placeholder='Bank Name'
                         options={bankItems}
                         fullWidth
                         setRef={this.fieldRef['payee.bankName']}
                         errorText={this.state.fields.payee.bankName.errors[0]}
                         onBlur={this.onPartyFieldBlur}
                         value={this.state.fields.payee.bankName.value}
                         onChange={this.handlePayeeBankChange()}
                       />
                     </div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <CommonTextField
                         id='accountName'
                         label='Account Name'
                         value={this.state.fields.payee.accountName.value}
                         setRef={this.fieldRef['payee.accountName']}
                         helperText={this.state.fields.payee.accountName.errors[0]}
                         onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                       />
                     </div>
                     <div className='col-md-12 padding-reset form-wrap'>
                       <CommonTextField
                         id='accountNumber'
                         label='Account Number'
                         value={this.state.fields.payee.accountNumber.value}
                         onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                         placeholder={`Please enter ${get(this.countryConfig, 'bank.accountNumberMinimumLength')}-${get(this.countryConfig, 'bank.accountNumberMaximumLength')} digits Account number`}
                         helperText={this.state.fields.payee.accountNumber.errors[0]}
                         setRef={this.fieldRef['payee.accountNumber']}
                         type='number'
                         onInput={e => {
                             e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.accountNumberMaximumLength'));
                         }}
                       />
                     </div>
                   </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Items To Be Invoiced</h4>
            <div style={{ position: 'relative' }}>
              <AddButton
                disabled={!this.state.selectedBilledToParty}
                variant="contained"
                label='Custom Item'
                primary={true}
                className={'btn-green'}
                onClick={this.openSideDraw}
                style={{ float: 'right', marginRight: '20px', marginTop: '0px', position: 'absolute', right: '0px', top: '-50px' }}
              />
            </div>
            <div>
              <SideDrawer isOpen={this.state.openSideDrawer} title='Add Item' onClose={this.closeSideDraw}>
                <InvoiceCustomItemForm closeDrawer={this.closeSideDraw} addItem={this.handleAddItem} currency={currency} />
              </SideDrawer>
            </div>

            <div>
              <InvoiceTable
                columns={COLUMNS}
                customItems={this.state.customItems}
                subscriptions={this.state.subscriptions}
                contracts={uniqBy(this.state.contracts, 'description')}
                handleItemClick={this.handleItemClick}
                hideFreightItems='true'
              />
              {some(this.state.customItems, item => item.gst === 'N/A') && <div>{`* ${getCountryLabel('gst')} is not applicable`}</div>}
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Invoice Conditions</h4>
            <div className='cardForm-content'>
              <div className='col-md-5 form-wrap'>
                <CommonAutoSelect
                  items={this.props.generalConditionsAll}
                  id='generalConditionsSelector'
                  value={this.state.generalConditionsSelector}
                  label='Invoice'
                  onChange={this.handleConditionSelector}
                  dontAutoselectSingleItem
                />
                <AddCircleIconWithTooltip
                  id='addInvoiceCondition'
                  title='Add Invoice Condition'
                  onClick={() => this.openInvoiceConditionSideDrawer()}
                />
                <CommonTextField
                  className='top15 text-black'
                  id='invoiceConditionViewer'
                  value={this.state.selectedGeneralConditionText}
                  fullWidth
                  multiline={true}
                  rows={1}
                  disabled={true}
                  rowsMax={1}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className='cardForm-content col-md-1 arrow-button'>
                <IconButton
                  disabled={!isNumber(this.state.generalConditionsSelector)}
                  onClick={this.appendInvoiceConditions}
                  size="large">
                  <ArrowForward color='primary' />
                </IconButton>
              </div>
              <div className='cardForm-content col-md-5'>
                <div className='field-label'>Conditions (Optional)</div>
                <TextField
                  className='text-area'
                  id='invoiceConditions'
                  value={this.state.fields.invoiceConditions.value}
                  fullWidth
                  multiline={true}
                  onChange={this.handleFieldChange}
                  rows={6}
                />
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Internal Notes (Optional)</h4>
            <div className='cardForm-content col-md-5'>
              <Notes
                title=''
                handler={this.noteHandler}
                note={this.state.fields.note}
                disabled={false}
              />
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Summary</h4>
            <div className='summary-section' style={{ marginLeft: '11px', marginBottom: '10px' }}>
              Against the Invoice ({this.state.fields.identifier.value}) raised by {this.props.user.company.name} ,{' '}
              {get(this.state.selectedBilledToParty, 'name')} agrees to pay {formatPrice(this.state.total, 'N/A', currency)} to {this.props.user.company.name} on
              the mentioned account number#{brokerAccountNumber} by {moment(this.state.fields.paymentDueDate.value).format(this.state.countryFormats.dateDisplay)}.
            </div>
            <div className='cardForm-content' style={{ marginLeft: '30%' }}>
              <div className='col-md-12 padding-reset form-wrap'>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>{`Sub-total (Ex ${getCountryLabel('gst')})`}</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{formatPrice(this.state.subTotal, 'N/A', currency)}</div>
                </div>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>{`${getCountryLabel('gst')}`}</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{formatPrice(this.state.gst, 'N/A', currency)}</div>
                </div>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>Total</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{formatPrice(this.state.total, 'N/A', currency)}</div>
                </div>
              </div>
            </div>

            <div className='col-md-12 cardForm-action' style={{ marginLeft: '25px' }}>
              <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleConfirmDialogOpen} />
              <CommonButton type='submit' label='Continue and Review' primary={true} variant="contained" />
            </div>
          </div>
          <Dialog open={this.state.isConfirmDialogOpen} onClose={this.handleConfirmDialogCancel} scroll='paper'>
            <DialogTitle>All the values entered by you will be lost.</DialogTitle>
            <DialogContent>Are you sure want to cancel?</DialogContent>
            <DialogActions>
              <CommonButton key='no' label='No' color="default" onClick={this.handleConfirmDialogCancel} variant='outlined' />
              <CommonButton key='yes' label='Yes' primary={true} onClick={this.handleConfirmDialogOk} variant='flat' />
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.preview} onClose={this.handlePreviewClose} scroll='paper' fullScreen>
            <DialogTitle>Invoice Preview</DialogTitle>
            <DialogContent>
              <InvoicePreview ref={el => (this.componentRef = el)} />
            </DialogContent>
            <DialogActions style={{paddingBottom: "40px"}}>
              <CommonButton label='Back' key='closeButton' default onClick={this.handlePreviewClose} variant='flat' />
              <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.handlePreviewSubmit} variant='flat' />
            </DialogActions>
          </Dialog>
        </form>

        <SideDrawer isOpen={this.props.isUpdateFormDisplayed} title='Edit NGR' size='big' onClose={this.closeNgrSideDrawer}>
          <UpdateCompanyNgr
            companyId={get(this.state.entityForNgrUpdate, 'id')}
            company={this.state.entityForNgrUpdate}
            canAccessAny={canAccessAny(this.state.entityForNgrUpdate)}
            bankAccountDetailsMandatory={true}
          />
        </SideDrawer>
        {this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
         <SideDrawer
           isOpen={this.state.ngrSideDrawerIsOpen}
           title="Add NGR"
           size="big"
           onClose={this.closeAddNgrSideDrawer}
           >
           <CreateCompanyNgr companyId={this.state.ngrEntity.id} company={this.state.ngrEntity} canAccessAny={true} closeDrawer={this.closeAddNgrSideDrawer} onSuccess={this.handleBilledToNgrCreation} bankAccountDetailsMandatory={true}/>
         </SideDrawer>
        }

        {this.props.isEmployeeSideDrawerOpened && <SideDrawer
                                                    isOpen={this.state.brokerSideDrawerIsOpen}
                                                    title={getLabelForEmployeeForm(this.props.user.company)}
                                                    onClose={this.closeBrokerContactSideDrawer}
                                                    app="officeEmployee"
                                                    canCreate={true}
                                                  >
          <CreateEmployee
            closeDrawer={this.closeBrokerContactSideDrawer}
            canAccessAny={true}
            companyId={get(this.props, 'user.company.id')}
            selectedCompany={get(this.props, 'user.company')}
            selectedFarm={null}
            establishmentDetails={this.getBrokerCompanyDetails()}
            getContacts={this.getCompanyEmployees}
          />
        </SideDrawer>
        }
        {this.state.ngrEntity &&
         get(this.state.ngrEntity, 'establishmentContentType', get(this.state.ngrEntity, 'entity')) === 'company' &&
         this.props.isCreateNgrFormDisplayed && (
           <SideDrawer isOpen={this.state.ngrSideDrawerIsOpen} title='Add NGR' size='big' onClose={this.closeAddNgrSideDrawer}>
             <CreateCompanyNgr
               companyId={this.state.ngrEntity.id}
               company={this.state.ngrEntity}
               canAccessAny={true}
               closeDrawer={this.closeAddNgrSideDrawer}
               onSuccess={this.handleBilledToNgrCreation}
               bankAccountDetailsMandatory={true}
             />
           </SideDrawer>
         )}

        {this.state.ngrEntity &&
         get(this.state.ngrEntity, 'establishmentContentType', get(this.state.ngrEntity, 'entity')) === 'farm' &&
         this.props.isCreateFormDisplayed && (
           <SideDrawer isOpen={this.state.ngrSideDrawerIsOpen} title='Add NGR' size='big' onClose={this.closeAddNgrSideDrawer}>
             <CreateCompanyNgr
               farmId={this.state.ngrEntity.id}
               company={this.state.ngrEntity}
               canAccessAny={true}
               closeDrawer={this.closeAddNgrSideDrawer}
               onSuccess={this.handleBilledToNgrCreation}
               bankAccountDetailsMandatory={true}
             />
           </SideDrawer>
         )}
        {this.props.isEmployeeSideDrawerOpened && (
           <SideDrawer
             isOpen={this.state.brokerSideDrawerIsOpen}
             title={getLabelForEmployeeForm(this.props.user.company)}
             onClose={this.closeBrokerContactSideDrawer}
             app='officeEmployee'
             canCreate={true}
             >
             <CreateEmployee
               closeDrawer={this.closeBrokerContactSideDrawer}
               canAccessAny={true}
               companyId={get(this.props, 'user.company.id')}
               selectedCompany={get(this.props, 'user.company')}
               selectedFarm={null}
               establishmentDetails={this.getBrokerCompanyDetails()}
               getContacts={this.getCompanyEmployees}
             />
           </SideDrawer>
        )}

        {this.billToContactSideDrawer()}
        {this.props.isGeneralConditionSideDrawerOpened && (
           <SideDrawer
             isOpen={this.state.invoiceConditionSideDrawerIsOpen}
             title='Add Invoice Condition'
             onClose={this.closeInvoiceConditionSideDrawer}
             app='condition'
             >
             <CreateGeneralCondition
               selectedConditionType='invoice'
               closeDrawer={this.closeInvoiceConditionSideDrawer}
               onSuccess={this.setDefaultGeneralCondition}
             />
           </SideDrawer>
        )}
        {
          get(this.props.invoiceCreateResponse, 'defaultSubject') &&
          this.state.showCustomEmailDialog && (
            <CustomEmailDialog
              parties={['billToParty', 'broker']}
              selectedParties={['billToParty']}
              title='Email PDF copies to'
              subject={this.props.invoiceCreateResponse.defaultSubject}
              noBody={true}
              open={this.state.showCustomEmailDialog}
              onClose={this.closeCustomEmailDialog}
              partyEmails={this.getPartyEmails()}
              partyContacts={this.getPartyContacts()}
            />
          )
        }
      </div>
    );
}
}

const mapDispatchToProps = dispatch => {
  return {
    handleAddEmployeeButtonClick: () => {
      dispatch(clickAddEmployeeButton());
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    dispatch,
  };
};

const mapStateToProps = state => {
  var paymentTerms = [];
  var farmParties = [];
  var companyParties = [];
  if (!isEmpty(state.companies.contracts.paymentTerms)) {
    paymentTerms = state.companies.contracts.paymentTerms;
  }
  if (!isEmpty(state.companies.contracts.parties.farms)) {
    farmParties = map(state.companies.contracts.parties.farms, farm => {
      return merge(farm, { name: get(farm, 'displayName'), rel: 'farms', entity: 'farm' });
    });
  }
  if (!isEmpty(state.companies.companies.companyParties)) {
    companyParties = map(state.companies.companies.companyParties, company => {
      return merge(company, { rel: 'companies', entity: 'company' });
    });
  }
  const parties = farmParties.concat(companyParties);
  const banks = state.main.banks || [];
  return {
    token: state.main.user.token,
    contract: state.companies.contracts.selectedContract,
    user: state.main.user.user,
    paymentTerms: paymentTerms,
    brokerEmployees: state.companySettings.employees.items,
    titleTransfers: state.companies.contracts.titleTransfers,
    freightMovements: state.companies.freights.items,
    isFreightContractsFetched: state.companies.freights.isItemsFetched,
    brokerContact: find(state.companySettings.employees.items, { id: state.main.user.user.id }),
    generalConditionsAll: state.companies.conditions.generalConditions,
    invoiceId: state.companies.invoices.selectedInvoiceId,
    isUpdateFormDisplayed: state.companies.companyNgrs.isUpdateFormDisplayed,
    isUpdateFormForBillToDisplayed: state.companies.ngrs.isUpdateFormDisplayed,
    updatedBillToNgr: state.companies.ngrs.updatedNgr,
    updatedNgr: state.companies.companyNgrs.updatedNgr,
    farmParties,
    companyParties,
    parties,
    banks,
    serverErrors: state.companies.invoices.serverErrors,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isGeneralConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    invoiceCreateResponse: state.companies.invoices.invoiceCreateResponse,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrokerageInvoiceForm);

import Tooltip from "@mui/material/Tooltip/index";
import React from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';

import merge from "lodash/merge";
import {PRIMARY_COLOR_GREEN} from "../../../common/constants";

const circleIconStyle = {
    fontSize: '30px',
};

export const AddCircleIconWithTooltip = (props) => {
    const disabled = props.disabled;
    const onClick = disabled ? null : props.onClick;
    const color = disabled ? 'disabled': PRIMARY_COLOR_GREEN;
    return (
        <Tooltip title={props.title} placement="top">
            <AddCircleIcon
                id={props.id}
                onClick={onClick}
                color={color}
                className="material-icons add-new-right"
                style={merge(props.style || {}, circleIconStyle)}
            />
        </Tooltip>
    );
};
import React from 'react';

import { connect } from 'react-redux';
import GeneralConditionsTable from '../../containers/GeneralConditionsTable';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import UpdateGeneralCondition from '../../containers/UpdateGeneralCondition';
import {
  getGeneralConditions,
  clickAddGeneralConditionButton,
  cancelEditGeneralCondition
} from '../../actions/companies/conditions';
import includes from 'lodash/includes';
import AddButton from '../common/AddButton';
import {setHeaderText, setBreadcrumbs} from '../../actions/main';
import isEqual from 'lodash/isEqual';
import SideDrawer from '../common/SideDrawer';
import {isSystemCompany} from "../../common/utils";

class GeneralConditions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
       openSideDrawer: false
    };

    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
  }

  componentDidMount() {
    this.props.getConditions();
    if(this.props.user) {
      if(this.props.headerText !== this.props.user.company.name) {
        this.props.setHeaderText(this.props.user.company.name);
      }
      const breadcrumbs = [
        {text: 'Companies', route: '/companies'},
        {text: this.props.user.company.name, onClick: this.props.onDetailsClick, route: '/companies/' + this.props.user.companyId + '/details?details'},
        {text: 'Conditions'}
      ];
      if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  openSideDraw(){
      this.props.handleAddGeneralConditionButtonClick();
      this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
      this.setState({ openSideDrawer: false, });
  }

  render() {
    return (
      <div>
        <AddButton
          label="General Condition"
          onClick={this.openSideDraw}
          disabled={!this.props.canCreate}
          app="condition"
        />
        <GeneralConditionsTable/>
        { this.props.isCreateFormDisplayed &&
        <SideDrawer
            isOpen={ this.state.openSideDrawer }
            title="Add General Condition"
            onClose={this.closeSideDraw}
            app="condition"
        >
          <CreateGeneralCondition closeDrawer={ this.closeSideDraw } />
        </SideDrawer>
        }
        <SideDrawer
            isOpen={ this.props.isUpdateFormDisplayed }
            title="Edit General Condition"
            onClose={this.props.cancelEditGeneralCondition }
            app="condition"
        >
          <UpdateGeneralCondition closeDrawer={ this.closeSideDraw } />
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const user = state.main.user.user;
  return {
    canCreate: includes([1, 2, 4], user.typeId) || isSystemCompany(),
    isCreateFormDisplayed: state.companies.conditions.isCreateFormDisplayed,
    isUpdateFormDisplayed: state.companies.conditions.isUpdateFormDisplayed,
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs,
    user: user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getConditions: () => dispatch(getGeneralConditions()),
    handleAddGeneralConditionButtonClick: () => dispatch(clickAddGeneralConditionButton()),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditGeneralCondition: () => dispatch(cancelEditGeneralCondition())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConditions);

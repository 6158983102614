import { connect } from 'react-redux';
import { get } from 'lodash'
import GenericTable from '../components/GenericTable';
import { activateEmployeeAction } from '../actions/company-settings/employees';
import { upsert, unlink } from '../actions/companies/key-contacts';
import { isInMyCompanyContext, isSystemCompany, isCurrentUserCompanyAdmin, isCurrentUserBelongsToCompany } from '../common/utils';
import { SYSTEM_COMPANY_IDS } from '../common/constants';
import { activateEmployee } from '../actions/api/employees';


const mapStateToProps = (state) => {
  const tooltipTextFunc = (item) => {
    return item.keyContact ? 'Unmark this person as your key contact' : 'Mark this person as your key contact';
  };

  var employees = state.companySettings.employees.archivedItems;
  const keyContactTab = { key: 'keyContact', header: 'Key Contact', checkbox: true, onChange: 'handleKeyContactClick', tooltipTextFunc: tooltipTextFunc };

  let columns = [
    { key: 'firstName', header: 'First Name', },
    { key: 'lastName', header: 'Last Name' },
    { key: 'type.displayName', header: 'Role' },
    { key: 'farm.name', secondaryKey: 'site.name', header: 'Farm/Site' },
    { key: 'title', header: 'Job Title' },
    { key: 'mobile', header: 'Mobile' },
    { key: 'email', header: 'Email' }
  ];

  if (!isInMyCompanyContext()) {
    columns.splice(0, 0, keyContactTab);
  }

  if(isSystemCompany() && SYSTEM_COMPANY_IDS.includes(get(employees, '0.companyId')))
    columns = [...columns, {key: 'alias', header: 'Alias'}]

  let config = {
    columns: columns,
    items: employees,
    scrollToTopOnUpdate: false,
    orderBy: 'firstName',
  };

  if (isSystemCompany() || (isCurrentUserBelongsToCompany(get(employees, '0.companyId')) && isCurrentUserCompanyAdmin()))
  config.optionsItems = [{ key: 'activate', text: 'Activate' }];

  return config;


};

const mapDispatchToProps = (dispatch) => {
  return {
    handleKeyContactClick: (selectedItem, isChecked) => {
      if (isChecked) {
        dispatch(upsert({ employeeId: selectedItem.id }, 'companies', selectedItem.companyId));
      } else {
        dispatch(unlink('companies', selectedItem.companyId));
      }
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'activate') {
        dispatch(activateEmployee(item, activateEmployeeAction));
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);

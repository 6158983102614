import React, {Component} from 'react';


class Farm extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width ? this.props.width: "40"}
      height={this.props.height ? this.props.height: "40"} viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <path d="M1 1h40v40H1z"/>
        <path fill={this.props.fill} fillRule="nonzero" stroke={this.props.fill} strokeWidth=".5" d="M38.845 1.328a1.118 1.118 0 0 0-1.582 0L32.92 5.672c-1.544-.202-5.876-.451-8.31 1.983-.958.958-1.501 2.186-1.797 3.422a7.804 7.804 0 0 0-1.366-1.84l-.791-.792 3.954-3.954a1.118 1.118 0 1 0-1.581-1.582L16.7 9.236a7.822 7.822 0 0 0-1.858 2.962 1.119 1.119 0 0 0-1.306.202l-1.582 1.581a7.821 7.821 0 0 0-1.858 2.962 1.117 1.117 0 0 0-1.305.202L7.21 18.727c-3.052 3.052-3.052 8.02 0 11.072l.791.79-6.677 6.678a1.118 1.118 0 1 0 1.581 1.582l6.678-6.678.79.791a7.804 7.804 0 0 0 5.537 2.29c2.004 0 4.01-.764 5.536-2.29l1.582-1.582c.341-.341.428-.868.201-1.305a7.822 7.822 0 0 0 2.962-1.858l1.582-1.582c.353-.353.42-.884.202-1.305a7.821 7.821 0 0 0 2.961-1.858l6.327-6.327a1.118 1.118 0 1 0-1.582-1.582l-3.954 3.954-.79-.79a7.803 7.803 0 0 0-1.842-1.367c1.237-.295 2.465-.839 3.423-1.797 2.47-2.47 2.189-6.73 1.983-8.31l4.344-4.344a1.118 1.118 0 0 0 0-1.581zm-20.559 9.49l.791-.791.791.79a5.599 5.599 0 0 1 0 7.91l-.875.875a7.806 7.806 0 0 0-2.288-5.62 5.582 5.582 0 0 1 1.581-3.164zm-4.745 4.745l.791-.79.79.79a5.599 5.599 0 0 1 0 7.909l-.874.875a7.806 7.806 0 0 0-2.288-5.62 5.581 5.581 0 0 1 1.581-3.164zm-3.163 12.654l-.791.79-.791-.79a5.599 5.599 0 0 1 0-7.909l.79-.79.792.79a5.599 5.599 0 0 1 0 7.909zm9.49 3.163a5.599 5.599 0 0 1-7.909 0l-.79-.79.79-.791a5.599 5.599 0 0 1 7.91 0l.79.79-.79.791zm4.745-4.745a5.581 5.581 0 0 1-3.163 1.582 7.806 7.806 0 0 0-5.62-2.288l.875-.876a5.599 5.599 0 0 1 7.908 0l.791.791-.79.791zm4.746-6.327l.79.791-.79.791a5.581 5.581 0 0 1-3.164 1.581 7.806 7.806 0 0 0-5.62-2.287l.875-.876a5.599 5.599 0 0 1 7.909 0zm1.581-6.327c-1.631 1.632-4.703 1.532-6.134 1.381-.177-1.665-.134-4.603 1.389-6.126 1.631-1.631 4.704-1.532 6.134-1.38.178 1.665.134 4.603-1.389 6.125z"/>
      </g>
    </svg>;
  }
}

export default Farm;

import React from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import get from 'lodash/get';
import APIService from '../../../services/APIService'
import NestedOptionMenu from "../../NestedOptionMenu";
import { isLoading } from '../../../actions/main';
import { handleInvoicesOptionsMapper } from "../../../actions/companies/invoices";
import { getActionMenuOptions, getWarehouseDashboardMenuOptions } from '../utils';

export class InvoiceDetailsAction extends React.Component {

  componentDidMount() {
    setTimeout(this.setActionButtonBoundaries, 100);
  }

  setActionButtonBoundaries() {
    const statusBarEl = get(document.getElementsByClassName('invoice-status'), '0');
    if(statusBarEl) {
      const top = statusBarEl.offsetTop;
      const height = statusBarEl.offsetHeight;
      if(top && height) {
        const actionsContainer = document.getElementById('nested-actions-menu-container');
        if(actionsContainer) {
          actionsContainer.style.top = (top - 1) + 'px';
          actionsContainer.style.height = (height + 1) + 'px';
        }
      }

    }
  }

  handleActionsClick = event => {
    this.setState({anchorEl: event.currentTarget});
  };


  syncXeroPayments = invoice => {
    this.props.dispatch(isLoading('xeroPaymentSync'))
    APIService
      .invoices(invoice.id)
      .appendToUrl('xero/payments/')
      .get()
      .then(() => alertifyjs.success('Successfully synced payments. Reloading...', 2, () => window.location.reload()))
  }

 handleOptionClick = (event, item, baseEntity) => {
   const { pdfUrl } = get(this.props, 'invoiceDetails');
   if(item.key === 'create')
     window.location.hash = `#/invoices/warehouse/${baseEntity.id}/edit`
   else if(item.key === 'sync_xero_payments')
     this.syncXeroPayments(baseEntity)
   else
    this.props.dispatch(handleInvoicesOptionsMapper(event, item, baseEntity, pdfUrl, 'InvoiceDetailsAction'));
  };

  getOptions() {
    const { invoiceDetails } = this.props;
    const options = invoiceDetails.statusDisplayName === 'draft' ? getWarehouseDashboardMenuOptions(invoiceDetails) : getActionMenuOptions(invoiceDetails, true);
    return options;
  };

  render() {
    return (
      <React.Fragment>
        {
          this.props.invoiceDetails &&
          <NestedOptionMenu
            optionsItems={this.getOptions()}
            item={this.props.invoiceDetails}
            handleOptionClick={this.handleOptionClick}
            currentUser={this.props.currentUser}
            shouldOptionBeDisabled={this.props.shouldOptionBeDisabled}
            useButton={true}
            useIconButton={false}
          />
        }
      </React.Fragment>
    );

  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

connect(mapStateToProps)(InvoiceDetailsAction);

import React from 'react';

import ActiveFarmFields from './ActiveFarmFields';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import ArchivedFarmFields from './ArchivedFarmFields';

class FarmFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: 'active'
    }
  }

  tabChanges = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={'active'} label="Active Fields" className={value !== 'active' ? 'unselected-subtab' : ''} />
          {
            this.props.canActOnFarm &&
              <Tab value={'archived'}  label="Archived Fields" className={value !== 'archived' ? 'unselected-subtab' : ''} />
          }
        </Tabs>
        <div className="subTab-container">
        {
          value === 'active' && <ActiveFarmFields {...this.props} />
        }
        {
          value === 'archived' && <ArchivedFarmFields {...this.props} />
        }
        </div>
      </div>
    );
  }
}

export default FarmFields;

import React from 'react';

import deburr from 'lodash/deburr';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';
import Autosuggest from 'react-autosuggest';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import Popper from '@mui/material/Popper';
import withStyles from '@mui/styles/withStyles';
import { startsWith } from 'lodash';


const styles = () => ({
  root: {
    // height: 250,
    flexGrow: 1,
  },
  container: {
    position: 'relative',
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: '8px',
    left: 0,
    right: 0,
  },
  suggestion: {
    display: 'block',
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  divider: {
    height: '16px',
  },
});

class CommonAutoSuggest extends React.Component {
  state = {
    single: '',
    areSuggestionsSet: false,
    suggestions: []
  };

  componentDidMount(){
    if(this.props.value){
      this.setState({single: this.props.value});
    }

    if(!isEmpty(this.props.suggestions)){
      this.setState({suggestions: this.props.suggestions, areSuggestionsSet: true});
    }
  }

  componentDidUpdate(){
    if(!isEqual(this.props.value, this.state.single)){
      this.setState({single: this.props.value});
    }
    if(!isEmpty(this.props.suggestions) && !isEqual(this.props.suggestions, this.state.suggestions) && !this.state.areSuggestionsSet){
      this.setState({suggestions: this.props.suggestions, areSuggestionsSet: true});
    }
  }

  renderInputComponent = (inputProps) => {
    const { classes, inputRef = () => {}, ref, ...other } = inputProps;
    return (
      <div className={this.props.disabled && this.props.value ? "relative-pos text-black": "relative-pos"} style={this.props.mainContainerStyle}>
        <TextField
          fullWidth
          InputProps={{
            inputRef: node => {
              ref(node);
              inputRef(node);
            },
            classes: {
              input: classes.input,
            },
          }}
          inputProps={{ autoComplete: 'off' }}
          disabled={this.props.disabled}
          {...other}
          variant="standard" />
        {
          this.props.disabled && this.props.value && <i className={this.props.lockIconClassName ? "side-drawer-icon-lock" : 'icon-lock'} style={this.props.lockIconStyle}></i>
        }
      </div>
    );
  };

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const matches = match(suggestion.name, query);
    const parts = parse(suggestion.name, matches);

    return (
      <MenuItem selected={isHighlighted} component="div">
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
              {part.text}
            </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            ),
          )}
        </div>
      </MenuItem>
    );
  };


  getSuggestionValue = (suggestion) => {
    return suggestion.name;
  };

  getSuggestions = (value) => {
    const { suggestions } = this.props;
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    const suggestionsCount = this.props.suggestionsCount || 5;
    let count = 0;

    return inputLength === 0 ? [] : (suggestions || []).filter(suggestion => {
      const keep = count < suggestionsCount && suggestion.name.toLowerCase().includes(inputValue);
      if (keep) {
        count += 1;
      }
      return keep;
    });
  };

  handleSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    });
  };

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: [],
    });
  };

  handleChange = name => (event, { newValue }) => {
    event.preventDefault();
    this.setState({
      [name]: newValue,
    }, () => this.props.onChange(newValue, this.props.id));
  };

  render() {
    const { classes } = this.props;

    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions: isArray(this.state.suggestions) ? this.state.suggestions : [],
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion,
    };

    return (
      <div className={this.props.errorText ? startsWith(this.props.errorText, 'Warning') ? 'autosuggest-warning ' : 'autosuggest-error' + classes.root : classes.root}>
        <Autosuggest
          onSuggestionSelected={this.props.onSelect}
          className={this.props.className}
          {...autosuggestProps}
          inputProps={{
            classes,
            autoComplete: 'off',
            label: this.state.single ? this.props.label : '',
            placeholder: this.props.placeholder || this.props.label,
            value: this.state.single,
            onChange: this.handleChange('single'),
            onBlur: this.props.onBlur,
            id: this.props.id,
            inputRef: node => {
              this.popperNode = node;
            },
            InputLabelProps: {
              shrink: true,
            },
            style: this.state.single ? {} : {marginTop: '16px'}
          }}
          theme={{
            suggestionsList: classes.suggestionsList,
            suggestion: classes.suggestion,
          }}
          renderSuggestionsContainer={options => (
            <Popper anchorEl={this.popperNode} open={Boolean(options.children)} style={{zIndex: 1400}}>
              <Paper
                square
                {...options.containerProps}
                style={{ width: this.popperNode ? this.popperNode.clientWidth : null}}
              >
                {options.children}
              </Paper>
            </Popper>
          )}
        />
        {
          this.props.errorText ?
          <p className={startsWith(this.props.errorText, 'Warning') ? "warning-message":"error-message"} id={this.props.id + '-helper-text'}>{this.props.errorText}</p>:
          <p></p>
        }
      </div>
    );
  }
}

export default withStyles(styles)(CommonAutoSuggest);

import React from 'react';
import {
  Dialog, DialogActions, DialogContent,
} from '@mui/material';
import { DialogTitleWithCloseIcon } from '../../common/DialogTitleWithCloseIcon';
import { capitalize, isEmpty, get, isArray } from 'lodash';
import CommonButton from '../../common/CommonButton';
import { useDispatch } from 'react-redux';
import { canCloseOutForContract, openCloseOutDialog, setClickedOption, showHierarchyForOrderId } from '../../../actions/companies/contracts';
import HierarchyIcon from '../../common/icons/HierarchyIcon';

const CloseOutDialog = ({ onClose, open, response }) => {
  const reasons = get(response[0], 'cannotCloseOutReasons', []);
  const userConfirmations = get(response[0], 'closeOutTransitionConfirmations', []);
  const dispatch = useDispatch();

  const createClickHandler = (orderId) => {
    dispatch(showHierarchyForOrderId(""));
    dispatch(showHierarchyForOrderId(orderId));
  };

  const handleClose = () => {
    dispatch(showHierarchyForOrderId(""));
    dispatch(openCloseOutDialog(null));
    dispatch(setClickedOption(null));
  }

  const handleProceed = () => {
    dispatch(showHierarchyForOrderId(""));
    dispatch(openCloseOutDialog(null));
    dispatch(canCloseOutForContract(true));

  }

  // Format reasons
  const formattedReasons = reasons.map((reason, index) => {
    const [key, values] = reason;
    if (isArray(values) && values.length > 0) {
      return (
        <li key={index}>
          {key}
          <ul>
            {values.map(([identifier, url], i) => (
              <li key={i}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {identifier}
                </a>
              </li>
            ))}
          </ul>
        </li>
      );
    } else {
      return <li key={index}>{key}</li>;
    }
  });


  let formattedConfirmations = null;
  if (isArray(userConfirmations) && !isEmpty(userConfirmations)) {
    formattedConfirmations = (
      <div>
        <p>With the following transitions:</p>
        <div style={{ maxHeight: '220px', minHeight: '50px', overflowY: 'scroll' }}>
          <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Order/Movement</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Current Status</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Post Close-Out Status</th>
                <th style={{ border: '1px solid black', padding: '8px', textAlign: 'left' }}>Total Delivered Tonnage</th>
              </tr>
            </thead>
            <tbody>
              {userConfirmations.map(([orderNumber, url, currentStatus, updatedStatus, deliveredTonnage, orderId], index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid black', padding: '8px' }}>
                    <a href={url} target='_blank' rel="noopener noreferrer">{orderNumber}</a>
                  </td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{capitalize(currentStatus)}</td>
                  <td style={{ border: '1px solid black', padding: '8px' }}>{capitalize(updatedStatus)}</td>
                  <td style={{ border: '1px solid black', padding: '8px', position: 'relative', paddingRight: '50px' }}>
                    {capitalize(deliveredTonnage)}
                    {orderId && <span
                      style={{ cursor: 'pointer', position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}
                      onClick={() => createClickHandler(orderId)}
                    >
                    <HierarchyIcon/>
                    </span>}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }

  return (
    <Dialog
      open={open}
      keepMounted
      className='slot-comments-dialog'
      onClose={onClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
      PaperProps={{
        style: {
          position: 'fixed',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        },
      }}
    >
      <DialogTitleWithCloseIcon onClose={onClose} style={{ height: '55px' }}>
        <div style={{ marginTop: '-4px' }}>Contract Close Out Warning</div>
      </DialogTitleWithCloseIcon>
      {!isEmpty(reasons) && (
        <DialogContent>
          <div>
            <p>You cannot close out this Contract because:</p>
            <ul>{formattedReasons}</ul>
          </div>
        </DialogContent>
      )
      }
      {
        isArray(reasons) && isEmpty(reasons) && (
          <DialogContent>
            <div>
              <p>To close out the contract, the system will reduce the contract tonnage to the tonnage that has been delivered.</p>
              {formattedConfirmations}
              <p></p>
              <p>Do you wish to proceed?</p>
            </div>
          </DialogContent>
        )
      }
      {
        isArray(reasons) && isEmpty(reasons) && (
          <DialogActions>
            <CommonButton onClick={() => handleClose()} key='cancel' label='Cancel' secondary variant="contained" />
            <CommonButton onClick={() => handleProceed()} key='submit' label='Proceed' primary variant="contained" />
          </DialogActions>
        )
      }
      {
        !isEmpty(reasons) && (
          <DialogActions>
            <CommonButton onClick={() => handleClose()} key='okay' label='Ok' primary variant="contained" />
          </DialogActions>
        )
      }
    </Dialog >
  );
};

export default CloseOutDialog;

import React, {Component} from 'react';


class TraderCompany extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="90" height="90" viewBox="0 0 90 90">
        <g fill="none" fillRule="evenodd">
          <path fill={this.props.fill} d="M41.106 60.828a1.745 1.745 0 0 0-2.466-2.467 1.747 1.747 0 0 0 0 2.467 1.746 1.746 0 0 0 2.466 0M67.709 19.46c0 8.648-7.037 15.685-15.686 15.685-8.65 0-15.685-7.037-15.685-15.685 0-8.65 7.036-15.686 15.685-15.686 8.65 0 15.686 7.037 15.686 15.686m-34.857 0c0 10.57 8.6 19.171 19.171 19.171 10.57 0 19.171-8.6 19.171-19.171 0-10.572-8.6-19.172-19.17-19.172-10.571 0-19.172 8.6-19.172 19.172"/>
          <g transform="translate(0 37.238)">
            <path fill={this.props.fill} d="M11.354 23.765a1.738 1.738 0 0 1 2.157-.246c.302.184-.564-.605 15.095 15.034.697.697.66 1.806.01 2.456l-7.204 7.097L4.288 30.85l7.066-7.085zM33.2 12.199c2.67 0 5.25.537 7.673 1.597.22.096.458.146.699.146h19.79c1.89 0 3.487 1.541 3.487 3.485a3.49 3.49 0 0 1-3.486 3.486h-14.22a1.743 1.743 0 0 0 0 3.486h14.22a6.98 6.98 0 0 0 6.971-6.972c0-.306-.022-.61-.06-.91C78.22 6.29 79.623 4.852 79.683 4.78a3.498 3.498 0 0 1 4.914-.415 3.493 3.493 0 0 1 .408 4.92L69.374 27.972a12.135 12.135 0 0 1-8.447 3.397H37.758c-2.27 0-4.42.869-6.05 2.443l-1.481 1.43L16.573 21.59c3.191-5.986 9.18-9.391 16.627-9.391zM20.162 51.794a1.742 1.742 0 0 0 2.46.014l8.45-8.324c1.434-1.437 1.871-3.562 1.24-5.41l1.817-1.752a5.198 5.198 0 0 1 3.63-1.466h23.168c4.11 0 7.995-1.579 10.94-4.445.121-.118-.92 1.108 15.807-18.883 2.48-2.935 2.11-7.342-.828-9.826-2.92-2.46-7.293-2.107-9.78.778L66.781 13.05a7.006 7.006 0 0 0-5.42-2.594H41.93A22.49 22.49 0 0 0 33.2 8.713c-8.392 0-15.72 3.877-19.653 11.143a5.209 5.209 0 0 0-4.661 1.445L.596 29.617a1.743 1.743 0 0 0-.003 2.458l19.569 19.719z"/>
            <path id="a" d="M.087.064h89.03V52.31H.087z"/>
          </g>
          <path fill={this.props.fill} d="M49.932 9.003v2.042a5.237 5.237 0 0 0-3.486 4.929 5.234 5.234 0 0 0 5.228 5.229c.961 0 1.744.781 1.744 1.742s-.783 1.743-1.744 1.743c-.744 0-1.548-.469-2.262-1.319a1.743 1.743 0 0 0-2.668 2.243c.931 1.108 2.027 1.88 3.188 2.27v2.034a1.743 1.743 0 1 0 3.486 0v-2.042a5.237 5.237 0 0 0 3.485-4.929 5.235 5.235 0 0 0-5.229-5.23c-.96 0-1.742-.78-1.742-1.741 0-.962.782-1.744 1.742-1.744.618 0 1.27.316 1.886.912a1.743 1.743 0 0 0 2.424-2.505c-.884-.856-1.77-1.34-2.566-1.605v-2.03a1.743 1.743 0 1 0-3.486 0"/>
        </g>
      </svg>
    );
  }
}

export default TraderCompany;

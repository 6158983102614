import React from 'react';
import { MenuItem, Divider } from '@mui/material';
import { Done } from '@mui/icons-material';
import { isElement, get, includes, without, map, find, isEqual } from 'lodash';

const DEFAULT_OPTION = {id: 'week', name: 'Weekly'};
const SEPARATOR = {id: 'separator', name: '' };
const OPTIONS = [
  {id: 'day', name: 'Daily'},
  DEFAULT_OPTION,
  {id: 'month', name: 'Monthly', weeks: 0},
  {id: '2Weeks', name: '2 weeks', weeks: 2},
  {id: '3Weeks', name: '3 weeks', weeks: 3},
  SEPARATOR,
  {id: 'hideWeekends', name: 'Hide Weekends', control: true},
  {id: 'startWeekOnSunday', name: 'Start Week on Sunday', control: true},
  {id: 'narrowerThanWeekdays', name: 'Narrower than weekdays', control: true},
];
class CalendarViewOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: null,
      selectedControls: [],
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    const { defaultView, selectedView, selectedControls} = this.props;
    if(defaultView)
      this.setState({selectedOption: find(OPTIONS, {id: defaultView}) || DEFAULT_OPTION});
    if(selectedView)
      this.setState({selectedOption: find(OPTIONS, {id: selectedView.id}) || selectedView})
    if(selectedControls)
      this.setState({selectedControls: selectedControls})
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const el = document.getElementById('calendar-options');

    if(isElement(el) && !el.contains(event.target))
      this.close();
  }

  handleSelect(option) {
    if(get(option, 'control'))
      this.selectControl(option);
    else
      this.selectOption(option);
  }

  afterSelect() {
    if(this.props.onSelect)
      this.props.onSelect(this.state.selectedOption, this.state.selectedControls);
    this.props.close();
  }

  selectControl(control) {
    const { selectedControls } = this.state;
    let controls;

    if(includes(selectedControls, control))
      controls = without(selectedControls, control);
    else
      controls = [...selectedControls, control];

    this.setState({selectedControls: controls}, this.afterSelect);
  }

  selectOption(option) {
    this.setState({selectedOption: option}, this.afterSelect);
  }

  render() {
    const { selectedOption, selectedControls } = this.state;
    const { open } = this.props;
    return (
      <span>
        { open &&
          OPTIONS.map((option, index) => (
          <div>
          { index !== OPTIONS.length && <Divider style={{margin: '0px'}}/> }
          {
            !isEqual(option.id, 'separator') ?
            <MenuItem style={{ fontSize: '14px', padding: '10px 15px' }} key={option.id} onClick={() => this.handleSelect(option) }>
              <span style={{ width: '70%', marginLeft: '15px' }}>{option.name}</span>
              {
                includes([get(selectedOption, 'id'), ...map(selectedControls, 'id')], option.id) &&
                <span style={{ color: 'rgba(0, 0, 0, 0.4)', margin: '-2px 0 -2px 25px' }}>
                  <Done />
                </span>
              }
            </MenuItem>
            : <Divider style={{ margin: '0px' }}/>
          }
          </div>
          ))
        }
      </span>
    );
  }
}

export default CalendarViewOptions;

import React from 'react';
import { connect } from 'react-redux';
import { Grid, Switch } from '@mui/material';
import { map } from 'lodash';
import { setHeaderText } from '../../actions/main/index';
import APIService from '../../services/APIService';

class Toggles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      toggles: {}
    };
  }

  componentDidMount() {
    this.props.dispatch(setHeaderText("Toggles"));
    this.fetchToggles();
  }

  fetchToggles() {
    APIService.toggles().get().then(toggles => this.setState({toggles: toggles}));
  }

  updateToggle = event => {
    const serverEnv = localStorage.serverEnv;
    if(!serverEnv)
      return;

    const toggleName = event.target.id;
    const toggleState = event.target.checked;
    const payload = {};
    payload[localStorage.serverEnv] = toggleState;

    APIService.toggles(toggleName).patch(payload).then(() => this.setState({toggles: {...this.state.toggles, [toggleName]: toggleState}}));
  };

  render() {
    const { toggles } = this.state;
    return (
      <div className='col-md-12' style={{background: 'white', padding: '10px 0'}}>
        {
          map(toggles, (state, toggle) => (
            <div className="col-md-12" key={toggle} style={{textAlign: 'left'}}>
              <div className='col-md-6'>
                {toggle}
              </div>
              <div className='col-md-4'>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <Grid item>OFF</Grid>
                  <Grid item>
                    <Switch color='primary' checked={state} id={toggle} onChange={this.updateToggle} />
                  </Grid>
                  <Grid item>ON</Grid>
                </Grid>
              </div>
            </div>
          ))
        }
      </div>
    );
  }
}

export default connect()(Toggles);

import { get } from 'lodash';
import {
  GET_COMMODITY_CONTRACTS_SUCCESS,
  GET_TEMPLATES_SUCCESS,
  GET_PARTIES_SUCCESS,
  GET_BUYER_CONTACTS_SUCCESS,
  GET_SELLER_CONTACTS_SUCCESS,
  GET_CUSTOMER_CONTACTS_SUCCESS,
  GET_FIXTURES_SUCCESS,
  START_FETCHING_SELECTED_CONTRACT,
  RESET_IS_FETCHING_SELECTED_CONTRACT,
  RECEIVE_CONTRACT,
  CLEAR_CONTRACT,
  ADD_CONTRACT_FAILURE,
  CLEAR_CONTRACT_FAILURE,
  CONFIRMATION_SUCCESS,
  REJECTION_SUCCESS,
  CAN_CONFIRM_OR_REJECT,
  GET_TITLE_TRANSFERS_SUCCESS,
  GET_PAYMENT_TERMS_SUCCESS,
  GET_PAGINATED_COMMODITY_CONTRACTS_SUCCESS,
  GET_TITLE_TRANSFER_CARRY_ITEMS_SUCCESS,
  GET_MOVEMENT_CARRY_ITEMS_SUCCESS,
  ADD_TITLE_TRANSFER_FAILURE,
  SHOW_HIDE_TITLE_TRANSFER_SIDE_DRAWER, IS_PARTIES_FETCHED,
  CAN_CREATE_COG,
  CONTRACT_ACTION_OPTIONS,
  CANNOT_MARK_COMPLETE_REASONS,
  SET_SELECTED_CONTRACT_ID,
  CAN_CREATE_TITLE_TRANSFERS,
  CAN_RAISE_VOID_REQUEST,
  CAN_RAISE_VOID_AND_DUPLICATE_REQUEST,
  SHOULD_OPEN_REVIEW_AMENDMENT_DRAWER,
  CAN_MARK_AS_PAID,
  IS_TITLE_TRANSFERS_FETCHED,
  FREIGHT_MOVEMENT_WARNING,
  SHOULD_FETCH_CONTRACT,
  CLICKED_OPTION,
  VOID_TITLE_TRANSFER_DIALOG,
  START_FETCHING_SELECTED_TITLE_TRANSFER,
  RESET_IS_FETCHING_SELECTED_TITLE_TRANSFER,
  RECEIVE_TITLE_TRANSFER,
  CLICKED_TITLE_TRANSFER_OPTION,
  SHOW_VIEW_TITLE_TRANSFER_SIDE_DRAWER,
  GET_TITLE_TRANSFER_CANOLA_LOAD_CARRY_ITEMS_SUCCESS,
  SELECTED_TITLE_TRANSFER,
  SHOW_ADD_TITLE_TRANSFER_SIDE_DRAWER,
  UPDATE_DUPLICATE_TITLE_TRANSFER_ID
} from '../../actions/companies/contracts';


const initialState = {
  items: [],
  templates: [],
  parties: [],
  buyerContacts: [],
  sellerContacts: [],
  customerContacts: [],
  documentTypes: [],
  types: [],
  pricePoints: [],
  paymentTerms: [],
  paymentScales: [],
  tolerances: [],
  conveyances: [],
  checkpoints: [],
  packagings: [],
  titleTransfers: [],
  titleTransferPaginatedData: [],
  movementsCarryItems: [],
  titleTransfersCarryItems: [],
  titleTransfersCanolaLoadCarryItems: [],
  selectedContractId: undefined,
  selectedContract: undefined,
  serverErrors: undefined,
  confirmedOrRejectedContract: undefined,
  canConfirmOrReject: undefined,
  isFetchingSelectedContract: false,
  titleTransferErrors: undefined,
  isAddTitleTransferSideDrawerOpened: false,
  isPartiesFetched: false,
  canCreateCOGForContractId: undefined,
  subItems: [],
  cannotMarkCompleteReasons: undefined,
  canCreateTitleTransferForContractId: undefined,
  canRaiseVoidRequest: false,
  isFetchingVoidRequest: true,
  shouldOpenReviewAmendmentDrawer: false,
  canMarkAsPaid: false,
  isTTFetched: false,
  fmWarningFlag: false,
  fmWarningList: [],
  FMwarningContractID: undefined,
  shouldFetchContract: false,
  clickedOption: null,
  showVoidDialog: false,
  titleTransferId: undefined,
  selectedTitleTransfer: [],
  selectedTitleTransferId: undefined,
  isFetchingSelectedTitleTransfer: false,
  clickedTitleTransferOption: null,
  isViewTitleTransferSideDrawerOpened: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOULD_FETCH_CONTRACT:
    return {
      ...state,
      shouldFetchContract: action.flag,
    };
    case CAN_CREATE_COG:
      return {
        ...state,
        canCreateCOGForContractId: action.contractId
      };
    case GET_COMMODITY_CONTRACTS_SUCCESS:
      return {
        ...state,
        items: action.contracts,
        selectedContractId: undefined,
        selectedContract: undefined,
      };
    case GET_PAGINATED_COMMODITY_CONTRACTS_SUCCESS:
      return {
        ...state,
        items: action.paginatedContracts.results,
        paginationData: action.paginatedContracts,
        selectedContractId: undefined,
        selectedContract: undefined,
      };
    case GET_TEMPLATES_SUCCESS:
      return {
        ...state,
        templates: action.templates,
      };
    case GET_PARTIES_SUCCESS:
      return {
        ...state,
        parties: action.parties,
      };
    case IS_PARTIES_FETCHED:
      return {
        ...state,
        isPartiesFetched: action.isFetched,
      };
    case GET_BUYER_CONTACTS_SUCCESS:
      return {
        ...state,
        buyerContacts: action.buyerContacts,
      };
    case GET_SELLER_CONTACTS_SUCCESS:
      return {
        ...state,
        sellerContacts: action.sellerContacts,
      };
    case GET_CUSTOMER_CONTACTS_SUCCESS:
      return {
        ...state,
        customerContacts: action.customerContacts,
      };
    case GET_FIXTURES_SUCCESS:
      return {
        ...state,
        documentTypes: action.fixtures.contractDocumentType,
        types: action.fixtures.contractType,
        pricePoints: action.fixtures.contractPricePoint,
        paymentTerms: action.fixtures.paymentTerm,
        paymentScales: action.fixtures.paymentScale,
        tolerances: action.fixtures.tolerance,
        conveyances: action.fixtures.contractConveyance,
        checkpoints: action.fixtures.checkpoint,
        packagings: action.fixtures.packaging,
      };
    case GET_PAYMENT_TERMS_SUCCESS:
      return {
        ...state,
        paymentTerms: action.paymentTerms,
      };
    case START_FETCHING_SELECTED_CONTRACT:
      return {
        ...state,
        isFetchingSelectedContract: true,
      };
    case RESET_IS_FETCHING_SELECTED_CONTRACT:
      return {
        ...state,
        isFetchingSelectedContract: false,
      };
    case RESET_IS_FETCHING_SELECTED_TITLE_TRANSFER:
      return {
        ...state,
        isFetchingSelectedTitleTransfer: false,
      };
    case START_FETCHING_SELECTED_TITLE_TRANSFER:
        return {
          ...state,
          isFetchingSelectedTitleTransfer: false,
        };
    case RECEIVE_TITLE_TRANSFER:
      return {
        ...state,
        selectedTitleTransfer: action.item,
        selectedTitleTransferId: get(action, 'item.id'),
        isFetchingSelectedTitleTransfer: false,
      };
    case RECEIVE_CONTRACT:
      return {
        ...state,
        selectedContract: action.item,
        selectedContractId: get(action, 'item.id'),
        isFetchingSelectedContract: false,
      };
    case CLEAR_CONTRACT:
      return {
        ...state,
        selectedContract: undefined,
        selectedContractId: undefined,
        canCreateTitleTransferForContractId: undefined,
        canRaiseVoidRequest: false,
        shouldOpenReviewAmendmentDrawer: false,
        canMarkAsPaid: false,
        shouldFetchContract: false,
      };
    case ADD_CONTRACT_FAILURE:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CLEAR_CONTRACT_FAILURE:
      return {
        ...state,
        serverErrors: undefined,
      };
    case ADD_TITLE_TRANSFER_FAILURE:
      return {
        ...state,
        titleTransferErrors: action.errors,
      };
    case CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmedOrRejectedContract: action.contract
      };
    case REJECTION_SUCCESS:
      return {
        ...state,
        confirmedOrRejectedContract: action.contract
      };
    case CAN_CONFIRM_OR_REJECT:
      return {
        ...state,
        canConfirmOrReject: action.result
      };
    case GET_TITLE_TRANSFERS_SUCCESS:
      return {
        ...state,
        titleTransfers: get(action.items, 'results', get(action, 'items', [])),
        titleTransferPaginatedData: action.items,
        isAddTitleTransferSideDrawerOpened: false,
      };
    case IS_TITLE_TRANSFERS_FETCHED:
      return {
        ...state,
        isTTFetched: action.isFetched,
      };
    case SHOW_ADD_TITLE_TRANSFER_SIDE_DRAWER:
      return {
        ...state,
        isAddTitleTransferSideDrawerToBeOpened: action.show,
      };
    case SHOW_HIDE_TITLE_TRANSFER_SIDE_DRAWER:
      return {
        ...state,
        isAddTitleTransferSideDrawerOpened: action.show,
      };
      case SHOW_VIEW_TITLE_TRANSFER_SIDE_DRAWER:
        return {
          ...state,
          isViewTitleTransferSideDrawerOpened: action.show,
        };
    case GET_MOVEMENT_CARRY_ITEMS_SUCCESS:
      return {
        ...state,
        movementsCarryItems: action.items
      };
    case GET_TITLE_TRANSFER_CARRY_ITEMS_SUCCESS:
      return {
        ...state,
        titleTransfersCarryItems: action.items
      };
    case GET_TITLE_TRANSFER_CANOLA_LOAD_CARRY_ITEMS_SUCCESS:
      return {
        ...state,
        titleTransfersCanolaLoadCarryItems: action.items
      }
    case CONTRACT_ACTION_OPTIONS:
      return {
        ...state,
        subItems: action.subItems,
      };
    case CANNOT_MARK_COMPLETE_REASONS:
      return {
        ...state,
        cannotMarkCompleteReasons: action.cannotMarkCompleteReasons
      };
    case SET_SELECTED_CONTRACT_ID:
      return {
        ...state,
        selectedContractId: action.selectedContractId
      };
    case CAN_CREATE_TITLE_TRANSFERS:
      return {
        ...state,
        canCreateTitleTransferForContractId: action.selectedContractId
      };
    case CAN_RAISE_VOID_REQUEST:
      return {
        ...state,
        canRaiseVoidRequest: action.flag,
        isFetchingVoidRequest: false,
      };
    case CAN_RAISE_VOID_AND_DUPLICATE_REQUEST:
      return {
        ...state,
        canRaiseVoidAndDuplicateRequest: action.flag,
        isFetchingVoidRequest: false,
      };
    case SHOULD_OPEN_REVIEW_AMENDMENT_DRAWER:
      return {
        ...state,
        shouldOpenReviewAmendmentDrawer: action.flag,
      };
    case CAN_MARK_AS_PAID:
      return {
        ...state,
        canMarkAsPaid: action.flag,
      };
    case FREIGHT_MOVEMENT_WARNING:
      return {
        ...state,
        fmWarningFlag: action.FMwarningFlag,
        fmWarningList: action.FMwarningList,
        FMwarningContractID: action.FMwarningContractID
      };
    case CLICKED_OPTION:
      return {
        ...state,
        clickedOption: action.option
      };
    case CLICKED_TITLE_TRANSFER_OPTION:
        return {
          ...state,
          clickedTitleTransferOption: action.option
        };
    case VOID_TITLE_TRANSFER_DIALOG:
      return{
        ...state,
        showVoidDialog: action.flag,
        titleTransferId: action.item.id
      };
    case UPDATE_DUPLICATE_TITLE_TRANSFER_ID:
      return {
        ...state,
        copyFrom: action.id
      };
    case SELECTED_TITLE_TRANSFER:
      return {
        ...state,
        selectedTitleTransfer: action.item
      }
    default:
      return {
        ...state
      };
  }
};

export default reducer;

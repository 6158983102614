import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Order extends Component {
  render() {
    const height = this.props.height || 24;
    const width = this.props.width || 24;
    return <Tooltip title="Orders" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" >
        <g fill="none" fillRule="evenodd">
          <path stroke={this.props.fill} strokeWidth="2" d="M16.578 13H14v8.003h6v-4.489L16.578 13z"/>
          <circle cx="4.5" cy="21.5" r="2" stroke={this.props.fill}/>
          <circle cx="16.5" cy="21.5" r="2" stroke={this.props.fill}/>
          <path d="M0 0h24v24H0z"/>
          <path stroke={this.props.fill} strokeLinecap="round" strokeWidth="2" d="M20.402 12.699A6.5 6.5 0 1 0 10 7.5"/>
          <rect width="1" height="4" x="16" y="4" fill={this.props.fill} rx=".5"/>
          <rect width="1" height="4" x="17.268" y="6.768" fill={this.props.fill} rx=".5" transform="rotate(135 17.768 8.768)"/>
          <path fill={this.props.fill} fillRule="nonzero" d="M2 11v9s-2 1.135-2 .582V10a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H6.558l-.353-2H13v-9H2z"/>
          <path fill={this.props.fill} fillRule="nonzero" d="M2 21H1v-1h1.944l-.473 2H1a1 1 0 0 1-1-1v-1a1 1 0 0 1 2 0v1z"/>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default Order;

import React, {Component, Fragment} from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './acceptRejectContract.scss';
import APIService from '../../services/APIService';

class ConfirmAmendedContractForUnregisteredUser extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isConfirmed: false,
    };
  }

  async componentDidMount(){
    if(this.props.match && this.props.match.params.contract_id) {
      const contract = await APIService.contracts(this.props.match.params.contract_id).get(this.props.match.params.salt);
      const newState = {...this.state};
      newState.isLoading = true;
      this.setState(newState);
      if(contract && contract.amendable){
        await this.confirmAmendedContract(contract.id);
      } else {
        const newState = {...this.state};
        newState.isLoading = false;
        newState.canConfirm = false;
        this.setState(newState);
      }
    }
  }

  async confirmAmendedContract(contractId){
    const salt = this.props.match ? this.props.match.params.salt : null;
    await APIService.contracts(contractId)['amend/confirm']().put({'expireToken': true},salt)
      .then((json) => {
        if(!isEmpty(json.errors)){
          this.setState({ errors: json.errors[0] ,isLoading:false});
        } else {
          this.setState({ isConfirmed: true ,isLoading:false});
        }
      });
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            {this.state.isLoading ? <span>Loading ...</span> : <Fragment>
                {this.state.isConfirmed ? <span>The Contract has been amended.</span> : <span>{get(this.state,'errors','You do not have permission to access the information.')}</span>}
            </Fragment>}
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>;
  }
}

export default ConfirmAmendedContractForUnregisteredUser;

import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import {get, isArray, isEmpty} from 'lodash';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toDateFormat, getCountryLabel, openURLInNewTab } from '../../../common/utils';


class TruckVendorDecRequestPending extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addPaymentFormOpen: false,
      isFetching: true,
      requests: [],
      selectedRequest: null,
    };

    this.onAddVD = this.onAddVD.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.vendor_decs().appendToUrl('requests/pending/1/').get().then(requests => {
      const isOk = isArray(requests.movement);
      this.setState({isFetching: !isOk, requests: isOk ? requests.movement : []}, () => {
        this.props.onDataLoad(this.state.requests.length);
      });
    });
  }

  getTitle() {
    const suffix = this.state.isFetching ? '' : `(${this.state.requests.length})`;
    return `Pending Truck Vendor Declaration Requests ${suffix}`;
  }

  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/requests/${id}/details`);
  }

  async onAddVD(event, request) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/vendor-decs/truck/new?entity=freightcontract&entityId=${request.id}`);
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.requests))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon/>}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="large" style={{width: '145px'}}>Requested Against</TableCell>
                      <TableCell align='center' className="medium">Movement No.</TableCell>
                      <TableCell align='center' className="medium">Vendor</TableCell>
                      <TableCell align='center' className="medium">Vendee</TableCell>
                      <TableCell align='center' className="xsmall">Commodity</TableCell>
                      <TableCell align='center' className="medium">Grade</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('tonnage')}</TableCell>
                      <TableCell align='center' className="xsmall">Requested By</TableCell>
                      <TableCell align='center' className="medium">Requested On</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.requests) ?
                      this.state.requests.map(request => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={request.identifier}
                          onClick={(event) => this.onRowClick(event, request.id)}
                          >
                          <TableCell className="large">{this.getColumnValue(request.requestedAgainst)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(request.identifier)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(request.vendeeName)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(request.vendorName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(request.commodityName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(request.gradeName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(request.tonnageDisplayValue)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(get(request, 'requestedBy[0]', 'null'))}</TableCell>
                          <TableCell align='center' className="medium">
                            {
                              toDateFormat(this.getColumnValue(get(request, 'requestedOn[0]', 'null')))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={(event) => this.onAddVD(event, request)}>
                              Add Vendor Dec
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
});

export default connect(mapStateToProps)(TruckVendorDecRequestPending);

import React from 'react';
import { Autocomplete, TextField, Box, InputAdornment } from '@mui/material';
import { find } from 'lodash'

const CountryDropDown = ({onChange, id, label, variant, selected, ...rest}) => {
  const countries = JSON.parse(localStorage.countries || '[]')
  const _selected = find(countries, {code: selected?.code})
  return (
    <Autocomplete
      id={id}
      options={countries}
      autoHighlight
      fullWidth
      onChange={onChange}
      value={_selected}
      getOptionLabel={(option) => option.name}
      {...rest}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.name} ({option.code.toUpperCase()})
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant || 'outlined'}
          label={label}
          fullWidth
          InputProps={{
            ...params.InputProps,
            startAdornment: selected?.code ? (
              <InputAdornment position="start">
                <img src={`https://flagcdn.com/w20/${_selected?.code.toLowerCase()}.png`}/>
              </InputAdornment>
            ) : null,
          }}
        />
      )}
    />
  )
}

export default CountryDropDown;

import React from 'react';

import moment from 'moment';
import Paper from '@mui/material/Paper';
import Help from '@mui/icons-material/Help';
import { compact, isArray, includes, has, get, map } from 'lodash';
import { convertEpochToDateFormat} from '../../../common/momentUtilities';
import  OrderDetailsActions  from './OrderDetailsActions';
import { PRIMARY_COLOR_GREEN, PACK_ORDER_TYPE_ID } from '../../../common/constants';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ChipLabel } from '../../common/ChipLabel';
import TonnageDetail from '../../common/TonnageDetail';
import { isFloat, isAtMainOrderDetails } from '../../../common/utils';
import { AppContext } from '../../main/LayoutContext';

const renderBasedOnStatus = status => {
  switch (status) {
    case 'confirmed':
      return <img src='images/confirmed.svg'></img>;
    case 'delivered':
      return <img src='images/delivered.svg'></img>;
    case 'open':
      return <img src='images/open.svg'></img>;
    case 'in_progress':
      return <img src='images/in-progress.svg'></img>;
    case 'void':
      return <img src='images/voided.svg'></img>;
    case 'completed':
      return <img src='images/completed.svg'></img>;
    case 'rejected':
      return <img src='images/rejected.svg'></img>;
    case 'draft':
      return <img src='images/draft.svg'></img>;
  }
  return <Help style={{ fill: '#eee200', height: '40px', width: '40px' }} />;
};

const isPackOrder = typeId => {
  return typeId == PACK_ORDER_TYPE_ID;
};

const TonnageDetailWidget = props => {
  // TODO - Need to form unique unit from API end only
  const _unit = props?.order?.requestedUnit || props?.order?.commodity?.priceUnit;
  const totalTonnage = parseFloat(get(props.order, 'inferredTonnage'));
  const accountedTonnage = parseFloat(get(props.order, 'accountedTonnage'));
  const progressTonnage = parseFloat(get(props.order, 'progressTonnage'));
  const unplannedTonnage = totalTonnage - accountedTonnage > 0 ? totalTonnage - accountedTonnage : 0;
  const outstandingTonnage = parseFloat(get(props.order, 'outstandingTonnage'));
  const deliveredTonnage = parseFloat(get(props.order, 'totalDeliveredTonnage'));
  const isPackingOrder = isPackOrder(get(props.order, 'typeId'));
  let data = [
    {
      label: 'Total',
      value: isFloat(totalTonnage) ? totalTonnage.toFixed(2) : totalTonnage,
    },
    {
      label: 'Outstanding',
      value: outstandingTonnage > 0 ? (isFloat(outstandingTonnage) ? outstandingTonnage.toFixed(2) : outstandingTonnage) : 0,
    },
    {
      label: 'Delivered',
      value: isFloat(deliveredTonnage) ? deliveredTonnage.toFixed(2) : deliveredTonnage,
    },
    {
      label: 'Unplanned',
      value: isFloat(unplannedTonnage) ? unplannedTonnage.toFixed(2) : unplannedTonnage,
    },
    {
      label: 'In Progress',
      value: progressTonnage ? progressTonnage: 0
    }
  ];
  if (isPackingOrder) {
    data.push({
      label: 'Packed',
      value: isFloat(deliveredTonnage) ? deliveredTonnage.toFixed(2) : deliveredTonnage,
    },{
      label: 'Unpacked',
      value: outstandingTonnage > 0 ? (isFloat(outstandingTonnage) ? outstandingTonnage.toFixed(2) : outstandingTonnage) : 0,
    });
    data = [
      [data[0], data[5], data[6]]
    ];
  }
  else {
    if (props.showAllocationTonnage && !isPackingOrder) {
      const freightAllocationsTonnage = parseFloat(get(props.order, 'freightAllocationsTonnage'));
      const allocated = {
        label: 'Allocated',
        value: isFloat(freightAllocationsTonnage) ? freightAllocationsTonnage.toFixed(2) : freightAllocationsTonnage,
      };
      data = [
        [data[0], data[2], data[4], allocated],
        [data[1], data[3]],
      ];
    }
    if (has(props.order, 'confirmedTonnage')) {
      const confirmedTonnage = parseFloat(get(props.order, 'confirmedTonnage'));
      const booked = {
        label: 'Booked',
        value: isFloat(confirmedTonnage) ? confirmedTonnage.toFixed(2) : confirmedTonnage,
      };
        if (props.showAllocationTonnage) {
          data[1][2] = booked;
        } else {
          data = [
            [data[0], data[2], data[4]],
            [data[1], data[3], booked],
          ];
        }
    }
  }
  return (
    <React.Fragment>
      {isArray(data[0]) ? (
        map(data, (item, i) => (
          <div className='item item2 tonnage-widget' style={{ padding: '6px 10px' }} key={i}>
            <span>
              <TonnageDetail className='' unit={_unit} items={item} />
            </span>
          </div>
        ))
      ) : (
        <div className='item item2 tonnage-widget' style={{ padding: '6px 10px' }}>
          <span>
            <TonnageDetail className='' unit={_unit} items={data} />
          </span>
        </div>
      )}
    </React.Fragment>
  );
};

const getOrderUrl = (orderId, typeId) => {
  return isPackOrder(typeId) ? `#/pack/orders/${orderId}/order` : `#/freights/orders/${orderId}/order`
};

const isOrderDelayed = order => {
  if(order) {
    const endDate =  get(order, 'freightDelivery.date');
    return includes(['planned', 'open', 'in_progress', 'confirmed'], order.status) &&
           endDate &&
           moment().isAfter(endDate + ' 23:59:59');
  }

  return false;
};

export const OrderDetailsBasicInfo = props => {
  const { isMobileDevice } = React.useContext(AppContext);
  const { order } = props;
  const contractUrl = order.commodityContractId ? `#/contracts/${order.commodityContractId}/contract` : '';
  const allocationBasicInfoClass = props.backButtonHandler
    ? 'allocation-details-basic-info-section order-details-status-section basic-info'
    : 'order-details-status-section basic-info';
  const isDelayed = isOrderDelayed(order);
  return (
    <Paper id='order-details-basic-info-card'>
      <div className={allocationBasicInfoClass}>
        {props.backButtonHandler && (
          <Button
            title='Freight Orders List'
            onClick={props.backButtonHandler}
            style={{ color: PRIMARY_COLOR_GREEN, maxWidth: '60px', borderRadius: '0px', borderRight: '1px solid rgb(224,224,224)' }}
          >
            <ArrowBackIcon />
          </Button>
        )}
        <div className='item item1' style={props.backButtonHandler && !isMobileDevice ? { marginLeft: '-26px' } : null}>
          {renderBasedOnStatus(order.status)}
          <span className='field-value'>{order.orderStatusDisplayName}</span>
        </div>
        <div className='item item2'>
          {order.identifier && (
            <div>
              <span className='field-label'>Order No</span>
              <span className='field-value'>
                {
                  isAtMainOrderDetails() ?
                  order.identifier :
                  <a href={getOrderUrl(order.id, get(order, 'typeId'))}>{order.identifier}</a>
                }
              </span>
              {
                !isMobileDevice &&
                  <span className='created-at'>Created: {convertEpochToDateFormat(order.createdAt)}</span>
              }
              {

                order.isAutoCreated &&
                  <span className='created-at' style={{marginTop: '5px'}}><i>(auto-created)</i></span>
              }

            </div>
          )}
          {order.order ? (
            <div>
              <div>
                <span className='field-label'>Order no</span>
                <span className='field-value'>
                  <a href={getOrderUrl(get(order, 'order.id', 'id'))}>
                    {order.order.identifier}
                  </a>
                </span>
                {
                  !isMobileDevice &&
                  <span className='created-at'>Created: {convertEpochToDateFormat(order.order.createdAt)}</span>
                }
                {

                  order.order.isAutoCreated &&
                    <span className='created-at' style={{marginTop: '5px'}}><i>(auto-created)</i></span>
                }
              </div>
            </div>
          ) : null}
        </div>
        {(order.commodityContract || order.externalReferenceNumber) ? (
          <div className='item item2'>
            <div>
              <div>
                <span className='field-label'>Contract No</span>
                <span className='field-value'>
                  {includes(compact([get(order, 'commodityContract.seller.companyId'),
                  get(order, 'commodityContract.seller.representedById'), get(order, 'commodityContract.buyer.companyId'),
                  get(order, 'commodityContract.buyer.representedById')]), props.currentUser.companyId) ? (
                      <a href={contractUrl}>{order.commodityContract.referenceNumber}</a>
                    ) : (
                      get(order, 'commodityContract.referenceNumber') || get(order, 'externalReferenceNumber')
                    )}
                </span>
                {
                  !isMobileDevice && order?.commodityContract?.id &&
                    <span className='created-at'>Created: {convertEpochToDateFormat(get(order, 'commodityContract.createdAt'))}</span>
                }
              </div>
            </div>
          </div>
        ) : null}
        <TonnageDetailWidget {...props} />
        {
          !isMobileDevice && (((order.isVoidRequestPending || (order.isAmendRequestPending && order.shouldDisplaySubStatusForAmend)) && !order.confirmable) || order.hasBeenAmended || isDelayed) ?
          <div className='item item5'>
              {isDelayed && <ChipLabel label='Delayed' />}
              {order.hasBeenAmended && <ChipLabel label='Amended' />}
              {(order.isVoidRequestPending || (order.isAmendRequestPending && order.shouldDisplaySubStatusForAmend)) && (
                <ChipLabel label={order.subStatus} />
              )}
          </div> :
          null
        }
        {
          !isMobileDevice &&
            <div className='status-actions' style={{padding: '0px 0px'}}>
              <OrderDetailsActions {...props} />
            </div>
        }
      </div>
    </Paper>
  );
};

export default OrderDetailsBasicInfo;

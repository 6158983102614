import { connect } from 'react-redux';
import NgrForm from '../components/ngrs/NgrForm';
import { updateNgr } from '../actions/api/ngrs';
import { editNgr, cancelEditNgr } from '../actions/companies/ngrs';
import find from 'lodash/find';

function submit(farmId, data) {
  return (dispatch, getState) => {
    const ngrId = getState().companies.ngrs.selectedNgrId;
    dispatch(updateNgr(farmId, ngrId, data, editNgr));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedNgr: find(
      state.companies.ngrs.items,
      { id: state.companies.ngrs.selectedNgrId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (farmId, data) => dispatch(submit(farmId, data)),
    cancelEdit: () => dispatch(cancelEditNgr()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NgrForm);

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Link extends Component {
  render() {
    return <Tooltip title="Linked Farm" placement="top">
      <i style={this.props.style} className="material-icons link-icon green">
        link
      </i>
    </Tooltip>;
  }
}

export default Link;

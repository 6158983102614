import React from 'react';
import { TextField, InputAdornment } from '@mui/material/';
import { some, forEach, set,  get, isEmpty, cloneDeep, isEqual } from 'lodash';
import { REQUIRED_FIELD } from '../../../common/constants';
import { positiveDecimalFilter } from '../../../common/input-filters';
import CommodityAutoComplete from '../../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../../common/autocomplete/GradeAutoComplete';
import SeasonSelect from '../../common/select/SeasonSelect';
import DisabledCommodityInfo from './DisabledCommodityInfo';
import { required } from '../../../common/validators';

class CommodityInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dependsOnCommodity:true,
      fields: {
        commodityId: cloneDeep(REQUIRED_FIELD),
        varietyId: {
          value: null,
          validators: [],
          errors: []
        },
        gradeId: cloneDeep(REQUIRED_FIELD),
        season: cloneDeep(REQUIRED_FIELD),
        tonnage: cloneDeep(REQUIRED_FIELD),
      }
    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.handleTonnageChange = this.handleTonnageChange.bind(this);
    this.propagateChanges = this.propagateChanges.bind(this);
    this.setAllFieldErrors = this.setAllFieldErrors.bind(this);
    this.hasErrors = this.hasErrors.bind(this);
    this.setFieldError = this.setFieldError.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { validate, updateCommodityFields, URLSelectedEntityId, selectedEntity, isVarietyMandatoryInCommodityDec } = this.props;
    if (prevProps.isVarietyMandatoryInCommodityDec !== isVarietyMandatoryInCommodityDec && isVarietyMandatoryInCommodityDec)
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          varietyId: {
            ...prevState.fields.varietyId,
            validators: [required()]
          }
        }
      }), this.setAllFieldErrors)
    if (prevProps.isVarietyMandatoryInCommodityDec && !isVarietyMandatoryInCommodityDec)
      this.setState((prevState) => ({
        fields: {
          ...prevState.fields,
          varietyId: {
            ...prevState.fields.varietyId,
            validators: [],
            errors: []
          }
        }
      }), this.setAllFieldErrors)
    if (!prevProps.validate && validate) {
      this.setAllFieldErrors();
    }

    if(prevProps.updateCommodityFields != updateCommodityFields || (URLSelectedEntityId && !isEqual(prevProps.selectedEntity, selectedEntity))){
      this.setDefaultValues();
    }
  }

  handleValueChange(value, event) {
    const newState = { ...this.state };
    set(newState.fields, `${event}.value`, value);
    this.setState(newState, () => {this.setFieldError(event);});
  }

  handleTonnageChange(event) {
    const newState = { ...this.state };
    set(newState.fields, `${event.target.id}.value`, event.target.value);
    this.setState(newState, () => {this.setFieldError('tonnage');});
  }

  hasErrors() {
    const { fields } = this.state;
    return some(fields, field => field.errors.length > 0);
  }

  setFieldError(field) {
    const newState = { ...this.state };
    const fieldState = get(newState.fields, field);
    fieldState.errors = this.getFieldErrors(field);
    this.setState(newState, () => {
      this.propagateChanges();
    });
  }

  getFieldErrors(field) {
    let errors = [];
    const fieldState = get(this.state.fields, field);
    if(!fieldState)
      return errors;
    fieldState.validators.forEach(validator => {
      if(validator.isInvalid(fieldState.value))
        errors.push(validator.message);
      else
        errors = [];
    });

    return errors;
  }

  setAllFieldErrors() {
    const newState = { ...this.state };
      forEach(newState.fields, state => {
        state.validators.forEach(validator => {
          if (validator.isInvalid(state.value))
            state.errors.push(validator.message);
          else
            state.errors = [];
        });
      });
      set(newState, 'dependsOnCommodity', true);
      this.setState(newState, this.propagateChanges);
  }

  propagateChanges() {
    this.props.onChange((this.state.fields), {commodity: this.hasErrors(), variety: this.hasErrors()});
  }

  setDefaultValues() {
    const { selectedEntity, isVarietyMandatoryInCommodityDec } = this.props;
    const newState = {...this.state};

    if(!isEmpty(selectedEntity)){
      set(newState, 'dependsOnCommodity', false);
    }
    if(!isVarietyMandatoryInCommodityDec)
      set(newState.fields, 'varietyId.validators', []);

      
    set(newState.fields, 'commodityId.value', get(selectedEntity, 'commodityId', undefined));
    set(newState.fields, 'varietyId.value', get(selectedEntity, 'varietyId'));
    set(newState.fields, 'season.value', get(selectedEntity, 'season', undefined));
    set(newState.fields, 'gradeId.value', get(selectedEntity, 'gradeId', undefined));
    set(newState.fields, 'tonnage.value', get(selectedEntity, 'tonnage'));
    this.setState(newState, () => this.setAllFieldErrors());
  }

  render() {
    const { selectedEntity, fieldRef, isNotIndependent, URLSelectedEntityId } = this.props;
    return (
      <div>
        <h4 className='cardForm-title'>Commodity Details</h4>
        {
          selectedEntity && isNotIndependent || URLSelectedEntityId?
          <DisabledCommodityInfo selectedEntity={selectedEntity}
            handleSpreadGradeChange={this.props.handleSpreadGradeChange}
            gradeId={get(this.props, 'gradeId')}
            varietyErrorText={get(this.state, 'fields.varietyId.errors[0]', '')}
            handleValueChange={this.handleValueChange}/> :
          <div className='cardForm-content'>
            <div className='col-md-6'>
              <div className='col-md-12 form-wrap padding-reset'>
                <CommodityAutoComplete
                  id="commodityId"
                  setRef={fieldRef["commodity.commodityId"]}
                  onChange={this.handleValueChange}
                  floatingLabelText="Commodity"
                  commodityId={this.state.fields.commodityId.value}
                  errorText={get(this.state, 'fields.commodityId.errors[0]', '')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <VarietyAutoComplete
                  id="varietyId"
                  onChange={this.handleValueChange}
                  floatingLabelText="Variety (Optional)"
                  commodityId={this.state.fields.commodityId.value}
                  varietyId={this.state.fields.varietyId.value}
                  dependsOnCommodity={this.state.dependsOnCommodity}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <SeasonSelect
                  id="season"
                  setRef={fieldRef["commodity.season"]}
                  onChange={this.handleValueChange}
                  season={this.state.fields.season.value}
                  errorText={get(this.state, 'fields.season.errors[0]', '')}
                />
              </div>
            </div>
            <div className='col-md-6'>
              <div className='col-md-12 form-wrap padding-reset'>
                <GradeAutoComplete
                  id="gradeId"
                  onChange={this.handleValueChange}
                  floatingLabelText="Grade"
                  setRef={fieldRef["commodity.gradeId"]}
                  commodityId={this.state.fields.commodityId.value}
                  varietyId={this.state.fields.varietyId.value}
                  gradeId={this.state.fields.gradeId.value}
                  season={this.state.fields.season.value}
                  dependsOnCommodity={this.state.dependsOnCommodity}
                  dependsOnSeason
                  selectedGradeId={this.state.fields.gradeId.value}
                  disabled={!this.state.fields.commodityId.value}
                  errorText={get(this.state, 'fields.gradeId.errors[0]', '')}
                />
              </div>
              <div className='col-md-12 form-wrap padding-reset'>
                <TextField
                  variant='standard'
                  error={!isEmpty(this.state.fields.tonnage.errors[0])}
                  id='tonnage'
                  label='Tonnage'
                  value={this.state.fields.tonnage.value || '' }
                  fullWidth
                  inputRef={fieldRef["commodity.tonnage"]}
                  helperText={this.state.fields.tonnage.errors[0]}
                  onChange={this.handleTonnageChange}
                  onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                        MT
                      </InputAdornment>
                    ),
                  }}
                />
              </div>

            </div>
          </div>

        }
      </div>
    );
  }
}

export default CommodityInfo;

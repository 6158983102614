export const START_FETCHING_VARIETIES = 'START_FETCHING_VARIETIES';
export const RESET_IS_FETCHING_VARIETIES = 'RESET_IS_FETCHING_VARIETIES';
export const RECEIVE_VARIETIES = 'RECEIVE_VARIETIES';

export const startFetchingVarieties = () => ({
  type: START_FETCHING_VARIETIES,
});

export const resetIsFetchingVarieties = () => ({
  type: RESET_IS_FETCHING_VARIETIES,
});

export const receiveVarieties = items => ({
  type: RECEIVE_VARIETIES,
  items,
});

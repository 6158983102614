import React from 'react';
import { connect } from 'react-redux';
import { getFarmFields, activateFarmField } from '../../actions/api/farm-fields';
import { receiveArchivedFarmFields } from '../../actions/companies/farm-fields';
import { getCountryConfig } from '../../common/utils';
import { get } from 'lodash';
import GenericTable from '../GenericTable';

class ArchivedFarmFields extends React.Component {

  componentDidMount() {
    this.props.getFarmFields(this.props.farmId);
  }

  render() {
    let columns = [
      { key: 'name', header: 'Field Name', },
      { key: 'marketzone.name', header: 'Market Zone' },
      { key: 'region.name', header: 'Region' },
      { key: 'ownershipDisplay', header: 'Ownership Type' },
      { key: 'size', header: `Size (in ${getCountryConfig()?.farmField?.sizeUnit})` },
      { key: 'address', header: 'Location', map: {
        name: 'address.address',
        lat: 'address.latitude',
        lng: 'address.longitude'
      }},
    ];
    return (
      <GenericTable
        columns={columns}
        items={this.props.farmFields}
        editColumnClass="xsmall"
        orderBy="siteName"
        optionsItems={[{key : 'activate', text: 'Activate' }]}
        handleOptionClick={(index,key,id, item) => this.props.handleOptionClick(key, item)}
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    farmFields: get(state.companies.farmFields, 'archivedItems'),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getFarmFields: (farmId) => dispatch(getFarmFields(farmId, receiveArchivedFarmFields, '?is_active=false')),
    handleOptionClick: (key, item) => {
      if (key === 'activate') {
        dispatch(activateFarmField(item))
      }
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedFarmFields);

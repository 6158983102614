import axios from "axios";
import { forceStopLoader } from '../main';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version

export const RECEIVE_FARMS = 'RECEIVE_FARMS';
export const RECEIVE_PAGINATED_FARMS = 'RECEIVE_PAGINATED_FARMS';
export const ADD_FARM = 'ADD_FARM';
export const EDIT_FARM = 'EDIT_FARM';
export const CLICK_ADD_FARM_BUTTON = 'CLICK_ADD_FARM_BUTTON';
export const ADD_FARM_ERRORS = 'ADD_FARM_ERRORS';
export const EDIT_FARM_ERRORS = 'EDIT_FARM_ERRORS';
export const GET_SELECTED_FARM = 'GET_SELECTED_FARM';
export const RECEIVE_FARM = 'RECEIVE_FARM';
export const CLICK_OPTION_FOR_FARM_IN_TABLE = 'CLICK_OPTION_FOR_FARM_IN_TABLE';
export const HIDE_CREATE_FARM_FORM = 'HIDE_CREATE_FARM_FORM';
export const FARM_ACCEPTED = 'FARM_ACCEPTED';
export const FARM_REJECTED = 'FARM_REJECTED';
export const FARM_SIDEDRAWER_TRANSFER = 'FARM_SIDEDRAWER_TRANSFER';
export const RECEIVE_MY_STOCKS = 'RECEIVE_MY_STOCKS';

export const receivePaginatedFarms = items => ({
  type: RECEIVE_PAGINATED_FARMS,
  items,
});

export const receiveFarms = items => ({
  type: RECEIVE_FARMS,
  items,
});

export const addFarm = item => ({
  type: ADD_FARM,
  item,
});

export const editFarm = item => ({
  type: EDIT_FARM,
  item,
});

export const clickAddFarmButton = () => ({
  type: CLICK_ADD_FARM_BUTTON,
});

export const addFarmErrors = errors => ({
  type: ADD_FARM_ERRORS,
  errors,
});

export const editFarmErrors = errors => ({
  type: EDIT_FARM_ERRORS,
  errors,
});

export const receiveFarm = item => ({
  type: RECEIVE_FARM,
  item,
});

export const clickOptionForFarmInTable = farm => ({
  type: CLICK_OPTION_FOR_FARM_IN_TABLE,
  farm,
});

export const hideCreateFarmForm = () => ({
  type: HIDE_CREATE_FARM_FORM,
});

export const farmAccepted = item => ({
  type: FARM_ACCEPTED,
  item,
});

export const farmRejected = farmId => ({
  type: FARM_REJECTED,
  farmId,
});

export const farmSideDrawerForTransfer = (item, farmSideDrawer) => {
  {
  return ({
    type: FARM_SIDEDRAWER_TRANSFER,
    item,
    farmSideDrawer,
  });
}};

export const receiveMyStocksLoads = (items, farmId) => ({
  type: RECEIVE_MY_STOCKS,
  items,
  farmId,
});

export const getOwnershipStocksLoads = (url, _forceStopLoader) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    let farmId = getState().companies.farms.selectedFarmId;
    if(!farmId && !url)
      return;

    axios.get(url, config)
      .then((response) => {
        dispatch(receiveMyStocksLoads(response?.data, farmId))
        if (_forceStopLoader)
          dispatch(forceStopLoader())
      })
      .catch((error) => {
        throw (error);
      });
  };
}


import React from 'react';
import { map, isEqual } from 'lodash';

import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect';
import SeasonSelect from "../common/select/SeasonSelect";

const NgrDeclarationForm = ({selectedSeason, selectedCommodities, index, allCommodities, onChange}) => {
  const [season, setSeason] = React.useState(selectedSeason || '')
  const [commodities, setCommodities] = React.useState(selectedCommodities || [])

  React.useEffect(() => {
    if(season !== selectedSeason && selectedSeason)
      setSeason(selectedSeason)
    if(!isEqual(commodities, selectedCommodities) && selectedCommodities?.length)
      setCommodities(selectedCommodities)
  }, [selectedSeason, selectedCommodities])

  const onSeasonChange = value => {
    const newSeason = value || ''
    setSeason(newSeason)
    onChange({season: newSeason, commodities: commodities})
  }
  const onCommoditiesChange = (id, _commodities) => {
    const commodityIds = map(_commodities, 'id')
    setCommodities(commodityIds)
    onChange({season: season, commodities: commodityIds})
  }
  return (
    <div className='col-xs-12 padding-reset' index={index}>
      <div className='col-xs-4 no-left-padding' style={{marginTop: '15px'}}>
        <SeasonSelect
          id="season"
          floatingLabelText="Season"
          onChange={onSeasonChange}
          season={season}
        />
      </div>
      <div className='col-xs-8 no-right-padding' style={{marginTop: commodities?.length ? '8px': '15px'}}>
        <CommodityMultiSelect
          onChange={onCommoditiesChange}
          commodities={allCommodities}
          selectedCommodities={commodities}
          label="Commodities"
          variant='standard'
          containerStyle={{marginTop: '0px'}}
          style={{marginTop: '0px'}}
        />
      </div>
    </div>
  )
}

export default NgrDeclarationForm

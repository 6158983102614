import React from 'react';
import { Chip, Menu, MenuItem } from '@mui/material';
import { Done, ArrowDropDown } from '@mui/icons-material';
import { isElement, map } from 'lodash';


const OPTIONS = [{id: 'all', name: 'All Slots'}, {id: 'outload', name: 'Pickups'}, {id: 'inload', name: 'Deliveries'}]

class CheckpointFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selected: 'all',
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    this.setState({selected: this.props.value}, () => {
      document.addEventListener('mousedown', this.handleClickOutside);
    })
  }

  componentDidUpdate() {
    const { value } = this.props;
    const { selected } = this.state;
    if(value?.length && value !== selected)
      this.setState({selected: value});
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const el = document.getElementById('checkpoint-options');

    if(isElement(el) && !el.contains(event.target))
      this.close();
  }

  setAnchorEl = event => this.setState({anchorEl: event.currentTarget});

  close = () => this.setState({anchorEl: null});

  afterSelect() {
    this.close();
    if(this.props.onChange)
      this.props.onChange(this.state.selected);
  }

  handleSelect(value) {
    this.setState({selected: value}, this.afterSelect)
  }

  render() {
    const { selected, anchorEl } = this.state;
    const selectedOption = OPTIONS.find(option => selected === option.id)
    return (
      <span style={{margin: '0 1px'}}>
        <Chip
          id="checkpoint-options"
          label={selectedOption?.name || 'All Slots'}
          deleteIcon={<ArrowDropDown />}
          variant="outlined"
          clickable
          onDelete={this.setAnchorEl}
          onClick={this.setAnchorEl}
          style={{minWidth: '100px'}}
          color="secondary"
        />
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}>
          {
            map(OPTIONS, option => (
              <MenuItem selected={selected === option.id} style={{fontSize: '14px', padding: '5px 15px'}} key={option.id} onClick={() => this.handleSelect(option.id) }>
                <span style={{width: '90%', marginRight: '10px'}}>
                  {option.name}
                </span>
                {
                  selected === option.id &&
                  <span style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: '5px'}}>
                    <Done color='primary' fontSize='small' />
                  </span>
                }
              </MenuItem>
            ))
          }
        </Menu>
      </span>

    );
  }
}

export default CheckpointFilter;

import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import { get, includes } from 'lodash';
import FreightMovements from './FreightMovements';
import PackMovements from './PackMovements';

class Movements extends React.Component {
  constructor(props) {
    super(props);

    this.FREIGHT_MOVEMENTS_URL = '/movements/freights';
    this.PACK_MOVEMENTS_URL = '/movements/pack';

    this.state = {
      value: '#' + this.props.location.pathname,
    };
    this.contractId = get(this.props.match, 'params.contract_id');
    this.parentURL = this.getParentURL();
    this.setValueBasedOnPath = this.setValueBasedOnPath.bind(this);
  }

  getParentURL() {
    let url = this.props.location.pathname;
    if(includes(this.props.location.pathname, this.FREIGHT_MOVEMENTS_URL))
      return url.split(this.FREIGHT_MOVEMENTS_URL)[0];
    if(includes(this.props.location.pathname, this.PACK_MOVEMENTS_URL))
      return url.split(this.PACK_MOVEMENTS_URL)[0];
  }

  componentDidUpdate() {
    setTimeout(this.setValueBasedOnPath, 500);
  }

  setValueBasedOnPath() {
    const currentUrl = `#${this.props.location.pathname}`;
    if(this.state.value !== currentUrl)
      this.setState({value: currentUrl});
  }

  getURL(pathname) {
    const parentURL = this.getParentURL();

    return '#' + parentURL + pathname;

  }

  tabChanges = (event, value) => this.setState({ value });

  render() {
    const { value } = this.state;
    const freightMovementsURL = this.getURL(this.FREIGHT_MOVEMENTS_URL);
    const packMovementsURL = this.getURL(this.PACK_MOVEMENTS_URL);
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={freightMovementsURL} href={freightMovementsURL} label="Freight Movements" className={value !== freightMovementsURL ? 'unselected-subtab' : ''} />
          <Tab value={packMovementsURL} href={packMovementsURL} label="Pack Movements" className={value !== packMovementsURL ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          value === freightMovementsURL && <FreightMovements {...this.props} />
        }
        {
          value === packMovementsURL && <PackMovements {...this.props} />
        }

      </div>
    );
  }
}

export default Movements;

import findIndex from 'lodash/findIndex';
import reject from 'lodash/reject';

import {
  RECEIVE_FARMS,
  RECEIVE_PAGINATED_FARMS,
  ADD_FARM,
  EDIT_FARM,
  CLICK_ADD_FARM_BUTTON,
  ADD_FARM_ERRORS,
  EDIT_FARM_ERRORS,
  RECEIVE_FARM,
  CLICK_OPTION_FOR_FARM_IN_TABLE,
  HIDE_CREATE_FARM_FORM,
  FARM_ACCEPTED,
  FARM_REJECTED,
  FARM_SIDEDRAWER_TRANSFER,
  RECEIVE_MY_STOCKS,
} from '../../actions/companies/farms';

const initialState = {
  items: [],
  selectedFarmId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
  selectedFarm: null,
  paginationData: null,
  stocksPaginationData: null,
  myStocksLoads: [],
  farmSideDrawer:false,
  selectedAsset:undefined,

};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FARMS:
      return { ...state, items: action.items };
    case RECEIVE_PAGINATED_FARMS:
      return {
        ...state,
        items: action.items.results,
        paginationData: action.items,
      };
    case ADD_FARM: {
      const index = findIndex(state.items, { id: action.item.id });
      let items;
      if(index > -1) {
        items = state.items.slice();
        items.splice(index, 1, action.item);
      } else {
        items = [action.item, ...state.items];
      }
      return {
        ...state,
        items: items,
        isCreateFormDisplayed: false,
      };
    }
    case EDIT_FARM: {
      const index = findIndex(state.items, { id: action.item.id });
      let items = state.items.slice();
      items.splice(index, 1, action.item);
      return {
        ...state,
        items: items,
        selectedFarmId: action.item.id,
        selectedFarm: action.item,
      };
    }
    case CLICK_ADD_FARM_BUTTON:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedFarm: null,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case ADD_FARM_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case EDIT_FARM_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case RECEIVE_FARM:
      return {
        ...state,
        selectedFarmId: action.item ? action.item.id : null,
        selectedFarm: action.item,
      };
    case CLICK_OPTION_FOR_FARM_IN_TABLE: {
      return {
        ...state,
        selectedFarmId: action.farm.id,
        selectedFarm: action.farm,
      };
    }
    case HIDE_CREATE_FARM_FORM:
      return {
        ...state,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    case FARM_ACCEPTED: {
      const index = findIndex(state.items, { id: action.item.id });

      return {
        ...state,
        selectedFarmId: action.item ? action.item.id : null,
        selectedFarm: action.item,
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
      };
    }
    case FARM_REJECTED:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedFarm: null,
        items: reject(state.items, { id: action.farmId }),
      };
      case FARM_SIDEDRAWER_TRANSFER:
        return {
          ...state,
          selectedAsset: action.item,
          farmSideDrawer: action.farmSideDrawer
        };
    case RECEIVE_MY_STOCKS:
      return {
        ...state,
        myStocksLoads: action.items.results,
        stocksPaginationData: action.items,
        selectedFarmId: action.farmId,
      };
    default:
      return state;
  }
};

export default reducer;

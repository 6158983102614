import React from 'react';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LoaderInline from '../LoaderInline';
import get from 'lodash/get';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';

class AllocatedContractWarningDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogList: false,
    };
  }
  handleDialog(value) {
    const { newOrderButton, handleToUpdate } = this.props;
    switch (value) {
      case 1:
        newOrderButton();
        handleToUpdate();
        break;
      case 2:
        this.setState({ dialogList: true });
        break;
      case 3:
        this.setState({ dialogList: false });
        break;
    }
  }
  closePopup = () => {
    const { handleToUpdate } = this.props;
    this.setState({ dialogList: false }, handleToUpdate);
  };

  redirectLink = (id) => {
    const orderType = this.props.isCog ? 'grain' : 'orders'
    if (get(this.props.contract, 'isSaleContract'))
      window.location.hash = `#/freights/${orderType}/new?commodityContractId=${this.props.contractId}&purchaseContractId=${id}`
    else
      window.location.hash = `#/freights/${orderType}/new?commodityContractId=${this.props.contractId}&saleContractId=${id}`
    this.props.handleToUpdate();
  }

  render() {
    const { orderWarningList } = this.props;
    const { dialogList } = this.state;
    const ListDialog = ({ orderWarningList, onClose, onSelect, onBack }) => (
      <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title">
        <DialogTitleWithCloseIcon onClose={onClose} style={{ marginBottom: '10px', height: '58px', paddingTop: '12px' }}>
          Select contract allocation from the following list:
        </DialogTitleWithCloseIcon>
        <DialogContent dividers>
          <DialogContentText>
            {
              orderWarningList.length === 0 &&
              <div style={{ minHeight: '75px' }}>
                <LoaderInline
                  imageStyle={{ width: '17%' }}
                  containerClassName='inline-loader-container'
                />
              </div>
            }
          </DialogContentText>
          <Table style={{ minWidth: '500px' }}>
            <TableBody>
              {
                orderWarningList.map(row => (
                  <TableRow
                    key={row.id}
                    hover
                    onClick={() => onSelect(row.id)}
                    style={{ cursor: 'pointer' }}>
                    {
                      row.displayName.split(" - ").map((field, index) => (
                        <TableCell
                          key={index}
                          align="left"
                          width="60px"
                          style={{ cursor: 'pointer' }}>
                          {field}
                        </TableCell>
                      ))}
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            type='button'
            onClick={onBack}
            color='primary'
            className='add-button'
            style={{ float: 'left', marginLeft: '4px', height: '35px', }}>
            Back
          </Button>
        </DialogActions>
      </Dialog>
    );

    const WarningDialog = ({ onClose, onNoClick, onYesClick }) => (
      <Dialog open={true} onClose={onClose}>
        <DialogTitleWithCloseIcon
          onClose={onClose}
          style={{ marginBottom: '10px', height: '58px', paddingTop: '12px' }}>
          Warning!
        </DialogTitleWithCloseIcon>
        <DialogContent dividers style={{ borderBottom: ' 1px solid #e6e6e6' }}>
          <DialogContentText>
            This contract has at least one allocation. Are you sure you want to create an order independent of an allocation?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onNoClick} color="primary">
            No, create against allocation
          </Button>
          <Button onClick={onYesClick} color="primary">
            Yes, create independent order
          </Button>
        </DialogActions>
      </Dialog>
    );
    if (dialogList) {
      return (
        <ListDialog
          orderWarningList={orderWarningList}
          onClose={this.closePopup}
          onSelect={this.redirectLink}
          onBack={() => this.handleDialog(3)}
        />
      );
    }
    else {
      return (
        <WarningDialog
          onClose={this.closePopup}
          onNoClick={() => this.handleDialog(2)}
          onYesClick={() => this.handleDialog(1)}
        />
      );
    }
  }
};

export default AllocatedContractWarningDialog;

import React from 'react'
import { Chip } from '@mui/material'
import { isEmpty, get, merge } from 'lodash'
import { AppContext } from '../main/LayoutContext';

const FiltersAppliedChip = ({ show, filters, style }) => {
  const { isMobileDevice } = React.useContext(AppContext);
  const shouldShow = !isMobileDevice && show && !isEmpty(Object.entries(filters).filter(val => get(val, '1.length', 0) !== 0))
  return shouldShow ? (
    <div style={merge({float: 'right', marginLeft: '10px', paddingRight: '10%', paddingTop: '2px'}, (style || {}))}>
      <Chip variant='outlined' color='warning' label='Filters Applied' />
    </div>
  ) : <React.Fragment />
}

export default FiltersAppliedChip;

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Unlink extends Component {
  render() {
    return <Tooltip title={this.props.style.fill ? "Farm not in your directory" : "Unlinked Farm"} placement="top">
      <svg style={this.props.style} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
        <g fill="none" fillRule="evenodd">
          <path d="M25.906 7.52L10.479 25.907-7.906 10.479 7.521-7.906z"/>
          <path fill={this.props.style.fill || "#F44337"} d="M14.207 2.795a3.103 3.103 0 0 1 .382 4.367l-2.571 3.065 1.455 1.22 2.571-3.063a5.002 5.002 0 0 0-.616-7.044 5.002 5.002 0 0 0-7.044.616L5.813 5.02l1.455 1.221 2.571-3.064a3.103 3.103 0 0 1 4.368-.382zm-4.59 13.25l2.57-3.065-1.455-1.221-2.571 3.064a3.103 3.103 0 0 1-4.368.382 3.103 3.103 0 0 1-.382-4.367l2.571-3.065-1.455-1.22-2.571 3.063a5.002 5.002 0 0 0 .616 7.044 5.002 5.002 0 0 0 7.044-.616z"/>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default Unlink;

import React, {Component} from 'react';

import Button from "@mui/material/Button/Button";
import withStyles from '@mui/styles/withStyles';
import { Settings as SettingsIcon } from '@mui/icons-material'

const styles = () => ({
  settingBtn:{
    minHeight: '20px',
    padding: '0 5px',
    margin:'-5px 0px 0px 5px'
  },
  settingBtnText:{
    textTransform: 'capitalize',
    padding: '0 5px',
    fontSize: '12px !important',
  }
});


class SettingsButton extends Component {
  render() {
    const { classes } = this.props;
    return <Button variant="outlined" onClick={this.props.onClick} href={this.props.href} size="small" className={classes.settingBtn} color="black">
      <SettingsIcon fontSize='small' />
      <span className={classes.settingBtnText}>{this.props.buttonText}</span>
    </Button>;
  }
}

export default withStyles(styles)(SettingsButton);

import React from 'react';
import {
  Dialog, DialogContent, DialogTitle, DialogActions, Button, TextField, CircularProgress,
  Autocomplete, Typography, Chip
} from '@mui/material';
import {
  Cancel as CancelIcon
} from '@mui/icons-material/';
import { debounce, filter, compact, includes, some, map } from 'lodash';
import APIService from '../../../services/APIService';
import { getCountryLabel } from '../../../common/utils';

const ManageTruck = ({ open, onClose, onClick, isAddTruck, managedTrucks, unsavedMovements }) => {
  const [input, setInput] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [trucks, setTrucks] = React.useState([]);
  const [selected, setSelected] = React.useState([]);
  const [nonRemovableTrucks, setNonRemovableTrucks] = React.useState([]);
  const isSearchable = input.length > 1;
  const fetchTrucks = debounce(searchStr => {
    APIService.trucks().appendToUrl(`global-search/${searchStr}/?is_active=true`).get().then(res => {
      setTrucks(res.trucks);
      setLoading(false);
    });
  });
  const onInputChange = (event, value) => {
    setInput(value);
    if(value.length > 1) {
      if(isAddTruck) {
        setLoading(true);
        fetchTrucks(value);
      }
    }
  };
  const onChange = (event, items) => setSelected(items);
  
  const shouldHighlight = truck => {
    if (isAddTruck) 
      return false;
    
    let truckUnsavedMovement = filter(unsavedMovements, movement => movement.plannedTruckId === truck.id)
    if (!includes(nonRemovableTrucks, truck?.id) && truckUnsavedMovement.length > 0)
      setNonRemovableTrucks(compact([...nonRemovableTrucks, truck?.id]));
    return truckUnsavedMovement.length > 0 ;
  }

  return (
    <React.Fragment>
      <Dialog onClose={onClose} open={open}>
        <DialogTitle onClose={onClose}>
          {isAddTruck ? 'Add Trucks' : 'Remove Managed Trucks'}
        </DialogTitle>
        <DialogContent style={{minWidth: '500px'}}>
          <Autocomplete
            multiple
            id={isAddTruck ? "add-truck" : "remove-truck"}
            blurOnSelect
            options={isAddTruck ? trucks : managedTrucks}
            getOptionLabel={option => option.name || `${option.rego}, ${option?.companyName}`}
            loading={loading}
            loadingText={isSearchable ? 'Loading...' : 'Type at least 2 characters to search'}
            noOptionsText={input || !isAddTruck ? "No results" : 'Start typing...'}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => {
                return (
                <Chip
                  {...getTagProps({ index })}
                  key={option.id} 
                  label={option.name || `${option.rego}, ${option?.companyName}`}
                  style={{
                    backgroundColor: shouldHighlight(option) ? 'rgba(211, 47, 47, 0.2)' : 'default',
                  }}
                  deleteIcon={ <CancelIcon /> }
                />
                )
            })
            }
            renderInput={params => (
              <TextField
                {...params}
                value={input}
                variant="outlined"
                placeholder={isAddTruck ? `Truck ${getCountryLabel('rego').toLowerCase()} (Type at least 2 characters to search)` : `Truck ${getCountryLabel('rego').toLowerCase()}`}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                fullWidth
                FormHelperTextProps={{ style: { marginLeft: '0px' } }}
                helperText={isAddTruck ? '' : 
                <React.Fragment>
                {
                  some(map(selected, truck => truck.id), truckId => includes(nonRemovableTrucks, truckId)) && 
                  <Typography style ={{ fontSize: '13px', marginTop: '5px' }} component="div" color="warning">The trucks highlighted in red will not be removed as there are draft movements against them. Please delete the movements to remove those trucks.</Typography>
                }
                </React.Fragment>
                }
              />
            )}
            value={selected}
            onChange={onChange}
            onInputChange={onInputChange}
            
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={() => onClick(isAddTruck ? selected : filter(selected, truck => !includes(nonRemovableTrucks, truck?.id)))} color='primary'>
            {isAddTruck ? 'Add' : 'Remove'}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default ManageTruck;

import React from 'react';
import {
  SLOT_PLANNED, SLOT_BOOKED, SLOT_COMPLETED, SLOT_CANCELLED, SLOT_IN_PROGRESS, CORNFLOWER_BLUE, BROWN, SLOT_DELAYED, MAUVE
} from '../../../common/constants';
import { RGBtoRGBA } from '../../../common/utils';
import SiteConnector from './SiteConnector';
import { CommonToolTip } from '../../common/CommonToolTip';
import TooltipTemplate from './TooltipTemplate';
import TruckIcon from '../../common/icons/Truck';

const BORDER_COLORS = {
  planned: SLOT_PLANNED,
  confirmed: BROWN,
  open: SLOT_BOOKED,
  in_progress: SLOT_IN_PROGRESS,
  delayed: SLOT_DELAYED,
  'void': SLOT_CANCELLED,
  delivered: CORNFLOWER_BLUE,
  completed: SLOT_COMPLETED,
  invoiced: MAUVE,
};

const OrderTemplate = ({ order, onSelect, isSelected, fadeOut, actualRemainingTonnage}) => {
  const [selected, setSelected] = React.useState(isSelected);
  const color = BORDER_COLORS[order.status];
  const bgColor = (color && color.startsWith('rgba')) ? '#FFF' : RGBtoRGBA(color, '0.2');

  React.useEffect(() => setSelected(isSelected), [isSelected]);

  const onOrderSelect = () => {
    setSelected(!selected);
    onSelect(order);
  };

  const style = {
    borderRadius: '5px',
    textAlign: 'left',
    border: selected ? `3px solid ${color}` : `1px solid ${color}`,
    fontSize: '14px',
    padding: '0 4px',
    backgroundColor: bgColor,
    opacity: fadeOut ? 0.5 : 1
  };

  return (
    <CommonToolTip
      placement='right'
      arrow
      title={<TooltipTemplate type='order' order={order} actualRemainingTonnage={actualRemainingTonnage} />}
    >
      <div className='col-sm-12 col-xs-12 order'  style={style} onClick={onOrderSelect}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '-2px'}}>
          <span className='ellipsis searchable'><b>{order.customer}</b></span>
          <span style={{fontSize: '12px'}}>{actualRemainingTonnage.toFixed(1)} MT</span>
        </div>
        {
        order.providerName &&
            <div style={{display: 'flex', alignItems: 'center', margin: '2px 0'}}>
              <span style={{display: 'flex'}}><TruckIcon fill='#000' width="16px" height="16px" /></span>
              <span className='ellipsis searchable' style={{marginLeft: '5px'}}>{order.providerName}</span>
            </div>
        }
        <div style={{fontSize: '12px'}} className='ellipsis searchable'>
          <a href={`/#/freights/orders/${order.id}/order`} target='_blank' rel='noopener noreferrer'>
            {`${order.identifier} (${order.commodity}-${order.plannedGrade})`}
          </a>
        </div>
        <div style={{fontSize: '12px'}} className='ellipsis searchable'>
         {
            order.canViewContract ?
            <a href={`/#/contracts/${order.commodityContractId}/contract`} target='_blank' rel='noopener noreferrer'>
              {order.contractIdentifier}
            </a> :
            order.contractIdentifier
         }
        </div>
        <div style={{fontSize: '12px'}} className='ellipsis'>
          {order.tonnage} MT
        </div>
        <div style={{display: 'flex', alignItems: 'center'}}>
          <div style={{width: '4%', marginRight: '3%'}}>
            <SiteConnector />
          </div>
          <div style={{width: '93%'}}>
            <div style={{fontSize: '12px'}} className='ellipsis searchable'>
              {order.consignorName}
            </div>
            <div style={{fontSize: '12px'}} className='ellipsis searchable'>
              {order.consigneeName}
            </div>
          </div>
        </div>
      </div>
    </CommonToolTip>
  );
};

export default OrderTemplate;

import React from 'react';
import { connect } from 'react-redux';

import { setHeaderText, setBreadcrumbs } from '../../../actions/main';
import { getCountryLabel } from '../../../common/utils'
import '../actionCentre.scss';
import isNumber from 'lodash/isNumber';
import MovementCompletionGroup from "./MovementCompletionGroup";
import MovementVoidRequestsPendingGroup from "./MovementVoidRequestsPendingGroup";
import MovementAmendRequestPendingGroup from "./MovementAmendRequestPendingGroup";
import MovementAcceptancePendingGroup from "./MovementAcceptancePendingGroup";
import MovementLoadDetails from './MovementLoadDetails';

class MovementsActionCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      movementItems1: 0,
      movementItems2: 0,
      movementItems3: 0,
      movementItems4: 0,
      movementItems5: 0,
      deliveredMovement: [],
      isFetching: true,
    };

    this.onMovementsDataLoad1 = this.onMovementsDataLoad1.bind(this);
    this.onMovementsDataLoad2 = this.onMovementsDataLoad2.bind(this);
    this.onMovementsDataLoad3 = this.onMovementsDataLoad3.bind(this);
    this.onMovementsDataLoad4 = this.onMovementsDataLoad4.bind(this);
  }

  componentDidMount() {
    this.props.onMount();
    this.props.dispatch(setHeaderText(getCountryLabel('actionCentre')));
    this.props.dispatch(setBreadcrumbs([{text: getCountryLabel('actionCentre')}]));
  }

  componentDidUpdate() {
    this.props.onParentDataLoad(this.getMovementsItemSum());
  }


  onMovementsDataLoad1(count) {
    this.setState({movementItems1: isNumber(count) ? count : this.props.existingCount});
  }

  onMovementsDataLoad2(count) {
    this.setState({movementItems2: isNumber(count) ? count : this.props.existingCount});
  }

  onMovementsDataLoad3(count) {
    this.setState({movementItems3: isNumber(count) ? count : this.props.existingCount});
  }

  onMovementsDataLoad4(count) {
    this.setState({movementItems4: isNumber(count) ? count : this.props.existingCount});
  }

  onMovementsDataLoad5 = count => {
    this.setState({movementItems5: isNumber(count) ? count : this.props.existingCount});
  };


  getMovementsItemSum() {
    const sum = this.state.movementItems1 + this.state.movementItems2 + this.state.movementItems3 +
      this.state.movementItems4 + this.state.movementItems5;
    return (sum || (sum === 0 && this.props.existingCount === 1)) ? sum : this.props.existingCount;
  }

  render() {
    return (
      <div className='action-centre-group-container'>
          {
            <div>
              <MovementAcceptancePendingGroup onDataLoad={this.onMovementsDataLoad1}/>
              <MovementAmendRequestPendingGroup onDataLoad={this.onMovementsDataLoad2}/>
              <MovementVoidRequestsPendingGroup onDataLoad={this.onMovementsDataLoad3}/>
              <MovementCompletionGroup onDataLoad={this.onMovementsDataLoad4}/>
              <MovementLoadDetails onDataLoad={this.onMovementsDataLoad5}/>
            </div>
          }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
});

export default connect(mapStateToProps)(MovementsActionCentre);

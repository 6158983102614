import React from 'react';
import moment from 'moment';
import { isEqual, sumBy, map, find, snakeCase, filter, get } from 'lodash';
import {
  getCountryConfig,
} from '../../common/utils';
import './TonnageDistribution.scss';

const STATUSES = ['planned', 'booked', 'in_progress', 'completed', 'cancelled', 'rejected'];

class TonnageDistribution extends React.Component {
  shouldComponentUpdate(nextProps) {
    return !isEqual(nextProps.schedule, this.props.schedule) || (
      !isEqual(get(nextProps.startDate, '_date'), get(this.props.startDate, '_date')) || !isEqual(get(nextProps.endDate, '_date', get(this.props.endDate, '_date')))
    );
  }

  distribution() {
    const { schedule, startDate, endDate } = this.props;
    if(schedule, startDate, endDate) {
      const start = moment(startDate.toDate()).startOf('day').toDate()
      const end = moment(endDate.toDate()).endOf('day').toDate()
      return map(STATUSES, status => {
        return {
          ...this.getStatusSettings(status),
          tonnage: sumBy(filter(schedule, slot => !slot.raw.slot.isTrailerSlot && slot.raw.slot.status === status && slot.start >= start && slot.start <= end), 'raw.slot.tonnage') || 0
        };
      });
    }
    return [];
  }


  getStatusSettings(status) {
    const { settings } = this.props;
    if (!settings)
      return {};
    return find(settings.statuses, {id: snakeCase(status)}) || {};
  }

  render() {
    const unit = getCountryConfig()?.truck?.unit || 'MT'
    const distribution = this.distribution();
    return (
      <div className='col-md-12 tonnage-distribution'>
      {
        map(distribution, data => {
          return (
            <div className='col-md-2 tonnage-info' key={data.label} style={{backgroundColor: data.color}}>
              <span className='tonnage-label'>
                {data.label}
              </span>
              <span className='tonnage-value'>
                {(data.tonnage || 0).toFixed(2)} {unit}
              </span>
            </div>
          );
        })
      }
      </div>
    );
  }
}

export default TonnageDistribution;

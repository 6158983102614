import alertifyjs from 'alertifyjs';
import axios from 'axios';
import { apiURL } from '../main';
import APIService from '../../services/APIService';
import { isLoading, forceStopLoader } from '../main';
import { getLoggedInUserDetails } from '../api/employees';
import { get, isEmpty, isArray } from 'lodash';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
const MESSAGES = {
  CREATE_SUCCESS: 'Company was successfully added',
  UPDATE_SUCCESS: 'Company details were successfully updated',
};

export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANY_NAMES_SUCCESS = 'GET_COMPANY_NAMES_SUCCESS';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';
export const VISIT_COMPANY_DETAILS = 'VISIT_COMPANY_DETAILS';
export const RECIEVE_COMPANY_DETAILS = 'RECIEVE_COMPANY_DETAILS';
export const UPDATE_COMPANY_DETAILS = 'UPDATE_COMPANY_DETAILS';
export const RECEIVE_BROKER_COMPANIES = 'RECEIVE_BROKER_COMPANIES';
export const GET_COMPANY_COMPANIES_SUCCESS = 'GET_COMPANY_COMPANIES_SUCCESS';
export const SHOW_SIDEDRAWER_TRANSFER = 'SHOW_SIDEDRAWER_TRANSFER';
export const SHOW_DAILY_REPORTS = 'SHOW_DAILY_REPORTS';
export const IS_FROM_COMPANY_DIRECTORY = 'IS_FROM_COMPANY_DIRECTORY';
export const SHOW_SIDEDRAWER_MERGE = 'SHOW_SIDEDRAWER_MERGE';
export const SET_TABLE_COLUMNS = 'SET_TABLE_COLUMNS';
export const GET_COMPANY_GROUPS_SUCCESS = 'GET_COMPANY_GROUPS_SUCCESS';
export const GET_COMPANY_GROUPS = 'GET_COMPANY_GROUPS'

export const getCompaniesResponse = companies => {
  return { type: GET_COMPANIES_SUCCESS, companies };
};

export const getCompaniesNames = companies => {
  return { type: GET_COMPANY_NAMES_SUCCESS, companies };
};

export const addCompanyFailure = errors => {
  return { type: ADD_COMPANY_FAILURE, errors };
};

export const updateCompanyFailure = errors => {
  return { type: UPDATE_COMPANY_FAILURE, errors };
};

export const visitCompanyDetails = companyId => ({
  companyId,
  type: VISIT_COMPANY_DETAILS,
});

export const receiveCompanyDetails = item => ({
  type: RECIEVE_COMPANY_DETAILS,
  item,
});

export const receiveBrokerCompanies = brokerCompanies => ({
  type: RECEIVE_BROKER_COMPANIES,
  brokerCompanies,
});

export const updateCompanyDetails = item => ({
  type: UPDATE_COMPANY_DETAILS,
  item,
});

export const getCompanyCompaniesResponse = companies => {
  return { type: GET_COMPANY_COMPANIES_SUCCESS, companies };
};
export const getCompanyGroupsResponse = companies => {
  return { type: GET_COMPANY_GROUPS_SUCCESS, companies };
}

export const getGroupsResponse = groups => {
  return { type: GET_COMPANY_GROUPS, groups}
}

export const isFromCompanyDirectory = flag => ({
  type: IS_FROM_COMPANY_DIRECTORY,
  flag,
})

export const setTableColumns = headers => ({
  type: SET_TABLE_COLUMNS,
  headers,
});

export const addCompany = payload => (dispatch, getState) => {
  fetch(`${apiURL}/companies/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (json.id == null) {
        dispatch(addCompanyFailure(json.errors));
      } else {
        alertifyjs.success(MESSAGES.CREATE_SUCCESS, 2, () => {
          document.location = '#/companies';
        });
      }
    });
};

export const getCompanyDetails = (companyId, stopLoader=false) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios
      .get(`${apiURL}/companies/` + companyId + '/', config)
      .then(response => {
        dispatch(receiveCompanyDetails(response.data));
        if(stopLoader)
          dispatch(forceStopLoader());
      })
      .catch(error => {
        throw error;
      });
  };
};

export const updateCompany = (companyId, data, callback) => (dispatch, getState) => {
  dispatch(isLoading(null, 10000));
  const { token, user } = getState().main.user;
  APIService.companies(companyId)
    .put(data, token)
    .then(item => {
      dispatch(forceStopLoader());
      if (!isEmpty(item.errors)) {
        if (isArray(item.errors)) {
          alertifyjs.error(item.errors[0]);
        } else {
          dispatch(updateCompanyFailure(item.errors));
        }
      } else {
        if (companyId === user.companyId) {
          dispatch(getLoggedInUserDetails());
        }
        dispatch(forceStopLoader());
        dispatch(receiveCompanyDetails(item));
        dispatch(updateCompanyDetails(item));
        if (callback) {
          callback();
        }
      }
    });
};

export const markRegister = companyId => (dispatch, getState) => {
  const { token } = getState().main.user;
  const service = APIService.companies(companyId);
  service.appendToUrl('register/');
  service.put({ register: true }, token).then(() => {
    alertifyjs.success('Company Successfully Registered!', 2, () => {
      window.location.reload();
    });
  });
};

export const unregister = companyId => (dispatch, getState) => {
  const { token } = getState().main.user;
  const service = APIService.companies(companyId);
  service.appendToUrl('unregister/');
  service.put({}, token).then(() => {
    alertifyjs.success('Company Successfully Unregistered!', 2, () => {
      window.location.reload();
    });
  });
};

export const activateCompany = companyId => (dispatch, getState) => {
  const { token } = getState().main.user;
  const service = APIService.companies(companyId);
  service.appendToUrl('register/');
  service.put({ register: true, setPassword: false }, token).then(() => {
    alertifyjs.success('Company Successfully Registered!', 2, () => {
      window.location.reload();
    });
  });
};

export const inviteCompanyAdmin = companyId => (dispatch, getState) => {
  const { token } = getState().main.user;
  const service = APIService.companies(companyId);
  service.appendToUrl('register/');
  service.put({ mail: true }, token).then(() => {
    alertifyjs.success('Company Admin has been invited!', 2, () => {
      window.location.reload();
    });
  });
};

export const purgeCompany = companyId => (dispatch, getState) => {
  const { token } = getState().main.user;
  const service = APIService.companies(companyId);
  service.appendToUrl('purge/');
  service.delete(token).then(result => {
    if (result && result.alert) {
      alertifyjs.error(result.alert);
    } else {
      alertifyjs.success('Company Successfully Purged!', 2, () => {
        window.location.reload();
      });
    }
  });
};

export const showSideDrawerForTransfer = (item, showTransferSideDrawer) => {
  {
  return ({
    type: SHOW_SIDEDRAWER_TRANSFER,
    item,
    showTransferSideDrawer,
  });
}};

export const showPopUpDailyReports = (item, showDailyReports) =>{
  return ({
    type: SHOW_DAILY_REPORTS,
    item,
    showDailyReports
  });
};

export const showSideDrawerForMerge = (item, showMergeSideDrawer) => {
  {
  return ({
    type: SHOW_SIDEDRAWER_MERGE,
    item,
    showMergeSideDrawer,
  });
}};

export const getCustomColumns = tableType => (dispatch, getState) => {
  const { token } = getState().main.user;
  const userId = getState().main.user.user.id;
  const service = APIService.profiles(userId);
  service.appendToUrl(`table-preferences/${tableType}/`);
  service.get(token).then(response => dispatch(setTableColumns(get(response, 'enabledHeaders'))));
}

import React, {Component} from 'react';


class Invoice extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="38" viewBox="0 0 28 38">
      <path fill={this.props.fill} fillRule="evenodd" d="M24 9l-5-.006V4H4v30h20V9zM4 .667h16.667l6.666 6.666V34A3.333 3.333 0 0 1 24 37.333H4A3.333 3.333 0 0 1 .667 34V4A3.333 3.333 0 0 1 4 .667zm12.68 22.336c0-.605-.192-1.105-.577-1.5-.384-.396-1.025-.752-1.923-1.069-.897-.317-1.595-.61-2.093-.88-1.66-.891-2.489-2.208-2.489-3.953 0-1.182.36-2.154 1.079-2.916.72-.762 1.695-1.214 2.927-1.356V9h1.709v2.35c1.239.178 2.196.703 2.873 1.575.676.873 1.015 2.007 1.015 3.402h-2.585c0-.897-.201-1.604-.604-2.12-.402-.516-.949-.774-1.64-.774-.683 0-1.217.185-1.601.555-.385.37-.577.901-.577 1.592 0 .62.19 1.116.571 1.49.381.374 1.027.728 1.939 1.063.911.334 1.627.644 2.147.929.52.285.958.61 1.314.977.356.367.63.789.822 1.266.192.477.288 1.036.288 1.677 0 1.203-.368 2.179-1.105 2.927-.737.747-1.764 1.189-3.082 1.324v2.083H13.39v-2.072c-1.41-.157-2.494-.663-3.252-1.517C9.379 24.872 9 23.74 9 22.33h2.596c0 .898.226 1.592.678 2.083.452.492 1.091.737 1.917.737.812 0 1.43-.196 1.853-.587.424-.392.636-.912.636-1.56z"/>
    </svg>;
  }
}

export default Invoice;

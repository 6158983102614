import findIndex from 'lodash/findIndex';

import {
  RECEIVE_TRAILERS,
  RECEIVE_TRUCKS,
  RECEIVE_TRUCKS_WEB,
  RECEIVE_USER_TRUCKS,
  ADD_TRUCK,
  EDIT_TRUCK,
  ADD_TRAILER,
  EDIT_TRAILER,
  CANCEL_EDIT_TRUCK,
  CLICK_ADD_TRUCK_BUTTON,
  CLICK_EDIT_TRUCK_ICON,
  CANCEL_EDIT_TRAILER,
  CLICK_ADD_TRAILER_BUTTON,
  CLICK_EDIT_TRAILER_ICON,
  EMPTY_CREATED_TRUCK,
} from '../../actions/companies/trucks';

const initialState = {
  items: [],
  trailers: [],
  selectedTrailerId: undefined,
  selectedTruckId: undefined,
  createdTruck: undefined,
  pickupCreatedTruck: undefined,
  deliveryCreatedTruck: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  isTrailerCreateFormDisplayed: false,
  isTrailerUpdateFormDisplayed: false,
  paginationData: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RECEIVE_TRUCKS_WEB:
    return {
      ...state,
      items: action.result.results,
      paginationData: action.result,
      selectedTruckId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
    };
  case RECEIVE_TRUCKS:
    return {
      ...state,
      items: action.items,
      selectedTruckId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
    };
  case RECEIVE_TRAILERS:
    return {
      ...state,
      trailers: action.items,
      selectedTrailerId: undefined,
      isTrailerCreateFormDisplayed: false,
      isTrailerUpdateFormDisplayed: false,
    };
  case RECEIVE_USER_TRUCKS:
    return {
      ...state,
      userTrucks: action.items,
    };
  case ADD_TRUCK:
    return {
      ...state,
      items: [action.item, ...state.items],
      selectedTruckId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
      createdTruck: action.id ? undefined : action.item,
      pickupCreatedTruck: action.id == 'freightPickup.outload.truckId' ? action.item : undefined,
      deliveryCreatedTruck: action.id == 'freightDelivery.inload.truckId' ? action.item : undefined,

    };
  case EMPTY_CREATED_TRUCK:
    return {
      ...state,
      createdTruck: !action.id ? undefined : state.createdTruck,
      pickupCreatedTruck: action.id == 'pickup' ? undefined : state.pickupCreatedTruck,
      deliveryCreatedTruck: action.id == 'delivery' ? undefined : state.deliveryCreatedTruck,
    };
  case EDIT_TRUCK: {
    const index = findIndex(state.items, { id: action.item.id });
    return {
      ...state,
      items: [
        ...state.items.slice(0, index),
        action.item,
        ...state.items.slice(index + 1),
      ],
      selectedTruckId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
    };
  }
  case CANCEL_EDIT_TRUCK:
    return {
      ...state,
      selectedTruckId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
    };
  case CLICK_ADD_TRUCK_BUTTON:
    return {
      ...state,
      selectedTruckId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: true,
    };
  case CLICK_EDIT_TRUCK_ICON:
    return {
      ...state,
      selectedTruckId: action.index.id,
      isUpdateFormDisplayed: true,
      isCreateFormDisplayed: false,
    };
  case ADD_TRAILER:
    return {
      ...state,
      trailers: [action.item, ...state.trailers],
      selectedTrailerId: undefined,
      isTrailerUpdateFormDisplayed: false,
      isTrailerCreateFormDisplayed: false,
    };
  case EDIT_TRAILER: {
    const index = findIndex(state.trailers, { id: action.item.id });
    return {
      ...state,
      trailers: [
        ...state.trailers.slice(0, index),
        action.item,
        ...state.trailers.slice(index + 1),
      ],
      selectedTrailerId: undefined,
      isTrailerUpdateFormDisplayed: false,
      isTrailerCreateFormDisplayed: false,
    };
  }
  case CANCEL_EDIT_TRAILER:
    return {
      ...state,
      selectedTrailerId: undefined,
      isTrailerUpdateFormDisplayed: false,
      isTrailerCreateFormDisplayed: false,
    };
  case CLICK_ADD_TRAILER_BUTTON:
    return {
      ...state,
      selectedTrailerId: undefined,
      isTrailerUpdateFormDisplayed: false,
      isTrailerCreateFormDisplayed: true,
    };
  case CLICK_EDIT_TRAILER_ICON:
    return {
      ...state,
      selectedTrailerId: action.index.id,
      isTrailerUpdateFormDisplayed: true,
      isTrailerCreateFormDisplayed: false,
    };
  default:
    return state;
  }
};

export default reducer;

import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import {
  RECEIVE_FARM_EMPLOYEES,
  ADD_FARM_EMPLOYEE,
  EDIT_FARM_EMPLOYEE,
  CANCEL_EDIT_FARM_EMPLOYEE,
  CLICK_ADD_FARM_EMPLOYEE_BUTTON,
  CLICK_EDIT_FARM_EMPLOYEE_ICON,
  ADD_FARM_EMPLOYEE_ERRORS,
  EDIT_FARM_EMPLOYEE_ERRORS,
} from '../../actions/companies/farm-employees';

const initialState = {
  items: [],
  selectedFarmEmployeeId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FARM_EMPLOYEES:
      return {
        ...state,
        items: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_FARM_EMPLOYEE:
      return {
        ...state,
        items: [action.item, ...state.items],
        selectedFarmEmployeeId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_FARM_EMPLOYEE_ERRORS:
      return {
        ...state,
        selectedFarmEmployeeId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_FARM_EMPLOYEE: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedFarmId: undefined,
        selectedFarmEmployeeId: undefined,
        isUpdateFormDisplayed: false,
      };
    }
    case EDIT_FARM_EMPLOYEE_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_FARM_EMPLOYEE:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedFarmEmployeeId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: false,
      };
    case CLICK_ADD_FARM_EMPLOYEE_BUTTON:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedFarmEmployeeId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_FARM_EMPLOYEE_ICON:
      return {
        ...state,
        selectedFarmId: find(state.items, {id: action.itemId}).farmId,
        selectedFarmEmployeeId: find(state.items, {id: action.itemId}).id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    default:
      return state;
  }
};

export default reducer;

import { connect } from 'react-redux';
import CompanyNgrForm from '../components/company-ngrs/CompanyNgrForm';
import { updateCompanyNgr } from '../actions/api/company-ngrs';
import { editCompanyNgr, cancelEditCompanyNgr } from '../actions/companies/company-ngrs';
import find from 'lodash/find';
import get from 'lodash/get';

function submit(companyId, data) {
  return (dispatch, getState) => {
    const ngrId = get(getState().companies.companyNgrs, 'selectedNgrId.id') || getState().companies.companyNgrs.selectedNgrId;
    dispatch(updateCompanyNgr(companyId, ngrId, data, editCompanyNgr));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedNgr: find(
      state.companies.companyNgrs.items,
      { id: state.companies.companyNgrs.selectedNgrId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (companyId, data) => dispatch(submit(companyId, data)),
    cancelEdit: () => dispatch(cancelEditCompanyNgr()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyNgrForm);

import { connect } from 'react-redux';
import CompanySiteForm from '../components/company-sites/CompanySiteForm';
import { createCompanySite, updateCompanySite } from '../actions/api/company-sites';
import { addCompanySite, editCompanySite } from '../actions/companies/company-sites';

function submit(companyId, data, callback) {
  return (dispatch) => {
    dispatch(createCompanySite(companyId, data, addCompanySite, callback));

  };
}

function update(companyId, siteId, data, callback) {
  return (dispatch) => {
    dispatch(updateCompanySite(companyId, siteId, data, editCompanySite, callback, true));
  };
}

export default connect(null, { submit, update })(CompanySiteForm);

import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';

class FreightScheduler extends Component {
  render() {
    return <Tooltip title="Freight Scheduler" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style={this.props.style || {}}>
        <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-1680.000000, -624.000000)">
            <g id="Icons-/-Dashboard-/-scheduler" transform="translate(1680.000000, 624.000000)">
              <rect id="Rectangle-Copy-5" x="0" y="0" width="24" height="24"></rect>
              <path d="M11.4389574,22.4008346 C11.2573624,23.3133149 10.4679994,24 9.52173913,24 C8.57547884,24 7.78611586,23.3133149 7.60452082,22.4008346 L6.7826087,22.4 C6.35038585,22.4 6,22.0418278 6,21.6 L6,12.8 C6,12.3581722 6.35038585,12 6.7826087,12 L16.9565217,12 C17.3887446,12 17.7391304,12.3581722 17.7391304,12.8 L17.739,13.599 L20.3931681,13.6 L23.5265997,16.5596235 C23.8268095,16.843181 24,17.235089 24,17.6493397 L24,17.6493397 L24,20.8802197 C24,21.7395236 23.2818718,22.4 22.4347826,22.4 L22.4347826,22.4 L20.8302618,22.4008346 C20.6486667,23.3133149 19.8593038,24 18.9130435,24 C17.9667832,24 17.1774202,23.3133149 16.9958252,22.4008346 Z M9.52173913,20.8 C8.87340486,20.8 8.34782609,21.3372583 8.34782609,22 C8.34782609,22.6627417 8.87340486,23.2 9.52173913,23.2 C10.1700734,23.2 10.6956522,22.6627417 10.6956522,22 C10.6956522,21.3372583 10.1700734,20.8 9.52173913,20.8 Z M18.9130435,20.8 C18.2647092,20.8 17.7391304,21.3372583 17.7391304,22 C17.7391304,22.6627417 18.2647092,23.2 18.9130435,23.2 C19.5613777,23.2 20.0869565,22.6627417 20.0869565,22 C20.0869565,21.3372583 19.5613777,20.8 18.9130435,20.8 Z M16.173913,13.6 L7.56521739,13.6 L7.56521739,20.8 L7.95615803,20.8003 C8.31309477,20.3143507 8.88150473,20 9.52173913,20 C10.1619735,20 10.7303835,20.3143507 11.0873202,20.8003 L16.173913,20.8 L16.173913,13.6 Z M19.7807449,15.2 L17.7391304,15.2 L17.7394925,20.3995756 C18.066428,20.1486681 18.4727515,20 18.9130435,20 C19.552847,20 20.1209228,20.3139278 20.4779037,20.7993192 L22.4347826,20.8 L22.4347826,20.8 L22.4347826,17.7068211 L19.7807449,15.2 Z" id="Combined-Shape" fill={this.props.fill} fillRule="nonzero"></path>
              <path d="M22,12.6 L22,3 L22,3 L2,3 L2,17 L3.7618885,17 C4.31417325,17 4.7618885,17.4477153 4.7618885,18 C4.7618885,18.5522847 4.31417325,19 3.7618885,19 L2,19 L2,19 C0.8954305,19 0,18.1045695 0,17 L0,3 C0,1.8954305 0.8954305,1 2,1 L22,1 C23.1045695,1 24,1.8954305 24,3 L24,12.6 C24,13.1522847 23.5522847,13.6 23,13.6 C22.4477153,13.6 22,13.1522847 22,12.6 Z" id="Path" fill={this.props.fill} fillRule="nonzero"></path>
              <rect id="Rectangle-12" fill={this.props.fill} x="2" y="6" width="20" height="2"></rect>
              <rect id="Rectangle-12-Copy" fill={this.props.fill} transform="translate(6.000000, 2.000000) rotate(-270.000000) translate(-6.000000, -2.000000) " x="4" y="1" width="4" height="2" rx="1"></rect>
              <rect id="Rectangle-12-Copy-2" fill={this.props.fill} transform="translate(12.000000, 2.000000) rotate(-270.000000) translate(-12.000000, -2.000000) " x="10" y="1" width="4" height="2" rx="1"></rect>
              <rect id="Rectangle-12-Copy-3" fill={this.props.fill} transform="translate(18.000000, 2.000000) rotate(-270.000000) translate(-18.000000, -2.000000) " x="16" y="1" width="4" height="2" rx="1"></rect>
            </g>
          </g>
        </g>
      </svg>
    </Tooltip>;
  }
}

FreightScheduler.propTypes = {
  fill: PropTypes.string,
  style: PropTypes.string,
};

export default FreightScheduler;

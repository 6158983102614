import React from 'react';
import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import { get, cloneDeep, isEqual, isMatch, includes } from 'lodash';
import { setHeaderText, setBreadcrumbs, setSubHeaderText } from '../../actions/main';
import VendorDecsTable from '../../containers/VendorDecsTable';
import { getVendorDecs, storeVendorDecMovementEntity } from '../../actions/companies/vendor-decs';
import CommonListingButton from '../common/CommonListingButton';
import { isCustomerGradeOrTonnageMissing } from '../freights/utils';
import { defaultViewAction } from '../../common/utils';

class VendorDecs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      paginationData: {},
    };
  }

  componentDidMount() {
    const {queryString, movementId, dispatch} = this.props;
    dispatch(getVendorDecs(null, null, queryString));
    dispatch(storeVendorDecMovementEntity(movementId));
    this.setHeader();
  }

  componentDidUpdate() {
    if(!isEqual(this.props.breadcrumbs, this.getBreadcrumbs()))
      this.setBreadcrumbs();
    if(!isEqual(this.props.headerText, this.getHeaderText()))
      this.setHeaderText();
    if(!isEqual(this.props.subheaderText, this.getSubHeaderText()))
      this.setSubHeaderText();
  }

  isLockedToOneParent() {
    return Boolean(this.props.queryString);
  }

  setHeader() {
    this.setHeaderText();
    this.setSubHeaderText();
    this.setBreadcrumbs();
  }

  setHeaderText() {
    const { dispatch } = this.props;
    dispatch(setHeaderText(this.getHeaderText()));
  }

  setSubHeaderText() {
    const { dispatch } = this.props;
    dispatch(setSubHeaderText(this.getSubHeaderText()));
  }

  setStandAloneBreadcrumbs() {
    const { dispatch, count } = this.props;
    const breadcrumbs = [{text: `Vendor Declarations (${count})`}];
    dispatch(setBreadcrumbs(breadcrumbs));
  }

  getBreadcrumbs() {
    const { count, baseBreadcrumbs } = this.props;
    const vendorDecBreadcrumb = {text: `Vendor Declarations (${count})`};
    if(this.isLockedToOneParent())
      return [...cloneDeep(baseBreadcrumbs), vendorDecBreadcrumb];
    return [vendorDecBreadcrumb];
  }

  getHeaderText() {
    const { baseHeaderText } = this.props;
    return baseHeaderText || 'Vendor Declarations';
  }

  getSubHeaderText() {
    const { baseSubHeaderText } = this.props;
    return baseSubHeaderText || '';
  }

  setBreadcrumbs() {
    this.props.dispatch(setBreadcrumbs(this.getBreadcrumbs()));
  }

  onAddClick = (type) => {
    if (this.isLockedToOneParent() && this.props.onAddClick){
      if(this.props?.movement && includes(['grainCommodity', 'truckCleanliness'], type)){
        isCustomerGradeOrTonnageMissing(this.props.movement, (isMissing) => {
          if(!isMissing){
            this.props.onAddClick(type);
          }
        });
      }
      else{
        this.props.onAddClick(type);
      }
    }
    else {
      if (type == 'grainCommodity') window.location = '/#/vendor-decs/grain/new/';
      else window.location = '/#/vendor-decs/truck/new/';
    }
  };

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  render() {
    const { hideAddButton } = this.props;
    let showoptions = !(isMatch(get(this.props,'queryString'),'?order_id') || isMatch(get(this.props,'queryString'),'?contract_id'));

    return (
      <React.Fragment>

      <Paper className="paper-table-paginated">
        <div style={{ position: 'relative' }}>
          <div>
            { !hideAddButton &&
            <CommonListingButton
              defaultHandler={() => this.onAddClick('grainCommodity')}
              showMenus={showoptions}
              optionMapper={[{
                name: "Grain Commodity",
                fx: () => this.onAddClick('grainCommodity'),
                enableOption: true
              },
              {
                name: "Truck Cleanliness",
                fx: () => this.onAddClick('truckCleanliness'),
                enableOption: true
              }]}
              title=""
              showDownLoadIcon={false}
              name="Vendor Declaration"
            />
          }
          </div>
          <div>
          <CommonListingButton
            showMenus
            showDownLoadIcon={false}
            optionMapper={this.getActionsOptionMapperListItems()}
            title='Actions'
            name='Actions'
          />
        </div>
          <VendorDecsTable />
        </div>
      </Paper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    count: get(state.companies.vendorDecs, 'items.length') || 0,
    breadcrumbs: state.main.breadcrumbs,
    headerText: state.main.headerText,
    subHeaderText: state.main.subHeaderText,
  };
};

export default connect(mapStateToProps)(VendorDecs);

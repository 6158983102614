import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { clickEditReleaseNoteIcon } from '../actions/companies/release-notes';

const mapDispatchToProps = (dispatch) => {
  return {
    handleDefaultCellClick: (item) => {
      dispatch(clickEditReleaseNoteIcon(item));
    }
  };
};

export default connect(
  null,
  mapDispatchToProps
)(GenericTable);

import {
    GET_CONTRACT_BIDS_SUCCESS,
    SELECTED_CONTRACT_BID,
    EDIT_SELECTED_CONTRACT_BID,
    VIEW_SELECTED_CONTRACT_BID,
    DUPLICATE_SELECTED_CONTRACT_BID,
    GET_COMPANY_CONTRACT_BIDS_SUCCESS,
    SHOW_QUANTITY_DIALOG
} from '../../actions/companies/contract-bids';
import { get } from 'lodash';

const initialState = {
    contractBidPaginatedData: [],
    contractBids: [],
    selectedContractBid: undefined,
    editSelectedContractBid: false,
    viewSelectedContractBid: false,
    duplicateSelectedContractBid: false,
    setContractBidResponses: [],
    showQuantityDialog: false,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTRACT_BIDS_SUCCESS:
            return {
                ...state,
                contractBids: get(action.items, 'results', get(action, 'items', [])),
                contractBidPaginatedData: action.items,
            };
        case SELECTED_CONTRACT_BID:
            return {
                ...state,
                selectedContractBid: action.item,
            };
        case EDIT_SELECTED_CONTRACT_BID:
            return {
                ...state,
                editSelectedContractBid: action.flag,
            };
        case VIEW_SELECTED_CONTRACT_BID:
            return {
                ...state,
                viewSelectedContractBid: action.flag,
            };
        case GET_COMPANY_CONTRACT_BIDS_SUCCESS:
            return {
                ...state,
                contractBids: get(action.items, 'results', get(action, 'items', [])),
                contractBidPaginatedData: action.items,
            };
        case DUPLICATE_SELECTED_CONTRACT_BID:
            return {
                ...state,
                duplicateSelectedContractBid: action.flag,
            };
        case SHOW_QUANTITY_DIALOG:
            return {
                ...state,
                showQuantityDialog: action.flag,
            };
        default:
            return {
                ...state
            };
    }
};

export default reducer;

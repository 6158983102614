import React from 'react'
import { connect } from 'react-redux'
import Paper from '@mui/material/Paper';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import TemplatesTable from '../../containers/TemplatesTable';
import { getEmployeeTemplates } from '../../actions/company-settings/employees';

class TemplatesHome extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '#' + this.props.location.pathname,
      type: this.props.type
    };
  }

  componentDidMount() {
    this.props.getTemplates(this.state.type);
    this.props.setBreadcrumbs([{ text: this.state.type === 'contract' ? 'Contract' : 'Freight Order' }]);
    this.props.setHeaderText('Templates');
  }

  render() {
    const { type } = this.state;
    return (
        <Paper className='paper-table-paginated'>
            <div style={{ position: 'relative' }}>
            <TemplatesTable
                type={type}
                {...this.props}
            />
            </div>
        </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    allItems: state.companySettings.employees.templates,
    count: state.companySettings.employees.templates || 0,
  };
};

const mapDispatchToProps = dispatch => ({
  getTemplates: (type) => dispatch(getEmployeeTemplates('', true, type)),
  setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  setHeaderText: (text) => dispatch(setHeaderText(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TemplatesHome);

import React from 'react';
import { Paper } from "@mui/material"
import { get } from "lodash";
import { convertEpochToDateFormat } from "../../common/momentUtilities";
import PaymentRunDetailsActions from './PaymentRunDetailsActions';
import { getCountryCurrency } from '../../common/utils';

const renderBasedOnStatus = status => {
  switch (status) {
    case 'Pending Payment':
      return (
        <img src="images/pending-payment.svg"></img>
      );
    case 'Paid':
      return (
        <img src="images/paid.png"></img>
      );
    case 'Partially Paid':
      return (
        <img src="images/partially_paid.svg"></img>
      );
    case 'Void':
      return (
        <img src="images/rejected.png"></img>
      );
    default:
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
  }
};

const getSection = (title, value) => {
  return <li className="flex-direction-column">
    <span className="field-label" key="label">{title}</span>
    <span className="field-value" key="value">{value}</span>
  </li>;
};

export const PaymentRunBasicDetails = props => {
  const status = get(props.paymentRunDetails, 'statusDisplay');
  let creationDate = get(props.paymentRunDetails, 'createdAt');
  creationDate = convertEpochToDateFormat(creationDate);
  let paymentDue = get(props.paymentRunDetails, 'paymentDue');
  paymentDue = convertEpochToDateFormat(paymentDue);
  let totalPayable = get(props.paymentRunDetails, 'total');
  let totalPaid = '-';
  if (status === 'Paid') {
    totalPaid =  `${getCountryCurrency()} ${totalPayable}`;
  }
  return (
    <Paper className='invoice-status'>
      <ul className="invoice-status-bar">
        <li className="status flex-direction-column">
          {renderBasedOnStatus(status)}
          <span className="field-value">{status}</span>
        </li>
        {getSection('Creation Date', creationDate)}
        {getSection('Payment Due Date', paymentDue)}
        {getSection('Total Payable', `$${totalPayable}`)}
        {getSection('Total Paid', totalPaid)}
      </ul>
      <PaymentRunDetailsActions {...props} />
    </Paper>
  );
};

export default PaymentRunBasicDetails;

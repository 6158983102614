import { apiURL, forceStopLoader } from '../main';
import alertifyjs from 'alertifyjs';
import { get, pickBy, map, isEmpty } from 'lodash';
import axios from 'axios';
import { titleTransfersResponse } from './contracts';
import { addPageCacheQueryParamsToUrl, getCountryConfig } from '../../common/utils';
import packageJson from '../../../package.json';
import { TAB } from '../../components/cash-boards/constants';

const VERSION = packageJson?.version
export const GET_CASH_PRICES_SUCCESS = 'GET_CASH_PRICES_SUCCESS';
export const CAN_CREATE_TITLE_TRANSFER_CASH_PRICES = 'CAN_CREATE_TITLE_TRANSFER_CASH_PRICES';
export const SELECTED_CASH_PRICE = 'SELECTED_CASH_PRICE';
export const EDIT_SELECTED_CASH_PRICE = 'EDIT_SELECTED_CASH_PRICE';
export const SHOW_AUDIT_HISTORY = 'SHOW_AUDIT_HISTORY';
export const VIEW_SELECTED_CASH_PRICE = 'VIEW_SELECTED_CASH_PRICE';
export const ENTITIES_FOR_ALL_CASH_PRICES = 'ENTITIES_FOR_ALL_CASH_PRICES';
export const CASH_PRICE_ALL_COMMODITIES = 'CASH_PRICE_ALL_COMMODITIES';
export const CASH_PRICE_CURRENT_COMMODITY = 'CASH_PRICE_CURRENT_COMMODITY';
export const FILTERS_FOR_UNREGISTERED_USER = 'FILTERS_FOR_UNREGISTERED_USER';
export const GET_COMPANY_CASH_PRICES_SUCCESS = 'GET_COMPANY_CASH_PRICES_SUCCESS';
export const GET_ARCHIVED_CASH_PRICES_SUCCESS = 'GET_ARCHIVED_CASH_PRICES_SUCCESS';
export const GET_SITE_ARCHIVED_CASH_PRICES_SUCCESS = 'GET_SITE_ARCHIVED_CASH_PRICES_SUCCESS';
export const GET_SITES_ACTIVE_CASH_PRICES_SUCCESS = 'GET_SITES_ACTIVE_CASH_PRICES_SUCCESS';
export const RESET_AUTO_SCROLL_POSITION = 'RESET_AUTO_SCROLL_POSITION';
export const SET_INTERVAL_TIME = "SET_INTERVAL_TIME";
export const SET_SELECTED_TAB = "SET_SELECTED_TAB";

export const cashPricesResponse = items => ({
    type: GET_CASH_PRICES_SUCCESS,
    items,
});

export const canCreateTitleTransferForCashPrice = items => ({
    type: CAN_CREATE_TITLE_TRANSFER_CASH_PRICES,
    items
});

export const setSelectedCashPrice = item => ({
    type: SELECTED_CASH_PRICE,
    item
});

export const editSelectedCashPrice = flag => ({
    type: EDIT_SELECTED_CASH_PRICE,
    flag
});

export const showAuditHistory = flag => ({
    type: SHOW_AUDIT_HISTORY,
    flag
})
export const viewSelectedCashPrice = flag => ({
    type: VIEW_SELECTED_CASH_PRICE,
    flag
});

export const allCashPricesEntities = (commodityId, track) => ({
    type: ENTITIES_FOR_ALL_CASH_PRICES,
    commodityId,
    track
});

export const setCurrentCashPriceCommodities = commodityIds => ({
    type: CASH_PRICE_ALL_COMMODITIES,
    commodityIds
});

export const setCurrentCommodityId = currentCommodityId => ({
    type: CASH_PRICE_CURRENT_COMMODITY,
    currentCommodityId
});

export const saveFiltersForUnregisteredUser = filters => ({
    type: FILTERS_FOR_UNREGISTERED_USER,
    filters
});

export const companyCashPricesResponse = items => ({
    type: GET_COMPANY_CASH_PRICES_SUCCESS,
    items,
});

export const sitesActiveCashPricesResponse = items => ({
    type: GET_SITES_ACTIVE_CASH_PRICES_SUCCESS,
    items,
});

export const archivedCashPricesResponse = items => ({
    type: GET_ARCHIVED_CASH_PRICES_SUCCESS,
    items,
});

export const siteArchivedCashPricesResponse = items => ({
    type: GET_SITE_ARCHIVED_CASH_PRICES_SUCCESS,
    items,
});

export const resetAutoScrollPosition = flag => ({
    type: RESET_AUTO_SCROLL_POSITION,
    flag,
});

export const setIntervalTime = time => ({
    type: SET_INTERVAL_TIME,
    time,
});

export const setSelectedTab = tab => ({
    type: SET_SELECTED_TAB,
    tab
});

export const getCashPrices = (queryString, _forceStopLoader = true, url, tabType, isPresentation = false) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
              'Content-type': 'application/json',
              accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}/cash_board/cash-prices/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];
        let joiner = '?';
        if(URL.indexOf('?') > -1)
            joiner = '&';
        URL = addPageCacheQueryParamsToUrl(URL, 'cash-board/cash-prices')
        if (isPresentation) {
            URL += `${joiner}is_presentation=true`;
            joiner = '&';
            let currentCommodityId = get(getState().companies.cashBoard, 'currentCommodityId');
            let currentCommodityIds = get(getState().companies.cashBoard, 'cashPriceCommodityIds');
            if (currentCommodityIds) {
                let updatedCommodityId = null;
                if (currentCommodityId) {
                    let index = currentCommodityIds.indexOf(currentCommodityId);
                    if (index === currentCommodityIds.length - 1 || index === -1){
                        updatedCommodityId = currentCommodityIds[0];
                    }
                    else {
                        updatedCommodityId = currentCommodityIds[++index];
                    }
                }
                else {
                    updatedCommodityId = currentCommodityIds[0];
                }
                if (updatedCommodityId) {
                    URL += `${joiner}commodity_id=${updatedCommodityId}`;
                    dispatch(setCurrentCommodityId(updatedCommodityId));
                }
                else {
                    return;
                }
            }
        }
        axios
            .get(URL, config)
            .then(response => {
                dispatch(cashPricesResponse(response.data));
                if (isPresentation) {
                    let intervalTime = response.data.length * (getCountryConfig()?.cashPriceDisplayTimeIntervalPerRecord || 1000);
                    let minimumTimePerCommodity = getCountryConfig().minimumTimePerCommodity || 15000;
                    dispatch(setIntervalTime(intervalTime < minimumTimePerCommodity ? minimumTimePerCommodity : intervalTime));
                    dispatch(resetAutoScrollPosition(true));
                }
                if (_forceStopLoader) {
                    dispatch(forceStopLoader());
                }
            })
            .catch(error => {
                dispatch(cashPricesResponse(error.response ? error.response.data : error.message));
            });
    };
};

export const getCashPricesUnregisteredUser = (params, url, isPresentation = false) => (dispatch, getState) => {
    if (get(getState().companies.cashBoard, 'unregisteredUserFilters')) {
        params = get(getState().companies.cashBoard, 'unregisteredUserFilters');
    }
  let queryParams = pickBy(params, value => value && !isEmpty(value));

    let queryString = Object.keys(queryParams).map(key => map(queryParams[key], id => (key + '=' + id)).join('&')).join('&');
    url = addPageCacheQueryParamsToUrl(url)
    let joiner = '?';
    if(url && url.indexOf('?') > -1)
        joiner = '&';
  if (isPresentation) {
        if(queryString)
          joiner = '&'
        queryString += `${joiner}is_presentation=true`;
        joiner = '&';
        let currentCommodityId = get(getState().companies.cashBoard, 'currentCommodityId');
        let currentCommodityIds = get(getState().companies.cashBoard, 'cashPriceCommodityIds', []);
        if (currentCommodityIds) {
            let updatedCommodityId = null;
            if (currentCommodityId) {
                let index = currentCommodityIds.indexOf(currentCommodityId);
                if (index === currentCommodityIds.length - 1){
                    updatedCommodityId = currentCommodityIds[0];
                }
                else {
                    updatedCommodityId = currentCommodityIds[++index];
                }
            }
            else {
                updatedCommodityId = currentCommodityIds[0];
            }
            if (updatedCommodityId) {
                queryString += `${joiner}commodity_id=${updatedCommodityId}`;
                dispatch(setCurrentCommodityId(updatedCommodityId));
            }
            else {
                return;
            }
        }
    }
    fetch(`${apiURL}/cash_board/cash-prices/` + `?${queryString}` + `${url ? '&' + url.split('?')[1] : ''}`)
        .then(response => response.json())
        .then((json) => {
            dispatch(cashPricesResponse(json));
            if (isPresentation) {
                let intervalTime = json.length * (getCountryConfig()?.cashPriceDisplayTimeIntervalPerRecord || 1000);
                let minimumTimePerCommodity = getCountryConfig().minimumTimePerCommodity || 15000;
                dispatch(setIntervalTime(intervalTime < minimumTimePerCommodity ? minimumTimePerCommodity : intervalTime))
                dispatch(resetAutoScrollPosition(true));
            }
            dispatch(forceStopLoader());
        });

};

export const canCreateTitleTransferCashPrice = (item) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
              'Content-type': 'application/json',
              accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };

        let URL = `${apiURL}/cash_board/${item.id}/can-create-title-transfer/`;
        axios
            .get(URL, config)
            .then(response => {
                if (get(response.data, 'result')) {
                    dispatch(canCreateTitleTransferForCashPrice(response.data));
                    dispatch(setSelectedCashPrice(item));
                }
                else {
                    dispatch(canCreateTitleTransferForCashPrice([]));
                    alertifyjs.error(get(response.data, 'reason'));
                }
            })
            .catch((res) => {
                dispatch(canCreateTitleTransferForCashPrice([]));
                alertifyjs.error(get(res, 'response.data.reasons', 'An Error Occurred!'));
                let query_params = window.location.hash.split('?')[1];
                if (query_params) {
                    const urlParams = new URLSearchParams(query_params);
                    let queryString = `?page_size=${urlParams.get('pageSize')}&page=${urlParams.get('page')}&order_by=${urlParams.get('orderBy')}&order=${urlParams.get('order')}`;
                    dispatch(getCashPrices(queryString, true, null, 'all'));
                }
                else dispatch(getCashPrices(null, true, null, 'all'));
            });
    };
};

export const getTitleTransfers = (cashPriceId) => (dispatch, getState) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            accept: 'application/json',
            Authorization: `Token ${getState().main.user.token}`,
            'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
            'WEB-VERSION': VERSION,
            'REFERER-COUNTRY': localStorage.current_country
        },
    };
    if (cashPriceId)
        dispatch(titleTransfersResponse([]));
    if (cashPriceId) {
        let service = `${apiURL}/cash_board/${cashPriceId}/title-transfers/`;
        axios
            .get(service, config).then(response => {
                dispatch(titleTransfersResponse(response.data));
            }).catch(error => {
                dispatch(titleTransfersResponse(error.response ? error.response.data : error.message));
            });
    }
};

export const setSelectedCashPriceInactive = (cashPriceId, tab=TAB.ALL_CASH_PRICE) => (dispatch, getState) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            accept: 'application/json',
          Authorization: `Token ${getState().main.user.token}`,
          'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'WEB-VERSION': VERSION,
          'REFERER-COUNTRY': localStorage.current_country
        },
    };

    if (cashPriceId) {
        let service = `${apiURL}/cash_board/cash-prices/${cashPriceId}/inactive/`;
        axios
            .get(service, config).then(res => {
                if (get(res.data, 'result') == true) {
                    dispatch(setSelectedCashPrice(tab))
                    if (tab == TAB.MY_CASH_PRICE)
                        dispatch(getCompanyCashPrices('', true))
                    else if (tab == TAB.SITE_ACTIVE_CASH_PRICE)
                        dispatch(getSitesActiveCashPrices('', true))
                    alertifyjs.success('Cash price inactivated');
                    window.location.hash = '#/cash-board/cash-prices';
                }
                else
                    alertifyjs.error(get(res.data.errors, 'reasons', 'An Error Occurred!'));
            });
    }
};

export const deleteCashPrice = cashPriceId => (dispatch, getState) => {
    const config = {
        headers: {
            'Content-type': 'application/json',
            accept: 'application/json',
          Authorization: `Token ${getState().main.user.token}`,
          'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'WEB-VERSION': VERSION,
          'REFERER-COUNTRY': localStorage.current_country
        },
    };

    if (cashPriceId) {
        let service = `${apiURL}/cash_board/cash-prices/${cashPriceId}/`;
        axios
            .delete(service, config).then(() => {
                alertifyjs.success('Cash price deleted', 3);
                window.location.reload();
            }).catch(error => {
                alertifyjs.error(error.response.data.message, 'An Error Occurred!', 3);
            });
    }
};

export const getCashPriceCommodities = (isUnregistered=true) => (dispatch, getState) => {
    if (!isUnregistered) {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        axios
            .get(`${apiURL}/cash_board/cash-prices/commodities/`, config)
            .then(response => {
                dispatch(setCurrentCashPriceCommodities(response.data['commodityIds']));
            });
    }
    else {
        let queryString = null;
        if (get(getState().companies.cashBoard, 'unregisteredUserFilters')) {
            let params = get(getState().companies.cashBoard, 'unregisteredUserFilters');
            let queryParams = pickBy(params, value => value);
            queryString = Object.keys(queryParams).map(key => map(queryParams[key], id => (key + '=' + id)).join('&')).join('&');
        }
        let url = `${apiURL}/cash_board/cash-prices/commodities`;
        if (queryString) {
            url += `?${queryString}`;
        }
        fetch(url)
            .then(response => response.json())
            .then((json) => {
                dispatch(setCurrentCashPriceCommodities(json['commodityIds']));
            });
    }
};


export const getCompanyCashPrices = (queryString, _forceStopLoader = true, url) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}/companies/${getState().main.user.user.companyId}/cash_prices/active/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];
        URL = addPageCacheQueryParamsToUrl(URL, 'cash-board/cash-prices/active')
        axios
            .get(URL, config)
            .then(response => {
                dispatch(companyCashPricesResponse(response.data));
                if (_forceStopLoader) {
                    dispatch(forceStopLoader());
                }
            })
            .catch(error => {
                dispatch(companyCashPricesResponse(error.response ? error.response.data : error.message));
            });
    };
};

export const getSitesActiveCashPrices = (queryString, _forceStopLoader = true, url) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}/companies/${getState().main.user.user.companyId}/cash_prices/site-active-prices/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];
        URL = addPageCacheQueryParamsToUrl(URL, 'cash-board/cash-prices/site-active-prices')
        axios
            .get(URL, config)
            .then(response => {
                dispatch(sitesActiveCashPricesResponse(response.data));
                if (_forceStopLoader) {
                    dispatch(forceStopLoader());
                }
            })
            .catch(error => {
                dispatch(sitesActiveCashPricesResponse(error.response ? error.response.data : error.message));
            });
    };
};


export const getArchivedCashPrices = (queryString, _forceStopLoader = true, url) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };

        let URL = `${apiURL}/companies/${getState().main.user.user.companyId}/cash_prices/archived/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];
        URL = addPageCacheQueryParamsToUrl(URL, 'cash-board/cash-prices/archived');
        axios
            .get(URL, config)
            .then(response => {
                dispatch(archivedCashPricesResponse(response.data));
                if (_forceStopLoader) {
                    dispatch(forceStopLoader());
                }
            })
            .catch(error => {
                dispatch(archivedCashPricesResponse(error.response ? error.response.data : error.message));
            });
    };
};

export const getSiteArchivedCashPrices = (queryString, _forceStopLoader = true, url) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}/companies/${getState().main.user.user.companyId}/cash_prices/siteArchived/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];
        URL = addPageCacheQueryParamsToUrl(URL, 'cash-board/cash-prices/site');
        axios
            .get(URL, config)
            .then(response => {
                dispatch(siteArchivedCashPricesResponse(response.data));
                if (_forceStopLoader) {
                    dispatch(forceStopLoader());
                }
            })
            .catch(error => {
                dispatch(siteArchivedCashPricesResponse(error.response ? error.response.data : error.message));
            });
    };
};

import React from 'react';
import { connect } from 'react-redux';
import CommonTabs from '../common/CommonTabs';
import { setSubHeaderText, isLoading, loaded } from '../../actions/main';
import TitleTransfers from '../title-transfers/TitleTransfers';
import Invoices from '../invoices/Invoices';
import Notes from '../notes/Notes';
import ContractDetails from './contractDetails/ContractsDetails';
import { ContractDetailsBasicInfo } from './contractDetails/ContractDetailsBasicInfo';
import SideDrawer from '../common/SideDrawer';
import AmendContract from './contractDetails/AmendContract';
import {
  getSelectedContract, receiveContract,
  canCreateMovementForContract, canCreateOrderForContract,
  canCreateCOGForContract, freightMovementWarning, voidTitleTransferDialog
} from '../../actions/companies/contracts';
import {
  getSelectedOrder,
  receiveOrder,
  receiveAllocation,
} from '../../actions/companies/orders';
import { receiveFreight, getSelectedFreight } from '../../actions/companies/freights';
import {
  isSystemCompany, getQueryParams, vendorDecBlockPermissionPopup
} from "../../common/utils";
import ContractFreightOrders from "./ContractFreightOrders";
import ContractFreightMovements from "./ContractFreightMovements";
import FreightMovementWarningDialog from "../../components/freights/FreightMovementWarningDialog";
import { RejectionReasonDialog } from "../../components/rejections/RejectionReasonDialog";
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import { voidReasonDialog } from '../../actions/companies/invoices';
import { required } from '../../common/validators';
import EditOrderReview from '../../components/freights/order-details/EditOrderReview';
import EditMovementReview from '../../components/freights/MovementDetails/EditMovementReview';
import VendorDecs from '../vendor-decs/VendorDecs';
import CustomEmailDialog from '../common/CustomEmailDialog';
import { ORDER_TYPE_NAMES } from '../../common/constants';
import {
  get, find, forEach, isEmpty, map, includes, without, pick, keys, uniq, filter,
  intersectionBy, has, values, compact, isEqual
} from 'lodash';
import moment from 'moment';
import { AppContext } from '../main/LayoutContext';

class ContractHome extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.isFreightDetailFetched = true;
    this.state = {
      emailPopupParties: [],
      showEmailDialog: false,
      activeTab: this.props.location.pathname,
      amendDrawOpen: false,
      amendDetails: null,
      amendTimeStamp: null,
      isLatestAmendRequest: false,
      isFromAuditHistory: false,
      showCurrentContract: true,
      requestReason: {
        value: '',
        validators: [required()],
        errors: []
      },
    };

    this.movementId = getQueryParams(document.location.hash, 'movementId');
    this.orderId = getQueryParams(document.location.hash, 'orderId');
    this.entityType = null;
    this.isOrderListingApplicable = isEmpty(getQueryParams(document.location.hash, 'orderType'));

    this.handleTabChange = this.handleTabChange.bind(this);
    this.onAmendOpen = this.onAmendOpen.bind(this);
    this.onAmendClose = this.onAmendClose.bind(this);
    this.onHandleAddMovementButtonClick = this.onHandleAddMovementButtonClick.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
      { ...prevState, activeTab: nextProps.location.pathname } :
      prevState;
  }

  componentDidMount() {
    this.props.dispatch(receiveFreight(null));
    const contractId = parseInt(this.props.match.params.contract_id);
    if (contractId) {
      this.props.dispatch(getSelectedContract(contractId, receiveContract, false, false, false, true));
    }
    this.props.dispatch(loaded())
  }

  componentDidUpdate() {
    const contractId = parseInt(this.props.match.params.contract_id);
    if (this.props.contract && contractId && this.props.contract.id !== contractId) {
      this.props.dispatch(isLoading('contractDetailsFromDidUpdate'))
      this.props.dispatch(getSelectedContract(contractId, receiveContract, false, false, false, true, undefined));
    }
    if (this.isFreightDetailFetched) {
      this.fetchFreightDetailIfAny();
      this.isFreightDetailFetched = false;
    }
    this.movementId = getQueryParams(document.location.hash, 'movementId');
    this.orderId = getQueryParams(document.location.hash, 'orderId');
    this.isOrderListingApplicable = isEmpty(getQueryParams(document.location.hash, 'orderType'));
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(setSubHeaderText(''));
    dispatch(receiveContract(null));
  }

  baseBreadcrumbs() {
    const { contract } = this.props;
    return [
      { text: 'Contracts', route: '/contracts' },
      {
        text: get(contract, 'referenceNumber', ''),
        route: `/contracts/${get(contract, 'id')}/contract`
      }
    ];
  }

  baseHeaderText() {
    const { contract } = this.props;
    return `Commodity Contract ${get(contract, 'referenceNumber', '')}`;
  }

  onAddVendorDecClick = () => {
    const { contract } = this.props;
    if(isEmpty(contract.cannotCreateVendorDecReasons))
      window.location.hash = `/vendor-decs/grain/new?entity=contract&entityId=${contract.id}`;
    else
      vendorDecBlockPermissionPopup(contract.cannotCreateVendorDecReasons, 'Contract');
  };

  fetchFreightDetailIfAny = () => {
    if (this.movementId) {
      this.props.dispatch(getSelectedFreight(this.movementId, receiveFreight, false, false, false, false));
      this.props.dispatch(isLoading('movementDetail'));
    } else if (this.orderId) {
      const orderType = getQueryParams(document.location.hash, 'orderType') || this.entityType;
      if (includes(['fo', 'freightorder'], orderType))
        this.props.dispatch(getSelectedOrder(this.orderId, receiveOrder, false, false, false, false, true));
      else
        this.props.dispatch(getSelectedOrder(this.orderId, receiveAllocation, false, false, false, false, true));
      this.props.dispatch(isLoading('orderDetail'));
    }
  };

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  onAmendOpen(note, requestCounterMap) {
    let isLatestAmendRequest = requestCounterMap && isEqual(get(requestCounterMap, get(note, 'args.acceptanceRequestId')), Object.keys(requestCounterMap).length)

    let additionState = {
      showCurrentContract: true
    };
    if (note) {
      additionState = {
        amendDetails: get(note, 'amendedDetails'),
        amendTimeStamp: moment(note.createdAt).format('LLLL'),
        showCurrentContract: true
      };
    }
    this.entityType = get(note, 'entity.entityType', undefined);
    if (this.entityType == 'freightorder') this.orderId =  get(note.entity, 'entityId');
    if (this.entityType == 'freightcontract') this.movementId =  get(note.entity, 'entityId');
    this.fetchFreightDetailIfAny();
    this.setState({ ...additionState, amendDrawOpen: true, isLatestAmendRequest: isLatestAmendRequest, isFromAuditHistory: Boolean(requestCounterMap)});
  }

  onAmendClose() {
    const additionState = {
      amendDetails: null,
      amendTimeStamp: null,
      showCurrentContract: true
    };
    this.setState({ ...additionState, amendDrawOpen: false });
  }

  onHandleAddMovementButtonClick() {
    this.props.dispatch(canCreateMovementForContract(this.props.match.params.contract_id));
  }

  onHandleAddOrderButtonClick = () => {
    this.props.dispatch(canCreateOrderForContract(this.props.match.params.contract_id));
  };

  onHandleAddCOGButtonClick = () => {
    this.props.dispatch(canCreateCOGForContract(this.props.match.params.contract_id));
  };

  onHandleAddInvoiceButtonClick = () => {
    window.location = '/#/invoices/new?commodityContractId=' + this.props.match.params.contract_id;
  };

  onHandleAddBrokerageInvoiceButtonClick = () => {
    window.location = '/#/invoices/brokerage/new?commodityContractId=' + this.props.match.params.contract_id;
  };

  handleVoidClick = () => {
    this.setReasonErrors();
    if (this.state.requestReason.errors.length === 0) {
      if (this.props.showVoidDialog) {
        const data = {rejection_reason: this.state.requestReason.value};
        this.openEmailDialog(data);
      }
      else {
        APIService.invoices(this.props.invoiceId).appendToUrl('void/')
          .post({ request_reason: this.state.requestReason.value }, this.props.userToken)
          .then(item => {
            if (has(item, 'errors')) {
              alertifyjs.error(item.errors[0]);
            } else {
              alertifyjs.success('Invoice void request sent', 1, () => {
                window.location.reload();
              });
            }
            this.props.dispatch(voidReasonDialog([], false));
          });
      }
    }

  };

  handleRequestReasonChange = (event) => {
    const value = event.target.value;
    const newState = { ...this.state };
    newState.requestReason.value = value;
    this.setState(newState);
  };

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.requestReason.errors = errors || this.getReasonErrors();
    this.setState({
      requestReason: { ...this.state.requestReason, errors: errors || this.getReasonErrors() }
    });
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `requestReason.value`);
    const validators = get(this.state, `requestReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  getEmailPopupParties = () => {
    if (get(this.props, 'contract.administration.brokeredById')) {
      return ['buyer', 'seller', 'broker', 'pickup site', 'delivery site'];
    }
    return ['buyer', 'seller', 'pickup site', 'delivery site'];
  };

  getSelectedParties = () => {
    if (get(this.props.contract, 'isBuyer') && get(this.props.contract, 'isSeller')) return ['buyer', 'seller'];
    else if (get(this.props.contract, 'isBuyer') && this.props.currentUser.companyId === get(this.props.contract, 'buyer.companyId')) return ['seller'];
    else if (get(this.props.contract, 'isSeller') && this.props.currentUser.companyId === get(this.props.contract, 'seller.companyId')) return ['buyer'];
    return ['buyer', 'seller'];
  };

  getPartyEmails = () => {
    return {
      buyer: get(this.props, 'contract.buyer.contact.email', ''),
      seller: get(this.props, 'contract.seller.contact.email', ''),
      broker: get(this.props, 'contract.administration.brokerContact.email', ''),
      consignor: '',
      consignee: '',
    };
  };

  getEmailPopupPartiesCompanyIds() {
    const parties = this.getEmailPopupParties();
    const ids = {};
    forEach(parties, party => {
      if (party === 'broker')
        ids.broker = get(this.props.contract, 'administration.brokerContact.companyId');
      if (party === 'buyer')
        ids.buyer = get(this.props.contract, 'buyer.companyId');
      if (party === 'seller')
        ids.seller = get(this.props.contract, 'seller.companyId');
      if (party === 'pickup site')
        ids.consignor = get(this.props.contract, 'consignorsWithSites[0].handler.companyId');
      if (party === 'delivery site')
        ids.consignee = get(this.props.contract, 'consigneesWithSites[0].handler.companyId');
    });

    return ids;
  }

  async getPartyContacts() {
    if (this.gotOncePartyContacts)
      return;

    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    const partiesWithoutContacts = without(keys(parties), 'consignee', 'consignor');
    const contacts = {};
    if (!isEmpty(partiesWithoutContacts)) {
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if (isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.token);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, { companyId: parties[party] });
      });
    }

    return contacts;
  }

  getEmailSubject = () => {
    const companyName = get(this.props.currentUser, 'company.name', "");
    const titleTransfer = intersectionBy(this.props.titleTransferList, [{ id: this.props.titleTransferId }], 'id');
    return `${companyName} Title Transfer Void #${titleTransfer[0].identifier}`;
  };

  getFooterNote = () => {
    return 'Title Transfer PDF will be automatically sent as part of the email';
  };

  openEmailDialog = data => {
    this.setState({ showEmailDialog: true, emailPopupParties: this.getEmailPopupParties() });
    this.payloadData = data;
  };

  closeEmailDialog = (communicationData, justClose) => {
    if (justClose) {
      this.gotOncePartyContacts = false;
      this.setState({ showEmailDialog: false });
    }
    else if (this.state.showEmailDialog) {
      const data = this.payloadData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      this.setState({ showEmailDialog: false }, () => {
        this.props.dispatch(isLoading('alertify'));
        APIService.contracts().appendToUrl(`title-transfers/${this.props.titleTransferId}/void/`)
          .put({ data }, this.props.userToken)
          .then(item => {
            if (has(item, 'errors')) {
              alertifyjs.error(item.errors[0]);
            } else {
              alertifyjs.success('Title Transfer is voided', 1, () => {
                window.location.reload();
              });
            }
            this.props.dispatch(voidTitleTransferDialog(false, []));
          });
      });
    }
  };

  render() {
    const { isMobileDevice } = this.context; canViewVendorDec
    const PARENT_URL = this.props.match.url;
    const contractId = this.props.match.params.contract_id;
    const canCreateCommodityContractInvoice = get(this.props.contract, 'canCreateCommodityContractInvoice') || isSystemCompany();
    const canCreateBrokerageInvoice = get(this.props.contract, 'canCreateBrokerageInvoice') || isSystemCompany();
    const canCreateInvoices = { contractInvoice: canCreateCommodityContractInvoice, brokerageInvoice: canCreateBrokerageInvoice };
    const movementId = this.movementId;
    const canViewVendorDec = this.props.contract?.canViewVendorDec;
    let tabs = [
      {
        label: 'Contract',
        key: 'contractDetails',
        url: `${PARENT_URL}/contract`,
        component: () => <ContractDetails {...this.props} contract={this.props.contract} />
      },
      {
        label: 'Orders',
        key: 'orders',
        url: `${PARENT_URL}/orders/freights`,
        urls: [`${PARENT_URL}/orders/freights`, `${PARENT_URL}/orders/grain`, `${PARENT_URL}/orders/requests`],
        component: () =>
          <ContractFreightOrders
            {...this.props}
            contractId={contractId}
            contract={this.props.contract}
            onHandleAddOrderButtonClick={this.onHandleAddOrderButtonClick}
            isOrderListingApplicable={this.isOrderListingApplicable}
            nested
          />
      },
      {
        label: 'Freight Movements',
        key: 'movements',
        url: `${PARENT_URL}/movements`,
        component: () => <ContractFreightMovements {...this.props} contractId={contractId} contract={this.props.contract} onHandleAddOrderButtonClick={this.onHandleAddMovementButtonClick} nested />
      },
      {
        label: 'Title Transfers',
        key: 'titleTransfer',
        url: `${PARENT_URL}/title-transfers`,
        component: () => <TitleTransfers {...this.props} contractId={contractId} contract={this.props.contract} nested />
      }
    ];

    if (!get(this.props.contract, 'isPoolContract') && !isMobileDevice) {
      tabs.push(
        {
          label: 'Invoice',
          key: 'invoice',
          url: `${PARENT_URL}/invoices`,
          component: () => <Invoices {...this.props} contractId={contractId} contract={this.props.contract} onHandleAddInvoiceButtonClick={this.onHandleAddInvoiceButtonClick}
            onHandleAddBrokerageInvoiceButtonClick={this.onHandleAddBrokerageInvoiceButtonClick} nested />
        }
      );
    }
    if(!isMobileDevice) {
      if(canViewVendorDec)
        tabs.push(
          {
            label: 'Vendor Declarations',
            key: 'vendorDecs',
            url: `${PARENT_URL}/vendor-decs`,
            component: () => <VendorDecs {...this.props} contractId={contractId} nested />
          }
        );
      tabs.push(
        {
          label: 'Audit History',
          key: 'notes',
          url: `${PARENT_URL}/notes`,
          component: () => <Notes {...this.props} objectId={contractId} objectType='contract' companyId={this.props.companyId} />
        }
      );
    }

    const contractNumber = get(this.props.contract, 'contractNumber') ? `(${get(this.props.contract, 'contractNumber')})` : '';
    const orderType = ORDER_TYPE_NAMES.find(orderType => orderType.id == get(this.props.selectedOrder, 'typeId'))?.name

    return (
      <div className="contract-details-container">
        <div className="tab">
          <div className="tab-header" style={{display: 'flex'}}>
            <CommonTabs
              value={this.state.activeTab}
              tabs={tabs}
            />
          </div>

          <SideDrawer
            isOpen={this.state.amendDrawOpen}
            title={`${this.entityType === 'freightorder' ? `${orderType} Order Amend Request (${get(this.props.selectedOrder, 'identifier')})` : this.entityType === 'freightcontract' ? `Movement Amend Request ${get(this.props.selectedFreight, 'identifier')}` : `Contract Amend Request ${contractNumber}`}`}
            onClose={this.onAmendClose}
            size="big"
        >
          {
            this.entityType === 'freightorder' && this.props.selectedOrder &&
            <EditOrderReview
              closeSidebar={this.onAmendClose}
              order={this.props.selectedOrder}
              showCurrentContract={this.state.showCurrentContract}
              amendDetails={this.state.amendDetails}
              amendTimeStamp={this.state.amendTimeStamp}
            />
          }
          {  (this.entityType === undefined || this.entityType === 'contract') &&
             <AmendContract
             useCurrent={false}
             closeSidebar={this.onAmendClose}
             contract={this.props.contract}
             showCurrentContract={this.state.showCurrentContract}
             amendDetails={this.state.amendDetails}
             amendTimeStamp={this.state.amendTimeStamp}
             isFromAuditHistory={this.state.isFromAuditHistory}
             isLatestAmendRequest={this.state.isLatestAmendRequest}
           />
          }
          {
            this.entityType === 'freightcontract' && this.props.selectedFreight &&
            <EditMovementReview
              closeSidebar={this.onAmendClose}
              movement={this.props.selectedFreight}
              showCurrentContract={this.state.showCurrentContract}
              amendDetails={this.state.amendDetails}
              amendTimeStamp={this.state.amendTimeStamp}
            />
          }
        </SideDrawer>

          {!isEmpty(this.props.contract) && !this.props.selectedOrder && !this.props.selectedAllocation && !this.props.selectedFreight &&
           <ContractDetailsBasicInfo {...this.props} contract={this.props.contract} onAmendClick={this.onAmendOpen} />
          }
          <div className="tab-content">
            {this.state.activeTab == find(tabs, { key: 'contractDetails' })?.url && <ContractDetails {...this.props} contract={this.props.contract} />}
            {
              this.state.activeTab == find(tabs, { key: 'movements' })?.url &&
              <ContractFreightMovements
                {...this.props}
                contractId={contractId}
                contract={this.props.contract}
                onHandleAddMovementButtonClick={this.onHandleAddMovementButtonClick}
                movementIdExists={!isEmpty(movementId)}
                nested
              />
            }
            {
              includes(this.state.activeTab, 'orders') &&
              <ContractFreightOrders
                {...this.props}
                contractId={contractId}
                contract={this.props.contract}
                onHandleAddOrderButtonClick={this.onHandleAddOrderButtonClick}
                onHandleAddCOGButtonClick={this.onHandleAddCOGButtonClick}
                isOrderListingApplicable={this.isOrderListingApplicable}
                nested
              />
            }
            {
              this.state.activeTab == find(tabs, { key: 'titleTransfer' })?.url &&
                <TitleTransfers
                  {...this.props}
                  contractId={contractId}
                  contract={this.props.contract}
                  nested
                />
            }
            {
              !get(this.props.contract, 'isPoolContract') &&
                this.state.activeTab == find(tabs, { key: 'invoice' })?.url &&
                <Invoices
                  {...this.props}
                  canCreateInvoices={canCreateInvoices}
                  contract={this.props.contract}
                  onHandleAddInvoiceButtonClick={this.onHandleAddInvoiceButtonClick}
                  onHandleAddBrokerageInvoiceButtonClick={this.onHandleAddBrokerageInvoiceButtonClick}
                  nested
                />
            }
            {
              this.state.activeTab == find(tabs, { key: 'notes' })?.url &&
                <Notes
                  {...this.props}
                  objectId={contractId}
                  objectType='contract'
                  companyId={this.props.companyId}
                  contract={this.props.contract}
                  onAmendOpen={this.onAmendOpen}
                />
            }
          </div>
          {
            this.props.fmWarningFlag &&
              <FreightMovementWarningDialog
                {...this.props}
                handleToUpdate={() => this.props.dispatch(freightMovementWarning(false, []))}
              />
          }
          {
            (this.props.showVoidDialog || this.props.showVoidReasonDialog) &&
            <RejectionReasonDialog
              open={this.props.showVoidDialog || this.props.showVoidReasonDialog}
              onClose={this.props.showVoidDialog ? () => this.props.dispatch(voidTitleTransferDialog(false, [])) : () => this.props.dispatch(voidReasonDialog([], false))}
              title={this.props.showVoidDialog ? 'Void Title Transfer' : 'Void Invoice'}
              value={this.state.requestReason.value}
              onChange={this.handleRequestReasonChange}
              helperText={get(this.state, 'requestReason.errors[0]', '')}
              onCancel={this.props.showVoidDialog ? () => this.props.dispatch(voidTitleTransferDialog(false, [])) : () => this.props.dispatch(voidReasonDialog([], false))}
              onReject={this.handleVoidClick}
              placeholder='Enter you reason for void request'
              submitText='Submit'
            />
          }
          {
            this.state.activeTab == find(tabs, {key: 'vendorDecs'})?.url &&
            <VendorDecs
              {...this.props}
              queryString={`?contract_id=${contractId}`}
              hideAddButton={!this.props.contract}
              onAddClick={this.onAddVendorDecClick}
              contractId={contractId}
              baseBreadcrumbs={this.baseBreadcrumbs()}
              baseHeaderText={this.baseHeaderText()}
              nested
            />
          }
          {this.state.showEmailDialog &&
            <CustomEmailDialog
              parties={this.state.emailPopupParties}
              selectedParties={this.getSelectedParties()}
              title="Email PDF copies to"
              partyEmails={this.getPartyEmails()}
              partyContacts={this.getPartyContacts()}
              subject={this.getEmailSubject()}
              noBody={true}
              footer={this.getFooterNote()}
              open={this.state.showEmailDialog}
              onClose={this.closeEmailDialog}
              disableAcceptanceRequired={true}
            />
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.companies.contracts.selectedContract || null,
    userToken: state.main.user.token,
    currentUser: state.main.user.user,
    selectedOrder: state.companies.orders.selectedOrder,
    selectedAllocation: state.companies.orders.selectedAllocation,
    selectedFreight: state.companies.freights.selectedFreight,
    fmWarningList: state.companies.contracts.fmWarningList,
    fmWarningFlag: state.companies.contracts.fmWarningFlag,
    showVoidDialog: state.companies.contracts.showVoidDialog,
    titleTransferId: state.companies.contracts.titleTransferId,
    titleTransferList: state.companies.contracts.titleTransfers,
    showVoidReasonDialog: state.companies.invoices.flag,
    invoiceId: state.companies.invoices.invoiceId
  };
};

export default connect(mapStateToProps)(ContractHome);

import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import {
  RECEIVE_FARM_FIELDS,
  ADD_FARM_FIELD,
  EDIT_FARM_FIELD,
  CANCEL_EDIT_FARM_FIELD,
  CLICK_ADD_FARM_FIELD_BUTTON,
  CLICK_EDIT_FARM_FIELD_ICON,
  ADD_FARM_FIELD_ERRORS,
  EDIT_FARM_FIELD_ERRORS,
  RECEIVE_ARCHIVED_FARM_FIELDS,
} from '../../actions/companies/farm-fields';

const initialState = {
  items: [],
  selectedFarmFieldId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_FARM_FIELDS:
      return {
        ...state,
        items: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_FARM_FIELD:
      return {
        ...state,
        items: [action.item, ...state.items],
        selectedFarmFieldId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_FARM_FIELD_ERRORS:
      return {
        ...state,
        selectedFarmFieldId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_FARM_FIELD: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedFarmId: undefined,
        selectedFarmFieldId: undefined,
        isUpdateFormDisplayed: false,
      };
    }
    case EDIT_FARM_FIELD_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_FARM_FIELD:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedFarmFieldId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: false,
      };
    case CLICK_ADD_FARM_FIELD_BUTTON:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedFarmFieldId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_FARM_FIELD_ICON:
      return {
        ...state,
        selectedFarmId: find(state.items, {id: action.itemId}).farmId,
        selectedFarmFieldId: find(state.items, {id: action.itemId}).id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    case RECEIVE_ARCHIVED_FARM_FIELDS:
      return {
        ...state,
        archivedItems: action.items,
      };
    default:
      return state;
  }
};

export default reducer;

import React, {Component} from 'react';


class SiteBooking extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 1.68h40V42H0z"/>
        <path fill={this.props.fill} fillRule="nonzero" d="M30 31.92h6.667c1.84 0 3.333-1.504 3.333-3.36V5.04c0-1.856-1.492-3.36-3.333-3.36H3.333C1.493 1.68 0 3.184 0 5.04v23.52c0 1.856 1.492 3.36 3.333 3.36H10v-3.333H3.333c.006 0 0-.006 0-.027V5.04c0-.02.006-.027 0-.027h33.334c-.006 0 0 .006 0 .027v23.52c0 .02-.006.027 0 .027H30v3.333z"/>
        <path fill={this.props.fill} d="M3.333 10.08h33.333v3.36H3.333zM11.667 1.667v3.386a1.667 1.667 0 1 1-3.333 0V1.667a1.667 1.667 0 0 1 3.333 0zM21.667 1.667v3.386a1.667 1.667 0 1 1-3.333 0V1.667a1.667 1.667 0 0 1 3.333 0zM31.667 1.667v3.386a1.667 1.667 0 1 1-3.333 0V1.667a1.667 1.667 0 0 1 3.333 0z"/>
        <ellipse cx="20" cy="28.24" fillRule="nonzero" stroke={this.props.fill} strokeWidth="3.333" rx="10" ry="10.08"/>
        <path fill={this.props.fill} d="M24.226 30.124c.65.656.65 1.72 0 2.376a1.657 1.657 0 0 1-2.357 0l-3.536-3.564V23.2c0-.928.747-1.68 1.667-1.68s1.667.752 1.667 1.68v4.344l2.559 2.58z"/>
      </g>
    </svg>;
  }
}

export default SiteBooking;

import React, {Component} from 'react';


class VendorDecs extends Component {
  render() {
    const { fill } = this.props;
    return <svg width="40px" height="40px" viewBox="0 0 40 40">
      <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Icons" transform="translate(-1488.000000, -912.000000)">
          <g id="Icons-/-Dashboard-/-vendor_dec_page" transform="translate(1488.000000, 912.000000)">
            <g id="Vendor-Dec-Icon">
              <rect id="Rectangle" x="0" y="0" width="40" height="40"></rect>
              <path d="M20,1.82617188 L3.33333333,8.10016927 C3.71826183,10.3477697 4.00703014,11.9793496 4.19963827,12.9949091 C5.40826199,19.3675856 6.61940373,23.8459956 7.83306351,26.4301394 C11.9768565,35.2531693 18.2547242,38.0906169 20,38.0906169 C21.7452758,38.0906169 28.0149749,35.2654025 32.1496952,26.466839 C33.3644236,23.8819327 34.5786703,19.4004794 35.7924352,13.0224791 C35.9867274,12.0015272 36.2781379,10.3607573 36.6666667,8.10016927 L20,1.82617188 Z" id="Path-2" stroke={fill} strokeWidth="3.33333333" strokeLinejoin="round"></path>
              <polygon id="Path-Copy" fill={fill} points="30.3082275 15.4636637 18.6582275 27.1303304 11.1415609 19.6303304 13.6415609 17.1303304 18.6582275 22.1303304 27.8082275 12.9636637"></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>;
  }
}

export default VendorDecs;

import React from 'react';
import moment from 'moment';
import { mapValues, set, forEach, cloneDeep, some, isEqual, get, isEmpty, pick } from 'lodash';
import { REQUIRED_FIELD, FIELD } from '../../../common/constants';
import CommonDatePicker from '../../common/CommonDatePicker';
import CommonTextField from '../../common/CommonTextField';
import SideDrawer from '../../common/SideDrawer';
import { getCountrySystemCompanyId } from '../../../common/utils';
import CreateCompanySite from '../../../containers/CreateCompanySite';
import { required } from '../../../common/validators';
import SiteAsyncAutocomplete from '../../common/autocomplete/SiteAsyncAutocomplete';


class DeliveryInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        deliveryStartDate: cloneDeep(REQUIRED_FIELD),
        deliveryEndDate: cloneDeep(REQUIRED_FIELD),
        pickupSiteId: cloneDeep(FIELD),
        deliverySiteId: cloneDeep(FIELD)
      },
      consignorSideDrawer: false,
      consigneeSideDrawer: false
    };
    this.propagateChanges = this.propagateChanges.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
  }

  componentDidMount() {
    const { formTypeId, defaultSelectedEntity } = this.props;
    const newState = { ...this.state };
    if(formTypeId==1)
    { if(isEmpty(get(defaultSelectedEntity, 'freightPickup.consignor.handler.name')))
        set(newState.fields.pickupSiteId, 'validators', [required()]);
      if(isEmpty(get(defaultSelectedEntity, 'freightDelivery.consignee.handler.name')))
        set(newState.fields.deliverySiteId, 'validators', [required()]);
      this.setState(newState);
    }
    }
  componentDidUpdate(prevProps) {
    const { formTypeId, validate, selectedTemplate, defaultSelectedEntity } = this.props;
    const newState = { ...this.state };

    if(formTypeId==1 && defaultSelectedEntity && prevProps.defaultSelectedEntity != defaultSelectedEntity)
    { if(isEmpty(get(defaultSelectedEntity, 'freightPickup.consignor.handler.name')))
        set(newState.fields.pickupSiteId, 'validators', [required()]);
        else set(newState.fields.pickupSiteId, 'validators', []);
      if(isEmpty(get(defaultSelectedEntity, 'freightDelivery.consignee.handler.name')))
        set(newState.fields.deliverySiteId, 'validators', [required()]);
        else set(newState.fields.deliverySiteId, 'validators', []);
      this.setState(newState);
    }

    if(!prevProps.validate && validate)
      this.setAllFieldErrors();

    if(this.shouldSetDataFromTemplate(prevProps))
      this.setDataFromEntity(selectedTemplate);
    else if(this.shouldSetDataFromDefaultSelectedEntity(prevProps))
      this.setDataFromEntity(defaultSelectedEntity);
  }

  shouldSetDataFromTemplate(prevProps) {
    const { selectedTemplate } = this.props;
    return selectedTemplate && !isEqual(prevProps.selectedTemplate, selectedTemplate);
  }

  shouldSetDataFromDefaultSelectedEntity(prevProps) {
    const { defaultSelectedEntity } = this.props;
    return defaultSelectedEntity && !isEqual(prevProps.defaultSelectedEntity, defaultSelectedEntity);
  }

  setDataFromEntity(entity) {
    const newState = { ...this.state };
    if(entity.deliveryEndDate)
      newState.fields.deliveryEndDate.value = moment(entity.deliveryEndDate).format('YYYY-MM-DD');
    if(entity.deliveryStartDate)
      newState.fields.deliveryStartDate.value = moment(entity.deliveryStartDate).format('YYYY-MM-DD');
    this.setState(newState, this.setAllFieldErrors);
  }

  onChange = (value, id) => {
    const newState = { ...this.state };
    set(newState.fields, `${id}.value`, value);
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  };

  handleFarmSideDrawer = (key, bool = false) => {
    this.setState({
      [key]: bool
    });
  };

  handleFarmSubmit = (key, data) => {
    this.handleFarmSideDrawer(key, false);
    this.setState({ inputTextFarm: "" });
    data = pick(data, ['id', 'name', 'ld']);
    if (key === 'consignorSideDrawer') {
      this.handleSiteChange(data, 'pickupSiteId');
    }
    else if (key === 'consigneeSideDrawer') {
      this.handleSiteChange(data, 'deliverySiteId');
    }
  };

  handleSiteChange(item, id) {
    const newState = { ...this.state };
    const sideDrawer = id === 'pickupSiteId' ? 'consignorSideDrawer' : 'consigneeSideDrawer';
    if (item && item.inputValue) {
      this.handleFarmSideDrawer(sideDrawer, true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    }
  
    newState.fields[id].value = item;
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  }

  setFieldError(field, callback) {
    const newState = {...this.state};
    const fieldState = get(newState.fields, field);
    fieldState.errors = this.getFieldErrors(field);
    this.setState(newState, () => {
      if(callback)
        callback();
    });
  }

  getFieldErrors(field) {
    let errors = [];
    const fieldState = get(this.state.fields, field);
    if(!fieldState)
      return errors;
    fieldState.validators.forEach(validator => {
      if(validator.isInvalid(fieldState.value))
        errors.push(validator.message);
      else
        errors = [];
    });

    return errors;
  }

  setAllFieldErrors() {
    const newState = {...this.state};
    forEach(newState.fields, (state, field) => {
      state.errors = this.getFieldErrors(field);
    });
    this.setState(newState, this.propagateChanges);
  }

  hasErrors() {
    const { fields } = this.state;
    return some(fields, field => field.errors.length > 0);
  }

  propagateChanges() {
    this.props.onChange(mapValues(this.state.fields, 'value'), {deliveryInfo: this.hasErrors()});
  }

  getMaxDate() {
    const date = this.state.fields.deliveryEndDate.value;
    if(date)
      return moment(date);
  }

  getMinDate() {
    const date = this.state.fields.deliveryStartDate.value;
    if(date)
      return moment(date);
  }


  render() {
    const maxDate = this.getMaxDate();
    const minDate = this.getMinDate();
    const { fieldRef, formTypeId, defaultSelectedEntity } = this.props;
    return (
      <div>
        <h4 className='cardForm-title'>Delivery Details</h4>
        <div className='cardForm-content'>
          <div className='col-md-6'>
            <div className='datePicker form-wrap padding-reset'>
              <CommonDatePicker
                id='deliveryStartDate'
                floatingLabelText='Delivery Start Date'
                setRef={fieldRef["data.deliveryStartDate"]}
                onChange={this.onChange}
                errorText={this.state.fields.deliveryStartDate.errors[0]}
                value={this.state.fields.deliveryStartDate.value}
                maxDate={maxDate}
              />
            </div>
          </div>
          <div className='col-md-6'>
            <div className='datePicker form-wrap padding-reset'>
              <CommonDatePicker
                id='deliveryEndDate'
                floatingLabelText='Delivery End Date'
                setRef={fieldRef["data.deliveryEndDate"]}
                onChange={this.onChange}
                errorText={this.state.fields.deliveryEndDate.errors[0]}
                value={this.state.fields.deliveryEndDate.value}
                minDate={minDate}
              />
            </div>
          </div>
        {formTypeId == 1 &&
        <div className="col-md-12 form-wrap padding-reset">
          <div className="col-md-6">
          <div className='form-wrap padding-reset'>
           { !isEmpty(get(defaultSelectedEntity, 'freightPickup.consignor.handler.name')) ?
           <CommonTextField
              id='pickupSiteId'
              label='Pickup Site'
              value={get(defaultSelectedEntity, 'freightPickup.consignor.handler.name')}
              disabled
            /> :
            <SiteAsyncAutocomplete
              id='pickupSiteId'
              variant='standard'
              ref={fieldRef["data.pickupSiteId"]}
              label="Pickup Site"
              minLength={3}
              errorText={this.state.fields.pickupSiteId.errors[0]}
              selected={get(this.state.fields.pickupSiteId, 'value')}
              onChange={this.handleSiteChange}
              activeSitesOnly
              addSiteOption
            />}
          </div>
          </div>
          <div className='col-md-6'>
            {!isEmpty(get(defaultSelectedEntity, 'freightDelivery.consignee.handler.name')) ?
            <CommonTextField
              id='deliverySiteId'
              label='Delivery Site'
              value={get(defaultSelectedEntity, 'freightDelivery.consignee.handler.name')}
              disabled
            /> :
            <SiteAsyncAutocomplete
              id='deliverySiteId'
              variant='standard'
              ref={fieldRef["data.deliverySiteId"]}
              label="Delivery Site"
              minLength={3}
              errorText={this.state.fields.deliverySiteId.errors[0]}
              selected={get(this.state.fields.deliverySiteId, 'value')}
              onChange={this.handleSiteChange}
              activeSitesOnly
              addSiteOption
            />}
            </div>
       </div>}
      </div>
        {this.state.consignorSideDrawer && <SideDrawer
          isOpen={this.state.consignorSideDrawer}
          title="Add Site"
          onClose={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
          app="condition"
        >
          <CreateCompanySite closeDrawer={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
            canAccessAny={true} companyId={getCountrySystemCompanyId()}
            handleFarmSubmit={(data) => this.handleFarmSubmit("consignorSideDrawer", data)}
            inputText={this.state.inputTextFarm} />
        </SideDrawer>}
        {this.state.consigneeSideDrawer && <SideDrawer
          isOpen={this.state.consigneeSideDrawer}
          title="Add Site"
          onClose={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
          app="condition"
        >
          <CreateCompanySite closeDrawer={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
            canAccessAny={true} companyId={getCountrySystemCompanyId()}
            handleFarmSubmit={(data) => this.handleFarmSubmit("consigneeSideDrawer", data)}
            inputText={this.state.inputTextFarm} />
        </SideDrawer>}
      </div>
    );
  }
}

export default DeliveryInfo;

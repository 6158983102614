import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Farm extends Component {
  render() {
    return <Tooltip title={this.props.title || "Farms"} placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path fill={this.props.fill} fillRule="nonzero" stroke="#FFF" strokeWidth=".2" d="M22.707.197a.671.671 0 0 0-.95 0l-2.605 2.606c-.927-.12-3.526-.27-4.986 1.19-.575.575-.901 1.311-1.079 2.053a4.682 4.682 0 0 0-.82-1.104l-.474-.475 2.373-2.372a.671.671 0 1 0-.95-.95L9.42 4.943a4.693 4.693 0 0 0-1.114 1.777.671.671 0 0 0-.784.12l-.949.95a4.693 4.693 0 0 0-1.115 1.777.67.67 0 0 0-.783.121l-.949.949a4.703 4.703 0 0 0 0 6.643l.475.475L.194 21.76a.671.671 0 1 0 .95.95l4.006-4.007.474.474a4.682 4.682 0 0 0 3.322 1.374 4.683 4.683 0 0 0 3.322-1.374l.949-.949a.67.67 0 0 0 .12-.783 4.693 4.693 0 0 0 1.778-1.115l.949-.949a.671.671 0 0 0 .12-.783 4.693 4.693 0 0 0 1.778-1.115l3.796-3.796a.671.671 0 1 0-.95-.95l-2.372 2.373-.474-.474a4.682 4.682 0 0 0-1.105-.82c.742-.177 1.479-.503 2.054-1.078 1.481-1.482 1.313-4.038 1.19-4.986l2.606-2.606a.671.671 0 0 0 0-.95zM10.372 5.89l.474-.475.475.475a3.36 3.36 0 0 1 0 4.745l-.525.525A4.683 4.683 0 0 0 9.423 7.79a3.35 3.35 0 0 1 .949-1.898zM7.525 8.738l.474-.475.475.475a3.36 3.36 0 0 1 0 4.745l-.525.525a4.684 4.684 0 0 0-1.373-3.372 3.349 3.349 0 0 1 .949-1.898zM5.627 16.33l-.475.475-.474-.475a3.36 3.36 0 0 1 0-4.745l.474-.474.475.474a3.36 3.36 0 0 1 0 4.745zm5.694 1.898a3.36 3.36 0 0 1-4.745 0l-.475-.474.475-.475a3.36 3.36 0 0 1 4.745 0l.474.475-.474.474zm2.847-2.847a3.349 3.349 0 0 1-1.898.949 4.683 4.683 0 0 0-3.372-1.373l.525-.525a3.36 3.36 0 0 1 4.745 0l.475.475-.475.474zm2.847-3.796l.475.475-.475.474a3.349 3.349 0 0 1-1.898.949 4.683 4.683 0 0 0-3.372-1.373l.525-.525a3.36 3.36 0 0 1 4.745 0zm.95-3.796c-.98.979-2.823.919-3.681.828-.107-.999-.08-2.762.833-3.675.979-.98 2.822-.92 3.68-.829.107 1 .08 2.762-.833 3.676z"/>
      </g>
      </svg>
    </Tooltip>;
  }
}

export default Farm;

import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import '@babel/polyfill';
import findIndex from 'lodash/findIndex';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SideDrawer from "../../common/SideDrawer";
import {forceStopLoader, isLoading} from "../../../actions/main";
import get from "lodash/get";
import EditOrderReview from "../../freights/order-details/EditOrderReview";
import { ORDER_TYPE_NAMES } from '../../../common/constants';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../../common/DialogTitleWithCloseIcon';
import { SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS } from '../../freights/Constants';
import { isCurrentUserBelongsToCompany, openURLInNewTab } from '../../../common/utils';
import { includes, isNull } from 'lodash';


class OrderAmendRequestPendingGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      amendDialogOpen: false,
      isFetching: true,
      orders: [],
      selectedOrder: null,
      orderTypeName: null,
    };
    this.onReviewClick = this.onReviewClick.bind(this);
    this.onChange = this.onChange.bind(this);

  }

  getData() {
    APIService.freights().appendToUrl('orders/amend/acceptance/pending/').get().then(orders => {
      const isOk = isArray(orders);
      this.setState({isFetching: !isOk, orders: isOk ? orders : []}, () => {
        this.props.onDataLoad(this.state.orders.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulOrderFromList();

  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.orders.length})`;
    return `Pending Amendment Request ${suffix}`;
  }

  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  handleAmendClose = () => {
    this.setState({amendDialogOpen: false});
  };


  removeSuccessfulOrderFromList() {
    const { responseOrder } = this.props;
    const { selectedOrder, orders } = this.state;
    if(
      selectedOrder &&
      get(responseOrder, 'id') === selectedOrder.id &&
      !isEmpty(orders)
    ) {
      const index = findIndex(orders, {id: selectedOrder.id});
      this.setState({
        orders: [...orders.slice(0, index), ...orders.slice(index + 1)],
        selectedOrder: null,
      }, () => {
        this.props.onDataLoad(this.state.orders.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/freights/orders/${id}/order`);
  }

  async handleSubmit(event, order) {
    event.stopPropagation();
    event.preventDefault();
    const {token} = this.props;
    this.props.dispatch(isLoading('editOrderReview'));

    const orderDetails = await APIService.freights().appendToUrl(`orders/${order.id}/`).get(token);
    this.setState({selectedOrder: orderDetails}, () => this.props.dispatch(forceStopLoader()));
    const isCurrentUserCustomer = isCurrentUserBelongsToCompany(get(orderDetails, 'customer.companyId'))
    const isCurrentUserBelongsToPickupSite = isCurrentUserBelongsToCompany(get(orderDetails, 'freightPickup.consignor.handler.companyId'))
    const isStocksManagementOnForConsignor = get(orderDetails, 'freightPickup.consignor.handler.stocksManagement')
    let availableTonnage = parseFloat(get(order, 'availableTonnage'));
    let plannedTonnage = parseFloat(get(orderDetails, 'amendDetails.plannedTonnage') || get(order, 'plannedTonnage')) - parseFloat(get(order, 'progressTonnage', 0));
    if (isStocksManagementOnForConsignor && get(order, 'ngr') && includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, get(orderDetails, 'typeId')) && (isCurrentUserCustomer || isCurrentUserBelongsToPickupSite) && !isNull(availableTonnage) && availableTonnage < plannedTonnage) {
      let remainingTonnage = (availableTonnage - plannedTonnage).toFixed(2);
      let dialogContent = `The tonnage on this order exceeds the stored tonnage for ${get(order, 'customer')} against NGR ${get(order, 'ngr')}.
                           This will leave ${get(order, 'customer')} with ${remainingTonnage} MT.`
      this.setState({reviewDialogOpen: true, dialogContent: dialogContent});
    }
    else {
      this.onReviewClick(event);
    }
  }

  onReviewClick(event) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      reviewDialogOpen: false,
      amendDialogOpen: true,
      orderTypeName: ORDER_TYPE_NAMES.find(orderType => orderType.id == this.state.selectedOrder.typeId)?.name,
    });
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.orders))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Order No.</TableCell>
                      <TableCell align='center' className="xsmall">Customer</TableCell>
                      <TableCell align='center' className="xsmall">Freight Provider</TableCell>
                      <TableCell align='center' className="medium">Commodity Description</TableCell>
                      <TableCell align='center' className="xsmall">Pickup</TableCell>
                      <TableCell align='center' className="xsmall">Delivery</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.orders) ?
                      this.state.orders.map(order => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={order.referenceNumber}
                          onClick={(event) => this.onRowClick(event, order.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(order.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.customer)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.freightProvider)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(order.commodityDescription)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.pickupDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(order.deliveryDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={(event) => this.handleSubmit(event, order)}>
                              Review
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <SideDrawer
          isOpen={this.state.amendDialogOpen}
          onClose={this.handleAmendClose}
          title={`${this.state.orderTypeName} Order Amend Request (${this.state.selectedOrder?.identifier})`}
          classes={{'paper' : 'left-text-align'}}
          size="big"
        >
          <EditOrderReview
            order={this.state.selectedOrder}
            closeSidebar={this.handleAmendClose}
            shouldReload={false}
          />
        </SideDrawer>
        <Dialog
          open={this.state.reviewDialogOpen}
          onClose={() => this.setState({reviewDialogOpen:false, dialogContent: undefined})}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitleWithCloseIcon
            onClose={() => this.setState({reviewDialogOpen:false, dialogContent: undefined})}
            id="order-accept-dialog"
          >
            Review Order
          </DialogTitleWithCloseIcon>
          <DialogContent style={{marginTop: '10px'}}>
            <span>{this.state.dialogContent}</span>
          </DialogContent>
          <DialogActions>
            <Button type='button' onClick={() => this.setState({reviewDialogOpen:false, dialogContent: undefined})} variant='outlined'>
              Cancel
            </Button>
            <Button type='button' onClick={(event) => this.onReviewClick(event)} color='primary' variant='contained'>
              Review
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseOrder: state.companies.orders.confirmedOrRejectedOrder,
});

export default connect(mapStateToProps)(OrderAmendRequestPendingGroup);

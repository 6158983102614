import React from 'react';
import { useEffect } from 'react';
import APIService from '../../services/APIService';
import { useState } from 'react';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';


let UnregisteredVoidAccept = props => {

    const [isVoided, setIsVoided] = useState(false);
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [entity, setEntity] = useState('');

    let acceptContractVoid =  contract_id => {
        const salt = props.match ? props.match.params.salt : null;
        APIService.contracts(contract_id).appendToUrl('void/confirm/').put({status: null}, salt)
          .then((json) => {
            setIsLoaded(true);
            if(!isEmpty(json.errors)){
              setError(true);
            } else {
              setIsVoided(true);
            }
          });
    };

    let acceptOrderVoid = order_id =>{
      const salt = props.match ? props.match.params.salt : null;
      APIService.freights.orders(order_id).appendToUrl('void/confirm/').put(null, salt)
      .then((json) => {
        setIsLoaded(true);
        if(!isEmpty(json.errors)){
          setError(true);
        } else {
          setIsVoided(true);
        }
      });
    };

    let acceptMovementVoid = movement_id =>{
      const salt = props.match ? props.match.params.salt : null;
      APIService.freights.contracts(movement_id).appendToUrl('void/confirm/').put(null, salt)
      .then((json) => {
        setIsLoaded(true);
        if(!isEmpty(json.errors)){
          setError(true);
        } else {
          setIsVoided(true);
        }
      });
    };

    let acceptInvoiceVoid = invoice_id =>{
      const salt = props.match ? props.match.params.salt : null;
      APIService.invoices(invoice_id).appendToUrl('void/confirm/').put({}, salt)
      .then((json) => {
        setIsLoaded(true);
        if(!isEmpty(json.errors)){
          setError(true);
        } else {
          setIsVoided(true);
        }
      });
    };

    useEffect(() => {
        if (get(props, 'match.params.contract_id', false)){
            setEntity('Contract');
            acceptContractVoid(get(props, 'match.params.contract_id'));
        }
        else if (get(props, 'match.params.order_id', false)){
          setEntity('Order');
          acceptOrderVoid(get(props, 'match.params.order_id'));
        }
        else if (get(props, 'match.params.movement_id', false)){
          setEntity('Movement');
          acceptMovementVoid(get(props, 'match.params.movement_id'));
        }
        else if (get(props, 'match.params.invoice_id', false)){
          setEntity('Invoice');
          acceptInvoiceVoid(get(props, 'match.params.invoice_id'));
        }

    },[]);

    return (<div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
              {
              isVoided ? (
               <span>{entity} voided successfully.</span>
              ) : isLoaded && error &&
                <span>You do not have permission to access the information</span>
              }
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>);
};

export default UnregisteredVoidAccept;

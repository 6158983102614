import React from 'react';

import { connect } from 'react-redux';
import {
  List, ListItem, ListItemText, ListItemIcon
} from '@mui/material';
import ScheduleIcon from '@mui/icons-material/TrackChanges';
import { map, keys, capitalize, isEmpty, get, includes, find } from 'lodash';
import APIService from '../../services/APIService';
import './slotHistory.scss';
import SideDrawer from '../common/SideDrawer';
import CommonButton from '../common/CommonButton';
import LoaderInline from '../LoaderInline';
import { getCountryLabel } from '../../common/utils';


class BulkHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: {},
      isLoading: false,
      maxDate: null,
    };
  }

  componentDidMount() {
    const { slotIds, siteId, timezone } = this.props;
    if(slotIds, siteId) {
      this.setState({isLoading: true}, () => {
        APIService
          .company_sites(siteId)
          .slots()
          .appendToUrl('history/')
          .post({slot_ids: slotIds, timezone: get(timezone, 'location')})
          .then(history => {
            this.setState({isLoading: false, history: get(history, 'history', {}), maxDate: get(history, 'maxHistoryDate')});
          });
      });
    }
  }

  render() {
    const { show, schedule, getTitle, onClick, onClose } = this.props;
    const { history, isLoading } = this.state;
    return (
      <div>
        <SideDrawer
          isOpen={show}
          onClose={onClose}
          size='small'
          title='Activity Log'
        >
          <div style={{padding: '0px'}}>
            {
              isLoading ?
              <LoaderInline
                style={{height: '500px'}}
                containerClassName="inline-loader-container"
                imageStyle={{width: '25%'}}
              /> :
              <List>
                {
                  map(history, (changeSets, date) => {
                    return (
                      map(changeSets, (changeSet, i) => {
                        const slotId = changeSet.id;
                        const slot = find(schedule, {id: slotId.toString()});
                        const title = slot ? getTitle(slot) : '';
                        const heading = title + ' - ' + changeSet.heading;
                        return (
                          <div className='changeset-container' key={date + '-' + i}>
                            <ListItem key={date} style={{padding: '10px 0'}}>
                              <ListItemIcon style={{marginRight: '0px'}}>
                                <ScheduleIcon fontSize='small' style={{color: slot ? slot.dragBgColor : '#000'}} />
                              </ListItemIcon>
                              <ListItemText className="changeset-item-text clickable" primary={heading} secondary={date} onClick={event => onClick({event: event, schedule: slot})} />
                            </ListItem>
                            {
                              !isEmpty(changeSet.items) &&
                              <div className='changeset-items'>
                                {
                                  map(changeSet.items, (item, index) => {
                                    let field = keys(item)[0];
                                    let value = item[field].new;
                                    if(field.toLowerCase() === 'extras' ){
                                      field = keys(value)[0];
                                      value = value[field];
                                      if(includes(['fleet_reminder_sent_at', 'fleetReminderSentAt'], field)){
                                        field = `Reminder Sent to update Truck ${getCountryLabel('rego')} in Slot`;
                                        value = '';
                                      }
                                    }
                                    return (
                                      <span key={index} className='changeset-item'>
                                        <span className='changeset-label'>{capitalize(field)}</span>
                                        <span className='changeset-value'>{value}</span>
                                      </span>
                                    );
                                  })
                                }
                              </div>
                            }
                          </div>
                        );
                      })
                    );
                  })
                }
              </List>
            }
          </div>
          <div className='col-sm-12 cardForm-action top15 padding-reset' style={{textAlign: 'center'}}>
            <CommonButton type='button' label='Close' default onClick={onClose} variant="contained" />
          </div>
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  };
};

export default connect(mapStateToProps)(BulkHistory);

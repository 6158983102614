import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class TitleTransfer extends Component {
  render() {
    const height = this.props.height || 24;
    const width = this.props.width || 24;
    return <Tooltip title="Title Transfers" placement="right">
      <svg width={width} height={height} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-1392.000000, -624.000000)">
            <g id="Icons-/-Dashboard-/-title_transfer" transform="translate(1392.000000, 624.000000)">
              <g id="Title-Transfer-Copy-2">
                <rect id="Rectangle-Copy-7" x="5.45696821e-13" y="0" width="24" height="24"></rect>
                <path stroke={this.props.fill} d="M10,5 L1,5 L1,21 L10,21 L10,5 Z" id="Rectangle-9" strokeWidth="2"></path>
                <path d="M10,5 C10,2.51471863 7.98528137,1 5.5,1 C3.01471863,1 1,2.51471863 1,5 C1,5 10,5 10,5 Z" id="Oval-3" stroke={this.props.fill} strokeWidth="2"></path>
                <rect id="Rectangle-10" fill={this.props.fill} x="6" y="7" width="2" height="4" rx="1"></rect>
                <rect id="Rectangle-10-Copy-2" fill={this.props.fill} x="9" y="20" width="2" height="4" rx="1"></rect>
                <rect id="Rectangle-10-Copy-3" fill={this.props.fill} x="1.81987758e-13" y="21" width="2" height="3" rx="1"></rect>
                <rect id="Rectangle-10-Copy" fill={this.props.fill} x="6" y="12" width="2" height="7" rx="1"></rect>
                <rect id="Rectangle-10-Copy" fill={this.props.fill} transform="translate(17.800000, 12.300000) rotate(-270.000000) translate(-17.800000, -12.300000) " x="16.8" y="6.6" width="2" height="11.4" rx="1"></rect>
                <rect id="Rectangle-10-Copy-4" fill={this.props.fill} transform="translate(20.800000, 10.800000) rotate(-228.000000) translate(-20.800000, -10.800000) " x="19.8" y="7.8" width="2" height="6" rx="1"></rect>
                <rect id="Rectangle-10-Copy-5" fill={this.props.fill} transform="translate(20.800000, 13.800000) scale(1, -1) rotate(-228.000000) translate(-20.800000, -13.800000) " x="19.8" y="10.8" width="2" height="6" rx="1"></rect>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default TitleTransfer;

import axios from "axios";
import { apiURL, forceStopLoader } from "../main";
import { capitalize } from 'lodash';
import isEqual from "lodash/isEqual";
import { getLoadReferences } from "../../components/stocks/utils";
import { addPageCacheQueryParamsToUrl, isAtStocksSiteLoads, toDateTimeFormat } from "../../common/utils";
import get from "lodash/get";
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const RECEIVE_COMPANY_SITES = 'RECEIVE_COMPANY_SITES';
export const ADD_COMPANY_SITE = 'ADD_COMPANY_SITE';
export const EDIT_COMPANY_SITE = 'EDIT_COMPANY_SITE';
export const CANCEL_EDIT_COMPANY_SITE = 'CANCEL_EDIT_COMPANY_SITE';
export const CLICK_ADD_COMPANY_SITE_BUTTON = 'CLICK_ADD_COMPANY_SITE_BUTTON';
export const CLICK_EDIT_COMPANY_SITE_ICON = 'CLICK_EDIT_COMPANY_SITE_ICON';
export const ADD_COMPANY_SITE_ERRORS = 'ADD_COMPANY_SITE_ERRORS';
export const EDIT_COMPANY_SITE_ERRORS = 'EDIT_COMPANY_SITE_ERRORS';
export const RECEIVE_COMPANY_BHC_SITES = 'RECEIVE_COMPANY_BHC_SITES';
export const RECEIVE_FREIGHT_SLOTS = 'RECEIVE_FREIGHT_SLOTS';
export const RECEIVE_MULTIPLE_FREIGHT_SLOTS = 'RECEIVE_MULTIPLE_FREIGHT_SLOTS';
export const RECEIVE_FREIGHT_SLOT = 'RECEIVE_FREIGHT_SLOT';
export const DELETE_FREIGHT_SLOT = 'DELETE_FREIGHT_SLOT';
export const DELETE_MULTIPLE_FREIGHT_SLOTS = 'DELETE_MULTIPLE_FREIGHT_SLOTS';
export const RECEIVE_FREIGHT_SLOT_UPDATED = 'RECEIVE_FREIGHT_SLOT_UPDATED';
export const RECEIVE_SM_SETTINGS = 'RECEIVE_SM_SETTINGS';
export const RECEIVE_SLOT_COMMENT = 'RECEIVE_SLOT_COMMENT';
export const RECEIVE_SLOT_COMMENT_UPDATED = 'RECEIVE_SLOT_COMMENT_UPDATED';
export const RECEIVE_SLOT_COMMENTS = 'RECEIVE_SLOT_COMMENTS';
export const RECEIVE_COMPANY_SITE_LOADS = 'RECEIVE_COMPANY_SITE_LOADS'

export const receiveCompanySites = items => ({
  type: RECEIVE_COMPANY_SITES,
  items,
});

export const receiveSMSettings = settings => ({
  type: RECEIVE_SM_SETTINGS,
  settings,
});

export const receiveFreightSlots = items => ({
  type: RECEIVE_FREIGHT_SLOTS,
  items,
});

export const receiveMultipleFreightSlots = items => ({
  type: RECEIVE_MULTIPLE_FREIGHT_SLOTS,
  items,
});

export const receiveFreightSlot = item => ({
  type: RECEIVE_FREIGHT_SLOT,
  item,
});

export const deleteFreightSlot = slotId => ({
  type: DELETE_FREIGHT_SLOT,
  slotId,
});

export const deleteMultipleFreightSlots = slotIds => ({
  type: DELETE_MULTIPLE_FREIGHT_SLOTS,
  slotIds,
});

export const receiveFreightSlotUpdated = item => ({
  type: RECEIVE_FREIGHT_SLOT_UPDATED,
  item,
});

export const receiveCompanyBHCSites = items => ({
  type: RECEIVE_COMPANY_BHC_SITES,
  items,
});

export const addCompanySite = item => ({
  type: ADD_COMPANY_SITE,
  item,
});

export const addCompanySiteErrors = errors => ({
  type: ADD_COMPANY_SITE_ERRORS,
  errors,
});

export const editCompanySite = item => ({
  type: EDIT_COMPANY_SITE,
  item,
});

export const editCompanySiteErrors = errors => ({
  type: EDIT_COMPANY_SITE_ERRORS,
  errors,
});

export const cancelEditCompanySite = () => ({
  type: CANCEL_EDIT_COMPANY_SITE,
});

export const clickAddCompanySiteButton = () => ({
  type: CLICK_ADD_COMPANY_SITE_BUTTON,
});

export const clickEditCompanySiteIcon = index => ({
  type: CLICK_EDIT_COMPANY_SITE_ICON,
  index,
});

export const receiveSlotComment = comment => ({
  type: RECEIVE_SLOT_COMMENT,
  comment,
});

export const receiveSlotCommentUpdated = comment => ({
  type: RECEIVE_SLOT_COMMENT_UPDATED,
  comment,
});

export const receiveSlotComments = comments => ({
  type: RECEIVE_SLOT_COMMENTS,
  comments,
});

export const receiveCompanySiteLoads = (paginatedSites, selectedLocation) => ({
  type: RECEIVE_COMPANY_SITE_LOADS,
  paginatedSites,
  selectedLocation
})

export const getSiteLoads = (url='', _forceStopLoader=false, selectedLocation) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    let companyId = getState().main.user.user.companyId;
    let selectedSiteId = selectedLocation || getState().companies.companySites.selectedSiteId;
    if(! selectedSiteId && ! url)
      return;
    let siteLoadsUrl = (url) ? url : `${apiURL}/companies/${companyId}/company_sites/${selectedSiteId}/loads/`;
    if(isAtStocksSiteLoads()) {
      siteLoadsUrl = addPageCacheQueryParamsToUrl(URL)
    }

    axios.get(siteLoadsUrl, config)
      .then((response) => {
        response.data.results.forEach(item => {
          item.createdAt = toDateTimeFormat(item.dateTime)
          item.subType = capitalize(item.subType);
          let refs =  getLoadReferences(item).split(',')
          let identifier = refs.at(-1)
          item.creator = get(item, 'titleTransferId') ? get(item, 'titleTransferCreator') : get(item, 'creator')
          if(identifier && ! isEqual(identifier, '-10')) {
              item.identifier = identifier.toUpperCase();
          }
          let typeRefs = getLoadReferences(item, true);
          let type = typeRefs.at(-1);
          if(type && ! get(item, 'shrinkageParent', false))
              item.type = type.ref;
          else if((get(item, 'optionType') == -4))
            item.type = 'Stock Swap';
          else if((get(item, 'shrinkageParent', false)))
            item.type = 'Shrinkage';
        });
        dispatch(receiveCompanySiteLoads(response.data, selectedSiteId));
        if(_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getSiteLoadsUrl = (pageSize, page, searchText, orderBy, order, selectedLocation) => {
  return (dispatch, getState) => {
    let companyId = getState().main.user.user.companyId;
    let selectedSiteId = selectedLocation || getState().companies.companySites.selectedSiteId;
    if(! selectedSiteId)
      return '';
    let url = `${apiURL}/companies/${companyId}/company_sites/${selectedSiteId}/loads/`;
    url += `search/?page_size=${pageSize}`;
    if (page) {
        url+=`&page=${page}`;
    }
    if(searchText) {
      url+=`&search=${searchText}`;
    }
    if(orderBy) {
      url+=`&order_by=${orderBy}&order=${order}`;
    }
    return url;
  }
}

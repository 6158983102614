import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { clickEditFarmFieldIcon } from '../actions/companies/farm-fields';
import { farmSideDrawerForTransfer } from '../actions/companies/farms';
import { isSystemCompany } from '../common/utils';
import { showSideDrawerForMerge } from '../actions/companies/index';
import { getCountryConfig } from '../common/utils';
import reject from 'lodash/reject';
import { deleteFarmField } from '../actions/api/farm-fields';

const mapStateToProps = (state, props) => {
  const config = {
    columns: [
      { key: 'name', header: 'Field Name', },
      { key: 'marketzone.name', header: 'Market Zone' },
      { key: 'region.name', header: 'Region' },
      { key: 'ownershipDisplay', header: 'Ownership Type' },
      { key: 'size', header: `Size (in ${getCountryConfig()?.farmField?.sizeUnit})` },
      { key: 'address', header: 'Location', map: {
        name: 'address.address',
        lat: 'address.latitude',
        lng: 'address.longitude'
      }},
    ],
    items: reject(state.companies.farmFields.items, { isUnknown: true }),
    scrollToTopOnUpdate: false,
    orderBy: 'name',
    order: 'asc',
    triggeredForceStopLoader: state.companies.farmFields.items.length == 1,
    optionsItems: []
  };
  if (isSystemCompany())
    config.optionsItems =  [{ key: 'transfer', text: 'Transfer' }];
  if (isSystemCompany())
    config.optionsItems.push({ key : 'merge', text: 'Merge' });
  if (props.canActOnFarm)
    config.optionsItems.push({ key : 'delete', text: 'Delete' });
  return config;
};

const mapDispatchToProps = (dispatch) => {
  return {
    handleDefaultCellClick: (item) => {
      dispatch(clickEditFarmFieldIcon(item.id));
    },
    handleOptionClick: (index,key,id, item) => {
      if (key === 'transfer'){
        dispatch(farmSideDrawerForTransfer(item,true));
      }
      if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
      if (key === 'delete') {
        dispatch(deleteFarmField(item));
      }
    }
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);

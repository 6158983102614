import React from 'react';
export const AppContext = React.createContext('');
export const AppProvider = AppContext.Provider;
export const AppConsumer = AppContext.Consumer;

const LayoutContext = ({ subPages }) => {
  const [device, setDevice] = React.useState('web');
  return (
    <AppProvider
      value={{
        setDevice: setDevice,
        device: device,
        isMobileDevice: device === 'mobile'
      }}>
      {subPages}
    </AppProvider>
  )
}
export default LayoutContext;

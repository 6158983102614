import React from "react";
import styled from "styled-components";

import SpotlightContext from "./SpotlightContext";
import { isEmpty, capitalize, map } from 'lodash';
import media from "./media";

const List = styled.div`
  width: 100%;
  overflow: hidden;
  overflow-y: auto;
  user-select: none;
  position: absolute;
  height: calc(100% - 55px);
  border-right: 1px solid #515253;
  background-color: rgba(0, 20, 41, 0.97);

  ${media.pc`width: 100%;`};

  ul {
    min-height: calc(100% - 55px);
  }

  ul,
  li {
    margin: 0;
    padding: 0;
    width: 100%;
    list-style-type: none;
  }
  `;

const ResultHeader = styled.div`
  color: #ffffff;
  font-size: 12px;
  padding: 4px 0 2px 25px;
  text-transform: uppercase;
  background-color: rgba(53, 75, 84, 0.97);
`;

const Hit = styled.li`
  color: #ffffff;
  cursor: pointer;
  font-size: 12px;
  padding: 6px 6px 6px 25px !important;

  ${props => props.selected && `color: #ffffff; background-color: #0093f8;`};
  `;

export default () => (
  <SpotlightContext.Consumer>
    {({ hits, selectedResultIndex, selectHit, toggle }) => (
      <List>
        <ul>
          {
            map(hits, (results, header) => {
              return (
                <li key={`hit-group-${header}`}>
                  <ul>
                    {map(results, (hit, index) => (
                      <li className='spotlight-search-result' key={`hit-group-${hit.id}`}>
                        <a onClick={() => {window.location.hash = hit.url; toggle();}}>
                          <Hit
                            selected={index === selectedResultIndex}
                          >{`${hit.name}`}</Hit>
                        </a>
                      </li>
                    ))}
                    {
                      results.length === 0 &&
                      <li className='spotlight-search-result'>
                        <Hit>No Results</Hit>
                      </li>
                    }
                  </ul>
                </li>
              )
            })
          }
          {isEmpty(hits) && <li>No Results</li>}
        </ul>
      </List>
    )}
  </SpotlightContext.Consumer>
);

import React, {Component} from 'react';
import { connect } from 'react-redux';
import {
  Dialog, DialogActions, DialogContent, TextField, Checkbox, FormControlLabel,
  ListItem, ListItemAvatar, Avatar, Chip,
} from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {
  map, find, startCase, isEmpty, includes, some, get, set, has, every, forEach,
  snakeCase, isEqual, reject, filter, without, uniqBy
} from 'lodash';
import parse from 'autosuggest-highlight/parse';
import match from 'autosuggest-highlight/match';
import Autocomplete from '@mui/material/Autocomplete';
import { DialogTitleWithCloseIcon } from './DialogTitleWithCloseIcon';
import CommonButton from './CommonButton';
import { required } from '../../common/validators';
import alertifyjs from 'alertifyjs';
import { EMAIL_REGEX } from '../../common/constants';
import './customEmailDialog.scss';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import { getCompanyCompaniesMinimal } from '../../actions/api/companies';
import APIService from '../../services/APIService';


class CustomEmailDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      partyContacts: {},
      defaultSubjectApplied: false,
      defaultBodyApplied: false,
      open: false,
      shouldHandleDismiss: true,
      xeroCreate: false,
      subject: {
        value: '',
        errors: [],
        validators: [required()],
      },
      body: {
        value: '',
        errors: [],
        validators: [],
      },
      acceptanceRequired: {
        value: false,
        errors: [],
        validators: [],
      },
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleCheckboxFieldChange = this.handleCheckboxFieldChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAcceptanceRequiredChange = this.handleAcceptanceRequiredChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  componentDidMount() {
    if(has(this.props, 'isAcceptanceRequired') && !this.props.forceSetAcceptanceRequired)
      this.setFieldValue('acceptanceRequired', false);
    if(this.props.isAcceptanceRequired && this.props.forceSetAcceptanceRequired)
      this.setFieldValue('acceptanceRequired', true);
    if(this.props.parties)
      this.setUpParties(this.props.parties, this.setUpSelectedParties);
    if(get(this.props.partyContacts, 'then')) {
      this.props.partyContacts.then(contacts => {
        this.setState({partyContacts: contacts}, this.setUpSelectedParties);
      });
    } else this.setState({partyContacts: this.props.partyContacts});
    this.props.dispatch(getCompanyCompaniesMinimal(this.props.companyId, { include_parent_company: true}));
    if(this.props.xero && this.props.currentUserCompany.isValidXeroConnection)
      this.setState({xeroCreate: this.props.xeroCounterPartyExists})
  }

  shouldUpdateSubject(prevProps) {
    const { subject } = this.props;
    return (
      isEmpty(this.state.subject.value) &&
      !isEmpty(subject) &&
      !includes(subject, 'undefined') &&
      !this.state.defaultSubjectApplied
    ) || (
      prevProps.subject !== subject
    );
  }

  __setParties() {
    const { allCompanyParties } = this.props;
    if (
      isEmpty(this.state.companies) &&
      !isEmpty(allCompanyParties)
    )
      this.setState({ companies: allCompanyParties});
  }

  componentDidUpdate(prevProps) {
    const { parties, open, subject, body, selectedParties } = this.props;
    this.__setParties();

    if (!isEqual(parties, prevProps.parties))
      this.setUpParties(parties, this.setUpSelectedParties);

    if(this.state.open !== open) this.toggle();

    if(this.shouldUpdateSubject(prevProps))
      this.setState(
        {defaultSubjectApplied: true},
        () => { this.setFieldValue('subject', subject); }
      );

    if(isEmpty(this.state.body.value) && !isEmpty(body) && !this.state.defaultBodyApplied)
      this.setState(
        {defaultBodyApplied: true},
        () => { this.setFieldValue('body', body); }
      );

    if (!isEqual(selectedParties, prevProps.selectedParties))
      this.setUpSelectedParties();
  }

  setUpParties = (parties, callback) => {
    const newState = {...this.state};
    parties.forEach(_party => {
      const party = get(_party, 'value', _party);
      newState[party] = {self: false, others: []};
    });
    this.setState(newState, () => {
      if (typeof callback === 'function') callback();
    });
  };

  setUpSelectedParties = () => {
    const { selectedParties } = this.props;
    if (!isEmpty(selectedParties)) {
      const newState = { ...this.state };
      forEach(this.props.parties, party => {
        const _party = get(party, 'value', party);
        if (has(newState, _party)) newState[_party].self = false;
      });
      forEach(selectedParties, selected => {
        if (has(newState, selected)) {
          newState[selected].self = true;
          newState[selected].others = this.getPartySelectedContactEmails(selected);
        }
      });
      this.setState(newState);
    }
  };

  toggle() {
    if(this.props.open) {
      this.handleOpen();
    } else if(this.state.shouldHandleDismiss){
      this.handleDismiss();
    } else if (!this.props.open) {
      this.handleClose();
    }
  }

  handleOpen() {
    this.setState({open: true});
  }

  handleClose = () => this.setState({ open: false }, () => this.props.onClose(null, true));

  handleDismiss() {
    this.setState({open: false, shouldHandleDismiss: false}, () => {
      const payload = {acceptanceRequired: this.state.acceptanceRequired.value}
      if(this.props.xero && this.props.currentUserCompany.isValidXeroConnection)
        payload.createXeroInvoice = this.state.xeroCreate
      this.props.onClose(payload);
    });
  }

  handleDone() {
    if(this.isFormValid()) {
      const { parties, isScheduled, onClose } = this.props;
      const recipients = {};
      forEach(parties, party => {
        const _party = get(party, 'value', party);
        recipients[snakeCase(_party)] = this.state[_party].others;
      });
      const data = {
        body: this.state.body.value,
        subject: this.state.subject.value,
        recipients: recipients,
        scheduled: !!isScheduled,
        acceptanceRequired: this.state.acceptanceRequired.value,
      };

      if (this.props.isIndependent) {
        const companyIds = {};
        forEach(parties, party => {
          const _party = get(party, 'value', party);
          companyIds[snakeCase(_party)] = this.state[_party].selectedCompany;
        });
        data['partiesCompanyIds'] = companyIds;
      }

      if(this.props.xero && this.props.currentUserCompany.isValidXeroConnection)
        data.createXeroInvoice = this.state.xeroCreate

      this.setState({open: false, shouldHandleDismiss: false}, () => {
        onClose(data);
      });
    } else if (!this.isAnyPartySelected()) {
      alertifyjs.error('Please select at least one recipient to send the email');
    } else if (!this.everySelectedPartyHasEmails()) {
      alertifyjs.error('Please enter email(s)');
    } else if (!this.areEmailsValid()) {
      alertifyjs.error('Please correct invalid emails');
    }
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    if(event.target.id === 'subject') {
      this.setFieldErrors('subject');
    }
  }

  isFormValid() {
    this.setFieldErrors('subject');
    return isEmpty(this.state.subject.errors) && this.isAnyPartySelected() && this.everySelectedPartyHasEmails() && this.areEmailsValid();
  }

  isAnyPartySelected() {
    return some(this.props.parties, _party => {
      const party = get(_party, 'value', _party);
      return get(this.state[party], 'self');
    });
  }

  arePartyContactsValid(party) {
    const partyInfo = get(this.state, party);
    return partyInfo &&
           every(partyInfo.others, email => this.isValidEmail(email));
  }

  areEmailsValid() {
    return every(this.props.parties, _party => {
      const party = get(_party, 'value', _party);
      if(this.state[party].self)
        return this.arePartyContactsValid(party);
      return true;
    });
  }

  everySelectedPartyHasEmails() {
    return every(this.props.parties, _party => {
      const party = get(_party, 'value', _party);
      if(this.state[party].self)
        return !isEmpty(this.state[party].others);
      return true;
    });
  }

  setFieldErrors(id) {
    const errors = [];
    const newState = {...this.state};
    const field = get(newState, id);
    if(field.validators && field.validators.length > 0) {
      field.validators.forEach(validator => {
        if(validator.isInvalid(field.value))
          errors.push(validator.message);
      });
    }
    set(newState, `${id}.errors`, errors);
    this.setState(newState);
  }

  setFieldValue(id, value) {
    const newState = {...this.state};
    newState[id].value = value;
    this.setState(newState);
  }

  getPartySelectedContactEmails(party) {
    const partyEmail = get(this.props.partyEmails, party);
    if (Array.isArray(partyEmail)) {
      let selectedPartyEmail = [];
      partyEmail.map(email => {
        selectedPartyEmail.push(get(map(filter(this.getContacts(party), {email: email}), 'email'), '0'));
      });
      return selectedPartyEmail;
    }
    else {
      return map(filter(this.getContacts(party), {email: partyEmail}), 'email');
    }
  }

  handleCheckboxFieldChange(event) {
    const party = event.target.id;
    const newState = {...this.state};
    newState[party].self = event.target.checked;
    if (event.target.checked){
      newState[party].others = this.getPartySelectedContactEmails(party);
    } else {
      newState[party].others = [];
    }
    this.setState(newState);
  }

  handleAcceptanceRequiredChange(event) {
    const newState = {...this.state};
    set(newState, `${event.target.id}.value`, event.target.checked);
    this.setState(newState);
  }

  handleXeroCreateChange = event => this.setState({xeroCreate: event.target.checked})

  handleOtherEmailFieldChange(event, items, party) {
    if(!party)
      return;

    const newState = {...this.state};

    if(!this.isCustomEmailAllowed(party))
      items = reject(items, item => !item?.email && !find(this.props.partyContacts[party], {email: item}))

    newState[party].others = map(items, item => get(item, 'email', item));

    this.setState(newState);
  }

  onBlur = (event, party) => {
    if(!party || !event.target.value)
      return;
    this.handleOtherEmailFieldChange(
      event, [...this.state[party].others, event.target.value], party
    );
  };

  handleContactDelete(event, item, party) {
    if(!party)
      return;

    const newState = {...this.state};
    newState[party].others = without(newState[party].others, get(item, 'email', item));
    this.setState(newState);
  }

  getLabel = (party, partyLabel) => {
    if (partyLabel) return partyLabel;
    if (party === 'provider') party = 'freight provider';
    if (party === 'transferSite') party = 'Transfer Site';
    if (party === 'consignor') party = 'Pickup Site';
    if (party === 'consignee') party = 'Delivery Site';
    if (party === 'packProvider') party = 'Pack Provider';
    return startCase(party);
  };

  getContacts(party) {
    const { partyContacts } = this.state;
    if(party && get(partyContacts, party)) {
      return map(
        filter(get(partyContacts, party, []), contact => this.isValidEmail(contact.email)),
        contact => {
          contact.title = `${contact.name} (${contact.email})`;
          return contact;
        }
      );
    }
    return [];
  }

  isValidEmail(email) {
    return email && EMAIL_REGEX.test(email);
  }

  shouldShowErrorOnMoreLabel(contacts) {
    return Boolean(find(contacts.slice(2), contact => !this.isValidEmail(get(contact, 'email', contact))));
  }

  handleCompanyChange(value, field, party) {
    const newState = { ...this.state };
    if (!value) {
      newState[party].selectedCompany = null;
      newState[party].selectedContact = null;
      this.setState(newState);
      return;
    }
    newState[party].selectedCompany = value;
    {
      APIService.contracts().companies(value).contacts().get()
                .then(contacts => {
                  newState[party].selectedContact = uniqBy(contacts, 'id');
                  this.setState(newState);
                });
    }
  }

  isCustomEmailAllowed = party => !has(this.props, 'customEmailParties') || this.props.customEmailParties.includes(party)

  render() {
    return (
      <Dialog fullWidth open={this.state.open} onClose={this.handleClose} scroll='paper' maxWidth={this.props.isIndependent ? 'md' : 'sm'}>
        <DialogTitleWithCloseIcon
          onClose={this.handleClose}
          id="custom-email-dialog"
        >
          {this.props.title}
        </DialogTitleWithCloseIcon>
        <DialogContent style={{marginTop: '10px'}}>
          {
            map(this.props.parties, (_party, index) => {
              const party = get(_party, 'value', _party);
              const contacts = this.getContacts(party);
              const selectedContacts = [
                ...filter(
                  contacts,
                  contact => includes(get(this.state[party], 'others', []), contact.email)
                ),
                ...reject(
                  get(this.state[party], 'others', []),
                  email => includes(map(contacts, 'email'), email)
                ),
              ];
              const isCustomAllowed = this.isCustomEmailAllowed(party)
              return (
                <div key={index} className="col-sm-12 padding-reset party-row">
                  <div className="col-md-3 padding-reset" style={{marginTop: '10px', clear: 'left'}}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id={party}
                             onChange={this.handleCheckboxFieldChange}
                             checked={get(this.state[party], 'self')}
                             disabled={includes(get(this.props, 'disabledPartiesForEmail', []), party)}
                        />
                      }
                      label={this.getLabel(party, get(_party, 'label'))}
                    />
                  </div>
                  {this.props.isIndependent &&
                   <div className="col-md-6 no-left-padding">
                     <CommonAutoSelect
                       items={this.state.companies}
                       label="Company"
                       id={`${party}-companies`}
                       value={get(this.state[party], 'selectedCompany')}
                       onChange={(value, field) => this.handleCompanyChange(value, field, party)}
                     />
                   </div>
                  }
                  <div className="col-md-9 padding-reset contact-selector-container">
                    <Autocomplete
                      multiple
                      freeSolo={isCustomAllowed}
                      clearIcon={false}
                      limitTags={2}
                      id={`${party}-others`}
                      options={get(this.state[party], 'selectedContact')|| contacts || []}
                      value={ selectedContacts }
                      getOptionLabel={(option) => get(option, 'title', option)}
                      onChange={(event, items) => this.handleOtherEmailFieldChange(event, items, party)}
                      onBlur={(event) => this.onBlur(event, party)}
                      disabled={!get(this.state[party], 'self')}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" fullWidth helperText={(isCustomAllowed || !get(this.state[party], 'self')) ? undefined : `Custom emails are not allowed in this because ${startCase(party)} party is a subscriber.`} />
                      )}
                      renderTags={ (value, getTagProps) =>
                        value.map((option, index) => (
                          <Chip
                            key={index}
                                label={get(option, 'title', option)}
                                {...getTagProps({ index })}
                                className={this.isValidEmail(get(option, 'email', option)) ? "chip-inline-ellipsis" : "chip-inline-ellipsis error"}
                                onDelete={(event) => this.handleContactDelete(event, option, party)} />
                        ))
                      }
                      getLimitTagsText={
                        more => (
                          <span className={this.shouldShowErrorOnMoreLabel(selectedContacts) ? "more-label error" : "more-label"}>
                            {`${more} more`}
                          </span>
                        )
                      }
                      renderOption={(params, option, { inputValue }) => {
                          const matchesName = match(option.name, inputValue);
                          const partsName = parse(option.name, matchesName);
                          const matchesEmail = match(option.email, inputValue);
                          const partsEmail = parse(option.email, matchesEmail);
                          return (
                              <ListItem {...params} style={{cursor: 'pointer'}} for={party} className="contact-list-item">
                                <ListItemAvatar className="contact-avatar">
                                  <Avatar>
                                    <PersonIcon />
                                  </Avatar>
                                </ListItemAvatar>
                                <div className='list-item-text'>
                                  <span className='primary'>
                                    {partsName.map((part, index) => (
                                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                        {part.text}
                                      </span>
                                    ))}
                                  </span>
                                  <p className="secondary">
                                    {partsEmail.map((part, index) => (
                                      <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: part.highlight ? 'rgb(0, 0, 0)' : 'rgba(0, 0, 0, 0.54)' }}>
                                        {part.text}
                                      </span>
                                    ))}

                                  </p>
                                </div>
                              </ListItem>
                          );
                      }}
                    />
                  </div>
                </div>
              );
            })
          }
          <div className="col-md-12 padding-reset" style={{marginTop: '20px'}}>
            <TextField
              id="subject"
              label="Subject"
              value={this.state.subject.value}
              onChange={this.handleFieldChange}
              style={{width: '100%'}}
              helperText={this.state.subject.errors[0]}
              error={!isEmpty(this.state.subject.errors)}
              variant="standard" />
          </div>
          {
            !this.props.noBody &&
            <div className="col-md-12 padding-reset" style={{marginTop: '20px'}}>
              <TextField
                id="body"
                label="Body"
                value={this.state.body.value}
                onChange={this.handleFieldChange}
                style={{width: '100%'}}
                helperText={this.state.body.errors[0]}
                error={!isEmpty(this.state.body.errors)}
                multiline
                rows={6}
                variant="standard" />
            </div>
          }
          <div className="col-md-12 padding-reset" style={{marginTop: '20px'}}>
            <FormControlLabel
              control={
                <Checkbox
                  id='acceptanceRequired'
                  onChange={this.handleAcceptanceRequiredChange}
                  checked={this.state.acceptanceRequired.value}
                  disabled={this.props.disableAcceptanceRequired}
                />
              }
              label='Acceptance Required'
            />
          </div>
          {
            this.props.xero &&
              this.props.currentUserCompany.isValidXeroConnection &&
              <div className="col-md-12 padding-reset" style={{marginTop: '20px'}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      id='xeroCreate'
                      onChange={this.handleXeroCreateChange}
                      checked={this.state.xeroCreate}
                      disabled={!this.props.xeroCounterPartyExists}
                    />
                  }
                  label={
                    this.props.xeroCounterPartyExists ?
                      'Create in Xero' :
                      <span style={{color: 'red'}}>
                        Cannot create in Xero as counter party does not exist as a Contact
                      </span>
                  }
                />
              </div>
          }
          {
            this.props.xero &&
              !this.props.currentUserCompany.isXeroAccountLinked &&
              <div className="col-md-12 padding-reset" style={{marginTop: '20px'}}>
                <p style={{color: 'red'}}>Warning: Not connected to Xero</p>
              </div>
          }
          {
            this.props.xero &&
              this.props.currentUserCompany.isXeroAccountLinked &&
              !this.props.currentUserCompany.isValidXeroConnection &&
              <div className="col-md-12 padding-reset" style={{marginTop: '20px'}}>
                <p style={{color: 'red'}}>Warning: Xero Connection Expired</p>
              </div>
          }
        </DialogContent>
        <DialogActions style={{display: 'inline-block'}}>
          {
            this.props.footer &&
            <p className="col-md-6 padding-reset" style={{fontSize: '13px'}}> Note: {this.props.footer} </p>
          }
          <CommonButton
            key="dismissButton"
            variant="contained"
      // eslint-disable-next-line spellcheck/spell-checker
            label="I’ll do it later"
            secondary
            onClick={this.handleDismiss}
            disabled={this.props.disableLater}
          />
          <CommonButton
            key="doneButton"
            variant="contained"
            label={this.props.okText || "Send Now"}
            primary={true}
            onClick={this.handleDone}
          />
        </DialogActions>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    allCompanyParties: state.companies.companies.companyParties,
    companyId: state.main.user.user.companyId
  };
};

export default connect(mapStateToProps)(CustomEmailDialog);

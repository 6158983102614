import withStyles from '@mui/styles/withStyles';
import Tooltip from '@mui/material/Tooltip';

export const CommonToolTip = withStyles(() => ({
  tooltip : {
    backgroundColor: '#fff',
    opacity: '1.0',
    boxShadow: '2px 2px 10px #888',
    fontSize: 11,
    color: '#000',
    width: 350,
    height: 'fit-content',
  },
  arrow: {
    color: '#fff',
    "&::before": {
      border: '1px solid #fff'
    }
  },
  popper: {
    opacity: 1
  }
}))(Tooltip);

import React from 'react';
import { connect } from 'react-redux';

import { Link, withRouter } from 'react-router-dom';
import { AppBar, Toolbar, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import UserSettings from '../user-settings/UserSettings';
import { has, get, last, isEmpty } from 'lodash';
import './Header.scss';
import FarmPageIcon from '../common/icons/page-headers/Farm';
import InvoicePageIcon from '../common/icons/page-headers/Invoice';
import ContractPageIcon from '../common/icons/page-headers/Contract';
import FreightMovementPageIcon from '../common/icons/page-headers/FreightMovement';
import FreightOrderPageIcon from '../common/icons/page-headers/FreightOrder';
import CompanyPageIcon from '../common/icons/page-headers/Company';
import StocksPageIcon from '../common/icons/page-headers/Stocks';
import ProfilePageIcon from '../common/icons/page-headers/Profile';
import SiteBookingPageIcon from '../common/icons/page-headers/SiteBooking';
import SiteManagementPageIcon from '../common/icons/page-headers/SiteManagement';
import VendorDecsPageIcon from '../common/icons/page-headers/VendorDecs';
import DocketIcon from '@mui/icons-material/LinkedCamera';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { BLACK, ACCENT_COLOR_BLUE } from '../../common/constants';
import { getHome, isLoggedOutCashBoardView } from '../../common/utils';

class Header extends React.Component {
  constructor(props) {
    super(props);
  }

  onRouteClick(item) {
    if(item.onClick) {
      item.onClick();
    }
  }

  getPageIcon() {
    const { breadcrumbs } = this.props;
    if(!isEmpty(breadcrumbs)) {
      let text = last(get(breadcrumbs, '[0].text', this.props.headerText).split(' ')).toLowerCase();
      if(text === 'dashboard' && this.props.headerText && this.props.headerText.toLowerCase().includes('warehouse'))
        return '';
      return this.getIconComponentFromText(text);
    } else if(window.location.hash.match('/profiles')) {
      return this.getIconComponentFromText('profiles');
    } else {
      return '';
    }
  }

  getIconComponentFromText(text) {
    switch(text) {
    case 'farms':
      return <FarmPageIcon fill={BLACK} />;
    case 'contracts':
      return <ContractPageIcon fill={BLACK} />;
    case 'orders':
      return <FreightOrderPageIcon fill={BLACK} />;
    case 'movements':
      return <FreightMovementPageIcon fill={BLACK} />;
    case 'invoices':
      return <InvoicePageIcon fill={BLACK} />;
    case 'companies':
      return <CompanyPageIcon fill={BLACK} />;
    case 'stocks':
      return <StocksPageIcon fill={BLACK} />;
    case 'profiles':
      return <ProfilePageIcon fill={BLACK} />;
    case 'bookings':
      return <SiteBookingPageIcon fill={BLACK} />;
    case 'management':
      return <SiteManagementPageIcon fill={BLACK} />;
    case 'dashboard':
      return <DashboardIcon fontSize='large' style={{color: BLACK}} />;
    case 'dockets':
      return <DocketIcon fontSize='large' fill={BLACK} />;
    case 'vendor':
    case 'declarations':
      return <VendorDecsPageIcon fill={BLACK} />;
    default:
      return '';
    }
  }

  render() {
    const isLoggedOutCashBoard = isLoggedOutCashBoardView()
    const pageIcon = this.getPageIcon();
    const USER_INFO = this.props.isLoggedIn ?
          (<UserSettings />) :
          (<Toolbar className="user-info col-sm-3 col-md-2">
                          <Typography variant="title" color="inherit" className="text-gray font-weight-100">
                            Guest
                          </Typography>
                        </Toolbar>);

    return (
      <AppBar className="nav-bar header app-header">
        {
          isLoggedOutCashBoard ?
            <span style={{display: 'flex', alignItems: 'center', width: '100%', flexDirection: 'row', color: BLACK}}>
              <img style={{height: '70px', marginLeft: '10px'}} src='images/agrichain-logo-black-hd.png' alt='AgriChain Logo' />
              {
                this.props.headerText &&
                  <span style={{marginLeft: '10px', fontSize: '2.1em', fontWeight: '500', color: ACCENT_COLOR_BLUE}}>
                    {this.props.headerText}
                  </span>
              }
            </span>:
          <Toolbar className="logo content-left blue-background">
            <Link to={getHome()}>
              <img className="agrichain-logo" src='images/agrichain-logo.png' alt='AgriChain Logo' />
              <img className="agrichain-logo-icon" src='images/agrichain-logo-icon.png' alt='AgriChain Logo' />
            </Link>
          </Toolbar>
        }
        {
          !isLoggedOutCashBoard &&
            <div className="content-right header-content">
              {
                this.props.isLoggedIn ?
                  <Toolbar className="breadcrumb">
                    {
                      pageIcon &&
                        <div className="col-md-1 page-header-icon">
                          {pageIcon}
                        </div>

                    }
                    <div className="col-md-11">
                      <h4 className="breadcrumb-title">{this.props.headerText}
                        {
                          has(this.props.subHeaderText, 'route') ?
                            <Link to={this.props.subHeaderText.route}  style={{position: 'relative', top: '5px', left: '10px'}}>
                              <SettingsIcon style={{color: 'darkgray'}}/>
                            </Link> : (
                              has(this.props.subHeaderText, 'icon') ?
                                this.props.subHeaderText.icon
                                : <span> {this.props.subHeaderText}</span>
                            )
                        }
                        {has(this.props, 'settingButton') && get(this.props,'settingButton.button')}
                        {has(this.props, 'secondarySettingButton') && get(this.props,'secondarySettingButton.button')}
                      </h4>
                      <nav className="breadcrumb-nav">
                        {
                          this.props.breadcrumbs.map((item, index) => {
                            if(item.route) {
                              return (
                                <span key={index}>
                                  <Link to={item.route} onClick={() => this.onRouteClick(item)}>{item.text || this.props.headerText}</Link>
                                  <i className="angle-right"></i>
                                </span>
                              );
                            }
                            else if(item.onClick) {
                              return (
                                <span key={index}>
                                  <a style={{cursor: 'pointer'}} onClick={() => this.onRouteClick(item)}>{item.text || this.props.headerText}</a>
                                  <i className="angle-right"></i>
                                </span>
                              );
                            }
                            else {
                              return (
                                <span key={index} className="no-link">{item.text}</span>
                              );
                            }
                          })
                        }
                      </nav>
                    </div>
                  </Toolbar>
                : null
              }
              {USER_INFO}
            </div>
        }
      </AppBar>
    );
  }
}

const mapStateToProps = state => {
  return {
    headerText: state.main.headerText,
    subHeaderText: state.main.subHeaderText,
    settingButton: state.main.settingButton,
    secondarySettingButton: state.main.secondarySettingButton,
    isLoggedIn: !!state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
  };
};

export default withRouter(connect(mapStateToProps)(Header));

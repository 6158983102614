import React from 'react';
import moment from 'moment';
import { Button, ButtonGroup, Popper, Fade, Box, TextField } from '@mui/material'
import RefreshIcon from '@mui/icons-material/Refresh';
import { merge } from 'lodash'


const TimeButtonGroup = ({selected, onClick, onReload, dateFrom, dateTo, isCustomApplied, onClear, onApply, anchorEl, onTimeFieldChange, btnGroupStyle}) => {
  const getVariant = key => selected === key ? 'contained' : 'outlined'
  const buttonText = (selected === 'custom' && dateFrom && dateTo && isCustomApplied) ? `${moment(dateFrom).format('DD-MM-YYYY (HH:mm)')} - ${moment(dateTo).format('DD-MM-YYYY (HH:mm)')}` : ''
  return (
    <div className='col-xs-12 padding-reset' style={{display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-end'}}>
      <ButtonGroup size="medium" aria-label="small button group" color="secondary" style={merge({marginRight: '10px'}, (btnGroupStyle || {}))}>
        <Button variant='outlined' onClick={onReload}>
          <RefreshIcon />
        </Button>
      </ButtonGroup>
      <ButtonGroup size="medium" aria-label="small button group" color="secondary" style={btnGroupStyle || {}}>
        {
          (selected === 'custom' && dateFrom && dateTo && isCustomApplied) ?
            <Button key='custom' variant={getVariant('custom')} onClick={event => onClick(event, 'custom')}>
              {buttonText}
            </Button> :
          ["1h", '3h', '6h', '12h', '1d', '3d', '5d', 'custom'].map(duration => (
            <Button key={duration} variant={getVariant(duration)} onClick={event => onClick(event, duration)}>
              {duration === 'custom' ? buttonText || duration : duration}
            </Button>
          ))
        }
      </ButtonGroup>

      <Popper
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          transition
          placement='bottom-end'
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <Box sx={{ border: '1px solid lightgray', padding: '15px', bgcolor: 'background.paper', marginTop: '5px', display: 'flex', borderRadius: '4px', flexDirection: 'column' }}>
                <div className='col-xs-12 padding-reset' style={{marginTop: '15px'}}>
                  <div className='col-xs-6 no-left-padding' style={{paddingRight: '7px'}}>
                    <TextField
                      id="dateFrom"
                      variant="outlined"
                      label="From"
                      fullWidth
                      type='datetime-local'
                      value={moment(dateFrom).format('YYYY-MM-DDTHH:mm')}
                      onChange={onTimeFieldChange}
                    />
                  </div>
                  <div className='col-xs-6 no-right-padding' style={{paddingLeft: '7px'}}>
                    <TextField
                      id="dateTo"
                      variant="outlined"
                      label="To"
                      fullWidth
                      type='datetime-local'
                      value={moment(dateTo).format('YYYY-MM-DDTHH:mm')}
                      onChange={onTimeFieldChange}
                    />
                  </div>
                </div>
                <div className='col-xs-12 padding-reset' style={{marginTop: '15px'}}>
                  <div className='col-xs-4 padding-reset'>
                    <Button variant='outlined' color='secondary' onClick={onClear}>
                      Clear
                    </Button>
                  </div>
                  <div className='col-xs-8 padding-reset' style={{textAlign: 'right'}}>
                    <Button variant='text' color='secondary' style={{marginRight: '10px'}} onClick={onClear}>
                      Cancel
                    </Button>
                    <Button variant='contained' onClick={onApply}>
                      Apply
                    </Button>
                  </div>
                </div>
              </Box>
            </Fade>
          )}
        </Popper>

    </div>
  )
}

export default TimeButtonGroup;

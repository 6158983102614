import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import { InputAdornment } from '@mui/material/';
import { required } from '../../common/validators';
import '../../common/forms.scss';
import CommonButton from '../common/CommonButton';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import CommodityMultiSelect from '../common/autocomplete/CommodityMultiSelect';
import CommonTextField from '../common/CommonTextField';
import APIService from '../../services/APIService';
import { mapValues, map, isEmpty, get, includes, compact, filter } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import { getCommodities } from '../../actions/api/commodities';
import { cartesian } from '../../common/utils';

class ShrinkageForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companies: [],
      sites: [],
      open: false,
      fields: {
        siteIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        commodityIds: {
          value: [],
          validators: [required()],
          errors: [],
        },
        percentage: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        forCompanyIds: {
          value: [],
          validators: [],
          errors: [],
        },
      }
    };
  }

  componentDidMount() {
    const { open, dispatch, selected, type } = this.props;
    this.getSites();
    if(type === 'special')
      this.getCompanies();

    dispatch(getCommodities());
    if(this.state.open != open)
      this.setState({open: open});
    if(selected)
      this.setAllFieldValuesFromSelectedShrinkage();
  }

  componentDidUpdate() {
    const { open } = this.props;
    if(this.state.open != open)
      this.setState({open: open});
  }

  setAllFieldValuesFromSelectedShrinkage() {
    const { selected } = this.props;
    if(selected) {
      const newState = {...this.state};
      newState.fields.percentage.value = selected.percentage;
      newState.fields.siteIds.value = [selected.siteId];
      newState.fields.commodityIds.value = [selected.commodityId];
      newState.fields.forCompanyIds.value = compact([selected.forCompanyId]);
      this.setState(newState);
    }
  }

  getCompanies() {
    APIService.companies().appendToUrl('directory/names/?excludeGroups=true&include_self=true').get().then(companies => {
      this.setState({companies: companies});
    });
  }

  getSites() {
    const { companyId } = this.props;
    APIService.companies(companyId).company_sites().appendToUrl('minimal/').get().then(sites => this.setState({sites: map(sites, site => ({id: site.id, name: site.name, isActive: site.isActive}))}));
  }

  getPayload() {
    let data = mapValues(this.state.fields, 'value');
    const {commodityIds, forCompanyIds, siteIds, percentage} = data;

    let args = [[percentage], commodityIds, siteIds];
    if(!isEmpty(forCompanyIds))
      args.push(forCompanyIds);

    let results = cartesian(...args);
    let payload = map(results, result => {
      return {
        percentage: result[0],
        commodityId: result[1],
        siteId: result[2],
        forCompanyId: result[3]
      };
    });

    return payload;
  }

  isValid() {
    const { fields } = this.state;
    const standardCondition = !isEmpty(fields.commodityIds.value) &&
                              !isEmpty(fields.siteIds.value) &&
                              fields.percentage.value;
    if(this.props.type === 'special')
      return standardCondition && !isEmpty(fields.forCompanyIds.value);

    return standardCondition;
  }

  handleSubmit = event => {
    event.preventDefault();
    if(!this.isValid()) {
      alertifyjs.error('All Fields are mandatory.');
      return;
    }

    let payload = null;

    let action = 'post';
    let path = 'shrinkages/';
    if(this.props.selected) {
      action = 'put';
      payload = {percentage: this.state.fields.percentage.value};
      path += `${this.props.selected.id}/`;
    }
    else
      payload = this.getPayload();

    APIService.farms().appendToUrl(path)[action](payload).then(data => {
      if(get(data, 'details[0]')) {
        if(includes(data.details[0], 'duplicate key') || includes(data.details, 'Overlapping Tenure'))
          alertifyjs.error('You are trying to create duplicate Shrinkage for one or more Site/Commodity combination. Please fix that and re-try!');
        else
          alertifyjs.error('An Error Occurred!');
      } else {
        alertifyjs.success('Successfully created!');
        this.onClose(null, true);
      };
    });
  };

  onMultiSelectChange = (id, selectedItems) => {
    const newState = {...this.state};
    newState.fields[id].value = map(selectedItems, 'id');
    this.setState(newState);
  };

  onClose = (event, refresh) => {
    this.setState({open: false}, () => this.props.onClose(refresh));
  };

  onPercentageChange(value) {
    const newState = {...this.state};
    newState.fields.percentage.value = value;
    this.setState(newState);
  }

  render() {
    const { commodities, type, selected } = this.props;
    const { sites, fields, open, companies } = this.state;
    const isEdit = Boolean(selected);
    return (
      <SideDrawer isOpen={open} title={isEdit ? 'Edit Shrinkage' : 'Shrinkage'} onClose={this.onClose}>
        <form noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70" style={{margin: '10px 0'}}>
                {
                  isEdit ?
                  <div style={{marginTop: '25px'}}>
                    <CommonTextField
                      id="siteIds"
                      label="Site"
                      value={get(selected, 'siteName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommonMultiSelect
                      id="siteIds"
                      items={filter(sites, site => site?.isActive)}
                      selectedItems={fields.siteIds.value}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      placeholder="Select Site(s)..."
                      label='Site'
                      selectAll
                      clearAll
                    />
                }
              </div>
              {
                type === 'special' &&
                <div className="col-sm-12 form-wrap-70" style={{marginBottom: '10px'}}>
                  {
                    isEdit ?
                    <div style={{marginTop: '25px'}}>
                    <CommonTextField
                      id="customer"
                      label="Customer"
                      value={get(selected, 'customerName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommonMultiSelect
                      id="forCompanyIds"
                      items={companies}
                      selectedItems={fields.forCompanyIds.value}
                      displayField='name'
                      onChange={this.onMultiSelectChange}
                      placeholder="Select Customer(s)..."
                      label='Customer'
                      selectAll
                      clearAll
                    />
                  }
                </div>
              }
              <div className="col-sm-12 form-wrap-70" style={{margin: '10px 0'}}>
                {
                  isEdit ?
                  <div style={{marginTop: '15px'}}>
                    <CommonTextField
                      id="commodityId"
                      label="Commodity"
                      value={get(selected, 'commodityName')}
                      variant='outlined'
                      disabled
                    />
                  </div>
                  : <CommodityMultiSelect
                      id="commodityIds"
                      commodities={commodities}
                      selectedCommodities={fields.commodityIds.value}
                      onChange={this.onMultiSelectChange}
                      placeholder='Select Commodities...'
                      label='Commodity'
                    />
                }
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '25px'}}>
                <CommonTextField
                  id='percentage'
                  label="Percentage"
                  value={this.state.fields.percentage.value}
                  helperText={this.state.fields.percentage.errors[0]}
                  onChange={event => this.onPercentageChange(event.target.value)}
                  variant='outlined'
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        %
                      </InputAdornment>
                    ),
                  }}

                />
              </div>
              <div className="col-sm-12 form-wrap-70" style={{marginTop: '5px', textAlign: 'right', paddingRight: '0px'}}>
                <CommonButton
                  variant="contained"
                  onClick={this.onClose}
                  label='Cancel'
                />
                <CommonButton
                  variant="contained"
                  type='submit'
                  primary
                  onClick={this.handleSubmit}
                  label='Submit'
                />
              </div>
            </div>
          </div>
        </form>
      </SideDrawer>
    );
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(ShrinkageForm);

import React from 'react';

import get from 'lodash/get';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { EMPTY_VALUE } from '../../../common/constants';
import Tooltip from '../../../common/Tooltip';
import { isVisibleInCountry, toPhoneFormat } from '../../../common/utils';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class InvoicePartiesSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  getCounterPartiesSectionAsPerGrid = (payload, sectionName) => {
    return (
      <div>
        <h4 className="section-title">{sectionName}</h4>
        <ul>
          {Object.keys(payload).map(function(key, index) {
            return (
              <li key={index}>
               <Tooltip
                  className="field-label ellipses"
                  tooltipText={key}
                  textContent={key}
                />
                {<Tooltip
                  className="field-value ellipses"
                  tooltipText={payload[key] || EMPTY_VALUE}
                  textContent={payload[key] || EMPTY_VALUE}
                />}
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  render() {
    const { expanded } = this.state;
    const hideABN = !isVisibleInCountry('abn')
    const hideNGR = !isVisibleInCountry('ngr')

    const payeeSectionName = get(this.props.invoiceDetails, 'payeeSectionName');
    const payerSectionName = get(this.props.invoiceDetails, 'payerSectionName');

    let payerPayload = {};
    if (get(this.props.invoiceDetails, 'type') === "WarehouseFee")
      payerPayload['Company'] = get(this.props.invoiceDetails, 'payerDisplayName');
    else
      payerPayload['Name'] = get(this.props.invoiceDetails, 'payerDisplayName');
    payerPayload = {
      ...payerPayload,
      'Entity': get(this.props.invoiceDetails.payer, 'entityName'),
      'Contact': get(this.props.invoiceDetails.payer,'contactName'),
      'Phone' : toPhoneFormat(get(this.props.invoiceDetails.payer, 'mobile')),
      'Address': get(this.props.invoiceDetails.payer, 'address'),
      'ABN': get(this.props.invoiceDetails.payer, 'abn'),
      'Email': get(this.props.invoiceDetails, 'payerEmail'),
      'NGR': get(this.props.invoiceDetails.payer, 'ngrNumber'),
    };

    let payeePayload = {};
    if (get(this.props.invoiceDetails, 'type') === "WarehouseFee")
      payeePayload['Company'] = get(this.props.invoiceDetails, 'payeeDisplayName');
    else
      payeePayload['Name'] = get(this.props.invoiceDetails, 'payeeDisplayName');
    payeePayload = {
      ...payeePayload,
      'Entity': get(this.props.invoiceDetails.payee, 'entityName'),
      'Contact': get(this.props.invoiceDetails.payee,'contactName'),
      'Phone' : toPhoneFormat(get(this.props.invoiceDetails.payee, 'mobile')),
      'Address': get(this.props.invoiceDetails.payee, 'address'),
      'ABN': get(this.props.invoiceDetails.payee, 'abn'),
      'Email': get(this.props.invoiceDetails, 'payeeEmail'),
      'NGR': get(this.props.invoiceDetails.payee, 'ngrNumber'),
    };

    const sentByPayload = {
      'Name' : get(this.props.invoiceDetails.sentBy, 'companyName'),
      'Entity': get(this.props.invoiceDetails.sentBy, 'entityName'),
      'Contact' : get(this.props.invoiceDetails.sentBy, 'contactName'),
      'Address' : get(this.props.invoiceDetails.sentBy, 'address'),
      'Phone' : toPhoneFormat(get(this.props.invoiceDetails.sentBy, 'phone')),
      'ABN' : get(this.props.invoiceDetails.sentBy, 'abn'),
    };
    if(hideABN) {
      delete payeePayload.ABN
      delete payerPayload.ABN
      delete sentByPayload.ABN
    }
    if(hideNGR) {
      delete payeePayload.NGR
      delete payerPayload.NGR
    }


    return (
      <Paper className="invoice-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Parties
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div className="section-details-container">
            {this.getCounterPartiesSectionAsPerGrid(payerPayload, payerSectionName)}
            {this.getCounterPartiesSectionAsPerGrid(payeePayload, payeeSectionName)}
            {this.getCounterPartiesSectionAsPerGrid(sentByPayload, 'Sent By')}
          </div>
        )}
      </Paper>
    );
  }
}

export default InvoicePartiesSection;

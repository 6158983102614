import React from 'react';

import { connect } from 'react-redux';
import SpecialConditionsTable from '../../containers/SpecialConditionsTable';
import CreateSpecialCondition from '../../containers/CreateSpecialCondition';
import UpdateSpecialCondition from '../../containers/UpdateSpecialCondition';
import {
  getSpecialConditions,
  clickAddSpecialConditionButton,
  cancelEditGeneralCondition
} from '../../actions/companies/conditions';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';

class SpecialConditions extends React.Component {
  constructor(props) {
    super(props);

      this.state = {
          openSideDrawer: false
      };

      this.openSideDraw = this.openSideDraw.bind(this);
      this.closeSideDraw = this.closeSideDraw.bind(this);
  }

  componentDidMount() {
    this.props.getConditions();
  }

  openSideDraw(){
      this.props.handleAddSpecialConditionButtonClick();
      this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
      this.setState({ openSideDrawer: false, });
  }

  render() {
    return (
      <div>
        <AddButton label="Special Condition" onClick={this.openSideDraw} app="condition" />
        <SpecialConditionsTable/>
        { this.props.isCreateFormDisplayed &&
          <SideDrawer
              isOpen={ this.state.openSideDrawer }
              title="Add Special Condition"
              onClose={this.closeSideDraw}
              app="condition"
          >
            <CreateSpecialCondition closeDrawer={ this.closeSideDraw } />
          </SideDrawer>
        }
        <SideDrawer
            isOpen={ this.props.isUpdateFormDisplayed }
            title="Edit Special Condition"
            onClose={this.props.cancelEditGeneralCondition }
            app="condition"
        >
          <UpdateSpecialCondition closeDrawer={ this.closeSideDraw } />
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isCreateFormDisplayed: state.companies.conditions.isCreateFormDisplayed,
    isUpdateFormDisplayed: state.companies.conditions.isUpdateFormDisplayed,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getConditions: () => dispatch(getSpecialConditions()),
    handleAddSpecialConditionButtonClick: () => dispatch(clickAddSpecialConditionButton()),
    cancelEditGeneralCondition: () => dispatch(cancelEditGeneralCondition())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpecialConditions);

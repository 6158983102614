import { Paper } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import AddButton from '../common/AddButton';
import AlertForm from './AlertForm';
import APIService from '../../services/APIService';
import GenericTable from '../GenericTable';
import {get, isEqual} from 'lodash';
import { setBreadcrumbs, setHeaderText } from '../../actions/main';
import { getCompanyDetails } from '../../actions/companies';

class Alerts extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      selected: undefined,
      data: [],
    }
  }

  componentDidMount() {
    this.setBreadcrumbs();
    this.getAlerts();
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
    }
  }

  componentDidUpdate() {
    if (this.props.selectedCompany) {
      if (this.props.headerText !== this.props.selectedCompany.name) {
        this.props.setHeaderText(this.props.selectedCompany.name);
      }
      this.setBreadcrumbs();
    }
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
    }
  }

  shouldFetchCompany() {
    return !this.props.selectedCompany || (this.props.selectedCompany.id !== this.props.companyId);
  }

  setBreadcrumbs() {
    if (this.props.selectedCompany) {
      const breadcrumbs = [
        { text: 'Companies', route: '/companies' },
        { text: this.props.selectedCompany.name, onClick: this.props.onDetailsClick, route: '/companies/' + this.props.selectedCompany.id + '/details' },
        { text: 'Alerts' }
      ];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  getAlerts() {
    APIService.alerts()
      .get(null, null, {company_id: this.props.companyId})
      .then(response => {
        this.setState({data: response});
      });
  }

  onAdd = () => this.setState({openForm: true});

  onCloseForm = () => this.setState({openForm: false, selected: null});

  onDefaultClick = item => {
    APIService.alerts(item.id)
      .get()
      .then(res => this.setState({selected: res, openForm: true}));
  }

  updateAlert(item) {
    if (item) {
      let isActive = get(item, 'isActive')
      const newState = {...this.state};
      let objIndex = newState.data.findIndex(obj => obj.id === item.id);
      newState.data[objIndex].isActive = !newState.data[objIndex].isActive;
      this.setState(newState);
      APIService.alerts(item.id)
        .put({'isActive': !isActive})
        .then(() => window.location.reload());
    }
  }

  render() {
    const COLUMNS = [
      {key: 'alertDisplayName', header: 'Alert', className: 'medium'},
      {key: 'description', header: 'Description', className: 'large'},
      {key: 'alertType', header: 'Contact Type', className: 'small'},
      {key: 'recipients', header: 'Recipients', className: 'large'},
      {key: 'frequencyDisplayName', header: 'Trigger', className: 'small'},
      {key: 'isActive', header: 'Is Active', className: 'small', showToggle: true, func: item => this.updateAlert(item)},
    ];
    return (
      <Paper className='paper-table-paginated'>
        <div>
          <AddButton label='Alert' app='alerts' onClick={this.onAdd} />
        </div>
        <GenericTable
          columns={COLUMNS}
          items={this.state.data}
          handleDefaultCellClick={this.onDefaultClick}
          showActiveToggle={true}
          updateAlert={(item) => this.updateAlert(item)}
        />
        {this.state.openForm &&
          <AlertForm alert={this.state.selected} companyId={this.props.companyId} open={true} onClose={this.onCloseForm}/>
        }
      </Paper>
    )
  }

}

const mapStateToProps = (state) => {
  return {
    selectedCompany: state.companies.companies.selectedCompany,
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);

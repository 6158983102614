import { combineReducers } from 'redux';
import main from './main';
import master from './master';
import companySettings from './company-settings';
import companies from './companies';
import profiles from './profiles';

export default combineReducers({
  main,
  master,
  companySettings,
  companies,
  profiles,
});

import { apiURL, forceStopLoader } from '../main';
import axios from 'axios';
import alertifyjs from 'alertifyjs';
export const GET_COMPANY_APPROVED_BUYERS = 'GET_COMPANY_APPROVED_BUYERS';
import { getCompanyIdFromCurrentRoute, getPageCache } from '../../common/utils';
import packageJson from '../../../package.json';
import get from 'lodash/get';

const VERSION = packageJson?.version

export const getCompanyApprovedBuyersResponse = items => {
    return { type: GET_COMPANY_APPROVED_BUYERS, items };
};

export const getApprovedBuyers = (_forceStopLoader = true, url = '') => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}`;
        if (url)
          URL = url;
        if  (!URL.includes('web/approved-buyers/')) {
            const ownerCompanyId = getCompanyIdFromCurrentRoute() || getState().main.user.user.companyId
            URL = URL + `/companies/${ownerCompanyId}/web/approved-buyers/`;
        }
        if (!URL.includes('page_size=')) {
            const cache = getPageCache();
            const cachedPageSize = get(cache, 'pageSize');
            const cachedPage = get(cache, 'page');
            let joiner = '?';
            if(URL.indexOf('?') > -1)
                joiner = '&';
            if(cachedPageSize) {
                URL += `${joiner}page_size=${cachedPageSize}`;
                joiner = '&';
            }
            if(cachedPage)
                URL += `${joiner}page=${cachedPage}`;
        }
        axios
            .get(URL, config)
            .then(response => {
                dispatch(getCompanyApprovedBuyersResponse(response.data));
                if (_forceStopLoader) {
                    dispatch(forceStopLoader());
                }
            })
            .catch(error => {
                dispatch(getCompanyApprovedBuyersResponse(error.response ? error.response.data : error.message));
            });
    };
};

export const deleteApprovedBuyers = companyId => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
      let URL = `${apiURL}`;
      const ownerCompanyId = getCompanyIdFromCurrentRoute() || getState().main.user.user.companyId
        URL = URL + `/companies/${ownerCompanyId}/approved-buyers/?company_id=${companyId}`;
        axios
            .delete(URL, config)
            .then(() => {
              dispatch(getApprovedBuyers());
                alertifyjs.success('Company removed from approved Buyers');
            })
            .catch(error => {
                if (error)
                    alertifyjs.error('An Error Occurred!');
            });
    };
};

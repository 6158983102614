import React, { Component } from 'react';
import { connect } from 'react-redux';
import AutoComplete from '../common/autocomplete/AutoComplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { required, minLength, maxLength, fixLength, maxValue, numRegex } from '../../common/validators';
import forEach from 'lodash/forEach';
import isEmpty from 'lodash/isEmpty';
import some from 'lodash/some';
import find from 'lodash/find';
import isNumber from 'lodash/isNumber';
import {apiURL, isLoading, loaded} from '../../actions/main';
import {  positiveDecimalFilter } from '../../common/input-filters';

import CommonTextField from '../common/CommonTextField';
import CommonButton from '../common/CommonButton';
import {PRIMARY_COLOR_GREEN} from '../../common/constants';
import { getCountryConfig, getCountryLabel } from '../../common/utils';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import get from 'lodash/get';
import set from 'lodash/set';

class BankAccountForm extends Component {
  constructor(props) {
    super(props);
    this.config = getCountryConfig();
    this.state = {
      nameSearchText: '',
      isConfirmDialogOpen: false,
      fields: {
        name: {
          value: '',
          validators: [],
          errors: []
        },
        bsbNumber: {
          value: '',
          validators: [fixLength(get(this.config,'bank.bsbLength')), numRegex()],
          errors: []
        },
        accountName: {
          value: '',
          validators: [],
          errors: []
        },
        accountNumber: {
          value: '',
          validators: [minLength(get(this.config, 'bank.accountNumberMinimumLength')), maxLength(get(this.config, 'bank.accountNumberMaximumLength')), numRegex()],
          errors: []
        },
        companyId: {
          value: '',
          validators: [],
          errors: []
        },
        shareholderPercent: {
          value: '',
          validators: [],
          errors: []
        },
      }
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.onAbnBlur = this.onAbnBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedNgr = this.setAllFieldsValuesBySelectedNgr.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.onNewRequestName = this.onNewRequestName.bind(this);
    this.onUpdateInputName = this.onUpdateInputName.bind(this);
    this.handleConfirmDialogOpen = this.handleConfirmDialogOpen.bind(this);
    this.handleConfirmDialogCancel = this.handleConfirmDialogCancel.bind(this);
    this.handleConfirmDialogOk = this.handleConfirmDialogOk.bind(this);
    this.notifyValueChanged = this.notifyValueChanged.bind(this);
    this.fetchAbn = this.fetchAbn.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    newState.fields.name.value = props.name;
    newState.fields.bsbNumber.value = props.bsbNumber;
    newState.fields.accountName.value = props.accountName;
    newState.fields.accountNumber.value = props.accountNumber;
    newState.nameSearchText = props.name;

    setTimeout(() => {
      const selectedBank = find(this.props.banks,{ name: this.props.name });
      const newState = { ...this.state };
      newState.fields.name.id = selectedBank ? selectedBank.id : undefined;
      this.setState(newState);
    }, 500);

    if (this.props.bankAccountDetailsMandatory) {
      forEach(['name', 'bsbNumber', 'accountNumber', 'accountName'], (key) => {
        newState.fields[key].validators.push(required());
      });
    }

    if(props.ngrType.toLowerCase() === 'shared') {
      newState.fields.companyId.validators = [required()];
      newState.fields.shareholderPercent.validators = [required(), maxValue(99)];

      newState.fields.companyId.value = props.companyId;
      newState.fields.shareholderPercent.value = props.shareholderPercent;
    }
    else{
      newState.fields.companyId.validators = [];
    }

    this.setState(newState, () => {
      if(props.startValidation) {
        this.setAllFieldsErrors();
      }
    });


  }


  onNewRequestName(selectedBank) {
    const newState = {...this.state};
    newState.nameSearchText = selectedBank.name;
    newState.fields.name.value = selectedBank.name;
    newState.fields.name.id = selectedBank.id;
    this.setState(newState);
    this.setFieldErrors('name');
  }

  handleCompanyChange = (value, id, chosenItem) => {

    let {fields} = this.state;
    if (chosenItem) {
      set(fields, `${id}.value`, value);
    }
    else{
      set(fields, `${id}.value`, undefined);
    }
    this.setState({fields: fields}, ()=> this.setFieldErrors('companyId'));
  };

  onUpdateInputName(searchText) {
    const newState = {...this.state};
    newState.nameSearchText = searchText;
    if (isEmpty(searchText)) {
      newState.fields.name.value = "";
    }
    this.setState(newState);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  onAbnBlur(event) {
    this.setFieldErrors('abn');
    if(this.state.fields.abn.value && this.state.fields.abn.value.length == 11) {
      this.fetchAbn(event.target.value);
    }
  }

  fetchAbn(abn) {
    const { dispatch } = this.props;
    dispatch(isLoading(null));
    fetch(`${apiURL}/abn?abn=${abn}&return_local=True`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
      },
    })
    .then(response => response.json())
    .then((json) => {
      const newState = {...this.state};
      if(json.entityName) {
        newState.fields.entityName.value = json.entityName;
      }
      else {
        newState.fields.entityName.value = '';
        newState.fields.abn.errors = ['The selected ABN does not exist'];
      }
      dispatch(loaded());
      this.setState(newState, this.notifyValueChanged);
    });
  }

  handleSelectChange(key, event, index, value) {
    this.setFieldValue(key, value);
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    let errors = this.getFieldErrors(key);
    const newState = {...this.state};
    newState.fields[key].errors = errors;
    this.setState(newState, this.notifyValueChanged);
  }

  notifyValueChanged() {
    const values = {};
    let errorCount = 0;
    forEach(this.state.fields, (field, key) => {
      values[key] = this.state.fields[key].value;
      errorCount += this.state.fields[key].errors.length;
    });
    values['isValid'] = errorCount == 0;
    this.props.valueChanged(this.props.index, values);
  }

  setAllFieldsValuesBySelectedNgr() {
    if (this.props.selectedNgr) {
      const newState = { ...this.state };

      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedNgr[key];
      });

      if(this.props.selectedNgr['name']) {
        newState.nameSearchText = this.props.selectedNgr['name'].name;
      }

      this.setState(newState);
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });

    if(this.props.ngrType.toLowerCase() === 'shared' && this.props.totalShareholderPercent != 100 && isEmpty(newState.fields['shareholderPercent'].errors)) {
      newState.fields['shareholderPercent'].errors.push('Please make sure total sum of Shareholder % is equal to 100');
    }
    this.setState(newState);
    this.props.validated(this.props.index);
  }

  handleConfirmDialogOpen() {
    const isFormData = some(this.state.fields, (field) => {
      return !isEmpty(field.value) || isNumber(field.value);
    });

    if(isFormData) {
      this.setState({
        ...this.state,
        isConfirmDialogOpen: true
      });
    }
    else {
      this.props.shareholderRemoved(this.props.index);
    }
  }

  handleConfirmDialogCancel() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false
    });
  }

  handleConfirmDialogOk() {
    this.props.shareholderRemoved(this.props.index);
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false,
    });
  }

  handleBankChange = () => value => {
    const chosenRequest = value ? this.props.banks.find(e => e.id === value) : {name: '', id: ''};
    this.onNewRequestName(chosenRequest);
  };

  render() {
    const bankItems = this.props.banks && this.props.banks.map(e => ({label: e.name, value: e.id}));
    return (
      <div>
        {
          this.props.ngrType.toLowerCase() === 'shared' ?
            <div>
              <div className="cardForm-content row trucks">
                <div className="col-sm-6 form-wrap">
                  <CommonTextField
                    id="shareholderPercent"
                    placeholder="Please enter shareholder percent"
                    label={this.props.index==0 ? 'Primary Shareholder %' : 'Shareholder ' + (this.props.index+1) + ' %'}
                    value={this.state.fields.shareholderPercent.value}
                    onChange={this.handleFieldChange}
                    disabled={this.props.disabled}
                    onBlur={this.onFieldBlur}
                    onKeyDown={(event)=>positiveDecimalFilter(event, 2)}
                    helperText={this.state.fields.shareholderPercent.errors[0]}
                    maxLength="6" />
                </div>
                <div className="col-sm-6 form-wrap">
                {
                  this.props.index > 1 ?
                  <a onClick={this.handleConfirmDialogOpen} style={{color: PRIMARY_COLOR_GREEN, cursor: 'pointer', float: 'right', marginTop: '20px'}} disabled={this.props.disabled}>
                    Remove Shareholder
                  </a>
                  : ''
                }
                </div>
              </div>
              <div className="cardForm-content row trucks">
                <div className="col-sm-6 form-wrap">
                      <CommonAutoSelect
                        items={this.props.allCompanies}
                        dataSourceConfig={{text: 'name', value: 'id'}}
                        id="companyId"
                        label="Select Company"
                        value={this.state.fields.companyId.value}
                        errorText={get(this.state.fields, 'companyId.errors[0]')}
                        onChange={this.handleCompanyChange}
                        dontAutoselectSingleItem
                />
                </div>
                <div className="col-sm-6 form-wrap">
                  <CommonTextField
                    id="entityName"
                    label="Entity Name"
                    placeholder="Please enter ABN to find entity name"
                    value={this.state.fields.entityName.value}
                    disabled={true}
                    maxLength="100" />
                </div>
              </div>
            </div>
          : ''
        }
        <div className="cardForm-content row trucks">
          <div className="col-sm-6 form-wrap">
            <AutoComplete
              id="name"
              label={this.props.bankAccountDetailsMandatory ? "Bank Name" : "Bank Name (Optional)"}
              placeholder={this.props.bankAccountDetailsMandatory ? "Bank Name" : "Bank Name (Optional)"}
              disabled={this.props.disabled}
              options={bankItems}
              fullWidth
              errorText={this.state.fields.name.errors[0]}
              onBlur={this.onFieldBlur}
              value={this.state.fields.name.id}
              onChange={this.handleBankChange()}
            />
          </div>
          <div className="col-sm-6 form-wrap">
            <CommonTextField
              id="bsbNumber"
              label={this.props.bankAccountDetailsMandatory ? `${getCountryLabel('bsb')} Number` : `${getCountryLabel('bsb')} Number (Optional)`}
              placeholder={`Please enter ${get(this.config,'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
              type="number"
              value={this.state.fields.bsbNumber.value}
              onChange={this.handleFieldChange}
              onBlur={this.onFieldBlur}
              disabled={this.props.disabled}
              helperText={this.state.fields.bsbNumber.errors[0]}
              onInput = {(e) =>{
                e.target.value = e.target.value.toString().slice(0,get(this.config,'bank.bsbLength'));
            }} />
          </div>
        </div>
        <div className="cardForm-content row trucks">
          <div className="col-sm-6 form-wrap">
            <CommonTextField
              id="accountName"
              label={this.props.bankAccountDetailsMandatory ? "Account Name" : "Account Name (Optional)"}
              placeholder="Please enter account name"
              value={this.state.fields.accountName.value}
              onChange={this.handleFieldChange}
              onBlur={this.onFieldBlur}
              disabled={this.props.disabled}
              helperText={this.state.fields.accountName.errors[0]}
              maxLength="100" />
          </div>
          <div className="col-sm-6 form-wrap">
            <CommonTextField
              id="accountNumber"
              label={this.props.bankAccountDetailsMandatory ? "Account Number" : "Account Number (Optional)"}
              placeholder={`Please enter ${get(this.config, 'bank.accountNumberMinimumLength')}-${get(this.config, 'bank.accountNumberMaximumLength')} digits Account number`}
              type="number"
              value={this.state.fields.accountNumber.value}
              onChange={this.handleFieldChange}
              onBlur={this.onFieldBlur}
              disabled={this.props.disabled}
              helperText={this.state.fields.accountNumber.errors[0]}
              onInput = {(e) =>{
                e.target.value = e.target.value.toString().slice(0,get(this.config, 'bank.accountNumberMaximumLength'));
            }}/>
          </div>
        </div>
        <hr></hr>

        <Dialog open={this.state.isConfirmDialogOpen} onClose={this.handleConfirmDialogCancel} scroll="paper">
          <DialogTitle>Remove Shareholder</DialogTitle>
          <DialogContent>
            Are you sure to remove the selected shareholder?
          </DialogContent>
          <DialogActions>
            <CommonButton
              key="no"
              label="No"
              onClick={this.handleConfirmDialogCancel}
              variant="outlined"
              color="default"
            />
            <CommonButton
              key="yes"
              label="Yes"
              primary={true}
              onClick={this.handleConfirmDialogOk}
              variant="flat"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(BankAccountForm);

import React from 'react';
import { connect } from 'react-redux';
import CommonTabs from '../common/CommonTabs';
import { setSubHeaderText, isLoading, setBreadcrumbs, forceStopLoader } from '../../actions/main';
import { includes, get, isEmpty, isEqual } from 'lodash';
import { setClickedOption } from "../../actions/companies/invoices";
import { InvoiceDetailsBasicInfo } from "./invoiceDetails/InvoiceDetailsBasicInfo";
import WarehouseInvoiceLoads from "./WarehouseInvoiceLoads";
import InvoicesActions from './InvoicesActions';
import WarehouseInvoices from './WarehouseInvoices';
import APIService from '../../services/APIService';

class WarehouseInvoiceHome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.location.pathname,
      invoiceDetails: {},
    };
    this.handleTabChange = this.handleTabChange.bind(this);
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
      { ...prevState, activeTab: nextProps.location.pathname } :
    prevState;
  }

  componentDidMount() {
    this.fetchInvoiceDetails()
    this.setHeaderAndBreadcrumbs()
    this.clearEntity();
  }

  setHeaderAndBreadcrumbs = () => {
    const breadcrumbs = [{ text: 'Warehouse Dashboard', onClick: this.props.history.goBack}];
    const payerName = get(this.state.invoiceDetails, 'payerDisplayName')
    if(payerName)
      breadcrumbs.push({text: payerName})

    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setSubHeaderText(''));
  }

  clearEntity() {
    this.props.dispatch(setClickedOption(null));
  }

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.getInvoiceParams(this.props), this.getInvoiceParams(prevProps)))
      this.fetchInvoiceDetails()
  }

  getInvoiceParams = props => {
    const queryParams = new URLSearchParams(this.props.location.search)
    return {
      tenure: props.match.params.tenure,
      payee_company_id: props.match.params.payee_company_id,
      payer_company_id: props.match.params.payer_company_id,
      payer_ngr_id: queryParams.get('ngrId'),
      commodity_id: queryParams.get('commodityId')
    }
  }

  isLoadsTab = () => !includes(this.state.activeTab, 'active')

  fetchInvoiceDetails() {
    this.props.dispatch(isLoading('warehouse-invoice-details'))
    const params = this.getInvoiceParams(this.props)
    const service = APIService.invoices().appendToUrl(`warehouse/${params.tenure}/payees/${params.payee_company_id}/payers/${params.payer_company_id}/`)
    const query = params.payer_ngr_id ? {payer_ngr_id: params.payer_ngr_id} : {}
    if (params.commodity_id) {
      query['commodity_id'] = params.commodity_id;
    }
    service.get(null, null, query).then(response => this.setState({invoiceDetails: response}, () => {
      if(!this.isLoadsTab())
        this.props.dispatch(forceStopLoader())
      this.setHeaderAndBreadcrumbs()
    }))
  }

  getHeaderText = () => {
    const { invoiceDetails } = this.state
    if(!invoiceDetails)
      return 'Loading...'
    return `${invoiceDetails.payerDisplayName} - ${invoiceDetails.tenure}`
  }


  render() {
    const PARENT_URL = this.props.match.url;
    const { activeTab, invoiceDetails } = this.state;

    let tabs = [
      {
        label: 'Loads',
        key: 'invoiceLoads',
        url: `${PARENT_URL}${this.props.location.search}`,
        urls: [`${PARENT_URL}${this.props.location.search}`, PARENT_URL],
        component: () => <WarehouseInvoiceLoads invoiceId={get(invoiceDetails, 'id')} />
      },
      {
        label: 'Active Invoices',
        key: 'activeInvoices',
        url: `${PARENT_URL}/active${this.props.location.search}`,
        urls: [`${PARENT_URL}/active${this.props.location.search}`, `${PARENT_URL}/active`],
        component: () => <WarehouseInvoices invoiceId={get(invoiceDetails, 'id')} />
      }
    ];
    const headerText = this.getHeaderText()

    return (
      <div className="invoice-details-container">
        <div className="tab">
          <div className="tab-header">
            <CommonTabs value={`${activeTab}${this.props.location.search}`} tabs={tabs} />
          </div>
          {
            !isEmpty(invoiceDetails) &&
              <React.Fragment>
                <InvoiceDetailsBasicInfo warehouse {...this.props} invoiceDetails={invoiceDetails} />
                <div className="tab-content">
                  {
                    this.isLoadsTab() ?
                      <WarehouseInvoiceLoads
                        idontRedirect
                        headerText={headerText}
                        {...this.props}
                        invoice={invoiceDetails}
                        invoiceId={get(invoiceDetails, 'id')}
                      /> :
                    <WarehouseInvoices
                      headerText={headerText}
                      invoiceId={invoiceDetails.id}
                    />
                  }
                </div>
                <InvoicesActions  {...this.props} selectedInvoice={invoiceDetails} selectedInvoiceId={get(invoiceDetails, 'id')}/>
              </React.Fragment>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const clickedOption = state.companies.invoices.clickedOption;
  return {
    currentUser: state.main.user.user,
    status: state.companies.invoices.statusDisplayName,
    showVoidDialog: state.companies.invoices.flag,
    invoiceId: state.companies.invoices.invoiceId,
    isPdfDownloadable: state.companies.invoices.isPdfDownloadable,
    flag: state.companies.invoices.flag,
    userToken: state.main.user.token,
    canAddPayment: state.companies.invoices.canAddPayment,
    canMarkComplete: state.companies.invoices.canMarkComplete,
    breadcrumbs: state.main.breadcrumbs,
    clickedOption
  };
};

export default connect(mapStateToProps)(WarehouseInvoiceHome);

import { connect } from 'react-redux';
import SpecialConditionForm from '../components/conditions/SpecialConditionForm';
import { createSpecialConditions } from '../actions/companies/conditions';

function submit(data, onSuccess) {
  return (dispatch) => {
    dispatch(createSpecialConditions(data, onSuccess));
  };
}

export default connect(null, { submit })(SpecialConditionForm);

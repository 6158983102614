import React from 'react';
import CommonAutoSelect from './autocomplete/CommonAutoSelect';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Autocomplete, TextField, Checkbox } from '@mui/material';
import { filter, includes } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

class CommonWeekSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: []
    }
  }

  componentDidMount() {
    this.setUpWeeks()
  }

  setUpWeeks() {
    const { weekStart, numberOfWeeks } = this.props;
    const currentDate = new Date();
    const firstDayOfWeek = weekStart === 7 ? 0 : weekStart;

    const weekRanges = [];
    const formatTwoDigits = (num) => num.toString().padStart(2, '0');

    let startDate = new Date(currentDate);
    // Find the start of the current week according to weekStart
    while (startDate.getDay() !== firstDayOfWeek % 7) {
      startDate.setDate(startDate.getDate() - 1);
    }

    for (let i = 0; i < numberOfWeeks; i++) {
      const endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 6);
      const startStr = `${formatTwoDigits(startDate.getDate())}/${formatTwoDigits(startDate.getMonth() + 1)}/${startDate.getFullYear()}`;
      const endStr = `${formatTwoDigits(endDate.getDate())}/${formatTwoDigits(endDate.getMonth() + 1)}/${endDate.getFullYear()}`;
      const range = `${startStr} - ${endStr}`;
      const name = `${startDate.getDate()} ${startDate.toLocaleString('default', { month: 'short' })}, ${startDate.getFullYear()} - ${endDate.getDate()} ${endDate.toLocaleString('default', { month: 'short' })}, ${endDate.getFullYear()}`;
      weekRanges.push({ id: range, name: name });

      startDate.setDate(startDate.getDate() - 7);
    }

    this.setState({ items: weekRanges.reverse() });
  }

  render() {
    const {items} = this.state;
    const {value, label, id, multiple, selected} = this.props;
    return (
      <>
        {
          multiple ?
          <Autocomplete
            multiple
            limitTags={3}
            size='small'
            disableCloseOnSelect
            getOptionLabel={option => option.name}
            value={filter(items, option => includes(selected || [], option.id))}
            renderOption={(params, option, { selected }) => (
              <li {...params}>
                <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: "2px", marginBottom: "5px", paddingLeft: "1px" }}
                  checked={selected}
                />
                {option.name}
              </li>
            )}
            renderInput={params => (<TextField style={{height: '40px'}} fullWidth {...params} label={label} />)}
            options={items}
            {...this.props}
          /> :
          <CommonAutoSelect
            id={id}
            label={label}
            items={items}
            value={value}
            disabled={this.props.disabled}
            onBlur={this.props.onBlur}
            onChange={this.props.onChange}
            errorText={this.props.errorText}
          />
        }
      </>
    )
  }
}

export default CommonWeekSelector;

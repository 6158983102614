import React from 'react';
import { connect } from 'react-redux';

import UpdateFarm from '../../containers/UpdateFarm';
import FarmFields from '../../components/farm-fields/FarmFields';
import Storages from '../../components/storages/Storages';
import CommonTabs from '../common/CommonTabs';
import '../common/commonTabs.scss';
import { setHeaderText, setSettingButton } from '../../actions/main';
import { getSelectedFarm } from '../../actions/api/farms';
import { receiveFarm } from '../../actions/companies/farms';
import get from 'lodash/get';
import compact from 'lodash/compact';
import startCase from 'lodash/startCase';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import includes from 'lodash/includes';
import { isSystemCompany, farmStocksHref, isEnabledForCurrentUser, isSiteEmployee } from '../../common/utils';
import StockSettingsButton from '../common/StockSettingsButton';

class FarmSettings extends React.Component {
  constructor(props) {
    super(props);
    this.tabs = [];

    this.state = {
      activeTab: this.props.location.pathname,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.props.setHeaderText(this.props.farmName);
  }

  componentDidUpdate() {
    if (this.props.selectedFarm && this.props.farmId && this.props.farmId !== this.props.selectedFarm.id) {
      this.props.getFarm(this.props.farmId);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ? { ...prevState, activeTab: nextProps.location.pathname } : prevState;
  }

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  setSettingButton = button => {
    if (!isEqual(get(this.props.settingButton, 'button.props.href'), get(button, 'button.props.href'))) {
      this.props.setSettingButton(button);
    }
  };

  shouldShowAsset(asset) {
    if(asset === 'employees')
      return false;
    if(asset === 'fields' && isEnabledForCurrentUser("haveField"))
      return true;
    if(!this.isGrowerSite() && includes(['employees', 'fields'], asset))
      return false;
    else if (isSystemCompany() || get(this.props, 'farm.companyId') === get(this.props.user, 'companyId'))
      return true;
    else return true;
  }

  componentWillUnmount() {
    this.props.setSettingButton(null);
  }

  getComponent(asset) {
    switch (asset) {
      case 'details':
        return <UpdateFarm farmId={this.props.farmId} />;
      case 'fields':
        return <FarmFields farmId={this.props.farmId} />;
      case 'storages':
        return <Storages farmId={this.props.farmId} />;
    }
  }

  getTabPath(asset) {
    if (asset === 'fields') return 'farm-fields';
    else return asset;
  }

  isGrowerSite() {
    return get(this.props, 'selectedFarm.company.typeId') === 1;
  }

  shouldRenderTab(asset) {
    const hasAsset = find(this.tabs, { url: this.state.activeTab, label: startCase(asset) });
    if(this.isGrowerSite() || isEnabledForCurrentUser('haveField'))
      return hasAsset;
    else
      return !includes(['employees', 'fields'], asset) && hasAsset;
  }

  render() {
    const PARENT_URL = this.props.match.url;
    this.tabs = compact(
      ['details', 'fields', 'storages'].map(asset => {
        if (this.shouldShowAsset(asset))
          return {
            label: startCase(asset),
            url: `${PARENT_URL}/${this.getTabPath(asset)}`,
            component: () => this.getComponent(asset),
          };
      }),
    );
    const stockButtonLink = !isSiteEmployee() && (this.props.isAssociated || isSystemCompany())
                          ? farmStocksHref(this.props.farmId)
                          : null;
    var settingButtonContent =
      stockButtonLink
      ? {
        button: <StockSettingsButton href={stockButtonLink} buttonText='Stocks' />,
      }
      : null;
    this.setSettingButton(settingButtonContent);
    return (
      <div>
        <div className='tab'>
          <div className='tab-header'>
            <CommonTabs value={this.props.location.pathname} tabs={this.tabs} />
          </div>
          {this.tabs.length > 0 && (
             <div key={0} className='tab-content'>
               {this.shouldRenderTab('details') && <UpdateFarm farmName={this.props.farmName} farmId={this.props.farmId} />}
               {this.shouldRenderTab('fields') && (
                  <FarmFields farmName={this.props.farmName} farmId={this.props.farmId} canActOnFarm={this.props.canActOnFarm} />
               )}
               {this.shouldRenderTab('storages') && (
                  <Storages farmName={this.props.farmName} farmId={this.props.farmId} canActOnFarm={this.props.canActOnFarm} {...this.props} />
               )}
             </div>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setHeaderText: text => dispatch(setHeaderText(text)),
  getFarm: farmId => dispatch(getSelectedFarm(farmId, receiveFarm)),
  setSettingButton: button => dispatch(setSettingButton(button)),
});

const mapStateToProps = state => ({
  selectedFarm: state.companies.farms.selectedFarm,
  user: state.main.user.user,
  settingButton: state.main.settingButton,
});

export default connect(mapStateToProps, mapDispatchToProps)(FarmSettings);

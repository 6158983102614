import React, { Component } from 'react';

import CheckBox from '@mui/icons-material/CheckBox';
import HighlightOff from '@mui/icons-material/HighlightOff';
import get from 'lodash/get';

class PasswordValidIndicator extends Component {
  constructor(props) {
    super(props);
  }

  componentDidUpdate() {
    if(this.props.onChange)
      this.props.onChange(this.isValid());
  }

  isValid() {
    return Boolean(
      this.has8Characters() &&
      this.hasNumber() &&
      this.hasUppercaseCharacter() &&
      this.hasLowercaseCharacter() &&
      this.hasSpecialCharacter()
    );
  }

  has8Characters() {
    return get(this.props.password, 'length') >= 8;
  }

  hasNumber() {
    return this.props.password && this.props.password.match(/\d/);
  }

  hasUppercaseCharacter() {
    return this.props.password && this.props.password.match(/[A-Z]/);
  }

  hasLowercaseCharacter() {
    return this.props.password && this.props.password.match(/[a-z]/);
  }

  hasSpecialCharacter() {
    // eslint-disable-next-line no-useless-escape
    return this.props.password && this.props.password.match(/[\(\)\[\]\{\}\|\\\`\~\!\@\#\$\%\^\&\*\_\-\+\=\;\:\'\"\,\<\>\.\/\?]/);
  }

  getIndicatorIcon(valid) {
    if(valid)
      return <CheckBox color='primary' style={{fontSize: '15px'}}/>;
    else
      return <HighlightOff color='error' style={{fontSize: '15px'}}/>;
  }

  render() {
    return(
      <div
        className="form-wrap-70 sign-up-field sign-up-right-field"
        style={this.props.mainContainerStyle}
      >
        <div>Password must:</div>
        <div style={this.props.indicatorContainerStyle}>
          <div style={{display: 'flex'}}>
            <span style={{marginRight: '5px'}}>
              {this.getIndicatorIcon(this.has8Characters())}
            </span>
            <span>Be at least 8 characters</span>
          </div>
          <div style={{display: 'flex'}}>
            <span  style={{marginRight: '5px'}}>
              {this.getIndicatorIcon(this.hasNumber())}
            </span>
            <span>Have at least one number</span>
          </div>
          <div style={{display: 'flex'}}>
            <span  style={{marginRight: '5px'}}>
              {this.getIndicatorIcon(this.hasUppercaseCharacter())}
            </span>
            <span>Have at least one uppercase letter</span>
          </div>
          <div style={{display: 'flex'}}>
            <span  style={{marginRight: '5px'}}>
              {this.getIndicatorIcon(this.hasLowercaseCharacter())}
            </span>
            <span>Have at least one lowercase letter</span>
          </div>
          <div style={{display: 'flex'}}>
            <span  style={{marginRight: '5px'}}>
              {this.getIndicatorIcon(this.hasSpecialCharacter())}
            </span>
            <span>Have at least one special character</span>
          </div>
        </div>
      </div>
    );
  }
}

export default PasswordValidIndicator;

import { apiURL, forceStopLoader } from '../main';
import axios from 'axios';
import APIService from '../../services/APIService';

export const RECEIVE_NOTES = 'RECEIVE_NOTES';
export const RECEIVE_HISTORY = 'RECEIVE_HISTORY';
export const ADD_NOTE = 'ADD_NOTE';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const receiveHistory = items => ({
  type: RECEIVE_HISTORY,
  items
});

export const receiveNotes = items => ({
    type: RECEIVE_NOTES,
    items
});

export const addNote = item => ({
    type: ADD_NOTE,
    item
});

export const createNote = payload => (dispatch, getState) => {
    const user = getState().main.user;
    fetch(`${apiURL}/profiles/${user.user.id}/notes`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-type': 'application/json',
            accept: 'application/json',
          Authorization: `Token ${user.token}`,
          'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
          'WEB-VERSION': VERSION,
          'REFERER-COUNTRY': localStorage.current_country
        },
    }).then(response => response.json())
      .then((json) => {
            dispatch(addNote(json));
    });
};

export const getNotes = (companyId, objectId, objectType, receiveNotes) => {
    return (dispatch, getState) => {
        const user = getState().main.user;
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };

        axios.get(`${apiURL}/profiles/${user.user.id}/notes?objectId=${objectId}&objectType=${objectType}`, config)
        .then((response) => {
            dispatch(receiveNotes(response.data));
        })
        .catch((error) => {
            throw (error);
        });
    };
};

/* eslint no-unused-vars: off */
export const getAuditHistory = (objectId, objectType) => {
  if (objectType === 'contract')
    return getContractAuditHistory(objectId);
  if (objectType === 'freightorder')
    return getOrderAuditHistory(objectId);
  if (objectType === 'freightcontract')
    return getMovementAuditHistory(objectId);
  if (objectType === 'invoice')
    return getInvoiceAuditHistory(objectId);
  if (objectType === 'vendor_dec')
    return getVendorDecAuditHistory(objectId);
  if (objectType === 'cash_prices')
    return getCashPriceAuditHistory(objectId);
};

export const getContractAuditHistory = (objectId) => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios.get(`${apiURL}/audit-history/contracts/${objectId}/`, config)
      .then((response) => {
        dispatch(receiveHistory(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getOrderAuditHistory = objectId => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios.get(`${apiURL}/audit-history/orders/${objectId}/`, config)
      .then((response) => {
        dispatch(receiveHistory(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getCashPriceAuditHistory = objectId  => {
  return (dispatch, getState) => {

    const token = getState().main.user.token;
    APIService['audit-history']()
      .appendToUrl(`cash_prices/${objectId}/`)
      .get(token)
      .then((response) => {
        dispatch(receiveHistory(response));
        dispatch(forceStopLoader())
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getMovementAuditHistory = objectId => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios.get(`${apiURL}/audit-history/movements/${objectId}/`, config)
      .then((response) => {
        dispatch(receiveHistory(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getInvoiceAuditHistory = objectId => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios.get(`${apiURL}/audit-history/invoices/${objectId}/`, config)
      .then((response) => {
        dispatch(receiveHistory(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

export const getVendorDecAuditHistory = objectId => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    axios.get(`${apiURL}/audit-history/vendor_decs/${objectId}/`, config)
      .then((response) => {
        dispatch(receiveHistory(response.data));
      })
      .catch((error) => {
        throw (error);
      });
  };
};

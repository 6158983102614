import React from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import { merge, isEmpty, some, get, mergeWith, isArray } from 'lodash';
import { setHeaderText, setBreadcrumbs, isLoading, forceStopLoader, setSubHeaderText } from '../../../actions/main';
import DeclarationType from './DeclarationType';
import APIService from '../../../services/APIService';
import CommodityContractParties from '../../freights/CommodityContractParties';
import CommodityInfo from './CommodityInfo';
import DeliveryInfo from './DeliveryInfo';
import TruckDetails from './TruckDetails';
import AdditionalInfo from './AdditionalInfo';
import CommonButton from '../../common/CommonButton';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import Preview from '../Preview';
import CustomEmailDialog from '../../common/CustomEmailDialog';
import { isCurrentUserBroker } from '../../../common/utils';
import { FREIGHT_CONTRACT_TYPE, SYSTEM_COMPANY_IDS } from '../../../common/constants';
import { getAutoSelectFocusField } from '../../../common/utils';
import Signatory from './Signatory';
import omit from 'lodash/omit';
import { isCustomerGradeOrTonnageMissing } from '../../freights/utils';

class TruckVendorDecForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            URLSelectedEntityType: null,
            URLSelectedEntityId: null,
            validate: false,
            selectedEntity: null,
            selectedTemplate: null,
            data: {},
            constants: {},
            commodity: {},
            typeId: 1,
            errors: {
                declarationType: true,
                deliveryInfo: true,
                truck: true,
            },
            preview: false,
            showEmailDialog: false,
            isVarietyMandatoryInCommodityDec: false,
          updateCommodityFields: false,
          materials: []
        };
        this.updateData = this.updateData.bind(this);
        this.updateCommodity = this.updateCommodity.bind(this);
        this.onReferenceEntitySelect = this.onReferenceEntitySelect.bind(this);
        this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
        this.fieldsOrder = [
            "data.identifier", "data.deliveryStartDate", "data.deliveryEndDate",
            "data.pickupSiteId", "data.deliverySiteId" , "data.driver", "signatoryName",
            "signatoryContact"
        ];
        this.fieldRef = {};
        this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
        this.unregToken = get(this.props.match, 'params.salt');
    }

    componentDidMount() {
      this.props.dispatch(isLoading());
      this.fetchMaterials()
        this.fetchConstants();
        this.setParamsFromURL();
        this.setHeader();
    }

  fetchMaterials = () => APIService.commodities().materials().get().then(materials => this.setState({materials: materials}))

    fetchConstants() {
        const { typeId } = this.state;
        APIService.vendor_decs().constants().appendToUrl(`${typeId}/`).get(this.unregToken).then(constants => {
          this.setState({constants: constants});
        });
    }

    setParamsFromURL() {
        const [entityId, entity] = this.extractEntityDetailsFromURL();
        if (entityId && entity)
            this.setState({ URLSelectedEntityId: entityId, URLSelectedEntityType: entity }, () => {
                this.fetchEntityDetails({ entity: entity, id: entityId });
            });
    }

    extractEntityDetailsFromURL() {
        const { location } = this.props;
        const query = new URLSearchParams(location.search);
        return [query.get('entityId'), query.get('entity')];
    }

    setHeader() {
        this.setHeaderText();
        this.setBreadcrumbs();
    }

    setHeaderText() {
        const { dispatch } = this.props;
        dispatch(setHeaderText('Create Truck Cleanliness Vendor Declaration'));
        dispatch(setSubHeaderText(''));
    }

    setBreadcrumbs() {
        const { dispatch } = this.props;
        const breadcrumbs = [
            { text: 'Vendor Declarations', route: '/vendor-decs' },
            { text: 'New' }
        ];
        dispatch(setBreadcrumbs(breadcrumbs));
    }

    onReferenceEntitySelect = (entity, template) => {
        if (entity)
            this.fetchEntityDetails(entity, template);
        else
            this.setState({ selectedEntity: null }, () => {
                setTimeout(
                    () => this.setState({ updateCommodityFields: !this.state.updateCommodityFields }),
                    500
                );
            }
            );
    };

  fetchEntityDetails(entity, template) {
    APIService.profiles().appendToUrl(`transactions/${entity.entity}/${entity.id}/`).get(this.unregToken).then(data => {

      if (template)
        this.setState({ selectedEntity: data, selectedTemplate: template, updateCommodityFields: !this.state.updateCommodityFields }, this.fetchContractContacts);
      else
        this.setState({ selectedEntity: data, updateCommodityFields: !this.state.updateCommodityFields },
          () => {
            this.fetchContractContacts();
            this.fetchVarietyMandatorySetting();
          });

      this.fetchProviderContacts(data);
    });
  }

  fetchVarietyMandatorySetting() {
    let deliveryCompanyId = get(this.state, 'selectedEntity.inload_site_company') || get(this.state, 'selectedEntity.freightDelivery.consignee.handlerCompanyId') || get(this.state, 'data.deliverySiteId.companyId');
    let pickupCompanyId = get(this.state, 'selectedEntity.outload_site_company') || get(this.state, 'selectedEntity.freightPickup.consignor.handlerCompanyId') || get(this.state, 'data.pickupSiteId.companyId');
    const promises = [];

    if (deliveryCompanyId) {
      promises.push(APIService.companies(deliveryCompanyId).get(this.unregToken));
    } else {
      promises.push(Promise.resolve({ isVarietyMandatoryInCommodityDec: false }));
    }

    if (pickupCompanyId) {
      promises.push(APIService.companies(pickupCompanyId).get(this.unregToken));
    } else {
      promises.push(Promise.resolve({ isVarietyMandatoryInCommodityDec: false }));
    }
  
    Promise.all(promises).then(([deliveryRes, pickupRes]) => {
      const isVarietyMandatoryInCommodityDec = deliveryRes.isVarietyMandatoryInCommodityDec || pickupRes.isVarietyMandatoryInCommodityDec;
      this.setState({ isVarietyMandatoryInCommodityDec: isVarietyMandatoryInCommodityDec });
    })
  }

    fetchProviderContacts = async (selectedEntity) =>{
        let {token} = this.props;
        if (!token){
            token=this.unregToken;
        }
      let providerEmployees = get(selectedEntity, 'provider.id') && !SYSTEM_COMPANY_IDS.includes(get(selectedEntity, 'provider.id')) ?
        await APIService.companies(get(selectedEntity, 'provider.id')).employees().get(token) : [];
        this.setState({
            'providerContact': providerEmployees
        });
    };

    fetchContractContacts() {
        const { selectedEntity } = this.state;
        const contractId = get(selectedEntity, 'contractId');
        const id = get(selectedEntity, 'id');
        if (contractId) {
            APIService.contracts(contractId).appendToUrl('contacts/').get(this.unregToken).then(contacts => {
                this.setState({ contacts: contacts });
            });
        }
        else {
          APIService.freights(id).appendToUrl('contacts/').get(this.unregToken).then(contacts => {
            this.setState({contacts: contacts});
          });
        }
    }

    updateData(updates, errors) {
        this.setState({
            data: mergeWith(this.state.data, updates, this.customizer),
            validate: false,
            errors: merge(this.state.errors, errors)
        });
    }

    updateCommodity(updates, errors) {
        this.setState({
            commodity: updates,
            commodityValidate: false,
            errors: merge(this.state.errors, errors)
        });
    }

    customizer = (objValue, srcValue) =>{
        return srcValue;
    };

    onSubmit = event => {
        event.preventDefault();
        if (!this.state.selectedEntity) {
            alertifyjs.error('Please select Movement', 3);
            window.scrollTo(0, 0);
            return;
        }
        isCustomerGradeOrTonnageMissing(this.state.selectedEntity, (isMissing) => {
            if(!isMissing){
                this.validate();
                this.checkIdentifierUniqueness();
                this.focusOnFirstErrorField();
            }
        });
    };

    validate() {
        this.setState({ validate: true, commodityValidate: true, partyValidate: true });
    }

    checkIdentifierUniqueness() {
        const identifier = get(this.state.data, 'identifier');
        if (identifier) {
            APIService.vendor_decs().appendToUrl(`${identifier}/is-unique/`).get(this.unregToken).then(response => {
                if (response.isUnique) {
                    if (!this.hasErrors())
                        this.getPreview();
                }
                else
                    alertifyjs.error('Vendor Dec with this identifier already exists.', 3);
            });
        }
    }

    focusOnFirstErrorField() {
        for (let i = 0; i < this.fieldsOrder.length; i++) {
            const formField = this.fieldRef[this.fieldsOrder[i]];
            const field = get(this.state, this.fieldsOrder[i]);
            const nestedFields = [
                "data.deliverySiteId", "data.pickupSiteId", "data.driver"
            ];
            const currentFields = ["data.identifier", "data.deliveryStartDate", "data.deliveryEndDate"];
            if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
                if (
                    (
                        this.fieldsOrder[i] === "data.pickupSiteId" &&
                        isEmpty(get(this.state.data, 'pickupSiteId')) &&
                        !get(this.state.selectedEntity, 'freightPickup.consignor')
                    ) ||
                    (
                        this.fieldsOrder[i] === "data.deliverySiteId" &&
                        isEmpty(get(this.state.data, 'deliverySiteId')) &&
                        !get(this.state.selectedEntity, 'freightDelivery.consignee')
                    ) ||
                    (
                        this.fieldsOrder[i] === "data.driver" &&
                        isEmpty(get(this.state.data, 'driver'))
                    )) {
                    getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
                    break;
                }
            }

            else if (currentFields.indexOf(this.fieldsOrder[i]) !== -1) {
                if ((
                    this.fieldsOrder[i] === "data.identifier" &&
                    isEmpty(this.state.data.identifier)
                ) ||
                    (
                        this.fieldsOrder[i] === "data.deliveryStartDate" &&
                        isEmpty(get(this.state.data, 'deliveryStartDate'))
                    ) ||
                    (
                        this.fieldsOrder[i] === "data.deliveryEndDate" &&
                        isEmpty(get(this.state.data, 'deliveryEndDate'))
                    )) {
                    formField.current.focus();
                    break;
                }
            }
            else if (
                field && get(field, 'errors.length') > 0
            ) {
                if (formField.current.node) {
                    formField.current.node.previousSibling.focus();
                    break;
                } else {
                    formField.current.focus();
                    break;
                }
            }
        }
    }

    getPreview() {
        const { dispatch } = this.props;
        const data = this.getPayload();
        dispatch(isLoading('vendorDecPreview'));
        APIService.vendor_decs().appendToUrl('truck/soft-view/').request(
            'POST', data, this.unregToken, { responseType: 'blob' }
        ).then(response => {
            this.setState({
                preview: true,
                previewData: window.URL.createObjectURL(response.data)
            }, () => {
                dispatch(forceStopLoader());
            });
        });
    }

    hasErrors() {
        return some(this.state.errors);
    }

    isLockedToOneParent() {
        const [entityId, entity] = this.extractEntityDetailsFromURL();
        return Boolean(entityId && entity);
    }

    getPayload() {
        const { data, selectedEntity, commodity } = this.state;
        let payload = { ...data };
        payload = omit(payload, ['signatoryCompany']);
        payload.pickupSiteId = get(payload.pickupSiteId, 'id')
        payload.deliverySiteId = get(payload.deliverySiteId, 'id')
        if (selectedEntity) {
            payload.movementId = selectedEntity.id;
            payload.assignedRego = selectedEntity.rego;
            payload.providerId = get(selectedEntity, 'provider.id');
            if (get(selectedEntity, 'seller.id')) {
                payload.sellerId = selectedEntity.seller.id;
                payload.buyerId = selectedEntity.buyer.id;
            }
            if (get(selectedEntity, 'customer.id'))
                payload.customerId = selectedEntity.customer.id;
            if (get(selectedEntity, 'freightDelivery.consignee.handler') && get(selectedEntity, 'freightPickup.consignor.handler')) {
                delete payload['pickupSiteId'];
                delete payload['deliverySiteId'];
                payload.pickupSiteId = get(selectedEntity, 'freightPickup.consignor.handler.id');
                payload.deliverySiteId = get(selectedEntity, 'freightDelivery.consignee.handler.id');
            }
            else if ( !get(selectedEntity, 'freightPickup.consignor.handler') && get(selectedEntity, 'freightDelivery.consignee.handler')) {
                delete payload['deliverySiteId'];
                payload.deliverySiteId = get(selectedEntity, 'freightDelivery.consignee.handler.id');
            }

            else if (!get(selectedEntity, 'freightDelivery.consignee.handler') && get(selectedEntity, 'freightPickup.consignor.handler')) {
                delete payload['pickupSiteId'];
                payload.pickupSiteId = get(selectedEntity, 'freightPickup.consignor.handler.id');
            }
            payload.commodityId = selectedEntity.commodityId;
            payload.varietyId = selectedEntity.varietyId || commodity.varietyId.value;;
            payload.gradeId = selectedEntity.gradeId;
            payload.tonnage = selectedEntity.tonnage;
            payload.season = selectedEntity.season;
            payload.driverId = payload.driver;
            delete payload['driver'];
            return payload;
        }
    }

    closePreview = () => {
        this.setState({ preview: false });
    };

    openEmailDialog = () => {
        if (this.unregToken)
        {const data = {
          body: null,
          subject: this.getEmailSubject(),
          recipients: {creator: [get(this.state.selectedEntity, 'comm')]},
          scheduled: false,
          acceptanceRequired: false,
        };
          this.create(data);}
        this.setState({ showEmailDialog: true });
    };

    getEmailSubject() {
        const { user } = this.props;
        const identifier = get(this.state.data, 'identifier', '');
        return `${get(user, 'company.name', '')} Vendor Declaration #${identifier}`;
    }

    getPartyContacts() {
        const { contacts } = this.state;
        return {
            seller: get(contacts, 'seller.contacts'),
            buyer: get(contacts, 'buyer.contacts'),
            broker: get(contacts, 'broker.contacts'),
            customer: get(contacts, 'customer.contacts'),
        };
    }

    getPartyEmails() {
        const { contacts } = this.state;
        return {
            seller: get(contacts, 'seller.selected.email'),
            buyer: get(contacts, 'buyer.selected.email'),
            broker: get(contacts, 'broker.selected.email'),
            customer: get(contacts, 'customer.selected.email'),
        };
    }

    create = communicationData => {
        const { dispatch } = this.props;
        const data = this.getPayload();
        data.communication = communicationData || {};
        dispatch(isLoading('creatingVendorDec'));
        APIService.vendor_decs().post(data, this.unregToken).then(response => {
            dispatch(forceStopLoader());
            let errors = get(response.errors, 'errors');
            if (isArray(errors) && !isEmpty(errors)) {
                this.closePreview();
                errors = '<li>' + errors.join('</li><li>');
                const errorInfo = get(response.errors, 'errorInfo') || ''
                alertifyjs.alert(
                    'Error:',
                    `<div id="complete-dialog-open" className=""><p>Following errors occured:</p><ul>${errors}</ul><p>${errorInfo}</p></div>`,
                    () => {},
                );
            }
            else if (response.errors)
                alertifyjs.error('An Error Occurred', 3);
            else {
                this.closePreview();
                if (this.unregToken)
                    window.location.hash = '/submit/success';
                else
                    window.location.hash = this.getAfterCreateRedirectURL();
                }
        });
    };

    getAfterCreateRedirectURL() {
        const entity = get(this.state.selectedEntity, 'entity');
        const id = get(this.state.selectedEntity, 'id');
        const defaultURL = '/vendor-decs';

        if (entity && id) {
            if (entity === 'contract')
                return `/contracts/${id}/vendor-decs`;
            if (entity === 'freightorder')
                return `/freights/orders/${id}/vendor-decs`;
            if (entity === 'freightcontract')
                return `/freights/movements/${id}/vendor-decs`;
        }

        return defaultURL;
    }

    getSelectedEmailParties() {
        const { selectedEntity } = this.state;
        if (isCurrentUserBroker())
            return ['seller', 'buyer'];

        if (selectedEntity.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY)
            return ['customer'];
        return ['buyer'];
    }

    saveTemplate = event => {
        event.preventDefault();
        alertifyjs.prompt(
          'Save Template',
          'Enter Template Name',
          '',
          (event, value) =>  {
            const { dispatch } = this.props;
            const data = this.getPayload();
            data.status = 'template';
            data.templateName = value;
            delete data.identifier;
            dispatch(isLoading('savingTemplate'));
            APIService.vendor_decs().post(data, this.unregToken).then(response => {
              dispatch(forceStopLoader());
              if(response.errors) {
                dispatch(forceStopLoader());
                alertifyjs.error('An Error Occurred', 3);
              } else
              alertifyjs.success('Saved Template: ' + value);
            });
          },
          () => { }
        );
      };


  closeEmailDialog = (communicationData, justClose) => {
    if(justClose) {
      this.gotOncePartyContacts = false;
      this.setState({showEmailDialog: false});
    }
    else if(this.state.showEmailDialog) {
      this.setState({showEmailDialog: false}, () => {
        this.create(communicationData);
      });
    }
  };

    getEmailParties() {
        const { selectedEntity } = this.state;
        if (selectedEntity.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY)
            return ['customer', 'consignor', 'consignee'];
        else return ['seller', 'buyer', 'broker', 'consignor', 'consignee'];
    }

    shouldRenderParentDependentComponents() {
        if(this.isLockedToOneParent())
          return this.state.selectedEntity;

        return true;
    }

    render() {
        const { token } = this.props;
        const emailSubject = this.getEmailSubject();
      const { selectedEntity, validate, constants, typeId, previewData, preview, showEmailDialog, selectedTemplate, materials } = this.state;
        const shouldRenderParentDependentComponents = this.shouldRenderParentDependentComponents();

        return (
            <div ref={this.formRef} id='vendor-dec-form-container'>
                <form onSubmit={this.onSubmit} noValidate>
                    {shouldRenderParentDependentComponents &&
                        <div className='cardForm'>
                            <DeclarationType
                                fieldRef={this.fieldRef}
                                onReferenceEntitySelect={this.onReferenceEntitySelect}
                                onChange={this.updateData}
                                validate={validate}
                                defaultSelectedEntity={selectedEntity}
                                token={token}
                                isLockedToOneParent={this.isLockedToOneParent()}
                                formTypeId={typeId}
                                isNotIndependent={true}
                                showToggle={false}
                            />
                        </div>
                    }
                    {
                        selectedEntity &&
                        <div className='contract-details-container'>
                            <CommodityContractParties {...selectedEntity} formTypeId={typeId} />
                        </div>
                    }
                    <div className='cardForm'>
                        <CommodityInfo
                            selectedEntity={selectedEntity}
                            validate={this.state.commodityValidate}
                            onChange={this.updateCommodity}
                            fieldRef={this.fieldRef}
                            isNotIndependent={true}
                            updateCommodityFields={this.state.updateCommodityFields}
                            isVarietyMandatoryInCommodityDec={this.state.isVarietyMandatoryInCommodityDec}
                        />
                    </div>
                    <div className='cardForm'>
                        <DeliveryInfo
                            fieldRef={this.fieldRef}
                            onChange={this.updateData}
                            validate={this.state.validate}
                            defaultSelectedEntity={selectedEntity}
                            formTypeId={typeId}
                        />
                    </div>
                    {constants &&
                        <div className='cardForm'>
                            <TruckDetails
                                {...selectedEntity}
                                constants={constants}
                                fieldRef={this.fieldRef}
                                validate={this.state.validate}
                                onChange={this.updateData}
                                unregToken={this.unregToken}
                              selectedTemplate={selectedTemplate}
                              materials={materials}
                            />
                        </div>
                    }
                    <div className='cardForm'>
                        <AdditionalInfo onChange={this.updateData} />
                        <Signatory
                            fieldRef={this.fieldRef}
                            onChange={this.updateData}
                            validate={this.state.validate}
                            user={get(this.props, 'user')}
                            contacts={this.state.providerContact}
                            companyName={get(this.state, 'selectedEntity.provider.companyName')}
                        />
                        <div className="col-md-12 padding-reset" style={{ textAlign: 'right' }}>
                            <CommonButton
                                onClick={this.saveTemplate}
                                label='Save Template'
                                variant="contained"
                                color='secondary'
                            />
                            <CommonButton
                                type='submit'
                                onClick={this.onSubmit}
                                primary={true}
                                label='Continue And Review'
                                variant="contained"
                            />
                        </div>
                    </div>
                </form>
                <Dialog open={preview} onClose={this.closePreview} scroll='paper' fullScreen>
                    <DialogTitle>Vendor Declaration Preview</DialogTitle>
                    <DialogContent>
                        <Preview ref={el => (this.componentRef = el)} data={previewData} />
                    </DialogContent>
                  <DialogActions style={{paddingBottom: "40px"}}>
                        <CommonButton label='Back' key='closeButton' default onClick={this.closePreview} variant='flat' />
                        <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.openEmailDialog} variant='flat' />
                    </DialogActions>
                </Dialog>
                {
                    showEmailDialog && !this.unregToken &&
                    <CustomEmailDialog
                        parties={this.getEmailParties()}
                        selectedParties={this.getSelectedEmailParties()}
                        title="Email PDF copies to"
                        open={showEmailDialog}
                        noBody={true}
                        subject={emailSubject}
                        disableAcceptanceRequired={true}
                        onClose={this.closeEmailDialog}
                        partyEmails={this.getPartyEmails()}
                        partyContacts={this.getPartyContacts()}
                    />
                }
            </div>);
    }
}

const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        allCompanyParties: state.companies.companies.companyParties,
        companyId: get(state.main, 'user.user.companyId'),
    };
};

export default connect(mapStateToProps)(TruckVendorDecForm);


import React from 'react';
import { connect } from 'react-redux';
import CashPricesTable from '../../containers/CashPricesTable';
import SideDrawer from '../common/SideDrawer';
import AddPriceDialog from './AddPriceDialog';
import Paper from '@mui/material/Paper';
import {
    editSelectedCashPrice, setSelectedCashPrice, viewSelectedCashPrice, getArchivedCashPrices, archivedCashPricesResponse
} from '../../actions/companies/cash-board';
import { TAB } from './constants';
import { isLoading, forceStopLoader } from '../../actions/main';
import Filters from '../common/Filters';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import APIService from '../../services/APIService';
import Button from '@mui/material/Button/Button';
import { isEmpty, get } from 'lodash';
import CommonListingButton from '../common/CommonListingButton';
import { defaultViewAction } from '../../common/utils';

class ArchivedCashPrices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openCloseAddPriceDialog: false,
            applyFilters: false,
            openSideDrawer: false,
            filters: {},
            filterValues: {
                commodity__id__in: [],
                grade__id__in: [],
                season__in: [],
                track__in: [],
                buyer__company__id__in: [],
                site__id__in: [],
            },
        };
        this.closeSideDrawer = this.closeSideDrawer.bind(this);

    }

    componentDidMount() {
        this.props.getArchivedCashPrices('', true);
        APIService.profiles()
            .filters('company_archived_cash_price')
            .get(this.props.token)
            .then(res => {
                this.setState({
                    filters: get(res, 'company_archived_cash_price', {}),
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditSelectedCashPrice !== this.props.isEditSelectedCashPrice)
            this.setState({ openCloseAddPriceDialog: this.props.isEditSelectedCashPrice });
        if (prevProps.isViewSelectedCashPrice !== this.props.isViewSelectedCashPrice)
            this.setState({ openCloseAddPriceDialog: this.props.isViewSelectedCashPrice });
    }

    closeSideDrawer() {
        this.setState({ openCloseAddPriceDialog: !this.state.openCloseAddPriceDialog });
        this.props.editSelectedCashPrice(false);
        this.props.viewSelectedCashPrice(false);
        this.props.setSelectedCashPrice([]);
    }


    componentWillUnmount() {
        this.props.setCashPricesResponse([]);
        this.props.editSelectedCashPrice(false);
        this.props.viewSelectedCashPrice(false);
        this.props.setSelectedCashPrice([]);
    }


    handleFilters = bool => {
        this.setState({
            applyFilters: bool,
            openSideDrawer: bool,
        });
    };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters') {
        const { filters } = this.state;
        APIService.profiles()
          .filters()
          .post({ company_archived_cash_price: filters }, this.props.token)
          .then(res => {
            this.setState({filters: res?.filters?.company_archived_cash_price || {}}, () => {
              this.props.isLoading();
              this.props.setCashPricesResponse([]);
              this.props.getArchivedCashPrices();
            })
          });
      }
    });
  };

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

    render() {
        return (
            <Paper className='paper-table-paginated'>
                <div>
                    <Tooltip title='Apply filters' placement='top'>
                        <Button
                            value={this.state.applyFilters}
                            variant="contained"
                            type='button'
                            onClick={() => this.handleFilters(true)}
                            color='primary'
                            className='add-button'
                            style={{ float: 'right', marginLeft: '10px' }}
                        >
                            <FilterListIcon style={{ paddingRight: '5px' }} />
                            FILTERS{' '}
                            {+!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))
                                ? `(${Object.entries(this.state.filters).filter(val => val[1].length !== 0).length})`
                                : ''}
                        </Button>
                    </Tooltip>
                    {this.state.applyFilters && (
                        <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                            <Filters
                                isLoading={this.props.isLoading}
                                forceStopLoader={this.props.forceStopLoader}
                                handleFilterState={this.handleFilterState}
                                filters={this.state.filters}
                                tracks={this.props.tracks}
                              filterValues={this.state.filterValues}
                              allCompanies
                            />
                        </SideDrawer>
                    )}
                                  <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
                    <CashPricesTable isAllCashPriceTab={TAB.CLOSED_CASH_PRICE} />
                </div>
                {(this.state.openCloseAddPriceDialog || this.props.isEditSelectedCashPrice || this.props.isViewSelectedCashPrice) &&
                    <SideDrawer
                        isOpen={this.state.openCloseAddPriceDialog}
                        title="View Price"
                        onClose={this.closeSideDrawer}
                        size='small'>
                        <AddPriceDialog onClose={this.closeSideDrawer} isAllCashPriceTab={TAB.CLOSED_CASH_PRICE} />
                    </SideDrawer>
                }
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        tracks: state.main.tracks,
        isEditSelectedCashPrice: state.companies.cashBoard.editSelectedCashPrice,
        isViewSelectedCashPrice: state.companies.cashBoard.viewSelectedCashPrice,
    };
};

const mapDispatchToProps = dispatch => ({
    getArchivedCashPrices: (query, loader) => dispatch(getArchivedCashPrices(query, loader, null)),
    setCashPricesResponse: () => dispatch(archivedCashPricesResponse([])),
    editSelectedCashPrice: flag => dispatch(editSelectedCashPrice(flag)),
    setSelectedCashPrice: () => dispatch(setSelectedCashPrice([])),
    viewSelectedCashPrice: flag => dispatch(viewSelectedCashPrice(flag)),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedCashPrices);

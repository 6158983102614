import React from 'react';
import { Paper, Button } from '@mui/material';
import MailIcon from '@mui/icons-material/ForwardToInbox';
import { connect } from 'react-redux';
import { get, find, isEmpty, isEqual, cloneDeep, map, filter, some, compact, flatten, values, sum } from 'lodash';
import { forceStopLoader, setBreadcrumbs, setHeaderText } from '../../actions/main';
import APIService from '../../services/APIService';
import { PaymentRunBasicDetails } from './PaymentRunBasicDetails';
import { getCountryCurrency, toDateFormat } from '../../common/utils';
import InvoicePayableTable from '../../containers/InvoicePayableTable';
import { PAYABLE_INVOICE_ITEMS_COLUMNS } from '../../common/constants';
import { RejectionDetails } from '../rejections/RejectionDetails';
import Communications from '../common/Communications';

class PaymentRunDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentRunId: get(props, 'match.params.payment_run_id'),
      paymentRunDetails: undefined,
      invoicesItems: undefined,
      invoiceItemsGroupedByPayee: [],
      companyDirectory: undefined,
      communications: [],
      selectedObj: false,
    }
  }

  toggleCommunications = obj => this.setState({selectedObj: obj || false})

  componentDidMount() {
    this.props.dispatch(forceStopLoader());
    this.fetchPaymentRunDetails();
    this.fetchInvoices();
  }

  setHeaderAndBreadcrumbs() {
    const breadcrumbs = [
      {text: 'Payment Runs', route: '/invoices/payment-runs'},
      {text: get(this.state.paymentRunDetails, 'identifier', '')},
    ]
    const headerText = 'Payment Run';
    this.props.dispatch(setHeaderText(headerText));
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  fetchInvoices() {
    APIService.invoices().appendToUrl(`payment-run/${this.state.paymentRunId}/items/`).get().then(response =>
      this.setState({invoicesItems: response}, this.fetchPaymentRunCommunications));
  }

  getCompanyDirectory() {
    APIService.companies().appendToUrl('directory/names/?excludeGroups=true').get().then(companies =>
      this.setState({companyDirectory: companies}, this.getPayableInvoiceItems));
  }

  getPayableInvoiceItems() {
    const { invoicesItems } = this.state;
    if (!isEmpty(invoicesItems)) {
      invoicesItems.map(obj => {
        let payee = find(this.state.companyDirectory, {id: obj.companyId});
        obj.payeeName = get(payee, 'name');
        obj.items.map(invoiceItem => {
          invoiceItem.paymentDue = toDateFormat(invoiceItem.paymentDue)
          if (invoiceItem.epr) {
            invoiceItem.total = invoiceItem.total + invoiceItem.eprTotal;
          }
          if (invoiceItem.levy) {
            invoiceItem.total = invoiceItem.total + invoiceItem.levy;
          }
          if (invoiceItem.carry) {
            invoiceItem.total += invoiceItem.carry;
          }
        });
        obj.communications = this.getCommunications(map(obj.items, 'invoiceId'))
        obj.chemicalApplicationItems.map(chemicalApplicationItem => {
          let index = obj.items.findIndex(item => item.itemId === chemicalApplicationItem.chemicalAppliedOnLoadId);
          if (index !== -1) {
            let blendLoadItem = get(obj.items, index);
            chemicalApplicationItem.ref = get(blendLoadItem, 'ref');
            chemicalApplicationItem.itemUrl = get(blendLoadItem, 'itemUrl');
            chemicalApplicationItem.loadRef = get(blendLoadItem, 'loadRef');
            chemicalApplicationItem.contract = get(blendLoadItem, 'contract');
            chemicalApplicationItem.contractUrl = get(blendLoadItem, 'contractUrl');
            chemicalApplicationItem.invoiceUrl = get(blendLoadItem, 'invoiceUrl');
            chemicalApplicationItem.invoice = get(blendLoadItem, 'invoice');
            chemicalApplicationItem.confirmedInvoice = get(blendLoadItem, 'confirmedInvoice');
            chemicalApplicationItem.contractInvoicing = get(blendLoadItem, 'contractInvoicing');
            chemicalApplicationItem.paymentDue = get(blendLoadItem, 'paymentDue');
            chemicalApplicationItem.tonnageDisplayName = get(blendLoadItem, 'tonnageDisplayName');
            chemicalApplicationItem.rateDisplayName = `${getCountryCurrency()} ${chemicalApplicationItem.rate} / ${get(chemicalApplicationItem, 'adjustments.loadUnit')}`;
            chemicalApplicationItem.sellerNgrDetails = get(blendLoadItem, 'sellerNgrDetails');
            obj.items.splice(index + 1, 0, chemicalApplicationItem);
          }
        });
      });
    }
    const newState = {...this.state};
    newState.invoiceItemsGroupedByPayee = invoicesItems
    this.setState(newState);
  }

  getCommunications = invoiceIds => filter(this.state.communications, comm => invoiceIds.includes(comm.invoiceId))

  fetchPaymentRunDetails() {
    if (this.state.paymentRunId) {
      APIService.invoices()
        .appendToUrl(`payment-run/${this.state.paymentRunId}/`)
        .get()
        .then(response => this.setState({paymentRunDetails: response}, this.setHeaderAndBreadcrumbs));
    }
  }

  fetchPaymentRunCommunications() {
    if (this.state.paymentRunId) {
      APIService.invoices()
        .appendToUrl(`payment-run/${this.state.paymentRunId}/communications/`)
        .get()
        .then(response => this.setState({communications: response}, this.getCompanyDirectory));
    }
  }

  getColumns() {
    let columns = cloneDeep(PAYABLE_INVOICE_ITEMS_COLUMNS);
    columns.map(obj => {
      if (obj.key === 'levy' || obj.key === 'epr') {
        obj.isColumnEditable = false;
      }
    });
    columns = columns.filter(column => column.key !== 'confirmedInvoice');
    if (get(this.state.paymentRunDetails, 'statusDisplay') !== 'Void') {
      const invoice = {
        urlKey: 'invoiceUrl',
        header: 'Invoice',
        fieldType: 'url-conditional',
        link: true,
        key: 'invoice',
        className: 'small'
      }
      columns.splice(2, 0, invoice);
    }
    return columns;
  }

  render() {
    const { invoiceItemsGroupedByPayee, selectedObj } = this.state;
    const tableColumns = this.getColumns();
    let reasonobject = {}
    reasonobject.requestReason = get(this.state.paymentRunDetails, 'voidReason');
    reasonobject.action = "Payment Run Void"
    return (
      <Paper className='paper-table'>
        <PaymentRunBasicDetails
          paymentRunDetails={this.state.paymentRunDetails}
        />
        {
          reasonobject &&
            <RejectionDetails
              rejectionReasonObject={reasonobject}
              className="order-details-section-container"
            />
        }
        <div>
          { !isEmpty(invoiceItemsGroupedByPayee) &&
            invoiceItemsGroupedByPayee.map(obj => {
              const communications = obj.communications
              const recipients = compact(flatten(map(communications, comm => flatten(values(comm.recipients)))))
              const mailStatus = sum(flatten(map(communications, comm => values(comm.mailStatus))))
              const isPending = some(communications, comm => isEmpty(comm.mailStatus))
              const success = recipients.length === mailStatus
              const partialSuccess = mailStatus > 0;
              const color = success ? 'success' : ((partialSuccess || isPending) ? 'info' : 'error')
              return (
                <div key={obj.payeeName}>
                  <div style={{ background: '#F5F5F5', display: 'flex', alignItems: 'center' }}>
                    <span style={{ paddingLeft: '10px', fontSize: '20px', lineHeight: '50px', alignItems: 'center' }}>
                      {obj.payeeName}
                    </span>
                    {
                      !isEmpty(obj.communications) &&
                        <span style={{marginLeft: '10px', alignItems: 'center'}}>
                          <Button onClick={() => this.toggleCommunications(obj)} variant='text' startIcon={<MailIcon fontSize='small' />} size='small' color={color}>
                            Email Status
                          </Button>
                        </span>
                    }
                  </div>
                  <div>
                    <InvoicePayableTable
                      items={obj.items}
                      columns={tableColumns}
                    />
                  </div>
                </div>
              )
            })
          }
        </div>
        {
          Boolean(selectedObj) &&
            <Communications
              open
              communications={selectedObj.communications}
              title={`${selectedObj.payeeName} Communications`}
              onClose={() => this.toggleCommunications()}
            />
        }
      </Paper>
    );
  }
}

export default connect()(PaymentRunDetails);

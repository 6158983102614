import React from 'react';
import { connect } from 'react-redux';

import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CheckCircle from '@mui/icons-material/CheckCircle';
import TextField from '@mui/material/TextField';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import { required } from '../../../common/validators';
import CommonButton from '../../common/CommonButton';
import {
  confirm,
  reject,
} from '../../../actions/companies/contracts';
import { RejectionReasonDialog } from "../../rejections/RejectionReasonDialog";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';

class ContractPendingAcceptanceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      acceptDialogOpen: false,
      rejectDialogOpen: false,
      isFetching: true,
      contracts: [],
      selectedContract: null,
      contractNumber: {
        value: undefined,
        errors: [],
        validators: [],
      },
      rejectionReason: {
        value: undefined,
        errors: [],
        validators: [required()],
      }
    };
  }

  componentDidMount() {
    const { token } = this.props;
    if(token)
      APIService.contracts().appendToUrl('acceptance/pending/').get(token).then(contracts => {
        const isOk = isArray(contracts);
        this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
          this.props.onDataLoad(this.state.contracts.length);
        });
      });
  }

  componentDidUpdate() {
    this.removeSuccessfulContractFromList();
    const responseContractId = get(this.props, 'responseContract.id');
    const selectedContractId = get(this.state, 'selectedContract.id');
    if (
        responseContractId && selectedContractId && selectedContractId === responseContractId &&
        (this.state.rejectionReason.value || !this.state.rejectionReason.validators.length)
      ) {
      this.setState({
          rejectionReason: {
            value: undefined,
            validators: [required()],
            errors: []
          }
        }
      );
    }
  }

  removeSuccessfulContractFromList() {
    const { responseContract } = this.props;
    const { selectedContract, contracts } = this.state;
    if(
      selectedContract && responseContract &&
      get(responseContract, 'id') === selectedContract.id &&
      !isEmpty(contracts)
    ) {
      const index = findIndex(contracts, {id: responseContract.id});
      this.setState({
        contracts: [...contracts.slice(0, index), ...contracts.slice(index + 1)],
        selectedContract: null,
        acceptDialogOpen: false,
        rejectDialogOpen: false,
      }, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/contracts/${id}/contract`);
  }

  getTitle() {
    let suffix = this.state.isFetching ? 'Loading...' : this.state.contracts.length;
    return `Pending Acceptance Request (${suffix})`;
  }

  onAcceptClick(event, contract) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      selectedContract: contract,
      acceptDialogOpen: true,
      contractNumber: {
        ...this.state.contractNumber, value: contract.contractNumber || undefined
      }
    });
  }

  onRejectClick(event, contract) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      selectedContract: contract,
      rejectDialogOpen: true,
      rejectionReason: {
        ...this.state.rejectionReason,
        errors: this.state.rejectionReason.value ? this.state.rejectionReason.errors : []
      }
    });
  }

  handleRejectClose(){
    this.setState({rejectDialogOpen: false, selectedContract: null});
  }

  handleAcceptClose() {
    this.setState({acceptDialogOpen: false, selectedContract: null});
  }

  handleAcceptSubmit(event) {
    event.stopPropagation();
    event.preventDefault();

    const data = {
      contractNumber: this.state.contractNumber.value,
      updatedAt: moment.utc(get(this.state,'selectedContract.updatedAt')).local().valueOf()
    };
    this.props.dispatch(confirm(this.state.selectedContract.id, data, null, false));
  }

  handleContractNumberChange(event) {
    this.setState({
      contractNumber: {
        ...this.state.contractNumber,
        value: event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase()
      }
    });
  }

  handleRejectionReasonChange(event) {
    this.setState({rejectionReason: {...this.state.rejectionReason, value: event.target.value}});
  }

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState({
      rejectionReason: {...this.state.rejectionReason, errors: errors || this.getReasonErrors()}
    });
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  handleRejectSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setReasonErrors();
    const {selectedContract, rejectionReason} = this.state;
    const data = {
      rejectionReason: rejectionReason.value,
      updatedAt: moment.utc(selectedContract.updatedAt).local().valueOf()
    };
    if (rejectionReason.errors.length === 0) {
      this.props.dispatch(reject(selectedContract.id, data, null, false));
    }
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion defaultExpanded style={{margin: '0px'}}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Identifier</TableCell>
                      <TableCell className="medium">Seller</TableCell>
                      <TableCell className="medium">Buyer</TableCell>
                      <TableCell className="medium">Description</TableCell>
                      <TableCell className="xxsmall">Price Point</TableCell>
                      <TableCell className="xxsmall">{getCountryLabel('tonnage')}</TableCell>
                      <TableCell align='center' className="small">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{contract.referenceNumber}</TableCell>
                          <TableCell className="medium">{contract.sellerName}</TableCell>
                          <TableCell className="medium">{contract.buyerName}</TableCell>
                          <TableCell className="medium">{contract.description}</TableCell>
                          <TableCell className="xxsmall">{contract.pricePoint}</TableCell>
                          <TableCell align='center' className="xxsmall">{contract.tonnage} {contract.tonnageUnit}</TableCell>
                          <TableCell align='center' className="small">
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-red-outlined"
                              onClick={(event) => this.onRejectClick(event, contract)}>
                              Reject
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{marginLeft: '8px'}}
                              onClick={(event) => this.onAcceptClick(event, contract)}>
                              Accept
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <Dialog
          open={this.state.acceptDialogOpen}
          onClose={(event) => this.handleAcceptClose(event)}
          aria-labelledby="form-dialog-title"
          fullWidth
        >
          <DialogTitle id="form-dialog-title">
            <CheckCircle style={{ fill: '#6aae20', height: '35px', width: '35px', verticalAlign: 'middle' }} /> Accept Contract
          </DialogTitle>
          <DialogContent>
            <TextField
              id="contractNumber"
              label="Contract No"
              placeholder="Please enter your Contract No"
              value={this.state.contractNumber.value}
              onChange={(event) => this.handleContractNumberChange(event)}
              helperText={get(this.state, 'contractNumber.errors[0]', '')}
              error={!isEmpty(get(this.state, 'contractNumber.errors[0]', ''))}
              errorStyle={{textAlign: "left"}}
              disabled={get(this.state, 'selectedContract.isContractNumberNonEditable')}
              inputProps={{
                maxLength: 14,
              }}
              fullWidth
              variant="standard" />
          </DialogContent>
          <DialogActions>
            <CommonButton onClick={() => this.handleAcceptClose()} key='cancel' label='Cancel' secondary variant="contained" />
            <CommonButton onClick={(event) => this.handleAcceptSubmit(event)} key='accept' label='Accept' primary variant="contained" />
          </DialogActions>
        </Dialog>
        <div className="status-actions-wrap">
          <RejectionReasonDialog
            open={this.state.rejectDialogOpen}
            onClose={() => this.handleRejectClose()}
            title="Reject Contract"
            value={this.state.rejectionReason.value}
            onChange={(event) => this.handleRejectionReasonChange(event)}
            helperText={get(this.state, 'rejectionReason.errors[0]', '')}
            onCancel={() => this.handleRejectClose()}
            onReject={(event) => this.handleRejectSubmit(event)}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseContract: state.companies.contracts.confirmedOrRejectedContract
});

export default connect(mapStateToProps)(ContractPendingAcceptanceGroup);

import React from 'react';
import { ExpandCircleDown as ExpandIcon } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { get, includes, without } from 'lodash';


const TruckTemplate = ({ truck, onContextMenu, onCollapse, isMobileDevice }) => {
  const getCache = () => JSON.parse(get(localStorage, 'freightScheduler', '{}'));
  const isExpandedFromCache = () => !includes(get(getCache(), 'collapsedTrucks', []), truck.id);
  const updateCache = collapsed => {
    const cache = getCache();
    if (!cache.collapsedTrucks)
      cache.collapsedTrucks = [];
    if (collapsed)
      cache.collapsedTrucks = [...cache.collapsedTrucks, truck.id];
    else
      cache.collapsedTrucks = without(cache.collapsedTrucks, truck.id);
    localStorage.setItem('freightScheduler', JSON.stringify(cache));
  };
  const [expanded, setExpanded] = React.useState(isExpandedFromCache);
  const onExpandClick = event => {
    event.stopPropagation();
    event.preventDefault();
    const newExpanded = !expanded;
    setExpanded(newExpanded);
    onCollapse(truck, !newExpanded);
    updateCache(!newExpanded);
  };

  return (
    <React.Fragment>
      <div
        onClick={!expanded ? onExpandClick : null}
        onContextMenu={truck.managed ? null : onContextMenu}
        className='col-sm-12 col-xs-12 no-side-padding truck'
        style={{ alignItems: 'center', borderRadius: '5px', border: `2px solid ${truck.color}`, }}>
        <div className='col-sm-12 col-xs-12 no-right-padding' style={{ paddingLeft: '5px' }} onClick={onExpandClick}>
          <div className='col-sm-12 col-xs-12 no-side-padding' style={{whiteSpace: "pre-wrap", overflowWrap: "break-word"}}>
            <b>{truck.rego}</b>
            {
              !truck?.isActive &&
              <div style={{ fontSize: isMobileDevice ? '10px' : '12px' }}>(Archived)</div>
            }
            {
              truck.managed && truck.companyName &&
              <div style={{ lineHeight: '10px', marginBottom: '5px' }}>
                <i style={{ fontSize: '10px', whiteSpace: 'break-spaces' }}>
                  {truck.companyName}
                </i>
              </div>
            }
          </div>
          <div className='col-sm-12 col-xs-12 no-side-padding' style={{display: 'flex', flexDirection: 'row',  justifyContent: 'space-between'}}>
            <div style={{ fontSize: isMobileDevice ? '10px' : '12px' }}>
              ({truck?.totalWeights?.netWeight ?? 0} MT)
            </div>
            <div style={{ padding: '1px', cursor: 'pointer', display: 'flex', justifyContent: 'right' }}>
              <Tooltip arrow title={expanded ? 'Collapse' : 'Expand'}>
                {
                  expanded ?
                    <ExpandIcon style={{transform: 'rotate(-180deg)', marginTop: '-6px', color: '#337ab7'}} fontSize='inherit' /> :
                  <ExpandIcon style={{marginTop: '-6px', color: '#337ab7'}} fontSize='inherit' />
                }
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TruckTemplate;

import React, {Component} from 'react';
import { compose, withProps, lifecycle } from "recompose";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  DirectionsRenderer,
  Marker,
} from "react-google-maps";
import { get, map, isEqual } from 'lodash';

const DEFAULT_LOCATION = {
  formatted_address: 'Australia',
  lat: -23.7001391,
  lng: 133.8810526,
};

class GoogleMapDirection extends Component {
  render() {
    return <MapWithADirectionsRenderer {...this.props}/>;
  }
}

const MapWithADirectionsRenderer = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyD7fXBq6bEoFpMvKq7mRsRu_ewTmtBY42I&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  lifecycle({
    componentDidMount() {
      let { source, destination, waypoints, slotStatus, slotType, movementStatus } = this.props;
      // eslint-disable-next-line no-undef
      let wayPoints = map(waypoints, point => ({location: new google.maps.LatLng(point.latitude, point.longitude)}))
      let origin;

      if (isEqual(slotType, 'outload') && get(waypoints, '0') && isEqual(movementStatus, 'confirmed') ) {
        destination = source;
        this.setState({
          slotStatus
        })
      } else if(isEqual(slotType, 'inload') && source && isEqual(movementStatus, 'confirmed')) {
        // eslint-disable-next-line no-undef
        wayPoints.push({location: new google.maps.LatLng(get(source, 'latitude'), get(source, 'longitude')), stopover: true});
      }

      if(source) {
        // eslint-disable-next-line no-undef
        origin = new google.maps.LatLng(get(source, 'latitude'), get(source, 'longitude'))
      } else {
        let truckLocation = get(waypoints, '0')
        // eslint-disable-next-line no-undef
        origin = new google.maps.LatLng(get(truckLocation, 'latitude'), get(truckLocation, 'longitude'))
      }
      if(!destination)
        destination = get(waypoints, '0');

      // eslint-disable-next-line no-undef
      const DirectionsService = new google.maps.DirectionsService();
      DirectionsService.route({
        // eslint-disable-next-line no-undef
        origin: origin,
        // eslint-disable-next-line no-undef
        destination: new google.maps.LatLng(get(destination, 'latitude'), get(destination, 'longitude')),
        // eslint-disable-next-line no-undef
        travelMode: google.maps.TravelMode.DRIVING,
        // eslint-disable-next-line no-undef
        waypoints: wayPoints,
      }, (result, status) => {
        // eslint-disable-next-line no-undef
        if (status === google.maps.DirectionsStatus.OK) {
          this.setState({
            directions: result,
          });
        } else {
          // eslint-disable-next-line no-console
          console.error(`error fetching directions ${result}`);
        }
      });
    }
  })
)(props =>
  <GoogleMap
    defaultZoom={7}
    // eslint-disable-next-line no-undef
    defaultCenter={new google.maps.LatLng(DEFAULT_LOCATION.lat, DEFAULT_LOCATION.lng)}
  >
    {
      props.source &&
      <Marker
        position={{lat: parseFloat(props.source.latitude), lng: parseFloat(props.source.longitude)}}
      />
    }
    {
      props.destination &&
      <Marker
        position={{lat: parseFloat(props.destination.latitude), lng: parseFloat(props.destination.longitude)}}
      />
    }
    {
      get(props.waypoints, '0') &&
      <Marker
        visible={Boolean(get(props.waypoints, '0'))}
        position={{lat: parseFloat(props.waypoints[0].latitude), lng: parseFloat(props.waypoints[0].longitude)}}
        icon={'/truck_icon.svg'}

      />
    }
    {props.directions && <DirectionsRenderer options={{suppressMarkers: true}} directions={props.directions} />}
  </GoogleMap>
);

export default GoogleMapDirection;

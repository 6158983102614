import { connect } from 'react-redux';
import ReleaseNoteForm from '../components/release-notes/ReleaseNoteForm';
import { editReleaseNote, cancelEditReleaseNote, updateReleaseNote } from '../actions/companies/release-notes';
import find from 'lodash/find';

function submit(data) {
  return (dispatch, getState) => {
    const releaseNoteId = getState().companies.releaseNotes.selectedReleaseNoteId;
    dispatch(updateReleaseNote(releaseNoteId, data, editReleaseNote));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedReleaseNote: find(
      state.companies.releaseNotes.items,
      { id: state.companies.releaseNotes.selectedReleaseNoteId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (data) => dispatch(submit(data)),
    cancelEdit: () => dispatch(cancelEditReleaseNote()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReleaseNoteForm);

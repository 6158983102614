import React from 'react';
import { connect } from 'react-redux';
import { toDateFormat, currentUserCompany } from '../../common/utils';
import APIService from '../../services/APIService';
import PaymentRunsTable from '../../containers/PaymentRunsTable';
import { setPaymentRuns } from '../../actions/companies/invoices';
import { get, isEqual } from 'lodash';
import { setBreadcrumbs, setHeaderText, isLoading, forceStopLoader } from '../../actions/main';


class PaymentRuns extends React.Component {

  componentDidMount() {
    this.props.dispatch(isLoading());
    this.setHeaderAndBreadcrumbs();
    this.fetchPaymentRuns();
  }

  componentDidUpdate() {
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    const countLabel = ` (${this.props.count})`;
    const breadcrumbs = [{ text: 'Payment Run' + countLabel }];
    const headerText = 'Payment Run';
    this.props.dispatch(setHeaderText(headerText));
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  fetchPaymentRuns() {
    const company = currentUserCompany();
    APIService.invoices()
      .appendToUrl(`payment-runs/?company_id=${company.id}`)
      .get(this.props.token)
      .then(response => {
        response.map(obj => {
          obj.paymentDue = toDateFormat(obj.paymentDue)
          obj.bankProcessingDate = obj.bankProcessingDate ? toDateFormat(obj.bankProcessingDate) : null;
        });
        this.props.dispatch(setPaymentRuns(response));
        this.props.dispatch(forceStopLoader());
      });
  }

  render() {
    return (
      <div>
        <PaymentRunsTable
         {...this.props}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({count: get(state.companies.invoices, 'paymentRunItems.length')});

export default connect(mapStateToProps)(PaymentRuns);

import React from 'react';
import { connect } from 'react-redux';
import '@babel/polyfill';

import moment from "moment";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import { RejectionReasonDialog } from "../../rejections/RejectionReasonDialog";
import {
  confirmVoid,
  rejectVoid,
} from '../../../actions/companies/contracts';
import { required } from '../../../common/validators';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';


class ContractPendingVoidAcceptanceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedContract: null,
      rejectDialogOpen: false,
      isFetching: true,
      contracts: [],
      rejectionReason: {
        value: undefined,
        errors: [],
        validators: [required()],
      }
    };
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.contracts().appendToUrl('void/acceptance/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulContractFromList();
  }

  removeSuccessfulContractFromList() {
    const { responseContract } = this.props;
    const { selectedContract, contracts } = this.state;
    if(
      selectedContract &&
      get(responseContract, 'id') === selectedContract.id &&
      !isEmpty(contracts)
    ) {
      const index = findIndex(contracts, {id: responseContract.id});
      this.setState({
        selectedContract: null,
        contracts: [...contracts.slice(0, index), ...contracts.slice(index + 1)],
        rejectDialogOpen: false,
      }, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/contracts/${id}/contract`);
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Void Request ${suffix}`;
  }

  onAcceptClick(event, contract) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({selectedContract: contract}, () => {
      this.props.dispatch(confirmVoid(contract.id, false));
    });
  }

  onRejectClick(event, contract) {
    event.stopPropagation();
    event.preventDefault();

    this.setState({
      rejectDialogOpen: true,
      selectedContract: contract,
      rejectionReason: {
        ...this.state.rejectionReason,
        errors: this.state.rejectionReason.value ? this.state.rejectionReason.errors : []
      }
    });
  }

  handleRejectClose() {
    this.setState({rejectDialogOpen: false, selectedContract: null});
  }

  handleRejectionReasonChange(event) {
    this.setState({rejectionReason: {...this.state.rejectionReason, value: event.target.value}});
  }

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState({
      rejectionReason: {...this.state.rejectionReason, errors: errors || this.getReasonErrors()}
    });
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  handleRejectSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    this.setReasonErrors();
    const {selectedContract, rejectionReason} = this.state;
    const data = {
      rejectionReason: rejectionReason.value,
      updatedAt: moment.utc(selectedContract.updatedAt).local().valueOf()
    };
    if (rejectionReason.errors.length === 0) {
      this.props.dispatch(rejectVoid(selectedContract.id, data, false));
    }
  }

  onChange(event, expanded) {
    if(expanded) {
      event.target.scrollIntoView(true);
      window.scrollBy(100, 100);
      if(isEmpty(this.state.contracts))
        this.getData();
    }
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Identifier</TableCell>
                      <TableCell className="medium">Seller</TableCell>
                      <TableCell className="medium">Buyer</TableCell>
                      <TableCell className="medium">Description</TableCell>
                      <TableCell className="xxsmall">Price Point</TableCell>
                      <TableCell className="xxsmall">{getCountryLabel('tonnage')}</TableCell>
                      <TableCell align='center' className="small">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{contract.referenceNumber}</TableCell>
                          <TableCell className="medium">{contract.sellerName}</TableCell>
                          <TableCell className="medium">{contract.buyerName}</TableCell>
                          <TableCell className="medium">{contract.description}</TableCell>
                          <TableCell className="xxsmall">{contract.pricePoint}</TableCell>
                          <TableCell align='center' className="xxsmall">{contract.tonnage} {contract.tonnageUnit}</TableCell>
                          <TableCell align='center' className="small">
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-red-outlined"
                              onClick={(event) => this.onRejectClick(event, contract)}>
                              Reject
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{marginLeft: '8px'}}
                              onClick={(event) => this.onAcceptClick(event, contract)}>
                              Accept
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <RejectionReasonDialog
          open={this.state.rejectDialogOpen}
          onClose={() => this.handleRejectClose()}
          title="Reject Void Request"
          value={this.state.rejectionReason.value}
          onChange={(event) => this.handleRejectionReasonChange(event)}
          helperText={get(this.state, 'rejectionReason.errors[0]', '')}
          onCancel={() => this.handleRejectClose()}
          onReject={(event) => this.handleRejectSubmit(event)}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseContract: state.companies.contracts.confirmedOrRejectedContract
});

export default connect(mapStateToProps)(ContractPendingVoidAcceptanceGroup);

import React from "react";

import CrossCircle from "@mui/icons-material/Cancel";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import CommonTextField from "../common/CommonTextField";
import {DialogTitleWithCloseIcon as DialogTitle} from "../common/DialogTitleWithCloseIcon";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import CommonButton from "../common/CommonButton";
import Dialog from "@mui/material/Dialog/Dialog";
import APIService from "../../services/APIService";
import connect from "react-redux/es/connect/connect";
import isEqual from 'lodash/isEqual';
import map from "lodash/map";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FileUpload from "../common/FileUpload";
import includes from "lodash/includes";
import get from "lodash/get";
import {required} from "../../common/validators";
import filter from "lodash/filter";
import {REJECT_LOAD_OTHER_ID} from "./constants";
import set from "lodash/set";
import forEach from "lodash/forEach";
import some from "lodash/some";
import isEmpty from "lodash/isEmpty";
import mapValues from "lodash/mapValues";
import {rejectMovementAfterDelivery} from "../../actions/companies/freights";
import alertifyjs from "alertifyjs";

class RejectLoadDialog extends React.Component {

  handleFileUpload = (fileState) => {
    const newState = {...this.state};
    newState.attachments.push(fileState.file);
    this.setState(newState);
  };

  handleFileRemove = (base64) => {
    const newState = {...this.state};
    newState.attachments = filter(newState.attachments, (a) => {
      return a.base64 !== base64;
    });
    this.setState(newState);
  };

  handleRejectLoadReasonsChange = (reasonId) => {
    const newState = {...this.state};
    const reasons = newState.rejectLoadReasons;
    if (includes(reasons, reasonId)) {
      newState.rejectLoadReasons = filter(reasons, (p) => {
        return p != reasonId;
      });
    } else {
      newState.rejectLoadReasons = [...reasons, reasonId];
    }
    if (includes(newState.rejectLoadReasons, REJECT_LOAD_OTHER_ID)) {
      newState.otherReasonHeading = 'Other Reason';
      newState.fields.rejectionReason.validators = [required()];
    } else {
      newState.fields.rejectionReason.errors = [];
      newState.fields.rejectionReason.validators = [];
      newState.otherReasonHeading = 'Other Reason (Optional)';
    }
    this.setState(newState);
  };

  constructor(props) {
    super(props);
    this.state = {
      fields: {
        rejectionReason: {
          value: undefined,
          validators: [],
          errors: []
        },
      },
      reasons: [],
      otherReasonHeading: 'Other Reason (Optional)',
      rejectLoadReasons: [],
      attachments: []
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
  }

  componentDidMount() {
    APIService.reasons().appendToUrl('?type=reject_load').get(this.props.token).then(reasons => {
      if (!isEqual(reasons, this.state.reasons)) {
        this.setState({reasons});
      }
    });
  }

  handleSubmit(event) {
    this.setAllFieldsErrors(() => {
      const isFormInvalid = some(this.state.fields, (field) => {
        return !isEmpty(field.errors);
      });
      const data = mapValues(this.state.fields, (field) => {
        return field.value;
      });

      data.rejectedLoadDetails = {
        reasons: this.state.rejectLoadReasons,
        attachments: this.state.attachments
      };

      if (!isFormInvalid) {
        if (isEmpty(data.rejectedLoadDetails.reasons)) {
          alertifyjs.error("Please select one of the rejection reasons");
        } else {
          const {dispatch} = this.props;
          dispatch(rejectMovementAfterDelivery(this.props.movement.id, data, this.props.shouldReload));
          this.setState({
              fields: {
                rejectionReason: {
                  value: undefined,
                  validators: [required()],
                  errors: []
                }
              },
              rejectLoadReasons: [],
            }
          );
        }
      }
    });
    event.preventDefault();
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  setFieldValue(key, value, callback) {
    const newState = {...this.state};
    set(newState.fields, key + '.value', value);
    this.setState(newState, () => {
      this.setFieldErrors(key);
      if (callback) callback(this.state);
    });
  }

  getFieldErrors(key) {
    const errors = [];
    const value = get(this.state.fields, `${key}.value`);
    const validators = get(this.state.fields, `${key}.validators`) || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    const newState = {...this.state};
    set(newState.fields, key + '.errors', this.getFieldErrors(key));
    this.setState(newState);
  }

  setAllFieldsErrors(callback) {
    const newState = {...this.state};
    newState.serverErrors = [];
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        maxWidth="lg"
        style={{maxWidth: '900px', margin: '0 auto'}}
      >
        <DialogTitle id="form-dialog-title">
          <CrossCircle style={{fill: '#FF0000', height: '35px', width: '35px', verticalAlign: 'middle'}}/>
          Reject Inload - This will reverse only the inload, outload from pickup site will remain as it is.
        </DialogTitle>
        <form onSubmit={this.handleSubmit} noValidate style={{marginTop: '10px'}}>
          <DialogContent style={{paddingTop: '0px'}}>
            <div className="row" id='reject-load-dialog'>
              <div className="col-sm-5">
                {map(this.state.reasons, (reason, index) => (
                  <div key={index}>
                    <Checkbox
                    id={`rejection-reason-${reason.id}`}
                    checked={includes(this.state.rejectLoadReasons, reason.id)}
                    style={{height: '40px'}}
                    onChange={() => this.handleRejectLoadReasonsChange(reason.id)}
                  />{reason.name}
                  </div>
                ))}
              </div>
              <div className="col-sm-7">
                {this.state.otherReasonHeading}
                <CommonTextField
                  id="rejectionReason"
                  placeholder="Please enter any other reason for rejection"
                  value={this.state.fields.rejectionReason.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onFieldBlur}
                  helperText={this.state.fields.rejectionReason.errors[0]}
                  errorStyle={{textAlign: "left"}}
                  fullWidth
                  maxLength="255"
                  multiline
                  rows={7}
                />
              </div>
            </div>
            <FileUpload
              className="col-sm-12 right"
              id="attachment"
              floatingLabelText="Attach File"
              fullWidth={true}
              textFieldstyle={{float: 'left', color: 'black'}}
              mainStyle={{marginTop: '10px'}}
              buttonStyle={{border: '1px solid'}}
              onChange={this.handleFileUpload}
              buttonText="Attach File"
              allowedExtensions="image/*,application/pdf"
              previewStyle={{marginTop: '10px', width: '100%'}}
              onRemove={this.handleFileRemove}
            />
          </DialogContent>
          <DialogActions>
            <DialogActions>
              <CommonButton
                label='Cancel'
                default={true}
                variant='outlined'
                onClick={this.props.onClose}
              />
              <CommonButton
                type='submit'
                label='Submit'
                primary={true}
                variant="contained"
              />
            </DialogActions>
          </DialogActions>
        </form>
      </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(RejectLoadDialog);

import React from 'react';
import { connect } from 'react-redux';
import {
  required,
  minLength,
  maxLength,
  truckRegoRegex,
} from '../../common/validators';
import { FormControlLabel, Checkbox, InputAdornment, FormLabel, IconButton, Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import alertifyjs from 'alertifyjs';
import {
  forEach, some, map, set, get, isArray, isObject, find, isEmpty, filter, times, includes,
  camelCase, cloneDeep, endsWith, toUpper, toNumber, isNumber, round, uniqBy, isString
} from 'lodash';
import {RemoveCircle as ContentRemove} from '@mui/icons-material';
import { MESSAGES } from '../../common/validators';
import { positiveDecimalFilter } from '../../common/input-filters';
import { validateRego, receiveValidateRegoResponse } from '../../actions/main';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import ColorPicker from '../common/ColorPicker';
import AddButton from '../common/AddButton';
import { getCompanyFarms } from '../../actions/api/farms';
import { receiveFarms } from '../../actions/companies/farms';
import '../../common/forms.scss';
import { DRIVER_TYPE_ID, NOTICE_NUMBERS, TRUCK_CODES } from "../../common/constants";
import './trucks.scss';
import APIService from '../../services/APIService';
import { getDefaultTruckTrailerUnit, isSystemCompany, getCountryLabel, isVisibleInCountry } from '../../common/utils';
import first from 'lodash/first';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';

const TRAILER_NOT_EMPTY = 'Trailer not empty!';
const TRAILER_CONFIRM_REMOVAL = 'Are you sure you want to remove this trailer?';
const AGRICHAIN_CONTACT_MESSAGE = `If this ${getCountryLabel('rego').toLowerCase()} belongs to your company, then contact AgriChain Support. If this is a sub freight provider's truck, then please add this company in your directory.`

const COMMON_MODEL = {
  rego: {
    value: '',
    validators: [required(), minLength(4,MESSAGES.REGO_REGEX), maxLength(10,MESSAGES.REGO_REGEX), truckRegoRegex()],
    errors: [],
    isDisabled: false,
    isAvailable: true,
  },
  tare_weight: {
    value: undefined,
    validators: [required()],
    errors: [],
    weight_errors: []
  },
  gross_weight: {
    value: undefined,
    validators: [required()],
    errors: [],
    weight_errors: []
  },
};

const TRUCK_MODEL = {
  ...COMMON_MODEL,
  farmId: {
    value: '',
    validators: [],
    errors: []
  },
  color: {
    value: '#7b1fa2',
    validators: [required()],
    errors: [],
  },
  code: {
    value: '',
    validators: [],
    errors: [],
  },
  categoryId: {
    value: undefined,
    validators: [],
    errors: [],
  },
  steer_point_5: {
    value: false,
    validators: [],
    errors: [],
  },
  steer_1_point_1: {
    value: false,
    validators: [],
    errors: [],
  },
  unit: {
    value: getDefaultTruckTrailerUnit(),
    validators: [],
    errors: [],
  },
  permitNumber: {
    value: undefined,
    validators: [],
    errors: [],
  },
  declaredMassLimit: {
    value: undefined,
    validators: [],
    errors: [],
  },
  accreditationNumber: {
    value: undefined,
    validators: [],
    errors: [],
  },
  restricted: {
    value: undefined,
    validators: [],
    errors: [],
  },
  noticeNumber: {
    value: undefined,
    validators: [],
    errors: [],
  },
  driverId: {
    value: undefined,
    validators: [],
    errors: [],
  },
};

const TRAILER_MODEL = {
  ...COMMON_MODEL,
};

class TruckForm extends React.Component {
  constructor(props) {
    super(props);
    let _truckModel = cloneDeep(TRUCK_MODEL)
    _truckModel.unit.value = getDefaultTruckTrailerUnit()

    this.RESTRICTED_CODES = [
      {id: 'R', name: 'R'},
      {id: 'R1', name: 'R1'},
      {id: 'R2', name: 'R2'},
      {id: 'R3', name: 'R3'},
    ]

    this.state = {
      permits: [],
      createNewTrailer0: false,
      createNewTrailer1: false,
      createNewTrailer2: false,
      selectedTrailers: [],
      truck: _truckModel,
      trailers: [],
      categories: [],
      truckErrorDialog: false,
      existingTruck: null,
      permitChecked: false,
      accreditationNumberChecked: false,
      restrictedChecked: false,
      loadSharing: true,
      massLimitPermit: {
        value: '',
        validators: [required()],
        errors: [],
      },
      ghms: {
        value: false,
        validators: [],
        errors: [],
      },
      truckErrors: {},
      companyTrucks: [],
      selectedCompany: undefined,
      truckMovements: []
    };

    this.addTrailer = this.addTrailer.bind(this);
    this.removeTrailer = this.removeTrailer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.getModels = this.getModels.bind(this);
    this.getIsFormValid = this.getIsFormValid.bind(this);
    this.getSubmitData = this.getSubmitData.bind(this);
    this.validateWeight = this.validateWeight.bind(this);
    this.getIsFormWeightValid = this.getIsFormWeightValid.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.handleTrailerChange = this.handleTrailerChange.bind(this);
    this.handleColorChange = this.handleColorChange.bind(this);
    this.handleTruckDetailsSelectValueChange = this.handleTruckDetailsSelectValueChange.bind(this);
  }

  componentDidMount() {
    this.fetchPermits();
    this.fetchCategories();
    this.fetchTrucks()

    if(this.props.currentUser)
      this.props.getFarms(this.props.companyId);
    if(!this.props.selectedCompany)
      this.fetchCompany()
    else {
      this.setState({selectedCompany: {...this.props.selectedCompany}}, () => this.fetchTruckMovements())
    }
  }

  fetchCompany() {
    APIService.companies(this.props.companyId)
      .get()
      .then(response => this.setState({selectedCompany: response}, () => this.fetchTruckMovements()))
  }

  fetchTruckMovements() {
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    if (this.isAssignToDriverEnabled() && selectedTruck) {
      APIService.trucks(selectedTruck.id)
        .appendToUrl('movements/?status=in_progress&is_web=true')
        .get()
        .then(resp => this.setState({truckMovements: resp}))
    }
  }

  componentWillUnmount() {
    this.props.resetValidRegoResponse();
  }

  componentDidUpdate(prevProps) {
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    const prevSelectedTruck = prevProps.selectedTruck || prevProps.truck;
    if (selectedTruck) {
      if (selectedTruck.id !== prevSelectedTruck.id) {
        this.setAllFieldsValuesBySelectedTruck();
      }
    }
    if (this.props.brokerAddingTrucksForGrower && isEmpty(this.state.truck.farmId.validators)) {
      const newState = {...this.state};
      newState.truck.farmId.validators = [required()];
      this.setState(newState);
    }
    this.setCategory();
    if(this.props.isRegoAvailable && !prevProps.isRegoAvailable){
      const newState = {...this.state};
      set(newState, `${this.props.isRegoAvailable.key}.isAvailable`, this.props.isRegoAvailable.isAvailable);
      set(newState, `${this.props.isRegoAvailable.key}.errors`, this.getFieldErrors(this.props.isRegoAvailable.key));
      this.setState(newState);
    }
    if(get(this.props, 'isRegoAvailable') !== get(prevProps, 'isRegoAvailable')){
      if(!get(this.props, 'isRegoAvailable.isAvailable')) {
        let queryParams = `?search=${get(this.state, 'truck.rego.value')}&is_active=true`;
        APIService.trucks().appendToUrl('search/' + queryParams).get().then(trucks => {
          this.setState({ existingTruck: first(filter(trucks, truck => truck.rego == get(this.state, 'truck.rego.value'))) }, this.toggleTruckErrorDialog)
        });
      }
    }
  }

  isAssignToDriverEnabled = () => isSystemCompany() || (get(this.props.currentUser, 'company.assignTruckToDrivers') && ((get(this.state.selectedCompany, 'id') === get(this.props.currentUser, 'companyId')) || !get(this.state.selectedCompany, 'transactionParticipation')))

  fetchTrucks() {
    APIService.companies(this.props.companyId)
      .appendToUrl('trucks/minimal/')
      .get()
      .then(response => this.setState({companyTrucks: response}));
  }

  toggleTruckErrorDialog = () => this.setState( { truckErrorDialog: !this.state.truckErrorDialog } )

  getCurrentDate() {
    let today = new Date();
    return `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;
  }

  fetchPermits() {
    APIService.trucks().appendToUrl(`categories/permits/?for_date=${this.getCurrentDate()}`).get().then(permits => this.setState({permits: permits}));
  }


  fetchCategories() {
    APIService.trucks().appendToUrl(`categories/?for_date=${this.getCurrentDate()}`).get().then(categories => this.setState({categories: categories}, this.setAllFieldsValuesBySelectedTruck));
  }

  addTrailer() {
    this.setState(state => set(state, 'trailers', [
      ...state.trailers,
      cloneDeep(TRAILER_MODEL)
    ]));
  }

  removeTrailer(index) {
    const trailer = get(this.state, `trailers[${index}]`);
    const isTrailerNotEmpty = some(trailer, (field) => {
      return field.value !== '' && field.value !== undefined;
    });

    const doRemoveTrailer = index => {
      const path = `createNewTrailer${index}`;
      const newState = {...this.state};
      newState[path] = false;
      newState.trailers = cloneDeep([
        ...newState.trailers.slice(0, index),
        ...newState.trailers.slice(index + 1)
      ]);
      newState.selectedTrailers = cloneDeep([
        ...newState.selectedTrailers.slice(0, index),
        ...newState.selectedTrailers.slice(index + 1)
      ]);
      this.setState(newState);
    };

    if (isTrailerNotEmpty) {
      alertifyjs.confirm(
        TRAILER_NOT_EMPTY,
        TRAILER_CONFIRM_REMOVAL,
        () => doRemoveTrailer(index),
        () => {}
      );
    } else {
      doRemoveTrailer(index);
    }
  }

  handleSubmit(event) {
    event.preventDefault()
    event.stopPropagation()
    this.setAllFieldsErrors();
    const isMassLimitFieldsValid = this.isMassLimitFieldsValid();
    if (!isMassLimitFieldsValid)
      return false;
    const submitCallback = () => {
      if (this.getIsFormValid() == true && this.getIsFormWeightValid() == true){
        let data = this.getSubmitData();
        if(this.props.farmId) {
          data.farmId = this.props.farmId;
        }
        [0, 1, 2].forEach((index) => {
          if(this.isTrailerSelectedFromExistingList(index)) {
            data.trailers[index].id = this.getSelectedTrailerId(index);
            delete data.trailers[index].rego;
            delete data.trailers[index].tare_weight;
            delete data.trailers[index].gross_weight;
          }
          else if (data.trailers.length > index)
            data.trailers[index].unit = this.state.truck.unit.value;
        });
        if(this.props.onSubmit)
          this.props.onSubmit(data);
        else
          this.props.submit(this.props.companyId, data, this.props.syncTrucks);
      }
      return false
    }
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    if (this.state.truck.driverId.value && get(selectedTruck, 'assignedDriverId') != this.state.truck.driverId.value) {
      const selectedDriver = find(this.props.companyEmployees, {id: this.state.truck.driverId.value});
      let queryParams = selectedTruck ? `?truck_id=${get(selectedTruck, 'id')}` : ''
      APIService.profiles(this.state.truck.driverId.value)
        .appendToUrl(`can-assign-truck/${queryParams}`)
        .get()
        .then(response => {
          if (!get(response, 'result')) {
            let reasons = get(response, 'reasons')
            reasons = reasons.length > 1 ? '<ul><li>' + get(response, 'reasons').join('</li><li>') + '</ul>' : '<p>' + reasons + '</p>';
            alertifyjs.alert(
              'Permission Denied',
              `<div className="">${reasons}</div>`,
              () => {},
            );
          }
          else if (selectedDriver && selectedDriver.truckId && get(selectedTruck, 'assignedDriverId') != selectedDriver.id) {
            const truck = find(this.state.companyTrucks, {id: selectedDriver.truckId});
            alertifyjs.confirm(
              'Warning',
              `This user is assigned to truck ${truck.rego}, are you sure you wish to log the driver out of their current truck? This will also change the driver on any upcoming bookings.`,
              () => {
                submitCallback();
                alertifyjs.confirm().destroy()
              },
              () => {},
            );
          }
          else
            submitCallback()
        })
    }
    else
      submitCallback();
  }

  handleFieldChange(event) {
    const value = endsWith(event.target.id, '.rego') ? toUpper(event.target.value) : event.target.value;
    this.setFieldValue(event.target.id, value);
    this.validateWeight(event);
  }

  handleColorChange(color) {
    this.setFieldValue('truck.color', color);
    if(this.props.onColorChange)
      this.props.onColorChange(color);
  }

  onBlur(event) {
    set(this.state, `${event.target.id}.isAvailable`, true);
    this.setFieldErrors(event.target.id);
    this.validateWeight(event);
    var key = event.target.id;
    setTimeout( () => {
      if(endsWith(key, '.rego')){
        if(isEmpty(get(this.state, `${key}.errors`))){
          this.props.validateRego(key, get(this.state, `${key}.value`));
        }
      }
    }, 500);
  }

  setFieldValue(path, value) {
    this.setState(
      state => set(state, `${path}.value`, value)
    );
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);
    const weight_errors = get(this.state, `${path}.weight_errors`, []);
    const is_rego_available = get(this.state, `${path}.isAvailable`);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message.replace('$regoLabel', getCountryLabel('rego')));
      }
    });

    if(isEmpty(weight_errors) == false){
      return weight_errors;
    }

    if(is_rego_available == false){
      return [`This ${getCountryLabel('rego')} already exists`];
    }

    return errors;
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  setAllFieldsValuesBySelectedTruck() {
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    if (selectedTruck) {
      const newState = {
        massLimitPermit: {
          value: '',
          validators: [required()],
          errors: [],
        },
        ghms: {
          value: false,
          validators: [],
          errors: [],
        },
        truck: cloneDeep(TRUCK_MODEL),
        trailers: times(
          selectedTruck.trailers.length,
          () => cloneDeep(TRAILER_MODEL)
        )
      };

      newState.selectedTrailers = cloneDeep(get(selectedTruck, 'trailers'));

      forEach(this.getModels(newState), (model) => {
        forEach(get(newState, model, {}), (field, key) => {
          const value = get(
            selectedTruck,
            model === 'truck' ? camelCase(key) : `${model}.${camelCase(key)}`
          );
          if (value !== undefined) set(newState, `${model}.${key}.value`, value);
          if (value !== undefined) set(newState, `${model}.${key}.isDisabled`, true);
        });
      });
      if (!isEmpty(this.state.categories) && selectedTruck.categoryId) {
        let category = find(this.state.categories, {id: selectedTruck.categoryId});
        if (category) {
          newState.massLimitPermit.value = category.massLimitPermit;
          newState.ghms.value = category.ghms;
        }
      }
      newState.truck.driverId.value = selectedTruck.assignedDriverId;
      newState.truck.categoryId.value = selectedTruck.categoryId;
      newState.truck.steer_1_point_1.value = selectedTruck.steer1Point1;
      newState.truck.steer_point_5.value = selectedTruck.steerPoint5;
      newState.truck.accreditationNumber.value = selectedTruck.accreditationNumber;
      newState.truck.declaredMassLimit.value = selectedTruck.declaredMassLimit;
      newState.truck.noticeNumber.value = selectedTruck.noticeNumber;
      newState.truck.permitNumber.value = selectedTruck.permitNumber;
      newState.truck.restricted.value = selectedTruck.restricted;
      newState.permitChecked = Boolean(selectedTruck.permitNumber);
      newState.accreditationNumberChecked = Boolean(selectedTruck.accreditationNumber);
      newState.restrictedChecked = Boolean(selectedTruck.restricted);
      this.setState(newState, () => {
        if(this.props.onColorChange)
          this.props.onColorChange(get(selectedTruck, 'color'));
      });
    }
  }

  handleSteerChange = event => {
    const newState = {...this.state};
    const checked = event.target.checked;
    newState.truck[event.target.id].value = checked;

    if(checked) {
      newState.permitChecked = false;
      if(event.target.id === 'steer_point_5')
        newState.truck.steer_1_point_1.value = false;
      else
        newState.truck.steer_point_5.value = false;
    }
    this.setState(newState);
  };

  shouldEnableSteerPoint5 = () => get(find(this.state.categories, {id: this.state.truck.categoryId.value}), 'steerPoint5');

  shouldEnableSteer1Point1 = () => get(find(this.state.categories, {id: this.state.truck.categoryId.value}), 'steer1Point1');

  handleCheckboxChange = event => {
    const newState = {...this.state}
    const value = event.target.checked
    const id = event.target.id
    set(newState, `${id}.value`, value || false)
    if (id === 'ghms' && value) {
      newState.permitChecked = false;
      newState.accreditationNumberChecked = false;
      newState.restrictedChecked = false;
    }
    this.setState(newState, () => this.setCategory())
  };

  setCategoryFromCategories(categoryId) {
    categoryId = categoryId || this.state.truck.categoryId.value;
    const category = find(this.state.categories, {id: categoryId});
    if(category) {
      if(categoryId !== this.state.truck.categoryId.value || this.state.massLimitPermit.value !== category.massLimitPermit || this.state.ghms.value !== category.ghms) {
        const newState = {...this.state};
        newState.truck.categoryId.value = categoryId;
        newState.massLimitPermit.value = category.massLimitPermit;
        newState.ghms.value = category.ghms;
        newState.loadSharing = true;
        this.setState(newState);
      }
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };

    forEach(this.getModels(), (model) => {
      forEach(get(newState, model, {}), (field, key) => {
        const path = `${model}.${key}`;
        set(newState, `${path}.errors`, this.getFieldErrors(path));
      });
    });

    this.setState(newState);
  }

  getModels(state = this.state) {
    const models = [];

    forEach(state, (value, key) => {
      if(includes(['truck', 'trailers'], key)) {
        if (isArray(value)) {
          times(value.length, (index) => models.push(`${key}[${index}]`));
        } else {
          models.push(key);
        }
      }
    });

    return models;
  }

  getIsFormValid() {
    return !some(this.getModels(), (model) => {
      return some(get(this.state, model, {}), (field) => {
        return some(field.validators, (validator) => {
          return validator.isInvalid(field.value);
        });
      });
    });
  }

  getIsFormWeightValid(){
    const newState = { ...this.state };
    var isValid = true;
    forEach(this.getModels(newState), (model) => {
      forEach(get(newState, model, {}), (field, key) => {
        if(key == 'rego' && get(newState, `${model}.rego.isAvailable`) == false){
          isValid = false;
        }
        if(key === 'tare_weight' || key === 'gross_weight'){
          var gross_weight = parseFloat(get(newState, `${model}.gross_weight.value`));
          var tare_weight = parseFloat(get(newState, `${model}.tare_weight.value`));
          if(isNaN(gross_weight) == false && isNaN(tare_weight) == false){
            if(tare_weight > gross_weight){
              set(newState, `${model}.tare_weight.weight_errors`, ["Tare Weight can't be greater than Gross Weight"]);
              set(newState, `${model}.gross_weight.weight_errors`, ["Gross Weight can't be less than Tare Weight"]);
              isValid  = false;
            }
          }
        }
      });
    });
    return isValid;
  }

  getSubmitData() {
    let submitData = cloneDeep(this.state);
    forEach(this.getModels(), (model) => {
      forEach(get(this.state, model, {}), (field, key) => {
        const path = `${model}.${key}`;
        set(submitData, path, get(this.state, `${path}.value`));
      });
    });

    submitData = {
      ...submitData.truck,
      trailers: submitData.trailers
    };
    if (!this.state.permitChecked)
      submitData.permitNumber = null;
    if (!this.state.permitChecked && !this.state.restrictedChecked)
      submitData.declaredMassLimit = null;
    if (!this.state.accreditationNumberChecked)
      submitData.accreditationNumber = null;
    if (this.state.massLimitPermit.value !== 'Notice')
      submitData.noticeNumber = null;
    if (!this.state.restrictedChecked)
      submitData.restricted = null;
    return submitData;
  }

  validateWeight(event){
    const value = endsWith(event.target.id, '.rego') ? toUpper(event.target.value) : event.target.value;
    var path = event.target.id;
    const newState = { ...this.state };
    var field_key = path.split('.');
    set(newState, `${field_key[0]}.tare_weight.weight_errors`, []);
    set(newState, `${field_key[0]}.gross_weight.weight_errors`, []);

    if(endsWith(path, '.tare_weight') || endsWith(path, '.gross_weight')) {
      var gross_weight = parseFloat(get(this.state, `${field_key[0]}.gross_weight.value`));
      var tare_weight = parseFloat(get(this.state, `${field_key[0]}.tare_weight.value`));
      if(endsWith(path, '.tare_weight')){
        tare_weight = parseFloat(value);
      } else if(endsWith(path, '.gross_weight')){
        gross_weight = parseFloat(value);
      }
      if(isNaN(tare_weight) == false && isNaN(gross_weight) == false){
        if(tare_weight > gross_weight){
          set(newState, `${field_key[0]}.tare_weight.weight_errors`, ["Tare Weight can't be greater than Gross Weight"]);
          set(newState, `${field_key[0]}.gross_weight.weight_errors`, ["Gross Weight can't be less than Tare Weight"]);
        }
      }
    }
    this.setState(newState);
  }

  handleSelectFieldChange(value, id) {
    this.setFieldValue(id, value);
    if (id === 'massLimitPermit' && value === 'Notice') {
      const newState = {...this.state};
      newState.ghms.value = false;
      newState.restrictedChecked = false;
      newState.permitChecked = false;
      this.setState(newState);
    }
    else if (id === 'massLimitPermit' && value && value.includes('PBS - ')) {
      const newState = {...this.state};
      newState.ghms.value = false;
      newState.accreditationNumberChecked = false;
      this.setState(newState);
    }
  }

  isTrailerSelectedFromExistingList(index) {
    return isObject(find(this.props.trailers, {rego: get(this.state, `trailers[${index}].rego.value`)}));
  }

  getAllAvailableTrailers() {
    const newTrailer = [{id: 'new', rego: 'New Trailer'}];
    let allTrailers = cloneDeep(this.props.trailers);
    allTrailers = allTrailers.filter(obj => obj.unit === this.state.truck.unit.value);
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    if(selectedTruck && !isEmpty(selectedTruck.trailers)) {
      allTrailers = uniqBy(allTrailers.concat(cloneDeep(selectedTruck.trailers)), 'id');
    }
    return newTrailer.concat(allTrailers);
  }

  getTrailer1Items() {
    return filter(this.getAllAvailableTrailers(), (trailer) => {
      return !(includes(
        [
          get(this.state, 'trailers[1].rego.value'),
          get(this.state, 'trailers[2].rego.value')
        ],
        trailer.rego
      ));
    });
  }
  getTrailer2Items() {
    return filter(this.getAllAvailableTrailers(), (trailer) => {
      return !(includes(
        [
          get(this.state, 'trailers[0].rego.value'),
          get(this.state, 'trailers[2].rego.value')
        ],
        trailer.rego
      ));
    });
  }
  getTrailer3Items() {
    return filter(this.getAllAvailableTrailers(), (trailer) => {
      return !(includes(
        [
          get(this.state, 'trailers[0].rego.value'),
          get(this.state, 'trailers[1].rego.value')
        ],
        trailer.rego
      ));
    });
  }

  handleTrailerChange(value, id, item) {
    const index = id.replace('trailers[', '').replace(']', '');
    const path = `createNewTrailer${index}`;
    if(item && item.id === 'new') {
      const newState = {...this.state};
      newState[path] = true;
      newState.trailers[index].rego.value = undefined;
      newState.trailers[index].rego.isDisabled = false;
      newState.trailers[index].tare_weight.value = undefined;
      newState.trailers[index].gross_weight.value = undefined;
      this.setState(newState);
    }
    else if(item && item.rego !== get(this.state, id + '.rego.value')) {
      const updateTrailerLocally = () => {
        const newState = {...this.state};
        newState[path] = false;
        set(newState, id.replace('trailers', 'selectedTrailers'), item);
        set(newState, id + '.rego.value', item.rego);
        set(newState, id + '.rego.isDisabled', true);
        set(newState, id + '.tare_weight.value', item.tareWeight);
        set(newState, id + '.gross_weight.value', item.grossWeight);
        this.setState(newState);
      };
      const resetTrailer = () => {
        this.removeTrailer(index);
        this.addTrailer();
      };
      if(this.isAlreadyAssignedTrailerSelected(item)) {
        alertifyjs.confirm(
          'Warning',
          'This trailer is already assigned to ' + item.truckRego + '. Do you want to now link it to ' + this.state.truck.rego.value + '?',
          () => updateTrailerLocally(),
          () => resetTrailer(),
        );
      } else {
        updateTrailerLocally();
      }
    }
    else if(item === null) {
      const newState = {...this.state};
      newState[path] = false;
      set(newState, id.replace('trailers', 'selectedTrailers'), null);
      set(newState, id + '.rego.value', '');
      set(newState, id + '.rego.isDisabled', false);
      set(newState, id + 'tare_weight.value', undefined);
      set(newState, id + '.gross_weight.value', undefined);
      this.setState(newState);
    }

  }

  isAlreadyAssignedTrailerSelected(trailer) {
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    if(selectedTruck) {
      return get(trailer, 'truckId') && (get(trailer, 'truckId') !== selectedTruck.id);
    } else {
      return isNumber(get(trailer, 'truckId'));
    }
  }

  cancelDrawer(){
    this.props.closeDrawer();
  }

  getSelectedTrailerId(index) {
    const trailer = find(this.getAllAvailableTrailers(), {rego: this.state.trailers[index].rego.value});
    return trailer ? trailer.id : null;
  }

  shouldShowTareWeightField(index) {
    return this.state[`createNewTrailer${index}`] || this.isTrailerSelectedFromExistingList(index) || get(this.state, `trailers[${index}].tare_weight.value`) !== undefined;
  }

  shouldShowGrossWeightField(index) {
    return this.state[`createNewTrailer${index}`] || this.isTrailerSelectedFromExistingList(index) || get(this.state, `trailers[${index}].gross_weight.value`) !== undefined;
  }

  setCategory() {
    const category = this.findCategory();
    if(get(category, 'id') !== this.state.truck.categoryId.value) {
      this.setCategoryFromCategories(get(category, 'id'));
    }
  }

  findCategory() {
    const { truck, massLimitPermit, ghms } = this.state;
    if(truck.code.value) {
      const code = truck.code.value.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
      const categories = filter(this.state.categories, category => {
        let truckCode = category.truckCode;
        if(isString(category.truckCode)) {
          truckCode = category.truckCode.toLowerCase().replaceAll(' ', '').replaceAll('-', '');
          return truckCode === code;
        }
      });
      return find(categories, {massLimitPermit: massLimitPermit.value ? massLimitPermit.value : null, ghms: ghms.value});
    }
    return undefined;
  }

  handlePermitClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    newState.massLimitPermit.validators = [required()];
    set(newState, 'permitChecked', isChecked);
    if (isChecked) {
      newState.accreditationNumberChecked = false;
      newState.ghms.value = false;
      newState.truck.steer_point_5.value = false;
      newState.truck.steer_1_point_1.value = false;
      newState.massLimitPermit.validators = []
    }
    this.setState(newState);
  }

  handleAccreditationNumberClick = event => {
    const isChecked = event.target.checked;
    const newState = {...this.state};
    set(newState, 'accreditationNumberChecked', isChecked);
    if (isChecked) {
      newState.permitChecked = false;
      newState.ghms.value = false;
    }
    this.setState(newState);
  }

  handleLoadSharingClick = event => event.target.checked ? this.setState({loadSharing: true}) : this.setState({loadSharing: false});

  handleRestrictedClick = event => {
    const isChecked = event.target.checked;
    const id = event.target.id;
    const newState = {...this.state};
    set(newState, id, isChecked);
    if (isChecked)
      newState.ghms.value = false;
    this.setState(newState);
  }

  handleTruckDetailsChange = event => {
    const newState = {...this.state}
    let id = event.target.id;
    set(newState, `${id}.value`, id === 'truck.restricted' ? event.target.checked : event.target.value);
    if (id !== 'truck.restricted' && event.target.value) {
      const parts = id.split(".")
      const errorField = `truckErrors.${parts[1]}`;
      set(newState, errorField, '')
    }
    this.setState(newState);
  }

  handleTruckDetailsSelectValueChange(value, id) {
    const newState = { ...this.state };
    set(newState, `${id}.value`, value);
    const parts = id.split(".")
    const errorField = `truckErrors.${parts[1]}`;
    set(newState, errorField, '')
    this.setState(newState);
  }

  isMassLimitFieldsValid() {
    const newState = {...this.state};
    let isValid = true;
    const ERROR_MESSAGE = "This field is required";
    if (this.state.permitChecked) {
      if (!get(this.state.truck, 'permitNumber.value')) {
        isValid = false;
        newState.truckErrors.permitNumber = ERROR_MESSAGE;
      }
      if (!get(this.state.truck, 'declaredMassLimit.value')) {
        isValid = false;
        newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
      }
    }
    if (this.state.accreditationNumberChecked && !get(this.state.truck, 'accreditationNumber.value')) {
      isValid = false;
      newState.truckErrors.accreditationNumber = ERROR_MESSAGE;
    }
    if (this.state.massLimitPermit.value === 'Notice' && !get(this.state.truck, 'noticeNumber.value')) {
      isValid = false;
      newState.truckErrors.noticeNumber = ERROR_MESSAGE;
    }
    if (this.state.restrictedChecked) {
      if (!get(this.state.truck, 'restricted.value')) {
        isValid = false;
        newState.truckErrors.restricted = ERROR_MESSAGE;
      }
      if (!get(this.state.truck, 'declaredMassLimit.value')) {
        isValid = false;
        newState.truckErrors.declaredMassLimit = ERROR_MESSAGE;
      }
    }
    const validator = get(this.state.massLimitPermit.validators, '0')
    if (validator && validator.isInvalid(this.state.massLimitPermit.value) && this.state.truck.code.value) {
      isValid = false;
      newState.massLimitPermit.errors = ['This field is required.']
    }
    this.setState(newState)
    return isValid;
  }

  render() {
    const selectedTruck = this.props.selectedTruck || this.props.truck;
    const totalTareWeight = this.getModels().map((model) => {
      const tareWeight = toNumber(get(this.state, `${model}.tare_weight.value`));
      return tareWeight ? round(tareWeight, 2) : null;
    }).reduce((accumulator, value) => round(accumulator + value, 2));

    const totalGrossWeight = this.getModels().map((model) => {
      const grossWeight = toNumber(get(this.state, `${model}.gross_weight.value`));
      return grossWeight ? round(grossWeight, 2) : null;
    }).reduce((accumulator, value) => round(accumulator + value, 2));

    const totalNetWeight = (isNumber(totalGrossWeight) && isNumber(totalTareWeight)) ? round(totalGrossWeight - totalTareWeight, 2) : null;
    const selectedUnit = this.state.truck.unit.value;
    const isTruckMassLimitsVisible = isVisibleInCountry('truckMassLimits');
    const isNoticePermit = this.state.massLimitPermit.value === 'Notice';
    let drivers = this.props.companyEmployees || [];
    if (!isEmpty(drivers))
      drivers = drivers.filter(driver => driver.typeId === DRIVER_TYPE_ID);
    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row trucks">
              <div className="col-sm-12 truck-weight-row">
                <div className="col-sm-4 truck-weight">
                  <span id="total-tare-weight" className="weight-label col-sm-12">Total Tare Weight</span>
                  <span>{totalTareWeight ? totalTareWeight + ` ${selectedUnit}`: `0 ${selectedUnit}`}</span>
                </div>
                <div className="col-sm-4 truck-weight">
                  <span id="total-gross-weight" className="weight-label col-sm-12">Total Gross Weight</span>
                  <span>{totalGrossWeight ? totalGrossWeight + ` ${selectedUnit}`: `0 ${selectedUnit}`}</span>
                </div>
                <div className="col-sm-4 truck-weight">
                  <span id="total-net-weight" className="weight-label col-sm-12">Total Net Weight</span>
                  <span>{totalNetWeight ? totalNetWeight + ` ${selectedUnit}`: `0 ${selectedUnit}`}</span>
                </div>
              </div>
              <div className="col-sm-12 prime-mover-label">
                Prime Mover
              </div>
              <div className="col-sm-3 form-wrap">
                <CommonTextField
                  id="truck.rego"
                  label={`Prime Mover ${getCountryLabel('rego')}`}
                  placeholder="Please enter"
                  value={this.state.truck.rego.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={get(this.state, 'truck.rego.errors[0]', '')}
                  onInput = {(e) =>{
                      e.target.value = e.target.value.toString().slice(0,10).replace(/[^0-9a-z]/gi, '');
                  }}
                  disabled={!isSystemCompany() && (!this.props.canAccessAny || this.state.truck.rego.isDisabled)}
                />
              </div>
              <div className="col-sm-3 form-wrap">
                <CommonTextField
                  id="truck.tare_weight"
                  label="Tare Weight"
                  placeholder="Please enter"
                  value={this.state.truck.tare_weight.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={get(this.state, 'truck.tare_weight.errors[0]', '')}
                  disabled={!this.props.canAccessAny}
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{selectedUnit}</InputAdornment>
                  }}
                />
              </div>
              <div className="col-sm-3 form-wrap">
                <CommonTextField
                  id="truck.gross_weight"
                  label="Gross Weight"
                  placeholder="Please enter"
                  value={this.state.truck.gross_weight.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={get(this.state, 'truck.gross_weight.errors[0]', '')}
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                  disabled={!this.props.canAccessAny}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">{selectedUnit}</InputAdornment>
                  }}
                />
              </div>
              {this.isAssignToDriverEnabled() &&
              <div className='col-sm-12'>
                <div className="col-sm-4 form-wrap" style={{paddingLeft: '0px'}}>
                  <CommonAutoSelect
                    id="truck.driverId"
                    label='Driver'
                    placeholder="Select driver..."
                    items={drivers}
                    value={this.state.truck.driverId.value || ''}
                    onChange={(value, id) => this.handleSelectFieldChange(value, id)}
                    dataSourceConfig={{id: 'id', text: 'name'}}
                  />
                </div>
              </div>
              }
              {
                isTruckMassLimitsVisible &&
                <React.Fragment>
                <div className="col-sm-7 form-wrap">
                  <CommonAutoSelect
                    id="truck.code"
                    label='Code'
                    placeholder="Select code..."
                    items={map(TRUCK_CODES, code => ({id: code, name: code}))}
                    value={this.state.truck.code.value || ''}
                    onChange={(value, id) => this.handleSelectFieldChange(value, id)}
                    dataSourceConfig={{id: 'id', text: 'name'}}
                  />
                </div>
                <div className="col-sm-4 form-wrap">
                  <CommonAutoSelect
                    id="massLimitPermit"
                    label='Mass Limit Permit'
                    placeholder="Select Mass Limit Permit..."
                    items={map(this.state.permits, permit => ({id: permit, name: permit}))}
                    value={this.state.massLimitPermit.value || ''}
                    onChange={(value, id) => this.handleSelectFieldChange(value, id)}
                    dataSourceConfig={{id: 'id', text: 'name'}}
                    errorText={get(this.state.massLimitPermit.errors, '0') || ''}
                  />
                </div>
                <div className="col-sm-3 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='steer_point_5'
                        color='primary'
                        checked={Boolean(this.state.truck.steer_point_5.value)}
                        onChange={this.handleSteerChange}
                        disabled={!this.shouldEnableSteerPoint5()}
                      />
                    }
                    label='0.5 T Steer Axle Allowance'
                  />
                </div>
                <div className="col-sm-3 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='steer_1_point_1'
                        color='primary'
                        checked={Boolean(this.state.truck.steer_1_point_1.value)}
                        onChange={this.handleSteerChange}
                        disabled={!this.shouldEnableSteer1Point1()}
                      />
                    }
                    label='1.1 T Allowance'
                  />
                </div>
                <div className="col-sm-2 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='ghms'
                        color='primary'
                        checked={Boolean(this.state.ghms.value)}
                        onChange={this.handleCheckboxChange}
                        disabled={isNoticePermit || (this.state.massLimitPermit.value || '').includes('PBS - ')}
                      />
                    }
                    label='GHMS'
                  />
                </div>
                <div className="col-sm-3 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='permitChecked'
                        color='primary'
                        checked={Boolean(this.state.permitChecked)}
                        onChange={this.handlePermitClick}
                        disabled={this.state.massLimitPermit.value === 'Notice'}
                      />
                    }
                    label='I have a Permit'
                  />
                </div>
                <div className="col-sm-4 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='accreditationNumberChecked'
                        color='primary'
                        checked={Boolean(this.state.accreditationNumberChecked)}
                        onChange={this.handleAccreditationNumberClick}
                        disabled={(this.state.massLimitPermit.value || '').includes('PBS - ')}
                      />
                    }
                    label='I have an accreditation number'
                  />
                </div>
                <div className="col-sm-3 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='loadSharing'
                        color='primary'
                        checked={Boolean(this.state.loadSharing)}
                        onChange={this.handleLoadSharingClick}
                        disabled
                      />
                    }
                    label='LS Present'
                  />
                </div>
                <div className="col-sm-6 form-wrap">
                  <FormControlLabel
                    control={
                      <Checkbox
                        id='restrictedChecked'
                        color='primary'
                        checked={Boolean(this.state.restrictedChecked)}
                        onChange={this.handleRestrictedClick}
                        disabled={isNoticePermit}
                      />
                    }
                    label='Restricted'
                  />
                </div>
                {!this.state.loadSharing && get(this.state.truck, 'categoryId.value') &&
                  <span style={{color: "red", fontSize: "14px", marginLeft: "30px"}}>
                    {`1 ${this.state.truck.unit.value} will be deducted from mass limit on account of no LS in truck.`}
                  </span>
                }
                {this.state.permitChecked &&
                  <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
                    <div className='col-sm-6 form-wrap-70'>
                      <CommonTextField
                        id='truck.permitNumber'
                        label="Permit Number"
                        value={this.state.truck.permitNumber.value}
                        onChange={this.handleTruckDetailsChange}
                        helperText={this.state.truckErrors.permitNumber}
                      />
                    </div>
                    <div className='col-sm-6 form-wrap-70'>
                      <CommonTextField
                        id='truck.declaredMassLimit'
                        label="Declared Mass Limit"
                        value={this.state.truck.declaredMassLimit.value}
                        onChange={this.handleTruckDetailsChange}
                        onKeyDown={event => positiveDecimalFilter(event, 2, 999.99)}
                        helperText={this.state.truckErrors.declaredMassLimit}
                      />
                    </div>
                  </div>
                }
                {this.state.accreditationNumberChecked &&
                  <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
                    <div className='col-sm-6 form-wrap-70'>
                      <CommonTextField
                        id='truck.accreditationNumber'
                        label="Accreditation Number"
                        value={this.state.truck.accreditationNumber.value}
                        onChange={this.handleTruckDetailsChange}
                        helperText={this.state.truckErrors.accreditationNumber}
                      />
                    </div>
                  </div>
                }
                {this.state.massLimitPermit.value === 'Notice' &&
                  <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
                    <div className='col-sm-6 form-wrap-70'>
                      <CommonAutoSelect
                        id="truck.noticeNumber"
                        label='Notice Number'
                        placeholder="Select Notice Number..."
                        items={NOTICE_NUMBERS}
                        value={this.state.truck.noticeNumber.value}
                        onChange={this.handleTruckDetailsSelectValueChange}
                        dataSourceConfig={{id: 'id', text: 'name'}}
                        errorText={this.state.truckErrors.noticeNumber}
                      />
                    </div>
                  </div>
                }
                {this.state.restrictedChecked &&
                  <div className="col-sm-12 form-wrap" style={{paddingLeft: '0px'}}>
                    <div className='col-sm-6 form-wrap-70'>
                      <CommonAutoSelect
                        id="truck.restricted"
                        label='Restricted Code'
                        placeholder="Select Restricted Code..."
                        items={this.RESTRICTED_CODES}
                        value={this.state.truck.restricted.value}
                        onChange={this.handleTruckDetailsSelectValueChange}
                        dataSourceConfig={{id: 'id', text: 'name'}}
                        errorText={this.state.truckErrors.restricted}
                      />
                    </div>
                    <div className='col-sm-6 form-wrap-70'>
                      <CommonTextField
                        id='truck.declaredMassLimit'
                        label="Declared Mass Limit"
                        value={this.state.truck.declaredMassLimit.value}
                        onChange={this.handleTruckDetailsChange}
                        onKeyDown={event => positiveDecimalFilter(event, 2, 999.99)}
                        helperText={this.state.truckErrors.declaredMassLimit}
                      />
                    </div>
                  </div>
                }
                </React.Fragment>
              }
              {
                this.state.trailers.length < 3 &&
                <div className="col-sm-12">
                  <AddButton
                    className="float-plus-btn"
                    label="Attach Trailer"
                    onClick={this.addTrailer}
                    disabled={!this.props.canAccessAny}
                    style={{marginLeft: '14px'}}
                    color="secondary"
                    variant="outlined"
                  />
                </div>
              }
              {
                this.state.trailers.length > 0 &&
                <div className="col-sm-12 trailers-label">
                  Trailers
                </div>
              }
            </div>
            {this.state.trailers.map((trailer, index) => (
              <div className="cardForm-content row trucks" key={index} style={{marginTop: '20px'}}>
                <div className="col-sm-3 form-wrap-70">
                  <CommonAutoSelect
                    id={`trailers[${index}]`}
                    label={`Select ${getCountryLabel('rego').toLowerCase()}`}
                    placeholder="Trailer (Optional)"
                    items={this[`getTrailer${index+1}Items`]()}
                    value={get(this.state, `selectedTrailers[${index}].id`)}
                    onChange={this.handleTrailerChange}
                    dataSourceConfig={{id: 'id', text: 'rego'}}
                  />
                </div>
                {
                  this.state[`createNewTrailer${index}`] &&
                  <div className="col-sm-3 form-wrap">
                    <CommonTextField
                      id={`trailers[${index}].rego`}
                      label={`Trailer ${index + 1} ${getCountryLabel('rego')}`}
                      placeholder="Please enter"
                      value={this.state.trailers[index].rego.value}
                      onChange={this.handleFieldChange}
                      onBlur={this.onBlur}
                      helperText={get(this.state, `trailers[${index}].rego.errors[0]`, '')}
                      onInput = {(e) =>{
                          e.target.value = e.target.value.toString().slice(0,10).replace(/[^0-9a-z]/gi, '');
                      }}
                      disabled={!this.props.canAccessAny || this.state.trailers[index].rego.isDisabled}
                    />
                  </div>
                }
                {
                  this.shouldShowTareWeightField(index) &&
                  <div className="col-sm-3 form-wrap">
                    <CommonTextField
                      id={`trailers[${index}].tare_weight`}
                      placeholder="Please enter"
                      label={`Trailer ${index + 1} Tare Weight`}
                      value={get(this.state, `trailers[${index}].tare_weight.value`, '')}
                      onChange={this.handleFieldChange}
                      onBlur={this.onBlur}
                      helperText={get(this.state, `trailers[${index}].tare_weight.errors[0]`, '')}
                      onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                      disabled={!this.props.canAccessAny || this.isTrailerSelectedFromExistingList(index)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)', marginRight: this.isTrailerSelectedFromExistingList(index) ? '25px' : '0px'}}>{selectedUnit}</InputAdornment>
                      }}
                    />
                  </div>
                }
                {
                  this.shouldShowGrossWeightField(index) &&
                  <div className="col-sm-3 form-wrap">
                    <CommonTextField
                      id={`trailers[${index}].gross_weight`}
                      placeholder="Please enter"
                      label={`Trailer ${index + 1} Gross Weight`}
                      value={get(this.state, `trailers[${index}].gross_weight.value`, '')}
                      onChange={this.handleFieldChange}
                      onBlur={this.onBlur}
                      helperText={get(this.state, `trailers[${index}].gross_weight.errors[0]`, '')}
                      onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                      disabled={!this.props.canAccessAny || this.isTrailerSelectedFromExistingList(index)}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)', marginRight: this.isTrailerSelectedFromExistingList(index) ? '25px' : '0px'}}>{selectedUnit}</InputAdornment>
                      }}
                    />
                  </div>
                }
                <IconButton
                  color='secondary'
                  size='small'
                  style={{width: '60px', height: '60px'}}
                  onClick={() => this.removeTrailer(index)}
                >
                  <ContentRemove />
                </IconButton>
              </div>
            ))
            }
            <div style={{marginTop: '15px'}}>
              <FormLabel>Select Truck Color</FormLabel>
              <div>
                <ColorPicker
                  width="100%"
                  height="100%"
                  color={this.state.truck.color.value}
                  handleChange={this.handleColorChange} />
              </div>
            </div>
            {this.props.canAccessAny ?
             <div className="col-sm-12 cardForm-action top15 padding-reset">
               <CommonButton
                 type="button"
                 label="Cancel"
                 variant="contained"
                 default
                 onClick={selectedTruck ? this.props.cancelEdit: this.cancelDrawer}
               />
               <CommonButton
                 type="submit"
                 variant="contained"
                 label="Save"
                 primary
               />
             </div>
             : null }
          </div>
        </form>
        {
          this.state.truckErrorDialog && (
            <Dialog
              open={this.state.truckErrorDialog}
              onClose={this.toggleTruckErrorDialog}
              id="complete-dialog-open"
              maxWidth="sm">
              <DialogTitleWithCloseIcon
                onClose={this.toggleTruckErrorDialog}
              >
              Truck belongs to below Company
              </DialogTitleWithCloseIcon>
              <DialogContent>
              <div className='row'>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  Company name
                </div>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  {get(this.state.existingTruck, 'companyName')}
                </div>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  Company entity
                </div>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  {get(this.state.existingTruck, 'companyEntityName')}
                </div>
              </div>
              <div className='row'>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  Company Type
                </div>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  {get(this.state.existingTruck, 'companyType')}
                </div>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  Company ABN
                </div>
                <div className='col-md-3' style={{margin: "5px 0px", fontSize: '15px'}}>
                  {get(this.state.existingTruck, 'companyAbn')}
                </div>
              </div>
              <div className="row">
                <div className='col-md-12' style={{marginTop: '30px'}}>
                    <span>
                      {AGRICHAIN_CONTACT_MESSAGE}
                    </span>
                  </div>
              </div>
              </DialogContent>
              <DialogActions>
                <Button type='button' onClick={this.toggleTruckErrorDialog} variant="contained" color="primary" >
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const isRegoAvailable = state.main.isRegoAvailable;
  return {
    isRegoAvailable,
    farms: state.companies.farms.items,
    currentUser: state.main.user.user,
    trailers: state.companies.companies.company.trucks.trailers,
    companyEmployees: state.companySettings.employees.items,
    companyTrucksWithFleet: state.companies.companies.company.trucks.items,
  };
};

const mapDispatchToProps = dispatch => ({
  getFarms: (companyId) => dispatch(getFarms(companyId)),
  validateRego: (key, value) => dispatch(validateRego(key, value, null)),
  resetValidRegoResponse: () => dispatch(receiveValidateRegoResponse(false))
});

function getFarms(selectedCompanyId) {
  return (dispatch, getState) => {
    const companyId = selectedCompanyId || getState().main.user.user.companyId;
    dispatch(getCompanyFarms(companyId, receiveFarms, true));
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TruckForm);

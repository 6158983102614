import React, {Component, Fragment} from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './acceptRejectOrder.scss';
import APIService from '../../services/APIService';
import CommonTextField from "../common/CommonTextField";
import CommonButton from "../common/CommonButton";
import {required} from "../../common/validators";

class RejectAmendedFreightContractForUnregisteredUser extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isRejected: false,
      freightContract: undefined,
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount(){
    const movementId = get(this.props, 'match.params.movement_id');
    const salt = get(this.props, 'match.params.salt');
    if(movementId && salt) {
      const freightContract = await APIService.freights().contracts(movementId).get(salt);
      const newState = {...this.state};
      newState.isLoading = false;
      if(freightContract && freightContract.amendable){
        newState.canReject = true;
        newState.freightContract = freightContract;
      } else {
        newState.canReject = false;
        newState.freightContract = undefined;
      }
      this.setState(newState);
    }
  }

  handleSubmit(event) {
    this.setErrors();
    if (this.getIsFormValid()) {
      const data = { rejectionReason: this.state.rejectionReason.value ,expireToken: true};
      const salt = this.props.match ? this.props.match.params.salt : null;
      APIService.freights().contracts(this.state.freightContract.id)['amend/reject']().put(data, salt)
        .then((json) => {
          if(!isEmpty(json.errors)){
            if(!isEmpty(json.errors['rejectionReason'])){
              this.setErrors(json.errors['rejectionReason']);
            } else {
              this.setErrors(json.errors);
            }
          } else {
            this.setState({ isRejected: true });
          }
        });
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return this.state.rejectionReason.errors.length == 0;
  }


  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            {this.state.isLoading ? <span>Loading ...</span> : <Fragment>
              {this.state.freightContract && this.state.canReject && !this.state.isRejected && <form onSubmit={this.handleSubmit} noValidate>
                <h4 className="reject-title">Reject Amend Request</h4>
                <CommonTextField
                  label="Reason"
                  placeholder="Please enter your reason for rejection"
                  id="rejectReason"
                  multiline
                  className="reject-reason-field"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  value={this.state.rejectionReason.value}
                  onChange={this.handleChange}
                  helperText={get(this.state, 'rejectionReason.errors[0]', '')}
                  errorStyle={{textAlign: "left"}}
                  fullWidth
                  maxLength="255"
                  rows={5}
                />
                <CommonButton
                  type="submit"
                  variant="contained"
                  label="REJECT"
                  primary
                />
              </form>}

              {this.state.isRejected && <span>The Freight Movement amend request has been rejected.</span>}
              {!this.state.canReject && <span>{get(this.state,'errors','You do not have permission to access the information.')}</span>}
            </Fragment>}
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>;
  }
}

export default RejectAmendedFreightContractForUnregisteredUser;

import React from 'react';
import { connect } from 'react-redux';

import { allocatedContractOrderWarning, createOrdersForAllocatedContract, getCallOnGrainOrders, getOrdersResponse } from '../../actions/companies/orders';
import AddButton from '../common/AddButton';
import { setHeaderText, setBreadcrumbs, setSubHeaderText, isSearchApplied } from '../../actions/main';
import { receiveOrder } from '../../actions/companies/orders';
import { has, get, includes, isEqual, isEmpty} from 'lodash';
import { getContractSubHeaderText, getOrderSubHeaderText, attachCSVEventListener, isDirectToBuyerAllocationEnabled, defaultViewAction } from '../../common/utils';
import CommonListingButton from '../common/CommonListingButton';
import Button from '@mui/material/Button/Button';
import APIService from '../../services/APIService';
import { receiveAllocation } from '../../actions/companies/orders';
import { Tooltip, Paper } from '@mui/material';
import DownloadDataDialog from '../common/DownloadDataDialog';
import {
  COMPANY_ADMIN, OFFICE_ADMIN, OBSERVER_TYPE_ID, FO_GO_FILTER_STATUSES,
  FO_GO_INVOICING, SYSTEM, getCallOnGrainOrdersGlobalListingHeaders, ORDERS_TABLE_COLUMN_LIMIT, DEFAULT_ORDERS_TABLE_COLUMN_LIMIT, PREDEFINED_DATE_RANGE_FILTER_KEYS, FILTER_KEYS_TO_EXCLUDE, ORDER_FILTER_KEYS_MAPPING
} from '../../common/constants';
import CallOnGrainOrdersTable from '../../containers/CallOnGrainOrdersTable';
import { setDownloadBar } from '../../actions/main';
import Filters from '../common/Filters';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import { isLoading, forceStopLoader } from '../../actions/main';
import { isAtGlobalOrders } from '../../common/utils';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import FiltersAppliedChip from '../common/FiltersAppliedChip';
import alertifyjs from 'alertifyjs';
import AllocatedContractWarningDialog from './AllocatedContractWarningDialog';

class CallOnGrainOrders extends React.Component {
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      isFetchingCallOnGrainOrders: false,
      csvData: [],
      openCogForm: false,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      filter_statuses: FO_GO_FILTER_STATUSES,
      invoicing: FO_GO_INVOICING,
      filterValues: {
        commodity__id__in: [],
        planned_grade__id__in: [],
        status__in: [],
        commodity_contract__seller__company__id__in: [],
        commodity_contract__buyer__company__id__in: [],
        delivery_start_date_range: '',
        delivery_end_date_range: '',
        freight_pickup__date_time__gte: '',
        freight_delivery__date_time__lte: '',
        season__in: [],
        freight_pickup__consignor__handler__id__in: [],
        freight_delivery__consignee__handler__id__in: [],
        updated_at_date_range: '',
        updated_at__lte: '',
        updated_at__gte: '',
      },
      customTableColumnOptions: false,
      customTableColumnNames: {},
      customColumnTitle: undefined,
      isFilteredCsv: false,
      csvPopup: false,
      searchView: false,
    };
    this.handleAddCOGOrderButtonClick = this.handleAddCOGOrderButtonClick.bind(this);
    this.handleCloseCOGOrderForm = this.handleCloseCOGOrderForm.bind(this);
    this.handleAddOrderButtonClick = this.handleAddOrderButtonClick.bind(this);
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters' && isAtGlobalOrders()) {
        const { filters } = this.state;
        APIService.profiles()
                  .filters()
                  .post({ cog_order: filters }, this.props.token)
                  .then(res => {
                    this.props.isLoading();
                    this.setState({filters: res?.filters?.cog_order || {}}, () => {
                      this.props.onGetCallOnGrainOrders(null, null);
                    })
                  });
      }
    });
  };

  _attachCSVEventListener() {
    attachCSVEventListener('freight-orders-csv-ready', 'Orders', this.onDownloadResponse);
  }

  componentWillUnmount() {
    this.props.unMountOrders();
    this.props.applySearch(null);
    if(window.location.hash.includes('?') && isAtGlobalOrders())
      window.location.hash = window.location.hash.split('?')[0]

  }

  componentDidMount() {
    this._attachCSVEventListener();
    this.setHeaderAndBreadcrumbs();
    this.props.applySearch(null);
    const queryParams = new URLSearchParams(this.props.location.search);

    this.commodityContractId = queryParams.get('commodity_contract_id');
    this.parentOrderId = queryParams.get('parent_order_id') || this.props.parentOrderId;
    if (!this.parentOrderId) {
      this.props.unsetSelectedOrder();
    }
    if (!this.commodityContractId && !this.parentOrderId) {
      this.props.unsetSelectedFreightAllocation();
    }
    if (!this.state.isFetchingCallOnGrainOrders) {
      if (this.props.match.params && has(this.props.match.params, 'contract_id')) {
        this.commodityContractId = this.props.match.params.contract_id;
        this.setState({ isFetchingCallOnGrainOrders: true }, () => {
          this.props.onGetCallOnGrainOrders(null, this.commodityContractId);
        });
      } else {
        this.setState({ isFetchingCallOnGrainOrders: true }, () => {
          this.props.onGetCallOnGrainOrders(this.parentOrderId, this.commodityContractId);
        });
      }
    }
    APIService.profiles()
              .filters('cog_order')
              .get(this.props.token)
              .then(res => {
                this.setState({
                  filters: get(res, 'cog_order', {}),
                });
              });
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'count') !== this.props.count)
      this.setHeaderAndBreadcrumbs();
    const queryParams = new URLSearchParams(this.props.location.search);
    if ((isEmpty(this.props.orders) && !this.state.isFetchingCallOnGrainOrders) || prevProps.selectedUnit !== this.props.selectedUnit) {
      if (this.props.match.params && has(this.props.match.params, 'contract_id')) {
        this.commodityContractId = this.props.match.params.contract_id;
        this.setState({ isFetchingCallOnGrainOrders: true }, () => {
          this.props.onGetCallOnGrainOrders(null, this.commodityContractId, this.props.selectedUnit);
        });
      } else if (this.props.parentOrderId !== prevProps.parentOrderId || this.commodityContractId != queryParams.get('commodity_contract_id')) {
        this.parentOrderId = this.props.parentOrderId;
        this.commodityContractId = queryParams.get('commodity_contract_id');
        this.setState({ isFetchingCallOnGrainOrders: true }, () => {
          this.props.onGetCallOnGrainOrders(this.parentOrderId, this.commodityContractId, this.props.selectedUnit);
        });
      }
    }
  }

  setHeaderAndBreadcrumbs() {
    const countLabel = ` (${this.props.count})`;
    let breadcrumbs = [{ text: 'Call On Grain Orders' + countLabel }];

    let headerText = 'Call On Grain Orders';
    if (this.props.contractId || this.props.contract) {
      breadcrumbs = [
        { text: 'Contracts', route: '/contracts' },
        { text: get(this.props.contract, 'referenceNumber', ''), route: '/contracts/' + this.props.contractId + '/contract' },
        { text: 'Orders' + countLabel },
      ];
      headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
      this.props.setSubHeaderText(getContractSubHeaderText(this.props.contract));
    } else if (this.props.farmId) {
      let farmRoute = '/stocks/storages-view?farmId=' + this.props.farmId;
      breadcrumbs = [{ text: 'Farms', route: '/farms' }, { text: this.props.farmName, route: farmRoute }, { text: 'Orders' + countLabel }];
      headerText = this.props.farmName;
    } else if (this.props.parentOrderId) {
      breadcrumbs = [
        { text: 'Orders', route: '/orders/grain' },
        { text: get(this.props.order, 'identifier', ''), route: '/freights/orders/' + this.props.parentOrderId + '/order' },
        { text: 'Allocations' + countLabel },
      ];
      headerText = 'Freight Order ' + get(this.props.order, 'identifier', '');
      this.props.setSubHeaderText(getOrderSubHeaderText(this.props.order));
    }
    this.props.setHeaderText(headerText);
    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  handleAddOrderButtonClick() {
    const func = this.props.handleAddOrderButtonClick || this.props.onHandleAddOrderButtonClick;
    if (func) {
      func();
    } else {
      window.location = '/#/freights/grain/new';
    }
  }

  newOrderButton = () => {
    const func = this.props.handleAddCOGButtonClick;
    if (func) func();
    this.setState({ openCogForm: true });
  }
  handleAddCOGOrderButtonClick() {
    if (this.commodityContractId && this.props.contract?.canCreateCallOnGrainOrder && isDirectToBuyerAllocationEnabled()){
      const isSaleContract = get(this.props.contract, 'isSaleContract', false);
      const isSeller = this.props.contract.isSeller
      const isBuyer = this.props.contract.isBuyer
      const path = (isSeller && isSaleContract) ? '/purchase-allocations/' : (isBuyer && !isSeller) ? '/sale-allocations/' : '/purchase-allocations/';
      this.props.createOrder(this.commodityContractId, path, ()=> {
        if (!this.props.orderWarningFlag){
          this.newOrderButton();
        }
      });
    }
    else{
      this.newOrderButton();
    }
  }

  handleCloseCOGOrderForm() {
    this.setState({ openCogForm: false });
  }

  fetchCSVData = () => {
    const { setDownloadBar } = this.props;
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    setDownloadBar('Your Orders CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    if (this.state.searchView && this.props.isSearchApplied)
      param+= param.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    const service = APIService.freights().orders();
    service.appendToUrl(`csv/?${param}`);
    if(this.commodityContractId)
      service.appendToUrl(`&commodity_contract_id=${this.commodityContractId}`);
    this.setState({csvPopup: false, searchView: false})
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => {
        this.setState({ csvData: csvData || [] });
      });
  };

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, OBSERVER_TYPE_ID, SYSTEM], get(this.props.currentUser, 'typeId'));
  }

  getActionsOptionMapperListItems() {
    return [
      { name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns() },
      defaultViewAction
    ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/call_on_grain_order_table/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const freightOrderColumns = getCallOnGrainOrdersGlobalListingHeaders(false);
    return freightOrderColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(ORDER_FILTER_KEYS_MAPPING, key)) : value.length !== 0;

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.isSearchApplied) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else
      this.setState(newState, this.fetchCSVData);
  }

  render() {
    const { contract } = this.props;
    return (
      <Paper className='paper-table'>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
            {this.canExportCSV() && (
               <CommonListingButton
                 defaultHandler={() => this.customCsvEnabled(false)}
                 showMenus={!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0)) && isAtGlobalOrders()}
                 optionMapper={[
                   { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
                   { name: 'Filtered List', fx: () => this.customCsvEnabled(true) },
                 ]}
                 title='Download Contents of the table in a CSV'
                 name='Export'
               />
            )}
            <DownloadDataDialog
              open={this.state.csvPopup}
              onClose={() => this.setState({csvPopup: false, searchView: false})}
              title='Download Call On Grains Data'
              isSearchApplied={this.props.isSearchApplied}
              searchView={this.state.searchView}
              onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
              isFilteredCsv={this.state.isFilteredCsv}
              onDownload={this.fetchCSVData}
            />
            {isAtGlobalOrders() && (
               <Tooltip title='Apply filters' placement='top'>
                 <Button
                   value={this.state.applyFilters}
                   variant="contained"
                   type='button'
                   onClick={() => this.handleFilters(true)}
                   color='primary'
                   className='add-button'
                   style={{ float: 'right', marginLeft: '10px' }}
                 >
                   <FilterListIcon style={{ paddingRight: '5px' }} />
                   FILTERS{' '}
                   {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                    ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                    : ''}
                 </Button>
               </Tooltip>
            )}
            {this.state.applyFilters && (
               <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                 <Filters
                   isLoading={this.props.isLoading}
                   forceStopLoader={this.props.forceStopLoader}
                   handleFilterState={this.handleFilterState}
                   filters={this.state.filters}
                   statusTemp={this.state.filter_statuses}
                   invoicingTemp={this.state.invoicing}
                   filterValues={this.state.filterValues}
                 />
               </SideDrawer>
            )}
            { isAtGlobalOrders() &&
              <AddButton
                label='Call On Grain Order'
                onClick={this.handleAddOrderButtonClick}
                app='order' style={{ marginTop: '0px' }} />
            }
            {contract && (
               <AddButton
                 label='Order'
                 onClick={this.handleAddCOGOrderButtonClick}
                 app='order'
                 style={{ marginRight: '10px' }}
                 tooltipTitle='Create Call On Grain Order'
                 tooltipPlacement='top'
               />
            )}
            <div style={{float: 'right', marginRight: '10px'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>
            <SideDrawer
              isOpen={this.state.customTableColumnOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customTableColumnOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customTableColumnNames}
                closeDrawer={() => this.setState({customTableColumnOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                table_type="call_on_grain_order_table"
                columnsMapping={this.getColumnsMapping()}
                maxColumnLimit={ORDERS_TABLE_COLUMN_LIMIT}
                updateColumnCount={(count) => this.updateColumnCount(count)}
                defaultColumnLimit={DEFAULT_ORDERS_TABLE_COLUMN_LIMIT}
              />
            </SideDrawer>
            {this.props.orderWarningFlag && isDirectToBuyerAllocationEnabled() && (
            <AllocatedContractWarningDialog {...this.props} newOrderButton={this.newOrderButton} handleToUpdate={() => this.props.closeWarningDialog(false, [])} isCog />
            )}
          </div>
          <FiltersAppliedChip filters={this.state.filters} show={isAtGlobalOrders()} style={{paddingRight: '50%'}} />
          <CallOnGrainOrdersTable dontRedirect={this.props.dontRedirect} />
        </div>
      </Paper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  unMountOrders: () => dispatch(getOrdersResponse([])),
  onGetCallOnGrainOrders: (parentOrderId, commodityContractId, unit) => dispatch(getCallOnGrainOrders(parentOrderId, commodityContractId, undefined, false, unit)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  setSubHeaderText: text => dispatch(setSubHeaderText(text)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  unsetSelectedOrder: () => dispatch(receiveOrder(null)),
  unsetSelectedFreightAllocation: () => dispatch(receiveAllocation(null)),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  closeWarningDialog: (value, listData) => dispatch(allocatedContractOrderWarning(value, listData)),
  createOrder: (contractId, path, callback) => dispatch(createOrdersForAllocatedContract(contractId, path, callback)),
  applySearch: searchStr => dispatch(isSearchApplied(searchStr)),
});

const mapStateToProps = state => {
  return {
    orders: state.companies.orders.cogOrders,
    token: state.main.user.token,
    currentUser: state.main.user.user,
    selectedFarm: state.companies.farms.selectedFarm,
    canCreateCOGForContractId: parseInt(state.companies.contracts.canCreateCOGForContractId),
    count: get(state.companies.orders, 'paginationData.count') || 0,
    orderWarningList: get(state.companies.orders, 'orderWarningList'),
    orderWarningFlag: get(state.companies.orders, 'orderWarningFlag'),
    isSearchApplied: state.main.isSearchApplied,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallOnGrainOrders);

import React from 'react';

import map from 'lodash/map';

const TonnageDetail = props => (
  <React.Fragment>
    {map(props.items, (item, i) => (
      <span key={i} className={props.className} style={{ padding: '1px 0px', minHeight: '17px' }}>
        <span className='col-sm-6 field-label' style={{ fontSize: '0.85rem', paddingLeft: 0, paddingRight: 4 }}>
          {item.label}
        </span>
        <span className='col-sm-6 field-value' style={{ fontSize: '0.90rem', fontWeight: 400, padding: 0, textAlign: 'end' }}>
          {`${item.value} ${item.unit || props.unit || 'MT'}`}
        </span>
      </span>
    ))}
  </React.Fragment>
);

TonnageDetail.defaultProps = {
  className: 'row col-sm-12',
};

export default TonnageDetail;

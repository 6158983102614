import React, { Component } from 'react';
import { connect } from 'react-redux';

import forEach from 'lodash/forEach';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import { required } from '../../common/validators';
import CommonSelect from '../common/select/CommonSelect';
import {TYPES_WITHOUT_INVOICE} from './fixtures';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import '../../common/forms.scss';
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

class SpecialConditionForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: {
          value: '',
          validators: [required()],
          errors: [],
        },
        details: {
          value: '',
          validators: [required()],
          errors: [],
        },
        module: {
          value: "",
          validators: [required()],
          errors: [],
        },
        default: {
          value: false,
          validators: [],
          errors: [],
        }
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.handleDefaultFieldChange = this.handleDefaultFieldChange.bind(this);
    this.formSubmitted = false;

  }

  componentDidMount() {
    this.setAllFieldsValuesBySelectedCondition();
    if (this.props.selectedConditionType !== undefined) {
      this.setFieldValue('module', this.props.selectedConditionType);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedSpecialCondition) {
      if (this.props.selectedSpecialCondition.id !== prevProps.selectedSpecialCondition.id) {
        this.setAllFieldsValuesBySelectedCondition();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      newState.fields[key].errors = value;
    });
    if(this.formSubmitted && Object.keys(props.serverErrors).length === 0){
      this.formSubmitted = false;
      this.props.closeDrawer();
    }
    this.setState(newState);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }
  handleSelectFieldChange(value, id) {
    this.setFieldValue(id, value);
    this.setFieldErrors(id);
  }
  handleDefaultFieldChange(event){
    this.setFieldValue(event.target.id, event.target.checked);
  }
  setAllFieldsValuesBySelectedCondition() {
    if (this.props.selectedSpecialCondition) {
      const newState = { ...this.state };
      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedSpecialCondition[key];
      });

      this.setState(newState);
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };

    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });

    this.setState(newState);
  }

  handleSubmit(event) {
    this.setAllFieldsErrors();

    const isFormInvalid = some(this.state.fields, (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
    if (!isFormInvalid) {
      const data = mapValues(this.state.fields, (field) => {
        return field.value;
      });
      if(this.props.companyId)
        data.companyId = this.props.companyId

      const { onSuccess } = this.props;
      this.props.submit(data, onSuccess);
      this.formSubmitted = true;
    }

    event.preventDefault();
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState);
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  cancelDrawer(){
      this.props.closeDrawer();
  }

  render() {
    return (
      <div ref={this.formRef}>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonSelect
                    id="module"
                    onChange={this.handleSelectFieldChange}
                    floatingLabelText="Condition Type"
                    errorText={this.state.fields.module.errors[0]}
                    items={TYPES_WITHOUT_INVOICE}
                    disabled={this.props.selectedConditionType !== undefined}
                    value={this.state.fields.module.value}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                    id="name"
                    label="Name"
                    helperText= {this.state.fields.name.errors[0]}
                    onChange={this.handleFieldChange}
                    onBlur={this.onFieldBlur}
                    value={this.state.fields.name.value}
                    maxLength="100" />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <div className="field-label">Details</div>
                <CommonTextField
                    className="text-area"
                    id="details"
                    label=""
                    value={this.state.fields.details.value}
                    helperText= {this.state.fields.details.errors[0]}
                    onChange={this.handleFieldChange}
                    onBlur={this.onFieldBlur}
                    multiline={true}
                    rows={15}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                  <FormControlLabel
                  control={<Checkbox
                    id="default"
                    checked={this.state.fields.default.value}
                    onChange={this.handleDefaultFieldChange}
                  />}
                  label="Set as Default"
                />
              </div>
              <div className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                    type="button"
                    variant="contained"
                    label="Cancel"
                    default
                    onClick={this.props.selectedSpecialCondition ? this.props.cancelEdit : this.cancelDrawer }
                />
                <CommonButton variant="contained" primary={true} label='Save' type="submit" />
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    serverErrors: state.companies.conditions.serverErrors || [],
  };
};

export default connect(mapStateToProps)(SpecialConditionForm);

import React from 'react';
import IconButton from '@mui/material/IconButton';
import { Cancel as RemoveIcon } from '@mui/icons-material'
import { filter, find } from 'lodash'
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import CurrencyField from '../common/CurrencyField'
import { positiveDecimalFilter } from '../../common/input-filters';

const ChemicalApplicationForm = ({ commodities, position, application, onChange, currency, unit, disabled, onDelete, style }) => {
  const [commodity, setCommodity] = React.useState(application?.commodity || null)
  const [commodityId, setCommodityId] = React.useState(application?.commodityId || '')
  const [price, setPrice] = React.useState(application?.price || 0)
  const [fee, setFee] = React.useState(application?.fee || 0)

  const onCommodityChange = itemId => {
    setCommodityId(itemId || '')
    setCommodity(itemId ? find(commodities, {id: itemId}) : null)
  }

  React.useEffect(() => {
    onChange({commodityId: commodityId, fee: fee, price: price, position: position})
  }, [commodityId, price, fee])

  return (
    <div className='col-xs-12 padding-reset' style={style}>
      <div className='col-xs-4'>
        <CommodityAutoComplete
          id='commodity'
          commodityId={commodityId}
          selected={commodity}
          onChange={onCommodityChange}
          label='Application Commodity (Optional)'
          disabled={disabled}
          itemFilterFunc={commodities => filter(commodities, {type: 'chemical'})}
        />
      </div>
      <div className='col-xs-3'>
        <CurrencyField
          id='price'
          label={`Price per ${commodity?.unit || 'unit'} (Optional)`}
          value={price}
          fullWidth
          disabled={disabled}
          onChange={event => setPrice(event.target.value || 0)}
          onKeyDown={event => positiveDecimalFilter(event, 2, 9999999.99)}
          variant="standard"
          selectedCurrency={currency}
          disabledCurrency
          type='number'
        />
      </div>
      <div className='col-xs-3'>
        <CurrencyField
          id='fee'
          label={`Application fee per ${unit} (Optional)`}
          value={fee}
          fullWidth
          disabled={disabled}
          onChange={event => setFee(event.target.value || 0)}
          onKeyDown={event => positiveDecimalFilter(event, 2, 9999999.99)}
          variant="standard"
          selectedCurrency={currency}
          disabledCurrency
          type='number'
        />
      </div>
      <div className='col-xs-1'>
        <IconButton onClick={() => onDelete(position)} color='error'>
          <RemoveIcon fontSize='inherit' />
        </IconButton>
        </div>
    </div>
  )
}

export default ChemicalApplicationForm;

import React from 'react';

import { connect } from 'react-redux';
import MovementDetails from './MovementDetails';
import {
  getSelectedFreight,
  receiveFreight,
} from '../../../actions/companies/freights';
import isEmpty from 'lodash/isEmpty';
import { isLoading, loaded } from '../../../actions/main';

class MovementDetailsContainer extends React.Component {
  static defaultProps = {
    contract_id: null,
  };

  componentDidMount() {
    this.props.waitForLoader('movementDetail');
    if (this.shouldFetchMovement()) {
      const { movement_id } = this.props.match.params;
      this.props.getSelectedFreight(movement_id, receiveFreight, true, false, false, true, false);
    }
    if(this.props.breadcrumbsFunc) {
      this.props.breadcrumbsFunc();
    }
  }

  componentWillUnmount() {
    this.props.receiveFreight(null);
  }

  componentDidUpdate(){
    this.props.loaded();
    const {movement_id} = this.props.match.params;
    if(this.props.movement && this.props.movement.id != movement_id){
      this.props.getSelectedFreight(movement_id, receiveFreight, true, false, false, true, false);
    }
  }

  shouldFetchMovement() {
    return isEmpty(this.props.movement);
  }

  render(){
    return <MovementDetails {...this.props}/>;
  }
}

const mapStateToProps = state => {
  return {
     movement: state.companies.freights.selectedFreight || null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSelectedFreight: (id, actionCreator, redirectPage, inActivatedUserToken, isEditingMode, setHeaderAndBreadcrumbs, _forceStopLoader) =>
      dispatch(
        getSelectedFreight(id, actionCreator, redirectPage, inActivatedUserToken, isEditingMode, setHeaderAndBreadcrumbs, _forceStopLoader),
      ),
    waitForLoader: () => dispatch(isLoading('movementDetail')),
    loaded: () => dispatch(loaded()),
    receiveFreight: (item) => dispatch(receiveFreight(item))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MovementDetailsContainer);

import React from 'react';

import {Autocomplete, TextField, CircularProgress} from '@mui/material';
import {get, debounce, last, isArray} from 'lodash';
import APIService from '../../../services/APIService';

const TruckAsyncAutocomplete = ({id, minLength, selected, onChange, size, variant, disabled, label, multiple, limitTags, excludeFleet}) =>  {
  const [input, setInput] = React.useState('')
  const [items, setItems] = React.useState([])
  const [isLoading, setIsLoading] = React.useState(false)
  const isSearchable = input.length >= minLength;

  const _onChange = (event, items) => {
    const item = isArray(items) ? last(items) : items
    const text = get(item, 'rego', '');
    if(!item || (item.id !== get(selected, 'id'))) {
      setInput(text)
      setIsLoading(item ? isLoading : false)
      onChange(multiple ? items : item, id)
    }
  }
  const _onInputChange = debounce((event, value) => {
    setInput(value)
    setIsLoading(Boolean(value))
    if(value && value.length >= minLength)
      fetchItems(value)
  }, 300);

  const fetchItems = searchStr => {
    if(searchStr) {
      APIService
        .trucks()
        .get(null, null, {search: searchStr, exclude_fleet: excludeFleet})
        .then(res => {
          setItems(res || [])
          setIsLoading(false)
        });
    }
  };

  return (
    <Autocomplete
      limitTags={limitTags || -1}
      multiple={Boolean(multiple)}
      id={id || "rego"}
      size={size || 'medium'}
      blurOnSelect
      options={items}
      getOptionLabel={option => option.rego}
      loading={isLoading}
      loadingText={isSearchable ? 'Loading...' : `Type at least ${minLength} characters to search`}
      noOptionsText={input ? "No results" : 'Start typing...'}
      renderInput={params => (
        <TextField
          {...params}
          value={input}
          variant={variant || 'outlined'}
          placeholder={`${label} (Type at least ${minLength} characters to search)`}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          fullWidth
        />
      )}
      value={selected}
      onChange={_onChange}
      onInputChange={_onInputChange}
      disabled={disabled}
      disableCloseOnSelect
    />

  )
}

export default TruckAsyncAutocomplete;

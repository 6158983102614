import {END_OF_DELIVERY, PRE_DELIVERY, POST_DELIVERY} from '../../common/constants';

export const DELIVERY_ONUS = [
  {id: 'Seller', name: 'Seller'},
  {id: 'Buyer', name: 'Buyer'}
];
export const FREQUENCIES = [
  {id: 'Monthly', name: 'Monthly'},
  {id: 'Fortnightly', name: 'Fortnightly'},
  {id: 'Weekly', name: 'Weekly'},
];
export const INVOICINGS = [
  {id: 'Seller to Invoice Buyer', name: 'Seller to Invoice Buyer'},
  {id: 'Broker to Invoice Buyer', name: 'Broker to Invoice Buyer'},
  {id: 'Buyer RCTI', name: 'Buyer RCTI'},
];

export const NEW_INVOICINGS = [
  {id: 'Seller to Invoice Buyer', name: 'Seller to Invoice Buyer'},
  {id: 'Buyer RCTI', name: 'Buyer RCTI'},
];

export const PAYABLE_BY = DELIVERY_ONUS.concat([
  {id: 'Seller And Buyer', name: 'Seller And Buyer'},
  {id: 'Nil', name: 'Nil'},
]);
export const END_OF_DELIVERY_CHARGED_AT = {id: END_OF_DELIVERY, name: END_OF_DELIVERY};
export const CHARGED_AT = [
  {id: PRE_DELIVERY, name: PRE_DELIVERY},
  {id: POST_DELIVERY, name: POST_DELIVERY},
];
export const BROKERAGE_FEE_TYPES = [
  {id: 'Fee Per MT', name: 'Fee Per MT'},
  {id: 'Flat Fee', name: 'Flat Fee'},
  {id: '% of Sale', name: '% of Sale'},
];
export const VOLUME_BROKERAGE_FEE_TYPES = [
  {id: 'Fee Per MT', name: 'Fee Per unit'},
  {id: 'Flat Fee', name: 'Flat Fee'},
  {id: '% of Sale', name: '% of Sale'},
];
export const BROKERAGE_FREQUENCIES = [
  {id: 'Weekly', name: 'Weekly'},
  {id: 'Fortnightly', name: 'Fortnightly'},
  {id: 'Monthly', name: 'Monthly'},
  {id: 'Quarterly', name: 'Quarterly'},
  {id: 'Semi-Annually', name: 'Semi-Annually'},
  {id: 'Annually', name: 'Annually'},
];
export const CHARGED_AT_PERIODS = [
  {id: 'Start of the Period', name: 'Start of the Period'},
  {id: 'End of the Period', name: 'End of the Period'},
];

import React from 'react';

import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import MyProfileDetails from './MyProfileDetails';
import ChangePassword from './ChangePassword';
import CommonTabs from '../common/CommonTabs';
import {setHeaderText, setBreadcrumbs} from '../../actions/main';
import '../common/commonTabs.scss';

class MyProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: props.location.pathname,
    };

    this.handleTabChange = this.handleTabChange.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(setHeaderText(this.props.user.name));
    this.props.dispatch(setBreadcrumbs([]));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
      { ...prevState, activeTab: nextProps.location.pathname } :
      prevState;
  }

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  render() {
    const PARENT_URL = this.props.match.url;
    let TABS = [
      {
        label: 'My Profile',
        url: `${PARENT_URL}/details`,
        component: () => <MyProfileDetails />
      },
      {
        label: 'Change Password',
        url: `${PARENT_URL}/change-password`,
        component: () => <ChangePassword />
      },
    ];
    if(!this.props.user.isDefaultAuthEnabled)
      TABS.pop();

      return (
        <div className="tab">
          <div className="tab-header">
            <CommonTabs
              value={this.props.location.pathname}
              tabs={TABS}
            />
          </div>
          <div key={0} className="tab-content">
            {
              TABS.map(tab => {
                return (
                  <Route
                    key={tab.url}
                    exact
                    path={tab.url}
                    component={tab.component}
                  />
                );
              })
            }
          </div>
        </div>
      );
  }
}
const mapStateToProps = state => {
  return {
    user: state.main.user.user
  };
};

export default connect(mapStateToProps, null)(MyProfile);

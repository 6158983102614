import React from 'react';

import { connect } from 'react-redux';
import { Paper } from '@mui/material';
import {setHeaderText, setBreadcrumbs, forceStopLoader, isLoading} from '../../actions/main';
import get from 'lodash/get';
import find from 'lodash/find';
import './icons.css';
import { getCompaniesResponse } from '../../actions/companies';
import SiteManagement from './SiteManagement';
import {receiveFarms} from '../../actions/companies/farms';
import {
  receiveCompanySites,
} from '../../actions/companies/company-sites';
import APIService from '../../services/APIService';
import CompanyAutocomplete from '../common/autocomplete/CompanyAutocomplete';

class SiteBooking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trucks: [],
      drivers: [],
      selectedCompany: null,
    };
    this.slotId = null;
    this.siteId = null;
    this.companyId = null;
    this.hasSetURLBasedAttrs = false;
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    this.siteId = query.get('siteId');
    this.companyId = query.get('companyId');
    this.startDate = query.get('startDate');
    this.endDate = query.get('endDate');
    this.fetchCurrentUserTrucksAndDrivers();
    this.getCompanyDirectory();
    this.props.setHeaderText('Site Bookings');
    this.props.setBreadcrumbs([{text: 'Site Bookings'}]);
  }

  fetchCurrentUserTrucksAndDrivers() {
    const { currentUserCompanyId, token } = this.props;
    APIService.companies(currentUserCompanyId).trucks().get(token).then(trucks => {
      this.setState({trucks: trucks});
    });
    APIService.companies(currentUserCompanyId).employees().appendToUrl('minimal/').get(token).then(drivers => {
      this.setState({drivers: drivers});
    });
  }

  getCompanyDirectory() {
    const { token } = this.props;
    APIService.companies().appendToUrl('directory/names/?excludeGroups=true').get(token).then(companies => {
      this.setState({companyDirectory: companies});
    });
  }

  componentDidUpdate() {
    const query = new URLSearchParams(this.props.location.search);
    const siteId = query.get('siteId');
    const companyId = query.get('companyId');
    if((siteId && this.siteId !== siteId) || (companyId && this.companyId !== companyId)) {
      window.location.reload();
    }
    if(this.companyId && this.state.companyDirectory && !this.hasSetURLBasedAttrs) {
      let selectedCompany = find(this.state.companyDirectory, {id: parseInt(this.companyId)});
      if (!selectedCompany && parseInt(this.companyId) === this.props.currentUserCompanyId) {
        selectedCompany = this.props.selectedCompany;
      }
      if(selectedCompany) {
        this.handleCompanyChange(selectedCompany);
        this.hasSetURLBasedAttrs = true;
      }
    }
  }

  handleCompanyChange(item, id) { // eslint-disable-line no-unused-vars
    const { selectedCompany } = this.state;
    if(!item || (item.id !== get(selectedCompany, 'id')))
      this.setState({
        selectedCompany: null // setting null first so that SiteManagement can be unmounted and then remounted to load sites of selected company.
      }, () => {
        this.setState({selectedCompany: item}, () => {
          this.props.resetCompanySites();
          this.props.resetFarms();
        })
      });
  }

  componentWillUnmount() {
    this.props.resetCompanySites();
    this.props.resetFarms();
    this.props.resetCompanies();
  }

  render() {
    return (
      <div>
        <Paper style={{boxShadow: 'none'}}>
          <CompanyAutocomplete
            selected={get(this.state, 'selectedCompany')}
            onChange={this.handleCompanyChange}
            minLength={3}
            getRegisteredCompanies={true}
            variant='outlined'
            disabled={false}
            label='Company'
          />
          {
            this.state.selectedCompany &&
            <SiteManagement
              startDate={this.startDate}
              endDate={this.endDate}
              selectedSlotId={parseInt(this.slotId)}
              selectedSiteId={parseInt(this.siteId)}
              company={this.state.selectedCompany}
              readOnly
              siteBooking
              dontSetBreadcrumbs
              rightDivStyles={{top: '160px'}}
              bookieTrucks={this.state.trucks}
              bookieDrivers={this.state.drivers}
              companyDirectory={this.state.companyDirectory}
              location={this.props.location}
            />
          }
        </Paper>
      </div>
    );
  }

}
const mapStateToProps = (state) => {
  return {
    token: state.main.user.token,
    companies: state.companies.companies.items,
    currentUserCompanyId: state.main.user.user.companyId,
    selectedCompany: state.main.user.user.company,
    sites: state.companies.companySites.items,
    slots: state.companies.companySites.slots,
    waitForComponent: state.main.waitForComponent,
    isLoading: state.main.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    resetCompanySites: () => dispatch(receiveCompanySites([])),
    resetFarms: () => dispatch(receiveFarms([])),
    resetCompanies: () => dispatch(getCompaniesResponse([])),
    forceStopLoader: () => dispatch(forceStopLoader()),
    companiesResponse: items => dispatch(getCompaniesResponse(items)),
    loadLoader: component => dispatch(isLoading(component)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SiteBooking);

import React from 'react';

import Paper from '@mui/material/Paper';
import Help from '@mui/icons-material/Help';
import {
  convertEpochToDateFormat,
} from '../../../common/momentUtilities';

import Button from '@mui/material/Button';
import { DIRECT_TO_BUYER_ALLOCATION, PRIMARY_COLOR_GREEN, THROUGH_WAREHOUSE_ALLOCATION } from '../../../common/constants';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import MovementDetailsActions from "./MovementDetailsActions";
import { ChipLabel } from '../../common/ChipLabel';
import { get, includes, isEmpty, some } from 'lodash';
import { AppContext } from '../../main/LayoutContext';
import InvoiceChipMenu from '../../common/InvoiceChip';

const renderBasedOnStatus = status => {
  switch (status){
    case 'confirmed':
      return (
        <img src="images/confirmed.svg"></img>
      );
    case 'delivered':
      return (
        <img src="images/delivered.svg"></img>
      );
    case'open':
      return (
        <img src="images/open.svg"></img>
      );
    case 'in_progress':
      return (
        <img src="images/in-progress.svg"></img>
      );
    case 'void':
      return (
        <img src="images/voided.svg"></img>
      );
    case 'completed':
    case 'manual_contract_complete_balanced':
      return (
        <img src="images/completed.svg"></img>
      );
    case 'rejected':
      return (
        <img src="images/rejected.svg"></img>
      );
    case 'load_rejected':
      return (
        <img src="images/load-rejected.svg"></img>
      );
    case 'invoiced':
      return (
        <img src="images/invoiced.svg"></img>
      );
    case 'draft':
      return (
        <img src="images/draft.svg"></img>
      );
  }
  return <Help style={{ fill: '#eee200', height: '40px', width: '40px' }} />;
};

export const MovementDetailsBasicInfo = props => {
  const { isMobileDevice } = React.useContext(AppContext);
  const { movement } = props;
  const contractUrl = movement?.buyerLinkedDeliveryOrder ? `#/contracts/${movement.buyerLinkedDeliveryOrder?.commodityContractId}/contract` : movement.commodityContractId ? `#/contracts/${movement.commodityContractId}/contract` : '';
  const contractSectionClass ='contract-details-status-section grid-7';
  const linkedOrderContractClickable = movement => get(movement, 'buyerLinkedDeliveryOrder.canUserViewContract')
  const contractClickable = movement => get(movement, 'commodityContract.canUserView')
  const order = movement.buyerLinkedDeliveryOrder?.id ? movement.buyerLinkedDeliveryOrder : movement.order
  const isOrderClickable = order?.viewerCompanyIds?.includes(props.currentUser.companyId)
  const labelPrefix = movement?.isPackMovement ? 'Pack ' : ''
  const showAllocationInvoicing = includes(get(props, 'currentUser.company.contractAllocations'), DIRECT_TO_BUYER_ALLOCATION) || includes(get(props, 'currentUser.company.contractAllocations'), THROUGH_WAREHOUSE_ALLOCATION)
  const invoicingHrefs = movement.invoiceHrefs
  const showInvoicing = some(invoicingHrefs, data => !isEmpty(data))
  const invoicingTypes = {
    freight: 'Freight Invoiced',
    commodityContract: 'Commodity Contract Invoiced',
    saleContract: 'Sale Contract Invoiced',
    purchaseContract: 'Purchase Contract Invoiced',
    warehouse: 'Warehouse Invoiced',
  };

  return (
    <Paper id='movement-details-basic-info-card'>
      <div className={contractSectionClass}>
        {
          props.backButtonHandler &&
          <Button title="Freight Movements List" onClick={props.backButtonHandler} style={{color: PRIMARY_COLOR_GREEN, maxWidth: '60px', borderRadius: '0px', borderRight: '1px solid rgb(224,224,224)'}}>
            <ArrowBackIcon/>
          </Button>
        }
        <div className="item item1" style={props.backButtonHandler && !isMobileDevice ? {marginLeft: '-40px'} : {}}>
          {renderBasedOnStatus(movement.status)}
          <span className="field-value">{movement.freightStatusDisplayName}</span>
        </div>
        <div className="item item2">
          {
            movement.identifier &&
           <div>
             <span className="field-label">{labelPrefix}Movement No</span>
             <span className="field-value">{movement.identifier}</span>
           {
             !isMobileDevice &&
               <span className="created-at">
                 Created:{' '}
                 {convertEpochToDateFormat(
                   movement.createdAt,
                 )}
               </span>
           }
           </div>
          }
        </div>
        {
          movement.order ?
          <div className="item item2">
            <div>
              <div>
                <span className="field-label">{labelPrefix}Order no</span>
                <span className="field-value">
                  {
                    isOrderClickable ?
                      <a href={`/#/freights/orders/${order.id}/order`}>{order.identifier}</a> :
                    order?.identifier
                }
                </span>
          {
            !isMobileDevice &&
                <span className="created-at">
                  Created:{' '}
                  {convertEpochToDateFormat(
                     movement.order.createdAt,
                  )}
            </span>
          }
              </div>
            </div>
          </div>
          : null
        }
        { (get(movement, 'commodityContract') || get(movement, 'externalReferenceNumber')) ?
          <div className="item item2">
            <div>
              <div>
                <span className="field-label">Contract No</span>
                <span className="field-value">{
                  get(movement, 'buyerLinkedDeliveryOrder') ?
                  linkedOrderContractClickable(movement) ?
                  <a href={contractUrl}>{get(movement, 'buyerLinkedDeliveryOrder.commodityContractIdentifier')}</a> :
                  get(movement, 'buyerLinkedDeliveryOrder.commodityContractIdentifier') :
                  contractClickable(movement) ?
                    <a href={contractUrl}>{get(movement, 'commodityContract.referenceNumber')}</a> :
                    get(movement, 'commodityContract.referenceNumber') || get(movement, 'externalReferenceNumber')
                }</span>
                {
                !isMobileDevice &&
                <span className="created-at">
                  Created:{' '}
                  {convertEpochToDateFormat(
                     get(movement.commodityContract, 'createdAt') || get(movement, 'createdAt'),
                  )}
                  </span>
                }
              </div>
            </div>
          </div>
          :null
        }

        {
          !isMobileDevice && (((showInvoicing || movement.isVoidRequestPending || (movement.isAmendRequestPending && movement.shouldDisplaySubStatusForAmend)) && (!movement.confirmable)) || movement.hasBeenAmended || (movement.isCommodityContractInvoiced && (movement.isBuyer || movement.isSeller))) ?
            <div className="item item3">
              <ul>
                {
                  movement.hasBeenAmended && <ChipLabel label="Amended" />
                }
                {
                  (
                    movement.isVoidRequestPending ||
                    (movement.isAmendRequestPending && movement.shouldDisplaySubStatusForAmend)
                  ) &&
                  <ChipLabel label={movement.subStatus} />
                }
                {
                  showInvoicing &&
                    <InvoiceChipMenu
                    invoicingHrefs={invoicingHrefs}
                    showAllocationInvoicing={showAllocationInvoicing}
                    menuItems={invoicingTypes}
                    />
                }
              </ul>
            </div> : null}

        { movement.containerMovement ?
          <div className="item item2">
            <div>
              <div>
                <span className="field-label">Container Movement</span>
                <span className="field-value">
                  <a rel="noopener noreferrer" href={`/#/freights/movements/${movement.containerMovement.id}/details`} target="_blank">{movement.containerMovement.identifier}</a>
                </span>
                {!isMobileDevice &&
                <span className="created-at">
                  Created:{' '}
                  {convertEpochToDateFormat(
                     movement.containerMovement.createdAt,
                  )}
                 </span>
                }
              </div>
            </div>
          </div>
          : movement.packMovement ?
          <div className="item item2">
            <div>
              <div>
                <span className="field-label">Pack Movement</span>
                <span className="field-value">
                  <a rel="noopener noreferrer" href={`/#/freights/movements/${movement.packMovement.id}/details`} target="_blank">{movement.packMovement.identifier}</a>
                </span>
                {
                  !isMobileDevice &&
                <span className="created-at">
                  Created:{' '}
                  {convertEpochToDateFormat(
                     movement.packMovement.createdAt,
                  )}
                </span>
                }
              </div>
            </div>
          </div> : null
        }
        {
          !isMobileDevice &&
        <div className="status-actions">
          <MovementDetailsActions {...props} />
        </div>
        }
      </div>
    </Paper>
  );
};

export default MovementDetailsBasicInfo;

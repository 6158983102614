import alertifyjs from 'alertifyjs';
import React from "react";
import { connect } from 'react-redux';
import { required } from "../../common/validators";
import CommodityMultiSelect from "../common/autocomplete/CommodityMultiSelect";
import { InputAdornment } from '@mui/material/';
import { every, find, forEach, get, isEmpty, isEqual, map, mapValues, set, uniqWith } from "lodash";
import { cartesian, getCountryDisplayUnit } from "../../common/utils";
import CommonTextField from "../common/CommonTextField";
import SideDrawer from "../common/SideDrawer";
import CommonButton from "../common/CommonButton";
import APIService from '../../services/APIService';
import { UNIT_ABBREVIATIONS } from '../../common/constants';

class ApplicationRateForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      fields: {
        commodityIds: {
          value: [],
          validators: [required()],
          errors: []
        },
        rate: {
          value: undefined,
          validators: [required()],
          errors: []
        }
      }
    }
  }

  componentDidMount() {
    const newState = {...this.state};
    newState.open = this.props.open;
    if (this.props.selected) {
      newState.fields.commodityIds.value = [get(this.props.selected, 'commodityId')];
      newState.fields.rate.value = get(this.props.selected, 'rate');
    }
    this.setState(newState);
  }

  getFieldErrors(field){
    set(field, 'errors', []);
    field.validators.forEach((validator) => {
      if (validator.isInvalid(field.value)) {
        field.errors.push(validator.message);
      }
    });
  }

  onMultiSelectChange = (id, selectedItems) => {
    const newState = { ...this.state };
    newState.fields[id].value = map(selectedItems, 'id');
    this.getFieldErrors(newState.fields[id]);
    this.setState(newState);
  };

  onRateChange(value) {
    const newState = { ...this.state };
    newState.fields.rate.value = value;
    this.getFieldErrors(newState.fields.rate);
    this.setState(newState);
  }

  onClose = (event, refresh) => {
    this.setState({ open: false }, () => this.props.onClose(refresh));
  };

  applyValidatorsOn() {
    const newState = {...this.state};
    forEach(newState.fields, (field) => {
      this.getFieldErrors(field);
    });
    this.setState(newState);
  };

  isValid() {
    const { fields } = this.state;
    return every(fields, state => isEmpty(state.errors));
  }

  getPayload() {
    let data = mapValues(this.state.fields, 'value');
    const {commodityIds, rate} = data;
    let args = [commodityIds, [rate]];
    let payload = [];
    let results = cartesian(...args);
    results = uniqWith(results, isEqual)
    map(results, result => {
      payload.push({
        commodityId: result[0],
        rate: result[1],
      });
    });
    return payload;
  }

  handleSubmit = event => {
    event.preventDefault();
    this.applyValidatorsOn();
    if (this.isValid()) {
      const payload = this.getPayload();
      APIService.companies(this.props.companyId)
        .appendToUrl('application-rates/')
        .post(payload)
        .then(response => {
          if (get(response, 'errors'))
            alertifyjs.error(get(response, 'errors'));
          else {
            this.props.refreshData();
            this.onClose();
          }
        })
    }
  }

  render() {
    const commodities = this.props.commodities.filter(commodity => commodity.type === 'chemical');
    const {selected} = this.props;
    const isCreate = selected ? false : true;
    const {fields, open} = this.state;
    let applicationRateUnit = '';
    if (!isEmpty(fields.commodityIds.value)) {
      const commodity = find(commodities, {id: fields.commodityIds.value[0]});
      if (commodity)
        applicationRateUnit = `${get(UNIT_ABBREVIATIONS, commodity.priceUnit)}/${getCountryDisplayUnit()}`;
    }
    return (
      <SideDrawer isOpen={open} title={isCreate ? 'Add Application Rate' : 'Edit Application Rate'} onClose={this.onClose}>
        <div className="col-sm-12 form-wrap-70" style={{ margin: '10px 0', paddingLeft: '0px' }}>
          <CommodityMultiSelect
            id="commodityIds"
            commodities={commodities}
            selectedCommodities={fields.commodityIds.value}
            error={fields.commodityIds.errors[0]}
            helperText={fields.commodityIds.errors[0]}
            onChange={this.onMultiSelectChange}
            placeholder='Select Applications...'
            label="Applications"
            disabled={!isCreate}
          />
        </div>
        <div className="col-sm-12 form-wrap-70" style={{paddingLeft: '0px', paddingTop: '20px'}}>
          <CommonTextField
            id='rate'
            label="Application Rate"
            value={fields.rate.value}
            helperText={fields.rate.errors[0]}
            onChange={event => this.onRateChange(event.target.value)}
            variant='outlined'
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  {applicationRateUnit}
                </InputAdornment>
              ),
            }}
            disabled={!isCreate || isEmpty(fields.commodityIds.value)}
          />
        </div>
        <div className="col-sm-12 form-wrap-70" style={{ margin: '20px 0', textAlign: 'right', paddingRight: '5px' }}>
          <CommonButton
            variant="contained"
            onClick={this.onClose}
            label='Cancel'
          />
          <CommonButton
            variant="contained"
            type='submit'
            primary
            onClick={this.handleSubmit}
            label='Submit'
          />
        </div>
      </SideDrawer>
    )
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items
  };
};

export default connect(mapStateToProps)(ApplicationRateForm);
import React from 'react';
import CommonMultiSelect from './CommonMultiSelect';

const GradeMultiSelect = (
  {id, options, selected, placeholder, variant, label, onChange, error, selectAll, clearAll, ...rest}
) => (
  <CommonMultiSelect
    selectAll={selectAll}
    clearAll={clearAll}
    items={options || []}
    selectedItems={selected || []}
    onChange={(event, items) => onChange(id, items)}
    displayField='name'
    id={id || "grade.AutoComplete"}
    placeholder={placeholder}
    label={label || ''}
    error={error}
    variant={variant || "outlined"}
    {...rest}
  />
);

export default GradeMultiSelect;

import React from 'react';
import { RadioGroup, FormControlLabel, Radio, DialogTitle } from '@mui/material';
import { useState } from 'react';
import CommonButton from '../common/CommonButton';
import get from 'lodash/get';
import { useDispatch } from 'react-redux';
import { CONTRACT_INVOICED_SUCCESS } from '../freights/MovementDetails/Constants';
import {markInvoicable} from '../../actions/companies/contracts';

let MarkInvoiceOutSide = props =>{

    const [buttons, setButtons] = useState();

    const dispatch = useDispatch();

    let handleSubmit = async () =>{
        dispatch(markInvoicable(props.selectedContractId, {"documentTypeId": buttons}, CONTRACT_INVOICED_SUCCESS));
        props.handleCancel();
    };

    return(<div>
        <form onSubmit={handleSubmit} noValidate>
            <DialogTitle id="form-dialog-title">
            <div style={{ marginLeft: '-24px'}}> Contract {props.identifier} </div>
            </DialogTitle>

            <div className="cardForm cardForm--drawer">
                <div className="cardForm-content row">
                    <RadioGroup onChange={e => setButtons(e.target.value)}  row className="col-sm-12 form-wrap-70">
                        <FormControlLabel key={1} value="Commodity Contract" disabled={get(props.defaultOption, 'contractInvoice')}
                            control={<Radio color="primary" />} label="Commodity Contract Invoiced & Paid"/>

                        <FormControlLabel key={2} value="Buyer Invoice" disabled={get(props.defaultOption, 'buyerInvoice')}
                             control={<Radio color="primary" />} label="Buyer Brokerage Invoiced"/>

                        <FormControlLabel key={3} value="Seller Invoice" disabled={get(props.defaultOption, 'sellerInvoice')}
                            control={<Radio color="primary" />} label="Seller Brokerage Invoiced"/>

                    </RadioGroup>
                </div>
                <div style={{ paddingTop: "40px", textAlign: "right" }} className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                    type="button"
                    variant="outlined"
                    label="Cancel"
                    default
                    onClick={props.handleCancel}
                />

                <CommonButton
                    primary
                    variant="contained"
                    label="Submit"
                    type="submit"
                />
                </div>
            </div>
        </form>
    </div>);
};

export default MarkInvoiceOutSide;
/*eslint no-process-env: 0*/
/*eslint no-undef: 0*/

import { Realtime } from 'ably';
import jwt from 'jwt-simple';
import { v4 as uuidv4 } from 'uuid';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import alertifyjs from 'alertifyjs';
import LZString from 'lz-string';
import { clarity } from 'react-microsoft-clarity';
import {default as QueryString} from 'query-string';
import {
  GROWER,
  BROKER,
  LOGISTIC,
  BHC_TYPE_ID,
  TRADER,
  SYSTEM,
  COMPANY_ADMIN,
  OFFICE_ADMIN,
  OBSERVER_TYPE_ID,
  FARM_ADMIN,
  FARM_EMPLOYEE,
  SALES_CONFIRMATION_PREFIX,
  BROKER_NOTE_PREFIX,
  CONTRACT_PREFIX,
  EMPTY_VALUE,
  FREIGHT_ORDER_PREFIX,
  FREIGHT_MOVEMENT_PREFIX,
  GRAIN_ORDER_PREFIX,
  PICKUP_ORDER_PREFIX,
  DELIVERY_ORDER_PREFIX,
  TITLE_TRANSFER_PREFIX,
  INVOICE_PREFIX,
  VD_PREFIX,
  ZENDESK_URL,
  MT_UNIT,
  PACK_ORDER_PREFIX,
  LOAD_PREFIX,
  PAYMENT_RUN_PREFIX,
  STOCK_SWAP_PREFIX,
  STORAGE_TO_STORAGE_PREFIX,
  REGRADE_RESEASON_PREFIX,
  SUPER_ADMIN_HOME,
  HOME,
  SITE_EMPLOYEE_HOME,
  LOGISTICS_LITE_PLAN,
  YEAR_MARGIN,
  BASE_SEASON,
  CENTURY_BASE_YEAR,
  SEASON_DATE,
  SEASON_MONTH,
  ALL_COMMODITY_UNITS,
  COMPANY_TYPES,
  DIRECT_TO_BUYER_ALLOCATION,
  THROUGH_WAREHOUSE_ALLOCATION,
  SEASON_NA,
  ORDER_TYPE_TITLE_MAPPING
} from './constants';
import moment from 'moment';
import isElement from 'lodash/isElement';
import isNumber from 'lodash/isNumber';
import isNaN from 'lodash/isNaN';
import get from 'lodash/get';
import has from 'lodash/has';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';
import keys from 'lodash/keys';
import map from 'lodash/map';
import forEach from 'lodash/forEach';
import pickBy from 'lodash/pickBy';
import replace from 'lodash/replace';
import startCase from 'lodash/startCase';
import snakeCase from 'lodash/snakeCase';
import times from 'lodash/times';
import find from 'lodash/find';
import isString from 'lodash/isString';
import differenceBy from 'lodash/differenceBy';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import indexOf from 'lodash/indexOf';
import merge from 'lodash/merge';
import without from 'lodash/without';
import { LOADING_PORT_LIST } from '../components/freights/Constants';


export const currentUser = () => JSON.parse(localStorage.getItem('user')) || {};
export const isStaff = () => currentUser()?.isStaff;
export const isSuperuser = () => currentUser()?.isSuperuser;
export const isSiteEmployee = () => FARM_EMPLOYEE === currentUserTypeId();
export const currentUserCompany = () => currentUser()?.company;
export const currentUserCompanyName = () => currentUserCompany()?.name;
export const currentUserName = () => currentUser()?.name;
export const currentUserEmail = () => currentUser()?.email;
export const currentUserType = () => startCase(currentUser()?.type?.name);
export const currentUserTypeId = () => currentUser()?.typeId;
export const currentUserToken = () => localStorage.getItem('token');
export const isCurrentUserCompanyAdmin = () => currentUserTypeId() === COMPANY_ADMIN;
export const isCurrentUserCompanyOrOfficeAdmin = () => [COMPANY_ADMIN, OFFICE_ADMIN, OBSERVER_TYPE_ID].includes(currentUserTypeId());
export const isCurrentUserFarmAdmin = () => currentUserTypeId() === FARM_ADMIN;
export const isCurrentUserBelongsToCompany = companyId => currentUserCompany()?.id === companyId;
export const isCurrentUserBrokerOrGrower = () => [GROWER, BROKER, SYSTEM].includes(currentUserCompany()?.typeId);
export const isCurrentUserGrower = () => currentUserCompany()?.typeId === GROWER;
export const isCurrentUserBroker = () => currentUserCompany()?.typeId === BROKER;
export const isCurrentUserLogistic = () => currentUserCompany()?.typeId === LOGISTIC;
export const isCurrentUserCompanyPlanLite = () => currentUserCompany()?.platformfeatures?.planType === LOGISTICS_LITE_PLAN;
export const isCurrentUserCompanyRegistered = () => currentUserCompany()?.isRegistered;
export const isCurrentUserRegisteredGrower = () => isCurrentUserCompanyRegistered() && isCurrentUserGrower();
export const isSubscribedUser = () => isTransactionParticipated(currentUserCompany());
export const isSystemCompany = () => currentUserCompany()?.typeId === SYSTEM;
export const isObserver = () => currentUser()?.typeId === 10 && !isSystemCompany();
export const getHome = () => {
  if (isSystemCompany())
    return SUPER_ADMIN_HOME
  if (isSiteEmployee())
    return SITE_EMPLOYEE_HOME
  return HOME
}
export const currentUserCompanyType = () => {
  const companyTypeId = currentUserCompany()?.typeId;
  if (companyTypeId === GROWER)
    return 'Grower';
  if (companyTypeId === BROKER)
    return 'Broker';
  if (companyTypeId === LOGISTIC)
    return 'Logistic';
  if (companyTypeId === BHC_TYPE_ID)
    return 'BHC';
  if (companyTypeId === TRADER)
    return 'Trader';
  if (companyTypeId === SYSTEM)
    return 'System';
};
export const isEnabledForCurrentUser = feature => {
  if(feature === 'dashboard' && isSystemCompany())
    return true
  if (isSiteEmployee())
    return feature === 'siteManagement'
  if ((feature === 'actionCentre'))
    return get(currentUserCompany(), `platformfeatures.${feature}`);
  if ((feature === 'haveField'))
    return get(currentUserCompany(), 'haveField');
  if (isSystemCompany())
    return true
  if (feature === 'xero')
    return get(currentUserCompany(), 'xeroEnabled')
  else if (feature === 'isVarietyMandatory') {
    const {loadTypeForVarietyMandatory, isVarietyMandatory} = currentUserCompany()
    return isVarietyMandatory && ['title_transfers_and_cash_outs'].some(type => loadTypeForVarietyMandatory.includes(type))
  }

  else
    return get(currentUserCompany(), `platformfeatures.${feature}`);
};

export const getRootForCurrentUser = (sendToReturnTo = true) => {
  let url;
  let returnTo = window.location.hash.replace('#', '');
  if (!sendToReturnTo || returnTo === '/')
    returnTo = '';
  else
    returnTo = `?referrerUrl=${returnTo}`;

  if (get(currentUserCompany(), 'platformfeatures.companies')) {
    url = '/';
  } else {
    const platformFeatures = get(currentUserCompany(), 'platformfeatures');
    const enabledFeatures = pickBy(platformFeatures, (value, key) => {
      return (
        value === true &&
        includes(['companies', 'farms', 'stocks', 'contracts', 'orders', 'movements', 'invoices', 'siteManagement', 'siteBookings'], key)
      );
    });
    url = enabledFeatures ? `/${replace(snakeCase(Object.keys(enabledFeatures)[0]), '_', '-')}` : '/';
  }
  if (returnTo)
    url += returnTo;

  return url;
};

export const nextFridayFrom = date => {
  const dateM = moment(date);
  if (dateM.weekday() > 5) return dateM.add(1, 'week').day(5);
  else return dateM.day(5);
};

export const nextEndDayFrom = (date, day) => {
  const dateM = moment(date);
  if (dateM.weekday() > day) return dateM.add(1, 'week').day(day);
  else return dateM.day(day);
};

export const getWeekDays = (startOfWeek, endOfWeek) => {
  let weekdays = [];
  let i = 0;
  if (startOfWeek < endOfWeek) {
    for (i = startOfWeek; i <= endOfWeek; i++) weekdays.push(i);
  } else {
    for (i = startOfWeek; i <= 6; i++) weekdays.push(i);

    for (i = 1; i <= endOfWeek; i++) weekdays.push(i);
  }
  return weekdays;
};

export const getDaysToAdd = (startDay, period, weekdays) => {
  let daysToAdd = 0;
  if (!weekdays.includes(startDay)) {
    period--;
  }
  while (period > 0) {
    if (weekdays.includes(startDay)) {
      period--;
    }
    startDay = getNextWeekDay(startDay);
    daysToAdd++;
  }

  while (!weekdays.includes(startDay)) {
    daysToAdd++;
    startDay = getNextWeekDay(startDay);
  }
  return daysToAdd;
};

export const getNextWeekDay = weekday => weekday == 6 ? 0 : weekday + 1;

export const getGradeName = item => {
  if (has(item, 'load') && !isEmpty(item.load)) {
    item = item.load;
  } else if (has(item, 'stocks') && !isEmpty(item.stocks)) {
    item = item.stocks;
  }
  let gradeName =
    get(item, 'gradeName') ||
    get(item, 'dominantGrade.name') ||
    get(item, 'plannedGrade.name') ||
    get(item, 'grade.name') ||
    get(item, 'commodityContract.grade.name') ||
    get(item, 'grade');

  if (includes(['BAR1', 'BAR2', 'BAR3'], gradeName) && includes(['16/17', '17/18', '18/19'], get(item, 'season')))
    gradeName = gradeName.replace('BAR', 'F');

  return gradeName;
};

export const generateIdentifier = (type, length = 14) => {
  var date = new Date();
  var generatedUUID = uuidv4().slice(0, 7);
  var dateFormatted = date.toISOString().slice(2, 10).replace(/-/g, '');
  let identifier = '';
  let prefix = '';
  if (type == 'sales_confirmation') {
    prefix = SALES_CONFIRMATION_PREFIX;
  } else if (type === 'broker_note') {
    prefix = BROKER_NOTE_PREFIX;
  } else if (type === 'contract') {
    prefix = CONTRACT_PREFIX;
  } else if (type === 'order') {
    prefix = FREIGHT_ORDER_PREFIX;
  } else if (type === 'grain_order') {
    prefix = GRAIN_ORDER_PREFIX;
  } else if (type === 'pickup_order') {
    prefix = PICKUP_ORDER_PREFIX;
  } else if (type === 'delivery_order') {
    prefix = DELIVERY_ORDER_PREFIX;
  } else if (type === 'freight_movement') {
    prefix = FREIGHT_MOVEMENT_PREFIX;
  } else if (type === 'title_transfer') {
    prefix = TITLE_TRANSFER_PREFIX;
  } else if (type === 'invoice') {
    prefix = INVOICE_PREFIX;
    length = getCountryConfig(getCurrentCountryCode())?.invoicing?.identifierLength || 14;
  } else if (type === 'vd') {
    prefix = VD_PREFIX;
  } else if (type === 'pack_order') {
    prefix = PACK_ORDER_PREFIX;
  } else if (type === 'regraded') {
    prefix = REGRADE_RESEASON_PREFIX;
  } else if (type === 'storageToStorage') {
    prefix = STORAGE_TO_STORAGE_PREFIX;
  } else if (type === 'stockSwap') {
    prefix = STOCK_SWAP_PREFIX;
  } else if (type === 'load') {
    prefix = LOAD_PREFIX;
  } else if (type === 'paymentRun') {
    prefix = PAYMENT_RUN_PREFIX;
  }

  generatedUUID = generatedUUID.toString().toUpperCase();
  identifier = prefix + dateFormatted + generatedUUID;
  return identifier.slice(0, length);
};

export const generateUpdatedIdentifier = (existingIdentifier, length = 14) => {
  if(!existingIdentifier)
    return null

  const prefix = existingIdentifier[0]

  var date = new Date();
  var generatedUUID = uuidv4().slice(0, 7);
  var dateFormatted = date.toISOString().slice(2, 10).replace(/-/g, '');
  generatedUUID = generatedUUID.toString().toUpperCase();
  let identifier = '';
  identifier = prefix + dateFormatted + generatedUUID;
  return identifier.slice(0, length);
}

export const getDocketNumberValue = item => {
  if (item.docketNumber && !isEmpty(item.docketImageUrl)) {
    return item.docketNumber;
  }
  if (item.docketNumber) {
    return item.docketNumber;
  }
  if (!isEmpty(item.docketImageUrl)) {
    return getCountryLabel('docket');
  }
  return null;
};

export const ROLE_BASED_CREATE_UPDATE_PERMS = {
  company: [1],
  office: [1],
  officeEmployee: [1, 2],
  farm: [1, 2, 3],
  alerts: [1, 2, 3, 4, 5, 6],
  farmEmployee: [1, 2, 3, 4],
  site: [1, 2, 3],
  truck: [1, 2, 3],
  companyNGR: [1, 2, 3],
  farmNGR: [1, 2, 3, 4, 5],
  condition: [1, 2, 3],
  farmField: [1, 2, 3, 4, 5],
  storage: [1, 2, 3, 4, 5],
  load: [1, 2, 3, 4, 5],
  contract: [1, 2, 3, 4, 5],
  vendorDecs: [1, 2, 3, 4, 5, 6],
  movement: [1, 2, 3, 4, 5],
  order: [1, 2, 3, 4, 5],
  notes: [1, 2, 3, 4, 5],
  shrinkages: [1, 2, 3, 4, 5],
  filters: [1, 2, 3, 4, 5, 6]
};

export const canCreateOrUpdate = (user, app, performRouteCheck = true) => {
  if (isSystemCompany() || isObserver()) {
    return true;
  }
  if (performRouteCheck) {
    const routeHash = document.location.hash;
    if (routeHash && routeHash.match('/companies/\\d+')) {
      const matches = routeHash.match(/\d+/g);
      if (matches) {
        if (user.companyId == parseInt(matches[0])) {
          return includes(ROLE_BASED_CREATE_UPDATE_PERMS[app], user.typeId);
        } else {
          return true;
        }
      }
    } else {
      return includes(ROLE_BASED_CREATE_UPDATE_PERMS[app], user.typeId);
    }
  } else {
    return includes(ROLE_BASED_CREATE_UPDATE_PERMS[app], user.typeId);
  }
};

export const isErrorPathname = pathname => includes(['#/403', '#/404', '#/500'], pathname || window.location.hash);

export const getCompanyIdFromCurrentRoute = () => {
  const match = window.location.hash.match(/\/companies\/([0-9]+)\//m);
  if (match && match.length > 1) {
    return match[1];
  }
};

export const getInvoiceIdFromCurrentRoute = () => {
  const match = window.location.hash.match(/\/invoices\/([0-9]+)\//m);
  if (match && match.length > 1) {
    return match[1];
  }
};
export const getContractIdFromCurrentRoute = () => {
  const match = window.location.hash.match(/\/contracts\/([0-9]+)\//m);
  if (match && match.length > 1) {
    return match[1];
  }
};

export const getOrderIdFromCurrentRoute = () => {
  const match = window.location.hash.match(/\/orders\/([0-9]+)\//m);
  if (match && match.length > 1) {
    return match[1];
  }
};

export const getFarmIdFromCurrentRoute = () => {
  const match = window.location.hash.match(/\/farms\/([0-9]+)\//m);
  if (match && match.length > 1) {
    return parseInt(match[1]);
  }
};

export const isGlobalInvoicesPath = () => {
  return includes(['#/invoices', '#/invoices/', 'invoices', '#/invoices/warehouse/dashboard', '#/invoices/payable', '#/invoices/receivable', '#/invoices/payment-runs', '#//invoices/payment-run', '#/invoices/freight-receivable'], window.location.hash.split('?')[0]);
};

export const isFarmStocksPath = () => {
  const match = window.location.hash.match(/#\/farms\/([0-9]+)\/home\/stocks/m);
  return match && match.length > 0;
};

export const isSiteManagementPath = () => {
  const match = window.location.hash.match(/#\/site-management/m);
  return match && match.length > 0;
};

export const isSiteBookingPath = () => {
  const match = window.location.hash.match(/#\/site-bookings/m);
  return match && match.length > 0;
};

export const isOrderDetailsPath = () => {
  const match =
    window.location.hash.match(/#\/freights\/orders\/([0-9]+)\/order/m) || window.location.hash.match(/#\/freights\/orders\/([0-9]+)\/movements/m);
  return match && match.length > 1;
};

export const isContractEditPath = () => {
  const match = window.location.hash.match(/#\/contracts\/([0-9]+)\/edit/m);
  return match && match.length > 1;
};

export const isOrderEditPath = () => {
  const match = window.location.hash.match(/#\/freights\/orders\/([0-9]+)\/edit/m);
  return match && match.length > 1;
};

export const isMovementEditPath = () => {
  const match = window.location.hash.match(/#\/freights\/movements\/([0-9]+)\/edit/m);
  return match && match.length > 1;
};

export const isMovementCreatePath = () => {
  return window.location.hash.includes('freights/movements/new');
};

export const isFarmRegistered = farm => {
  return get(farm, 'company.isRegistered');
};

export const canActOnFarm = farm => {
  if (isSystemCompany())
    return true
  if (isSiteEmployee())
    return false

  if (farm) {
    const farmRegistered = isFarmRegistered(farm);
    return (farmRegistered && farm.isAssociated) || !farmRegistered;
  }
};

export const canNotEdit = selectedEntity => {
  if (selectedEntity && selectedEntity.typeId === SYSTEM) {
    return false;
  }
  return selectedEntity && selectedEntity.typeId === 4;
};

export const isEntityFarm = (selectedEntity) => {
  return get(selectedEntity, 'entity') === 'farm';
};

export const getLabelForEmployeeForm = party => {
  return 'Add Employee for ' + (get(party, 'displayName') || get(party, 'company.displayName'));
};

export const canAccessAny = selectedEntity => {
  const userCompanyId = currentUserCompany()?.id;
  if (selectedEntity && !canNotEdit(selectedEntity)) {
    if (isEntityFarm(selectedEntity))
      return !isCurrentUserGrower()
        ? selectedEntity.brokerCompanyId === userCompanyId || canActOnFarm(selectedEntity)
        : selectedEntity.companyId === userCompanyId || !isFarmRegistered(selectedEntity);
    else
      return selectedEntity.id === userCompanyId || !get(selectedEntity, 'isRegistered');
  }
  return false;
};

export const isTransactionParticipated = selectedEntity => get(selectedEntity, 'transactionParticipation')

export const canActOnStorage = farm => Boolean(
  !isSiteEmployee() && (isSystemCompany() ||
    isCurrentUserBelongsToCompany(farm.companyId) || (
      isCurrentUserBroker() && farm.isManaged && farm.isManagedByUser
    ))
)

export const canEditFarm = farm => {
  if (isSiteEmployee())
    return false
  if (farm) {
    if (isEqual(get(farm, 'company.typeId'), BHC_TYPE_ID) && !(isSystemCompany() || isCurrentUserBelongsToCompany(farm.companyId || farm?.company?.id) && !(get(farm, 'isAssociated') && isCurrentUserBroker())))
      return false;

    let isEditable = isSystemCompany() || isCurrentUserBelongsToCompany(farm.companyId || farm?.company?.id) || (!isTransactionParticipated(farm?.company));
    return isEditable || (get(farm, 'isAssociated') && isCurrentUserBroker())
  }
};

export const isCompanyEditable = company => {
  if (company) {
    const isSuperUser = isSuperuser()

    if (isSuperUser)
      return true

    if (isSiteEmployee())
      return false

    const isSystem = isSystemCompany()
    const belongsToCompany = isCurrentUserBelongsToCompany(company.id)
    if (belongsToCompany && isSystem)
      return isSuperUser

    if (isSystem || belongsToCompany)
      return true;

    return !(
      isEqual(get(company, 'typeId'), BHC_TYPE_ID) ||
      (get(company, 'isRegistered') && get(company, 'transactionParticipation'))
    )
  }
}

export const disableEditFarm = farm => farm && isTransactionParticipated(farm.company) && !farm.isAssociated && isCurrentUserBroker();

export const isInMyCompanyContext = () => {
  const userCompany = currentUserCompany();
  if (isSystemCompany())
    return true;

  const companyIdFromRoute = getCompanyIdFromCurrentRoute();
  if (companyIdFromRoute) {
    return userCompany.id === parseInt(companyIdFromRoute);
  } else {
    return userCompany.typeId === 1;
  }
};

export const getHandlerName = (item, load = null) => {
  var handlerName = null;
  const storageType = load
    ? get(load, 'storage.storageType') || get(item, 'sites.0.location.storageType')
    : get(item, 'sites.0.location.storageType');
  if (storageType === 'system') {
    handlerName = load ? get(load, 'storage.operatorName') || get(item, 'sites.0.location.operatorName') : get(item, 'sites.0.location.operatorName');
  } else {
    handlerName = get(item, 'handler.displayName');
  }
  return handlerName || EMPTY_VALUE;
};

export const getLoadingPortDisplayName = (portName) => {
  let loading_ports = LOADING_PORT_LIST;
  return get(find(loading_ports, { id: portName }), 'name');
}

export const getSiteName = (item, load = null) => {
  var siteName = load
    ? get(load, 'storage.siteName') || get(load, 'companySite.name') || get(load, 'farmField.name') || get(item, 'sites.0.location.name')
    : get(item, 'sites.0.location.name');
  const storageType = load
    ? get(load, 'storage.storageType') || get(item, 'sites.0.location.storageType')
    : get(item, 'sites.0.location.storageType');
  if (storageType === 'system') {
    siteName = load ? get(load, 'storage.siteName') || get(item, 'sites.0.location.siteName') : get(item, 'sites.0.location.siteName');
  }
  return siteName || EMPTY_VALUE;
};

export const isSystemStorageOrBhc = (item, load) => {
  const storageType = load
    ? get(load, 'storage.storageType') || get(item, 'sites.0.location.storageType')
    : get(item, 'sites.0.location.storageType');
  return storageType === 'system' || get(item, 'sites.0.location.isBhc');
};

export const getDateTimeInUTC = (date, time) => {
  const utcDateTime = moment(date + ' ' + time)
    .utc()
    .format('YYYY-MM-DD HH:mm:ss');
  return {
    dateTime: utcDateTime
  };
};

export const getDateTimeFromUTC = dateTime => {
  if (!dateTime)
    return {
      date: '',
      time: '',
    };
  const localDate = moment(dateTime).format('YYYY-MM-DD');
  const localTime = moment(dateTime).format('HH:mm:ss');
  return {
    date: localDate,
    time: localTime,
  };
};

export const getContractSubHeaderText = contract => {
  let subHeaderText = '';
  const commodity = get(contract, 'commodity.displayName');
  let tonnage = get(contract, 'tonnage');
  let unit = contract?.requestedUnit || contract?.commodity?.priceUnit || MT_UNIT;
  if (contract && contract.isMeterCubeCommodity) {
    tonnage = get(contract, 'quantity');
  }

  if (commodity) subHeaderText += commodity;
  if (tonnage) subHeaderText += ' ' + parseFloat(tonnage).toFixed(2) + ' ' + unit;
  if (subHeaderText) subHeaderText = '(' + subHeaderText + ')';

  return subHeaderText;
};

export const getOrderSubHeaderText = order => {
  let subHeaderText = '';
  const commodity = get(order, 'commodity.displayName');
  let tonnage = get(order, 'plannedTonnage');
  let unit = order?.requestedUnit || order?.commodity?.priceUnit || MT_UNIT;
  if (order && order.isMeterCubeCommodity) {
    tonnage = get(order, 'inferredPlannedTonnage');
  }

  if (commodity) {
    subHeaderText += commodity;
  }
  if (tonnage) {
    subHeaderText += ' ' + parseFloat(tonnage).toFixed(2) + ' ' + unit;
  }

  if (subHeaderText) {
    subHeaderText = '(' + subHeaderText + ')';
  }
  return subHeaderText;
};

export const getOrderHeaderText = order =>{
  const orderTypeId = get(order, 'typeId')
  const label = get(ORDER_TYPE_TITLE_MAPPING, orderTypeId, 'Freight Order')
  let headerText = label
  headerText += ` ${get(order, 'identifier', '')}`;
  return headerText;
}

export const initials = user => {
  return user ? (get(user, 'firstName[0]', '') + get(user, 'lastName[0]', '')).toUpperCase() : '';
};

export const getBrokerageDisplayValue = (brokerage, unit = 'MT') => {
  const currency = getCountryCurrency()
  if (brokerage.feeType === 'Flat Fee') {
    if (brokerage.frequency) {
      var brokerageDisplayValue = `${currency} ${brokerage.totalFee} ${brokerage.frequency}`;
      if (brokerage.chargedAt) {
        brokerageDisplayValue +=
          ', charged at ' + brokerage.chargedAt + ' starting from ' + toDateFormat(brokerage.subscriptionStartDate);
      }
      return brokerageDisplayValue;
    } else {
      return `${currency} ${brokerage.totalFee} Flat`;
    }
  } else if (brokerage.feeType === '% of Sale') {
    return brokerage.rate + brokerage.feeType;
  } else if (brokerage.feeType === 'Fee Per MT') {
    return `${currency} ${brokerage.rate} per ${unit}`;
  }
};

function toDataUrl(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.onload = function () {
    var reader = new FileReader();
    reader.onloadend = function () {
      return callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}

export const convertToBase64 = (url, key) => {
  if (!key) {
    key = 'base64Data';
  }
  let httpUrl = url.replace('https://', 'http://');
  toDataUrl(httpUrl, base64Data => {
    window[key] = base64Data;
  });
  return window[key];
};

export const getPoolGrades = grades => uniqBy(grades, 'name')
  .filter(grade => !grade.name.match('UNGRADED'))
  .map(grade => grade.name)
  .join(', ');


export const isShallowEqual = (value, other) => {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) return false;

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
  var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isShallowEqual(item1, item2)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      // if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() != item2.toString()) return false;
      } else {
        if (item1 != item2) return false;
      }
    }
  };

  // Compare properties
  if (type === '[object Array]') {
    for (var i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (has(value, key)) {
        if (compare(value[key], other[key]) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

export const weekdays = () => {
  return [
    { id: 0, displayName: 'Sunday' },
    { id: 1, displayName: 'Monday' },
    { id: 2, displayName: 'Tuesday' },
    { id: 3, displayName: 'Wednesday' },
    { id: 4, displayName: 'Thursday' },
    { id: 5, displayName: 'Friday' },
    { id: 6, displayName: 'Saturday' },
  ];
};

export const getWeek = (value, data) => {
  if (!data) {
    data = weekdays();
  }
  const week = data.find(e => e.id === value);
  if (week) {
    return week.displayName;
  }
};

export const isAtMainOrderDetails = () => {
  return window.location.hash.match('#/freights/orders/([0-9]+)/((movements|order))(/)?$') ||
    window.location.hash.match('#/freights/orders/([0-9]+)(/)?$');
};

export const isAtGlobalOrders = () => {
  return includes(['#/orders/freights', '#/orders/grain', '#/orders/requests', '#/orders/packing'], window.location.hash.split('?')[0]);
};

export const isAtGlobalCompanies = () => {
  return includes(['#/companies', '#/companies/', '#/companies/groups', '#/companies/groups/'], window.location.hash.split('?')[0]);
};

export const isAtGlobalVendorDecs = () => {
  return window.location.hash.split('?')[0].match('#/vendor-decs');
};

export const isAtGlobalFarms = () => {
  return includes(['#/farms', '#/farms/'], window.location.hash.split('?')[0]);
};

export const isAtGlobalContracts = () => {
  return includes(['#/contracts', '#/contracts/'], window.location.hash.split('?')[0]);
};

export const isAtGlobalFMs = () => {
  return includes(['#/freights/movements/', '#/freights/movements', '#/movements/freights', '#/movements/pack'], window.location.hash.split('?')[0]);
};

export const isAtGlobalInvoices = () => {
  return includes(['#/invoices', '#/invoices/'], window.location.hash.split('?')[0]);
};

export const isAtGlobalCashPrices = () => {
  return window.location.hash.startsWith('#/cash-board');
};

export const isAtGlobalContractBids = () => {
  return window.location.hash.startsWith('#/contract-bids');
};

export const isAtStocksSiteLoads = () => {
  return window.location.hash.endsWith('site-view');
}

export const isLoggedOutCashBoardView = () => {
  return !currentUser()?.id && window.location.hash.split("?")[0] === '#/cash-board'
}

export const isAtGlobalTitleTransfer = () => {
  return includes(['#/title-transfers', '#/title-transfers/'], window.location.hash.split('?')[0]);
};

export const isAtFarmFMs = () => {
  return window.location.hash.match('/farms/([0-9]+)/home/movements');
};

export const isAtContractFMs = () => {
  return window.location.hash.match("/contracts/([0-9]+)/movements");
};

export const isAtOrderFMs = () => {
  return window.location.hash.match("/orders/([0-9]+)/movements");
};

export const isAtAnyVendorDecsListing = () => {
  return window.location.hash.match("/vendor-decs");
};


export const isAtOrderListing = () => {
  return window.location.hash.match("/([0-9]+)/orders/");
};

export const isInFarmContext = () => {
  const matches = window.location.hash.match('#/farms');
  return matches && matches.length > 0;
};

export const isInCompanyContext = () => {
  const matches = window.location.hash.match('#/companies');
  return matches && matches.length > 0;
};

export const plannedIcon = (color = '#fff', width = '16', height = '16') => {
  return `<svg class="planned" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path fill="${color}" d="M13 3c-4.97 0-9 4.03-9 9H1l4 3.99L9 12H6c0-3.87 3.13-7 7-7s7 3.13 7 7-3.13 7-7 7c-1.93 0-3.68-.79-4.94-2.06l-1.42 1.42C8.27 19.99 10.51 21 13 21c4.97 0 9-4.03 9-9s-4.03-9-9-9zm-1 5v5l4.25 2.52.77-1.28-3.52-2.09V8z"/></svg>`;
};
export const bookedIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg class="booked" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 14 14"> <g fill="none" fill-rule="evenodd"> <path fill="${color}" d="M6.993.333A6.663 6.663 0 0 0 .333 7c0 3.68 2.98 6.667 6.66 6.667A6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 6.993.333zm.007 12A5.332 5.332 0 0 1 1.667 7 5.332 5.332 0 0 1 7 1.667 5.332 5.332 0 0 1 12.333 7 5.332 5.332 0 0 1 7 12.333z"/> <path d="M-1-1h16v16H-1z"/> <path fill="${color}" d="M7.333 3.667h-1v4l3.5 2.1.5-.82-3-1.78z"/> </g> </svg>`;
};
export const delayedIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg width="${width}" height="${height}" viewBox="0 0 12 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 54.1 (76490) - https://sketchapp.com -->
    <title>ic_rotate_right copy</title>
    <desc>Created with Sketch.</desc>
    <g id="Mockups" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="SM_mgmt_status_Ridley" transform="translate(-333.000000, -573.000000)">
            <g id="ic_rotate_right-copy" transform="translate(331.000000, 573.000000)">
                <g id="Icon-24px">
                    <polygon id="Shape" points="0 0 16 0 16 16 0 16"></polygon>
                    <path d="M10.3666667,3.7 L7.33333333,0.666666667 L7.33333333,2.71333333 C4.70666667,3.04 2.66666667,5.28 2.66666667,8 C2.66666667,10.72 4.7,12.96 7.33333333,13.2866667 L7.33333333,11.94 C5.44,11.62 4,9.98 4,8 C4,6.02 5.44,4.38 7.33333333,4.06 L7.33333333,6.66666667 L10.3666667,3.7 L10.3666667,3.7 Z M13.2866667,7.33333333 C13.1733333,6.40666667 12.8066667,5.51333333 12.2066667,4.74 L11.26,5.68666667 C11.62,6.18666667 11.8466667,6.75333333 11.94,7.33333333 L13.2866667,7.33333333 L13.2866667,7.33333333 Z M8.66666667,11.9333333 L8.66666667,13.28 C9.59333333,13.1666667 10.4933333,12.8066667 11.2666667,12.2066667 L10.3066667,11.2466667 C9.80666667,11.6066667 9.24666667,11.84 8.66666667,11.9333333 L8.66666667,11.9333333 Z M11.26,10.32 L12.2066667,11.26 C12.8066667,10.4866667 13.1733333,9.59333333 13.2866667,8.66666667 L11.94,8.66666667 C11.8466667,9.24666667 11.62,9.81333333 11.26,10.32 L11.26,10.32 Z" id="Shape" fill="${color}"></path>
                </g>
            </g>
        </g>
    </g>
</svg>`;
};
export const completedIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg class="completed" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 14 14"> <g fill="none" fill-rule="evenodd"> <path d="M-1-1h16v16H-1z"/> <path fill="${color}" d="M7 .333A6.67 6.67 0 0 0 .333 7 6.67 6.67 0 0 0 7 13.667 6.67 6.67 0 0 0 13.667 7 6.67 6.67 0 0 0 7 .333zm0 12A5.34 5.34 0 0 1 1.667 7 5.34 5.34 0 0 1 7 1.667 5.34 5.34 0 0 1 12.333 7 5.34 5.34 0 0 1 7 12.333zm-1.115-4.04L4.181 6.59l-.848.848 2.544 2.544 4.8-4.8-.848-.848-3.944 3.96z"/> </g> </svg>`;
};
export const inProgressIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg class="in_progress" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 16 12"> <g fill="none" fill-rule="evenodd"> <path fill="${color}" d="M12.667 3.333L10 6h2c0 2.207-1.793 4-4 4a3.914 3.914 0 0 1-1.867-.467l-.973.974c.82.52 1.793.826 2.84.826A5.332 5.332 0 0 0 13.333 6h2l-2.666-2.667zM4 6c0-2.207 1.793-4 4-4 .673 0 1.313.167 1.867.467l.973-.974A5.287 5.287 0 0 0 8 .667 5.332 5.332 0 0 0 2.667 6h-2l2.666 2.667L6 6H4z"/> <path d="M0-2h16v16H0z"/> </g> </svg>`;
};
export const cancelledIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg class="cancelled" xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}" viewBox="0 0 14 14"> <g fill="none" fill-rule="evenodd"> <path d="M-1-1h16v16H-1z"/> <path fill="${color}" d="M8.727 4.333L7 6.06 5.273 4.333l-.94.94L6.06 7 4.333 8.727l.94.94L7 7.94l1.727 1.727.94-.94L7.94 7l1.727-1.727-.94-.94zM7 .333A6.66 6.66 0 0 0 .333 7 6.66 6.66 0 0 0 7 13.667 6.66 6.66 0 0 0 13.667 7 6.66 6.66 0 0 0 7 .333zm0 12A5.34 5.34 0 0 1 1.667 7 5.34 5.34 0 0 1 7 1.667 5.34 5.34 0 0 1 12.333 7 5.34 5.34 0 0 1 7 12.333z"/> </g> </svg>`;
};

export const commentIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg font-size="${width}" height="${height}" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g><path fill="${color}" d="M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM18 14H6v-2h12v2zm0-3H6V9h12v2zm0-3H6V6h12v2z"></path></g></svg>`;
};

export const restrictedIcon = (color = '#fff', width = '14', height = '14') => {
  return `<svg font-size="${width}" height="${height}" focusable="false" viewBox="0 0 24 24" aria-hidden="true" role="presentation"><g><path fill="${color}" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.42 0-8-3.58-8-8 0-1.85.63-3.55 1.69-4.9L16.9 18.31C15.55 19.37 13.85 20 12 20zm6.31-3.1L7.1 5.69C8.45 4.63 10.15 4 12 4c4.42 0 8 3.58 8 8 0 1.85-.63 3.55-1.69 4.9z"/></g></svg>`;
};


export const logSuccessResponseInHTML = response => {
  const urlEl = '<div>URL: ' + get(response, 'config.url') + '</div>';
  const statusEl = '<div>Code: ' + get(response, 'status') + '</div>';
  const tokenEl = '<pre>Headers: ' + JSON.stringify(get(response, 'config.headers'), undefined, 2) + '</pre>';
  const bodyEl = '<pre>Response: ' + JSON.stringify(get(response, 'data'), undefined, 2) + '</pre>';
  const el = document.getElementById('json-data');
  el.innerHTML = el.innerHTML + '<div class="response">' + urlEl + statusEl + tokenEl + bodyEl + '</div>';
};

export const logErrorResponseInHTML = error => {
  const urlEl = '<div>URL: ' + get(error, 'config.url') + '</div>';
  const statusEl = '<div>Code: ' + get(error, 'request.status') + '</div>';
  const tokenEl = '<pre>Headers: ' + JSON.stringify(get(error, 'config.headers'), undefined, 2) + '</pre>';
  const bodyEl = '<div>Response: <pre>' + get(error, 'request.response') + '</pre></div>';
  const el = document.getElementById('json-data');
  el.innerHTML = el.innerHTML + '<div class="response error">' + urlEl + statusEl + tokenEl + bodyEl + '</div>';
};

export const redirectToZendesk = () => {
  const URL = getZendeskURL();
  window.open(URL, '_blank');
};

export const getZendeskURL = () => {
  const payload = {
    iat: moment().unix(),
    jti: uuidv4(),
    name: currentUserName(),
    email: currentUserEmail() || 'dummy@agrichain.com',
  };
  const secret = process.env.ZENDESK_SHARED_SECRET || 'bigdummyrandkey';
  const token = jwt.encode(payload, secret);
  return ZENDESK_URL + token;
};


export const getCommoditySpecColumns = commodity => {
  return commodity && commodity.specs
    ? times(commodity.specs.length, index => {
      const spec = find(commodity.specs, { order: index + 1 });
      const code = get(spec, 'code', '');
      const name = get(spec, 'name');
      const unit = isString(name) ? name.substring(name.indexOf('(') + 1, name.indexOf(')')) : null;
      var header = 'SP' + index;
      if (unit && name) {
        header = `${code} (${unit})`;
      } else if (name) {
        header = name;
      }
      const key = isString(code) ? `specs.${code.toLowerCase()}` : '';
      return { key: key, header: header };
    })
    : {};
};

export const dateTimeToUTC = (date, time) => {
  if (date && time)
    return moment(date + ' ' + time).utc().format();
};

export const collapseLeftNav = () => {
  const button = document.querySelector('button[title="Collapse"]');
  if (button) button.click();
};

export const loadStylesheet = url => {
  let style = document.createElement('link');
  style.rel = 'stylesheet';
  style.href = url;
  document.head.appendChild(style);
};

export const loadScript = url => {
  let script = document.createElement('script');
  script.rel = 'text/javascript';
  script.src = url;
  document.head.appendChild(script);
};

export const toLocalDateTime = (date, time, format) => {
  if(!format)
    format = getCountryFormats().date
  let datetime;
  if (date && time) {
    datetime = moment(date + ' ' + time + 'Z');
    if (format) return datetime.format(format);
    return datetime.toDate();
  }
};

export const isChromeBrowser = () => {
  return window.chrome != null && window.navigator.vendor === 'Google Inc.';
};

export const isOldChromeVersion = () => {
  var chromeDetails = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  if (typeof (chromeDetails) != "undefined") {
    var chromeVersion = parseInt(get(chromeDetails, '2'), 10);
    if (isNumber(chromeVersion) && !isNaN(chromeVersion))
      return chromeVersion < 85;
    return false;
  }
  return false;
};

export const getAutoSelectFocusField = (fieldRef, field) => {
  if (has(fieldRef, field)) {
    const node = get(
      fieldRef[field],
      'current.childNodes[0].childNodes[1].childNodes[0].childNodes[0].childNodes[0].childNodes[0].childNodes[1].childNodes[0]',
    );
    if (node) {
      node.focus();
    }
  }
};

export const hasQueryString = () => {
  return window.location.hash.includes('?');
};

export const extractDigits = value => {
  if (value != null) {
    return value.toString().replace(/^\D/g, '');
  }
  return value;
};

export const formatPrice = (value, NAValue = 'N/A', currency) => {
  let currency_symbol = currency || getCountryCurrency();
  if (value) {
    let value_f = parseFloat(value).toFixed(2);
    let amount = parseFloat(Math.abs(value_f).toFixed(2)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
    if (value_f >= 0) return `${currency_symbol} ${amount}`;
    else return `-${currency_symbol} ${amount}`
  }
  return NAValue;
};
export const getSitePhoneNumber = (handler, site) => {
  if (get(handler, 'entity') === 'farm') {
    return get(handler, 'mobile', '');
  }
  return get(site, 'phone', '') || get(site, 'mobile', '');
};

export const compareArrayObjects = (arr1, arr2, key) => {
  let retValue = true;
  if (key) {
    forEach(arr1, item1 => {
      if (differenceBy(arr2, [item1], key).length === arr2.length) {
        retValue = false;
      }
    });
  } else {
    forEach(arr1, item1 => {
      if (!filter(arr2, item2 => isEqual(item1, item2)).length) {
        retValue = false;
      }
    });
  }
  return retValue;
};

export const combineObjectKeysWithMultiValue = (obj1, obj2) => {
  const _keys = uniq([...keys(obj1), ...keys(obj2)]);
  const obj = map(_keys, i => ({
    key: i,
    value1: get(obj1, i),
    value2: get(obj2, i),
  }));
  return obj;
};

export const compareObjectValues = (obj, excludes = [undefined]) => {
  if (isEmpty(excludes)) {
    excludes = [undefined];
  }

  if (indexOf(excludes, obj.value1) === -1) {
    return obj;
  }
  return null;
};

export const removeObjectValues = (obj, excludes = [undefined]) => {
  if (isEmpty(excludes)) {
    excludes = [undefined];
  }

  if (obj.value2 && obj.value2 !== obj.value1) {
    return obj;
  }
  if (indexOf(excludes, obj.value2) === -1 && obj.value2 !== obj.value1) {
    obj.value2 = '-';
    return obj;
  }
  return null;
};

export const compareObjectWithNonEqualValues = (obj, excludes = [undefined]) => {
  if (isEmpty(excludes)) {
    excludes = [undefined];
  }
  if (indexOf(excludes, obj.value2) === -1 && obj.value2 !== obj.value1) {
    return obj;
  }
  return null;
};
export const isInt = n => Number(n) === n && n % 1 === 0;

export const isFloat = n => Number(n) === n && n % 1 !== 0;

export const getQueryParams = (url, param_name) => {
  let href = url;
  let reg = new RegExp('[?&]' + param_name + '=([^&#]*)', 'i');
  let queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};

export const getAbsoluteUrl = url => {
  const u = url.split('?')[0].split('#');
  return u[u.length - 1];
};

export const reportToErrbit = (error, user, serverEnv, content, traces) => {
  if (!process.env.ERRBIT_KEY) return;
  const airbrake = new AirbrakeClient({
    projectId: 1,
    projectKey: process.env.ERRBIT_KEY,
    environment: serverEnv,
    host: process.env.ERRBIT_URL || 'http://localhost:8000'
  });

  airbrake.addFilter(notice => {
    if (!isEmpty(traces))
      notice.errors[0].backtrace = traces;
    return notice;
  });

  const promise = airbrake.notify({
    error: error,
    params: { payload: content },
    context: {
      component: window.location.href, user: { id: user.id, email: user.email }
    }
  });

  promise.then(function (notice) {
    if (!notice.id) {
      // eslint-disable-next-line no-console
      console.log('Error notification failed', notice.error);
    }
  });
};

export const isEqualByProperty = (a, b, prop) => {
  if (a.length === b.length) {
    if (!prop) {
      prop = 'id';
    }
    var idsA = map(a, x => x[prop]).sort();
    var idsB = map(b, x => x[prop]).sort();
    return idsA.join(',') === idsB.join(',');
  }
  return false;
};

export const dataConfigMap = (item, config) => {
  const _object = {};
  forEach(Object.keys(config), key => {
    _object[key] = get(item, config[key]);
  });
  return _object;
};

export const spliceItem = (options, predicate) => {
  let spliceItem = filter(options, predicate);
  if (!isEmpty(spliceItem)) {
    const index = getIndexOfSpliceItem(options, spliceItem[0]);
    options.splice(index, 1);
  }
};

export const getIndexOfSpliceItem = (options, spliceItem) => {
  for (let index = 0; index < options.length; index++) {
    if (options[index].key === spliceItem.key) {
      return index;
    }
  }
};

export const attachCSVEventListener = (channelName, entity, responseHandler) => {
  const onEventHandler = message => {
    if (!document.querySelector('div.ajs-message.ajs-success.ajs-visible'))
      if (message.data) {
        const data = JSON.parse(message.data);
        if (get(data, 'status') === 'failed')
          responseHandler(
            `Your ${entity} CSV couldn't be generated. Please try again after sometime.`
          );
        else if (get(data, 'url'))
          responseHandler(
            `Your ${entity} CSV is ready. Please <a href=${data.url}>click here</a> to download.`
          );
      }
  };
  attachABLYListener(channelName, get(currentUser(), 'id'), onEventHandler);
};

export const attachABAEventListener = responseHandler => {
  const onEventHandler = message => {
    if (!document.querySelector('div.ajs-message.ajs-success.ajs-visible'))
      if (message.data) {
        const data = JSON.parse(message.data);
        if (get(data, 'status') === 'failed')
          responseHandler(
            `Your ABA File couldn't be generated. Please try again after sometime.`
          );
        else if (get(data, 'url'))
          responseHandler(
            `Your ABA File is ready. Please <a href=${data.url}>click here</a> to download.`
          );
      }
  };
  attachABLYListener('payment-run-aba', get(currentUser(), 'id'), onEventHandler);
};

export const attachABLYListener = (channelName, stream, callback) => {
  const ABLY_KEY = process.env.ABLY_KEY;
  if (ABLY_KEY) {
    const ably = new Realtime(ABLY_KEY);
    ably.connection.on('connected', () => {
      // eslint-disable-next-line no-console
      console.log('Connected!');
    });
    if (stream) {
      stream = stream.toString();
      const channel = ably.channels.get(channelName);
      channel.subscribe(stream, message => {
        callback(message);
      });
    }
  }
};

export const openURLInNewTab = url => {
  const win = window.open(url, '_blank');
  win.focus();
};

export const jsonify = data => {
  if (!data)
    return data;

  let _data = data;

  if (isString(_data))
    _data = JSON.parse(_data);

  if (isString(_data))
    return jsonify(_data);

  return _data;
};

export const regeneratePDF = (service) => {
  const token = currentUserToken();
  if (service && token) {
    const _alertify = alertifyjs.notify('Sending PDF regeneration request, please be patient...', 'message', 0);
    service.get(token).then(() => {
      _alertify.dismiss();
      alertifyjs.success('Request is queued, PDFs will be regenerated in few minutes!', 3);
    });
  }
};

export const closeSpotLight = () => {
  if (isSpotLightOn())
    toggleSpotLight();
};

export const toggleSpotLight = () => {
  var event = new KeyboardEvent('keydown', {
    keyCode: 32,
    ctrlKey: true
  });
  document.body.dispatchEvent(event);
};

export const isSpotLightOn = () => {
  return isElement(document.getElementById('spotlight-input'));
};

export const vendorDecBlockPermissionPopup = (reasons, entity) => {
  let _reasons = map(reasons, reason => reason.replace('replace_with_entity', entity));
  const reasonsHTML = '<li>' + _reasons.join('</li><li>');
  alertifyjs.alert(
    'Permission Denied',
    `<div><p>You cannot create Vendor Declaration because:</p><ul>${reasonsHTML}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
    () => { }
  );
};

export const requestVendorDecBlockPermissionPopup = (reasons, entity) => {
  let _reasons = map(reasons, reason => reason.replace('replace_with_entity', entity));
  const reasonsHTML = '<li>' + _reasons.join('</li><li>');
  alertifyjs.alert(
    'Permission Denied',
    `<div><p>You cannot request Vendor Declaration because:</p><ul>${reasonsHTML}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
    () => { }
  );
};

export const chemicalFormPermissionPopup = (callback) => {
  alertifyjs.alert(
    'Permission Denied',
    `<div>
      <p>
        You cannot add Chemicals because:
      </p>
      <ul>
        <li>
          Answer to the above question for fumigant/pesticide/insecticide
          treatment is No
        </li>
      </ul>
      <div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>
    </div>`, callback()
  );

};

export const stocksHref = () => {
  return '#/stocks';
};

export const farmStocksHref = farmId => {
  return '#' + farmStocksPath(farmId);
};
export const farmStocksPath = farmId => {
  return `/stocks?farmId=${farmId}`;
};

const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
export const cartesian = (a, b, ...c) => {
  if(b && !isArray(b))
    b = [b]
  return (b ? cartesian(f(a, b), ...c) : a)
};

export const getMonthYear = tenure => {
  const monthNames = ["", "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];
  tenure = tenure.split('-');
  return monthNames[parseInt(tenure[1])] + ' ' + tenure[0];
};

export const getPageName = () => get(window.location.hash.split('?'), '0', '').replace('#/', '');

export const getPageCache = pageName => {
  const cache = localStorage.getItem(pageName || getPageName());

  if (cache)
    return JSON.parse(cache);

  return cache;
};

export const defaultViewAction = {
  name: 'Default View',
  fx: () => {
    updatePageCache({orderBy: '', order: ''});
    removeQueryParams();
  }
};

export const addPageCacheQueryParamsToUrl = (URL, pageName=null) => {
  if (!URL)
    return;
  const cache = getPageCache(pageName);
  const cachedPageSize = get(cache, 'pageSize');
  const cachedPage = get(cache, 'page');
  const cachedOrderBy = get(cache, 'orderBy');
  const cachedOrder = get(cache, 'order')

  let joiner = '?';
  if(URL.indexOf('?') > -1)
      joiner = '&';
  if(cachedPageSize) {
      URL += `${joiner}page_size=${cachedPageSize}`;
      joiner = '&';
  }
  if(cachedPage) {
      URL += `${joiner}page=${cachedPage}`;
      joiner = '&';
  }
  if(cachedOrderBy){
    URL += `${joiner}order_by=${cachedOrderBy}`;
    joiner = '&'
  }
  if(cachedOrder){
    URL += `${joiner}order=${cachedOrder}`;
  }
  if (cachedOrderBy && cachedOrder) {
    const pathsToCheck = [
        'farms/web/',
        'loads/',
        'contracts/web/',
        'orders/web/',
        'contracts/title-transfers/',
        'vendor_decs/web/'
    ];
    let shouldReplace = pathsToCheck.some(path => URL.indexOf(path) > -1);

    if (shouldReplace && URL.indexOf('/search/') === -1) {
        let queryParamIndex = URL.indexOf('?');
        if (queryParamIndex === -1) {
            URL = URL + 'search/';
        } else {
            URL = URL.slice(0, queryParamIndex) + 'search/' + URL.slice(queryParamIndex);
        }
      }
    }
  return URL;
}

export const updatePageCache = attrs => {
  let existingCache = getPageCache() || {};
  existingCache = merge(existingCache, attrs);
  localStorage.setItem(getPageName(), JSON.stringify(existingCache));
};

export const clearPageCache = () => {
  const pageName = getPageName();
  localStorage.removeItem(pageName);
};

export const removeQueryParams = () => {
  // Parse the current URL
  const url = new URL(window.location.href);

  // Extract the fragment identifier (the part after #)
  const fragment = url.hash.split('?')[0];

  // Create a new URL without the query parameters
  const newUrl = `${url.origin}${url.pathname}${fragment}`;

  // Reload the page with the new URL
  window.location.href = newUrl
  window.location.reload()
};

export const getUTCDateString = (dateString, secondsOffset) => {
  const date = moment(dateString).utc();
  if(secondsOffset)
    date.add(secondsOffset, 'seconds')
  return date.format('YYYY-MM-DD hh:mm:ss')
};

export const getUTCEndOfDateString = (dateString, secondsOffset) => {
  const date = moment(dateString).endOf('day').utc();
  if(secondsOffset)
    date.add(secondsOffset, 'seconds')
  return date.format('YYYY-MM-DD hh:mm:ss')
};

export const getLoadWarningTableData = (data, tonnage = null, space = null) => {
  let tableData = '';
  forEach(data, obj => {
    tableData += `<tr>
    <td>${get(obj, 'displayName')}</td>
    <td>${get(obj, 'grade') || '-'}</td>
    <td>${get(obj, 'season') || '-'}</td>
    <td>${tonnage ? tonnage : get(obj, 'tonnage') >= 0 ? parseFloat(get(obj, 'tonnage', 0)).toFixed(2) : '0'}</td>
    <td>${space ? space : get(obj, 'space') >= 0 ? parseFloat(get(obj, 'space', 0)).toFixed(2) : '0'}</td>
    </tr>`;
  });

  return tableData;
};

export const RGBtoRGBA = (rgb, opacity = '0.2') => rgb ? rgb.replace('rgb', 'rgba').replace(')', `,${opacity})`) : rgb;

export const returnFloatFromString = (value, decimalPrecision=2) => parseFloat(parseFloat(value.toString().replace(/[^0-9.-]/gi, '')).toFixed(decimalPrecision));

export const toFloatFromString = (value, roundOff = true) => {
  // take care of unit first
  let unit, val = value;
  if (isString(value)) {
    if (value.match(/[a-zA-Z]/)) {
      unit = value.slice(-3)
      val = value.slice(0, -3)
    }
  }
  val = parseFloat(val.toString().replace(/[^0-9.-]/gi, ''))
  val = roundOff ? val.toFixed(2) : val;
  if (isNaN(val))
    return value
  else {
    if (unit)
      val += unit
    return val
  }
}

export const deleteAllCookies = () => {
  var cookies = document.cookie.split(";");
  forEach(cookies, cookie => {
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  });
}

export const encrypt_password = password => {
  const salt = "$2a$10$"
  const result = [];
  times(password.length, i => result.push(password.charCodeAt(i)))
  return result.join(salt)
}

export const downloadFileFromBlob = (fileBlob, fileName) => {
  const url = window.URL.createObjectURL(new Blob([fileBlob]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
}

export const hasDuplicates = elements => new Set(elements).size != elements.length

export const round = number => {
  if (!isNumber(number))
    return number

  return Math.round(number * 100) / 100
}

//this is used in mobile seasons, if changed, please make sure to update there too.
export const getSeasons = () => {
  var today = new Date()
  var current_month = today.getMonth() + 1
  var current_date = today.getDay()

  var current_month_and_day = new Date(`${CENTURY_BASE_YEAR}-${current_month}-${current_date}`)
  var season_month_and_day = new Date(`${CENTURY_BASE_YEAR}-${SEASON_MONTH}-${SEASON_DATE}`)

  var current_season = today.getFullYear() - CENTURY_BASE_YEAR

  if (current_month_and_day.getTime() < season_month_and_day.getTime())
    current_season -= 1
  var max = current_season + YEAR_MARGIN;
  var years = []
  for (var i = max; i >= BASE_SEASON; i--) {
    years.push(`${i}/${i + 1}`)
  }
  return [...years, SEASON_NA]
}

export const STOCK_UPLOAD_DATA_TYPES = [
  { id: 'commodity', name: 'Commodity' },
  { id: 'sites', name: 'Sites' },
  { id: 'ngr', name: 'NGRs' },
  { id: 'storages', name: 'Storages' },
  { id: 'employees', name: 'Employees' },
]

export const STOCK_UPLOAD_DATA_OF = [
  { id: 'self', name: 'Self' },
  { id: 'company_directory', name: 'Company Directory' },
]

export const currentUserCanEdit = companyId => isCurrentUserBelongsToCompany(companyId) && isCurrentUserCompanyOrOfficeAdmin()

export const isCompanyGrower = company => company?.typeId === GROWER


export const isCompanyNgrsEditable = (id, selectedEntity) => {
  if(isSystemCompany() || (isCurrentUserBelongsToCompany(id) && isCurrentUserCompanyAdmin()))
    return true

  if(!selectedEntity?.transactionParticipation && isCompanyGrower(selectedEntity))
    return !isCurrentUserBelongsToCompany(id)

  return false
}

export const alertForNGRBasedOnRegistration = company => {
  const message = get(company, 'isRegistered') ?
      `Please ask ${company?.name} to log in to add/edit NGR details or contact us on <a href="mailto:support@agrichain.com" target='_blank' rel="noopener noreferer">support@agrichain.com</a> for more information.`
      : `For security reasons, NGR details can only be added and edited by ${company?.name} once they create an account with AgriChain. Please ask them to sign up or contact us at <a href="mailto:support@agrichain.com" target='_blank' rel="noopener noreferer">support@agrichain.com</a> for more information.`;
  alertifyjs.error(message);
}

export const getAusConfig = () => find(JSON.parse(localStorage.countries || '[]'), {code: 'au'}) || {}
export const getCurrentCountry = countryCode => find(JSON.parse(localStorage.countries || '[]'), {code: countryCode || localStorage.current_country || 'au'}) || {}
export const getCountryConfig = countryCode => getCurrentCountry(countryCode)?.config
export const getCurrentCountryCode = () => localStorage.current_country || 'au'

export const customVarietiesAllowed = () => getCurrentCountry()?.config?.allowCustomVarieties;

export const getDefaultTruckTrailerUnit = () => getCurrentCountry()?.config?.truck?.unit;

export const toUSAPhoneFormat = value => {
  if(!value)
    return value
  let val = value.replace(')', '').replace('(', '').replace(' ', '').replace('-', '')
  return `(${val.slice(0, 3)}) ${val.slice(3, 6)}-${val.slice(6, 10)}`
}

export const toPhoneFormat = value => {
  if(!value)
    return value
  const phoneFormatMethod = getCurrentCountry()?.config?.phoneFormatMethod;
  if(phoneFormatMethod) {
    return eval(phoneFormatMethod + "(value)");
  }
  return value
}

export const isEntityNameDisabled = () => getCurrentCountry()?.config?.disableEntityName;

export const getPhoneMobileRegex = () => {
  return new RegExp(getCurrentCountry()?.config?.phoneMobileRegex);
}

export const getCountryFormats = () => getCurrentCountry()?.config?.format || {}
export const getCountryLabel = key => get(getCountryConfig()?.labels, key) || ''
export const getCountrySystemCompanyId = () => getCountryConfig()?.systemCompanyId
export const getCountryRootUserId = () => getCountryConfig()?.rootUserId

export const toDateFormat = value => moment(value).format(getCountryFormats().date)

export const toDateTimeFormat = value => moment(value).format(getCountryFormats().datetime)
export const getCountryMovementDisplayUnit = () => getCountryConfig()?.movementDisplayUnit
export const getCountryDisplayUnit = () => getCountryConfig()?.displayUnit
export const getCountryCurrency = countryCode => getCountryConfig(countryCode)?.currency
export const isVisibleInCountry = key => !(getCountryConfig()?.hiddenFields || []).includes(key)
export const isMoistureSpecMandatory = () => getCountryConfig()?.moistureSpecMandatory
export const showTargetMoistureTab = () => getCountryConfig()?.showTargetMoisture
export const isNGRVisibleInCountry = () => isVisibleInCountry('ngr')
export const toBankAccountFormat = bankDetails => {
  if(!bankDetails)
    return EMPTY_VALUE;
  const bankAccountFormatMethod = getCurrentCountry()?.config?.bankAccountFormatMethod;
  if(bankAccountFormatMethod)
    return eval(bankAccountFormatMethod + "(bankDetails)");
  return get(bankDetails, 'accountNumber') || EMPTY_VALUE;
}

export const toNZBankAccountFormat = bankDetails => {
  if (!bankDetails.code || !bankDetails.bsbNumber || !bankDetails.accountNumber)
    return get(obj, 'accountNumber') || EMPTY_VALUE;
  const code = bankDetails.code;
  const bsbLast4 = bankDetails.bsbNumber.slice(-4);
  const accountFirst7 = bankDetails.accountNumber.slice(0, 7);
  const accountLast3 = bankDetails.accountNumber.slice(-3);
  return `${code}-${bsbLast4}-${accountFirst7}-${accountLast3}`;
}

export const MAX_DATE = toDateFormat('9999-12-31')

export const replaceUnit = (str, unit) => {
  let toleranceUnit = getCountryConfig()?.toleranceUnit || unit
  const otherUnits = without(ALL_COMMODITY_UNITS, toleranceUnit)
  otherUnits.forEach(_unit => str = str.replace(_unit.toLowerCase(), toleranceUnit).replace(_unit.toUpperCase(), toleranceUnit))
  return str
}

export const pendoInitialize = _user => {
  const user = _user || currentUser()
  if(window.PENDO_TOGGLE && user?.id) {
    pendo.initialize({
      visitor: {
        id: user.username,
        email: user.email,
        full_name: user.name,
        role: user.type?.name,
        creationDate: user.createdAt || user.dateJoined
      },
      account: {
        id: user.companyId,
        name: user.company?.name,
        is_paying: user.company?.transactionParticipation,
        planLevel: user.company?.platformfeatures?.planType,
        // eslint-disable-next-line spellcheck/spell-checker
        businesstype: COMPANY_TYPES[user.company?.typeId]
      }
    });
  }
}

export const clarityInitialize = _user => {
  const user = _user || currentUser()
  const CLARITY_ID = process.env.CLARITY_ID
  if(CLARITY_ID && user?.id) {
    clarity.init(CLARITY_ID);
    clarity.consent();
    clarity.upgrade('upgradeReason');
    if (clarity.hasStarted()) {
      clarity.setTag('userId', user.username);
    }
 }
}

export const compressString = str => LZString.compressToUTF16(str);
export const decompressString = str => LZString.decompressFromUTF16(str);

export const getSavedHandlers = () => {
  const handlers = localStorage.getItem('handlers')
  if(handlers) {
    if(handlers.startsWith('[{')) {
      saveHandlers(JSON.parse(handlers))
      return getSavedHandlers()
    }
    return JSON.parse(decompressString(handlers))
  }
  return []
}

export const saveHandlers = handlers => localStorage.setItem('handlers', compressString(JSON.stringify(handlers)))
export const isDirectToBuyerAllocationEnabled = () => includes(currentUserCompany().contractAllocations, DIRECT_TO_BUYER_ALLOCATION);
export const isThroughWarehouseAllocationEnabled = () => includes(currentUserCompany().contractAllocations, THROUGH_WAREHOUSE_ALLOCATION);

export const validateMovementUnitForLoadEdit = movement => {
  let result = true
  const displayUnit = getCountryMovementDisplayUnit()
  const isNotInMovementDisplayUnit = (movement?.requestedUnit || currentUser()?.unit) !== displayUnit
  if(getCountryConfig()?.showConversions && isNotInMovementDisplayUnit) {
    result = false
    alertifyjs.alert('Movement Unit', `To add/edit load please switch to ${displayUnit} unit.`, () => {})
  }
  return result
}

export const formatQueryString = url => {
  let newURL = url
  if(newURL && newURL.includes('?')) {
    const parts = newURL.split('?')
    let params = QueryString.parse(parts[1])
    forEach(params, (value, key) => {
      if(isArray(value) && value.length > 1)
        params[key] = uniq(value)
    })
    newURL = parts[0] + '?' + QueryString.stringify(params)
  }
  return newURL
}

export const copyToClipboard = (copyText, message, timeout) => {
  if(copyText)
    navigator.clipboard.writeText(copyText);

  if(message)
    alertifyjs.success(message, isNumber(timeout) ? timeout : undefined);
}

export const getEstimatedPaymentDueDateForDatePaymentTerm = (referenceDate, selectedPaymentTerm, format) => {
    const startDate = moment(referenceDate)
    const day = startDate.date();
    const period = selectedPaymentTerm.period;

    let paymentDueDate;
    if (day <= period)
      paymentDueDate = startDate.endOf('month');
    else {
      const nextMonth = startDate.add(1, 'month');
      const daysInNextMonth = nextMonth.daysInMonth();
      const adjustedDay = Math.min(period, daysInNextMonth);
      paymentDueDate = nextMonth.date(adjustedDay);
    }

    const val = paymentDueDate.format(format);
    return val
}
import {
  RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES,
  RECEIVE_COMMODITIES_WITH_GRADES,
  RECEIVE_GRADES,
} from '../../actions/master/commodities';
import flatten from 'lodash/flatten';

const initialState = {
  items: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES:
    case RECEIVE_COMMODITIES_WITH_GRADES:
      return { items: flatten([].concat(action.items.map(item => item.grades))) };
    case RECEIVE_GRADES:
      return { items: action.items };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import { getCurrentCountry, isSuperuser } from '../../common/utils';
import CommonTextField from '../common/CommonTextField';

const ABNField = ({value, helperText, onBlur, onChange, disabled, errorStyle, countryCode}) => {
  const config = getCurrentCountry(countryCode)?.config?.abn
  const getLabel = () => {
    let label = 'ABN'
    if(config?.label) {
      label = config.label
      label = (config.optional || isSuperuser()) ? `${label} (Optional)` : label
    }
    return label
  }

  return (
    <CommonTextField
      id="abn"
      label={getLabel()}
      type="number"
      placeholder={`Please enter ${config?.length} digit ${config?.label} number`}
      value={value}
      helperText={helperText}
      errorStyle={errorStyle}
      onBlur={onBlur}
      onChange={onChange}
      onInput = {(e) =>{
        e.target.value = e.target.value.toString().slice(0,config?.abn?.length);
      }}
      fullWidth
      disabled={disabled}
    />
  )
}

export default ABNField;

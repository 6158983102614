import React from "react";
import { connect } from 'react-redux';
import { get, has, includes, set, map, isString, pickBy } from "lodash";
import CommonTextField from "../common/CommonTextField";
import { DELIVERY_MANDATORY_PRICE_POINTS, DISTANCE_FOR_LEVY, FREIGHT_DELIVERY_CHARGES, PICKUP_MANDATORY_PRICE_POINTS, PRICE_POINTS } from "../../common/constants";
import { CARRY_ITEM_TYPE, EPR_ITEM_TYPE, GRAIN_LEVY_ITEM_TYPE, TITLE_TRANSFER_ITEM_TYPE, LOAD_ITEM_TYPE } from "./Constants";
import CommonButton from "../common/CommonButton";
import { getCountryConfig, getCountryLabel } from '../../common/utils'
import AddButton from "../common/AddButton";
import { decimalFilter } from "../../common/input-filters";
import { Button, Typography, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { isLoading } from '../../actions/main';
import ContentRemove from '@mui/icons-material/Remove';
import '../../common/forms.scss';
import alertifyjs from 'alertifyjs';
import { forEach, find } from "lodash";


class AdjustedPrices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryConfig: getCountryConfig(),
      unit: props.unit || 'MT',
      contractPrice: undefined,
      locationDifferential: undefined,
      spreadPrice: undefined,
      totalPrice: undefined,
      originalTotalPrice: undefined,
      shrinkage: undefined,
      receivalFees: undefined,
      admixtureAdjustment: undefined,
      oilAdjustment: undefined,
      avgAdmix: undefined,
      avgOil: undefined,
      customAdjustmentsCount: 0,
      customAdjustments: {},
      openWarningDialog:false,
      levyDetails: {
        saleValue: undefined,
        tonnage: undefined,
        totalDistance: undefined,
        commodityLevy: undefined,
        standardFormula: undefined,
        adjustedFormula: undefined,
        standard: undefined,
        standardDeliveryRate: null,
        adjusted: undefined,
        totalLevy: undefined,
        finalLevy: undefined,
      },
      eprDetails: {
        rate: undefined,
        totalEpr: undefined,
        tonnage: undefined,
        finalEpr: undefined,
      },
      carryDetails: {
        rate: undefined,
        tonnage: undefined,
        numberOfCarryPeriods: undefined,
        totalCarry: undefined,
        carryPeriod: undefined,
        ratePerCarryPeriod: undefined,
      }
    }

    this.handleReceivalFeesChange = this.handleReceivalFeesChange.bind(this);
    this.handleShrinkageChange = this.handleShrinkageChange.bind(this);
  }

  checkForChangingStandardDeliveryRate = () => {
    let itemForType = get(this.props.item, 'itemForType');
    let pricePointId = get(this.props.item, 'pricePointId');
    let isBHC = get(this.props.item, 'isBhc');
    return !includes([TITLE_TRANSFER_ITEM_TYPE, LOAD_ITEM_TYPE], itemForType) && !isBHC && includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId)
  }

  componentDidMount() {
    const newState = {...this.state};
    if (get(this.props.item, 'itemType') === GRAIN_LEVY_ITEM_TYPE) {
      let itemForType = get(this.props.item, 'itemForType');
      let pricePointId = get(this.props.item, 'pricePointId');
      newState.levyDetails.saleValue = get(this.props.item, 'contractValue');
      newState.levyDetails.tonnage = get(this.props.item, 'tonnage');
      newState.levyDetails.totalDistance = get(this.props.item, 'totalDistance');
      newState.levyDetails.commodityLevy = get(this.props.item, 'rate');
      newState.levyDetails.totalLevy = get(this.props.item, 'actualPrice') ? parseFloat(parseFloat(get(this.props.item, 'actualPrice')).toFixed(2)) : get(this.props.item, 'actualPrice');
      newState.levyDetails.finalLevy = get(this.props.item, 'total') ? parseFloat(parseFloat(get(this.props.item, 'total')).toFixed(2)) : get(this.props.item, 'total');
      let includeAdjustedValue = get(this.props.item, 'levyAdjustmentIncluded');
      let isBHC = get(this.props.item, 'isBhc');
      let rate = isString(this.props.item?.rate) ? this.props.item?.rate?.replace("%", "").replace(this.props.currency, '').replace(` / ${this.state.unit}`, "") : this.props.item?.rate
      rate = parseFloat(rate) * 0.01;
      if (get(this.props.item, 'multiplier') === 'tonnage') {
        let tonnageMultiplierRate = parseFloat(rate) * 100;
        newState.levyDetails.standardFormula = "Commodity Levy Rate * Tonnage";
        newState.levyDetails.standard = (tonnageMultiplierRate * get(this.props.item, 'tonnage', 0)).toFixed(2);
        newState.levyDetails.adjustedFormula = '';
        newState.levyDetails.adjusted = 0;
      }
      else if ((includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId) &&
        (includes([TITLE_TRANSFER_ITEM_TYPE, LOAD_ITEM_TYPE], itemForType) || isBHC)) ||
        includes([PRICE_POINTS.TRACK, PRICE_POINTS.DELIVERED_MARKET_ZONE, PRICE_POINTS.FREE_IN_STORE], pricePointId)) {
        newState.levyDetails.standardFormula = "Commodity Levy Percentage * Sale Value";
        newState.levyDetails.standard = (rate * get(this.props.item, 'contractValue', 0)).toFixed(2);
        newState.levyDetails.adjustedFormula = includeAdjustedValue ?`Commodity Levy Percentage * $${DISTANCE_FOR_LEVY} * tonnage` : '';
        newState.levyDetails.adjusted = includeAdjustedValue ? (rate * DISTANCE_FOR_LEVY * get(this.props.item, 'tonnage', 0)).toFixed(2) : 0;
      }
      else if (!includes([TITLE_TRANSFER_ITEM_TYPE, LOAD_ITEM_TYPE], itemForType) && !isBHC && includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId)) {
        newState.levyDetails.standardFormula = "Commodity Levy Percentage * Sale Value";
        newState.levyDetails.standard = (rate * get(this.props.item, 'contractValue', 0)).toFixed(2);
        newState.levyDetails.standardDeliveryRate = includeAdjustedValue ? get(this.props.item, 'standardDeliveryRate', FREIGHT_DELIVERY_CHARGES) : 0;
        newState.levyDetails.adjustedFormula = includeAdjustedValue ? `Commodity Levy Percentage * $${newState.levyDetails.standardDeliveryRate} * Total Distance * Tonnage)` : '';
        newState.levyDetails.adjusted = includeAdjustedValue ? (rate * newState.levyDetails.standardDeliveryRate * get(this.props.item, 'totalDistance', 0) * get(this.props.item, 'tonnage', 0)).toFixed(2) : 0;
      }
      else if (includes(PICKUP_MANDATORY_PRICE_POINTS, pricePointId)) {
        newState.levyDetails.standardFormula = "Commodity Levy Percentage * Sale Value";
        newState.levyDetails.standard = (rate * get(this.props.item, 'contractValue', 0)).toFixed(2);
        newState.levyDetails.adjustedFormula = "";
        newState.levyDetails.adjusted = 0;
      }
    }
    else if(get(this.props.item, 'itemType') === CARRY_ITEM_TYPE) {
      newState.carryDetails.ratePerCarryPeriod = get(this.props.item, 'carryRatePerPeriod', 0);
      newState.carryDetails.rate = get(this.props.item, 'rate', 0);
      newState.carryDetails.numberOfCarryPeriods = get(this.props.item, 'numberOfCarryPeriods');
      newState.carryDetails.tonnage = get(this.props.item, 'tonnage');
      newState.carryDetails.totalCarry = get(this.props.item, 'subTotal');
      newState.carryDetails.carryPeriod = get(this.props.item, 'startDate') + ' - ' + get(this.props.item, 'date')
    }
    else if(get(this.props.item, 'itemType') === EPR_ITEM_TYPE) {
      newState.eprDetails.rate = get(this.props.item, 'rate');
      newState.eprDetails.totalEpr = get(this.props.item, 'actualPrice');
      newState.eprDetails.tonnage = get(this.props.item, 'tonnage');
      newState.eprDetails.finalEpr = get(this.props.item, 'subTotal');
    }
    else {
      newState.contractPrice = get(this.props, 'item.contractPrice');
      newState.locationDifferential = get(this.props, 'item.locationDifferential');
      newState.spreadPrice = get(this.props, 'item.spreadPrice') || 0;
      newState.totalPrice = get(this.props, 'item.totalPricePerMt') ? parseFloat(get(this.props, 'item.totalPricePerMt')).toFixed(2) : get(this.props, 'item.totalPricePerMt');
      newState.originalTotalPrice = get(this.props, 'item.priceWithoutCustomAdjustment');
      newState.shrinkage = get(this.props, 'item.shrinkage');
      newState.receivalFees = get(this.props, 'item.receivalFees');
      newState.admixtureAdjustment = get(this.props, 'item.admixtureAdjustment');
      newState.oilAdjustment = get(this.props, 'item.oilAdjustment');
      newState.avgAdmix = get(this.props, 'item.avgAdmix');
      newState.avgOil = get(this.props, 'item.avgOil');
      let customAdjustments = get(this.props, 'item.customAdjustments');
      if (customAdjustments) {
        newState.customAdjustments = customAdjustments.reduce((acc, item, index) => {
          acc[index] = { ...item };
          return acc;
        }, {});
        newState.customAdjustmentsCount = customAdjustments.length;
      }
    }

    this.setState(newState);
  }

  getPriceLabel() {
    return PRICE_POINTS.TRACK === get(this.props, 'item.pricePointId') ? `FIS Price (Ex ${getCountryLabel('gst')}) (Per ${this.state.unit})` : `Price (Ex ${getCountryLabel('gst')}) (Per ${this.state.unit})`;
  }

  isLevyItem() {
    return has(this.props, 'item.itemType') && get(this.props, 'item.itemType') === GRAIN_LEVY_ITEM_TYPE;
  }

  isEprItem() {
    return has(this.props, 'item.itemType') && get(this.props, 'item.itemType') === EPR_ITEM_TYPE;
  }

  isCarryItem() {
    return has(this.props, 'item.itemType') && get(this.props, 'item.itemType') === CARRY_ITEM_TYPE;
  }

  applyReceivalFeesOnPrice(pricePerMt, receivalFees) {
    return parseFloat(pricePerMt) + parseFloat(receivalFees);
  }

  applyShrinkageOnPrice(pricePerMT, shrinkage) {
    return parseFloat(parseFloat(pricePerMT) / (1- (parseFloat(shrinkage) / 100))).toFixed(5);
  }

  applyShrinkageAndReceivalOnPrice(receivalFees, shrinkage, id) {
    let pricePerMT = get(this.props, 'item.exShrinkageReceivalPrice');
    if (receivalFees) {
      pricePerMT = this.applyReceivalFeesOnPrice(pricePerMT, receivalFees);
    }
    if (shrinkage) {
      pricePerMT = this.applyShrinkageOnPrice(pricePerMT, shrinkage);
    }
    const newState = {...this.state};
    newState.totalPrice = pricePerMT;
    set(newState, id, id === 'receivalFees' ? receivalFees : shrinkage)
    this.setState(newState);
  }

  handleReceivalFeesChange(event) {
    this.props.handleReceivalFeesChange(this.props.item, event.target.value);
    this.applyShrinkageAndReceivalOnPrice(event.target.value, this.state.shrinkage, 'receivalFees');
  }

  handleShrinkageChange(event) {
    this.props.handleShrinkageChange(this.props.item, event.target.value);
    this.applyShrinkageAndReceivalOnPrice(this.state.receivalFees, event.target.value, 'shrinkage');
  }

  handleCustomAdjustmentChange(event, index, property) {
    let value = event.target.value;
    const newState = {...this.state};
    if (typeof newState.customAdjustments[index] !== 'object')
      newState.customAdjustments[index] = {};
    if (property === 'value' && value && value !== '-')
      value = parseFloat(value)
    newState.customAdjustments[index][property] = value;
    this.setState(newState);
  }

  handleRemoveCustomAdjustment(indexToDelete) {
    const newState = {...this.state};
    newState.customAdjustmentsCount = this.state.customAdjustmentsCount - 1;
    let obj = {...this.state.customAdjustments};
    delete obj[indexToDelete];
    newState.customAdjustments = Object.keys(obj).reduce((acc, key, index) => {
      acc[index] = obj[key];
      return acc;
    }, {});
    this.setState(newState, () => this.updateTotalPrice());
  }

  handleStandardDeliveryRateChange = (event) => {
    let rate = isString(this.props.item?.rate) ? this.props.item?.rate?.replace("%", "").replace(this.props.currency, '').replace(` / ${this.state.unit}`, "") : this.props.item?.rate
    rate = parseFloat(rate) * 0.01;

    if(this.checkForChangingStandardDeliveryRate() && event.target.value) {
      const newState = {...this.state};
      newState.levyDetails.standardDeliveryRate = parseFloat(event.target.value);
      newState.levyDetails.adjustedFormula = `Commodity Levy Percentage * $${newState.levyDetails.standardDeliveryRate} * Total Distance * Tonnage)`;
      newState.levyDetails.adjusted = (rate * newState.levyDetails.standardDeliveryRate * get(this.props.item, 'totalDistance', 0) * get(this.props.item, 'tonnage', 0)).toFixed(2);
      newState.levyDetails.totalLevy = (-1 * (parseFloat(newState.levyDetails.standard) - parseFloat(newState.levyDetails.adjusted))).toFixed(2);
      newState.levyDetails.finalLevy = newState.levyDetails.totalLevy;
      this.setState(newState);
    }
  }

  updateTotalPrice() {
    let obj = {...this.state.customAdjustments};
    const customAdjustmentTotal = Object.values(obj).reduce((acc, item) => acc + (!get(item, 'value') || get(item, 'value') === '-' ? 0 : get(item, 'value')), 0);
    let price = parseFloat(this.state.originalTotalPrice + customAdjustmentTotal).toFixed(2)
    this.setState({totalPrice: price});
  }

  handleChangeForSelectedMovement = () => {
    const {dispatch} = this.props;
    let adjustments = {
      "standardDeliveryRate": this.state.levyDetails.standardDeliveryRate

    }
    let item = find(this.props.selectedMovementItems, {itemId: get(this.props.item, 'freightMovementId')})
    dispatch(isLoading('nonExistentComponent'));
    this.props.handleLevyStandardDeliveryRateChange(item, adjustments);
    this.handleClose();
    this.props.closeDrawer();
  }

  handleChangeFortAllMovementsInThisInvoice = () => {
    let adjustments = {
      "standardDeliveryRate": this.state.levyDetails.standardDeliveryRate,
    }
    const { dispatch } = this.props;
    dispatch(isLoading('nonExistentComponent'));
    forEach(this.props.selectedMovementItems, item => {
      this.props.handleLevyStandardDeliveryRateChange(item, adjustments);
    })
    this.handleClose();
    this.props.closeDrawer();
  }
  handleSave() {
    let adjustments = Object.values(this.state.customAdjustments)
    const isGrainLevyItem = get(this.props.item, 'itemType') === GRAIN_LEVY_ITEM_TYPE;
    if (this.checkForChangingStandardDeliveryRate() && isGrainLevyItem && this.state.levyDetails.standardDeliveryRate != get(this.props.item, 'standardDeliveryRate')) {
      this.setState({openWarningDialog: true})
    } else {
      const isAdjustmentsValid = adjustments.every(element => !isNaN(parseFloat(element.value)));
      if (isAdjustmentsValid) {
        this.props.handleCustomAdjustments(this.props.item, {'customAdjustments': Object.values(this.state.customAdjustments)});
        this.props.closeDrawer();
      }
      else
        alertifyjs.error("Invalid Adjustment", 4);
    }
  }

  handleClose = ()  => this.setState({openWarningDialog: false})

  render() {
    const showSpread = this.state.countryConfig?.invoicing?.spreadAdjustment
    const isLevyItem = this.isLevyItem();
    const isEprItem = this.isEprItem();
    const isCarryItem = this.isCarryItem();
    const commodityRateLabel = get(this.props.item, 'multiplier') === 'tonnage' ? 'Commodity Levy Rate' : 'Commodity Levy Percentage';
    const isChemicalItem = get(this.props.item, 'isChemicalItem');
    const isBlendingFees = get(this.props.item, 'isBlendingFees');
    return (
      <React.Fragment>
        {isLevyItem &&
        <div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.saleValue'
              label='Sale Value'
              value={this.state.levyDetails.saleValue}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.tonnage'
              label='Tonnage'
              value={this.state.levyDetails.tonnage}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.commodityLevy'
              label={commodityRateLabel}
              value={this.state.levyDetails.commodityLevy}
              disabled
            />
          </div>
          {!includes([null, undefined], this.state.levyDetails.totalDistance) &&
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.totalDistance'
              label='Total Distance'
              value={this.state.levyDetails.totalDistance}
              disabled
            />
          </div>
          }
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.standard'
              label={`Standard Levy (${this.state.levyDetails.standardFormula})`}
              value={this.state.levyDetails.standard}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.standard'
              label='Standard Delivery Rate'
              value={this.state.levyDetails.standardDeliveryRate}
              onChange={this.handleStandardDeliveryRateChange}
              disabled={get(this.props, 'detailsView') || !this.checkForChangingStandardDeliveryRate() || !get(this.props.item, 'levyAdjustmentIncluded')}
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.adjusted'
              label={`Levy Adjustments (${this.state.levyDetails.adjustedFormula})`}
              value={this.state.levyDetails.adjusted}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.totalLevy'
              label='Total Levy'
              value={this.state.levyDetails.totalLevy}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='levyDetails.finalLevy'
              label='Final Levy'
              value={this.state.levyDetails.finalLevy}
              disabled
            />
          </div>
        </div>
        }
        {isEprItem &&
        <div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='eprDetails.rate'
              label='EPR Rate'
              value={this.state.eprDetails.rate}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='eprDetails.tonnage'
              label='Tonnage'
              value={this.state.eprDetails.tonnage}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='eprDetails.totalEpr'
              label={`Total EPR (Ex ${getCountryLabel('gst')}) (EPR Rate * Tonnage)`}
              value={this.state.eprDetails.totalEpr}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='eprDetails.finalEpr'
              label={`Final EPR (Ex ${getCountryLabel('gst')})`}
              value={this.state.eprDetails.finalEpr}
              disabled
            />
          </div>
        </div>
        }
        {isCarryItem &&
        <div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='carryDetails.carryRatePerPeriod'
              label={`Carry Rate (Per Carry Period Per ${this.state.unit})`}
              value={this.state.carryDetails.ratePerCarryPeriod}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='carryDetails.carryPeriods'
              label='Carry Period'
              value={this.state.carryDetails.carryPeriod}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='carryDetails.numberOfCarryPeriods'
              label='Number Of Carry Periods'
              value={this.state.carryDetails.numberOfCarryPeriods}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='carryDetails.rate'
              label={`Carry Rate (Per ${this.state.unit}) (Carry Rate * Number Of Carry Periods)`}
              value={this.state.carryDetails.rate}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='carryDetails.tonnage'
              label='Tonnage'
              value={this.state.carryDetails.tonnage}
              disabled
            />
          </div>
          <div style={{marginTop: '15px'}}>
            <CommonTextField
              id='carryDetails.totalCarry'
              label={`Total Carry (Ex ${getCountryLabel('gst')}) (Total Carry Rate * Tonnage)`}
              value={this.state.carryDetails.totalCarry}
              disabled
            />
          </div>
        </div>
        }
        {!isLevyItem && !isEprItem && !isCarryItem && !isChemicalItem && !isBlendingFees &&
          <div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='contractPrice'
                label={`Contract Price (Per ${this.state.unit})`}
                value={this.state.contractPrice}
                disabled
              />
            </div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='locationDifferential'
                label={`Location Differential (LD) (per ${this.state.unit})`}
                value={this.state.locationDifferential}
                disabled
              />
            </div>
            {
              showSpread &&
                <div style={{marginTop: '15px'}}>
                  <CommonTextField
                    id='spreadPrice'
                    label={`Spread (Per ${this.state.unit})`}
                    value={this.state.spreadPrice}
                    disabled
                  />
                </div>
            }
            {
              has(this.props, 'item.additionalCost') &&
                <div style={{marginTop: '15px'}}>
                  <CommonTextField
                    label={`Additional Cost (Per ${this.state.unit})`}
                    value={this.props.item.additionalCost}
                    disabled
                  />
                </div>
            }
            {!includes([undefined, null], this.state.shrinkage) &&
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='shrinkage'
                label={`Shrinkage % (per ${this.state.unit})`}
                value={this.state.shrinkage}
                onBlur={this.handleShrinkageChange}
                disabled={get(this.props, 'detailsView')}
              />
            </div>
            }
            {!includes([undefined, null], this.state.receivalFees) &&
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='receivalFees'
                label={`Receival fees (per ${this.state.unit})`}
                value={this.state.receivalFees}
                onBlur={this.handleReceivalFeesChange}
                disabled={get(this.props, 'detailsView')}
              />
            </div>
            }
            {has(this.props, 'item.admixtureAdjustment') &&
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='admixtureAdjustment'
                label='Admixture Adjustment'
                value={this.state.admixtureAdjustment}
                disabled
              />
            </div>
            }
            {has(this.props, 'item.oilAdjustment') &&
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='oilAdjustment'
                label='Oil Adjustment'
                value={this.state.oilAdjustment}
                disabled
              />
            </div>
            }
            {has(this.props, 'item.avgAdmix') &&
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='avgAdmix'
                label='Average Admix'
                value={this.state.avgAdmix}
                disabled
              />
            </div>
            }
            {has(this.props, 'item.avgOil') &&
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='avgOil'
                label='Average Oil'
                value={this.state.avgOil}
                disabled
              />
            </div>
            }
            {
              map(pickBy(this.props?.item?.specs, val => val.price), (val, spec) => (
                <div key={`${spec}-${val.value}`} style={{marginTop: '15px'}}>
                  <CommonTextField
                    id={spec}
                    label={`${spec.toUpperCase()} Adjustment`}
                    value={parseFloat(val.value).toFixed(2)}
                    disabled
                  />
                </div>
              ))
            }
            {this.state.customAdjustmentsCount > 0 &&
             <div style={{marginTop: '15px'}}>
              <Typography variant="title">Custom Adjustments</Typography>
             </div>
            }
            {this.state.customAdjustmentsCount > 0 &&
              Array.from({ length: this.state.customAdjustmentsCount }, (_, index) => (
                <div>
                  <div className="col-sm-5" style={{marginTop: '18px'}}>
                    <CommonTextField
                      id={`adjustment${index}`}
                      placeholder='Enter Label'
                      value={get(this.state.customAdjustments, `${index}.label`)}
                      onChange={event => this.handleCustomAdjustmentChange(event, index, 'label')}
                      disabled={get(this.props, 'detailsView')}
                    />
                  </div>
                  <div className="col-sm-5" style={{marginTop: '18px'}}>
                    <CommonTextField
                      id={`adjustment${index}`}
                      placeholder='Enter Value'
                      value={get(this.state.customAdjustments, `${index}.value`)}
                      onChange={event => this.handleCustomAdjustmentChange(event, index, 'value')}
                      onKeyDown={event => decimalFilter(event, 2, 99999.99)}
                      onBlur={() => this.updateTotalPrice()}
                      disabled={get(this.props, 'detailsView')}
                    />
                  </div>
                  {!get(this.props, 'detailsView') &&
                    <div className="col-sm-2" style={{marginTop: '20px'}}>
                      <Button
                        style={{position: 'absolute'}}
                        mini
                        variant='fab'
                        secondary={true}
                        onClick={() => this.handleRemoveCustomAdjustment(index)}
                        disabled={get(this.props, 'detailsView')}
                      >
                        <ContentRemove />
                      </Button>
                  </div>
                  }
                </div>
              ))
            }
            {!get(this.props, 'detailsView') && !get(this.props.item, 'isBlended') &&
              <div style={{marginTop: '15px'}}>
                <AddButton
                  label='Add Adjustments'
                  id='addAdjustments'
                  onClick={() => this.setState({customAdjustmentsCount: this.state.customAdjustmentsCount + 1})}
                  style={{float: 'left', marginTop: '15px', marginBottom: '15px'}}
                />
              </div>
            }
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='totalPrice'
                label={this.getPriceLabel()}
                value={this.state.totalPrice}
                style={{marginTop: '15px'}}
                disabled
              />
            </div>
          </div>
        }
        {
          isBlendingFees &&
          <div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='blendingFees'
                label={`Blending Fees (Per ${get(this.props.item, 'unit')})`}
                value={get(this.props.item, 'blendingFees')}
                disabled
              />
            </div>
          </div>
        }
        {
          isChemicalItem &&
          <div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='applicationRate'
                label={`Application Rate (${get(this.props.item, 'chemicalUnit')} Per ${get(this.props.item, 'loadUnit')})`}
                value={get(this.props.item, 'applicationRate')}
                disabled
              />
            </div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='pricePerUnit'
                label={`Price per ${get(this.props.item, 'chemicalUnit')}`}
                value={get(this.props.item, 'chemicalRatePerUnit')}
                disabled
              />
            </div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='mixingFees'
                label={`Application Fees Per ${get(this.props.item, 'loadUnit')}`}
                value={get(this.props.item, 'mixingFees')}
                disabled
              />
            </div>
            <div style={{marginTop: '15px'}}>
              <CommonTextField
                id='appliedOnTonnage'
                label={`Applied On Tonnage (${get(this.props.item, 'loadUnit')})`}
                value={get(this.props.item, 'tonnage')}
                disabled
              />
            </div>
          </div>
        }
        {this.state.openWarningDialog &&
          <React.Fragment>
            <Dialog onClose={this.handleClose} open={this.state.openWarningDialog}>
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
              Warning
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={this.handleClose}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
              <CloseIcon />
            </IconButton>
            <DialogContent dividers>
              <Typography>
                Change freight rate of all the movements in this invoice to {this.state.levyDetails.standardDeliveryRate}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleChangeFortAllMovementsInThisInvoice} color="primary">
                Change for all movements
              </Button>
              <Button onClick={this.handleChangeForSelectedMovement} color="primary">
                Change for this movement only
              </Button>
            </DialogActions>
            </Dialog>
          </React.Fragment>
        }
        <div style={{marginTop: '20px', marginLeft: 'auto', float: 'right' }}>
          <CommonButton
            variant="outlined"
            label="Cancel"
            type="button"
            default
            onClick={this.props.closeDrawer}
          />
          <CommonButton
            variant="outlined"
            label="Save"
            type="button"
            default
            onClick={() => this.handleSave()}
            disabled={get(this.props, 'detailsView')}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default connect()(AdjustedPrices);

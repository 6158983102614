import React from 'react';
import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import '../common/subTab.scss';
import { Button, Dialog, DialogActions, DialogContent, InputAdornment, Tab, Tabs } from '@mui/material';
import { forceStopLoader, isLoading, setBreadcrumbs, setHeaderText } from '../../actions/main';
import { isEmpty, map, get, cloneDeep, set, isEqual, includes } from 'lodash';
import { getCountryCurrency, isSystemCompany, toDateFormat } from '../../common/utils';
import AddButton from '../common/AddButton';
import { getCompanyGroups } from '../../actions/api/companies';
import FeeForm from './FeeForm';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import { getCommoditiesWithGrades } from '../../actions/api/commodities';
import alertifyjs from 'alertifyjs';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CommonTextField from '../common/CommonTextField';
import CollapsibleTable from '../CollapsibleTable';
import { PRIMARY_COLOR_GREEN } from '../../common/constants';
import ApplicationRateForm from './ApplicationRateForm';
import GenericTable from '../GenericTable';

const STANDARD = 'standard';
const EXCEPTION = 'exception';
const APPLICATION_RATES = 'application_rates';
const STANDARD_FEE_COLUMNS = [
  { key: 'siteName', header: 'Site', className: 'large', cellStyle: {width: '12%'} },
  { key: 'type', header: 'Fees Type', className: 'small', cellStyle: {width: '12%'} },
  { key: 'commodityName', header: 'Commodity', className: 'medium', cellStyle: {width: '12%'} },
  { key: 'gradeName', header: 'Grade', className: 'medium', cellStyle: {width: '12%'} },
  { key: 'season', header: 'Season', className: 'small', cellStyle: {width: '12%'} },
  { key: 'startDate', header: 'Start Date', className: 'medium', formatter: fees => fees.startDate ? toDateFormat(fees.startDate) : undefined, cellStyle: {width: '12%'} },
  { key: 'endDate', header: 'End Date', className: 'medium', formatter: fees => fees.endDate && fees.endDate.substr(0, 4) !== "9999" ? toDateFormat(fees.endDate) : undefined, cellStyle: {width: '12%'} },
  { header: 'Fees', className: 'small', formatter: fees => `${getCountryCurrency()} ${(fees.fees).toFixed(2)} / ${fees.priceUnit}`, cellStyle: {width: '12%'}},
];

const EXCEPTION_FEE_COLUMNS = [
  { key: 'siteName', header: 'Site', className: 'large', cellStyle: {width: '12%'} },
  { key: 'type', header: 'Fees Type', className: 'small', cellStyle: {width: '12%'} },
  {
    key: 'customerName',
    urlKey: 'groupUrl',
    header: "Customer/Group Name",
    className: 'small',
    fieldType: 'url-conditional',
    link: true, cellStyle: {width: '12%'}
  },
  { key: 'commodityName', header: 'Commodity', className: 'medium', cellStyle: {width: '12%'} },
  { key: 'gradeName', header: 'Grade', className: 'medium', cellStyle: {width: '12%'} },
  { key: 'season', header: 'Season', className: 'small', cellStyle: {width: '12%'} },
  { key: 'startDate', header: 'Start Date', className: 'medium', formatter: fees => fees.startDate ? toDateFormat(fees.startDate) : undefined, cellStyle: {width: '12%'} },
  { key: 'endDate', header: 'End Date', className: 'medium', formatter: fees => fees.endDate ? toDateFormat(fees.endDate) : undefined, cellStyle: {width: '12%'} },
  { header: 'Fees', className: 'small', formatter: fees => `${getCountryCurrency()} ${(fees.fees).toFixed(2)} / ${fees.priceUnit}`, cellStyle: {width: '12%'} },
];

const ACTION_OPTIONS = [
  { key: 'edit', text: 'Edit' },
  { key: 'delete', text: 'Delete' },
];

const APPLICATION_RATES_COLUMNS = [
  {key: 'applicationName', header: 'Application', className: 'medium'},
  {key: 'rateDisplay', header: 'Rate', className: 'medium'},
]


class CommonFees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      selected: null,
      filters: {},
      allCompanies: [],
      filterValues: {
        site_id: "allLocations",
        type: "allTypes",
        commodity_id: "allCommodities",
      },
      sites: [],
      groupEditPopup: false,
      selectedGroup: null,
      updatedGroupFees: {
        value: undefined,
        errors: [],
      }
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.commodities))
      this.props.dispatch(getCommoditiesWithGrades());
    if (isEmpty(this.props.companyGroups))
      this.props.dispatch(getCompanyGroups(this.props.userCompanyId))
    if (isEmpty(this.state.allCompanies)) {
      APIService.companies()
        .appendToUrl('directory/names/')
        .get()
        .then(companyData => this.setState({ allCompanies: companyData }));
    }
    if (isEmpty(this.state.sites)) {
      APIService.companies(this.props.companyId)
        .company_sites()
        .appendToUrl('minimal/')
        .get()
        .then(sites => {
          let allSites = [{id: 'allLocations', name: 'All Locations'}, ...sites]
          this.setState({ sites: map(allSites, site => ({ id: site.id, name: site.name })) })
        });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(prevState.filterValues, this.state.filterValues))
      this.props.refreshData(this.props.type, this.state.filterValues);
  }

  onAdd = () => {
    this.setState({ openForm: true });
  };

  onCloseForm = refresh => {
    this.setState({ 
      openForm: false,
      selected: null, 
      filterValues: {
        site_id: "allLocations",
        type: "allTypes",
        commodity_id: "allCommodities",
      }}, () => {
      if (refresh)
        this.props.refreshData(this.props.type);
    });
  };

  onFiltersChange = (value, id) => {
    const filterValues = cloneDeep(this.state.filterValues);
    set(filterValues, id, value)
    this.setState({filterValues: filterValues});
  }

  onDefaultClick = item => {
    if (this.props.hasWriteAccess)
      this.setState({ selected: item, openForm: true });
  };

  onOptionClick = (rowNum, key, feeId, fee) => {
    if (!feeId)
      return;

    if (key === 'edit')
      this.onDefaultClick(fee);

    if (key === 'delete') {
      this.props.onOptionClick(rowNum, key, feeId, fee);
    }
  }

  handleGroupOptionClick = (event, index, key, value, obj) => {
    event.stopPropagation()
    if (key === 'edit') {
      const newState = {...this.state};
      newState.groupEditPopup = true;
      newState.selectedGroup = obj;
      newState.updatedGroupFees.value = get(obj, 'perUnit')
      this.setState(newState);
    }
    else if (key === 'delete') {
      alertifyjs.confirm(
        'Warning',
        'Do you wish to continue?',
        () => {
          const feesIds = obj.items.map(item => item.id);
          APIService.farms()
            .appendToUrl('fees/')
            .delete(null, null, `?fees_ids=${feesIds.join(', ')}`)
            .then(() => {
              this.props.refreshData(this.props.type);
              alertifyjs.success("Successfully Deleted!", 3);
            })
        },
        () => { },
      );
    }
  }

  updateGroupFees(value) {
    const newState = {...this.state};
    if (!value)
      newState.updatedGroupFees.errors = ["This field is required"]
    else {
      newState.updatedGroupFees.value = value;
      newState.updatedGroupFees.errors = [];
    }
    this.setState(newState)
  }

  handleGroupUpdate() {
    if (!isEmpty(this.state.updatedGroupFees.errors))
      return
    this.setState({groupEditPopup: false});
    const feesIds = this.state.selectedGroup.items.map(item => item.id);
    APIService.farms()
      .appendToUrl('fees/')
      .put({'fees_ids': feesIds, 'per_unit': this.state.updatedGroupFees.value})
      .then(() => this.props.refreshData(this.props.type))
  }

  closeGroupEditPopup() {
    const newState = {...this.state};
    newState.groupEditPopup = false;
    newState.updatedGroupFees.value = null;
    newState.updatedGroupFees.errors = [];
    newState.selectedGroup = null
    this.setState(newState)
  }

  clearFilters = () => {
    this.setState({filterValues: {
      site_id: "allLocations",
      type: "allTypes",
      commodity_id: "allCommodities",
    }})
  }

  render() {
    const { type, data, columns, companyId, companyGroups, hasWriteAccess } = this.props;
    const { openForm, selected, allCompanies, sites, filterValues, groupEditPopup, updatedGroupFees } = this.state;
    let summaryData = [
      {key: "sites", label: "Sites", className: 'large', toolTipKey: 'groupSites'},
      {key: "type", label: "Fees Type", className: 'small' },
      {key: "commodities", label: "Commodities", className: 'medium', toolTipKey: 'groupCommodities' },
      {key: "grades", label: "Grades", className: 'medium', toolTipKey: 'groupGrades' },
      {key: "seasons", label: "Seasons", className: 'small', toolTipKey: 'groupSeasons' },
      {key: 'startDate', label: 'Start Date', className: 'medium' },
      {key: 'endDate', label: 'End Date', className: 'medium' },
      {label: "Fees", className: 'small', formatter: obj => `${getCountryCurrency()} ${(obj.perUnit).toFixed(2)} / ${obj.priceUnit}`},
    ]
    if (hasWriteAccess) {
      summaryData.push({key: "actions", label: "Actions"});
    }
    if (type === EXCEPTION)
      summaryData.splice(2, 0, {key:"customerGroupName", label: "Customer/Group Name", className: 'small'})
    const allFees = !isEmpty(data) ? [].concat(...data.map(obj => obj.items)) : [];
    const feesType = ['Storage Fees', 'Outload Fees', 'Inload Fees', 'Transfer Fees', 'Throughput Inload Fees', 'Throughput Outload Fees']
    let feesOptions = map(feesType, feeType => ({ id: feeType, name: feeType }))
    feesOptions.splice(0, 0, {id: "allTypes", name: "All Types"});
    let allCommodities = map(this.props.commodities, commodity => ({ id: commodity.id, name: commodity.displayName }))
    if (allCommodities)
      allCommodities.splice(0, 0, {id: "allCommodities", name: "All Commodities"});
    return (
      <div>
        <div>
          <span style={{ display: 'flow-root' }}>
            <div className='col-xs-9' style={{paddingLeft: '0px', paddingRight: '0px', marginTop: '10px'}}>
              <div className='col-xs-3' style={{marginBottom: '8px', marginTop: '8px', paddingLeft: '0px'}}>
                <CommonAutoSelect
                  id='site_id'
                  label='Sites'
                  items={sites}
                  selectConfig={{ text: 'name', value: 'id' }}
                  value={get(filterValues, 'site_id')}
                  onChange={this.onFiltersChange}
                  variant="outlined"
                  size="small"
                  notClearable
                />
              </div>
              <div className='col-xs-3' style={{marginBottom: '8px', marginTop: '8px', paddingLeft: '0px'}}>
                <CommonAutoSelect
                  id='type'
                  label='Fees Type'
                  items={feesOptions}
                  selectConfig={{ text: 'name', value: 'id' }}
                  value={get(filterValues, 'type')}
                  onChange={this.onFiltersChange}
                  variant="outlined"
                  size="small"
                  notClearable
                />
              </div>
              <div className='col-xs-3' style={{marginBottom: '8px', marginTop: '8px', paddingLeft: '0px'}}>
                <CommonAutoSelect
                  id='commodity_id'
                  label='Commodity'
                  items={allCommodities}
                  selectConfig={{ text: 'displayName', value: 'id' }}
                  value={get(filterValues, 'commodity_id')}
                  onChange={this.onFiltersChange}
                  variant="outlined"
                  size="small"
                  notClearable
                />
              </div>
              <div className='col-xs-3' style={{marginBottom: '8px', marginTop: '15px', paddingLeft: '0px'}}>
                <a onClick={this.clearFilters} style={{ color: PRIMARY_COLOR_GREEN, cursor: 'pointer'}}> Clear </a>
              </div>
            </div>
            {hasWriteAccess &&
              <div className='col-xs-3' style={{marginBottom: '8px', marginTop: '8px', paddingRight: '0px'}}>
                <AddButton style={{marginTop: '12px'}} label='Fees' app='shrinkages' onClick={this.onAdd}/>
              </div>
            }
          </span>
        </div>
        <CollapsibleTable
          rowHeaders={summaryData}
          rowData={data}
          itemColumns={columns}
          hasActions={hasWriteAccess}
          optionItems={hasWriteAccess ? ACTION_OPTIONS : undefined}
          onOptionClick={this.onOptionClick}
          onDefaultClick={this.onDefaultClick}
          rowActionOptions={hasWriteAccess ? ACTION_OPTIONS : undefined}
          handleRowOptionClick={this.handleGroupOptionClick}
          user={this.props.user}
        />
        {
          openForm &&
          <FeeForm
            type={type}
            companyId={companyId}
            open={openForm}
            onClose={this.onCloseForm}
            selected={selected}
            type_options={feesType}
            allFees={allFees}
            groups={companyGroups}
            companies={allCompanies}
            selectedCompany={this.props.selectedCompany}
          />
        }
        {
          groupEditPopup &&
          <Dialog
            open={groupEditPopup}
            onClose={() => this.closeGroupEditPopup()}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitleWithCloseIcon 
              onClose={() => this.closeGroupEditPopup()}
              closeButtonStyle={{ marginTop: '0px' }}
              id='form-dialog-title'>
                Update Group Fees
            </DialogTitleWithCloseIcon>
            <DialogContent>
              <CommonTextField
                id='perUnit'
                label={"Fee per MT"}
                value={updatedGroupFees.value}
                helperText={updatedGroupFees.errors[0]}
                onChange={event => this.updateGroupFees(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {getCountryCurrency()}
                    </InputAdornment>
                  ),
                }}
                />
            </DialogContent>
            <DialogActions>
              <Button
                type='button'
                onClick={() => this.closeGroupEditPopup()}
                variant='outlined'>
                Cancel
              </Button>
              <Button type='button' onClick={() => this.handleGroupUpdate()} color='primary' variant='contained'>
                  OK
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    )
  }
}

class StandardFees extends React.Component {
  render() {
    return (
      <CommonFees {...this.props} columns={STANDARD_FEE_COLUMNS} type={STANDARD} />
    );
  }
}

class ExceptionFees extends React.Component {
  render() {
    return (
      <CommonFees {...this.props} columns={EXCEPTION_FEE_COLUMNS} type={EXCEPTION} />
    );
  }
}

class ApplicationRates extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      selected: undefined,
      applicationRates: []
    }
  }

  componentDidMount() {
    this.fetchApplicationRates();
  }

  fetchApplicationRates() {
    this.props.dispatch(isLoading());
    APIService.companies(this.props.companyId)
      .appendToUrl('application-rates/')
      .get()
      .then(resp => this.setState({applicationRates: resp}, () => this.props.dispatch(forceStopLoader())))
  }

  onAdd = () => {
    this.setState({ openForm: true });
  };

  refreshData = () => this.fetchApplicationRates();

  onDefaultClick = item => {
    if (this.props.hasWriteAccess)
      this.setState({ selected: item, openForm: true });
  };

  deleteApplicationRate(applicationRateId) {
    APIService.companies()
      .appendToUrl(`application-rates/${applicationRateId}/`)
      .delete()
      .then(() => this.refreshData())
  }

  onOptionClick = (rowNum, key, applicationRateId) => {
    if (!applicationRateId)
      return;

    if (key === 'delete') {
      alertifyjs.confirm(
        'Warning',
        `Are you sure you want to delete this Application Rate?`,
        () => {this.deleteApplicationRate(applicationRateId)},
        () => {},
      ).set({'reverseButtons': true})
    }
  };

  render() {
    const {hasWriteAccess, companyId} = this.props;
    const {openForm, selected, applicationRates} = this.state;
    return (
      <div>
        <div style={{ position: 'relative', paddingTop: '10px' }}>
          <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
            {hasWriteAccess &&
              <div style={{marginBottom: '8px', marginTop: '8px', paddingRight: '0px'}}>
                <AddButton label='Application Rate' onClick={this.onAdd}/>
              </div>
            }
          </div>
          <GenericTable
            columns={APPLICATION_RATES_COLUMNS}
            items={applicationRates}
            editColumnClass="xsmall"
            optionsItems={[{ key: 'delete', text: 'Delete' }]}
            handleOptionClick={this.onOptionClick}
            handleDefaultCellClick={this.onDefaultClick}
            hasActions={hasWriteAccess}
            hideActionLabel
            actionStyles={{width: '6%', textAlign: 'center', paddingRight: '0px !important'}}
            actionsClass='none'
          />
        </div>
        {
          openForm &&
          <ApplicationRateForm
            companyId={companyId}
            open={openForm}
            onClose={() => this.setState({openForm: false, selected: undefined})}
            selected={selected}
            selectedCompany={this.props.selectedCompany}
            refreshData={this.refreshData}
          />
        }
      </div>
    )
  }
}

const VALUE_TAB_MAPPING = {
  0: STANDARD,
  1: EXCEPTION,
  2: APPLICATION_RATES
};

const STANDARD_EXCEPTION_FEES_TABS = [STANDARD, EXCEPTION];

class Fees extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab: STANDARD,
      standard: [],
      exception: [],
      applicationRates: [],
      exceptionFetched: false,
      standardFetched: false,
      applicationRatesFetched: false,
    }
    this.refreshData = this.refreshData.bind(this);
  }

  componentDidMount() {
    this.setHeaderAndBreadcrumbs();
    this.getFeeData();
  }

  getCompanyDetails() {
    const { companyId, user, selectedCompany } = this.props;
    if (user.companyId === companyId)
      return user.company;
    if (selectedCompany && companyId == selectedCompany.id)
      return selectedCompany;
  }

  getBreadcrumbs(company) {
    company = company || this.getCompanyDetails();
    if (company) {
      return [
        { text: 'Companies', route: '/companies' },
        { text: company.name, route: '/companies/' + company.id + '/details' },
        { text: 'Fees' }
      ];
    }
  }

  setHeaderAndBreadcrumbs() {
    const company = this.getCompanyDetails();
    if (company) {
      const { dispatch } = this.props;
      dispatch(setHeaderText(company.name));
      dispatch(setBreadcrumbs(this.getBreadcrumbs()));
    }
  }

  getFeeData(filters=null) {
    const { companyId, dispatch } = this.props;
    const { tab } = this.state;
    let queryParams = {
      fee_type: tab,
      site__company_id__in: companyId
    }
    if (filters) {
      if (get(filters, 'site_id') !== "allLocations")
        queryParams['site_id__in'] = get(filters, 'site_id');
      if (get(filters, 'type') !== "allTypes")
        queryParams['type__in'] = get(filters, 'type');
      if (get(filters, 'commodity_id') !== "allCommodities")
        queryParams['commodity_id__in'] = get(filters, 'commodity_id');
    }
    if (!this.state[`${tab}Fetched`]) {
      APIService.farms().appendToUrl('fees/')
                .get(null, null, queryParams)
                .then(data => {
                  this.setState(
                    { [tab]: data, [`${tab}Fetched`]: true },
                    () => dispatch(forceStopLoader())
                  );
                });
    }
  }

  refreshData(type, filters=null) {
    if (type == 'exception')
      this.setState({ exception: [], exceptionFetched: false }, () => this.getFeeData(filters));
    if (type == 'standard')
      this.setState({ standard: [], standardFetched: false }, () => this.getFeeData(filters));
  }

  onTabChanges = (event, value) => {
    this.setState({ tab: get(VALUE_TAB_MAPPING, value) }, () => {
      if (includes(STANDARD_EXCEPTION_FEES_TABS, this.state.tab))
        this.getFeeData();
    });
  };

  hasWriteAccess() {
    const { companyId, company, user } = this.props;
    return isSystemCompany() || user.companyId === companyId || !get(company, 'transactionParticipation');
  }

  deleteFee(feeId) {
    alertifyjs.confirm(
      'Warning',
      'Do you wish to continue?',
      () => {
        APIService.farms().appendToUrl(`fees/${feeId}/`).delete().then(() => {
          alertifyjs.success('Successfully Deleted!');
          this.refreshData(this.state.tab)
        });
      },
      () => { },
    );
  }

  onOptionClick = (rowNum, key, feeId) => {
    if (!feeId)
      return;

    if (key === 'delete')
      this.deleteFee(feeId);
  };

  render() {
    const { companyId } = this.props;
    const { tab, standard, exception } = this.state;
    const hasWriteAccess = this.hasWriteAccess();
    const TAB_VALUE_MAPPING = {
      [STANDARD]: 0,
      [EXCEPTION]: 1,
      [APPLICATION_RATES]: 2
    }
    return (
      <div className="subTab">
        <Tabs indicatorColor="primary" className="subTab-header" value={get(TAB_VALUE_MAPPING, tab)} onChange={this.onTabChanges}>
          <Tab label="Standard" className={tab !== STANDARD ? 'unselected-subtab' : ''} />
          <Tab label="Exception" className={tab !== EXCEPTION ? 'unselected-subtab' : ''} />
          <Tab label="Application Rates" className={tab !== APPLICATION_RATES ? 'unselected-subtab' : ''} />
        </Tabs>
        <div className="subTab-container">
          {
            tab === STANDARD &&
            <StandardFees
              {...this.props}
              data={standard}
              onOptionClick={this.onOptionClick}
              hasWriteAccess={hasWriteAccess}
              companyId={companyId}
              refreshData={this.refreshData}
            />
          }
          {
            tab === EXCEPTION &&
            <ExceptionFees
              {...this.props}
              data={exception}
              onOptionClick={this.onOptionClick}
              hasWriteAccess={hasWriteAccess}
              companyId={companyId}
              refreshData={this.refreshData}
            />
          }
          {
            tab === APPLICATION_RATES &&
            <ApplicationRates
              {...this.props}
              data={exception}
              onOptionClick={this.onOptionClick}
              hasWriteAccess={hasWriteAccess}
              companyId={companyId}
              refreshData={this.refreshData}
            />
          }
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    selectedCompany: state.companies.companies.selectedCompany,
    breadcrumbs: state.main.breadcrumbs,
    companyGroups: state.companies.companies.companyGroups,
    userCompanyId: state.main.user.user.companyId,
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(Fees);

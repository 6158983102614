import React from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import {DialogTitleWithCloseIcon} from '../common/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import {
  deleteSlot, deleteForwardSiblingsSlots, deleteAllSiblingsSlots
} from '../../actions/api/company-sites';
import {
  EMPTY
} from '../../common/constants';
import {isLoading, forceStopLoader} from '../../actions/main';

class DeleteSlot extends React.Component {
  constructor(props) {
    super(props);
    this.THIS_SLOT = 'current';
    this.THIS_AND_FOLLOWING_SLOTS = 'forward';
    this.ALL_SLOTS = 'all';
    this.state = {
      deleteFrom: this.THIS_SLOT
    };

    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOptionChange(event, value) {
    this.setState({deleteFrom: value});
  }

  handleSubmit() {
    const { dispatch } = this.props;
    if(this.props.siteId && this.props.slotId) {
      dispatch(isLoading('loaderDom'));
      const callback = () => {
        this.props.onDelete();
        dispatch(forceStopLoader());
      };
      if(this.state.deleteFrom === this.THIS_AND_FOLLOWING_SLOTS)
        dispatch(deleteForwardSiblingsSlots(
          this.props.siteId, this.props.slotId, callback, this.props.onDeleteFailure
        ));
      else if(this.state.deleteFrom === this.ALL_SLOTS)
        dispatch(deleteAllSiblingsSlots(
          this.props.siteId, this.props.slotId, callback, this.props.onDeleteFailure
        ));
      else if(this.state.deleteFrom === this.THIS_SLOT)
        dispatch(deleteSlot(
          this.props.siteId, this.props.slotId, callback
        ));
    }
  }

  render() {
    return this.props.show ?
           (
             <Dialog open={this.props.show} onClose={this.props.handleClose} aria-labelledby="form-dialog-title">
               <DialogTitleWithCloseIcon> Delete recurring event </DialogTitleWithCloseIcon>
               <DialogContent>
                 <div className="col-sm-12 no-left-padding">
                   <form onSubmit={this.handleSubmit}>
                     <div className="col-sm-12 no-left-padding">
                       <RadioGroup
                         name="deleteFrom"
                         value={this.state.deleteFrom}
                         onChange={this.handleOptionChange}
                       >
                         <FormControlLabel
                           value={this.THIS_SLOT}
                           control={<Radio color="primary" />}
                           label="This slot"
                         />
                         <FormControlLabel
                           value={this.THIS_AND_FOLLOWING_SLOTS}
                           control={<Radio color="primary" />}
                           label="This and following slots"
                         />
                         <FormControlLabel
                           value={this.ALL_SLOTS}
                           control={<Radio color="primary" />}
                           label="All slots"
                         />
                       </RadioGroup>
                     </div>
                   </form>
                 </div>
               </DialogContent>
               <DialogActions>
                 <Button onClick={this.props.handleClose}>
                   Cancel
                 </Button>
                 <Button onClick={this.handleSubmit} color="primary">
                   Ok
                 </Button>
               </DialogActions>
             </Dialog>
           ) :
           EMPTY;
  }
}

export default connect()(DeleteSlot);

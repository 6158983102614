import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import { addCompanyNgrErrors, editCompanyNgrErrors } from '../../actions/companies/company-ngrs';
import isEmpty from 'lodash/isEmpty';
import { isLoading, forceStopLoader, loaded } from '../../actions/main';

const MESSAGES = {
  CREATE_SUCCESS: 'NGR was successfully added.',
  UPDATE_SUCCESS: 'NGR details were successfully updated.',
};

export const getCompanyNgrs = (companyId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    APIService.companies(companyId)
      .ngrs()
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const getCompanyFarmNgrs = (companyId, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (companyId) {
    APIService.companies(companyId)
      .farm_ngrs()
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createCompanyNgr = (companyId, data, actionCreator) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .ngrs()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addCompanyNgrErrors(item.errors));
        dispatch(forceStopLoader());
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        dispatch(loaded());
      }
    });
};

export const createAndGetCompanyNgr = (companyId, data, actionCreator, onSuccess) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .ngrs()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addCompanyNgrErrors(item.errors));
        dispatch(forceStopLoader());
      } else {
        dispatch(actionCreator(item));
        onSuccess(companyId, item);
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        dispatch(loaded());
      }
    });
};

export const updateCompanyNgr = (companyId, ngrId, data, actionCreator) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .ngrs(ngrId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editCompanyNgrErrors(item.errors));
        dispatch(forceStopLoader());
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS, 2, () => {
          dispatch(loaded());
          window.location.reload();
        });
      }
    });
};

import React from 'react';
import alertifyjs from 'alertifyjs'
import SideDrawer from '../common/SideDrawer';
import APIService from '../../services/APIService'
import {Autocomplete, TextField, Button} from '@mui/material'

const PaymentRunAssignNGR = ({ paymentRun, isOpen, onClose }) => {
  const [ngrs, setNgrs] = React.useState([])
  const [selected, setSelected] = React.useState({})
  const fetchNGRs = () => {
    if(paymentRun?.payerCompanyId) {
      APIService.companies(paymentRun.payerCompanyId).ngrs().get().then(response => {
        if(paymentRun?.payerNgr?.id) {
          setSelected(response.find(ngr => ngr.id === paymentRun.payerNgr.id) || {})
        }
        setNgrs(response)
      })
    }
  }
  const onNGRChange = (event, ngr) => {
    setSelected(ngr || {})
  }

  const onSave = () => {
    APIService.invoices().appendToUrl(`payment-run/${paymentRun?.id}/`).put({payerNgrId: selected.id}).then(() => {
      alertifyjs.success('Successfully updated NGR!', 2, onClose)
    })
  }

  React.useState(() => {
    if(isOpen)
      fetchNGRs()
  }, [isOpen])

  return (
    <SideDrawer isOpen={isOpen} onClose={onClose} title='Assign Buyer NGR'>
      <div className='col-xs-12 no-side-padding' style={{marginTop: '20px'}}>
        <Autocomplete
          fullWidth
          disableClearable
          id="payerNgrId"
          options={ngrs}
          getOptionLabel={item => item.ngrNumber || ''}
          renderInput={(params) => (
            <TextField
              label="Buyer Ngr"
              {...params}
            />
          )}
          value={ selected || '' }
          onChange={onNGRChange}
        />
      </div>
      <div className='col-xs-12 no-side-padding' style={{marginTop: '20px', textAlign: 'right'}}>
        <Button onClick={onSave} variant='contained' color='primary'>
          Save
        </Button>
      </div>
    </SideDrawer>
  )
}

export default PaymentRunAssignNGR;

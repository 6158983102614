import React from 'react';
import { connect } from 'react-redux';
import ContractBidsTable from '../../containers/ContractBidsTable';
import Paper from '@mui/material/Paper';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import AddContractBid from './AddContractBid';
import { 
    editSelectedContractBid, setSelectedContractBid, viewSelectedContractBid, contractBidsResponse, 
    getCompanyContractBids, showQuantityDialog, duplicateSelectedContractBid
} from '../../actions/companies/contract-bids';

class MyActiveContractBids extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            openCloseAddPContractBidDialog: false,
        };
        this.openSideDraw = this.openSideDraw.bind(this);
    }

    componentDidMount() {
        this.props.getCompanyContractBids(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditSelectedContractBid !== this.props.isEditSelectedContractBid)
            this.setState({ openCloseAddPContractBidDialog: this.props.isEditSelectedContractBid });
        if (prevProps.isViewSelectedContractBid !== this.props.isViewSelectedContractBid)
            this.setState({ openCloseAddPContractBidDialog: this.props.isViewSelectedContractBid });
        if (prevProps.isDuplicateSelectedContractBid !== this.props.isDuplicateSelectedContractBid)
            this.setState({ openCloseAddPContractBidDialog: this.props.isDuplicateSelectedContractBid });
    }

    openSideDraw() {
        this.setState({ openCloseAddPContractBidDialog: !this.state.openCloseAddPContractBidDialog });
        this.props.editSelectedContractBid(false);
        this.props.viewSelectedContractBid(false);
        this.props.duplicateSelectedContractBid(false);
        this.props.setSelectedContractBid([]);
    }

    componentWillUnmount() {
        this.props.setContractBidsResponse([]);
        this.props.viewSelectedContractBid(false);
    }

    render() {
        return (
            <div>
            <Paper className='paper-table-paginated'>
                <div>
                    <AddButton label="Contract Bid" onClick={this.openSideDraw} style={{ float: 'right', marginLeft: '10px' }}  />
                </div>
                <ContractBidsTable {...this.props} tab='my_active'/>

                {(this.state.openCloseAddPContractBidDialog || this.props.isEditSelectedContractBid || this.props.isViewSelectedContractBid) &&
                    <SideDrawer
                        isOpen={this.state.openCloseAddPContractBidDialog}
                        title={`${this.props.isEditSelectedContractBid ? "Edit" : this.props.isViewSelectedContractBid ? "View" : "Add"} Contract Bid`}
                        onClose={this.openSideDraw}
                        size='small'>
                        <AddContractBid onClose={this.openSideDraw} isCreate={!this.props.isEditSelectedContractBid} tab={this.props.tab}/>
                    </SideDrawer>
                }
            </Paper>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        tracks: state.main.tracks,
        selectedContractBid: state.companies.contractBids.selectedContractBid,
        isEditSelectedContractBid: state.companies.contractBids.editSelectedContractBid,
        isViewSelectedContractBid: state.companies.contractBids.viewSelectedContractBid,
        isDuplicateSelectedContractBid: state.companies.contractBids.duplicateSelectedContractBid,
        showQuantityDialog: state.companies.contractBids.showQuantityDialog,
        contractBids: state.companies.contractBids.contractBids,
        commodities: state.master.commodities.items,
    };
};

const mapDispatchToProps = dispatch => ({
    getCompanyContractBids: loader => dispatch(getCompanyContractBids(loader, null, 'my_active')),
    setContractBidsResponse: () => dispatch(contractBidsResponse([])),
    editSelectedContractBid: flag => dispatch(editSelectedContractBid(flag)),
    setSelectedContractBid: () => dispatch(setSelectedContractBid([])),
    viewSelectedContractBid: flag => dispatch(viewSelectedContractBid(flag)),
    duplicateSelectedContractBid: flag => dispatch(duplicateSelectedContractBid(flag)),
    setShowQuantityDialog: flag => dispatch(showQuantityDialog(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MyActiveContractBids);

import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { clickEditTruckIcon } from '../actions/companies/trucks';
import { isSystemCompany, getCompanyIdFromCurrentRoute, getCountryLabel, currentUserCompany, isCurrentUserCompanyAdmin } from '../common/utils';
import filter from "lodash/filter";
import { includes, get } from "lodash";
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../actions/companies/index';
import { getCompanyTrucksWeb } from '../actions/api/trucks';
import APIService from '../services/APIService';
import alertifyjs from 'alertifyjs';


const mapStateToProps = (state, ownProps) => {
  const regoLabel = getCountryLabel('rego')
  const columns = [
    { key: 'rego', header: `Truck ${regoLabel}`, sortable: false},
    { key: 'code', header: 'Code', sortable: false},
    { key: 'trailers[0].rego', header: `Trailer 1 ${regoLabel}`, sortable: false },
    { key: 'trailers[1].rego', header: `Trailer 2 ${regoLabel}`, sortable: false },
    { key: 'trailers[2].rego', header: `Trailer 3 ${regoLabel}`, sortable: false },
    { key: 'tareWeightDisplayValue', header: 'Total Tare Weight', sortable: false},
    { key: 'grossWeightDisplayValue', header: 'Total Gross Weight', sortable: false },
    { key: 'netWeightDisplayValue', header: 'Total Net Weight', sortable: false },
  ];

  let config = {
    columns: columns,
    items: filter(state.companies.companies.company.trucks.items, { isFleet: false }),
    scrollToTopOnUpdate: false,
    orderBy: 'rego',
    order: 'asc',
    tableHeader: 'Trucks',
    rowBorderLeftColorAttr: 'color',
    paginationData: state.companies.companies.company.trucks.paginationData,
    globalSearch: true,
  };
  config.optionsItems = []
  if (isSystemCompany() && !ownProps?.isArchived)
    config.optionsItems = [{ key: 'transfer', text: 'Transfer' }];

  if (isSystemCompany() && !ownProps?.isArchived)
    config.optionsItems.push({ key : 'merge', text: 'Merge' });

  if (isSystemCompany() || (ownProps?.companyId == currentUserCompany()?.id && isCurrentUserCompanyAdmin())) {
    var option = ownProps.isArchived ? { key : 'unarchive', text: 'Unarchive' } : { key : 'archive', text: 'Archive' };
    config.optionsItems = [...config.optionsItems, option]
  }
  return config;

};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleDefaultCellClick: (rowNum) => {
      if (!ownProps.isArchived)
        dispatch(clickEditTruckIcon(rowNum));
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'transfer') {
        item.entity = 'truck';
        dispatch(showSideDrawerForTransfer(item, true));
      }
      if (key === 'merge') {
        dispatch(showSideDrawerForMerge(item, true));
      }
      if (includes(['archive', 'unarchive'], key)) {
        APIService.trucks(item.id).put({'isActive': ownProps.isArchived}).then(response => {
          if(get(response, 'errors.rego'))
            alertifyjs.alert('Warning',response.errors?.rego[0]);
          else {
            let keyWord = ownProps.isArchived ? 'Unarchived' : 'Archived';
            alertifyjs.success(`Truck ${keyWord}`, 3, () => window.location.reload())
          }
        })
      }
    },
    navigateTo: url => {
      dispatch(getCompanyTrucksWeb(null, url));
    },
    changePageSize: (url, pageSize) => {
      if (url.includes('?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getCompanyTrucksWeb(null, url));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const companyService = APIService.companies(getCompanyIdFromCurrentRoute());
      companyService.appendToUrl(`trucks/web/?page_size=${pageSize}`);
      if (page) {
        companyService.appendToUrl(`&page=${page}`);
      }
      if (searchText) {
        companyService.appendToUrl(`&search=${searchText}`);
      }
      if (orderBy) {
        companyService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return companyService.URL;
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GenericTable);

import React from 'react';
import { connect } from 'react-redux';
import '@babel/polyfill';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import SideDrawer from '../../common/SideDrawer';
import AmendContract from '../../contracts/contractDetails/AmendContract';
import { isLoading, forceStopLoader } from '../../../actions/main';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';

class ContractPendingAmendAcceptanceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amendDialogOpen: false,
      isFetching: true,
      contracts: [],
      selectedContract: null,
    };
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.contracts().appendToUrl('amend/acceptance/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulContractFromList();
  }

  removeSuccessfulContractFromList() {
    const { responseContract } = this.props;
    const { selectedContract, contracts } = this.state;
    if(
      selectedContract &&
      get(responseContract, 'id') === selectedContract.id &&
      !isEmpty(contracts)
    ) {
      const index = findIndex(contracts, {id: responseContract.id});
      this.setState({
        contracts: [...contracts.slice(0, index), ...contracts.slice(index + 1)],
        selectedContract: null,
        acceptDialogOpen: false,
        rejectDialogOpen: false,
      }, () => {
        this.props.dispatch(forceStopLoader());
        this.props.onDataLoad(this.state.contracts.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/contracts/${id}/contract`);
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Amendment Request ${suffix}`;
  }

  async onReviewClick(event, contract) {
    event.stopPropagation();
    event.preventDefault();
    this.props.dispatch(isLoading('contractDetailsWithAmend'));

    const contractDetails = await APIService.contracts(contract.id).get(this.props.token);

    this.setState({
      amendDialogOpen: true,
      selectedContract: contractDetails,
    }, () => {
      this.props.dispatch(forceStopLoader());
    });
  }

  handleAmendClose(){
    this.setState({amendDialogOpen: false});
  }

  onChange(event, expanded) {
    if(expanded) {
      event.target.scrollIntoView(true);
      window.scrollBy(100, 100);
      if(isEmpty(this.state.contracts))
        this.getData();
    }
  }

  render() {
    const contractNumber = get(this.state.selectedContract, 'contractNumber') ? `(${get(this.state.selectedContract, 'contractNumber')})` : '';
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Identifier</TableCell>
                      <TableCell className="medium">Seller</TableCell>
                      <TableCell className="medium">Buyer</TableCell>
                      <TableCell className="medium">Description</TableCell>
                      <TableCell className="xxsmall">Price Point</TableCell>
                      <TableCell className="xxsmall">{getCountryLabel('tonnage')}</TableCell>
                      <TableCell align='center' className="small">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{contract.referenceNumber}</TableCell>
                          <TableCell className="medium">{contract.sellerName}</TableCell>
                          <TableCell className="medium">{contract.buyerName}</TableCell>
                          <TableCell className="medium">{contract.description}</TableCell>
                          <TableCell className="xxsmall">{contract.pricePoint}</TableCell>
                          <TableCell className="xxsmall">{contract.tonnage} {contract.tonnageUnit}</TableCell>
                          <TableCell align='center' className="small">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={(event) => this.onReviewClick(event, contract)}>
                              Review
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <SideDrawer
          isOpen={this.state.amendDialogOpen}
          title={`Contract Amend Request ${contractNumber}`}
          onClose={() => this.handleAmendClose()}
          size="big"
        >
          {
            this.state.selectedContract &&
            <AmendContract
              useCurrent={false}
              closeSidebar={() => this.handleAmendClose()}
              contract={this.state.selectedContract}
              reload={false}
              isFromAuditHistory={false}
            />
          }
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseContract: state.companies.contracts.confirmedOrRejectedContract
});

export default connect(mapStateToProps)(ContractPendingAmendAcceptanceGroup);

import React from 'react';
import './RequestSubmitSuccess.scss';
import CommonButton from '../common/CommonButton';


export default class RequestSubmitSuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

  }
  redirectToSingUp(){
    window.location.hash = '/sign-up';
  }

  redirectToAgriChain(){
    const url = 'https://agrichain.com/';
    window.open(url, "_blank");
  }

  render() {
    return (
      <div className="request-wrapper">
        <div className="request-container">
          <div className="request-content-box">
            <div className="request-content-box--header">
              <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
            </div>
            <div className="group-3">
              <img src="images/Group.png" className="group" alt="Smartphone" />
              <div className="vendor-declaration-s">Vendor Declaration Submission Successful</div>
            </div>
            <div className="manage-and-automate">Manage and automate all your vendor declaration requirements, plus much more on AgriChain.</div>
            <div className="group-17">
              <div className="group-16">
                <img src="images/smartphone.png" className="smartphone-1" alt="Smartphone" />
                <div className="automate-your-chain">Automate your Chain of Responsibility Obligations</div>
              </div>
              <div className="group-15">
                <img src="images/graphs.png" className="graphs" alt="Graph" />
                <div className="view-your-total-on-f">View your total on-farm and system stocks</div>
              </div>
              <div className="group-7">
                <img src="images/silo5.png" className="silo5" alt="Silo" />
                <div className="accurately-blend-you">Accurately blend your stock on outload</div>
              </div>
              <div className="group-6">
                <img src="images/contract.png" className="contract" alt="Silo" />
                <div className="automatically-update">Automatically updates contracts as you deliver</div>
              </div>
            </div>
            <CommonButton
              type="submit"
              label="Sign Up Now"
              variant="contained"
              primary={true}
              onClick={() => this.redirectToSingUp()}
              className="rectangle-3"
            />
            <CommonButton
              type="submit"
              label="Learn More"
              variant="contained"
              onClick={() => this.redirectToAgriChain()}
              className="buttons-text-secondary-enabled"
            />
          </div>
          <div className="for-help-or-enquiries">For help or enquiries please contact us on 1300 565 005 or support@agrichain.com</div>
        </div>
      </div >
    );
  }
}

import React from 'react';
import { map, startCase, without } from 'lodash';
import {
  Slide, List, ListItem, ListItemButton, Checkbox, ListItemIcon, ListItemText, ListSubheader,
  Collapse, Badge, Chip
} from '@mui/material'
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Cancel';


const getValue = (field, val) => {
  if(val && field.includes('grade'))
    return val.toUpperCase()
  if(!val || field.includes('season') || field.includes('buyer') || field.includes('seller'))
    return val
  return startCase(val)
}

const getName = field => {
  const parts = field.split('_')
  if(field.includes('price_point'))
    return startCase([parts[0], parts[1]].join(' '))
  return startCase(parts[0])
}

export const AppliedFacets = ({ saved, onRemove, applyFacets }) => {
  const [facets, setFacets] = React.useState({})
  const onFacetRemove = (field, facet) => {
    const newFacets = {...facets}
    newFacets[field] = without(newFacets[field], facet)
    onRemove(newFacets)
    setFacets(newFacets)
    setTimeout(() => applyFacets(), 100)
  }

  React.useEffect(() => {
    setFacets(saved)
  }, [JSON.stringify(saved)])

  return (
    <React.Fragment>
      {
        map(facets, (fieldFacets, field) => {
          return (
            fieldFacets.map(facet => (
              <Chip
                key={`${field}-${facet}`}
                color='secondary'
                size='small'
                label={`${getName(field)}: ${getValue(field, facet)}`}
                sx={{margin: '0 2px'}}
                deleteIcon={<CloseIcon fontSize='inherit' />}
                onDelete={() => onFacetRemove(field, facet)}
              />
            ))
          )
        })
      }
    </React.Fragment>
  )
}


const Facets = ({ facets, applied, isOpen, onChange }) => {
  const [openFacets, setOpenFacets] = React.useState([]);

  const toggleFacetGroup = field => {
    if(openFacets.includes(field))
      setOpenFacets(without(openFacets, field))
    else
      setOpenFacets([...openFacets, field])
  }

  const toggleFacet = (field, facet, checked) => {
    const newSelected = {...applied}
    newSelected[field] = newSelected[field] || []
    if(checked) {
      newSelected[field] = [...newSelected[field], facet]
    } else {
      newSelected[field] = without(newSelected[field], facet)
    }
    onChange(newSelected)
  }

  return (
    <Slide direction="right" in={isOpen} mountOnEnter unmountOnExit>
      <List
        dense
        sx={{
          width: '100%',
          bgcolor: 'background.paper',
          position: 'relative',
          overflow: 'auto',
          maxHeight: 'calc(100vh - 240px)',
          '& ul': { padding: 0 },
        }}
        subheader={<li />}
      >
        {
          map(facets, (fieldFacets, field) => {
            const isFacetOpen = openFacets.includes(field)
            return (
              <li key={field}>
                <ul>
                  <ListSubheader sx={{p: 0, cursor: 'pointer', lineHeight: '44px'}} onClick={() => toggleFacetGroup(field)}>
                    <span style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                      <span>
                        {getName(field)}
                      </span>
                      <span style={{display: 'flex', paddingRight: '8px'}}>
                        <Badge
                          max={10}
                          anchorOrigin={{vertical: 'top', horizontal: 'left'}}
                          badgeContent={applied[field]?.length}
                          color="primary">
                          {isFacetOpen ? <ExpandLess /> : <ExpandMore />}
                        </Badge>
                        </span>
                      </span>
                  </ListSubheader>
                  <Collapse in={isFacetOpen} timeout="auto">
                  {
                    map(fieldFacets, fieldFacet => {
                      const isSelected = applied[field]?.includes(fieldFacet[0]) || false
                      return (
                        <ListItem
                          sx={{p: 0}}
                          key={fieldFacet[0]}
                          secondaryAction={
                            <span style={{fontSize: '0.8rem'}}>{fieldFacet[1].toLocaleString()}</span>
                          }
                          disablePadding
                        >
                          <ListItemButton sx={{padding: '0 0 0 8px'}} role={undefined} dense onClick={() => toggleFacet(field, fieldFacet[0], !isSelected)}>
                            <ListItemIcon sx={{minWidth: 0}}>
                              <Checkbox
                                checked={isSelected}
                                onChange={() => toggleFacet(field, fieldFacet[0], !isSelected)}
                                sx={{padding: '4px 8px 4px 4px'}}
                                size='small'
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ 'aria-labelledby': fieldFacet[0] }}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={fieldFacet[0]}
                              primary={getValue(field, fieldFacet[0])}
                              sx={{
                                '.MuiListItemText-primary': {
                                  fontSize: '0.8rem'
                                }
                              }}
                            />
                          </ListItemButton>
                        </ListItem>
                      )
                    })
                  }
                  </Collapse>
                </ul>
              </li>
            )
          })
        }
      </List>
    </Slide>
  )
}

export default Facets

import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { TextField } from '@mui/material';
import { changePassword } from '../../actions/main';
import CommonButton from '../common/CommonButton';
import PasswordValidIndicator from '../employees/PasswordValidIndicator';

class ChangePassword extends Component {
  componentDidMount() {
    this.setState({passwordMisMatch: ''});
  }
  constructor(props) {
    super(props);
    this.state = {
      isNewPasswordValid: false,
      passwordMisMatch: '',
      incorrectPassword: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      shortPassword: ''
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
    this.handleNewBlur = this.handleNewBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isNewPasswordValid = this.isNewPasswordValid.bind(this);
  }

  isNewPasswordValid(valid) {
    if(valid !== this.state.isNewPasswordValid)
      this.setState({isNewPasswordValid: valid});
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      incorrectPassword: get(nextProps.passwordError,'password[0]'),
      shortPassword: get(nextProps.passwordError,'newPassword[0]')
    });
  }

  handleChange(event) {
    const newState = {...this.state};
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleConfirmBlur(event) {
    if(this.state.newPassword !== event.target.value && this.state.confirmPassword != '') {
      this.setState({passwordMisMatch: 'New password and Confirm password do not match'});
    } else {
      this.setState({passwordMisMatch: ''});
    }
  }

  handleNewBlur(event) {
    if(this.state.confirmPassword !== event.target.value && this.state.confirmPassword != '') {
      this.setState({passwordMisMatch: 'New password and Confirm password do not match'});
    } else {
      this.setState({passwordMisMatch: ''});
    }

    if(this.state.newPassword.length < 8) {
      this.setState({shortPassword: 'Password cannot be less than 8 Characters'});
    } else {
      this.setState({shortPassword: ''});
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = this.props;
    if (this.state.isNewPasswordValid && isEmpty(this.state.passwordMisMatch) && isEmpty(this.state.shortPassword)){
      dispatch(changePassword({password: this.state.password, newPassword: this.state.newPassword}));
    }
  }
  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit}>
          <div className="cardForm">
            <h4 className="cardForm-title">Change Password</h4>
            <div className="cardForm-content">
              <div className="col-md-5">
                <div className="col-md-12 form-wrap padding-reset">
                  <TextField
                    label="Current Password"
                    onChange={this.handleChange}
                    name="password"
                    type="password"
                    fullWidth
                    required
                    errorStyle={{textAlign: "left"}}
                    helperText={this.state.incorrectPassword || ''}
                    error={!isEmpty(this.state.incorrectPassword)}
                    value={this.state.password}
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <TextField
                    label="New Password"
                    onChange={this.handleChange}
                    onBlur={this.handleNewBlur}
                    name="newPassword"
                    type="password"
                    fullWidth
                    required
                    helperText={this.state.shortPassword || ''}
                    errorStyle={{textAlign: "left"}}
                    value={this.state.newPassword}
                    error={!isEmpty(this.state.shortPassword)}
                  />
                </div>
                <div className="col-md-12 form-wrap padding-reset">
                  <TextField
                    label="Confirm Password"
                    onChange={this.handleChange}
                    onBlur={this.handleConfirmBlur}
                    name="confirmPassword"
                    type="password"
                    fullWidth
                    required
                    errorStyle={{textAlign: "left"}}
                    value={this.state.confirmPassword}
                    helperText={this.state.passwordMisMatch}
                    error={!isEmpty(this.state.passwordMisMatch)}
                  />
                </div>
              </div>
              <div className="col-md-3">
                <PasswordValidIndicator
                  password={this.state.newPassword}
                  mainContainerStyle={{marginTop: '95px'}}
                  onChange={this.isNewPasswordValid}
                />
              </div>
            </div>
          </div>
          <div className="container-fluid row">
            <CommonButton
              type="submit"
              variant="contained"
              primary
              label="Submit"
            />
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  var passwordError = state.main.password || '';
  if(!isEmpty(passwordError)) {
    passwordError = passwordError.errors;
  }
  return {
    passwordError
  };
};

export default connect(mapStateToProps)(ChangePassword);

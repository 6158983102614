import React from "react";

import './orderPreview.scss';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import { isVisibleInCountry, getGradeName, getCurrentCountry, toPhoneFormat, getCountryLabel } from '../../../common/utils';
import { map } from "lodash";
import { UNIT_ABBREVIATIONS } from "../../../common/constants";

class GrainOrderPreview extends React.Component {

  getApplicationSummary(application) {
    const { previewFields } = this.props
    const { priceUnit } = previewFields
    let summary = ''
    if (get(application, 'commodity.displayName'))
      summary += `${get(application, 'commodity.displayName')}`
    if (application.applicationRate && get(application, 'commodity.unit')) {
      let unitAbbr = get(UNIT_ABBREVIATIONS, application.commodity.unit)
      summary += ` ${application.applicationRate} ${unitAbbr}/${priceUnit}`
    }
    if (application.gradeName)
      summary += ` on ${application.gradeName}`
    return summary
  }

  render() {
    const { previewFields } = this.props;
    const { config } = getCurrentCountry();
    const ABNLabel = config.abn.label
    const { pickupDetails, deliveryDetails, seller, buyer } = previewFields;
    const tonnageLabel = getCountryLabel('tonnage')
    const isNGRVisible = isVisibleInCountry('ngr')
    const isABNVisible = isVisibleInCountry('abn')
    const spreadLabel = getCountryLabel('spreadDetails')
    return (
      <div className="order-preview">
        <div className="order-header">
          <div className="agri-logo">
            {
              previewFields.currentUserCompanyLogo ?
                <img src={previewFields.currentUserCompanyLogo} alt={previewFields.currentUser.company.name} crossOrigin="anonymous" /> :
                <h2>{previewFields.currentUser.company.name}</h2>
            }
          </div>
          <div className="order-header--content">
            <div className="col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>Grain Order</h4>
              {
                previewFields.contractReferenceNumber &&
                <p className="reference">
                  <span>Contract Number:</span> {previewFields.contractReferenceNumber}
                </p>
              }
              {
                previewFields.externalReferenceNumber &&
                <p className="reference">
                  <span>Contract Number:</span> {previewFields.externalReferenceNumber}
                </p>
              }
              <p className="reference">
                <span>Order Number:</span> {previewFields.identifier.toUpperCase()}
              </p>
              <p className="reference">
                <span>Date Issued:</span> {previewFields.dateIssued}
              </p>
            </div>
            <div className="text-right col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{previewFields.currentUser.company.name}</h4>
              {
                previewFields.currentUser.company.address.address &&
                <p>{previewFields.currentUser.company.address.address}</p>
              }
              {
                previewFields.currentUser.email &&
                <p><span>EMAIL: </span>{previewFields.currentUser.email}</p>
              }
              {
                previewFields.currentUser.company.mobile &&
                <p><span>PHONE: </span>{toPhoneFormat(previewFields.currentUser.company.mobile)}</p>
              }
              {
                previewFields.currentUser.company.abn && isABNVisible &&
                  <p><span>{`${ABNLabel}: `}</span> {previewFields.currentUser.company.abn}</p>
              }
            </div>
          </div>
        </div>
        <div className="order-body">
          <p className="description">
            {
              `This document is a record of the Grain Order agreed between the below Seller and Buyer following discussions between the Seller’s and Buyer’s representatives on ${previewFields.dateIssued}.`
            }
            <p>
              {
                `For further information please contact ${previewFields.currentUserCompanyName} on ${toPhoneFormat(previewFields.currentUserCompanyPhone)}`
              }
            </p>
          </p>
          <div className="order-details">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h4>SELLER</h4>
              <h5>
                {get(seller, 'company.businessName', get(seller, 'company.displayName'))}
              </h5>
            {
                isABNVisible &&
                  <p>
                    <span className="pre-label">{ABNLabel}</span>
                    <span className="pre-content">{get(seller, 'company.abn')}</span>
                  </p>
            }
              <p>
                <span className="pre-label">Entity</span>
                <span className="pre-content">{get(seller, 'company.entityName')}</span>
              </p>
            {
                isNGRVisible &&
                  <p>
                    <span className="pre-label">NGR</span>
                    <span className="pre-content">{get(seller, 'ngr.ngrNumber')}</span>
                  </p>
            }
              <p>
                <span className="pre-label">Contact</span>
                <span className="pre-content">{get(seller, 'contact.name')}</span>
              </p>
              <div className="contacts">
                <p>
                  <span className="pre-label">Phone</span>
                  <span className="pre-content">{toPhoneFormat(get(seller, 'company.mobile'))}</span>
                </p>
                <p>
                  <span className="pre-label">Email</span>
                  <span className="pre-content">{get(seller, 'contact.email')}</span>
                </p>
              </div>
            </div>
            <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h4>BUYER</h4>
              <h5>
                {get(buyer, 'company.businessName', get(buyer, 'company.displayName'))}
              </h5>
            {
                isABNVisible &&
                  <p>
                    <span className="pre-label">{ABNLabel}</span>
                    <span className="pre-content">{get(buyer, 'company.abn')}</span>
                  </p>
            }
              <p>
                <span className="pre-label">Entity</span>
                <span className="pre-content">{get(buyer, 'company.entityName')}</span>
              </p>
            {
                isNGRVisible &&
                  <p>
                    <span className="pre-label">NGR</span>
                    <span className="pre-content">{get(buyer, 'ngr.ngrNumber')}</span>
                  </p>
            }
              <p>
                <span className="pre-label">Contact</span>
                <span className="pre-content">{get(buyer, 'contact.name')}</span>
              </p>
              <div className="contacts">
                <p>
                  <span className="pre-label">Phone</span>
                  <span className="pre-content">{toPhoneFormat(get(buyer, 'company.mobile'))}</span>
                </p>
                <p>
                  <span className="pre-label">Email</span>
                  <span className="pre-content">{get(buyer, 'contact.email')}</span>
                </p>
              </div>
            </div>
          </div>
          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>COMMODITY DETAILS</h5>
              <p>
                <span className="pre-label">COMMODITY</span>
                <span className="pre-content">{get(previewFields, 'commodity.displayName')}</span>
              </p>
              <p>
                <span className="pre-label">VARIETY</span>
                <span className="pre-content">{get(previewFields, 'variety.name')}</span>
              </p>
              <p>
                <span className="pre-label">GRADE</span>
                <span className="pre-content">
                  {previewFields.isPoolContract ? get(previewFields, 'poolGrades') : getGradeName(previewFields)}
                </span>
              </p>
              {
                previewFields.showSpreadGradesSection &&
                <p>
                  <span className="pre-label">
                    {spreadLabel}
                  </span>
                  <span className="pre-content">
                    {
                      map(previewFields.spread, (grade, index) => <div key={index}><span className="spread-section" key={index} style={{fontWeight: 'normal'}}>{grade.gist}</span></div>)
                    }
                  </span>
                </p>
              }
              {
                previewFields.chemicalApplications?.length > 0 &&
                  <p>
                    <span className="pre-label">
                      Applications
                    </span>
                    <span className="pre-content">
                      {
                        map(previewFields.chemicalApplications, (application, index) => {
                          return <div key={index}><span className="spread-section" key={index} style={{fontWeight: 'normal'}}>{this.getApplicationSummary(application)}</span></div>
                        })
                      }
                    </span>
                  </p>
               }
              {
                previewFields.quantity &&
                <p>
                  <span className="pre-label">{previewFields.quantityLabel}</span>
                  <span className="pre-content">{get(previewFields, 'quantity')} {get(previewFields, 'quantityUnit')}</span>
                </p>
              }
              <p>
                <span className="pre-label">{tonnageLabel.toUpperCase()}</span>
                <span className="pre-content">{get(previewFields, 'plannedTonnage')} {previewFields?.commodity?.tonnageUnit}</span>
              </p>
              <p>
                <span className="pre-label">SEASON</span>
                <span className="pre-content">{get(previewFields, 'season')}</span>
              </p>
            </div>
          </div>
          <div className="order-details margintop-20">
            <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>PICKUP DETAILS</h5>
              {pickupDetails && Object.keys(pickupDetails).map(function (key, index) {
                return <p key={index}><span className="pre-label">{key}</span> <span className="pre-content">{pickupDetails[key]}</span></p>;
              })}
            </div>
            <div className="order-details__right col-xs-12 col-sm-6 col-md-6">
              <h5>DELIVERY DETAILS</h5>
              {deliveryDetails && Object.keys(deliveryDetails).map(function (key, index) {
                return <p key={index}><span className="pre-label">{key}</span> <span className="pre-content">{deliveryDetails[key]}</span></p>;
              })}
            </div>
          </div>
          {
            previewFields && previewFields.note && filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).length !== 0 &&
            <div className="order-details margintop-20">
              <div className="order-details__left col-xs-12 col-sm-6 col-md-6">
                <h5>Notes</h5>
                {
                  filter(Object.keys(previewFields.note), i => !isEmpty(previewFields.note[i])).map(function (key, index) {
                    if (key != 'attachments')
                      return (<p key={index}>
                        <span className="pre-label">Description: </span>
                        <span className="pre-content">{previewFields.note[key]}</span>
                      </p>);
                    return (<p key={index}>
                      <span className="pre-label">Attachment: </span>
                      <span className="pre-content">{previewFields.note[key][0]['name']}</span>
                    </p>);
                  })
                }
              </div>
            </div>
          }
        </div>

        {previewFields.generalConditions && <div className="commodity-details order-conditions">
          <h4>Freight Order Conditions</h4>
          <p>{previewFields.generalConditions}</p>
        </div>}
        {previewFields.specialConditions && <div className="commodity-details order-conditions">
          <h4>Special Conditions</h4>
          <p>{previewFields.specialConditions}</p>
        </div>}
      </div>
    );
  }
}

export default GrainOrderPreview;

import React, { Component } from 'react'
import { isEqual, set, cloneDeep, forEach } from 'lodash';
import { FIELD, REQUIRED_FIELD } from '../../common/constants';
import CommonSelect from '../common/select/CommonSelect';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';

class TransactionConfig extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemIndex: undefined,
      data: {
        transactionType: cloneDeep(REQUIRED_FIELD),
        prefix: cloneDeep(FIELD),
        startFrom: cloneDeep(REQUIRED_FIELD),
        length: cloneDeep(REQUIRED_FIELD)
      }
    }

    this.updateTransactionDetails = this.updateTransactionDetails.bind(this);
    this.deleteTransaction = this.deleteTransaction.bind(this);
    this.handleTransactionChange = this.handleTransactionChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.notifyValueChanged = this.notifyValueChanged.bind(this);

  }

  componentDidMount() {
    this.updateTransactionDetails(this.props);
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps, this.props)) {
      this.updateTransactionDetails(this.props);
    }
  }

  updateTransactionDetails(props){
    const { transactionType, prefix, startFrom, length, index } = props;
    const newState = { ...this.state };
    newState.itemIndex = index;
    newState.data.transactionType.value = transactionType;
    newState.data.prefix.value = prefix;
    newState.data.startFrom.value = startFrom;
    newState.data.length.value = length;
    this.setState(newState, () => this.setAllFieldsErrors());
  }

  onFieldChange(event) {
    const { data } = this.state;
    set(data, `${event.target.id}.value`, event.target.value);
    this.setState({ data }, () => this.setFieldErrors(event.target.id));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.data[key].value;
    const validators = this.state.data[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  setFieldErrors(key) {
    let errors = this.getFieldErrors(key);
    const newState = {...this.state};
    newState.data[key].errors = errors;
    this.setState(newState, this.notifyValueChanged);
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.data, (value, key) => {
      newState.data[key].errors = this.getFieldErrors(key);
    });

    this.setState(newState);
  }

  notifyValueChanged() {
    const values = {};
    let errorCount = 0;
    forEach(this.state.data, (field, key) => {
      values[key] = this.state.data[key].value;
      errorCount += this.state.data[key].errors.length;
    });
    values['isValid'] = errorCount == 0;
    this.props.valueChanged(this.props.index, values);
  }

  deleteTransaction() {
    this.props.deleteTransaction(this.state.itemIndex)
  }

  handleTransactionChange(item) {
    const { data } = this.state;
    set(data, `transactionType.value`, item);
    this.setState({ data }, () => this.setFieldErrors('transactionType'));
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-sm-12 form-wrap-70">
          <CommonSelect
            id="transactionType"
            floatingLabelText="Transaction Type"
            onChange={this.handleTransactionChange}
            value={this.state.data.transactionType.value}
            items={this.props.transactionItems}
            errorText={this.state.data.transactionType.errors[0]}
          />
          <CommonTextField
            id="prefix"
            label='Prefix (Optional)'
            placeholder='Prefix (Optional)'
            onChange={this.onFieldChange}
            value={this.state.data.prefix.value}
            maxLength="4"
            style={{margin: '5px 0px'}}
            helperText={this.state.data.prefix.errors[0]}
            onBlur={this.onFieldBlur}
          />
          <CommonTextField
            id="length"
            label='Length'
            placeholder='Length'
            type='number'
            onChange={this.onFieldChange}
            value={this.state.data.length.value}
            style={{margin: '5px 0px'}}
            helperText={this.state.data.length.errors[0]}
            onBlur={this.onFieldBlur}
          />
          <CommonTextField
            id="startFrom"
            label='Start From'
            placeholder='Start From'
            type='number'
            onChange={this.onFieldChange}
            value={this.state.data.startFrom.value}
            style={{margin: '5px 0px'}}
            helperText={this.state.data.startFrom.errors[0]}
            onBlur={this.onFieldBlur}
          />
        </div>
        <div className="col-sm-12 form-wrap-70" style={{display: 'flex', justifyContent: 'end', marginTop: '15px'}}>
          <CommonButton
            label='Delete'
            color='secondary'
            onClick={this.deleteTransaction}
            variant="outlined"
            style={{ height: 'fit-content', width: 'fit-content' }}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default TransactionConfig;
import { connect } from 'react-redux';
import FarmForm from '../components/farms/FarmForm';
import { createFarm } from '../actions/api/farms';
import { addFarm } from '../actions/companies/farms';

function submit(companyId, data) {
  return (dispatch) => {
    dispatch(createFarm(companyId, data, addFarm));
  };
}

export default connect(null, { submit })(FarmForm);

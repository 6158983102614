import React from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import {
  AddCircle as AddIcon,
} from '@mui/icons-material';
import { TextField, Button, Autocomplete} from '@mui/material';
import {
  get, forEach, isEmpty, map, orderBy, find, values, filter,
  includes, uniqBy, compact, remove, reject, isNaN, isArray
} from 'lodash';
import APIService from '../../services/APIService';
import {
  setHeaderText, setBreadcrumbs, setSettingButton, setDownloadBar, forceStopLoader,
  isLoading
} from '../../actions/main';
import { setSelectedStorageForStocks } from '../../actions/companies/storages';
import { isCurrentUserBroker, attachCSVEventListener, isSubscribedUser, isSystemCompany, getSeasons, getCurrentCountry, currentUser, isObserver, validateUnitForStockOperation} from '../../common/utils';
import SettingsButton from '../common/SettingsButton';
import CreateTitleTransfer from '../../containers/CreateTitleTransfer';
import {
  getStorageQueryStringByStocksStorageItem, getStorageQueryStringByStocksStorageItemforStorageView
} from './utils';
import '../../common/card.scss';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import StorageStockCSV from './StorageStockCSV';
import OwnershipStockCSV from './OwnershipStockCSV';
import CommonTabs from '../common/CommonTabs';
import UpdateStorageFilters from './UpdateStorageFilters';
import StorageToStorageTransferDialog from './StorageToStorageTransferDialog';
import StockDifferencesDialog from './StockDifferencesDialog';
import StockSwapDialog from './StockSwapDialog';
import SideDrawer from '../common/SideDrawer';
import StockSwapsView from './StockSwapsView';
import SiteLoadsView from './SiteLoadsView';
import StockSwapCSV from './StockSwapCSV';
import FarmStorageStocks from './FarmStorageStocks';
import CompanyOwnershipStocks from './CompanyOwnershipStocks';
import AddButton from '../common/AddButton';
import ContainerCSV from './ContainerCSV';


const ALL_STORAGE_OPTION = { id: 'all', name: 'All Storages' };
const ALL_CONTAINER_OPTION = { id: 'all', name: 'All Containers' };
const ALL_LOCATION_OPTION = {id: 'all', name: 'All Locations' }
const ALL_OWNER_OPTION = {id: 'all', name: 'All Owners' }

class CompanyStocks extends React.Component {
  constructor(props) {
    super(props);

    this.setQueryParamsInContext(props, true);

    const correctPath = props.location.pathname === '/stocks' ? ((isSystemCompany() || isSubscribedUser()) ? '/stocks/storages-view' : '/stocks/ownership-view') : props.location.pathname;

    this.state = {
      zeroStocks: false,
      storageStocks: {},
      ownershipStocks: {},
      swapStocks: {},
      stockedCommoditiesGroupedByOwnersForSwaps: {},
      activeTab: correctPath,
      owner: this.ownerId || 'all',
      storage: this.storageId || 'all',
      locationType: this.farmId || 'all',
      isOpen: false,
      ngrs: [],
      allLocation: [],
      otherOwners: [],
      managedOwners: [],
      commodities: [],
      filteredCommodities: [],
      filteredSeasons: [],
      filteredGrades: [],
      filteredNgrs: this.ngrIds || [],
      allStockedStorages: [],
      stockDifferences: [],
      openStockDifferenceDialog: false
    }
  }

  toggleZeroStocks = () => this.setState({zeroStocks: !this.state.zeroStocks}, this.initiateDataLoading)

  getStockDifferences = () => {
    const commodities = Object.keys(this.state.storageStocks);
    const location = this.state.locationType || this.farmId;
    const commodityIds = commodities ? commodities.join(',') : ''
    this.props.dispatch(isLoading("stockDifferences"))
    APIService.stocks().farms(location).appendToUrl('stock-diffs/').get(this.props.token, null, {commodities: commodityIds}).then(response => {
      this.setState({ stockDifferences: response, openStockDifferenceDialog: true  }, () => this.props.dispatch(forceStopLoader()));
    })
  }

  setQueryParamsInContext(props, isMounting=false) {
    this.setCacheInContext(isMounting)
    if (!props)
      return;
    const { location, navigatedFromFarmId, match } = props;
    const queryParams = new URLSearchParams(get(location, 'search'));
    const farmId = navigatedFromFarmId || queryParams.get('farmId') || get(match, 'params.farm_id');
    const ownerId = queryParams.get('ownerId');
    const storageId = queryParams.get('storageId');
    const containerId = queryParams.get('containerId');
    if (farmId)
      this.farmId = parseInt(farmId);
    if (ownerId)
      this.ownerId = parseInt(ownerId);
    if (location.pathname === '/stocks/ownership-view')
      this.ownerId = this.props.user.companyId;
    if (storageId)
      this.storageId = parseInt(storageId);
    if (containerId)
      this.containerId = parseInt(containerId);
  }

  setCacheInContext(isMounting=false) {
    let cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    const key = this.getViewCacheKey()
    if(key) {
      cache = get(cache, key)
      if(cache?.location && cache.location !== 'all') {
        const location = parseInt(cache.location)
        if(!isNaN(location)) {
          this.farmId = location
        }
      } else if (cache?.location === 'all')
        this.farmId = undefined
      this.ownerId = cache?.ownerId
      this.commodityIds = cache?.commodityIds || []
      this.gradeIds = cache?.gradeIds || []
      this.ngrIds = cache?.ngrIds || []
      this.seasons = cache?.seasons || []

      const { location } = this.props;
      const queryParams = new URLSearchParams(get(location, 'search'));
      const farmId = queryParams.get('farmId')

      if(isMounting){
        if(farmId && !cache?.storage)
          return

        this.storage = cache?.storage || 'all'
        this.storageId = cache?.storage || 'all'
      }
    }
  }

  getViewCacheKey = () => {
    if(this.props.location.pathname.includes('/stocks/storages-view'))
      return 'storagesView'
    else if(this.props.location.pathname.includes('/stocks/ownership-view'))
      return 'ownershipView'
    else if(this.props.location.pathname.includes('/stocks/other-stocks-view'))
      return 'otherStocksView'
    else if(this.props.location.pathname.includes('/stocks/containers'))
      return 'storagesContainerView'
    else if(this.props.location.pathname.includes('/stocks/site-view'))
      return 'siteView'
    else if(this.props.location.pathname.includes('/stocks/stock-swaps'))
      return 'stockSwapsView'
    else
      return 'storagesView'
  }

  componentDidMount() {
    this.setSettingsButton();
    const { dispatch, dontSetLayout } = this.props;
    this._attachCSVEventListener();
    this.getCommoditiesWithGrades();
    if (!dontSetLayout) {
      dispatch(setHeaderText('Stocks'));
      dispatch(setBreadcrumbs([{ text: 'Stocks' }]));
    }
    if (this.isContainerView() || this.isOtherStocksView()) {
      this.setQueryParamsInContext(this.props, true)
      this.setState(
        {locationType: this.farmId || 'all', owner: this.ownerId || 'all', filteredCommodities: this.commodityIds, filteredGrades: this.gradeIds, filteredSeasons: this.seasons, filteredNgrs: this.ngrIds},
        () => this.initiateDataLoading()
      )
    } else
      this.initiateDataLoading();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.location.pathname !== this.props.location.pathname) {
      this.setQueryParamsInContext(this.props)
      let owner = this.props.location.pathname == this.ownershipViewURL() ? this.props.user?.companyId || 'all' : this.ownerId || 'all'
      const activeTab = this.props.location.pathname === '/stocks' ? '/stocks/storages-view' : this.props.location.pathname
      let zeroStocks = [this.ownershipViewURL(), this.otherStocksViewURL(), this.storagesViewURL()].includes(activeTab) ? this.state.zeroStocks : false
      this.setState(
        {activeTab: activeTab, locationType: this.farmId || 'all', owner: owner, zeroStocks: zeroStocks, filteredCommodities: this.commodityIds, filteredGrades: this.gradeIds, filteredSeasons: this.seasons, filteredNgrs: this.ngrIds},
        this.initiateDataLoading
      )
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setSettingButton(null))
  }

  getCommoditiesWithGrades() {
    APIService.commodities().get(null, null, { includes: 'grade', simplify_names: true, countryId: getCurrentCountry().id }).then(response => {
      this.setState({ commodities: response });
    })
  }

  _attachCSVEventListener = () => {
    attachCSVEventListener('stocks-csv-ready', 'Stocks', this.onDownloadResponse);
    attachCSVEventListener('storage-transfer-csv-ready', 'Stocks', this.onDownloadResponse);
    attachCSVEventListener('stock-swap-csv-ready', 'Stocks', this.onDownloadResponse);
    attachCSVEventListener('regrade-reseason-csv-ready', 'Stocks', this.onDownloadResponse);
    attachCSVEventListener('acquisition-csv-ready', 'Acquisition', this.onDownloadResponse);
    attachCSVEventListener('stocks-overview-csv-ready', 'Stocks Overview', this.onDownloadResponse);
  };

  onDownloadResponse = message => this.props.dispatch(setDownloadBar(message, true, this.onCloseDownloadResponse))

  onCloseDownloadResponse = () => this.props.dispatch(setDownloadBar(false))

  initiateDataLoading = () => {
    this.props.dispatch(isLoading("companyStocks"))
    if(this.isStoragesView())
      this.getStoragesViewData()
    else if(this.isContainerView()) {
      this.fetchOtherOwners()
      this.getStoragesViewData(true)
    } else if(this.isOwnershipsView())
      this.getOwnershipViewData()
    else if(this.isOtherStocksView())
      this.getOthersOwnershipViewData()
    else if(this.isStockSwapView())
      this.getStockSwapViewData()
    else if(this.isSiteLoadsView())
      this.getSiteViewData()
    else
      this.props.dispatch(forceStopLoader())
  }

  getStoragesViewData = containers => {
    this.fetchStockedFarms(containers)
    this.getStorageViewDataForLocation(containers)
  }

  getStockSwapViewData = () => {
    this.fetchStockedFarms()
    this.getOwnershipViewDataForOwner()
  }

  getSiteViewData = () => {
    this.fetchFarmsWithOwnerStocks()
  }

  getOwnershipViewData = () => {
    this.fetchFarmsWithOwnerStocks()
    this.fetchManagedOwners()
    this.getOwnershipViewDataForOwner()
  }

  getOthersOwnershipViewData = () => {
    this.fetchStockedFarms()
    this.fetchOtherOwners()
    this.getOwnershipViewDataForOthers()
  }

  getOwnershipViewDataForOwner = () => {
    let owner = this.isOwnershipsView() ? this.state.owner || this.ownerId || this.props.user.companyId : (this.state.owner || this.ownerId)
    if(this.isStockSwapView())
      owner = this.props.user.companyId
    if(owner)
      this.fetchOwnershipStocks(owner)
  }

  getOwnershipViewDataForOthers = () => {
    if(this.state.owner || this.ownerId) {
      this.fetchOwnershipStocksForOthers()
    }
  }

  getStorageViewDataForLocation = containers => {
    if(this.state.locationType || this.farmId) {
      this.fetchStockedFarmStorages(containers)
      this.fetchStorageStocks(containers)
      this.fetchEmptyStorages(containers)
      this.fetchArchivedStorages(containers)
    }
  }

  onLocationChange = (value, id) => {
    const shouldSetSettingButton = value !== this.state.locationType && value
    if (value !== this.state.locationType && (value !== null || isCurrentUserBroker() || this.farmId)) {
      this.setState({ [id]: value || '', filteredCommodities: [], filteredGrades: [], filteredSeasons: [], filteredNgrs: this.isOwnershipsView() ? this.state.filteredNgrs : [] }, () => {
        if(shouldSetSettingButton)
          this.setSettingsButton();
        if(this.isStoragesView()) {
          this.fetchStockedFarmStorages()
          this.getStorageViewDataForLocation()
        }
        if(this.isContainerView()) {
          this.fetchStockedFarmStorages(true)
          this.getStorageViewDataForLocation(true)
        }
        if(this.isOwnershipsView())
          this.getOwnershipViewDataForOwner()
        if(this.isOtherStocksView())
          this.getOwnershipViewDataForOthers()
        if(this.isStockSwapView())
          this.getOwnershipViewDataForOwner()
      });
    }
  };

  onOwnerChange = value => {
    if (value !== this.state.owner && value) {
      const propagate = () => {
        const { allLocation } = this.state;
        let allData = [...allLocation];
        if (isCurrentUserBroker())
          allData = uniqBy([ALL_LOCATION_OPTION, ...allData], 'id');
        else
          allData = allLocation;
        this.setState({ owner: value || '', storage: 'all', allLocation: allData, filteredCommodities: [], filteredGrades: [], filteredSeasons: [], filteredNgrs: [] }, () => {
          if(value) {
            if(this.isOtherStocksView()) {
              this.getOwnershipViewDataForOthers()
            }
            else if(this.isOwnershipsView()) {
              this.fetchFarmsWithOwnerStocks()
              this.getOwnershipViewDataForOwner()
            }
            else if(this.isContainerView()) {
              this.getStorageViewDataForLocation(true)
            }
          }
          this.updateFiltersCache()
        });
      };
      propagate();
    }
  };


  updateFiltersCache = () => {
    let cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    const key = this.getViewCacheKey()
    cache[key] = { ...cache[key],
      'commodityIds': this.state.filteredCommodities,
      'gradeIds': this.state.filteredGrades,
      'ngrIds': this.state.filteredNgrs,
      'seasons': this.state.filteredSeasons,
      'location': this.state.locationType,
      'ownerId': this.state.owner,
      'storage': key === 'storagesView' && (this.state.storage || 'all')
    };
    localStorage.setItem('stocks', JSON.stringify(cache));
  }

  applyFilters = () => {
    if(this.isOtherStocksView())
      this.getOwnershipViewDataForOthers()
    else if(this.isContainerView())
      this.getStorageViewDataForLocation(true)
    this.updateFiltersCache()
  }

  resetFilters = () => this.setState({filteredCommodities: [], filteredGrades: [], filteredSeasons: [], filteredNgrs: []}, this.applyFilters)

  fetchFarmsWithOwnerStocks = () => {
    let owner = this.state.owner
    if(!owner || owner === 'all')
      owner = this.props.user.companyId
    APIService.stocks().companies(owner).appendToUrl('owners/farms/stocked/').get().then(farms => this.setState({stockedFarms: farms, allLocation: this.getLocationOptions(farms)}, () => {
      if (this.farmId) {
        const farm = find(farms, { id: this.farmId });
        if (farm)
          this.onLocationChange(this.farmId, 'locationType');
      }
    }))
  }

  fetchStockedFarms = containers => {
    APIService.stocks().companies(this.props.user.companyId).appendToUrl('farms/stocked/').get(null, null, {only_containers: containers, only_zero: this.state.zeroStocks}).then(farms => this.setState({stockedFarms: farms, allLocation: this.getLocationOptions(farms)}, () => {
      if (this.farmId) {
        const farm = find(farms, { id: this.farmId });
        if (farm)
          this.onLocationChange(this.farmId, 'locationType');
      } else if(!this.state.locationType || this.state.locationType != 'all') {
        get(farms, '0.id') ? this.onLocationChange(get(farms, '0.id'), 'locationType') : null
      }
      this.props.dispatch(forceStopLoader())
    }))
  }

  fetchStockedFarmStorages = containers => {
    let location = this.state.locationType || this.farmId
    if(location === 'all')
      location = undefined
    let data = {
      only_containers: containers,
      only_zero: this.state.zeroStocks,
    }
    if(this.state.filteredCommodities)
      data['commodity_id'] = this.state.filteredCommodities.join(',')
    if(this.state.filteredGrades)
      data['grade_id'] = this.state.filteredGrades.join(',')
    if(this.state.filteredNgrs)
      data['ngr_id'] = this.state.filteredNgrs.join(',')
    if(this.state.filteredSeasons)
      data['season'] = this.state.filteredSeasons.join(',')
    if(location)
      APIService.stocks().farms(location).appendToUrl(`storages/stocked/`).get(null, null, data).then(response => this.setState({stockedStorages: response}, () => {
        const storageId = this.storageId || this.storage
        if (storageId) {
          const storage = find(response, { id: storageId});
          this.onStorageChange(storage ? storage : 'all')
          this.storageId = null;
          this.storage = null;
          document.location.hash = document.location.hash.split('?')[0];
        }
    }))
  }

  fetchOtherOwners = () => {
    if (isEmpty(this.state.otherOwners) || !this.state.zeroStocks)
      APIService.stocks().companies(this.props.user.companyId).appendToUrl('others/').get().then(response => this.setState({otherOwners: response}))
  }

  fetchManagedOwners = () => {
    APIService.stocks().companies(this.props.user.companyId).appendToUrl('managed/').get().then(response => this.setState({managedOwners: isArray(response) ? response : []}))
  }

  fetchOwnershipStocks = owner => {
    owner = owner || this.state.owner || this.ownerId
    let location = this.state.locationType || this.farmId
    let ngrIds = false
    if(location === 'all')
      location = undefined;
    if(owner === 'all')
      owner = undefined;
    if(this.state.filteredNgrs)
      ngrIds = this.state.filteredNgrs.join(',')
    if(owner) {
      if(this.isStockSwapView()) {
        if(!location || location === 'all')
          APIService.stocks().companies(owner).appendToUrl('stock-swaps/').get().then(response => this.setState({swapStocks: response}))
        else
          APIService.stocks().farms(location).appendToUrl('stock-swaps/').get().then(response => this.setState({swapStocks: response}))
      } else {
        APIService.stocks().companies(owner).appendToUrl('stocks/').get(null, null, {farm_id: location, only_zero: this.state.zeroStocks, ngr_id: ngrIds}).then(response => this.setState({ownershipStocks: response}, () => this.props.dispatch(forceStopLoader())))
        APIService.ngrs().get(null, null, {company_ids: owner}).then(response => this.setState({ngrs: response}))
      }
    }
  }

  getCommonNgrs = ngrs => {
    const filteredIdsSet = new Set(this.state.filteredNgrs);
    return ngrs.filter(ngr => filteredIdsSet.has(ngr.id)).map(ngr => ngr.id)
  };

  fetchOwnershipStocksForOthers = () => {
    let owner = this.state.owner || this.ownerId
    let location = this.state.locationType || this.farmId
    let commodityIds, gradeIds, seasons, ngrIds;
    if(location === 'all')
      location = undefined;
    if(owner === 'all')
      owner = undefined;
    if(this.state.filteredCommodities)
      commodityIds = this.state.filteredCommodities.join(',')
    if(this.state.filteredGrades)
      gradeIds = this.state.filteredGrades.join(',')
    if(this.state.filteredNgrs)
      ngrIds = this.state.filteredNgrs.join(',')
    if(this.state.filteredSeasons)
      seasons = this.state.filteredSeasons.join(',')
    APIService.stocks().companies(this.props.user.companyId).appendToUrl('others/stocks/').get(null, null, {farm_id: location, owner_company_id: owner, season: seasons, commodity_id: commodityIds, grade_id: gradeIds, ngr_id: ngrIds, only_zero: this.state.zeroStocks}).then(response => this.setState({ownershipStocks: response}, () => this.props.dispatch(forceStopLoader())))
    APIService.stocks().companies(this.props.user.companyId).appendToUrl('others/ngrs/').get(null, null, {farm_id: location, owner_company_id: owner, season: seasons, commodity_id: commodityIds, grade_id: gradeIds, only_zero: this.state.zeroStocks}).then(response => this.setState({ngrs: response, filteredNgrs: this.getCommonNgrs(response)}))
  }

  fetchStorageStocks = () => {
    const isContainers = this.isContainerView()
    let location = this.state.locationType || this.farmId
    let storage = this.state.storage || this.storageId
    if(includes(['all', 'empty', 'archived'], storage))
      storage = undefined
    if(location){
      let data = {
        farm_id: location,
        storage_id: storage,
        only_containers: isContainers,
        only_zero: this.state.zeroStocks}
      if (location === 'all'){
        data['storage_id'] = undefined
        data['farm_id'] = undefined
      }
      if (this.state.filteredCommodities?.length != 0)
        data['commodity_id'] = this.state.filteredCommodities.join(',')
      if (this.state.filteredGrades?.length != 0)
        data['grade_id'] = this.state.filteredGrades.join(',')
      if (this.state.filteredNgrs?.length != 0)
        data['ngr_id'] = this.state.filteredNgrs.join(',')
      if (this.state.filteredSeasons?.length != 0)
        data['seasons'] = this.state.filteredSeasons.join(',')
      if (this.state.owner != 'all' && this.state.owner != null)
        data['owner_id'] = this.state.owner
      APIService.stocks().farms().appendToUrl('stocks/').get(null, null, data).then(response => this.setState({storageStocks: response}), () => this.props.dispatch(forceStopLoader()))
      APIService.stocks().farms().appendToUrl('ngrs/').get(null, null, data).then(response => this.setState({ngrs: response}))
    }
  }

  fetchEmptyStorages = containers => {
    let location = this.state.locationType || this.farmId
    if(location && location !== 'all')
      APIService.stocks().farms(location).appendToUrl('storages/empty/').get(null, null, {only_containers: containers, only_zero: this.state.zeroStocks}).then(response => this.setState({emptyStorages: response}))
  }

  fetchArchivedStorages = containers => {
    let location = this.state.locationType || this.farmId
    if (location && location !== 'all' && !this.isArchivedStoragesLoaded() && !this.state.isLoadingArchivedStorages && this.isStoragesView())
      this.__fetchAndSetArchivedStorages(containers);
  }

  __fetchAndSetArchivedStorages = containers => {
    const { locationType, archivedStorages } = this.state;
    this.setState({ isLoadingArchivedStorages: true }, () => {
      const callback = storages => this.setState({
        isLoadingArchivedStorages: false,
        archivedStorages: {
          ...archivedStorages,
          [locationType]: storages
        }
      });
      if (locationType && locationType !== 'all') {
        const service = APIService.farms(locationType).appendToUrl('archived-storages/');
        service.get(null, null, {only_containers: containers}).then(storages => {
          callback(storages);
        });
      }
    });

  }

  getSettingsButton() {
    const { locationType } = this.state;
    if (locationType !== 'all')
      return {
        button: <SettingsButton
                  href={`#/farms/${locationType}/settings/details`}
                  buttonText='Settings' />
      };
  }

  setSettingsButton() {
    this.props.dispatch(setSettingButton(this.getSettingsButton()));
  }

  getStocksTabs() {
    let tabs = [
      { label: 'Storages View', url: this.storagesViewURL(), component: () => <FarmStorageStocks /> },
      { label: 'My Stocks', url: this.ownershipViewURL(), component: () => <CompanyOwnershipStocks /> },
      { label: 'Third Party Stocks', url: this.otherStocksViewURL(), component: () => <CompanyOwnershipStocks /> },
      { label: 'Containers', url: this.containersViewURL(), component: () => <FarmStorageStocks /> },
      { label: 'Stock Swaps', url: this.stockSwapsViewURL(), component: () => <StockSwapsView /> },
      { label: 'Site View', url: this.siteLoadsViewURL(), component: () => <SiteLoadsView /> },
    ];

    if(!isSubscribedUser() && !isSystemCompany()) {
      tabs = [
        { label: 'My Stocks', url: this.ownershipViewURL(), component: () => <CompanyOwnershipStocks /> },
        { label: 'Site View', url: this.siteLoadsViewURL(), component: () => <SiteLoadsView /> },
      ]
    }

    return tabs;
  }

  storagesViewURL = () => this.props.match.url + '/storages-view'

  ownershipViewURL = () => this.props.match.url + '/ownership-view'

  otherStocksViewURL = () => this.props.match.url + '/other-stocks-view'

  stockSwapsViewURL = () => this.props.match.url + '/stock-swaps'

  siteLoadsViewURL = () => this.props.match.url + '/site-view'

  containersViewURL= () => this.props.match.url + '/containers'

  isStoragesView = () =>  this.state.activeTab == this.storagesViewURL()

  isOtherStocksView = () => this.state.activeTab == this.otherStocksViewURL()

  isOwnershipsView = () => this.state.activeTab == this.ownershipViewURL()

  isStockSwapView = () => this.state.activeTab == this.stockSwapsViewURL()

  isSiteLoadsView = () => this.state.activeTab == this.siteLoadsViewURL()

  isContainerView = () => this.state.activeTab == this.containersViewURL()

  isEmptyStoragesSelected = () => this.isSelfLocation() && this.state.storage === 'empty'

  isArchivedStoragesLoaded = () => get(this.state.archivedStorages, this.state.locationType)

  isArchivedStoragesSelected = () => this.isSelfLocation() && this.state.storage === 'archived'

  getSeason = () => map(getSeasons(), season => ({id: season, name: season}))

  getGradeList() {
    let grades = [];
    if (this.isOtherStocksView() || this.isContainerView()) {
      let items = filter(this.state.commodities, obj => { if (includes(this.state.filteredCommodities, obj.id)) return obj; });
      forEach(items, val => {
        map(val['grades'], grade => {
          if(grade.name.toLowerCase().includes(val.displayName.toLowerCase()))
            grade['commodityGradeName'] = grade.name;
          else
            grade['commodityGradeName'] = `${val.displayName}-${grade.name}`;
          return grade;
        });
        grades = [...grades, ...val.grades];
      });
    }
    return grades;
  }


  getStorages = () => {
    const { stockedStorages } = this.state;
    const isSelfLocation = this.isSelfLocation();
    let otherOptions = map(stockedStorages, storage => ({id: storage.id, name: storage.name}))
    const EmptyOption = { id: 'empty', name: this.isContainerView() ? 'Empty Containers' : 'Empty Storages' };
    const ArchivedOption = this.isContainerView() ? null : { id: 'archived', name: 'Archived Storages' };
    let allStoragesLabel = this.isContainerView() ? ALL_CONTAINER_OPTION : ALL_STORAGE_OPTION;

    if (!otherOptions)
      return isSelfLocation ? compact([allStoragesLabel, EmptyOption, ArchivedOption]) : [allStoragesLabel];


    if (isSelfLocation)
      otherOptions = compact([EmptyOption, ArchivedOption, ...otherOptions]);
    else if (otherOptions.length === 1)
      return otherOptions;

    return compact([
      allStoragesLabel,
      ...otherOptions
    ]);
  };

  getLocationOptions(farms) {
    const stockedFarms = farms || this.state.stockedFarms;
    let options = orderBy(stockedFarms, [farm => farm.name]);
    options = filter(options, farm => farm?.isActive == true);
    if (this.isSiteLoadsView())
      return options;

    return [
      ALL_LOCATION_OPTION,
      ...options
    ];
  }

  getOwners() {
    if (this.isStoragesView())
      return [];
    if (this.isOwnershipsView())
      return [...(this.state.managedOwners || [])]
    if (this.isOtherStocksView() || this.isContainerView()) {
      let owners = [ALL_OWNER_OPTION, ...(this.state.otherOwners || [])]
      if(this.ownerId && owners.length === 1)
        owners = [{id: this.ownerId, name: 'Loading...'}, ...owners]
      else
        owners = reject(owners, {name: 'Loading...'})
      if (this.isContainerView()) {
        let userCompany = this.props.user.company
        let company = {id: userCompany?.id, name: userCompany?.businessName, transactionParticipation: userCompany?.transactionParticipation, typeId: userCompany?.typeId}
        owners = [ ...owners, company]
      }
      return owners
    }
  }

  getLocation() {
    const { locationType } = this.state;
    return find(this.getLocationOptions(), { id: locationType });
  }

  getContainers = () => {
    const { locationType, commoditiesGroupedByContainers } = this.state;
    const isSelfLocation = this.isSelfLocation();
    const data = get(commoditiesGroupedByContainers, locationType);
    const AllOption = { id: 'all', name: 'All Containers' };
    const EmptyOption = { id: 'empty', name: 'Empty Containers' };

    if (!data)
      return isSelfLocation ? [AllOption, EmptyOption] : [AllOption];

    let otherOptions = map(values(data), 'info');

    otherOptions = remove(otherOptions, obj => get(obj, 'id') !== 'all');
    otherOptions = orderBy(uniqBy(otherOptions, 'id'), storage => get(storage, 'name', '').toLowerCase());
    if (isSelfLocation)
      otherOptions = [EmptyOption, ...otherOptions];
    else if (otherOptions.length === 1)
      return otherOptions;

    return [
      AllOption,
      ...otherOptions
    ];
  };

  isSelfLocation() {
    const { user } = this.props;
    const location = this.getLocation();

    return includes(
      compact([get(location, 'companyId'), get(location, 'brokerCompanyId')]),
      user.companyId
    );
  }

  handleCommodityChange = (id, selectedItems) => {
    const newState = { ...this.state };
    const items = uniqBy(selectedItems, 'id');
    if (isEmpty(selectedItems)) {
      newState.filteredGrades = []
    }
    newState.filteredCommodities = map(items, 'id')
    this.setState(newState);
  }

  handleGradeChange = (id, selectedItems) => this.setState({filteredGrades: map(uniqBy(selectedItems, 'id'), 'id')})

  handleNgrChange = (id, selectedItems) => this.setState({filteredNgrs: map(uniqBy(selectedItems, 'id'), 'id')})
  onNgrChange = (id, selectedItems) => {
    this.setState({filteredNgrs: map(uniqBy(selectedItems, 'id'), 'id')}, this.getOwnershipViewDataForOwner)
  }

  handleSeasonChange = (id, selectedItems) => this.setState({filteredSeasons: map(uniqBy(selectedItems, 'id'), 'id')})

  onStorageChange = value => {
    if (value && value !== this.state.storage && !this.storageId)
      this.setState({ storage: value }, this.fetchStorageStocks);
  };

  handleAddHomeStorageButtonClick = () => this.setState({ isAddHomeStorageSideDrawerOpened: true, })

  onHomeStorageCloseSideDraw = () => this.setState({ isAddHomeStorageSideDrawerOpened: false })

  handleAddSystemStorageButtonClick = () => this.setState({ isAddSystemStorageSideDrawerOpened: true, })

  onSystemStorageCloseSideDraw = () => this.setState({ isAddSystemStorageSideDrawerOpened: false, })

  onHomeStorageAdd = (data) => {
    this.setCacheInContext()
    this.onHomeStorageCloseSideDraw()
    this.onStorageAdd(data)
  };

  onStorageAdd = data => {
    this.setCacheInContext()
    Boolean(data) && this.initiateDataLoading()
  }

  toggleDialog = () => this.setState({ isOpen: !this.state.isOpen })

  toggleUpdateStorageDialog = () => this.setState({
    handleEmptyStorages: false, handleNegativeStockStorages: false,
    handleUpdateStock: false, handleRegradingStock: false,
    handleSiloToSiloTransfer: false, handleStocksSwap: false, handleTransfer: false,
  })

  toggleStockDifferencesDialog = () => this.setState({ stockDifferences: null, openStockDifferenceDialog: false })

  onSystemStorageAdd = data => {
    this.onStorageAdd(data)
    this.onSystemStorageCloseSideDraw()
  };

  handleOptionClick = (rowNum, key, storageId, item) => {
    this.props.dispatch(setSelectedStorageForStocks(item));
    const returnToQueryParams = this.getReturnToQueryParams();
    let params;
    if (this.isStoragesView())
      params = getStorageQueryStringByStocksStorageItemforStorageView(item);
    else
      params = getStorageQueryStringByStocksStorageItem(item);
    const queryString = params + '&' + returnToQueryParams;
    const farmId = get(item, 'farmId')
    const ngrId = get(item, 'ngrId');
    const location = find(this.getLocationOptions(), {id: farmId})
    const canUpdateStock = location?.stocksManagement && location.brokerCompanyId !== this.props.user.companyId && location.companyId !== this.props.user.companyId
    if (key === 'handle-negative-stocks')
      this.setState({ handleNegativeStockStorages: true, item: item });
    if (key === 'handle-storage-to-storage-transfer' && validateUnitForStockOperation())
      this.setState({ handleSiloToSiloTransfer: true, item: item });
    else if (key === 'empty-storage') {
      if (canUpdateStock) {
        alertifyjs.alert('Update Stock', `<div className=""><p>You cannot empty this stock because:</p><ul><li>You are not the site owner who manages this stock.</li></ul></div>`);
      }
      else {
        this.setState({ handleEmptyStorages: true, item: item });
      }
    }
    else if (key === 'update-stock') {
      if (canUpdateStock) {
        alertifyjs.alert('Update Stock', `<div className=""><p>You cannot update this stock because:</p><ul><li>You are not the site owner who manages this stock.</li></ul></div>`);
      }
      else if (validateUnitForStockOperation()) {
        this.setState({ handleUpdateStock: true, item: item });
      }
    }
    else if (key === 'regrading-stock') {
      if (canUpdateStock) {
        alertifyjs.alert('Regrade Stock', `<div className=""><p>You cannot regrade this stock because:</p><ul><li>You are not the site owner who manages this stock.</li></ul></div>`);
      }
      else if (validateUnitForStockOperation()) {
        this.setState({ handleRegradingStock: true, item: item });
      }
    }
    if (key === 'loads') {
      const currentContextPath = get(returnToQueryParams.split('returnTo='), '1');
      if (currentContextPath)
        this.props.history.replace(currentContextPath);
      if (this.isStoragesView() || this.isContainerView())
        document.location.hash = `#/farms/${farmId}/storages/${storageId || get(item, 'storageId')}/loads${queryString}`;
      else if (ngrId)
        document.location.hash = `#/farms/${farmId}/ngrs/${ngrId}/ownership-loads${queryString}`;
    }
    if (key === 'create_container_movement') {
      alertifyjs.confirm(
        "Are you sure?",
        "This action will create a Container Movement. Do you want to proceed?",
        () => {
          APIService.farms(item.farmId).storages(item.storageId).appendToUrl('container-movement/').post({'createdById': currentUser()?.id}).then(response => {
            if(response?.id)
              alertifyjs.success('Container has been moved. Reloading...', 2, () => window.location.reload())
            else
              alertifyjs.error('Unable to move the container. Please create the Container Movement from Movement Creation Form.', 0)
          })

        },
        () => {}
      )
    }
    if (key === 'handle-stocks-swap' && validateUnitForStockOperation())
      this.setState({ handleStocksSwap: true, item: item });
    if (key == 'handle-transfer' && validateUnitForStockOperation())
      this.setState({ handleTransfer: true, item: item });
  }

  handleDefaultCellClick = storage => {
    let queryString = '';
    let params;
    const returnToQueryParams = this.getReturnToQueryParams();

    if (this.isStoragesView())
      params = getStorageQueryStringByStocksStorageItemforStorageView(storage) || '?';
    else
      params = getStorageQueryStringByStocksStorageItem(storage) || '?';

    queryString = params + '&' + returnToQueryParams;

    const farmId = get(storage, 'farmId')
    const ngrId = get(storage, 'ngrId');
    if (farmId) {
      const currentContextPath = get(returnToQueryParams.split('returnTo='), '1');
      if (currentContextPath)
        this.props.history.replace(currentContextPath);
      if (this.isStoragesView() || this.isContainerView())
        document.location.hash = `#/farms/${farmId}/storages/${storage.storageId || storage.id}/loads${queryString}`;
      else if (ngrId)
        document.location.hash = `#/farms/${farmId}/ngrs/${ngrId}/ownership-loads${queryString}`;
      else
        document.location.hash = `#/farms/${farmId}/ownership-loads${queryString}`;
    }
  }

  getReturnToQueryParams = () => {
    const { match, location } = this.props;
    const { locationType, owner, storage } = this.state;
    let BASE_PATH = '/stocks';
    if (this.isOtherStocksView())
      BASE_PATH = '/stocks/other-stocks-view';
    else if (this.isContainerView())
      BASE_PATH = '/stocks/containers';
    else if (this.isOwnershipsView())
      BASE_PATH = '/stocks/ownership-view';
    else if (this.isStockSwapView())
      BASE_PATH = '/stocks/stock-swaps';
    if (get(match, 'params.farm_id'))
      BASE_PATH = location.pathname;
    const FROM = 'Stocks';
    const baseQS = `from=${FROM}`;
    let queryString = `${baseQS}&returnTo=${BASE_PATH}?`;
    if (locationType && locationType !== 'all')
      queryString += `farmId=${locationType}`;
    if (owner && owner !== 'all' && !this.isStoragesView())
      queryString += `&ownerId=${owner}`;
    if (storage && storage !== 'all' && this.isStoragesView())
      queryString += `&storageId=${storage}`;

    return queryString;
  }

  getStocks = () => {
    const { locationType } = this.state;
    if(this.isStockSwapView())
      return get(this.state.stockedCommoditiesGroupedByOwnersForSwaps, `${locationType}.commodities`) || {}
    return {}
  }

  getAddInloadButtonRowColumn = storage => {
    return (
      <AddButton
        label="Inload"
        style={{ float: 'left', marginTop: '0', marginBottom: '0' }}
        onClick={() => {
          if(storage)
            this.setState({ isAddInloadSideDrawerOpened: true, addInloadStorageId: storage.id });
        }}
        app="load"
        tooltipTitle="Update stocks by adding Inloads"
      />
    );
  }

  closeAddInloadSideDrawer = () => this.setState({ isAddInloadSideDrawerOpened: false });

  render() {
    const { locationType, activeTab, allLocation, owner, storage, ngrs } = this.state;
    const isSelfLocation = this.isSelfLocation();
    const currentLocation = this.getLocation();
    const isEmptyStoragesSelected = this.isEmptyStoragesSelected();
    const isArchivedStoragesSelected = this.isArchivedStoragesSelected();
    const isStoragesView = this.isStoragesView();
    const isOtherStocksView = this.isOtherStocksView();
    const isOwnershipsView = this.isOwnershipsView();
    const isStockSwapView = this.isStockSwapView();
    const isSiteLoadsView = this.isSiteLoadsView();
    const isContainerView = this.isContainerView();
    const seasons = this.getSeason();
    const isSubscribed = isSubscribedUser();
    const isSuperAdmin = isSystemCompany();
    const TABS = this.getStocksTabs()
    const grades = this.getGradeList();
    const owners = this.getOwners()
    const storages = this.getStorages()

    return (
      <div className="tab">
        <div className="tab-header">
          <CommonTabs
            value={activeTab}
            tabs={TABS}
            floating={true}
          />
        </div>
        <div className="tab-content">
          <div className='card col-xs-12 stocks-filters'>
            <span className="stock-main-filters">
              <span className="control" style={ isSiteLoadsView ? { width: "40%" } : { width: "30%"} }>
                <CommonAutoSelect
                  id='locationType'
                  label='Locations'
                  style={{ wordWrap: "break-word" }}
                  items={allLocation}
                  selectConfig={{ text: 'name', value: 'id' }}
                  value={locationType}
                  onChange={this.onLocationChange}
                  notClearable
                  autoSelectSingleItem
                />
              </span>
              {
                !isStoragesView && !isStockSwapView && !isSiteLoadsView &&
                  <span className="control">
                    <CommonAutoSelect
                      id='owners'
                      label='Owners'
                      items={owners}
                      selectConfig={{ text: 'name', value: 'id' }}
                      value={owner}
                      onChange={this.onOwnerChange}
                      notClearable
                    />
                  </span>
              }
              {
                locationType && locationType !== 'all' && isStoragesView && !isStockSwapView && !isSiteLoadsView &&
                  <span className="control">
                    <CommonAutoSelect
                      id='storages'
                      label='Storages'
                      items={storages}
                      selectConfig={{ text: 'name', value: 'id' }}
                      value={storage}
                      onChange={this.onStorageChange}
                      autoSelectSingleItem
                      notClearable
                    />
                  </span>
              }
              {
                locationType && locationType !== 'all' && isContainerView && !isStockSwapView &&
                  <span className="control">
                    <CommonAutoSelect
                      id='containers'
                      label='Containers'
                      items={storages}
                      selectConfig={{ text: 'name', value: 'id' }}
                      value={storage}
                      onChange={this.onStorageChange}
                      autoSelectSingleItem
                      notClearable
                    />
                  </span>
              }
              {
                isOwnershipsView &&
                  <span className="control">
                    <Autocomplete
                      multiple
                      disableCloseOnSelect
                      id="ngr"
                      limitTags={1}
                      onChange={this.onNgrChange}
                      ChipProps={{size: 'small'}}
                      options={ngrs}
                      getOptionLabel={option => option.ngrNumber}
                      value={filter(ngrs, item => includes(this.state.filteredNgrs, item.id))}
                      renderInput={
                        params => <TextField {...params} label="NGRs" variant="standard" fullWidth />
                      }
                    />
                  </span>
              }
            </span>
            <span className="stock-actions" style={{justifyContent: 'end', width: '40%', alignItems: 'center'}}>
              {
                (isStoragesView) && (locationType && locationType !== 'all') && isObserver() &&
                  <span className="control">
                    <Button size="small" color='primary' variant={this.state.zeroStocks ? 'contained' : 'outlined'} onClick={this.getStockDifferences}>
                      Stock Differences
                    </Button>
                  </span>
              }
              {
                (isOtherStocksView || isOwnershipsView || isStoragesView) &&
                  <span className="control">
                    <Button size="small" color='primary' variant={this.state.zeroStocks ? 'contained' : 'outlined'} onClick={this.toggleZeroStocks}>
                      Historical View
                    </Button>
                  </span>
              }
              {
                isStoragesView && !isStockSwapView && !isSiteLoadsView && locationType != 'all' &&
                  <span className="control">
                    <Button size="small" color='primary' variant='outlined' onClick={this.handleAddHomeStorageButtonClick}>
                      <AddIcon style={{ paddingRight: '5px' }} />
                      Storage
                    </Button>
                  </span>
              }
              {
                !isStockSwapView && !isContainerView && !isSiteLoadsView &&
                  <span className="control">
                    <Button size="small" color='primary' variant='outlined' onClick={this.handleAddSystemStorageButtonClick}>
                      <AddIcon style={{ paddingRight: '5px' }} />
                      Stock
                    </Button>
                  </span>
              }
              {
                (isSelfLocation || locationType === 'all') && isStoragesView && locationType &&
                  <span className="control csv">
                    <StorageStockCSV
                      isOpen={this.state.isOpen}
                      toggleDialog={this.toggleDialog}
                      locations={allLocation}
                      farm_id={locationType}
                      selectedOwner={parseInt(owner)}
                      user={this.props.user}
                      token={this.props.token}
                    />
                  </span>
              }
              {
                !isStoragesView && !isStockSwapView && !isContainerView && !isSiteLoadsView &&
                  <span className="control csv">
                    <OwnershipStockCSV
                      isThirdPartyStocks={isOtherStocksView}
                      isMyStocks={isOwnershipsView}
                      isOpen={this.state.isOpen}
                      toggleDialog={this.toggleDialog}
                      locations={allLocation}
                      farm_id={locationType}
                      selectedOwner={parseInt(owner)}
                      user={this.props.user}
                      token={this.props.token}
                      filteredCommodities={this.state.filteredCommodities}
                      filteredGrades={this.state.filteredGrades}
                      filteredNgrs={this.state.filteredNgrs}
                      filteredSeasons={this.state.filteredSeasons}
                    />
                  </span>
              }
              {
                isStockSwapView &&
                  <span className="control csv">
                    <StockSwapCSV
                      isThirdPatyStocks={this.isOtherStocksView()}
                      isMyStocks={this.isOwnershipsView()}
                      isOpen={this.state.isOpen}
                      toggleDialog={this.toggleDialog}
                      locations={allLocation}
                      farm_id={locationType}
                      selectedOwner={parseInt(owner)}
                      user={this.props.user}
                      token={this.props.token}
                    />
                  </span>
              }
              {
                isContainerView &&
                  <span className="control csv">
                    <ContainerCSV
                      isThirdPartyStocks={isOtherStocksView}
                      isMyStocks={isOwnershipsView}
                      isOpen={this.state.isOpen}
                      toggleDialog={this.toggleDialog}
                      locations={allLocation}
                      farm_id={locationType}
                      selectedOwner={parseInt(owner)}
                      user={this.props.user}
                      token={this.props.token}
                      filteredCommodities={this.state.filteredCommodities}
                      filteredGrades={this.state.filteredGrades}
                      filteredNgrs={this.state.filteredNgrs}
                      filteredSeasons={this.state.filteredSeasons}
                    />
                  </span>
              }
            </span>
          </div>
          {
            (isOtherStocksView  || isContainerView) &&
              <div className="tab-content">
                <div className='card col-xs-12 stocks-filters' style={{padding: '10px 20px'}}>
                  <span style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                    <span style={{width: 'calc(20% - 10px)', display: 'inline-block', marginRight: '10px'}}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        limitTags={1}
                        id="commodity"
                        onChange={this.handleCommodityChange}
                        options={isArray(this.state.commodities) ? this.state.commodities : []}
                        getOptionLabel={option => option.displayName}
                        value={filter(this.state.commodities, item => includes(this.state.filteredCommodities, item.id))}
                        renderInput={
                          params => <TextField {...params} label="Commodities" variant="standard" fullWidth />
                        }
                        ChipProps={{size: 'small'}}
                      />
                    </span>
                    <span style={{width: 'calc(20% - 10px)', display: 'inline-block', marginRight: '10px'}}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="grade"
                        limitTags={1}
                        onChange={this.handleGradeChange}
                        options={grades}
                        getOptionLabel={option => option.commodityGradeName}
                        value={filter(grades, item => includes(this.state.filteredGrades, item.id))}
                        renderInput={
                          params => <TextField {...params} label="Grades" variant="standard" fullWidth />
                        }
                        ChipProps={{size: 'small'}}
                      />
                    </span>
                    <span style={{width: 'calc(20% - 10px)', display: 'inline-block', marginRight: '10px'}}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="season"
                        limitTags={1}
                        onChange={this.handleSeasonChange}
                        options={seasons}
                        isOptionEqualToValue={(current, value) => get(value, 'id') === get(current, 'id')}
                        value={filter(seasons, item => includes(this.state.filteredSeasons, item.id))}
                        getOptionLabel={option => option.name}
                        renderInput={
                          params => <TextField {...params} label="Seasons" variant="standard" fullWidth />
                        }
                        ChipProps={{size: 'small'}}
                      />
                    </span>
                    <span style={{width: 'calc(20% - 10px)', display: 'inline-block', marginRight: '10px'}}>
                      <Autocomplete
                        multiple
                        disableCloseOnSelect
                        id="ngr"
                        limitTags={1}
                        onChange={this.handleNgrChange}
                        options={ngrs}
                        getOptionLabel={option => option.ngrNumber}
                        value={filter(ngrs, item => includes(this.state.filteredNgrs, item.id))}
                        renderInput={
                          params => <TextField {...params} label="NGRs" variant="standard" fullWidth />
                        }
                        ChipProps={{size: 'small'}}
                      />
                    </span>
                    <span style={{width: '20%', textAlign: 'right', display: 'inline-block'}}>
                      <Button size="small" color='primary' variant='outlined' onClick={this.applyFilters} style={{marginRight: '10px'}}>
                        Apply
                      </Button>
                      <Button size="small" color='default' variant='outlined' onClick={this.resetFilters}>
                        Reset
                      </Button>
                    </span>
                  </span>
                </div>
              </div>
          }

          {
            ((isSuperAdmin || isSubscribed) ? (this.state.activeTab == get(TABS, '1.url') || this.state.activeTab == get(TABS, '2.url')) : this.state.activeTab == get(TABS, '0.url')) &&
              <CompanyOwnershipStocks
                isEmptyStoragesSelected={isEmptyStoragesSelected}
                isArchivedStoragesSelected={isArchivedStoragesSelected}
                {...this.state}
                commodities={this.state.commodities}
                locations={this.state.stockedFarms}
                owners={owners}
                handleOptionClick={this.handleOptionClick}
                handleDefaultCellClick={this.handleDefaultCellClick}
                currentLocation={currentLocation}
                onSystemStorageCloseSideDraw={this.onSystemStorageCloseSideDraw}
                onSystemStorageAdd={this.onSystemStorageAdd}
                stocks={this.state.ownershipStocks}
                filteredStocks={this.state.stocks}
                isOtherStocksView={isOtherStocksView}
              />
          }
          {
            (isSuperAdmin || isSubscribed) && this.state.activeTab == TABS[0].url &&
              <FarmStorageStocks
                {...this.state}
                stocks={this.state.storageStocks}
                commodities={this.state.commodities}
                locations={this.state.stockedFarms}
                isEmptyStoragesSelected={isEmptyStoragesSelected}
                isArchivedStoragesSelected={isArchivedStoragesSelected}
                handleOptionClick={this.handleOptionClick}
                handleDefaultCellClick={this.handleDefaultCellClick}
                isSelfLocation={isSelfLocation}
                closeAddInloadSideDrawer={this.closeAddInloadSideDrawer}
                currentLocation={currentLocation}
                onSystemStorageCloseSideDraw={this.onSystemStorageCloseSideDraw}
                onSystemStorageAdd={this.onSystemStorageAdd}
                onHomeStorageCloseSideDraw={this.onHomeStorageCloseSideDraw}
                onHomeStorageAdd={this.onHomeStorageAdd}
                getAddInloadButtonRowColumn={this.getAddInloadButtonRowColumn}
                emptyStorages={this.state.emptyStorages}
                archivedStorages={this.state.archivedStorages}
              />
          }
          {
            (isSuperAdmin || isSubscribed) && this.state.activeTab == TABS[3].url &&
              <FarmStorageStocks
                {...this.state}
                stocks={this.state.storageStocks}
                commodities={this.state.commodities}
                locations={this.state.stockedFarms}
                isEmptyStoragesSelected={isEmptyStoragesSelected}
                handleOptionClick={this.handleOptionClick}
                handleDefaultCellClick={this.handleDefaultCellClick}
                currentLocation={currentLocation}
                isSelfLocation={isSelfLocation}
                onSystemStorageCloseSideDraw={this.onSystemStorageCloseSideDraw}
                onSystemStorageAdd={this.onSystemStorageAdd}
                emptyStorages={this.state.emptyStorages}
                isContainers
              />
          }
          {
            (isSuperAdmin || isSubscribed) && this.state.activeTab == TABS[4].url &&
              <StockSwapsView
                {...this.state}
                handleOptionClick={this.handleOptionClick}
                handleDefaultCellClick={this.handleDefaultCellClick}
                currentLocation={currentLocation}
                onSystemStorageCloseSideDraw={this.onSystemStorageCloseSideDraw}
                onSystemStorageAdd={this.onSystemStorageAdd}
                stocks={this.state.swapStocks}
                isOtherStocksView={isOtherStocksView}
              />
          }
          {
            isSiteLoadsView &&
              <SiteLoadsView
                selectedLocation={locationType}
                user={this.props.user}
                token={this.props.token}
              />
          }
          {
            (this.state.handleNegativeStockStorages || this.state.handleEmptyStorages || this.state.handleUpdateStock || this.state.handleRegradingStock) &&
              <UpdateStorageFilters
                emptyStorage={this.state.handleEmptyStorages}
                updateStorage={this.state.handleUpdateStock}
                regradingStock={this.state.handleRegradingStock}
                isStoragesView={isStoragesView}
                token={this.props.token}
                item={this.state.item}
                locations={allLocation}
                owners={owners}
                isOpen
                toggleDialog={this.toggleUpdateStorageDialog}
              />
          }
          {
            this.state.handleSiloToSiloTransfer &&
              <StorageToStorageTransferDialog
                isStoragesView={isStoragesView}
                token={this.props.token}
                item={this.state.item}
                locations={allLocation}
                isOpen
                siteName={currentLocation?.name}
                toggleDialog={this.toggleUpdateStorageDialog}
              />
          }
          {this.state.handleStocksSwap &&
           <SideDrawer isOpen={Boolean(this.state.handleStocksSwap)} title="Stock Swap" size='small' onClose={this.toggleUpdateStorageDialog}>
             <StockSwapDialog
               isStoragesView={isStoragesView}
               isOwnershipsView={isOwnershipsView}
               token={this.props.token}
               item={this.state.item}
               locations={allLocation}
               owners={owners}
               commodities={this.state.commodities}
               isOpen
               toggleDialog={this.toggleUpdateStorageDialog}
             />
           </SideDrawer>
          }
          {
            this.state.handleTransfer &&
             <SideDrawer
              isOpen={Boolean(this.state.handleTransfer)}
              title="Add Title Transfer"
              onClose={this.toggleUpdateStorageDialog}
              size='xlarge'>
              <CreateTitleTransfer closeDrawer={this.toggleUpdateStorageDialog} stock={this.state.item} />
            </SideDrawer>
          }
        </div>
        <StockDifferencesDialog
          stockDifferences={this.state.stockDifferences}
          isOpen={this.state.openStockDifferenceDialog}
          toggleDialog={this.toggleStockDifferencesDialog}
        />
      </div>
  );

}
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    isAddHomeStorageSideDrawerOpened: state.companies.storages.isCreateFormDisplayed,
    isAddSystemStorageSideDrawerOpened: state.companies.systemStorages.isCreateFormDisplayed,
    commodities: state.master.commodities.items,
    grades: state.master.grades.items,
  };
};

export default connect(mapStateToProps)(CompanyStocks);

import React from 'react';
import { connect } from 'react-redux';
import FreightMovements from "../freights/FreightMovements";
import MovementDetailsContainer from '../freights/MovementDetails/MovementDetailsContainer';
import {
  setBreadcrumbs,
} from '../../actions/main';
import {
  receiveFreight,
} from '../../actions/companies/freights';
import get from 'lodash/get';
import { getAbsoluteUrl } from '../../common/utils';

class ContractFreightMovements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setBreadcrumbs: true
    };
    this.handleBackClick = this.handleBackClick.bind(this);
    this.setBreadcrumbsForMovementDetails = this.setBreadcrumbsForMovementDetails.bind(this);
  }

  handleBackClick() {
    this.setState({setBreadcrumbs: true}, () => {
      this.props.dispatch(receiveFreight(null));
      document.location.hash = getAbsoluteUrl(document.location.hash);
    });
  }

  componentDidMount() {
    this.props.dispatch(receiveFreight(null));
    this.props.dispatch(setBreadcrumbs([]));
  }

  componentWillUnmount() {
    this.props.dispatch(receiveFreight(null));
  }

  componentDidUpdate() {
    if(this.props.selectedFreight && !this.props.location.search)
      this.props.dispatch(receiveFreight(null))
  }

  setBreadcrumbsForMovementDetails() {
    if(this.props.selectedFreight && this.props.breadcrumbs && this.state.setBreadcrumbs) {
      let breadcrumbs = this.props.breadcrumbs.slice(0, 2).concat([
        {text: 'Movements', route: '/contracts/' + this.props.contractId + '/movements', onClick: this.handleBackClick },
        {text: get(this.props.selectedFreight, 'identifier', '')}
      ]);
      this.setState({setBreadcrumbs: false}, () => {
        this.props.dispatch(setBreadcrumbs(breadcrumbs));
      });
    }
  }
  render() {
    return (
      <div>
        {
          (this.props.selectedFreight  && this.props.movementIdExists ) ?
          <div>
            <MovementDetailsContainer
              {...this.props}
              movement={this.props.selectedFreight}
              dontSetBreadcrumbs={true}
              breadcrumbsFunc={this.setBreadcrumbsForMovementDetails}
              backButtonHandler={this.handleBackClick}
              nested
            />
          </div> :
          <FreightMovements
              dontRedirect={true}
              dontSetBreadcrumbs={true}
              {...this.props}
              contractId={this.props.contractId}
              contract={this.props.contract}

              handleAddMovemenetButtonClick={this.props.onHandleAddMovementButtonClick}
              removeContractFromSearch={true}
              nested
            />
        }
      </div>
    );

  }

}
const mapStateToProps = state => {
  return {
    selectedFreight: state.companies.freights.selectedFreight,
    breadcrumbs: state.main.breadcrumbs,
  };
};
export default connect(mapStateToProps)(ContractFreightMovements);

import React, {Component} from 'react';


class Company extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"/>
        <rect width="36.667" height="23.333" x="1.667" y="8.333" stroke={this.props.fill} strokeWidth="3.333" rx="3.333"/>
        <path fill={this.props.fill} d="M23.333 15h10v3.333h-10zM23.333 21.667h10V25h-10z"/>
        <circle cx="10.833" cy="15.833" r="4.167" fill={this.props.fill}/>
        <path fill={this.props.fill} d="M16.667 28.341c0-3.221-2.612-6.674-5.834-6.674C7.612 21.667 5 25.12 5 28.34c0-.003 11.667-.004 11.667 0z"/>
      </g>
    </svg>;
  }
}

export default Company;

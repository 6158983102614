import React from 'react';
import { connect } from 'react-redux';

import CompaniesTable from '../../containers/CompaniesTable';
import AddButton from '../common/AddButton';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import Paper from '@mui/material/Paper';
import CommonListingButton from '../common/CommonListingButton';
import { setHeaderText, setBreadcrumbs, isLoading, forceStopLoader } from '../../actions/main';
import SideDrawer from '../common/SideDrawer';
import Button from '@mui/material/Button/Button';
import CommonButton from '../common/CommonButton';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import { getCompanyCompaniesWeb, getCompanyGroups, getCompaniesOfGroup, getPaginatedCompaniesResponse } from '../../actions/api/companies';
import { get, isEqual, isEmpty, find, reject } from 'lodash';
import { isFromCompanyDirectory } from '../../actions/companies/index';
import { getCurrentCountry, currentUserCompany, defaultViewAction } from '../../common/utils';
import AddToOrCreateCompanyGroup from './AddToOrCreateCompanyGroup';
import RenameCompanyGroup  from './RenameCompanyGroup';
import RemoveCompaniesFromGroup from './RemoveCompaniesFromGroup';
import Filters from '../common/Filters';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';


class CompanyGroups extends React.Component {
  constructor(props) {
    super(props);
    this.setQueryParameter();
    this.state = {
      allCompanies:[],
      ungroupedCompanies:[],
      allCompaniesOfSelectedGroup:[],
      companies: [],
      selectedCompanies:[],
      showCheckBoxes:false,
      openSideDrawer: false,
      openAddGroupSideDrawer: false,
      isAddingNewGroup: false,
      paginationData: null,
      filters: [],
      selectedGroup: this.groupId || 'Ungrouped Companies',
      filterApplied: false,
      openFilterSideDrawer: false,
      openRenameSideDrawer: false,
      openRemoveCompaniesFromGroupSideDrawer: false,
      applyFilters: false,
      filterValues: {
        primary_business__in: [],
        group__in: null,
        approved_buyers: false,
    },
    };

    this.handleDefaultClick = this.handleDefaultClick.bind(this);
    this.handleAddGroupButtonClick = this.handleAddGroupButtonClick.bind(this);
    this.onCloseSideDraw = this.onCloseSideDraw.bind(this);
    this.cancelGrouping = this.cancelGrouping.bind(this);
    this.onSelectAllToggle = this.onSelectAllToggle.bind(this);
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
    this.handleAddToGroupButtonClick = this.handleAddToGroupButtonClick.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
    this.openRenameGroupSideDrawer = this.openRenameGroupSideDrawer.bind(this);
    this.onCloseRenameSideDraw = this.onCloseRenameSideDraw.bind(this);
    this.openRemoveCompaniesFromGroup = this.openRemoveCompaniesFromGroup.bind(this);
    this.onCloseRemoveCompaniesFromGroupSideDrawer = this.onCloseRemoveCompaniesFromGroupSideDrawer.bind(this);
    this.openAddGroupSideDrawer = this.openAddGroupSideDrawer.bind(this);
    this.closeAddGroupSideDrawer = this.closeAddGroupSideDrawer.bind(this);
  }

  setQueryParameter() {
    const groupId = new URLSearchParams(this.props.location.search).get("group_id")
    if (groupId) {
      this.groupId = parseInt(groupId);
      this.props.getCompanyGroups(this.props.userCompanyId)
    } 
  }

  _setBreadcrumbs() {
    const breadcrumbs = [{ text: `Companies (${this.props.count})` }];
    if(!isEqual(this.props.breadcrumbs, breadcrumbs))
    this.props.setBreadcrumbs(breadcrumbs);
  }

  handleAddToGroupButtonClick = async () => this.setState({showCheckBoxes: true, companies: this.props.companies,selectedCompanies: [] });

  handleAddGroupButtonClick() {
    this.setState({openSideDrawer: true, isAddingNewGroup:false});
  }

  openAddGroupSideDrawer() {
    this.setState({openAddGroupSideDrawer: true, isAddingNewGroup:true});
  }

  closeAddGroupSideDrawer() {
    this.setState({openAddGroupSideDrawer: false, isAddingNewGroup:false});
  }

  cancelGrouping = async () =>  {
    this.setState({selectedCompanies: [], showCheckBoxes: false});
  }

  onCloseSideDraw() {
    this.setState({
      openSideDrawer: false,
      selectedCompanies: [],
      showCheckBoxes: false,
      isAddingNewGroup: false,
    });
  }

  onCloseFilterSideDraw = () => {
    this.setState({openFilterSideDrawer: false, filterValues: {...this.state.filterValues, group__in: null}});
  }

  componentDidMount() {
    this.props.isLoading('genericTableWithData');
    this.props.setHeaderText('Companies');
    this.props.getCompanyGroups(this.props.userCompanyId)
    APIService.profiles()
            .filters()
            .get(this.props.token)
            .then(res => {
                this.setState({
                    filters: get(res, 'filters.company_filters', {}),
                    filterValues: {
                      ...this.state.filterValues,
                      group__in: null
                    }
                });
            });
    this._setBreadcrumbs();
    this.props.getCompanyCompaniesWeb(this.props.userCompanyId, null, true, true, true);
    APIService.companies(this.props.userCompanyId).appendToUrl('companies/web/minimal/').get(this.props.userToken).then(companyData => this.setState({ allCompanies: companyData }));
  }

  componentDidUpdate (prevProps, prevState) {
    let isAllCompanies = this.state.selectedGroup == 'All Companies'
    this._setBreadcrumbs();
    if (!isEqual(this.props.companies, this.state.companies) && this.props.companies) {
      this.setState({companies: this.props.companies, filterValues: {...this.state.filterValues,group__in: null}});
      if (typeof this.state.selectedGroup == 'string')
        APIService.companies(this.props.userCompanyId).appendToUrl(`companies/web/minimal/?is_groups=true&ungrouped=${!isAllCompanies}`).get(this.props.userToken).then(companyData => this.setState({ allCompanies: companyData }));
    }
    if(typeof this.state.selectedGroup == 'string' && !isEqual(prevState.selectedGroup, this.state.selectedGroup)) {
      let isAllCompanies = this.state.selectedGroup == 'All Companies'
      this.props.getCompanyCompaniesWeb(this.props.userCompanyId, null, true, true, !isAllCompanies)
      this.setState({companies: this.props.companies, filterValues: {...this.state.filterValues,group__in: null}})
    }
    if(typeof this.state.selectedGroup == 'object' && (!isEqual(prevState.selectedGroup, this.state.selectedGroup)))
      APIService.companies(this.props.userCompanyId).appendToUrl(`groups/${this.state.selectedGroup?.id}/companies/minimal/`).get(this.props.userToken).then(companyData => this.setState({ allCompaniesOfSelectedGroup: companyData }));
    this.props.isFromDirectory(false)
  }


  handleDefaultClick = (item, checked) => this.setState({selectedCompanies: checked ? [...this.state.selectedCompanies, item] : reject(this.state.selectedCompanies, {id: item.id})})

  checkIsSelected = company => Boolean(company?.id && find(this.state.selectedCompanies, {id: company.id}))

  onSelectAllToggle = (event) => {
    const { filterApplied, allCompaniesOfSelectedGroup, allCompanies } = this.state
    const items = filterApplied ? allCompaniesOfSelectedGroup : allCompanies
    items.map(item => item.isCompanySelected = event.target.checked);
    this.setState({ selectedCompanies: event.target.checked ? items : [], showCheckBoxes: true })
    if (items.length > 0) {
      let message = event.target.checked ? 'Selected all companies' : 'Unselected all companies';
      alertifyjs.success(message)
    } else
      alertifyjs.error("No Companies in this group")
  }

  openRenameGroupSideDrawer() {
    this.setState({ openRenameSideDrawer: true })
  }

  onCloseRenameSideDraw() {
    this.setState({ openRenameSideDrawer: false, filterApplied: false })
  }

  openRemoveCompaniesFromGroup() {
    this.setState({ openRemoveCompaniesFromGroupSideDrawer: true})
  }

  onCloseRemoveCompaniesFromGroupSideDrawer = () => this.setState({ openRemoveCompaniesFromGroupSideDrawer: false, filterApplied: false })

  getActionsOptionMapperListItems() {
    return [
      { name: 'Add New Group', fx: () => this.openAddGroupSideDrawer() }, 
      { name: 'Rename Group', fx: () => this.openRenameGroupSideDrawer() }, 
      { name: 'Remove companies from groups', fx: () => this.openRemoveCompaniesFromGroup() },
      defaultViewAction
    ];
  }

  handleFilters = bool => this.setState({ openFilterSideDrawer: bool });
 
  onGroupChange(value) {
    if (value == "All Companies")
      this.setState({filterApplied: false, selectedGroup: 'All Companies', filterValues: {...this.state.filterValues, group__in: null}})
    else if (value == "Ungrouped Companies")
      this.setState({filterApplied: false, selectedGroup: 'Ungrouped Companies', filterValues: {...this.state.filterValues, group__in: null}})
    else {
      if (this.state.selectedGroup == "Ungrouped Companies" || this.state.selectedGroup?.id != value) {
        const selectedGroup = this.props.companyGroups.find(group => group.id === value);
        this.setState({ selectedGroup: selectedGroup, filterApplied: true, filterValues: {...this.state.filterValues, group__in: null} });
        if (selectedGroup)
          this.props.getCompaniesOfGroup(this.props.userCompanyId, selectedGroup?.id, null, false)
      }
    }
  }


  handleFilterState = (key, value) => {
    let isUngroupCompanies = typeof this.state.selectedGroup == 'string' && this.state.selectedGroup == 'Ungrouped Companies'
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters') {
        const { filters } = this.state;
        APIService.profiles()
                  .filters()
                  .post({ company_filters: filters }, this.props.token)
                  .then(() => {
                    this.props.isLoading();
                    this.props.getPaginatedCompaniesResponse([]);
                    this.state.filterApplied ? this.props.getCompaniesOfGroup(this.props.userCompanyId, get(this.state.selectedGroup, 'id'), null, false) :
                    this.props.getCompanyCompaniesWeb(this.props.userCompanyId, null, false, true, isUngroupCompanies)
                    this.setState({ filterValues: {...this.state.filterValues, group__in: null}})
                  });
      }
    });
    this.onCloseFilterSideDraw();
  };

render() {
  const { filterApplied, selectedGroup, isAddingNewGroup, selectedCompanies, allCompaniesOfSelectedGroup, allCompanies } = this.state
  const { groupCompanyItems } = this.props;
  const enableTransferToApprovedBuyers = get(currentUserCompany(), 'enableTransferToApprovedBuyers')
  const country = getCurrentCountry()
  const allItems = filterApplied ? allCompaniesOfSelectedGroup : allCompanies
  const selectedCount = selectedCompanies?.length || 0;
  const isIndeterminate = selectedCount > 0 && selectedCount < allItems.length;
  const allSelected = selectedCount && selectedCount === allItems.length;
  const isFiltered = !isEmpty(Object.entries(this.state.filters).filter(val => (typeof val[1] === 'boolean' && val[1] === true) || (Array.isArray(val[1]) && val[1].length !== 0) ))
  const companyGroupsAndUngroupedOption = [{id: 'All Companies', name:"All Companies", value:"All Companies"}, { id:"Ungrouped Companies", name: "Ungrouped Companies", value: "Ungrouped Companies" }, ...this.props.companyGroups]
  const items = filterApplied ? groupCompanyItems : this.props.companies
  let TABLE_COLUMNS = [
    { key: 'name', header: 'Business Name', className: 'large' },
    { key: 'abn', header: country?.config?.abn?.label, className: 'xsmall' },
    { key: 'keyContactNameForUser', header: 'Key Contact', className: 'xxsmall' },
    { key: 'mobile', header: 'Phone / Mobile', className: 'xsmall' },
    { key: 'address', header: 'Address', className: 'medium' },
    { key: 'businessType', header: 'Primary Business', className: 'xxsmall' },
    { key: 'groupNames', header: 'Group', className: 'xxsmall'}
  ];

  if (this.state.showCheckBoxes)
    TABLE_COLUMNS = [
      { checkbox: true, className: 'xxsmall', checked: allSelected, onChange: this.handleDefaultClick, func: this.checkIsSelected , indeterminate: isIndeterminate, selectAll: true, onSelectAll: event => this.onSelectAllToggle(event)},
      ...TABLE_COLUMNS
    ]
  return (
    <div>
      <Paper className='paper-table' style={{marginBottom: "5px", display: 'flex' }}>
        <CommonAutoSelect
          id='groups'
          label='Groups'
          style={{ wordWrap: "break-word", width: "30%" }}
          items={companyGroupsAndUngroupedOption}
          selectConfig={{ text: 'name', value: 'name' }}
          value={selectedGroup?.id == undefined ? selectedGroup : selectedGroup?.id}
          onChange={this.onGroupChange}
          notClearable
          autoSelectSingleItem
        />
      </Paper>
      <Paper className='paper-table'>
        <div style={{ position: 'relative' }}>
          {!this.state.showCheckBoxes && 
            <div style={{ position: 'absolute', right: isFiltered ? '266px' : '235px', top: '5px' }}>
              <AddButton
              label='Add to Group'
              onClick={this.handleAddToGroupButtonClick}
              />
            </div>
          }
          { this.state.showCheckBoxes && 
            <div style={{ position: 'relative'}}>
              <div style={{ position: 'absolute', right: isFiltered ? '220px': '192px', top: '0px' }}>
                <CommonButton
                  label='Cancel'
                  secondary
                  onClick={this.cancelGrouping}
                />
              </div>
            
              <div style={{ position: 'absolute', right: isFiltered ? '140px': '114px', top: '5px' }}>
                <AddButton
                label='Add'
                onClick={this.handleAddGroupButtonClick}
                disabled={selectedCompanies.length == 0}
                />
              </div>
              
            </div>
          }
          {!this.state.showCheckBoxes &&
            <div style={{ position: 'absolute', right: isFiltered ? '140px': '114px', top: '5px' }}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>
          }
          <div style={{position: 'absolute', right: '0px', top: '5px' }}>
            <Tooltip title='Apply filters' placement='top' >
              <Button
                  value={this.state.applyFilters}
                  variant="contained"
                  type='button'
                  onClick={() => this.handleFilters(true)}
                  color='primary'
                  className='add-button'
                  style={{ float: 'right', marginLeft: '10px' }}
              >
                  <FilterListIcon />
                  FILTERS{' '}
                  {+!isEmpty(Object.entries(this.state.filters).filter(val => (typeof val[1] === 'boolean' && val[1] === true) || (Array.isArray(val[1]) && val[1].length !== 0)))
                      ? `(${Object.entries(this.state.filters).filter(val => (typeof val[1] === 'boolean' && val[1] === true) || (Array.isArray(val[1]) && val[1].length !== 0)).length})`
                      : ''}
              </Button>
            </Tooltip>
          </div>
          <CompaniesTable
          columns={TABLE_COLUMNS}
          items={items}
          handleDefaultClick={this.handleDefaultClick}
          orderBy='name'
          paginationData={filterApplied ? this.props.paginationDataOfGroup : this.props.paginationData}
          isAllCompanies={selectedGroup == 'All Companies'}
          groupIdSelected={selectedGroup && selectedGroup?.id}
          isGroups={!this.props.isFromCompanyDirectory}
          />
        </div>  
        <SideDrawer isOpen={isAddingNewGroup ? this.state.openAddGroupSideDrawer : this.state.openSideDrawer} title={isAddingNewGroup?'Add New Group': 'Add To Group'} onClose={isAddingNewGroup ? this.closeAddGroupSideDrawer : this.onCloseSideDraw}>
          <AddToOrCreateCompanyGroup isAddingNewGroup={isAddingNewGroup} closeDrawer={isAddingNewGroup ? this.closeAddGroupSideDrawer : this.onCloseSideDraw} selectedCompanies={selectedCompanies}/>
        </SideDrawer> 
        <SideDrawer isOpen={this.state.openRenameSideDrawer} title='Rename Group' onClose={this.onCloseRenameSideDraw}>
          <RenameCompanyGroup closeDrawer={this.onCloseRenameSideDraw} />
        </SideDrawer>  
        <SideDrawer isOpen={this.state.openRemoveCompaniesFromGroupSideDrawer} title='Remove Companies From Group' onClose={this.onCloseRemoveCompaniesFromGroupSideDrawer}>
          <RemoveCompaniesFromGroup closeDrawer={this.onCloseRemoveCompaniesFromGroupSideDrawer} />
        </SideDrawer>
        <SideDrawer isOpen={this.state.openFilterSideDrawer} title='Add Filter' onClose={this.onCloseFilterSideDraw} size='medium' app='filters'>
          <Filters
            isLoading={() => this.props.isLoading}
            forceStopLoader={() => this.props.forceStopLoader}
            handleFilterState={this.handleFilterState}
            filters={this.state.filters}
            statusTemp={this.state.filter_statuses}
            filterValues={this.state.filterValues}
            isCompanyFilters={true}
            enableTransferToApprovedBuyers={enableTransferToApprovedBuyers}
            noHandlers
          />
        </SideDrawer>
      </Paper>
    </div>
  );
  }
}

const mapStateToProps = state => ({
  openSideDrawer: state.companies.companies.showTransferSideDrawer,
  dailyReports: state.companies.companies.showDailyReports,
  token: state.main.user.token,
  userCompanyId: state.main.user.user.companyId,
  companies: state.companies.companies.items,
  paginationData: state.companies.companies.paginationData,
  groupCompanyItems: state.companies.companies.groupCompanyItems,
  paginationDataOfGroup: state.companies.companies.paginationDataOfGroup,
  count: get(state.companies.companies, 'paginationData.count') || 0,
  breadcrumbs: state.main.breadcrumbs,
  companyGroups: state.companies.companies.companyGroups,
});

const mapDispatchToProps = (dispatch) => {

  return {
    isLoading: () => dispatch(isLoading()),
    forceStopLoader: () => dispatch(forceStopLoader()),
    getCompanyGroups: companyId => dispatch(getCompanyGroups(companyId)),
    setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
    getCompanyCompaniesWeb: (companyId, url, forceStopper, isGrouped, unGrouped) => dispatch(getCompanyCompaniesWeb(companyId, url, forceStopper, isGrouped, unGrouped)),
    setHeaderText: text => dispatch(setHeaderText(text)),
    isFromDirectory: flag => dispatch(isFromCompanyDirectory(flag)),
    getCompaniesOfGroup: (companyId, groupId, url, forceStopLoader) => dispatch(getCompaniesOfGroup(companyId, groupId, url, forceStopLoader)),
    getPaginatedCompaniesResponse: data => dispatch(getPaginatedCompaniesResponse(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompanyGroups);

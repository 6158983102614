import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import {
    getContractBids, setSelectedContractBid, editSelectedContractBid, contractBidsResponse, showQuantityDialog, duplicateSelectedContractBid,
} from '../actions/companies/contract-bids';
import { getContractBidGlobalListingTableHeaders } from "../common/constants";
import { BIDS_TAB } from "../components/contract-bids/constants";
import alertifyjs from 'alertifyjs';
import APIService from '../services/APIService';
import { includes, get } from 'lodash';
import {  getActionOptionMenu } from '../components/contract-bids/utils';
import { isCurrentUserBelongsToCompany } from '../common/utils';

class ContractBidsTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      intervalCleared: true,
      commodityIdsSet: false,
      slide: true,
    }
  } 

  render() {
    return (
      <div>
        <GenericTable
        {...this.props}
        />
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  let tab = {
    'my_active': BIDS_TAB.MY_ACTIVE_CONTRACT_BIDS,
    'archived': BIDS_TAB.ARCHIVED_CONTRACT_BIDS,
  }
  let columns = [...getContractBidGlobalListingTableHeaders(get(tab, ownProps?.tab, BIDS_TAB.CONTRACT_BIDS))];
  
  let contractBids = state.companies.contractBids.contractBids;
  let contractBidPaginatedData = state.companies.contractBids.contractBidPaginatedData;
  let clearSearchResponseMethod = contractBidsResponse;
  
  return {
    columns: [...columns, ...(ownProps.customColumns || [])],
    items: contractBids,
    scrollToTopOnUpdate: false,
    handleDefaultCellClick: false,
    clearSearch: clearSearchResponseMethod,
    paginationData: contractBidPaginatedData,
    globalSearch: true,
    noHighlight: ownProps?.isPresentation,
    optionsItems: item => getActionOptionMenu(item),
    selectedContractBid: state.companies.contractBids.selectedContractBid,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    handleOptionClick: (index, key, id, item) => {
      if (key === 'amend') {
        dispatch(setSelectedContractBid(item));
        dispatch(editSelectedContractBid(true));
      }
      if (key == 'acceptContract') {
        dispatch(setSelectedContractBid(item));
        dispatch(showQuantityDialog(true))
      }
      if (key == 'duplicate') {
        dispatch(setSelectedContractBid(item));
        dispatch(duplicateSelectedContractBid(true));
      }
      if (key == 'delete') {
        alertifyjs.confirm('Are you sure?', 'Are you sure you want to delete this contract bid?',
          () => { 
            APIService.contract_bids(item.id).put({
              'status': 'inactive'
            }).then(() => {
              dispatch(getContractBids(true, null, ownProps?.tab));
              alertifyjs.success('Contract Bid Deleted Successfully');
            })
          },
          () => {}
          ).set('reverseButtons', true).set('labels', {ok: 'Yes, Proceed', cancel: 'Cancel'});
      }
    },
    shouldOptionBeEnabled: (option, item) =>  includes(['acceptContract'], option.key) || (!includes(['acceptContract'], option.key) && isCurrentUserBelongsToCompany(item?.buyer?.companyId)),

    navigateTo: url => {
        dispatch(getContractBids(true, url, ownProps?.tab)); 
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getContractBids(true, url, ownProps?.tab));
      
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const contractBidService = APIService.contract_bids();
      contractBidService.appendToUrl(`?page_size=${pageSize}`);
      if (page) {
        contractBidService.appendToUrl(`&page=${page}`);
      }
      if (searchText) {
        contractBidService.appendToUrl(`&search=${searchText}`);
      }
      if (orderBy) {
        contractBidService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return contractBidService.URL;
    },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContractBidsTable);

import React from 'react';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs, forceStopLoader } from '../../../actions/main';
import CommodityContractInvoiceGroup from './CommodityContractInvoiceGroup';
import BrokerageInvoiceGroup from './BrokerageInvoiceGroup';
import ContractPendingAcceptanceGroup from './ContractPendingAcceptanceGroup';
import ContractPendingAmendAcceptanceGroup from './ContractPendingAmendAcceptanceGroup';
import ContractPendingVoidAcceptanceGroup from './ContractPendingVoidAcceptanceGroup';
import '../actionCentre.scss';
import isNumber from 'lodash/isNumber';
import {isCurrentUserBroker, getCountryLabel} from '../../../common/utils';

class ContractsActionCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      contractItems1: 0,
      contractItems2: 0,
      contractItems3: 0,
      contractItems4: 0,
      contractItems5: 0,
    };

    this.onContractsDataLoad1 = this.onContractsDataLoad1.bind(this);
    this.onContractsDataLoad2 = this.onContractsDataLoad2.bind(this);
    this.onContractsDataLoad3 = this.onContractsDataLoad3.bind(this);
    this.onContractsDataLoad4 = this.onContractsDataLoad3.bind(this);
    this.onContractsDataLoad5 = this.onContractsDataLoad5.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(forceStopLoader());
    this.props.dispatch(setHeaderText(getCountryLabel('actionCentre')));
    this.props.dispatch(setBreadcrumbs([{text: getCountryLabel('actionCentre')}]));
  }
  componentDidUpdate() {
    this.props.onParentDataLoad(this.getContractsItemSum());
  }


  onContractsDataLoad1(count) {
    this.setState({contractItems1: isNumber(count) ? count : 0});
  }
  onContractsDataLoad2(count) {
    this.setState({contractItems2: isNumber(count) ? count : 0});
  }
  onContractsDataLoad3(count) {
    this.setState({contractItems3: isNumber(count) ? count : 0});
  }
  onContractsDataLoad4(count) {
    this.setState({contractItems4: isNumber(count) ? count : 0});
  }
  onContractsDataLoad5(count) {
    this.setState({contractItems5: isNumber(count) ? count : 0});
  }

  getContractsItemSum() {
    const sum = this.state.contractItems1 + this.state.contractItems2 + this.state.contractItems3 +
      this.state.contractItems4 + this.state.contractItems5;
    return (sum || (sum === 0 && this.props.existingCount === 1)) ? sum : this.props.existingCount;
  }

  render() {
    return (
      <div className='action-centre-group-container'>
          {
            <div>
              <ContractPendingAcceptanceGroup onDataLoad={this.onContractsDataLoad1}/>
              <ContractPendingAmendAcceptanceGroup onDataLoad={this.onContractsDataLoad4}/>
              <ContractPendingVoidAcceptanceGroup onDataLoad={this.onContractsDataLoad5}/>
              <CommodityContractInvoiceGroup onDataLoad={this.onContractsDataLoad2}/>
              {
                isCurrentUserBroker() &&
                <BrokerageInvoiceGroup onDataLoad={this.onContractsDataLoad3}/>
              }
            </div>
          }
      </div>
    );
  }
}

export default connect()(ContractsActionCentre);

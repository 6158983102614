import React from 'react';
import { connect } from "react-redux";
import { includes, get } from "lodash";
import APIService from '../services/APIService';
import {
  getSelectedOrder,
  receiveOrder,
  receiveAllocation,
  getOrders,
  getPaginatedFreightOrdersResponse,
} from "../actions/companies/orders";
import {isLoading} from "../actions/main";
import {
  FREIGHT_ALLOCATION_TABLE_COLUMN_LIMIT,
  getFreightOrdersGlobalListingHeaders
} from "../common/constants";
import {
  isAtGlobalOrders,
  getContractIdFromCurrentRoute,
  getOrderIdFromCurrentRoute,
  getAbsoluteUrl,
} from "../common/utils";
import {
  getFreightOrderActionMenuOptions, regenerateOrderPDF
} from '../components/freights/utils';
import GenericTable from '../components/GenericTable';
import {handleFreightOrderOptionsMapper} from "../actions/companies/orders";
import FreightAllocationActions from '../components/freights/FreightAllocationActions';

class FreightAllocationsTable extends React.Component {

  render() {
    return(
      <div>
        <GenericTable {...this.props}/>
        <FreightAllocationActions {...this.props}/>
      </div>
    );
  }

}
const mapStateToProps = state => {
  const hasParentOrderId = document.location.href.includes('parent_order_id');
  const subItems = state.companies.orders.subItems;
  const clickedOption = state.companies.orders.clickedOption;
  let columns = [...getFreightOrdersGlobalListingHeaders(hasParentOrderId)];
  if (columns.length > FREIGHT_ALLOCATION_TABLE_COLUMN_LIMIT) {
    columns = columns.splice(0, FREIGHT_ALLOCATION_TABLE_COLUMN_LIMIT);
  }
  return {
    columns: columns,
    items: state.companies.orders.items,
    scrollToTopOnUpdate: false,
    optionsItems: (item) => getFreightOrderActionMenuOptions(item, subItems, clickedOption),
    currentUser: state.main.user.user,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    paginationData: state.companies.orders.paginationData,
    globalSearch: true,
    clearSearch: getPaginatedFreightOrdersResponse,
    useNestedOptionMenu: true,
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    selectedAllocationId: state.companies.orders.selectedAllocationId,
    shouldFetchOrder: state.companies.orders.shouldFetchOrder,
    selectedAllocation: state.companies.orders.selectedAllocation,
    clickedOption: clickedOption,
    subItems: subItems,
    userToken: state.main.user.token,
    voidFilter: true
  };
};

const mapDispatchToProps = (dispatch, that) => {
  const { dontRedirect } = that;
  return {
    handleOptionClick: (event, item, baseEntity) => {
      if(get(item, 'key') === 'regenerate_pdf')
        return regenerateOrderPDF(baseEntity);
      else
        dispatch(handleFreightOrderOptionsMapper(event, {...item, type: 'allocation'}, baseEntity));
    },
    handleDefaultCellClick: (item) => {
      const receiver = isAtGlobalOrders() ? receiveOrder : (item.parentOrderId ? receiveAllocation : receiveOrder);
      dispatch(getSelectedOrder(item.id, receiver, false, false, false, false, true));
      dispatch(isLoading('nonExistingComponent'));
      if(dontRedirect) {
        const orderType = isAtGlobalOrders() ? 'fo' : (item.parentOrderId ? 'fa' : 'fo');
        document.location.hash = `${getAbsoluteUrl(document.location.hash)}?orderId=${item.id}&orderType=${orderType}`;
      } else {
        document.location.hash = '/freights/orders/' + item.id + '/order';
      }
    },
    navigateTo: (url) => {dispatch(getOrders('', '', url, true));},
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}&type_id=1&type_id=2`;
      } else {
        url = `${url}?page_size=${pageSize}&type_id=1&type_id=2`;
      }
      dispatch(getOrders('', '', url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
      const contractService = APIService.freights().orders();
      contractService.appendToUrl(`web/search/?page_size=${pageSize}&type_id=1&type_id=2`);
      if (!isAtGlobalOrders()) {
        const contractId = getContractIdFromCurrentRoute();
        if(contractId) {
          contractService.appendToUrl(`&commodity_contract_id=${contractId}&type_id=1&type_id=2`);
        }
        const orderId = getOrderIdFromCurrentRoute();
        if(orderId) {
          contractService.appendToUrl(`&parent_order_id=${orderId}&type_id=1&type_id=2`);
        }
      }
      if (page) {
        contractService.appendToUrl(`&page=${page}&type_id=1&type_id=2`);
      }
      if(searchText) {
        contractService.appendToUrl(`&search=${searchText}&type_id=1&type_id=2`);
      }
      if(orderBy) {
        contractService.appendToUrl(`&order_by=${orderBy}&order=${order}&type_id=1&type_id=2`);
      }
      if(includeVoid) {
        contractService.appendToUrl(`&include_void=${includeVoid}`);
      }

      return contractService.URL;
    },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreightAllocationsTable);

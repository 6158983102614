import React from 'react';
import { connect } from 'react-redux';
import '../common/subTab.scss';

import CashPrices from './CashPricesTab';
import CommonTabs from '../common/CommonTabs';
import { setHeaderText, setBreadcrumbs, setSubHeaderText } from '../../actions/main';
import { getTracks } from '../../actions/main/index';
import ContractBidsTab from '../contract-bids/ContractBidsTab';

class CashBoard extends React.Component {
  constructor(props) {
    super(props);

    this.CASH_PRICES_URL = '/cash-board/cash-prices';
    this.CONTRACT_BIDS_URL = '/contract-bids';
    this.state = {
      activeTab: this.props.location.pathname,
    };
  }
  componentDidMount() {
    this.props.dispatch(getTracks());
    this.setHeader();
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ? { ...prevState, activeTab: nextProps.location.pathname } : prevState;
  }
  handleTabChange = value => this.setState(state => ({ ...state, activeTab: value }));

  setHeader() {
    this.setHeaderText();
    this.setBreadcrumbs();
  }

  setHeaderText() {
    const { dispatch } = this.props;
    dispatch(setHeaderText('Cash Board'));
    dispatch(setSubHeaderText(''));
  }

  setBreadcrumbs() {
    this.props.dispatch(setBreadcrumbs([{ text: 'Cash Board' }]));
  }

  render() {
    const tabs = [
      { label: 'Cash Prices', url: `${this.CASH_PRICES_URL}`, component: () => <CashPrices {...this.props}/> },
      { label: 'Contract Bids', url: `${this.CONTRACT_BIDS_URL}`, component: () => <ContractBidsTab {...this.props} /> },
  ];
    return (
      <div className="tab">
        <div className='tab-header'>{<CommonTabs value={this.props.location.pathname} tabs={tabs} />}</div>
        <div key={0} className='tab-content'>
          {this.state.activeTab === this.CASH_PRICES_URL && <CashPrices {...this.props} />}
          {this.state.activeTab === this.CONTRACT_BIDS_URL && <ContractBidsTab {...this.props} />}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
    headerText: state.main.headerText,
  };
};

export default connect(mapStateToProps)(CashBoard);

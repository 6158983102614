import axios from 'axios';
import alertifyjs from 'alertifyjs';
import isEmpty from 'lodash/isEmpty';
import { apiURL } from '../main';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const RECEIVE_GENERAL_CONDITIONS = 'RECEIVE_GENERAL_CONDITIONS';
export const ADD_GENERAL_CONDITION = 'ADD_GENERAL_CONDITION';
export const EDIT_GENERAL_CONDITION = 'EDIT_GENERAL_CONDITION';
export const CANCEL_EDIT_GENERAL_CONDITION = 'CANCEL_EDIT_GENERAL_CONDITION';
export const CLICK_ADD_GENERAL_CONDITION_BUTTON = 'CLICK_ADD_GENERAL_CONDITION_BUTTON';
export const CLICK_EDIT_GENERAL_CONDITION_ICON = 'CLICK_EDIT_GENERAL_CONDITION_ICON';
export const CLICK_EDIT_SPECIAL_CONDITION_ICON = 'CLICK_EDIT_SPECIAL_CONDITION_ICON';
export const ADD_GENERAL_CONDITION_ERRORS = 'ADD_GENERAL_CONDITION_ERRORS';
export const EDIT_GENERAL_CONDITION_ERRORS = 'EDIT_GENERAL_CONDITION_ERRORS';
export const RECEIVE_SPECIAL_CONDITIONS = 'RECEIVE_SPECIAL_CONDITIONS';
export const ADD_SPECIAL_CONDITION = 'ADD_SPECIAL_CONDITION';
export const EDIT_SPECIAL_CONDITION = 'EDIT_SPECIAL_CONDITION';
export const CLICK_ADD_SPECIAL_CONDITION_BUTTON = 'CLICK_ADD_SPECIAL_CONDITION_BUTTON';
export const ADD_SPECIAL_CONDITION_ERRORS = 'ADD_SPECIAL_CONDITION_ERRORS';

export const receiveGeneralConditions = items => ({
  type: RECEIVE_GENERAL_CONDITIONS,
  items,
});

export const addGeneralCondition = item => ({
  type: ADD_GENERAL_CONDITION,
  item,
});

export const addGeneralConditionErrors = errors => ({
  type: ADD_GENERAL_CONDITION_ERRORS,
  errors,
});

export const editGeneralCondition = item => ({
  type: EDIT_GENERAL_CONDITION,
  item,
});

export const editGeneralConditionErrors = errors => ({
  type: EDIT_GENERAL_CONDITION_ERRORS,
  errors,
});

export const cancelEditGeneralCondition = () => ({
  type: CANCEL_EDIT_GENERAL_CONDITION,
});

export const clickAddGeneralConditionButton = () => ({
  type: CLICK_ADD_GENERAL_CONDITION_BUTTON,
});

export const clickEditGeneralConditionIcon = index => ({
  type: CLICK_EDIT_GENERAL_CONDITION_ICON,
  index,
});

export const clickEditSpecialConditionIcon = index => ({
  type: CLICK_EDIT_SPECIAL_CONDITION_ICON,
  index,
});

export const receiveSpecialConditions = items => ({
  type: RECEIVE_SPECIAL_CONDITIONS,
  items,
});

export const addSpecialCondition = item => ({
  type: ADD_SPECIAL_CONDITION,
  item,
});

export const addSpecialConditionErrors = errors => ({
  type: ADD_SPECIAL_CONDITION_ERRORS,
  errors,
});

export const clickAddSpecialConditionButton = () => ({
  type: CLICK_ADD_SPECIAL_CONDITION_BUTTON,
});

export const editSpecialCondition = item => ({
  type: EDIT_SPECIAL_CONDITION,
  item,
});

const MESSAGES = {
  CREATE_SUCCESS: 'Condition was successfully added.',
  UPDATE_SUCCESS: 'Condition details were successfully updated.',
};

export const getGeneralConditions = type => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    var url = `${apiURL}/conditions/generals/?company_id=${user.user.companyId}`;
    if (type) {
      url += `&module=${type}`;
    }
    axios
      .get(url, config)
      .then(response => {
        dispatch(receiveGeneralConditions(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const getSpecialConditions = type => {
  return (dispatch, getState) => {
    const user = getState().main.user;
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    var url = `${apiURL}/conditions/specials/?company_id=${user.user.companyId}`;
    if (type) {
      url += `&module=${type}`;
    }
    axios
      .get(url, config)
      .then(response => {
        dispatch(receiveSpecialConditions(response.data));
      })
      .catch(error => {
        throw error;
      });
  };
};

export const createGeneralConditions = (data, onSuccess) => (dispatch, getState) => {
  fetch(`${apiURL}/conditions/generals/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (json.id == null) {
        dispatch(addGeneralConditionErrors(json.errors));
      } else {
        dispatch(addGeneralCondition(json));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (onSuccess) {
          onSuccess(json);
        }
      }
    });
};
export const updateGeneralCondition = (id, data) => (dispatch, getState) => {
  fetch(`${apiURL}/conditions/generals/${id}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (!isEmpty(json.errors)) {
        dispatch(editGeneralConditionErrors(json.errors));
      } else {
        dispatch(editGeneralCondition(json));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
      }
    });
};
export const createSpecialConditions = (data, onSuccess) => (dispatch, getState) => {
  fetch(`${apiURL}/conditions/specials/`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (json.id == null) {
        dispatch(addSpecialConditionErrors(json.errors));
      } else {
        dispatch(addSpecialCondition(json));
        if (onSuccess) {
          onSuccess(json);
        }
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
      }
    });
};
export const updateSpecialCondition = (id, data) => (dispatch, getState) => {
  fetch(`${apiURL}/conditions/specials/${id}/`, {
    method: 'PUT',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
    },
  })
    .then(response => response.json())
    .then(json => {
      if (!isEmpty(json.errors)) {
        dispatch(editGeneralConditionErrors(json.errors));
      } else {
        dispatch(editSpecialCondition(json));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
      }
    });
};

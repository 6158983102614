import React from "react";
import alertifyjs from 'alertifyjs'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { get, sum, without, includes, map, startCase } from 'lodash';
import APIService from '../../services/APIService';
import CommonTextField from '../common/CommonTextField';
import SideDrawer from "../common/SideDrawer";
import GenericTable from "../GenericTable";
import { MT_UNIT } from "../../common/constants";
import { positiveDecimalFilter } from "../../common/input-filters";
import { CONTRACT_TYPES } from "../../common/constants";
import { getCountryLabel } from "../../common/utils";
import { PRICE_POINTS } from "../../common/constants";

class ContractStockAllocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.tonnageLabel = getCountryLabel('tonnage');
    this.state = {
      stocks: [],
      selectedStocks: [],
      editItemCurrentStock: undefined,
      editItemAllocatedTonnage: undefined,
      editItemAllocatedTonnageError: undefined,
    }
  }

  componentDidMount() {
    const {contract} = this.props;
    const sellerCompanyId = get(contract, 'seller.companyId');
    let gradeIds = [contract.gradeId];
    if (contract.typeId === CONTRACT_TYPES.MULTI_GRADE && get(contract, 'spread.details')) {
      gradeIds = get(contract, 'spread.details').map(spreadDetails => spreadDetails.id);
    }
    if (get(this.props, 'editItem')) {
      const editItem = this.props.editItem;
      APIService.stocks()
        .companies(sellerCompanyId)
        .appendToUrl(`stocks/allocations/?commodity_id=${contract.commodityId}&grade_ids=${gradeIds.join(',')}&season=${contract.season}&farm_ids=${editItem.siteId}&ngr_id=${editItem.ngrId}`)
        .get()
        .then(response => {
          let stocks = get(response, `${contract.commodityId}.0`);
          this.setState({editItemCurrentStock: stocks, editItemAllocatedTonnage: get(editItem, 'tonnage')});
        })
    }
    else {
      let urlString = `stocks/allocations/?commodity_id=${contract.commodityId}&grade_ids=${gradeIds.join(',')}&season=${contract.season}`;
      if (contract.pricePointId === PRICE_POINTS.EX_FARM_STORE) {
        const consignorsWithSites = get(contract, 'consignorsWithSites')
        urlString += `&farm_ids=${consignorsWithSites.map(item => item.handlerId).join(',')}`
      }
      APIService.stocks()
        .companies(sellerCompanyId)
        .appendToUrl(urlString)
        .get()
        .then(response => {
          let stocks = get(response, contract.commodityId);
          if (stocks) {
            const isQuantityBased = get(contract, 'commodity.isStrictQuantityBased');
            if (isQuantityBased)
              stocks = stocks.filter(stock => stock.availableQuantity > 0);
            else
              stocks = stocks.filter(stock => stock.availableTonnage > 0);
            if (stocks.length === 0)
              alertifyjs.error("No Stocks Found", 2, () => this.props.onClose());
            else {
              stocks = stocks.map((stock, index) => {
                return { ...stock, id: index + 1, allocatedTonnage: parseFloat((isQuantityBased ? stock.availableQuantity : stock.availableTonnage).toFixed(2)) };
              });
              this.setState({stocks: stocks});
            }
          }
          else {
            alertifyjs.error("No Stocks Found", 2, () => this.props.onClose());
          }
        })
    }
  }

  getSubmitData() {
    const {contract} = this.props;
    return this.state.selectedStocks.map(stock => {
      return {
        commodityId: contract.commodityId,
        gradeId: stock.gradeId,
        ngrId: stock.ngrId,
        tonnage: stock.allocatedTonnage,
        season: stock.season,
        siteId: stock.farmId
      }
    })
  }

  onSubmit = event => {
    event.preventDefault();
    if (this.state.selectedStocks.length === 0)
      alertifyjs.error("No stock selected");
    else {
      let totalAllocatedTonnage = parseFloat(this.totalAllocatedTonnage() || 0);
      let remainingTonnageAllocationOnContract = parseFloat((get(this.props.contract, 'tonnage') - get(this.props.contract, 'allocatedTonnageForUser')).toFixed(2));
      if (totalAllocatedTonnage > remainingTonnageAllocationOnContract)
        alertifyjs.error(`Total allocated ${this.tonnageDisplayValue().toLowerCase()} should be less than or equal to contract remaining allocated ${this.tonnageDisplayValue().toLowerCase()}`, 5);
      else {
        const data = this.getSubmitData();
        this.props.onClose();
        APIService.contracts(this.props.contract.id)
          .appendToUrl('stock-allocations/')
          .post(data)
          .then(resp => {
            if (get(resp, 'errors')) {
              alertifyjs.error("An Error Occurred");
            }
            else {
              alertifyjs.success("Stocks Allocated", 5);
              window.location.reload();
            }
          })
      }
    }
  }

  isSelectedStock = stockId => includes(map(this.state.selectedStocks, 'id'), stockId);

  handleAllocatedTonnageChange(event, stock) {
    const { stocks, selectedStocks } = this.state;
    let value = event.target.value;
    const tonnageProperty = this.tonnageProperty();
    if (get(this.props.contract, 'commodity.isStrictQuantityBased') ? value > stock.availableQuantity : value > stock.availableTonnage)
      alertifyjs.error(`Entered ${tonnageProperty} cannot be greater than available ${tonnageProperty}`);
    else {
      this.setState({selectedStocks: map(selectedStocks, selectedStock => {
        if(selectedStock.id === stock.id)
          selectedStock.allocatedTonnage = value ? parseFloat(value) : 0;
        return selectedStock;
      }), stocks: map(stocks, currentStock => {
        if(currentStock.id === stock.id)
          currentStock.allocatedTonnage = value ? parseFloat(value) : 0;
        return currentStock;
      })});
    }
  }

  getColumns() {
    const tonnageDisplayValue = this.tonnageDisplayValue();
    const tonnageProperty = this.tonnageProperty();
    return [
      {checkbox: true, onChange: 'onChange', func: item => this.isSelectedStock(item.id)},
      { key: 'ngrNumber', header: 'NGR Number'},
      { key: 'farmName', header: 'Location'},
      { key: 'gradeName', header: 'Grade'},
      { key: 'season', header: 'Season'},
      { key: `${tonnageProperty}`, header: `Total ${tonnageDisplayValue}`},
      { header: `Allocated ${tonnageDisplayValue}`, formatter: stock => (get(stock, tonnageProperty) - get(stock, `available${this.titleCasedTonnageProperty()}`)).toFixed(2)},
      { header: `Allocate ${tonnageDisplayValue}`,
        formatter: stock => <input
                             disabled={!this.isSelectedStock(stock.id)}
                             size='small'
                             value={stock.allocatedTonnage}
                             type='number'
                             step="1"
                             onChange={event => this.handleAllocatedTonnageChange(event, stock)} />
      }
    ];
  }

  onStockCheckboxChange = (selectedStock, isChecked) => {
    if(isChecked)
      this.setState({selectedStocks: [...this.state.selectedStocks, selectedStock]});
    else
      this.setState({selectedStocks: without(this.state.selectedStocks, selectedStock)});
  };

  totalAllocatedTonnage = () => sum(map(this.state.selectedStocks, 'allocatedTonnage')).toFixed(2);

  unit() {
    return get(this.props.contract, 'commodity.priceUnit', MT_UNIT);
  }

  handleStockAllocationEdit = event => {
    event.preventDefault();
    if (this.state.editItemAllocatedTonnageError) {
      return;
    }
    else {
      let remainingTonnageAllocationOnContract = parseFloat(((get(this.props.contract, 'tonnage') - get(this.props.contract, 'allocatedTonnageForUser')) + get(this.props, 'editItem.tonnage')).toFixed(2));
      if (this.state.editItemAllocatedTonnage > remainingTonnageAllocationOnContract) {
        this.setState({editItemAllocatedTonnageError: `Allocated ${this.tonnageLabel.toLowerCase()} cannot be greater than contract unallocated ${this.tonnageLabel.toLowerCase()} (${remainingTonnageAllocationOnContract} ${this.unit()})`});
        return;
      }
      this.props.onClose();
      APIService.contracts()
        .appendToUrl(`stock-allocations/${this.props.editItem.id}/`)
        .put({tonnage: this.state.editItemAllocatedTonnage})
        .then(() => this.props.fetchAllocations())
    }
  }

  handleStockAllocationUpdate = event => {
    let value = event.target.value;
    let errorText = undefined;
    let tonnageDisplayValue = this.tonnageDisplayValue();
    if(!value)
      errorText = "This field is required";
    else if (value > get(this.state.editItemCurrentStock, `available${this.titleCasedTonnageProperty()}`) + get(this.props, 'editItem.tonnage'))
      errorText = `Entered ${tonnageDisplayValue.toLowerCase()} cannot be greater than available ${tonnageDisplayValue.toLowerCase()}`
    this.setState({editItemAllocatedTonnage: event.target.value, editItemAllocatedTonnageError: errorText});
  }

  tonnageDisplayValue = () => get(this.props.contract, 'commodity.isStrictQuantityBased') ? 'Quantity' : this.tonnageLabel;

  tonnageProperty = () => get(this.props.contract, 'commodity.isStrictQuantityBased') ? 'quantity' : 'tonnage';

  titleCasedTonnageProperty = () => startCase(this.tonnageProperty());

  render() {
    const {open, onClose, contract, editItem} = this.props;
    const { editItemCurrentStock, editItemAllocatedTonnage, editItemAllocatedTonnageError } = this.state;
    const tonnageDisplayValue = this.tonnageDisplayValue();
    const tonnageProperty = this.tonnageProperty();
    const titleCasedTonnageProperty = this.titleCasedTonnageProperty();
    const totalAllocatedTonnage = get(editItemCurrentStock, tonnageProperty, 0) - get(editItemCurrentStock, `available${titleCasedTonnageProperty}`, 0);
    const tonnageAvailableForAllocation = get(editItemCurrentStock, `available${titleCasedTonnageProperty}`, 0) + get(editItem, 'tonnage');


    return (
      <React.Fragment>
        {
          editItem ?
          <Dialog fullWidth open={open} onClose={onClose}>
            <DialogTitle>
              {`Stock Allocation | ${editItem.commodityName} | ${editItem.gradeName} | ${editItem.ngrNumber} | ${editItem.siteName}`}
            </DialogTitle>
            <DialogContent>
              <div className="col-sm-12" style={{marginTop: "15px"}}>
                <CommonTextField
                  variant="standard"
                  label={`Total ${tonnageDisplayValue}`}
                  disabled
                  value={get(editItemCurrentStock, tonnageProperty)}
                />
              </div>
              <div className="col-sm-12" style={{marginTop: "15px"}}>
                <CommonTextField
                  variant="standard"
                  label={`Total Allocated ${tonnageDisplayValue}`}
                  value={totalAllocatedTonnage ? totalAllocatedTonnage.toFixed(2) : totalAllocatedTonnage}
                  disabled
                />
              </div>
              <div className="col-sm-12" style={{marginTop: "15px"}}>
                <CommonTextField
                  variant="standard"
                  label={`Allocated ${tonnageDisplayValue} (${get(this.props.contract, 'identifier')})`}
                  value={get(editItem, 'tonnage')}
                  disabled
                />
              </div>
              <div className="col-sm-12" style={{marginTop: "15px"}}>
                <CommonTextField
                  variant="standard"
                  label={`Allocate ${tonnageDisplayValue} (Available ${tonnageAvailableForAllocation.toFixed(2)} ${this.unit()})`}
                  value={editItemAllocatedTonnage}
                  onChange={this.handleStockAllocationUpdate}
                  helperText={editItemAllocatedTonnageError}
                  onKeyDown={event => positiveDecimalFilter(event, 2, tonnageAvailableForAllocation ||99999.99)}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <div style={{marginTop: '20px'}}>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={this.handleStockAllocationEdit} color='primary'>Save</Button>
              </div>
            </DialogActions>
          </Dialog>
          :
          <SideDrawer
            isOpen={open}
            title={`Stock Allocation: ${get(contract, 'displayName')}`}
            onClose={onClose}
            size="xlarge"
          >
            <div style={{marginTop: '15px'}}>
              <GenericTable
                items={this.state.stocks}
                columns={this.getColumns()}
                onChange={this.onStockCheckboxChange}
                showHeader={true}
                showHeaderValue={false}
              />
            </div>
            <div style={{marginTop: '20px', float: 'right'}}>
              {`Total Allocated ${tonnageDisplayValue}`}: {this.totalAllocatedTonnage()}{this.unit()}
            </div>
            <div style={{marginTop: '50px', marginLeft: '856px'}}>
              <Button onClick={onClose}>Cancel</Button>
              <Button onClick={this.onSubmit} color='primary'>Save</Button>
            </div>
          </SideDrawer>
        }
      </React.Fragment>
    )
  }
}

export default ContractStockAllocationForm;

import React from 'react';
import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import { find, merge, map, isArray, isEmpty, omit, flatten, some, get, includes, mergeWith, remove, cloneDeep } from 'lodash';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { setHeaderText, setBreadcrumbs, isLoading, forceStopLoader, setSubHeaderText } from '../../../actions/main';
import APIService from '../../../services/APIService';
import CommonButton from '../../common/CommonButton';
import CustomEmailDialog from '../../common/CustomEmailDialog';
import { isCurrentUserBroker } from '../../../common/utils';
import CommodityContractParties from '../../freights/CommodityContractParties';
import DeclarationType from './DeclarationType';
import CommodityInfo from './CommodityInfo';
import DeliveryInfo from './DeliveryInfo';
import ProductIntegrity from './ProductIntegrity';
import ChemicalsInfo from './ChemicalsInfo';
import AdditionalInfo from './AdditionalInfo';
import Preview from '../Preview';
import { getAutoSelectFocusField } from '../../../common/utils';
import CounterPartiesInfo from './CounterPartiesInfo';
import Signatory from './Signatory';
const EMAIL_PARTIES = ['seller', 'buyer', 'broker', 'consignor', 'consignee'];

class VendorDecForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      updateCommodityFields:false,
      sellerDetails: [],
      buyerDetails: [],
      party: null,
      selectedTemplate: null,
      validate: false,
      partyValidate: false,
      commodityValidate: false,
      URLSelectedEntityType: null,
      URLSelectedEntityId: null,
      selectedEntity: null,
      preview: false,
      previewData: '',
      showEmailDialog: false,
      isVarietyMandatoryInCommodityDec: false,
      errors: {
        declarationType: true,
        chemicalInfo: true,
        deliveryInfo: true,
        productIntegrity: true,
        commodity: false,
        party: true,

      },
      data: {},
      constants: {},
      commodity:{},
      isNotIndependent:true,
      typeId: 0,
      gradeId: undefined,
    };
    this.formRef = React.createRef();
    this.updateData = this.updateData.bind(this);
    this.updateParty = this.updateParty.bind(this);
    this.updateCommodity = this.updateCommodity.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.isNotIndependentCheck = this.isNotIndependentCheck.bind(this);

    this.fieldsOrder = [
      "data.identifier", "data.externalReferenceNumber", "data.referenceEntity",
      'sellerDetails.companyId', 'sellerDetails.contactId', 'buyerDetails.companyId', 'buyerDetails.contactId',
      "commodity.commodityId", "commodity.varietyId", "commodity.gradeId", 'commodity.tonnage', "commodity.season", "data.deliveryStartDate",
      "data.deliveryEndDate", "data.commoditySource", "data.gmo", 'signatoryContact', 'signatoryCompany'
    ];
    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.unregToken = get(this.props.match, 'params.salt');
  }

  componentDidMount() {
    this.setParamsFromURL();
    this.fetchConstants();
    this.setHeader();
    this.props.dispatch(forceStopLoader());
    if (this.unregToken) this.setState({isNotIndependent: true});
  }

  isLockedToOneParent() {
    const [entityId, entity] = this.extractEntityDetailsFromURL();
    return Boolean(entityId && entity);
  }


  openEmailDialog = () => {
    this.setState({ showEmailDialog: true });
    if (this.unregToken)
    {const data = {
      body: null,
      subject: this.getEmailSubject(),
      recipients: {creator: [get(this.state.selectedEntity, 'comm')]},
      scheduled: false,
      acceptanceRequired: false,
    };
      this.create(data);}
  };

  closePreview = () => {
    this.setState({preview: false});
  };

  extractEntityDetailsFromURL() {
    const { location } = this.props;
    const query = new URLSearchParams(location.search);
    return [query.get('entityId'), query.get('entity')];
  }

  setParamsFromURL() {
    const [entityId, entity] = this.extractEntityDetailsFromURL();
    if(entityId && entity)
      this.setState({URLSelectedEntityId: entityId, URLSelectedEntityType: entity}, () => {
        this.fetchEntityDetails({entity: entity, id: entityId});
      });
  }

  fetchConstants() {
    const { typeId }  = this.state;
    APIService.vendor_decs().constants().appendToUrl(`${typeId}/`).get(this.unregToken).then(constants => {
      this.setState({constants: constants});
    });
  }

  customizer = (objValue, srcValue) =>{
      return srcValue;
  };

  updateData(updates, errors) {
    this.setState({
      data: mergeWith(this.state.data, updates, this.customizer),
      validate:false,
      errors: merge(this.state.errors, errors)
    });
  }

  updateCommodity(updates, errors) {
    this.setState({
      commodity: updates,
      commodityValidate: false,
      errors: merge(this.state.errors, errors)
    });
  }

  updateParty(seller, buyer, errors, contacts){
    this.setState({
      sellerDetails: seller,
      buyerDetails: buyer,
      partyValidate: false,
      errors: merge(this.state.errors, errors),
      sellerContacts: contacts.sellerContacts,
      buyerContacts: contacts.buyerContacts
    });

  }
  setHeader() {
    this.setHeaderText();
    this.setBreadcrumbs();
  }

  setHeaderText() {
    const { dispatch } = this.props;
    dispatch(setHeaderText('Create Grain Commodity Vendor Declaration'));
    dispatch(setSubHeaderText(''));
  }

  setBreadcrumbs() {
    const { dispatch } = this.props;
    const breadcrumbs = [
      {text: 'Vendor Declarations', route: '/vendor-decs'},
      {text: 'New'}
    ];
    dispatch(setBreadcrumbs(breadcrumbs));
  }

  onReferenceEntitySelect = (entity, template) => {
      if (entity)
        this.fetchEntityDetails(entity, template);
      else
        this.setState({ selectedEntity: null, selectedTemplate: template }, () =>{
          setTimeout(
            () => this.setState({updateCommodityFields: !this.state.updateCommodityFields, isVarietyMandatoryInCommodityDec: false}),
            500
          );
        });
  };

  fetchEntityDetails(entity, template) {
    APIService.profiles().appendToUrl(`transactions/${entity.entity}/${entity.id}/`).get(this.unregToken).then(data => {
      if (!get(data, 'contractId') && !get(data, 'movementId') && !get(data, 'orderId') && !get(data, 'externalReferenceNumber'))
        this.setState({ isNotIndependent: false, updateCommodityFields: !this.state.updateCommodityFields });

      this.setState({ selectedEntity: data, selectedTemplate: template, updateCommodityFields: !this.state.updateCommodityFields }, 
          () => {
            this.fetchContractContacts();
            this.fetchVarietyMandatorySetting();
          });
    });
  }

  fetchContractContacts() {
    const { selectedEntity } = this.state;
    const contractId = get(selectedEntity, 'contractId');
    if(contractId) {
      APIService.contracts(contractId).appendToUrl('contacts/').get(this.unregToken).then(contacts => {
        this.setState({contacts: contacts});
      });
    }
    else {
      const id = get(selectedEntity, 'id');
      const type = get(selectedEntity, 'entity');
      APIService.freights().appendToUrl(`${type}/${id}/contacts/`).get(this.unregToken).then(contacts => {
        this.setState({ contacts: contacts });
      });
    }
  }

  fetchVarietyMandatorySetting(){
    let companyId = get(this.state, 'selectedEntity.inload_site_company') || get(this.state, 'selectedEntity.freightDelivery.consignee.handlerCompanyId');
    if (!companyId && get(this.state, 'selectedEntity.entity') == 'contract')
      companyId = get(this.state, 'selectedEntity.buyer.companyId');
    APIService.companies(companyId).get(this.unregToken).then(res =>{
      this.setState({isVarietyMandatoryInCommodityDec: res.isVarietyMandatoryInCommodityDec});
    });
  }

  onChangeSpreadGrade = (val, id) =>{
    this.setState({
      [id]: val
    });
  };

  validate() {
    this.setState({validate: true, commodityValidate: true, partyValidate: true});
  }

  getPayload() {
    const { data, selectedEntity, sellerDetails, buyerDetails, commodity, isNotIndependent , gradeId} = this.state;
    let payload = { ...data };
    payload = omit(payload, ['signatoryCompany']);
    if (selectedEntity && isNotIndependent) {
      if (selectedEntity.entity == 'contract')
        payload.contractId = selectedEntity.id;
      if (selectedEntity.entity == 'freightorder')
        payload.orderId = selectedEntity.id;
      if (selectedEntity.entity == 'freightcontract')
        payload.movementId = selectedEntity.id;
      payload.sellerId = selectedEntity.seller.id;
      payload.buyerId = selectedEntity.buyer.id;
      payload.commodityId = selectedEntity.commodityId;
      payload.varietyId = selectedEntity.varietyId || get(commodity, 'varietyId.value');
      payload.gradeId = gradeId || selectedEntity.gradeId;
      payload.tonnage = selectedEntity.tonnage;
      payload.season = selectedEntity.season;
    }
    if (data.externalReferenceNumber && !isNotIndependent) {
      payload.commodityId = commodity.commodityId.value;
      payload.varietyId = commodity.varietyId.value;
      payload.gradeId = commodity.gradeId.value.id || commodity.gradeId.value;
      payload.tonnage = commodity.tonnage.value;
      payload.season = commodity.season.value;
      payload.seller = sellerDetails;
      payload.buyer = buyerDetails;
    }
    if (selectedEntity && !data.externalReferenceNumber && !isNotIndependent) {
    if (selectedEntity.entity == 'contract')
      payload.contractId = selectedEntity.id;
    if (selectedEntity.entity == 'freightorder')
      payload.orderId = selectedEntity.id;
    if (selectedEntity.entity == 'freightcontract')
      payload.movementId = selectedEntity.id;
      payload.seller = sellerDetails;
      payload.buyer = buyerDetails;
      payload.commodityId = selectedEntity.commodityId;
      payload.varietyId = selectedEntity.varietyId || get(commodity, 'varietyId.value');
      payload.gradeId = selectedEntity.gradeId;
      payload.tonnage = selectedEntity.tonnage;
      payload.season = selectedEntity.season;
    }
    if (isArray(payload.chemicals) && !isEmpty(payload.chemicals))
      payload.chemicals = flatten(map(payload.chemicals, chemical => omit(chemical, 'id')));
    if (!isEmpty(payload.previousCommoditiesHeld)) {
      let commoditiesHeld = cloneDeep(payload.previousCommoditiesHeld);
      payload.previousCommoditiesHeld = remove(commoditiesHeld, item => { return item != 0; });
    }
    if (!isEmpty(payload.previousCropGrown)) {
      let commoditiesHeld = cloneDeep(payload.previousCropGrown);
      payload.previousCropGrown = remove(commoditiesHeld, item => { return item != 0; });
    }
    return payload;
  }

  getPreview() {
    const { dispatch } = this.props;
    const data = this.getPayload();
    dispatch(isLoading('vendorDecPreview'));
    APIService.vendor_decs().appendToUrl('soft-view/').request(
      'POST', data, this.unregToken, { responseType: 'blob' }
    ).then(response => {
      this.setState({
        preview: true,
        previewData: window.URL.createObjectURL(response.data)
      }, () => {
        dispatch(forceStopLoader());
      });
    });
  }

  closeEmailDialog = (communicationData, justClose) => {
    if(justClose) {
      this.gotOncePartyContacts = false;
      this.setState({showEmailDialog: false});
    }
    else if(this.state.showEmailDialog) {
      this.setState({showEmailDialog: false}, () => {
        this.create(communicationData);
      });
    }
  };

  create = communicationData => {
    const { dispatch } = this.props;
    const data = this.getPayload();
    data.communication = communicationData || {};
    dispatch(isLoading('creatingVendorDec'));
    APIService.vendor_decs().post(data, this.unregToken).then(response => {
      dispatch(forceStopLoader());
      if (response.errors) {
        alertifyjs.error('An Error Occurred', 3);
      }
      else {
        this.closePreview();
        if (this.unregToken)
          window.location.hash = '/submit/success';
        else
          APIService.vendor_decs(get(response, 'id')).appendToUrl('can-user-view/').get().then(res => {
            window.location.hash = this.getAfterCreateRedirectURL(res);
          });
      }
    });
  };

  getAfterCreateRedirectURL(canUserView=true) {
    const defaultURL = '/vendor-decs';
    const entity = get(this.state.selectedEntity, 'entity');
    const id = get(this.state.selectedEntity, 'id');

    if(canUserView) {
      if(entity === 'contract')
        return `/contracts/${id}/vendor-decs`;
      if(entity === 'freightorder')
        return `/freights/orders/${id}/vendor-decs`;
      if(entity === 'freightcontract')
        return `/freights/movements/${id}/vendor-decs`;
    }

    return defaultURL;
  }


  onSubmit = event => {
    event.preventDefault();
    if (!this.state.selectedEntity && this.state.isNotIndependent) {
      alertifyjs.error('Please select either Contract, Order or Movement', 3);
      window.scrollTo(0, 0);
      return;
    }
    if (!this.state.isNotIndependent && !this.state.data.externalReferenceNumber && !this.state.URLSelectedEntityId) {
      alertifyjs.error('Please provide a reference number.', 3);
      const formField = this.fieldRef["data.externalReferenceNumber"];
      formField.current.focus();
      return;
    }
    if(!isEmpty(get(this.state.data, 'attachments')) && isEmpty(get(this.state.data, 'notes')))
      return;
    this.validate();
    this.checkIdentifierUniqueness();
    this.focusOnFirstErrorField();
  };

  checkIdentifierUniqueness() {
    const identifier = get(this.state.data, 'identifier');
    if(identifier) {
      APIService.vendor_decs().appendToUrl(`${identifier}/is-unique/`).get(this.unregToken).then(response => {
        if(response.isUnique) {
          if(!this.hasErrors())
            this.getPreview();
        }
        else
          alertifyjs.error('Vendor Dec with this identifier already exists.', 3);
      });
    }
  }

  saveTemplate = event => {
    event.preventDefault();
    alertifyjs.prompt(
      'Save Template',
      'Enter Template Name',
      '',
      (event, value) =>  {
        const { dispatch } = this.props;
        const data = this.getPayload();
        data.status = 'template';
        data.templateName = value;
        delete data.identifier;
        dispatch(isLoading('savingTemplate'));
        APIService.vendor_decs().post(data, this.unregToken).then(response => {
          dispatch(forceStopLoader());
          if(response.errors) {
            dispatch(forceStopLoader());
            alertifyjs.error('An Error Occurred', 3);
          } else
          alertifyjs.success('Saved Template: ' + value);
        });
      },
      () => { }
    );
  };

  isSingleCommoditySource() {
    if (get(this.state.data, 'commoditySource', false))
      return includes(get(this.state.data, 'commoditySource', '').toLowerCase(), 'single source');

    return;
  }

  hasErrors() {
    return some(this.state.errors);
  }

  shouldRenderParentDependentComponents() {
    if(this.isLockedToOneParent())
      return this.state.selectedEntity;

    return true;
  }
  isSellerOrBuyer(){
    const {selectedEntity, sellerDetails, buyerDetails }= this.state;
    let buyer = get(selectedEntity, 'buyer.companyId') || get(buyerDetails, 'companyId');
    let seller = get(selectedEntity, 'seller.companyId') || get(sellerDetails, 'companyId');

    return includes([seller, buyer], this.props.user.companyId);
  }

  getSelectedEmailParties() {
    if(isCurrentUserBroker() || !this.isSellerOrBuyer())
      return ['seller', 'buyer'];

    return ['buyer'];
  }

  getEmailSubject() {
    const { user } = this.props;
    const identifier = get(this.state.data, 'identifier', '');
    return `${get(user, 'company.name', '')} Vendor Declaration #${identifier}`;
  }

  getPartyContacts() {
    const { contacts, sellerContacts, buyerContacts } = this.state;
    return {
      seller: get(contacts, 'seller.contacts') || sellerContacts,
      buyer: get(contacts, 'buyer.contacts') || buyerContacts,
      broker: get(contacts, 'broker.contacts'),
    };
  }

  getPartyEmails() {
    const { contacts, sellerDetails, buyerDetails, sellerContacts, buyerContacts } = this.state;
    return {
      seller: get(contacts, 'seller.selected.email') || get(find(sellerContacts, {id: get(sellerDetails, 'contactId')}), 'email'),
      buyer: get(contacts, 'buyer.selected.email') || get(find(buyerContacts, {id: get(buyerDetails, 'contactId')}), 'email'),
      broker: get(contacts, 'broker.selected.email'),
    };
  }

  focusOnFirstErrorField() {
    for (let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = get(this.state, this.fieldsOrder[i]);
      const nestedFields = [
        "sellerDetails.companyId", "sellerDetails.contactId", "buyerDetails.companyId",
        "buyerDetails.contactId", "commodity.commodityId", "commodity.varietyId", "commodity.gradeId",
      ];
      const currentFields = [ "data.identifier", "data.deliveryStartDate", "data.deliveryEndDate", "data.commoditySource", "data.gmo"];
      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (
          (
            this.fieldsOrder[i] === "sellerDetails.companyId" &&
            get(this.state.errors, 'party')
          ) ||
          (
            this.fieldsOrder[i] === "buyerDetails.companyId" &&
            get(this.state.errors, 'party')
          ) ||
          (
            this.fieldsOrder[i] === "commodity.commodityId" &&
            get(this.state.commodity, 'commodityId.errors.length')
          ) ||
          (
            this.fieldsOrder[i] === "commodity.varietyId" &&
            get(this.state.commodity, 'varietyId.errors.length', false)
          ) ||
          (
            this.fieldsOrder[i] === "commodity.gradeId" &&
            get(this.state.commodity, 'gradeId.errors.length', false)
          ) ||
          (
            this.fieldsOrder[i] === "commodity.tonnage" &&
            get(this.state.commodity, 'tonnage.errors.length', false)
          ) ||
          (
            this.fieldsOrder[i] === "sellerDetails.contactId" &&
            get(this.state.errors, 'party')
          ) ||
          (
            this.fieldsOrder[i] === "buyerDetails.contactId" &&
            get(this.state.errors, 'party')
          )
        ) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      }
      else if(currentFields.indexOf(this.fieldsOrder[i]) !== -1){
        if ((
          this.fieldsOrder[i] === "data.identifier" &&
          isEmpty(this.state.data.identifier)
        ) ||
        (
          this.fieldsOrder[i] === "data.gmo" &&
          isEmpty(get(this.state.data, 'gmo'))
        ) ||
        (
          this.fieldsOrder[i] === "data.commoditySource" &&
          isEmpty(get(this.state.data, 'commoditySource'))
        ) ||
        (
          this.fieldsOrder[i] === "data.deliveryStartDate" &&
          isEmpty(get(this.state.data, 'deliveryStartDate'))
        ) ||
        (
          this.fieldsOrder[i] === "data.deliveryEndDate" &&
          isEmpty(get(this.state.data, 'deliveryEndDate'))
        )) {
          formField.current.focus();
          break;
        }
      }
      else if (
        field && get(field, 'errors.length') > 0
      ) {
        if (formField.current.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField.current.focus();
          break;
        }
      }
    }
  }

  isNotIndependentCheck = flag => {
    this.setState({
        isNotIndependent: flag,
        selectedEntity: null
    });
  };

  render() {
    const {
      selectedEntity, constants, previewData, preview, selectedTemplate, showEmailDialog, isNotIndependent,
      URLSelectedEntityId, typeId
    } = this.state;
    const { token } = this.props;
    const emailSubject = this.getEmailSubject();
    const selectedEmailParties = this.getSelectedEmailParties();
    const shouldRenderParentDependentComponents = this.shouldRenderParentDependentComponents();
    const isLockedToOneParent = this.isLockedToOneParent();
    return (
      <div ref={this.formRef} id='vendor-dec-form-container'>
        <form onSubmit={this.onSubmit} noValidate>
          {
            shouldRenderParentDependentComponents &&
            <div className='cardForm'>
              <DeclarationType
                fieldRef={this.fieldRef}
                onReferenceEntitySelect={this.onReferenceEntitySelect}
                onChange={this.updateData}
                validate={this.state.validate}
                defaultSelectedEntity={selectedEntity}
                token={token}
                isLockedToOneParent={isLockedToOneParent}
                isNotIndependent={isNotIndependent}
                isNotIndependentCheck={this.isNotIndependentCheck}
                URLSelectedEntityId={URLSelectedEntityId}
                formTypeId={typeId}
                showToggle={true}
              />
            </div>
          }
          {
            selectedEntity && selectedEntity.seller && isNotIndependent &&
            <div className='contract-details-container'>
              <CommodityContractParties {...selectedEntity} formTypeId={typeId} />
            </div>
          }
          { !isNotIndependent?
              <CounterPartiesInfo
                fieldRef={this.fieldRef}
                onChange={this.updateParty}
                validate={this.state.partyValidate}
                defaultSelectedEntity={selectedEntity}
                data={this.state.data}
                selectedTemplate={selectedTemplate}
                unregToken={this.unregToken}
              /> : null
          }

          <div className='cardForm'>
            <CommodityInfo
              selectedEntity={selectedEntity}
              validate={this.state.commodityValidate}
              onChange={this.updateCommodity}
              fieldRef={this.fieldRef}
              isNotIndependent={isNotIndependent}
              selectedTemplate={selectedTemplate}
              URLSelectedEntityId={URLSelectedEntityId}
              handleSpreadGradeChange={this.onChangeSpreadGrade}
              gradeId={get(this.state, 'gradeId')}
              updateCommodityFields={this.state.updateCommodityFields}
              isVarietyMandatoryInCommodityDec={this.state.isVarietyMandatoryInCommodityDec}
            />
          </div>
          <div className='cardForm'>
            <DeliveryInfo
              fieldRef={this.fieldRef}
              onChange={this.updateData}
              validate={this.state.validate}
              selectedTemplate={selectedTemplate}
              defaultSelectedEntity={selectedEntity}
              formTypeId={typeId}
            />
          </div>
          {
            constants &&
            <div className='cardForm'>
              <ProductIntegrity
                fieldRef={this.fieldRef}
                onChange={this.updateData}
                constants={constants}
                validate={this.state.validate}
                selectedTemplate={selectedTemplate}
              />
            </div>
          }
          {
            constants &&
            <div className='cardForm'>
              <ChemicalsInfo
                onChange={this.updateData}
                constants={constants}
                validate={this.state.validate}
                selectedTemplate={selectedTemplate}
                isSingleCommoditySource={this.isSingleCommoditySource()}
              />
            </div>
          }
          <div className='cardForm'>
            <AdditionalInfo onChange={this.updateData} selectedTemplate={selectedTemplate} />
            <Signatory
                fieldRef={this.fieldRef}
                onChange={this.updateData}
                validate={this.state.validate}
                user={get(this.props ,'user')}
                contacts={get(this.state, 'contacts.seller.contacts')}
                companyName={get(this.state, 'selectedEntity.seller.companyName')}
              />

            <div className="col-md-12 padding-reset" style={{textAlign: 'right'}}>
              <CommonButton
                onClick={this.saveTemplate}
                label='Save Template'
                variant="contained"
                color='secondary'
              />
              <CommonButton
                type='submit'
                onClick={this.onSubmit}
                primary={true}
                label='Continue And Review'
                variant="contained"
              />
            </div>
          </div>
        </form>
        <Dialog open={preview} onClose={this.closePreview} scroll='paper' fullScreen>
          <DialogTitle>Vendor Declaration Preview</DialogTitle>
          <DialogContent>
            <Preview ref={el => (this.componentRef = el)} data={previewData} />
          </DialogContent>
          <DialogActions style={{paddingBottom: "40px"}}>
            <CommonButton label='Back' key='closeButton' default onClick={this.closePreview} variant='flat' />
            <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.openEmailDialog} variant='flat' />
          </DialogActions>
        </Dialog>
        {
          showEmailDialog && !this.unregToken &&
          <CustomEmailDialog
            parties={EMAIL_PARTIES}
            selectedParties={selectedEmailParties}
            title="Email PDF copies to"
            open={showEmailDialog}
            noBody={true}
            subject={emailSubject}
            disableAcceptanceRequired={true}
            onClose={this.closeEmailDialog}
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
  };
};

export default connect(mapStateToProps)(VendorDecForm);

import React, { useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { useState } from 'react';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import { map, uniqBy, isEqual, filter, includes, remove } from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import { setDownloadBar } from '../../actions/main';
import { currentUser, getSeasons, getCurrentCountry } from "../../common/utils";
import CommonDatePicker from '../common/CommonDatePicker';
import moment from 'moment';
import CommonListingButton from '../common/CommonListingButton';
import { getCommodities } from '../../actions/api/commodities';


const ContainerCSV = props => {
    const [allLocations, setAllLocations] = useState([]);
    const [allCompanies, setAllCompanies] = useState([]);
    const [allSeason, setSeason] = useState([]);
    const [allGrade, setGrade] = useState([]);
    const dispatch = useDispatch();

    const [combinedStates, setCombinedStates] = useState({
        'loads__farm_id__in': [],
        'customer__company_id__in': [],
        'loads__ngr__company_id__in': [],
        'loads__grade__id__in': [],
        'loads__season__in': [],
        'loads__date_time__lte': '',
        'commodity_id__in': '',
    });
    const getSeason = () => setSeason(map(getSeasons(), season => ({ id: season, name: season })))

    const getAllCompanies = async () => {
        const user = currentUser();
        let companies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(props.token);
        companies = uniqBy([...companies, { id: user.companyId, name: user.company.name }], 'id');
        setAllCompanies(companies);
    };

    const getAllGrade = async () => {
        const grades = await APIService.commodities().appendToUrl(`grades/all/`).get(props.token, null, { countryId: getCurrentCountry().id });
        setGrade(grades);
    };

    const handleContainersCSV = () => {
        let combinedStatesCopy = Object.assign({}, combinedStates);
        if (combinedStatesCopy.loads__date_time__lte) {
            let lte_time = `${combinedStatesCopy.loads__date_time__lte} 23:59:59`;
            lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
            combinedStatesCopy.loads__date_time__lte = lte_time;
        }
        if (combinedStatesCopy.loads__farm_id__in.length == 1 && combinedStatesCopy.loads__farm_id__in[0] == 'all')
            combinedStatesCopy.loads__farm_id__in = [];
        dispatch(setDownloadBar('Your Containers Overview CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
        const service = APIService.farms().appendToUrl('web/').appendToUrl('containers-overview-csv/');
        service.put(combinedStatesCopy, props.token, null);
        props.toggleDialog();
    };

    useEffect(() => {
        if (props.isOpen) {
            getAllCompanies();
            getSeason();
            getAllGrade();
            getAllCommodities();
            if (!isEqual(props.filteredCommodities, combinedStates.commodity_id__in) || !isEqual(props.filteredGrades, combinedStates.loads__grade__id__in) || !isEqual(props.filteredSeasons, combinedStates.loads__season__in)) {
                setCombinedStates({ ...combinedStates, 'commodity_id__in': props.filteredCommodities, 'loads__grade__id__in': props.filteredGrades, 'loads__season__in': props.filteredSeasons });
            }
        }
    }, [props.isOpen]);

    useEffect(() => {
      if (!isEqual([props.selectedOwner], combinedStates.loads__ngr__company_id__in) || !isEqual(combinedStates.farm_id__in, [props.farm_id])) {
        setCombinedStates({ ...combinedStates, 'loads__farm_id__in': [props.farm_id], 'loads__ngr__company_id__in': props.selectedOwner ? [props.selectedOwner] : [] });
      }
    }, [props.farm_id, props.selectedOwner]);

    useEffect(() =>{
        if (props.locations) {
            let { locations } = {...props};
            let copyLocations = locations.slice();
            remove(copyLocations, val => val.id ==='all');
            copyLocations = filter(copyLocations, location => location?.isActive == true);
            setAllLocations(copyLocations);
        }
      }, [combinedStates.loads__farm_id__in, allCompanies, props.locations]);

    const onStocksCSVClick = () => {
        props.toggleDialog();
    };

    const getGrades = () => filter(allGrade, grade => includes(combinedStates.commodity_id__in, grade.commodityId))

    let handleCompanyChange = async (id, selectedItems) => {
        setCombinedStates({ ...combinedStates, [id]: map(selectedItems, 'id') });
    }

    let allStocks = useSelector(state => state.master.commodities.items);

    let getAllCommodities = () => dispatch(getCommodities());

    let dialogTitle = () => {
        return 'Download Containers Data'
    }

    let onClickMethod = () => {
        return handleContainersCSV
    }

    let getOptionMapperListItems = () => {
        let items = [
            { name: 'Download Containers CSV', fx: onStocksCSVClick }
        ]
        return items;
    }

    return (
        <span style={{ display: 'inline-block' }}>
            <CommonListingButton
                defaultHandler={props.toggleDialog}
                showMenus
                optionMapper={getOptionMapperListItems()}
                title='Download Contents of the table in a CSV'
                name='CSV'
                style={{}}
                variant="outlined"
                size="small"
            />
            {
                props.isOpen &&
                <Dialog open onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
                    <DialogTitleWithCloseIcon onClose={props.toggleDialog} style={{ display: 'inline-flex', alignItems: 'center' }} closeButtonStyle={{ marginTop: '0px' }} id='form-dialog-title'>
                        {dialogTitle()}
                    </DialogTitleWithCloseIcon>
                    <DialogContent>
                        <div className="col-sm-12 padding-reset">
                            <CommonMultiSelect
                                id="loads__farm_id__in"
                                items={allLocations}
                                selectedItems={combinedStates.loads__farm_id__in}
                                displayField="name"
                                onChange={(id, selectedItems) => setCombinedStates({ ...combinedStates, 'loads__farm_id__in': map(selectedItems, 'id') })}
                                placeholder="Select Location(s)..."
                                label="Location(s)"
                                selectAll
                                clearAll
                                containerStyle={{ marginTop: '0px' }}
                            />
                        </div>
                        <div className="col-sm-12 padding-reset">
                            <CommonMultiSelect
                                id="customer__company_id__in"
                                items={allCompanies}
                                selectedItems={combinedStates.customer__company_id__in}
                                displayField="name"
                                onChange={handleCompanyChange}
                                placeholder="Select Customer(s)..."
                                label="Customer"
                                selectAll
                                clearAll
                            />
                        </div>
                        <div className="col-sm-12 padding-reset">
                            <CommonMultiSelect
                                id="loads__ngr__company_id__in"
                                items={allCompanies}
                                selectedItems={combinedStates.loads__ngr__company_id__in}
                                displayField="name"
                                onChange={handleCompanyChange}
                                placeholder="Select Owner(s)..."
                                label="Stock Owners"
                                selectAll
                                clearAll
                            />
                        </div>
                        <div>
                            <div className="col-sm-12 padding-reset">
                                <CommonMultiSelect
                                    id="commodity_id__in"
                                    items={allStocks}
                                    selectedItems={combinedStates.commodity_id__in}
                                    displayField="displayName"
                                    onChange={(id, selectedItems) =>
                                        setCombinedStates({ ...combinedStates, 'commodity_id__in': map(selectedItems, 'id') })}
                                    placeholder="Select Commodity..."
                                    label="Commodity"
                                    selectAll
                                    clearAll
                                />
                            </div>
                            <div className="col-sm-12 padding-reset">
                                <CommonMultiSelect
                                    id="loads__grade__id__in"
                                    items={getGrades()}
                                    selectedItems={combinedStates.loads__grade__id__in}
                                    displayField="name"
                                    onChange={(id, selectedItems) => setCombinedStates({ ...combinedStates, 'loads__grade__id__in': map(selectedItems, 'id') })}
                                    placeholder="Select Grade(s)..."
                                    label="Grade(s)"
                                    selectAll
                                    clearAll
                                />
                            </div>
                            <div className="col-sm-12 padding-reset">
                                <CommonMultiSelect
                                    id="loads__season__in"
                                    items={allSeason}
                                    selectedItems={combinedStates.loads__season__in}
                                    displayField="name"
                                    onChange={(id, selectedItems) => setCombinedStates({ ...combinedStates, 'loads__season__in': map(selectedItems, 'id') })}
                                    placeholder="Select Season..."
                                    label="Season"
                                    selectAll
                                    clearAll
                                />
                            </div>
                        </div>
                        <div className='col-sm-6 padding-reset' style={{ paddingLeft: '0px', paddingTop: '2%' }}>
                            <CommonDatePicker
                                id='loads__date_time__lte'
                                floatingLabelText='Containers As On'
                                value={combinedStates.loads__date_time__lte}
                                onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button type='button' onClick={props.toggleDialog} variant='outlined'>
                            Cancel
                        </Button>
                        <Button type='button' onClick={onClickMethod()} color='primary' variant='outlined'>
                            Download
                        </Button>
                    </DialogActions>

                </Dialog>
            }
        </span>
    );
};

export default ContainerCSV;

import React from 'react';
import  { useSelector, useDispatch }  from 'react-redux';
import { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import { isCurrentUserBroker } from '../../common/utils';
import { get, includes, sortBy, isNumber, uniqBy, filter, map } from 'lodash';
import CustomEmailDialog from './CustomEmailDialog';
import { isLoading, forceStopLoader } from '../../actions/main';


let ContractCustomEmail = props => {
    const token = useSelector(state => {
        return state.main.user.token;
    });

    const dispatch = useDispatch();

    const user = useSelector(state => {
        return state.main.user.user;
    });

    let getEmailSubject = () =>{
        if (props.subject){
          return props.subject;
        }
        let companyName = user.company.name;
        if (isCurrentUserBroker()){
          let representingPartyCompany = checkRepresenting("seller") ? get(props.contract, 'seller.company.name'): get(props.contract, 'buyer.company.name');
          return `[Void] ${companyName} a/c ${representingPartyCompany} Commodity Contract #${get(props, 'contract.identifier')}`;
        }
        return `[Void] ${companyName} Commodity Contract #${get(props, 'contract.identifier')}`;
    };

    let getPartyEmails = () => {
    return {
        seller: get(props.contract, 'seller.contact.email', ''),
        buyer: get(props.contract, 'buyer.contact.email', ''),
        broker: get(props.contract, 'administration.brokerContact.email', '', )
        };
    };

    const brokers = useSelector(state => {
        return isCurrentUserBroker() ? filter(state.companySettings.employees.items, { companyId: state.main.user.user.companyId }) : [];
    });

  let isAcceptanceDisabled = () => {
    if (props.vendorDecDetails) {
      return true;
    }
    else {
      if ((get(props.contract, 'isSeller') && get(props.contract, 'isBuyer')) || get(props.contract, 'status') === 'planned') {
        return true;
      }
      return false;
    }
  };

    const [isLoaded, setLoaded ] = useState(false);
    const [contacts, setContact] = useState({
        seller: [],
        buyer: [],
        broker: []
    });

    let filterContacts = (contacts, includeCurrentCompanyContacts) => {
        if (includeCurrentCompanyContacts) {
          contacts = contacts.concat(brokers);
        } else {
          const brokerIds = map(brokers, 'id');
          contacts = filter(contacts, contact => {
            return !includes(brokerIds, contact.id);
          });
        }
        return uniqBy(contacts, 'id');
      };

    let checkRepresenting = (type) =>{
        return (get(props.contract,
                `${type}.representedById`) == user.companyId) ;
    };

    let getContacts = async (company, getContactsFor, representingParty) => {
        if(!company)
          return;
        const representingCompanyId = isNumber(representingParty) ? representingParty : null;
        let isCurrentUserSameAsParty = get(company, 'id') === get(user, 'companyId');
        let contacts = await APIService.contracts().companies(company.id).contacts().get(
          token,
          {},
          (representingCompanyId ? {representing_company_id: representingCompanyId} : {})
        );
        if(getContactsFor == 'seller'){
          const selectedSeller = get(props.contract, 'seller.company');
          if(selectedSeller){
            const selectedSellerCompanyId = get(selectedSeller, 'id');
            if(selectedSellerCompanyId === user.company.id){
              contacts = uniqBy([{
                id: user.id,
                name: user.name,
                email: user.email
              }].concat(contacts),'id');
            }
          }

          contacts = (!isCurrentUserSameAsParty && isCurrentUserBroker()) ?
                     filterContacts(contacts, representingParty || checkRepresenting("seller")) :
                     contacts;
          contacts = sortBy(contacts, contact => (contact.id === user.id ? 0 : 1));
        } else if(getContactsFor == 'buyer'){
          const selectedBuyer = get(props.contract, 'buyer.company');
          if(selectedBuyer){
            const selectedBuyerCompanyId = get(selectedBuyer,'id');
            if(selectedBuyerCompanyId === user.company.id){
              contacts = uniqBy([{
                id: user.id,
                name: user.name,
                email: user.email
              }].concat(contacts),'id');
            }
          }
          contacts = (!isCurrentUserSameAsParty && isCurrentUserBroker()) ?
                     filterContacts(contacts, representingParty || checkRepresenting("buyer")) :
                     contacts;
          contacts = sortBy(contacts, contact => (contact.id === user.id ? 0 : 1));
        }
        return uniqBy(contacts, 'id');
    };

   let getBrokerCompanyEmployees = async (companyId) => {
        let employees = companyId ?  await APIService.companies(companyId)
                                      .employees()
                                      .get(props.token): "";
        return employees;
    };

  let fetchContacts = async selectedContract => {
    dispatch(isLoading("CustomEmailDialog"));
    let seller = await getContacts(get(selectedContract, 'seller.company'), 'seller',
      get(selectedContract, 'administration.brokerContact.companyId'));

    let buyer = await getContacts(get(selectedContract, 'buyer.company'), 'buyer',
      get(selectedContract, 'administration.brokerContact.companyId'));

    let broker = await getBrokerCompanyEmployees(get(selectedContract,
      'administration.brokerContact.companyId'));
    setContact({
      seller: seller, buyer: buyer, broker: broker
    });
    setLoaded(true);
    dispatch(forceStopLoader());
  };

  let getSelectedParties = () => {
    const sellerCompanyId = get(props.contract, 'seller.companyId', '');
    const buyerCompanyId = get(props.contract, 'buyer.companyId', '');
    if (props.vendorDecDetails) {
      if (get(props.contract, 'isBuyer') && get(props.contract, 'seller.representedById', false)) return ['seller', 'broker'];
      if (user.companyId === buyerCompanyId) return ['seller'];
    }
    else {
      if (user.companyId !== sellerCompanyId && user.companyId !== buyerCompanyId) return ['buyer', 'seller'];
      else if (user.companyId === sellerCompanyId) return ['buyer'];
      else if (user.companyId === buyerCompanyId) return ['seller'];
    }
  };

    useEffect(()=>{
        if (props.contract){
            setLoaded(false);
            fetchContacts(props.contract);
        }
    },[props.contract]);

    let getPartyContacts = () => {
        return contacts;
    };

    return (<span>
        {
        props.contract && props.showCustomEmailDialog && isLoaded &&
        <CustomEmailDialog
            parties={props.vendorDecDetails ? getSelectedParties() : ['broker', 'buyer', 'seller']}
            selectedParties={getSelectedParties()}
            title={props.title ? props.title : (props.vendorDecDetails ? 'Request Vendor Declaration' : 'Void Contract')}
            partyEmails={getPartyEmails()}
            partyContacts={getPartyContacts()}
            subject={getEmailSubject()}
            noBody={true}
            open={props.showCustomEmailDialog}
            onClose={props.closeCustomEmailDialog}
            disableAcceptanceRequired={isAcceptanceDisabled()}
            disableLater={props.disable}
            hideAcceptance={props.hideAcceptance}
        />
        }
        </span>);
};


export default ContractCustomEmail;

import React from 'react';

import { connect } from 'react-redux';
import MovementDetails from './MovementDetails';
import {
  getSelectedFreight,
  receiveFreight,
} from '../../../actions/companies/freights';
import isEmpty from 'lodash/isEmpty';
import get from "lodash/get";
import find from 'lodash/find';
import moment from 'moment';
import { isLoading, loaded } from '../../../actions/main';
import Notes from '../../notes/Notes';
import CommonTabs from '../../common/CommonTabs';
import {MovementDetailsBasicInfo} from './MovementDetailsBasicInfo';
import SideDrawer from '../../common/SideDrawer';
import EditMovementReview from './EditMovementReview';
import {
  vendorDecBlockPermissionPopup,
} from "../../../common/utils";
import VendorDecs from '../../vendor-decs/VendorDecs';
import BookingDialog from './BookingDialog';
import { raiseMovementAmendRequest } from '../../../actions/companies/freights';
import { PACK_ORDER_TYPE_ID } from '../../../common/constants';
import { AppContext } from '../../main/LayoutContext';

class MovementHome extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.location.pathname,
      amendDrawOpen: false,
      amendDetails: null,
      amendTimeStamp: null,
      showCurrentContract: true,
      showBookingDialog: false,
      activeDialog: '',
      activeBookingNumber: ''
    };
  }

  baseBreadcrumbs() {
    const { movement } = this.props;
    const pathSegment = get(this.props.movement, 'typeId') == PACK_ORDER_TYPE_ID ? "pack": "freights"
    return [
      {text: 'Movements', route: `/movements/${pathSegment}`},
      {text: get(movement, 'identifier', ''), route: `/freights/movements/${get(movement, 'id')}/details`}
    ];
  }

  baseHeaderText() {
    const { movement } = this.props;
    const title = get(movement, 'typeId') === PACK_ORDER_TYPE_ID ? 'Pack Movement': 'Freight Movement';
    return `${title} ${get(movement, 'identifier', '')}`;
  }

  componentDidMount() {
    this.props.waitForLoader('movementDetail');
    if (this.shouldFetchMovement()) {
      const { movement_id } = this.props.match.params;
      this.props.getSelectedFreight(movement_id, receiveFreight, false, false, false, true, false);
    }
    if(this.props.breadcrumbsFunc) {
      this.props.breadcrumbsFunc();
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
           { ...prevState, activeTab: nextProps.location.pathname } :
           prevState;
  }

  componentWillUnmount() {
    this.props.receiveFreight(null);
  }

  componentDidUpdate(){
    this.props.loaded();
    const {movement_id} = this.props.match.params;
    if(this.props.movement && this.props.movement.id != movement_id){
      this.props.loading();
      this.props.getSelectedFreight(movement_id, receiveFreight, true, false, false, true, false);
    }
  }

  toggleBookingPopup = (activeDialog, activeBookingNumber) => this.setState((prevState) => ({ showBookingDialog: !prevState.showBookingDialog, activeDialog, activeBookingNumber}));

  shouldFetchMovement() {
    return isEmpty(this.props.movement);
  }


  onAmendOpen = note => {
    let additionState = {
      showCurrentContract: true
    };
    if (note) {
      additionState = {
        amendDetails: get(note, 'amendedDetails'),
        amendTimeStamp: moment(note.createdAt).format('LLLL'),
        showCurrentContract: false
      };
    }
    this.setState({ ...additionState, amendDrawOpen: true });
  };

  onAmendClose = () => {
    const additionState = {
      amendDetails: null,
      amendTimeStamp: null,
      showCurrentContract: true
    };
    this.setState({ ...additionState, amendDrawOpen: false });
  };


  onAddVendorDecClick = (type) => {
    const { movement } = this.props;
    if (type == 'grainCommodity') {
      if (isEmpty(movement.cannotCreateVendorDecReasons) && type == 'grainCommodity')
        window.location.hash = `/vendor-decs/grain/new?entity=freightcontract&entityId=${movement.id}`;
      else
        vendorDecBlockPermissionPopup(movement.cannotCreateVendorDecReasons, 'Freight Movement');
    } else {
      window.location.hash = `/vendor-decs/truck/new?entity=freightcontract&entityId=${movement.id}`;
    }
  };

  updateMovement = (movementId, payload) => {
    this.props.waitForLoader('movementDetail');
    this.props.raiseAmendRequest(movementId, payload);
  }

  render() {
    const { isMobileDevice } = this.context
    const PARENT_URL = this.props.match.url;
    const {movement_id} = this.props.match.params;
    const canViewPickup = this.props.movement?.canViewPickup
    const canViewDelivery = this.props.movement?.canViewDelivery
    let tabs = [
      {
        label: this.props?.movement?.id ? (this.props.movement.isPackMovement ? 'Pack Movement' : 'Freight Movement') : 'Movement',
        key: 'details',
        url: `${PARENT_URL}/details`,
        component: () => <MovementDetails {...this.props} />
      }
    ];

    if(!isMobileDevice) {
      if(canViewPickup && canViewDelivery)
        tabs.push(
          {
            label: 'Vendor Declarations',
            key: 'vendorDecs',
            url: `${PARENT_URL}/vendor-decs`,
            component: () => <VendorDecs {...this.props} movementId={movement_id} />
          }
        );
      tabs.push({
        label: 'Audit History',
        key: 'notes',
        url: `${PARENT_URL}/notes`,
        component: () => <Notes {...this.props} objectId={movement_id} objectType='freightcontract' companyId={this.props.companyId} />
      });
    }

    return (
      <div className="order-details-container">
        <div className="tab">
          <div className="tab-header">
            <CommonTabs value={this.state.activeTab} tabs={tabs} />
          </div>
          {
            !isEmpty(this.props.movement) &&
            this.state.activeTab != find(tabs, {key: 'details'})?.url &&
            <div className="contract-details-container" style={{marginBottom: '10px'}}>
              <MovementDetailsBasicInfo {...this.props} />
            </div>
          }
          <div className="tab-content">
            {
              this.state.activeTab == find(tabs, {key: 'details'})?.url &&
                <MovementDetails {...this.props} toggleBookingPopup={this.toggleBookingPopup} />
            }
            {
              this.state.activeTab == find(tabs, { key: 'notes' })?.url &&
              <div className="contract-details-container">
                <div style={{marginTop: '10px'}}>
                  <Notes
                    {...this.props}
                    objectId={movement_id}
                    objectType='freightcontract'
                    companyId={this.props.companyId}
                    movement={this.props.movement}
                    onAmendOpen={this.onAmendOpen}
                  />
                </div>
              </div>
            }
            {
              this.state.activeTab == find(tabs, {key: 'vendorDecs'})?.url &&
              <VendorDecs
                {...this.props}
                queryString={`?movement_id=${movement_id}`}
                hideAddButton={!this.props.movement}
                onAddClick={this.onAddVendorDecClick}
                movementId={movement_id}
                baseBreadcrumbs={this.baseBreadcrumbs()}
                baseHeaderText={this.baseHeaderText()}
              />
            }
          </div>
        </div>
        <SideDrawer
          isOpen={this.state.amendDrawOpen}
          title={`Movement Amend Request (${get(this.props.movement, 'identifier', '')})`}
          onClose={this.onAmendClose}
          size="big"
        >
          <EditMovementReview
            closeSidebar={this.onAmendClose}
            movement={this.props.selectedFreight || this.props.movement}
            showCurrentContract={this.state.showCurrentContract}
            amendDetails={this.state.amendDetails}
            amendTimeStamp={this.state.amendTimeStamp}
          />
        </SideDrawer>
        {
          this.state.showBookingDialog &&
            <BookingDialog
              toggleBookingPopup={this.toggleBookingPopup}
              activeDialog={this.state.activeDialog}
              bookingNumber={this.state.activeBookingNumber}
              movement={this.props.movement}
              updateMovement={this.updateMovement}
            />
        }
      </div>
    );
  }
}

MovementHome.defaultProps = {
  contract_id: null,
};

const mapStateToProps = state => {
  return {
     movement: state.companies.freights.selectedFreight || null,
     currentUser: state.main.user.user,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSelectedFreight: (id, actionCreator, redirectPage, inActivatedUserToken, isEditingMode, setHeaderAndBreadcrumbs, _forceStopLoader) =>
      dispatch(
        getSelectedFreight(id, actionCreator, redirectPage, inActivatedUserToken, isEditingMode, setHeaderAndBreadcrumbs, _forceStopLoader),
      ),
    waitForLoader: () => dispatch(isLoading('movementDetail')),
    loading: () => dispatch(isLoading('unknown')),
    loaded: () => dispatch(loaded()),
    receiveFreight: (item) => dispatch(receiveFreight(item)),
    raiseAmendRequest: (movementId, data, successMessage, isMovementPage) => dispatch(raiseMovementAmendRequest(movementId, data, successMessage, isMovementPage)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MovementHome);

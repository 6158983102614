export const TYPES = [
  {id: 'contract', name: 'Contract'},
  {id: 'freight', name: 'Freight'},
  {id: 'invoice', name: 'Invoice'},
];

export const TYPES_WITHOUT_INVOICE = [
  {id: 'contract', name: 'Contract'},
  {id: 'freight', name: 'Freight'},
];

import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import {
  RECEIVE_INLOADS,
  ADD_INLOAD,
  EDIT_INLOAD,
} from '../../actions/companies/inloads';
import { getDateTimeFromUTC } from '../../common/utils';

const initialState = {
  items: [],
  selectedInloadId: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_INLOADS:
      forEach(action.items, (item) => {
        const localDateTime = getDateTimeFromUTC(item.dateTime);
        item.date = localDateTime.date;
        item.time = localDateTime.time;
      });
      return { ...state, items: action.items };
    case ADD_INLOAD:
      return {
        ...state,
        items: [action.item, ...state.items],
      };
    case EDIT_INLOAD: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedInloadId: undefined,
      };
    }
    default:
      return state;
  }
};

export default reducer;

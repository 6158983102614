import React from 'react';
import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';

import CompanyCashPrices from './CompanyCashPrices';
import AllCashPrices from './AllCashPrices';
import ArchivedCashPrices from './ArchivedCashPrices';
import SiteArchivedCashPrices from './SiteArchivedCashPrices';
import { getTracks } from '../../actions/main/index';
import CompanySitesCashPrices from './CompanySitesCashPrices';
import { TAB } from './constants';
import { get } from 'lodash';
class CashPrices extends React.Component {
  constructor(props) {
    super(props);

    this.MY_PRICES_URL = '/cash-board/cash-prices/active';
    this.CASH_PRICES_URL = '/cash-board/cash-prices';
    this.MY_PRICES_SITES_URL = '/cash-board/cash-prices/site-active-prices';
    this.ARCHIVED_PRICES_URL = '/cash-board/cash-prices/archived';
    this.SITE_ARCHIVED_PRICES_URL = '/cash-board/cash-prices/site';
    this.TABS = {
      1: this.CASH_PRICES_URL,
      2: this.MY_PRICES_URL,
      3: this.MY_PRICES_SITES_URL,
      4: this.ARCHIVED_PRICES_URL,
      5: this.SITE_ARCHIVED_PRICES_URL,
      6: this.SITE_ARCHIVED_PRICES_URL,
    }
    this.state = {
      value: this.props.location.pathname,
    };
  }
  componentDidMount() {
    this.props.dispatch(getTracks());
    if (this.props.selectedTab != TAB.ALL_CASH_PRICE)
      this.setState({ value: get(this.TABS, this.props.selectedTab) });
  }

  tabChanges = (event, value) => this.setState({ value });

  render() {
    const { value } = this.state;
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={this.CASH_PRICES_URL} label="Cash Prices" className={value !== this.CASH_PRICES_URL ? 'unselected-subtab' : ''} />
          <Tab value={this.MY_PRICES_URL} label="My Active Prices" className={value !== this.MY_PRICES_URL ? 'unselected-subtab' : ''} />
          <Tab value={this.MY_PRICES_SITES_URL}  label="Active Cash Prices (Site)" className={value !== this.MY_PRICES_SITES_URL ? 'unselected-subtab' : ''} />
          <Tab value={this.ARCHIVED_PRICES_URL} label="My Archived Prices" className={value !== this.ARCHIVED_PRICES_URL ? 'unselected-subtab' : ''} />
          <Tab value={this.SITE_ARCHIVED_PRICES_URL} label="Site Archived Prices" className={value !== this.SITE_ARCHIVED_PRICES_URL ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          value === this.CASH_PRICES_URL && <AllCashPrices {...this.props} />
        }
        {
          value === this.MY_PRICES_URL && <CompanyCashPrices {...this.props} />
        }
        {
          value === this.MY_PRICES_SITES_URL && <CompanySitesCashPrices {...this.props} />
        }
        {
          value === this.ARCHIVED_PRICES_URL && <ArchivedCashPrices {...this.props} />
        }
        {
          value === this.SITE_ARCHIVED_PRICES_URL && <SiteArchivedCashPrices {...this.props} />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
    headerText: state.main.headerText,
    selectedTab: state.companies.cashBoard.selectedTab,
  };
};

export default connect(mapStateToProps)(CashPrices);

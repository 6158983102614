import React from 'react';

import { connect } from "react-redux";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import template from 'lodash/template';
import find from 'lodash/find';
import includes from 'lodash/includes';
import some from 'lodash/some';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import isEqual from 'lodash/isEqual';
import pickBy from 'lodash/pickBy';
import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import get from 'lodash/get';
import isString from 'lodash/isString';
import { isCurrentUserFarmAdmin, jsonify, getCountryFormats } from '../../common/utils';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Divider from '@mui/material/Divider';
import moment from 'moment';
import MenuItem from '@mui/material/MenuItem';
import './notifications.scss';
import APIService from '../../services/APIService';
import { Link } from 'react-router-dom';
import LoaderInline from '../LoaderInline';
import alertifyjs from 'alertifyjs';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      countryFormats: getCountryFormats(),
      notifications: [],
      open: false,
      showUnreadOnly: false,
      unseenCount: 0,
      isFetching: false,
      loadedAll: false,
    };

    this.getNotificationUrl = this.getNotificationUrl.bind(this);
    this.fetchUnseenCount = this.fetchUnseenCount.bind(this);
    this.onLoadAllClick = this.onLoadAllClick.bind(this);
  }

  componentDidMount() {
    this.fetchUnseenCount();
    this.prepareActions();
  }

  componentWillUnmount(){
    clearInterval(this.fetchUnseenNotificationsCount);
  }

  isDevServer() {
    const { serverEnv } = this.props;
    return serverEnv === 'dev';
  }

  pollForUnseenCount() {
    this.fetchUnseenNotificationsCount = setInterval(
      this.fetchUnseenCount, this.isDevServer() ? 600000 : 60000
    );
  }

  prepareActions() {
    this.notificationActions = [
      {app: 'farms', entity: 'farm', verb: 'Accepted', url: template('/farms/${item.actionObjectObjectId}/settings/details')},
      {app: 'farms', entity: 'farm', verb: 'Rejected', url: template('/farms')},
      {app: 'farms', entity: 'farm', verb: 'Broker Rejected', url: template('/farms/${item.actionObjectObjectId}/settings/details')},
      {app: 'farms', entity: 'farm', verb: 'Removed', url: template('/farms')},
      {app: 'farms', entity: 'farm', verb: 'Updated', url: template('/farms/${item.actionObjectObjectId}/settings/details')},
      {app: 'profiles', entity: 'farm', verb: 'Added', url: template('/farms/${data.parent_id}/settings/employees?employees')},
      {app: 'profiles', entity: 'farm', verb: 'Updated', url: template('/farms/${data.parent_id}/settings/employees?employees')},
      {app: 'profiles', entity: 'farm', verb: 'Removed', url: template('/farms/${data.parent_id}/settings/employees?employees')},
      {app: 'profiles', entity: 'companysite', verb: 'Added', url: template('/companies/${data.company_id}/employees?employees')},
      {app: 'profiles', entity: 'companysite', verb: 'Updated', url: template('/companies/${data.company_id}/employees?employees')},
      {app: 'profiles', entity: 'companysite', verb: 'Removed', url: template('/companies/${data.company_id}/employees?employees')},
      {app: 'profiles', entity: 'employee', verb: 'Added', url: template('/profiles/details')},
      {app: 'profiles', entity: 'employee', verb: 'Updated', url: template('/profiles/details')},
      {app: 'profiles', entity: 'employee', verb: 'Removed', url: template('/profiles/details')},
      {app: 'farms', entity: 'field', verb: 'Added', url: template('/farms/${item.actionObjectObjectId}/settings/farm-fields')},
      {app: 'farms', entity: 'field', verb: 'Updated', url: template('/farms/${item.actionObjectObjectId}/settings/farm-fields')},
      {app: 'farms', entity: 'home_storage', verb: 'Added', url: template('/farms/${item.actionObjectObjectId}/settings/storages')},
      {app: 'farms', entity: 'home_storage', verb: 'Updated', url: template('/farms/${item.actionObjectObjectId}/settings/storages')},
      {app: 'farms', entity: 'system_storage', verb: 'Added', url: template('/farms/${item.actionObjectObjectId}/settings/storages')},
      {app: 'farms', entity: 'farm_ngr', verb: 'Added', url: template('/farms/${item.actionObjectObjectId}/settings/ngrs')},
      {app: 'farms', entity: 'farm_ngr', verb: 'Updated', url: template('/farms/${item.actionObjectObjectId}/settings/ngrs')},
      {app: 'farms', entity: 'inload', verb: 'inload', url: template('/farms/${item.actionObjectObjectId}/storages/${data.storage_id}/loads')},
      {app: 'farms', entity: 'outload', verb: 'outload', url: template('/farms/${item.actionObjectObjectId}/storages/${data.storage_id}/loads')},
      {app: 'trucks', entity: 'farm_truck', verb: 'Added', url: template('/farms/${data.farm_id}/settings/trucks')},
      {app: 'trucks', entity: 'truck', verb: 'Added', url: template('/companies/${data.company_id}/trucks?trucks')},
      {app: 'trailers', entity: 'trailer', verb: 'Added', url: template('/companies/${data.company_id}/trucks?trucks')},
      {app: 'trucks', entity: 'truck', verb: 'Removed', url: template('/farms/${data.farm_id}/settings/trucks')},
      {app: 'freights', entity: 'freight', verb: 'sent', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'cp_confirm', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'fp_confirm', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'cp_reject', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'fp_reject', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'void_sent', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'void_confirm', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'void_reject', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'voided', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight', verb: 'void_by_contract', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Received', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Accepted', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Rejected', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Voided', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Completed', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Void Received', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Void Rejected', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Load Rejected', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Void Accepted', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Amended', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Amend Received', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Amend Rejected', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_movement', verb: 'Amend Accepted', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
      {app: 'freights', entity: 'freight_order', verb: 'Received', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Accepted', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Rejected', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Voided', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Completed', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Void Received', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Void Rejected', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Void Accepted', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Amended', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Amend Received', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Amend Rejected', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Amend Accepted', url: template('/freights/orders/${item.actionObjectObjectId}/order')},
      {app: 'freights', entity: 'freight_order', verb: 'Customer Movement Permission Changed', url: template('/freights/orders/${item.actionObjectObjectId}/movements')},
      {app: 'loads', entity: 'freight_movement', verb: 'Load Failed', url: template('/freights/movements/${item.actionObjectObjectId}/details')},
    ];

    if(isCurrentUserFarmAdmin()) {
      this.notificationActions.push(
        {app: 'farms', entity: 'farm', verb: 'Created', url: template('/farms/${item.actionObjectObjectId}/settings/details')}
      );
      this.notificationActions.push(
        {app: 'farms', entity: 'farm', verb: 'Added', url: template('/farms/${item.actionObjectObjectId}/settings/details')}
      );
    }
    else {
      this.notificationActions.push(
        {app: 'farms', entity: 'farm', verb: 'Created', url: template('/farms/${item.actionObjectObjectId}/accept-reject')}
      );
      this.notificationActions.push(
        {app: 'farms', entity: 'farm', verb: 'Added', url: template('/farms/${item.actionObjectObjectId}/accept-reject')}
      );
    }
  }

  toggleRead = event => {
    event.preventDefault();
    this.setState( prevState => ({ showUnreadOnly: !prevState.showUnreadOnly}));
  };

  getAction(data, item) {
    return find(this.notificationActions, {entity: data.entity, app: data.app, verb: item.verb});
  }

  getURLFromActions(data, item) {
    let action = this.getAction(data, item);

    if(!action)
      return;

    let url = action.url({item: item, data: data});
    let queryParams = {};
    if(get(data,'storage_type') === 'home')
      queryParams = pickBy({
        commodityId: get(data,'commodity_id'),
      }, value => value);
    else if(get(data,'storage_type') === 'system')
      queryParams = pickBy({
        commodityId: get(data,'commodity_id'),
        ngrId: get(data,'ngr_id'),
        gradeId: get(data,'grade_id'),
        season: get(data,'season'),
      }, value => value);
    const queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
    if(queryString)
      url += '?' + queryString;

    return url;
  }

  getNotificationUrl(data, item) {
    if(data) {
      const url = this.getURLFromActions(data, item);

      if(includes(['inload', 'outload'], item.verb)) {
        if(url)
          return url;
      }
      if (data.app === 'contracts') {
        return '/' + data.app + '/' + item.actionObjectObjectId + '/' + 'contract';
      } else if (data.app === 'invoices') {
        return '/invoices/' + item.actionObjectObjectId + '/details';
      } else if (data.app === 'title_transfer') {
        return '/contracts/' + item.actionObjectObjectId + '/title-transfers';
      } else if (data.entity === 'freight_slot' && data.app === 'site_management') {
        return '/site-management';
      } else if (data.entity === 'freight_slot' && data.app === 'site_bookings') {
        const companyId = data.company_id || data.companyId;
        let url = `/site-bookings/?companyId=${companyId}`;
        if(data.site_id)
          url += `&siteId=${data.site_id}`;
        return url;
      } else if (url) return url;
      else {
        return '/' + data.app + '/' + item.actionObjectObjectId;
      }
    }
    return '';
  }

  toggleDrawer = open => () => {
    if(open)
      this.setState({open: open, isFetching: true}, this.fetchFirstPage);
    else
      this.setState({open: open, showUnreadOnly: false, loadedAll: false});
  };

  onNotificationsIconClick = open => () => {
    this.toggleDrawer(open)();
  };

  onNotificationClick = notification => () => {
    if(includes(get(notification, 'verb'), 'Message from')) { // SM broadcast message
      alertifyjs.alert(
        notification.verb,
        notification.description.replace(notification.verb + ':', ''),
        () => {}
      );
    }
    if(notification) {
      APIService.profiles().notifications().appendToUrl(
        `${notification.id}/read/`
        // eslint-disable-next-line no-unused-vars
      ).put(null, this.props.token).then(result => {
        const newState = {...this.state};
        const oldNotification = find(newState.notifications, {id: notification.id});
        oldNotification.unread = false;
        this.setState(newState);
      });
    }
    this.toggleDrawer(false)();
  };

  markNotificationsSeen() {
    const { notifications } = this.state;
    if(some(notifications, {unseen: true})) {
      APIService.profiles().notifications().appendToUrl('seen/').put(
        null, this.props.token
        // eslint-disable-next-line no-unused-vars
      ).then(result => {
        notifications.forEach(n => n.unseen = false);
        this.setState({ unseenCount: 0, notifications: notifications });
      });
    }
  }

  markAllNotificationsRead = event => {
    event.preventDefault();

    const { notifications } = this.state;
    if(some(notifications, {unread: true})) {
      APIService.profiles().notifications().appendToUrl('read/').put(
        null, this.props.token
        // eslint-disable-next-line no-unused-vars
      ).then(result => {
        notifications.forEach(n => n.unread = false);
        this.setState({ notifications: notifications});
      });
    }
  };

  fetchUnseenCount() {
    if(this.props.token)
      APIService.profiles().notifications().appendToUrl('unseen/count/').get(
        this.props.token
      ).then( data => {
        this.setState({unseenCount: get(data, 'count', 0)});
      });
  }

  kickOutIfNotGood(data) {
    if(data && data.detail && data.detail === 'Invalid token.') {
      localStorage.clear();
      window.location = '/';
    }
  }

  fetchAndSetNotifications() {
    APIService.profiles().notifications().get(this.props.token).then( notifications => {
      this.kickOutIfNotGood(notifications);
      this.setNotifications(notifications);

      this.setState({isFetching: false});
    });
  }

  onLoadAllClick(event) {
    event.preventDefault();

    this.setState({isFetching: true, loadedAll: true}, this.fetchAndSetNotifications);
  }

  setNotifications(notifications) {
    if(!isEqual(this.state.notifications, notifications))
      this.setState({notifications: orderBy(uniqBy(notifications || [], 'id'), 'id', ['desc'])});
  }

  fetchFirstPage() {
    APIService.profiles().notifications().appendToUrl('first/').get(this.props.token).then( notifications => {
      this.kickOutIfNotGood(notifications);
      this.setNotifications(notifications);
      this.markNotificationsSeen();

      this.setState({isFetching: false});
    });
  }

  fetchAndSetUnseenNotifications() {
    APIService.profiles().notifications().appendToUrl('unseen/').get(
      this.props.token
    ).then( notifications => {
      this.kickOutIfNotGood(notifications);
      if (isArray(notifications) && notifications.length > 0)
        this.setNotifications(notifications.concat(this.state.notifications));
    });
  }

  getFreightSlotNotificationsDescription(notification, data) {
    const mStart = moment(data.start);
    const mEnd = moment(data.end);
    let description = notification.description;
    description = description.replace('start_date', mStart.format(this.state.countryFormats.shortDateDisplay));
    description = description.replace('start_time', mStart.format(this.state.countryFormats.time));
    description = description.replace('end_date', mEnd.format(this.state.countryFormats.shortDateDisplay));
    description = description.replace('end_time', mEnd.format(this.state.countryFormats.time));
    return description;
  }

  getNotificationDescription(notification) {
    let data = get(notification, 'data', "{}");
    data = isString(data) ? JSON.parse(data) : data;
    if(data.entity === 'freight_slot')
      return this.getFreightSlotNotificationsDescription(notification, data);
    else
      return notification.description;
  }

  getNotificationsToRender() {
    const { notifications, showUnreadOnly } = this.state;
    if (showUnreadOnly)
      return filter(notifications, {unread: true});

    return notifications;
  }

  render() {
    const {
      unseenCount, showUnreadOnly, open, isFetching
    } = this.state;
    const notifications = this.getNotificationsToRender();

    return (
      <span>
        <Tooltip title="Notifications">
          <IconButton
            onClick={ this.onNotificationsIconClick(true) }
            className='notification'
            size="large">
            {
              unseenCount ?
              <Badge badgeContent={unseenCount} color='primary'>
                <NotificationsIcon />
              </Badge> :
              <NotificationsIcon />
            }
          </IconButton>
        </Tooltip>
        <SwipeableDrawer
          anchor="top"
          open={open}
          onClose={this.toggleDrawer(false)}
          onOpen={this.toggleDrawer(true)}
          className="notification-drawer"
        >
          {
            isFetching ?
            <LoaderInline containerClassName="inline-loader-container" imageStyle={{width: '18%'}} /> :
            <div className='notifications-content'>
              <div className='col-md-12 col-xs-12 header'>
                <span className='col-md-6 col-xs-6 title'>Notifications</span>
                <span className="col-md-6 col-xs-6 controls">
                  <span className='col-md-5 col-xs-5 padding-0'>
                    <a href='#' onClick={ this.toggleRead }>
                      { showUnreadOnly ? 'Show All' : 'Show Unread' }
                    </a>
                  </span>
                  <span className='col-md-1 col-xs-1 controls-separator'>.</span>
                  <span className='col-md-6 col-xs-6 padding-0'>
                    <a href='#' onClick={this.markAllNotificationsRead}>
                      Mark All as Read
                    </a>
                  </span>
                </span>
              </div>
              <div className="notifications-body">
                {
                  (isArray(notifications) ? notifications : []).map((item, index) => {
                    const data = jsonify(get(item, 'data'));
                    const url = this.getNotificationUrl(data, item);
                    const className = item.unread ? 'unread notification-row' : 'notification-row';
                    return (
                      <Link to={ url } key={ index } onClick={ this.onNotificationClick(item) }>
                        <MenuItem className={ className }>
                          <div
                            dangerouslySetInnerHTML={{ __html: this.getNotificationDescription(item) }}
                            className='col-md-12 notification-text'
                          />
                          <div className="notification-timestamp">
                            { moment(item.timestamp).fromNow() }
                          </div>
                        </MenuItem>
                        <Divider />
                      </Link>
                    );
                  })
                }
              </div>
              {
                notifications.length == 0 && open &&
                <MenuItem className="no-notifications-row">
                  <div className='col-md-12'> You do not have any { showUnreadOnly ? 'unread ': null } notifications. </div>
                </MenuItem>
              }
              {
                !this.state.loadedAll &&
                <div className='col-md-12 col-xs-12 footer'>
                  <span className='col-md-12 col-xs-12 control'>
                    <a href='#' onClick={ this.onLoadAllClick }>Load All</a>
                  </span>
                </div>
              }
            </div>
          }
        </SwipeableDrawer>
      </span>
    );
  }
}

const mapStateToProps = state => ({
  companyId: state.main.user.user.companyId,
  user: state.main.user.user,
  token: state.main.user.token,
  serverEnv: state.main.serverEnv,
});

export default connect(mapStateToProps)(Notifications);

import {
  RECEIVE_SYSTEM_STORAGES,
  ADD_SYSTEM_STORAGE,
  CLICK_ADD_SYSTEM_STORAGE_BUTTON,
  ADD_SYSTEM_STORAGE_ERRORS, CHANGE_REFRESH_STOCKS_STATUS, RECEIVE_STORAGE_LOADS
} from '../../actions/companies/storages';

const initialState = {
  items: [],
  isCreateFormDisplayed: false,
  serverErrors: [],
  refreshStocks: false,
  storageLoads: [],
  paginationData: [],
  storageId: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_SYSTEM_STORAGES:
      return {
        ...state,
        items: action.items,
        isCreateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_SYSTEM_STORAGE:
      return {
        ...state,
        items: [action.item, ...state.items],
        isCreateFormDisplayed: false,
        serverErrors: [],
        refreshStocks: true,
      };
    case ADD_SYSTEM_STORAGE_ERRORS:
      return {
        ...state,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case CLICK_ADD_SYSTEM_STORAGE_BUTTON:
      return {
        ...state,
        isCreateFormDisplayed: true,
      };
    case CHANGE_REFRESH_STOCKS_STATUS:
      return{
        ...state,
        refreshStocks: action.status
      };
    case RECEIVE_STORAGE_LOADS:
      return {
        ...state,
        storageLoads: action?.items?.results?.results || action?.items?.results,
        paginationData: action?.items || [],
        storageId: action.storageId
      };
    default:
      return state;
  }
};

export default reducer;

import React, {Component, Fragment} from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import './acceptRejectOrder.scss';
import APIService from '../../services/APIService';

class ConfirmAmendedOrderForUnregisteredUser extends Component{
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isConfirmed: false,
    };
  }

  async componentDidMount(){
    const orderId = get(this.props, 'match.params.order_id');
    const salt = get(this.props, 'match.params.salt');
    if(orderId && salt) {
      const order = await APIService.freights().orders(orderId).get(salt);
      const newState = {...this.state};
      newState.isLoading = true;
      this.setState(newState);
      if(order && order.amendable){
        await this.confirmAmendedOrder(order.id);
      } else {
        const newState = {...this.state};
        newState.isLoading = false;
        newState.canConfirm = false;
        this.setState(newState);
      }
    }
  }

  async confirmAmendedOrder(orderId){
    const salt = this.props.match ? this.props.match.params.salt : null;
    await APIService.freights().orders(orderId)['amend/confirm']().put({'expireToken': true}, salt)
      .then((json) => {
        if(!isEmpty(json.errors)){
          this.setState({ errors: json.errors[0], isLoading:false});
        } else {
          this.setState({ isConfirmed: true, isLoading:false});
        }
      });
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            {this.state.isLoading ? <span>Loading ...</span> : <Fragment>
                {this.state.isConfirmed ? <span>The Order has been amended.</span> : <span>{get(this.state, 'errors', 'You do not have permission to access the information.')}</span>}
            </Fragment>}
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>;
  }
}

export default ConfirmAmendedOrderForUnregisteredUser;

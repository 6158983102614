import React, {Component} from 'react';


class FreightMovement extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="26" viewBox="0 0 40 26">
      <g fill="none" fillRule="evenodd" transform="translate(0 -8)">
        <path d="M0 0h40v40H0z"/>
        <path stroke={this.props.fill} strokeWidth="3.333" d="M32.63 15h-4.297v13.338h10v-7.48L32.63 15z"/>
        <circle cx="12.5" cy="29.167" r="3.333" stroke={this.props.fill} strokeWidth="1.667"/>
        <circle cx="32.5" cy="29.167" r="3.333" stroke={this.props.fill} strokeWidth="1.667"/>
        <rect width="13.333" height="1.667" x="1.667" y="18.333" fill={this.props.fill} rx=".833"/>
        <rect width="13.333" height="1.667" y="21.667" fill={this.props.fill} rx=".833"/>
        <rect width="13.333" height="1.667" x="3.333" y="15" fill={this.props.fill} rx=".833"/>
        <path fill={this.props.fill} fillRule="nonzero" d="M8.333 11.667a1.667 1.667 0 0 1-3.333 0V10c0-.92.746-1.667 1.667-1.667h21.666C29.253 8.333 30 9.08 30 10v18.333c0 .92-.746 1.667-1.667 1.667H15.93l-.589-3.333h11.326v-15H8.333zM8.333 28.333H6.667v-1.666h1.595l1.644.001L9.118 30H6.667C5.747 30 5 29.254 5 28.333v-1.666a1.667 1.667 0 1 1 3.333 0v1.666z"/>
      </g>
    </svg>;
  }
}

export default FreightMovement;

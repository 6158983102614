import React from 'react';
import { connect } from 'react-redux';
import withStyles from '@mui/styles/withStyles';
import { useTheme } from '@mui/material/styles';
import TablePagination from '@mui/material/TablePagination';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { isLoading } from '../../actions/main';
import { get, isNumber } from 'lodash';
import { currentUserCompany, getPageCache, updatePageCache } from '../../common/utils';
import { ACCENT_COLOR_BLUE } from '../../common/constants';
import { AppContext } from '../main/LayoutContext';

const TablePaginationActions = props => {
  const theme = useTheme();
  const { count, page, rowsPerPage } = props;
  const { isMobileDevice } = React.useContext(AppContext)
  const navigateToFirst = () => props.onPageChange('first')
  const navigateToPrevious = () => props.onPageChange('previous')
  const navigateToNext = () => props.onPageChange('next')
  const navigateToLast = () => props.onPageChange('last')

    return (
      <div style={{flexShrink: 0, color: ACCENT_COLOR_BLUE, marginLeft: '20px',}}>
        {
        !isMobileDevice &&
            <IconButton
              onClick={navigateToFirst}
              disabled={page === 0}
              aria-label="First Page"
              size="large">
              {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
            </IconButton>
        }
        <IconButton
          onClick={navigateToPrevious}
          disabled={page === 0}
          aria-label="Previous Page"
          size="large">
          {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
        </IconButton>
        <IconButton
          onClick={navigateToNext}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label="Next Page"
          size="large">
          {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
        </IconButton>
        {
        !isMobileDevice &&
            <IconButton
              onClick={navigateToLast}
              disabled={page >= Math.ceil(count / rowsPerPage) - 1}
              aria-label="Last Page"
              size="large">
              {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
            </IconButton>
        }
      </div>
    );
}


const styles = () => ({
  root: {
    width: '100%',
    marginTop: '24px',
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class CommonTablePagination extends React.Component{
  static contextType = AppContext

  onChangeRowsPerPage = event => {
    const pageSize = event.target.value;
    this.props.dispatch(isLoading('searchedResults'));
    this.props.changePageSize(pageSize);
    if(!this.props.noCache)
      updatePageCache({pageSize: pageSize});
  };

  onChangePage = page => {
    this.props.dispatch(isLoading('searchResults'));
    this.props.changePage(page);
  };

  getPageNumberFrom = pageText => {
    const { paginationData } = this.props;
    if (isNumber(pageText)) return pageText;
    if (pageText === 'previous') return paginationData.page;
    if (pageText === 'next') return paginationData.page + 2;
    if (pageText === 'first') return 0;
    if (pageText === 'last') return Math.ceil(paginationData.count/paginationData.pageSize) - 1;
  };

  getPageSize = () => get(getPageCache(), 'pageSize') || this.props.paginationData.pageSize;
  getPage = () => get(getPageCache(), 'page') || this.props.paginationData.page;

  render () {
    const { isMobileDevice } = this.context
    let rowsPerPageOptions = [5, 10, 15, 20, 25, 30];
    if (get(currentUserCompany(), 'rowsPerPage') == 100){
      rowsPerPageOptions = rowsPerPageOptions.concat(50, 100);
    }
    if (get(currentUserCompany(), 'rowsPerPage') == 50){
      rowsPerPageOptions = rowsPerPageOptions.concat(50);
    }
    return (
      <TablePagination
        component="div"
        id='generic-table-pagination'
        count={this.props.count || 0}
        page={this.getPage() || 0}
        rowsPerPage={this.getPageSize() || 10}
        rowsPerPageOptions={rowsPerPageOptions}
        ActionsComponent={TablePaginationActions}
        onRowsPerPageChange={this.onChangeRowsPerPage}
        onPageChange={this.onChangePage}
        style={isMobileDevice ? {padding: 0} : {marginBottom: '-5px'}}
      />
    );
  }
}
export default withStyles(styles)(connect()(CommonTablePagination));

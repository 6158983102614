import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import { get } from 'lodash';
import {isCurrentUserBroker, openURLInNewTab} from '../../common/utils';
import { canMarkInvoicePaid, setClickedOption, voidReasonDialog, generateInvoice, removeFromPaymentRunDialog, redirectToDuplicateInvoice, voidAndDuplicateReasonDialog, clearInvoice } from '../../actions/companies/invoices';
import { clearContract, getSelectedContract, receiveContract } from '../../actions/companies/contracts';
import { clearOrder, getSelectedOrder, receiveOrder } from '../../actions/companies/orders';
import APIService from '../../services/APIService';
import has from 'lodash/has';
import alertifyjs from 'alertifyjs';
import SideDrawer from '../common/SideDrawer';
import AddPaymentForm from './AddPaymentForm';
import { RejectionReasonDialog } from '../rejections/RejectionReasonDialog';
import ContractCustomEmail from '../common/ContractCustomEmail';
import FreightCustomEmail from '../common/FreightCustomEmail';
import BrokerageCustomEmail from '../common/BrokerageCustomEmail';
import WarehouseCustomEmail from '../common/WarehouseCustomEmail';
import { isLoading } from '../../actions/main';
import isEqual from 'lodash/isEqual';
import AddManuallyMarkPaidForm from './AddManuallyMarkPaidForm';
import RemoveInvoiceFromPaymentRuns from './RemoveInvoiceFromPaymentRuns';

let InvoicesActions = props =>{

  const [options, setOptions] = useState({
    isAddPaymentDrawerOpened: false,
    isVoidDialogOpen: false,
    showCustomEmailDialog: false,
    isMarkManuallyPaidDrawerOpened: false,
    removeFromPaymentRunDialog: false,
  });

  const [requestReason, setRequestReason] = useState("");

  let dispatch = useDispatch();

  const selectedData = useSelector(state => {
    return {
      contractDetails: state.companies.contracts.selectedContract,
      selectedOrderDetails: state.companies.orders.selectedOrder,
      user: state.main.user.user
    };
  });

  let setOptionKey = (key, value) =>{
    setOptions({
      ...options,
      [key]: value
    });
    dispatch(setClickedOption(null));
  };

  let handleCloseRejection = ()=>{
    setOptions({
      ...options,
      showCustomEmailDialog: false,
      isVoidDialogOpen: false
    });
    dispatch(voidAndDuplicateReasonDialog(null, false));
    setRequestReason("");
    if (props.contractDetails){
      dispatch(clearContract());
    }
    if (props.selectedOrderDetails){
      dispatch(clearOrder());
    }
    dispatch(voidReasonDialog([], false));
  };

  let handleCloseRemoveFromPaymentRun = () => {
    if (props.removeFromPaymentRunDialog)
      dispatch(removeFromPaymentRunDialog(null, false));
    setOptions({
      ...options,
      removeFromPaymentRunDialog: false
    });
  };

  let handleRemoveFromPaymentRun = () => {
    let queryParams = '';
    if(props.removeFromPaymentRunDialog && props.itemType == 'invoice')
      queryParams = '?void_invoice=true'
    if (props.removeFromPaymentRunDialog && props.itemType == 'payment')
      queryParams = `?void_payment_id=${props.selectedInvoicePayment?.id}`

    APIService.invoices(props.selectedInvoice.id).appendToUrl(`payment-runs/${queryParams}`)
      .delete()
      .then(item => {
        if (has(item, 'errors')) {
          alertifyjs.error(item.errors[0]);
        } else {
          if(props.canVoidAndDuplicateInvoice)
            dispatch(redirectToDuplicateInvoice(props.selectedInvoice))
          else
            alertifyjs.success('Invoice removed from payment run', 1, () => {
              window.location.reload();
            });
        }
      });
    handleCloseRemoveFromPaymentRun()
  }

  let handleVoidClick = emailData => {
    const invoiceId = props?.selectedInvoice?.id || props.invoiceId
    APIService.invoices(invoiceId).appendToUrl('void/')
      .post({ request_reason: requestReason, ...emailData }, props.token)
      .then(item => {
        if (has(item, 'errors')) {
          alertifyjs.error(item.errors[0]);
        } else {
          alertifyjs.success('Invoice void request sent', 1, () => {
            if(props.canVoidAndDuplicateInvoice){
              dispatch(voidAndDuplicateReasonDialog(null, false));
              dispatch(clearInvoice(invoiceId))
              dispatch(redirectToDuplicateInvoice(props.selectedInvoice))
            }
            else
              window.location.reload();
          });
        }
        dispatch(voidReasonDialog(false, false));
      });
  };

  let closeCustomEmailDialog = (data, justClose) =>{
    if(justClose){
      setOptionKey("showCustomEmailDialog", false);
      return;
    }
    handleCloseRejection();
    const communicationData = {};
    if (data) {
      delete data.body;
      communicationData['communication'] = data;
    }
    handleVoidClick(communicationData);
  };

  let closeEmailDialogAndCreate = (data, justClose) => {
    if(justClose)
      setOptionKey("showCustomEmailDialog", false);
    else {
      handleCloseRejection();
      const communicationData = {};
      if (data) {
        delete data.body;
        communicationData['communication'] = data;
      }
      dispatch(generateInvoice(props.selectedInvoice.id, null, null, communicationData));
    }
  }

  let openCustomEmail = async () => {
    const { selectedInvoice } = props;
    if (get(selectedInvoice, 'type').includes('Commodity Contract') &&
        selectedInvoice.raisedForId != get(selectedData.contractDetails, 'id')){
      dispatch(isLoading('contractDetail'));
      dispatch(getSelectedContract(selectedInvoice.raisedForId, receiveContract, false,
                                   false, false, false));

    }
    else if (get(selectedInvoice, 'type').includes('Freight') &&
             selectedInvoice.raisedForId != get(selectedData.selectedOrderDetails, 'id')){
      dispatch(isLoading(''));
      dispatch(getSelectedOrder(selectedInvoice.raisedForId, receiveOrder, false, false, false, false, true));
    }
    setOptionKey("showCustomEmailDialog", true);
  };

  let checkForOptions = async () => {
    const { selectedInvoiceId, selectedInvoice, canAddPayment, isPdfDownloadable, clickedOption, invoiceId, flag, canMarkComplete, canVoidAndDuplicateInvoice, removeFromPaymentRunDialog } = props;
    const pdfUrl = get(props, 'selectedInvoice.pdfUrl');
    if (selectedInvoiceId && selectedInvoice && canAddPayment && get(clickedOption, 'key') === 'add_payment') {
      setOptionKey("isAddPaymentDrawerOpened", true);
    }
    else if (isPdfDownloadable && pdfUrl){
      openURLInNewTab(pdfUrl);
    }
    else if (selectedInvoice && get(clickedOption, 'key') === 'void' && invoiceId == selectedInvoice.id && flag){
      setOptionKey("isVoidDialogOpen", true);
    }
    else if (selectedInvoiceId && selectedInvoice && canMarkComplete && get(clickedOption, 'key') === 'mark_paid') {
      setOptionKey("isMarkManuallyPaidDrawerOpened", true);
    }
    else if (selectedInvoice && get(clickedOption, 'key') === 'create_send') {
      openCustomEmail()
    } else if (selectedInvoice && get(clickedOption, 'key') === 'remove_from_payment_run') {
      setOptionKey("removeFromPaymentRunDialog", true);
    }
    else if (selectedInvoice && get(clickedOption, 'key') === 'void_and_duplicate' && invoiceId == selectedInvoice.id && canVoidAndDuplicateInvoice && !removeFromPaymentRunDialog){
      setOptionKey("isVoidDialogOpen", true);
    }
  };


  let checkRepresenting = (parent, party, user) =>{
    return (get(selectedData, `${parent}.${party}.representedById`) == user.companyId) ;
  };

  let getEmailSubject = () =>{
    const {user} = selectedData;
    const { selectedInvoice, clickedOption } = props;
    let companyName = user.company.name;
    if (get(selectedInvoice, 'type') === 'Commodity Contract'){
      if (isCurrentUserBroker()){
        if (checkRepresenting('contractDetails', 'seller', user)){
          return `[Void] ${companyName} a/c ${get(selectedData.contractDetails, 'seller.company.name')} Commodity Contract Invoice #${get(props, 'selectedInvoice.identifier')}`;
        }
        else if (checkRepresenting('contractDetails', 'buyer', user)){
          return `[Void] ${companyName} a/c ${get(selectedData.contractDetails, 'buyer.company.name')} Commodity Contract Invoice #${get(props, 'selectedInvoice.identifier')}`;
        }
      }
      return `[Void] ${companyName} Commodity Contract Invoice #${get(props, 'selectedInvoice.identifier')}`;
    }
    else if (get(selectedInvoice, 'type') === 'Freight'){
      if (isCurrentUserBroker() && checkRepresenting('selectedOrderDetails', 'customer', user)){
        return `[Void] ${companyName} a/c ${get(selectedData.selectedOrderDetails, 'customer.company.name')} Freight Tax Invoice #${get(props, 'selectedInvoice.identifier')}`;
      }
      return `[Void] ${companyName} Freight Tax Invoice #${get(props, 'selectedInvoice.identifier')}`;

    } else if (get(clickedOption, 'key') == 'create_send') {
      return `${companyName} Invoice #${get(props, 'selectedInvoice.identifier')}`;
    }
    return `[Void] ${companyName} Invoice #${get(props, 'selectedInvoice.identifier')}`;
  };


  useEffect(()=> {
    if(props.clickedOption){
      checkForOptions();
    }
  }, [props]);

  useEffect(() => {
    if (props.removeFromPaymentRunDialog)
      setOptionKey("removeFromPaymentRunDialog", true);
  }, [props.removeFromPaymentRunDialog]);

  return (
    <span>
      {
        props.selectedInvoice &&
        isEqual(get(props.selectedInvoice, 'raisedForId'), get(selectedData.contractDetails, 'id')) &&
          <ContractCustomEmail
            contract={selectedData.contractDetails}
            showCustomEmailDialog={options.showCustomEmailDialog}
            closeCustomEmailDialog ={closeCustomEmailDialog}
            subject={getEmailSubject()}
            title="Void Invoice"
          />
      }
      {
        props.selectedInvoice &&
          isEqual(get(props.selectedInvoice, 'raisedForId'), get(selectedData.selectedOrderDetails, 'id')) &&
          <FreightCustomEmail
            order={selectedData.selectedOrderDetails}
            showCustomEmailDialog={options.showCustomEmailDialog}
            closeCustomEmailDialog={closeCustomEmailDialog}
            subject={getEmailSubject()}
            title="Void Invoice"
          />
      }
      <BrokerageCustomEmail
        invoiceDetails={props.selectedInvoice}
        showCustomEmailDialog={options.showCustomEmailDialog}
        closeCustomEmailDialog ={closeCustomEmailDialog}
      />
      <WarehouseCustomEmail
        invoiceDetails={props.selectedInvoice}
        showCustomEmailDialog={options.showCustomEmailDialog}
        closeCustomEmailDialog={get(props.selectedInvoice, 'statusDisplayName') === 'draft' ? closeEmailDialogAndCreate : closeCustomEmailDialog}
        clickedOption={props.clickedOption}
      />
      {
        options.isAddPaymentDrawerOpened &&
          <SideDrawer
            isOpen={options.isAddPaymentDrawerOpened}
            title={`Add Payment: ${props.selectedInvoice.identifier}`}
            onClose={() => setOptionKey("isAddPaymentDrawerOpened", false)}
          >
            <AddPaymentForm
              invoice={props.selectedInvoice}
              onClose={() => setOptionKey("isAddPaymentDrawerOpened", false)}
              fromListing={true}
            />
          </SideDrawer>
      }
      {
        options.isMarkManuallyPaidDrawerOpened &&
          <SideDrawer
            isOpen={options.isMarkManuallyPaidDrawerOpened}
            title={`Add Payment: ${props.selectedInvoice.identifier}`}
            onClose={() => {
              setOptionKey("isMarkManuallyPaidDrawerOpened", false);
              dispatch(canMarkInvoicePaid(false));
            }}
          >
            <AddManuallyMarkPaidForm
              invoice={props.selectedInvoice}
              onClose={() => {
                setOptionKey("isMarkManuallyPaidDrawerOpened", false);
                dispatch(canMarkInvoicePaid(false));
              }}
              fromListing
            />
          </SideDrawer>
      }
      {
        options.isVoidDialogOpen &&
          <RejectionReasonDialog
            open={options.isVoidDialogOpen}
            onClose={handleCloseRejection}
            title="Void Invoice"
            value={requestReason}
            onChange={(event) => setRequestReason(event.target.value)}
            onCancel={handleCloseRejection}
            onReject={openCustomEmail}
            placeholder="Enter you reason for void request"
            submitText="Submit"
            required ={true}
          />
      }
      {
        options.removeFromPaymentRunDialog &&
        <RemoveInvoiceFromPaymentRuns
          open={options.removeFromPaymentRunDialog}
          onClose={handleCloseRemoveFromPaymentRun}
          itemType={props.itemType || 'payment'}
          selectedInvoice={props.selectedInvoice}
          handleRemoveFromPaymentRun={handleRemoveFromPaymentRun}
          removeFromPaymentRunDialog={props.removeFromPaymentRunDialog}
        />
      }
    </span>);

};

const mapStateToProps = state => {
  return {
    removeFromPaymentRunDialog: state.companies.invoices.removeFromPaymentRunDialog,
    itemType: state.companies.invoices.itemType,
    selectedInvoicePayment: state.companies.invoices.selectedInvoicePayment,
    canVoidAndDuplicateInvoice: state.companies.invoices.canVoidAndDuplicateInvoice,
    selectedInvoice: state.companies.invoices.selectedInvoice,
  };
};

export default connect(mapStateToProps)(InvoicesActions);

import React, {Component} from 'react';


class LogisticsCompany extends Component {
  render() {
    return (
      <svg xmlns="http://www.w3.org/2000/svg" width="103" height="76" viewBox="0 0 103 76">
        <g fill="none" fillRule="evenodd">
          <path fill={this.props.fill} d="M86.822 60a4.008 4.008 0 0 0-4.02 4c0 2.21 1.798 4 4.02 4 2.22 0 4.02-1.79 4.02-4s-1.8-4-4.02-4M84.812 44c-1.11 0-2.01.895-2.01 2 0 1.103.9 2 2.01 2 1.11 0 2.01-.897 2.01-2 0-1.105-.9-2-2.01-2M25.323 60c-2.22 0-4.02 1.79-4.02 4s1.8 4 4.02 4c2.22 0 4.02-1.79 4.02-4s-1.8-4-4.02-4"/>
          <g>
            <path fill={this.props.fill} d="M98.047 59.618C96.264 55.103 91.83 52 86.822 52c-5.15 0-9.695 3.282-11.37 8h-4.708V40h22.107a6.039 6.039 0 0 1 5.684 4h-5.684c-1.11 0-2.01.895-2.01 2s.9 2 2.01 2h6.03v10c0 .665-.33 1.254-.834 1.618zM86.822 72c-4.433 0-8.039-3.589-8.039-8 0-4.472 3.671-8 8.039-8 4.377 0 8.039 3.538 8.039 8 0 4.411-3.607 8-8.039 8zm-50.13-12c-1.68-4.72-6.222-8-11.369-8-5.149 0-9.694 3.282-11.37 8H6.03a2.007 2.007 0 0 1-2.01-2V6c0-1.103.901-2 2.01-2h58.686c1.108 0 2.01.897 2.01 2v54H36.691zM25.323 72c-4.433 0-8.039-3.589-8.039-8 0-4.472 3.671-8 8.04-8 4.377 0 8.038 3.538 8.038 8 0 4.411-3.606 8-8.039 8zm63.509-54c0 1.103-.901 2-2.01 2H70.744v-4h16.078c1.108 0 2.01.897 2.01 2zm-1.57 6l3.014 12h-16.69l4.608-4.586a1.992 1.992 0 0 0 0-2.828 2.016 2.016 0 0 0-2.842 0l-4.608 4.585V24h16.518zm7.19 12.128l-3.465-13.794A5.936 5.936 0 0 0 92.85 18c0-3.309-2.704-6-6.029-6H70.744V6c0-3.309-2.705-6-6.03-6H6.03C2.705 0 0 2.69 0 6v52c0 3.309 2.705 6 6.03 6h7.235c0 6.617 5.409 12 12.058 12 6.65 0 12.059-5.383 12.059-12h37.381c0 6.617 5.41 12 12.059 12 6.65 0 12.059-5.383 12.059-12 0-.114-.006-.227-.01-.342 2.344-.822 4.03-3.048 4.03-5.658V46c0-4.972-3.666-9.106-8.448-9.872z"/>
            <path id="a" d="M0 0h102.9v76H0z"/>
          </g>
        </g>
      </svg>
    );
  }
}

export default LogisticsCompany;

import React from 'react';
import alertifyjs from 'alertifyjs';
import { TextField, CircularProgress, Autocomplete } from '@mui/material/';
import { debounce, flatten, values, isEqual, get, find, isEmpty, trim, startsWith } from 'lodash';
import { FIELD, PRIMARY_COLOR_GREEN } from '../../common/constants';
import APIService from '../../services/APIService';
import { ListboxComponent } from './ListboxComponent';
import { currentUserToken } from '../../common/utils';
import OrderIcon from './icons/Order';
import MovementIcon from './icons/Movement';
import ContractIcon from './icons/Contract';

class TransactionSearchSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingResults: false,
      results: [],
      inputText: '',
      selected: null,
      fields: {
        referenceEntity: {...FIELD},
      }
    };
  }

  componentDidUpdate(prevProps) {
    const { defaultSelected } = this.props;
    if(!isEqual(prevProps.defaultSelected, defaultSelected))
      this.setupDefaultSelected();
  }

  setupDefaultSelected() {
    const { defaultSelected } = this.props;
    this.handleChange(null, defaultSelected);
  }

  defaultTransactionsService(searchStr) {
    return APIService.profiles().appendToUrl(`transactions/${searchStr}/`);
  }

  isSearchableInput() {
    return get(this.state.inputText, 'length') > 2;
  }

  noOptionsText() {
    return this.state.inputText ? 'No results' : 'Start typing...';
  }

  loadingText() {
    return this.isSearchableInput() ? 'Loading...' : 'Type at least 3 characters to search';
  }

  handleChange = (event, item) => {
    const { selected } = this.state;
    const text = trim(get(item, 'identifier', ''));
    if(!item || !isEqual(item, selected))
      this.setState({
        selected: item, inputText: text, isLoading: item ? this.state.isLoading : false
      }, () => this.props.onSelect(item));
  };

  handleInputChange = (event, value) => {
    let val = trim(value);
    this.setState({ inputText: val, isLoading: Boolean(val) }, () => {
      if (val && val.length > 2)
        if (this.isInResults(val))
          this.setState({ isLoading: false });
      else
        this.fetchResults(val);
    });
  };

  handleOnClose = () => {
    if((!this.state.selected || !startsWith(get(this.state.selected, 'displayName'), this.state.inputText)) && this.state.inputText && this.props.formTypeId==0 && isEmpty(this.state.results)){
      APIService.vendor_decs().appendToUrl(`${this.state.inputText}/exact-match-entity/`)
                .get()
                .then(entity => {
                  if(get(entity, '0'))
                    this.setState({ results: entity, isLoading: false, selected: entity[0]}, () => this.props.onSelect(entity[0]) );
                  else {
                    alertifyjs.error('Contract/Order/Movement not found');
                  }
                });
    }
  };

  isInResults(searchStr) {
    return Boolean(find(this.state.results, {displayName: searchStr}));
  }

  fetchResults = debounce(searchStr => {
    const { transactionsService } = this.props;
    let service;
    if(transactionsService)
      service = transactionsService(searchStr);
    else
      service = this.defaultTransactionsService(searchStr);
    service
      .get(currentUserToken(), null, this.props.queryParams)
      .then(items => {
        this.setState({ results: flatten(values(items)), isLoading: false });
      });
  }, 500);

  getSelectedEntityIcon() {
    const {selected} = this.state;
    return this.getIcon(get(selected, 'entity'));
  }

  getIcon(entity) {
    if(entity === 'contract')
      return <span style={{marginRight: '-20px'}}>
        <ContractIcon fill={PRIMARY_COLOR_GREEN} />
      </span>;
    if(entity === 'freightorder')
      return <span style={{marginRight: '3px', marginBottom: '2px'}}>
        <OrderIcon fill={PRIMARY_COLOR_GREEN} />
      </span>;
    if(entity === 'freightcontract')
      return <span style={{marginRight: '3px', marginBottom: '-3px'}}>
        <MovementIcon fill={PRIMARY_COLOR_GREEN} />
      </span>;

    return '';
  }

  render() {
    const { id } = this.props;
    const { isLoading, results, inputText, selected } = this.state;
    const loadingText = this.loadingText();
    const noOptionsText = this.noOptionsText();
    const icon = this.getSelectedEntityIcon();
    return (
      <Autocomplete
        id={id || 'referenceEntity'}
        blurOnSelect
        ListboxComponent={ListboxComponent}
        options={results || []}
        loading={isLoading}
        loadingText={loadingText}
        noOptionsText={noOptionsText}
        getOptionLabel={option => option.displayName}
        renderInput={params => (
          <TextField
            {...params}
            value={inputText}
                  placeholder={this.props.labelName || "Enter Valid Contract/Order/Movement"}
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: (
                      <React.Fragment>
                        {
                          selected &&
                          icon
                        }
                      </React.Fragment>
                    ),
                    endAdornment: (
                      <React.Fragment>
                        {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                  fullWidth
                  variant='standard'
          />
        )}
        renderOption={(params, option) => (
          <li {...params} style={{cursor: 'pointer'}}>
            <span style={{marginRight: '5px'}}>{this.getIcon(option.entity)}</span>
            {option.displayName}
          </li>
        )}
        value={selected}
        onChange={this.handleChange}
        onInputChange={this.handleInputChange}
        onClose={this.handleOnClose}
        freeSolo
      />
    );
  }
}

export default TransactionSearchSelect;

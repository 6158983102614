import React, {Component} from 'react';
import { connect } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { map, find, isEmpty, forEach, includes, get, merge, cloneDeep, filter} from 'lodash';
import CommonButton from '../common/CommonButton';
import AddButton from '../common/AddButton';
import TextField from "@mui/material/TextField";
import { extractDigits, getGradeName } from "../../common/utils";
import { Checkbox } from '@mui/material';
import alertifyjs from 'alertifyjs';
import { CONTRACT_TYPES } from '../../common/constants';

class SpreadPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      grades: [],
      selectedBaseGrade: null,
    };

    this.handleOpen = this.handleOpen.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleDone = this.handleDone.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props){
    if(props.grades){
      let grades = [];
      var selectedBaseGrade = null;
      if(props.baseGradeId) {
        selectedBaseGrade = find(props.grades, {id: props.baseGradeId});
        let order = 0;
        let priceVariation = '';
        props.grades.forEach(grade => {
          if(
            props.commodityId === grade.commodityId &&
              (
                grade.name.match('UNGRADED') || !grade?.category || !selectedBaseGrade?.category ||
                  selectedBaseGrade.category === grade.category
              )
          ){
            let spreadDetails = {
              name: grade.name,
              id: grade.id,
              order: order++,
              value: undefined,
              error: '',
              priceVariation: priceVariation,
              baseGrade: false
            };
            if(props.baseGradeId == grade.id){
              spreadDetails['value'] = '0';
              spreadDetails.priceVariation = '';
              spreadDetails.baseGrade = true;
              grades.push(spreadDetails);
            } 
            if(!isEmpty(props.spread)){
              let spreads = filter(props.spread, {id: grade.id});
              if (!isEmpty(spreads)){
                spreads.forEach(spread => {
                  let spreadDetails = cloneDeep(spread);
                  if (spread.value == '0' && priceVariation == '')
                    return;
                  if (spread.value) {
                    spreadDetails.value = spread.priceVariation + extractDigits(spread.value);
                  } else {
                    spreadDetails.value = undefined;
                  }
                  spreadDetails.selected = true;
                  grades.push(spreadDetails);
                });}
              else{
                grades.push(spreadDetails);
              }
            }
          else if (props.baseGradeId != grade.id){
            grades.push(spreadDetails);
          }
            
          }
        });
      }
      grades = map(
        grades,
        grade => {
          return merge(grade, {name: getGradeName({grade: grade, season: props.season})});
        }
      );

      this.setState({grades: grades, selectedBaseGrade: selectedBaseGrade});
    }
  }

  handleOpen() {
    this.setState({
      open: true
    });
  }

  handleClose() {
    this.setState({
      open: false
    });
  }

  handleDone() {
    event.preventDefault();
    if(!includes([CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.AREA], this.props.contractType) && !isEmpty(filter(this.state.grades, item => (item.selected && !item.value && !item.baseGrade)))){
      alertifyjs.error("Please enter spread values for all the selected grades.");
      return;
    }
    if (this.state.grades.some((grade) => grade.error)) {
      return;
    }
    this.setState({
      open: false,
    },() => {
      const baseGrade = get(this.state, 'selectedBaseGrade.id');
      let grades = [];
      forEach(this.state.grades, grade => {
        if(grade.id == baseGrade && grade.priceVariation == ''){
          grade.baseGrade = true;
          grades.push(grade);
        } else if(grade.value && !includes(['+', '-', '.'], grade.value)){
          grade.priceVariation = grade.value ? this.getPriceVariation(grade.value) : '';
          grade.selected = true
          grades.push(grade);
        }
      });
      this.props.onSave(grades.length > 1 ? grades : []);
    });
  }

  getPriceVariation(value) {
    const firstCharacter = value.charAt(0);
    if(firstCharacter === '+' || firstCharacter === '-'){
      return firstCharacter;
    } else {
      return '+';
    }
  }

  handleFieldChange(value, index) {
    const newState = { ...this.state };
    const regex = new RegExp('^[+-]?[0-9]{0,3}\\.?[0-9]{0,2}$');
    if (regex.test(value)) {
      if (includes(this.props.ordersGradeDifferentFromBaseGrade, newState.grades[index].id) || includes(this.props.movementsGradeDifferentFromBaseGrade, newState.grades[index].id) || includes(this.props.titleTransfersGradesDifferentFromBaseGrade, newState.grades[index].id)){
        if (isEmpty(value) || (value.length ==1 && includes(['+','-'], value))) {
          newState.grades[index].value = value;
          newState.grades[index].error = 'The grade cannot be removed as it has planned/delivered order/movement/transfer';
        } else {
          newState.grades[index].value = value;
          newState.grades[index].error = '';
        }
      } else {
        newState.grades[index].value = value;
        newState.grades[index].error = '';
      }
    }
    this.setState(newState);
  }

  handleCheckboxChange = (grade, index) => {
    let { grades } = this.state;
    grades = grades.map((item, i) =>
      i === index ? { ...item, selected: !item.selected, value: '' } : item
    );
    this.setState({ grades });
  };

  render() {
    const grades = this.state.grades;

    return (
      <div className="col-xs-12" ref={this.props.setRef}>
        <AddButton
          label={isEmpty(this.props.spread) ? 'Add' : 'Edit'}
          onClick={this.handleOpen}
          disabled={this.props.disableAddButton}
          style={{float: 'none', marginLeft: '-3px', marginBottom: '20px', marginTop: '0px'}}
        />
        {this.props.errorText && <span style={{marginLeft: '5px', color : '#F46059'}}>{this.props.errorText}</span>}
        {this.props.spread && map(this.props.spread, (grade, index) => {
          return <div className="col-md-12 padding-reset" key={index}>
                    <div className="spread-text">
                      <span style={{color: '#B2B2B2'}}>{grade.name}:</span> {grade.value ? `${grade.priceVariation}${this.props.currency} ${extractDigits(grade.value)}` : '-'}
                    </div>
                  </div>;})}
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          scroll="paper"
          fullWidth
        >
          <DialogTitle>
            <div>
              Add Spread Details and Price Variations for <b>{get(this.state, 'selectedBaseGrade.name')}</b>
            </div>
          </DialogTitle>
          <DialogContent>
            { map(grades, (grade, index) => {
              const isChecked = grade?.selected;
              return (
                <div key={index} className='row'>
                  <div className="col-xs-1"  style={{marginTop: '10px'}}>
                    <Checkbox
                      id={`grade[${index}]`}
                      color='primary'
                      checked={get(this.state, 'selectedBaseGrade.id') == grade.id || includes(this.props.gradesInvoiced, grade.id) || isChecked}
                      onChange={() => this.handleCheckboxChange(grade, index)}
                      disabled={grade.baseGrade || includes(this.props.gradesInvoiced, grade.id)}
                    />
                  </div>
                  <div className="col-xs-11">
                    <TextField
                      id={`grade[${index}]`}
                      label={grade.name}
                      value={grade.value}
                      inputProps={{
                        maxLength: 7
                      }}
                      fullWidth
                      onChange={event => this.handleFieldChange(event.target.value, index)}
                      helperText={grades[index].error}
                      error={!isEmpty(grades[index].error)}
                      disabled={!isChecked || grade.baseGrade || includes(this.props.gradesInvoiced, grade.id)}
                      variant="standard" />
                  </div>
                </div>
              );
            })}
          </DialogContent>
          <DialogActions>
            <CommonButton
              variant="contained"
              key="cancelButton"
              label="Cancel"
              default
              onClick={this.handleClose}
            />
            <CommonButton
              key="doneButton"
              variant="contained"
              label="Save"
              primary
              onClick={this.handleDone}
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    grades: state.master.grades.items,
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(SpreadPopup);

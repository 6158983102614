import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {formatPrice, toDateFormat, getCountryLabel, openURLInNewTab} from "../../../common/utils";
import SideDrawer from "../../common/SideDrawer";
import AddPaymentForm from "../../invoices/AddPaymentForm";
import {forceStopLoader, isLoading} from "../../../actions/main";


class InvoicePendingPaymentAcceptanceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addPaymentFormOpen: false,
      isFetching: true,
      invoices: [],
      selectedInvoice: null,
    };

    this.onAddPayment = this.onAddPayment.bind(this);
    this.closePaymentForm = this.closePaymentForm.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.invoices().appendToUrl('pending-payments/').get().then(invoices => {
      const isOk = isArray(invoices);
      this.setState({isFetching: !isOk, invoices: isOk ? invoices : []}, () => {
        this.props.onDataLoad(this.state.invoices.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulInvoiceFromList();
    this.updateInvoices();
  }

  getTitle() {
    const suffix = this.state.isFetching ? '' : `(${this.state.invoices.length})`;
    return `Pending Payment Request ${suffix}`;
  }


  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }


  updateInvoices = () => {
    let updateInvoicesList = this.state.invoices;
    const { pendingPaymentAcceptanceGroupUpdatedInvoice } = this.props;
    if (pendingPaymentAcceptanceGroupUpdatedInvoice) {
      updateInvoicesList.push(pendingPaymentAcceptanceGroupUpdatedInvoice);
      this.setState({invoices: updateInvoicesList});
      this.props.shuffleInvoice(null, 'InvoiceInPendingPaymentAcceptanceGroup', 'push');
    }
  };

  removeSuccessfulInvoiceFromList() {
    const { responseInvoice } = this.props;
    const { selectedInvoice, invoices } = this.state;
    const status = get(responseInvoice, 'status');
    let updatedInvoices = invoices;
    if(
      selectedInvoice && responseInvoice &&
      get(responseInvoice, 'id') === selectedInvoice.id &&
      (status === 'paid' || status === 'confirmed') &&
      !isEmpty(invoices)
    ) {
      const index = findIndex(invoices, {id: responseInvoice.id});
      if (status === 'paid') {
        this.setState({
          invoices: [...invoices.slice(0, index), ...invoices.slice(index + 1)],
          selectedInvoice: null,
          addPaymentFormOpen: false,
        }, () => {
          this.props.onDataLoad(this.state.invoices.length);
        });
      }
      else if (status === 'confirmed') {
          invoices[index] = { ...invoices[index], statusDisplayName: responseInvoice.statusDisplayName };
          if (responseInvoice.amountPayable === 0) {
              updatedInvoices = [...invoices.slice(0, index), ...invoices.slice(index + 1)];
              this.setState({ invoices: updatedInvoices, selectedInvoice: null });
          }
        }
      }
    }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/invoices/${id}/details`);
  }

  async onAddPayment(event, invoice) {
    event.stopPropagation();
    event.preventDefault();
    const { token } = this.props;
    this.props.dispatch(isLoading('invoiceAddPaymentForm'));

    const invoiceDetails = await APIService.invoices().appendToUrl(`${invoice.id}`).get(token);

    this.setState({
      addPaymentFormOpen: true,
      selectedInvoice: invoiceDetails,
    }, () => {
      this.props.dispatch(forceStopLoader());
    });
  }

  closePaymentForm() {
    this.setState({addPaymentFormOpen: false});
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.invoices))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon/>}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall" style={{width: '145px'}}>Invoice No.</TableCell>
                      <TableCell align='center' className="medium">Status</TableCell>
                      <TableCell align='center' className="medium">Bill To</TableCell>
                      <TableCell align='center' className="medium">Bill For</TableCell>
                      <TableCell align='center' className="xsmall">Type</TableCell>
                      <TableCell align='center' className="medium">Payment Due Date</TableCell>
                      <TableCell align='center' className="xsmall">Sub-Total</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('gst')}</TableCell>
                      <TableCell align='center' className="medium">Total</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.invoices) ?
                      this.state.invoices.map(invoice => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={invoice.referenceNumber}
                          onClick={(event) => this.onRowClick(event, invoice.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(invoice.identifier)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(invoice.statusDisplayName)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(invoice.payerDisplayName)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(invoice.payeeDisplayName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.type)}</TableCell>
                          <TableCell align='center' className="medium">
                            {
                              toDateFormat(this.getColumnValue(invoice.paymentDueDate))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.subTotal))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(invoice.gst, `${invoice?.currency} 0.00`, invoice?.currency)
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.total))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={(event) => this.onAddPayment(event, invoice)}>
                              Add Payment
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <SideDrawer
          isOpen={this.state.addPaymentFormOpen}
          title="Add Payment"
          onClose={this.closePaymentForm}
        >
          <AddPaymentForm
            invoice={this.state.selectedInvoice}
            dispatchInvoicePayments={true}
            dispatchInvoiceDetails={true}
            onClose={this.closePaymentForm}
          />
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseInvoice: state.companies.invoices.confirmedOrRejectedInvoice,
});

export default connect(mapStateToProps)(InvoicePendingPaymentAcceptanceGroup);

import React from 'react';
import { connect } from 'react-redux';
import FreightOrders from "../freights/FreightOrders";
import OrderDetails from '../freights/order-details/OrderDetails';
import {OrderDetailsBasicInfo} from "../freights/order-details/OrderDetailsBasicInfo";
import {getSelectedOrder, receiveAllocation, receiveOrder} from "../../actions/companies/orders";
import {
  isLoading,
  setBreadcrumbs,
} from '../../actions/main';
import get from 'lodash/get';
import {getAbsoluteUrl, getQueryParams} from "../../common/utils";

class OrderAllocations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      setBreadcrumbs: true
    };
    this.orderId = get(this.props, 'orderId');

    this.handleBackClick = this.handleBackClick.bind(this);
    this.setBreadcrumbsForOrderDetails = this.setBreadcrumbsForOrderDetails.bind(this);
  }

  componentDidMount() {
    const { orderId }  = this.props;
    if (orderId) {
      this.props.dispatch(isLoading('orderDetail'));
      this.props.dispatch(
      getSelectedOrder(orderId, receiveAllocation,
      false, false,
      false, true, false)
      );
    } else {
      this.resetSelectedAllocation();
      this.props.dispatch(setBreadcrumbs([]));
    }
  }

  componentDidUpdate() {
    this.orderId = getQueryParams(document.location.hash, 'orderId');
  }

  resetSelectedAllocation() {
    if(this.props.selectedAllocation) {
      this.props.dispatch(receiveAllocation(null));
    }
  }

  handleBackClick() {
    this.setState({setBreadcrumbs: true}, () => {
      this.props.dispatch(receiveAllocation(null));
      document.location.hash = getAbsoluteUrl(document.location.hash);
    });
  }

  componentWillUnmount() {
    this.props.dispatch(receiveAllocation(null));
    this.props.dispatch(receiveOrder(null));
  }

  setBreadcrumbsForOrderDetails() {
    if(this.props.selectedAllocation && this.props.breadcrumbs && this.state.setBreadcrumbs) {
      let breadcrumbs = this.props.breadcrumbs.slice(0, 2).concat([
        {text: 'Allocations', route: '/freights/orders/' + this.props.parentOrderId + '/allocations', onClick: this.handleBackClick },
        {text: get(this.props.selectedAllocation, 'identifier', '')}
      ]);
      this.setState({setBreadcrumbs: false}, () => {
        this.props.dispatch(setBreadcrumbs(breadcrumbs));
      });
    }
  }

  render() {
    return (
      <div>
        {
          this.props.selectedAllocation && this.orderId ?
          <div>
            <OrderDetailsBasicInfo
              {...this.props}
              order={this.props.selectedAllocation}
              backButtonHandler={this.handleBackClick}
            />
            <OrderDetails
              {...this.props}
              order={this.props.selectedAllocation}
              dontSetBreadcrumbs={true}
              breadcrumbsFunc={this.setBreadcrumbsForOrderDetails}
            />
          </div>
          : <FreightOrders dontRedirect={true} dontSetBreadcrumbs={true} {...this.props} contractId={this.props.contractId} contract={this.props.contract} handleAddOrderButtonClick={this.props.onHandleAddOrderButtonClick}/>
        }
      </div>
    );

  }
}

const mapStateToProps = state => {
  return {
    selectedOrder: state.companies.orders.selectedOrder,
    selectedAllocation: state.companies.orders.selectedAllocation,
    orders: state.companies.orders.items,
    breadcrumbs: state.main.breadcrumbs,
  };
};
export default connect(mapStateToProps)(OrderAllocations);

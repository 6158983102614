import React from 'react';
import CommonMultiSelect from './CommonMultiSelect';

const CommodityMultiSelect = ({id, commodities, selectedCommodities, placeholder, variant, label, onChange, error, selectAll, clearAll, ...rest}) => (
  <CommonMultiSelect
    selectAll={selectAll}
    clearAll={clearAll}
    items={commodities || []}
    selectedItems={selectedCommodities|| []}
    onChange={(event, items) => onChange(id, items)}
    displayField='displayName'
    id={id || "commodity.AutoComplete"}
    placeholder={placeholder || ''}
    label={label || ''}
    error={error}
    variant={variant || 'outlined'}
    {...rest}
  />
);

export default CommodityMultiSelect;

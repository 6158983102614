import React from 'react';
import {
 Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import './slotHistory.scss';
import {
  getDateTimeFromUTC
} from '../../common/utils';
import moment from 'moment';
import 'moment-timezone';

class ConfirmMoveSlotsDialog extends React.Component {
    constructor(props) {
      super(props);
    }

    render() {
        const { closeConfirmMoveSlotDialog, getUpdatedSlotTime, handleSubmitMultiSlotMove, selectedPlannedOrBookedSlots, getSlotsOverlapped } = this.props;
        return (
            <Dialog fullWidth open onClose={closeConfirmMoveSlotDialog}>
            <DialogTitle style={{ float: 'left'}}>
              Confirm Slot Changes
            </DialogTitle>
            <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center' style={{'border': '1px solid silver'}}>Original Slot Time</TableCell>
                  <TableCell align='center' style={{'border': '1px solid silver'}}>Updated Slot Time</TableCell>
                  <TableCell align='center'style={{'border': '1px solid silver'}}>Overlapping Slots</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {
                selectedPlannedOrBookedSlots.map(schedule => {
                  const originalStart = moment(getDateTimeFromUTC(schedule.raw.slot.start).time, 'HH:mm:ss').format('h:mm A')
                  const originalEnd = moment(getDateTimeFromUTC(schedule.raw.slot.end).time, 'HH:mm:ss').format('h:mm A')
                  const updatedStart = getUpdatedSlotTime(getDateTimeFromUTC(schedule.raw.slot.start))
                  const updatedEnd = getUpdatedSlotTime(getDateTimeFromUTC(schedule.raw.slot.end))
                  const slotsOverlapped = getSlotsOverlapped(updatedStart, updatedEnd, schedule)
                  return (
                    <TableRow key={schedule.id}>
                      <TableCell align='center' style={{'border': '1px solid silver'}}>{
                        <span>
                          <div>{`${originalStart} - ${originalEnd}`}</div>
                          <div>{moment(getDateTimeFromUTC(schedule.raw.slot.start).date).format('DD-MM-YYYY')}</div>
                        </span>
                      }</TableCell>
                      <TableCell align='center' style={{'border': '1px solid silver'}}>{
                        <span>
                          <div style={{ color: 'rgb(106,174,32)'}}>{`${updatedStart.time} - ${updatedEnd.time}`}</div>
                          <div style={{ color: 'rgb(106,174,32)'}}>{moment(updatedStart.date).format('DD-MM-YYYY')}</div>
                        </span>
                      }</TableCell>
                      <TableCell align='center' style={{'border': '1px solid silver'}}>{
                        slotsOverlapped.length !== 0 ?
                        slotsOverlapped.map(slot => {
                          return(
                            <span>
                              <div style={{ color: '#f0ad4e'}}>{`${moment(slot.start.time, 'HH:mm:ss').format('h:mm A')} - ${moment(slot.end.time, 'HH:mm:ss').format('h:mm A')}`}</div>
                              <div style={{ color: '#f0ad4e'}}>{moment(slot.start.date).format('DD-MM-YYYY')}</div>
                            </span>
                          )
                        })
                        : <span align='center'>-</span>}
                      </TableCell>
                    </TableRow>
                  );
                })
              }
              </TableBody>
            </Table>
            </DialogContent>
            <DialogActions>
              <Button type='button' color='secondary' variant='outlined' onClick={closeConfirmMoveSlotDialog}>Close</Button>
              <Button type='button' variant="contained" color="primary" onClick={handleSubmitMultiSlotMove}>Confirm</Button>
            </DialogActions>
          </Dialog>
        )
    }
}
export default ConfirmMoveSlotsDialog;
import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails
} from '@mui/material';
import {
  ExpandMore as ExpandMoreIcon
} from '@mui/icons-material/';
import {get, map, without, includes, has, uniq, find, sumBy, groupBy, compact, mapValues, orderBy, isEqual, keys} from 'lodash';
import SideDrawer from '../common/SideDrawer';
import CreateStorageInload from '../../containers/CreateStorageInload';
import CreateStorage from "../../containers/CreateStorage";
import CreateSystemStorage from "../../containers/CreateSystemStorage";
import GenericTable from '../GenericTable';
import LoaderInline from '../LoaderInline';
import {
  getStorageStocksColumnsByCommodity, getOptionItems, numberFormatterByValue, emptyHomeStoragesColumns
} from './utils';
import { currentUser } from '../../common/utils'
import { MT_UNIT } from '../../common/constants';

class FarmStorageStocks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: [],
      stocks: [],
    };
  }

  componentDidMount() {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    if(has(cache, 'storagesView'))
      this.setState({expanded: cache.storagesView.expanded});
    else
      this.updateCache();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.locationType !== this.props.locationType)
      this.updateCache({location: this.props.locationType})

    if(!isEqual(this.props.stocks, prevProps.stocks))
      this.setState({stocks: this.getSortedStock()})

    if(prevProps.storage !== this.props.storage)
      this.updateCache({storage: this.props.storage})
  }

  updateCache = data => {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    cache.storagesView = {...cache.storagesView, expanded: this.state.expanded};

    if(data)
      cache.storagesView = {...cache.storagesView, ...data}

    localStorage.setItem('stocks', JSON.stringify(cache));
  };

  toggle = (event, expanded, commodityId) => {
    event.persist();
    const { locationType } = this.props;
    const key = `${locationType}-${commodityId}`;
    let newExpanded = expanded ? [...this.state.expanded, key] : without(this.state.expanded, key);
    this.setState({expanded: uniq(newExpanded)}, () => {
      this.updateCache();
    });
  };

  getMultipleOrOne = (data, field) => {
    const values = uniq(compact(map(data, field)))
    let id = '-'
    if(values.length > 1)
      id = 'Multiple'
    else
      id = values[0] || id
    return id
  }

  makeStorageStock = commodityStocks => {
    return map(groupBy(commodityStocks, 'storageName'), (storageStocks, storageName) => {
      return ({
        storageName: storageName,
        gradeId: this.getMultipleOrOne(storageStocks, 'gradeId'),
        season: this.getMultipleOrOne(storageStocks, 'season'),
        tonnage: sumBy(storageStocks, 'tonnage'),
      })
    })
  }

  getSortedStock = () => {
    const { stocks } = this.props
    delete stocks['requestedUnit'];
    const totalTonnage = mapValues(stocks, items => sumBy(items, 'tonnage'));
    const sortedKeys = orderBy(keys(totalTonnage), key => totalTonnage[key], ['desc']);
    return sortedKeys.map(key => [key, stocks[key]])
  }

  render() {
    const {
      isEmptyStoragesSelected, handleOptionClick, handleDefaultCellClick, isSelfLocation, emptyStorages, locationType, isAddInloadSideDrawerOpened, closeAddInloadSideDrawer, addInloadStorageId, currentLocation, isAddSystemStorageSideDrawerOpened, onSystemStorageCloseSideDraw, onSystemStorageAdd, isAddHomeStorageSideDrawerOpened,
      onHomeStorageCloseSideDraw, onHomeStorageAdd, getAddInloadButtonRowColumn, archivedStorages, isArchivedStoragesSelected, storage, commodities, isContainers
    } = this.props;
    const unit = currentUser()?.unit || MT_UNIT;
    const emptyStorageLabel = isContainers ? 'Empty Containers' : 'Empty Home Storages';
    let emptyStoragesColumns = [...emptyHomeStoragesColumns(unit)];
    if (isContainers) {
      emptyStoragesColumns.splice(1, 1);
      emptyStoragesColumns.splice(0, 0, { key: 'location', header: 'Site', className: 'medium' });
      emptyStoragesColumns.splice(2, 0, { key: 'stockOwner', header: 'Stock Owner', className: 'medium' });
      emptyStoragesColumns.splice(3, 0, { key: 'stockNgr', header: 'NGR', className: 'medium' });
      emptyStoragesColumns.splice(4, 0, { key: 'isoCode', header: 'ISO Code', className: 'small' });
    }

    return (
      <div style={{minHeight: '1000px'}}>
        {
          !isEmptyStoragesSelected && !isArchivedStoragesSelected && map(this.state.stocks, stock => {
            const commodityId = stock[0]
            const details = stock[1]
            const key = `${locationType}-${commodityId}`;
            const commodity = find(commodities, {id: parseInt(commodityId)})
            const commodityName = commodity?.displayName
            const commodityTonnage = sumBy(details, 'tonnage')
            const columns = getStorageStocksColumnsByCommodity(commodity, commodities, this.props.locations, unit)
            if (isContainers) {
              columns.splice(2, 0, { key: 'stockOwner', header: 'Stock Owner', className: 'medium' })
              columns.splice(3, 0, { key: 'stockNgr', header: 'NGR', className: 'medium' })
              columns.splice(6, 0, { key: 'isoCode', header: 'ISO Code', className: 'small' })
            }
              return (
                <Accordion
                  key={key}
                  expanded={includes(this.state.expanded, key)}
                  className="stocks-container"
                  style={{margin: '0 0 10px 0', display: 'inline-block', width: '100%'}}
                  onChange={
                    (event, expanded) => this.toggle(event, expanded, commodityId)
                  }>
                  <AccordionSummary expandIcon={<ExpandMoreIcon />} className="stock-panel">
                    <div className='col-xs-12 stocks-summary' style={{margin: 'auto', padding: '0'}}>
                      <h3 className="header-title" style={{display: 'inline-block', margin: '0 10px 0 0'}}>
                        {commodityName}
                      </h3>
                    </div>
                    <div className="card-header-content col-xs-2">
                      <div className="card-header-item">
                        <label>Total</label>
                        {numberFormatterByValue({tonnage: commodityTonnage}, 'tonnage', `${unit}`)}
                      </div>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails style={{padding: '0 0 20px 0', maxHeight: '380px', overflow: 'scroll'}}>
                    <GenericTable
                      mainContainerStyle={{margin: '0 15px', width: '100%'}}
                      columns={columns}
                      items={details}
                      optionsItems={getOptionItems(false, true, false, isContainers)}
                      handleOptionClick={handleOptionClick}
                      handleDefaultCellClick={handleDefaultCellClick}
                      optionsColumnNumber={13}
                      orderBy="tonnage"
                      order="desc"
                    />
                  </AccordionDetails>
                </Accordion>
              );
          })
        }
        {
          isSelfLocation && (isEmptyStoragesSelected || storage == 'all') &&
          <Accordion style={{margin: '0 0 10px 0', display: 'inline-block', width: '100%'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} className="stock-panel">
              <h3 className="header-title" style={{margin: '0', padding: '0'}}>
                {`${emptyStorageLabel} (${emptyStorages?.length})`}
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{padding: '0 0 20px 0', maxHeight: '380px', overflow: 'scroll'}}>
                <GenericTable
                  mainContainerStyle={{margin: '15px 15px 0px', width: '100%'}}
                  columns={emptyStoragesColumns}
                  items={emptyStorages}
                  handleOptionClick={handleOptionClick}
                  customColumnGenerator={getAddInloadButtonRowColumn}
                  customColumnClass="xsmall"
                  orderBy="name"
                  order='asc'
                />
            </AccordionDetails>
          </Accordion>
        }
        {
          !isContainers && isSelfLocation && (isArchivedStoragesSelected || storage == 'all') &&
          <Accordion style={{margin: '0 0 10px 0', display: 'inline-block', width: '100%'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}  className="stock-panel">
              <h3 className="header-title" style={{margin: '0', padding: '0'}}>
                {`Archived Home Storages (${get(archivedStorages, locationType)?.length})`}
              </h3>
            </AccordionSummary>
            <AccordionDetails style={{padding: '0 0 20px 0', maxHeight: '380px', overflow: 'scroll'}}>
              {
                get(archivedStorages, locationType) ?
                <GenericTable
                  mainContainerStyle={{margin: '15px 15px 0px', width: '100%'}}
                  columns={emptyHomeStoragesColumns(unit)}
                  items={get(archivedStorages, locationType)}
                  orderBy="name"
                  order='asc'
                /> :
                <LoaderInline containerClassName="inline-loader-container" />
              }
            </AccordionDetails>
          </Accordion>
        }
        {
          isAddInloadSideDrawerOpened &&
          <SideDrawer
            isOpen={isAddInloadSideDrawerOpened}
            title="Add Inload"
            size="big"
            onClose={closeAddInloadSideDrawer}
            app="load"
            >
            {
              addInloadStorageId &&
              <CreateStorageInload
                companyId={get(currentLocation, 'companyId')}
                farmId={locationType}
                storageId={addInloadStorageId}
                closeDrawer={closeAddInloadSideDrawer}
                isAddingToEmptyStorage={true}
              />
            }
          </SideDrawer>
        }
        {
          isAddSystemStorageSideDrawerOpened &&
          <SideDrawer
            isOpen={isAddSystemStorageSideDrawerOpened}
            title="Add Stock"
            size="medium"
            onClose={onSystemStorageCloseSideDraw}
            app="storage"
            >
            <CreateSystemStorage
              closeDrawer={onSystemStorageCloseSideDraw}
              farmId={locationType}
              companyId={get(currentLocation, 'companyId')}
              afterSubmit={onSystemStorageAdd}
            />
          </SideDrawer>
        }
        {
          isAddHomeStorageSideDrawerOpened &&
          <SideDrawer
            isOpen={isAddHomeStorageSideDrawerOpened}
            title="Add Storage"
            size="small"
            onClose={onHomeStorageCloseSideDraw}
            app="storage"
            >
            <CreateStorage
              closeDrawer={onHomeStorageCloseSideDraw}
              farmId={locationType}
              companyId={get(currentLocation, 'companyId')}
              afterSubmit={onHomeStorageAdd}
            />
          </SideDrawer>
        }
      </div>
    );
  }
}

export default FarmStorageStocks;

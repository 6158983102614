import React from 'react';
import { useState } from 'react';
import get from 'lodash/get';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import set from 'lodash/set';
import forEach from 'lodash/forEach';
import { useEffect } from 'react';
import CommonTextField from '../../common/CommonTextField';
import CommonAutoSuggest from '../../common/autocomplete/CommonAutoSuggest';
import { required } from '../../../common/validators';


let Signatory = props =>{

    const [fields, setFields] = useState({
        signatoryContact: {
            value: get(props, 'user.name'),
            validators: [required()],
            errors: [],
          },
        signatoryCompany: {
            value: get(props, 'user.company.name'),
            validators: [],
            errors: [],
          }
    });

    let onChange = (value, field) => {
        let copyFields = {...fields};
        set(copyFields, `${field}.value`, value);
        checkForErrorFields(field, copyFields);
    };

    let checkForErrorFields = (field, copyFields) => {
        const fieldState = get(copyFields, field);
        fieldState.errors = getFieldErrors(field);
        setFields(copyFields);
        propagateChanges();
    };

    let getFieldErrors = field => {
        let errors = [];
        const fieldState = get(fields, field);
        if(!fieldState)
          return errors;
        fieldState.validators.forEach(validator => {
          if(validator.isInvalid(fieldState.value))
            errors.push(validator.message);
          else
            errors = [];
        });
        return errors;
    };

    useEffect(() => {
        if (props.validate)
            setAllFieldErrors();
    }, [props.validate]);


    let setAllFieldErrors = () => {
        const newState = {...fields};
        forEach(newState, (state, field) => {
          state.errors = getFieldErrors(field);
        });
        setFields(newState);
        propagateChanges();
    };

    let propagateChanges = () => {
        props.onChange(mapValues(fields, 'value'), {productIntegrity: hasErrors()});
    };

    let hasErrors = () =>{
        return some(fields, field => field.errors.length > 0);
    };

    return(<span>
        <h4 className='section-heading'>Signatory Details</h4>
        <div className='cardForm-content'>
            <div className="col-md-6">
                <CommonAutoSuggest
                    id='signatoryContact'
                    suggestions={get(props, 'contacts')}
                    label="Signatory Name"
                    value={fields.signatoryContact.value || get(props, 'user.name')}
                    errorText={get(fields, 'signatoryContact.errors[0]')}
                    onChange={onChange}
                    disabled={Boolean(get(props, 'user', false))}
                    inputProps={{ autoComplete: 'off' }}
                />

            </div>
            <div className="col-md-6">
                <CommonTextField
                    label='Signatory Company'
                    setRef={props.fieldRef["signatoryCompany"]}
                    id="signatoryCompany"
                    onChange={(event) =>onChange(event.target.value, event.target.id)}
                    value={fields.signatoryCompany.value || get(props, 'companyName')}
                    disabled={Boolean(get(props, 'companyName', false))}
                    helperText={get(fields, 'signatoryCompany.errors[0]')}
                    errorStyle={{textAlign: "left"}}
                />
            </div>
        </div>
    </span>);
};


export default Signatory;

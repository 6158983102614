import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import {confirmVoid, rejectVoid} from '../../../actions/companies/freights';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {RejectionReasonDialog} from "../../rejections/RejectionReasonDialog";
import {required} from "../../../common/validators";
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';



class MovementVoidRequestsPendingGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectDialogOpen: false,
      isFetching: true,
      contracts: [],
      selectedContract: null,
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },

    };
    this.setReasonErrors = this.setReasonErrors.bind(this);
    this.getReasonErrors = this.getReasonErrors.bind(this);
    this.handleRejectClickOpen = this.handleRejectClickOpen.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
    this.handleRejectClose = this.handleRejectClose.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  acceptVoid(event, contract) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({selectedContract: contract});
    this.props.dispatch(confirmVoid(contract.id, false));
  }


  getData() {
    APIService.freights().appendToUrl('contracts/void/acceptance/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulContractFromList();
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Void Request ${suffix}`;
  }


  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  handleRejectClickOpen = (event, contract) => {
    event.stopPropagation();
    event.preventDefault();
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    newState.selectedContract = contract;
    if(!newState.rejectionReason.value){
      newState.rejectionReason.errors = [];
    }
    this.setState(newState);
  };

  handleRejectClose = () => {
    this.setState({rejectDialogOpen: false});
  };

  handleReasonChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  }

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
      const errors = [];
      const value = get(this.state, `rejectionReason.value`);
      const validators = get(this.state, `rejectionReason.validators`, []);

      validators.forEach((validator) => {
          if (validator.isInvalid(value)) {
              errors.push(validator.message);
          }
      });

      return errors;
  }


  handleRejectSubmit() {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    const movement = this.state.selectedContract;
    if (this.state.rejectionReason.errors.length === 0) {
      const {dispatch} = this.props;
      dispatch(rejectVoid(movement.id, data, false));
      this.setState({
          rejectionReason: {
            value: undefined,
            validators: [required()],
            errors: []
          }
        }
      );
    }
  }

  removeSuccessfulContractFromList() {
    const { responseContract } = this.props;
    const { selectedContract, contracts } = this.state;
    if(
      selectedContract &&
      get(responseContract, 'id') === selectedContract.id &&
      !isEmpty(contracts)
    ) {
      const index = findIndex(contracts, {id: responseContract.id});
      this.setState({
        contracts: [...contracts.slice(0, index), ...contracts.slice(index + 1)],
        selectedContract: null,
           rejectDialogOpen: false,
      }, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/freights/movements/${id}/details`);
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.contracts))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Movement No.</TableCell>
                      <TableCell align='center' className="xsmall">Customer</TableCell>
                      <TableCell align='center' className="xsmall">Freight Provider</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('rego')}</TableCell>
                      <TableCell align='center' className="medium">Commodity Description</TableCell>
                      <TableCell align='center' className="xsmall">Pickup</TableCell>
                      <TableCell align='center' className="xsmall">Delivery</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(contract.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.customer)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.freightProvider)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.rego)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(contract.commodityDescription)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.pickupDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.deliveryDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-red-outlined"
                              onClick={(event) => this.handleRejectClickOpen(event, contract)}>
                              Reject
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{marginLeft: '8px'}}
                              onClick={(event) => this.acceptVoid(event, contract)}>
                              Accept
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <RejectionReasonDialog
            open={this.state.rejectDialogOpen}
            onClose={this.handleRejectClose}
            title='Reject Void Request'
            value={this.state.rejectionReason.value}
            onChange={this.handleReasonChange}
            helperText={get(this.state, 'rejectionReason.errors[0]', '')}
            onCancel={this.handleRejectClose}
            onReject={this.handleRejectSubmit}
          />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseContract: state.companies.freights.confirmedOrRejectedContract,
});

export default connect(mapStateToProps)(MovementVoidRequestsPendingGroup);

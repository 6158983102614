export const RECEIVE_FARM_FIELDS = 'RECEIVE_FARM_FIELDS';
export const ADD_FARM_FIELD = 'ADD_FARM_FIELD';
export const EDIT_FARM_FIELD = 'EDIT_FARM_FIELD';
export const CANCEL_EDIT_FARM_FIELD = 'CANCEL_EDIT_FARM_FIELD';
export const CLICK_ADD_FARM_FIELD_BUTTON = 'CLICK_ADD_FARM_FIELD_BUTTON';
export const CLICK_EDIT_FARM_FIELD_ICON = 'CLICK_EDIT_FARM_FIELD_ICON';
export const ADD_FARM_FIELD_ERRORS = 'ADD_FARM_FIELD_ERRORS';
export const EDIT_FARM_FIELD_ERRORS = 'EDIT_FARM_FIELD_ERRORS';
export const RECEIVE_ARCHIVED_FARM_FIELDS = 'RECEIVE_ARCHIVED_FARM_FIELDS';

export const receiveFarmFields = items => ({
  type: RECEIVE_FARM_FIELDS,
  items,
});

export const addFarmField = item => ({
  type: ADD_FARM_FIELD,
  item,
});

export const addFarmFieldErrors = errors => ({
  type: ADD_FARM_FIELD_ERRORS,
  errors,
});

export const editFarmField = item => ({
  type: EDIT_FARM_FIELD,
  item,
});

export const editFarmFieldErrors = errors => ({
  type: EDIT_FARM_FIELD_ERRORS,
  errors,
});

export const cancelEditFarmField = () => ({
  type: CANCEL_EDIT_FARM_FIELD,
});

export const clickAddFarmFieldButton = () => ({
  type: CLICK_ADD_FARM_FIELD_BUTTON,
});

export const clickEditFarmFieldIcon = itemId => ({
  type: CLICK_EDIT_FARM_FIELD_ICON,
  itemId,
});

export const receiveArchivedFarmFields = items => ({
  type: RECEIVE_ARCHIVED_FARM_FIELDS,
  items,
});

import React from "react";

import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import CommonTextField from "../common/CommonTextField";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import CommonButton from "../common/CommonButton";
import Dialog from "@mui/material/Dialog/Dialog";
import CommonDatePicker from "../common/CommonDatePicker";
import {required} from "../../common/validators";
import forEach from "lodash/forEach";
import some from "lodash/some";
import mapValues from "lodash/mapValues";

export class MarkPaidDialog extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      fields: {
        paymentDate: {
          value: '',
          validators: [required()],
          errors: [],
        },
        paymentDetails: {
          value: '',
          validators: [],
          errors: [],
        },
      },
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.markPaid = this.markPaid.bind(this);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    if (event) {
      this.setFieldErrors(event.target.id);
    }
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  handleDateChange(value, id) {
    this.setFieldValue(id, value);
  }

  markPaid(){
    this.setAllFieldsErrors(() => {
      const isInvalid = some(this.state.fields, (field) => {
        return field.errors.length > 0;
      });
      const data = mapValues(this.state.fields, (field) => {
        return field.value;
      });
      if (!isInvalid) {
        this.props.onSubmit(data);
      }
    });
  }

  render () {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <div> Payment Confirmation </div>
        </DialogTitle>
        <DialogContent>
          {this.props.showWarning && <div style={{paddingBottom: '20px'}}>Please note: Marking a contract ‘Paid’ will update the status of all the commodity contract invoices of this contract to ‘Paid’. Payment Date for all these invoices will be selected as the date entered below. If you need to enter exact payment dates for each commodity contract invoice, then you can mark these invoices paid individually and when all these invoices are marked ‘Paid’, this contract status will automatically be updated to “Paid”. </div>}
          <div className="col-md-12 padding-reset form-wrap">
            <CommonDatePicker
                id="paymentDate"
                floatingLabelText="Payment Date"
                errorText={this.state.fields.paymentDate.errors[0]}
                value={this.state.fields.paymentDate.value}
                onChange={this.handleDateChange}
                onBlur={this.onFieldBlur}
            />
          </div>
          <CommonTextField
            id="paymentDetails"
            label="Payment Details"
            placeholder="Add bank details, transaction details (Optional)"
            value={this.state.fields.paymentDetails.value}
            onChange={this.handleFieldChange}
            fullWidth
            maxLength="255"
            multiline
            rows={7}
          />
        </DialogContent>
        <DialogActions>
          <DialogActions>
            <CommonButton onClick={this.props.onCancel} key='cancel' label='Cancel' secondary variant="contained" />
            <CommonButton onClick={this.markPaid} key='submit' label='Submit' primary variant="contained" />
          </DialogActions>
        </DialogActions>
      </Dialog>
    );
  }
}

import { connect } from 'react-redux';
import FarmFieldForm from '../components/farm-fields/FarmFieldForm';
import { updateFarmField } from '../actions/api/farm-fields';
import { editFarmField, cancelEditFarmField } from '../actions/companies/farm-fields';
import find from 'lodash/find';

function submit(farmId, data) {
  return (dispatch, getState) => {
    const farmFieldId = getState().companies.farmFields.selectedFarmFieldId;
    dispatch(updateFarmField(farmId, farmFieldId, data, editFarmField));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedFarmField: find(
      state.companies.farmFields.items,
      { id: state.companies.farmFields.selectedFarmFieldId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (farmId, data) => dispatch(submit(farmId, data)),
    cancelEdit: () => dispatch(cancelEditFarmField()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FarmFieldForm);

import React from "react";
import { connect } from 'react-redux';
import { includes } from 'lodash';
import GenericTable from '../components/GenericTable';
import { getPaginatedVendorDecsResponse, getVendorDecs, downloadVendorDecPDFs } from '../actions/companies/vendor-decs';
import APIService from '../services/APIService';

class VendorDecsTable extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <GenericTable {...this.props} />
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    clearSearch: getPaginatedVendorDecsResponse,
    items: state.companies.vendorDecs.items,
    paginationData: state.companies.vendorDecs.paginationData,
    globalSearch: true,
    columns: [
      { key: 'identifier', header: 'Vendor Dec No.', className: 'small' },
      { key: 'type', header: 'Type', className: 'large' },
      {
        urlKey: 'parentWebUrl',
        header: 'Order/Movement/Contract',
        fieldType: 'url-conditional',
        link: true,
        key: 'parentIdentifier',
        className: 'small',
      },
      { key: 'vendorName', header: 'Vendor', className: 'small',},
      { key: 'vendeeName', header: 'Vendee', className: 'small',},
      { key: 'commodityName', header: 'Commodity', className: 'small' },
      { key: 'gradeName', header: 'Grade', className: 'xsmall' },
      { key: 'createdOn', header: 'Created On', className: 'small' },
      { key: 'createdBy', header: 'Created By', className: 'small' },
    ],
    optionsItems: [
      { key: 'riskAssessment', text: 'Risk Assessment' },
      { key: 'testResults', text: 'Test Results'},
      { key: 'additionalInformation', text: 'Additional Information' }
    ],
    order: 'desc',
    orderBy: 'createdAt',
  };
};

const mapDispatchToProps = dispatch => {
  return {
    handleDefaultCellClick: item => {
      document.location.hash = '/vendor-decs/' + item.id + '/details';
    },
    handleOptionClick: (index, key, id, item) => {
      if (key === 'riskAssessment')
        dispatch(downloadVendorDecPDFs(item.id, 'risk_assessment_documents'));
      if (key === 'testResults')
        dispatch(downloadVendorDecPDFs(item.id, 'chemical_residue_analysis_documents'));
      if (key === 'additionalInformation')
        dispatch(downloadVendorDecPDFs(item.id, 'attachments'));
    },
    navigateTo: (url) => {
      dispatch(getVendorDecs(url, true));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      dispatch(getVendorDecs(url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const service = APIService.vendor_decs();
      service.appendToUrl(`web/search/?page_size=${pageSize}`);
      if (page) {
        service.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return service.URL;
    },
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VendorDecsTable);

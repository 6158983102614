import React from 'react';
import { Select, TextField, InputAdornment, MenuItem } from '@mui/material';
import { CURRENCIES } from '../../common/constants';
import { getCountryCurrency } from '../../common/utils';

const CurrencyField = ({selectedCurrency, value, disabled, size, onCurrencyChange, disabledCurrency, ...rest}) => {
  const defaultCurrency = getCountryCurrency()
  const [currency, setCurrency] = React.useState(selectedCurrency || defaultCurrency)

  React.useEffect(() => {
    setCurrency(selectedCurrency)
  }, [selectedCurrency])

  const _onCurrencyChange = newCurrency => {
    setCurrency(newCurrency)
    if(onCurrencyChange)
      onCurrencyChange(newCurrency)
  }

  return (
    <TextField
      value={value || ''}
      size={size || 'medium'}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            {
              disabledCurrency ?
                currency :
                <Select
                  variant='standard'
                  size={size || 'medium'}
                  disabled={disabled}
                  value={currency}
                  onChange={event => _onCurrencyChange(event.target.value)}
                >
                  {
                    CURRENCIES.map(_currency => (
                      <MenuItem key={_currency.id} value={_currency.id}>
                        {_currency.id}
                      </MenuItem>
                    ))
                  }
                </Select>
            }
          </InputAdornment>
        ),
        endAdornment: (
          <React.Fragment>
            {
              disabled && <i style={{top: '5px'}} className="icon-lock"></i>
            }
          </React.Fragment>)
      }}
      {...rest}
    />
  )
}

export default CurrencyField

import React, { useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions, TextField, Chip, ListItem, ListItemAvatar, Avatar, Autocomplete } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { useState } from 'react';
import APIService from '../../services/APIService';
import PersonIcon from '@mui/icons-material/Person';
import { useSelector, useDispatch } from 'react-redux';
import {
    map, get, without
  } from 'lodash';
import { EMAIL_REGEX } from '../../common/constants';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { showPopUpDailyReports, getCompanyDetails } from '../../actions/companies';


let DailyReports = props =>{

    let [employees , setEmployees] = useState([]);

    let [selectedEmployees, setSelectedEmployees] = useState([]);

    let {token, isOpen, company, selectedCompany} = useSelector(state =>{
        return {
            "token": state.main.user.token,
            "isOpen": state.companies.companies.showDailyReports,
            'company': state.companies.companies.selectedAsset,
            'selectedCompany': state.companies.companies.selectedCompany
            };
        }
    );

    let dispatch = useDispatch();

    let handleContactDelete = (event, item) =>{
        let values  = without(selectedEmployees, get(item, 'email', item));
        setSelectedEmployees(values);
    };

    let shouldShowErrorOnMoreLabel = contacts => {
        return Boolean(find(contacts.slice(2), contact => !isValidEmail(get(contact, 'email', contact))));
    };

    let handleOtherEmailFieldChange = (event, items) =>{
        let values = map(items, item => get(item, 'email', item));
        setSelectedEmployees(values);
    };

    let onBlur = (event) => {
        if(!event.target.value)
          return;
        handleOtherEmailFieldChange(
          event, [...selectedEmployees, event.target.value]
        );
    };

    let getCompanyInfo = async companyId => {
        let employees = await APIService.companies(companyId).employees().get(token);
        setEmployees(employees);
        dispatch(getCompanyDetails(companyId));
    };



    let isValidEmail = email =>{
        return email && EMAIL_REGEX.test(email);
    };

    useEffect(()=>{
        if (get(company, 'id')){
            getCompanyInfo(company.id);
        }
    },[company]);

    useEffect(()=>{
      if (get(selectedCompany, 'reportRecipients', false)){
        setSelectedEmployees(selectedCompany.reportRecipients);
      }
    }, [selectedCompany]);


    return (
      <div>
          <Dialog open={isOpen} onClose={() =>dispatch(showPopUpDailyReports(get(company, 'id'), false))} aria-labelledby='form-dialog-title' fullWidth>
              <DialogTitleWithCloseIcon
                  onClose={() => dispatch(showPopUpDailyReports(get(company, 'id'), false))}
                  closeButtonStyle={{marginTop: '0px'}}
                  id='form-dialog-title'>
                  Set Report Config
              </DialogTitleWithCloseIcon>

              <DialogContent style={{marginTop: '15px'}}>

              <div className="col-md-9 padding-reset contact-selector-container">
                      <Autocomplete
                        multiple
                        freeSolo
                        clearIcon={false}
                        limitTags={2}
                        options={employees || []}
                        value={ selectedEmployees }
                        getOptionLabel={(option) => get(option, 'title', option)}
                        onChange={(event, items) => handleOtherEmailFieldChange(event, items)}
                        onBlur={(event) => onBlur(event)}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" fullWidth />
                        )}
                        renderTags={ (value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={index}
                              label={get(option, 'title', option)}
                              {...getTagProps({ index })}
                              className={isValidEmail(get(option, 'email', option)) ? "chip-inline-ellipsis" : "chip-inline-ellipsis error"}
                              onDelete={(event) => handleContactDelete(event, option)} />
                          ))
                        }
                        getLimitTagsText={
                          more => (
                            <span className={shouldShowErrorOnMoreLabel(selectedEmployees) ? "more-label error" : "more-label"}>
                              {`${more} more`}
                            </span>
                          )
                        }
                        renderOption={(params, option, { inputValue }) => {
                            const matchesName = match(option.name, inputValue);
                            const partsName = parse(option.name, matchesName);
                            const matchesEmail = match(option.email, inputValue);
                            const partsEmail = parse(option.email, matchesEmail);
                            return (
                                <ListItem {...params} style={{cursor: 'pointer'}} className="contact-list-item" >
                                  <ListItemAvatar className="contact-avatar">
                                    <Avatar>
                                      <PersonIcon />
                                    </Avatar>
                                  </ListItemAvatar>
                                  <div className='list-item-text'>
                                    <span className='primary'>
                                      {partsName.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                                          {part.text}
                                        </span>
                                      ))}
                                    </span>
                                    <p className="secondary">
                                      {partsEmail.map((part, index) => (
                                        <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: part.highlight ? 'rgb(0, 0, 0)' : 'rgba(0, 0, 0, 0.54)' }}>
                                          {part.text}
                                        </span>
                                      ))}

                                    </p>
                                  </div>
                                </ListItem>
                            );
                        }}
                      />
                    </div>

              </DialogContent>
              <DialogActions>
                  <Button
                    type='button'
                    onClick={() =>dispatch(showPopUpDailyReports(get(company, 'id'), false))}
                    variant='outlined'>
                  Cancel
                  </Button>
                  <Button type='button' onClick={() => props.handleReports(company, selectedEmployees)} color='primary' variant='outlined'>
                  Submit
                  </Button>
            </DialogActions>

          </Dialog>
      </div>
    );

};

export default DailyReports;

import React from 'react';

import { Checkbox, Menu, MenuItem, Chip } from '@mui/material/';
import { ArrowDropDown } from '@mui/icons-material/';
import {
  snakeCase, camelCase, groupBy, forEach, find, map, set, get, keys, filter, isEqual, compact,
  includes, size, isElement,
} from 'lodash';

class SlotStats extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      statuses: {
        planned: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Planned',
        },
        booked: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Booked',
        },
        inProgress: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'In Progress',
        },
        completed: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Completed',
        },
        delayed: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Delayed',
        },
        cancelled: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Incomplete',
        },
        rejected: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Rejected',
        },
        restricted: {
          enabled: true,
          currentViewCount: 0,
          count: 0,
          label: 'Restricted',
        },
      }
    };

    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    if(this.props.settings)
      this.applyLegendCustomisations();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.props.settings, prevProps.settings) && this.props.settings)
      this.applyLegendCustomisations();

    if(!isEqual(this.props.slots, prevProps.slots))
      this.setStats();

    if(!isEqual(this.props.startDate, prevProps.startDate))
      this.setStats();

    if(!isEqual(this.props.endDate, prevProps.endDate))
      this.setStats();
  }

  handleClickOutside(event) {
    const el = document.getElementById('status-options');

    if(isElement(el) && !el.contains(event.target))
      this.close();
  }

  setAnchorEl = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  close = () => {
    this.setState({anchorEl: null});
  };

  applyLegendCustomisations() {
    const newState = {...this.state};
    forEach(newState.statuses, (info, status) => {
      const statusSettings = find(this.props.settings.statuses, {id: snakeCase(status)});
      if(statusSettings) {
        info.color = this.props.statusColors[status];
        info.label = statusSettings.label;
      }
    });
    this.setState(newState);
  }

  handleClick(event, status) {
    event.preventDefault();
    event.stopPropagation();
    const newState = {...this.state};
    set(newState.statuses[status], 'enabled', !get(newState.statuses[status], 'enabled'));
    this.setState(newState, () => {
      this.props.handleStatClick(this.getEnabledStatuses());
      this.close();
    });
  }

  getEnabledStatuses() {
    return compact(map(this.state.statuses, (stat, status) => stat.enabled ? snakeCase(status) : null));
  }

  groupSlotsByStatus() {
    const {slots} = this.props;

    if(slots) {
      const allStatuses = keys(this.state.statuses);
      let groupedStatuses = groupBy(slots, 'raw.slot.status');
      if(!keys(groupedStatuses.length) !== allStatuses.length) {
        forEach(allStatuses, status => {
          if(!includes(keys(groupedStatuses), snakeCase(status)))
            groupedStatuses[camelCase(status)] = [];
        });
      }
      return groupedStatuses;
    }
  }

  getCurrentViewCount(slots) {
    return filter(slots, slot => slot.start >= this.props.startDate && slot.end <= this.props.endDate).length;
  }

  setStats() {
    const groupedSlots = this.groupSlotsByStatus();
    if(groupedSlots) {
      const newState = {...this.state};
      forEach(groupedSlots, (slots, status) => {
        set(newState.statuses[camelCase(status)], 'count', slots.length);
        set(newState.statuses[camelCase(status)], 'currentViewCount', this.getCurrentViewCount(slots));
      });
      this.setState(newState);
    }
  }

  render() {
    const { disabled } = this.props;
    const { anchorEl, statuses } = this.state;
    const enabledStatusesCount = size(this.getEnabledStatuses());
    return (
      <span style={{margin: '0 1px'}}>
        <Chip
          id="status-options"
          label={`Status (${enabledStatusesCount})`}
          deleteIcon={<ArrowDropDown />}
          variant="outlined"
          clickable
          onDelete={this.setAnchorEl}
          onClick={this.setAnchorEl}
          style={{minWidth: '100px'}}
          color="secondary"
          disabled={disabled}
        />
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}>
          {
            map(statuses, (details, status) => (
              <MenuItem
                style={{fontSize: '14px', padding: '5px 15px'}}
                key={ status }
                onClick={ (event) => this.handleClick(event, status) }>
                <Checkbox
                  className={status}
                  checked={details.enabled}
                  style={{color: `${details.color}`, paddingLeft: '0px', paddingRight: '5px'}}
                />
                <span>
                  {`${details.label} (${details.currentViewCount})`}
                </span>
              </MenuItem>
            ))
          }
        </Menu>
      </span>
    );
  }
}

export default SlotStats;

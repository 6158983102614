import React from 'react';

import connect from "react-redux/es/connect/connect";
import { getInvoicePreview } from '../../actions/companies/invoices';
import {setHeaderText, setBreadcrumbs} from '../../actions/main';
import isEqual from 'lodash/isEqual';
import queryString from "query-string";
import get from "lodash/get";
import {withRouter} from "react-router-dom";

class InvoicePreview extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (!this.props.previewHtml && this.props.invoiceId) {
      this.props.dispatch(getInvoicePreview(this.props.invoiceId, false, get(queryString.parse(this.props.location.search), 'type') === 'freightOrder' || get(this.props, 'isFreightInvoice', false), get(this.props, 'isWarehouseInvoice', false)));
    }
    this.setHeaderAndBreadcrumbs();
  }

  setHeaderAndBreadcrumbs() {
    let breadcrumbs = [{text: 'Invoices'}];
    let headerText = 'Invoices';

    this.props.dispatch(setHeaderText(headerText));

    if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  render() {
    return (
      <div className="invoice-preview" dangerouslySetInnerHTML={{__html: this.props.previewHtml}}/>
    );
  }
}

const mapStateToProps = state => ({ previewHtml: state.companies.invoices.previewHtml });

export default withRouter(connect(mapStateToProps)(InvoicePreview));

import React, {Component} from 'react';


class Stocks extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h40v40H0z"/>
        <path stroke={this.props.fill} strokeWidth="3.333" d="M5 35h15V8.333H5V35zM20 35h15V23.333H20V35zM20 8.333c0-4.142-3.358-6.666-7.5-6.666C8.358 1.667 5 4.19 5 8.333h15zM35 23.333c0-4.142-3.358-6.666-7.5-6.666-4.142 0-7.5 2.524-7.5 6.666h15z"/>
        <rect width="3.333" height="6.667" x="13.333" y="11.667" fill={this.props.fill} rx="1.667"/>
        <rect width="3.333" height="1.667" x="28.333" y="26.667" fill={this.props.fill} rx=".833"/>
        <rect width="3.333" height="1.667" x="28.333" y="30" fill={this.props.fill} rx=".833"/>
        <rect width="3.333" height="5" x="33.333" y="35" fill={this.props.fill} rx="1.667"/>
        <rect width="3.333" height="6.667" x="18.333" y="33.333" fill={this.props.fill} rx="1.667"/>
        <rect width="3.333" height="5" x="3.333" y="35" fill={this.props.fill} rx="1.667"/>
        <rect width="3.333" height="11.667" x="13.333" y="20" fill={this.props.fill} rx="1.667"/>
      </g>
    </svg>;
  }
}

export default Stocks;

import React, {Component} from 'react';


class Profile extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <defs>
        <path id="a" d="M0 0h40v40H0z"/>
      </defs>
      <g fill="none" fillRule="evenodd">
        <path fill={this.props.fill} d="M28.77 15.133c0-4.836-3.935-8.77-8.77-8.77-4.835 0-8.77 3.934-8.77 8.77a8.762 8.762 0 0 0 3 6.6 13.85 13.85 0 0 0-3.723 2.487 13.889 13.889 0 0 0-3.698 5.706A16.494 16.494 0 0 1 3.485 20c0-9.106 7.409-16.515 16.515-16.515 9.107 0 16.515 7.409 16.515 16.515 0 3.59-1.174 7.074-3.326 9.928a13.935 13.935 0 0 0-3.339-5.362 13.851 13.851 0 0 0-4.078-2.836 8.763 8.763 0 0 0 2.997-6.597m-15.713 11.5A10.427 10.427 0 0 1 20 23.97c2.79 0 5.413 1.087 7.386 3.06a10.48 10.48 0 0 1 2.946 5.844A16.52 16.52 0 0 1 20 36.515a16.52 16.52 0 0 1-10.332-3.641 10.42 10.42 0 0 1 3.388-6.242M20 9.848a5.29 5.29 0 0 1 5.284 5.285A5.29 5.29 0 0 1 20 20.417a5.29 5.29 0 0 1-5.284-5.284A5.29 5.29 0 0 1 20 9.848m14.142-3.99A19.87 19.87 0 0 0 20 0 19.87 19.87 0 0 0 5.858 5.858 19.87 19.87 0 0 0 0 20a19.87 19.87 0 0 0 5.858 14.142A19.87 19.87 0 0 0 20 40a19.87 19.87 0 0 0 14.142-5.858A19.87 19.87 0 0 0 40 20a19.87 19.87 0 0 0-5.858-14.142" />
      </g>
    </svg>;
  }
}

export default Profile;

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Contract extends Component {
  render() {
    const height = this.props.height || 24;
    const width = this.props.width || 24;
    return <Tooltip title="Contracts" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" style={this.props.noStyle ? {} : {marginLeft: '-4px', marginRight: '19px'}}>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z"/>
          <g fill={this.props.fill}>
            <path fillRule="nonzero" d="M18 6l-3-.004V3H6v18h12V6zM6 1h10l4 4v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2z"/>
            <path d="M14 8v2H8V8zM16 12v2H8v-2zM16 16v2H8v-2z"/>
          </g>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default Contract;

import React from 'react';
import Stepper from "@mui/material/Stepper/Stepper";
import Step from "@mui/material/Step/Step";
import StepLabel from "@mui/material/StepLabel/StepLabel";
import StepConnector from "@mui/material/StepConnector/StepConnector";
import Divider from "@mui/material/Divider/Divider";
import { map, keys, startCase } from 'lodash';
import EmployeeSignUpForm from "../employees/EmployeeSignUpForm";
import alertifyjs from "alertifyjs";
import CompanySignUpForm from "../companies/CompanySignUpForm";
import BusinessType from "../companies/BusinessType";
import './signup.scss';
import {PRIMARY_COLOR_GREEN, WARM_GREY} from "../../common/constants";
import SignUpSuccess from "./SignUpSuccess";
import { login } from '../../actions/main';
import connect from 'react-redux/es/connect/connect';
import { AppContext } from '../main/LayoutContext';

class CompanySignUp extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.state = {
      setupDone: false,
      completed: [],
      signUpData: {company: {}},
      activeStep: 0,
      signUpSuccess: false,
      isUnRegGrowerSignup: false,
      tonnage: 0,
      formData: null,
      successHeader: '',
      successContent: '',
      showFirstTwoSteps: false,
      companyResponse: null,
      ngrNumber: null,
      transferNgrId: null,
      shouldCreateNgr: null,
      isFetchedFromNgr: false,
      selectedEmployee: null,
    };
  }

  componentDidMount() {
    if (!this.state.setupDone){
      this.setupSignUp();
    }
  }

  setupSignUp() {
    const newState = {...this.state};
    newState.completed = [false, false, false, false];
    newState.setupDone = true;
    this.setState(newState);
  }

  getSteps = () => {
    return keys(this.getAllStepContent());
  };

  getDisplaySteps = () => {
    const steps = this.getAllStepContent();
    return map(keys(steps), startCase);
  };

  getAllStepContent = () => {
    return this.state.showFirstTwoSteps ? this.firstTwoSteps() : this.allStepContent();
  };

  allStepContent = () => {
    return {
      'companyDetails': <CompanySignUpForm submit={this.getFieldsValue} previousStep={this.previousStep} nextStep={this.nextStep} values={this.state.signUpData} completeSignUp={this.completeSignUp} updateSteps={this.showFirstTwoSteps} setCompanyResponse={this.setCompanyResponse} setNgrNumber={this.setNgrNumber} ngrNumber={this.state.ngrNumber} setTransferNgrId={this.setTransferNgrId} shouldCreateNgr={this.shouldCreateNgr} setFetchedFromNgr={this.setFetchedFromNgr}/>,
      'personalDetails': <EmployeeSignUpForm submit={this.getFieldsValue} previousStep={this.previousStep} nextStep={this.nextStep} values={this.state.signUpData} showBack={true} completeSignUp={this.completeSignUp} isExistingCompanyRegistered={!this.state.showFirstTwoSteps} companyResponse={this.state.companyResponse} ngrNumber={this.state.ngrNumber} transferNgrId={this.state.transferNgrId} shouldCreateNgr={this.state.shouldCreateNgr}/>,
      'businessType': <BusinessType submit={this.getFieldsValue} previousStep={this.previousStep} nextStep={this.finishSetup} values={this.state.signUpData} ngrNumber={this.state.ngrNumber} transferNgrId={this.state.transferNgrId} shouldCreateNgr={this.state.shouldCreateNgr}/>,
    };
  };

  firstTwoSteps = () => {
    return {
      'companyDetails': <CompanySignUpForm submit={this.getFieldsValue} previousStep={this.previousStep} nextStep={this.nextStep} values={this.state.signUpData} completeSignUp={this.completeSignUp} updateSteps={this.showFirstTwoSteps} setCompanyResponse={this.setCompanyResponse} onEmployeeSelect={this.onEmployeeSelect} setNgrNumber={this.setNgrNumber} ngrNumber={this.state.ngrNumber} setTransferNgrId={this.setTransferNgrId} shouldCreateNgr={this.shouldCreateNgr} setFetchedFromNgr={this.setFetchedFromNgr}/>,
      'personalDetails': <EmployeeSignUpForm submit={this.getFieldsValue} previousStep={this.previousStep} nextStep={this.finishSetup} values={this.state.signUpData} showBack={true} completeSignUp={this.completeSignUp} companyResponse={this.state.companyResponse} selectedEmployee={this.state.selectedEmployee} ngrNumber={this.state.ngrNumber} transferNgrId={this.state.transferNgrId} shouldCreateNgr={this.state.shouldCreateNgr} isFetchedFromNgr={this.state.isFetchedFromNgr}/>,
    };
  };

  onEmployeeSelect = (employee, callback) => {
    this.setState({selectedEmployee: employee}, () => {
      if(callback)
        callback();
    });
  };

  setCompanyResponse = response => this.setState({companyResponse: response})

  setNgrNumber = ngrNumber => this.setState({ngrNumber: ngrNumber})

  setTransferNgrId = id => this.setState({transferNgrId: id})

  shouldCreateNgr = flag => this.setState({shouldCreateNgr: flag})

  setFetchedFromNgr = flag => this.setState({isFetchedFromNgr: flag})

  showFirstTwoSteps = (showFirstTwoSteps) => {
    if(showFirstTwoSteps)
      this.setState({showFirstTwoSteps: true, completed: [false, false]});
    else
      this.setState({showFirstTwoSteps: false, completed: [false, false, false, false]});
  };

  getStepperContent = () => {
    const steps = [];
    this.getDisplaySteps().map((label, index) => {
      const props = {};
      props.completed = this.state.completed[index];
      const labelProps = {};
      steps.push((
        <Step key={label} {...props}>
          <StepLabel {...labelProps} classes={{completed: 'step-label-completed', active: 'step-label-active'}}>
            {label}
          </StepLabel>
        </Step>
      ));
    });
    return steps;
  };

  completeSignUp = (header, content, tonnage=0, isUnRegGrowerSignup=false, formData=null) => {
    this.setState({signUpSuccess: true, successHeader: header, successContent: content, isUnRegGrowerSignup: isUnRegGrowerSignup, tonnage:tonnage, formData: formData});
  };

  getStepContent = () => {
    const currentStepTab = this.getSteps()[this.state.activeStep];
    return this.getAllStepContent()[currentStepTab];
  };

  previousStep = () => {
    if (this.state.activeStep === 0) {
      alertifyjs.confirm('Are you sure?', 'All data will be lost. Are you sure?', () => window.location = '#', () => {});
    } else {
      const newState = {...this.state};
      newState.activeStep -= 1;
      this.setState(newState);
    }
  };

  finishSetup = () => {
    const newState = {...this.state};
    newState.completed[newState.activeStep] = true;
    this.setState(newState);
  };

  nextStep = () => {
    const newState = {...this.state};
    newState.completed[newState.activeStep] = true;
    newState.activeStep += 1;
    this.setState(newState);
  };

  getFieldsValue = (details) => {
    const newState = this.state;
    newState.signUpData = {...newState.signUpData, ...details};
    this.setState(newState);
  };

  navigateToLogin = () => {
    if(this.state.signUpSuccess){
      window.location = '#';
    }
  };

  render() {
    const { isMobileDevice } = this.context
    return (
      <div className={isMobileDevice ? "" : "signup-wrapper"}>
        <div className={isMobileDevice ? "" : "signup-container"}>
          {
            !this.state.signUpSuccess ?
              <div className={isMobileDevice ? "" : "signup-content-box"} style={isMobileDevice ? {background: '#FFF', minHeight: '100vh'} : {}}>
                <div className="signup-content-box--header" style={isMobileDevice ? {borderRadius: 0} : {}}>
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
              </div>

              <div className='col-sm-12' style={{padding: '5px 24px'}}>
              <span style={{fontSize: '26px', color: PRIMARY_COLOR_GREEN}}>Sign Up</span>
              </div>

              <div>
              <Divider className='col-sm-12 padding-reset'/>
              </div>

              <Stepper connector={<StepConnector className='step-connector'/>} activeStep={this.state.activeStep} style={{height: '48px', maxHeight: '48px', width: '100%', padding: '0 16px'}}>
              {this.getStepperContent()}
              </Stepper>

              <div>
              <Divider className='col-sm-12 padding-reset'/>
              </div>

              <div className={isMobileDevice ? "" : "signup-content-box--content"} style={isMobileDevice ? {padding: '16px'} : {}}>
              {this.getStepContent()}
              </div>
            </div>
              :
            <div className={isMobileDevice ? "" : "signup-content-box"} style={isMobileDevice ? {} : {margin: '0 5%', marginTop: '80px'}}>
              <div className="signup-content-box--header" style={isMobileDevice ? {borderRadius: 0} : {}}>
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
              </div>

              <div className={isMobileDevice ? "" : "signup-content-box--content"} style={isMobileDevice ? {padding: '16px'} : {}}>
                <div className="cardForm cardForm--drawer" id='registerSuccess'>
                  <SignUpSuccess
                  onClick={this.navigateToLogin}
                  loginToStocks={() => login(
                    {...this.state.formData},
                    '/stocks',
                    null,
                    this.props.dispatch,
                    null,
                    error => this.setState({error: error.alert})
                  )}
                  login={() => login(
                    {...this.state.formData},
                    null,
                    null,
                    this.props.dispatch,
                    null,
                    error => this.setState({error: error.alert})
                  )}
                  isUnRegGrowerSignup={this.state.isUnRegGrowerSignup}
                  tonnage={this.state.tonnage}
                  contentText={this.state.successContent}
                  headerText={this.state.successHeader}
                  isMiniature={true}
                  contentColor={!this.state.isUnRegGrowerSignup ? WARM_GREY : null}/>
                </div>
              </div>
            </div>
          }

        </div>
      </div>
    );
  }
}

export default connect()(CompanySignUp);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import alertifyjs from 'alertifyjs';
import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import { find, reject, isEmpty, isBoolean, get, includes } from 'lodash';
import APIService from '../../../services/APIService'
import GenericTable from '../../GenericTable';
import LoaderInline from '../../LoaderInline';
import ContractAllocationForm from '../ContractAllocationForm';
import { DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME, EMPTY_VALUE, THROUGH_WAREHOUSE_ALLOCATION, THROUGH_WAREHOUSE_ALLOCATION_DISPLAY_NAME } from '../../../common/constants';
import { currentUserCompany } from '../../../common/utils';

const renderBasedOnExpandedFlag = expanded => expanded ? (<KeyboardArrowUp style={{ fill: '#112c42', height: '20px', width: '20px' }} />) : (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );

class ContractAllocations extends Component {
  constructor(props) {
    super(props)
    this.state = {
      allocations: [],
      isLoading: false,
      expanded: true,
      edit: false,
      selectedContract: null,
    }
  }

  componentDidMount() {
    this.fetchAllocations()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.contract?.id !== this.props.contract?.id && this.props.contract?.id)
      this.fetchAllocations()
  }

  fetchAllocations = () => {
    let url = 'allocations/'
    if (this.props.isSaleContract)
      url += '?is_sale_contract=true'
    this.setState(
      {isLoading: true},
      () => APIService.contracts(this.props.contract.id).appendToUrl(url).get().then(allocations => this.setState({isLoading: false, allocations: allocations, expanded: allocations.length > 0}))
    )
  }

  toggleExpanded = () => this.setState({expanded: !this.state.expanded});

  handleOptionClick = (event, option, contractId) => {
    const clickedContract = find(this.state.allocations, {id: contractId})
    const purchaseContract = this.props.contract.isSeller ? clickedContract : this.props.contract
    const saleContract = this.props.contract.isSeller ? this.props.contract : clickedContract
    if(option === 'delete') {
      let url = get(clickedContract, 'allocationType') === DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME ? `sale-allocations/${saleContract.id}/` : `through-warehouse-allocations/${saleContract.id}/`;
      alertifyjs.confirm(
        'Delete Allocation',
        'Are you sure you want to remove this allocation?',
        () => {
          APIService.contracts(purchaseContract.id)
            .appendToUrl(url)
            .delete()
            .then(() => {
              this.setState(
                {allocations: reject(this.state.allocations, {id: clickedContract.id})},
                () => window.location.reload()
              )
            })
        },
        () => {}
      )
    }
    else if(option === 'edit') {
      this.setState({selectedContract: clickedContract, edit: true})
    } else if (option === 'create_freight_order') {
      if (get(saleContract, 'counterPartyOrderCreationRestricted') && !get(saleContract, 'hasUnfinishedGrainOrdersAgainstSaleContractWithTonnage')) {
        let partyName = get(saleContract, 'counterPartyName') || get(saleContract, 'counterParty')
        alertifyjs.alert(
          'Error',
          `Please contact ${partyName} to create the Call on Grain Order first`,
          () => {}
        );
      } else
        window.location.hash = `#/freights/orders/new?commodityContractId=${purchaseContract.id}&saleContractId=${saleContract.id}`
    } else if (option === 'create_cog_order')
      window.location.hash = `#/freights/grain/new?commodityContractId=${purchaseContract.id}&saleContractId=${saleContract.id}`
  }

  onClose = afterSave => this.setState({selectedContract: null, edit: false}, () => {
    if(isBoolean(afterSave) && afterSave)
      this.fetchAllocations()
  })

  getAllocationOptionItems(item) {
    let allocationOptionItems = [
      {key: 'edit', text: 'Edit'},
      {key: 'delete', text: 'Delete'},
    ]
    if(get(item, 'allocationType') === DIRECT_TO_BUYER_ALLOCATION_DISPLAY_NAME) {
      allocationOptionItems.push({key: 'create_freight_order', text: 'Create Freight Order'});
      if((get(this.props, 'isPurchaseContract') && this.props.contract?.canCreateCallOnGrainOrder && !get(item, 'counterPartyTransactionParticipator')) || (get(this.props, 'isSaleContract') && get(item, 'canCreateCallOnGrainOrder') && !get(item, 'saleContractCounterPartyTransactionParticipator')))
        allocationOptionItems.push({key: 'create_cog_order', text: 'Create Call On Grain Order'});
    }
    return allocationOptionItems;
  }


  render() {
    const { isSaleContract, header } = this.props
    const { expanded, isLoading, allocations, edit, selectedContract } = this.state
    let COLUMNS = [
      {header: isSaleContract ? 'Purchase Contract' : 'Sale Contract', key: 'referenceNumber', formatter: item => <Link to={`/contracts/${item.id}/contract`} target='_blank' rel='noreferrer noopener'>{item.referenceNumber}</Link>, className: 'large' },
      {header: 'Counter Party', key: 'counterParty', className: 'large'},
      {header: 'Allocation Type', key: 'allocationType', className: 'large'},
      {header: 'Price Point', key: 'pricePoint', className: 'medium'},
      {header: 'Allocated Tonnage (in MT)', key: 'allocatedTonnage', className: 'medium'},
      {header: 'Planned (in MT)', key: 'plannedTonnage', className: 'medium'},
      {header: 'Delivered (in MT)', key: 'deliveredTonnage', className: 'medium' },
    ]
    if (includes(currentUserCompany().contractAllocations, THROUGH_WAREHOUSE_ALLOCATION)) {
      const remainingTonnageKey = this.props.isSaleContract? 'purchaseContractRemainingTonnage': 'saleContractRemainingTonnage';
      COLUMNS.splice(4, 0, {
        header: this.props.isSaleContract ? 'Remaining In Purchase (in MT)' : 'Remaining In Sale (in MT)',
        key: this.props.isSaleContract? 'purchaseContractRemainingTonnage': 'saleContractRemainingTonnage',
        toolTipLabel: `Any tonnage that is not planned or delivered against the ${this.props.isSaleContract ? 'purchase' : 'sale'} contract. This includes tonnage not part of the allocation as well. (Only applicable for through warehouse.)`,
        formatter: item => item.allocationType === THROUGH_WAREHOUSE_ALLOCATION_DISPLAY_NAME ? get(item, remainingTonnageKey) : EMPTY_VALUE,
        className: 'large', cellStyle: {width: '12%'}
      });
      COLUMNS.splice(5, 0, {
        header: `Delivered Against ${this.props.isSaleContract ? 'Purchase' : 'Sale'} (in MT)`,
        key: 'totalDeliveredTonnage',
        toolTipLabel: `Any tonnage that is delivered against the ${this.props.isSaleContract ? 'purchase' : 'sale'} contract. This includes tonnage delivered outside of the allocation as well. (Only applicable for through warehouse.)`,
        formatter: item => item.allocationType === THROUGH_WAREHOUSE_ALLOCATION_DISPLAY_NAME ? get(item, 'totalDeliveredTonnage') : EMPTY_VALUE,
        className: 'large', cellStyle: {width: '12%'}
      });
    }
    const count = allocations.length
    let heading = header
    if(!isLoading)
      heading += ` (${count})`

    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={count ? this.toggleExpanded : () => {}}>
          { heading }
          {
            Boolean(count) &&
              <span className="expand-icon">
                {renderBasedOnExpandedFlag(expanded)}
              </span>
          }
        </h2>
        {
          expanded &&
            <div style={{marginTop: '15px'}}>
              {
                isLoading ?
                  <LoaderInline containerClassName="inline-loader-container" /> :
                  <GenericTable
                    columns={COLUMNS}
                    items={allocations}
                    optionsItems={(item) => this.getAllocationOptionItems(item)}
                    handleOptionClick={this.handleOptionClick}
                  />
              }
            </div>
        }
        {
          edit && !isEmpty(selectedContract) &&
            <ContractAllocationForm edit onClose={this.onClose} subjectContract={this.props.contract} objectContract={selectedContract} allocatedTonnage={selectedContract.allocatedTonnage} open />
        }
      </Paper>
    )
  }
}

export default ContractAllocations

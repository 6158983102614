import React from 'react';

import moment from 'moment';
import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import {
  TIME_DB_FORMAT,
  TIME_DISPLAY_FORMAT,
} from '../../common/constants';
import isEmpty from 'lodash/isEmpty';
import pick from 'lodash/pick';
import has from 'lodash/has'

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

class CommonTimePicker extends React.Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.value != this.props.value) {
      this.setStateAndPropagate(this.props.value);
    }
  }

  handleChange(event) {
    this.setStateAndPropagate(event.target.value);
  }

  setStateAndPropagate(value) {
    this.setState(
      { value },
      () => {this.props.onChange(moment(value, TIME_DISPLAY_FORMAT).format(TIME_DB_FORMAT), this.props.id);}
    );
  }

  render() {
    const valueArgs = pick(this.props, ['defaultValue', 'value']);
    if(has(valueArgs, 'value'))
      valueArgs.value = valueArgs.value || ''
    if(has(valueArgs, 'defaultValue'))
      valueArgs.defaultValue = valueArgs.defaultValue || ''
    return (
      <div className={this.props.disabled && (this.props.value || this.props.defaultValue) ? "relative-pos text-black": "relative-pos"}>
        <TextField
          id={this.props.id}
          error={!isEmpty(this.props.errorText)}
          inputRef={this.props.setRef}
          disabled={this.props.disabled}
          label={this.props.shouldRemoveLabel ? '' : this.props.floatingLabelText || 'Time'}
          type="time"
          fullWidth
          onChange={this.handleChange}
          helperText={this.props.errorText || ''}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            step: 300, // 5 min
          }}
          {...valueArgs}
          variant="standard" />
        {
          this.props.disabled && (this.props.value || this.props.defaultValue) &&
          <i
            className={this.props.lockIconClassName ? "side-drawer-icon-lock" : 'icon-lock'}
            style={this.props.lockIconStyle}
          />
        }
      </div>
    );
  }
}

export default withStyles(styles)(CommonTimePicker);

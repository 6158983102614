import React, {Component} from 'react';


class FreightOrder extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
      <g fill="none" fillRule="evenodd">
        <path stroke={this.props.fill} strokeWidth="3.333" d="M27.63 21.667h-4.297v13.338h10v-7.481l-5.703-5.857z"/>
        <circle cx="7.5" cy="35.833" r="3.333" stroke={this.props.fill} strokeWidth="1.667"/>
        <circle cx="27.5" cy="35.833" r="3.333" stroke={this.props.fill} strokeWidth="1.667"/>
        <path d="M0 0h40v40H0z"/>
        <path stroke={this.props.fill} strokeLinecap="round" strokeWidth="3.333" d="M34.003 21.165a10.817 10.817 0 0 0 4.33-8.665c0-5.983-4.85-10.833-10.833-10.833-5.983 0-10.833 4.85-10.833 10.833"/>
        <rect width="1.667" height="6.667" x="26.667" y="6.667" fill={this.props.fill} rx=".833"/>
        <rect width="1.667" height="6.667" x="28.78" y="11.28" fill={this.props.fill} rx=".833" transform="rotate(135 29.613 14.613)"/>
        <path fill={this.props.fill} fillRule="nonzero" d="M3.333 18.333v15S0 35.225 0 34.304V16.667C0 15.747.746 15 1.667 15h21.666c.92 0 1.667.746 1.667 1.667V35c0 .92-.746 1.667-1.667 1.667H10.93l-.589-3.334h11.326v-15H3.333z"/>
        <path fill={this.props.fill} fillRule="nonzero" d="M3.333 35H1.667v-1.667h1.595c.024 0 1.644 0 1.644.002l-.788 3.332H1.667C.747 36.667 0 35.92 0 35v-1.667a1.667 1.667 0 0 1 3.333 0V35z"/>
      </g>
    </svg>;
  }
}

export default FreightOrder;

import { connect } from 'react-redux';
import TitleTransferForm from '../components/title-transfers/TitleTransferForm';
import { requestTitleTransfer } from '../actions/companies/contracts';
import { getCommoditiesWithVarieties } from '../actions/api/commodities';

function submit(contractId, data) {
  return dispatch => {
    dispatch(requestTitleTransfer(contractId, data, true));
  };
}

function fetchVarieties() {
  return dispatch => {
    dispatch(getCommoditiesWithVarieties());
  };
}

export default connect(null, { submit, fetchVarieties })(TitleTransferForm);

import React from 'react';
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import HierarchySideDrawer from '../common/HierarchySideDrawer';
import APIService from '../../services/APIService';
import { Chip } from '@mui/material';
import { isArray, isEmpty, isEqual, orderBy, uniq, without, map, flatten, compact, filter } from 'lodash';
import { currentUser } from '../../common/utils';

const COLOR_LEGEND = {
  not_booked: 'hierarchy-red',
  booked: 'hierarchy-yellow',
  delivered: 'hierarchy-green'
};

const STATUS_COLORS = {
  ...COLOR_LEGEND,
  notbooked: 'hierarchy-red',
};

class OrderDistribution extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      open: false,
      isLoading: false,
      selected: [],
    };
  }

  showHierarchy = () => {
    if(this.state.isLoading)
      return;

    this.setState({open: true});
  };

  closeHierarchy = () => this.setState({open: false});

  componentDidMount() {
    this.fetchOrdersData();
  }

  componentDidUpdate(prevProps) {
    const { site, start, end } = this.props;
    if(!isEqual(prevProps.site, site) || !isEqual(prevProps.start, start) || !isEqual(prevProps.end, end))
      this.fetchOrdersData();
  }

  fetchOrdersData = refresh => {
    const { site, start, end } = this.props;
    if(site, start, end)
      this.setState({isLoading: true}, () => {
        APIService.freights().orders().appendToUrl('slot-tonnage-distribution/')
          .post({
            siteId: site.id, companyId: currentUser().companyId, start: start, end: end
          })
          .then(data => {
            const mViewStart = moment(this.props.viewStartDate.toDate()).startOf('day');
            const mViewEnd = moment(this.props.viewEndDate.toDate()).endOf('day');
            const _orders = filter(data, order => {
              const mStart = moment(order.startDate)
              const mEnd = moment(order.endDate)
              return mStart.isBetween(mViewStart, mViewEnd) || mEnd.isBetween(mViewStart, mViewEnd) || (mStart.isSameOrBefore(mViewStart) && mEnd.isSameOrAfter(mViewEnd))
            })
            this.setState({
              data: orderBy(_orders, ['bars.0.value'], ['desc']), isLoading: false,
              selected: map(_orders, 'id'),
            }, () => refresh && alertifyjs.success('Successfully refreshed!'))
          });
      });
  };

  getChildrenOrderIds = orderId => orderId ? map(document.querySelector(`div [elementid="${orderId}"]`).querySelectorAll('div.hierarchy-object'), el => parseInt(el.getAttribute('elementid'))) : []

  onSelectChange = (id, checked, orderIds) => {
    //id null -> bulk operation

    let selectedOrderIds;

    if(checked) {
      selectedOrderIds = isArray(orderIds) ? orderIds : uniq([...this.state.selected, id])
      if(!isEmpty(selectedOrderIds))
        selectedOrderIds = flatten(map(selectedOrderIds, orderId => [orderId, ...this.getChildrenOrderIds(orderId)]))
    } else {
      selectedOrderIds = isArray(orderIds) ?
        flatten(map(orderIds, orderId => [orderId, ...this.getChildrenOrderIds(orderId)])) :
        without(this.state.selected, ...[id, ...this.getChildrenOrderIds(id)]);
    }
    selectedOrderIds = uniq(compact(selectedOrderIds))

    this.setState({selected: selectedOrderIds}, () => {
      if(this.props.onSelectChange)
        this.props.onSelectChange(this.state.selected);
    });
  };

  render() {
    const { data, open, isLoading, selected } = this.state;
    const { title } = this.props;
    const label = 'Order Distribution';
    return (
      <span style={{margin: '0 1px'}}>
        {
          isArray(data) && !isEmpty(data) &&
          <Chip
            id="order-distribution"
            label={isLoading ? `${label} (loading...)` : label}
            variant="outlined"
            clickable={!isLoading}
            onClick={this.showHierarchy}
            style={{minWidth: '100px'}}
            color="secondary"
          />
        }
        <HierarchySideDrawer
          isOpen={open}
          onClose={this.closeHierarchy}
          title={title || "Orders"}
          data={data}
          legendColors={COLOR_LEGEND}
          statusColors={STATUS_COLORS}
          checkbox
          selected={selected}
          onSelectChange={this.onSelectChange}
          onRefresh={() => this.fetchOrdersData(true)}
        />
      </span>
    );
  }
}

export default OrderDistribution;

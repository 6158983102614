import React from 'react';
import { connect } from 'react-redux';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material/';
import { get, map, isEmpty, without, includes, has, uniq, find } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import CreateSystemStorage from "../../containers/CreateSystemStorage";
import GenericTable from '../GenericTable';
import { getSwapStocksColumnsByCommodity } from './utils';

class StockSwapsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: []
    };
  }

  componentDidMount() {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    if (has(cache, 'stockSwapsView'))
      this.setState({ expanded: cache.stockSwapsView.expanded || [] });
    else
      this.updateCache();
  }

  updateCache = data => {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    cache.stockSwapsView = {...cache.stockSwapsView, expanded: this.state.expanded};
    if(data)
      cache.stockSwapsView = {...cache.stockSwapsView, ...data}
    localStorage.setItem('stocks', JSON.stringify(cache));
  };

  componentDidUpdate(prevProps) {
    if(prevProps.locationType !== this.props.locationType)
      this.updateCache({location: this.props.locationType})
  }


  toggle = (event, expanded, commodityId) => {
    event.persist();
    const { locationType, owner } = this.props;
    const key = `${locationType}-${commodityId}-${owner}`;
    let newExpanded = expanded ? [...this.state.expanded, key] : without(this.state.expanded, key);
    this.setState({ expanded: uniq(newExpanded) }, () => {
      this.updateCache();
    });
  };

  render() {
    const {
      isEmptyStoragesSelected, handleOptionClick, handleDefaultCellClick, locationType,
      currentLocation, isAddSystemStorageSideDrawerOpened, onSystemStorageCloseSideDraw,
      onSystemStorageAdd, owner, stocks
    } = this.props;
    delete stocks['requestedUnit'];

    return (
      <div style={{ minHeight: '1000px' }}>
        {
          (isEmpty(stocks) && !this.props.isLoading && owner && locationType) ?
            <Accordion defaultExpanded className="stocks-container" style={{ margin: '0 0 10px 0', display: 'inline-block', width: '100%' }}>
              <AccordionSummary expanded expandIcon={<span />} className="stock-panel">
                <div className='col-xs-12 stocks-summary' style={{ margin: 'auto', padding: '0' }}>
                  <h5 className="header-title" style={{ display: 'inline-block', margin: '0 10px 0 0' }}>
                    There are no current stocks for this owner.
                  </h5>
                </div>
              </AccordionSummary>
            </Accordion> :
            (
              !isEmptyStoragesSelected && map(stocks, (details, commodityId) => {
                const key = `${locationType}-${commodityId}-${owner}`;
                const commodity = find(this.props.commodities, {id: parseInt(commodityId)})?.displayName
                  return (
                    <Accordion
                      key={key}
                      expanded={includes(this.state.expanded, key)}
                      className="stocks-container"
                      style={{ margin: '0 0 10px 0', display: 'inline-block', width: '100%' }}
                      onChange={
                        (event, expanded) => this.toggle(event, expanded, commodityId)
                      }>
                      <AccordionSummary expandIcon={<ExpandMoreIcon />} className="stock-panel">
                        <div className='col-xs-12 stocks-summary' style={{ margin: 'auto', padding: '0' }}>
                          <h3 className="header-title" style={{ display: 'inline-block', margin: '0 10px 0 0' }}>
                            {commodity}
                          </h3>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails style={{ padding: '0 0 20px 0', maxHeight: '380px', overflow: 'scroll' }}>
                        <GenericTable
                          mainContainerStyle={{ margin: '0 15px', width: '100%' }}
                          columns={getSwapStocksColumnsByCommodity(this.props.commodities, this.props.displayUnit)}
                          items={details}
                          handleOptionClick={handleOptionClick}
                          handleDefaultCellClick={handleDefaultCellClick}
                          optionsColumnNumber={13}
                          orderBy="stocks.currentTonnage"
                          order="desc"
                        />
                      </AccordionDetails>
                    </Accordion>
                  );
              })
            )
        }
        {
          isAddSystemStorageSideDrawerOpened &&
          <SideDrawer
            isOpen={isAddSystemStorageSideDrawerOpened}
            title="Add Stock"
            size="small"
            onClose={onSystemStorageCloseSideDraw}
            app="storage"
          >
            <CreateSystemStorage
              closeDrawer={onSystemStorageCloseSideDraw}
              farmId={locationType}
              companyId={get(currentLocation, 'companyId')}
              afterSubmit={onSystemStorageAdd}
            />
          </SideDrawer>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    isLoading: state.main.isLoading
  };
};

export default connect(mapStateToProps)(StockSwapsView);

import React from 'react';
import { SvgIcon, Button } from '@mui/material'
import withStyles from '@mui/styles/withStyles';

const styles = () => ({
  settingBtn:{
    minHeight: '20px',
    padding: '0 5px',
    margin:'-5px 0px 0px 5px'
  },
  settingBtnText:{
    textTransform: 'capitalize',
    padding: '0 5px',
    fontSize: '12px !important',
  }
});

const StockSettingsButton = ({classes, href, buttonText}) => {
  return (
    <Button variant="outlined" href={href} size="small" color="black">
      <SvgIcon fontSize="inherit" color='primary'>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z"/>
          <path stroke="#112C42" strokeWidth="2" d="M3 21h9V5H3v16zM12 21h9v-7h-9v7zM12 5c0-2.485-2.015-4-4.5-4S3 2.515 3 5h9zM21 14c0-2.485-2.015-4-4.5-4S12 11.515 12 14h9z"/>
          <rect width="2" height="4" x="8" y="7" fill="#112C42" rx="1"/>
          <rect width="2" height="1" x="17" y="16" fill="#112C42" rx=".5"/>
          <rect width="2" height="1" x="17" y="18" fill="#112C42" rx=".5"/>
          <rect width="2" height="3" x="20" y="21" fill="#112C42" rx="1"/>
          <rect width="2" height="4" x="11" y="20" fill="#112C42" rx="1"/>
          <rect width="2" height="3" x="2" y="21" fill="#112C42" rx="1"/>
          <rect width="2" height="7" x="8" y="12" fill="#112C42" rx="1"/>
        </g>
      </SvgIcon>
      <span className={classes.settingBtnText}>{buttonText}</span>
    </Button>
  )
}

export default withStyles(styles)(StockSettingsButton);

import { isSystemCompany, isCurrentUserBelongsToCompany } from '../../common/utils';
import { includes } from 'lodash';

export const getActionOptionMenu = (item) => {
    let options = []
    if (item?.status != 'inactive' && !includes(['Inactive', 'Completed'], item?.statusDisplayName)) {
        if (isSystemCompany() || isCurrentUserBelongsToCompany(item?.buyer?.companyId)) {
            options.push({ key: 'amend', text: 'Amend' });
            options.push({ key: 'duplicate', text: 'Duplicate' });
            options.push({ key: 'delete', text: 'Delete'});
        } else 
            options.push({ key: 'acceptContract', text: 'Accept Contract' });
    }
    else if (isSystemCompany() || isCurrentUserBelongsToCompany(item?.buyer?.companyId))
        options.push({ key: 'duplicate', text: 'Duplicate' });
    return options;
}

import findIndex from 'lodash/findIndex';
import orderBy from 'lodash/orderBy';

import {
  RECEIVE_GENERAL_CONDITIONS,
  RECEIVE_SPECIAL_CONDITIONS,
  ADD_GENERAL_CONDITION,
  EDIT_GENERAL_CONDITION,
  CANCEL_EDIT_GENERAL_CONDITION,
  CLICK_ADD_GENERAL_CONDITION_BUTTON,
  CLICK_EDIT_GENERAL_CONDITION_ICON,
  ADD_GENERAL_CONDITION_ERRORS,
  EDIT_GENERAL_CONDITION_ERRORS,
  ADD_SPECIAL_CONDITION,
  EDIT_SPECIAL_CONDITION,
  CLICK_ADD_SPECIAL_CONDITION_BUTTON,
  CLICK_EDIT_SPECIAL_CONDITION_ICON,
  ADD_SPECIAL_CONDITION_ERRORS,
} from '../../actions/companies/conditions';

const initialState = {
  generalConditions: [],
  specialConditions: [],
  selectedGeneralConditionId: undefined,
  selectedSpecialConditionId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
  setDefaultGeneralCondition: undefined,
  setDefaultSpecialCondition: undefined
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_GENERAL_CONDITIONS:
      return {
        ...state,
        generalConditions: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case RECEIVE_SPECIAL_CONDITIONS:
      return {
        ...state,
        specialConditions: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
      case ADD_GENERAL_CONDITION:
      var generalConditions = [action.item, ...state.generalConditions];
      return {
        ...state,
        generalConditions: orderBy(generalConditions,[condition => condition.name.toLowerCase()],['type', 'name']),
        selectedGeneralConditionId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
        setDefaultGeneralCondition:  action.item.id
      };
    case ADD_GENERAL_CONDITION_ERRORS:
      return {
        ...state,
        selectedGeneralConditionId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_GENERAL_CONDITION: {
      const index = findIndex(state.generalConditions, { id: action.item.id });
      return {
        ...state,
        generalConditions: [
          ...state.generalConditions.slice(0, index),
          action.item,
          ...state.generalConditions.slice(index + 1),
        ],
        selectedGeneralConditionId: undefined,
        isUpdateFormDisplayed: false,
      };
    }
    case EDIT_GENERAL_CONDITION_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_GENERAL_CONDITION:
      return {
        ...state,
        selectedGeneralConditionId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: false,
      };
    case CLICK_ADD_GENERAL_CONDITION_BUTTON:
      return {
        ...state,
        selectedGeneralConditionId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_GENERAL_CONDITION_ICON:
      return {
        ...state,
        selectedGeneralConditionId: action.index.id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    case ADD_SPECIAL_CONDITION:
      var specialConditions = [action.item, ...state.specialConditions];
      return {
        ...state,
        specialConditions: orderBy(specialConditions,[condition => condition.name.toLowerCase()],['type', 'name']),
        selectedSpecialConditionId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
        setDefaultSpecialCondition:  action.item.id
      };
    case ADD_SPECIAL_CONDITION_ERRORS:
      return {
        ...state,
        selectedSpecialConditionId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_SPECIAL_CONDITION: {
      const index = findIndex(state.specialConditions, { id: action.item.id });
      return {
        ...state,
        specialConditions: [
          ...state.specialConditions.slice(0, index),
          action.item,
          ...state.specialConditions.slice(index + 1),
        ],
        selectedSpecialConditionId: undefined,
        isUpdateFormDisplayed: false,
      };
    }
    case CLICK_ADD_SPECIAL_CONDITION_BUTTON:
      return {
        ...state,
        selectedSpecialConditionId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_SPECIAL_CONDITION_ICON:
      return {
        ...state,
        selectedSpecialConditionId: action.index.id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    default:
      return state;
  }
};

export default reducer;

export const INVOICINGS = [
  {id: 'Freight Provider to Invoice Broker', name: 'Freight Provider to Invoice Broker'},
  {id: 'Freight Provider to Invoice Customer', name: 'Freight Provider to Invoice Customer'},
  {id: 'Customer RCTI', name: 'Customer RCTI'},
];

export const INVOICINGS_NEW_MOVEMENTS = [
  {id: 'Freight Provider to Invoice Customer', name: 'Freight Provider to Invoice Customer'},
  {id: 'Customer RCTI', name: 'Customer RCTI'},
];

export const INVOICINGS_PACK_ORDERS = [
  {id: 'Packed Provider to Invoice Customer', name: 'Packed Provider to Invoice Customer'},
  {id: 'Customer RCTI', name: 'Customer RCTI'},
];

import { connect } from 'react-redux';
import OutloadForm from '../components/outloads/OutloadForm';
import { createStorageOutload } from '../actions/api/outloads';
import { addOutload } from '../actions/companies/outloads';

function submit(storageId, data, successCallback) {
  return (dispatch) => {
    dispatch(createStorageOutload(storageId, data, addOutload, successCallback));
  };
}

export default connect(
  null,
  { submit }
)(OutloadForm);

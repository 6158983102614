import React from 'react';

import './contractPreview.scss';
import map from 'lodash/map';
import includes from 'lodash/includes';
import {
  CONTRACT_TYPES, PRICE_POINTS,
  PICKUP_MANDATORY_PRICE_POINTS, DELIVERY_MANDATORY_PRICE_POINTS,
} from '../../common/constants';
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import find from 'lodash/find';
import {extractDigits, getGradeName, getCountryConfig, toPhoneFormat, getCountryLabel, isVisibleInCountry} from "../../common/utils";

class ContractPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {config: getCountryConfig()}
  }

  getEntityName(party, ngr) {
    if(this.isSharedNgr(ngr)){
      return get(ngr, 'shareHolderEntityNames', []).join(' | ');
    } else {
      return get(party, 'entityName');
    }
  }

  isSharedNgr(ngr) {
    return get(ngr, 'ngrType') === 'shared';
  }

  getApplicationDetails = applications => {
    let applicationsDetails = [];
    const { previewFields } = this.props
    const { commodities, currency, priceUnit } = previewFields
    map(applications, application => {
      const commodity = find(commodities, {id: application.commodityId})
      if (commodity) {
        applicationsDetails.push(`Commodity: ${commodity.displayName}`);
        if (application.price)
          applicationsDetails.push(`Price: ${currency}${application.price}/${commodity.unit}`);
        if (application.fee)
          applicationsDetails.push(`Application Fee: ${currency}${application.fee}/${priceUnit}`);
      }
    });
    return applicationsDetails;
  }

  getGradeName() {
    const { previewFields } = this.props
    let gradeName = '';
    if (get(previewFields, 'isBlendedContract')) {
      const labels = [];
      const tonnage = parseFloat(previewFields.tonnage);

      const spreadDetails = previewFields.spread || [];
      spreadDetails.forEach(spread => {
        const gradeName = get(spread, 'gradeName');
        const quantity = parseFloat(get(spread, 'quantity'));
        if (gradeName && quantity) {
          let percentage = parseFloat(((quantity / tonnage) * 100).toFixed(2))
          if (percentage.toString().endsWith('.0'))
            percentage = percentage.toString().replace('.0', '');
          labels.push(`${gradeName} ${percentage}%`);
        }
      });
      gradeName = labels.join(' / ');
    }
    else
      gradeName = getGradeName(previewFields);
    return gradeName;
  }

  render() {
    const { previewFields } = this.props;
    const { config } = this.state;
    const spreadLabel = getCountryLabel('spreadDetails')
    const isNGRVisible = isVisibleInCountry('ngr')
    const isABNVisible = isVisibleInCountry('abn')
    const sellerDetails = previewFields.sellerDetails;
    const buyerDetails = previewFields.buyerDetails;
    const consignees = previewFields.consignees;
    const consignors = previewFields.consignors;
    const creator = previewFields.currentUser;
    const creatorCompanyName = get(creator, 'company.name');
    const creatorEmail = get(creator, 'email');
    const creatorCompanyPhone = get(creator, 'company.mobile');
    const creatorCompanyAbn = get(creator, 'company.abn');
    const creatorCompanyAddress = get(creator, 'company.address.address');
    const ABNLabel = config.abn.label
    const fieldSizeUnit = config.farmField.sizeUnit
    const estimatedYieldUnit = `${previewFields.priceUnit} per ${fieldSizeUnit}`
    const isGSTVisible = config.invoicing.gst
    const tonnageLabel = getCountryLabel('tonnage')
    const applications = previewFields.chemicalApplications || []
    return (
      <div className="contract-preview">
        <div className="contract-header">
          <div className="agri-logo">
            {previewFields.currentUserCompanyLogo ? <img src={previewFields.currentUserCompanyLogo} alt={creatorCompanyName} crossOrigin="anonymous"/> : <h2>{creatorCompanyName}</h2>}
          </div>
          <div className="contract-header--content">
            <div className="col-xs-12 col-sm-6 col-md-6 padding-reset">
              <h4>{previewFields.brokersDocumentType}</h4>
              {previewFields.brokerEmployee && <p className="reference"><span> {previewFields.isBrokerSalesConfirmation ? 'Agent:' : 'Brokered by:'}</span> {previewFields.brokerEmployee} ({previewFields.brokerCompany})</p>}
              <p className="reference"><span>{previewFields.identifierLabel}:</span> {previewFields.identifier.toUpperCase()}</p>
              {(previewFields.contractIdentifier !== undefined && previewFields.contractIdentifier !== null) &&
               <p className="reference"><span>Contract Number:</span> {previewFields.contractIdentifier.toUpperCase()}</p>}
               <p className="reference"><span>Contract Date:</span> {previewFields.contractDate}</p>
            </div>
            <div className="text-right col-xs-12 col-sm-6 col-md-6 padding-reset">
              {creatorCompanyName && <h4>{creatorCompanyName}</h4>}
              {creatorCompanyAddress && <p>{creatorCompanyAddress}</p>}
              {creatorEmail && <p><span>EMAIL: </span>{creatorEmail}</p>}
              {creatorCompanyPhone && <p><span>PHONE: </span>{toPhoneFormat(creatorCompanyPhone)}</p>}
              {isABNVisible && creatorCompanyAbn && <p><span>{`${ABNLabel}: `}</span> {creatorCompanyAbn}</p>}
            </div>
          </div>
        </div>

        <div className="contract-body">
          <p className="description">
            {"This document is a record of the Contract agreed between the below Seller and Buyer following discussions between "}
            {previewFields.documentType == 3 ? (<span>
              {previewFields.brokerEmployee}
              {" representing "}
              {creatorCompanyName}{" (as Broker) and "}
            </span>) : null}
            {"the Seller's and Buyer's representatives on "}
            {previewFields.contractDate}{". "}
            {previewFields.documentType == 3 ? (
               <p>
                 {"For further information please contact "}
                 {creatorCompanyName}{" on "}{ creatorCompanyPhone }{"."}
               </p>
            ) : null}
          </p>
          <div className="contract-details">
            <div className="contract-details__left col-xs-12 col-sm-6 col-md-6">
              <h4>{'SELLER'.concat(previewFields.isRepresentingSeller ? ' (Representing)' : '')}</h4>
              <h5>{sellerDetails && sellerDetails.name}</h5>
              {isABNVisible && !this.isSharedNgr(previewFields.sellerNgr) && <p><span className="pre-label">{ABNLabel}</span> <span className="pre-content">{get(sellerDetails, 'abn')}</span></p>}
              <p><span className="pre-label">Entity</span> <span className="pre-content">{this.getEntityName(sellerDetails, previewFields.sellerNgr)}</span></p>
              {isNGRVisible && <p><span className="pre-label">NGR</span> <span className="pre-content">{get(previewFields, 'sellerNgr.ngrNumber')}</span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{previewFields.sellerContact && previewFields.sellerContact.name}</span></p>
              <div className="contacts">
                <p><span className="pre-label">Email</span>
                  <span className="pre-content">{previewFields.sellerContact && previewFields.sellerContact.email} </span>
                </p>
              </div>
            </div>
            <div className="contract-details__right col-xs-12 col-sm-6 col-md-6">
              <h4>{'BUYER'.concat(previewFields.isRepresentingBuyer ? ' (Representing)' : '')}</h4>
              <h5>{buyerDetails && buyerDetails.name}</h5>
              {isABNVisible && !this.isSharedNgr(previewFields.buyerNgr) && <p><span className="pre-label">{ABNLabel}</span> <span className="pre-content">{get(buyerDetails, 'abn')}</span></p>}
              <p><span className="pre-label">Entity</span> <span className="pre-content">{this.getEntityName(buyerDetails, previewFields.buyerNgr)}</span></p>
              {isNGRVisible && <p><span className="pre-label">NGR</span> <span className="pre-content">{get(previewFields, 'buyerNgr.ngrNumber')}</span></p>}
              <p><span className="pre-label">Contact</span> <span className="pre-content">{previewFields.buyerContact && previewFields.buyerContact.name}</span></p>
              <div className="contacts">
                <p><span className="pre-label">Email</span>
                  <span className="pre-content">{previewFields.buyerContact && previewFields.buyerContact.email}</span>
                </p>
              </div>
            </div>
          </div>



          <div className="contract-details margintop-20">
            <div className="contract-details__left col-xs-12 col-sm-6 col-md-6">
              <h5>CONTRACT DETAILS</h5>
              <p><span className="pre-label">CONTRACT TYPE</span> <span className="pre-content">{previewFields.contractType}</span></p>
              <p><span className="pre-label">COMMODITY</span> <span className="pre-content">{previewFields.commodityDetails + (previewFields.sustainableCommodity ? ' (Sustainable)': '')}</span></p>
              <p><span className="pre-label">Lot No.</span> <span className="pre-content">{previewFields.lotNumber}</span></p>
              <p><span className="pre-label">VARIETY</span> <span className="pre-content">{previewFields.variety}</span></p>
              {previewFields.showGradeField && <p><span className="pre-label">{((previewFields.contractTypeId === CONTRACT_TYPES.FLOATING_MULTI_GRADE || previewFields.contractTypeId == CONTRACT_TYPES.MULTI_GRADE || previewFields.isAreaContract) ? 'Base Grade' : 'Grade')}</span> <span className="pre-content">{this.getGradeName()}</span></p>}
              {previewFields.isPoolContract && <p><span className="pre-label">GRADE</span> <span className="pre-content">{previewFields.poolGrades}</span></p>}
              {
                previewFields.showSpreadGradesSection &&
                  (
                    [CONTRACT_TYPES.FLOATING_MULTI_GRADE, CONTRACT_TYPES.MULTI_GRADE, CONTRACT_TYPES.AREA, CONTRACT_TYPES.BLENDED].includes(previewFields.contractTypeId) &&
                      <p>
                        <span className="pre-label">
                          {spreadLabel}
                        </span>
                        <span className="pre-content">
                          {
                            map(previewFields.spread, (grade, index) => {
                              return grade.gist ?
                                <div key={index}><span className="spread-section" key={index} style={{fontWeight: 'normal'}}>{grade.gist}</span></div> :
                              <span className="spread-section" key={index}>{grade.name} : { grade.value ? `${grade.priceVariation} ${previewFields.currency} ${extractDigits(grade.value)}` : '-' } &nbsp;</span>;
                            })
                          }
                        </span>
                      </p>
                  )}
              {
                applications?.length > 0 &&
                      <p>
                        <span className="pre-label">
                          Applications
                        </span>
                        <span className="pre-content">
                          {
                            map(this.getApplicationDetails(applications), (applicationDetail, index) => {
                              return <div key={index}><span className="spread-section" key={index} style={{fontWeight: 'normal'}}>{applicationDetail}</span></div>
                            })
                          }
                        </span>
                      </p>
                  }

              {previewFields.quantity && <p><span className="pre-label">{previewFields.quantityLabel.toUpperCase()}</span><span className="pre-content">{previewFields.quantity} {previewFields.quantityUnit}</span></p>}
              <p><span className="pre-label">{previewFields.isAreaContract ? `MAXIMUM ${tonnageLabel.toUpperCase()}` : `${tonnageLabel.toUpperCase()}`}</span> <span className="pre-content">{previewFields.tonnage} {previewFields.tonnageUnit}</span></p>
              <p><span className="pre-label">SEASON</span> <span className="pre-content">{previewFields.season}</span></p>
              <p><span className="pre-label">PRICE POINT</span> <span className="pre-content">{previewFields.pricePoint} &nbsp;{((previewFields.pricePointId === PRICE_POINTS.TRACK ) ? '(' + previewFields.track + ')' : '')}</span></p>
              {
                previewFields.price &&
                  <p>
                    <span className="pre-label" style={{textTransform: "none"}}>
                      {`PRICE (PER ${previewFields.priceUnit})`}
                    </span>
                    <span className="pre-content">
                      {previewFields.price + (isGSTVisible ? ` exc. ${getCountryLabel('gst')}` : '')}
                    </span>
                  </p>
              }
              {
                previewFields.blendingFee &&
                  <p>
                    <span className="pre-label" style={{textTransform: "none"}}>
                      {`Blending Fee (PER ${previewFields.priceUnit})`}
                    </span>
                    <span className="pre-content">
                      {`${previewFields.currency} ${previewFields.blendingFee}`}
                    </span>
                  </p>
              }
              {previewFields.carryValue !== null && <p><span className="pre-label">Carry</span><span className="pre-content">{previewFields.carryDetails}</span></p>}
              <p><span className="pre-label">Invoicing</span><span className="pre-content">{previewFields.invoicing}</span></p>
              {previewFields.isAreaContract && <React.Fragment><p><span className="pre-label">Area</span><span className="pre-content">{previewFields.area} {fieldSizeUnit}</span></p>
                <p><span className="pre-label">Estimated Yield</span><span className="pre-content">{previewFields.estimatedYield} {estimatedYieldUnit}</span></p>
                                                    <p><span className="pre-label">{`Estimated ${tonnageLabel}`}</span><span className="pre-content">{previewFields.estimatedTonnage} {previewFields.priceUnit}</span></p>
                <p><span className="pre-label">Price By</span><span className="pre-content">{previewFields.priceBy}</span></p></React.Fragment>}
              {!isEmpty(previewFields.brokerages) &&
                <React.Fragment>
                  <p><span className="pre-label brokerage-label">
                  {previewFields.isBrokerSalesConfirmation ? 'Agent Fees' : 'Brokerage'}</span>
                    <div style={{display : 'inline-grid'}}>
                    {
                      map(previewFields.brokerages, (brokerage, i) => {
                        let brokerageText;
                        let type = brokerage['feeType']['value'];
                        let totalFee = brokerage['totalFee']['value'];
                        let rate = brokerage['rate']['value'];
                        let chargedOn = brokerage['chargedOn']['value'];
                        if (type === 'Flat Fee')
                          brokerageText = `${previewFields.currency} ${totalFee} Flat Fee, Charged at ${chargedOn}`;
                        else if (type === 'Fee Per MT') {
                          brokerageText = `${previewFields.currency} ${rate} per ${previewFields.priceUnit}, Charged at ${chargedOn}`;
                          if (chargedOn != 'Post-Delivery')
                            brokerageText = `${previewFields.currency} ${totalFee}  @  ${brokerageText}`
                        } else {
                          brokerageText = `${rate}% of sale, Charged at ${chargedOn}`;
                          if (chargedOn != 'Post-Delivery')
                            brokerageText = `${previewFields.currency} ${totalFee}  @  ${brokerageText}`
                        }
                        return <span index={i} style={{fontWeight: 400, fontSize: '15px'}}>{brokerageText} ({brokerage.type.value})</span>;
                      })
                    }
                    </div>
                  </p>
                </React.Fragment>}
      </div>
      <div className="contract-details__right col-xs-12 col-sm-6 col-md-6">
        <h5>DELIVERY DETAILS</h5>
        {
          includes(PICKUP_MANDATORY_PRICE_POINTS, previewFields.pricePointId) &&
            <div>
              {
                map(consignors, (consignor, index) => (
                  <div key={index}>
                    <p>
                      <span className="pre-label">
                        PICKUP SITE{consignors.length > 1 ? index+1: ''}
                      </span>
                      <span className="pre-content">
                        {get(consignor, 'item.displayName') || get(consignor, 'item.name')} {get(consignor,'ld.value') != 0 ? '(Price Differential: ' + get(consignor,'ld.value') + ')' : null}
                      </span>
                    </p>
                  </div>
                ))
              }
            </div>
        }
        {
          includes(DELIVERY_MANDATORY_PRICE_POINTS, previewFields.pricePointId) &&
            <div>
          {
            map(consignees, (consignee, index) => (
              <div key={index}>
                <p>
                  <span className="pre-label">
                    DELIVERY SITE{consignees.length > 1 ? index+1: ''}
                  </span>
                  <span className="pre-content">
                    {get(consignee,'item.displayName') || get(consignee,'item.name')} {get(consignee,'ld.value') != 0 ? '(Price Differential: ' + get(consignee,'ld.value') + ')' : null}
                  </span>
                </p>
              </div>
            ))
          }
        </div>}
        {
          includes(PICKUP_MANDATORY_PRICE_POINTS, previewFields.pricePointId) ?
          <p><span className="pre-label">PICKUP ADDRESS</span><span className="pre-content">{previewFields.pickupSiteAddress}</span></p> :
          <p><span className="pre-label">DELIVERY ADDRESS</span><span className="pre-content">{previewFields.deliverySiteAddress}</span></p>
        }
        <p><span className="pre-label">DELIVERY ONUS</span> <span className="pre-content">{previewFields.deliveryOnus}</span></p>
        <p><span className="pre-label">DELIVERY PERIOD</span> <span className="pre-content">{previewFields.deliveryStartDate} to {previewFields.deliveryEndDate}</span></p>
        <p><span className="pre-label">PAYMENT TERMS</span> <span className="pre-content">{previewFields.paymentTerms}</span></p>
        <p><span className="pre-label">PAYMENT SCALE</span> <span className="pre-content">{previewFields.paymentScale}</span></p>
        <p><span className="pre-label">CONVEYANCE</span> <span className="pre-content">{previewFields.conveyance}</span></p>
        <p><span className="pre-label">TOLERANCE</span> <span className="pre-content">{previewFields.tolerance}</span></p>
        <p><span className="pre-label">WEIGHT</span><span className="pre-content">{previewFields.weight}</span></p>
        <p><span className="pre-label">INSPECTION</span> <span className="pre-content">{previewFields.inspection}</span></p>
        <p><span className="pre-label">PACKAGING</span> <span className="pre-content">{previewFields.packaging}</span></p>
        {previewFields.marketZoneId && <p><span className="pre-label">MARKET ZONE</span> <span className="pre-content">{previewFields.marketZone}</span></p>}
        {previewFields.regionId && <p><span className="pre-label">REGION</span> <span className="pre-content">{previewFields.region}</span></p>}
      </div>
      </div>
      </div>
      {previewFields.contractConditions && <div className="commodity-details contract-conditions">
        <h4>General Conditions</h4>
        <p>{previewFields.contractConditions}</p>
      </div>}
      {previewFields.specialConditions && <div className="commodity-details contract-conditions">
        <h4>Special Conditions</h4>
        <p>{previewFields.specialConditions}</p>
      </div>}
      </div>
    );
  }
}

export default ContractPreview;

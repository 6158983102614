import React from 'react';

import { connect } from 'react-redux';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import { getCompanyFarmsWeb, getCompanyFarms } from '../../actions/api/farms';
import { getCompanyDetails, showSideDrawerForTransfer } from '../../actions/companies';
import {
  clickAddFarmButton,
  hideCreateFarmForm,
} from '../../actions/companies/farms';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { receivePaginatedFarms, receiveFarms } from '../../actions/companies/farms';
import { isInCompanyContext, isSystemCompany } from '../../common/utils';
import { get, isEqual } from 'lodash';
import FarmListing from './FarmListing';
import ApprovedBuyers from './ApprovedBuyers';

class Farms extends React.Component {
  constructor(props) {
    super(props);

    this.FARM_LISTING = 'farm';
    this.ARCHIVED_FARM_LISTING = 'archived-farm';
    this.APPROVED_BUYER = 'approved-buyers';
    this.state = {
      value: 'farm',
    };
  }

  componentDidMount() {
    if (this.props.companyId) {
      if (!this.props.selectedCompany || this.props.selectedCompany.id !== this.props.companyId) {
        this.props.getCompany(this.props.companyId);
      }
    }

    this.setHeaderAndBreadcrumbs();
  }

  componentDidUpdate() {
    this.setHeaderAndBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.hideCreateFarmForm();
    this.props.cleanFarms();
    this.props.cancelTransferDrawer(null, false);
  }

  setHeaderAndBreadcrumbs() {
    if (this.props.companyId && this.props.selectedCompany)
      this.setHeaderTextAndBreadcrumbsFromCompany();
    else {
      if (this.props.headerText !== 'Farms')
        this.props.setHeaderText('Farms');
      let breadcrumbs = [{ text: `Farms (${this.props.count})` }];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs))
        this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  setHeaderTextAndBreadcrumbsFromCompany() {
    if (this.props.selectedCompany) {
      if (this.props.headerText !== this.props.selectedCompany.name) {
        this.props.setHeaderText(this.props.selectedCompany.name);
      }
      const breadcrumbs = [
        { text: 'Companies', route: '/companies' },
        { text: this.props.selectedCompany.name, onClick: this.props.onDetailsClick, route: '/companies/' + this.props.selectedCompany.id + '/details' },
        { text: `Farms (${this.props.count})` }
      ];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  onTabChanges = (event, value) => {
    this.setState({ value });
  };

  render() {
    const { value } = this.state;
    return (
      <div>
        {this.props.isFromCompanySetting && (this.props.companyId == this.props.user.companyId || isSystemCompany()) ?
          <div className="subTab">
            <Tabs className="subTab-header" indicatorColor="primary" value={value} onChange={this.onTabChanges}>
              <Tab label="Farms" value={this.FARM_LISTING} className={value !== this.FARM_LISTING ? 'unselected-subtab' : ''} />
              <Tab label="Archived Farms" value={this.ARCHIVED_FARM_LISTING} className={value !== this.ARCHIVED_FARM_LISTING ? 'unselected-subtab' : ''} />
              <Tab label="Approved Buyers" value={this.APPROVED_BUYER} className={value !== this.APPROVED_BUYER ? 'unselected-subtab' : ''} />
            </Tabs>
            {
              value === this.FARM_LISTING && <FarmListing {...this.props} />
            }
            {
              value === this.ARCHIVED_FARM_LISTING && <FarmListing {...this.props} isArchived={true}/>
            }
            {
              value === this.APPROVED_BUYER && <ApprovedBuyers {...this.props} />
            }
          </div> :
          <FarmListing {...this.props} />}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.main.user.user,
    isCreateFormDisplayed: state.companies.farms.isCreateFormDisplayed,
    headerText: state.main.headerText,
    selectedCompany: state.companies.companies.selectedCompany,
    breadcrumbs: state.main.breadcrumbs,
    selectedCompanyId: state.main.user.user.companyId,
    token: state.main.user.token,
    count: get(state.companies.farms, 'paginationData.count') || get((get(state.companies.farms, 'items') || []), 'length') || 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
    cleanFarms: () => dispatch(receivePaginatedFarms({ results: [], paginationData: {} })),
    getFarms: (companyId) => dispatch(getFarms(companyId)),
    getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
    handleAddFarmButtonClick: () => dispatch(clickAddFarmButton()),
    hideCreateFarmForm: () => dispatch(hideCreateFarmForm()),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  };
};

function getFarms(selectedCompanyId) {
  return (dispatch, getState) => {
    const companyId = selectedCompanyId || getState().main.user.user.companyId;
    isInCompanyContext() ? dispatch(getCompanyFarms(companyId, receiveFarms, true, null)) : dispatch(getCompanyFarmsWeb(companyId, null, false, true));
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Farms);

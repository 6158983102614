import React from 'react';
import moment from 'moment';
import {
  Dialog, DialogContent, Slide, Tooltip, IconButton
} from '@mui/material';
import { Done, Refresh as RefreshIcon, HourglassEmpty as PendingIcon } from '@mui/icons-material';
import { get, includes, isEqual } from 'lodash';
import APIService from '../../services/APIService';
import LoaderInline from '../LoaderInline';
import { PRIMARY_COLOR_GREEN, WHITE } from '../../common/constants';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import GoogleMapDirection from '../common/GoogleMapDirection';
import { toDateTimeFormat, getCountryFormats } from '../../common/utils';


class PickupInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      countryFormats: getCountryFormats(),
      data: {},
      isLoading: false,
    };
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { movementId } = this.props;
    if(movementId) {
      this.fetchFootprint(false);
      this.pollFetch = setInterval(() => this.fetchFootprint(true), 60000);
    }
  }

  fetchFootprint(refresh) {
    const { movementId } = this.props;
    this.setState({isLoading: Boolean(!refresh)}, () => {
      APIService.freights().contracts(movementId)
                .appendToUrl('footprint/')
                .get()
                .then(data => this.setState({data: data, isLoading: false}));
    });
  }

  Transition(props) {
    return <Slide direction="left" {...props} />;
  }

  onClose() {
    clearInterval(this.pollFetch);
    this.props.onClose();
  }

  getTitleAndIcon() {
    const { movementStatus } = this.state.data;
    const styles = {marginRight: '10px', color: WHITE, backgroundColor: PRIMARY_COLOR_GREEN, borderRadius: '15px', width: '20px', height: '20px'};
    let title = 'Truck Status';
    let icon = <Done style={styles} />;
    if(movementStatus === 'confirmed') {
      title = 'Pickup Pending';
      icon = <PendingIcon style={styles} />;
    }
    else if(movementStatus === 'in_progress')
      title = 'Pickup Complete';
    else if(includes(['delivered', 'completed', 'invoiced'], movementStatus))
      title = 'Delivery Complete';

    return {title: title, icon: icon};
  }

  getETA = () => {
    const { data } = this.state
    let dropTime = isEqual(this.state.data.movementStatus, 'delivered') && data.dropLocationRecordedAt;
    let lastLocationTime = data.currentLocationRecordedAt;
    let pickupTime = data.pickupLocationRecordedAt;
    const utcOffsetMinutes = this.props.pickupUtcOffsetMinutes || this.props.deliveryUtcOffsetMinutes;
    let eta = isEqual(this.state.data.movementStatus, 'confirmed') ?
      (
        isEqual(this.props.slotType, 'outload') && lastLocationTime && utcOffsetMinutes ?
          moment(lastLocationTime).utcOffset(utcOffsetMinutes).add(get(data, 'distanceToPickupSite.duration.value', '0'), 'seconds') :
          ''
      ) :
      (
        !dropTime && (pickupTime || !get(this.state.data, 'distanceToPickupSite.distance.text')) && lastLocationTime && utcOffsetMinutes ?
          moment(lastLocationTime).utcOffset(utcOffsetMinutes).add(get(data, 'truckDistanceToDestination.duration.value', '0'), 'seconds') :
          ''
      )

    let deliveryTZAbbr = this.props.deliveryTZAbbreviation ? ` (${this.props.deliveryTZAbbreviation})` : ''

    if (((isEqual(this.props.slotType, 'inload') && pickupTime && moment(pickupTime).isAfter(eta)) || (isEqual(this.props.slotType, 'outload') && pickupTime)))
      eta = null;

    if (eta) {
      const currentTime = moment();
      const sixHoursAgo = moment(currentTime).subtract(6, 'hours');
      const sixHoursLater = moment(currentTime).add(6, 'hours');
      if (eta.isBetween(sixHoursAgo, sixHoursLater))
        eta = eta.format(this.state.countryFormats.datetime) + deliveryTZAbbr;
      else
        eta = null;
    }
    return eta || 'N/A'
  }

  render() {
    const { data, isLoading } = this.state;
    const { show } = this.props;
    let pickupTime = data.pickupLocationRecordedAt;
    let tzAbbr = data?.timezoneAbbreviation ? ` (${data.timezoneAbbreviation})` : ''
    let formattedPickupTime = moment(pickupTime).isValid() ? toDateTimeFormat(pickupTime) + tzAbbr : 'N/A';
    let dropTime = isEqual(this.state.data.movementStatus, 'delivered') && data.dropLocationRecordedAt;
    let lastLocationTime = data.currentLocationRecordedAt;
    let formattedLastLocationTime = moment(lastLocationTime).isValid() ? toDateTimeFormat(lastLocationTime) + tzAbbr : 'N/A';
    const { title, icon } = this.getTitleAndIcon();
    const eta = this.getETA()

    return (
      <div>
        <Dialog
          open={show}
          TransitionComponent={this.Transition}
          keepMounted
          className='slot-comments-dialog'
          onClose={this.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitleWithCloseIcon onClose={this.onClose} style={{height: '55px'}}>
            <div style={{marginTop: '-4px'}}>Truck Status</div>
          </DialogTitleWithCloseIcon>
          {
            isLoading ?
            <DialogContent>
              <LoaderInline
                containerClassName="inline-loader-container"
                style={{height: '100%'}}
                imageStyle={{width: '30%'}}
              />
            </DialogContent> :
            <DialogContent>
              <div style={{display: 'flex', alignItems: 'center'}} >
                <h4 style={{width: '80%', display: 'flex'}}>
                  {icon}
                  {title}
                </h4>
                <Tooltip title='Refresh'>
                  <IconButton
                    onClick={() => this.fetchFootprint(false)}
                    variant='outlined'
                    color='primary'
                    size="large">
                    <RefreshIcon />
                  </IconButton>
                </Tooltip>
              </div>
                <div className='col-xs-12 padding-reset' style={{fontSize: '14px'}}>
                  <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                    Pickup Time
                  </div>
                  <div className='col-xs-8 padding-reset'>
                    {isEqual(this.state.data.movementStatus, 'confirmed') ? 'N/A' : formattedPickupTime || 'N/A'}
                  </div>
                </div>
                <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                  <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                    Pickup Site
                  </div>
                  <div className='col-xs-8 padding-reset'>
                    {
                      data.pickupSiteName ?
                        data.pickupSiteName + ' | ' : 'N/A'
                    }
                    {
                      data.pickupStorageName ?
                        data.pickupStorageName : ''
                    }
                  </div>
                </div>
                {
                  isEqual(this.state.data.movementStatus, 'confirmed') && get(this.state.data, 'distanceToPickupSite.distance.text') &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Distance to Pickup
                    </div>
                    <div className='col-xs-8 padding-reset'>
                      {
                        get(this.state.data, 'distanceToPickupSite.distance.text', '-')
                      }
                    </div>
                  </div>
                }
                {
                  isEqual(this.state.data.movementStatus, 'confirmed') && get(this.state.data, 'distanceToPickupSite.duration.text') &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Est. Travel Time
                    </div>
                    <div className='col-xs-8 padding-reset'>
                      {
                        get(this.state.data, 'distanceToPickupSite.duration.text', '-')
                      }
                    </div>
                  </div>
                }
                {
                  isEqual(this.props.slotType, 'inload') && get(this.state.data, 'distanceMatrix.distance.text') &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Distance B/w Sites
                    </div>
                    <div className='col-xs-8 padding-reset'>
                    {
                      get(this.state.data, 'distanceMatrix.distance.text', '-')
                    }
                    </div>
                  </div>
                }
                {
                  isEqual(this.props.slotType, 'inload') && get(this.state.data, 'distanceMatrix.duration.text') &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Est. Travel Time
                    </div>
                    <div className='col-xs-8 padding-reset'>
                    {
                      get(this.state.data, 'distanceMatrix.duration.text', '-')
                    }
                    </div>
                  </div>
                }
                {
                  isEqual(this.props.slotType, 'inload' ) && !dropTime && (pickupTime || !get(this.state.data, 'distanceToPickupSite.distance.text')) &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Distance to Delivery
                    </div>
                    <div className='col-xs-8 padding-reset'>
                    {
                      get(this.state.data, 'truckDistanceToDestination.distance.text', '-')
                    }
                    </div>
                  </div>
                }
                {
                  isEqual(this.props.slotType, 'inload' ) && !dropTime && (pickupTime || !get(this.state.data, 'distanceToPickupSite.distance.text')) &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Est. Travel Time
                    </div>
                    <div className='col-xs-8 padding-reset'>
                    {
                      get(this.state.data, 'truckDistanceToDestination.duration.text', '-')
                    }
                    </div>
                  </div>
                }
                <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                  <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                    ETA
                  </div>
                  <div className='col-xs-8 padding-reset'>
                    { eta }
                  </div>
                </div>
                {
                  lastLocationTime &&
                  <div className='col-xs-12 padding-reset' style={{fontSize: '14px', marginTop: '10px'}}>
                    <div className='col-xs-4 padding-reset' style={{color: 'rgba(0,0,0,0.5)'}}>
                      Last Updated
                    </div>
                    <div className='col-xs-8 padding-reset'>
                      {formattedLastLocationTime}
                    </div>
                  </div>
                }
                {
                  Boolean(get(data, 'pickupCoordinates') || get(data, 'dropCoordinates')) &&
                  <div className='col-xs-12 padding-reset' style={{marginTop: '10px'}}>
                    <GoogleMapDirection
                      slotStatus={this.props.slotStatus}
                      movementStatus={this.state.data.movementStatus}
                      source={data.pickupCoordinates}
                      destination={data.dropCoordinates}
                      slotType={this.props.slotType}
                      waypoints={data.currentCoordinates ? [data.currentCoordinates] : []}
                    />
                  </div>
                }
            </DialogContent>
          }
        </Dialog>
              </div>
    );
  }
}

export default PickupInfo;

import React from 'react';
import alertify from 'alertifyjs';
import get from 'lodash/get';
import set from 'lodash/set';
import some from 'lodash/some';
import InputAdornment from '@mui/material/InputAdornment';
import UserIcon from '@mui/icons-material/PermIdentity';
import { required, minLength } from '../common/validators';
import APIService from '../services/APIService';
import CommonButton from './common/CommonButton';
import CommonTextField from './common/CommonTextField';
import alertifyjs from 'alertifyjs';

const SUCCESSFUL_PASSWORD_RESET = 'An email containing details to reset your password has been sent to your inbox';

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: {
        value: null,
        validators: [required('This field can not be blank'), minLength(3)],
        errors: []
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(event) {
    this.setErrors();

    if (this.getIsFormValid()) {
      const data = { username: this.state.username.value };

      APIService.profiles().appendToUrl('forgot_password').post(data)
        .then((response) => {
          if (response.errors) {
            if (response.errors.username) {
              this.setErrors([response.errors.username[0]]);
            } else {
              alertify.error(response.errors.error || 'Error');
            }
          } else if (response?.warning) {
            alertifyjs.confirm(
              'Warning',
              `${response.warning}.Please confirm that you would like to receive the password on this email ID.`,
              () => {
                data['forceChange'] = true;
                APIService.profiles().appendToUrl('forgot_password').post(data)
                .then(() => {
                  alertify.success(SUCCESSFUL_PASSWORD_RESET);
                  document.location.hash = '/';
                })
              },
              () => {}
            ).set('reverseButtons', true).set(
              'labels', {ok: 'Confirm', cancel: 'Cancel'}
            ).show()
          } else if (response.alert) {
            alertify.error(response.alert, 0);
          } else {
            alertify.success(SUCCESSFUL_PASSWORD_RESET);
            document.location.hash = '/';
          }
        });
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    this.setState(
      state => set(state, 'username.value', value),
      () => this.setErrors()
    );
  }

  setErrors(errors) {
    this.setState(state => set(state, `username.errors`, errors || this.getErrors()));
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `username.value`);
    const validators = get(this.state, `username.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return !some(this.state.username.validators, (validator) => {
      return validator.isInvalid(this.state.username.value);
    });
  }

  render() {
    return (
      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-content-box">
            <div className="login-content-box--header">
              <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
            </div>
            <div className="login-content-box--content">
              <h2>Reset Password</h2>
              <p>Enter your username and we’ll send you a link on email to reset your password.</p>
              <form onSubmit={this.handleSubmit} noValidate>
                <div className="cardForm cardForm--drawer">
                  <div className="cardForm-content row">
                    <div className="col-sm-12 form-wrap">
                      <CommonTextField
                        id="username"
                        label="Username"
                        placeholder="Please enter"
                        value={this.state.username.value}
                        onChange={this.handleChange}
                        helperText={get(this.state, 'username.errors[0]', '')}
                        maxLength="255"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <UserIcon nativeColor="#999999" />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
                  <div className="col-12" style={{'textAlign': 'center'}}>
                    <CommonButton
                      label="Back to Login"
                      secondary={true}
                      variant="outlined"
                      href="#/"
                    />
                    <CommonButton
                      type="submit"
                      variant="contained"
                      label="Reset Password"
                      primary
                      className="login-button"
                    />
                  </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { TRANSPORT_MODE_TRUCK, TRANSPORT_MODES } from '../../../common/constants'


class TransportModeSelect extends React.Component {
  constructor(props) {
    super (props);

    this.state = {
      value: TRANSPORT_MODE_TRUCK
    }
  }

  componentDidMount() {
    if (this.props.transportMode)
      this.handleChange(this.props.value);
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value)
      this.handleChange(this.props.value || TRANSPORT_MODE_TRUCK);
  }

  handleChange = value => {
    this.setState(
      { value },
      () => {
        if(this.props.onChange) this.props.onChange(this.state.value, this.props.id)
      }
    );
  }

  render() {
    const { value } = this.state
    return (
      <FormControl className='mui-select' error={!!this.props.errorText} style={{width: '100%'}} variant="standard">
        <InputLabel htmlFor={this.props.id}>
          {this.props.label || 'Transport Mode'}
        </InputLabel>
        <Select
          id={this.props.id}
          value={value}
          onChange={event => this.handleChange(event.target.value)}
          style={this.props.style}
          disabled={this.props.disabled}
          inputProps={{
            ref: this.props.setRef,
          }}
          variant={this.props.variant || "standard"}
        >
          {
            TRANSPORT_MODES.map(mode => (
                <MenuItem key={mode} value={mode} selected={value === mode}>
                  {mode}
                </MenuItem>
            ))
          }
        </Select>
        {
          Boolean(this.props.disabled && value) && <i className="icon-lock"></i>
        }
        <FormHelperText>{this.props.errorText}</FormHelperText>
      </FormControl>
    );
  }
}

export default TransportModeSelect;

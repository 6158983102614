import React from "react";
import { get, isArray, isEqual, isEmpty, pick, find, startsWith, compact } from 'lodash';
import { VALUE_NOT_SELECTED } from '../../../common/validators';
import { Autocomplete, TextField, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { WARNING_ORANGE_CLASSES, PRIMARY_COLOR_GREEN } from '../../../common/constants';
import FarmIcon from '../icons/Farm';
import StockIcon from '../icons/Stocks';

const styles = () => ({
  ...WARNING_ORANGE_CLASSES,
});


class CommonAutoSelect extends React.Component {
  state = {
    single: null,
    autoSelectSingleItem: true,
  };

  componentDidMount() {
    if(this.props.value && !isEmpty(this.props.items)) {
      const selectedItem = find(this.props.items, {id: this.props.value});
      if(selectedItem) {
        this.setState({
          single: selectedItem,
        },() => {
          this.props.onChange(selectedItem.id, this.props.id, selectedItem);
        });
      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if((this.props.value && (isEmpty(prevState.single) || (this.props.value != prevProps.value))) || (!isEqual(this.props.items, prevProps.items))) {
      const itemFilterFields =  this.props.itemFilterFields ? this.props.itemFilterFields : ['id', 'name']
      const item = this.props.selectedItem ? find(this.props.items, pick(this.props.selectedItem, itemFilterFields)) : this.props.entity ? find(this.props.items, {id: this.props.value, entity: this.props.entity}) : find(this.props.items, {id: this.props.value});
      if(!isEmpty(item) && !isEqual(this.state.single, item)) {
        this.setState({
          single: item,
        },() => {
          this.props.onChange(item.id, this.props.id, item);
        });
      }
      if(this.state.single && (isEmpty(this.props.items) || (isEmpty(item) && prevProps.value))){
        this.setState({
          single: null,
        },() => {
          this.props.onChange(null, this.props.id, null);
        });
      }
    }
    if(!this.props.value && prevProps.value) this.setState({ single: '' });
    if(isEmpty(this.state.single) && prevProps.value == VALUE_NOT_SELECTED){
      this.props.onChange(null, this.props.id);
    }

    if (((this.props.items && this.props.items.length === 0) || !isEqual(this.props.items, prevProps.items)) && !this.state.autoSelectSingleItem && !this.props.dontAutoselectSingleItem)
      this.setState({autoSelectSingleItem: true});

    if(this.props.items && this.props.items.length === 1 && this.state.autoSelectSingleItem && !this.props.dontAutoselectSingleItem){
      this.setState({
        single: this.props.items[0],
        autoSelectSingleItem: false,
      },() => {
        this.props.onChange(this.props.items[0].id, this.props.id, this.props.items[0]);
      });
    }

  }

  handleChange = (event, value) => {
    this.setState({
      single: value,
    }, () => {
      document.getElementById(this.props.id).blur();
      this.props.onChange(value ? value.id : null, this.props.id, value);
    });
  };

  renderStorageFieldOption = (params, option) => {
    return (
      <ListItem {...params} sx={{cursor: 'pointer'}}>
        <ListItemIcon sx={{minWidth: '40px'}}>
          {option.entity === 'storage' && <StockIcon fill={PRIMARY_COLOR_GREEN} title='Storage' /> }
          {option.entity === 'farm_field' && <FarmIcon fill={PRIMARY_COLOR_GREEN} title='Farm Field' /> }
        </ListItemIcon>
        <ListItemText primary={this.getOptionLabel(option)} />
      </ListItem>
    )
  }

  getOptionLabel = option => get(option, get(this.props.dataSourceConfig, 'text')) || option.label || option.name || option.value || option

  render() {
    const moreProps = {renderOption: this.props.storageFieldMix ? this.renderStorageFieldOption : undefined, getOptionLabel: this.getOptionLabel}
    const items = isArray(this.props.items) ? this.props.items.map(item => {
      if(this.props.dataSourceConfig) {
        return {...item, label: item[this.props.dataSourceConfig.text], id: item[this.props.dataSourceConfig.id] || item.id};
      }
      return {...item, label: get(item, 'name')};
    }) : [];

    return (
      <div style={{textAlign: 'left'}} className={(this.props.containerClass || '') + " relative-pos"} ref={this.props.setRef}>
        <Autocomplete
          fullWidth
          value={this.state.single || null}
          onChange={this.handleChange}
          options={compact(items) || []}
          onBlur={this.props.onBlur}
          id={this.props.id}
          disabled={Boolean(this.props.disabled)}
          style={this.props.style}
          disableClearable={Boolean(this.props.notClearable)}
          isOptionEqualToValue={(option, value) => option && !isEmpty(option) && value && !isEmpty(value) && get(option, 'id') === get(value, 'id')}
          size={this.props.size || "large"}
          filterOptions={this.props.filterOptions}
          renderInput={params => (
            <TextField
              {...params}
              fullWidth
              label={this.props.label}
                    error={!isEmpty(this.props.errorText)}
                    helperText={this.props.errorText || this.props.helperText}
                    placeholder={this.props.placeholder || this.props.label}
                    variant={this.props.variant || "standard"}
                    FormHelperTextProps={{
                      ...params.FormHelperTextProps,
                      classes: {
                        error: startsWith(this.props.errorText, 'Warning') ?
                               this.props.classes.warningColor :
                               null
                      }
                    }}
                    InputLabelProps={{
                      ...params.InputLabelProps,
                      className: startsWith(this.props.errorText, 'Warning') ?
                                 this.props.classes.warningColor :
                                 null
                    }}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        underline: startsWith(this.props.errorText, 'Warning') ?
                                   this.props.classes.warningUnderline :
                                   null
                      }
                    }}
                    inputProps={{
                      ...params.inputProps,
                      className: params.inputProps.className + ' black-text'
                    }}
            />
          )}
          {...moreProps}
        />
        {this.props.disabled && this.state.single ? <i className="icon-lock"></i>: ''}
      </div>
    );
  }
}

export default withStyles(styles)(CommonAutoSelect);

import { capitalize, get } from "lodash";
import axios from 'axios';
import alertifyjs from 'alertifyjs';
import { apiURL, forceStopLoader } from '../main';
export const RECEIVE_EMPLOYEES = 'RECEIVE_EMPLOYEES';
export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const EDIT_EMPLOYEE = 'EDIT_EMPLOYEE';
export const CANCEL_EDIT_EMPLOYEE = 'CANCEL_EDIT_EMPLOYEE';
export const CLICK_ADD_EMPLOYEE_BUTTON = 'CLICK_ADD_EMPLOYEE_BUTTON';
export const CLICK_EDIT_EMPLOYEE_ICON = 'CLICK_EDIT_EMPLOYEE_ICON';
export const ADD_EMPLOYEE_ERRORS = 'ADD_EMPLOYEE_ERRORS';
export const EDIT_EMPLOYEE_ERRORS = 'EDIT_EMPLOYEE_ERRORS';
export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const ARCHIVED_EMPLOYEE = 'ARCHIVED_EMPLOYEE';
export const ACTIVATED_EMPLOYEE = 'ACTIVATED_EMPLOYEE';
export const GET_PAGINATED_TEMPLATES_SUCCESS = 'GET_PAGINATED_TEMPLATES_SUCCESS';
export const GET_TEMPLATES_SUCCESS = 'GET_TEMPLATES_SUCCESS';
export const SET_RESPONSE_RECEIVED = 'SET_RESPONSE_RECEIVED';
import { getPageCache } from '../../common/utils';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version

export const receiveEmployees = items => ({
  type: RECEIVE_EMPLOYEES,
  items,
});

export const addEmployee = item => ({
  type: ADD_EMPLOYEE,
  item,
});

export const addEmployeeErrors = errors => ({
  type: ADD_EMPLOYEE_ERRORS,
  errors,
});

export const editEmployee = item => ({
  type: EDIT_EMPLOYEE,
  item,
});

export const editEmployeeErrors = errors => ({
  type: EDIT_EMPLOYEE_ERRORS,
  errors,
});

export const cancelEditEmployee = () => ({
  type: CANCEL_EDIT_EMPLOYEE,
});

export const clickAddEmployeeButton = () => ({
  type: CLICK_ADD_EMPLOYEE_BUTTON,
});

export const clickEditEmployeeIcon = index => ({
  type: CLICK_EDIT_EMPLOYEE_ICON,
  index,
});


export const deleteEmployeeAction = item => ({
  type: DELETE_EMPLOYEE,
  item,

});

export const activateEmployeeAction = item => ({
  type: ACTIVATED_EMPLOYEE,
  item,

});

export const receiveArchivedEmployees = items => ({
  type: ARCHIVED_EMPLOYEE,
  items,
});

export const getEmployeeTemplatesResponse = templates => ({
  type: GET_TEMPLATES_SUCCESS,
  templates
});

export const getPaginatedTemplatesResponse = paginatedTemplates => ({
  type: GET_PAGINATED_TEMPLATES_SUCCESS,
  paginatedTemplates
});

export const setResponseReceived = responseReceived => ({
  type: SET_RESPONSE_RECEIVED,
  responseReceived
})


export const getEmployeeTemplates = (url = '', _forceStopLoader = false, type) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };

    let URL = `${apiURL}/profiles/templates/${type}/`;
    if (url)
      URL = url
    const cache = getPageCache(`profiles/templates/${type}`);
    const cachedPageSize = get(cache, 'pageSize');
    const cachedPage = get(cache, 'page');
    let joiner = '?';
    if (URL.indexOf('?') > -1)
      joiner = '&';
    if (cachedPageSize) {
      URL += `${joiner}page_size=${cachedPageSize}`;
      joiner = '&';
    }
    if (cachedPage) {
      URL += `${joiner}page=${cachedPage}`;
      joiner = '&';
    }

    axios
      .get(URL, config)
      .then(response => {
        dispatch(getPaginatedTemplatesResponse(response.data));
        if (_forceStopLoader) {
          dispatch(forceStopLoader());
        }
      })
      .catch(error => {
        dispatch(getEmployeeTemplatesResponse(error.response ? error.response.data : error.message));
      });
  };
};

export const deleteEmployeeTemplates = (templateId, type, actionCreator) => (dispatch, getState) => {
  if (!templateId) return;

  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  };
  let service = `${apiURL}/profiles/templates/${type}/${templateId}/`;
  let URL = `${apiURL}/profiles/templates/${type}/`;
  alertifyjs.confirm(
    'Delete Template',
    `Are you sure you want to delete this ${type} template ?`,
    () => {
      axios
        .delete(service, config)
        .then(() => {
          alertifyjs.success(`${capitalize(type)} Template Deleted Successfully`, 2, () => {
          });
          dispatch(actionCreator(URL, true, type));
        });
    },
    () => { });
};

import {
  GET_INVOICES_SUCCESS, GET_INVOICE_PREVIEW_SUCCESS, ADD_INVOICE_FAILURE, INVOICE_CREATE_SUCCESS,
  GET_PAGINATED_INVOICES_SUCCESS, GET_INVOICE_DETAILS, GET_INVOICE_PAYMENTS_SUCCESS, INVOICE_PAYMENT_REJECT_DIALOG,
  GET_INVOICE_PAYMENT_SUMMARY, INVOICE_CONFIRMATION_SUCCESS, INVOICE_REJECTION_SUCCESS, SET_SELECTED_INVOICE_ID,
  CLEAR_INVOICE, UNSET_SELECTED_INVOICE_ID, TRIGGER_PDF_DOWNLOADING, VOID_REASON_DIALOG, CLICKED_OPTION, REMOVE_FROM_PAYMENT_RUN_DIALOG,
  INVOICE_PAYMENT_VOID_DIALOG, CAN_MARK_PAID, PAYMENT_RUN_ITEMS, SET_SELECTED_PAYMENT_RUN, CAN_DOWNLOAD_ABA_FOR_SELECTED_PAYMENT_RUN,
  SET_SELECTED_INVOICE_PAYMENT, PAYMENT_RUN_VOID_DIALOG, SELECTED_PAYMENT_RUN_ID, VOID_AND_DUPLICATE_REASON_DIALOG
} from '../../actions/companies/invoices';
import { merge, get } from 'lodash';

const initialState = {
  invoices: [],
  previewHtml: '',
  selectedInvoiceId: undefined,
  selectedInvoice: undefined,
  serverErrors: undefined,
  invoiceCreateResponse: undefined,
  invoicePaymentsPaginationData: undefined,
  invoicePayments: [],
  selectedInvoicePayment: undefined,
  isRejectInvoicePaymentDialogOpened: false,
  isVoidInvoicePaymentDialogOpened: false,
  canAddPayment: false,
  statusDisplayName: '',
  confirmedOrRejectedInvoice: undefined,
  isPdfDownloadable: false,
  invoiceId: undefined,
  flag: false,
  clickedOption: null,
  canMarkComplete: false,
  removeFromPaymentRunDialog: false,
  itemType: null
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAGINATED_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.paginatedInvoices.results,
        paginationData: action.paginatedInvoices,
        canAddPayment: false,
        canMarkComplete: false
      };
    case INVOICE_CREATE_SUCCESS:
      return {
        ...state,
        invoiceCreateResponse: action.invoice
      };
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.items
      };
    case GET_INVOICE_PREVIEW_SUCCESS:
      return {
        ...state,
        previewHtml: action.invoicePreview.previewHTML,
        selectedInvoiceId: action.invoicePreview.invoiceId
      };
    case ADD_INVOICE_FAILURE:
      return {
        ...state,
        serverErrors: action.errors
      };
    case GET_INVOICE_DETAILS:
      return {
        ...state,
        selectedInvoice: action.item,
        canAddPayment: get(action.item, 'canAddPayment'),
        statusDisplayName: get(action.item, 'statusDisplayName')
      };
    case GET_INVOICE_PAYMENT_SUMMARY:
      return {
        ...state,
        selectedInvoice: merge(state.selectedInvoice || {}, action.item),
        canAddPayment: get(action.item, 'canAddPayment'),
        statusDisplayName: get(action.item, 'statusDisplayName')
      };
    case GET_INVOICE_PAYMENTS_SUCCESS:
      return {
        ...state,
        invoicePayments: action.items,
      };
    case INVOICE_PAYMENT_REJECT_DIALOG:
      return {
        ...state,
        selectedInvoicePayment: action.item,
        isRejectInvoicePaymentDialogOpened: action.showHide
      };
    case INVOICE_CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmedOrRejectedInvoice: action.invoice,
        serverErrors: action.errors,
      };
    case INVOICE_REJECTION_SUCCESS:
      return {
        ...state,
        confirmedOrRejectedInvoice: action.invoice,
        serverErrors: action.errors,
      };
    case SET_SELECTED_INVOICE_ID:
      return {
        ...state,
        selectedInvoiceId: action.invoiceId,
      };
    case CLEAR_INVOICE:
      return {
        ...state,
        selectedInvoiceId: undefined,
        selectedInvoice: undefined,
        canAddPayment: false,
        canMarkComplete: false,
        isPdfDownloadable: false,
      };
    case UNSET_SELECTED_INVOICE_ID:
      return {
        ...state,
        selectedInvoiceId: undefined,
        canAddPayment: false,
        canMarkComplete: false,
        isPdfDownloadable: false,
      };
    case TRIGGER_PDF_DOWNLOADING:
      return {
        ...state,
        isPdfDownloadable: true,
      };
    case VOID_REASON_DIALOG:
      return {
        ...state,
        invoiceId: action.item,
        flag: action.flag,
      };
    case VOID_AND_DUPLICATE_REASON_DIALOG:
        return {
          ...state,
          invoiceId: action.item,
          canVoidAndDuplicateInvoice: action.flag,
        };
    case REMOVE_FROM_PAYMENT_RUN_DIALOG:
      return {
        ...state,
        itemType: action.itemType,
        removeFromPaymentRunDialog: action.flag
      };
    case CLICKED_OPTION:
      return {
        ...state,
        clickedOption: action.option
      };
    case INVOICE_PAYMENT_VOID_DIALOG:
      return {
        ...state,
        isVoidInvoicePaymentDialogOpened: action.showHide
      };
    case CAN_MARK_PAID:
      return {
        ...state,
        canMarkComplete: action.flag,
      };
    case PAYMENT_RUN_ITEMS:
      return {
        ...state,
        paymentRunItems: action.paymentRuns,
      };
    case SET_SELECTED_PAYMENT_RUN:
      return {
        ...state,
        selectedPaymentRun: action.paymentRun,
      };
    case CAN_DOWNLOAD_ABA_FOR_SELECTED_PAYMENT_RUN:
      return {
        ...state,
        canDownloadABA: action.flag,
      }
    case SET_SELECTED_INVOICE_PAYMENT:
      return {
        ...state,
        selectedInvoicePayment: action.item,
      }
    case PAYMENT_RUN_VOID_DIALOG:
      return {
        ...state,
        isPaymentRunVoidDialogOpen: action.showHide,
      }
    case SELECTED_PAYMENT_RUN_ID:
      return {
        ...state,
        selectedPaymentRunId: action.paymentRunId,
      }
    default:
      return {
        ...state
      };
  }
};

export default reducer;

import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import { addFarmFieldErrors, editFarmFieldErrors, receiveArchivedFarmFields, receiveFarmFields } from '../../actions/companies/farm-fields';
import isEmpty from 'lodash/isEmpty';
import { forceStopLoader } from '../main';

const MESSAGES = {
  CREATE_SUCCESS: 'Field was successfully added.',
  UPDATE_SUCCESS: 'Field details were successfully updated.',
};

export const getFarmFields = (farmId, actionCreator, queryString='') => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (farmId) {
    APIService.farms(farmId)
      .farm_fields()
      .appendToUrl(queryString)
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createFarmField = (farmId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .farm_fields()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addFarmFieldErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
      }
    });
};

export const updateFarmField = (farmId, farmFieldId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    .farm_fields(farmFieldId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editFarmFieldErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
      }
    });
};


export const deleteFarmField = item => dispatch => {
  alertifyjs.confirm(
    'Delete Farm Field',
    `Are you sure you want to delete this farm field, ${item.name}?`,
    () =>
    {
      APIService.farms(item.farmId)
        .farm_fields(item.id)
        .delete()
        .then(() => {
            alertifyjs.success('Farm Field Deleted', 1, () => {
              dispatch(getFarmFields(item.farmId, receiveFarmFields));
              dispatch(forceStopLoader());
           });
        });
    },
    () => {},
  );
};

export const activateFarmField = item => dispatch => {
  alertifyjs.confirm(
    'Activate Farm Field',
    `Are you sure you want to activate this farm field, ${item.name}?`,
    () =>
    {
      APIService.farms(item.farmId)
        .farm_fields(item.id)
        .appendToUrl('activate/')
        .put({'farm_field_id': item.id})
        .then(() => {
            alertifyjs.success('Farm Field Activated', 1, () => {
              dispatch(getFarmFields(item.farmId, receiveArchivedFarmFields, '?is_active=false'));
              dispatch(forceStopLoader());
           });
        });
    },
    () => {},
  );
};

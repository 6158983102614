import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '@mui/material';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import APIService from '../../services/APIService';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonButton from '../common/CommonButton';
import { get, isEmpty, set, omit, filter, map} from 'lodash';
import GenericTable from '../GenericTable';
import { getLoadReferences, getSpecColumns, loadReferencesDisplay } from '../stocks/utils';
import { isSystemCompany, isCompanyGrower, toDateTimeFormat } from '../../common/utils';
import { SYSTEM_COMPANY_IDS } from '../../common/constants';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import alertifyjs from 'alertifyjs';
import { required } from '../../common/validators';
import Notes from "../common/Notes";
import Tooltip from '../../common/Tooltip';
import Chip from '@mui/material/Chip';
import {
  Cancel as CancelIcon
} from '@mui/icons-material/';

class TitleTransferDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLoads: false,
      loads: [],
      isLoadingLoads: false,
      buyerNgrs: [],
      buyer: {
        ngrId: undefined,
      },
      varietyId: {
        value: null,
        validators: [],
        errors: []
      },
      note: {
        description: '',
        attachments: [],
        attachmentUrls: [],
        companyId: this.props.currentUser.companyId,
        errors : []
      },
    };
    this.isCashPriced = get(this.props.selectedTitleTransfer, 'isCashPriced')
    this.isEditAllowed = isSystemCompany() && !this.isCashPriced
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  isVarietyEditAllowed = () => isEmpty(get(this.props.selectedTitleTransfer, 'canolaLoadIds')) && this.props.selectedTitleTransfer?.status !== 'invoiced'

  isVarietyMandatory = () => {
    const site = this.props.selectedTitleTransfer?.site
    let result = false
    if(!SYSTEM_COMPANY_IDS.includes(site?.companyId) && site?.isVarietyMandatory) {
      const sellerCompany = this.props.selectedTitleTransfer?.seller
      const { isVarietyMandatory, loadTypeForVarietyMandatory, userTypeForVarietyMandatory } = site
      let isGrower = isCompanyGrower(sellerCompany)
      let isGrowerType = isGrower && userTypeForVarietyMandatory && ['growers'].some(type => userTypeForVarietyMandatory.includes(type))
      let isNonGrowerType = !isGrower && userTypeForVarietyMandatory && ['non_growers'].some(type => userTypeForVarietyMandatory.includes(type))
      let isLoadTypeForVarietyMandatory = loadTypeForVarietyMandatory && ['title_transfers_and_cash_outs'].some(type => (loadTypeForVarietyMandatory.includes(type)))
      result = isVarietyMandatory && isLoadTypeForVarietyMandatory && (isGrowerType || isNonGrowerType)
    }
    return result
  }

  setVarietyMandate = () => {
    const isMandatory = this.isVarietyMandatory()
    if(isMandatory && !this.state.varietyId.validators.length) {
      const newState = {...this.state}
      newState.varietyId.validators = [required()]
      this.setState(newState)
    } else if(!isMandatory && this.state.varietyId.validators.length) {
      const newState = {...this.state}
      newState.varietyId.validators = []
      newState.varietyId.errors = []
      this.setState(newState)
    }
  };

  componentDidMount() {
    this.setState({varietyId: {...this.state.varietyId, value: this.props.selectedTitleTransfer?.varietyId || undefined}}, () => {
      if (this.isEditAllowed)
        APIService.companies(get(this.props, 'selectedTitleTransfer.buyer.companyId')).ngrs().appendToUrl('minimal/')
        .get().then(items => {
          const newState = { ...this.state };
          newState.buyer.ngrId = this.props.selectedTitleTransfer?.buyer?.ngrId
          newState.buyerNgrs = items
          newState.varietyId.value = this.props.selectedTitleTransfer?.varietyId || undefined
          this.setState(newState, this.setVarietyMandate);
        });
      else
        this.setVarietyMandate()
    })
    if (get(this.props, 'selectedTitleTransfer.note')) {
      const newState = { ...this.state };
      newState.note.description = this.props.selectedTitleTransfer.note.description || ''
      newState.note.attachments = this.props.selectedTitleTransfer.note.attachments || []
      newState.note.attachmentUrls = this.props.selectedTitleTransfer.note.attachmentUrls || []
      this.setState(newState);
    }
  }

  handleVarietyChange = id => this.setState({varietyId: {...this.state.varietyId, value: id, errors: id ? [] : this.state.varietyId.errors}})

  toggleLoads = () => {
    const open = !this.state.openLoads;
    if(open)
      this.fetchLoads();
    else
      this.setState({openLoads: open, loads: []});
  };

  fetchLoads = () => {
    const { selectedTitleTransfer } = this.props;
    if(selectedTitleTransfer) {
      this.setState(
        {isLoadingLoads: true, loads: []},
        () => APIService.contracts().appendToUrl(`title-transfer/${selectedTitleTransfer.id}/loads/`).get().then(loads => this.setState({isLoadingLoads: false, loads: loads, openLoads: true}))
      );
    }
  };

  getColumns = commodity => {
    const { selectedTitleTransfer } = this.props;
    const specColumns = commodity ? getSpecColumns(commodity, 'specs') : [];
    return [
      {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
      { key: 'season', header: 'Season' },
      { key: 'varietyName', header: 'Variety' },
      { key: 'gradeName', header: 'Grade' },
      ...specColumns,
      { key: 'remainingTonnage', header: 'Remaining Tonnage' },
      {
        header: 'Transfer Tonnage',
        formatter: load => <span>
                             {
                               parseFloat(parseFloat(get(selectedTitleTransfer.canolaLoadIds, load.id) || 0).toFixed(2))
                             }
                           </span>
      },
    ];
  };

  handleChange(value, id) {
    const newState = { ...this.state };
    set(newState, id, value);
    this.setState(newState);
  }

  getService = () => APIService
    .contracts()
    .appendToUrl(`title-transfer/${get(this.props, 'selectedTitleTransfer.id')}/`)

  setAllFieldsErrors = () => {
    if(!this.state.varietyId.value && this.state.varietyId.validators.length)
      this.setState({varietyId: {...this.state.varietyId, errors: ['This field can not be blank']}})
    else
      this.setState({varietyId: {...this.state.varietyId, errors: []}})
  }


  handleSubmit() {
    this.setAllFieldsErrors()

    if ((this.state.varietyId.value || !this.state.varietyId.validators.length) && (this.isEditAllowed || this.isVarietyEditAllowed()))
      this.getService().put(this.getPayload()).then(() => {
        alertifyjs.success('Title Transfer updated. Reloading...', 2, () => {
          this.props.onClose(true)
        });
      });
    else
      alertifyjs.alert('Please fill information for update!');
  }

  getPayload = () => isSystemCompany() ? { buyer: { ngrId: this.state.buyer.ngrId || null }, varietyId: this.state.varietyId.value || null, note: omit(this.state.note, ['errors', 'attachmentUrls']) } : { varietyId: this.state.varietyId.value || null, note: omit(this.state.note, ['errors', 'attachmentUrls']) }

  noteHandler = val => this.setState({note: val});

  removeAttachment = (name) => {
    let updatedNote = this.state.note
    updatedNote.attachments = filter(this.state.note.attachments, attachment => attachment.name != name)
    updatedNote.attachmentUrls = filter(this.state.note.attachmentUrls, attachmentUrl => attachmentUrl.name != name)
    this.setState({note: updatedNote})
  }

  handleClickAttachment = (url) => window.open(url, '_blank');

  imageTemplate = (attachments) => {
    const canEditComment = this.isVarietyEditAllowed()
    return (
      map(attachments, attachment => {
        return (
          <span key={attachment.url} className="field-value"   style={{paddingLeft: '10px', paddingRight: '10px'}}>
            <Chip style={{cursor: 'pointer', margin: '10px 0'}} label={attachment.name} variant="outlined" onClick={() => this.handleClickAttachment(attachment.url)} deleteIcon={canEditComment ? <CancelIcon fontSize='inherit' /> : null}  onDelete={canEditComment ? () => this.removeAttachment(attachment.name) : undefined}/>
          </span>
        );
      })
    );
  };

  render() {
    const showLoads = !isEmpty(get(this.props.selectedTitleTransfer, 'canolaLoadIds'));
    const { openLoads, loads } = this.state;
    const isVarietyEditable = this.isVarietyEditAllowed()
    const renderCompanyInfoRow = (label, value) => (
      <div className="row padding-reset" style={{marginTop: '15px'}}>
        <div className="col-sm-6 payment-summary" align="left">
          {label}
        </div>
        <div className="col-sm-6" align="right">
          {value}
        </div>
      </div>
    );
    return (
      <div id='title-transfer-side-form' className='col-xs-12 padding-reset'>
        <div className='cardForm cardForm--drawer col-xs-12 padding-reset' style={ openLoads ? {} : {display: 'none'}}>
          <div className='col-xs-12 no-side-padding' onClick={this.toggleLoads} style={{margin: '10px 0'}}>
            <Button color='primary' size='small' variant='outlined'>
              <BackIcon fontSize='inherit' style={{marginRight: '5px'}} />
              Back
            </Button>
          </div>
          {
            openLoads &&
            <GenericTable
              items={loads}
              columns={this.getColumns(get(loads, '0.commodity'))}
              optionsItems={[]}
            />
          }
        </div>
        <form noValidate style={openLoads ? {display: 'none'} : {marginTop: '15px'}}>
          <div className='cardForm cardForm--drawer col-xs-12 padding-reset'>
            <div className='cardForm-content row col-xs-12 padding-reset'>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id='identifier'
                  label='Title Transfer No.'
                  value={get(this.props, 'selectedTitleTransfer.identifier')}
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField id='contractNumber' label='Contract Number' value={get(this.props, 'selectedTitleTransfer.contractNumber')} disabled />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField id='sellerCompanyId' label='Seller Company' value={get(this.props, 'selectedTitleTransfer.seller.companyName')} disabled />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField id='buyerCompanyId' label='Buyer Company' value={get(this.props, 'selectedTitleTransfer.buyer.companyName')} disabled />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id="sellerContactId"
                  label="Seller Contact"
                  value={get(this.props, 'selectedTitleTransfer.seller.contactName')}
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id="buyerContactId"
                  label="Buyer Contact"
                  value={get(this.props, 'selectedTitleTransfer.buyer.contactName')}
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField id='seller.ngrId' label='Seller NGR' value={get(this.props, 'selectedTitleTransfer.seller.ngrNumber')} disabled />
              </div>
              {this.isEditAllowed ?
                <div className='col-xs-6 form-wrap'>
                  <CommonAutoSelect
                    id='buyer.ngrId'
                    label='Buyer NGR'
                    placeholder='Buyer NGR'
                    items={this.state.buyerNgrs}
                    fullWidth
                    onChange={this.handleChange}
                    value={get(this.state.buyer, 'ngrId')}
                    dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                  />
                </div> :
                <div className='col-xs-6 form-wrap'>
                  <CommonTextField id='buyer.ngrId' label='Buyer NGR' value={get(this.props, 'selectedTitleTransfer.buyer.ngrNumber')} disabled />
                </div>
              }
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id="commodityId"
                  label="Commodity"
                  value={get(this.props, 'selectedTitleTransfer.commodityName')}
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                {
                  isVarietyEditable ?
                    <VarietyAutoComplete
                      id="varietyId"
                      onChange={this.handleVarietyChange}
                      label={this.state.varietyId.validators.length ? "Variety" : "Variety (Optional)"}
                      commodityId={get(this.props, 'selectedTitleTransfer.commodityId')}
                      varietyId={this.state.varietyId.value}
                      errorText={get(this.state, 'varietyId.errors[0]', '')}
                      dependsOnCommodity
                    /> :
                  <CommonTextField
                    id='varietyId'
                    label='Variety (Optional)'
                    value={get(this.props, 'selectedTitleTransfer.varietyName')}
                    disabled
                  />
                }
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField id='gradeId' label='Grade' value={get(this.props, 'selectedTitleTransfer.gradeName')} disabled />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id="season"
                  label='season'
                  value={get(this.props, 'selectedTitleTransfer.season')}
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id='tonnage'
                  label='Tonnage'
                  placeholder='Tonnage'
                  value={get(this.props, 'selectedTitleTransfer.tonnageDisplayValue')}
                  fullWidth
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonDatePicker
                  id='processOn'
                  floatingLabelText='Transfer Date'
                  value={get(this.props, 'selectedTitleTransfer.processOn')}
                  disabled
                />
              </div>
              <div className='col-xs-6 form-wrap'>
                <CommonTextField
                  id='handlerId'
                  label='Site'
                  placeholder='Site'
                  fullWidth
                  value={get(this.props, 'selectedTitleTransfer.siteDisplayName')}
                  disabled
                />
              </div>

              {get(this.props, 'selectedTitleTransfer.commodityId') == 3 && (
                 <div className={'col-xs-6 form-wrap'}>
                   <CommonTextField
                     id='coil'
                     label={'COIL'}
                     value={get(this.props, 'selectedTitleTransfer.coil')}
                     disabled
                   />
                 </div>
              )}
              {get(this.props, 'selectedTitleTransfer.commodityId') == 3 && (
                 <div className={'col-xs-6 form-wrap'}>
                   <CommonTextField
                     id='impu'
                     label={'IMPU'}
                     value={get(this.props, 'selectedTitleTransfer.impu')}
                     disabled
                   />
                 </div>
              )}
              {get(this.props, 'selectedTitleTransfer.pricePerMt') && (
                 <div className={'col-xs-6 form-wrap'}>
                   <CommonTextField
                     id='price_per_mt'
                     label={'Price per MT'}
                     value={get(this.props, 'selectedTitleTransfer.pricePerMt')}
                     disabled
                   />
                 </div>
              )}
              {get(this.props, 'selectedTitleTransfer.paymentTerm') && (
                 <div className={'col-xs-6 form-wrap'}>
                   <CommonTextField
                     id='payment_term'
                     label={'Payment Term'}
                     value={get(this.props, 'selectedTitleTransfer.paymentTerm')}
                     disabled
                   />
                 </div>
              )}
              {get(this.props, 'selectedTitleTransfer.saleValue') > 0 && (
                 <div className={'col-xs-6 form-wrap'}>
                   <CommonTextField
                     id='sale_value'
                     label={'Sale Value'}
                     value={get(this.props, 'selectedTitleTransfer.saleValue')}
                     disabled
                   />
                 </div>
              )}
                <div className="col-xs-6 form-wrap">
                  <CommonTextField
                    id="referenceNumber"
                    label="Reference Number"
                    value={this.props?.selectedTitleTransfer?.referenceNumber || ''}
                    disabled
                  />
                  {renderCompanyInfoRow('Created On', get(this.props, 'selectedTitleTransfer.createdAt') ? toDateTimeFormat(get(this.props, 'selectedTitleTransfer.createdAt')) : '')}
                  {renderCompanyInfoRow('Created By', get(this.props, 'selectedTitleTransfer.createdBy'))}
                  {renderCompanyInfoRow('Created By Company', get(this.props, 'selectedTitleTransfer.createdByCompany'))}
                </div>
                
              <div className='col-xs-6 form-wrap'>
                <Notes
                  title='Comments'
                  handler={this.noteHandler}
                  note={this.state.note}
                  disabled={!isVarietyEditable}
                />
                {
                  this.state.note &&
                  <Tooltip
                    className="field-label list"
                    textContent={this.imageTemplate(get(this.state.note, 'attachmentUrls'))}
                  />
                }
              </div>
              {get(this.props, 'selectedTitleTransfer.rejectionReason') &&
               <div className={'col-xs-12 form-wrap'}>
                 <CommonTextField
                   id='reason'
                   label={'Void Reason'}
                   value={get(this.props, 'selectedTitleTransfer.rejectionReason')}
                   disabled
                 />
               </div>}
            </div>
            <div className='col-xs-12 cardForm-action top15 padding-reset'>
              <CommonButton type='button' variant='outlined' label='Close' default onClick={this.props.onClose} />
              {
                showLoads && !openLoads &&
                <CommonButton primary variant="contained" label='Show Loads' onClick={this.toggleLoads} />
              }
              {
                (this.isEditAllowed || isVarietyEditable) &&
                <CommonButton primary variant='raised' label='Save' onClick={this.handleSubmit} />
              }
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    serverErrors: state.companies.contracts.titleTransferErrors,
    varieties: state.master.varieties.items || [],
    currentUser: state.main.user.user,
    allCompanyParties: state.companies.companies.companyParties,
    selectedTitleTransfer: state.companies.contracts.selectedTitleTransfer,
  };
};

export default connect(mapStateToProps)(TitleTransferDetails);

import React from 'react';

import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import { getCountryLabel } from '../../common/utils';
import { getLoadsWithBalance, numberFormaterByLoadType } from '../stocks/utils';
import GenericTable from '../GenericTable';
import Paper from '@mui/material/Paper';

class InvoiceLoadsTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSideDrawer: false,
            invoice: null,
            loads: [],
        };
        this.fetchLoads = this.fetchLoads.bind(this);
        this.handleDefaultCellClick = this.handleDefaultCellClick.bind(this);
    }

    handleDefaultCellClick = load =>  {
        if(load.freightMovementId)
          window.open(`#/freights/movements/${load.freightMovementId}/details`);
    };

    componentDidMount() {
        this.fetchLoads();
    }

    fetchLoads() {
        const { invoice } = this.props;
        APIService.invoices(invoice.id).appendToUrl('loads/')
                  .get().then(data => this.setState({isLoaded: true, loads: data || []}));
    }

    render() {
        const netWeightFormatter = load => numberFormaterByLoadType(load, 'tonnage');
        const { loads } = this.state;
        const COLUMNS = [
            {header: 'Date & Time', key: 'date'},
            {header: 'Contract', key: 'contractNumber'},
            {header: 'Movement / Title Transfer', className: 'medium', default: item => item.movementNumber || item.titleTransferNumber, sortable: false},
            {header: 'Freight Provider', key: 'freightProvider', className: 'small'},
            {header: getCountryLabel('rego'), key: 'rego'},
            {header: 'Season', key: 'season', className: 'xsmall'},
            {header: 'NGR', key: 'ngr', className: 'medium'},
            {header: 'Variety', key: 'varietyName'},
            {header: 'Grade', key: 'gradeName', className: 'xsmall'},
            {header: 'Tonnage', formatter: netWeightFormatter, className: 'medium'},
            {header: 'Unshrunk Tonnage', key: 'unshrunkTonnage', className: 'small'},
        ];
        return (
            <Paper className='paper-table'>
                <div style={{ position: 'relative' }}>
                        <GenericTable
                            columns={COLUMNS}
                            items={getLoadsWithBalance(loads)}
                            handleDefaultCellClick={this.handleDefaultCellClick}
                            orderBy="dateTime"
                            order="desc"
                        />
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        breadcrumbs: state.main.breadcrumbs,
        invoice: state.companies.invoices.selectedInvoice,
    };
};

export default connect(mapStateToProps)(InvoiceLoadsTable);

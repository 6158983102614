export const RECEIVE_OUTLOADS = 'RECEIVE_OUTLOADS';
export const ADD_OUTLOAD = 'ADD_OUTLOAD';
export const EDIT_OUTLOAD = 'EDIT_OUTLOAD';

export const receiveOutloads = items => ({
  type: RECEIVE_OUTLOADS,
  items,
});

export const addOutload = item => ({
  type: ADD_OUTLOAD,
  item,
});

export const editOutload = item => ({
  type: EDIT_OUTLOAD,
  item,
});

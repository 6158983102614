import alertifyjs from 'alertifyjs';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { GROWER } from '../common/constants';
import { markRegister, inviteCompanyAdmin, purgeCompany, activateCompany, unregister, showPopUpDailyReports } from '../actions/companies';
import { login } from '../actions/main/';
import { getCompaniesOfGroup, getCompanyCompaniesWeb, getPaginatedCompaniesResponse } from '../actions/api/companies';
import {
  currentUserCompany, isSystemCompany, currentUserToken, showTargetMoistureTab
} from '../common/utils';
import APIService from '../services/APIService';
import includes from 'lodash/includes';

const mapDispatchToProps = (dispatch, ownProps) => {
  const {isAllCompanies, groupIdSelected, isGroups} = ownProps;
  return {
    handleDefaultCellClick: company => {
      document.location.hash = `#/companies/${company.id}/details`;
    },
    handleOptionClick: (rowNum, key, companyId, company) => {
      const keyMap = {
        details: 'details',
        employees: 'employees',
        farms: 'farms',
        'farm-fields': 'fields',
        storages: 'storages',
        trucks: 'trucks',
        ngrs: 'ngrs',
        'company-sites': 'sites',
        shrinkages: 'shrinkages',
        fees: 'fees',
        merge: 'merge',
        integrations: 'integrations'
      };
      if (key === 'invite') {
        dispatch(inviteCompanyAdmin(company.id));
      } else if (key === 'register') {
        alertifyjs.confirm(
          'Warning',
          'This action cannot be reversed. Do you wish to continue?',
          () => {
            dispatch(markRegister(company.id));
          },
          () => { },
        );
      } else if (key === 'unregister') {
        alertifyjs.confirm(
          'Warning',
          'This action cannot be reversed. Do you wish to continue?',
          () => {
            dispatch(unregister(company.id));
          },
          () => { },
        );
      } else if (key === 'activate') {
        alertifyjs.confirm(
          'Warning',
          'This action cannot be reversed. Do you wish to continue?',
          () => {
            dispatch(activateCompany(company.id));
          },
          () => { },
        );
      } else if (key === 'purge') {
        alertifyjs.confirm(
          'Warning',
          'This action cannot be reversed. Should you choose to Accept?',
          () => {
            dispatch(purgeCompany(company.id));
          },
          () => { },
        );
      } else if(key ==='daily_reports'){
        dispatch(showPopUpDailyReports(company, true));
      } else if(key === 'loginAs' && companyId) {
        APIService.profiles().appendToUrl('observers/').post({companyId: companyId}).then(res => {
          if(res.success) {
            localStorage.setItem('beforeSwitchToken', currentUserToken())
            alertifyjs.success(`Signing in as ${company.name}`);
            localStorage.setItem('stocks', JSON.stringify({}));
            dispatch(
              login(
                {username: `aobserver_${companyId}`, password: 'Pass@123!'},
                null, null, dispatch
              )
            );
          }
        });
      } else if (key === 'merge'){
        document.location.hash = `#/companies/${companyId}/merge`;
      } else if (key === 'target-moisture') {
        document.location.hash = `#/companies/${companyId}/target-moisture`;
      } else {
        document.location.hash = `#/companies/${companyId}/${key}?${keyMap[key]}`;
      }
    },
    navigateTo: url => {
      if (isAllCompanies)
        dispatch(getCompanyCompaniesWeb(null, url, true));
      else
        dispatch(getCompanyCompaniesWeb(null, url, true, true, true))
      if (groupIdSelected)
        dispatch(getCompaniesOfGroup(currentUserCompany().id, groupIdSelected, url, true))
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')) {
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      if (isAllCompanies)
        dispatch(getCompanyCompaniesWeb(null, url, true));
      else
        dispatch(getCompanyCompaniesWeb(null, url, true, true, true))
      if (groupIdSelected)
        dispatch(getCompaniesOfGroup(currentUserCompany().id, groupIdSelected, url, true))
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const companyService = APIService.companies(currentUserCompany().id);
      groupIdSelected ? companyService.appendToUrl(`groups/${groupIdSelected}/companies/web/search/?page_size=${pageSize}`) : companyService.appendToUrl(`companies/web/search/?is_groups=${isGroups}&ungrouped=${!isAllCompanies}&page_size=${pageSize}`);
      if (page) {
        companyService.appendToUrl(`&page=${page}`);
      }
      if (searchText) {
        companyService.appendToUrl(`&search=${searchText}`);
      }
      if (orderBy) {
        companyService.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }

      return companyService.URL;
    },
  };
};

const mapStateToProps = () => {
  const farmOption = { key: 'farms', text: 'Farms' };
  return {
    order: 'asc',
    orderBy: 'name',
    clearSearch: getPaginatedCompaniesResponse,
    globalSearch: true,
    rowHighlightedMap: {
      canRegister: 'delhi-blue',
    },
    optionsItems: item => {
      let adminOption = [
        { key: 'purge', text: 'Purge' },
        { key: 'loginAs', text: 'Login'},
      ];
      let unregisterOption = { key: 'unregister', text: 'Unregister' };
      let registerOption = [
        { key: 'invite', text: 'Invite Company Admin' },
        { key: 'register', text: 'Register Silently (Resets Password)' },
        { key: 'activate', text: 'Activate' },
      ];
      let options = [
        { key: 'details', text: 'Details' },
        { key: 'employees', text: 'Employees' },
        { key: 'ngrs', text: 'NGRs' },
        { key: 'trucks', text: 'Trucks' },
        { key: 'company-sites', text: 'Sites' },
        { key: 'shrinkages', text: 'Shrinkages' },
        { key: 'fees', text: 'Fees' }
      ];
      if (showTargetMoistureTab()) {
        options.splice(6, 1);
        options.push({ key: 'target-moisture', text: 'Target Moisture' })
      }

      const isSystemAdmin = isSystemCompany()

      if (isSystemAdmin)
        options.push({ key: 'merge', text: 'Merge Into' });

      if (item && item.typeId === GROWER) {
        options = options.filter(option => option.key !== 'company-sites');
        options.splice(1, 0, farmOption);
      }
      if (item.canRegister) options = registerOption.concat(options);
      if (isSystemAdmin && item.isRegisteredText === 'Yes')
        options = [...options, unregisterOption];

      if (isSystemAdmin)
        options = options.concat(adminOption);
      if(isSystemAdmin)
        options = options.concat([{key: 'integrations', text: 'Integrations'}])

      return options;
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(GenericTable);

import { connect } from 'react-redux';
import OutloadForm from '../components/outloads/OutloadForm';
import { updateOutload } from '../actions/api/outloads';

function submit(loadId, data, successCallback) {
  return (dispatch) => {
    dispatch(updateOutload(loadId, data, successCallback));
  };
}

export default connect(null, { submit })(OutloadForm);

import findIndex from 'lodash/findIndex';
import forEach from 'lodash/forEach';
import {
  RECEIVE_OUTLOADS,
  ADD_OUTLOAD,
  EDIT_OUTLOAD,
} from '../../actions/companies/outloads';
import { getDateTimeFromUTC } from '../../common/utils';

const initialState = {
  items: [],
  selectedOutloadId: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_OUTLOADS:
      forEach(action.items.outloads, (item) => {
        const localDateTime = getDateTimeFromUTC(item.dateTime);
        item.date = localDateTime.date;
        item.time = localDateTime.time;
      });
      return {
        ...state,
        items: action.items.outloads,
        storageTonnage: {
          storageId: action.items.storageId,
          totalTonnage:action.items.totalTonnage
        }
      };
    case ADD_OUTLOAD:
      return {
        ...state,
        items: [action.item, ...state.items],
      };
    case EDIT_OUTLOAD: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedOutloadId: undefined,
      };
    }
    default:
      return state;
  }
};

export default reducer;

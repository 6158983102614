import React from 'react';
import { connect } from 'react-redux';
import { Add as AddIcon } from '@mui/icons-material/';
import { Radio, FormControlLabel, RadioGroup, Button, FormHelperText } from '@mui/material/';
import {
  get, map, set, mapValues, includes, has, omit, isEmpty, find, reject, cloneDeep, some, every,
  isEqual, forEach,
} from 'lodash';
import { required } from '../../../common/validators';
import { REQUIRED_FIELD, FIELD } from '../../../common/constants';
import SideDrawer from '../../common/SideDrawer';
import CommonTextField from '../../common/CommonTextField';
import CommodityMultiSelect from '../../common/autocomplete/CommodityMultiSelect';
import FileUpload from '../../common/FileUpload';
import ChemicalForm from './ChemicalForm';
import ChemicalTable from './ChemicalTable';
import { chemicalFormPermissionPopup } from '../../../common/utils';

const YES = 'yes';
const NO = 'no';
const NA = 'na';

const COMMODITY_BASED_QUESTIONS = {
  adjacentCrops: 'List all known adjacent crops grown within 100 meters of this commodity (only applicable for single source commodities)',
  previousCropGrown: 'Name the crop previously grown in the same field/s prior to this current crop?',
  previousCommoditiesHeld: 'What was the previous commodity held in the relevant "on farm" storage bin?',
};

class ChemicalsInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chemicalForm: false,
      selectedChemical: null,
      fields: {
        chemicalDescription: {...cloneDeep(REQUIRED_FIELD), value: {}, errors: {}},
        chemicals: {...cloneDeep(FIELD), value: []},
        qaProgram: cloneDeep(FIELD),
        qaCertificateNumber: cloneDeep(FIELD),
        adjacentCrops: {...cloneDeep(FIELD), value: [], errors: []},
        previousCropGrown: {...cloneDeep(FIELD), value: [], errors: []},
        previousCommoditiesHeld: {...cloneDeep(FIELD), value: [], errors: []},
        chemicalResidueAnalysisDocuments: {...cloneDeep(FIELD), value: []},
        riskAssessmentDocuments: {...cloneDeep(FIELD), value: []},
      }
    };
    this.propagateChanges = this.propagateChanges.bind(this);
  }

  componentDidUpdate(prevProps) {
    const { validate, isSingleCommoditySource } = this.props;
    if(!prevProps.validate && validate)
      this.setAllFieldErrors();
    if(this.shouldSetDataFromTemplate(prevProps))
      this.setDataFromSelectedTemplate();
    if(prevProps.isSingleCommoditySource !== isSingleCommoditySource)
      this.setAdjacentCropsValidators();
  }

  shouldSetDataFromTemplate(prevProps) {
    const { selectedTemplate } = this.props;
    return selectedTemplate && !isEqual(prevProps.selectedTemplate, selectedTemplate);
  }

  setDataFromSelectedTemplate() {
    const { selectedTemplate } = this.props;
    const newState = { ...this.state };
    [
      'qaProgram', 'qaCertificateNumber', 'adjacentCrops', 'previousCommoditiesHeld',
      'previousCropGrown', 'chemicals',
    ].forEach(field => {
      newState.fields[field].value = selectedTemplate[field];
    });
    newState.fields.chemicalDescription.value = this.getFormattedQuestionsFromSelectedTemplate();
    this.setState(newState, () => {
      this.toggleChemicalPeriodTypeQuestion();
      this.toggleQAProgramDependents();
      this.propagateChanges();
    });
  }

  getFormattedQuestionsFromSelectedTemplate() {
    const { selectedTemplate, constants } = this.props;
    const questions = selectedTemplate.chemicalDescription || {};
    const result = {};
    let formattedQuestion = null;

    forEach(questions, (answer, question) => {
      if (question == "if the commodity is a byProduct, has a risk assessment been completed?") {
        question = "if the commodity is a by_product, has a risk assessment been completed?";
        formattedQuestion = find(
          constants.chemicalUseQuestions, q => q.toLowerCase() === question.toLowerCase()
        );
      }
      else {
        formattedQuestion = find(
          constants.chemicalUseQuestions, q => q.toLowerCase() === question.toLowerCase()
        );
      }
      result[formattedQuestion] = answer;
    });

    return result;
  }

  mandatoryQuestions() {
    const { constants } = this.props;
    let mandatory = [];
    if(!isEmpty(constants)) {
      mandatory = constants.chemicalUseQuestions.slice(0, 3);
      mandatory.push(constants.chemicalUseQuestions[4]);

      if(this.isTreatedWithPesticides())
        mandatory.push(constants.chemicalUseQuestions[3]);

      if(!this.isQAProgramDone()) {
        mandatory.push(constants.chemicalUseQuestions[5]);
        mandatory.push(constants.chemicalUseQuestions[6]);
      }
    }

    return mandatory;
  }

  isMandatoryQuestion(question) {
    return includes(this.mandatoryQuestions(), question);
  }

  isValidQuestionResponse(question) {
    if(includes(this.mandatoryQuestions(), question))
      return this.hasResponded(question);

    return true;
  }

  toggleChemicalForm = () => {
    this.setState({
      chemicalForm: !this.state.chemicalForm,
      selectedChemical: this.state.chemicalForm ? this.state.selectedChemical : null,
    });
  };

  onChange = (value, id) => {
    const newState = { ...this.state };
    set(newState.fields, `${id}.value`, value);
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  };

  transformToPrimitiveValue(value) {
    if(includes([NA, null], value))
      return null;
    if(includes([YES, true]), value)
      return true;

    return false;
  }

  transformToFormattedValue(value) {
    if(includes([null, NA]), value)
      return NA;
    if(includes([true, YES]), value)
      return YES;

    return NO;
  }

  onQuestionResponse = (event, question) => {
    const value = event.target.value;
    const newState = { ...this.state };
    newState.fields.chemicalDescription.value[question] = value === 'na' ? null : value === 'yes';;
    this.setState(newState, () => {
      this.toggleChemicalPeriodTypeQuestion();
      this.toggleQAProgramDependents();
      const { constants } = this.props;
      if(includes(
        [constants.chemicalUseQuestions[5], constants.chemicalUseQuestions[6], constants.chemicalUseQuestions[4]], question
      ))
        this.setAttachmentValidators();
      this.propagateChanges();
    });
  };

  setChemicalValidators = question => {
    let error = false;
    if (!this.isTreatedWithPesticides() && isEqual(question, get(this.props.constants, 'chemicalUseQuestions[2]'))){
      error = !isEmpty(this.state.fields.chemicals.value) ? 'Select "Yes" if commodity has been treated with chemicals': false;
    }
    return error && <FormHelperText style={{display: 'contents'}} error>{error}</FormHelperText>;
  };

  isChemicalDetailsRequired = () => {
    let error = false;
    if (this.isTreatedWithPesticides() && this.isChemicalHistoryKnown()){
      error = !isEmpty(this.state.fields.chemicals.value) ? false : "Mandatory";
    }
    return error && <FormHelperText style={{display: 'contents'}} error>{error}</FormHelperText>;
  };

  setAttachmentValidators() {
    const newState = {...this.state};
    if(this.isAnalysedForResidues() && !this.isQAProgramDone())
      newState.fields.chemicalResidueAnalysisDocuments.validators = [required()];
    else {
      newState.fields.chemicalResidueAnalysisDocuments.validators = [];
      newState.fields.chemicalResidueAnalysisDocuments.errors = [];
    }
    if(this.isRiskAssessmentDone() && !this.isQAProgramDone())
      newState.fields.riskAssessmentDocuments.validators = [required()];
    else {
      newState.fields.riskAssessmentDocuments.validators = [];
      newState.fields.riskAssessmentDocuments.errors = [];
    }
    this.setState(newState);
  }

  propagateChanges() {
    this.props.onChange(mapValues(this.state.fields, 'value'), {chemicalInfo: this.hasErrors()});
  }

  allMandatoryQuestionsAnswered() {
    const mandatoryQuestions = this.mandatoryQuestions();
    return every(
      mandatoryQuestions,
      question => this.hasResponded(question)
    );
  }

  setFieldError(field, callback) {
    const newState = {...this.state};
    const fieldState = get(newState.fields, field);
    fieldState.errors = this.getFieldErrors(field);
    this.setState(newState, () => {
      if(callback)
        callback();
    });
  }

  getFieldErrors(field) {
    let errors = [];
    if(field === 'chemicalDescription')
      return errors;
    const fieldState = get(this.state.fields, field);
    if(!fieldState)
      return errors;
    fieldState.validators.forEach(validator => {
      if(validator.isInvalid(fieldState.value))
        errors.push(validator.message);
      else
        errors = [];
    });

    return errors;
  }

  setAllFieldErrors() {
    const newState = {...this.state};
    forEach(newState.fields, (state, field) => {
      state.errors = this.getFieldErrors(field);
    });
    this.setState(newState, this.propagateChanges);
  }

  hasErrors() {
    const { fields } = this.state;
    return some(fields, field => field.errors.length > 0) || !this.allMandatoryQuestionsAnswered() ||
          some(this.setChemicalValidators(get(this.props.constants, 'chemicalUseQuestions[2]'))) ||
          some(this.isChemicalDetailsRequired());
  }

  hasResponded(question) {
    return has(this.state.fields.chemicalDescription.value, question);
  }

  getQuestionResponse(question) {
    if(this.hasResponded(question)) {
      const value = this.state.fields.chemicalDescription.value[question];
      if(value === null) return NA;
      if(value) return YES;
      return NO;
    }
    return '';
  }

  toggleChemicalPeriodTypeQuestion() {
    if(!this.isTreatedWithPesticides()) {
      const { constants } = this.props;
      const newState = { ...this.state };
      newState.fields.chemicalDescription.value = omit(newState.fields.chemicalDescription.value, constants.chemicalUseQuestions[3]);
      this.setState(newState, this.propagateChanges);
    }
  }

  setAdjacentCropsValidators() {
    const { isSingleCommoditySource } = this.props;
    const newState = {...this.state};
    if(!this.isQAProgramDone() && isSingleCommoditySource) {
      newState.fields.adjacentCrops.validators = [required()];
    } else {
      newState.fields.adjacentCrops.validators = [];
      newState.fields.adjacentCrops.errors = [];
    }

    this.setState(newState);
  }

  toggleQAProgramDependents() {
    const { constants, isSingleCommoditySource } = this.props;
    const newState = {...this.state};
    if(this.isQAProgramDone()) {
      newState.fields.adjacentCrops.value = [];
      newState.fields.previousCropGrown.value = [];
      newState.fields.previousCommoditiesHeld.value = [];
      newState.fields.adjacentCrops.validators = [];
      newState.fields.previousCropGrown.validators = [];
      newState.fields.previousCommoditiesHeld.validators = [];
      newState.fields.adjacentCrops.errors = [];
      newState.fields.previousCropGrown.errors = [];
      newState.fields.previousCommoditiesHeld.errors = [];
      newState.fields.chemicalDescription.value = omit(
        newState.fields.chemicalDescription.value,
        [constants.chemicalUseQuestions[5], constants.chemicalUseQuestions[6]]
      );
      newState.fields.riskAssessmentDocuments.value = [];
      newState.fields.chemicalResidueAnalysisDocuments.value = [];
      newState.fields.qaCertificateNumber.validators = [required()];
      newState.fields.qaProgram.validators = [required()];
    } else {
      newState.fields.qaCertificateNumber.errors = [];
      newState.fields.qaCertificateNumber.validators = [];
      newState.fields.qaProgram.errors = [];
      newState.fields.qaProgram.validators = [];
      newState.fields.qaCertificateNumber.value = undefined;
      newState.fields.qaProgram.value = undefined;
      if(isSingleCommoditySource)
        newState.fields.adjacentCrops.validators = [required()];
      else {
        newState.fields.adjacentCrops.validators = [];
        newState.fields.adjacentCrops.errors = [];
      }
      newState.fields.previousCropGrown.validators = [required()];
      newState.fields.previousCommoditiesHeld.validators = [required()];
    }

    this.setState(newState, this.propagateChanges);
  }

  getQuestionTemplate = (question, includeNA, yesLabel, noLabel="No", naLabel="N/A (see explanatory notes)") => {
    const value = this.getQuestionResponse(question);
    const quest = (question || '').replaceAll('_', '-');
    return (
      <div key={question} className='col-md-12'>
        <h4 style={{marginBottom: '0px', fontWeight: '500'}}>{quest}</h4>
        <div>
          <RadioGroup
            style={{display: 'inline-block'}}
            value={value}
            onChange={(event) => this.onQuestionResponse(event, question)}
            aria-label={question}
            name={question}
            id={question}>
            <FormControlLabel
              value={YES}
              control={<Radio color='primary' />}
              label={yesLabel || "Yes"}
              labelPlacement="end"
            />
            <FormControlLabel
              value={NO}
              control={<Radio />}
              label={noLabel}
              labelPlacement="end"
            />
            {
              includeNA &&
              <FormControlLabel
                value={NA}
                control={<Radio />}
                label={naLabel}
                labelPlacement="end"
              />
            }
          </RadioGroup>
          {
            !this.isValidQuestionResponse(question) &&
            <FormHelperText style={{display: 'contents'}} error>Mandatory</FormHelperText>
          }
          {
            this.setChemicalValidators(question)
          }
        </div>
      </div>
    );
  };

  onCommodityChange = (id, commodities) => {
    const newState = {...this.state};
    set(newState.fields, `${id}.value`, map(commodities, 'id'));
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  };

  getCommoditySelectorQuestionTemplate = field => {
    const question = COMMODITY_BASED_QUESTIONS[field];
    const error = get(this.state.fields, `${field}.errors.0`);
    let commodities = includes(['previousCropGrown', 'previousCommoditiesHeld'], field) ? [ { 'id': 0, 'displayName': 'N/A' }, ...this.props.commodities,] : this.props.commodities;
    return (
      <div key={question} className='col-md-12'>
        <h4 style={{marginBottom: '10px', fontWeight: '500'}}>{question}</h4>
        <div>
          <CommodityMultiSelect
            id={field}
            commodities={commodities}
            selectedCommodities={get(this.state.fields, `${field}.value`)}
            onChange={this.onCommodityChange}
          />
          {
            error &&
            <FormHelperText style={{display: 'contents'}} error>{ error }</FormHelperText>
          }
        </div>
      </div>
    );
  };

  isChemicalHistoryKnown() {
    const { constants } = this.props;
    return get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[0]')) &&
      this.isTreatedWithPesticides();
  }

  isTreatedWithPesticides() {
    const { constants } = this.props;
    return get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[2]'));
  }

  isQAProgramDone() {
    const { constants } = this.props;
    return get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[4]'));
  }

  isRiskAssessmentDone() {
    const { constants } = this.props;
    return get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[5]'));
  }

  isAnalysedForResidues() {
    const { constants } = this.props;
    return get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[6]'));
  }

  onAddChemical = data => {
    const newState = { ...this.state };

    if(find(newState.fields.chemicals.value, {id: data.id}))
      newState.fields.chemicals.value = map(
        newState.fields.chemicals.value,
        chemical => chemical.id === data.id ? data : chemical
      );
    else
      newState.fields.chemicals.value = [...newState.fields.chemicals.value, data];

    this.setState(newState, this.propagateChanges);
  };

  onChemicalRowDelete = (event, chemical) => {
    event.preventDefault();
    event.stopPropagation();

    const newState = {...this.state};
    newState.fields.chemicals.value = reject(newState.fields.chemicals.value, {id: chemical.id});
    this.setState(newState, this.propagateChanges);
  };

  onChemicalRowClick = chemical => {
    this.setState({selectedChemical: chemical, chemicalForm: true});
  };

  onFileUpload = (id, file) => {
    const newState = {...this.state};
    newState.fields[id].value.push(file.file);
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  };

  onFileRemove = (id, base64) => {
    const newState = {...this.state};
    newState.fields[id].value = reject(newState.fields[id].value, {base64: base64});
    this.setState(newState, () => {
      this.setFieldError(id, this.propagateChanges);
    });
  };

  render() {
    const { chemicalForm, fields, selectedChemical } = this.state;
    const { constants } = this.props;
    const isTreatedWithPesticides = this.isTreatedWithPesticides();
    const isQAProgramDone = this.isQAProgramDone();
    return (
      <div>
        <h4 className='cardForm-title'>Chemical Use</h4>
        <div className='cardForm-content'>
          {
            map(
              get(constants, 'chemicalUseQuestions', []).slice(0, 3),
              question => this.getQuestionTemplate(question)
            )
          }
          {
            isTreatedWithPesticides && this.getQuestionTemplate(constants.chemicalUseQuestions[3])
          }
          {
            constants &&
            <div className='col-md-12' style={{marginTop: '20px'}}>
              <Button id='add-chemical' variant='outlined' type='button' color='primary' onClick={this.toggleChemicalForm}>
                <AddIcon style={{paddingRight: '5px'}} /> Chemical
              </Button>
              <div style={{ display: 'inline-block', padding: '10px' }}>
                {this.isChemicalDetailsRequired()}
              </div>

              <SideDrawer isOpen={chemicalForm && isTreatedWithPesticides} title='Add Chemical Applied Details' onClose={this.toggleChemicalForm}>
                  <ChemicalForm
                  constants={constants}
                  onClose={this.toggleChemicalForm}
                  onSubmit={this.onAddChemical}
                  selectedChemical={selectedChemical}
                  />
              </SideDrawer>
            </div>
          }
          {
                chemicalForm && ! isTreatedWithPesticides ?
                chemicalFormPermissionPopup(this.toggleChemicalForm) :""
          }

          {
            !isEmpty(fields.chemicals.value) &&
            <div className='col-md-12' style={{marginTop: '10px'}}>
              <ChemicalTable
                chemicals={fields.chemicals.value}
                onRowClick={this.onChemicalRowClick}
                onRowDelete={this.onChemicalRowDelete}
              />
            </div>
          }
          { this.getQuestionTemplate(get(constants, 'chemicalUseQuestions[4]')) }
          {
            isQAProgramDone ?
            <div className="col-md-12">
              <div className="col-md-6 form-wrap no-left-padding">
                <CommonTextField
                  id='qaProgram'
                  label="Quality Assurance Program"
                  value={this.state.fields.qaProgram.value}
                  helperText={this.state.fields.qaProgram.errors[0]}
                  onChange={event => this.onChange(event.target.value, event.target.id)}
                />
              </div>
              <div className="col-md-6 form-wrap no-right-padding">
                <CommonTextField
                  id='qaCertificateNumber'
                  label="Accreditation/Certification Number	"
                  value={this.state.fields.qaCertificateNumber.value}
                  helperText={this.state.fields.qaCertificateNumber.errors[0]}
                  onChange={event => this.onChange(event.target.value, event.target.id)}
                />
              </div>
            </div> :
            <div className="col-md-12 padding-reset">
              {
                map(['adjacentCrops', 'previousCropGrown', 'previousCommoditiesHeld'], this.getCommoditySelectorQuestionTemplate)
              }
              {
                <div className='col-md-12 padding-reset'>
                  {
                    this.getQuestionTemplate(
                      get(constants, 'chemicalUseQuestions[5]'),
                      true,
                      'Yes (attach copy of risk assessment)',
                      "No, Risk Assessment has not been completed",
                      "N/A - Its not a by-product"
                    )
                  }
                  <div className='col-md-12 padding-reset' style={{marginTop: '-10px'}}>
                    <FileUpload
                      id="riskAssessmentDocuments"
                      floatingLabelText="Attach File"
                      fullWidth={true}
                      textFieldstyle={{color: 'black'}}
                      onChange={(file) => this.onFileUpload('riskAssessmentDocuments', file)}
                      buttonText="Attachment"
                      onRemove={(base64) => this.onFileRemove('riskAssessmentDocuments', base64)}
                      fileCount={1}
                      disabled={this.state.fields.riskAssessmentDocuments.value.length > 0 || !get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[5]'))}
                      previewContainerClass='col-md-10'
                      buttonContainerClass='col-md-2'
                      buttonStyle={{marginTop: '20px'}}
                      previewStyle={{marginLeft: '-60px', marginTop: '-5px'}}
                      buttonColor='primary'
                      showImage
                      icon={<AddIcon style={{paddingRight: '5px'}} />}
                    />
                    {
                      this.state.fields.riskAssessmentDocuments.errors[0] &&
                      <FormHelperText style={{marginTop: '65px', marginLeft: '17px'}} error>Mandatory</FormHelperText>
                    }
                  </div>
                </div>
              }
              {
                <div className='col-md-12 padding-reset'>
                  {
                    this.getQuestionTemplate(
                      get(constants, 'chemicalUseQuestions[6]'),
                      null,
                      'Yes (attach details of test results)'
                    )
                  }
                  <div className='col-md-12 padding-reset' style={{marginTop: '-10px'}}>
                    <FileUpload
                      id="chemicalResidueAnalysisDocuments"
                      floatingLabelText="Attach File"
                      fullWidth={true}
                      textFieldstyle={{color: 'black'}}
                      onChange={(file) => this.onFileUpload('chemicalResidueAnalysisDocuments', file)}
                      buttonText="Attachment"
                      onRemove={(base64) => this.onFileRemove('chemicalResidueAnalysisDocuments', base64)}
                      fileCount={1}
                      disabled={this.state.fields.chemicalResidueAnalysisDocuments.value.length > 0 || !get(this.state.fields.chemicalDescription.value, get(constants, 'chemicalUseQuestions[6]'))}
                      previewContainerClass='col-md-10'
                      buttonContainerClass='col-md-2'
                      buttonStyle={{marginTop: '20px'}}
                      previewStyle={{marginLeft: '-60px', marginTop: '-5px'}}
                      buttonColor='primary'
                      showImage
                      icon={<AddIcon style={{paddingRight: '5px'}} />}
                    />
                    {
                      this.state.fields.chemicalResidueAnalysisDocuments.errors[0] &&
                      <FormHelperText style={{marginTop: '65px', marginLeft: '17px'}} error>Mandatory</FormHelperText>
                    }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    commodities: state.master.commodities.items,
  };
};

export default connect(mapStateToProps)(ChemicalsInfo);

import React from 'react';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs } from '../../../actions/main';
import { getCountryLabel } from '../../../common/utils'
import '../actionCentre.scss';
import isNumber from 'lodash/isNumber';
import OrderVoidRequestsPendingGroup from "./OrderVoidRequestsPendingGroup";
import OrderAcceptancePendingGroup from "./OrderAcceptancePendingGroup";
import OrderAmendRequestPendingGroup from "./OrderAmendRequestPendingGroup";

class OrdersActionCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      orderItems1: 0,
      orderItems2: 0,
      orderItems3: 0,
    };

    this.onOrdersDataLoad1 = this.onOrdersDataLoad1.bind(this);
    this.onOrdersDataLoad2 = this.onOrdersDataLoad2.bind(this);
    this.onOrdersDataLoad3 = this.onOrdersDataLoad3.bind(this);
  }

  componentDidMount() {
    this.props.onMount();
    this.props.dispatch(setHeaderText(getCountryLabel('actionCentre')));
    this.props.dispatch(setBreadcrumbs([{text: getCountryLabel('actionCentre')}]));
  }

  componentDidUpdate() {
    this.props.onParentDataLoad(this.getMovementsItemSum());
  }


  onOrdersDataLoad1(count) {
    this.setState({orderItems1: isNumber(count) ? count : this.props.existingCount});
  }

  onOrdersDataLoad2(count) {
    this.setState({orderItems2: isNumber(count) ? count : this.props.existingCount});
  }

  onOrdersDataLoad3(count) {
    this.setState({orderItems3: isNumber(count) ? count : this.props.existingCount});
  }

  getMovementsItemSum() {
    const sum = this.state.orderItems1 + this.state.orderItems2 + this.state.orderItems3;
    return (sum || (sum === 0 && this.props.existingCount === 1)) ? sum : this.props.existingCount;
  }

  render() {
    return (
      <div className='action-centre-group-container'>
          {
            <div>
              <OrderAcceptancePendingGroup onDataLoad={this.onOrdersDataLoad1}/>
              <OrderAmendRequestPendingGroup onDataLoad={this.onOrdersDataLoad2}/>
              <OrderVoidRequestsPendingGroup onDataLoad={this.onOrdersDataLoad3}/>
            </div>
          }
      </div>
    );
  }
}

export default connect()(OrdersActionCentre);

export const STATUS_PENDING_PAYMENT = 'Pending Payment';
export const STATUS_AWAITING_PAYMENT = 'Awaiting Payment';
export const STATUS_AWAITING_PAYMENT_OVERDUE = 'Awaiting Payment (Overdue)';
export const STATUS_PENDING_PAYMENT_OVERDUE = 'Pending Payment (Overdue)';
export const STATUS_PAYMENT_OVERDUE = 'Payment Overdue';
export const STATUS_PENDING_PAYMENT_CONFIRMATION = 'Pending Payment Confirmation';
export const STATUS_AWAITING_PAYMENT_CONFIRMATION = 'Awaiting Payment Confirmation';
export const STATUS_PARTIALLY_PAID = 'Partially Paid';
export const STATUS_PARTIALLY_PAID_OVERDUE = 'Partially Paid (Overdue)';


export const PENDING_PAYMENT_STATUSES = [
    STATUS_PENDING_PAYMENT, STATUS_AWAITING_PAYMENT, STATUS_AWAITING_PAYMENT_OVERDUE, STATUS_PENDING_PAYMENT_OVERDUE,
    STATUS_PAYMENT_OVERDUE, STATUS_PENDING_PAYMENT_CONFIRMATION, STATUS_AWAITING_PAYMENT_CONFIRMATION,
    STATUS_PARTIALLY_PAID, STATUS_PARTIALLY_PAID_OVERDUE
];

import React from 'react';
import { connect } from 'react-redux';
import NestedOptionMenu from '../NestedOptionMenu';
import { getPaymentRunMenuOptions } from './utils';
import { handlePaymentRunsOptionsMapper } from '../../actions/companies/invoices';
import PaymentRunActions from './PaymentRunActions';
import PaymentRunAssignNGR from './PaymentRunAssignNGR'

class PaymentRunDetailsActions extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      openNGR: false,
    }
  }

  handleOptionClick = (event, item, baseEntity) => {
    if(item?.key === 'assign_payer_ngr')
      this.setState({openNGR: true})
    else
      this.props.dispatch(handlePaymentRunsOptionsMapper(event, item, baseEntity));
  };

  getOptions() {
    const { paymentRunDetails } = this.props;
    const options = getPaymentRunMenuOptions(paymentRunDetails);
    return options;
  };

  onClose = () => {
    this.setState({openNGR: false}, () => {
      if(this.props.onNGRFormClose)
        this.props.onNGRFormClose()
    })
  }

  render() {
    const { noMenu, assignNGR } = this.props
    const { openNGR } = this.state
    const open = openNGR || assignNGR
    return (
      <React.Fragment>
        <PaymentRunActions {...this.props} />
        {
          !noMenu &&
            <div className='status-actions'>
              <NestedOptionMenu
                optionsItems={this.getOptions()}
                item={this.props.paymentRunDetails}
                handleOptionClick={this.handleOptionClick}
                currentUser={this.props.currentUser}
                shouldOptionBeDisabled={false}
                useButton={true}
                useIconButton={false}
                buttonContainerStyle= {{ top: '100px', height: '90px' }}
              />
            </div>

        }
        {
          open &&
            <PaymentRunAssignNGR paymentRun={this.props.paymentRunDetails} isOpen={open} onClose={this.onClose} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    clickedOption: state.companies.invoices.clickedOption,
    canDownloadABA: state.companies.invoices.canDownloadABA,
  };
};

export default connect(mapStateToProps)(PaymentRunDetailsActions);

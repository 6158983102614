import React from "react";
import TextField from '@mui/material/TextField';
import { Autocomplete } from '@mui/material';
import { isNumber, has, get, find, merge, isEmpty, startsWith, isEqual, isArray } from 'lodash';
import { ListboxComponent } from '../ListboxComponent'

const AutoSelect = props => {
  let options = props.items || props.options || [];
  options = isArray(options) ? options : []
  let _value = props.value;
  if(_value && isNumber(_value)) {
    _value = find(options, o => has(o, 'value') ? o.value === _value : o.id === _value);
  }
  const _props = props.noListbox ? {} : {'ListboxComponent': ListboxComponent}
  return (
    <Autocomplete
      fullWidth
      blurOnSelect={get(props, 'blurOnSelect')}
      value={_value || null}
      onChange={(event, value) => props.onChange(get(value, 'id') || get(value, 'value') || value, value)}
      options={options}
      onBlur={props.onBlur}
      id={props.id}
      disabled={props.disabled}
      disableClearable={Boolean(props.notClearable)}
      getOptionLabel={option => {
        let label = option.label || option.name || get(option, get(props.dataSourceConfig, 'text')) || option
        if(label && props.suffix)
          label += ` (${props.suffix})`
        return label
      }}
      renderOption={(props, option) => {
        return (<li {...props}>{option.label || option.name || get(option, get(props.dataSourceConfig, 'text')) || option}</li>)
      }}
      isOptionEqualToValue={(option, value) => {
          if(option && !isEmpty(option) && value && !isEmpty(value)) {
            if(has(option, 'value') && has(value, 'value'))
              return option.value === value.value
            if(has(option, 'id') && has(value, 'id'))
              return option.id === value.id
            return isEqual(option, value)
          }
          return false
      }}
      style={merge({height: '68px !important', width: '100%'}, props.style || {})}
      filterOptions={get(props, 'filterOptions')}
      renderInput={params => (
        <TextField
          {...params}
          fullWidth
          label={props.label}
                error={!isEmpty(props.errorText)}
                helperText={props.errorText}
                placeholder={props.placeholder}
                variant={props.variant || "standard"}
                FormHelperTextProps={{
                  ...params.FormHelperTextProps,
                  classes: {
                    error: startsWith(props.errorText, 'Warning') ?
                           props.classes.warningColor :
                           null
                  }
                }}
                InputLabelProps={{
                  ...params.InputLabelProps,
                  className: startsWith(props.errorText, 'Warning') ?
                             props.classes.warningColor :
                             null
                }}
                InputProps={{
                  ...params.InputProps,
                  classes: {
                    underline: startsWith(props.errorText, 'Warning') ?
                               props.classes.warningUnderline :
                               null
                  },
                  endAdornment: (
                  <React.Fragment>
                    {
                      props.showLockIcon && <i style={{top: '5px'}} className="icon-lock"></i>
                    }
                  </React.Fragment>)
                }}
                inputProps={{
                  ...params.inputProps,
                  className: params.inputProps.className + ' black-text'
                }}
        />
      )}
    {..._props}
    />
  );};

export default AutoSelect;

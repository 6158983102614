import { apiURL, forceStopLoader } from '../main';
import alertifyjs from 'alertifyjs';
import axios from 'axios';
import APIService from '../../services/APIService';
import { addPageCacheQueryParamsToUrl } from '../../common/utils';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const GET_CONTRACT_BIDS_SUCCESS = 'GET_CONTRACT_BIDS_SUCCESS';
export const SELECTED_CONTRACT_BID = 'SELECTED_CONTRACT_BID';
export const EDIT_SELECTED_CONTRACT_BID = 'EDIT_SELECTED_CONTRACT_BID';
export const VIEW_SELECTED_CONTRACT_BID = 'VIEW_SELECTED_CONTRACT_BID';
export const DUPLICATE_SELECTED_CONTRACT_BID = 'DUPLICATE_SELECTED_CONTRACT_BID';
export const GET_COMPANY_CONTRACT_BIDS_SUCCESS = 'GET_COMPANY_CONTRACT_BIDS_SUCCESS';
export const SHOW_QUANTITY_DIALOG = 'SHOW_QUANTITY_DIALOG';

export const contractBidsResponse = items => ({
    type: GET_CONTRACT_BIDS_SUCCESS,
    items,
});

export const setSelectedContractBid = item => ({
    type: SELECTED_CONTRACT_BID,
    item
});

export const editSelectedContractBid = flag => ({
    type: EDIT_SELECTED_CONTRACT_BID,
    flag
});

export const viewSelectedContractBid = flag => ({
    type: VIEW_SELECTED_CONTRACT_BID,
    flag
});

export const duplicateSelectedContractBid = flag => ({
    type: DUPLICATE_SELECTED_CONTRACT_BID,
    flag
})

export const companyContractBidsResponse = items => ({
    type: GET_COMPANY_CONTRACT_BIDS_SUCCESS,
    items
});

export const showQuantityDialog = flag => ({
    type: SHOW_QUANTITY_DIALOG,
    flag
});

export const getCompanyContractBids = (_forceStopLoader = true, url, tab='active') => {
    return (dispatch, getState) => {
        const config = {
            headers: {
                'Content-type': 'application/json',
                accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}/companies/${getState().main.user.user.companyId}/contract_bids/${tab}/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];

        axios
            .get(URL, config)
            .then(response => {
                dispatch(companyContractBidsResponse(response.data));
                if (_forceStopLoader)
                    dispatch(forceStopLoader());
                if (window.location.hash.match(/\/contract-bids\/\d+/)) {
                    const contractBidId = window.location.hash.split('/').pop();
                    const contractBid = response.data.results.find(item => item.id === parseInt(contractBidId));
                    if (contractBid) {
                        dispatch(setSelectedContractBid(contractBid));
                        dispatch(viewSelectedContractBid(true));
                    } else 
                        dispatch(getContractBid(contractBidId));
                }
            })
            .catch(error => {
                dispatch(companyContractBidsResponse(error.response ? error.response.data : error.message));
            });
    };
};

export const getContractBid = (contractBidId, _forceStopLoader = true) => {
    return (dispatch) => {
        APIService.contract_bids(contractBidId).get().then(item => {
            if (item?.errors)
                alertifyjs.error(item.errors);
            else {
                dispatch(setSelectedContractBid(item));
                dispatch(viewSelectedContractBid(true));
            }
            if (_forceStopLoader)
                dispatch(forceStopLoader());
        });
    }
}

export const getContractBids = (_forceStopLoader = true, url, tab) => {
    return (dispatch, getState) => {
        const config = {
            headers: {
              'Content-type': 'application/json',
              accept: 'application/json',
              Authorization: `Token ${getState().main.user.token}`,
              'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
              'WEB-VERSION': VERSION,
              'REFERER-COUNTRY': localStorage.current_country
            },
        };
        let URL = `${apiURL}/contract_bids/`;
        if (url)
            URL = URL + '?' + url.split('?')[1];
        let joiner = '?';
        if(URL.indexOf('?') > -1)
            joiner = '&';
        URL = addPageCacheQueryParamsToUrl(URL, 'contract-bids')
        
        if (tab) {
            if(URL.indexOf('?') > -1)
                joiner = '&';
            URL = URL + `${joiner}tab=${tab}`;
        }
            
        axios
            .get(URL, config)
            .then(response => {
                dispatch(contractBidsResponse(response.data));
                if (_forceStopLoader)
                    dispatch(forceStopLoader());
            })
            .catch(error => {
                dispatch(contractBidsResponse(error.response ? error.response.data : error.message));
            });
    };
};

import React from 'react';

import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import merge from 'lodash/merge';
import startsWith from 'lodash/startsWith';
import { WARNING_ORANGE_CLASSES } from '../../common/constants';

const styles = () => ({
  ...WARNING_ORANGE_CLASSES,
});

class CommonTextField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: isNumber(props.value)|| (props.value===".") ? props.value : props.value || '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.value !== prevProps.value) {
      this.setState(state => ({
        ...state,
        value: isNumber(this.props.value) ? this.props.value : this.props.value || '',
      }));
    }
  }

  handleChange = event => {
    event.persist();
    const { onChange } = this.props;
    this.setState({value: event.target.value}, () => {
      if(onChange)
        onChange(event);
    });
  };

  render() {

    const { classes } = this.props;
    return (
      <div className={this.props.disabled && this.props.value ? "relative-pos text-black": "relative-pos"}>
      <TextField
        error={!isEmpty(this.props.helperText)}
        id={this.props.id}
        label={this.props.label}
        placeholder={this.props.placeholder}
        value={this.state.value}
        fullWidth
        helperText={this.props.helperText || this.props.additionalHelperText}
        name={this.props.name}
        onChange={this.handleChange}
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
        type={this.props.type || 'text'}
        multiline={this.props.multiline}
        style={this.props.style}
        rows={this.props.rows}
        inputRef={this.props.setRef}
        inputProps={this.props.inputProps}
        InputProps={merge({
          maxLength: this.props.maxLength,
          onInput: this.props.onInput,
          onKeyDown: this.props.onKeyDown,
          accept: this.props.accept,
          classes: {
            underline: (startsWith(this.props.helperText, 'Warning') || this.props.showWarningColorForWarningText) ? classes.warningUnderline : null,
          }
        }, this.props.InputProps || {})}
        className={this.props.className}
        InputLabelProps={ (startsWith(this.props.helperText, 'Warning') || this.props.showWarningColorForWarningText) ? {
          shrink: true,
          className: classes.warningColor,
        } : {}}
        FormHelperTextProps={{
          classes: {
            error: startsWith(this.props.helperText, 'Warning') ? classes.warningColor : null,
          },
          className: this.props.showWarningColorForWarningText ? classes.warningColor : ''
        }}
        variant={this.props.variant || "standard"}
        size={this.props.size || 'medium'}
      />
      {this.props.disabled && this.state.value ? <i className={this.props.lockIconClassName ? "side-drawer-icon-lock" : 'icon-lock'} style={this.props.lockIconStyle}></i> : ''}
      </div>
    );
  }
}

export default withStyles(styles)(CommonTextField);

import React from 'react';
import { map, reject, orderBy, forEach } from 'lodash';
import ChemicalApplicationForm from './ChemicalApplicationForm'
import AddButton from '../common/AddButton'

const ChemicalApplications = ({ commodities, applications, disabled, currency, unit, onChange  }) => {
  const [chemicalApplications, setChemicalApplications] = React.useState(map(applications || [], (application, index) => ({...application, position: index})));

  const onAdd = () => setChemicalApplications([...chemicalApplications, {position: chemicalApplications.length}])

  const onDelete = position => {
    let newApplications = reject([...chemicalApplications], {position: position})
    newApplications = map(orderBy(newApplications, 'position'), (application, index) => ({...application, position: index}))
    setChemicalApplications(newApplications)
    onChange(sanitize(newApplications))
  }

  const _onChange = application => {
    let newApplications = orderBy(chemicalApplications, 'position')
    newApplications[application.position] = application
    setChemicalApplications(newApplications)
    onChange(sanitize(newApplications))
  }

  const sanitize = applications => {
    const newApplications = []
    forEach(applications, application => {
      newApplications.push({commodityId: application.commodityId, price: parseFloat(application.price), fee: parseFloat(application.fee)})
    })
    return newApplications
  }

  return (
    <div className='col-xs-12 padding-reset'>
      <div className='col-xs-12 padding-reset'>
        {
          map(orderBy(chemicalApplications, 'position'), (application, index) => (
            <ChemicalApplicationForm
              commodities={commodities}
              style={index > 0 ? {marginTop: '20px'} : {}}
              key={index}
              disabled={disabled}
              currency={currency}
              unit={unit}
              onChange={_onChange}
              position={application.position}
              application={application}
              onDelete={onDelete}
            />
          ))
        }
      </div>
      <div className='col-xs-12 padding-reset' style={{marginTop: '30px'}}>
        <AddButton label='Application' onClick={onAdd} disabled={disabled} style={{float: 'left'}} />
      </div>
    </div>
  )
}

export default ChemicalApplications

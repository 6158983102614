import {
  RECEIVE_BHC_COMPANY_LOCATIONS,
} from '../../actions/master/bhc-company-locations';

const initialState = {
  items: [],
  isBHCFetched: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_BHC_COMPANY_LOCATIONS:
      return { ...state, items: action.items, isBHCFetched: true };
    default:
      return state;
  }
};

export default reducer;

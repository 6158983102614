import React, { Component } from 'react';
import { connect } from 'react-redux';
import AutoComplete from '../common/autocomplete/AutoComplete';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { required, minLength, maxLength, fixLength, maxValue, numRegex, valueEqual } from '../../common/validators';
import { forEach, isEmpty, some, find, isNumber, get, set, isEqual, uniqBy, filter, without } from 'lodash'
import { apiURL, isLoading, forceStopLoader } from '../../actions/main';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonTextField from '../common/CommonTextField';
import CommonButton from '../common/CommonButton';
import { PRIMARY_COLOR_GREEN, BSB_MISMATCH_ERROR } from '../../common/constants';
import { getCountryLabel, getCountryConfig } from '../../common/utils';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";

class CompanyBankAccountForm extends Component {
  constructor(props) {
    super(props);
    this.config = getCountryConfig()
    this.state = {
      nameSearchText: '',
      isConfirmDialogOpen: false,
      bankCode: '',
      bsbNumbers: [],
      fields: {
        name: {
          value: '',
          validators: [],
          errors: []
        },
        bsbNumber: {
          value: '',
          validators: [numRegex(), fixLength(get(this.config,'bank.bsbLength'))],
          errors: []
        },
        accountName: {
          value: '',
          validators: [],
          errors: []
        },
        accountNumber: {
          value: '',
          validators: [numRegex(), minLength(get(this.config, 'bank.accountNumberMinimumLength')), maxLength(get(this.config, 'bank.accountNumberMaximumLength'))],
          errors: []
        },
        companyId: {
          value: '',
          validators: [],
          errors: []
        },
        shareholderPercent: {
          value: props.ngrType === 'shared' ? '' : 100,
          validators: [],
          errors: []
        },
        isPrimary: {
          value: false,
          validators: [],
          errors: [],
        }
      }
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.onAbnBlur = this.onAbnBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedNgr = this.setAllFieldsValuesBySelectedNgr.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.onNewRequestName = this.onNewRequestName.bind(this);
    this.onUpdateInputName = this.onUpdateInputName.bind(this);
    this.handleConfirmDialogOpen = this.handleConfirmDialogOpen.bind(this);
    this.handleConfirmDialogCancel = this.handleConfirmDialogCancel.bind(this);
    this.handleConfirmDialogOk = this.handleConfirmDialogOk.bind(this);
    this.notifyValueChanged = this.notifyValueChanged.bind(this);
    this.fetchAbn = this.fetchAbn.bind(this);
    this.handleIsPrimaryChange = this.handleIsPrimaryChange.bind(this);
    this.setFieldsForEdit = this.setFieldsForEdit.bind(this);
    this.handleBSBChange = this.handleBSBChange.bind(this);
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    newState.fields.name.value = props.name;
    newState.fields.bsbNumber.value = props.bsbNumber;
    newState.fields.accountName.value = props.accountName;
    newState.fields.accountNumber.value = props.accountNumber;
    newState.nameSearchText = props.name;
    if (this.props.index == 0)
      newState.fields.isPrimary.value = true;
    newState.fields.isPrimary.value = props.isPrimary;
    setTimeout(() => {
      const selectedBank = find(props.banks, { name: props.name });
      const newState = { ...this.state };
      newState.fields.name.id = selectedBank ? selectedBank.id : undefined;
      this.setState(newState);
    }, 500);

    if (props.bankAccountDetailsMandatory) {
      forEach(['name', 'bsbNumber', 'accountNumber', 'accountName'], (key) => {
        newState.fields[key].validators.push(required());
      });
    }

    if (props.ngrType.toLowerCase() === 'shared') {
      newState.fields.companyId.validators = [required()];
      newState.fields.companyId.value = props.companyId;
      newState.fields.shareholderPercent.value = props.shareholderPercent;
      if (this.props.index != 0 && props.isPrimary)
        newState.fields.shareholderPercent.validators = [required(), maxValue(99), valueEqual(this.props.toBePercentage)];
      else if (this.props.ngrType.toLowerCase() === 'shared' && !props.isPrimary || this.props.index == 0)
        newState.fields.shareholderPercent.validators = [required(), maxValue(99)];
    }
    else {
      newState.fields.companyId.validators = [];
    }

    this.setState(newState, () => {
      if (props.startValidation) {
        this.setAllFieldsErrors();
      }
    });
  }

  componentDidMount() {
    this.setFieldsForEdit(this.props)
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps, this.props)) {
      this.setFieldsForEdit(this.props)
    }
  }

  setFieldsForEdit(details) {
    if(!details.name || isEmpty(this.props.banks))
      return
    const newState = { ...this.state };
    newState.fields.name.value = details.name;
    newState.fields.bsbNumber.value = details.bsbNumber;
    newState.fields.accountName.value = details.accountName;
    newState.fields.accountNumber.value = details.accountNumber;
    newState.nameSearchText = details.name;
    newState.fields.shareholderPercent.value = details.shareholderPercent;
    newState.fields.companyId.value = details.companyId;
    newState.fields.isPrimary.value = details.isPrimary;

    const banksByName = filter(this.props.banks, {name: details.name})
    let selectedBank;
    if(details.bsbNumber)
      selectedBank = find(banksByName, {bsbNumber: details.bsbNumber})
    if(!selectedBank)
      selectedBank = banksByName[0]
    newState.bankCode = selectedBank.code

    let bsbErrors = newState.fields.bsbNumber.errors
    if(selectedBank && this.state.fields.bsbNumber.value && isEqual(this.state.fields.bsbNumber.value.length , get(this.config,'bank.bsbLength'))) {
      const bankByBSBNumber = find(this.props.banks, {bsbNumber: details.bsbNumber})
      if(bankByBSBNumber && bankByBSBNumber.name !== selectedBank.name) {
        bsbErrors = [BSB_MISMATCH_ERROR];
      } else {
        bsbErrors = without(bsbErrors, BSB_MISMATCH_ERROR);
      }
    } else {
      bsbErrors = without(bsbErrors, BSB_MISMATCH_ERROR);
    }
    newState.fields.bsbNumber.errors = bsbErrors

    const isShared = this.props.ngrType === 'shared'
    newState.fields.companyId.validators = isShared ? [required()] : [];

    this.setState(newState, () => {
      if(isShared)
        this.setFieldErrors('companyId')
    });

  }

  onNewRequestName(selectedBank) {
    const newState = { ...this.state };
    newState.nameSearchText = selectedBank.name;
    newState.fields.name.value = selectedBank.name;
    newState.bankCode = selectedBank.code
    this.setState(newState, () => this.setFieldErrors('name'));
  }

  onUpdateInputName(searchText) {
    const newState = { ...this.state };
    newState.nameSearchText = searchText;
    if (isEmpty(searchText)) {
      newState.fields.name.value = "";
    }
    this.setState(newState);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value)
  }

  handleBSBChange(event) {
    let bsbNumber = event.target.value;
    this.setFieldValue(event.target.id, bsbNumber)


    if(bsbNumber && isEqual(bsbNumber.length, get(this.config,'bank.bsbLength'))) {
      let bsbBank = find(this.props.banks, {bsbNumber: bsbNumber});
      if(bsbBank) {
        const newState = { ...this.state };
        newState.fields.name.id = bsbBank.id;
        newState.fields.name.value = bsbBank.name;
        newState.fields.name.errors = [];
        newState.bankCode = bsbBank.code;
        this.setState(newState)
      }
    }
  }

  shouldBankDetailsMandatory() {
    const isMandatory = some(['name', 'bsbNumber', 'accountNumber', 'accountName'], (key) => {
      return !isEmpty(get(this.state.fields, `${key}.value`)) && !isNumber(get(this.state.fields, `${key}.value`));
    });

    return isMandatory;
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleCompanyChange = (value, id, chosenItem) => {

    let { fields } = this.state;
    if (chosenItem) {
      set(fields, `${id}.value`, value);
    }
    else {
      set(fields, `${id}.value`, undefined);
    }
    this.setState({ fields: fields }, () => this.setFieldErrors('companyId'));
  };

  onAbnBlur(event) {
    this.setFieldErrors('abn');
    if (this.state.fields.abn.value && this.state.fields.abn.value.length == 11) {
      this.fetchAbn(event.target.value);
    }
  }

  fetchAbn(abn) {
    const { dispatch } = this.props;
    dispatch(isLoading(null));
    fetch(`${apiURL}/abn?abn=${abn}&return_local=True`, {
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
      },
    })
      .then(response => response.json())
      .then((json) => {
        const newState = { ...this.state };
        if (json.entityName) {
          newState.fields.entityName.value = json.entityName;
        }
        else {
          newState.fields.entityName.value = '';
          newState.fields.abn.errors = ['The selected ABN does not exist'];
        }
        dispatch(forceStopLoader());
        this.setState(newState, this.notifyValueChanged);
      });
  }

  handleSelectChange(key, event, index, value) {
    this.setFieldValue(key, value);
  }

  setFieldValue(key, value) {
    const newState = { ...this.state };
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }


  getSelectedBank = (name, bsbNumber) => {
    const allBanksByName = filter(this.props.banks, {name: name})
    return bsbNumber ? find(allBanksByName, {bsbNumber: bsbNumber}) : allBanksByName[0]
  }

  setFieldErrors(key) {
    let errors = this.getFieldErrors(key);
    const newState = { ...this.state };
    newState.fields[key].errors = errors;
    if(isEqual(key, 'name')) {
      let value = newState.fields[key].value;
      const bankByNameAndBSBNumber = this.getSelectedBank(value, this.state.fields.bsbNumber.value);
      let bsbErrors = newState.fields.bsbNumber.errors
      if(!bankByNameAndBSBNumber) {
        const bankByName = this.getSelectedBank(value);
        if(bankByName && this.state.fields.bsbNumber.value && isEqual(this.state.fields.bsbNumber.value.length , get(this.config,'bank.bsbLength'))) {
          if(find(this.props.banks, {bsbNumber: this.state.fields.bsbNumber.value}))
            bsbErrors = [BSB_MISMATCH_ERROR];
          else
            bsbErrors = without(bsbErrors, BSB_MISMATCH_ERROR)
        } else
          bsbErrors = without(bsbErrors, BSB_MISMATCH_ERROR)
      } else
        bsbErrors = without(bsbErrors, BSB_MISMATCH_ERROR)
      newState.fields.bsbNumber.errors = bsbErrors
    }
    this.setState(newState, this.notifyValueChanged);
  }

  notifyValueChanged() {
    const values = {};
    let errorCount = 0;
    forEach(this.state.fields, (field, key) => {
      values[key] = this.state.fields[key].value;
      errorCount += this.state.fields[key].errors.length;
    });
    values['isValid'] = errorCount == 0;
    values['isMandatory'] = this.shouldBankDetailsMandatory();
    this.props.valueChanged(this.props.index, values);
    const newState = { ...this.state };
    if(this.shouldBankDetailsMandatory()) {
      forEach(['name', 'bsbNumber', 'accountNumber', 'accountName'], (key) => {
        newState.fields[key].validators.push(required());
      });
    } else if(!this.props.bankAccountDetailsMandatory) {
      forEach(['name', 'bsbNumber', 'accountNumber', 'accountName'], (key) => {
        newState.fields[key].validators = [];
        newState.fields[key].errors = [];
      });
    }
    this.setState(newState);
  }

  setAllFieldsValuesBySelectedNgr() {
    if (this.props.selectedNgr) {
      const newState = { ...this.state };

      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedNgr[key];
      });

      if (this.props.selectedNgr['name']) {
        newState.nameSearchText = this.props.selectedNgr['name'].name;
      }

      this.setState(newState);
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });

    if (this.props.ngrType.toLowerCase() === 'shared' && this.props.totalShareholderPercent != 100 && isEmpty(newState.fields['shareholderPercent'].errors)) {
      newState.fields['shareholderPercent'].errors.push('Please make sure total sum of Shareholder % is equal to 100');
    }

    if (this.props.ngrType.toLowerCase() === 'shared' && isEmpty(newState.fields['shareholderPercent'].errors) && this.state.fields.isPrimary.value==true && this.props.highestSharesPercentage != this.state.fields.shareholderPercent.value) {
      newState.fields['shareholderPercent'].errors.push('Primary shareholder should have maximum percent share');
    }
    this.setState(newState);
    this.props.validated(this.props.index);
  }

  handleConfirmDialogOpen() {
    const isFormData = some(this.state.fields, (field) => {
      return !isEmpty(field.value) || isNumber(field.value);
    });

    if (isFormData) {
      this.setState({
        ...this.state,
        isConfirmDialogOpen: true
      });
    }
    else {
      this.props.shareholderRemoved(this.props.index);
    }
  }

  handleConfirmDialogCancel() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false
    });
  }

  handleConfirmDialogOk() {
    this.props.shareholderRemoved(this.props.index);
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false,
    });
  }

  handleBankChange = () => value => {
    const allBanksByName = value ? filter(this.props.banks, {name: value}) : [];
    const selectedBank = allBanksByName[0]
    if(selectedBank)
      this.onNewRequestName(selectedBank)
  };

  handleIsPrimaryChange(event) {
    const newState = { ...this.state };
    set(newState.fields, 'shareholderPercent.errors', []);
    set(newState.fields, 'isPrimary.value', event.target.checked);
    if (this.props.index != 0 && event.target.checked && this.props.ngrType.toLowerCase() === 'shared')
      newState.fields.shareholderPercent.validators = [required(), maxValue(99), valueEqual(this.props.toBePercentage)];
    else if (this.props.ngrType.toLowerCase() === 'shared' && (!event.target.checked || this.props.index == 0))
      newState.fields.shareholderPercent.validators = [required(), maxValue(99)];
    else
      newState.fields.shareholderPercent.validators = [];
    this.setState(newState, this.notifyValueChanged);
  }

  render() {
    const bankItems = this.props.banks && uniqBy(this.props.banks.map(e => ({ label: e.name, value: e.name })), 'label');
    const isBankDetailsMandatory = this.shouldBankDetailsMandatory();
    const isBankSelected = Boolean(this.state.fields.name.value);

    return (
      <div>
        {
          this.props.ngrType.toLowerCase() === 'shared' ?
            <div>
              <div className="cardForm-content row trucks">
                <div className="col-sm-6 form-wrap">
                  <CommonTextField
                    id="shareholderPercent"
                    type='number'
                    placeholder="Please enter shareholder percent"
                    disabled={this.props.disabled}
                    label={this.props.index == 0 ? 'Primary Shareholder %' : 'Shareholder ' + (this.props.index + 1) + ' %'}
                    value={this.state.fields.shareholderPercent.value}
                    onChange={this.handleFieldChange}
                    onBlur={this.onFieldBlur}
                    onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
                    helperText={this.state.fields.shareholderPercent.errors[0]}
                    maxLength="6"
                  />
                </div>
                <div className="col-sm-6 form-wrap">
                  <div className="col-sm-3 form-wrap">
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.state.fields.isPrimary.value}
                          onChange={this.handleIsPrimaryChange}
                          disabled={this.props.disabled}
                        />
                      }
                      label="Is primary shareholder"
                    />
                  </div>
                  <div className="col-sm-3 form-wrap">
                    {
                      this.props.index > 1 ?
                        <a onClick={this.handleConfirmDialogOpen} style={{ color: PRIMARY_COLOR_GREEN, cursor: 'pointer', float: 'left', paddingLeft: '50px' }} disabled={this.props.disabled}>
                          Remove Shareholder
                        </a>
                        : ''
                    }
                  </div>
                </div>
              </div>
              <div className="cardForm-content row trucks">
                <div className="col-sm-6 form-wrap">
                  <CommonAutoSelect
                    items={this.props.allCompanies}
                    dataSourceConfig={{ text: 'name', value: 'id' }}
                    id="companyId"
                    label="Select Company"
                    value={this.state.fields.companyId.value}
                    errorText={get(this.state.fields, 'companyId.errors[0]')}
                    onChange={this.handleCompanyChange}
                    dontAutoselectSingleItem
                    disabled={this.props.disabled}
                  />
                </div>
              </div>
            </div>
            : ''
        }
        <div className="cardForm-content row trucks">
          <div className={isBankSelected ? "col-sm-4 form-wrap" : "col-sm-6 form-wrap"}>
            <CommonTextField
              id="bsbNumber"
              label={this.props.bankAccountDetailsMandatory || isBankDetailsMandatory ? `${getCountryLabel('bsb')} Number` : `${getCountryLabel('bsb')} Number (Optional)`}
              type="number"
              placeholder={`Please enter ${get(this.config,'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
              value={this.state.fields.bsbNumber.value}
              onChange={this.handleBSBChange}
              onBlur={this.onFieldBlur}
              disabled={this.props.disabled}
              helperText={this.state.fields.bsbNumber.errors[0]}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, get(this.config,'bank.bsbLength'));
              }} />
          </div>
          {
            isBankSelected &&
            <div className="col-sm-2 form-wrap">
              <CommonTextField
                id="bankCode"
                type="text"
                value={this.state.bankCode}
                variant='standard'
                lockIconStyle={{display: 'none'}}
                label="Bank Code"
                disabled
              />
            </div>
          }
          <div className="col-sm-6 form-wrap">
            <AutoComplete
              id="name"
              label={this.props.bankAccountDetailsMandatory || isBankDetailsMandatory ? "Bank Name" : "Bank Name (Optional)"}
              placeholder={this.props.bankAccountDetailsMandatory || isBankDetailsMandatory ? "Bank Name" : "Bank Name (Optional)"}
              options={bankItems}
              fullWidth
              errorText={this.state.fields.name.errors[0]}
              disabled={this.props.disabled}
              onBlur={this.onFieldBlur}
              value={this.state.fields.name.value}
              onChange={this.handleBankChange()}
            />
          </div>
        </div>
        <div className="cardForm-content row trucks">
          <div className="col-sm-6 form-wrap">
            <CommonTextField
              id="accountName"
              label={this.props.bankAccountDetailsMandatory || isBankDetailsMandatory ? "Account Name" : "Account Name (Optional)"}
              placeholder="Please enter account name"
              value={this.state.fields.accountName.value}
              onChange={this.handleFieldChange}
              onBlur={this.onFieldBlur}
              disabled={this.props.disabled}
              helperText={this.state.fields.accountName.errors[0]}
              maxlength="100"
            />
          </div>
          <div className="col-sm-6 form-wrap">
            <CommonTextField
              id="accountNumber"
              type="number"
              label={this.props.bankAccountDetailsMandatory || isBankDetailsMandatory ? "Account Number" : "Account Number (Optional)"}
              placeholder={`Please enter ${get(this.config, 'bank.accountNumberMinimumLength')}-${get(this.config, 'bank.accountNumberMaximumLength')} digits Account number`}
              disabled={this.props.disabled}
              value={this.state.fields.accountNumber.value}
              onChange={this.handleFieldChange}
              onBlur={this.onFieldBlur}
              helperText={this.state.fields.accountNumber.errors[0]}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, get(this.config, 'bank.accountNumberMaximumLength'));
              }}
            />
          </div>
        </div>
        <hr />

        <Dialog open={this.state.isConfirmDialogOpen} onClose={this.handleConfirmDialogCancel} scroll="paper">
          <DialogTitle>Remove Shareholder</DialogTitle>
          <DialogContent>
            Are you sure to remove the selected shareholder?
          </DialogContent>
          <DialogActions>
            <CommonButton
              key="no"
              label="No"
              primary={true}
              onClick={this.handleConfirmDialogCancel}
              variant="flat" />
            <CommonButton
              key="yes"
              label="Yes"
              primary={true}
              onClick={this.handleConfirmDialogOk}
              variant="flat" />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(CompanyBankAccountForm);

import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TitleTransfersTable from '../../containers/TitleTransfersTable';
import Paper from '@mui/material/Paper';
import SideDrawer from '../common/SideDrawer';
import {
    showHideTitleTransferSideDrawer, showViewTitleTransferSideDrawer
} from '../../actions/companies/contracts';
import { getTitleTransfers } from '../../actions/companies/cash-board';
import TitleTransferDetails from '../title-transfers/TitleTransferDetails';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from "@mui/material/Button/Button";

class CashPriceDetails extends React.Component {
    constructor(props) {
        super(props);
        this.cashPriceId = parseInt(this.props.match.params.cash_price_id);
        this.state = {
            value: '#' + this.props.location.pathname,
        };
        this.closeSideDraw = this.closeSideDraw.bind(this);
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return nextProps.location.pathname !== prevState.value ?
            { ...prevState, value: nextProps.location.pathname } :
            prevState;
    }

    componentDidMount() {
        if (get(this.props, 'selectedCashPrice.id') !== this.cashPriceId)
            this.props.getTitleTransfers(this.cashPriceId);
    }

    tabChanges = (event, value) => {
        this.setState({ value });
    };

    closeSideDraw() {
        this.props.showHideTitleTransferSideDrawer(false);
        this.props.showViewTitleTransferSideDrawer(false);
    }

    render() {
        const { value } = this.state;
        const PARENT_URL = this.props.match.url;
        return (
            <div className="subTab">
                <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
                    <Tab value={`${PARENT_URL}/title-transfers`} href={`${PARENT_URL}/title-transfers`} label="Title Transfers" className={value !== `${PARENT_URL}/title-transfers` ? 'unselected-subtab' : ''} />
                </Tabs>
                {
                    value === `${PARENT_URL}/title-transfers` &&
                    <Paper className='paper-table-paginated'>
                        <Button
                            variant="contained"
                            type="button"
                            onClick={() => window.history.back()}
                            color="primary"
                            className='add-button'
                            style={{ float: 'right', marginLeft: '10px' }}
                        >
                            <ArrowBackIcon style={{ paddingRight: '5px' }} /> Back to Cash Prices
                        </Button>
                        <TitleTransfersTable isCashBoard={true} />
                    </Paper>
                }
                {
                    this.props.isViewTitleTransferSideDrawerOpened &&
                    <SideDrawer
                        isOpen={this.props.isViewTitleTransferSideDrawerOpened}
                        title="Title Transfer"
                        onClose={this.closeSideDraw}
                        size='big'>
                        <TitleTransferDetails onClose={this.closeSideDraw} />
                    </SideDrawer>
                }
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        isViewTitleTransferSideDrawerOpened: state.companies.contracts.isViewTitleTransferSideDrawerOpened,
        selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
    };
};

const mapDispatchToProps = dispatch => ({
    showHideTitleTransferSideDrawer: flag => dispatch(showHideTitleTransferSideDrawer(flag)),
    showViewTitleTransferSideDrawer: flag => dispatch(showViewTitleTransferSideDrawer(flag)),
    getTitleTransfers: id => dispatch(getTitleTransfers(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CashPriceDetails);

import React from 'react';
import {
  Dialog, DialogContent, DialogActions, DialogTitle, Table, TableBody, TableRow, TableHead, TableCell,
  Select, MenuItem, Tooltip, InputAdornment, Divider, Button, IconButton
} from '@mui/material'
import { Info as InfoIcon, Cancel as RemoveIcon } from '@mui/icons-material'
import { map, find, isEmpty, set, orderBy, max, remove, filter, pick, keys } from 'lodash';
import CommonButton from '../common/CommonButton';
import AddButton from '../common/AddButton';
import CommonTextField from '../common/CommonTextField'
import { getCountryCurrency } from '../../common/utils';

const COLUMNS = [
  {label: 'Spec', tooltip: "Commodity's spec Name", width: '15%'},
  {label: 'Operator', tooltip: 'Operator', width: '15%'},
  {label: 'Value', tooltip: 'Value', width: '15%'},
  {label: 'Adjustment Type', tooltip: 'Adjustment Type', width: '15%'},
  {label: 'Adjustment Value', tooltip: 'Adjustment Value', width: '15%'},
  {label: 'Adjustment Per Value', tooltip: 'Adjustment Per Value', width: '15%'},
]

const OPERATORS = ['>', '>=', '<', '<=']
const ADJUSTMENTS = [
  {id: 'price', label: 'Price'},
  {id: 'quantity', label: 'Quantity'},
]

const SpecPriceAdjustments = ({ spread, disabled, commodity, onSave, setRef, errorText, currency }) => {
  const rowStruct = {
    spec: '',
    operator: '',
    value: '',
    adjustmentType: '',
    adjustmentValue: '',
    adjustmentPerValue: '',
    order: 0
  }
  const isEdit = Boolean(spread)
  const [open, setOpen] = React.useState(false)
  const [rows, setRows] = React.useState(isEdit ? [...spread] : [{...rowStruct}])
  const toggle = () => setOpen(!open)
  const label = (isEdit || Boolean(find(rows, row => row.spec))) ? 'Edit Price Adjustments' : 'Add Price Adjustments'
  const _onChange = (index, field, value) => {
    let newRows = [...rows]
    set(find(newRows, {order: index}), field, value)
    setRows(newRows)
  }
  const addRow = () => setRows([...rows, {...rowStruct, order: (max(map(rows, 'order')) || 0) + 1}])
  const deleteRow = index => {
    let newRows = remove([...rows], row => row.order !== index)
    newRows = map(newRows, row => ({...row, gist: getGist(row)}))
    setRows(newRows)
    onSave(newRows)
  }
  const _onSave = () => {
    const newRows = filter(map(rows, row => pick({...row, gist: getGist(row)}, [...keys(rowStruct), 'gist'])), row => row.spec && row.operator && row.value && row.adjustmentType && row.adjustmentValue && row.adjustmentPerValue)
    setRows(newRows)
    onSave(newRows)
    toggle()
  }
  const getGist = row => {
    if(!row.spec)
      return

    let gist = `If ${row.spec} ${row.operator} ${row.value}, Then `

    if(row.adjustmentType === 'price')
      gist += `Discount = ${currency || getCountryCurrency()} ${row.adjustmentValue}`
    else if (row.adjustmentType === 'quantity')
      gist += `Shrink = ${row.adjustmentValue}%`

    gist += ` per ${row.adjustmentPerValue}`
    gist += row.spec === 'TW' ? ' LB/BU' : '%'

    return gist
  }


  React.useEffect(() => {
    if(!isEmpty(spread))
      setRows([...spread])
  }, [spread])

  return (
    <div className="col-xs-12" ref={setRef}>
      <div className='col-xs-12'>
        {
          orderBy(filter(rows, row => !isEmpty(row.gist)), 'order').map(row => (
            <p key={row.spec}>{row.gist}</p>
          ))
        }
      </div>
      <AddButton
        label={label}
        onClick={toggle}
        disabled={disabled}
        style={{float: 'left'}}
      />
      {Boolean(errorText) && <span style={{marginTop: '10px', color : '#F46059', display: 'flex', paddingLeft: '10px' }}>{errorText}</span>}
      <Dialog
        open={open}
        onClose={toggle}
        scroll="paper"
        fullWidth
        maxWidth='lg'
      >
        <DialogTitle>
          {label}
        </DialogTitle>
        <DialogContent>
          <Table>
            <TableHead>
              <TableRow>
                {
                  COLUMNS.map(header => (
                    <TableCell key={header.label} style={{width: header.width}}>
                      <span style={{display: 'flex', alignItems: 'center'}}>
                        {header.label}
                        <Tooltip title={header.tooltip} arrow>
                          <InfoIcon fontSize='small' style={{fontSize: '1rem', marginLeft: '2px', color: 'rgba(0, 0, 0, 0.5)'}} />
                        </Tooltip>
                      </span>
                    </TableCell>
                  ))
                }
                <TableCell style={{width: '10%'}} />
              </TableRow>
            </TableHead>
            <TableBody>
              {
                map(orderBy(rows, 'order'), row => {
                  const id = `spec-${row.order}`
                  return (
                    <TableRow
                      id={id}
                      key={id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell style={{paddingLeft: '0px'}}>
                          <Select fullWidth size='small' value={row.spec} onChange={event => _onChange(row.order, 'spec', event.target.value)}>
                          {
                            commodity?.specs?.map(spec => (
                              <MenuItem key={spec.code} value={spec.code}>{spec.code}</MenuItem>
                            ))
                          }
                          </Select>
                      </TableCell>
                      <TableCell>
                          <Select fullWidth size='small' value={row.operator} onChange={event => _onChange(row.order, 'operator', event.target.value)}>
                            {
                              OPERATORS.map(operator => (
                                <MenuItem key={operator} value={operator}>{operator}</MenuItem>
                              ))
                            }
                          </Select>
                      </TableCell>
                      <TableCell>
                        <CommonTextField
                          size='small'
                          value={row.value}
                          type='number'
                          variant='outlined'
                          onChange={event => _onChange(row.order, 'value', event.target.value)}
                          inputProps={{max: 100, min: 0}}
                        />
                      </TableCell>
                      <TableCell>
                        <Select fullWidth size='small' value={row.adjustmentType} onChange={event => _onChange(row.order, 'adjustmentType', event.target.value)}>
                          {
                            ADJUSTMENTS.map(adj => (
                              <MenuItem key={adj.id} value={adj.id}>{adj.label}</MenuItem>
                            ))
                          }
                        </Select>
                      </TableCell>
                      <TableCell>
                        <CommonTextField
                          size='small'
                          value={row.adjustmentValue}
                          type='number'
                          variant='outlined'
                          InputProps={{
                            startAdornment: row.adjustmentType === 'price' ?
                              <InputAdornment position='start'>{currency || getCountryCurrency()}</InputAdornment> :
                              undefined,
                            endAdornment: row.adjustmentType === 'quantity' ?
                              <InputAdornment position='end'>%</InputAdornment> :
                              undefined
                          }}
                          onChange={event => _onChange(row.order, 'adjustmentValue', event.target.value)}
                        />
                        </TableCell>
                        <TableCell>
                          <CommonTextField
                            size='small'
                            value={row.adjustmentPerValue}
                            type='number'
                            variant='outlined'
                            InputProps={{
                              endAdornment: <InputAdornment position='end'>{row.spec === 'TW' ? 'LB/BU' : '%'}</InputAdornment>
                            }}
                            onChange={event => _onChange(row.order, 'adjustmentPerValue', event.target.value)}
                            inputProps={{max: 100, min: 0}}
                          />
                        </TableCell>
                      <TableCell align='right'>
                            <IconButton size='small' color='error' onClick={() => deleteRow(row.order)}>
                              <RemoveIcon fontSize='inherit'/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                  )
                })
              }
            </TableBody>
          </Table>
          <Divider />
          <Button variant='text' onClick={addRow} size='small' style={{textTransform: 'none'}}>
            Add Price Adjustment
          </Button>
        </DialogContent>
        <DialogActions>
          <CommonButton
            variant="contained"
            label="Cancel"
            default
            onClick={toggle}
          />
          <CommonButton
            variant="contained"
            label="Save"
            primary
            onClick={_onSave}
          />
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default SpecPriceAdjustments;

import React, { Component } from 'react';
import isEmpty from 'lodash/isEmpty';
import './acceptRejectOrder.scss';
import APIService from '../../services/APIService';
import get from "lodash/get";
import moment from "moment";

class UnregisteredUserConfirmOrder extends Component{
  constructor(props) {
    super(props);
    this.state = {
      canConfirm: false,
      isConfirmed: false,
      order: undefined,
      loaded: false,
    };
    this.acceptOrder = this.acceptOrder.bind(this);
  }

  async componentDidMount(){
    const orderId = get(this.props, 'match.params.order_id');
    const salt = get(this.props, 'match.params.salt');
    if(orderId && salt) {
      const order = await APIService.freights().orders(orderId).get(salt);
      if(order && order.confirmable){
        await this.acceptOrder(order);
      }
      const newState = {...this.state};
      newState.loaded = true;
      this.setState(newState);
    }
  }

  async acceptOrder(order){
    const salt = this.props.match ? this.props.match.params.salt : null;
    await APIService.freights().orders(order.id)['confirm']().put({updatedAt: moment.utc(order.updatedAt).local().valueOf()}, salt)
      .then((json) => {
        if(!isEmpty(json.errors)){
          this.setState({ errors: json.errors[0] });
        } else {
          this.setState({ isConfirmed: true });
        }
      });
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
              {this.state.isConfirmed ? (
                <span>Order accepted successfully.</span>
              ) : this.state.loaded && (
                <span>{get(this.state, 'errors.0', 'You do not have permission to access the information.')}</span>
              )}
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>;
  }
}

export default UnregisteredUserConfirmOrder;

/*eslint no-process-env: 0*/

import React from 'react';
import { connect } from 'react-redux';
import InputAdornment from '@mui/material/InputAdornment';
import PasswordIcon from '@mui/icons-material/Key';
import './login.scss';
import CommonButton from '../common/CommonButton';
import APIService from "../../services/APIService";
import { forEach, some, mapValues } from "lodash";
import { PASSWORDS_DO_NOT_MATCH } from '../../common/constants';
import { required, minLength } from '../../common/validators';
import CommonTextField from '../common/CommonTextField';
import { isChromeBrowser, isOldChromeVersion, encrypt_password } from '../../common/utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import PasswordValidIndicator from '../employees/PasswordValidIndicator';
import alertifyjs from 'alertifyjs';
import { includes } from 'lodash';
import { isLoading, forceStopLoader } from '../../actions/main';

class UserSetPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      browserAlert: false,
      updateChromePopup: false,
      fields: {
        password: {
            value: '',
            validators: [required(), minLength(8)],
            errors: [],
            showPassword: false
        },
        confirmPassword: {
            value: '',
            validators: [required(), minLength(8)],
            errors: [],
            showPassword: false
        }
      },
      token: null,
      isPasswordValid: false,
      isLinkValid: true,
      linkError: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeBrowserAlert = this.closeBrowserAlert.bind(this);
    this.closeUpdateChromePopup= this.closeUpdateChromePopup.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.isPasswordValid = this.isPasswordValid.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
  }

  componentDidMount() {
    const match = window.location.hash.match(/user-set-password\/(\w+)/);
    this.setState({ token: match ? match[1] : null})
    this.props.dispatch(isLoading());
    let appendToUrl = 'set_password_validate_link/'
    if (includes(window.location.hash, 'reset=true'))
      appendToUrl += '?reset=true'
    APIService.profiles().appendToUrl(appendToUrl).get(match[1]).then(response => {
      if(response?.errors) {
        alertifyjs.error(response?.errors)
        if (this.props.isLoading)
          this.props.dispatch(forceStopLoader())
        this.setState({isLinkValid: false, linkError: response?.errors})
        setTimeout(() => window.location.hash = '/forgot-password', 1000)
      }
    })
    this.setBrowserAlertIfNotChrome();
    this.setBrowserAlertIfOlderChromeVersion();
  }

  componentDidUpdate() {
    if(this.state.isLinkValid != this.props.isLinkValid && this.state.linkError == null)
      this.setState({isLinkValid: this.props.isLinkValid})
  }

  setBrowserAlertIfOlderChromeVersion() {
    if (isOldChromeVersion()) {
      this.setState({ updateChromePopup: true});
    }
  }

  setBrowserAlertIfNotChrome() {
    if (!isChromeBrowser())
      this.setState({ browserAlert: true });
  }

  closeUpdateChromePopup = () => this.setState({ updateChromePopup: false});

  closeBrowserAlert = () => this.setState({ browserAlert: false })

  handleChange = event => {
    const newState = { ...this.state };
    newState.fields[event.target.id].value = event.target.value;
    this.setState(newState);
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState);
  }

  handleSubmit(event) {
    event.preventDefault();
    if (this.state.fields.password.value !== this.state.fields.confirmPassword.value) {
      const newState = {...this.state};
      newState.fields.confirmPassword.errors = [PASSWORDS_DO_NOT_MATCH];
      this.setState(newState);
    } else {
      this.setAllFieldsErrors();
      if(!this.state.isPasswordValid)
        return;
      const isFormInvalid = some(this.state.fields, field => {
        return some(field.validators, (validator) => validator.isInvalid(field.value));
      });
      const submitData = mapValues(this.state.fields, field => encrypt_password(field.value));

      delete submitData.confirmPassword;
      if (!isFormInvalid && this.state.token) {
        let reset = window.location.hash.includes('reset')
        APIService.profiles().appendToUrl(`user_set_password/?reset=${reset}`).post(submitData, this.state.token).then(response => {
          if (response?.errors) {
            if(response?.errors.includes('Link')) {
              alertifyjs.error(response?.errors)
              setTimeout(() => window.location.hash = '/forgot-password', 1000)
            } else if(response?.errors.includes('old password')){
              const newState = {...this.state};
              newState.fields.password.errors = [response?.errors]
              this.setState(newState);
            } else
              alertifyjs.error("An error occurred while setting password")
          } else if (response == null) {
            alertifyjs.success("Password set successfully")
            window.location.hash = '/'
          }
        })
      }
    }
  }

  checkPasswordsMatch() {
    const newState = {...this.state};
    newState.fields.confirmPassword.errors = newState.fields.password.value !== newState.fields.confirmPassword.value ? [PASSWORDS_DO_NOT_MATCH] : [];
    this.setState(newState);
  }

  getFieldErrors(key) {
    let errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    if(key === 'confirmPassword')
      errors = this.state.fields.password.value !== this.state.fields.confirmPassword.value ? [PASSWORDS_DO_NOT_MATCH] : []

    return errors;
   }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }),
    () => {
    if (this.state.fields.password.value && this.state.fields.confirmPassword.value)
        this.checkPasswordsMatch()
    });
}

  handleClickShowPassword(id) {
    const newState = { ...this.state };
    newState.fields[id].showPassword = !newState.fields[id].showPassword;
    this.setState(newState);
  }

  isPasswordValid(valid) {
    if(valid !== this.state.isPasswordValid)
      this.setState({isPasswordValid: valid});
  }

  render() {
    return (
      <div className="login-wrapper">
        <div className='row'>
          <div className="login-container" style={{marginTop: '30px'}}>
            <div className="login-content-box">
              <div className="login-content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
              </div>
              <div className="login-content-box--content">
                <h2>Set Password</h2>
                <p>Enter password based on the rules.</p>
                <form
                  onSubmit={this.handleSubmit}
                >
                  <div className="cardForm cardForm--drawer" style={{marginBottom: '0px'}}>
                    <div className="cardForm-content row">
                      <div className="col-sm-12 form-wrap text-black" style={{padding: 0}}>
                        <div className="col-sm-12 form-wrap">
                            <CommonTextField
                            id="password"
                            label="Password"
                            placeholder="Please enter"
                            fullWidth
                            onChange={this.handleChange}
                            name="password"
                            type={this.state.fields.password.showPassword ? 'text' : 'password'}
                            value={this.state.fields.password.value}
                            required
                            onBlur={() => this.setFieldErrors('password')}
                            helperText={this.state.fields.password.errors[0]}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordIcon />
                                </InputAdornment>
                                ),
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.handleClickShowPassword('password')}
                                                size="large">
                                    {this.state.fields.password.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                            autoFocus
                            />
                        </div>
                        <div className="col-sm-12 form-wrap">
                            <CommonTextField
                            id="confirmPassword"
                            label="Confirm Password"
                            placeholder="Please enter"
                            fullWidth
                            onChange={this.handleChange}
                            name="password"
                            type={this.state.fields.confirmPassword.showPassword ? 'text' : 'password'}
                            value={this.state.fields.confirmPassword.value}
                            required
                            onBlur={() => this.setFieldErrors('confirmPassword')}
                            helperText={this.state.fields.confirmPassword.errors[0]}
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                <InputAdornment position="start">
                                    <PasswordIcon />
                                </InputAdornment>
                                ),
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton
                                    aria-label="Toggle password visibility"
                                    onClick={() => this.handleClickShowPassword('confirmPassword')}
                                                size="large">
                                    {this.state.fields.confirmPassword.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                                )
                            }}
                            />
                        </div>
                        <div className="col-sm-12 form-wrap-70">
                            <PasswordValidIndicator
                                mainContainerStyle={{fontSize: '12px', marginLeft: '0px', display: 'inline'}}
                                indicatorContainerStyle={{marginTop: '2px', marginBottom: '15px'}}
                                password={this.state.fields.password.value}
                                onChange={this.isPasswordValid}
                            />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                    <CommonButton
                        type="submit"
                        label={"Submit"}
                        variant="contained"
                        primary
                        className="login-button"
                        disabled={!this.state.isLinkValid}
                    />
                  </div>
                </form>
                <span style={{ fontSize: '0.7em' }}>By continuing you agree to these <a href="#/web/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://www.iubenda.com/privacy-policy/38052771" title="Privacy Policy" target='_blank' rel="noopener noreferrer">Privacy Policy</a></span>
              </div>
            </div>
          </div>
        </div>
        <Dialog open={this.state.browserAlert} onClose={this.closeBrowserAlert} aria-labelledby="form-dialog-title" style={{ zIndex: '2000' }}>
          <DialogTitleWithCloseIcon>Important!</DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '10px' }}>
            AgriChain platform works best on Google Chrome. We recommend you to download the same by clicking on this <a rel="noopener noreferrer" href="https://www.google.com/chrome/" target="_blank">link</a>.
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.closeBrowserAlert}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.updateChromePopup} onClose={this.closeUpdateChromePopup} aria-labelledby="form-dialog-title" style={{ zIndex: '2000' }}>
          <DialogTitleWithCloseIcon>Important!</DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '10px' }}>
            Your Chrome version is very old, please download the latest version to have the best experience of AgriChain by clicking on this <a rel="noopener noreferrer" href="https://www.google.com/chrome/update/" target="_blank">link</a>.
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.closeUpdateChromePopup}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    isLinkValid: state.main.isLinkValid,
    isLoading: state.main.isLoading
  };
};
export default connect(mapStateToProps)(UserSetPassword);

import React from 'react';
import { FormControl, InputLabel, ListItemText, MenuItem, Select, FormHelperText } from "@mui/material";
import { PREDEFINED_DATE_RANGE_FILTERS, ERROR } from "../../common/constants";
import {get, isEmpty} from 'lodash';

let PreDefinedDateRangeSelector = props => {
  return (
    <FormControl variant="outlined" style={{width: '100%'}}>
      <InputLabel id="select-label" style={{paddingBottom: '5px'}}>{props.name}</InputLabel>
      <Select labelId="select-label" label={props.name} MenuProps={{classes: {paper: "list-height-restricted"}}} style={{width: '100%', height: '60px'}} value={isEmpty(props.predefinedDateRangeFilters) ? props.dateRangeFilters : get(props.predefinedDateRangeFilters, props.id)}>
      <MenuItem value="" onClick={() => props.setDateRange(isEmpty(props.predefinedDateRangeFilters) ? "" : {...props.predefinedDateRangeFilters, [props.id]: ""})}>
          <em>{props.emptyOptionText || "None"}</em>
        </MenuItem>
        {PREDEFINED_DATE_RANGE_FILTERS.map(item => (
            <MenuItem key={item.id} value={item.id} onClick={() => props.setDateRange(isEmpty(props.predefinedDateRangeFilters) ? item.id : {...props.predefinedDateRangeFilters, [props.id]: item.id})}>
              <ListItemText primary={item.name} secondary={item.secondaryText}/>
            </MenuItem>
        ))}
      </Select>
      {props?.isMandatory && isEmpty(props.dateRangeFilters ? props.dateRangeFilters : get(props.predefinedDateRangeFilters, props.id)) &&
        <FormHelperText style={{marginLeft: '0px', color: ERROR}}>This field is required</FormHelperText>
      }
    </FormControl>
  )
}

export default PreDefinedDateRangeSelector;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import { isEmpty } from 'lodash'
import { getCurrentCountry } from '../../common/utils';
import APIService from '../../services/APIService';
import LoaderInline from '../LoaderInline';
import XeroMappings from './XeroMappings';
import XeroSetupInstructions from './XeroSetupInstructions';


class XeroMappingsContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tab: 0,
      mappings: [],
      xeroAccounts: [],
      xeroItemCodes: [],
      commodities: [],
      grades: [],
      seasons: [],
      itemTypes: [],
      isLoadingMappings: false,
      allTrackingCategories: [],
    }
  }

  handleTabChange = (event, value) => this.setState({tab: value}, this.fetchMappings)

  getTransactionType = () => {
    const { tab } = this.state
    if(tab === 1)
      return 'purchase_contract'
    if(tab === 2)
      return 'sale_contract'
    if(tab === 3)
      return 'warehouse'
    if(tab === 4)
      return 'deductions'
    if(tab === 5)
      return 'freight_invoice_payable'
    if(tab === 6)
      return 'freight_invoice_receivable'
  }

  fetchMappings = () => {
    this.setState({mappings: [], isLoadingMappings: true}, () => {
      this.refetchMappings(response => this.setState({mappings: response, isLoadingMappings: false}))
    })
  }

  refetchMappings = callback => {
    APIService
      .companies(this.props.company.id)
      .appendToUrl('xero/mappings/')
      .get(null, null, {transaction_type: this.getTransactionType()})
      .then(response => callback ? callback(response) : this.setState({mappings: response}))
  }

  fetchFixtures = () => {
    APIService.companies(this.props.company.id).appendToUrl('xero/connections/fixtures/').get().then(response => {
      this.setState({xeroAccounts: response.accounts, xeroItemCodes: response.items})
    })
  }

  fetchCommodities = () => {
    APIService.commodities().get(null, null, {includes: 'grade', countryId: getCurrentCountry().id}).then(response => this.setState({commodities: response}))
  }

  fetchTrackingCategories = () => {
    APIService.companies(this.props.company.id).appendToUrl(`xero/tracking-categories/`).get().then(data => {
        this.setState({allTrackingCategories: data});
      })
  }

  componentDidMount() {
    this.fetchCommodities()
    this.fetchMappings()
    if(this.props.company.isValidXeroConnection) {
      this.fetchFixtures();
      if (this.props.company.hasTrackingCategories) {
        this.fetchTrackingCategories();
      }
    }
  }


  render() {
    const { company } = this.props
    const { tab, mappings, isLoadingMappings, commodities, xeroAccounts, xeroItemCodes, allTrackingCategories } = this.state
    const tabs = [
      {id: 0, label: 'Instructions'},
      {id: 1, label: 'Purchase Contract'},
      {id: 2, label: 'Sale Contract'},
      {id: 3, label: 'Warehouse'},
      {id: 4, label: 'Deductions'},
      {id: 5, label: 'Freight Invoice Payable'},
      {id: 6, label: 'Freight Invoice Receivable'},
    ]
    const transactionType = this.getTransactionType()
    return (
        <div className="subTab">
          <Tabs indicatorColor="primary" className="subTab-header" value={tab} onChange={this.handleTabChange}>
            {
              tabs.map(_tab => (
                <Tab key={_tab.label} label={_tab.label}  className={tab !== _tab.id ? 'unselected-subtab' : ''} />
              ))
            }
          </Tabs>
          {
            tab === 0 ?
              <div className="subTab-container">
                <XeroSetupInstructions company={company} />
              </div> :
              <div className="subTab-container">
                {
                  (isLoadingMappings || isEmpty(commodities)) ?
                    <LoaderInline containerClassName="inline-loader-container" /> :
                    <XeroMappings
                      xeroAccounts={xeroAccounts}
                      xeroItemCodes={xeroItemCodes}
                      allTrackingCategories={allTrackingCategories}
                      commodities={commodities}
                      mappings={mappings}
                      transactionType={transactionType}
                      company={company}
                      onRefetch={this.refetchMappings}
                      onSuccess={this.fetchMappings}
                    />
                }
              </div>
          }
        </div>
    )
  }
}

export default connect()(XeroMappingsContainer);

export const RECEIVE_FARM_EMPLOYEES = 'RECEIVE_FARM_EMPLOYEES';
export const ADD_FARM_EMPLOYEE = 'ADD_FARM_EMPLOYEE';
export const EDIT_FARM_EMPLOYEE = 'EDIT_FARM_EMPLOYEE';
export const CANCEL_EDIT_FARM_EMPLOYEE = 'CANCEL_EDIT_FARM_EMPLOYEE';
export const CLICK_ADD_FARM_EMPLOYEE_BUTTON = 'CLICK_ADD_FARM_EMPLOYEE_BUTTON';
export const CLICK_EDIT_FARM_EMPLOYEE_ICON = 'CLICK_EDIT_FARM_EMPLOYEE_ICON';
export const ADD_FARM_EMPLOYEE_ERRORS = 'ADD_FARM_EMPLOYEE_ERRORS';
export const EDIT_FARM_EMPLOYEE_ERRORS = 'EDIT_FARM_EMPLOYEE_ERRORS';
export const RECEIVE_SYSTEM_STORAGES = 'RECEIVE_SYSTEM_STORAGES';
export const ADD_SYSTEM_STORAGE = 'ADD_SYSTEM_STORAGE';
export const EDIT_SYSTEM_STORAGE = 'EDIT_SYSTEM_STORAGE';
export const CLICK_ADD_SYSTEM_STORAGE_BUTTON = 'CLICK_ADD_SYSTEM_STORAGE_BUTTON';
export const ADD_SYSTEM_STORAGE_ERRORS = 'ADD_SYSTEM_STORAGE_ERRORS';

export const receiveFarmEmployees = items => ({
  type: RECEIVE_FARM_EMPLOYEES,
  items,
});

export const addFarmEmployee = item => ({
  type: ADD_FARM_EMPLOYEE,
  item,
});

export const addFarmEmployeeErrors = errors => ({
  type: ADD_FARM_EMPLOYEE_ERRORS,
  errors,
});

export const editFarmEmployee = item => ({
  type: EDIT_FARM_EMPLOYEE,
  item,
});

export const editFarmEmployeeErrors = errors => ({
  type: EDIT_FARM_EMPLOYEE_ERRORS,
  errors,
});

export const cancelEditFarmEmployee = () => ({
  type: CANCEL_EDIT_FARM_EMPLOYEE,
});

export const clickAddFarmEmployeeButton = () => ({
  type: CLICK_ADD_FARM_EMPLOYEE_BUTTON,
});

export const clickEditFarmEmployeeIcon = itemId => ({
  type: CLICK_EDIT_FARM_EMPLOYEE_ICON,
  itemId,
});

export const receiveSystemStorages = items => ({
  type: RECEIVE_SYSTEM_STORAGES,
  items,
});

export const addSystemStorage = item => ({
  type: ADD_SYSTEM_STORAGE,
  item,
});

export const addSystemStorageErrors = errors => ({
  type: ADD_SYSTEM_STORAGE_ERRORS,
  errors,
});

export const clickAddSystemStorageButton = () => ({
  type: CLICK_ADD_SYSTEM_STORAGE_BUTTON,
});

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { required } from '../../common/validators';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CommonTextField from '../common/CommonTextField';
import CommonButton from '../common/CommonButton';
import './acceptRejectContract.scss';
import APIService from '../../services/APIService';
import moment from "moment";

class UnregisteredUserRejectContract extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
      canReject: false,
      isRejected: false,
      contract: undefined,
      loaded: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount(){
    if(this.props.match && this.props.match.params.contract_id) {
      const contract = await APIService.contracts(this.props.match.params.contract_id).appendToUrl('unreg/').get(this.props.match.params.salt);
      const newState = {...this.state};
      if(contract && contract.id){
        newState.canReject = contract.confirmable && (contract.status === 'planned' || contract.status !== 'rejected');
        newState.isRejected = contract.status === 'rejected';
        newState.contract = contract;
      }
      newState.loaded = true;
      this.setState(newState);
    }
  }

  handleSubmit(event) {
    this.setErrors();
    if (this.getIsFormValid()) {
      const data = { rejectionReason: this.state.rejectionReason.value, updatedAt: moment.utc(get(this.state.contract,'updatedAt')).local().valueOf() };
      const salt = this.props.match ? this.props.match.params.salt : null;
      APIService.contracts(this.state.contract.id)['reject']().put(data,salt)
                .then((json) => {
                  if(!isEmpty(json.errors)){
                    if(!isEmpty(json.errors['rejectionReason'])){
                      this.setErrors(json.errors['rejectionReason']);
                    } else {
                      this.setErrors(json.errors);
                    }
                  } else {
                    if(json.status === 'rejected'){
                      this.setState({ isRejected: true });
                    }
                  }
                });
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return this.state.rejectionReason.errors.length == 0;
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            <form onSubmit={this.handleSubmit} noValidate>
              { this.state.contract && this.state.canReject && !this.state.isRejected ? (
                  <div>
                    <h4 className="reject-title">Reject Contract</h4>
                    <CommonTextField
                      label="Reason"
                      placeholder="Please enter your reason for rejection"
                      id="rejectReason"
                      multiline
                      className="reject-reason-field"
                      InputProps={{
                        disableUnderline: true,
                      }}
                      value={this.state.rejectionReason.value}
                      onChange={this.handleChange}
                      helperText={get(this.state, 'rejectionReason.errors[0]', '')}
                      errorStyle={{textAlign: "left"}}
                      fullWidth
                      maxLength="255"
                    />
                    <CommonButton
                      type="submit"
                      variant="contained"
                      label="REJECT"
                      primary
                    />
                  </div>
              ) : (
                  this.state.contract && this.state.isRejected ? (
                    <span>Contract is Rejected</span>
                  ) : this.state.loaded && (
                    <span>You do not have permission to access the information.</span>
                  )
              )}
            </form>
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.
        </div>
      </div>
    </div>;
  }
}

const mapStateToProps = state => {
  return {
    contract: state.companies.contracts.selectedContract,
  };
};

export default connect(mapStateToProps)(UnregisteredUserRejectContract);

import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import {
  RECEIVE_NGRS,
  ADD_NGR,
  EDIT_NGR,
  CANCEL_EDIT_NGR,
  CLICK_ADD_NGR_BUTTON,
  CLICK_EDIT_NGR_ICON,
  CLICK_EDIT_NGR,
  ADD_NGR_ERRORS,
  EDIT_NGR_ERRORS,
} from '../../actions/companies/ngrs';

const initialState = {
  items: [],
  selectedNgrId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_NGRS:
      return {
        ...state,
        items: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_NGR:
      return {
        ...state,
        items: [action.item, ...state.items],
        selectedNgrId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_NGR_ERRORS:
      return {
        ...state,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_NGR: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [...state.items.slice(0, index), action.item, ...state.items.slice(index + 1)],
        selectedFarmId: undefined,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        updatedNgr: action.item,
      };
    }
    case EDIT_NGR_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_NGR:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: false,
      };
    case CLICK_ADD_NGR_BUTTON:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_NGR_ICON:
      return {
        ...state,
        selectedFarmId: find(state.items, { id: action.id }).farmId,
        selectedNgrId: find(state.items, { id: action.id }).id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    case CLICK_EDIT_NGR:
      return {
        ...state,
        selectedNgrId: action.id,
        isUpdateFormDisplayed: true,
      };
    default:
      return state;
  }
};

export default reducer;

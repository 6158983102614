import React, { Component } from 'react';
import { connect } from 'react-redux';

import { applyValidatorsOn, required, selected } from '../../common/validators';
import alertifyjs from 'alertifyjs';
import '@babel/polyfill';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import CustomEmailDialog from '../common/CustomEmailDialog';
import TextField from '@mui/material/TextField/TextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Tooltip from '@mui/material/Tooltip';
import AddButton from '../common/AddButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  get, isNumber, isEqual, isEmpty, remove, forEach, cloneDeep,
  find, reduce, filter, some, includes, mapValues, keys, values, compact,
  uniq, without, pick, uniqBy, every, uniqWith, has, map, merge, set, omit, sum,
  pickBy, isArray
} from 'lodash';
import queryString from 'query-string';
import {
  canAccessAny,
  currentUserCompany,
  generateIdentifier,
  getAutoSelectFocusField,
  getDaysToAdd,
  getLabelForEmployeeForm,
  getWeekDays,
  getZendeskURL,
  isCompanyGrower,
  isCurrentUserBelongsToCompany,
  nextEndDayFrom,
  returnFloatFromString,
  getCountryFormats,
  getCountryConfig, getCountryLabel, getCountryCurrency, formatPrice, toBankAccountFormat,
  getEstimatedPaymentDueDateForDatePaymentTerm
} from '../../common/utils';
import APIService from '../../services/APIService';
import { fetchBanks, isLoading, setBreadcrumbs, setHeaderText, forceStopLoader } from '../../actions/main';
import {
  clearContract,
  getContractsResponse,
  getMovementsCarryItems,
  getSelectedContract,
  getTitleTransferCarryItems,
  getTitleTransfers,
  movementCarryItemsResponse,
  receiveContract,
  titleTransfersCarryItemsResponse,
  titleTransfersResponse,
  isTitleTransfersFetch,
  getTitleTransferCanolaLoadCarryItems,
} from '../../actions/companies/contracts';
import { getDetailedContracts, isFreightContractsFetched, loadInvoiceItems } from '../../actions/companies/freights';
import InvoiceTable from './InvoiceTable';
import moment from 'moment';
import InvoiceCustomItemForm from '../invoices/InvoiceCustomItemForm';
import SideDrawer from '../common/SideDrawer';
import {
  COMMODITIES,
  NGR_MISSING_DETAILS_ERROR,
  NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS,
  PAYMENT_SCALES,
  PRICE_POINTS,
} from '../../common/constants';
import { clickAddGeneralConditionButton, getGeneralConditions } from '../../actions/companies/conditions';
import CommonButton from '../common/CommonButton';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Dialog from '@mui/material/Dialog/Dialog';
import InvoicePreview from './InvoicePreview';
import './invoice.scss';
import CommonSelect from '../common/select/CommonSelect';
import AutoComplete from '../common/autocomplete/AutoComplete';
import Button from '@mui/material/Button/Button';
import { cancelEditNgr } from '../../actions/companies/ngrs';
import { cancelEditCompanyNgr, clickEditCompanyNgr, receiveCompanyNgrs } from '../../actions/companies/company-ngrs';
import UpdateCompanyNgr from '../../containers/UpdateCompanyNgr';
import UpdateNgr from '../../containers/UpdateNgr';
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import CreateEmployee from '../../containers/CreateEmployee';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import { AddCircleIconWithTooltip } from '../common/icons/AddCircleIconWithTooltip';
import { canCreateEmployee } from '../../actions/api/employees';
import {
  CARRY_ITEM_TYPE,
  CUSTOM_ITEM_TYPE,
  EPR_ITEM_TYPE,
  FREIGHT_CONTRACT_ITEM_TYPE,
  GRAIN_LEVY_ITEM_TYPE,
  LOAD_ITEM_TYPE,
  TITLE_TRANSFER_ITEM_TYPE,
} from './Constants';
import { clickAddEmployeeButton } from '../../actions/company-settings/employees';
import { canCreateNgr } from '../../actions/api/ngrs';
import { getCompanyCompaniesMinimal } from '../../actions/api/companies';
import Notes from '../common/Notes';
import { getInvoiceDetails, invoiceDetails } from '../../actions/companies/invoices';
import LoaderInline from '../LoaderInline';

const DEFAULT_SIDE_DRAWERS_STATE = {
  sellerSideDrawerIsOpen: false,
  buyerSideDrawerIsOpen: false,
  invoiceConditionSideDrawerIsOpen: false,
  openSideDrawer: false,
  ngrSideDrawerIsOpen: false,
};

class CommodityContractInvoiceForm extends Component {
  constructor(props) {
    super(props)
    this.invoicePartyFields = {
      companyId: {
        value: null,
        validators: [],
        errors: [],
      },
      contactId: {
        value: null,
        validators: [],
        errors: [],
      },
      ngrId: {
        value: null,
        validators: [],
        errors: [],
      },
      bankName: {
        value: null,
        id: '',
        validators: [required(), selected()],
        errors: [],
      },
      accountName: {
        value: null,
        validators: [required()],
        errors: [],
      },
      bsbNumber: {
        value: null,
        validators: [required()],
        errors: [],
      },
      accountNumber: {
        value: null,
        validators: [required()],
        errors: [],
      },
    };
    this.payerFields = cloneDeep(this.invoicePartyFields);
    this.payeeFields = cloneDeep(this.invoicePartyFields);
    const countryConfig = getCountryConfig()
    this.state = {
      amendNotAllowed: false,
      eprStates: {},
      levyStates: {},
      countryConfig: countryConfig,
      countryFormats: getCountryFormats(),
      gstApplicable: countryConfig?.invoicing?.gst,
      gstRate: countryConfig?.invoicing?.gstRate,
      eprCache: {},
      levyCache: {},
      levyEprPreferences: undefined,
      levyEprDefaults: undefined,
      xeroCounterPartyExists: false,
      currentUserCompany: null,
      removeLoader: false,
      showCustomEmailDialog: false,
      isDefaultGeneralConditionApplied: false,
      isInEditMode: false,
      sellerNgrs: [],
      buyerNgrs: [],
      sellerEmployees: [],
      buyerEmployees: [],
      allContracts: [],
      titleTransfers: [],
      freightMovements: [],
      blendedGradeLoads: [],
      chemicalApplicationItems: [],
      loads: [],
      canolaLoadByLoadTitleTransfers: [],
      isLoadSet: false,
      entityForNgrUpdate: '',
      sellerSideDrawerIsOpen: false,
      buyerSideDrawerIsOpen: false,
      invoiceConditionSideDrawerIsOpen: false,
      billToParties: [],
      customItems: [],
      grainLevies: [],
      carryItems: [],
      eprs: [],
      selectedItems: [],
      filteredContracts: [],
      openSideDrawer: false,
      displayBuyerBankForm: false,
      displaySellerBankForm: false,
      preview: false,
      sellerNgrAccountNumber: '',
      customItemId: 1,
      fetchingSellerContacts: false,
      fetchingBuyerContacts: false,
      fetchingSellerNgrs: false,
      fetchingBuyerNgrs: false,
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      fields: {
        note: {
          description: '',
          attachments: [],
          companyId: this.props.user.companyId,
        },
        paymentDueDate: {
          value: '',
          validators: [required()],
          errors: [],
        },
        identifier: {
          value: generateIdentifier('invoice'),
          validators: [required()],
          errors: [],
        },
        buyerNgrId: {
          value: '',
          validators: [],
          errors: [],
        },
        sellerNgrId: {
          value: '',
          validators: [required()],
          errors: [],
        },
        invoiceConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        sellerContact: {
          value: '',
          validators: [required()],
          errors: [],
        },
        buyerContact: {
          value: '',
          validators: [required()],
          errors: [],
        },
        selectedSellerParty: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        selectedBuyerParty: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        selectedContract: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        payee: cloneDeep(this.payeeFields),
        payer: cloneDeep(this.payerFields),
      },
      subTotal: 0.0,
      gst: 0.0,
      total: 0.0,
      isConfirmDialogOpen: false,
      isDefaultSelected: false,
      isChanged: false,
      fmTtTonnage: 0.0,
    };

    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSellerNgrChange = this.handleSellerNgrChange.bind(this);
    this.handleBuyerNgrChange = this.handleBuyerNgrChange.bind(this);
    this.handlePaymentDueDateChange = this.handlePaymentDueDateChange.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.handleConfirmDialogOpen = this.handleConfirmDialogOpen.bind(this);
    this.handleConfirmDialogOk = this.handleConfirmDialogOk.bind(this);
    this.handleConfirmDialogCancel = this.handleConfirmDialogCancel.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handlePreviewClose = this.handlePreviewClose.bind(this);
    this.handlePreviewSubmit = this.handlePreviewSubmit.bind(this);
    this.appendInvoiceConditions = this.appendInvoiceConditions.bind(this);
    this.getCarryItems = this.getCarryItems.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.getNgrs = this.getNgrs.bind(this);
    this.handleSellerChange = this.handleSellerChange.bind(this);
    this.handleBuyerChange = this.handleBuyerChange.bind(this);
    this.handleContractChange = this.handleContractChange.bind(this);
    this.checkDueDate = this.checkDueDate.bind(this);
    this.handleBuyerContactChange = this.handleBuyerContactChange.bind(this);
    this.handleSellerContactChange = this.handleSellerContactChange.bind(this);
    this.handlePayeeBankChange = this.handlePayeeBankChange.bind(this);
    this.handlePayerBankChange = this.handlePayerBankChange.bind(this);
    this.handleAccountChangeForEntity = this.handleAccountChangeForEntity.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.updateSelectedItem = this.updateSelectedItem.bind(this);
    this.openAddNgrSideDrawer = this.openAddNgrSideDrawer.bind(this);
    this.closeAddNgrSideDrawer = this.closeAddNgrSideDrawer.bind(this);
    this.openInvoiceConditionSideDrawer = this.openInvoiceConditionSideDrawer.bind(this);
    this.closeInvoiceConditionSideDrawer = this.closeInvoiceConditionSideDrawer.bind(this);
    this.openSellerContactSideDrawer = this.openSellerContactSideDrawer.bind(this);
    this.closeSellerContactSideDrawer = this.closeSellerContactSideDrawer.bind(this);
    this.openBuyerContactSideDrawer = this.openBuyerContactSideDrawer.bind(this);
    this.closeBuyerContactSideDrawer = this.closeBuyerContactSideDrawer.bind(this);
    this.getAddBuyerContactButton = this.getAddBuyerContactButton.bind(this);
    this.getAddSellerContactButton = this.getAddSellerContactButton.bind(this);
    this.getSellerCompanyDetails = this.getSellerCompanyDetails.bind(this);
    this.getBuyerCompanyDetails = this.getBuyerCompanyDetails.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.commonOpenSideDrawer = this.commonOpenSideDrawer.bind(this);
    this.sellerSideDrawer = this.sellerSideDrawer.bind(this);
    this.buyerSideDrawer = this.buyerSideDrawer.bind(this);
    this.updateSelectedItems = this.updateSelectedItems.bind(this);
    this.relatedItemToTitleTransfer = this.relatedItemToTitleTransfer.bind(this);
    this.relatedItemToFm = this.relatedItemToFm.bind(this);
    this.isMatchingGrainLevyOrEpr = this.isMatchingGrainLevyOrEpr.bind(this);
    this.areSame = this.areSame.bind(this);
    this.closeCustomEmailDialog = this.closeCustomEmailDialog.bind(this);
    this.handleSubTotal = this.handleSubTotal.bind(this);
    this.filterContractsByParties = this.filterContractsByParties.bind(this);
    this.getSelectedInvoiceCustomItems = this.getSelectedInvoiceCustomItems.bind(this);
    this.getSubmitData = this.getSubmitData.bind(this);
    this.handleShrinkageChange = this.handleShrinkageChange.bind(this);
    this.handleReceivalFeesChange = this.handleReceivalFeesChange.bind(this);
    this.handleCustomAdjustments = this.handleCustomAdjustments.bind(this);
    this.isChecked = this.isChecked.bind(this);

    this.fieldsOrder = [
      'contractNumber',
      'identifier',
      'paymentDueDate',
      'buyerContact',
      'buyerNgrId',
      'sellerContact',
      'sellerNgrId',
      'payeeBankName',
      'payee.accountName',
      'payee.accountNumber',
      'payee.bsbNumber',
    ];

    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.paymentDefault = false;
    this.selectedInvoiceId = get(this.props, 'match.params.invoice_id');
    this.selectedInvoiceAndContractFetched = false;
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(isFreightContractsFetched(false));
    dispatch(getContractsResponse([]));
    dispatch(isTitleTransfersFetch(false));
    dispatch(titleTransfersResponse([]));
    dispatch(movementCarryItemsResponse([]));
    dispatch(titleTransfersCarryItemsResponse([]));
    this.props.dispatch(getGeneralConditions([]));
    dispatch(invoiceDetails(null));
    this.closeNgrSideDrawer();
  }

  fetchCurrentUserCompany() {
    const companyId = this.props.user?.companyId
    if(companyId)
      APIService.companies(this.props.user.companyId).get().then(response => this.setState({currentUserCompany: response}))
  }

  async get_levy_epr_preferences(companyId) {
    const response = await APIService.companies()
          .appendToUrl(`${companyId}/payment-run-levy-epr-preferences/`)
          .get();
    return response;
  }

  async fetchLevyEprPreference(){
    const company = currentUserCompany();
    const response = await this.get_levy_epr_preferences(company.id.toString())
    this.setState({ levyEprPreferences: response });
  };

  async fetchLevyEprDefaults() {
    const { contract } = this.props;
    const response = await this.get_levy_epr_preferences(contract.buyer.company.id);
    this.setState({ levyEprDefaults: response });
  }

  isChecked(title) {
    const company = currentUserCompany()
    const seller = get(this.props.contract, 'seller.company')
    const buyer = get(this.props.contract, 'buyer.company')
    const defaultValue = get(this.state.levyEprDefaults, 'defaultValue', true) ? isCompanyGrower(seller) && !isCompanyGrower(buyer) : false
    const isCurrentUserCompanySeller = company.id === get(seller, 'id')
    const partyId = isCurrentUserCompanySeller ? get(buyer, 'id') : get(seller, 'id')
    let levyEprPreferences = get(this.state.levyEprPreferences, partyId.toString());
    if(title === 'Levy')
      return get(levyEprPreferences, 'levy', defaultValue);
    if(title === 'EPR')
      return get(levyEprPreferences, 'epr', defaultValue);
  }

  componentDidMount() {
    const { location } = this.props;
    const pathSegments = location.pathname.split('/');
    const isInEditMode = pathSegments.includes('edit');
    this.setState({ isInEditMode });

    this.fetchCurrentUserCompany()
    this.fetchLevyEprPreference()
    const { dispatch } = this.props;
    this.props.titleTransfersResponse();
    if(this.selectedInvoiceId && this.props.invoiceId != this.selectedInvoiceId) {
      dispatch(getInvoiceDetails(this.selectedInvoiceId));
    }
    dispatch(isLoading('invoiceFormFromContract'));
    const breadcrumbs = [{ text: 'Invoices', route: '/invoices' }, { text: 'New' }];
    dispatch(setBreadcrumbs(breadcrumbs));
    dispatch(setHeaderText('Invoices'));
    dispatch(loadInvoiceItems({ isFetchedTTs: false, isFetchedFMs: false }));
    dispatch(getGeneralConditions('invoice'))
    dispatch(getCompanyCompaniesMinimal(this.props.user.companyId, { include_parent_company: true }))
    dispatch(fetchBanks())

    if (includes(this.props.location.search, 'commodityContractId=')) {
      const queryParams = queryString.parse(this.props.location.search);
      const contractId = parseInt(get(queryParams, 'commodityContractId')) || get(this.props, 'selectedInvoice.raisedForId');
      dispatch(isTitleTransfersFetch(false));
      dispatch(isFreightContractsFetched(false));
      dispatch(getTitleTransfers(contractId, true, null, false, false, true));
      dispatch(getDetailedContracts('', contractId, '&status=manual_contract_complete_balanced&status=completed&for_invoice=true', null, true));
      if(contractId)
        dispatch(getSelectedContract(contractId, receiveContract, false, false, null, false, true, null, true));
      dispatch(getMovementsCarryItems(contractId, true));
      dispatch(getTitleTransferCarryItems(contractId, true));
      dispatch(getTitleTransferCanolaLoadCarryItems(contractId, true));
    } else if(!this.selectedInvoiceId) {
      this.setState({ removeLoader: true });
    }
  }

  getDefaultPaymentDueDate = () => {
    const { contract } = this.props;
    let date = this.getEditInvoicePaymentDueDateForForm()
    if (!date && contract)
      date = this.estimatedPaymentDueDate('YYYY-MM-DD')
    return date
  }

  getEditInvoicePaymentDueDateForForm = () => {
    const { selectedInvoice } = this.props
    return selectedInvoice?.paymentDueDate ? moment(selectedInvoice.paymentDueDate).format('YYYY-MM-DD') : ''
  }

  convertToDate(dateStr) {
    return moment(dateStr, getCountryFormats().date, true).toDate()
  }

  componentDidUpdate(prevProps, prevState) {
    if(!prevProps.selectedInvoice && this.props.selectedInvoice?.id) {
      if(this.props.selectedInvoice?.canAmend === false) {
        if(!this.state.amendNotAllowed)
          this.setState({amendNotAllowed: true})
        alertifyjs.alert('Operation Not Allowed', 'This invoice cannot be amended!', () => {window.location.hash = '/invoices/'})
      }
      else
        this.setState({amendNotAllowed: false}, () => this.handleContractChange(this.props.selectedInvoice.raisedForId))
    }
    const newState = { ...this.state };
    let titleTransfersAndLoads = this.props.contract ? this.getTitleTransfers() : [];
    let titleTransfers = titleTransfersAndLoads[0];
    let loads = titleTransfersAndLoads[1];
    let totalFromItems = 0;
    let freightMovements = this.props.contract ? this.getFreightMovements(newState) : [];
    let blendedGradeLoadsAndChemicalApplicationItems = get(this.props.contract, 'isBlended') ? this.getBlendedGradeLoads() : [];
    let blendedGradeLoads = get(blendedGradeLoadsAndChemicalApplicationItems, '0') || [];
    let chemicalApplicationItems = get(blendedGradeLoadsAndChemicalApplicationItems, '1') || [];
    this.updateNgrChanges(prevProps, newState);
    let carryItems = uniqBy([...this.getCarryItems(), ...this.getInvoicedCarryItems()], 'description');
    let itemsForInvoice = filter(filter(
      this.state.selectedItems,
      selectedItem => !includes([GRAIN_LEVY_ITEM_TYPE, CARRY_ITEM_TYPE], selectedItem.itemType)
    ), 'invoice' ).concat(filter(carryItems, carry => carry.invoice));
    let itemsForTonnage = filter(filter(
      this.state.selectedItems,
      selectedItem => !includes([CUSTOM_ITEM_TYPE, CARRY_ITEM_TYPE, GRAIN_LEVY_ITEM_TYPE, EPR_ITEM_TYPE], selectedItem.itemType)
    ), 'invoice' );
    let subTotal = reduce(
      itemsForInvoice,
      function (sum, item) {
        return sum + item.subTotal;
      },
      0,
    );
    let gst = reduce(
      itemsForInvoice,
      function (sum, item) {
        return sum + (item.gst === 'N/A' ? 0 : item.gst);
      },
      0,
    );
    let fmTtTonnage = reduce(
      itemsForTonnage,
      function (sum, item) {
        return sum + item.tonnage;
      },
      0,
    );
    let grainLeviesForTotal = filter(this.state.selectedItems, selectedItem => selectedItem.itemType === GRAIN_LEVY_ITEM_TYPE && selectedItem.invoice);
    let grainLevyTotal = reduce(
      grainLeviesForTotal,
      function (sum, item) {
        return sum + parseFloat(item.total);
      },
      0,
    );
    if (!prevProps.contract && this.props.contract) {
      newState.fields.paymentDueDate.value = this.getDefaultPaymentDueDate()
      newState.levyEprDefaults = this.fetchLevyEprDefaults()
    }
    if (this.props.selectedInvoice && !prevProps.selectedInvoice) {
      newState.fields.identifier.value = get(this.props.selectedInvoice, 'identifier');
      newState.fields.paymentDueDate.value = this.getDefaultPaymentDueDate()
    }

    if (this.props.contract) {
      newState.removeLoader = true;
      this._setCurrentUserAsContactIfApplicable(get(this.props.contract, 'seller.company'), 'sellerContact', newState);
      this._setCurrentUserAsContactIfApplicable(get(this.props.contract, 'buyer.company'), 'buyerContact', newState);
      newState.subTotal = (parseFloat(subTotal) + grainLevyTotal).toFixed(2);
      newState.gst = parseFloat(gst).toFixed(2);
      totalFromItems = parseFloat(newState.subTotal) + parseFloat(newState.gst);
      newState.total = parseFloat(totalFromItems).toFixed(2);
      newState.fmTtTonnage = parseFloat(fmTtTonnage).toFixed(2);
      if (isEmpty(this.state.sellerNgrs) && !this.state.fetchingSellerNgrs) {
        newState.fetchingSellerNgrs = true;
        this.getNgrs(this.props.contract.seller.companyId, undefined, 'sellerNgrs');
      }
      if (isEmpty(this.state.buyerNgrs) && !this.state.fetchingBuyerNgrs) {
        newState.fetchingBuyerNgrs = true;
        this.getNgrs(this.props.contract.buyer.companyId, undefined, 'buyerNgrs');
      }
      if (isEmpty(this.state.sellerEmployees) && !this.state.fetchingSellerContacts) {
        newState.fetchingSellerContacts = true;
        this.getCompanyEmployees(this.props.contract.seller.company, 'seller', null, get(this.props, 'contract.seller.representedById'));
      }
      if (isEmpty(this.state.buyerEmployees) && !this.state.fetchingBuyerContacts) {
        newState.fetchingBuyerContacts = true;
        this.getCompanyEmployees(this.props.contract.buyer.company, 'buyer', null, get(this.props, 'contract.buyer.representedById'));
      }
    }
    if (isEmpty(this.state.billToParties)) {
      newState.billToParties = newState.billToParties.concat(this.props.parties);
    }
    newState.titleTransfers = titleTransfers;
    newState.freightMovements = freightMovements;
    newState.loads = loads;
    newState.carryItems = carryItems;
    newState.blendedGradeLoads = blendedGradeLoads;
    newState.chemicalApplicationItems = chemicalApplicationItems;
    if (!isEqual(this.props.serverErrors, prevProps.serverErrors)) {
      forEach(this.props.serverErrors, (value, key) => {
        if (has(newState.fields, key)) {
          newState.fields[key].errors = value;
        }
      });
      if (!isEmpty(this.props.serverErrors)) {
        newState.preview = false;
      }
    }

    if (!isEqual(this.state, newState)) {
      this.fetchLevyEprPreference();
      let selectedItems = this.state.selectedItems.filter(obj => !includes([TITLE_TRANSFER_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE, LOAD_ITEM_TYPE], obj.itemType));
      let selectedTts = this.state.selectedItems.filter(obj => obj.itemType === TITLE_TRANSFER_ITEM_TYPE);
      let selectedLoads = this.state.selectedItems.filter(obj => obj.itemType === LOAD_ITEM_TYPE && !obj.isBlended);
      let selectedFms = this.state.selectedItems.filter(obj => obj.itemType === FREIGHT_CONTRACT_ITEM_TYPE);
      let selectedBlendedGradeLoads = this.state.selectedItems.filter(obj => obj.itemType === LOAD_ITEM_TYPE && obj.isBlended && !obj.isChemicalItem);
      let selectedChemicalApplicationItems = this.state.selectedItems.filter(obj => obj.itemType === LOAD_ITEM_TYPE && obj.isBlended && obj.isChemicalItem);
      let updatedSelectedTts = [];
      let updatedSelectedLoads = [];
      let updatedSelectedFms = [];
      let updatedBlendedGradeLoads = [];
      let updatedChemicalApplicationItems = [];
      const selectedTtItemIds = map(selectedTts, 'itemId');
      updatedSelectedTts = filter(titleTransfers, titleTransfer => includes(selectedTtItemIds, titleTransfer.itemId));
      const selectedLoadItemIds = map(selectedLoads, 'itemId')
      updatedSelectedLoads = filter(loads, load => includes(selectedLoadItemIds, load.itemId));
      const selectedFmItemIds = map(selectedFms, 'itemId');
      updatedSelectedFms = filter(freightMovements, freightMovement => includes(selectedFmItemIds, freightMovement.itemId));
      const selectedBlendedGradeLoadItemIds = map(selectedBlendedGradeLoads, 'itemId');
      updatedBlendedGradeLoads = filter(blendedGradeLoads, blendedGradeLoad => includes(selectedBlendedGradeLoadItemIds, blendedGradeLoad.itemId));
      const selectedChemicalApplicationItemIds = map(selectedChemicalApplicationItems, 'itemId');
      updatedChemicalApplicationItems = filter(chemicalApplicationItems, chemicalApplicationItem => includes(selectedChemicalApplicationItemIds, chemicalApplicationItem.itemId));
      newState.selectedItems = [...selectedItems, ...updatedSelectedTts, ...updatedSelectedFms, ...updatedSelectedLoads, ...updatedBlendedGradeLoads, ...updatedChemicalApplicationItems];
      this.setState(newState);
    } else if (
      get(prevState, 'freightMovements.length') !== get(newState, 'freightMovements.length') ||
      get(prevState, 'titleTransfers.length') !== get(newState, 'titleTransfers.length')
    ) {
      this.setState(newState);
    }

    const selectedInvoiceConditionsValue = (this.props.selectedInvoice && get(this.props.selectedInvoice, 'invoiceConditions')) || ""
    const oldInvoiceConditionsValue = newState.fields.invoiceConditions.value || ""

    if(this.state.isInEditMode){
      if (
        !oldInvoiceConditionsValue &&
        selectedInvoiceConditionsValue &&
        !this.state.isDefaultGeneralConditionApplied
      ) {
        newState.fields.invoiceConditions.value = oldInvoiceConditionsValue + selectedInvoiceConditionsValue;
        newState.isDefaultGeneralConditionApplied = true;
      }
    }
    else {
      if (!isEmpty(this.props.generalConditionsAll) && !this.selectedContract && !this.state.isDefaultGeneralConditionApplied) {
        newState.fields.invoiceConditions.value = map(filter(this.props.generalConditionsAll, { default: true, module: 'invoice' }), 'details').join('\n');
        newState.isDefaultGeneralConditionApplied = true;
      }
    }

    if(newState.fields.invoiceConditions.value != oldInvoiceConditionsValue){
      this.setState(newState)
    }

    if (
      this.state.removeLoader &&
      this.props.isFreightMovementFetched &&
      this.props.isTitleTransferFetched &&
      this.props.isFetchedTTs &&
      this.props.isFetchedFMs
    ) {
        this.setUpDefaultStateSelected();
    }

    if (this.props.selectedInvoice && this.props.contract && !isEmpty(this.state.buyerNgrs) && !isEmpty(this.state.sellerNgrs) &&
      !isEmpty(this.state.buyerEmployees) && !isEmpty(this.state.sellerEmployees) && !this.selectedInvoiceAndContractFetched) {
      this.setNGRAndContactFromSelectedInvoice();
      this.getSelectedInvoiceCustomItems();
      this.selectedInvoiceAndContractFetched = true;
    }
  }

  setNGRAndContactFromSelectedInvoice() {
    const newState = { ...this.state };
    newState.fields.buyerContact.value = find(this.state.buyerEmployees, {id: get(this.props.selectedInvoice, 'payer.contactId')});
    newState.fields.buyerNgrId.value = get(this.props.selectedInvoice, 'payer.ngrId');
    newState.fields.sellerContact.value = find(this.state.sellerEmployees, {id: get(this.props.selectedInvoice, 'payee.contactId')});
    newState.fields.sellerNgrId.value = get(this.props.selectedInvoice, 'payee.ngrId');
    this.setState(newState, () => {
      if (this.state.fields.sellerNgrId.value)
        this.updateSellerNgrDetails('sellerNgrs');
      if (this.state.fields.sellerContact.value)
        this.updateSelectedContactForParty(this.state.fields.sellerContact.value, 'seller');
      if (this.state.fields.buyerContact.value)
        this.updateSelectedContactForParty(this.state.fields.buyerContact.value, 'buyer');
    });
  }

  openCustomEmailDialog() {
    this.setState({ showCustomEmailDialog: true });
  }

  closeCustomEmailDialog(data, justClose) {
    this.gotOncePartyContacts = false;
    if (justClose)
      this.setState({ showCustomEmailDialog: false });
    else if (this.state.showCustomEmailDialog) {
      let communicationData = {};
      if (!this.selectedInvoiceId) {
        if (data) {
          delete data.body;
          communicationData['communication'] = data;
        }
      } else {
      communicationData = this.getSubmitData();
      if (communicationData) {
        communicationData['communication'] = data;
      }}
      this.setState({ showCustomEmailDialog: false }, () => {
        if (this.selectedInvoiceId)
          this.props.editInvoice(communicationData, this.selectedInvoiceId, this.props.contract.id,);
        else
          this.props.generateInvoice(this.props.invoiceId, this.props.contract.id, communicationData);
      });
    }
  }

  setDefaultGeneralCondition(condition) {
    this.closeInvoiceConditionSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  fetchNgrsAndContactsAsPerNewContract(newState) {
    newState.fetchingSellerContacts = false;
    newState.fetchingBuyerContacts = false;
    newState.fetchingBuyerNgrs = false;
    newState.fetchingSellerNgrs = false;
  }

  updateNgrChanges(prevProps, newState) {
    if (this.props.updatedNgr && prevProps.updatedNgr !== this.props.updatedNgr) {
      this.updateSellerBuyerNgr(newState, this.props.updatedNgr);
    }

    if (this.props.updatedBillToNgr && prevProps.updatedBillToNgr !== this.props.updatedBillToNgr) {
      this.updateSellerBuyerNgr(newState, this.props.updatedBillToNgr);
    }
  }

  updateSellerBuyerNgr(newState, updatedNgr) {
    if (updatedNgr.id === get(this.state.fields, 'sellerNgrId.value') && !newState.fetchingSellerNgrs) {
      const selectedSellerParty = this.state.fields.selectedSellerParty.value || get(this.props.contract, 'seller');
      newState.fetchingSellerNgrs = true;
      this.updateNgrsForParty(selectedSellerParty, 'sellerNgrs');
    }
    if (updatedNgr.id === this.state.fields.buyerNgrId.value && !newState.fetchingBuyerNgrs) {
      const selectedBuyerParty = this.state.fields.selectedBuyerParty.value || get(this.props.contract, 'buyer');
      newState.fetchingBuyerNgrs = true;
      this.updateNgrsForParty(selectedBuyerParty, 'buyerNgrs');
    }
  }

  updateNgrsForParty(selectedParty, ngrsKey) {
    let partyId = get(selectedParty, 'company.id') || get(selectedParty, 'id');
    this.getNgrs(partyId, undefined, ngrsKey);
  }

  handleConfirmDialogCancel() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false,
    });
  }

  handleConfirmDialogOk() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false,
    });
    if (this.props.contract) window.location.hash = 'contracts/' + this.props.contract.id + '/invoices';
    else window.location.hash = '/invoices';
  }

  handleConfirmDialogOpen() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: true,
    });
  }

  handlePreviewClick() {
    this.setState({
      ...this.state,
      preview: true,
    });
  }

  handleBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
  }

  handlePreviewClose() {
    this.setState({
      ...this.state,
      preview: false,
    });
    this.props.deleteInvoice(this.props.invoiceId);
  }

  openNgrSideDrawer(selectedEntity, ngrs, ngrId) {
    const { dispatch } = this.props;
    this.commonOpenSideDrawer();
    dispatch(receiveCompanyNgrs(ngrs));
    dispatch(clickEditCompanyNgr(ngrId));
    this.setState({
      entityForNgrUpdate: selectedEntity.company || selectedEntity,
      fetchingBuyerNgrs: false,
      fetchingSellerNgrs: false,
    });
  }

  closeNgrSideDrawer() {
    const { dispatch } = this.props;
    dispatch(cancelEditNgr());
    dispatch(cancelEditCompanyNgr());
  }

  getCompanyEmployees = (party, type, contactId = '', representingCompanyId) => {
    const queryParams = representingCompanyId ? { representing_company_id: representingCompanyId } : {};
    type = this.getPartyType(type, party);
    APIService.contracts()
      .companies(party.id)
      .contacts()
      .get(this.props.token, {}, queryParams)
      .then(employees => {
        this.setEmployeesAndContactForParty(type, employees, contactId);
      });
  };

  setEmployeesAndContactForParty(type, employees, contactId) {
    if (type) {
      this.setState({ [type + 'Employees']: employees }, () => {
        setTimeout(() => {
          this.updateSelectedContactForParty(contactId, type);
        }, 500);
      });
    }
  }

  getPartyType(type, party) {
    if (!type) {
      if (isEqual(party, this.getSelectedSellerCompany())) {
        type = 'seller';
      } else if (isEqual(party, this.getSelectedBuyerCompany())) {
        type = 'buyer';
      }
    }
    return type;
  }

  updateSelectedContactForParty(contactId, type) {
    if (contactId) {
      if (type === 'seller') {
        this.handleSellerContactChange(contactId);
      } else if (type === 'buyer') {
        this.handleBuyerContactChange(contactId);
      }
    }
  }

  getSelectedSellerCompany() {
    const party = this.state.fields.selectedSellerParty.value || get(this.props.contract, 'seller');
    return get(party, 'company') || party;
  }

  getSelectedBuyerCompany() {
    const party = this.state.fields.selectedBuyerParty.value || get(this.props.contract, 'buyer');
    return get(party, 'company') || party;
  }

  handlePayeeBankChange = () => value => {
    const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
    const newState = { ...this.state };
    newState.fields.payee.bankName.value = selectedBank.name;
    newState.fields.payee.bankName.id = selectedBank.id;
    this.setState(newState, () => this.setPartyFieldErrors('payee.bankName'));
  };

  handlePayerBankChange = () => value => {
    const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
    const newState = { ...this.state };
    newState.fields.payer.bankName.value = selectedBank.name;
    newState.fields.payer.bankName.id = selectedBank.id;
    this.setState(newState);
  };

  setCurrentUserAsContactIfApplicable(company, fieldToSet) {
    const newState = { ...this.state };
    const result = this._setCurrentUserAsContactIfApplicable(company, fieldToSet, newState);
    if (result) this.setState(newState);
  }

  _setCurrentUserAsContactIfApplicable(company, fieldToSet, newState) {
    const { user } = this.props;
    if (get(newState.fields, fieldToSet + '.value')) return false;

    if (get(user, 'companyId') === get(company, 'id')) {
      set(newState.fields, fieldToSet + '.value', user);
      return true;
    }
    return false;
  }

  handleSellerChange(value, targetId, chosenItem) {
    const newState = { ...this.state };
    newState.fields.selectedSellerParty.value = chosenItem;
    this.clearValuesForParty(newState, chosenItem, 'seller');
    this.setState(newState, () => {
      this.setFieldErrors('selectedSellerParty');
      this.setCurrentUserAsContactIfApplicable(chosenItem, 'sellerContact');
    });
  }

  handleBuyerChange(value, targetId, chosenItem) {
    const newState = { ...this.state };
    newState.fields.selectedBuyerParty.value = chosenItem;
    this.clearValuesForParty(newState, chosenItem, 'buyer');
    this.setState(newState, () => {
      this.setFieldErrors('selectedBuyerParty');
      this.setCurrentUserAsContactIfApplicable(chosenItem, 'buyerContact');
    });
  }

  clearValuesForParty(newState, chosenItem, partyType) {
    newState.filteredContracts = this.filterContractsByParties(chosenItem, partyType);
    newState.sellerNgrs = [];
    newState.buyerNgrs = [];
    newState.fields['sellerNgrId'].errors = [];
    newState.fields.paymentDueDate.value = '';
    newState.fields.paymentDueDate.errors = [];
    newState.fields.selectedContract.value = '';
    this.paymentDefault = false;
    if (chosenItem) this.getCompanyEmployees(chosenItem, partyType);
    this.props.dispatch(clearContract());
  }

  filterContractsByParties(selectedParty, party) {
    let self = this;
    let sellerCompanyId = party === 'seller' ? get(selectedParty, 'companyId') : get(this.state.fields.selectedSellerParty, 'value.companyId', get(this.state.fields.selectedSellerParty, 'value.id'));
    let buyerCompanyId = party === 'seller' ? get(this.state.fields.selectedBuyerParty, 'value.companyId',get(this.state.fields.selectedBuyerParty, 'value.id' )) : get(selectedParty, 'companyId');
    if (selectedParty && sellerCompanyId && buyerCompanyId) {
      let contractsService = APIService.contracts();
      contractsService.appendToUrl(`minimalistic/?seller_company_id=${sellerCompanyId}&buyer_company_id=${buyerCompanyId}&get_for=commodity_contract_invoice`);
      contractsService
        .get(this.props.token, {'REFERER-DO-NOT-CONVERT-UNIT': true}, null)
        .then(contracts => self.setState({filteredContracts: filter(contracts, 'canCreateCommodityContractInvoice')}));
    }
    return [];

  }

  handleContractChange(contractId) {
    const { dispatch } = this.props;
    if (!this.state.fields.selectedContract.value) {
      this.populateFields(contractId, dispatch);
      return;
    }
    if (this.state.fields.selectedContract.value && this.state.fields.selectedContract.value != contractId) {
      alertifyjs
        .confirm(
          'Warning',
          'Already filled values in the Invoice will be reset. Do you wish to continue?',
          () => this.populateFields(contractId, dispatch),
          () => { },
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    }
  }

  populateFields(contractId, dispatch) {
    const newState = { ...this.state };
    this.fetchNgrsAndContactsAsPerNewContract(newState);
    newState.fields.selectedContract.value = contractId;
    newState.customItems = [];
    newState.selectedItems = [];
    newState.grainLevies = [];
    newState.eprs = [];
    this.paymentDefault = false;
    newState.fields.paymentDueDate.value = '';
    newState.fields.paymentDueDate.errors = [];
    newState.ngrSideDrawerIsOpen = false;
    this.setState(newState, () => this.setFieldErrors('selectedContract'));
    dispatch(isLoading('nonExistentComponent'));
    if (contractId) {
      dispatch(loadInvoiceItems({ isFetchedTTs: false, isFetchedFMs: false }));
      dispatch(getTitleTransfers(contractId, true, null, false, null, true));
      dispatch(getDetailedContracts('', contractId, '&status=manual_contract_complete_balanced&status=completed&for_invoice=true', null, true));
      dispatch(getSelectedContract(contractId, receiveContract, false, false, false, true, true, null, true));
      dispatch(getMovementsCarryItems(contractId, true));
      dispatch(getTitleTransferCarryItems(contractId, true));
      dispatch(getTitleTransferCanolaLoadCarryItems(contractId, true));
    } else {
      dispatch(forceStopLoader());
    }
  }

  appendInvoiceConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.invoiceConditions.value)){
      newState.fields.invoiceConditions.value = newState.selectedGeneralConditionText
    }
    else {
      newState.fields.invoiceConditions.value = newState.fields.invoiceConditions.value + '\n' + newState.selectedGeneralConditionText;
    }

    this.setState(newState);
  }

  applyReceivalFeesOnPrice(pricePerMt, receivalFees) {
    return parseFloat(parseFloat(pricePerMt) + parseFloat(receivalFees)).toFixed(2);
  }

  applyShrinkageOnPrice(pricePerMT, shrinkage) {
    return parseFloat(parseFloat(pricePerMT) / (1- (parseFloat(shrinkage) / 100))).toFixed(5);
  }

  applyShrinkageAndReceivalOnPrice(pricePerMT, receivalFees, shrinkage) {
    if (receivalFees) {
      pricePerMT = this.applyReceivalFeesOnPrice(pricePerMT, receivalFees);
    }
    if (shrinkage) {
      pricePerMT = this.applyShrinkageOnPrice(pricePerMT, shrinkage);
    }
    return pricePerMT;
  }

  getTitleTransfers() {
    let titleTransfers = [];
    let loads = [];
    let total = 0;
    this.props.titleTransfers.sort((a, b) => new Date(a.processOn).getTime() - new Date(b.processOn).getTime());
    let tts = [];
    let canolaLoadByLoadTitleTransfers = [];
    if (this.selectedInvoiceId) {
      tts = this.props.titleTransfers.filter(item => get(item, 'invoiceId') === parseInt(this.selectedInvoiceId) || get(item, 'status') === 'completed');
    }
    else {
      tts = filter(this.props.titleTransfers, { status: 'completed' });
    }
    tts.forEach(tt => {
      let locationDifferential = this.getLDFromContract(get(tt, 'storage.farmId'));
      if (!isNumber(locationDifferential)) locationDifferential = this.getDefaultLD(tt, 'storage.farm') || 0;
      let pricePerMT = this.priceForOthers(tt.grade.id, locationDifferential).toFixed(2);
      let ttSubTotal = 0
      let adjustmentDetails = {};
      let customAdjustments = {};
      let adjustedPrice = undefined;
      let stateTt = find(this.state.titleTransfers, {itemId: tt.id});
      let shrinkage = get(stateTt, 'shrinkage') || get(tt, 'shrinkage') || '0';
      let receivalFees = get(stateTt, 'receivalFees') || get(tt, 'receivalFees') || '0';
      let isLoadByLoad = false;
      const titleTransfer = find(this.state.titleTransfers, { itemId: tt.id });
      let isInvoiced = tt.status === 'invoiced';
      let invoice = this.selectedInvoiceId ? get(titleTransfer, 'invoice', isInvoiced) :
      get(titleTransfer, 'invoice', this.state.isDefaultSelected);
      let roundOffRate = true;
      if (this.props.contract && this.props.contract.commodityId === COMMODITIES.CANOLA) {
        let canolaLoadPricePerMT;
        if (tt.canolaLoads !== null && !isEmpty(tt.canolaLoads) && !isEmpty(tt.outloads)) {
          isLoadByLoad = true;
          tt.outloads.forEach(load => {
            let loadState = find(this.state.loads, {itemId: load.id});
            shrinkage = get(loadState, 'shrinkage') || shrinkage;
            receivalFees = get(loadState, 'receivalFees') || receivalFees;
            let loadSpecs = get(load, 'specs');
            adjustmentDetails = this.getAdjustmentDetails(load.id, LOAD_ITEM_TYPE, tt.grade.id, locationDifferential, parseFloat(pricePerMT), shrinkage, receivalFees, true, true, loadSpecs.impu, loadSpecs.coil);
            canolaLoadPricePerMT = parseFloat(this.priceForCanola(pricePerMT, loadSpecs.impu, loadSpecs.coil)).toFixed(2);
            adjustmentDetails['exShrinkageReceivalPrice'] = canolaLoadPricePerMT;
            if (this.isPricePointTrack()) {
              canolaLoadPricePerMT = this.applyShrinkageAndReceivalOnPrice(canolaLoadPricePerMT, receivalFees, shrinkage);
              adjustmentDetails['totalPricePerMt'] = canolaLoadPricePerMT;
              roundOffRate = !shrinkage;
            }
            adjustmentDetails['priceWithoutCustomAdjustment'] = parseFloat(canolaLoadPricePerMT);
            customAdjustments = get(loadState, 'customAdjustments') || get(adjustmentDetails, 'customAdjustments');
            if (customAdjustments) {
              let totalCustomAdjustment = customAdjustments.reduce((acc, item) => acc + item.value, 0);
              canolaLoadPricePerMT = parseFloat(canolaLoadPricePerMT) + totalCustomAdjustment;
              adjustmentDetails['customAdjustments'] = customAdjustments;
              adjustmentDetails['totalPricePerMt'] = parseFloat(canolaLoadPricePerMT);
            }
            let loadSubtotal = +(load.tonnage * canolaLoadPricePerMT).toFixed(2);
            ttSubTotal += loadSubtotal;
            let loadGst = this.state.gstApplicable ? +(loadSubtotal * this.state.gstRate).toFixed(2) : 0;
            let loadTotal = loadSubtotal + loadGst;
            let canolaLoad = find(this.state.loads, {itemId: load.id});
            let loadInvoice = get(canolaLoad, 'invoice', isInvoiced && this.selectedInvoiceId);
            adjustedPrice = (adjustmentDetails['totalPricePerMt'] - get(this.props, 'contract.price'));
            loads.push({
              tonnage: load.tonnage,
              subTotal: loadSubtotal,
              rate: parseFloat(canolaLoadPricePerMT),
              gst: this.state.gstApplicable ? loadGst : 0,
              total: loadTotal,
              itemType: 'load',
              itemId: load.id,
              description: load.description,
              date: moment(tt.processOn).format(this.state.countryFormats.date),
              identifier: load.loadIdentifier,
              invoice: loadInvoice,
              paymentDate: moment(tt.processOn),
              paymentDueDate: this.getEstimatedPaymentDueDate(tt.processOn),
              contractPrice: get(this.props, 'contract.price'),
              titleTransferId: tt.id,
              adjustmentDetails: adjustmentDetails,
              adjustedPrice: adjustedPrice,
              shrinkage: shrinkage,
              receivalFees: receivalFees,
              roundOffRate: roundOffRate,
              customAdjustments: customAdjustments,
            });
          });
        }
        else {
          adjustmentDetails = this.getAdjustmentDetails(tt.id, 'titletransfer', tt.grade.id, locationDifferential, parseFloat(pricePerMT), shrinkage, receivalFees, true, false, tt.impu, tt.coil);
          pricePerMT = parseFloat(this.priceForCanola(pricePerMT, tt.impu, tt.coil)).toFixed(2);
          if (this.isPricePointTrack()) {
            let shrinkageReceivalPricePerMT = this.applyShrinkageAndReceivalOnPrice(adjustmentDetails['totalPricePerMt'], receivalFees, shrinkage);
            adjustmentDetails['totalPricePerMt'] = shrinkageReceivalPricePerMT;
            adjustmentDetails['exShrinkageReceivalPrice'] = pricePerMT
            pricePerMT = this.applyShrinkageAndReceivalOnPrice(pricePerMT, receivalFees, shrinkage);
            roundOffRate = !shrinkage;
          }
          adjustmentDetails['priceWithoutCustomAdjustment'] = parseFloat(pricePerMT);
          customAdjustments = get(stateTt, 'customAdjustments') || get(adjustmentDetails, 'customAdjustments');
          if (customAdjustments) {
            let totalCustomAdjustment = customAdjustments.reduce((acc, item) => acc + item.value, 0);
            pricePerMT = parseFloat(pricePerMT) + totalCustomAdjustment;
            adjustmentDetails['customAdjustments'] = customAdjustments;
            adjustmentDetails['totalPricePerMt'] = parseFloat(pricePerMT);
          }
          adjustedPrice = parseFloat(pricePerMT) - parseFloat(get(this.props, 'contract.price'));
          ttSubTotal = +(tt.inferredTonnage * pricePerMT).toFixed(2);
        }
      }
      else {
        if (isEmpty(adjustmentDetails)) {
          adjustmentDetails = this.getAdjustmentDetails(tt.id, 'titletransfer', tt.grade.id, locationDifferential, parseFloat(pricePerMT), shrinkage, receivalFees, false, false, null, null);
        }
        if (this.isPricePointTrack() && this.props.contract.commodityId !== COMMODITIES.CANOLA) {
          adjustmentDetails['exShrinkageReceivalPrice'] = pricePerMT;
          pricePerMT = this.applyShrinkageAndReceivalOnPrice(pricePerMT, receivalFees, shrinkage);
          adjustmentDetails['totalPricePerMt'] = pricePerMT;
          roundOffRate = !shrinkage;
        }
        adjustmentDetails['priceWithoutCustomAdjustment'] = parseFloat(pricePerMT);
        customAdjustments = get(stateTt, 'customAdjustments') || get(adjustmentDetails, 'customAdjustments');
        if (customAdjustments) {
          let totalCustomAdjustment = customAdjustments.reduce((acc, item) => acc + item.value, 0);
          pricePerMT = parseFloat(pricePerMT) + totalCustomAdjustment;
          adjustmentDetails['customAdjustments'] = customAdjustments;
          adjustmentDetails['totalPricePerMt'] = parseFloat(pricePerMT);
        }
        if (adjustedPrice === undefined) {
          adjustedPrice = parseFloat(pricePerMT) - parseFloat(get(this.props, 'contract.price'));
        }
        ttSubTotal = +(tt.inferredTonnage * pricePerMT).toFixed(2);
      }
      total = +(ttSubTotal * (this.state.gstApplicable ? (1 + this.state.gstRate) : 1)).toFixed(2);
      if (this.props.selectedInvoice && !invoice) {
        let invoiceTts = get(this.props.selectedInvoice, 'titleTransfers');
        let invoiceTt = find(invoiceTts, {itemId: tt.id});
        if (!invoiceTt){
          invoice = false;
        }
      }
      let ttInvoiceItem = {
        tonnage: tt.inferredTonnage,
        subTotal: ttSubTotal,
        rate: parseFloat(pricePerMT),
        gst: this.state.gstApplicable ? +(ttSubTotal * this.state.gstRate).toFixed(2) : 0,
        total: total,
        itemType: 'titletransfer',
        itemId: tt.id,
        description: tt.description,
        date: moment(tt.processOn).format(this.state.countryFormats.date),
        identifier: tt.identifier,
        invoice: invoice,
        paymentDate: moment(tt.processOn),
        paymentDueDate: this.getEstimatedPaymentDueDate(tt.processOn),
        adjustmentDetails: isLoadByLoad ? null : adjustmentDetails,
        adjustedPrice: roundOffRate ? parseFloat(adjustedPrice.toFixed(2)) : parseFloat(adjustedPrice.toFixed(5)),
        contractPrice: get(this.props, 'contract.price'),
        shrinkage: shrinkage,
        receivalFees: receivalFees,
        roundOffRate: roundOffRate,
        customAdjustments: customAdjustments,
      };
      if (this.props.contract && this.props.contract.commodityId === COMMODITIES.CANOLA && tt.canolaLoads !== null && !isEmpty(tt.canolaLoads)) {
        delete ttInvoiceItem.rate;
      }
      if (!isLoadByLoad)
        titleTransfers.push(ttInvoiceItem);
      else
        canolaLoadByLoadTitleTransfers.push(ttInvoiceItem);
    });
    const sortedTTs = titleTransfers.sort((a, b) => this.convertToDate(a.paymentDueDate) - this.convertToDate(b.paymentDueDate))
    const sortedCanolaLoadByLoadTTs = canolaLoadByLoadTitleTransfers.sort((a, b) => this.convertToDate(a.paymentDueDate) - this.convertToDate(b.paymentDueDate))
    if (!isEqual(this.state.loads, loads)) {
      const newState = {...this.state};
      newState.loads = loads;
      newState.canolaLoadByLoadTitleTransfers = [...newState.canolaLoadByLoadTitleTransfers, ...sortedCanolaLoadByLoadTTs];
      this.setState(newState);
    }
    if (this.props.isTitleTransferFetched && !this.props.isFetchedTTs) {
      this.props.dispatch(loadInvoiceItems({ isFetchedTTs: true }));
      this.setState({titleTransfers: sortedTTs});
    }
    return [sortedTTs, loads];
  }

  isBuyerRcti() {
    return get(this.props, 'contract.administration.invoicing') === 'Buyer RCTI';
  }

  getCarryItems() {
    let titleTransferIds = [];
    let freightMovementIds = [];
    let loadIds = [];
    forEach(this.state.selectedItems.map(e => {
      if (e.itemType === TITLE_TRANSFER_ITEM_TYPE && e.itemId) {
        titleTransferIds.push(e.itemId);
      }
      if (e.itemType === FREIGHT_CONTRACT_ITEM_TYPE && e.itemId) {
        freightMovementIds.push(e.itemId);
      }
      if (e.itemType === LOAD_ITEM_TYPE && e.itemId)
        loadIds.push(e.itemId);
    }));
    let carryItems = filter(
      this.props.freightMovementCarryItems.concat(this.props.titleTransfersCarryItems, this.props.titleTransfersCanolaLoadCarryItems),
      item =>
        (item.titleTransferId !== undefined && titleTransferIds.includes(item.titleTransferId)) ||
        (item.freightMovementId !== undefined && freightMovementIds.includes(item.freightMovementId)) ||
        (item.loadId !== undefined && loadIds.includes(item.loadId)),
    );
    carryItems = uniqBy(carryItems, 'description')
    carryItems.sort((a, b) => this.convertToDate(a.deliveryDatetime) - this.convertToDate(b.deliveryDatetime));
    forEach(carryItems, item => {
      let selectedCarry = null;
      if (item.freightMovementId)
        selectedCarry = find(this.state.carryItems, { freightMovementId: item.freightMovementId });
      else if (item.loadId)
        selectedCarry = find(this.state.carryItems, { loadId: item.loadId });
      else if (item.titleTransferId)
        selectedCarry = find(this.state.carryItems, { titleTransferId: item.titleTransferId });
      item.gst = this.state.gstApplicable ? (item.subTotal * this.state.gstRate) : 0;
      item.total = item.subTotal + item.gst;
      item.itemType = CARRY_ITEM_TYPE;
      item.invoice = get(selectedCarry, 'invoice', item.total && this.state.isDefaultSelected);
      delete item['tonnageDisplayValue'];
    });
    return uniqBy(carryItems, 'description');
  }

  getInvoicedCarryItems() {
    let carryItems = [];
    let titleTransferIds = [];
    let freightMovementIds = [];
    let loadIds = []
    forEach(this.state.selectedItems.map(e => {
      if (e.itemType === TITLE_TRANSFER_ITEM_TYPE && e.itemId) {
        titleTransferIds.push(e.itemId);
      }
      if (e.itemType === FREIGHT_CONTRACT_ITEM_TYPE && e.itemId) {
        freightMovementIds.push(e.itemId);
      }
      if (e.itemType === LOAD_ITEM_TYPE && e.itemId)
        loadIds.push(e.itemId);
    }));

    if (this.props.selectedInvoice && this.props.selectedInvoice.carry) {
      forEach(this.props.selectedInvoice.carry, (item) => {
        let carryItem = {};
        carryItem.description = item.description;
        carryItem.gst = this.state.gstApplicable ? returnFloatFromString(item.gst) : 0;
        if (item.itemType === TITLE_TRANSFER_ITEM_TYPE)
          carryItem.titleTransferId = item.itemId
        else if (item.itemType === FREIGHT_CONTRACT_ITEM_TYPE)
          carryItem.freightMovementId = item.itemId;
        else if(item.itemType === LOAD_ITEM_TYPE)
          carryItem.loadId = item.itemId;
        carryItem.itemType = 'carryitem';
        carryItem.subTotal = returnFloatFromString(item.totalExGst);
        carryItem.tonnage = returnFloatFromString(item.tonnage);
        carryItem.total = returnFloatFromString(item.total);
        carryItem.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
        carryItem.itemId = item.itemId;
        let selectedCarry = null;
        if (carryItem.freightMovementId)
          selectedCarry = find(this.state.carryItems, { freightMovementId: item.freightMovementId });
        else if (carryItem.loadId)
          selectedCarry = find(this.state.carryItems, { loadId: item.loadId });
        else if (carryItem.titleTransferId)
          selectedCarry = find(this.state.carryItems, { titleTransferId: item.titleTransferId });
        carryItem.invoice = get(selectedCarry, 'invoice', true);
        carryItem.rate = returnFloatFromString(item.price);
        carryItems.push(carryItem);
      });
      carryItems = filter(
        carryItems,
        item =>
          (item.titleTransferId !== undefined && titleTransferIds.includes(item.titleTransferId)) ||
          (item.freightMovementId !== undefined && freightMovementIds.includes(item.freightMovementId)) ||
          (item.loadId !== undefined && loadIds.includes(item.loadId)),
      );
      carryItems.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date))
    }
    return carryItems;
  }

  async getGrainLevies(item) {
    let requestData = {...item}
    delete requestData.adjustmentDetails;
    delete requestData.customAdjustments;
    delete requestData.contractPrice;
    delete requestData.adjustedPrice;
    delete requestData.shrinkage;
    delete requestData.receivalFees;
    delete requestData.titleTransferId;
    delete requestData.roundOffRate;
    delete requestData.isBlendingFees;
    delete requestData.freightMovementIdentifier;
    requestData.identifier = item.isBlended ? item.freightMovementIdentifier : item.identifier;
    return await APIService.contracts(this.props.contract.id).appendToUrl('levy-epr/grainlevy/').post(requestData);
  }

  async getEprs(item) {
    let requestData = {...item}
    delete requestData.adjustmentDetails;
    delete requestData.customAdjustments;
    delete requestData.contractPrice;
    delete requestData.adjustedPrice;
    delete requestData.shrinkage;
    delete requestData.receivalFees;
    delete requestData.titleTransferId;
    delete requestData.roundOffRate;
    delete requestData.isBlendingFees;
    delete requestData.freightMovementIdentifier;
    requestData.identifier = item.isBlended ? item.freightMovementIdentifier : item.identifier;
    return await APIService.contracts(this.props.contract.id).appendToUrl('levy-epr/epr/').post(requestData);
  }

  getSelectedInvoiceCustomItems() {
    let customItems = [];
    let customItemId = 1;
    const newState = { ...this.state };
    forEach(this.props.selectedInvoice.others, (item) => {
      let customItem = {};
      customItem.description = item.description;
      customItem.subTotal = returnFloatFromString(item.totalExGst);
      customItem.gst = returnFloatFromString(item.gst);
      customItem.total = returnFloatFromString(item.total);
      customItem.itemType = CUSTOM_ITEM_TYPE;
      customItem.itemId = customItemId;
      customItem.id = customItemId;
      customItem.invoice = true;
      customItem.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
      customItems.push(customItem);
      customItemId += 1;
    });
    newState.customItemId = customItemId;
    newState.customItems = [...newState.customItems, ...customItems];
    newState.selectedItems = [...newState.selectedItems, ...customItems];
    newState.customItems.sort((a, b) => new Date(moment(a.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime() - new Date(moment(b.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime());
    this.setState(newState);
  }

  getLDFromContract = (consigneeHandlerId, consignorHandlerId=0) => {
    const consignees = get(this.props, 'contract.consigneesWithSites', []);
    const consigneeHandler = find(consignees, { handlerId: consigneeHandlerId });
    const consignors = get(this.props, 'contract.consignorsWithSites', []);
    const consignorHandler = find(consignors, { handlerId: consignorHandlerId });
    const consigneeLd = get(consigneeHandler, 'ld', undefined);
    const consignorLd = get(consignorHandler, 'ld', undefined);
    if (!isNumber(consigneeLd))
      return consignorLd;
    if (!isNumber(consignorLd))
      return consigneeLd;
    return consigneeLd + consignorLd;
  };

  getDefaultLD = (freight, tracks_parent) => {
    let ld=null;
    if (PRICE_POINTS.TRACK === get(this.props, 'contract.pricePointId')) {
      const tracks = get(freight, `${tracks_parent}.tracks`)
      find(tracks, (_ld, _name) => {
        if(_name.toLowerCase() === this.props.contract.track.toLowerCase())
          ld = _ld
      })
      if(ld === null)
        ld = get(freight, `${tracks_parent}.ld`)
    }
    if (isNumber(ld)) ld = -1 * ld;
    return ld;
  };

  isPricePointTrack() {
    return PRICE_POINTS.TRACK === get(this.props, 'contract.pricePointId');
  }

  isFlat() {
    return get(this.props, 'contract.paymentScaleId') === PAYMENT_SCALES.FLAT;
  }

  getSpecAdjustment(basePrice, specRule, specValue, tonnage) {
    let isApplicable = eval(`${specValue} ${specRule.operator} ${specRule.value}`)
    if(isApplicable) {
      let adjustmentFactor = Math.abs(specRule.value - specValue)/specRule.adjustmentPerValue
      let adjustment = specRule.adjustmentValue * adjustmentFactor
      if(specRule.adjustmentType == 'price')
        return {value: -adjustment, price: true}
      if(specRule.adjustmentType == 'quantity')
        return {value: -(tonnage-(tonnage * ((100 - adjustment)/100))), quantity: true}
    }
    return 0
  }

  getAdmixtureAdjustment(basePrice, impu) {
    return parseFloat(basePrice) * (0 - parseFloat(impu)) * 0.01;
  }

  getOilAdjustment(basePrice, impu, coil) {
    let postAdmixAdjustedPrice = this.getAdmixtureAdjustment(basePrice, impu);
    return parseFloat((parseFloat(postAdmixAdjustedPrice) + parseFloat(basePrice)) * (parseFloat(coil) - 42) * 0.015).toFixed(2);
  }

  getWeightedAverage(loads, loadIds, property) {
    let percentage = 0;
    let totalWeight = 0;
    loads.forEach(load => {
      let tonnage = get(loadIds, load.id);
      let impu = get(load, `specs.${property}`);
      percentage += (tonnage * impu);
      totalWeight += tonnage;
    });
    return parseFloat(percentage / totalWeight).toFixed(2);
  }

  getAdjustmentDetails(itemId, itemType, gradeId, locationDifferential, pricePerMT, shrinkage, receivalFees, isCanola, isLoadByLoad, impu, coil, priceDistribution=null, loadSpecs, tonnage) {
    const { contract } = this.props
    let adjustmentDetails = {};
    let isPricePointTrack = this.isPricePointTrack();
    if(tonnage)
      adjustmentDetails['itemTonnage'] = tonnage
    adjustmentDetails['contractPrice'] = get(priceDistribution, 'contract') || get(this.props, 'contract.price');
    adjustmentDetails['spreadPrice'] = this.spreadGradePrice(gradeId);
    adjustmentDetails['locationDifferential'] = get(priceDistribution, 'ld') || locationDifferential;
    adjustmentDetails['totalPricePerMt'] = get(priceDistribution, 'total') || pricePerMT;
    adjustmentDetails['totalQuantity'] = tonnage || 0;
    if(has(priceDistribution, 'additionalCost'))
      adjustmentDetails['additionalCost'] = priceDistribution.additionalCost
    adjustmentDetails['pricePointId'] = get(this.props, 'contract.pricePointId');
    if (isPricePointTrack) {
      adjustmentDetails['shrinkage'] = shrinkage || "0";
      adjustmentDetails['receivalFees'] = receivalFees || "0";
    }
    if (isCanola) {
      let isFlat = this.isFlat();
      if (isFlat) {
        adjustmentDetails['admixtureAdjustment'] = 0.0;
        adjustmentDetails['oilAdjustment'] = 0.0;
      }
      else {
        adjustmentDetails['admixtureAdjustment'] = parseFloat(this.getAdmixtureAdjustment(pricePerMT, impu)).toFixed(2);
        adjustmentDetails['oilAdjustment'] = this.getOilAdjustment(pricePerMT, impu, coil);
        adjustmentDetails['totalPricePerMt'] += (parseFloat(adjustmentDetails['admixtureAdjustment']) + parseFloat(adjustmentDetails['oilAdjustment']));
        if (isLoadByLoad)
          adjustmentDetails['isLoadByLoad'] = true;
      }
    }
    let hasSpecAdjustments = false
    adjustmentDetails['specs'] = {}
    if(get(contract?.spread?.details, '0.adjustmentType')) {
      forEach(contract.spread.details, specRule => {
        const loadSpecValue = get(loadSpecs, specRule.spec.toLowerCase())
        if(specRule.value && specRule.operator && loadSpecValue) {
          hasSpecAdjustments = true
          let _adjustment = this.getSpecAdjustment(pricePerMT, specRule, loadSpecValue, tonnage)
          let _value = isNumber(_adjustment) ? _adjustment : _adjustment?.value || 0
          let isQuantity = _adjustment?.quantity
          adjustmentDetails.specs[specRule.spec] = _adjustment
          if(isQuantity)
            adjustmentDetails['totalQuantity'] += _value
          else
            adjustmentDetails['totalPricePerMt'] += _value
        }
      })
    }
    if(hasSpecAdjustments)
      adjustmentDetails['hasSpecAdjustments'] = true
    adjustmentDetails['totalPricePerMt'] = parseFloat(parseFloat(adjustmentDetails['totalPricePerMt']).toFixed(2))
    adjustmentDetails['totalQuantity'] = parseFloat(parseFloat(adjustmentDetails['totalQuantity']).toFixed(2))
    adjustmentDetails['itemId'] = itemId;
    adjustmentDetails['itemType'] = itemType;
    return adjustmentDetails;
  }

  blendedGradePrice(gradeId) {
    let rate = 0;
    if (this.props.contract && this.props.contract.spread) {
      let grade = find(this.props.contract.spread.details, { gradeId: gradeId });
      if (grade)
        rate = parseFloat(grade.price);
    }
    return rate;
  }

  getAdjustmentsForBlendedGradeLoad(load, invoice, paymentDate, currency, rate, freightMovementId) {
    let chemicalApplicationItems = [];
    const chemicalLoads = get(load, 'chemicalLoads') || [];
    const contractChemicalApplications = get(this.props.contract, 'chemicalApplications') || [];
    forEach(chemicalLoads, chemicalLoad => {
      const chemicalApplication = chemicalLoad.chemicalApplication;
      const chemicalApplicationCommodityId = chemicalApplication.commodityId;
      const contractChemicalDetails = find(contractChemicalApplications, {commodityId: chemicalApplicationCommodityId});
      const chemicalRatePerUnit = get(contractChemicalDetails, 'price') || 0;
      let loadRate = parseFloat(((chemicalApplication.applicationFee * chemicalRatePerUnit) + (get(contractChemicalDetails, 'fee') || 0)).toFixed(2));
      let loadSubtotal = parseFloat((parseFloat(load.netWeight) * loadRate).toFixed(2));
      let chemicalAdjustmentDetails = {
        'applicationRate': chemicalApplication.applicationFee,
        'chemicalRatePerUnit': chemicalRatePerUnit,
        'mixingFees': get(contractChemicalDetails, 'fee'),
        'tonnage': parseFloat(load.netWeight),
        'isChemicalItem': true,
        'chemicalUnit': chemicalLoad.unit,
        'loadUnit': this.props.contract?.commodity?.priceUnit
      }
      chemicalApplicationItems.push({
        description: chemicalLoad.description,
        date: moment(chemicalLoad.dateTime).format(this.state.countryFormats.date),
        invoice: invoice,
        paymentDate: paymentDate,
        paymentDueDate: this.getEstimatedPaymentDueDate(paymentDate),
        tonnage: parseFloat(load.netWeight),
        subTotal: loadSubtotal,
        currency: currency,
        contractPrice: loadRate,
        rate: loadRate,
        gst: this.state.gstApplicable ? + (loadSubtotal * this.state.gstRate).toFixed(2) : 0,
        total: parseFloat((loadSubtotal * (this.state.gstApplicable ? (1 + this.state.gstRate).toFixed(2) : 1)).toFixed(2)),
        itemType: LOAD_ITEM_TYPE,
        itemId: chemicalLoad.id,
        chemicalAppliedOnLoad: load,
        chemicalAppliedOnLoadId: load.id,
        isBlended: true,
        isChemicalItem: true,
        adjustmentDetails: chemicalAdjustmentDetails,
        freightMovementId: freightMovementId
      })
    })
    let adjustmentDetails = {
      contractPrice: rate,
      totalPricePerMt: rate,
      isBlended: true,
      chemicalApplicationItems: chemicalApplicationItems
    }
    return adjustmentDetails;
  }

  getBlendedGradeLoads() {
    let blendedGradeLoads = [];
    let chemicalApplicationItems = [];
    let fms = [];
    if (this.selectedInvoiceId)
      fms = filter(this.props.freightMovements, item => get(item, 'invoiceId') === parseInt(this.selectedInvoiceId) || !item.isCommodityContractInvoiced);
    else
      fms = filter(this.props.freightMovements, item => !item.isCommodityContractInvoiced);
    fms.forEach(fm => {
      let movement_loads = fm.loadsToInvoice;
      if (!isEmpty(movement_loads)) {
        const movement = find(this.state.freightMovements, { itemId: fm.id });
        const paymentDueDate = this.getEstimatedPaymentDueDate(fm.deliveryDatetime)
        movement_loads.forEach(movement_load => {
          const load = find(this.state.blendedGradeLoads, {itemId: movement_load.id});
          let invoice = this.selectedInvoiceId ? (load ? get(load, 'invoice', fm.isCommodityContractInvoiced): get(movement, 'invoice', fm.isCommodityContractInvoiced)) : (load ? get(load, 'invoice', this.state.isDefaultSelected) : get(movement, 'invoice', this.state.isDefaultSelected))
          if (this.props.selectedInvoice && !invoice) {
            let invoiceLoads = get(this.props.selectedInvoice, 'blendedGradeLoads');
            let invoiceLoad = find(invoiceLoads, {itemId: movement_load.id});
            if (!invoiceLoad){
              invoice = false;
            }
          }
          let loadRate = this.blendedGradePrice(movement_load.gradeId);
          let loadSubtotal = parseFloat((parseFloat(movement_load.netWeight) * loadRate).toFixed(2));
          let paymentDate = fm.deliveryDatetime;
          let adjustments = this.getAdjustmentsForBlendedGradeLoad(movement_load, invoice, paymentDate, get(fm, 'currency'), loadRate, fm.id);
          chemicalApplicationItems = [...chemicalApplicationItems, ...adjustments['chemicalApplicationItems']]
          delete adjustments['chemicalApplicationItems'];
          blendedGradeLoads.push({
            description: movement_load.description,
            date: moment(fm.deliveryDatetime).format(this.state.countryFormats.date),
            invoice: invoice,
            paymentDate: paymentDate,
            paymentDueDate: paymentDueDate,
            tonnage: parseFloat(movement_load.netWeight),
            subTotal: loadSubtotal,
            currency: get(fm, 'currency'),
            contractPrice: loadRate,
            rate: loadRate,
            gst: this.state.gstApplicable ? + (loadSubtotal * this.state.gstRate).toFixed(2) : 0,
            total: loadSubtotal * (this.state.gstApplicable ? (1 + this.state.gstRate).toFixed(2) : 1),
            itemType: LOAD_ITEM_TYPE,
            itemId: movement_load.id,
            isBlended: true,
            freightMovementId: fm.id,
            identifier: fm.identifier + movement_load.id,
            freightMovementIdentifier: fm.identifier,
            adjustmentDetails: adjustments
          })
        })
      }
    })
    return [blendedGradeLoads, chemicalApplicationItems];
  }

  getFreightMovements(newState) {
    let freightMovements = [];
    let fms = [];
    if (this.selectedInvoiceId) {
      fms = filter(this.props.freightMovements, item => get(item, 'invoiceId') === parseInt(this.selectedInvoiceId) || !item.isCommodityContractInvoiced);
    }
    else {
      fms = filter(this.props.freightMovements, item => !item.isCommodityContractInvoiced);
    }
    fms.forEach(fm => {
      if (fm.isBlended && fm.loadsToInvoice.length < 2) // if length of loads to invoice is less than 2, that means there is no blending involved, so blending fee item is not needed
        return;
      let adjustmentDetails = {};
      let locationDifferential = this.getLDFromContract(get(fm, 'inload.farmId'), get(fm, 'outload.farmId'));
      if (!isNumber(locationDifferential)) locationDifferential = this.getDefaultLD(fm, 'inload.farm') || 0;
      let load = get(this.props.contract, 'inspection.name') === 'Origin' ? fm.outload : fm.inload;
      let pricePerMT = this.priceForOthers(get(load, 'gradeId'), locationDifferential).toFixed(2);
      let stateFm = find(this.state.freightMovements, {itemId: fm.id});
      let shrinkage = get(stateFm, 'shrinkage') || get(fm, 'shrinkage') || '0';
      let receivalFees = get(stateFm, 'receivalFees') || get(fm, 'receivalFees') || '0';
      let roundOffRate = true;
      let totalTonnage = fm.inferredTonnage
      let standardDeliveryRate = get(stateFm, 'standardDeliveryRate');
      if (this.props.contract && this.props.contract.commodityId === COMMODITIES.CANOLA) {
        adjustmentDetails = this.getAdjustmentDetails(fm.id, FREIGHT_CONTRACT_ITEM_TYPE, get(load, 'gradeId'), locationDifferential, parseFloat(pricePerMT), shrinkage, receivalFees, true, false, get(load, 'specs.impu'), get(load, 'specs.coil'), get(fm, 'invoiceDetails.prices'), load?.specs);
        pricePerMT = parseFloat(this.priceForCanola(pricePerMT, get(load, 'specs.impu'), get(load, 'specs.coil'))).toFixed(2);
      }
      if (isEmpty(adjustmentDetails)) {
        adjustmentDetails = this.getAdjustmentDetails(fm.id, FREIGHT_CONTRACT_ITEM_TYPE, get(load, 'gradeId'), locationDifferential, parseFloat(pricePerMT), shrinkage, receivalFees, false, false, null, null, get(fm, 'invoiceDetails.prices'), load?.specs, fm.inferredTonnage);
      }
      if(adjustmentDetails?.hasSpecAdjustments) {
        pricePerMT = parseFloat(parseFloat(pricePerMT) + (sum(map(values(pickBy(adjustmentDetails.specs, val => val.price)), 'value')))).toFixed(2)
        totalTonnage = parseFloat(parseFloat(totalTonnage) + (sum(map(values(pickBy(adjustmentDetails.specs, val => val.quantity)), 'value')))).toFixed(2)
      }
      if (this.isPricePointTrack()) {
        adjustmentDetails['exShrinkageReceivalPrice'] = pricePerMT;
        pricePerMT = this.applyShrinkageAndReceivalOnPrice(pricePerMT, receivalFees, shrinkage);
        adjustmentDetails['totalPricePerMt'] = pricePerMT;
        roundOffRate = !shrinkage;
      }
      adjustmentDetails['priceWithoutCustomAdjustment'] = parseFloat(pricePerMT);
      let customAdjustments = get(stateFm, 'customAdjustments') || get(adjustmentDetails, 'customAdjustments');
      if (!customAdjustments && this.props.selectedInvoice) {
        let invoiceFms = get(this.props.selectedInvoice, 'freightContracts');
        let invoiceFm = find(invoiceFms, {itemId: fm.id});
        customAdjustments = get(invoiceFm, 'adjustments.customAdjustments');
      }
      if (customAdjustments) {
        let totalCustomAdjustment = customAdjustments.reduce((acc, item) => acc + item.value, 0);
        pricePerMT = parseFloat(pricePerMT) + totalCustomAdjustment;
        adjustmentDetails['customAdjustments'] = customAdjustments;
        adjustmentDetails['totalPricePerMt'] = pricePerMT;
      }
      let adjustedPrice = parseFloat(pricePerMT) - parseFloat(get(this.props, 'contract.price')) + parseFloat(get(fm, 'invoiceDetails.prices.additionalCost') || 0);

      if(get(fm, 'invoiceDetails.prices.additionalCost'))
        pricePerMT = (parseFloat(pricePerMT) + fm.invoiceDetails.prices.additionalCost).toFixed(2)

      const movement = find(this.state.freightMovements, { itemId: fm.id });
      let invoice = this.selectedInvoiceId ? get(movement, 'invoice', fm.isCommodityContractInvoiced) :
      get(movement, 'invoice', this.state.isDefaultSelected)
      if (this.props.selectedInvoice && !invoice) {
        let invoiceFms = get(this.props.selectedInvoice, 'freightContracts');
        let invoiceFm = find(invoiceFms, {itemId: fm.id});
        if (!invoiceFm){
          invoice = false;
        }
      }
      if (fm.isBlended) {
        pricePerMT = get(this.props.contract, 'blendingFee');
        adjustmentDetails['isBlendingFees'] = fm.isBlended;
        adjustmentDetails['isBlended'] = fm.isBlended;
        adjustmentDetails['blendingFees'] = pricePerMT;
        adjustmentDetails['totalPricePerMt'] = pricePerMT;
        adjustmentDetails['unit'] = get(this.props.contract, 'commodity.unit');
      }
      let fmSubTotal = +(totalTonnage * pricePerMT).toFixed(2);
      freightMovements.push({
        tonnage: parseFloat(totalTonnage),
        subTotal: fmSubTotal,
        currency: get(fm, 'currency'),
        rate: parseFloat(pricePerMT),
        gst: this.state.gstApplicable ? +(fmSubTotal * this.state.gstRate).toFixed(2) : 0,
        total: fmSubTotal * (this.state.gstApplicable ? (1 + this.state.gstRate).toFixed(2) : 1),
        standardDeliveryRate: standardDeliveryRate,
        itemType: FREIGHT_CONTRACT_ITEM_TYPE,
        itemId: fm.id,
        description: get(fm, 'invoiceDetails.description'),
        date: moment(fm.deliveryDatetime).format(this.state.countryFormats.date),
        isBHC: get(fm, 'freightDelivery.load.0.isBhcSite', false) || get(fm, 'inload.isBhcSite', false),
        identifier: fm.identifier,
        invoice: invoice,
        paymentDate: get(fm, 'deliveryDatetime'),
        paymentDueDate: this.getEstimatedPaymentDueDate(fm.deliveryDatetime),
        adjustmentDetails: adjustmentDetails,
        adjustedPrice: roundOffRate ? parseFloat(adjustedPrice.toFixed(2)) : parseFloat(adjustedPrice.toFixed(5)),
        contractPrice: fm.isBlended ? parseFloat(pricePerMT) : get(this.props, 'contract.price'),
        shrinkage: shrinkage,
        receivalFees: receivalFees,
        roundOffRate: roundOffRate,
        customAdjustments: customAdjustments,
        isBlendingFees: fm.isBlended
      });
    });
    const sortedFMs = freightMovements.sort((a, b) => this.convertToDate(a.paymentDueDate) - this.convertToDate(b.paymentDueDate));
    if (this.props.isFreightMovementFetched && !this.props.isFetchedFMs) {
      this.props.dispatch(loadInvoiceItems({ isFetchedFMs: true }));
    }
    if (!this.paymentDefault) {
      newState.fields.paymentDueDate.value = this.getEditInvoicePaymentDueDateForForm() || this.estimatedPaymentDueDate('YYYY-MM-DD', sortedFMs);
    }
    return sortedFMs;
  }

  priceForCanola(basePrice, impu, coil) {
    let paymentScale = this.props.contract.paymentScaleId;
    if (impu && coil) {
      if (paymentScale === PAYMENT_SCALES.AOF_BASIC) {
        return this.calculatePrice(basePrice, impu, coil);
      }
      if (paymentScale === PAYMENT_SCALES.AOF_BASIC_CAP) {
        return coil >= 46 ? this.calculatePrice(basePrice, impu, 46) : this.calculatePrice(basePrice, impu, coil);
      }
    }
    return basePrice;
  }

  calculatePrice(basePrice, impu, coil) {
    let admixAdjustment = parseFloat(this.getAdmixtureAdjustment(basePrice, impu)).toFixed(2);
    let oilAdjustment= this.getOilAdjustment(basePrice, impu, coil);
    return parseFloat(parseFloat(basePrice) + parseFloat(admixAdjustment) + parseFloat(oilAdjustment)).toFixed(2);
  }

  spreadGradePrice(gradeId) {
    let gradePriceDiff = 0;
    if (this.props.contract && this.props.contract.spread) {
      let grade = find(this.props.contract.spread.details, { id: gradeId });
      if (grade) {
        let gradeValue = parseFloat(grade.value);
        gradePriceDiff = grade.priceVariation === '+' ? gradeValue : -1 * gradeValue;
      }
    }
    return gradePriceDiff || 0;
  }

  priceForOthers(gradeId, locationDifferential) {
    let priceDiff = this.spreadGradePrice(gradeId);
    priceDiff += locationDifferential;
    return this.props.contract ? this.props.contract.price + priceDiff : priceDiff;
  }

  getNgrs(partyId, newlyCreatedNgr, fieldName) {
    APIService.companies(partyId)
      .ngrs()
      .get(this.props.token)
      .then(items => {
        const newState = { ...this.state };
        if (fieldName) {
          newState[fieldName] = items;
        }
        if (newlyCreatedNgr) {
          if (this.props.contract.seller.companyId === partyId) {
            newState.fields.sellerNgrId.value = newlyCreatedNgr.id;
            newState.sellerNgrs = items;
          } else {
            newState.fields.buyerNgrId.value = newlyCreatedNgr.id;
            newState.buyerNgrs = items;
          }
        }
        this.setState(newState);
        this.updateSellerNgrDetails(fieldName);
      });
  }

  updateSellerNgrDetails(fieldName) {
    let sellerNgrId = get(this.state.fields, 'sellerNgrId.value') || get(this.props.contract, 'seller.ngrId');
    if (fieldName === 'sellerNgrs' && sellerNgrId) {
      this.handleSellerNgrChange(sellerNgrId);
    }
  }

  setUpDefaultStateSelected = () => {
    if (!this.state.isDefaultSelected) {
      const newState = { ...this.state };
      let freightsAndTitleTransfersAndLoads = [];
      if (this.selectedInvoiceId) {
        let freights = filter(newState.freightMovements, 'invoice');
        let titleTransfers = filter(newState.titleTransfers, 'invoice');
        let loads = !isEmpty(newState.loads) ? filter(newState.loads, 'invoice'): [];
        let blendedGradeLoads = !isEmpty(newState.blendedGradeLoads) ? filter(newState.blendedGradeLoads, 'invoice'): [];
        let chemicalApplicationItems = !isEmpty(newState.chemicalApplicationItems) ? filter(newState.chemicalApplicationItems, 'invoice'): [];
        freightsAndTitleTransfersAndLoads = [...freights, ...titleTransfers, ...loads, ...blendedGradeLoads, ...chemicalApplicationItems];
      }
      else {
        let loads = !isEmpty(newState.loads) ? newState.loads : [];
        let blendedGradeLoads = !isEmpty(newState.blendedGradeLoads) ? newState.blendedGradeLoads: [];
        let chemicalApplicationItems = !isEmpty(newState.chemicalApplicationItems) ? newState.chemicalApplicationItems: [];
        freightsAndTitleTransfersAndLoads = [...newState.freightMovements, ...newState.titleTransfers, ...loads, ...blendedGradeLoads, ...chemicalApplicationItems];
      }
      newState.isDefaultSelected = true;
      this.setState(newState, () => {
        const length = freightsAndTitleTransfersAndLoads?.length
        if(length) {
          this.props.dispatch(isLoading('ccInvoiceEPRAndLevyLoading'))
          forEach(freightsAndTitleTransfersAndLoads, (item, index) => this.handleItemClick(item, true, false, index === length-1));
        }
        else
          this.props.dispatch(forceStopLoader())
      });
    }
  };


  handleItemClick = (item, checked, fromUser=true, stopLoader=false) => {
    const newState = { ...this.state };
    this.updateSelectedItem(newState.customItems, item, checked, CUSTOM_ITEM_TYPE);
    let loadItems = [];
    remove(newState.selectedItems, i => (i.description == item.description) && (item.itemType == i.itemType) && (item.itemId == i.itemId));
    if (checked) {
      newState.selectedItems.push({ ...item, invoice: checked });
      if (item.itemType === LOAD_ITEM_TYPE && fromUser) {
        if (item.isBlended) {
          remove(newState.selectedItems, i => (i.freightMovementId === item.freightMovementId) && (item.itemType == i.itemType) && (item.itemId !== i.itemId) && (i.isBlended && !i.isChemicalItem));
          let blendedGradeLoadItems = filter(newState.blendedGradeLoads, loadItem => loadItem.freightMovementId === item.freightMovementId && loadItem.itemId !== item.itemId);
          blendedGradeLoadItems = blendedGradeLoadItems.map(loadItem => ({...loadItem, invoice: checked}));
          newState.selectedItems = [...newState.selectedItems, ...blendedGradeLoadItems];

          remove(newState.selectedItems, i => (i.freightMovementId === item.freightMovementId) && (item.itemType == i.itemType) && (item.itemId !== i.chemicalAppliedOnLoadId) && (i.isBlended && i.isChemicalItem));
          let chemicalLoadItems = filter(newState.chemicalApplicationItems, chemicalApplicationItem => chemicalApplicationItem.freightMovementId === item.freightMovementId);
          chemicalLoadItems = chemicalLoadItems.map(loadItem => ({...loadItem, invoice: checked}));
          newState.selectedItems = [...newState.selectedItems, ...chemicalLoadItems];

          remove(newState.selectedItems, i => (i.itemId === item.freightMovementId) && (i.itemType === FREIGHT_CONTRACT_ITEM_TYPE) && i.isBlendedFees);
          let blendingFeeItems = filter(newState.freightMovements, freightMovement => freightMovement.itemId === item.freightMovementId);
          blendingFeeItems = blendingFeeItems.map(blendingFeeItem => ({...blendingFeeItem, invoice: checked}));
          newState.selectedItems = [...newState.selectedItems, ...blendingFeeItems];

          loadItems = [...blendedGradeLoadItems, ...chemicalLoadItems];
        }
        else {
          remove(newState.selectedItems, i => (i.titleTransferId === item.titleTransferId) && (item.itemType == i.itemType) && (item.itemId !== i.itemId));
          loadItems = filter(newState.loads, loadItem => loadItem.titleTransferId === item.titleTransferId && loadItem.itemId !== item.itemId);
          loadItems = loadItems.map(loadItem => ({...loadItem, invoice: checked}));
          newState.selectedItems = [...newState.selectedItems, ...loadItems];
        }
      }
      else if (item.itemType === FREIGHT_CONTRACT_ITEM_TYPE && item.isBlendedFees && fromUser) {
        remove(newState.selectedItems, i => (i.freightMovementId === item.itemId) && (item.itemId !== i.itemId));
        let blendedGradeLoadItems = filter(newState.blendedGradeLoads, loadItem => loadItem.freightMovementId === item.itemId);
        blendedGradeLoadItems = blendedGradeLoadItems.map(loadItem => ({...loadItem, invoice: checked}));
        newState.selectedItems = [...newState.selectedItems, ...blendedGradeLoadItems];

        let chemicalLoadItems = filter(newState.chemicalApplicationItems, chemicalApplicationItem => chemicalApplicationItem.freightMovementId === item.itemId);
        chemicalLoadItems = chemicalLoadItems.map(loadItem => ({...loadItem, invoice: checked}));
        newState.selectedItems = [...newState.selectedItems, ...chemicalLoadItems];
      }
    } else {
      this.updateSelectedItems(newState, item);
      this.removeRelatedItems(newState.grainLevies, item);
      this.removeRelatedItems(newState.eprs, item);
    }
    if (item.itemType === FREIGHT_CONTRACT_ITEM_TYPE) {
      if (item.isBlendingFees) {
        forEach(newState.blendedGradeLoads, load => {
          if (get(load, 'freightMovementId') === get(item, 'itemId'))
            load.invoice = checked;
        });
        forEach(newState.chemicalApplicationItems, load => {
          if (get(load, 'freightMovementId') === get(item, 'itemId'))
            load.invoice = checked;
        });
      }
      forEach(newState.freightMovements, movement => {
        if (movement.itemId === item.itemId)
          movement.invoice = checked;
      });
    } else if (item.itemType === TITLE_TRANSFER_ITEM_TYPE) {
      forEach(newState.titleTransfers, title => {
        if (title.itemId === item.itemId)
          title.invoice = checked;
      });
    } else if (item.itemType === CARRY_ITEM_TYPE) {
      forEach(newState.carryItems, carry => {
        if (item.freightMovementId && item.freightMovementId === carry.freightMovementId) {
          carry.invoice = checked;
        } else if (item.titleTransferId && item.titleTransferId === carry.titleTransferId) {
          carry.invoice = checked;
        } else if (item.loadId && item.loadId === carry.loadId) {
          carry.invoice = checked;
        }
      });
    } else if (item.itemType === LOAD_ITEM_TYPE) {
      if (item.isBlended) {
        forEach(newState.blendedGradeLoads, load => {
          if (get(load, 'freightMovementId') === get(item, 'freightMovementId'))
            load.invoice = checked;
        });
        forEach(newState.chemicalApplicationItems, load => {
          if (get(load, 'freightMovementId') === get(item, 'freightMovementId'))
            load.invoice = checked;
        });
        forEach(newState.freightMovements, freightMovement => {
          if (get(freightMovement, 'itemId') === get(item, 'freightMovementId') && freightMovement.isBlendingFees)
            freightMovement.invoice = checked;
        });
      }
      else {
        forEach(newState.loads, load => {
          if (get(load, 'titleTransferId') === get(item, 'titleTransferId'))
            load.invoice = checked;
        });
      }
    }
    if(!this.paymentDefault)
      newState.fields.paymentDueDate.value = this.getEditInvoicePaymentDueDateForForm() || this.estimatedPaymentDueDate('YYYY-MM-DD');
    this.setState(newState, () => {
      const shouldFetchEPRAndLevy = checked && ((isArray(loadItems) && !isEmpty(loadItems)) || (includes([TITLE_TRANSFER_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE, LOAD_ITEM_TYPE], item.itemType) && item?.itemId))
      if(shouldFetchEPRAndLevy) {
        forEach(loadItems, loadItem => {
          if(loadItem.itemId && !loadItem.isChemicalItem) {
            this.updateGrainLevies(loadItem);
            this.updateEprs(loadItem, stopLoader);
          }
        })
        if (includes([TITLE_TRANSFER_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE, LOAD_ITEM_TYPE], item.itemType)) {
          if(item.itemId && !item.isBlendingFees && !item.isChemicalItem) {
            this.updateGrainLevies(item);
            this.updateEprs(item, stopLoader);
          }
        }
      }
    });
  }

  handleSubTotal(item) {
    filter(this.state.selectedItems, i => {
      if (i.description == item.description)
        merge(i, item);
    });
    this.setState({ isChanged: !this.state.isChanged });
  }

  updateSelectedItems(newState, item) {
    remove(newState.selectedItems, selectedItem => {
      return (
        this.areSame(selectedItem, item) ||
        this.isMatchingGrainLevyOrEpr(selectedItem, item) ||
        this.relatedItemToFm(selectedItem, item) ||
        this.relatedItemToTitleTransfer(selectedItem, item) ||
        this.relatedItemToLoad(selectedItem, item)
      );
    });
  }

  relatedItemToLoad(selectedItem, item) {
    return item.itemType === LOAD_ITEM_TYPE && (item.isBlended ? (item.freightMovementId == get(selectedItem, 'freightMovementId') || item.freightMovementId === selectedItem.itemId) : item.titleTransferId === get(selectedItem, 'titleTransferId'));
  }

  isMatchingGrainLevyOrEpr(selectedItem, item) {
    return (
      ((selectedItem.titleTransferId && selectedItem.titleTransferId === item.titleTransferId) ||
        (selectedItem.freightMovementId && selectedItem.freightMovementId === item.freightMovementId)) &&
      includes([GRAIN_LEVY_ITEM_TYPE, EPR_ITEM_TYPE], item.itemType) &&
      selectedItem.itemType === item.itemType
    );
  }

  relatedItemToTitleTransfer(selectedItem, item) {
    return selectedItem.titleTransferIdentifier === item.identifier && item.itemType === TITLE_TRANSFER_ITEM_TYPE;
  }

  relatedItemToFm(selectedItem, item) {
    return item.itemType === FREIGHT_CONTRACT_ITEM_TYPE && (selectedItem.freightMovementIdentifier === item.identifier || (selectedItem.freightMovementId === item.itemId && item.isBlendingFees && selectedItem.isBlended));
  }

  areSame(selectedItem, item) {
    return selectedItem.itemId && selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType;
  }

  removeRelatedItems(relatedItems, item) {
    forEach(relatedItems, selectedItem => {
      if(
        (selectedItem.freightMovementIdentifier === item.identifier && item.itemType === FREIGHT_CONTRACT_ITEM_TYPE) ||
        (selectedItem.titleTransferIdentifier === item.identifier && item.itemType === TITLE_TRANSFER_ITEM_TYPE) ||
        (selectedItem.titleTransferId === item.titleTransferId && item.itemType === LOAD_ITEM_TYPE)
      )
        selectedItem.invoice = false
    });
  }

  updateEprs(item, stopLoader) {
    const callback = epr => {
      if (epr) {
        this.setState({eprStates: {...this.state.eprStates, [item.identifier]: epr}}, () => {
          let transactionSelected = false
          if(epr?.freightMovementId)
            transactionSelected = find(this.state.selectedItems, {itemId: epr.freightMovementId, itemType: 'freightcontract'})
          else if(epr?.loadId)
            transactionSelected = find(this.state.selectedItems, {itemId: epr.loadId, itemType: 'load'})
          else if(epr?.titleTransferId)
            transactionSelected = find(this.state.selectedItems, {itemId: epr.titleTransferId, itemType: 'titletransfer'})
            const newState = {...this.state}
            epr.actualPrice = get(epr, 'subTotal');
            epr.invoice = Boolean(transactionSelected && (isEqual(get(epr, 'total'), -0) ? false : this.isChecked('EPR')));

          if(epr?.freightMovementId) {
            remove(newState.eprs, {freightMovementId: epr.freightMovementId})
            remove(newState.selectedItems, {freightMovementId: epr.freightMovementId, itemType: "epr"})
          }
          else if(epr?.loadId) {
            remove(newState.eprs, {loadId: epr.loadId})
            remove(newState.selectedItems, {loadId: epr.loadId, itemType: "epr"})
          }
          else if(epr?.titleTransferId) {
            remove(newState.eprs, {titleTransferId: epr.titleTransferId})
            remove(newState.selectedItems, {titleTransferId: epr.titleTransferId, itemType: "epr"})
          }

          newState.eprs.push(epr);
          if(epr.invoice)
            newState.selectedItems.push(epr);

          newState.eprs = uniqBy(newState.eprs, 'description')
          newState.selectedItems = uniqBy(newState.selectedItems, 'description')
          newState.eprs.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date));
          this.setState({
            eprs: uniqWith(newState.eprs, isEqual),
            selectedItems: newState.selectedItems,
          }, () => stopLoader && this.props.dispatch(forceStopLoader()));
        })
      }
    }
    const epr = this.state.eprStates[item.identifier]
    epr ? callback(epr) : this.getEprs(item).then(callback);
  }

  updateGrainLevies(item, fetchGrainLevy=false) {
    const callback = grainlevies => {
      if (grainlevies && isArray(grainlevies) && !isEmpty(grainlevies)) {
        this.setState({levyStates: {...this.state.levyStates, [item.identifier]: grainlevies}}, () => {
          let transactionSelected = false
          let grainlevy = get(grainlevies, '0')
          if(grainlevy?.freightMovementId)
            transactionSelected = find(this.state.selectedItems, {itemId: grainlevy.freightMovementId, itemType: 'freightcontract'})
          else if(grainlevy?.loadId)
            transactionSelected = find(this.state.selectedItems, {itemId: grainlevy.loadId, itemType: 'load'})
          else if(grainlevy?.titleTransferId)
            transactionSelected = find(this.state.selectedItems, {itemId: grainlevy.titleTransferId, itemType: 'titletransfer'})
  
          const newState = {...this.state}
          forEach(grainlevies, _grainLevy => {
            _grainLevy.actualPrice = get(_grainLevy, 'total');
            _grainLevy.invoice = Boolean(transactionSelected && (isEqual(get(_grainLevy, 'total'), -0) ? false : this.isChecked('Levy')));
          })
          grainlevy = get(grainlevies, '0')
          if(grainlevy?.freightMovementId) {
            remove(newState.grainLevies, {freightMovementId: grainlevy.freightMovementId})
            remove(newState.selectedItems, {freightMovementId: grainlevy.freightMovementId, itemType: "grainlevy"})
          }
          else if(grainlevy?.loadId) {
            remove(newState.grainLevies, {loadId: grainlevy.loadId})
            remove(newState.selectedItems, {loadId: grainlevy.loadId, itemType: "grainlevy"})
          }
          else if(grainlevy?.titleTransferId) {
            remove(newState.grainLevies, {titleTransferId: grainlevy.titleTransferId})
            remove(newState.selectedItems, {titleTransferId: grainlevy.titleTransferId, itemType: "grainlevy"})
          }
  
          newState.grainLevies = [...newState.grainLevies, ...grainlevies];
          if(grainlevy.invoice)
            newState.selectedItems = [...newState.selectedItems, ...grainlevies];
  
          newState.grainLevies = uniqBy(newState.grainLevies, 'description')
          newState.selectedItems = uniqBy(newState.selectedItems, 'description')
  
          newState.grainLevies.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date));
  
          this.setState({
            grainLevies: uniqWith(newState.grainLevies, isEqual),
            selectedItems: newState.selectedItems,
          });
        })
      }
    }
    const levy = this.state.levyStates[item.identifier]
    levy && !fetchGrainLevy ? callback(levy) : this.getGrainLevies(item).then(callback);
  }

  updateSelectedItem(items, itemToBeUpdated, selected, itemType) {
    let item = items.find(i => i.itemId === itemToBeUpdated.itemId && itemToBeUpdated.itemType === itemType);
    if (item) item.invoice = selected;
  }

  handleAddItem(item) {
    const newState = { ...this.state };
    if (item.modifier && item.modifier === 'minus') {
      item.subTotal = -1 * item.subTotal;
      if (item.gst) {
        item.gst = -1 * item.gst;
        item.total = -1 * item.total;
      }
    }
    if (item.gst === '') {
      item.gst = 'N/A';
      item.total = item.subTotal;
    }
    item.itemType = 'customitem';
    item.itemId = newState.customItemId;
    item.id = newState.customItemId;
    item.invoice = true;
    item.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
    newState.customItems.push(item);
    newState.customItems.sort((a, b) => new Date(moment(a.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime() - new Date(moment(b.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime());
    newState.selectedItems.push(item);
    newState.openSideDrawer = false;
    newState.customItemId += 1;
    this.setState(newState);
  }

  checkIfSelectedItemWithOtherCurrencyIncluded() {
    let selectedFms = filter(this.state.selectedItems, item => item.itemType == FREIGHT_CONTRACT_ITEM_TYPE)
    let result = false
    selectedFms.some(fm => {
      if (get(fm, 'adjustmentDetails.additionalCost') && get(fm, 'adjustmentDetails.additionalCost') != 0 && fm?.currency !== get(this.props.contract, 'currency')) {
        result = true;
        return true;
      }
    });
    return result;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setAllFieldsErrors(() => {
      let isPayeeValid = true;
      const isFormInvalid = some(this.state.fields, field => {
        return field.errors.length > 0;
      });

      if (this.state.displaySellerBankForm) {
        isPayeeValid = every(this.state.fields.payee, key => {
          return isEmpty(get(key, 'errors'));
        });
      }

      if (this.state.selectedItems.length === 0) {
        alertifyjs.error('Please select at least one item to be invoiced');
        return;
      }
      if(this.checkIfSelectedItemWithOtherCurrencyIncluded()) {
        alertifyjs.alert(
          "Warning",
          "Invoice has amounts in multiple currencies, please ensure all the amounts are in same currency")
        return;
      }
      if (!isFormInvalid && isPayeeValid) {
        let data = this.getSubmitData();
        this.props.dispatch(isLoading('alertify'));
        if (this.selectedInvoiceId)
          this.props.createAmendInvoice(data, response => this.setState({xeroCounterPartyExists: response.hasCounterPartyXeroContact}));
        else
          this.props.submit(data, response => this.setState({xeroCounterPartyExists: response.hasCounterPartyXeroContact}))
        this.handlePreviewClick()
      }
    });
    this.focusOnFirstErrorField();
  }

  getSubmitData() {
    const data = mapValues(this.state.fields, field => {
      return field.value;
    });
    data.raisedForType = 'contract';
    data.raisedForId = this.props.contract.id;
    let company = this.props.contract.buyer.company;
    data.invoiceItems = cloneDeep(
      filter(this.state.selectedItems, selectedItem => !includes([CARRY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice),
    ).concat(cloneDeep(filter(this.state.carryItems, carry => carry.invoice)));
    const eprs = uniqWith(
      filter(data.invoiceItems, selectedItem => includes([EPR_ITEM_TYPE], selectedItem.itemType)),
      isEqual,
    );
    data.invoiceItems = cloneDeep(filter(data.invoiceItems, selectedItem => !includes([EPR_ITEM_TYPE], selectedItem.itemType))).concat(
      cloneDeep(eprs),
    );
    let validTonnageItems = filter(this.state.selectedItems, item => !!item.tonnage && item.itemType !== CARRY_ITEM_TYPE);
    data.tonnage = reduce(
      validTonnageItems,
      (sum, item) => sum + parseFloat(item.tonnage),
      0,
    );
    const custom_item = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([CUSTOM_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice));
    const carry_item_fm = cloneDeep(filter(this.state.carryItems, selectedItem => includes([CARRY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'freightMovementId', false)));
    const carry_item_tt = cloneDeep(filter(this.state.carryItems, selectedItem => includes([CARRY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'titleTransferId', false)));
    const carry_item_load = cloneDeep(filter(this.state.carryItems, selectedItem => includes([CARRY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'loadId', false)));
    const grain_levy_item_fm = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([GRAIN_LEVY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'freightMovementIdentifier', false)));
    const grain_levy_item_tt = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([GRAIN_LEVY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'titleTransferIdentifier', false)));
    const grain_levy_item_load = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([GRAIN_LEVY_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'loadIdentifier', false)));
    const epr_item_fm = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([EPR_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'freightMovementIdentifier', false)));
    const epr_item_tt = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([EPR_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'titleTransferIdentifier', false)));
    const epr_item_load = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([EPR_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && get(selectedItem, 'loadIdentifier', false)));
    const freight_contract_item = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([FREIGHT_CONTRACT_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice));
    const title_transfer_item = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([TITLE_TRANSFER_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice));
    const load_item = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([LOAD_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && !selectedItem.isBlended));
    const secondaryEntityList = [custom_item, carry_item_fm, carry_item_tt, carry_item_load, grain_levy_item_fm, grain_levy_item_tt, grain_levy_item_load, epr_item_fm, epr_item_tt, epr_item_load];
    const carry_items = [...carry_item_fm, ...carry_item_tt, ...carry_item_load]
    const grain_levy_items = [...grain_levy_item_fm, ...grain_levy_item_tt, ...grain_levy_item_load]
    const epr_items = [...epr_item_fm, ...epr_item_tt, ...epr_item_load]

    let blended_grade_load_items = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([LOAD_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && selectedItem.isBlended && !selectedItem.isChemicalItem));
    const chemicalApplicationItems = cloneDeep(filter(this.state.selectedItems, selectedItem => includes([LOAD_ITEM_TYPE], selectedItem.itemType) && selectedItem.invoice && selectedItem.isBlended && selectedItem.isChemicalItem));
    blended_grade_load_items.map(blendedGradeLoad => {
      const loadId = blendedGradeLoad.itemId;
      let chemicalApplicationLoads = filter(chemicalApplicationItems, chemicalApplicationItem => chemicalApplicationItem.chemicalAppliedOnLoadId == loadId);
      chemicalApplicationLoads.map(chemicalApplicationLoad => {
        chemicalApplicationLoad.adjustments = chemicalApplicationLoad.adjustmentDetails;
        delete chemicalApplicationLoad.adjustmentDetails;
      })
      blendedGradeLoad.adjustmentDetails['chemicalApplicationLoads'] = chemicalApplicationLoads;
      blendedGradeLoad.total = parseFloat(blendedGradeLoad.total.toFixed(2));
      delete blendedGradeLoad.freightMovementId;
    })

    const entityList = [freight_contract_item, title_transfer_item, load_item, blended_grade_load_items];

    forEach(secondaryEntityList, i => i.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date)));
    forEach(entityList, i => i.sort((a, b) => this.convertToDate(a.paymentDueDate) - this.convertToDate(b.paymentDueDate)));

    carry_items.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date));
    grain_levy_items.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date));
    epr_items.sort((a, b) => this.convertToDate(a.date) - this.convertToDate(b.date));

    data.invoiceItems = [].concat(custom_item, carry_items, grain_levy_items, epr_items, freight_contract_item, title_transfer_item, load_item, blended_grade_load_items);
    data.invoiceItems.forEach(item => {
      if (!includes([CUSTOM_ITEM_TYPE, CARRY_ITEM_TYPE, GRAIN_LEVY_ITEM_TYPE, EPR_ITEM_TYPE], item.itemType)) {
        delete item['description'];
        delete item['date'];
      }
      if (item.itemType === CUSTOM_ITEM_TYPE) {
        delete item['id'];
        delete item['itemId'];
        if (item['gst'] === 'N/A') {
          item['gst'] = 0;
        }
      }
      if (has(item, 'loadId')) {
        delete item['titleTransferId'];
      }
      if (includes([TITLE_TRANSFER_ITEM_TYPE, FREIGHT_CONTRACT_ITEM_TYPE, LOAD_ITEM_TYPE], item.itemType)) {
        delete item['identifier'];
        delete item['isBHC'];
        delete item['paymentDueDate'];
        delete item['titleTransferId'];
        delete item['date'];
        delete item['roundOffRate'];
        delete item['standardDeliveryRate'];
        delete item['isBlended'];
        delete item['isBlendingFees'];
        if (has(item, 'adjustmentDetails')) {
          delete item['adjustmentDetails']['exShrinkageReceivalPrice'];
          delete item['adjustmentDetails']['itemId'];
          delete item['adjustmentDetails']['pricePointId'];
        }
        item["adjustments"] = item['adjustmentDetails'] || {};
      }
      if (item.itemType === GRAIN_LEVY_ITEM_TYPE) {
        item['gst'] = 0;
        item['subTotal'] = 0;
        item['date'] = moment(item['date'], 'DD/MM/YYYY').format('MM/DD/YYYY');
        item["adjustments"] = {
          'itemForType': get(item, 'itemForType'),
          'pricePointId': get(item, 'pricePointId'),
          'contractValue': get(item, 'contractValue'),
          'tonnage': get(item, 'tonnage'),
          'totalDistance': get(item, 'totalDistance'),
          'rate': get(item, 'rate'),
          'actualPrice': get(item, 'actualPrice'),
          'total': get(item, 'total'),
          'itemType': item.itemType,
          'multiplier': get(item, 'multiplier'),
          'levyAdjustmentIncluded': get(item, 'levyAdjustmentIncluded'),
          'standardDeliveryRate': get(item, 'standardDeliveryRate'),
          'adjustedPriceValue': get(item, 'adjustedPriceValue'),
        }
        delete item['standardDeliveryRate'];
        delete item['levyAdjustmentIncluded'];
        delete item['adjustedPriceValue'];
      }
      if (item.itemType === EPR_ITEM_TYPE) {
        item["adjustments"] = {
          'rate': get(item, 'rate'),
          'subTotal': get(item,'subTotal'),
          'tonnage': get(item, 'tonnage'),
          'itemType': item.itemType,
          'actualPrice': get(item, 'actualPrice')
        }
      }
      if (item.itemType === CARRY_ITEM_TYPE) {
        item["adjustments"] = {
          'rate': get(item, 'rate'),
          'carryRatePerPeriod': get(item, 'carryRatePerPeriod'),
          'numberOfCarryPeriods': get(item, 'numberOfCarryPeriods'),
          'subTotal': get(item,'subTotal'),
          'tonnage': get(item, 'tonnage'),
          'itemType': item.itemType,
          'startDate': get(item, 'startDate'),
          'date': get(item, 'date'),
        }
      }
      delete item['actualPrice'];
      delete item['adjustmentDetails'];
      delete item['adjustedPrice'];
      delete item['contractPrice'];
      delete item['numberOfCarryPeriods'];
      delete item['carryRatePerPeriod'];
      delete item['startDate'];
      delete item['isBhc'];
      delete item['totalDistance'];
      delete item['contractValue'];
      delete item['pricePointId'];
      delete item['itemForType'];
      delete item['shrinkage'];
      delete item['receivalFees'];
      delete item['customAdjustments'];
      delete item['invoice'];
      delete item['freightMovementIdentifier'];
      delete item['titleTransferIdentifier'];
      delete item['loadIdentifier'];
      delete item['paymentDate'];
      delete item['multiplier'];
      if (has(item, 'currency'))
        delete item['currency'];
    });
    data.subTotal = this.state.subTotal;
    data.gst = this.state.gst;
    data.total = this.state.total;
    data.type = 'Commodity Contract';
    delete data['buyerNgrId'];
    delete data['sellerNgrId'];
    delete data['buyerContact'];
    delete data['sellerContact'];
    delete data['selectedSellerParty'];
    delete data['selectedBuyerParty'];
    delete data['selectedContract'];

    this.setPayerData(data, company);
    this.setPayeeData(data);

    data.note = omit(this.state.fields.note, ['errors']);
    return data;
  }

  selectedSellerNgr() {
    return find(this.state.sellerNgrs, { id: this.state.fields.sellerNgrId.value }) || get(this.props.contract, 'seller.ngr');
  }

  selectedBuyerNgr() {
    return find(this.state.buyerNgrs, { id: this.state.fields.buyerNgrId.value }) || get(this.props.contract, 'buyer.ngr');
  }

  setPayeeData(data) {
    data['payee'] = {};
    data['payee']['companyId'] = this.props.contract.seller.companyId;
    data['payee']['contactId'] = this.state.fields.sellerContact.value.id;
    data['payee']['ngrId'] = this.state.fields.sellerNgrId.value;
    this.setEntityBankDetails(data, 'payee');
  }

  setEntityBankDetails(data, key) {
    data[key]['bankAccount'] = {};
    data[key]['bankAccount']['bank_id'] = get(find(this.props.banks, {name: this.state.fields[key].bankName.value}), 'id', '');
    data[key]['bankAccount']['accountName'] = this.state.fields[key].accountName.value;
    data[key]['bankAccount']['bsbNumber'] = this.state.fields[key].bsbNumber.value;
    data[key]['bankAccount']['accountNumber'] = this.state.fields[key].accountNumber.value;
  }

  setPayerData(data, company) {
    data['payer'] = {};
    data['payer']['companyId'] = company.id;
    data['payer']['contactId'] = this.state.fields.buyerContact.value.id;
    data['payer']['ngrId'] = this.state.fields.buyerNgrId.value;
    this.setEntityBankDetails(data, 'payer');
  }

  focusOnFirstErrorField() {
    const autoCompleteFields = ['contractNumber', 'buyerContact', 'sellerContact', 'payeeBankName'];
    const nestedFields = ['payee.accountName', 'payee.accountNumber', 'payee.bsbNumber', 'payee.accountNumber'];

    for (let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];

      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (this.fieldsOrder[i] === 'payee.bankName' && this.state.fields.payee.bankName.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
        if (
          (this.fieldsOrder[i] === 'payee.accountName' && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.accountNumber' && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === 'payee.bsbNumber' && this.state.fields.payee.accountName.errors.length > 0)
        ) {
          formField.current.focus();
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (formField.current.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField.current.focus();
          break;
        }
      }
    }
  }

  handlePreviewSubmit() {
    this.openCustomEmailDialog();
  }

  handleSellerNgrChange(id) {
    const newState = { ...this.state };
    newState.fields.sellerNgrId.value = id;
    if (id) {
      let sellerNgr = filter(this.state.sellerNgrs, {id: id});
      let sellerNgrAccountNumber = get(sellerNgr[0], 'bankAccounts[0].accountNumber');
      newState.fields.sellerNgrId.validators = [required()];
      newState.sellerNgrAccountNumber = sellerNgrAccountNumber;
    } else {
      newState.sellerNgrAccountNumber = '';
      newState.fields.sellerNgrId.validators = [];
    }
    newState.displaySellerBankForm = !id;
    this.closeNgrSideDrawer();
    this.setState(newState, () => this.setSellerNgrErrors());
  }

  setSellerNgrErrors = () => {
    const newState = { ...this.state };
    this.getSellerNgrErrors(newState);
    this.setState(newState);
  };

  getSellerNgrErrors(newState) {
    const sellerNgr = this.selectedSellerNgr();
    const selectedSellerParty = this.state.fields.selectedSellerParty.value || get(this.props.contract, 'seller');
    const sellerErrorMsg = (canAccessAny(get(selectedSellerParty, 'company') || selectedSellerParty) && get(selectedSellerParty, 'company.isRegistered')) || isCurrentUserBelongsToCompany(selectedSellerParty.id)
      ? NGR_MISSING_DETAILS_ERROR
      : NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS;
    newState.fields['sellerNgrId'].errors = [];
    if (this.state.fields.sellerNgrId.value && selectedSellerParty) {
      if (!isEmpty(sellerNgr.bankAccounts)) {
        sellerNgr.bankAccounts.forEach(account => {
          if (!(account.name && account.accountName && account.accountNumber && account.bsbNumber)) {
            newState.fields['sellerNgrId'].errors = [sellerErrorMsg];
          }
        });
      } else {
        newState.fields['sellerNgrId'].errors = [sellerErrorMsg];
      }
    }
  }

  handleBuyerNgrChange(id) {
    const newState = { ...this.state };
    this.setFieldValue('buyerNgrId', id);
    this.closeNgrSideDrawer();
    newState.displayBuyerBankForm = !id;
    this.setState(newState, () => {
      if (get(this.props.contract, 'buyer.company.contractInvoicingFrom'))
        this.setBuyerNgrErrors()
    });
  }

  handlePaymentDueDateChange(value, id) {
    this.paymentDefault = true;
    this.setFieldValue(id, value);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleAccountChangeForEntity(event, key) {
    const newState = { ...this.state };
    const id = event.target.id;
    const value = event.target.value;
    newState.fields[key][id].value = value;
    if(id == 'bsbNumber' && value && isEqual(value.length, get(this.state.countryConfig,'bank.bsbLength'))) {
      let bsbBank = find(this.props.banks, {bsbNumber: newState.fields[key][id].value});
      if(bsbBank) {
        newState.fields[key]['bankName'].id = bsbBank.id;
        newState.fields[key]['bankName'].value = bsbBank.name;
        newState.fields[key]['bankName'].errors = [];
      }
    }
    this.setState(newState, () => {
      if (key === 'payee') {
        this.setPartyFieldErrors(`${key}.${id}`);
      }
    });
  }

  setPartyFieldErrors = id => {
    const newState = { ...this.state };
    let errors = [];
    const validators = get(newState, `fields.${id}.validators`);
    const value = get(newState, `fields.${id}.value`);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    set(newState, `fields.${id}.errors`, errors);
    this.setState(newState);
  };

  onPartyFieldBlur = event => {
    this.setPartyFieldErrors(event.target.id);
  };

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  setFieldValue(key, value) {
    this.setState(prevState => {
      const newState = { ...prevState };
      newState.fields[key].value = value;
      return newState;
    });
    this.setFieldErrors(key);
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key] ? this.state.fields[key].value : '';
    const validators = this.state.fields[key] ? this.state.fields[key].validators : [];
    if (validators) {
      validators.forEach(validator => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }
    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key),
        },
      },
    }));
  }

  handleIdentifierChange(event) {
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    this.setFieldValue(event.target.id, value);
  }

  getEstimatedPaymentDueDate(date, format = this.state.countryFormats.date) {
    const selectedPaymentTerm = get(this.props.contract, 'paymentTerm');
    const buyer = get(this.props.contract, 'buyer');
    let val = null;
    if (has(selectedPaymentTerm, 'id') && buyer && date) {
      if (selectedPaymentTerm.name.includes('Invoice Date'))
        date = new Date().toISOString();
      let startOfWeek = get(buyer, 'company.startOfWeek');
      let endOfWeek = get(buyer, 'company.endOfWeek');
      const periodType = get(selectedPaymentTerm, 'periodType');
      if (periodType == 'weeks') {
        const nextFridayFromDate = nextEndDayFrom(date, endOfWeek);
        val =
          Object.prototype.toString.call(nextFridayFromDate) == '[object Object]' &&
          nextFridayFromDate.add(selectedPaymentTerm.period, 'days').format(format);
      } else if (periodType == 'months') {
        val = moment(date)
          .endOf('month')
          .add(selectedPaymentTerm.period, 'days')
          .format(format);
      } else if (periodType == 'days') {
        if (selectedPaymentTerm.name.includes('Business Days') ) {
          const startDay = moment(date).weekday();
          const weekdays = getWeekDays(startOfWeek, endOfWeek);
          if (startDay || startDay == 0) {
            const daysToAdd = getDaysToAdd(startDay, selectedPaymentTerm.period, weekdays);
            val = moment(date).add(daysToAdd, 'days').format(format);
          }
        } else {
          val = moment(date).add(selectedPaymentTerm.period, 'days').format(format);
        }
      } else if(periodType == 'date'){
        val = getEstimatedPaymentDueDateForDatePaymentTerm(date, selectedPaymentTerm, format);
      } else {
        val = selectedPaymentTerm.name;
      }
      return val === 'Invalid date' ? moment().format(format) : val;
    } else {
      return null;
    }
  }

  estimatedPaymentDueDate(format = this.state.countryFormats.date, sortedFMs) {
    const selectedPaymentTerm = get(this.props.contract, 'paymentTerm');
    const buyer = get(this.props.contract, 'buyer');
    let val = null;
    let fmList = sortedFMs || get(this.state, 'freightMovements');
    fmList = fmList.concat(get(this.state, 'titleTransfers'));
    let fms = {};
    if(fmList)
      fms = filter(fmList, item => get(item, 'invoice'));
    if (has(selectedPaymentTerm, 'id') && buyer) {
      let latest_date = moment();
      if(!isEmpty(fms) && !selectedPaymentTerm.name.includes('Invoice Date'))
        latest_date = moment(Math.max(...fms.map(e => moment(get(e, 'paymentDate')))));
      let startOfWeek = get(buyer, 'company.startOfWeek');
      let endOfWeek = get(buyer, 'company.endOfWeek');
      const periodType = get(selectedPaymentTerm, 'periodType');
      if(periodType === 'unknown')
        return ''
      if (periodType == 'weeks') {
        const nextFridayFromDate = nextEndDayFrom(latest_date, endOfWeek);
        val =
          Object.prototype.toString.call(nextFridayFromDate) == '[object Object]' &&
          nextFridayFromDate.add(selectedPaymentTerm.period, 'days').format(format);
      } else if (periodType == 'months') {
        val = latest_date
          .endOf('month')
          .add(selectedPaymentTerm.period, 'days')
          .format(format);
      } else if (periodType == 'days') {
        if (selectedPaymentTerm.name.includes('Business Days') ) {
          const startDay = latest_date.weekday();
          const weekdays = getWeekDays(startOfWeek, endOfWeek);
          if (startDay || startDay == 0) {
            const daysToAdd = getDaysToAdd(startDay, selectedPaymentTerm.period, weekdays);
            val = latest_date.add(daysToAdd, 'days').format(format);
          }
        } else {
          val = latest_date.add(selectedPaymentTerm.period, 'days').format(format);
        }
      } else if(periodType == 'date'){
        val = getEstimatedPaymentDueDateForDatePaymentTerm(latest_date, selectedPaymentTerm, format);
      } else {
        val = selectedPaymentTerm.name;
      }
      return val === 'Invalid date' ? moment().format(format) : val;
    } else {
      return null;
    }
  }

  handleBuyerContactChange(value, id = 'buyerContact', item) {
    if(value && !item)
      item = find(this.state.buyerEmployees, {id: value})
    this.setFieldValue(id, item);
  }

  handleSellerContactChange(value, id = 'sellerContact', item) {
    if(value && item)
      item = find(this.state.sellerEmployees, {id: value})
    this.setFieldValue(id, item);
  }

  openSellerContactSideDrawer(party) {
    const selectedParty = get(party, 'company', party);
    const companyId = get(selectedParty, 'id');
    if (companyId) {
      const { dispatch } = this.props;
      dispatch(
        canCreateEmployee(companyId, 'company', () => {
          this.props.handleAddEmployeeButtonClick();
          this.commonOpenSideDrawer('sellerSideDrawerIsOpen');
        }),
      );
    }
  }

  alertPermissionError() {
    alertifyjs.alert(
      'Permission Denied',
      `<div className=""><p>You do not have permission to create ngr for the party because:</p><ul><li>You are not authorized.Please contact Company Admin </li></ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
      () => { },
    );
  }

  closeSellerContactSideDrawer() {
    this.setState({ sellerSideDrawerIsOpen: false });
  }

  openBuyerContactSideDrawer(party) {
    const selectedParty = get(party, 'company', party);
    const companyId = get(selectedParty, 'id');
    if (companyId) {
      const { dispatch } = this.props;
      dispatch(
        canCreateEmployee(companyId, 'company', () => {
          this.props.handleAddEmployeeButtonClick();
          this.commonOpenSideDrawer('buyerSideDrawerIsOpen');
        }),
      );
    }
  }

  closeBuyerContactSideDrawer() {
    this.setState({ buyerSideDrawerIsOpen: false });
  }

  openInvoiceConditionSideDrawer() {
    this.props.dispatch(clickAddGeneralConditionButton());
    this.commonOpenSideDrawer('invoiceConditionSideDrawerIsOpen');
  }

  closeInvoiceConditionSideDrawer() {
    this.setState({ invoiceConditionSideDrawerIsOpen: false });
  }

  commonOpenSideDrawer(type) {
    if (type !== 'ngrSideDrawerIsOpen') {
      this.closeNgrSideDrawer();
    }
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer);
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = 'selectedGeneralConditionText';

    const newState = { ...this.state };

    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }

    this.setState(newState);
  }

  isCurrentUserBuyer = () => isCurrentUserBelongsToCompany(this.state.fields.selectedBuyerParty.value || this.props.contract?.buyer?.companyId)

  getBuyerNgrErrors(newState) {
    const selectedBuyerParty = this.state.fields.selectedBuyerParty.value || get(this.props.contract, 'buyer');
    const isBuyer = this.isCurrentUserBuyer()
    if(!isBuyer)
      return
    const buyerNgr = this.selectedBuyerNgr();
    const buyerErrorMsg = (canAccessAny(get(selectedBuyerParty, 'company') || selectedBuyerParty) && get(selectedBuyerParty, 'company.isRegistered')) || isBuyer
      ? NGR_MISSING_DETAILS_ERROR
      : NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS;
    newState.fields['buyerNgrId'].errors = [];
    if (this.state.fields.buyerNgrId.value && selectedBuyerParty) {
      if (!isEmpty(buyerNgr.bankAccounts)) {
        buyerNgr.bankAccounts.forEach(account => {
          if (!(account.name && account.accountName && account.accountNumber && account.bsbNumber)) {
            newState.fields['buyerNgrId'].errors = [buyerErrorMsg];
          }
        });
      } else {
        newState.fields['buyerNgrId'].errors = [buyerErrorMsg];
      }
    }
  }

  setBuyerNgrErrors() {
    if(!this.isCurrentUserBuyer())
      return
    const newState = { ...this.state };
    newState.fields.buyerNgrId.validators = newState.displayBuyerBankForm ? [] : [required()];
    newState.fields.buyerNgrId.errors = [];
    forEach(newState.fields.buyerNgrId.validators, validator => {
      if(validator.isInvalid(newState.fields.buyerNgrId.value))
        newState.fields.buyerNgrId.errors.push(validator.message);
    });

    if (isEmpty(newState.fields.buyerNgrId.errors) && newState.fields.buyerNgrId.value)
      this.getBuyerNgrErrors(newState);

    this.setState(newState);
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    if (this.props.contract) {
      forEach(['selectedContract', 'selectedBuyerParty', 'selectedSellerParty'], key => {
        newState.fields[key].errors = [];
      });
      if (get(this.props.contract, 'buyer.company.contractInvoicingFrom') && !newState.displayBuyerBankForm)
        this.setBuyerNgrErrors();
    }
    if (this.state.displaySellerBankForm) {
      applyValidatorsOn(newState.fields.payee);
    }
    this.setState(newState, callback);
    if (isEmpty(newState.fields['sellerNgrId'].errors)) {
      this.setSellerNgrErrors();
    }
  }

  openSideDraw() {
    this.commonOpenSideDrawer('openSideDrawer');
  }

  closeSideDraw() {
    const newState = { ...this.state };
    newState.openSideDrawer = false;
    this.setState(newState);
  }

  openAddNgrSideDrawer(ngrEntity, type) {
    ngrEntity.type = type;
    const selectedParty = get(ngrEntity, 'company', ngrEntity);
    const companyId = get(selectedParty, 'id');
    if (companyId && isCurrentUserBelongsToCompany(companyId)) {
      const { dispatch } = this.props;
      const selectedEntity = find(this.props.allCompanyParties, {id: companyId});
      dispatch(
        canCreateNgr(companyId, 'company', selectedEntity, () => {
          this.props.handleAddCompanyNgrButtonClick();
          this.props.handleAddNgrButtonClick();
          this.setState({ ngrEntity: selectedParty }, () => this.commonOpenSideDrawer('ngrSideDrawerIsOpen'));
        }),
      );
    }
    else
      this.alertPermissionError();
  }

  closeAddNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }

  checkDueDate() {
    const newState = { ...this.state };
    const paymentDueDate = this.state.fields.paymentDueDate.value;
    const estimatedDate = this.estimatedPaymentDueDate();
    if (moment(estimatedDate).isAfter(moment(paymentDueDate))) {
      newState.fields.paymentDueDate.errors = [
        'Warning: Minimum Payment Due Date as per payment terms of the contract is ' + moment(estimatedDate).format(this.state.countryFormats.date),
      ];
      this.setState(newState);
    }
  }

  getAddBuyerContactButton() {
    const party = this.state.fields.selectedBuyerParty.value || get(this.props.contract, 'buyer');
    return (
      party && <AddCircleIconWithTooltip id='addBuyerContact' onClick={() => this.openBuyerContactSideDrawer(party)} title='Add Buyer Contact' />
    );
  }

  getAddSellerContactButton() {
    const party = this.state.fields.selectedSellerParty.value || get(this.props.contract, 'seller');
    return (
      party && <AddCircleIconWithTooltip id='addSellerContact' onClick={() => this.openSellerContactSideDrawer(party)} title='Add Seller Contact' />
    );
  }

  getSellerCompanyDetails() {
    return {
      company: this.getSelectedSellerCompany(),
      value: 'seller',
    };
  }

  getBuyerCompanyDetails() {
    return {
      company: this.getSelectedBuyerCompany(),
      value: 'buyer',
    };
  }

  sellerSideDrawer() {
    const sellerDetails = this.getSellerCompanyDetails();
    const seller = get(sellerDetails, 'company');
    const companyId = get(seller, 'id');
    return (
      this.props.isEmployeeSideDrawerOpened &&
      seller && (
        <SideDrawer
          isOpen={this.state.sellerSideDrawerIsOpen}
          title={getLabelForEmployeeForm(seller)}
          onClose={this.closeSellerContactSideDrawer}
          app='officeEmployee'
          canCreate={true}
        >
          <CreateEmployee
            closeDrawer={this.closeSellerContactSideDrawer}
            canAccessAny={true}
            companyId={companyId}
            selectedCompany={seller}
            establishmentDetails={sellerDetails}
            getContacts={this.getCompanyEmployees}
          />
        </SideDrawer>
      )
    );
  }

  buyerSideDrawer() {
    const buyerDetails = this.getBuyerCompanyDetails();
    const buyer = get(buyerDetails, 'company');
    const companyId = get(buyer, 'id');
    return (
      this.props.isEmployeeSideDrawerOpened &&
      buyer && (
        <SideDrawer
          isOpen={this.state.buyerSideDrawerIsOpen}
          title={getLabelForEmployeeForm(buyer)}
          onClose={this.closeBuyerContactSideDrawer}
          app='officeEmployee'
          canCreate={true}
        >
          <CreateEmployee
            closeDrawer={this.closeBuyerContactSideDrawer}
            canAccessAny={true}
            companyId={companyId}
            selectedCompany={buyer}
            establishmentDetails={buyerDetails}
            getContacts={this.getCompanyEmployees}
          />
        </SideDrawer>
      )
    );
  }

  getPartyEmails() {
    return {
      buyer: get(find(this.state.buyerEmployees, { id: get(this.state.fields, 'buyerContact.value.id') }), 'email'),
      seller: get(find(this.state.sellerEmployees, { id: get(this.state.fields, 'sellerContact.value.id') }), 'email'),
      broker: get(this.props, 'user.email'),
    };
  }

  getEmailPopupPartiesCompanyIds() {
    const parties = ['broker', 'buyer', 'seller'];
    const ids = {};
    forEach(parties, party => {
      if (party === 'broker')
        ids.broker = get(this.props, 'user.companyId');
      if (party === 'buyer')
        ids.buyer = get(this.state.buyerEmployees, '0.companyId');
      if (party === 'seller')
        ids.seller = get(this.state.sellerEmployees, '0.companyId');
    });

    return ids;
  }

  async getPartyContacts() {
    if (this.gotOncePartyContacts)
      return;

    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    const partiesWithoutContacts = without(keys(parties), 'seller', 'buyer');
    const contacts = {};
    forEach(parties, (id, party) => {
      contacts[party] = [];
      if (party === 'buyer')
        contacts[party] = this.state.buyerEmployees;
      if (party === 'seller')
        contacts[party] = this.state.sellerEmployees;
    });
    if (!isEmpty(partiesWithoutContacts)) {
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if (isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.token);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, { companyId: parties[party] });
      });
    }

    return contacts;
  }

  noteHandler = val => {
    this.setState({ fields: { ...this.state.fields, note: val } });
  };

  getSelectedParties() {
    if (get(this.props.contract, 'buyer.companyId') === this.props.user.companyId) return ['seller'];
    else if (get(this.props.contract, 'seller.companyId') === this.props.user.companyId) return ['buyer'];
    else if ((get(this.props.contract, 'seller.representedById') || get(this.props.contract, 'buyer.representedById')) === this.props.user.companyId) return ['seller', 'buyer'];
  }

  handleShrinkageChange(item, value) {
    let itemType = get(item, 'itemType')
    let items = itemType === FREIGHT_CONTRACT_ITEM_TYPE ? this.state.freightMovements : itemType === TITLE_TRANSFER_ITEM_TYPE ? this.state.titleTransfers : this.state.loads;
    let objItem = find(items, {itemId: item.itemId});
    if (objItem) {
      const newState = {...this.state};
      let objects = items.map(obj => {
        if (obj.itemId === item.itemId) {
          return {...obj, shrinkage: value}
        }
        return obj;
      });
      if (itemType === FREIGHT_CONTRACT_ITEM_TYPE)
        newState.freightMovements = objects;
      else if (itemType === TITLE_TRANSFER_ITEM_TYPE)
        newState.titleTransfers = objects;
      else
        newState.loads = objects;
      this.setState(newState);
    }
  }

  handleReceivalFeesChange(item, value) {
    let itemType = get(item, 'itemType')
    let items = itemType === FREIGHT_CONTRACT_ITEM_TYPE ? this.state.freightMovements : itemType === TITLE_TRANSFER_ITEM_TYPE ? this.state.titleTransfers : this.state.loads;
    let objItem = find(items, {itemId: item.itemId});
    if (objItem) {
      const newState = {...this.state};
      let objects = items.map(obj => {
        if (obj.itemId === item.itemId) {
          return {...obj, receivalFees: value};
        }
        return obj;
      });
      if (itemType === FREIGHT_CONTRACT_ITEM_TYPE)
        newState.freightMovements = objects;
      else if (itemType === TITLE_TRANSFER_ITEM_TYPE)
        newState.titleTransfers = objects;
      else
        newState.loads = objects;
      this.setState(newState);
    }
  }

  handleCustomAdjustments(item, adjustments) {
    let itemType = get(item, 'itemType')
    let items = itemType === FREIGHT_CONTRACT_ITEM_TYPE ? this.state.freightMovements : itemType === TITLE_TRANSFER_ITEM_TYPE ? this.state.titleTransfers : this.state.loads;
    let objItem = find(items, {itemId: item.itemId});
    if (objItem) {
      const newState = {...this.state};
      let objects = items.map(obj => {
        if (obj.itemId === item.itemId) {
          return {...obj, customAdjustments: get(adjustments, 'customAdjustments')}
        }
        return obj;
      });
      if (itemType === FREIGHT_CONTRACT_ITEM_TYPE)
        newState.freightMovements = objects;
      else if (itemType === TITLE_TRANSFER_ITEM_TYPE)
        newState.titleTransfers = objects;
      else
        newState.loads = objects;
      this.setState(newState);
    }
  }

  handleLevyStandardDeliveryRateChange(item, adjustments) {
    for (const key in adjustments) {
      item[key] = adjustments[key];
    }
    this.updateGrainLevies(item, true)
  }

  toggleLevyAdjustments = event => {
    let isChecked = event.target.checked;
    const newState = {...this.state};
    if (isChecked) {
      const updatedGrainLevies = this.state.grainLevies.map(item => ({
        ...item,
        levyAdjustmentIncluded: true,
        total: item.total + item.adjustedPriceValue,
        subTotal: item.subTotal + item.adjustedPriceValue,
        actualPrice: item.actualPrice + item.adjustedPriceValue,
        adjustedPrice: item.adjustedPriceValue,
      }));
      const updatedSelectedGrainLevies = this.state.selectedItems.filter(selectedItem => selectedItem.itemType === GRAIN_LEVY_ITEM_TYPE).map(item => ({
        ...item,
        levyAdjustmentIncluded: true,
        total: item.total + item.adjustedPriceValue,
        subTotal: item.subTotal + item.adjustedPriceValue,
        actualPrice: item.actualPrice + item.adjustedPriceValue,
        adjustedPrice: item.adjustedPriceValue,
      }));
      remove(newState.selectedItems, {itemType: "grainlevy"})
  
      newState.grainLevies = [...updatedGrainLevies];
      newState.selectedItems = [...newState.selectedItems, ...updatedSelectedGrainLevies]
    }
    else {
      const updatedGrainLevies = this.state.grainLevies.map(item => ({
        ...item,
        levyAdjustmentIncluded: false,
        total: item.total - item.adjustedPriceValue,
        subTotal: item.subTotal - item.adjustedPriceValue,
        actualPrice: item.actualPrice - item.adjustedPriceValue,
        adjustedPrice: 0,
      }));
      const updatedSelectedGrainLevies = this.state.selectedItems.filter(selectedItem => selectedItem.itemType === GRAIN_LEVY_ITEM_TYPE).map(item => ({
        ...item,
        levyAdjustmentIncluded: false,
        total: item.total - item.adjustedPriceValue,
        subTotal: item.subTotal - item.adjustedPriceValue,
        actualPrice: item.actualPrice - item.adjustedPriceValue,
        adjustedPrice: 0,
      }));
      remove(newState.selectedItems, {itemType: "grainlevy"})
  
      newState.grainLevies = [...updatedGrainLevies];
      newState.selectedItems = [...newState.selectedItems, ...updatedSelectedGrainLevies]
    }
    this.setState(newState);
  }

  render() {
    const isStrictQuantityBasedCommodity = this.props?.contract?.isStrictQuantityBasedCommodity
    const unit = this.props.contract?.commodity?.priceUnit || 'MT'
    const currency = get(this.props.contract, 'currency') || getCountryCurrency()
    let COLUMNS = [
      { key: 'invoice', header: 'Select Item', checkbox: true, onChange: 'handleItemClick' },
      { key: 'description', header: 'Description', className: 'xlarge' },
      { key: 'date', header: 'Date' },
      { key: 'paymentDueDate', header: 'Payment Due Date' },
      { key: 'contractPrice', header: `Contract Price (Ex ${getCountryLabel('gst')})` },
      { key: 'adjustedPrice', header: `Price Adjustments (Ex ${getCountryLabel('gst')})`, showDetails: true },
      { key: 'rate', header: `Price (Ex ${getCountryLabel('gst')})` },
      { key: 'subTotal', header: `Total (Ex ${getCountryLabel('gst')})` },
      { key: 'gst', header: `${getCountryLabel('gst')}` },
      { key: 'total', header: `Total (Inc ${getCountryLabel('gst')})` },
    ];

    let tonnageColumn = { key: 'tonnage', header: `${this.state.countryConfig?.labels?.tonnage} (in ${unit})`, showQuantityAdjustments: true };
    const quantityColumn = { key: 'tonnage', header: `Quantity (in ${this.props.contract?.quantityUnit})` };
    if (isStrictQuantityBasedCommodity) tonnageColumn = quantityColumn;

    COLUMNS.splice(4, 0, tonnageColumn);

    const isLockedContract = includes(this.props.location.search, 'commodityContractId=') || this.selectedInvoiceId;

    const seller = get(this.props.contract, 'seller.company.name', '');
    const buyer = get(this.props.contract, 'buyer.company.name', '');
    const sellerAccountNumber = this.state.fields.payee.accountNumber.value || this.state.sellerNgrAccountNumber;
    let sellerAccountNumberDisplay = sellerAccountNumber;
    if (sellerAccountNumber) {
      const sellerNgr = find(this.state.sellerNgrs, {id: this.state.fields.sellerNgrId.value});
      if (sellerNgr) {
        const bankAccount = get(sellerNgr, 'bankAccounts.0');
        sellerAccountNumberDisplay = toBankAccountFormat(bankAccount);
      }
    }
    const bankItems = this.props.banks && uniqBy(this.props.banks.map(e => ({ label: e.name, value: e.id })), 'label');
    const selectedSellerParty = this.state.fields.selectedSellerParty.value || get(this.props.contract, 'seller');
    const selectedBuyerParty = this.state.fields.selectedBuyerParty.value || get(this.props.contract, 'buyer');
    const selectedSellerCompany = get(selectedSellerParty, 'company') || selectedSellerParty;
    const selectedBuyerCompany = get(selectedBuyerParty, 'company') || selectedBuyerParty;
    const isBuyerNgrMandatory = get(this.props.contract, 'buyer.company.contractInvoicingFrom') && isCurrentUserBelongsToCompany(selectedBuyerCompany?.id)
    const editSellerNgrButton = get(this.state.fields, 'sellerNgrId.value') ? (
      <Button
        variant='outlined'
        onClick={() => this.openNgrSideDrawer(selectedSellerParty, this.state.sellerNgrs, get(this.state.fields, 'sellerNgrId.value'))}
        className='edit-ngr-button'
      >
        {isCurrentUserBelongsToCompany(selectedSellerCompany.id) ? 'Update NGR Details' : 'View NGR Details'}
      </Button>
    ) : null;
    const editBuyerNgrButton = this.state.fields.buyerNgrId.value ? (
      <Button
        variant='outlined'
        onClick={() => this.openNgrSideDrawer(selectedBuyerParty, this.state.buyerNgrs, this.state.fields.buyerNgrId.value)}
        className='edit-ngr-button'
      >
        {isCurrentUserBelongsToCompany(selectedBuyerCompany.id) ? 'Update NGR Details' : 'View NGR Details'}
      </Button>
    ) : null;
    const addSellerNgrButton =
      selectedSellerParty && seller ? (
        <Tooltip title='Add Seller NGR' placement='top'>
          <AddCircleIcon
            id='addSellerNgr'
            onClick={() => this.openAddNgrSideDrawer(selectedSellerParty, 'Seller')}
            className='material-icons add-new-right'
            style={{ fontSize: '30px' }}
          />
        </Tooltip>
      ) : null;
    const addBuyerNgrButton =
      selectedBuyerParty && buyer ? (
        <Tooltip title='Add Buyer NGR' placement='top'>
          <AddCircleIcon
            id='addBuyerNgr'
            onClick={() => this.openAddNgrSideDrawer(selectedBuyerParty, 'Buyer')}
            className='material-icons add-new-right'
            style={{ fontSize: '30px' }}
          />
        </Tooltip>
      ) : null;
    return (
      <div ref={this.formRef}>
        <form className='invoice-form' onSubmit={this.handleSubmit} noValidate style={this.state.amendNotAllowed ? {filter: 'blur(4px)'} : {}}>
          <div className='cardForm'>
            {this.state.removeLoader && <div className='dom-for-allocation-loader'></div>}
            <h4 className='cardForm-title'>General</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
                <div className='col-md-12 padding-reset form-wrap'>
                  {
                    isLockedContract ?
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField id='seller' label='Seller' value={seller} disabled />
                    </div> :
                      <div className='col-md-12 padding-reset form-wrap'>
                        <CommonAutoSelect
                          id='seller'
                          label='Seller'
                          items={this.state.billToParties}
                          dataSourceConfig={{ text: 'displayName', value: 'id' }}
                          onBlur={this.onFieldBlur}
                          onChange={this.handleSellerChange}
                          selectedItem={this.state.fields.selectedSellerParty.value}
                          errorText={this.state.fields.selectedSellerParty.errors[0]}
                        />
                      </div>
                    }
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  {
                    isLockedContract ?
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField id='buyer' label='Buyer' value={buyer} disabled />
                    </div> :
                      <div className='col-md-12 padding-reset form-wrap'>
                        <CommonAutoSelect
                          id='buyer'
                          label='Buyer'
                          items={this.state.billToParties}
                          dataSourceConfig={{ text: 'displayName', value: 'id' }}
                          onBlur={this.onFieldBlur}
                          onChange={this.handleBuyerChange}
                          selectedItem={this.state.fields.selectedBuyerParty.value}
                          errorText={this.state.fields.selectedBuyerParty.errors[0]}
                        />
                      </div>
                    }
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  {
                    isLockedContract ?
                    <CommonTextField
                      id='contractNumber'
                      label='Contract No'
                      value={this.props.contract?.referenceNumber}
                      disabled
                    /> :
                      <CommonAutoSelect
                        label='Contract No'
                        id='contractNumber'
                        setRef={this.fieldRef['contractNumber']}
                        dataSourceConfig={{ text: 'commodityContractInvoiceDisplayName', value: 'id' }}
                        items={this.state.filteredContracts}
                        onBlur={this.onFieldBlur}
                        onChange={this.handleContractChange}
                        value={this.state.fields.selectedContract.value}
                        placeholder='Select Seller & Buyer to find relevant contracts'
                        errorText={this.state.fields.selectedContract.errors[0]}
                        dontAutoselectSingleItem
                      />
                    }
                </div>
              </div>
              <div className='col-md-5 col-md-offset-1'>
                <div className='col-md-12 padding-reset form-wrap'>
                  <TextField
                    id='identifier'
                    inputRef={this.fieldRef['identifier']}
                    error={!isEmpty(this.state.fields.identifier.errors[0])}
                    placeholder='Please enter 14 digit unique number'
                    label='Invoice No'
                    value={this.state.fields.identifier.value}
                    helperText={this.state.fields.identifier.errors[0]}
                    onChange={this.handleIdentifierChange}
                    onBlur={this.handleBlur}
                    fullWidth
                    inputProps={{
                      maxLength: 14,
                    }}
                    variant='standard'
                    disabled={Boolean(this.selectedInvoiceId)}
                  />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <div className='col-md-12 padding-reset form-wrap'>
                    <CommonDatePicker
                      id='paymentDueDate'
                      setRef={this.fieldRef['paymentDueDate']}
                      floatingLabelText='Payment Due Date'
                      errorText={this.state.fields.paymentDueDate.errors[0]}
                      value={this.state.fields.paymentDueDate.value}
                      onChange={this.handlePaymentDueDateChange}
                      onBlur={this.checkDueDate}
                      disabled={!this.props.contract}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Invoicing Parties</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonTextField id='buyerParty' label='Buyer' value={buyer} disabled />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonAutoSelect
                    id='buyerContact'
                    setRef={this.fieldRef['buyerContact']}
                    label='Contact'
                    items={this.state.buyerEmployees}
                    value={this.state.fields.buyerContact.value}
                    onBlur={this.onFieldBlur}
                    onChange={this.handleBuyerContactChange}
                    errorText={this.state.fields.buyerContact.errors[0]}
                    selectedItem={this.state.fields.buyerContact.value}
                    dontAutoselectSingleItem
                  />
                  {this.getAddBuyerContactButton()}
                </div>
                <div className='col-md-12 padding-reset form-wrap' ref={this.fieldRef['buyerNgrId']}>
                  <CommonSelect
                    id='buyerNgr'
                    floatingLabelText={this.state.displayBuyerBankForm ? 'Enter Bank Details' : isBuyerNgrMandatory ? 'Buyer NGR': 'Buyer NGR (Optional)'}
                    selectConfig={{ text: 'ngrNumber', value: 'id' }}
                    items={this.state.buyerNgrs}
                    fullWidth
                    onChange={this.handleBuyerNgrChange}
                    errorText={this.state.fields.buyerNgrId.errors[0]}
                    value={this.state.fields.buyerNgrId.value}
                    includeEmptyOption={isCurrentUserBelongsToCompany(this.state.fields.selectedBuyerParty.value?.id)}
                    emptyOptionText='Enter Bank Details'
                    dontAutoselectSingleItem
                  />
                  {addBuyerNgrButton}
                  {editBuyerNgrButton}
                </div>
                {this.state.displayBuyerBankForm ? (
                  <div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField
                        id='bsbNumber'
                        label={`${getCountryLabel('bsb')}  Number (Optional)`}
                        value={this.state.fields.payer.bsbNumber.value}
                        onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                        type='number'
                        placeholder={`Please enter ${get(this.state.countryConfig, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, get(this.state.countryConfig, 'bank.bsbLength'));
                        }}
                      />
                    </div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <AutoComplete
                        id='name'
                        label='Bank Name (Optional)'
                        placeholder='Bank Name (Optional)'
                        options={bankItems}
                        fullWidth
                        errorText={this.state.fields.payer.bankName.errors[0]}
                        onBlur={this.onPartyFieldBlur}
                        value={get(this.state.fields.payer.bankName, 'value')}
                        onChange={this.handlePayerBankChange()}
                      />
                    </div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField
                        id='accountName'
                        label='Account Name (Optional)'
                        value={this.state.fields.payer.accountName.value}
                        onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                      />
                    </div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField
                        id='accountNumber'
                        label='Account Number (Optional)'
                        value={this.state.fields.payer.accountNumber.value}
                        placeholder={`Please enter ${get(this.state.countryConfig, 'bank.accountNumberMinimumLength')}-${get(this.state.countryConfig, 'bank.accountNumberMaximumLength')} digits Account number`}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, get(this.state.countryConfig, 'bank.accountNumberMaximumLength'));
                        }}
                        type='number'
                        onChange={event => this.handleAccountChangeForEntity(event, 'payer')}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className='col-md-5 col-md-offset-1'>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonTextField id='sellerParty' label='Seller' value={seller} disabled />
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonAutoSelect
                    id='sellerContact'
                    setRef={this.fieldRef['sellerContact']}
                    label='Contact'
                    items={this.state.sellerEmployees}
                    value={this.state.fields.sellerContact.value}
                    onBlur={this.onFieldBlur}
                    onChange={this.handleSellerContactChange}
                    errorText={this.state.fields.sellerContact.errors[0]}
                    selectedItem={this.state.fields.sellerContact.value}
                    dontAutoselectSingleItem
                  />
                  {this.getAddSellerContactButton()}
                </div>
                <div className='col-md-12 padding-reset form-wrap'>
                  <CommonSelect
                    id='sellerNgr'
                    label={this.state.displaySellerBankForm ? 'Enter Bank Details' : 'Seller NGR'}
                    setRef={this.fieldRef['sellerNgrId']}
                    floatingLabelText={this.state.displaySellerBankForm ? 'Enter Bank Details' : 'Seller NGR'}
                    selectConfig={{ text: 'ngrNumber', value: 'id' }}
                    items={this.state.sellerNgrs}
                    fullWidth
                    onChange={this.handleSellerNgrChange}
                    errorText={this.state.fields.sellerNgrId.errors[0]}
                    value={this.state.fields.sellerNgrId.value}
                    includeEmptyOption={isCurrentUserBelongsToCompany(this.state.fields.selectedSellerParty.value?.id)}
                    emptyOptionText='Enter Bank Details'
                    dontAutoselectSingleItem
                  />
                  {addSellerNgrButton}
                  {editSellerNgrButton}
                </div>
                {this.state.displaySellerBankForm ? (
                  <div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField
                        id='bsbNumber'
                        label={`${getCountryLabel('bsb')} Number`}
                        value={this.state.fields.payee.bsbNumber.value}
                        setRef={this.fieldRef['payee.bsbNumber']}
                        helperText={this.state.fields.payee.bsbNumber.errors[0]}
                        type='number'
                        onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                        placeholder={`Please enter ${get(this.state.countryConfig, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, get(this.state.countryConfig, 'bank.bsbLength'));
                        }}
                      />
                    </div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <AutoComplete
                        id='name'
                        label='Bank Name'
                        placeholder='Bank Name'
                        options={bankItems}
                        fullWidth
                        setRef={this.fieldRef['payee.bankName']}
                        errorText={this.state.fields.payee.bankName.errors[0]}
                        onBlur={this.onFieldBlur}
                        value={this.state.fields.payee.bankName.value}
                        onChange={this.handlePayeeBankChange()}
                      />
                    </div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField
                        id='accountName'
                        label='Account Name'
                        setRef={this.fieldRef['payee.accountName']}
                        value={this.state.fields.payee.accountName.value}
                        helperText={this.state.fields.payee.accountName.errors[0]}
                        onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                      />
                    </div>
                    <div className='col-md-12 padding-reset form-wrap'>
                      <CommonTextField
                        id='accountNumber'
                        label='Account Number'
                        value={this.state.fields.payee.accountNumber.value}
                        setRef={this.fieldRef['payee.accountNumber']}
                        helperText={this.state.fields.payee.accountNumber.errors[0]}
                        type='number'
                        onChange={event => this.handleAccountChangeForEntity(event, 'payee')}
                        placeholder={`Please enter ${get(this.state.countryConfig, 'bank.accountNumberMinimumLength')}-${get(this.state.countryConfig, 'bank.accountNumberMaximumLength')} digits Account number`}
                        onInput={e => {
                          e.target.value = e.target.value.toString().slice(0, get(this.state.countryConfig, 'bank.accountNumberMaximumLength'));
                        }}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Items To Be Invoiced</h4>
            <div style={{ position: 'relative' }}>
              <AddButton
                disabled={!this.props.contract}
                variant="contained"
                label='Custom Item'
                primary={true}
                className={'btn-green'}
                onClick={this.openSideDraw}
                style={{ float: 'right', marginRight: '20px', marginTop: '0px', position: 'absolute', right: '0px', top: '-50px' }}
              />
            </div>
            <div>
              <SideDrawer isOpen={this.state.openSideDrawer} title='Add Item' onClose={this.closeSideDraw}>
                <InvoiceCustomItemForm closeDrawer={this.closeSideDraw} addItem={this.handleAddItem} currency={currency} />
              </SideDrawer>
            </div>
            <div>
              {
                this.props.isFreightMovementFetched && this.props.isTitleTransferFetched ?
                  <InvoiceTable
                    columns={COLUMNS}
                    titleTransfers={this.state.titleTransfers}
                    freightMovements={this.state.freightMovements}
                    blendedGradeLoads={this.state.blendedGradeLoads}
                    chemicalApplicationItems={this.state.chemicalApplicationItems}
                    isBlendedContract={get(this.props.contract, 'isBlended')}
                    customItems={this.state.customItems}
                    carryItems={this.state.carryItems}
                    grainLevies={this.state.grainLevies}
                    eprs={this.state.eprs}
                    loads={this.state.loads}
                    handleItemClick={this.handleItemClick}
                    handleSubTotal={this.handleSubTotal}
                    handleShrinkageChange={(item, value, id) => this.handleShrinkageChange(item, value, id)}
                    handleReceivalFeesChange={(item, value, id) => this.handleReceivalFeesChange(item, value, id)}
                    handleCustomAdjustments={(item, adjustments) => this.handleCustomAdjustments(item, adjustments)}
                    handleLevyStandardDeliveryRateChange={(item, adjustments) => this.handleLevyStandardDeliveryRateChange(item, adjustments)}
                    buyer={get(this.props.contract, 'buyer.company')}
                    seller={get(this.props.contract, 'seller.company')}
                    unit={unit}
                    currency={currency}
                    selectedItems={this.state.selectedItems}
                    contractId={this.state.fields.selectedContract.value}
                    toggleLevyAdjustments={this.toggleLevyAdjustments}
                  /> :
                ((this.state.fields.selectedContract.value || this.props.contract?.referenceNumber) ? <LoaderInline containerClassName="inline-loader-container" text='Loading items...' /> : null)
              }
            </div>
            {(some(this.state.customItems, item => item.gst === 'N/A') || !isEmpty(this.state.grainLevies)) && <div>{`* ${getCountryLabel('gst')} is not applicable`}</div>}
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Invoice Conditions</h4>
            <div className='cardForm-content'>
              <div className='col-md-5 form-wrap'>
                <CommonAutoSelect
                  items={this.props.generalConditionsAll}
                  id='generalConditionsSelector'
                  value={this.state.generalConditionsSelector}
                  label='Invoice'
                  onChange={this.handleConditionSelector}
                  dontAutoselectSingleItem
                />
                <AddCircleIconWithTooltip
                  id='addInvoiceCondition'
                  title='Add Invoice Condition'
                  onClick={() => this.openInvoiceConditionSideDrawer()}
                />
                <CommonTextField
                  className='top15 text-black'
                  id='invoiceConditionViewer'
                  value={this.state.selectedGeneralConditionText}
                  fullWidth
                  multiline={true}
                  rows={1}
                  disabled={true}
                  rowsMax={1}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className='cardForm-content col-md-1 arrow-button'>
                <IconButton
                  disabled={!isNumber(this.state.generalConditionsSelector)}
                  onClick={this.appendInvoiceConditions}
                  size="large">
                  <ArrowForward color='primary' />
                </IconButton>
              </div>
              <div className='cardForm-content col-md-5'>
                <div className='field-label'>Conditions (Optional)</div>
                <TextField
                  className='text-area'
                  id='invoiceConditions'
                  value={this.state.fields.invoiceConditions.value}
                  fullWidth
                  multiline={true}
                  onChange={this.handleFieldChange}
                  rows={6}
                />
              </div>
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Internal Notes (Optional)</h4>
            <div className='cardForm-content col-md-5'>
              <Notes
                title=''
                handler={this.noteHandler}
                note={this.state.fields.note}
                disabled={false}
              />
            </div>
          </div>
          <div className='cardForm'>
            <h4 className='cardForm-title'>Summary</h4>
            <div className='summary-section' style={{ marginLeft: '11px', marginBottom: '10px' }}>
              Against the Invoice ({this.state.fields.identifier.value}) raised by {this.props.user.company.displayName} , {buyer} agrees to pay{' '}
              {formatPrice(this.state.total, 'N/A', currency)} to {seller} on the mentioned account number#{sellerAccountNumberDisplay} by{' '}
              {moment(this.state.fields.paymentDueDate.value).format(this.state.countryFormats.dateDisplay)}.
              <div>{this.isBuyerRcti() ? ` ** PLEASE NOTE: The ${getCountryLabel('gst')} shown on this RCTI is payable by ${seller}` : ''}</div>
            </div>
            <div className='cardForm-content' style={{ marginLeft: '30%' }}>
              <div className='col-md-12 padding-reset form-wrap'>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>Total Quantity</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{this.state.fmTtTonnage} {this.props?.contract?.commodity?.priceUnit}</div>
                </div>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>{`Sub-total (Ex ${getCountryLabel('gst')})`}</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{formatPrice(this.state.subTotal, 'N/A', currency)}</div>
                </div>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>{getCountryLabel('gst')} {this.isBuyerRcti() ? '**' : ''}</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{formatPrice(this.state.gst, 'N/A',currency)}</div>
                </div>
                <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>Total Amount Payable {this.isBuyerRcti() ? '' : `(Inc ${getCountryLabel('gst')})`}</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{formatPrice(this.state.total, 'N/A', currency)}</div>
                </div>
              </div>
            </div>

            <div className='col-md-12 cardForm-action' style={{ marginLeft: '25px' }}>
              <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleConfirmDialogOpen} />
              <CommonButton type='submit' label='Continue and Review' primary={true} variant="contained" />
            </div>
          </div>
          <Dialog open={this.state.isConfirmDialogOpen} onClose={this.handleConfirmDialogCancel} scroll='paper'>
            <DialogTitle>All the values entered by you will be lost.</DialogTitle>
            <DialogContent>Are you sure want to cancel?</DialogContent>
            <DialogActions>
              <CommonButton key='no' label='No' color="default" onClick={this.handleConfirmDialogCancel} variant='outlined' />
              <CommonButton key='yes' label='Yes' primary={true} onClick={this.handleConfirmDialogOk} variant='flat' />
            </DialogActions>
          </Dialog>
          <Dialog open={this.state.preview} onClose={this.handlePreviewClose} scroll='paper' fullScreen>
            <DialogTitle>Invoice Preview</DialogTitle>
            <DialogContent>
              <InvoicePreview ref={el => (this.componentRef = el)} />
            </DialogContent>
            <DialogActions style={{paddingBottom: "40px"}}>
              <CommonButton label='Back' key='closeButton' default onClick={this.handlePreviewClose} variant='flat' />
              <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.handlePreviewSubmit} variant='flat' />
            </DialogActions>
          </Dialog>
        </form>
        <SideDrawer isOpen={this.props.isUpdateFormDisplayed} title='Edit NGR' size='big' onClose={this.closeNgrSideDrawer}>
          <UpdateCompanyNgr
            companyId={get(this.state.entityForNgrUpdate, 'id')}
            company={this.state.entityForNgrUpdate}
            canAccessAny={canAccessAny(this.state.entityForNgrUpdate)}
            bankAccountDetailsMandatory={true}
          />
        </SideDrawer>

        <SideDrawer isOpen={this.props.isUpdateFormForBillToDisplayed} title='Edit NGR' size='big' onClose={this.closeNgrSideDrawer}>
          <UpdateNgr
            farmId={get(this.state.entityForNgrUpdate, 'id')}
            canAccessAny={canAccessAny(this.state.entityForNgrUpdate)}
            bankAccountDetailsMandatory={true}
          />
        </SideDrawer>
        {this.state.ngrEntity && this.props.isCreateNgrFormDisplayed && (
          <SideDrawer isOpen={this.state.ngrSideDrawerIsOpen} title='Add NGR' size='big' onClose={this.closeAddNgrSideDrawer}>
            <CreateCompanyNgr
              companyId={this.state.ngrEntity.id}
              company={this.state.ngrEntity}
              canAccessAny={true}
              closeDrawer={this.closeAddNgrSideDrawer}
              onSuccess={this.getNgrs}
              bankAccountDetailsMandatory={true}
            />
          </SideDrawer>
        )}
        {this.sellerSideDrawer()}
        {this.buyerSideDrawer()}
        {this.props.isGeneralConditionSideDrawerOpened && (
          <SideDrawer
            isOpen={this.state.invoiceConditionSideDrawerIsOpen}
            title='Add Invoice Condition'
            onClose={this.closeInvoiceConditionSideDrawer}
            app='condition'
          >
            <CreateGeneralCondition
              selectedConditionType='invoice'
              closeDrawer={this.closeInvoiceConditionSideDrawer}
              onSuccess={this.setDefaultGeneralCondition}
            />
          </SideDrawer>
        )}
        {get(this.props.invoiceCreateResponse, 'defaultSubject') && this.state.showCustomEmailDialog && (
          <CustomEmailDialog
            parties={['broker', 'buyer', 'seller']}
            title='Email PDF copies to'
            subject={this.props.invoiceCreateResponse.defaultSubject}
            selectedParties={this.getSelectedParties()}
            noBody={true}
            open={this.state.showCustomEmailDialog}
            onClose={this.closeCustomEmailDialog}
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
            disableAcceptanceRequired={Boolean(this.selectedInvoiceId)}
            currentUserCompany={this.state.currentUserCompany}
            xero={this.state.currentUserCompany.xeroEnabled}
            xeroCounterPartyExists={this.state.xeroCounterPartyExists}
          />
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    handleAddEmployeeButtonClick: () => {
      dispatch(clickAddEmployeeButton());
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    titleTransfersResponse: () => dispatch(titleTransfersResponse([])),
    dispatch,
  };
};

const mapStateToProps = state => {
  var farmParties = [];
  var companyParties = [];
  if (!isEmpty(state.companies.contracts.parties.farms)) {
    farmParties = map(state.companies.contracts.parties.farms, farm => {
      return merge(farm, { name: farm.displayName, rel: 'farms', entity: 'farm' });
    });
  }
  if (!isEmpty(state.companies.companies.companyParties)) {
    companyParties = map(state.companies.companies.companyParties, company => {
      return merge(company, { rel: 'companies', entity: 'company' });
    });
  }
  const parties = farmParties.concat(companyParties);
  const banks = state.main.banks || [];
  const isFetchedTTs = state.companies.freights.isFetchedTTs;
  const isFetchedFMs = state.companies.freights.isFetchedFMs;
  const isFreightMovementFetched = state.companies.freights.isItemsFetched;
  const isTitleTransferFetched = state.companies.contracts.isTTFetched;
  return {
    token: state.main.user.token,
    user: state.main.user.user,
    contract: state.companies.contracts.selectedContract,
    titleTransfers: state.companies.contracts.titleTransfers,
    isTitleTransferFetched,
    freightMovements: state.companies.freights.items,
    isFreightMovementFetched,
    isFetchedTTs,
    isFetchedFMs,
    freightMovementCarryItems: state.companies.contracts.movementsCarryItems,
    titleTransfersCarryItems: state.companies.contracts.titleTransfersCarryItems,
    titleTransfersCanolaLoadCarryItems: state.companies.contracts.titleTransfersCanolaLoadCarryItems,
    generalConditionsAll: state.companies.conditions.generalConditions,
    invoiceId: state.companies.invoices.selectedInvoiceId,
    isUpdateFormDisplayed: state.companies.companyNgrs.isUpdateFormDisplayed,
    isUpdateFormForBillToDisplayed: state.companies.ngrs.isUpdateFormDisplayed,
    updatedBillToNgr: state.companies.ngrs.updatedNgr,
    updatedNgr: state.companies.companyNgrs.updatedNgr,
    parties,
    banks,
    serverErrors: state.companies.invoices.serverErrors,
    invoiceCreateResponse: state.companies.invoices.invoiceCreateResponse,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isGeneralConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    selectedInvoice: state.companies.invoices.selectedInvoice,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CommodityContractInvoiceForm);

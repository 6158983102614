import React from 'react';

import withStyles from '@mui/styles/withStyles';
import TextField from '@mui/material/TextField';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import { MAX_DATE } from '../../common/utils';
import { WARNING_ORANGE_CLASSES } from '../../common/constants';

const styles = () => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  ...WARNING_ORANGE_CLASSES,
});

class CommonDatePickerUncontrolled extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      minDate: props.minDate ? moment(props.minDate).format('YYYY-MM-DD') : null,
      maxDate: props.maxDate ? moment(props.maxDate).format('YYYY-MM-DD') : MAX_DATE,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);
  }

  componentDidUpdate(prevProps) {
    const minDate = this.props.minDate ? moment(this.props.minDate).format('YYYY-MM-DD') : null;
    const maxDate = this.props.maxDate ? moment(this.props.maxDate).format('YYYY-MM-DD') : null;
    if (this.props.minDate !== prevProps.minDate && this.state.minDate !== minDate) {
      this.setState(state => ({
        ...state,
        minDate: this.props.minDate ? moment(this.props.minDate).format('YYYY-MM-DD') : null,
      }));
    }
    if (this.props.maxDate !== prevProps.maxDate && this.state.maxDate !== maxDate) {
      this.setState(state => ({
        ...state,
        maxDate: this.props.maxDate ? moment(this.props.maxDate).format('YYYY-MM-DD') : MAX_DATE,
      }));
    }
  }

  handleChange(event) {
    this.props.onChange(event.target.value ?
      (this.props.type == 'month' ? moment(event.target.value).format('YYYY-MM') :
        moment(event.target.value).format('YYYY-MM-DD')) : undefined, this.props.id);
  }

  handleOnBlur(event) {
    if(event.target.value){
      const selectedDate = this.props.type=='month' ? moment(event.target.value, 'YYYY-MM') : moment(event.target.value, 'YYYY-MM-DD');
      if(this.state.minDate && selectedDate.isBefore(this.state.minDate)){
        this.props.onChange(undefined, this.props.id);
      }
      if(this.state.maxDate && selectedDate.isAfter(this.state.maxDate)){
        this.props.onChange(undefined, this.props.id);
      }
    }
    if(this.props.onBlur){
      this.props.onBlur(event);
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className="relative-pos text-black">
        <TextField
          error={!isEmpty(this.props.errorText)}
          id={this.props.id}
          label={this.props.shouldRemoveLabel ? '' : this.props.floatingLabelText || 'Date'}
          type={this.props.type ? this.props.type : "date"}
          inputRef={this.props.setRef}
          defaultValue={this.props.defaultValue || ''}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          onBlur={this.handleOnBlur}
          helperText={this.props.errorText || ''}
          fullWidth
          InputLabelProps={{
            shrink: true,
            className: startsWith(this.props.errorText, 'Warning') ? classes.warningColor : null,
          }}
          FormHelperTextProps={{
            classes: {
              error: startsWith(this.props.errorText, 'Warning') ? classes.warningColor : null,
            }
          }}
          inputProps={{
            min: this.state.minDate,
            max: this.state.maxDate
          }}
          InputProps={{
            classes: {
              underline: startsWith(this.props.errorText, 'Warning') ? classes.warningUnderline : null,
            }
          }}
          variant="standard" />
        {this.props.disabled && this.props.defaultValue && <i className={this.props.isInSideDrawer ? "side-drawer-icon-lock" : 'icon-lock'}></i>}
      </div>
    );
  }
}

export default withStyles(styles)(CommonDatePickerUncontrolled);

import React from 'react';
import connect from "react-redux/es/connect/connect";

import moment from "moment";
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
} from '@mui/material';
import {
  Check, Close as Cancel, CheckCircle
} from '@mui/icons-material';
import {
  get, isEmpty
} from 'lodash';
import {
  confirmVoid, rejectVoid, confirm, reject,
  handleContractOptionsMapper, setClickedOption,
} from '../../../actions/companies/contracts';
import CommonButton from '../../common/CommonButton';
import NestedOptionMenu from "../../NestedOptionMenu";
import { getActionMenuOptions, regenerateContractPDF } from '../utils';
import { required } from '../../../common/validators';
import { RejectionReasonDialog } from "../../rejections/RejectionReasonDialog";
import ContractActions from '../ContractActions';
import ContractAllocationForm from '../ContractAllocationForm';
import ContractStockAllocationForm from '../ContractStockAllocationForm';

class ContractDetailsActions extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      anchorEl: null,
      allocationForm: false,
      stockAllocationForm: false,
      acceptDialogOpen: false,
      rejectDialogOpen: false,
      rejectionAction: null,
      rejectionTitle: null,
      contractNumber: {
        value: get(this.props, 'contract.contractNumber'),
        validators: get(this.props, 'contract.isContractNumberMandatoryForConfirmation') ? [required()] : [],
        errors: []
      },
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
      requestReason: {
        value: undefined,
        validators: [],
        errors: []
      },
    };
    this.handleAcceptClickOpen = this.handleAcceptClickOpen.bind(this);
    this.handleAcceptClose = this.handleAcceptClose.bind(this);
    this.handleRejectClickOpen = this.handleRejectClickOpen.bind(this);
    this.handleRejectClose = this.handleRejectClose.bind(this);
    this.handleAmendClick = this.handleAmendClick.bind(this);
    this.acceptVoid = this.acceptVoid.bind(this);
    this.denyVoid = this.denyVoid.bind(this);
    this.handleContractChange = this.handleContractChange.bind(this);
    this.setContractErrors = this.setContractErrors.bind(this);
    this.getContractErrors = this.getContractErrors.bind(this);
    this.handleAcceptSubmit = this.handleAcceptSubmit.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.setReasonErrors = this.setReasonErrors.bind(this);
    this.getReasonErrors = this.getReasonErrors.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
  }

  toggleAllocationForm = () => this.setState({allocationForm: !this.state.allocationForm})

  toggleStockAllocationForm = () => this.setState({stockAllocationForm: !this.state.stockAllocationForm})

  componentWillUnmount() {
    this.clearEntity();
  }


  componentDidMount() {
    setTimeout(this.setActionButtonBoundaries, 100);
  }

  setActionButtonBoundaries() {
    const statusBarEl = get(document.getElementsByClassName('contract-status'), '0');
    if(statusBarEl) {
      const top = statusBarEl.offsetTop;
      const height = statusBarEl.offsetHeight;
      if(top && height) {
        const actionsContainer = document.getElementById('nested-actions-menu-container');
        if(actionsContainer) {
          actionsContainer.style.top = (top - 1) + 'px';
          actionsContainer.style.height = (height + 1) + 'px';
        }
      }

    }
  }

  handleAcceptClickOpen(){
    const newState = { ...this.state };
    newState.acceptDialogOpen = true;
    newState.contractNumber.errors = [];
    newState.contractNumber.value = this.props.contract && this.props.contract.contractNumber ? this.props.contract.contractNumber : undefined;
    this.setState(newState);
  }

  handleAcceptClose() {
    this.setState({acceptDialogOpen: false}, this.clearEntity);
  }

  handleRejectClickOpen(action){
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    if(!newState.rejectionReason.value){
      newState.rejectionReason.errors = [];
    }
    newState.rejectionAction = action;
    newState.rejectionTitle = 'Reject ' + (action === 'confirm' ? 'Contract' : action === 'void' ? 'Void Request' : '');
    this.setState(newState);
  }

  handleRejectClose(){
    this.setState({rejectDialogOpen: false}, this.clearEntity);
  }

  handleAmendClick() {
    this.props.onAmendClick();
    this.setState({ anchorEl: null });
  }

  acceptVoid(id) {
    this.props.dispatch(confirmVoid(id, true));
    this.clearEntity();
  }

  denyVoid(id, data) {
    this.props.dispatch(rejectVoid(id, data, true));
    this.clearEntity();
  }

  handleContractChange(event) {
    const newState = {...this.state};
    newState.contractNumber.value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    this.setState(newState, () => this.setContractErrors());
  }

  setContractErrors(errors) {
    const newState = {...this.state};
    newState.contractNumber.errors = errors || this.getContractErrors();
    this.setState(newState);
  }

  getContractErrors() {
    const errors = [];
    const value = get(this.state, `contractNumber.value`);
    const validators = get(this.state, `contractNumber.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  handleAcceptSubmit(event) {
    this.setContractErrors();
    const data = { contractNumber: this.state.contractNumber.value , updatedAt: moment.utc(get(this.props.contract,'updatedAt')).local().valueOf()};
    const salt = this.props.match ? this.props.match.params.salt : null;
    const { dispatch } = this.props;
    if (this.state.contractNumber.errors.length == 0) {
      dispatch(confirm(this.props.contract.id, data, salt));
      this.clearEntity();
    }
    event.preventDefault();
  }

  handleRejectSubmit(event) {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value , updatedAt: moment.utc(get(this.props.contract,'updatedAt')).local().valueOf()};
    const salt = this.props.match ? this.props.match.params.salt : null;
    if (this.state.rejectionReason.errors.length === 0) {
      const { dispatch } = this.props;
      if (this.state.rejectionAction === 'confirm'){
        dispatch(reject(this.props.contract.id, data, salt));
      } else if (this.state.rejectionAction === 'void'){
        dispatch(rejectVoid(this.props.contract.id, data, true));
      }
      this.clearEntity();

    }
    event.preventDefault();
  }

  handleReasonChange(event) {
    const value = event.target.value;
    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  }


  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  isContractNumberFieldDisabled() {
    return this.props.contract && this.props.contract.party === 'seller' && this.props.contract.documentType.name === 'contract';
  }

  handleOptionClick = (event, item, baseEntity) => {
    if(get(item, 'key') === 'regenerate_pdf')
      return regenerateContractPDF(baseEntity);
    else if(get(item, 'key') === 'contract_allocation')
      this.toggleAllocationForm()
    else if(get(item, 'key') === 'stock_allocation')
      this.toggleStockAllocationForm()
    else
      this.props.dispatch(handleContractOptionsMapper(event, item, baseEntity, 'ContractDetailsAction'));
  };

  clearEntity = () => this.props.dispatch(setClickedOption(null));

  render() {
    const { contract, currentUser, shouldOptionBeDisabled, subItems, clickedOption } = this.props;
    const { id, confirmable, voidable, amendable } = contract;
    return (
      <div>
        <div className='grey-bg'>
          {
            amendable &&
            <div className="status-actions-wrap">
              <h4>Contract Amend Request</h4>
              <div>
                <Button variant="contained" className='btn-green' onClick={this.handleAmendClick}>
                  REVIEW
                </Button>
              </div>
            </div>
          }
          {
            voidable &&
            <div className="status-actions-wrap">
              <h4>Contract Void Request</h4>
              <div>
                <Button
                  style={{marginRight: '10px'}}
                  variant="contained"
                  className={'btn-red'}
                  onClick={() => this.handleRejectClickOpen('void')}>
                  <Cancel /> REJECT
                </Button>
                <Button
                  variant="contained"
                  className={'btn-green'}
                  onClick={() => this.acceptVoid(id)}>
                  <Check /> ACCEPT
                </Button>
              </div>
            </div>
          }
          {
            confirmable &&
            <div className="status-actions-wrap">
              <h4>Contract Confirm Request</h4>
              <div>
                <Button
                  style={{marginRight: '10px'}}
                  variant="contained"
                  className={'btn-red'}
                  onClick={() => this.handleRejectClickOpen('confirm')} >
                  <Cancel /> REJECT
                </Button>
                <Button
                  variant="contained"
                  className={'btn-green'}
                  onClick={this.handleAcceptClickOpen}>
                  <Check /> ACCEPT
                </Button>
                <Dialog
                  open={this.state.acceptDialogOpen}
                  onClose={this.handleAcceptClose}
                  aria-labelledby="form-dialog-title"
                  fullWidth>
                  <DialogTitle id="form-dialog-title">
                    <CheckCircle
                      style={{
                        fill: '#6aae20', height: '35px', width: '35px', verticalAlign: 'middle'
                      }}
                    /> Accept Contract
                  </DialogTitle>
                  <DialogContent>
                    <TextField
                      id="contractNumber"
                      label="Contract No"
                      placeholder="Please enter your Contract No"
                      value={this.state.contractNumber.value}
                      onChange={this.handleContractChange}
                      helperText={get(this.state, 'contractNumber.errors[0]', '')}
                      error={!isEmpty(get(this.state, 'contractNumber.errors[0]', ''))}
                      errorStyle={{textAlign: "left"}}
                      disabled={this.isContractNumberFieldDisabled()}
                      inputProps={{maxLength: 14}}
                      fullWidth
                      variant="standard" />
                  </DialogContent>
                  <DialogActions>
                    <CommonButton onClick={this.handleAcceptClose} label='Cancel' secondary variant="contained" />
                    <CommonButton onClick={this.handleAcceptSubmit} label='Accept' primary variant="contained" />
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          }
          {
            ((voidable || confirmable) && this.state.rejectDialogOpen) &&
            <div className="status-actions-wrap">
              <RejectionReasonDialog
                open={this.state.rejectDialogOpen}
                onClose={this.handleRejectClose}
                title={this.state.rejectionTitle}
                value={this.state.rejectionReason.value}
                onChange={this.handleReasonChange}
                helperText={get(this.state, 'rejectionReason.errors[0]', '')}
                onCancel={this.handleRejectClose}
                onReject={this.handleRejectSubmit}
              />
            </div>
          }
        </div>
        {
          contract &&
          <div className="status-actions-wrap">
            <NestedOptionMenu
              optionsItems={getActionMenuOptions(contract, subItems, clickedOption)}
              item={contract}
              handleOptionClick={this.handleOptionClick}
              currentUser={currentUser}
              shouldOptionBeDisabled={shouldOptionBeDisabled}
              useButton={true}
              useIconButton={false}
              buttonContainerStyle={{float: 'none', marginLeft: 'none'}}
            />
          </div>
        }
        <ContractActions {...this.props} selectedContract={contract}/>
        {
          this.state.allocationForm &&
            <ContractAllocationForm
              open={this.state.allocationForm}
              onClose={this.toggleAllocationForm}
              subjectContract={contract}
            />
        }
        {
          this.state.stockAllocationForm &&
            <ContractStockAllocationForm
              open={this.state.stockAllocationForm}
              onClose={this.toggleStockAllocationForm}
              contract={contract}
            />
        }
      </div>
    );
  }
}


const mapStateToProps = state => {
  return {
    currentUser: state.main.user.user,
    token: state.main.user.token,
    canRaiseVoidRequest: state.companies.contracts.canRaiseVoidRequest,
    canRaiseVoidAndDuplicateRequest: state.companies.contracts.canRaiseVoidAndDuplicateRequest,
    selectedContractId: state.companies.contracts.selectedContractId,
    canCreateTitleTransferForContractId: state.companies.contracts.canCreateTitleTransferForContractId,
    subItems: state.companies.contracts.subItems,
    clickedOption: state.companies.contracts.clickedOption,
    canCreateCOGForContractId: state.companies.contracts.canCreateCOGForContractId,
  };
};

export default connect(mapStateToProps, null)(ContractDetailsActions);

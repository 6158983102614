import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Company extends Component {
  render() {
    return <Tooltip title="Companies" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z"/>
          <rect width="22" height="14" x="1" y="5" stroke={this.props.fill} strokeWidth="2" rx="2"/>
          <path fill={this.props.fill} d="M14 9h6v2h-6zM14 13h6v2h-6z"/>
          <circle cx="6.5" cy="9.5" r="2.5" fill={this.props.fill}/>
          <path fill={this.props.fill} d="M10 17.005C10 15.072 8.433 13 6.5 13S3 15.072 3 17.005c0-.002 7-.003 7 0z"/>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default Company;

import React from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { Autocomplete, TextField, Chip, ListItem, ListItemAvatar, Avatar } from '@mui/material';
import { Person as PersonIcon } from '@mui/icons-material';
import { get, find, isArray } from 'lodash';
import { EMAIL_REGEX } from '../../../common/constants';

const EmailAutocomplete = ({selected, onDelete, options, ...rest}) => {
  const isValidEmail = email => email && EMAIL_REGEX.test(email)
  const shouldShowErrorOnMoreLabel = contacts => Boolean(find(contacts.slice(2), contact => !isValidEmail(get(contact, 'email', contact))))
  let items = isArray(options) ? options : []

  return (
    <Autocomplete
      multiple
      freeSolo
      clearIcon={false}
      limitTags={2}
      value={ selected }
      options={items}
      getOptionLabel={option => get(option, 'title', option)}
      renderInput={params => (<TextField {...params} variant="outlined" fullWidth />)}
      renderTags={ (value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            key={index}
            label={get(option, 'title', option)}
            {...getTagProps({ index })}
            className={isValidEmail(get(option, 'email', option)) ? "chip-inline-ellipsis" : "chip-inline-ellipsis error"}
            onDelete={onDelete} />
        ))
      }
      getLimitTagsText={
        more => (
          <span className={shouldShowErrorOnMoreLabel(selected) ? "more-label error" : "more-label"}>
            {`${more} more`}
          </span>
        )
      }
      renderOption={(params, option, { inputValue }) => {
        const matchesName = match(option.name, inputValue);
        const partsName = parse(option.name, matchesName);
        const matchesEmail = match(option.email, inputValue);
        const partsEmail = parse(option.email, matchesEmail);
        return (
          <ListItem {...params} style={{cursor: 'pointer', margin: '0 10px'}} className="contact-list-item">
            <ListItemAvatar className="contact-avatar">
              <Avatar>
                <PersonIcon />
              </Avatar>
            </ListItemAvatar>
            <div className='list-item-text'>
              <span className='primary'>
                {partsName.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
              </span>
              <p className="secondary">
                {partsEmail.map((part, index) => (
                  <span key={index} style={{ fontWeight: part.highlight ? 700 : 400, color: part.highlight ? 'rgb(0, 0, 0)' : 'rgba(0, 0, 0, 0.54)' }}>
                    {part.text}
                  </span>
                ))}

              </p>
            </div>
          </ListItem>
        );
      }}
    {...rest}
    />
  )
}

export default EmailAutocomplete;

import React from 'react'
import { Tooltip, Chip } from '@mui/material'
import { isStaff } from '../../common/utils';

const MovementIdentifier = ({ movement }) => {
  return isStaff() ? (
    <span style={{display: 'flex', flexDirection: 'column'}}>
      {movement?.identifier}
      <span>
        {
          movement?.pausedDocketEntry &&
            <Tooltip title='Seeking Clarification'>
              <Chip fontSize='small' color='warning' label='Hold' style={{margin: '2px', height: '20px'}} />
            </Tooltip>
        }
        {
          movement?.isSms &&
            <Tooltip title='SMS Docket Movement'>
              <Chip fontSize='small' color='info' label='SMS' style={{margin: '2px', height: '20px'}} />
            </Tooltip>
        }
        {
          movement?.ignoreDocketEntry == false &&
            <Tooltip title='Docket Entry Pending'>
              <Chip fontSize='small' color='secondary' label='Docket' style={{margin: '2px', height: '20px'}} />
            </Tooltip>
        }
      </span>
    </span>
  ) : movement?.identifier
}

export default MovementIdentifier;

import React from 'react';
import { TextField } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material/';
import { mapValues, reject, cloneDeep, isEqual, isEmpty, get } from 'lodash';
import { FIELD } from '../../../common/constants';
import FileUpload from '../../common/FileUpload';

class AdditionalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        notes: cloneDeep(FIELD),
        attachments: {...cloneDeep(FIELD), value: []},
      }
    };
  }

  componentDidUpdate(prevProps) {
    if(this.shouldSetDataFromTemplate(prevProps))
      this.setDataFromSelectedTemplate();
  }

  shouldSetDataFromTemplate(prevProps) {
    const { selectedTemplate } = this.props;
    return selectedTemplate && !isEqual(prevProps.selectedTemplate, selectedTemplate);
  }

  setDataFromSelectedTemplate() {
    const { selectedTemplate } = this.props;
    const newState = { ...this.state };
    newState.fields.notes.value = selectedTemplate.notes;
    this.setState(newState, this.propagateChanges);
  }

  onChange = event => {
    this.setState({
      fields: {
        ...this.state.fields,
        notes: {
          ...this.state.fields.notes,
          value: event.target.value,
          errors: (isEmpty(event.target.value) && !isEmpty(this.state.fields.attachments.value)) ? ["Please add a note for the attachment"] : []}
      }
    }, this.propagateChanges);
  };

  propagateChanges() {
    this.props.onChange(mapValues(this.state.fields, 'value'), {});
  }

  onFileUpload = file => {
    const newState = {...this.state};
    newState.fields.attachments.value.push(file.file);
    newState.fields.notes.errors = (isEmpty(newState.fields.notes.value) && !isEmpty(newState.fields.attachments.value)) ? ["Please add a note for the attachment"] : []
    this.setState(newState, this.propagateChanges);
  };

  onFileRemove = base64 => {
    const newState = {...this.state};
    newState.fields.attachments.value = reject(newState.fields.attachments.value, {base64: base64});
    this.setState(newState, this.propagateChanges);
  };

  render() {
    return (
      <div>
        <h4 className='cardForm-title'>Additional Information</h4>
        <div className='cardForm-content'>
          <div className='col-md-12 padding-reset'>
            <TextField
              placeholder='Notes'
              id='notes'
              value={this.state.fields.notes.value || ''}
              fullWidth
              multiline={true}
              onChange={this.onChange}
              rows={6}
              variant='outlined'
              error={!isEmpty(this.state.fields.notes.errors)}
              helperText={get(this.state.fields.notes, 'errors[0]', '')}
            />
          </div>
          <div className='col-md-12 padding-reset' style={{marginLeft: '-15px'}}>
            <FileUpload
              id="attachments"
              floatingLabelText="Attach File"
              fullWidth={true}
              textFieldstyle={{color: 'black'}}
              onChange={this.onFileUpload}
              buttonText="Attachments"
              onRemove={this.onFileRemove}
              fileCount={1}
              previewContainerClass='col-md-10'
              buttonContainerClass='col-md-2'
              buttonStyle={{marginTop: '20px'}}
              previewStyle={{marginLeft: '-60px'}}
              buttonColor='primary'
              showImage
              icon={<AddIcon style={{paddingRight: '5px'}} />}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default AdditionalInfo;

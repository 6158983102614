import React from 'react';
import { connect } from 'react-redux';
import ContractBidsTable from '../../containers/ContractBidsTable';
import Paper from '@mui/material/Paper';
import {  setSelectedContractBid, contractBidsResponse, 
    getCompanyContractBids, showQuantityDialog
} from '../../actions/companies/contract-bids';
import { InputAdornment } from '@mui/material';
import APIService from '../../services/APIService';
import Button from '@mui/material/Button/Button';
import { isEmpty, get, find } from 'lodash';
import alertifyjs from 'alertifyjs';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { getCountryCurrency } from '../../common/utils';
import CommonTextField from '../common/CommonTextField';
import { MT_UNIT } from '../../common/constants';

class ContractBids extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            quantity: null
        };
    }

    componentDidMount() {
        this.props.getCompanyContractBids(true);
    }

    componentWillUnmount() {
        this.props.setContractBidsResponse([]);
    }

    closeQuantityDialog = () => this.props.setShowQuantityDialog(false);

    handleQuantityChange = event => this.setState({ quantity: event.target.value });

    handleAcceptContract = () => {
        const { selectedContractBid } = this.props;
        if (!isEmpty(this.state.quantity) && selectedContractBid) {
            this.closeQuantityDialog();
            alertifyjs.confirm('Confirm',
            `Accepting this price will create a ${this.state.quantity} ${get(this.getSelectedCommodity(), 'priceUnit', MT_UNIT)} contract with price ${getCountryCurrency()} ${selectedContractBid?.price}. Are you sure you want to proceed?`,
            () => {
                APIService.contract_bids(selectedContractBid.id).appendToUrl('contract/').post({
                    quantity: this.state.quantity
                }).then(response => {
                    this.setState({ quantity: null });
                    if(isEmpty(response?.errors)) {
                        window.location = `/#/contracts/${response?.id}/contract`;
                        alertifyjs.success('Contract Accepted Successfully');
                    } else
                        alertifyjs.error('Error in accepting contract');
                })
            },
            () => {}
            ).set('reverseButtons', true).set(
            'labels', {ok: 'Yes, Proceed', cancel: 'Cancel'})
        }
    }

    getSelectedCommodity = () => {
        const id = this.props.selectedContractBid?.commodity;
        return id ? find(this.props.commodities, {id: id}) : null
    }

    render() {
        const priceUnit = get(this.getSelectedCommodity(), 'priceUnit', MT_UNIT);
        return (
            <div>
            <Paper className='paper-table-paginated'>
                <ContractBidsTable {...this.props}/>

                { this.props.showQuantityDialog && 
                <div>
                    <Dialog
                        open={this.props.showQuantityDialog}
                        maxWidth='xs'
                        fullWidth
                        onClose={() => this.closeQuantityDialog()}
                    >
                        <DialogTitleWithCloseIcon id="alert-dialog-title" onClose={() => this.closeQuantityDialog()}>{"Accept Contract"}</DialogTitleWithCloseIcon>
                        <DialogContent>
                            <CommonTextField
                                id='quantity'
                                label='Quantity'
                                type='number'
                                onChange={this.handleQuantityChange}
                                value={this.state.quantity}
                                fullWidth
                                required
                                InputProps={{
                                    endAdornment: (
                                      <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                                        {priceUnit}
                                      </InputAdornment>
                                    ),
                                }}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.closeQuantityDialog()} variant='outlined' color="secondary">
                                Cancel
                            </Button>
                            <Button onClick={() => this.handleAcceptContract()} variant='contained' color="primary" autoFocus>
                                Accept Contract
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>}
            </Paper>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        selectedContractBid: state.companies.contractBids.selectedContractBid,
        showQuantityDialog: state.companies.contractBids.showQuantityDialog,
        contractBids: state.companies.contractBids.contractBids,
        commodities: state.master.commodities.items,
    };
};

const mapDispatchToProps = dispatch => ({
    getCompanyContractBids: loader => dispatch(getCompanyContractBids(loader, null, 'active')),
    setContractBidsResponse: () => dispatch(contractBidsResponse([])),
    setSelectedContractBid: () => dispatch(setSelectedContractBid([])),
    setShowQuantityDialog: flag => dispatch(showQuantityDialog(flag)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContractBids);

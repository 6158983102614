import React from 'react';
import { includes, filter, get, size, merge, isArray } from 'lodash';
import { TextField, Autocomplete } from '@mui/material/';
import { ListboxComponent } from '../ListboxComponent';

const SELECT_ALL_STYLES = {
  fontSize: '12px',
  textDecoration: 'underline',
  cursor: 'pointer'
};

const CLEAR_ALL_STYLES = {
  ...SELECT_ALL_STYLES,
  marginLeft: '10px'
};

class CommonMultiSelect extends React.Component {
  onItemChange = (event, items) => this.props.onChange(this.props.id, items);

  onSelectAll = event => {
    event.preventDefault();
    this.props.onChange(this.props.id, this.props.items);
  };

  onClearAll = event => {
    event.preventDefault();
    this.props.onChange(this.props.id, []);
  };

  areAllSelected() {
    return size(this.props.selectedItems) === size(this.props.items);
  }

  shouldShowClearAll() {
    return Boolean(size(this.props.selectedItems));
  }

  render() {
    // eslint-disable-next-line no-unused-vars
    const { id, items, selectedItems, displayField, clearAll, selectAll, placeholder, helperText, error, label, variant, onChange, containerStyle, ...rest } = this.props;
    return (
      <div style={merge({marginTop: '20px'}, (containerStyle || {}))}>
        {
          selectAll && !rest?.disabled &&
            <a onClick={this.onSelectAll} style={SELECT_ALL_STYLES}>Select All</a>
        }
        {
          this.shouldShowClearAll() && this.props.clearAll && !rest?.disabled &&
            <a onClick={this.onClearAll} style={CLEAR_ALL_STYLES}>Clear All</a>
        }
        <Autocomplete
          style={{marginTop: '5px'}}
          multiple
          disableCloseOnSelect
          ListboxComponent={ListboxComponent}
          id={id}
          options={isArray(items) ? items : []}
          getOptionLabel={option => get(option, displayField)}
          onBlur={this.props.onBlur}
          renderInput={params => (
            <TextField
              {...params}
              autoComplete='off'
              fullWidth
              placeholder={placeholder || ''}
              helperText={helperText}
              error={error}
              label={label || ''}
              variant={variant || 'outlined'}
            />
          )}
          value={ filter(items, item => includes(selectedItems, item.id)) }
          onChange={this.onItemChange}
          limitTags={5}
          {...rest}
        />
      </div>
    );
  }
}

export default CommonMultiSelect;

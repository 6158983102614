import React from 'react';
import { connect } from "react-redux";
import { includes, get, find, isEmpty } from "lodash";
import APIService from '../services/APIService';
import {
  getSelectedOrder,
  receiveOrder,
  receiveAllocation,
  getOrders,
  getPaginatedFreightOrdersResponse,
} from "../actions/companies/orders";
import {isLoading} from "../actions/main";
import {
  getFreightOrdersGlobalListingHeaders, ORDERS_TABLE_COLUMN_LIMIT
} from "../common/constants";
import {
  isAtGlobalOrders,
  getContractIdFromCurrentRoute,
  getOrderIdFromCurrentRoute,
  getAbsoluteUrl,
} from "../common/utils";
import {
  getFreightOrderActionMenuOptions, regenerateOrderPDF
} from '../components/freights/utils';
import GenericTable from '../components/GenericTable';
import {handleFreightOrderOptionsMapper} from "../actions/companies/orders";
import FreightOrderActions from '../components/freights/FreightOrderActions';
import { getCustomColumns } from '../actions/companies/index';

class FreightOrdersTable extends React.Component {

  componentDidMount() {
    this.props.getCustomColumns('freight_order_table');
  }

  render() {
    return(
      <div>
        <GenericTable {...this.props}/>
        <FreightOrderActions {...this.props}/>
      </div>
    );
  }

}
const mapStateToProps = state => {
  const hasParentOrderId = document.location.href.includes('parent_order_id');
  const subItems = state.companies.orders.subItems;
  const clickedOption = state.companies.orders.clickedOption;
  const freightOrderColumns = [...getFreightOrdersGlobalListingHeaders(hasParentOrderId)];
  let customColumns = freightOrderColumns;
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(freightOrderColumns, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > ORDERS_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, ORDERS_TABLE_COLUMN_LIMIT-1);
  }
  return {
    columns: customColumns,
    items: state.companies.orders.items,
    scrollToTopOnUpdate: false,
    optionsItems: (item) => getFreightOrderActionMenuOptions(item, subItems, clickedOption),
    currentUser: state.main.user.user,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    paginationData: state.companies.orders.paginationData,
    globalSearch: true,
    clearSearch: getPaginatedFreightOrdersResponse,
    useNestedOptionMenu: true,
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
    selectedOrderId: state.companies.orders.selectedOrderId,
    shouldFetchOrder: state.companies.orders.shouldFetchOrder,
    selectedOrder: state.companies.orders.selectedOrder,
    clickedOption: clickedOption,
    subItems: subItems,
    userToken: state.main.user.token,
    canCloseOutForOrder: state.companies.orders.canCloseOutForOrder,
    voidFilter: true
  };
};

const mapDispatchToProps = (dispatch, that) => {
  const { dontRedirect } = that;
  return {
    handleOptionClick: (event, item, baseEntity) => {
      const canPassContractDetailsMappingFrom = (get(item, 'key') === 'void_and_duplicate' && that.isContractDetailsModule)

      if(get(item, 'key') === 'regenerate_pdf')
        return regenerateOrderPDF(baseEntity);
      else
        dispatch(handleFreightOrderOptionsMapper(event, item, baseEntity, canPassContractDetailsMappingFrom && 'contractDetailsModule'));
    },
    handleDefaultCellClick: (item) => {
      const receiver = isAtGlobalOrders() ? receiveOrder : (item.parentOrderId ? receiveAllocation : receiveOrder);
      dispatch(getSelectedOrder(item.id, receiver, false, false, false, false, true, item.requestedUnit));
      dispatch(isLoading('nonExistingComponent'));
      if(dontRedirect) {
        const orderType = isAtGlobalOrders() ? 'fo' : (item.parentOrderId ? 'fa' : 'fo');
        document.location.hash = `${getAbsoluteUrl(document.location.hash)}?orderId=${item.id}&orderType=${orderType}`;
      } else {
        document.location.hash = '/freights/orders/' + item.id + '/order';
      }
    },
    navigateTo: (url) => {dispatch(getOrders('', '', url, true));},
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}&type_id=1&type_id=2`;
      } else {
        url = `${url}?page_size=${pageSize}&type_id=1&type_id=2`;
      }
      dispatch(getOrders('', '', url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, path, includeVoid) => {
      const service = APIService.freights().orders();
      service.appendToUrl(`web/search/?page_size=${pageSize}&type_id=1&type_id=2`);
      if (!isAtGlobalOrders()) {
        const contractId = getContractIdFromCurrentRoute();
        if(contractId) {
          service.appendToUrl(`&commodity_contract_id=${contractId}&type_id=1&type_id=2`);
        }
        const orderId = getOrderIdFromCurrentRoute();
        if(orderId) {
          if(path.includes('/allocations'))
            service.appendToUrl(`&parent_order_id=${orderId}&type_id=1&type_id=2`);
          else
            service.appendToUrl(`&order_id=${orderId}&type_id=1&type_id=2`);
        }
      }
      if (page) {
        service.appendToUrl(`&page=${page}&type_id=1&type_id=2`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}&type_id=1&type_id=2`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}&type_id=1&type_id=2`);
      }
      if(includeVoid) {
        service.appendToUrl(`&include_void=${includeVoid}`)
      }
      return service.URL;
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreightOrdersTable);

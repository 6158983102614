import React from 'react';
import { setPassword } from '../../actions/main';
import InputAdornment from '@mui/material/InputAdornment';
import LockIcon from '@mui/icons-material/LockOutlined';
import EmailIcon from '@mui/icons-material/MailOutline';
import { TextField } from '@mui/material';
import { connect } from 'react-redux';
import './login.scss';
import { Redirect } from 'react-router-dom';
import CommonButton from '../common/CommonButton';
import last from 'lodash/last';
import APIService from '../../services/APIService';

class LoginFormWithSetPassword extends React.Component {
  constructor(props) {
    super(props);

    var referrer = null;

    if(document.location.hash.match('referrerUrl=/')) {
      referrer = last(document.location.hash.split('referrerUrl='));
    }

    this.state = {
      user: null,
      referrerUrl: referrer || '/companies',
      isCompanyRegistered: false,
      formData: {
        password: '',
        confirmPassword: '',
      },
      error : '',
      submitted: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.emailRef = React.createRef();
    this.passwordRef = React.createRef();
    this.confirmPasswordRef = React.createRef();
  }

  componentDidMount() {
    APIService.profiles(this.props.match.params.profile_id).appendToUrl('?includes=type').get(this.props.match.params.salt)
                                                   .then((response) => {
                                                     const newState = {...this.state};
                                                     newState.user = response;
                                                     newState.isCompanyRegistered = response.isRegistered;
                                                     this.setState(newState);
                                                   });
  }

  handleBlur (event) {
    this[event.target.name + 'Ref'].current.validate(event.target.value);
  }

  handleChange(event) {
    const { formData } = this.state;
    formData[event.target.name] = event.target.value;
    this.setState({ formData });
  }

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = this.props;
    setPassword(
      this.state.formData,
      this.state.user,
      this.props.match.params.salt,
      this.state.referrerUrl,
      dispatch
    );
  }

  render() {
    const { formData, submitted } = this.state;
    return (
      <div className="login-wrapper">
        <div className="login-container">
          <div className="login-content-box">
            <div className="login-content-box--header">
              <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
            </div>
            <div className="login-content-box--content">
              {
                this.state.isCompanyRegistered ?
                (
                  <p style={{textAlign: "center"}}>
                    You are already registered. Please click
                    <a href="#/"> here </a>
                    to login.
                  </p>
                ) : (
                  <div>
                    <h2>Setup Password</h2>
                    <form
                      onSubmit={this.handleSubmit}
                    >
                      {
                        this.props.user.token ?
                        <Redirect to={this.state.referrerUrl} /> :
                        <div className="error-message">{this.props.user.alert}</div>
                      }
                      <div className="cardForm cardForm--drawer">
                        <div className="cardForm-content row">
                          { this.state.user ?
                            <div className="col-sm-12 form-wrap">
                              <TextField
                                label="Email"
                                fullWidth
                                ref={this.emailRef}
                                name="email"
                                value={this.state.user.email}
                                disabled
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <EmailIcon nativeColor="#999999" />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </div>
                            : null
                          }
                          <div className="col-sm-12 form-wrap">
                            <TextField
                              label="Password"
                              ref={this.passwordRef}
                              fullWidth
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              name="password"
                              type="password"
                              value={formData.password}
                              required
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockIcon nativeColor="#999999" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-sm-12 form-wrap">
                            <TextField
                              label="Confirm Password"
                              ref={this.confirmPasswordRef}
                              fullWidth
                              onChange={this.handleChange}
                              onBlur={this.handleBlur}
                              name="confirmPassword"
                              type="password"
                              value={formData.confirmPassword}
                              required
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <LockIcon nativeColor="#999999" />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-12">
                        <CommonButton
                          type="submit"
                          label="Submit"
                          variant="contained"
                          disabled={submitted}
                          primary={true}
                        />
                      </div>
                    </form>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const user = state.main.user || '';
  return {
    user,
    setPasswordErrors: state.main.setPasswordErrors
  };
};
export default connect(mapStateToProps)(LoginFormWithSetPassword);

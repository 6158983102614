import { connect } from 'react-redux';
import ReleaseNoteForm from '../components/release-notes/ReleaseNoteForm';
import { addReleaseNote, createReleaseNote } from '../actions/companies/release-notes';

function submit(data) {
  return (dispatch) => {
    dispatch(createReleaseNote(data, addReleaseNote));
  };
}

export default connect(null, { submit })(ReleaseNoteForm);

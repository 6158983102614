import React from 'react';
import { Chip } from '@mui/material';
import { DateRange } from '@mui/icons-material';
import './CalendarDateSelector.scss';

class CalendarDateSelector extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.defaultDate
    };
  }

  componentDidUpdate(prevProps) {
    if(prevProps.defaultDate !== this.props.defaultDate && prevProps.defaultDate === this.state.date)
      this.setState({date: this.props.defaultDate});
  }

  onChange = event => {
    const date = event.target.value;
    if(date)
      this.setState({date: date}, () => this.props.onChange(date));
  };

  render() {
    const { rangeText, disabled } = this.props;
    return (
      <span style={{margin: '0 1px'}} id='date-range'>
        <span id='hidden-date-html5'>
          <input className='hidden-date' type="date" onChange={this.onChange} value={this.state.date} style={{padding: "9px"}} />
          <Chip
            id='calendarText'
            label={ rangeText }
            deleteIcon={
              <DateRange style={{width: '17px', marginTop: '-3px', marginRight: '10px'}} />
            }
            onDelete={() => {}}
            variant="outlined"
            clickable
            style={{minWidth: '100px'}}
            color="secondary"
            className="calendar-custom-date-button"
            disabled={disabled}
          />
        </span>
      </span>
    );
  }
}

export default CalendarDateSelector;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import isEmpty from 'lodash/isEmpty';
import last from 'lodash/last';
import get from 'lodash/get';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import '../../common/forms.scss';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import alertifyjs from 'alertifyjs';
import './release-notes.scss';

class ReleaseNoteForm extends Component {
  constructor(props) {
    super(props);
    this.state = { changelog: '', version: '', versionError: ''};

    this.handleChangelogChange = this.handleChangelogChange.bind(this);
    this.handleVersionChange = this.handleVersionChange.bind(this);
    this.onVersionBlur = this.onVersionBlur.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    // eslint-disable-next-line no-useless-escape
    this.VERSION_REGEX = '^image-v[0-9]+\.[0-9]+\.[0-9]+$';
    this.VERSION_ERROR_MESSAGE  = 'Please enter version in the format: image-v0.0.1';
    this.VERSION_NOT_GREATER_THAN_CURRENT_MESSAGE = 'Please enter version greater than the last version ';
  }

  componentDidMount() {
    if(this.props.selectedReleaseNote) {
      this.setAllFieldsFromSelectedReleaseNote();
    }
  }

  cancelDrawer(){
    if(this.props.selectedReleaseNote) {
      this.props.cancelEdit();
    } else {
      this.props.closeDrawer();
    }
  }

  handleChangelogChange(value) {
    this.setState({ changelog: value });
  }

  handleVersionChange(event) {
    this.setState({version: event.target.value});
  }

  onVersionBlur(event) {
    if(!event.target.value || !event.target.value.match(this.VERSION_REGEX)) {
      this.setState({versionError: this.VERSION_ERROR_MESSAGE});
    } else if(event.target.value && !this.isVersionGreaterThanCurrent(event.target.value)) {
      this.setState({versionError: this.VERSION_NOT_GREATER_THAN_CURRENT_MESSAGE + this.props.currentVersion});
    } else {
      this.setState({versionError: ''});
    }
  }

  isVersionGreaterThanCurrent(version) {
    version = version || this.state.version;

    const versionNums = version.split('-v')[1];
    const firstTwoNums = parseFloat(versionNums);
    const lastNum = parseFloat(last(versionNums.split('.')));
    const currentVersionNums = this.props.currentVersion.split('-v')[1];
    const firstTwoNumsFromCurrentVersion = parseFloat(currentVersionNums);
    const lastNumFromCurrentVersionNum = parseFloat(last(currentVersionNums.split('.')));

    let result = false;

    if(firstTwoNums > firstTwoNumsFromCurrentVersion) {
      result = true;
    } else if(firstTwoNums === firstTwoNumsFromCurrentVersion) {
      return result = lastNum > lastNumFromCurrentVersionNum;
    }

    return result;
  }

  isFormValid() {
    return !isEmpty(this.state.version) && !isEmpty(this.state.changelog);
  }

  handleSubmit(event) {
    if(this.isFormValid()) {
      const data = {version: this.state.version, changelog: this.state.changelog};
      this.props.submit(data);
    } else {
      alertifyjs.notify('Please enter all fields', 'error', 3);
    }
    event.preventDefault();
  }

  setAllFieldsFromSelectedReleaseNote() {
    if(this.props.selectedReleaseNote) {
      this.setState({
        version: get(this.props.selectedReleaseNote, 'version'),
        changelog: get(this.props.selectedReleaseNote, 'changelog'),
      });
    }
  }

  render() {
    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div style={{marginBottom: '30px'}}>
            <CommonTextField
              id="version"
              label="Version"
              helperText= {this.state.versionError}
              onChange={this.handleVersionChange}
              onBlur={this.onVersionBlur}
              value={this.state.version}
              maxLength="100"
            />
          </div>
          <ReactQuill
            theme="snow"
            value={this.state.changelog}
            modules={ReleaseNoteForm.modules}
            formats={ReleaseNoteForm.formats}
            onChange={this.handleChangelogChange}
            placeholder="Enter Changelog..."
          />
          <div className="col-sm-5 cardForm-action top15 padding-reset" style={{marginTop: '20px'}}>
            <CommonButton
              type="button"
              variant="contained"
              label="Cancel"
              default
              onClick={this.props.cancelEdit}
            />
            <CommonButton
              primary={true}
              variant="contained"
              label="Save"
              type="submit" />
          </div>
        </form>
      </div>
    );
  }
}

ReleaseNoteForm.modules = {
  toolbar: [
    [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
    ['link',],
    ['clean']
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  }
};
/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
ReleaseNoteForm.formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video'
];

const mapStateToProps = (state) => {
  return {
    currentVersion: state.main.version
  };
};

export default connect(mapStateToProps)(ReleaseNoteForm);

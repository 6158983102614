import React from 'react';
import { connect } from 'react-redux';
import { CompareArrows as ViaIcon } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { AzureAD, AuthenticationState } from 'react-aad-msal';
import { get } from 'lodash';
import { receiveLogin } from '../../actions/main';
import { getAuthProvider } from '../../services/AzureAuthProvider';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';

const SIZE = '24px';
const WHITE = '#FFF';

class AzureLogin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      companyId: this.props.match.params.company_id,
      setting: null,
      authProvider: null,
      account: null,
    };
  }

  componentDidMount() {
    this.fetchSettings();
  }

  fetchSettings = () => {
    if(this.state.companyId)
      APIService.companies(this.state.companyId).appendToUrl('sso-setting/web/').get().then(setting => {
        if(get(setting, 'id'))
          this.setState({setting: setting, authProvider: getAuthProvider(setting.extras)});
        else
          this.setState({setting: false});
      });
  }


  makeHandshake = (event, account) => {
    event.preventDefault();
    event.stopPropagation();
    if(account) {
      let firstName = account.account.givenname || account.account.idTokenClaims.givenname || account.account.idTokenClaims.given_name;
      let lastName = account.account.surname || account.account.idTokenClaims.surname || account.account.idTokenClaims.family_name || account.account.idTokenClaims.familyname;
      if(firstName && !lastName)
        lastName = ' ';
      if(lastName && !firstName) {
        firstName = lastName;
        lastName = ' ';
      }
      if (!firstName && !lastName) {
        const names = account.account.name.split(' ');
        lastName = names.shift();
        firstName = names.join(' ');
      }
      firstName = firstName.replace(',', '');
      lastName = lastName.replace(',', '');
      const username = account.account.userName || account.account.idTokenClaims.preferred_username;
      const user = {typeId: 2, username: username, jwtIdToken: account.jwtIdToken, email: account.account.userName, lastName: lastName, firstName: firstName};
      APIService.companies(this.state.companyId).appendToUrl('sso/employees/upsert/').post(user).then(response => {
        if(get(response, 'token')) {
          //success
          this.props.dispatch(receiveLogin(response));
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.user));
          document.location.hash = '/';
        } else {
          //error -- do something
        }
      });
    }
  }

  render() {
    const { setting, authProvider } = this.state;
    const isValid = get(setting, 'id');
    return (
      <div className="login-wrapper">
        <div className='row'>
          <div className="login-container" style={{width: '500px'}}>
            <div className="login-content-box">
              {
                setting === null &&
                <div className="login-content-box--header" style={{padding: 0, display: 'flex', alignItems: 'center'}}>
                  <CircularProgress style={{width: '25px', height: '25px', marginRight: '10px'}} />
                  <span style={{display: 'flex', alignItems: 'center', color: WHITE}}>
                    Getting SSO Configurations...
                  </span>
                </div>
              }
              {
                setting === false &&
                <div className="login-content-box--header" style={{padding: 0}}>
                  <span style={{display: 'flex', alignItems: 'center', color: 'red'}}>
                    SSO not configured yet!
                  </span>
                </div>
              }
              {
                isValid &&
                <React.Fragment>
                  <div className="login-content-box--header" style={{padding: 0}}>
                    <span style={{display: 'flex', alignItems: 'center'}}>
                      <img src="images/agrichain-logo-icon.png" alt="AgriChain" />
                      <span style={{fontSize: SIZE, color: WHITE, marginLeft: '10px'}}>
                        <span style={{fontWeight: '400'}}>AGRI</span>
                        <span style={{fontWeight: '100'}}>CHAIN</span>
                      </span>
                      <span style={{margin: '0 10px'}}><ViaIcon style={{color: WHITE, width: SIZE, height: SIZE}}/></span>
                      {
                        setting.logoUrl &&
                        <img style={{marginRight: '10px', height: '40px', width: '40px', objectFit: 'cover'}} src={setting.logoUrl} alt={setting.companyName} />
                      }
                      <span style={{fontSize: SIZE, color: WHITE}}>
                        <span>{setting.companyName}</span>
                      </span>
                    </span>
                  </div>
                  <div className="login-content-box--content" style={{textAlign: 'center'}}>
                    <AzureAD provider={authProvider}>
                      {
                        ({login, logout, authenticationState, error, accountInfo}) => {
                          switch (authenticationState) {
                            case AuthenticationState.Authenticated:
                              // eslint-disable-next-line no-console
                              console.log('Authenticated...');
                              // eslint-disable-next-line no-console
                              console.log(accountInfo);
                              return (
                                <React.Fragment>
                                  <h2>Welcome, {get(accountInfo, 'account.name')}!</h2>
                                  <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                                    <CommonButton
                                      label='Enter AgriChain'
                                      variant='contained'
                                      primary
                                      className='login-button'
                                    onClick={event => this.makeHandshake(event, accountInfo)}
                                    />
                                    <CommonButton
                                      label="Logout"
                                      variant="contained"
                                      secondary
                                      className="login-button"
                                      onClick={logout}
                                    />
                                  </div>
                                </React.Fragment>
                              );
                            case AuthenticationState.Unauthenticated:
                              return (
                                <div>
                                  {
                                    error &&
                                    <p style={{textAlign: 'center'}}>
                                      <span style={{color: 'red'}}>An error occurred during authentication, please try again!</span>
                                    </p>
                                  }
                                  <React.Fragment>
                                    <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                                      <CommonButton
                                        style={{textTransform: 'none'}}
                                        label="LOGIN via GWF"
                                        variant="contained"
                                        primary
                                        className="login-button"
                                        onClick={login}
                                      />
                                    </div>
                                  </React.Fragment>
                                </div>
                              );
                            case AuthenticationState.InProgress:
                              return (
                                <React.Fragment>
                                  <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                                    <CommonButton
                                      label="Authenticating..."
                                      variant="contained"
                                      className="login-button"
                                      disabled
                                    />
                                  </div>
                                </React.Fragment>

                              );
                          }
                        }
                      }
                    </AzureAD>
                  </div>
                </React.Fragment>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default connect()(AzureLogin);

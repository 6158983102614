import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Card, FormControlLabel, Checkbox } from '@mui/material';
import GenericTable from '../GenericTable';
import LoaderInline from '../LoaderInline';
import APIService from '../../services/APIService';
import { get, uniq, compact, map, isEqual, startCase, includes, find } from 'lodash';
import { getSelectedFarm } from '../../actions/api/farms';
import { getOwnershipStocksLoads, receiveFarm, receiveMyStocksLoads } from '../../actions/companies/farms';
import OwnershipLoadsCard from './OwnershipLoadsCard';
import { getCountryLabel, isSystemCompany, isObserver, showTargetMoistureTab, currentUser, validateUnitForStockOperation } from '../../common/utils';
import {
  getLoadsWithBalance, numberFormaterByLoadType, numberFormatterByValue, loadReferencesDisplay,
  getLoadReferences,
  getLoadsWithBalanceWithTargetMoistureTonnage
} from './utils';
import {
  setHeaderText, setBreadcrumbs, forceStopLoader
} from '../../actions/main';
import CreateStorageInload from '../../containers/CreateStorageInload';
import UpdateInload from '../../containers/UpdateInload';
import CreateStorageOutload from '../../containers/CreateStorageOutload';
import UpdateOutload from '../../containers/UpdateOutload';
import SideDrawer from '../common/SideDrawer';
import CommonListingButton from '../common/CommonListingButton';
import {
  showHideTitleTransferSideDrawer,
  showViewTitleTransferSideDrawer
} from '../../actions/companies/contracts';
import TitleTransferDetails from '../../components/title-transfers/TitleTransferDetails';
import {
  receiveTitleTransfer,
  getSelectedTitleTransfer
} from '../../actions/companies/contracts';
import StockSwapDialog from './StockSwapDialog';
import UpdateStorageFilters from './UpdateStorageFilters';
import { getCommodities } from '../../actions/api/commodities';
import ManageStorageAndOwnershipStocks from './ManageStorageAndOwnershipStocks';
import { OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STOCK_UPDATE_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD, STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES } from '../../common/constants';

const MULTIPLE = 'Multiple';
const netWeightFormatter = load => numberFormaterByLoadType(load, 'tonnage');
const balanceFormatter = load => numberFormatterByValue(load, 'balance');
const quantityFormatter = load => numberFormaterByLoadType(load, 'quantity');
const quantityBalanceFormatter = load => numberFormatterByValue(load, 'quantityBalance');
const getColumns = commodity => {
  const unit = commodity?.requestedUnit || currentUser()?.unit
  return [
    {header: 'Date & Time', key: 'date'},
    {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
    {header: 'Freight Provider', key: 'freightProvider', className: 'medium'},
    {header: getCountryLabel('rego'), key: 'rego'},
    {header: 'Season', key: 'season', className: 'xsmall'},
    {header: 'NGR', key: 'ngr'},
    {header: 'Variety', key: 'varietyName'},
    {header: 'Grade', key: 'gradeName', className: 'xsmall'},
    {header: `${getCountryLabel('tonnage')} (${unit})`, key: 'tonnage'},
    {header: `${getCountryLabel('tonnage')} with Shrinkage (${unit})`, formatter: load => numberFormaterByLoadType(load, showTargetMoistureTab() ? 'tonnageWithTargetMoisture' : 'tonnageWithShrinkage')},
    {header: `Balance (${unit})`, formatter: balanceFormatter},
    {header: 'User', key: 'createdBy'},
  ];
};

const getStockSwapColumns = commodity => {
  const unit = commodity?.requestedUnit || currentUser()?.unit
  return [
    {header: 'Date & Time', key: 'date'},
    {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
    {header: 'Stock Owner', key: 'stockOwnerCompanyName'},
    {header: 'NGR', key: 'ngr'},
    {header: 'Season', key: 'season', className: 'xsmall'},
    {header: 'Variety', key: 'varietyName'},
    {header: 'Grade', key: 'gradeName', className: 'xsmall'},
    {header: `${getCountryLabel('tonnage')} (${unit})`, formatter: netWeightFormatter, className: 'medium'},
    {header: `Balance (${unit})`, formatter: balanceFormatter, className: 'small'},
    {header: 'User', key: 'createdBy'},
  ];
};
class OwnershipLoads extends React.Component {
  constructor(props) {
    super(props);
    this.setQueryParamsInContext(props);
    this.orderedLoads = false;

    this.state = {
      includeVoid: false,
      openTitleTransferSideDrawer: false,
      isAddLoadSideDrawerOpened: false,
      isEditLoadSideDrawerOpened: false,
      load: null,
      movement: null,
      ownerId: this.ownerId,
      farmId: this.farmId,
      ngrId: this.ngrId,
      season: this.season,
      commodityId: this.commodityId,
      gradeId: this.gradeId,
      loads: null,
      ngr: null,
      isLoading: false,
      handleStocksSwap: false,
      openUpdateStorageFilter: false,
      isRedirectedFromSwapPage: false,
      ngrCompanyId: null,
      manageStorageOwnershipSideDrawerOpened: false
    };
    this.openAddLoadSideDrawer = this.openAddLoadSideDrawer.bind(this);
    this.closeAddLoadSideDrawer = this.closeAddLoadSideDrawer.bind(this);
    this.closeEditLoadSideDrawer = this.closeEditLoadSideDrawer.bind(this);
  }

  closeSideDraw = () => {
    this.props.showHideTitleTransferSideDrawer(false);
    this.props.showViewTitleTransferSideDrawer(false);
    this.setState({ openTitleTransferSideDrawer: false, handleStocksSwap: false, openUpdateStorageFilter: false});
  }

  setQueryParamsInContext(props) {
    if(!props)
      return;
    const { match } = props;
    const queryParams = this.getQueryParams(props);
    this.farmId = parseInt(queryParams.get('farmId') || get(match, 'params.farm_id')) || null;
    this.ownerId = parseInt(queryParams.get('ownerId')) || null;
    this.season = queryParams.get('season') || null;
    this.gradeId = parseInt(queryParams.get('gradeId')) || null;
    this.commodityId = parseInt(queryParams.get('commodityId')) || null;
    this.ngrId = parseInt(queryParams.get('ngrId') || get(match, 'params.ngr_id')) || null;
  }

  getQueryParams(props) {
    props = props || this.props;
    const { location } = props;
    return new URLSearchParams(get(location, 'search'));
  }

  componentDidMount() {
    this.fetchDataIfNeeded();
    this.fetchLoads();
    this.fetchFarmLoadsAttributes();
    this.props.getCommodities();
  }

  componentDidUpdate(prevProps) {
    this.fetchDataIfNeeded();
    const breadcrumbs = this.getBreadcrumbs();
    const headerText = this.getHeaderText();
    if(!isEqual(breadcrumbs, this.props.breadcrumbs))
      this.props.dispatch(setBreadcrumbs(breadcrumbs));
    if(!isEqual(headerText, this.props.headerText))
      this.props.dispatch(setHeaderText(headerText));
    if (includes(this.getReturnTo(), '/stocks/stock-swaps') && !this.state.isRedirectedFromSwapPage)
      this.setState({isRedirectedFromSwapPage: true});
    if (this.props.stocksLoads !== prevProps.stocksLoads)
      this.setState({loads: this.props.stocksLoads, isLoading: false});
  }

  getReturnTo() {
    const searchParams = this.props.location.search;
    if(searchParams) {
      const queryParams = queryString.parse(searchParams);
      return get(searchParams.split('returnTo='), '[1]') || get(queryParams, 'returnTo');
    }
  }

  getBreadcrumbs() {
    const { farm, breadcrumbs } = this.props;
    if(farm)
      return [
        {text: 'Stocks', route: '/stocks/ownership-view'},
        {text: farm.name, route: this.getReturnTo()},
        {text: 'Ownership Loads'},
      ];
    return breadcrumbs;
  }

  getHeaderText() {
    const { farm, headerText } = this.props;
    if(farm)
      return farm.name;
    return headerText;
  }

  fetchDataIfNeeded() {
    this.fetchFarmIfNeeded();
    this.fetchNGRIfNeeded();
  }

  getQueryStringFromState() {
    const { ngrId, gradeId, season, commodityId, ngrCompanyId, includeVoid } = this.state;
    let query = '?';
    if(commodityId)
      query += `commodity_id=${commodityId}&`;
    if(ngrId && ngrId.toString().toLowerCase() !== MULTIPLE.toLowerCase())
      query += `ngr_id=${ngrId}&`;
    if(season && season.toLowerCase() !== MULTIPLE.toLowerCase())
      query += `season=${season}&`;
    if(gradeId && gradeId.toString().toLowerCase() !== MULTIPLE.toLowerCase())
      query += `grade_id=${gradeId}&`;
    if (includes(this.getReturnTo(), '/stocks/stock-swaps'))
      query += 'stock_swap=true&';
    if(includeVoid)
      query += 'include_void=true&'
    if(ngrCompanyId)
      query += `ngr_company_id=${ngrCompanyId}`;
    return query;
  }

  fetchFarmIfNeeded() {
    const { farmId } = this.state;
    const { farm, fetchFarm } = this.props;
    if((!farm || farm.id !== farmId) && farmId)
      fetchFarm(farmId);
  }

  fetchNGRIfNeeded() {
    const { ngrId, ngr } = this.state;
    if((!ngr || ngr.id !== ngrId) && ngrId)
      this.fetchNGR();
  }

  fetchNGR() {
    const { ngrId } = this.state;
    APIService.ngrs(ngrId).appendToUrl('minimal/').get().then(
      ngr => this.setState({ngr: ngr})
    );
  }

  toggleVoid = () => this.setState({includeVoid: !this.state.includeVoid, isLoading: true}, this.fetchLoads)

  fetchLoads() {
    const queryString = this.getQueryStringFromState();
    if(this.farmId) {
      APIService.farms(this.farmId).appendToUrl(`loads/minimal/${queryString}`)
        .get().then(response => {
          this.props.dispatch(receiveMyStocksLoads(response, this.farmId));
          this.setState({loads: response?.results, isLoading: false}, () => this.props.dispatch(forceStopLoader()))
        });
    }
  }

  getAttrFromLoads(attr) {
    const { loads } = this.state;
    if(this.state.loads) {
      const values = uniq(compact(map(loads, attr)));
      if(values.length > 1)
        return MULTIPLE;
      return values[0];
    }
  }

  fetchFarmLoadsAttributes() {
    if (this.farmId && this.ngrId) {
      APIService.farms(this.farmId).appendToUrl(`attributes/?ngrs=${this.ngrId}`)
        .get().then(attributes => this.setState({ attributes: attributes }));
    }
    else {
      APIService.farms(this.farmId).appendToUrl(`attributes/`)
        .get().then(attributes => this.setState({ attributes: attributes }));
    }
  }

  refreshLoads = state => {
    this.setState(
      {
        commodityId: state.commodityId, gradeId: state.gradeId, season: state.season, isLoading: true,
        ngrCompanyId: state.ngrCompanyId,
      },
      this.fetchLoads
    );
  };

  openAddLoadSideDrawer(type, callback) {
    if (validateUnitForStockOperation()) {
      this.setState({
        isAddLoadSideDrawerOpened: true, isEditLoadSideDrawerOpened: false,
        loadType: type
      }, () => {
        if(callback)
          callback();
      });
    }
  }

  closeAddLoadSideDrawer() {
    this.setState({ isAddLoadSideDrawerOpened: false, loadType: null });
  }

  closeEditLoadSideDrawer() {
    this.setState({ isEditLoadSideDrawerOpened: false, load: null, movement: null, loadType: null });
  }

  handleDefaultCellClick = load =>  {
    if(load.freightMovementId)
      window.open(`#/freights/movements/${load.freightMovementId}/details`);
    else if(load.titleTransferNumber && validateUnitForStockOperation()) {
      this.setState({
        openTitleTransferSideDrawer: true
      }, () => {
        this.props.dispatch(getSelectedTitleTransfer(load.titleTransferId, receiveTitleTransfer, false));
      });
    }
    else if(get(load, 'optionType') == -4 && validateUnitForStockOperation()){
      this.setState({
        handleStocksSwap: true,
        load: load,
        loadType: load.type,
      });
    } else if(get(load, 'optionType') == -2 && validateUnitForStockOperation()){
      this.setState({
        openUpdateStorageFilter: true,
        load: load,
        loadType: load.type,
      });
    } else if ((window.MANAGE_OWNERSHIP_STORAGES || isSystemCompany() || isObserver()) && includes(STORAGE_STOCK_EMPTY_UPDATE_OPTION_TYPES, load.optionType) && validateUnitForStockOperation()) {
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: false,
        isAddLoadSideDrawerOpened: false,
        manageStorageOwnershipSideDrawerOpened: true,
        handleStocksSwap: false,
        load: load,
        loadType: load.type,
      });
    }
     else if (validateUnitForStockOperation())
      this.setState({
        openTitleTransferSideDrawer: false,
        isEditLoadSideDrawerOpened: true,
        isAddLoadSideDrawerOpened: false,
        load: load,
        loadType: load.type,
      });
  };

  getSelectedCommodity = commodityId => find(this.props.commodities, {id: commodityId || this.state.commodityId})

  hasQuantityBasedCommodity = () => Boolean(this.state.commodityId && this.getSelectedCommodity()?.isQuantityBased)

  getTitle = optionType => {
    if (optionType === OPTION_TYPE_STOCK_EMPTY_LOAD)
      return 'Stock Empty'
    if (optionType === OPTION_TYPE_STOCK_UPDATE_LOAD)
      return 'Stock Update'
    if (optionType === OPTION_TYPE_STORAGE_EMPTY_LOAD)
      return
  }

  render() {
    const { loads, attributes, loadType, load, isLoading, includeVoid } = this.state;
    let columns = getColumns(this.getSelectedCommodity());
    if (this.hasQuantityBasedCommodity()) {
      columns.splice(11, 0, {header: 'Quantity', key: 'quantity', formatter: quantityFormatter, className: 'small'});
      columns.splice(12, 0, {header: 'Quantity Balance', key: 'quantityBalance', formatter: quantityBalanceFormatter, className: 'small'});
    }
    const isExternallySyncSourceSite = get(this.props, 'farm.externallySyncSource');
    return (
      <div>
        {
          attributes &&
          <OwnershipLoadsCard
            {...this.state}
            onSubmit={this.refreshLoads}
            farm={this.props.farm}
            isRedirectedFromSwapPage={this.state.isRedirectedFromSwapPage}
          />
        }
        <Card style={{marginTop: '10px', padding: '10px'}}>
          <div>
            <div style={{textAlign: 'right'}}>
              <FormControlLabel
                size='small'
                label="Show Void"
                control={
                  <Checkbox
                    size='small'
                    checked={includeVoid}
                    onChange={this.toggleVoid}
                    disabled={isLoading}
                  />
                }
              />
              {
                !this.state.isRedirectedFromSwapPage && !isExternallySyncSourceSite &&
                  <CommonListingButton
                    style={{zIndex: 1}}
                    showMenus={true}
                    showDownLoadIcon={false}
                    optionMapper={[
                      { name: 'Add Inload', fx: callback => this.openAddLoadSideDrawer('inload', callback) },
                      { name: 'Add Outload', fx: callback => this.openAddLoadSideDrawer('outload', callback) },
                    ]}
                    title='Add Loads'
                    name='Add Loads'
                  />
              }
            </div>
            {
              (loads === null || isLoading) ?
                <LoaderInline containerClassName="inline-loader-container" />:
                <div className="col-xs-12 padding-reset" style={{marginTop: '-38px'}}>
                  <React.Fragment>
              <GenericTable
                columns={this.state.isRedirectedFromSwapPage ? getStockSwapColumns(this.getSelectedCommodity()) : columns}
                items={showTargetMoistureTab() ? getLoadsWithBalanceWithTargetMoistureTonnage(loads) : getLoadsWithBalance(loads)}
                handleDefaultCellClick={this.handleDefaultCellClick}
                orderBy="dateTime"
                order="desc"
                rowHighlightedMap={
                  {"void": 'void-table-row'}
                }
                globalSearch
                noCache
                hardClear
                {...this.props}
              />
              </React.Fragment>
              </div>
            }
          </div>
        </Card>
        {
          loadType === 'inload' &&
          <SideDrawer
            isOpen={this.state.isAddLoadSideDrawerOpened}
            title={`Add ${startCase(loadType)}`}
            size="big"
            onClose={this.closeAddLoadSideDrawer}
            app="load"
            >
            <CreateStorageInload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              closeDrawer={this.closeAddLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={true}
            />
          </SideDrawer>
        }
        {
          loadType === 'inload' &&
          <SideDrawer
            isOpen={this.state.isEditLoadSideDrawerOpened}
            title={`Edit ${startCase(loadType)}`}
            size="big"
            onClose={this.closeEditLoadSideDrawer}
            app="load"
            >
            <UpdateInload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              inload={load}
              closeDrawer={this.closeEditLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={false}
            />
          </SideDrawer>
        }
        {
          loadType === 'outload' &&
          <SideDrawer
            isOpen={this.state.isAddLoadSideDrawerOpened}
            title={`Add ${startCase(loadType)}`}
            size="big"
            onClose={this.closeAddLoadSideDrawer}
            app="load"
            >
            <CreateStorageOutload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              closeDrawer={this.closeAddLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={true}
            />
          </SideDrawer>
        }
        {
          loadType === 'outload' &&
          <SideDrawer
            isOpen={this.state.isEditLoadSideDrawerOpened}
            title={`Edit ${startCase(loadType)}`}
            size="big"
            onClose={this.closeEditLoadSideDrawer}
            app="load"
            >
            <UpdateOutload
              companyId={this.props.companyId}
              farmId={this.props.farmId}
              storageId={this.props.storageId}
              outload={load}
              closeDrawer={this.closeEditLoadSideDrawer}
              showNgrField={this.props.showNgrField}
              isCreate={false}
            />
          </SideDrawer>
        }
        {
          this.props.isViewTitleTransferSideDrawerOpened &&
          <SideDrawer
            isOpen={this.state.openTitleTransferSideDrawer}
            title="Title Transfer"
            onClose={this.closeSideDraw}
            size='big'>
            <TitleTransferDetails onClose={this.closeSideDraw} />
          </SideDrawer>
        }
        {this.state.handleStocksSwap &&
          <SideDrawer isOpen={this.state.handleStocksSwap} title="Stock Swap" size='small' onClose={this.closeSideDraw}>
            <StockSwapDialog
              isEdit={true}
              token={this.props.token}
              item={this.state.load}
              isOpen={this.state.handleStocksSwap}
              toggleDialog={this.closeSideDraw}
            />
          </SideDrawer>
        }
        {this.state.openUpdateStorageFilter &&
          <UpdateStorageFilters
          isEdit={true}
          regradingStock={true}
          isStoragesView={false}
          token={this.props.token}
          item={this.state.load}
          isOpen
          toggleDialog={this.closeSideDraw}
        />
        }
        {this.state.manageStorageOwnershipSideDrawerOpened &&
        <SideDrawer
          isOpen={this.state.manageStorageOwnershipSideDrawerOpened}
          title={includes([OPTION_TYPE_STOCK_EMPTY_LOAD, OPTION_TYPE_STORAGE_EMPTY_LOAD], load.optionType) ? "Stock Empty" : "Stock Update"}
          size='big'
          onClose={() => this.setState({manageStorageOwnershipSideDrawerOpened: false})}
        >
          <ManageStorageAndOwnershipStocks load={load} onClose={() => this.setState({manageStorageOwnershipSideDrawerOpened: false})}/>
        </SideDrawer>
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  farm: state.companies.farms.selectedFarm,
  breadcrumbs: state.main.breadcrumbs,
  headerText: state.main.headerText,
  isViewTitleTransferSideDrawerOpened: state.companies.contracts.isViewTitleTransferSideDrawerOpened,
  commodities: state.master.commodities.items,
  stocksLoads: state.companies.farms.myStocksLoads,
  paginationData: state.companies.farms.stocksPaginationData,
});

const mapDispatchToProps = dispatch => ({
  getCommodities: () => dispatch(getCommodities()),
  showHideTitleTransferSideDrawer: (flag) => dispatch(showHideTitleTransferSideDrawer(flag)),
  showViewTitleTransferSideDrawer: (flag) => dispatch(showViewTitleTransferSideDrawer(flag)),
  fetchFarm: (farmId) => dispatch(getSelectedFarm(farmId, receiveFarm)),
  navigateTo: url => dispatch(getOwnershipStocksLoads(url, true)),
  changePageSize: (url, pageSize) => {
    if (includes(url, '?')){
      url = `${url}&page_size=${pageSize}`;
    } else {
      url = `${url}?page_size=${pageSize}`;
    }
    dispatch(getOwnershipStocksLoads(url, true));
  },
  dispatch,
});


export default connect(mapStateToProps, mapDispatchToProps)(OwnershipLoads);

import React, { useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CommonDatePicker from '../common/CommonDatePicker';
import { useState } from 'react';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import { map, includes, get } from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch } from 'react-redux';
import { setDownloadBar } from '../../actions/main';
import { currentUser } from "../../common/utils";
import { SYSTEM_COMPANY_IDS, GROWER } from "../../common/constants";
import moment from 'moment';
import Checkbox from "@mui/material/Checkbox/Checkbox";

let RSMReportFilters = props => {

    let [combinedStates, setCombinedStates] = useState({
        'date_time__gte': '',
        'date_time__lte': '',
        'type__in': [],
        'site_id__in': [],
        'show_only_overweights': false
    });

    const dispatch = useDispatch();


    let [allSites, setAllSites] = useState({});


    let handleStocksCSV = () => {
        let combinedStatesCopy = Object.assign({}, combinedStates);
        if (combinedStatesCopy.date_time__lte) {
            let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
            lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
            combinedStatesCopy.date_time__lte = lte_time;
        }

        if (combinedStatesCopy.date_time__gte) {
            let gte_time = `${combinedStatesCopy.date_time__gte} 00:00:00`;
            gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
            combinedStatesCopy.date_time__gte = gte_time;
        }

      dispatch(setDownloadBar('Your Freight Movements CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
      const service = APIService.freights().contracts().appendToUrl('rms-csv/');
      service.put(combinedStatesCopy, props.token);
      props.toggleDialog();
    };


    let getAllSites = () => {
        const user = currentUser();
        if (!includes(SYSTEM_COMPANY_IDS, user.companyId) && get(user, 'company.typeId') === GROWER)
            APIService.companies(user.companyId).farms().appendToUrl('?is_active=true').get().then(response => setAllSites(map(response, item => ({ ...item, name: item?.displayName }))))
        else {
            APIService.companies(user.companyId).appendToUrl('company_sites/?is_active=true').get().then(response => setAllSites(map(response, item => {
                let displayName = includes(SYSTEM_COMPANY_IDS, user.companyId) ? 'Unknown Company' : get(item, 'company.displayName');
                return { ...item, name: `${displayName} (${item.name})` }
            })))
        }
    };


    useEffect(() => {
        getAllSites();
    }, [props.isOpen]);


    return (
        <div>
            <Dialog open={props.isOpen} onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
                <DialogTitleWithCloseIcon
                    onClose={props.toggleDialog}
                    closeButtonStyle={{ marginTop: '0px' }}
                    id='form-dialog-title'>
                    {'RMS Report'}
                </DialogTitleWithCloseIcon>

                <DialogContent style={{ marginTop: '15px' }}>
                    <div className="col-sm-12 padding-reset">
                        <CommonMultiSelect
                            id="type__in"
                            items={[{ 'id': 'inload', 'name': 'Inload' },
                            { 'id': 'outload', 'name': 'Outload' }]}
                            selectedItems={combinedStates.type__in}
                            displayField="name"
                            onChange={(id, selectedItems) =>
                                setCombinedStates({ ...combinedStates, 'type__in': map(selectedItems, 'id') })}
                            placeholder="Select Load Type..."
                            label="Load Type"
                            selectAll
                            clearAll
                        />
                    </div>

                    <div>
                        <div className='col-sm-6 no-left-padding'>
                            <CommonDatePicker
                                id='date_time__gte'
                                floatingLabelText='Start Date'
                                value={combinedStates.date_time__gte}
                                onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                            />
                        </div>

                        <div className='col-sm-6 no-right-padding'>
                            <CommonDatePicker
                                id='date_time__lte'
                                floatingLabelText='End Date'
                                value={combinedStates.date_time__lte}
                                onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                            />
                        </div>


                    </div>

                    <div className="col-sm-12 padding-reset">
                        <CommonMultiSelect
                            id="site_id__in"
                            items={allSites}
                            selectedItems={combinedStates.site_id__in}
                            displayField="name"
                            onChange={(id, selectedItems) =>
                                setCombinedStates({ ...combinedStates, 'site_id__in': map(selectedItems, 'id') })}
                            placeholder="Select Site..."
                            label="Sites"
                            selectAll
                            clearAll
                        />
                    </div>

                    <div>
                        <Checkbox
                            id={'show_only_overweights'}
                            checked={combinedStates.show_only_overweights}
                            style={{ height: '40px' }}
                            onChange={() => setCombinedStates({ ...combinedStates, 'show_only_overweights': !combinedStates.show_only_overweights })}
                        />{'Show only overweights'}
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button type='button' onClick={props.toggleDialog} variant='outlined'>
                        Cancel
                    </Button>
                    <Button type='button' onClick={handleStocksCSV} color='primary' variant='outlined'>
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );

};

export default RSMReportFilters;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import '@babel/polyfill';
import moment from 'moment';
import alertifyjs from 'alertifyjs';
import IconButton from '@mui/material/IconButton';
import { Cancel as RemoveIcon } from '@mui/icons-material'
import ArrowForward from '@mui/icons-material/ArrowForward';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import DialogTitle from '@mui/material/DialogTitle/DialogTitle';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import DialogActions from '@mui/material/DialogActions/DialogActions';
import Dialog from '@mui/material/Dialog/Dialog';
import withStyles from '@mui/styles/withStyles';
import CommonButton from '../common/CommonButton';
import {
  FREIGHT_CONTRACT_TYPE,
  PRICE_POINTS,
  PRIMARY_COLOR_GREEN,
  MT_UNIT,
  AMENDABLE_STATUSES,
  PICKUP_MANDATORY_PRICE_POINTS, DELIVERY_MANDATORY_PRICE_POINTS,
  REQUEST_ORDER_TYPE_IDS, PICKUP_REQUEST_ORDER_TYPE_ID, CALL_ON_GRAIN_TYPE_ID, GROWER,
  CONTRACT_TYPES, TRANSPORT_MODE_TRUCK, WARM_GREY, DELIVERY_REQUEST_ORDER_TYPE_ID
} from '../../common/constants';
import CurrencyField from '../common/CurrencyField'
import { required, selected, minDate, maxDate } from '../../common/validators';
import { SCENARIOS_FOR_SELF_ORDER, SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS } from './Constants';
import { create, getOrderFixtures } from '../../actions/companies/orders';
import { getCompanyCompaniesMinimal } from '../../actions/api/companies';
import { isLoading, loaded, setBreadcrumbs, setHeaderText } from '../../actions/main/index';
import { getCompanyTrucks } from '../../actions/api/trucks';
import {
  clickAddGeneralConditionButton,
  clickAddSpecialConditionButton,
  getGeneralConditions,
  getSpecialConditions,
  receiveGeneralConditions,
  receiveSpecialConditions,
} from '../../actions/companies/conditions';
import { receiveUserTrucks } from '../../actions/companies/trucks';
import {
  remove, isEmpty, isNumber, isEqual, map, filter, every, replace, find, forEach, mapValues,
  includes, set, get, has, uniqBy, merge, omit, isArray, pick, partialRight, intersectionWith,
  isObject, cloneDeep, keys, values, compact, uniq, without, isNil, min, isString,
  isNull
} from 'lodash';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommodityAutoComplete from '../common/autocomplete/CommodityAutoComplete';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import GradeAutoComplete from '../common/autocomplete/GradeAutoComplete';
import CommonSelect from '../common/select/CommonSelect';
import CommonDatePicker from '../common/CommonDatePicker';
import SeasonSelect from '../common/select/SeasonSelect';
import TransportModeSelect from '../common/select/TransportModeSelect';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import { INVOICINGS } from './fixtures';
import { positiveDecimalFilter } from '../../common/input-filters';
import CommonTextField from '../common/CommonTextField';
import {
  generateIdentifier,
  getAutoSelectFocusField,
  getLabelForEmployeeForm,
  getPoolGrades,
  getSitePhoneNumber,
  getZendeskURL,
  isCurrentUserBroker,
  getDateTimeFromUTC,
  getCountryFormats,
  getCountryLabel,
  getCountryConfig,
  getCountrySystemCompanyId,
  getCountryCurrency,
  isCurrentUserBelongsToCompany,
  toPhoneFormat,
  generateUpdatedIdentifier,
  currentUserCompany
} from '../../common/utils';
import { getCommoditiesWithVarietiesAndGrades } from '../../actions/api/commodities';
import APIService from '../../services/APIService';
import CommodityContractParties from './CommodityContractParties';
import OrderPreview from './order-preview/OrderPreview';
import GrainOrderPreview from './order-preview/GrainOrderPreview';
import SideDrawer from '../common/SideDrawer';
import CreateEmployee from '../../containers/CreateEmployee';
import CreateGeneralCondition from '../../containers/CreateGeneralCondition';
import CreateSpecialCondition from '../../containers/CreateSpecialCondition';
import { clickAddEmployeeButton } from '../../actions/company-settings/employees';
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import {AddCircleIconWithTooltip} from "../common/icons/AddCircleIconWithTooltip";
import {canCreateEmployee} from "../../actions/api/employees";
import {clickAddFarmEmployeeButton} from "../../actions/companies/farm-employees";
import {raiseOrderAmendRequest} from "../../actions/companies/freights";
import {forceStopLoader} from "../../actions/main";
import {canCreateNgr} from "../../actions/api/ngrs";
import Notes from "../common/Notes";
import CustomEmailDialog from '../common/CustomEmailDialog';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CreateCompanySite from '../../containers/CreateCompanySite';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import CounterPartiesInfo from '../vendor-decs/form-components/CounterPartiesInfo';
import CreateCompany from '../../containers/CreateCompany';
import { Checkbox, FormControlLabel, Tooltip } from '@mui/material';
import QuickOrder from '../common/TemplateSearch';
import first from 'lodash/first';
import toString from 'lodash/toString';
import pickBy from 'lodash/pickBy';
import UpdateLocation from '../locations/UpdateLocation';
import PinDropIcon from '@mui/icons-material/PinDrop';
import AddButton from '../common/AddButton';
import Create from '@mui/icons-material/Create';
import { CommonToolTip } from '../common/CommonToolTip';
import HierarchySideDrawer from '../common/HierarchySideDrawer';
import { CHECKPOINT_ORDER_CREATION_PERMISSIONS } from './Constants';
import { COMPANY_ADMIN } from '../../common/constants';
import NumberField from '../common/NumberField';

const NO_FILTERED_CONTRACTS = 'No Contracts found for the applied filter';
const DEFAULT_SIDE_DRAWERS_STATE = {
  customerSideDrawerIsOpen: false,
  ngrSideDrawerIsOpen: false,
  freightProviderSideDrawerIsOpen: false,
  generalConditionSideDrawerIsOpen: false,
  specialConditionSideDrawerIsOpen: false,
  consignorSideDrawer: false,
  consigneeSideDrawer: false
};
const autocompleteFilters = createFilterOptions();
const styles = () => ({
  inputRoot: {
    color: 'black !important',
  },
  consignorPopper: {
    width: '400px',
    marginTop: '1325px',
    marginLeft: localStorage.toggleNav === "true" ? '115px' : '270px',
  },
  consigneePopper: {
    width: '400px',
    marginTop: '1325px',
    marginLeft: localStorage.toggleNav === "true" ? '805px' : '960px',
  },
});

const EntityTonnageDetail = props => {
  const grid = (12 / props.columns);
  return (
  <React.Fragment>
    {map(props.items, (item, i) => {
      const labelStyle = props.labelStyle || {};
      const valueStyle = props.valueStyle || {};
      const isLastIndex = i === props.items.length - 1;
      const isFirstIndex = i === 0;
      return (
      <span key={i} className='row col-sm-12' style={{ padding: '1px 0px', minHeight: '17px' }}>
        {isFirstIndex ?
        <span className={`col-sm-${grid}`} style={{ ...props.headerStyle }}>
          Tonnages
        </span> :
        <span className={`col-sm-${grid} field-label`} style={{ fontSize: '0.85rem', paddingLeft: 0, paddingRight: 4, ...labelStyle }}>
          {item.label}
        </span>
        }
        {isFirstIndex ?
        <span className={`col-sm-${grid}`} style={{ ...props.headerStyle, textAlign: 'end', paddingRight: 6 }}>
          {props.counterEntity ? props.entityDisplay : ''}
        </span> :
        <span className={`col-sm-${grid} field-value`} style={{ fontSize: '0.90rem', fontWeight: 400, paddingRight: 6, textAlign: 'end', ...valueStyle }}>
          {`${item.value} ${item.unit || props.unit || 'MT'}`}
          {isLastIndex &&
          <div>
          <a rel="noopener noreferrer" style={{textDecoration: "underline", cursor: 'pointer', paddingBottom: '2px', paddingTop: '2px', display: 'block'}} onClick={() => props.openHierarchyFor(props.entity)}> Breakdown</a>
          </div>
          }
        </span>
        }
        {props.counterEntity &&
        <>
        {isFirstIndex ?
        <span className={`col-sm-${grid}`} style={{ ...props.headerStyle, textAlign: 'end', padding: 0 }}>
          {props.counterEntityDisplay}
        </span> :
        <span className={`col-sm-${grid} field-value`} style={{ fontSize: '0.90rem', fontWeight: 400, padding: 0, textAlign: 'end', ...valueStyle }}>
          {`${item.counterEntityVal} ${item.unit || props.unit || 'MT'}`}
          {isLastIndex &&
           <a rel="noopener noreferrer" style={{textDecoration: "underline", cursor: 'pointer', paddingBottom: '2px', paddingTop: '2px'}} onClick={() => props.openHierarchyFor(props.counterEntity)}> Breakdown</a>
          }
        </span>
        }
        </>
        }
      </span>
    )})}
  </React.Fragment>
)};


class FreightOrderForm extends Component {
  constructor(props) {
    super(props);
    this.isEditForm = Boolean(this.props.match.params.order_id);
    this.isCallOnGrainOrder = window.location.hash.match('#/freights/grain/new');
    this.isParentRequestOrder = window.location.hash.match('&parent=requestorder')
    this.parentRequestOrder = null
    this.removeLoader = false;
    this.applyingTemplate = false;
    this.tonnageLabel = getCountryLabel('tonnage')
    this.identifierGenerated = generateIdentifier(this.isCallOnGrainOrder ? 'grain_order':'order');
    this.EXCESS_ORDER_QUANTITY_ERROR_MESSAGE = 'Order volume cannot be greater than Contract volume';
    this.EXCESS_ALLOCATION_TONNAGE_ERROR_MESSAGE = `Allocation ${this.tonnageLabel.toLowerCase()} cannot be greater than Order ${this.tonnageLabel.toLowerCase()}`;
    this.EXCESS_ALLOCATION_QUANTITY_ERROR_MESSAGE = 'Allocation volume cannot be greater than Order volume';
    this.MINIMUM_TONNAGE_ERROR_MESSAGE = `Order ${this.tonnageLabel.toLowerCase()} cannot be less than it's combined allocations & movements's ${this.tonnageLabel.toLowerCase()}`;
    this.MINIMUM_QUANTITY_ERROR_MESSAGE = "Order volume cannot be less than it's combined allocations & movements's volume";
    const queryParams = new URLSearchParams(this.props.location.search);
    const hashQueryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    this.isDuplicatingOrder = Boolean(hashQueryParams.get('copyFrom')) && window.VOID_AND_DUPLICATE_TOGGLE
    this.selectedOrderId = queryParams.get('orderId');
    this.applyingBaseEntity = false;
    this.selectedOrder = null;
    this.fetchingOrder = false;
    this.disableForm = true;
    this.state = {
      enableSubmit: true,
      countryFormats: getCountryFormats(),
      currency: getCountryCurrency(),
      saleContractId: null,
      purchaseContractId: null,
      allocatedContract: null,
      isLoadingAllocatedContract: false,
      pickupSettings: {},
      deliverySettings: {},
      partyValidate: false,
      isDependent: true,
      orderStatus: "",
      pickupCheckpointOrderExists: false,
      deliveryCheckpointOrderExists: false,
      fetchingConditions: true,
      emailPopupParties: [],
      isDefaultGeneralConditionApplied: false,
      isDefaultSpecialConditionApplied: false,
      base64Logo: null,
      didFetchedContracts: false,
      setFieldErrors: false,
      hasAnyChildren: false,
      contracts: [],
      filteredContracts: [],
      queryParamExists: false,
      selectedBaseEntityId: null,
      selectedBaseEntityType: null,
      selectedBaseEntity: null,
      selectedBaseEntityRaw: null,
      selectedConsignee: undefined,
      selectedConsignor: undefined,
      selectedConsignorId: undefined,
      selectedConsigneeId: undefined,
      selectedCustomer: undefined,
      totalDistance: 0,
      estimatedTime: 0,
      fetchConsignorSites: false,
      fetchConsigneeSites: false,
      consignors: [],
      sellerDetails: [],
      buyerDetails: [],
      consignees: [],
      consignorSites: [],
      consigneeSites: [],
      customerContacts: [],
      customerNgrs: [],
      generalConditionsAll: [],
      specialConditionsAll: [],
      providers: [],
      providerEmployees: [],
      selectedGeneralConditionText: undefined,
      selectedSpecialConditionText: undefined,
      generalConditionsSelector: undefined,
      specialConditionsSelector: undefined,
      selectedConsignorSite: undefined,
      selectedConsigneeSite: undefined,
      consignorAddress: undefined,
      consigneeAddress: undefined,
      isPickupSiteGate: false,
      isDeliverySiteGate: false,
      commissionRate: (0).toFixed(2),
      filterParty: undefined,
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      pickupOrder: null,
      deliveryOrder: null,
      templateDialog: false,
      resetAllFieldValues: false,
      contractStockAllocation: undefined,
      throughputInloadOrder: false,
      throughputInloadCheckbox: false,
      throughputOutloadOrder: false,
      throughputOutloadCheckbox: false,
      openConsignorFarmForm: false,
      openConsigneeFarmForm: false,
      openConsignorStorageForm: false,
      openConsigneeStorageForm: false,
      customerTonnageAvailable: undefined,
      openWarningDialog: false,
      isParentContractBlendContract: false,
      blendGradeDetails: [],
      chemicalApplications: [],
      deliveryOrderNumbers: [],
      relatedEntity: undefined,
      showHierarchy: false,
      openHierarchyFor: undefined,
      openUpdateTonnagePopup: false,
      pickupEmployees: [],
      deliveryEmployees: [],
      relatedEntityTonnage: {
        value: null,
        errors: []
      },
      templateName: {
        value: null,
        validators: [required()],
        errors: []
      },
      errors: {
        party: false
      },
      chemicalApplicationRates: [],
      fields: {
        externalReferenceNumber:{
          value: undefined,
          errors: [],
          validators: []
        },
        note: {
          description: '',
          attachments: [],
          companyId: this.props.currentUser.companyId,
          errors : []
        },
        isSelf: {
          value: false,
        },
        status: {
          value: 'planned',
        },
        parentOrderId: {
          value: undefined,
        },
        generalConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        specialConditions: {
          value: '',
          validators: [],
          errors: [],
        },
        identifier: {
          value: this.identifierGenerated,
          validators: [required()],
          errors: [],
        },
        commodityContractId: {
          value: undefined,
          validators: [selected()],
          errors: [],
        },
        commodityId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        varietyId: {
          value: undefined,
          validators: [],
          errors: [],
        },
        typeId: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        season: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        transportMode: {
          value: TRANSPORT_MODE_TRUCK,
          validators: [],
          errors: [],
        },
        paymentTermId: {
          value: undefined,
          validators: [selected()],
          errors: [],
        },
        assignToId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        transferOwnershipToId: {
          value: undefined,
          validators: [selected()],
          errors: [],
        },
        providerId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        invoicing: {
          value: undefined,
          validators: [],
          errors: [],
        },
        rateFreightIn: {
          value: undefined,
          validators: [],
          errors: [],
        },
        rateOvers: {
          value: undefined,
          validators: [],
          errors: [],
        },
        rateFreightOut: {
          value: undefined,
          validators: [],
          errors: [],
        },
        quantity: {
          value: undefined,
          validators: [],
          errors: [],
        },
        plannedTonnage: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        plannedGradeId: {
          value: undefined,
          validators: [required(), selected()],
          errors: [],
        },
        additionalCost: {
          value: undefined,
          validators: [],
          errors: [],
        },
        spread: {
          value: null,
          validators: [],
          errors: [],
        },
        freightPickup: {
          date: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          timeStart: {
            value: '12:00:00',
          },
          orderNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          orderId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          instructions: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignor: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            site: {
              locationType: {
                value: undefined,
                validators: [],
                errors: [],
              },
              locationId: {
                value: undefined,
                validators: [],
                errors: [],
              },
              address: undefined,
            },
          },
        },
        freightDelivery: {
          date: {
            value: undefined,
            validators: [required()],
            errors: [],
          },
          timeStart: {
            value: '12:00:00',
          },
          orderNumber: {
            value: undefined,
            validators: [],
            errors: [],
          },
          orderId: {
            value: undefined,
            validators: [],
            errors: [],
          },
          instructions: {
            value: undefined,
            validators: [],
            errors: [],
          },
          consignee: {
            handlerId: {
              value: undefined,
              validators: [],
              errors: [],
            },
            site: {
              locationType: {
                value: undefined,
                validators: [],
                errors: [],
              },
              locationId: {
                value: undefined,
                validators: [],
                errors: []
              },
              consignee: {
                handlerId: {
                  value: undefined,
                  validators: [],
                  errors: []
                },
                site: {
                  locationType: {
                    value: undefined,
                    validators: [],
                    errors: []
                  },
                  locationId: {
                    value: undefined,
                    validators: [],
                    errors: []
                  },
                  address: undefined,
                },
              },
            },
          },
        },
        customer: {
          company: undefined,
          companyId: {
            value: undefined,
            validators: [required(), selected()],
            errors: []
          },
          contactId: {
            value: undefined,
            validators: [required(), selected()],
            errors: []
          },
          ngrId: {
            value: undefined,
            validators: [selected()],
            errors: []
          },
          address: undefined,
          representedById: { value: undefined }
        },
      },
      isConsignorEditable: true,
      isConsigneeEditable: true,

      // Edit FO Fields
      isFetchingSelectedOrder: false,
      isPopulated: false,
      customerNgr: undefined,
      providerItems: [],
      customerItems: [],
      typeItems: [],
      openCompanySideDrawer: false,
      contractParty: ''
    };

    // Edit FO Vars
    this.editOrderId = get(this.props, 'match.params.order_id');
    this.editOrder = null;
    this.isEditForm = !!get(this.props, 'match.params.order_id');
    this.fieldsDup = cloneDeep(this.state.fields);


    const { dispatch } = this.props;
    if (!this.isEditForm) {
      if (includes(this.props.location.search, 'commodityContractId=')) {
        dispatch(isLoading('movementFormFromContract'));
      } else if (includes(this.props.location.search, '?orderId=')) {
        dispatch(isLoading('orderFormFromOrder'));
      }
      dispatch(getCompanyTrucks(this.props.currentUser.companyId, receiveUserTrucks));
    } else {
      this.props.dispatch(isLoading('nonExistentComponent'));
    }
    dispatch(getCompanyCompaniesMinimal(this.props.userCompanyId, { include_parent_company: true }));
    dispatch(getOrderFixtures());
    if(!this.isEditForm && !this.isCallOnGrainOrder){
      Promise.all([
        this.props.dispatch(getGeneralConditions('freight')),
        this.props.dispatch(getSpecialConditions('freight')),
      ]);
    }
    if(this.isCallOnGrainOrder){
      Promise.all([
        this.props.dispatch(getGeneralConditions('contract')),
        this.props.dispatch(getSpecialConditions('contract')),
      ]);
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleProviderChange = this.handleProviderChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleThroughputOrderChange = this.handleThroughputOrderChange.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
    this.handleCommodityChange = this.handleCommodityChange.bind(this);
    this.handleVarietyChange = this.handleVarietyChange.bind(this);
    this.handleGradeChange = this.handleGradeChange.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleOrderBlur = this.handleOrderBlur.bind(this);
    this.handleConsignorChange = this.handleConsignorChange.bind(this);
    this.handleConsigneeChange = this.handleConsigneeChange.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.appendGeneralConditions = this.appendGeneralConditions.bind(this);
    this.appendSpecialConditions = this.appendSpecialConditions.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleOrderTypeChange = this.handleOrderTypeChange.bind(this);
    this.handleBaseEntityChange = this.handleBaseEntityChange.bind(this);
    this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
    this.handleQuantityFieldChange = this.handleQuantityFieldChange.bind(this);
    this.setDistance = this.setDistance.bind(this);
    this.fetchDistance = this.fetchDistance.bind(this);
    this.handleSelfOrderChange = this.handleSelfOrderChange.bind(this);
    this.handleCustomerContactChange = this.handleCustomerContactChange.bind(this);
    this.handleInvoicingFieldChange = this.handleInvoicingFieldChange.bind(this);
    this.getProviders = this.getProviders.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handleFreightRateChange = this.handleFreightRateChange.bind(this);
    this.handleRateBlur = this.handleRateBlur.bind(this);
    this.setRateError = this.setRateError.bind(this);
    this.closeFreightProviderSideDrawer = this.closeFreightProviderSideDrawer.bind(this);
    this.closeCustomerSideDrawer = this.closeCustomerSideDrawer.bind(this);
    this.openCustomerSideDrawer = this.openCustomerSideDrawer.bind(this);
    this.openFreightProviderSideDrawer = this.openFreightProviderSideDrawer.bind(this);
    this.getCompanyEmployees = this.getCompanyEmployees.bind(this);
    this.getCustomerNgrs = this.getCustomerNgrs.bind(this);
    this.getCustomerContacts = this.getCustomerContacts.bind(this);
    this.openGeneralConditionSideDrawer = this.openGeneralConditionSideDrawer.bind(this);
    this.closeSpecialConditionSideDrawer = this.closeSpecialConditionSideDrawer.bind(this);
    this.closeGeneralConditionSideDrawer = this.closeGeneralConditionSideDrawer.bind(this);
    this.openSpecialConditionSideDrawer = this.openSpecialConditionSideDrawer.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.addFreightProviderContactButton = this.addFreightProviderContactButton.bind(this);
    this.addCustomerContactButton = this.addCustomerContactButton.bind(this);
    this.addGeneralConditionButton = this.addGeneralConditionButton.bind(this);
    this.addSpecialConditionButton = this.addSpecialConditionButton.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.setDefaultSpecialCondition = this.setDefaultSpecialCondition.bind(this);
    this.customerSideDrawer = this.customerSideDrawer.bind(this);
    this.freightProviderSideDrawer = this.freightProviderSideDrawer.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.getFreightOrder = this.getFreightOrder.bind(this);
    this.getCommodityContract = this.getCommodityContract.bind(this);
    this.isDependentCheck = this.isDependentCheck.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.handleReferenceNumberBlur = this.handleReferenceNumberBlur.bind(this);
    this.updateParty = this.updateParty.bind(this);
    this.openCompanySideDrawer = this.openCompanySideDrawer.bind(this);
    this.closeCompanySideDraw = this.closeCompanySideDraw.bind(this);
    this.addNewCompany = this.addNewCompany.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleTemplateNameChange = this.handleTemplateNameChange.bind(this);
    this.handleTemplateNameBlur = this.handleTemplateNameBlur.bind(this);
    this.handleTemplateDialogClose = this.handleTemplateDialogClose.bind(this);
    this.getTemplatesService = this.getTemplatesService.bind(this);
    this.handleQuickOrderChange = this.handleQuickOrderChange.bind(this);
    this.saveTemplate = this.saveTemplate.bind(this);
    this.getAllocatedContractPartySites = this.getAllocatedContractPartySites.bind(this);
    this.finalSubmit = this.finalSubmit.bind(this);
    this.isTonnageOrOrderDatesAmended = this.isTonnageOrOrderDatesAmended.bind(this);
    this.isAcceptanceRequiredInEditForm = this.isAcceptanceRequiredInEditForm.bind(this);

    this.fieldsOrder = [
      "typeId", "identifier", "commodityContractId",  "externalReferenceNumber", "filterParty", 'sellerDetails.companyId', 'sellerDetails.contactId', 'buyerDetails.companyId',
      'buyerDetails.contactId', "customer.companyId",
      "customer.contactId", "customer.ngrId", "providerId", "assignToId", "commodityId",
      "varietyId", "plannedGradeId", "season", "quantity", "plannedTonnage", "freightPickup.date",
      "freightPickup.consignor.handlerId",
      "freightPickup.orderNumber", "freightDelivery.date", "freightDelivery.consignee.handlerId",
      "freightDelivery.orderNumber", "invoicing",
      "paymentTermId", "rateFreightIn", "rateFreightOut", "rateOvers", "generalConditionsSelector",
      "generalConditionViewer", "generalConditions", "specialConditionsSelector",
      "specialConditionViewer", "specialConditions", "note"
    ];

    this.formRef = React.createRef();

    this.fieldRef = {};
    this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    this.disableForm = !this.isEditForm;
  }

  setDistance(response) {
    if (get(response, 'status') == 'OK') {
      const distanceInKms = parseFloat(response['distance'] ? response['distance']['value'] / 1000 : 0).toFixed(2);
      this.setDistanceAndTimeState(distanceInKms, response['duration']['text']);
    } else {
      this.resetDistanceAndTimeState();
    }
  }

  setDistanceAndTimeState(distance, duration) {
    setTimeout(() => {
      this.setState({
        totalDistance: distance,
        totalDistanceLabel: distance + ' ' + getCountryConfig()?.distanceUnit,
        estimatedTime: duration ? duration : '0',
      });
    }, 300);
  }

  resetDistanceAndTimeState() {
    setTimeout(() => {
      this.setState({
        totalDistance: '',
        totalDistanceLabel: '',
        estimatedTime: '',
      });
    }, 300);
  }

  async fetchDistance() {
    let consignorAddress = get(this.state.selectedConsignorSite, 'address') || this.state.selectedConsignor;
    let consigneeAddress = get(this.state.selectedConsigneeSite, 'address') || this.state.selectedConsignee;
    if (consignorAddress)
      consignorAddress = {value: {latitude: get(consignorAddress, 'latitude'), longitude: get(consignorAddress, 'longitude')}};
    if (consigneeAddress)
      consigneeAddress = {value: {latitude: get(consigneeAddress, 'latitude'), longitude: get(consigneeAddress, 'longitude')}};
    if (consignorAddress && consigneeAddress) {
      const consignorAddressVal = consignorAddress.value;
      const consigneeAddressVal = consigneeAddress.value;
      if (consignorAddressVal && consigneeAddressVal) {
        const consignorLat = consignorAddressVal.latitude;
        const consigneeLat = consigneeAddressVal.latitude;
        const consignorLng = consignorAddressVal.longitude;
        const consigneeLng = consigneeAddressVal.longitude;
        if (consignorLat && consigneeLat && consignorLng && consigneeLng) {
          let data = {
            'origin_latitude': consignorLat,
            'origin_longitude': consignorLng,
            'destination_latitude': consigneeLat,
            'destination_longitude': consigneeLng
          }
          let response = await APIService.farms().appendToUrl('geo-distance/').post(data);
          this.setDistance(response);
        }
      }
    }
  }

  componentDidMount() {
    this.getBase64Logo();
    this.props.dispatch(setHeaderText("Orders"));
    let breadcrumbs = [{ text: 'Orders', route: '/orders/freights' }];

    const query = new URLSearchParams(this.props.location.search);
    const querySaleContractId = query.get('saleContractId');
    if(querySaleContractId)
      APIService.contracts(querySaleContractId).appendToUrl(`orders/minimal/?type_id=${CALL_ON_GRAIN_TYPE_ID}`).get(this.props.userToken).then(response => {
        const newState = {...this.state};
        newState.deliveryOrderNumbers = response;
        newState.fields.freightDelivery.orderNumber.validators = response.length > 0 ? [required()] : []
        this.setState(newState)
    });

    if (this.isEditForm) {
      breadcrumbs = breadcrumbs.concat([
        { text: get(this.editOrder, 'displayName'), route: `/freights/orders/${this.editOrderId}/order` },
        { text: 'Edit' },
      ]);
    } else {
      breadcrumbs = breadcrumbs.concat([{ text: 'New' }]);
    }
    this.props.dispatch(setBreadcrumbs(breadcrumbs));

    if (!this.isEditForm) {
      if (isEmpty(this.props.commodities)) {
        this.props.dispatch(getCommoditiesWithVarietiesAndGrades());
      }
      setTimeout(() => {
        this.setState({ setFieldErrors: true });
      }, 2000);
      const newState = { ...this.state };
      if(this.isCallOnGrainOrder)
        this.handleCallOnGrainOrder(newState);
      newState.fields.identifier.value = this.identifierGenerated;
      this.setState(newState);
    }

    this.fetchAndSetAllocationContract()

    if(this.isDuplicatingOrder){
      this.disableForm = false;
      const queryParams = new URLSearchParams(window.location.hash.split('?')[1]);
      this.props.dispatch(isLoading())
      this.setOrder(queryParams.get('copyFrom'));
    }
  }

  async fetchAndSetAllocationContract() {
    const query = new URLSearchParams(this.props.location.search);
    let commodityContract, salesContract, purchaseContract;
    const stockAllocationId = query.get('stockAllocationId');
    if (stockAllocationId) {
      const stockAllocation = await APIService.contracts().appendToUrl(`stock-allocations/${stockAllocationId}/`).get();
      this.setState({contractStockAllocation: stockAllocation});
    }
    if(this.editOrder || this.isDuplicatingOrder) {
      salesContract = get(this.editOrder, 'saleCommodityContractId');
      purchaseContract = get(this.editOrder, 'purchaseCommodityContractId');
      commodityContract = get(this.editOrder, 'commodityContractId');
    }
    const saleContractId = query.get('saleContractId') || salesContract;
    const purchaseContractId = query.get('purchaseContractId') || purchaseContract;
    const commodityContractId = query.get('commodityContractId') || commodityContract;
    if(saleContractId) {
      this.setState({purchaseContractId: null, saleContractId: saleContractId, isLoadingAllocatedContract: true}, async () => {

        const allocatedContract = await APIService.contracts(saleContractId).get(null, null, {brief: true, allocation: true, purchase_contract_id: commodityContractId})
          this.setState({allocatedContract: allocatedContract, isLoadingAllocatedContract: false})
    })
    } else if(purchaseContractId) {
      this.setState({saleContractId: null, purchaseContractId: purchaseContractId, isLoadingAllocatedContract: true}, async () => {

        const allocatedContract = await APIService.contracts(purchaseContractId).get(null, null, {brief: true, allocation: true, sale_contract_id: commodityContractId})
          this.setState({allocatedContract: allocatedContract, isLoadingAllocatedContract: false})
    })
    } else
      this.setState({saleContractId: null, purchaseContractId: null, isLoadingAllocatedContract: false, allocatedContract: null})
  }



  async handleCallOnGrainOrder(newState) {
    newState.fields.typeId.value = 3;
    newState.typeItems.push({
      displayName: "Call On Grain",
      entity: "freightcontracttype", id: 3, isActive: true,
      name: "call_on_grain"
    });
    newState.fields.season.validators = [required()];
    newState.fields.commodityId.validators = [required()];
    newState.fields.customer.companyId.validators = [required(), selected()];
    newState.fields.customer.contactId.validators = [required(), selected()];
    this.disableForm = false;
    this.setPlannedGradeValidators(newState);
    this.setConsignorConsigneeValidators(newState);
    this.setState(newState, async () => {
      if(!includes(this.props.location.search, 'commodityContractId='))
        await this.getContractsList();
    })
  }

  getBase64Logo() {
    const logoUrl = get(this.props, 'currentUser.myCompanyLogoUrl') || get(this.editOrder, 'createdBy.myCompanyLogoUrl');
    if (logoUrl && !this.state.base64Logo) {
      APIService.base64()
                .post({ url: logoUrl })
                .then(data => {
                  this.setState({ base64Logo: data.base64 });
                });
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.isEditForm &&
      !this.state.isFetchingSelectedOrder
    ) {
      this.setState({ isFetchingSelectedOrder: true, isPopulated: false }, () =>
        this.setOrder(this.editOrderId)
      );
    } else {
      if (this.state.queryParamExists === false) {
        const query = new URLSearchParams(this.props.location.search);
        if (includes(this.props.location.search, 'orderId=') && isEmpty(this.selectedOrder)) {
          const baseEntityId = parseInt(query.get('orderId'));
          if (isEmpty(this.state.selectedBaseEntityId) || this.state.selectedBaseEntityId != baseEntityId) {
            this.setSelectedBaseEntity();
          }
        } else if (includes(this.props.location.search, 'commodityContractId=')) {
          const baseEntityId = parseInt(query.get('commodityContractId'));
          if (!this.state.didFetchedContracts && (isEmpty(this.state.selectedBaseEntityId) || this.state.selectedBaseEntityId != baseEntityId)) {
            this.setState({ didFetchedContracts: true }, () => this.setSelectedBaseEntity());
          }
        }
      }
      if (this.state.queryParamExists === true) {
        this.disableForm = false;
      }
      if (!isEqual(this.props.serverErrors, prevProps.serverErrors)) {
        const newState = { ...this.state };
        forEach(this.props.serverErrors, (value, key) => {
          if(key && isString(key)) {
            if(key.toLowerCase() === 'identifier')
              value[0] = value[0].replace('Freight order', 'Order')
            if(key.toLowerCase() === 'freightorder') {
              alertifyjs.error(first(value));
              return
            }
            if(has(newState.fields, key))
              newState.fields[key].errors = value;
          }
        });
        this.setState(newState);
      }

      if (
        !this.state.filterParty &&
        isEmpty(this.state.filteredContracts) &&
        !isEmpty(this.state.contracts) &&
        this.state.filteredContracts !== this.state.contracts
      ) {
        this.setState({ filteredContracts: this.state.contracts });
      }

      if (!isEmpty(this.props.specialConditionsAll) && !this.selectedContract && !this.state.isDefaultSpecialConditionApplied && !this.isEditForm) {
        const newState = { ...this.state };
        const specialConditions = map(filter(this.props.specialConditionsAll, { default: true }), 'details').join('\n');
        if (!isEmpty(specialConditions) && !includes(newState.fields.specialConditions.value, specialConditions))
          newState.fields.specialConditions.value = specialConditions + '\n' + newState.fields.specialConditions.value;
        newState.isDefaultSpecialConditionApplied = true;
        this.setState(newState);
      }

      if (!isEmpty(this.props.generalConditionsAll) && !this.selectedContract && !this.state.isDefaultGeneralConditionApplied && !this.isEditForm) {
        const newState = { ...this.state };
        const generalConditions = map(filter(this.props.generalConditionsAll, { default: true }), 'details').join('\n');
        if (!isEmpty(generalConditions) && !includes(newState.fields.generalConditions.value, generalConditions))
          newState.fields.generalConditions.value = generalConditions + '\n' + newState.fields.generalConditions.value;
        newState.isDefaultGeneralConditionApplied = true;
        this.setState(newState);
      }
    }

    if(this.isSelectedOrderCallOnGrain() && this.state.fields.typeId.value !== 2 && !this.isCallOnGrainOrder) {
      const newState = {...this.state};
      newState.fields.typeId.value = 2;
      this.setState(newState);
    }
    if (this.state.deliveryOrderNumbers.length == 1 && !this.state.fields.freightDelivery.date.value)
      this.handleDeliveryOrderNumberChange(null, this.state.deliveryOrderNumbers[0])
    if (this.state.deliveryOrder && !this.state.fields.freightDelivery.orderId.value) {
      const newState = {...this.state};
      newState.fields.freightDelivery.orderId.value = this.state.deliveryOrder.id;
      this.setState(newState)
    }
  };

  componentWillUnmount() {
    this.props.dispatch(receiveGeneralConditions([]));
    this.props.dispatch(receiveSpecialConditions([]));
  }

  setOrder = async (orderId) => {
    await APIService.freights()
                    .orders(orderId)
      .get(this.props.userToken, {'original-unit': true})
                    .then(response => {
                      this.editOrder = response;
                      this.props.dispatch(isLoading(null, 10000000));
                      this.fetchAndSetAllocationContract()

                      if(!this.isDuplicatingOrder && !this.applyingTemplate){
                        let breadcrumbs = [
                          { text: 'Orders', route: '/orders/freights' },
                          { text: get(this.editOrder, 'displayName'), route: `/freights/orders/${this.editOrderId}/order` },
                          { text: 'Edit' },
                        ];
                        this.props.dispatch(setBreadcrumbs(breadcrumbs));
                      }

                      this.populateRequiredFields();
                      const newState = { ...this.state };
                      newState.isDependent = !get(response, 'externalReferenceNumber', 0);
                      newState.orderStatus = get(response, 'status');
                      this.isCallOnGrainOrder = get(response, 'typeId') == 3;
                      this.setState(newState);
                      Promise.all([
                        this.props.dispatch(getGeneralConditions('freight')),
                        this.props.dispatch(getSpecialConditions('freight')),
                      ]);
                      setTimeout(() => this.props.dispatch(forceStopLoader()), 1000);
                    });
  };

  isValueChanged = field => {
    return get(this.state.fields, `${field}.value`) != get(this.editOrder, field);
  };

  populateRequiredFields = async () => {
    const customerCompany = get(this.editOrder, 'customer.company');
    const getCustomerContacts = this.getContacts(customerCompany, get(this.editOrder, 'customer.representedById'));
    if(customerCompany)
      this.getCustomerNgrs(customerCompany.id);

    if((this.isDuplicatingOrder || this.applyingTemplate) && [FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER, FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN].includes(get(this.editOrder, 'type.id')))
      await this.getContractsList()

    await Promise.all([getCustomerContacts]).then(responses => {
      this.setState(
        {
          customerContacts: responses[0],
        },
        () => {
          const newState = { ...this.state };
          const outloadThroughputFee = get(this.editOrder, 'outloadThroughputFee');
          const inloadThroughputFee = get(this.editOrder, 'inloadThroughputFee');
          if(outloadThroughputFee){
            set(newState, 'throughputOutloadOrder', true);
          }
          if(inloadThroughputFee){
            set(newState, 'throughputInloadOrder', true);
          }
          set(newState, 'fields.note.description', get(this.editOrder, 'note.description'));

          if(this.isDuplicatingOrder)
            set(newState, 'typeItems', this.props.types)
          else
            forEach(['typeItems'], key => set(newState, key, [get(this.editOrder, replace(key, 'Items', ''))]));

          const provider = get(this.editOrder, 'provider');
          const baseEntityTemp = get(this.editOrder, 'parentOrder') || get(this.editOrder, 'commodityContract');
          let canFillProviderDetails = true
          if(this.isDuplicatingOrder){
            let isCurrentUserCustomer = get(this.editOrder, 'customer.companyId') === this.props.currentUser.companyId
            let isUserRepresentingCustomer =   (
              (
                get(baseEntityTemp, 'seller.company.id') === get(this.editOrder, 'customer.companyId') &&
                get(baseEntityTemp, 'seller.representedById') === this.props.currentUser.companyId
              ) ||
              (
                get(baseEntityTemp, 'buyer.company.id') === get(this.editOrder, 'customer.companyId') &&
                get(baseEntityTemp, 'buyer.representedById') === this.props.currentUser.companyId
              )
            )
            if (!this.isDuplicatingOrder && !(isCurrentUserCustomer || isUserRepresentingCustomer))
              canFillProviderDetails = false
          }

          if (provider && canFillProviderDetails) {
            set(newState, 'providerItems', [provider]);
            set(newState.fields, 'providerId.value', provider.id);
          } else {
            set(newState.fields, 'providerId.errors', []);
            set(newState.fields, 'providerId.validators', []);
            set(newState.fields, 'assignToId.errors', []);
            set(newState.fields, 'assignToId.validators', []);
            set(newState.fields, 'invoicing.errors', []);
            set(newState.fields, 'invoicing.validators', []);
            set(newState.fields, 'paymentTermId.errors', []);
            set(newState.fields, 'paymentTermId.validators', []);
            set(newState.fields, 'rateFreightIn.errors', []);
            set(newState.fields, 'rateFreightIn.validators', []);
            set(newState.fields, 'rateFreightOut.errors', []);
            set(newState.fields, 'rateFreightOut.validators', []);
          }

          const selectedCustomer = find(this.props.allDirectoryCompanies, { id: get(this.editOrder, 'customer.handlerId') });
          if (selectedCustomer) {
            set(newState, 'customerItems', [selectedCustomer]);
          }

          const isOrderStatusVoid = get(this.editOrder, 'orderStatusDisplayName') === 'Void';
          if(this.isDuplicatingOrder && this.editOrder.identifier && !isOrderStatusVoid)
            this.editOrder.identifier = generateUpdatedIdentifier(this.editOrder.identifier[0]);

          if(this.isDuplicatingOrder && this.editOrder.typeId === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER)
            newState.fields.commodityContractId.validators = [required(), selected()];

          let fields = [
            'typeId', 'commodityContractId', 'customer.companyId', 'rateOvers',
            'commodityId', 'varietyId', 'plannedGradeId', 'season', 'plannedTonnage', 'invoicing',
            'paymentTermId', 'rateFreightIn', 'rateFreightOut',
            'freightPickup.orderNumber', 'freightDelivery.orderNumber', 'generalConditions', 'specialConditions', 'additionalCost',
            'freightPickup.instructions', 'freightDelivery.instructions', 'transportMode'
          ]

          const isBuyerToSellerOrderTemplate = this.applyingTemplate && get(this.editOrder, 'typeId') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER
          if(!isBuyerToSellerOrderTemplate)
            fields.push('identifier')

          forEach(fields, key => set(newState.fields, `${key}.value`, get(this.editOrder, key)));
          newState.pickupOrder = this.editOrder.pickupOrder;
          newState.deliveryOrder = this.editOrder.deliveryOrder;
          newState.fields.freightPickup.orderId.value = get(newState.pickupOrder, 'id');
          newState.fields.freightDelivery.orderId.value = get(newState.deliveryOrder, 'id');
          newState.deliveryCheckpointOrderExists = Boolean(get(newState.deliveryOrder, 'id'))
          newState.pickupCheckpointOrderExists = Boolean(get(newState.pickupOrder, 'id'))

          let pickupLocalDateTime = getDateTimeFromUTC(this.editOrder?.freightPickup?.dateTime);
          let deliveryLocalDateTime = getDateTimeFromUTC(this.editOrder?.freightDelivery?.dateTime);
          set(newState.fields, 'freightPickup.date.value', pickupLocalDateTime.date);
          set(newState.fields, 'freightDelivery.date.value', deliveryLocalDateTime.date);
          set(newState.fields, 'customer.company', get(this.editOrder, 'customer.company'));
          set(newState, 'customerContact', get(this.editOrder, 'customer.contact'));
          set(newState, 'customerNgr', get(this.editOrder, 'customer.ngr'));
          if(!this.isDuplicatingOrder)
            set(newState, 'hasAnyChildren', get(this.editOrder, 'hasAnyChildren', false));

          if (get(this.editOrder, 'currency') || get(this.editOrder, 'commodityContract.currency'))
            set(newState, 'currency', get(this.editOrder, 'currency') || get(this.editOrder, 'commodityContract.currency'));
          const baseEntity = get(this.editOrder, 'parentOrder') || get(this.editOrder, 'commodityContract');
          if(get(this.editOrder, 'parentOrder'))
            newState.fields.parentOrderId.value = get(this.editOrder, 'parentOrder.id');

          if (baseEntity){
            newState.selectedBaseEntity = baseEntity;
            newState.selectedBaseEntityRaw = baseEntity;
            newState.selectedBaseEntityId = baseEntity.id;
            newState.selectedBaseEntityType = baseEntity.entity === 'freightorder' ? 'order' : 'commodityContract';
            newState.selectedBaseEntity.isSeller = get(this.editOrder, 'isSeller');
            newState.selectedBaseEntity.isBuyer = get(this.editOrder, 'isBuyer');
            newState.selectedBaseEntity.commodityContractConsignorsWithSites = get(this.editOrder, 'commodityContractConsignorsWithSites', []);
            newState.selectedBaseEntity.commodityContractConsigneesWithSites = get(this.editOrder, 'commodityContractConsigneesWithSites', []);
          }

          if (get(this.editOrder, 'commodityContractId')) {
            const { commodityContract } = this.editOrder;
            newState.buyer = commodityContract.buyer;
            newState.seller = commodityContract.seller;
          }

          this.setConsignorConsigneeValidators(newState);

          const orderConsignor = get(this.editOrder, 'freightPickup.consignor.handler');
          const orderConsignee = get(this.editOrder, 'freightDelivery.consignee.handler');

          if (!isEmpty(orderConsignor)) {
            newState.selectedConsignor = {id: orderConsignor.id, name: orderConsignor.displayName, address: get(orderConsignor, 'address.address'), stocksManagement: get(orderConsignor, 'stocksManagement')};
            newState.selectedConsignorId = orderConsignor.id
            newState.fields.freightPickup.consignor.handlerId.value = get(orderConsignor, 'id');
            let consignor = cloneDeep(orderConsignor)
            consignor.address = get(orderConsignor, 'address.address')
            consignor.name = get(orderConsignor, 'displayName')
            newState.consignors = uniqBy([...newState.consignors, consignor], 'id');
          }

          if (!isEmpty(orderConsignee)) {
            newState.selectedConsignee = {id: orderConsignee.id, name: orderConsignee.displayName, address: get(orderConsignee, 'address.address')};
            newState.selectedConsigneeId = orderConsignee.id
            newState.fields.freightDelivery.consignee.handlerId.value = get(orderConsignee, 'id');
            let consignee = cloneDeep(orderConsignee)
            consignee.address = get(orderConsignee, 'address.address')
            consignee.name = get(orderConsignee, 'displayName')
            newState.consignees = uniqBy([...newState.consignees, consignee], 'id');
          }
          if (get(this.editOrder, 'commodityContractConsignorsWithSites', [])) {
            let consignors = map(get(this.editOrder, 'commodityContractConsignorsWithSites'), consignor => consignor?.handler)
            consignors = map(consignors, ({ address, displayName, ...rest }) => ({...rest, name: displayName, address: address?.address}));
            newState.consignors = uniqBy([...newState.consignors, ...consignors], 'id');
          }
          if (get(this.editOrder, 'commodityContractConsigneesWithSites', [])) {
            let consignees = map(get(this.editOrder, 'commodityContractConsigneesWithSites'), consignee => consignee?.handler)
            consignees = map(consignees, ({ address, displayName, ...rest }) => ({...rest, name: displayName, address: address?.address}));
            newState.consignees = uniqBy([...newState.consignees, ...consignees], 'id');
          }

          this.setState(newState, () => {
            this.setHandlerSites();
            if(!isEmpty(this.state.selectedConsignor) && isEmpty(this.state.pickupSettings))
              this.fetchPickupSettings(this.state.selectedConsignor.id);
            if(!isEmpty(this.state.selectedConsignee) && isEmpty(this.state.deliverySettings))
              this.fetchDeliverySettings(this.state.selectedConsignee.id);
            if(this.editOrder.isBlended) {
              this.setBlendedGradeAndChemicalApplicationsForEditOrder();
              this.fetchApplicationRates(get(this.editOrder, 'freightPickup.consignor.handler.companyId'))
            }

            if(this.isCallOnGrainOrder)
              this.isDuplicatingOrder = false
          });
        },
      );
    });
  };

  fetchApplicationRates(companyId) {
    APIService.companies(companyId)
      .appendToUrl('application-rates/')
      .get()
      .then(resp => this.setState({chemicalApplicationRates: resp}))
  }

  setBlendedGradeAndChemicalApplicationsForEditOrder() {
    const newState = {...this.state};
    const commodityContract = get(this.editOrder, 'commodityContract');
    newState.selectedBaseEntity = commodityContract;
    newState.selectedBaseEntity.spread = get(commodityContract, 'spread.details');
    newState.isParentContractBlendContract = true;
    const spreadDetails = get(this.editOrder, 'spread.details') || [];
    const contractSpreadDetails = get(commodityContract, 'spread') || []
    let blendGradeDetails = [];
    let spreadGradeIds = contractSpreadDetails.map(detail => detail.gradeId);
    let lastId = 1;
    map(spreadDetails, (spreadDetail, index) => {
      spreadGradeIds = spreadGradeIds.filter(element => element !== get(spreadDetail, 'gradeId'));
      lastId = index + 1;
      blendGradeDetails.push({
        id: index + 1,
        gradeId: get(spreadDetail, 'gradeId'),
        gradeName: get(spreadDetail, 'gradeName'),
        tonnage: get(spreadDetail, 'quantity'),
        percentage: get(spreadDetail, 'percentage'),
        errors: []
      })
    });
    forEach(spreadGradeIds, spreadGradeId => {
      let grade = find(this.props.grades, {id: spreadGradeId});
      blendGradeDetails.push({
        id: lastId += 1,
        gradeId: spreadGradeId,
        gradeName: get(grade, 'name'),
        tonnage: 0,
        percentage: 0,
        errors: []
      })
    })
    newState.blendGradeDetails = blendGradeDetails;
    const chemicalApplications = get(this.editOrder, 'chemicalApplications') || [];
    const contractChemicalApplications = get(commodityContract, 'chemicalApplications') || [];
    let chemicalApplicationDetails = [];
    map(chemicalApplications, (chemicalApplication, index) => {
      let commodity = find(this.props.commodities, {id: get(chemicalApplication, 'commodityId')});
      let grade = find(this.props.grades, {id: get(chemicalApplication, 'gradeId')});
      chemicalApplicationDetails.push({
        id: index + 1,
        commodityId: get(chemicalApplication, 'commodityId'),
        commodityIdOptions: contractChemicalApplications.map(chemicalApplicationObj => chemicalApplicationObj.commodityId),
        commodity: commodity,
        gradeId: get(chemicalApplication, 'gradeId'),
        gradeIds: contractSpreadDetails.map(detail => detail.gradeId),
        gradeName: get(grade, 'name'),
        applicationRate: get(chemicalApplication, 'applicationFee'),
        errors: []
      })
    });
    newState.chemicalApplications = chemicalApplicationDetails;
    this.setState(newState);
  }

  populateDependentFields = (consignor, consignee) => {
    const newState = { ...this.state };

    let dependentFields = ['customer.contactId', 'customer.ngrId'];
    if (get(newState.fields, 'providerId.value')) {
      dependentFields.push('assignToId');
    }
    forEach(dependentFields, key => set(newState.fields, `${key}.value`, get(this.editOrder, key)));
    if (!isEmpty(consignor)) {
      const consignor = get(this.editOrder, 'freightPickup.consignor');
      const site = get(consignor, 'sites[0]');
      const isCustomerPickupSite = get(this.editOrder, 'customer.company.id') === get(consignor, 'handler.companyId');
      set(newState.fields, 'freightPickup.consignor.handlerId.value', get(consignor, 'handlerId'));
      set(newState.fields, 'freightPickup.consignor.site.address', get(site, 'location.address'));
      set(newState.fields, 'freightPickup.consignor.site.locationType.value', get(site, 'locationContentType'));
      set(newState.fields, 'freightPickup.consignor.site.locationId.value', get(site, 'locationId'));
      newState.consignors = uniqBy([...newState.consignors, consignor?.handler], 'id');
      if (((get(consignor, 'handler.companyId') === get(this.props, 'currentUser.companyId'))) && !isCustomerPickupSite) {
        set(newState, 'throughputOutloadCheckbox', true);
      }
    }

    if (!isEmpty(consignee)) {
      const consignee = get(this.editOrder, 'freightDelivery.consignee');
      const site = get(consignee, 'sites[0]');
      const isCustomerDeliverySite = get(this.editOrder, 'customer.company.id') === get(consignee, 'handler.companyId');
      set(newState.fields, 'freightDelivery.consignee.handlerId.value', get(consignee, 'handlerId'));
      set(newState.fields, 'freightDelivery.consignee.site.address', get(site, 'location.address'));
      set(newState.fields, 'freightDelivery.consignee.site.locationType.value', get(site, 'locationContentType'));
      set(newState.fields, 'freightDelivery.consignee.site.locationId.value', get(site, 'locationId'));
      newState.consignees = uniqBy([...newState.consignees, consignee?.handler], 'id');
      if (((get(consignee, 'handler.companyId') === get(this.props, 'currentUser.companyId'))) && !isCustomerDeliverySite) {
        set(newState, 'throughputInloadCheckbox', true);
      }
      newState.selectedConsigneeSite = find(this.state.consigneeSites, {
        id: get(site, 'locationId'),
        entity: get(site, 'locationContentType'),
      });
    }
    newState.isPopulated = true;
    this.setState(newState, () => 
    {
      this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, this.isCallOnGrainOrder ? get(this.state, 'sellerDetails.ngrId') : this.state.fields.customer.ngrId.value);
      this.props.dispatch(forceStopLoader())
    });
  };

  setHandlerSites = async () => {
    const selectedConsignor = this.state.selectedConsignor || get(this.editOrder, 'freightPickup.consignor.handler');
    if (!isEmpty(selectedConsignor)) {
      await Promise.all([this.getSites(selectedConsignor.id, 'farm', 'consignor')]);
    }

    const selectedConsignee = this.state.selectedConsignee || get(this.editOrder, 'freightDelivery.consignee.handler');
    if (!isEmpty(selectedConsignee)) {
      await Promise.all([this.getSites(selectedConsignee.id, 'farm', 'consignee')]);
    }

    this.populateDependentFields(selectedConsignor, selectedConsignee);
  };

  isNoteChanged = () => {
    return (get(this.state.fields, `note.description`) != get(this.editOrder, 'note.description') ||
            !isEqual(get(this.state.fields, `note.attachments`), get(this.editOrder, 'note.attachments')));
  };

  isDateTimeChanges = field =>{
    let measuredDate = moment(get(this.editOrder, `${field}.dateTime`)).format("YYYY-MM-DD");
    return get(this.state.fields, `${field}.date.value`) != measuredDate;
  };

  getEditData = () => {
    let data = {};
    forEach([
      'assignToId', 'plannedTonnage', 'invoicing', 'paymentTermId', 'rateFreightOut', 'rateOvers',
      'freightPickup.orderNumber', 'freightDelivery.orderNumber',
      'generalConditions', 'specialConditions', 'customer.contactId', 'customer.ngrId', 'quantity',
      'varietyId', 'plannedGradeId', 'season',  'seller.contacts', 'providerId'
    ], field => {
      if (this.isValueChanged(field)){
        set(data, field, get(this.state.fields, `${field}.value`));
      }
    });

    forEach([
      'sellerDetails.contactId', 'buyerDetails.contactId', 'sellerDetails.ngrId', 'buyerDetails.ngrId'
    ], field => {
      if (get(this.state, `${field}`) != get(this.editOrder, field.replace('Details',''))){
        if(get(this.state, `${field}`))
          set(data, field.replace('Details',''), get(this.state, `${field}`));
      }
    });

    forEach(['freightPickup', 'freightDelivery'], field =>{
      if(this.isDateTimeChanges(field)){
        set(data, `${field}.dateTime`, `${get(this.state, `fields.${field}.date.value`)} 00:00:00Z`);
      }
    });

    forEach(data, (value, key) => {
      if (includes(['plannedTonnage', 'rateFreightOut', 'rateOvers'], key) && isEmpty(value)) {
        set(data, key, null);
      }
    });

    forEach(data, (value, key) => {
      if (includes(['freightPickup', 'freightDelivery'], key) && !isEmpty(value)) {
        forEach(value, (v, k) => {
          if (includes(['date'], k) && !isEmpty(v)) {
            set(data, `${key}.dateTime`, `${get(data, `${key}.${k}`)} 00:00:00Z`);
          }
        });
      }
    });

    if (this.isHandlerChanged('freightPickup', 'consignor')) {
      set(data, 'freightPickup.consignor.handlerId', get(this.state.selectedConsignor, 'id'));
      set(data, 'freightPickup.consignor.sites[0].locationId', get(this.state.selectedConsignorSite, 'id'));
      set(data, 'freightPickup.consignor.sites[0].locationType', get(this.state.selectedConsignorSite, 'entity'));
      set(data, 'estimatedDistance', this.state.totalDistance === '' ? null : this.state.totalDistance);
      set(data, 'estimatedTime', this.state.estimatedTime === '' ? null : this.state.estimatedTime);
    }

    if (this.isHandlerChanged('freightDelivery', 'consignee')) {
      set(data, 'freightDelivery.consignee.handlerId', get(this.state.selectedConsignee, 'id'));
      set(data, 'freightDelivery.consignee.sites[0].locationId', get(this.state.selectedConsigneeSite, 'id'));
      set(data, 'freightDelivery.consignee.sites[0].locationType', get(this.state.selectedConsigneeSite, 'entity'));
      set(data, 'estimatedDistance', this.state.totalDistance === '' ? null : this.state.totalDistance);
      set(data, 'estimatedTime', this.state.estimatedTime === '' ? null : this.state.estimatedTime);
    }
    if(this.isDeliveryOrderChanged()) {
      set(data, 'freightDelivery.orderNumber', this.state.fields.freightDelivery.orderNumber.value)
      set(data, 'freightDelivery.orderId', this.state.fields.freightDelivery.orderId.value)
    }
    if(this.isPickupOrderChanged()) {
      set(data, 'freightPickup.orderNumber', this.state.fields.freightPickup.orderNumber.value)
      set(data, 'freightPickup.orderId', this.state.fields.freightPickup.orderId.value)
    }
    if(this.isPickupInstructionsChanged())
      set(data, 'freightPickup.instructions', this.state.fields.freightPickup.instructions.value)

    if(this.isDeliveryInstructionsChanged())
      set(data, 'freightDelivery.instructions', this.state.fields.freightDelivery.instructions.value)

    if(this.isIndependentSiteContractOrder())
      set(data, 'additionalCost', this.state.fields.additionalCost.value)
    if (this.isNoteChanged()) {
      let note = get(this.state.fields, 'note');
      note = omit(note, ['errors']);
      data.note = note;
    }
    if(get(this.editOrder, 'currency') != this.state.currency)
      set(data, 'currency', this.state.currency);
    const inloadThroughputFee = {
      'isActive': this.state.throughputInloadOrder,
      'type': 'inload'
    }
    const outloadThroughputFee = {
      'isActive': this.state.throughputOutloadOrder,
      'type': 'outload'
    }
    const throughputFees = [inloadThroughputFee, outloadThroughputFee];
    
    set(data, 'throughputFees', throughputFees);
    data.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
      return {
        commodityId: chemicalApplication.commodityId,
        gradeId: chemicalApplication.gradeId,
        applicationFee: chemicalApplication.applicationRate
      }
    });
    let blendGradeDetails = this.state.blendGradeDetails.filter(blendGradeDetail => blendGradeDetail.percentage > 0).map((blendGradeDetail, index) => {
      if (blendGradeDetail.percentage > 0) {
        return {
          order: index,
          gist: `${blendGradeDetail.gradeName}: ${blendGradeDetail.percentage}% - ${blendGradeDetail.tonnage}${this.priceUnit()}`,
          gradeId: blendGradeDetail.gradeId,
          gradeName: blendGradeDetail.gradeName,
          price: null,
          quantity: parseFloat(blendGradeDetail.tonnage),
          percentage: parseFloat(blendGradeDetail.percentage),
          value: null
        }
      }
    });
    data.spread = {details: blendGradeDetails};
    return data;
  };

  isDeliveryOrderChanged = () => this.state.fields.freightDelivery.orderNumber.value !== this.editOrder.freightDelivery.orderNumber;

  isPickupOrderChanged = () => this.state.fields.freightPickup.orderNumber.value !== this.editOrder.freightPickup.orderNumber;

  isPickupInstructionsChanged = () => this.state.fields.freightPickup.instructions.value != this.editOrder?.freightPickup?.instructions

  isDeliveryInstructionsChanged = () => this.state.fields.freightDelivery.instructions.value != this.editOrder?.freightDelivery?.instructions

  isHandlerChanged = (checkpoint, handler) => {
    return (
      this.isValueChanged(`${checkpoint}.${handler}.handlerId`) ||
      get(this.state.fields, `${checkpoint}.${handler}.site.locationType.value`) !==
        get(this.editOrder, `${checkpoint}.${handler}.sites[0].locationContentType`) ||
      get(this.state.fields, `${checkpoint}.${handler}.site.locationId.value`) !== get(this.editOrder, `${checkpoint}.${handler}.sites[0].locationId`)
    );
  };

  getSubmitData = () => {
    let data = mapValues(this.state.fields, 'value');
    data.identifier = data?.identifier?.toUpperCase();
    data.freightPickup = mapValues(this.state.fields.freightPickup, 'value');
    data.freightDelivery = mapValues(this.state.fields.freightDelivery, 'value');

    set(data, 'freightDelivery.dateTime', `${data.freightDelivery.date} 00:00:00Z`);
    set(data, 'freightPickup.dateTime', `${data.freightPickup.date} 00:00:00Z`);
    if (this.state.fields.freightPickup.consignor.handlerId.value) {
      data.freightPickup.consignor = {
        handlerId: this.state.fields.freightPickup.consignor.handlerId.value,
        sites: [],
      };
      if (this.state.fields.freightPickup.consignor.site.locationId.value && this.state.fields.freightPickup.consignor.site.locationType.value) {
        data.freightPickup.consignor.sites = [
          {
            locationId: this.state.fields.freightPickup.consignor.site.locationId.value,
            locationType: this.state.fields.freightPickup.consignor.site.locationType.value,
          },
        ];
      }
    }
    if(this.state.selectedConsignor && !isEqual(get(this.state.selectedConsignor, 'id'), this.state.fields.freightPickup.consignor.handlerId.value)) {
      data.freightPickup.consignor = {
        handlerId: get(this.state.selectedConsignor, 'id'),
        sites: [],
      };
    }

    if(!this.state.isDependent){
      if(this.selectedOrderId)
        {
          data.sellerId = get(this.state, 'seller.id');
          data.buyerId = get(this.state, 'buyer.id');

        }
      else
        {
          data.seller = get(this.state, 'sellerDetails');
          data.buyer = get(this.state, 'buyerDetails');
        }
      delete data.baseEntityId;
    }

    if (this.state.fields.freightDelivery.consignee.handlerId.value) {
      data.freightDelivery.consignee = {
        handlerId: this.state.fields.freightDelivery.consignee.handlerId.value,
        sites: [],
      };
      if (this.state.fields.freightDelivery.consignee.site.locationId.value && this.state.fields.freightDelivery.consignee.site.locationType.value) {
        data.freightDelivery.consignee.sites = [
          {
            locationId: this.state.fields.freightDelivery.consignee.site.locationId.value,
            locationType: this.state.fields.freightDelivery.consignee.site.locationType.value,
          },
        ];
      }
    }

    if(this.state.selectedConsignee && !isEqual(get(this.state.selectedConsignee, 'id'), this.state.fields.freightDelivery.consignee.handlerId.value)) {
      data.freightDelivery.consignee = {
        handlerId: get(this.state.selectedConsignee, 'id'),
        sites: [],
      };
    }

    data.customer = mapValues(this.state.fields.customer, 'value');
    if(this.state.saleContractId || this.state.purchaseContractId)
      set(data, 'isContractAllocated', true)
    if (data.rateFreightIn === '') {
      data.rateFreightIn = null;
    }

    if (data.rateOvers === '') {
      data.rateOvers = null;
    }

    data.estimatedDistance = this.state.totalDistance === '' ? null : this.state.totalDistance;
    data.estimatedTime = this.state.estimatedTime === '' ? null : this.state.estimatedTime;

    for (var key in data) {
      if (has(data, key)) {
        if (/^\d+$/.test(key)) {
          delete data[key];
        }
      }
    }

    if (!isNumber(get(data, 'freightPickup.consignor.handlerId'))) {
      delete data.freightPickup.consignor;
    } else {
      forEach(get(data, 'freightPickup.consignor.sites'), site => {
        if (!isNumber(site.locationId)) delete data.freightPickup.consignor['sites'];
      });
    }

    if (!isNumber(get(data, 'freightDelivery.consignee.handlerId'))) {
      delete data.freightDelivery.consignee;
    } else {
      forEach(get(data, 'freightDelivery.consignee.sites'), site => {
        if (!isNumber(site.locationId)) delete data.freightDelivery.consignee['sites'];
      });
    }

    if (this.hasSelectedBaseEntity()) {
      data.commodityContractId = get(this.state.selectedBaseEntity, 'commodityContractId');
      if(isEmpty(get(data, 'customer.representedById')))
        set(data, 'customer.representedById', get(this.state.selectedBaseEntity, 'customerRepresentedById'));
    } else if (!this.isFreightProviderCreator()) {
      set(data, 'customer.representedById', this.props.currentUser.companyId);
    }

    if (!this.showConditionsSection()) {
      data = omit(data, ['generalConditions', 'specialConditions']);
    }

    if (this.state.isDependent) {
      let commodityContractId = get(this.state.selectedBaseEntity, 'commodityContractId');
      if (this.selectedOrderId && !commodityContractId) {
        data.sellerId = get(this.state, 'seller.id');
        data.buyerId = get(this.state, 'buyer.id');
      }
      delete data.baseEntityId;
    }

    if (this.isCallOnGrainOrder) {
      if (!this.state.isDependent) {
        data.seller = get(this.state, 'sellerDetails');
        data.buyer = get(this.state, 'buyerDetails');
        delete data.customer;
      }
      if(this.state.isDependent){
        delete data.seller;
        delete data.buyer;
      }
      delete data.estimatedDistance;
      delete data.estimatedTime;
      delete data.status;
      delete data.paymentTermId;
      delete data.isSelf;
      delete data.providerId;
      if(!this.isIndependentSiteContractOrder())
        delete data.additionalCost
    }

    let note = this.state.fields.note;
    note = omit(note,['errors']);
    data.note = note;
    delete data.throughputInload;
    delete data.throughputOutload;
    const inloadThroughputFee = {
      'isActive': this.state.throughputInloadOrder,
      'type': 'inload'
    }
    const outloadThroughputFee = {
      'isActive': this.state.throughputOutloadOrder,
      'type': 'outload'
    }
    const throughputFees = [inloadThroughputFee, outloadThroughputFee];
    data.throughputFees = throughputFees;
    data.currency = this.state.currency;
    data.chemicalApplications = this.state.chemicalApplications.map(chemicalApplication => {
      return {
        commodityId: chemicalApplication.commodityId,
        gradeId: chemicalApplication.gradeId,
        applicationFee: chemicalApplication.applicationRate
      }
    });
    let spreadDetails = this.getSpreadDetails();
    if (!isEmpty(spreadDetails))
      data.spread = {details: spreadDetails};
    return data;
  };

  getSpreadDetails() {
    return this.state.blendGradeDetails.filter(blendGradeDetail => blendGradeDetail.percentage > 0).map((blendGradeDetail, index) => {
      if (blendGradeDetail.percentage > 0) {
        return {
          order: index,
          gist: `${blendGradeDetail.gradeName}: ${blendGradeDetail.percentage}% - ${blendGradeDetail.tonnage}${this.priceUnit()}`,
          gradeId: blendGradeDetail.gradeId,
          gradeName: blendGradeDetail.gradeName,
          price: null,
          quantity: parseFloat(blendGradeDetail.tonnage),
          percentage: parseFloat(blendGradeDetail.percentage),
          value: null
        }
      }
    });
  }

  finalSubmit(data) {
    if (this.state.preview) {
      if (this.isEditForm) {
        const editData = this.getEditData();
        if (isEmpty(editData) || isEqual(keys(editData), 'communication')) {
          alertifyjs.alert(`${this.getOrderEntity()} Order`, 'Please update the order before re-submitting.');
        }
        else if(Object.entries(editData).filter(val =>  val[1] != null).length == 1 && has(editData, "note")){
          this.props.dispatch(isLoading('genericTableWithData'));
          this.props.dispatch(raiseOrderAmendRequest(this.editOrderId, editData));
        }
        else {
          this.openEmailDialog(editData);
        }
      } else {
        this.openEmailDialog(data);
      }
    } else {
      this.handleOpenPreview();
    }
  }

  handleSubmitClick = event => {
    event.preventDefault();
    if (this.state.customerTonnageAvailable){
      let isTonnageExceeding = parseFloat(this.state.customerTonnageAvailable) < parseFloat(this.state.fields.plannedTonnage.value);
      if (this.editOrder)
        isTonnageExceeding = parseFloat(this.state.customerTonnageAvailable) < (parseFloat(this.state.fields.plannedTonnage.value) - parseFloat(get(this.editOrder, 'progressTonnage', 0)))
      if (includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, this.state.fields.typeId.value) && isTonnageExceeding)
        this.setState({openWarningDialog: true});
      else
        this.handleSubmit(event, isTonnageExceeding);
    }
    else
      this.handleSubmit(event);
  }

  handleCloseDialog = event => {
    event.persist();
    this.setState({openWarningDialog: false}, () => this.handleSubmit(event));
  }

  handleSubmit(event, isTonnageExceeding=false) {
    if (this.state.openConsignorFarmForm || this.state.openConsigneeFarmForm || this.state.openConsignorStorageForm || this.state.openConsigneeStorageForm)
      return;
    const isPickupByPassed = this.isPickupOrderNumberMandatory(false) && !this.state.fields.freightPickup.orderNumber.value;
    const isDeliveryByPassed = this.isDeliveryOrderNumberMandatory(false) && !this.state.fields.freightDelivery.orderNumber.value;
    event.preventDefault();
    if (this.isCallOnGrainOrder) {
      const newState = { ...this.state };
      newState.fields.providerId.validators = [];
      newState.fields.providerId.errors = [];
      newState.fields.customer.companyId.validators = [];
      newState.fields.customer.companyId.errors = [];
      newState.fields.customer.contactId.validators = [];
      newState.fields.customer.contactId.errors = [];
      newState.fields.assignToId.validators = [];
      newState.fields.assignToId.errors = [];
      newState.fields.invoicing.validators = [];
      newState.fields.invoicing.errors = [];
      newState.fields.rateFreightIn.validators = [];
      newState.fields.rateFreightIn.errors = [];
      newState.fields.rateOvers.validators = [];
      newState.fields.rateOvers.errors = [];
      newState.fields.rateFreightOut.validators = [];
      newState.fields.rateFreightOut.errors = [];
      newState.fields.paymentTermId.validators = [];
      newState.fields.paymentTermId.errors = [];
      this.setState(newState);
    }
    this.setAllFieldErrors();

    if (this.state.selectedBaseEntity) {
      const newState = { ...this.state };
      newState.fields.freightPickup.date.errors = filter(newState.fields.freightPickup.date.errors, error => !error.startsWith('Warning'));
      newState.fields.freightDelivery.date.errors = filter(newState.fields.freightDelivery.date.errors, error => !error.startsWith('Warning'));
      this.setState(newState);
    }
    this.setState({ partyValidate: true });
    this.focusOnFirstErrorField();
    if (!isEmpty(this.state.blendGradeDetails)) {
      const totalPercentage = this.state.blendGradeDetails.reduce((sum, current) => sum + current.percentage, 0);
      if (totalPercentage < 100) {
        alertifyjs.error("Blended grade percentage should add up to 100.");
        return;
      }
      if (this.state.blendGradeDetails.some(item => item.errors.length > 0))
        return;
    }

    setTimeout(() => {
      if (
        this.valid(this.state.fields.freightPickup.consignor.site) &&
        this.valid(this.state.fields.freightDelivery.consignee.site) &&
        this.valid(this.state.fields.freightPickup.consignor) &&
        this.valid(this.state.fields.freightDelivery.consignee) &&
        this.valid(this.state.fields.customer) &&
        (this.state.isDependent? this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || this.valid(this.state.fields.customer) : this.valid(this.state.fields.customer) ) &&
        this.valid(this.state.fields.freightPickup) &&
        this.valid(this.state.fields.freightDelivery) &&
        this.valid(this.state.fields) &&
        (get(this.state, 'errors.party') ? false : true)
      ) {
        const data = this.getSubmitData();
        let pickupSiteCompanyId = this.isParentRequestOrder && this.parentRequestOrder?.typeId == PICKUP_REQUEST_ORDER_TYPE_ID ? get(this.parentRequestOrder, 'freightPickup.consignor.handler.companyId') : get(this.state.consignorSites, '0.companyId') || this.state.selectedConsignor?.companyId
        let deliverySiteCompanyId = this.isParentRequestOrder && this.parentRequestOrder?.typeId == DELIVERY_REQUEST_ORDER_TYPE_ID ? get(this.parentRequestOrder, 'freightDelivery.consignee.handler.companyId') : get(this.state.consigneeSites, '0.companyId') || this.state.selectedConsignee?.companyId
        if (this.isSamePickupAndDeliverySites(get(data, 'freightPickup.consignor.sites'), get(data, 'freightDelivery.consignee.sites'))) {
          alertifyjs.error("Pickup and Delivery Storage can't be same.");
        } else if (!isCurrentUserBroker() && this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY
                   && !includes([this.state.fields.customer.companyId.value, this.state.fields.providerId.value, pickupSiteCompanyId, deliverySiteCompanyId], this.props.currentUser.companyId) && this.state.isDependent ){
          alertifyjs.error("Your company has to be one of the parties for this order");
        } else if ( 
          this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN &&
          !this.state.fields.freightPickup.consignor.handlerId.value &&
          !this.state.fields.freightDelivery.consignee.handlerId.value
        ){
          alertifyjs.error('One of the Pickup Site or Delivery site is required.');
        }
        else if (!this.selectedOrderId && !this.state.isDependent && isCurrentUserBroker() && ((get(data, 'seller.representedById') != this.props.currentUser.companyId) && (get(data, 'buyer.representedById') != this.props.currentUser.companyId))) {
          alertifyjs.error('Your company has to be representing one of the parties for this order');
        }
        else if (includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, this.state.fields.typeId.value) && isTonnageExceeding){
          this.setState({openWarningDialog: true});
        }
        else if((isPickupByPassed ^ isDeliveryByPassed) && !this.state.preview && (!this.isEditForm || this.isAcceptanceRequiredInEditForm())){
          const siteName = isPickupByPassed ? 'Pickup' : 'Delivery';
          alertifyjs.confirm(
            `${siteName} Order Number Not Entered`,
            `<div><p>Since no ${siteName} order number is entered, as mandated by the ${siteName} site, this order will go to the site owner for acceptance. </p><p>This process is put in place by the site owners and is a required step.</p></div>`,
            () => { this.finalSubmit(data); },
            () => {  },
          );
        }
        else{
          this.finalSubmit(data);
        }
      }
    }, 200);
  }

  isSamePickupAndDeliverySites(consignorSites, consigneeSites) {
    consignorSites = map(consignorSites, partialRight(pick, ['locationId', 'locationType']));
    consigneeSites = map(consigneeSites, partialRight(pick, ['locationId', 'locationType']));
    var isSiteEmpty = true;
    forEach(consignorSites, site => {
      if (site.locationId && site.locationType) {
        isSiteEmpty = false;
      }
    });
    forEach(consigneeSites, site => {
      if (site.locationId && site.locationType) {
        isSiteEmpty = false;
      }
    });
    if (isSiteEmpty) {
      return false;
    }
    return !isEmpty(intersectionWith(consignorSites, consigneeSites, isEqual));
  }

  isOnePartyUser(seller, buyer) {
    if(seller && buyer)
      {
        if(seller === this.props.currentUser.companyId || buyer === this.props.currentUser.companyId)
          return false;
        else if(seller != this.props.currentUser.companyId && buyer != this.props.currentUser.companyId)
          return true;
      }
    return false;
  }

  valid(fields) {
    return every(fields, field => {
      if (has(field, 'errors')) return field.errors.length === 0;
      else return true;
    });
  }

  async setSelectedBaseEntity() {
    const query = new URLSearchParams(this.props.location.search);
    const commodityContractId = query.get('commodityContractId');
    if (this.selectedOrderId) {
      await this.getFreightOrder(this.selectedOrderId);
    } else if (commodityContractId) {
      await this.getCommodityContract(commodityContractId);
    }
  }

  async getFreightOrder(orderId, updateOrder=false) {
    if (!this.fetchingOrder || updateOrder) {
      this.fetchingOrder = true;
      await APIService.freights()
                      .orders(orderId)
                      .get(this.props.userToken)
                      .then(response => {
                        const newState = { ...this.state };
                        newState.pickupOrder = get(response, 'pickupOrder');
                        newState.deliveryOrder = get(response, 'deliveryOrder');
                        const isBuyerToSellerOrder = get(response, 'typeId') == FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER
                        if(this.applyingTemplate && isBuyerToSellerOrder){
                          newState.isDependent = !get(response, 'externalReferenceNumber', 0);
                          newState.orderStatus = get(response, 'status');
                          this.isCallOnGrainOrder = get(response, 'typeId') == 3;
                          this.isDuplicatingOrder = true
                        }
                        this.setState(newState);
                        this.selectedOrder = response;
                        if (get(this.selectedOrder, 'canCreateFreightOrder') || isEqual(get(this.selectedOrder, 'status'), 'template') || this.isDuplicatingOrder) {
                          let baseEntityType = 'order'
                          let baseEntity = this.selectedOrder
                          if(this.isDuplicatingOrder && isBuyerToSellerOrder){
                            baseEntity = get(this.selectedOrder, 'parentOrder') || get(this.selectedOrder, 'commodityContract') || this.selectedOrder
                            baseEntityType = baseEntity.entity === 'freightorder' ? 'order' : 'commodityContract';
                          }
                          setTimeout(() => this.setBaseEntityState(baseEntity, baseEntityType, updateOrder), 1000);
                        } else {
                          window.location.hash = '#/403';
                        }
                      });
    }
  }

  async getCommodityContract(contractId, updateContract=false) {
    if (!this.isFetchingCommodityContract || updateContract) {
      this.isFetchingCommodityContract = true;
      let contract = await APIService.contracts(contractId).get(this.props.userToken);
      if (get(contract, 'requestedUnit') !== get(contract, 'commodity.priceUnit')) {
        contract = await APIService.contracts(contractId).get(this.props.userToken, {'REFERER-UNIT' : get(contract, 'commodity.priceUnit'), 'REFERER-UNIT-FOR-REQUEST': true});
      }
      if (get(contract, 'canCreateFreightOrder')) {
        setTimeout(() => this.setBaseEntityState(contract, 'commodityContract', updateContract), 1000);
      } else {
        window.location.hash = '#/403';
      }
    }
  }

  setBaseEntityState(baseEntity, entityType, forceUpdateEntity=false) {
    if (baseEntity && (this.state.selectedBaseEntityId !== baseEntity.id || forceUpdateEntity)) {
      const newState = { ...this.state };
      if(!this.isCallOnGrainOrder){
        let baseEntityTypeId = FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER;
        if(entityType === 'order')
          baseEntityTypeId = baseEntity.typeId == FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY ? FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY: FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER
        newState.fields.typeId.value = this.isParentRequestOrder ? undefined : baseEntityTypeId
      }
      newState.queryParamExists = true;
      newState.selectedBaseEntityId = baseEntity.id;
      newState.selectedBaseEntityType = entityType;
      if(isEqual(entityType, 'order') && isEqual(get(baseEntity, 'status'), 'template') || this.applyingTemplate) {
        this.disableForm = false;
        newState.queryParamExists = false;
        newState.fields.typeId.errors = [];
      }
      this.setState(newState, () => setTimeout(() => this.handleBaseEntityChange(baseEntity.id, entityType + 'Id', baseEntity), 2000));
    }
  }

  getBaseEntityFromItem(item, entityType) {
    const isCallOnGrain = this.isSelectedOrderCallOnGrain();
    const isRequestOrder = this.isParentRequestOrder;
    const baseEntity = {
      id: item.id,
      identifier: item.identifier,
      entity: entityType,
      unaccountedTonnage: item.unaccountedTonnage,
      isPoolContract: get(item, 'isPoolContract'),
      customerRepresentedById: null,
      administration: get(item, 'administration', get(item, 'commodityContract.administration')),
      canRaiseAmendRequest: get(item, 'canRaiseAmendRequest')
    };
    const isOrderTemplate = entityType === 'order' && (isEqual(get(item, 'status'), 'template') || this.applyingTemplate);

    if (entityType === 'order') {
      baseEntity.customerRepresentedById = get(item, 'customer.representedById');
      baseEntity.season = this.getValueFromOrder(item, 'season');
      baseEntity.commodityId = this.getValueFromOrder(item, 'commodityId');
      baseEntity.varietyId = this.getValueFromOrder(item, 'varietyId');
      baseEntity.deliveryStartDate = get(item, 'freightPickup.dateTime') ? moment(get(item, 'freightPickup.dateTime')).format('YYYY-MM-DD') : this.getValueFromOrder(item, 'freightPickup.date', 'deliveryStartDate');
      baseEntity.deliveryEndDate = get(item, 'freightDelivery.dateTime') ? moment(get(item, 'freightDelivery.dateTime')).format('YYYY-MM-DD') : this.getValueFromOrder(item, 'freightDelivery.date', 'deliveryEndDate');

      baseEntity.commodityContractId = this.getValueFromOrder(item, 'commodityContractId', 'id');
      baseEntity.customerId = this.getValueFromOrder(item, 'customerId');
      baseEntity.customerCompany = isCallOnGrain || isRequestOrder || isOrderTemplate ? this.getValueFromOrder(item, 'customer.company') : this.getValueFromOrder(item, 'provider');
      baseEntity.customerCompanyId = isCallOnGrain || isRequestOrder || isOrderTemplate ? this.getValueFromOrder(item, 'customer.companyId') : this.getValueFromOrder(item, 'provider.id');
      baseEntity.customerContactId = isCallOnGrain || isRequestOrder || isOrderTemplate ? this.getValueFromOrder(item, 'customer.contactId') : this.getValueFromOrder(item, 'assignToId', 'administration.assignToId');
      baseEntity.customerContact = isCallOnGrain || isRequestOrder || isOrderTemplate ? this.getValueFromOrder(item, 'customer.contact') : this.getValueFromOrder(item, 'assignTo', 'administration.assignTo');
      baseEntity.seller = this.getValueFromOrder(item, 'seller');
      baseEntity.buyer = this.getValueFromOrder(item, 'buyer');

      baseEntity.consignorHandlerId = this.getValueFromOrder(item, 'freightPickup.consignor.handlerId', 'consignorsWithSites[0].handlerId');
      baseEntity.consignorHandlerName = this.getValueFromOrder(item, 'freightPickup.consignor.handler.displayName', 'consignorsWithSites[0].handler.displayName');
      baseEntity.consignorLocationId = this.getValueFromOrder(item, 'freightPickup.consignor.sites.0.locationId', 'consignorsWithSites[0].sites.0.locationId');
      baseEntity.consignorSiteAddress = this.getValueFromOrder(
        item,
        'freightPickup.consignor.sites.0.location.address',
        'consignorsWithSites[0].sites.0.location.address',
      );
      baseEntity.consignorLocationType = this.getValueFromOrder(
        item,
        'freightPickup.consignor.sites.0.locationContentType',
        'consignorsWithSites[0].sites.0.locationContentType',
      );
      baseEntity.consignorLocationName = this.getValueFromOrder(
        item,
        'freightPickup.consignor.sites.0.location.name',
        'consignorsWithSites[0].sites.0.location.name',
      );

      baseEntity.consigneeHandlerId = this.getValueFromOrder(item, 'freightDelivery.consignee.handlerId', 'consigneesWithSites[0].handlerId');
      baseEntity.consigneeHandlerName = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.handler.displayName',
        'consigneesWithSites[0].handler.displayName',
      );
      baseEntity.consigneeLocationId = this.getValueFromOrder(item, 'freightDelivery.consignee.sites.0.locationId', 'consigneesWithSites[0].sites.0.locationId');
      baseEntity.consigneeSiteAddress = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.sites.0.location.address',
        'consigneesWithSites[0].sites.0.location.address',
      );
      baseEntity.consigneeLocationType = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.sites.0.locationContentType',
        'consigneesWithSites[0].sites.0.locationContentType',
      );
      baseEntity.consigneeLocationName = this.getValueFromOrder(
        item,
        'freightDelivery.consignee.sites.0.location.name',
        'consigneesWithSites[0].sites.0.location.name',
      );

      baseEntity.consignorSites = this.getValueFromOrder(item, 'freightPickup.consignor.sites', 'consignorsWithSites[0].sites');
      baseEntity.consigneeSites = this.getValueFromOrder(item, 'freightDelivery.consignee.sites', 'consigneesWithSites[0].sites');

      baseEntity.spread = this.getValueFromOrder(item, 'spread.details');

      baseEntity.plannedGradeId = this.getValueFromOrder(item, 'plannedGradeId', 'gradeId');
      baseEntity.tonnage = this.getValueFromOrder(item, 'plannedTonnage', 'tonnage');
      baseEntity.orderTonnage = item.orderTonnage;
      baseEntity.trackName = this.getValueFromOrder(item, 'track');
      baseEntity.conveyance = this.getValueFromOrder(item, 'conveyance');
      baseEntity.deliveryOnus = this.getValueFromOrder(item, 'deliveryOnus');
      baseEntity.generalConditions = item.generalConditions;
      baseEntity.specialConditions = item.specialConditions;
      baseEntity.pickupOrderNumber = get(item, 'freightPickup.orderNumber');
      baseEntity.deliveryOrderNumber = get(item, 'freightDelivery.orderNumber');
      baseEntity.accountedTonnage = item.accountedTonnage;
      baseEntity.inferredTonnage = item.inferredTonnage;
      baseEntity.totalDeliveredTonnage = item.totalDeliveredTonnage;
      baseEntity.progressTonnage = item.progressTonnage;
      baseEntity.outstandingTonnage = item.outstandingTonnage;
      if (get(item, 'commodityContract.typeId') === CONTRACT_TYPES.BLENDED) {
        baseEntity.chemicalApplications = get(item, 'commodityContract.chemicalApplications');
        baseEntity.gradeName = get(item, 'commodityContract.gradeName');
        this.setState({isParentContractBlendContract: true});
        this.fetchApplicationRates(get(baseEntity, 'seller.companyId'));
      }
    } else {
      baseEntity.contractStatus = item.status;
      baseEntity.pricePointId = item.pricePointId;
      baseEntity.isBuyer = item.isBuyer;
      baseEntity.isSeller = item.isSeller;
      baseEntity.weightId = item.weightId;
      baseEntity.expectedTonnage = item.expectedTonnage;
      baseEntity.directFreightContractsTonnage = item.directFreightContractsTonnage;
      baseEntity.directFreightOrdersTonnage = item.directFreightOrdersTonnage;
      baseEntity.commodityContractId = item.id;
      baseEntity.season = item.season;
      baseEntity.commodityId = item.commodityId;
      baseEntity.varietyId = item.varietyId;
      baseEntity.deliveryStartDate = item.deliveryStartDate;
      baseEntity.deliveryEndDate = item.deliveryEndDate;
      baseEntity.deliveryOnus = item.deliveryOnus;
      baseEntity.userHasConveyance = item.userHasConveyance;
      baseEntity.userHasDeliveryOnus = item.userHasDeliveryOnus;
      baseEntity.seller = item.seller;
      baseEntity.buyer = item.buyer;
      baseEntity.isSellerRegistered = get(item, 'isSellerParticipatingInTransaction');
      baseEntity.isBuyerRegistered = get(item, 'isBuyerParticipatingInTransaction');
      baseEntity.isBothPartiesManagedBy = get(item, 'isBothPartiesManagedBy');
      baseEntity.conveyance = item.conveyance;
      baseEntity.deliveryOnus = item.deliveryOnus;
      baseEntity.pricePoint = item.pricePoint;
      baseEntity.displayName = item.displayName;
      baseEntity.currency = item.currency;
      baseEntity.chemicalApplications = item.chemicalApplications;
      baseEntity.gradeName = item.gradeName;
      baseEntity.accountedTonnage = item.accountedTonnage;
      baseEntity.inferredTonnage = item.inferredTonnage;
      baseEntity.totalDeliveredTonnage = item.totalDeliveredTonnage;
      baseEntity.progressTonnage = item.progressTonnage;
      baseEntity.outstandingTonnage = item.outstandingTonnage;
      baseEntity.invoicedTonnage = item.invoicedTonnage;
      baseEntity.remainingTonnage = item.remainingTonnage;
      if (item.typeId === CONTRACT_TYPES.BLENDED) {
        this.setState({isParentContractBlendContract: true})
        this.fetchApplicationRates(get(baseEntity, 'seller.companyId'));
      }
      if (item.deliveryOnus) {
        if (item.deliveryOnus.toLowerCase() == 'seller') {
          baseEntity.customerCompany = get(item, 'seller.company');
          baseEntity.customerCompanyId = get(item, 'seller.companyId');
          baseEntity.customerContactId = get(item, 'seller.contactId');
          baseEntity.customerContact = get(item, 'seller.contact');
          baseEntity.customerNgrId = get(item, 'seller.ngrId');
          baseEntity.customerRepresentedById = get(item, 'seller.representedById');
        } else {
          baseEntity.customerCompany = get(item, 'buyer.company');
          baseEntity.customerCompanyId = get(item, 'buyer.companyId');
          baseEntity.customerContactId = get(item, 'buyer.contactId');
          baseEntity.customerContact = get(item, 'buyer.contact');
          baseEntity.customerNgrId = get(item, 'buyer.ngrId');
          baseEntity.customerRepresentedById = get(item, 'buyer.representedById');
        }
      }
      baseEntity.allocatedContractConsignorSites = get(this.state.allocatedContract, 'consignorsWithSites')
      baseEntity.allocatedContractConsigneeSites = get(this.state.allocatedContract, 'consigneesWithSites')
      baseEntity.baseContractConsignorSites = item.consignorsWithSites
      baseEntity.baseContractConsigneeSites = item.consigneesWithSites
      let consignors = compact([...(item.consignorsWithSites || []), ...(get(this.state.allocatedContract, 'consignorsWithSites') || [])])
      baseEntity.consignorsWithSites = consignors
      baseEntity.consignorHandlerId = get(consignors, '0.handlerId');
      baseEntity.consignorHandlerName = get(consignors, '0.handler.displayName');
      baseEntity.consignorLocationId = get(consignors, '0.sites.0.locationId');
      baseEntity.consignorLocationName = get(consignors, '0.sites.0.location.name');
      baseEntity.consignorLocationType = get(consignors, '0.sites.0.locationContentType');
      baseEntity.consignorSites = get(consignors, '0.sites');

      let consignees = compact([...(item.consigneesWithSites || []), ...(get(this.state.allocatedContract, 'consigneesWithSites') || [])])
      baseEntity.consigneesWithSites = consignees
      baseEntity.consigneeHandlerId = get(consignees, '0.handlerId');
      baseEntity.consigneeHandlerName = get(consignees, '0.handler.displayName');
      baseEntity.consigneeLocationId = get(consignees, '0.sites.0.locationId');
      baseEntity.consigneeLocationName = get(consignees, '0.sites.0.location.name');
      baseEntity.consigneeLocationType = get(consignees, '0.sites.0.locationContentType');
      baseEntity.consigneeSites = get(consignees, '0.sites');

      baseEntity.spread = get(item, 'spread.details');
      baseEntity.plannedGradeId = item.gradeId;
      baseEntity.tonnage = item.tonnage;
      baseEntity.orderTonnage = item.orderTonnage;
      baseEntity.trackName = get(item, 'track');
    }

    if(isOrderTemplate) {
      baseEntity.providerId = this.getValueFromOrder(item, 'providerId');
      baseEntity.assignToId = this.getValueFromOrder(item, 'assignToId');
      baseEntity.plannedTonnage = this.getValueFromOrder(item, 'inferredPlannedTonnage');
    }

    return baseEntity;
  }

  getValueFromOrder(order, keyPath, contractKeyPath) {
    if (isEmpty(order)) return null;
    contractKeyPath = contractKeyPath || keyPath;
    const value = get(order, keyPath);
    if (isEmpty(value) && !isNumber(value)) {
      if (!isEmpty(order['commodityContract'])) {
        return get(order['commodityContract'], contractKeyPath);
      }
      if (!isEmpty(order['parentOrder'])) {
        return this.getValueFromOrder(order['parentOrder'], keyPath, contractKeyPath);
      }
    }
    return value;
  }

  getValueArrayFromOrder(order, keyPath, contractKeyPath) {
    if (isEmpty(order)) return null;
    contractKeyPath = contractKeyPath || keyPath;
    let values = [];
    const value = get(order, keyPath);
    if (value) values.push(value);

    if (!isEmpty(order['commodityContract'])) {
      const contractValue = get(order['commodityContract'], contractKeyPath);
      if (contractValue) values.push(contractValue);
    }

    if (!isEmpty(order['parentOrder'])) {
      const parentOrderValues = this.getValueFromOrder(order['parentOrder'], keyPath, contractKeyPath);
      if (parentOrderValues) values = values.concat(parentOrderValues);
    }
    return values;
  }

  getAllowedLinkedParentOrderTonnage() {
    const { pickupOrder, deliveryOrder } = this.state;
    let deliveryOrderRemainingTonnage = this.isFreightAllocation() ? null : deliveryOrder?.unaccountedTonnage;
    let pickupOrderRemainingTonnage = this.isFreightAllocation() ? null : pickupOrder?.unaccountedTonnage;
    if (deliveryOrderRemainingTonnage && pickupOrderRemainingTonnage && this.state.fields.typeId.value >= 4)
      return parseFloat(min([deliveryOrderRemainingTonnage, pickupOrderRemainingTonnage]))
    else
      return deliveryOrderRemainingTonnage;
  }

  maximumAllowedTonnage(withReason=false) {
    if (this.editOrder) {
      const allowedTonnage = (get(this.editOrder, 'maxAllowedTonnageForAmend') || 0).toFixed(2)
      if (withReason)
        return {allowedTonnage: allowedTonnage, reason: get(this.editOrder, 'reason')};
      return allowedTonnage;
    }
    else {
      const baseEntityUnaccountedTonnage = get(this.state.selectedBaseEntity, 'unaccountedTonnage');
      const { allocatedContract } = this.state;
      const allocatedContractUnaccountedTonnage = get(allocatedContract, 'unaccountedTonnage');
      const parentOrderUnaccountedTonnage = get(this.selectedOrder, 'unaccountedTonnage');
      const allowedLinkedParentOrderTonnage = this.getAllowedLinkedParentOrderTonnage();
      let parentEntities = [parentOrderUnaccountedTonnage, allowedLinkedParentOrderTonnage]
      const { pickupOrder, deliveryOrder} = this.state;
      let deliveryOrderRemainingTonnage = deliveryOrder?.unaccountedTonnage;
      let pickupOrderRemainingTonnage = pickupOrder?.unaccountedTonnage;
      if (get(this.state.selectedBaseEntity, 'id') && !includes(compact([get(pickupOrder, 'commodityContractId'), get(deliveryOrder, 'commodityContractId')]), get(this.state.selectedBaseEntity, 'id'))) {
        parentEntities.push(baseEntityUnaccountedTonnage)
      }
      if (get(this.state.allocatedContract, 'id') && !includes(compact([get(pickupOrder, 'commodityContractId'), get(deliveryOrder, 'commodityContractId')]), get(this.state.allocatedContract, 'id'))) {
        parentEntities.push(allocatedContractUnaccountedTonnage)
      }
      const allowedTonnage = parseFloat((min(parentEntities) || 0).toFixed(2))
      const reason = allowedTonnage === deliveryOrderRemainingTonnage ? 'delivery order' : allowedTonnage === pickupOrderRemainingTonnage ? 'pickup order' : allocatedContract || includes(['commodityContract', 'contract'], get(this.state.selectedBaseEntity, 'entity')) ? 'contract' : 'parent order';
      if(withReason){
        return {allowedTonnage: allowedTonnage.toFixed(2), reason: reason};
      }
      return allowedTonnage.toFixed(2);
    }
  }

  async handleOrderTypeChange(value) {
    this.props.dispatch(isLoading(null, 100000000));
    if (value !== this.state.fields.typeId.value) {
      if (!includes(this.props.location.search, 'commodityContractId=') && value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) {
        await this.getContractsList();
      }
      const newState = { ...this.state };
      this.resetFields(newState);
      newState.fields.typeId.value = value;
      newState.isDependent = true;
      newState.customerTonnageAvailable = undefined
      if (value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) {
        if (!this.isParentRequestOrder) {
          newState.fields.freightDelivery.date.value = null;
          newState.fields.freightPickup.date.value = null;
        }
        newState.fields.commodityContractId.validators = [required(), selected()];
        newState.fields.season.validators = [];
        newState.fields.commodityId.validators = [];
        newState.fields.customer.companyId.validators = [];
        newState.fields.customer.contactId.validators = [];
      } else {
        const query = new URLSearchParams(this.props.location.search);
        const queryParamExists = query.get('orderId') || query.get('commodityContractId');
        if (!queryParamExists) {
          newState.selectedBaseEntity = null;
        }
        newState.fields.commodityContractId.value = undefined;
        newState.fields.commodityContractId.validators = [];
        newState.fields.season.validators = [required()];
        newState.fields.commodityId.validators = [required()];
        newState.fields.customer.companyId.validators = [required(), selected()];
        newState.fields.customer.contactId.validators = [required(), selected()];
        newState.fields.externalReferenceNumber.validators = [];
      }
      if (!isNil(newState.fields.typeId.value))
        this.disableForm = false;
      this.setPlannedGradeValidators(newState);
      this.setConsignorConsigneeValidators(newState);
      this.setState(newState, () => this.setFieldErrors('typeId', value));
    }
    this.props.dispatch(loaded());
  }

  setPlannedGradeValidators(newState) {
    if (get(newState.selectedBaseEntity, 'isPoolContract')) {
      newState.fields.plannedGradeId.validators = [];
    } else {
      newState.fields.plannedGradeId.validators = [required()];
    }
  }

  isIndependentSiteContractOrder = () => Boolean(this.state.selectedBaseEntityRaw?.isIndependentSiteContract || this.editOrder?.priceDistribution?.contract)

  toFloat = val => {
    if(val)
      return parseFloat(val.replace(this.state.currency, '').replace(',', ''))
    return 0
  }

  getContractPrice = () => {
    if(this.isEditForm)
      return this.toFloat(this.editOrder?.priceDistribution?.contract) || 0
    const contract = this.state.selectedBaseEntityRaw
    return contract?.price || 0
  }

  getSpreadPrice = () => {
    if(this.isEditForm)
      return this.toFloat(this.editOrder?.priceDistribution?.spread) || 0
    const { fields } = this.state
    const contract = this.state.selectedBaseEntityRaw
    let price = 0
    const gradeId = fields.plannedGradeId.value
    if(contract?.spread?.details && gradeId) {
      const spread = find(contract.spread.details, {id: gradeId})
      if(spread?.value && spread.value !== "0")
        price = parseFloat(`${spread.priceVariation}${spread.value}`)
    }
    return price
  }

  getLDPrice = () => {
    if(this.isEditForm)
      return this.toFloat(this.editOrder?.priceDistribution?.ld) || 0
    const { fields } = this.state
    const contract = this.state.selectedBaseEntityRaw
    let price = 0
    const siteId = fields.freightDelivery.consignee.handlerId.value
    if(contract?.consigneesWithSites && siteId) {
      const handler = find(contract.consigneesWithSites, {handlerId: siteId})
      price = handler?.ld || price
    }
    return price
  }

  async handleBaseEntityChange(value, id, item, setFieldErrors = true) {
    if (!this.isEditForm) {
      this.resetDistanceAndTimeState();
      if (item) {
        this.applyingBaseEntity = true;
        this.props.dispatch(isLoading(null, 10000000));
        const entityType = id.slice(0, -2);
        let baseEntity = null;
        if (item.entity === 'contract') {
          let contract = item;
          if (!includes(this.props.location.search, 'commodityContractId=')) {
            contract = await APIService.contracts(item.id).get(this.props.userToken);
          }
          if (get(contract, 'requestedUnit') !== get(contract, 'commodity.priceUnit')) {
            contract = await APIService.contracts(contract.id).get(this.props.userToken, {'REFERER-UNIT' : get(contract, 'commodity.priceUnit'), 'REFERER-UNIT-FOR-REQUEST': true});
          }
          baseEntity = this.getBaseEntityFromItem(contract, entityType);
        } else {
          baseEntity = this.getBaseEntityFromItem(item, entityType);
        }
        const newState = { ...this.state };
        newState.setFieldErrors = setFieldErrors;
        if(!this.isDuplicatingOrder)
          this.resetFields(newState);
        newState.selectedBaseEntity = baseEntity;
        if (baseEntity?.currency)
          newState.currency = baseEntity?.currency
        newState.customerContact = baseEntity.customerContact;
        newState.selectedBaseEntityType = entityType;
        newState.selectedBaseEntityId = item.id;
        newState.selectedBaseEntityRaw = item;
        newState.fields.commodityContractId.value = baseEntity.commodityContractId;
        newState.fields.season.value = baseEntity.season;
        newState.fields.commodityId.value = baseEntity.commodityId;
        newState.fields.plannedGradeId.value = baseEntity.plannedGradeId;
        if (this.hasQuantityBasedCommodity(baseEntity.commodityId)) newState.fields.quantity.validators = [required()];
        else {
          newState.fields.quantity.validators = [];
          newState.fields.quantity.errors = [];
          newState.fields.quantity.value = undefined;
        }
        if(isEqual(get(item, 'status'), 'template') || this.applyingTemplate) {
          baseEntity.status = 'template';
          newState.fields.plannedTonnage.value = baseEntity.plannedTonnage;
          if(item.freightPickup && item.freightPickup.dateTime)
            newState.fields.freightPickup.date.value = moment(item.freightPickup.dateTime).format('YYYY-MM-DD')
          if(item.freightDelivery && item.freightDelivery.dateTime)
            newState.fields.freightDelivery.date.value = moment(item.freightDelivery.dateTime).format('YYYY-MM-DD')
          newState.fields.customer.ngrId.value = get(item, 'customer.ngrId')
          newState.fields.rateFreightOut.value = get(item, 'rateFreightOut')
          newState.fields.note = cloneDeep(get(item, 'note')) || cloneDeep(this.fieldsDup.note)
          newState.generalConditionsSelector = get(find(this.props.generalConditionsAll, { details: get(item, 'generalConditions') }), 'id');
          newState.specialConditionsSelector = get(find(this.props.specialConditionsAll, { details: get(item, 'specialConditions') }), 'id');
          newState.selectedSpecialConditionText = get(item, 'specialConditions');
          const consignor = get(item, 'freightPickup.consignor');
          const consignee = get(item, 'freightDelivery.consignee');
          const outloadThroughputFee = get(item, 'outloadThroughputFee');
          const inloadThroughputFee = get(item, 'inloadThroughputFee');
          const isCustomerPickupSite = get(this.editOrder, 'customer.company.id') === get(consignor, 'handler.companyId');
          const isCustomerDeliverySite = get(this.editOrder, 'customer.company.id') === get(consignee, 'handler.companyId');
          if (get(consignor, 'handler.companyId') === get(this.props, 'currentUser.companyId') && !isCustomerPickupSite){
            set(newState, 'throughputOutloadCheckbox', true); 
          }
          if (get(consignee, 'handler.companyId') === get(this.props, 'currentUser.companyId') && !isCustomerDeliverySite){
            set(newState, 'throughputInloadCheckbox', true);
          }
          if( outloadThroughputFee){
            set(newState, 'throughputOutloadOrder', true);
          }
          if( inloadThroughputFee){
            set(newState, 'throughputInloadOrder', true);
          }
        }
        newState.fields.providerId.value = baseEntity.providerId;
        if(this.isDuplicatingOrder){
          newState.fields.providerId.value = get(this.editOrder, 'providerId')
          newState.fields.plannedTonnage.value = get(this.editOrder, 'plannedTonnage')
          newState.fields.note.description = get(this.editOrder, 'note.description')
          newState.fields.rateFreightOut.value = get(this.editOrder, 'rateFreightOut')
        }
        const customer = find(newState.allDirectoryCompanies, { id: baseEntity.customerCompanyId}) || baseEntity.customerCompany;
        newState.fields.paymentTermId.value = get(customer,'paymentTermId') || get(customer,'company.paymentTermId');
        newState.selectedCustomer = customer;
        newState.fields.customer.company = baseEntity.customerCompany;
        newState.fields.customer.companyId.value = baseEntity.customerCompanyId;
        newState.fields.customer.companyId.validators = [];
        newState.seller = baseEntity.seller;
        newState.buyer = baseEntity.buyer;
        newState.didFetchedContracts = false;
        let isRequestOrder = false
        if (entityType === 'order') {
          if(!isEqual(get(baseEntity, 'status'), 'template') || !this.applyingTemplate) {
            newState.fields.freightPickup.orderNumber.value = get(baseEntity, 'pickupOrderNumber');
            newState.fields.freightDelivery.orderNumber.value = get(baseEntity, 'deliveryOrderNumber');
            newState.fields.parentOrderId.value = value;
          }
          newState.fields.generalConditions.value = baseEntity.generalConditions;
          newState.fields.specialConditions.value = baseEntity.specialConditions;
          newState.fields.rateFreightIn.value = newState.selectedBaseEntityRaw.rateFreightOut;
          if(this.isDuplicatingOrder)
            newState.fields.rateFreightIn.value = get(this.editOrder, 'rateFreightOut')
          isRequestOrder = includes(REQUEST_ORDER_TYPE_IDS, item.typeId)
          if(isRequestOrder) {
            if(!isEqual(get(baseEntity, 'status'), 'template'))
              newState.fields.parentOrderId.value = undefined;
            newState.fields.customer.companyId.value = item.customer.companyId
            newState.fields.customer.company = item.customer.company
            newState.selectedCustomer = find(newState.allDirectoryCompanies, {id: item.customer.companyId})
            newState.fields.customer.contactId.value = item.customer.contactId
            newState.fields.customer.ngrId.value = item.customer.ngrId
            if(item.typeId === PICKUP_REQUEST_ORDER_TYPE_ID) {
              newState.isLinkedPickupOrder = true
              newState.fields.freightPickup.orderId.value = item.id
              newState.fields.freightPickup.orderNumber.value = item.identifier
              newState.pickupCheckpointOrderExists = true
              newState.pickupOrder = item
            }
            else {
              newState.isLinkedDeliveryOrder = true
              newState.fields.freightDelivery.orderId.value = item.id
              newState.fields.freightDelivery.orderNumber.value = item.identifier
              newState.deliveryCheckpointOrderExists = true
              newState.deliveryOrder = item
            }
            if(item.freightPickup.dateTime)
              newState.fields.freightPickup.date.value = moment(item.freightPickup.dateTime).format('YYYY-MM-DD')
            if(item.freightDelivery.dateTime)
              newState.fields.freightDelivery.date.value = moment(item.freightDelivery.dateTime).format('YYYY-MM-DD')
            this.selectedOrder = null
            this.selectedOrderId = null
            newState.selectedBaseEntity = null
            newState.selectedBaseEntityRaw = null
            newState.selectedBaseEntityId = null
            newState.selectedBaseEntityType = null
            this.parentRequestOrder = item
          }
        } else if (entityType == 'commodityContract') {
          let consignors = map(get(baseEntity, 'consignorsWithSites'), consignor => consignor?.handler)
          consignors = map(consignors, ({ address, displayName, ...rest }) => ({...rest, name: displayName, address: address?.address}));
          newState.consignors = uniqBy([...newState.consignors, ...consignors], 'id');
          let consignees = map(get(baseEntity, 'consigneesWithSites'), consignee => consignee?.handler)
          consignees = map(consignees, ({ address, displayName, ...rest }) => ({...rest, name: displayName, address: address?.address}));
          newState.consignees = uniqBy([...newState.consignees, ...consignees], 'id');
        }
        this.setProviderAndInvoicingValidators(newState);
        this.setConsignorConsigneeValidators(newState);
        this.setPlannedGradeValidators(newState);
        this.setState(newState, () => {
          setTimeout(() => {
            this.forceSetFieldErrors('freightPickup.orderId', this.getCheckpointOrderFieldErrors('freightPickup'));
            this.forceSetFieldErrors('freightDelivery.orderId', this.getCheckpointOrderFieldErrors('freightDelivery'));
          }, 3000)
        });
        setTimeout(() => {
          const newState = { ...this.state };
          newState.fields.varietyId.value = baseEntity.varietyId;
          newState.fields.assignToId.value = baseEntity.assignToId;
          if(this.isDuplicatingOrder)
            newState.fields.assignToId.value = get(this.editOrder, 'assignToId')
          this.setState(newState, () => {
            const newState = { ...this.state };
            newState.fields.plannedGradeId.errors = [];
            if (get(newState.selectedBaseEntity, 'isPoolContract')) {
              newState.fields.plannedGradeId.value = undefined;
            } else {
              newState.fields.plannedGradeId.value = baseEntity.plannedGradeId;
            }
            newState.setFieldErrors = true;
            this.setState(newState);
          });
        }, 500);
        setTimeout(() => {
          const newState = { ...this.state };
          if (baseEntity.consigneeSites && baseEntity.consigneeSites.length > 0) {
            newState.consigneeSites = map(baseEntity.consigneeSites, function(item) {
              return {
                id: item.location.id,
                name: item.location.name,
                companyId: item.location.companyId,
                tracks: item.location.tracks,
                entity: item.location.entity,
              };
            });
          }
          if (baseEntity.consignorSites && baseEntity.consignorSites.length > 0) {
            newState.consignorSites = map(baseEntity.consignorSites, function(item) {
              return {
                id: item.location.id,
                name: item.location.name,
                companyId: item.location.companyId,
                tracks: item.location.tracks,
                entity: item.location.entity,
              };
            });
          }
          const dateValidators = [required()];
          if (!isEmpty(baseEntity.deliveryStartDate) && !isEqual(get(baseEntity, 'status'), 'template')) {
            dateValidators.push(
              minDate(
                baseEntity.deliveryStartDate,
                `Delivery start date in ${baseEntity.entity === 'order' ? 'order' : 'contract'} is ${moment(baseEntity.deliveryStartDate).format(
                  this.state.countryFormats.date,
                )}`,
                'warning',
              ),
            );
          }
          if (!isEmpty(baseEntity.deliveryEndDate) && !isEqual(get(baseEntity, 'status'), 'template')) {
            dateValidators.push(
              maxDate(
                baseEntity.deliveryEndDate,
                `Delivery end date in ${baseEntity.entity === 'order' ? 'order' : 'contract'} is ${moment(baseEntity.deliveryEndDate).format(
                  this.state.countryFormats.date,
                )}`,
                'warning',
              ),
            );
          }
          newState.fields.freightPickup.date.validators = dateValidators;
          newState.fields.freightDelivery.date.validators = dateValidators;

          this.setState(newState);
        }, 200);
        setTimeout(() => {
          this.fetchDistance();
        }, 1200);
        if (baseEntity.customerCompanyId) {
          await this.getCustomerContacts(newState.fields.customer.company, null, null, baseEntity.customerRepresentedById);
          if (baseEntity.customerContactId && this.state.customerContacts) {
            const newState = { ...this.state };
            newState.fields.customer.contactId.value = baseEntity.customerContactId;
            this.setState(newState);
          }
          await this.getCustomerNgrs(baseEntity.customerCompanyId);
          if (baseEntity.customerNgrId && this.state.customerNgrs) {
            const newState = { ...this.state };
            newState.fields.customer.ngrId.value = baseEntity.customerNgrId;
            newState.fields.customer.ngrId.validators = [];
            this.setState(newState);
          }
        }
        if (baseEntity.consignorHandlerId) {
          const selectedConsignor = find(this.state.consignors, { id: baseEntity.consignorHandlerId }) || {id: baseEntity.consignorHandlerId, name: baseEntity.consignorHandlerName};
          if (selectedConsignor) {
            await Promise.all([this.getSites(baseEntity.consignorHandlerId, 'farm', 'consignor')]);
            const newState = { ...this.state };
            newState.selectedConsignor = selectedConsignor;
            newState.selectedConsignorId = baseEntity.consignorHandlerId
            newState.fields.freightPickup.consignor.handlerId.value = selectedConsignor.id;
            newState.fields.freightPickup.consignor.site.locationId.errors = [];
            if(!this.isParentRequestOrder)
              newState.fields.freightPickup.orderId.value = get(item, 'pickupOrder.id');
            const isPickupSiteGate = get(baseEntity, 'consignorSites.0.location.isGate');
            if (baseEntity.consignorLocationId) {
              newState.isPickupSiteGate = isPickupSiteGate;
              newState.fields.freightPickup.consignor.site.locationId.value = baseEntity.consignorSites[0].locationId;
              newState.fields.freightPickup.consignor.site.locationType.value = baseEntity.consignorSites[0].locationContentType;
              const selectedConsignorSite = find(this.state.consignorSites, {
                id: baseEntity.consignorSites[0].locationId,
                entity: baseEntity.consignorSites[0].locationContentType,
              });
              if (selectedConsignorSite) {
                newState.selectedConsignorSite = selectedConsignorSite;
              }
              newState.fields.freightPickup.consignor.site.address = baseEntity.consigneeSiteAddress;
              newState.consignorAddress = { value: get(baseEntity, 'consignorSites.0.location.address') };
            }
            this.setState(newState, () => {
              if(get(this.state, 'selectedConsignor.id'))
                this.fetchPickupSettings(this.state.selectedConsignor.id);
            });
          }
        }
        if (baseEntity.consigneeHandlerId) {
          const selectedConsignee = find(this.state.consignees, { id: baseEntity.consigneeHandlerId }) || {id: baseEntity.consigneeHandlerId, name: baseEntity.consigneeHandlerName};
          if (selectedConsignee) {
            await Promise.all([this.getSites(baseEntity.consigneeHandlerId, 'farm', 'consignee')]);
            const newState = { ...this.state };
            newState.selectedConsignee = selectedConsignee;
            newState.selectedConsigneeId = baseEntity.consigneeHandlerId
            if(!this.isParentRequestOrder)
              newState.fields.freightDelivery.orderId.value = get(item, 'deliveryOrder.id');
            newState.fields.freightDelivery.consignee.handlerId.value = selectedConsignee.id;
            newState.fields.freightDelivery.consignee.site.locationId.errors = [];
            const isDeliverySiteGate = get(baseEntity, 'consigneeSites.0.location.isGate');
            if (baseEntity.consigneeLocationId && !isDeliverySiteGate) {
              newState.isDeliverySiteGate = isDeliverySiteGate;
              newState.fields.freightDelivery.consignee.site.locationId.value = baseEntity.consigneeSites[0].locationId;
              newState.fields.freightDelivery.consignee.site.locationType.value = baseEntity.consigneeSites[0].locationContentType;
              const selectedConsigneeSite = find(this.state.consigneeSites, {
                id: baseEntity.consigneeSites[0].locationId,
                entity: baseEntity.consigneeSites[0].locationContentType,
              });
              if (selectedConsigneeSite) {
                newState.selectedConsigneeSite = selectedConsigneeSite;
              }
              newState.fields.freightDelivery.consignee.site.address = baseEntity.consigneeSiteAddress;
              newState.consigneeAddress = { value: get(baseEntity, 'consigneeSites.0.location.address') };
            }
            this.setState(newState, () => {
              if(get(this.state, 'selectedConsignee.id'))
                this.fetchDeliverySettings(this.state.selectedConsignee.id);
            });
          }
        }
        newState.fields.invoicing.value = get(item, 'invoicing');
        if(this.isDuplicatingOrder)
          newState.fields.invoicing.value = get(this.editOrder, 'invoicing')

        if (baseEntity.consignorHandlerId) {
          newState.fields.freightPickup.consignor.handlerId.value = baseEntity.consignorHandlerId;
        }
        if (baseEntity.consigneeHandlerId) {
          newState.fields.freightDelivery.consignee.handlerId.value = baseEntity.consigneeHandlerId;
        }
        if (baseEntity.consignorLocationId) {
          newState.fields.freightPickup.consignor.site.locationId.value = baseEntity.consignorSites[0].locationId;
          newState.fields.freightPickup.consignor.site.locationType.value = baseEntity.consignorSites[0].locationContentType;
        }
        if (baseEntity.consigneeLocationId) {
          newState.fields.freightDelivery.consignee.site.locationId.value = baseEntity.consigneeSites[0].locationId;
          newState.fields.freightDelivery.consignee.site.locationType.value = baseEntity.consigneeSites[0].locationContentType;
        }
        this.removeLoader = true;
        this.applyingBaseEntity = false;
        this.fetchingOrder = false;
        this.props.dispatch(loaded());
      } else if (id === 'commodityContractId') {
        const newState = { ...this.state };
        newState.fields.commodityContractId.value = value;
        this.setState(newState);
      }

      if(this.isDuplicatingOrder)
        this.isDuplicatingOrder = false
      this.setFieldErrors(id, value);
      this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, this.isCallOnGrainOrder ? get(this.state, 'sellerDetails.ngrId') : this.state.fields.customer.ngrId.value);
      this.applyingTemplate = false;
    }
  }

  setProviderAndInvoicingValidators(newState) {
    if (!newState.fields.isSelf.value) {
      newState.fields.providerId.validators = [required()];
      newState.fields.assignToId.validators = [required()];
    }
  }

  maximumAllowedTonnageLabel() {
    const { allowedTonnage, reason } = this.maximumAllowedTonnage(true);
    if (this.hasSelectedBaseEntity() && !this.isMeterCubeCommodityCommodity() && !isEqual(get(this.state.selectedBaseEntity, 'status'), 'template')) {
      return ` (Maximum Allowed ${this.tonnageLabel}: ${allowedTonnage} ${this.priceUnit()} due to ${reason})`
    } else {
      return '';
    }
  }

  allocatedTonnageHelperText() {
    const { allocatedContract, fields} = this.state;
    if(allocatedContract?.allocatedTonnage){
      const maxAllowedTonnage = allocatedContract.allocatedTonnage - allocatedContract.allocatedPlannedTonnage + get(this.editOrder, 'plannedTonnage', 0);
      if (fields.plannedTonnage.value > maxAllowedTonnage){
        return `Warning: You are exceeding from the unplanned allocated ${this.tonnageLabel.toLowerCase()} ${maxAllowedTonnage.toFixed(2)}${this.priceUnit()}`
      }
    }
  }

  maximumAllowedQuantityLabel() {
    if (this.hasSelectedBaseEntity() && this.isMeterCubeCommodityCommodity() && !isEqual(get(this.state.selectedBaseEntity, 'status'), 'template')) {
      const commodity = this.getSelectedCommodity()
      const quantityLabel = commodity?.quantityLabel || ''
      return ` (Maximum Allowed ${quantityLabel}: ${this.maximumAllowedTonnage()} ${commodity?.unit})`;
    } else {
      return '';
    }
  }

  handleFarmSideDrawer = (key, bool = false) => {
    this.setState({
      [key]: bool
    });
  };

  handleFarmSubmit = (key, data) =>{
    this.handleFarmSideDrawer(key, false);
    this.setState({inputTextFarm: ""});
    data = pick(data, ['id', 'name', 'ld']);
    if (key === 'consignorSideDrawer'){
      this.handleConsignorChange(null, data);
    }
    else if (key === 'consigneeSideDrawer'){
      this.handleConsigneeChange(null, data);
    }
  };

  handleQuantityFieldChange(event) {
    let value = event.target.value;
    if (value) value = parseFloat(value);

    this.setState({
      fields: {
        ...this.state.fields,
        quantity: { ...this.state.fields.quantity, value: value, errors: value ? [] : this.state.fields.quantity.errors },
      },
    });
    const newState = { ...this.state };
    if (this.hasSelectedBaseEntity() && this.isMeterCubeCommodityCommodity()) {
      if (parseFloat(event.target.value) > this.maximumAllowedTonnage()) {
        if (get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder') {
          newState.fields[event.target.id].errors = [this.EXCESS_ALLOCATION_QUANTITY_ERROR_MESSAGE];
        } else {
          newState.fields[event.target.id].errors = [this.EXCESS_ORDER_QUANTITY_ERROR_MESSAGE];
        }
      } else {
        newState.fields[event.target.id].errors = [];
      }
    }
    if (this.isMeterCubeCommodityCommodity() && this.isEditForm && parseFloat(event.target.value) <= this.maximumAllowedTonnage()) {
      if (parseFloat(event.target.value) < parseFloat(get(this.editOrder, 'minimumAllowedTonnage', 0))) {
        newState.fields[event.target.id].errors = [this.MINIMUM_QUANTITY_ERROR_MESSAGE];
      } else {
        newState.fields[event.target.id].errors = [];
      }
    }
    this.setState(newState);
  }

  getExcessOrderTonnageErrorMessage() {
    const { reason } = this.maximumAllowedTonnage(true);
    return `Order ${this.tonnageLabel.toLowerCase()} cannot be greater than ${reason} ${this.tonnageLabel.toLowerCase()}`;
  }

  async getAvailableTonnageForCustomer(siteId, plannedGradeId, season, ngrId) {
    const pickupSiteId = siteId || this.state.fields.freightPickup.consignor.handlerId.value;
    const ownerNgrId = ngrId ? ngrId : this.state.fields.customer.ngrId.value
    const isCurrentUserCustomer = isCurrentUserBelongsToCompany(this.state.fields.customer.companyId.value);
    const consignor = this.getEstablishment(this.state.fields.freightPickup.consignor.handlerId.value, null, this.state.consignors);
    const isCurrentUserBelongsToPickupSite = isCurrentUserBelongsToCompany(get(consignor, 'companyId'));
    let companyTypeId = get(this.state.fields.customer.company, 'typeId');
    const isStocksManagementOnForConsignor = get(this.state.selectedConsignor, 'stocksManagement')
    if (isStocksManagementOnForConsignor && includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, this.state.fields.typeId.value) && (isCurrentUserCustomer || isCurrentUserBelongsToPickupSite) && this.state.fields.commodityId.value && plannedGradeId && season && ownerNgrId && pickupSiteId) {
      let queryString = `?commodityId=${this.state.fields.commodityId.value}&gradeId=${plannedGradeId}&siteId=${siteId}&season=${season}`
      if (companyTypeId === GROWER) {
        queryString += `&type=outload&customerTypeId=${companyTypeId}`;
      }
      let response = await APIService.ngrs(ownerNgrId).appendToUrl('available-stock/')
                                    .appendToUrl(queryString)
                                    .get();
      let tonnage = response.totalTonnage.toFixed(2);
      this.setState({customerTonnageAvailable: tonnage});
      if (isEmpty(this.state.fields.plannedTonnage.errors))
        this.setTonnageWarning(this.state.fields.plannedTonnage.value, tonnage);
    }
    else {
      const newState = {...this.state};
      newState.customerTonnageAvailable = null;
      this.setState(newState);
    }
  }

  setTonnageWarning(tonnage, availableTonnage) {
    const newState = {...this.state};
    let isTonnageExceeding = parseFloat(availableTonnage) < parseFloat(tonnage)
    if (this.editOrder)
      isTonnageExceeding = parseFloat(availableTonnage) < (parseFloat(tonnage) - parseFloat(get(this.editOrder, 'progressTonnage', 0)))
    if (includes(SHOW_TONNAGE_ERROR_FOR_ORDER_TYPE_IDS, this.state.fields.typeId.value) && tonnage && !isNull(availableTonnage) && isTonnageExceeding) {
      newState.fields.plannedTonnage.errors = [`Warning: exceeds stored ${this.tonnageLabel.toLowerCase()}. Available ${this.tonnageLabel} = ${availableTonnage} ${this.priceUnit()}`];
    }
    this.setState(newState);
  }

  handleTonnageFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    this.setFieldErrors(event.target.id, event.target.value);
    this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, this.isCallOnGrainOrder ? get(this.state, 'sellerDetails.ngrId') : this.state.fields.customer.ngrId.value);
    const newState = { ...this.state };
    this.setTonnageWarning(event.target.value, this.state.customerTonnageAvailable);
    if (this.hasSelectedBaseEntity() && !isEqual(get(this.state.selectedBaseEntity, 'status'), 'template') && !this.isMeterCubeCommodityCommodity()) {
      if (parseFloat(event.target.value) > this.maximumAllowedTonnage()) {
        if (get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder') {
          newState.fields[event.target.id].errors = [this.EXCESS_ALLOCATION_TONNAGE_ERROR_MESSAGE];
        } else {
          newState.fields[event.target.id].errors = [this.getExcessOrderTonnageErrorMessage()];
        }
      }
    }
    if (!this.isMeterCubeCommodityCommodity() && this.isEditForm && (!this.hasSelectedBaseEntity() || parseFloat(event.target.value) <= this.maximumAllowedTonnage())) {
      if (parseFloat(event.target.value) < parseFloat(get(this.editOrder, 'minimumAllowedTonnage', 0))) {
        newState.fields[event.target.id].errors = [this.MINIMUM_TONNAGE_ERROR_MESSAGE];
      } else {
        newState.fields[event.target.id].errors = [];
      }
    }
    const allocatedTonnageHelperText = this.allocatedTonnageHelperText()
    if(allocatedTonnageHelperText && isEmpty(newState.fields[event.target.id].errors))
      newState.fields[event.target.id].errors = [allocatedTonnageHelperText]
    else
      newState.fields[event.target.id].errors = without(newState.fields[event.target.id].errors, allocatedTonnageHelperText)
    this.forceSetFieldErrors('freightPickup.orderId', this.getCheckpointOrderFieldErrors('freightPickup'));
    this.forceSetFieldErrors('freightDelivery.orderId', this.getCheckpointOrderFieldErrors('freightDelivery'));
    this.setState(newState, () => this.setBlendGradeAndChemicalApplicationDetails());
  }

  getPercentageForSpread(spread) {
    return parseFloat((((get(spread, 'quantity') / get(this.state.selectedBaseEntity, 'tonnage')) * 100)).toFixed(2))
  }

  setBlendGradeAndChemicalApplicationDetails() {
    let blendGradeDetails = [];
    let chemicalApplicationDetails = [];
    if (this.state.isParentContractBlendContract && this.state.fields.plannedTonnage.value) {
      const spreadDetails = get(this.state.selectedBaseEntity, 'spread') || [];
      if (spreadDetails && !isEmpty(spreadDetails)) {
        const orderTonnage = parseFloat(this.state.fields.plannedTonnage.value);
        let isPercentageExhausted = false;
        let totalPercentage = 0
        map(spreadDetails, (spread, index) => {
          let gradeName = get(spread, 'gradeName');
          let quantity = get(spread, 'quantity');
          let percentage = 0;
          if (isPercentageExhausted)
            percentage = 0;
          else {
            if (gradeName && quantity)
              percentage = this.getPercentageForSpread(spread);
            if (percentage > 100) {
              percentage = 100;
              isPercentageExhausted = true;
            }
          }
          if (totalPercentage + percentage > 100)
            percentage = 100 - totalPercentage;
          totalPercentage += percentage;
          let objTonnage = (percentage/100) * orderTonnage;
          const obj = {
            id: index + 1,
            gradeId: get(spread, 'gradeId'),
            gradeName: get(spread, 'gradeName'),
            tonnage: parseFloat(objTonnage.toFixed(2)),
            percentage: percentage,
            errors: []
          }
          blendGradeDetails.push(obj);
        })
      }
    }
    if (this.state.isParentContractBlendContract) {
      const chemicalApplications = get(this.state.selectedBaseEntity, 'chemicalApplications');
      const spreadDetails = get(this.state.selectedBaseEntity, 'spread') || [];
      map(chemicalApplications, (chemicalApplication, index) => {
        const commodity = find(this.props.commodities, {id: get(chemicalApplication, 'commodityId')});
        const obj = {
          id: index + 1,
          commodityId: get(chemicalApplication, 'commodityId'),
          commodityIdOptions: chemicalApplications.map(chemicalApplicationObj => chemicalApplicationObj.commodityId),
          commodity: commodity,
          gradeId: undefined,
          gradeIds: spreadDetails.map(detail => detail.gradeId),
          gradeName: undefined,
          applicationRate: undefined,
          errors: []
        }
        chemicalApplicationDetails.push(obj);
      })
    }
    this.setState({blendGradeDetails: blendGradeDetails, chemicalApplications: chemicalApplicationDetails})
  }

  hasSelectedBaseEntity() {
    return !isEmpty(this.state.selectedBaseEntity);
  }

  appendGeneralConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.generalConditions.value))
      newState.fields.generalConditions.value = newState.selectedGeneralConditionText;
    else if (!includes(newState.fields.generalConditions.value, newState.selectedGeneralConditionText))
      newState.fields.generalConditions.value = newState.fields.generalConditions.value + '\n\n' + newState.selectedGeneralConditionText;
    this.setState(newState);
  }

  appendSpecialConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.specialConditions.value))
      newState.fields.specialConditions.value = newState.selectedSpecialConditionText;
    else if (!includes(newState.fields.specialConditions.value, newState.selectedSpecialConditionText))
      newState.fields.specialConditions.value = newState.fields.specialConditions.value + '\n\n' + newState.selectedSpecialConditionText;
    this.setState(newState);
  }

  appendCustomCondition(e, field) {
    const newState = { ...this.state };
    newState.fields[field].value = e.target.value;
    this.setState(newState);
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = null;
    if (id === 'generalConditionsSelector') {
      key = 'selectedGeneralConditionText';
    } else if (id === 'specialConditionsSelector') {
      key = 'selectedSpecialConditionText';
    }
    const newState = { ...this.state };
    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }
    this.setState(newState);
  }

  handleSiteChange(value, id, item) {
    var collectionKey = null;
    var addressKey = null;
    if (id.search('consignor') > -1) {
      collectionKey = 'consignorSites';
      addressKey = 'consignorAddress';
    } else if (id.search('consignee') > -1) {
      collectionKey = 'consigneeSites';
      addressKey = 'consigneeAddress';
    }

    if (item) {
      if (get(item, 'storageType') === 'system') {
        set(this.state, addressKey + '.value', item.location.address);
      } else {
        set(this.state, addressKey + '.value', item.address);
      }
      this.setFieldValue(id.replace('locationId', 'locationType'), item.entity);
      this.fetchDistance();
    } else {
      this.setFieldValue(id.replace('locationId', 'locationType'), undefined);
      this.resetDistanceAndTimeState();
    }
    if (collectionKey === 'consignorSites') {
      set(this.state, 'selectedConsignorSite', item);
    } else if (collectionKey === 'consigneeSites') {
      set(this.state, 'selectedConsigneeSite', item);
    }
    this.handleSelectFieldChange(value, id);
  }

  handleConsignorInputChange = (event, value) => {
    this.setState({consignorInputText: value});
  };

  handleConsigneeInputChange = (event, value) => {
    this.setState({consigneeInputText: value});
  };

  handleThroughputCheckbox(newState, item, key) {
    const isCustomerSite = get(this.editOrder, 'customer.company.id') === get(item, 'companyId');
    if ((get(item, 'companyId') === get(this.props, 'currentUser.companyId')) && !isCustomerSite) {
      set(newState, key + 'Checkbox', true);
    } else {
      set(newState, key + 'Checkbox', false);
      set(newState, key + 'Order', false);
    }
    this.setState(newState);
  }

  fetchSiteEmployees(checkpoint, companyId) {
    APIService.companies(companyId)
      .employees()
      .get()
      .then(response => {
        const newState = {...this.state};
        set(newState, `${checkpoint}Employees`, response);
        this.setState(newState);
      })
  }

  handleConsignorChange(event, item) {
    const newState = { ...this.state };
    const value = get(item, 'id');
    this.handleThroughputCheckbox(newState, item, 'throughputOutload');
    this.resetDistanceAndTimeState();
    newState.selectedConsignorId = null
    if (item && item.inputValue) {
      this.handleFarmSideDrawer("consignorSideDrawer", true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    } else if (item) {
      newState.selectedConsignor = item;
      if (find(this.state.consignors, {id: value})) {
        let consignors = filter(this.state.consignors, consignor => consignor.id != value)
        newState.consignors = uniqBy([...consignors, item], 'id');
      } else
        newState.consignors = uniqBy([...this.state.consignors, item], 'id');
    }
    if(this.isDeliveryOrderNumberMandatory()) {
      newState.fields.freightDelivery.orderId.validators = [required()];
      newState.fields.freightDelivery.orderId.errors = [];
    }
    else {
      newState.fields.freightDelivery.orderId.validators = [];
    }
    if (
      this.state.fields.freightPickup.consignor.handlerId.value != value
    ) {
      newState.fields.freightPickup.consignor.site.locationId.value = null;
      newState.fields.freightPickup.consignor.site.locationType.value = null;
      newState.fields.freightPickup.consignor.handlerId.value = value;
      newState.pickupSettings = {};
      if (isObject(item)) {
        if (!this.applyingBaseEntity || (this.isEditForm && this.state.isPopulated)) {
          this.getSites(value, 'farm', 'consignor');
        }
      } else {
        newState.selectedConsignor = undefined;
        newState.consignorSites = [];
      }
      this.setState(newState, () => {
        if(get(item, 'id')) {
          this.fetchPickupSettings(item.id);
          this.fetchDistance();
          this.fetchSiteEmployees('pickup', item.companyId);
        }
        this.setFieldErrors('freightPickup.consignor.handlerId', value);
        this.forceSetFieldErrors('freightPickup.orderId', this.getCheckpointOrderFieldErrors('freightPickup'));
        this.getAvailableTonnageForCustomer(value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, this.isCallOnGrainOrder ? get(this.state, 'sellerDetails.ngrId') : this.state.fields.customer.ngrId.value);
      });
    }
    else
      this.setState(newState);
  }

  handleConsigneeChange(event, item) {
    const newState = { ...this.state };
    this.handleThroughputCheckbox(newState, item, 'throughputInload');
    this.resetDistanceAndTimeState();
    const value = get(item, 'id');
    newState.selectedConsigneeId = null
    if (item && item.inputValue) {
      this.handleFarmSideDrawer("consigneeSideDrawer", true);
      this.setState({
        inputTextFarm: item.inputValue
      });
      return;
    } else if (item) {
      newState.selectedConsignee = item;
      if (find(this.state.consignees, {id: value})) {
        let consignees = filter(this.state.consignees, consignee => consignee?.id != value)
        newState.consignees = uniqBy([...consignees, item], 'id');
      } else
        newState.consignees = uniqBy([...this.state.consignees, item], 'id');
    }
    if(this.isPickupOrderNumberMandatory()) {
      newState.fields.freightPickup.orderId.validators = [required()];
      newState.fields.freightPickup.orderId.errors = [];
    }
    else {
      newState.fields.freightPickup.orderId.validators = [];
    }
    if (
      this.state.fields.freightDelivery.consignee.handlerId.value != value
    ) {
      newState.fields.freightDelivery.consignee.site.locationId.value = null;
      newState.fields.freightDelivery.consignee.site.locationType.value = null;
      newState.fields.freightDelivery.consignee.handlerId.value = value;
      newState.deliverySettings = {};
      if (isObject(item)) {
        if (!this.applyingBaseEntity) {
          this.getSites(value, 'farm', 'consignee');
        }
      } else {
        newState.selectedConsignee = undefined;
        newState.consigneeSites = [];
      }
      this.setState(newState, () => {
        if(get(item, 'id')) {
          this.fetchDeliverySettings(item.id);
          this.fetchDistance();
          this.fetchSiteEmployees('delivery', item.companyId);
        }
        this.setFieldErrors('freightDelivery.consignee.handlerId', value);
        this.forceSetFieldErrors('freightDelivery.orderId', this.getCheckpointOrderFieldErrors('freightDelivery'));
      });
    }
    else
      this.setState(newState);
  }

  async getSites(handlerId, partyType, getSitesFor) {
    let sites = [];
    if (partyType == 'farm') {
      let storageId = getSitesFor == 'consignor' ? get(this.editOrder, 'freightPickup.consignor.sites[0].locationId') : get(this.editOrder, 'freightDelivery.consignee.sites[0].locationId');
      let queryString = '';
      if(storageId)
        queryString = this.isEditForm ? `?amend&storageId=${storageId}`:'';
      queryString = queryString ? queryString + '&with_containers=true' : '?with_containers=true';
      let homeStorages = await APIService.farms(handlerId)
                                         .appendToUrl('storages/home/minimal/').appendToUrl(queryString)
                                         .get(this.props.userToken);
      let farmFields = await APIService.farms(handlerId)
                                       .farm_fields()
                                       .get(this.props.userToken);

      homeStorages = map(homeStorages, storage => {
        return merge(storage, {
          id: storage.id,
        });
      });

      farmFields = map(farmFields, ff => {
        return merge(ff, { entity: 'farmfield' });
      });
      sites = farmFields.concat(homeStorages);
    } else {
      let companySites = await APIService.companies(handlerId)
                                         .company_sites()
                                         .get(this.props.userToken);
      sites = map(companySites, site => {
        return merge(site, { entity: 'companysite' });
      });
      const selectedTrack = get(this.state.selectedBaseEntity, 'trackName');
      if (selectedTrack && getSitesFor == 'consignee') {
        sites = filter(sites, site => {
          if (site.isBhc) {
            return includes(site.tracks, selectedTrack);
          } else {
            return site;
          }
        });
      }
    }
    if (getSitesFor == 'consignor') {
      if (this.isEditForm)
        setTimeout(() => {
          this.setState({ consignorSites: sites }, () => this.updateSelectedStorage('consignor'));
        }, 3000);
      else this.setState({ consignorSites: sites }, () => this.updateSelectedStorage('consignor'));
    } else if (getSitesFor == 'consignee') {
      if (this.isEditForm)
        setTimeout(() => {
          this.setState({ consigneeSites: sites }, () => this.updateSelectedStorage('consignee'));
        }, 3000);
      else this.setState({ consigneeSites: sites }, () => this.updateSelectedStorage('consignee'));
    }
  }

  updateSelectedStorage(checkpointType) {
    const newState = {...this.state};
    if (checkpointType === 'consignor' && this.state.selectedConsignorSite)
      newState.selectedConsignorSite = find(this.state.consignorSites, {id: this.state.selectedConsignorSite.id});
    if (checkpointType === 'consignee' && this.state.selectedConsigneeSite)
      newState.selectedConsigneeSite = find(this.state.consigneeSites, {id: this.state.selectedConsigneeSite.id});
    this.setState(newState);
  }

  fetchPickupSettings = farmId => this.fetchSMSettings('pickupSettings', farmId, this.setPickupOrderMandatory);

  fetchDeliverySettings = farmId => this.fetchSMSettings('deliverySettings', farmId, this.setDeliveryOrderMandatory);

  fetchSMSettings = (siteSettings, farmId, callback) => {
    if(!farmId)
      return
    const service = APIService.company_sites(farmId).appendToUrl('settings/')

    service
      .get()
      .then(settings => this.setState({[siteSettings]: settings}, () => {
        if(callback) callback();
      }));
  };

  setPickupOrderMandatory = () => {
    const newState = {...this.state};
    if(this.isPickupOrderNumberMandatory()) {
      newState.fields.freightPickup.orderId.validators = [required()];
      newState.pickupCheckpointOrderExists = true;
    }
    else {
      newState.fields.freightPickup.orderId.validators = [];
      newState.pickupCheckpointOrderExists = Boolean(this.state.fields.freightPickup.orderId.value);
    }
    this.setState(newState, () => {
      this.forceSetFieldErrors('freightPickup.orderId', this.getCheckpointOrderFieldErrors('freightPickup'));
    });
  };

  setDeliveryOrderMandatory = () => {
    const newState = {...this.state};
    if(this.isDeliveryOrderNumberMandatory()) {
      newState.fields.freightDelivery.orderId.validators = [required()];
      newState.deliveryCheckpointOrderExists = true;
    }
    else {
      newState.fields.freightDelivery.orderId.validators = [];
      newState.deliveryCheckpointOrderExists = Boolean(this.state.fields.freightDelivery.orderId.value)
    }
    this.setState(newState, () => {
      this.forceSetFieldErrors('freightDelivery.orderId', this.getCheckpointOrderFieldErrors('freightDelivery'));
    });
  };

  canByPassMandatoryOrderNumber = (type) => {
    const { pickupSettings, deliverySettings } = this.state;
    const isPickupPermissionForGrainOwnerAndSite = get(pickupSettings, 'checkpointOrderCreationPermission') === 'grain_owner_and_site_acceptance_from_site'
    const isDeliveryPermissionForGrainOwnerAndSite = get(deliverySettings, 'checkpointOrderCreationPermission') === 'grain_owner_and_site_acceptance_from_site'
    const isPickupPermissionForSiteOnly = get(pickupSettings, 'checkpointOrderCreationPermission') === 'site_only'
    const isDeliveryPermissionForSiteOnly = get(deliverySettings, 'checkpointOrderCreationPermission') === 'site_only'
    const pickupAcceptance = Boolean(get(pickupSettings, 'pickupOrderNumberRequired') && isPickupPermissionForGrainOwnerAndSite)
    const deliveryAcceptance = Boolean(get(deliverySettings, 'deliveryOrderNumberRequired') && isDeliveryPermissionForGrainOwnerAndSite)
    const pickupOrderNumberRequiredWithSiteApproval = Boolean(get(pickupSettings, 'pickupOrderNumberRequired') && (isPickupPermissionForSiteOnly || isPickupPermissionForGrainOwnerAndSite))
    const deliveryOrderNumberRequiredWithSiteApproval = Boolean(get(deliverySettings, 'deliveryOrderNumberRequired') && (isDeliveryPermissionForSiteOnly || isDeliveryPermissionForGrainOwnerAndSite))
    const canPickupSiteUserBypass = Boolean(type === 'pickup' && this.props.currentUser.companyId === deliverySettings.companyId && pickupAcceptance)
    const canDeliverySiteUserBypass = Boolean(type === 'delivery' && this.props.currentUser.companyId === pickupSettings.companyId && deliveryAcceptance)
    const canProviderOrCustomerUserBypass = Boolean((this.isFreightProviderCreator() || this.isCustomerCreator()) && ((pickupAcceptance && this.props.currentUser.companyId !== pickupSettings.companyId) ^ (deliveryAcceptance && this.props.currentUser.companyId !== deliverySettings.companyId)))
    const canSellerUserBypass = Boolean(get(this.state, 'seller.companyId') === this.props.currentUser.companyId) && (pickupAcceptance && this.props.currentUser.companyId !== pickupSettings.companyId && (!deliveryAcceptance || this.props.currentUser.companyId === deliverySettings.companyId))
    const canBuyerUserBypass = Boolean(get(this.state, 'buyer.companyId') === this.props.currentUser.companyId) && (deliveryAcceptance && this.props.currentUser.companyId !== deliverySettings.companyId && (!pickupAcceptance || this.props.currentUser.companyId === pickupSettings.companyId))
    const canUserBypassIfOrderCreatorSameAsSite = Boolean((type === 'pickup' && get(this.editOrder, 'createdBy.company')?.id === pickupSettings.companyId && pickupOrderNumberRequiredWithSiteApproval) ||
    (type === 'delivery' && get(this.editOrder, 'createdBy.company')?.id === deliverySettings.companyId && deliveryOrderNumberRequiredWithSiteApproval)) && this.props.currentUser.companyId !== get(this.editOrder, 'createdBy.company')?.id
    if (canUserBypassIfOrderCreatorSameAsSite)
      return true;
    if (canPickupSiteUserBypass || canDeliverySiteUserBypass || canProviderOrCustomerUserBypass || canSellerUserBypass || canBuyerUserBypass){
      if (type === 'pickup')
        return pickupAcceptance;
      if (type === 'delivery')
        return deliveryAcceptance;
    }
    return false;
  }

  isPickupOrderNumberMandatory = (byPass=true) => {
    let shouldApplyOnAnyParentOrder = Boolean(!this.isEditForm && this.state.selectedBaseEntity?.id && this.state.selectedConsignor?.id !== this.state.selectedBaseEntity?.consignorHandlerId)
    if(!shouldApplyOnAnyParentOrder && (this.isFreightAllocation() || this.isSelectedOrderCallOnGrain()))
      return false;
    if(this.isParentRequestOrder && this.state.isLinkedPickupOrder)
      return true
    if (byPass && this.canByPassMandatoryOrderNumber('pickup'))
      return false

    const { pickupSettings } = this.state;

    return get(pickupSettings, 'pickupOrderNumberRequired') &&
      this.props.currentUser.companyId !== pickupSettings.companyId &&
      this.isAllowedForTruckCompany(pickupSettings);
  };

  isDeliveryOrderNumberMandatory = (byPass=true) => {
    let shouldApplyOnAnyParentOrder = Boolean(!this.isEditForm && this.state.selectedBaseEntity?.id && this.state.selectedConsignee?.id !== this.state.selectedBaseEntity?.consigneeHandlerId)
    if(!isEmpty(this.state.deliveryOrderNumbers))
      return true
    if(!shouldApplyOnAnyParentOrder && (this.isFreightAllocation() || this.isSelectedOrderCallOnGrain()))
      return false;
    if(this.isParentRequestOrder && this.state.isLinkedDeliveryOrder)
      return true
    if (byPass && this.canByPassMandatoryOrderNumber('delivery'))
      return false

    const { deliverySettings } = this.state;

    return get(deliverySettings, 'deliveryOrderNumberRequired') &&
      this.props.currentUser.companyId !== deliverySettings.companyId &&
      this.isAllowedForTruckCompany(deliverySettings);
  };

  isAllowedForTruckCompany = settings => {
    const allowedTruckCompanyIds = settings?.allowedTruckCompanyIds

    if(!allowedTruckCompanyIds || isEmpty(allowedTruckCompanyIds) || !this.state.fields.providerId.value)
      return true

    return includes(allowedTruckCompanyIds, this.state.fields.providerId.value)
  }

  isProviderFieldDisabled() {
    let isCurrentUserSameAsParty = this.state.fields.customer.companyId.value === get(this.props, 'currentUser.companyId');
    let result = this.isEditForm || this.disableForm
    let isFreightOrderAllocated = get(this.editOrder, 'freightAllocationsTonnage') > 0
    return isFreightOrderAllocated || (isCurrentUserSameAsParty ? result && !(includes(['open', 'confirmed'], this.state.orderStatus)) : result)
  }

  handleProviderChange(value, id, item) {
    const newState = { ...this.state };
    let isCurrentUserSameAsParty = get(item, 'id') === get(this.props, 'currentUser.companyId') && !this.isEditForm;
    newState.fields.assignToId.value = null;
    if (isCurrentUserSameAsParty) {
      newState.fields.assignToId.value = this.props.currentUser.id ;
    }

    newState.fields.providerId.value = value;
    let isSelf = false;
    if (item) {
      if (newState.fields.customer.companyId.value) {
        const customer = newState.fields.customer.company;
        if (customer && ((customer.entity === 'farm' && item.id === customer.companyId) || (customer.entity === 'company' && item.id === customer.id))) {
          isSelf = true;
        }
      }
    } else {
      newState.providerEmployees.length = 0;
    }

    newState.fields.isSelf.value = isSelf;
    this.setValidatorsForIsSelf(isSelf, newState);
    this.setConsignorConsigneeValidators(newState);
    this.setState(newState, () => {
      this.setFieldErrors(id, value);
      this.handleSideDrawer();
      if (item) {
        this.getCompanyEmployees(item.id);
      }
    });
    if (isSelf && !this.isEditForm) {
      this.displaySelfOrderAlert();
    }
    this.setPickupOrderMandatory()
    this.setDeliveryOrderMandatory()
  }

  displaySelfOrderAlert() {
    let scenariosForSelfOrder = '<li>' + SCENARIOS_FOR_SELF_ORDER.join('</li><li>');
    alertifyjs.alert(
      'Warning',
      `<div><p>You are creating a Freight Order where the Customer is same as Freight Provider. You should only create such Freight Orders in one of the following situations:</b></p><ul>${scenariosForSelfOrder}</ul><div>If any of the above situations does not hold true, then you should directly select the Freight Provider to whom the Freight Order will be sent.</div></div>`,
      () => {},
    );
  }

  async getCompanyEmployees(companyId, newContactId) {
    const employees = await APIService.companies(companyId).employees().get(this.props.userToken);
    let providerKeyContactId = null;
    const newState = { ...this.state };
    if(!this.isEditForm)
      providerKeyContactId = this.getKeyContactId(employees, companyId, 'company');
    if(providerKeyContactId)
      set(newState.fields, 'assignToId.value', providerKeyContactId);
    if(newContactId)
      set(newState.fields, 'assignToId.value', newContactId);

    newState.providerEmployees = employees;
    this.setState(newState);
  }

  handleCommodityChange(value, id) {
    if (this.state.fields.commodityId.value != value) {
      const newState = { ...this.state };
      if (this.hasQuantityBasedCommodity(value))
        newState.fields.quantity.validators = [required()];
      else {
        newState.fields.quantity.validators = [];
        newState.fields.quantity.errors = [];
        newState.fields.quantity.value = undefined;
        newState.fields.quantity.errors = [];
      }
      newState.fields.commodityId.value = value;
      newState.fields.varietyId.value = null;
      this.setState(newState, () => {
        this.setFieldErrors(id, value);
        this.forceSetFieldErrors('freightPickup.orderId', this.getCheckpointOrderFieldErrors('freightPickup'));
        this.forceSetFieldErrors('freightDelivery.orderId', this.getCheckpointOrderFieldErrors('freightDelivery'));
      });
    }
  }

  handleVarietyChange(value) {
    if (this.state.fields.varietyId.value != value) {
      const newState = { ...this.state };
      newState.fields.varietyId.value = value || null;
      this.setState(newState);
    }
  }

  handleGradeChange(valueObject, id) {
    if (valueObject.id) {
      this.setFieldValue(id, valueObject.id);
      this.setFieldErrors(id, valueObject.id);
      this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, valueObject.id, this.state.fields.season.value, this.isCallOnGrainOrder ? get(this.state, 'sellerDetails.ngrId') : this.state.fields.customer.ngrId.value);
    }
  }

  isMeterCubeCommodityCommodity(commodityId) {
    const _id = commodityId || this.state.fields.commodityId.value;
    return get(this.getSelectedCommodity(_id), 'isStrictQuantityBased')
  }

  handleCustomerChange(value, id, item) {
    const isCustomerOnlyEdit = this.isEditForm && get(this.editOrder, 'typeId') === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY;
    let isCurrentUserSameAsParty = get(item, 'id') === get(this.props, 'currentUser.companyId') && !this.isEditForm;;
    if (!this.isEditForm || isCustomerOnlyEdit) {
      const newState = { ...this.state };
      let isSelf = false;
      if (this.state.fields.customer.companyId.value !== value) {
        newState.fields.customer.contactId.value = null;
        newState.fields.customer.ngrId.value = null;
        newState.fields.customer.company = null;
        newState.fields.customer.companyId.value = value;
        newState.fields.customer.representedById.value = get(item, 'representedById');
      }

      if (item) {
        if (isCurrentUserSameAsParty) {
          newState.fields.customer.contactId.value = this.props.currentUser.id;
        }
        newState.fields.customer.company = item;
        newState.selectedCustomer = item;
        if (newState.fields.providerId.value) {
          if (
            (item.entity === 'farm' && newState.fields.providerId.value === item.companyId) ||
            (item.entity === 'company' && newState.fields.providerId.value === item.id)
          ) {
            isSelf = true;
          }
        }
        newState.fields.isSelf.value = isSelf;
        newState.fields.paymentTermId.value = get(item, 'paymentTermId') || get(item, 'company.paymentTermId');
        if(!this.isCallOnGrainOrder) this.setValidatorsForIsSelf(isSelf, newState);
      } else {
        newState.fields.isSelf.value = false;
        newState.customerContacts.length = 0;
        newState.customerNgrs.length = 0;
        newState.selectedCustomer = undefined;
      }
      this.setConsignorConsigneeValidators(newState);
      this.setState(newState, async () => {
        this.setFieldErrors(id, value);
        this.handleSideDrawer();
        if (!this.applyingBaseEntity && item) {
          await this.getCustomerContacts(item);
          this.getCustomerNgrs(this.state.fields.customer.companyId.value);
          this.setCustomerContactAndNgr();
        }
      });
      if (isSelf && !this.isEditForm) {
        this.displaySelfOrderAlert();
      }
    }
  }

  setCustomerContactAndNgr() {
    if (this.state.fields.customer.companyId.value) {
      const newState = { ...this.state };
      let customerContactId = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId')) ? get(this.state, 'sellerDetails.contactId', get(this.state.selectedBaseEntity, 'seller.contactId')) :
                              newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId')) ? get(this.state, 'buyerDetails.contactId', get(this.state.selectedBaseEntity, 'buyer.contactId')) : null;
      let customerNgrId = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId')) ? get(this.state, 'sellerDetails.ngrId', get(this.state.selectedBaseEntity, 'seller.ngrId')) :
                          newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId')) ? get(this.state, 'buyerDetails.ngrId', get(this.state.selectedBaseEntity, 'buyer.ngrId')) : null;
      let customerContacts = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId') ? get(this.state, 'sellerContacts') :
                             newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId') ? get(this.state, 'buyerContacts') : null;
      let customerNgrs = newState.fields.customer.companyId.value == get(this.state, 'sellerDetails.companyId') ? get(this.state, 'sellerNgrs') :
                         newState.fields.customer.companyId.value == get(this.state, 'buyerDetails.companyId') ? get(this.state, 'buyerNgrs') : null;
      newState.fields.customer.contactId.value = customerContactId || newState.fields.customer.contactId.value;
      newState.fields.customer.ngrId.value = customerNgrId;
      if (customerContacts)
        newState.customerContacts = customerContacts;
      if (customerNgrs)
        newState.customerNgrs = customerNgrs;
      this.setState(newState);
    }
  }

  handleCustomerContactChange(value, id) {
    const newState = { ...this.state };
    newState.fields.customer.contactId.value = value;
    if (this.state.fields.isSelf.value) {
      newState.fields.assignToId.value = value;
    }
    this.setState(newState);
    this.setFieldErrors(id, value);
  }

  handleInvoicingFieldChange(value, id) {
    this.setFieldValue(id, value);
    setTimeout(() => {
      this.calculateCommissionRate();
      this.setRateError();
    }, 100);
    this.setFieldErrors(id, value);
  }

  handleCurrencyChange = value => {
    if (value)
      this.setState({currency: value})
  }

  handleFreightRateChange(event) {
    const value = event.target.value ? event.target.value : undefined;
    this.setFieldValue(event.target.id, value);
    setTimeout(() => {
      this.calculateCommissionRate();
      this.setRateError();
    }, 100);
  }

  calculateCommissionRate() {
    const newState = { ...this.state };
    var commissionRate = 0;
    if (
      newState.fields.invoicing.value === 'Freight Provider to Invoice Broker' ||
      (this.state.selectedBaseEntityRaw && this.state.selectedBaseEntityRaw.entity === 'freightorder')
    ) {
      const rateFreightIn = newState.fields.rateFreightIn.value;
      const rateFreightOut = newState.fields.rateFreightOut.value;
      if (rateFreightIn && rateFreightOut) {
        commissionRate = parseFloat(newState.fields.rateFreightIn.value) - parseFloat(newState.fields.rateFreightOut.value);
        if (commissionRate < 0) {
          commissionRate = 0;
        }
      }
    } else {
      newState.fields.rateFreightIn.value = '';
    }
    newState.commissionRate = commissionRate.toFixed(2);
    this.setState(newState);
  }

  handleSelectFieldChange(value, id) {
    if (id === 'season') {
      this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, value, this.isCallOnGrainOrder ? get(this.state, 'sellerDetails.ngrId') : this.state.fields.customer.ngrId.value);
    }
  if (id === 'customer.ngrId') {
    this.getAvailableTonnageForCustomer(this.state.fields.freightPickup.consignor.handlerId.value, this.state.fields.plannedGradeId.value, this.state.fields.season.value, value);
  }
    this.setFieldValue(id, value);
    this.setFieldErrors(id, value);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleDeliveryOrderNumberChange(event, item) {
    this.setState({deliveryOrder: item})
    this.setFieldValue('freightDelivery.orderNumber', item?.identifier, true);
    this.setFieldValue('freightPickup.date', moment(item?.pickupDate).format('YYYY-MM-DD'), true);
    this.setFieldValue('freightDelivery.date', moment(item?.deliveryDate).format('YYYY-MM-DD'), true);
    this.handleOrderBlur('freightDelivery.orderId', item?.identifier)
  }

  handleThroughputOrderChange(event) {
    const newState = { ...this.state };
    const feeField = event.target.id.replace('Order', '')
    set(newState, event.target.id, event.target.checked);
    set(newState.fields, feeField + '.value', 0)
    this.setState(newState);
  }

  setFieldValue(id, value, validateAfterSet = false, callback) {
    const newState = { ...this.state };
    set(newState.fields, id + '.value', value);
    this.setState(newState, () => {
      if (validateAfterSet) this.setFieldErrors(id, value);
      if (callback) callback();
    });
  }

  handleRateBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
    setTimeout(() => {
      this.calculateCommissionRate();
      this.setRateError();
    }, 100);
  }

  setRateError() {
    const newState = { ...this.state };
    newState.fields.rateFreightOut.errors = [];
    this.setState(newState);
  }

  setFieldErrors(id, value) {
    const newState = { ...this.state };
    if (newState.setFieldErrors && !this.applyingTemplate) {
      this.getFieldErrors(get(newState.fields, id), value);
    }
    this.setState(newState);
  }

  setFieldCustomErrors = (id, msg) => {
    const newState = { ...this.state };
    const field = get(newState.fields, id);
    if (has(field, 'errors') && isArray(field.errors)) {
      if (msg) field.errors.push(msg);
      else field.errors = [];
    }
    this.setState(newState);
  };

  getFieldErrors(field, value) {
    if (has(field, 'value') && has(field, 'validators')) {
      field.errors = [];
      field.validators.forEach(validator => {
        if (validator.isInvalid(value || field.value)) {
          field.errors.push(validator.message);
        }
      });
    }
  }

  setAllFieldErrors() {
    const existingOrderErrors = {pickup: this.state.fields.freightPickup.orderId.errors, delivery: this.state.fields.freightDelivery.orderId.errors};
    const newState = { ...this.state };
    this.applyValidatorsOn(newState.fields);
    this.applyValidatorsOn(newState.fields.freightPickup.consignor);
    this.applyValidatorsOn(newState.fields.freightPickup.consignor.site);
    this.applyValidatorsOn(newState.fields.freightDelivery.consignee);
    this.applyValidatorsOn(newState.fields.freightDelivery.consignee.site);
    this.applyValidatorsOn(newState.fields.customer);
    if(this.isPickupOrderNumberMandatory()) {
      newState.fields.freightPickup.orderId.validators = [required()];
      newState.pickupCheckpointOrderExists = true;
    }
    else {
      newState.fields.freightPickup.orderId.validators = [];
      newState.pickupCheckpointOrderExists = Boolean(this.state.fields.freightPickup.orderNumber.value);
    }
    if(this.isDeliveryOrderNumberMandatory()) {
      newState.fields.freightDelivery.orderId.validators = [required()];
      newState.deliveryCheckpointOrderExists = true;
    }
    else {
      newState.fields.freightDelivery.orderId.validators = [];
      newState.deliveryCheckpointOrderExists = Boolean(this.state.fields.freightDelivery.orderNumber.value)
    }
    this.applyValidatorsOn(newState.fields.freightPickup);
    this.applyValidatorsOn(newState.fields.freightDelivery);
    if (
      newState.fields.plannedTonnage.value &&
      this.hasSelectedBaseEntity() &&
      !isEqual(get(this.state.selectedBaseEntity, 'status'), 'template') &&
      parseFloat(newState.fields.plannedTonnage.value) > parseFloat(this.maximumAllowedTonnage())
    ) {
      if (!this.isMeterCubeCommodityCommodity()) {
        if (get(newState.selectedBaseEntityRaw, 'entity') === 'freightorder') {
          newState.fields.plannedTonnage.errors = [this.EXCESS_ALLOCATION_TONNAGE_ERROR_MESSAGE];
        } else {
          newState.fields.plannedTonnage.errors = [this.getExcessOrderTonnageErrorMessage()];
        }
      }
    }
    if (
      newState.fields.quantity.value &&
      this.hasSelectedBaseEntity() &&
      parseFloat(newState.fields.quantity.value) > parseFloat(this.maximumAllowedTonnage())
    ) {
      if (this.isMeterCubeCommodityCommodity()) {
        if (get(newState.selectedBaseEntityRaw, 'entity') === 'freightorder') {
          newState.fields.quantity.errors = [this.EXCESS_ALLOCATION_QUANTITY_ERROR_MESSAGE];
        } else {
          newState.fields.quantity.errors = [this.EXCESS_ORDER_QUANTITY_ERROR_MESSAGE];
        }
      }
    }
    if (this.isEditForm && parseFloat(newState.fields.plannedTonnage.value) < parseFloat(this.maximumAllowedTonnage())) {
      if (
        !this.isMeterCubeCommodityCommodity() &&
        parseFloat(newState.fields.plannedTonnage.value) < parseFloat(get(this.editOrder, 'minimumAllowedTonnage', 0))
      )
        newState.fields.plannedTonnage.errors = [this.MINIMUM_TONNAGE_ERROR_MESSAGE];
    }
    if (this.isEditForm && parseFloat(newState.fields.quantity.value) < parseFloat(this.maximumAllowedTonnage())) {
      if (this.isMeterCubeCommodityCommodity() && parseFloat(newState.fields.quantity.value) < parseFloat(get(this.editOrder, 'minimumAllowedTonnage', 0)))
        newState.fields.quantity.errors = [this.MINIMUM_QUANTITY_ERROR_MESSAGE];
    }
    if((this.isPickupOrderNumberMandatory() || newState.pickupCheckpointOrderExists) && newState.fields.freightPickup.orderId.errors.length < 1) {
      newState.fields.freightPickup.orderId.errors = existingOrderErrors.pickup;
    }
    if((this.isDeliveryOrderNumberMandatory() || newState.deliveryCheckpointOrderExists) && newState.fields.freightDelivery.orderId.errors.length < 1) {
      newState.fields.freightDelivery.orderId.errors = existingOrderErrors.delivery;
    }
    this.setState(newState);
    window.scrollTo(0, 0);
  }

  focusOnFirstErrorField() {
    const nestedFields = ["sellerDetails.companyId", "sellerDetails.contactId", "buyerDetails.companyId",
                          "buyerDetails.contactId", "customer.companyId", "customer.contactId", "customer.ngrId", "freightPickup.date",
                          "freightPickup.consignor.handlerId", "freightPickup.consignor.site.locationId", "freightPickup.orderNumber",
                          "freightDelivery.date", "freightDelivery.consignee.handlerId", "freightDelivery.consignee.site.locationId",
                          "freightDelivery.orderNumber"];

    const autoCompleteFields = ['externalReferenceNumber', "commodityContractId", "providerId", "assignToId", "commodityId", "plannedGradeId",
                                "paymentTermId", "generalConditionsSelector", "specialConditionsSelector"];

    for(let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];

      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if ((this.fieldsOrder[i] === "customer.companyId" && this.state.fields.customer.companyId.errors.length) ||
            (this.fieldsOrder[i] === 'customer.contactId' && this.state.fields.customer.contactId.errors.length > 0) ||
            (this.fieldsOrder[i] === "customer.ngrId" && this.state.fields.customer.ngrId.errors.length) ||
            (
              this.fieldsOrder[i] === "sellerDetails.companyId" &&
              get(this.state.errors, 'party')
            ) ||
            (
              this.fieldsOrder[i] === "buyerDetails.companyId" &&
              get(this.state.errors, 'party')
            ) ||
            (
              this.fieldsOrder[i] === "sellerDetails.contactId" &&
              get(this.state.errors, 'party')
            ) ||
            (
              this.fieldsOrder[i] === "buyerDetails.contactId" &&
              get(this.state.errors, 'party')
            )) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
        if (
          (this.fieldsOrder[i] === 'freightDelivery.consignee.handlerId' && this.state.fields.freightDelivery.consignee.handlerId.errors.length > 0 && formField.current.node) ||
          (this.fieldsOrder[i] === 'freightPickup.consignor.handlerId' && this.state.fields.freightPickup.consignor.handlerId.errors.length > 0 && formField.current.node)
        ){
          formField.current.node.previousSibling.focus();
          break;
        }
        if (
          (this.fieldsOrder[i] === 'freightPickup.date' && this.state.fields.freightPickup.date.errors.length > 0) ||
          (this.fieldsOrder[i] === 'freightPickup.orderNumber' && this.state.fields.freightPickup.orderNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === 'freightDelivery.date' && this.state.fields.freightDelivery.date.errors.length > 0) ||
          (this.fieldsOrder[i] === 'freightDelivery.orderNumber' && this.state.fields.freightDelivery.orderNumber.errors.length > 0) ||
          (this.fieldsOrder[i] === "freightDelivery.orderNumber" && this.state.fields.freightDelivery.orderId.errors.length) ||
          (this.fieldsOrder[i] === "freightPickup.orderNumber" && this.state.fields.freightPickup.orderId.errors.length)
        ) {
          formField.current?.focus();
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (formField.current.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField.current.focus();
          formField.current.scrollIntoView({ block: 'center' });
          break;
        }
      }
    }
  }

  applyValidatorsOn(fields) {
    forEach(fields, field => {
      this.getFieldErrors(field);
    });
  }

  handleBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
  }

  forceSetFieldErrors(id, errors) {
    const newState = {...this.state};
    set(newState.fields, id + '.errors', errors);
    this.setState(newState);
  }

  handleOrderBlur(id, identifier, force=false) {
    const isPickup = id.includes('freightPickup');
    const checkpointState = isPickup ? this.state.pickupCheckpointOrderExists || this.isPickupOrderNumberMandatory(false)  : this.state.deliveryCheckpointOrderExists || this.isDeliveryOrderNumberMandatory(false);
    if(!checkpointState)
      return;
    const checkpoint = isPickup ? 'Pickup' : 'Delivery';
    const orderField = isPickup ? 'pickupOrder' : 'deliveryOrder';
    if(identifier) {
      const orderIdent = identifier.trim();
      if(orderIdent === get(this.state, `${orderField}.identifier`) && !force) {
        if(!get(this.state.fields, `${id}.value`)) {
          const newState = {...this.state};
          newState.enableSubmit = true
          set(newState, `fields.${id}.value`, get(this.state, `${orderField}.id`));
          this.setState(newState, () => this.forceSetFieldErrors(id, this.getCheckpointOrderFieldErrors('freight' + checkpoint)));
        }
      } else {
        this.forceSetFieldErrors(id, []);
        this.setFieldValue(id, null);
        this.setState({
          [orderField]: null,
          enableSubmit: false,
        }, () => {
          APIService.freights().orders(orderIdent).appendToUrl(`checkpoint-details/`).get().then(response => {
            if(get(response, 'detail') === 'Not found.')
              this.setState({enableSubmit: true}, () => this.forceSetFieldErrors(id, ['Order Number doesn\'t exist']))
            else if(get(response, 'id')) {
              const newState = {...this.state};
              newState.enableSubmit = true
              set(newState, `fields.${id}.value`, response.id);
              set(newState, orderField, response);
              this.setState(newState, () => this.forceSetFieldErrors(id, this.getCheckpointOrderFieldErrors('freight' + checkpoint)));
            }
            else
              this.setState({enableSubmit: true}, () => this.forceSetFieldErrors(id, ['An Error Occurred']))
          });
        });
      }
    } else {
      const newState = { ...this.state }
      newState.enableSubmit = true
      set(newState, `fields.${id}.value`, null)
      set(newState, `fields.${id}.errors`, [])
      set(newState, orderField, null)
      this.setState(newState)
    }
  }

  getExpectedPickupSiteName() {
    return get(this.state, 'selectedConsignor.name');
  }

  getExpectedDeliverySiteName() {
    return get(this.state, 'selectedConsignee.name');
  }

  getCheckpointOrderFieldErrors(checkpointType, fields) {
    const { pickupOrder, deliveryOrder } = this.state;
    fields = fields || this.state.fields
    const isPickup = checkpointType === 'freightPickup';
    const checkpoint = isPickup ? 'Pickup' : 'Delivery';
    const order = isPickup ? pickupOrder : deliveryOrder;
    if(!fields[checkpointType].orderId.value)
      return [];

    if(fields[checkpointType].orderNumber.value && !order)
      return ['Order Number doesn\'t exist'];

    const expectedSiteName = isPickup ? this.getExpectedPickupSiteName() : this.getExpectedDeliverySiteName();
    const orderSiteName = isPickup ? order.consignorName || get(order, 'freightPickup.consignor.handler.displayName') : order.consigneeName || get(order, 'freightDelivery.consignee.handler.displayName');
    const errors = [];
    const parentOrder = this.selectedOrder || get(this.editOrder, 'parentOrder')
    if(fields.commodityId.value !== order.commodityId)
      errors.push(`Commodity of this order is not same as that of ${checkpoint} order's`);

    if(expectedSiteName !== orderSiteName)
      errors.push(`${checkpoint} Site of this order is not same as that of ${checkpoint} order's`);

    const {relatedEntity, tonnages} = this.getEntityTonnages(checkpoint === 'Pickup' ? 'pickup order' : 'delivery order');
    let relatedEntityDisplay = get(relatedEntity, 'entity') === "commodityContract" ? 'Contract' : 'Order';
    let tonnageDistributionLink = '';
    if (get(relatedEntity, 'canAccessOrder') || includes(get(relatedEntity, 'viewerCompanyIds') || [], get(currentUserCompany (), 'id'))) {
      const tonnageDistributionData = <React.Fragment>
        <div className='order-tonnage-distribution'>
          <EntityTonnageDetail
            entity={relatedEntity}
            openHierarchyFor={this.openHierarchyFor}
            columns={2}
            labelStyle={{color: WARM_GREY, fontSize: '0.8rem', fontWeight: '300'}}
            valueStyle={{fontSize: '0.8rem'}}
            headerStyle={{fontSize: '0.8rem', paddingLeft: '0px'}}
            entityDisplay={relatedEntityDisplay}
            unit={this.priceUnit()}
            items={tonnages}
          />
        </div>
      </React.Fragment>
      tonnageDistributionLink = <CommonToolTip title={tonnageDistributionData} placement="right" arrow><a rel="noopener noreferrer" style={{textDecoration: "underline", cursor: 'pointer'}}>How?</a></CommonToolTip>
    }

    if(!parentOrder && isPickup && fields.plannedTonnage.value < order.plannedTonnage && order.typeId < 4 && !get(order, 'externalArgs') && (!get(order, 'commodityContractId') || (get(order, 'commodityContractId') !== get(this.state, 'selectedBaseEntity.commodityContractId'))))
      errors.push(<div>{`${this.tonnageLabel} of ${checkpoint} order is greater than planned ${this.tonnageLabel.toLowerCase()}`} {tonnageDistributionLink}</div>);

    let orderRemainingTonnage = order.unaccountedTonnage
    if(this.isEditForm){
      orderRemainingTonnage = orderRemainingTonnage +  get(this.editOrder, 'plannedTonnage', 0)
    }

    if(!parentOrder && isPickup && fields.plannedTonnage.value > orderRemainingTonnage && order.typeId >= 4)
      errors.push(<div>{`Remaining ${this.tonnageLabel} of ${checkpoint} order is less than planned ${this.tonnageLabel.toLowerCase()}`} {tonnageDistributionLink}</div>);

    if(!parentOrder && !isPickup && fields.plannedTonnage.value > orderRemainingTonnage)
      errors.push(<div>{`Remaining ${this.tonnageLabel} of ${checkpoint} order is less than planned ${this.tonnageLabel.toLowerCase()}`} {tonnageDistributionLink}</div>);

    return errors;
  }

  pickUpDateMax() {
    return this.state.fields.freightDelivery.date.value ? new Date(this.state.fields.freightDelivery.date.value) : null;
  }

  pickUpDateMin() {
    if(!isEmpty(this.state.pickupOrder))
      return new Date(this.state.pickupOrder.pickupDate);
  }

  deliveryDateMax() {
    if(!isEmpty(this.state.deliveryOrder))
      return new Date(this.state.deliveryOrder.deliveryDate);
  }

  deliveryDateMin() {
    let minimumDate = this.state.fields.freightPickup.date.value ? new Date(this.state.fields.freightPickup.date.value) : null;
    if (this.isEditForm) {
      const pickupDate = get(this.editOrder, 'freightPickup.date');
      minimumDate = pickupDate ? moment(pickupDate) : minimumDate;
    }
    return minimumDate;
  }

  handleSelfOrderChange(event) {
    if (this.state.fields.isSelf.value != event.target.checked) {
      const newState = { ...this.state };
      newState.fields.isSelf.value = event.target.checked;
      if (event.target.checked) {
        newState.fields.providerId.value = this.props.currentUser.companyId;
        newState.fields.rateFreight.value = 0;
        newState.fields.invoicing.validators = [];
        newState.fields.paymentTermId.validators = [];
        newState.fields.rateFreight.validators = [];
      } else {
        newState.fields.invoicing.validators = [required()];
        newState.fields.paymentTermId.validators = [required(), selected()];
        newState.fields.rateFreight.validators = [required()];
      }

      this.setState(newState);
    }
  }

  setValidatorsForIsSelf(isSelf, newState) {
    if(!isSelf)
      newState.fields.paymentTermId.validators = [selected()];
    this.setProviderAndInvoicingValidators(newState);
    return newState;
  }

  async getCustomerNgrs(customerId, newlyCreatedNgr) {
    let customerNgrs = await APIService.companies(customerId).ngrs().appendToUrl('minimal/').get(this.props.userToken);
    const newState = {...this.state};
    newState.customerNgrs = customerNgrs;
    if (newlyCreatedNgr) {
      newState.fields.customer.ngrId.value = newlyCreatedNgr.id;
    }
    newState.customerNgrs = customerNgrs;
    this.setState(newState);
  }

  handleCancelButtonClick(event) {
    event.preventDefault();
    if (this.isEditForm && this.editOrderId) {
      window.history.back();
      setTimeout(() => window.location.reload(), 100);
    } else {
      window.location.hash = '/orders/freights';
    }
  }

  handleIdentifierChange(event) {
    const regex = new RegExp('^[a-zA-Z0-9-]*$');
    if (regex.test(event.target.value)) {
      this.setFieldValue(event.target.id, event.target.value);
    } else {
      this.setFieldValue(event.target.id, this.state.fields.identifier.value);
    }
  }

  applyFiltersByParty = (value, id, item) => {
    if (this.state.filterParty !== value) {
      let filteredContracts = null;
      if (item) {
        filteredContracts = filter(this.state.contracts, contract => {
          return includes([contract.seller.companyId, contract.buyer.companyId], item.id);
        });
      } else {
        filteredContracts = this.state.contracts;
      }
      this.setState({ filteredContracts: filteredContracts, filterParty: value });
    }
  };

  resetFields(newState) {
    if(this.isEditingGrainOrder())
      return;
    if (!this.isParentRequestOrder) {
      newState.fields.season.value = undefined;
      newState.fields.commodityId.value = undefined;
      newState.fields.varietyId.value = undefined;
      newState.fields.plannedGradeId.value = undefined;
      newState.fields.season.errors = [];
      newState.fields.commodityId.errors = [];
      newState.fields.varietyId.errors = [];
      newState.fields.plannedGradeId.errors = [];
    }
    newState.fields.providerId.value = undefined;
    newState.fields.assignToId.value = undefined;
    newState.fields.providerId.errors = [];
    newState.fields.assignToId.errors = [];
    newState.fields.customer.companyId.validators = [required(), selected()];
    newState.fields.customer.contactId.validators = [required(), selected()];
    newState.throughputInloadOrder = false;
    newState.throughputOutloadOrder = false;
    newState.throughputInloadCheckbox = false;
    newState.throughputOutloadCheckbox = false;
    if (!this.isParentRequestOrder) {
      this.setConsignorValidator(newState, []);
      this.setConsigneeValidator(newState, []);
      this.resetFreightPickup(newState);
      this.resetFreightDelivery(newState);
    }
  }

  resetFreightPickup(newState) {
    newState.selectedConsignor = null
    newState.fields.freightPickup.date.value = undefined;
    newState.fields.freightPickup.consignor.handlerId.value = undefined;
    newState.fields.freightPickup.consignor.site.locationId.value = undefined;
    newState.fields.freightPickup.date.errors = [];
    newState.fields.freightPickup.consignor.handlerId.errors = [];
    newState.fields.freightPickup.consignor.site.locationId.errors = [];
  }

  resetFreightDelivery(newState) {
    newState.selectedConsignee = null
    newState.fields.freightDelivery.date.value = undefined;
    newState.fields.freightDelivery.consignee.handlerId.value = undefined;
    newState.fields.freightDelivery.consignee.site.locationId.value = undefined;
    newState.fields.freightDelivery.date.errors = [];
    newState.fields.freightDelivery.consignee.handlerId.errors = [];
    newState.fields.freightDelivery.consignee.site.locationId.errors = [];
  }

  async getContractsList() {
    if (isEmpty(this.state.contracts)) {
      this.setState({ didFetchedContracts: true });
      let contractsService = APIService.contracts();
      let params = this.isCallOnGrainOrder ? 'minimalistic/?status=open&status=in_progress&status=confirmed&status=delivered&cog=true' :
                   'minimalistic/?status=open&status=in_progress&status=confirmed&status=delivered';
      contractsService.appendToUrl(params);
      const contracts = await contractsService.get(this.props.userToken, {}, null);
      this.setState({ contracts: filter(contracts, 'canCreateFreightOrder')});
      this.props.dispatch(forceStopLoader());
    }
  }

  getProviders() {
    const { allDirectoryCompanies } = this.props;
    const { selectedBaseEntity, fields } = this.state;
    const baseEntityType = get(selectedBaseEntity, 'entity');
    let exclusionId = get(selectedBaseEntity, 'deliveryOnus', '') === "Buyer" ? get(selectedBaseEntity, 'buyer.companyId'): get(selectedBaseEntity, 'seller.companyId') ;
    if (baseEntityType === 'order') {
      const order = this.selectedOrder;
      if (!this.isSelectedOrderCallOnGrain() && !this.state.isDependent) {
        const excludeParties = [
          exclusionId,
          get(order, 'providerId'),
          get(order, 'parentOrder.providerId'),
          get(order, 'parentOrder.customer.companyId'),
          get(selectedBaseEntity, 'customerCompany.id'),
          get(fields.customer, 'company.id'),
          get(order, 'customer.companyId'),
        ];
        return filter(allDirectoryCompanies, provider => !includes(excludeParties, provider.id));
      }
    }

    return allDirectoryCompanies || [];
  }

  getCustomers() {
    if (this.selectedOrderId && this.state.isDependent && !get(this.state.selectedBaseEntity, 'customerCompanyId')) {
      let seller = get(this.state.selectedBaseEntity, 'seller.company');
      let buyer = get(this.state.selectedBaseEntity, 'buyer.company');
      set(seller, 'representedById', get(this.state.selectedBaseEntity, 'seller.representedById'));
      set(buyer, 'representedById', get(this.state.selectedBaseEntity, 'buyer.representedById'));
      return [seller, buyer];
    }
    return this.props.allDirectoryCompanies || [];
  }

  // eslint-disable-next-line no-unused-vars
  async getCustomerContacts(company, _='', customerContactId='', representedById) { // eslint-disable-line no-unused-vars
    const contacts = await this.getContacts(company, representedById);
    let customerKeyContactId = this.state.isDependent ? (customerContactId || this.getKeyContactId(contacts, company.id)) : null;
    this.setState({customerContacts: contacts}, () => {
      if(customerKeyContactId && !this.state.fields.customer.contactId.value) {
        this.handleSelectFieldChange(customerKeyContactId, 'customer.contactId');
      }
    });
  }

  async getContacts(company, representedById) {
    const queryParams = representedById ? {representing_company_id: representedById} : {};
    return get(company, 'id') ? await APIService.contracts().companies(company.id).contacts().get(this.props.userToken, {}, queryParams) : [];
  }

  getKeyContactId(contacts, entityId) {
    const keyContacts = filter(contacts, {keyContact: true});
    let keyContactId = null;
    if (keyContacts && keyContacts.length === 1) {
      keyContactId = keyContacts[0].id;
    } else {
      const companyKeyContact = find(keyContacts, { companyId: entityId });
      if (companyKeyContact) {
        keyContactId = companyKeyContact.id;
      }
    }
    return keyContactId;
  }

  showProviderAndInvoicingSection = () => {
    return this.isEditForm ? this.showProviderSectionOnEdit() : true;
  };

  showProviderAndInvoicingSectionOnNew = () => {
    let deliveryOnus = get(this.state.selectedBaseEntity, 'deliveryOnus');
    if (
      get(this.state.selectedBaseEntity, 'isSellerRegistered') &&
      get(this.state.selectedBaseEntity, 'isBuyerRegistered') &&
      !get(this.state.selectedBaseEntity, 'isBothPartiesManagedBy')
    ) {
      let skipProviderValidations =
        deliveryOnus &&
        ((deliveryOnus.toLowerCase() === 'seller' && !get(this.state.selectedBaseEntity, 'isSeller')) ||
         (deliveryOnus.toLowerCase() === 'buyer' && !get(this.state.selectedBaseEntity, 'isBuyer')));
      if (skipProviderValidations) {
        return false;
      }
    }
    return true;
  };

  showProviderSectionOnEdit = () => {
    return !!get(this.editOrder, 'providerId');
  };

  showInvoicingSectionOnEdit = () => {
    return get(this.editOrder, 'canEditInvoicingSection');
  };

  setConsignorConsigneeValidators(newState) {
    if (newState.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
      this.setConsignorValidator(newState, [required()]);
      this.setConsigneeValidator(newState, [required()]);
    } else if (newState.selectedBaseEntity) {
      if (newState.selectedBaseEntityRaw.entity === 'contract' || this.isSelectedOrderCallOnGrain()) {
        const contract =
          newState.selectedBaseEntityRaw.entity === 'contract' ? newState.selectedBaseEntity : get(this.selectedOrder, 'commodityContract');
        if (contract) {
          const pricePointId = get(contract, 'pricePointId');
          const consignorsWithSites = get(newState.selectedBaseEntity, 'consignorsWithSites');
          if (includes(PICKUP_MANDATORY_PRICE_POINTS, pricePointId)) {
            this.setConsignorValidator(newState, [required()]);
            if(consignorsWithSites && consignorsWithSites.length > 1)
              newState.isConsignorEditable = true;
            else
              newState.isConsignorEditable = false;
          } else {
            this.setConsignorValidator(newState, []);
            newState.isConsignorEditable = true;
          }
          if (includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId)) {
            this.setConsigneeValidator(newState, [required()]);
            newState.isConsigneeEditable = false;
          } else {
            this.setConsigneeValidator(newState, []);
            newState.isConsigneeEditable = true;
          }
          const consigneesWithSites = get(newState.selectedBaseEntity, 'consigneesWithSites');
          if (
            consigneesWithSites &&
            consigneesWithSites.length > 1 &&
              includes([PRICE_POINTS.DELIVERED_BUYER, PRICE_POINTS.DELIVERED_SITE, PRICE_POINTS.DPU], pricePointId)
          ) {
            newState.isConsigneeEditable = true;
          }

          if((contract.isSeller) || get(this.selectedOrder, 'isSeller')){
            if (newState.fields.isSelf.value && contract.seller.companyId == newState.fields.providerId.value) {
              this.setConsignorValidator(newState, [required()]);
            } else if (!this.isEditingGrainOrder() && (!includes(DELIVERY_MANDATORY_PRICE_POINTS, pricePointId) || !this.isCallOnGrainOrder)) {
              this.setConsignorValidator(newState, [required()]);
            }
            if (newState.fields.providerId.value) {
              this.setConsigneeValidator(newState, [required()]);
            } else {
              this.setConsigneeValidator(newState, []);
            }
          } else if((contract.isBuyer) || get(this.selectedOrder, 'isBuyer')) {
            if (newState.fields.isSelf.value && contract.buyer.companyId == newState.fields.providerId.value) {
              this.setConsigneeValidator(newState, [required()]);
            } else if (!this.isEditingGrainOrder() && (!includes(PICKUP_MANDATORY_PRICE_POINTS, pricePointId) || !this.isCallOnGrainOrder)) {
              this.setConsigneeValidator(newState, [required()]);
            }
            if (newState.fields.providerId.value) {
              this.setConsignorValidator(newState, [required()]);
            } else {
              this.setConsignorValidator(newState, []);
            }
          } else {
            this.setConsignorValidator(newState, [required()]);
            this.setConsigneeValidator(newState, [required()]);
          }
        }
      }
    }
  }

  setConsignorValidator(newState, validators) {
    newState.fields.freightPickup.consignor.handlerId.validators = validators;
  }

  setConsigneeValidator(newState, validators) {
    newState.fields.freightDelivery.consignee.handlerId.validators = validators;
  }

  handleOpenPreview = () => {
    this.setState({ preview: true });
  };

  handleClosePreview = () => {
    this.setState({ preview: false });
  };

  showInvoicingField = () => this.isEditForm ? this.showInvoicingSectionOnEdit() : this.showProviderAndInvoicingSection();

  showPaymentTermField = () => this.isEditForm ? this.showInvoicingSectionOnEdit() : this.showProviderAndInvoicingSection();

  showFreightRateInField = () => {
    const showInvoicing = this.isEditForm ? this.showInvoicingSectionOnEdit() : this.showProviderAndInvoicingSection();
    const isAllocatedOrder = get(this.editOrder, 'parentOrder.providerId') === this.props.currentUser.companyId;
    return (
      this.state.fields.providerId.value !== this.props.currentUser.companyId &&
      showInvoicing &&
      (!((this.isEditForm && !get(this.editOrder, 'commissionRate') && !get(this.editOrder, 'parentOrderId')) || !this.selectedOrderId) ||
      (isAllocatedOrder && this.isEditForm))
    );
  };

  showFreightRateOutField = () => this.isEditForm ? this.showInvoicingSectionOnEdit() : this.showProviderAndInvoicingSection();

  isFreightRateAsLabel() {
    return (
      this.state.fields.isSelf.value ||
      this.state.fields.providerId.value === this.props.currentUser.companyId ||
      (this.isEditForm && !get(this.editOrder, 'commissionRate') && !get(this.editOrder, 'parentOrderId')) ||
      !this.selectedOrderId ||
      this.isSelectedOrderCallOnGrain()
    );
  }

  getFreightRateOutFieldLabel = () => {
    return this.isFreightRateAsLabel() ? 'Freight Rate (Optional)' : 'Freight Rate Out (Optional)';
  };

  showOversRateField = () => {
    const showInvoicing = this.isEditForm ? this.showInvoicingSectionOnEdit() : this.showProviderAndInvoicingSection();
    return showInvoicing && this.state.fields.rateOvers.value;
  };

  showCommissionRateField = () => {
    const showInvoicing = this.isEditForm ? this.showInvoicingSectionOnEdit() : this.showProviderAndInvoicingSection();
    return (
      !this.state.fields.isSelf.value &&
      this.state.fields.providerId.value !== this.props.currentUser.companyId &&
      showInvoicing &&
      !((this.isEditForm && !get(this.editOrder, 'commissionRate') && !get(this.editOrder, 'parentOrderId')) || !this.selectedOrderId)
    );
  };

  getEstablishmentName = establishment => get(establishment, 'displayName') || get(establishment, 'name');

  getPreviewFields = () => {
    const fields = this.state.fields;
    const canViewPickup = this.editOrder?.id ? this.editOrder.canViewPickup : true
    const canViewDelivery = this.editOrder?.id ? this.editOrder.canViewDelivery : true
    const currentUserCompany = this.isEditForm ? get(this.editOrder, 'createdBy.company') : this.props.currentUser.company;
    const customer = fields.customer;
    const freightRateIn = fields.rateFreightIn.value ? `${this.state.currency} ${parseFloat(fields.rateFreightIn.value).toFixed(2)}` : undefined;
    const freightRateOut = fields.rateFreightOut.value ? `${this.state.currency} ${parseFloat(fields.rateFreightOut.value).toFixed(2)}` : undefined;
    const rateOvers = fields.rateOvers.value ? `${this.state.currency} ${parseFloat(fields.rateOvers.value).toFixed(2)}` : undefined;
    const freightRateOutLabel = this.getFreightRateOutFieldLabel();
    const submitData = this.getSubmitData();
    const freightContractDetails = {
      ...(this.showInvoicingField() && { Invoicing: fields.invoicing.value }),
      ...(this.showPaymentTermField() && { 'Payment Term': get(find(this.props.paymentTerms, { id: fields.paymentTermId.value }), 'name') }),
      ...(this.showFreightRateInField() && { 'Freight Rate In': freightRateIn }),
    };
    const consignorHandlerId = get(submitData, 'freightPickup.consignor.handlerId');
    const consigneeHandlerId = get(submitData, 'freightDelivery.consignee.handlerId');
    const consignor = this.getEstablishment(consignorHandlerId, null, this.state.consignors);
    const consignee = this.getEstablishment(consigneeHandlerId, null, this.state.consignees);

    const pickupLocationId = get(submitData, 'freightPickup.consignor.sites.0.locationId');
    const pickupLocationType = get(submitData, 'freightPickup.consignor.sites.0.locationType');
    const pickupSite = this.getEstablishment(pickupLocationId, pickupLocationType, this.state.consignorSites);
    const pickupStorage = get(find(this.state.consignorSites, {id: this.state.fields.freightPickup.consignor.site.locationId.value}), 'name');
    const deliveryLocationId = get(submitData, 'freightDelivery.consignee.sites.0.locationId');
    const deliveryLocationType = get(submitData, 'freightDelivery.consignee.sites.0.locationType');
    const deliverySite = this.getEstablishment(deliveryLocationId, deliveryLocationType, this.state.consigneeSites);
    const deliveryStorage = get(find(this.state.consigneeSites, {id: this.state.fields.freightDelivery.consignee.site.locationId.value}), 'name');

    const pickupDetails = canViewPickup ? {
      'DELIVERY START DATE': moment(get(submitData, 'freightPickup.date')).format(this.state.countryFormats.dateDisplay),
      'PICKUP SITE': this.getEstablishmentName(consignor),
      'PICKUP STORAGE': pickupStorage,
      'PICKUP SITE PHONE': getSitePhoneNumber(consignor, pickupSite),
      'PICKUP ADDRESS': consignor ? get(consignor, 'address') : '',
      'PICKUP ORDER NO': fields.freightPickup.orderNumber.value,
    } : {};

    const deliveryDetails = canViewDelivery ? {
      'DELIVERY END DATE': moment(get(submitData, 'freightDelivery.date')).format(this.state.countryFormats.dateDisplay),
      'DELIVERY SITE': this.getEstablishmentName(consignee),
      'DELIVERY STORAGE': deliveryStorage,
      'DELIVERY SITE PHONE': getSitePhoneNumber(consignee, deliverySite),
      'DELIVERY ADDRESS': consignee ? get(consignee, 'address') : '',
      'DELIVERY ORDER NO': fields.freightDelivery.orderNumber.value,
    } : {};
    if (!this.isCallOnGrainOrder) {
      pickupDetails['PICKUP INSTRUCTIONS'] = canViewPickup ? get(submitData, 'freightPickup.instructions') : ''
      deliveryDetails['DELIVERY INSTRUCTIONS'] = canViewDelivery ? get(submitData, 'freightDelivery.instructions') : ''
    }

    if (this.showFreightRateOutField()) {
      freightContractDetails[freightRateOutLabel] = freightRateOut;
    }

    if (this.showOversRateField()) {
      freightContractDetails['Overs Rate'] = rateOvers;
    }

    if (this.state.totalDistanceLabel) {
      freightContractDetails['Est. Distance'] = this.state.totalDistanceLabel;
    }

    if (this.state.estimatedTime) {
      freightContractDetails['Est. Travel Time'] = this.state.estimatedTime;
    }

    var providerDetails = {};
    if (this.showProviderAndInvoicingSection()) {
      const providers = this.isEditForm && this.isProviderFieldDisabled() ? this.state.providerItems : this.getProviders();
      providerDetails = {
        provider: find(providers, { id: fields.providerId.value }),
        providerContact: find(this.state.providerEmployees, { id: fields.assignToId.value }),
      };
    }
    const canViewCondition = this.showConditionsSection();
    return {
      priceUnit: this.priceUnit(),
      identifier: this.state.fields.identifier.value,
      contractReferenceNumber: get(this.state.selectedBaseEntityRaw, 'referenceNumber'),
      externalReferenceNumber: get(this.state.fields, 'externalReferenceNumber.value'),
      dateIssued: moment(new Date()).format(this.state.countryFormats.dateDisplay),
      currentUser: this.isEditForm ? get(this.editOrder, 'createdBy') : this.props.currentUser,
      currentUserCompanyName: get(currentUserCompany, 'name'),
      currentUserCompanyWebsite: get(currentUserCompany, 'website'),
      currentUserCompanyPhone: get(currentUserCompany, 'mobile'),
      currentUserCompanyAbn: get(currentUserCompany, 'abn'),
      currentUserCompanyAddress: get(currentUserCompany, 'address.address'),
      currentUserCompanyLogo: this.state.base64Logo,
      customerDetails : {
        customer: find(this.getCustomers(), {id: customer.companyId.value}),
        customerContact: find(this.state.customerContacts, {id: fields.customer.contactId.value}) || this.state.customerContact,
        customerNgr: this.getPropertyById(fields.customer.ngrId.value, 'ngrNumber',this.state.customerNgrs) || get(this.state.customerNgr, 'ngrNumber')
      },
      providerDetails,
      commodity: find(this.props.commodities, { id: fields.commodityId.value }),
      variety: find(this.props.varieties, { id: fields.varietyId.value }),
      plannedGrade: find(this.props.grades, { id: fields.plannedGradeId.value }) || get(this.editOrder, 'plannedGrade'),
      quantity: fields.quantity.value,
      plannedTonnage: fields.plannedTonnage.value,
      quantityLabel: this.quantityLabel(),
      quantityUnit: this.quantityUnit(),
      season: fields.season.value,
      freightContractDetails,
      specialConditions: canViewCondition ? fields.specialConditions.value : null,
      generalConditions: canViewCondition ? fields.generalConditions.value : null,
      pickupDetails,
      deliveryDetails,
      isPoolContract: get(this.state.selectedBaseEntity, 'isPoolContract'),
      poolGrades: getPoolGrades(
        filter(this.props.grades, grade => {
          return fields.commodityId.value ? fields.commodityId.value == grade.commodityId : true;
        }),
      ),
      note: pick(fields.note, ['description', 'attachments']),
      baseEntity: this.state.selectedBaseEntity,
      seller: this.state.selectedBaseEntity ? this.state.selectedBaseEntity.seller : {
        company: find(this.props.allDirectoryCompanies, {id: get(this.state, 'sellerDetails.companyId')}),
        contact: find(get(this.state, 'sellerContacts', []), {id: get(this.state, 'sellerDetails.contactId')}),
        ngr: find(get(this.state, 'sellerNgrs', []), {id: get(this.state, 'sellerDetails.ngrId')})
      },
      buyer: this.state.selectedBaseEntity ? this.state.selectedBaseEntity.buyer : {
        company: find(this.props.allDirectoryCompanies, {id: get(this.state, 'buyerDetails.companyId')}),
        contact: find(get(this.state, 'buyerContacts', []), {id: get(this.state, 'buyerDetails.contactId')}),
        ngr: find(get(this.state, 'buyerNgrs', []), {id: get(this.state, 'buyerDetails.ngrId')})
      },
      showSpreadGradesSection: this.state.isParentContractBlendContract,
      gradeName: get(this.state.selectedBaseEntity, 'gradeName'),
      spread: this.getSpreadDetails(),
      chemicalApplications: this.state.chemicalApplications,
      currency: this.state.currency,
    };
  };

  getSelectedProvider() {
    return find([...this.getProviders(), ...this.state.providerItems], { id: this.state.fields.providerId.value });
  }

  getEstablishment = (id, type, parties = []) => type ? find(parties, {id: id, entity: type}) : find(parties, {id: id});

  handleSideDrawer(type) {
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer);
  }

  openFreightProviderSideDrawer() {
    const provider = this.getSelectedProvider();
    const companyId = get(provider, 'id');
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.handleSideDrawer('freightProviderSideDrawerIsOpen');
      }));
    }
  }

  alertPermissionError() {
    alertifyjs.alert(
      'Permission Denied',
      `<div className=""><p>You do not have permission to create employee for the party because:</p><ul><li>The selected party is registered on the system.</li></ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
      () => {},
    );
  }

  closeFreightProviderSideDrawer() {
    this.setState({ freightProviderSideDrawerIsOpen: false });
  }

  openCustomerSideDrawer(){
    const companyId = get(this.state.fields, 'customer.companyId.value');
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.handleSideDrawer('customerSideDrawerIsOpen');
      }));
    }
  }

  checkCustomerOnlyEditCheck = () => {
    if (this.isEditForm && !isEmpty(this.editOrder) && includes(AMENDABLE_STATUSES, get(this.editOrder, 'status'))) {
      return false;
    }
    return this.isEditForm;
  };

  isGradeDisable = () => {
    return this.checkCustomerOnlyEditCheck() || this.isParentRequestOrder || ((!isNumber(this.state.fields.commodityId.value) || !!get(this.state.selectedBaseEntity, 'plannedGradeId')) && !(this.state.selectedBaseEntity && this.state.selectedBaseEntity.spread && (this.isSelectedOrderCallOnGrain() || !this.selectedOrderId)));
  };

  closeCustomerSideDrawer(){
    this.setState({customerSideDrawerIsOpen: false});
  }

  openGeneralConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddGeneralConditionButton());
    this.handleSideDrawer('generalConditionSideDrawerIsOpen');
  }

  closeGeneralConditionSideDrawer() {
    this.setState({ generalConditionSideDrawerIsOpen: false });
  }

  openSpecialConditionSideDrawer() {
    const { dispatch } = this.props;
    dispatch(clickAddSpecialConditionButton());
    this.handleSideDrawer('specialConditionSideDrawerIsOpen');
  }

  closeSpecialConditionSideDrawer() {
    this.setState({ specialConditionSideDrawerIsOpen: false });
  }

  openNgrSideDrawer(ngrEntity){
    const companyId = get(this.state.fields, 'customer.companyId.value');
    const selectedEntity = find(this.props.allDirectoryCompanies, {id: companyId});
    if(companyId){
      const { dispatch } = this.props;
      dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
        this.props.handleAddCompanyNgrButtonClick();
        this.props.handleAddNgrButtonClick();
        this.setState({ngrEntity: ngrEntity}, () => this.handleSideDrawer('ngrSideDrawerIsOpen'));
      }));
    }
  }

  closeNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }

  getPropertyById(id, field, list = []) {
    if(!isEmpty(list)) {
      const element = list.find(val => val.id === id);
      return element && get(element, field); 
    }
  }

  getCommissionRate = () => {
    return (this.state.selectedBaseEntityRaw && this.state.selectedBaseEntityRaw.entity === 'freightorder') ||
           this.state.fields.invoicing.value == 'Freight Provider to Invoice Broker'
         ? `${this.state.currency} ${this.state.commissionRate}`
         : '';
  };

  showConditionsSection = () => {
    if(this.state.fields.isSelf.value)
      return false;

    if (this.isEditForm) {
      return !!get(this.editOrder, 'canEditInvoicingSection') || this.isEditingGrainOrder();
    } else if (get(this.state, 'selectedBaseEntity.commodityContractId') && !this.showProviderAndInvoicingSection()) {
      return false;
    }else {
      return !this.state.fields.isSelf.value;
    }
  };

  addFreightProviderContactButton() {
    return (
      this.state.fields.providerId.value && (
        <AddCircleIconWithTooltip
          id='addFreightProviderContact'
          title='Add Freight Provider Contact'
          onClick={() => this.openFreightProviderSideDrawer()}
        />
      )
    );
  }

  addCustomerContactButton() {
    return !this.selectedOrderId && !(this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) && (this.state.fields.customer && this.state.fields.customer.companyId.value) &&
           <AddCircleIconWithTooltip
             id="addCustomerContact"
             title="Add Customer Contact"
             onClick={() => this.openCustomerSideDrawer()}
           />;
  }

  addGeneralConditionButton() {
    return <AddCircleIconWithTooltip id='addGeneralCondition' title='Add General Condition' onClick={() => this.openGeneralConditionSideDrawer()} />;
  }

  addSpecialConditionButton() {
    return <AddCircleIconWithTooltip id='addSpecialCondition' title='Add Special Condition' onClick={() => this.openSpecialConditionSideDrawer()} />;
  }

  addCustomerNgrButton() {
    return (this.state.fields.customer.companyId.value && !(this.state.fields.typeId.value == FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER)) &&
           <AddCircleIconWithTooltip
             id="addCustomerNgr"
             title="Add Customer NGR"
             onClick={() => this.openNgrSideDrawer(this.state.fields.customer)}
           />;
  }

  setDefaultGeneralCondition(condition) {
    this.handleSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  setDefaultSpecialCondition(condition) {
    this.handleSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'specialConditionsSelector', condition);
    }
  }

  customerSideDrawer() {
    const companyId = get(this.state.fields, 'customer.companyId.value');
    const customer = get(this.state.fields.customer, 'company');
    return this.props.isEmployeeSideDrawerOpened &&
           companyId &&
           <SideDrawer
             isOpen={this.state.customerSideDrawerIsOpen}
             title={getLabelForEmployeeForm(customer)}
             onClose={this.closeCustomerSideDrawer}
             app="officeEmployee"
             canCreate={true}
           >
             <CreateEmployee
               closeDrawer={this.closeCustomerSideDrawer}
               canAccessAny={true}
               companyId={companyId}
               selectedCompany={customer}
               establishmentDetails={this.state.fields.customer}
               getContacts={this.getCustomerContacts}
             />
           </SideDrawer>;
  }

  getEmailPopupParties = () => {
    if (this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY)
      return ['customer', 'provider', 'consignor', 'consignee'];

    const buyerCompanyId = this.isEditForm ? get(this.editOrder, 'buyer.companyId', get(this.editOrder, 'commodityContract.buyer.companyId', '')) : get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId', ''));
    const sellerCompanyId = this.isEditForm ? get(this.editOrder, 'seller.companyId', get(this.editOrder, 'commodityContract.seller.companyId', '')) : get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId', ''));
    const customerCompanyId = this.isEditForm ? get(this.editOrder, 'customerCompanyId', '') : get(this.state.fields, 'customer.companyId.value', get(this.state.selectedBaseEntity, 'customerCompanyId', ''));
    const buyer = buyerCompanyId === customerCompanyId ? {label: 'Buyer (Customer)', value: 'customer'} : 'buyer';
    const seller = sellerCompanyId === customerCompanyId ? {label: 'Seller (Customer)', value: 'customer'} : 'seller';

    if (this.isCallOnGrainOrder) {
      return [buyer, seller, 'consignor', 'consignee'];
    }

    if (
      get(this.state.selectedBaseEntity, 'administration.brokeredById') ||
      (this.isEditForm && (get(this.editOrder, 'commodityContract.administration.brokeredById')))
    )
      return [buyer, seller, 'broker', 'provider', 'consignor', 'consignee'];

    return [buyer, seller, 'provider', 'consignor', 'consignee'];
  };

  getSelectedParties = () => {
    const isPickupByPassed = this.isPickupOrderNumberMandatory(false) && !this.state.fields.freightPickup.orderNumber.value;
    const isDeliveryByPassed = this.isDeliveryOrderNumberMandatory(false) && !this.state.fields.freightDelivery.orderNumber.value;

    if (this.isEditForm) {
      if (this.isTonnageOrOrderDatesAmended() && (isPickupByPassed || isDeliveryByPassed)) {
        return isPickupByPassed ? ['consignor'] : ['consignee'];
      }
      if (get(this.editOrder, 'isFreightProvider') && get(this.editOrder, 'isCustomer')) return ['customer', 'provider'];
      else if (get(this.editOrder, 'isFreightProvider')) return ['customer'];
      else if (get(this.editOrder, 'isCustomer')) return ['provider'];
    } else {
      const customerCompanyId = get(this.state.fields, 'customer.companyId.value');
      const providerCompanyId = get(this.state.fields, 'providerId.value');

      if (isPickupByPassed || isDeliveryByPassed){
        return isPickupByPassed ? ['consignor'] : ['consignee'];
      }
      else if (this.props.userCompanyId !== customerCompanyId && this.props.userCompanyId !== providerCompanyId) return ['customer', 'provider'];
      else if (this.props.userCompanyId === providerCompanyId) return ['customer'];
      else if (this.props.userCompanyId === customerCompanyId) return ['provider'];
    }
    return ['customer', 'provider'];
  };

  getPartyEmails = () => {
    let parties = {
      broker: get(this.state.selectedBaseEntity, 'administration.brokerContact.email', ''),
      buyer: this.isEditForm ? get(
        this.editOrder, 'buyer.contact.email', get(this.editOrder, 'commodityContract.buyer.contact.email', '')
      ) : get(
        find(get(this.state, 'buyerContacts'), { id: get(this.state.buyerDetails, 'contactId') }
        ), 'email', get(this.state.selectedBaseEntity, 'buyer.contact.email', '')),
      seller: this.isEditForm ? get(
        this.editOrder, 'seller.contact.email', get(this.editOrder, 'commodityContract.seller.contact.email', '')
      ) : get(
        find(get(this.state, 'sellerContacts'), { id: get(this.state.sellerDetails, 'contactId') }
        ), 'email', get(this.state.selectedBaseEntity, 'seller.contact.email', '')),
      customer: get(find(this.state.customerContacts, {id: this.state.fields.customer.contactId.value}), 'email'),
      provider: get(find(this.state.providerEmployees, {id: this.state.fields.assignToId.value}), 'email'),
      consignor: '',
      consignee: '',
    };

    if(this.isEditingGrainOrder())
      delete parties.provider;

    return parties;
  };

  getEmailPopupPartiesCompanyIds() {
    const parties = this.getEmailPopupParties();
    const ids = {};
    forEach(parties, party => {
      if(party === 'broker')
        ids.broker = get(this.state.selectedBaseEntity, 'administration.brokerContact.companyId');
      if(party === 'buyer')
        ids.buyer = this.isEditForm ?
                    get(this.editOrder, 'buyer.companyId',
                        get(this.editOrder, 'commodityContract.buyer.companyId')) :
                    get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId'));
      if(party === 'seller')
        ids.seller = this.isEditForm ?
                     get(this.editOrder, 'seller.companyId',
                         get(this.editOrder, 'commodityContract.seller.companyId')) :
                     get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId'));
      if(party === 'consignor')
        ids.consignor = this.state.selectedConsignor?.companyId || find(this.state.consignors, {id: this.state.selectedConsignor?.id})?.companyId;
      if(party === 'consignee')
        ids.consignee = this.state.selectedConsignee?.companyId || find(this.state.consignees, {id: this.state.selectedConsignee?.id})?.companyId;
      if(party === 'customer' || get(party, 'label'))
        ids.customer = this.state.fields.customer.companyId.value;
      if(party === 'provider')
        ids.provider = this.state.fields.providerId.value;
    });

    return ids;
  }

  async getPartyContacts() {
    if(this.gotOncePartyContacts)
      return;

    this.gotOncePartyContacts = true;
    const parties = this.getEmailPopupPartiesCompanyIds();
    let partiesWithoutContacts = without(keys(parties), 'customer', 'provider');
    const contacts = {};
    forEach(parties, (id, party) => {
      contacts[party] = [];
      if (party === 'seller')
        contacts[party] = get(this.state, 'sellerContacts');
      if (party === 'buyer')
        contacts[party] = get(this.state, 'buyerContacts');
      if(party === 'customer')
        contacts[party] = this.state.customerContacts;
      if(party === 'provider')
        contacts[party] = this.state.providerEmployees;
    });
    if(!isEmpty(partiesWithoutContacts)) {
      forEach(partiesWithoutContacts, party => {
        forEach(['seller', 'buyer', 'customer', 'provider'], _party => {
          if(!isEmpty(contacts[_party]) && parties[_party] == parties[party]) {
            contacts[party] = [...contacts[_party]]
            partiesWithoutContacts = without(partiesWithoutContacts, party)
          }
        })
      })
      const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
      if(isEmpty(companyIds))
        return contacts;
      const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
      const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.userToken);
      forEach(partiesWithoutContacts, party => {
        contacts[party] = filter(employees, {companyId: parties[party]});
      });
    }

    return contacts;
  }

  getEmailSubject = () => {
    const companyName = get(this.props.currentUser,'company.name',"");
    const amendingText = this.isEditForm ? "[Amendment] " : "";
    const identifier = get(this.state.fields, 'identifier.value', '').toUpperCase();
    const entity = this.getOrderEntity();
    return `${amendingText}${companyName} ${entity} Order #${identifier}`;
  };

  getFooterNote = () => {
    let bindText = '';
    this.sendButtonText = null;
    if (
      (this.isEditForm && get(this.editOrder, 'status') === 'draft') ||
      get(this.state.selectedBaseEntity, 'contractStatus') === 'planned'
    ) {
      bindText = ' after confirmation of Contract.';
      this.sendButtonText = 'Send';
    }
    const entity = this.getOrderEntity();
    return this.isEditForm ?
           `Amendments and updated ${entity} Order PDF will be automatically sent as part of the email${bindText}` :
           `${entity} Order PDF will be automatically sent as part of the email${bindText}`;
  };

  openEmailDialog = (data) => {
    let emailPopupParties = this.getEmailPopupParties();
    if(this.isEditingGrainOrder())
      remove(emailPopupParties, party => {return party === 'provider';});

    this.setState({ showEmailDialog: true, emailPopupParties: emailPopupParties });
    this.payloadData = data;
  };

  isParentCallOnGrainOrder() {
    return includes([get(this.state.deliveryOrder, 'typeId'), get(this.state.pickupOrder, 'typeId')], CALL_ON_GRAIN_TYPE_ID);
  }

  closeEmailDialog = (communicationData, justClose) => {
    if(justClose) {
      this.gotOncePartyContacts = false;
      this.setState({showEmailDialog: false});
    }
    else if(this.state.showEmailDialog) {
      let data = this.payloadData;
      data = omit(data, ['freightPickup.date', 'freightDelivery.date', 'freightPickup.timeStart', 'freightDelivery.timeStart']);
      if(communicationData) {
        data['communication'] = communicationData;
      }
      let acceptanceRequired = communicationData['acceptanceRequired'];
      this.setState({showEmailDialog: false}, () => {
        const {dispatch} = this.props;
        if (this.isEditForm) {
          dispatch(isLoading('genericTableWithData'));
          if (acceptanceRequired) {
            if (get(data, 'freightPickup.consignor.handlerId') && get(this.state.selectedConsignor, 'companyId'))
              set(data, 'freightPickup.consignor.handler.handler.companyId' ,get(this.state.selectedConsignor, 'companyId'))

            if (get(data, 'freightDelivery.consignee.handlerId') && get(this.state.selectedConsignee, 'companyId'))
              set(data, 'freightDelivery.consignee.handler.handler.companyId' , get(this.state.selectedConsignee, 'companyId'))
          }
          dispatch(raiseOrderAmendRequest(this.editOrderId, data));
        } else {
          if(this.state.saleContractId)
            data['saleContractId'] = this.state.saleContractId
          else if(this.state.purchaseContractId)
            data['purchaseContractId'] = this.state.purchaseContractId
          dispatch(isLoading('genericTableWithData'));
          dispatch(create(data, false, this.isSelectedOrderCallOnGrain() || this.isCallOnGrainOrder || this.isParentRequestOrder, false, this.isParentCallOnGrainOrder()));
        }
      });
    }
    this.handleClosePreview();
  };

  freightProviderSideDrawer() {
    const freightProvider = this.getSelectedProvider();
    return (
      this.props.isEmployeeSideDrawerOpened &&
      freightProvider && (
        <SideDrawer
          isOpen={this.state.freightProviderSideDrawerIsOpen}
          title={getLabelForEmployeeForm(freightProvider)}
          onClose={this.closeFreightProviderSideDrawer}
          app='officeEmployee'
          canCreate={true}
        >
          <CreateEmployee
            closeDrawer={this.closeFreightProviderSideDrawer}
            canAccessAny={true}
            companyId={this.state.fields.providerId.value}
            selectedCompany={freightProvider}
            establishmentDetails={this.state.fields.providerId.value}
            getContacts={this.getCompanyEmployees}
          />
        </SideDrawer>
      )
    );
  }

  isFreightProviderCreator() {
    return this.state.fields.providerId.value == this.props.currentUser.companyId;
  }

  isCustomerCreator(){
    return this.state.fields.customer.companyId.value == this.props.currentUser.companyId;
  }

  getSelectedCommodity = commodityId => {
     const id = commodityId || this.state.fields.commodityId.value
     return id ? find(this.props.commodities, {id: id}) : null
   }

  hasQuantityBasedCommodity = commodityId => Boolean(this.getSelectedCommodity(commodityId || this.state.fields.commodityId.value)?.isQuantityBased)

  priceUnit(commodityId) {
    return get(this.getSelectedCommodity(commodityId), 'priceUnit', MT_UNIT)
  }

  quantityLabel() {
    return get(this.getSelectedCommodity(), 'quantityLabel')
  }

  quantityUnit() {
    return get(this.getSelectedCommodity(), 'unit')
  }

  noteHandler = val => {
    this.setState({fields: {...this.state.fields, note: val}});
  };

  isEditingGrainOrder() {
    const order = this.editOrder;
    return get(order, 'typeId') === 3;
  }

  isEditingBeforeConfirmation() {
    return includes(['draft', 'planned'], get(this.editOrder, 'status'));
  }

  isSelectedOrderCallOnGrain() {
    return get(this.selectedOrder, 'typeId') === 3;
  }

  isFreightAllocation() {
    const isCallOnGrain = this.isSelectedOrderCallOnGrain()
    return (this.selectedOrderId || get(this.editOrder, 'parentOrder')) && !isCallOnGrain && !this.isCallOnGrainOrder
  }

  isSelectedOrderExternalCallOnGrain() {
    const order = this.selectedOrder;
    return get(order, 'externalReferenceNumber');
  }

  getOrderEntity() {
    return this.isEditingGrainOrder() || this.isCallOnGrainOrder ? 'Grain' : 'Freight';
  }

  isRepresentingCustomer() {
    return includes(
      compact([
        get(this.state, 'selectedBaseEntity.customerRepresentedById'),
        get(this.editOrder, 'customer.representedById')
      ]),
      get(this.props, 'currentUser.companyId')
    );
  }

  currentUserNotAmongOrderParty() {
    const sellerBrokerCompanyId = get(this.state.selectedBaseEntity, 'seller.representedById', get(this.state.sellerDetails, 'representedById'))
    const buyerBrokerCompanyId = get(this.state.selectedBaseEntity, 'buyer.representedById', get(this.state.buyerDetails, 'representedById'))
    if(this.isCallOnGrainOrder) {
      const buyerCompanyId = this.isEditForm ? get(this.editOrder, 'buyer.companyId', get(this.editOrder, 'commodityContract.buyer.companyId', '')) : get(this.state, 'buyerDetails.companyId', get(this.state.selectedBaseEntity, 'buyer.companyId', ''))
      const sellerCompanyId = this.isEditForm ? get(this.editOrder, 'seller.companyId', get(this.editOrder, 'commodityContract.seller.companyId', '')) : get(this.state, 'sellerDetails.companyId', get(this.state.selectedBaseEntity, 'seller.companyId', ''))
      return !includes([sellerCompanyId, buyerCompanyId, sellerBrokerCompanyId, buyerBrokerCompanyId], this.props.currentUser.companyId)
    }
    let orderParty = [this.state.fields.customer.companyId.value, this.state.fields.customer.representedById.value, this.state.fields.providerId.value]
    isEqual(get(this.state.selectedBaseEntity, 'deliveryOnus'), 'Seller') ? orderParty.push(sellerBrokerCompanyId) : orderParty.push(buyerBrokerCompanyId)
    return !includes(orderParty, this.props.currentUser.companyId)
  }

  isTonnageOrOrderDatesAmended() {
    return false && (this.isValueChanged('plannedTonnage') || this.isDateTimeChanges('freightPickup') || this.isDateTimeChanges('freightDelivery'))
  }

  isAcceptanceRequiredInEditForm(){
    return (this.isEditForm && (this.isTonnageOrOrderDatesAmended()))
  }

  isAcceptanceDisabled() {
    const { fields } = this.state;
    const isPickupOrderNumberEmpty = !fields.freightPickup.orderNumber.value;
    const isDeliveryOrderNumberEmpty = !fields.freightDelivery.orderNumber.value;
    return (
      this.isEditingBeforeConfirmation() ||
      fields.isSelf.value ||
      (this.isRepresentingCustomer() && this.isFreightProviderCreator()) ||
      this.currentUserNotAmongOrderParty() ||
      (this.isPickupOrderNumberMandatory(false) && this.canByPassMandatoryOrderNumber('pickup') && isPickupOrderNumberEmpty && (!this.isEditForm || this.isAcceptanceRequiredInEditForm())) ||
      (this.isDeliveryOrderNumberMandatory(false) && this.canByPassMandatoryOrderNumber('delivery') && isDeliveryOrderNumberEmpty && (!this.isEditForm || this.isAcceptanceRequiredInEditForm()))
    );
  }

  isAcceptanceRequired = () => {
    const { fields } = this.state;
    const isPickupOrderNumberEmpty = !fields.freightPickup.orderNumber.value;
    const isDeliveryOrderNumberEmpty = !fields.freightDelivery.orderNumber.value;
    return (
      (this.isPickupOrderNumberMandatory(false) && this.canByPassMandatoryOrderNumber('pickup') && isPickupOrderNumberEmpty && (!this.isEditForm || this.isAcceptanceRequiredInEditForm())) ||
      (this.isDeliveryOrderNumberMandatory(false) && this.canByPassMandatoryOrderNumber('delivery') && isDeliveryOrderNumberEmpty && (!this.isEditForm || this.isAcceptanceRequiredInEditForm()))
    );
  }

  onCheckpointOrderExistsChange = (event, checkpointStateKey) => {
    const stateKey = checkpointStateKey === 'pickup' ? 'pickupCheckpointOrderExists' : 'deliveryCheckpointOrderExists';
    const id = checkpointStateKey === 'pickup' ? 'freightPickup.orderId' : 'freightDelivery.orderId';
    const checked = event.target.checked;
    const newState = {...this.state};
    newState[stateKey] = checked;
    if(!checked) {
      set(newState.fields, id + '.value', null);
      set(newState.fields, id + '.errors', []);
    }
    this.setState(newState, () => {
      const value = checkpointStateKey === 'pickup' ? this.state.fields.freightPickup.orderNumber.value : this.state.fields.freightDelivery.orderNumber.value;
      if(checked)
        this.handleOrderBlur(id, value);
    });
  };

  isDependentCheck(event) {
    const newState = { ...this.state };
    let identifier = newState.fields.identifier.value;
    if (this.isParentRequestOrder) {
      newState.fields.externalReferenceNumber.validators = [required()];
      newState.fields.commodityContractId.validators = [selected()];
      newState.fields.commodityContractId.value = undefined;
    } else {
    newState.fields = cloneDeep(this.fieldsDup);
    if (event.target.checked) {
      newState.fields.sellerDetails = [];
      newState.fields.buyerDetails = [];
      newState.fields.identifier.value = identifier;
      newState.partyValidate = false;
      newState.errors.party = false;
    }
    else {
      newState.seller = {};
      newState.buyer = {};
      newState.errors.party = true;
      newState.fields.externalReferenceNumber.validators = [required()];
      newState.fields.freightPickup.consignor.handlerId.validators = [];
      newState.fields.freightDelivery.consignee.handlerId.validators = [];
      newState.fields.identifier.value = identifier;
      newState.selectedConsignor = undefined;
      newState.selectedConsignorSite = undefined;
      newState.selectedConsignee = undefined;
      newState.selectedConsigneeSite = undefined;
      newState.selectedBaseEntity = null;
      newState.customerContact = null;
      newState.selectedBaseEntityType = null;
      newState.selectedBaseEntityId = null;
      newState.selectedBaseEntityRaw = null;
      newState.isConsigneeEditable = true;
      newState.isConsignorEditable = true;
      newState.customerNgrs = [];
      newState.filterParty = undefined;
      if (!this.isCallOnGrainOrder){
        newState.fields.freightPickup.consignor.handlerId.validators = [required()];
        newState.fields.freightDelivery.consignee.handlerId.validators = [required()];
      }
    }
    }
    if (this.isCallOnGrainOrder)
      newState.fields.typeId.value = 3;
    else
      newState.fields.typeId.value = FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER;
    newState.consignorSites = [];
    newState.consigneeSites = [];
    newState.isDependent = event.target.checked;
    this.setState(newState);
  }

  onFieldChange = event => {
    const newState = { ...this.state };
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    set(newState.fields, `${event.target.id}.value`, value);
    this.setState(newState);
  };

  handleReferenceNumberBlur(event) {
    if (get(event.target, 'value') != '') {
      APIService.empty().appendToUrl(`common/exists/${event.target.value}/`).get().then(res => {
        const newState = { ...this.state };
        if (res.result) {
          newState.fields.externalReferenceNumber.errors = ['Reference Number already exists in system'];
        }
        else {
          newState.fields.externalReferenceNumber.errors = [];
        }

        this.setState({ newState });
      });
    }
  }

  handleSaveButtonClick() {
    if(!this.state.fields.typeId.value) {
      alertifyjs.alert(`${this.getOrderEntity()} Order`, 'Please select order type to create template.');
      return;
    }
    else {
      const newState = { ...this.state };
      newState.templateDialog = true;
      newState.templateName.value = null;
      newState.templateName.errors = [];
      this.setState(newState);
    }
  }

  handleTemplateDialogClose() {
    this.setState({
      ...this.state,
      templateDialog: false,
    });
  }

  saveTemplate() {
    const newState = { ...this.state };
    const templateNameValidator = newState.templateName.validators[0];
    if (templateNameValidator.isInvalid(newState.templateName.value)) {
      newState.templateName.errors.push(templateNameValidator.message);
      this.setState(newState);
    } else {
      let data = this.getSubmitData();
      const { dispatch } = this.props;
      data['status'] = 'template';
      data['templateName'] = newState.templateName.value;
      if(includes(get(data.freightDelivery, 'dateTime'), 'undefined') || includes(get(data.freightDelivery, 'dateTime'), 'Invalid')) {
        delete data.freightDelivery.dateTime;
      }
      if(includes(get(data.freightPickup, 'dateTime'), 'undefined') || includes(get(data.freightDelivery, 'dateTime'), 'Invalid')) {
        delete data.freightPickup.dateTime;
      }
      if(isEmpty(toString(get(data, 'plannedTonnage'))))
        delete data.plannedTonnage;

      data['note'] = pick(get(this.state.fields, 'note'), ['description', 'attachments', 'companyId']);
      data = omit(data, ['identifier', 'freightPickup.date', 'freightDelivery.date', 'freightPickup.timeStart', 'freightDelivery.timeStart']);
      data = pickBy(data, (value) => {
        return !(value === undefined || value === "" || value === null);
      })
      newState.templateDialog = false;
      this.setState(newState);
      dispatch(isLoading('genericTableWithData'));
      dispatch(create(data, false, false, true));
    }
  }

  handleTemplateNameChange(event) {
    const newState = { ...this.state };
    newState.templateName.value = event.target.value;
    const targetId = event.target.id;
    this.setState(newState, () => this.setTemplateNameFieldErrors(targetId, this.state.templateName.value));
  }

  handleTemplateNameBlur(event) {
    this.setTemplateNameFieldErrors(event.target.id, event.target.value);
  }

  setTemplateNameFieldErrors(id, value) {
    const newState = { ...this.state };
    this.getFieldErrors(get(newState, id), value);
    this.setState(newState);
  }

  openCompanySideDrawer(contractParty){
    this.setState({openCompanySideDrawer: true, contractParty: contractParty});
  }

  closeCompanySideDraw(){
    this.setState({
      openCompanySideDrawer: false
    });
  }

  handleQuickOrderChange(item) {
    if(item) {
      const newState = {...this.state};
      this.selectedOrder = undefined;
      alertifyjs
        .confirm(
          'Warning',
          'Already filled values in the Order will be overridden. Do you wish to continue?',
          () => {
            this.applyingTemplate = true;
            this.resetAllFieldsValues(newState);
            this.setState(newState, () => {
              this.disableForm = false
              this.isDuplicatingOrder = true
              if(item.typeId === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER)
                this.setOrder(item.id)
              else
                this.getFreightOrder(item.id);
              this.props.dispatch(isLoading());
            });
          },
          () => {},
        )
        .set('labels', { ok: 'Yes', cancel: 'No' });
    }
  }

  resetAllFieldsValues(newState) {
    newState.fields = cloneDeep(this.fieldsDup);
    newState.selectedConsignor = undefined;
    newState.selectedConsignorSite = undefined;
    newState.selectedConsignee = undefined;
    newState.selectedConsigneeSite = undefined;
    newState.isConsignorEditable = true;
    newState.isConsigneeEditable = true;
    newState.selectedBaseEntity = null;
    this.setConsignorValidator(newState, []);
    this.setConsigneeValidator(newState, []);
    this.resetFreightPickup(newState);
    this.resetFreightDelivery(newState);
  }

  getTemplatesService(queryString, callback) {
    let typeIdVal = this.state.fields.typeId.value
    if(!typeIdVal)
      typeIdVal = [FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY, FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER]

    if (!queryString.type_id)
      queryString.type_id = [];

    if (Array.isArray(typeIdVal) && typeIdVal.length > 0)
      typeIdVal.forEach(id => { queryString.type_id.push(id); });
    else if (typeIdVal !== undefined && typeIdVal !== null)
      queryString.type_id.push(typeIdVal);

    const { userToken } = this.props;
    APIService.freights().appendToUrl('templates/').get(userToken, null, queryString).then(templates => {
      callback(templates);
    });
  }

  addNewCompany(newCompany){
    let newState = { ...this.state };
    newCompany.companyId = newCompany.id;
    if(includes(this.state.contractParty, 'freight')) {
      newState.providerItems = [...newState.providerItems, newCompany];
      newState.fields.providerId = {
        ...newState.fields.providerId,
        value: newCompany.id
      };
    } else if(includes(this.state.contractParty, 'customer')) {
      newState.customerItems = [...this.getCustomers(), newCompany];
      newState.fields.customer.companyId = {
        ...newState.fields.customer.companyId,
        value: newCompany.id
      };
      newState.selectedCustomer = newCompany;
    }
    this.setState(newState);
  }

  updateParty(seller, buyer, errors, contacts, customer, ngrs) {
    const newState = { ...this.state };
    newState.sellerDetails = seller;
    newState.buyerDetails = buyer;
    newState.partyValidate = false;
    newState.errors = merge(this.state.errors, errors);
    newState.customerItems = customer;
    newState.sellerContacts = contacts.sellerContacts;
    newState.buyerContacts = contacts.buyerContacts;
    newState.sellerNgrs = ngrs.sellerNgrs;
    newState.buyerNgrs = ngrs.buyerNgrs;
    this.setState(newState, () => {
      if (this.state.fields.customer.companyId.value && (
        !includes([get(seller, 'contactId'), get(buyer, 'contactId')], this.state.fields.customer.contactId.value) ||
        !includes([get(seller, 'ngrId'), get(buyer, 'ngrId')], this.state.fields.customer.ngrId.value)
      ))
        this.setCustomerContactAndNgr();
    });
  }

  isConsignorDisabled = () => {
    return this.state.selectedConsignor && get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' &&
        (!get(this.state.selectedBaseEntityRaw, 'isCallOnGrain') ||
            includes(PICKUP_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntityRaw, 'commodityContract.pricePointId', ''))) &&
        (get(this.state.selectedBaseEntity, 'consignorHandlerId') ||
            get(this.state.selectedBaseEntity, 'freightPickup.consignorId'));
  }

  isConsigneeDisabled = () => {
    return this.state.selectedConsignee && get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' &&
        (!get(this.state.selectedBaseEntityRaw, 'isCallOnGrain') ||
            includes(DELIVERY_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntityRaw, 'commodityContract.pricePointId', ''))) &&
        (get(this.state.selectedBaseEntity, 'consigneeHandlerId') ||
            get(this.state.selectedBaseEntity, 'freightDelivery.consigneeId'));
  }

  getConsignorSites() {
    const allConsignorSites = get(this.state.selectedBaseEntity, 'consignorsWithSites', get(this.state.selectedBaseEntity, 'commodityContractConsignorsWithSites', []))
    let filteredSites = [];
    if (allConsignorSites && this.state.consignors) {
      filteredSites = filter(this.state.consignors, site => find(allConsignorSites, { handlerId: site.id }));
    }
    return filteredSites;
  }

  getConsigneeSites() {
    const allConsigneeSites = get(this.state.selectedBaseEntity, 'consigneesWithSites', get(this.state.selectedBaseEntity, 'commodityContractConsigneesWithSites', []))
    let filteredSites = [];
    if (allConsigneeSites && this.state.consignees) {
      filteredSites = filter(this.state.consignees, site => find(allConsigneeSites, { handlerId: site.id }));
    }
    return filteredSites;
  }

  getAllocatedContractPartySites(party) {
    let filteredSites = [];
    if(this.state.allocatedContract) {
      if(((this.isEditForm && get(this.editOrder, 'extras.isContractAllocated')) || !this.isEditForm) && isEqual(party, 'consignor')) {
        let allocatedContractConsignorSites = get(this.state.allocatedContract, 'consignorsWithSites');
        let baseContractConsignorSites = filter(this.state.consignors, site => find(get(this.state.selectedBaseEntity, 'baseContractConsignorSites'), { handlerId: site.id }));

        let allocatedContractConsignorHandlers = map(allocatedContractConsignorSites, site => site.handlerId)
        let allocatedContractFilteredSites = filter(this.state.consignors, site => includes(allocatedContractConsignorHandlers, site.id ))
        if(includes(PICKUP_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntity, 'pricePointId')) && includes(PICKUP_MANDATORY_PRICE_POINTS, get(this.state.allocatedContract, 'pricePointId')))
          filteredSites = intersectionWith(allocatedContractFilteredSites, baseContractConsignorSites, isEqual)
        else if (includes(PICKUP_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntity, 'pricePointId')))
          filteredSites = baseContractConsignorSites;
        else if (includes(PICKUP_MANDATORY_PRICE_POINTS, get(this.state.allocatedContract, 'pricePointId')))
          filteredSites = allocatedContractFilteredSites;

        if(!isEmpty(filteredSites) && !find(filteredSites, {id: get(this.state.selectedConsignor, 'id')}) && !isEqual(this.state.selectedConsignor, first(filteredSites))) {
          const consignor = first(filteredSites);
          if(!isEmpty(consignor)) {
            const newState = { ...this.state }
            newState.selectedConsignor = consignor
            newState.consignors = uniqBy([...newState.consignors, consignor], 'id')
            newState.fields.freightPickup.consignor.handlerId.value = consignor.id
            this.getFieldErrors(newState.fields.freightPickup.consignor.handlerId, consignor.id)
            newState.fields.freightPickup.orderId.errors = this.getCheckpointOrderFieldErrors('freightPickup', newState.fields)
            this.setState(newState, () => {
              this.getSites(consignor.id, 'farm', 'consignor')
              if(get(consignor, 'id'))
                this.fetchPickupSettings(consignor.id);
            });
          }
        }

      } else if(((this.isEditForm && get(this.editOrder, 'extras.isContractAllocated')) || !this.isEditForm) && isEqual(party, 'consignee')) {
        let allocatedContractConsigneeSites = get(this.state.allocatedContract, 'consigneesWithSites');
        let baseContractConsigneeSites = filter(this.state.consignees, site => find(get(this.state.selectedBaseEntity, 'baseContractConsigneeSites'), { handlerId: site.id }));
        let allocatedContractConsigneeHandlers = map(allocatedContractConsigneeSites, site => site.handlerId)

        let allocatedContractFilteredSites = filter(this.state.consignees, site => includes(allocatedContractConsigneeHandlers, site.id ))

        if(includes(DELIVERY_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntity, 'pricePointId')) && includes(DELIVERY_MANDATORY_PRICE_POINTS, get(this.state.allocatedContract, 'pricePointId')))
          filteredSites = intersectionWith(allocatedContractFilteredSites, baseContractConsigneeSites, isEqual)
        else if (includes(DELIVERY_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntity, 'pricePointId')))
          filteredSites = baseContractConsigneeSites;
       else if (includes(DELIVERY_MANDATORY_PRICE_POINTS, get(this.state.allocatedContract, 'pricePointId')))
          filteredSites = allocatedContractFilteredSites;

        if(!isEmpty(filteredSites) && !find(filteredSites, {id: get(this.state.selectedConsignee, 'id')}) && !isEqual(this.state.selectedConsignee, first(filteredSites))) {
          const consignee = first(filteredSites)
          if(!isEmpty(consignee)) {
            const newState = { ...this.state }
            newState.selectedConsignee = consignee
            newState.consignees = uniqBy([...newState.consignees, consignee], 'id')
            newState.fields.freightDelivery.consignee.handlerId.value = consignee.id
            this.getFieldErrors(newState.fields.freightDelivery.consignee.handlerId, consignee.id)
            newState.fields.freightDelivery.orderId.errors = this.getCheckpointOrderFieldErrors('freightDelivery', newState.fields)
            this.setState(newState, () => {
              this.getSites(consignee.id, 'farm', 'consignee')
              if(get(consignee, 'id'))
                this.fetchDeliverySettings(consignee.id);
            });
          }
        }
      }
    }
    return filteredSites;
  }

  showFreightContractDetails() {
    return !this.isEditForm || get(this.editOrder, 'isCustomer') || get(this.editOrder, 'isFreightProvider')
  }

  setConsignorSiteAndCustomerNgrIfContractStockAllocation() {
    if (this.state.contractStockAllocation?.siteId && (!this.state.fields.freightPickup.consignor.handlerId.value || this.state.fields.freightPickup.consignor.handlerId.value !==get(this.state.contractStockAllocation, 'siteId') || !this.state.selectedConsignor || this.state.fields.plannedGradeId.value !== get(this.state.contractStockAllocation, 'gradeId'))) {
      const newState = {...this.state};
      const siteId = this.state.contractStockAllocation.siteId
      newState.fields.freightPickup.consignor.handlerId.value = get(this.state.contractStockAllocation, 'siteId');
      newState.selectedConsignor = find(this.state.consignors, {id: siteId}) || {id: siteId, name: this.state.contractStockAllocation.siteDisplayName};
      newState.selectedConsignorId = newState.selectedConsignor?.id
      newState.fields.customer.ngrId.value = get(this.state.contractStockAllocation, 'ngrId');
      newState.fields.plannedGradeId.value = get(this.state.contractStockAllocation, 'gradeId');
      this.setState(newState);
    }
  }

  handleBlendGradePercentageChange(obj, value) {
    const totalPercentage = this.state.blendGradeDetails.filter(obj => isEmpty(obj.errors)).reduce((acc, detail) => acc + (detail.percentage || 0), 0);
    value = value ? parseFloat(value) : undefined;
    let errors = [];
    this.setState(prevState => ({
      blendGradeDetails: prevState.blendGradeDetails.map(detail => {
        if (detail.id === obj.id) {
          let allowedTonnage = 100 - totalPercentage
          if (isEmpty(obj.errors))
            allowedTonnage = 100 - (totalPercentage - (obj.percentage || 0))
          if (value && value > allowedTonnage )
            errors = [`Cannot be greater than ${allowedTonnage.toFixed(2)}`];
          return { ...detail, percentage: value, errors: errors };
        }
        return detail;
      })
    }), () => {
      if (isEmpty(errors))
        this.updateAndValidateBlendGradeDetails()
    });
  }

  updateAndValidateBlendGradeDetails() {
    const totalPercentage = this.state.blendGradeDetails.reduce((acc, detail) => acc + (detail.percentage || 0), 0);
    const orderTonnage = parseFloat(this.state.fields.plannedTonnage.value);
    let blendGradeDetails = [...this.state.blendGradeDetails];
    blendGradeDetails = blendGradeDetails.map(detail => {
      if (totalPercentage <= 100 || isEmpty(detail.errors)) {
        let percentage = get(detail, 'percentage') || 0;
        let objTonnage = parseFloat(((percentage/100) * orderTonnage).toFixed(2));
        return {...detail, tonnage: objTonnage, errors: []}
      }
      else
        return {...detail};
    })
    this.setState({blendGradeDetails: blendGradeDetails})
  }

  onChemicalApplicationCommodityChange(obj, value) {
    let commodity = undefined
    if (value)
      commodity = find(this.props.commodities, {id: value});
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, commodityId: value, commodity: commodity};
        return {...chemicalApplication}
      })
    }));
  }

  handleApplicationRateChange(obj, value) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, applicationRate: value};
        return {...chemicalApplication}
      })
    }));
  }

  onChemicalApplicationGradeChange(obj, item) {
    this.setState(prevState => ({
      chemicalApplications: prevState.chemicalApplications.map(chemicalApplication => {
        if (chemicalApplication.id == obj.id)
          return {...chemicalApplication, gradeId: item.id, gradeName: item.name};
        return {...chemicalApplication}
      })
    }));
  }

  onChemicalApplicationAdd() {
    const newState = {...this.state};
    const spreadDetails = get(this.state.selectedBaseEntity, 'spread') || [];
    const chemicalApplications = get(this.state.selectedBaseEntity, 'chemicalApplications') || [];
    const newObj = {
      id: newState.chemicalApplications.length + 1,
      commodityIdOptions: chemicalApplications.map(chemicalApplication => chemicalApplication.commodityId),
      commodityId: undefined,
      commodity: undefined,
      gradeId: undefined,
      gradeIds: spreadDetails.map(detail => detail.gradeId),
      gradeName: undefined,
      applicationRate: undefined,
      errors: []
    }
    newState.chemicalApplications = [...newState.chemicalApplications, newObj];
    this.setState(newState)
  }

  onChemicalApplicationDelete(obj) {
    const newState = {...this.state};
    let chemicalApplications = [...newState.chemicalApplications.filter(chemicalApplication => chemicalApplication.id !== obj.id)];
    newState.chemicalApplications = chemicalApplications.map((chemicalApplication, index) => ({...chemicalApplication, id: index + 1}));
    this.setState(newState)
  }

  updateFarmEntities = (entityId, address, mobile, checkpoint) => {
    let handlers = checkpoint == 'pickup' ? this.state.consignors : this.state.consignees
    let updateSelectedHandler = checkpoint == 'pickup' ? this.state.selectedConsignor : this.state.selectedConsignee
    if (find(handlers, {id: entityId})) {
      const newState = {...this.state};
      newState[handlers] = map(handlers, item => {
        if(item?.id == entityId) {
          item.address = address;
          item.mobile =  mobile
        }
        return item
      })
      newState[updateSelectedHandler] = {...updateSelectedHandler, address: address, mobile: mobile}
      this.setState(newState)
    }
  }

  getEntityTonnages(forEntity=null) {
    let { allowedTonnage, reason } = this.maximumAllowedTonnage(true);
    reason = forEntity ? forEntity : reason;
    let entity = undefined;
    let counterContract = undefined;
    if (reason === 'delivery order') {
      const { deliveryOrder } = this.state;
      entity = deliveryOrder;
    }
    else if (reason === 'pickup order') {
      const { pickupOrder } = this.state;
      entity = pickupOrder;
    }
    else if (reason === 'contract') {
      allowedTonnage = parseFloat(allowedTonnage);
      let baseEntityUnaccountedTonnage = get(this.state.selectedBaseEntity, 'unaccountedTonnage') ? parseFloat(get(this.state.selectedBaseEntity, 'unaccountedTonnage')) : get(this.state.selectedBaseEntity, 'unaccountedTonnage');
      let allocatedContractUnaccountedTonnage = get(this.state.allocatedContract, 'unaccountedTonnage') ? parseFloat(get(this.state.allocatedContract, 'unaccountedTonnage')) : get(this.state.allocatedContract, 'unaccountedTonnage');
      if (this.editOrder) {
        if (baseEntityUnaccountedTonnage)
          baseEntityUnaccountedTonnage += get(this.editOrder, 'plannedTonnage')
        if (allocatedContractUnaccountedTonnage)
          allocatedContractUnaccountedTonnage += get(this.editOrder, 'plannedTonnage')
      }
      if (allowedTonnage === baseEntityUnaccountedTonnage) {
        entity = this.state.selectedBaseEntity;
        counterContract = this.state.allocatedContract ? {...this.state.allocatedContract, entity: 'commodityContract'} : this.state.allocatedContract;
      }
      else if (allowedTonnage === allocatedContractUnaccountedTonnage) {
        entity = {...this.state.allocatedContract, entity: 'commodityContract'};
        counterContract = this.state.selectedBaseEntity;
      }
    }
    else if (reason === 'parent order') {
      entity = this.selectedOrder || this.state.selectedBaseEntity;
    }
    let tonnagesData = [];
    const accountedTonnage = parseFloat(get(entity, 'accountedTonnage'));
    const totalTonnage = parseFloat(get(entity, 'inferredTonnage'));
    const unplannedTonnage = totalTonnage - accountedTonnage > 0 ? totalTonnage - accountedTonnage : 0;

    let counterContractAccountedTonnage = undefined;
    let counterContractTotalTonnage = undefined;
    let counterContractUnplannedTonnage = undefined;

    if (counterContract) {
      counterContractAccountedTonnage = parseFloat(get(counterContract, 'accountedTonnage'));
      counterContractTotalTonnage = parseFloat(get(counterContract, 'inferredTonnage'));
      counterContractUnplannedTonnage = counterContractTotalTonnage - counterContractAccountedTonnage > 0 ? counterContractTotalTonnage - counterContractAccountedTonnage : 0;
    }

    tonnagesData = [
      {label: 'Total', value: totalTonnage, counterEntityVal: counterContractTotalTonnage},
      {label: 'Delivered', value: get(entity, 'totalDeliveredTonnage'), counterEntityVal: get(counterContract, 'totalDeliveredTonnage')},
      {label: 'In Progress', value: get(entity, 'progressTonnage'), counterEntityVal: get(counterContract, 'progressTonnage')},
      {label: 'Outstanding', value: get(entity, 'outstandingTonnage'), counterEntityVal: get(counterContract, 'outstandingTonnage')},
      {label: 'Unplanned', value: unplannedTonnage, counterEntityVal: counterContractUnplannedTonnage}
    ];
    if (reason === 'contract') {
      tonnagesData.push({label: 'Invoiced', value: get(entity, 'invoicedTonnage'), counterEntityVal: get(counterContract, 'invoicedTonnage')});
      tonnagesData.push({label: 'Remaining', value: get(entity, 'remainingTonnage'), counterEntityVal: get(counterContract, 'remainingTonnage')})
    }
    if (!this.state.relatedEntityTonnage.value && totalTonnage && isEmpty(this.state.relatedEntityTonnage.errors)) {
      const newState = {...this.state};
      newState.relatedEntityTonnage.value = totalTonnage;
      this.setState(newState);
    }
    return {relatedEntity: entity, tonnages: tonnagesData, counterContract: counterContract}
  }

  handleEntityTonnageChange = (entity, updatedTonnage, entityType) => {
    const newState = {...this.state};
    const entityCurrentTonnage = parseFloat(get(entity, 'inferredTonnage'))
    newState.relatedEntityTonnage.value = updatedTonnage;
    if (!updatedTonnage)
      newState.relatedEntityTonnage.errors = ['This field is required.'];
    else if (entityCurrentTonnage > parseFloat(updatedTonnage))
      newState.relatedEntityTonnage.errors = ['Cannot be less than current tonnage'];
    else if (entityType === 'Order' && get(entity, 'maxAllowedTonnageForAmend') && parseFloat(updatedTonnage) > get(entity, 'maxAllowedTonnageForAmend'))
      newState.relatedEntityTonnage.errors = [`Cannot be greater than ${get(entity, 'maxAllowedTonnageForAmend')} ${this.priceUnit()} due to ${get(entity, 'reason')}`];
    else
      newState.relatedEntityTonnage.errors = [];
    this.setState(newState);
  }

  updateEntityTonnage(entity, entityType) {
    if (!isEmpty(this.state.relatedEntityTonnage.errors))
      return;
    const entityCurrentTonnage = parseFloat(get(entity, 'inferredTonnage'));
    if (entityCurrentTonnage !== parseFloat(this.state.relatedEntityTonnage.value)) {
      if (entityType === 'Contract') {
        const amendedData = {
          'amended': {tonnage: this.state.relatedEntityTonnage.value},
          'communication': {acceptanceRequired: false},
          'current': {tonnage: entityCurrentTonnage}
        }
        APIService.contracts(entity.id)
          .appendToUrl('amend/')
          .post(amendedData)
          .then(async () => {
            this.props.dispatch(isLoading());
            if (entity.id === this.state.selectedBaseEntity.id)
              await this.getCommodityContract(entity.id, true);
            else
              this.fetchAndSetAllocationContract()
            this.props.dispatch(forceStopLoader());
          })
      }
      else {
        const amendedData = {
          'plannedTonnage': this.state.relatedEntityTonnage.value,
          'communication': {acceptanceRequired: false},
        }
        APIService.freights()
          .appendToUrl(`orders/${entity.id}/amend/`)
          .post(amendedData)
          .then(async () => {
            this.props.dispatch(isLoading());
            if (includes([this.state.fields.freightDelivery.orderNumber.value, this.state.fields.freightPickup.orderNumber.value], entity.identifier))
              this.handleOrderBlur(entity.identifier === this.state.fields.freightDelivery.orderNumber.value ? 'freightDelivery.orderId' : 'freightPickup.orderId', entity.identifier, true);
            else
              await this.getFreightOrder(entity.id, true);
            this.props.dispatch(forceStopLoader());
          })
      }
    }
    this.setState({openUpdateTonnagePopup: false, showHierarchy: false});
  }

  openHierarchyFor = entity => {
    const newState = {...this.state};
    newState.openHierarchyFor = entity;
    if (newState.relatedEntityTonnage.value !== get(entity, 'inferredTonnage'))
      newState.relatedEntityTonnage.value = get(entity, 'inferredTonnage')
    newState.showHierarchy = true;
    this.setState(newState)
  };

  checkpointOrderMandatoryReason(checkpoint) {
    let reason = '';
    let checkpointOrderCreationPermission = '';
    if (checkpoint == 'pickup' && this.state.pickupSettings)
      checkpointOrderCreationPermission = get(this.state.pickupSettings, 'checkpointOrderCreationPermission');
    else if (checkpoint == 'delivery' && this.state.deliverySettings)
      checkpointOrderCreationPermission = get(this.state.deliverySettings, 'checkpointOrderCreationPermission');
    if (includes([CHECKPOINT_ORDER_CREATION_PERMISSIONS.SITE_ONLY, CHECKPOINT_ORDER_CREATION_PERMISSIONS.GRAIN_OWNER_AND_SITE_ACCEPTANCE_FROM_SITE], checkpointOrderCreationPermission)) {
      const handler = checkpoint == 'pickup' ? this.state.selectedConsignor : this.state.selectedConsignee;
      let companyName = '';
      let companyAdmin = '';
      if (handler) {
        const company = find(this.props.allDirectoryCompanies, {id: handler.companyId});
        if (company) {
          companyName = company.name;
          const employees = checkpoint == 'pickup' ? [...this.state.pickupEmployees] : [...this.state.deliveryEmployees];
          if (employees && !isEmpty(employees)) {
            const companyAdmins = employees.filter(employee => employee.typeId === COMPANY_ADMIN);
            if (companyAdmins && !isEmpty(companyAdmins))
              companyAdmin = get(companyAdmins, '0.name');
            else
              companyAdmin = get(employees, '0.name');
          }
        }
      }
      reason = `This site requires that a ${checkpoint} order is created by the site prior to freight order creation. Please get in touch with ${companyAdmin} at ${companyName} for this.`
    }
    return reason;
  }

  getEligibleApplicationRates(commodityId) {
    let eligibleApplicationRates = [];
    if (commodityId && !isEmpty(this.state.chemicalApplicationRates)) {
      let _eligibleApplicationRates = this.state.chemicalApplicationRates.filter(chemicalApplicationRate => chemicalApplicationRate.commodityId === commodityId);
      if (!isEmpty(_eligibleApplicationRates)) {
        _eligibleApplicationRates.forEach(_eligibleApplicationRate => {
          eligibleApplicationRates.push({'name': _eligibleApplicationRate.rate, 'id': _eligibleApplicationRate.rate})
        })
      }
    }
    return eligibleApplicationRates;
  }

  render() {
    const canViewPickup = !this.editOrder?.id || this.editOrder.canViewPickup
    const canViewDelivery = !this.editOrder?.id || this.editOrder.canViewDelivery
    const entity = this.getOrderEntity();
    const query = new URLSearchParams(this.props.location.search);
    const commodityContractId = query.get('commodityContractId');
    const querySaleContractId = query.get('saleContractId');
    const queryPurchaseContractId = query.get('purchaseContractId');
    const foreignCurrencyAllowed = get(this.props.currentUser.company, 'foreignCurrencyAllowed');
    const contractLabel = querySaleContractId ? 'Purchase Contract' : (queryPurchaseContractId ? 'Sale Contract' : 'Contract Number')
    let isTemplateApplied = this.state.selectedBaseEntityType === 'order' && isEqual(get(this.state.selectedBaseEntityRaw, 'status'), 'template')
    const isConsignorDisabled = Boolean(((this.editOrder?.freightPickup?.consignor?.handlerId || !this.editOrder?.id) && (this.isConsignorDisabled() || get(this.state, 'hasAnyChildren')) && !isTemplateApplied) || this.state.contractStockAllocation || (this.isParentRequestOrder && this.parentRequestOrder?.typeId == PICKUP_REQUEST_ORDER_TYPE_ID));
    const isConsigneeDisabled = Boolean((this.editOrder?.freightDelivery?.consignee?.handlerId || !this.editOrder?.id) && ((this.isConsigneeDisabled() || get(this.state, 'hasAnyChildren')) && !isTemplateApplied) || (this.isParentRequestOrder && this.parentRequestOrder?.typeId == DELIVERY_REQUEST_ORDER_TYPE_ID));
    const consignorSites = this.getConsignorSites();
    const consigneeSites = this.getConsigneeSites();
    const contractConsignorSites = this.getAllocatedContractPartySites('consignor');
    const contractConsigneeSites = this.getAllocatedContractPartySites('consignee');
    const isDeliverySiteMandatory = includes(DELIVERY_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntity, 'pricePointId',  ''));
    const isPickupSiteMandatory = includes(PICKUP_MANDATORY_PRICE_POINTS, get(this.state.selectedBaseEntity, 'pricePointId',  ''));
    const spreadDetails = get(this.state.selectedBaseEntity, 'spread.details', get(this.state.selectedBaseEntity, 'spread',  ''));
    const isCallOnGrain = this.isSelectedOrderCallOnGrain()
    const isFreightOrderConditionsDisplayed = this.showConditionsSection();
    const baseEntityGradeId = this.state.selectedBaseEntityRaw?.gradeId
    const fixedGrades = uniqBy(filter(this.props.grades, grade => find(spreadDetails, { id: grade.id }) || Boolean(baseEntityGradeId && baseEntityGradeId === grade.id)), 'id');
    const isContractQueryStringExists = includes(this.props.location.search, 'commodityContractId=');
    const commissionRate = this.getCommissionRate();
    const isIndependentSiteContractOrder = this.isIndependentSiteContractOrder()
    let spreadPrice = isIndependentSiteContractOrder ? this.getSpreadPrice() : 0
    let ldPrice = isIndependentSiteContractOrder ? this.getLDPrice() : 0
    const contractPrice = this.getContractPrice()
    let totalOrderPrice = isIndependentSiteContractOrder ? (
      contractPrice + ldPrice + spreadPrice + (parseFloat(this.state.fields.additionalCost.value || 0))
    ) : 0
    const isProviderFieldDisabled = this.isProviderFieldDisabled();
    const isInvoicingFieldsDisabled = get(this.editOrder, 'isValidFreightInvoiceExists');
    const isPickupWarehouseInvoiced= get(this.editOrder, 'isValidWarehouseInvoiceExists.0');
    const isDeliveryWarehouseInvoiced = get(this.editOrder, 'isValidWarehouseInvoiceExists.1');
    const priceUnit = this.priceUnit()
    let requestedTonnage = this.state.fields.plannedTonnage.value;
    if (this.editOrder)
      requestedTonnage = parseFloat(this.state.fields.plannedTonnage.value) - parseFloat(get(this.editOrder, 'progressTonnage') || 0)

    let invoicing = INVOICINGS;
    if (!this.isEditForm) {
      invoicing = filter(invoicing, invoice => {
        return invoice.id !== 'Freight Provider to Invoice Broker';
      });
    }
    this.setConsignorSiteAndCustomerNgrIfContractStockAllocation();
    let tonnageDistributionLink = '';
    let _relatedEntity = null;
    let _relatedEntityDisplay = '';
    if (window.SHOW_ORDER_CONTRACT_TONNAGE_DISTRIBUTION) {
      const {relatedEntity, tonnages, counterContract} = this.getEntityTonnages();
      _relatedEntity = relatedEntity;
      let relatedEntityDisplay = get(relatedEntity, 'entity') === "commodityContract" ? 'Contract' : 'Order';
      _relatedEntityDisplay = get(relatedEntity, 'entity') === "commodityContract" ? 'Contract' : 'Order';
      let counterEntityDisplay = ''
      if (counterContract && relatedEntityDisplay === 'Contract') {
        if (querySaleContractId) {
          if (querySaleContractId === get(counterContract, 'id')) {
            counterEntityDisplay = 'Sale';
            relatedEntityDisplay = 'Purchase';
          }
          else {
            relatedEntityDisplay = 'Sale';
            counterEntityDisplay = 'Purchase';
          }
        }
        else if (queryPurchaseContractId) {
          if (queryPurchaseContractId === get(counterContract, 'id')) {
            counterEntityDisplay = 'Purchase';
            relatedEntityDisplay = 'Sale';
          }
          else {
            relatedEntityDisplay = 'Purchase';
            counterEntityDisplay = 'Sale';
          }
        }
      }
      const tonnageDistributionData = <React.Fragment>
        <div className='order-tonnage-distribution'>
          <EntityTonnageDetail
            entity={relatedEntity}
            counterEntity={counterContract}
            openHierarchyFor={this.openHierarchyFor}
            columns={counterContract ? 3 : 2}
            labelStyle={{color: WARM_GREY, fontSize: '0.8rem', fontWeight: '300'}}
            valueStyle={{fontSize: '0.8rem'}}
            headerStyle={{fontSize: '0.8rem', paddingLeft: '0px'}}
            entityDisplay={relatedEntityDisplay}
            counterEntityDisplay={counterEntityDisplay}
            unit={this.priceUnit()}
            items={tonnages}
          />
        </div>
      </React.Fragment>
      tonnageDistributionLink = <CommonToolTip title={tonnageDistributionData} placement="right" arrow><a rel="noopener noreferrer" style={{textDecoration: "underline", cursor: 'pointer'}}>How?</a></CommonToolTip>
    }
    const maxAllowedTonnageLabel = this.maximumAllowedTonnageLabel();
    const tonnageDisplay = <div>{this.tonnageLabel} {maxAllowedTonnageLabel} {_relatedEntity && maxAllowedTonnageLabel ? tonnageDistributionLink : ''}</div>
    const buttons = (
      <div className='cardForm-action col-md-12 top-15'>
        <CommonButton label='Cancel' default={true} variant='outlined' onClick={this.handleCancelButtonClick} />
        {
          ! (this.state.queryParamExists || this.isEditForm || this.isCallOnGrainOrder) &&
          <Tooltip title='Save template to quickly make orders' placement='top'>
            <span>
              <CommonButton label='Save Template' secondary onClick={this.handleSaveButtonClick} variant="contained" />
            </span>
          </Tooltip>
        }
        <CommonButton type='submit' label='Continue and Review' primary={true} variant="contained" disabled={!this.state.enableSubmit} />
      </div>
    );
    let canRepresent = !this.state.isDependent && !this.selectedOrderId && isCurrentUserBroker() && !this.isEditForm && !this.editOrder;
    const consignorAddress = get(this.state.selectedConsignor, 'address') || get(this.editOrder, 'freightPickup.consignor.handler.address.address');
    const consignorMobile = get(this.state.selectedConsignor, 'mobile') || get(this.editOrder, 'freightPickup.consignor.handler.mobile');
    let consignorAddressLink = '';
    if (consignorAddress)
      consignorAddressLink = <div style={{marginTop: "5px"}}>
        <div style={{ display: "block" }}>
          <span>{consignorAddress}</span>
          <IconButton
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsignorFarmForm: true})}
            size="small">
              <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        {consignorMobile &&
        <div style={{ display: "block" }}>
          <span>{toPhoneFormat(consignorMobile)}</span>
          <IconButton
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsignorFarmForm: true})}
            size="small">
              <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        }
      </div>

    const consigneeAddress = get(this.state.selectedConsignee, 'address') || get(this.editOrder, 'freightDelivery.consignee.handler.address.address');
    const consigneeMobile = get(this.state.selectedConsignee, 'mobile') || get(this.editOrder, 'freightDelivery.consignee.handler.mobile');
    let consigneeAddressLink = '';
    if (consigneeAddress)
      consigneeAddressLink = <div style={{marginTop: "5px"}}>
        <div style={{ display: "block" }}>
          <span>{consigneeAddress}</span>
          <IconButton
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsigneeFarmForm: true})}
            size="small">
              <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        {consigneeMobile &&
        <div style={{ display: "block" }}>
          <span>{toPhoneFormat(consigneeMobile)}</span>
          <IconButton
            style={{padding: "0px"}}
            onClick={() => this.setState({openConsigneeFarmForm: true})}
            size="small">
              <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
          </IconButton>
        </div>
        }
      </div>

    const consignorStorageAddress = get(this.state.selectedConsignorSite, 'address.address');
    let consignorStorageAddressLink = '';
    if (consignorStorageAddress)
      consignorStorageAddressLink = <div style={{marginTop: "5px"}}>
        <span>{consignorStorageAddress}</span>
        <IconButton
          style={{padding: "0px"}}
          onClick={() => this.setState({openConsignorStorageForm: true})}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
      </div>

    const consigneeStorageAddress = get(this.state.selectedConsigneeSite, 'address.address');
    let consigneeStorageAddressLink = '';
    if (consigneeStorageAddress)
      consigneeStorageAddressLink = <div style={{marginTop: "5px"}}>
        <span>{consigneeStorageAddress}</span>
        <IconButton
          style={{padding: "0px"}}
          onClick={() => this.setState({openConsigneeStorageForm: true})}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
      </div>
    let pickupOrderErrors = get(this.state.fields.freightPickup.orderId.errors, '0');
    if (window.SHOW_ORDER_CONTRACT_TONNAGE_DISTRIBUTION && pickupOrderErrors == 'This field can not be blank') {
      const _checkpointOrderMandatoryReason = this.checkpointOrderMandatoryReason('pickup');
      const checkpointOrderMandatoryReason = <React.Fragment>
        <div><span style={{fontSize: '0.90rem', fontWeight: 400}}>{_checkpointOrderMandatoryReason}</span></div>
      </React.Fragment>
      const checkpointOrderMandatoryReasonLink = <CommonToolTip title={checkpointOrderMandatoryReason} placement="right" arrow><a rel="noopener noreferrer" style={{textDecoration: "underline", cursor: 'pointer'}}>Why?</a></CommonToolTip>
      pickupOrderErrors = <div>{pickupOrderErrors} {checkpointOrderMandatoryReasonLink}</div>
    }

    let deliveryOrderErrors = get(this.state.fields.freightDelivery.orderId.errors, '0');
    if (window.SHOW_ORDER_CONTRACT_TONNAGE_DISTRIBUTION && deliveryOrderErrors == 'This field can not be blank') {
      const _checkpointOrderMandatoryReason = this.checkpointOrderMandatoryReason('delivery');
      const checkpointOrderMandatoryReason = <React.Fragment>
        <div><span style={{fontSize: '0.90rem', fontWeight: 400}}>{_checkpointOrderMandatoryReason}</span></div>
      </React.Fragment>
      const checkpointOrderMandatoryReasonLink = <CommonToolTip title={checkpointOrderMandatoryReason} placement="right" arrow><a rel="noopener noreferrer" style={{textDecoration: "underline", cursor: 'pointer'}}>Why?</a></CommonToolTip>
      deliveryOrderErrors = <div>{deliveryOrderErrors} {checkpointOrderMandatoryReasonLink}</div>
    }
    return (
      <div ref={this.formRef}>
        <form onSubmit={this.handleSubmitClick} noValidate>
          <div className='cardForm'>
            {this.state.isPopulated && <div className='non-existent-component hide'></div>}
            {this.removeLoader && <div className='dom-for-allocation-loader hide'></div>}
            <h4 className='cardForm-title'>General</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
              {
                ! (this.state.queryParamExists || this.isEditForm || this.isCallOnGrainOrder) &&
                  <QuickOrder
                    id="quickOrder"
                    onChange={this.handleQuickOrderChange}
                    service={this.getTemplatesService}
                    placeholder="Quick Order (Type to search...)"
                    style={{marginBottom: '25px', marginTop: '14px'}}
                  />
              }
                {(!this.selectedOrderId || isCallOnGrain || this.isParentRequestOrder) &&
                 <div className='col-md-12 padding-reset form-wrap'>
                   <CommonSelect
                     style={{ float: 'left' }}
                     id='typeId'
                     setRef={this.fieldRef['typeId']}
                     onChange={this.handleOrderTypeChange}
                     floatingLabelText={this.disableForm ? 'Select Type to proceed' : `${entity} Order Type`}
                     errorText={this.state.fields.typeId.errors[0]}
                     selectConfig={{ text: 'displayName', value: 'id' }}
                     items={isEmpty(this.state.typeItems) ? this.props.types : this.state.typeItems}
                     value={isCallOnGrain ? FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER : this.state.fields.typeId.value}
                     disabled={Boolean((this.state.queryParamExists && !this.isParentRequestOrder) || this.isEditForm || this.isCallOnGrainOrder)}
                   />
                 </div>
                }
                {!this.isEditForm ? (
                   <div className='col-md-12 padding-reset form-wrap'>
                     <TextField
                       id='identifier'
                       variant='standard'
                       inputRef={this.fieldRef['identifier']}
                       error={!isEmpty(this.state.fields.identifier.errors[0])}
                       placeholder='Please enter unique identifier'
                       label={this.isFreightAllocation() ? 'Allocation No' : 'Order No'}
                       value={this.state.fields.identifier.value}
                       helperText={this.state.fields.identifier.errors[0]}
                       onChange={this.handleIdentifierChange}
                       onBlur={this.handleBlur}
                       fullWidth
                       disabled={this.disableForm}
                       inputProps={{
                         maxLength: 22,
                       }}
                     />
                   </div>
                ) : (
                   <div className='col-md-12 padding-reset form-wrap'>
                     <CommonTextField id='identifier' label='Order No' value={this.state.fields.identifier.value} disabled />
                   </div>
                )}
                {
                  (get(this.state.fields.typeId, 'value') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || this.isCallOnGrainOrder) && !this.isEditForm && !this.selectedOrderId && !commodityContractId &&
                  <div className="col-md-12 padding-reset form-wrap" style={{minHeight: '50px', height: '50px'}}>
                    <Grid container alignItems="center" spacing={1} wrap='nowrap'>
                      <Grid item style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.54)', paddingRight: '50px' }}>Contract exists</Grid>
                      <Grid item>Outside AgriChain</Grid>
                      <Grid item>
                        <Switch color='primary' checked={this.state.isDependent} onChange={this.isDependentCheck} name="contractExists" />
                      </Grid>
                      <Grid item>On AgriChain</Grid>
                    </Grid>
                  </div>
                }
              </div>
              <div className='col-md-5 col-md-offset-1'>
                {this.state.fields.typeId.value &&
                 this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER &&
                 this.state.selectedBaseEntityType !== 'order' &&
                 !this.state.queryParamExists &&
                 !this.isEditForm && (
                   <div className='col-md-12 padding-reset form-wrap'>
                     <CommonAutoSelect
                       items={this.getCustomers()}
                       setRef={this.fieldRef['filterParty']}
                       id='filterParty'
                       label='Filter By Seller/Buyer'
                       value={this.state.filterParty}
                       style={{ float: 'left' }}
                       onChange={this.applyFiltersByParty}
                       disabled={!this.state.isDependent}
                       dontAutoselectSingleItem
                     />
                   </div>
                 )}
                {!this.state.isDependent && !this.isEditForm &&
                 <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity' style={{marginTop: '16px'}}>
                   <CommonTextField
                     id='externalReferenceNumber'
                     placeholder='Contract Reference No.'
                     setRef={this.fieldRef["externalReferenceNumber"]}
                     maxLength={25}
                     value={this.state.fields.externalReferenceNumber.value}
                     onChange={this.onFieldChange}
                     helperText={this.state.fields.externalReferenceNumber.errors[0]}
                     onBlur={this.handleReferenceNumberBlur}
                   />
                 </div>
                }
                {
                  this.isEditForm && (
                    get(this.editOrder, 'commodityContractId') || get(this.editOrder, 'externalReferenceNumber')
                  ) ? (
                    <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity'>
                      <CommonTextField
                        id='commodityContractId'
                        label='Contract Number'
                        value={get(this.editOrder, 'commodityContract.displayName') || get(this.editOrder, 'externalReferenceNumber') || ''}
                        disabled
                      />
                    </div>
                  ) :
                    isContractQueryStringExists ?
                    (
                      <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity'>
                        <CommonTextField
                          id='commodityContractId'
                          label={contractLabel}
                          value={get(this.state.selectedBaseEntity, 'displayName') || get(this.editOrder, 'externalReferenceNumber') || ''}
                          disabled
                        />
                      </div>
                    ) : (
                      (this.state.isDependent && ((this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER &&
                                                   this.state.selectedBaseEntityType !== 'order') || this.isCallOnGrainOrder)) &&
                        <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity'>
                          <CommonAutoSelect
                            items={this.state.filteredContracts}
                            id='commodityContractId'
                            setRef={this.fieldRef['commodityContractId']}
                            dataSourceConfig={{ text: 'displayName', value: 'id' }}
                            label='Contract Number'
                            value={this.state.fields.commodityContractId.value}
                            errorText={
                              this.state.filterParty && isEmpty(this.state.filteredContracts)
                                ? NO_FILTERED_CONTRACTS
                                : this.state.fields.commodityContractId.errors[0]
                            }
                            style={{ float: 'left' }}
                            onChange={this.handleBaseEntityChange}
                            disabled={(this.state.queryParamExists || this.isEditForm) && !this.isParentRequestOrder}
                            dontAutoselectSingleItem
                          />
                        </div>
                    )
                }
                {
                  Boolean(querySaleContractId) &&
                    <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity'>
                      <CommonTextField
                        id='saleContractId'
                        label='Sale Contract'
                        value={this.state.allocatedContract?.displayName}
                        disabled
                      />
                    </div>
                }
                {
                  Boolean(queryPurchaseContractId) &&
                    <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity'>
                      <CommonTextField
                        id='purchaseContractId'
                        label='Purchase Contract'
                        value={this.state.allocatedContract?.displayName}
                        disabled
                      />
                    </div>
                }
                {!isEqual(get(this.state.selectedBaseEntity, 'status'), 'template') && (this.state.selectedBaseEntityType === 'order' || get(this.editOrder, 'parentOrder')) && (
                   <div className='col-md-12 padding-reset form-wrap freight-movement-base-entity'>
                     <CommonTextField
                       id='parentOrderId'
                       label={isCallOnGrain ? 'Grain Order Number' : 'Order Number'}
                       value={get(this.state.selectedBaseEntity, 'identifier') || get(this.editOrder, 'parentOrder.identifier') || ''}
                       disabled
                     />
                   </div>
                )}
              </div>
            </div>
          </div>
          {
            !this.state.isDependent && !isCallOnGrain &&
            <CounterPartiesInfo
              fieldRef={this.fieldRef}
              onChange={this.updateParty}
              validate={this.state.partyValidate}
              title={this.isCallOnGrainOrder ? 'Counterparties' : 'Commodity Contract Parties'}
              userCompanyId={this.props.currentUser.companyId}
              canRepresent={canRepresent}
              orderType={this.state.fields.typeId.value}
              defaultSelectedEntity={this.editOrder}
              isNotIndependent={this.state.isDependent}
              editForm={this.isEditForm}
              selectedTemplate={this.state.isDependent}
            />
          }
          {(this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER || isCallOnGrain || this.isCallOnGrainOrder) && this.state.isDependent && this.state.seller && this.state.buyer && (
             <div className='contract-details-container'>
               <CommodityContractParties
                 seller={get(this.state, 'seller')}
                 buyer={get(this.state, 'buyer')}
                 buyerContact={get(this.state, 'buyer.contact')}
                 sellerContact={get(this.state, 'seller.contact')}
                 title={this.isCallOnGrainOrder ? 'Counterparties' : 'Commodity Contract Parties'}
               />
             </div>
          )}
          {(!this.isCallOnGrainOrder) &&
           <div className='cardForm'>
             <h4 className='cardForm-title'>Counterparties</h4>
             <div className='cardForm-content'>
               <div className='col-md-5'>
                 <div className='col-md-12 form-wrap padding-reset'>
                   {
                     ((this.state.isDependent && this.selectedOrderId && !this.isSelectedOrderExternalCallOnGrain() && this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER)
                      || (this.isEditForm || this.isParentRequestOrder) && get(this.state.fields.customer, 'company.displayName')) ? (
                       <CommonTextField
                         id="customer.companyId"
                         label="Customer Name"
                         value={get(this.state.fields.customer, 'company.displayName')}
                         disabled
                       />
                     ) :
                     <React.Fragment>
                       <CommonAutoSelect
                         id="customer.companyId"
                         items={isEmpty(this.state.customerItems) ? uniqBy(this.getCustomers(), 'id') : uniqBy(this.state.customerItems, 'id')}
                         setRef={this.fieldRef["customer.companyId"]}
                         label="Customer Name"
                         value={this.state.fields.customer.companyId.value}
                         errorText={this.state.fields.customer.companyId.errors[0]}
                         style={{ float: 'left' }}
                         onChange={this.handleCustomerChange}
                         disabled={this.state.isDependent ? this.isSelectedOrderCallOnGrain() ? false : (this.isEditForm || this.selectedOrderId || this.disableForm || this.state.fields.typeId.value === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER) : false}
                         dontAutoselectSingleItem
                         selectedItem={this.state.selectedCustomer}
                       />
                       {
                         isEqual(this.state.fields.typeId.value, FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) &&
                         <AddCircleIconWithTooltip
                           id="addCustomerCompany"
                           onClick={() => this.openCompanySideDrawer('customer')}
                           title="Add Customer"
                         />
                       }
                     </React.Fragment>
                   }
                 </div>
                 <div className='col-md-12 form-wrap padding-reset'>
                   <CommonAutoSelect
                     items={uniqBy(this.state.customerContacts, 'id')}
                     setRef={this.fieldRef['customer.contactId']}
                     id='customer.contactId'
                     value={this.state.fields.customer.contactId.value}
                     style={{ float: 'left' }}
                     floatingLabelText='Customer Contact'
                     label='Customer Contact'
                     disabled={this.disableForm}
                     errorText={this.state.fields.customer.contactId.errors[0]}
                     onChange={this.handleSelectFieldChange}
                   />
                   {this.addCustomerContactButton()}
                 </div>
                 <div className='col-md-12 form-wrap padding-reset'>
                   {this.isEditForm && get(this.editOrder, 'typeId') === FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER ? (
                      <CommonTextField id='customer.ngrId' label='Customer NGR' value={get(this.state, 'customerNgr.ngrNumber') || ''} disabled />
                   ) : (
                      <CommonAutoSelect
                        items={this.state.customerNgrs}
                        setRef={this.fieldRef['customer.ngrId']}
                        dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                        id='customer.ngrId'
                        label='Customer NGR (Optional)'
                        value={this.state.fields.customer.ngrId.value}
                        errorText={this.state.fields.customer.ngrId.errors[0]}
                        style={{ float: 'left' }}
                        disabled={this.disableForm || (!this.isEditForm && !this.state.isDependent) || this.state.contractStockAllocation}
                        onChange={this.handleSelectFieldChange}
                      />
                   )}
                   {this.addCustomerNgrButton()}
                 </div>
               </div>
               <div
                 className='col-md-5 col-md-offset-1'
                 style={this.showProviderAndInvoicingSection() ? { display: 'inline-block' } : { display: 'none' }}
               >
                 <div className='col-md-12 form-wrap padding-reset'>
                   <CommonAutoSelect
                     items={isEmpty(this.state.providerItems) || !isProviderFieldDisabled ? uniqBy(this.getProviders(), 'id') : uniqBy(this.state.providerItems, 'id')}
                     setRef={this.fieldRef['providerId']}
                     id='providerId'
                     label='Freight Provider'
                     value={this.state.fields.providerId.value}
                     errorText={this.state.fields.providerId.errors[0]}
                     style={{ float: 'right' }}
                     onChange={this.handleProviderChange}
                     dontAutoselectSingleItem
                     disabled={isProviderFieldDisabled}
                   />
                 </div>
                 <div className='col-md-12 form-wrap padding-reset'>
                   <CommonAutoSelect
                     items={this.state.providerEmployees}
                     setRef={this.fieldRef['assignToId']}
                     id='assignToId'
                     value={this.state.fields.assignToId.value}
                     floatingLabelText='Freight Provider Contact'
                     label='Freight Provider Contact'
                     errorText={this.state.fields.assignToId.errors[0]}
                     style={{ float: 'right' }}
                     onChange={this.handleSelectFieldChange}
                     disabled={(this.isEditForm && !this.showInvoicingSectionOnEdit()) || this.disableForm}
                   />
                   {((this.isEditForm && this.showInvoicingSectionOnEdit()) || !this.isEditForm) && this.addFreightProviderContactButton()}
                 </div>
               </div>
             </div>
           </div>}
          <div className='cardForm'>
            <h4 className='cardForm-title'>{`${entity} Order Details`}</h4>
            <div className='cardForm-content'>
              <div className='col-md-5'>
                <div className='col-md-12 form-wrap padding-reset'>
                  {this.isEditForm ? (
                     <CommonTextField
                       id='commodityId'
                       label='Commodity'
                       style={{ float: 'left' }}
                       disabled={true}
                       value={get(this, 'editOrder.commodity.displayName')}
                     />
                  ) : (
                     <CommodityAutoComplete
                       id='commodityId'
                       setRef={this.fieldRef['commodityId']}
                       onChange={this.handleCommodityChange}
                       floatingLabelText='Commodity'
                       commodityId={this.state.fields.commodityId.value}
                       style={{ float: 'left' }}
                       errorText={this.state.fields.commodityId.errors[0]}
                       disabled={(!this.isDuplicatingOrder && !!get(this.state.selectedBaseEntity, 'commodityId') && !isEqual(get(this.state.selectedBaseEntity, 'status'), 'template')) || this.isEditForm || this.disableForm || this.isParentRequestOrder}
                     />
                  )}
                </div>
                <div className='col-md-12 form-wrap padding-reset'>
                  <VarietyAutoComplete
                    id='varietyId'
                    setRef={this.fieldRef['varietyId']}
                    label='Variety (Optional)'
                    commodityId={this.state.fields.commodityId.value}
                    varietyId={this.state.fields.varietyId.value}
                    dependsOnCommodity
                    onChange={this.handleVarietyChange}
                    style={{ float: 'left' }}
                    errorText={this.state.fields.varietyId.errors[0]}
                    onBlur={this.handleBlur}
                  />
                </div>
                {
                  this.state.isParentContractBlendContract ?
                  <div className='col-md-12 form-wrap padding-reset'>
                    <CommonTextField
                      id="plannedGradeId"
                      label="Grade"
                      value={get(this.state.selectedBaseEntity, 'gradeName')}
                      disabled
                    />
                  </div>
                  :
                  <div className='col-md-12 form-wrap padding-reset'>
                    <GradeAutoComplete
                      id="plannedGradeId"
                      setRef={this.fieldRef["plannedGradeId"]}
                      style={{float: 'left'}}
                      commodityId={this.state.fields.commodityId.value}
                      varietyId={this.state.fields.varietyId.value}
                      varietyWarning={msg => this.setFieldCustomErrors('varietyId', msg)}
                      gradeId={this.state.fields.plannedGradeId.value}
                      selectedGradeId={this.state.fields.plannedGradeId.value}
                      season={this.state.fields.season.value}
                      dependsOnCommodity
                      dependsOnSeason
                      onChange={this.handleGradeChange}
                      disabled={!this.isDuplicatingOrder && ((!isEqual(get(this.state.selectedBaseEntity, 'status'), 'template') && this.isGradeDisable()) || this.state.contractStockAllocation)}
                      errorText={this.state.fields.plannedGradeId.errors[0]}
                      onBlur={this.handleBlur}
                      fixedGrades={fixedGrades}
                      displayNameOnly={get(this.state.selectedBaseEntity,'isPoolContract') && this.state.fields.commodityId.value}
                    />
                  </div>
                }
                <div className='col-md-12 form-wrap padding-reset'>
                  <SeasonSelect
                    id='season'
                    setRef={this.fieldRef['season']}
                    onChange={this.handleSelectFieldChange}
                    season={this.state.fields.season.value}
                    style={{ float: 'left' }}
                    errorText={this.state.fields.season.errors[0]}
                    disabled={!this.isDuplicatingOrder && !isEqual(get(this.state.selectedBaseEntity, 'status'), 'template') && !!get(this.state.selectedBaseEntity, 'season') || this.checkCustomerOnlyEditCheck() || this.isParentRequestOrder}
                  />
                </div>
              </div>
              <div className="col-md-5 form-wrap padding-reset col-md-offset-1">
                {
                  this.hasQuantityBasedCommodity() ?
                    <React.Fragment>
                      <div className="col-md-12">
                        <NumberField
                          id="quantity"
                          label={this.quantityLabel() + this.maximumAllowedQuantityLabel()}
                          setRef={this.fieldRef["quantity"]}
                          value={get(this.editOrder, 'quantity') || this.state.fields.quantity.value}
                          maxValue={99999.99}
                          helperText={this.state.fields.quantity.errors[0]}
                          onChange={this.handleQuantityFieldChange}
                          onBlur={this.handleFieldChange}
                          InputProps={{
                            endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{this.quantityUnit()}</InputAdornment>
                          }}
                          disabled={(this.isEditForm && includes(['completed'], get(this.editOrder, 'status')))}
                        />
                      </div>
                      <div className="col-md-12 form-wrap" style={{paddingTop: '39px'}}>
                        <NumberField
                          id="plannedTonnage"
                          label={tonnageDisplay}
                          setRef={this.fieldRef["plannedTonnage"]}
                          value={this.state.fields.plannedTonnage.value}
                          maxValue={999999999.99}
                          helperText={this.state.fields.plannedTonnage.errors[0]}
                          onChange={this.handleTonnageFieldChange}
                          onBlur={this.handleTonnageFieldChange}
                          InputProps={{
                            endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{MT_UNIT}</InputAdornment>
                          }}
                          disabled={(this.isEditForm && includes(['completed'], get(this.editOrder, 'status')))}
                        />
                      </div>
                    </React.Fragment> :
                  <div className="col-md-12">
                    <NumberField
                      id="plannedTonnage"
                      label={tonnageDisplay}
                      onPaste={this.handlePaste}
                      setRef={this.fieldRef["plannedTonnage"]}
                      value={this.state.fields.plannedTonnage.value}
                      maxValue={999999999.99}
                      helperText={this.state.fields.plannedTonnage.errors[0]}
                      onChange={this.handleTonnageFieldChange}
                      onBlur={this.handleTonnageFieldChange}
                      InputProps={{
                        endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{priceUnit}</InputAdornment>
                      }}
                      disabled={this.disableForm}
                    />
                  </div>
                }
                <div className="col-md-12 form-wrap" style={{paddingTop: '39px'}}>
                  <TransportModeSelect
                    id='transportMode'
                    setRef={this.fieldRef['transportMode']}
                    onChange={this.handleSelectFieldChange}
                    season={this.state.fields.transportMode.value}
                    errorText={this.state.fields.transportMode.errors[0]}
                  />
                </div>
              </div>
            </div>
          </div>
          {
            this.state.isParentContractBlendContract && !isEmpty(this.state.blendGradeDetails) &&
            <div className='cardForm'>
              <h4 className='cardForm-title'>Blend Details</h4>
              {
                map(this.state.blendGradeDetails, (obj, index) => (
                  <div key={index}>
                    <div className="col-md-9" style={{paddingBottom: "20px"}}>
                      <div className="col-md-3">
                        <GradeAutoComplete
                          floatingLabelText={`Grade ${index + 1}`}
                          commodityId={this.state.fields.commodityId.value}
                          gradeId={obj.gradeId}
                          selectedGradeId={obj.gradeId}
                          disabled
                          dependsOnCommodity
                        />
                      </div>
                      <div className="col-md-3">
                        <CommonTextField
                          label={`Grade ${index + 1} %`}
                          value={obj.percentage}
                          helperText={get(obj, 'errors.0')}
                          type="number"
                          onChange={(event) => this.handleBlendGradePercentageChange(obj, event.target.value)}
                          onKeyDown={(event) => positiveDecimalFilter(event, 2, 999999999.99)}
                          InputProps={{
                            endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>%</InputAdornment>
                          }}
                        />
                      </div>
                      <div className="col-md-3">
                        <CommonTextField
                          label={`Grade ${index + 1} ${this.tonnageLabel} (${priceUnit})`}
                          value={obj.tonnage}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                ))
              }
            </div>
          }
          {
            this.state.isParentContractBlendContract &&
            <div className='cardForm'>
              <h4 className='cardForm-title'>Application Details</h4>
              {
                map(this.state.chemicalApplications, (obj, index) => (
                  <div key={index}>
                    <div className="col-md-10" style={{paddingBottom: "20px"}}>
                      <div className="col-md-3">
                      <CommodityAutoComplete
                        id='commodity'
                        commodityId={obj.commodityId}
                        selected={obj.commodity}
                        onChange={(value) => this.onChemicalApplicationCommodityChange(obj, value)}
                        label='Application Commodity'
                        itemFilterFunc={commodities => filter(commodities, {type: 'chemical'})}
                      />
                      </div>
                      <div className="col-md-3">
                        <CommonAutoSelect
                          id='applicationRate'
                          disabled={!obj.commodityId}
                          onChange={value => this.handleApplicationRateChange(obj, value)}
                          label={`Application Rate (Optional) (L/${this.priceUnit()})`}
                          dataSourceConfig={{ text: 'name', value: 'id' }}
                          value={obj.applicationRate}
                          items={this.getEligibleApplicationRates(obj.commodityId)}
                        />
                      </div>
                      <div className="col-md-3">
                        <GradeAutoComplete
                          floatingLabelText="Apply On"
                          commodityId={this.state.fields.commodityId.value}
                          gradeId={obj.gradeId}
                          selectedGradeId={obj.gradeId}
                          itemFilterFunc={grades => filter(grades, grade => includes(obj.gradeIds, grade.id))}
                          dependsOnCommodity
                          onChange={item => this.onChemicalApplicationGradeChange(obj, item)}
                        />
                      </div>
                      <div className='col-md-1'>
                        <IconButton onClick={() => this.onChemicalApplicationDelete(obj)} color='error'>
                          <RemoveIcon fontSize='inherit' />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                ))
              }
              <div className='col-xs-12 padding-reset' style={{marginTop: '30px'}}>
                <AddButton label='Application' onClick={() => this.onChemicalApplicationAdd()} style={{float: 'left'}} />
              </div>
            </div>
          }
          <div className='row'>
                {
                  canViewPickup &&
            <div className='col-md-6' style={{paddingRight: '5px'}}>
              <div className='cardForm'>
                <h4 className='cardForm-title'>Pickup Details</h4>
                <div className='cardForm-content'>
                  <div className='col-md-12 form-wrap' ref={this.fieldRef['freightPickup.consignor.handlerId']}>
                  {!isEmpty(contractConsignorSites) || isPickupSiteMandatory ?
                    <Autocomplete
                      id="freightPickup.consignor.handlerId"
                      blurOnSelect
                      options={!isEmpty(contractConsignorSites) ? contractConsignorSites : isPickupSiteMandatory ? consignorSites : null}
                      disabled={isConsignorDisabled}
                      popupIcon={isConsignorDisabled ? <i className="icon-lock-inline"></i> : undefined}
                      filterOptions={(options, params) => {
                        const filtered = autocompleteFilters(options, params);
                        if (params.inputValue !== '' && !isPickupSiteMandatory) {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}" as a new site`,
                          });
                        }
                        return filtered;
                      }}
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.name;
                        }
                        return option.name;
                      }}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label='Pickup Site'
                          fullWidth
                          helperText={this.state.fields.freightPickup.consignor.handlerId.errors[0] || consignorAddressLink}
                          error={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                          inputProps={{
                            ...params.inputProps,
                            className: params.inputProps.className + ' black-text'
                          }}
                        />
                      )}
                      classes={{inputRoot: this.props.classes.inputRoot}}
                      value={get(this.state, 'selectedConsignor', '')}
                      onInputChange={this.handleConsignorInputChange}
                      onChange={this.handleConsignorChange}
                    /> :
                    <SiteAsyncAutocomplete
                      limitTags={2}
                      label="Pickup Site"
                      id="freightPickup.consignor.handlerId"
                      onChange={item => this.handleConsignorChange(null, item)}
                      selected={get(this.state, 'selectedConsignor', '')}
                      minLength={3}
                      variant="standard"
                      classes={{inputRoot: this.props.classes.inputRoot}}
                      addSiteOption
                      disabled={isConsignorDisabled}
                      fullWidth
                      activeSitesOnly
                      addLabel
                      helperText={consignorAddressLink}
                      errorText={this.state.fields.freightPickup.consignor.handlerId.errors[0]}
                      farmId={this.state.selectedConsignorId ? this.state.selectedConsignorId : null}
                    />
                  }
                  </div>
                  {this.state.openConsignorFarmForm &&
                    <UpdateLocation updateEntities={(address, mobile) => this.updateFarmEntities(get(this.state, 'selectedConsignor.id'), address, mobile, 'pickup')}  entityId={get(this.state, 'selectedConsignor.id')} entity='farm' onCloseDrawer={() => this.setState({openConsignorFarmForm: false})}/>
                  }
                  <div className='col-md-12 form-wrap' style={{marginTop: '15px'}}>
                    <CommonAutoSelect
                      items={this.state.consignorSites}
                      setRef={this.fieldRef['freightPickup.consignor.site.locationId']}
                      id='freightPickup.consignor.site.locationId'
                      label='Pickup Storage (Optional)'
                      value={this.state.fields.freightPickup.consignor.site.locationId.value}
                      errorText={this.state.fields.freightPickup.consignor.site.locationId.errors[0]}
                      helperText={consignorStorageAddressLink}
                      style={{ float: 'left' }}
                      onChange={this.handleSiteChange}
                      selectedItem={this.state.selectedConsignorSite}
                      dontAutoselectSingleItem
                      disabled={
                        ((get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' &&
                          !get(this.state.selectedBaseEntityRaw, 'isCallOnGrain') &&
                          (get(this.state.selectedBaseEntity, 'consignorLocationId') ||
                           get(this.state.selectedBaseEntity, 'freightPickup.consignor.sites.0.locationId'))) ||
                         (this.isEditForm && includes(['delivered', 'in_progress', 'completed'], get(this.editOrder, 'status')))
                        || this.disableForm) && false
                      }
                    />
                  </div>
                  {this.state.openConsignorStorageForm &&
                      <UpdateLocation updateEntities={() => this.getSites(get(this.state, 'selectedConsignor.id'), 'farm', 'consignor')} selectedStorage={this.state.selectedConsignorSite} entity='storage' onCloseDrawer={() => this.setState({openConsignorStorageForm: false})} farmId={get(this.state, 'selectedConsignor.id')} companyId={get(this.state, 'selectedConsignor.companyId')}/>
                  }
                  <div className='col-md-12 form-wrap'>
                    <CommonTextField
                      id='freightPickup.orderNumber'
                      setRef={this.fieldRef['freightPickup.orderNumber']}
                      label={ this.isPickupOrderNumberMandatory() ? 'Pickup Order No' : 'Pickup Order No (Optional)' }
                      value={this.state.fields.freightPickup.orderNumber.value}
                      maxLength='10'
                      helperText={pickupOrderErrors}
                      onChange={this.handleFieldChange}
                      onBlur={event => this.handleOrderBlur('freightPickup.orderId', event.target.value)}
                      disabled={
                        (get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' && get(this.state.selectedBaseEntity, 'pickupOrderNumber') && !this.isFreightAllocation())
                        || (this.editForm && get(this.editOrder, 'movementTonnage')) || this.disableForm|| (this.isParentRequestOrder && this.parentRequestOrder?.typeId == PICKUP_REQUEST_ORDER_TYPE_ID)
                      }
                    />
                  </div>
                  {
                    !this.isPickupOrderNumberMandatory(false) && !get(this.editOrder, 'movementTonnage') && (get(this.state.selectedBaseEntityRaw, 'entity') !== 'freightorder' || !get(this.state.selectedBaseEntity, 'pickupOrderNumber')) &&
                    <div className='col-md-12 form-wrap'>
                      <Grid component="label" container alignItems="center" spacing={1} wrap='nowrap'>
                        <Grid item style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.54)', paddingRight: '50px' }}>Pickup order exists</Grid>
                        <Grid item>Outside AgriChain</Grid>
                        <Grid item>
                          <Switch color='primary' checked={this.state.pickupCheckpointOrderExists} onChange={event => this.onCheckpointOrderExistsChange(event, 'pickup')} name='pickupCheckpointExists' />
                        </Grid>
                        <Grid item>On AgriChain</Grid>
                      </Grid>
                    </div>
                  }
                  <div className='col-md-12 form-wrap'>
                    <CommonDatePicker
                      id='freightPickup.date'
                      setRef={this.fieldRef['freightPickup.date']}
                      floatingLabelText='Delivery Start Date'
                      onChange={this.handleSelectFieldChange}
                      errorText={this.state.fields.freightPickup.date.errors[0]}
                      value={this.state.fields.freightPickup.date.value}
                      style={{ float: 'left' }}
                      minDate={this.pickUpDateMin()}
                      maxDate={this.pickUpDateMax()}
                      disabled={this.disableForm}
                    />
                  </div>
                  {!this.isCallOnGrainOrder &&
                  <div className="col-md-12 form-wrap top-15">
                    <CommonTextField
                      id="freightPickup.instructions"
                      setRef={this.fieldRef["freightPickup.instructions"]}
                      label="Pickup Instructions (Optional)"
                      value={this.state.fields.freightPickup.instructions.value}
                      helperText={this.state.fields.freightPickup.instructions.errors[0]}
                      disabled={this.disableForm || (this.isEditForm && get(this.editOrder, 'isInstructionsEditDisabled'))}
                      onChange={this.handleFieldChange}
                      multiline={true}
                      rows={2}
                      rowsMax={2}
                      onBlur={this.handleBlur}
                    />
                  </div>
                  }
                  {this.state.throughputOutloadCheckbox &&
                  <div className='col-md-12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='throughputOutloadOrder'
                          color="primary"
                          checked={this.state.throughputOutloadOrder}
                          onChange={this.handleThroughputOrderChange}
                          disabled={isPickupWarehouseInvoiced}
                        />
                      }
                      label="Is Throughput Order"
                    />
                  </div>
                  }
                </div>
              </div>
            </div>
                }

            {
              canViewDelivery &&
            <div className='col-md-6' style={{paddingLeft: '5px'}}>
              <div className='cardForm'>
                <h4 className='cardForm-title'>Delivery Details</h4>
                <div className='cardForm-content'>
                  <div className='col-md-12 form-wrap' ref={this.fieldRef['freightDelivery.consignee.handlerId']}>
                    {!isEmpty(contractConsigneeSites) || isDeliverySiteMandatory ?
                      <Autocomplete
                      id="freightDelivery.consignee.handlerId"
                      blurOnSelect
                      options={!isEmpty(contractConsigneeSites) ? contractConsigneeSites : isDeliverySiteMandatory ? consigneeSites : null}
                      getOptionLabel={option => {
                        if (typeof option === 'string') {
                          return option;
                        }
                        if (option.inputValue) {
                          return option.name;
                        }
                        return option.name;
                      }}
                      filterOptions={(options, params) => {
                        const filtered = autocompleteFilters(options, params);
                        if (params.inputValue !== '' && !isDeliverySiteMandatory) {
                          filtered.push({
                            inputValue: params.inputValue,
                            name: `Add "${params.inputValue}" as a new site`,
                          });
                        }
                        return filtered;
                      }}
                      disabled={isConsigneeDisabled}
                      popupIcon={isConsigneeDisabled ? <i className="icon-lock-inline"></i> : undefined}
                      renderInput={params => (
                        <TextField
                          {...params}
                          variant="standard"
                          label='Delivery Site'
                          fullWidth
                          error={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                          helperText={this.state.fields.freightDelivery.consignee.handlerId.errors[0] || consigneeAddressLink}
                          inputProps={{
                            ...params.inputProps,
                            className: params.inputProps.className + ' black-text'
                          }}
                        />
                      )}
                      classes={{inputRoot: this.props.classes.inputRoot}}
                      value={get(this.state, 'selectedConsignee', '')}
                      onInputChange={this.handleConsigneeInputChange}
                      onChange={this.handleConsigneeChange}
                      /> :
                    <SiteAsyncAutocomplete
                      limitTags={2}
                      label="Delivery Site"
                      id="freightDelivery.consignee.handlerId"
                      onChange={item => this.handleConsigneeChange(null, item)}
                      selected={get(this.state, 'selectedConsignee', '')}
                      minLength={3}
                      variant="standard"
                      classes={{inputRoot: this.props.classes.inputRoot}}
                      addSiteOption
                      disabled={isConsigneeDisabled}
                      fullWidth
                      activeSitesOnly
                      addLabel
                      helperText={consigneeAddressLink}
                      errorText={this.state.fields.freightDelivery.consignee.handlerId.errors[0]}
                      farmId={this.state.selectedConsigneeId ? this.state.selectedConsigneeId : null}
                    />
                  }
                  </div>
                  {this.state.openConsigneeFarmForm &&
                    <UpdateLocation updateEntities={(address, mobile) => this.updateFarmEntities(get(this.state, 'selectedConsignee.id'), address, mobile, 'delivery')} entityId={get(this.state, 'selectedConsignee.id')} entity='farm' onCloseDrawer={() => this.setState({openConsigneeFarmForm: false})}/>
                  }
                  <div className='col-md-12 form-wrap' style={{marginTop: '15px'}}>
                    <CommonAutoSelect
                      items={filter(this.state.consigneeSites, site => {
                          return site.entity !== 'farmfield';
                      })}
                      setRef={this.fieldRef['freightDelivery.consignee.site.locationId']}
                      id='freightDelivery.consignee.site.locationId'
                      label='Delivery Storage (Optional)'
                      value={this.state.fields.freightDelivery.consignee.site.locationId.value}
                      errorText={this.state.fields.freightDelivery.consignee.site.locationId.errors[0]}
                      helperText={consigneeStorageAddressLink}
                      style={{ float: 'right' }}
                      onChange={this.handleSiteChange}
                      selectedItem={this.state.selectedConsigneeSite}
                      dontAutoselectSingleItem
                      disabled={
                        ((get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' &&
                          !get(this.state.selectedBaseEntityRaw, 'isCallOnGrain') &&
                          (get(this.state.selectedBaseEntity, 'consigneeLocationId') ||
                           get(this.state.selectedBaseEntity, 'freightDelivery.consignee.sites.0.locationId'))) ||
                         (this.isEditForm && includes(['delivered', 'in_progress', 'completed'], get(this.editOrder, 'status')))
                        || this.disableForm) && false
                      }
                    />
                  </div>
                  {this.state.openConsigneeStorageForm &&
                      <UpdateLocation updateEntities={() => this.getSites(get(this.state, 'selectedConsignee.id'), 'farm', 'consignee')} selectedStorage={this.state.selectedConsigneeSite} entity='storage' onCloseDrawer={() => this.setState({openConsigneeStorageForm: false})} farmId={get(this.state, 'selectedConsignee.id')} companyId={get(this.state, 'selectedConsignee.companyId')}/>
                  }
                  <div className='col-md-12 form-wrap'>
                    { (querySaleContractId && !isEmpty(this.state.deliveryOrderNumbers)) ?
                    <Autocomplete
                      id='freightDelivery.orderNumber'
                      value={this.state.deliveryOrder}
                      label='Delivery Order No'
                      options={this.state.deliveryOrderNumbers}
                      errorText={this.state.fields.freightDelivery.orderNumber.errors[0]}
                      getOptionLabel={option => option?.identifier || ''}
                      renderInput={ params =>
                      <TextField {...params}
                        label='Delivery Order No'
                        variant='standard'
                        helperText={this.state.fields.freightDelivery.orderNumber.errors[0]}
                        error={this.state.fields.freightDelivery.orderNumber.errors[0]}
                        inputRef={this.fieldRef["freightDelivery.orderNumber"]}
                      />
                      }
                      onChange={(event, item) => this.handleDeliveryOrderNumberChange(event, item)}
                      disabled={
                        (get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' && get(this.state.selectedBaseEntity, 'deliveryOrderNumber'))
                        || (this.editForm && get(this.editOrder, 'movementTonnage')) || this.disableForm || (this.isParentRequestOrder && this.parentRequestOrder?.typeId == DELIVERY_REQUEST_ORDER_TYPE_ID)}
                    />
                    :
                    <CommonTextField
                      id='freightDelivery.orderNumber'
                      setRef={this.fieldRef['freightDelivery.orderNumber']}
                      value={this.state.fields.freightDelivery.orderNumber.value}
                      label={this.isDeliveryOrderNumberMandatory() ? 'Delivery Order No' : 'Delivery Order No (Optional)'}
                      maxLength='10'
                      helperText={deliveryOrderErrors}
                      onChange={this.handleFieldChange}
                      onBlur={event => this.handleOrderBlur('freightDelivery.orderId', event.target.value)}
                      disabled={
                        (get(this.state.selectedBaseEntityRaw, 'entity') === 'freightorder' && get(this.state.selectedBaseEntity, 'deliveryOrderNumber') && !this.isFreightAllocation())
                        || (this.editForm && get(this.editOrder, 'movementTonnage')) || this.disableForm || (this.isParentRequestOrder && this.parentRequestOrder?.typeId == DELIVERY_REQUEST_ORDER_TYPE_ID)}
                    />
                    }
                  </div>
                  {
                    !this.isDeliveryOrderNumberMandatory(false) && !get(this.editOrder, 'movementTonnage') && (get(this.state.selectedBaseEntityRaw, 'entity') !== 'freightorder' || !get(this.state.selectedBaseEntity, 'deliveryOrderNumber')) &&
                    <div className='col-md-12 form-wrap'>
                      <Grid component="label" container alignItems="center" spacing={1} wrap='nowrap'>
                        <Grid item style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.54)', paddingRight: '50px' }}>Delivery order exists</Grid>
                        <Grid item>Outside AgriChain</Grid>
                        <Grid item>
                          <Switch color='primary' checked={this.state.deliveryCheckpointOrderExists} onChange={event => this.onCheckpointOrderExistsChange(event, 'delivery')} name='deliveryCheckpointExists' />
                        </Grid>
                        <Grid item>On AgriChain</Grid>
                      </Grid>
                    </div>
                  }
                  <div className='col-md-12 form-wrap'>
                    <CommonDatePicker
                      id='freightDelivery.date'
                      setRef={this.fieldRef['freightDelivery.date']}
                      floatingLabelText='Delivery End Date'
                      onChange={this.handleSelectFieldChange}
                      errorText={this.state.fields.freightDelivery.date.errors[0]}
                      value={this.state.fields.freightDelivery.date.value}
                      style={{ float: 'right' }}
                      minDate={this.deliveryDateMin()}
                      maxDate={this.deliveryDateMax()}
                      disabled={this.disableForm}
                    />
                  </div>
                  {!this.isCallOnGrainOrder &&
                  <div className="col-md-12 form-wrap top-15">
                    <CommonTextField
                      id="freightDelivery.instructions"
                      setRef={this.fieldRef["freightDelivery.instructions"]}
                      label="Delivery Instructions (Optional)"
                      value={this.state.fields.freightDelivery.instructions.value}
                      helperText={this.state.fields.freightDelivery.instructions.errors[0]}
                      onChange={this.handleFieldChange}
                      multiline={true}
                      rows={2}
                      rowsMax={2}
                      onBlur={this.handleBlur}
                      disabled={this.disableForm || (this.isEditForm && get(this.editOrder, 'isInstructionsEditDisabled'))}
                    />
                  </div>
                  }
                  {this.state.throughputInloadCheckbox &&
                  <div className='col-md-12'>
                    <FormControlLabel
                      control={
                        <Checkbox
                          id='throughputInloadOrder'
                          color="primary"
                          checked={this.state.throughputInloadOrder}
                          onChange={this.handleThroughputOrderChange}
                          disabled={isDeliveryWarehouseInvoiced}
                        />
                      }
                      label="Is Throughput Order"
                    />
                  </div>}
                </div>
              </div>
            </div>
            }
          </div>
          {!this.isCallOnGrainOrder && this.showFreightContractDetails() &&
           <div className='cardForm'>
             <h4 className='cardForm-title'>Freight Contract Details</h4>
             <div className='cardForm-content'>
               <div className='col-md-5 form-wrap padding-reset'>
                 {this.showInvoicingField() && !this.isCallOnGrainOrder && (
                    <div className='col-md-12 form-wrap'>
                      <CommonSelect
                        id='invoicing'
                        disabled={Boolean(this.disableForm) || isInvoicingFieldsDisabled}
                        setRef={this.fieldRef['invoicing']}
                        onChange={this.handleInvoicingFieldChange}
                        floatingLabelText='Invoicing (Optional)'
                        errorText={this.state.fields.invoicing.errors[0]}
                        items={invoicing}
                        style={{ float: 'left' }}
                        value={this.state.fields.invoicing.value}
                      />
                    </div>
                 )}
                 {this.showPaymentTermField() && !this.isCallOnGrainOrder && (
                    <div className='col-md-12 form-wrap'>
                      <CommonAutoSelect
                        id='paymentTermId'
                        setRef={this.fieldRef['paymentTermId']}
                        disabled={this.disableForm || isInvoicingFieldsDisabled}
                        onChange={this.handleSelectFieldChange}
                        label='Payment Term (Optional)'
                        dataSourceConfig={{ text: 'name', value: 'id' }}
                        value={this.state.fields.paymentTermId.value}
                        errorText={this.state.fields.paymentTermId.errors[0]}
                        items={this.props.paymentTerms}
                      />
                    </div>
                 )}
                 <div className='col-md-6 top-15'>Est. Distance: {this.state.totalDistanceLabel}</div>
                 <div className='col-md-5 top-15'>Est. Travel Time: {this.state.estimatedTime}</div>
               </div>
               <div className='col-md-5 col-md-offset-1 padding-reset'>
                 {this.showFreightRateInField() && !isCallOnGrain && !this.isCallOnGrainOrder && (
                    <div className='col-md-12 form-wrap' style={{ display: 'flex' }}>
                      <CurrencyField
                        id='rateFreightIn'
                        setRef={this.fieldRef['rateFreightIn']}
                        label='Freight Rate In (Optional)'
                        value={this.state.fields.rateFreightIn.value}
                        helperText={this.state.fields.rateFreightIn.errors[0]}
                        inputRef={this.fieldRef['price']}
                        fullWidth
                        onChange={this.handleFreightRateChange}
                        onBlur={this.handleRateBlur}
                        onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                        disabled={this.state.fields.invoicing.value !== 'Freight Provider to Invoice Broker' || this.disableForm || isInvoicingFieldsDisabled}
                        variant="standard"
                        onCurrencyChange={this.handleCurrencyChange}
                        selectedCurrency={this.state.currency}
                        disabledCurrency={!foreignCurrencyAllowed}
                      />
                    </div>
                 )}
                 <div className='col-md-12 form-wrap'>
                   {this.showFreightRateOutField() && !this.isCallOnGrainOrder && (
                    <div style={{ display: 'flex' }}>
                      <CurrencyField
                        id='rateFreightOut'
                        setRef={this.fieldRef['rateFreightOut']}
                        label={this.getFreightRateOutFieldLabel()}
                        value={this.state.fields.rateFreightOut.value}
                        helperText={this.state.fields.rateFreightOut.errors[0]}
                        inputRef={this.fieldRef['price']}
                        fullWidth
                        onChange={this.handleFreightRateChange}
                        onBlur={this.handleRateBlur}
                        onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                        disabled={this.disableForm}
                        variant="standard"
                        onCurrencyChange={this.handleCurrencyChange}
                        selectedCurrency={this.state.currency}
                        disabledCurrency={!foreignCurrencyAllowed}
                      />
                    </div>
                   )}
                 </div>
                 {this.showOversRateField() && !this.isCallOnGrainOrder && (
                    <div className='col-md-12 form-wrap'>
                      <CommonTextField
                        id='rateOvers'
                        setRef={this.fieldRef['rateOvers']}
                        label='Overs Rate (Optional)'
                        disabled={this.disableForm}
                        onKeyDown={event => positiveDecimalFilter(event, 2, 10000)}
                        helperText={this.state.fields.rateOvers.errors[0]}
                        value={this.state.fields.rateOvers.value}
                        onChange={this.handleFieldChange}
                        onBlur={this.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                              {this.state.currency}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </div>
                 )}
                 {this.showCommissionRateField() && !isCallOnGrain && !this.isCallOnGrainOrder && !this.isCallOnGrainOrder && <div className='col-md-12 top-15'>Commission Rate : {commissionRate}</div>}
               </div>
             </div>
           </div>
          }
          {
            isIndependentSiteContractOrder &&
              <div className='cardForm'>
                <h4 className='cardForm-title'>Invoicing</h4>
                <div className='cardForm-content'>
                  <div className='col-md-5'>
                    <div className='col-md-12 form-wrap padding-reset'>
                      <CommonTextField
                        id='contractPrice'
                        label={`Contract Price (per ${priceUnit})`}
                        disabled
                        value={contractPrice.toFixed(2)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                              {this.state.currency}
                            </InputAdornment>
                          )
                        }}
                      />
                      </div>
                  </div>
                  <div className='col-md-5 padding-reset col-md-offset-1'>
                    <div className='col-md-12 form-wrap'>
                      <CommonTextField
                        id='ld'
                        label={`LD (per ${priceUnit})`}
                        disabled
                        value={ldPrice.toFixed(2)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                              {this.state.currency}
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>
                  <div className='col-md-5'>
                    <div className='col-md-12 form-wrap padding-reset'>
                      <CommonTextField
                        id='spreadPrice'
                        label={`Spread Price (per ${priceUnit})`}
                        disabled
                        value={spreadPrice.toFixed(2)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                              {this.state.currency}
                            </InputAdornment>
                          )
                        }}
                      />
                      </div>
                  </div>
                  <div className='col-md-5 padding-reset col-md-offset-1'>
                    <div className='col-md-12 form-wrap'>
                      <NumberField
                        id='additionalCost'
                        label={`Additional cost (per ${priceUnit}) (optional)`}
                        value={this.state.fields.additionalCost.value}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                              {this.state.currency}
                            </InputAdornment>
                          )
                        }}
                        maxValue={99999.99}
                        onChange={this.handleFieldChange}
                      />
                    </div>
                  </div>
                  <div className='col-md-5'>
                    <div className='col-md-12 form-wrap padding-reset'>
                      <CommonTextField
                        id='totalPrice'
                        label={`Total Price (per ${priceUnit})`}
                        disabled
                        value={totalOrderPrice.toFixed(2)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                              {this.state.currency}
                            </InputAdornment>
                          )
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
          }

          {isFreightOrderConditionsDisplayed && (
             <div className='cardForm'>
               <h4 className='cardForm-title'>Order Conditions</h4>
               <div className='cardForm-content col-md-12'>
                 <div className='col-md-5 form-wrap padding-reset'>
                   <CommonAutoSelect
                     items={this.props.generalConditionsAll}
                     setRef={this.fieldRef['generalConditionsSelector']}
                     style={{ float: 'left' }}
                     id='generalConditionsSelector'
                     value={this.state.generalConditionsSelector}
                     label='General'
                     onChange={this.handleConditionSelector}
                     dontAutoselectSingleItem
                   />
                   {this.addGeneralConditionButton()}
                   <CommonTextField
                     id='generalConditionViewer'
                     setRef={this.fieldRef['generalConditionViewer']}
                     value={this.state.selectedGeneralConditionText}
                     multiline={true}
                     rows={2}
                     disabled={true}
                     rowsMax={2}
                   />
                 </div>
                 <div className='cardForm-content col-md-1 arrow-button'>
                   <IconButton
                     disabled={!isNumber(this.state.generalConditionsSelector)}
                     onClick={this.appendGeneralConditions}
                     size="large">
                     <ArrowForward color={PRIMARY_COLOR_GREEN} />
                   </IconButton>
                 </div>
                 <div className='cardForm-content col-md-5'>
                   <div className='field-label'>Conditions (Optional)</div>
                   <CommonTextField
                     id='generalConditions'
                     setRef={this.fieldRef['generalConditions']}
                     className='text-area'
                     label=''
                     value={this.state.fields.generalConditions.value}
                     multiline={true}
                     rows={6}
                     rowsMax={6}
                     onBlur={e => this.appendCustomCondition(e, 'generalConditions')}
                   />
                 </div>
               </div>
               <div className='cardForm-content col-md-12'>
                 <div className='col-md-5 form-wrap padding-reset'>
                   <CommonAutoSelect
                     items={this.props.specialConditionsAll}
                     setRef={this.fieldRef['specialConditionsSelector']}
                     id='specialConditionsSelector'
                     value={this.state.specialConditionsSelector}
                     label='Special'
                     onChange={this.handleConditionSelector}
                     dontAutoselectSingleItem
                   />
                   {this.addSpecialConditionButton()}
                   <CommonTextField
                     id='specialConditionViewer'
                     setRef={this.fieldRef['specialConditionViewer']}
                     value={this.state.selectedSpecialConditionText}
                     multiline={true}
                     rows={2}
                     disabled={true}
                     rowsMax={2}
                     onBlur={this.handleBlur}
                   />
                 </div>
                 <div className='cardForm-content col-md-1 arrow-button'>
                   <IconButton
                     disabled={
                       !isNumber(this.state.specialConditionsSelector) ||
                               (this.isEditForm && includes(['in_progress', 'delivered', 'completed'], get(this.editOrder, 'status')))
                     }
                     onClick={this.appendSpecialConditions}
                     size="large">
                     <ArrowForward color={PRIMARY_COLOR_GREEN} />
                   </IconButton>
                 </div>
                 <div className='cardForm-content col-md-5'>
                   <div className='field-label'>Conditions (Optional)</div>
                   <CommonTextField
                     id='specialConditions'
                     setRef={this.fieldRef['specialConditions']}
                     className='text-area'
                     label=''
                     value={this.state.fields.specialConditions.value}
                     multiline={true}
                     rows={6}
                     rowsMax={6}
                     onBlur={e => this.appendCustomCondition(e, 'specialConditions')}
                   />
                 </div>
               </div>
             </div>
          )}
          <div className='cardForm'>
            <h4 className='cardForm-title'>Internal Notes (Optional)</h4>
            <div className='cardForm-content col-md-5'>
              <Notes
                title=''
                handler={this.noteHandler}
                note={this.state.fields.note}
                disabled={false}
              />
            </div>
          </div>
          <div style={{ textAlign: 'right' }}>{buttons}</div>
        </form>
        {
          this.state.showHierarchy && this.state.openHierarchyFor &&
          <>
          {includes(["commodityContract", "contract"], get(this.state.openHierarchyFor, 'entity')) ?
          <HierarchySideDrawer
            isOpen={this.state.showHierarchy}
            onClose={() => this.setState({showHierarchy: false, openHierarchyFor: undefined})}
            title="Contract Hierarchy"
            contractId={get(this.state.openHierarchyFor, 'id')}
            openUpdateTonnagePopup={() => this.setState({openUpdateTonnagePopup: true})}
            showUpdateTonnage={get(this.state.openHierarchyFor, 'canRaiseAmendRequest')}
          /> :
          <HierarchySideDrawer
            isOpen={this.state.showHierarchy}
            onClose={() => this.setState({showHierarchy: false, openHierarchyFor: undefined})}
            title="Order Hierarchy"
            orderId={get(this.state.openHierarchyFor, 'id')}
            openUpdateTonnagePopup={() => this.setState({openUpdateTonnagePopup: true})}
            showUpdateTonnage={get(this.state.openHierarchyFor, 'canRaiseAmendRequest') || get(this.state.openHierarchyFor, 'canAccessOrder')}
          />
          }
          </>
        }
        {this.state.openUpdateTonnagePopup &&
          <Dialog open={this.state.openUpdateTonnagePopup} onClose={() => this.setState({openUpdateTonnagePopup: false})} scroll='paper'>
            <DialogTitle>{`Update ${_relatedEntityDisplay} Tonnage`}</DialogTitle>
            <DialogContent>
              <div style={{paddingTop: '10px'}}>
                <NumberField
                  id="entityTonnage"
                  label={`${_relatedEntityDisplay} Tonnage`}
                  value={this.state.relatedEntityTonnage.value}
                  maxValue={999999999.99}
                  helperText={this.state.relatedEntityTonnage.errors[0]}
                  onChange={event => this.handleEntityTonnageChange(this.state.openHierarchyFor, event.target.value, _relatedEntityDisplay)}
                  variant='outlined'
                  InputProps={{
                    endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{priceUnit}</InputAdornment>
                  }}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <CommonButton label='Close' key='closeButton' default onClick={() => this.setState({openUpdateTonnagePopup: false})} variant='flat' />
              <CommonButton label='Update' key='submitButton' primary={true} onClick={() => this.updateEntityTonnage(this.state.openHierarchyFor, _relatedEntityDisplay)} variant='flat' />
            </DialogActions>
          </Dialog>
        }
        <Dialog open={Boolean(this.state.preview)} onClose={this.handleOpenPreview} scroll='paper' fullScreen>
          <DialogTitle>{`${entity} Order Preview`}</DialogTitle>
          <DialogContent>
            {
              this.isCallOnGrainOrder ?
              <GrainOrderPreview previewFields={this.getPreviewFields()} ref={el => (this.componentRef = el)} /> :
              <OrderPreview previewFields={this.getPreviewFields()} ref={el => (this.componentRef = el)} />
            }
          </DialogContent>
          <DialogActions style={{paddingBottom: "40px"}}>
            <CommonButton label='Back' key='closeButton' default onClick={this.handleClosePreview} variant='flat' />
            <CommonButton label='Submit' key='submitButton' primary={true} onClick={this.handleSubmit} variant='flat' />
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.templateDialog} onClose={this.handleTemplateDialogClose} scroll='paper'>
          <DialogTitle>Order Template</DialogTitle>
          <DialogContent>
            <TextField
              id='templateName'
              label='Template Name'
              placeholder='Please enter'
              value={this.state.templateName.value || undefined}
              fullWidth
              error={!isEmpty(this.state.templateName.errors[0])}
              helperText={this.state.templateName.errors[0]}
              onChange={this.handleTemplateNameChange}
              maxLength='200'
              onBlur={this.handleTemplateNameBlur}
              variant="standard" />
          </DialogContent>
          <DialogActions>
            <CommonButton variant='flat' label='Cancel' key='closeButton' default onClick={this.handleTemplateDialogClose} />
            <CommonButton variant='flat' label='Save' key='saveButton' primary={true} onClick={this.saveTemplate} />
          </DialogActions>
        </Dialog>
        {
          this.state.consignorSideDrawer &&
          <SideDrawer
            isOpen={ this.state.consignorSideDrawer }
            title="Add Site"
            onClose={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
            app="condition"
            >
            <CreateCompanySite
              closeDrawer={() => this.handleFarmSideDrawer("consignorSideDrawer", false)}
              canAccessAny={true}
              companyId={getCountrySystemCompanyId()}
              handleFarmSubmit={(data) => this.handleFarmSubmit("consignorSideDrawer" ,data)}
              inputText={this.state.inputTextFarm}/>
          </SideDrawer>
        }
        {
          this.state.consigneeSideDrawer &&
          <SideDrawer
            isOpen={ this.state.consigneeSideDrawer }
            title="Add Site"
            onClose={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
            app="condition"
            >
            <CreateCompanySite
              closeDrawer={() => this.handleFarmSideDrawer("consigneeSideDrawer", false)}
              canAccessAny={true}
              companyId={getCountrySystemCompanyId()}
              handleFarmSubmit={(data) => this.handleFarmSubmit("consigneeSideDrawer", data)}
              inputText={this.state.inputTextFarm}/>
          </SideDrawer>
        }

        {this.freightProviderSideDrawer()}
        {this.customerSideDrawer()}
        {
          this.props.isConditionSideDrawerOpened &&
          <SideDrawer
            isOpen={ this.state.generalConditionSideDrawerIsOpen }
            title="Add General Condition"
            onClose={ this.closeGeneralConditionSideDrawer }
            app="condition"
            >
            <CreateGeneralCondition
              selectedConditionType= {this.isEditingGrainOrder() ? "contract" : "freight"}
              closeDrawer={this.closeGeneralConditionSideDrawer}
              onSuccess={this.setDefaultGeneralCondition}
            />
          </SideDrawer>
        }
        {
          this.props.isConditionSideDrawerOpened &&
          <SideDrawer
            isOpen={this.state.specialConditionSideDrawerIsOpen}
            title="Add Special Condition"
            onClose={this.closeSpecialConditionSideDrawer}
            app="condition"
            >
            <CreateSpecialCondition
              selectedConditionType={this.isEditingGrainOrder() ? "contract" : "freight"}
              closeDrawer={this.closeSpecialConditionSideDrawer}
              onSuccess={this.setDefaultSpecialCondition}
            />
          </SideDrawer>
        }
        {
          this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
          <SideDrawer
            isOpen={this.state.ngrSideDrawerIsOpen}
            title="Add NGR"
            size="big"
            onClose={this.closeNgrSideDrawer}
            >
            <CreateCompanyNgr companyId={get(this.state, 'ngrEntity.id') || get(this.state, 'ngrEntity.company.id')} company={get(this.state.ngrEntity, 'company')} canAccessAny={true} closeDrawer={this.closeNgrSideDrawer} onSuccess={this.getCustomerNgrs} bankAccountDetailsMandatory={false}/>
          </SideDrawer>
        }
        {
          this.state.showEmailDialog &&
          <CustomEmailDialog
            parties={this.state.emailPopupParties}
            selectedParties={this.getSelectedParties()}
            title="Email PDF copies to"
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
            subject={this.getEmailSubject()}
            noBody={true}
            footer={this.getFooterNote()}
            open={this.state.showEmailDialog}
            onClose={this.closeEmailDialog}
            isScheduled={!!this.sendButtonText}
            okText={this.sendButtonText}
            disableAcceptanceRequired={this.isAcceptanceDisabled()}
            isAcceptanceRequired={this.isAcceptanceRequired()}
            forceSetAcceptanceRequired={this.isAcceptanceRequired()}
          />
        }
        {
          this.state.openCompanySideDrawer &&
          <SideDrawer isOpen={this.state.openCompanySideDrawer} title="Add Company" onClose={this.closeCompanySideDraw}>
            <CreateCompany closeDrawer={this.closeCompanySideDraw} skipCompanyRedirection addNewCompany={this.addNewCompany}/>
          </SideDrawer>
        }
        {this.state.openWarningDialog &&
        <div>
          <Dialog open onClose={() => this.setState({openWarningDialog: false})} scroll='paper'>
            <DialogTitle>Warning</DialogTitle>
            <DialogContent>
              <span>
                {`Pickup ${this.tonnageLabel} exceeds the Available Stocks ${this.tonnageLabel}`}<br></br>
                {`Available ${this.tonnageLabel} = ${this.state.customerTonnageAvailable} ${priceUnit}`}<br></br>
                {`Requested ${this.tonnageLabel} = ${requestedTonnage} ${priceUnit}`}<br></br>
              </span>
            </DialogContent>
            <DialogActions>
              <CommonButton label='Cancel' key='closeButton' default onClick={() => this.setState({openWarningDialog: false})} variant='flat' />
              <CommonButton label='Continue' key='submitButton' primary={true} onClick={this.handleCloseDialog} variant='flat' />
            </DialogActions>
          </Dialog>
        </div>
        }
      </div>
    );
}
}

const mapDispatchToProps = dispatch => {
  return {
    handleAddEmployeeButtonClick: type => {
      if (type === 'farm') {
        dispatch(clickAddFarmEmployeeButton());
      } else {
        dispatch(clickAddEmployeeButton());
      }
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    dispatch
  };
};

const mapStateToProps = state => {
  var types = state.companies.orders.types;
  var paymentTerms = state.companies.orders.paymentTerms;

  const userTrucksCount = state.companies.companies.company.trucks.userTrucks ? state.companies.companies.company.trucks.userTrucks.length : 0;
  const commodities = state.master.commodities.items || [];
  const varieties = state.master.varieties.items || [];
  return {
    userTrucksCount,
    types,
    paymentTerms,
    serverErrors: state.companies.orders.serverErrors,
    currentUser: state.main.user.user,
    generalConditionsAll: state.companies.conditions.generalConditions,
    specialConditionsAll: state.companies.conditions.specialConditions,
    grades: state.master.grades.items || [],
    allDirectoryCompanies: state.companies.companies.companyParties,
    providers: state.companies.companies.companyParties,
    providerEmployees: state.companySettings.employees.items,
    contracts: state.companies.contracts.items,
    selectedOrder: state.companies.orders.selectedOrder,
    userCompanyId: state.main.user.user.companyId,
    userToken: state.main.user.token,
    commodities,
    varieties,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    isLoading: state.main.isLoading,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FreightOrderForm));

import React from 'react';
import { Button, Tooltip } from '@mui/material'

const Xero = props => {
  const URL = `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${props.clientId}&redirect_uri=${window.location.origin}/xero/callback&scope=openid profile email accounting.transactions accounting.settings accounting.contacts offline_access&state=${props.companyId}`
  const onClick = () => window.open(URL, '_blank')

  return (
    <Tooltip arrow title={props.title || 'Connect Xero Account for Invoices'}>
      <Button
        disabled={props.disabled}
        size='large'
        variant='contained'
        onClick={onClick}
      >
        {props.label || 'Connect Xero'}
      </Button>
    </Tooltip>
  )
}

export default Xero;

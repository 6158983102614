import React from 'react';

import { connect } from 'react-redux';
import { isEmpty, find, map } from 'lodash';
import { getCommoditiesWithVarietiesAndGrades, getCommoditiesWithGrades } from '../../../actions/api/commodities';
import AutoComplete from './AutoComplete';

class CommodityAutoComplete extends React.Component {
  constructor(props) {
    super (props);

    this.state = {
      commodityId: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (isEmpty(this.props.commodities)) {
      if(this.props.noVarieties)
        this.props.getCommoditiesWithGrades();
      else
        this.props.getCommoditiesWithVarietiesAndGrades();
    }

    if (this.props.commodityId) {
      this.setCommodityIdAndPropagate(this.props.commodityId);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.commodityId !== prevProps.commodityId) {
      this.setCommodityIdAndPropagate(this.props.commodityId || '');
    }
  }

  setCommodityIdAndPropagate(commodityId) {
    this.setState(
      { commodityId },
      () => {
        if(this.props.onChange)
          this.props.onChange(this.state.commodityId, this.props.id, this.getSelectedCommodity());
      }
    );
  }

  getSelectedCommodity() {
    if(this.state.commodityId) {
      return find(this.props.commodities, {id: this.state.commodityId});
    }
  }

  handleChange(value) {
    this.setCommodityIdAndPropagate(value);
  }

  getItems = () => {
    const { commodities, itemFilterFunc } = this.props
    let items = commodities
    if(itemFilterFunc)
      items = itemFilterFunc(items)
    items = map(items, commodity => ({label: commodity.displayName, value: commodity.id}));
    return items
  }

  render() {
    const floatingLabelText = this.props.floatingLabelText || 'Commodity';
    const placeholder = this.props.placeholder || floatingLabelText;
    const items = this.getItems();
    return (
      <div className="relative-pos" ref={this.props.setRef}>
        <AutoComplete
          id={this.props.id}
          placeholder={placeholder}
          options={items}
          value={this.state.commodityId}
          label={floatingLabelText}
          onBlur={this.props.onBlur}
          errorText={this.props.errorText}
          disabled={this.props.disabled}
          onChange={this.handleChange}
          style={this.props.style}
          noListbox
        />
        {this.props.disabled && this.state.commodityId ? <i style={this.props.style} className="icon-lock"></i> : ''}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    commodities: state.master.commodities.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommoditiesWithVarietiesAndGrades: () => dispatch(getCommoditiesWithVarietiesAndGrades()),
    getCommoditiesWithGrades: () => dispatch(getCommoditiesWithGrades())
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CommodityAutoComplete);

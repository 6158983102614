import {
  GET_PAGINATED_VENDOR_DECS_SUCCESS, STORE_VENDOR_DEC_MOVEMENT_ENTITY
} from '../../actions/companies/vendor-decs';

const initialState = {
  items: [],
  paginationData: {}
};

const reducer = (state = initialState, action) => {
  switch(action.type) {
  case GET_PAGINATED_VENDOR_DECS_SUCCESS:
    return {
      ...state,
      items: action.result.results,
      paginationData: action.result
    };
  case STORE_VENDOR_DEC_MOVEMENT_ENTITY:
    return {
      ...state,
      movementId: action.movementId
    }
  default:
    return {...state};
  }
};

export default reducer;

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Invoice extends Component {
  render() {
    return <Tooltip title="Invoices" placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width || "24"} height={this.props.height || "24"} viewBox="0 0 24 24" style={this.props.noStyle ? (this.props.style || {}) : {marginLeft: '-4px', marginRight: '19px'}}>
        <g fill="none" fillRule="evenodd">
          <path d="M0 0h24v24H0z"/>
          <path fill={this.props.fill} d="M18 6l-3-.004V3H6v18h12V6zM6 1h10l4 4v16a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zm7.608 13.402c0-.363-.115-.663-.346-.9-.23-.238-.615-.451-1.154-.641-.538-.19-.957-.367-1.256-.53-.995-.533-1.493-1.324-1.493-2.37 0-.71.216-1.293.647-1.75.432-.457 1.017-.728 1.756-.814V6h1.026v1.41c.743.107 1.318.422 1.724.945.405.524.608 1.204.608 2.041h-1.55c0-.538-.121-.962-.363-1.272-.241-.31-.569-.464-.983-.464-.41 0-.731.11-.962.333-.23.222-.346.54-.346.955 0 .372.115.67.343.894.229.224.616.437 1.163.638.547.2.977.386 1.288.557.312.171.575.366.789.586.213.22.378.474.493.76.116.286.173.622.173 1.006 0 .722-.22 1.308-.663 1.756-.442.449-1.059.714-1.849.795v1.25h-1.019v-1.244c-.846-.094-1.496-.397-1.951-.91C9.228 15.523 9 14.844 9 13.998h1.557c0 .539.136.955.407 1.25.272.295.655.442 1.15.442.488 0 .858-.117 1.113-.352.254-.235.38-.547.38-.936z"/>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default Invoice;

import React from 'react';
import moment from 'moment';
import { Chip, Menu, MenuItem } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';
import { isEmpty, isEqual, groupBy, forEach, range, filter, sumBy, map, reject, includes } from 'lodash';
import BottomDrawer from '../common/BottomDrawer';

class GradeDistribution extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      data: undefined,
      open: props.open,
      start: props.start,
      end: props.end,
      type: 'inload'
    };
  }

  componentDidMount() {
    if(this.state.open)
      this.prepareData();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.start !== this.props.start || prevProps.end !== this.props.end || !isEqual(prevProps.slots, this.props.slots))
      this.setState({data: undefined, start: this.props.start, end: this.props.end}, () => {
        if(this.state.open)
          this.prepareData();
      });
  }

  prepareData = () => {
    const { slots } = this.props;
    const { start, end, type } = this.state;
    let data = {};
    const mStart = moment(start);
    const mEnd = moment(end);
    const loadSlots = filter(slots, slot => (!slot.raw.slot.isTrailerSlot && slot.raw.slot.type === type && includes(['booked', 'completed', 'in_progress', 'delayed'], slot.raw.slot.status)));
    range(mEnd.diff(mStart, 'd') + 1).forEach(d => {
      const date = mStart.clone().add(d, 'd').format("YYYY/MM/DD");
      const dateSlots = filter(loadSlots, slot => moment(slot.start).format('YYYY/MM/DD') === date);
      data[d] = [];
      const groups = groupBy(dateSlots, 'raw.slot.commodity.displayName');
      forEach(groups, (commoditySlots, commodity) => {
        const result = {name: commodity, groups: []};
        forEach(groupBy(commoditySlots, 'raw.slot.grade.name'), (gradeSlots, grade) => {
          let sum = sumBy(gradeSlots, 'raw.slot.tonnage');
          if(sum)
            sum = sum.toFixed(2);
          result.groups.push({name: grade, tonnage: sum});
        });
        data[d].push(result);
      });
    });

    this.setState({data: data});
  };

  toggleDrawer = () => this.setState({open: !this.state.open}, () => {
    this.props.toggleDailyGrade(this.state.open)
    if(this.state.open && this.state.data === undefined)
      this.prepareData();
  });

  toggleAnchorEl = event => this.setState({anchorEl: this.state.anchorEl ? null : event.currentTarget});

  onTypeChange = type => this.setState({type: type}, () => {
    this.prepareData();
    this.toggleAnchorEl();
  });

  render() {
    const { data, open, type, anchorEl } = this.state;
    return (
      <span style={{margin: '0 1px'}}>
        {
          open &&
          <BottomDrawer isOpen={open} onClose={this.toggleDrawer}>
            <div style={{marginTop: '15px'}}>
              <div style={{width: '100%', fontSize: '14px'}}>
                <Chip
                  label={type === 'inload' ? 'Inbound' : 'Outbound'}
                  deleteIcon={<ArrowDropDown />}
                  variant="outlined"
                  clickable
                  onDelete={this.toggleAnchorEl}
                  onClick={this.toggleAnchorEl}
                  color="secondary"
                />
                <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}>
                  <MenuItem onClick={() => this.onTypeChange('inload')}>Inbound</MenuItem>
                  <MenuItem onClick={() => this.onTypeChange('outload')}>Outbound</MenuItem>
                </Menu>
              </div>
              <div className='grade-distribution-container'>
                {
                  map(data, (commodityGroups, index) => {
                    return (
                      <div key={index} className='grade-container' style={{textAlign: 'left', borderLeft: '1px solid lightgray', paddingLeft: '10px'}}>
                        {
                          isEmpty(commodityGroups) ?
                          <span /> :
                          map(reject(commodityGroups, group => !group.name || group.name === 'undefined'), cGroup => {
                            return (
                              <div style={{marginTop: '10px'}} key={`${index}-${cGroup.name}`}>
                                <div><b>{cGroup.name}</b></div>
                                {
                                  map(reject(cGroup.groups, group => !group.name || group.name === 'undefined'), gGroup => {
                                    return (
                                      <React.Fragment key={`${index}-${gGroup.name}`}>
                                        <div className=''>{`${gGroup.name}: ${gGroup.tonnage} MT`}</div>
                                      </React.Fragment>
                                    );
                                  })
                                }
                              </div>
                            );
                          })
                        }
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </BottomDrawer>
        }
      </span>
    );
  }
}

export default GradeDistribution;

import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { forceStopLoader, isLoading } from '../../actions/main';

const MESSAGES = {
  CREATE_SUCCESS: 'Outload was successfully added',
  UPDATE_SUCCESS: 'Outload details were successfully updated',
};

export const getStorageOutloads = (storageId, actionCreator, queryParams) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.storages(storageId)
    .outloads()
    .get(token, undefined, queryParams)
    .then(items => {
      if (items.outloads.length == 0) {
        dispatch(forceStopLoader());
      }
      dispatch(actionCreator(items));
    });
};

export const createStorageOutload = (storageId, data, actionCreator, successCallback) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  APIService.storages(storageId)
    .outloads()
    .post(data, token)
    .then(item => {
      dispatch(forceStopLoader());
      if (!isEmpty(item.errors)) {
        forEach(item.errors, value => {
          alertifyjs.error(value[0]);
        });
      } else if (item.id) {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (successCallback) {
          successCallback();
        }
      }
    });
};

export const createOutload = (data, successCallback) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  data['type'] = 'outload';
  APIService.loads()
    .post(data, token)
    .then(response => {
      dispatch(forceStopLoader());
      if (!isEmpty(get(response[0], 'errors'))) {
        forEach(response[0].errors, value => {
          if(typeof(value) === 'string')
            alertifyjs.error(value);
          else
            alertifyjs.error(value[0]);
        });
      } else if (get(response, '[0].id')) {
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (successCallback) {
          successCallback();
        }
      }
    });
};

export const updateOutload = (loadId, data, successCallback) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  APIService.loads(loadId)
    .put(data, token)
    .then(response => {
      dispatch(forceStopLoader());
      if (!isEmpty(get(response, 'errors'))) {
        forEach(response.errors, value => {
          if(typeof(value) === 'string')
            alertifyjs.error(value);
          else
            alertifyjs.error(value[0]);
        });
      } else if (response.id) {
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
        if (successCallback) {
          successCallback();
        }
      }
    });
};

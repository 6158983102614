import { MsalAuthProvider, LoginType } from 'react-aad-msal';

const authenticationParameters = {
  scopes: [
    'user.read'
  ]
};

const options = {
  loginType: LoginType.Popup,
  tokenRefreshUri: window.location.origin + '/auth.html'
};

export const getAuthProvider = params => {
  const authConfig = {
    auth: {
      ...params.auth
    },
    cache: {
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true
    }
  };

  const authParams = params.scopes || authenticationParameters;
  return new MsalAuthProvider(authConfig, authParams, options);
};

import React from 'react';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { getSeasons } from '../../../common/utils';


class SeasonSelect extends React.Component {
  constructor(props) {
    super (props);

    this.state = {
      season: '',
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    if (this.props.season) {
      this.setSeasonAndPropagate(this.props.season);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.season !== prevProps.season) {
      this.setSeasonAndPropagate(this.props.season || '');
    }
  }

  setSeasonAndPropagate(season) {
    this.setState(
      { season },
      () => this.props.onChange(this.state.season, this.props.id)
    );
  }

  handleChange(event) {
    this.setState(
      { season: event.target.value },
      () => {
        if (this.props.onChange) this.props.onChange(event.target.value, this.props.id);
      }
    );
  }

  render() {
    const seasons = getSeasons();
    return (
      <FormControl className='mui-select' error={!!this.props.errorText} style={{width: '100%'}} variant="standard">
        <InputLabel htmlFor={this.props.id}>{this.props.floatingLabelText || 'Season'}</InputLabel>
        <Select
          id={this.props.id}
          value={this.state.season}
          onChange={this.handleChange}
          style={this.props.style}
          disabled={this.props.disabled}
          inputProps={{
            ref: this.props.setRef,
          }}
          variant="standard">
          {
            this.props.includeEmptyOption ?
              <MenuItem value='' /> :
              ''
          }
          {
            seasons.map(season => {
              return (
                <MenuItem
                  key={season}
                  value={season}
                  >
                  {season}
                </MenuItem>
              );
            })
          }
        </Select>
        {this.props.disabled && this.state.season ? <i className="icon-lock"></i>: ''}
        <FormHelperText>{this.props.errorText}</FormHelperText>
      </FormControl>
    );
  }
}

export default SeasonSelect;

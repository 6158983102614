import React from 'react';

import Paper from '@mui/material/Paper';
import {get, includes, upperFirst, isArray, map } from 'lodash';

import {
  convertEpochToDateFormat, convertEpochToDateTimeFormat
} from '../../../common/momentUtilities';

import { InvoiceDetailsAction } from "./InvoiceDetailsAction";
import InvoiceAcceptRejectForm from "../InvoiceAcceptRejectForm";
import { ChipLabel } from '../../common/ChipLabel';

const renderBasedOnStatus = status => {
  switch (status) {
    case 'Action Pending':
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
    case 'Awaiting Confirmation':
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
    case 'Awaiting Payment Confirmation':
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
    case 'Pending Payment (Overdue)':
      return (
        <img src="images/pending-payment-overdue.svg"></img>
      );
    case 'Awaiting Payment (Overdue)':
      return (
        <img src="images/pending-payment-overdue.svg"></img>
      );
    case 'Awaiting Payment':
      return (
        <img src="images/pending-payment.svg"></img>
      );
    case 'Rejected':
      return (
        <img src="images/rejected.png"></img>
      );
    case 'Pending Payment':
      return (
        <img src="images/pending-payment.svg"></img>
      );
    case 'Paid':
      return (
        <img src="images/paid.png"></img>
      );
    case 'Generated':
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
    case 'Confirmed':
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
    case 'Overpaid':
      return (
        <img src="images/overpaid.svg"></img>
      );
    case 'Partially Paid':
      return (
        <img src="images/partially_paid.svg"></img>
      );
    case 'Void':
      return (
        <img src="images/rejected.png"></img>
      );
    default:
      return (
        <img src="images/awaiting-confirmation.svg"></img>
      );
  }
};

const getSectionWithUrl = (title, identifier, created, url = null, linkProps={}, xeroError=null) => {
  const _props = linkProps || {}
  return (
    <li className="flex-direction-column">
      <span className="field-label" key="label">{title}</span>
      <span className="field-value" key="value">
        {
          url ?
            <a href={url} {..._props}>{identifier}</a> :
          identifier
        }
      </span>
      {
        isArray(created) ? (
          map(created, (_created, index) => (
            <span key={index} className="created-at" style={{textAlign: 'center'}}>{_created}</span>
          ))
        ) : (
          created &&
            <span className="created-at" key="created-at" style={{textAlign: 'center'}}>{created}</span>
        )
      }
      {
        xeroError?.error &&
          <React.Fragment>
            <span style={{color: 'red', textAlign: 'center', maxWidth: '200px', fontSize: '14px', paddingTop: '8px'}}>
              {xeroError.error}
            </span>
            <span style={{color: 'red', textAlign: 'center', fontSize: '12px', fontWeight: '300', paddingTop: '2px'}}>
              Attempted Sync: {convertEpochToDateTimeFormat(xeroError.createdAt)}
            </span>
          </React.Fragment>
      }
    </li>
  );
};

const toCurrency = (currency, number) => `${currency} ${parseFloat(number).toFixed(2)}`

export const InvoiceDetailsBasicInfo = props => {
  const { invoiceDetails, status, warehouse, currentUserParty } = props;
  const { xeroInvoiceUrl, isCreatingXeroInvoice, xeroSyncedAt } = invoiceDetails
  const statusDisplayName = warehouse ? invoiceDetails.warehouseStatus : (status ? upperFirst(status) : upperFirst(invoiceDetails.statusDisplayName));
  const voidRequest = !invoiceDetails.voidable && invoiceDetails.isVoidRequestPending ? true : false;
  const isValidInvoice = !includes(['rejected', 'void'], invoiceDetails?.status)
  const label = invoiceDetails.type === 'Commodity Contract' ? 'Contract' : 'Freight Order';
  let raisedForUrl = null;
  if (label === 'Contract')
    raisedForUrl = `#/contracts/${invoiceDetails.raisedForId}/contract`;
  else if (label === 'Freight Order')
    raisedForUrl = `#/freights/orders/${invoiceDetails.raisedForId}/order`;
  const systemFees = warehouse ? invoiceDetails.systemAmount : get(invoiceDetails, 'totalDetails.total');
  const amountLabel = warehouse ? 'Fees' : 'Invoice Amount';
  const identifier = get(invoiceDetails, 'identifier');
  const raisedForNo = get(invoiceDetails, 'raisedForNo');
  const createdAt = convertEpochToDateFormat(invoiceDetails.createdAt);
  const xeroLastSyncedAt = xeroSyncedAt ? convertEpochToDateFormat(xeroSyncedAt) : null;
  const amountPayable = parseFloat(get(invoiceDetails, 'amountPayable', 0)).toFixed(2);
  const amountPaid = parseFloat(get(invoiceDetails, 'amountPaid', 0)).toFixed(2);
  const tenure = get(invoiceDetails, 'tenure', 0);
  const onCreateXeroInvoiceClick = event => {
    event.preventDefault()
    event.stopPropagation()
    props.onCreateXeroClick()
  }


  let xeroSyncedAtSubLabels = []
  if(xeroLastSyncedAt)
    xeroSyncedAtSubLabels = [`Synced at: ${xeroLastSyncedAt}`]
  if(invoiceDetails?.xeroPaymentSyncedAt)
    xeroSyncedAtSubLabels.push(`Payment synced at: ${convertEpochToDateFormat(invoiceDetails.xeroPaymentSyncedAt)}`)

  const paymentRunId = invoiceDetails?.canViewPaymentRun ? invoiceDetails?.paymentRunId : false
  const paymentRunURL = paymentRunId ? `#/invoices/payment-runs/${paymentRunId}` : false
  const paymentRunCreatedAt = paymentRunId ? convertEpochToDateFormat(invoiceDetails.paymentRunCreatedAt) : false
  const externalError = invoiceDetails?.externalError

  return (
    <Paper className='invoice-status'>
      <ul className="invoice-status-bar">
        <li className="status flex-direction-column">
          {renderBasedOnStatus(statusDisplayName)}
          <span className="field-value">{statusDisplayName}</span>
        </li>
        {!warehouse && identifier && getSectionWithUrl('Invoice No', identifier, `Created: ${createdAt}`)}
        {
          xeroInvoiceUrl &&
            getSectionWithUrl('Xero Invoice', 'Open in Xero', xeroLastSyncedAt ? xeroSyncedAtSubLabels : false, xeroInvoiceUrl, {target: '_blank', rel: 'noreferrer noopener', style: {fontSize: '12px', display: 'flex', textAlign: 'center'}}, externalError)
        }
        {
          !xeroInvoiceUrl && isCreatingXeroInvoice && currentUserParty?.isXeroAccountLinked &&
            getSectionWithUrl('Xero Invoice', 'Creating...')
        }
        {
          !xeroInvoiceUrl && !isCreatingXeroInvoice && currentUserParty?.isValidXeroConnection && isValidInvoice &&
            getSectionWithUrl(
              'Xero Invoice',
              props.xeroMode ? 'Draft Invoice' : (invoiceDetails.hasCounterPartyXeroContact ? 'Create' : "Cannot create in Xero as counter party does not exist as a Contact"),
              false,
              !props.xeroMode,
              {
                style: invoiceDetails.hasCounterPartyXeroContact ?
                {fontSize: '12px', cursor: 'pointer'} :
                {fontSize: '12px', color: 'red', textDecoration: 'none', display: 'flex', textAlign: 'center', maxWidth: '150px'},
                onClick: invoiceDetails.hasCounterPartyXeroContact ? onCreateXeroInvoiceClick : null
              },
              props?.xeroMode ? false : externalError
            )
        }
        {
          !xeroInvoiceUrl && !currentUserParty?.isValidXeroConnection && currentUserParty?.isXeroAccountLinked && isValidInvoice &&
            getSectionWithUrl('Xero Invoice', 'Re-Connect', 'Xero Connection is invalidated, please Re-Connect', `/#/companies/${currentUserParty?.companyId}/integrations`)
        }
        {raisedForNo && getSectionWithUrl(label, raisedForNo, `Created: ${createdAt}`, raisedForUrl)}
        {paymentRunId && getSectionWithUrl('Payment Run', invoiceDetails?.paymentRunIdentifier, `Created: ${paymentRunCreatedAt}`, paymentRunURL, {target: '_blank', rel: 'noreferrer noopener'})}
        {
          systemFees &&
            getSectionWithUrl(
              amountLabel,
              toCurrency(get(invoiceDetails, 'currency'), systemFees),
              `Due: ${convertEpochToDateFormat(invoiceDetails.paymentDueDate)}`
            )
        }
        {
          warehouse &&
            getSectionWithUrl(
              'Invoiced',
              toCurrency(get(invoiceDetails, 'currency'), invoiceDetails.invoicedAmount)
            )
        }
        {
          warehouse &&
            getSectionWithUrl(
              'Fees Not Invoiced',
              toCurrency(get(invoiceDetails, 'currency'), invoiceDetails.systemAmount - invoiceDetails.invoicedAmount)
            )
        }
        {
          warehouse &&
            getSectionWithUrl(
              'Adjusted Fees',
              toCurrency(get(invoiceDetails, 'currency'), invoiceDetails.warehouseCustomAmount)
            )
        }
        {
          !warehouse && amountPayable && amountPaid &&
          getSectionWithUrl('Amount Payable', `${get(invoiceDetails, 'currency')} ${amountPayable}`, `Amount Paid: ${get(invoiceDetails, 'currency')} ${amountPaid}`)
        }
        {
          tenure && (invoiceDetails.type == 'WarehouseFee' || warehouse) &&
          getSectionWithUrl('Tenure', tenure)
        }
        {
          !warehouse && voidRequest && <ChipLabel label='Void Request Sent' />
        }

      </ul>
      {
        !warehouse &&
          <div className="status-actions">
            <InvoiceAcceptRejectForm {...props} />
            <InvoiceDetailsAction {...props} />
          </div>
      }
    </Paper>
  );
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Chip } from '@mui/material';
import APIService from '../../services/APIService';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import GenericTable from '../GenericTable';
import XeroMappingForm from './XeroMappingForm';
import { camelCase, isEmpty, get } from 'lodash';

const XeroStatus = ({ company }) => {
  let status = 'Not Linked'
  let color = 'default'
  if(company.isValidXeroConnection) {
    status = 'Connected'
    color = 'primary'
  }
  else if (company.isXeroAccountLinked) {
    status = 'Disconnected'
    color = 'error'
  }

  const style = {
    fontSize: '1rem',
    height: '40px',
    borderRadius: '20px',
  }
  return <Chip size='large' color={color} variant='outlined' label={`Status: ${status}`} style={style} />
}


class XeroMappings extends Component {
  constructor(props) {
    super(props)
    this.state = {
      drawer: false,
      selected: null,
    }
  }

  toggleDrawer = () => this.setState({drawer: !this.state.drawer, selected: this.state.drawer ? null : this.state.selected})

  onMappingClick = mapping => this.setState({selected: mapping}, this.toggleDrawer)

  onOptionClick = (index, key, id, mapping) => {
    this.setState({selected: mapping}, () => {
      if(key === 'edit')
        this.toggleDrawer()
      else if (key === 'delete')
        this.deleteMapping()
    })
  }

  deleteMapping = () => {
    APIService.companies(this.props.company.id).appendToUrl(`xero/mappings/${this.state.selected.id}/`).delete().then(() => {
      this.setState({selected: null}, this.props.onSuccess)
    })
  }

  render() {
    const { company, allTrackingCategories } = this.props
    const { drawer, selected } = this.state
    let mappings = get(this.props, 'mappings');
    let columns = [
      { key: 'xeroAccount', header: 'Xero Account'},
      { key: 'commodity', header: 'Commodity', },
      { key: 'grade', header: 'Grade', },
      { key: 'season', header: 'Season', },
      { key: 'itemTypeDisplayName', header: 'Item Type', },
      { key: 'xeroItemCode', header: 'Xero Item Code'},
    ]
    if (allTrackingCategories) {
      allTrackingCategories.forEach(trackingCategory => {
        columns.push({ key: camelCase(trackingCategory.name), header: trackingCategory.name });
      })
    }
    if (this.props.transactionType === 'warehouse')
      columns.splice(4, 0, { key: 'siteName', header: 'Site'})
    if (mappings) {
      mappings.map(mapping => {
        let trackingCategories = mapping.trackingCategories;
        if (!isEmpty(trackingCategories)) {
          trackingCategories.forEach(trackingCategory => {
            mapping[camelCase(trackingCategory.name)] = trackingCategory.optionName;
          });
        }
      });
    }
    return (
      <div style={{ position: 'relative' }}>
        {
          company?.isValidXeroConnection &&
            <div style={{ position: 'absolute', right: '170px', top: '2px' }}>
              <XeroStatus company={company}/>
            </div>
        }
        <div style={{ position: 'absolute', right: '0', top: '2px' }}>
          <AddButton
            label='Add Mapping'
            onClick={this.toggleDrawer}
            disabled={!company.isValidXeroConnection}
          />
        </div>
        <GenericTable
          columns={columns}
          items={mappings}
          handleDefaultCellClick={this.onMappingClick}
          handleOptionClick={this.onOptionClick}
          optionsItems={[
            {key: 'edit', text: 'Edit'},
            {key: 'delete', text: 'Delete'},
          ]}
          orderBy='xeroAccount'
          order='asc'
        />
        {
          drawer &&
            <SideDrawer
              isOpen={drawer}
              title={selected?.id ? "Edit Xero Mapping" : "Create Xero Mapping"}
              size="small"
              onClose={this.toggleDrawer}
            >
              <XeroMappingForm {...this.props} selected={selected} onClose={this.toggleDrawer} />
            </SideDrawer>
        }
      </div>
    )
  }
}

export default connect()(XeroMappings);

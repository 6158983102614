import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import '@babel/polyfill';
import findIndex from 'lodash/findIndex';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SideDrawer from "../../common/SideDrawer";
import EditMovementReview from "../../freights/MovementDetails/EditMovementReview";
import {forceStopLoader, isLoading} from "../../../actions/main";
import get from "lodash/get";
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';


class MovementAmendRequestPendingGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      amendDialogOpen: false,
      isFetching: true,
      contracts: [],
      selectedContract: null,
      selectedFreightContract: null,
    };
    this.onReviewClick = this.onReviewClick.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.freights().appendToUrl('contracts/amend/acceptance/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulContractFromList();
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Amendment Request ${suffix}`;
  }


  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  handleAmendOpen = () => {
    this.setState({anchorEl: null, amendDialogOpen: true});
  };

  handleAmendClose = () => {
    this.setState({amendDialogOpen: false});
  };


  removeSuccessfulContractFromList() {
    const { responseContract } = this.props;
    const { selectedContract, contracts } = this.state;
    if(
      selectedContract &&
      get(responseContract, 'id') === selectedContract.id &&
      !isEmpty(contracts)
    ) {
      const index = findIndex(contracts, {id: selectedContract.id});
      this.setState({
        contracts: [...contracts.slice(0, index), ...contracts.slice(index + 1)],
        selectedContract: null,
      }, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/freights/movements/${id}/details`);
  }

  async onReviewClick(event, contract) {
    event.stopPropagation();
    event.preventDefault();
    const { token } = this.props;
    this.props.dispatch(isLoading('editMovementReview'));

    const contractDetails = await APIService.freights().appendToUrl(`contracts/${contract.id}`).get(token);

    this.setState({
      amendDialogOpen: true,
      selectedContract: contractDetails,
    }, () => {
      this.props.dispatch(forceStopLoader());
    });
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.contracts))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Movement No.</TableCell>
                      <TableCell align='center' className="xsmall">Customer</TableCell>
                      <TableCell align='center' className="xsmall">Freight Provider</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('rego')}</TableCell>
                      <TableCell align='center' className="medium">Commodity Description</TableCell>
                      <TableCell align='center' className="xsmall">Pickup</TableCell>
                      <TableCell align='center' className="xsmall">Delivery</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(contract.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.customer)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.freightProvider)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.rego)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(contract.commodityDescription)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.pickupDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.deliveryDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={(event) => this.onReviewClick(event, contract)}>
                              Review
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <SideDrawer
          isOpen={this.state.amendDialogOpen}
          onClose={this.handleAmendClose}
          title={`Movement Amend Request (${this.state.selectedContract?.identifier})`}
          classes={{'paper' : 'left-text-align'}}
          size="big"
        >
          <EditMovementReview
            movement={this.state.selectedContract}
            closeSidebar={this.handleAmendClose}
            shouldReload={false}
          />
        </SideDrawer>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseContract: state.companies.freights.confirmedOrRejectedContract
});

export default connect(mapStateToProps)(MovementAmendRequestPendingGroup);

import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

const HierarchyIcon = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 512 512">
      <path
        d="M496 336h-32v-80c0-8.832-7.168-16-16-16H272v-64h32c8.832 0 16-7.168 16-16V64c0-8.832-7.168-16-16-16h-96c-8.832 0-16 7.168-16 16v96c0 8.832 7.168 16 16 16h32v64H64c-8.832 0-16 7.168-16 16v80H16c-8.832 0-16 7.168-16 16v96c0 8.832 7.168 16 16 16h96c8.832 0 16-7.168 16-16v-96c0-8.832-7.168-16-16-16H80v-64h160v64h-32c-8.832 0-16 7.168-16 16v96c0 8.832 7.168 16 16 16h96c8.832 0 16-7.168 16-16v-96c0-8.832-7.168-16-16-16h-32v-64h160v64h-32c-8.832 0-16 7.168-16 16v96c0 8.832 7.168 16 16 16h96c8.832 0 16-7.168 16-16v-96c0-8.832-7.168-16-16-16z"
        fill="#6aae20"
        opacity="1"
      />
    </SvgIcon>
  );
};

export default HierarchyIcon;

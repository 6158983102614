import React from 'react';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import map from "lodash/map";
import get from "lodash/get";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';


const CardMultivalue = props => {
  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={{ border: '1px solid #000',  width: '30%'}}>
          <Typography className={props.subTitleClass} style={{textAlign: 'center'}}>
            {props.sectionName ? props.sectionName : ''}
          </Typography>
        </TableCell>
        <TableCell style={{ border: '1px solid #000', width: '35%'}}></TableCell>
        <TableCell style={{ border: '1px solid #000', width: '35%'}}></TableCell>
      </TableRow>
      {map(props.payload, (item, index) => (
        <TableRow key={index}>
          <TableCell style={{ border: '1px solid #000', textAlign: 'center', width: '30%' }}>{get(item, 'key', ' ')}</TableCell>
          <Tooltip title={get(item, 'value1', '')}>
            <TableCell style={{ border: '1px solid #000', textAlign: 'center', width: '35%' }}>
              <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
                {get(item, 'value1', '') || '-'}
              </div>
            </TableCell>
          </Tooltip>
          <Tooltip title={get(item, 'value2', '')}>
            <TableCell style={{ border: '1px solid #000', textAlign: 'center', width: '35%' }}>
              <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
                {get(item, 'value2', ' ') || '-'}
              </div>
            </TableCell>
          </Tooltip>
        </TableRow>
      ))}
    </React.Fragment>
  );
};

CardMultivalue.defaultProps = {
  showContract: true
};

export default CardMultivalue;

import { apiURL } from '../main';
import alertifyjs from 'alertifyjs';
import { getCompanyEmployees } from '../../actions/api/employees';
import { receiveEmployees } from '../../actions/company-settings/employees';
import { getFarmEmployees } from '../../actions/api/farm-employees';
import { receiveFarmEmployees } from '../../actions/companies/farm-employees';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version
export const RECEIVE_NOTES = 'RECEIVE_NOTES';
export const ADD_NOTE = 'ADD_NOTE';

export const upsert = (payload, entity, entityId) => (dispatch, getState) => {
  const user = getState().main.user;
  fetch(`${apiURL}/key-contacts/${entity}/${entityId}/`, {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  }).then(response => response.json())
    .then((json) => {
      if(json.id) {
        alertifyjs.success('Successfully updated as Key Contact', 1, () => {
          if (entity == 'companies')
            dispatch(getCompanyEmployees(entityId, receiveEmployees));
          if (entity == 'farms')
            dispatch(getFarmEmployees(entityId, receiveFarmEmployees));
        });
      } else {
        alertifyjs.error('Successfully went wrong!', 1);
      }
    });
};

export const unlink = (entity, entityId) => (dispatch, getState) => {
  const user = getState().main.user;
  fetch(`${apiURL}/key-contacts/${entity}/${entityId}/`, {
    method: 'DELETE',
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country
    },
  }).then(alertifyjs.success('Successfully unlinked', 1, () => {
    if (entity == 'companies')
      dispatch(getCompanyEmployees(entityId, receiveEmployees));
    if (entity == 'farms')
      dispatch(getFarmEmployees(entityId, receiveFarmEmployees));
  }));
};

import {
  RECEIVE_STORAGE_LEVELS,
} from '../../actions/companies/storage-levels';

const initialState = {
  items: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_STORAGE_LEVELS:
      return { ...state, items: action.items };
    default:
      return state;
  }
};

export default reducer;

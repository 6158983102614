import React from 'react';
import { Checkbox, Menu, MenuItem, Chip } from '@mui/material/';
import {
  snakeCase, camelCase, groupBy, forEach, map, set, get, keys, isEqual, compact,
  includes, size, orderBy, filter
} from 'lodash';
import {
  SLOT_PLANNED, SLOT_BOOKED, SLOT_IN_PROGRESS, SLOT_COMPLETED, SLOT_DELAYED,
  SLOT_CANCELLED, SLOT_RESTRICTED, BROWN, CORNFLOWER_BLUE, MAUVE, ERROR
} from '../../../common/constants';
import CancelIcon from '@mui/icons-material/Cancel';


const BORDER_COLORS = {
  planned: SLOT_PLANNED,
  confirmed: BROWN,
  open: SLOT_BOOKED,
  inProgress: SLOT_IN_PROGRESS,
  delayed: SLOT_DELAYED,
  'void': SLOT_CANCELLED,
  delivered: CORNFLOWER_BLUE,
  completed: SLOT_COMPLETED,
  invoiced: MAUVE,
  unsaved: SLOT_RESTRICTED,
};
class StatusFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showStatuses: false,
      statuses: {
        unsaved: {
          id: 'unsaved',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Draft',
        },
        planned: {
          id: 'planned',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Planned',
        },
        confirmed: {
          id: 'confirmed',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Confirmed',
        },
        open: {
          id: 'open',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Open',
        },
        inProgress: {
          id: 'inProgress',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'In Progress',
        },
        delivered: {
          id: 'delivered',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Delivered',
        },
        completed: {
          id: 'completed',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Completed',
        },
        "void": {
          id: 'void',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'void',
        },
        delayed: {
          id: 'delayed',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Delayed',
        },
        rejected: {
          id: 'rejected',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Rejected',
        },
        invoiced: {
          id: 'invoiced',
          enabled: false,
          currentViewCount: 0,
          count: 0,
          label: 'Freight Invoiced',
        },
      }
    };
    this.chipRef = React.createRef();
  }

  componentDidMount() {
    this.setStats();
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(this.props.items, prevProps.items))
      this.setStats();
  }

  toggleShowStatuses = () =>  this.setState(prevState => ({ showStatuses: !prevState.showStatuses }));


  closeStatusMenu = () => this.setState({ showStatuses: false }, () => this.props.onChange(this.getEnabledStatuses()));

  handleClick(status) {
    const newState = {...this.state};
    set(newState.statuses[status], 'enabled', !get(newState.statuses[status], 'enabled'));
    if (status == 'invoiced') {
      const { items } = this.props;
      let groupedStatuses = groupBy(items, 'status');
      let invoicedMovements = filter(items, item => item?.isFreightInvoiced) || []
      let movementIds = map(invoicedMovements, 'id') || []
      set(newState.statuses[camelCase(status)], 'count', get(newState.statuses[status], 'enabled') ? invoicedMovements.length : 0)
      set(newState.statuses['completed'], 'count', get(newState.statuses[status], 'enabled') ? filter(groupedStatuses['completed'], item => !includes(movementIds, item?.id)).length : get(groupedStatuses, 'completed', []).length)
      set(newState.statuses['delivered'], 'count', get(newState.statuses[status], 'enabled') ? filter(groupedStatuses['delivered'], item => !includes(movementIds, item?.id)).length : get(groupedStatuses, 'delivered', []).length)
    }
    this.setState(newState, () => {
      this.props.onChange(this.getEnabledStatuses())
    });
  }

  handleSelectAll = () => {
    const newState = {...this.state};
    forEach(newState.statuses, status => set(status , 'enabled', true));
    this.setState(newState, () => {
      this.props.onChange(this.getEnabledStatuses());
    });
  }

  handleClearAll = () => {
    const newState = {...this.state};
    forEach(newState.statuses, status => set(status , 'enabled', false));
    this.setState(newState, () => {
      this.props.onChange(this.getEnabledStatuses());
    });
  }

  getEnabledStatuses() {
    return compact(map(this.state.statuses, (stat, status) => includes(this.props.includeStatuses, status) && stat.enabled ? snakeCase(status) : null));
  }

  groupByStatus() {
    const { items } = this.props;

    if(items) {
      const allStatuses = keys(this.state.statuses);
      let groupedStatuses = groupBy(items, 'status');
      if(!keys(groupedStatuses.length) !== allStatuses.length) {
        forEach(allStatuses, status => {
          if(!includes(keys(groupedStatuses), snakeCase(status)))
            groupedStatuses[camelCase(status)] = [];
          if (status == 'invoiced') {
            let invoicedMovements = filter(items, item => item?.isFreightInvoiced)
            let movementIds = map(invoicedMovements, 'id')
            groupedStatuses[camelCase(status)] = invoicedMovements
            groupedStatuses['completed'] = filter(groupedStatuses['completed'], item => !includes(movementIds, item?.id))
            groupedStatuses['delivered'] = filter(groupedStatuses['delivered'], item => !includes(movementIds, item?.id))
          }
        });
      }
      return groupedStatuses;
    }
  }

  setStats() {
    const grouped = this.groupByStatus();
    if(grouped) {
      const newState = {...this.state};
      forEach(grouped, (items, status) => {
        set(newState.statuses[camelCase(status)], 'enabled', includes(this.props.enabledStatuses, camelCase(status)))
        set(newState.statuses[camelCase(status)], 'count', items.length);
      });
      this.setState(newState);
    }
  }

  render() {
    const { label, items } = this.props;
    const { statuses } = this.state;
    const enabledStatusesCount = size(this.getEnabledStatuses());
    let invoicedMovements = filter(items, item => item?.isFreightInvoiced) || []
    let disableInvoicedStatus = invoicedMovements.length == 0
    return (
      <div style={{margin: '0 1px'}}>
        <Chip
          id="status-options"
          label={`${label} (${enabledStatusesCount})`}
          deleteIcon={<CancelIcon style={{color: ERROR}} />}
          variant="outlined"
          clickable
          onClick={this.toggleShowStatuses}
          onDelete={this.handleClearAll}
          color="secondary"
          ref={this.chipRef}
        />
        <Menu
          anchorEl={this.chipRef.current}
          open={this.state.showStatuses}
          onClose={this.closeStatusMenu}
        >
          <React.Fragment>
            <MenuItem
            style={{fontSize: '14px', padding: '6px 8px'}}
            key="selectAll"
            onClick={ this.handleSelectAll }
            >
              Select All
            </MenuItem>
            { map(orderBy(statuses, 'count', ['desc']), status => {
              if (includes(this.props.includeStatuses, status.id)){
                return (
                  <MenuItem
                    style={{fontSize: '14px', padding: '5px 8px'}}
                    key={ status.id }
                    disabled={status.id == 'invoiced' ? disableInvoicedStatus : status.count == 0 }
                    onClick={ () => this.handleClick(status.id) }
                  >
                    <Checkbox
                      className={status.id}
                      checked={status.enabled}
                      disabled={status.id == 'invoiced' ? disableInvoicedStatus : status.count == 0 }
                      style={{color: `${BORDER_COLORS[status.id] || status.color}`, padding: '2px 10px 2px 0'}}
                    />
                    <span>
                      {`${status.label} (${status.count})`}
                    </span>
                  </MenuItem>
                )
              }
            })}
          </React.Fragment>
        </Menu>
      </div>
    );
  }
}

export default StatusFilter;

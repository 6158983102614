import React from 'react';
import connect from "react-redux/es/connect/connect";
import {
  map, get, set, forEach, startCase, isArray, isEmpty, reject, upperFirst, has, isEqual, includes
} from 'lodash';
import './hierarchy.scss';
import { Chip, Tooltip, Checkbox, FormControlLabel, Button } from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircleOutline';
import AddCircle from '@mui/icons-material/AddCircleOutline';
import RefreshIcon from '@mui/icons-material/Refresh';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import APIService from '../../services/APIService';
import LoaderInline from '../LoaderInline';

const UNKNOWN_COLOR = 'hierarchy-true-black';
const COLOR_LEGEND = {
  unplanned: 'hierarchy-red',
  notbooked: 'hierarchy-red',
  planned: 'hierarchy-blue',
  booked: 'hierarchy-yellow',
  delivered: 'hierarchy-green',
  unplanned_order: 'hierarchy-blue-stripes',
};
const STATUS_COLORS = {
  ...COLOR_LEGEND,
  confirmed: 'hierarchy-yellow',
  'void': 'hierarchy-black',
  completed: 'hierarchy-green',
  invoiced: 'hierarchy-green',
  paid: 'hierarchy-green',
};


class Bar extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      dummy: false
    };
  }

  onSubTreeIconClick(parentDOMId) {
    if(parentDOMId) {
      let kidsState = document.parentsWithKids[parentDOMId];
      kidsState = !kidsState;
      this.toggleKids(parentDOMId, kidsState);
      document.parentsWithKids[parentDOMId] = kidsState;
      this.setState({dummy: !this.state.dummy}, this.props.heightFunc);
    }
  }

  toggleKids(parentDOMId, state){
    const kids = document.querySelectorAll(`div[parentdomid='${parentDOMId}']`);
    if(kids)
      kids.forEach(kid => {kid.hidden = !state;});
  }

  isExpanded() {
    return document.parentsWithKids[this.props.ownerDOMId];
  }

  getIcon() {
    return this.isExpanded() ?
           <Tooltip placement='top' title='Collapse'><RemoveCircle /></Tooltip> :
           <Tooltip placement='top' title='Expand'><AddCircle /></Tooltip>;

  }

  render() {
    const { details, total, haveKids, ownerDOMId, entity, statusColors } = this.props;
    const className = 'subtree-icon ' + (this.isExpanded() ? 'collapse' : '');
    const sColors = statusColors || STATUS_COLORS;
    return (
      <div className='hierarchy-bar'>
        {
          haveKids &&
          <span className={className} onClick={() => this.onSubTreeIconClick(ownerDOMId)}>
            {this.getIcon() }
          </span>
        }
        {
          map(reject(details, {value: 0}), (detail, index) => {
            const type = (entity === 'freightorder' && detail.type === 'unplanned') ? 'unplanned_order' : detail.type;
            const width = (detail.value/total)*100 + '%';
            const prefixClassName = index === 0 ? 'sub-bar-first ' : '';
            return (
              <Tooltip title={upperFirst(detail.description)} placement="top">
                <span
                  style={{width: `${width}`}}
                  key={type}
                  className={prefixClassName + get(sColors, type.toLowerCase(), UNKNOWN_COLOR)}>
                  {detail.label}
                </span>
              </Tooltip>
            );
          })
        }
      </div>
    );
  }
}

class HierarchyObject extends React.Component {
  constructor(props){
    super(props);
    this.setVerticalConnectorsHeight = this.setVerticalConnectorsHeight.bind(this);
  }

  componentDidMount() {
    setTimeout(this.setVerticalConnectorsHeight, 100);
    //setTimeout(this.collapseExpandedSubtree, 150);
  }

  generateRandomId() {
    return Math.random().toString(36).substring(7);
  }

  getParentY(id) {
    const parent = document.getElementById(id);
    if(parent)
      return parent.querySelector('div.hierarchy-bar').getBoundingClientRect().y;
  }


  getHeight(id, parentId) {
    const parentY = this.getParentY(parentId);
    const descendantBar = document.querySelector(`div[descendantid="${id}"] div.hierarchy-bar`);
    if(descendantBar && parentY)
      return descendantBar.getBoundingClientRect().y - parentY - 10;
  }

  setVerticalConnectorHeight(verticalConnectorId, descendantId, parentId) {
    if(!has(document.parentsWithKids, parentId) || document.parentsWithKids[parentId])
      set(
        document.getElementById(verticalConnectorId),
        'style.height',
        this.getHeight(descendantId, parentId) + 'px'
      );
  }

  setVerticalConnectorsHeight() {
    forEach(document.descendantCombos, combo => {
      this.setVerticalConnectorHeight(
        combo.verticalConnectorId, combo.descendantId, combo.parentId
      );
    });
  }

  collapseExpandedSubtree() {
    let els = document.getElementsByClassName('collapse');
    els = [...els];
    els.shift();
    forEach(els, el => el.click());
  }

  render() {
    const {
      href, label, descendantId, total, bars, origin, children, entity, statusColors,
      checkbox, selected, onSelectChange, id
    } = this.props;
    const parentDOMId = this.generateRandomId();
    const hasKids = !isEmpty(children);
    if(hasKids)
      document.parentsWithKids[parentDOMId] = true;
    return (
      <div
        elementId={id}
        id={parentDOMId}
        descendantid={descendantId}
        className={descendantId ? 'hierarchy-object children' : 'hierarchy-object'}>
        <div className={"label" + (hasKids ? ' with-kids' : '')}>
          {
            checkbox &&
            <Checkbox
              style={{padding: '2px', paddingTop: '0px', paddingLeft: '0px'}}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              checked={includes(selected, id)}
              onChange={event => onSelectChange(id, event.target.checked)}
            />
          }
          <span>
            {
              (href && !origin) ?
              <a href={href} target='_blank' rel="noopener noreferrer">{label}</a> :
              label
            }
          </span>
        </div>
        <div>
          <Bar
            entity={entity}
            total={total}
            details={bars}
            haveKids={hasKids}
            ownerDOMId={parentDOMId}
            heightFunc={this.setVerticalConnectorsHeight}
            statusColors={statusColors}
          />
        </div>
        {
          map((children || []), child => {
            const descendantDOMId = this.generateRandomId();
            const verticalConnectorId = this.generateRandomId();
            document.descendantCombos.push(
              {verticalConnectorId: verticalConnectorId, descendantId: descendantDOMId, parentId: parentDOMId}
            );
            return (
              <div key={child.label} parentdomid={parentDOMId}>
                <HierarchyObject descendantId={descendantDOMId} {...child} />
                <div id={verticalConnectorId} className="vertical" />
              </div>
            );
          })
        }
      </div>
    );
  }
}

class Hierarchy extends React.Component {
  constructor(props) {
    super(props);
    document.descendantCombos = [];
    document.parentsWithKids = {};
    this.state = {
      data: null
    };
  }

  componentDidMount() {
    const { data } = this.props;
    if(data)
      this.setState({data: data});
    else
      this.getHierarchyData();
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps.data, this.props.data))
      this.setState({data: this.props.data});
  }

  getHierarchyData() {
    const {contractId, token, orderId} = this.props;
    if(isEmpty(this.state.data) && token) {
      let service;
      if(contractId)
        service = APIService.contracts(contractId).hierarchy()
      else if (orderId)
        service = APIService.freights().orders(orderId).appendToUrl('hierarchy/')

      if(service?.URL)
        service.get(token).then(data => this.setState({data: [data]}));
    }
  }


  render() {
    const { legendColors, statusColors, selected, onSelectChange, checkbox, onRefresh } = this.props;
    const { data } = this.state;
    const ids = map(data, 'id');
    const canUnselectAll = get(ids, 'length') <= get(selected, 'length');
    const allSelected = Boolean(checkbox && canUnselectAll);
    return (
      <div className="hierarchy-container col-md-12">
        <div className="color-legend padding-reset">
          {
            map(legendColors || COLOR_LEGEND, (klass, name) => {
              return (
                <Chip
                  key={name}
                  className={klass}
                  label={startCase(name)}
                  style={{marginRight: '5px', width: '100px', fontSize: '10px'}}
                />
              );
            })
          }
        </div>
        <div style={{width: '100%', display: 'inline-flex', alignItems: 'center', justifyContent: 'flex-start'}}>
        {
          checkbox &&
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={allSelected}
                          onChange={event => onSelectChange(null, null, event.target.checked ? ids : [])}
                />
              }
              label="Select/Deselect All"
            />
          </div>
        }
        {
          onRefresh &&
          <div>
            <Button onClick={onRefresh} color='primary' style={{marginRight: '2px'}}>
              <RefreshIcon fontSize='medium' style={{marginRight: '2px'}} />
              Refresh
            </Button>
          </div>
        }
        </div>
        {
          (!isEmpty(data) && isArray(data)) ?
          map(data, object => {
            return (
              <div className="col-sm-12 padding-reset" style={{marginTop: '10px'}} key={object.label}>
                <HierarchyObject
                  {...object}
                  statusColors={statusColors}
                  checkbox={checkbox}
                  selected={selected}
                  onSelectChange={onSelectChange}
                />
              </div>
            );
          }) :
          <LoaderInline
            style={{height: '500px'}}
            containerClassName="inline-loader-container"
            imageStyle={{width: '25%'}}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(Hierarchy);

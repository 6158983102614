import React from 'react';
import { connect } from 'react-redux';
import GenericTable from '../components/GenericTable';
import { convertEpochToDateFormat } from '../common/momentUtilities';
import { isLoading } from '../actions/main';
import {
  getPaginatedInvoicesResponse,
  getInvoicesWeb
} from '../actions/companies/invoices';
import moment from "moment";
import capitalize from 'lodash/capitalize';
import {
  toDateFormat, isGlobalInvoicesPath,
} from "../common/utils";
import APIService from '../services/APIService';
import includes from 'lodash/includes';
import { getWarehouseDashboardMenuOptions as getMenuOptions } from '../components/invoices/utils';
import get from 'lodash/get';
import InvoicesActions from '../components/invoices/InvoicesActions';

class WarehouseDashboardTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: "",
    };
  }

  closeAddSideForm = () => this.setState({item: undefined});

  openAddSideForm = item => this.setState({item: item});

  render() {
    return (
      <div>
        <GenericTable
          {...this.props}
          openAddSideForm={this.openAddSideForm}
          closeAddSideForm={this.closeAddSideForm}
        />
        <InvoicesActions {...this.props} />
      </div>
    );
  }

}


const mapDispatchToProps = dispatch => {
  return {
    handleDefaultCellClick: invoice => {
      dispatch(isLoading('invoiceDetail'));
      let invoiceTenure = invoice.tenureRaw;
      if (includes(invoiceTenure, '/'))
        invoiceTenure = invoiceTenure.split('/').join('-');
      let url = `/invoices/warehouse/${invoiceTenure}/payees/${invoice.payeeCompanyId}/payers/${invoice.payerCompanyId}`;
      let joiner = '?'
      if (invoice.payerNgrId) {
        url += `${joiner}ngrId=${invoice.payerNgrId}`;
        joiner = '&';
      }
      if (invoice.commodityId) {
        url += `${joiner}commodityId=${invoice.commodityId}`;
      }
      document.location.hash = url;
    },
    navigateTo: url => {
      url += url.includes('?') ? '&' : '?'
      url += 'status=draft&type=warehousefee'
      dispatch(getInvoicesWeb(null, url, null, true));
    },
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}`;
      } else {
        url = `${url}?page_size=${pageSize}`;
      }
      url += '&status=draft&type=warehousefee'
      dispatch(getInvoicesWeb(null, url, null, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order) => {
      const hash = window.location.hash
      let months;
      if(hash) {
        const query = new URLSearchParams(hash.split('?')[1])
        months = query.get('months')
      }
      const service = APIService.invoices();
      service.appendToUrl(`web/?page_size=${pageSize}&status=draft&type=warehousefee`);
      if (page) {
        service.appendToUrl(`&page=${page}`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
      }
      if(months)
        service.appendToUrl(`&tenure=${months}`)
      else
        service.appendToUrl(`&tenure=${moment().subtract(1, 'month').format('YYYY-MM')}`)

      return service.URL;
    },
    dispatch
  };
};

const mapStateToProps = state => {
  get(state.companies, 'invoices.invoices', []).forEach((i) => {
    i.dueDate = convertEpochToDateFormat(i.paymentDueDate);
    i['paidOn'] = i.paymentDate ? toDateFormat(i.paymentDate) : '-';
    i['createdOn'] = toDateFormat(new Date(i.createdAt));
    i['status'] = capitalize(i.status);
    if(i['type'] === 'Freight'){
      i['type'] = 'Freight Invoice';
    }
    else if(i['type'] === 'WarehouseFee' || i['type'] === 'Warehouse Fee Invoice'){
      i['type'] = 'Warehouse Fee Invoice';
    }
    else{
      let invoiceType = 'Commodity Contract Invoice';
      i['type'] = i.type === invoiceType || i.type === 'Brokerage' || i.type ==='Freight Invoice' ? i.type : invoiceType;
    }
  });
  const clickedOption = state.companies.invoices.clickedOption;
  return {
    clearSearch: getPaginatedInvoicesResponse,
    globalSearch: isGlobalInvoicesPath(),
    handleDefaultCellClick: true,
    items: state.companies.invoices.invoices,
    paginationData: state.companies.invoices.paginationData,
    optionsItems: item => getMenuOptions(item),
    order: 'desc',
    orderBy: 'createdAt',
    scrollToTopOnUpdate: false,
    selectedInvoice: state.companies.invoices.selectedInvoice,
    canAddPayment: state.companies.invoices.canAddPayment,
    selectedInvoiceId: state.companies.invoices.selectedInvoiceId,
    useNestedOptionMenu: true,
    invoiceId: state.companies.invoices.invoiceId,
    isPdfDownloadable: state.companies.invoices.isPdfDownloadable,
    flag: state.companies.invoices.flag,
    token: state.main.user.token,
    canMarkComplete: state.companies.invoices.canMarkComplete,
    clickedOption
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (WarehouseDashboardTable);

import React from 'react';
import { Divider } from '@mui/material';

const SiteConnector = (props) => {

  const { height = '12px' } = props;
  return (
    <span style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <div className='circle' />
      <Divider orientation='vertical' style={{ height: height, backgroundColor: '#000', width: '1px' }} />
      <div className='circle' />
    </span>
  );
};

export default SiteConnector;

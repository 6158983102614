import React from 'react';
import { connect } from 'react-redux';
import CashPricesTable from '../../containers/CashPricesTable';
import Paper from '@mui/material/Paper';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import AddPriceDialog from './AddPriceDialog';
import { editSelectedCashPrice, setSelectedCashPrice, viewSelectedCashPrice, getCompanyCashPrices, companyCashPricesResponse } from '../../actions/companies/cash-board';
import { TAB } from './constants';
import { isLoading, forceStopLoader } from '../../actions/main';
import Filters from '../common/Filters';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import APIService from '../../services/APIService';
import Button from '@mui/material/Button/Button';
import { isEmpty, get, find, reject } from 'lodash';
import { setDownloadBar } from '../../actions/main';
import alertifyjs from 'alertifyjs';
import CommonListingButton from '../common/CommonListingButton';
import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { defaultViewAction } from '../../common/utils';

class CompanyCashPrices extends React.Component {

    constructor(props) {
        super(props);
        this.uploadForm = React.createRef();
        this.state = {
            openCloseAddPriceDialog: false,
            applyFilters: false,
            openSideDrawer: false,
            filters: {},
            filterValues: {
                commodity__id__in: [],
                grade__id__in: [],
                season__in: [],
                track__in: [],
                buyer__company__id__in: [],
                site__id__in: [],
            },
            selectedCashPrices: [],
            bulkInactiveConfirmDialog: false,
        };
        this.openSideDraw = this.openSideDraw.bind(this);
        this.handleFileChosen = this.handleFileChosen.bind(this);
        this.handleInactivateClick = this.handleInactivateClick.bind(this);
    }

    componentDidMount() {
        this.props.getCompanyCashPrices('', true);
        APIService.profiles()
        .filters('company_active_cash_price')
            .get(this.props.token)
            .then(res => {
                this.setState({
                    filters: get(res, 'company_active_cash_price', {}),
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditSelectedCashPrice !== this.props.isEditSelectedCashPrice)
            this.setState({ openCloseAddPriceDialog: this.props.isEditSelectedCashPrice });
        if (prevProps.isViewSelectedCashPrice !== this.props.isViewSelectedCashPrice)
            this.setState({ openCloseAddPriceDialog: this.props.isViewSelectedCashPrice });
    }

    openSideDraw() {
        this.setState({ openCloseAddPriceDialog: !this.state.openCloseAddPriceDialog });
        this.props.editSelectedCashPrice(false);
        this.props.viewSelectedCashPrice(false);
        this.props.setSelectedCashPrice([]);
    }

    componentWillUnmount() {
        this.props.setCashPricesResponse([]);
        this.props.viewSelectedCashPrice(false);
    }

    handleFilters = bool => {
        this.setState({
            applyFilters: bool,
            openSideDrawer: bool,
        });
    };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters') {
        const { filters } = this.state;
        APIService.profiles()
          .filters()
          .post({ company_active_cash_price: filters }, this.props.token)
          .then(res => {
            this.setState({filters: res?.filters?.company_active_cash_price || {}}, () => {
              this.props.isLoading();
              this.props.setCashPricesResponse([]);
              this.props.getCompanyCashPrices();
            })
          });
      }
    });
  };

    handleFileChosen(event) {
        const file = event.target.files[0];
        event.target.value = '';
        const formData = new FormData();
        formData.append('file', file);
        this.props.isLoading("csv-upload");
        APIService.cash_board()
            .appendToUrl('cash-prices/csv/')
            .request('POST', formData, null)
            .then(response => {
                this.props.dispatch(forceStopLoader("csv-upload"));
                let reasons = get(response?.data, 'reasons')
                if (reasons != null && reasons.length > 0) {
                    reasons = '<li>' + reasons.join('</li><li>');
                    alertifyjs.alert(
                    'Error While Uploading CSV',
                    `<div id="complete-dialog-open" className=""><p>Cash Prices cannot be added because:</p><ul>${reasons}</ul><div>Please download the CSV template again and reupload it with the data under all the given headers.</div></div>`,
                    () => {},
                    );
                }
                else if (response && response.status === 201){
                    alertifyjs.alert(
                        'Processing Complete',
                        `<div><p>Cash Price CSV processing Complete. Cash Price Upload Results are as below:</p><ul><li>Successful: ${get(response.data, 'success')}</li><li>Failed: ${get(response.data, 'failures')}</li></ul>
                        <p>Check the details of uploaded Cash Prices by clicking on the link - <a href=${get(response.data, 'url')}>click here</a>.
                        You can also check the Downloads section's Cash Price group to view detailed results.</p></div>`,
                        () => window.location.reload()
                        );
                }
            })
            .catch(() => {
                this.props.dispatch(forceStopLoader("csv-upload"));
                alertifyjs.error("Failed - Incorrect CSV format")
            });
    }

    updateSelectedItems(selectedCashPrice, isChecked) {
        this.setState({selectedCashPrices: isChecked ? [...this.state.selectedCashPrices, selectedCashPrice] : reject(this.state.selectedCashPrices, {id: selectedCashPrice.id})});
    }

    isSelected = cashPrice => Boolean(find(this.state.selectedCashPrices, {id: cashPrice.id}));

    allSelected = () => this.state.selectedCashPrices?.length === this.props.allItems?.length;

    onSelectAllToggle(event) {
        this.setState({selectedCashPrices: event.target.checked ? this.props?.allItems || [] : []});
    }

    inactivateSelectedCashPrices() {
        const selectedCashPriceIds = this.state.selectedCashPrices.map(item => item.id);
        APIService.cash_board()
                .appendToUrl('cash-prices/inactive/')
                .put({ CashPriceIds: selectedCashPriceIds })
                .then(response => {
                    if (response.success)
                        alertifyjs.success("Successfully Marked Inactive", 2, () => window.location.reload());
                    else
                        alertifyjs.error("Something went wrong");
                });
        this.setState({bulkInactiveConfirmDialog: false})
    }

    handleInactivateClick() {
        if (isEmpty(this.state.selectedCashPrices))
            alertifyjs.error("No cash price selected");
        else
            this.setState({bulkInactiveConfirmDialog: true});
    }

    getActionsOptionMapperListItems() {
      return [defaultViewAction];
    }

    render() {
        return (
            <Paper className='paper-table-paginated'>
                <div>
                    <div>
                        <CommonListingButton
                            showMenus
                            optionMapper={[
                            { name: 'Download Template', fx: () => location.href = 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/Cash+Price+Upload+Template+New.csv' },
                            { name: 'Upload CSV', fx: () => this.uploadForm.current.click() },
                            ]}
                            name='CSV'
                            title='CSV actions'
                        />
                        <Tooltip title='Apply filters' placement='top'>
                            <Button
                                value={this.state.applyFilters}
                                variant="contained"
                                type='button'
                                onClick={() => this.handleFilters(true)}
                                color='primary'
                                className='add-button'
                                style={{ float: 'right', marginLeft: '10px' }}
                            >
                                <FilterListIcon style={{ paddingRight: '5px' }} />
                                FILTERS{' '}
                                {+!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))
                                    ? `(${Object.entries(this.state.filters).filter(val => val[1].length !== 0).length})`
                                    : ''}
                            </Button>
                        </Tooltip>
                        {this.state.applyFilters && (
                            <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                                <Filters
                                    isLoading={this.props.isLoading}
                                    forceStopLoader={this.props.forceStopLoader}
                                    handleFilterState={this.handleFilterState}
                                    filters={this.state.filters}
                                    tracks={this.props.tracks}
                                  filterValues={this.state.filterValues}
                                  allCompanies
                                />
                            </SideDrawer>
                        )}
                        <AddButton label="Price" onClick={this.openSideDraw} style={{ float: 'right', marginLeft: '10px' }} />
                        <CommonListingButton
                            showMenus
                            optionMapper={[
                            { name: 'Inactivate', fx: this.handleInactivateClick },
                            ]}
                            showDownLoadIcon={false}
                            name='Action Selected Prices'
                            title='Action Selected Prices'
                        />
                        <Dialog
                            open={this.state.bulkInactiveConfirmDialog}
                            onClose={() => this.setState({ bulkInactiveConfirmDialog: false })}
                            aria-labelledby="form-dialog-title"
                            fullWidth
                        >
                            <DialogTitleWithCloseIcon
                                onClose={() => this.setState({bulkInactiveConfirmDialog: false})}
                                closeButtonStyle={{ marginTop: '0px' }}
                                id='form-dialog-title'>
                                Bulk Inactive Cash Prices
                            </DialogTitleWithCloseIcon>
                            <DialogContent style={{ marginTop: '15px' }}>
                                <span style={{float: 'left'}}>
                                    Selected cash prices will be marked Inactive.
                                </span>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    type='button'
                                    onClick={() => this.setState({bulkInactiveConfirmDialog: false})}
                                    variant='outlined'>
                                    Cancel
                                </Button>
                                <Button type='button' onClick={() => this.inactivateSelectedCashPrices()} color='primary' variant='contained'>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                        <CommonListingButton
                          showMenus
                          showDownLoadIcon={false}
                          optionMapper={this.getActionsOptionMapperListItems()}
                          title='Actions'
                          name='Actions'
                        />
                    </div>
                    
                    <CashPricesTable
                     isAllCashPriceTab={TAB.MY_CASH_PRICE}
                     showSelectAll={true}
                     updateSelectedItems={(selected, isChecked) => this.updateSelectedItems(selected, isChecked)}
                     isSelected={(cashPrice) => this.isSelected(cashPrice)}
                     allSelected={this.allSelected()}
                     onSelectAllToggle={(event) => this.onSelectAllToggle(event)}
                    />
                    {(this.state.openCloseAddPriceDialog || this.props.isEditSelectedCashPrice || this.props.isViewSelectedCashPrice) &&
                        <SideDrawer
                            isOpen={this.state.openCloseAddPriceDialog}
                            title="Add Price"
                            onClose={this.openSideDraw}
                            size='small'>
                            <AddPriceDialog onClose={this.openSideDraw} isCreate={!this.props.isEditSelectedCashPrice}/>
                        </SideDrawer>
                    }
                    <input ref={this.uploadForm} name="upload" id="upload" type="file" accept=".csv" style={{ visibility: "hidden" }} onChange={this.handleFileChosen} />
                </div>
            </Paper>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        tracks: state.main.tracks,
        isEditSelectedCashPrice: state.companies.cashBoard.editSelectedCashPrice,
        isViewSelectedCashPrice: state.companies.cashBoard.viewSelectedCashPrice,
        allItems: state.companies.cashBoard.companyCashPrices,
    };
};

const mapDispatchToProps = dispatch => ({
    getCompanyCashPrices: (query, loader) => dispatch(getCompanyCashPrices(query, loader, null)),
    setCashPricesResponse: () => dispatch(companyCashPricesResponse([])),
    editSelectedCashPrice: flag => dispatch(editSelectedCashPrice(flag)),
    setSelectedCashPrice: () => dispatch(setSelectedCashPrice([])),
    viewSelectedCashPrice: flag => dispatch(viewSelectedCashPrice(flag)),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
    setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),

});

export default connect(mapStateToProps, mapDispatchToProps)(CompanyCashPrices);

import React from 'react';
import { connect } from 'react-redux';

import '@babel/polyfill';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import APIService from '../../../services/APIService';
import { isEmpty, isArray } from 'lodash';
import LoaderInline from '../../LoaderInline';
import AddButton from '../../common/AddButton';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';

class CommodityContractInvoiceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      contracts: []
    };
    this.onChange = this.onChange.bind(this);
  }

  componentDidMount() {
  }

  getData() {
    APIService.contracts().appendToUrl('invoices/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    });
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/contracts/${id}/contract`);
  }

  async onAddButtonClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/invoices/new?commodityContractId=${id}`, '_blank');
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Commodity Contract Invoices ${suffix}`;
  }

  onChange(event, expanded) {
    if(expanded) {
      event.target.scrollIntoView(true);
      window.scrollBy(100, 400);
      if(isEmpty(this.state.contracts))
        this.getData();
    }
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Identifier</TableCell>
                      <TableCell className="medium">Seller</TableCell>
                      <TableCell className="medium">Buyer</TableCell>
                      <TableCell className="medium">Description</TableCell>
                      <TableCell className="xxsmall">Price Point</TableCell>
                      <TableCell className="xxsmall">{getCountryLabel('tonnage')}</TableCell>
                      <TableCell align='center' className="small">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{contract.referenceNumber}</TableCell>
                          <TableCell className="medium">{contract.sellerName}</TableCell>
                          <TableCell className="medium">{contract.buyerName}</TableCell>
                          <TableCell className="medium">{contract.description}</TableCell>
                          <TableCell className="xxsmall">{contract.pricePoint}</TableCell>
                          <TableCell className="xxsmall">{contract.tonnage} {contract.tonnageUnit}</TableCell>
                          <TableCell align='center' className="small">
                            <AddButton
                              tooltipTitle="Create Commodity Contract Invoice"
                              label="Invoice"
                              onClick={(event) => this.onAddButtonClick(event, contract.id)}
                              style={{ float: 'none'}}
                              variant="outlined"
                            />
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
});

export default connect(mapStateToProps)(CommodityContractInvoiceGroup);

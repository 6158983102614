import React from 'react';
import { connect } from 'react-redux';

import {
  required,
  minLength,
  maxLength,
  truckRegoRegex
} from '../../common/validators';
import InputAdornment from '@mui/material/InputAdornment';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import set from 'lodash/set';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import endsWith from 'lodash/endsWith';
import toUpper from 'lodash/toUpper';
import { MESSAGES } from '../../common/validators';
import {positiveDecimalFilter} from '../../common/input-filters';
import { validateRego, receiveValidateRegoResponse } from '../../actions/main/index';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import '../../common/forms.scss';
import cloneDeep from 'lodash/cloneDeep';
import { getDefaultTruckTrailerUnit, isSystemCompany, getCountryLabel } from '../../common/utils';

class TrailerForm extends React.Component {
  constructor(props) {
    super(props);

    const _regoRegex = MESSAGES.REGO_REGEX
    this.state = {
      fields: {
        rego: {
          value: '',
          validators: [required(), minLength(4, _regoRegex), maxLength(10, _regoRegex), truckRegoRegex()],
          errors: [],
          isDisabled: false,
          isAvailable: true,
        },
        tareWeight: {
          value: '',
          validators: [required()],
          errors: [],
          weight_errors: []
        },
        grossWeight: {
          value: '',
          validators: [required()],
          errors: [],
          weight_errors: []
        },
        unit: {
          value: getDefaultTruckTrailerUnit() || 'MT',
          validators: [],
          errors: [],
        },
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.getIsFormValid = this.getIsFormValid.bind(this);
    this.getSubmitData = this.getSubmitData.bind(this);
    this.validateWeight = this.validateWeight.bind(this);
    this.getIsFormWeightValid = this.getIsFormWeightValid.bind(this);
    this.cancelDrawer = this.cancelDrawer.bind(this);
    this.handleSelectFieldChange = this.handleSelectFieldChange.bind(this);
  }

  componentWillUnmount() {
    this.props.resetValidRegoResponse();
  }

  componentDidMount() {
    setTimeout(() => {
      this.setAllFieldsValuesBySelectedTrailer();
    }, 300);
  }

  componentDidUpdate(prevProps){
    if(this.props.isRegoAvailable && get(this.state.fields, `${this.props.isRegoAvailable.key}.isAvailable`) !== this.props.isRegoAvailable.isAvailable){
      const newState = this.state;
      set(newState.fields, `${this.props.isRegoAvailable.key}.isAvailable`, this.props.isRegoAvailable.isAvailable);
      set(newState.fields, `${this.props.isRegoAvailable.key}.errors`, this.getFieldErrors(this.props.isRegoAvailable.key));
      this.setState(newState);
    }
    if (this.props.selectedTrailer) {
      if (this.props.selectedTrailer.id !== prevProps.selectedTrailer.id) {
        this.setAllFieldsValuesBySelectedTrailer();
      }
    }
  }

  handleSubmit(event) {
    this.setAllFieldsErrors();
    if (this.getIsFormValid() == true && this.getIsFormWeightValid()){
      let data = this.getSubmitData();
      this.props.submit(this.props.companyId || get(this.props, 'selectedFarm.companyId'), data);
    }
    event.preventDefault();
  }

  handleFieldChange(event) {
    const value = endsWith(event.target.id, 'rego') ? toUpper(event.target.value) : event.target.value;
    if(event.target.id === 'rego') {
      this.props.resetValidRegoResponse();
      const newState = {...this.state};
      newState.fields.rego.errors = [];
      this.setState(newState);
    }
    this.setFieldValue(event.target.id, value);
    this.validateWeight(event);
  }

  onBlur(event) {
    const newState = {...this.state};
    set(newState.fields, `${event.target.id}.isAvailable`, true);
    this.setFieldErrors(event.target.id);
    this.validateWeight(event);
    var key = event.target.id;
    setTimeout( () => {
      if(endsWith(key, 'rego')){
        if(isEmpty(get(this.state.fields, `${key}.errors`))){
          this.props.validateRego(key, get(this.state.fields, `${key}.value`));
        }
      }
    }, 500);
  }

  setFieldValue(path, value) {
    this.setState(
      state => set(state, `fields.${path}.value`, value)
    );
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `fields.${path}.value`);
    const validators = get(this.state, `fields.${path}.validators`, []);
    const weight_errors = get(this.state, `fields.${path}.weight_errors`, []);
    const is_rego_available = get(this.state, `fields.${path}.isAvailable`);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message.replace('$regoLabel', getCountryLabel('rego')));
      }
    });

    if(isEmpty(weight_errors) == false){
      return weight_errors;
    }

    if(is_rego_available == false){
      return [`This ${getCountryLabel('rego')} already exists`];
    }
    return errors;
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `fields.${path}.errors`, this.getFieldErrors(path)));
  }

  setAllFieldsValuesBySelectedTrailer() {
    if (this.props.selectedTrailer) {
      const newState = {...this.state};
      newState.fields.rego.value = this.props.selectedTrailer.rego;
      newState.fields.unit.value = this.props.selectedTrailer.unit;
      newState.fields.tareWeight.value = this.props.selectedTrailer.tareWeight;
      newState.fields.grossWeight.value = this.props.selectedTrailer.grossWeight;

      this.setState(newState);
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };

    forEach(this.state.fields, (field, key) => {
      set(newState.fields, `${key}.errors`, this.getFieldErrors(key));
    });

    this.setState(newState);
  }

  getIsFormValid() {
    return !some(this.state.fields, (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
  }

  getIsFormWeightValid(){
    const newState = { ...this.state };
    var isValid = true;
    forEach(this.state.fields, (field, key) => {
      if(key == 'rego' && !get(field, 'isAvailable')){
        isValid = false;
      }
      if(key === 'tareWeight' || key === 'grossWeight'){
        var grossWeight = parseFloat(get(newState.fields, 'grossWeight.value'));
        var tareWeight = parseFloat(get(newState.fields, 'tareWeight.value'));
        if(!isNaN(grossWeight) && !isNaN(tareWeight)){
          if(tareWeight > grossWeight){
            set(newState.fields, 'tareWeight.weight_errors', ["Tare Weight can't be greater than Gross Weight"]);
            set(newState.fields, 'grossWeight.weight_errors', ["Gross Weight can't be less than Tare Weight"]);
            isValid  = false;
          }
        }
      }
    });
    return isValid;
  }

  getSubmitData() {
    let submitData = cloneDeep(this.state.fields);

    forEach(this.state.fields, (field, key) => {
      set(submitData, key, get(field, 'value'));
    });

    return submitData;
  }

  validateWeight(event){
    const value = endsWith(event.target.id, 'rego') ? toUpper(event.target.value) : event.target.value;
    var path = event.target.id;
    const newState = { ...this.state };
    set(newState.fields, `tareWeight.weight_errors`, []);
    set(newState.fields, `grossWeight.weight_errors`, []);

    if(endsWith(path, 'tareWeight') || endsWith(path, 'grossWeight')) {
      var grossWeight = parseFloat(get(this.state.fields, `grossWeight.value`));
      var tareWeight = parseFloat(get(this.state.fields, `tareWeight.value`));
      if(endsWith(path, 'tareWeight')){
        tareWeight = parseFloat(value);
      } else if(endsWith(path, 'grossWeight')){
        grossWeight = parseFloat(value);
      }
      if(!isNaN(tareWeight) && !isNaN(grossWeight)){
        if(tareWeight > grossWeight){
          set(newState.fields, `tareWeight.weight_errors`, ["Tare Weight can't be greater than Gross Weight"]);
          set(newState.fields, `grossWeight.weight_errors`, ["Gross Weight can't be less than Tare Weight"]);
        }
      }
    }
    this.setState(newState);
  }

  handleSelectFieldChange(value, id) {
    this.setFieldValue(id, value);
  }

  cancelDrawer(){
    this.props.closeDrawer();
  }

  render() {
    const selectedUnit = this.state.fields.unit.value;
    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="rego"
                  label={getCountryLabel('rego')}
                  placeholder="Please enter"
                  value={this.state.fields.rego.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={get(this.state.fields, 'rego.errors[0]', '')}
                  onInput = {(e) =>{
                      e.target.value = e.target.value.toString().slice(0,10).replace(/[^0-9a-z]/gi, '');
                  }}
                  disabled={!isSystemCompany() && (!this.props.canAccessAny || this.state.fields.rego.isDisabled || this.props.isTrailerUpdateFormDisplayed)}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="tareWeight"
                  label="Tare Weight"
                  placeholder="Please enter"
                  value={this.state.fields.tareWeight.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={get(this.state.fields, 'tareWeight.errors[0]', '')}
                  disabled={!this.props.canAccessAny}
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{selectedUnit}</InputAdornment>
                  }}
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="grossWeight"
                  label="Gross Weight"
                  placeholder="Please enter"
                  value={this.state.fields.grossWeight.value}
                  onChange={this.handleFieldChange}
                  onBlur={this.onBlur}
                  helperText={get(this.state.fields, 'grossWeight.errors[0]', '')}
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, Infinity)}
                  disabled={!this.props.canAccessAny}
                  InputProps={{
                    endAdornment: <InputAdornment position="end" style={{color: 'rgb(162,162,162)'}}>{selectedUnit}</InputAdornment>
                  }}
                />
              </div>
            </div>
            {
              this.props.canAccessAny ?
              <div className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                  type="button"
                  label="Cancel"
                  variant="contained"
                  default
                  onClick={this.props.selectedTrailer ? this.props.cancelEdit: this.cancelDrawer}
                />
                <CommonButton
                  type="submit"
                  variant="contained"
                  label="Save"
                  primary
                />
              </div>
              : null
            }
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const isRegoAvailable = state.main.isRegoAvailable;
  return {
    isRegoAvailable,
    currentUser: state.main.user.user,
    selectedFarm: state.companies.farms.selectedFarm,
    isTrailerUpdateFormDisplayed: state.companies.companies.company.trucks.isTrailerUpdateFormDisplayed
  };
};

const mapDispatchToProps = dispatch => ({
  validateRego: (key, value) => dispatch(validateRego(key, value)),
  resetValidRegoResponse: () => dispatch(receiveValidateRegoResponse(false))
});

export default connect(mapStateToProps, mapDispatchToProps)(TrailerForm);

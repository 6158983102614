import { connect } from 'react-redux';
import InloadForm from '../components/inloads/InloadForm';
import { updateInload } from '../actions/api/inloads';

function submit(loadId, data, successCallback) {
  return (dispatch) => {
    dispatch(updateInload(loadId, data, successCallback));
  };
}

export default connect(null, { submit })(InloadForm);

import { combineReducers } from 'redux';
import commodities from './commodities';
import varieties from './varieties';
import grades from './grades';
import bhcLocations from './bhc-company-locations';

export default combineReducers({
  commodities,
  varieties,
  grades,
  bhcLocations
  // ... reducers for other master data
});

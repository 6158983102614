import React from 'react';
import { connect } from 'react-redux';
import '../common/subTab.scss';

import CommonButton from '../common/CommonButton';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import { map } from 'lodash';

class RemoveCompaniesFromGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedGroup: null,
      allCompanies: [],
      fields: {
        removeCompaniesFromGroup: {
          value: [],
        }
      },
      companyName: null,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    APIService.companies()
      .appendToUrl('directory/names/')
      .get(this.props.userToken).then(companyData => this.setState({ allCompanies: companyData }));
  }

  handleSubmit() {
    const selectedItems = Array.from(
      new Set(this.state.fields['removeCompaniesFromGroup'].value.map((company_id) => company_id))
    ).map((company_id) => {
      const company =
        this.state.allCompanies &&
        this.state.allCompanies.find((company) => company.id === company_id);
      const existingGroup =
        company?.companyGroups &&
        company?.companyGroups.find(
          (group) => group.ownerCompanyId === this.props.userCompanyId
        );
      return {
        company,
        existingGroup,
      };
    });

    const sortedItems = [...selectedItems].sort((a, b) => {
      return (a.existingGroup ? -1 : 1) - (b.existingGroup ? -1 : 1);
    });
    let items = ''
    sortedItems.forEach(({ company, existingGroup }) => {
      items += `<tr><td style="text-align: center; border: 1px solid #000; padding: 8px;">${
        company?.name
      }</td><td style="text-align: center; border: 1px solid #000; padding: 8px;">${
        existingGroup !== undefined ? existingGroup?.name : "-"
      }</td></tr>`;
    });

    alertifyjs.confirm(
      'Warning',
      `<div>
      <p>The selected companies will be removed from all groups. This will remove these companies from fees and anywhere else these groups have been added</p>
      <div style="max-height: 400px; overflow-y: auto;">
        <table style="border-collapse: collapse; width: 100%;">
          <thead>
            <tr>
              <th style="border: 1px solid #000; padding: 8px;">Company</th>
              <th style="border: 1px solid #000; padding: 8px;">Group Name</th>
            </tr>
          </thead>
          <tbody>
              ${items}
          </table>
       </div>
       </div>`,
      () => {
        let data = {
          'companyIds': this.state.fields['removeCompaniesFromGroup'].value,
        }
        APIService.companies(this.props.userCompanyId)
          .appendToUrl('groups/?remove_companies=true')
          .post(data, this.props.userToken)
          .then(() => {
            alertifyjs.success('Companies removed from group successfully');
          });
          this.props.closeDrawer()
          window.location.reload()
      },
      () => {}).set('reverseButtons', true).set('labels', {ok:'Confirm'});
    }
  
  onMultiSelectChange = (id, selectedItems) => {
    const newState = {...this.state};
    newState.fields[id].value = map(selectedItems, 'id');
    this.setState(newState);
  };

  render() {
    return (
      <div style={{ marginTop: '5px' }}>
        <form onSubmit={this.handleSubmit} noValidate>
            <CommonMultiSelect
            id="removeCompaniesFromGroup"
            items={this.state.allCompanies}
            selectedItems={this.state.fields['removeCompaniesFromGroup'].value}
            displayField='name'
            onChange={this.onMultiSelectChange}
            label='Companies'
            variant='standard'
            style={{margin: '15px 0 15px 0'}}
            selectAll
            clearAll
            />
        
        <div style={{ float: 'right', top: '20px' }}>
            <CommonButton type='button' variant='outlined' label='Cancel' default onClick={this.props.closeDrawer} />
            <CommonButton primary={true} variant="contained" label='Remove' type='submit' />
        </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    userCompanyId: state.main.user.user.companyId,
    companies: state.companies.companies.items,
    companyParties: state.companies.companies.companyParties,
    companyGroups: state.companies.companies.companyGroups,
    userToken: state.main.user.token,
  });

export default connect(mapStateToProps)(RemoveCompaniesFromGroup);
import React from 'react';
import { connect } from 'react-redux';

import { getContracts, getFarmContracts, getContractsResponse } from '../../actions/companies/contracts';
import ContractsTable from '../../containers/ContractsTable';
import AddButton from '../common/AddButton';
import Paper from '@mui/material/Paper';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import TuneIcon from '@mui/icons-material/Tune';
import { setHeaderText, setBreadcrumbs, isLoading, forceStopLoader, isSearchApplied } from '../../actions/main';
import Button from '@mui/material/Button/Button';
import APIService from '../../services/APIService';
import { attachCSVEventListener, defaultViewAction, getCountryConfig } from '../../common/utils';
import Tooltip from '@mui/material/Tooltip';
import {
  COMPANY_ADMIN, OFFICE_ADMIN, CONTRACT_FILTER_STATUSES, CONTRACT_INVOICING,
  OBSERVER_TYPE_ID, SYSTEM, getContractsGlobalListingTableHeaders, CONTRACT_TABLE_COLUMN_LIMIT, DEFAULT_CONTRACT_TABLE_COLUMN_LIMIT, PREDEFINED_DATE_RANGE_FILTER_KEYS, FILTER_KEYS_TO_EXCLUDE
} from '../../common/constants';
import { includes, get, isEmpty, isEqual, size, forEach, flatten, filter, omitBy } from 'lodash';
import { setDownloadBar } from '../../actions/main';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import Filters from '../common/Filters';
import FreightMovementWarningDialog from '../../components/freights/FreightMovementWarningDialog';
import { freightMovementWarning } from '../../actions/companies/contracts';
import CommonListingButton from '../common/CommonListingButton';
import alertifyjs from 'alertifyjs';
import DownloadDataDialog from '../common/DownloadDataDialog';
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import FiltersAppliedChip from '../common/FiltersAppliedChip';


const CONTRACT_FILTERS_MAPPING = {
  'delivery_start_date_range': ['delivery_start_date__gte'],
  'delivery_end_date_range': ['delivery_end_date__lte'],
  'updated_at_date_range': ['updated_at__gte', 'updated_at__lte']
}

class Contracts extends React.Component {
  constructor(props) {
    super(props);
    this.uploadForm = React.createRef();
    this.state = {
      quickFilters: false,
      facets: {},
      savedFacets: {},
      appliedFacets: {},
      countryConfig: getCountryConfig(),
      csvData: [],
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      filter_statuses: CONTRACT_FILTER_STATUSES,
      contract_invoicing: CONTRACT_INVOICING,
      csvPopup: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      searchView: false,
      isFilteredCsv: false,
      filterValues: {
        commodity__id__in: [],
        grade__id__in: [],
        status__in: [],
        price_point__id__in: [],
        administration__invoicing__in: [],
        buyer__company__id__in: [],
        seller__company__id__in: [],
        buyer__ngr__id__in: [],
        seller__ngr__id__in: [],
        delivery_start_date_range: '',
        delivery_end_date_range: '',
        delivery_start_date__gte: '',
        delivery_end_date__lte: '',
        season__in: [],
        updated_at_date_range: '',
        updated_at__lte: '',
        updated_at__gte: '',
        consignor__handler__id__in: [],
        consignee__handler__id__in: [],
      },
      customTableColumnOptions: false,
      customTableColumnNames: {},
      customColumnTitle: undefined,
    };
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.getActionsOptionMapperListItems = this.getActionsOptionMapperListItems.bind(this);
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({[key]: value}, () => {
      if(key === 'applyFilters') {
        const { filters } = this.state;
        APIService
          .profiles()
          .filters()
          .post({ contract: filters }, this.props.token)
          .then(res => {
            this.setState({filters: get(res, 'filters.contract', {})}, () => {
              this.props.isLoading();
              this.props.unMountContracts();
              this.props.onGetContracts();
              this.getFacets()
            })
          });
      }
    });
  };

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  _attachCSVEventListener() {
    attachCSVEventListener('contracts-csv-ready', 'Contracts', this.onDownloadResponse);
  }
  componentDidMount() {
    this.props.isLoading('genericTableWithData');
    this.props.applySearch(null);
    this.getFacets()
    APIService.profiles()
      .filters('contract')
      .get(this.props.token)
      .then(res => {
        this.setState({
          filters: get(res, 'contract', {}),
        });
      });
    this._attachCSVEventListener();
    if (this.props.farmId) {
      this.props.setHeaderText(this.props.farmName);
      this.props.onGetFarmContracts(this.props.farmId);
    } else {
      this.props.setHeaderText('Contracts');
      this.props.onGetContracts();
    }
    this.setBreadcrumbs();
  }

  componentWillUnmount() {
    this.props.unMountContracts();
    this.props.applySearch(null);
  }

  setBreadcrumbs() {
    const { farmId, farmName, count } = this.props;
    let breadcrumbs = [{ text: `Contracts (${count})` }];
    if (farmId) {
      let farmRoute = `/stocks/storages-view?farmId=${farmId}`;

      breadcrumbs = [{ text: 'Farms', route: '/farms' }, { text: farmName, route: farmRoute }, ...breadcrumbs];
    }

    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) this.props.setBreadcrumbs(breadcrumbs);
  }

  componentDidUpdate(prevProp) {
    if (get(prevProp, 'count') !== this.props.count) this.setBreadcrumbs();
  }


  getFacets = url => {
    const service = APIService.contracts().appendToUrl('web/?only_facets=true')
    if (url) {
      service.URL = url + '&only_facets=true'
    }
    service.get().then(response => {
      let newSelected = {}
      forEach(response, (fieldFacets, field) => {
        const selected = flatten(filter(fieldFacets, facet => facet[2] === true).map(s => s[0]))
        if(selected?.length)
          newSelected[field] = selected
      })

      this.setState({facets: response, appliedFacets: newSelected, savedFacets: newSelected}, () => {
      })
    })
  }

  fetchCSVData = (callback) => {
    const { setDownloadBar } = this.props;
    var params = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns)
      params+= params.length == 0 ? 'custom_csv' : '&custom_csv';
    if (this.props.isSearchApplied && this.state.searchView)
      params+= params.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    setDownloadBar('Your Contracts CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    const service = APIService.contracts().appendToUrl(`web/csv/?${params}`);
    this.setState({csvPopup: false, searchView: false})
    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => {
        this.setState({ csvData: csvData || []});
      });
    if (callback) {
      callback();
    }
  };

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, OBSERVER_TYPE_ID, SYSTEM], get(this.props.currentUser, 'typeId'));
  }

  handleFileChosen = event => {
    let file = event.target.files[0];
    event.target.value = '';
    let formData = new FormData();
    formData.append('file', file);
    this.props.setDownloadBar('Your Contract CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true);
    APIService.contracts().appendToUrl('web/csv/').request(
      'POST', formData, null,
    ).catch(() => alertifyjs.error("Failed - Incorrect CSV format"));
  };

  getOptionMapperListItems() {
    let items = [{ name: 'Complete List', fx: () => this.customCsvEnabled(false) }];
    if (!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0)))
      items = [...items, { name: 'Filtered List', fx: () =>  this.customCsvEnabled(true) }];
    if (this.props.currentUser.company.canUploadContractCsv || this.props.currentUser.isSuperuser)
      items = [
        ...items,
        { name: 'Upload Contract', fx: () => this.uploadForm.current.click() },
        { name: 'Download Contract Template', fx: () => location.href = this.props.currentUser.isSuperuser ? 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/Generic_Contracts_Upload_VIA_SUPERUSER_2.csv' : 'https://agrichain-api-production.s3.ap-southeast-2.amazonaws.com/assets/Contract_Upload_Template_VIA_AC.csv' }
      ];
    return items;
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/contracts_csv/`).get(this.props.token);
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv || this.props.isSearchApplied) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }

  getActionsOptionMapperListItems() {
    return [
      {name: 'Custom Table Columns', fx: () => this.updateCustomTableColumns()},
      defaultViewAction
     ];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/contract_table/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    const contractColumns = getContractsGlobalListingTableHeaders();
    return contractColumns.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(this.state.filters[key]))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(CONTRACT_FILTERS_MAPPING, key)) : value.length !== 0

  onFacetChange = selected => {
    this.setState({appliedFacets: omitBy(selected, values => isEmpty(values))})
  }

  clearURLFromFacets = url => {
    let existingParams = new URLSearchParams(url.split('?')[1])
    let newExistingParams = {}
    existingParams.keys().forEach(key => {
      if(!key.startsWith('facets'))
        newExistingParams[key] = existingParams.get(key)
    })
    url = url.split('?')[0] + '?' + new URLSearchParams(newExistingParams).toString()
    return url
  }

  getFacetQueryString = () => {
    const { appliedFacets } = this.state
    const params = new URLSearchParams();
    const appendNestedParams = (obj, prefix) => {
      for (const key in obj) {
        if (Array.isArray(obj[key])) {
          obj[key].forEach(value => params.append(`${prefix}[${key}]`, value));
        } else if (typeof obj[key] === 'object') {
          appendNestedParams(obj[key], `${prefix}[${key}]`);
        } else {
          params.append(`${prefix}[${key}]`, obj[key]);
        }
      }
    };

    appendNestedParams(appliedFacets, 'facets');
    return params.toString()
  }

  onFacetApply = (url, close=true) => {
    this.props.isLoading('TableFacets');

    let queryString = this.getFacetQueryString()

    url = this.clearURLFromFacets(url)
    url += (url.includes('?') ? '&' : '?') + queryString

    this.setState({
      savedFacets: this.state.appliedFacets, quickFilters: close ? false : this.state.quickFilters
    }, () => this.props.onGetContracts(url))
  }

  onFacetClear = (url, force=false, close=true) => {
    if(!force && isEmpty(this.state.appliedFacets))
      this.props.forceStopLoader()
    else {
      this.props.isLoading('genericTableWithData');
      APIService.profiles().appendToUrl('facets/contract/').delete().then(() => {
        this.setState({appliedFacets: {}, quickFilters: close ? false : this.state.quickFilters}, () => {
          let URL = this.clearURLFromFacets(url)
          this.getFacets(URL)
          this.props.onGetContracts(URL)
        })
      })
    }
  }

  render() {
    return (
      <Paper className='paper-table-paginated'>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
            {this.canExportCSV() && (
              <CommonListingButton
                defaultHandler={() => this.customCsvEnabled(false)}
                showMenus={this.props.currentUser.company.canUploadContractCsv ? true : !isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))}
                optionMapper={this.getOptionMapperListItems()}
                title='Download Contents of the table in a CSV'
                name='CSV'
              />
            )}
            <DownloadDataDialog
              open={this.state.csvPopup}
              onClose={() => this.setState({csvPopup: false, searchView: false})}
              title='Download Contracts Data'
              enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
              isSearchApplied={this.props.isSearchApplied}
              searchView={this.state.searchView}
              onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
              isFilteredCsv={this.state.isFilteredCsv}
              onDownload={this.fetchCSVData}
              customColumnTitle={this.state.customColumnTitle}
              user={this.props.currentUser}
              token={this.props.token}
              csvType="contracts_csv"
              updateColumnCount={(count) => this.updateColumnCount(count)}
            />
            <input ref={this.uploadForm} name="upload" id="upload" type="file" accept=".csv" style={{ visibility: "hidden", display: 'none' }} onChange={this.handleFileChosen} />
            <Tooltip title='Apply filters' placement='top'>
              <Button
                value={this.state.applyFilters}
                variant="contained"
                type='button'
                onClick={() => this.handleFilters(true)}
                color='primary'
                className='add-button'
                style={{ float: 'right', marginLeft: '10px' }}
              >
                <FilterListIcon style={{ paddingRight: '5px' }} />
                FILTERS{' '}
                {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                 ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                 : ''}
              </Button>
            </Tooltip>
            {this.state.applyFilters && (
              <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                <Filters
                  isLoading={this.props.isLoading}
                  forceStopLoader={this.props.forceStopLoader}
                  handleFilterState={this.handleFilterState}
                  filters={this.state.filters}
                  statusTemp={this.state.filter_statuses}
                  invoicingTemp={this.state.contract_invoicing}
                  filterValues={this.state.filterValues}
                  isContractFilters
                />
              </SideDrawer>
            )}
            <AddButton label='Contract' onClick={this.props.handleAddContractButtonClick} app='contract' style={{ marginTop: '0px' }} />
            {
            this.state.countryConfig?.contracts?.spot &&
                <AddButton label='Spot Contract' onClick={() => window.location.hash = '#/contracts/new/?contractType=spot'} app='contract' style={{ marginTop: '0px', marginRight: '10px' }} />
            }
            <div style={{float: 'right', marginRight: '10px'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>
            <SideDrawer
              isOpen={this.state.customTableColumnOptions}
              title={this.state.customColumnTitle}
              onClose={() => this.setState({customTableColumnOptions: false})}
              size="small"
            >
              <CustomHeaderOptions
                customColumns={this.state.customTableColumnNames}
                closeDrawer={() => this.setState({customTableColumnOptions: false})}
                user={this.props.currentUser}
                token={this.props.token}
                table_type="contract_table"
                columnsMapping={this.getColumnsMapping()}
                maxColumnLimit={CONTRACT_TABLE_COLUMN_LIMIT}
                updateColumnCount={(count) => this.updateColumnCount(count)}
                defaultColumnLimit={DEFAULT_CONTRACT_TABLE_COLUMN_LIMIT}
              />
            </SideDrawer>
          </div>
          <FiltersAppliedChip filters={this.state.filters} show style={{paddingRight: '45%'}} />
          <ContractsTable
            appliedFacets={this.state.appliedFacets}
            savedFacets={this.state.savedFacets}
            facets={this.state.facets}
            showFacets={this.state.quickFilters}
            getFacets={this.getFacets}
            onFacetChange={this.onFacetChange}
            onFacetApply={this.onFacetApply}
            onFacetClear={this.onFacetClear}
            headerControls={
              Boolean(this.props.currentUser?.company?._enableFacets) &&
                  <Tooltip arrow title='Tune your results with quick filters'>
                    <span style={{marginRight: '10px'}}>
                      <Badge badgeContent={size(this.state.appliedFacets)} color="primary">
                        <IconButton onClick={() => this.setState({quickFilters: !this.state.quickFilters})} color={this.state.quickFilters ? 'primary' : 'default'} disabled={isEmpty(this.state.facets)}>
                          <TuneIcon fontSize='inherit' />
                        </IconButton>
                      </Badge>
                    </span>
                  </Tooltip>
            }
          />
          {this.props.fmWarningFlag && <FreightMovementWarningDialog {...this.props} handleToUpdate={() => this.props.closeWarningDialog()} />}
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.main.user.user,
    breadcrumbs: state.main.breadcrumbs,
    token: state.main.user.token,
    selectedFarm: state.companies.farms.selectedFarm,
    count: get(state.companies.contracts, 'paginationData.count') || 0,
    fmWarningList: state.companies.contracts.fmWarningList,
    fmWarningFlag: state.companies.contracts.fmWarningFlag,
    FMwarningContractID: state.companies.contracts.FMwarningContractID,
    isSearchApplied: state.main.isSearchApplied,
  };
};

const mapDispatchToProps = dispatch => ({
  unMountContracts: () => dispatch(getContractsResponse([])),
  onGetContracts: (url, queryString) => dispatch(getContracts(url, true, queryString)),
  onGetFarmContracts: farmId => dispatch(getFarmContracts(farmId)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  isLoading: (component) => dispatch(isLoading(component)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  handleAddContractButtonClick: () => window.open('/#/contracts/new', '_blank'),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  closeWarningDialog: () => dispatch(freightMovementWarning(false, [])),
  applySearch: searchStr => dispatch(isSearchApplied(searchStr))
});

export default connect(mapStateToProps, mapDispatchToProps)(Contracts);

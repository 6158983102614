import { combineReducers } from 'redux';
import companies from './companies';
import farms from './farms';
import farmFields from './farm-fields';
import storages from './storages';
import systemStorages from './system-storages';
import ngrs from './ngrs';
import companyNgrs from './company-ngrs';
import farmEmployees from './farm-employees';
import storageLevels from './storage-levels';
import inloads from './inloads';
import outloads from './outloads';
import contracts from './contracts';
import freights from './freights';
import orders from './orders';
import conditions from './conditions';
import companySites from './company-sites';
import notes from './notes';
import invoices from './invoices';
import releaseNotes from './release-notes';
import vendorDecs from './vendor-decs';
import cashBoard from './cash-board';
import contractBids from './contract-bids';

export default combineReducers({
  companies,
  contracts,
  cashBoard,
  freights,
  orders,
  farms,
  farmFields,
  storages,
  ngrs,
  systemStorages,
  farmEmployees,
  companyNgrs,
  storageLevels,
  inloads,
  outloads,
  conditions,
  companySites,
  notes,
  invoices,
  releaseNotes,
  vendorDecs,
  contractBids
});

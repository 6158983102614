import React from 'react';

import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class InvoiceDetailsConditionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  render() {
    const { invoiceDetails } = this.props;
    const { expanded } = this.state;

    return (
      <React.Fragment>
        {(invoiceDetails.invoiceConditions) &&
        <Paper className="invoice-details-section-container">
          <h2 onClick={this.toggleExpanded}>
            Conditions
            <span className="expand-icon">
              {renderBasedOnExpandedFlag(expanded)}
            </span>
          </h2>

          {expanded && invoiceDetails.invoiceConditions && (
            <div>
              <div className={"condition-section"}>{invoiceDetails.invoiceConditions}</div>
            </div>
          )}
        </Paper>}
      </React.Fragment>
    );
  }
}

export default InvoiceDetailsConditionSection;
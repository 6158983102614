import React from 'react';
import moment from 'moment';
import Help from '@mui/icons-material/Help';
import Paper from '@mui/material/Paper';
import includes from 'lodash/includes';
import map from 'lodash/map';
import has from 'lodash/has';
import compact from 'lodash/compact';
import isArray from 'lodash/isArray';
import { convertEpochToDateFormat } from '../../../common/momentUtilities';
import  ContractDetailsActions  from './ContractDetailsActions';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import get from 'lodash/get';
import { ChipLabel } from '../../common/ChipLabel';
import TonnageDetail from '../../common/TonnageDetail';
import { isFloat, isDirectToBuyerAllocationEnabled, isThroughWarehouseAllocationEnabled } from '../../../common/utils';
import { AppContext } from '../../main/LayoutContext';


const renderBasedOnStatus = status => {
  switch (status) {
  case 'Confirmed':
    return <img src='images/confirmed.svg'></img>;
  case 'Delivered':
    return <img src='images/delivered.svg'></img>;
  case 'Open':
    return <img src='images/open.svg'></img>;
  case 'In Progress':
    return <img src='images/in-progress.svg'></img>;
  case 'Void':
    return <img src='images/voided.svg'></img>;
  case 'Completed':
    return <img src='images/completed.svg'></img>;
  case 'Rejected':
    return <img src='images/rejected.svg'></img>;
  case 'Invoiced':
    return <img src='images/invoiced.svg'></img>;
  case 'Paid':
    return <img src='images/paid.png'></img>;
  }
  return <Help style={{ fill: '#eee200', height: '40px', width: '40px' }} />;
};

const TonnageDetailWidget = props => {
  const showAllocatedTonnage = isDirectToBuyerAllocationEnabled() || isThroughWarehouseAllocationEnabled();
  // TODO - Need to form unique unit from API end only
  const _unit = props?.contract?.requestedUnit || props?.contract?.commodity?.priceUnit;
  const totalTonnage = parseFloat(get(props.contract, 'inferredTonnage'));
  const progressTonnage = parseFloat(get(props.contract, 'progressTonnage'));
  const outstandingTonnage = parseFloat(get(props.contract, 'outstandingTonnage'));
  const accountedTonnage = parseFloat(get(props.contract, 'accountedTonnage'));
  const deliveredTonnage = parseFloat(get(props.contract, 'totalDeliveredTonnage'));
  const unplannedTonnage = totalTonnage - accountedTonnage > 0 ? totalTonnage - accountedTonnage : 0;
  let data = [
    {
      label: 'Total',
      value: isFloat(totalTonnage) ? totalTonnage.toFixed(2) : totalTonnage,
    },
    {
      label: 'Outstanding',
      value: outstandingTonnage > 0 ? (isFloat(outstandingTonnage) ? outstandingTonnage.toFixed(2) : outstandingTonnage) : 0,
    },
    {
      label: 'Delivered',
      value: isFloat(deliveredTonnage) ? deliveredTonnage.toFixed(2) : deliveredTonnage,
    },
    {
      label: 'Unplanned',
      value: isFloat(unplannedTonnage) ? unplannedTonnage.toFixed(2) : unplannedTonnage,
    },
    {
      label: 'In Progress',
      value: progressTonnage ? progressTonnage : 0
    },
    {
      label: 'Remaining',
      value: props.contract?.remainingTonnage ? parseFloat(props.contract.remainingTonnage).toFixed(2) : 0
    }
  ];
  if (has(props.contract, 'confirmedTonnage')) {
    const confirmedTonnage = parseFloat(get(props.contract, 'confirmedTonnage'));
    const invoicedTonnage = parseFloat(get(props.contract, 'invoicedTonnage'));
    data = [
      compact([
        data[0],
        data[2],
        data[4] ? data[4]: 0,
        showAllocatedTonnage ? {label: 'Allocated', value: parseFloat(get(props.contract, 'allocatedTonnageForUser')) || 0} : null
      ]),
      [
        data[1],
        data[3],
        {
          label: 'Booked',
          value: isFloat(confirmedTonnage) ? confirmedTonnage.toFixed(2) : confirmedTonnage,
        },
        {
          label: 'Invoiced',
          value: invoicedTonnage ? invoicedTonnage : 0
        },
        data[5]
      ],
    ];
  }
  if (!has(props.contract, 'confirmedTonnage') && has(props.contract, 'invoicedTonnage')) {
    const invoicedTonnage = parseFloat(get(props.contract, 'invoicedTonnage'));
    data = [
      compact([
        data[0],
        data[2],
        data[4] ? data[4]: 0,
        showAllocatedTonnage ? {label: 'Allocated', value: parseFloat(get(props.contract, 'allocatedTonnageForUser')) || 0} : null
      ]),
      [
        data[1],
        data[3],
        {
          label: 'Invoiced',
          value: invoicedTonnage ? invoicedTonnage : 0
        },
        data[5]
      ],
    ];
  }

  return (
    <React.Fragment>
      {isArray(data[0]) ? (
        map(data, (item, i) => (
          <li className='tonnage-distribution' key={i} style={{ justifyContent: 'center', width: '210px', paddingTop: '6px', paddingBottom: '6px' }}>
            <TonnageDetail unit={_unit} items={item} />
          </li>
        ))
      ) : (
        <li style={{ justifyContent: 'center', width: '210px', paddingTop: '6px', paddingBottom: '6px' }}>
          <TonnageDetail unit={_unit} items={data} />
        </li>
      )}
    </React.Fragment>
  );
};

export const ContractDetailsBasicInfo = props => {
  const { isMobileDevice } = React.useContext(AppContext)
  const { contract, onAmendClick } = props;
  const currentDate = moment().format('YYYY-MM-DD');
  const isDelayed =
        contract.deliveryEndDate && contract.deliveryEndDate < currentDate && includes(['planned', 'open', 'in_progress', 'confirmed'], contract.status);

  const otherNumberLabel = contract?.documentType?.name === 'contract' ? 'Sales Confirmation' : contract?.documentType?.displayName

  return (
    <Paper className='contract-status'>
      <ul className='contract-status-bar'>
        <li className='status'>
          {renderBasedOnStatus(contract.statusDisplayName)}
          <span className='field-value'>{contract.statusDisplayName}</span>
        </li>
        {
          contract.contractNumber &&
            <li>
              <span className='field-label' key='label'>
                Contract No
              </span>
              <span className='field-value' key='value'>
                {contract.contractNumber}
              </span>
              {
                !isMobileDevice &&
                  <span className='created-at' key='contract-date'>
                    Contract Date: {convertEpochToDateFormat(contract.contractDate)}
                  </span>
              }
            </li>
        }
        {
          (!contract.contractNumber || contract.documentType.name !== 'contract' || contract.contractNumber !== contract.identifier) &&
            <li>
              <span className='field-label' key='label'>
                {otherNumberLabel} No
              </span>
              <span className='field-value' key='value'>
                {contract.identifier}
              </span>
              {
                !isMobileDevice &&
                  <span className='created-at' key='created-at'>
                    Created: {convertEpochToDateFormat(contract.createdAt)}
                  </span>
              }
            </li>
        }

        <TonnageDetailWidget {...props} />

        {
          !isMobileDevice && props.currentUser.id === contract.createdById && contract.private &&
            <li>
              &nbsp;
              <span className='field-tag'>Private</span>
              &nbsp;
            </li>
        }
        {
          !isMobileDevice &&
            <li>
              {contract.isVoidRequestPending && !contract.voidable && <ChipLabel label='Void Request Sent' />}
              {contract.isAmendRequestPending && !contract.amendable && <ChipLabel label='Amend Request Sent' />}
              {contract.hasBeenAmended && <ChipLabel label='Amended' />}
              {isDelayed && <ChipLabel label='Delayed' />}
              {
                contract.sellerBrokerageInvoiced && !isEmpty(contract.brokeragesForUser) && find(contract.brokeragesForUser, { type: 'Seller' }) &&
                  <ChipLabel label='Seller Brokerage Invoiced' />
              }
              {
                contract.buyerBrokerageInvoiced && !isEmpty(contract.brokeragesForUser) && find(contract.brokeragesForUser, { type: 'Buyer' }) &&
                  <ChipLabel label='Buyer Brokerage Invoiced' />
              }
            </li>
        }
      </ul>
      <div className='status-actions' style={{paddingBottom: '15px'}}>
        <ContractDetailsActions {...props} onAmendClick={() => onAmendClick()} />
      </div>
    </Paper>
  );
};

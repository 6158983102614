import React from 'react';


import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import { POST_DELIVERY, BROKER } from '../../../common/constants';
import has from 'lodash/has';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class ContractDetailsBrokerageDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  getBrokerageDetailsSectionAsPerGrid = (payload) => {
    return (
      <div>
        <ul>
          {Object.keys(payload).map(function(key, index) {
            return (
              <li key={index}>
                <span className="field-label">{key}</span>
                <span className="fieldValue">
                    {payload[key] || '-'}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  render() {
    const { contract } = this.props;
    const { expanded } = this.state;
    let unit = contract?.commodity?.priceUnit
    const brokerages = contract.brokeragesForUser;
    let sellerPayload = null,buyerPayload = null,brokeredBy = null;
    const administration = get(contract,'administration');
    const brokeredByName = get(contract,'administration.brokeredBy.name');
    const brokerContact = get(contract,'administration.brokerContact.name');
    const isBrokerSalesConfirmation = isEqual(get(contract, 'createdBy.company.typeId'), BROKER) && isEqual(contract.documentType.name, 'sales_confirmation')
    if(administration && (brokeredByName || brokerContact)){
      brokeredBy = {
        'Broker Company': brokeredByName,
        'Brokered By': brokerContact,
      };
      if(isBrokerSalesConfirmation) {
        brokeredBy['Agent'] = brokeredBy['Broker Company'];
        brokeredBy['Agent Contact'] = brokeredBy['Brokered By'];
        delete brokeredBy['Broker Company'];
        delete brokeredBy['Brokered By'];
      }
    }
    if(!isEmpty(brokerages)) {
      brokerages.forEach(brokerage => {
        if(brokerage.type === 'Seller'){
          sellerPayload = {
            'Payable By' : contract.sellerBrokerageInvoiced ? brokerage.type + ' (INVOICED)' : brokerage.type,
            'Fee Type' : brokerage.feeType.replace('MT', unit),
            'Rate' : brokerage.rate && brokerage.rate.toFixed(2),
            'Total Brokerage Fee' : `${get(contract, 'currency')} ${brokerage.totalFee}`,
            'Charged At' : brokerage.chargedOn,
          };
          if (brokerage.chargedOn === POST_DELIVERY) { delete sellerPayload['Total Brokerage Fee']; }
          if(isBrokerSalesConfirmation && has(sellerPayload, 'Total Brokerage Fee')) {
            sellerPayload['Total Agent Fee'] = sellerPayload['Total Brokerage Fee'];
            delete sellerPayload['Total Brokerage Fee'];
          }
        }

        if(brokerage.type === 'Buyer'){
          buyerPayload = {
            'Payable By' : contract.buyerBrokerageInvoiced ? brokerage.type + ' (INVOICED)' : brokerage.type,
            'Fee Type' : brokerage.feeType.replace('MT', unit),
            'Rate' : brokerage.rate && brokerage.rate.toFixed(2),
            'Total Brokerage Fee' : `${get(contract, 'currency')} ${brokerage.totalFee}`,
            'Charged At' : brokerage.chargedOn,
          };
          if (brokerage.chargedOn === POST_DELIVERY) { delete buyerPayload['Total Brokerage Fee']; }
          if(isBrokerSalesConfirmation && has(buyerPayload, 'Total Brokerage Fee')) {
            buyerPayload['Total Agent Fee'] = buyerPayload['Total Brokerage Fee'];
            delete buyerPayload['Total Brokerage Fee'];
          }
        }
      });
    }
    return (
      (brokeredBy || !isEmpty(brokerages)) &&
      <Paper className="contract-details-section-container">
        <h2 onClick={this.toggleExpanded}>
            {isBrokerSalesConfirmation ? 'Agent Details' : 'Brokerage Details'}
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div className="section-details-container">
            {brokeredBy && this.getBrokerageDetailsSectionAsPerGrid(brokeredBy)}
            {sellerPayload && this.getBrokerageDetailsSectionAsPerGrid(sellerPayload)}
            {buyerPayload && this.getBrokerageDetailsSectionAsPerGrid(buyerPayload)}
          </div>
        )}
      </Paper>
    );
  }
}

export default ContractDetailsBrokerageDetails;

import React from "react";
import styled from "styled-components";
import { debounce } from "lodash";

import media from "./media";
import SpotlightContext from "./SpotlightContext";
import { getCountryLabel } from '../../../common/utils';


const Input = styled.input`
  margin: 0;
  padding: 0;
  height: 55px;
  color: #ffffff;
  font-size: 1.7em;
  font-weight: 100;
  padding-left: 55px;
  padding-right: 50px;
  box-sizing: border-box;
  border: none !important;
  outline: none !important;
  max-width: 100% !important;
  background-color: transparent;

  ${media.pc`
    padding: 0;
    float: left;
    box-sizing: content-box;
    width: 440px !important;
    border-right: 2px solid rgb(166,166,166) !important;
  `};

  &:-ms-input-placeholder {
    color: #a6a6a6;
  }
  &:-moz-placeholder {
    color: #a6a6a6;
  }
  &::-moz-placeholder {
    color: #a6a6a6;
  }
  &::-webkit-input-placeholder {
    color: #a6a6a6;
  }
`;

class SearchInput extends React.PureComponent {
  constructor(props) {
    super(props);
    this._performSearch = debounce(this._performSearch, 500);
    this.state = {placeholder: 'Search....'}
  }

  componentDidMount() {
    setInterval(() => {
      const newPlaceholder = this.getPlaceholder();
      if(this.state.placeholder != newPlaceholder)
        this.setState({placeholder: newPlaceholder})
    }, 50)
  }

  _performSearch = searchTerm => {
    this.props.keyUpFunction(searchTerm);
  };

  performSearch = event => {
    this._performSearch(event.target.value);
  };

  getPlaceholder() {
    const defaultLabel = 'Search...';
    const el = document.getElementById('searchTerm');
    if(!el)
      return defaultLabel;

    let searchTerm = el.value;

    if(!searchTerm)
      return defaultLabel;

    searchTerm = searchTerm.toLowerCase();
    switch(searchTerm) {
      case 'company':
        return 'Enter ABN or Company Name';
      case 'employee':
        return 'Enter Employee Name'
      case 'truck':
      return `Enter Truck ${getCountryLabel('rego')}`
      case 'ngr':
        return 'Enter NGR Number'
      case 'farm':
        return 'Enter Farm/Site Name'
      case 'contract':
        return 'Enter Contract Number'
      case 'order':
        return 'Enter Order Number'
      case 'movement':
        return 'Enter Movement Number'
      case 'invoice':
        return 'Enter Invoice Number'
      case 'vendor_dec':
        return 'Enter Vendor Declaration Number'
      default:
        return defaultLabel;
    }
    return defaultLabel;
  }

  render() {
    return (
      <Input
        autoComplete='off'
        id="spotlight-input"
        autoFocus={true}
        placeholder={this.state.placeholder}
        onKeyUp={this.performSearch}
        onKeyDown={this.props.keyDownFunction}
      />
    );
  }
}

export default () => (
  <SpotlightContext.Consumer>
    {({ handleKeyUp, handleKeyDown }) => (
      <SearchInput
        keyUpFunction={handleKeyUp}
        keyDownFunction={handleKeyDown}
      />
    )}
  </SpotlightContext.Consumer>
);

import React from "react";
import { connect } from "react-redux";
import UpdateOutload from "../../../containers/UpdateOutload";
import CreateOutload from "../../../containers/CreateOutload";
import UpdateInload from "../../../containers/UpdateInload";
import CreateInload from "../../../containers/CreateInload";
import get from "lodash/get";
import SideDrawer from "../../common/SideDrawer";
import {OUTLOAD, INLOAD, SPLIT_OUTLOAD, SPLIT_INLOAD } from "./Constants";
import {isSystemStorageOrBhc} from "../../../common/utils";
import {FREIGHT_CONTRACT_TYPE, OPTION_TYPE_WEB_SPLIT_LOADS } from "../../../common/constants";
import { includes, isEqual } from "lodash";

class InloadOutloadConstDrawer extends React.Component {
  constructor(props) {
    super(props);
  }

  getLoadConstOrSplit = (type, movement) => {
    if (type === OUTLOAD){
      if (get(movement.freightPickup, 'load')){
        const load = get(movement.freightPickup, 'load')[0]
        return !isEqual(get(load,  'status'), 'void') ? load : null
      }
    }
    else if (type === INLOAD){
      if (get(movement.freightDelivery, 'load')){
        const load = get(movement.freightDelivery, 'load')[0]
        return !isEqual(get(load,  'status'), 'void') ? load : null
      }
    }
    else if (type === SPLIT_OUTLOAD){
      if (get(movement.freightPickup, 'load')){
        const load = get(movement.freightPickup, 'load')[1]
        return !isEqual(get(load,  'status'), 'void') ? load : null
      }
    }
    else if (type === SPLIT_INLOAD){
      if (get(movement.freightDelivery, 'load')){
        const load = get(movement.freightDelivery, 'load')[1]
        return !isEqual(get(load,  'status'), 'void') ? load : null
      }
    }
    else
      return null;
  };


  getLoadNgrEstablishment = (type, loadConstOrSplit, movement) => {
    const pickupConst = movement.freightPickup;
    const deliveryConst = movement.freightDelivery;
    if (type === OUTLOAD)
      return this.getEstablishmentForFetchingNgr('freightPickup', pickupConst, loadConstOrSplit);
    else if (type === INLOAD)
      return this.getEstablishmentForFetchingNgr('freightDelivery', deliveryConst, loadConstOrSplit);
  };

  getEstablishmentForFetchingNgr(fetchFor, freightConst, loadConstOrSplit){
    let establishmentIdKey = null;
    let establishmentTypeKey = 'company';
    let isSystemStorage = false;
    const movementType = get(this.props, 'movement.typeId');
    if(fetchFor === 'freightPickup'){
      establishmentIdKey = 'movement.freightPickup.consignor.handler.companyId';
      establishmentTypeKey = 'company';
      isSystemStorage = isSystemStorageOrBhc(get(freightConst, 'consignor'), loadConstOrSplit);
      if(isSystemStorage){
        if(movementType === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY){
          if(get(this.props, 'movement.orderActualCustomer')){
            establishmentIdKey = 'movement.orderActualCustomer.company.id';
            establishmentTypeKey = 'company';
          } else {
            establishmentIdKey = 'movement.customer.companyId';
            establishmentTypeKey = 'company';
          }
        } else {
          establishmentIdKey = 'movement.commodityContract.seller.companyId';
          establishmentTypeKey = 'company';
        }
      }
    } else if (fetchFor === 'freightDelivery'){
      establishmentIdKey = 'movement.freightDelivery.consignee.handler.companyId';
      establishmentTypeKey = 'company';
      isSystemStorage = isSystemStorageOrBhc(get(freightConst, 'consignee'), loadConstOrSplit);
      if(isSystemStorage){
        if(movementType === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY){
          if(get(this.props, 'movement.orderActualCustomer')){
            establishmentIdKey = 'movement.orderActualCustomer.company.id';
            establishmentTypeKey = 'company';
          } else {
            establishmentIdKey = 'movement.customer.companyId';
            establishmentTypeKey = 'company';
          }
        } else {
          establishmentIdKey = 'movement.commodityContract.buyer.companyId';
          establishmentTypeKey = 'company';
        }
      }
    }

    if((!freightConst || !loadConstOrSplit) && !get(this.props, 'movement.commodityContract') && get(this.props.movement, 'customer.companyId')){
      establishmentIdKey = 'movement.customer.companyId';
      establishmentTypeKey = 'company';
    }


    if(establishmentIdKey && establishmentTypeKey){
      return {
        establishmentId: get(this.props, establishmentIdKey),
        establishmentType: establishmentTypeKey,
      };
    }
  }

  getLoadNgrId = (type, loadConstOrSplit) => {
    const loadNgrId = get(loadConstOrSplit, 'ngr.id');
    return loadNgrId ? loadNgrId : null;
  };

  closeSideDrawer = () => {
    this.props.onClose();
  };

  getPropsForUpdateLoad = (type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, movement) => {
    let updateLoadProps = {
      farmId: get(loadNgrEstablishment, 'establishmentType') === 'farm' ? get(loadNgrEstablishment, 'establishmentId') : null,
      companyId: get(loadNgrEstablishment, 'establishmentType') === 'company' ? get(loadNgrEstablishment, 'establishmentId') : null,
      ngrId: loadNgrId,
      movement: movement,
      closeDrawer: this.closeSideDrawer
    };
    if (includes([OUTLOAD, SPLIT_OUTLOAD], type))
      updateLoadProps['outload'] = loadConstOrSplit;
    else
      updateLoadProps['inload'] = loadConstOrSplit;
    return updateLoadProps;
  };

  getPropsForCreateLoad = (type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement) => {
    let createLoadProps = {
      farmId: get(loadNgrEstablishment, 'establishmentType') === 'farm' ? get(loadNgrEstablishment, 'establishmentId') : null,
      companyId: get(loadNgrEstablishment, 'establishmentType') === 'company' ? get(loadNgrEstablishment, 'establishmentId') : null,
      ngrId: loadNgrId,
      movement: movement,
      closeDrawer: this.closeSideDrawer,
      isCreate: true,
      flipLoad,
    };
    if (includes([SPLIT_OUTLOAD, SPLIT_INLOAD], type))
      createLoadProps['optionType'] = OPTION_TYPE_WEB_SPLIT_LOADS;
    return createLoadProps;
  };

  getTitle = (loadConstOrSplit, type) => {
    const identifier = get(this.props, 'identifier');
    const loadType = includes([OUTLOAD, SPLIT_OUTLOAD], type) ? 'Outload' : (this.props.movement?.isPackMovement ? 'Pack Details' : 'Inload');
    const subTitle = (loadConstOrSplit ? 'Edit ' : 'Add ') + loadType;
    if (identifier) {
      return  subTitle + ': ' + identifier;
    } else {
      return subTitle;
    }
  };

  getDrawerForOutLoad = (type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement) => {
    return (
      <React.Fragment>
        <SideDrawer
          isOpen={this.props.isOpen}
          title={this.getTitle(loadConstOrSplit, type)}
          size="big"
          onClose={this.closeSideDrawer}
          app="load"
        >
          {
            loadConstOrSplit ?
              <UpdateOutload
                {...this.getPropsForUpdateLoad(type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, movement)}
                isCreate={false}
              />
              :
              <CreateOutload
                {...this.getPropsForCreateLoad(type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement)}
                isCreate={true}
              />
          }
        </SideDrawer>
      </React.Fragment>
    );
  };

  getDrawerForInLoad = (type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement) => {
    return (
      <React.Fragment>
        <SideDrawer
          isOpen={this.props.isOpen}
          title={this.getTitle(loadConstOrSplit, type)}
          size="big"
          onClose={this.closeSideDrawer}
          app="load"
        >
         {
            loadConstOrSplit ?
            <UpdateInload
              {...this.getPropsForUpdateLoad(type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, movement)}
              isCreate={false}
            />
             :
            <CreateInload
              {...this.getPropsForCreateLoad(type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement)}
              isCreate={true}
            />
          }
        </SideDrawer>
      </React.Fragment>
    );
  };

  getFlipLoad = (type, movement) => {
    if (type === OUTLOAD)
      return get(movement, 'freightDelivery.load[0]');
    else if (type === INLOAD)
      return get(movement, 'freightPickup.load[0]');
    else if (type === SPLIT_OUTLOAD)
      return get(movement, 'freightDelivery.load[1]');
    else if (type === SPLIT_INLOAD)
      return get(movement, 'freightPickup.load[1]');
  };

  render() {

    const { movement, type, create } = this.props;
    const loadConstOrSplit = this.getLoadConstOrSplit(type, movement);
    const loadNgrEstablishment = this.getLoadNgrEstablishment(type, loadConstOrSplit, movement);
    const loadNgrId = this.getLoadNgrId(type, loadConstOrSplit);
    const flipLoad = this.getFlipLoad(type, movement);

    return(
      <div id='inload-outload-side-form'>
        {
          type === OUTLOAD &&
          this.getDrawerForOutLoad(type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement)
        }
        {
          type === INLOAD &&
          this.getDrawerForInLoad(type, loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement)
        }
        {
          type === SPLIT_OUTLOAD &&
          this.getDrawerForOutLoad(type, create ? null : loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement)
        }
        {
          type === SPLIT_INLOAD &&
          this.getDrawerForInLoad(type, create ? null : loadConstOrSplit, loadNgrEstablishment, loadNgrId, flipLoad, movement)
        }
      </div>
    );
  }

}

export default connect()(InloadOutloadConstDrawer);

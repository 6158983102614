/* eslint-disable */
import React, {Component} from 'react';
import './termsAndConditions.scss'

class WebTermsAndConditions extends Component {

    render() {
        return <div className="full-screen-container term-and-conditions">
            <div className="content-wrapper">
                <div className="content-box--header">
                    <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
                </div>
                <div className="content-body">
                    <div id="page">
                        <p className="p0 ft0">AGRICHAIN WEBSITE TERMS AND CONDITIONS OF USE</p>
                        <p className="p1 ft0"><span className="ft0">1.</span><span className="ft1">About the Website</span>
                        </p>
                        <p className="p2 ft5">
                            <span className="ft2">1.1.</span>
                            <span className="ft3">Welcome to <a href="https://agrichain.com/" target="_blank"
                                                                rel="noopener noreferrer">www.agrichain.com</a> (the '</span>
                            <span className="ft4">Website</span>'). The Website facilitates interactions between:</p>
                        <p className="p13 ft2"><span className="ft2">a.</span><span className="ft10">business to business buyers
                        (the '</span><span className="ft4">Receiver</span>') and</p>
                        <p className="p13 ft2"><span className="ft2">b.</span><span className="ft10">business to business
                        sellers (the '</span><span className="ft4">Provider</span>'),</p>
                        <p className="p2 ft3">
                    <span className="ft3">making it easier for the Receiver and the Provider to locate, communicate,
                        arrange payment and deliver the services in a fast and secure manner (the '</span><span
                            className="ft4">Services</span>').
                        </p>
                        <p className="p3 ft5"><span className="ft2">1.2.</span><span className="ft3">The Website is operated by
                        AgriChain PTY. LTD. (ABN 59 606 114 989). Access
                        to and use of the Website, or any of its associated products or Services, is
                        provided by AgriChain. Please read these terms and conditions (the '</span><span
                            className="ft6">Terms</span>') carefully. By using, browsing and/or reading the Website,
                            this signifies that you
                            have read, understood and agree to be bound by the Terms. If you do not agree
                            with the Terms, you must cease usage of the Website, or any of its products or
                            Services, immediately.</p>
                        <p className="p4 ft5"><span className="ft2">1.3.</span>
                            <span className="ft3">AgriChain reserves the right to review and change any of the Terms by updating
                        this page at its sole discretion. When AgriChain updates the Terms, it will use
                        reasonable endeavours to provide you with notice of updates of the Terms. Any
                        changes to the Terms take immediate effect from the date of their publication.
                        Before you continue, we recommend you keep a copy of the Terms for your
                        records.</span>
                        </p>
                        <p className="p5 ft0"><span className="ft0">2.</span><span className="ft1">Acceptance of the
                        Terms</span></p>
                        <p className="p6 ft5">You accept the Terms by registering for the Services and/or making any
                            payment as
                            required under the Terms for use of the Services. You may also accept the Terms by
                            clicking to accept or agree to the Terms where and if this option is made available to you
                            by AgriChain in the user interface.</p>
                        <p className="p1 ft0"><span className="ft0">3.</span><span className="ft1">The Services</span>
                        </p>
                        <p className="p7 ft5"><span className="ft2">3.1.</span><span className="ft3">In order to access the
                        Services, both the Receiver and the Provider are required to register for an account through the
                        Website (the '</span><span className="ft6">Account</span>').</p>
                        <p className="p12 ft5"><span className="ft2">3.2.</span><span className="ft3">As part of the
                        registration process, or as part of your continued use of the
                        Services, you may be required to provide personal information about yourself
                        (such as identification or contact details), including:</span>
                        </p>
                        <p className="p13 ft2"><span className="ft2">a.</span><span className="ft10">Email address</span></p>
                        <p className="p14 ft2"><span className="ft2">b.</span><span className="ft10">Preferred username</span>
                        </p>
                        <p className="p14 ft2"><span className="ft11">c.</span><span className="ft12">Mailing address</span></p>
                        <p className="p14 ft2"><span className="ft2">d.</span><span className="ft10">Telephone number</span></p>
                        <p className="p14 ft2"><span className="ft2">e.</span><span className="ft10">Password</span></p>
                        <p className="p14 ft2"><span className="ft2">f.</span><span className="ft13">Business Name</span></p>
                        <p className="p14 ft2"><span className="ft2">g.</span><span className="ft10">ABN</span></p>
                        <p className="p15 ft5"><span className="ft2">3.3.</span><span className="ft3">You warrant that any
                        information you give to AgriChain in the course of
                        completing the registration process will always be accurate, correct and up to
                        date.</span>
                        </p>
                        <p className="p16 ft5"><span className="ft2">3.4.</span><span className="ft3">Once you have completed
                        the registration process, you will be a registered
                        member of the Website ('</span><span className="ft6">Member</span>') and agree to be bound by
                            the Terms.</p>
                        <p className="p17 ft2"><span className="ft2">3.5.</span><span className="ft7">You may not use the
                        Services and may not accept the Terms if:</span>
                        </p>
                        <p className="p14 ft2"><span className="ft2">a.</span><span className="ft10">you are not of legal age to
                        form a binding contract with AgriChain; or</span>
                        </p>
                        <p className="p18 ft5"><span className="ft2">b.</span><span className="ft14">you are a person barred
                        from receiving the Services under the laws of
                        Australia or other countries including the country in which you are resident
                        or from which you use the Services.</span>
                        </p>
                        <p className="p1 ft0"><span className="ft0">4.</span><span className="ft1">Your obligations as a
                        Member</span></p>
                        <p className="p19 ft2"><span className="ft2">4.1.</span><span className="ft7">As a Member, you agree to
                        comply with the following:</span>
                        </p>
                        <p className="p14 ft2"><span className="ft2">a.</span><span className="ft10">you will not share your
                        profile with any other person;</span>
                        </p>
                        <p className="p14 ft2"><span className="ft2">b.</span><span className="ft10">you will use the Services
                        only for purposes that are permitted by:</span>
                        </p>
                        <p className="p20 ft2"><span className="ft2">(i)</span><span className="ft15">the Terms; and</span></p>
                        <p className="p20 ft2"><span className="ft2">(ii)</span><span className="ft15">any applicable law,
                        regulation or generally accepted practices or
                        guidelines in the relevant jurisdictions;</span></p>
                        <p className="p23 ft5"><span className="ft2">c.</span><span className="ft14">you have sole
                        responsibility for protecting the confidentiality of your
                        password and/or email address. Use of your password by any other person may result in the
                        immediate cancellation of the Services;</span>
                        </p>
                        <p className="p24 ft5"><span className="ft11">d.</span><span className="ft16">any use of your
                        registration information by any other person, or third
                        parties, is strictly prohibited. You agree to immediately notify AgriChain of
                        any unauthorised use of your password or email address or any breach of
                        security of which you have become aware;</span>
                        </p>
                        <p className="p25 ft5"><span className="ft2">e.</span><span className="ft14">you must not expressly or
                        impliedly impersonate another Member or use
                        the profile or password of another Member at any time;</span></p>
                        <p className="p26 ft5"><span className="ft2">f.</span><span className="ft14">any content that you
                        broadcast, publish, upload, transmit, post or
                        distribute on the Website ('</span><span className="ft6">Your Content</span>') will always be
                            accurate, correct
                            and up to date and you will maintain reasonable records of Your Content.
                        </p>
                        <p className="p27 ft5"><span className="ft2">g.</span><span className="ft17">you agree not to harass,
                        impersonate, stalk, threaten another Member of
                        the Website (where interaction with other Members is made available to
                        you);</span>
                        </p>
                        <p className="p28 ft5"><span className="ft2">h.</span><span className="ft14">access and use of the
                        Website is limited, non-transferable and allows for
                        the sole use of the Website by you for the purposes of providing the
                        Services;</span>
                        </p>
                        <p className="p29 ft9"><span className="ft2">i.</span><span className="ft18">you will not use the
                        Services or the Website in connection with any
                        commercial endeavours except those that are specifically endorsed or
                        approved by the management of AgriChain;</span>
                        </p>
                        <p className="p29 ft9"><span className="ft2">j.</span><span className="ft18">you will not use the
                        Services or Website for any illegal and/or
                        unauthorised use which includes collecting the personal or commercial
                        information of other Members, by electronic or other means, for the
                        purpose of commercial advantage or unsolicited contact, including but not
                        limited to sales and marketing activities;</span>
                        </p>
                        <p className="p29 ft9"><span className="ft2">k.</span><span className="ft18">you agree that commercial
                        advertisements, affiliate links and other forms
                        of solicitation may be removed from Member profiles without notice and
                        may result in termination of the Services. Appropriate legal action will be
                        taken by AgriChain for any illegal or unauthorised use of the Website; and</span>
                        </p>
                        <p className="p29 ft9"><span className="ft2">l.</span><span className="ft18">you acknowledge and agree
                        that any automated use of the Website or its
                        Services is prohibited.</span>
                        </p>
                        <p className="p30 ft0"><span className="ft0">5.</span><span className="ft1">Transactions Between
                        Providers and Receivers</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">5.1.</span><span className="ft19">Through the Website,
                        AgriChain provides an electronic online platform for
                        exchanging information and documentation between Providers and Receivers of
                        products and services. Members may use the Website to create, edit, manage,
                        cancel, accept, fulfill, void and complete contracts, orders and requests for the
                        provision of products and services. AgriChain does not represent any Member,
                        Provider or Receiver in specific transactions.</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">5.2.</span><span className="ft19">AgriChain does not
                        control and is not liable or responsible for the ability of the
                        Providers and Receivers to complete a transaction. AgriChain does not control
                        and is not liable or responsible for the availability, quality, safety or lawfulness of
                        the products or services offered by Members on the Website.</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">5.3.</span><span className="ft19">As a Member, you are
                        solely responsible for all transactions conducted through
                        or as a result of the Website, including adhering to any terms and conditions.
                        Terms and conditions may include but are not limited to, terms regarding
                        payment, returns, warranties, freighting, insurance, fees, taxes, titles, licences,
                        fines, permits, safety, handling, transportation and storage.</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">5.4.</span><span className="ft19">As a Member, you fully
                        acknowledge and assume the risks of conducting any
                        sale or purchase transactions when using the Website. As a Member, you fully
                        acknowledge and assume the risks relating to any liability or harm of any kind
                        connected to the subsequent activity of any kind relating to products or services
                        that are the subject of transactions using the Website. Such risks shall include,
                        but are not limited to, mis-representation of products and services, fraudulent
                        schemes, unsatisfactory service delivery, unsatisfactory quality, failure to meet
                        specifications, defective or dangerous products, unlawful products, loss or
                        damage of products, delay or default in delivery or payment, cost
                        mis-calculations, breach of warranty, breach of contract and transportation
                        accidents.</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">5.5.</span><span className="ft19">As a Member, you agree to
                        provide all information required by AgriChain in
                        relation to transactions conducted through or as a result of the Website. If any
                        Member fails to provide the required information, AgriChain holds the right to
                        suspend or terminate the Member’s account.</span>
                        </p>
                        <p className="p30 ft0"><span className="ft0">6.</span><span className="ft1">Payment</span></p>
                        <p className="p31 ft9"><span className="ft2">6.1.</span><span className="ft19">By AgriChain offering the
                        Services to you, you agree that:</span>
                        </p>
                        <p className="p13 ft2"><span className="ft2">a.</span><span className="ft10">
                        all payments made in the course of your use of the Services are made
                        using credit card or cryptocurrency;
                    </span></p>
                        <p className="p32 ft2"><span className="ft2">b.</span><span className="ft10">
                        you will pay all fees specified by AgriChain for Services;
                    </span></p>
                        <p className="p32 ft2"><span className="ft11">c.</span><span className="ft12">
                        you will provide AgriChain with valid and updated credit card information;</span>
                        </p>
                        <p className="p32 ft2"><span className="ft11">d.</span><span className="ft12">by providing credit card
                        information to AgriChain, you authorise AgriChain
                        to charge such credit card for all purchased Services; and</span>
                        </p>
                        <p className="p32 ft2"><span className="ft11">e.</span><span className="ft12">if an invoiced amount is
                        not received by the due date, then AgriChain
                        may, without limitation charge you accrued late interest up to the
                        maximum rate permitted by law.</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">6.2.</span><span className="ft19">In using the Website, the
                        Services or when making any payment in relation to
                        your use of the Services, you warrant that you have read, understood and agree
                        to be bound by the terms and conditions of the payment service.</span></p>
                        <p className="p30 ft0"><span className="ft0">7.</span><span className="ft1">Refund Policy</span>
                        </p>
                        <p className="p31 ft9"><span className="ft2">7.1.</span><span className="ft19">Since AgriChain is only a
                        facilitator in introducing the Receiver to the Provider
                        and providing a system to make safe payment, AgriChain does not hold any
                        liability to the Receiver directly and will not personally or otherwise refund them
                        any payments made in the use of Services</span></p>
                        <p className="p31 ft9"><span className="ft2">7.2.</span><span className="ft19">AgriChain will only
                        provide you with a refund of the Subscription Fee in the event
                        AgriChain are unable to continue to provide the Services or if the manager of
                        AgriChain makes a decision, at its absolute discretion, that it is reasonable to do
                        so under the circumstances. Where this occurs, the refund will be in the
                        proportional amount of the Subscription Fee that remains unused by the Member
                        (the '<span className="ft6">Refund</span>').</span></p>
                        <p className="p31 ft9"><span className="ft2">7.3.</span><span className="ft19">Both the Receiver and
                        Provider agree that they will comply with the Refund
                        Policy contained in this Clause of these Terms.</span></p>
                        <p className="p38 ft0"><span className="ft0">8.</span><span className="ft1">Copyright and Intellectual
                        Property Copyright and Intellectual Property</span>
                        </p>
                        <p className="p39 ft5"><span className="ft2">8.1.</span><span className="ft3">The Website, the Services
                        and all of the related products of AgriChain are
                        subject to copyright. The material on the Website is protected by copyright under
                        the laws of Australia and through international treaties. Unless otherwise
                        indicated, all rights (including copyright) in the Services and compilation of the
                        Website (including but not limited to text, graphics, logos, button icons, video
                        images, audio clips, Website, code, scripts, design elements and interactive
                        features) or the Services are owned or controlled for these purposes, and are
                        reserved by AgriChain or its contributors.</span>
                        </p>
                        <p className="p40 ft9"><span className="ft2">8.2.</span><span className="ft19">All trademarks, service
                        marks and trade names are owned, registered and/or
                        licensed by AgriChain, who grants to you a worldwide, non-exclusive, royaltyfree, revocable
                        license whilst you are a Member to:</span>
                        </p>

                        <p className="p14 ft2"><span className="ft2">a.</span><span className="ft10">use the Website pursuant to
                        the Terms;</span>
                        </p>
                        <p className="p43 ft9"><span className="ft2">b.</span><span className="ft18">copy and store the Website
                        and the material contained in the Website in
                        your device's cache memory; and</span>
                        </p>
                        <p className="p44 ft9"><span className="ft11">c.</span><span className="ft20">print pages from the
                        Website for your own personal and non-commercial
                        use. AgriChain does not grant you any other rights whatsoever in relation
                        to the Website or the Services. All other rights are expressly reserved by
                        AgriChain.</span></p>
                        <p className="p46 ft5"><span className="ft2">8.3.</span><span className="ft3">AgriChain retains all
                        rights, title and interest in and to the Website and all related
                        Services. Nothing you do on or in relation to the Website will transfer any:</span>
                        </p>
                        <p className="p47 ft9"><span className="ft2">a.</span><span className="ft18">business name, trading
                        name, domain name, trade mark, industrial
                        design, patent, registered design or copyright, or</span>
                        </p>
                        <p className="p48 ft9"><span className="ft2">b.</span><span className="ft18">a right to use or exploit a
                        business name, trading name, domain name,
                        trade mark or industrial design, or</span>
                        </p>
                        <p className="p49 ft5"><span className="ft11">c.</span><span className="ft16">a thing, system or process
                        that is the subject of a patent, registered
                        design or copyright (or an adaptation or modification of such a thing,
                        system or process), to you.</span>
                        </p>
                        <p className="p50 ft5"><span className="ft2">8.4.</span><span className="ft3">You may not, without the
                        prior written permission of AgriChain and the permission
                        of any other relevant rights owners: broadcast, republish, up-load to a third party,
                        transmit, post, distribute, show or play in public, adapt or change in any way the Services or
                        third party Services for any purpose, unless otherwise provided by
                        these Terms. This prohibition does not extend to materials on the Website, which
                        are freely available for re-use or are in the public domain.</span>
                        </p>
                        <p className="p50 ft5"><span className="ft2">8.5.</span><span className="ft3">Where you broadcast,
                        publish, upload, transmit, post or distribute Your Content
                        on the Website, then you grant to AgriChain a non-exclusive, transferable,
                        perpetual, royalty-free, irrevocable, worldwide licence to broadcast, republish,
                        up-load to a third party, transmit, post, distribute, show or play in public, adapt or
                        change Your Content.</span>
                        </p>
                        <p className="p51 ft0"><span className="ft0">9.</span><span className="ft1">Privacy</span></p>
                        <p className="p52 ft5">AgriChain takes your privacy seriously and any information provided
                            through your use of
                            the Website and/or Services are subject to AgriChain's Privacy Policy, which is available
                            on the Website.
                        </p>
                        <p className="p68 ft0"><span className="ft0">10.</span><span className="ft1">General Disclaimer</span>
                        </p>
                        <p className="p69 ft9"><span className="ft2">10.1.</span><span className="ft19">Nothing in the Terms
                        limits or excludes any guarantees, warranties,
                        representations or conditions implied or imposed by law, including the Australian
                        Consumer Law (or any liability under them) which by law may not be limited or
                        excluded.</span>
                        </p>
                        <p className="p76 ft2"><span className="ft2">10.2.</span><span className="ft7">Subject to this clause,
                        and to the extent permitted by law:</span>
                        </p>
                        <p className="p77 ft2"><span className="ft2">a.</span><span className="ft10">all terms, guarantees,
                        warranties, representations or conditions which are
                        not expressly stated in the Terms are excluded; and</span>
                        </p>
                        <p className="p78 ft9"><span className="ft2">b.</span><span className="ft18">AgriChain will not be
                        liable for any special, indirect or consequential loss
                        or damage (unless such loss or damage is reasonably foreseeable
                        resulting from our failure to meet an applicable Consumer Guarantee),
                        loss of profit or opportunity, or damage to goodwill arising out of or in
                        connection with the Services or these Terms (including as a result of not
                        being able to use the Services or the late supply of the Services), whether
                        at common law, under contract, tort (including negligence), in equity,
                        pursuant to statute or otherwise.</span>
                        </p>
                        <p className="p69 ft9"><span className="ft2">10.3.</span><span className="ft19">Use of the Website and
                        the Services is at your own risk. Everything on the Website and the Services is provided to you
                        "as is" and "as available" without
                        warranty or condition of any kind. None of the directors, officers, employees,
                        agents, affiliates, contributors and licensors of AgriChain make any express or
                        implied representation or warranty about the Services or any products or
                        Services (including the products or Services of AgriChain) referred to on the
                        Website. This includes (but is not restricted to) loss or damage you might suffer
                        as a result of any of the following:</span>
                        </p>
                        <p className="p78 ft9"><span className="ft2">a.</span><span className="ft18">failure of performance,
                        error, omission, interruption, deletion, defect,
                        failure to correct defects, delay in operation or transmission, computer
                        virus or other harmful component, loss of data, communication line failure,
                        unlawful third party conduct, or theft, destruction, alteration or
                        unauthorised access to records;</span>
                        </p>
                        <p className="p78 ft9"><span className="ft2">b.</span><span className="ft18">the accuracy, suitability
                        or currency of any information on the Website, the
                        Services, or any of its Services related products (including third party
                        material and advertisements on the Website);</span>
                        </p>
                        <p className="p78 ft9"><span className="ft2">c.</span><span className="ft18">costs incurred as a result
                        of you using the Website, the Services or any of
                        the products of AgriChain; and</span>
                        </p>
                        <p className="p78 ft9"><span className="ft2">d.</span><span className="ft18">the Services or operation
                        in respect to links which are provided for your
                        convenience.</span>
                        </p>
                        <p className="p69 ft9"><span className="ft2">10.4.</span><span className="ft19">You acknowledge that
                        AgriChain Website and the Services are only intended to
                        facilitate the interactions between the Receiver and the Provider and does not
                        offer any services other than the Services and AgriChain holds no liability to you
                        as a result of any conduct of the Members or the misuse of Your Content by any
                        party (including other Members).</span>
                        </p>
                        <p className="p5 ft0"><span className="ft0">11.</span><span className="ft1">Limitation of
                        liability</span></p>
                        <p className="p66 ft5"><span className="ft2">11.1.</span><span className="ft3">AgriChain's total
                        liability arising out of or in connection with the Services or these
                        Terms, however arising, including under contract, tort (including negligence), in
                        equity, under statute or otherwise, will not exceed the resupply of the Services to
                        you.</span>
                        </p>
                        <p className="p67 ft5"><span className="ft2">11.2.</span><span className="ft3">You expressly understand
                        and agree that AgriChain (including, but not limited to,
                        our directors, officers, employees, agents, affiliates, contributors and licensors) shall not be
                        liable to you for any direct, indirect, incidental, special consequential
                        or exemplary damages which may be incurred by you, however caused and
                        under any theory of liability. This shall include, but is not limited to, any loss of
                        profit (whether incurred directly or indirectly), any loss of goodwill or business
                        reputation and any other intangible loss.</span>
                        </p>
                        <p className="p67 ft5"><span className="ft2">11.3.</span><span className="ft3">You acknowledge and agree
                        that AgriChain (including, but not limited to, our
                        directors, officers, employees, agents, affiliates, contributors and licensors) holds
                        no liability for any direct, indirect, incidental, special consequential or exemplary
                        damages which may be incurred by you as a result of providing Your Content to
                        the Website</span>
                        </p>
                        <p className="p67 ft5"><span className="ft2">11.4.</span><span className="ft3">You acknowledge and agree
                        that the Website is provided “as is”, “as available”
                        and “with all faults”. AgriChain (including, but not limited to, our directors, officers,
                        employees, agents, affiliates, contributors and licensors) expressly disclaims all
                        warranties, express or implied. Warranties may include, but are not limited to,
                        warranties of quality, reliability, conditions, performance, accuracy, durability, or
                        fitness of purpose.</span>
                        </p>
                        <p className="p67 ft5"><span className="ft2">11.5.</span><span className="ft3">You acknowledge and agree
                        that AgriChain (including, but not limited to, our
                        directors, officers, employees, agents, affiliates, contributors and licensors)
                        makes no representations or warranties about the accuracy, validity, reliability,
                        quality or currentness or completeness of information or data provided through
                        the Website.</span>
                        </p>
                        <p className="p68 ft0"><span className="ft0">12.</span><span className="ft1">Termination of
                        Contract</span></p>
                        <p className="p69 ft9"><span className="ft2">12.1.</span><span className="ft19">If you want to terminate
                        the Terms, you may do so by providing AgriChain with 30
                        days' notice of your intention to terminate by sending notice of your intention to
                        terminate to AgriChain via the 'Contact Us' link on our homepage.</span>
                        </p>
                        <p className="p76 ft2"><span className="ft2">12.2.</span><span className="ft7">AgriChain may at any
                        time, terminate the Terms with you if:</span>
                        </p>
                        <p className="p77 ft2"><span className="ft2">a.</span><span className="ft10">you have breached any
                        provision of the Terms or intend to breach any
                        provision;</span></p>
                        <p className="p73 ft9"><span className="ft2">b.</span><span className="ft18">AgriChain is required to do
                        so by law;</span>
                        </p>
                        <p className="p74 ft9"><span className="ft11">c.</span><span className="ft20">AgriChain is transitioning
                        to no longer providing the Services to Members
                        in the country in which you are resident or from which you use the service; or</span>
                        </p>
                        <p className="p74 ft9"><span className="ft11">d.</span><span className="ft20">the provision of the
                        Services to you by AgriChain is, in the opinion of
                        AgriChain, no longer commercially viable.</span>
                        </p>
                        <p className="p81 ft5"><span className="ft2">12.3.</span><span className="ft3">Subject to local
                        applicable laws, AgriChain reserves the right to discontinue or
                        cancel your membership at any time and may suspend or deny, in its sole
                        discretion, your access to all or any portion of the Website or the Services without
                        notice if you breach any provision of the Terms or any applicable law or if your
                        conduct impacts AgriChain's name or reputation or violates the rights of those of
                        another party.</span>
                        </p>
                        <p className="p81 ft5"><span className="ft2">12.4.</span><span className="ft3">When the Terms come to an
                        end, all of the legal rights, obligations and liabilities
                        that you and AgriChain have benefited from, been subject to (or which have
                        accrued over time whilst the Terms have been in force) or which are expressed to
                        continue indefinitely, shall be unaffected by this cessation, and the provisions of
                        this clause shall continue to apply to such rights, obligations and liabilities
                        indefinitely.</span>
                        </p>
                        <p className="p38 ft0"><span className="ft0">13.</span><span className="ft1">Indemnity</span>
                        </p>
                        <p className="p82 ft9"><span className="ft2">13.1.</span><span className="ft19">You agree to indemnify
                        AgriChain, its directors, officers, employees, agents,
                        affiliates, contributors, licensors third party content providers from and against:</span>
                        </p>
                        <p className="p83 ft5"><span className="ft2">a.</span><span className="ft14">all actions, suits, claims,
                        demands, liabilities, costs, expenses, loss and
                        damage (including legal fees on a full indemnity basis) incurred, suffered
                        or arising out of or in connection with Your Content;</span>
                        </p>
                        <p className="p84 ft9"><span className="ft2">b.</span><span className="ft18">any direct or indirect
                        consequences of you accessing, using or transacting
                        on the Website or attempts to do so; and/or</span>
                        </p>
                        <p className="p84 ft9"><span className="ft2">c.</span><span className="ft18">any breach of the
                        Terms.</span>
                        </p>
                        <p className="p82 ft9"><span className="ft2">13.2.</span><span className="ft19">In the event of a
                        dispute between you and any other party to a transaction using
                        the Website, you agree to release and indemnify AgriChain (including, but not
                        limited to, our directors, officers, employees, agents, affiliates, contributors and
                        licensors) from all claims, demands, actions, proceedings, costs, expenses and
                        damages (including any actual, special, incidental or consequential damages)
                        resulting from any transaction.</span>
                        </p>
                        <p className="p1 ft0"><span className="ft0">14.</span><span className="ft1">Dispute Resolution</span>
                        </p>
                        <p className="p86 ft2"><span className="ft2">14.1.</span><span className="ft7">Compulsory:</span></p>
                        <p className="p87 ft5">If a dispute arises out of or relates to the Terms, either party may not
                            commence
                            any Tribunal or Court proceedings in relation to the dispute, unless the following
                            clauses have been complied with (except where urgent interlocutory relief is
                            sought).</p>
                        <p className="p88 ft2"><span className="ft2">14.2.</span><span className="ft7">Notice:</span>
                        </p>
                        <p className="p89 ft5">A party to the Terms claiming a dispute ('<span className="ft4">Dispute</span>')
                            has arisen under the
                            Terms, must give written notice to the other party detailing the nature of the
                            dispute, the desired outcome and the action required to settle the Dispute</p>
                        <p className="p90 ft2"><span className="ft2">14.3.</span><span className="ft7">Resolution:</span></p>
                        <p className="p91 ft9">On receipt of that notice ('<span className="ft6">Notice</span>’) by that
                            other party, the parties to the Terms ('<span className="ft6">Parties</span>’) must:</p>
                        <p className="p92 ft5"><span className="ft2">a.</span><span className="ft14">Within 90 days of the
                        Notice endeavour in good faith to resolve the
                        Dispute expeditiously by negotiation or such other means upon which
                        they may mutually agree;</span>
                        </p>
                        <p className="p93 ft5"><span className="ft2">b.</span><span className="ft14">If for any reason
                        whatsoever, 30 days days after the date of the Notice,
                        the Dispute has not been resolved, the Parties must either agree upon
                        selection of a mediator or request that an appropriate mediator be
                        appointed by the President of the Australian Mediation Association or his
                        or her nominee;</span>
                        </p>
                        <p className="p94 ft5"><span className="ft11">c.</span><span className="ft16">The Parties are equally
                        liable for the fees and reasonable expenses of a
                        mediator and the cost of the venue of the mediation and without limiting
                        the foregoing undertake to pay any amounts requested by the mediator
                        as a pre-condition to the mediation commencing. The Parties must each
                        pay their own costs associated with the mediation;</span>
                        </p>
                        <p className="p95 ft2"><span className="ft2">d.</span><span className="ft10">The mediation will be held
                        in Brisbane, Queensland, Australia.</span>
                        </p>
                        <p className="p86 ft2"><span className="ft2">14.4.</span><span className="ft7">Confidential</span></p>
                        <p className="p96 ft5">All communications concerning negotiations made by the Parties arising
                            out of
                            and in connection with this dispute resolution clause are confidential and to the
                            extent possible, must be treated as "without prejudice" negotiations for the purpose of
                            applicable laws of evidence.</p>

                        <p className="p99 ft2"><span className="ft2">14.5.</span><span className="ft7">Termination of
                        Mediation:</span></p>
                        <p className="p100 ft5">If 60 have elapsed after the start of a mediation of the Dispute and the
                            Dispute
                            has not been resolved, either Party may ask the mediator to terminate the
                            mediation and the mediator must do so.</p>
                        <p className="p1 ft0"><span className="ft0">15.</span><span className="ft1">Venue and
                        Jurisdiction</span></p>
                        <p className="p101 ft5">The Services offered by AgriChain is intended to be viewed by residents
                            of Australia. In
                            the event of any dispute arising out of or in relation to the Website, you agree that the
                            exclusive venue for resolving any dispute shall be in the courts of Queensland,
                            Australia.</p>
                        <p className="p1 ft0"><span className="ft0">16.</span><span className="ft1">Governing Law</span>
                        </p>
                        <p className="p102 ft5">The Terms are governed by the laws of Queensland, Australia. Any
                            dispute, controversy,
                            proceeding or claim of whatever nature arising out of or in any way relating to the Terms
                            and the rights created hereby shall be governed, interpreted and construed by, under
                            and pursuant to the laws of Queensland, Australia, without reference to conflict of law
                            principles, notwithstanding mandatory rules. The validity of this governing law clause is
                            not contested. The Terms shall be binding to the benefit of the parties hereto and their
                            successors and assigns.</p>
                        <p className="p68 ft0"><span className="ft0">17.</span><span className="ft1">Independent Legal
                        Advice</span></p>
                        <p className="p103 ft5">Both parties confirm and declare that the provisions of the Terms are
                            fair and reasonable
                            and both parties having taken the opportunity to obtain independent legal advice and
                            declare the Terms are not against public policy on the grounds of inequality or bargaining
                            power or general grounds of restraint of trade.</p>
                        <p className="p104 ft0"><span className="ft0">18.</span><span className="ft1">Severance</span>
                        </p>
                        <p className="p105 ft9">If any part of these Terms is found to be void or unenforceable by a
                            Court of competent
                            jurisdiction, that part shall be severed and the rest of the Terms shall remain in
                            force.</p>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default WebTermsAndConditions;

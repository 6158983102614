/*eslint no-process-env: 0*/

import React from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import UserIcon from '@mui/icons-material/PermIdentity';
import LockIcon from '@mui/icons-material/LockOutlined';
import PasswordIcon from '@mui/icons-material/Key';
import {TextField} from '@mui/material';
import { login, receiveToggles, receiveLogin, forceStopLoader } from '../../actions/main';
import { connect } from 'react-redux';
import './login.scss';
import { Redirect } from 'react-router-dom';
import CommonButton from '../common/CommonButton';
import last from 'lodash/last';
import includes from 'lodash/includes';
import map from 'lodash/map';
import get from 'lodash/get';
import url from 'url';
import APIService from "../../services/APIService";
import isEmpty from "lodash/isEmpty";
import forOwn from "lodash/forOwn";
import has from "lodash/has";
import { isChromeBrowser, isOldChromeVersion, deleteAllCookies, getHome } from '../../common/utils';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import Button from '@mui/material/Button';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import IconButton from '@mui/material/IconButton';
import { getAuthProvider } from '../../services/AzureAuthProvider';
import { AzureAD, AuthenticationState } from 'react-aad-msal';

class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    var referrer = null;
    var returnTo = null;

    if (document.location.hash.match('referrerUrl=/')) {
      referrer = last(document.location.hash.split('referrerUrl='));
    }

    var query = url.parse(document.location.href, true).query;
    if (query['return_to']) {
      returnTo = encodeURIComponent(query['return_to']);
    }

    this.state = {
      browserAlert: false,
      updateChromePopup: false,
      referrerUrl: referrer,
      returnTo: returnTo,
      formData: {
        username: '',
        password: '',
      },
      error: '',
      submitted: false,
      /*eslint no-undef: 0*/
      isAdminApp: process.env.IS_ADMIN_APP || false,
      systemAdmins: undefined,
      showPassword: false,
      ssoSettings: null,
      showPasswordField: false,
      authProvider: null,
      isUsernameChanged: true,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeBrowserAlert = this.closeBrowserAlert.bind(this);
    this.closeUpdateChromePopup= this.closeUpdateChromePopup.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.usernameRef = React.createRef();
    this.passwordRef = React.createRef();
  }

  componentDidMount() {
    this.fetchToggles();
    this.fetchCountries()
    this.setBrowserAlertIfNotChrome();
    this.setBrowserAlertIfOlderChromeVersion();
  }

  setBrowserAlertIfOlderChromeVersion() {
    if (isOldChromeVersion()) {
      this.setState({ updateChromePopup: true});
    }
  }

  setBrowserAlertIfNotChrome() {
    if (!isChromeBrowser())
      this.setState({ browserAlert: true });
  }

  closeUpdateChromePopup() {
    this.setState({ updateChromePopup: false});
  }

  closeBrowserAlert() {
    this.setState({ browserAlert: false });
  }

  fetchToggles() {
    APIService.toggles().get().then((json) => {
      if (!isEmpty(json)) {
        forOwn(json, (value, key) => {
          if (!has(window, key) || window[key] !== value) {
            window[key] = value;
          }
        });
      }
      this.props.dispatch(receiveToggles(json));
    });
  }

  fetchCountries() {
    APIService.countries().get().then((json) => {
      localStorage.setItem('countries', JSON.stringify(json))
    });
  }

  resetExceptUsername = () => {
    this.setState({
      error: '',
      ssoSettings: null,
      showPasswordField: false,
      authProvider: null,
      isUsernameChanged: false,
      formData: {
        ...this.state.formData,
        password: ''
      }
    })
  }

  resetSSOCache = () => {
    localStorage.clear()
    deleteAllCookies()
  }

  componentDidUpdate() {
    if(this.props.loading)
      this.props.dispatch(forceStopLoader())
    const newState = { ...this.state };
    if (!newState.systemAdmins && this.props.systemAdmins) {
      newState.systemAdmins = this.props.systemAdmins;
      this.setState(newState);
    }
  }

  handleChange(event) {
    const newState = {...this.state};
    if(event.target.name === 'username') {
      newState.isUsernameChanged = event.target.value !== this.state.formData.username
    }
    newState.formData[event.target.name] = event.target.value;
    this.setState(newState);
  }

  getReferrerURL() {
    return this.state.referrerUrl || getHome();
  }

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = this.props;
    if (this.isFormValid())
      login(
        {...this.state.formData},
        this.getReferrerURL(),
        this.state.returnTo,
        dispatch,
        null,
        error => this.setState({error: error.alert})
      );
  }

  handleNext = event => {
    event.preventDefault()
    event.stopPropagation()
    if(this.state.formData.username) {
      if(this.state.isUsernameChanged)
        this.resetSSOCache()
      APIService.profiles().appendToUrl('sso-setting/web/').post({username: this.state.formData.username}).then(response => {
        if(!response)
          this.setState({showPasswordField: true})
        else if(get(response, 'id'))
          this.setState({ssoSettings: response, showPasswordField: false, authProvider: getAuthProvider(response.extras)})
      })
    }
  }

  isFormValid = () => {
    return (this.state.isAdminApp && includes(map(this.state.systemAdmins, email => { return email.toLowerCase(); }), this.state.formData.username)) ||
           (!this.state.isAdminApp && !includes(map(this.state.systemAdmins, email => { return email.toLowerCase(); }), this.state.formData.username));
  };

  setError = (error) => {
    this.setState({ error });
  };

  handleClickShowPassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  makeHandshake = (event, account) => {
    event.preventDefault();
    event.stopPropagation();
    if(account) {
      let firstName = account.account.givenname || account.account.idTokenClaims.givenname || account.account.idTokenClaims.given_name;
      let lastName = account.account.surname || account.account.idTokenClaims.surname || account.account.idTokenClaims.family_name || account.account.idTokenClaims.familyname;
      if(firstName && !lastName)
        lastName = ' ';
      if(lastName && !firstName) {
        firstName = lastName;
        lastName = ' ';
      }
      if (!firstName && !lastName) {
        const names = account.account.name.split(' ');
        firstName = names.shift();
        lastName = names.join(' ');
      }
      const username = account.account.userName || account.account.idTokenClaims.preferred_username;
      const user = {typeId: 2, username: username, jwtIdToken: account.jwtIdToken, email: account.account.userName, lastName: lastName, firstName: firstName};
      APIService.companies(this.state.ssoSettings.companyId).appendToUrl('sso/employees/upsert/').post(user).then(response => {
        if(get(response, 'token')) {
          //success
          this.props.dispatch(receiveLogin(response));
          localStorage.setItem('token', response.token);
          localStorage.setItem('user', JSON.stringify(response.user));
          window.location.reload();
          //document.location.hash = '/';
        } else {
          //error -- do something
        }
      });
    }
  }

  render() {
    const { formData, submitted, showPasswordField, ssoSettings, authProvider } = this.state;
    return (
      <div className="login-wrapper">
        <div className='row'>
          <div className="login-container">
            <div className="login-content-box">
              <div className="login-content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
              </div>
              <div className="login-content-box--content">
                <h2>Login</h2>
                <p>Enter your details below to continue.</p>
                <form
                  onSubmit={showPasswordField ? this.handleSubmit : this.handleNext}
                >
                  {
                    this.props.user.token && localStorage.getItem("token") && !ssoSettings ?
                    <Redirect to={this.getReferrerURL()} /> :
                    <div className="error-message" style={{marginBottom: '15px'}}>
                      {this.state.error}
                    </div>
                  }
                  <div className="cardForm cardForm--drawer" style={{marginBottom: '0px'}}>
                    <div className="cardForm-content row">
                      <div className="col-sm-12 form-wrap text-black">
                        <TextField
                          label="Email/Username"
                          placeholder="Email/Username"
                          fullWidth
                          onChange={this.handleChange}
                          ref={this.usernameRef}
                          name="username"
                          value={formData.username}
                          required
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <UserIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (showPasswordField || ssoSettings) ? (
                              <InputAdornment position="end">
                                <LockIcon />
                              </InputAdornment>
                            ) : null,
                          }}
                          disabled={Boolean(showPasswordField || ssoSettings)}
                          autoFocus={!showPasswordField}
                        />
                      </div>
                      {
                        showPasswordField &&
                        <div className="col-sm-12 form-wrap">
                          <TextField
                            label="Password"
                            placeholder="Password"
                            ref={this.passwordRef}
                            fullWidth
                            onChange={this.handleChange}
                            name="password"
                            type={this.state.showPassword ? 'text' : 'password'}
                            value={formData.password}
                            required
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <PasswordIcon />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="Toggle password visibility"
                                  onClick={this.handleClickShowPassword}
                                                size="large">
                                    {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            autoFocus={showPasswordField}
                          />
                          <div style={{ 'textAlign': 'right' }}>
                            <CommonButton
                              label="Forgot Password"
                              primary
                              variant='text'
                              href="#/forgot-password"
                              style={{ fontSize: '12px', fontWeight: '400', padding: '0', display: 'initial', textTransform: 'none', margin: '0' }}
                            />
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                    {
                      (showPasswordField || ssoSettings) &&
                      <CommonButton
                        label='Sign in as different User'
                        variant="text"
                        disabled={submitted}
                        primary
                        className="login-button"
                        onClick={this.resetExceptUsername}
                        style={{textTransform: 'none'}}
                        size='small'
                      />
                    }
                    {
                      ssoSettings ?
                      <AzureAD provider={authProvider}>
                        {
                          ({login, logout, authenticationState, error, accountInfo}) => {
                            switch (authenticationState) {
                              case AuthenticationState.Authenticated:
                                return (
                                  <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                                    <CommonButton
                                      label='Enter AgriChain'
                                      variant='contained'
                                      primary
                                      className='login-button'
                                      onClick={event => this.makeHandshake(event, accountInfo)}
                                    />
                                    <CommonButton
                                      label="Logout"
                                      variant="contained"
                                      secondary
                                      className="login-button"
                                      onClick={logout}
                                    />
                                  </div>
                                );
                              case AuthenticationState.Unauthenticated:
                                return (
                                  <div>
                                    {
                                      error &&
                                      <p style={{textAlign: 'center'}}>
                                        <span style={{color: 'red'}}>An error occurred during authentication, please try again!</span>
                                      </p>
                                    }
                                    <React.Fragment>
                                      <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                                        <CommonButton
                                          label="Login Via SSO"
                                          variant="contained"
                                          disabled={submitted}
                                          primary
                                          className="login-button"
                                          onClick={login}
                                        />
                                      </div>
                                    </React.Fragment>
                                  </div>
                                );
                              case AuthenticationState.InProgress:
                                return (
                                  <React.Fragment>
                                    <div className="col-12" style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
                                      <CommonButton
                                        label="Authenticating..."
                                        variant="contained"
                                        className="login-button"
                                        disabled
                                      />
                                    </div>
                                  </React.Fragment>
                                );
                            }
                          }
                        }
                      </AzureAD> :
                      <CommonButton
                        type="submit"
                        label={showPasswordField ? "Login" : "Next"}
                        variant="contained"
                        disabled={submitted}
                        primary
                        className="login-button"
                      />
                    }
                    <div>
                      <span style={{ 'fontSize': '0.8em' }}>New User? <a href="#/sign-up">Sign Up</a></span>
                    </div>
                  </div>
                </form>
                <span style={{ fontSize: '0.7em' }}>By continuing you agree to these <a href="#/web/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a> and <a href="https://www.iubenda.com/privacy-policy/38052771" title="Privacy Policy" target='_blank' rel="noopener noreferrer">Privacy Policy</a></span>
              </div>
            </div>
          </div>
          <div className='cashboard-container' style={{ 'textAlign': 'center', 'marginBottom': '12px' }}>
            <Button style={{ width: '100%' }} onClick={() => { window.location.hash = '/cash-board'; }} color="primary"> VIEW CASHBOARDS </Button>
          </div>
        </div>
        <Dialog open={this.state.browserAlert} onClose={this.closeBrowserAlert} aria-labelledby="form-dialog-title" style={{ zIndex: '2000' }}>
          <DialogTitleWithCloseIcon>Important!</DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '10px' }}>
            AgriChain platform works best on Google Chrome. We recommend you to download the same by clicking on this <a rel="noopener noreferrer" href="https://www.google.com/chrome/" target="_blank">link</a>.
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.closeBrowserAlert}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.updateChromePopup} onClose={this.closeUpdateChromePopup} aria-labelledby="form-dialog-title" style={{ zIndex: '2000' }}>
          <DialogTitleWithCloseIcon>Important!</DialogTitleWithCloseIcon>
          <DialogContent style={{ marginTop: '10px' }}>
            Your Chrome version is very old, please download the latest version to have the best experience of AgriChain by clicking on this <a rel="noopener noreferrer" href="https://www.google.com/chrome/update/" target="_blank">link</a>.
          </DialogContent>
          <DialogActions>
            <Button variant="contained" color="primary" onClick={this.closeUpdateChromePopup}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const user = state.main.user || '';
  const systemAdmins = [];
  return {
    user,
    systemAdmins,
    loading:  state.main.isLoading
  };
};
export default connect(mapStateToProps)(LoginForm);

import React from 'react';
import { connect } from 'react-redux';
import NgrsTable from '../../containers/CompanyNgrsTable';
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import UpdateCompanyNgr from '../../containers/UpdateCompanyNgr';
import { getCompanyNgrs } from '../../actions/api/company-ngrs';
import { cancelEditCompanyNgr } from '../../actions/companies/company-ngrs';
import { receiveCompanyNgrs, clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { getCompanyDetails } from '../../actions/companies/index';

import AddButton from '../common/AddButton';
import Paper from '@mui/material/Paper';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { isCompanyEditable, isCompanyNgrsEditable, isCurrentUserBroker, isSystemCompany, isTransactionParticipated, alertForNGRBasedOnRegistration} from '../../common/utils';
import SideDrawer from '../common/SideDrawer';
import {map, get, isEmpty, filter, pick, isEqual, cloneDeep, find} from 'lodash';
import { getCompanyFarms } from '../../actions/api/farms';
import TransferToOtherCompany from '../../containers/TransferToOtherCompany';
import { showSideDrawerForTransfer, showSideDrawerForMerge } from '../../actions/companies/index';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import CompanyNgrPortalDialog from './CompanyNgrPortalDialog';
import { Button } from '@mui/material';

class CompanyNgrs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openCreateSideDrawer: false,
      openSideDrawer: false,
      openMergeSideDrawer: false,
      mergeToNgr: {
        value: undefined,
        validators: [],
        errors: []
      },
      farms: [],
      isNgrPortalDialogOpen: false,
    };
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleAddCompanyNgrButtonClick = this.handleAddCompanyNgrButtonClick.bind(this);
    this.onCloseSideDraw = this.onCloseSideDraw.bind(this);
    this.handleNgrChange = this.handleNgrChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleNgrPortalDetailsDialog = this.toggleNgrPortalDetailsDialog.bind(this);
    this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
  }

  componentDidMount() {
    this.setBreadcrumbs();
    this.props.getNgrs(this.props.companyId);
    this.props.cancelMergeDrawer;
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
    }
    if (isCurrentUserBroker() && isEmpty(this.state.farms)) {
      this.props.getCompanyFarms(
        this.props.companyId,
        null,
        { include_unaccepted: true, broker_company_id: this.props.currentUserCompanyId },
        this.getFarms,
      );
    }
  }

  componentDidUpdate() {
    if (this.props.selectedCompany) {
      if (this.props.headerText !== this.props.selectedCompany.name) {
        this.props.setHeaderText(this.props.selectedCompany.name);
      }
      this.setBreadcrumbs();
    }
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
      this.props.getNgrs(this.props.companyId);
    }
  }

  openSideDraw(){
    this.props.handleAddOfficeButtonClick();
    this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
    this.setState({ openSideDrawer: false, });
  }

  getFarms = items => {
    let farms = map(
      filter(items, i => i.isManagedByUser),
      item => pick(item, ['id', 'name']),
    );
    if (!isEmpty(farms)) {
      farms = [{ id: '', name: null }, ...farms];
    }
    this.setState({ farms });
  };

  shouldFetchCompany() {
    return !this.props.selectedCompany || this.props.selectedCompany.id !== this.props.companyId;
  }

  setBreadcrumbs() {
    if (this.props.selectedCompany) {
      const breadcrumbs = [
        { text: 'Companies', route: '/companies' },
        {
          text: this.props.selectedCompany.name,
          onClick: this.props.onDetailsClick,
          route: '/companies/' + this.props.selectedCompany.id + '/details',
        },
        { text: `NGRs (${this.props.count})` },
      ];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  handleAddCompanyNgrButtonClick() {
    if (isCompanyNgrsEditable(this.props.companyId, this.props.selectedCompany))
    {
      this.props.handleAddCompanyNgrButtonClick();
      this.setState({
        openCreateSideDrawer: true,
      });
    } else {
      alertForNGRBasedOnRegistration(this.props.selectedCompany)
    }
  }

  toggleNgrPortalDetailsDialog() {
    this.setState({ isNgrPortalDialogOpen: !this.state.isNgrPortalDialogOpen })
  }

  onCloseSideDraw() {
    this.setState({
      openCreateSideDrawer: false,
    });
  }

  canAccessAny() {
    return !isTransactionParticipated(this.props.selectedCompany) || isCompanyEditable(this.props.selectedCompany);
  }
  componentWillUnmount() {
    this.props.cancelTransferDrawer(null, false);
    this.props.cancelMergeDrawer(null, false);
  };

  getNgrs = () => {
    let ngrs = cloneDeep(this.props.ngrs);
    ngrs.splice(ngrs.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
    return ngrs;
  }

  getLabel = () => {
    let fromNgrNumber = get(this.props, 'selectedAsset.ngrNumber');
    let toNgrNumber = "";
    if (this.state.mergeToNgr.value) {
      toNgrNumber = get(find(this.props.ngrs, {id: this.state.mergeToNgr.value}), 'ngrNumber');
    }
    return "Merge " + fromNgrNumber + " into " + toNgrNumber;
  }

  closeMergeDrawer(){
    const newState = {...this.state};
    newState.mergeToNgr.value = undefined;
    this.setState(newState);
    this.props.cancelMergeDrawer();
  }

  handleNgrChange(value) {
    const newState = {...this.state};
    newState.mergeToNgr.value = value;
    newState.mergeToNgr.errors = [];
    this.setState(newState);
  }

  handleSubmit() {
    let data = {};
    data['mergeFrom'] = get(this.props.selectedAsset, 'id');
    if (data['mergeFrom']) {
      APIService.ngrs()
        .appendToUrl(`${this.state.mergeToNgr.value}/merge/`)
        .post(data)
        .then(res => {
          if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
          else alertifyjs.success('Ngr Merged!', 3, () => window.location.reload())
        });
    }
  }

  render() {
    return (
      <div>
      <Paper className='paper-table'>
        <div>
        {(this.canAccessAny() && isCompanyNgrsEditable(this.props.companyId, this.props.selectedCompany)) ? (
            <AddButton label='NGR' onClick={this.handleAddCompanyNgrButtonClick} app='companyNGR' />
          ) : null}
          {
            isSystemCompany() ? get(this.props.ngrPortalDetails, 'isPortalLinked') ?
                <Button
                  type="button"
                  variant="contained"
                  style = {{marginRight: '10px', float: 'right', marginTop: '0px', marginBottom: '0px'}}
                  color="primary"
                  onClick={this.toggleNgrPortalDetailsDialog}>
                  View NGR Linking
                </Button> :
                <AddButton
                  label='Link NGR Portal'
                  style = {{marginRight: '10px'}}
                  onClick={this.toggleNgrPortalDetailsDialog} /> : null
          }
          <NgrsTable />
        </div>
        {this.props.isCreateFormDisplayed && (
          <SideDrawer isOpen={this.state.openCreateSideDrawer} title='Add NGR' size='big' onClose={this.onCloseSideDraw}>
            <CreateCompanyNgr
              canAccessAny={this.canAccessAny()}
              closeDrawer={this.onCloseSideDraw}
              companyId={this.props.companyId}
              farms={this.state.farms}
              ngrPortalDetails={this.props.ngrPortalDetails}
              company={this.props.company}
            />
          </SideDrawer>
        )}
        {
            <SideDrawer
              isOpen={ this.props.openSideDrawer }
              title="Transfer"
              onClose={this.props.cancelTransferDrawer}
              app='office'
              >
             <TransferToOtherCompany canAccessAny={this.canAccessAny()}></TransferToOtherCompany>
            </SideDrawer>
        }
        {(this.canAccessAny() &&  isCompanyNgrsEditable(this.props.companyId, this.props.selectedCompany))
        ? (
            <SideDrawer isOpen={this.props.isUpdateFormDisplayed} title='Edit NGR' size='big' onClose={this.props.cancelEditNgr}>
            <UpdateCompanyNgr
              canAccessAny={this.canAccessAny()}
              companyId={this.props.companyId}
              farms={this.state.farms}
              ngrPortalDetails={this.props.ngrPortalDetails}
              company={this.props.company}
              isEdit={true}
            />
          </SideDrawer>
        ) : null}
        {
            (this.canAccessAny() && this.props.canEdit) ?
            <SideDrawer
              isOpen={ this.props.openSideDrawer }
              title="Transfer"
              onClose={this.props.cancelTransferDrawer}
              app='office'
              >
             <TransferToOtherCompany canAccessAny={this.canAccessAny()} includeParties={true} />
            </SideDrawer>
            : null
          }
          {this.props.openMergeSideDrawer &&
            <SideDrawer
              isOpen={this.props.openMergeSideDrawer}
              title="Merge"
              onClose={this.closeMergeDrawer}
            >
              <div style={{ paddingTop: "15px"}}>
                <CommonAutoSelect
                  id='mergeToNgr'
                  items={this.getNgrs()}
                  label={this.getLabel()}
                  value={this.state.mergeToNgr.value}
                  dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                  onChange={this.handleNgrChange}
                />
              </div>
              {
              <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
                <CommonButton
                  type="button"
                  label="Cancel"
                  default
                  onClick={this.closeMergeDrawer}
                  variant="contained"
                />
                <CommonButton
                  type="submit"
                  label="Merge"
                  primary
                  onClick={this.handleSubmit}
                  variant="contained"
                />
              </div>
              }
            </SideDrawer>
          }
      </Paper>
      {
        this.state.isNgrPortalDialogOpen ? (
          <CompanyNgrPortalDialog
            handlePortalCredentials={this.props.handlePortalCredentials}
            onClose={this.toggleNgrPortalDetailsDialog}
            companyId={this.props.companyId}
            ngrPortalDetails={this.props.ngrPortalDetails}
          />
        ): null
      }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    openSideDrawer: state.companies.companies.showTransferSideDrawer,
    openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
    isCreateFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    currentUserCompanyId: state.main.user.user.companyId,
    selectedCompany: state.companies.companies.selectedCompany,
    isUpdateFormDisplayed: state.companies.companyNgrs.isUpdateFormDisplayed,
    breadcrumbs: state.main.breadcrumbs,
    ngrs: state.companies.companyNgrs.items,
    count: get(state.companies.companyNgrs, 'items.length', 0),
    selectedAsset: state.companies.companies.selectedAsset
  };
};

const mapDispatchToProps = dispatch => {
  return {
    cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
    cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
    getNgrs: companyId => dispatch(getNgrs(companyId)),
    getCompany: companyId => dispatch(getCompanyDetails(companyId)),
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    setHeaderText: text => dispatch(setHeaderText(text)),
    setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditNgr: () => dispatch(cancelEditCompanyNgr()),
    getCompanyFarms: (companyId, action, query, callback) => dispatch(getCompanyFarms(companyId, action, query, callback)),
  };
};

const getNgrs = companyId => {
  return dispatch => {
    dispatch(getCompanyNgrs(companyId, receiveCompanyNgrs));
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanyNgrs);

import React from 'react';
import { connect } from 'react-redux';
import ContractBidsTable from '../../containers/ContractBidsTable';
import Paper from '@mui/material/Paper';
import SideDrawer from '../common/SideDrawer';
import AddContractBid from './AddContractBid';
import {  setSelectedContractBid, contractBidsResponse, 
    getCompanyContractBids, duplicateSelectedContractBid
} from '../../actions/companies/contract-bids';
import { isLoading, forceStopLoader } from '../../actions/main';

class ArchivedContractBids extends React.Component {

    constructor(props) {
        super(props);
        
        this.state = {
            openCloseAddContractBidDialog: false,
        };
        this.openSideDraw = this.openSideDraw.bind(this);
    }

    componentDidMount() {
        this.props.getCompanyContractBids(true);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isDuplicateSelectedContractBid !== this.props.isDuplicateSelectedContractBid)
            this.setState({ openCloseAddContractBidDialog: this.props.isDuplicateSelectedContractBid });
    }

    openSideDraw() {
        this.setState({ openCloseAddContractBidDialog: !this.state.openCloseAddContractBidDialog });
        this.props.duplicateSelectedContractBid(false);
        this.props.setSelectedContractBid([]);
    }

    componentWillUnmount() {
        this.props.setContractBidsResponse([]);
    }

    getSelectedCommodity = () => {
        const id = this.props.selectedContractBid?.commodity;
        return id ? find(this.props.commodities, {id: id}) : null
    }

    render() {
        return (
            <div>
            <Paper className='paper-table-paginated'>
                <ContractBidsTable {...this.props} tab='archived'/>

                {this.state.openCloseAddContractBidDialog &&
                    <SideDrawer
                        isOpen={this.state.openCloseAddContractBidDialog}
                        title='Add Contract Bid'
                        onClose={this.openSideDraw}
                        size='small'>
                        <AddContractBid onClose={this.openSideDraw} isCreate={true} tab={this.props.tab}/>
                    </SideDrawer>
                }
            </Paper>
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        tracks: state.main.tracks,
        selectedContractBid: state.companies.contractBids.selectedContractBid,
        isDuplicateSelectedContractBid: state.companies.contractBids.duplicateSelectedContractBid,
        contractBids: state.companies.contractBids.contractBids,
        commodities: state.master.commodities.items,
    };
};

const mapDispatchToProps = dispatch => ({
    getCompanyContractBids: loader => dispatch(getCompanyContractBids(loader, null, 'archived')),
    setContractBidsResponse: () => dispatch(contractBidsResponse([])),
    setSelectedContractBid: () => dispatch(setSelectedContractBid([])),
    duplicateSelectedContractBid: flag => dispatch(duplicateSelectedContractBid(flag)),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ArchivedContractBids);

import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {required} from "../../../common/validators";
import {formatPrice, toDateFormat, getCountryLabel, openURLInNewTab} from "../../../common/utils";
import Button from "@mui/material/Button";


class InvoiceConfirmationPendingPaymentAcceptanceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectDialogOpen: false,
      isFetching: true,
      invoices: [],
      selectedInvoice: null,
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
    };
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.invoices().appendToUrl('payment/confirmation/pending/').get().then(invoices => {
      const isOk = isArray(invoices);
      this.setState({isFetching: !isOk, invoices: isOk ? invoices : []}, () => {
        this.props.onDataLoad(this.state.invoices.length);
      });
    });
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.invoices.length})`;
    return `Pending Payment Confirmation ${suffix}`;
  }


  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/invoices/${id}/details`);
  }

  onReviewClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/invoices/${id}/details/payments`);
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.invoices))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon/>}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Invoice No.</TableCell>
                      <TableCell align='center' className="xsmall">Bill To</TableCell>
                      <TableCell align='center' className="xsmall">Bill For</TableCell>
                      <TableCell align='center' className="xsmall">Type</TableCell>
                      <TableCell align='center' className="medium">Payment Due Date</TableCell>
                      <TableCell align='center' className="xsmall">Sub-Total</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('gst')}</TableCell>
                      <TableCell align='center' className="medium">Total</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.invoices) ?
                      this.state.invoices.map(invoice => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={invoice.referenceNumber}
                          onClick={(event) => this.onRowClick(event, invoice.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(invoice.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.payerDisplayName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.payeeDisplayName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.type)}</TableCell>
                          <TableCell align='center' className="medium">
                            {
                              toDateFormat(this.getColumnValue(invoice.paymentDueDate))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.subTotal))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.gst))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.total))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              color="primary"
                              onClick={(event) => this.onReviewClick(event, invoice.id)}>
                              Review
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseInvoice: state.companies.invoices.confirmedOrRejectedInvoice,
});

export default connect(mapStateToProps)(InvoiceConfirmationPendingPaymentAcceptanceGroup);

import React from 'react';
import  { useSelector, useDispatch }  from 'react-redux';
import { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import { get, without, isEmpty, keys, values, forEach, filter, map, uniq, compact, pick, includes } from 'lodash';
import CustomEmailDialog from './CustomEmailDialog';
import { isLoading, forceStopLoader } from '../../actions/main';
import find from 'lodash/find';
import { isCurrentUserBroker } from '../../common/utils';
import { FREIGHT_CONTRACT_TYPE, PACK_ORDER_TYPE_ID, SYSTEM_COMPANY_IDS } from '../../common/constants';


let MovementCustomEmail = (props) => {
    const token = useSelector(state => {
        return state.main.user.token;
    });

    const [emailParties, setEmailParties] = useState([]);
    const [isIndependent, setIsIndependent ] = useState(false);
    const dispatch = useDispatch();
    const [allContactsList, setAllContactsList] = useState({});
    const user = useSelector(state => {
        return state.main.user.user;
    });


    let getEmailSubject = () => {
      if (props.subject){
        return props.subject;
      }
        const companyName = get(user,'company.name',"");
        const identifier = get(props.movement, 'identifier', '').toUpperCase();
        let movementType = get(props.movement, 'typeId') === PACK_ORDER_TYPE_ID ? 'Pack' : 'Freight'
        let RepresentingCustomerCompany = null;
        if (isCurrentUserBroker()){
          RepresentingCustomerCompany = isRepresentingCustomer() ? get(props.movement, 'customer.company.name') :
          get(props.movement, 'provider.name');
        }
        let void_mail = RepresentingCustomerCompany ? `[Void] ${companyName} a/c ${RepresentingCustomerCompany} ${movementType} Movement #${identifier}`:
        `[Void] ${companyName} ${movementType} Movement #${identifier}`;
        return void_mail;
    };

    const [isLoaded, setLoaded ] = useState(false);

    let movementAgainstOrderAndMovementNotDelivered = () => {
        return get(props.movement, 'orderId') && get(props.movement, 'status') !== 'delivered';
    };

    let statusAmong= () => {
        return includes(['draft', 'planned'], get(props.movement, 'status'));
    };

    let isRepresentingCustomer = () => {
        return get(props.movement, 'customer.representedById') === get(user, 'companyId');
    };

    let isThirdParty = () => {
        return get(props.movement, 'customer.representedById') !== get(user, 'companyId') &&
            get(props.movement, 'customer.companyId') !== get(user, 'companyId') && !isFreightProviderCreator();
    };

    let isFreightProviderCreator = () => {
        return get(props.movement, 'providerId') == user.companyId;
    };

  let isAcceptanceDisabled = () => {
    if (props.vendorDecDetails) {
      return true;
    }
    else {
      return statusAmong() ||
             get(props.movement, 'isSelf') ||
             movementAgainstOrderAndMovementNotDelivered() ||
             (isRepresentingCustomer() && isFreightProviderCreator()) ||
             isThirdParty() ||
             (!props.movement.isCustomer && !props.movement.isFreightProvider);
    }
  };


    let getEmailPopupPartiesCompanyIds = parties => {
        const { movement } =  props;
        const ids = {};
        forEach(parties, party => {
          if(party === 'broker')
            ids.broker = get(movement, 'commodityContract.administration.brokerContact.companyId');
          if(party === 'buyer')
            ids.buyer = get(movement, 'commodityContract.buyer.companyId', get(movement, 'buyer.companyId'));
          if(party === 'seller')
            ids.seller = get(movement, 'commodityContract.seller.companyId', get(movement, 'seller.companyId'));

          if(party === 'consignor')
            ids.consignor = get(movement, 'freightPickup.consignor.handler.companyId');
          if(party === 'consignee')
            ids.consignee = get(movement, 'freightDelivery.consignee.handler.companyId');
          if(party === 'customer' || get(party, 'label'))
            ids.customer = get(movement, 'customer.company.id');
          if(party === 'provider')
            ids.provider = get(movement, 'providerId');
          if(party === 'packProvider')
            ids.packProvider = get(movement, 'providerId');
        });

        return ids;
    };

    let fetchPartyContacts = async (emailPopupParties, contactsList) => {
        const parties = getEmailPopupPartiesCompanyIds(emailPopupParties);
        const partiesWithoutContacts = without(keys(parties), 'customer', 'provider');
        const contacts = {};
        forEach(parties, (id, party) => {
          contacts[party] = [];
          if(party === 'customer')
              contacts[party] = contactsList.customerContacts;
          if(party === 'provider' || party === 'packProvider')
              contacts[party] = contactsList.providerEmployees;
        });
        if(!isEmpty(partiesWithoutContacts)) {
          const companyIds = uniq(compact(values(pick(parties, partiesWithoutContacts))));
          if(isEmpty(companyIds))
            return contacts;
          const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
          const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(token);
          forEach(partiesWithoutContacts, party => {
            contacts[party] = filter(employees, {companyId: parties[party]});
          });
        }
        return contacts;
    };

    let getEmailPopupParties = selectedFreightMovement => {
      if (selectedFreightMovement.typeId === PACK_ORDER_TYPE_ID) {
        return ['customer', 'packProvider'];
      }
      if (isEmpty(selectedFreightMovement.cannotRequestVendorDecReasons) && props.vendorDecDetails)
      return ['seller', 'provider', 'broker'];
      else {
        if (selectedFreightMovement.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          return ['customer', 'provider', 'consignor', 'consignee'];
        }
        if (get(selectedFreightMovement, 'order')) {
          let fmOrder = get(selectedFreightMovement, 'order');
          if (get(fmOrder, 'level') > 0 && get(fmOrder, 'parentOrder.typeId') != FREIGHT_CONTRACT_TYPE.CALL_ON_GRAIN) {
            if (get(selectedFreightMovement, 'administration.brokeredById')) {
              return ['buyer', 'seller', 'broker', 'customer', 'provider', 'consignor', 'consignee'];
            }
            return ['buyer', 'seller', 'customer', 'provider', 'consignor', 'consignee'];
          }
        }
        const buyerCompanyId = get(selectedFreightMovement, 'commodityContract.buyer.companyId', get(selectedFreightMovement, 'buyer.companyId', ''));
        const sellerCompanyId = get(selectedFreightMovement, 'commodityContract.seller.companyId', get(selectedFreightMovement, 'seller.companyId', ''));
        const customerCompanyId = get(selectedFreightMovement, 'customer.companyId', '');
        const buyer = buyerCompanyId === customerCompanyId ? { label: 'Buyer (Customer)', value: 'customer' } : 'buyer';
        const seller = sellerCompanyId === customerCompanyId ? { label: 'Seller (Customer)', value: 'customer' } : 'seller';

        if (get(selectedFreightMovement, 'commodityContract.administration.brokeredById'))
          return [buyer, seller, 'broker', 'provider', 'consignor', 'consignee'];

        return [buyer, seller, 'provider', 'consignor', 'consignee'];
      }
    };

    let getPartyEmails = () => {
        const { movement } = props;
        let parties = {
          broker: get(movement, 'commodityContract.administration.brokerContact.email', ''),
          buyer: get(movement, 'commodityContract.buyer.contact.email', get(movement, 'buyer.contact.email', '')),
          seller: get(movement, 'commodityContract.seller.contact.email', get(movement, 'seller.contact.email', '')),
          customer: get(movement, 'customer.contact.email'),
          provider: get(find(allContactsList.provider, {id: get(movement, 'assignToId')}), 'email'),
          packProvider: get(find(allContactsList.provider, {id: get(movement, 'assignToId')}), 'email'),
          consignor: '',
          consignee: '',
        };
        return parties;
    };


  let fetchContacts = async selectedFreightMovement => {
    dispatch(isLoading('movementDetail'));
    let parties = getEmailPopupParties(selectedFreightMovement);
    let sellerContacts = await getContacts(get(selectedFreightMovement, 'commodityContract.seller.company', get(selectedFreightMovement, 'seller.company', false)), get(selectedFreightMovement, 'commodityContract.seller.representedById', get(selectedFreightMovement, 'seller.representedById')));
    let buyerContacts = await getContacts(get(selectedFreightMovement, 'commodityContract.buyer.company', get(selectedFreightMovement, 'buyer.company', false)), get(selectedFreightMovement, 'commodityContract.buyer.representedById', get(selectedFreightMovement, 'buyer.representedById')));
    let customerContacts = await getContacts(get(selectedFreightMovement, 'customer.company'), get(selectedFreightMovement, 'customer.representedById'));

    let providerEmployees = get(selectedFreightMovement, 'providerId') && get(selectedFreightMovement, 'providerId') !== 1 ? await APIService.companies(get(selectedFreightMovement, 'providerId')).employees().get(token) : [];
    let contacts = await fetchPartyContacts(parties, {
      customerContacts: customerContacts,
      providerEmployees: providerEmployees,
      buyerContacts: buyerContacts,
      sellerContacts: sellerContacts,
    }
    );
    setAllContactsList(contacts);
    setEmailParties(parties);
    dispatch(forceStopLoader());
    setLoaded(true);
  };

  let getSelectedParties = () => {
    const { movement, vendorDecType } = props;
    if (props.vendorDecDetails) {
      if (vendorDecType == 'grain_commodity') {
        if (get(movement, 'isCustomer') && movement.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) return ['seller', 'broker'];
        else if (get(movement, 'isBuyer') && get(movement, 'commodityContract.seller.representedById', get(movement, 'seller.representedById'))) return ['seller', 'broker'];
        else return ['seller'];
      }
      else
        return ['provider'];
    }
    else {
      if (get(movement, 'isFreightProvider') && get(movement, 'isCustomer') && !SYSTEM_COMPANY_IDS.includes(get(movement, 'providerId'))) return ['customer', 'provider'];
      else if (get(movement, 'isFreightProvider')) return ['customer'];
      else if (get(movement, 'isCustomer') && !SYSTEM_COMPANY_IDS.includes(get(movement, 'providerId'))) return ['provider'];
    }
  };

    let getContacts = async (company, representedById) => {
        const queryParams = representedById ? {representing_company_id: representedById} : {};
        return get(company, 'id') ? await APIService.contracts().companies(company.id).contacts().get(token, {}, queryParams) : [];
    };

    let getPartyContacts = () =>{
        return allContactsList;
    };

    useEffect(() => {
        if (props.movement){
            setLoaded(false);
            fetchContacts(props.movement);
            settingIndependence();}
    },[props.movement, props.vendorDecDetails]);


  let settingIndependence = () => {
    if (props.vendorDecType == 'grain_commodity') { if (get(props.movement, 'isCustomer') && get(props.movement, 'typeId') === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) setIsIndependent(true); }
    else {
      if (props.vendorDecDetails && (isEmpty(get(props.movement, 'provider')) || SYSTEM_COMPANY_IDS.includes(get(props.movement, 'providerId'))))
        setIsIndependent(true);
      else setIsIndependent(false);
    }
  };

  let partiesForVendorDecRequest = () => {
    const { vendorDecType } = props;
    if (vendorDecType == 'grain_commodity') {
      if (props.movement.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
        return ['seller', 'broker'];
      }
      else {
        if (get(props.movement, 'commodityContract.seller.representedById', get(props.movement, 'seller.representedById', false))) {
          return ['seller', 'broker'];
        }
        else {
          return ['seller'];
        }
      }
    }
    else
      return ['provider'];
  };

    return (<span>
    {
        props.movement && props.showCustomEmailDialog && isLoaded &&
        <CustomEmailDialog
            parties={props.vendorDecDetails ? partiesForVendorDecRequest() : emailParties}
            selectedParties={getSelectedParties()}
            title={(props.vendorDecDetails) ? 'Request Vendor Declaration' : 'Void Movement'}
            partyEmails={getPartyEmails()}
            partyContacts={getPartyContacts()}
            subject={getEmailSubject()}
            noBody={true}
            open={props.showCustomEmailDialog}
            onClose={props.closeCustomEmailDialog}
            disableAcceptanceRequired={isAcceptanceDisabled()}
            disableLater={props.disable}
            isIndependent={isIndependent}
        />
    }
    </span>);

};

export default MovementCustomEmail;

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { getHeaderTitle } from "./invoiceDetails/InvoiceDetails";
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import InvoiceTransfersTable from './InvoiceTransfersTable';

class InvoiceTransfers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSideDrawer: false,
            invoice: null,
            value: '#' + this.props.location.pathname,
        };
        this.setValueBasedOnPath = this.setValueBasedOnPath.bind(this);
    }

    getInvoiceId() {
        return this.props.invoiceId || this.props.match.params.invoice_id;
    }

    componentDidMount() {
        this.setHeaderAndBreadcrumbs();
    }

    setHeaderAndBreadcrumbs() {
        const invoiceId = this.getInvoiceId();
        let breadcrumbs = [
            { text: 'Transfers' },
        ];
        let headerText = 'Transfers';
        if (invoiceId) {
            breadcrumbs = [
                { text: 'Invoices', route: '/invoices' },
                { text: get(this.props.invoice, 'identifier', ''), route: '/invoices/' + invoiceId + '/details' },
                { text: 'Transfers' },
            ];
            headerText = getHeaderTitle(this.props.invoice);
        }
        this.props.dispatch(setHeaderText(headerText));

        if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
            this.props.dispatch(setBreadcrumbs(breadcrumbs));
        }

    }

    componentDidUpdate() {
        setTimeout(this.setValueBasedOnPath, 500);
        this.setHeaderAndBreadcrumbs();
    }

    setValueBasedOnPath() {
        const currentUrl = `#${this.props.location.pathname}`;
        if (this.state.value !== currentUrl) {
            this.setState({ value: currentUrl });
        }
    }

    render() {
        return (
            <InvoiceTransfersTable {...this.props} />
        );
    }
}

const mapStateToProps = state => {
    return {
        breadcrumbs: state.main.breadcrumbs,
        invoice: state.companies.invoices.selectedInvoice,
    };
};

InvoiceTransfers.propTypes = {
    invoiceId: PropTypes.number,
    match: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    breadcrumbs: PropTypes.array,
};

export default connect(mapStateToProps)(InvoiceTransfers);
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import {
  RECEIVE_COMPANY_NGRS,
  ADD_COMPANY_NGR,
  EDIT_COMPANY_NGR,
  CANCEL_EDIT_COMPANY_NGR,
  CLICK_ADD_COMPANY_NGR_BUTTON,
  CLICK_EDIT_COMPANY_NGR_ICON,
  CLICK_EDIT_COMPANY_NGR,
  ADD_COMPANY_NGR_ERRORS,
  EDIT_COMPANY_NGR_ERRORS,
  CURRENT_USER_REPRESENTING_ANY_PARTY,
} from '../../actions/companies/company-ngrs';

const initialState = {
  items: [],
  selectedNgrId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  isCurrentUserRepresentingAnyParty: false,
  serverErrors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case RECEIVE_COMPANY_NGRS:
      return {
        ...state,
        items: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_COMPANY_NGR:
      return {
        ...state,
        items: [action.item, ...state.items],
        selectedNgrId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case ADD_COMPANY_NGR_ERRORS:
      return {
        ...state,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
        serverErrors: action.errors,
      };
    case EDIT_COMPANY_NGR: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedCompanyId: undefined,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        updatedNgr: action.item,
      };
    }
    case EDIT_COMPANY_NGR_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_COMPANY_NGR:
      return {
        ...state,
        selectedCompanyId: undefined,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: false,
      };
    case CLICK_ADD_COMPANY_NGR_BUTTON:
      return {
        ...state,
        selectedCompanyId: undefined,
        selectedNgrId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_COMPANY_NGR_ICON:
      return {
        ...state,
        selectedCompanyId: find(state.items, {id: action.id}).companyId,
        selectedNgrId: find(state.items, {id: action.id}).id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    case CLICK_EDIT_COMPANY_NGR:
      return {
        ...state,
        selectedNgrId: action.id,
        isUpdateFormDisplayed: true,
      };
    case CURRENT_USER_REPRESENTING_ANY_PARTY:
      return {
        ...state,
        isCurrentUserRepresentingAnyParty: action.item,
      };
    default:
      return state;
  }
};

export default reducer;

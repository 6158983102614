import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { IconButton, CircularProgress } from '@mui/material';
import { ChevronLeft as LeftIcon, ChevronRight as RightIcon } from '@mui/icons-material';

const PDFViewer = props => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => setNumPages(numPages);

  return (
    <div>
      {
        numPages > 1 &&
        <React.Fragment>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <IconButton size='small' onClick={() => setPageNumber(pageNumber - 1)} disabled={pageNumber <= 1}>
              <LeftIcon fontSize='inherit' />
            </IconButton>
            <span style={{margin: '0 10px'}}>{pageNumber} of {numPages}</span>
            <IconButton size='small' onClick={() => setPageNumber(pageNumber + 1)} disabled={pageNumber === numPages}>
              <RightIcon fontSize='inherit' />
            </IconButton>
          </div>
        </React.Fragment>
      }
        <div style={{border: '1px solid lightgray'}}>
          <Document
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <div style={{margin: '20px', textAlign: 'center'}}>
                <CircularProgress />
              </div>
            }
            {...props}
          >
            <Page pageNumber={pageNumber} />
          </Document>
        </div>
    </div>
  );
};

export default PDFViewer;

import React from 'react';
import { Card, Button } from '@mui/material';
import { get, map, filter, isEmpty } from 'lodash';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import APIService from "../../services/APIService";
import { currentUserCompany } from '../../common/utils';

const LOADING_TEXT = 'Loading...';
const DIV_PADDING_STYLES = {padding: '0 10px', marginTop: '16px'};

class OwnershipLoadsCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      gradeId: props.gradeId,
      season: props.season,
      commodityId: props.commodityId,
      ngrCompanyId: null,
      selectedCompanies: [],
      isFetchingCompanies: false,
    };
    this.isFetchingCompanies = false;
  }

  componentDidUpdate() {
    if (this.props.isRedirectedFromSwapPage && isEmpty(this.state.selectedCompanies) && !this.isFetchingCompanies) {
      this.isFetchingCompanies = true;
      this.fetchDirectoryCompanies();
    }
  }

  handleSelectFieldChange = (value, id) => {
    this.setState({[id]: value});
  };

  handleSubmit = () => {
    this.props.onSubmit(this.state);
  };

  getCommodities() {
    return map(get(this.props.attributes, 'commodities', []), commodity => ({id: commodity.id, name: commodity.displayName}));
  }
  getGrades() {
    let grades = get(this.props.attributes, 'grades', []);
    if(this.state.commodityId)
      grades = filter(grades, {commodityId: this.state.commodityId});
    return grades;
  }
  getSeasons() {
    return map(get(this.props.attributes, 'seasons', []), season => ({id: season, name: season}));
  }

  fetchDirectoryCompanies = async () =>{
    let companyData = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get();
    companyData.push({
      name: get(currentUserCompany(), 'name'),
      id: get(currentUserCompany(), 'id')
    });
    this.setState({
      selectedCompanies: companyData,
    });
  };

  render() {
    const { commodityId, gradeId, season, ngrCompanyId } = this.state;
    const {farm, ngr} = this.props;
    return (
      <Card style={{display: 'flex', overflow: 'visible'}}>
        <div className='col-sm-12 padding-reset'>
          <div className='col-sm-3 padding-reset' style={{textAlign: 'center'}}>
            <h2 style={{marginTop: '10px', marginBottom: '0px'}}>
              {get(farm, 'name', LOADING_TEXT)}
            </h2>
            {
              ngr &&
              <div style={{fontSize: '14px', color: 'rgba(0, 0,0, 0.5)', marginBottom: '8px'}}>
                {`${ngr.companyName} - ${ngr.ngrNumber}`}
              </div>
            }
          </div>
          <div className='col-sm-8 padding-reset'>
            <div className={this.props.isRedirectedFromSwapPage ? 'col-sm-3' : 'col-sm-4'} style={DIV_PADDING_STYLES}>
              <CommonAutoSelect
                id='commodityId'
                placeholder='Commodity'
                items={this.getCommodities()}
                onChange={this.handleSelectFieldChange}
                value={commodityId}
              />
            </div>
            <div className={this.props.isRedirectedFromSwapPage ? 'col-sm-3' : 'col-sm-4'} style={DIV_PADDING_STYLES}>
              <CommonAutoSelect
                id='gradeId'
                placeholder='Grade'
                items={this.getGrades()}
                onChange={this.handleSelectFieldChange}
                value={gradeId}
              />
            </div>
            <div className={this.props.isRedirectedFromSwapPage ? 'col-sm-3' : 'col-sm-4'} style={DIV_PADDING_STYLES}>
              <CommonAutoSelect
                id='season'
                placeholder='Season'
                items={this.getSeasons()}
                onChange={this.handleSelectFieldChange}
                value={season}
              />
            </div>
            {this.props.isRedirectedFromSwapPage &&
              <div className='col-sm-3' style={DIV_PADDING_STYLES}>
                <CommonAutoSelect
                  id='ngrCompanyId'
                  placeholder='Owner'
                  items={this.state.selectedCompanies || []}
                  onChange={this.handleSelectFieldChange}
                  value={ngrCompanyId}
                  dataSourceConfig={{ value: 'id', text: 'name' }}
                />
              </div>
            }
          </div>
          <div className='col-sm-1' style={{...DIV_PADDING_STYLES, textAlign: 'center'}}>
            <Button color='primary' variant="contained" onClick={this.handleSubmit}>
              Reload
            </Button>
          </div>
        </div>
      </Card>
    );
  }
}

export default OwnershipLoadsCard;

import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';
import {
  Dialog, DialogContent, Slide, List, ListItem, ListItemText, ListItemIcon
} from '@mui/material';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { map, keys, capitalize, isEmpty, get, isBoolean, sortBy, fromPairs, reject } from 'lodash';
import APIService from '../../services/APIService';
import './slotHistory.scss';
import { getCountryLabel, getCountryFormats, isStaff } from '../../common/utils';


class SlotHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: {}
    };
    this.onClose = this.onClose.bind(this);
  }

  componentDidMount() {
    const { slotId, siteId, token, timezone } = this.props;

    if(slotId, siteId) {
      const queryParams = {timezone: get(timezone, 'location')};
      APIService
      .company_sites(siteId)
      .slots(slotId)
      .appendToUrl('history/')
      .get(token, null, queryParams)
        .then(history => {
          const isStaffUser = isStaff()
          const format = getCountryFormats().datetimeWithSeconds
          let sortedData = sortBy(Object.entries(history), ([key]) => moment(key, format)).reverse()
          if(!isStaffUser) {
            sortedData = reject(sortedData, data => isEmpty(data[1].items) && data[1].heading.startsWith('Slot updated by'))
          }
          const sortedObject = fromPairs(sortedData);

        this.setState({history: sortedObject});
      });
    }
  }

  Transition(props) {
    return <Slide direction="left" {...props} />;
  }

  onClose() {
    this.props.onClose();
  }


  render() {
    const { show } = this.props;
    return (
      <div>
        <Dialog
          open={show}
          TransitionComponent={this.Transition}
          keepMounted
          className='slot-comments-dialog'
          onClose={this.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitleWithCloseIcon onClose={this.onClose} style={{height: '55px'}}>
            <div style={{marginTop: '-4px'}}>History</div>
          </DialogTitleWithCloseIcon>
          <DialogContent style={{padding: '0px'}}>
            <List>
              {
                map(this.state.history, (changeSet, date) => {
                  return (
                    <div className='changeset-container'>
                      <ListItem key={date}>
                        <ListItemIcon style={{marginRight: '0px'}}>
                          <ScheduleIcon />
                        </ListItemIcon>
                        {
                          changeSet.href ?
                          <Link to={changeSet.href} target="_blank">
                            <ListItemText className="changeset-item-text" primary={changeSet.heading} secondary={date} />
                          </Link> :
                          <ListItemText className="changeset-item-text" primary={changeSet.heading} secondary={date} />
                        }
                      </ListItem>
                      {
                        !isEmpty(changeSet.items) &&
                        <div className='changeset-items'>
                          {
                            map(changeSet.items, (item, index) => {
                              let field = keys(item)[0];
                              let value = item[field].new;
                              if(field === 'extras' ){
                                field = keys(value)[0];
                                value = value[field];
                                if(field === 'fleetReminderSentAt'){
                                  field = `Reminder Sent to update Truck ${getCountryLabel('rego')} in Slot`;
                                  value = '';
                                }
                              }
                              if(isBoolean(value))
                                value = value ? 'true' : 'false'
                              return (
                                <span key={index} className='changeset-item'>
                                  <span className='changeset-label'>{capitalize(field)}</span>
                                  <span className='changeset-value'>{value}</span>
                                </span>
                              );
                            })
                          }
                        </div>
                      }
                    </div>
                  );
                })
              }
            </List>
          </DialogContent>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  };
};

export default connect(mapStateToProps)(SlotHistory);

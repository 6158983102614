import React from 'react';
import { connect } from 'react-redux';
import { getContracts, getFarmMovements, getContractsResponse } from '../../actions/companies/freights';
import PackMovementsTable from '../../containers/PackMovementsTable';
import Paper from '@mui/material/Paper';
import {
  setHeaderText, setSubHeaderText, setBreadcrumbs, isLoading, forceStopLoader, isSearchApplied
} from '../../actions/main';
import { has, get, isEqual, includes, isEmpty } from 'lodash';
import {
  getContractSubHeaderText, getOrderSubHeaderText, isAtGlobalFMs, attachCSVEventListener, isSystemCompany, getZendeskURL, isCurrentUserCompanyPlanLite,
  defaultViewAction
} from '../../common/utils';
import { receiveFreight } from '../../actions/companies/freights';
import APIService from '../../services/APIService';
import Tooltip from '@mui/material/Tooltip';
import {
  CALL_ON_GRAIN_TYPE_ID, PACK_MOVEMENT_FILTER_STATUSES, OBSERVER_TYPE_ID, COMPANY_ADMIN, OFFICE_ADMIN,
  SYSTEM, PACK_ORDER_TYPE_ID, PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS, FILTER_KEYS_TO_EXCLUDE, PREDEFINED_DATE_RANGE_FILTER_KEYS
} from '../../common/constants';
import { setDownloadBar } from '../../actions/main';
import { freightAddForCreateableMovement } from '../../actions/companies/contracts';
import { canCreateMovementForOrder } from '../../actions/companies/orders';
import CommonListingButton from '../common/CommonListingButton';
import Button from '@mui/material/Button/Button';
import FilterListIcon from '@mui/icons-material/FilterList';
import SideDrawer from '../common/SideDrawer';
import Filters from '../common/Filters';
import RSMReportFilters from './RSMReportFilters';
import DownloadDataDialog from '../common/DownloadDataDialog';
import alertifyjs from 'alertifyjs';
import FiltersAppliedChip from '../common/FiltersAppliedChip';

const PACK_MOVEMENT_FILTER_KEYS_MAPPING = {
  'pack_by_date_range': ['pack__load__date_time__gte', 'pack__load__date_time__lte'],
  'updated_at_date_range': ['updated_at__gte', 'updated_at__lte']
}

class PackMovements extends React.Component {
  constructor(props) {
    super(props);
    this.uploadForm = React.createRef();
    this.state = {
      isOpen: false,
      csvData: [],
      filterValues: {
        customer__company__id__in: [],
        customer__ngr__in: [],
        status__in: [],
        commodity__id__in: [],
        planned_grade__id__in: [],
        season__in: [],
        provider__id__in: [],
        freight_delivery__consignee__handler__id__in: [],
        pack_by_date_range: '',
        pack__load__date_time__gte: '',
        pack__load__date_time__lte: '',
        updated_at_date_range: '',
        updated_at__lte: '',
        updated_at__gte: '',
      },
      filters: {},
      filter_statuses: PACK_MOVEMENT_FILTER_STATUSES,
      applyFilters: false,
      openSideDrawer: false,
      csvPopup: false,
      customColumns: true,
      customColumnNames: {},
      customHeaderOptions: false,
      searchView: false,
      isFilteredCsv: false,
      isPackOrder: true,
      customTableColumnOptions: false,
      customTableColumnNames: {},
      customColumnTitle: undefined,
      csvType: undefined,
    };
    this.onHandleAddMovementButtonClick = this.onHandleAddMovementButtonClick.bind(this);
    this.onDownloadResponse = this.onDownloadResponse.bind(this);
    this.onCloseDownloadResponse = this.onCloseDownloadResponse.bind(this);
    this.editCustomHeaderOptions = this.editCustomHeaderOptions.bind(this);
  }

  onCloseDownloadResponse() {
    this.props.setDownloadBar(false);
  }

  onDownloadResponse(message) {
    this.props.setDownloadBar(message, true, this.onCloseDownloadResponse);
  }

  _attachCSVEventListener() {
    attachCSVEventListener(
      'freight-movements-csv-ready', 'Freight Movements', this.onDownloadResponse
    );
  }

  componentWillUnmount() {
    this.props.unMountContracts();
    this.props.applySearch(null);
    if (window.location.hash.includes('?') && isAtGlobalFMs())
      window.location.hash = window.location.hash.split('?')[0]
  }

  componentDidMount() {
    let { filterValues } = this.state;
    this._attachCSVEventListener();
    this.props.unsetSelectedFreight();
    this.props.applySearch(null);
    let isPackOrder = true
    if (get(this.props, 'order')) {
      isPackOrder = this.props.order.typeId === PACK_ORDER_TYPE_ID;
      this.setState({isPackOrder: isPackOrder});
    }
    this.setHeaderAndBreadcrumbs(isPackOrder);
    if (has(this.props.match.params, 'order_id') && get(this.props,'order') === undefined) {
      this.props.isLoading();
    }

    const queryParams = new URLSearchParams(this.props.location.search);
    const farmId = this.props.farmId || queryParams.get('farmId');
    if (this.props.match.params && (has(this.props.match.params, 'order_id') || has(this.props.match.params, 'contract_id'))) {
      this.orderId = this.props.match.params.order_id;
      this.commodityContractId = this.props.match.params.contract_id;
      this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, true);
    } else if (farmId) {
      this.props.isLoading('tillFarmMovements');
      this.props.onGetFarmMovements(farmId);
    } else if (has(this.props.location, 'search')) {
      this.orderId = queryParams.get('order_id');
      this.commodityContractId = queryParams.get('commodity_contract_id');
      this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, true);
    }
    this.setState({csvType: 'pack_movements_csv'});
    APIService.profiles()
      .filters('pack_movement')
      .get(this.props.token)
      .then(res => this.setState({filters: res?.pack_movement || {}}));
    if (isSystemCompany()) {
      filterValues['show_missing_docket'] = false;
      filterValues['sms_entry'] = false;
    }

  }

  setHeaderAndBreadcrumbs(isPackOrder) {
    const countLabel = ` (${this.props.count})`;
    const isCallOnGrain = get(this.props, 'order.typeId') === CALL_ON_GRAIN_TYPE_ID;
    const orderType = isCallOnGrain ? 'grain' : 'freights';
    let breadcrumbs = [
      { text: 'Movements' + countLabel },
    ];
    let headerText = 'Pack Movements';
    if (this.props.contractId) {
      breadcrumbs = [
        { text: 'Contracts', route: '/contracts' },
        { text: get(this.props.contract, 'referenceNumber', ''), route: '/contracts/' + this.props.contractId + '/contract' },
        { text: 'Movements' + countLabel },
      ];
      headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
      this.props.setSubHeaderText(getContractSubHeaderText(this.props.contract));
    } else if (this.props.farmId) {
      let farmRoute = '/stocks/storages-view?farmId=' + this.props.farmId
      breadcrumbs = [{ text: 'Farms', route: '/farms' }, { text: this.props.farmName, route: farmRoute }, { text: 'Movements' + countLabel }];
      headerText = this.props.farmName;
    } else if (this.orderId) {
      breadcrumbs = [
        { text: 'Orders', route: `/orders/${orderType}` },
        { text: get(this.props.order, 'identifier', ''), route: '/freights/orders/' + this.orderId + '/order' },
        { text: 'Movements' + countLabel }
      ];
      if (isPackOrder) {
        headerText = 'Pack Order ' + get(this.props.order, 'identifier', '');
      }
      else {
        headerText = (isCallOnGrain ? 'Call On Grain Orders' : 'Freight Order ') + get(this.props.order, 'identifier', '');
      }
      this.props.setSubHeaderText(getOrderSubHeaderText(this.props.order));
    }
    this.props.setHeaderText(headerText);

    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      this.props.setBreadcrumbs(breadcrumbs);
    }
  }

  componentDidUpdate(prevProps) {
    if (has(this.props.match.params, 'order_id') && get(this.props,'order') !== undefined) {
      this.props.forceStopLoader();
    }
    let isPackOrder = get(this.props, 'order.typeId') === PACK_ORDER_TYPE_ID;
    if (get(prevProps, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs(isPackOrder);
    if (this.props.match.params && (has(this.props.match.params, 'order_id') || has(this.props.match.params, 'contract_id'))) {
      if (this.orderId != this.props.match.params.order_id || this.commodityContractId != this.props.match.params.contract_id) {
        this.orderId = this.props.match.params.order_id;
        this.commodityContractId = this.props.match.params.contract_id;
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder);
      }
    } else if (has(this.props, 'location.search')) {
      const queryParams = new URLSearchParams(this.props.location.search);
      if (this.orderId != queryParams.get('order_id') || this.commodityContractId != queryParams.get('commodity_contract_id')) {
        this.orderId = queryParams.get('order_id');
        this.commodityContractId = queryParams.get('commodity_contract_id');
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder);
      }
    }
    if (isPackOrder && !this.state.isPackOrder) {
      this.setState({isPackOrder: isPackOrder}, () => {
        this.setHeaderAndBreadcrumbs(isPackOrder);
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, isPackOrder);
      });
    }
  }

  onHandleAddMovementButtonClick() {
    const func = this.props.handleAddMovementButtonClick || this.props.onHandleAddMovementButtonClick;
    if (func) {
      const { contract, orderId, dispatch } = this.props;
      if (contract)
        dispatch(freightAddForCreateableMovement(get(contract, 'id'), get(contract, 'status')));
      else
        dispatch(canCreateMovementForOrder(orderId));
    } else window.location = '/#/freights/movements/new';
  }

  fetchCSVData = (callback) => {
    const { setDownloadBar } = this.props;
    var param = this.state.isFilteredCsv ? 'show_filters': '';
    if (this.state.customColumns)
      param+= param.length == 0 ? 'custom_csv' : '&custom_csv';
    if (this.props.isSearchApplied && this.state.searchView)
      param+= param.length == 0 ? `search=${this.props.isSearchApplied}` : `&search=${this.props.isSearchApplied}`;
    let service;
    let movementType = 'Pack'
    setDownloadBar(`Your ${movementType} Movements CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.`, true);

    service = APIService.freights().contracts().appendToUrl(`pack-movements/csv/?${param}`);
    if (this.orderId)
      service.appendToUrl(`&order_id=${this.orderId}`);
    if (this.commodityContractId)
      service.appendToUrl(`&commodity_contract_id=${this.commodityContractId}`);

    this.setState({csvPopup: false, searchView: false})

    service
      .get(this.props.token, {
        'Content-Type': 'text/csv',
        Accept: 'text/csv',
      })
      .then(csvData => {
        this.setState({ csvData: csvData || [],});
      });
    if (callback) {
      callback();
    }
  };

  canExportCSV() {
    return includes([COMPANY_ADMIN, OFFICE_ADMIN, SYSTEM, OBSERVER_TYPE_ID], get(this.props.currentUser, 'typeId'));
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters' && isAtGlobalFMs()) {
        const { filters } = this.state;
        APIService.profiles()
          .filters()
          .post({ pack_movement: filters }, this.props.token)
          .then(res => {
            this.props.isLoading('manualWait');
            this.setState({filters: res?.filters?.pack_movement || {}}, () => {
              this.props.onGetMovements(null, null, '', true, this.state.isPackOrder);
            })
          });
      }
    });
  };

  fetchRMSReportData = (callback) => {
    this.toggleDialog();
    if (callback) {
      callback();
    }
  };

  toggleDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  }

  async editCustomHeaderOptions() {
    const columnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/report-preferences/${this.state.csvType}/`).get(this.props.token);
    this.setState({customColumnNames: columnNames, customHeaderOptions: true});
  }

  customCsvEnabled(isFilteredCsv) {
    const newState = {...this.state};
    newState.isFilteredCsv = isFilteredCsv;
    if (this.props.currentUser.company.enableCustomCsv || this.props.isSearchApplied) {
      newState.csvPopup = true;
      this.setState(newState);
    }
    else {
      newState.customColumns = false;
      this.setState(newState, this.fetchCSVData);
    }
  }


  handleFileChosen = event => {
    let file = event.target.files[0];
    event.target.value = '';
    let formData = new FormData();
    formData.append('file', file);
    formData.append('orderId', this.props.orderId);
    APIService.freights().contracts().appendToUrl('csv/')
    .post(formData)
    .then(response => {
      let reasons = get(response, 'reasons')
      if (reasons != null && reasons.length > 0) {
        reasons = '<li>' + reasons.join('</li><li>');
        alertifyjs.alert(
          'Permission Denied',
          `<div id="complete-dialog-open" className=""><p>Pack Movement cannot be created because:</p><ul>${reasons}</ul><div>Please follow <a href=${getZendeskURL()} target='_blank'>FAQs</a> for more details</div></div>`,
          () => {},
        );
      }
      else {
        this.props.onGetMovements(this.orderId, this.commodityContractId, '', false, this.state.isPackOrder);
      }
    })
    .catch(() => alertifyjs.error("Failed - Incorrect CSV format"));
  };

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  async updateCustomTableColumns() {
    if (this.props.currentUser.company.enableCustomCsv) {
      const tableColumnNames = await APIService.profiles().appendToUrl(`${this.props.currentUser.id}/table-preferences/freight_movements_table/`).get(this.props.token);
      this.setState({customTableColumnNames: tableColumnNames, customTableColumnOptions: true});
    }
    else {
      alertifyjs.alert(
        'Permission Denied',
        'This feature is not enabled for your company. Please contact AgriChain support',
        () => { },
      );
    }
  }

  getColumnsMapping() {
    return PACK_MOVEMENTS_TABLE_GLOBAL_LISTING_HEADERS.reduce((obj, objectKey) => ({ ...obj, [objectKey.key]: objectKey.header }), {});
  }

  updateColumnCount(count) {
    this.setState({customColumnTitle: `Edit Columns (${count})`});
  }

  getCsvOptions() {
    let options = [
      { name: 'Complete List', fx: () => this.customCsvEnabled(false) },
      !isEmpty(Object.entries(this.state.filters).filter(val => !isEmpty(val[1]))) ? { name: 'Filtered List', fx: () => this.customCsvEnabled(true) } : {},
    ];
    return options;
  }

  customFilterValueExist = filterKeys => filterKeys.some(key => Boolean(get(this.state.filters, key)))

  filterCriteria = (key, value) => includes(FILTER_KEYS_TO_EXCLUDE, key) ? false : includes(PREDEFINED_DATE_RANGE_FILTER_KEYS, key) && value === 'custom' ? this.customFilterValueExist(get(PACK_MOVEMENT_FILTER_KEYS_MAPPING, key)) : value.length !== 0 && value !== false;

  render() {
    const { filterValues } = this.state;
    return (
      <Paper className='paper-table-paginated'>
        <div style={{ position: 'relative' }}>
          <FiltersAppliedChip filters={this.state.filters} show={isAtGlobalFMs()} style={{paddingRight: '50%'}} />
          <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
            {this.canExportCSV() && !isCurrentUserCompanyPlanLite() && (
               <CommonListingButton
                 defaultHandler={() => this.customCsvEnabled(false)}
                 showMenus={isAtGlobalFMs()}
                 optionMapper={this.getCsvOptions()}
                 title='Download Contents of the table in a CSV'
                 name='Export'
               />
            )}
            <DownloadDataDialog
              open={this.state.csvPopup}
              onClose={() => this.setState({csvPopup: false, searchView: false})}
              title='Download Pack Movements Data'
              enableCustomCsv={this.props.currentUser.company.enableCustomCsv}
              isSearchApplied={this.props.isSearchApplied}
              searchView={this.state.searchView}
              onSearchViewChange={() => this.setState({searchView: !this.state.searchView})}
              isFilteredCsv={this.state.isFilteredCsv}
              onDownload={this.fetchCSVData}
              customColumnTitle={this.state.customColumnTitle}
              user={this.props.currentUser}
              token={this.props.token}
              csvType={this.state.csvType}
              updateColumnCount={(count) => this.updateColumnCount(count)}
            />
            {
              <div style={{ float: 'right' }}>
                <CommonListingButton
                  showMenus
                  showDownLoadIcon={false}
                  optionMapper={this.getActionsOptionMapperListItems()}
                  title='Actions'
                  name='Actions'
                  variant='outlined'
                  color='secondary'
                />
              </div>
            }
            {isAtGlobalFMs() && (
               <Tooltip title='Apply filters' placement='top'>
                 <Button
                   value={this.state.applyFilters}
                   variant='contained'
                   type='button'
                   onClick={() => this.handleFilters(true)}
                   style={{ float: 'right', marginLeft: '10px' }}
                 >
                   <FilterListIcon style={{ paddingRight: '5px' }} />
                   FILTERS{' '}
                   {+!isEmpty(Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])))
                    ? `(${Object.entries(this.state.filters).filter(val => this.filterCriteria(val[0], val[1])).length})`
                    : ''}
                 </Button>
               </Tooltip>
            )}
            {this.state.applyFilters && (
               <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                 <Filters
                   isLoading={this.props.isLoading}
                   forceStopLoader={this.props.forceStopLoader}
                   handleFilterState={this.handleFilterState}
                   filters={this.state.filters}
                   statusTemp={this.state.filter_statuses}
                   filterValues={filterValues}
                   isFMFilters={false}
                   isPMFilters
                 />
               </SideDrawer>
            )}
            <input ref={this.uploadForm} name="upload" id="upload" type="file" accept=".csv" style={{ visibility: "hidden", display: 'none' }} onChange={this.handleFileChosen} />

          </div>
          <PackMovementsTable
            dontRedirect={this.props.dontRedirect}
            removeContractFromSearch={get(this.props, 'removeContractFromSearch')}
            nested={this.props.nested}
          />
        </div>
        {
          this.state.isOpen &&
          <RSMReportFilters isOpen={this.state.isOpen} toggleDialog={this.toggleDialog} />
        }
      </Paper>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  unMountContracts: () => dispatch(getContractsResponse([])),
  onGetMovements: (orderId, commodityContractId, url, loader, isPackOrder) => dispatch(getContracts(orderId, commodityContractId, url, loader, isPackOrder)),
  setHeaderText: text => dispatch(setHeaderText(text)),
  setSubHeaderText: text => dispatch(setSubHeaderText(text)),
  setBreadcrumbs: breadcrumbs => dispatch(setBreadcrumbs(breadcrumbs)),
  onGetFarmMovements: farmId => dispatch(getFarmMovements(farmId, true)),
  unsetSelectedFreight: () => dispatch(receiveFreight(null)),
  setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  applySearch: (searchStr) => dispatch(isSearchApplied(searchStr)),
});

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
    token: state.main.user.token,
    selectedFarm: state.companies.farms.selectedFarm,
    currentUser: state.main.user.user,
    count: get(state.companies.freights, 'paginationData.count') || 0,
    fmWarningList: state.companies.contracts.fmWarningList,
    fmWarningFlag: state.companies.contracts.fmWarningFlag,
    isSearchApplied: state.main.isSearchApplied,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackMovements);


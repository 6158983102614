import React from 'react';
import { connect } from "react-redux";
import GenericTable from "../components/GenericTable";
import {
  getSelectedOrder,
  receiveOrder,
  receiveAllocation,
  getPaginatedFreightOrdersResponse,
  getCallOnGrainOrders,
  handleCallOnGrainOrderOptionsMapper
} from "../actions/companies/orders";
import {isLoading} from "../actions/main";
import includes from "lodash/includes";
import APIService from '../services/APIService';
import {
  isAtGlobalOrders,
  getContractIdFromCurrentRoute,
  getOrderIdFromCurrentRoute,
  getAbsoluteUrl,
} from "../common/utils";
import { getCallOnGrainOrdersGlobalListingHeaders, ORDERS_TABLE_COLUMN_LIMIT } from "../common/constants";
import { getGrainOrderActionMenuOptions, regenerateOrderPDF } from '../components/freights/utils';
import { get, find, isEmpty } from "lodash";
import FreightOrderActions from '../components/freights/FreightOrderActions';
import { getCustomColumns } from '../actions/companies/index';

class CallOnGrainOrdersTable extends React.Component {

  componentDidMount() {
    this.props.getCustomColumns('call_on_grain_order_table');
  }

  render() {
    return (
      <div>
        <GenericTable {...this.props}/>
        <FreightOrderActions {...this.props} />
      </div>
    );
  }

}

const mapStateToProps = state => {
  const hasParentOrderId = document.location.href.includes('parent_order_id');
  const subItems = state.companies.orders.subItems;
  const clickedOption = state.companies.orders.clickedOption;
  const token = state.main.user.token;
  const callOnGrainOrderColumns = [...getCallOnGrainOrdersGlobalListingHeaders(hasParentOrderId)];
  let customColumns = callOnGrainOrderColumns;
  const tableColumnsOrder = get(state.companies, 'companies.tableColumns');
  if (tableColumnsOrder && !isEmpty(tableColumnsOrder)) {
    customColumns = [];
    tableColumnsOrder.forEach(val => {
      const obj = find(callOnGrainOrderColumns, {key: val});
      if (obj) {
        customColumns.push(obj);
      }
    });
  }
  if (customColumns.length > ORDERS_TABLE_COLUMN_LIMIT) {
    customColumns = customColumns.splice(0, ORDERS_TABLE_COLUMN_LIMIT-1);
  }
  return {
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
    clearSearch: getPaginatedFreightOrdersResponse,
    columns: customColumns,
    currentUser: state.main.user.user,
    globalSearch: true,
    items: state.companies.orders.cogOrders,
    optionsItems: (item) => getGrainOrderActionMenuOptions(item, subItems, clickedOption),
    order: 'desc',
    orderBy: 'createdAt',
    paginationData: state.companies.orders.paginationData,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedOrderId: state.companies.orders.selectedOrderId,
    selectedOrder: state.companies.orders.selectedOrder,
    useNestedOptionMenu: true,
    clickedOption: clickedOption,
    shouldFetchOrder: state.companies.orders.shouldFetchOrder,
    subItems: subItems,
    token,
    canCloseOutForOrder: state.companies.orders.canCloseOutForOrder,
    voidFilter: true
  };
};

const mapDispatchToProps = (dispatch, that) => {
  const { dontRedirect } = that;
  return {
    handleOptionClick: (event, item, baseEntity) => {
      if(get(item, 'key') === 'regenerate_pdf') {
        return regenerateOrderPDF(baseEntity);
      } else {
        dispatch(handleCallOnGrainOrderOptionsMapper(event, item, baseEntity));
      }

    },
    handleDefaultCellClick: (item) => {
      const receiver = isAtGlobalOrders() ? receiveOrder : (item.parentOrderId ? receiveAllocation : receiveOrder);
      dispatch(getSelectedOrder(item.id, receiver, false, false, false, false, true));
      dispatch(isLoading('orderDetail'));
      if (dontRedirect) {
        const orderType = isAtGlobalOrders() ? 'fo' : (item.parentOrderId ? 'fa' : 'fo');
        document.location.hash = `${getAbsoluteUrl(document.location.hash)}?orderId=${item.id}&orderType=${orderType}`;
      } else {
        document.location.hash = '/freights/orders/' + item.id + '/order';
      }
    },
    navigateTo: (url) => {dispatch(getCallOnGrainOrders('', '', url, true));},
    changePageSize: (url, pageSize) => {
      if (includes(url, '?')){
        url = `${url}&page_size=${pageSize}&type_id=3`;
      } else {
        url = `${url}?page_size=${pageSize}&type_id=3`;
      }
      dispatch(getCallOnGrainOrders('', '', url, true));
    },
    getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
      const service = APIService.freights().orders();
      service.appendToUrl(`web/search/?page_size=${pageSize}&type_id=3`);
      if (!isAtGlobalOrders()) {
        const contractId = getContractIdFromCurrentRoute();
        if(contractId) {
          service.appendToUrl(`&commodity_contract_id=${contractId}&type_id=3`);
        }
        const orderId = getOrderIdFromCurrentRoute();
        if(orderId) {
          service.appendToUrl(`&order_id=${orderId}&type_id=3`);
        }
      }
      if (page) {
        service.appendToUrl(`&page=${page}&type_id=3`);
      }
      if(searchText) {
        service.appendToUrl(`&search=${searchText}&type_id=3`);
      }
      if(orderBy) {
        service.appendToUrl(`&order_by=${orderBy}&order=${order}&type_id=3`);
      }
      if(includeVoid) {
        service.appendToUrl(`&include_void=${includeVoid}`)
      }

      return service.URL;
    },
    getCustomColumns: tableType => dispatch(getCustomColumns(tableType)),
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CallOnGrainOrdersTable);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, Button, Paper } from '@mui/material';
import CashPricesTable from '../../containers/CashPricesTable';
import { TAB } from './constants';
import { getCashPricesUnregisteredUser, cashPricesResponse, saveFiltersForUnregisteredUser, resetAutoScrollPosition } from '../../actions/companies/cash-board';
import Filters from '../common/Filters';
import FilterListIcon from '@mui/icons-material/FilterList';
import { isEmpty, map } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import { isLoading, forceStopLoader, setHeaderText } from '../../actions/main';
import { getTracks } from '../../actions/main/index';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { getCountryConfig } from '../../common/utils';


class WithoutLoginCashBoard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      welcomeDialog: false,
      applyFilters: false,
      openSideDrawer: false,
      filters: {},
      filterValues: {
        commodity__id__in: [],
        grade__id__in: [],
        season__in: [],
        track__in: [],
        buyer__company__id__in: [],
        site__id__in: [],
      },
      isPresentation: false,
      scrollPosition: 0,
      autoScrollInterval: null,
    };
    this.myRef = React.createRef();
    this.exitFullScreen = this.exitFullScreen.bind(this);
  }

  componentDidMount() {
    this.props.dispatch(setHeaderText('Cash Board'))
    if(localStorage.getItem('token') || (this.props.match.params.salt && this.props.match.params.pepper))
      window.location.hash = '#/cash-board/cash-prices';
    this.props.getTracks();
    this.props.getCashPricesUnregisteredUser();
    document.addEventListener('fullscreenchange', this.exitFullScreen);
  }

  componentWillUnmount() {
    this.props.setCashPricesResponse([]);
  }

  componentDidUpdate() {
    if (this.props.resetAutoScrollPosition) {
      if (this.state.scrollPosition !== 0)
        this.setState({scrollPosition: 0})
      this.props.resetAutoScrollPositionMethod(false);
    }
    if (!this.state.isPresentation && this.state.autoScrollInterval) {
      clearInterval(this.state.autoScrollInterval);
      this.setState({scrollPosition: 0, autoScrollInterval: null});
    }
    if (this.state.isPresentation && !this.state.autoScrollInterval) {
      const autoScrollInterval = setInterval(this.autoScroll, 500);
      this.setState({ autoScrollInterval });
    }
  }

  autoScroll = () => {
    const scrollContainer = document.getElementById('cash-price-table');
    let newScrollPosition = this.state.scrollPosition + (getCountryConfig()?.cashPriceScrollSpeed || 5);
    scrollContainer.scrollTo(this.state.scrollPosition, newScrollPosition);
    this.setState({ scrollPosition: newScrollPosition });
  }

  exitFullScreen() {
    if (document.fullscreenElement) {
      if (this.state.isPresentation) {
        this.setState({isPresentation: false});
        document.exitFullscreen();
      }
      else {
        this.setState({isPresentation: true});
      }
    }
    else {
      this.setState({isPresentation: false});
    }
  }

  applyFullScreen = () => {
    let element = document.getElementById('cash-price-table');
    element.requestFullscreen();
  }

  handleFilters = bool => {
    this.setState({
      applyFilters: bool,
      openSideDrawer: bool,
    });
  };

  handleFilterState = (key, value) => {
    this.setState({ [key]: value }, () => {
      if (key === 'applyFilters') {
        const { filters } = this.state;
        const newFilters = {...filters}
        newFilters.site__id__in = map(newFilters.site__id__in, 'id')
        newFilters.buyer__company__id__in = map(newFilters.buyer__company__id__in, 'id')
        this.props.saveFiltersForUnregisteredUser(newFilters);
        this.props.getCashPricesUnregisteredUser(newFilters);
      }
    });
  };

  render() {
    return (
      <Paper className='paper-table-paginated'>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '0px', top: '0px' }}>
            <Button
              variant="contained"
              type="button"
              onClick={() => window.location.hash = ''}
              color="primary"
              className='add-button'
              style={{ float: 'right', marginLeft: '10px' }}
            >
              <ArrowBackIcon style={{ paddingRight: '5px' }} /> Back to Login Page
            </Button>
            <Tooltip title='Apply filters' placement='top'>
              <Button
                value={this.state.applyFilters}
                variant="contained"
                type='button'
                onClick={() => this.handleFilters(true)}
                color='primary'
                className='add-button'
                style={{ float: 'right', marginLeft: '10px' }}
              >
                <FilterListIcon style={{ paddingRight: '5px' }} />
                FILTERS{' '}
                {+!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))
                 ? `(${Object.entries(this.state.filters).filter(val => val[1].length !== 0).length})`
                 : ''}
              </Button>
            </Tooltip>
            <Button
              id='fullscreen-btn'
              variant="contained"
              type='button'
              onClick={() => this.applyFullScreen()}
              color='primary'
              className='add-button'
            > Presentation</Button>
            {this.state.applyFilters && (
              <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                <Filters
                  isLoading={this.props.isLoading}
                  forceStopLoader={this.props.forceStopLoader}
                  handleFilterState={this.handleFilterState}
                  filters={this.state.filters}
                  tracks={this.props.tracks}
                  filterValues={this.state.filterValues}
                  loggedOut
                />
              </SideDrawer>
            )}
          </div>
          <div id='cash-price-table' ref={this.myRef} style={this.state.isPresentation ? {overflow: "auto"}: {}}>
            <CashPricesTable
              isAllCashPriceTab={TAB.UNREGISTERED_CASH_PRICE}
              isPresentation={this.state.isPresentation}
              loggedOut
              customColumns={
                this.state.isPresentation ? [] : [
                  {
                    cellStyle: {width: '100px'},
                    sortable: false,
                    align: 'right',
                    header: '',
                    formatter: () => <Button onClick={() => this.setState({welcomeDialog: true})} color='secondary' size='small' variant='contained'>
                                         Cash Out
                                       </Button>
                  }
                ]
              }
            />
          </div>
        </div>
        <Dialog open={this.state.welcomeDialog} onClose={() => this.setState({welcomeDialog: false})}>
          <DialogTitle>
            Welcome to AgriChain!
          </DialogTitle>
          <DialogContent>
            Easily cash out on this and many other cash prices by signing up for the AgriChain platform today!
            Get access to 12+ modules including stock management, contracting, order management that are trusted by over 300 growers, traders, brokers and BHCs to improve efficiency and profits across businesses.
          </DialogContent>
          <DialogActions>
            <Button color='default' onClick={() => this.setState({welcomeDialog: false})}>
              {"Don't Cash Out"}
            </Button>
            <Button style={{marginLeft: '20px'}} color='primary' href='#/sign-up' variant='contained'>
              Sign Up Now
            </Button>
          </DialogActions>

        </Dialog>
      </Paper>
    );
  }
}
const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    tracks: state.main.tracks,
    allCompanyParties: state.companies.companies.companyParties,
    selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
    resetAutoScrollPosition: state.companies.cashBoard.resetAutoScrollPosition,
  };
};

const mapDispatchToProps = dispatch => ({
  getTracks: () => dispatch(getTracks()),
  getCashPricesUnregisteredUser: (queryParams) => dispatch(getCashPricesUnregisteredUser(queryParams)),
  setCashPricesResponse: () => dispatch(cashPricesResponse([])),
  isLoading: (component) => dispatch(isLoading(component)),
  forceStopLoader: () => dispatch(forceStopLoader()),
  saveFiltersForUnregisteredUser: (filters) => dispatch(saveFiltersForUnregisteredUser(filters)),
  resetAutoScrollPositionMethod: (flag) => dispatch(resetAutoScrollPosition(flag)),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(WithoutLoginCashBoard);

import {
  START_FETCHING_COMMODITIES,
  START_FETCHING_COMMODITIES_WITH_VARIETIES,
  START_FETCHING_COMMODITIES_WITH_GRADES,
  START_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES,
  RESET_IS_FETCHING_COMMODITIES,
  RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES,
  RESET_IS_FETCHING_COMMODITIES_WITH_GRADES,
  RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES,
  RECEIVE_COMMODITIES,
  RECEIVE_COMMODITIES_WITH_VARIETIES,
  RECEIVE_COMMODITIES_WITH_GRADES,
  RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES,
} from '../../actions/master/commodities';

const initialState = {
  isFetchingCommodities: false,
  isFetchingCommoditiesWithVarieties: false,
  isFetchingCommoditiesWithGrades: false,
  isFetchingCommoditiesWithVarietiesAndGrades: false,
  items: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case START_FETCHING_COMMODITIES:
      return { ...state, isFetching: true };
    case START_FETCHING_COMMODITIES_WITH_VARIETIES:
      return { ...state, isFetchingCommoditiesWithVarieties: true };
    case START_FETCHING_COMMODITIES_WITH_GRADES:
      return { ...state, isFetchingCommoditiesWithGrades: true };
    case START_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES:
      return { ...state, isFetchingCommoditiesWithVarietiesAndGrades: true };
    case RESET_IS_FETCHING_COMMODITIES:
      return { ...state, isFetchingCommodities: false };
    case RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES:
      return { ...state, isFetchingCommoditiesWithVarieties: false };
    case RESET_IS_FETCHING_COMMODITIES_WITH_GRADES:
      return { ...state, isFetchingCommoditiesWithGrades: false };
    case RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES:
      return { ...state, isFetchingCommoditiesWithVarietiesAndGrades: false };
    case RECEIVE_COMMODITIES:
      return { ...state, items: action.items, isFetchingCommodities: false };
    case RECEIVE_COMMODITIES_WITH_VARIETIES:
      return {
        ...state,
        items: action.items,
        isFetchingCommoditiesWithVarieties: false,
      };
    case RECEIVE_COMMODITIES_WITH_GRADES:
      return {
        ...state,
        items: action.items,
        isFetchingCommoditiesWithGrades: false,
      };
    case RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES:
      return {
        ...state,
        items: action.items,
        isFetchingCommoditiesWithVarietiesAndGrades: false,
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';
import CommonAutoSelect from '../../common/autocomplete/CommonAutoSelect';
import CommonTextField from '../../common/CommonTextField';
import { connect } from 'react-redux';

import find from 'lodash/find';
import map from 'lodash/map';
import merge from 'lodash/merge';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import identity from 'lodash/identity';
import filter from 'lodash/filter';
import APIService from '../../../services/APIService';
import AutoComplete from '../../common/autocomplete/AutoComplete';
import get from 'lodash/get';
import CommonButton from '../../common/CommonButton';
import InputAdornment from '@mui/material/InputAdornment';
import { positiveDecimalFilter } from '../../../common/input-filters';
import some from 'lodash/some';
import '@babel/polyfill';
import { required } from '../../../common/validators';
import forEach from "lodash/forEach";

const COMMISSION_ERROR = 'Cannot be smaller than Freight Rate Out';

class AssignContractToMovementForm extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      fields: {
        baseEntityId: {
          value: undefined,
          errors: [],
          validators: []
        },
        customerNgrId: {
          value: undefined,
          errors: [],
          validators: []
        },
        rateFreightIn: {
          value: undefined,
          errors: [],
          validators: []
        },
      },
      selectedBaseEntity: undefined,
      filteredEntities: [],
      contracts: [],
      orders: [],
      customerNgrs: [],
      commissionRate: 0,
      sites: []
    };

    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.handleCustomerNgrChange = this.handleCustomerNgrChange.bind(this);
    this.handleFreightRateChange = this.handleFreightRateChange.bind(this);
    this.handleBaseEntityChange = this.handleBaseEntityChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
  }

  componentDidMount() {
    this.getContractsList();
    this.getOrdersList();
    if (this.props.assignExistingFmToOrder) {
      const newState = { ...this.state };
      newState.fields.baseEntityId.validators = [required()];
      this.setState(newState);
    } else if (get(this.props, 'movement.customer.companyId')) {
      this.getNgrs(
          get(this.props, 'movement.customer.companyId'),
          'customerNgrs'
      );
    }
  }

  handleBaseEntityChange(value, id, item){
    const newState = {...this.state};
    let baseEntity;
    if(get(item, 'entity') === 'contract') {
      baseEntity = find(this.state.contracts, {'id': value});
    } else {
      baseEntity = find(this.state.orders, {'id': value});
    }
    newState.selectedBaseEntity = baseEntity;
    newState.fields.baseEntityId.value = value;
    this.setState(newState, () => {
      this.setAllFieldsErrors();
    });
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  handleFreightRateChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
    setTimeout(() => {
      this.calculateCommissionRate();
    }, 100);
  }

  calculateCommissionRate(){
    const newState = {...this.state};
    var commissionRate = 0;

    const rateFreightIn = newState.fields.rateFreightIn.value;
    const rateFreightOut = this.props.movement.rateFreightOut;

    if(rateFreightIn && rateFreightOut){
      commissionRate = parseFloat(rateFreightIn) - parseFloat(rateFreightOut);
      if(commissionRate < 0) {
        newState.fields.rateFreightIn.errors.push(COMMISSION_ERROR);
        commissionRate = 0;
      }
      else {
        newState.fields.rateFreightIn.errors = [];
      }
    }

    newState.commissionRate = commissionRate.toFixed(2);
    this.setState(newState);
  }

  handleSubmit(e) {
    this.setAllFieldsErrors();
    const isFormInvalid = some(this.state.fields, (field) => {
      return field.errors.length > 0;
    });
    const data = mapValues(this.state.fields, (field) => {
      return field.value;
    });
    if (!isFormInvalid) {
      delete data.baseEntityId;
      data.baseEntity = this.state.selectedBaseEntity;
      const submitData = pickBy(data, identity);
      this.props.handleAccept(submitData);
    }
    e.preventDefault();
  }

  async getContractsList() {
    let {movement} = this.props;
    let movementsService = APIService.freights().contracts(movement.id);
    movementsService.appendToUrl('contracts/');
    movementsService.get(this.props.token, {}, null)
      .then(items => this.setState({contracts: items, filteredEntities: this.state.filteredEntities.concat(items)}));
  }

  getOrdersList() {
    let {movement} = this.props;
    let ordersService = APIService.freights().contracts(movement.id);
    ordersService.appendToUrl('orders/');
    ordersService.get(this.props.token, {}, null)
      .then(items => {
        const filteredOrders = filter(items, 'canAssignFreightOrder');
        const orders = map(filteredOrders, item => merge(item, {referenceNumber: item.identifier, entity: 'order' }));
        this.setState({orders: orders, filteredEntities: this.state.filteredEntities.concat(orders)});
      });
  }

  getNgrs(partyId, fieldName) {
    APIService.companies(partyId).ngrs().appendToUrl('minimal/').get(this.props.token)
      .then(items => this.setState({[fieldName]: items}));
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });

    this.setState(newState);
  }

  handleCustomerNgrChange(id) {
    this.setFieldValue('customerNgrId', id);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  render(){
    return (
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm cardForm--drawer" id='assign-to-contract-or-freight-order'>
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonAutoSelect
                  items={this.state.filteredEntities}
                  id="baseEntityId"
                  dataSourceConfig={{text: 'displayName', value: 'id'}}
                  label={this.props.assignExistingFmToOrder ? "Contract/Order Number" : "Contract/Order Number (Optional)"}
                  value={this.state.fields.baseEntityId.value}
                  style={{float: 'left'}}
                  onChange={this.handleBaseEntityChange}
                  errorText={this.state.fields.baseEntityId.errors}
                  dontAutoselectSingleItem/>
              </div>

              {!this.props.assignExistingFmToOrder && <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="customerName"
                  label="Customer Name"
                  value={get(this.props, 'movement.customer.company.displayName')}
                  disabled
                />
              </div>}

              {!this.props.assignExistingFmToOrder && <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="customerContactName"
                  label="Customer Contact"
                  value={get(this.props, 'movement.customer.contact.name')}
                  disabled
                />
              </div>}

              { !this.props.assignExistingFmToOrder ?
                get(this.props, 'movement.customer.ngrId') ?
                  <div className="col-sm-12 form-wrap-70">
                    <CommonTextField
                      id="customerNgrId"
                      label="Customer NGR"
                      value={this.props.movement.customer.ngr.ngrNumber}
                      disabled
                    />
                  </div>
                  :
                  <div className="col-sm-12 form-wrap-70">
                    <AutoComplete
                      id="customerNgrId"
                      label="Customer NGR"
                      placeholder="Customer NGR"
                      options={this.state.customerNgrs.map(s => ({label: s.ngrNumber, value: s.id}))}
                      fullWidth
                      onBlur={this.onFieldBlur}
                      onChange={this.handleCustomerNgrChange}
                      errorText={this.state.fields.customerNgrId.errors[0] || get(this.state.serverErrors,'customerNgrId.0')}
                      value={this.state.fields.customerNgrId.value}
                    />
                  </div> : null
              }

              {this.state.commissionRate > 0 && !this.props.assignExistingFmToOrder && <div className="col-sm-12 form-wrap">
                <CommonTextField
                  id="rateFreightIn"
                  label="Freight Rate In (Optional)"
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, 10000)}
                  helperText={ this.state.fields.rateFreightIn.errors[0] }
                  value={this.state.fields.rateFreightIn.value}
                  onChange={this.handleFreightRateChange}
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>$</InputAdornment>
                  }}
                />
              </div>}

              {!this.props.assignExistingFmToOrder && <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="rateFreightOut"
                  label={ this.state.commissionRate > 0 ?  "Freight Rate Out" : "Freight Rate"}
                  value={this.props.movement.rateFreightOut}
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>$</InputAdornment>
                  }}
                />
              </div>}

              {
                this.state.fields.commissionRate > 0 && !this.props.assignExistingFmToOrder &&
                <div className="col-sm-12 form-wrap-70">
                  <CommonTextField
                    id="commission"
                    label="Commission Rate"
                    value={this.state.commissionRate}
                    disabled
                    InputProps={{
                      startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>$</InputAdornment>
                    }}
                  />
              </div>
              }

              {!this.props.assignExistingFmToOrder && <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="invoicing"
                  label="Invoicing"
                  value={this.props.movement.invoicing}
                  disabled
                />
              </div>}

            </div>

            <div className="col-sm-12 cardForm-action top15 padding-reset">
              <CommonButton
                type="button"
                variant="outlined"
                label="Cancel"
                default
                onClick={this.props.handleCancel}
              />
              <CommonButton
                primary={true}
                variant="contained"
                label={this.props.assignExistingFmToOrder ? "Submit" : "Accept"}
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  };
};

export default connect(mapStateToProps)(AssignContractToMovementForm);

import React from 'react'
import { connect } from 'react-redux'
import { includes } from 'lodash';
import { Tab, Tabs } from '@mui/material';
import TemplatesHome from './TemplatesHome';

class Templates extends React.Component {

  constructor(props) {
    super(props);
    this.CONTRACT_TEMPLATE_URL = '/profiles/templates/contract';
    this.ORDER_TEMPLATE_URL = '/profiles/templates/freightorder';

    this.uploadForm = React.createRef();
    this.state = {
      value: '#' + this.props.location.pathname,
      type: (this.props.location.pathname === this.CONTRACT_TEMPLATE_URL) ? 'contract' : 'freightorder'
    };

  }

  getParentURL() {
    let url = this.props.location.pathname;
    if (includes(this.props.location.pathname, this.CONTRACT_TEMPLATE_URL))
      return url.split(this.CONTRACT_TEMPLATE_URL)[0];
    if (includes(this.props.location.pathname, this.ORDER_TEMPLATE_URL))
      return url.split(this.ORDER_TEMPLATE_URL)[0];
  }

  getURL(pathname) {
    const parentURL = this.getParentURL();
    return '#' + parentURL + pathname;
  }

  tabChanges = (event, value, type) => {
    window.location.hash = window.location.hash.split('?')[0];
    this.setState({ value, type });
  };

  render() {
    const { value, type } = this.state;
    const contractTemplateURL = this.getURL(this.CONTRACT_TEMPLATE_URL);
    const orderTemplateURL = this.getURL(this.ORDER_TEMPLATE_URL);
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} type={type} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={contractTemplateURL} type='contract' href={contractTemplateURL} label="Contract Templates" className={value !== contractTemplateURL ? 'unselected-subtab' : ''} />
          <Tab value={orderTemplateURL} type='freightorder' href={orderTemplateURL} label="Freight Order Templates" className={value !== orderTemplateURL ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          (value === contractTemplateURL) && <div style={{ position: 'relative' }}>
            <TemplatesHome
              type='contract'
              {...this.props}
            />
          </div>
        }
        {
          (value === orderTemplateURL) && <div style={{ position: 'relative' }}>
            <TemplatesHome
              type='freightorder'
              {...this.props}
            />
          </div>
        }
      </div>

    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    allItems: state.companySettings.employees.templates,
    count: state.companySettings.employees.templates || 0,
  };
};

export default connect(mapStateToProps)(Templates);

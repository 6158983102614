import React, { Component } from 'react';

import { connect } from 'react-redux';
import AddButton from '../common/AddButton';
import Paper from '@mui/material/Paper';
import SideDrawer from '../common/SideDrawer';
import {
    isInMyCompanyContext, hasQueryString, isInCompanyContext, isCompanyEditable, isCurrentUserBroker, defaultViewAction
} from '../../common/utils';
import { getCompanyFarmsWeb, getCompanyFarms } from '../../actions/api/farms';
import { getCompanyDetails, showSideDrawerForTransfer, showSideDrawerForMerge } from '../../actions/companies';
import APIService from "../../services/APIService";
import { get, cloneDeep, find } from 'lodash';
import alertifyjs from 'alertifyjs';
import TransferToOtherCompany from '../../containers/TransferToOtherCompany';
import FarmsTable from '../../containers/FarmsTable';
import CreateFarm from '../../containers/CreateFarm';
import {
    clickAddFarmButton,
    hideCreateFarmForm,
} from '../../actions/companies/farms';
import { receivePaginatedFarms, receiveFarms } from '../../actions/companies/farms';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import CommonListingButton from '../common/CommonListingButton';


class FarmListing extends Component {
    constructor(props) {
        super(props);
        this.csvLink = React.createRef();
        this.state = {
            openSideDrawer: false,
            openMergeSideDrawer: false,
            mergeToFarm: {
                value: undefined,
                errors: [],
                validators: []
            }
        };
        this.handleAddFarmButtonClick = this.handleAddFarmButtonClick.bind(this);
        this.onCloseSideDraw = this.onCloseSideDraw.bind(this);
        this.handleFarmChange = this.handleFarmChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
    }

    componentDidMount() {
        this.props.cancelMergeDrawer;
        if (!hasQueryString() || window.location.hash.split('?')[1] === 'farms')
            this.props.getFarms(this.props.companyId, !this.props.isArchived);
    }

    componentWillUnmount() {
        this.props.hideCreateFarmForm();
        this.props.cleanFarms();
        this.props.cancelTransferDrawer(null, false);
        this.props.cancelMergeDrawer(null, false);
    }

    handleAddFarmButtonClick() {
        this.props.handleAddFarmButtonClick();
        this.setState({
            openSideDrawer: true,
        });
    }

    onCloseSideDraw() {
        this.setState({
            openSideDrawer: false,
        });
    }

    getFarms = () => {
        let farms = cloneDeep(this.props.companyFarms);
        farms.splice(farms.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
        return farms;
    }

    getLabel = () => {
        let fromFarm = get(this.props, 'selectedAsset.name');
        let toFarm = "";
        if (this.state.mergeToFarm.value) {
          toFarm = get(find(this.props.companyFarms, {id: this.state.mergeToFarm.value}), 'name');
        }
        return "Merge " + fromFarm + " into " + toFarm;
    }

    handleFarmChange(value) {
        const newState = {...this.state};
        newState.mergeToFarm.value = value;
        newState.mergeToFarm.errors = [];
        this.setState(newState);
    }

    closeMergeDrawer() {
        const newState = {...this.state};
        newState.mergeToFarm.value = undefined;
        this.setState(newState);
        this.props.cancelMergeDrawer();
    }

    handleSubmit() {
        let data = {};
        data['mergeFrom'] = get(this.props.selectedAsset, 'id');
        if (data['mergeFrom']) {
        APIService.farms()
            .appendToUrl(`${this.state.mergeToFarm.value}/merge/`)
            .post(data)
            .then(res => {
            if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
            else alertifyjs.success('Farm Merged!', 3, () => window.location.reload())
            });
        }
    }

    getActionsOptionMapperListItems() {
      return [defaultViewAction];
    }

    render() {
        let isEditable = isCompanyEditable(this.props.selectedCompany) || isInMyCompanyContext() || (!this.props.companyId && isCurrentUserBroker())
        return (
            <Paper className="paper-table-paginated">
                <div>
                    <div style={{ float: 'right', marginLeft: '10px' }}>
                        {
                            isEditable ?
                                <AddButton
                                    label="Farm"
                                    onClick={this.handleAddFarmButtonClick}
                                    app="farm" style={{ marginTop: '0px' }}
                                    tooltipTitle="Add a new farm to your directory"
                                />
                                : null
                        }
                    </div>
                    <div style={{float: 'right'}}>
              <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
            </div>

                    <FarmsTable isArchived={this.props.isArchived} />
                   
                </div>
                {this.props.isCreateFormDisplayed &&
                    <SideDrawer
                        isOpen={this.state.openSideDrawer}
                        title="Add Farm"
                        size="small"
                        onClose={this.onCloseSideDraw}
                        app="farm"
                    >
                      <CreateFarm closeDrawer={this.onCloseSideDraw} showHeading={true} />
                    </SideDrawer>
                }
                {
                    <SideDrawer
                        isOpen={this.props.openSideDrawer}
                        title="Transfer"
                        onClose={this.props.cancelTransferDrawer}
                        app='office'
                    >
                        <TransferToOtherCompany />
                    </SideDrawer>

                }
                {this.props.openMergeSideDrawer &&
                  <SideDrawer
                    isOpen={this.props.openMergeSideDrawer}
                    title="Merge"
                    onClose={this.closeMergeDrawer}
                  >
                    <div style={{ paddingTop: "15px"}}>
                      <CommonAutoSelect
                        id='mergeToNgr'
                        items={this.getFarms()}
                        label={this.getLabel()}
                        value={this.state.mergeToFarm.value}
                        onChange={this.handleFarmChange}
                      />
                    </div>
                    {
                      <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
                        <CommonButton
                          type="button"
                          label="Cancel"
                          default
                          onClick={this.closeMergeDrawer}
                          variant="contained"
                        />
                        <CommonButton
                          type="submit"
                          label="Merge"
                          primary
                          onClick={this.handleSubmit}
                          variant="contained"
                        />
                      </div>
                    }
                  </SideDrawer>
                }
            </Paper>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        openSideDrawer: state.companies.companies.showTransferSideDrawer,
        openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
        isCreateFormDisplayed: state.companies.farms.isCreateFormDisplayed,
        headerText: state.main.headerText,
        selectedCompany: state.companies.companies.selectedCompany,
        breadcrumbs: state.main.breadcrumbs,
        selectedCompanyId: state.main.user.user.companyId,
        token: state.main.user.token,
        companyFarms: get(state.companies.farms, 'items'),
        count: get(state.companies.farms, 'paginationData.count') || get((get(state.companies.farms, 'items') || []), 'length') || 0,
        selectedAsset: state.companies.companies.selectedAsset
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
        cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
        cleanFarms: () => dispatch(receivePaginatedFarms({ results: [], paginationData: {} })),
        getFarms: (companyId, isActive) => dispatch(getFarms(companyId, isActive)),
        getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
        handleAddFarmButtonClick: () => dispatch(clickAddFarmButton()),
        hideCreateFarmForm: () => dispatch(hideCreateFarmForm()),
    };
};

function getFarms(selectedCompanyId, isActive) {
    return (dispatch, getState) => {
        const companyId = selectedCompanyId || getState().main.user.user.companyId;
        isInCompanyContext() ? dispatch(getCompanyFarms(companyId, receiveFarms, true, null, isActive)) : dispatch(getCompanyFarmsWeb(companyId, null, false, true, isActive));
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmListing);

import React from 'react';

export default () => (
  <div style={
    {
      float: 'left',
      height: '100%',
      display: 'flex',
      padding: '5px',
      borderRight: '2px solid rgb(166,166,166)',
    }
  }>
    <select
      id="searchTerm"
      style={
        {
          backgroundColor: 'rgb(9,29,47)',
          color: 'rgb(166,166,166)',
          border: 'none',
          fontSize: '12px',
          cursor: 'pointer',
        }
      }>
      <option value="company">Companies</option>
      <option value="employee">Employees</option>
      <option value="truck">Trucks</option>
      <option value="ngr">NGRs</option>
      <option value="farm">Farms/Sites</option>
      <option value="contract">Contracts</option>
      <option value="order">Orders</option>
      <option value="movement">Movements</option>
      <option value="invoice">Invoices</option>
      <option value="vendor_dec">Vendor Decs</option>
    </select>
  </div>

);

import React from 'react';
import { Autocomplete, TextField, Checkbox } from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { filter, includes } from 'lodash';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const MonthsSelector = ({selected, ...rest}) => {
  return (
    <Autocomplete
      multiple
      limitTags={3}
      size='small'
      id='months-selector'
      disableCloseOnSelect
      getOptionLabel={option => option.title}
      value={filter(rest.options, option => includes(selected || [], option.value))}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.title}
        </li>
      )}
      renderInput={params => (<TextField style={{height: '40px'}} fullWidth {...params} label="Months" />)}
      {...rest}
    />
  );
}

export default MonthsSelector;

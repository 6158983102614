export const START_FETCHING_COMMODITIES = 'START_FETCHING_COMMODITIES';
export const START_FETCHING_COMMODITIES_WITH_VARIETIES = 'START_FETCHING_COMMODITIES_WITH_VARIETIES';
export const START_FETCHING_COMMODITIES_WITH_GRADES = 'START_FETCHING_COMMODITIES_WITH_GRADES';
export const START_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES = 'START_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES';
export const RESET_IS_FETCHING_COMMODITIES = 'RESET_IS_FETCHING_COMMODITIES';
export const RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES = 'RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES';
export const RESET_IS_FETCHING_COMMODITIES_WITH_GRADES = 'RESET_IS_FETCHING_COMMODITIES_WITH_GRADES';
export const RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES = 'RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES';
export const RECEIVE_COMMODITIES = 'RECEIVE_COMMODITIES';
export const RECEIVE_COMMODITIES_WITH_VARIETIES = 'RECEIVE_COMMODITIES_WITH_VARIETIES';
export const RECEIVE_COMMODITIES_WITH_GRADES = 'RECEIVE_COMMODITIES_WITH_GRADES';
export const RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES = 'RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES';
export const RECEIVE_GRADES = 'RECEIVE_GRADES';

export const startFetchingCommodities = () => ({
  type: START_FETCHING_COMMODITIES,
});

export const startFetchingCommoditiesWithVarieties = () => ({
  type: START_FETCHING_COMMODITIES_WITH_VARIETIES,
});

export const startFetchingCommoditiesWithGrades = () => ({
  type: START_FETCHING_COMMODITIES_WITH_GRADES,
});

export const startFetchingCommoditiesWithVarietiesAndGrades = () => ({
  type: START_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES,
});

export const resetIsFetchingCommodities = () => ({
  type: RESET_IS_FETCHING_COMMODITIES,
});

export const resetIsFetchingCommoditiesWithVarieties = () => ({
  type: RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES,
});

export const resetIsFetchingCommoditiesWithGrades = () => ({
  type: RESET_IS_FETCHING_COMMODITIES_WITH_GRADES,
});

export const resetIsFetchingCommoditiesWithVarietiesAndGrades = () => ({
  type: RESET_IS_FETCHING_COMMODITIES_WITH_VARIETIES_AND_GRADES,
});

export const receiveCommodities = items => ({
  type: RECEIVE_COMMODITIES,
  items,
});

export const receiveCommoditiesWithVarieties = items => ({
  type: RECEIVE_COMMODITIES_WITH_VARIETIES,
  items,
});

export const receiveCommoditiesWithGrades = items => ({
  type: RECEIVE_COMMODITIES_WITH_GRADES,
  items,
});

export const receiveCommoditiesWithVarietiesAndGrades = items => ({
  type: RECEIVE_COMMODITIES_WITH_VARIETIES_AND_GRADES,
  items,
});

export const receiveGrades = items => ({
  type: RECEIVE_GRADES,
  items,
});

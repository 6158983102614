import React from 'react';


import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import get from "lodash/get";
import find from "lodash/find";
import {REJECT_LOAD_OTHER_ID} from "./constants";

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
      style={{fill: '#112c42', height: '20px', width: '20px'}}
    />);
  }
  return (<KeyboardArrowDown style={{fill: '#112c42', height: '20px', width: '20px'}}/>);
};

export class RejectedLoadReasonDetails extends React.Component {
  toggleExpanded = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  render() {
    const {movement} = this.props;
    const {expanded} = this.state;
    const rejectionReason = get(movement, 'rejectionReasonDisplay.rejectionReason');
    const reasons = get(movement, 'rejectedLoadDisplayDetails.reasons');
    const hasSelectedOtherReason = find(reasons, {id: REJECT_LOAD_OTHER_ID});
    return (
      movement &&
      <Paper className={this.props.className ? this.props.className : "contract-details-section-container"}>
        <h2 onClick={this.toggleExpanded}>
          Reason for Load Rejection
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div style={{marginTop: '20px', whiteSpace: 'pre-wrap'}}>
            <ul>
              {map(reasons, (reason, index) => {
                return <li
                  key={index}>{reason.id == REJECT_LOAD_OTHER_ID ? reason.name + ' Reason: ' + rejectionReason : reason.name}</li>;
              })}
              {!hasSelectedOtherReason && rejectionReason && <li>Other Reason: {rejectionReason}</li>}
            </ul>
            {
              !isEmpty(movement.rejectedLoadDisplayDetails.attachments) && <div>
                Attachments
                <ul>
                  {map(movement.rejectedLoadDisplayDetails.attachments, (attachment, index) => {
                    return <li key={index}><a href={attachment.fullPath} download>{attachment.name}</a></li>;
                  })}
                </ul>
              </div>
            }
          </div>
        )}
      </Paper>
    );
  }
}

import React from 'react';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import withStyles from '@mui/styles/withStyles';
import {merge} from 'lodash';

export const DialogTitleWithCloseIcon = withStyles(() => ({
  root: {
    borderBottom: `1px solid rgba(0, 0, 0, 0.12)`,
    margin: 0,
    padding: '16px'
  },
  closeButton: {
    position: 'absolute',
    right: '8px',
    top: '8px',
    color: '#9e9e9e',
  },
}))(props => {
  const { children, onClose, className, style, closeButtonStyle } = props;
  return (
    <MuiDialogTitle sx={{ m: 0, p: 2 }} className={className} style={style || {}}>
      {children}
      {onClose ? (
         <IconButton
           aria-label="Close"
           style={merge({marginTop: '-5px'}, (closeButtonStyle || {}))}
           onClick={onClose}
           sx={{
             position: 'absolute',
             right: 8,
             top: 8,
           }}
           size="large">
           <CloseIcon />
         </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});


import React, { Component } from 'react';
import { connect } from 'react-redux';
import { required } from '../../common/validators';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import {
  reject,
  receiveContract,
  getSelectedContract,
} from '../../actions/companies/contracts';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';

class RejectContractForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    if(this.props.match && this.props.match.params.contract_id) {
      const {dispatch} = this.props;
      if(isEmpty(this.props.contract)){
        dispatch(getSelectedContract(this.props.match.params.contract_id, receiveContract, false, this.props.match.params.salt));
      }
    }
  }

  handleSubmit(event) {
    this.setErrors();

    if (this.getIsFormValid()) {
      const data = { rejectionReason: this.state.rejectionReason.value };
      const salt = this.props.match ? this.props.match.params.salt : null;
      const { dispatch } = this.props;
      dispatch(reject(this.props.contract.id, data, salt));
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return this.state.rejectionReason.errors.length == 0;
  }

  canReject() {
    if(this.props.contract && this.props.contract.confirmable) {
      if(isEmpty(this.props.rejectedContract)) {
        return this.props.contract.id && this.props.contract.status === 'planned';
      } else {
        return this.props.rejectedContract.status !== 'rejected';
      }
    }
  }

  isRejected() {
    const isRejected = this.props.rejectedContract && this.props.rejectedContract.status === 'rejected';
    if(isRejected) {
      const salt = this.props.match ? this.props.match.params.salt : null;
      if(!salt) {
        const url = '/contracts/' + (this.props.contract ? this.props.contract.id : '');
        document.location.hash = url;
      }
    }
    return isRejected;
  }

  render() {
    return (
      <div>
        { this.props.contract ?
          <form onSubmit={this.handleSubmit} noValidate>
            <Card style={{ padding: 20, margin: '10% auto', width: '50%'}}>
              { this.canReject() ?
                (
                  <div>
                    <CardHeader
                      title="Reject Contract"
                    />
                    <CardContent>
                      <div className="row">
                        <div className="col-xs-12">
                          <CommonTextField
                            id="rejectionReason"
                            floatingLabelText="Reason"
                            hintText="Please enter"
                            value={this.state.rejectionReason.value}
                            onChange={this.handleChange}
                            errorText={get(this.state, 'rejectionReason.errors[0]', '')}
                            errorStyle={{textAlign: "left"}}
                            fullWidth
                            maxLength="255"
                          />
                        </div>
                      </div>
                    </CardContent>
                    <CardActions>
                      <div className="row">
                        <div className="col-xs-12">
                          <CommonButton
                            type="submit"
                            label="Submit"
                            primary={true}
                            variant="contained"
                          />
                        </div>
                      </div>
                    </CardActions>
                  </div>
                )
                : ( this.isRejected() ?
                    <div>
                      <CardHeader
                        subtitle="Contract is Rejected"
                      />
                    </div>
                :
                    <div>
                      <CardHeader
                        subtitle="You do not have permission to access the information."
                      />
                    </div>
                )
              }
            </Card>
          </form>
          : null
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contract: state.companies.contracts.selectedContract,
    rejectedContract: state.companies.contracts.confirmedOrRejectedContract
  };
};

export default connect(mapStateToProps)(RejectContractForm);

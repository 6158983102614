import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import '../common/subTab.scss';
import { get, isEmpty, uniqBy, set, mapValues, filter, includes, forEach,
  some, map, without, compact, isNumber, values, sum, find } from 'lodash';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import { required, valueAbove } from '../../common/validators';
import { generateIdentifier, isCompanyGrower } from '../../common/utils';
import TextField from '@mui/material/TextField';
import APIService from '../../services/APIService';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import InputAdornment from '@mui/material/InputAdornment';
import { COMMODITIES, BROKER, GROWER, MT_UNIT } from '../../common/constants';
import { getAutoSelectFocusField, getCountryCurrency } from '../../common/utils';
import { positiveDecimalFilter } from '../../common/input-filters';
import { ArrowBack as BackIcon } from '@mui/icons-material';
import GenericTable from '../GenericTable';
import { getLoadReferences, getSpecColumns, loadReferencesDisplay } from '../stocks/utils';
import { Button } from '@mui/material';
import isEqual from 'lodash/isEqual';
import { FLAT_PAYMENT_SCALE } from './constants';
import { setSelectedCashPrice } from '../../actions/companies/cash-board';
import LoaderInline from '../LoaderInline';

class CashStockForm extends React.Component {
    constructor(props) {
        super(props);
        this.EXCESS_TONNAGE_ERROR = 'Not enough stocks at the site.';
        this.EXCESS_REMAINING_LIMIT_ERROR = 'Remaining Limit of Cash out is lesser than selected tonnage'
        this.identifierGenerated = generateIdentifier('title_transfer');
        this.state = {
          handlers: [],
          isLoadingInitialData: false,
          allCompanyParties: [],
            settings: null,
            sellerContacts: [],
            sellerCompanies: [],
            sellerNgrs: [],
            totalSale: 0,
            isLoadingCanolaLoads: false,
            openCanolaLoads: false,
            fetchedCanolaLoads: false,
            selectedCanolaLoads: [],
            canolaLoads: [],
            sellerTonnageCashPrice: 0,
            selectedCashPrice: {},
            fields: {
                identifier: {
                    value: this.identifierGenerated,
                    validators: [required()],
                    errors: [],
                },
                seller: {
                    contactId: {
                        value: '',
                        validators: [required()],
                        errors: [],
                    },
                    companyId: {
                        value: '',
                        validators: [required()],
                        errors: [],
                    },
                    ngrId: {
                        value: '',
                        validators: [required()],
                        errors: [],
                    },
                },
                varietyId: {
                    value: '',
                    validators: [],
                    errors: [],
                },
                tonnage: {
                    value: null,
                    validators: [required(), valueAbove(0)],
                    errors: [],
                },
                coil: {
                  value: null,
                  validators: [],
                  errors: [],
                },
                impu: {
                  value: null,
                  validators: [],
                  errors: [],
                },
            }
        };
        this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
        this.handleValueChange = this.handleValueChange.bind(this);
        this.getContactNgr = this.getContactNgr.bind(this);
        this.handleVarietyChange = this.handleVarietyChange.bind(this);
        this.handleTonnageFieldChange = this.handleTonnageFieldChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getSellerCompanies = this.getSellerCompanies.bind(this);
        this.setFieldErrors = this.setFieldErrors.bind(this);
        this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
        this.getFieldErrors = this.getFieldErrors.bind(this);
        this.onFieldBlur = this.onFieldBlur.bind(this);
        this.handleFieldChange = this.handleFieldChange.bind(this);
        this.fieldsOrder = [
            'identifier', 'seller.companyId', 'seller.contactId',
            'seller.ngrId', 'tonnage'
        ];
        this.fieldRef = {};
        this.fieldsOrder.forEach(e => (this.fieldRef[e] = React.createRef()));
    }

  isVarietyMandatory = () => {
    const handlerId = this.props.selectedCashPrice?.siteId
    if(handlerId) {
      const site = find(this.state.handlers, { id: handlerId })
      if(!site?.id)
        return false
      const sellerCompany = find(this.state.allCompanyParties, {id: this.state.fields.seller.companyId.value})
      const { isVarietyMandatory, loadTypeForVarietyMandatory, userTypeForVarietyMandatory } = site
      if(isVarietyMandatory) {
        let isGrower = sellerCompany && isCompanyGrower(sellerCompany)
        let isGrowerType = isGrower && userTypeForVarietyMandatory && ['growers'].some(type => userTypeForVarietyMandatory.includes(type))
        let isNonGrowerType = !isGrower && userTypeForVarietyMandatory && ['non_growers'].some(type => userTypeForVarietyMandatory.includes(type))
        let isLoadTypeForVarietyMandatory = loadTypeForVarietyMandatory && ['title_transfers_and_cash_outs'].some(type => loadTypeForVarietyMandatory.includes(type))
        return isVarietyMandatory && isLoadTypeForVarietyMandatory && (isGrowerType || isNonGrowerType)
      }
    }
    return false
  }

  setVarietyMandate = () => {
    const isVarietyMandatory = this.isVarietyMandatory()
    if(isVarietyMandatory && !this.state.fields.varietyId.validators.length) {
      const newState = {...this.state}
      newState.fields.varietyId.validators = [required()]
      this.setState(newState)
    } else if (!isVarietyMandatory && this.state.fields.varietyId.validators.length) {
      const newState = {...this.state}
      newState.fields.varietyId.validators = [required()]
      newState.fields.varietyId.validators = []
      this.setState(newState)
    }
  }

  componentDidMount() {
    this.fetchInitialData()
  }

  componentDidUpdate(prevProps) {
    if(!isEqual(prevProps.selectedCashPrice, this.props.selectedCashPrice)) {
      this.fetchSettings();
    }
    if(!isEqual(this.state.selectedCashPrice, this.props.selectedCashPrice)){
      this.setVarietyMandate();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(setSelectedCashPrice([]))
  }

  fetchInitialData = () => {
    this.setState({isLoadingInitialData: true}, () => {
      APIService
        .companies(this.props.user.companyId)
        .appendToUrl('companies/minimal/?include_parent_company=true')
        .get()
        .then(response => this.setState({allCompanyParties: response}, () => {
          APIService.farms(this.props.selectedCashPrice?.siteId).get().then(site => {this.setState({handlers: uniqBy([...this.state.handlers, site], 'id')}, ()=>this.getSellerCompanies())})
          this.fetchSettings()
        }))
    })
  }

    onFieldBlur(event) {
      this.setFieldErrors(event.target.id);
    }

    handleIdentifierChange = event => {
        const regex = new RegExp('^[a-zA-Z0-9]*$');
        if (regex.test(event.target.value)) {
            this.handleValueChange(event.target.value, event.target.id);
        } else {
            this.handleValueChange(this.state.fields.identifier.value, event.target.id);
        }
    };

    handleValueChange(value, id) {
        const newState = { ...this.state };
        set(newState.fields, `${id}.value`, value);
        let seller = id === 'seller.companyId';
        if (id == 'seller.ngrId')
            this.getSellerAvailableTonnage(value);
        if (seller) {
            set(newState, 'sellerContacts', []);
            set(newState, 'sellerNgrs', []);
            set(newState.fields, 'seller.ngrId.value', null);
            if (value)
                this.getContactNgr(value);
            if (newState.fields.seller.companyId.value === this.props.user.companyId || this.props.user.company.typeId === BROKER)
                set(newState.fields, 'seller.contactId.value', this.props.user.id);
            else
                set(newState.fields, 'seller.contactId.value', null);
        };
        this.setState(newState, () => {
           this.setFieldErrors(id);
           if (id === 'seller.companyId') {
               this.fetchSettings();
           }
        });
    }

    getSellerAvailableTonnage(value) {
        if (value) {
            APIService.ngrs(value).appendToUrl(`cash_price/${this.props.selectedCashPrice.id}`)
                .get().then(tonnage => {
                    this.setState({
                        sellerTonnageCashPrice: tonnage.totalTonnage
                    });
                });
        }
        else
            this.setState({
                sellerTonnageCashPrice: 0,
            });
    }

    validateNgrSustainabilityCommodityCheck(ngrId) {
        let isValidated = false;
        let selectedNgr = filter(this.state.sellerNgrs, { id: ngrId });
        if(!isEmpty(selectedNgr)) {
            let selectedNgrSustainableDeclarations = get(selectedNgr, '0.sustainableDeclarations');
          if(!isEmpty(selectedNgrSustainableDeclarations) && !isEmpty(filter(selectedNgrSustainableDeclarations, { commodityId: get(this.props.selectedCashPrice, 'commodityId'), season: this.props.selectedCashPrice?.season })))
                isValidated = true;
        }
        return isValidated;
    }

    getContactNgr(value) {
        let companyQueryString = `company_ids=${value}`;
        if (this.props.user.company.typeId === BROKER)
            companyQueryString = map([this.props.user.companyId, value], id => `company_ids=${id}`).join('&');
        APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(this.props.token)
            .then(contacts => {
                APIService.companies(value).ngrs().appendToUrl('minimal/')
                    .get().then(items => {
                        this.setState({
                            sellerNgrs: uniqBy(items, 'id'), sellerContacts: uniqBy(contacts, 'id')
                        });
                    }
                    );
            });
    }

    handleVarietyChange = id => {
        this.handleValueChange(id, 'varietyId');
    };

    handleTonnageFieldChange(event) {
        const newState = { ...this.state };
        newState.fields.tonnage.value = event.target.value;
        let value = event.target.value ? event.target.value : '0.00';
        let totalSale = (parseFloat(value) * parseFloat(get(this.props, 'selectedCashPrice.price'))).toFixed(2);
        newState.totalSale = totalSale;
        if (!this.isStrictQuantityBasedCommodity() && this.props.selectedCashPrice) {
            if (parseFloat(event.target.value) > this.state.sellerTonnageCashPrice) {
                newState.fields.tonnage.errors = [this.EXCESS_TONNAGE_ERROR];
            } else if (this.state.fields.tonnage.value > this.props.selectedCashPrice.limitRemaining) {
                newState.fields.tonnage.errors = [`Tonnage Limit Remaining: ${this.props.selectedCashPrice.limitRemaining} ${MT_UNIT}`];
            } else {
                newState.fields.tonnage.errors = [];
            }
        }
        this.setState(newState);
    }

  getSelectedCommodity = () => {
    const commodityId = get(this.props.selectedCashPrice, 'commodityId')
    return find(this.props.commodities, {id: commodityId})
  }

  isStrictQuantityBasedCommodity() {
    return get(this.getSelectedCommodity(), 'isStrictQuantityBased')
  }

    handleSubmit() {
        event.preventDefault();
        this.setAllFieldsErrors();
        let isFormInvalid = some(this.state.fields, field => {
            return field.errors.length > 0;
        }) || some(this.state.fields.seller, field => {
            return field.errors.length > 0;
        });
        if(get(this.props.selectedCashPrice, 'isCommoditySustainable')) {
            if(!this.validateNgrSustainabilityCommodityCheck(this.state.fields.seller.ngrId.value)) {
                isFormInvalid = true;
                alertifyjs.error('Sorry, we are unable to complete your request. Cashing out against a sustainable commodity cash price requires a self declaration to be completed on the NGR.com website prior to cashing.');
            }
        }
        if (this.state.fields.seller.companyId.value === get(this.props, 'selectedCashPrice.buyer.companyId')) {
            isFormInvalid = true;
            alertifyjs.error('you cannot cash out against your own cash price');
        }
        let sellerNgr = filter(this.state.sellerNgrs, { id: this.state.fields.seller.ngrId.value });
        if (!includes(get(sellerNgr, '0.primaryShareholderCompanyIds'), get(this.state.fields, 'seller.companyId.value')) && !get(find(this.state.handlers, { id: get(this.state.fields.handlerId, 'value') }), 'isOverdraftAllowed')) {
            isFormInvalid = true;
            alertifyjs.alert('Title Transfer', `<div className=""><p>You cannot create this Title Transfer because:</p><ul><li>You are not the primary shareholder in this ngr.</li></ul></div>`);
        }
        this.focusOnFirstErrorField();
        const shouldFetchCanolaLoads = this.shouldFetchCanolaLoads();
        if (!isFormInvalid) {
          if(shouldFetchCanolaLoads && !this.state.openCanolaLoads)
            this.toggleCanolaLoads();
          else {
            const data = mapValues(this.state.fields, 'value');
            const seller = mapValues(this.state.fields.seller, 'value');
            data.seller = seller;
            if(shouldFetchCanolaLoads && this.state.openCanolaLoads)
              if(isEmpty(this.getInvalidSelectedLoadIds()) && !isEmpty(this.state.selectedCanolaLoads)) {
                const result = {};
                forEach(this.state.selectedCanolaLoads, load => result[load.id] = load.transferrableTonnage);
                data.canolaLoadIds = result;
                data.tonnage = sum(values(data.canolaLoadIds));
                if(get(this.props.selectedCashPrice, 'limitRemaining') < data.tonnage)
                  alertifyjs.error(this.EXCESS_REMAINING_LIMIT_ERROR);
                else
                  this.props.handleConfirmTransferDialog(data);
              }
              else
                alertifyjs.error('Please make sure loads are selected and transfer tonnage is less than remaining tonnage');
            else
              this.props.handleConfirmTransferDialog(data);
          }
        }
    }

    toggleCanolaLoads = () => {
      const open = !this.state.openCanolaLoads;
      if(open)
        this.fetchCanolaLoads();
      else
        this.setState({openCanolaLoads: open, selectedCanolaLoads: [], canolaLoads: []});
    };

    shouldFetchCanolaLoads = () => !this.state.isLoadingCanolaLoads && this.showSelectLoads();

    fetchCanolaLoads = () => this.setState(
      {isLoadingCanolaLoads: true, selectedCanolaLoads: [], canolaLoads: []},
      () => APIService
        .farms(this.props.selectedCashPrice.siteId)
        .appendToUrl('loads/minimal/')
        .get(null, null, {
          commodity_id: COMMODITIES.CANOLA,
          grade_id: get(this.props.selectedCashPrice, 'gradeId'),
          ngr_company_id: this.state.fields.seller.companyId.value,
          ngr_id: this.state.fields.seller.ngrId.value,
          season: get(this.props.selectedCashPrice, 'season'),
          transfer: true,
        }).then(loads => {
          if(isEmpty(loads))
            this.setState(
              {isLoadingCanolaLoads: false, fetchedCanolaLoads: false},
              () => alertifyjs.error('No Loads found.')
            );
          else {
            const formattedLoads = map(loads, load => {
              load.transferrableTonnage = load.remainingTonnage;
              return load;
            });
            this.setState({canolaLoads: formattedLoads, isLoadingCanolaLoads: false, fetchedCanolaLoads: true, openCanolaLoads: true});
          }
      }));

  getSellerCompanies() {
    APIService.farms(this.props.selectedCashPrice.siteId).get().then(async site => {
      let sellerCompanies = []
      if (this.props.user.company.typeId === BROKER && !site.company.showCashPricesToAll) {
        sellerCompanies = filter(this.state.allCompanyParties, { id: get(site.company, 'id') });
      }
      else if (this.props.user.company.typeId === BROKER && site.company.showCashPricesToAll) {
        let relatedSellers = await APIService.companies(this.props.user.companyId).appendToUrl('brokers/').get();
        sellerCompanies = filter(this.state.allCompanyParties, obj =>
          includes([...relatedSellers.companies, this.props.user.companyId], obj.id));
      } else if (
        this.props.user.companyId === get(
          filter(this.state.handlers, { id: this.props.selectedCashPrice.siteId }), '0.companyId'
        ) && this.props.user.company.showCashPricesToAll) {
        sellerCompanies = [...this.state.allCompanyParties]
      } else {
        sellerCompanies = filter(this.state.allCompanyParties, { id: this.props.user.companyId });
      }
      const newState = {...this.state}
      newState.sellerCompanies = sellerCompanies
      newState.isLoadingInitialData = false
      let sellerId;
      if(sellerCompanies?.length === 1) {
        sellerId = sellerCompanies[0].id
      }
      this.setState(newState, () => sellerId && this.handleValueChange(sellerId, 'seller.companyId'))
    });
  }

    setAllFieldsErrors() {
        forEach(this.state.fields, (value, key) => {
            this.setFieldErrors(key);
        });
        forEach(this.state.fields.seller, (value, key) => {
            this.setFieldErrors(`seller.${key}`);
        });
        if (this.props.selectedCashPrice) {
            const newState = { ...this.state };
            if (this.state.fields.tonnage.value > this.state.sellerTonnageCashPrice) {
                newState.fields.tonnage.errors = [this.EXCESS_TONNAGE_ERROR];
            } else if (this.state.fields.tonnage.value > this.props.selectedCashPrice.limitRemaining) {
                newState.fields.tonnage.errors = [`Tonnage Limit Remaining: ${this.props.selectedCashPrice.limitRemaining} ${MT_UNIT}`];
            } else if(this.state.fields.tonnage.value && this.state.fields.tonnage.value > 0){
                newState.fields.tonnage.errors = [];
            }
            this.setState(newState);
        }
    }

    setFieldErrors(key) {
        const newState = { ...this.state };
        set(newState.fields, key + '.errors', this.getFieldErrors(key));
        this.setState(newState);
    }

    showOilAndAdmixFields = () => COMMODITIES.CANOLA == get(this.props, 'selectedCashPrice.commodityId')

    isSellerGrower = () => (
        get(find(this.state.sellerCompanies, {id: this.state.fields.seller.companyId.value}), 'typeId')
      ) === GROWER;

    showSelectLoads = () => this.showOilAndAdmixFields() && this.isSellerGrower() && get(this.state.settings, 'loadByLoadTransfer');

    setValidatorsForCanola() {
      const newState = { ...this.state };
      newState.fields.tonnage.value = null;
      newState.fields.tonnage.validators = [];
      newState.fields.tonnage.errors = [];
      newState.fields.coil.value = null;
      newState.fields.coil.validators = [];
      newState.fields.coil.errors = [];
      newState.fields.impu.value = null;
      newState.fields.impu.validators = [];
      newState.fields.impu.errors = [];
      this.setState(newState);
    }

    fetchSettings = async () => {
      const handlerId = get(this.props.selectedCashPrice, 'siteId', '');
      if(handlerId) {
        let settings = await APIService.company_sites(handlerId).appendToUrl('settings/').get()
        this.setState({settings: settings}, () => {
            if(this.showSelectLoads()) {
                this.setValidatorsForCanola();
            }
            else if(this.showOilAndAdmixFields()) {
                if(!isEqual(get(this.props.selectedCashPrice, 'paymentScale', ''), FLAT_PAYMENT_SCALE)) {
                    const newState = { ...this.state };
                    newState.fields.coil.validators = [required()];
                    newState.fields.impu.validators = [required()];
                    this.setState(newState);
                }
            }
          });
      }
    };

    getFieldErrors(key) {
        const errors = [];
        const value = get(this.state.fields, `${key}.value`);
        const validators = get(this.state.fields, `${key}.validators`) || [];
        validators.forEach(validator => {
            if (validator.isInvalid(value)) {
                errors.push(validator.message);
            }
        });
        return errors;
    }

    focusOnFirstErrorField() {
        const nestedFields = ["identifier", "seller.companyId", "seller.contactId",
            "seller.ngrId", "tonnage"
        ];

        for (let i = 0; i < this.fieldsOrder.length; i++) {
            const formField = this.fieldRef[this.fieldsOrder[i]];
            const field = this.state.fields[this.fieldsOrder[i]];
            if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
                if ((this.fieldsOrder[i] === "identifier" && this.state.fields.identifier.errors.length) ||
                    (this.fieldsOrder[i] === "seller.companyId" && this.state.fields.seller.companyId.errors.length) ||
                    (this.fieldsOrder[i] === 'seller.contactId' && this.state.fields.seller.contactId.errors.length) ||
                    (this.fieldsOrder[i] === "seller.ngrId" && this.state.fields.seller.ngrId.errors.length) ||
                    (this.fieldsOrder[i] === "tonnage" && this.state.fields.tonnage.errors.length)) {
                    getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
                    break;
                }
                if ((this.fieldsOrder[i] === "siteId" && this.state.fields.siteId.errors.length > 0) ||
                    (this.fieldsOrder[i] === "season" && this.state.fields.season.errors.length > 0) ||
                    (this.fieldsOrder[i] === "price" && this.state.fields.price.errors.length) ||
                    (this.fieldsOrder[i] === "limit" && this.state.fields.limit.errors.length)) {
                    formField.current.focus();
                    break;
                }
            } else if (field && field.errors.length > 0) {
                if (get(formField, 'current.node')) {
                    formField.current.node.previousSibling.focus();
                    break;
                } else {
                    const current = get(formField, 'current');
                    if (current)
                        current.focus();
                    break;
                }
            }
        }
    }

    onLoadCheckboxChange = (selectedLoad, isChecked) => {
      if(isChecked)
        this.setState({selectedCanolaLoads: [...this.state.selectedCanolaLoads, selectedLoad]});
      else
        this.setState({selectedCanolaLoads: without(this.state.selectedCanolaLoads, selectedLoad)});
    };

    getInvalidSelectedLoadIds() {
      return compact(map(this.state.selectedCanolaLoads, load => {
        if(!isNumber(load.transferrableTonnage) || !load.transferrableTonnage || load.transferrableTonnage < 0 || load.remainingTonnage < load.transferrableTonnage)
          return load.id;
      }));
    }

    handleRemainingTonnageChange = (event, load) => {
      const { canolaLoads } = this.state;
      this.setState({canolaLoads: map(canolaLoads, canolaLoad => {
        if(canolaLoad.id === load.id)
          canolaLoad.transferrableTonnage = event.target.value ? parseFloat(event.target.value) : 0;
        return canolaLoad;
      })});
    };

    isSelectedLoad = loadId => includes(map(this.state.selectedCanolaLoads, 'id'), loadId);

    getColumns = commodity => {
      const invalidLoadIds = this.getInvalidSelectedLoadIds();
      const specColumns = commodity ? getSpecColumns(commodity, 'specs') : [];
      return [
        {checkbox: true, onChange: 'onChange', func: item => this.isSelectedLoad(item.id)},
        {header: 'Reference(s)', formatter: loadReferencesDisplay, default: getLoadReferences},
        { key: 'season', header: 'Season' },
        { key: 'varietyName', header: 'Variety' },
        { key: 'gradeName', header: 'Grade' },
        ...specColumns,
        { key: 'remainingTonnage', header: 'Remaining Tonnage' },
        { header: 'Transfer Tonnage',
          formatter: load => <input
                               style={includes(invalidLoadIds, load.id) ? {color: 'red'} : {}}
                               disabled={!this.isSelectedLoad(load.id)}
                               size='small'
                               value={load.transferrableTonnage}
                               type='number'
                               min="0.00001"
                               step="1"
                               max={load.remainingTonnage}
                               onChange={event => this.handleRemainingTonnageChange(event, load)} />
        },
      ];
    };

    handleFieldChange(event) {
      const newState = { ...this.state };
      set(newState, `fields.${event.target.id}.value`, event.target.value);
      this.setState(newState);
    }

    totalTransferrableCanolaTonnage = () => sum(map(this.state.selectedCanolaLoads, 'transferrableTonnage')).toFixed(2)

    priceUnit() {
      return get(this.getSelectedCommodity(), 'priceUnit', MT_UNIT);
    }

  render() {
    const showOilAndAdmixFields = this.showOilAndAdmixFields();
    const showSelectLoads = this.showSelectLoads();
    const priceUnit = this.priceUnit();
    const { openCanolaLoads, canolaLoads, isLoadingInitialData } = this.state;
    return (
      <div id='title-transfer-side-form'>
        {
          isLoadingInitialData ?
            <LoaderInline
              style={{ height: '500px' }}
              containerClassName="inline-loader-container"
              imageStyle={{ width: '15%' }}
            /> :
          <form noValidate>
            <div className='cardForm cardForm--drawer' style={openCanolaLoads ? {} : {display: 'none'}}>
              <div className='col-sm-12 no-side-padding' onClick={this.toggleCanolaLoads} style={{margin: '10px 0'}}>
                <Button color='primary' size='small' variant='outlined'>
                  <BackIcon fontSize='inherit' style={{marginRight: '5px'}} />
                  Back
                </Button>
              </div>
              {
                openCanolaLoads &&
                  <GenericTable
                    items={canolaLoads}
                    columns={this.getColumns(get(canolaLoads, '0.commodity'))}
                    optionsItems={[]}
                    onChange={this.onLoadCheckboxChange}
                  />
              }
            </div>
            <div className='cardForm cardForm--drawer'>
              <div className='cardForm-content row' style={openCanolaLoads ? {display: 'none'} : {}}>
                <div className='col-sm-6 form-wrap'>
                  <TextField
                    error={!isEmpty(get(this.state.fields.identifier, 'errors[0]'))}
                    id='identifier'
                    label='Title Transfer No.'
                    inputRef={this.fieldRef['identifier']}
                    placeholder='Please enter 14 digit unique number'
                    style={{ float: 'left' }}
                    value={this.state.fields.identifier.value}
                    inputProps={{
                      maxLength: 14,
                    }}
                    fullWidth
                    helperText={get(this.state.fields.identifier, 'errors[0]')}
                    onChange={this.handleIdentifierChange}
                    variant="standard" />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='site' label='Site' value={get(this.props, 'selectedCashPrice.siteName')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonAutoSelect
                    id='seller.companyId'
                    label='Seller Company'
                    setRef={this.fieldRef['seller.companyId']}
                    placeholder='Seller Company'
                    items={this.state.sellerCompanies}
                    fullWidth
                    onChange={this.handleValueChange}
                    errorText={get(this.state.fields.seller.companyId, 'errors[0]')}
                    value={this.state.fields.seller.companyId.value}
                  />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='buyerCompany' label='Buyer Company' value={get(this.props, 'selectedCashPrice.buyer.companyName')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonAutoSelect
                    items={this.state.sellerContacts}
                    setRef={this.fieldRef['seller.contactId']}
                    id="seller.contactId"
                    label="Seller Contact"
                    value={this.state.fields.seller.contactId.value}
                    onChange={this.handleValueChange}
                    errorText={this.state.fields.seller.contactId.errors[0]}
                  />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='buyerContact' label='Buyer Contact' value={get(this.props, 'selectedCashPrice.buyer.contactName')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonAutoSelect
                    id='seller.ngrId'
                    label='Seller NGR'
                    placeholder='Seller NGR'
                    setRef={this.fieldRef['seller.ngrId']}
                    items={this.state.sellerNgrs}
                    fullWidth
                    onChange={this.handleValueChange}
                    errorText={get(this.state.fields.seller.ngrId, 'errors[0]')}
                    value={this.state.fields.seller.ngrId.value}
                    dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
                  />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='buyerNgr' label='Buyer NGR' value={get(this.props, 'selectedCashPrice.buyer.ngrNumber')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='commodity' label='Commodity' value={get(this.props, 'selectedCashPrice.commodityName')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <VarietyAutoComplete
                    id="varietyId"
                    onChange={this.handleVarietyChange}
                    label={isEmpty(this.state.fields.varietyId.validators) ? "Variety (Optional)": "Variety"}
                    commodityId={get(this.props, 'selectedCashPrice.commodityId')}
                    varietyId={this.state.fields.varietyId.value}
                    dependsOnCommodity={true}
                    errorText={get(this.state, 'fields.varietyId.errors[0]', '')}
                  />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='grade' label='Grade' value={get(this.props, 'selectedCashPrice.gradeName')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='season' label='Season' value={get(this.props, 'selectedCashPrice.season')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='price' label='Price per MT' value={`${getCountryCurrency()} ${get(this.props, 'selectedCashPrice.price')}`} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='paymentTerm' label='Payment Terms' value={get(this.props, 'selectedCashPrice.paymentTerm.name')} disabled />
                </div>
                <div className='col-sm-6 form-wrap'>
                  <TextField
                    error={!isEmpty(get(this.state.fields.tonnage, 'errors[0]'))}
                    id='tonnage'
                    label={`Enter Tonnage (Available: ${this.state.sellerTonnageCashPrice} ${MT_UNIT})`}
                    placeholder='Enter Tonnage'
                    inputRef={this.fieldRef['identifier']}
                    value={this.state.fields.tonnage.value || ''}
                    fullWidth
                    disabled={showSelectLoads}
                    helperText={get(this.state.fields.tonnage, 'errors[0]')}
                    onChange={this.handleTonnageFieldChange}
                    onInput = {(e) =>{
                      e.target.value = e.target.value.toString().replace(/[^0-9.]/gi, '');
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end' style={{ color: 'rgb(162,162,162)' }}>
                          {MT_UNIT}
                        </InputAdornment>
                      ),
                    }}
                    variant="standard" />
                </div>
                {showOilAndAdmixFields && (
                  <div className={'col-sm-6 form-wrap'}>
                    <CommonTextField
                      id='coil'
                      setRef={this.fieldRef['coil']}
                      label={isEmpty(this.state.fields.coil.validators) ? 'COIL (Optional)' : 'COIL'}
                      value={this.state.fields.coil.value}
                      helperText={this.state.fields.coil.errors[0]}
                      onKeyDown={event => positiveDecimalFilter(event, 2, 99.99)}
                      onChange={this.handleFieldChange}
                      onBlur={this.onFieldBlur}
                      disabled={showSelectLoads}
                    />
                  </div>
                )}
                {showOilAndAdmixFields && (
                  <div className={'col-sm-6 form-wrap'}>
                    <CommonTextField
                      id='impu'
                      setRef={this.fieldRef['impu']}
                      label={isEmpty(this.state.fields.impu.validators) ? 'IMPU (Optional)' : 'IMPU'}
                      value={this.state.fields.impu.value}
                      helperText={this.state.fields.impu.errors[0]}
                      onChange={this.handleFieldChange}
                      onKeyDown={event => positiveDecimalFilter(event, 2, 99.99)}
                      onBlur={this.onFieldBlur}
                      disabled={showSelectLoads}
                    />
                  </div>
                )}
                <div className='col-sm-6 form-wrap'>
                  <CommonTextField id='totalSale' label='Total Sale' value={`${getCountryCurrency()} ${get(this.state, 'totalSale', 0)}`} disabled />
                </div>
              </div>
              {
                  !isEmpty(this.state.selectedCanolaLoads) &&
                    (<div className='col-xs-12 cardForm-action top15 padding-reset' style={{marginBottom: '15px'}}>
                      Total Transfer Tonnage: {this.totalTransferrableCanolaTonnage()}{priceUnit}
                    </div>)
              }
              <div className='col-sm-12 cardForm-action top15 padding-reset'>
                <CommonButton type='button' variant='outlined' label='Close' default onClick={this.props.closeDrawer} />
                <CommonButton type='submit' primary variant='contained' label={openCanolaLoads ? 'Submit' : (showSelectLoads ? 'Select Loads' : 'Submit')} default onClick={this.handleSubmit} />
              </div>
            </div>
          </form>

        }
      </div>
    );
  }
}

const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
    };
};
export default connect(mapStateToProps)(CashStockForm);

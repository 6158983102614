import React from 'react';
import { List, ListItemButton, IconButton, ListItemIcon, ListItemText, Tooltip } from '@mui/material'
import { find } from 'lodash'
import PopperGrow from '../common/PopperGrow';
import { getCurrentCountryCode } from '../../common/utils'

const CountrySwitch = () => {
  const countries = JSON.parse(localStorage.countries)
  const [country, setCountry] = React.useState(getCurrentCountryCode())
  const [open, setOpen] = React.useState(false)
  const anchorRef = React.useRef(null);
  const handleClick = _country => {
    localStorage.setItem('current_country', _country)
    setCountry(_country)
    setOpen(false)
    window.location.reload()
  };
  const toggleMenu = () => setOpen(!open)

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target))
      return;

    setOpen(false);
  };

  return (
    <React.Fragment>
      <Tooltip title={find(countries, {code: country})?.name}>
        <IconButton onClick={toggleMenu} ref={anchorRef}>
          <img src={`https://flagcdn.com/w40/${country}.png`} width="30" />
        </IconButton>
      </Tooltip>
      <PopperGrow open={open} anchorRef={anchorRef} handleClose={handleClose} minWidth="100px">
        <List>
          {
            countries.map(_country => (
              <ListItemButton selected={_country.code === country} key={_country.code} onClick={() => handleClick(_country.code)}>
                <ListItemIcon style={{minWidth: '45px'}}>
                  <img src={`https://flagcdn.com/w40/${_country.code}.png`} width="30" />
                </ListItemIcon>
                <ListItemText primary={_country.name} />
              </ListItemButton>
            ))
          }
        </List>
      </PopperGrow>
    </React.Fragment>
  );
}

export default CountrySwitch;

import React from 'react';
import { connect } from 'react-redux';

import { get, map, set, mapValues, cloneDeep, some, forEach, isEmpty, isEqual, camelCase, includes, has } from 'lodash';
import { REQUIRED_FIELD, FIELD, REQUIRED_BOOLEAN_FIELD } from '../../../common/constants';
import CommonTextField from '../../common/CommonTextField';
import CommonAutoSelect from '../../common/autocomplete/CommonAutoSelect';
import CommonSelect from '../../common/select/CommonSelect';
import { getCompanyEmployeesMinimal } from '../../../actions/api/employees';
import { receiveEmployees } from '../../../actions/company-settings/employees';
import APIService from '../../../services/APIService';
import TextField from '@mui/material/TextField';
import { getCommodities } from '../../../actions/api/commodities';
import FormControlLabel from '@mui/material/FormControlLabel';
import './truckDetails.scss';
import { RadioGroup, Radio, FormLabel } from '@mui/material';
import { required } from '../../../common/validators';
import { getCountryLabel } from '../../../common/utils';
import { CLEANLINESS_QUESTIONS, OPTIONAL_CLEANLINESS_QUESTIONS } from '../constants';


class TruckDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        driver: cloneDeep(REQUIRED_FIELD),
        codeOfPractice: cloneDeep(REQUIRED_BOOLEAN_FIELD),
        codeOfPracticeName: cloneDeep(FIELD),
        halalDeclaration: cloneDeep(REQUIRED_BOOLEAN_FIELD),
      },
      drivers: [],
      trailers: [],
      headList: {},
      truckCleanliness: {},
      priorLoads: {}

    };
    this.handleValueChange = this.handleValueChange.bind(this);
    this.propagateChanges = this.propagateChanges.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    const { rego, selectedTemplate, id } = this.props;
    if (this.props.unregToken) {
      APIService.profiles().appendToUrl('details/').get(this.props.unregToken)
      .then( details => {
        if(details.data)
        this.props.getCompanyEmployeesMinimal(details.data.companyId, this.props.unregToken);
      });
    }
    else
      this.props.getCompanyEmployeesMinimal(this.props.companyId);
    if (id && selectedTemplate) {
      this.setState({ headList: [rego] }, () => {
        this.fetchTrailer().then(() => {
          if (selectedTemplate) this.setDataFromSelectedTemplate();
        });
      }
      );
    }
  }

  componentDidUpdate(prevProps) {
    const { id, rego, validate, selectedTemplate } = this.props;
    if(selectedTemplate && selectedTemplate != prevProps.selectedTemplate){
      if (id == prevProps.id)
      {
            this.setDataFromSelectedTemplate();
      }
      else
      {
        this.setState({ headList: [rego] }, () => {
          this.fetchTrailer().then(() => {
            this.setDataFromSelectedTemplate();
          });
        });
      }
    }
    else{
      if (id != prevProps.id) {
        this.setState({ headList: [rego] }, () => {
          this.fetchTrailer();
        });
      }
    }
    if (isEmpty(this.props.commodities))
      this.props.getCommodities();
    if (!prevProps.validate && validate)
      this.setAllFieldErrors();
    if (this.props.drivers !== prevProps.drivers)
      this.__setDrivers();
    if (!has(prevProps, 'provider') && has(this.props, 'provider'))
      this.props.getCompanyEmployeesMinimal(get(this.props.provider, 'id'))
  }

  shouldSetDataFromTemplate(prevProps) {
    const { selectedTemplate } = this.props;
    return selectedTemplate && !isEqual(prevProps.selectedTemplate, selectedTemplate);
  }

  setDataFromSelectedTemplate(){
    const newState = { ...this.state };
    const { constants, selectedTemplate } = this.props;
    const truckCleanlinessQuestions = this.toSelectOptions(get(constants, 'truckCleanlinessQuestions', []));
    const priorLoads = this.toSelectOptions(get(constants, 'priorLoads', []));
    set(newState.fields, 'driver.value', selectedTemplate.driver);
    set(newState.fields, 'halalDeclaration.value', selectedTemplate.halalDeclaration);
    set(newState.fields, 'codeOfPractice.value', selectedTemplate.codeOfPractice);
    set(newState.fields, 'codeOfPracticeName.value', selectedTemplate.codeOfPracticeName);
    truckCleanlinessQuestions.map((row) => {
        map(this.state.headList, (vehicle) => { set(newState, `truckCleanliness[${row.name}][${vehicle}][value]`, this.getDefaultValue(selectedTemplate.truckCleanliness, row.name, vehicle)); });
    });
    priorLoads.map((row) => {
      map(this.state.headList, (vehicle) => { set(newState, `priorLoads[${row.name}][${vehicle}][value]`,  this.getDefaultValue(selectedTemplate.priorLoads, row.name, vehicle)); });
    });
    this.setState(newState);
  }

  getDefaultValue(template, row, vehicle){
    let rowValue = String(row).toLowerCase();
    let truck = camelCase(vehicle);
    return get(template , `${rowValue}[${truck}]`);
  }

  __setDrivers() {
    const { drivers } = this.props;
    if (!isEmpty(drivers))
      this.setState({ drivers: drivers });
  }

  async fetchTrailer() {
    const { truckId, constants, unregToken, id } = this.props;
    const truckCleanlinessQuestions = this.toSelectOptions(get(constants, 'truckCleanlinessQuestions', []));
    const priorLoads = this.toSelectOptions(get(constants, 'priorLoads', []));

    if (truckId) {
      await APIService.trucks(truckId).appendToUrl(`trailers/`).get(unregToken).then(
        trailers => {
          let addTrailers = map(trailers, 'rego');
          this.setState({ trailers: trailers, headList: [...this.state.headList, ...addTrailers] });
        }
      );
      var params = new URLSearchParams();
      params.append("truck_ids", truckId);
      await APIService.freights().contracts(id).appendToUrl(`trucks/?${params}`).get(unregToken).then(
        result => {
          if(result.data)
          this.setState({priorLoadDefault:result.data});
        }
      );

      this.setState({ truckCleanliness: null, priorLoads: null });
      const newState = { ...this.state };
      truckCleanlinessQuestions.map((row) => {
        if (row.id == 'If cleaning chemical used indicate type/brand')
          map(this.state.headList, (vehicle) => { set(newState, `truckCleanliness[${row.name}][${vehicle}]`, cloneDeep(FIELD)); });
        else if (row.id == 'Visual inspection prior to loading') {
          map(this.state.headList, (vehicle) => {
            set(newState, `truckCleanliness[${row.name}][${vehicle}]`, {...cloneDeep(REQUIRED_FIELD), 'value': 'yes'});
          });
        }
        else
          map(this.state.headList, (vehicle) => { set(newState, `truckCleanliness[${row.name}][${vehicle}]`, includes(OPTIONAL_CLEANLINESS_QUESTIONS, row.name) ? cloneDeep(FIELD) : cloneDeep(REQUIRED_FIELD)); });
      });
      priorLoads.map((row, index) => {
        map(this.state.headList, (vehicle) => {
          set(newState, `priorLoads[${row.name}][${vehicle}]`,
          {...cloneDeep(REQUIRED_FIELD), 'value': this.state.priorLoadDefault[truckId][index]});
        });
      });
      this.setState(newState);
    }
  }

  getQuestionResponseDom(question, index) {
    if (question === 'Visual inspection prior to loading')
      return this.getVisualInspectionResponseDom(question, index);
    if (includes(CLEANLINESS_QUESTIONS, question))
      return this.getCleaningPriorLoadingResponseDom(question, index);

    return this.getTextTypeResponseDom(question, index);
  }

  getTextTypeResponseDom(question, index) {
    const { headList } = this.state;
    return (
      <TextField
        className='text-outlined'
        id={`truckCleanliness[${question}][${headList[index]}]`}
        onChange={(event) => this.handleValueChange(event)}
        fullWidth
        variant="standard" />
    );
  }

  getCleaningPriorLoadingResponseDom(question, index) {
    const { headList } = this.state;
    const { constants } = this.props;
    const cleaningPriorToLoadingOptions = this.toSelectOptions(get(constants, 'cleaningPriorToLoadingOptions', []));
    return (
      <CommonSelect
        id={`truckCleanliness[${question}][${headList[index]}]`}
        onChange={this.handleChange}
        items={map(cleaningPriorToLoadingOptions, option => { return { id: option.name, value: option.name }; })}
        value={get(this.state, `truckCleanliness[${question}][${headList[index]}][value]`)}
        selectConfig={{ text: 'value', value: 'id' }}
        defaultSelectClass='select-outlined'
        errorText={get(this.state, `truckCleanliness[${question}][${headList[index]}][errors][0]`)}
      />
    );
  }

  getVisualInspectionResponseDom(question, index) {
    const { headList } = this.state;
    return (
      <CommonSelect
        id={`truckCleanliness[${question}][${headList[index]}]`}
        onChange={this.handleChange}
        items={[{ id: 'yes', value: 'Yes' }, { id: 'no', value: 'No' }]}
        selectConfig={{ text: 'value', value: 'id' }}
        value={get(this.state, `truckCleanliness[${question}][${headList[index]}][value]`)}
        defaultSelectClass='select-outlined'
        errorText={get(this.state, `truckCleanliness[${question}][${headList[index]}][errors][0]`)}
        includeEmptyOption={false}
        showOnlyEmptyOption={false}
      />
    );
  }

  getPriorLoadsResponseDom(question, index) {
    const { headList } = this.state;
    const { commodities, materials } = this.props;
    const items = [
      ...commodities,
      ...map(materials, material => ({...material, id: `${material.id}/m`}))
    ]
    return (
      <CommonAutoSelect
        id={`priorLoads[${question}][${headList[index]}]`}
        onChange={this.handleChange}
        items={items}
        value={get(this.state, `priorLoads[${question}][${headList[index]}][value]`)}
        dataSourceConfig={{ text: 'displayName', value: 'id' }}
        errorText={get(this.state.priorLoads, `[${question}][${headList[index]}][errors][0]`)}
        containerClass="select-outlined-container"
      />
    );
  }

  toSelectOptions(dataList) {
    return map(dataList, item => ({ id: item, name: item }));
  }

  handleValueChange(event) {
    const newState = { ...this.state };
    const field = event.target.id;
    set(newState, `${event.target.id}.value`, event.target.value);
    this.setState(newState, () => this.setFieldError(field));
  }

  handleBoolValueChange = event => {
    const newState = {...this.state.fields}
    const field = event.target.id
    set(newState, `${field}.value`, event.target.value === 'true')
    this.setState({"fields": newState}, () => this.setFieldErrorForCompliance(field))
  }


  handleCodeOfPracticeChange(event) {
    const newState = { ...this.state.fields };
    const field = event.target.id;
    if (event.target.value == "true"){
      set(newState, `${field}.value`, true);
      set(newState, 'codeOfPracticeName.validators', [required()]);
    }
    else{
      set(newState, 'codeOfPracticeName.validators', []);
      set(newState, 'codeOfPracticeName.errors', []);
      set(newState, `${field}.value`, false);
    }
    this.setState({"fields": newState}, () => this.setFieldErrorForCompliance(field));
  }

  handleChange(value, fieldId) {
    const newState = { ...this.state };
    if (get(newState, `${fieldId}.validators`)) {
      if (value)
        set(newState, `${fieldId}.value`, value);
      else
        set(newState, `${fieldId}.value`, null);
      this.setState(newState, () => this.setFieldError(fieldId));
    }
  }

  setFieldError(field) {
    if (!this.props.isNotIndependent) {
      const newState = { ...this.state };
      const fieldState = get(newState, field);
      fieldState.errors = this.getFieldErrors(field);
      this.setState(newState, () => {
        this.propagateChanges();
      });
    }
  }

  setFieldErrorForCompliance(field) {
    if (!this.props.isNotIndependent) {
      const newState = { ...this.state };
      const fieldState = get(newState, `fields.${field}`);
      fieldState.errors = this.getFieldErrors(field);
      this.setState(newState, () => {
        this.propagateChanges();
      });
    }
  }


  getFieldErrors(field) {
    let errors = [];
    const fieldState = get(this.state, field);
    const deliveryHalal = get(this.props, 'freightDelivery.consignee.handler.isHalalDeclarationMandatory')
    const deliveryOwner = get(this.props, 'freightDelivery.consignee.handler.name')
    const pickupHalal = get(this.props, 'freightPickup.consignor.handler.isHalalDeclarationMandatory')
    const pickupOwner = get(this.props, 'freightPickup.consignor.handler.name')
    if (!fieldState)
      return errors;
    forEach(fieldState.validators, validator => {
      if (validator.isInvalid(fieldState.value))
        errors.push(validator.message);
      else if (field === 'fields[halalDeclaration]' && !fieldState.value)
        if (pickupHalal && deliveryHalal)
          errors.push(`Both ${pickupOwner} and ${deliveryOwner} require this to be checked for the vendor declaration to proceed.`)
        else if (pickupHalal ^ deliveryHalal){
          if (pickupHalal)
            errors.push(`${pickupOwner} requires this to be checked for the vendor declaration to proceed.`)
          if (deliveryHalal)
            errors.push(`${deliveryOwner} requires this to be checked for the vendor declaration to proceed.`)
        }
      else
        errors = [];
    });
    return errors;
  }

  propagateChanges() {
    const { fields, truckCleanliness, priorLoads } = this.state;
    let values = mapValues(fields, 'value');
    let truckCleanlinessValue = {};
    let priorLoadsValue = {};
    forEach(truckCleanliness, (value, key) => {
      truckCleanlinessValue[key] = mapValues(value, 'value');
    });
    forEach(priorLoads, (value, key) => {
      priorLoadsValue[key] = mapValues(value, 'value');
    });
    let data = { ...values, 'truckCleanliness': truckCleanlinessValue, 'priorLoads': priorLoadsValue };
    this.props.onChange(data, { truck: this.hasErrors() });
  }

  setAllFieldErrors() {
    const newState = { ...this.state };
    forEach(newState.truckCleanliness, (state, question) => {
      forEach(state, (value, field) => {
        value.errors = this.getFieldErrors(`truckCleanliness[${question}][${field}]`);
      });
    });
    forEach(newState.priorLoads, (state, question) => {
      forEach(state, (value, field) => {
        value.errors = this.getFieldErrors(`priorLoads[${question}][${field}]`);
      });
    });
    forEach(newState.fields, (state, field) => {
      state.errors = this.getFieldErrors(`fields[${field}]`);
    });
    this.setState(newState, this.propagateChanges);
  }

  hasErrors() {
    const { fields, truckCleanliness, priorLoads } = this.state;
    let hasError = some(fields, field => get(field, 'errors.length') > 0) ||
      some(truckCleanliness, questions => some(questions, obj => get(obj, 'errors.length') > 0)) ||
      some(priorLoads, questions => some(questions, obj => get(obj, 'errors.length') > 0));

    return hasError;
  }

  render() {
    const { trailers, fields, drivers } = this.state;
    const { constants, fieldRef } = this.props;
    const truckCleanlinessQuestions = this.toSelectOptions(get(constants, 'truckCleanlinessQuestions', []));
    const priorLoads = this.toSelectOptions(get(constants, 'priorLoads', []));
    const codeOfPracticeError = get(fields, 'codeOfPractice.errors[0]');
    const halalDeclarationError = get(fields, 'halalDeclaration.errors[0]');
    return (
      <div>
        <h4 className='cardForm-title'>Truck Details</h4>
        <div className='cardForm-content'>
          <div className='col-md-6'>
            <CommonTextField
              id='rego'
              label={getCountryLabel('rego')}
              value={get(this.props, 'rego')}
              disabled
            />
          </div>
          <div className="col-md-6">
            {drivers &&
              <CommonAutoSelect
                items={this.state.drivers}
                label="Driver"
                setRef={fieldRef["data.driver"]}
                id="fields.driver"
                selectedItemId={this.state.fields.driver.value}
                value={this.state.fields.driver.value}
                onChange={this.handleChange}
                errorText={this.state.fields.driver.errors[0]}
              />}
          </div>
          <div className='col-md-12 padding-reset form-wrap'>
            <h4 className='section-heading'>Truck Cleanliness</h4>
            <div className='col-md-12 padding-reset'>
              <div className='col-md-12 padding-reset'>
                <span className='col-md-4 cell'></span>
                <span className='col-md-2 cell center truck-label'>
                  <div className='sub-label'>Prime Mover</div>
                  <div>{get(this.props, 'rego')}</div>
                </span>
                {
                  trailers.map(trailer => (
                    <span key={trailer.id} className='col-md-2 cell center truck-label'>
                      <div className='sub-label'>Trailer</div>
                      <div>{trailer.rego}</div>
                    </span>
                  ))
                }
              </div>
              {
                map(truckCleanlinessQuestions, question => (
                  <div className='col-md-12 padding-reset' key={question.id}>
                    <span className='col-md-4 cell question'>{includes(OPTIONAL_CLEANLINESS_QUESTIONS, question.name) ? `${question.name} (Optional)` : question.name}</span>
                    <span className='col-md-2 cell center'>
                      {this.getQuestionResponseDom(question.id, 0)}
                    </span>
                    {
                      map(trailers, (trailer, index) => {
                        return (
                          <span key={trailer.id} className='col-md-2 cell center'>
                            {this.getQuestionResponseDom(question.id, index + 1)}
                          </span>
                        );
                      })
                    }
                  </div>
                ))
              }
              <h4 className='section-heading nested-heading'>Prior Loads</h4>
              {
                map(priorLoads, question => (
                  <div className='col-md-12 padding-reset' key={question.id}>
                    <span className='col-md-4 cell question'>{question.name}</span>
                    <span className='col-md-2 cell center'>
                      {this.getPriorLoadsResponseDom(question.id, 0)}
                    </span>
                    {
                      map(trailers, (trailer, index) => {
                        return (
                          <span key={trailer.id} className='col-md-2 cell center'>
                            {this.getPriorLoadsResponseDom(question.id, index + 1)}
                          </span>
                        );
                      })
                    }
                  </div>
                ))
              }
            </div>
          </div>
          <div className="col-md-12 padding-reset form-wrap" style={{paddingTop: "20px"}}>
            <RadioGroup row onChange={(event) => this.handleCodeOfPracticeChange(event)} value={fields.codeOfPractice.value} sx={{alignItems: 'center'}}>
                <FormLabel style={{"paddingRight": "16px" }}>Compliance with GTA transport code of practice:</FormLabel>
                <FormControlLabel value="true" control={<Radio id="codeOfPractice" color="primary" checked={fields.codeOfPractice.value === true}/>} label="Yes" />
                <FormControlLabel value="false" control={<Radio id="codeOfPractice" color="primary" checked={fields.codeOfPractice.value === false} />} label="No" />
            </RadioGroup>
            {
              codeOfPracticeError &&
                <div className="col-md-6 padding-reset" style={{fontSize: '10px', color: 'red'}}>
                  {codeOfPracticeError}
                </div>
            }
            <div className="col-md-6 padding-reset">
              {fields.codeOfPractice.value === true &&
                <CommonTextField
                  id="fields.codeOfPracticeName"
                  label='Code of Practice Name'
                  onChange={(event) => this.handleValueChange(event)}
                  type='text'
                  value={fields.codeOfPracticeName.value}
                  helperText={get(fields, 'codeOfPracticeName.errors[0]')}
                  style={{float: 'left'}}
                  errorStyle={{textAlign: "left"}}
                />
              }
            </div>
          </div>
          <div className="col-md-12 padding-reset form-wrap" style={{paddingTop: "20px"}}>
            <RadioGroup row onChange={event => this.handleBoolValueChange(event)} value={fields.halalDeclaration.value} sx={{alignItems: 'center'}}>
              <FormLabel style={{"paddingRight": "16px" }}>The truck is free from dogs, pigs, pork and raw meats of any kind. Excluding assistance animals:</FormLabel>
                <FormControlLabel value="true" control={<Radio id="halalDeclaration" color="primary" checked={fields.halalDeclaration.value === true}/>} label="Yes" />
                <FormControlLabel value="false" control={<Radio id="halalDeclaration" color="primary" checked={fields.halalDeclaration.value === false} />} label="No" />
            </RadioGroup>
            {
              halalDeclarationError &&
                <div className="col-md-6 padding-reset" style={{fontSize: '10px', color: 'red'}}>
                  {halalDeclarationError}
                </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    companyId: get(state.main, 'user.user.companyId'),
    drivers: state.companySettings.employees.items,
    commodities: state.master.commodities.items,
  };
};


const mapDispatchToProps = dispatch => ({
  getCommodities: () => dispatch(getCommodities()),
  getCompanyEmployeesMinimal: (id, token) => dispatch(getCompanyEmployeesMinimal(id, receiveEmployees, token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TruckDetails);

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class VendorDecs extends Component {
  render() {
    const { fill } = this.props;
    return <Tooltip title="Vendor Decs" placement="right">
      <svg width="24px" height="24px" viewBox="0 0 24 24">
        <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-1488.000000, -624.000000)">
            <g id="Icons-/-Dashboard-/-vendor_dec" transform="translate(1488.000000, 624.000000)">
              <g id="Vendor-Dec-Icon">
                <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                <path d="M12,1.09570313 L2,4.86010156 C2.2309571,6.20866181 2.40421809,7.18760978 2.51978296,7.79694548 C3.24495719,11.6205513 3.97164224,14.3075974 4.6998381,15.8580836 C7.18611388,21.1519016 10.9528345,22.8543701 12,22.8543701 C13.0471655,22.8543701 16.808985,21.1592415 19.2898171,15.8801034 C20.0186542,14.3291596 20.7472022,11.6402876 21.4754611,7.81348743 C21.5920364,7.20091632 21.7668827,6.21645436 22,4.86010156 L12,1.09570313 Z" id="Path-2" stroke={fill} strokeWidth="2" strokeLinejoin="round"></path>
                <polygon id="Path-Copy" fill={fill} points="18.1849365 9.27819824 11.1949365 16.2781982 6.68493652 11.7781982 8.18493652 10.2781982 11.1949365 13.2781982 16.6849365 7.77819824"></polygon>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default VendorDecs;

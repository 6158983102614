import React from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import InputAdornment from '@mui/material/InputAdornment';
import moment from 'moment';
import set from 'lodash/set';
import get from 'lodash/get';
import includes from 'lodash/includes';
import map from 'lodash/map';
import CommonSelect from '../common/select/CommonSelect';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import {
  DAYS_OF_WEEK, DAYS, DAY, WEEK, DATE_DB_FORMAT, EMPTY
} from '../../common/constants';
import { positiveDecimalFilter } from '../../common/input-filters';

class RepeatSlot extends React.Component {
  constructor(props) {
    super(props);

    this.DAYS = DAYS_OF_WEEK;
    this.REPEAT_EVERY_OPTIONS = [
      {id: DAY, name: DAY},
      {id: WEEK, name: WEEK},
    ];

    this.state = {
      repeatDetails: {
        interval: DAY,
        intervalOccurrence: 1,
        on: [],
        endsOn: moment().add(14, DAYS).format(DATE_DB_FORMAT),
        endsAfter: 13,
        ends: "on"
      },
      repeat: false,
    };
    this.handleRepeatDetailsChange = this.handleRepeatDetailsChange.bind(this);
    this.handleWeekDayClick = this.handleWeekDayClick.bind(this);
    this.handleRepeatEndsChange = this.handleRepeatEndsChange.bind(this);
  }

  componentDidMount() {
    this.notifyParent();
    this.setEndsOnDate()
  }

  componentDidUpdate(prevProps) {
    if(prevProps.endsOnMinDate !== this.props.endsOnMinDate && this.props.endsOnMinDate) {
      this.setEndsOnDate()
    }
  }

  setEndsOnDate() {
    if(this.props.endsOnMinDate) {
      const today = moment()
      const startDate = moment(this.props.endsOnMinDate)
      const date = startDate.isAfter(today) ? startDate : today
      this.setState({repeatDetails: {...this.state.repeatDetails, endsOn: date.add(14, DAYS).format(DATE_DB_FORMAT)}}, this.notifyParent)
    }
  }

  handleWeekDayClick(event) {
    if(includes(event.target.classList, 'selected'))
      event.target.classList.remove('selected');
    else
      event.target.classList.add('selected');

    const selectedWeekDays = this.getSelectedWeekDays();
    this.setState(set(this.state, 'repeatDetails.on', selectedWeekDays), this.notifyParent);
  }

  getSelectedWeekDays() {
    return map(document.querySelectorAll('div.week-day.selected'), el => {
      return parseInt(el.id);
    });
  }

  handleRepeatEndsChange(event, value) {
    this.setState(set(this.state, 'repeatDetails.ends', value), this.notifyParent);
  }

  handleRepeatDetailsChange(event, id) {
    if(get(event, 'target'))
      this.setState(set(this.state, event.target.id, event.target.value), this.notifyParent);
    else if(id)
      this.setState(set(this.state, id, event), this.notifyParent);
  }

  notifyParent() {
    if(this.props.onChange)
      this.props.onChange(this.state.repeatDetails);
  }

  getEndsOnMinDate() {
    const { endsOnMinDate } = this.props;
    if(endsOnMinDate)
      return moment(endsOnMinDate);
  }

  render() {
    return this.props.show ? (
      <div className="col-md-12 no-side-padding" style={{marginBottom: '15px'}}>
        <div className="col-md-12 no-side-padding" style={{display: 'flex', alignItems: 'center'}}>
          <div className="col-md-3 no-left-padding" style={{marginTop: '-20px'}}>
            <CommonTextField
              id="repeatDetails.intervalOccurrence"
              label="Every"
              placeholder="Every"
              value={this.state.repeatDetails.intervalOccurrence}
              onKeyDown={(event)=>positiveDecimalFilter(event, 2, 9999.99)}
              onChange={this.handleRepeatDetailsChange}
            />
          </div>
          <div className="col-md-3 no-left-padding">
            <CommonSelect
              floatingLabelText="Interval"
              id="repeatDetails.interval"
              onChange={this.handleRepeatDetailsChange}
              items={this.REPEAT_EVERY_OPTIONS}
              value={this.state.repeatDetails.interval}
            />
          </div>
        </div>
        {
          this.state.repeatDetails.interval === 'week' &&
          <div className="col-sm-12 no-side-padding" style={{marginBottom: '20px'}}>
            <div className="col-sm-3 no-side-padding">
              <FormLabel>Repeat on</FormLabel>
            </div>
            <div className="col-sm-8 no-side-padding">
              {
                this.DAYS.map(day => {
                  return <div
                           id={day.id}
                           className="week-day"
                           key={day.id}
                           onClick={this.handleWeekDayClick}>
                    {day.name}
                  </div>;
                })
              }
            </div>
          </div>
        }
        <div className="col-sm-12 no-side-padding">
          <div className="col-md-4 no-side-padding">
            <RadioGroup
              name="endsOn"
              value={this.state.repeatDetails.ends}
              onChange={this.handleRepeatEndsChange}
            >
              <FormControlLabel
                value="on"
                control={<Radio color="primary" />}
                label="Ends On"
              />
              <FormControlLabel
                value="after"
                control={<Radio color="primary" />}
                label="Ends After"
              />
            </RadioGroup>
          </div>
          <div className="col-md-8 no-side-padding">
            <CommonDatePicker
              id="repeatDetails.endsOn"
              onChange={this.handleRepeatDetailsChange}
              value={this.state.repeatDetails.endsOn}
              disabled={this.state.repeatDetails.ends !== "on"}
              minDate={this.getEndsOnMinDate()}
            />
          </div>
          <div className="col-md-8 no-side-padding" style={{marginTop: '20px'}}>
            <TextField
              id="repeatDetails.endsAfter"
              value={this.state.repeatDetails.endsAfter}
              onKeyDown={(event)=>positiveDecimalFilter(event, 2, 99999999999.99)}
              onChange={this.handleRepeatDetailsChange}
              disabled={this.state.repeatDetails.ends !== "after"}
              InputProps={{
                endAdornment: <InputAdornment position="end">day(s)</InputAdornment>,
              }}
              variant="standard" />
          </div>
        </div>
      </div>
    ) : EMPTY;
  }
}

export default RepeatSlot;

import { Paper } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import APIService from '../../services/APIService';
import AddButton from '../common/AddButton';
import { getPaginatedOrdersResponse } from '../../actions/companies/orders';
import PackOrdersTable from '../../containers/PackOrdersTable';
import { setHeaderText, setBreadcrumbs, forceStopLoader } from '../../actions/main';
import { isAtGlobalOrders, defaultViewAction } from '../../common/utils';
import CommonListingButton from '../common/CommonListingButton';


class PackOrders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      orders: []
    };
  }

  componentDidMount() {
    this.fetchOrders();
    this.setHeaderAndBreadcrumbs();
  }

  componentDidUpdate(prevProps) {
    if (get(prevProps, 'count') !== this.props.count) this.setHeaderAndBreadcrumbs();
    this.props.dispatch(forceStopLoader());
  }
  componentWillUnmount() {
    if(window.location.hash.includes('?') && isAtGlobalOrders())
      window.location.hash = window.location.hash.split('?')[0]
  }

  setHeaderAndBreadcrumbs() {
    const { count, dispatch } = this.props;
    const countLabel = ` (${count})`;
    let headerText = 'Pack Orders';
    let breadcrumbs = [{ text: headerText + countLabel }];
    dispatch(setHeaderText(headerText));
    dispatch(setBreadcrumbs(breadcrumbs));
  }

  fetchOrders() {
    this.setState({isLoading: true}, () => {
      APIService.freights().orders().appendToUrl('web/?type_id=6').get().then(orders => this.setState({orders: orders, isLoading: false}, () => {
        this.props.dispatch(getPaginatedOrdersResponse(orders));
      }));
    });
  }

  getActionsOptionMapperListItems() {
    return [defaultViewAction];
  }

  render() {
    return (
      <Paper className='paper-table'>
        <div style={{ position: 'relative' }}>
          <div>
            <AddButton
              label='Pack Order'
              href='/#/orders/pack/new'
              app='order'
              tooltipTitle='Create Pack Order'
              tooltipPlacement='top'
            />
          </div>
          <div style={{float: 'right', marginRight: '10px'}}>
          <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
          </div>
          
          <PackOrdersTable dontRedirect={this.props.dontRedirect} />
        </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    currentUser: state.main.user.user,
    count: get(state.companies.orders, 'paginationData.count') || 0,
  };
};

export default connect(mapStateToProps)(PackOrders);

import React from 'react';
import './Footer.scss';
import packageJson from '../../package.json';
import { isSiteManagementPath, isSiteBookingPath } from '../common/utils'

const VERSION = packageJson?.version

const Footer = () => {
  const classes = (isSiteBookingPath() || isSiteManagementPath()) ? ' hide' : ''
  return (
    <footer className={'col-xs-12 footer-container flex-vertical-center' + classes}>
      <div className='version'>
        <span>Powered by <a href='https://agrichain.com' target='_blank' rel='noreferrer noopener'>AgriChain</a> :</span>
        <span style={{marginLeft: '5px'}}>{VERSION}</span>
      </div>
    </footer>
  )
};

export default Footer;

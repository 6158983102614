import React from 'react';
import moment from 'moment';
import { find, includes, isEqual } from 'lodash';
import { Tooltip, IconButton, Button } from '@mui/material';
import { Cancel as CloseIcon } from '@mui/icons-material';
import {
  SLOT_PLANNED, SLOT_BOOKED, SLOT_COMPLETED, SLOT_CANCELLED, SLOT_IN_PROGRESS, MAUVE, BROWN, SLOT_DELAYED, CORNFLOWER_BLUE
} from '../../../common/constants';
import { RGBtoRGBA, round } from '../../../common/utils';
import SiteConnector from './SiteConnector';
import Draggable from 'react-draggable';
import get from 'lodash/get';
import alertifyjs from 'alertifyjs';


const BORDER_COLORS = {
  planned: SLOT_PLANNED,
  confirmed: BROWN,
  open: SLOT_BOOKED,
  delayed: SLOT_DELAYED,
  in_progress: SLOT_IN_PROGRESS,
  'void': SLOT_CANCELLED,
  'rejected': SLOT_CANCELLED,
  delivered: CORNFLOWER_BLUE,
  completed: SLOT_COMPLETED,
  unsaved: 'rgba(0, 0, 0, 0.3)',
  invoiced: MAUVE,
};

const editableStatuses = [
  'planned',
  'confirmed'
];

const MovementTemplate = ({ movement, onDelete, onDrag, onVoid, fadeOut, isDraggable, orders, isPickupSiteSlotRequired, isDeliverySiteSlotRequired, onClick, movementEnabledStatuses }) => {
  const [dragging, setDragging] = React.useState(false)
  const isSaved = movement.status !== 'unsaved';
  const selectOrder = find(orders, { id: get(movement, 'orderId') });
  
  const color = movement?.isFreightInvoiced && includes(movementEnabledStatuses, 'invoiced') ? BORDER_COLORS['invoiced'] : BORDER_COLORS[movement.status];
  const bgColor = color.startsWith('rgba') ? '#FFF' : RGBtoRGBA(color, '0.2');

  const getPickupLabel = () => getCheckpointLabel(movement.consignorName, movement._startDate || movement.startDate, null, movement.status, movement.showStartTime);
  const getDeliveryLabel = () => getCheckpointLabel(movement.consigneeName, movement._endDate || movement.endDate, null, movement.status, movement.showEndTime);

  const getCheckpointLabel = (handler, date, dateTime = null, movementStatus=null, movementShowTime=null) => {
    let result = handler;
    if (isSaved && date)
      if (!dateTime) {
        date = moment.isMoment(date) ? date : moment(date);
        if(! includes(editableStatuses, movementStatus) && date.isValid())
          result = `${date.format('hh:mm A')} ${handler}`;
        else if(movementShowTime && date.isValid())
          result = `${date.format('hh:mm A')} ${handler}`;
      }
    else {
      dateTime = moment.isMoment(dateTime) ? dateTime : moment(dateTime);
      if(! includes(editableStatuses, movementStatus) && dateTime.isValid())
        result = `${dateTime.format('hh:mm A')} ${handler}`;
      else if(movementShowTime && date.isValid())
        result = `${dateTime.format('hh:mm A')} ${handler}`;
    }
    return result;
  };

  const onDragStop = (event, data) => {
    setDragging(false)
    if (data.x === 0 && data.y === 0)
      return;
    const currentParent = data.node.closest('div.truck-row');
    const currentDateSection = data.node.closest('div.date-section.data-container');
    const currentDateSectionIndex = parseInt(currentDateSection.getAttribute('data-index'));
    const currentMovementsSection = data.node.closest('div.movements-section');
    const currentRow = parseInt(currentParent.getAttribute('data-index'));
    const allRows = document.getElementsByClassName('truck-row');
    const yAbs = Math.abs(data.y);

    const rowDateSections = movementsSection => movementsSection.getElementsByClassName('date-section data-container');

    const moveToOriginal = () => {
      data.node.style.transform = null;
      data.node.classList.remove('react-draggable-dragged');
      data.node.classList.remove('react-draggable-dragging');
      onDrag(movement, movement.startDate, movement.plannedTruckId);
    };

    const isValidOrderDate = (movementDetails, newDate) => {
      let selectedOrder = find(orders, { id: get(movementDetails, 'orderId') });
      if(moment(newDate, 'YYYY-MM-DD').isAfter(moment(selectedOrder.endDate, 'YYYY-MM-DD')) || moment(newDate, 'YYYY-MM-DD').isBefore(moment(selectedOrder.startDate, 'YYYY-MM-DD')) ) {
        alertifyjs.error("Cannot create planned movements outside order's delivery start and delivery end date range", 3);
        return false;
      } else {
        return true;
      }
    }

    const moveToSameDateDifferentRow = truckId => {
      if (!truckId || truckId === movement.plannedTruckId)
        moveToOriginal();
      else {
        if(isValidOrderDate(movement, movement.startDate)) {
          onDrag(movement, movement.startDate, truckId);
        } else {
          moveToOriginal();
        }
      }
    };

    const moveSideways = row => {
      row = row || currentMovementsSection;
      let candidates = [...rowDateSections(row)];
      if (data.x > 99) {
        candidates = [...candidates.slice(currentDateSectionIndex + 1)].reverse();
      } else if (data.x < -99) {
        candidates = [...candidates.slice(0, currentDateSectionIndex).reverse()];
      } else {
        // x (or date) dint change
        moveToSameDateDifferentRow(row.getAttribute('data-truck'));
        return;
      }

      const movedTo = currentDateSection.offsetLeft + data.x;
      const droppedTo = find(candidates, candidate => candidate.offsetLeft < movedTo);
      if (droppedTo) {
        if(isValidOrderDate(movement, droppedTo.getAttribute('data-date'))) {
          if (movement.status !== 'unsaved' && (movement.inloadSlotId || movement.outloadSlotId)){
            alertifyjs.error("Dates cannot be changed for tiles with slots", 3);
            moveToOriginal();
          } else
            onDrag(movement, droppedTo.getAttribute('data-date'), droppedTo.getAttribute('data-truck'));
        }
        else {
          moveToOriginal();
          return;
        }
      }
      else {
        moveToOriginal();
        return;
      }
    };

    if ((yAbs < 50 && yAbs > 0) || data.y === 0 || (data.y < 0 && currentRow === 0) || (data.y > 0 && currentRow === allRows.length - 1)) {
      moveSideways();
    } else if (data.y > 0) {
      if (currentRow === allRows.length - 1)
        moveSideways();
      else {
        const rowsBelow = [...allRows].slice(currentRow + 1).reverse();
        const droppedToRow = find(rowsBelow, row => row.offsetTop < (currentParent.offsetTop + data.y));
        moveSideways(droppedToRow);
      }
    } else if (data.y < 0) {
      if (currentRow === 0) {
        moveSideways();
      } else {
        const rowsAbove = [...allRows].slice(0, currentRow).reverse();
        const droppedToRow = find(rowsAbove, row => (row.offsetTop - 10) < currentParent.offsetTop + data.y);
        moveSideways(droppedToRow);
      }
    }
  };

  const containerStyle = {
    borderRadius: '5px',
    textAlign: 'left',
    fontSize: '14px',
    padding: '0 4px',
    backgroundColor: bgColor,
    opacity: fadeOut ? 0.5 : 1,
    border: `1px solid ${color}`,
    paddingBottom: '16px'
  };
  if(isEqual(movement.status, 'unsaved')){
    if (isDeliverySiteSlotRequired || isPickupSiteSlotRequired)
      containerStyle.borderLeft = '3px solid rgb(128, 0, 0)';
    else
      containerStyle.borderLeft = '3px solid rgb(0, 128, 0)';
  }

  const _onDelete = event => {
    event.preventDefault()
    event.stopPropagation()
    onDelete()
  }
  const _onVoid = event => {
    event.preventDefault()
    event.stopPropagation()
    onVoid()
  }
  const onDragStart = () => setDragging(true)

  return (
    <div onClick={onClick} style={{display: 'flex', flexDirection: 'column', }}>
      {
        !dragging &&
          <div style={{display: 'flex', position: 'absolute', zIndex: 1, right: '0px'}}>
            {
              !isSaved && !fadeOut &&
                <Tooltip title='Remove this movement' placement='top'>
                  <IconButton size='small' onClick={_onDelete} color='error' sx={{padding: '4px 2px'}}>
                    <CloseIcon style={{ width: '14px', height: '14px', background: '#fff' }} />
                  </IconButton>
                </Tooltip>
            }
            {
              !includes(['void', 'rejected', 'unsaved'], movement.status) && !fadeOut &&
                <Tooltip title='Void this movement' placement='top'>
                  <IconButton size='small' onClick={_onVoid} color='error' sx={{padding: '4px 2px'}}>
                    <CloseIcon style={{ width: '14px', height: '14px', background: '#fff' }} />
                  </IconButton>
                </Tooltip>
            }
          </div>
      }
      <Draggable cancel='.need-interaction' onMouseDown={e => {e.preventDefault(); e.stopPropagation();}} onStart={onDragStart} onStop={onDragStop} disabled={fadeOut || !isDraggable} allowAnyClick={false} onClick={onClick}>
        <div className='col-sm-12 col-xs-12 movement' style={containerStyle} onClick={onClick}>
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '-2px' }} onClick={onClick}>
            <span className='ellipsis'><b>{movement.customer}</b></span>
          </div>
          <div style={{ fontSize: '12px' }} className='ellipsis'>
            {movement.identifier}
          </div>
          <div style={{ fontSize: '12px' }} className='ellipsis'>
            {`${movement.commodity} - ${movement.inloadGrade || movement.outloadGrade || selectOrder?.plannedGrade} - ${round(movement.plannedTonnage)} MT`}
          </div>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div style={{ width: '4%', marginRight: '3%' }}>
              <SiteConnector />
            </div>
            <div style={{ width: '93%' }}>
              <div style={{ fontSize: '12px' }} className='ellipsis'>
                {getPickupLabel()}
              </div>
              <div style={{ fontSize: '12px' }} className='ellipsis'>
                {getDeliveryLabel()}
              </div>
            </div>
          </div>
        </div>
      </Draggable>
      {
        !dragging &&
          <div style={{display: 'flex', position: 'absolute', zIndex: 1, right: '0px', bottom: '0px', justifyContent: 'end'}}>
            <Button variant='text' size='small' sx={{textTransform: 'none', justifyContent: 'right', padding: 0, paddingRight: '4px', fontSize: '12px'}} className='need-interaction' onClick={onClick}>
              View
            </Button>
          </div>
      }
    </div>
  );
};

export default MovementTemplate;

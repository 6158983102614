import axios from "axios";
import { isLoading, forceStopLoader } from '../main';
import alertifyjs from 'alertifyjs';
import get from 'lodash/get';
import { getStorages } from '../api/storages';
import APIService from '../../services/APIService';
import packageJson from '../../../package.json';

const VERSION = packageJson?.version

export const RECEIVE_STORAGES = 'RECEIVE_STORAGES';
export const RECEIVE_ARCHIVED_STORAGES = 'RECEIVE_ARCHIVED_STORAGES';
export const ADD_STORAGE = 'ADD_STORAGE';
export const REMOVE_STORAGE = 'REMOVE_STORAGE';
export const EDIT_STORAGE = 'EDIT_STORAGE';
export const CANCEL_EDIT_STORAGE = 'CANCEL_EDIT_STORAGE';
export const CLICK_ADD_STORAGE_BUTTON = 'CLICK_ADD_STORAGE_BUTTON';
export const CLICK_EDIT_STORAGE_ICON = 'CLICK_EDIT_STORAGE_ICON';
export const ADD_STORAGE_ERRORS = 'ADD_STORAGE_ERRORS';
export const EDIT_STORAGE_ERRORS = 'EDIT_STORAGE_ERRORS';
export const RECEIVE_SYSTEM_STORAGES = 'RECEIVE_SYSTEM_STORAGES';
export const ADD_SYSTEM_STORAGE = 'ADD_SYSTEM_STORAGE';
export const EDIT_SYSTEM_STORAGE = 'EDIT_SYSTEM_STORAGE';
export const CLICK_ADD_SYSTEM_STORAGE_BUTTON = 'CLICK_ADD_SYSTEM_STORAGE_BUTTON';
export const ADD_SYSTEM_STORAGE_ERRORS = 'ADD_SYSTEM_STORAGE_ERRORS';
export const CHANGE_REFRESH_STOCKS_STATUS = 'CHANGE_REFRESH_STOCKS_STATUS';
export const SET_SELECTED_STORAGE_FOR_STOCK = 'SET_SELECTED_STORAGE_FOR_STOCK';
export const RECEIVE_STORAGE_LOADS = 'RECEIVE_STORAGE_LOADS';

export const setSelectedStorageForStocks = item => ({
  type: SET_SELECTED_STORAGE_FOR_STOCK,
  item,
});

export const receiveStorages = items => ({
  type: RECEIVE_STORAGES,
  items,
});

export const receiveArchivedStorages = items => ({
  type: RECEIVE_ARCHIVED_STORAGES,
  items,
});

export const addStorage = item => ({
  type: ADD_STORAGE,
  item,
});

export const addStorageErrors = errors => ({
  type: ADD_STORAGE_ERRORS,
  errors,
});

export const removeStorage = item => ({
  type: REMOVE_STORAGE,
  item
});

export const editStorage = item => ({
  type: EDIT_STORAGE,
  item,
});

export const editStorageErrors = errors => ({
  type: EDIT_STORAGE_ERRORS,
  errors,
});

export const cancelEditStorage = () => ({
  type: CANCEL_EDIT_STORAGE,
});

export const clickAddStorageButton = () => ({
  type: CLICK_ADD_STORAGE_BUTTON,
});

export const clickEditStorageIcon = itemId => ({
  type: CLICK_EDIT_STORAGE_ICON,
  itemId,
});

export const receiveSystemStorages = items => ({
  type: RECEIVE_SYSTEM_STORAGES,
  items,
});

export const addSystemStorage = item => ({
  type: ADD_SYSTEM_STORAGE,
  item,
});

export const addSystemStorageErrors = errors => ({
  type: ADD_SYSTEM_STORAGE_ERRORS,
  errors,
});

export const clickAddSystemStorageButton = () => ({
  type: CLICK_ADD_SYSTEM_STORAGE_BUTTON,
});

export const changeRefreshStocksStatus = status => ({
  type: CHANGE_REFRESH_STOCKS_STATUS,
  status,
});

export const receiveStorageLoads = (items, storageId) => ({
  type: RECEIVE_STORAGE_LOADS,
  items,
  storageId
});

export const getStorageLoads = (url, _forceStopLoader) => {
  return (dispatch, getState) => {
    const config = {
      headers: {
        'Content-type': 'application/json',
        accept: 'application/json',
        Authorization: `Token ${getState().main.user.token}`,
        'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
        'WEB-VERSION': VERSION,
        'REFERER-COUNTRY': localStorage.current_country
      },
    };
    let storageId = getState().companies.systemStorages.storageId;
    if(!storageId && !url)
      return;

    axios.get(url, config)
      .then((response) => {
        dispatch(receiveStorageLoads(response?.data, storageId))
        if (_forceStopLoader)
          dispatch(forceStopLoader())
      })
      .catch((error) => {
        throw (error);
      });
  };
}

export const archiveStorages = (item, actionCreator) => dispatch => {
    alertifyjs.confirm(
      'Archive Storage',
      `Are you sure you want to archive this storage, ${item.name}?`,
      () =>
      {
        APIService.storages(item.id)
          .appendToUrl('archive/')
          .put({'storage_id': `${item.id}`})
          .then(response => {
            if(get(response, 'message')) {
              dispatch(forceStopLoader())
              alertifyjs.alert(
                'Archive Storage',
                get(response, 'message'),
                () => {}
              );
            }
            else
              alertifyjs.success('Storage Archived', 1, () => {
                dispatch(forceStopLoader())
              dispatch(actionCreator(item));
             });
          });
      },
      () => {},
    );
};

export const activateStorage = item => dispatch => {
  alertifyjs.confirm(
    'Activate Storage',
    `Are you sure you want to activate this storage, ${item.name}?`,
    () =>
    {
      APIService.storages(item.id)
        .appendToUrl('activate/')
        .put({'storage_id': `${item.id}`})
        .then(() => {
          alertifyjs.success('Storage Unarchived', 1, () => {
            dispatch(getStorages(item.farmId, receiveArchivedStorages, '?no_stocks&archived'));
            dispatch(forceStopLoader())
         });
        })
    },
    () => {},
  );
};

export const updateStorageStocks = data => dispatch => {
  dispatch(isLoading("companyStocks"))
  APIService.storages()
    .appendToUrl('stocks/update/')
    .put(data)
    .then(response => {
      if (get(response, 'data')) {
        dispatch(forceStopLoader())
        alertifyjs.alert(
          'Update Storage Stocks',
          get(response, 'data.message'),
          () => { }
        );
      }
      else if (get(response, 'errors')) {
        dispatch(forceStopLoader())
        alertifyjs.error(get(response, 'errors'), 3)
      }
      else
        alertifyjs.success('Storage Stocks Updated', 1, () => {
          dispatch(forceStopLoader())
          window.location.reload();
        });
    });
};

export const emptyStorages = data => dispatch => {
  dispatch(isLoading("companyStocks"))
  APIService.storages()
    .appendToUrl('stocks/update/?empty=true')
    .put(data)
    .then(response => {
      if (get(response, 'data')) {
        dispatch(forceStopLoader())
        alertifyjs.alert(
          'Empty Stock',
          get(response, 'data.message'),
          () => { }
        );
      }
      else if (get(response, 'errors')) {
        dispatch(forceStopLoader())
        alertifyjs.error(get(response, 'errors'), 3)
      }
      else
        alertifyjs.success('Stock Emptied', 1, () => {
          dispatch(forceStopLoader())
          window.location.reload();
        });
    });
};

export const transferStocksStorageToStorage = data => dispatch => {
  APIService.storages()
    .appendToUrl('silo-to-silo-transfer/')
    .post(data)
    .then(response => {
      if (get(response, 'errors')) {
        dispatch(forceStopLoader())
        alertifyjs.error(response.errors || 'An Error Occurred', 3);
      }
      else
        alertifyjs.success('Stocks Transferred', 0.5, () => {
          window.location.reload();
        });
    });
};

export const transferStocksStorageToStorageUpdate = data => dispatch => {
  APIService.storages()
    .appendToUrl('silo-to-silo-transfer/')
    .put(data)
    .then(response => {
      if (get(response, 'errors')) {
        dispatch(forceStopLoader())
        alertifyjs.error(response.errors || 'An Error Occurred', 3);
      }
      else
        alertifyjs.success('Stocks Updated', 1, () => {
          window.location.reload();
        });
    });
};

export const updateRegradedStocks = (data, identifier) => dispatch => {
  APIService.loads()
    .appendToUrl(`identifier/${identifier}/regrade/`)
    .put(data)
    .then(response => {
      if (get(response, 'errors')) {
        dispatch(forceStopLoader())
        alertifyjs.error(response.errors, 3);
      }
      else if (get(response, 'data')) {
        dispatch(forceStopLoader())
        alertifyjs.alert(
          'Update Storage Stocks',
          get(response, 'data.message'),
          () => { }
        );
      }
      else
        alertifyjs.success('Storage Stocks Updated', 1, () => {
          window.location.reload();
        });
    });
};


export const createRegradedStock = (data) => dispatch => {
  dispatch(isLoading("companyStocks"))
  APIService.loads()
    .appendToUrl('regrade-reseason/')
    .post(data)
    .then(response => {
      if (get(response, 'errors')) {
        dispatch(forceStopLoader())
        alertifyjs.error(response.errors, 3);
      }
      else if (get(response, 'data')) {
        dispatch(forceStopLoader())
        alertifyjs.alert(
          'Update Storage Stocks',
          get(response, 'data.message'),
          () => { }
        );
      }
      else
        alertifyjs.success('Storage Stocks Updated', 1, () => {
          dispatch(forceStopLoader())
          window.location.reload();
        });
    });
}

import React from 'react';
import { Card } from '@mui/material';
import { get, chunk, map, orderBy, isEmpty, find } from 'lodash';

const TEXT_ELLIPSIS = {whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'};
const DIV_LABEL_DETAILS_STYLES = {fontSize: '14px', color: 'rgba(0,0,0,0.5)', marginTop: '16px', ...TEXT_ELLIPSIS};
const DIV_VALUE_DETAILS_STYLES = {fontWeight: 'bold', marginBottom: '16px', ...TEXT_ELLIPSIS};
const SPAN_DETAILS_STYLES = {fontSize: '12px', color: 'rgba(0,0,0,0.5)', ...TEXT_ELLIPSIS};
const BORDER_RIGHT = '1px solid lightgray';
const LOADING_TEXT = 'Loading...';
const EMPTY = '-';

class StorageLoadsCard extends React.Component {
  remainingSpace() {
    const { storage, summary } = this.props;
    if(get(storage, 'isGate'))
      return EMPTY;

    if(summary && summary.stock && summary.capacity) {
      return (summary.capacity - Math.max(summary.stock, 0)).toFixed(2) + ' ' + (summary?.requestedUnit || 'MT');
    }

    return EMPTY;
  }

  stockClassName() {
    const { summary } = this.props;
    let className = '';
    if(summary) {
      if(summary.stock > 0)
        className = 'positive-number';
      if(summary.stock < 0)
        className = 'negative-number';
    }

    return className;
  }

  render() {
    const {
      commodity, storage, summary,
      gradeText, seasonText, ngrText, varietyText,
    } = this.props;
    const commodityText = get(commodity, 'displayName', EMPTY);
    const isEmptyCommodity = commodityText === EMPTY;
    const requestedUnit = summary?.requestedUnit || 'MT'
    const targetMoistures = get(storage, 'targetMoistures');
    let storageCommodityTargetMoisture = '';
    if (targetMoistures && !isEmpty(targetMoistures) && commodity)
      storageCommodityTargetMoisture = get(find(targetMoistures, {commodityId: commodity?.id}), 'percentage')
    return (
      <Card>
        <div className='col-sm-12 padding-reset' style={{display: 'flex', alignItems: 'center'}}>
          <div className='col-sm-6 padding-reset' style={{display: 'flex', alignItems: 'center'}}>
            <div className='col-sm-5 padding-reset' style={{borderRight: BORDER_RIGHT, textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', flexDirection: 'column'}}>
              <h2 style={{...TEXT_ELLIPSIS, padding: '0 10px', margin: '0px'}}>{get(storage, 'name', LOADING_TEXT)}</h2>
              {
                storageCommodityTargetMoisture &&
               <span style={{fontSize: '14px', color: 'rgba(0,0,0,0.5)'}}>Target Moisture: {`${storageCommodityTargetMoisture} %`}</span>
              }
            </div>
            <div className='col-sm-5' style={{textAlign: 'center', borderRight: BORDER_RIGHT}}>
              <div style={DIV_LABEL_DETAILS_STYLES}>
                Commodity
              </div>
              <div style={DIV_VALUE_DETAILS_STYLES}>
                {commodityText}
              </div>
            </div>
            <div className='col-sm-2' style={{borderRight: BORDER_RIGHT, textAlign: 'center'}}>
              <div style={DIV_LABEL_DETAILS_STYLES}>
                Grade
              </div>
              <div style={DIV_VALUE_DETAILS_STYLES}>
                {isEmptyCommodity ? EMPTY : get(summary, 'grade') || gradeText || EMPTY}
              </div>
            </div>
          </div>
          <div className='col-sm-6 padding-reset' style={{display: 'flex', alignItems: 'center'}}>
            <div className='col-sm-6 padding-reset' style={{borderRight: BORDER_RIGHT}}>
              <div className='col-sm-6 padding-reset'>
                <div className='col-sm-12' style={{marginTop: '13px'}}>
                  <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                    Stock
                  </span>
                  <span
                    className={this.stockClassName() + ' col-sm-6 padding-reset'}
                    style={{fontSize: '12px'}}>
                    {get(summary, 'stock', EMPTY)} {requestedUnit}
                  </span>
                </div>
                <div className='col-sm-12'>
                  <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                    Capacity
                  </span>
                  <span className='col-sm-6 padding-reset' style={{fontSize: '12px', ...TEXT_ELLIPSIS}}>
                    {get(storage, 'isGate') ? EMPTY : `${get(summary, 'capacity', EMPTY)} ${requestedUnit}`}
                  </span>
                </div>
                <div className='col-sm-12' style={{marginBottom: '4px'}}>
                  <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                    Space
                  </span>
                  <span className='col-sm-6 padding-reset' style={{fontSize: '12px', ...TEXT_ELLIPSIS}}>
                    {this.remainingSpace()}
                  </span>
                </div>
              </div>
              <div className='col-sm-6 padding-reset'>
                <div className='col-sm-12' style={{marginTop: '13px'}}>
                  <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                    Variety
                  </span>
                  <span className='col-sm-6 padding-reset' style={{fontSize: '12px', ...TEXT_ELLIPSIS}}>
                    {isEmptyCommodity ? EMPTY : get(summary, 'variety') || varietyText}
                  </span>
                </div>
                <div className='col-sm-12'>
                  <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                    Season
                  </span>
                  <span className='col-sm-6 padding-reset' style={{fontSize: '12px', ...TEXT_ELLIPSIS}}>
                    {isEmptyCommodity ? EMPTY : get(summary, 'season') || seasonText}
                  </span>
                </div>
                <div className='col-sm-12' style={{marginBottom: '10px'}}>
                  <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                    NGR
                  </span>
                  <span className='col-sm-6 padding-reset' style={{fontSize: '12px', ...TEXT_ELLIPSIS}}>
                    {isEmptyCommodity ? EMPTY : ngrText}
                  </span>
                </div>
              </div>
            </div>
            <div className='col-sm-6 padding-reset' style={{display: 'flex', alignItems: 'center'}}>
              {
                map(chunk(map(orderBy(get(commodity, 'specs', []), 'order').slice(0, 6), 'code'), 3), (specGroup, index) => (
                  <div className='col-sm-6 padding-reset' key={index}>
                    {
                      map(specGroup, (spec, i) => {
                        let styles = {};
                        if(i === 0)
                          styles = {marginTop: '13px'};
                        if(i === 2)
                          styles = {marginBottom: '5px'};
                        let specValue = get(summary, `specs.${spec.toLowerCase()}`);
                        if(specValue)
                          specValue = specValue.toFixed(2);
                        return (
                          <div className='col-sm-12' style={styles} key={i}>
                            <span className='col-sm-6 padding-reset' style={SPAN_DETAILS_STYLES}>
                              {spec}
                            </span>
                            <span className='col-sm-6 padding-reset' style={{fontSize: '12px'}}>
                              {specValue || EMPTY}
                            </span>
                          </div>
                        );
                      })
                    }
                  </div>
                )
                )
              }
            </div>
          </div>
        </div>
      </Card>
    );
  }
}

export default StorageLoadsCard;

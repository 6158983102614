export const contractStatusLegends = [
  'Private',
  'Delayed',
  'Error',
  'Payment Overdue',
  'Amended'
];

export const MOVEMENT_CONFIRMED = 'Movement is accepted';
export const ASSIGN_CONTRACT_FO_SUCCESS = 'Assigned contract/freight order successfully';
export const ASSIGN_CONTRACT_PO_SUCCESS = 'Assigned pack order successfully';
export const OUTLOAD = 'outload';
export const INLOAD = 'inload';
export const SPLIT_INLOAD = 'splitInload';
export const SPLIT_OUTLOAD = 'splitOutload';
export const CONTRACT_INVOICED_SUCCESS = "Contract has been successfully invoices";
export const PACKED_CONTAINER = 'packedContainer'

import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Table,
  TableHead, TableRow, TableCell, TableBody
} from '@mui/material';
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LoaderInline from '../../LoaderInline';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import InloadOutloadConstDrawer from '../../freights/MovementDetails/InloadOutloadConstDrawer';
import { OUTLOAD, INLOAD, PACKED_CONTAINER } from '../../freights/MovementDetails/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { canAddLoadsForMovement, shouldFetchFreightMovement, getSelectedFreight, confirmationResponse, resetIsFetchingSelectedFreight } from '../../../actions/companies/freights';
import { useEffect } from 'react';
import { isLoading } from '../../../actions/main';
import get from 'lodash/get';
import { openURLInNewTab, getCountryLabel } from '../../../common/utils';
import APIService from '../../../services/APIService';
import { PACK_ORDER_TYPE_ID } from '../../../common/constants';

let MovementLoadDetails = props =>{

    const [options, setOptions] = useState({
        isInloadConstSideDrawerOpened: false,
        isOutloadConstSideDrawerOpened: false
    });

    const [contractListInfo, setContractListInfo] = useState({
        isFetching: true,
        contracts: []
    });

    const [selectedContract, setSelectedContract] = useState(null);

    const dispatch = useDispatch();

    let getColumnValue = value =>{
        return value === 'None (None)' || !value ? '-' : value;
    };

  const getData = () => {
    APIService.freights().appendToUrl('contracts/completion/pending/?missing_load_details=true').get().then(contracts => {
      const isOk = isArray(contracts);
      setContractListInfo({
        isFetching: !isOk,
        contracts: isOk ? contracts : []
      });
      props.onDataLoad(contracts.length);

    });
  };
    let {shouldFetchMovement, selectedFreightMovement} = useSelector(state =>{
        return {
            "shouldFetchMovement": state.companies.freights.shouldFetchMovement,
            "selectedFreightMovement": state.companies.freights.confirmedOrRejectedContract,
            };
        }
    );


    useEffect(() => {
        if (shouldFetchMovement && selectedContract && get(selectedFreightMovement, 'id') !== selectedContract){
            dispatch(isLoading("movementDetail"));
            dispatch(
                getSelectedFreight(selectedContract, confirmationResponse, false, false, false, false, true)
            );
            dispatch(resetIsFetchingSelectedFreight());
            dispatch(shouldFetchFreightMovement(false));
        }
    }, [shouldFetchMovement]);


    let getTitle = () =>{
        let suffix = contractListInfo.isFetching ? '' : `(${contractListInfo.contracts.length})`;
        return `Missing Load Details ${suffix}`;
    };

    let checkLoads = (event, type, contract)=>{
        dispatch(canAddLoadsForMovement(event, contract.id, () =>
        setOptions({...options, [type]: true})));
        dispatch(shouldFetchFreightMovement(true));
        setSelectedContract(contract.id);
    };

    let closeDrawer = key =>{
        setOptions({...options, [key]: false});
        dispatch(shouldFetchFreightMovement(false));
    };

  let onChange = (event, expanded) => {
    if(expanded && isEmpty(contractListInfo.contracts))
      getData();
  };

  const isPackMovement = get(selectedFreightMovement, 'typeId') === PACK_ORDER_TYPE_ID;

  let onRowClick = (event, contractId) => {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`#/freights/movements/${contractId}/details`);
  }

  return(<div className="action-centre-group">
    {options.isOutloadConstSideDrawerOpened && get(selectedFreightMovement, 'id') == selectedContract &&
     <InloadOutloadConstDrawer
       type={OUTLOAD}
       isOpen={options.isOutloadConstSideDrawerOpened}
       onClose={() => closeDrawer("isOutloadConstSideDrawerOpened")}
       movement={selectedFreightMovement}
       identifier={get(selectedFreightMovement, 'identifier')}
     />
    }
    {options.isInloadConstSideDrawerOpened && get(selectedFreightMovement, 'id') == selectedContract  &&
     <InloadOutloadConstDrawer
       type={isPackMovement ? PACKED_CONTAINER : INLOAD}
       isOpen={options.isInloadConstSideDrawerOpened}
       onClose={() => closeDrawer("isInloadConstSideDrawerOpened")}
       movement={selectedFreightMovement}
       identifier={get(selectedFreightMovement, 'identifier')}
     />
    }
    <Accordion style={{margin: '0px'}} onChange={onChange}>
      <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon/>}>
        {getTitle()}
      </AccordionSummary>
      <AccordionDetails style={{padding: '0px'}}>
        {
          contractListInfo.isFetching ?
          <LoaderInline containerClassName="inline-loader-container" /> :
          <div className="table-container">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="xsmall">Movement No.</TableCell>
                  <TableCell align='center' className="xsmall">Customer</TableCell>
                  <TableCell align='center' className="xsmall">Freight Provider</TableCell>
                  <TableCell align='center' className="xsmall">{getCountryLabel('rego')}</TableCell>
                  <TableCell align='center' className="medium">Commodity Description</TableCell>
                  <TableCell align='center' className="xsmall">Pickup</TableCell>
                  <TableCell align='center' className="xsmall">Delivery</TableCell>
                  <TableCell align='center' className="medium">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  isArray(contractListInfo.contracts) ?
                  contractListInfo.contracts.map(contract => (
                    <TableRow
                      className="cursor-pointer row-with-data"
                      key={contract.referenceNumber}
                      onClick={(event) => onRowClick(event, contract.id)}
                      >
                      <TableCell className="xsmall">{getColumnValue(contract.identifier)}</TableCell>
                      <TableCell align='center' className="xsmall">{getColumnValue(contract.customer)}</TableCell>
                      <TableCell align='center' className="xsmall">{getColumnValue(contract.freightProvider)}</TableCell>
                      <TableCell align='center' className="xsmall">{getColumnValue(contract.rego)}</TableCell>
                      <TableCell align='center' className="medium">{getColumnValue(contract.commodityDescription)}</TableCell>
                      <TableCell align='center' className="xsmall">{getColumnValue(contract.pickupDetails)}</TableCell>
                      <TableCell align='center' className="xsmall">{getColumnValue(contract.deliveryDetails)}</TableCell>
                      <TableCell align='center' className="xsmall">
                        <Link to="#" onClick={event => checkLoads(event, "isOutloadConstSideDrawerOpened", contract)} style={{marginRight: '8px'}}>{contract.addOrEditOutloadLabel}</Link>
                        <Link to="#" onClick={event => checkLoads(event, "isInloadConstSideDrawerOpened", contract)}>{contract.addOrEditInloadLabel}</Link>
                      </TableCell>
                    </TableRow>
                  )) :
                  <TableRow>
                    <TableCell colSpan="100" className='no-record-column'>
                      No records found
                    </TableCell>
                  </TableRow>
                }
              </TableBody>
            </Table>
          </div>
        }
      </AccordionDetails>
    </Accordion>

  </div>);

};

export default MovementLoadDetails;

import React from 'react';
import { Button } from '@mui/material/';
import { set, get, map, mapValues, every, isEmpty, forEach, cloneDeep } from 'lodash';
import { REQUIRED_FIELD, FIELD } from '../../../common/constants';
import { positiveDecimalFilter } from '../../../common/input-filters';
import CommonTextField from '../../common/CommonTextField';
import CommonDatePicker from '../../common/CommonDatePicker';
import CommonSelect from '../../common/select/CommonSelect';
import { required } from '../../../common/validators';
import { PER_TON_MEASUREMENT } from '../constants';

class ChemicalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {
        name: cloneDeep(REQUIRED_FIELD),
        rate: cloneDeep(REQUIRED_FIELD),
        date: cloneDeep(REQUIRED_FIELD),
        periodType: cloneDeep(FIELD),
        measurement: {
          value: PER_TON_MEASUREMENT,
          validators: [required()],
          errors: [],
        },
      }
    };
  }

  componentDidMount() {
    const { selectedChemical } = this.props;
    if(selectedChemical)
      this.setFieldsFromSelected(selectedChemical);
  }

  setFieldError(field, callback) {
    const newState = {...this.state};
    const fieldState = get(newState.fields, field);
    fieldState.errors = this.getFieldErrors(field);
    this.setState(newState, () => {
      if(callback)
        callback();
    });
  }

  getFieldErrors(field) {
    let errors = [];
    const fieldState = get(this.state.fields, field);
    if(!fieldState)
      return errors;
    fieldState.validators.forEach(validator => {
      if(validator.isInvalid(fieldState.value))
        errors.push(validator.message);
      else
        errors = [];
    });

    return errors;
  }

  setAllFieldErrors() {
    const newState = {...this.state};
    forEach(newState.fields, (state, field) => {
      state.errors = this.getFieldErrors(field);
    });
    this.setState(newState, this.propagateChanges);
  }

  isValidForm() {
    return every(this.state.fields, state => isEmpty(state.errors));
  }

  setFieldsFromSelected(chemical) {
    const newState = {...this.state};
    newState.fields.name.value = chemical.name;
    newState.fields.rate.value = chemical.rate;
    newState.fields.date.value = chemical.date;
    newState.fields.periodType.value = chemical.periodType;
    newState.fields.measurement.value = chemical.measurement;
    this.setState(newState);
  }

  onChange = event => {
    const newState = {...this.state};
    set(newState.fields, `${event.target.id}.value`, event.target.value);
    this.setState(newState, () => this.setFieldError(event.target.id));
  };

  getPeriodTypeOptions() {
    const { constants } = this.props;
    return map(get(constants, 'chemicalPeriodTypes', []), item => ({id: item[0], name: item[1]}));
  }

  getMeasurementOptions() {
    const { constants } = this.props;
    return map(get(constants, 'chemicalMeasurements', []), item => ({id: item[0], name: item[1]}));
  }

  getNewId() {
    return Math.random().toString(36).substring(7);
  }

  getData() {
    const {selectedChemical} = this.props;
    const data = mapValues(this.state.fields, 'value');

    data.id = get(selectedChemical, 'id') || this.getNewId();

    return data;
  }

  submit = event => {
    event.preventDefault();
    this.setAllFieldErrors();
    if(this.isValidForm()) {
      this.props.onSubmit(this.getData());
      this.props.onClose();
    }
  };

  render() {
    const periodTypeOptions = this.getPeriodTypeOptions();
    const measurementOptions = this.getMeasurementOptions();

    return (
      <div className='col-md-12 padding-reset' style={{margin: '20px 0'}}>
        <div className="col-md-12 padding-reset" style={{marginBottom: '20px'}}>
          <CommonTextField
            id='name'
            label="Chemical Applied"
            value={this.state.fields.name.value}
            helperText={this.state.fields.name.errors[0]}
            onChange={this.onChange}
          />
        </div>
        <div className="col-md-12 padding-reset" style={{marginBottom: '20px'}}>
          <CommonSelect
            id='measurement'
            floatingLabelText='Application Measurement'
            items={measurementOptions}
            selectConfig={{text: 'name', value: 'id'}}
            onChange={(value, id) => this.onChange({target: {id: id, value: value}})}
            value={this.state.fields.measurement.value}
          />
        </div>
        <div className="col-md-12 padding-reset" style={{marginBottom: '20px'}}>
          <CommonTextField
            id='rate'
            label="Rate of Application"
            value={this.state.fields.rate.value}
            helperText={this.state.fields.rate.errors[0]}
            onChange={this.onChange}
            onKeyDown={event => positiveDecimalFilter(event, 2, 99999.99)}
            type='number'
          />
        </div>
        <div className="col-md-12 padding-reset" style={{marginBottom: '20px'}}>
          <CommonDatePicker
            id='date'
            floatingLabelText='Application Date'
            onChange={(value, id) => this.onChange({target: {id: id, value: value}})}
            errorText={this.state.fields.date.errors[0]}
            value={this.state.fields.date.value}
          />
        </div>
        <div className="col-md-12 padding-reset" style={{marginBottom: '20px'}}>
          <CommonSelect
            id='periodType'
            floatingLabelText='WHP/ ESI/ EAFI'
            items={periodTypeOptions}
            selectConfig={{text: 'name', value: 'id'}}
            onChange={(value, id) => this.onChange({target: {id: id, value: value}})}
            value={this.state.fields.periodType.value}
          />
        </div>
        <div className="col-md-12 padding-reset">
          <Button
            type='button'
            variant="outlined"
            color='black'
            onClick={this.props.onClose}
            style={{marginRight: '20px'}}>
            Cancel
          </Button>
          <Button type='button' variant="contained" color='primary' onClick={this.submit}>
            Submit
          </Button>
        </div>
      </div>
    );
  }
}

export default ChemicalForm;

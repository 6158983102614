import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import size from 'lodash/size';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import includes from 'lodash/includes'
import compact from 'lodash/compact'
import startsWith from "lodash/startsWith";
import {WARNING_ORANGE_CLASSES} from "../../../common/constants";
import withStyles from '@mui/styles/withStyles';
import map from 'lodash/map';
const styles = () => ({
  ...WARNING_ORANGE_CLASSES,
});

class CommonSelect extends React.Component {
  constructor(props) {
    super (props);

    this.defaultValue = this.props.multiple ? [] : '';
    this.state = {
      value: this.props.value || this.defaultValue,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.items, prevProps.items)) {
      if (size(this.props.items) === 1 && !this.props.dontAutoselectSingleItem) {
        this.setValueAndPropagate(get(this.props.items, `0.${get(this.props, 'selectConfig.value', 'id')}`));
      } else if (this.props.selectedItemId){
        const item = find(this.props.items, { id: parseInt(this.props.selectedItemId) });
        this.setValueAndPropagate(get(item, 'id', ''));
      } else if (this.state.value !== this.props.value && this.props.value){
        const item = find(this.props.items, { id: parseInt(this.props.value) });
        this.setValueAndPropagate(get(item, 'id', ''));
      }
    }

    if(isNumber(this.props.selectedItemId) && prevState.value === ''){
      const item = find(this.props.items, { id: parseInt(this.props.selectedItemId) });
      this.setValueAndPropagate(get(item, 'id', ''));
    }

    if(!isEqual(this.props.value, prevProps.value) && !isEmpty(this.props.items)){
      var item = null;
      if(isNumber(this.props.value)){
        item = find(this.props.items, { id: parseInt(this.props.value) });
      } else {
        item = find(this.props.items, { id: this.props.value });
      }
      let defaultValue = this.props.multiple ? [] : null;
      this.setValueAndPropagate(!isEmpty(item) ? get(item, 'id', '') : defaultValue);
    }

    if(prevState.value !== '' && this.props.value !== null && !isEqual(this.state.value, prevState.value) && this.props.restorePrev){
      var findBy = isNumber(this.props.value) ? { id: parseInt(this.props.value) } : { id: this.props.value };
      var entity = find(this.props.items, findBy);
      let defaultValue = this.props.multiple ? [] : null;
      var val = !isEmpty(entity) ? get(entity, 'id', '') : defaultValue;
      this.setState({ value: val });
    }

    if(this.props.clearSelectedItems && !isEqual(this.props.clearSelectedItems, prevProps.clearSelectedItems)){
      this.setState({value: this.defaultValue});
    }
  }

  handleChange(event) {
    this.setValueAndPropagate(event.target.value);
  }

  setValueAndPropagate(value) {
    this.setState(
      { value },
      () => {
        if (this.props.onChange) this.props.onChange(value, this.props.id, find(this.props.items, {id: value}));
      }
    );
  }

  render() {
    const items = this.props.items || [];

    return (
      <div className={this.props.disabled && this.state.value ? "relative-pos text-black": "relative-pos"}>
        <FormControl
          error={!!this.props.errorText}
          disabled={this.props.disabled || false}
          style={{width: '100%'}}
          variant="standard">
          <InputLabel htmlFor={this.props.id} classes={{
            error: startsWith(this.props.errorText, 'Warning') ? this.props.classes.warningColor : null,
          }}>{this.props.floatingLabelText}</InputLabel>
          {
            this.props.showOnlyEmptyOption &&
            isEmpty(items) ?
            <Select variant="standard" className='mui-select'>
              <MenuItem value="">
                <em>{this.props.emptyOptionText || "None"}</em>
              </MenuItem>
            </Select> :
            <React.Fragment>
              <Select
                className={(this.props.defaultSelectClass || '') + (startsWith(this.props.errorText, 'Warning') ? this.props.classes.warningUnderline : '') + ' mui-select'}
                id={this.props.id}
                value={includes([null, undefined], this.state.value) ? '' : this.state.value}
                onChange={this.handleChange}
                style={this.props.style}
                multiple={this.props.multiple}
                inputProps={{
                  ref: this.props.setRef,
                }}
                variant="standard">
                {
                  this.props.includeEmptyOption &&
                  <MenuItem value={null}>
                    <em>{this.props.emptyOptionText || "None"}</em>
                  </MenuItem>
                }
                {
                  map(compact(items), item => {
                    return (
                      <MenuItem
                        classes={get(item, 'keyContact') ? {root: 'key-contact'} : null}
                        key={item[get(this.props, 'selectConfig.value', 'id')]}
                        value={item[get(this.props, 'selectConfig.value', 'id')]}
                        >
                        {item[get(this.props, 'selectConfig.text', 'name')]}
                      </MenuItem>
                    );
                  })
                }
              </Select>
              {
                Boolean(this.props.disabled && this.state.value) &&
                <i className="icon-lock" />
              }
              <FormHelperText classes={{
                error: startsWith(this.props.errorText, 'Warning') ? this.props.classes.warningColor : null,
              }}>
                {this.props.errorText}
              </FormHelperText>
            </React.Fragment>
          }
        </FormControl>
      </div>
    );
  }
}

export default withStyles(styles)(CommonSelect);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import CommonButton from '../common/CommonButton';
import get from 'lodash/get';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import { formatPrice } from '../../common/utils';
import LoaderInline from '../LoaderInline';
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

class AddManuallyMarkPaidForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            serverErrors: [],
            termsAccepted: false,
            isDisabledClick: false,
            isSubmittingForm: false,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addPayment = this.addPayment.bind(this);
        this.handleTermsAcceptedChange = this.handleTermsAcceptedChange.bind(this);
    }

    handleTermsAcceptedChange(event) {
        this.setState({ termsAccepted: event.target.checked });
    }

    handleSubmit(event) {
        event.preventDefault();
        if (this.state.termsAccepted)
            this.addPayment();
        else alertifyjs.error('Please accept terms and conditions');
    }

    addPayment() {
        if (!this.props.invoice)
            return;
        APIService.invoices(this.props.invoice.id)
        ['mark-paid']()
            .get()
            .then(response => {
                if (!isEmpty(get(response, 'errors'))) {
                    alertifyjs.error('An Error Occurred!');
                    this.props.onClose();
                } else {
                    alertifyjs.success('Invoice marked paid successfully.', 2, () => window.location.reload());
                    this.setState({ isSubmittingForm: false });
                    this.props.onClose();
                }
            });
    }

    render() {
        const amountPayable = parseFloat(get(this.props, 'invoice.amountPayable', 0)).toFixed(2);
        const invoiceTotal = parseFloat(get(this.props, 'invoice.totalDetails.total', 0)).toFixed(2);
        const amountPaid = parseFloat(get(this.props, 'invoice.amountPaid', 0)).toFixed(2);
        const currency = get(this.props, 'invoice.currency');
        return (
            <div ref={this.formRef}>
                {this.state.isSubmittingForm ? (
                    <LoaderInline style={{ height: '500px' }} containerClassName='inline-loader-container' imageStyle={{ width: '25%' }} />
                ) : (
                    <form onSubmit={this.handleSubmit} noValidate>
                        <div className='cardForm cardForm--drawer' id='invoice-add-payment-form'>
                            <div className='cardForm-content invoiced-amount-wrapper'>
                                <div className='col-sm-12 padding-reset'>
                                    <div className='col-sm-4 invoiced-amount-container'>
                                        <label className='invoiced-amount-label'>Invoice Amount</label>
                                        <p className='invoiced-amount'>{formatPrice(invoiceTotal, `${currency} 0.00`, currency)}</p>
                                    </div>
                                    <div className='col-sm-4 invoiced-amount-container'>
                                        <label className='invoiced-amount-label'>Invoice Paid</label>
                                        <p className='invoiced-amount'>{formatPrice(amountPaid, `${currency} 0.00`, currency)}</p>
                                    </div>
                                    <div className='col-sm-4 invoiced-amount-container'>
                                        <label className='invoiced-amount-label'>Amount Payable</label>
                                        <p className='invoiced-amount'>{formatPrice(amountPayable, `${currency} 0.00`, currency)}</p>
                                    </div>
                                </div>
                            </div>
                            <div className='cardForm-content row'>
                                <div className='col-sm-12 form-wrap-70'>
                                    <p>By marking this invoice paid, I confirm that:</p>
                                    <ul>
                                        <li>All the required payments against this invoice have been added.</li>
                                        <li>No further payments need to be added against this invoice.</li>
                                    </ul>
                                </div>
                                <div style={{marginTop: "25%"}}>
                                    <div className="col-sm-12 form-wrap-70">
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    color="primary"
                                                    checked={this.state.termsAccepted}
                                                    onChange={this.handleTermsAcceptedChange}
                                                />
                                            }
                                            label="I confirm the below-given Payment Summary of the invoice to be true after marking the invoice paid."
                                        />
                                    </div>
                                    <div className='col-sm-12 form-wrap-70 payment-summary'>
                                        <span className='sub-heading'>Payment Summary</span>
                                        <div className='row padding-reset'>
                                            <div className='col-sm-6' align='left'>
                                                Invoice Amount
                                            </div>
                                            <div className='col-sm-6 payment-summary-value' align='right'>
                                                {formatPrice(invoiceTotal, `${currency} 0.00`, currency)}
                                            </div>
                                        </div>
                                        <div className='row padding-reset payment-summary'>
                                            <div className='col-sm-6' align='left'>
                                                Amount Paid
                                            </div>
                                            <div className='col-sm-6 payment-summary-value' align='right'>
                                                {formatPrice(invoiceTotal, `${currency} 0.00`, currency)}
                                            </div>
                                        </div>
                                        <div className='row padding-reset payment-summary'>
                                            <div className='col-sm-9' align='left'>
                                                Amount Payable/Balance Amount
                                            </div>
                                            <div className='col-sm-3 payment-summary-value' align='right'>
                                              {`${currency} 0.00`}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-sm-12 cardForm-action top15 padding-reset'>
                                <CommonButton variant='outlined' label='Cancel' type='button' default onClick={this.props.onClose} />
                                <CommonButton
                                    disabled={this.state.isDisabledClick}
                                    variant='raised'
                                    primary={true}
                                    label={this.state.isDisabledClick ? 'Saving...' : 'Save'}
                                    type='submit'
                                    className='margin-right-0'
                                />
                            </div>
                        </div>
                    </form>
                )}
            </div>
        );
    }
}

AddManuallyMarkPaidForm.propTypes = {
    onClose: PropTypes.func.isRequired,
    invoice: PropTypes.object.isRequired,
    token: PropTypes.string.isRequired,
    dispatch: PropTypes.func,
    dispatchInvoicePayments: PropTypes.boolean,
    dispatchInvoiceDetails: PropTypes.boolean,
};

const mapStateToProps = state => {
    return {
        token: state.main.user.token,
    };
};

export default connect(mapStateToProps)(AddManuallyMarkPaidForm);

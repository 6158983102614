import React, {useEffect} from 'react';
import Zendesk from "react-zendesk";
import { ZENDESK_KEY } from '../../common/constants';

const ZendeskWidget = (props) => {
  const setting = {
    launcher: {
      label: {
        "en-GB": "Support"
      }
    },
    offset: { horizontal: '40px', vertical: '-10px' },
    zIndex: 999999,
    helpCenter: {
      searchPlaceholder: {
        '*': 'Search'
      },
      messageButton: {
        '*': 'Contact'
      },
      title: {
        'en-GB': 'Support'
      }
    }
  };
  const loadZendesk = () => { 
    document.addEventListener("DOMContentLoaded", () => {
      if (document.readyState === "complete") 
        window.zE(() =>  window.zE.setHelpCenterSuggestions({ search: 'ngr' }))
    });
    if(window.zE) 
      return props.hideZendesk ? window.zE(() => window.zE.hide()) : window.zE(() => window.zE.show());
  }
  
  useEffect(() => {
    if (typeof window.zE !== 'undefined') {
      if (document.readyState === "complete") {
        if(props.hideZendesk)
          window.zE(() => window.zE.hide());
        else
          window.zE(() => window.zE.show());
      }
    }
  }, [props.hideZendesk]);

  return (
    <div>
    { window.ZENDESK_TOGGLE && <Zendesk defer activate="click" zendeskKey={ZENDESK_KEY} {...setting} onLoaded={loadZendesk}/> }
    </div>
  );  
}
export default ZendeskWidget;
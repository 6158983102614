import { ADD_NOTE, RECEIVE_NOTES, RECEIVE_HISTORY } from '../../actions/companies/notes';

const initialState = {
    history: [],
    items: []
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case RECEIVE_NOTES:
            return { ...state, items: action.items };
        case ADD_NOTE:
            return { ...state, items: [action.item, ...state.items] };
        case RECEIVE_HISTORY:
            return { ...state, history: action.items };
        default:
            return state;
    }
};

export default reducer;
import React from 'react';
import { connect } from 'react-redux';
import Paper from '@mui/material/Paper';
import SiteLoadsTable from './SiteLoadsTable';
import APIService from '../../services/APIService';
import isEqual from 'lodash/isEqual';
import { capitalize, has } from 'lodash';
import { getSiteLoads, getSiteLoadsUrl, receiveCompanySiteLoads } from '../../actions/companies/company-sites';
import get from 'lodash/get';
import { getLoadReferences } from './utils';
import { getPageCache } from '../../common/utils';
import { STOCK_SWAP } from '../../common/constants';
import { forceStopLoader } from '../../actions/main';

class SiteLoadsView extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        filters: {}
      }
      this.fetchSiteLoadsWithQueryParams = this.fetchSiteLoadsWithQueryParams.bind(this);
      this.fetchSiteLoads = this.fetchSiteLoads.bind(this);
      this.applyFilters = this.applyFilters.bind(this);
    }

    componentDidMount() {
      this.applyFilters();
      const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
      if(has(cache, 'siteView'))
        this.setState({expanded: cache.siteView.expanded});
      else
        this.updateCache();
      if(this.props.selectedLocation && this.props.selectedLocation !== 'all')
        this.fetchSiteLoads(true)
    }

  updateCache = data => {
    const cache = JSON.parse(get(localStorage, 'stocks', '{}'));
    cache.siteView = {};

    if(data)
      cache.siteView = {...cache.siteView, ...data}

    localStorage.setItem('stocks', JSON.stringify(cache));
  };

    componentDidUpdate(prevProps) {
      if(!isEqual(this.props.selectedLocation, 'all') && this.props.selectedLocation && !isEqual(prevProps.selectedLocation, this.props.selectedLocation)) {
        this.fetchSiteLoads(true);
      }
      if(prevProps.selectedLocation !== this.props.selectedLocation)
        this.updateCache({location: this.props.selectedLocation}, () => this.fetchSiteLoads(true))
    }

    componentWillUnmount() {
      this.props.dispatch(receiveCompanySiteLoads([]))
    }

    getURLSearchQuery() {
      return new URLSearchParams(window.location.hash.split('?')[1]);
    }

    applyFilters() {
      APIService.profiles()
        .filters('site_loads_filters')
        .get(this.props.token)
        .then(res => {
          this.setState(
            {filters: res?.site_loads_filters || {}},
            () => this.props.dispatch(forceStopLoader())
          );
        });
    }

    getURLSearchQueryString() {
      return this.getURLSearchQuery().toString();
    }

    async fetchSiteLoads(skipPage) {
      const { selectedLocation, user, token } = this.props;
      const urlSearchQueryString = this.getURLSearchQueryString();
      if (urlSearchQueryString) {
          this.fetchSiteLoadsWithQueryParams(urlSearchQueryString, selectedLocation, skipPage)
      } else if(selectedLocation !== 'all' && selectedLocation) {
          let url = `${user.companyId}/company_sites/${selectedLocation}/loads/`;
          const cache = getPageCache();
          const cachedPageSize = get(cache, 'pageSize');
          const cachedPage = get(cache, 'page');
          let joiner = '?';
          if(cachedPageSize) {
              url += `${joiner}page_size=${cachedPageSize}`;
              joiner = '&';
          }
          if(cachedPage && !skipPage)
              url += `${joiner}page=${cachedPage}`;
        let siteLoads = await APIService.companies().appendToUrl(url).get(token)
          siteLoads.results.forEach(item => {
              item.subType = capitalize(item.subType);
              item.creator = get(item, 'titleTransferId') ? get(item, 'titleTransferCreator') : get(item, 'creator')
              let refs =  getLoadReferences(item).split(',')
              let identifier = refs.at(-1)
              if(identifier && ! isEqual(identifier, '-10')) {
                  item.identifier = identifier.toUpperCase();
              }
              let typeRefs = getLoadReferences(item, true);
              let type = typeRefs.at(-1);
              if(type && ! get(item, 'shrinkageParent', false))
                  item.type = type.ref;
              else if((get(item, 'optionType') == STOCK_SWAP))
                  item.type = 'Stock Swap';
              else if((get(item, 'shrinkageParent', false)))
                  item.type = 'Shrinkage';
          });
          this.props.dispatch(receiveCompanySiteLoads(siteLoads, selectedLocation));
        }
      this.props.dispatch(forceStopLoader())
    }

    fetchSiteLoadsWithQueryParams(urlSearchQueryString, selectedLocation, skipPage) {
      const queryParams = new URLSearchParams(urlSearchQueryString);
      let searchText = queryParams.get('q') || '';
      const orderBy = queryParams.get('orderBy') || 'name';
      const order = queryParams.get('order') || 'desc';
      const pageSize = parseInt(queryParams.get('pageSize') || '10');
      let page = '1';
      if(! skipPage) {
        page = parseInt(queryParams.get('page') );
      }
      searchText = this.sanitizeSearchText(searchText);
      let url = this.props.dispatch(getSiteLoadsUrl(pageSize || 10, page || 1, searchText.replace('&', '%26').replace('#', '%23'), orderBy, order, selectedLocation));
      this.props.dispatch(getSiteLoads(url, true, selectedLocation));
      //setTimeout(() => this.props.dispatch(forceStopLoader()), 5000)
    }

    sanitizeSearchText(searchText) {
      return searchText ? searchText.trim() : searchText;
    }

    render() {
      return (
        <div className="col-xs-12 padding-reset">
          <Paper className='paper-table-paginated'>
            <SiteLoadsTable { ...this.props } filters={this.state.filters} fetchSiteLoads={this.fetchSiteLoads} />
          </Paper>
        </div>
      )
    }
}

const mapStateToProps = state => {
    return {
      isLoading: state.main.isLoading,
      token: state.main.user.token
    };
};

export default connect(mapStateToProps)(SiteLoadsView);

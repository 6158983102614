import React from 'react';
import { connect } from 'react-redux';

import Paper from '@mui/material/Paper';
import { setHeaderText, setBreadcrumbs, isLoading } from '../../actions/main';
import SideDrawer from '../common/SideDrawer';
import AddButton from '../common/AddButton';
import ReleaseNotesTable from '../../containers/ReleaseNotesTable';
import {
  getReleaseNotes,
  clickAddReleaseNoteButton,
  cancelEditReleaseNote
} from '../../actions/companies/release-notes';
import CreateReleaseNote from '../../containers/CreateReleaseNote';
import UpdateReleaseNote from '../../containers/UpdateReleaseNote';

class ReleaseNotes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openSideDrawer: false,
    };
    this.openSideDrawer = this.openSideDrawer.bind(this);
    this.closeSideDrawer = this.closeSideDrawer.bind(this);
    this.cancelEditReleaseNote = this.cancelEditReleaseNote.bind(this);
  }

  openSideDrawer(){
    this.props.dispatch(clickAddReleaseNoteButton());
    this.setState({ openSideDrawer: true, });
  }

  closeSideDrawer() {
    this.setState({ openSideDrawer: false, });
  }
  componentDidMount() {
    this.props.dispatch(getReleaseNotes());
    this.props.dispatch(isLoading('genericTableWithData'));
    this.props.dispatch(setHeaderText('Release Notes'));
    this.props.dispatch(setBreadcrumbs([{text: 'Release Notes'}]));
  }

  cancelEditReleaseNote() {
    this.props.dispatch(cancelEditReleaseNote());
  }

  render() {
    const TABLE_COLUMNS = [
      {key: 'version', header: 'Version', className: 'xsmall'},
      {key: 'createdAt', header: 'Created On', className: 'xsmall'},
      {key: 'changelog', header: 'ChangeLog', className: 'large'},
    ];

    return (
      <Paper className="paper-table">
        <div style={{position: 'relative'}}>
          <div style={{position: 'absolute', right: '0px', top: '-10px'}}>
            <AddButton
              label="Release Notes"
              onClick={this.openSideDrawer}
              style={{ marginTop: '0px'}}
            />
          </div>
          <ReleaseNotesTable
            columns={TABLE_COLUMNS}
            items={this.props.releaseNotes}
            orderBy="createdAt"
            order="desc"
          />
          { this.props.isCreateFormDisplayed &&
            <SideDrawer
              isOpen={ this.state.openSideDrawer }
              title="Add Release Note"
              size="big"
              onClose={this.closeSideDrawer}
              app="release-notes"
              >
              <CreateReleaseNote closeDrawer={ this.closeSideDrawer } />
            </SideDrawer>
          }
          <SideDrawer
            isOpen={ this.props.isUpdateFormDisplayed }
            title="Edit Release Note"
            onClose={this.cancelEditReleaseNote }
            app="site"
            size="big"
          >
            <UpdateReleaseNote />
          </SideDrawer>
        </div>
      </Paper>
    );
  }
}
const mapStateToProps = state => ({
  token: state.main.user.token,
  releaseNotes: state.companies.releaseNotes.items,
  isCreateFormDisplayed: state.companies.releaseNotes.isCreateFormDisplayed,
  isUpdateFormDisplayed: state.companies.releaseNotes.isUpdateFormDisplayed,
});

export default connect(mapStateToProps)(ReleaseNotes);

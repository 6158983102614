import React from 'react';
import { Chip, Menu, MenuItem } from '@mui/material';
import { Done, ArrowDropDown } from '@mui/icons-material';
import { isElement, get, map, find, isArray } from 'lodash';

class Timezones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      selected: null,
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidUpdate() {
    const { updatedTz } = this.props;
    const { selected } = this.state;
    if(updatedTz && get(selected, 'displayName') !== get(updatedTz, 'displayName'))
      this.setState({selected: updatedTz});
  }

  componentDidMount() {
    const { timezones } = this.props;
    this.setState({selected: find(timezones, {isCurrent: true})});
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside(event) {
    const el = document.getElementById('timezone-options');

    if(isElement(el) && !el.contains(event.target))
      this.close();
  }

  setAnchorEl = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  close = () => {
    this.setState({anchorEl: null});
  };

  afterSelect() {
    this.close();
    if(this.props.onChange)
      this.props.onChange(this.state.selected);
  }

  handleSelect(tz) {
    this.setState({selected: tz}, this.afterSelect);
  }

  render() {
    const { selected, anchorEl } = this.state;
    return (
      <span style={{margin: '0 1px'}}>
        <Chip
          id="timezone-options"
          label={get(selected, 'displayName')}
          deleteIcon={<ArrowDropDown />}
          variant="outlined"
          clickable
          onDelete={this.setAnchorEl}
          onClick={this.setAnchorEl}
          style={{minWidth: '100px'}}
          color="secondary"
        />
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)}>
          {
            isArray(this.props.timezones) && map(this.props.timezones, tz => (
              <MenuItem selected={get(selected, 'displayName') === tz.displayName} style={{fontSize: '14px', padding: '5px 15px'}} key={tz.fullName} onClick={() => this.handleSelect(tz) }>
                <span style={{width: '90%', marginRight: '10px'}}>
                  {tz.displayName} {tz.isCurrent ? ('Current') : ''}
                </span>
                {
                  get(selected, 'displayName') === tz.displayName &&
                  <span style={{color: 'rgba(0, 0, 0, 0.4)', marginTop: '5px'}}>
                    <Done color='primary' fontSize='small' />
                  </span>
                }
              </MenuItem>
            ))
          }
        </Menu>
      </span>

    );
  }
}

export default Timezones;

import React, {Component} from 'react';


class SiteManagement extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40">
      <g fill="none" fillRule="nonzero">
        <path d="M0 0h40v40H0z"/>
        <path fill={this.props.fill} d="M31.667 11.667H35V35H5V11.667h3.333v20h23.334z"/>
        <path fill={this.props.fill} d="M15 20v11.667h10V20H15zm-3.333-3.333h16.666V35H11.667V16.667zM2.356 14.85a1.667 1.667 0 0 1-1.379-3.034L20 3.17l19.023 8.647a1.667 1.667 0 1 1-1.38 3.035L20 6.83 2.356 14.85z"/>
        <path fill={this.props.fill} d="M15 21.667h10V25H15zM15 26.667h10V30H15z"/>
      </g>
    </svg>;
  }
}

export default SiteManagement;

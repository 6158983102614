import React from 'react';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs } from '../../../actions/main';
import { getCountryLabel } from '../../../common/utils'
import '../actionCentre.scss';
import isNumber from 'lodash/isNumber';
import InvoiceAcceptancePendingGroup from "./InvoiceAcceptancePendingGroup";
import InvoicePendingVoidRequestGroup from "./InvoicePendingVoidRequestGroup";
import InvoiceConfirmationPendingPaymentAcceptanceGroup from "./InvoiceConfirmationPendingPaymentAcceptanceGroup";
import InvoicePendingPaymentAcceptanceGroup from "./InvoicePendingPaymentAcceptanceGroup";
import get from "lodash/get";

class InvoiceActionCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invoiceItems1: 0,
      invoiceItems2: 0,
      invoiceItems3: 0,
      pendingPaymentAcceptanceGroupUpdatedInvoice: undefined,

    };

    this.onInvoiceDataLoad1 = this.onInvoiceDataLoad1.bind(this);
    this.onInvoiceDataLoad2 = this.onInvoiceDataLoad2.bind(this);
    this.onInvoiceDataLoad3 = this.onInvoiceDataLoad3.bind(this);

  }

  componentDidMount() {
    this.props.onMount();
    this.props.dispatch(setHeaderText(getCountryLabel('actionCentre')));
    this.props.dispatch(setBreadcrumbs([{text: getCountryLabel('actionCentre')}]));
  }

  componentDidUpdate() {
    this.props.onParentDataLoad(this.getInvoiceItemSum());
  }


  onInvoiceDataLoad1(count) {
    this.setState({invoiceItems1: isNumber(count) ? count : this.props.existingCount});
  }

  onInvoiceDataLoad2(count) {
    this.setState({invoiceItems2: isNumber(count) ? count : this.props.existingCount});
  }

  onInvoiceDataLoad3(count) {
    this.setState({invoiceItems3: isNumber(count) ? count : this.props.existingCount});
  }

  getInvoiceItemSum() {
    const sum = this.state.invoiceItems1 + this.state.invoiceItems2 + this.state.invoiceItems3;
    return (sum || (sum === 0 && this.props.existingCount === 1)) ? sum : this.props.existingCount;
  }

  shuffleInvoice = (invoice, group, operation) => {
    const groupMethod = get(this, 'shuffle' + group);
    if (groupMethod)
      groupMethod(invoice, operation);
  };

  shuffleInvoiceInPendingPaymentAcceptanceGroup = (invoice, operation) => {
    if (operation === 'push')
      this.setState({pendingPaymentAcceptanceGroupUpdatedInvoice: invoice});
  };

  render() {
    return (
      <div className='action-centre-group-container'>
          {
            <div>
              <InvoiceAcceptancePendingGroup
                onDataLoad={this.onInvoiceDataLoad1}
                shuffleInvoice={this.shuffleInvoice}
              />
              <InvoiceConfirmationPendingPaymentAcceptanceGroup
                onDataLoad={this.onInvoiceDataLoad2}
              />
              <InvoicePendingPaymentAcceptanceGroup
                onDataLoad={this.onInvoiceDataLoad3}
                pendingPaymentAcceptanceGroupUpdatedInvoice={this.state.pendingPaymentAcceptanceGroupUpdatedInvoice}
                shuffleInvoice={this.shuffleInvoice}
              />
              <InvoicePendingVoidRequestGroup
                onDataLoad={this.onInvoiceDataLoad1}
                shuffleInvoice={this.shuffleInvoice}/>
            </div>
          }
      </div>
    );
  }
}

export default connect()(InvoiceActionCentre);

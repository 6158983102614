import React from 'react';
import APIService from '../../services/APIService';
import { useState } from 'react';
import get from 'lodash/get';
import { useForm, Controller } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import CommonTextField from './CommonTextField';
import CommonButton from './CommonButton';
import { MESSAGES } from '../../common/validators';
import { useEffect } from 'react';

let UnregisteredVoidReject = props =>{
    const {handleSubmit, control, errors} = useForm();
    const [isRejected, setIsRejected] = useState(false);
    const [error, setError] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [entity, setEntity] = useState('');

    let voidRejectContract = rejectReason =>{
        const salt = props.match ? props.match.params.salt : null;
        APIService.contracts(get(props, 'match.params.contract_id')).appendToUrl('void/reject/')
        .put({rejectionReason: rejectReason}, salt)
          .then((json) => {
            setIsLoaded(true);
            if(!isEmpty(json.errors)){
              setError(true);
            } else {
              setIsRejected(true);
            }
          });
    };

    let voidRejectOrder = rejectReason =>{
        const salt = props.match ? props.match.params.salt : null;
        APIService.freights.orders(get(props, 'match.params.order_id')).appendToUrl('void/reject/')
        .put({rejectionReason: rejectReason}, salt)
            .then((json) => {
                setIsLoaded(true);
                if(!isEmpty(json.errors)){
                setError(true);
                } else {
                setIsRejected(true);
                }
        });
    };

    let voidRejectMovement = rejectReason =>{
        const salt = props.match ? props.match.params.salt : null;
        APIService.freights.contracts(get(props, 'match.params.movement_id')).appendToUrl('void/reject/')
        .put({rejectionReason: rejectReason}, salt)
        .then((json) => {
            setIsLoaded(true);
            if(!isEmpty(json.errors)){
                setError(true);
            } else {
                setIsRejected(true);
            }
        });
    };

    let voidRejectInvoice = rejectReason =>{
        const salt = props.match ? props.match.params.salt : null;
        APIService.freights.contracts(get(props, 'match.params.invoice_id')).appendToUrl('void/reject/')
        .put({rejectionReason: rejectReason}, salt)
        .then((json) => {
            setIsLoaded(true);
            if(!isEmpty(json.errors)){
                setError(true);
            } else {
                setIsRejected(true);
            }
        });
    };

    useEffect(()=> {

        if (get(props, 'match.params.contract_id', false)){
            setEntity('Contract');
        }
        else if (get(props, 'match.params.order_id', false)){
          setEntity('Order');
        }
        else if (get(props, 'match.params.movement_id', false)){
          setEntity('Movement');
        }
        else if (get(props, 'match.params.invoice_id', false)){
          setEntity('Invoice');
        }
        else {
            setError(true);
        }
    }, []);

    let OnSubmit = async data =>{
        let functionMapper = {
            'Contract': voidRejectContract,
            'Order': voidRejectOrder,
            'Movement': voidRejectMovement,
            'Invoice': voidRejectInvoice
        };
        let rejectReason = data['reason'];
        await functionMapper[entity](rejectReason);
    };

    return (<div className="full-screen-container">
            <div className="content-container">
            <div className="content-box">
                <div className="content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
                </div>
                <div className="content-box--content">
                <form onSubmit={handleSubmit(OnSubmit)} noValidate>
                    {!isLoaded ? (
                        <div>
                        <h4 className="reject-title">Reject Void {entity}</h4>
                        <Controller
                            as = {<CommonTextField
                                    label="Reason"
                                    placeholder="Please enter your reason for rejection"
                                    id="rejectReason"
                                    multiline
                                    className="reject-reason-field"
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                    helperText={get(errors["reason"], 'message', '')}
                                    errorStyle={{textAlign: "left"}}
                                    fullWidth
                                    maxLength="255"
                                />}
                            control={control}
                            name="reason"
                            rules={{required: MESSAGES.REQUIRED}}
                            />
                        <CommonButton
                            type="submit"
                            variant="contained"
                            label="REJECT"
                            primary
                        />
                        </div>
                    ) : (
                        isLoaded && isRejected ? (
                        <span>{entity} void request has been Rejected successfully</span>
                        ) : isLoaded && error && (
                        <span>You do not have permission to access the information.</span>
                        )
                    )}
                </form>
                </div>
            </div>
            <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
                here
            </a>.
            </div>
            </div>
        </div>);
};

export default UnregisteredVoidReject;
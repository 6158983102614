export const CARRY_ITEM_TYPE = 'carryitem';
export const CUSTOM_ITEM_TYPE = 'customitem';
export const FREIGHT_CONTRACT_ITEM_TYPE = 'freightcontract';
export const TITLE_TRANSFER_ITEM_TYPE = 'titletransfer';
export const EPR_ITEM_TYPE = 'epr';
export const EPR_ITEM_DB_TYPE = 'epritem';
export const GRAIN_LEVY_ITEM_TYPE = 'grainlevy';
export const LOAD_ITEM_TYPE = 'load';
export const STOCK_SWAP_ITEM_TYPE = 'warehousestockswapitem';
export const REGRADE_RESEASON_ITEM_TYPE = 'warehouseregradereseasonitem';
export const WAREHOUSE_STORAGE_ITEM_TYPE= 'warehousestorageitem';

import React from 'react';

import { connect } from 'react-redux';
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TrucksTable from '../../containers/TrucksTable';
import TrailersTable from '../../containers/TrailersTable';
import CreateTruck from '../../containers/CreateTruck';
import UpdateTruck from '../../containers/UpdateTruck';
import CreateTrailer from '../../containers/CreateTrailer';
import UpdateTrailer from '../../containers/UpdateTrailer';
import { getCompanyTrailers, getCompanyTrucksWeb } from '../../actions/api/trucks';
import AddButton from '../common/AddButton';
import {
  receiveTrucksWeb,
  receiveTrailers,
  clickAddTruckButton,
  cancelEditTruck,
  clickAddTrailerButton,
  cancelEditTrailer,
} from '../../actions/companies/trucks';
import {
  showSideDrawerForTransfer, showSideDrawerForMerge
} from '../../actions/companies/index';
import TransferToOtherCompany from '../../containers/TransferToOtherCompany';
import { getCompanyDetails } from '../../actions/companies/index';
import Paper from '@mui/material/Paper';
import {setHeaderText, setBreadcrumbs} from '../../actions/main';
import {isEqual, get, filter, cloneDeep, find} from 'lodash';
import SideDrawer from '../common/SideDrawer';
import {isCompanyEditable, isTransactionParticipated} from "../../common/utils";
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import { receiveEmployees } from '../../actions/company-settings/employees';
import { getCompanyEmployees } from '../../actions/api/employees';

class Trucks extends React.Component {
  constructor(props) {
    super(props);
    this.TRUCK_LISTING = 'truck';
    this.ARCHIVED_TRUCK_LISTING = 'archived-truck';
    this.TRAILER_LISTING = 'trailer';
    this.ARCHIVED_TRAILER_LISTING = 'archived-trailer';
    this.state = {
      truckValue: 'truck',
      trailerValue: 'trailer',
      color: '#7b1fa2',
      openTruckSideDrawer: false,
      openTrailerSideDrawer: false,
      openSideDrawer: false,
      openMergeSideDrawer: false,
      mergeToTruck: {
        value: undefined,
        validators: [],
        errors: []
      }
    };

    this.openTruckSideDraw = this.openTruckSideDraw.bind(this);
    this.closeTruckSideDraw = this.closeTruckSideDraw.bind(this);
    this.openTrailerSideDraw = this.openTrailerSideDraw.bind(this);
    this.closeTrailerSideDraw = this.closeTrailerSideDraw.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleTruckChange = this.handleTruckChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
    this.onTabChanges = this.onTabChanges.bind(this);
    this.onTrailerTabChanges = this.onTrailerTabChanges.bind(this);
  }

  componentDidMount() {
    this.setBreadcrumbs();
    this.props.getTrucks(this.props.companyId);
    this.props.getTrailers(this.props.companyId);
    this.props.getEmployees(this.props.companyId);
    this.props.cancelMergeDrawer;
    if(this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
    }
  }

  openSideDraw(){
    this.props.handleAddOfficeButtonClick();
    this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
    this.setState({ openSideDrawer: false, });
  }

  onColorChange(color) {
    if(color !== this.state.color)
      this.setState({color: color});
  }

  shouldFetchCompany() {
    return !this.props.selectedCompany || (this.props.selectedCompany.id !== this.props.companyId);
  }

  componentDidUpdate(prevProps){
    if(this.props.selectedCompany) {
      if(this.props.headerText !== this.props.selectedCompany.name) {
        this.props.setHeaderText(this.props.selectedCompany.name);
      }
      this.setBreadcrumbs();
    }

    if(prevProps.companyId && prevProps.companyId !== this.props.companyId) {
      this.props.getCompany(this.props.companyId);
      this.props.getTrucks(this.props.companyId, this.state.truckValue !== this.TRUCK_LISTING ? 'is_archived=true' : null);
      this.props.getTrailers(this.props.companyId, this.state.trailerValue !== this.TRAILER_LISTING ? '?is_archived=true' : null);
    }
  }

  setBreadcrumbs() {
    if(this.props.selectedCompany) {
      const breadcrumbs = [
        {text: 'Companies', route: '/companies'},
        {text: this.props.selectedCompany.name, onClick: this.props.onDetailsClick, route: '/companies/'+this.props.selectedCompany.id+'/details'},
        {text: `Trucks (${this.props.count})`}
      ];
      if(!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.setBreadcrumbs(breadcrumbs);
      }
    }
  }

  openTruckSideDraw(){
    this.props.handleAddTruckButtonClick();
    this.setState({ openTruckSideDrawer: true, });
  }

  closeTruckSideDraw() {
    this.setState({ openTruckSideDrawer: false, });
  }

  openTrailerSideDraw(){
    this.props.handleAddTrailerButtonClick();
    this.setState({ openTrailerSideDrawer: true, });
  }

  closeTrailerSideDraw() {
    this.setState({ openTrailerSideDrawer: false, });
  }

  canAccessAny() {
    return !isTransactionParticipated(this.props.selectedCompany) || isCompanyEditable(this.props.selectedCompany);
  }

  componentWillUnmount() {
    this.props.cancelTransferDrawer(null, false);
    this.props.cancelMergeDrawer(null, false);
    this.props.clearTrucks()
  };

  getTrucks = () => {
    let trucks = cloneDeep(this.props.companyTrucksWithFleet);
    trucks.splice(trucks.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
    return trucks;
  }

  getLabel = () => {
    let fromTruckRego = get(this.props, 'selectedAsset.rego');
    let toTruckRego = "";
    if (this.state.mergeToTruck.value) {
      toTruckRego = get(find(this.props.companyTrucks, {id: this.state.mergeToTruck.value}), 'rego');
    }
    return "Merge " + fromTruckRego + " into " + toTruckRego;
  }

  handleTruckChange(value) {
    const newState = {...this.state};
    newState.mergeToTruck.value = value;
    newState.mergeToTruck.errors = [];
    this.setState(newState);
  }

  closeMergeDrawer() {
    const newState = {...this.state};
    newState.mergeToTruck.value = undefined;
    this.setState(newState);
    this.props.cancelMergeDrawer();
  }

  handleSubmit() {
    let data = {};
      data['mergeFrom'] = get(this.props.selectedAsset, 'id');
      if (data['mergeFrom']) {
        APIService.trucks()
          .appendToUrl(`${this.state.mergeToTruck.value}/merge/`)
          .post(data)
          .then(res => {
            if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
            else alertifyjs.success('Truck Merged!', 3, () => window.location.reload())
          });
      }
  }

  shouldShowAlert() {
    return get(this.props, 'selectedCompany.id') === get(this.props, 'currentUserCompanyId')
  }

  onTabChanges = (event, value) => {
    this.setState({ truckValue: value });
    this.props.getTrucks(this.props.companyId, value !== this.TRUCK_LISTING ? 'is_archived=true' : null);
  }

  onTrailerTabChanges = (event, value) => {
    this.setState({ trailerValue: value });
    this.props.getTrailers(this.props.companyId, value !== this.TRAILER_LISTING ? '?is_archived=true' : null);
  }

  render() {
    const canAccess = Boolean(this.canAccessAny())
    const showAlert = this.shouldShowAlert()
    const addTrucksHeader = showAlert ? "Only add trucks that you own. If adding sub freight provider's trucks, go to Company Directory" : false;
    const { truckValue, trailerValue } = this.state;
    return (
      <div>
        <React.Fragment>
        <div className="subTab">
          <Tabs className="subTab-header" value={truckValue} onChange={this.onTabChanges}>
            <Tab label="Active" value={this.TRUCK_LISTING} className={truckValue !== this.TRUCK_LISTING ? 'unselected-subtab' : ''} />
            <Tab label="Archived" value={this.ARCHIVED_TRUCK_LISTING} className={truckValue !== this.ARCHIVED_TRUCK_LISTING ? 'unselected-subtab' : ''} />
          </Tabs>
        </div>
        <Paper className="paper-table">
          {
            showAlert && truckValue === this.TRUCK_LISTING &&
              <div style={{display: 'flex', justifyContent: 'center'}}>
                <Alert severity="warning">
                  {"Do not add sub freight provider's trucks here. Add them via Company Directory"}
                </Alert>
              </div>
          }
          <div style={{marginTop: showAlert && truckValue === this.TRUCK_LISTING ? '-45px' : '5px'}}>
            {
              canAccess && truckValue === this.TRUCK_LISTING &&
              <AddButton label="Truck" onClick={this.openTruckSideDraw} app="truck" />
            }
            <TrucksTable isCompanyTrucks={true} companyId={this.props.selectedCompany?.id} isArchived={truckValue == this.ARCHIVED_TRUCK_LISTING} />
            {
              this.props.isCreateFormDisplayed && truckValue === this.TRUCK_LISTING &&
             <SideDrawer
               isOpen={ this.state.openTruckSideDrawer }
               title="Add Trucks"
               subHeading={addTrucksHeader}
               size="big"
               onClose={this.closeTruckSideDraw}
               app="truck"
               paperStyle={{borderLeft: '8px solid ' + this.state.color || '#fff'}}
               >
               <CreateTruck onColorChange={this.onColorChange} closeDrawer={ this.closeTruckSideDraw } canAccessAny={canAccess} companyId={this.props.companyId} company={this.props.selectedCompany}/>
             </SideDrawer>
            }
            {
              canAccess && truckValue === this.TRUCK_LISTING &&
              <SideDrawer
                isOpen={ this.props.isUpdateFormDisplayed }
                title="Edit Trucks"
                size="big"
                onClose={this.props.cancelEditTruck}
                app="truck"
                paperStyle={{borderLeft: '8px solid ' + this.state.color || '#fff'}}
                >
                <UpdateTruck selectedCompany={this.props.selectedCompany} onColorChange={this.onColorChange} canAccessAny={canAccess} companyId={this.props.companyId} />
              </SideDrawer>
            }
          </div>
        </Paper>
        </React.Fragment>
        <div style={{ marginTop: '10px' }}>
        <div className="subTab">
          <Tabs className="subTab-header" value={trailerValue} onChange={this.onTrailerTabChanges}>
            <Tab label="Active" value={this.TRAILER_LISTING} className={trailerValue !== this.TRAILER_LISTING ? 'unselected-subtab' : ''} />
            <Tab label="Archived" value={this.ARCHIVED_TRAILER_LISTING} className={trailerValue !== this.ARCHIVED_TRAILER_LISTING ? 'unselected-subtab' : ''} />
          </Tabs>
        </div>
        <Paper className="paper-table" style={{ marginBottom: '45px'}}>
          <div style={{marginTop: '5px'}}>
            {
              canAccess && trailerValue === this.TRAILER_LISTING &&
              <AddButton label="Trailer" onClick={this.openTrailerSideDraw} app="truck" />
            }
            <TrailersTable companyId={this.props.selectedCompany?.id} isArchived={trailerValue == this.ARCHIVED_TRAILER_LISTING} />
            {
              this.props.isTrailerCreateFormDisplayed && this.TRAILER_LISTING &&
             <SideDrawer
               isOpen={ this.state.openTrailerSideDrawer }
               title="Add Trailer"
               onClose={this.closeTrailerSideDraw}
               app="truck"
               >
               <CreateTrailer closeDrawer={ this.closeTrailerSideDraw } canAccessAny={canAccess} companyId={this.props.companyId} />
             </SideDrawer>
            }
            {
              canAccess && this.TRAILER_LISTING &&
              <SideDrawer
                isOpen={ this.props.isTrailerUpdateFormDisplayed }
                title="Edit Trailer"
                onClose={this.props.cancelEditTrailer}
                app="truck"
                >
                <UpdateTrailer canAccessAny={canAccess} companyId={this.props.companyId} />
              </SideDrawer>
            }
            {
            canAccess && this.props.canEdit && this.TRAILER_LISTING &&
            <SideDrawer
              isOpen={ this.props.openSideDrawer }
              title="Transfer"
              onClose={this.props.cancelTransferDrawer}
              app='office'
              >
             <TransferToOtherCompany canAccessAny={canAccess} />
            </SideDrawer>
          }
          {this.props.openMergeSideDrawer && this.TRAILER_LISTING &&
            <SideDrawer
              isOpen={this.props.openMergeSideDrawer}
              title="Merge"
              onClose={this.closeMergeDrawer}
            >
              <div style={{ paddingTop: "15px"}}>
                <CommonAutoSelect
                  id='mergeToTruck'
                  items={this.getTrucks()}
                  label={this.getLabel()}
                  value={this.state.mergeToTruck.value}
                  dataSourceConfig={{ text: 'rego', value: 'id' }}
                  onChange={this.handleTruckChange}
                />
              </div>
              {
              <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
                <CommonButton
                  type="button"
                  label="Cancel"
                  default
                  onClick={this.closeMergeDrawer}
                  variant="contained"
                />
                <CommonButton
                  type="submit"
                  label="Merge"
                  primary
                  onClick={this.handleSubmit}
                  variant="contained"
                />
              </div>
              }
            </SideDrawer>
          }
          </div>

        </Paper>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    openSideDrawer: state.companies.companies.showTransferSideDrawer,
    openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
    isCreateFormDisplayed: state.companies.companies.company.trucks.isCreateFormDisplayed,
    isTrailerCreateFormDisplayed: state.companies.companies.company.trucks.isTrailerCreateFormDisplayed,
    currentUserCompanyId: state.main.user.user.companyId,
    selectedCompany: state.companies.companies.selectedCompany,
    isUpdateFormDisplayed: state.companies.companies.company.trucks.isUpdateFormDisplayed,
    isTrailerUpdateFormDisplayed: state.companies.companies.company.trucks.isTrailerUpdateFormDisplayed,
    headerText: state.main.headerText,
    breadcrumbs: state.main.breadcrumbs,
    companyTrucks: filter(state.companies.companies.company.trucks.items, { isFleet: false }),
    count: get(filter(state.companies.companies.company.trucks.items, { isFleet: false }), 'length') || 0,
    companyTrucksWithFleet: state.companies.companies.company.trucks.items,
    selectedAsset: state.companies.companies.selectedAsset
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getTrucks: (companyId, queryParams) => dispatch(getTrucks(companyId, queryParams)),
    getTrailers: (companyId, queryParams) => dispatch(getCompanyTrailers(companyId, receiveTrailers, queryParams)),
    getEmployees: companyId => dispatch(getCompanyEmployees(companyId, receiveEmployees)),
    getCompany:(companyId) => dispatch(getCompanyDetails(companyId)),
    handleAddTruckButtonClick: () => dispatch(clickAddTruckButton()),
    handleAddTrailerButtonClick: () => dispatch(clickAddTrailerButton()),
    setHeaderText: (text) => dispatch(setHeaderText(text)),
    setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
    cancelEditTruck: () => dispatch(cancelEditTruck()),
    cancelEditTrailer: () => dispatch(cancelEditTrailer()),
    cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
    cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
    clearTrucks: () => dispatch(receiveTrucksWeb({}))
  };
};

function getTrucks(companyId, queryParams) {
  return (dispatch) => {
    dispatch(getCompanyTrucksWeb(companyId, null, queryParams));
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Trucks);

import React, { Component } from 'react';
import Card from '@mui/material/Card';
import {PRIMARY_COLOR_GREEN, BLACK} from "../../common/constants";
import CardContent from '@mui/material/CardContent';

import RightTick from './icons/RightTick';

class ImageTile extends Component{
  constructor(props){
    super(props);
  }

  getStyles() {
    if(this.props.isActive) {
      return { position: 'relative', top: '-40px', width: '100%', height: '144px', paddingTop: '20px', borderStyle: 'solid' };
    } else {
      return { width: '100%', height: '144px', paddingTop: '20px', cursor: 'pointer' };
    }
  }

  render() {
    return (
      <div style={{height: '144px'}}>
        {
          this.props.isActive &&
          <RightTick className="signup-business-type-tick" />
        }
        <Card raised className='image-tile-card' style={this.getStyles()}>
          <div className='image-tile-child'>
            {this.props.children}
          </div>
          <div>
            <CardContent>
              <span style={{color: this.props.isActive ? PRIMARY_COLOR_GREEN : (this.props.inActiveColor || BLACK)}}>
                {this.props.label}
              </span>
            </CardContent>
          </div>
        </Card>
      </div>
    );
  }
}

export default ImageTile;

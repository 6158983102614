import { connect } from 'react-redux';
import OutloadForm from '../components/outloads/OutloadForm';
import { createOutload } from '../actions/api/outloads';
import { isNumber, isObject } from 'lodash';

function submit(data, successCallback, func) {
  if(isNumber(data) && isObject(successCallback) && func) {
    data = successCallback;
    successCallback = func;
  }

  return (dispatch) => {
    dispatch(createOutload(data, successCallback));
  };
}

export default connect(null, { submit })(OutloadForm);

import React, {Component} from 'react';
import { getCountryLabel } from '../../../common/utils'

import Tooltip from '@mui/material/Tooltip';

class ActionCentre extends Component {
  render() {
    return <Tooltip title={getCountryLabel('actionCentre')} placement="right">
      <svg width="24px" height="20px" viewBox="0 0 24 20" version="1.1">
        <g id="Components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Icons" transform="translate(-1296.000000, -626.000000)">
            <g id="Icons-/-Dashboard-/-action_centre" transform="translate(1296.000000, 624.000000)">
              <g id="Icons-/-Dashboard-/-action_centre_selected-Copy">
                <g id="Group-2">
                  <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
                  <g id="Group" transform="translate(0.000000, 2.000000)" fill="#FFFFFF">
                    <path d="M11,16 L3,16 C2.44771525,16 2,15.5522847 2,15 L2,3 C2,2.44771525 2.44771525,2 3,2 L17,2 C17.5522847,2 18,2.44771525 18,3 L18,11 L20,11 L20,3 C20,1.34314575 18.6568542,0 17,0 L3,0 C1.34314575,0 0,1.34314575 0,3 L0,15 C0,16.6568542 1.34314575,18 3,18 L11,18 L11,16 Z" id="Path" fillRule="nonzero" fill={this.props.fill}></path>
                    <rect fill={this.props.fill} id="Rectangle" x="7" y="4" width="9" height="2"></rect>
                    <rect fill={this.props.fill} id="Rectangle-Copy" x="7" y="8" width="6" height="2"></rect>
                    <rect fill={this.props.fill} id="Rectangle-Copy-2" x="7" y="12" width="4" height="2"></rect>
                    <rect fill={this.props.fill} id="Rectangle-Copy-3" x="4" y="12" width="2" height="2" rx="1"></rect>
                    <rect fill={this.props.fill} id="Rectangle-Copy-4" x="4" y="8" width="2" height="2" rx="1"></rect>
                    <rect fill={this.props.fill} id="Rectangle-Copy-5" x="4" y="4" width="2" height="2" rx="1"></rect>
                    <polygon fill={this.props.fill} id="Path-Copy" points="22 11 23.5 12.5 16.51 19.5 12 15 13.5 13.5 16.51 16.5"></polygon>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default ActionCentre;

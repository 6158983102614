import { connect } from 'react-redux';
import StorageForm from '../components/storages/StorageForm';
import { updateStorage } from '../actions/api/storages';
import { editStorage, cancelEditStorage } from '../actions/companies/storages';
import find from 'lodash/find';

function submit(farmId, data) {
  return (dispatch, getState) => {
    const storageId = getState().companies.storages.selectedHomeStorageId;
    const selectedHomeStorage = find(getState().companies.storages.items, { id: storageId });
    const homeStorageId = selectedHomeStorage.homeStorageId || selectedHomeStorage.id || storageId;
    dispatch(updateStorage(farmId, homeStorageId, data, editStorage));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedHomeStorage: find(
      state.companies.storages.items,
      { id: state.companies.storages.selectedHomeStorageId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (farmId, data) => dispatch(submit(farmId, data)),
    cancelEdit: () => dispatch(cancelEditStorage()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageForm);

import React from 'react';
import { connect } from 'react-redux';
import '../common/subTab.scss';

import APIService from '../../services/APIService';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import { TextField } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { addCompanyGroup, getCompanyGroups } from '../../actions/api/companies';
import alertifyjs from 'alertifyjs';
import { map, isEmpty, filter, get, isNull, find } from 'lodash';

const types = [{id: 'fees', name: 'Fees'}, {id: 'contract_bids', name: 'Contract Bids'}]

const autocompleteFilters = createFilterOptions();

class AddToOrCreateCompanyGroup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allCompanies: [],
      selected: [],
      groupName: null,
      type: null,
      selectedGroup: null,
      businessTypes: [],
      businessTypeId: {
        value: null,
        validators: [],
        errors: [],
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onTypeChange = this.onTypeChange.bind(this);
    this.onGroupChange = this.onGroupChange.bind(this);
  }

  componentDidMount() {
    this.setState({ selected: map(this.props.selectedCompanies, 'id') })
    this.props.dispatch(getCompanyGroups(this.props.userCompanyId))
    APIService.companies()
      .appendToUrl('directory/names/')
      .get(this.props.userToken).then(companyData => this.setState({ allCompanies: companyData }));
    if(isEmpty(this.state.businessTypes) && this.props.isAddingNewGroup)
      APIService.companies().types().get().then(types => this.setState({businessTypes: types}));
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedCompanies !== this.props.selectedCompanies)
      this.setState({ selected: map(this.props.selectedCompanies, 'id') })
    if(isEmpty(this.state.type) && !isEmpty(this.state.selectedGroup) && typeof this.state.selectedGroup != 'string')
      this.setState({type: this.state.selectedGroup?.type})
  }

  onTypeChange = (value) => {
    this.setState({ type: value })
  }

  onBusinessTypeChange = typeId => {
    const newState = { ...this.state };
    newState.businessTypeId.value = typeId;
    const groupWithBusinessTypeExist = filter(this.props.companyGroups, { businessTypeId: typeId })
    newState.businessTypeId.errors = groupWithBusinessTypeExist.length > 0 ? [`A group with this business type already exists`] : [];
    this.setState(newState);
  }

  onNameChange = (event) => {
    this.setState({ groupName: event.target.value })
  }

  handleSubmit() {
    event.preventDefault();
    const groupName = this.props.isAddingNewGroup ? this.state.groupName : (typeof this.state.selectedGroup === 'string' ? this.state.selectedGroup : this.state.selectedGroup?.name)
    const groupType = this.props.isAddingNewGroup ? this.state.type : (typeof this.state.selectedGroup === 'string' ? this.state.type : this.state.selectedGroup?.type)
    const businessTypeId = this.props.isAddingNewGroup && this.state.businessTypeId.value ? this.state.businessTypeId.value : null
    if (groupName && groupType) {
      if (isEmpty(this.state.businessTypeId.errors)) {
      if (this.props.isAddingNewGroup) {
        if (this.props.companyGroups.find(group => group.name == groupName))
          alertifyjs.error("Group Name already exists")
        else {
          let data = {
            'groupName': groupName,
            'groupType': groupType,
            'companyIds': null,
            'businessTypeId': businessTypeId
          }
          this.props.dispatch(addCompanyGroup(this.props.userCompanyId, data))
          this.props.closeDrawer()
          window.location.reload()
        }
      } else {
        let items = ''
        const selectedItems = Array.from(
          new Set(this.state.selected.map((company_id) => company_id))
        ).map((company_id) => {
          const company =
            this.state.allCompanies &&
            this.state.allCompanies.find((company) => company.id === company_id);
          let existingGroup = company?.companyGroups && company?.companyGroups.find(group => isNull(group.businessTypeId))
          return {
            company,
            existingGroup,
          };
        });
        
        const sortedItems = [...selectedItems].sort((a, b) => {
          return (a.existingGroup ? -1 : 1) - (b.existingGroup ? -1 : 1);
        });
        
        sortedItems.forEach(({ company, existingGroup }) => {
          items += `<tr><td style="text-align: center; border: 1px solid #000; padding: 8px;">${
            company?.name
          }</td><td style="text-align: center; border: 1px solid #000; padding: 8px;">${
            existingGroup !== undefined ? existingGroup?.name : "-"
          }</td></tr>`;
        });
        
          alertifyjs.confirm(
            'Confirm Group Changes',
            `<style>
              .custom-scrollbar {
                max-height: 400px;
                overflow-y: auto;
                padding: 16px;
                box-sizing: border-box;
              }
              .custom-scrollbar::-webkit-scrollbar {
                width: 12px;
              }
              .custom-scrollbar::-webkit-scrollbar-track {
                background: #f1f1f1;
              }
              .custom-scrollbar::-webkit-scrollbar-thumb {
                background: #888;
                border-radius: 6px;
              }
              .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                background: #555;
              }
            </style>
            <div class="custom-scrollbar">
              <p>The following companies will be moved to the group <b>${groupName}</b><p>
              <table style="border-collapse: collapse; width: 100%;">
                <thead>
                  <tr>
                    <th style="border: 1px solid #000; padding: 8px;">Company</th>
                    <th style="border: 1px solid #000; padding: 8px;">Existing Group Name</th>
                  </tr>
                </thead>
                <tbody>
                    ${items}
                </table>
              
            </div>`,
            () => {
              let data = {
                'groupName': groupName,
                'groupType': groupType,
                'companyIds': this.state.selected
              }
              this.props.dispatch(addCompanyGroup(this.props.userCompanyId, data))
              this.setState({groupInputText: null}, () => {
                this.props.closeDrawer()
                window.location.reload()
              })
            },
            () => { },
          ).set({'reverseButtons': true})
      }
      }
    } else 
        alertifyjs.error("Group name and type are mandatory");
  }
 
  onGroupChange(event, value) {
    if (value && value.inputValue)
      this.setState({selectedGroup: value.inputValue})
    else {
      let group = this.props.companyGroups.find(group => group.id == value?.id)
      this.setState({selectedGroup: group})
    }
  }

  render() {
    return (
      <div style={{ marginTop: '5px' }}>
        <form noValidate>
          {this.props.isAddingNewGroup ?
            <CommonTextField 
            id='group_name' 
            label='Group Name' 
            value={this.state.groupName} 
            onChange={this.onNameChange} />  
            :<Autocomplete
                id="groups"
                options={filter(this.props.companyGroups, group => isNull(group.businessTypeId))}
                filterOptions={(options, params) => {
                    const filtered = autocompleteFilters(options, params);
                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}" as a new group`,
                      });
                    }
                    return filtered;
                }}
                getOptionLabel={option => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.name;
                    }
                    return option.name;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    variant="standard"
                    label='Groups'
                    fullWidth
                  />
                )}
                value={this.state.selectedGroup}
                onChange={(event, item) => this.onGroupChange(event, item)}
              />
          }
        
        <CommonAutoSelect
            id='type'
            label='Type'
            placeholder='Type'
            items={types}
            fullWidth
            onBlur={this.onFieldBlur}
            onChange={this.onTypeChange}
            value={this.state.type}
            style={{marginTop: '10px'}}
            disabled={this.props.isAddingNewGroup ? false : find(this.props.companyGroups, group => group.id == this.state.selectedGroup?.id)}
        />
        {
          this.props.isAddingNewGroup &&
          <CommonAutoSelect
            id="businessType"
            label="Business Type (Optional)"
            placeholder="Business Type"
            value={this.state.businessTypeId.value}
            onBlur={this.onFieldBlur}
            style={{marginTop: '10px'}}
            dataSourceConfig={{ text: 'displayName', value: 'id' }}
            onChange={this.onBusinessTypeChange}
            items={this.state.businessTypes}
            errorText={get(this.state.businessTypeId, 'errors')}
            fullWidth
          />
        }
        <div style={{ float: 'right', top: '20px', marginTop: '20px', marginRight: "-10px"}}>
            <CommonButton type='button' variant='outlined' label='Cancel' default onClick={this.props.closeDrawer} />
            <CommonButton primary={true} variant="contained" label='Submit' type='submit' onClick={this.handleSubmit}/>
        </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = state => ({
    userCompanyId: state.main.user.user.companyId,
    companies: state.companies.companies.items,
    companyGroups: state.companies.companies.companyGroups
  });

export default connect(mapStateToProps)(AddToOrCreateCompanyGroup);
import React from 'react';
import { connect } from 'react-redux';

import {isEmpty, get} from 'lodash';

class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      waitForComponent: undefined,
      componentElementMap: {
        contractDetail: 'ul.contract-status-bar li.status',
        orderDetail: 'div.order-details-status-section div.item.item1',
        movementDetail: 'div.contract-details-status-section div.item.item1',
        movementFormFromContract: 'div.contract-details-section-container',
        orderFormFromContract: 'div.contract-details-section-container',
        genericTableWithData: 'tr.row-with-data',
        alertify: 'div.alertify-notifier div.ajs-message',
        abnEntityName: 'div.cardForm--drawer div#entityName',
        orderFormFromOrder: 'div.dom-for-allocation-loader',
        invoiceFormFromContract: 'div.dom-for-allocation-loader',
        acceptRejectFromOrderDetails: 'div.dom-for-order-accept-reject-loader',
        MovementFromOrderForm: 'div.dom-for-movement-from-customer-only-order-loader',
        invoiceDetail: 'div.invoice-details-subcontainer',
        nonExistentComponent: 'div.non-existent-component',
        registerSuccess: 'div#registerSuccess',
        titleTransferForm: 'div#title-transfer-side-form',
        callOnGrainOrderForm: 'div#call-on-grain-order-side-form',
        voidReasonDialog: 'div#rejection-dialog',
        inloadOutloadSideDrawerForm: 'div#inload-outload-side-form',
        loadRejectionDialog: 'div#reject-load-dialog',
        editFreightOrderReview: 'div#edit-order-review',
        assignToContractOrFreightOrder: 'div#assign-to-contract-or-freight-order',
        canMarkComplete: 'div#complete-dialog-open',
        contractAmendReviewForm: 'div#contract-amend-review-form',
        tuiCalendar: 'div.tui-full-calendar-layout',
        editOrderReview: 'div#edit-order-review',
        editMovementReview: 'div#edit-movement-review',
        invoiceAddPaymentForm: 'div#invoice-add-payment-form',
      }
    };
    this.stopLoading = this.stopLoading.bind(this);
  }

  componentDidUpdate() {
    if(this.props.forceStopLoader && this.state.isLoading) {
      setTimeout(() => {
        this.stopLoading();
      }, 300);
    }
    else if(this.state.isLoading !== this.props.isLoading) {
      this.props.isLoading ? this.loading() : this.loaded();
    } else if (this.shouldUpdateWaitForComponent()) {
      this.updateWaitForComponent();
    }
  }

  loading() {
    if(this.props.waitForComponent && this.elementExist(this.state.componentElementMap[this.props.waitForComponent])) {
      return;
    } else {
      this.setState({isLoading: this.props.isLoading, waitForComponent: this.props.waitForComponent});
    }

  }

  updateWaitForComponent() {
    this.setState({ waitForComponent: this.props.waitForComponent });
  }

  shouldUpdateWaitForComponent() {
    return this.props.waitForComponent && (this.state.waitForComponent !== this.props.waitForComponent);
  }

  loaded () {
    if(!isEmpty(this.state.waitForComponent)) {
      this.waitForElement(
        this.state.componentElementMap[this.state.waitForComponent],
        this.stopLoading
      );
    } else {
      setTimeout(() => {
        this.stopLoading();
      }, this.props.waitForMilliseconds || 1000);
    }
  }

  stopLoading() {
    this.setState({isLoading: false});
  }

  elementExist(element) {
    return element && document.querySelectorAll(element).length;
  }

  waitForElement(elementPath, callBack) {
    setTimeout(() => {
      if(this.elementExist(elementPath)){
        callBack(elementPath, document.querySelectorAll(elementPath));
      }else{
        this.waitForElement(elementPath, callBack);
      }
    }, 500);
  }

  getLeftOffset() {
    const el = get(document.getElementsByClassName('left-bar'), '0');
    if(el)
      return el.offsetWidth || 0;

    return 0;
  }

  render() {
    return (
      <div className={this.state.isLoading ? 'col-sm-12 loader show': 'col-sm-12 loader hide'} style={{left: this.getLeftOffset()}}>
        <div className='col-sm-12' style={{top: '25%'}}>
          <img src='images/loader.gif'/>
        </div>
        {
          this.props.loadingText &&
          <div className="col-sm-12" style={{top: '25%'}}>{this.props.loadingText}</div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isLoading: state.main.isLoading,
    waitForComponent: state.main.waitForComponent,
    forceStopLoader: state.main.forceStopLoader,
    waitForMilliseconds: state.main.waitForMilliseconds,
    loadingText: state.main.loadingText,
  };
};

export default connect(
  mapStateToProps,
  null
)(Loader);

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { canMarkPaymentRunVoid, downloadPaymentRunABA, markPaymentRunPaid, markPaymentRunUnPaid, markPaymentRunVoid, paymentRunVoidDialog, setClickedOption, setSelectedPaymentRunId, updateBankProcessingDate } from '../../actions/companies/invoices';
import CommonButton from '../common/CommonButton';
import CommonDatePicker from '../common/CommonDatePicker';
import { get, isUndefined } from 'lodash';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { attachABAEventListener } from '../../common/utils';
import { setDownloadBar } from '../../actions/main';
import { RejectionReasonDialog } from '../rejections/RejectionReasonDialog';
import { connect } from 'react-redux';

let PaymentRunActions = props => {
  const [options, setOptions] = useState({
    markDialogOpen: false,
    isPaidDialogOpen: false,
    isUnPaidDialogOpen: false,
  });

  const [bankProcessingDate, setBankProcessingDate] = useState();
  const [bankProcessingDateError, setBankProcessingDateError] = useState();
  const [requestReason, setRequestReason] = useState("");

  let setOptionKey = (key, value) =>{
    setOptions({
        ...options,
        [key]: value
    });
    props.dispatch(setClickedOption(null));
  };

  let checkForOptions = () => {
    const { clickedOption, canDownloadABA } = props;
    const clickedOptionKey = get(clickedOption, 'key');
    const paymentRunId = get(props, 'paymentRunDetails.id');
    props.dispatch(setSelectedPaymentRunId(paymentRunId));
    if (clickedOptionKey === 'mark_paid') {
      setOptionKey("isPaidDialogOpen", true)
    }
    else if (clickedOptionKey === 'update_bank_processing_date') {
      let bankProcessingDate = get(props, 'paymentRunDetails.bankProcessingDate');
      if (bankProcessingDate) {
        bankProcessingDate = bankProcessingDate.substring(6, 10) + '-' + bankProcessingDate.substring(3, 5) + '-' + bankProcessingDate.substring(0, 2);
      }
      setBankProcessingDate(bankProcessingDate);
      setOptionKey("markDialogOpen", true);
    }
    else if (clickedOptionKey === 'void') {
      props.dispatch(canMarkPaymentRunVoid())
    }
    else if (clickedOptionKey === 'un_paid'){
      setOptionKey("isUnPaidDialogOpen", true);
    }
    else if (canDownloadABA) {
      props.dispatch(downloadPaymentRunABA(paymentRunId));
      props.dispatch(setDownloadBar('Your ABA File is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments', true));
    }
  };

  let onCloseDownloadResponse = () => {
    props.dispatch(setDownloadBar(false));
  };

  let onDownloadResponse = message => {
    props.dispatch(setDownloadBar(message, true, onCloseDownloadResponse));
  };

  let _attachABAEventListener = () => {
    attachABAEventListener(onDownloadResponse);
  };

  useEffect(() => {
    if(props.clickedOption){
      checkForOptions();
    }
  }, [props.clickedOption, props.canDownloadABA]);

  useEffect(() => {
    _attachABAEventListener();
  }, []);

  let handleBankProcessingDateChange = value => {
    setBankProcessingDate(value);
    setBankProcessingDateError('');
  }

  let handleDialogOk = (event) => {
    if (isUndefined(bankProcessingDate)) {
      setBankProcessingDateError("This field cannot be blank");
    }
    else {
      event.preventDefault();
      setOptionKey("markDialogOpen", false);
      const paymentRunId = get(props, 'paymentRunDetails.id');
      props.dispatch(updateBankProcessingDate(paymentRunId, bankProcessingDate));
    }
  }

  let handleVoidPaymentRun = () => {
    props.dispatch(paymentRunVoidDialog(false))
    const paymentRunId = get(props, 'paymentRunDetails.id');
    props.dispatch(markPaymentRunVoid(paymentRunId, requestReason));
  }

  let handleUnPaidDialogOk = () => {
    setOptionKey("isUnPaidDialogOpen", false);
    const paymentRunId = get(props, 'paymentRunDetails.id');
    props.dispatch(markPaymentRunUnPaid(paymentRunId));
  }

  let closeVoidDialog = () =>{
    props.dispatch(paymentRunVoidDialog(false));
    setRequestReason("");
  };

  let handlePaidDialogOk = email => {
    setOptionKey("isPaidDialogOpen", false);
    const paymentRunId = get(props, 'paymentRunDetails.id');
    props.dispatch(markPaymentRunPaid(paymentRunId, email));
  }

  return (<span>
    { options.markDialogOpen &&
      <Dialog
        id='mark-dialog-open'
        open={options.markDialogOpen}
        onClose={() => setOptionKey("markDialogOpen", false)}
        aria-labelledby='form-dialog-title'
        fullWidth
      >
        <DialogTitleWithCloseIcon
          onClose={() => setOptionKey("markDialogOpen", false)}
        >
          Payment Run : {get(props, 'paymentRunDetails.identifier')}
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <span>Select the Bank Processing Date:</span>
          <CommonDatePicker
            id='bankProcessingDate'
            floatingLabelText='Bank Processing Date'
            onChange={handleBankProcessingDateChange}
            errorText={bankProcessingDateError}
            value={bankProcessingDate}
            minDate={new Date()}
          />
        </DialogContent>
        <DialogActions>
          <CommonButton
            key='cancel'
            label='Cancel'
            onClick={() => setOptionKey("markDialogOpen", false)}
            color="default"
            variant='outlined'
          />
          <CommonButton
            key='ok'
            label='OK'
            primary={true}
            onClick={(event) => handleDialogOk(event)}
            variant='flat'
          />
        </DialogActions>
      </Dialog>
    }
    {options.isPaidDialogOpen &&
      <Dialog
        id='paid-dialog-open'
        open={options.isPaidDialogOpen}
        onClose={() => setOptionKey("isPaidDialogOpen", false)}
        aria-labelledby='form-dialog-title'
        fullWidth
      >
        <DialogTitleWithCloseIcon
          onClose={() => setOptionKey("isPaidDialogOpen", false)}
        >
          Payment Run : {get(props, 'paymentRunDetails.identifier')}
        </DialogTitleWithCloseIcon>
        <DialogContent>
          <span>Click Send Now to send emails to saved recipients.</span>
        </DialogContent>
        <DialogActions>
          <CommonButton
            key='doLater'
            label="I'll do it later"
            onClick={() => handlePaidDialogOk(false)}
            secondary
            variant="contained"
          />
          <CommonButton
            key='sendNow'
            label='Send Now'
            primary={true}
            onClick={() => handlePaidDialogOk(true)}
            variant='flat'
          />
        </DialogActions>
      </Dialog>
    }
    {options.isUnPaidDialogOpen &&
      <Dialog
        id='unpaid-dialog-open'
        open={options.isUnPaidDialogOpen}
        onClose={() => setOptionKey("isUnPaidDialogOpen", false)}
        aria-labelledby='form-dialog-title'
        fullWidth
      >
        <DialogTitle>
          Do you want to mark this payment run unpaid ?
        </DialogTitle>
        <DialogContent>
          <span>{get(props, 'paymentRunDetails.identifier')} will be marked unpaid.</span>
        </DialogContent>
        <DialogActions>
          <CommonButton
            key='cancel'
            label='Cancel'
            onClick={() => setOptionKey("isUnPaidDialogOpen", false)}
            color="default"
            variant='outlined'
          />
          <CommonButton
            key='ok'
            label='OK'
            primary={true}
            onClick={handleUnPaidDialogOk}
            variant='flat'
          />
        </DialogActions>
      </Dialog>
    }
    {
      props.isPaymentRunVoidDialogOpen &&
      <RejectionReasonDialog
        open={props.isPaymentRunVoidDialogOpen}
        onClose={closeVoidDialog}
        title={`Void Payment Run: ${get(props, 'paymentRunDetails.identifier')}`}
        value={requestReason}
        onChange={(event) => setRequestReason(event.target.value)}
        onReject={handleVoidPaymentRun}
        onCancel={closeVoidDialog}
        placeholder="Enter the reason for void"
        submitText="Submit"
        required
      />
    }
  </span>
  );
};

const mapStateToProps = state => ({
  isPaymentRunVoidDialogOpen: state.companies.invoices.isPaymentRunVoidDialogOpen
})

export default connect(mapStateToProps)(PaymentRunActions);
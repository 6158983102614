import React from 'react';

import { connect } from 'react-redux';
import AutoComplete from './AutoComplete';
import { isEmpty, isNumber, filter, find, isEqual, get } from 'lodash';
import { getCommoditiesWithVarieties } from '../../../actions/api/commodities';
import { TextField, createFilterOptions } from '@mui/material';
import alertifyjs from 'alertifyjs';
import APIService from '../../../services/APIService';
import { receiveVarieties } from '../../../actions/master/varieties';
import { customVarietiesAllowed } from '../../../common/utils';

const autocompleteFilters = createFilterOptions();

class VarietyAutoComplete extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
    };
  }

  componentDidMount() {
    if (isEmpty(this.props.varieties) && !this.props.noFetch) {
      this.props.getCommoditiesWithVarieties();
    }
    this.setState({searchText: get(this.props, 'varietyId', '')});
  }

  componentDidUpdate(prevProps) {
    if((this.props.varietyId && this.props.varietyId != prevProps.varietyId) || (!isEqual(this.props.varieties, prevProps.varieties))){
      var item = find(this.props.varieties, {id: this.props.varietyId});
      if(!isEmpty(item)) {
        this.setState({
            searchText: item['id']
        },() => {
            this.props.onChange(item['id'], this.props.id);
        });
      }
    }

    if (
      (this.props.varietyId !== prevProps.varietyId) &&
      (!isEmpty(this.props.varietyId) || isNumber(this.props.varietyId)) &&
      !isEmpty(this.props.varieties) &&
      isEmpty(this.state.searchText)
    ) {
      const variety = find(this.props.varieties, {id: this.props.varietyId});
      if (variety) {
        this.setState({ searchText: variety.id });
      }
    }

    if (
      this.props.dependsOnCommodity &&
      prevProps.commodityId &&
      this.props.commodityId != prevProps.commodityId
    ) {
      this.setState(
        { searchText: undefined },
        () => this.props.onChange(undefined, this.props.id)
      );
    }
  }

  handleChange = () => value => {
    if (value && value.inputValue) {
      APIService.commodities()
        .appendToUrl('varieties/')
        .post({name: value.inputValue, commodity_id: this.props.commodityId})
        .then(res => {
          if (get(res, 'id')) {
            alertifyjs.success("Variety created successfully");
            let varieties = this.props.varieties;
            varieties.push(res);
            this.props.receiveVarieties(varieties);
            this.setState(
              {searchText: get(res, 'id')},
              () => this.props.onChange(get(res, 'id'), this.props.id)
            );
          }
          else
            alertifyjs.error("Error creating variety")
        })
    }
    else {
      this.setState(
        {searchText: value},
        () => this.props.onChange(value || undefined, this.props.id)
      );
    }
  };

  filterOptions = (options, params) => {
    const filtered = autocompleteFilters(options, params);
    if (customVarietiesAllowed()) {
      if (params.inputValue !== '') {
        filtered.push({
          inputValue: params.inputValue,
          name: `Add "${params.inputValue}" as a new variety`,
        });
      }
      return filtered;
    }
    return filtered;
  }

  render() {
    const varieties = filter(this.props.varieties, (variety) => {
        if (this.props.dependsOnCommodity) {
          return this.props.commodityId === variety.commodityId;
        }
        return true;
    });

    const items = varieties && varieties.map((e) => {
      if (this.props.dataSourceConfig) {
        return {label: e[this.props.dataSourceConfig.text], value: e[this.props.dataSourceConfig.value]};
      }
      return {label: e.name, value: e.id};
    });

    return (
      <div className="relative-pos" ref={this.props.setRef}>
        <AutoComplete
          options={items}
          value={this.state.searchText}
          errorText={this.props.errorText}
          disabled={this.props.disabled || (this.props.dependsOnCommodity && !this.props.commodityId)}
          label={this.props.label || "Variety"}
          placeholder={this.props.label || "Variety"}
          onBlur={this.props.onBlur}
          id={this.props.id}
          filterOptions={this.filterOptions}
          blurOnSelect={true}
          renderInput={params => (
            <TextField
              {...params}
              variant="standard"
              fullWidth
            />
          )}
          onChange={this.handleChange()}
        />
        {this.props.disabled && (this.props.dependsOnCommodity && !this.props.commodityId) || (this.props.disabled && this.props.varietyId) ?
         <i className="icon-lock"></i>: ''
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    varieties: state.master.varieties.items,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getCommoditiesWithVarieties: () => dispatch(getCommoditiesWithVarieties()),
    receiveVarieties: (items) => dispatch(receiveVarieties(items)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VarietyAutoComplete);

import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import { set, mapValues, forEach, cloneDeep, some, get } from 'lodash';
import { isLoading, forceStopLoader } from '../../../actions/main';
import CommonTextField from '../../common/CommonTextField';
import { FIELD, REQUIRED_FIELD } from '../../../common/constants';
import { TYPES } from '../constants';
import {
  generateIdentifier
} from '../../../common/utils';
import TransactionSearchSelect from '../../common/TransactionSearchSelect';
import TemplateSearch from '../../common/TemplateSearch';
import APIService from '../../../services/APIService';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

class DeclarationType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTemplate: null,
      editableTemplate: true,
      fields: {
        type: { ...cloneDeep(REQUIRED_FIELD), value: TYPES[this.props.formTypeId].id },
        identifier: { ...cloneDeep(REQUIRED_FIELD), value: generateIdentifier('vd') },
        externalReferenceNumber: { ...FIELD }
      },
    };
    this.getTemplateService = this.getTemplateService.bind(this);
    this.getSelectedTemplate = this.getSelectedTemplate.bind(this);
    this.setDataFromTemplate = this.setDataFromTemplate.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.setFieldEmpty = this.setFieldEmpty.bind(this);
  }

  transactionsService = searchStr => {
    const { formTypeId } = this.props;
    return APIService.profiles().appendToUrl(`vendor-dec-transactions/${formTypeId}/${searchStr}/`);
  };

  componentDidMount() {
    const { defaultSelectedEntity } = this.props;
    this.setState(
      { editableTemplate: !defaultSelectedEntity },
      this.propagateChanges
    );
  }

  componentDidUpdate(prevProps) {
    const { validate, defaultSelectedEntity } = this.props;
    if (!prevProps.validate && validate) {
      this.setAllFieldErrors();
    }
    if (defaultSelectedEntity && !prevProps.defaultSelectedEntity) {
      this.setFieldEmpty();
    }
  }

  setFieldEmpty() {
    const newState = { ...this.state.fields };
    set(newState, 'externalReferenceNumber.value', null);
    this.setState(newState, this.propagateChanges);
  }

  setAllFieldErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, state => {
      state.validators.forEach(validator => {
        if (validator.isInvalid(state.value))
          state.errors.push(validator.message);
        else
          state.errors = [];
      });
    });
    this.setState(newState, this.propagateChanges);
  }

  hasErrors() {
    const { fields } = this.state;
    return some(fields, field => field.errors.length > 0);
  }

  onFieldChange = event => {
    const newState = { ...this.state };
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    set(newState.fields, `${event.target.id}.value`, value);
    this.setState(newState, this.propagateChanges);
  };

  propagateChanges() {
    if (this.props.defaultSelectedEntity && !get(this.props.defaultSelectedEntity, 'seller'))
      this.props.onChange(mapValues(this.state.fields, 'value'), { declarationType: this.hasErrors() });
    else if (this.state.fields.externalReferenceNumber.value)
      this.props.onChange(mapValues(this.state.fields, 'value'), { declarationType: this.hasErrors(), party: true });
    else
      this.props.onChange(mapValues(this.state.fields, 'value'), { declarationType: this.hasErrors(), party: false });
  }

  getTemplateService(query, callback) {
    const searchStr = get(query, 'search');
    const { formTypeId } = this.props;
    if (searchStr) {
      APIService.vendor_decs().appendToUrl(`web/templates/search/${formTypeId}/${searchStr}/`).get().then(templates => {
        callback(templates);
      });
    }
  }

  getSelectedTemplate = item => {
    if (!item) {
      this.setState({ editableTemplate: true});
      this.props.isNotIndependentCheck(true);
      return;
    }
    alertifyjs
      .confirm(
        'Warning',
        'Already filled values in the Vendor Declaration will be overridden. Do you wish to continue?',
        () => {
          const { dispatch } = this.props;
          dispatch(isLoading('gettingTemplate'));
          APIService.vendor_decs().appendToUrl(`web/templates/${item.id}/`).get().then(data => {
            this.setState({
              selectedTemplate: data,
              editableTemplate: true,
            }, this.setDataFromTemplate);
            if(this.props.isNotIndependentCheck)
            this.props.isNotIndependentCheck(get(data, 'parentId') ? true : false);
          });
        },
        () => { },
      )
      .set('labels', { ok: 'Yes', cancel: 'No' });
  };

  setDataFromTemplate() {
    const { selectedTemplate, fields } = this.state;
    const { onReferenceEntitySelect, dispatch } = this.props;
    if (!selectedTemplate)
      return;

    if (selectedTemplate.parentId)
      onReferenceEntitySelect(
        { entity: selectedTemplate.parentEntity, id: get(selectedTemplate, 'parentId') }, selectedTemplate
      );
    else {
      set(fields, 'externalReferenceNumber.value', get(selectedTemplate, 'externalReferenceNumber'));
      this.setState(fields);
      onReferenceEntitySelect({ entity: 'vendordec', id: get(selectedTemplate, 'id') }, selectedTemplate);
    }

    setTimeout(() => dispatch(forceStopLoader()), 1000);
  }

  shouldHaveDefaultSelectedTransaction() {
    const { selectedTemplate } = this.state;
    const { defaultSelectedEntity } = this.props;

    return get(selectedTemplate.status === 'template') && defaultSelectedEntity;
  }

  handleBlur(event) {
    if (get(event.target, 'value') != '') {
      APIService.empty().appendToUrl(`common/exists/${event.target.value}/`).get().then(res => {
        const newState = { ...this.state };
        if (res.result) {
          newState.fields.externalReferenceNumber.errors = ['Reference Number already exists in system'];
        }
        else {
          newState.fields.externalReferenceNumber.errors = [];
        }
        this.setState({ newState });
      });
    }
  }

  render() {
    const { defaultSelectedEntity, URLSelectedEntityId, isLockedToOneParent, showToggle, formTypeId, fieldRef } = this.props;
    const { editableTemplate } = this.state;

    return (
      <div style={{ marginBottom: '20px' }}>
        <h4 className='cardForm-title'>General</h4>
        <div className='cardForm-content'>
          {
            !isLockedToOneParent &&
            <div className='col-md-6' style={{ marginTop: '15px' }}>
              <TemplateSearch
                onChange={this.getSelectedTemplate}
                service={this.getTemplateService}
                placeholder="Quick Vendor Declaration (Type to search...)"
              />
            </div>
          }
          <div className='col-md-6' style={{ marginBottom: '15px' }}>
           <CommonTextField
              id='identifier'
              label='Identifier'
              setRef={fieldRef["data.identifier"]}
              placeholder='Please enter 14 digit unique number'
              value={this.state.fields.identifier.value}
              onChange={this.onFieldChange}
              helperText={this.state.fields.identifier.errors[0]}
            />
          </div>
          {
            (editableTemplate && showToggle) &&
            <div className='col-md-6' style={{paddingLeft: '23px'}}>
                <Grid component="label" container alignItems="center" spacing={1}>
                  <div style={{ fontSize: '15px', color: 'rgba(0, 0, 0, 0.54)', paddingRight: '136px'}}>Contract/Movement/Order exists</div>
                  <Grid item>Outside AgriChain</Grid>
                  <Grid item>
                    <Switch color='primary' checked={this.props.isNotIndependent} onChange={() => this.props.isNotIndependentCheck(!this.props.isNotIndependent)} name="checkedC" />
                  </Grid>
                  <Grid item>On AgriChain</Grid>
                </Grid>
            </div>
          }
          {
            this.props.isNotIndependent ?
              (<div className='col-md-6'>
                {editableTemplate ?
                <div style={{ marginTop: '15px' }}>
                  <TransactionSearchSelect
                    onSelect={this.props.onReferenceEntitySelect}
                    transactionsService={this.transactionsService}
                    defaultSelected={defaultSelectedEntity}
                    labelName={formTypeId === 1 ? "Enter Valid Movement" : null}
                    formTypeId={this.props.formTypeId}
                  />
                  </div> :
                  <CommonTextField
                    disabled
                    id='selected-parent'
                    value={get(defaultSelectedEntity, 'displayName')}
                    label='Contract/Order/Movement'
                  />
                }
              </div>)
              :
              (
                (<div className='col-md-6'>
                  {URLSelectedEntityId ?
                    <CommonTextField
                      disabled
                      id='selected-parent'
                      value={get(defaultSelectedEntity, 'displayName')}
                      label='Contract/Order/Movement'
                    /> :
                    <div style={{ marginTop: '15px' }}>
                    <CommonTextField
                      id='externalReferenceNumber'
                      placeholder='Contract/Order/Movement Reference No.'
                      setRef={fieldRef["data.externalReferenceNumber"]}
                      maxLength={25}
                      onChange={this.onFieldChange}
                      helperText={this.state.fields.externalReferenceNumber.errors[0]}
                      onBlur={this.handleBlur}
                    />
                    </div>}
                </div>))
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(DeclarationType);

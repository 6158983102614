import {
    GET_CASH_PRICES_SUCCESS,
    CAN_CREATE_TITLE_TRANSFER_CASH_PRICES,
    SELECTED_CASH_PRICE,
    EDIT_SELECTED_CASH_PRICE, VIEW_SELECTED_CASH_PRICE,
    ENTITIES_FOR_ALL_CASH_PRICES,
    CASH_PRICE_ALL_COMMODITIES,
    CASH_PRICE_CURRENT_COMMODITY,
    FILTERS_FOR_UNREGISTERED_USER,
    GET_COMPANY_CASH_PRICES_SUCCESS,
    GET_ARCHIVED_CASH_PRICES_SUCCESS,
    GET_SITE_ARCHIVED_CASH_PRICES_SUCCESS,
    GET_SITES_ACTIVE_CASH_PRICES_SUCCESS,
    SHOW_AUDIT_HISTORY,
    RESET_AUTO_SCROLL_POSITION,
    SET_INTERVAL_TIME,
    SET_SELECTED_TAB
} from '../../actions/companies/cash-board';
import { get } from 'lodash';

const initialState = {
    cashPricesPaginatedData: [],
    cashPrices: [],
    canCreateTTForCashPrice: false,
    selectedCashPrice: undefined,
    titleTransfers: [],
    titleTransferPaginatedData: [],
    editSelectedCashPrice: false,
    viewSelectedCashPrice: false,
    commodityId: undefined,
    showAuditHistoryDialog: false,
    track: '',
    selectedTab: 1,
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_CASH_PRICES_SUCCESS:
            return {
                ...state,
                cashPrices: get(action.items, 'results', get(action, 'items', [])),
                cashPricesPaginatedData: action.items,
            };
        case CAN_CREATE_TITLE_TRANSFER_CASH_PRICES:
            return {
                ...state,
                canCreateTTForCashPrice: get(action.items, 'result'),
            };
        case SELECTED_CASH_PRICE:
            return {
                ...state,
                selectedCashPrice: action.item,
            };
        case EDIT_SELECTED_CASH_PRICE:
            return {
                ...state,
                editSelectedCashPrice: action.flag,
            };
        case VIEW_SELECTED_CASH_PRICE:
            return {
                ...state,
                viewSelectedCashPrice: action.flag,
            };
        case ENTITIES_FOR_ALL_CASH_PRICES:
            return {
                ...state,
                commodityId: action.commodityId,
                track: action.track,
            };
        case CASH_PRICE_ALL_COMMODITIES:
            return {
                ...state,
                cashPriceCommodityIds: action.commodityIds,
            }
        case CASH_PRICE_CURRENT_COMMODITY:
            return {
                ...state,
                currentCommodityId: action.currentCommodityId,
            }
        case FILTERS_FOR_UNREGISTERED_USER:
            return {
                ...state,
                unregisteredUserFilters: action.filters,
            }
        case GET_COMPANY_CASH_PRICES_SUCCESS:
            return {
                ...state,
                companyCashPrices: get(action.items, 'results', get(action, 'items', [])),
                companyCashPricesPaginatedData: action.items,
            };
        case GET_SITES_ACTIVE_CASH_PRICES_SUCCESS:
            return {
                ...state,
                siteActiveCashPrices: get(action.items, 'results', get(action, 'items', [])),
                siteActiveCashPricesPaginatedData: action.items,
            };
        case GET_ARCHIVED_CASH_PRICES_SUCCESS:
            return {
                ...state,
                archivedCashPrices: get(action.items, 'results', get(action, 'items', [])),
                archivedCashPricesPaginatedData: action.items,
            };
        case GET_SITE_ARCHIVED_CASH_PRICES_SUCCESS:
            return {
                ...state,
                siteArchivedCashPrices: get(action.items, 'results', get(action, 'items', [])),
                siteArchivedCashPricesPaginatedData: action.items,
            };
        case SHOW_AUDIT_HISTORY:
            return {
                ...state,
                showAuditHistoryDialog: action.flag,
            }
        case RESET_AUTO_SCROLL_POSITION:
            return {
                ...state,
                resetAutoScrollPosition: action.flag,
            }
        case SET_INTERVAL_TIME:
            return {
                ...state,
                intervalTime: action.time,
            }
        case SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.tab,
            }
        default:
            return {
                ...state
            };
    }
};

export default reducer;
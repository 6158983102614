import React from 'react';
import { connect } from 'react-redux';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import {canRejectLoad, canMarkComplete, completeMovement} from '../../../actions/companies/freights';
import LoaderInline from '../../LoaderInline';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RejectLoadDialog from "../../rejections/RejectLoadDialog";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import CommonButton from "../../common/CommonButton";
import {isLoading} from "../../../actions/main";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import APIService from '../../../services/APIService';
import { getCountryLabel, openURLInNewTab } from '../../../common/utils';

class MovementCompletionGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      completeDialogOpen: false,
      rejectLoadDialogOpen: false,
      selectedContract: null,
      isFetching: true,
      contracts: []
    };

    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.freights().appendToUrl('contracts/completion/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(get(contracts, "length"));
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulContractFromList();
  }

  removeSuccessfulContractFromList() {
    const { responseContract} = this.props;
    const { selectedContract, contracts } = this.state;
    if(
      selectedContract &&
      get(responseContract, 'id') === selectedContract.id &&
      !isEmpty(contracts)
    ) {
      const index = findIndex(contracts, {id: responseContract.id});
      this.setState({
        contracts: [...contracts.slice(0, index), ...contracts.slice(index + 1)],
        selectedContract: null,
        completeDialogOpen: false,
           rejectLoadDialogOpen: false,
      });
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/freights/movements/${id}/details`);
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Completion ${suffix}`;
  }


  openRejectLoadDialog = () => {
    this.setState({ rejectLoadDialogOpen: true });
  };

  closeRejectLoadDialog = () => {
    this.setState({ rejectLoadDialogOpen: false });
  };

  canRejectLoad = (event, contract) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ selectedContract: contract });
    this.props.dispatch(
      canRejectLoad(
        contract.id,
        this.openRejectLoadDialog
      ),
    );
  };

  handleCompleteClickOpen = () => {
    this.setState({ completeDialogOpen: true});
  };


  canMarkComplete = (event, contract) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ selectedContract: contract });
    this.props.dispatch(
        canMarkComplete(
            contract.id,
            this.handleCompleteClickOpen
        ),
    );
  };

  handleCompleteClose = () => {
    this.setState({ completeDialogOpen: false });
  };

  handleCompleteDialogOk = (event) => {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ completeDialogOpen: false });
    this.handleMarkAsComplete(event);
  };

  handleCompleteDialogCancel = () => {
    this.setState({ completeDialogOpen: false });
  };

  handleMarkAsComplete(event) {
    const { dispatch } = this.props;
    dispatch(isLoading());
    dispatch(completeMovement(this.state.selectedContract.id, false));
    event.stopPropagation();
    event.preventDefault();
  }

  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.contracts))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon/>}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Movement No.</TableCell>
                      <TableCell align='center' className="xsmall">Customer</TableCell>
                      <TableCell align='center' className="xsmall">Freight Provider</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('rego')}</TableCell>
                      <TableCell align='center' className="medium">Commodity Description</TableCell>
                      <TableCell align='center' className="xsmall">Pickup</TableCell>
                      <TableCell align='center' className="xsmall">Delivery</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={contract.referenceNumber}
                          onClick={(event) => this.onRowClick(event, contract.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(contract.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.customer)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.freightProvider)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.rego)}</TableCell>
                          <TableCell align='center' className="medium">{this.getColumnValue(contract.commodityDescription)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.pickupDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(contract.deliveryDetails)}</TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-red-outlined"
                              onClick={(event) => this.canRejectLoad(event, contract)}>
                              Reject Load
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{marginLeft: '8px'}}
                              onClick={(event) => this.canMarkComplete(event, contract)}>
                              Mark Complete
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <RejectLoadDialog
          open={this.state.rejectLoadDialogOpen}
          onClose={this.closeRejectLoadDialog}
          movement={this.state.selectedContract}
          shouldReload={false}
        />
        <Dialog
          open={this.state.completeDialogOpen}
          onClose={this.handleCompleteClose}
          aria-labelledby="form-dialog-title"
          fullWidth
          id="complete-dialog-open"
        >
          <DialogTitle id="form-dialog-title">
            Complete Movement
          </DialogTitle>
          <DialogContent>
            Are you sure to mark the movement as completed?
          </DialogContent>
          <DialogActions>
            <CommonButton
              key="no"
              label='No'
              primary={true}
              onClick={this.handleCompleteDialogCancel}
              variant="flat"
            />
            <CommonButton
              key="yes"
              label='Yes'
              primary={true}
              onClick={this.handleCompleteDialogOk}
              variant="flat"
            />
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseContract: state.companies.freights.confirmedOrRejectedContract,
});

export default connect(mapStateToProps)(MovementCompletionGroup);

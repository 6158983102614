export const RECEIVE_NGRS = 'RECEIVE_NGRS';
export const ADD_NGR = 'ADD_NGR';
export const EDIT_NGR = 'EDIT_NGR';
export const CANCEL_EDIT_NGR = 'CANCEL_EDIT_NGR';
export const CLICK_ADD_NGR_BUTTON = 'CLICK_ADD_NGR_BUTTON';
export const CLICK_EDIT_NGR_ICON = 'CLICK_EDIT_NGR_ICON';
export const CLICK_EDIT_NGR = 'CLICK_EDIT_NGR';
export const ADD_NGR_ERRORS = 'ADD_NGR_ERRORS';
export const EDIT_NGR_ERRORS = 'EDIT_NGR_ERRORS';

export const receiveNgrs = items => ({
  type: RECEIVE_NGRS,
  items,
});

export const addNgr = item => ({
  type: ADD_NGR,
  item,
});

export const addNgrErrors = errors => ({
  type: ADD_NGR_ERRORS,
  errors,
});

export const editNgr = item => ({
  type: EDIT_NGR,
  item,
});

export const editNgrErrors = errors => ({
  type: EDIT_NGR_ERRORS,
  errors,
});

export const cancelEditNgr = () => ({
  type: CANCEL_EDIT_NGR,
});

export const clickAddNgrButton = () => ({
  type: CLICK_ADD_NGR_BUTTON,
});

export const clickEditNgrIcon = id => ({
  type: CLICK_EDIT_NGR_ICON,
  id,
});

export const clickEditNgr = id => ({
  type: CLICK_EDIT_NGR,
  id,
});

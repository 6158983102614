import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setClickedTitleTransferOption, setSelectedTitleTransfer } from '../../actions/companies/contracts';
import AssignTitleTransferToContract from './AssignTitleTransferToContract';
import { get, isArray } from 'lodash';
import alertifyjs from 'alertifyjs';

let TitleTransferActions = props => {

  const [options, setOptions] = useState({openAssignToDrawer: false})

  let dispatch = useDispatch();

  let checkForOptions = () => {
    let {clickedOption, selectedTitleTransfer} = props;
    selectedTitleTransfer = isArray(selectedTitleTransfer) ? get(selectedTitleTransfer, '0') : selectedTitleTransfer;
    if (selectedTitleTransfer && clickedOption === 'assign_to') {
      if (get(selectedTitleTransfer, 'isCashPriced'))
        alertifyjs.alert(
          'Permission Denied',
          `<div><p>Title Transfer cannot be assigned to a new contract because: </p> <ul><li> Cashed Title transfers cannot be assigned to another contract. If there are any issues in this transfer, Employees of the Site where this transfer has been executed can mark this transfer Void and then it can be recreated.</li></ul></div>`,
          () => {},
        );
      else if (get(selectedTitleTransfer, 'status') == 'invoiced')
        alertifyjs.alert(
          'Permission Denied',
          `<div><p>Title Transfer cannot be assigned to a new contract because: </p> <ul><li> Title Transfer has been invoiced. If there are any issues in this transfer, Employees of the Site where this transfer has been executed can mark this transfer Void and then it can be recreated.</li></ul></div>`,
          () => {},
        );
      else if (get(selectedTitleTransfer, 'status') == 'void')
        alertifyjs.alert(
          'Permission Denied',
          `<div><p>Title Transfer cannot be assigned to a new contract because: </p> <ul><li> Title Transfer has been voided.</li></ul></div>`,
          () => {},
        );
      else
        setOptionKey("openAssignToDrawer", true);
      dispatch(setClickedTitleTransferOption(null));
    }
  }

  let setOptionKey = (key, value) =>{
    setOptions({
        ...options,
        [key]: value
    });
    dispatch(setClickedTitleTransferOption(null));
    if (key === 'openAssignToDrawer' && !value)
      dispatch(setSelectedTitleTransfer(null))
  };

  useEffect(() => {
    if (props.clickedOption)
      checkForOptions()
  }, [props.clickedOption, props.selectedTitleTransfer])

  return (
    <div>
      {
        options.openAssignToDrawer &&
        <AssignTitleTransferToContract {...props} titleTransfer={props.selectedTitleTransfer} onClose={() => setOptionKey("openAssignToDrawer", false)} />
      }
    </div>
  )

};

export default TitleTransferActions;

import React from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import Check from '@mui/icons-material/Check';
import Cancel from '@mui/icons-material/Close';
import { required } from '../../../common/validators';
import { get, includes, isEmpty} from 'lodash';
import { isSystemCompany } from '../../../common/utils';
import {
  confirmMovement,
  rejectMovement,
  rejectMovementAfterDelivery,
  raiseVoidRequest,
  confirmVoid,
  rejectVoid,
  assignMovement,
  deliverMovement, handleFreightMovementOptionsMapper, setClickedOption
} from '../../../actions/companies/freights';
import { RejectionReasonDialog } from '../../rejections/RejectionReasonDialog';
import RejectLoadDialog from '../../rejections/RejectLoadDialog';
import { FREIGHT_CONTRACT_TYPE, PACK_ORDER_TYPE_ID } from '../../../common/constants';
import { ASSIGN_CONTRACT_FO_SUCCESS, MOVEMENT_CONFIRMED} from './Constants';
import moment from 'moment';
import NestedOptionMenu from "../../NestedOptionMenu";
import {isSystemStorageOrBhc} from "../../../common/utils";
import { getFreightMovementActionMenuOptions, regenerateMovementPDF } from '../utils';
import MovementActions from '../MovementActions';
import SideDrawer from '../../common/SideDrawer';
import AssignContractToMovementForm from './AssignContractToMovementForm';
import EditMovementReview from './EditMovementReview';


class MovementDetailsActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      acceptDrawerOpen: false,
      rejectDialogOpen: false,
      rejectionAction: null,
      rejectionTitle: null,
      completeDialogOpen: false,
      amendDialogOpen: false,
      movementNumber: {
        value: this.props.movement ? this.props.movement.identifier : undefined,
        validators: [required()],
        errors: [],
      },
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: [],
      },
      assignExistingFmToOrder: false,
      rejectLoadDialogOpen: false,
    };
    this.handleAcceptSubmit = this.handleAcceptSubmit.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleVoidClick = this.handleVoidClick.bind(this);
    this.setReasonErrors = this.setReasonErrors.bind(this);
    this.getReasonErrors = this.getReasonErrors.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
    this.canAssign = this.canAssign.bind(this);
  }

  componentWillUnmount() {
    this.clearEntity();
  }

  componentDidMount() {
    setTimeout(this.setActionButtonBoundaries, 100);
  }

  clearEntity() {
    this.props.dispatch(setClickedOption(null));
  }

  setActionButtonBoundaries() {
    const statusBarEl = document.getElementById('movement-details-basic-info-card');
    if (statusBarEl) {
      const top = statusBarEl.offsetTop;
      const height = statusBarEl.offsetHeight;
      if (top && height) {
        const actionsContainer = document.getElementById('nested-actions-menu-container');
        if (actionsContainer) {
          actionsContainer.style.top = (top - 1) + 'px';
          actionsContainer.style.height = (height + 1) + 'px';
        }
      }

    }
  }

  handleActionsClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleActionsClose = () => {
    this.setState({ anchorEl: null });
  };

  handleAssignToSideDrawer = (key, val) => {
    this.setState({
      [key]: val
    });
  };

  handleAcceptDrawerClose = () => {
    this.setState({ acceptDrawerOpen: false, assignExistingFmToOrder: false });
  };

  handleRejectClickOpen = action => {
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    if (!newState.rejectionReason.value) {
      newState.rejectionReason.errors = [];
    }
    newState.rejectionAction = action;
    newState.rejectionTitle = 'Reject ' + (action === 'confirm' ? 'Movement' : action === 'void' ? 'Void Request' : '');
    this.setState(newState);
  };

  handleRejectClose = () => {
    this.setState({ rejectDialogOpen: false });
  };

  handleCompleteClickOpen = () => this.setState({ completeDialogOpen: true });

  openRejectLoadDialog = () => {
    this.setState({ rejectLoadDialogOpen: true });
  };

  closeRejectLoadDialog = () => {
    this.setState({ rejectLoadDialogOpen: false });
  };


  handleDeliverDialogOk = e => {
    e.preventDefault();
    this.setState({ completeDialogOpen: false }, () => {
      const { dispatch } = this.props;
      // dispatch(isLoading());
      dispatch(deliverMovement(this.props.movement.id));
    });
  };

  handleVoidClick() {
    const { dispatch } = this.props;
    const data = { requestReason: this.state.requestReason.value };
    dispatch(raiseVoidRequest(this.props.movement.id, data));
    this.setState({ anchorEl: null });
  }

  acceptVoid(id) {
    this.props.dispatch(confirmVoid(id));
  }

  handleEditClick() {
    window.location.href = `#/freights/movements/${this.props.movement.id}/edit`;
  }

  acceptMovement = data => {
    const { dispatch } = this.props;
    if (this.state.assignExistingFmToOrder) {
      dispatch(assignMovement(this.props.movement.id, data, ASSIGN_CONTRACT_FO_SUCCESS));
    } else {
      dispatch(confirmMovement(this.props.movement.id, data, MOVEMENT_CONFIRMED));
    }
    this.setState({ acceptDrawerOpen: false, assignExistingFmToOrder: false });
  };


  handleAcceptSubmit(event) {
    if (this.props.movement.typeId === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY && this.props.movement.isCustomer) {
      this.setState({ acceptDrawerOpen: true, assignExistingFmToOrder: false });
    } else {
      this.acceptMovement({
        updatedAt: moment
          .utc(get(this.props.movement, 'updatedAt'))
          .local()
          .valueOf(),
      });
    }
    event.preventDefault();
  }

  handleRejectSubmit(event) {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value, feature: 'Movement Details' };
    if (this.state.rejectionReason.errors.length === 0) {
      const { dispatch } = this.props;
      if (isEmpty(this.props.movement.cannotMarkAsCompleteReasons)) {
        dispatch(rejectMovementAfterDelivery(this.props.movement.id, data));
      } else if (this.state.rejectionAction === 'confirm') {
        dispatch(rejectMovement(this.props.movement.id, data));
      } else if (this.state.rejectionAction === 'void') {
        dispatch(rejectVoid(this.props.movement.id, data));
      }
    }
    event.preventDefault();
  }

  handleAmendOpen = () => {
    this.setState({ anchorEl: null, amendDialogOpen: true });
  };

  handleAmendClose = () => {
    this.setState({ amendDialogOpen: false });
  };

  handleReasonChange(event) {
    const value = event.target.value;

    const newState = { ...this.state };
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  }

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  handleOptionClick = (event, item, baseEntity) => {
    if(get(item, 'key') === 'regenerate_pdf')
      regenerateMovementPDF(baseEntity);
    else {
      this.props.dispatch(
        handleFreightMovementOptionsMapper(event, item, baseEntity, 'MovementDetailsAction')
      );
    }
  };

  canMarkDelivered = movement => includes(['completed'], get(movement, 'status')) && isSystemCompany();

  canAssignToContractOrFreightOrder = movement => get(movement, 'status') !== 'planned' && get(movement, 'isIndependentCustomerOnly');

  getCanMarkToDelivered = movement => includes(['completed'], movement.status) && isSystemCompany();

  canAssign = movement => get(movement, 'typeId') === PACK_ORDER_TYPE_ID ? this.props.currentUser.isStaff || (this.props.freights.packSiteCompanyId === this.props.currentUser.companyId) : this.props.freights.canAssignForSelectedMovementId

  getEstablishmentForFetchingNgr(fetchFor, freightConst, loadConst) {
    let establishmentIdKey = null;
    let establishmentTypeKey = 'company';
    let isSystemStorage = false;
    const movementType = get(this.props, 'movement.typeId');
    if (fetchFor === 'freightPickup') {
      establishmentIdKey = 'movement.freightPickup.consignor.handlerId';
      establishmentTypeKey = 'company';
      isSystemStorage = isSystemStorageOrBhc(get(freightConst, 'consignor'), loadConst);
      if (isSystemStorage) {
        if (movementType === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          if (get(this.props, 'movement.orderActualCustomer')) {
            establishmentIdKey = 'movement.orderActualCustomer.company.id';
            establishmentTypeKey = 'company';
          } else {
            establishmentIdKey = 'movement.customer.companyId';
            establishmentTypeKey = 'company';
          }
        } else {
          establishmentIdKey = 'movement.commodityContract.seller.companyId';
          establishmentTypeKey = 'company';
        }
      }
    } else if (fetchFor === 'freightDelivery') {
      establishmentIdKey = 'movement.freightDelivery.consignee.handlerId';
      establishmentTypeKey = 'movement.freightDelivery.consignee.handlerContentType';
      isSystemStorage = isSystemStorageOrBhc(get(freightConst, 'consignee'), loadConst);
      if (isSystemStorage) {
        if (movementType === FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY) {
          if (get(this.props, 'movement.orderActualCustomer')) {
            establishmentIdKey = 'movement.orderActualCustomer.company.id';
            establishmentTypeKey = 'company';
          } else {
            establishmentIdKey = 'movement.customer.companyId';
            establishmentTypeKey = 'company';
          }
        } else {
          establishmentIdKey = 'movement.commodityContract.buyer.companyId';
          establishmentTypeKey = 'company';
        }
      }
    }

    if (establishmentIdKey && establishmentTypeKey) {
      return {
        establishmentId: get(this.props, establishmentIdKey),
        establishmentType: establishmentTypeKey,
      };
    }
  }

  render() {
    const
    {
      id, confirmable, isAmendRequestPending, voidable, amendable, cannotMarkAsCompleteReasons,
      isVoidRequestPending,
    } = this.props.movement;

    const { movement, subItems, clickedOption } = this.props;
    const canRespondOnMovement = includes(['delivered', 'confirmed', 'in_progress'], this.props.movement.status) && !isVoidRequestPending;
    const rejectLoadClass = canRespondOnMovement ? 'reject-load-status' : '';
    return (
      <div
        className={
          confirmable || amendable || voidable ? `${rejectLoadClass} status-actions-container grey-bg` : `${rejectLoadClass} status-actions-container`
        }
      >
        <MovementActions {...this.props} selectedFreightMovement={this.props.movement}
          selectedFreightMovementId={this.props.movement.id}
          isPackMovement={get(this.props.movement, 'typeId') === PACK_ORDER_TYPE_ID}
          canAssignForSelectedMovementId={this.canAssign(this.props.movement)} />

        {amendable && isAmendRequestPending && (
          <div className='status-actions-wrap'>
            <h4>Movement Amend Request</h4>
            <div>
              <Button variant="contained" className={'btn-green'} onClick={this.handleAmendOpen}>
                REVIEW
               </Button>
            </div>
          </div>
        )}

        {voidable && (
          <div className='status-actions-wrap'>
            <h4>Movement Void Request</h4>
            <div>
              <Button style={{ marginRight: '10px' }} variant="contained" className={'btn-red'} onClick={() => this.handleRejectClickOpen('void')}>
                <Cancel />
                 REJECT
               </Button>
              <Button variant="contained" className={'btn-green'} onClick={() => this.acceptVoid(id)}>
                <Check />
                 ACCEPT
               </Button>
             </div>
           </div>
        )}
        {
          this.state.rejectLoadDialogOpen &&
          <RejectLoadDialog open={this.state.rejectLoadDialogOpen} onClose={this.closeRejectLoadDialog} movement={this.props.movement} />
        }
        {confirmable && (
          <div className='status-actions-wrap'>
            <h4>Movement Confirm Request</h4>
            <div>
              <Button style={{ marginRight: '10px' }} variant="contained" color='error' className={'btn-red'} onClick={() => this.handleRejectClickOpen('confirm')}>
                <Cancel />
                 REJECT
               </Button>
              <Button variant="contained" className={'btn-green'} onClick={this.handleAcceptSubmit}>
                <Check />
                 ACCEPT
               </Button>
            </div>
          </div>
        )}
        {
          (
            (voidable || confirmable || isEmpty(cannotMarkAsCompleteReasons)) &&
            this.state.rejectDialogOpen
          ) && (
            <div className='status-actions-wrap'>
              <RejectionReasonDialog
                open={this.state.rejectDialogOpen}
                onClose={this.handleRejectClose}
                title={this.state.rejectionTitle}
                value={this.state.rejectionReason.value}
                onChange={this.handleReasonChange}
                helperText={get(this.state, 'rejectionReason.errors[0]', '')}
                onCancel={this.handleRejectClose}
                onReject={this.handleRejectSubmit}
              />
            </div>
          )
        }
        {
          this.state.acceptDrawerOpen &&
          <SideDrawer
            isOpen={this.state.acceptDrawerOpen}
            onClose={this.handleAcceptDrawerClose}
            title={this.state.assignExistingFmToOrder ? 'Assign Contract/Order' : 'Enter Freight Details'}
            classes={{ paper: 'left-text-align' }}
            >
            <AssignContractToMovementForm
              handleCancel={this.handleAcceptDrawerClose}
              handleAccept={this.acceptMovement}
              movement={this.props.movement}
              assignExistingFmToOrder={this.state.assignExistingFmToOrder}
            />
          </SideDrawer>
        }
        {
          this.props.movement &&
          <div className='status-actions-wrap'>
            <NestedOptionMenu
              optionsItems={getFreightMovementActionMenuOptions(movement, subItems, clickedOption, get(this.props.movement, 'typeId') === PACK_ORDER_TYPE_ID)}
              item={this.props.movement}
              handleOptionClick={this.handleOptionClick}
              currentUser={this.props.currentUser}
              shouldOptionBeDisabled={this.props.shouldOptionBeDisabled}
              useButton={true}
              useIconButton={false}
              buttonContainerStyle={{float: 'none', marginLeft: 'none'}}
            />
          </div>
        }
        {
          this.state.amendDialogOpen &&
          <SideDrawer
            isOpen={this.state.amendDialogOpen}
            onClose={this.handleAmendClose}
            title={`Movement Amend Request (${this.props.movement?.identifier})`}
            classes={{ paper: 'left-text-align' }}
            size='big'
            >
            <EditMovementReview movement={this.props.movement} closeSidebar={this.handleAmendClose} shouldReload={true} />
        </SideDrawer>
        }

      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userToken: state.main.user.token,
    canMarkCompleteForSelectedMovementId: state.companies.freights.canMarkCompleteForSelectedMovementId,
    rejectLoadForMovementId: state.companies.freights.rejectLoadForMovementId,
    subItems: state.companies.freights.subItems,
    clickedOption: state.companies.freights.clickedOption,
    voidableSelectedFreightMovementId: state.companies.freights.voidableSelectedFreightMovementId,
    currentUser: state.main.user.user,
    freights: state.companies.freights,
    selectedPackMovement: state.companies.freights.selectedFreightMovement,
    canVoidAndDuplicateFreightMovement: state.companies.freights.canVoidAndDuplicateFreightMovement,
  };
};

export default connect(mapStateToProps)(MovementDetailsActions);

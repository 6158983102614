import { Paper } from "@mui/material";
import React, { Component } from "react";
import alertifyjs from 'alertifyjs';
import LoaderInline from "../../LoaderInline";
import GenericTable from "../../GenericTable";
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import APIService from '../../../services/APIService'
import { get } from 'lodash';
import { MT_UNIT } from "../../../common/constants";
import ContractStockAllocationForm from "../ContractStockAllocationForm";
import { getCountryLabel } from "../../../common/utils";

const renderBasedOnExpandedFlag = expanded => expanded ? (<KeyboardArrowUp style={{ fill: '#112c42', height: '20px', width: '20px' }} />) : (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );

class ContractStockAllocations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allocations: [],
      expanded: true,
      isLoading: true,
      edit: false,
      editItem: undefined
    }
    this.fetchAllocations = this.fetchAllocations.bind(this);
  }

  componentDidMount() {
    this.fetchAllocations()
  }

  fetchAllocations() {
    APIService.contracts(this.props.contract.id)
      .appendToUrl('stock-allocations/')
      .get()
      .then(response => {
        const allocations = response;
        this.setState({allocations: allocations, isLoading: false, expanded: allocations.length > 0});
      });
  }

  toggleExpanded = () => this.setState({expanded: !this.state.expanded});

  unit() {
    return get(this.props.contract, 'commodity.priceUnit', MT_UNIT);
  }

  getAllocationOptionItems() {
    return [
      {key: 'edit', text: 'Edit'},
      {key: 'delete', text: 'Delete'},
      {key: 'create_freight_order', text: 'Create Freight Order'}
    ];
  }

  handleOptionClick = (option, item) => {
    if (option === 'edit')
      this.setState({edit: true, editItem: item});
    else if (option === 'delete')
      alertifyjs.confirm(
        'Warning',
        '<div>Selected stock allocation will be deleted. Are you sure?</div>',
        () => {
          APIService.contracts()
            .appendToUrl(`stock-allocations/${item.id}/`)
            .delete()
            .then(() => this.fetchAllocations());
        },
        () => { },
      ).set(
        'labels', {ok: 'Yes', cancel: 'No'}
      ).show();
    else if (option === 'create_freight_order') {
      window.location.hash = `#/freights/orders/new?commodityContractId=${this.props.contract.id}&stockAllocationId=${item.id}`;
    }
  }

  tonnageDisplayValue = () => get(this.props.contract, 'commodity.isQuantityBased') ? 'Quantity' : getCountryLabel('tonnage');

  render() {
    const { header } = this.props;
    const { expanded, allocations, isLoading } = this.state;
    const unit = this.unit();
    const tonnageDisplayValue = this.tonnageDisplayValue();
    const COLUMNS = [
      {header: 'Grade', key: 'gradeName'},
      {header: 'Season', key: 'season'},
      {header: 'NGR', key: 'ngrNumber'},
      {header: 'Location', key: 'siteDisplayName'},
      {header: `Allocated ${tonnageDisplayValue} (in ${unit})`, key: 'tonnage'},
      {header: `Planned ${tonnageDisplayValue} (in ${unit})`, key: `plannedTonnage`},
      {header: `Delivered ${tonnageDisplayValue} (in ${unit})`, key: 'deliveredTonnage'},

    ]
    const count = allocations.length;
    let heading = header
    if(!isLoading)
      heading += ` (${count})`

    return (
      <Paper className="contract-details-section-container">
        <h2 onClick={count ? this.toggleExpanded : () => {}}>
          { heading }
          {
            Boolean(count) &&
              <span className="expand-icon">
                {renderBasedOnExpandedFlag(expanded)}
              </span>
          }
        </h2>
        {
          expanded &&
            <div style={{marginTop: '15px'}}>
              {
                isLoading ?
                  <LoaderInline containerClassName="inline-loader-container" /> :
                  <GenericTable
                    columns={COLUMNS}
                    items={allocations}
                    showHeader={true}
                    showHeaderValue={false}
                    optionsItems={this.getAllocationOptionItems()}
                    handleOptionClick={(event, option, id, item) => this.handleOptionClick(option, item)}
                  />
              }
            </div>
        }
        {
          this.state.edit && this.state.editItem &&
          <ContractStockAllocationForm
            open={true}
            onClose={() => this.setState({edit: false, editItem: undefined})}
            editItem={this.state.editItem}
            contract={this.props.contract}
            fetchAllocations={this.fetchAllocations}
          />
        }
      </Paper>
    )
  }
}

export default ContractStockAllocations;
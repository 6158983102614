import { connect } from 'react-redux';
import TrailerForm from '../components/trucks/TrailerForm';
import { updateTrailer } from '../actions/api/trucks';
import { editTrailer, cancelEditTrailer } from '../actions/companies/trucks';
import find from 'lodash/find';

function submit(companyId, data) {
  return (dispatch, getState) => {
    const trailerId = getState().companies.companies.company.trucks.selectedTrailerId;
    dispatch(updateTrailer(trailerId, data, editTrailer));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedTrailer: find(
      state.companies.companies.company.trucks.trailers,
      { id: state.companies.companies.company.trucks.selectedTrailerId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (companyId, data) => dispatch(submit(companyId, data)),
    cancelEdit: () => dispatch(cancelEditTrailer()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TrailerForm);

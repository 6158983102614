import React from "react";
import { connect } from "react-redux";
import { get, includes, isEmpty, pull } from "lodash";
import SideDrawer from "../../common/SideDrawer";
import CommonButton from "../../common/CommonButton";
import { Checkbox, FormControlLabel } from "@mui/material";
import CommonTextField from "../../common/CommonTextField";
import APIService from '../../../services/APIService.js'
import alertifyjs from "alertifyjs";


class ClarificationDrawer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inload: {
        checkbox: false,
        keyInfoMissing: false,
        docketIssue: false,
        valueMissing: false,
        tonnageExhausted: false,
        category: '',
        subCategory: [],
      },
      outload: {
        checkbox: false,
        keyInfoMissing: false,
        docketIssue: false,
        valueMissing: false,
        tonnageExhausted: false,
        category: '',
        subCategory: [],
      },
      comment: ''
    }

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.isEditForm){
      APIService.freights().contracts().appendToUrl(`${this.props.freightMovementId}/seek-clarification/`)
      .get()
      .then(response => {
        const inloadData = get(response, 'inload');
        const outloadData = get(response, 'outload');
        const newState = {...this.state};
        if (inloadData){
          newState.inload.checkbox = true
          newState.inload.category = inloadData.category
          newState.inload.subCategory = inloadData.subCategory
          newState.comment = inloadData.comment
        }
        if(outloadData){
          newState.outload.checkbox = true
          newState.outload.category = outloadData.category
          newState.outload.subCategory = outloadData.subCategory
          newState.comment = outloadData.comment
        }
        this.setState(newState);
      })
    }
  }

  handleCheckboxClick = event => {
    const checkboxId = event.target.id;
    const newState = { ...this.state };
    newState[checkboxId].checkbox = event.target.checked;
    newState[checkboxId].category = '';
    newState[checkboxId].subCategory = [];
    this.setState(newState);
  }

  handleSubmit(event) {
    event.preventDefault();
    event.stopPropagation()
    if (((this.state.inload.checkbox && (!this.state.inload.category || isEmpty(this.state.inload.subCategory)))) ||
    (this.state.outload.checkbox && (!this.state.outload.category || isEmpty(this.state.outload.subCategory)))){
      alertifyjs.error("Category and Sub Category is required to add inload/outload clarification")
      return
    }
    let docketClarification =  {
      "comment": this.state.comment,
      "inload": {},
      "outload": {}
    }
    if(this.state.inload.category){
      docketClarification["inload"] = {
        "category": this.state.inload.category,
        "subCategory": this.state.inload.subCategory
      }
    }
    if(this.state.outload.category){
      docketClarification["outload"] = {
        "category": this.state.outload.category,
        "subCategory": this.state.outload.subCategory
      }
    }
    
    const submitData = {
      "docketClarification": docketClarification
    }
    const docketService = APIService.freights().contracts().appendToUrl(`${this.props.freightMovementId}/seek-clarification/`)
    if(this.props.isEditForm){
      docketService
      .put(submitData).then(response => {
        this.props.onClose();
        if (response.docketClarification)
          alertifyjs.success("Docket Clarification has been updated successfully", 2, ()=> window.location.reload());
        else
          alertifyjs.error("Something went wrong");
      })
    }
    else {
      docketService
      .post(submitData).then(response => {
        this.props.onClose();
        if (response.docketClarification)
          alertifyjs.success("Docket Clarification has been added successfully", 2, ()=> window.location.reload());
        else
          alertifyjs.error("Something went wrong");
      })
    }
  }


  buttonConfigurations = [
    { id: "keyInfoMissing", label: "Key Info Missing", key: 'key_info_missing' },
    { id: "docketIssue", label: "Docket Issue", key: 'docket_issue' },
    { id: "valueMissing", label: "Value Missing in AgriChain", key: 'value_missing_in_ac' },
    { id: "tonnageExhausted", label: "Tonnage Finished", key: 'tonnage_exhausted' }
  ];

  keyInfoCategory = [
    { id: "site", label: "Site" },
    { id: "grade", label: "Grade" },
    { id: "stock_owner", label: "Stock Owner" },
    { id: "net_weight", label: "Net Weight" },
    { id: "truck", label: "Truck" },
    { id: "season", label: "Season" },
    { id: "canola_spec", label: "Canola Spec" },
  ]
  docketIssueCategory = [
    { id: "irrelevant_image", label: "Irrelevant Image" },
    { id: "wrong_docket", label: "Wrong Docket" },
    { id: "multiple_dockets", label: "Multiple Dockets" },
    { id: "docket_not_readable", label: "Docket Not Readable" },
  ]
  valueMissingCategory = [
    { id: "site", label: "Site" },
    { id: "stock_owner", label: "Stock Owner" },
    { id: "grade", label: "Grade" }
  ]
  tonnageExhaustedCategory = [
    { id: "contract_tonnage_exhausted", label: "Contract Tonnage Finished" },
    { id: "order_tonnage_exhausted", label: "Order Tonnage Finished" },
  ]

  handleInloadCategorySelection = event => {
    const id = event.target.id;
    const newState = { ...this.state };
    newState.inload.category = id;
    newState.inload.subCategory = [];
    this.setState(newState);
  }

  handleOutloadCategorySelection = event => {
    const id = event.target.id;
    const newState = { ...this.state };
    newState.outload.category = id;
    newState.outload.subCategory = [];
    this.setState(newState);
  }


  handleInloadSubCategorySelection = event => {
    const id = event.target.id;
    const newState = { ...this.state };
    if (includes(this.state.inload.subCategory, id))
      pull(newState.inload.subCategory, id);
    else
      newState.inload.subCategory.push(id)
    this.setState(newState);
  }

  handleOutloadSubCategorySelection = event => {
    const id = event.target.id;
    const newState = { ...this.state };
    if (includes(this.state.outload.subCategory, id))
      pull(newState.outload.subCategory, id);
    else
      newState.outload.subCategory.push(id)
    this.setState(newState);
  }

  handleFieldChange = event => {
    const id = event.target.id
    const newState = { ...this.state };
    newState[id] = event.target.value
    this.setState(newState);
  }



  render() {
    const SubCategorySection = ({ category, subCategories }) => (
      this.state.inload.category === category && (
        <div className="row form-wrap col-xs-12" style={{ paddingLeft: '30px' }}>
          <label className='invoiced-amount-label col-xs-12' style={{ padding: "0px", marginBottom: "10px" }}>Sub Category (Required)</label>
          {subCategories.map((buttonConfig) => (
            <CommonButton
              key={buttonConfig.id}
              id={buttonConfig.id}
              label={buttonConfig.label}
              default={includes(this.state.inload.subCategory, buttonConfig.id)}
              variant={includes(this.state.inload.subCategory, buttonConfig.id) ? 'contained' : 'outlined'}
              color={includes(this.state.inload.subCategory, buttonConfig.id) ? 'primary' : ''}
              onClick={this.handleInloadSubCategorySelection}
              style={{ borderRadius: '25px', marginRight: '10px', textTransform: 'none' }}
              disabled={!this.state.inload.checkbox}
            />
          ))}
        </div>
      )
    );

    const OutloadSubCategorySection = ({ category, subCategories }) => (
      this.state.outload.category === category && (
        <div className="row form-wrap col-xs-12" style={{ paddingLeft: '30px' }}>
          <label className='invoiced-amount-label col-xs-12' style={{ padding: "0px", marginBottom: "10px" }}>Sub Category (Required)</label>
          {subCategories.map((buttonConfig) => (
            <CommonButton
              key={buttonConfig.id}
              id={buttonConfig.id}
              label={buttonConfig.label}
              default={includes(this.state.outload.subCategory, buttonConfig.id)}
              variant={includes(this.state.outload.subCategory, buttonConfig.id) ? 'contained' : 'outlined'}
              color={includes(this.state.outload.subCategory, buttonConfig.id) ? 'primary' : ''}
              onClick={this.handleOutloadSubCategorySelection}
              style={{ borderRadius: '25px', marginRight: '10px', textTransform: 'none' }}
              disabled={!this.state.outload.checkbox}
            />
          ))}
        </div>
      )
    );

    return (
      <div id='docket-clarification-form'>
        <React.Fragment>
          <SideDrawer
            isOpen={true}
            title={this.props.title}
            size="xlarge"
            onClose={this.props.onClose}
            app="load"
            paperStyle={{ textAlign: "left" }}
          >
            {
              <form onSubmit={this.handleSubmit} noValidate>
                <div className='cardForm cardForm--drawer col-xs-12 padding-reset'>
                  <div className='cardForm-content row trucks col-xs-12 padding-reset'>
                    <div className='col-xs-6' style={{ display: 'inline-block', marginTop: '16px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='inload'
                            color='primary'
                            checked={Boolean(this.state.inload.checkbox)}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        style={{ float: "left" }}
                        label='Add Clarification For Inload'
                      />
                    </div>

                    <div className="row form-wrap" style={{ paddingLeft: '30px' }}>
                      <label className='invoiced-amount-label col-xs-12' style={{ padding: "0px", marginBottom: "10px" }}>Category</label>
                      {this.buttonConfigurations.map((buttonConfig) => (
                        <CommonButton
                          key={buttonConfig.key}
                          id={buttonConfig.key}
                          label={buttonConfig.label}
                          default={this.state.inload.category === buttonConfig.key}
                          variant={this.state.inload.category === buttonConfig.key ? 'contained' : 'outlined'}
                          color={this.state.inload.category === buttonConfig.key ? 'primary' : ''}
                          onClick={this.handleInloadCategorySelection}
                          style={{ borderRadius: '25px', marginRight: '10px', textTransform: 'none' }}
                          disabled={!this.state.inload.checkbox}
                        />
                      ))}
                    </div>
                    <SubCategorySection
                      category="key_info_missing"
                      subCategories={this.keyInfoCategory}
                    />

                    <SubCategorySection
                      category="docket_issue"
                      subCategories={this.docketIssueCategory}
                    />

                    <SubCategorySection
                      category="value_missing_in_ac"
                      subCategories={this.valueMissingCategory}
                    />

                    <SubCategorySection
                      category="tonnage_exhausted"
                      subCategories={this.tonnageExhaustedCategory}
                    />
                    <div className='col-xs-6' style={{ display: 'inline-block', marginTop: '16px' }}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id='outload'
                            color='primary'
                            checked={Boolean(this.state.outload.checkbox)}
                            onChange={this.handleCheckboxClick}
                          />
                        }
                        style={{ float: "left" }}
                        label='Add Clarification For Outload'
                      />
                    </div>
                    <div className="row form-wrap" style={{ paddingLeft: '30px' }}>
                      <label className='invoiced-amount-label col-xs-12' style={{ padding: "0px", marginBottom: "10px" }}>Category</label>
                      {this.buttonConfigurations.map((buttonConfig) => (
                        <CommonButton
                          key={buttonConfig.key}
                          id={buttonConfig.key}
                          label={buttonConfig.label}
                          default={this.state.outload.category === buttonConfig.key}
                          variant={this.state.outload.category === buttonConfig.key ? 'contained' : 'outlined'}
                          color={this.state.outload.category === buttonConfig.key ? 'primary' : ''}
                          onClick={this.handleOutloadCategorySelection}
                          style={{ borderRadius: '25px', marginRight: '10px', textTransform: 'none' }}
                          disabled={!this.state.outload.checkbox}
                        />
                      ))}
                    </div>
                    <OutloadSubCategorySection
                      category="key_info_missing"
                      subCategories={this.keyInfoCategory}
                    />

                    <OutloadSubCategorySection
                      category="docket_issue"
                      subCategories={this.docketIssueCategory}
                    />

                    <OutloadSubCategorySection
                      category="value_missing_in_ac"
                      subCategories={this.valueMissingCategory}
                    />

                    <OutloadSubCategorySection
                      category="tonnage_exhausted"
                      subCategories={this.tonnageExhaustedCategory}
                    />
                    <div className='col-xs-12 cardForm-action'>
                      <CommonTextField
                        id='comment'
                        label='Comment'
                        value={this.state.comment}
                        onChange={this.handleFieldChange}
                        multiline
                        rows={3}
                        variant='outlined'
                      />
                    </div>
                    <div className='col-xs-12 cardForm-action padding-reset'>
                      <CommonButton
                        label='Cancel'
                        default={true}
                        variant='outlined'
                        onClick={this.props.onClose}
                      />
                      {
                        <CommonButton
                          type='submit'
                          label='Save'
                          primary
                          variant="contained"
                          disabled={isEmpty(this.state.inload.subCategory) && isEmpty(this.state.outload.subCategory)}
                        />
                      }
                    </div>
                  </div>
                </div>
              </form>
            }
          </SideDrawer>
        </React.Fragment>
      </div>
    );
  }
}

export default connect()(ClarificationDrawer);

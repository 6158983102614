import React, { useState, useRef } from 'react';
import Chip from '@mui/material/Chip';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import InvoiceIcon from './icons/Invoice';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import { BLACK } from '../../common/constants';

const InvoiceChipMenu = ({
  menuItems,
  menuAnchorOrigin = { vertical: 'bottom', horizontal: 'right' },
  menuTransformOrigin = { vertical: 'top', horizontal: 'right' },
  showAllocationInvoicing,
  invoicingHrefs
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const anchorRef = useRef(null);

  const toggleMenu = () => {
    setMenuOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    setMenuOpen(false);
  };

  return (
    <React.Fragment>
      <Chip
        clickable
        style={{ padding: '16px 5px', width: '135px', fontSize: "16px", margin: '1px' }}
        color='secondary'
        size='small'
        variant='outlined'
        label="Invoiced"
        icon={<InvoiceIcon fontSize='inherit' fill={BLACK} width="20" height="20" noStyle style={{ marginRight: "-4px" }} />}
        deleteIcon={<DownIcon fontSize='inherit' style={{ fontSize: '18px' }} />}
        onDelete={toggleMenu}
        onClick={toggleMenu}
        ref={anchorRef}
      />
      <Menu
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={menuAnchorOrigin}
        transformOrigin={menuTransformOrigin}
      >
        {Object.entries(menuItems).map(([key, label]) => {
          if (!showAllocationInvoicing && (['saleContract', 'purchaseContract'].includes(key))) {
            return null;
          }
          const items = invoicingHrefs[key] || [];
          return (
            <MenuItem key={key} style={{ cursor: 'auto' }}>
              {label}: &nbsp;
              {items.length > 0
                ? items.map((item, index) => (
                  <span key={index}>
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                      {item.identifier}
                    </a>
                    {index < items.length - 1 && ', '}
                  </span>
                ))
                : '-'}
            </MenuItem>
          );
        })}
      </Menu>
    </React.Fragment>
  );
};

export default InvoiceChipMenu;

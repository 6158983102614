import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab } from '@mui/material';
import Invoices from './Invoices';
import WarehouseDashboard from './WarehouseDashboard';
import InvoicePayable from './InvoicePayable';
import PaymentRuns from './PaymentRuns';
import InvoiceReceivable from './InvoiceReceivable';
import FreightInvoiceReceivable from './FreightInvoiceReceivable';

class InvoiceListingHome extends React.Component {
  constructor(props) {
    super(props);
    this.ACTIVE_URL = '/invoices';
    this.PAYABLE_URL = '/invoices/payable';
    this.RECEIVABLE_URL = '/invoices/receivable';
    this.PAYMENT_RUN_URL = '/invoices/payment-runs';
    this.WAREHOUSE_DASHBOARD_URL = '/invoices/warehouse/dashboard';
    this.FREIGHT_RECEIVABLE_URL = '/invoices/freight-receivable';
    this.state = {
      value: '#' + this.props.location.pathname,
    }
    this.updateUrlValue = this.updateUrlValue.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  updateUrlValue() {
    const currentUrl = `#${this.props.location.pathname.replace(/\/$/, '')}`;
    if (this.state.value !== currentUrl)
      this.setState({ value: currentUrl });
  }

  componentDidMount() {
    this.updateUrlValue();
  }

  componentDidUpdate() {
    this.updateUrlValue();
  }

  onTabChange = (event, value) => {
    window.location.hash = value
    this.setState({value});
  }

  render() {
    const { value } = this.state;
    const isWarehouseDashboardTab = value === warehouseDashboardURL || this.props.location.pathname.includes(this.WAREHOUSE_DASHBOARD_URL);
    const activeInvoicesURL = '#' + this.ACTIVE_URL;
    const payablesURL = '#' + this.PAYABLE_URL;
    const receivableURL = '#' + this.RECEIVABLE_URL;
    const paymentRunsURL = '#' + this.PAYMENT_RUN_URL;
    const warehouseDashboardURL = '#' + this.WAREHOUSE_DASHBOARD_URL;
    const freightReceivableURL = '#' + this.FREIGHT_RECEIVABLE_URL;
    return (
      <div className="subTab">
        <Tabs className="subTab-header" indicatorColor="primary" value={value} onChange={this.onTabChange} >
          <Tab label="Active" value={activeInvoicesURL} href={activeInvoicesURL} className={value !== activeInvoicesURL ? 'unselected-subtab' : ''} />
          <Tab label="Payable" value={payablesURL} href={payablesURL} className={value !== payablesURL ? 'unselected-subtab' : ''} />
          <Tab label="Payment Runs" value={paymentRunsURL} href={paymentRunsURL} className={value !== paymentRunsURL ? 'unselected-subtab' : ''} />
          <Tab label="Warehouse Dashboard" value={warehouseDashboardURL} href={warehouseDashboardURL} className={value !== warehouseDashboardURL ? 'unselected-subtab' : ''} />
          <Tab label="Receivable" value={receivableURL} href={receivableURL} className={value !== receivableURL ? 'unselected-subtab' : ''} />
          <Tab label="Freight Receivable" value={freightReceivableURL} href={freightReceivableURL} className={value !== freightReceivableURL ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          value === activeInvoicesURL &&
            <Invoices {...this.props} />
        }
        {
          value === payablesURL &&
          <InvoicePayable {...this.props} />
        }
        {
          value === paymentRunsURL &&
          <PaymentRuns {...this.props} />
        }
        {
          isWarehouseDashboardTab &&
          <WarehouseDashboard {...this.props} />
        }
        {
          value === receivableURL  &&
          <InvoiceReceivable {...this.props} />
        }
        {
          value === freightReceivableURL &&
          <FreightInvoiceReceivable {...this.props} />
        }
      </div>
    )
  }
}

export default connect()(InvoiceListingHome);

import findIndex from 'lodash/findIndex';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import {
  RECEIVE_COMPANY_SITES,
  RECEIVE_FREIGHT_SLOTS,
  RECEIVE_MULTIPLE_FREIGHT_SLOTS,
  RECEIVE_FREIGHT_SLOT,
  DELETE_FREIGHT_SLOT,
  DELETE_MULTIPLE_FREIGHT_SLOTS,
  RECEIVE_FREIGHT_SLOT_UPDATED,
  ADD_COMPANY_SITE,
  EDIT_COMPANY_SITE,
  CANCEL_EDIT_COMPANY_SITE,
  CLICK_ADD_COMPANY_SITE_BUTTON,
  CLICK_EDIT_COMPANY_SITE_ICON,
  ADD_COMPANY_SITE_ERRORS,
  EDIT_COMPANY_SITE_ERRORS,
  RECEIVE_COMPANY_BHC_SITES,
  RECEIVE_SM_SETTINGS,
  RECEIVE_SLOT_COMMENT,
  RECEIVE_SLOT_COMMENTS,
  RECEIVE_SLOT_COMMENT_UPDATED,
  RECEIVE_COMPANY_SITE_LOADS
} from '../../actions/companies/company-sites';
import get from 'lodash/get';

const initialState = {
  items: [],
  slots: [],
  bhcSites: [],
  selectedCompanySiteId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
  settings: null,
  comments: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case RECEIVE_COMPANY_SITES:
    return {
      ...state,
      items: action.items,
      isCreateFormDisplayed: false,
      isUpdateFormDisplayed: false,
      serverErrors: [],
    };
  case RECEIVE_COMPANY_SITE_LOADS:
    return {
      ...state,
      siteLoads: action.paginatedSites.results,
      paginationData: get(action, 'paginatedSites', []),
      selectedSiteId: action.selectedLocation,
      serverErrors: [],
    }
  case RECEIVE_SLOT_COMMENTS:
    return {
      ...state,
      comments: action.comments
    };
  case RECEIVE_SLOT_COMMENT:
    return {
      ...state,
      comments: [...state.comments, action.comment]
    };
  case RECEIVE_SLOT_COMMENT_UPDATED: {
    const index = findIndex(state.comments, comment => { return comment.id.toString() === action.comment.id.toString(); });
    return {
      ...state,
      comments: [
        ...state.comments.slice(0, index),
        action.comment,
        ...state.comments.slice(index + 1),
      ]
    };
  }
  case RECEIVE_SM_SETTINGS:
    return {
      ...state,
      settings: action.settings,
    };
  case RECEIVE_FREIGHT_SLOTS:
    return {
      ...state,
      slots: action.items,
    };
  case RECEIVE_MULTIPLE_FREIGHT_SLOTS:
    return {
      ...state,
      slots: [...action.items, ...state.slots],
    };
  case RECEIVE_FREIGHT_SLOT:
    return {
      ...state,
      slots: [action.item, ...state.slots],
    };
  case DELETE_FREIGHT_SLOT: {
    const deletedIndex = findIndex(state.slots, {id: action.slotId});
    let newSlots = state.slots;
    if(deletedIndex > -1)
      newSlots = [
        ...state.slots.slice(0, deletedIndex),
        ...state.slots.slice(deletedIndex + 1),
      ];
    return {
      ...state,
      slots: newSlots
    };
  }
  case DELETE_MULTIPLE_FREIGHT_SLOTS: {
    const updatedSlots = filter(state.slots, (slot) => {return !includes(action.slotIds, slot.id);});
    return {
      ...state,
      slots: updatedSlots
    };
  }
  case RECEIVE_FREIGHT_SLOT_UPDATED: {
    const index = findIndex(state.slots, slot => { return slot.id.toString() === action.item.id.toString(); });
    return {
      ...state,
      slots: [
        ...state.slots.slice(0, index),
        action.item,
        ...state.slots.slice(index + 1),
      ],
    };
  }
  case RECEIVE_COMPANY_BHC_SITES:
    return {
      ...state,
      bhcSites: action.items,
      isCreateFormDisplayed: false,
      isUpdateFormDisplayed: false,
      serverErrors: [],
    };
  case ADD_COMPANY_SITE:
    return {
      ...state,
      items: [action.item, ...state.items],
      selectedCompanySiteId: undefined,
      isCreateFormDisplayed: false,
      isUpdateFormDisplayed: false,
      serverErrors: [],
    };
  case ADD_COMPANY_SITE_ERRORS:
    return {
      ...state,
      selectedCompanySiteId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: true,
      serverErrors: action.errors,
    };
  case EDIT_COMPANY_SITE: {
    const index = findIndex(state.items, { id: action.item.id });
    return {
      items: [
        ...state.items.slice(0, index),
        action.item,
        ...state.items.slice(index + 1),
      ],
      selectedCompanyId: undefined,
      selectedCompanySiteId: undefined,
      isUpdateFormDisplayed: false,
    };
  }
  case EDIT_COMPANY_SITE_ERRORS:
    return {
      ...state,
      serverErrors: action.errors,
    };
  case CANCEL_EDIT_COMPANY_SITE:
    return {
      ...state,
      selectedCompanyId: undefined,
      selectedCompanySiteId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
    };
  case CLICK_ADD_COMPANY_SITE_BUTTON:
    return {
      ...state,
      selectedCompanyId: undefined,
      selectedCompanySiteId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: true,
    };
  case CLICK_EDIT_COMPANY_SITE_ICON:
    return {
      ...state,
      selectedCompanyId: action.index.companyId,
      selectedCompanySiteId: action.index.id,
      isUpdateFormDisplayed: true,
      isCreateFormDisplayed: false,
    };
  default:
    return state;
  }
};

export default reducer;

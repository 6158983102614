import React from 'react';


import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
        style={{ fill: '#112c42', height: '20px', width: '20px' }}
      />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class ContractDetailsConditionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  render() {
    const { contract } = this.props;
    const { expanded } = this.state;
    return (
      (contract.generalConditions || contract.specialConditions) &&
      <Paper className="contract-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          Conditions
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && contract.generalConditions && (
          <div>
            <h4 className="section-title">General Conditions</h4>
            <div className={"condition-section"}>{contract.generalConditions}</div>
          </div>
        )}
        {expanded && contract.specialConditions && (
          <div>
            <h4 className="section-title">Special Conditions</h4>
            <div className={"condition-section"}>{contract.specialConditions}</div>
          </div>
        )}
      </Paper>
    );
  }
}

export default ContractDetailsConditionSection;

import React, { Component } from 'react';

import Tooltip from '@mui/material/Tooltip';

class CashBoard extends Component {
  render() {

    return <Tooltip title="Cash Boards" placement="right">
      <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>Cash Boards</title>
        <g id="Components" stroke="none" strokeWidth="1" fill='none' fillRule="evenodd">
          <g id="Icons" transform="translate(-1577.000000, -768.000000)" fillRule="nonzero">
            <g id="Cashboard-icon-Copy-2" transform="translate(1577.000000, 768.000000)">
              <rect id="Rectangle" x="0" y="0" width="24" height="24"></rect>
              <path d="M8.23392188,13.8237656 L8.23392188,14.7594375 C8.23392188,16.4882344 9.44746875,17.9909062 11.0409375,18.424125 L11.0409375,20.4210469 L12.9122813,20.4210469 L12.9122813,18.4275469 C13.5498281,18.2584687 14.1377344,17.9173594 14.621625,17.4259687 C15.3294844,16.7070937 15.7192969,15.7657031 15.7192969,14.7752812 C15.7192969,13.8302812 15.3229688,13.0014844 14.5412344,12.3119062 C13.9362188,11.7781406 13.1689219,11.37825 12.4268906,10.9914375 L12.4090781,10.9821094 C11.107125,10.3034531 10.5262969,9.82757812 10.5262969,8.80134375 C10.5262969,8.03704687 11.1769219,7.41520312 11.9766094,7.41520312 C12.7762969,7.41520312 13.4269219,8.04482812 13.4269219,8.81873437 L13.4269219,9.75440625 L15.2982656,9.75440625 L15.2982656,8.81873437 C15.2982656,7.33326562 14.2895625,6.07645312 12.9122813,5.67703125 L12.9122813,3.57895312 L11.0409375,3.57895312 L11.0409375,5.676375 C9.66365625,6.07364062 8.65495313,7.32379688 8.65495313,8.80139062 C8.65495313,11.1356719 10.4635313,12.078375 11.5441406,12.6415781 L11.5619531,12.6509063 C12.9792656,13.3896563 13.8479531,13.8875156 13.8479531,14.7753281 C13.8479531,15.8069063 12.9909844,16.6784063 11.9766094,16.6784063 C10.9797188,16.6784063 10.1052656,15.7816875 10.1052656,14.7594844 L10.1052656,13.8238125 L8.23392188,13.8238125 L8.23392188,13.8237656 Z" id="Path" fill={this.props.fill}></path>
              <path d="M12.0233906,22.1052656 C8.38120313,22.1052656 5.1166875,20.2033125 3.320625,17.1462188 L6.54970313,17.1462188 L6.54970313,15.274875 L0,15.274875 L0,21.8245781 L1.87134375,21.8245781 L1.87134375,18.3597187 C2.7241875,19.7212031 3.846375,20.9014219 5.18685937,21.8348437 C7.19901563,23.2359844 9.56310937,23.9766094 12.0233906,23.9766094 C15.2206406,23.9766094 18.2205937,22.7301094 20.4706875,20.4667969 C22.716375,18.2077969 23.9532188,15.2009063 23.9532188,12 L22.081875,12 C22.081875,17.5720781 17.5696406,22.1052656 12.0233906,22.1052656 Z" id="Path" fill={this.props.fill}></path>
              <path d="M22.1286562,2.17542187 L22.1286562,5.64028125 C21.2757656,4.27879687 20.153625,3.098625 18.8131406,2.16520312 C16.8009844,0.764015625 14.4368906,0.023390625 11.9766094,0.023390625 C8.77753125,0.023390625 5.76998437,1.2691875 3.50789062,3.53123437 C1.24579688,5.793375 0,8.80092187 0,12 L1.87134375,12 C1.87134375,6.42792188 6.40453125,1.89473437 11.9766094,1.89473437 C15.6188437,1.89473437 18.8833125,3.79664062 20.679375,6.85378125 L17.4502969,6.85378125 L17.4502969,8.725125 L24,8.725125 L24,2.17542187 L22.1286562,2.17542187 Z" id="Path" fill={this.props.fill}></path>
            </g>
          </g>
        </g>
      </svg>
    </Tooltip>;
  }
}

export default CashBoard;

import React from 'react';
import { IconButton } from '@mui/material'

const XeroIcon = ({connected, ...rest}) => {
  const color = connected ? 'success' : 'error'

  return (
    <IconButton color={color} {...rest}>
      <svg width="37px" height="37px" viewBox="0 0 37 37" version="1.1">
        <g id="Integrations" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
          <g id="Purchase" transform="translate(-1218.000000, -737.000000)" fillRule="nonzero">
            <g id="Xero-Copy-3" transform="translate(1236.666667, 755.581334) scale(-1, 1) rotate(-180.000000) translate(-1236.666667, -755.581334) translate(1218.666667, 737.581334)">
              <path d="M18.0000306,5.22151278e-15 C27.9411906,5.22151278e-15 36,8.05880942 36,18.0000306 C36,27.9411293 27.9411906,36 18.0000306,36 C8.05887066,36 1.38703599e-15,27.9411293 1.38703599e-15,18.0000306 C1.38703599e-15,8.05880942 8.05887066,5.22151278e-15 18.0000306,5.22151278e-15" id="path20" fill="#6AAE20"></path>
              <path d="M8.61632518,18.0520221 L11.6881733,21.1314639 C11.790013,21.2352632 11.8468424,21.3726825 11.8468424,21.5184303 C11.8468424,21.8221735 11.5999286,22.0688423 11.2961853,22.0688423 C11.148233,22.0688423 11.0095277,22.0108494 10.9049323,21.904968 C10.9046261,21.9043556 7.83583986,18.8373453 7.83583986,18.8373453 L4.75303003,21.9095609 C4.64886326,22.0122579 4.51083157,22.0688423 4.36447143,22.0688423 C4.06109566,22.0688423 3.81412059,21.8222959 3.81412059,21.5188589 C3.81412059,21.3708453 3.87321579,21.2319563 3.97928096,21.1272996 L7.05070042,18.0563088 L3.98087316,14.9807252 C3.87297084,14.8744763 3.81412059,14.7352811 3.81412059,14.5865939 C3.81412059,14.2829119 4.06109566,14.0365492 4.36447143,14.0365492 C4.51107652,14.0365492 4.64923069,14.0934398 4.75303003,14.1973004 L7.83106325,17.2711694 L10.8973999,14.2085071 C11.0061596,14.0960731 11.1464571,14.0361818 11.2961853,14.0361818 C11.5996224,14.0361818 11.8465362,14.2829119 11.8465362,14.5865939 C11.8465362,14.7331377 11.7897681,14.870802 11.6864586,14.9746013 L8.61632518,18.0520221 Z" id="path22" fill="#FFFFFF"></path>
              <path d="M26.8779669,18.0529407 C26.8779669,17.5014263 27.3264168,17.0527927 27.8786048,17.0527927 C28.4295068,17.0527927 28.8781404,17.5014263 28.8781404,18.0529407 C28.8781404,18.6045776 28.4295068,19.05315 27.8786048,19.05315 C27.3264168,19.05315 26.8779669,18.6045776 26.8779669,18.0529407" id="path24" fill="#FFFFFF"></path>
              <path d="M24.9810416,18.0524508 C24.9810416,19.6495522 26.2802786,20.9490342 27.8775637,20.9490342 C29.4740527,20.9490342 30.773596,19.6495522 30.773596,18.0524508 C30.773596,16.4557169 29.4740527,15.156786 27.8775637,15.156786 C26.2802786,15.156786 24.9810416,16.4557169 24.9810416,18.0524508 M23.8416371,18.0524508 C23.8416371,15.8274094 25.6521548,14.0171366 27.8775637,14.0171366 C30.1029114,14.0171366 31.9143477,15.8274094 31.9143477,18.0524508 C31.9143477,20.2777985 30.1029114,22.0885611 27.8775637,22.0885611 C25.6521548,22.0885611 23.8416371,20.2777985 23.8416371,18.0524508" id="path26" fill="#FFFFFF"></path>
              <path d="M23.5551632,22.0195453 L23.3858386,22.0200352 C22.8774974,22.0200352 22.3872216,21.8597127 21.9777194,21.5441504 C21.9237682,21.7910643 21.7030645,21.976862 21.4401674,21.976862 C21.1375877,21.976862 20.8951443,21.7344186 20.8944094,21.4312266 C20.8944094,21.4301243 20.8962466,14.6337475 20.8962466,14.6337475 C20.8970427,14.3311066 21.1438953,14.0851114 21.4466587,14.0851114 C21.7493608,14.0851114 21.9961522,14.3311066 21.9969483,14.6344212 C21.9969483,14.6357684 21.9971932,18.8140134 21.9971932,18.8140134 C21.9971932,20.2070679 22.1245694,20.7696665 23.3178638,20.9187824 C23.4281544,20.9324998 23.5481207,20.9303565 23.5486107,20.9303565 C23.8751346,20.9415631 24.1071675,21.1659412 24.1071675,21.4691945 C24.1071675,21.7726315 23.8594575,22.0195453 23.5551632,22.0195453" id="path28" fill="#FFFFFF"></path>
              <path d="M12.9869817,18.7168891 C12.9869817,18.7319538 12.9881452,18.7476308 12.9888801,18.7631242 C13.3084229,20.0262918 14.4524202,20.961037 15.8148555,20.961037 C17.1937639,20.961037 18.348233,20.0033273 18.6514251,18.7168891 L12.9869817,18.7168891 Z M19.7785206,18.8208109 C19.5413437,19.9437422 18.9265699,20.8661172 17.9904162,21.4584777 C16.6219795,22.327269 14.8149524,22.2791968 13.4931796,21.3392463 C12.4150137,20.5722334 11.7926463,19.3175168 11.7926463,18.0250159 C11.7926463,17.7009415 11.8317165,17.3738052 11.9130413,17.0518129 C12.3201551,15.4503024 13.6969202,14.2379628 15.3383583,14.0370391 C15.8254497,13.9780664 16.2995586,14.0062974 16.790508,14.1334286 C17.2123804,14.2360644 17.6205966,14.4071649 17.9967237,14.6481998 C18.3871195,14.8991554 18.713276,15.2300885 19.0291444,15.6262407 C19.0355133,15.6335281 19.0419433,15.6399582 19.0483121,15.647613 C19.2675461,15.9195121 19.2268837,16.3061723 18.9859713,16.4907453 C18.7827818,16.646475 18.441683,16.7096119 18.1732745,16.3658799 C18.1155878,16.2836978 18.051226,16.1991274 17.980373,16.1145569 C17.7669567,15.8787273 17.5021612,15.6502462 17.1848843,15.4730219 C16.7811385,15.2573397 16.3210533,15.1340665 15.831941,15.1313108 C14.2310428,15.1491312 13.3743768,16.2666122 13.0696537,17.0642443 C13.0164374,17.2131765 12.9754076,17.3676814 12.9468092,17.5267791 C12.9430737,17.556541 12.9403179,17.5852619 12.9392156,17.611717 C13.27021,17.611717 18.6841265,17.6126356 18.6841265,17.6126356 C19.4717154,17.62917 19.8956087,18.1850935 19.7785206,18.8208109" id="path30" fill="#FFFFFF"></path>
            </g>
          </g>
        </g>
      </svg>
    </IconButton>
  )
}

export default XeroIcon

import { get, find, isArray, isEmpty, includes } from 'lodash';
import APIService from '../../services/APIService';
import { 
  CONTRACTS_TABLE_OPTIONS_ITEMS,
  DIRECT_TO_BUYER_ALLOCATION,
  STOCK_ALLOCATION,
  THROUGH_WAREHOUSE_ALLOCATION,
  DUPLICATE_OPTION_KEYS
} from '../../common/constants';
import {
  isSystemCompany, getIndexOfSpliceItem, regeneratePDF, currentUserCompany
} from '../../common/utils';

export const getActionMenuOptions = (contract, subItems, clickedOption, listing) => {
  let contractActionOptions = [...CONTRACTS_TABLE_OPTIONS_ITEMS];

  if(!window.VOID_AND_DUPLICATE_TOGGLE)
    contractActionOptions = contractActionOptions.filter(
      option => !DUPLICATE_OPTION_KEYS.includes(option.key)
    );

  const { editOrAmendLabel, isAmendRequestPending, canMarkPaid, canViewVendorDec } = contract;
  const vendorDecSpliceItem = {key: 'vendor_dec'};
  if (!canViewVendorDec){
    const vendorDecIndex = getIndexOfSpliceItem(contractActionOptions, vendorDecSpliceItem);
    contractActionOptions.splice(vendorDecIndex, 1);
  }

  if (editOrAmendLabel) {
    const amendIndex = getIndexOfSpliceItem(contractActionOptions, {key: 'amend'});
    contractActionOptions[amendIndex].text = editOrAmendLabel;
  }

  if (isSystemCompany()){
    contractActionOptions.push({key: 'invoiced_outside', text: 'Invoiced Outside'});
  }

  if (isAmendRequestPending)
    contractActionOptions.push({key: 'review_amendment', text: 'Review Amendment'});

  if (canMarkPaid)
    contractActionOptions.push({key: 'mark_as_paid', text: 'Mark as Paid'});

  if(isSystemCompany())
    contractActionOptions.push({key: 'regenerate_pdf', text: 'Regenerate PDF'});

  contractActionOptions.push({key: 'show_hierarchy', text: 'Show Hierarchy'});
  if (!listing && [DIRECT_TO_BUYER_ALLOCATION, THROUGH_WAREHOUSE_ALLOCATION].some(allocationType => includes(currentUserCompany().contractAllocations, allocationType)))
    contractActionOptions.push({key: 'contract_allocation', text: 'Contract Allocation'});
  if(!listing && includes(currentUserCompany().contractAllocations, STOCK_ALLOCATION) && currentUserCompany().id === get(contract, 'seller.companyId'))
    contractActionOptions.push({key: 'stock_allocation', text: 'Stock Allocation'});

  const option = find(contractActionOptions, {key: get(clickedOption, 'key')});
  if(option && isArray(subItems) && !isEmpty(subItems))
    option.subItems = subItems;

  return contractActionOptions;
};

export const regenerateContractPDF = contract => {
  const contractId = get(contract, 'id');
  if(contractId)
    return regeneratePDF(APIService.contracts(contractId).appendToUrl('regenerate-pdfs/'));
};

import {
  GET_ROLES_SUCCESS,
} from '../../actions/api/employees';

const initialState = {
  roles: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case GET_ROLES_SUCCESS:
    return {
      ...state,
      roles: action.roles,
    };
  default:
    return {
      ...state,
    };
  }
};

export default reducer;

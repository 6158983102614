import '@babel/polyfill';
import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import Paper from '@mui/material/Paper';
import { Autocomplete, TextField, Button, IconButton } from '@mui/material';
import {get, find, compact, set, size, keys, values, every, map, isEmpty, filter, pickBy } from "lodash";
import APIService from '../../../services/APIService'
import {isLoading, forceStopLoader} from '../../../actions/main';
import { METER_CUBE_UNIT } from '../../../common/constants'
import { toFloatFromString , toDateFormat, getCountryLabel, currentUser } from '../../../common/utils';
import InvoiceTotalSection from './InvoiceTotalSection';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import AdjustedPrices from '../AdjustedPrices';
import QuantityAdjustments from '../QuantityAdjustments';
import SideDrawer from '../../common/SideDrawer';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
              style={{ fill: '#112c42', height: '20px', width: '20px' }}
            />);
  }
  return (<KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} /> );
};

class InvoiceItemsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: currentUser(),
      expanded: true,
      xero: {},
      isSettingUpXeroMappings: false,
      selectedItem: undefined,
      showAdjustmentsSideBar: false,
      showQuantityAdjustments: false
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({ expanded: !prevState.expanded}));
  };

  getTitle() {
    let header = null;
    const type = get(this.props, 'invoiceDetails.type');
    switch (type) {
    case 'Commodity Contract' :
      header = 'Commodity Contract : ';
      break;
    case 'Brokerage':
      header = 'Items To Be Invoiced';
      break;
    case 'Freight' :
      header = 'Freight Order : ';
      break;
    case 'WarehouseFee' :
      header = 'Invoiced Items:';
      break;
    }
    const number = this.props.invoiceDetails.raisedForNo || this.props.invoiceDetails.identifier;
    if(type !== 'Brokerage' && type !== 'WarehouseFee')
      header += number;
    return header;
  }


  getInvoiceItemsSection = () => {
    const {
      freightContracts, titleTransfers, carry, grainLevies, eprs, others, contractItems, warehouseTransfers,
      subscriptionItems, isFreightInvoice, warehouseInloads, warehouseOutloads, warehouseStorages, isWarehouseInvoice, loads,
      warehouseStockSwaps, warehouseRegradeReseasons, warehouseThroughputInloads, warehouseThroughputOutloads, blendedGradeLoads,
      blendedGradeMovements, chemicalApplicationItems,
    } = this.props.invoiceDetails;
    if (isFreightInvoice) {
      return (
        <div className="action-centre-group invoice-section-group">
          <Accordion style={{margin: '0px', borderTop: "1px solid rgb(224, 224, 224)"}} expanded={true}>
            {!isEmpty(freightContracts) && this.getFreightInvoiceItemDetails(freightContracts, 'Movements')}
            {!isEmpty(others) && this.getFreightInvoiceItemDetails(others, 'Others')}
          </Accordion>
          <InvoiceTotalSection {...this.props} invoiceDetails={this.props.invoiceDetails} expansionFalse={false} showHeader={false}/>
        </div>
      );
    } else if (isWarehouseInvoice) {
      return (
        <div className="action-centre-group invoice-section-group">
          <Accordion style={{ margin: '0px', borderTop: "1px solid rgb(224, 224, 224)" }} expanded={true}>
            {!isEmpty(others) && this.getWarehouseInvoiceItemDetails(others, 'Others')}
            {!isEmpty(warehouseInloads) && this.getWarehouseInvoiceItemDetails(warehouseInloads, 'Inload Fees')}
            {!isEmpty(warehouseOutloads) && this.getWarehouseInvoiceItemDetails(warehouseOutloads, 'Outload Fees')}
            {!isEmpty(warehouseThroughputInloads) && this.getWarehouseInvoiceItemDetails(warehouseThroughputInloads, 'Throughput Inload Fees')}
            {!isEmpty(warehouseThroughputOutloads) && this.getWarehouseInvoiceItemDetails(warehouseThroughputOutloads, 'Throughput Outload Fees')}
            {!isEmpty(warehouseTransfers) && this.getWarehouseInvoiceItemDetails(warehouseTransfers, 'Transfer Fees')}
            {!isEmpty(warehouseStorages) && this.getWarehouseInvoiceStorageItemDetails(warehouseStorages, 'Storage Fees')}
            {!isEmpty(warehouseStockSwaps) && this.getWarehouseInvoiceItemDetails(warehouseStockSwaps, 'Stock Swaps')}
            {!isEmpty(warehouseRegradeReseasons) && this.getWarehouseInvoiceItemDetails(warehouseRegradeReseasons, 'Regrade Reseasons')}
          </Accordion>
          <InvoiceTotalSection {...this.props} invoiceDetails={this.props.invoiceDetails} expansionFalse={false} />
        </div >
      );
    }
    else{
      return (
        <div className="action-centre-group invoice-section-group">
          <Accordion style={{margin: '0px', borderTop: "1px solid rgb(224, 224, 224)"}} expanded={true}>
            {!isEmpty(freightContracts) && this.getInvoiceItemDetails(freightContracts, 'Movements')}
            {!isEmpty(blendedGradeLoads) && this.getInvoiceItemDetails(blendedGradeLoads, 'Movements')}
            {!isEmpty(blendedGradeMovements) && this.getInvoiceItemDetails(blendedGradeMovements, 'Blending Fee')}
            {!isEmpty(chemicalApplicationItems) && this.getInvoiceItemDetails(chemicalApplicationItems, 'Applications')}
            {!isEmpty(titleTransfers) && this.getInvoiceItemDetails(titleTransfers, 'Title Transfers')}
            {!isEmpty(loads) && this.getInvoiceItemDetails(loads, 'Canola Loads')}
            {!isEmpty(carry) && this.getInvoiceItemDetails(carry, 'Carry')}
            {!isEmpty(grainLevies) && this.getInvoiceItemDetails(grainLevies, 'Grain Levies')}
            {!isEmpty(eprs) && this.getInvoiceItemDetails(eprs, 'EPRs')}
            {!isEmpty(contractItems) && this.getInvoiceItemDetails(contractItems, 'Contracts')}
            {!isEmpty(subscriptionItems) && this.getInvoiceItemDetails(subscriptionItems, 'Periodic Brokerage Fees')}
            {!isEmpty(others) && this.getInvoiceItemDetails(others, 'Others')}
          </Accordion>
          <InvoiceTotalSection {...this.props} invoiceDetails={this.props.invoiceDetails} expansionFalse={false}/>
        </div>
      );
    }
  };

  isOthers(title) {
    return title === 'Others';
  }

  setXeroField = (item, value, xeroField, isChemicalApplicationItem) => {
    const newState = {...this.state}
    const xeroStateId = isChemicalApplicationItem ? item.itemId : item.invoiceItemId
    set(newState.xero, `${xeroStateId}.${xeroField}`, value)
    if (isChemicalApplicationItem)
      set(newState.xero, `${xeroStateId}.isChemicalApplicationItem`, true);
      set(newState.xero, `${xeroStateId}.chemicalAppliedOnLoadId`, item.chemicalAppliedOnLoadId);
    this.setState(newState)
  }

  getItemTypeForMapping = name => {
    if(name.toLowerCase().includes('movement'))
      return 'movements'
    if(name.toLowerCase().includes('title'))
      return 'title_transfers'
    if(name.toLowerCase().includes('levies'))
      return 'levy'
    if(name.toLowerCase().includes('epr'))
      return 'epr'
    if(name.toLowerCase().includes('carry'))
      return 'carry'
    if(name.toLowerCase().includes('inloads'))
      return 'inload_fees'
    if(name.toLowerCase().includes('outloads'))
      return 'outload_fees'
    if(name.toLowerCase().includes('transfers'))
      return 'transfer_fees'
    if(name.toLowerCase().includes('storages'))
      return 'storage_fees'
    if(name.toLowerCase().includes('stock_swaps'))
      return 'stock_swap'
    if(name.toLowerCase().includes('regrade_reseasons'))
      return 'regrade_reseason'
    if(name.toLowerCase().includes('custom'))
      return 'custom'
  }

  getMappingsForItem = name => {
    const itemType = this.getItemTypeForMapping(name)
    return filter(this.props.xeroMappings, mapping => !mapping.itemType || mapping.itemType === itemType)
  }

  getBestMatchedMappingForItem = (itemName, item=null) => {
    const itemType = this.getItemTypeForMapping(itemName)
    const mappings = this.getMappingsForItem(itemName)
    let { commodityId, gradeId, season } = this.props.invoiceDetails;
    if (item) {
      commodityId = item.commodityId;
      gradeId = item.gradeId;
      season = item.season;
    }
    let bestMatch = find(mappings, {commodityId: commodityId, gradeId: gradeId, season: season, itemType: itemType})
    if(!bestMatch) {
      [
        filter(mappings, {itemType: itemType}), // same itemType as invoice item
        filter(mappings, mapping => !mapping.itemType) // without any itemType (means all itemTypes)
      ].forEach(_mappings => {
        _mappings.forEach(mapping => {
          if(!bestMatch && mapping.commodityId === commodityId && mapping.gradeId === gradeId && mapping.season === season && !mapping.itemType)
            bestMatch = mapping
          if(!bestMatch && mapping.commodityId === commodityId && mapping.gradeId === gradeId && !mapping.season && (mapping.itemType === itemType || !mapping.itemType))
            bestMatch = mapping
          else if(!bestMatch && mapping.commodityId === commodityId && mapping.season === season && !mapping.gradeId && (mapping.itemType === itemType || !mapping.itemType))
            bestMatch = mapping
          else if(!bestMatch && mapping.commodityId === commodityId && !mapping.season && !mapping.gradeId && (mapping.itemType === itemType || !mapping.itemType))
            bestMatch = mapping
          else if (!bestMatch && mapping.season === season && !mapping.commodityId && !mapping.gradeId && (mapping.itemType === itemType || !mapping.itemType))
            bestMatch = mapping
        })
      })
    }
    return bestMatch
  }

  setUpXeroMapping = () => {
    const {
      freightContracts, titleTransfers, carry, grainLevies, eprs, others, isCommodityContractInvoice,
      warehouseInloads, warehouseOutloads, warehouseStockSwaps, warehouseRegradeReseasons,
      warehouseStorages, warehouseTransfers, isWarehouseInvoice, warehouseThroughputInloads, warehouseThroughputOutloads, isFreightInvoice, loads,
    } = this.props.invoiceDetails;
    if(isCommodityContractInvoice) {
      const newState = {...this.state}
      const _setup = (items, itemName) => {
        if(!isEmpty(items)) {
          const bestMatch = this.getBestMatchedMappingForItem(itemName)
          if(!isEmpty(bestMatch))
            items.forEach(item => {
              set(newState.xero, `${item.invoiceItemId}.accountCode`, bestMatch.xeroAccount)
              set(newState.xero, `${item.invoiceItemId}.itemCode`, bestMatch.xeroItemCode)
            });
        }
      }
      _setup(freightContracts, 'movement')
      _setup(titleTransfers, 'title_transfers')
      _setup(carry, 'carry')
      _setup(grainLevies, 'levies')
      _setup(eprs, 'epr')
      _setup(others, 'custom')
      _setup(loads, 'title_transfers')
      newState.isSettingUpXeroMappings = false
      this.setState(newState)
    }
    if (isFreightInvoice) {
      const newState = {...this.state}
      const _setup = (items, itemName) => {
        if(!isEmpty(items)) {
          const bestMatch = this.getBestMatchedMappingForItem(itemName)
          if(!isEmpty(bestMatch))
            items.forEach(item => {
              set(newState.xero, `${item.invoiceItemId}.accountCode`, bestMatch.xeroAccount)
              set(newState.xero, `${item.invoiceItemId}.itemCode`, bestMatch.xeroItemCode)
            });
        }
      }
      _setup(freightContracts, 'movement')
      _setup(others, 'custom')
      newState.isSettingUpXeroMappings = false
      this.setState(newState)
    }
    if (isWarehouseInvoice) {
      const newState = {...this.state};
      const _setup = (items, itemName) => {
        if (!isEmpty(items)) {
          items.forEach(item => {
            const bestMatch = this.getBestMatchedMappingForItem(itemName, item);
            if (!isEmpty(bestMatch)) {
              set(newState.xero, `${item.invoiceItemId}.accountCode`, bestMatch.xeroAccount);
              set(newState.xero, `${item.invoiceItemId}.itemCode`, bestMatch.xeroItemCode);
            }
          });
        }
      }
      _setup(warehouseInloads, 'inloads')
      _setup(warehouseOutloads, 'outloads')
      _setup(warehouseThroughputInloads, 'throughput_inloads')
      _setup(warehouseThroughputOutloads, 'throughput_outloads')
      _setup(warehouseStockSwaps, 'stock_swaps')
      _setup(warehouseRegradeReseasons, 'regrade_reseasons')
      _setup(warehouseStorages, 'storages')
      _setup(warehouseTransfers, 'transfers')
      newState.isSettingUpXeroMappings = false
      this.setState(newState)
    }
  }

  componentDidUpdate(prevProps) {
    if(!isEmpty(this.props.xeroMappings) && !this.state.isSettingUpXeroMappings && isEmpty(prevProps.xeroMappings)) {
      this.setState({isSettingUpXeroMappings: true}, this.setUpXeroMapping)
    }
  }

  showAdjustments(item) {
    this.setState({selectedItem: item, showAdjustments: true, showQuantityAdjustments: false});
  }

  showQuantityAdjustments = item => this.setState({selectedItem: item, showQuantityAdjustments: true, showAdjustments: false})

  getQuantitySpecAdjustments = item => pickBy(item?.adjustments?.specs, val => val.quantity)

  getInvoiceItemDetails(items, title) {
    const showGSTLine = this.shouldShowGSTNotApplicableTextForOtherItems(items, title);
    const isGrainLevies = title === 'Grain Levies';
    const isPeriodicBrokerageFees = title === 'Periodic Brokerage Fees';
    const isTitleTransfers = title === 'Title Transfers'
    const isChemicalApplications = title === 'Applications';
    const { isStrictQuantityBasedCommodity, currency } = get(this.props, 'invoiceDetails');
    return <div>
             <h3 className="invoice-item-heading">{title}</h3>
             {
               !isEmpty(items) &&
                 <AccordionDetails style={{padding: '0px'}}>
                   {
                     <div className="table-container">
                       <Table>
                         <TableHead>
                           <TableRow>
                             <TableCell className="xxsmall" align="center" width="5%">S.No</TableCell>
                             {
                               this.props.xeroMode &&
                                 <React.Fragment>
                                   <TableCell align="center" width="10%">Xero Item Code</TableCell>
                                   <TableCell align="center" width="10%">Xero Account Code</TableCell>
                                 </React.Fragment>
                             }
                             <TableCell align="center" width="8%">Date</TableCell>
                             <TableCell className="medium" align="center" width="42%">Description</TableCell>
                             <TableCell className="medium" align="center" width="8%">{ isStrictQuantityBasedCommodity ? 'Quantity' : getCountryLabel('tonnage') }</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">Contract Price</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">Price Adjustments</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">Rate</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">Price (Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="small" align="center" width="12%">{getCountryLabel('gst')}</TableCell>
                             <TableCell className="padding-right-30" align="right" width="8%">Total(Inc {getCountryLabel('gst')})</TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
                           {items.map((item, index) => {
                             let isTitleTransferHasCanolaLoads =  false;
                             if(isTitleTransfers) {
                               isTitleTransferHasCanolaLoads = Boolean(find(get(this.props.invoiceDetails, 'canolaLoads'), { id: get(item, 'itemId') }))
                             }
                             const total = item.total || `${currency} 0.00`;
                             const xeroStateId = isChemicalApplications ? item.itemId : item.invoiceItemId;
                             const xeroState = get(this.state.xero, `${xeroStateId}`)
                             let tonnage = item.tonnage === 'N/A' ? item.tonnage : isStrictQuantityBasedCommodity ? item.tonnage.slice(0, -3) ? (toFloatFromString(item.tonnage.slice(0, -3)) + ' ' + (item?.commodity?.unit || item.quantityUnit || METER_CUBE_UNIT)) : "0.00" : toFloatFromString(item.tonnage) || "0.00";
                             return (
                               <TableRow key={index}>
                                 <TableCell className="xxsmall" width="5%" align="center">{index+1}</TableCell>
                                 {
                                   this.props.xeroMode &&
                                     <React.Fragment>
                                       <TableCell width="10%">
                                         <Autocomplete
                                           disableClearable
                                           size='small'
                                           disablePortal
                                           id="xero-item-code"
                                           options={this.props.xeroItems}
                                           value={xeroState?.itemCode ? find(this.props.xeroItems, {code: xeroState.itemCode}) : undefined}
                                           getOptionLabel={option => option.name}
                                           renderInput={
                                             params => <TextField
                                                         size='small'
                                                         {...params}
                                                         label="Xero Item"
                                                       />
                                           }
                                           onChange={(event, value) => this.setXeroField(item, value.code, 'itemCode', isChemicalApplications)}
                                         />
                                       </TableCell>
                                       <TableCell width="10%">
                                         <Autocomplete
                                           disableClearable
                                           size='small'
                                           disablePortal
                                           id="xero-item-account"
                                           value={xeroState?.accountCode ? find(this.props.xeroAccounts, {code: xeroState.accountCode}) : undefined}
                                           isOptionEqualToValue={(option, value) => option.code === value.code}
                                           options={this.props.xeroAccounts}
                                           getOptionLabel={option => option.name}
                                           renderInput={
                                             params => <TextField
                                                         size='small'
                                                         {...params}
                                                         label="Xero Account"
                                                       />
                                           }
                                           onChange={(event, value) => this.setXeroField(item, value.code, 'accountCode', isChemicalApplications)}
                                         />
                                       </TableCell>
                                     </React.Fragment>
                                 }
                                 <TableCell className="xxsmall" align="center" width="8%">
                                   {
                                     toDateFormat(item.datetime || item.date)
                                   }
                                 </TableCell>
                                 <TableCell className="xxsmall" align="center" width="8%">{item.description}</TableCell>
                                 <TableCell className="xxsmall" align="center" width="8%">
                                   { tonnage }
                                   {
                                     !isEmpty(this.getQuantitySpecAdjustments(item)) &&
                                       <IconButton
                                         size="small"
                                         onClick={() => this.showQuantityAdjustments(item)}
                                       >
                                         <InfoOutlinedIcon fontSize='small'/>
                                       </IconButton>
                                   }
                                 </TableCell>
                                 <TableCell className="xxsmall" align="center" width="8%">{get(item, 'contractPrice') ? toFloatFromString(get(item, 'contractPrice')) : 'N/A'}</TableCell>
                                 <TableCell className="xxsmall" align="center" width="8%">
                                   {get(item, 'adjustedPrice') ? toFloatFromString(get(item, 'adjustedPrice'), false) : 'N/A'}
                                   <IconButton
                                     size="small"
                                     onClick={() => this.showAdjustments(item)}
                                   >
                                     <InfoOutlinedIcon fontSize='small'/>
                                   </IconButton>
                                 </TableCell>
                                 <TableCell className="xxsmall" align="center" width="8%">
                                   {
                                     this.isOthers(title) || isPeriodicBrokerageFees || (isTitleTransfers && isTitleTransferHasCanolaLoads) ? "N/A" : isStrictQuantityBasedCommodity ? item.price.slice(0,-5) ? item.price.slice(0,-5) +' / ' + (item?.commodity?.unit || item?.quantityUnit || METER_CUBE_UNIT) : `${currency} 0.00` : (item.price || `${currency} 0.00`)
                                   }
                                 </TableCell>
                                 <TableCell className="xxsmall" align="center" width="8%">
                                   {
                                     isGrainLevies ? total : item.totalExGst || `${currency} 0.00`
                                   }
                                 </TableCell>
                                 <TableCell className="xxsmall" align="center" width="12%">
                                   {
                                     ( isGrainLevies || this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ) ?
                                       "N/A" :
                                       item.gst || `${currency} 0.00`
                                   }
                                 </TableCell>
                                 <TableCell className="padding-right-30" align="right" width="8%">
                                   {
                                     this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? total + "*" : total
                                   }
                                 </TableCell>
                               </TableRow>
                             );
                           })
                           }
                         </TableBody>
                       </Table>
                       {
                         showGSTLine &&
                           <p style={{padding: '4px 56px 4px 24px', fontSize: '0.8125rem'}}>
                             * {getCountryLabel('gst')} is not applicable
                           </p>
                       }
                     </div>
                   }
                 </AccordionDetails>
             }
             {
               this.state.showAdjustments &&
                 <SideDrawer
                   isOpen
                   title='Rate Breakdown'
                   onClose={() => this.setState({showAdjustments: false, selectedItem: undefined})}
                   size="big"
                 >
                   <AdjustedPrices
                     unit={this.props.invoiceDetails.raisedForUnit}
                     item={get(this.state.selectedItem, 'adjustments')}
                     closeDrawer={() => this.setState({showAdjustments: false, selectedItem: undefined})}
                     detailsView
                     currency={currency}
                   />
                 </SideDrawer>
             }
             {
               this.state.showQuantityAdjustments &&
                 <SideDrawer
                   isOpen
                   title='Quantity Adjustments'
                   onClose={() => this.setState({showQuantityAdjustments: false, selectedItem: undefined})}
                   size="big"
                 >
                   <QuantityAdjustments
                     unit={this.props.invoiceDetails.raisedForUnit}
                     item={this.state.selectedItem}
                     closeDrawer={() => this.setState({showQuantityAdjustments: false, selectedItem: undefined})}
                     detailsView
                   />
                 </SideDrawer>
             }
           </div>;
  }

  shouldShowGSTNotApplicableTextForOtherItems(items, title) {
    const { currency } = this.props.invoiceDetails;
    return title === 'Grain Levies' || (title === 'Others' && find(items, {gst: `${currency} 0.00`}));
  }

  shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) {
    const { currency } = this.props.invoiceDetails;
    return title === 'Grain Levies' || ( title === 'Others' && get(item, 'gst') === `${currency} 0.00`);
  }

  getFreightInvoiceItemDetails(items, title) {
    const showGSTLine = this.shouldShowGSTNotApplicableTextForOtherItems(items, title);
    const { currency } = this.props.invoiceDetails;
    return <div>
             <h3 className="invoice-item-heading">{title}</h3>
             {
               !isEmpty(items) &&
                 <AccordionDetails style={{padding: '0px'}}>
                   {
                     <div className="table-container">
                       <Table>
                         <TableHead>
                           <TableRow>
                             <TableCell className="xsmall padding-left-30" width="5%" align="center">S.No</TableCell>
                             {
                               this.props.xeroMode &&
                                 <React.Fragment>
                                   <TableCell align="center" width="10%">Xero Item Code</TableCell>
                                   <TableCell align="center" width="10%">Xero Account Code</TableCell>
                                 </React.Fragment>
                             }
                             <TableCell width="8%"  align="center">Date</TableCell>
                             <TableCell width="37%" align="center">Description</TableCell>
                             <TableCell className="medium" width="8%" align="center">{getCountryLabel('tonnage')}</TableCell>
                             <TableCell className="small" width="8%" align="center">Freight Rate(Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="small" width="8%" align="center">Overs Rate(Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="xxsmall" width="8%" align="center">Total Rate(Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="xxsmall" align="center">Total (Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="small" width="8%" align="center">{getCountryLabel('gst')}</TableCell>
                             <TableCell className="padding-right-30" width="8%" align="right">Total(Inc {getCountryLabel('gst')})</TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
                           {
                             compact(items).map((item, index) => {
                               const total = item.total || `${currency} 0.00`;
                               const xeroState = get(this.state.xero, `${item.invoiceItemId}`)
                               return (
                                 <TableRow key={index}>
                                   <TableCell className="xxsmall padding-left-30" width="5%" align="center">
                                     {index+1}
                                   </TableCell>
                                   {
                                   this.props.xeroMode &&
                                     <React.Fragment>
                                       <TableCell width="10%">
                                         <Autocomplete
                                           disableClearable
                                           size='small'
                                           disablePortal
                                           id="xero-item-code"
                                           options={this.props.xeroItems}
                                           value={xeroState?.itemCode ? find(this.props.xeroItems, {code: xeroState.itemCode}) : undefined}
                                           getOptionLabel={option => option.name}
                                           renderInput={
                                             params => <TextField
                                                         size='small'
                                                         {...params}
                                                         label="Xero Item"
                                                       />
                                           }
                                           onChange={(event, value) => this.setXeroField(item, value.code, 'itemCode')}
                                         />
                                       </TableCell>
                                       <TableCell width="10%">
                                         <Autocomplete
                                           disableClearable
                                           size='small'
                                           disablePortal
                                           id="xero-item-account"
                                           value={xeroState?.accountCode ? find(this.props.xeroAccounts, {code: xeroState.accountCode}) : undefined}
                                           isOptionEqualToValue={(option, value) => option.code === value.code}
                                           options={this.props.xeroAccounts}
                                           getOptionLabel={option => option.name}
                                           renderInput={
                                             params => <TextField
                                                         size='small'
                                                         {...params}
                                                         label="Xero Account"
                                                       />
                                           }
                                           onChange={(event, value) => this.setXeroField(item, value.code, 'accountCode')}
                                         />
                                       </TableCell>
                                     </React.Fragment>
                                 }
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       toDateFormat(item.datetime)
                                     }
                                   </TableCell>
                                   <TableCell className="xxsmall" width="37%" align="center">{item.description}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">{item.tonnage}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       this.isOthers(title) ? "N/A" : (item.freightRate || `${currency} 0.00`)
                                     }
                                   </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       this.isOthers(title) ? "N/A" : (item.oversRate || `${currency} 0.00`)
                                     }
                                   </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       this.isOthers(title) ? "N/A" : (item.freightRate || `${currency} 0.00`)
                                     }
                                   </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">{item.totalExGst || `${currency} 0.00`}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? "N/A" : (item.gst || `${currency} 0.00`)
                                     }
                                   </TableCell>
                                   <TableCell className="padding-right-30" width="8%" align="right">
                                     {
                                       this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? total + "*" : total
                                     }
                                   </TableCell>
                                 </TableRow>
                               );
                             })
                           }
                         </TableBody>
                       </Table>
                       {
                         showGSTLine &&
                           <p style={{padding: '4px 56px 4px 24px', fontSize: '0.8125rem'}}>* {getCountryLabel('gst')} is not applicable</p>
                       }
                     </div>
                   }
                 </AccordionDetails>
             }
           </div>;
  }

  getWarehouseInvoiceItemDetails(items, title) {
    const showGSTLine = this.shouldShowGSTNotApplicableTextForOtherItems(items, title);
    const { currency } = this.props.invoiceDetails;
    const unit = this.state.user?.unit
    return <div>
             <h3 className="invoice-item-heading">{title}</h3>
             {
               !isEmpty(items) &&
                 <AccordionDetails style={{padding: '0px'}}>
                   {
                     <div className="table-container">
                       <Table>
                         <TableHead>
                           <TableRow>
                             <TableCell className="xsmall padding-left-30" width="5%" align="center">S.No</TableCell>
                             {
                               this.props.xeroMode &&
                                 <React.Fragment>
                                   <TableCell align="center" width="10%">Xero Item Code</TableCell>
                                   <TableCell align="center" width="10%">Xero Account Code</TableCell>
                                 </React.Fragment>
                             }
                             <TableCell width="37%" align="center">Description</TableCell>
                             <TableCell className="medium" width="8%" align="center">{getCountryLabel('tonnage')}</TableCell>
                             <TableCell className="medium" width="8%" align="center">{`Shrunk ${getCountryLabel('tonnage')}`}</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">{`Rate (per ${unit})`}</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">Price (Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="small" align="center" width="8%">{getCountryLabel('gst')}</TableCell>
                             <TableCell className="padding-right-30" align="right" width="8%">Total(Inc {getCountryLabel('gst')})</TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
                           {
                             compact(items).map((item, index) => {
                               const total = item.total || `${currency} 0.00`;
                               const xeroState = get(this.state.xero, `${item.invoiceItemId}`)
                               return (
                                 <TableRow key={index}>
                                   <TableCell className="xxsmall padding-left-30" width="5%" align="center">
                                     {index+1}
                                   </TableCell>
                                   {
                                     this.props.xeroMode &&
                                       <React.Fragment>
                                         <TableCell width="10%">
                                           <Autocomplete
                                             disableClearable
                                             size='small'
                                             disablePortal
                                             id="xero-item-code"
                                             options={this.props.xeroItems}
                                             value={xeroState?.itemCode ? find(this.props.xeroItems, {code: xeroState.itemCode}) : undefined}
                                             getOptionLabel={option => option.name}
                                             getOptionSelected={(option, value) => option.code === value.code}
                                             renderInput={
                                               params => <TextField
                                                           size='small'
                                                           {...params}
                                                           label="Xero Item"
                                                         />
                                             }
                                             onChange={(event, value) => this.setXeroField(item, value.code, 'itemCode')}
                                           />
                                         </TableCell>
                                         <TableCell width="10%">
                                           <Autocomplete
                                             disableClearable
                                             size='small'
                                             disablePortal
                                             id="xero-item-account"
                                             value={xeroState?.accountCode ? find(this.props.xeroAccounts, {code: xeroState.accountCode}) : undefined}
                                             isOptionEqualToValue={(option, value) => option.code === value.code}
                                             options={this.props.xeroAccounts}
                                             getOptionLabel={option => option.name}
                                             renderInput={
                                               params => <TextField
                                                           size='small'
                                                           {...params}
                                                           label="Xero Account"
                                                         />
                                             }
                                             onChange={(event, value) => this.setXeroField(item, value.code, 'accountCode')}
                                           />
                                         </TableCell>
                                       </React.Fragment>
                                   }
                                   <TableCell className="xxsmall" width="37%" align="center">{item.description}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">{item.tonnage}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     { item.shrunkTonnage ? item.shrunkTonnage + " " + this.state.user.unit : 'N/A'} </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       item.price || `${currency} 0.00`
                                     }
                                   </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">{item.totalExGst || `${currency} 0.00`}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? "N/A" : (item.gst || `${currency} 0.00`)
                                     }
                                   </TableCell>
                                   <TableCell className="padding-right-30" width="8%" align="right">
                                     {
                                       this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? total + "*" : total
                                     }
                                   </TableCell>
                                 </TableRow>
                               );
                             })
                           }
                         </TableBody>
                       </Table>
                       {
                         showGSTLine &&
                           <p style={{padding: '4px 56px 4px 24px', fontSize: '0.8125rem'}}>* {getCountryLabel('gst')} is not applicable</p>
                       }
                     </div>
                   }
                 </AccordionDetails>
             }
           </div>;
  }

  getWarehouseInvoiceStorageItemDetails(items, title) {
    const showGSTLine = this.shouldShowGSTNotApplicableTextForOtherItems(items, title);
    const { currency } = this.props.invoiceDetails;
    return <div>
             <h3 className="invoice-item-heading">{title}</h3>
             {
               !isEmpty(items) &&
                 <AccordionDetails style={{padding: '0px'}}>
                   {
                     <div className="table-container">
                       <Table>
                         <TableHead>
                           <TableRow>
                             <TableCell className="xsmall padding-left-30" width="5%" align="center">S.No</TableCell>
                             {
                               this.props.xeroMode &&
                                 <React.Fragment>
                                   <TableCell align="center" width="10%">Xero Item Code</TableCell>
                                   <TableCell align="center" width="10%">Xero Account Code</TableCell>
                                 </React.Fragment>
                             }
                             <TableCell width="37%" align="center">Description</TableCell>
                             <TableCell className="medium" width="8%" align="center">{getCountryLabel('tonnage')}</TableCell>
                             <TableCell className="medium" width="8%" align="center">{`Shrunk ${getCountryLabel('tonnage')}`}</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">{`Rate (per ${this.state.user.unit})`}</TableCell>
                             <TableCell className="xxsmall" align="center" width="8%">Price (Ex {getCountryLabel('gst')})</TableCell>
                             <TableCell className="small" align="center" width="8%">{getCountryLabel('gst')}</TableCell>
                             <TableCell className="padding-right-30" align="right" width="8%">Total(Inc {getCountryLabel('gst')})</TableCell>
                           </TableRow>
                         </TableHead>
                         <TableBody>
                           {
                             compact(items).map((item, index) => {
                               const total = item.total || `${currency} 0.00`;
                               const xeroState = get(this.state.xero, `${item.invoiceItemId}`);
                               return (
                                 <TableRow key={index}>
                                   <TableCell className="xxsmall padding-left-30" width="5%" align="center">
                                     {index+1}
                                   </TableCell>
                                   {
                                     this.props.xeroMode &&
                                       <React.Fragment>
                                         <TableCell width="10%">
                                           <Autocomplete
                                             disableClearable
                                             size='small'
                                             disablePortal
                                             id="xero-item-code"
                                             options={this.props.xeroItems}
                                             value={xeroState?.itemCode ? find(this.props.xeroItems, {code: xeroState.itemCode}) : undefined}
                                             getOptionLabel={option => option.name}
                                             getOptionSelected={(option, value) => option.code === value.code}
                                             renderInput={
                                               params => <TextField
                                                           size='small'
                                                           {...params}
                                                           label="Xero Item"
                                                         />
                                             }
                                             onChange={(event, value) => this.setXeroField(item, value.code, 'itemCode')}
                                           />
                                         </TableCell>
                                         <TableCell width="10%">
                                           <Autocomplete
                                             disableClearable
                                             size='small'
                                             disablePortal
                                             id="xero-item-account"
                                             value={xeroState?.accountCode ? find(this.props.xeroAccounts, {code: xeroState.accountCode}) : undefined}
                                             isOptionEqualToValue={(option, value) => option.code === value.code}
                                             options={this.props.xeroAccounts}
                                             getOptionLabel={option => option.name}
                                             renderInput={
                                               params => <TextField
                                                           size='small'
                                                           {...params}
                                                           label="Xero Account"
                                                         />
                                             }
                                             onChange={(event, value) => this.setXeroField(item, value.code, 'accountCode')}
                                           />
                                         </TableCell>
                                       </React.Fragment>
                                   }
                                   <TableCell className="xxsmall" width="37%" align="center">{item.description}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">{item.tonnage}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     { item.shrunkTonnage ? item.shrunkTonnage + " " + this.state.user.unit : 'N/A'} </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       item.price || `${currency} 0.00`
                                     }
                                   </TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">{item.totalExGst || `{currency} 0.00`}</TableCell>
                                   <TableCell className="xxsmall" width="8%" align="center">
                                     {
                                       this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? "N/A" : (item.gst || `${currency} 0.00`)
                                     }
                                   </TableCell>
                                   <TableCell className="padding-right-30" width="8%" align="right">
                                     {
                                       this.shouldShowGSTMarkForOtherItemOrGrainLevies(item, title) ? total + "*" : total
                                     }
                                   </TableCell>
                                 </TableRow>
                               );
                             })
                           }
                         </TableBody>
                       </Table>
                       {
                         showGSTLine &&
                           <p style={{padding: '4px 56px 4px 24px', fontSize: '0.8125rem'}}>* {getCountryLabel('gst')} is not applicable</p>
                       }
                     </div>
                   }
                 </AccordionDetails>
             }
           </div>;
  }

  onCreateClick = event => {
    event.preventDefault()
    event.stopPropagation()
    const {
      freightContracts, titleTransfers, carry, grainLevies, eprs, others, contractItems, warehouseTransfers,
      subscriptionItems, warehouseInloads, warehouseOutloads, warehouseThroughputInloads, warehouseThroughputOutloads,
      warehouseStorages, warehouseStockSwaps, warehouseRegradeReseasons, blendedGradeLoads, blendedGradeMovements, chemicalApplicationItems, loads
    } = this.props.invoiceDetails;
    const { xero } = this.state
    const totalItems = size(freightContracts) + size(titleTransfers) + size(carry) + size(grainLevies) + size(eprs) + size(others) + size(contractItems) + size(warehouseStorages)
      + size(subscriptionItems) + size(warehouseOutloads) + size(warehouseInloads) + size(warehouseThroughputInloads) + size(warehouseThroughputOutloads)
          + size(warehouseTransfers) + size(warehouseStockSwaps) + size(warehouseRegradeReseasons) + size(blendedGradeLoads) + size(blendedGradeMovements) + size(chemicalApplicationItems) + size(loads)
    if(keys(xero).length === totalItems && every(values(xero), val => Boolean(val.accountCode))) {
      this.props.dispatch(isLoading('createXeroInvoice'))
      const items = map(xero, (info, itemId) => {
        const property = get(info, 'isChemicalApplicationItem') ? 'itemId': 'invoiceItemId';
        return {[property]: itemId, ...info}
      });
      APIService.invoices(this.props.invoiceDetails.id).appendToUrl('xero/').post({invoiceItems: items}).then(response => {
        this.props.dispatch(forceStopLoader())
        if(get(response, 'result.invoices[0].invoiceID'))
          alertifyjs.success('Successfully created Invoice in Xero. Reload...', 1, () => window.location.reload())
        else if(get(response, 'result.creditNotes[0].creditNoteID'))
          alertifyjs.success('Successfully created Credit Note in Xero. Reload...', 1, () => window.location.reload())
        else {
          const errorMessage = get(response, 'result.elements[0].validationErrors[0].message')
          alertifyjs.error(errorMessage || 'An Error Occurred!', 5)
        }
      })
    } else {
      alertifyjs.error('Please select account for each Invoice Item.')
    }
  }

  render() {
    const { expanded } = this.state;
    return (
      <Paper className="invoice-details-section-container">
        <h2 onClick={this.toggleExpanded}>
          {this.getTitle()}
          {
            this.props.xeroMode &&
              <Button variant='contained' size='small' style={{marginLeft: '25px'}} onClick={this.onCreateClick}>Create Xero Invoice</Button>
          }
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && this.getInvoiceItemsSection()}
      </Paper>
    );
  }
}
export default connect()(InvoiceItemsSection);

import React from "react";
import { has, pickBy, map } from "lodash";
import CommonButton from "../common/CommonButton";
import CommonTextField from "../common/CommonTextField";


class QuantityAdjustments extends React.Component {
  render() {
    const unit = this.props.unit || 'MT'
    const { item } = this.props;
    const adjustments = has(item, 'adjustmentDetails') ? item.adjustmentDetails : item.adjustments
    return (
      <div>
        <div style={{marginTop: '15px'}}>
          <CommonTextField
            id='quantity'
            label='Quantity'
            value={`${parseFloat(adjustments?.itemTonnage).toFixed(2)} ${unit}`}
            disabled
          />
        </div>
        {
          map(pickBy(adjustments?.specs, val => val.quantity), (val, spec) => (
            <div key={`${spec}-${val.value}`} style={{marginTop: '15px'}}>
              <CommonTextField
                id={spec}
                label={`${spec.toUpperCase()} Adjustment`}
                value={parseFloat(val.value).toFixed(2)}
                disabled
              />
            </div>
          ))
        }
        <div style={{marginTop: '20px', marginLeft: 'auto', float: 'right' }}>
          <CommonButton
            variant="outlined"
            label="Cancel"
            type="button"
            default
            onClick={this.props.closeDrawer}
          />
        </div>
      </div>
    );
  }
}

export default QuantityAdjustments;

import React, { Component } from 'react';
import {
    Dialog, DialogContent, DialogTitle, DialogActions, Button, TextField
} from '@mui/material';
import { set, includes } from 'lodash';

class BookingDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookingNumber: this.props.bookingNumber,
            isDialogOpen: true,
        };
    }

    handleBookingChange = event => this.setState({ [event.target.name]: event.target.value });

    onUpdate = () => {
        let payload = {
            communication: { acceptanceRequired: false }
        };
        if(includes(this.props.activeDialog, 'Pickup Booking')) {
            set(payload, 'freightPickup.bookingNumber', this.state.bookingNumber);
        } else if(includes(this.props.activeDialog, 'Delivery Booking')) {
            set(payload, 'freightDelivery.bookingNumber', this.state.bookingNumber);
        }
        this.props.updateMovement(this.props.movement.id, payload);
        this.toggleBookingDialog();
    }

    toggleBookingDialog = () => {
        this.setState({ isDialogOpen: false });
        this.props.toggleBookingPopup('', '');
    }

    render() {
        return (
            <React.Fragment>
                <Dialog onClose={this.toggleBookingDialog} open={this.state.isDialogOpen}>
                    <DialogTitle onClose={this.toggleBookingDialog}>
                        {this.props.activeDialog}
                    </DialogTitle>
                    <DialogContent style={{minWidth: '500px'}}>
                        <form>
                            <div className='row' style={{marginTop: '10px'}}>
                                <div className='col-md-12'>
                                    <TextField
                                        variant="outlined"
                                        id='booking_number'
                                        name='bookingNumber'
                                        label='Booking Number'
                                        placeholder='Booking Number'
                                        value={this.state.bookingNumber}
                                        style={{width: '100%'}}
                                        onChange={this.handleBookingChange}
                                    />
                                </div>
                            </div>
                        </form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.toggleBookingDialog}>
                            Cancel
                        </Button>
                        <Button onClick={this.onUpdate} color='primary'>
                            Update
                        </Button>
                    </DialogActions>
                </Dialog>
            </React.Fragment>
        );
    }
}


export default BookingDialog;
import React from 'react';
import {
 Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, InputLabel, Autocomplete,
 ToggleButtonGroup, ToggleButton
} from '@mui/material';
import {isEmpty} from 'lodash';
import './slotHistory.scss';
import {
  getDateTimeFromUTC
} from '../../common/utils';
import moment from 'moment';
import 'moment-timezone';

class MoveSlotsDialog extends React.Component {
  constructor(props) {
    super(props);
    this.directionOptions = ['Forwards', 'Backwards']
  }

  showDayChangeWarning = (selectedPlannedOrBookedSlots) => {
    return selectedPlannedOrBookedSlots.some(schedule => {
      const updatedStart = this.props.getUpdatedSlotTime(getDateTimeFromUTC(schedule.raw.slot.start));
      return !moment(getDateTimeFromUTC(schedule.raw.slot.start).date, 'YYYY-MM-DD').isSame(updatedStart.date)
    });
  }

  render() {
    const { open, closeMoveSlotDialog, moveSlotDirection, moveSlotTimeAmount, handleConfirmMultiSlotMove, handleDaysChange, handleSlotDirection, handleHoursChange, handleMinutesChange, selectedPlannedOrBookedSlots } = this.props;
    const showWarning = this.showDayChangeWarning(selectedPlannedOrBookedSlots)
    return (
      <Dialog fullWidth open={open} onClose={closeMoveSlotDialog}>
        <DialogTitle style={{ float: 'left' }}>
          Move Slots
        </DialogTitle>
        <DialogContent>
          <ToggleButtonGroup
            value={moveSlotDirection}
            defaultValue="Forwards"
            onChange={(_, direction) => handleSlotDirection(direction)}
            exclusive={true}
          >
            <ToggleButton value="Forwards">Forwards</ToggleButton>
            <ToggleButton value="Backwards">Backwards</ToggleButton>
          </ToggleButtonGroup>

          <InputLabel style={{ margin: "20px 0 5px" }}>Move by:</InputLabel>
            <div style={{ display: 'flex' }}>
            <Autocomplete
              id='move_slot_days'
              options={Array.from({ length: 4 }, (_, index) => index)}
              value={moveSlotTimeAmount.days}
              style={{ width:'30%', marginRight: '18px'}}
              onChange={(_, days) => handleDaysChange(days)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Days"
                  variant="outlined"
                />
              )}
              getOptionLabel={(option) => option.toString()}
            />
            <Autocomplete
              id='move_slot_hours'
              options={Array.from({ length: 24 }, (_, index) => index)}
              value={moveSlotTimeAmount.hours}
              style={{ width:'30%', marginRight: '18px' }}
              onChange={(_, hours) => handleHoursChange(hours)}
              disabled={moveSlotTimeAmount.days === 3}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Hours"
                  variant="outlined"
                />
              )}
              getOptionLabel={(option) => option.toString()}
            />
            <Autocomplete
              id='move_slot_minutes'
              options={[0, 30]}
              value={moveSlotTimeAmount.minutes}
              style={{ width:'30%' }}
              onChange={(_, minutes) => handleMinutesChange(minutes)}
              disabled={moveSlotTimeAmount.days === 3}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Minutes"
                  variant="outlined"
                />
              )}
              getOptionLabel={(option) => option.toString()}
            />
            </div>
            {
              !isEmpty(this.props.timeAmountError) &&
              <div style={{ color: '#ef6030', fontSize: '12px', marginTop:"5px" }}>{this.props.timeAmountError}</div>
            }
            {
              showWarning &&
              <div style={{ color: '#f0ad4e', fontSize: '12px', marginTop:"5px" }}>Warning: Some slots might be moved to a different day</div>
            }
        </DialogContent>
        <DialogActions>
          <Button type='button' color='secondary' variant='outlined' onClick={closeMoveSlotDialog}>Close</Button>
          <Button type='button' variant="contained" color="primary" onClick={handleConfirmMultiSlotMove}>Confirm</Button>
        </DialogActions>
      </Dialog>
    )
  }

}

export default MoveSlotsDialog;
import React, { useEffect } from 'react';
import { Dialog, DialogContent, Button, DialogActions } from '@mui/material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CommonDatePicker from '../common/CommonDatePicker';
import { useState } from 'react';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import { map, remove, uniqBy, filter, includes, some } from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch, useSelector } from 'react-redux';
import { setDownloadBar } from '../../actions/main';
import { getCommodities } from '../../actions/api/commodities';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';
import { currentUser, getSeasons, getCurrentCountry } from "../../common/utils";
import moment from 'moment';
import CommonListingButton from '../common/CommonListingButton';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import CustomHeaderOptions from '../common/CustomHeaderOptions';
import SideDrawer from '../common/SideDrawer';

let StorageStockCSV = props => {
  let [combinedStates, setCombinedStates] = useState({
    'date_time__gte': '',
    'date_time__lte': '',
    'type__in': [],
    'commodity_id__in': [],
    'farm_id__in': [props.farm_id],
    'storage_id__in': [],
    'ngr__company_id__in': [],
    'season__in': [],
    'grade_id__in': [],
    'ngr_id__in': []
  });

  let [allCompanies, setAllCompanies] = useState({
    'managedCompanies': [],
    'directoryCompanies': []
  });
  let [allOwners, setAllOwners] = useState({});

  let getAllCompanies = async () => {
    const user = currentUser();
    let managedCompanies = await APIService.companies().appendToUrl('managed/companies/').get(props.token);
    let DirectoryCompanies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(props.token);
    DirectoryCompanies = uniqBy([...DirectoryCompanies, { id: user.companyId, name: user.company.name }], 'id');
    setAllCompanies({
      'managedCompanies': managedCompanies,
      'directoryCompanies': DirectoryCompanies
    });
    let companyExists = find(managedCompanies, { id: user.companyId });
    if (companyExists)
      setAllOwners(DirectoryCompanies);
    else
      setAllOwners(managedCompanies);
  };

  const dispatch = useDispatch();

  const CUSTOM_HEADER_EDIT = {
    fontSize: '12px',
    textDecoration: 'underline',
    cursor: 'pointer',
    marginLeft: '10px'
  };

  let [allLocations, setAllLocations] = useState([]);

  let [allStorages, setAllStorages] = useState([]);


  let [allSeason, setSeason] = useState([]);



  let [isInloadOutloadCSV, setIsInloadOutloadCSV] = useState(false);
  let [groupCommodityDetailsTogether, setGroupCommodityDetailsTogether] = useState(true);
  let [showAfterLastEmpty, setShowAfterLastEmpty] = useState(true);
  let [customColumns, setCustomColumns] = useState(true);
  let [negativeOutloads, setNegativeOutloads] = useState(false);
  let [customHeaderOptions, setCustomHeaderOptions] = useState(false);
  let [customColumnNames, setCustomColumnNames] = useState({});
  let [csvType, setCsvType] = useState();
  let [allGrades, setGrade] = useState([]);
  let [allNgrs, setNgrs] = useState([]);
  let [storageTransferReport, setStorageTransferReport] = useState(false);
  let [customColumnTitle, setCustomColumnTitle] = useState(undefined);

  let getSeason = () => setSeason(map(getSeasons(), season => ({id: season, name: season})));

  let handleStocksCSV = () => {
    const { managedCompanies } = allCompanies;
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if (isEmpty(combinedStatesCopy.ngr__company_id__in)) {
      combinedStatesCopy.farm_id__in.forEach(farmId => {
        let farm = find(props.locations, { id: farmId });
        let companyExists = find(managedCompanies, { id: farm.companyId });
        if (!companyExists) {
          combinedStatesCopy.ngr__company_id__in = map(managedCompanies, 'id');
        }
      });
    }
    if (combinedStatesCopy.date_time__lte) {
      let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__lte = lte_time;
    }

    if (combinedStatesCopy.date_time__gte) {
      let gte_time = `${combinedStatesCopy.date_time__gte} 00:00:00`;
      gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__gte = gte_time;
    }
    combinedStatesCopy.custom_csv = customColumns;
    combinedStatesCopy.negative_outloads = negativeOutloads;

    dispatch(setDownloadBar('Your Stocks CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
    const service = APIService.farms().appendToUrl(`web/loads-csv/`);
    service
      .put(combinedStatesCopy, props.token);

    props.toggleDialog();
  };

  let handleStorageReportCSV = () => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if(combinedStatesCopy.date_time__lte){
      let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__lte = lte_time;
    }
    combinedStatesCopy['group_commodity_details_together'] = groupCommodityDetailsTogether;
    combinedStatesCopy['after_last_empty'] = showAfterLastEmpty;
    combinedStatesCopy.custom_csv = customColumns;
    dispatch(setDownloadBar('Your Stocks CSV is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
    const service = APIService.farms().appendToUrl(`web/storages-csv/`);
    service
      .put(combinedStatesCopy, props.token);
    props.toggleDialog();
  };

  let handleStorageTransferReport = () => {
    let combinedStatesCopy = Object.assign({}, combinedStates);
    if (combinedStatesCopy.date_time__lte) {
      let lte_time = `${combinedStatesCopy.date_time__lte} 23:59:59`;
      lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__lte = lte_time;
    }
    if (combinedStatesCopy.date_time__gte) {
      let gte_time = `${combinedStatesCopy.date_time__gte} 00:00:00`;
      gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
      combinedStatesCopy.date_time__gte = gte_time;
    }
    dispatch(setDownloadBar('Your Storage Transfer Report is being prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
    const service = APIService.farms().appendToUrl(`web/storage-transfer-csv/`);
    service
      .put(combinedStatesCopy, props.token);

    props.toggleDialog();
  }

  let allStocks = useSelector(state => state.master.commodities.items);

  let getAllCommodities = () => dispatch(getCommodities());


  let getStorageFarmIdName = storage => {
    let farmName;
    if(isEmpty(allLocations)) {
      let { locations } = { ...props };
      let copyLocations = locations.filter(val => val.id !== 'all');
      farmName = find(copyLocations, { id: storage?.farmId })?.name;
      setAllLocations(copyLocations);
    } else
      farmName = find(allLocations, { id: storage?.farmId })?.name;
    return {
      id: storage.id,
      name: storage.name,
      farmId: storage?.farmId,
      farmName: farmName,
      displayName: `${storage.name} - ${farmName}`
    };
  };

  let getAllStorages = () => {
    const { farm_id__in } = combinedStates;
    map(farm_id__in, farm_id => {
      if (allStorages.length == 0 || !some(allStorages, storage => storage.farmId === farm_id)) {
        APIService
        .farms(farm_id)
        .storages()
        .appendToUrl('home/?no_stocks&no_aggregations&no_relations')
        .get(props.token)
        .then(storages => {
          let data = map(storages, getStorageFarmIdName);
          setAllStorages([...allStorages, ...data ]);
        });
      }})
  };

  useEffect(() => {
    setCombinedStates({
      ...combinedStates,
      'farm_id__in': props.farm_id != 'all' ? [props.farm_id] : []
    });
  }, [props.farm_id]);

  useEffect(() => {
    if (props.isOpen) {
      getAllCommodities();
      getSeason();
      getAllCompanies();
      getAllGrades();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen) {
      if (combinedStates.farm_id__in.length != 0) {
        setCombinedStates({
          ...combinedStates,
          'storage_id__in': [], 'ngr__company_id__in': []
        });
        getAllStorages();
      }
      if (props.locations) {
        let { locations } = { ...props };
        let copyLocations = locations.slice();
        remove(copyLocations, val => val.id === 'all');
        copyLocations = filter(copyLocations, location => location?.isActive == true);
        setAllLocations(copyLocations);
      }
    }
  }, [combinedStates.farm_id__in, allCompanies.directoryCompanies, props.locations]);

  let fetchCSVData = (param = '', callback) => {
    setStorageTransferReport(false);
    setIsInloadOutloadCSV(param === 1);
    setCsvType(param === 1 ? 'loads_csv' : 'storage_report_csv');
    props.toggleDialog();
    if (callback) {
      callback();
    }
  };

  let editCustomHeaderOptions = async () => {
    let columnNames = await APIService.profiles().appendToUrl(`${props.user.id}/report-preferences/${csvType}/`).get(props.token);
    setCustomColumnNames(columnNames);
    setCustomHeaderOptions(true);
  };

  const enableCustomCsv = () => {
    return props.user.company.enableCustomCsv;
  };

  let getFarmStorages = () => filter(allStorages, storage => includes(combinedStates.farm_id__in, storage.farmId))

  let getGrades = () => filter(allGrades, grade => includes(combinedStates.commodity_id__in, grade.commodityId))

  const getAllGrades = async() => {
    const grades = await APIService.commodities().appendToUrl(`grades/all/`).get(props.token, null, {countryId: getCurrentCountry().id});
    setGrade(grades);
  };

  let handleCompanyChange = async(id, selectedItems) => {
    setCombinedStates({ ...combinedStates, 'ngr__company_id__in': map(selectedItems, 'id') });
    if (!isEmpty(selectedItems)) {
      const companyQueryString = map(selectedItems, obj => `company_ids=${obj.id}`).join('&');
      const ngrs = await APIService.ngrs().appendToUrl(`?${companyQueryString}`).get();
      setNgrs(ngrs);
    }
    else setNgrs([]);
  }

  let fetchStorageTransferReport = callback => {
    setIsInloadOutloadCSV(false);
    setStorageTransferReport(true);
    props.toggleDialog();
    if (callback) {
      callback();
    }
  }

  let updateColumnCount = count => {
    setCustomColumnTitle(`Edit Columns (${count})`);
  }

  return (
    <span style={{display: 'inline-block'}}>
      <CommonListingButton
        showMenus={true}
        optionMapper={[
          { name: 'Inload/Outload Data', fx: callback => fetchCSVData(1, callback) },
          { name: 'Storage Report', fx: callback => fetchCSVData(2, callback) },
          { name: 'Storage Transfer Report', fx: callback => fetchStorageTransferReport(callback) },
        ]}
        title='Download Contents of the table in a CSV'
        name='CSV'
        style={{}}
        variant="outlined"
        size="small"
      />
      <Dialog open={props.isOpen} onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
        <DialogTitleWithCloseIcon
          onClose={props.toggleDialog}
          closeButtonStyle={{ marginTop: '0px' }}
          id='form-dialog-title'>
          {isInloadOutloadCSV ? 'Download Inload/Outload Data' : (storageTransferReport ? 'Storage Transfer Report' : 'Storage Report')}
        </DialogTitleWithCloseIcon>

        <DialogContent>
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="farm_id"
              items={allLocations}
              selectedItems={combinedStates.farm_id__in}
              displayField="name"
              onChange={(id, selectedItems) => setCombinedStates({...combinedStates, 'farm_id__in': map(selectedItems, 'id')})}
              placeholder="Select Farm(s) or Site(s)..."
              label="Farm(s)/Site(s)"
              selectAll
              clearAll
              containerStyle={{marginTop: '0px'}}
            />
          </div>

          {(isInloadOutloadCSV || storageTransferReport) &&
           <div>
             <div className='col-sm-6 no-left-padding' style={{ marginTop: '15px' }}>
               <CommonDatePicker
                 id='date_time__gte'
                 floatingLabelText='Start Date'
                 value={combinedStates.date_time__gte}
                 onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
               />
             </div>

             <div className='col-sm-6 no-right-padding' style={{ marginTop: '15px' }}>
               <CommonDatePicker
                 id='date_time__lte'
                 floatingLabelText='End Date'
                 value={combinedStates.date_time__lte}
                 onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
               />
             </div>
             { !storageTransferReport && 
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="type__in"
                  items={[{ 'id': 'inload', 'name': 'Inload' },
                          { 'id': 'outload', 'name': 'Outload' }]}
                  selectedItems={combinedStates.type__in}
                  displayField="name"
                  onChange={(id, selectedItems) =>
                    setCombinedStates({ ...combinedStates, 'type__in': map(selectedItems, 'id') })}
                  placeholder="Select Load Type..."
                  label="Load Type"
                  selectAll
                  clearAll
                />
              </div>
             }
           </div>
          }

          <div className="col-sm-6 no-left-padding">
            <CommonMultiSelect
              id="commodity_id__in"
              items={allStocks}
              selectedItems={combinedStates.commodity_id__in}
              displayField="displayName"
              onChange={(id, selectedItems) =>
                setCombinedStates({ ...combinedStates, 'commodity_id__in': map(selectedItems, 'id') })}
              placeholder="Select Commodity..."
              label="Commodity"
              selectAll
              clearAll
            />
          </div>

          <div className="col-sm-6 no-right-padding">
            <CommonMultiSelect
              id="season__in"
              items={allSeason}
              selectedItems={combinedStates.season__in}
              displayField="name"
              onChange={(id, selectedItems) =>
                setCombinedStates({ ...combinedStates, 'season__in': map(selectedItems, 'id') })}
              placeholder="Select Season..."
              label="Season"
              selectAll
              clearAll
            />
          </div>

          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="grade_id__in"
              items={getGrades()}
              selectedItems={combinedStates.grade_id__in}
              displayField="name"
              onChange={(id, selectedItems) =>
                setCombinedStates({ ...combinedStates, 'grade_id__in': map(selectedItems, 'id') })}
              placeholder="Select Grade..."
              label="Grade"
              selectAll
              clearAll
            />
          </div>
          { !storageTransferReport &&
          <div className="col-sm-12 padding-reset">
            <CommonMultiSelect
              id="storage_id__in"
              items={getFarmStorages()}
              selectedItems={combinedStates.storage_id__in}
              displayField="displayName"
              onChange={(id, selectedItems) =>
                setCombinedStates({ ...combinedStates, 'storage_id__in': map(selectedItems, 'id') })}
              placeholder="Select Storage..."
              label="Storages"
              selectAll
              clearAll
            />
          </div>
          }

          {isInloadOutloadCSV &&
            <div>
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="ngr__company_id__in"
                  items={allOwners}
                  selectedItems={combinedStates.ngr__company_id__in}
                  displayField="name"
                  onChange={handleCompanyChange}
                  placeholder="Select NGR Company..."
                  label="Stock Owners"
                  selectAll
                  clearAll
                />
              </div>
              <div className="col-sm-12 padding-reset">
                <CommonMultiSelect
                  id="ngr_id__in"
                  items={allNgrs}
                  selectedItems={combinedStates.ngr_id__in}
                  displayField="ngrNumber"
                  onChange={(id, selectedItems) =>
                    setCombinedStates({ ...combinedStates, 'ngr_id__in': map(selectedItems, 'id') })}
                  placeholder="Select NGR..."
                  label="Ngrs"
                  selectAll
                  clearAll
                />
              </div>
            </div>
          }
          {enableCustomCsv() && !storageTransferReport &&
            <div>
              <Checkbox
                id={'custom-headers'}
                checked={customColumns}
                style={{ height: '40px' }}
                onChange={() => setCustomColumns(!customColumns)}
              />{'Custom Columns'}
              <a onClick={editCustomHeaderOptions} hidden={!customColumns} style={CUSTOM_HEADER_EDIT}>View & Update</a>
            </div>
          }
          {isInloadOutloadCSV && <div>
              <Checkbox
                id={'negativeOutloads'}
                checked={negativeOutloads}
                style={{ height: '40px' }}
                onChange={() => setNegativeOutloads(!negativeOutloads)}
              />{'Show outloads as negative'}
            </div>
          }
          {
            !isInloadOutloadCSV && !storageTransferReport &&
            <div>
              <div className='col-sm-6 padding-reset' style={{ paddingLeft: '0px', paddingTop: '2%' }}>
                <CommonDatePicker
                  id='date_time__lte'
                  floatingLabelText='Stocks As On'
                  value={combinedStates.date_time__lte}
                  onChange={(val, id) => setCombinedStates({ ...combinedStates, [id]: val })}
                />
              </div>
              <div className='col-sm-12 padding-reset' style={{ paddingTop: '8px' }}>
                <div className='col-sm-6 padding-reset'>
                  <Checkbox
                    id='club-commodity-details'
                    checked={groupCommodityDetailsTogether}
                    style={{ height: '40px' }}
                    onChange={() => setGroupCommodityDetailsTogether(!groupCommodityDetailsTogether)}
                  />Club grades together
                </div>
                {
                !groupCommodityDetailsTogether &&
                <div className='col-sm-6 padding-reset'>
                  <Checkbox
                    id='after-last-empty'
                    disabled={groupCommodityDetailsTogether}
                    checked={showAfterLastEmpty}
                    style={{ height: '40px' }}
                    onChange={() => setShowAfterLastEmpty(!showAfterLastEmpty)}
                  />Since the last empty of storage
                </div>
                }
              </div>
            </div>
          }
          <SideDrawer
            isOpen={customHeaderOptions}
            title={customColumnTitle}
            onClose={() => setCustomHeaderOptions(false)}
            size="small"
          >
            <CustomHeaderOptions
              customColumns={customColumnNames}
              closeDrawer={() => setCustomHeaderOptions(false)}
              user={props.user}
              token={props.token}
              csv_type={csvType}
              updateColumnCount={(count) => updateColumnCount(count)}
            />
          </SideDrawer>
        </DialogContent>
        <DialogActions>
          <Button type='button' onClick={props.toggleDialog} variant='outlined'>
            Cancel
          </Button>
          <Button type='button' onClick={isInloadOutloadCSV ? handleStocksCSV : (storageTransferReport ? handleStorageTransferReport : handleStorageReportCSV)} color='primary' variant='outlined'>
            Download
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );

};

export default StorageStockCSV;

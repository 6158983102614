import React from "react";
import { connect } from 'react-redux';
import { forceStopLoader, setBreadcrumbs, setHeaderText } from "../../actions/main";
import APIService from '../../services/APIService';
import { isCompanyEditable, isTransactionParticipated, toDateFormat } from "../../common/utils";
import AddButton from "../common/AddButton";
import TargetMoistureForm from "./TargetMoistureForm";
import GenericTable from "../GenericTable";
import { Paper } from "@mui/material";
import { reject } from "lodash";

class TargetMoisture extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openForm: false,
      data: [],
      selected: undefined
    }
  }

  componentDidMount() {
    this.setHeaderAndBreadcrumbs();
    this.getTargetMoistureData();
  }

  getTargetMoistureData() {
    const { companyId, dispatch } = this.props;
    APIService.farms()
      .appendToUrl('target-moistures/')
      .get(null, null, {storage__farm__company_id__in: companyId})
      .then(data => this.setState({data: data},() => dispatch(forceStopLoader())));
  }

  setHeaderAndBreadcrumbs() {
    const company = this.getCompanyDetails();
    if(company) {
      const { dispatch } = this.props;
      dispatch(setHeaderText(company.name));
      dispatch(setBreadcrumbs(this.getBreadcrumbs()));
    }
  }

  getBreadcrumbs(company) {
    company = company || this.getCompanyDetails();
    if(company) {
      return [
        {text: 'Companies', route: '/companies'},
        {text: company.name, route: '/companies/' + company.id + '/details'},
        {text: 'Target Moisture'}
      ];
    }
  }

  getCompanyDetails() {
    const { companyId, user, selectedCompany } = this.props;
    if(user.companyId === companyId)
      return user.company;
    if(selectedCompany && companyId == selectedCompany.id)
      return selectedCompany;
  }

  hasWriteAccess() {
    const { selectedCompany } = this.props;
    return !isTransactionParticipated(selectedCompany) || isCompanyEditable(selectedCompany)
  }

  onAdd = () => this.setState({openForm: true});

  onCloseForm = refresh => {
    this.setState({openForm: false, selected: null}, () => {
      if(refresh)
        this.setState({data: []}, this.getTargetMoistureData);
    });
  };

  onDefaultClick = item => {
    if(this.hasWriteAccess())
      this.setState({selected: item, openForm: true});
  };

  onOptionClick = (rowNum, key, targetMoistureId, targetMoisture) => {
    if(!targetMoistureId)
      return;

    if(key === 'edit')
      this.onDefaultClick(targetMoisture);

    if(key === 'delete')
      this.deleteTargetMoisture(targetMoistureId);
  };

  deleteTargetMoisture(targetMoistureId) {
    APIService.farms().appendToUrl(`target-moistures/${targetMoistureId}/`).delete().then(() => {
      this.setState({data: reject(this.state.data, {id: targetMoistureId})});
    });
  }

  render() {
    const hasWriteAccess = this.hasWriteAccess();
    const {companyId} = this.props;
    const {selected} = this.state;
    const COLUMNS = [
      {key: 'siteName', header: 'Site', className: 'large'},
      {key: 'storageName', header: 'Storage Name', className: 'large'},
      {key: 'commodityName', header: 'Commodity', className: 'large'},
      {key: 'startDate', header: 'Start Date', className: 'medium', formatter: fees => fees.startDate ? toDateFormat(fees.startDate) : undefined},
      {key: 'endDate', header: 'End Date', className: 'medium', formatter: fees => fees.endDate && fees.endDate.substr(0, 4) !== "9999" ? toDateFormat(fees.endDate) : undefined},
      {key: 'percentage', header: 'Target Moisture (in %)', className: 'small'}
    ];
    const ACTION_OPTIONS = [
      {key: 'edit', text: 'Edit'},
      {key: 'delete', text: 'Delete'},
    ];
    return (
      <Paper className='paper-table'>
        <div>
        {
          hasWriteAccess &&
          <AddButton label='Target Moisture' onClick={ this.onAdd } />
        }
        <GenericTable
          columns={COLUMNS}
          items={this.state.data}
          editColumnClass="xsmall"
          orderBy="siteName"
          optionsItems={hasWriteAccess ? ACTION_OPTIONS : undefined}
          handleOptionClick={this.onOptionClick}
          handleDefaultCellClick={this.onDefaultClick}
          hasActions={hasWriteAccess}
        />
        </div>
        {
          this.state.openForm &&
          <TargetMoistureForm
            companyId={companyId}
            open={this.state.openForm}
            onClose={this.onCloseForm}
            selected={selected}
          />
        }
      </Paper>
    )
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
    selectedCompany: state.companies.companies.selectedCompany,
    breadcrumbs: state.main.breadcrumbs,
  };
};

export default connect(mapStateToProps)(TargetMoisture);
import { Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Paper, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React from "react";
import { EMPTY_VALUE, PRIMARY_COLOR_GREEN } from "../../common/constants";
import { map } from "lodash";

class UnitConversions extends React.Component {

  render() {
    const { unitConversions, preferredUnit, commodityUnit } = this.props;
    return (
      <Paper>
        <Dialog open onClose={this.props.onClose} maxWidth='sm' fullWidth>
          <DialogTitle>
            Unit Conversions
          </DialogTitle>
          <DialogContent>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Unit</TableCell>
                  <TableCell>Quantity</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  map(unitConversions, (value, _unit) => {
                    const isPreferred = _unit.toLowerCase() === preferredUnit.toLowerCase()
                    const isCommodityUnit = _unit.toLowerCase() === commodityUnit.toLowerCase()
                    const style = isPreferred ? {color: PRIMARY_COLOR_GREEN, width: '33%'} : {width: '33%'}
                    return (
                      <TableRow key={_unit}>
                        <TableCell style={style}>{_unit.toUpperCase()} </TableCell>
                        <TableCell style={style}>
                            {value ? parseFloat(value).toFixed(2) : EMPTY_VALUE}
                        </TableCell>
                        <TableCell align='left' style={style}>
                          {isCommodityUnit && <Chip size='small' variant='outlined' color='primary' label='Commodity Unit' />}
                          </TableCell>
                      </TableRow>
                    )
                  })
                }
              </TableBody>
            </Table>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.props.onClose}>
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </Paper>
    )
  }
}

export default UnitConversions;
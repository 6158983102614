import React from 'react';
import { InputAdornment } from '@mui/material/';
import { get } from 'lodash';
import CommonTextField from '../../common/CommonTextField';
import CommonSelect from '../../common/select/CommonSelect';
import VarietyAutoComplete from '../../common/autocomplete/VarietyAutoComplete';
import SeasonSelect from '../../common/select/SeasonSelect';
import isEqual from 'lodash/isEqual';
class DisabledCommodityInfo extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { selectedEntity } = this.props;
    return (
      <div className='cardForm-content'>
        <div className='col-md-6'>
          <div className='col-md-12 form-wrap padding-reset'>
            <CommonTextField
              id='commodityId'
              label='Commodity'
              value={get(selectedEntity, 'commodity')}
              disabled
            />
          </div>
          {get(selectedEntity, 'variety') ?
          <div className='col-md-12 form-wrap padding-reset'>
            <CommonTextField
              id='varietyId'
              label='Variety'
              value={get(selectedEntity, 'variety')}
              disabled
            />
          </div> :
          <div className='col-md-12 form-wrap padding-reset'>
            <VarietyAutoComplete
              id="varietyId"
              onChange={this.props.handleValueChange}
              floatingLabelText="Variety (Optional)"
              commodityId={get(selectedEntity, 'commodityId')}
              varietyId={get(selectedEntity, 'varietyId')}
              dependsOnCommodity={true}
              disabled={false}
              errorText={this.props.varietyErrorText}
            />
          </div>}
          { !get(selectedEntity, 'season') ?
            <div className='col-md-12 form-wrap padding-reset'>
              <SeasonSelect
                id="season"
                onChange={this.props.handleValueChange}
                errorText={get(this.state, 'fields.season.errors[0]', '')}
              />
            </div> :
            <div className='col-md-12 form-wrap padding-reset'>
              <CommonTextField
                id='season'
                label='Season'
                value={get(selectedEntity, 'season')}
                disabled
              />
            </div>
          }
        </div>
        <div className='col-md-6'>
          {
            isEqual(get(selectedEntity, 'typeId'), 2) && isEqual(get(selectedEntity, 'entity'), 'contract') ?
            <div className='col-md-12 form-wrap padding-reset'>
              <CommonSelect
                id='gradeId'
                floatingLabelText='Grade'
                items={get(selectedEntity, 'spreadDetails')}
                selectConfig={{text: 'name', value: 'id'}}
                onChange={(val, id) => this.props.handleSpreadGradeChange(val, id)}
                value={this.props.gradeId || get(this.props, 'selectedEntity.gradeId')}
              />
            </div>
            :
            <div className='col-md-12 form-wrap padding-reset'>
              <CommonTextField
                id='gradeId'
                label='Grade'
                value={get(selectedEntity, 'grade')}
                disabled
              />
            </div>
          }
          <div className='col-md-12 form-wrap padding-reset'>
            <CommonTextField
              id='tonnage'
              label='Tonnage'
              value={get(selectedEntity, 'tonnage')}
              disabled
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end' style={{ color: 'rgb(162,162,162)', marginLeft: '-45px' }}>
                    {get(selectedEntity, 'unit')}
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default DisabledCommodityInfo;

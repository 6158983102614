import { connect } from 'react-redux';
import TrailerForm from '../components/trucks/TrailerForm';
import { createTrailer } from '../actions/api/trucks';
import { addTrailer } from '../actions/companies/trucks';

function submit(companyId, data) {
  return (dispatch) => {
    dispatch(createTrailer(companyId, data, addTrailer));
  };
}

export default connect(null, { submit })(TrailerForm);

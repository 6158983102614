import React, { Component } from 'react';
import { connect } from 'react-redux';
import { required } from '../../common/validators';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CommonTextField from '../common/CommonTextField';
import CommonButton from '../common/CommonButton';
import './acceptRejectOrder.scss';
import APIService from '../../services/APIService';
import moment from "moment";

class UnregisteredUserRejectMovement extends Component{
  constructor(props) {
    super(props);
    this.state = {
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },
      canReject: false,
      isRejected: false,
      movement: undefined,
      loaded: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount(){
    const movementId = get(this.props, 'match.params.movement_id');
    const salt = get(this.props, 'match.params.salt');
    if(movementId && salt) {
      const movement = await APIService.freights().contracts(movementId).get(salt);
      const newState = {...this.state};
      if(movement && movement.id){
        newState.canReject = movement.confirmable;
        newState.isRejected = movement.status === 'rejected';
        newState.movement = movement;
      }
      newState.loaded = true;
      this.setState(newState);
    }
  }

  handleSubmit(event) {
    this.setErrors();
    const movement = this.state.movement;
    if (this.getIsFormValid() && movement) {
      const data = { rejectionReason: this.state.rejectionReason.value, updatedAt: moment.utc(movement.updatedAt).local().valueOf()};
      const salt = this.props.match ? this.props.match.params.salt : null;
      APIService.freights().contracts(movement.id)['reject']().put(data, salt)
        .then((json) => {
          if(!isEmpty(json.errors)){
            if(!isEmpty(json.errors['rejectionReason'])){
              this.setErrors(json.errors['rejectionReason']);
            } else {
              this.setErrors(json.errors);
            }
          } else {
            if(json.status === 'rejected'){
              this.setState({ isRejected: true });
            }
          }
        });
    }

    event.preventDefault();
  }

  handleChange(event) {
    const value = event.target.value;

    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  getIsFormValid() {
    return this.state.rejectionReason.errors.length == 0;
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            <form onSubmit={this.handleSubmit} noValidate>
              { this.state.movement && this.state.canReject && !this.state.isRejected ? (
                <div>
                  <h4 className="reject-title">Reject Freight Movement</h4>
                  <CommonTextField
                    label="Reason"
                    placeholder="Please enter your reason for rejection"
                    id="rejectReason"
                    multiline
                    className="reject-reason-field"
                    InputProps={{
                      disableUnderline: true,
                    }}
                    value={this.state.rejectionReason.value}
                    onChange={this.handleChange}
                    helperText={get(this.state, 'rejectionReason.errors[0]', '')}
                    errorStyle={{textAlign: "left"}}
                    fullWidth
                    maxLength="255"
                    rows={5}
                  />
                  <CommonButton
                    type="submit"
                    variant="contained"
                    label="REJECT"
                    primary
                  />
                </div>
              ) : (
                this.state.movement && this.state.isRejected ? (
                  <span>Freight Movement is Rejected</span>
                ) : this.state.loaded && (
                  <span>You do not have permission to access the information.</span>
                )
              )}
            </form>
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.
        </div>
      </div>
    </div>;
  }
}

export default connect()(UnregisteredUserRejectMovement);

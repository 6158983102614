import React, { Component } from 'react';

import { connect } from 'react-redux';
import CompanySitesTable from '../../containers/CompanySitesTable';
import CreateCompanySite from '../../containers/CreateCompanySite';
import UpdateCompanySite from '../../containers/UpdateCompanySite';
import { getCompanySites } from '../../actions/api/company-sites';
import { getCompanyDetails, showSideDrawerForTransfer, showSideDrawerForMerge } from '../../actions/companies/index';
import {
  receiveCompanySites,
  clickAddCompanySiteButton,
  cancelEditCompanySite
} from '../../actions/companies/company-sites';
import AddButton from '../common/AddButton';
import Paper from '@mui/material/Paper';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { get, cloneDeep, find } from 'lodash';
import SideDrawer from '../common/SideDrawer';
import { isCompanyEditable } from "../../common/utils";
import TransferToOtherCompany from '../../containers/TransferToOtherCompany';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import CommonButton from '../common/CommonButton';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';

class CompanySitesListing extends Component {
  constructor(props) {
    super(props);
    this.companyId = this.props.companyId;
    this.state = {
      openSideDrawer: false,
      openMergeSideDrawer: false,
      mergeToSite: {
        value: undefined,
        validators: [],
        errors: []
      }
    };
    this.openSideDraw = this.openSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.closeMergeDrawer = this.closeMergeDrawer.bind(this);
  }


  componentDidMount() {
    this.props.getCompanySites(this.companyId, !this.props.isArchived);
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
    }
    this.props.cancelMergeDrawer;
  }

  shouldFetchCompany() {
    return !this.props.selectedCompany || (this.props.selectedCompany.id !== this.props.companyId);
  }

  componentDidUpdate() {
    if (this.shouldFetchCompany()) {
      this.props.getCompany(this.props.companyId);
      this.props.getCompanySites(this.props.companyId, !this.props.isArchived);
    }
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    if (dispatch) {
      dispatch(receiveCompanySites([]));
    }
    this.props.cancelMergeDrawer(null, false);
  }

  openSideDraw() {
    this.props.handleAddCompanySiteButtonClick();
    this.setState({ openSideDrawer: true, });
  }

  closeSideDraw() {
    this.setState({ openSideDrawer: false, });
  }

  canAccessAny() {
    return isCompanyEditable(this.props.selectedCompany);
  }

  handleSiteChange(value) {
    const newState = {...this.state};
    newState.mergeToSite.value = value;
    newState.mergeToSite.errors = [];
    this.setState(newState);
  }

  handleSubmit() {
    let data = {};
    data['mergeFrom'] = get(this.props.selectedAsset, 'id');
    if (data['mergeFrom']) {
      APIService.farms()
        .appendToUrl(`${this.state.mergeToSite.value}/merge/`)
        .post(data)
        .then(res => {
          if (get(res, 'alert')) alertifyjs.error(get(res, 'alert'))
          else alertifyjs.success('Site Merged!', 3, () => window.location.reload())
        });
    }
  }

  getSites = () => {
    let sites = cloneDeep(this.props.companySites);
    sites.splice(sites.findIndex(item => item.id === get(this.props, 'selectedAsset.id')), 1);
    return sites;
  }

  getLabel = () => {
    let fromSiteName = get(this.props, 'selectedAsset.name');
    let toSiteName = "";
    if (this.state.mergeToSite.value) {
      toSiteName = get(find(this.props.companySites, {id: this.state.mergeToSite.value}), 'name');
    }
    return "Merge " + fromSiteName + " into " + toSiteName;
  }

  closeMergeDrawer() {
    const newState = {...this.state};
    newState.mergeToSite.value = undefined;
    this.setState(newState);
    this.props.cancelMergeDrawer();
  }

  render() {
    return (
      <Paper className="paper-table">
        <div>
          {(this.canAccessAny() && this.props.canEdit) ?
           <AddButton label="Site" onClick={this.openSideDraw} app="site" /> : null
          }
          <CompanySitesTable isArchived={this.props.isArchived} siteCompanyId={this.companyId}/>
          {this.props.isCreateFormDisplayed &&
           <SideDrawer
             isOpen={this.state.openSideDrawer}
             title="Add Site"
             size="small"
             onClose={this.closeSideDraw}
             app="site"
           >
             <CreateCompanySite closeDrawer={this.closeSideDraw} canAccessAny={this.canAccessAny()} companyId={this.companyId} />
           </SideDrawer>
          }
          {
            (this.canAccessAny() && this.props.canEdit) ?
              <SideDrawer
                isOpen={this.props.isUpdateFormDisplayed}
                title="Edit Site"
                onClose={this.props.cancelEditCompanySite}
                app="site"
              >
                <UpdateCompanySite canAccessAny={this.canAccessAny()} companyId={this.companyId} />
              </SideDrawer>
            : null
          }
          {
            <SideDrawer
              isOpen={this.props.openSideDrawer}
              title="Transfer"
              onClose={this.props.cancelTransferDrawer}
              app='office'
            >
              <TransferToOtherCompany />
            </SideDrawer>

          }
          {this.props.openMergeSideDrawer &&
           <SideDrawer
             isOpen={this.props.openMergeSideDrawer}
             title="Merge"
             onClose={this.closeMergeDrawer}
           >
             <div style={{ paddingTop: "15px"}}>
               <CommonAutoSelect
                 id='mergeToSite'
                 items={this.getSites()}
                 label={this.getLabel()}
                 value={this.state.mergeToSite.value}
                 onChange={this.handleSiteChange}
               />
             </div>
             {
               <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }}>
                 <CommonButton
                   type="button"
                   label="Cancel"
                            default
                            onClick={this.closeMergeDrawer}
                            variant="contained"
                        />
                        <CommonButton
                            type="submit"
                            label="Merge"
                            primary
                            onClick={this.handleSubmit}
                            variant="contained"
                        />
                       </div>
                      }
                    </SideDrawer>
                    }
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        openSideDrawer: state.companies.companies.showTransferSideDrawer,
        openMergeSideDrawer: state.companies.companies.showMergeSideDrawer,
        isCreateFormDisplayed: state.companies.companySites.isCreateFormDisplayed,
        currentUserCompanyId: state.main.user.user.companyId,
        selectedCompany: state.companies.companies.selectedCompany,
        isUpdateFormDisplayed: state.companies.companySites.isUpdateFormDisplayed,
        headerText: state.main.headerText,
        breadcrumbs: state.main.breadcrumbs,
        companySites: state.companies.companySites.items,
        count: get(state.companies.companySites, 'items.length', 0),
        selectedAsset: state.companies.companies.selectedAsset
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        cancelTransferDrawer: () => dispatch(showSideDrawerForTransfer(false)),
        cancelMergeDrawer: () => dispatch(showSideDrawerForMerge(false)),
      getCompanySites: (companyId, isActive) => dispatch(getCompanySites(companyId, receiveCompanySites, null, '?user_properties=is_associated,is_managed_by_user,is_in_user_directory,is_highlighted,is_pending_request,is_pending_request_for_grower&properties=company_slot_order_booking', isActive)),
        getCompany: (companyId) => dispatch(getCompanyDetails(companyId)),
        handleAddCompanySiteButtonClick: () => dispatch(clickAddCompanySiteButton()),
        setHeaderText: (text) => dispatch(setHeaderText(text)),
        setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
        cancelEditCompanySite: () => dispatch(cancelEditCompanySite())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CompanySitesListing);

import React from 'react';
import { connect } from 'react-redux';

import { isArray, get, isEmpty, findIndex, includes } from 'lodash';
import APIService from '../../../services/APIService';
import LoaderInline from '../../LoaderInline';
import {
  AccordionDetails, AccordionSummary, Accordion,
  Button, TableBody, Table, TableCell, TableHead, TableRow
} from '@mui/material';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { RejectionReasonDialog } from "../../rejections/RejectionReasonDialog";
import { required } from "../../../common/validators";
import { voidAcceptInvoice, voidRejectInvoice } from "../../../actions/companies/invoices";
import { formatPrice, toDateFormat, getCountryLabel, openURLInNewTab } from "../../../common/utils";
import { PENDING_PAYMENT_STATUSES } from '../constants';


class InvoicePendingVoidRequestGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rejectDialogOpen: false,
      isFetching: true,
      invoices: [],
      selectedInvoice: null,
      rejectionReason: {
        value: undefined,
        validators: [required()],
        errors: []
      },

    };
    this.setReasonErrors = this.setReasonErrors.bind(this);
    this.getReasonErrors = this.getReasonErrors.bind(this);
    this.handleRejectClickOpen = this.handleRejectClickOpen.bind(this);
    this.handleReasonChange = this.handleReasonChange.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
    this.handleRejectClose = this.handleRejectClose.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  acceptConfirm(event, invoice) {
    event.stopPropagation();
    event.preventDefault();
    this.setState({ selectedInvoice: invoice });
    this.props.dispatch(
      voidAcceptInvoice(
        invoice.id,
        false,
      )
    );
    this.setState({ rejectDialogOpen: false });
  }

  getData() {
    APIService.invoices().appendToUrl('void/pending/').get().then(invoices => {
      const isOk = isArray(invoices);
      this.setState({ isFetching: !isOk, invoices: isOk ? invoices : [] }, () => {
        this.props.onDataLoad(this.state.invoices.length);
      });
    });
  }

  componentDidUpdate() {
    this.removeSuccessfulInvoiceFromList();
    const responseInvoiceId = get(this.props, 'responseInvoice.id');
    const selectedInvoiceId = get(this.state, 'selectedInvoice.id');
    if (responseInvoiceId && selectedInvoiceId && selectedInvoiceId === responseInvoiceId &&
      (this.state.rejectionReason.value || !this.state.rejectionReason.validators.length)
    ) {
      this.setState({
        rejectionReason: {
          value: undefined,
          validators: [required()],
          errors: []
        }
      }
      );
    }
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.invoices.length})`;
    return `Pending Void Request ${suffix}`;
  }

  getColumnValue(value) {
    return value === 'None (None)' || !value ? '-' : value;
  }

  handleRejectClickOpen = (event, invoice) => {
    event.stopPropagation();
    event.preventDefault();
    const newState = { ...this.state };
    newState.rejectDialogOpen = true;
    newState.selectedInvoice = invoice;
    if (!newState.rejectionReason.value) {
      newState.rejectionReason.errors = [];
    }
    this.setState(newState);
  };

  handleRejectClose = () => {
    this.setState({ rejectDialogOpen: false });
  };

  handleReasonChange(event) {
    const value = event.target.value;

    const newState = { ...this.state };
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  }

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }


  handleRejectSubmit() {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    const invoice = this.state.selectedInvoice;
    if (this.state.rejectionReason.errors.length === 0) {
      const { dispatch } = this.props;
      dispatch(voidRejectInvoice(
        invoice.id,
        data,
        false)
      );
      this.setState({ rejectDialogOpen: false });
    }
  }

  removeSuccessfulInvoiceFromList() {
    const { responseInvoice } = this.props;
    const { selectedInvoice, invoices } = this.state;
    const status = get(responseInvoice, 'status');
    if (
      selectedInvoice &&
      get(responseInvoice, 'id') === selectedInvoice.id &&
      !isEmpty(invoices)
    ) {
      const index = findIndex(invoices, { id: responseInvoice.id });
      this.setState({
        invoices: [...invoices.slice(0, index), ...invoices.slice(index + 1)],
        selectedInvoice: null,
        rejectDialogOpen: false,
      }, () => {
        this.props.onDataLoad(this.state.invoices.length);
      });
      if (status === 'confirmed' && includes(PENDING_PAYMENT_STATUSES, get(responseInvoice, 'statusDisplayName'))) {
        this.props.shuffleInvoice(responseInvoice, 'InvoiceInPendingPaymentAcceptanceGroup', 'push');
      }
    }
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    openURLInNewTab(`/#/invoices/${id}/details`);
  }

  onChange(event, expanded) {
    if(expanded && isEmpty(this.state.invoices))
      this.getData();
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{ margin: '0px' }} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{ padding: '0px' }}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Invoice No.</TableCell>
                      <TableCell align='center' className="xsmall">Bill To</TableCell>
                      <TableCell align='center' className="xsmall">Bill For</TableCell>
                      <TableCell align='center' className="xsmall">Type</TableCell>
                      <TableCell align='center' className="medium">Payment Due Date</TableCell>
                      <TableCell align='center' className="xsmall">Sub-Total</TableCell>
                      <TableCell align='center' className="xsmall">{getCountryLabel('gst')}</TableCell>
                      <TableCell align='center' className="medium">Total</TableCell>
                      <TableCell align='center' className="medium">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.invoices) ?
                      this.state.invoices.map(invoice => (
                        <TableRow
                          className="cursor-pointer row-with-data"
                          key={invoice.referenceNumber}
                          onClick={(event) => this.onRowClick(event, invoice.id)}
                          >
                          <TableCell className="xsmall">{this.getColumnValue(invoice.identifier)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.payerDisplayName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.payeeDisplayName)}</TableCell>
                          <TableCell align='center' className="xsmall">{this.getColumnValue(invoice.type)}</TableCell>
                          <TableCell align='center' className="medium">
                            {
                              toDateFormat(this.getColumnValue(invoice.paymentDueDate))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.subTotal))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.gst))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            {
                              formatPrice(this.getColumnValue(invoice.total))
                            }
                          </TableCell>
                          <TableCell align='center' className="xsmall">
                            <Button
                              variant="outlined"
                              size="small"
                              className="btn-red-outlined"
                              onClick={(event) => this.handleRejectClickOpen(event, invoice)}>
                              Reject
                            </Button>
                            <Button
                              variant="outlined"
                              color="primary"
                              size="small"
                              style={{ marginLeft: '8px' }}
                              onClick={(event) => this.acceptConfirm(event, invoice)}>
                              Accept
                            </Button>
                          </TableCell>
                        </TableRow>
                      )) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
        <RejectionReasonDialog
          open={this.state.rejectDialogOpen}
          onClose={this.handleRejectClose}
          title='Reject Void Invoice'
          value={this.state.rejectionReason.value}
          onChange={this.handleReasonChange}
          helperText={get(this.state, 'rejectionReason.errors[0]', '')}
          onCancel={this.handleRejectClose}
          onReject={this.handleRejectSubmit}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
  responseInvoice: state.companies.invoices.confirmedOrRejectedInvoice,
});

export default connect(mapStateToProps)(InvoicePendingVoidRequestGroup);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { get, includes, isEqual } from 'lodash';
import './contractDetails.scss';
import ContractDetailsCounterParties from './ContractDetailsCounterParties';
import ContractDetailsSection from './ContractDetailsSection';
import ContractDetailsBrokerageDetails from './ContractDetailsBrokerageDetails';
import ContractDetailsConditionSection from './ContractDetailsConditionSection';
import { setHeaderText, setSubHeaderText, setBreadcrumbs } from '../../../actions/main';
import { getContractSubHeaderText, currentUserCompany } from '../../../common/utils';
import { receiveOrder, receiveAllocation } from '../../../actions/companies/orders';
import { receiveFreight } from '../../../actions/companies/freights';
import { RejectionDetails } from '../../rejections/RejectionDetails';
import ContractAllocations from './ContractAllocations';
import ContractStockAllocations from './ContractStockAllocations';
import OrderNotes from '../../freights/order-details/OrderNotes';
import { DIRECT_TO_BUYER_ALLOCATION, STOCK_ALLOCATION, THROUGH_WAREHOUSE_ALLOCATION } from '../../../common/constants';

class ContractDetails extends Component {
  componentDidMount() {
    this.props.dispatch(receiveOrder(null));
    this.props.dispatch(receiveFreight(null));
    this.props.dispatch(receiveAllocation(null));
    this.setLayout();
  }

  componentDidUpdate() {
    this.setLayout();
  }

  setLayout() {
    if (this.props.contract) {
      const headerText = 'Commodity Contract ' + get(this.props.contract, 'referenceNumber', '');
      this.props.dispatch(setHeaderText(headerText));
      this.props.dispatch(setSubHeaderText(getContractSubHeaderText(this.props.contract)));

      const breadcrumbs = [{ text: 'Contracts', route: '/contracts' }, { text: get(this.props.contract, 'referenceNumber', '') }];
      if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
        this.props.dispatch(setBreadcrumbs(breadcrumbs));
      }
    }
  }

  static defaultProps = {
    contract: null,
  };

  componentWillUnmount() {
    this.props.dispatch(setHeaderText(null));
    this.props.dispatch(setSubHeaderText(null));
  }

  render() {
    const { contract } = this.props;
    const showDirectToBuyerAllocation = includes(currentUserCompany().contractAllocations, DIRECT_TO_BUYER_ALLOCATION);
    const showStockAllocation = includes(currentUserCompany().contractAllocations, STOCK_ALLOCATION);
    const showThroughWarehouseAllocation = includes(currentUserCompany().contractAllocations, THROUGH_WAREHOUSE_ALLOCATION);
    const note  = get(this.props, 'contract.note');
    return (
      <div className='contract-details-container'>
        {contract && (
          <div>
            {contract.requestReasonDisplay && contract.requestReasonDisplay.requestReason && (
              <RejectionDetails rejectionReasonObject={contract.requestReasonDisplay} />
            )}
            {contract.rejectionReasonDisplay && contract.rejectionReasonDisplay.rejectionReason && (
              <RejectionDetails rejectionReasonObject={contract.rejectionReasonDisplay} />
            )}
            {
              (showDirectToBuyerAllocation || showThroughWarehouseAllocation) && Boolean(contract?.id) &&
                <React.Fragment>
                  {
                    contract.isSeller &&
                      <ContractAllocations {...this.props} header='Contract Allocations' isSaleContract />
                  }
                  {
                    contract.isBuyer && !contract.isSeller &&
                    <ContractAllocations {...this.props} header='Contract Allocations' isPurchaseContract />
                  }
                </React.Fragment>
            }
            {
              showStockAllocation && contract.isSeller &&
              <ContractStockAllocations {...this.props} header='Stock Allocations' />
            }
            <ContractDetailsCounterParties {...this.props} />
            <ContractDetailsSection {...this.props} />
            <ContractDetailsBrokerageDetails {...this.props} />
            <ContractDetailsConditionSection {...this.props} />
            {note && <OrderNotes {...this.props} entity={get(this.props, 'contract')}/>}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    breadcrumbs: state.main.breadcrumbs,
  };
};

export default connect(mapStateToProps)(ContractDetails);

import React from 'react';

class Preview extends React.Component {
  render() {
    const { data } = this.props;
    return (
      <object width="100%" height="100%" type="application/pdf" data={data}>
        <p>Some error occurred. Please refresh the page.</p>
      </object>
    );
  }
}

export default Preview;

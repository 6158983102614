import React from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isFunction from 'lodash/isFunction';
import has from 'lodash/has';


class OptionMenu extends React.Component {
  state = {
    anchorEl: null,
  };

  handleClick = event => {
    if (has(this.props, 'stopPropagation'))
      event.stopPropagation()
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleOptionClick = (event, index, key, id, item,) => {
    this.handleClose();
    if (has(this.props, 'stopPropagation'))
      this.props.handleOptionClick(event, index, key, id , item);
    else
      this.props.handleOptionClick(index, key, id , item);
  };

  render() {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    return (
      <div>
        <IconButton
          aria-label="More"
          aria-owns={open ? 'long-menu' : null}
          aria-haspopup="true"
          onClick={this.handleClick}
          size={this.props.iconSize || "small"}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleClose}
          PaperProps={{
            style: {
              minWidth: 150,
            },
          }}
        >
          {
            (isArray(this.props.optionsItems) && !isEmpty(this.props.optionsItems)) || isFunction(this.props.optionsItems) ? (
              (isArray(this.props.optionsItems) ? this.props.optionsItems : this.props.optionsItems(this.props.item)).map(option => {
                const shouldOptionBeDisabled = this.props.shouldOptionBeDisabled ? this.props.shouldOptionBeDisabled(option) : false;
                return (
                  has(this.props, 'shouldOptionBeEnabled') && this.props.shouldOptionBeEnabled ?
                  (
                    (this.props['shouldOptionBeEnabled'](option, this.props.item, this.props.currentUser)) ?
                    <MenuItem
                      disabled={shouldOptionBeDisabled}
                      key={option.key}
                      onClick={(event) => this.handleOptionClick(
                        event,
                        this.props.item.index,
                        option.key,
                        this.props.item.id,
                        this.props.item,)}
                      >{option.text}</MenuItem>
                    : '')
                  : <MenuItem
                      disabled={shouldOptionBeDisabled}
                      key={option.key}
                      onClick={(event) => this.handleOptionClick(
                        event,
                        this.props.item.index,
                        option.key,
                        this.props.item.id,
                        this.props.item,
                      )}
                    >{option.text}</MenuItem>
                );
              })) : ''
          }
        </Menu>
      </div>
    );
  }
}

export default OptionMenu;

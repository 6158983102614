import { connect } from 'react-redux';
import InloadForm from '../components/inloads/InloadForm';
import { createStorageInload } from '../actions/api/inloads';
import { addInload } from '../actions/companies/inloads';

function submit(storageId, data, successCallback) {
  return (dispatch) => {
    dispatch(createStorageInload(storageId, data, addInload, successCallback));
  };
}

export default connect(
  null,
  { submit }
)(InloadForm);

import React from 'react';

import { Button } from '@mui/material';
import { merge, has, omit, includes } from 'lodash';

const CommonButton = props => {
  let color = props.color || 'secondary';
  if(has(props, 'primary'))
    color = 'primary';
  let variant = includes(['flat', 'raised'], props.variant) ? 'contained' : props.variant;
  return (
    <Button
      className='common-button'
      type={props.type || "button"}
      onClick={props.onClick}
      disabled={props.disabled}
      style={merge({margin: '5px 10px', minWidth: '100px'}, props.style || {})}
      {...omit(props, ['primary', 'secondary', 'variant'])}
      variant={variant || 'contained'}
      color={color}
    >
      {props.label}
    </Button>
  );
};

export default CommonButton;

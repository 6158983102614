import {
  GET_FREIGHT_CONTRACTS_SUCCESS,
  GET_FIXTURES_SUCCESS,
  ADD_CONTRACT_FAILURE,
  START_FETCHING_SELECTED_FREIGHT,
  RESET_IS_FETCHING_SELECTED_FREIGHT,
  RECEIVE_FREIGHT, GET_PAGINATED_FREIGHT_CONTRACTS_SUCCESS, IS_FREIGHT_CONTRACTS_FETCHED,
  RECEIVE_FREIGHT_CONTRACT,
  CONFIRMATION_SUCCESS,
  REJECTION_SUCCESS,
  SET_FREIGHT_MOVEMENT_ID,
  SET_FREIGHT_MOVEMENT,
  CLEAR_FREIGHT_CONTRACT,
  FREIGHT_CONTRACT_OPTIONS, CAN_MARK_COMPLETE_FOR_SELECTED_MOVEMENT_ID,
  REJECT_LOAD_FOR_MOVEMENT_ID, VOIDABLE_SELECTED_FREIGHT_MOVEMENT_ID,
  VOIDABLE_LOAD,
  COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED,
  FREIGHT_CONTRACT_INVOICE_ITEMS_FETCHED, SHOULD_FETCH_MOVEMENT, CLICKED_OPTION, CAN_ASSIGN_FOR_SELECTED_MOVEMENT_ID,
  CAN_VOID_AND_DUPLICATE_FREIGHT_MOVEMENT
} from '../../actions/companies/freights';
import pick from 'lodash/pick';

const initialState = {
  items: [],
  types: [],
  paymentTerms: [],
  statuses: [],
  selectedFreightId: undefined,
  selectedFreight: undefined,
  serverErrors: [],
  isFetchingSelectedFreight: false,
  isItemsFetched: false,
  confirmedOrRejectedContract: undefined,
  selectedFreightMovementId: undefined,
  subItems: undefined,
  canMarkCompleteForSelectedMovementId: false,
  canAssignForSelectedMovementId: false,
  rejectLoadForMovementId: undefined,
  voidableSelectedFreightMovementId: undefined,
  voidableLoad: undefined,
  assignToContractOrFreightOrderId: undefined,
  shouldFetchMovement: false,
  clickedOption: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FREIGHT_CONTRACTS_SUCCESS:
      return {
        ...state,
        items: action.contracts,
        serverErrors: [],
      };
    case IS_FREIGHT_CONTRACTS_FETCHED:
      return {
        ...state,
        isItemsFetched: action.isFetched,
      };
    case GET_PAGINATED_FREIGHT_CONTRACTS_SUCCESS:
      return {
        ...state,
        items: action.paginatedContracts.results,
        paginationData: action.paginatedContracts
      };
    case START_FETCHING_SELECTED_FREIGHT:
      return {
        ...state,
        isFetchingSelectedFreight: true,
      };
    case RESET_IS_FETCHING_SELECTED_FREIGHT:
      return {
        ...state,
        isFetchingSelectedFreight: false,
      };
    case RECEIVE_FREIGHT:
      return {
        ...state,
        selectedFreight: action.item,
        selectedFreightId: action.item ? action.item.id : null,
        serverErrors: [],
        isFetchingSelectedFreight: false,
      };
    case RECEIVE_FREIGHT_CONTRACT:
      return {
        ...state,
        selectedFreightContract: action.item,
        selectedFreightContractId: action.item ? action.item.id : null,
        serverErrors: [],
        isFetchingSelectedFreightContract: false,
      };
    case GET_FIXTURES_SUCCESS:
      return {
        ...state,
        types: action.fixtures.freightContractType,
        paymentTerms: action.fixtures.paymentTerm,
        statuses: action.fixtures.statuses,
        serverErrors: [],
      };
    case ADD_CONTRACT_FAILURE:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CONFIRMATION_SUCCESS:
      return {
        ...state,
        confirmedOrRejectedContract: action.contract,
        packSiteCompanyId: action.contract.assignTo?.companyId,
        serverErrors: action.errors,
      };
    case REJECTION_SUCCESS:
      return {
        ...state,
        confirmedOrRejectedContract: action.contract,
        serverErrors: action.errors,
      };
    case SET_FREIGHT_MOVEMENT_ID:
      return {
        ...state,
        selectedFreightMovementId: action.movementId,
        confirmedOrRejectedContract: undefined,
        canMarkCompleteForSelectedMovementId: false,
        rejectLoadForMovementId: undefined,
      };
    case SET_FREIGHT_MOVEMENT:
      return {
        ...state,
        selectedFreightMovement: action.movement,
      };
    case CLEAR_FREIGHT_CONTRACT:
      return {
        ...state,
        selectedFreightMovementId: undefined,
        confirmedOrRejectedContract: undefined,
        canMarkCompleteForSelectedMovementId: false,
        canAssignForSelectedMovementId: false,
        rejectLoadForMovementId: undefined,
        subItems: undefined,
        voidableSelectedFreightMovementId: undefined,
        voidableLoad: undefined,
        assignToContractOrFreightOrderId: undefined,
        shouldFetchMovement: false,
      };
    case FREIGHT_CONTRACT_OPTIONS:
      return {
        ...state,
        subItems: action.subItems,
      };
    case CAN_MARK_COMPLETE_FOR_SELECTED_MOVEMENT_ID:
      return {
        ...state,
        canMarkCompleteForSelectedMovementId: action.flag,
        rejectLoadForMovementId: undefined,
      };
    case CAN_ASSIGN_FOR_SELECTED_MOVEMENT_ID:
      return {
        ...state,
        canAssignForSelectedMovementId: action.flag,
      };
    case REJECT_LOAD_FOR_MOVEMENT_ID:
      return {
        ...state,
        rejectLoadForMovementId: action.movementId,
        canMarkCompleteForSelectedMovementId: undefined,
      };
    case VOIDABLE_SELECTED_FREIGHT_MOVEMENT_ID:
      return {
        ...state,
        voidableSelectedFreightMovementId: action.movementId,
      };
    case CAN_VOID_AND_DUPLICATE_FREIGHT_MOVEMENT:
      return {
        ...state,
        canVoidAndDuplicateFreightMovement: action.flag,
      }
    case VOIDABLE_LOAD:
      return {
        ...state,
        voidableLoad: {
          loadId: action.loadId,
          loadType: action.loadType,
        }
      };
    case COMMODITY_CONTRACT_INVOICE_ITEMS_FETCHED:
      return {
        ...state,
        ...pick(action, ['isFetchedTTs', 'isFetchedFMs'])
      };
    case FREIGHT_CONTRACT_INVOICE_ITEMS_FETCHED:
      return {
        ...state,
        isFetchedFreightContracts: action.isFetchedFreightContracts
      };
    case SHOULD_FETCH_MOVEMENT:
      return {
        ...state,
        shouldFetchMovement: action.flag,
      };
  case CLICKED_OPTION:
    return {
      ...state,
      clickedOption: action.option
    };
    default:
      return {
        ...state
      };
  }
};

export default reducer;

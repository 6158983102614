export const RECEIVE_TRUCKS = 'RECEIVE_TRUCKS';
export const RECEIVE_TRUCKS_WEB = 'RECEIVE_TRUCKS_WEB';
export const RECEIVE_TRAILERS = 'RECEIVE_TRAILERS';
export const RECEIVE_USER_TRUCKS = 'RECEIVE_USER_TRUCKS';
export const ADD_TRUCK = 'ADD_TRUCK';
export const EDIT_TRUCK = 'EDIT_TRUCK';
export const CANCEL_EDIT_TRUCK = 'CANCEL_EDIT_TRUCK';
export const CLICK_ADD_TRUCK_BUTTON = 'CLICK_ADD_TRUCK_BUTTON';
export const CLICK_EDIT_TRUCK_ICON = 'CLICK_EDIT_TRUCK_ICON';
export const ADD_TRAILER = 'ADD_TRAILER';
export const EDIT_TRAILER = 'EDIT_TRAILER';
export const CANCEL_EDIT_TRAILER = 'CANCEL_EDIT_TRAILER';
export const CLICK_ADD_TRAILER_BUTTON = 'CLICK_ADD_TRAILER_BUTTON';
export const CLICK_EDIT_TRAILER_ICON = 'CLICK_EDIT_TRAILER_ICON';
export const EMPTY_CREATED_TRUCK = 'EMPTY_CREATED_TRUCK';

export const receiveTrucks = items => ({
  type: RECEIVE_TRUCKS,
  items,
});

export const receiveTrucksWeb = result => ({
  type: RECEIVE_TRUCKS_WEB,
  result,
});

export const receiveTrailers = items => ({
  type: RECEIVE_TRAILERS,
  items,
});

export const receiveUserTrucks = items => ({
  type: RECEIVE_USER_TRUCKS,
  items,
});

export const addTruck = (item, id) => ({
  type: ADD_TRUCK,
  item,
  id,
});

export const emptyCreatedTruck = (id) => ({
  type: EMPTY_CREATED_TRUCK,
  id,
});

export const editTruck = item => ({
  type: EDIT_TRUCK,
  item,
});

export const cancelEditTruck = () => ({
  type: CANCEL_EDIT_TRUCK,
});

export const clickAddTruckButton = () => ({
  type: CLICK_ADD_TRUCK_BUTTON,
});

export const clickEditTruckIcon = index => ({
  type: CLICK_EDIT_TRUCK_ICON,
  index,
});

export const addTrailer = item => ({
  type: ADD_TRAILER,
  item,
});

export const editTrailer = item => ({
  type: EDIT_TRAILER,
  item,
});

export const cancelEditTrailer = () => ({
  type: CANCEL_EDIT_TRAILER,
});

export const clickAddTrailerButton = () => ({
  type: CLICK_ADD_TRAILER_BUTTON,
});

export const clickEditTrailerIcon = index => ({
  type: CLICK_EDIT_TRAILER_ICON,
  index,
});

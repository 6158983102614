import { GET_COMPANY_APPROVED_BUYERS } from '../../actions/company-settings/approved-buyers';
import { get } from 'lodash';
const initialState = {
    items: [],
    paginationData: [],
};


const reducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_COMPANY_APPROVED_BUYERS:
            return {
                ...state,
                items: get(action.items, 'results', get(action, 'items', [])),
                paginationData: action.items,
            };
        default:
            return state;
    }
};

export default reducer;
import { connect } from 'react-redux';
import CompanyToCompany from '../components/companies/CompanyToCompany';
import { transferCompanyAsset } from '../actions/api/companies';
import { showSideDrawerForTransfer } from '../actions/companies/index';


function submit(companyId, data) {
  return (dispatch) => {
    dispatch(transferCompanyAsset(companyId, data));
  };
}

const mapStateToProps = state => {
  return {
    selectedAsset: state.companies.companies.selectedAsset
  };
};

const mapDispatchToProps = dispatch => {
  return {
    submit: (companyId, data) => dispatch(submit(companyId, data)),
    cancel: () => dispatch(showSideDrawerForTransfer(false)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyToCompany);

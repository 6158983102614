import React from 'react';
import { connect } from "react-redux";
import GenericTable from "../components/GenericTable";
import {
    getSelectedOrder,
    receiveOrder,
    getPaginatedOrdersResponse,
    getPackOrders,
  } from "../actions/companies/orders";
import { includes } from 'lodash';
import { PACK_ORDERS_HEADERS } from "../common/constants";
import FreightOrderActions from '../components/freights/FreightOrderActions';
import { getFreightOrderActionMenuOptions, regenerateOrderPDF } from '../components/freights/utils';
import APIService from '../services/APIService';
import {isLoading} from "../actions/main";
import { handleFreightOrderOptionsMapper } from "../actions/companies/orders";
import { get } from 'lodash';

class PackOrdersTable extends React.Component {
    render() {
        return (
            <div>
              <GenericTable {...this.props} />
              <FreightOrderActions {...this.props}/>
            </div>
        );
    }
}

const mapStateToProps = state => {
  const subItems = state.companies.orders.subItems;
  const clickedOption = state.companies.orders.clickedOption;
  const token = state.main.user.token;
  return {
    canRaiseVoidRequestForOrder: state.companies.orders.canRaiseVoidRequestForOrder,
    canRaiseVoidAndDuplicateRequestForOrder: state.companies.orders.canRaiseVoidAndDuplicateRequestForOrder,
    clearSearch: getPaginatedOrdersResponse,
    columns: PACK_ORDERS_HEADERS,
    currentUser: state.main.user.user,
    items: state.companies.orders.cogOrders,
    globalSearch: true,
    optionsItems: item => getFreightOrderActionMenuOptions(item, subItems, clickedOption),
    order: 'desc',
    orderBy: 'createdAt',
    paginationData: state.companies.orders.paginationData,
    rowHighlightedMap: {'isHighlighted': 'delhi-blue'},
    scrollToTopOnUpdate: false,
    selectedOrderId: state.companies.orders.selectedOrderId,
    selectedOrder: state.companies.orders.selectedOrder,
    useNestedOptionMenu: true,
    clickedOption: clickedOption,
    shouldFetchOrder: state.companies.orders.shouldFetchOrder,
    subItems: subItems,
    token,
    canCloseOutForOrder: state.companies.orders.canCloseOutForOrder,
    voidFilter: true
  };
};

const mapDispatchToProps = dispatch => {
    return {
      handleOptionClick: (event, item, baseEntity) => {
        if(get(item, 'key') === 'regenerate_pdf')
          return regenerateOrderPDF(baseEntity);
        else
          dispatch(handleFreightOrderOptionsMapper(event, item, baseEntity));
      },
      handleDefaultCellClick: (item) => {
        dispatch(getSelectedOrder(item.id, receiveOrder, false, false, false, false, true));
        dispatch(isLoading('orderDetail'));
        document.location.hash = '/pack/orders/' + item.id + '/order';
      },
      navigateTo: url => dispatch(getPackOrders(url, true)),
      changePageSize: (url, pageSize) => {
        if (includes(url, '?')){
          url = `${url}&page_size=${pageSize}`;
        } else {
          url = `${url}?page_size=${pageSize}`;
        }
        dispatch(getPackOrders( url, true));
      },
      getSearchSortUrl: (pageSize, page, searchText, orderBy, order, _, includeVoid) => {
        const service = APIService.freights().orders();
        service.appendToUrl(`web/search/?page_size=${pageSize}`);
        if (page) {
          service.appendToUrl(`&page=${page}`);
        }
        if(searchText) {
          service.appendToUrl(`&search=${searchText}`);
        }
        if(orderBy) {
          service.appendToUrl(`&order_by=${orderBy}&order=${order}`);
        }
        if(includeVoid) {
          service.appendToUrl(`&include_void=${includeVoid}`);
        }
        return service.URL;
      },
      dispatch
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackOrdersTable);

import React from 'react';
import { connect } from 'react-redux';
import { setHeaderText, setBreadcrumbs, forceStopLoader } from '../../actions/main';
import { getCountryLabel } from '../../common/utils';
import './actionCentre.scss';
import isNumber from 'lodash/isNumber';
import CommonTabs from "../common/CommonTabs";
import find from "lodash/find";
import MovementsActionCentre from "./movements/MovementsActionCentre";
import ContractsActionCentre from "./contracts/ContractsActionCentre";
import OrdersActionCentre from "./order/OrdersActionCentre";
import InvoiceActionCentre from "./invoices/InvoiceActionCentre";
import VendorDecActionCentre from "./vendor-dec/VendorDecActionCentre";

class ActionCentre extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: this.props.location.pathname,

      contractsItemSum:0,
      movementsItemSum:0,
      ordersItemSum:0,
      invoiceItemSum:0,
      vendorDecItemSum:0,

      isMovementsTabMounted: false,
      isOrdersTabMounted: false,
      isInvoiceTabMounted: false,
      isVendorDecTabMounted: false,
    };

    this.handleTabChange = this.handleTabChange.bind(this);

    this.onContractItemsLoad = this.onContractItemsLoad.bind(this);
    this.onMovementItemsLoad = this.onMovementItemsLoad.bind(this);
    this.onOrderItemsLoad = this.onOrderItemsLoad.bind(this);
    this.onInvoiceItemsLoad = this.onInvoiceItemsLoad.bind(this);
    this.onVendorDecItemsLoad = this.onVendorDecItemsLoad.bind(this);

    this.setMovementsTabMounted = this.setMovementsTabMounted.bind(this);
    this.setOrdersTabMounted = this.setOrdersTabMounted.bind(this);
    this.setInvoiceTabMounted = this.setInvoiceTabMounted.bind(this);
    this.setVendorDecTabMounted = this.setVendorDecTabMounted.bind(this);
  }

  setMovementsTabMounted() {
    this.setState({isMovementsTabMounted: true});
  }

  setOrdersTabMounted() {
    this.setState({isOrdersTabMounted: true});
  }

  setInvoiceTabMounted() {
    this.setState({isInvoiceTabMounted: true});
  }

  setVendorDecTabMounted(){
    this.setState({isVendorDecTabMounted: true});
  }

  handleTabChange(value) {
    this.setState(state => ({ ...state, activeTab: value }));
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    return nextProps.location.pathname !== prevState.activeTab ?
      { ...prevState, activeTab: nextProps.location.pathname } :
      prevState;
  }

  componentDidMount() {
    this.props.dispatch(forceStopLoader());
    this.props.dispatch(setHeaderText(getCountryLabel('actionCentre')));
    this.props.dispatch(setBreadcrumbs([{text: getCountryLabel('actionCentre')}]));
  }


  onContractItemsLoad(count) {
    this.setState({contractsItemSum: isNumber(count) ? count : 0});
  }

  onMovementItemsLoad(count) {
    if(this.state.isMovementsTabMounted)
      this.setState({movementsItemSum: isNumber(count) ? count : 0});
  }

  onOrderItemsLoad(count) {
    if(this.state.isOrdersTabMounted)
      this.setState({ordersItemSum: isNumber(count) ? count : 0});
  }

  onInvoiceItemsLoad(count) {
    if(this.state.isInvoiceTabMounted)
      this.setState({invoiceItemSum: isNumber(count) ? count : 0});
  }

  onVendorDecItemsLoad(count){
    if(this.state.isVendorDecTabMounted)
      this.setState({vendorDecItemSum: isNumber(count) ? count : 0});
  }

  getTabSublabel(sum, mounted) {
    if(mounted)
      return `(${sum})`;
    else
      return '';
  }

  render() {
    const PARENT_URL = this.props.match.url;
    let tabs = [
        {
          label: 'Contracts',
          key: 'contractsActionCentre',
          url: `${PARENT_URL}`,
          component: () => <ContractsActionCentre {...this.props} />
        },
        {
          label: `Orders`,
          key: 'ordersActionCentre',
          url: `${PARENT_URL}/orders`,
          component: () => <OrdersActionCentre {...this.props} />
        },
        {
          label: `Movements`,
          key: 'movementsActionCentre',
          url: `${PARENT_URL}/movements`,
          component: () => <MovementsActionCentre {...this.props} />
        },
        {
          label: `Invoices`,
          key: 'invoicesActionCentre',
          url: `${PARENT_URL}/invoices`,
          component: () => <InvoiceActionCentre {...this.props} />
        },
        {
          label: `Vendor Declarations`,
          key: 'vendorDecActionCentre',
          url: `${PARENT_URL}/vendor-dec`,
          component: () => <VendorDecActionCentre {...this.props} />
        },
      ];

    return (
        <div className="tab">
          <div className="tab-header">
            <CommonTabs
              value={this.state.activeTab}
              tabs={tabs}
            />
          </div>
          <div className="tab-content">
            {
              this.state.activeTab === find(tabs, {key: 'contractsActionCentre'})['url'] &&
              <ContractsActionCentre
                {...this.props}
                existingCount={this.state.contractsItemSum}
                onParentDataLoad={this.onContractItemsLoad}
              />
            }
            {
              this.state.activeTab === find(tabs, {key: 'ordersActionCentre'})['url'] &&
              <OrdersActionCentre
                {...this.props}
                existingCount={this.state.ordersItemSum}
                onParentDataLoad={this.onOrderItemsLoad}
                onMount={this.setOrdersTabMounted}
              />
            }
            {
              this.state.activeTab === find(tabs, {key: 'movementsActionCentre'})['url'] &&
              <MovementsActionCentre
                {...this.props}
                existingCount={this.state.movementsItemSum}
                onParentDataLoad={this.onMovementItemsLoad}
                onMount={this.setMovementsTabMounted}
              />
            }
            {
              this.state.activeTab === find(tabs, {key: 'invoicesActionCentre'})['url'] &&
              <InvoiceActionCentre
                {...this.props}
                existingCount={this.state.invoiceItemSum}
                onParentDataLoad={this.onInvoiceItemsLoad}
                onMount={this.setInvoiceTabMounted}
              />
            }
            {
              this.state.activeTab === find(tabs, {key: 'vendorDecActionCentre'})['url'] &&
              <VendorDecActionCentre
                {...this.props}
                existingCount={this.state.vendorDecItemSum}
                onParentDataLoad={this.onVendorDecItemsLoad}
                onMount={this.setVendorDecTabMounted}
              />
            }
          </div>
        </div>
    );
  }
}

export default connect()(ActionCentre);

import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import { addStorageErrors, editStorageErrors, addSystemStorageErrors } from '../../actions/companies/storages';
import isEmpty from 'lodash/isEmpty';
import isArray from 'lodash/isArray';

const MESSAGES = {
  CREATE_SUCCESS: 'Home Storage was successfully added.',
  UPDATE_SUCCESS: 'Home Storage details were successfully updated.',
  CREATE_SYSTEM_STORAGE_SUCCESS: 'Storage was successfully added.',
};

export const getStorages = (farmId, actionCreator, queryString='') => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (farmId) {
    APIService.farms(farmId)
      .appendToUrl('storages/home/')
      .appendToUrl(queryString)
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createStorage = (farmId, data, actionCreator, successCallback) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    ['storages/home']()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        dispatch(addStorageErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        if(successCallback)
         successCallback(item);
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
      }
    });
};

export const updateStorage = (farmId, homeStorageId, data, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    ['storages/home'](homeStorageId)
    .put(data, token)
    .then(item => {
      if (!isEmpty(item.errors)) {
        dispatch(editStorageErrors(item.errors));
      } else {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
      }
    });
};

export const getSystemStorages = (farmId, actionCreator, queryString='') => (dispatch, getState) => {
  const { token } = getState().main.user;
  if (farmId) {
    APIService.farms(farmId)
      .appendToUrl('storages/system')
      .appendToUrl(queryString)
      .get(token)
      .then(items => {
        dispatch(actionCreator(items));
      });
  }
};

export const createSystemStorage = (farmId, data, callback, actionCreator) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.farms(farmId)
    ['storages/system']()
    .post(data, token)
    .then(item => {
      if (item.id == null) {
        if (isArray(item.errors)) {
          alertifyjs.error(item.errors[0]);
        } else {
          dispatch(addSystemStorageErrors(item.errors));
        }
      } else {
        if (callback){
          callback(data);
        }
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.CREATE_SYSTEM_STORAGE_SUCCESS);
      }
    });
};

export const getStoredCommodityDetails = (storageId, callback) => {
  if (storageId) {
    APIService.storages(storageId).appendToUrl('commodity-details/')
    .get()
    .then(item => {
      callback(item);
    });
  }
  else {
    callback([]);
  }
};

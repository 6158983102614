import { connect } from 'react-redux';
import InloadForm from '../components/inloads/InloadForm';
import { createInload } from '../actions/api/inloads';
import { isNumber, isObject } from 'lodash';

function submit(data, successCallback, func) {
  if(isNumber(data) && isObject(successCallback) && func) {
    data = successCallback;
    successCallback = func;
  }
  return (dispatch) => {
    dispatch(createInload(data, successCallback));
  };
}

export default connect(null, { submit })(InloadForm);

import { connect } from 'react-redux';
import NewCompanyFormYetToBeUpdatedToHandleCompanyUpdate from '../components/companies/NewCompanyFormYetToBeUpdatedToHandleCompanyUpdate';
import { addCompany } from '../actions/api/companies';

const mapStateToProps = state => ({
  token: state.main.user.token,
  userCompanyId: state.main.user.user.companyId,
});

const mapDispatchToProps = dispatch => ({
  submit: (data, callback) => dispatch(addCompany(data, callback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCompanyFormYetToBeUpdatedToHandleCompanyUpdate);

import { Button, Dialog, DialogActions, DialogContent, Divider, IconButton, InputAdornment } from '@mui/material';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import React, { Component } from 'react'
import { required } from '../../common/validators';
import CommonTextField from '../common/CommonTextField';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';

class CompanyNgrPortalDialog extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ngrPortalUserName: {
            value: null,
            validators: [required()],
            errors: [],
          },
          ngrPortalPassword: {
            value: null,
            validators: [required()],
            errors: [],
          },
          ngrPortalDetails: undefined,
          showPassword: false
        }
    this.handleChange = this.handleChange.bind(this);
    this.validateFields = this.validateFields.bind(this);
    this.submitData = this.submitData.bind(this);
    this.updateCredentials = this.updateCredentials.bind(this);
    this.unlinkCredentials = this.unlinkCredentials.bind(this);
    this.handlePasswordClick = this.handlePasswordClick.bind(this);
    this.portalPasswordRef = React.createRef();
    }

    componentDidMount() {
      if(get(this.props.ngrPortalDetails, 'isPortalLinked')) {
        const newState = { ...this.state };
        newState.ngrPortalUserName.value = get(this.props.ngrPortalDetails, 'username');
        newState.ngrPortalPassword.value = get(this.props.ngrPortalDetails, 'password');
        newState.ngrPortalDetails = this.props.ngrPortalDetails;
        this.setState(newState);
      }
    }

  validateFields() {
    const newState = { ...this.state };
    let errorCount = 0;
    forEach(newState, field => {
    if(get(field, 'validators')) {
      field.errors = [];
      field.validators.forEach(validator => {
        if (validator.isInvalid(field.value)) {
          field.errors.push(validator.message);
          errorCount++;
        }
      });
    }
    });
    this.setState(newState);
    if(errorCount>0){
      return false;
    } else {
      return true;
    }
  }

  handlePasswordClick() {
    this.setState({
      showPassword: !this.state.showPassword
    });
  }

  handleChange(event) {
    let field = event.target.name
    const newState = { ...this.state };
    if (isEqual(field, 'ngrPortalUserName')) {
      set(newState, 'ngrPortalUserName.value', event.target.value);
    } else if (isEqual(field, 'ngrPortalPassword')) {
      set(newState, 'ngrPortalPassword.value', event.target.value);
    }
    this.setState(newState);
}

  unlinkCredentials() {
    APIService.companies().appendToUrl(`${this.props.companyId}/ngrs/credentials/`)
    .delete().then(response => {
      if(get(response, 'error')) {
        alertifyjs.error(get(response, 'error'));
      } 
    else {
      this.props.handlePortalCredentials({});
      alertifyjs.success("Ngr Credentials Unlinked");
      this.props.onClose();
    }
    })
  }

  updateCredentials(event) {
    let payload = {}
    if(this.validateFields()) {
      if(!isEqual(this.state.ngrPortalUserName.value, get(this.state.ngrPortalDetails, 'username')))
        set(payload, 'username', this.state.ngrPortalUserName.value);
      if(!isEqual(this.state.ngrPortalPassword.value, get(this.state.ngrPortalDetails, 'password')))
        set(payload, 'password', this.state.ngrPortalPassword.value);

      APIService.companies().appendToUrl(`${this.props.companyId}/ngrs/credentials/`)
      .put(payload).then(response => {
        if(get(response, 'error')) {
          alertifyjs.error(get(response, 'error'));
        } 
        else {
          const updatedDetails = {
            username: this.state.ngrPortalUserName.value,
            password: this.state.ngrPortalPassword.value,
            isPortalLinked: true
          }
          this.props.handlePortalCredentials(updatedDetails);
          alertifyjs.success("Successfully updated NGR Portal Details");
          this.props.onClose();
        }
      });
    }
    event.preventDefault();
  }

  submitData(event) {
    if(this.validateFields()) {
      let payload = {
        username: this.state.ngrPortalUserName.value,
        password: this.state.ngrPortalPassword.value
      }
      APIService.companies().appendToUrl(`${this.props.companyId}/ngrs/credentials/`)
      .put(payload).then(response => {
        if(get(response, 'error')) {
          alertifyjs.error(get(response, 'error'));
        } else {
          set(payload, 'isPortalLinked', true);
          this.props.handlePortalCredentials(payload);
          alertifyjs.success("Successfully added NGR Portal Details");
          this.props.onClose();
        }
      })
    }
    event.preventDefault();
  }

  render() {
    return (
      <Dialog
        open onClose={this.props.onClose} aria-labelledby="form-dialog-title" id="complete-dialog-open" maxWidth="md">
        <DialogTitleWithCloseIcon
          onClose={this.props.onClose}
        >
        NGR Portal Credentials
        </DialogTitleWithCloseIcon>
        <Divider />
        <DialogContent style={{ borderBottom: ' 1px solid #e6e6e6', padding: '0px' }}>
        <form>
          <div className='cardForm cardForm--drawer' style={{ padding: '0 24px 0' }}>
            <div className='cardForm-content row' style={{ marginTop: '10px' }}>
              <div className='col-sm-12 form-wrap'>
                <CommonTextField
                  type='text'
                  id='ngrPortalUserName'
                  name='ngrPortalUserName'
                  label='Username'
                  placeholder='Username'
                  onChange={this.handleChange}
                  value={this.state.ngrPortalUserName.value}
                  helperText= {get(this.state, 'ngrPortalUserName.errors[0]', '')}
                  InputProps={{ autoComplete: 'off' }}
                  />
              </div>
              <div className='col-sm-12 form-wrap'>
                <CommonTextField
                  type={this.state.showPassword ? 'text' : 'password'}
                  id='ngrPortalPassword'
                  name='ngrPortalPassword'
                  label='Password'
                  placeholder='Password'
                  onChange={this.handleChange}
                  ref={this.portalPasswordRef}
                  value={this.state.ngrPortalPassword.value}
                  helperText= {get(this.state, 'ngrPortalPassword.errors[0]', '')}
                  InputProps={{ 
                    autoComplete: 'off',
                    endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="Toggle password visibility"
                        onClick={this.handlePasswordClick}
                        size="medium">
                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  )}}
                />
              </div>
            </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          {
          get(this.props.ngrPortalDetails, 'isPortalLinked') ? (
            <div>
            <Button type='button' variant="outlined" color="primary" onClick={this.props.onClose} >
              Cancel
            </Button>
            <Button style={{marginLeft: '10px'}} type='button' variant="contained" color="primary" onClick={this.unlinkCredentials} >
              Unlink
            </Button>
            <Button style={{marginLeft: '10px'}} type='button' variant="contained" color="primary" onClick={this.updateCredentials} >
              Update
            </Button>
            </div>
          ) :
            <Button type='button' variant="contained" color="primary" onClick={this.submitData} >
            Link NGR Account
            </Button>
          }
        </DialogActions>
      </Dialog>
    )
  }
}

export default CompanyNgrPortalDialog;

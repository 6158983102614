import findIndex from 'lodash/findIndex';
import {
  ADD_RELEASE_NOTE,
  EDIT_RELEASE_NOTE,
  CANCEL_EDIT_RELEASE_NOTE,
  CLICK_ADD_RELEASE_NOTE_BUTTON,
  CLICK_EDIT_RELEASE_NOTE_ICON,
  ADD_RELEASE_NOTE_ERRORS,
  EDIT_RELEASE_NOTE_ERRORS,
  RECEIVE_RELEASE_NOTES,
} from '../../actions/companies/release-notes';

const initialState = {
  items: [],
  selectedReleaseNoteId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case ADD_RELEASE_NOTE:
    return {
      ...state,
      items: [action.item, ...state.items],
      selectedReleaseNoteId: undefined,
      isCreateFormDisplayed: false,
      isUpdateFormDisplayed: false,
      serverErrors: [],
    };
  case RECEIVE_RELEASE_NOTES:
    return {
      ...state,
      items: action.items,
      serverErrors: [],
    };
  case ADD_RELEASE_NOTE_ERRORS:
    return {
      ...state,
      selectedReleaseNoteId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: true,
      serverErrors: action.errors,
    };
  case EDIT_RELEASE_NOTE: {
    const index = findIndex(state.items, { id: action.item.id });
    return {
      items: [
        ...state.items.slice(0, index),
        action.item,
        ...state.items.slice(index + 1),
      ],
      selectedReleaseNoteId: undefined,
      isUpdateFormDisplayed: false,
    };
  }
  case EDIT_RELEASE_NOTE_ERRORS:
    return {
      ...state,
      serverErrors: action.errors,
    };
  case CANCEL_EDIT_RELEASE_NOTE:
    return {
      ...state,
      selectedReleaseNoteId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: false,
    };
  case CLICK_ADD_RELEASE_NOTE_BUTTON:
    return {
      ...state,
      selectedReleaseNoteId: undefined,
      isUpdateFormDisplayed: false,
      isCreateFormDisplayed: true,
    };
  case CLICK_EDIT_RELEASE_NOTE_ICON:
    return {
      ...state,
      selectedReleaseNoteId: action.index.id,
      isUpdateFormDisplayed: true,
      isCreateFormDisplayed: false,
    };
  default:
    return state;
  }
};

export default reducer;

export const TYPES = [
  {id: 'grain_commodity', name: 'Grain Commodity Vendor Declaration'},
  {id: 'truck_cleanliness', name: 'Truck Cleanliness and Prior Load Vendor Declaration'},
];

export const PER_TON_MEASUREMENT = 'Per Ton';

export const CLEANLINESS_QUESTIONS = [
  'Cleaning prior to loading', '2nd last cleaning prior to loading', '3rd last cleaning prior to loading',
];

export const OPTIONAL_CLEANLINESS_QUESTIONS = ['2nd last cleaning prior to loading', '3rd last cleaning prior to loading']

import React, {Component} from 'react';

import Tooltip from '@mui/material/Tooltip';

class Movement extends Component {
  render() {
    const height = this.props.height || 24;
    const width = this.props.width || 24;
    return <Tooltip title={this.props.title || "Freight Movements"} placement="right">
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 24 24" >
      <g fill="none" fillRule="evenodd">
        <path d="M0 0h24v24H0z"/>
        <path stroke={this.props.fill} strokeWidth="2" d="M19.578 9H17v8.003h6v-4.489L19.578 9z"/>
        <circle cx="7.5" cy="17.5" r="2" stroke={this.props.fill}/>
        <circle cx="19.5" cy="17.5" r="2" stroke={this.props.fill}/>
        <rect width="8" height="1" x="1" y="11" fill={this.props.fill} rx=".5"/>
        <rect width="8" height="1" y="13" fill={this.props.fill} rx=".5"/>
        <rect width="8" height="1" x="2" y="9" fill={this.props.fill} rx=".5"/>
        <path fill={this.props.fill} fillRule="nonzero" d="M5 7a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1h13a1 1 0 0 1 1 1v11a1 1 0 0 1-1 1H9.558l-.353-2H16V7H5zM5 17H4v-1h1.944l-.473 2H4a1 1 0 0 1-1-1v-1a1 1 0 0 1 2 0v1z"/>
      </g>
      </svg>
    </Tooltip>;
  }
}

export default Movement;

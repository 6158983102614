import { connect } from 'react-redux';
import GeneralConditionForm from '../components/conditions/GeneralConditionForm';
import { updateGeneralCondition, cancelEditGeneralCondition } from '../actions/companies/conditions';
import find from 'lodash/find';

function submit(data) {
  return (dispatch, getState) => {
    const generalConditionId = getState().companies.conditions.selectedGeneralConditionId;
    dispatch(updateGeneralCondition(generalConditionId, data));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedGeneralCondition: find(
      state.companies.conditions.generalConditions,
      { id: state.companies.conditions.selectedGeneralConditionId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (data) => dispatch(submit(data)),
    cancelEdit: () => dispatch(cancelEditGeneralCondition()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GeneralConditionForm);

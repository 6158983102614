import React, {Fragment, Component} from 'react';
import TextField from '@mui/material/TextField';
import FileUpload from './FileUpload';
import {get, isEmpty, reject, some} from 'lodash';

class Notes extends Component {

    handleTextChange = (e) => {
        const {note} = this.props;
        note.description = e.target.value;
        note.errors= (isEmpty(note.description) && !isEmpty(note.attachments)) ? ["Please add a note for the attachment"] : [];
        this.props.handler(note);
    };

    handleUpload = fileState => {
        const {note} = this.props;
        if (!some(note.attachments, attachment => attachment.name == fileState.file.name))
            note.attachments.push(fileState.file);
        note.errors = isEmpty(note.description) ? ["Please add a note for the attachment"] : [];
        this.props.handler(note);
    };

    handleFileRemove = base64 => {
        const {note} = this.props;
        note.attachments = reject(note.attachments, {base64: base64});
        note.errors = [];
        this.props.handler(note);
    };

    render() {
        return (
            <Fragment>
                <div className={this.props.className1}>{this.props.title}</div>
                <TextField
                    id="description"
                    className={this.props.className2}
                    value={get(this.props.note, 'description', undefined)}
                    fullWidth
                    style={{float: 'right'}}
                    onChange={this.handleTextChange}
                    multiline={true}
                    rows={3}
                    disabled={this.props.disabled}
                    error={!isEmpty(this.props.note.attachments) && isEmpty(this.props.note.description)}
                    helperText={get(this.props.note, 'errors[0]', '')}
                    variant="standard" />
                {
                    <FileUpload
                      className={this.props.className3}
                      id="attachments"
                      floatingLabelText="Attach File"
                      value={get(this.props.note, 'attachments', [])}
                      fullWidth={true}
                      textFieldstyle={{float: 'left', color: 'black'}}
                      mainStyle={{marginTop: '10px'}}
                      buttonStyle={{border: '1px solid'}}
                      onChange={this.handleUpload}
                      buttonText="Attach File"
                      allowedExtensions="image/*,application/pdf"
                      previewStyle={{marginTop: '10px', width: '150%'}}
                      onRemove={this.handleFileRemove}
                      disabled={this.props.disabled}
                    />
                }
            </Fragment>
        );
        }
}

Notes.defaultProps = {
    validate: false,
    disabled: false,
    hasAttachments: false,
    className1: 'field-label',
    className2: 'text-area',
    className3: 'col-md-4 col-xs-4',
};

export default Notes;


import React from 'react';
import MSnackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from 'react-redux';
import { setDownloadBar } from '../../actions/main';

class Snackbar extends React.Component {
  constructor(props) {
    super(props);

    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.props.dispatch(setDownloadBar(null, false));
  }

  componentWillUnmount() {
    this.props.dispatch(setDownloadBar(null, false));
  }

  render() {
    const { showDownloadBar, onDownloadBarClose, downloadBarMessage } = this.props;
    return (
      <MSnackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={showDownloadBar}
        onClose={onDownloadBarClose}
        ContentProps={{
          'aria-describedby': 'message-id',
        }}
        message={
          <span id="message-id" dangerouslySetInnerHTML={{__html: downloadBarMessage}}></span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={this.handleClose}
            size="large">
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
}
const mapStateToProps = state => {
  return {
    downloadBarMessage: state.main.downloadBarMessage,
    showDownloadBar: state.main.showDownloadBar,
    onDownloadBarClose: state.main.onDownloadBarClose,
  };
};

export default connect(mapStateToProps)(Snackbar);

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import APIService from '../../services/APIService';
import GenericTable from '../GenericTable';
import Paper from '@mui/material/Paper';

class InvoiceTransfersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openSideDrawer: false,
            invoice: null,
            transfers: [],
        };
        this.fetchTransfers = this.fetchTransfers.bind(this);
        this.handleDefaultCellClick = this.handleDefaultCellClick.bind(this);
    }

    handleDefaultCellClick = load =>  {
        if(load.freightMovementId)
          window.open(`#/freights/movements/${load.freightMovementId}/details`);
    }

    componentDidMount() {
        this.fetchTransfers();
    }

    fetchTransfers() {
        const { invoice } = this.props;
        APIService.invoices(invoice.id).appendToUrl('transfers/')
                  .get().then(data => this.setState({isLoaded: true, transfers: data || []}));
    }

    render() {
        const tonnage = obj => obj.tonnage + ' MT';
        const { transfers } = this.state;
        const COLUMNS = [
            {header: 'Date & Time', key: 'processOn'},
            {header: 'Transfers', key: 'identifier'},
            {header: 'Type', key: 'type', className: 'medium'},
            {header: 'Seller', key: 'sellerName', className: 'medium'},
            {header: 'Seller NGR', key: 'sellerNgr', className: 'small'},
            {header: 'Buyer', key: 'buyerName', className: 'medium'},
            {header: 'Buyer NGR', key: 'buyerNgr', className: 'small'},
            {header: 'Site', key: 'siteName', className: 'medium'},
            {header: 'Commodity', key: 'commodityName'},
            {header: 'Grade', key: 'gradeName', className: 'xsmall'},
            {header: 'Season', key: 'season', className: 'xsmall'},
            {header: 'Tonnage', formatter: tonnage, className: 'medium'},
        ];
        return (
            <Paper className='paper-table'>
                <div style={{ position: 'relative' }}>
                        <GenericTable
                            columns={COLUMNS}
                            items={transfers}
                            handleDefaultCellClick={this.handleDefaultCellClick}
                            orderBy="dateTime"
                            order="desc"
                        />
                </div>
            </Paper>
        );
    }
}

const mapStateToProps = state => {
    return {
        breadcrumbs: state.main.breadcrumbs,
        invoice: state.companies.invoices.selectedInvoice,
    };
};

InvoiceTransfersTable.propTypes = {
    invoiceId: PropTypes.number,
    match: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    invoice: PropTypes.object,
    breadcrumbs: PropTypes.array,
};

export default connect(mapStateToProps)(InvoiceTransfersTable);

import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import { create } from '../companies/freights';
import { isLoading, forceStopLoader } from '../main';

const MESSAGES = {
  CREATE_SUCCESS: 'Inload was successfully added',
  UPDATE_SUCCESS: 'Inload details were successfully updated',
  DELETE_SUCCESS: 'Inload was successfully deleted',
};

export const getStorageInloads = (storageId, actionCreator, queryParams) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.storages(storageId)
    .inloads()
    .get(token, undefined, queryParams)
    .then(items => {
      dispatch(actionCreator(items));
    });
};

export const createStorageInload = (storageId, data, actionCreator, successCallback) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  APIService.storages(storageId)
    .inloads()
    .post(data, token)
    .then(item => {
      dispatch(forceStopLoader());
      if (!isEmpty(item.errors)) {
        forEach(item.errors, value => {
          alertifyjs.error(value[0]);
        });
      } else if (item.id) {
        dispatch(actionCreator(item));
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (successCallback) {
          successCallback();
        }
      }
    });
};

export const createInload = (data, successCallback, successCallbackData) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  data['type'] = 'inload';
  APIService.loads()
    .post(data, token)
    .then(response => {
      dispatch(forceStopLoader());
      if (!isEmpty(get(response[0], 'errors'))) {
        forEach(response[0].errors, value => {
          if (typeof value === 'string')
            alertifyjs.error(value);
          else
            alertifyjs.error(value[0]);
        });
      } else if (get(response, '[0].id')) {
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (successCallback) {
          if (successCallbackData) {
            dispatch(successCallback(successCallbackData, deleteLoad, response[0].id));
          } else {
            successCallback();
          }
        }
      }
    });
};

export const createFMWithInload = (data, freightData) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  data['type'] = 'inload';
  APIService.loads()
    .post(data, token)
    .then(response => {
      dispatch(forceStopLoader());
      if (!isEmpty(get(response[0], 'errors'))) {
        forEach(response[0].errors, value => {
          alertifyjs.error(value);
        });
      } else if (get(response, '[0].id')) {
        alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        let outload = get(freightData.freightPickup, 'outload');
        outload.systemLoadId = response[0].id;
        dispatch(create(freightData, deleteLoad, response[0].id));
      }
    });
};

export const updateInload = (loadId, data, successCallback) => (dispatch, getState) => {
  dispatch(isLoading('nonExistentComponent'));
  const { token } = getState().main.user;
  APIService.loads(loadId)
    .put(data, token)
    .then(response => {
      dispatch(forceStopLoader());
      if (!isEmpty(get(response, 'errors'))) {
        forEach(response.errors, value => {
          if(typeof(value) === 'string')
            alertifyjs.error(value);
          else
            alertifyjs.error(value[0]);
        });
      } else if (response.id) {
        alertifyjs.success(MESSAGES.UPDATE_SUCCESS);
        if (successCallback) {
          successCallback();
        }
      }
    });
};

export const deleteLoad = loadId => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.loads(loadId).delete(token);
};

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import APIService from '../../services/APIService';

const PDFData = props => {
  const [success, setSuccess] = useState(undefined);
  const [error, setError] = useState(undefined);
  useEffect(() => {
    if (success || error) {
      if (typeof props.callback === 'function') props.callback();
      if (success) setSuccess(undefined);
      if (error) {
        alertifyjs.error('There was a problem, we will regenerate this PDF. Please try again after some time.', 10);
        setError(undefined);
      }
    }
  });

  const clickHandler = e => {
    e.preventDefault();
    const alertify = alertifyjs.notify('PDF will be downloaded soon...', 'message', 0);
    fetchPDFData({ ...props.data }, props.token, setSuccess, setError, alertify);
  };

  return (
    <a rel='noopener noreferrer' className='no-link' style={{ width: '100%' }} download href='' onClick={clickHandler}>
      {props.text || 'PDF'}
    </a>
  );
};

const mapStateToProps = state => ({
  token: state.main.user.token,
  user: state.main.user.user,
  serverEnv: state.main.serverEnv,
});

export default connect(mapStateToProps)(PDFData);

const fetchPDFData = (data, token, callback, failureCallback, alertify) => {
  APIService.empty()
    .appendToUrl('common/pdf/')
    .request('POST', data, token, { responseType: 'blob' })
    .then(response => {
      const fileName = getFilename(response.headers['content-disposition']);
      downloadFile(response.data, fileName);
      if (typeof callback === 'function') callback(true);
      if (alertify) alertify.dismiss();
    })
    .catch(error => {
      if (alertify) alertify.dismiss();
      if (typeof failureCallback === 'function') failureCallback(error);
    });
};

const getFilename = context => {
  var filename = 'data.pdf';
  if (context && context.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(context);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};

const downloadFile = (blob, fileName) => {
  let url = window.URL.createObjectURL(blob);
  let a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
};

//Moment Utils
import moment from 'moment';
import { getCountryFormats } from './utils';

export const convertEpochToDateFormat = (
  dateInUnixTimestamp,
) => moment(dateInUnixTimestamp).format(getCountryFormats().date);

export const convertEpochToDateTimeFormat = (
  dateInUnixTimestamp,
) => moment(dateInUnixTimestamp).format(getCountryFormats().datetime);

export const convertTimeToTwelveHour = time => moment(time, 'h:mm:ss').format(getCountryFormats().time);

import { connect } from 'react-redux';
import EmployeeForm from '../components/employees/EmployeeForm';
import { updateEmployee } from '../actions/api/employees';
import { editEmployee, cancelEditEmployee } from '../actions/company-settings/employees';
import find from 'lodash/find';

function submit(companyId, employeeId, data, callback) {
  return (dispatch) => {
    dispatch(updateEmployee(companyId, employeeId, data, editEmployee, false, callback));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedEmployee: find(
      state.companySettings.employees.items,
      { id: state.companySettings.employees.selectedEmployeeId }
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (companyId, employeeId, data, callback) => dispatch(submit(companyId, employeeId, data, callback)),
    cancelEdit: () => dispatch(cancelEditEmployee()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployeeForm);

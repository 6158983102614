import React, {Component} from 'react';

import merge from 'lodash/merge';
import Button from '@mui/material/Button';
import includes from 'lodash/includes';
import isArray from "lodash/isArray";
import isEmpty from "lodash/isEmpty";

class FileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggleRefresh: false,
      file: {
        name: null,
        base64: null,
      },
      errors: [],
    };

    this.onFileLoad = this.onFileLoad.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.uploadFile = React.createRef();
  }

  removeFile(event) {
    const fileEl = event.target.closest('span').previousSibling.previousSibling;
    const fileSrc = fileEl.getAttribute('src');
    if(fileSrc) {
      if(!includes(fileEl.parentElement.classList, 'existing-file-preview')) {
        fileEl.parentElement.remove();
      }
      this.props.onRemove(fileSrc);
      if(this.state.file && fileSrc === this.state.file.base64) {
        this.setState({file: {
          name: null,
          base64: null,
        }});
      }
    }
    this.setState({toggleRefresh: !this.state.toggleRefresh});
  }


  onFileLoad(event){
    const fileToUpload = event.target.files[0];
    var fileReader = new FileReader();
    fileReader.onload = ((file) => {
      var sizeInMB = file.size/(1024*1024);
      var errors = [],
          stateFileObject = { name: null, base64: null };
      return (e) => {
        if(sizeInMB > 10) {
          errors = ['Please upload less than 10MB'];
        } else {
          var span = document.createElement('span');
          if(file.type === "application/pdf") {
            span.innerHTML = [
              '<embed class="thumb" type="application/pdf" src="', e.target.result, '" title="', escape(fileToUpload.name), '"/><span class="thumbnail-text">', escape(fileToUpload.name), '</span><span class="file-close"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.87L11.87 13 9 10.13 6.13 13 5 11.87 7.87 9 5 6.13 6.13 5 9 7.87 11.87 5 13 6.13 10.13 9 13 11.87z"/></svg></span>'
            ].join('');
          } else {
            span.innerHTML = [
              '<img class="thumb-pdf" src="', e.target.result, '" title="', escape(fileToUpload.name), '"/><span class="thumbnail-text">', escape(fileToUpload.name), '</span><span class="file-close"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.87L11.87 13 9 10.13 6.13 13 5 11.87 7.87 9 5 6.13 6.13 5 9 7.87 11.87 5 13 6.13 10.13 9 13 11.87z"/></svg></span>'
            ].join('');
          }
          span.style.display = 'flex';
          span.className = 'preview-wrapper'
          span.style.overflow = 'hidden';
          document.getElementById(this.props.id + '-image-preview').insertBefore(span, null);
          document.getElementById('image-preview-container').removeAttribute("hidden")
          const fileSpans = document.getElementsByClassName('file-close');
          for(var i=0; i<fileSpans.length; i++) {
            if(fileSpans[i]) {
              fileSpans[i].onclick = this.removeFile;
            }
          }
          stateFileObject.name = file.name;
          stateFileObject.base64 = e.target.result;
        }
        this.setState(
          {errors: errors, file: stateFileObject},
          () => this.props.onChange(this.state)
        );
      };
    })(fileToUpload);

    fileReader.readAsDataURL(fileToUpload);
    event.target.value = ''
  }

  getPreviewHtml = (item , index = 0) => {
    return (
      <span className='existing-file-preview' key={index}>
        <img className="thumb-pdf" src={item}/>
        <span></span>
        {
          this.props.disabled ? null :
            <span className="file-close" onClick={this.removeFile}>
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path
                d="M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.87L11.87 13 9 10.13 6.13 13 5 11.87 7.87 9 5 6.13 6.13 5 9 7.87 11.87 5 13 6.13 10.13 9 13 11.87z"/>
              </svg>
            </span>
        }
      </span>
    );
  };

  render() {
    const { showImagePreviewFromURL } = this.props;
    const maxUploadFileCount = this.props.maxUploadFileCount || 2;
    let isImageHidden = this.props.showImage ? !this.props.showImage : !(isArray(showImagePreviewFromURL) || !isEmpty(showImagePreviewFromURL)) && true

    return (
      <div className={this.props.className || "col-md-12 col-xs-12"} style={merge((this.props.mainStyle || {}), { paddingLeft: '0px', paddingRight: '0px' })}>
        <div className={this.props.buttonContainerClass || "col-xs-12"}>
          <input
            accept={this.props.allowedExtensions || "image/*,application/pdf"}
            style={{display: 'none'}}
            id={this.props.id}
            type="file"
            onChange={this.onFileLoad}
            disabled={this.props.disabled}
            ref={this.uploadFile}
          />
          {!(this.props.fileCount + 1 > maxUploadFileCount) && (
            <label htmlFor={this.props.id}>
              <Button
                variant="outlined"
                component="span"
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.uploadFile.current.click();
                  }
                }}
                style={this.props.buttonStyle}
                disabled={this.props.disabled}
                color={this.props.buttonColor || 'primary'}
              >
                {this.props.icon} {this.props.buttonText || "Upload"}
              </Button>
            </label>
          )}
        </div>
        
        
        <div className={this.props.previewContainerClass || "col-md-12"} id='image-preview-container' hidden={isImageHidden}>
          <output className="col-md-12 file-upload-preview-files" id={this.props.id + "-image-preview"} style={this.props.previewStyle}></output>
          {(isArray(showImagePreviewFromURL) && !isEmpty(showImagePreviewFromURL)) ? (
            showImagePreviewFromURL.map((item, index) => {
              return this.getPreviewHtml(item, index);
            })
          ) : !isEmpty(showImagePreviewFromURL) && this.getPreviewHtml(showImagePreviewFromURL, 0)}
          <br/>
        </div>
      </div>
    );
  }
}

export default FileUpload;

import React from 'react';
import { connect } from 'react-redux';

import { Switch, Route } from 'react-router-dom';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import FarmSettings from './FarmSettings';
import UpdateFarm from '../../containers/UpdateFarm';
import { getSelectedFarm } from '../../actions/api/farms';
import { receiveFarm } from '../../actions/companies/farms';
import {setHeaderText, setSubHeaderText, setBreadcrumbs} from '../../actions/main';
import {canActOnFarm} from '../../common/utils';
import Link from '../common/icons/Link';
import Unlink from '../common/icons/Unlink';

class Farm extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const farmId = parseInt(this.props.match.params.farm_id);

    if (farmId && (!this.props.farm || this.props.farm.id !== farmId)) {
      this.props.getFarm(farmId);
      this.props.setHeaderText(get(this.props.farm, 'name', ''));
    }
    this._setSubHeaderText();
  }

  _setSubHeaderText() {
    if(this.props.farm && this.props.currentUser && get(this.props.currentUser, 'company.typeId') === 2) {
      this.props.setSubHeaderText({
        icon: this.props.farm.isManagedByUser ?
              <Link style={{marginLeft: '10px', paddingLeft: '10px'}} /> :
              (
                this.props.farm.isInUserDirectory ?
                <Unlink style={{marginLeft: '10px'}} /> :
                <Unlink style={{marginLeft: '10px', fill: 'lightgray'}} />
              )
      });
    }
  }

  componentDidUpdate(prevProps) {
    const farmId = parseInt(this.props.match.params.farm_id);
    if(this.props.farm && farmId && farmId !== this.props.farm.id){
      this.props.getFarm(farmId);
    }
    if (!isEqual(prevProps.farm, this.props.farm)) {
      this.props.setHeaderText(get(this.props.farm, 'name', ''));
    }
    this._setSubHeaderText();
  }

  componentWillUnmount() {
    this.props.setBreadcrumbs([]);
    this.props.resetSelectedFarm();
    this.props.setHeaderText(null);
    this.props.setSubHeaderText(null);
  }

  render() {
    const PARENT_URL = this.props.match.url;
    const FARM_ID = parseInt(this.props.match.params.farm_id);
    const FARM_NAME = get(this.props.farm, 'name', '');

    return (
      <div>
        <Switch>
          <Route
            path={`${PARENT_URL}/pending-request`}
            render={(props) => <UpdateFarm {...props} farmId={FARM_ID} farmName={FARM_NAME} />}
          />
          <Route
            path={`${PARENT_URL}/accept-reject`}
            render={(props) => <UpdateFarm {...props} farmId={FARM_ID} farmName={FARM_NAME} />}
          />
          <Route
            path={`${PARENT_URL}/settings`}
            render={(props) => <FarmSettings {...props} farmId={FARM_ID} farm={this.props.farm} farmName={FARM_NAME} canActOnFarm={canActOnFarm(this.props.farm)} isFarmAlone={get(this.props.farm, 'isAlone', false)} isAssociated={get(this.props.farm, 'isAssociated', false)} />}
          />
        </Switch>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  farm: state.companies.farms.selectedFarm,
  currentUser: state.main.user.user,
});

const mapDispatchToProps = dispatch => ({
  getFarm: (farmId) => dispatch(getSelectedFarm(farmId, receiveFarm)),
  setHeaderText: (text) => dispatch(setHeaderText(text)),
  setSubHeaderText: (text) => dispatch(setSubHeaderText(text)),
  setBreadcrumbs: (breadcrumbs) => dispatch(setBreadcrumbs(breadcrumbs)),
  resetSelectedFarm: () => dispatch(receiveFarm(null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Farm);

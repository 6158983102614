import React from 'react';

import moment from 'moment';
import { connect } from 'react-redux';
import { orderBy, filter, size, } from 'lodash';
import {
  DialogActions, DialogContent, Dialog, Button, TextField, Slide, IconButton, Divider,
  Tooltip,
} from '@mui/material';
import { Unarchive } from '@mui/icons-material/';
import {DialogTitleWithCloseIcon} from '../common/DialogTitleWithCloseIcon';
import {createSlotComment, updateSlotComment} from '../../actions/api/company-sites';
import {receiveSlotComments} from '../../actions/companies/company-sites';
import { initials, isSystemCompany } from '../../common/utils';
import './slotComments.scss';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class SlotComments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      show: false,
      showPastComments: false,
    };
    this.handleCommentChange = this.handleCommentChange.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetCommentBox = this.resetCommentBox.bind(this);
  }

  componentDidMount() {
    if(this.props.show)
      this.setState({show: this.props.show});
  }

  handleCommentChange(event) {
    this.setState({comment: event.target.value});
  }

  togglePastComments = () => {
    this.setState({showPastComments: !this.state.showPastComments});
  };

  onOpen() {
    this.setState({show: true});
  }

  onClose() {
    this.setState({show: false}, this.props.onClose);
  }

  resetCommentBox() {
    this.setState({comment: ''});
  }

  handleSubmit() {
    if(this.state.comment && this.props.siteId && this.props.slotId)
      this.props.dispatch(
        createSlotComment(
          this.props.siteId, this.props.slotId, {comment: this.state.comment}, this.resetCommentBox)
      );
  }

  componentWillUnmount() {
    this.props.dispatch(receiveSlotComments([]));
  }

  archivedCommentsCount() {
    return size(filter(this.props.comments, {archived: true}));
  }

  unArchive(comment) {
    if(comment)
      this.props.dispatch(updateSlotComment(comment.id, {archived: false}));
  }

  showArchived() {
    return !this.props.siteBooking || isSystemCompany();
  }

  getCommentsMarkup = (comments, unArchive=false) => {
    const commentClassName = 'no-right-padding ' + (unArchive ? 'col-sm-10' : 'col-sm-11');
    return (
      <div>
      {
        orderBy(comments, 'createdAt').reverse().map(comment => (
          <div key={comment.id} className="col-sm-12" style={{padding: '10px'}}>
            <div className="col-md-1 col-sm-1 user-info" style={{marginLeft: '-10px'}}>
              <IconButton
                touch="true"
                className='user-info-icon'
                style={{fontSize: '16px', padding: '5px'}}
                size="large">
                {
                  initials({
                    firstName: comment.creatorFirstName,
                    lastName: comment.creatorLastName
                  })
                }
              </IconButton>
            </div>
            <div className={commentClassName} style={{marginLeft: '-5px'}}>
              <div className="no-right-padding col-sm-12">
                {comment.creatorFirstName} {comment.creatorLastName}
              </div>
              <div className="no-right-padding col-sm-12" style={{color: '#808080', fontSize: '10px'}}>
                {moment(comment.createdAt).format('ddd, LLL')}
              </div>
              <div className="no-right-padding col-sm-12" style={{whiteSpace: 'pre-wrap', textAlign: 'justify', padding: '5px 0px 10px 15px'}}>
                {comment.comment}
              </div>
            </div>
            {
              unArchive &&
              <div className="col-sm-1 padding-reset">
              <Tooltip title="Unhide">
                <IconButton
                  onClick={() => this.unArchive(comment)}
                  style={{marginLeft: '-15px'}}
                  size="large">
                  <Unarchive color="primary" />
                </IconButton>
              </Tooltip>
              </div>
            }
          </div>
        ))
      }
      </div>
    );
  };

  render() {
    const showArchived = this.showArchived();
    const archivedCommentsCount = this.archivedCommentsCount();
    return (
        <Dialog
          open={this.props.show}
          TransitionComponent={Transition}
          keepMounted
          className="slot-comments-dialog"
          onClose={this.onClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitleWithCloseIcon onClose={this.onClose} style={{height: '55px'}}>
            <div style={{marginTop: '-4px'}}>Comments/Notes ({this.props.comments.length})</div>
          </DialogTitleWithCloseIcon>
          <DialogContent className="no-side-padding">
            <div className="col-sm-12 no-side-padding">
              <div className="col-sm-12">
                <TextField
                  id="comment"
                  label="Comment/Note"
                  placeholder="Write a message..."
                  multiline
                  rows="4"
                  value={this.state.comment}
                  onChange={this.handleCommentChange}
                  margin="normal"
                  variant="outlined"
                  inputProps={{autoComplete: "off"}}
                  fullWidth
                />
              </div>
              <div className="col-sm-2" style={{display: 'flex', float: 'right', justifyContent: 'end'}}>
                <Button
                  size="small"
                  type="button"
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}>
                  Send
                </Button>
              </div>
            </div>
            <div className="col-sm-12 no-side-padding" style={{fontSize: '12px'}}>
              {
                this.getCommentsMarkup(filter(this.props.comments, {archived: false}))
              }
            </div>
            {
              showArchived &&
              <div>
                {
                  archivedCommentsCount > 0 &&
                  <div className="col-md-12 past-comments">
                    <Divider />
                    <a className="col-sm-8" onClick={this.togglePastComments}>
                      Hidden past booking comments ({archivedCommentsCount})
                      <br />
                      (visible only to you)
                    </a>
                    <Divider />
                  </div>
                }
                {
                  this.state.showPastComments &&
                  <div className="col-sm-12 no-side-padding" style={{fontSize: '12px'}}>
                    {
                      this.getCommentsMarkup(filter(this.props.comments, {archived: true}), true)
                    }
                  </div>
                }
              </div>
            }
          </DialogContent>
          <DialogActions>
            <Button onClick={this.onClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
    );
  }
}

const mapStateToProps = state => {
  return {
    comments: state.companies.companySites.comments,
  };
};

export default connect(mapStateToProps)(SlotComments);

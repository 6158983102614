import React, { Component } from 'react';
import { connect } from 'react-redux';

import { get, debounce } from 'lodash';
import APIService from '../../services/APIService';
import CommonButton from '../common/CommonButton';
import TextField from "@mui/material/TextField/TextField";
import Autocomplete from '@mui/material/Autocomplete';
import SiteAsyncAutocomplete from '../common/autocomplete/SiteAsyncAutocomplete';
import alertifyjs from 'alertifyjs';
import { CircularProgress } from '@mui/material';
import uniqBy from 'lodash/uniqBy';

class FarmToFarm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedFarm: null,
      selectedStorage: undefined,
      handlers: [],
      searchText: '',
      isLoadingFarms: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFarmInputChange = this.handleFarmInputChange.bind(this);
    this.handleFarmChange = this.handleFarmChange.bind(this);
  }

  componentDidMount() {
    this.fetchHandlers();
  }

  fetchHandlers = debounce(searchStr => {
    const queryString = searchStr ? { search_txt: searchStr } : {};
    if (this.props.selectedAsset.entity == 'farmfield') {
      APIService
        .farms()
        .appendToUrl(`growers/`)
        .get(this.props.token, null, queryString)
        .then(data => {
          this.setState({ handlers: data, isLoadingFarms: false });
        });
    }
  }, 500);


  handleFarmChange = (event, item) => {
    const { selectedFarm } = this.state;
    const text = get(item, 'name', '');
    if (!item || (item.id !== get(selectedFarm, 'id'))) {
      const newState = { ...this.state };
      newState.searchText = text;
      newState.selectedFarm = item;
      newState.isLoadingFarms = Boolean(item && this.state.isLoadingFarms);
      if (this.props.selectedAsset.entity == 'storage')
        newState.handlers = uniqBy([...newState.handlers, item], 'id');
      this.setState(newState);
    }
  };

  handleFarmInputChange = (event, value) => {
    this.setState({ searchText: value, isLoadingFarms: Boolean(value) }, () => {
      if (value && value.length > 2)
        this.fetchHandlers(value);
    });
  };

  isSearchableInput() {
    return get(this.state.searchText, 'length') > 2;
  }

  handleSubmit(event) {
    const { selectedAsset } = this.props;

    const submitData = {
      asset:  get(selectedAsset, 'entity'),
      asset_id:  get(selectedAsset, 'id')
    };
    if (this.state.selectedFarm) {
      this.props.submit(this.state.selectedFarm.id, submitData);
      this.props.cancel();
    }
    if (!this.state.selectedFarm) {
      alertifyjs.error('Please select a Farm');
    }
    event.preventDefault();

  }

  render() {
    const { isLoadingFarms, handlers, searchText } = this.state;
    const isSearchableInput = this.isSearchableInput();
    return (
      <div style={{ paddingTop: "20px" }}>
        { this.props.selectedAsset.entity == 'farmfield' ?
          <Autocomplete
            id="farmId"
            blurOnSelect
            options={handlers || []}
            getOptionLabel={option => option.name}
            loading={isLoadingFarms}
            loadingText={isSearchableInput ? 'Loading...' : 'Type at least 3 characters to search'}
            noOptionsText={searchText ? "No results" : 'Start typing...'}
            renderInput={params => (
              <TextField
                {...params}
                value={searchText}
                variant="outlined"
                placeholder="Farm/Site (Type at least 3 characters to search)"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {isLoadingFarms ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                fullWidth
              />
            )}
            value={get(this.state, 'selectedFarm')}
            onChange={this.handleFarmChange}
            onInputChange={this.handleFarmInputChange}
          /> :
          <SiteAsyncAutocomplete
            limitTags={2}
            label="Farm/Site"
            id="farmId"
            onChange={item => this.handleFarmChange(null, item)}
            selected={get(this.state, 'selectedFarm')}
            minLength={3}
            variant="outlined"
            fullWidth
            activeSitesOnly
          />
          }
        <div className="col-sm-12 cardForm-action top15 padding-reset" style={{ paddingTop: "7px", textAlign: "right" }} >
          <CommonButton
            variant="outlined"
            label="Cancel"
            type="button"
            default
            onClick={this.props.cancel}
          />
          <CommonButton variant="contained" primary={true} label='Save' onClick={this.handleSubmit} type="submit" />
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.main.user.token,
    selectedFarm: state.companies.farms.selectedFarm,
  };
};

export default connect(mapStateToProps)(FarmToFarm);

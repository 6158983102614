import React from 'react';

import {Autocomplete, TextField, CircularProgress} from '@mui/material';
import {get, debounce, last, isArray} from 'lodash';
import APIService from '../../../services/APIService';

class CompanyAutocomplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      companies: [],
      isLoading: false
    }
  }

  _onChange = (event, items) => {
    const item = isArray(items) ? last(items) : items
    const text = get(item, 'name', '');
    const { selected, multiple, onChange, id } = this.props;
    if(!item || (item.id !== get(selected, 'id'))) {
      this.setState({input: text, isLoading: item ? this.state.isLoading : false}, () => onChange(multiple ? items : item, id))
    }
  }
  _onInputChange = debounce((event, value) => {
    if(value !== this.state.input) {
      this.setState({input: value || '', isLoading: Boolean(value)}, () => this.fetchCompanies(value))
    }
  }, 300);

  fetchCompanies = searchStr => {
    if(searchStr) {
      const { queryParams, filterFunc, companyId, selected, onChange, multiple, id } = this.props
      this.getService()
        .get(null, null, {...(queryParams || {}), search: searchStr})
        .then(items => {
          let _items = filterFunc ? filterFunc(items) : items
          this.setState({companies: _items, isLoading: false}, () => {
            if(_items?.length === 1 && companyId === _items[0].id){
              this._onChange(null, _items)
              if(selected?.id === companyId)
                onChange(multiple ? _items : last(_items), id)
            }
          })
        });
    }
  };

  getURLPath = () => {
    const type = this.props.getRegisteredCompanies ? 'registered' : 'all';
    return this.props.urlPath ? this.props.urlPath : `minimal/${type}/`
  }

  fetchById = () => {
    const { companyId, queryParams } = this.props
    if(companyId) {
      this.setState({isLoading: true}, () => {
        this.getService()
          .get(null, null, {...(queryParams || {}), company_id: companyId})
          .then(items => {
            this.setState({companies: items, isLoading: false}, () => this._onChange(null, items))
          });
      })
    }
  }

  getService = () => APIService.companies().appendToUrl(this.getURLPath())

  componentDidUpdate(prevProps) {
    const { companyId, selected } = this.props
    const { companies, isLoading } = this.state
    if(prevProps.companyId !== companyId && companyId && !selected?.id && !isLoading && !companies?.length) {
      this.fetchById()
    }
  }

  render() {
    const { id, minLength, selected, size, variant, disabled, label, multiple, limitTags, disableCloseOnSelect, errorText } = this.props
    const { input, isLoading, companies } = this.state
    const isSearchable = input.length >= minLength;
    return (
      <Autocomplete
        multiple={Boolean(multiple)}
        disableCloseOnSelect={Boolean(disableCloseOnSelect)}
        limitTags={limitTags || -1}
        id={id || "company"}
        size={size || 'medium'}
        blurOnSelect
        options={companies}
        getOptionLabel={option => option.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id && value?.id}
        loading={isLoading}
        loadingText={isSearchable ? 'Loading...' : `Type at least ${minLength} characters to search`}
        noOptionsText={input ? "No results" : 'Start typing...'}
        renderInput={params => (
          <TextField
            {...params}
            value={input}
            variant={variant || 'outlined'}
            placeholder={`${label} (Type at least ${minLength} characters to search)`}
            label={label || undefined}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {
                    (isLoading && !disabled) ? <CircularProgress color="inherit" size={20} /> : null
                  }
                  {
                    (!isLoading && disabled && selected?.name) ?
                      <i style={{top: '5px'}} className="icon-lock"></i> :
                    params.InputProps.endAdornment
                  }
                </React.Fragment>
              ),
            }}
            inputProps={{
              ...params.inputProps,
              className: params.inputProps.className + ' black-text'
            }}
            fullWidth
            error={Boolean(errorText)}
            helperText={errorText}
          />
        )}
        value={selected}
        onChange={this._onChange}
        onInputChange={this._onInputChange}
        disabled={disabled}
      />
    )
  }
}

export default CompanyAutocomplete;

import React from 'react';


import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return (<KeyboardArrowUp
      style={{fill: '#112c42', height: '20px', width: '20px'}}
    />);
  }
  return (<KeyboardArrowDown style={{fill: '#112c42', height: '20px', width: '20px'}}/>);
};

export class RejectionDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true
    };
  }

  toggleExpanded = () => {
    this.setState((prevState) => ({expanded: !prevState.expanded}));
  };

  getHeading() {
    const { rejectionReasonObject, heading } = this.props;
    if(heading)
      return heading

    if(get(rejectionReasonObject, 'requestReason'))
      return `Reason for ${get(rejectionReasonObject, 'action')} Request`;

    if(get(rejectionReasonObject, 'rejectionReason'))
    return `Reason for Rejection of ${get(rejectionReasonObject, 'action')} Request`;

    const action = get(rejectionReasonObject, 'action') || '';
    if(action.toLowerCase() === 'void')
      return 'Reason for Void Request';

    if(action)
      return `Reason for Rejection of ${action} Request`;

    return `Reason for Rejection`;
  }

  render() {
    const {rejectionReasonObject} = this.props;
    const {expanded} = this.state;
    return (
      !isEmpty(rejectionReasonObject) && (get(rejectionReasonObject, 'rejectionReason', false) || get(rejectionReasonObject, 'requestReason', false))  &&
      <Paper className={this.props.className ? this.props.className : "contract-details-section-container"}>
        <h2 onClick={this.toggleExpanded}>
          { this.getHeading() }
          <span className="expand-icon">
            {renderBasedOnExpandedFlag(expanded)}
          </span>
        </h2>
        {expanded && (
          <div style={{marginTop: '20px', whiteSpace: 'pre-wrap'}}>
            {rejectionReasonObject.rejectionReason || rejectionReasonObject.requestReason}
          </div>
        )}
      </Paper>
    );
  }
}

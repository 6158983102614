import companyReducer from './company';
import {
  GET_COMPANIES_SUCCESS,
  ADD_COMPANY_FAILURE,
  RECIEVE_COMPANY_DETAILS,
  UPDATE_COMPANY_FAILURE,
  RECEIVE_BROKER_COMPANIES,
  UPDATE_COMPANY_DETAILS,
  GET_COMPANY_COMPANIES_SUCCESS,
  GET_COMPANY_GROUPS_SUCCESS,
  GET_COMPANY_GROUPS,
  GET_COMPANY_NAMES_SUCCESS,
  SHOW_SIDEDRAWER_TRANSFER,
  SHOW_DAILY_REPORTS,
  SHOW_SIDEDRAWER_MERGE,
  SET_TABLE_COLUMNS,
  IS_FROM_COMPANY_DIRECTORY,
} from '../../actions/companies/index';
import { GET_PAGINATED_COMPANIES_SUCCESS, GET_PAGINATED_COMPANIES_OF_GROUP_SUCCESS } from '../../actions/api/companies';

const initialState = {
  items: [],
  company: undefined,
  errors: [],
  selectedCompany: undefined,
  brokerCompanies: [],
  updatedCompanyDetails: undefined,
  paginationData: null,
  paginationDataOfGroup: null,
  groupCompanyItems: [],
  companyParties: [],
  selectedSubcontractRequestId: undefined,
  selectedAsset: undefined,
  showTransferSideDrawer: false,
  showDailyReports: false,
  isFromCompanyDirectory: false,
  companyGroups: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMPANIES_SUCCESS:
      return {
        ...state,
        items: action.companies,
        selectedCompany: undefined,
        updatedCompanyDetails: undefined,
      };
    case GET_COMPANY_NAMES_SUCCESS:
      return {
        ...state,
        names: action.companies,
        selectedCompany: undefined,
        updatedCompanyDetails: undefined,
      };
    case GET_COMPANY_COMPANIES_SUCCESS:
      return {
        ...state,
        companyParties: action.companies,
        isCompanyPartyDataSet: true
      };
    case GET_PAGINATED_COMPANIES_SUCCESS:
      return {
        ...state,
        items: action.paginatedCompanies.results,
        paginationData: action.paginatedCompanies,
        selectedCompany: undefined,
        updatedCompanyDetails: undefined,
      };
    case GET_PAGINATED_COMPANIES_OF_GROUP_SUCCESS:
      return {
        ...state,
        groupCompanyItems: action.paginatedCompanies.results,
        paginationDataOfGroup: action.paginatedCompanies,
        selectedCompany: undefined,
        updatedCompanyDetails: undefined,
      };
    case ADD_COMPANY_FAILURE:
      return {
        ...state,
        errors: action.errors,
        selectedCompany: undefined,
        updatedCompanyDetails: undefined,
      };
    case RECIEVE_COMPANY_DETAILS:
      return {
        ...state,
        selectedCompany: action.item,
      };
    case UPDATE_COMPANY_DETAILS:
      return {
        ...state,
        updatedCompanyDetails: action.item,
      };
    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        errors: action.errors,
      };
    case RECEIVE_BROKER_COMPANIES:
      return {
        ...state,
        brokerCompanies: action.brokerCompanies,
      };
    case SHOW_SIDEDRAWER_TRANSFER:
        return {
          ...state,
          selectedAsset: action.item,
          showTransferSideDrawer: action.showTransferSideDrawer
        };
    case SHOW_DAILY_REPORTS:
      return {
        ...state,
        selectedAsset: action.item,
        showDailyReports: action.showDailyReports
      };
    case SHOW_SIDEDRAWER_MERGE:
      return {
        ...state,
        selectedAsset: action.item,
        showMergeSideDrawer: action.showMergeSideDrawer
      };
    case SET_TABLE_COLUMNS:
      return {
        ...state,
        tableColumns: action.headers
      };
    case IS_FROM_COMPANY_DIRECTORY:
      return {
        ...state,
        isFromCompanyDirectory: action.flag
      }
    case GET_COMPANY_GROUPS_SUCCESS:
      return {
        ...state,
        companyParties: action.companies,
      }
    case GET_COMPANY_GROUPS:
      return {
        ...state,
        companyGroups: action.groups,
      }
    default:
      return {
        ...state,
        company: companyReducer(state.company, action),
      };
  }
};

export default reducer;

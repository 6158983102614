import React from 'react';
import moment from 'moment'
import { Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material'; 
import { map, startCase } from 'lodash'
import { getCountryFormats } from '../../../common/utils';


const LoadSMSTable = ({ messages }) => {
  const datetimeFormat = getCountryFormats().datetime
  return (
    <Table dense>
      <TableHead>
        <TableCell>
          Type
        </TableCell>
        <TableCell>
          Sender
        </TableCell>
        <TableCell>
          Recipient
        </TableCell>
        <TableCell>
          Status
        </TableCell>
        <TableCell>
          Sent At
        </TableCell>
        <TableCell>
          Reminder Sent At
        </TableCell>
        <TableCell>
          Reply Processed At
        </TableCell>
        <TableCell>
          Message
        </TableCell>
      </TableHead>
      <TableBody>
        {
          map(messages, message => (
            <TableRow key={message.id}>
              <TableCell>
                {startCase(message.type)}
              </TableCell>
              <TableCell>
                {message.senderNumber}
              </TableCell>
              <TableCell>
                {message.recipient}
              </TableCell>
              <TableCell>
                {startCase(message.status)}
              </TableCell>
              <TableCell>
                {message.sentAt ? moment(message.sentAt).format(datetimeFormat) : '-'}
              </TableCell>
              <TableCell>
                {message.lastReminderSentAt ? moment(message.lastReminderSentAt).format(datetimeFormat) : '-'}
              </TableCell>
              <TableCell>
                {message.lastReplyProcessedAt ? moment(message.lastReplyProcessedAt).format(datetimeFormat) : '-'}
              </TableCell>
              <TableCell>
                <div style={{whiteSpace: 'break-spaces', maxWidth: '300px', overflow: 'auto'}}>
                  {message.message}
                </div>
              </TableCell>
            </TableRow>
          ))
        }
      </TableBody>
    </Table>
  )
}

export default LoadSMSTable;

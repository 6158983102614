import React, { Component } from 'react';
import { connect } from 'react-redux';

import { applyValidatorsOn, required, selected, } from '../../common/validators';
import alertifyjs from 'alertifyjs';
import '@babel/polyfill';
import CommonTextField from '../common/CommonTextField';
import CommonDatePicker from '../common/CommonDatePicker';
import CustomEmailDialog from '../common/CustomEmailDialog';
import TextField from "@mui/material/TextField/TextField";
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import IconButton from '@mui/material/IconButton';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Tooltip from '@mui/material/Tooltip';
import AddButton from '../common/AddButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import remove from 'lodash/remove';
import forEach from 'lodash/forEach';
import uniqBy from 'lodash/uniqBy';
import cloneDeep from 'lodash/cloneDeep';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import queryString from 'query-string';
import some from 'lodash/some';
import includes from 'lodash/includes';
import mapValues from 'lodash/mapValues';
import compact from 'lodash/compact';
import {
  canAccessAny,
  generateIdentifier, getAutoSelectFocusField,
  getDaysToAdd, getLabelForEmployeeForm,
  getWeekDays,
  isTransactionParticipated,
  nextEndDayFrom,
  getCountryFormats,
  getCountryConfig, getCountryLabel, getCountryCurrency, formatPrice,
  getEstimatedPaymentDueDateForDatePaymentTerm,
  generateUpdatedIdentifier,
  returnFloatFromString
} from '../../common/utils';
import APIService from '../../services/APIService';
import { fetchBanks, isLoading, loaded, forceStopLoader, setBreadcrumbs, setHeaderText } from '../../actions/main';
import {
  getContractsResponse,
  movementCarryItemsResponse,
  getMovementsCarryItems
} from '../../actions/companies/contracts';
import { getDetailedContracts, isFreightContractsFetched, loadFreightInvoiceItems } from '../../actions/companies/freights';
import InvoiceTable from './InvoiceTable';
import moment from 'moment';
import InvoiceCustomItemForm from './InvoiceCustomItemForm';
import SideDrawer from '../common/SideDrawer';
import {
  NGR_MISSING_DETAILS_ERROR, NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS,
  FREIGHT,
} from '../../common/constants';
import {
  clickAddGeneralConditionButton,
  getGeneralConditions,
  receiveGeneralConditions
} from "../../actions/companies/conditions";
import CommonButton from "../common/CommonButton";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import Dialog from "@mui/material/Dialog/Dialog";
import './invoice.scss';
import has from "lodash/has";
import map from "lodash/map";
import merge from "lodash/merge";
import filter from "lodash/filter";
import { every } from "lodash";
import CommonSelect from "../common/select/CommonSelect";
import AutoComplete from "../common/autocomplete/AutoComplete";
import Button from "@mui/material/Button/Button";
import { cancelEditNgr } from "../../actions/companies/ngrs";
import { cancelEditCompanyNgr, clickEditCompanyNgr, receiveCompanyNgrs } from "../../actions/companies/company-ngrs";
import UpdateCompanyNgr from "../../containers/UpdateCompanyNgr";
import UpdateNgr from "../../containers/UpdateNgr";
import set from "lodash/set";
import CreateCompanyNgr from '../../containers/CreateCompanyNgr';
import { clickAddCompanyNgrButton } from '../../actions/companies/company-ngrs';
import { clickAddNgrButton } from '../../actions/companies/ngrs';
import CreateEmployee from "../../containers/CreateEmployee";
import CreateGeneralCondition from "../../containers/CreateGeneralCondition";
import { AddCircleIconWithTooltip } from "../common/icons/AddCircleIconWithTooltip";
import { canCreateEmployee } from "../../actions/api/employees";
import {
  CUSTOM_ITEM_TYPE,
  FREIGHT_CONTRACT_ITEM_TYPE,
} from "./Constants";
import { clickAddFarmEmployeeButton } from "../../actions/companies/farm-employees";
import { clickAddEmployeeButton } from "../../actions/company-settings/employees";
import { canCreateNgr } from "../../actions/api/ngrs";
import InvoicePreview from "./InvoicePreview";
import omit from "lodash/omit";
import Notes from "../common/Notes";
import { getInvoiceDetails } from '../../actions/companies/invoices';

const DEFAULT_SIDE_DRAWERS_STATE = {
  freightProviderSideDrawerIsOpen: false,
  customerSideDrawerIsOpen: false,
  invoiceConditionSideDrawerIsOpen: false,
  openSideDrawer: false,
  ngrSideDrawerIsOpen: false,
};

class FreightInvoiceForm extends Component {
  constructor(props) {
    super(props);
    this.invoicePartyFields = {
      accountName: {
        value: null,
        validators: [required()],
        errors: []
      },
      accountNumber: {
        value: null,
        validators: [required()],
        errors: []
      },
      bankName: {
        value: null,
        id: '',
        validators: [required(), selected()],
        errors: []
      },
      bsbNumber: {
        value: null,
        validators: [required()],
        errors: []
      },
      contactId: {
        value: null,
        validators: [],
        errors: []
      },
      companyId: {
        value: null,
        validators: [],
        errors: []
      },
      ngrId: {
        value: null,
        validators: [],
        errors: []
      },
    };
    this.payeeFields = cloneDeep(this.invoicePartyFields);
    this.payerFields = cloneDeep(this.invoicePartyFields);
    this.countryConfig = getCountryConfig();
    this.state = {
      countryFormats: getCountryFormats(),
      showCustomEmailDialog: false,
      order: null,
      isDefaultGeneralConditionApplied: false,
      freightProviderNgrs: [],
      customerNgrs: [],
      freightProviderEmployees: [],
      customerEmployees: [],
      allContracts: [],
      freightMovements: [],
      entityForNgrUpdate: '',
      freightProviderSideDrawerIsOpen: false,
      customerSideDrawerIsOpen: false,
      invoiceConditionSideDrawerIsOpen: false,
      billToParties: [],
      customItems: [],
      grainLevies: [],
      carryItems: [],
      totalDistance: 0,
      eprs: [],
      selectedItems: [],
      filteredContracts: [],
      openSideDrawer: false,
      displayCustomerBankForm: false,
      displayFreightProviderBankForm: false,
      preview: false,
      freightProviderNgrAccountNumber: '',
      customerNgrAccountNumber: '',
      customItemId: 1,
      fetchingFreightProviderContacts: false,
      fetchingCustomerContacts: false,
      fetchingFreightProviderNgrs: false,
      ngrSideDrawerIsOpen: false,
      ngrEntity: undefined,
      gstApplicable: this.countryConfig?.invoicing?.gst,
      gstRate: this.countryConfig?.invoicing?.gstRate,
      xeroCounterPartyExists: false,
      currentUserCompany: undefined,
      fields: {
        note: {
          description: '',
          attachments: [],
          companyId: this.props.user.companyId,
        },
        paymentDueDate: {
          value: '',
          validators: [required()],
          errors: [],
        },
        identifier: {
          value: generateIdentifier('invoice'),
          validators: [required()],
          errors: [],
        },
        customerNgrId: {
          value: '',
          validators: [],
          errors: []
        },
        freightProviderNgrId: {
          value: '',
          validators: [required()],
          errors: []
        },
        invoiceConditions: {
          value: '',
          validators: [],
          errors: []
        },
        freightProviderContact: {
          value: '',
          validators: [required()],
          errors: []
        },
        customerContact: {
          value: '',
          validators: [required()],
          errors: []
        },
        selectedFreightProviderParty: {
          value: undefined,
          validators: [required()],
          errors: []
        },
        selectedCustomerParty: {
          value: undefined,
          validators: [required()],
          errors: []
        },
        selectedOrder: {
          value: undefined,
          validators: [required()],
          errors: []
        },
        payee: cloneDeep(this.payeeFields),
        payer: cloneDeep(this.payerFields),
      },
      subTotal: 0.00,
      gst: 0.00,
      total: 0.00,
      isConfirmDialogOpen: false,
      isDefaultSelected: false,
      fmTtTonnage: 0.0,
    };

    const hashQueryParams = new URLSearchParams(window.location.hash.split('?')[1]);
    this.isDuplicatingInvoice = Boolean(hashQueryParams.get('copyFrom')) && window.VOID_AND_DUPLICATE_TOGGLE_INVOICE
    this.copyFrom = hashQueryParams.get('copyFrom')
    this.appendInvoiceConditions = this.appendInvoiceConditions.bind(this);
    this.areSame = this.areSame.bind(this);
    this.checkDueDate = this.checkDueDate.bind(this);
    this.closeAddNgrSideDrawer = this.closeAddNgrSideDrawer.bind(this);
    this.closeFreightProviderContactSideDrawer = this.closeFreightProviderContactSideDrawer.bind(this);
    this.closeInvoiceConditionSideDrawer = this.closeInvoiceConditionSideDrawer.bind(this);
    this.closeNgrSideDrawer = this.closeNgrSideDrawer.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.closeSideDraw = this.closeSideDraw.bind(this);
    this.commonOpenSideDrawer = this.commonOpenSideDrawer.bind(this);
    this.estimatedPaymentDueDate = this.estimatedPaymentDueDate.bind(this);
    this.focusOnFirstErrorField = this.focusOnFirstErrorField.bind(this);
    this.freightProviderSideDrawer = this.freightProviderSideDrawer.bind(this);
    this.getAddCustomerContactButton = this.getAddCustomerContactButton.bind(this);
    this.getAddFreightProviderContactButton = this.getAddFreightProviderContactButton.bind(this);
    this.getContacts = this.getContacts.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.getNgrs = this.getNgrs.bind(this);
    this.getSelectedFreightProviderCompany = this.getSelectedFreightProviderCompany.bind(this);
    this.handleAccountChangeForEntity = this.handleAccountChangeForEntity.bind(this);
    this.handleAddItem = this.handleAddItem.bind(this);
    this.handleBlur = this.handleBlur.bind(this);
    this.handleConditionSelector = this.handleConditionSelector.bind(this);
    this.handleConfirmDialogCancel = this.handleConfirmDialogCancel.bind(this);
    this.handleConfirmDialogOk = this.handleConfirmDialogOk.bind(this);
    this.handleConfirmDialogOpen = this.handleConfirmDialogOpen.bind(this);
    this.handleCustomerContactChange = this.handleCustomerContactChange.bind(this);
    this.handleCustomerNgrChange = this.handleCustomerNgrChange.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleFreightProviderContactChange = this.handleFreightProviderContactChange.bind(this);
    this.handleFreightProviderNgrChange = this.handleFreightProviderNgrChange.bind(this);
    this.handleIdentifierChange = this.handleIdentifierChange.bind(this);
    this.handleItemClick = this.handleItemClick.bind(this);
    this.handlePayeeBankChange = this.handlePayeeBankChange.bind(this);
    this.handlePayerBankChange = this.handlePayerBankChange.bind(this);
    this.handlePaymentDueDateChange = this.handlePaymentDueDateChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isCustomerRcti = this.isCustomerRcti.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.openAddNgrSideDrawer = this.openAddNgrSideDrawer.bind(this);
    this.openCustomerContactSideDrawer = this.openCustomerContactSideDrawer.bind(this);
    this.openFreightProviderContactSideDrawer = this.openFreightProviderContactSideDrawer.bind(this);
    this.openInvoiceConditionSideDrawer = this.openInvoiceConditionSideDrawer.bind(this);
    this.openNgrSideDrawer = this.openNgrSideDrawer.bind(this);
    this.openSideDraw = this.openSideDraw.bind(this);
    this.relatedItemToFm = this.relatedItemToFm.bind(this);
    this.removeRelatedItems = this.removeRelatedItems.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.setDefaultGeneralCondition = this.setDefaultGeneralCondition.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.updateFreightProviderNgrDetails = this.updateFreightProviderNgrDetails.bind(this);
    this.updateNgrChanges = this.updateNgrChanges.bind(this);
    this.updateSelectedItem = this.updateSelectedItem.bind(this);
    this.updateSelectedItems = this.updateSelectedItems.bind(this);
    this.handlePreviewClick = this.handlePreviewClick.bind(this);
    this.handlePreviewClose = this.handlePreviewClose.bind(this);
    this.handlePreviewSubmit = this.handlePreviewSubmit.bind(this);
    this.closeCustomEmailDialog = this.closeCustomEmailDialog.bind(this);

    this.fieldsOrder = ["contractNumber", "identifier", "paymentDueDate", "customerContact", "customerNgrId", "freightProviderContact",
      "freightProviderNgrId", "payeeBankName", "payee.accountName", "payee.accountNumber", "payee.bsbNumber"];

    this.fieldRef = {};
    this.fieldsOrder.forEach(e => this.fieldRef[e] = React.createRef());
    this.order = null;
    this.setDefaultPaymentDueDate = true;
  }

  appendInvoiceConditions() {
    const newState = { ...this.state };
    if (isEmpty(newState.fields.invoiceConditions.value)){
      newState.fields.invoiceConditions.value = newState.selectedGeneralConditionText
    }
    else {
      newState.fields.invoiceConditions.value = newState.fields.invoiceConditions.value + "\n" + newState.selectedGeneralConditionText;
    }
    this.setState(newState);
  }

  areSame(selectedItem, item) {
    return selectedItem.itemId && selectedItem.itemId === item.itemId && selectedItem.itemType === item.itemType;
  }

  checkDueDate() {
    const newState = { ...this.state };
    const paymentDueDate = this.state.fields.paymentDueDate.value;
    const estimatedDate = this.estimatedPaymentDueDate();
    if (moment(estimatedDate).isAfter(moment(paymentDueDate))) {
      newState.fields.paymentDueDate.errors = ['Warning: Minimum Payment Due Date as per payment terms of the order is ' + moment(estimatedDate).format(this.state.countryFormats.date)];
      this.setState(newState);
    }
  }

  closeAddNgrSideDrawer() {
    this.setState({ ngrSideDrawerIsOpen: false });
  }


  closeFreightProviderContactSideDrawer() {
    this.setState({ freightProviderSideDrawerIsOpen: false });
  }

  closeInvoiceConditionSideDrawer() {
    this.setState({ invoiceConditionSideDrawerIsOpen: false });
  }


  closeNgrSideDrawer() {
    this.props.dispatch(cancelEditNgr());
    this.props.dispatch(cancelEditCompanyNgr());
  }

  closeSideDraw() {
    const newState = { ...this.state };
    newState.openSideDrawer = false;
    this.setState(newState);
  }

  commonOpenSideDrawer(type) {
    if (type !== 'ngrSideDrawerIsOpen') {
      this.closeNgrSideDrawer();
    }
    const sideDrawer = cloneDeep(DEFAULT_SIDE_DRAWERS_STATE);
    if (type) {
      sideDrawer[type] = true;
    }
    this.setState(sideDrawer);
  }

  fetchCurrentUserCompany() {
    const companyId = this.props.user?.companyId
    if(companyId)
      APIService.companies(this.props.user.companyId).get().then(response => this.setState({currentUserCompany: response}))
  }


  async componentDidMount() {
    this.fetchCurrentUserCompany()
    const { dispatch } = this.props;
    dispatch(isLoading('nonExistingComponent'));
    let movementsQueryString = '?'

    if(this.copyFrom && this.props.invoiceId != this.copyFrom)
      dispatch(getInvoiceDetails(this.copyFrom));

    if (includes(this.props.location.search, 'orderId=')) {
      const queryParams = queryString.parse(this.props.location.search);
      var orderId = parseInt(get(queryParams, 'orderId'))
      movementsQueryString = '&'
    }
    movementsQueryString += 'status=load_rejected,delivered,completed'
    if(orderId){
      Promise.all([
        dispatch(loadFreightInvoiceItems(false)),
        dispatch(getGeneralConditions('invoice')),
        dispatch(fetchBanks()),
        dispatch(getDetailedContracts(orderId, null, movementsQueryString)),
        this.setOrder(),
        dispatch(getMovementsCarryItems(orderId))
      ]).then(() => {
        dispatch(loaded())
        const newState = { ...this.state };
        this.setCurrentUserAsContactIfApplicable(get(this.state.order, 'customer.company'), 'customerContact', newState);
        this.setCurrentUserAsContactIfApplicable(get(this.state.order, 'provider'), 'freightProviderContact', newState);
        this.setState(newState);
      });
      this.getFreightMovements();
    }
    else
      dispatch(loaded())
  }

  setCurrentUserAsContactIfApplicable(company, fieldToSet, newState) {
    const { user } = this.props;
    if (get(newState.fields, fieldToSet + '.value'))
      return false;

    if (get(user, 'companyId') === get(company, 'id')) {
      set(newState.fields, fieldToSet + '.value', user.id);
      return true;
    }
    return false;
  }

  convertToDate(dateStr) {
    return moment(dateStr, getCountryFormats().date, true).toDate()
  }

  populateFields(orderId, dispatch) {
    const newState = { ...this.state };
    newState.fields.selectedOrder.value = orderId;
    dispatch(isLoading('nonExistentComponent'));
    let movementsQueryString = '?status=load_rejected,delivered,completed'
    if (orderId) {
      Promise.all([
        dispatch(loadFreightInvoiceItems(false)),
        dispatch(getGeneralConditions('invoice')),
        dispatch(fetchBanks()),
        dispatch(getDetailedContracts(orderId, null, movementsQueryString)),
        this.setOrder(orderId),
        dispatch(getMovementsCarryItems(orderId))
      ]).then(() => {
        dispatch(loaded())
        const newState = { ...this.state };
        this.setCurrentUserAsContactIfApplicable(get(this.state.order, 'customer.company'), 'customerContact', newState);
        this.setCurrentUserAsContactIfApplicable(get(this.state.order, 'provider'), 'freightProviderContact', newState);
        this.setState(newState, () => {
          if(this.isDuplicatingInvoice)
            this.getSelectedInvoiceCustomItems();
        });
      });
    } else {
      dispatch(forceStopLoader());
    }
  }

  handleOrderChange(orderId) {
    const { dispatch } = this.props;
    if (!this.state.fields.selectedOrder.value) {
      this.populateFields(orderId, dispatch);
      return;
    }
  }

  componentDidUpdate(prevProps) {
    const newState = { ...this.state };
    if(!prevProps.selectedInvoice && this.props.selectedInvoice?.id && this.isDuplicatingInvoice) {
      this.handleOrderChange(this.props.selectedInvoice.raisedForId)
    }
    let totalFromItems = 0;
    let freightMovements = this.state.order ? this.getFreightMovements() : [];
    this.updateNgrChanges(prevProps, newState);
    let itemsForInvoice = this.state.selectedItems;
    let subTotal = reduce(itemsForInvoice, function (sum, item) { return sum + item.subTotal; }, 0);
    let gst = reduce(itemsForInvoice, function (sum, item) { return sum + (item.gst === 'N/A' ? 0 : item.gst); }, 0);
    let itemsForTonnage = filter(filter(
      itemsForInvoice,
      selectedItem => !includes([CUSTOM_ITEM_TYPE], selectedItem.itemType)
    ), 'invoice' );
    let fmTtTonnage = reduce(
      itemsForTonnage,
      function (sum, item) {
        return sum + item.tonnage;
      },
      0,
    );
    if (this.state.order) {
      newState.subTotal = (subTotal).toFixed(2);
      newState.gst = parseFloat(gst).toFixed(2);
      totalFromItems = parseFloat(newState.subTotal) + parseFloat(newState.gst);
      newState.total = parseFloat(totalFromItems).toFixed(2);
      newState.fmTtTonnage = parseFloat(fmTtTonnage).toFixed(2);
      if(!isEmpty(freightMovements))
        newState.freightMovements = freightMovements.sort((a, b) => this.convertToDate(a?.deliveryDatetime) - this.convertToDate(b?.deliveryDatetime));
      if (this.state.order && isEmpty(this.state.customerNgrs) && !this.state.fetchingCustomerNgrs) {
        newState.fetchingCustomerNgrs = true;
        this.getNgrs(this.state.order.customer.company.id, undefined, 'customerNgrs');
      }
      if (this.state.order && isEmpty(this.state.freightProviderNgrs) && !this.state.fetchingFreightProviderNgrs) {
        newState.fetchingFreightProviderNgrs = true;
        this.getNgrs(this.state.order.provider.id, undefined, 'freightProviderNgrs');
      }

      const selectedInvoiceConditionsValue = (this.props.selectedInvoice && get(this.props.selectedInvoice, 'invoiceConditions')) || ""
      const oldInvoiceConditionsValue = newState.fields.invoiceConditions.value || ""
      if(this.isDuplicatingInvoice){
        if (
          !oldInvoiceConditionsValue &&
          selectedInvoiceConditionsValue &&
          !this.state.isDefaultGeneralConditionApplied
        ) {
          newState.fields.invoiceConditions.value = oldInvoiceConditionsValue + selectedInvoiceConditionsValue;
          newState.isDefaultGeneralConditionApplied = true;
        }
      }
      else {
        if (!isEmpty(this.props.generalConditionsAll) && !this.state.isDefaultGeneralConditionApplied) {
          newState.fields.invoiceConditions.value = map(filter(this.props.generalConditionsAll, { default: true, module: 'invoice' }), 'details').join('\n');
          newState.isDefaultGeneralConditionApplied = true;
        }
      }

      if (this.state.order && isEmpty(this.state.freightProviderEmployees) && !this.state.fetchingFreightProviderContacts) {
        newState.fetchingFreightProviderContacts = true;
        this.getCompanyEmployees(this.state.order.provider, 'freightProvider');
      }
      if (this.state.order && isEmpty(this.state.customerEmployees) && !this.state.fetchingCustomerContacts) {
        newState.fetchingCustomerContacts = true;
        this.getCompanyEmployees(this.state.order.customer.company, 'customer');
      }
      if (this.setDefaultPaymentDueDate && !isEmpty(newState.freightMovements)) {
        this.setDefaultPaymentDueDate = false;
        newState.fields.paymentDueDate.value = this.estimatedPaymentDueDate('YYYY-MM-DD', newState.freightMovements);
      }
    }
    if (isEmpty(this.state.billToParties)) {
      newState.billToParties = newState.billToParties.concat(this.props.parties);
    }
    if (!isEqual(this.props.serverErrors, prevProps.serverErrors)) {
      forEach(this.props.serverErrors, (value, key) => {
        if (has(newState.fields, key)) {
          newState.fields[key].errors = value;
        }
      });
      if (!isEmpty(this.props.serverErrors)) {
        newState.preview = false;
      }
    }

    if (!isEmpty(this.props.generalConditionsAll) && !this.selectedContract && !this.state.isDefaultGeneralConditionApplied && !this.isDuplicatingInvoice) {
      newState.fields.invoiceConditions.value = map(filter(this.props.generalConditionsAll, { default: true }), 'details').join('\n');
      newState.isDefaultGeneralConditionApplied = true;
    }

    if (this.props.selectedInvoice && !prevProps.selectedInvoice) {
      newState.fields.identifier.value = get(this.props.selectedInvoice, 'identifier');
      if(this.isDuplicatingInvoice && get(this.props.selectedInvoice, 'status') != 'void')
        newState.fields.identifier.value = generateUpdatedIdentifier(newState.fields.identifier.value);
      newState.fields.note.description = get(this.props.selectedInvoice, 'note.description', '');
    }

    if (!isEqual(this.state, newState))
      this.setState(newState);

    if(
      this.state.freightProviderEmployees.length > 0 &&
      this.state.customerEmployees.length > 0 &&
      this.props.selectedInvoice &&
      this.isDuplicatingInvoice
    ){
      this.setNGRAndContactFromSelectedInvoice();
      this.isDuplicatingInvoice = false;
    }

    if (this.props.isItemsFetched && !this.props.forceStopLoader && this.props.isFetchedFreightContracts) {
      this.props.dispatch(forceStopLoader());
      this.setUpDefaultStateSelected();
    }
  }

  setNGRAndContactFromSelectedInvoice() {
    const newState = { ...this.state };
    newState.fields.customerContact.value = find(this.state.customerEmployees, {id: get(this.props.selectedInvoice, 'payer.contactId')});
    newState.fields.customerNgrId.value = get(this.props.selectedInvoice, 'payer.ngrId');
    newState.fields.freightProviderContact.value = find(this.state.freightProviderEmployees, {id: get(this.props.selectedInvoice, 'payee.contactId')});
    newState.fields.freightProviderNgrId.value = get(this.props.selectedInvoice, 'payee.ngrId');
    this.setState(newState);
  }

  getSelectedInvoiceCustomItems() {
    let customItems = [];
    let customItemId = 1;
    const newState = { ...this.state };
    forEach(this.props.selectedInvoice.others, (item) => {
      let customItem = {};
      customItem.description = item.description;
      customItem.subTotal = returnFloatFromString(item.totalExGst);
      customItem.gst = returnFloatFromString(item.gst);
      customItem.total = returnFloatFromString(item.total);
      customItem.itemType = CUSTOM_ITEM_TYPE;
      customItem.itemId = customItemId;
      customItem.id = customItemId;
      customItem.invoice = true;
      customItem.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
      customItems.push(customItem);
      customItemId += 1;
    });
    newState.customItemId = customItemId;
    newState.customItems = [...newState.customItems, ...customItems];
    newState.selectedItems = [...newState.selectedItems, ...customItems];
    newState.customItems.sort((a, b) => new Date(moment(a.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime() - new Date(moment(b.date, 'DD/MM/YYYY').format("YYYY/MM/DD")).getTime());
    this.setState(newState);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(getContractsResponse([]));
    dispatch(loadFreightInvoiceItems(false));
    dispatch(movementCarryItemsResponse([]));
    dispatch(receiveGeneralConditions([]));
    dispatch(isFreightContractsFetched(false));
    this.closeNgrSideDrawer();
  }

  estimatedPaymentDueDate(format = this.state.countryFormats.date, sortedMovements) {
    const selectedPaymentTerm = this.state.order ? get(this.state.order, 'paymentTerm') : null;
    const customer = this.state.order ? get(this.state.order, 'customer') : null;
    let lastDate = moment();
    let val = null;
    if (has(selectedPaymentTerm, 'id') && customer) {
      if(!isEmpty(sortedMovements) && !selectedPaymentTerm.name.includes('Invoice Date'))
        lastDate = moment(Math.max(...sortedMovements.map(movement => moment(get(movement, 'date'), 'DD/MM/YYYY'))));

      let startOfWeek = get(customer, 'company.startOfWeek');
      let endOfWeek = get(customer, 'company.endOfWeek');

      const periodType = get(selectedPaymentTerm, 'periodType');
      if (periodType == 'weeks') {
        const nextFridayFromDate = nextEndDayFrom(lastDate, endOfWeek);
        val = Object.prototype.toString.call(nextFridayFromDate) == '[object Object]' && nextFridayFromDate.add(selectedPaymentTerm.period, 'days').format(format);
      }
      else if (periodType == 'months') {
        val = lastDate.endOf('month').add(selectedPaymentTerm.period, 'days').format(format);
      }
      else if (periodType == 'days') {
        if (selectedPaymentTerm.name.includes('Business Days')) {
          const startDay = lastDate.weekday();
          const weekdays = getWeekDays(startOfWeek, endOfWeek);
          if (startDay || startDay == 0) {
            const daysToAdd = getDaysToAdd(startDay, selectedPaymentTerm.period, weekdays);
            val = lastDate.add(daysToAdd, 'days').format(format);
          }
        }
        else {
          val = lastDate.add(selectedPaymentTerm.period, 'days').format(format);
        }
      }
      else if(periodType == 'date'){
        val = getEstimatedPaymentDueDateForDatePaymentTerm(lastDate, selectedPaymentTerm, format);
      }
      else {
        val = selectedPaymentTerm.name;
      }
      return val === 'Invalid date' ? moment().format(format) : val;
    } else {
      return null;
    }
  }

  focusOnFirstErrorField() {
    const autoCompleteFields = ["freightOrderNumber", "customerContact", "freightProviderContact", "payeeBankName"];
    const nestedFields = ["payee.accountName", "payee.accountNumber", "payee.bsbNumber", "payee.accountNumber"];

    for (let i = 0; i < this.fieldsOrder.length; i++) {
      const formField = this.fieldRef[this.fieldsOrder[i]];
      const field = this.state.fields[this.fieldsOrder[i]];

      if (nestedFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (this.fieldsOrder[i] === "payee.bankName" && this.state.fields.payee.bankName.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
        if ((this.fieldsOrder[i] === "payee.accountName" && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === "payee.accountNumber" && this.state.fields.payee.accountName.errors.length > 0) ||
          (this.fieldsOrder[i] === "payee.bsbNumber" && this.state.fields.payee.accountName.errors.length > 0)) {
          formField.current.focus();
          break;
        }
      } else if (autoCompleteFields.indexOf(this.fieldsOrder[i]) !== -1) {
        if (field && field.errors.length > 0) {
          getAutoSelectFocusField(this.fieldRef, this.fieldsOrder[i]);
          break;
        }
      } else if (field && field.errors.length > 0) {
        if (formField.current.node) {
          formField.current.node.previousSibling.focus();
          break;
        } else {
          formField.current.focus();
          break;
        }
      }
    }
  }


  freightProviderSideDrawer() {
    const freightProviderDetails = this.getFreightProviderCompanyDetails();
    const freightProvider = get(freightProviderDetails, 'company');
    const companyId = get(freightProvider, 'id');
    return this.props.isEmployeeSideDrawerOpened &&
      freightProvider &&
      <SideDrawer
        isOpen={this.state.freightProviderSideDrawerIsOpen}
        title={getLabelForEmployeeForm(freightProvider)}
        onClose={this.closeFreightProviderContactSideDrawer}
        app="officeEmployee"
        canCreate={true}
      >
        <CreateEmployee
          closeDrawer={this.closeFreightProviderContactSideDrawer}
          canAccessAny={true}
          companyId={companyId}
          selectedCompany={freightProvider}
          establishmentDetails={freightProviderDetails}
          getContacts={this.getCompanyEmployees}
        />
      </SideDrawer>;
  }

  getAddCustomerContactButton() {
    const party = this.state.fields.selectedCustomerParty.value || get(this.state.order, 'customer');
    return party && <AddCircleIconWithTooltip
      id="addCustomerContact"
      onClick={() => this.openCustomerContactSideDrawer(party)}
      title="Add Customer Contact"
    />;
  }

  getAddFreightProviderContactButton() {
    const party = this.state.fields.selectedFreightProviderParty.value || get(this.state.order, 'provider');
    return party && <AddCircleIconWithTooltip
      id="addFreightProviderContact"
      onClick={() => this.openFreightProviderContactSideDrawer(party)}
      title="Add Freight Provider Contact"
    />;
  }

  getCompanyEmployees = (party, type, orderId = '') => {
    const api = party.entity === 'farm' ? APIService.contracts().farms(party.id).contacts() : APIService.contracts().companies(party.id).contacts();
    type = this.getPartyType(type, party);
    api.get(this.props.token)
      .then(employees => {
        this.setEmployeesAndContactForParty(type, employees, orderId);
      });
  };

  getContacts(party, type, contactId = '') {
    const api = APIService.contracts().companies(party.id).contacts();
    api.get(this.props.token)
      .then(employees => {
        if (party.entity === 'farm') {
          APIService.contracts().companies(party.companyId).contacts().get(this.props.token)
            .then(contacts => {
              this.setEmployeesAndContactForParty(type, uniqBy(employees.concat(contacts), 'id'), contactId);
            });
        } else {
          this.setEmployeesAndContactForParty(type, employees, contactId);
        }
      });
  }


  getField = (id, label, value, disabled) => {
    return <div className="col-md-12 padding-reset form-wrap">
      <div className="col-md-12 padding-reset form-wrap">
        <CommonTextField id={id} label={label} value={value} disabled={disabled} />
      </div>
    </div>;
  };


  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key] ? this.state.fields[key].value : '';
    const validators = this.state.fields[key] ? this.state.fields[key].validators : [];
    if (validators) {
      validators.forEach((validator) => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }
    return errors;
  }

  getFreightMovements() {
    let freightMovements = [];
    let fms = filter(this.props.freightMovements, (item) => { return !item.isFreightInvoiced; });

    filter(fms, fm => includes(
      ['delivered', 'completed', 'load_rejected'], fm.status)).forEach((fm) => {
        let rateExGST = get(this.state.order, 'rateFreightOut') ? parseFloat(get(this.state.order, 'rateFreightOut')).toFixed(2) : 0;
        let oversRate = parseFloat(fm.rateOvers).toFixed(2);
        let totalRate = rateExGST;
        if (!isNaN(oversRate)) {
          totalRate = parseFloat(totalRate) + parseFloat(oversRate);
        }
        let subTotal = +(totalRate * fm.actualTonnage).toFixed(2);
        const movement = find(this.state.freightMovements, { itemId: fm.id });
        freightMovements.push({
          'tonnage': fm.actualTonnage,
          'rateExGST': +(rateExGST),
          'currency': get(fm, 'currency'),
          'oversRate': !isNaN(oversRate) ? `${get(fm, 'currency')} ${oversRate}` : "N/A",
          'totalRate': `${get(fm, 'currency')} ${parseFloat(totalRate).toFixed(2)}`,
          'gst': this.state.gstApplicable ? +(subTotal * this.state.gstRate).toFixed(2) : 0,
          'total': +(subTotal * (this.state.gstApplicable ? (1 + this.state.gstRate) : 1)).toFixed(2),
          'itemType': FREIGHT_CONTRACT_ITEM_TYPE,
          'itemId': fm.id,
          'description': get(fm, 'freightInvoiceMovementDescription'),
          'date': moment(fm.deliveryDatetime).format(this.state.countryFormats.date),
          'isBHC': this.isBhc(fm),
          'totalDistance': fm.estimatedDistance,
          'identifier': fm.identifier,
          'subTotal': subTotal,
          'invoice': fm.actualTonnage === 0 ? false : get(movement, 'invoice', this.state.isDefaultSelected)
        });
      });
    const sortedFMs = freightMovements.sort((a, b) => (a.date > b.date) ? 1 : -1);
    if (this.props.isItemsFetched && !this.props.isFetchedFreightContracts)
      this.props.dispatch(loadFreightInvoiceItems(true));
    return sortedFMs;
  }


  isBhc(fm) {
    return get(fm, 'freightDelivery.consignee.sites.0.locationContentType') === 'storage' && get(fm, 'freightDelivery.consignee.sites.0.location.storageType') === 'system';
  }

  getFreightProviderCompanyDetails() {
    return {
      'company': this.getSelectedFreightProviderCompany(),
      'value': 'provider',
    };
  }

  getFreightProviderNgrErrors(newState) {
    const freightProviderNgr = this.selectedFreightProviderNgr();
    const selectedFreightProviderParty = this.state.fields.selectedFreightProviderParty.value || get(this.state.order, 'provider');
    const freightProviderErrorMsg = canAccessAny(selectedFreightProviderParty) ? NGR_MISSING_DETAILS_ERROR : NGR_MISSING_DETAILS_ERROR_WITHOUT_ACCESS;
    newState.fields['freightProviderNgrId'].errors = [];
    if (this.state.fields.freightProviderNgrId.value && selectedFreightProviderParty) {
      if (!isEmpty(freightProviderNgr.bankAccounts)) {
        freightProviderNgr.bankAccounts.forEach((account) => {
          if (!(account.name && account.accountName && account.accountNumber && account.bsbNumber)) {
            newState.fields['freightProviderNgrId'].errors = [freightProviderErrorMsg];
          }
        });
      } else {
        newState.fields['freightProviderNgrId'].errors = [freightProviderErrorMsg];
      }
    }
  }

  getNgrs(partyId, newlyCreatedNgr, fieldName) {
    APIService.companies(partyId).ngrs().get(this.props.token)
      .then(items => {
        const newState = { ...this.state };
        if (fieldName) {
          newState[fieldName] = items;
        }
        if (newlyCreatedNgr) {
          if (this.state.order.provider.id === partyId) {
            newState.fields.freightProviderNgrId.value = newlyCreatedNgr.id;
            newState.freightProviderNgrs = items;
          } else {
            newState.fields.customerNgrId.value = newlyCreatedNgr.id;
            newState.customerNgrs = items;
          }
        }
        this.setState(newState);
        this.updateFreightProviderNgrDetails(fieldName);
      });
  }


  getPartyType(type, party) {
    if (!type) {
      if (isEqual(party, this.getSelectedSellerCompany())) {
        type = 'freightProvider';
      } else if (isEqual(party, this.getSelectedBuyerCompany())) {
        type = 'customer';
      }
    }
    return type;
  }

  getSelectedFreightProviderCompany() {
    const party = this.state.fields.selectedFreightProviderParty.value || get(this.state.order, 'provider');
    return get(party, 'company') || party;
  }

  handleAccountChangeForEntity(event, key) {
    const newState = { ...this.state };
    const id = event.target.id;
    const value = event.target.value
    newState.fields[key][id].value = value;
    if(id == 'bsbNumber' && value && isEqual(value.length, get(this.countryConfig, 'bank.bsbLength'))) {
      let bsbBank = find(this.props.banks, {bsbNumber: newState.fields[key][id].value});
      if(bsbBank) {
        newState.fields[key]['bankName'].id = bsbBank.id;
        newState.fields[key]['bankName'].value = bsbBank.name;
        newState.fields[key]['bankName'].errors = [];
      }
    }
    this.setState(newState, () => {
      if (key === 'payee') {
        this.setPartyFieldErrors(`${key}.${id}`);
      }
    });
  }

  handleAddItem(item) {
    const newState = { ...this.state };
    if (item.modifier && item.modifier === 'minus') {
      item.subTotal = -1 * item.subTotal;
      if (item.gst) {
        item.gst = -1 * item.gst;
        item.total = -1 * item.total;
      }
    }
    if (item.gst === '') {
      item.gst = 'N/A';
      item.total = item.subTotal;
    }
    item.itemType = 'customitem';
    item.itemId = newState.customItemId;
    item.id = newState.customItemId;
    item.invoice = true;
    item.date = moment(item.date, 'YYYY-MM-DD').format(this.state.countryFormats.date);
    newState.customItems.push(item);
    newState.selectedItems.push(item);
    newState.openSideDrawer = false;
    newState.customItemId += 1;
    this.setState(newState);
  }

  handleBlur(event) {
    this.setFieldErrors(event.target.id, event.target.value);
  }

  handleConditionSelector(value, id, chosenItem) {
    var key = 'selectedGeneralConditionText';

    const newState = { ...this.state };

    if (chosenItem) {
      newState[key] = chosenItem.details;
      newState[id] = value;
    } else {
      newState[key] = undefined;
      newState[id] = undefined;
    }

    this.setState(newState);
  }

  handleConfirmDialogCancel() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false
    });
  }

  handleConfirmDialogOk() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: false,
    });
    window.location.hash = '/freights/orders/' + (this.state.order ? get(this.state.order, 'id') : null) + '/freights/invoices';
  }

  handleConfirmDialogOpen() {
    this.setState({
      ...this.state,
      isConfirmDialogOpen: true
    });
  }

  handlePreviewClick() {
    this.setState({
      ...this.state,
      preview: true
    });
  }

  handlePreviewClose() {
    this.setState({
      ...this.state,
      preview: false
    });
    this.props.deleteInvoice(this.props.invoiceId);
  }

  handleCustomerContactChange(value, id = 'customerContact') {
    if (value !== this.state.fields.customerContact.value)
      this.setFieldValue(id, value);
  }

  handleCustomerNgrChange(id) {
    this.setFieldValue('customerNgrId', id);
    this.closeNgrSideDrawer();
    if (id) {
      this.setState({ ...this.state, displayCustomerBankForm: false });
    } else {
      this.setState({
        ...this.state,
        displayCustomerBankForm: true
      });
    }
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleFreightProviderContactChange(value, id = 'freightProviderContact') {
    this.setFieldValue(id, find(this.state.freightProviderEmployees, { id: value }));
  }

  handleFreightProviderNgrChange(id) {
    const newState = { ...this.state };
    newState.fields.freightProviderNgrId.value = id;
    if (id) {
      let freightProviderNgr = this.state.freightProviderNgrs.filter((ngr) => { return ngr.id === id; });
      let freightProviderNgrAccountNumber = get(freightProviderNgr[0], 'bankAccounts[0].accountNumber');
      newState.fields.freightProviderNgrId.validators = [required()];
      newState.displayFreightProviderBankForm = false;
      newState.freightProviderNgrAccountNumber = freightProviderNgrAccountNumber;
    } else {
      newState.freightProviderNgrAccountNumber = '';
      newState.fields.freightProviderNgrId.validators = [];
      newState.displayFreightProviderBankForm = true;
    }
    this.closeNgrSideDrawer();
    this.setState(newState, () => this.setFreightProviderNgrErrors());
  }

  handleIdentifierChange(event) {
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    this.setFieldValue(event.target.id, value);
  }

  changeFreightProviderNgrValidators(newState) {
    if (newState.displayFreightProviderBankForm) {
      newState.fields.freightProviderNgrId.validators = [];
    } else {
      newState.fields.freightProviderNgrId.validators = [required()];
    }
  }

  setUpDefaultStateSelected = () => {
    if (!this.state.isDefaultSelected) {
      const newState = { ...this.state };
      forEach(newState.freightMovements, item => this.handleItemClick(item, Boolean(item.tonnage)));
      newState.isDefaultSelected = true;
      this.setState(newState);
    }
  };

  handleItemClick(item, checked) {
    const newState = { ...this.state };
    this.updateSelectedItem(newState.customItems, item, checked, CUSTOM_ITEM_TYPE);
    if (checked) {
      newState.selectedItems.push({ ...item, invoice: checked });
    } else {
      this.updateSelectedItems(newState, item);
    }
    forEach(newState.freightMovements, movement => {
      if (movement.itemId === item.itemId) {
        movement.invoice = checked;
      }
    });
    this.setState(newState);
  }

  handlePayeeBankChange = () => value => {
    const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
    const newState = { ...this.state };
    newState.fields.payee.bankName.value = selectedBank.name;
    newState.fields.payee.bankName.id = selectedBank.id;
    this.setState(newState);
  };

  handlePayerBankChange = () => value => {
    const selectedBank = value ? this.props.banks.find(e => e.id === value) : { name: '', id: '' };
    const newState = { ...this.state };
    newState.fields.payer.bankName.value = selectedBank.name;
    newState.fields.payer.bankName.id = selectedBank.id;
    this.setState(newState);
  };

  handlePaymentDueDateChange(value, id) {
    this.setFieldValue(id, value);
  }

  checkIfSelectedItemWithOtherCurrencyIncluded() {
    let selectedFms = filter(this.state.selectedItems, item => item?.itemType == FREIGHT_CONTRACT_ITEM_TYPE);
    let uniqueCurrencies = Array.from(new Set(compact(map(selectedFms, item => item?.currency))))
    return uniqueCurrencies.filter(currency => currency != get(this.state.order, 'currency')).length != 0;
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setAllFieldsErrors(() => {
      let isPayeeValid = true;
      const isFormInvalid = some(this.state.fields, (field) => {
        return field.errors.length > 0;
      });

      if (this.state.displayFreightProviderBankForm) {
        isPayeeValid = every(this.state.fields.payee, (key) => {
          return isEmpty(get(key, 'errors'));
        });
      }

      if (this.state.selectedItems.length === 0) {
        alertifyjs.error("Please select at least one item to be invoiced");
        return;
      }
      if(this.checkIfSelectedItemWithOtherCurrencyIncluded()) {
        alertifyjs.alert(
          "Warning",
          "Invoice has amounts in multiple currencies, please ensure all the amounts are in same currency")
        return;
      }
      if (!isFormInvalid && isPayeeValid) {
        const data = mapValues(this.state.fields, (field) => {
          return field.value;
        });
        data.raisedForType = 'freightorder';
        data.raisedForId = this.state.order.id;
        data.invoiceItems = cloneDeep(this.state.selectedItems).concat(cloneDeep(this.state.carryItems));
        let validTonnageItems = this.state.selectedItems.filter((item) => { return !!item.tonnage; });
        data.tonnage = reduce(validTonnageItems, function (sum, item) { return sum + item.tonnage; }, 0);
        data.invoiceItems.forEach((item) => {
          if (!includes([CUSTOM_ITEM_TYPE], item.itemType)) {
            delete item['description'];
            delete item['date'];
            delete item['rateExGST'];
            delete item['oversRate'];
            delete item['totalRate'];
          }
          if (item.itemType === CUSTOM_ITEM_TYPE) {
            delete item['id'];
            delete item['itemId'];
            if (item['gst'] === 'N/A') {
              item['gst'] = 0;
            }
          }
          if (item.itemType === FREIGHT_CONTRACT_ITEM_TYPE) {
            delete item['identifier'];
            delete item['isBHC'];
            delete item['totalDistance'];
          }
          delete item['invoice'];
          delete item['freightMovementIdentifier'];
          if (has(item, 'currency'))
            delete item['currency'];
        });
        data.subTotal = this.state.subTotal;
        data.gst = this.state.gst;
        data.total = this.state.total;
        data.type = FREIGHT;
        delete data['customerNgrId'];
        delete data['freightProviderNgrId'];
        delete data['customerContact'];
        delete data['freightProviderContact'];
        delete data['selectedFreightProviderParty'];
        delete data['selectedCustomerParty'];
        delete data['selectedOrder'];

        this.setPayerData(data);
        this.setPayeeData(data);

        data.note = omit(this.state.fields.note, ['errors']);

        this.props.dispatch(isLoading('alertify'));
        this.props.submit(data,  response => this.setState({xeroCounterPartyExists: response.hasCounterPartyXeroContact}));
        this.handlePreviewClick();
      }
    });
    this.focusOnFirstErrorField();
  }

  isCustomerRcti() {
    return get(this.state, 'order.invoicing') === 'Customer RCTI';
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  openAddNgrSideDrawer(ngrEntity, type) {
    ngrEntity.type = type;
    const selectedParty = get(ngrEntity, 'company', ngrEntity);
    const companyId = get(selectedParty, 'id');
    if (companyId) {
      const { dispatch } = this.props;
      const selectedEntity = find(this.props.companyParties, {id: companyId});
      dispatch(canCreateNgr(companyId, 'company', selectedEntity, () => {
        this.props.handleAddCompanyNgrButtonClick();
        this.props.handleAddNgrButtonClick();
        this.setState({ ngrEntity: selectedParty }, () => this.commonOpenSideDrawer('ngrSideDrawerIsOpen'));
      }));
    }
  }

  openCustomerContactSideDrawer(party) {
    const selectedParty = get(party, 'company', party);
    const companyId = get(selectedParty, 'id');
    if (companyId) {
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.commonOpenSideDrawer('customerSideDrawerIsOpen');
      }));
    }
  }

  openFreightProviderContactSideDrawer(party) {
    const selectedParty = party;
    const companyId = get(selectedParty, 'id');
    if (companyId) {
      const { dispatch } = this.props;
      dispatch(canCreateEmployee(companyId, 'company', () => {
        this.props.handleAddEmployeeButtonClick();
        this.commonOpenSideDrawer('freightProviderSideDrawerIsOpen');
      }));
    }
  }

  openInvoiceConditionSideDrawer() {
    this.props.dispatch(clickAddGeneralConditionButton());
    this.commonOpenSideDrawer('invoiceConditionSideDrawerIsOpen');
  }


  openNgrSideDrawer(selectedEntity, ngrs, ngrId) {
    const { dispatch } = this.props;
    this.commonOpenSideDrawer();
    dispatch(receiveCompanyNgrs(ngrs));
    dispatch(clickEditCompanyNgr(ngrId));
    this.setState({
      entityForNgrUpdate: (selectedEntity.company || selectedEntity),
      fetchingCustomerNgrs: false,
      fetchingFreightProviderNgrs: false,
    });
  }


  openSideDraw() {
    this.commonOpenSideDrawer('openSideDrawer');
  }


  relatedItemToFm(selectedItem, item) {
    return selectedItem.freightMovementIdentifier === item.identifier && item.itemType === FREIGHT_CONTRACT_ITEM_TYPE;
  }

  removeRelatedItems(relatedItems, item) {
    remove(relatedItems, selectedItem => {
      return (
        selectedItem.freightMovementIdentifier === item.identifier && item.itemType === FREIGHT_CONTRACT_ITEM_TYPE
      );
    });
  }

  selectedFreightProviderNgr() {
    return find(this.state.freightProviderNgrs, { id: this.state.fields.freightProviderNgrId.value }) || get(this.state.order, 'provider.id');
  }


  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    this.changeFreightProviderNgrValidators(newState);
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    if (this.state.order) {
      forEach(['selectedOrder', 'selectedCustomerParty', 'selectedFreightProviderParty'], (key) => {
        newState.fields[key].errors = [];
      });
    }
    if (this.state.displayFreightProviderBankForm) {
      applyValidatorsOn(newState.fields.payee);
    }
    this.setState(newState, callback);
    if (isEmpty(newState.fields['freightProviderNgrId'].errors)) {
      this.setFreightProviderNgrErrors();
    }
  }

  setDefaultGeneralCondition(condition) {
    this.closeInvoiceConditionSideDrawer();
    if (condition) {
      this.handleConditionSelector(condition.id, 'generalConditionsSelector', condition);
    }
  }

  setEmployeesAndContactForParty(type, employees, contactId) {
    if (type) {
      this.setState({ [type + 'Employees']: employees }, () => {
        if (contactId)
          this.updateSelectedContactForParty(contactId, type);
      });
    }
  }

  setEntityBankDetails(data, key) {
    data[key]['bankAccount'] = {};
    data[key]['bankAccount']['bank_id'] = get(find(this.props.banks, {name: this.state.fields[key].bankName.value}), 'id', '');
    data[key]['bankAccount']['accountName'] = this.state.fields[key].accountName.value;
    data[key]['bankAccount']['bsbNumber'] = this.state.fields[key].bsbNumber.value;
    data[key]['bankAccount']['accountNumber'] = this.state.fields[key].accountNumber.value;
  }


  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  setFieldValue(key, value) {
    const newState = { ...this.state };
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  setFreightProviderNgrErrors = () => {
    const newState = { ...this.state };
    this.getFreightProviderNgrErrors(newState);
    this.setState(newState);
  };

  setHeaderAndBreadcrumbs() {
    const { dispatch } = this.props;
    const queryParams = queryString.parse(this.props.location.search);
    const orderId = parseInt(get(queryParams, 'orderId'));
    const freightOrderNo = this.state.order ? this.state.order.identifier : null;
    let breadcrumbs = [{ text: 'Invoices' }];
    let headerText = 'Invoices';
    if (orderId) {
      breadcrumbs = [
        { text: 'Invoices', route: '/orders/freights' },
        { text: get(this.state.order, 'identifier', ''), route: '/freights/orders/' + orderId + '/order' },
        { text: 'Invoices' }
      ];
      headerText = 'Freight Order ' + freightOrderNo;
    }
    dispatch(setHeaderText(headerText));

    if (!isEqual(this.props.breadcrumbs, breadcrumbs)) {
      dispatch(setBreadcrumbs(breadcrumbs));
    }
  }

  setOrder = async (relatedOrderId = null) => {
    const queryParams = queryString.parse(this.props.location.search);
    const orderId = parseInt(get(queryParams, 'orderId') || relatedOrderId);
    await APIService.freights().orders(orderId).get(this.props.token).then(response => {
      this.setState({ order: response }, async () => {
        this.setHeaderAndBreadcrumbs();
        this.getContacts(get(this.state, 'order.customer.company'), 'customer');
        this.getContacts(get(this.state, 'order.provider'), 'freightProvider');
      });
    });
    if (this.props.isItemsFetched && this.props.forceStopLoader)
      this.props.dispatch(forceStopLoader());
  };

  setPartyFieldErrors = (id) => {
    const newState = { ...this.state };
    let errors = [];
    const validators = get(newState, `fields.${id}.validators`);
    const value = get(newState, `fields.${id}.value`);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    set(newState, `fields.${id}.errors`, errors);
    this.setState(newState);
  };

  setPayeeData(data) {
    data['payee'] = {};
    data['payee']['companyId'] = this.state.order.provider.id;
    data['payee']['contactId'] = this.state.fields.freightProviderContact.value.id;
    data['payee']['ngrId'] = this.state.fields.freightProviderNgrId.value;
    this.setEntityBankDetails(data, 'payee');
  }

  setPayerData(data) {
    data['payer'] = {};
    data['payer']['companyId'] = this.state.order.customer.company.id;
    data['payer']['contactId'] = this.state.fields.customerContact.value;
    data['payer']['ngrId'] = this.state.fields.customerNgrId.value;
    this.setEntityBankDetails(data, 'payer');
  }

  updateNgrs(newState, updatedNgr) {
    this.updateFreightProviderNgr(updatedNgr, newState);
    this.updateCustomerNgr(updatedNgr, newState);
  }

  updateCustomerNgr(updatedNgr, newState) {
    if (updatedNgr.id === this.state.fields.customerNgrId.value && !newState.fetchingCustomerNgrs) {
      const selectedCustomerParty = this.state.fields.selectedCustomerParty.value || get(this.state.order, 'customer');
      newState.fetchingCustomerNgrs = true;
      this.updateNgrsForParty(selectedCustomerParty, 'customerNgrs');
    }
  }

  updateFreightProviderNgr(updatedNgr, newState) {
    if (updatedNgr.id === (get(this.state.fields, 'freightProviderNgrId.value')) && !newState.fetchingFreightProviderNgrs) {
      const selectedFreightProviderParty = this.state.fields.selectedFreightProviderParty.value || get(this.state.order, 'provider');
      newState.fetchingFreightProviderNgrs = true;
      this.updateNgrsForParty(selectedFreightProviderParty, 'freightProviderNgrs');
    }
  }

  updateFreightProviderNgrDetails(fieldName) {
    let freightProviderNgrId = get(this.state.fields, 'freightProviderNgrId.value') || get(this.state.order, 'provider.id');
    if (fieldName === 'freightProviderNgrs' && freightProviderNgrId) {
      this.handleFreightProviderNgrChange(freightProviderNgrId);
    }
  }

  openCustomEmailDialog() {
    this.setState({ showCustomEmailDialog: true });
  }

  closeCustomEmailDialog(data, justClose) {
    if (justClose)
      this.setState({ showCustomEmailDialog: false });
    else if (this.state.showCustomEmailDialog) {
      const communicationData = {};
      if (data) {
        delete data.body;
        communicationData['communication'] = data;
      }
      this.setState({ showCustomEmailDialog: false }, () => {
        this.props.generateInvoice(
          this.props.invoiceId, null, get(this.state.order, 'id'), communicationData
        );
      });
    }
  }

  handlePreviewSubmit() {
    this.openCustomEmailDialog();
  }

  updateNgrChanges(prevProps, newState) {
    if (this.props.updatedNgr && prevProps.updatedNgr !== this.props.updatedNgr) {
      this.updateNgrs(newState, this.props.updatedNgr);
    }

    if (this.props.updatedBillToNgr && prevProps.updatedBillToNgr !== this.props.updatedBillToNgr) {
      this.updateNgrs(newState, this.props.updatedBillToNgr);
    }
  }

  updateNgrsForParty(selectedParty, ngrsKey) {
    let partyId = get(selectedParty, 'company.id') || get(selectedParty, 'id');
    this.getNgrs(partyId, undefined, ngrsKey);
  }

  updateSelectedContactForParty(contactId, type) {
    if (contactId) {
      if (type === 'customer') {
        this.handleCustomerContactChange(contactId);
      } else if (type === 'freightProvider') {
        this.handleFreightProviderContactChange(contactId);
      }
    }
  }

  updateSelectedItem(items, itemToBeUpdated, selected, itemType) {
    let item = items.find(i => i.itemId === itemToBeUpdated.itemId && itemToBeUpdated.itemType === itemType);
    if (item)
      item.invoice = selected;
  }
  updateSelectedItems(newState, item) {
    remove(newState.selectedItems, selectedItem => {
      return (
        this.areSame(selectedItem, item)
      )
        || (
          this.relatedItemToFm(selectedItem, item)
        );
    });
  }

  getPartyEmails() {
    return {
      customer: get(find(this.state.customerEmployees, {'id': get(this.state.fields, 'customerContact.value')}), 'email'),
      freightProvider: get(find(this.state.freightProviderEmployees, {'id': get(this.state.fields, 'freightProviderContact.value')}), 'email'),
    };
  }

  getPartyContacts() {
    return {
      customer: this.state.customerEmployees,
      freightProvider: this.state.freightProviderEmployees
    };
  }

  noteHandler = val => {
    this.setState({ fields: { ...this.state.fields, note: val } });
  };

  getSelectedParties() {
    if (get(this.state.order, 'providerId') === this.props.user.companyId) return ['customer'];
    else if (get(this.state.order, 'customer.companyId') === this.props.user.companyId) return ['freightProvider'];
  }

  render() {
    const currency = get(this.state.order, 'currency') || getCountryCurrency()
    const COLUMNS = [
      { key: 'invoice', header: 'Select Item', checkbox: true, onChange: 'handleItemClick' },
      { key: 'description', header: 'Description' },
      { key: 'date', header: 'Date' },
      { key: 'tonnage', header: 'Tonnage (in MT)' },
      { key: 'rateExGST', header: `Freight Rate (Ex ${getCountryLabel('gst')})` },
      { key: 'oversRate', header: `Overs Rate (Ex ${getCountryLabel('gst')})` },
      { key: 'totalRate', header: `Total Rate (Ex ${getCountryLabel('gst')})` },
      { key: 'subTotal', header: `Total (Ex ${getCountryLabel('gst')})` },
      { key: 'gst', header: `${getCountryLabel('gst')}` },
      { key: 'total', header: `Total (Inc ${getCountryLabel('gst')})` },
    ];

    const freightProvider = this.state.order ? this.state.order.provider.displayName : null;
    const customer = get(this.state, 'order.customer.company.displayName');
    const freightProviderAccountNumber = this.state.fields.payee.accountNumber.value || this.state.freightProviderNgrAccountNumber;
    const selectedCustomerParty = this.state.fields.selectedCustomerParty.value || get(this.state.order, 'customer');
    const selectedFreightProviderParty = this.state.fields.selectedFreightProviderParty.value || get(this.state.order, 'provider');
    const selectedCustomerCompany = get(selectedCustomerParty, 'company') || selectedCustomerParty;
    const selectedFreightProviderCompany = get(selectedFreightProviderParty, 'company') || selectedFreightProviderParty;
    const bankItems = this.props.banks && uniqBy(this.props.banks.map(e => ({ label: e.name, value: e.id })), 'label');

    const editCustomerNgrButton = this.state.fields.customerNgrId.value ?
      <Button variant="outlined" onClick={() => this.openNgrSideDrawer(selectedCustomerParty, this.state.customerNgrs, this.state.fields.customerNgrId.value)} className="edit-ngr-button">
        {!isTransactionParticipated(selectedCustomerCompany) ? 'Update NGR Details' : 'View NGR Details'}
      </Button>
      : null;

    const addCustomerNgrButton = (selectedCustomerParty && customer) ?
      <Tooltip title="Add Customer NGR" placement="top">
        <AddCircleIcon id="addCustomerNgr" onClick={() => this.openAddNgrSideDrawer(selectedCustomerParty, "customer")}
          className="material-icons add-new-right"
          style={{ fontSize: '30px' }} />
      </Tooltip>
      : null;

    const addFreightProviderNgrButton = (selectedFreightProviderParty && freightProvider) ?
      <Tooltip title="Add Freight Provider NGR" placement="top">
        <AddCircleIcon id="addFreightProviderNgr" onClick={() => this.openAddNgrSideDrawer(selectedFreightProviderParty, "freightProvider")}
          className="material-icons add-new-right"
          style={{ fontSize: '30px' }} />
      </Tooltip>
      : null;

    const editFreightProviderNgrButton = this.state.fields.freightProviderNgrId.value ?
      <Button variant="outlined" onClick={() => this.openNgrSideDrawer(selectedFreightProviderParty, this.state.freightProviderNgrs, this.state.fields.freightProviderNgrId.value)} className="edit-ngr-button">
        {!isTransactionParticipated(selectedFreightProviderCompany) ? 'Update NGR Details' : 'View NGR Details'}
      </Button>
      : null;


    const isNotGSTApplicable = (some(this.state.customItems, { gst: 'N/A' }));
    return (
      <div ref={this.formRef}>
        <form className="invoice-form" onSubmit={this.handleSubmit} noValidate>
          <div className="cardForm">
            <h4 className="cardForm-title">General</h4>
            <div className="cardForm-content">
              <div className="col-md-5">
                {this.getField('freightProvider', 'Freight Provider', get(this.state, 'order.provider.displayName'), true)}
                {this.getField('customer', 'Customer', get(this.state, 'order.customer.company.displayName'), true)}
                {this.getField('freightOrderNumber', 'Freight Order Number', get(this.state, 'order.identifier'), true)}
              </div>
              <div className="col-md-5 col-md-offset-1">
                <div className="col-md-12 padding-reset form-wrap">
                  <TextField
                    id="identifier"
                    inputRef={this.fieldRef["identifier"]}
                    error={!isEmpty(get(this.state, 'fields.identifier.errors[0]'))}
                    placeholder="Please enter 14 digit unique number"
                    label="Invoice Number"
                    value={get(this.state, 'fields.identifier.value')}
                    helperText={get(this.state, 'fields.identifier.errors[0]')}
                    onChange={this.handleIdentifierChange}
                    onBlur={this.handleBlur}
                    fullWidth
                    inputProps={{
                      maxLength: 14,
                    }}
                    variant='standard'
                  />
                </div>
                <div className="col-md-12 padding-reset form-wrap">
                  <div className="col-md-12 padding-reset form-wrap">
                    <CommonDatePicker
                      id="paymentDueDate"
                      setRef={this.fieldRef["paymentDueDate"]}
                      label="Payment Due Date"
                      floatingLabelText="Payment Due Date"
                      errorText={get(this.state, 'fields.paymentDueDate.errors[0]')}
                      value={get(this.state, 'fields.paymentDueDate.value')}
                      onChange={this.handlePaymentDueDateChange}
                      onBlur={this.checkDueDate}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="cardForm">
            <h4 className="cardForm-title">Invoicing Parties</h4>
            <div className="cardForm-content">
              <div className="col-md-5">
                {this.getField('customer', 'Customer', get(this.state, 'order.customer.company.displayName'), true)}
                <div className="col-md-12 padding-reset form-wrap">
                  <CommonAutoSelect
                    id="customerContact"
                    setRef={this.fieldRef["customerContact"]}
                    label="Contact"
                    items={this.state.customerEmployees}
                    value={this.state.fields.customerContact.value}
                    onBlur={this.onFieldBlur}
                    onChange={this.handleCustomerContactChange}
                    errorText={this.state.fields.customerContact.errors[0]}
                    dontAutoselectSingleItem
                  />
                  {this.getAddCustomerContactButton()}
                </div>
                <div className="col-md-12 padding-reset form-wrap" ref={this.fieldRef["customerNgrId"]}>
                  <CommonSelect
                    id="customerNgr"
                    floatingLabelText={this.state.displayCustomerBankForm ? 'Enter Bank Details' : 'Customer NGR (Optional)'}
                    selectConfig={{ text: 'ngrNumber', value: 'id' }}
                    items={this.state.customerNgrs}
                    fullWidth
                    onChange={this.handleCustomerNgrChange}
                    errorText={this.state.fields.customerNgrId.errors[0]}
                    value={this.state.fields.customerNgrId.value}
                    includeEmptyOption={true}
                    emptyOptionText="Enter Bank Details"
                    dontAutoselectSingleItem
                  />
                  {addCustomerNgrButton}
                  {editCustomerNgrButton}
                </div>
                {
                  this.state.displayCustomerBankForm ?
                    <div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="bsbNumber"
                          label={`${getCountryLabel('bsb')} Number (Optional)`}
                          value={this.state.fields.payer.bsbNumber.value}
                          onChange={(event) => this.handleAccountChangeForEntity(event, 'payer')}
                          type='number'
                          placeholder={`Please enter ${get(this.countryConfig, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.bsbLength'));
                          }}
                        />
                      </div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <AutoComplete
                          id="name"
                          label="Bank Name (Optional)"
                          placeholder="Bank Name (Optional)"
                          options={bankItems}
                          fullWidth
                          errorText={this.state.fields.payer.bankName.errors[0]}
                          onBlur={this.onPartyFieldBlur}
                          value={get(this.state.fields.payer.bankName, 'value')}
                          onChange={this.handlePayerBankChange()}
                        />
                      </div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="accountName"
                          label="Account Name (Optional)"
                          value={this.state.fields.payer.accountName.value}
                          onChange={(event) => this.handleAccountChangeForEntity(event, 'payer')}
                        />
                      </div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="accountNumber"
                          label="Account Number (Optional)"
                          value={this.state.fields.payer.accountNumber.value}
                          placeholder={`Please enter ${get(this.countryConfig, 'bank.accountNumberMinimumLength')}-${get(this.countryConfig, 'bank.accountNumberMaximumLength')} digits Account number`}
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.accountNumberMaximumLength'));
                          }}
                          type='number'
                          onChange={(event) => this.handleAccountChangeForEntity(event, 'payer')}
                        />
                      </div>
                    </div>
                    :
                    null
                }
              </div>
              <div className="col-md-5 col-md-offset-1">
                <div className="col-md-12 padding-reset form-wrap">
                  {this.getField('Freight Provider', 'Freight Provider', get(this.state, 'order.provider.name'), true)}
                </div>
                <div className="col-md-12 padding-reset form-wrap">
                  <CommonAutoSelect
                    id="freightProviderContact"
                    setRef={this.fieldRef["freightProviderContact"]}
                    label="Contact"
                    items={this.state.freightProviderEmployees}
                    value={this.state.fields.freightProviderContact.value}
                    onBlur={this.onFieldBlur}
                    onChange={this.handleFreightProviderContactChange}
                    errorText={this.state.fields.freightProviderContact.errors[0]}
                    selectedItem={this.state.fields.freightProviderContact.value}
                    dontAutoselectSingleItem
                  />
                  {this.getAddFreightProviderContactButton()}
                </div>
                <div className="col-md-12 padding-reset form-wrap">
                  <CommonSelect
                    id="freightProviderNgr"
                    label={this.state.displayFreightProviderBankForm ? 'Enter Bank Details' : 'Freight Provider NGR'}
                    setRef={this.fieldRef["freightProviderNgrId"]}
                    floatingLabelText={this.state.displayFreightProviderBankForm ? 'Enter Bank Details' : 'Freight Provider NGR'}
                    selectConfig={{ text: 'ngrNumber', value: 'id' }}
                    items={this.state.freightProviderNgrs}
                    fullWidth
                    onChange={this.handleFreightProviderNgrChange}
                    errorText={this.state.fields.freightProviderNgrId.errors[0]}
                    value={this.state.fields.freightProviderNgrId.value}
                    includeEmptyOption={true}
                    emptyOptionText="Enter Bank Details"
                    dontAutoselectSingleItem
                  />
                  {addFreightProviderNgrButton}
                  {editFreightProviderNgrButton}
                </div>
                {
                  this.state.displayFreightProviderBankForm ?
                    <div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="bsbNumber"
                          label={`${getCountryLabel('bsb')} Number`}
                          value={this.state.fields.payee.bsbNumber.value}
                          setRef={this.fieldRef["payee.bsbNumber"]}
                          helperText={this.state.fields.payee.bsbNumber.errors[0]}
                          type='number'
                          onChange={(event) => this.handleAccountChangeForEntity(event, 'payee')}
                          placeholder={`Please enter ${get(this.countryConfig, 'bank.bsbLength')} digits ${getCountryLabel('bsb')} number`}
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.bsbLength'));
                          }}
                        />
                      </div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <AutoComplete
                          id="name"
                          label="Bank Name"
                          placeholder="Bank Name"
                          options={bankItems}
                          fullWidth
                          setRef={this.fieldRef["payee.bankName"]}
                          errorText={this.state.fields.payee.bankName.errors[0]}
                          onBlur={this.onFieldBlur}
                          value={this.state.fields.payee.bankName.value}
                          onChange={this.handlePayeeBankChange()}
                        />
                      </div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="accountName"
                          label="Account Name"
                          setRef={this.fieldRef["payee.accountName"]}
                          value={this.state.fields.payee.accountName.value}
                          helperText={this.state.fields.payee.accountName.errors[0]}
                          onChange={(event) => this.handleAccountChangeForEntity(event, 'payee')}
                        />
                      </div>
                      <div className="col-md-12 padding-reset form-wrap">
                        <CommonTextField
                          id="accountNumber"
                          label="Account Number"
                          value={this.state.fields.payee.accountNumber.value}
                          setRef={this.fieldRef["payee.accountNumber"]}
                          helperText={this.state.fields.payee.accountNumber.errors[0]}
                          type='number'
                          onChange={(event) => this.handleAccountChangeForEntity(event, 'payee')}
                          placeholder={`Please enter ${get(this.countryConfig, 'bank.accountNumberMinimumLength')}-${get(this.countryConfig, 'bank.accountNumberMaximumLength')} digits Account number`}
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, get(this.countryConfig, 'bank.accountNumberMaximumLength'));
                          }}
                        />
                      </div>
                    </div>
                    :
                    null
                }
              </div>
            </div>
          </div>
          <div className="cardForm">
            <h4 className="cardForm-title">Items To Be Invoiced</h4>
            <div style={{ position: 'relative' }}>
              <AddButton
                disabled={!this.state.order}
                variant="contained"
                label="Custom Item"
                primary={true}
                className={'btn-green'}
                onClick={this.openSideDraw}
                style={{ float: 'right', marginRight: '20px', marginTop: '0px', position: 'absolute', right: '0px', top: '-50px' }} />

            </div>
            <div>
              <SideDrawer
                isOpen={this.state.openSideDrawer}
                title="Add Item"
                onClose={this.closeSideDraw}>
                <InvoiceCustomItemForm closeDrawer={this.closeSideDraw} addItem={this.handleAddItem} currency={currency}/>
              </SideDrawer>
            </div>
            <div>

              <InvoiceTable
                columns={COLUMNS}
                freightMovements={this.state.freightMovements}
                customItems={this.state.customItems}
                handleItemClick={this.handleItemClick}
                currency={currency}
              />
            </div>
            {
              isNotGSTApplicable &&
              <div>
                * {getCountryLabel('gst')} is not applicable
                </div>
            }
          </div>
          <div className="cardForm">
            <h4 className="cardForm-title">Invoice Conditions</h4>
            <div className="cardForm-content">
              <div className="col-md-5 form-wrap">
                <CommonAutoSelect
                  items={this.props.generalConditionsAll}
                  id="generalConditionsSelector"
                  value={this.state.generalConditionsSelector}
                  label="Invoice"
                  onChange={this.handleConditionSelector}
                  dontAutoselectSingleItem
                />
                <AddCircleIconWithTooltip
                  id='addInvoiceCondition'
                  title='Add Invoice Condition'
                  onClick={() => this.openInvoiceConditionSideDrawer()}
                />
                <CommonTextField
                  className="top15 text-black"
                  id="invoiceConditionViewer"
                  value={this.state.selectedGeneralConditionText}
                  fullWidth
                  multiline={true}
                  rows={1}
                  disabled={true}
                  rowsMax={1}
                  onBlur={this.handleBlur}
                />
              </div>
              <div className="cardForm-content col-md-1 arrow-button">
                <IconButton
                  disabled={!isNumber(this.state.generalConditionsSelector)}
                  onClick={this.appendInvoiceConditions}
                  size="large">
                  <ArrowForward color="primary" />
                </IconButton>
              </div>
              <div className="cardForm-content col-md-5">
                <div className="field-label">Conditions (Optional)</div>
                <TextField
                  className="text-area"
                  id="invoiceConditions"
                  value={this.state.fields.invoiceConditions.value}
                  fullWidth
                  multiline={true}
                  onChange={this.handleFieldChange}
                  rows={6}
                />
              </div>
            </div>
          </div>
          <div className="cardForm">
            <h4 className="cardForm-title">Internal Notes (Optional)</h4>
            <div className="cardForm-content col-md-5">
              <Notes
                title=''
                handler={this.noteHandler}
                note={this.state.fields.note}
                disabled={false}
              />
            </div>
          </div>

          <div className="cardForm">
            <h4 className="cardForm-title">Summary</h4>
            <div className="summary-section" style={{ marginLeft: '11px', marginBottom: '10px' }}>
              Against the Invoice ({this.state.fields.identifier.value}) raised by {this.props.user.company.displayName} , {customer} agrees to pay {formatPrice(this.state.total, 'N/A', currency)} to {freightProvider} on the mentioned account number#{freightProviderAccountNumber} by #{moment(this.state.fields.paymentDueDate.value).format(this.state.countryFormats.dateDisplay)}.
                <div>{this.isCustomerRcti() ? ` ** PLEASE NOTE: The ${getCountryLabel('gst')} shown on this RCTI is payable by ${freightProvider}` : ""}</div>
            </div>
            <div className="cardForm-content" style={{ marginLeft: '30%' }}>
              <div className="col-md-12 padding-reset form-wrap">
              <div className='col-md-4 col-md-offset-8' style={{ padding: '5px' }}>
                  <div className='col-md-6 padding-reset left'>
                    <b>Total Tonnage</b>
                  </div>{' '}
                  <div className='col-md-6 padding-reset right'>{this.state.fmTtTonnage} MT</div>
                </div>
                <div className="col-md-4 col-md-offset-8" style={{ padding: '5px' }}>
                  <div className="col-md-6 padding-reset left"><b>Sub-total (Ex {getCountryLabel('gst')})</b></div> <div className="col-md-6 padding-reset right">{formatPrice(this.state.subTotal, 'N/A', currency)}</div>
                </div>
                <div className="col-md-4 col-md-offset-8" style={{ padding: '5px' }}>
                  <div className="col-md-6 padding-reset left"><b>{getCountryLabel('gst')} {this.isCustomerRcti() ? '**' : ''}</b></div> <div className="col-md-6 padding-reset right">{formatPrice(this.state.gst, 'N/A', currency)}</div>
                </div>
                <div className="col-md-4 col-md-offset-8" style={{ padding: '5px' }}>
                  <div className="col-md-6 padding-reset left"><b>Total Amount Payable {this.isCustomerRcti() ? '' : `(Inc ${getCountryLabel('gst')})`}</b></div> <div className="col-md-6 padding-reset right">{formatPrice(this.state.total, 'N/A', currency)}</div>
                </div>
              </div>
            </div>

            <div className="col-md-12 cardForm-action" style={{ marginLeft: '25px' }}>
              <CommonButton
                label='Cancel'
                default={true}
                variant='outlined'
                onClick={this.handleConfirmDialogOpen}
              />
              <CommonButton
                type='submit'
                label='Continue and Review'
                primary={true}
                variant="contained"
                onClick={this.handleSubmit}
              />
            </div>
          </div>
          <Dialog
            open={this.state.isConfirmDialogOpen}
            onClose={this.handleConfirmDialogCancel}
            scroll="paper"
          >
            <DialogTitle>All the values entered by you will be lost.</DialogTitle>
            <DialogContent>
              Are you sure want to cancel?
              </DialogContent>
            <DialogActions>
              <CommonButton
                key="no"
                label="No"
                color="default"
                onClick={this.handleConfirmDialogCancel}
                variant="outlined"
              />
              <CommonButton
                key="yes"
                label="Yes"
                primary={true}
                onClick={this.handleConfirmDialogOk}
                variant="flat"
              />
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.preview}
            onClose={this.handlePreviewClose}
            scroll="paper"
            fullScreen
          >
            <DialogTitle>Invoice Preview</DialogTitle>
            <DialogContent>
              <InvoicePreview
                ref={el => (this.componentRef = el)}
              />
            </DialogContent>
            <DialogActions style={{paddingBottom: "40px"}}>
              <CommonButton
                label="Back"
                key="closeButton"
                default
                onClick={this.handlePreviewClose}
                variant="flat"
              />
              <CommonButton
                label="Submit"
                key="submitButton"
                primary={true}
                onClick={this.handlePreviewSubmit}
                variant="flat"
              />
            </DialogActions>
          </Dialog>
        </form>
        <SideDrawer
          isOpen={this.props.isUpdateFormDisplayed}
          title="Edit NGR"
          size="big"
          onClose={this.closeNgrSideDrawer}>
          <UpdateCompanyNgr companyId={get(this.state.entityForNgrUpdate, 'id')} company={this.state.entityForNgrUpdate} canAccessAny={canAccessAny(this.state.entityForNgrUpdate)} bankAccountDetailsMandatory={true} />
        </SideDrawer>

        <SideDrawer
          isOpen={this.props.isUpdateFormForBillToDisplayed}
          title="Edit NGR"
          size="big"
          onClose={this.closeNgrSideDrawer}>
          <UpdateNgr farmId={get(this.state.entityForNgrUpdate, 'id')} canAccessAny={canAccessAny(this.state.entityForNgrUpdate)} bankAccountDetailsMandatory={true} />
        </SideDrawer>

        {this.state.ngrEntity && this.props.isCreateNgrFormDisplayed &&
          <SideDrawer
            isOpen={this.state.ngrSideDrawerIsOpen}
            title="Add NGR"
            size="big"
            onClose={this.closeAddNgrSideDrawer}
          >
            <CreateCompanyNgr companyId={this.state.ngrEntity.id} company={this.state.ngrEntity} canAccessAny={true} closeDrawer={this.closeAddNgrSideDrawer} onSuccess={this.getNgrs} bankAccountDetailsMandatory={true} />
          </SideDrawer>
        }
        {this.freightProviderSideDrawer()}
        {this.props.isGeneralConditionSideDrawerOpened && <SideDrawer
          isOpen={this.state.invoiceConditionSideDrawerIsOpen}
          title="Add Invoice Condition"
          onClose={this.closeInvoiceConditionSideDrawer}
          app="condition"
        >
          <CreateGeneralCondition
            selectedConditionType="invoice"
            closeDrawer={this.closeInvoiceConditionSideDrawer}
            onSuccess={this.setDefaultGeneralCondition}
          />
        </SideDrawer>
        }
        {
          get(this.props.invoiceCreateResponse, 'defaultSubject') &&
          this.state.showCustomEmailDialog &&
          <CustomEmailDialog
            parties={['customer', 'freightProvider']}
            selectedParties={this.getSelectedParties()}
            title="Email PDF copies to"
            subject={this.props.invoiceCreateResponse.defaultSubject}
            noBody={true}
            open={this.state.showCustomEmailDialog}
            onClose={this.closeCustomEmailDialog}
            partyEmails={this.getPartyEmails()}
            partyContacts={this.getPartyContacts()}
            currentUserCompany={this.state.currentUserCompany}
            xero={get(this.state.currentUserCompany, 'xeroEnabled')}
            xeroCounterPartyExists={this.state.xeroCounterPartyExists}
          />
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    handleAddEmployeeButtonClick: (type) => {
      if (type === 'farm') {
        dispatch(clickAddFarmEmployeeButton());
      } else {
        dispatch(clickAddEmployeeButton());
      }
    },
    handleAddCompanyNgrButtonClick: () => dispatch(clickAddCompanyNgrButton()),
    handleAddNgrButtonClick: () => dispatch(clickAddNgrButton()),
    dispatch
  };
};

const mapStateToProps = (state) => {
  var farmParties = [];
  var companyParties = [];
  if (!isEmpty(state.companies.contracts.parties.farms)) {
    farmParties = map(state.companies.contracts.parties.farms, (farm) => {
      return merge(farm, { name: farm.displayName, rel: 'farms', entity: 'farm' });
    });
  }
  if (!isEmpty(state.companies.contracts.parties.companies)) {
    companyParties = map(state.companies.contracts.parties.companies, (company) => {
      return merge(company, { rel: 'companies', entity: 'company' });
    });
  }
  const parties = farmParties.concat(companyParties);
  const banks = state.main.banks || [];
  return {
    token: state.main.user.token,
    user: state.main.user.user,
    order: state.companies.orders.selectedOrder,
    freightMovements: state.companies.freights.items,
    freightMovementCarryItems: state.companies.contracts.movementsCarryItems,
    generalConditionsAll: state.companies.conditions.generalConditions,
    invoiceId: state.companies.invoices.selectedInvoiceId,
    selectedInvoice: state.companies.invoices.selectedInvoice,
    isUpdateFormDisplayed: state.companies.companyNgrs.isUpdateFormDisplayed,
    isUpdateFormForBillToDisplayed: state.companies.ngrs.isUpdateFormDisplayed,
    updatedBillToNgr: state.companies.ngrs.updatedNgr,
    updatedNgr: state.companies.companyNgrs.updatedNgr,
    parties,
    banks,
    serverErrors: state.companies.invoices.serverErrors,
    isCreateNgrFormDisplayed: state.companies.companyNgrs.isCreateFormDisplayed,
    isCreateFormDisplayed: state.companies.ngrs.isCreateFormDisplayed,
    isGeneralConditionSideDrawerOpened: state.companies.conditions.isCreateFormDisplayed,
    isEmployeeSideDrawerOpened: state.companySettings.employees.isCreateFormDisplayed || state.companies.farmEmployees.isCreateFormDisplayed,
    invoiceCreateResponse: state.companies.invoices.invoiceCreateResponse,
    isItemsFetched: state.companies.freights.isItemsFetched,
    isFetchedFreightContracts: state.companies.freights.isFetchedFreightContracts,
    forceStopLoader: state.main.forceStopLoader,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FreightInvoiceForm);

import React, { Component } from 'react';
import alertifyjs from 'alertifyjs';
import { Paper } from '@mui/material';
import APIService from '../../services/APIService';
import GenericTable from '../GenericTable';
import AddButton from '../common/AddButton';
import SideDrawer from '../common/SideDrawer';
import ExternalPortalForm from './ExternalPortalForm';


class ExternalPortals extends Component {
  constructor(props) {
    super(props)
    this.state = {
      portals: [],
      isLoading: true,
      drawer: false,
      selected: null,
    }
  }

  componentDidMount() {
    this.fetchPortals()
  }

  fetchPortals = () => {
    if(this.props.company.id) {
      APIService
        .companies(this.props.company.id)
        .appendToUrl('external/portals/')
        .get()
        .then(response => this.setState({portals: response}))
    }
  }

  toggleDrawer = () => this.setState({drawer: !this.state.drawer, selected: this.state.drawer ? null : this.state.selected})

  onDefaultClick = item => this.setState({selected: item}, this.toggleDrawer)

  onSuccess = () => {
    this.fetchPortals()
    this.toggleDrawer()
  }

  onDelete = () => {
    alertifyjs.confirm(
      'Delete Account?',
      'Are you sure you want to permanently delete this Account?',
      () => {
        APIService.companies(this.props.company.id).appendToUrl(`external/portals/${this.state.selected.id}/`).delete().then(() => {
          this.setState({selected: null}, this.fetchPortals)
        })
      },
      () => {}
    )
  }

  onOptionClick = (index, key, id, item) => {
    this.setState({selected: item}, () => {
      if(key === 'edit')
        this.toggleDrawer()
      else if (key === 'delete')
        this.onDelete()
    })
  }

  render() {
    const { drawer, selected, portals } = this.state
    const columns = [
      { key: 'portal', header: 'Name'},
      { key: 'username', header: 'Username', },
      { key: 'updatedBy', header: 'Updated By', },
      { key: 'updatedAt', header: 'Updated At', type: 'datetime' },
    ]
    return (
      <Paper style={{padding: '15px'}}>
        <div style={{ position: 'relative' }}>
          <div style={{ position: 'absolute', right: '0', top: '2px' }}>
            <AddButton label='Add Account' onClick={this.toggleDrawer} />
          </div>
          <GenericTable
            columns={columns}
            items={portals}
            handleDefaultCellClick={this.onDefaultClick}
            handleOptionClick={this.onOptionClick}
            optionsItems={[
              {key: 'edit', text: 'Edit'},
              {key: 'delete', text: 'Delete'},
            ]}
            orderBy='updatedAt'
            order='desc'
          />
          {
            drawer &&
              <SideDrawer
                isOpen={drawer}
                title={selected?.id ? "Edit Account" : "Add Account"}
                size="small"
                onClose={this.toggleDrawer}
              >
                <ExternalPortalForm {...this.props} selected={selected} onClose={this.toggleDrawer} onSuccess={this.onSuccess} onDelete={this.onDelete} />
              </SideDrawer>
          }
        </div>
      </Paper>
    )
  }
}

export default ExternalPortals;

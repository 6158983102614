export const TAB = {
  ALL_CASH_PRICE: 1,
  MY_CASH_PRICE: 2,
  SITE_ACTIVE_CASH_PRICE: 3,
  CLOSED_CASH_PRICE: 4,
  SITE_ARCHIVED_CASH_PRICE: 5,
  UNREGISTERED_CASH_PRICE: 6
};

export const FLAT_PAYMENT_SCALE = 1;

import React, {Component} from 'react';


class Contract extends Component {
  render() {
    return <svg xmlns="http://www.w3.org/2000/svg" width="28" height="38" viewBox="0 0 28 38">
      <g fill="none" fillRule="evenodd">
        <path d="M-6-1h40v40H-6z"/>
        <g fill={this.props.fill}>
          <path fillRule="nonzero" d="M24 9l-5-.006V4H4v30h20V9zM4 .667h16.667l6.666 6.666V34A3.333 3.333 0 0 1 24 37.333H4A3.333 3.333 0 0 1 .667 34V4A3.333 3.333 0 0 1 4 .667z"/>
          <path d="M17.334 12.333v3.333h-10v-3.333zM20.667 19v3.333H7.334V19zM20.667 25.667V29H7.334v-3.333z"/>
        </g>
      </g>
    </svg>;
  }
}

export default Contract;

import { connect } from 'react-redux';
import CompanyNgrForm from '../components/company-ngrs/CompanyNgrForm';
import { createCompanyNgr, createAndGetCompanyNgr } from '../actions/api/company-ngrs';
import { addCompanyNgr } from '../actions/companies/company-ngrs';

function submit(companyId, data, onSuccess) {
  return dispatch => {
    if (onSuccess) {
      dispatch(createAndGetCompanyNgr(companyId, data, addCompanyNgr, onSuccess));
    } else {
      dispatch(createCompanyNgr(companyId, data, addCompanyNgr));
    }
  };
}

export default connect(null, { submit })(CompanyNgrForm);

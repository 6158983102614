import React, { Component } from 'react';
import { connect } from 'react-redux';
import {get, forEach} from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import { changePassword, forceStopLoader, logout } from '../../actions/main';
import CommonButton from '../common/CommonButton';
import PasswordValidIndicator from '../employees/PasswordValidIndicator';
import PasswordIcon from '@mui/icons-material/Key';
import { currentUser } from '../../common/utils';
import { PASSWORDS_DO_NOT_MATCH } from '../../common/constants';
import UserIcon from '@mui/icons-material/PermIdentity';
import LockIcon from '@mui/icons-material/LockOutlined';
import { Visibility, VisibilityOff } from '@mui/icons-material';

class ResetUserPassword extends Component {
  componentDidMount() {
    if (!localStorage.getItem('isPasswordExpired'))
      window.location.hash = '#/login';

    this.setState({ passwordMisMatch: '' });
  }

  componentDidUpdate() {
    if (!localStorage.getItem('isPasswordExpired'))
      window.location.hash = '#/login';
    this.props.dispatch(forceStopLoader())
  }

  constructor(props) {
    super(props);
    this.state = {
      isNewPasswordValid: false,
      passwordMisMatch: '',
      incorrectPassword: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      shortPassword: '',
      showPassword: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleConfirmBlur = this.handleConfirmBlur.bind(this);
    this.handleNewBlur = this.handleNewBlur.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.isNewPasswordValid = this.isNewPasswordValid.bind(this);
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.signInAsDifferentUser = this.signInAsDifferentUser.bind(this);
    this.redirectToForgotPassword = this.redirectToForgotPassword.bind(this);
    this.props.dispatch(forceStopLoader())
  }

  isNewPasswordValid(valid) {
    if (valid !== this.state.isNewPasswordValid)
      this.setState({ isNewPasswordValid: valid });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      incorrectPassword: get(nextProps.passwordError, 'password[0]'),
      shortPassword: get(nextProps.passwordError, 'newPassword[0]')
    });
  }

  handleChange(event) {
    const newState = { ...this.state };
    newState[event.target.name] = event.target.value;
    this.setState(newState);
  }

  handleConfirmBlur(event) {
    if (this.state.newPassword !== event.target.value && this.state.confirmPassword != '') {
      this.setState({ passwordMisMatch: 'New password and Confirm password do not match' });
    } else {
      this.setState({ passwordMisMatch: '' });
    }
  }

  handleNewBlur(event) {
    if (this.state.confirmPassword !== event.target.value && this.state.confirmPassword != '') {
      this.setState({ passwordMisMatch: 'New password and Confirm password do not match' });
    } else {
      this.setState({ passwordMisMatch: '' });
    }

    if (this.state.newPassword.length < 8) {
      this.setState({ shortPassword: 'Password cannot be less than 8 Characters' });
    } else {
      this.setState({ shortPassword: '' });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const { dispatch } = this.props;
    if (this.state.isNewPasswordValid && isEmpty(this.state.passwordMisMatch) && isEmpty(this.state.shortPassword)) {
      const username = currentUser().username;
      dispatch(changePassword({
        username: username,
        password: this.state.password,
        newPassword: this.state.newPassword
      }));
    }
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState);
  }

  getFieldErrors(key) {
    let errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    if (key === 'confirmPassword')
      errors = this.state.fields.password.value !== this.state.fields.confirmPassword.value ? [PASSWORDS_DO_NOT_MATCH] : []

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }),
      () => {
        if (this.state.fields.password.value && this.state.fields.confirmPassword.value)
          this.checkPasswordsMatch()
      });
  }

  checkPasswordsMatch() {
    const newState = { ...this.state };
    newState.fields.confirmPassword.errors = newState.fields.password.value !== newState.fields.confirmPassword.value ? [PASSWORDS_DO_NOT_MATCH] : [];
    this.setState(newState);
  }

  signInAsDifferentUser() {
    this.props.dispatch(logout())
    window.location.hash = "#/login";
  }

  redirectToForgotPassword() {
    this.props.dispatch(() => {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      localStorage.clear();
    })
    window.location.hash = "#/forgot-password";
  }

  handleClickShowPassword() {
    this.setState(prevState => ({
      showPassword: !prevState.showPassword
    }));
  }

  render() {
    return (
      <div className="login-wrapper" style={{overflow: 'scroll'}}>
        <div className='row'>
          <div
            className="login-container"
            style={{
             position: 'relative',
             margin: 'auto',
             top: 'unset',
             left: 'unset',
             transform: 'unset',
             padding: "20px"
            }}>
            <div className="login-content-box">
              <div className="login-content-box--header">
                <img src="images/agrichain-logo.png" alt="Agri Chain Logo" />
              </div>
              <div className="login-content-box--content">
                <h2>Reset Password</h2>
                <p>Your password is expired according to the password expiry policy set by your organization. Please reset your password to continue using the platform.</p>

                <form onSubmit={this.handleSubmit}>
                  <div className="cardForm cardForm--drawer" style={{ marginBottom: '0px' }}>
                    <div className="cardForm-content row">
                      <div className="col-sm-12 form-wrap text-black" style={{ padding: 0 }}>
                        <div className="col-md-12 form-wrap padding-reset">
                          <div className="col-sm-12 form-wrap">
                            <TextField
                              label="Username"
                              placeholder="username"
                              fullWidth
                              name="username"
                              value={currentUser().username}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <UserIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <LockIcon />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </div>
                          <div className="col-sm-12 form-wrap">
                            <TextField
                              label="Current Password"
                              onChange={this.handleChange}
                              name="password"
                              type={this.state.showPassword ? 'text' : 'password'}
                              fullWidth
                              required
                              errorStyle={{ textAlign: "left", marginTop: '0.2px', marginBottom: '0px' }}
                              helperText={this.state.incorrectPassword || ''}
                              FormHelperTextProps={{
                                style: {
                                  marginBottom: '5px',
                                  marginTop: '0px'
                                },
                              }}
                              error={!isEmpty(this.state.incorrectPassword)}
                              value={this.state.password}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <PasswordIcon />
                                  </InputAdornment>
                                ),
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <IconButton
                                      aria-label="Toggle password visibility"
                                      onClick={this.handleClickShowPassword}
                                      size="large">
                                      {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                  </InputAdornment>
                                )
                              }}
                            />
                            <div style={{ 'textAlign': 'right', 'marginBottom': '10px' }}>
                              <CommonButton
                                label="Forgot Password"
                                primary
                                variant='text'
                                onClick={this.redirectToForgotPassword}
                                style={{ fontSize: '12px', fontWeight: '400', padding: '0', display: 'initial', textTransform: 'none', margin: '0' }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 form-wrap">
                          <TextField
                            label="New Password"
                            onChange={this.handleChange}
                            onBlur={this.handleNewBlur}
                            name="newPassword"
                            type="password"
                            fullWidth
                            required
                            helperText={this.state.shortPassword || ''}
                            errorStyle={{ textAlign: "left" }}
                            value={this.state.newPassword}
                            error={!isEmpty(this.state.shortPassword)}
                            FormHelperTextProps={{
                              style: {
                                marginTop: '5px'
                              },
                            }}
                          />
                        </div>
                        <div className="col-sm-12 form-wrap">
                          <TextField
                            label="Confirm Password"
                            onChange={this.handleChange}
                            onBlur={this.handleConfirmBlur}
                            name="confirmPassword"
                            type="password"
                            fullWidth
                            required
                            errorStyle={{ textAlign: "left" }}
                            value={this.state.confirmPassword}
                            helperText={this.state.passwordMisMatch}
                            error={!isEmpty(this.state.passwordMisMatch)}
                            FormHelperTextProps={{
                              style: {
                                marginTop: '5px'
                              },
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 form-wrap-70">
                        <PasswordValidIndicator
                          mainContainerStyle={{ fontSize: '12px', marginLeft: '0px', display: 'inline' }}
                          indicatorContainerStyle={{ marginTop: '2px', marginBottom: '15px' }}
                          password={this.state.newPassword}
                          onChange={this.isNewPasswordValid}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="container-fluid row" style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                    <CommonButton
                      label='Sign in as different User'
                      variant="text"
                      primary
                      className="login-button"
                      onClick={this.signInAsDifferentUser}
                      style={{ textTransform: 'none' }}
                      size='small'
                    />
                    <CommonButton
                      type="submit"
                      variant="contained"
                      primary
                      label="Submit"
                      className="login-button"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  var passwordError = state.main.password || '';
  if (!isEmpty(passwordError)) {
    passwordError = passwordError.errors;
  }

  return {
    passwordError,
    isLoading: state.main.isLoading
  }
};

export default connect(mapStateToProps)(ResetUserPassword);
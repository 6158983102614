import alertifyjs from 'alertifyjs';
import axios from 'axios';
import { apiURL } from '../main';
import APIService from '../../services/APIService';
import { addCompanyFailure, receiveBrokerCompanies } from '../../actions/companies';
import { forceStopLoader, loaded, isLoading } from '../main';
import { addPageCacheQueryParamsToUrl, getPageCache, isAtGlobalCompanies } from '../../common/utils';
import { getCompanyCompaniesResponse, getCompaniesResponse, getCompaniesNames, getCompanyGroupsResponse, getGroupsResponse } from '../companies';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import packageJson from '../../../package.json';
const VERSION = packageJson?.version

export const GET_PAGINATED_COMPANIES_SUCCESS = 'GET_PAGINATED_COMPANIES_SUCCESS';
export const GET_PAGINATED_COMPANIES_OF_GROUP_SUCCESS = 'GET_PAGINATED_COMPANIES_OF_GROUP_SUCCESS';
const MESSAGES = {
  CREATE_SUCCESS: 'Company was successfully added',
  TRANSFER_SUCCESS: ' has been successfully transferred'
};
export const getPaginatedCompaniesResponse = paginatedCompanies => {
  return { type: GET_PAGINATED_COMPANIES_SUCCESS, paginatedCompanies };
};
export const getPaginatedCompaniesOfGroupResponse = paginatedCompanies => {
  return { type: GET_PAGINATED_COMPANIES_OF_GROUP_SUCCESS, paginatedCompanies };
};

export const addCompany = (data, callback, skipRedirection = false, skipSuccessMessage = false) => (dispatch, getState) => {
  dispatch(isLoading('alertify'));
  const { token } = getState().main.user;
  APIService.companies()
    .post(data, token)
    .then(item => {
      if (item.errors) {
        dispatch(forceStopLoader());
        dispatch(addCompanyFailure(item.errors));
      } else if (item.id == null) {
        dispatch(forceStopLoader());
        alertifyjs.error(get(item, 'alert', 'An Error Occurred!'));
      } else {
        dispatch(loaded());
        dispatch(addCompanyFailure([]));
        if(!skipSuccessMessage)
          alertifyjs.success(MESSAGES.CREATE_SUCCESS);
        if (callback) {
          callback(item);
        } else {
          if(!skipRedirection)
            document.location.hash = '#/companies';
        }
      }
    });
};

export const getBrokerCompanies = () => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies()
    .get(token, undefined, { type_id: 2 })
    .then(items => {
      dispatch(receiveBrokerCompanies(items));
    });
};

export const getCompaniesWithFarmOrSiteMinimal = () => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies()
    .appendToUrl('minimal/')
    .get(token)
    .then(items => {
      dispatch(getCompaniesResponse(items));
    });
};

export const getCompanyCompanies = (companyId, query) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .companies()
    .get(token, undefined, query)
    .then(items => {
      dispatch(getCompanyCompaniesResponse(items));
    });
};

export const getCompanyCompaniesMinimal = (companyId, query, getToken=null, _forceStopLoader) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .companies()
    .appendToUrl('minimal/')
    .get(token || getToken, null, query)
    .then(items => {
      dispatch(getCompanyCompaniesResponse(items));
      if (_forceStopLoader) {
        dispatch(forceStopLoader());
      }
    });
};

export const getCompaniesOfGroup = (companyId, groupId, url, _forceStopLoader) => (dispatch, getState) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country,
    },
  };
  let URL = url ? url :`${apiURL}/companies/${companyId}/groups/${groupId}/companies/`;
  URL = addPageCacheQueryParamsToUrl(URL)    
  axios
  .get(URL, config)
  .then(response => {
    if (get(response.data, 'count') == 0)
      alertifyjs.error("No companies in this group")
    dispatch(getPaginatedCompaniesOfGroupResponse(response.data));
    if (_forceStopLoader) {
      dispatch(forceStopLoader());
    }
  })
  .catch(error => {
    dispatch(getPaginatedCompaniesOfGroupResponse(error.response ? error.response.data : error.message));
  });
}

export const getCompanyGroups = companyId => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .appendToUrl('groups/')
    .get(token)
    .then(items => {
      dispatch(getGroupsResponse(items))
    });
}

export const addCompanyGroup = (companyId, data) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .appendToUrl('groups/')
    .post(data, token)
    .then(items => {
      if(items?.errors) {
        alertifyjs.error(items.errors);
      }
      else if (items?.success) {
        alertifyjs.success(items?.success)
        dispatch(getCompanyGroups(companyId))
      }
      else {
        alertifyjs.success("Group successfully created/updated")
        dispatch(getCompanyGroupsResponse(items))
        dispatch(getCompanyGroups(companyId))
      }
    });
}

export const getCompanyCompaniesWeb = (companyId, url, _forceStopLoader, isGroups=false, unGrouped=false) => (dispatch, getState) => {
  const config = {
    headers: {
      'Content-type': 'application/json',
      accept: 'application/json',
      Authorization: `Token ${getState().main.user.token}`,
      'REFERER-TZ': Intl.DateTimeFormat().resolvedOptions().timeZone,
      'WEB-VERSION': VERSION,
      'REFERER-COUNTRY': localStorage.current_country,
    },
  };
  const cache = getPageCache();
  const cachedOrderBy = get(cache, 'orderBy');
  let webUrl = `${apiURL}/companies/${companyId}/companies/web/`
  if(cachedOrderBy)
    webUrl += `search/?is_groups=${isGroups}`;
  else
    webUrl += `?is_groups=${isGroups}`;
  if(unGrouped)
    webUrl += '&ungrouped=true';

  let URL = url ? url : webUrl;

  if(isAtGlobalCompanies()) {
    URL = addPageCacheQueryParamsToUrl(URL)
  }

  axios
    .get(URL, config)
    .then(response => {
      dispatch(getPaginatedCompaniesResponse(response.data));
      if (_forceStopLoader) {
        dispatch(forceStopLoader());
      }
    })
    .catch(error => {
      dispatch(getPaginatedCompaniesResponse(error.response ? error.response.data : error.message));
    });
};

export const getCompanyCompaniesMinimalisticWithAssets = (companyId, assets) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .appendToUrl('companies/minimal/')
    .get(token, null, { assets: assets })
    .then(items => {
      dispatch(getCompaniesResponse(items));
    });
};

export const getCompanyNames = () => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies()
    .names()
    .get(token, null, null)
    .then(items => {
      dispatch(getCompaniesNames(items));
    });
};

export const transferCompanyAsset = (companyId, data, reload=true) => (dispatch, getState) => {
  const { token } = getState().main.user;
  APIService.companies(companyId)
    .appendToUrl('transfer-asset/')
    .put(data, token)
    .then((response) => {
      if(response?.error){
        alertifyjs.error(response.error)
      }
      else if (response?.id)
        alertifyjs.notify(capitalize(data.asset) + MESSAGES.TRANSFER_SUCCESS, 'success', 3, () => { if(reload) window.location.reload(); });
      else
        alertifyjs.error("Something went wrong!")
  });
};



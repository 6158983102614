import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip, Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { CloudDownload } from '@mui/icons-material';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import CommonMultiSelect from '../common/autocomplete/CommonMultiSelect';
import map from 'lodash/map';
import { useState } from 'react';
import CommonDatePicker from '../common/CommonDatePicker';
import moment from 'moment';
import { setDownloadBar } from '../../actions/main';
import APIService from '../../services/APIService';
import get from 'lodash/get';

let FarmFieldsCSV = props =>{

    const [filters, setFilters] = useState({
        'date_time__gte': '',
        'date_time__lte': '',
        'farm_field_id__in': []
    });

    let {allFarmFields, token} = useSelector(state =>{
        return {
            "allFarmFields": state.companies.farmFields.items,
            "token": state.main.user.token
            };
        }
    );

    const dispatch = useDispatch();

    let handleFarmFieldCSV = () =>{
        let filtersCopy = Object.assign({}, filters);
        if(filtersCopy.date_time__lte ){
            let lte_time = `${filtersCopy.date_time__lte} 23:59:59`;
            lte_time = moment.tz(lte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
            filtersCopy.date_time__lte = lte_time;
        }

        if (filtersCopy.date_time__gte){
            let gte_time = `${filtersCopy.date_time__gte} 00:00:00`;
            gte_time = moment.tz(gte_time, moment.tz.guess()).utc().format('YYYY-MM-DD HH:mm:ss');
            filtersCopy.date_time__gte = gte_time;
        }
      dispatch(setDownloadBar('Your Paddock CSV is getting prepared. Please visit <a href="/#/downloads">Downloads</a> in few moments.', true));
      const service = APIService.farms(props.farmId).appendToUrl(`farm_fields/csv`);
        service.put(filtersCopy, token);
        props.toggleDialog();
    };


    return (
        <div>
               {
               get(props, 'showPaddock') &&
                <Tooltip title="Download Paddock CSV">
                    <Button
                        onClick={props.toggleDialog}
                        color='secondary'
                        variant="outlined"
                        type="button"
                        style={{position: "absolute", top: "0px", right: "5px"}}
                        disabled={false}
                    >
                    <CloudDownload style={{paddingRight: '5px'}} fontSize="small"/> Paddock Report
                    </Button>
                </Tooltip>
                }

                <Dialog open={props.isOpen} onClose={props.toggleDialog} aria-labelledby='form-dialog-title' fullWidth>
                        <DialogTitleWithCloseIcon
                            onClose={props.toggleDialog}
                            closeButtonStyle={{marginTop: '0px'}}
                            id='form-dialog-title'>
                            Download Paddock Report CSV
                        </DialogTitleWithCloseIcon>
                        <DialogContent style={{marginTop: '15px'}}>
                            <div className='col-sm-6 no-left-padding '>
                                <CommonDatePicker
                                    id='date_time__gte'
                                    floatingLabelText='Start Date'
                                    value={filters.date_time__gte}
                                    onChange={(val, id)=>setFilters({...filters, [id]: val})}
                                />
                            </div>

                            <div className='col-sm-6 no-right-padding '>
                                <CommonDatePicker
                                    id='date_time__lte'
                                    floatingLabelText='End Date'
                                    value={filters.date_time__lte}
                                    onChange={(val, id)=>setFilters({...filters, [id]: val})}
                                />
                            </div>

                            <div className="col-sm-12 padding-reset">
                                <CommonMultiSelect
                                    id="farm_field_id__in"
                                    items={allFarmFields}
                                    selectedItems={filters.farm_field_id__in}
                                    displayField="name"
                                    onChange={(id, selectedItems) =>
                                        setFilters({...filters,'farm_field_id__in': map(selectedItems, 'id')})}
                                    placeholder="Select Fields"
                                    label="Farm Fields"
                                    selectAll
                                    clearAll
                                />
                            </div>

                        </DialogContent>
                        <DialogActions>
                                <Button type='button' onClick={props.toggleDialog} variant='outlined'>
                                Cancel
                                </Button>
                                <Button type='button' onClick={handleFarmFieldCSV} color='primary' variant='outlined'>
                                Download
                                </Button>
                        </DialogActions>
                </Dialog>
            </div>
    );
};

export default FarmFieldsCSV;

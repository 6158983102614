import React, { Component } from 'react';
import { connect } from 'react-redux';

import { fetchBanks } from '../../actions/main';
import alertifyjs from 'alertifyjs';
import CommonSelect from '../common/select/CommonSelect';
import mapValues from 'lodash/mapValues';
import forEach from 'lodash/forEach';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';
import get from 'lodash/get';
import { required, fixLength, numRegex } from '../../common/validators';
import BankAccountForm from './BankAccountForm';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import {PRIMARY_COLOR_GREEN} from '../../common/constants';
import FileUpload from "../common/FileUpload";
import uniqBy from 'lodash/uniqBy';
import APIService from '../../services/APIService';
import includes from 'lodash/includes';
import map from 'lodash/map';
import { isCurrentUserBelongsToCompany } from '../../common/utils';

class NgrForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      bankNameSearchText: '',
      shareholderCount: props.selectedNgr ? get(props, 'selectedNgr.bankAccounts.length') : 1,
      totalShareholderPercent: 0,
      bankValidationSignals: [false, false, false, false, false],
      isAbnDuplicate: [false, false, false, false, false],
      ngrTypes: [{'id': 'single', 'name': 'Single'}, {'id': 'shared', 'name': 'Shared'}],
      allCompanies: '',
      fields: {
        ngrType: {
          value: 'single',
          validators: [],
          errors: []
        },
        ngrNumber: {
          value: '',
          validators: [required('NGR is required.'), fixLength(8), numRegex()],
          errors: []
        },
        shareAuthorityUrl: {
          value: [],
          validators: [],
          errors: []
        },
        shareAuthorityDoc: {
          value: [],
          validators: [],
          errors: []
        },
        shareAuthorityFile: {
          value: [],
          validators: [],
          errors: []
        },
        shareAuthorityFileClone: {
          value: [],
          validators: [],
          errors: []
        },
      }
    };

    this.bankAccounts = [{
      name: '',
      bsbNumber: '',
      accountName: '',
      accountNumber: '',
      companyId: '',
      shareholderPercent: 100,
      isValid: this.isBankAccountValid(get(props,'selectedNgr.bankAccounts.0')),
    }];

    this.handleSubmit = this.handleSubmit.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsValuesBySelectedNgr = this.setAllFieldsValuesBySelectedNgr.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleNgrTypeChanged = this.handleNgrTypeChanged.bind(this);
    this.handleBankAccountChanged = this.handleBankAccountChanged.bind(this);
    this.addShareholder = this.addShareholder.bind(this);
    this.removeShareholder = this.removeShareholder.bind(this);
    this.setBankValidationSignal = this.setBankValidationSignal.bind(this);
    this.onBankValidated = this.onBankValidated.bind(this);
    this.loadImageFileAsURL = this.loadImageFileAsURL.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.removeFile = this.removeFile.bind(this);

    const { dispatch } = this.props;
    dispatch(fetchBanks());
  }

  getAllCompanies = async ()=>{
    let DirectoryCompanies = await APIService.companies().appendToUrl(`directory/names/?excludeGroups=true`).get(this.props.token);
    DirectoryCompanies = uniqBy([...DirectoryCompanies, { id: this.props.user.companyId, name: this.props.user.company.name }], 'id');
    this.setState({ 'allCompanies': DirectoryCompanies});
  };

  componentDidMount() {
    this.setAllFieldsValuesBySelectedNgr();
    this.getAllCompanies();
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedNgr) {
      if (this.props.selectedNgr.id !== prevProps.selectedNgr.id) {
        this.setAllFieldsValuesBySelectedNgr();
      }
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const newState = { ...this.state };
    forEach(props.serverErrors, (value, key) => {
      if (newState.fields[key].value) {
        newState.fields[key].errors = value;
      }
    });
    this.setState(newState);
  }

  onBankValidated(index) {
    this.setBankValidationSignal(index, false);
  }

  setBankValidationSignal(index, value) {
    const newState = {...this.state};
    newState.bankValidationSignals[index] = value;
    this.setState(newState);
  }

  loadImageFileAsURL(filestate) {
    const newState = {...this.state};
    newState.fields.shareAuthorityDoc.value.push(filestate.file);
    this.setState(newState, this.notifyValueChanged);
  }

  handleFileRemove(src) {
    const newState = {...this.state};
    const index = this.state.fields.shareAuthorityDoc.value.findIndex((file) => file.base64 === src);
    newState.fields.shareAuthorityDoc.value.splice(index, 1);
    this.setState(newState);
  }

  handleNgrTypeChanged(value) {
    if (this.state.fields.ngrType.value === value) {
      return;
    }
    let shareholderCount = 1;
    if (value === 'single') {
      this.bankAccounts.splice(1, this.bankAccounts.length - 1);
      this.bankAccounts[0].shareholderPercent = 100;
      this.bankAccounts[0].isValid = true;
    }
    else {
      this.bankAccounts[0].shareholderPercent = '';
      this.bankAccounts[0].isValid = false;
      this.bankAccounts.push({
        name: '',
        companyId: '',
        bsbNumber: '',
        accountName: '',
        accountNumber: '',
        isValid: false,
        shareholderPercent: '',
      });
      shareholderCount = 2;
    }

    const newState = {...this.state};
    newState.fields['ngrType'].value = value;
    newState.shareholderCount = shareholderCount;
    this.setState(newState);
  }

  handleBankAccountChanged(index, bankAccount) {
    this.bankAccounts[index] = bankAccount;
  }

  addShareholder() {
    if(this.state.shareholderCount < 5) {
      this.bankAccounts.push({
        name: '',
        bsbNumber: '',
        accountName: '',
        accountNumber: '',
        abn: '',
        entityName: '',
        isValid: false,
        shareholderPercent: '',
      });

      this.setState((prevState) => ({
        shareholderCount: prevState.shareholderCount + 1
      }));
    }
  }

  removeShareholder(index) {
    if(this.state.shareholderCount > 2) {
      this.bankAccounts.splice(index, 1);

      this.setState((prevState) => ({
        shareholderCount: prevState.shareholderCount - 1
      }));
    }
  }

  removeFile(index) {
    const newState = {...this.state};
    newState.fields.shareAuthorityFileClone.value.splice(index, 1);
    this.setState(newState);
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldsErrors(this.submitForm);
  }

  submitForm() {
    const isFormInvalid = some(this.state.fields, (field) => {
      return some(field.validators, (validator) => {
        return validator.isInvalid(field.value);
      });
    });
    const areBanksValid = this.bankAccounts.every((bank) => bank.isValid);
    const isAbnDuplicate = this.state.isAbnDuplicate.indexOf(true) != -1;
    const submitData = mapValues(this.state.fields, (field) => {
      return field.value;
    });
    const banks = [];
    this.bankAccounts.forEach((bankAccount) => {
      const bank = Object.assign({}, bankAccount);
      delete bank.isValid;
      if(bank.abn || bank.name || bank.bsbNumber || bank.accountName || bank.accountNumber){
        if(bank.name) {
          let bankOption = find(this.props.banks, {name: bank.name});
          bank.bankId = bankOption.id;
        }
        delete bank.name;
        banks.push(bank);
      }
    });

    if(banks.length !== 0){
      submitData.bankAccounts = banks;
    } else {
      if(get(submitData, 'bankAccounts')){
        delete submitData.bankAccounts;
      }
    }
    submitData.ngrType = submitData.ngrType.toLowerCase();
    submitData['shareAuthorityUrl'] = submitData['shareAuthorityDoc'];
    submitData['shareAuthorityFile'] = submitData['shareAuthorityFileClone'];
    delete submitData['shareAuthorityDoc'];
    delete submitData['shareAuthorityFileClone'];
    if(submitData.ngrType === 'single') {
      if(get(submitData, 'bankAccounts')){
        submitData.bankAccounts[0].shareholderPercent = 100;
      }
      if (!isFormInvalid && areBanksValid) {
        this.props.submit(this.props.farmId, submitData, this.props.onSuccess);
      }
    }
    else if (!isFormInvalid && !isAbnDuplicate && areBanksValid && this.state.totalShareholderPercent == 100) {
      if (!includes((map(submitData.bankAccounts, 'companyId')), this.props.companyId)){
        alertifyjs.error("Company where the NGR is being added must be one of the shareholders");
        return;
      };

      this.props.submit(this.props.farmId, submitData, this.props.onSuccess);
    }
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleSelectChange(key, event, index, value) {
    this.setFieldValue(key, value);
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  setAllFieldsValuesBySelectedNgr() {
    if (this.props.selectedNgr) {
      const newState = { ...this.state };
      forEach(newState.fields, (value, key) => {
        newState.fields[key].value = this.props.selectedNgr[key];
      });
      newState.fields.ngrNumber.validators = [required('NGR is required.')];
      newState.fields.shareAuthorityDoc.value = [];
      if (this.props.selectedNgr.shareAuthorityFile)
        newState.fields.shareAuthorityFileClone.value = this.props.selectedNgr.shareAuthorityFile.slice();
      else
        newState.fields.shareAuthorityFileClone.value = [];
      if(get(this.props, 'selectedNgr.bankAccounts.length') > 0){
        this.bankAccounts = this.props.selectedNgr.bankAccounts.map(bankAccount => {
          return {
            name: bankAccount.name,
            bsbNumber: bankAccount.bsbNumber,
            accountName: bankAccount.accountName,
            accountNumber: bankAccount.accountNumber,
            shareholderPercent: bankAccount.shareholderPercent,
            companyId: bankAccount.companyId,
            isValid: this.isBankAccountValid(bankAccount),
          };
        });
      }
      this.setState(newState);
    }
  }

  isBankAccountValid = (bank) => {
    const isBankDetailsRequired = get(this.props, 'bankAccountDetailsMandatory',false);
    if(isBankDetailsRequired){
      return get(bank,'name') &&
          get(bank,'bsbNumber') &&
          get(bank,'accountNumber') &&
          get(bank,'shareholderPercent') && (
              get(this.props,'selectedNgr.ngrType') === 'shared' && (get(bank,'abn') && get(bank,'entityName')) || get(this.props,'selectedNgr.ngrType') === 'single'
          );
    } else {
      return true;
    }
  };

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    let totalShareholderPercent = 0;
    let isAbnDuplicate = [false, false, false, false, false];
    if(this.state.fields.ngrType.value.toLowerCase() === 'shared') {
      forEach(this.bankAccounts, (bankAccount, index) => {
        for (var i = index+1; i < this.bankAccounts.length; i++) {
          if(!isEmpty(bankAccount.abn) && bankAccount.abn == this.bankAccounts[i].abn) {
            isAbnDuplicate[i] = true;
            isAbnDuplicate[index] = true;
          }
        }
        totalShareholderPercent += isEmpty(bankAccount.shareholderPercent) && !isNumber(bankAccount.shareholderPercent) ? 0 : parseFloat(bankAccount.shareholderPercent);
      });
    }
    newState.totalShareholderPercent = totalShareholderPercent;
    newState.isAbnDuplicate = isAbnDuplicate;
    newState.bankValidationSignals.fill(true, 0, this.bankAccounts.length);
    this.setState(newState, callback);
  }

  isUnknownNGR() {
    const { selectedNgr } = this.props;
    return selectedNgr && get(selectedNgr, 'ngrNumber').match(/UNKNOWN_/);
  }

  canEditUnknown() {
    if (get(this.props.selectedNgr, 'company.transactionParticipation')) {
      if (this.props.selectedNgr.company.id == this.props.user.companyId)
        return false;
      else
        return true;
    }
    return false;
  }

  render() {
    const maxFileCountReached = this.state.fields.shareAuthorityFileClone.value.length + this.state.fields.shareAuthorityDoc.value.length >= 5;
    return (
      <form onSubmit={this.handleSubmit} noValidate>
        <div className="cardForm cardForm--drawer">
          <div className="cardForm-content row trucks">
            <div className="col-sm-6 form-wrap">
              <CommonTextField
                id="ngrNumber"
                label="NGR"
                placeholder="Please enter 8 digits NGR number"
                type={this.props.selectedNgr ? '': 'number'}
                helperText= {this.state.fields.ngrNumber.errors[0]}
                onChange={this.handleFieldChange}
                onBlur={this.onFieldBlur}
                value={this.state.fields.ngrNumber.value}
                disabled={!this.props.canAccessAny || !isEmpty(this.props.selectedNgr)}
                onInput = {(e) =>{
                  e.target.value = e.target.value.toString().slice(0,8);
              }} />
            </div>
            <div className="col-sm-6 form-wrap">
              <CommonSelect
                id="ngrType"
                floatingLabelText="NGR Type"
                selectedItemId={this.state.fields.ngrType.value}
                onChange={this.handleNgrTypeChanged}
                items={this.state.ngrTypes}
                value={this.state.fields.ngrType.value}
                selectConfig={{text: 'name', value: 'id'}}
                disabled={!this.props.canAccessAny || !isEmpty(this.props.selectedNgr)}
              />
            </div>
          </div>
            {
              this.bankAccounts.map( (bankAccount, index) => {
                return <BankAccountForm
                          key={index}
                          banks={this.props.banks}
                          ngrType={this.state.fields.ngrType.value}
                          totalShareholderPercent={this.state.totalShareholderPercent}
                          isAbnDuplicate={this.state.isAbnDuplicate[index]}
                          disabled={this.isUnknownNGR() ? this.canEditUnknown() : (!this.props.canAccessAny && !isCurrentUserBelongsToCompany(this.props.selectedNgr.company.id ))}
                          startValidation={this.state.bankValidationSignals[index]}
                          validated={this.onBankValidated}
                          {...bankAccount}
                          index={index}
                          bankAccountDetailsMandatory={this.props.bankAccountDetailsMandatory}
                          valueChanged={this.handleBankAccountChanged}
                          shareholderRemoved={this.removeShareholder}
                          allCompanies={this.state.allCompanies}/>;
              })
            }
            {
              this.state.fields.ngrType.value.toLowerCase() === 'shared' ?
              <div>
                <div className="cardForm-content row trucks">
                {
                  this.state.shareholderCount < 5 ?
                  <div className="col-sm-6 form-wrap">
                    <a onClick={this.addShareholder} style={{color: PRIMARY_COLOR_GREEN, cursor: 'pointer', float: 'left'}} disabled={!this.props.canAccessAny}>
                      Add Shareholder
                    </a>
                  </div> : ''
                }
                </div>
                <div className="cardForm-content row trucks">
                  <div className="form-wrap">
                    <FileUpload
                      id="shareDoc"
                      buttonText="Attach Share Authority"
                      disabled={!this.props.canAccessAny || maxFileCountReached}
                      onRemove={this.handleFileRemove}
                      onChange={this.loadImageFileAsURL} />
                  </div>
                </div>
                <div className="cardForm-content row trucks">
                  <div className="col-sm-12 form-wrap">
                    {
                      this.state.fields.shareAuthorityFileClone.value.map((file, index) => {
                        return <span key={index}>
                                <span>
                                  <embed className="thumb" type="application/pdf" src={this.state.fields.shareAuthorityFile.value[index]} />
                                  <span className="thumbnail-text">{file.name}</span>
                                </span>
                                <span className="file-close" onClick={() => this.removeFile(index)}>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M9 1C4.58 1 1 4.58 1 9s3.58 8 8 8 8-3.58 8-8-3.58-8-8-8zm4 10.87L11.87 13 9 10.13 6.13 13 5 11.87 7.87 9 5 6.13 6.13 5 9 7.87 11.87 5 13 6.13 10.13 9 13 11.87z"/></svg>
                                </span>
                              </span>;
                      })
                    }
                  </div>
                </div>
              </div>  : ''
            }
          {this.props.canAccessAny ?
              <div className="col-sm-12 cardForm-action top15 padding-reset">
                <CommonButton
                    type="button"
                    label="Cancel"
                    variant="outlined"
                    default
                    onClick={this.props.selectedNgr ? this.props.cancelEdit : this.props.closeDrawer}
                />
                <CommonButton
                    primary={true}
                    variant="contained"
                    label="Save"
                    type="submit"/>
              </div>
              : null
          }
        </div>
      </form>
    );
  }
}

NgrForm.defaultProps = {
  canAccessAny: true,
};

const mapStateToProps = (state) => {
  const banks = state.main.banks || [];
  return {
    banks,
    serverErrors: state.companies.ngrs.serverErrors,
    user: state.main.user.user,
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(NgrForm);

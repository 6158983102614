import findIndex from 'lodash/findIndex';
import find from 'lodash/find';

import {
  RECEIVE_STORAGES,
  RECEIVE_ARCHIVED_STORAGES,
  ADD_STORAGE,
  REMOVE_STORAGE,
  EDIT_STORAGE,
  CANCEL_EDIT_STORAGE,
  CLICK_ADD_STORAGE_BUTTON,
  CLICK_EDIT_STORAGE_ICON,
  ADD_STORAGE_ERRORS,
  EDIT_STORAGE_ERRORS, CHANGE_REFRESH_STOCKS_STATUS,
  SET_SELECTED_STORAGE_FOR_STOCK,
} from '../../actions/companies/storages';
import filter from 'lodash/filter';

const initialState = {
  items: [],
  selectedHomeStorageId: undefined,
  isCreateFormDisplayed: false,
  isUpdateFormDisplayed: false,
  serverErrors: [],
  refreshStocks: false,
  selectedStorageForStocks: undefined,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case SET_SELECTED_STORAGE_FOR_STOCK:
    return {
      ...state,
      selectedStorageForStocks: action.item,
    };
    case RECEIVE_STORAGES:
      return {
        ...state,
        items: action.items,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
      };
    case RECEIVE_ARCHIVED_STORAGES:
        return {
          ...state,
          archivedItems: action.items,
          serverErrors: [],
        };
    case ADD_STORAGE:
      return {
        ...state,
        items: [action.item, ...state.items],
        selectedHomeStorageId: undefined,
        isCreateFormDisplayed: false,
        isUpdateFormDisplayed: false,
        serverErrors: [],
        refreshStocks: true,
      };
    case ADD_STORAGE_ERRORS:
        return {
          ...state,
          selectedHomeStorageId: undefined,
          isUpdateFormDisplayed: false,
          isCreateFormDisplayed: true,
          serverErrors: action.errors,
        };
    case REMOVE_STORAGE: {
        const updatedItems = filter(state.items, i => {return i.id != action.item.id;});
        return {
          ...state,
          items: [...updatedItems],
          selectedHomeStorageId: undefined,
          isCreateFormDisplayed: false,
          isUpdateFormDisplayed: false,
          serverErrors: [],
          refreshStocks: true,
        };
    }
    case EDIT_STORAGE: {
      const index = findIndex(state.items, { id: action.item.id });
      return {
        items: [
          ...state.items.slice(0, index),
          action.item,
          ...state.items.slice(index + 1),
        ],
        selectedFarmId: undefined,
        selectedHomeStorageId: undefined,
        isUpdateFormDisplayed: false,
      };
    }
    case EDIT_STORAGE_ERRORS:
      return {
        ...state,
        serverErrors: action.errors,
      };
    case CANCEL_EDIT_STORAGE:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedHomeStorageId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: false,
      };
    case CLICK_ADD_STORAGE_BUTTON:
      return {
        ...state,
        selectedFarmId: undefined,
        selectedHomeStorageId: undefined,
        isUpdateFormDisplayed: false,
        isCreateFormDisplayed: true,
      };
    case CLICK_EDIT_STORAGE_ICON:
      return {
        ...state,
        selectedFarmId: find(state.items, {id: action.itemId}).farmId,
        selectedHomeStorageId: find(state.items, {id: action.itemId}).id,
        isUpdateFormDisplayed: true,
        isCreateFormDisplayed: false,
      };
    case CHANGE_REFRESH_STOCKS_STATUS:
      return{
        ...state,
        refreshStocks: action.status
      };
    default:
      return state;
  }
};

export default reducer;

import React from 'react';

import Paper from '@mui/material/Paper';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import ScaleIcon from '@mui/icons-material/Scale';
import { EMPTY_VALUE, PACK_ORDER_TYPE_ID, FREIGHT_CONTRACT_TYPE, PRIMARY_COLOR_GREEN } from '../../../common/constants';
import Tooltip from '../../../common/Tooltip';
import { getGradeName, getHandlerName, getSiteName, getLoadingPortDisplayName, toDateFormat, getCurrentCountry, getCountryLabel, getCountryConfig, getCountryCurrency, toPhoneFormat } from '../../../common/utils';
import connect from 'react-redux/es/connect/connect';
import {omit, get, isNull, includes, isEmpty, map} from 'lodash';
import { IconButton, Tooltip as MUITooltip } from '@mui/material';
import UnitConversions from '../../common/UnitConversions';
import UpdateLocation from '../../locations/UpdateLocation';
import PinDropIcon from '@mui/icons-material/PinDrop';
import Create from '@mui/icons-material/Create';

const renderBasedOnExpandedFlag = expanded => {
  if (expanded) {
    return <KeyboardArrowUp style={{ fill: '#112c42', height: '20px', width: '20px' }} />;
  }
  return <KeyboardArrowDown style={{ fill: '#112c42', height: '20px', width: '20px' }} />;
};

class OrderDetailsSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
      conversion: false,
      openConsignorLocationForm: false,
      openConsigneeLocationForm: false,
      selectedConsignorLocationType: undefined,
      selectedConsigneeLocationType: undefined,
    };
  }

  componentDidMount() {}

  toggleExpanded = () => {
    this.setState(prevState => ({ expanded: !prevState.expanded }));
  };

  getSectionAsPerGrid = (dataLists, label='', showSpread=false) => {
    let spreadDetails = null;
    if (showSpread && !isEmpty(get(this.props, 'order.spread')))
      spreadDetails = this.props.order.spread.details;
    return (
      <div>
        <h5>{label}</h5>
        <ul className='field-label-list'>
          {Object.keys(dataLists).map((key, index) => {
            return (
              <li key={index}>
                <Tooltip className='field-label ellipses' tooltipText={key} textContent={key} />
                {key === 'Pickup Site' || key === 'Delivery Site' ?
                <Tooltip className='field-value' tooltipText={dataLists[key] || EMPTY_VALUE} textContent={dataLists[key] || EMPTY_VALUE} />:
                <Tooltip
                  className='field-value ellipses'
                  tooltipText={dataLists[key] || EMPTY_VALUE}
                  textContent={dataLists[key] || EMPTY_VALUE}
                  prefix={
                    getCurrentCountry().config?.showConversions && key.toLowerCase() === 'tonnage' &&
                      <MUITooltip title='View in other units' arrow>
                        <span style={{marginLeft: '10px'}}>
                          <IconButton size='small' color='primary' onClick={() => this.setState({conversion: !this.state.conversion})}>
                            <ScaleIcon fontSize='small' />
                          </IconButton>
                        </span>
                      </MUITooltip>
                  }
                />}
              </li>
            );
          })}
        </ul>
        {
          spreadDetails &&
          <div className="section-details">
            {this.getSpreadDetailsSection(spreadDetails, this.props.order?.isBlended ? 'Grades' : getCountryLabel('spreadDetails'), this.props.order?.currency)}
          </div>
        }
        {
          showSpread && this.props.order.chemicalApplications?.length > 0 &&
            <div className="section-details">
              {this.getChemicalApplicationsSection(this.props.order.chemicalApplications, 'Applications')}
            </div>
        }
      </div>
    );
  };

  getChemicalApplicationsSection = (applications, title) => {
    return (
      <div>
        <h4 className="section-title">{title}</h4>
        <ul>
          {
            map(applications, (application, index) => (
              <li key={index} style={{width: '100%'}}>
              <span style={{width: '100%'}}>{application.summary}</span>
              </li>
            ))
          }
        </ul>
      </div>
    );
  };

  getSpreadDetailsSection = (spreadDetails, title, currency) => {
    return (
      <div>
        <h4 className="section-title">{title}</h4>
        <ul>
          {
            map(spreadDetails, (grade, index) => (
              <li key={index} style={grade.gist ? {width: '100%'} : {}}>
                {
                !grade.gist &&
                    <Tooltip
                      className="field-label ellipses"
                      tooltipText={grade.name}
                      textContent={grade.name}
                    />
                }
                {
                  grade.gist ?
                    <span style={{width: '100%'}}>{grade.gist}</span> :
                <Tooltip
                  className="field-value ellipses"
                  tooltipText={grade.name}
                  textContent={grade.value ? `${grade.priceVariation}${currency} ${grade.value}` : '-'}
                />
                }
              </li>
            ))
          }
        </ul>
      </div>
    );
  };

  isPackOrder(typeId) {
    return typeId === PACK_ORDER_TYPE_ID;
  }

  getTitle(isCallOnGrain) {
    const { isPickupOrder, isDeliveryOrder } = this.props;
    if(isPickupOrder)
      return 'Pickup Request Order Details';
    if (isDeliveryOrder)
      return 'Delivery Request Order Details';
    if (isCallOnGrain)
      return 'Call On Grain Order Details';
    if (this.isPackOrder(get(this.props.order, 'typeId')))
      return 'Pack Order Details';

    return 'Freight Order Details';
  }


  omitFieldsForSelfOrder = (invoicePayload) => {
    const redundantKeys = ['Payment Term', 'Freight Rate In', 'Freight Rate Out', 'Freight Rate', 'Invoicing'];
    omit(invoicePayload, redundantKeys);
  };

  render() {
    const { order, isPickupOrder, isDeliveryOrder } = this.props;
    const { expanded } = this.state;
    const isCallOnGrain = get(order, 'typeId') === 3;
    const isPackOrder = this.isPackOrder(get(order, 'typeId'));
    const unit = order.requestedUnit || order?.commodity?.tonnageUnit
    const currency = get(order, 'currency') || getCountryCurrency()
    let general = {};
    if (order.quantity) general[order.quantityLabel] = order.quantity + ' ' + order.quantityUnit;

    general = {
      ...general,
      [getCountryLabel('tonnage')]: order.plannedTonnage + ' ' + unit,
      Commodity: get(order, 'commodity.displayName', EMPTY_VALUE),
      Variety: get(order, 'variety.name', EMPTY_VALUE),
    };

    if (order.isPoolContract) {
      general['Grade'] = order.poolGrades || EMPTY_VALUE;
    } else {
      general['Grade'] = getGradeName(order) || EMPTY_VALUE;
    }

    general['Season'] = get(order, 'season', EMPTY_VALUE);
    if(!isPackOrder)
      general['Transport Mode'] = order?.transportMode
    let shippingDetails = {};
    let containerDetails = {};
    if (isPackOrder) {
      const freightShipping = get(order, 'freightShipping');
      const freightContainer = get(order, 'freightContainer');
      const releaseNumbers = freightContainer.releaseNumbers || [];
      shippingDetails = {
        'Lloyd Number': freightShipping.lloyedNumber,
        'Shippers Ref No.':freightShipping.shipperRefNumber,
        'Customs Ref':freightShipping.customsRefNumber,
        'Export Declaration Number':freightShipping.exportDeclarationNumber,
        'Import Permit Number':freightShipping.importPermitNumber,
        'Import Permit Date': freightShipping.importPermitDate ? toDateFormat(freightShipping.importPermitDate) : EMPTY_VALUE,
        'Shipping Line': get(freightShipping, 'shippingLine.displayName'),
        'Vessel Name':freightShipping.vesselName,
        'Loading Port': getLoadingPortDisplayName(freightShipping.loadingPort),
        'Destination Port':freightShipping.destinationPort,
        'Final Destination':freightShipping.finalDestination,
        'Shipped To': freightShipping.shippedTo
      };
      containerDetails = {
        'Release Numbers': releaseNumbers.join(', '),
        'Number Of Containers':freightContainer.numberOfContainers,
        'Dual Seal':freightContainer.dualSeal ? 'Yes' : 'No',
        'Container Lining':freightContainer.containerLining ? 'Yes' : 'No',
        'Fumigation':freightContainer.fumigation ? 'Yes' : 'No',
        'Pack By Date':toDateFormat(freightContainer.packByDate),
        'Delivered By Date':toDateFormat(freightContainer.deliverByDate),
        'Pack Site': getHandlerName(freightContainer.consignor),
        'Pack Storage': getSiteName(freightContainer.consignor),
        'Empty Container AO': get(freightContainer, 'emptyContainerAo.name'),
        'Packed Container AO': get(freightContainer, 'packedContainerAo.name')
      };
    }

    const consignor = get(order, 'freightPickup.consignor');
    const consignee = get(order, 'freightDelivery.consignee');
    let showCounterCheckpointDetails = false;
    if (isPickupOrder && consignee)
      showCounterCheckpointDetails = true;
    if (isDeliveryOrder && consignor)
      showCounterCheckpointDetails = true;
    const outloadThroughputFee = get(order, 'outloadThroughputFee', null);
    const inloadThroughputFee = get(order, 'inloadThroughputFee', null);
    const consignorCompanyId = get(consignor, 'handler.companyId');
    const consigneeCompanyId = get(consignee, 'handler.companyId');
    const currentUserCompanyId = get(this.props, 'currentUser.companyId');
    const getLinkedPickupOrderLabel = () => {
      const orderNumber = get(order, 'freightPickup.orderNumber');
      if(!orderNumber)
        return EMPTY_VALUE;
      if(get(order, 'pickupOrder.identifier', '').toLowerCase() === orderNumber.toLowerCase())
        return <a rel='noopener noreferrer' target='_blank' href={`/#/freights/orders/${order.pickupOrder.id}/order`}>{orderNumber}</a>;
      return orderNumber;
    };
    const getLinkedDeliveryOrderLabel = () => {
      const orderNumber = get(order, 'freightDelivery.orderNumber');
      if(!orderNumber)
        return EMPTY_VALUE;
      if(get(order, 'deliveryOrder.identifier', '').toLowerCase() === orderNumber.toLowerCase())
        return <a rel='noopener noreferrer' target='_blank' href={`/#/freights/orders/${order.deliveryOrder.id}/order`}>{orderNumber}</a>;
      return orderNumber;
    };

    const getPickupAddress = consignor => {
      const sites = get(consignor, 'sites');
      const handler = get(consignor, 'handler');
      let address = get(handler, 'address.name');
      let locationType = 'farm';
      if (!isEmpty(sites)) {
        address = get(sites, '0.location.address.address')
        locationType = 'storage';
      }
      if(!address){
        return EMPTY_VALUE
      }

      return <div style={{ whiteSpace: 'normal' }}>
        <span><>{address}</>
        <IconButton
          onClick={() => this.setState({openConsignorLocationForm: true, selectedConsignorLocationType: locationType})}
          style={{paddingLeft: '0px'}}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
        </span>
      </div>
    }

    const getPickupSiteMobile = consignor => {
      const handler = get(consignor, 'handler');
      const mobile = get(handler, 'mobile');
      if(!mobile)
        return EMPTY_VALUE

      return <div style={{ whiteSpace: 'normal' }}>
        <span><>{toPhoneFormat(mobile)}</>
        <IconButton
          onClick={() => this.setState({openConsignorLocationForm: true, selectedConsignorLocationType: 'farm'})}
          style={{paddingLeft: '0px'}}
          size="small">
            <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
        </span>
      </div>
    }

    const getDeliveryAddress = consignee => {
      const sites = get(consignee, 'sites');
      const handler = get(consignee, 'handler');
      let address = get(handler, 'address.name');
      let locationType = 'farm';

      if (!isEmpty(sites)) {
        address = get(sites, '0.location.address.address')
        locationType = 'storage';
      }

      if(!address){
        return EMPTY_VALUE
      }
      return <div style={{ whiteSpace: 'normal' }}>
        <span><>{address}</>
        <IconButton
          onClick={() => this.setState({openConsigneeLocationForm: true, selectedConsigneeLocationType: locationType})}
          style={{paddingLeft: '0px'}}
          size="small">
            <PinDropIcon style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
        </span>
      </div>
    }

    const getDeliverySiteMobile = consignee => {
      const handler = get(consignee, 'handler');
      const mobile = get(handler, 'mobile');
      if(!mobile)
        return EMPTY_VALUE

      return <div style={{ whiteSpace: 'normal' }}>
        <span><>{toPhoneFormat(mobile)}</>
        <IconButton
          onClick={() => this.setState({openConsigneeLocationForm: true, selectedConsigneeLocationType: 'farm'})}
          style={{paddingLeft: '0px'}}
          size="small">
            <Create style={{ color: PRIMARY_COLOR_GREEN, fontSize: "0.95rem", marginBottom: "0.1rem" }} />
        </IconButton>
        </span>
      </div>
    }

    let pickupPayload = (isDeliveryOrder && !showCounterCheckpointDetails) ? {} : {
      'Pickup Site': getHandlerName(consignor),
      'Pickup Storage': getSiteName(consignor),
      'Pickup Address': getPickupAddress(consignor),
      'Pickup Site Phone No': getPickupSiteMobile(consignor),
      'Delivery Start Date': get(order, 'freightPickup.dateTime') ? toDateFormat(order.freightPickup.dateTime) : EMPTY_VALUE,
      'Delivery End Date': get(order, 'freightDelivery.dateTime') ? toDateFormat(order.freightDelivery.dateTime) : EMPTY_VALUE,
      'Pickup Order No': getLinkedPickupOrderLabel(),
    };

    let deliveryPayload = (isPickupOrder && !showCounterCheckpointDetails) ? {} : {
      'Delivery Site': getHandlerName(consignee),
      'Delivery Storage': getSiteName(consignee),
      'Delivery Address': getDeliveryAddress(consignee),
      'Delivery Site Phone No': getDeliverySiteMobile(consignee),
      'Delivery Start Date': get(order, 'freightPickup.dateTime') ? toDateFormat(order.freightPickup.dateTime) : EMPTY_VALUE,
      'Delivery End Date': get(order, 'freightDelivery.dateTime') ? toDateFormat(order.freightDelivery.dateTime) : EMPTY_VALUE,
      'Delivery Order No': getLinkedDeliveryOrderLabel(),
    };

    if (includes([FREIGHT_CONTRACT_TYPE.CUSTOMER_ONLY, FREIGHT_CONTRACT_TYPE.SELLER_TO_BUYER], order?.typeId)) {
      pickupPayload['Pickup Instructions'] = get(order, 'freightPickup.instructions', EMPTY_VALUE);
      deliveryPayload['Delivery Instructions'] = get(order, 'freightDelivery.instructions', EMPTY_VALUE);
    }

    const rateFreightIn = order.rateFreightIn ? `${currency} ${parseFloat(order.rateFreightIn).toFixed(2)}` : EMPTY_VALUE;
    const rateFreightOut = order.rateFreightOut ? `${currency} ${parseFloat(order.rateFreightOut).toFixed(2)}` : EMPTY_VALUE;
    const invoicePayload = {
      Invoicing: get(order, 'invoicing', EMPTY_VALUE),
      'Payment Term': get(order, 'paymentTerm.name', EMPTY_VALUE),
      'Freight Rate In': rateFreightIn,
      'Freight Rate Out': order.rateFreightOut ? `${currency} ${parseFloat(order.rateFreightOut).toFixed(2)}` : EMPTY_VALUE,
      'Commission Rate': order.commissionRate ?  `${currency} ${order.commissionRate.toFixed(2)}` : EMPTY_VALUE,
      ...(order.rateOvers && { 'Overs Rate': `${currency} ${parseFloat(order.rateOvers).toFixed(2)}` }),
      'Est. Distance': get(order, 'estimatedDistance') ? get(order, 'estimatedDistance') + ' ' + getCountryConfig()?.distanceUnit : EMPTY_VALUE,
      'Est. Total Time': get(order, 'estimatedTime') ? get(order, 'estimatedTime') : EMPTY_VALUE,
    };

    if (!isNull(outloadThroughputFee) && (consignorCompanyId === currentUserCompanyId) && (consignorCompanyId !== get(order, 'customer.company.id')) ) {
      pickupPayload['Throughput Order'] = 'Yes';
    }

    if (!isNull(inloadThroughputFee) && (consigneeCompanyId === currentUserCompanyId) && (consigneeCompanyId !== get(order, 'customer.company.id'))) {
      deliveryPayload['Throughput Order'] = 'Yes';
    }

    if (
      !order.isCustomer &&
      (order.isCustomerRegistered || (!order.isCustomerRegistered && (order.isConsignee || order.isConsignor || order.isFreightProvider)))
    ) {
      invoicePayload['Freight Rate'] = invoicePayload['Freight Rate Out'];
      delete invoicePayload['Freight Rate In'];
      delete invoicePayload['Commission Rate'];
      delete invoicePayload['Freight Rate Out'];
      if (!order.isFreightProvider) {
        delete invoicePayload['Invoicing'];
        delete invoicePayload['Payment Term'];
        delete invoicePayload['Freight Rate'];
      }
    }

    if (!order.parentOrderId && !order.commissionRate) {
      delete invoicePayload['Commission Rate'];
      delete invoicePayload['Freight Rate In'];
      invoicePayload['Freight Rate'] = rateFreightOut;
      delete invoicePayload['Freight Rate Out'];
    }

    if (get(order, 'isSelf'))
      this.omitFieldsForSelfOrder(invoicePayload);

    if (!order.isCustomer && !order.isFreightProvider) delete invoicePayload['Freight Rate'];

    if (isPackOrder) {
      delete invoicePayload['Freight Rate'];
      delete invoicePayload['Est. Distance'];
      delete invoicePayload['Est. Total Time'];
      invoicePayload[`Packing Rate Per ${unit}`] = order.ratePacking ? `$${order.ratePacking}` : EMPTY_VALUE;
      invoicePayload[`Fumigation Fee Per ${unit}`] = order.fumigationFee ? `$${order.fumigationFee}` : EMPTY_VALUE;
      invoicePayload['Wharf Delivery Fee'] = order.wharfDeliveryFee ? `$${order.wharfDeliveryFee}` : EMPTY_VALUE;
      invoicePayload['Wharf Booking Fee'] = order.wharfBookingFee ? `$${order.wharfBookingFee}` : EMPTY_VALUE;
    }

    const getPriceDistribution = () => {
      if(!order.priceDistribution)
        return false
      let prices = {}
      if(order.priceDistribution?.contract)
        prices['Contract Price'] = order.priceDistribution.contract
      if(order.priceDistribution?.ld)
        prices['LD Price'] = order.priceDistribution.ld
      if(order.priceDistribution?.spread)
        prices['Spread Price'] = order.priceDistribution.spread
      if(order.priceDistribution?.additionalCost)
        prices['Additional Cost'] = order.priceDistribution.additionalCost
      if(order.priceDistribution?.total)
        prices['Total Price'] = order.priceDistribution.total

      return prices
    }

    const priceDistribution = getPriceDistribution()

    return (
      <Paper className='order-details-section-container'>
        <h2 onClick={this.toggleExpanded}>
          {this.getTitle(isCallOnGrain)}
          <span className='expand-icon'>{renderBasedOnExpandedFlag(expanded)}</span>
        </h2>
        {expanded && !isPackOrder && (
          <div className='section-details-container'>
            {this.getSectionAsPerGrid(general, '', true)}
            {(!isDeliveryOrder || (isDeliveryOrder && showCounterCheckpointDetails)) && this.getSectionAsPerGrid(pickupPayload)}
            {(!isPickupOrder || (isPickupOrder && showCounterCheckpointDetails)) && this.getSectionAsPerGrid(deliveryPayload)}
            {!isCallOnGrain && !isPickupOrder && !isDeliveryOrder && this.getSectionAsPerGrid(invoicePayload)}
            {priceDistribution && this.getSectionAsPerGrid(priceDistribution)}
          </div>
        )}
        {expanded && isPackOrder && (
          <div className='section-details-container'>
            {this.getSectionAsPerGrid(shippingDetails, 'Shipping Details')}
            {this.getSectionAsPerGrid(general, 'Commodity Details')}
            {this.getSectionAsPerGrid(containerDetails, 'Container Details')}
            {this.getSectionAsPerGrid(invoicePayload, 'Invoicing')}
          </div>
        )}
        {this.state.conversion &&
          <UnitConversions
            onClose={() => this.setState({conversion: false})}
            unitConversions={order.unitConversions}
            preferredUnit={unit}
            commodityUnit={order.commodity.unit}
          />
        }
        {this.state.openConsignorLocationForm && this.state.selectedConsignorLocationType === 'farm' &&
          <UpdateLocation updateEntities={() => window.location.reload()} entityId={get(consignor, 'handler.id')} entity='farm' onCloseDrawer={() => this.setState({openConsignorLocationForm: false, selectedConsignorLocationType: undefined})}/>
        }
        {this.state.openConsigneeLocationForm && this.state.selectedConsigneeLocationType === 'farm' &&
          <UpdateLocation updateEntities={() => window.location.reload()} entityId={get(consignee, 'handler.id')} entity='farm' onCloseDrawer={() => this.setState({openConsigneeLocationForm: false, selectedConsigneeLocationType: undefined})}/>
        }
        {this.state.openConsignorLocationForm && this.state.selectedConsignorLocationType === 'storage' &&
          <UpdateLocation updateEntities={() => window.location.reload()} farmId={get(consignor, 'handler.id')} selectedStorage={get(consignor, 'sites.0.location')} entity='storage' onCloseDrawer={() => this.setState({openConsignorLocationForm: false, selectedConsignorLocationType: undefined})}/>
        }
        {this.state.openConsigneeLocationForm && this.state.selectedConsigneeLocationType === 'storage' &&
          <UpdateLocation updateEntities={() => window.location.reload()} farmId={get(consignee, 'handler.id')} selectedStorage={get(consignee, 'sites.0.location')} entity='storage' onCloseDrawer={() => this.setState({openConsigneeLocationForm: false, selectedConsigneeLocationType: undefined})}/>
        }
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.main.user.user,
    userToken: state.main.user.token,
  };
};

export default connect(mapStateToProps)(OrderDetailsSection);

import React, { Component } from 'react';
import { connect } from 'react-redux';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import CommonButton from '../common/CommonButton';
import './acceptRejectContract.scss';
import APIService from '../../services/APIService';
import TextField from '@mui/material/TextField';
import moment from "moment";
import {required} from '../../common/validators';

class UnregisteredUserConfirmContract extends Component{
  constructor(props) {
    super(props);
    this.state = {
      contractNumber: {
        value: this.props.contract && this.props.contract.contractNumber ? this.props.contract.contractNumber : undefined,
        validators: [],
        errors: []
      },
      canConfirm: false,
      isConfirmed: false,
      contract: undefined,
      loaded: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async componentDidMount(){
    if(this.props.match && this.props.match.params.contract_id) {
      const contract = await APIService.contracts(this.props.match.params.contract_id).appendToUrl('unreg/').get(this.props.match.params.salt);
      const newState = {...this.state};
      if(contract && contract.id){
        newState.contractNumber.validators = contract.isContractNumberMandatoryForConfirmation ? [required()] : [];
        newState.contractNumber.value = contract.contractNumber;
        newState.canConfirm = contract.confirmable && (contract.status === 'planned' || contract.status !== 'confirmed');
        newState.isConfirmed = contract.status === 'confirmed';
        newState.contract = contract;
      }
      newState.loaded = true;
      this.setState(newState);
    }
  }

  handleSubmit(event) {
    this.setErrors();
    if (this.getIsFormValid()) {
      const data = { contractNumber: this.state.contractNumber.value, updatedAt: moment.utc(get(this.state.contract,'updatedAt')).local().valueOf() };
      const salt = this.props.match ? this.props.match.params.salt : null;
       APIService.contracts(this.state.contract.id)['confirm']().put(data,salt)
       .then((json) => {
         if(!isEmpty(json.errors)){
           if(!isEmpty(json.errors['contractNumber'])){
            this.setErrors(json.errors['contractNumber']);
           } else {
            this.setErrors(json.errors);
           }
         } else {
          if(json.status === 'confirmed'){
            this.setState({ isConfirmed: true });
          }
         }
       });
    }
    event.preventDefault();
  }

  handleChange(event) {
    var value = event.target.value.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
    const newState = {...this.state};
    newState.contractNumber.value = value;
    this.setState(newState, () => this.setErrors());
  }

  setErrors(errors) {
    const newState = {...this.state};
    newState.contractNumber.errors = errors || this.getErrors();
    this.setState(newState);
  }

  getErrors() {
    const errors = [];
    const value = get(this.state, `contractNumber.value`);
    const validators = get(this.state, `contractNumber.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  getIsFormValid() {
    return this.state.contractNumber.errors.length == 0;
  }

  isContractNumberFieldDisabled() {
    return this.state.contract && this.state.contract.party === 'seller' && this.state.contract.documentType.name === 'contract';
  }

  render () {
    return (
      <div className="full-screen-container">
        <div className="content-container">
          <div className="content-box">
            <div className="content-box--header">
              <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
            </div>
            <div className="content-box--content">
              <form onSubmit={this.handleSubmit} noValidate>
                { this.state.contract && this.state.canConfirm && !this.state.isConfirmed ? (
                    <div>
                      <h4 className="accept-title">Accept Contract</h4>
                      <TextField
                        label="Contract No"
                        placeholder="Please enter your Contract No"
                        id="contractNumber"
                        className="accept-field"
                        value={this.state.contractNumber.value}
                        onChange={this.handleChange}
                        helperText={get(this.state, 'contractNumber.errors[0]', '')}
                        errorStyle={{textAlign: "left"}}
                        fullWidth
                        inputProps={{
                          maxLength: 14,
                        }}
                        disabled={this.isContractNumberFieldDisabled()}
                        error={!isEmpty(this.state.contractNumber.errors[0])}
                        variant="standard" />
                      <CommonButton
                        type="submit"
                        variant="contained"
                        label="ACCEPT"
                        primary
                      />
                    </div>
                ) : (
                    this.state.contract && this.state.isConfirmed ? (
                      <span>Contract is Confirmed</span>
                    ) : this.state.loaded && (
                      <span>You do not have permission to access the information.</span>
                    )
                )}
              </form>
            </div>
          </div>
          <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
            here
          </a>.</div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    contract: state.companies.contracts.selectedContract,
  };
};

export default connect(mapStateToProps)(UnregisteredUserConfirmContract);

import React from 'react';
import { connect } from 'react-redux';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import APIService from '../../../services/APIService';
import {isArray, find, isEmpty} from 'lodash';
import LoaderInline from '../../LoaderInline';
import AddButton from '../../common/AddButton';
import { getBrokerageDisplayValue } from '../../../common/utils';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isLoading } from '../../../actions/main';

class BrokerageInvoiceGroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFetching: true,
      contracts: []
    };
    this.onChange = this.onChange.bind(this);
  }

  getData() {
    APIService.contracts().appendToUrl('brokerage-invoices/pending/').get().then(contracts => {
      const isOk = isArray(contracts);
      this.setState({isFetching: !isOk, contracts: isOk ? contracts : []}, () => {
        this.props.onDataLoad(this.state.contracts.length);
      });
    });
  }

  onRowClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    window.location = `/#/contracts/${id}/contract`;
  }

  onAddButtonClick(event, id) {
    event.stopPropagation();
    event.preventDefault();
    this.props.dispatch(isLoading('nonExistentComponent'));
    window.location = `/#/invoices/brokerage/new?commodityContractId=${id}`;
  }

  getTitle() {
    let suffix = this.state.isFetching ? '' : `(${this.state.contracts.length})`;
    return `Pending Brokerage Invoices ${suffix}`;
  }

  onChange(event, expanded) {
    if(expanded) {
      event.target.scrollIntoView(true);
      window.scrollBy(100, 100);
      if(isEmpty(this.state.contracts))
        this.getData();
    }
  }

  render() {
    return (
      <div className="action-centre-group">
        <Accordion style={{margin: '0px'}} onChange={this.onChange}>
          <AccordionSummary className="table-heading" expandIcon={<ExpandMoreIcon />}>
            {this.getTitle()}
          </AccordionSummary>
          <AccordionDetails style={{padding: '0px'}}>
            {
              this.state.isFetching ?
              <LoaderInline containerClassName="inline-loader-container" /> :
              <div className="table-container">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="xsmall">Identifier</TableCell>
                      <TableCell className="medium">Seller</TableCell>
                      <TableCell className="medium">Buyer</TableCell>
                      <TableCell className="medium">Description</TableCell>
                      <TableCell className="xxsmall">Seller Brokerage</TableCell>
                      <TableCell className="xxsmall">Buyer Brokerage</TableCell>
                      <TableCell align='center' className="small">Actions</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {
                      isArray(this.state.contracts) ?
                      this.state.contracts.map(contract => {
                        const sellerBrokerage = find(contract.brokerages, {type: 'Seller'});
                        const buyerBrokerage = find(contract.brokerages, {type: 'Buyer'});
                        const unit = contract.isStrictQuantityBasedCommodity ? contract.quantityUnit : contract.tonnageUnit;
                        return (
                          <TableRow
                            className="clickable"
                            key={contract.referenceNumber}
                            onClick={(event) => this.onRowClick(event, contract.id)}
                            >
                            <TableCell className="xsmall">{contract.referenceNumber}</TableCell>
                            <TableCell className="medium">{contract.sellerName}</TableCell>
                            <TableCell className="medium">{contract.buyerName}</TableCell>
                            <TableCell className="medium">{contract.description}</TableCell>
                            <TableCell className="xxsmall">
                              {sellerBrokerage && getBrokerageDisplayValue(sellerBrokerage, unit)}
                            </TableCell>
                            <TableCell align='center' className="xxsmall">
                              {buyerBrokerage && getBrokerageDisplayValue(buyerBrokerage, unit)}
                            </TableCell>
                            <TableCell align='center' className="small">
                              <AddButton
                                tooltipTitle="Create Brokerage Invoice"
                                label="Invoice"
                                onClick={(event) => this.onAddButtonClick(event, contract.id)}
                                style={{ float: 'none'}}
                                variant="outlined"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      }) :
                      <TableRow>
                        <TableCell colSpan="100" className='no-record-column'>
                          No records found
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  token: state.main.user.token,
});

export default connect(mapStateToProps)(BrokerageInvoiceGroup);

import React from 'react';

import connect from "react-redux/es/connect/connect";
import APIService from '../../services/APIService';
import isEmpty from 'lodash/isEmpty';
import alertifyjs from 'alertifyjs';
import { RejectionReasonDialog } from "../../components/rejections/RejectionReasonDialog";
import { required } from '../../common/validators';
import get from 'lodash/get';
import {
  getInvoicePayments,
  INVOICE_PAYMENT_REJECTED,
  invoicePaymentRejectDialog,
  getInvoicePaymentSummary,
  invoicePaymentVoidDialog,
  setSelectedInvoicePayment,
} from "../../actions/companies/invoices";

class InvoicePaymentRejectForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rejectionReason: {
        value: '',
        validators: [required()],
        errors: []
      },
      rejectDialogOpen: false,
      errors: [],
    };

    this.handleRejectClick = this.handleRejectClick.bind(this);
    this.handleRejectSubmit = this.handleRejectSubmit.bind(this);
    this.handleRejectClose = this.handleRejectClose.bind(this);
  }

  componentDidUpdate(prevProps) {
    if((this.props.isDialogOpened && this.props.isDialogOpened !== prevProps.isDialogOpened) || (
      this.props.isVoidDialogOpened && this.props.isVoidDialogOpened !== prevProps.isVoidDialogOpened)){
      this.handleRejectClick();
    }
  }

  handleRejectSubmit() {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    if (this.state.rejectionReason.errors.length === 0) {
      let action = this.props.isVoidDialogOpened ? 'void': 'reject';
      APIService.invoices().appendToUrl(`invoice-payments/${this.props.invoicePayment.id}/${action}/`).put(data, this.props.token)
        .then((json) => {
          if(!isEmpty(json.errors)){
            alertifyjs.error(json.errors[0]);
          } else {
            const newState = {...this.state};
            alertifyjs.success(INVOICE_PAYMENT_REJECTED);
            this.props.dispatch(getInvoicePaymentSummary(this.props.invoicePayment.invoiceId));
            this.props.dispatch(getInvoicePayments(this.props.invoicePayment.invoiceId));
            this.props.dispatch(setSelectedInvoicePayment(null));
            this.props.dispatch(invoicePaymentVoidDialog(false));
            newState.rejectionReason.value = '';
            this.setState(newState);
          }
        });
    }
  }

  handleRejectClick() {
    const newState = {...this.state};
    newState.rejectDialogOpen = true;
    this.setState(newState);
  }

  handleRejectClose() {
    const newState = {...this.state};
    newState.rejectDialogOpen = false;
    newState.rejectionReason.value = '';
    this.setState(newState);
    const { dispatch } = this.props;
    dispatch(invoicePaymentRejectDialog(null, false));
    dispatch(setSelectedInvoicePayment(null));
    dispatch(invoicePaymentVoidDialog(false));
  }

  handleReasonChange = (event) => {
    const value = event.target.value;
    const newState = {...this.state};
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  };

  setReasonErrors(errors) {
    const newState = {...this.state};
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  render() {
    return ((this.props.isDialogOpened || this.props.isVoidDialogOpened) && <RejectionReasonDialog
      open={this.state.rejectDialogOpen}
      onClose={this.handleRejectClose}
      title={this.props.isVoidDialogOpened ? "Void Invoice Payment" : "Reject Invoice Payment"}
      value={this.state.rejectionReason.value}
      helperText={get(this.state, 'rejectionReason.errors[0]', '')}
      onChange={this.handleReasonChange}
      onCancel={this.handleRejectClose}
      onReject={this.handleRejectSubmit}
      submitText={this.props.isVoidDialogOpened ? 'Void' : 'Reject'}
      placeholder={this.props.isVoidDialogOpened ? "Please enter your reason for Void" : null}
    />);
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.main.user.token,
    isDialogOpened: state.companies.invoices.isRejectInvoicePaymentDialogOpened,
    isVoidDialogOpened: state.companies.invoices.isVoidInvoicePaymentDialogOpened,
    invoicePayment: state.companies.invoices.selectedInvoicePayment,
  };
};

export default connect(mapStateToProps)(InvoicePaymentRejectForm);

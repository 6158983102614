import React from 'react';
import { connect } from 'react-redux';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import LoaderInline from '../LoaderInline';
import get from 'lodash/get';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { canCreateMovementForContract } from '../../actions/companies/contracts';

class FreightMovementWarningDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogList: false,
    };
    this.closePopup = this.closePopup.bind(this);
    this.redirectLink = this.redirectLink.bind(this);
  }
  handleDialog(value) {
    const { contract,FMwarningContractID } = this.props;
    switch (value) {
      case 1:
        this.props.createContract(get(contract,'id') || FMwarningContractID);
        this.props.handleToUpdate();
        break;
      case 2:
        this.setState({ dialogList: true });
        break;
      case 3:
        this.setState({ dialogList: false });
        break;
    }
  }
  closePopup() {
    this.setState({ dialogList: false }, () => {
      this.props.handleToUpdate();
    });
  }

  redirectLink(id) {
    window.location = `#/freights/movements/new?orderId=${id}`;
    this.props.handleToUpdate();
  }

  render() {
    const { fmWarningList } = this.props;
    return (
      <div>
        {
          this.state.dialogList ? (
            <Dialog open={true} onClose={this.closePopup} aria-labelledby="responsive-dialog-title">
              <DialogTitleWithCloseIcon onClose={this.closePopup} style={{ marginBottom: '10px', height: '58px', paddingTop: '12px' }}>
                Select order from the following list:
              </DialogTitleWithCloseIcon>
              <DialogContent dividers>
                <DialogContentText>
                  {
                    fmWarningList.length == 0 &&
                    <div style={{ minHeight: '75px' }}>
                      <LoaderInline
                        imageStyle={{ width: '17%' }}
                        containerClassName='inline-loader-container'
                      />
                    </div>
                  }
                  <Table style={{ minWidth: '500px' }}>
                    <TableBody>
                      {
                        fmWarningList.map(row => (
                          <TableRow
                            key={row.id}
                            hover
                            onClick={() => this.redirectLink(row.id)}
                            style={{cursor: 'pointer'}}>
                            {
                              row.displayName.split(" - ").map(field => (
                                <TableCell
                                  key={row.id}
                                  align="left"
                                  width="60px"
                                  style={{cursor: 'pointer'}}>
                                  {field}
                                </TableCell>
                              ))}
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  variant="contained"
                  type='button'
                  onClick={() => this.handleDialog(3)}
                  color='primary'
                  className='add-button'
                  style={{ float: 'left', marginLeft: '4px', height: '35px', }}>
                  Back
                </Button>
              </DialogActions>
            </Dialog>
          ) :
          <Dialog open={true} onClose={this.closePopup}>
            <DialogTitleWithCloseIcon
              onClose={this.closePopup}
              style={{ marginBottom: '10px', height: '58px', paddingTop: '12px' }}>
              Warning!
            </DialogTitleWithCloseIcon>
            <DialogContent dividers style={{ borderBottom: ' 1px solid #e6e6e6' }}>
              <DialogContentText>
                This contract already has order(s) planned under it. Freight Movement should be created under those order(s). Are you sure you want to create a Freight Movement under this contract directly?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.handleDialog(2)} color="primary">
                No, create against order
              </Button>
              <Button onClick={() => this.handleDialog(1)} color="primary">
                Yes, create against contract
              </Button>
            </DialogActions>
          </Dialog>
        }
      </div>
    );
  }
};

const mapDispatchToProps = dispatch => ({
  createContract: id => dispatch(canCreateMovementForContract(id))});

export default connect(null, mapDispatchToProps)(FreightMovementWarningDialog);

import React from 'react';
import  { useSelector, useDispatch }  from 'react-redux';
import { useEffect, useState } from 'react';
import APIService from '../../services/APIService';
import { get } from 'lodash';
import CustomEmailDialog from './CustomEmailDialog';
import find from 'lodash/find';
import { isLoading, forceStopLoader } from '../../actions/main';


let WarehouseCustomEmail = props => {

  const [isLoaded, setLoaded ] = useState(false);
  const [allContactsList, setAllContactsList] = useState({});
  const [partyEmails, setPartyEmails] = useState();
  const dispatch = useDispatch();
  const isCreate = get(props.clickedOption, 'key') === 'create_send' || get(props.invoiceDetails, 'statusDisplayName') === 'draft';

  const token = useSelector(state => {
    return state.main.user.token;
  });
  const user = useSelector(state => {
    return state.main.user.user;
  });

  let getSelectedParties = () => {
    if (isCurrentUserWarehouseCompany()){
      return ["billToParty"];
    }
    return ["warehouseCompany"];
  };

  let getCompanyEmployees = async (companyId) => {

    let employees = companyId ?  await APIService.companies(companyId)
        .employees()
        .get(props.token): "";
    return employees;
  };

  let isCurrentUserWarehouseCompany = () => {
    if(get(props.invoiceDetails, 'payeeCompanyId') === user.companyId)
      return true;
    else return false;
  };

  let getWarehouseCompanyCompanies = async() => {
    return await APIService.companies().get(token, undefined, { type_id: 2 });
  };

  let warehouseCompanyRepresentedCompanies = async(companyId, query) => {
    return await APIService.companies(companyId).companies().appendToUrl('minimal/').get(token, null, query);
  };

  let setWarehouseCompanyAndBillToContacts = async invoiceDetails => {
    dispatch(isLoading('CustomEmailDialog'));
    let warehouseCompany = [];
    let billToContact = [];
    let warehouseCompanyEmail = null;
    let billtoContactEmail = null;
    if(isCurrentUserWarehouseCompany()){
      warehouseCompany = await getCompanyEmployees(user.companyId);
      warehouseCompanyEmail = user.email;
      let billToCompanies = await warehouseCompanyRepresentedCompanies(user.companyId);
      let billToCompanyId = get(find(billToCompanies, {'name': get(invoiceDetails, 'payerDisplayName')}), 'id');
      billToContact = await getCompanyEmployees(billToCompanyId);
      billtoContactEmail = get(find(billToContact, {'name': get(invoiceDetails, 'payer.contactName')}), 'email');
    }
    else {
      let warehouseCompanyCompanies = await getWarehouseCompanyCompanies();
      let warehouseCompanyCompanyId = get(find(warehouseCompanyCompanies, {'name': get(invoiceDetails,
                                                                                       'payeeDisplayName')}), 'id');
      billtoContactEmail = user.email;
      warehouseCompany = await getCompanyEmployees(warehouseCompanyCompanyId);
      billToContact = get(invoiceDetails, 'sentBy.email');
    }
    setAllContactsList({
      'warehouseCompany': warehouseCompany,
      'billToParty': billToContact
    });
    setPartyEmails({
      'warehouseCompany': warehouseCompanyEmail,
      'billToParty': billtoContactEmail
    });
    setLoaded(true);
    dispatch(forceStopLoader());
  };

  useEffect(() => {
    if (props.invoiceDetails && get(props, 'invoiceDetails.type') == 'WarehouseFee' && props.showCustomEmailDialog){
      setLoaded(false);
      setWarehouseCompanyAndBillToContacts(props.invoiceDetails);
    }
  },[props.invoiceDetails, props.showCustomEmailDialog]);

  let getPartyContacts = () =>{
    return allContactsList;
  };

  let getPartyEmails = () =>{
    return partyEmails;
  };

  let getEmailSubject = () =>{
    if (props.subject){
      return props.subject;
    }
    let companyName = user.company.name;
    if(isCreate)
      return `${companyName} Warehouse Invoice #${get(props, 'invoiceDetails.identifier')}`;

    return `[Void] ${companyName} Warehouse Invoice #${get(props, 'invoiceDetails.identifier')}`;
  };

  return (<span>
            {
              props.invoiceDetails && props.showCustomEmailDialog && isLoaded &&
                <CustomEmailDialog
                  parties={['billToParty', 'warehouseCompany']}
                  selectedParties={getSelectedParties()}
                  title={isCreate ? 'Send Invoice' : 'Void Invoice'}
                  partyEmails={getPartyEmails()}
                  partyContacts={getPartyContacts()}
                  subject={getEmailSubject()}
                  noBody={true}
                  open={props.showCustomEmailDialog}
                  onClose={props.closeCustomEmailDialog}
                  disableAcceptanceRequired={false}
                />
            }
          </span>);

};

export default WarehouseCustomEmail;

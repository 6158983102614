import React, { Component } from 'react';

import Button from '@mui/material/Button';
import { isEnabledForCurrentUser } from '../../common/utils';
import {PRIMARY_COLOR_GREEN, WHITE} from '../../common/constants';
import capitalize from 'lodash/capitalize';
import ContractIcon from '../common/icons/Contract';
import CompanyIcon from '../common/icons/Company';
import ActionCentre from '../common/icons/ActionCentre';
import FarmIcon from '../common/icons/Farm';
import StocksIcon from '../common/icons/Stocks';
import OrderIcon from '../common/icons/Order';
import MovementIcon from '../common/icons/Movement';
import InvoiceIcon from '../common/icons/Invoice';
import SiteManagementIcon from '../common/icons/SiteManagement';
import SiteBookingIcon from '../common/icons/SiteBooking';
import VendorDecsIcon from '../common/icons/VendorDecs';
import TitleTransferIcon from '../common/icons/TitleTransfer';
import CashBoardsIcon from '../common/icons/CashBoard';
import FreightSchedulerIcon from '../common/icons/FreightScheduler';
import DashboardIcon from '@mui/icons-material/Dashboard';

class PanelControl extends Component {
  constructor(props) {
    super(props);
  }

  getFillColor() {
    return this.props.activeTab === this.props.feature ? PRIMARY_COLOR_GREEN : WHITE;
  }

  getIconComponent(feature) {
    const fill = this.getFillColor();

    switch(feature) {
    case "dashboard":
      return <DashboardIcon style={{color: fill }} />;
      case "actionCentre":
        return <ActionCentre fill={ fill } />;
      case "companies":
        return <CompanyIcon fill={ fill } />;
      case "farms":
        return <FarmIcon fill={fill } />;
      case "contracts":
        return <ContractIcon fill={ fill } />;
      case "stocks":
        return <StocksIcon fill={ fill } />;
      case "movements":
        return <MovementIcon fill={ fill }/>;
      case "orders":
        return <OrderIcon fill={ fill } />;
      case "invoices":
        return <InvoiceIcon fill={ fill }/>;
      case "siteManagement":
        return <SiteManagementIcon fill={ fill }/>;
      case "siteBookings":
        return <SiteBookingIcon fill={ fill }/>;
      case "vendorDecs":
        return <VendorDecsIcon fill={ fill }/>;
      case "titleTransfers":
        return <TitleTransferIcon fill={ fill }/>;
      case "cashBoard":
        return <CashBoardsIcon fill={ fill }/>;
      case "freightScheduler":
        return <FreightSchedulerIcon fill={ fill }/>;
      default:
        break;
    }
  }

  render() {
    const { feature, activeTab } = this.props;
    const href = this.props.href || `#/${this.props.feature}`;
    const isEnabled = isEnabledForCurrentUser(feature);
    const displayText = this.props.displayText || capitalize(feature);
    const klass = (activeTab === feature || (activeTab === 'scheduler' && feature === 'freightScheduler')) ? 'selected' : '';
    return (
      <div>
        {
          isEnabled &&
          <div className="left-bar-control col-md-12">
            <Button fullWidth type="button" href={ href } className={ klass } >
              { this.getIconComponent(feature) }
              <span className="nav-text">
                { displayText }
              </span>
            </Button>
          </div>
        }
      </div>
    );
  }
}

export default PanelControl;

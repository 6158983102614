import React from 'react';
import alertifyjs from 'alertifyjs';
import moment from 'moment';
import { connect } from 'react-redux';
import Mark from "mark.js";
import {
  times, map, filter, reject, get, includes, omit, find, sumBy, min, isEmpty, findIndex,
  orderBy, isNumber, forEach, camelCase, without, forOwn, isEqual, compact, uniqBy, debounce, some
} from 'lodash';
import {
  Divider, Chip, IconButton, Menu, MenuItem, Dialog, DialogTitle, DialogContent, Typography, Table, TableHead, TableRow, TableCell, DialogActions, TableBody, TextField, InputAdornment
} from '@mui/material';
import {
  setHeaderText, isLoading, forceStopLoader, setBreadcrumbs
} from '../../../actions/main';
import { raiseMovementAmendRequest } from '../../../actions/companies/freights';
import APIService from '../../../services/APIService';
import { generateIdentifier, round, getCountryFormats } from '../../../common/utils';
import CalendarDateSelector from '../../company-sites/CalendarDateSelector';
import './FreightScheduler.scss';
import TruckTemplate from './TruckTemplate';
import MovementTemplate from './MovementTemplate';
import OrderTemplate from './OrderTemplate';
import StatusFilter from './StatusFilter';
import SideDrawer from '../../common/SideDrawer';
import UpdateTruck from '../../../containers/UpdateTruck';
import ManageTruck from './ManageTruck';
import TooltipTemplate from './TooltipTemplate';
import MovementDetailsDialog from './MovementDetailsDialog';
import { CommonToolTip } from '../../common/CommonToolTip';
import { ACCENT_COLOR_BLUE } from '../../../common/constants';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material/';
import CommonButton from '../../common/CommonButton';
import { AppContext } from '../../main/LayoutContext';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Done } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Info as InfoIcon } from '@mui/icons-material'
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import { RejectionReasonDialog } from "../../rejections/RejectionReasonDialog";

const DEFAULT_OPTION = {id: 'week', name: 'Weekly View', viewLength: 5};
const VIEW_OPTIONS = [
  {id: 'day', name: 'Daily View', viewLength: 1},
  DEFAULT_OPTION,
  {id: 'includeWeekends', name: 'Include Weekends', viewLength: 7},
];

const MIN_CHAR_FOR_SEARCH = 3;

const MANAGE_TRUCK_OPTIONS = [
  {id: 'addTrucks', name: 'Add Trucks'},
  {id: 'removeTrucks', name: 'Remove Trucks'},
];
const ACTIONS = [
  {
    id: 'scheduleView',
    name: 'Schedule View',
    toggle: true,
  },
  {
    id: 'manageTrucks',
    name: 'Manage Trucks',
    toggle: true,
  },
];

class FreightScheduler extends React.Component {
  static contextType = AppContext
  constructor(props) {
    super(props);
    this.viewLength = DEFAULT_OPTION?.viewLength;
    const today = moment();
    const startDate = this.getStartDate(today);
    const endDate = this.getEndDate(startDate);
    this.orderStatuses = ['confirmed', 'open', 'inProgress', 'delivered', 'completed'],
    this.movementStatuses =  ['unsaved', 'planned', 'confirmed', 'open', 'inProgress', 'delivered', 'completed', 'delayed', 'invoiced'],
    this.state = {
      countryFormats: getCountryFormats(),
      addingTruck: false,
      render: 0,
      searchInput: '',
      filteredOrders: [],
      showActions: false,
      changeViewButton: false,
      selectedViewOption: DEFAULT_OPTION,
      showManageTruckOptions: false,
      manageTrucks: false,
      removingTrucks: false,
      focusDate: today,
      start: startDate,
      end: endDate,
      viewLength: this.viewLength,
      showWeekends: false,
      placeholderText: 'Search for Orders',
      collapsedTrucks: get(this.getCache(), 'collapsedTrucks', []),
      trucks: [],
      movementTrucks: [],
      isLoadingTrucks: false,
      isLoadingManagedTrucks: false,
      movements: [],
      isLoadingOrders: false,
      isLoadingMovements: false,
      orders: [],
      allOrders: [],
      selectedOrders: [],
      searchOrderText: '',
      movementContextTarget: null,
      selectedMovement: null,
      movementDialog: false,
      truckContextTarget: null,
      selectedTruck: null,
      editTruck: false,
      movementEnabledStatuses: [...this.movementStatuses],
      orderEnabledStatuses: [...this.orderStatuses],
      draggableStatuses: ['unsaved', 'planned', 'confirmed'],
      invalidDrafts: [],
      executedMovementCount: 0,
      isVoidDialogOpen: false,
      requestReason: '',
    };
    this.chipRef = React.createRef();

    this.updateTruckTonnage = this.updateTruckTonnage.bind(this);
    this.getEndDate = this.getEndDate.bind(this);
  }

  toggleEditTruck = () => this.setState({ editTruck: !this.state.editTruck, truckContextTarget: null })

  getStartDate = focusDate => (focusDate || this.state.focusDate).clone().startOf('isoweek')
  getEndDate = start => (start || this.getStartDate()).clone().add(get(this.state, 'selectedViewOption')?.viewLength || this.viewLength, 'days')

  fetchOrders = () => {
    this.setState({ isLoadingOrders: true, orders: [], allOrders: [], searchOrderText: '', selectedOrders: [] }, () => {
      APIService.freights().appendToUrl('scheduler/orders/')
                .get(null, null, { start_date: this.state.start.format('YYYY-MM-DD'), end_date: this.state.end.format('YYYY-MM-DD') })
                .then(orders => {
                  const _orders = map(orders, order => {
                    order.draftUnaccountedTonnage = this.getRemainingOrderTonnage(order);
                    return order;
                  });
                  this.setState({ orders: _orders, allOrders: _orders, isLoadingOrders: false}, () => {
                    this.handleOrdersSearch(this.state.searchInput);
                    this.fetchMovements();
                  });
                });

    });
  }

  setDraftUnaccountedTonnage = (order, tonnage) => {
    order.draftUnaccountedTonnage = isNumber(tonnage) ? tonnage : this.getRemainingOrderTonnage(order);
    const newState = { ...this.state };
    const index = findIndex(newState.orders, { id: order.id });
    newState.orders.splice(index, 1, order);
    this.setState(newState, this.updateCacheForMovements);
  }

  unsavedMovementsTonnage = order => sumBy(filter(this.state.movements, { orderId: order.id, status: 'unsaved' }), 'plannedTonnage');

  getRemainingOrderTonnage = order => order.unaccountedTonnage - this.unsavedMovementsTonnage(order)

  getActualRemainingTonnage = order => {
    let actualTonnage = order.remainingTonnage - this.unsavedMovementsTonnage(order);
    if(actualTonnage < 0)
      return 0;
    return round(actualTonnage);
  }


  fetchMovementsForTruck = truckId => {
    const { orders, start, end } = this.state;
    const orderIds = map(orders, 'id');
    if (!isEmpty(orderIds) && truckId) {
      this.setState({ isLoadingMovements: true }, () => {
        const queryParams = { start_date: start.clone().subtract(1, 'days').format('YYYY-MM-DD'), end_date: end.clone().add('days', 1).format('YYYY-MM-DD'), order_ids: orderIds.join(','), 'void': true, truck_ids: truckId };
        APIService.freights().appendToUrl('scheduler/movements/').get(null, null, queryParams).then(movements => {
          movements = map(movements, movement => ({
            ...movement,
            createdAt: moment(movement.createdAt).toDate(),
            _startDate: movement.startDate ? moment(movement.startDate) : null,
            startDate: movement.startDate ? moment(movement.startDate).format('YYYY-MM-DD') : null,
            startTime: movement.startDate ? moment(movement.startDate).format("hh:mm A") : null,
            _endDate: movement.endDate ? moment(movement.endDate) : null,
            endDate: movement.endDate ? moment(movement.endDate).format('YYYY-MM-DD') : null,
            endTime: movement.endDate ? moment(movement.endDate).format("hh:mm A") : null,
            showStartTime: movement.showStartTime
          }));
          this.setState({ movements: [...this.state.movements, ...movements], isLoadingMovements: false }, () => {
            const _orders = map(this.state.allOrders, order => {
              order.draftUnaccountedTonnage = this.getRemainingOrderTonnage(order);
              return order;
            });
            this.setState({ orders: _orders, allOrders: _orders }, () => {
              this.handleOrdersSearch(this.state.searchInput)
              this.updateCacheForMovements()
            });
          });
        });
      });
    }
  }

  fetchMovements = () => {
    const { orders, start, trucks } = this.state;
    const orderIds = map(orders, 'id');
    const truckIds = map(trucks, 'id');
    if (!isEmpty(orderIds) && !isEmpty(truckIds)) {
      this.setState({ isLoadingMovements: true }, () => {
        const queryParams = { start_date: start.clone().subtract(1, 'days').format('YYYY-MM-DD'), end_date: start.clone().add(this.state.selectedViewOption?.id == 'day' ? this.state.viewLength : 7, 'days').format('YYYY-MM-DD'), order_ids: orderIds.join(','), 'void': true, truck_ids: truckIds.join(',') };
        APIService.freights().appendToUrl('scheduler/movements/').get(null, null, queryParams).then(movements => {
          movements = map(movements, movement => ({
            ...movement,
            createdAt: moment(movement.createdAt).toDate(),
            _startDate: movement.startDate ? moment(movement.startDate) : null,
            startDate: movement.startDate ? moment(movement.startDate).format('YYYY-MM-DD') : null,
            startTime: movement.startDate ? moment(movement.startDate).format("hh:mm A") : null,
            _endDate: movement.endDate ? moment(movement.endDate) : null,
            endDate: movement.endDate ? moment(movement.endDate).format('YYYY-MM-DD') : null,
            endTime: movement.endDate ? moment(movement.endDate).format("hh:mm A") : null,
            showStartTime: movement.showStartTime,
            inloadSlotId: movement.inloadSlotId,
            outloadSlotId: movement.outloadSlotId
          }));
          const cachedMovements = this.getCachedMovements();
          forEach(filter([...this.state.movements, ...cachedMovements], { status: 'unsaved' }), movement => {
            if (!find(movements, { identifier: movement.identifier }))
              movements.push(movement);
          });
          this.setState({ movements: movements, isLoadingMovements: false }, () => {
            const _orders = map(this.state.allOrders, order => {
              order.draftUnaccountedTonnage = this.getRemainingOrderTonnage(order);
              return order;
            });
            this.setState({ orders: _orders, allOrders: _orders }, () => {
              this.handleOrdersSearch(this.state.searchInput);
              this.updateCacheForMovements();
            });
          });
        });
      });
    }
  }

  fetchTrucks = () => this.setState(
    { isLoadingTrucks: true },
    () => APIService.companies(this.props.user.companyId)
                    .trucks().appendToUrl('?all_trucks=true')
                    .get()
                    .then(trucks => this.setState({
                      isLoadingTrucks: false,
                      trucks: [
                        find(trucks, { isFleet: true }),
                        ...orderBy(reject(trucks, { isFleet: true }), 'rego')
                      ]
                    }, this.fetchManagedTrucks))
  )

  fetchManagedTrucks = () => this.setState(
    { isLoadingManagedTrucks: true },
    () => APIService.companies(this.props.user.companyId)
      .appendToUrl('managed-trucks/')
      .get()
      .then(trucks => {
        const all_trucks = uniqBy([
          ...this.state.trucks, ...map(trucks, truck => ({ ...truck, managed: true }))
        ], 'id');
        this.setState({
          isLoadingManagedTrucks: false,
          trucks: all_trucks,
          movementTrucks: uniqBy([
            ...this.state.movementTrucks, ...map(all_trucks, truck => ({ id: truck.id, name: truck.rego , isActive: truck?.isActive }))
          ], 'id')
        }, this.fetchOrders)
      })
  )

  getDayName = date => date.format('ddd')

  getDayOfMonth = date => date.format('DD')

  isCurrentDate = date => date.format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')

  componentDidMount() {
    this.props.dispatch(setHeaderText('Freight Scheduler'));
    this.props.dispatch(setBreadcrumbs([{text: 'Freight Scheduler'}]));
    this.setState({ movements: this.getCachedMovements({'allView': true})}, () => {
      this.fetchTrucks();
    });
    const cache = this.getCache();
    let userCache = get(cache, this.props.user?.id)
    this.setState({
      orderEnabledStatuses: get(userCache, 'orderStatuses', this.state.orderEnabledStatuses),
      movementEnabledStatuses: get(userCache, 'movementStatuses', this.state.movementEnabledStatuses),
      selectedViewOption: get(userCache, 'scheduleView', DEFAULT_OPTION),
      viewLength: get(userCache, 'scheduleView.viewLength', this.viewLength),
      start: get(userCache, 'start') ? moment(get(userCache, 'start')) : this.state.start,
      end: get(userCache, 'end') ? moment(get(userCache, 'end')) : this.state.end,
      searchInput: get(userCache, 'searchInput', this.state.searchInput),
    }, () => {
      this.fetchOrders();
      this.handleOrdersSearch();
    });
  }

  onDateSectionClick = (event, truck, date) => {
    event.stopPropagation();
    event.preventDefault();
    if (date.isBefore(moment().format('YYYY-MM-DD')) && this.state.selectedOrders.length === 0) {
      return;
    }
    if (this.state.selectedOrders.length === 0)
      alertifyjs.error('Please select an order from the left menu to create jobs', 3);
    else if(this.state.selectedOrders.length !== 0 && date.isBefore(moment().format('YYYY-MM-DD'))) {
      alertifyjs.error('Cannot create planned movements on past dates', 3);
    }
    else if (!truck?.isActive)
      alertifyjs.error(`Truck ${truck?.rego} is archived`, 3);
    else {
      const order = find(this.state.allOrders, { id: this.state.selectedOrders[0] });
      if(moment(date, 'YYYY-MM-DD').isAfter(moment(order.endDate, 'YYYY-MM-DD')) || moment(date, 'YYYY-MM-DD').isBefore(moment(order.startDate, 'YYYY-MM-DD')) )
        alertifyjs.error("Cannot create planned movements outside order's delivery start and delivery end date range", 3);
      else
        this.addMovement({ startDate: date.format('YYYY-MM-DD'), endDate: date.format('YYYY-MM-DD'), plannedTruckId: truck.id });
    }
  }

  getSelectedOrderMovements = includeVoid => {
    if (isEmpty(this.state.selectedOrders))
      return [];

    const allOrderMovements = filter(this.state.movements, { orderId: this.state.selectedOrders[0] });
    if (includeVoid)
      return allOrderMovements;

    return reject(allOrderMovements, { status: 'void' });
  }

  addMovement = data => {
    const selectedOrder = find(this.state.orders, { id: this.state.selectedOrders[0] || data.orderId });
    if (selectedOrder.draftUnaccountedTonnage <= 0)
      alertifyjs.error("This order's tonnage is exhausted.");
    else {
      const selectedTruck = find(this.state.trucks, { id: data.plannedTruckId });
      const tonnage = min([selectedOrder.draftUnaccountedTonnage, selectedTruck.totalWeights.netWeight]);
      const newMovement = {
        identifier: generateIdentifier('freight_movement'),
        consignorName: selectedOrder.consignorName,
        consigneeName: selectedOrder.consigneeName,
        consignorId: selectedOrder.consignorId,
        consigneeId: selectedOrder.consigneeId,
        consignorHandlerId: selectedOrder.consignorHandlerId,
        consigneeHandlerId: selectedOrder.consigneeHandlerId,
        customer: selectedOrder.customer,
        commodity: selectedOrder.commodity,
        orderId: selectedOrder.id,
        status: 'unsaved',
        plannedTonnage: tonnage,
        createdAt: moment().toDate(),
        ...data
      };
      this.setState({ movements: [...this.state.movements, newMovement] }, () => {
        this.setDraftUnaccountedTonnage(selectedOrder, selectedOrder.draftUnaccountedTonnage - tonnage);
      });
    }
  }

  saveForLater = (movement, updatedDetails, currentOrder, draftUnaccountedTonnage) => {
    const newState = { ...this.state };
    const index = findIndex(newState.movements, { identifier: movement.identifier });
    let previousMovement = newState.movements.at(index);
    const fieldsToUpdate = ['outloadSlotId', 'inloadSlotId', 'plannedTonnage', 'plannedTruckId', 'driverId', 'outloadSlotUpdatedAt', 'inloadSlotUpdatedAt']
    forOwn(updatedDetails, (value, key) => {
      if (isEqual(key, 'freightPickup')) {
        if (updatedDetails.freightPickup.dateTime) {
          const mDate = moment(updatedDetails.freightPickup.dateTime + 'Z');
          previousMovement._startDate = mDate;
          previousMovement.startDate = mDate.format('YYYY-MM-DD');
          const hasTime = moment(updatedDetails.freightPickup.dateTime, 'YYYY-MM-DD HH:mm:ss', true).isValid();
          if (hasTime)
            previousMovement.startTime = mDate.format('hh:mm A');
          if (!updatedDetails.pickupSkipShowTime)
            previousMovement.showStartTime = true;
        }
        previousMovement.pickupBookingNumber = updatedDetails.freightPickup.bookingNumber;
        previousMovement.pickupInstructions = updatedDetails.freightPickup.instructions;
      } else if (isEqual(key, 'freightDelivery')) {
        if (updatedDetails.freightDelivery.dateTime) {
          const mDate = moment(updatedDetails.freightDelivery.dateTime + 'Z');
          previousMovement._endDate = mDate;
          previousMovement.endDate = mDate.format('YYYY-MM-DD');
          const hasTime = moment(updatedDetails.freightDelivery.dateTime, 'YYYY-MM-DD HH:mm:ss', true).isValid();
          if (hasTime)
            previousMovement.endTime = mDate.format('hh:mm A');
          if (!updatedDetails.deliverySkipShowTime)
            previousMovement.showEndTime = true;
        }
        previousMovement.deliveryBookingNumber = updatedDetails.freightDelivery.bookingNumber;
        previousMovement.deliveryInstructions = updatedDetails.freightDelivery.instructions;
      } else if (includes(fieldsToUpdate, key)) {
          previousMovement[key] = updatedDetails[key];
      }
    });
    newState.movements.splice(index, 1, previousMovement);
    newState.selectedMovement = null;
    newState.movementDialog = false;
    this.setState(newState, () => {
      this.updateOrderTonnage(currentOrder, draftUnaccountedTonnage);
      this.updateCacheForMovements();
    });
  }

  updateCache = () => {
    const cache = this.getCache();
    let userId = this.props.user?.id;
    cache[userId] =  get(cache, userId) || {};
    cache[userId]['orderStatuses'] = this.state.orderEnabledStatuses;
    cache[userId]['movementStatuses'] = this.state.movementEnabledStatuses;
    cache[userId]['scheduleView'] = this.state.selectedViewOption;
    cache[userId]['start'] = this.state.start;
    cache[userId]['end'] = this.state.end;
    cache[userId]['searchInput'] = this.state.searchInput;
    localStorage.setItem('freightScheduler', JSON.stringify(cache));
  }

  updateCacheForMovements = () => {
    const unsavedMovements = filter(this.state.movements, { status: 'unsaved' });
    const start = new Date(this.state.start);
    const end = new Date(this.state.end);
    const currentViewCondition = (movement, start, end) => {
      const startDate = new Date(movement.startDate);
      const endDate = new Date(movement.endDate);
      return startDate >= start && endDate <= end;
    };
    const cache = this.getCache();
    cache[this.getCurrentViewCacheKey()] = uniqBy(unsavedMovements.filter(movement => currentViewCondition(movement, start, end)), 'identifier');
    if (this.state.selectedViewOption?.id == 'day') {
      let start = this.getStartDate();
      let end = start.clone().add(this.viewLength, 'days')
      cache[`${start.format('YYYY-MM-DD')}-${end.format('YYYY-MM-DD')}`] = uniqBy(unsavedMovements.filter(movement => currentViewCondition(movement, start, end)), 'identifier');

      end = start.clone().add(7, 'days');
      cache[`${start.format('YYYY-MM-DD')}-${end.format('YYYY-MM-DD')}`] = uniqBy(unsavedMovements.filter(movement => currentViewCondition(movement, start, end)), 'identifier');
    }
    localStorage.setItem('freightScheduler', JSON.stringify(cache));
  }

  getCurrentViewCacheKey = () => `${this.state.start.format('YYYY-MM-DD')}-${this.state.end.format('YYYY-MM-DD')}`;

  getCache = () => JSON.parse(get(localStorage, 'freightScheduler', '{}'))

  getCachedMovements = (allView=false) => {
    const cache = this.getCache();
    if(allView)
      return [].concat(...Object.values(cache));
    const movements = cache[this.getCurrentViewCacheKey()];
    const existingMovementIds = map(this.state.movements, 'identifier');
    return reject(movements, movement => includes(existingMovementIds, movement.identifier));
  }

  onMovementClick = (event, movement) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({selectedMovement: movement, movementDialog: true});
  }

  onMovementDialogClose = () => {
    this.setState({ selectedMovement: null, movementDialog: false });
  }

  onMovementDelete = movement => {
    this.setState(
      { movements: reject(this.state.movements, { identifier: movement.identifier }) },
      () => {
        this.updateCacheForMovements();
        const order = find(this.state.allOrders, { id: movement.orderId });
        this.setDraftUnaccountedTonnage(order, order.draftUnaccountedTonnage + movement.plannedTonnage);
      }
    );
  }

  getSlotOrderBooking(movement, orderField, movementField) {
    const currentOrder = find(this.state.allOrders, { id: movement.orderId });
    return currentOrder ? currentOrder[orderField] && !get(movement, movementField) : false;
  }

  isStartTimeWithinNHours(movement, N) {
    const slot_time = movement._startDate
    const minutesFromStart = Math.abs(moment().diff(slot_time, 'minutes'));
    return minutesFromStart/60 <= N;
  }

  onMovementVoid = (event, movement) => {
    const currentOrder = find(this.state.allOrders, { id: movement.orderId });

    if (includes(['in_progress', 'delivered', 'completed'], movement.status)) {
      alertifyjs.alert("Void!", `In Progress, Delivered and Completed Movements can only be marked Void from the movement's <a href='/#/freights/movements/${movement.id}/details' target='_blank'>details page</a>.`);
    }
    else if (!(currentOrder.consignorId === this.props.user.companyId) && currentOrder.isPickupSiteSlotOrderBookingOn && this.isStartTimeWithinNHours(movement, currentOrder.pickupHoursBeforeCancellationStops)){
      alertifyjs.error(`Outload Slot cannot be cancelled within ${currentOrder.pickupHoursBeforeCancellationStops} hours of the booking time. Please contact Site Manager/Operator to cancel the booking.`, 5);
    }
    else if (!(currentOrder.consigneeId === this.props.user.companyId) && currentOrder.isDeliverySiteSlotOrderBookingOn && this.isStartTimeWithinNHours(movement, currentOrder.deliveryHoursBeforeCancellationStops)){
      alertifyjs.error(`Inload Slot cannot be cancelled within ${currentOrder.deliveryHoursBeforeCancellationStops} hours of the booking time. Please contact Site Manager/Operator to cancel the booking.`, 5);
    }
    else {
      this.props.dispatch(isLoading('freightScheduler'));
      APIService.freights().contracts(movement.id).appendToUrl('void/').post({
        requestReason: this.state.requestReason,
        feature: 'Freight Scheduler'}).then(() => {
        const newState = { ...this.state };
        const index = findIndex(this.state.movements, { id: movement.id });
        newState.movements.splice(index, 1, { ...movement, status: 'void' });
        newState.isVoidDialogOpen = false,
        newState.requestReason = '',
        newState.selectedMovement = null
        this.setState(newState, () => {
          this.props.dispatch(forceStopLoader());
          const order = find(this.state.allOrders, { id: movement.orderId });
          this.setDraftUnaccountedTonnage(order, order.draftUnaccountedTonnage + movement.plannedTonnage);
          this.updateCacheForMovements();
        });
      });
    }
  }

  onDateChange = date => {
    let focusDate = moment(date);
    this.setState(
    { focusDate: focusDate },
    () => {
      if (this.state.selectedViewOption?.id != 'day') {
        const newStart = this.getStartDate();
        const newEnd = this.getEndDate();
        if (!this.state.start.isSame(newStart) || !this.state.end.isSame(newEnd))
          this.setState({ start: newStart, end: newEnd }, () => {
            this.fetchOrders();
            this.updateCache();
          });
      } else {
        this.setState({ start: focusDate, end: this.getEndDate(focusDate) }, () => {
          this.fetchOrders();
          this.updateCache();
        });
      }
    }
  )}

  getDateRangeText = () => this.state.viewLength == 1 ? this.state.start.format(this.state.countryFormats.dateDisplay) : `${this.state.start.format('DD')} - ${this.state.end.format(this.state.countryFormats.dateDisplay)}`


  onOrderSelect = order => this.setState({ selectedOrders: includes(this.state.selectedOrders, order.id) ? [] : [order.id] })

  onSearch = () => {
    this.setState({ orders: this.state.searchOrderText ? filter(this.state.allOrders, order => order.identifier.toLowerCase().match(this.state.searchOrderText.toLowerCase())) : this.state.allOrders });
  }

  onSearchTextChange = event => this.setState({ searchOrderText: event.target.value })

  onSearchEnterKeyPress = event => {
    if (event.key === 'Enter')
      this.onSearch();
  }

  onMovementContextMenuClick = (event, movement) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ movementContextTarget: event.currentTarget, selectedMovement: movement });
  }

  onTruckContextMenuClick = (event, truck) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ truckContextTarget: event.currentTarget, selectedTruck: truck });
  }

  closeTruckContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ truckContextTarget: null, selectedTruck: null });
  }

  closeMovementContextMenu = event => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ movementContextTarget: null, selectedMovement: null });
  }

  onTruckEditClick = event => {
    event.preventDefault();
    event.stopPropagation();
    this.toggleEditTruck();
  }

  onDuplicateClick = event => {
    event.persist();
    this.addMovement(omit(this.state.selectedMovement, ['identifier', 'status']));
    this.closeMovementContextMenu(event);
  }

  onMovementViewDetailsClick = event => {
    event.persist();
    this.closeMovementContextMenu(event);

    const { selectedMovement } = this.state;
    window.open(`/#/freights/movements/${selectedMovement.id}/details`, '_blank');
  }

  onMovementDrag = (movement, date, truckId) => {
    const newState = { ...this.state };
    const index = findIndex(newState.movements, { identifier: movement.identifier });
    const prevMovementDate = movement.startDate;
    const prevTruckId = movement.plannedTruckId;
    movement.plannedTruckId = parseInt(truckId);
    movement.driverId = find(this.state.trucks, { id: parseInt(truckId) })?.assignedDriverId;
    movement.startDate = date;
    newState.movements.splice(index, 1, movement);
    newState.render += 1;
    const updatedDetails = this.updateTruckTonnage(movement, movement.plannedTruckId);
    const movementIndex = findIndex(newState.movements, { identifier: movement.identifier });
    const orderIndex = findIndex(newState.orders, { id: updatedDetails.orderdetails.id });
    let previousMovement = newState.movements.at(movementIndex);
    let previousOrder = newState.orders.at(orderIndex);
    previousMovement.plannedTonnage = updatedDetails.newTonnage;
    if(prevMovementDate !== date){
      previousMovement.outloadSlotId = '';
    }
    if(movement.endDate !== date){
      previousMovement.inloadSlotId = '';
    }
    previousOrder.draftUnaccountedTonnage = updatedDetails.draftUnaccountedTonnage;
    newState.movements.splice(movementIndex, 1, previousMovement);
    newState.orders.splice(orderIndex, 1, previousOrder);
    this.setState(newState);
    if ((movement.status === "planned" || movement.status === 'confirmed') && ! (isEqual(prevMovementDate, date) && isEqual(prevTruckId, parseInt(truckId)))) {
      let movementDateTime = movement.startDate;
      if (movement.startTime && movement.showStartTime) {
        movementDateTime = moment(movement.startDate + ' ' + movement._startDate.format('HH:mm:ss')).utc().format('YYYY-MM-DD HH:mm:ss');
      }
      const payload = {
        communication: { acceptanceRequired: false },
        freightPickup: {
          dateTime: movementDateTime,
        },
        pickupSkipShowTime: true,
        deliverySkipShowTime: true,
        plannedTruckId: movement.plannedTruckId,
        plannedTonnage: movement.plannedTonnage,
        inloadSlotId: movement.inloadSlotId,
        outloadSlotId: movement.outloadSlotId,
        driverId: movement.driverId,
        feature: 'Freight Scheduler'
      };
      this.props.dispatch(isLoading('freightScheduler'));
      this.props.dispatch(raiseMovementAmendRequest(movement.id, payload, undefined, false));
    }
    this.updateCacheForMovements();
  }

  updateTruckTonnage = (movement, truckId) => {
    const orderdetails = find(this.state.orders, { id: movement.orderId });
    const newTruckDetails = find(this.state.trucks, { id: truckId });

    let draftUnaccountedTonnage = get(orderdetails, 'draftUnaccountedTonnage');
    let newTruckCapacity = get(newTruckDetails, 'totalWeights');
    let newTonnage = newTruckCapacity?.netWeight;
    if (newTruckCapacity?.netWeight > movement.plannedTonnage) {
      let truckCapacityDifference = newTruckCapacity?.netWeight - movement.plannedTonnage;
      if (truckCapacityDifference > draftUnaccountedTonnage) {
        newTonnage = movement.plannedTonnage + draftUnaccountedTonnage;
        draftUnaccountedTonnage = 0;
      } else {
        draftUnaccountedTonnage = draftUnaccountedTonnage - truckCapacityDifference;
      }
    } else {
      let truckCapacityDifference = movement.plannedTonnage - newTruckCapacity?.netWeight;
      draftUnaccountedTonnage = draftUnaccountedTonnage + truckCapacityDifference;
    }
    return {
      newTonnage,
      draftUnaccountedTonnage,
      orderdetails
    };
  }

  onMovementUpdate = (movement, updatedDetails, currentOrder, draftUnaccountedTonnage) => {
    const newState = { ...this.state };
    const index = findIndex(newState.movements, { identifier: movement.identifier });
    let previousMovement = newState.movements.at(index);
    forOwn(updatedDetails, (value, key) => {
      if (isEqual(key, 'plannedTonnage')) {
        previousMovement.plannedTonnage = value;
      } else if (isEqual(key, 'plannedTruckId')) {
        previousMovement.plannedTruckId = value;
      } else if (isEqual(key, 'freightPickup')) {
        if (updatedDetails.freightPickup.dateTime) {
          const mDate = moment(updatedDetails.freightPickup.dateTime + 'Z');
          previousMovement._startDate = mDate;
          previousMovement.startDate = mDate.format('YYYY-MM-DD');
          previousMovement.startTime = mDate.format('hh:mm A');
          if (!updatedDetails.pickupSkipShowTime)
            previousMovement.showStartTime = true;
        }
        if(updatedDetails.freightPickup.bookingNumber) {
          previousMovement.pickupBookingNumber = updatedDetails.freightPickup.bookingNumber;
        }
      } else if (isEqual(key, 'freightDelivery')) {
        if (updatedDetails.freightDelivery.dateTime) {
          const mDate = moment(updatedDetails.freightDelivery.dateTime + 'Z');
          previousMovement._endDate = mDate;
          previousMovement.endDate = mDate.format('YYYY-MM-DD');
          previousMovement.endTime = mDate.format('hh:mm A');

          if (!updatedDetails.deliverySkipShowTime)
            previousMovement.showEndTime = true;
        }
        if(updatedDetails.freightDelivery.bookingNumber) {
          previousMovement.deliveryBookingNumber = updatedDetails.freightDelivery.bookingNumber;
        }
      }
    });
    newState.movements.splice(index, 1, previousMovement);
    newState.selectedMovement = null;
    newState.movementDialog = false;
    this.setState(newState, () => {
      this.updateOrderTonnage(currentOrder, draftUnaccountedTonnage);
    });
  }

  onExecute = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.dispatch(isLoading('freightScheduler'));

    const movements = map(filter(this.state.movements, { status: 'unsaved' }), movement => ({
      identifier: movement.identifier,
      orderId: movement.orderId,
      plannedTruckId: movement.plannedTruckId,
      createdAt: movement.createdAt,
      startDate: movement.startDate,
      plannedTonnage: movement.plannedTonnage,
      driverId: movement.driverId,
      providerId: find(this.state.trucks, { id: movement.plannedTruckId })?.companyId,
      outloadSlotId: movement.outloadSlotId,
      outloadSlotUpdatedAt: movement.outloadSlotUpdatedAt,
      inloadSlotId: movement.inloadSlotId,
      inloadSlotUpdatedAt: movement.inloadSlotUpdatedAt,
      order: find(this.state.allOrders, { id: movement.orderId }),
    }));

    const [validDrafts, invalidDrafts] = this.getValidInvalidDrafts(movements);
    const newState = {...this.state};
    newState.invalidDrafts = invalidDrafts
    if(isEmpty(validDrafts)){
      this.props.dispatch(forceStopLoader());
      newState.executionDialog = true;
      this.setState(newState);
      return;
    }
    else{
      this.setState(newState, ()=> this.executeMovements(validDrafts))
    }

  }

  showExecutionReport(invalidDrafts) {
    if(!isEmpty(invalidDrafts)){
      const movements = invalidDrafts.map(item => `${item.rego} | ${item.date} | ${item.identifier} | ${item.orderNumber}`);
      const reasons = '<li>' + movements.join('</li><li>');
      alertifyjs.alert(
        'Permission Denied',
        `<div id="complete-dialog-open" className=""><p>The following slots cannot be executed as order booking is on for one or both the sites of the order.</p><ul>${reasons}</ul><p>Please enter all the required details in the slots by opening each of them and then execute.</p></div>`,
        () => {},
      );
    }
  }

  handleDrafts = (items, reason, invalidDrafts) => {
    items.forEach((item) => {
      const existingDraft = this.findDraftByIdentifier(invalidDrafts, item.identifier);
      if (existingDraft) {
        this.updateDraftWithReason(existingDraft, reason);
      } else {
        invalidDrafts.push(this.createDraft(item, reason));
      }
    });
  };

  createDraft = (item, reason) => ({
    rego: find(this.state.trucks, { id: item.plannedTruckId }).rego,
    date: item.startDate,
    identifier: item.identifier,
    orderNumber: item.orderNumber || item.order?.identifier,
    reasons: [reason],
  });

  updateDraftWithReason = (draft, reason) => {
    draft.reasons.push(reason);
  };

  findDraftByIdentifier = (drafts, identifier) =>
      drafts.find((draft) => draft.identifier === identifier);

  getValidInvalidDrafts(movements) {
    const invalidInloadSlotValues = movements.filter(movement => movement.order?.isDeliverySiteSlotOrderBookingOn && !movement.inloadSlotId) || [];
    const invalidOutloadSlotValues = movements.filter(movement => movement.order?.isPickupSiteSlotOrderBookingOn && !movement.outloadSlotId) || [];

    const invalidTonnageValues = movements.filter(movement => !movement.plannedTonnage) || []

    const invalidDriverValues = movements.filter(movement => ((movement.order?.isDeliverySiteSlotOrderBookingOn || movement.order?.isPickupSiteSlotOrderBookingOn)
      && !movement.driverId)) || []

    let conflictingMovements = [];
    const findConflictingMovements = () => {
      const slotIdMap = new Map();

      movements.forEach(movement => {
        const { inloadSlotId, outloadSlotId, identifier } = movement;
        if (inloadSlotId) {
          if (slotIdMap.has(inloadSlotId)) {
            const conflictingMovementIdentifier = slotIdMap.get(inloadSlotId);
            const conflictingMovement = find(movements, { identifier: conflictingMovementIdentifier })
            conflictingMovements.push(
              movement,
              conflictingMovement
            );
          } else
            slotIdMap.set(inloadSlotId, identifier);
        }
        if (outloadSlotId) {
          if (slotIdMap.has(outloadSlotId)) {
            const conflictingMovementIdentifier = slotIdMap.get(outloadSlotId);
            const conflictingMovement = find(movements, { identifier: conflictingMovementIdentifier })
            conflictingMovements.push(
              movement,
              conflictingMovement
            );
          } else
            slotIdMap.set(outloadSlotId, identifier);
        }
      });
    };

    findConflictingMovements();
    conflictingMovements = uniqBy(conflictingMovements, 'identifier');

    const invalidDrafts = [];

    this.handleDrafts(conflictingMovements, "Cannot select the same slot in multiple movements. Please choose different slots across different tiles.", invalidDrafts);
    this.handleDrafts(invalidDriverValues, "Driver is required. Please click on the tile and select a driver.", invalidDrafts);
    this.handleDrafts(invalidTonnageValues, "Tonnage cannot be 0", invalidDrafts);
    this.handleDrafts(invalidInloadSlotValues, "Slot needs to be selected for Inload site. Please click on the tile and select a slot.", invalidDrafts);
    this.handleDrafts(invalidOutloadSlotValues, "Slot needs to be selected for Outload site. Please click on the tile and select a slot.", invalidDrafts);

    const invalidIds = invalidDrafts.map(item => item.identifier);

    let validDrafts = movements.filter(movement => !includes(invalidIds, movement.identifier));
    validDrafts = validDrafts.map(item => omit(item, 'order'));
    return [validDrafts, invalidDrafts];
  }

  executeMovements = (movements, order, draftUnaccountedTonnage, isDialogAction=false) => {
    APIService.freights().appendToUrl('scheduler/movements/').post(movements).then(response => {
      const confirmedMovements = response.filter(item => (item.status === 'confirmed' && !item.errors));
      const unconfirmedMovements = response.filter(item => (item.errors));
      const unconfirmedMovementsIdentifiers = unconfirmedMovements.map(item => item.identifier);
      const newState = { ...this.state };
      newState.executedMovementCount = confirmedMovements.length;
      let unconfirmedDrafts = movements.filter(movement => includes(unconfirmedMovementsIdentifiers, movement.identifier))
      unconfirmedDrafts = unconfirmedDrafts.map(draft => ({
        ...draft,
        orderNumber: find(this.state.allOrders, {id: draft.orderId})?.identifier,
      }));
      this.handleDrafts(unconfirmedDrafts, "Selected Slot is not available to book. Please re-select the slot.", newState.invalidDrafts);
      newState.executionDialog = true;
      forEach(confirmedMovements, item => {
        const confirmMovement = find(movements, { identifier: item.identifier })
        let movement = find(newState.movements, { identifier: item.identifier });
        movement['inloadSlotId'] = confirmMovement.inloadSlotId
        movement['outloadSlotId'] = confirmMovement.outloadSlotId
        const index = findIndex(newState.movements, { identifier: item.identifier });
        newState.movements.splice(index, 1, { ...movement, status: 'confirmed', id: item.id });
      });
      this.setState(newState, () => {
        this.props.dispatch(forceStopLoader());
        this.updateCacheForMovements();
        if(isDialogAction) {
          this.onMovementUpdate(movements[0], movements[0], order, draftUnaccountedTonnage);
        }
      });
    });
  }

  getLoadingText = () => {
    const { isLoadingOrders, isLoadingTrucks, isLoadingMovements } = this.state;
    if (isLoadingTrucks)
      return 'Loading Trucks...';
    if (isLoadingOrders)
      return 'Loading Orders...';
    if (isLoadingMovements)
      return 'Loading Movements...';
  }

  onTruckUpdate = data => {
    const { selectedTruck } = this.state;
    APIService.trucks(selectedTruck.id).put(data).then(res => {
      const newState = { ...this.state };
      const index = findIndex(newState.trucks, { id: selectedTruck.id });
      newState.trucks.splice(index, 1, res);
      newState.truckContextTarget = null;
      newState.selectedTruck = null;
      this.setState(newState);
    });
  }

  onViewRangeChange(event, view) {
    event.preventDefault();
    let focusDate = '';
    let viewLength = this.state.selectedViewOption?.id == 'day' ? 1 : 7;
    if(isEqual(view, 'prev'))
      focusDate = moment(this.state.start).subtract(viewLength,'d').format('YYYY-MM-DD');
    else if(isEqual(view, 'next'))
      focusDate = moment(this.state.start).add(viewLength,'d').format('YYYY-MM-DD');
    this.onDateChange(focusDate);
  }

  updateOrderTonnage = (order, updatedDraftTonnage) => {
    const newState = { ...this.state };
    const index = findIndex(newState.orders, { identifier: order?.identifier });
    order.draftUnaccountedTonnage = updatedDraftTonnage;
    newState.orders.splice(index, 1, order);
    this.setState(newState);
  }

  toggleManageTruck = () => {
    const newState = { ...this.state };
    let optionState = isEqual(get(newState.manageTrucks, 'id'), 'addTrucks') ? 'addingTruck' : 'removingTrucks';
    newState[optionState] = !newState[optionState];
    this.setState(newState)
  }

  onAddTruck = selectedTrucks => {
    this.toggleManageTruck();
    const existingTruckIds = map(this.state.trucks, 'id');
    forEach(selectedTrucks, truck => {
      if (!includes(existingTruckIds, truck.id)) {
        APIService
          .companies(this.props.user.companyId)
          .appendToUrl('managed-trucks/')
          .post({ truckId: truck.id })
          .then(res => {
            const newTruck = { ...res, managed: res.companyId !== this.props.user.companyId };
            const newState = { ...this.state };
            newState.trucks = [...this.state.trucks, newTruck];
            newState.movementTrucks = uniqBy([
              ...this.state.movementTrucks, ...map(newState.trucks, truck => ({ id: truck.id, name: truck.rego, isActive: truck?.isActive}))
            ], 'id');
            this.setState(newState, this.fetchMovementsForTruck(newTruck.id));
          });
      }
    });
  }

  onRemoveTruck = selectedTrucks => {
    let truckIds = map(selectedTrucks, 'id');
    this.toggleManageTruck();
    let deletePromises = map(truckIds, truckId =>
      APIService
      .companies(this.props.user?.companyId)
      .appendToUrl(`managed-trucks/${truckId}/`)
      .delete()
    );
    Promise.all(deletePromises).then(() => {
      this.setState({
        trucks: filter(this.state.trucks, truck => !includes(truckIds, truck.id)),
        movementTrucks: uniqBy([
          ...filter(this.state.movementTrucks, truck => !includes(truckIds, truck.id)), ...filter(this.state.trucks, truck => !includes(truckIds, truck.id))
        ], 'id')
      });
    });
  }

  onTruckToggle = (truck, collapsed) => this.setState({
    collapsedTrucks: collapsed ?
                     [...this.state.collapsedTrucks, truck.id] :
                     without(this.state.collapsedTrucks, truck.id)
  })

  filterMovements = () => {
    let movements = filter(this.state.movements, movement => includes(this.state.movementEnabledStatuses, camelCase(movement.status)));
    if (includes(this.state.movementEnabledStatuses, 'invoiced'))
      movements = movements.concat(filter(this.state.movements, movement => movement?.isFreightInvoiced ));
    return uniqBy(movements, 'identifier');
  }

  showActionsMenu = () => this.setState(prevState => ({ showActions: !prevState.showActions }))

  closeActionsMenu = () => this.setState({ showActions: false, changeViewButton: false, showManageTruckOptions: false })

  afterSelect() {
    const newState = { ...this.state };
    newState.viewLength = this.state.selectedViewOption.viewLength;
    newState.start = this.state.selectedViewOption?.id == 'day' ? moment(new Date()) : this.getStartDate();
    newState.end = this.getEndDate(newState.start)
    this.setState(newState, () => {
      this.fetchOrders();
      this.updateCache();
    });
    this.closeActionsMenu();
  }

  handleViewSelect = option => this.setState({selectedViewOption: option, changeViewButton: false}, this.afterSelect);

  filterOrders = () => filter(this.state.orders, order => includes(this.state.orderEnabledStatuses, camelCase(order.status)))

  filterOrdersBySearch = value => {
    let orders = this.filterOrders();
    const keys = ['identifier', 'contractIdentifier', 'commodity', 'plannedGrade', 'customer', 'providerName', 'consignorName', 'consigneeName'];
    if (value && value.length >= MIN_CHAR_FOR_SEARCH ) {
      const re = new RegExp(value, 'i');
      let searchedResults = filter(orders, order => some(keys, key => re.test(get(order, key))));
      this.setState({ filteredOrders: searchedResults });
    } else
      this.setState({ filteredOrders: orders });
  }

  handleOrdersSearch = debounce(value => {
    this.filterOrdersBySearch(value)
    let markInstance = new Mark(document.querySelectorAll(".searchable"));
    const options = {
      element: "span",
      className: "highlight-search-results",
      separateWordSearch: false,
    }

    if (value && value.length >= MIN_CHAR_FOR_SEARCH)
      markInstance.unmark({done: () => markInstance.mark(value, options)});
    else
      markInstance.unmark(options);

  }, 200);

  handleInputChange = event => {
    const { value } = event.target;
    this.handleOrdersSearch(value.trim());
    this.setState({searchInput: value}, this.updateCache)
  };

  handleOrderStatusChange = statuses => {
    this.setState(
      {orderEnabledStatuses: map(statuses, status => camelCase(status))},
      () => {
        this.handleOrdersSearch(this.state.searchInput)
        this.updateCache();
      }
    )
  }

  handleMovementStatusChange = statuses => {
    this.setState({movementEnabledStatuses: map(statuses, status => camelCase(status))}, this.updateCache)
  }

  handleManageTruckSelect = option => {
    const newState = { ...this.state };
    newState.manageTrucks = option;
    newState.showManageTruckOptions = false;
    newState.showActions = false;
    newState.addingTruck = isEqual(option.id, 'addTrucks');
    newState.removingTrucks = isEqual(option.id, 'removeTrucks');
    this.setState(newState);
  };

  handleActionsClick = action => {
    this.setState({ changeViewButton: false})
    switch(action.id) {
      case 'scheduleView':
        this.setState({changeViewButton: !this.state.changeViewButton})
        break;
      case 'manageTrucks':
        this.setState({showManageTruckOptions: !this.state.showManageTruckOptions})
        break;
      default:
          return;
    }
  }

  handleCloseVoidDialog = event => {
    event.preventDefault()
    event.stopPropagation()
    this.setState({ isVoidDialogOpen: false, requestReason: '', selectedMovement: null })
  }

  render() {
    const { isMobileDevice } = this.context
    const {
      start, viewLength, trucks, movements, orders, selectedOrders, allOrders, end,
      movementContextTarget, selectedMovement, movementEnabledStatuses, selectedTruck,
      editTruck, truckContextTarget, addingTruck, orderEnabledStatuses, selectedViewOption, filteredOrders, removingTrucks
    } = this.state;
    const filteredMovements = this.filterMovements();
    const sortedOrders = orderBy(filteredOrders, order => new Date(order.endDate))
    const isFullWeek = viewLength === 7;
    const dateContentSectionWidth = `${100 / viewLength}%`;
    const dateHeadingSectionWidth = `${90 / viewLength}%`;
    const unsavedMovements = filter(movements, { status: 'unsaved' });
    const noUnsavedMovements = unsavedMovements.length === 0;
    const numberOfOrders = allOrders.length != 0 ? (allOrders.length == filteredOrders.length ? `(${allOrders.length})`:`(${filteredOrders.length}/${allOrders.length})`) : '';
    const ordersLabel = `Orders ${numberOfOrders}`
    const loadingText = this.getLoadingText();
    const formattedStart = start.format('YYYY-MM-DD');
    const formattedEnd = end.format('YYYY-MM-DD');
    const currentViewMovements = filter(movements, movement => (movement?.startDate >= formattedStart && movement?.startDate < formattedEnd) || (movement?.endDate > formattedStart && movement?.endDate < formattedEnd) || (movement?.startDate <= formattedStart && movement?.endDate > formattedEnd))
    return (
      <React.Fragment>
        <div className='col-sm-12 col-xs-12 freight-scheduler'>
          <div className='col-sm-12 col-xs-12 section controls'>
          <TextField
            id="search"
            placeholder={this.state.placeholderText}
            value={this.state.searchInput}
            variant='outlined'
            color='default'
            style={{ maxWidth: "330px", paddingRight: '-10px' }}
            className='control pull-left'
            onChange={this.handleInputChange}
            onBlur={() => this.setState({ placeholderText: 'Search for Orders' })}
            onFocus={() => this.setState({ placeholderText: 'Minimum 3 characters' })}
            helperText={
              <span style={{marginLeft: '-10px'}}>
                Search Tip
                <CommonToolTip title='Search by commodity, grade, order, contract, provider, customer and sites' placement='right'>
                  <InfoIcon fontSize='small' style={{fontSize: '1rem', marginLeft: '2px', color: 'rgba(0, 0, 0, 0.5)', marginBottom: '-4px'}} />
                </CommonToolTip>
              </span>
            }
            maxLength="20"
            InputProps={{
              autoComplete: "off",
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
              style: {height: '40px', paddingRight: "5px"}
            }}
            />
            {
              !isEmpty(orders) &&
                <span className='control pull-left' style={{ margin: '3px 1px 0 5px' }}>
                  <StatusFilter label='Order Status' items={orders} onChange={statuses => this.handleOrderStatusChange(statuses)} includeStatuses={this.orderStatuses} enabledStatuses={orderEnabledStatuses}/>
                </span>
            }
            {
              !isEmpty(currentViewMovements) &&
                <span className='control pull-left' style={{ margin: '3px 1px 0 0' }}>
                  <StatusFilter label='Movement Status' items={currentViewMovements} onChange={statuses => this.handleMovementStatusChange(statuses)} includeStatuses={this.movementStatuses} enabledStatuses={movementEnabledStatuses}/>
                </span>
            }
            <span className='control pull-left' style={{ margin: '3px 1px 0 0' }}>
              <CalendarDateSelector
                defaultDate={start.format('YYYY-MM-DD')}
                rangeText={this.getDateRangeText()}
                onChange={this.onDateChange}
              />
              <IconButton
                variant="outlined"
                color="secondary"
                style={{margin: '0 1px', padding: '3px', border: `1px solid ${ACCENT_COLOR_BLUE}`}}
                onClick={(event) => this.onViewRangeChange(event, 'prev') }
                size="large">
                <KeyboardArrowLeft />
              </IconButton>
              <IconButton
                variant="outlined"
                color="secondary"
                style={{margin: '0 1px', padding: '3px', border: `1px solid ${ACCENT_COLOR_BLUE}`}}
                onClick={(event) => this.onViewRangeChange(event, 'next') }
                size="large">
                <KeyboardArrowRight />
              </IconButton>
            </span>
            <span className='control pull-right' style={{ margin: '3px 1px 0 0' }}>
              <Chip
                color='primary'
                variant='outlined'
                clickable
                label="Actions"
                onClick={this.showActionsMenu}
                ref={this.chipRef}
                icon={<MoreVertIcon fontSize='small' style={{order:2, margin: '0 4px 0 -12px'}}/>}
              />
              {this.state.showActions &&
                <Menu
                  anchorEl={this.chipRef.current}
                  open={this.state.showActions}
                  onClose={this.closeActionsMenu}
                  style={{ width: '260px', maxHeight: '100%'}}
                >
                  {map(ACTIONS, (action, index) => (
                    <div key={index}>
                      {index !== 0 && <Divider style={{margin: '0px'}}/>}
                      <MenuItem
                        style={{fontSize: '14px', padding: '12px 15px 0px',flexDirection: 'column'}}
                        key={action.id}
                        onClick={ () => this.handleActionsClick(action) }
                        >
                        <div style={{width: '200px', display: 'flex', alignItems: 'center', paddingBottom:'4px'}}>
                          <div style={{ marginLeft: '1px', width: '88%', }}>{action.name}</div>
                          {
                            action.toggle &&
                            <span style={{ display: 'inline-block', width: '12%' }}>
                              {this.state.changeViewButton ? <ExpandLess /> : <ExpandMore />}
                            </span>
                          }
                        </div>
                        {this.state.changeViewButton && isEqual(action.id, 'scheduleView') &&
                          VIEW_OPTIONS.map((option, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'column', lineHeight:'5px' }}>
                              <Divider style={{ width: '230px' }}/>
                              {
                                <MenuItem style={{ fontSize: '14px', padding: '12px 15px' }} key={option.id} onClick={() => this.handleViewSelect(option) }>
                                  <div style={{width: '200px', display: 'flex', alignItems: 'center'}}>
                                    <div style={{ marginLeft: '1px',width: '88%', padding: '2px'}}>{option.name}</div>
                                    {includes(get(selectedViewOption, 'id'), option.id) &&
                                      <span style={{ color: 'rgba(0, 0, 0, 0.4)', margin: '-2px 0 -2px 25px' }}>
                                        <Done />
                                      </span>
                                    }
                                  </div>
                                </MenuItem>
                              }
                            </div>
                          ))}
                          {this.state.showManageTruckOptions && isEqual(action.id, 'manageTrucks') &&
                            MANAGE_TRUCK_OPTIONS.map((option, index) => (
                            <div key={index} style={{ display: 'flex', flexDirection: 'column', lineHeight:'5px' }}>
                              <Divider style={{ width: '230px' }}/>
                              <MenuItem
                                style={{ fontSize: '14px', padding: '12px 15px' }}
                                key={option.id}
                                onClick={() => this.handleManageTruckSelect(option)}
                              >
                                <div style={{width: '200px', display: 'flex', alignItems: 'center'}}>{option.name}</div>
                              </MenuItem>
                            </div>
                          ))}
                      </MenuItem>
                    </div>
                  ))}
                </Menu>
              }
            </span>
            {
              loadingText &&
                <span className='control pull-left' style={{ margin: '3px 1px 0 0' }}>
                <Chip
                  disabled
                  label={loadingText}
                />
              </span>
            }
            <span className='control pull-right' style={{margin: '3px 5px 0px 0px'}}>
              <Chip color='primary' variant='contained' disabled={noUnsavedMovements} onClick={this.onExecute} label={`Execute ${unsavedMovements?.length ? unsavedMovements.length : ''}`} />
            </span>
          </div>
          <div className='col-sm-12 col-xs-12 no-side-padding workspace' style={{marginTop: '23px'}}>
            <div className='col-sm-2 col-xs-2 section left-section'>
              <div className='col-xs-12 orders-label' style={{ paddingLeft: '5px', paddingTop: '5px', position: 'absolute' }}>
                {ordersLabel}
              </div>
              <div className='col-xs-12' style={{ padding: '5px 2px', fontSize: '14px', top: '30px', overflow: 'auto', height: '78vh' }}>
                {
                  map(sortedOrders, order => {
                    const isSelected = includes(selectedOrders, order.id);
                    return (
                      <span className={`orderId${order.id}`}>
                      <OrderTemplate
                        order={order}
                        key={order.identifier}
                        isSelected={isSelected}
                        onSelect={this.onOrderSelect}
                        actualRemainingTonnage={this.getActualRemainingTonnage(order)}
                        fadeOut={!isSelected && selectedOrders.length > 0}
                      />
                      </span>
                    );
                  })
                }
              </div>
            </div>
            <div className='col-sm-10 col-xs-10 section right-section'>
              <div className='col-sm-12 col-xs-12 no-side-padding'>
                <div className='col-sm-12 col-xs-12 section dates' style={{display: 'flex', alignItems: 'center'}}>
                  <div className='truck-section' style={{ width: isFullWeek ? '8%' : '10%' }}>
                  </div>
                  {
                    times(viewLength, day => {
                      const current = start.clone().add(day, 'days');
                      const dayName = this.getDayName(current);
                      const dayOfMonth = this.getDayOfMonth(current);
                      const isCurrent = this.isCurrentDate(current);
                      let classes = 'date-label';
                      if (isCurrent)
                        classes += ' selected';
                      return (
                        <div className='date-section' key={day} style={{ width: dateHeadingSectionWidth }}>
                          <span className={classes}>
                            <span className='month-day'>{dayOfMonth}</span>
                            <span className='week-day'>{dayName}</span>
                          </span>
                        </div>
                      );
                    })
                  }
                </div>
                <div className='col-sm-12 col-xs-12 section content'>
                  <div className='col-sm-12 col-xs-12 content-container'>
                    {
                      map(compact(trucks), (truck, index) => {
                        const isCollapsed = includes(this.state.collapsedTrucks, truck.id);
                        const truckMovements = filter(filteredMovements, m => m.plannedTruckId.toString() === truck.id.toString());
                        let truckRowClasses = 'col-sm-12 col-xs-12 truck-row';
                        if (isCollapsed)
                          truckRowClasses += ' collapsed';
                        return (
                          <React.Fragment key={truck.id}>
                            <div className={truckRowClasses} data-index={index} data-truck={truck.id}>
                              <div className='truck-section' style={{ width: isFullWeek ? '8%' : '10%' }}>
                                <TruckTemplate isMobileDevice={isMobileDevice} onCollapse={this.onTruckToggle} truck={truck} onContextMenu={event => this.onTruckContextMenuClick(event, truck)} />
                              </div>
                              <div className='movements-section' data-truck={truck.id} style={isCollapsed ? { pointerEvents: 'none' } : {}}>
                                {
                                  times(viewLength, day => {
                                    const mCurrentDate = start.clone().add(day, 'days');
                                    const currentDate = mCurrentDate.format('YYYY-MM-DD');
                                    const currentDateMovements = orderBy(filter(truckMovements, movement => movement.startDate && movement.startDate !== 'Invalid date' ? movement.startDate === currentDate : movement.endDate === currentDate), 'createdAt');
                                    return (
                                      <div className='date-section data-container' data-date={currentDate} data-truck={truck.id} data-index={day} key={day} onClick={event => this.onDateSectionClick(event, truck, mCurrentDate)} style={{ width: dateContentSectionWidth }}>
                                        {
                                          map(currentDateMovements, (movement, index) => {
                                            const isHighlighted = includes(selectedOrders, movement.orderId);
                                            return (
                                              <div key={index}>
                                                {
                                                isMobileDevice ?
                                                  <div
                                                       className='col-sm-12 col-xs-12 no-side-padding'
                                                       onClick={event => this.onMovementClick(event, movement)}
                                                       onContextMenu={event => this.onMovementContextMenuClick(event, movement)}
                                                  >
                                                    <MovementTemplate
                                                      onClick={event => this.onMovementClick(event, movement)}
                                                      movement={movement}
                                                      isDraggable={includes(this.state.draggableStatuses, movement.status)}
                                                      onDelete={() => {
                                                        this.onMovementDelete(movement)
                                                      }}
                                                      onDrag={this.onMovementDrag}
                                                      onVoid={event => {
                                                        event.persist()
                                                        this.setState({isVoidDialogOpen: true, selectedMovement: movement})
                                                      }}
                                                      fadeOut={!isHighlighted && selectedOrders.length > 0}
                                                      orders={this.state.orders}
                                                      isPickupSiteSlotRequired={this.getSlotOrderBooking(movement, 'isPickupSiteSlotOrderBookingOn', 'outloadSlotId')}
                                                      isDeliverySiteSlotRequired={this.getSlotOrderBooking(movement, 'isDeliverySiteSlotOrderBookingOn', 'inloadSlotId')}
                                                      movementEnabledStatuses={movementEnabledStatuses}
                                                    />
                                                  </div> :
                                                  <CommonToolTip
                                                    arrow
                                                    height='290px'
                                                    placement="left"
                                                    title={
                                                      <TooltipTemplate
                                                        type='movement'
                                                        movement={movement}
                                                        orders={this.state.orders}
                                                      />
                                                    }
                                                  >
                                                    <div
                                                       className='col-sm-12 col-xs-12 no-side-padding'
                                                       onClick={event => this.onMovementClick(event, movement)}
                                                       onContextMenu={event => this.onMovementContextMenuClick(event, movement)}
                                                       style={ this.state.selectedViewOption?.id === 'day' ? { maxWidth: '245px', marginRight: '5px'} : {}}
                                                  >
                                                    <MovementTemplate
                                                      movement={movement}
                                                      isDraggable={includes(this.state.draggableStatuses, movement.status)}
                                                      onDelete={() => {
                                                        this.onMovementDelete(movement)
                                                      }}
                                                      onDrag={this.onMovementDrag}
                                                      onVoid={event => {
                                                        event?.persist()
                                                        this.setState({isVoidDialogOpen: true, selectedMovement: movement})
                                                      }}
                                                      fadeOut={!isHighlighted && selectedOrders.length > 0}
                                                      orders={this.state.orders}
                                                      isPickupSiteSlotRequired={this.getSlotOrderBooking(movement, 'isPickupSiteSlotOrderBookingOn', 'outloadSlotId')}
                                                      isDeliverySiteSlotRequired={this.getSlotOrderBooking(movement, 'isDeliverySiteSlotOrderBookingOn', 'inloadSlotId')}
                                                      movementEnabledStatuses={movementEnabledStatuses}
                                                    />
                                                  </div>
                                                  </CommonToolTip>
                                                }
                                              </div>
                                            );
                                          })
                                        }
                                      </div>
                                    );
                                  })
                                }
                              </div>
                            </div>
                            <Divider style={{ width: '100%' }} />
                          </React.Fragment>
                        );
                      })
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          movementContextTarget &&
          <Menu
            id="movement-right-click-menu"
            anchorEl={movementContextTarget}
            open={Boolean(movementContextTarget)}
            onClose={this.closeMovementContextMenu}>
            <MenuItem onClick={this.onDuplicateClick}>Duplicate</MenuItem>
            {
              selectedMovement.status !== 'unsaved' &&
              <MenuItem onClick={this.onMovementViewDetailsClick}>View Details</MenuItem>
            }
          </Menu>
        }
        {
          truckContextTarget &&
          <Menu
            id="truck-right-click-menu"
            anchorEl={truckContextTarget}
            open={Boolean(truckContextTarget)}
            onClose={this.closeTruckContextMenu}>
            <MenuItem onClick={this.onTruckEditClick}>Edit</MenuItem>
          </Menu>
        }
        {
          selectedTruck &&
          <SideDrawer
            isOpen={editTruck}
            title="Edit Truck"
            size="big"
            onClose={this.toggleEditTruck}
            app="truck"
            >
            <UpdateTruck canAccessAny onSubmit={this.onTruckUpdate} truck={selectedTruck} companyId={selectedTruck.companyId} />
          </SideDrawer>
        }
        {
          (addingTruck || removingTrucks) &&
          <ManageTruck
            open
            onClose={this.toggleManageTruck}
            onClick={addingTruck ? this.onAddTruck : this.onRemoveTruck}
            isAddTruck={addingTruck}
            managedTrucks={filter(this.state.trucks, truck => truck?.companyId != this.props.user?.companyId)}
            unsavedMovements={unsavedMovements}
          />
        }

        {
          this.state.movementDialog &&
          <MovementDetailsDialog
            updateTruckTonnage={this.updateTruckTonnage}
            allTrucks={this.state.trucks}
            onUpdate={this.onMovementUpdate}
            saveForLater={this.saveForLater}
            executeMovements={this.executeMovements}
            currentTruck={find(this.state.trucks, { id: this.state.selectedMovement.plannedTruckId })}
            orders={this.state.orders}
            allOrders={this.state.allOrders}
            movement={this.state.selectedMovement}
            movementTrucks={this.state.movementTrucks}
            handleClosePopUp={this.onMovementDialogClose}
            movementEnabledStatuses={movementEnabledStatuses}
          />
        }
        <RejectionReasonDialog
          open={this.state.isVoidDialogOpen}
          onClose={this.handleCloseVoidDialog}
          title={`Void Freight Movement: ${this.state.selectedMovement?.identifier}`}
          value={this.state.requestReason}
          onChange={(event) => {
            event.preventDefault()
            event.stopPropagation()
            this.setState({requestReason: event.target.value})}
          }
          onCancel={this.handleCloseVoidDialog}
          onReject={event => {
            event?.persist()
            this.onMovementVoid(event, this.state.selectedMovement)
          }}
          placeholder="Enter you reason for void request"
          submitText="Submit"
          required
        />
        {this.state.executionDialog &&
            <Dialog
              open={this.state.executionDialog}
              onClose={() => this.setState({executionDialog: false, executedMovementCount: 0, invalidDrafts: []})}
              aria-labelledby="form-dialog-title"
              maxWidth="xl"
            >
            <DialogTitle id="form-dialog-title">
              Execution Report
            </DialogTitle>
            <DialogContent>
              <Typography>
                {this.state.executedMovementCount} {this.state.executedMovementCount <= 1 ? "Movement" : "Movements"} created.
              </Typography>

              {!isEmpty(this.state.invalidDrafts) &&
                <React.Fragment>
                  <Typography>
                    The following movements could not be created due to the reasons mentioned.
                  </Typography>
                  <Table style={{ width: '1000px' }}>
                    <TableHead>
                      <TableRow>
                        <TableCell className="small">Rego</TableCell>
                        <TableCell className="small">Date</TableCell>
                        <TableCell className="large">Movement</TableCell>
                        <TableCell className="large">Order</TableCell>
                        <TableCell className="larger">Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        Object.entries(this.state.invalidDrafts).map(([index, invalidDraft]) => {
                          const { rego, date, identifier, orderNumber, reasons } = invalidDraft;
                          const errors = reasons.map(reason => <li key={reason}>{reason}</li>);
                          return (
                            <TableRow key={index}>
                              <TableCell className="small">{rego}</TableCell>
                              <TableCell className="small">{date}</TableCell>
                              <TableCell className="large">{identifier}</TableCell>
                              <TableCell className="large">{orderNumber}</TableCell>
                              <TableCell className="large" style={{ width: '50%' }}><ul>{errors}</ul></TableCell>
                            </TableRow>
                          )
                        })
                      }
                    </TableBody>
                  </Table>
                </React.Fragment>}
            </DialogContent>
            <DialogActions>
              <CommonButton onClick={() => this.setState({executionDialog: false, executedMovementCount: 0, invalidDrafts: []})} label='OK' primary variant="contained" />
            </DialogActions>
          </Dialog>
          }

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.main.user.user,
  };
};


export default connect(mapStateToProps)(FreightScheduler);

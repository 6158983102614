import React, { useEffect, useState } from 'react';
import alertifyjs from "alertifyjs";
import { Button, InputAdornment, TextField } from '@mui/material';
import CommonDatePicker from '../common/CommonDatePicker';
import { get, filter, some, isEmpty, forEach, find, map, isEqual, has, sortBy } from 'lodash';
import APIService from '../../services/APIService';
import { useDispatch } from 'react-redux';
import { getCountryDisplayUnit, getDateTimeInUTC, getDateTimeFromUTC, currentUserCompany, generateIdentifier, downloadFileFromBlob, isCurrentUserBelongsToCompany, getCountryLabel, isCompanyGrower, getCountryCurrency } from "../../common/utils";
import moment from 'moment';
import CommonTimePicker from '../common/CommonTimePicker';
import CommonTextField from '../common/CommonTextField';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import VarietyAutoComplete from '../common/autocomplete/VarietyAutoComplete';
import { forceStopLoader, isLoading } from '../../actions/main';
import { COMMODITIES, FIELD } from '../../common/constants';
import { MESSAGES, required, valueBetween } from '../../common/validators';
import CustomEmailDialog from '../common/CustomEmailDialog';
import CommonListingButton from '../common/CommonListingButton';
import SpecParametersValue from '../common/SpecParametersValue';
import { mapValues, orderBy, includes, set, compact } from 'lodash';

const StockSwapDialog = props => {
  const displayUnit = getCountryDisplayUnit()
  const tonnageLabel = getCountryLabel('tonnage')
  const [stockOwner, setStockOwner] = React.useState({})
    let [combinedStates, setCombinedStates] = useState({
      commodity_id: props.item?.commodityId || props.item?.stocks?.commodityId,
      grade_id: props.item?.gradeId || props.item?.stocks?.gradeId || props.item?.stocks?.grade?.id,
      variety_id: props.item?.varietyId || props.item?.stocks?.varietyId || props.item?.stocks?.variety?.id,
      season: props.item?.season || props.item?.stocks?.season,
      current_site_id: props.item?.farmId || props.item?.id,
      swap_site_id: '',
      estimated_net_weight: '',
      freight_differential: null,
      quality_differential: null,
      date: '',
      time: '',
      ngr_id: props.item?.ngrId || props.item?.stocks?.ngrId || props.item?.stocks?.ngrId,
      comment: '',
      identifier: '',
      specs: {},
      quantity: '',
    });
    let [commodity, setCommodity] = useState([]);
    let [commoditySpecs, setCommoditySpecs] = useState([]);
    let [sites, setSites] = useState([]);
    let [companySites, setCompanySites] = useState([]);
    let [stockSwap, setStockSwap] = useState([]);
    let [showEmailDialog, setShowEmailDialog] = useState(false);
    let [payloadData, setPayloadData] = useState(null);
    let [partyEmails, setPartyEmails] = useState({});
    let [partyContacts, setPartyContacts] = useState({});
    let [isVarietyMandatoryLabel, setIsVarietyMandatoryLabel] = useState(false);

    let [gotOncePartyContacts, setGotOncePartyContacts] = useState(false);
    let [isWarehouseInvoiced, setIsWarehouseInvoiced] = useState(false);

    let [errors, setErrors] = useState({
      estimated_net_weight: '',
      freight_differential: '',
      quality_differential: '',
      variety_id: '',
      swap_site_id: '',
      identifier: '',
      date: '',
      time: '',
    });
    let [availableTonnage, setAvailableTonnage] = useState(props.item?.tonnage || get(props.item, 'stocks.currentTonnage', get(props.item, 'balance')))
    const dispatch = useDispatch();

  let sitesOfCompany = async (companyId) => {
    return await APIService.companies(companyId).appendToUrl('company_sites/?properties=is_variety_mandatory,user_type_for_variety_mandatory,load_type_for_variety_mandatory').get()
  }

  const getStockOwner = async stockOwnerId => await APIService.companies(stockOwnerId).get()

  const _setStockOwner = async () => {
    const stockOwnerId = props.item?.ngrCompanyId || get(props.item, 'stocks.ngr.companyId') || get(props.item, 'stockOwnerId') || get(props.item, 'ownerId')
    let _stockOwner = null
    if(stockOwnerId) {
      if(stockOwner?.id === stockOwnerId)
        _stockOwner = stockOwner
      _stockOwner = await getStockOwner(stockOwnerId)
      setStockOwner(_stockOwner)
    }
    return _stockOwner
  }

  const isVarietyMandatory = async () => {
    let result = false
    const siteId = combinedStates?.swap_site_id
    const site = find([...companySites, ...sites], {id: siteId})
    if(site?.isVarietyMandatory) {
      const _stockOwner = await _setStockOwner()
      if(_stockOwner?.id) {
        const { isVarietyMandatory, loadTypeForVarietyMandatory, userTypeForVarietyMandatory } = site
        let isGrower = isCompanyGrower(_stockOwner)
        let isGrowerType = isGrower && ['growers'].some(type => userTypeForVarietyMandatory.includes(type));
        let isNonGrowerType = !isGrower && ['non_growers'].some(type => userTypeForVarietyMandatory.includes(type));
        let isLoadTypeForVarietyMandatory = ['inload', 'inload_and_outload'].some(type => loadTypeForVarietyMandatory.includes(type))
        result = isVarietyMandatory && isLoadTypeForVarietyMandatory && (isGrowerType || isNonGrowerType)
      }
    }
    return result
  }

  let setVarietyMandatoryBasedUserSettingsAndStockOwner = async () => {
    setIsVarietyMandatoryLabel(await isVarietyMandatory())
  }

  let initialSetup = async () => {
    dispatch(isLoading());
    if (props.isEdit) {
      let stock_swap = await APIService.loads().appendToUrl(`stock-swap/?load_id=${props.item.id}`).get();
      setStockSwap(stock_swap);
      let date_time = getDateTimeFromUTC(get(stock_swap, 'inload.dateTime'));
      setAvailableTonnage(get(stock_swap, 'availableTonnage'));
      let sites;
      if(isEmpty(props.locations)) {
        sites = await sitesOfCompany(get(stock_swap, 'outload.siteCompanyId', get(props.user, 'companyId')));
      } else {
        sites = props.locations
      }
      setSites(sites);
      setIsWarehouseInvoiced(get(stock_swap, 'isWarehouseInvoiced'));

      setCombinedStates({
        ...combinedStates,
        date: date_time.date,
        time: date_time.time,
        commodity_id: get(stock_swap, 'inload.commodityId'),
        variety_id: get(stock_swap, 'inload.varietyId'),
        grade_id: get(stock_swap, 'inload.grade.id'),
        season: get(stock_swap, 'inload.season'),
        current_site_id: get(stock_swap, 'outload.siteId'),
        swap_site_id: get(stock_swap, 'inload.siteId'),
        estimated_net_weight: stock_swap?.inload?.enteredEstimatedNetWeight || stock_swap?.inload?.estimatedNetWeight,
        freight_differential: get(stock_swap, 'freightDifferential'),
        quality_differential: get(stock_swap, 'qualityDifferential'),
        ngr_id: get(stock_swap, 'ngrId'),
        identifier: get(stock_swap, 'inload.extras.identifier'),
        comment: get(stock_swap, 'inload.extras.comment'),
        specs: get(stock_swap, 'inload.specs'),
        quantity: get(stock_swap, 'inload.quantity'),
      });
    } else {
      let date_time = getDateTimeFromUTC(get(props.item, 'dateTime') || Date().toLocaleString());
      setCombinedStates({
        ...combinedStates,
        identifier: generateIdentifier('stockSwap'),
        date: date_time.date,
        time: date_time.time
      });
      let sites = isEmpty(props.locations) ? await sitesOfCompany(currentUserCompany().id ? currentUserCompany().id : get(props.user, 'companyId')) : props.locations;
      setSites(sites);
      let companySites = sortBy(await sitesOfCompany(find(props.locations, {id: props.item?.farmId})?.companyId), 'name')
      setCompanySites(companySites)
    }
    let commodity = isEmpty(props.commodities) ? await APIService.commodities(get(combinedStates, 'commodity_id')).get() : find(props.commodities, {id: get(combinedStates, 'commodity_id')});
    setCommodity(commodity);
    setCommoditySpecs(get(commodity, 'specs'));
    dispatch(forceStopLoader());
  };

  useEffect(() => {
    setVarietyMandatoryBasedUserSettingsAndStockOwner()
  }, [combinedStates?.swap_site_id])

  useEffect(() => {
    let specs = getCommoditySpec(commoditySpecs)
    setCombinedStates({...combinedStates, 'specs': specs});
  }, [commoditySpecs])

  useEffect(() => {
    if(commoditySpecs && stockSwap) {
      let specs = getCommoditySpec(commoditySpecs, stockSwap)
        setCombinedStates({...combinedStates, 'specs': specs});
    }
  }, [commoditySpecs, stockSwap])

  let getCommoditySpec = (commoditySpecs, stockSwap) => {
    const modelSpecs = {};
    if (!isEmpty(commoditySpecs)) {
      forEach(orderBy(commoditySpecs, 'order'), (spec) => {
        let validators = [valueBetween(spec.min, spec.max, true)];
        if (get(combinedStates, 'commodity_id') === COMMODITIES.CANOLA && includes(['COIL', 'IMPU'], spec.code)) {
          validators.push(required());
        }
        modelSpecs[spec.code] = {
          ...FIELD,
          value: get(stockSwap, `inload.specs.${spec.code.toLowerCase()}`, ''),
          validators,
        };
      });
    }
    return modelSpecs;
  }

  let handleSpecsChange = (specCode, specValue) => {
    let specs = {...combinedStates.specs}, errors = [];
    set(specs, `${specCode}.value`, specValue)
    setCombinedStates({
      ...combinedStates,
      specs
    })

    errors = setFieldErrors(`specs.${specCode}`);
    set(specs, `${specCode}.errors`, errors)
    setCombinedStates({
      ...combinedStates,
      specs
    })
  }

  let validateSpecs = () => {
    let specs = {...combinedStates.specs}, isSpecsInvalid = false;
    forEach(combinedStates.specs, (specValue, specCode) => {
      let errors = [];
      errors = setFieldErrors(`specs.${specCode}`);
      if(!isEmpty(errors))
        isSpecsInvalid = true;
      set(specs, `${specCode}.errors`, errors)
    })
    setCombinedStates({
      ...combinedStates,
      specs
    })
    return isSpecsInvalid;
  }

  let setFieldErrors = path => {
    const errors = [];
    const value = get(combinedStates, `${path}.value`);
    const validators = get(combinedStates, `${path}.validators`, []);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }



  let handleSubmit = async () => {
    let combinedStateCopy = Object.assign({}, combinedStates);
    let estimated_net_weight_error = '';
    let freight_differential_error = '';
    let quality_differential_error = '';
    let swap_site_id_errors = '';
    let identifier = '';
    let variety_id_errors = '';
    let date = ''
    let time = ''
    if (combinedStateCopy.identifier && combinedStateCopy.identifier.toUpperCase() != get(stockSwap, 'inload.extras.identifier', '').toUpperCase())
      await APIService.loads().appendToUrl(`identifier/${combinedStateCopy.identifier}/`).get().then(
        res => { if (res) identifier = 'Identifier already exists' }
      )
    let commodityId  = props.item?.commodityId || props.item?.stocks?.commodityId || commodity?.id
    if (!combinedStateCopy.identifier)
      identifier =  MESSAGES.REQUIRED;
    if (!combinedStateCopy.estimated_net_weight)
      estimated_net_weight_error = MESSAGES.REQUIRED;
    else if (combinedStateCopy.estimated_net_weight && combinedStateCopy.estimated_net_weight > availableTonnage)
      estimated_net_weight_error = `Cannot be more than available ${tonnageLabel.toLowerCase()}`;
    else if (combinedStateCopy.estimated_net_weight && combinedStateCopy.estimated_net_weight == 0)
      estimated_net_weight_error = `${tonnageLabel} must be greater than 0`;
    if (!combinedStateCopy.freight_differential && combinedStateCopy.freight_differential != 0)
      freight_differential_error = MESSAGES.REQUIRED;
    if (!combinedStateCopy.quality_differential && combinedStateCopy.quality_differential != 0 && commodityId == COMMODITIES.CANOLA)
      quality_differential_error = MESSAGES.REQUIRED;
    if (!combinedStateCopy.swap_site_id)
      swap_site_id_errors = MESSAGES.REQUIRED;
    if (!combinedStateCopy.date || combinedStateCopy.date === 'Invalid date')
      date = MESSAGES.REQUIRED;
    if (!combinedStateCopy.time || combinedStateCopy.time === 'Invalid date')
      time = MESSAGES.REQUIRED;
    if(!combinedStateCopy.variety_id && isVarietyMandatoryLabel)
      variety_id_errors = MESSAGES.REQUIRED;
    const utcDateTime = getDateTimeInUTC(combinedStateCopy.date, combinedStateCopy.time);
    combinedStateCopy.date_time = utcDateTime.dateTime;
    delete combinedStateCopy.date;
    delete combinedStateCopy.time;
    setErrors(() => {
      let newErrors = {
        ...errors, quality_differential: quality_differential_error, freight_differential: freight_differential_error,
        estimated_net_weight: estimated_net_weight_error, swap_site_id: swap_site_id_errors, identifier: identifier,
        date: date, time: time, variety_id: variety_id_errors
      }
      let isSpecsInvalid = false;
      if(get(combinedStates, 'commodity_id') === COMMODITIES.CANOLA) {
        isSpecsInvalid = validateSpecs()
        if(!isSpecsInvalid) {
          let specs = {}
          forEach(combinedStates.specs, (specValue, specCode) => {
            set(specs, `${specCode.toUpperCase()}`, specValue.value);
          })
          combinedStateCopy.specs = specs;
        }
      }

      let isInvalid = some(newErrors, error => !isEmpty(error)) || isSpecsInvalid;

      if (!isInvalid) {
        const currentSiteStockManagementSetting = find(props.locations, {id: props.item?.farmId})?.stocksManagement;
        const swapSiteStockManagementSetting = find(companySites, {id: combinedStates.swap_site_id})?.stocksManagement;
        const currentSiteCompanyId = find(props.locations, {id: props.item?.farmId})?.companyId
        if(props.isOwnershipsView && (!isCurrentUserBelongsToCompany(currentSiteCompanyId) && (currentSiteStockManagementSetting || swapSiteStockManagementSetting))) {
          alertifyjs.error("Current Site/New Site manages the stock at their sites. Please contact the site directly for stock swap creation.")
        } else {
          dispatch(isLoading());
          const partyContacts = getPartyContacts();
          setPartyContacts(partyContacts)
          if (props.isEdit) {
            openEmailDialog(combinedStateCopy);
          } else {
            openEmailDialog(combinedStateCopy);
          }
        }
      }
      return newErrors;
    });
  };

  let onCombinedStateChange = async (val, id) => {
    setCombinedStates({ ...combinedStates, [id]: val });
    if (isEmpty(val) && !val)
      setErrors({ ...errors, [id]: MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, [id]: '' });
  };

  const closeEmailDialog = (communicationData, justClose) => {
    if (justClose) {
      setGotOncePartyContacts(false);
      setShowEmailDialog(false);
    } else if (showEmailDialog) {
      let data = payloadData;
      if (communicationData) {
        data['communication'] = communicationData;
      }
      if(!isEmpty(data.specs)) {
        let specs = {}
        forEach(data.specs, (value, code) => {
          if(has(value, 'value') && value.value)
            specs[code] = value.value
          else if (!has(value, 'value'))
            specs[code] = value
        })
        data.specs = specs
      }

      setShowEmailDialog(false);
      const service = APIService.loads().appendToUrl('stock-swap/')
      let promise;
      if(props.isEdit) {
        promise = service.appendToUrl(`${stockSwap.id}/`).put(data);
      } else {
        promise = service.post(data)
      }
      promise.then(response => {
        dispatch(forceStopLoader());
        if (get(response, 'errors'))
          alertifyjs.error(get(response, 'errors'), 3)
        else {
          alertifyjs.success('Stocks Swapped');
          props.toggleDialog();
          window.location.reload();
        }
      })
    }
  }

  const getEmailSubject = () => {
    let companyName = find(props.owners, {id: props.item.ngrCompanyId})?.name;
    let name = '';
    if(companyName)
      name += `${companyName} | `
    if(props.isEdit)
      name = '[Amendment] ' + name
    const oldSite = find(sites, { id: get(combinedStates, 'current_site_id') });
    const newSite = find(sites, { id: get(combinedStates, 'swap_site_id') });;
    const identifier = get(combinedStates, 'identifier').toUpperCase()
    return name + `${get(oldSite, 'displayName') || oldSite?.name} to ${get(newSite, 'displayName') || newSite?.name} | Stock Swap #${identifier}`;
  }

  const getPartyEmails = () => partyEmails

  const getEmailPopupParties = () => ['stock_owner', 'swap_site']

  const getEmailPopupPartiesCompanyIds = async () => {
    const ids = {};
    ids.stock_owner = props.item?.ngrCompanyId || get(props.item, 'stocks.ngr.companyId') || get(props.item, 'stockOwnerId') || get(props.item, 'ownerId');
    const siteId = get(combinedStates, 'swap_site_id');
    const siteInfo = await APIService.farms().appendToUrl(`${siteId}/`).get();
    ids.swap_site = get(siteInfo, 'companyId')

    return ids;
  }

  const getPartyContacts = async () => {
    if (gotOncePartyContacts)
      return;

    setGotOncePartyContacts(true);
    let partyCompanyIds = await getEmailPopupPartiesCompanyIds();
    const parties = getEmailPopupParties();
    const contacts = {};
    const emails = {};
    let companyIds = compact(Object.values(partyCompanyIds));
    const companyQueryString = map(companyIds, id => `company_ids=${id}`).join('&');
    const employees = await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get();

    forEach(parties, party => {
      contacts[party] = filter(employees, { companyId: partyCompanyIds[party] });
      if(contacts[party])
        emails[party] = get(contacts[party][0], 'email');
    });

    setPartyEmails(emails);
    return contacts;
  }

  const openEmailDialog = (data) => {
    setShowEmailDialog(true);
    setPayloadData(data);
  };

  const initiateDownloadPdf = () => {
    let queryParams = '?download=true';
    APIService.loads().appendToUrl('stock-swap/'+ get(stockSwap, 'id')+'/'+queryParams).requestUsingFetch('GET')
    .then(response => {
      if(isEqual(get(response, 'status'), 200))
        return response.blob()
    })
    .then(response => {
      if(response) {
        const fileName = 'Stock_Swap' +  moment().utc() + '.pdf';
        downloadFileFromBlob(response, fileName);
      }
    })
  }

  let handleDelete = () => {
    if (get(stockSwap, 'inload.id') && get(stockSwap, 'outload.id')) {
      alertifyjs.confirm(
        'Warning',
        `<div className=""><ul>Are you sure you want to delete?</ul></div>`,
        () => {
          APIService.storages().appendToUrl(`silo-to-silo-transfer/?load_ids=${stockSwap.inload.id}&load_ids=${stockSwap.outload.id}`).delete().then(
            alertifyjs.success('Loads Deleted', 1, () => {
              localStorage.setItem('stocks', JSON.stringify({}));
              window.location.reload();
              props.toggleDialog();
            })
          );
        },
        () => { }
      );
    }
  };

  let handleChange = event => {
    setCombinedStates({ ...combinedStates, [event.target.id]: event.target.value });
    if (isEmpty(event.target.value) && !event.target.value)
      setErrors({ ...errors, [event.target.id]: MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, [event.target.id]: '' });
    if (event.target.id === 'estimated_net_weight' && event.target.value > availableTonnage){
      setErrors({ ...errors, [event.target.id]: `Cannot be more than available ${tonnageLabel.toLowerCase()}` });
    }
  }

  let onSiteChange = id => {
    setCombinedStates({ ...combinedStates, 'swap_site_id': id || '' })
    if (isEmpty(id) && !id)
      setErrors({ ...errors, swap_site_id: MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, swap_site_id: '' });
  }

  let onVarietyChange = id => {
    if(isVarietyMandatoryLabel && !id) {
      setErrors({ ...errors, 'variety_id': MESSAGES.REQUIRED });
      setCombinedStates({ ...combinedStates, 'variety_id': '' })
    }
    else {
      setCombinedStates({ ...combinedStates, 'variety_id':id || '' });
      setErrors({ ...errors, 'variety_id': '' });
    }
  }


  useEffect(() => {
    initialSetup() // this needs to be called inside a function due to async behaviour
  }, []);

  let handleIdentifierChange = event => {
    if (isEmpty(event.target.value))
      setErrors({ ...errors, 'identifier': MESSAGES.REQUIRED });
    else
      setErrors({ ...errors, 'identifier': '' });

    const regex = new RegExp('^[a-zA-Z0-9]*$');
    if (regex.test(event.target.value)) {
      setCombinedStates({ ...combinedStates, 'identifier': event.target.value })
    }
  }

  const ownerName = find(props.owners, {id: props.item?.ngrCompanyId})?.name
  const currentSite = find(props.locations, {id: props.item?.farmId})?.name
  const gradeName = find(find(props.commodities, {id: props.item?.commodityId})?.grades, {id: props.item?.gradeId})?.name
  const currency = getCountryCurrency()
  let hasQuantityBasedCommodity = () => Boolean(commodity?.isQuantityBased)

  return (
    <div className='col-md-12 padding-reset' style={{ margin: '20px 0' }}>
      {
          isWarehouseInvoiced &&
          <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
            <span style={{fontSize: '14px', color: 'rgba(0, 0, 0, 0.5)'}}>
              <i>(this stock swap is already invoiced, editing is restricted.)</i>
            </span>
          </div>
      }
      { props.isEdit &&
        <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
          <CommonListingButton
            showDownLoadIcon
            title='Download PDF'
            name='Download PDF'
            variant='outlined'
            style={{marginLeft: '0px'}}
            defaultHandler={initiateDownloadPdf}
          />
        </div>
      }
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
      <TextField
        id='identifier'
        label='Identifier'
        value={combinedStates.identifier}
        onChange={handleIdentifierChange}
        error={!isEmpty(errors.identifier)}
        helperText={errors.identifier}
        variant='standard'
        disabled={isWarehouseInvoiced}
        inputProps={{
          maxLength: 14,
        }}
        fullWidth
      />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='owner' label='Stock Owner' value={ownerName || get(props.item, 'stocks.ngr.companyName', get(stockSwap, 'inload.ngr.companyName'))} disable/>
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='ngr' label='Owner NGR' value={props.item?.ngrNumber || get(props.item, 'stocks.ngr.ngrNumber', get(stockSwap, 'inload.ngr.ngrNumber'))} disabled />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='commodity' label='Commodity' value={get(commodity, 'displayName')} disabled />
      </div>
      <div className='col-md-12 padding-reset' style={{ marginBottom: '20px' }}>
        <VarietyAutoComplete
          id="variety_id"
          onChange={onVarietyChange}
          label="Variety"
          commodityId={commodity?.id}
          varietyId={combinedStates.variety_id || get(props.item, 'stocks.variety_id', get(stockSwap, 'inload.variety_id'))}
          dependsOnCommodity={true}
          errorText={errors.variety_id}
        />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='grade' label='Grade' value={gradeName || get(props.item, 'stocks.grade.name', get(stockSwap, 'inload.grade.name'))} disabled />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='season' label='Season' value={props.item?.season || get(props.item, 'stocks.season', get(combinedStates, 'season'))} disabled />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='stock_site' label='Current Stock Site' value={currentSite || get(props.item, 'displayName', get(stockSwap, 'outload.siteDisplayName'))} disabled />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonAutoSelect
          id="swap_site_id"
          fullWidth
          onChange={onSiteChange}
          label="Swap Stock To Site"
          items={props.isOwnershipsView ? filter(companySites, obj => obj.id != combinedStates.current_site_id && obj.id != 'all' && obj?.isActive) : filter(sites, obj => obj.id != combinedStates.current_site_id && obj.id != 'all' && obj?.isActive)}
          value={combinedStates.swap_site_id}
          getOptionLabel={option => get(option, 'displayName')}
          errorText={errors.swap_site_id}
          disabled={isWarehouseInvoiced}
        />
      </div>
      <div className='col-md-12 padding-reset' style={{ marginBottom: '20px' }}>
        <CommonDatePicker
          id="date"
          floatingLabelText='Swap Date'
          onChange={onCombinedStateChange}
          value={combinedStates.date}
          minDate={moment().subtract(5, 'years').format('YYYY/MM/DD')}
          maxDate={moment().format('YYYY/MM/DD')}
          errorText={errors.date}
          disabled={isWarehouseInvoiced}
        />
      </div>
      <div className='col-md-12 padding-reset' style={{ marginBottom: '20px' }}>
        <CommonTimePicker
          id='time'
          floatingLabelText='Swap Time'
          onChange={onCombinedStateChange}
          value={combinedStates.time}
          errorText={errors.time}
          disabled={isWarehouseInvoiced}
        />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField id='available_tonnage' label={`Available ${tonnageLabel} (${displayUnit})`} value={parseFloat(props.isEdit ? availableTonnage : props.item?.tonnage || get(props.item, 'stocks.currentTonnage', get(props.item, 'balance'))).toFixed(2)} disabled />
      </div>
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField
          id='estimated_net_weight'
          label={`Swap ${tonnageLabel} (${displayUnit})`}
          value={combinedStates.estimated_net_weight}
          onChange={handleChange}
          helperText={errors.estimated_net_weight}
          disabled={isWarehouseInvoiced}
        />
      </div>
      {
        hasQuantityBasedCommodity() &&
        <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
          <CommonTextField
            id='quantity'
            label={`Swap Quantity (${commodity?.unit})`}
            value={combinedStates.quantity}
            onChange={handleChange}
            disabled={isWarehouseInvoiced}
          />
        </div>
      }
      <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
        <CommonTextField
          id='freight_differential'
          label='Freight Differential'
          value={combinedStates.freight_differential}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                {currency}
              </InputAdornment>
            ),
          }}
          helperText={errors.freight_differential}
          disabled={isWarehouseInvoiced}
        />
      </div>
      {(props.item?.commodityId || get(props.item, 'stocks.commodityId', get(commodity, 'id'))) == COMMODITIES.CANOLA &&
       <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
          <CommonTextField
            id='quality_differential'
            label='Quality Differential '
            value={combinedStates.quality_differential}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                  {currency}
                </InputAdornment>
              ),
            }}
            helperText={errors.quality_differential}
            disabled={isWarehouseInvoiced}
          />
        </div>}
        <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
          <SpecParametersValue
            commoditySpecs={commoditySpecs}
            fieldsSpecs={combinedStates.specs}
            onChange={handleSpecsChange}
            className="col-sm-12 form-wrap"
            style={{paddingLeft: '0px', marginBottom: '10px'}}
            errorTexts={mapValues(combinedStates.specs, spec => get(spec, 'errors[0]', ''))}
          />
        </div>
        <div className="col-md-12 padding-reset" style={{ marginBottom: '20px' }}>
            <CommonTextField
              id="comment"
              label="Comment (Optional)"
              value={combinedStates.comment}
              onChange={(event) => setCombinedStates({ ...combinedStates, 'comment': event.target.value })}
              multiline={true}
              rows={2}
              rowsMax={2}
            />
        </div>
      <div className="col-md-12 padding-reset">
        <Button
          type='button'
          color='default'
          variant='outlined'
          onClick={props.toggleDialog}
          style={{ marginRight: '20px' }}>
          Cancel
        </Button>
        {props.isEdit &&
          <Button
            type='button'
            variant='contained'
            color='secondary'
            onClick={handleDelete}
            style={{ marginRight: '20px' }}
            disabled={isWarehouseInvoiced}
          >
            Delete
          </Button>}
        <Button type='button' variant='contained' disabled={isWarehouseInvoiced} onClick={handleSubmit}>
          {props.isEdit ? 'Update' : 'Confirm'}
        </Button>
      </div>
      {showEmailDialog &&
        <CustomEmailDialog
          parties={getEmailPopupParties()}
          title="Email PDF copies to"
          partyEmails={getPartyEmails()}
          partyContacts={partyContacts}
          subject={getEmailSubject()}
          noBody
          open={showEmailDialog}
          onClose={closeEmailDialog}
          disableAcceptanceRequired
          footer='Stock Swap PDF will be automatically sent as part of the email'
        />
      }
    </div>
  );
};
export default StockSwapDialog;

import React from 'react';
import { connect } from 'react-redux';
import alertifyjs from 'alertifyjs';
import FileDownload from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import RevokeIcon from '@mui/icons-material/CancelScheduleSend';
import APIService from '../../services/APIService';
import LoaderInline from '../LoaderInline';
import {
  isArray, isEmpty, filter, get, startCase, chunk, capitalize, includes
} from 'lodash';
import { setHeaderText, setBreadcrumbs } from '../../actions/main';
import { IconButton, Tooltip, Button } from '@mui/material'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import ContractIcon from '../common/icons/Contract';
import MovementIcon from '../common/icons/Movement';
import TitleTransferIcon from '../common/icons/TitleTransfer';
import OrderIcon from '../common/icons/Order';
import InvoiceIcon from '../common/icons/Invoice';
import SiteManagement from '../common/icons/SiteManagement';
import { BLACK } from '../../common/constants';
import StockIcon from '../common/icons/Stocks';
import FarmIcon from '../common/icons/page-headers/Farm';
import CashBoard from '../common/icons/CashBoard';
import Company from '../common/icons/Company';
import { toDateTimeFormat } from '../../common/utils'

class DownloadsPanel extends React.Component {
  handleClick(event, download) {
    if(get(download, 'url') && download.status === 'ready')
      window.open(download.url, '_blank');
  }

  getDownloadMessage(download) {
    if (includes(download.name, '_upload_') || download.module === 'cash_prices')
      return download.status === 'ready' ? download.name : download.status != 'failed' ? `${download.name} (${capitalize(download.status)})` : `${download.name} (${capitalize(download.status)} - Incorrect CSV format)`;
    else
      return download.status === 'ready' ? download.name : `${download.name} (${startCase(download.status)})`;
  }

  onDelete(event, download) {
    event.preventDefault()
    event.stopPropagation()
    if(download.id) {
      alertifyjs.confirm('Delete Download', 'Are you sure you want to delete this download?', () => {
        APIService.profiles().downloads(download.id).delete().then(() => window.location.reload())
      }, () => {})
    }
  }

  onRequestOffline = download => {
    APIService.profiles().downloads(download.id).post().then(response => {
      if(response?.error === 204)
        alertifyjs.error(response.data?.error || 'Something update, reloading the state...', 2, () => window.location.reload())
      else
        alertifyjs.success('Request submitted, please give us some time while we process your request.', 3, () => window.location.reload())
    })
  }

  render() {
    const {title, downloads, icon} = this.props;
    const count = isEmpty(downloads) || !isArray(downloads) ? 0 : downloads.length;
    return (
      <div className="col-xs-4" style={{padding: '0 5px', marginTop: '10px'}}>
        <Accordion defaultExpanded={Boolean(count)} disabled={count === 0}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            {icon}
            <Typography style={{marginLeft: '5px'}}>{`${title} (${count})`}</Typography>
          </AccordionSummary>
          <AccordionDetails style={{padding: '0', height: '215px', overflow: 'scroll'}}>
            <List>
              {
                !isEmpty(downloads) &&
                  downloads.map(download => {
                    const isDone = includes(['failed', 'ready'], download.status)
                    const isCancelled = download.status === 'cancelled'
                    const isSystemRevoked = download.status === 'revoked'
                    const iconColor = (isSystemRevoked || isCancelled || download.status == 'failed') ? 'error' : (isDone ? 'primary' : 'default');
                    return (
                      <ListItem style={{padding: '8px'}} key={download.name} button={download.status === 'ready'} onClick={event => this.handleClick(event, download)}>
                        <ListItemIcon style={{minWidth: 'auto', marginRight: '8px'}}>
                          <Tooltip title={startCase(download.status)}>
                            <span>
                            {
                              isCancelled &&
                                <CancelIcon color='error' />
                            }
                            {
                                  isSystemRevoked &&
                                    <RevokeIcon color='warning' />

                            }
                            {
                              (!isCancelled && !isSystemRevoked) &&
                                <FileDownload color={iconColor} />
                            }
                            </span>
                            </Tooltip>
                        </ListItemIcon>
                        <ListItemText
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          primary={this.getDownloadMessage(download)}
                          secondary={
                            isSystemRevoked ?
                              (
                                <span style={{whiteSpace: 'pre-wrap'}}>
                                  This request could take long time to process. Kindly <Button style={{textTransform: 'none', padding: 0}} variant='text' size='small' onClick={() => this.onRequestOffline(download)}>Click Here</Button> to request this offline and our support team will get back to you with the requested file soon.
                                </span>
                              ) :
                              toDateTimeFormat(new Date(download.updatedAt || download.createdAt))
                          }
                        />
                        {
                          download.status !== 'requested_offline' &&
                            <Tooltip title='Delete/Cancel this download.'>
                              <IconButton color='error' size='small' onClick={event => this.onDelete(event, download)}>
                                <DeleteIcon fontSize='small' />
                              </IconButton>
                            </Tooltip>
                        }
                      </ListItem>
                    )
                  })
              }
            </List>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  }
}

class Downloads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      isLoading: true
    };
  }

  componentDidMount(){
    this.props.dispatch(setHeaderText('Downloads'));
    this.props.dispatch(setBreadcrumbs([{text: 'Downloads'}]));

    APIService.profiles().downloads().get(this.props.token).then(data => {
      this.setState({data: data, isLoading: false});
    });
  }

  getModuleData(module) {
    return filter(this.state.data, {module: module});
  }

  getIcon(module) {
    switch(module) {
    case 'farm_freight_movements':
    case 'freight_movements':
      return <MovementIcon fill={BLACK} width="20" height="20" />;
    case 'title_transfers':
      return <TitleTransferIcon fill={BLACK} width="20" height="20" />;
    case 'freight_orders':
      return <OrderIcon fill={BLACK} width="20" height="20" />;
    case 'contracts':
      return <ContractIcon fill={BLACK} width="20" height="20" noStyle />;
    case 'slots':
      return <SiteManagement fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}}/>;
    case 'brokerage_invoices':
    case 'commodity_contract_invoices':
    case 'freight_invoices':
    case 'aba_files':
      return <InvoiceIcon fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}} />;
    case 'warehouse_invoices':
      return <InvoiceIcon fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}} />;
    case 'stocks':
      return <StockIcon fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}} />;
    case 'paddock_report':
    case 'farms':
      return <FarmIcon fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}}/>;
    case 'cash_prices':
      return <CashBoard fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}}/>;
    case 'companies':
      return <Company fill={BLACK} width="20" height="20" noStyle style={{marginTop: '-3px'}}/>;
    default:
      return '';
    }
  }

  render() {
    const { isLoading } = this.state;
    return (
      <div>
        {
          isLoading ?
            <LoaderInline containerClassName="inline-loader-container" /> :
            <div className="col-xs-12 padding-reset">
              {
                chunk([
                  'freight_movements', 'freight_orders', 'contracts', 'title_transfers', 'slots', 'brokerage_invoices',
                  'freight_invoices', 'commodity_contract_invoices', 'farm_freight_movements', 'warehouse_invoices', 'stocks', 'farms',
                  'paddock_report', 'cash_prices', 'rms_report', 'companies', 'aba_files'
                ], 3).map(chunk => {
                  return (
                    <div key={chunk.toString()} className="col-xs-12 padding-reset">
                      {
                        chunk.map(module => (
                          <DownloadsPanel
                            key={module}
                            title={module=='rms_report' ? 'RMS Report' : startCase(module)}
                            downloads={this.getModuleData(module)}
                            icon={this.getIcon(module)}
                          />
                        ))
                      }
                    </div>
                  );
                })
              }
            </div>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token
  };
};

export default connect(mapStateToProps)(Downloads);

import React, { Component } from 'react';

import { connect } from 'react-redux';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CommonButton from '../../common/CommonButton';
import { required } from '../../../common/validators';
import { isLoading } from '../../../actions/main';
import get from 'lodash/get';
import filter from 'lodash/filter';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import toString from 'lodash/toString';
import { RejectionReasonDialog } from '../../rejections/RejectionReasonDialog';
import Tooltip from '../../../common/Tooltip';
import {
  confirmMovementAmend,
  rejectMovementAmend,
  getSelectedFreight,
  receiveFreightContract
} from '../../../actions/companies/freights';
import {
  convertEpochToDateFormat,
  convertTimeToTwelveHour,
} from '../../../common/momentUtilities';
import includes from 'lodash/includes';
import { compareObjectValues } from '../../../common/utils';


const styles = () => ({
  subTitle: {
    fontSize: 15,
    fontWeight: 600,
    color: '#112c42',
  },
  columnTitle:{
    border: '1px solid #000',
    textAlign: 'center',
    width : '35%',
    fontSize: 15,
    fontWeight: 600
  },
  listItem: {
    display: 'block',
    height: 85,
    padding: 0,
  },
  secondaryListItem: {
    padding: 0,
  },
  primaryListItem: {
    padding: 0,
  },
});

class EditMovementReview extends Component {
  constructor(props) {
    super(props);

    this.classes = this.props.classes;
    this.movementId = null;

    this.state = {
      rejectDialogOpen: {
        value: false,
        validators: [],
        errors: [],
      },
      rejectionReason: {
        value: '',
        validators: [],
        errors: [],
      },
    };

    this.confirmAmendRequestWrapper = this.confirmAmendRequestWrapper.bind(this);
    this.rejectAmendRequestWrapper = this.rejectAmendRequestWrapper.bind(this);
    this.setUpMovementDetail();
  }

  componentDidMount() {
    if (isEmpty(this.props.movement) && this.props.movementId) {
      this.props.getSelectedFreight(this.props.movementId);
      this.amendedDetails = this.props.amendDetails;
    }
    if (!isEmpty(this.props.movement) && !this.movementId) {
      this.setUpMovementDetail();
    }
  }

  setUpMovementDetail = () => {
    if (!isEmpty(this.props.movement)) {
      this.movementId = this.props.movement.id;
      this.isAmendRequestPending = this.props.movement.isAmendRequestPending;
      this.amendable = this.props.movement.amendable;
      this.amendedDetails = this.props.amendDetails || this.props.movement.amendDetails;
    }
  };

  handleRejectClickOpen = () => {
    const newState = { ...this.state };
    newState.rejectDialogOpen.value = true;
    newState.rejectionReason.validators = [required()];
    if (!newState.rejectionReason.value) {
      newState.rejectionReason.errors = [];
    }
    this.setState(newState);
  };

  handleRejectClose = () => {
    this.setState({
      rejectDialogOpen: { errors: [], value: false, validators: [] },
    });
  };

  handleRejectSubmit = event => {
    this.setReasonErrors();
    const data = { rejectionReason: this.state.rejectionReason.value };
    if (this.state.rejectionReason.errors.length === 0) {
      this.rejectAmendRequestWrapper(data);
    }
    event.preventDefault();
  };

  handleReasonChange = event => {
    const value = event.target.value;
    const newState = { ...this.state };
    newState.rejectionReason.value = value;
    this.setState(newState, () => this.setReasonErrors());
  };

  setReasonErrors(errors) {
    const newState = { ...this.state };
    newState.rejectionReason.errors = errors || this.getReasonErrors();
    this.setState(newState);
  }

  getReasonErrors() {
    const errors = [];
    const value = get(this.state, `rejectionReason.value`);
    const validators = get(this.state, `rejectionReason.validators`, []);
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  confirmAmendRequestWrapper(){
    this.props.closeSidebar();
    this.props.confirmAmendRequest(this.movementId, this.props.shouldReload);
  }

  rejectAmendRequestWrapper(data){
    this.props.closeSidebar();
    this.props.rejectAmendRequest(this.movementId, data, null, this.props.shouldReload);
  }

  getValidField = (field, amendedField) => {
    const currentField = get(this.props.movement, amendedField) || '-';
    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: typeof currentField == 'object' ? (currentField?.name || '') : currentField,
      value1: get(this.amendedDetails, amendedField)
    });
    if (isEmpty(resultObj) || resultObj.value1 == null || resultObj.value1 == resultObj.currentValue) {
      return null;
    }
    return resultObj;
  };

  getHeading = heading => {
    return (
      <TableRow style={{ border: '1px solid #000'}}>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '30%' }}>
          <Typography variant="heading" className={this.classes.subTitle} style={{textAlign: 'center', marginLeft:'-20px'}}>
            {heading}
          </Typography>
        </TableCell>
        <TableCell style={{ border: '1px solid #000', width : '35%' }}></TableCell>
        <TableCell style={{ border: '1px solid #000', width : '35%' }}></TableCell>
      </TableRow>
    );
  };

  getRow = (resultObj, index) => {
    if (isEmpty(resultObj)) {
      return;
    }
    return (
      <TableRow key={index} style={{width: '100%'}}>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '30%' }}>
          <Tooltip
              className="ellipse amend-field-value"
              tooltipText={resultObj.key}
              textContent={resultObj.key}
              style={{textAlign: 'center', marginLeft:'-30px'}}
            />
        </TableCell>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '35%' }}>
          <Tooltip
            className="ellipses amend-field-value"
            tooltipText={toString(resultObj.currentValue)}
            textContent={toString(resultObj.currentValue)}
          />
        </TableCell>
        <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '35%' }}>
          <Tooltip
            className="ellipses amend-field-value"
            tooltipText={toString(resultObj.value1)}
            textContent={toString(resultObj.value1)}
          />
        </TableCell>
      </TableRow>
    );
  };

  getNgrRow = (field, amendedField) => {
    let currentField = get(this.props.movement, amendedField);
    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: currentField ? currentField?.ngrNumber : '-',
      value1: get(this.amendedDetails, amendedField),
    });
    if (isEmpty(resultObj)) {
      return null;
    }
    return resultObj;
  };

  getGradeRow = (field, amendedField) => {
    let currentField = get(this.props.movement, amendedField) || '-';
    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: typeof currentField == 'object' ? currentField?.name : currentField,
      value1: get(this.amendedDetails, amendedField),
    });
    if (isEmpty(resultObj)) {
      return null;
    }
    return resultObj;
  };

  getDateRow = (field, amendedField) => {
    let amendedDate = get(this.amendedDetails, amendedField);
    let currentDate = get(this.props.movement, amendedField)

    if (amendedDate)
      amendedDate = convertEpochToDateFormat(amendedDate);

    if (currentDate)
      currentDate = convertEpochToDateFormat(currentDate);

    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: currentDate,
      value1: amendedDate,
    });
    if (isEmpty(resultObj)) {
      return null;
    }
    return resultObj;
  };

  getValidSiteField = (field, amendedField) => {
    let currentField = '-'
    if (includes(['Pickup Site', 'Delivery Site'], field))
      currentField = field == 'Delivery Site' ? get(this.props.movement, 'freightDelivery.consignee.handler.displayName') : get(this.props.order, 'freightPickup.consignor.handler.displayName')
    else
      currentField = field == 'Delivery Storage' ? get(this.props.movement, 'freightDelivery.consignee.sites[0].location.name') : get(this.props.order, 'freightPickup.consignor.sites[0].location.name')
    const resultObj = compareObjectValues({
        key: field || '',
        currentValue: currentField,
        value1: get(this.amendedDetails, amendedField),
      });
    if (isEmpty(resultObj) || resultObj.value1 == null || resultObj.value1 == resultObj.currentValue)
      return null;

    return resultObj;
  };

  getTimeRow = (field, amendedField) => {
    let amendedTime = get(this.amendedDetails, amendedField);
    let currentTime = get(this.props.movement, amendedField)

    if (amendedTime)
      amendedTime = convertTimeToTwelveHour(amendedTime);
    if (currentTime)
      currentTime = convertTimeToTwelveHour(currentTime);

    const resultObj = compareObjectValues({
      key: field || '',
      currentValue: currentTime,
      value1: amendedTime,
    });
    if (isEmpty(resultObj)) {
      return null;
    }
    return resultObj;
  };

  getConditions = (field, amendedField) => {
    const _check = this.getValidField(field, amendedField);
    if (isEmpty(_check)) {
      return;
    }
    return (
      <React.Fragment>
        <TableRow>
          <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '30%' }}>
            <Typography variant="heading" className={this.classes.subTitle} style={{margin:"15px 0"}}>
              {field}
            </Typography>
          </TableCell>
          <TableCell style={{ border: '1px solid #000', width : '35%' }}>
            <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
              {get(this.props.movement, amendedField)}
            </div>
          </TableCell>
          <TableCell style={{ border: '1px solid #000', textAlign: 'center', width : '35%' }}>
            <div style={{whiteSpace: 'pre-line', wordWrap: 'break-word'}}>
              {get(this.amendedDetails, amendedField)}
            </div>
          </TableCell>
      </TableRow>
      </React.Fragment>
    );
  };

  quantityLabel() {
    const quantityLabel = get(this.props, 'movement.commodity.quantityLabel')
    const unit = get(this.props, 'movement.commodity.unit')
    if(quantityLabel)
      return `${quantityLabel} (${unit})}`
  }

  tonnageLabel() {
    return get(this.props.movement, 'tonnageWithUnitLabel')
  }

  render() {
    const movementValues = this.props.movement|| this.props.selectedFreight;
    const isCustomer = get(movementValues, 'isCustomer');
    const isFreightProvider = get(movementValues, 'isFreightProvider');
    const isCreator = get(movementValues, 'isCreator');
    const canEditInvoicingSection = get(movementValues, 'canEditInvoicingSection');
    const isCustomerRegistered = get(movementValues, 'isCustomerRegistered');
    const showCheckpoint = includes(
      ['planned', 'confirmation_pending', 'confirmed', 'rejected'],
      get(movementValues, 'status'),
    );
    const showCommodity = get(movementValues, 'isIndependentCustomerOnly');
    const hasParentOrder = !!get(movementValues, 'orderId');
    const FreightProvider = filter([
      this.getValidField('Contact', 'assignTo'),
    ]);
    const Customer = filter([
      this.getValidField(
        'Contact',
        'customer.contact',
      ),
      this.getNgrRow('NGR', 'customer.ngr'),
    ]);
    const GeneralDetail = filter([
      this.getValidField(this.quantityLabel(), 'quantity'),
      this.getValidField(this.tonnageLabel(), 'plannedTonnage'),
    ]);
    const GeneralDetailExtra = filter([
      this.getValidField('Commodity', 'commodity'),
      this.getValidField('Variety', 'variety'),
      this.getGradeRow('Grade', 'plannedGrade'),
      this.getValidField('Season', 'season'),
    ]);

    const PickUpDetail = filter([
      this.getDateRow(
        'Pickup Date',
        'freightPickup.date',
      ),
      this.getTimeRow(
        'Pickup Time',
        'freightPickup.timeStart',
      ),
      this.getValidSiteField(
        'Pickup Site',
        'consignorName',
      ),
      this.getValidSiteField(
        'Pickup Storage',
        'pickupSite',
      ),
      this.getValidField(
        'Pickup Order Number',
        'freightPickup.orderNumber',
      ),
    ]);

    const DeliveryDetail = filter([
      this.getDateRow(
        'Delivery Date',
        'freightDelivery.date',
      ),
      this.getTimeRow(
        'Delivery Time',
        'freightDelivery.timeStart',
      ),
      this.getValidSiteField(
        'Delivery Site',
        'consigneeName',
      ),
      this.getValidSiteField(
        'Delivery Storage',
        'deliverySite',
      ),
      this.getValidField(
        'Delivery Order Number',
        'freightDelivery.orderNumber',
      ),
    ]);

    const Invoicing = filter([
      this.getValidField('Invoicing', 'invoicing'),
      this.getValidField('Payment Term', 'paymentTerm'),
    ]);

    return (
      <Grid container spacing={8} style={{display: 'inline'}}>
        {movementValues && (
          <div className="col-md-12 padding-reset" id="edit-movement-review">
            <Table style={{ marginTop: '10px' }}>
              <TableHead style={{borderCollapse: 'collapse'}} >
                <TableRow key='column-names'>
                  <TableCell style={{ borderBottom: '1px solid #000', width : '30%' }}></TableCell>
                  <TableCell className={this.classes.columnTitle}>Current</TableCell>
                  <TableCell className={this.classes.columnTitle}>Amended</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(FreightProvider) && this.getHeading('Freight Provider')}
                {!isEmpty(FreightProvider) && map(FreightProvider, (element, index) => this.getRow(element, index))}

                {!isEmpty(Customer) && this.getHeading('Customer')}
                {!isEmpty(Customer) && map(Customer, (element, index) => this.getRow(element, index))}

                {!isEmpty(GeneralDetail) && this.getHeading('General Details')}
                {!isEmpty(GeneralDetail) && map(GeneralDetail, (element, index) => this.getRow(element, index))}
                {showCommodity && !isEmpty(GeneralDetailExtra) &&
                  map(GeneralDetailExtra, (element, index) => this.getRow(element, index))}

                {showCheckpoint &&
                !isEmpty(PickUpDetail) &&
                this.getHeading('Pickup Details')}
                {showCheckpoint &&
                  !isEmpty(PickUpDetail) &&
                  map(PickUpDetail, (element, index) => this.getRow(element, index))}

                {showCheckpoint &&
                !isEmpty(DeliveryDetail) &&
                this.getHeading('Delivery Details')}
                {showCheckpoint &&
                  !isEmpty(DeliveryDetail) &&
                  map(DeliveryDetail, (element, index) => this.getRow(element, index))}

                {canEditInvoicingSection && !isEmpty(Invoicing) &&
                  !hasParentOrder &&
                  this.getRow(
                    this.getValidField('Invoicing', 'invoicing'),
                  )}
                {canEditInvoicingSection &&
                  this.getRow(
                    this.getValidField(
                      'Payment Term',
                      'paymentTerm',
                    ),
                  )}

                {(isCustomer || (isCreator && !isCustomerRegistered)) &&
                  !hasParentOrder &&
                  this.getRow(
                    this.getValidField(
                      'Freight Rate In',
                      'rateFreightIn',
                    ),
                  )}
                {(isCustomer || (isCreator && !isCustomerRegistered)) &&
                  !hasParentOrder &&
                  this.getRow(
                    this.getValidField(
                      'Freight Rate Out',
                      'rateFreightOut',
                    ),
                  )}

                {isFreightProvider &&
                  !isCustomer &&
                  isCreator &&
                  !hasParentOrder &&
                  this.getRow(
                    this.getValidField(
                      'Freight Rate',
                      'rateFreightOut',
                    ),
                  )}

                {canEditInvoicingSection &&
                  this.getRow(
                    this.getValidField('Overs Rate', 'rateOvers'),
                  )}

                {canEditInvoicingSection &&
                  this.getConditions(
                    'General Conditions',
                    'generalConditions',
                  )}
                {canEditInvoicingSection &&
                  this.getConditions(
                    'Special Conditions',
                    'specialConditions',
                  )}
              </TableBody>
            </Table>
          </div>
        )}

        <div
          className="button-container col-md-12"
          style={{ marginTop: '10px', textAlign: 'right' }}
        >
          {this.amendable && this.isAmendRequestPending
            ? [
                <CommonButton
                  key="reject"
                  label="Reject"
                  secondary
                  variant="outlined"
                  onClick={() => this.handleRejectClickOpen()}
                />,
                <CommonButton
                  key="accept"
                  label="Accept"
                  primary
                  variant="contained"
                  onClick={this.confirmAmendRequestWrapper}
                />,
              ]
            : [
                <CommonButton
                  key="close"
                  label="Close"
                  secondary
                  variant="outlined"
                  onClick={this.props.closeSidebar}
                />,
              ]}
        </div>

        {this.amendable && (
          <div className="status-actions-wrap">
            <RejectionReasonDialog
              open={this.state.rejectDialogOpen.value}
              onClose={this.handleRejectClose}
              title="Reject Amend Request"
              value={this.state.rejectionReason.value}
              onChange={this.handleReasonChange}
              helperText={get(this.state, 'rejectionReason.errors[0]', '')}
              onCancel={this.handleRejectClose}
              onReject={this.handleRejectSubmit}
            />
          </div>
        )}
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
  selectedFreight: state.companies.freights.selectedFreight};
};

const mapDispatchToProps = dispatch => ({
  confirmAmendRequest: (movementId, reload) =>
    dispatch(confirmMovementAmend(movementId, null, reload)),
  rejectAmendRequest: (movementId, data, inActivatedUserToken, reload) => dispatch(
    rejectMovementAmend(movementId, data, inActivatedUserToken, reload)
  ),
  isLoading: (component) => dispatch(isLoading(component)),
  getSelectedFreight: id =>
    dispatch(
      getSelectedFreight(id, receiveFreightContract, false, false, false, false, false),
    ),
});

export default withStyles(styles)(
  connect(mapStateToProps, mapDispatchToProps)(EditMovementReview),
);

import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import '../common/subTab.scss';
import Companies from "./Companies";
import CompanyGroups from "./CompanyGroups";

import { includes } from 'lodash';

class CompaniesTab extends React.Component {
  constructor(props) {
    super(props);

    this.COMPANY_DIRECTORY = '/companies';
    this.COMPANY_GROUPS = '/companies/groups';

    this.state = {
      value: this.props.location.pathname,
    };
    this.parentURL = this.getParentURL();
    this.setValueBasedOnPath = this.setValueBasedOnPath.bind(this);
  }

  getParentURL() {
    let url = this.props.location.pathname;
    if(includes(this.props.location.pathname, this.COMPANY_DIRECTORY))
      return url.split(this.COMPANY_DIRECTORY)[0];
    if(includes(this.props.location.pathname, this.COMPANY_GROUPS)) {
      return url.split(this.COMPANY_GROUPS)[0]; }
  }

  componentDidUpdate() {
    setTimeout(this.setValueBasedOnPath, 500);
  }

  setValueBasedOnPath() {
    const currentUrl = `${this.props.location.pathname}`;
    if(this.state.value !== currentUrl)
      this.setState({value: currentUrl});
  }

  tabChanges = (event, value) => {
    this.setState({ value });
    window.location.hash = `${value}`;
    this.props.location.pathname = `${value}`;
  }

  render() {
    const { value } = this.state;
    return (
      <div className="subTab">
        <Tabs className="subTab-header" value={value} indicatorColor="primary" onChange={this.tabChanges}>
          <Tab value={`${this.COMPANY_DIRECTORY}`} href={`#${this.COMPANY_DIRECTORY}`} label="Directory" className={value !== `${this.COMPANY_DIRECTORY}` ? 'unselected-subtab' : ''} />
          <Tab value={`${this.COMPANY_GROUPS}`} href={`#${this.COMPANY_GROUPS}`} label="Groups" className={value !== `${this.COMPANY_GROUPS}` ? 'unselected-subtab' : ''} />
        </Tabs>
        {
          value === this.COMPANY_DIRECTORY && <Companies {...this.props} />
        }
        {
          value === this.COMPANY_GROUPS && <CompanyGroups {...this.props} />
        }
        
      </div>
    );
  }
}

export default CompaniesTab;
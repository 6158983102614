import React, { Component } from 'react';
import { connect } from 'react-redux';

import forEach from 'lodash/forEach';
import some from 'lodash/some';
import { required } from '../../common/validators';
import isEmpty from 'lodash/isEmpty';
import CommonButton from '../common/CommonButton';
import { positiveDecimalFilter } from '../../common/input-filters';
import InputAdornment from '@mui/material/InputAdornment/InputAdornment';
import mapValues from 'lodash/mapValues';
import get from 'lodash/get';
import set from 'lodash/set';
import has from 'lodash/has';
import every from 'lodash/every';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import CommonDatePicker from '../common/CommonDatePicker';
import CommonTextField from '../common/CommonTextField';
import FileUpload from '../common/FileUpload';
import filter from 'lodash/filter';
import APIService from '../../services/APIService';
import alertifyjs from 'alertifyjs';
import { ADD_INVOICE_PAYMENT, getInvoicePaymentSummary, getInvoicePayments } from '../../actions/companies/invoices';
import max from 'lodash/max';
import { formatPrice } from '../../common/utils';
import LoaderInline from '../LoaderInline';
import CustomEmailDialog from '../common/CustomEmailDialog';

class AddPaymentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serverErrors: [],
      fields: {
        amount: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        amountPaidBy: {
          value: 'Balance Amount Payable',
          validators: [required()],
          errors: [],
        },
        paymentDate: {
          value: undefined,
          validators: [required()],
          errors: [],
        },
        paymentDetails: {
          value: undefined,
          validators: [],
          errors: [],
        },
        attachments: {
          value: [],
          validators: [],
          errors: [],
        },
      },
      showCustomEmailDialog: false,
      isDisabledClick: false,
      isSubmittingForm: false,
      sellerContacts: [],
      buyerContacts: [],
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.handleAmountPaidBy = this.handleAmountPaidBy.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleFileRemove = this.handleFileRemove.bind(this);
    this.addPayment = this.addPayment.bind(this);
    this.setServerErrors = this.setServerErrors.bind(this);
    this.closeCustomEmailDialog = this.closeCustomEmailDialog.bind(this);
    this.formRef = React.createRef();
  }

  componentDidMount() {
    const { invoice } = this.props;
    if (invoice) {
      let sellerCompanyId = get(this.props, 'invoice.payee.companyId');
      let buyerCompanyId = get(this.props, 'invoice.payer.companyId');
      this.getContacts(sellerCompanyId, buyerCompanyId)
      this.setState({ fields: { ...this.state.fields, amount: { ...this.state.fields.amount, value: invoice.amountPayable } } });
    }
  }

  async getContacts(sellerCompanyId, buyerCompanyId) {
    let sellerContacts = await APIService.contracts().companies(sellerCompanyId).contacts().get();
    let buyerContacts = await APIService.contracts().companies(buyerCompanyId).contacts().get();
    this.setState({sellerContacts: sellerContacts, buyerContacts: buyerContacts});
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    newState.serverErrors = [];
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  valid(fields) {
    return every(fields, field => {
      if (has(field, 'errors')) return field.errors.length === 0;
      else return true;
    });
  }

  openCustomEmailDialog() {
    this.setState({ showCustomEmailDialog: true });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setAllFieldsErrors(() => {
      const isInvalid = some(this.state.fields, field => {
        return !isEmpty(field.errors);
      });
      if (!isInvalid) {
        if (get(this.props, 'invoice.isCommodityContractInvoice')) {
          this.openCustomEmailDialog();
        }
        else {
          const data = mapValues(this.state.fields, field => {
            return field.value;
          });
          this.setState({ isDisabledClick: true, isSubmittingForm: true }, () => {
            this.addPayment(data);
          });
        }
      }
    });
  }

  addPayment(data) {
    if(!this.props.invoice?.id)
      return;
    APIService.invoices(this.props.invoice.id)
      ['invoice-payments']()
      .post(data, this.props.token)
      .then(response => {
        if (!isEmpty(response.errors)) {
          this.setServerErrors(response.errors);
        } else {
          if(this.props.fromListing)
            alertifyjs.success(ADD_INVOICE_PAYMENT, 2, () => window.location.reload());
          else {
            const { dispatch, dispatchInvoicePayments, dispatchInvoiceDetails } = this.props;
            if (dispatchInvoiceDetails) {
              dispatch(getInvoicePaymentSummary(this.props.invoice.id));
            }
            if (dispatchInvoicePayments) {
              dispatch(getInvoicePayments(this.props.invoice.id));
            }
            this.setState({ isSubmittingForm: false });
            this.props.onClose();
          }
        }
      });
  }

  setServerErrors(errors) {
    const newState = { ...this.state };
    newState.serverErrors = errors;
    newState.isDisabledClick = false;
    newState.isSubmittingForm = false;
    forEach(errors, (value, key) => {
      if (has(newState.fields, key)) {
        newState.fields[key].errors = value;
      }
    });
    this.setState(newState);
  }

  setFieldValue(key, value, setErrors = true) {
    const newState = { ...this.state };
    set(newState.fields, key + '.value', value);
    this.setState(newState, () => {
      if (setErrors) {
        this.setFieldErrors(key);
      }
    });
  }

  getFieldErrors(key) {
    const errors = [];
    const value = get(this.state.fields, `${key}.value`);
    const validators = get(this.state.fields, `${key}.validators`) || [];
    validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });
    return errors;
  }

  setFieldErrors(key) {
    const newState = { ...this.state };
    set(newState.fields, key + '.errors', this.getFieldErrors(key));
    this.setState(newState);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleAmountPaidBy(event) {
    const newState = { ...this.state };
    const value = event.target.value;
    const { invoice } = this.props;
    newState.fields.amountPaidBy.value = value;
    if (value === 'Balance Amount Payable') newState.fields.amount.value = invoice.amountPayable;
    else newState.fields.amount.value = '';

    this.setState(newState);
  }

  handleDateChange(value, id) {
    this.setFieldValue(id, value);
  }

  handleUpload(fileState) {
    const newState = { ...this.state };
    newState.fields.attachments.value.push(fileState.file);
    this.setState(newState);
  }

  handleFileRemove(base64) {
    const newState = { ...this.state };
    newState.fields.attachments.value = filter(newState.fields.attachments.value, a => {
      return a.base64 !== base64;
    });
    this.setState(newState);
  }

  getSelectedParties = () => {
    return ['buyer', 'seller'];
  };

  getPartyEmails() {
    let sellerContactId = get(this.props, 'invoice.payee.contactId');
    let buyerContactId = get(this.props, 'invoice.payee.contactId');
    return {
      seller: get(find(this.state.sellerContacts, { id: sellerContactId }), 'email'),
      buyer: get(find(this.state.buyerContacts, { id: buyerContactId }), 'email'),
    };
  }

  getPartyContacts() {
    return {
      seller: this.state.sellerContacts,
      buyer: this.state.buyerContacts,
    };
  }

  getEmailSubject() {
    let invoice = get(this.props, 'invoice');
    return get(invoice, 'payerDisplayName', '') + " Paid Commodity Contract " + get(invoice, 'contractInvoicing', '') + ' #' + get(invoice, 'identifier');
  }

  getFooterNote() {
    return 'Invoice PDF will be automatically sent as part of the email';
  }

  closeCustomEmailDialog(communicationData, justClose) {
    if (justClose)
      this.setState({showCustomEmailDialog: false});
    else if (this.state.showCustomEmailDialog) {
      const data = mapValues(this.state.fields, field => {
        return field.value;
      });
      if (communicationData) {
        data['communication'] = communicationData;
      }
      this.setState({ showCustomEmailDialog: false, isDisabledClick: true, isSubmittingForm: true }, () => {
        this.addPayment(data);
      });
    }
  }

  render() {
    const amountPayable = parseFloat(get(this.props, 'invoice.amountPayable', 0)).toFixed(2);
    const currentAmountPaid = this.state.fields.amount.value ? parseFloat(this.state.fields.amount.value).toFixed(2) : 0.0;
    const invoiceTotal = parseFloat(get(this.props, 'invoice.totalDetails.total', 0)).toFixed(2);
    const amountPaid = parseFloat(get(this.props, 'invoice.amountPaid', 0)).toFixed(2);
    const hasAttachments = get(this.state.fields.attachments.value, '0.base64');
    const balanceAmount = max([amountPayable - currentAmountPaid, 0.0]);
    const currency = get(this.props.invoice, 'currency');
    return (
      <div ref={this.formRef}>
        {this.state.isSubmittingForm ? (
          <LoaderInline style={{ height: '500px' }} containerClassName='inline-loader-container' imageStyle={{ width: '25%' }} />
        ) : (
          <React.Fragment>
            <form onSubmit={this.handleSubmit} noValidate>
              <div className='cardForm cardForm--drawer' id='invoice-add-payment-form'>
                <div className='cardForm-content invoiced-amount-wrapper'>
                  <div className='col-sm-12 padding-reset'>
                    <div className='col-sm-4 invoiced-amount-container'>
                      <label className='invoiced-amount-label'>Invoice Amount</label>
                      <p className='invoiced-amount'>{formatPrice(invoiceTotal, `${currency} 0.00`, currency)}</p>
                    </div>
                    <div className='col-sm-4 invoiced-amount-container'>
                      <label className='invoiced-amount-label'>Invoice Paid</label>
                      <p className='invoiced-amount'>{formatPrice(amountPaid, `${currency} 0.00`, currency)}</p>
                    </div>
                    <div className='col-sm-4 invoiced-amount-container'>
                      <label className='invoiced-amount-label'>Amount Payable</label>
                      <p className='invoiced-amount'>{formatPrice(amountPayable, `${currency} 0.00`, currency)}</p>
                    </div>
                  </div>
                </div>
                <div className='cardForm-content row'>
                  <div className='col-sm-12 form-wrap-70'>
                    <span className='sub-heading'>Amount Paid</span>
                    <RadioGroup
                      id='amountOption'
                      value={this.state.fields.amountPaidBy.value}
                      onChange={this.handleAmountPaidBy}
                      className='flex-direction-row'
                    >
                      <FormControlLabel
                        value='Balance Amount Payable'
                        control={<Radio color='primary' />}
                        label='Balance Amount Payable'
                        labelPlacement='end'
                      />
                      <FormControlLabel value='Other amount' control={<Radio color='primary' />} label='Other amount' labelPlacement='end' />
                    </RadioGroup>
                  </div>
                  <div className='col-sm-12 form-wrap-70'>
                    <CommonTextField
                      error={!isEmpty(this.state.fields.amount.errors[0])}
                      id='amount'
                      label={this.state.fields.amountPaidBy.value}
                      placeholder={this.state.fields.amountPaidBy.value}
                      value={this.state.fields.amount.value || ''}
                      fullWidth
                      helperText={this.state.fields.amountPaidBy.value !== 'Balance Amount Payable' && this.state.fields.amount.errors[0]}
                      onKeyDown={event => positiveDecimalFilter(event, 2, 9999999999.99)}
                      onChange={this.handleFieldChange}
                      onBlur={this.onFieldBlur}
                      disabled={this.state.fields.amountPaidBy.value === 'Balance Amount Payable'}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start' style={{ color: 'rgb(162,162,162)' }}>
                            {this.props.invoice?.currency}
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap-70'>
                    <CommonDatePicker
                      id='paymentDate'
                      floatingLabelText='Payment Date'
                      errorText={this.state.fields.paymentDate.errors[0]}
                      value={this.state.fields.paymentDate.value}
                      onChange={this.handleDateChange}
                      onBlur={this.onFieldBlur}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap-70'>
                    <CommonTextField
                      id='paymentDetails'
                      label='Payment Details'
                      placeholder='Add bank details, transaction details (Optional)'
                      value={this.state.fields.paymentDetails.value}
                      onChange={this.handleFieldChange}
                      fullWidth
                      maxLength='255'
                      multiline
                      rows={7}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap-70' style={{ padding: hasAttachments ? '0px' : '25px 0px 0px 0px' }}>
                    <FileUpload
                      id='attachments'
                      floatingLabelText='Attach File'
                      fullWidth={true}
                      textFieldstyle={{ float: 'left', color: 'black' }}
                      buttonStyle={{ border: '1px solid' }}
                      onChange={this.handleUpload}
                      buttonText='Add Attachment'
                      allowedExtensions='image/*,application/pdf'
                      previewStyle={{ marginBottom: '10px' }}
                      onRemove={this.handleFileRemove}
                      fileCount={hasAttachments ? 2 : 0}
                    />
                  </div>
                  <div className='col-sm-12 form-wrap-70 payment-summary'>
                    <span className='sub-heading'>Payment Summary</span>
                    <div className='row padding-reset'>
                      <div className='col-sm-6' align='left'>
                        Amount Payable
                      </div>
                      <div className='col-sm-6 payment-summary-value' align='right'>
                        {formatPrice(amountPayable, `${currency} 0.00`, currency)}
                      </div>
                    </div>
                    <div className='row padding-reset payment-summary'>
                      <div className='col-sm-6' align='left'>
                        Amount Paid
                      </div>
                      <div className='col-sm-6 payment-summary-value' align='right'>
                        {formatPrice(currentAmountPaid, `${currency} 0.00`, currency)}
                      </div>
                    </div>
                    <div className='row padding-reset payment-summary'>
                      <div className='col-sm-6' align='left'>
                        Balance Amount
                      </div>
                      <div className='col-sm-6 payment-summary-value' align='right'>
                        {formatPrice(balanceAmount, `${currency} 0.00`, currency)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-sm-12 cardForm-action top15 padding-reset'>
                  <CommonButton variant='outlined' label='Cancel' type='button' default onClick={this.props.onClose} />
                  <CommonButton
                    disabled={this.state.isDisabledClick}
                    variant="contained"
                    primary={true}
                    label={this.state.isDisabledClick ? 'Saving...' : 'Save'}
                    type='submit'
                    className='margin-right-0'
                  />
                </div>
              </div>
            </form>
            {
              this.state.showCustomEmailDialog &&
              <CustomEmailDialog
                parties={['buyer', 'seller']}
                selectedParties={this.getSelectedParties()}
                title='Email PDF copies to'
                partyEmails={this.getPartyEmails()}
                partyContacts={this.getPartyContacts()}
                subject={this.getEmailSubject()}
                noBody={true}
                footer={this.getFooterNote()}
                open={this.state.showCustomEmailDialog}
                onClose={this.closeCustomEmailDialog}
              />
            }
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.main.user.token,
  };
};

export default connect(mapStateToProps)(AddPaymentForm);

import React from 'react';
import { useState } from 'react';
import CloudDownload from '@mui/icons-material/CloudDownload';
import { Button, Menu, MenuItem, Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import get from 'lodash/get';
import { AppContext } from '../main/LayoutContext';

const CommonListingButton = props => {
  const [showOptions, setShowOptions] = useState(false);
  const { isMobileDevice } = React.useContext(AppContext)

  const handleOptionClick = val => {
    val.fx();
    setShowOptions(false);
  };

  return isMobileDevice ? <React.Fragment /> : (
    <span>
      <Tooltip title={props.title} placement="top">
        <Button
          variant={props.variant || "contained"}
          type="button"
          onClick={props.showMenus ? (e) => setShowOptions(e.currentTarget): props.defaultHandler }
          color={props.color || "primary"}
          className='add-button common-listing-button'
          style= {get(props, 'style', { float: 'right', marginLeft: '10px' })}
          size={props.size || 'medium'}
        >
          {
            get(props, 'showDownLoadIcon', true) &&
            <CloudDownload style={{ paddingRight: '5px' }} />
          }
          { props.name }
          { props.showMenus && <MoreVertIcon /> }
        </Button>
      </Tooltip>
      {
        Boolean(showOptions) &&
        <Menu
          id="actions-menu"
          anchorEl={showOptions}
          open={Boolean(showOptions)}
          onClose={() => setShowOptions(false)}
          >
          {
            get(props, 'optionMapper', []).map((val, index) => (
              get(val, 'enableOption', true) &&
              get(val, 'name') &&
              <MenuItem key={index} onClick={() => handleOptionClick(val)}>
                {val.name}
              </MenuItem>
            ))
          }
        </Menu>
      }
    </span>
  );
};

export default CommonListingButton;

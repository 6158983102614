import React from 'react';
import alertifyjs from 'alertifyjs';
import { CircularProgress } from '@mui/material'
import { get } from 'lodash';
import APIService from '../../services/APIService'

class XeroCallback extends React.Component {
  constructor(props) {
    super(props);
    this.state = { code: null, response: null, companyId: null }
  }

  componentDidMount() {
    this.handshake()
  }

  handshake = () => {
    const params = new URLSearchParams(this.props.location.search)
    const code = params.get('code')
    const state = params.get('state')
    if(code && state) this.setState({code: code, companyId: state}, this.exchange)
  }

  exchange = () => APIService
    .companies(this.state.companyId)
    .appendToUrl('xero/exchange-code/')
    .post({
      code: this.state.code,
      redirect_uri: window.location.origin + '/xero/callback'
    })
    .then(response => this.setState(
      {response: response},
      () => {
        alertifyjs.success(
          'Successfully connected Xero.',
          1,
          () => window.location.hash = `/companies/${this.state.companyId}/integrations`
        )
      }))

  copyToken = () => navigator.clipboard.writeText(this.state.response.accessToken)

  render() {
    const isSuccess = get(this.state.response, 'accessToken') && get(this.state.response, 'refreshToken')
    return (
      <div>
        {
          this.state.response ? (
            isSuccess ?
              <div><CircularProgress /></div> :
            <div>
              <pre style={{overflow: 'auto'}}>
                {JSON.stringify(this.state.response, undefined, 4)}
              </pre>
            </div>
          ) :
          <div><CircularProgress /></div>
        }
      </div>
    )
  }
}

export default XeroCallback;

import React from 'react';



const RightTick = ({width, height, className, strokeWidth, ...restProps}) => (
  <svg {...restProps} className={className} width={width || "32"} height={height || "40"} viewBox="0 0 110 110">
    <g fill="none" fillRule="evenodd">
      <circle cx="55" cy="55" r="51" fill="#FFF" fillRule="nonzero" stroke="#6AAE20" strokeWidth={strokeWidth || "8"}/>
      <path fill="#6AAE20" d="M77.587 32.562l-32.23 32.244L33.553 53a5.567 5.567 0 0 0-7.857 0c-2.18 2.179-2.18 5.706 0 7.87l15.726 15.738a5.552 5.552 0 0 0 3.933 1.624 5.586 5.586 0 0 0 3.937-1.624l36.164-36.176a5.554 5.554 0 0 0 0-7.87c-2.167-2.166-5.69-2.166-7.87 0"/>
    </g>
  </svg>
);

export default RightTick;

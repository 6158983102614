import React from 'react';
import { connect } from 'react-redux';
import { mapValues, set, forEach, cloneDeep, some, filter, get, isEmpty, uniqBy } from 'lodash';
import CommonAutoSelect from '../../common/autocomplete/CommonAutoSelect';
import { FIELD, REQUIRED_FIELD } from '../../../common/constants';
import APIService from '../../../services/APIService';
import { getCompanyCompaniesMinimal } from '../../../actions/api/companies';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

class CounterPartiesInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sellerItem: [],
      buyerItem: [],
      sellerNgrs: null,
      buyerNgrs: null,
      sellerContacts: null,
      buyerContacts: null,
      sellerRepresenting: false,
      buyerRepresenting: false,
      fields: {
        sellerDetails: {
          companyId: { ...cloneDeep(REQUIRED_FIELD) },
          contactId: { ...cloneDeep(REQUIRED_FIELD) },
          ngrId: { ...cloneDeep(FIELD) },
          representedById: { ...cloneDeep(FIELD) }
        },
        buyerDetails: {
          companyId: { ...cloneDeep(REQUIRED_FIELD) },
          contactId: { ...cloneDeep(REQUIRED_FIELD) },
          ngrId: { ...cloneDeep(FIELD) },
          representedById: { ...cloneDeep(FIELD) }
        }
      },
      sellerParties: [],
      buyerParties: [],
    };
    this.__setParties = this.__setParties.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleValueChange = this.handleValueChange.bind(this);
    this.setDefaultValues = this.setDefaultValues.bind(this);
    this.handleRepresentationChange = this.handleRepresentationChange.bind(this);
  }

  componentDidMount() {
    const { defaultSelectedEntity, isNotIndependent } = this.props;
    if (this.props.unregToken) {
      APIService.profiles().appendToUrl('details/').get(this.props.unregToken)
      .then( details => {
        if(details.data)
        this.props.dispatch(getCompanyCompaniesMinimal(details.data.companyId, { include_parent_company: true }, this.props.unregToken));
      });
    }
    else
      this.props.dispatch(getCompanyCompaniesMinimal(this.props.companyId, { include_parent_company: true }));
    this.__setParties();
    if(!isNotIndependent && defaultSelectedEntity){
      this.setDefaultValues();
    }
  }

  componentDidUpdate(prevProps) {
    const { validate, isNotIndependent, selectedTemplate } = this.props;
    if (!prevProps.validate && validate) {
      this.setAllFieldsErrors();
    }
    this.__setParties();
    if(!isNotIndependent && (prevProps.selectedTemplate != selectedTemplate)){
      this.setDefaultValues();
    }
  }

  hasErrors() {
    const { fields } = this.state;
    return some(fields, field => some(field, obj => obj.errors.length > 0));
  }

  __setParties() {
    const { allCompanyParties } = this.props;
    if (
      (isEmpty(this.state.sellerParties) || isEmpty(this.state.buyerParties)) &&
      !isEmpty(allCompanyParties)
    )
      this.setState({ sellerParties: allCompanyParties, buyerParties: allCompanyParties });
  }

  setDefaultValues(){
    const { defaultSelectedEntity } = this.props;
    const newState = { ...this.state };
    if (defaultSelectedEntity){
      set(newState.fields, 'sellerDetails.companyId.value', get(defaultSelectedEntity, 'seller.companyId'));
      set(newState.fields, 'buyerDetails.companyId.value', get(defaultSelectedEntity, 'buyer.companyId'));
      this.setState({...newState, flag:true});}
  }

  setAllFieldsErrors() {
    const newState = { ...this.state };

    this.applyValidatorsOn(newState.fields.buyerDetails, 'buyerDetails');
    this.applyValidatorsOn(newState.fields.sellerDetails, 'sellerDetails');

    this.setState(newState, this.propagateChanges);

  }

  applyValidatorsOn(fields, val) {
    forEach(fields, field => {
      this.getFieldErrors(field, field.value, val);
    });
  }

  handleValueChange(value, fieldId, item) {
    const newState = { ...this.state };
    const { defaultSelectedEntity } = this.props;
    set(newState.fields, `${fieldId}.value`, value);
    let buyer = fieldId === 'buyerDetails.companyId';
    let seller = fieldId === 'sellerDetails.companyId';
    if ((seller || buyer) ) {
      if (value) {
        APIService.contracts().companies(value).contacts().get(this.props.unregToken)
          .then(contacts => {
            if (seller)
              this.setState({ sellerContacts: uniqBy(contacts, 'id') });
            else if (buyer)
              this.setState({ buyerContacts: uniqBy(contacts, 'id') });

            APIService.companies(value).ngrs().appendToUrl('minimal/')
              .get(this.props.unregToken).then(items => {
                if (seller)
                  this.setState({ sellerNgrs: uniqBy(items, 'id') });
                else if (buyer)
                  this.setState({ buyerNgrs: uniqBy(items, 'id') });
              }
              );
            if (defaultSelectedEntity && this.state.flag) {
              set(newState.fields, 'sellerDetails.contactId.value', get(defaultSelectedEntity, 'seller.contactId'));
              set(newState.fields, 'sellerDetails.ngrId.value', get(defaultSelectedEntity, 'seller.ngrId'));
              set(newState.fields, 'buyerDetails.contactId.value', get(defaultSelectedEntity, 'buyer.contactId'));
              set(newState.fields, 'buyerDetails.ngrId.value', get(defaultSelectedEntity, 'buyer.ngrId'));
              this.setState({flag:false});
            }
          });
      }

      if (seller && !defaultSelectedEntity) {
          set(newState, 'sellerItem[0]', item);
          set(newState.fields, 'sellerDetails.contactId.value', null);
          set(newState, 'sellerContacts', null);
          set(newState.fields, 'sellerDetails.ngrId.value', null);
          set(newState, 'sellerNgrs', null);
      }
      if (buyer && !defaultSelectedEntity) {
          set(newState, 'buyerItem[0]', item);
          set(newState.fields, 'buyerDetails.contactId.value', null);
          set(newState, 'buyerContacts', null);
          set(newState.fields, 'buyerDetails.ngrId.value', null);
          set(newState, 'buyerNgrs', null);
      }
    };
    this.setFieldErrors(fieldId, value);
    this.setState(newState, () => this.propagateChanges());
  }

  propagateChanges() {
    this.props.onChange(mapValues(this.state.fields.sellerDetails, 'value'), mapValues(this.state.fields.buyerDetails, 'value'),
      { party: this.hasErrors()}, {buyerContacts: this.state.buyerContacts, sellerContacts: this.state.sellerContacts },
      [this.state.sellerItem[0], this.state.buyerItem[0]], {buyerNgrs: this.state.buyerNgrs, sellerNgrs: this.state.sellerNgrs });
  }

  setFieldErrors(id, value) {
    {
      const newState = { ...this.state.fields };
      this.getFieldErrors(get(newState, id), value);
      this.setState(newState);
    }
  }

  getFieldErrors(field, value) {
    field.validators.forEach(validator => {
      if (validator.isInvalid(value)) {
        field.errors.push(validator.message);
      }
      else {
        field.errors = [];
      }
    });
  }

  handleRepresentationChange = party => event => {
    const newState = { ...this.state };
    if (party === 'seller') {
      if (this.state.fields.sellerDetails) {
        if (event.target.checked) {
          set(newState, 'fields.sellerDetails.representedById.value', this.props.userCompanyId);
          set(newState, 'sellerRepresenting', true);
          if (this.props.orderType === 3) {
            set(newState, 'buyerRepresenting', null);
            set(newState, 'fields.buyerDetails.representedById.value', null);
          }
        }
        else {
          set(newState, 'fields.sellerDetails.representedById.value', null);
          set(newState, 'sellerRepresenting', false);
        }
      }
    } else if (party === 'buyer') {
      if (this.state.fields.buyerDetails) {
        if (event.target.checked) {
          set(newState, 'fields.buyerDetails.representedById.value', this.props.userCompanyId);
          set(newState, 'buyerRepresenting', true);
          if (this.props.orderType === 3) {
            set(newState, 'sellerRepresenting', false);
            set(newState, 'fields.sellerDetails.representedById.value', null);
          }
        }
        else {
          set(newState, 'fields.buyerDetails.representedById.value', null);
          set(newState, 'buyerRepresenting', false);
        }
      }
    }
    this.setState(newState, () => this.propagateChanges());
  };

  render() {
    const { fieldRef, title, canRepresent, editForm } = this.props;
    return (
      <div className='cardForm'>
        <h4 className='cardForm-title'>{title ? title : 'Counterparties'}</h4>
        <div className="cardForm-content col-md-5">
          <div className="col-md-12 form-wrap padding-reset">
            <CommonAutoSelect
              items={filter(this.state.sellerParties, obj => { return obj.id != this.state.fields.buyerDetails.companyId.value; })}
              label="Seller Name"
              setRef={fieldRef["sellerDetails.companyId"]}
              id="sellerDetails.companyId"
              value={this.state.fields.sellerDetails.companyId.value}
              onChange={this.handleValueChange}
              errorText={this.state.fields.sellerDetails.companyId.errors[0]}
              disabled={editForm}
            />
          </div>
          {
            canRepresent &&
            <FormControlLabel
              control={<Checkbox color="primary" value="seller" checked={this.state.sellerRepresenting} onChange={this.handleRepresentationChange('seller')} />}
              label="Representing"
            />
          }
          <div className="col-md-12 form-wrap padding-reset">
            <CommonAutoSelect
              items={this.state.sellerContacts}
              setRef={fieldRef["sellerDetails.contactId"]}
              id="sellerDetails.contactId"
              label="Seller Contact"
              value={this.state.fields.sellerDetails.contactId.value}
              onChange={this.handleValueChange}
              errorText={this.state.fields.sellerDetails.contactId.errors[0]}
            />
          </div>
          <div className="col-md-12 form-wrap padding-reset">
            <CommonAutoSelect
              items={this.state.sellerNgrs || []}
              dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
              id="sellerDetails.ngrId"
              label="Seller NGR (Optional)"
              value={this.state.fields.sellerDetails.ngrId.value}
              onChange={this.handleValueChange}
              dontAutoselectSingleItem={true}
            />
          </div>
        </div>
        <div className="cardForm-content col-md-5 col-md-offset-1">
          <div className="col-md-12 form-wrap padding-reset">
            <CommonAutoSelect
              items={filter(this.state.buyerParties, obj => { return obj.id != this.state.fields.sellerDetails.companyId.value; })}
              setRef={fieldRef["buyerDetails.companyId"]}
              id="buyerDetails.companyId"
              label="Buyer Name"
              value={this.state.fields.buyerDetails.companyId.value}
              onChange={this.handleValueChange}
              errorText={this.state.fields.buyerDetails.companyId.errors[0]}
              disabled={editForm}
            />
          </div>
          {
            canRepresent &&
            <FormControlLabel
              control={<Checkbox color="primary" value="buyer" checked={this.state.buyerRepresenting} onChange={this.handleRepresentationChange('buyer')} />}
              label="Representing"
            />
          }
          <div className="col-md-12 form-wrap padding-reset">
            <CommonAutoSelect
              items={this.state.buyerContacts}
              setRef={fieldRef["buyerDetails.contactId"]}
              id="buyerDetails.contactId"
              label="Buyer Contact"
              value={this.state.fields.buyerDetails.contactId.value}
              onChange={this.handleValueChange}
              errorText={this.state.fields.buyerDetails.contactId.errors[0]}
            />
          </div>
          <div className="col-md-12 form-wrap padding-reset">
            <CommonAutoSelect
              items={this.state.buyerNgrs || []}
              id="buyerDetails.ngrId"
              dataSourceConfig={{ text: 'ngrNumber', value: 'id' }}
              label="Buyer NGR (Optional)"
              value={this.state.fields.buyerDetails.ngrId.value}
              onChange={this.handleValueChange}
              dontAutoselectSingleItem={true}
            />
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    token: state.main.user.token,
    allCompanyParties: state.companies.companies.companyParties,
    companyId: get(state.main, 'user.user.companyId')
  };
};

export default connect(mapStateToProps)(CounterPartiesInfo);
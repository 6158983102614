import React from 'react';
import { connect } from 'react-redux';
import CashPricesTable from '../../containers/CashPricesTable';
import Paper from '@mui/material/Paper';
import SideDrawer from '../common/SideDrawer';
import AddPriceDialog from './AddPriceDialog';
import { editSelectedCashPrice, setSelectedCashPrice, viewSelectedCashPrice, getSitesActiveCashPrices, sitesActiveCashPricesResponse, canCreateTitleTransferForCashPrice, getCashPrices} from '../../actions/companies/cash-board';
import { TAB } from './constants';
import { isLoading, forceStopLoader } from '../../actions/main';
import Filters from '../common/Filters';
import Tooltip from '@mui/material/Tooltip';
import FilterListIcon from '@mui/icons-material/FilterList';
import CustomEmailDialog from '../common/CustomEmailDialog';
import APIService from '../../services/APIService';
import Button from '@mui/material/Button/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import { DialogTitleWithCloseIcon } from '../common/DialogTitleWithCloseIcon';
import { isEmpty, get, reject, map, includes, forEach, filter, find } from 'lodash';
import { setDownloadBar } from '../../actions/main';
import { defaultViewAction, getSavedHandlers } from '../../common/utils';
import alertifyjs from 'alertifyjs';
import CashStockForm from './CashStockForm';
import CommonListingButton from '../common/CommonListingButton';
class CompanySitesCashPrices extends React.Component {
    constructor(props) {
        super(props);
        this.uploadForm = React.createRef();
        this.state = {
            openCloseAddPriceDialog: false,
            openCloseConfirmTransferDialog: false,
            showSelectPageDialog: false,
            showEmailDialog: false,
            employees: {},
            applyFilters: false,
            openSideDrawer: false,
            filters: {},
            filterValues: {
                commodity__id__in: [],
                grade__id__in: [],
                season__in: [],
                track__in: [],
                buyer__company__id__in: [],
                site__id__in: [],
            },
            redirectLocation: null,
            selectedCashPrices: [],
            bulkInactiveConfirmDialog: false,
        };
        this.openSideDraw = this.openSideDraw.bind(this);
        this.setOpenCloseConfirmTransferDialog = this.setOpenCloseConfirmTransferDialog.bind(this);
    }

    componentDidMount() {
        this.props.getSitesActiveCashPrices('', true);
        APIService.profiles()
            .filters('site_active_cash_price')
            .get(this.props.token)
            .then(res => {
                this.setState({
                    filters: get(res, 'site_active_cash_price', {}),
                });
            });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditSelectedCashPrice !== this.props.isEditSelectedCashPrice)
            this.setState({ openCloseAddPriceDialog: this.props.isEditSelectedCashPrice });
        if (prevProps.isViewSelectedCashPrice !== this.props.isViewSelectedCashPrice)
            this.setState({ openCloseAddPriceDialog: this.props.isViewSelectedCashPrice });
    }

    openSideDraw() {
        this.setState({ openCloseAddPriceDialog: !this.state.openCloseAddPriceDialog });
        this.props.editSelectedCashPrice(false);
        this.props.viewSelectedCashPrice(false);
        this.props.setSelectedCashPrice([]);
    }

    componentWillUnmount() {
        this.props.setCashPricesResponse([]);
        this.props.viewSelectedCashPrice(false);
    }

    handleFilters = bool => {
        this.setState({
            applyFilters: bool,
            openSideDrawer: bool,
        });
    };

    setOpenCloseConfirmTransferDialog(data) {
        APIService.cash_board().appendToUrl(`cash-prices/${this.props.selectedCashPrice.id}/active/check/`).request(
            'GET', data, null,
        ).then(response => {
            if (response.data.result) {
                this.setState({
                    openCloseConfirmTransferDialog: true,
                    data: data,
                });
            }
            else
                alertifyjs.error('Cash Price is not active.');
        });
    }

    async handleEmail() {
        if (this.state.openCloseConfirmTransferDialog) {
            this.props.isLoading();
            let buyerCompanyId = get(this.props, 'selectedCashPrice.buyer.companyId');
            let sellerCompanyId = get(this.state.data, 'seller.companyId');
            let companyQueryString = map({ buyerCompanyId, sellerCompanyId }, id => `company_ids=${id}`).join('&');
            let siteCompanyId = get(this.props, 'selectedCashPrice.siteCompanyId');
            if (siteCompanyId && !includes([buyerCompanyId, sellerCompanyId], siteCompanyId)) {
                companyQueryString += `&company_ids=${siteCompanyId}`;
            }
            await APIService.profiles().appendToUrl(`employees-signature/?${companyQueryString}`).get(
                this.props.token).then(
                    res => {
                      this.setState({ openCloseConfirmTransferDialog: false, showEmailDialog: true, employees: res, redirectLocation: `cash-price/${this.props.selectedCashPrice?.id}/title-transfers` });
                    });
            this.props.forceStopLoader();
        }
        else {
            this.setState({ openCloseConfirmTransferDialog: false, showEmailDialog: false, employees: {}, showSelectPageDialog: false },
                () => { document.location.hash = this.state.redirectLocation; }
            );
        }
    }

    getSelectedParties() {
        const stocksManagement = get(find(getSavedHandlers(), { id: get(this.props.selectedCashPrice, 'siteId') }), 'stocksManagement');
          if (stocksManagement) {
            return ['buyer', 'seller', 'site'];
          }
          return ['buyer', 'seller'];
      }

    getPartyEmails = () => {
        const { employees } = this.state;
        let companyAdminsForSite = get(this.props, 'selectedCashPrice.companyAdminsForSite');
        let data = {
            buyer: get(find(employees, { id: get(this.props, 'selectedCashPrice.buyer.contactId') }), 'email'),
            seller: get(find(employees, { id: get(this.state.data, 'seller.contactId') }), 'email'),
        };
        if (companyAdminsForSite) {
            let sitePartyEmails = [];
            companyAdminsForSite.map(employeeId => {
              let email = get(find(employees, { id: employeeId }), 'email');
                if (email && !isEmpty(email))
                    sitePartyEmails.push(email);
            });
            data['site'] = sitePartyEmails;
        }
        return data;
    };

    async getPartyContacts() {
        const { employees } = this.state;
        const contacts = {};
        const parties = this.getEmailPopupPartiesCompanyIds();
        forEach(['seller', 'buyer', 'site'], party => {
            contacts[party] = filter(employees, { companyId: parties[party] });
        });
        return contacts;
    }

    getEmailPopupPartiesCompanyIds() {
        const { data } = this.state;
        const parties = ['seller', 'buyer', 'site'];
        const ids = {};
        forEach(parties, party => {
            if (party === 'buyer')
                ids.buyer = get(this.props, 'selectedCashPrice.buyer.companyId');
            if (party === 'seller')
                ids.seller = get(data, 'seller.companyId');
            if (party === 'site')
              ids.site = get(find(getSavedHandlers(), { id: get(this.props, 'selectedCashPrice.siteId') }), 'companyId');
        });
        return ids;
    }

    getEmailSubject() {
        const { data } = this.state;
        let seller = get(find(this.props.allCompanyParties, { id: get(data, 'seller.companyId') }), 'name');
        if (data.seller.companyId != this.props.user.companyId)
            return `${this.props.user.company.entityName} A/C ${seller} Title Transfer #${get(data, 'identifier')} (${get(data, 'tonnage')} MT) against cash price (${this.props.selectedCashPrice.siteName} | ${this.props.selectedCashPrice.track} | ${this.props.selectedCashPrice.commodityName} | ${this.props.selectedCashPrice.gradeName} | ${this.props.selectedCashPrice.season}).`;
        return `${seller} Title Transfer #${get(data, 'identifier')} (${get(data, 'tonnage')} MT) against cash price (${this.props.selectedCashPrice.siteName} | ${this.props.selectedCashPrice.track} | ${this.props.selectedCashPrice.commodityName} | ${this.props.selectedCashPrice.gradeName} | ${this.props.selectedCashPrice.season}).`;
    }

    closeEmailDialog = (communicationData, justClose) => {
        if (justClose) {
            this.setState({ showEmailDialog: false, employees: false, data: {} });
        }
        else if (this.state.showEmailDialog) {
            const data = this.state.data;
            this.props.isLoading('alertify');
            if (communicationData) {
                data['communication'] = communicationData;
            }
          this.setState({ showEmailDialog: false }, () => {
            this.props.isLoading('alertify');
            const id = this.props.selectedCashPrice.id
                APIService.cash_board(id).appendToUrl('title-transfers/').request(
                    'POST', data, null,
                ).then(response => {
                    if (response.data) {
                        this.props.closeSideDrawer(false);
                        this.setState({
                            successTransferText: `Title Transfer #${response.data.identifier} completed against the Cash price.
                            Contract has been generated for the same. Do you want to check the Title Transfer or stay on Cash Prices?`,
                          redirectLocation: `cash-price/${id}/title-transfers`,
                            showSelectPageDialog: true,
                            showEmailDialog: false,
                        }, () => {
                            alertifyjs.success('Title Transfer Created');
                            this.props.getCashPrices();
                            this.props.forceStopLoader();
                        });
                    }
                }).catch(res => {
                    alertifyjs.error(get(res, 'response.data.errors', 'An Error Occurred'), 3);
                    this.props.forceStopLoader();
                });
            });
        }
    };

    getDisabledPartiesForEmail() {
        const stocksManagement = get(find(getSavedHandlers(), { id: get(this.props.selectedCashPrice, 'siteId') }), 'stocksManagement');
          if (stocksManagement) {
            return ['buyer', 'site'];
          }
          return [];
    }

    isLaterDisabled() {
        let disabledParties = this.getDisabledPartiesForEmail();
        if (disabledParties.length > 0)
            return true;
        return false;
    }

    handleFilterState = (key, value) => {
        this.setState({ [key]: value }, () => {
        if (key === 'applyFilters') {
            const { filters } = this.state;
            APIService.profiles()
            .filters()
            .post({ site_active_cash_price: filters }, this.props.token)
            .then(res => {
                this.setState({filters: res?.filters?.site_active_cash_price || {}}, () => {
                this.props.isLoading();
                this.props.setCashPricesResponse([]);
                this.props.getSitesActiveCashPrices();
                })
            });
        }
        });
    };

    updateSelectedItems(selectedCashPrice, isChecked) {
        this.setState({selectedCashPrices: isChecked ? [...this.state.selectedCashPrices, selectedCashPrice] : reject(this.state.selectedCashPrices, {id: selectedCashPrice.id})});
    }

    handleDialog(confirmTransfer) {
        if (confirmTransfer) {
            this.setState({ openCloseConfirmTransferDialog: false });
            return;
        }
        this.setState({ showSelectPageDialog: false });
    }

    getActionsOptionMapperListItems() {
      return [defaultViewAction];
    }

    render() {
        const { openCloseConfirmTransferDialog, showSelectPageDialog, showEmailDialog } = this.state;
        return (
            <Paper className='paper-table-paginated'>
                <div>
                    <div>
                        <Tooltip title='Apply filters' placement='top'>
                            <Button
                                value={this.state.applyFilters}
                                variant="contained"
                                type='button'
                                onClick={() => this.handleFilters(true)}
                                color='primary'
                                className='add-button'
                                style={{ float: 'right', marginLeft: '10px' }}
                            >
                                <FilterListIcon style={{ paddingRight: '5px' }} />
                                FILTERS{' '}
                                {+!isEmpty(Object.entries(this.state.filters).filter(val => val[1].length !== 0))
                                    ? `(${Object.entries(this.state.filters).filter(val => val[1].length !== 0).length})`
                                    : ''}
                            </Button>
                        </Tooltip>
                      {this.state.applyFilters && (
                        <SideDrawer isOpen={this.state.openSideDrawer} title='Filters' size='big' onClose={() => this.handleFilters(false)} app='filters'>
                          <Filters
                            isLoading={this.props.isLoading}
                            forceStopLoader={this.props.forceStopLoader}
                            handleFilterState={this.handleFilterState}
                            filters={this.state.filters}
                            tracks={this.props.tracks}
                            filterValues={this.state.filterValues}
                            allCompanies
                          />
                        </SideDrawer>
                      )}
                    </div>
                    <CommonListingButton
                showMenus
                showDownLoadIcon={false}
                optionMapper={this.getActionsOptionMapperListItems()}
                title='Actions'
                name='Actions'
              />
                    <CashPricesTable
                     isAllCashPriceTab={TAB.SITE_ACTIVE_CASH_PRICE}
                     updateSelectedItems={(selected, isChecked) => this.updateSelectedItems(selected, isChecked)}
                    />
                    {
                        this.props.canCreateTTForCashPrice && this.props.selectedCashPrice &&
                        <SideDrawer
                            isOpen={this.props.canCreateTTForCashPrice}
                            title="Cash Stock"
                            onClose={this.props.closeSideDrawer}
                            size='xlarge'>
                            <CashStockForm closeDrawer={this.props.closeSideDrawer} handleConfirmTransferDialog={this.setOpenCloseConfirmTransferDialog} />
                        </SideDrawer>
                    }
                    <Dialog open={openCloseConfirmTransferDialog || showSelectPageDialog} >
                        <DialogTitleWithCloseIcon
                            style={{ marginBottom: '10px', height: '58px', paddingTop: '12px' }}>
                            {openCloseConfirmTransferDialog ? 'Confirm Transfer' : 'Transfer Successful'}
                        </DialogTitleWithCloseIcon>
                        <DialogContent dividers style={{ borderBottom: ' 1px solid #e6e6e6' }}>
                            <DialogContentText>
                                {openCloseConfirmTransferDialog ?
                                    <span>
                                        <div> Please verify all the transfer information is correct before confirming.</div>
                                        <div> By confirming you agree to <a href="#/web/terms-and-conditions" target="_blank" rel="noopener noreferrer">Terms & Conditions</a></div>
                                    </span> :
                                    this.state.successTransferText
                                }
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => this.handleDialog(openCloseConfirmTransferDialog)}>
                                {openCloseConfirmTransferDialog ? 'Cancel' : 'Stay on Cash Prices'}
                            </Button>
                            <Button onClick={() => this.handleEmail()} color="primary">
                                {openCloseConfirmTransferDialog ? 'Confirm' : 'Show Title Transfer'}
                            </Button>
                        </DialogActions>
                    </Dialog>
                    {showEmailDialog &&
                        <CustomEmailDialog
                            parties={['buyer', 'seller', 'site']}
                            selectedParties={this.getSelectedParties()}
                            title="Email PDF copies to"
                            partyEmails={this.getPartyEmails()}
                            partyContacts={this.getPartyContacts()}
                            subject={this.getEmailSubject()}
                            noBody={true}
                            footer={'Title Transfer PDF will be automatically sent as part of the email'}
                            open={showEmailDialog}
                            onClose={this.closeEmailDialog}
                            disableAcceptanceRequired={true}
                            disabledPartiesForEmail={this.getDisabledPartiesForEmail()}
                            disableLater={this.isLaterDisabled()}
                        />
                    }
                    {(this.state.openCloseAddPriceDialog || this.props.isEditSelectedCashPrice || this.props.isViewSelectedCashPrice) &&
                        <SideDrawer
                            isOpen={this.state.openCloseAddPriceDialog}
                            title="Add Price"
                            onClose={this.openSideDraw}
                            size='small'>
                            <AddPriceDialog onClose={this.openSideDraw} isCreate={this.state.openCloseAddPriceDialog} />
                        </SideDrawer>
                    }
                </div>
            </Paper>
        );
    }
}
const mapStateToProps = state => {
    return {
        user: state.main.user.user,
        tracks: state.main.tracks,
        isEditSelectedCashPrice: state.companies.cashBoard.editSelectedCashPrice,
        isViewSelectedCashPrice: state.companies.cashBoard.viewSelectedCashPrice,
        allItems: state.companies.cashBoard.siteActiveCashPrices,
        canCreateTTForCashPrice: state.companies.cashBoard.canCreateTTForCashPrice,
        selectedCashPrice: state.companies.cashBoard.selectedCashPrice,
        allCompanyParties: state.companies.companies.companyParties,
    };
};

const mapDispatchToProps = dispatch => ({
    getSitesActiveCashPrices: (query, loader) => dispatch(getSitesActiveCashPrices(query, loader, null)),
    setCashPricesResponse: () => dispatch(sitesActiveCashPricesResponse([])),
    editSelectedCashPrice: flag => dispatch(editSelectedCashPrice(flag)),
    setSelectedCashPrice: () => dispatch(setSelectedCashPrice([])),
    viewSelectedCashPrice: flag => dispatch(viewSelectedCashPrice(flag)),
    isLoading: (component) => dispatch(isLoading(component)),
    forceStopLoader: () => dispatch(forceStopLoader()),
    setDownloadBar: (message, isOpen, onClose) => dispatch(setDownloadBar(message, isOpen, onClose)),
    closeSideDrawer: () => dispatch(canCreateTitleTransferForCashPrice()),
    getCashPrices: () => dispatch(getCashPrices('', true, null, 'all')),
});

export default connect(mapStateToProps, mapDispatchToProps)(CompanySitesCashPrices);

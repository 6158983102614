import React from 'react';
import { Chip, List, ListItemButton, ListItemText, Avatar, ListItemIcon, Tooltip } from '@mui/material';
import DownIcon from '@mui/icons-material/ArrowDropDown';
import PopperGrow from '../common/PopperGrow';
import { getCurrentCountry, currentUser } from '../../common/utils';
import APIService from '../../services/APIService'

const UNITS = {
  ST: 'Short Tonne',
  BU: 'Bushel',
  LB: 'Pound',
  CWT: 'Hundredweight',
  MT: 'Metric Tonne'
}



const UnitSwitch = ({defaultUnit, units, useCountryUnits, onChange, ...rest}) => {
  const countryConfig = getCurrentCountry()?.config
  const anchorRef = React.useRef(null);
  const user = currentUser()
  const [selected, setSelected] = React.useState(user?.unit)
  const [open, setOpen] = React.useState(false);
  const getUnits = () => useCountryUnits ? countryConfig?.units : units

  const _onChange = value => {
    setSelected(value)
    APIService.profiles(user.id).put({unit: value}).then(() => {
      localStorage.setItem('user', JSON.stringify({...user, unit: value}))
      if(onChange)
        onChange(value)
      else
        window.location.reload()
    })
    setOpen(false)
  }

  const toggleMenu = () => setOpen(!open)

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target))
      return;

    setOpen(false);
  };

  React.useEffect(() => defaultUnit && setSelected(defaultUnit), [defaultUnit])

  const __units = getUnits()

  return (countryConfig?.showConversions && __units) ? (
    <React.Fragment>
      <Tooltip title={`${UNITS[selected] || selected} (${selected})`}>
        <Chip
          id='unit-switch'
          clickable
          color='secondary'
          variant='outlined'
          label={<span style={{marginTop: '1px'}}>{UNITS[selected] || selected}</span>}
          avatar={<Avatar>{selected}</Avatar>}
          deleteIcon={<DownIcon fontSize='inherit' />}
          onDelete={toggleMenu}
          onClick={toggleMenu}
          ref={anchorRef}
          {...rest}
        />
      </Tooltip>
      <PopperGrow open={open} anchorRef={anchorRef} handleClose={handleClose} minWidth="100px">
        <List>
          {
            __units.map(unit => (
              <ListItemButton selected={unit === selected} key={unit} onClick={() => _onChange(unit)}>
                <ListItemIcon>
                  {unit}
                </ListItemIcon>
                <ListItemText primary={UNITS[unit] || unit} />
              </ListItemButton>
            ))
          }
        </List>
      </PopperGrow>
    </React.Fragment>
  ) : <React.Fragment />
}

export default UnitSwitch;

import React from 'react';

import Chip from '@mui/material/Chip';
import merge from 'lodash/merge';

export const ChipLabel = props => {
  const { label, variant, color, style, size } = props;
  return (
    <Chip
      label={label}
      variant={variant || 'outlined'}
      style={merge({fontSize: '11px', margin: '1px'}, style || {})}
      color={color || 'secondary'}
      className="chip-label"
      size={size}
    />
  );
};

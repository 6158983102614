import React from 'react';
import { FormControlLabel, Tooltip, RadioGroup, Radio } from '@mui/material';
import {SEARCH_BY_FREIGHT_PROVIDER_REGOS, SEARCH_BY_ALL_REGOS, SEARCH_BY_TRUCK_OWNER_REGOS} from '../../common/constants';

const TruckSearchOption = props =>  {
    return (
        <React.Fragment>
            <div style={{ marginRight: '10px', marginTop: '8px' }}>{props.label}:</div>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <RadioGroup
                    id={props.id}
                    value={props.value}
                    onChange={(event) => props.handleSearchOptionChange(props.id, event.target.value, event)}
                    style={{ display: 'flex', flexDirection: 'row' }}
                >
                    <div>
                        <Tooltip style={{ padding: '0px' }} title='Search only within trucks owned by the freight provider' placement={props.tooltipPosition}>
                            <FormControlLabel
                                disabled={props.disabled}
                                value={SEARCH_BY_FREIGHT_PROVIDER_REGOS}
                                control={<Radio color='primary' />}
                                label={props.freightProviderLabel}
                                labelPlacement='end'
                                style={{ flex: 1 }}
                            />
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip style={{ padding: '0px' }} title='Search all trucks present in the system. Use this if the sub freight provider company is not known' placement={props.tooltipPosition}>
                            <FormControlLabel
                                style={{ flex: 1, marginLeft: '40px' }}
                                value={SEARCH_BY_ALL_REGOS} control={<Radio color='primary' />}
                                label={props.allRegoLabel}
                            />
                        </Tooltip>
                    </div>
                    <div>
                        <Tooltip style={{ padding: '0px' }} title='Use this option to filter trucks based on sub freight provider company' placement={props.tooltipPosition}>
                            <FormControlLabel
                                style={{ flex: 1, marginLeft: '40px' }}
                                value={SEARCH_BY_TRUCK_OWNER_REGOS}
                                control={<Radio color='primary' />}
                                label={props.truckOwnerLabel}
                            />
                        </Tooltip>
                    </div>
                </RadioGroup>
            </div>
        </React.Fragment>
    );
}

export default TruckSearchOption;

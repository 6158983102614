import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  required,
} from '../../common/validators';
import CommonButton from '../common/CommonButton';
import CommonTextField from '../common/CommonTextField';
import CurrencyField from '../common/CurrencyField'
import {forEach, isString, some, mapValues, isNaN } from 'lodash';
import { getCountryConfig, getCountryLabel } from '../../common/utils';
import InputAdornment from '@mui/material/InputAdornment';
import Checkbox from "@mui/material/Checkbox/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import CommonSelect from '../common/select/CommonSelect';
import { positiveDecimalFilter } from '../../common/input-filters';

class WarehouseInvoiceCustomItemForm extends Component {
  constructor(props){
    super(props);
    const config = getCountryConfig()
    this.state = {
      modifiers: [{id: "plus", name: "+ (Plus)"}, {id: "minus", name: "- (Minus)"}],
      gstRate: config?.invoicing?.gstRate,
      currency: props.currency ||config?.currency,
      gstEditable: true,
        fields: {
            description: {
                value: '',
                validators: [required()],
                errors: [],
            },
            tonnage: {
                value: '',
                validators: [required()],
                errors: [],
            },
            rate: {
                value: '',
                validators: [required()],
                errors: [],
            },
            subTotal: {
                value: '',
                validators: [required()],
                errors: [],
            },
            gst: {
                value: '',
                validators: [],
                errors: [],
            },
            total: {
                value: '',
                validators: [],
                errors: [],
            },
            modifier: {
              value: '',
              validators: [required()],
              errors: [],
            },
        },
    };

    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.onFieldBlur = this.onFieldBlur.bind(this);
    this.setFieldValue = this.setFieldValue.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.setAllFieldsErrors = this.setAllFieldsErrors.bind(this);
    this.handleSubTotalChange = this.handleSubTotalChange.bind(this);
    this.handleGstEditableChange = this.handleGstEditableChange.bind(this);
    this.handleModifierChange = this.handleModifierChange.bind(this);
    this.addItem = this.addItem.bind(this);
  }

  handleFieldChange(event) {
    this.setFieldValue(event.target.id, event.target.value);
  }

  handleCurrencyChange = value => {
    if (value)
      this.setState({currency: value})
  }

  handleNumberFieldChange = event => {
    let value = event.target.value
    if(isString(value))
      value = parseFloat(value.includes(',') ? value.replace(',', '') : value)
    if(isNaN(value))
      value = ''

    this.setFieldValue(event.target.id, value);
  }

  onFieldBlur(event) {
    this.setFieldErrors(event.target.id);
  }

  setFieldValue(key, value) {
    const newState = {...this.state};
    newState.fields[key].value = value;
    this.setState(newState, () => this.setFieldErrors(key));
    if(newState.fields.tonnage.value && newState.fields.rate.value){
        this.handleSubTotalChange();
    }
  }

  getFieldErrors(key) {
    const errors = [];
    const value = this.state.fields[key].value;
    const validators = this.state.fields[key].validators || [];

    validators.forEach((validator) => {
      if (validator.isInvalid(value)) {
        errors.push(validator.message);
      }
    });

    return errors;
  }

  setFieldErrors(key) {
    this.setState(state => ({
      ...state,
      fields: {
        ...state.fields,
        [key]: {
          ...state.fields[key],
          errors: this.getFieldErrors(key)
        }
      }
    }));
  }

  setAllFieldsErrors(callback) {
    const newState = { ...this.state };
    forEach(newState.fields, (value, key) => {
      newState.fields[key].errors = this.getFieldErrors(key);
    });
    this.setState(newState, callback);
  }

  handleSubTotalChange(){
    const newState = {...this.state};
    let value = newState.fields.tonnage.value * newState.fields.rate.value;
    newState.fields.subTotal.value = +(value.toFixed(2));
    if (value && this.state.gstEditable) {
      const gst = +(value * this.state.gstRate).toFixed(2);
      newState.fields.gst.value = gst;
      newState.fields.total.value = +(value + gst).toFixed(2);
    } else {
      newState.fields.gst.value = '';
      newState.fields.total.value = '';
    }
    this.setState(newState, () => this.setFieldErrors('subTotal'));
  }

  handleGstEditableChange(event){
    const newState = {...this.state};
    newState.gstEditable = event.target.checked;
    if (event.target.checked && newState.fields.subTotal.value) {
      const gst = +(newState.fields.subTotal.value * this.state.gstRate).toFixed(2);
      newState.fields.gst.value = gst;
      newState.fields.total.value = +(newState.fields.subTotal.value + gst).toFixed(2);
    } else {
      newState.fields.gst.value = '';
      newState.fields.total.value = '';
    }
    this.setState(newState);
  }

  addItem(){
    this.setAllFieldsErrors(() => {
      const isInvalid = some(this.state.fields, (field) => {
        return field.errors.length > 0;
      });
      const data = mapValues(this.state.fields, (field) => {
        return field.value;
      });
      if (!isInvalid) {
        this.props.addItem(data);
      }
    });
  }

  handleModifierChange(value){
    const newState = {...this.state};
    newState.fields.modifier.value = value;
    this.setState(newState, () => this.setFieldErrors('modifier'));
  }


  render() {
    return(
      <div>
        <form onSubmit={this.handleSubmit} noValidate>
        <div className="cardForm cardForm--drawer">
            <div className="cardForm-content row">
              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="description"
                  label="Description"
                  placeholder="Description"
                  value={this.state.fields.description.value}
                  onChange={this.handleFieldChange}
                  helperText={this.state.fields.description.errors[0]}
                  errorStyle={{textAlign: "left"}}
                  fullWidth
                  maxLength="255"
                />
              </div>
              <div className="col-sm-12 form-wrap-70">
                <CommonSelect
                  id="modifier"
                  floatingLabelText="Modifier"
                  onChange={this.handleModifierChange}
                  value={this.state.fields.modifier.value}
                  errorText={this.state.fields.modifier.errors[0]}
                  items={this.state.modifiers}
                />
              </div>

              <div className="col-sm-12 form-wrap-70">
              <CommonTextField
                  id="tonnage"
                  label="Quantity"
                  placeholder="Quantity"
                  value={this.state.fields.tonnage.value}
                  onChange={this.handleNumberFieldChange}
                  helperText={this.state.fields.tonnage.errors[0]}
                  errorStyle={{textAlign: "left"}}
                onKeyDown={(event)=>positiveDecimalFilter(event, 2, 99999999.99)}
                  fullWidth
                  maxLength="255"
                />
              </div>

              <div className="col-sm-12 form-wrap-70">
              <CommonTextField
                  id="rate"
                  label="Rate"
                  placeholder="Rate"
                  value={this.state.fields.rate.value}
                  onChange={this.handleNumberFieldChange}
                  helperText={this.state.fields.rate.errors[0]}
                  errorStyle={{textAlign: "left"}}
                  onKeyDown={(event)=>positiveDecimalFilter(event, 2, 99999999.99)}
                  fullWidth
                  maxLength="255"
                />
              </div>

              <div className="col-sm-12 form-wrap-70">
                <CurrencyField
                  id="subTotal"
                  label={`Price (Ex ${getCountryLabel('gst')})`}
                  value={this.state.fields.subTotal.value}
                  helperText={this.state.fields.subTotal.errors[0]}
                  fullWidth
                  disabled
                  variant="standard"
                  onCurrencyChange={this.handleCurrencyChange}
                  selectedCurrency={this.state.currency}
                  disabledCurrency
                />
                <FormControlLabel
                  control={<Checkbox color="primary" checked={this.state.gstEditable} onChange={this.handleGstEditableChange}/>}
                  label={`${getCountryLabel('gst')} Applicable`}
                />
              </div>

              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="gst"
                  label={getCountryLabel('gst')}
                  value={this.state.fields.gst.value}
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>{this.state.currency}</InputAdornment>
                  }}
                />
              </div>

              <div className="col-sm-12 form-wrap-70">
                <CommonTextField
                  id="total"
                  label={`Total (Inc ${getCountryLabel('gst')})`}
                  value={this.state.fields.total.value}
                  disabled
                  InputProps={{
                    startAdornment: <InputAdornment position="start" style={{color: 'rgb(162,162,162)'}}>{this.state.currency}</InputAdornment>
                  }}
                />
              </div>

            </div>

            <div className="col-sm-12 cardForm-action top15 padding-reset">
              <CommonButton
                type="button"
                variant="outlined"
                label="Cancel"
                default
                onClick={this.props.closeDrawer}
              />
              <CommonButton
                primary={true}
                variant="contained"
                label="Save"
                type="button"
                onClick={this.addItem}
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default connect()(WarehouseInvoiceCustomItemForm);

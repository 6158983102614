import React, { Component } from 'react';
import { connect } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import './acceptRejectOrder.scss';
import APIService from '../../services/APIService';
import get from "lodash/get";
import moment from "moment";

class UnregisteredUserConfirmMovement extends Component{
  constructor(props) {
    super(props);
    this.state = {
      canConfirm: false,
      isConfirmed: false,
      movement: undefined,
      loaded: false,
    };
    this.acceptFreightContract = this.acceptFreightContract.bind(this);
  }

  async componentDidMount(){
    const movementId = get(this.props, 'match.params.movement_id');
    const salt = get(this.props, 'match.params.salt');
    if(movementId && salt) {
      const movement = await APIService.freights().contracts(movementId).get(salt);
      if(movement && movement.confirmable){
        await this.acceptFreightContract(movement);
      }
      const newState = {...this.state};
      newState.loaded = true;
      this.setState(newState);
    }
  }

  async acceptFreightContract(movement){
    const salt = this.props.match ? this.props.match.params.salt : null;
    await APIService.freights().contracts(movement.id)['confirm']().put({updatedAt: moment.utc(movement.updatedAt).local().valueOf() }, salt)
      .then((json) => {
        if(!isEmpty(json.errors)){
          this.setState({ errors: json.errors[0] });
        } else {
          this.setState({ isConfirmed: true });
        }
      });
  }

  render () {
    return <div className="full-screen-container">
      <div className="content-container">
        <div className="content-box">
          <div className="content-box--header">
            <img src="images/agrichain-logo.png" alt="Agri Chain Logo"/>
          </div>
          <div className="content-box--content">
            {this.state.isConfirmed ? (
              <span>Freight contract accepted successfully.</span>
            ) : this.state.loaded && (
              <span>You do not have permission to access the information.</span>
            )}
          </div>
        </div>
        <div className="content-box-link">See how AgriChain can empower your business <a rel="noopener noreferrer" href="https://agrichain.com" target="_blank">
          here
        </a>.</div>
      </div>
    </div>;
  }
}

export default connect()(UnregisteredUserConfirmMovement);

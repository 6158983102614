import React from 'react';

import { connect } from 'react-redux';
import GooglePlacesAutoComplete from '../google-places/AutoComplete';
import { get, map, isEmpty, cloneDeep, set, some, join, isEqual, forEach, isNumber, includes } from 'lodash';
import APIService from '../../services/APIService';
import { FIELD, REQUIRED_FIELD, DEFAULT_LAT, DEFAULT_LNG, AUSTRALIA_COUNTRY_ID} from '../../common/constants';
import {
  required,
  phoneMobileRegex,
  websiteRegex,
  fixLength,
  numRegex,
} from '../../common/validators';
import { getAusConfig, getCountryLabel } from '../../common/utils';
import CommonTextField from '../common/CommonTextField';
import CommonSelect from '../common/select/CommonSelect';
import CommonAutoSelect from '../common/autocomplete/CommonAutoSelect';
import FileUpload from '../common/FileUpload';
import {forceStopLoader, isLoading, loaded} from "../../actions/main";
import {getPaymentTerms} from "../../actions/companies/contracts";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import CommonButton from "../common/CommonButton";
import {
  Button, Dialog, DialogTitle, DialogContent, DialogActions, Table, TableBody, TableRow, TableCell,
} from '@mui/material/';
import alertifyjs from "alertifyjs";
import format from 'string-format';
import CountryDropDown from '../common/CountryDropDown';
import ABNField from '../common/ABNField';
import NGRField from '../common/NGRField';
import PhoneField from '../common/PhoneField'

const ABN_ALREADY_EXISTS = 'This ABN already exist';
const CLAIM_REQUEST_SENT = 'Company Claim Sent';
const CLAIM_REQUEST_TEXT = 'Your company claim request has been successfully sent to Information AgriChain. Once the AgriChain ' +
                           'Admin approves your request, you will receive an email and be able to login.';
const REGISTERED_CLAIM_DIALOG_TEXT = 'Company corresponding to the {} entered already exists as a registered company on the system. Please contact your company admin(s) at {} for your account creation';
const UNREGISTERED_CLAIM_DIALOG_TEXT = '​Company corresponding to the ABN entered already exists as an unregistered company on the system. Please click "Claim The Company" if you manage the company.';



class CompanySignUpForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      weekdays: [
        {id: 0, displayName: 'Sunday'},
        {id: 1, displayName: 'Monday'},
        {id: 2, displayName: 'Tuesday'},
        {id: 3, displayName: 'Wednesday'},
        {id: 4, displayName: 'Thursday'},
        {id: 5, displayName: 'Friday'},
        {id: 6, displayName: 'Saturday'},
      ],
      existingEmployeeDialog: false,
      companyExistsDialogOpen: false,
      companyClaimDialogOpen: false,
      existingCompanyId: undefined,
      isExistingCompanyRegistered: false,
      disableDetails: false,
      claimReason: '',
      adminEmails: [],
      employees: [],
      isSignUpSuccess: false,
      resetAddress: false,
      country: getAusConfig(),
      entity: 'ABN',
      ngrShareholderCompanies: [],
      fields: {
        abn: set(cloneDeep(REQUIRED_FIELD), 'validators', [required(), numRegex(), fixLength(11)]),
        ngrNumber: set(cloneDeep(FIELD), 'validators', [fixLength(8), numRegex()]),
        businessName: cloneDeep(REQUIRED_FIELD),
        entityName: cloneDeep(REQUIRED_FIELD),
        address: set(cloneDeep(REQUIRED_FIELD), 'validators', [required()]),
        mobile: set(cloneDeep(REQUIRED_FIELD), 'validators', [required(), phoneMobileRegex()]),
        website: set(cloneDeep(FIELD), 'validators', [websiteRegex()]),
        gstNumber: set(cloneDeep(FIELD)),
        startOfWeek: {...cloneDeep(FIELD), value: 1},
        endOfWeek: {...cloneDeep(FIELD), value: 5},
        paymentTermId: set(cloneDeep(REQUIRED_FIELD), 'validators', [required()]),
        logo: {...cloneDeep(FIELD), value: {base64: '', name: ''}},
        countryId: {...cloneDeep(REQUIRED_FIELD), value: AUSTRALIA_COUNTRY_ID}
      },
      abnServerErrors: [],
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleNGRNumberBlur = this.handleNGRNumberBlur.bind(this);
    this.handleFieldBlur = this.handleFieldBlur.bind(this);
    this.handleAbnBlur = this.handleAbnBlur.bind(this);
    this.handleAddressBlur = this.handleAddressBlur.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleGenericSelectFieldChange = this.handleGenericSelectFieldChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleStartOfWeekChange = this.handleStartOfWeekChange.bind(this);
    this.handleEndOfWeekChange = this.handleEndOfWeekChange.bind(this);
    this.getFieldErrors = this.getFieldErrors.bind(this);
    this.setFieldErrors = this.setFieldErrors.bind(this);
    this.handleLogoUpload = this.handleLogoUpload.bind(this);
    this.handleLogoRemove = this.handleLogoRemove.bind(this);
    this.populateCompanyDetails= this.populateCompanyDetails.bind(this);
    this.populateCompanyDetailsFromNgr = this.populateCompanyDetailsFromNgr.bind(this);
    this.handleAbnChangeAndSelectCompany = this.handleAbnChangeAndSelectCompany.bind(this);
  }

  handleCompanyExistsDialogClose = () => {
    const newState = {...this.state};
    newState.companyExistsDialogOpen = false;
    newState.fields.abn.value = '';
    newState.fields.ngrNumber.value = '';
    newState.ngrShareholderCompanies = [];
    newState.entity = 'ABN';

    this.setState(newState, () => {
    if(this.props.updateSteps)
        this.props.updateSteps(false);
    });
  };

  handleCompanyClaimDialogClose = () => {
    this.setState({companyClaimDialogOpen: false});
  };

  handleCompanyClaimDialogOpen = () => {
    this.setState({companyClaimDialogOpen: true, companyExistsDialogOpen: false});
  };

  handleExistingEmployeesDialogOpen = () => {
    this.setState({existingEmployeeDialog: true});
  };

  handleExistingEmployeesDialogClose = () => {
    this.setState({existingEmployeeDialog: false});
  };

  navigateToLogin = (event) => {
    event.preventDefault();
    document.location.hash = '/';
  };

  componentDidMount(){
    this.props.dispatch(getPaymentTerms());
    this.copyValuesFromProps();
  }

  copyValuesFromProps = () => {
    const newState = this.state;
    forEach(newState.fields, (field, key) => {
      field.value = get(this.props.values.company, key, field.value);
    });
    newState.fields.logo.value = {base64: '', name: ''};
    newState.fields.ngrNumber.value = this.props.ngrNumber;
    this.setState(newState, this.getCompanyDataByAbnAndUpdateState);
  };

  componentDidUpdate(prevProps, prevState) {
    if(this.props.paymentTerms && !this.state.fields.paymentTermId.value) {
      this.setFieldValue('fields.paymentTermId', 10);
    }
    if (!isEqual(prevState.abnServerErrors, this.state.abnServerErrors)) {
      const errors = isEmpty(this.state.abnServerErrors) ? this.state.fields.abn.errors : this.state.abnServerErrors;
      this.setState(state => set(state, 'fields.abn.errors', errors));
    }
    if (this.state.resetAddress) {
      this.setState({ resetAddress: false });
    }
    if(this.props.ngrNumber && !this.state.fields.ngrNumber.value && this.props.setNgrNumber) {
      this.props.setNgrNumber(null)
      this.resetRelatedStateValues('ngrNumber');
    }
  }

  setEmployeeAndProceed(event, employee) {
    event.persist()
    this.setState(
      { selectedEmployee: employee, existingEmployeeDialog: false },
      () => {
        this.props.onEmployeeSelect(employee, () => this.handleSubmit(event));
      }
    );
  }

  handleContinue = (event) => {
    event.preventDefault();
    if(
      this.state.existingCompanyId &&
      !this.state.isExistingCompanyRegistered &&
      !isEmpty(this.state.employees)
    )
      this.setState({existingEmployeeDialog: true});
    else
      this.handleSubmit(event);
  };

  handleSubmit(event) {
    event.preventDefault();
    if (isEmpty(this.state.abnServerErrors)){
      this.setAllFieldsErrors();
      if(!this.getIsFormInvalid()) {
        const data = this.getSubmitData();
        this.props.submit(data);
        this.props.nextStep();
      }
    }
  }

  getSubmitData = () => {
    let submitData = {};
    forEach(this.state.fields, (field, key) => {
      submitData[key] = field.value;
    });
    if(get(submitData, 'address.address') && !get(submitData, 'address.latitude') && !get(submitData, 'address.longitude')){
      submitData.address.latitude = DEFAULT_LAT;
      submitData.address.longitude = DEFAULT_LNG;
    }
    delete submitData['ngrNumber'];
    return {company: {...this.props.values.company, ...submitData}};
  };

  handleFieldBlur(event) {
    this.setFieldValue(`fields.${event.target.id}`, event.target.value);
    this.props.submit(this.getSubmitData());
  }

  handleAbnBlur(event) {
    this.resetRelatedStateValues();

    this.setFieldValue(`fields.abn`, event.target.value, true, (state) => {
      if (!this.getIsFieldInvalid(state.fields.abn)) {
        this.getCompanyDataByAbnAndUpdateState();
      }
    });
    this.props.submit(this.getSubmitData());
  }

  handleAddressBlur() {
    this.setFieldErrors(`fields.address`);
    this.props.submit(this.getSubmitData());
  }

  resetRelatedStateValues(type='abn', callback=null) {
    const newState = { ...this.state };

    newState.fields.entityName.value = '';
    newState.fields.businessName.value = '';
    newState.fields.address.value = {};
    newState.fields.mobile.value = '';
    newState.fields.website.value = '';
    newState.fields.gstNumber.value = '';
    newState.fields.startOfWeek.value = 1;
    newState.fields.endOfWeek.value = 5;
    newState.disableDetails = false;
    newState.resetAddress = true;
    newState.abnServerErrors = [];

    if(type == 'ngrNumber') {
      newState.ngrShareholderCompanies = [];
      newState.fields.abn.value = null;
    }

    this.setState(newState, () => {
      if (callback) callback();
    });
  }

  handleClaimReasonChange = (event) => {
    this.setState({claimReason: event.target.value});
  };

  getEmployeeData (){
    const {values} = this.props;
    values['claimDetails'] = this.state.claimReason;
    delete values['company'];
    delete values['confirmPassword'];
    delete values['isChecked'];
    return values;
  }

  claimCompany = () => {
    const employeeData = this.getEmployeeData();
    const {dispatch} = this.props;

    dispatch(isLoading('registerSuccess'));
    APIService.companies(this.state.existingCompanyId).claim().post(employeeData).then(response => {
      dispatch(loaded());
      if(response.success){
        this.handleCompanyClaimDialogClose();
        this.props.completeSignUp(CLAIM_REQUEST_SENT, CLAIM_REQUEST_TEXT);
      } else {
        alertifyjs.error("Something went wrong!");
      }
    });
  };

  getRegisteredCompanyAdmins() {
    const adminEmailsService = APIService.companies(this.state.existingCompanyId).admins();
    adminEmailsService.get().then(response => {
      if (response.emails){
        this.setState({adminEmails: response.emails}, () => this.setState({companyExistsDialogOpen: true}));
      }
    }
    );
  }

  getAllCompanyEmployees(companyId) {
    companyId = companyId || this.state.existingCompanyId;
    if(companyId)
      APIService.companies().appendToUrl(`unregistered/${companyId}/employees/`).get().then(employees => {
        this.setState({employees: employees});
      });
  }

  populateCompanyDetails(details) {
    const newState = {...this.state};
    forEach(newState.fields, (field, key) => {
      if (!includes(['logo', 'abn', 'ngrNumber'], key)){
        field.value = details[key];
        field.errors = [];
      }
    });
    newState.disableDetails = true;
    this.setState(newState);
  }

  getCompanyDataByAbnAndUpdateState() {
    if(!this.state.fields.abn.value)
      return;

    let fields = {...this.state.fields};
    let abnServerErrors = [];
    this.props.dispatch(isLoading('abnEntityName'));
    this.getCompanyNameByAbn(this.state.fields.abn.value).then(response => {
      if(this.props.setCompanyResponse)
        this.props.setCompanyResponse(response);
      if (this.state.fields.ngrNumber.value && this.props.setNgrNumber)
        this.props.setNgrNumber(this.state.fields.ngrNumber.value);
      if (isEmpty(response.errors)) {
        Object.keys(response).forEach((fieldKey) => {
          if (fieldKey == 'address')
            fields = set(fields, `${fieldKey}.value.address`, response[fieldKey]);
          else
            fields = set(fields, `${fieldKey}.value`, response[fieldKey]);
        });
        if(this.props.getExistingCompany){
          this.props.getExistingCompany();
        }
      } else {
        abnServerErrors = response.errors.abn;
        if (abnServerErrors[0] === ABN_ALREADY_EXISTS){
          abnServerErrors = [];
          if(this.props.getExistingCompany){
            this.props.getExistingCompany(response.companyId);
          }
          if(!response.isCompanyRegistered)
            this.getAllCompanyEmployees(response.companyId);
          if (response.isCompanyRegistered){
            this.setState({
              existingCompanyId: response.companyId,
              isExistingCompanyRegistered: response.isCompanyRegistered,
              entity: 'ABN'
            }, () => {
              if(this.props.updateSteps)
                this.props.updateSteps(false);
              this.getRegisteredCompanyAdmins();
            });
          } else if (this.props.isLiteSignUp){
            this.setState({
              existingCompanyId: response.companyId,
              isExistingCompanyRegistered: response.isCompanyRegistered
            }, () => {
              if(this.props.updateSteps)
                this.props.updateSteps(false);
              this.populateCompanyDetails(response);
            });
          } else {
            this.setState({
              existingCompanyId: response.companyId,
              isExistingCompanyRegistered: response.isCompanyRegistered
            }, () => {
              if(this.props.updateSteps)
              this.props.updateSteps(true);
              this.populateCompanyDetails(response);
            });
          }
        }
      }
      this.props.dispatch(forceStopLoader());
      this.setState(state => ({ ...state, fields, abnServerErrors}), () => {this.props.submit(this.getSubmitData());});
    });
  }

  handleFieldChange(event) {
    this.setFieldValue(`fields.${event.target.id}`, event.target.value, true);
  }

  handleAbnChange = event => {
    let value = event.target.value
    this.setFieldValue(`fields.abn`, value, true)
  }

  handleCountryChange = (event, item) => {
    const newState = {...this.state}
    newState.fields.countryId.value = item?.id
    newState.country = item
    if(item?.config?.abn?.optional) {
      newState.fields.abn.errors = []
      newState.fields.abn.validators = [fixLength(item.config.abn.length)]
      newState.fields.mobile.errors = []
      newState.fields.mobile.validators = [required(), phoneMobileRegex(null)]
    } else {
      newState.fields.abn.errors = []
      newState.fields.abn.validators = [required(), numRegex(), fixLength(11)]
      newState.fields.mobile.errors = []
      newState.fields.mobile.validators = [required(), phoneMobileRegex()]
    }
    localStorage.setItem('current_country', item?.code)
    this.setState(newState, () => this.setFieldErrors('fields.countryId'))
  }

  handleAddressChange(data) {
    if (data.address) {
      this.setFieldValue('fields.address', {
        address: data.address,
        latitude: data.lat || DEFAULT_LAT,
        longitude: data.lng || DEFAULT_LNG,
      }, true);
    }
  }

  handleGenericSelectFieldChange(value, id) {
    this.setFieldValue(`fields.${id}`, value, true);
  }

  handleStartOfWeekChange(value) {
    if (isNumber(value) && this.state.fields.startOfWeek.value !== value) {
      const newState = {...this.state};
      newState.fields.startOfWeek.value = value;
      if(value === this.state.fields.endOfWeek.value) {
        newState.fields.endOfWeek.errors.push('Cannot be same as Start of Week');
      }
      else {
        newState.fields.endOfWeek.errors = [];
      }
      this.setState(newState);
    }
    this.setFieldErrors(`fields.startOfWeek`);
  }

  handleEndOfWeekChange(value) {
    if (isNumber(value) && this.state.fields.endOfWeek.value !== value) {
      const newState = {...this.state};
      newState.fields.endOfWeek.value = value;
      if(this.state.fields.startOfWeek.value === value) {
        newState.fields.endOfWeek.errors.push('Cannot be same as Start of Week');
      }
      else {
        newState.fields.endOfWeek.errors = [];
      }
      this.setState(newState);
    }
  }

  setFieldValue(path, value, validateAfterSet = true, callback) {
    this.setState(
      state => set(state, `${path}.value`, value),
      () => {
        if (validateAfterSet) this.setFieldErrors(path);
        if (callback) callback(this.state);
        this.props.submit(this.getSubmitData());
      }
    );
  }

  setFieldErrors(path) {
    this.setState(state => set(state, `${path}.errors`, this.getFieldErrors(path)));
  }

  getFieldErrors(path) {
    const errors = [];
    const value = get(this.state, `${path}.value`);
    const validators = get(this.state, `${path}.validators`, []);

    if(path === 'fields.mobile' && value) {
      if(!value.match(this.state.country?.config?.phoneMobileRegex))
        errors.push(validators[1].message)
    } else {
      validators.forEach((validator) => {
        if (validator.isInvalid(value)) {
          errors.push(validator.message);
        }
      });
    }


    return errors;
  }

  setAllFieldsErrors() {
    let newState = { ...this.state };

    forEach(newState.fields, (field, fieldKey) => {
      const path = `fields.${fieldKey}`;
      newState = set(newState, `${path}.errors`, this.getFieldErrors(path));
    });

    this.setState(newState);
  }

  getIsFormInvalid() {
    return some(this.state.fields, (field, key) => {
      if(key === 'mobile')
        return !isEmpty(field.errors)
      return this.getIsFieldInvalid(field);
    }) || !isEmpty(this.state.abnServerErrors);
  }

  getIsFieldInvalid(field) {
    return some(field.validators, (validator) => {
      return validator.isInvalid(field.value);
    });
  }

  getCompanyNameByAbn(abn) {
    return APIService.abn().appendToUrl('company/').get(null, null, { abn });
  }

  handleLogoUpload(fileState){
    const newState = {...this.state};
    newState.fields.logo.value = fileState.file;
    this.setState(newState, () => {
      this.props.submit(this.getSubmitData());
    });
  }

  handleLogoRemove() {
    const newState = {...this.state};
    newState.fields.logo.value.base64 = null;
    newState.fields.logo.value.name = null;
    this.setState(newState, () => {
      this.props.submit(this.getSubmitData());
    });
  }

  getCompanyExistsDialogContent () {
    if (this.state.isExistingCompanyRegistered){
      return format(REGISTERED_CLAIM_DIALOG_TEXT, this.state.entity || 'ABN', join(this.state.adminEmails, ', '));
    } else {
      return UNREGISTERED_CLAIM_DIALOG_TEXT;
    }
  }

  getLabel = (config, defaultLabel) => {
    let label = defaultLabel
    if(config?.label) {
      label = config.label
      label = config.optional ? `${label} (Optional)` : label
    }
    return label
  }
  populateCompanyDetailsFromNgr = company => {
    let isRegistered = get(company, 'isRegistered', false);
    if (isRegistered) {
      this.setState({
        existingCompanyId: company?.id,
        isExistingCompanyRegistered: isRegistered,
        entity: isEmpty(this.state.ngrShareholderCompanies) ? 'NGR' : 'ABN',
      }, () => {
        if(this.props.updateSteps)
          this.props.updateSteps(false);
        this.getRegisteredCompanyAdmins();
      })
    } else {
      delete company?.isRegistered;
      delete company?.label
      let fields = { ...this.state.fields };
      this.getAllCompanyEmployees(company.id);
      if(this.props.setCompanyResponse)
        this.props.setCompanyResponse(company);
      if(this.props.setNgrNumber)
        this.props.setNgrNumber(this.state.fields.ngrNumber.value);
      if (this.props.setFetchedFromNgr)
        this.props.setFetchedFromNgr(true);
      Object.keys(company).forEach(fieldKey => {
        if (!includes(['id', 'name', 'countryCode'], fieldKey))
          fields = set(fields, `${fieldKey}.value`, company[fieldKey]);
      });
      this.setState(state => ({
        ...state,
        fields,
        disableDetails:  true,
        existingCompanyId: company.id,
        isExistingCompanyRegistered: isRegistered
      }));
    }
  }

  getCompaniesByNgr = ngrNumber => APIService.ngrs().appendToUrl(`company/?ngr_number=${ngrNumber}`).get();

  getCompanyDataByNgrAndUpdateState() {
    this.getCompaniesByNgr(this.state.fields.ngrNumber.value).then(items => {
      let systemNgrId = get(items, 'systemNgrId', null);
      items = systemNgrId ? get(items, 'companies') : items;
      if (isEmpty(items)) {
        if(this.props.updateSteps)
          this.props.updateSteps(false);
        if (systemNgrId && this.props.setTransferNgrId)
          this.props.setTransferNgrId(systemNgrId);
        if (!systemNgrId && this.props.shouldCreateNgr)
          this.props.shouldCreateNgr(this.state.fields.ngrNumber.value);
      } else {
        if(this.props.updateSteps)
          this.props.updateSteps(true);

        if (items.length == 1)
          this.populateCompanyDetailsFromNgr(get(items, '[0]', null));
        else
          this.setState({ngrShareholderCompanies: items})
      }
    });
  }

  handleNGRNumberBlur(event) {
    event.persist()
    this.setFieldValue('fields.ngrNumber', event.target.value, true, () => {
      if (this.state.fields.ngrNumber.value) {
        this.resetRelatedStateValues('ngrNumber');
        this.getCompanyDataByNgrAndUpdateState();
      }
    });
  }

  handleAbnChangeAndSelectCompany(value, id, item) {
    this.setFieldValue(`fields.abn`, item?.abn, true,  () => {
      this.resetRelatedStateValues(null, () => this.populateCompanyDetailsFromNgr(item));
    })
  }


  render() {
    const ERROR_STYLE = { textAlign: 'left' };
    const config = this.state.country?.config
    const isNgrFieldVisible = !this.props.isLiteSignUp && includes([AUSTRALIA_COUNTRY_ID], this.state.country?.id)
    return (
      <div>
        <div className="sign-up-form-height">
          <div className="row" style={{height: '100%'}}>
            <div className='col-sm-6'>
              <div className="form-wrap-70 sign-up-field">
                <CountryDropDown
                  id="country"
                  label="Country"
                  onChange={this.handleCountryChange}
                  variant='standard'
                  selected={this.state.country}
                  disableClearable
                />
              </div>
              { isNgrFieldVisible &&
              <div className="form-wrap-70 sign-up-field">
                <NGRField
                  label='NGR (Optional)'
                  helperText= {get(this.state.fields.ngrNumber, 'errors[0]', '')}
                  onChange={this.handleFieldChange}
                  onBlur={this.handleNGRNumberBlur}
                  value={this.state.fields.ngrNumber.value}
                />
              </div>
              }
              {!isEmpty(this.state.ngrShareholderCompanies) ?
              <div className="form-wrap-70 sign-up-field">
                <CommonAutoSelect
                  id='abn'
                  value={find(this.state.ngrShareholderCompanies, {abn: this.state.fields.abn.value})}
                  label={config?.abn?.label || 'ABN'}
                  items={this.state.ngrShareholderCompanies}
                  onChange={this.handleAbnChangeAndSelectCompany}
                  dataSourceConfig={{text: 'abn', value: 'id'}}
                  disableClearable
                />
              </div>
              :
              <div className="form-wrap-70 sign-up-field">
                <ABNField
                  value={this.state.fields.abn.value}
                  helperText= {get(this.state.fields.abn, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  onBlur={this.handleAbnBlur}
                  onChange={this.handleAbnChange}
                  disabled={this.props.employeeExists || !this.state.fields.countryId.value}
                />
              </div>
              }
              <div className="form-wrap-70 sign-up-field">
                <CommonTextField
                  id="entityName"
                  label="Entity Name"
                  value={this.state.fields.entityName.value}
                  disabled={config?.entityName?.disabled}
                  onChange={this.handleFieldChange}
                  helperText= {get(this.state.fields.entityName, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  fullWidth
                />
              </div>
              <div className="form-wrap-70 sign-up-field">
                <CommonTextField
                  id="businessName"
                  label="Business Name"
                  placeholder="Please enter"
                  value={this.state.fields.businessName.value}
                  helperText= {get(this.state.fields.businessName, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  onBlur={this.handleFieldBlur}
                  onChange={this.handleFieldChange}
                  maxLength="100"
                  fullWidth
                  disabled={this.state.disableDetails}
                />
              </div>

              <div className="form-wrap-70 sign-up-field">
                <GooglePlacesAutoComplete
                  id="address"
                  floatingLabelText="Address"
                  hintText="Please select an address from the suggestions"
                  value={get(this.state.fields, 'address.value.address')}
                  errorText={get(this.state.fields.address, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  onBlur={this.handleAddressBlur}
                  onChange={this.handleAddressChange}
                  forceSelection
                  fullWidth
                  disabled={this.state.disableDetails}
                  resetState={this.state.resetAddress}
                  countryCode={this.state.country?.code}
                />
              </div>
              { !isNgrFieldVisible &&
              <div className="form-wrap-70 sign-up-field">
                <PhoneField
                  id="mobile"
                  label="Phone/Mobile"
                  value={this.state.fields.mobile.value}
                  helperText={get(this.state.fields.mobile, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  onBlur={this.handleFieldBlur}
                  onChange={this.handleFieldChange}
                  disabled={this.state.disableDetails}
                  countryCode={this.state.country?.code}
                />
              </div>
              }
            </div>
            <div className='col-sm-6'>
              { isNgrFieldVisible &&
              <div className= "form-wrap-70 sign-up-field sign-up-right-field">
                  <PhoneField
                    id="mobile"
                    label="Phone/Mobile"
                    value={this.state.fields.mobile.value}
                    helperText={get(this.state.fields.mobile, 'errors[0]', '')}
                    errorStyle={ERROR_STYLE}
                    onBlur={this.handleFieldBlur}
                    onChange={this.handleFieldChange}
                    disabled={this.state.disableDetails}
                    countryCode={this.state.country?.code}
                  />
                </div>
              }
              <div className="form-wrap-70 sign-up-field sign-up-right-field">
                <CommonTextField
                  id="website"
                  label="Website (Optional)"
                  placeholder="Please enter"
                  value={this.state.fields.website.value}
                  helperText={get(this.state.fields.website, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  onBlur={this.handleFieldBlur}
                  onChange={this.handleFieldChange}
                  fullWidth
                  disabled={this.state.disableDetails}
                />
              </div>
              {config.invoicing?.gst && <div className="form-wrap-70 sign-up-field sign-up-right-field">
                <CommonTextField
                  id="gstNumber"
                  label={`${getCountryLabel('gst')} (Optional)`}
                  placeholder="Please enter"
                  value={this.state.fields.gstNumber.value}
                  helperText={get(this.state.fields.gst, 'errors[0]', '')}
                  errorStyle={ERROR_STYLE}
                  onBlur={this.handleFieldBlur}
                  onChange={this.handleFieldChange}
                  fullWidth
                  disabled={this.state.disableDetails}
                />
              </div>}


              <div className="form-wrap-70 sign-up-field sign-up-right-field">
                <CommonSelect
                  id="startOfWeek"
                  floatingLabelText="Invoicing Start of Week"
                  value={this.state.fields.startOfWeek.value}
                  selectedItemId={this.state.fields.startOfWeek.value}
                  errorText= {get(this.state.fields.startOfWeek, 'errors[0]', '')}
                  selectConfig={{text: 'displayName', value: 'id'}}
                  onChange={this.handleStartOfWeekChange}
                  items={this.state.weekdays}
                  errorStyle={ERROR_STYLE}
                  disabled={this.state.disableDetails}
                />
              </div>
              <div className="form-wrap-70 sign-up-field sign-up-right-field">
                <CommonSelect
                  id="endOfWeek"
                  floatingLabelText="Invoicing End of Week"
                  value={this.state.fields.endOfWeek.value}
                  selectedItemId={this.state.fields.endOfWeek.value}
                  errorText= {get(this.state.fields.endOfWeek, 'errors[0]', '')}
                  selectConfig={{text: 'displayName', value: 'id'}}
                  onChange={this.handleEndOfWeekChange}
                  items={this.state.weekdays}
                  errorStyle={ERROR_STYLE}
                  disabled={this.state.disableDetails}
                />
              </div>

              <div className="form-wrap-70 sign-up-field sign-up-right-field">
                <CommonAutoSelect
                  id="paymentTermId"
                  onChange={this.handleGenericSelectFieldChange}
                  label="Default Payment Term"
                  dataSourceConfig={{text: 'name', value: 'id'}}
                  value={this.state.fields.paymentTermId.value}
                  errorText={get(this.state, 'fields.paymentTermId.errors[0]', '')}
                  items={this.props.paymentTerms || []}
                  disabled={this.state.disableDetails}
                />
              </div>

              {
                !this.state.disableDetails && this.state.fields.logo.value &&
                <div className="form-wrap-70 sign-up-field sign-up-right-field" style={{padding: '0px 0px 15px 0px'}}>
                  <FileUpload
                    id="logo"
                    floatingLabelText="Attach File"
                    fullWidth={true}
                    textFieldstyle={{float: 'left', color: 'black'}}
                    buttonStyle={{border: '1px solid'}}
                    onChange={this.handleLogoUpload}
                    buttonText="Upload Logo"
                    allowedExtensions="image/*"
                    previewStyle={{marginTop: '10px', width: '150%', marginLeft: '-15px'}}
                    onRemove={this.handleLogoRemove}
                    fileCount={(this.state.fields.logo.value.base64) ? 2 : 0}
                  />
                </div>
              }
            </div>

            <div className="signup-content-box--footer col-sm-12">
              <div className='col-sm-6 left-text-align padding-reset fixed-bottom' style={{zIndex: '100'}}>
                <Button
                  variant="text"
                  type="button"
                  color="primary"
                  style={{color: 'rgb(0, 0, 0)'}}
                  onClick={this.props.previousStep}
                >
                  <KeyboardArrowLeft style={{padding: '1px 0px'}}/>Back
                </Button>
              </div>
              <div className='col-sm-6 padding-reset fixed-bottom' style={{'textAlign': 'right', width: '97%'}}>
                <CommonButton
                  type="submit"
                  label="Continue"
                  variant="contained"
                  primary={true}
                  className="login-button"
                  onClick={this.handleContinue}
                  style={{width: '30%'}}
                />
              </div>
            </div>
          </div>

          <Dialog
            onClose={this.handleCompanyExistsDialogClose}
            aria-labelledby="customized-dialog-title"
            open={this.state.companyExistsDialogOpen}
          >
            <DialogTitle id="customized-dialog-title" onClose={this.handleCompanyExistsDialogClose}>
              Company Already Exists
            </DialogTitle>
            <DialogContent>
              {this.getCompanyExistsDialogContent()}
            </DialogContent>
            <DialogActions>
              <Button onClick={this.navigateToLogin} color="primary">
                Back to Login
              </Button>
              <Button onClick={this.handleCompanyExistsDialogClose} color="primary">
                {`Use Different ${this.state.entity || 'ABN'}`}
              </Button>
            </DialogActions>
          </Dialog>
          {
            this.state.existingEmployeeDialog && <Dialog
                                                   onClose={this.handleExistingEmployeesDialogClose}
                                                   aria-labelledby="customized-dialog-title"
                                                   open={this.state.existingEmployeeDialog}>
              <DialogTitle id="customized-dialog-title" onClose={this.handleExistingEmployeesDialogClose}>
                This company has existing employee(s)
              </DialogTitle>
              <DialogContent>
                <div style={{fontSize: '14px', margin: '10px 0'}}>
                  Please select if you are any of the following employees or else click on New Employee if you are not listed
                </div>
                <Table>
                  <TableBody>
                    {
                      map(this.state.employees, employee => (
                        <TableRow key={employee.id} onClick={(event) => this.setEmployeeAndProceed(event, employee)}>
                          <TableCell align='center' style={{cursor: 'pointer'}}>{employee.name}</TableCell>
                          <TableCell align='center' style={{cursor: 'pointer'}}>{employee.mobile}</TableCell>
                          <TableCell align='center' style={{cursor: 'pointer'}}>{employee.email}</TableCell>
                        </TableRow>
                      ))
                    }
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleExistingEmployeesDialogClose}>
                  Cancel
                </Button>
                <Button onClick={event => this.setEmployeeAndProceed(event)} color="primary">
                  New Employee
                </Button>
              </DialogActions>
            </Dialog>
          }

          <Dialog
            open={this.state.companyClaimDialogOpen}
            onClose={this.handleCompanyClaimDialogClose}
            aria-labelledby="form-dialog-title"
            fullWidth
          >
            <DialogTitle id="form-dialog-title">
              Company Claim Request
            </DialogTitle>
            <DialogContent>
              <CommonTextField
                id="claimCompany"
                label={"Company Claim Details (Optional)"}
                value={this.state.claimReason}
                onChange={this.handleClaimReasonChange}
                fullWidth
                maxLength="255"
                multiline
                rows={7}
              />
            </DialogContent>
            <DialogActions>
              <DialogActions>
                <CommonButton onClick={this.handleCompanyClaimDialogClose} key='cancel' label='Cancel' variant="outlined" />
                <CommonButton onClick={this.claimCompany} key='claim' label='Claim' primary variant="contained" />
              </DialogActions>
            </DialogActions>
          </Dialog>

        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentTerms: state.companies.contracts.paymentTerms
  };
};

export default connect(mapStateToProps)(CompanySignUpForm);

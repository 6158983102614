import { connect } from 'react-redux';
import FarmToFarm from '../components/farms/FarmToFarm';
import { transferFarmAsset } from '../actions/api/farms';
import { farmSideDrawerForTransfer } from '../actions/companies/farms';

function submit(farmId, data) {
  return (dispatch) => {
    dispatch(transferFarmAsset(farmId, data));
  };
}

const mapStateToProps = (state) => {
  return {
    selectedAsset: state.companies.farms.selectedAsset
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    submit: (farmId, data) => dispatch(submit(farmId, data)),
    cancel: () => dispatch(farmSideDrawerForTransfer(false)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FarmToFarm);
